import { createContext, useContext } from 'react';
import type { BlockId } from 'venn-state';

export const BlockIdContext = createContext<BlockId | undefined>(undefined);
BlockIdContext.displayName = 'BlockIdContext';

export const useBlockId = () => {
  const blockId = useContext(BlockIdContext);
  if (blockId === undefined) {
    throw new Error('useBlockId must be used within a BlockIdProvider and provided a valid blockId');
  }

  return blockId;
};
