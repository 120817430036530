import { useCallback, useEffect, useState } from 'react';
import { isEmpty, isEqual } from 'lodash';
import type { History } from 'history';
import queryString from 'query-string';

import type { SavedViewsQuickFilter } from 'venn-utils';
import { getQueryParams } from 'venn-utils';
import { SORTDIR, useDebounce } from 'venn-components';

import type { Params } from '../types';
import { SectionKey } from '../types';
import type { ItemType } from 'venn-ui-kit';

const DEFAULT_PARAMS: Params = {
  page: 1,
  name: '',
  filters: [],
  itemTypes: [],
};

export const useLibraryStateURLSynchronizer = (
  history: History<{ shouldListenerIgnore?: boolean }>,
  pageLoaded: React.MutableRefObject<boolean>,
) => {
  const [queryParams, setQueryParams] = useState(getParamsFromUrl(history.location.search));
  const [debouncedParams] = useDebounce(queryParams, 500); // Used to update the url when queryParams change

  useEffect(() => {
    pageLoaded.current = true;
    setQueryParams((prev) => {
      const mergeParams = { ...DEFAULT_PARAMS, ...prev };
      return mergeParams;
    });
  }, [pageLoaded, setQueryParams]);

  useEffect(() => {
    return history.listen(({ state, search }) => {
      if (state?.shouldListenerIgnore) {
        return;
      }
      setQueryParams((prev) => {
        const paramsFromUrl = getParamsFromUrl(search);
        if (isEqual(paramsFromUrl, prev)) {
          return prev;
        }
        return {
          ...prev,
          ...paramsFromUrl,
        };
      });
    });
  }, [history]);

  // Watches for updates to debouncedParams and updates history.location.search accordingly
  useEffect(() => {
    const path = history.location.pathname;
    const existing = getParamsFromUrl(history.location.search);
    const mergedParams = {
      ...existing,
      ...debouncedParams,
    };

    const skipDefault = {
      ...(mergedParams.page !== DEFAULT_PARAMS.page && { page: mergedParams.page }),
      ...(mergedParams.name !== DEFAULT_PARAMS.name && { name: mergedParams.name }),
      ...(mergedParams.sortBy !== undefined && { sortBy: mergedParams.sortBy }),
      ...(mergedParams.order !== undefined && { order: mergedParams.order }),
      ...(!isEmpty(mergedParams.filters) && { filters: mergedParams.filters }),
      ...(!isEmpty(mergedParams.itemTypes) && { itemType: mergedParams.itemTypes }),
    };

    const newSearch = queryString.stringify(skipDefault);

    if (path && !isEqual(existing, mergedParams)) {
      history.push(`${path}?${newSearch}`, { shouldListenerIgnore: true });
    }
  }, [debouncedParams, history]);

  const updateSearchParam = useCallback((key: string, value: string | string[] | undefined, order?: SORTDIR) => {
    setQueryParams((prevQueryParams) => {
      let updateParams;
      switch (key) {
        case 'name':
          updateParams = {
            ...prevQueryParams,
            [key]: value,
            page: 1,
          };
          break;
        case 'page':
          updateParams = {
            ...prevQueryParams,
            [key]: Number(value),
          };
          break;
        case 'sortBy':
          updateParams = {
            ...prevQueryParams,
            [key]: value,
            order: order === SORTDIR.DESC ? 'desc' : 'asc',
            page: 1,
          };
          break;
        default:
          updateParams = {
            ...prevQueryParams,
            [key]: value,
            page: 1,
          };
      }

      return updateParams as Params;
    });
  }, []);

  const toggleParamItem = useCallback((key: SectionKey, item: SavedViewsQuickFilter | ItemType) => {
    setQueryParams((prevQueryParams) => {
      switch (key) {
        case SectionKey.FILTERS:
        case SectionKey.ITEM_TYPE:
          const updated = new Set<SavedViewsQuickFilter | ItemType>(prevQueryParams?.[key] ?? []);
          if (updated.has(item)) {
            updated.delete(item);
          } else {
            updated.add(item);
          }
          return {
            ...prevQueryParams,
            ...(key === SectionKey.FILTERS && { page: 1 }),
            [key]: Array.from(updated),
          };
        default:
          return prevQueryParams;
      }
    });
  }, []);

  const resetQueryParams = useCallback(() => {
    setQueryParams(DEFAULT_PARAMS);
  }, []);

  return {
    queryParams,
    setQueryParams,
    updateSearchParam,
    resetQueryParams,
    toggleParamItem,
  };
};

const getParamsFromUrl = (search: string): Params => {
  const { filters, itemType, ...rest } = getQueryParams(search);

  return {
    ...rest,
    filters: typeof filters === 'string' ? [filters] : filters || [],
    itemTypes: typeof itemType === 'string' ? [itemType] : itemType || [],
  };
};
