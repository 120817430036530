import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { GetColor } from '../../style/color';
import { ELLIPSIS_ITEM } from './utils/Constants';

interface PaginationItemWrapperProps {
  selected: boolean;
}

const PaginationItemWrapper = styled.div<PaginationItemWrapperProps>`
  display: inline-block;
  margin: 0 5px;
  color: ${GetColor.Grey};

  button,
  button i.fas {
    color: ${(props) => (props.selected ? GetColor.Black : GetColor.Primary.Dark)};
    cursor: ${(props) => (props.selected ? 'inherit' : 'pointer')};
  }
`;

export interface PaginationItemProps {
  item: number | string;
  selected: boolean;
  onClick?: (item: number | string) => void;
}

export class PaginationItem extends PureComponent<PaginationItemProps> {
  handleItemClick = () => {
    this.props.onClick?.(this.props.item);
  };

  render() {
    const { selected, item } = this.props;
    return (
      <PaginationItemWrapper selected={selected}>
        {item === ELLIPSIS_ITEM ? (
          <span>&hellip;</span>
        ) : (
          <button onClick={!selected ? this.handleItemClick : undefined} type="button">
            {item}
          </button>
        )}
      </PaginationItemWrapper>
    );
  }
}
