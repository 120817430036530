import React, { useState } from 'react';
import type { ModalSize } from '../../modal';
import { Modal, ModalFooter, ModalHeader, ModalContent, ModalSubhead } from '../../modal';
import styled from 'styled-components';
import { GetColor, CheckboxWrapper, Body1 } from 'venn-ui-kit';

export interface ConfirmationModalProps {
  header?: string;
  subhead?: string;
  text?: string | React.ReactNode;
  confirmationCheckboxLabel?: string;
  proceedLabel?: string;
  cancelLabel?: string;
  destructive?: boolean;
  onCancel?: () => void;
  onProceed: () => void;
  proceedDisabled?: boolean;
  error?: string;
  rightChildren?: React.ReactNode;
  secondaryLabel?: string;
  onSecondaryClick?: () => void;
  className?: string;
  size?: ModalSize;
  zIndex?: number;
}

const ConfirmationModal = ({
  destructive = false,
  header,
  subhead,
  text,
  confirmationCheckboxLabel,
  proceedLabel = 'Proceed',
  cancelLabel,
  onCancel,
  onProceed,
  proceedDisabled,
  error,
  rightChildren,
  secondaryLabel,
  onSecondaryClick,
  className,
  size,
  zIndex,
}: ConfirmationModalProps) => {
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const requireSecondaryConfirmation = !!confirmationCheckboxLabel;

  return (
    <Modal onClose={onCancel} className={className} size={size} zIndex={zIndex}>
      <ModalHeader extraPadding>{header || 'Confirmation'}</ModalHeader>
      {subhead && <StyledModalSubhead extraPadding>{subhead}</StyledModalSubhead>}
      <StyledModalContent extraPadding hasSubhead={!!subhead}>
        {text}
        {requireSecondaryConfirmation && (
          <CheckboxWrapper
            checked={checkboxChecked}
            onChange={() => setCheckboxChecked(!checkboxChecked)}
            justify="left"
          >
            <Body1>{confirmationCheckboxLabel}</Body1>
          </CheckboxWrapper>
        )}
      </StyledModalContent>
      <ModalFooter
        destructive={destructive}
        onCancel={onCancel}
        primaryLabel={proceedLabel}
        cancelLabel={cancelLabel}
        secondaryLabel={secondaryLabel}
        onSecondaryClick={onSecondaryClick}
        primaryClassName="qa-confirmation-modal"
        onPrimaryClick={onProceed}
        primaryDisabled={proceedDisabled || (requireSecondaryConfirmation && !checkboxChecked)}
        secondaryDisabled={proceedDisabled}
        rightChildren={rightChildren || (error && <Error>{error}</Error>)}
      />
    </Modal>
  );
};

export default ConfirmationModal;

const StyledModalContent = styled(ModalContent)<{ hasSubhead: boolean }>`
  ${({ hasSubhead }) => hasSubhead && 'font-size: 14px;'}
`;

const StyledModalSubhead = styled(ModalSubhead)`
  padding-top: 0;
  white-space: pre-wrap;
`;

const Error = styled.span`
  color: ${GetColor.Error};
`;
