import { useCallback, useEffect, useState } from 'react';

export default function useSelectState<T>(initialSelected: T[]) {
  const [selectedState, setSelectedState] = useState<Set<T>>(new Set(initialSelected));

  useEffect(() => {
    setSelectedState(new Set(initialSelected));
  }, [initialSelected]);

  const resetState = useCallback(() => {
    setSelectedState(new Set(initialSelected));
  }, [initialSelected]);

  const onOptionClick = useCallback((value: T) => {
    setSelectedState((prevState) => {
      const updatedState = new Set(prevState);
      if (prevState.has(value)) {
        updatedState.delete(value);
      } else {
        updatedState.add(value);
      }
      return updatedState;
    });
  }, []);

  const updateSelectedState = useCallback((updated: T[]) => {
    setSelectedState(new Set(updated));
  }, []);

  return {
    selectedState,
    resetState,
    onOptionClick,
    updateSelectedState,
    setSelectedState,
  };
}
