import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useHistory } from 'react-router-dom';
import {
  analyticsService,
  assertNotNil,
  focusUniversalSearch,
  getAnalysisPathForInvestment,
  navigateToReportLab,
  navigateToStudioView,
  useHasFF,
} from 'venn-utils';
import type { AnalysisView } from 'venn-api';
import { TutorialContent } from './TutorialContent';
import { Button, Hint, Link as LinkStyle } from 'venn-ui-kit';

export const STUDIO_SCENARIO_TEMPLATE_ID = '0a9f28a2-fc9f-4420-93b3-7e533795161c';
export const REPORT_LAB_SCENARIO_TEMPLATE_ID = 'b90444df-e391-4638-a9e5-7678bb2dfd3b';

export const MacroScenarioTab = () => {
  const { Images } = useContext(ThemeContext);
  const history = useHistory();
  const hasStudio = useHasFF('studio_ff');
  const hasReportLab = useHasFF('studio_report_editor');

  const onNavigateToView = (id: string, isReport: boolean) => {
    const document = { id, analysisViewType: isReport ? 'REPORT' : 'TEARSHEET' } as AnalysisView;

    analyticsService.ctaClicked({
      destination: isReport ? 'Report Lab' : 'Studio',
      filled: true,
      locationOnPage: 'Home tab 3.0 (scenario)',
      purpose: 'Discover Scenario Analysis',
      text: isReport ? 'Explore in report lab' : 'Explore in studio',
      type: 'button',
    });

    if (isReport) {
      navigateToReportLab(history, { newDocument: document, id });
    } else {
      navigateToStudioView(history, { newDocument: document, id });
    }
  };

  return (
    <TutorialContent
      headline="Ever wonder how your investments would behave under a higher 3-month interest rate?"
      images={[assertNotNil(Images.macroScenario)]}
    >
      <Hint>
        See expected returns for macroeconomic scenarios like U.S. unemployment and industrial production, inflation
        (CPI), and 3-month interest rates. Try it out now under the <b>Sensitivity Analysis</b> block in Studio and
        Report Lab!
      </Hint>
      <div>
        {hasStudio && (
          <Button
            dominant
            onClick={() => onNavigateToView(STUDIO_SCENARIO_TEMPLATE_ID, false)}
            className="qa-check-it"
            data-testid="qa-check-it"
          >
            Explore in studio
          </Button>
        )}
        {hasReportLab && (
          <Button
            dominant
            onClick={() => onNavigateToView(REPORT_LAB_SCENARIO_TEMPLATE_ID, true)}
            className="qa-check-it"
            data-testid="qa-check-it"
          >
            Explore in report lab
          </Button>
        )}
      </div>
    </TutorialContent>
  );
};

export const ScenarioTab = ({ fundId }: { fundId?: string }) => {
  const { Images } = useContext(ThemeContext);
  const history = useHistory();

  return (
    <TutorialContent
      headline="See expected return for your investments under different market conditions"
      images={[assertNotNil(Images.scenario)]}
    >
      <Hint>
        See expected returns for different market conditions or{' '}
        <LinkStyle>
          <button type="submit" onClick={focusUniversalSearch} className="qa-home-search-for-anything">
            search
          </button>
        </LinkStyle>{' '}
        to analyze anything.
      </Hint>
      <div>
        <Button
          dominant
          disabled={!fundId}
          onClick={() => {
            history.push(getAnalysisPathForInvestment(fundId!, 'sensitivity'));
            analyticsService.ctaClicked({
              destination: 'Analysis Results',
              filled: true,
              locationOnPage: 'Home tab 3.0 (scenario)',
              purpose: 'Discover Scenario Analysis',
              text: 'Check it out',
              type: 'button',
            });
          }}
          className="qa-check-it"
          data-testid="qa-check-it"
        >
          Check it out
        </Button>
      </div>
    </TutorialContent>
  );
};
