/*
 * Venn Platform API 1.0.0 Operations for "featureavailability"
 * Automatically generated file - do not modify!
 */

import type { UserFeature } from '../types';
import type { OperationResult } from '../../utils';
import { buildRequestInit, extractError, retryGeneratedFetch } from '../../utils';

/** Get all features visible to a user */
export async function getVisibleFeaturesForUser(
  signal?: AbortSignal,
): Promise<OperationResult<{ [key: string]: UserFeature }>> {
  const endpoint = '/api/featureavailability';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: { [key: string]: UserFeature } = (await response.json()) as { [key: string]: UserFeature };
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
