import React, { forwardRef } from 'react';
import type { ShimmerBlockProps } from 'venn-ui-kit';
import { ShimmerBlock } from 'venn-ui-kit';
import { useAppPrintMode } from '../../print/AppPrintMode';

const StudioShimmerBlock = (
  props: ShimmerBlockProps & React.HTMLAttributes<HTMLDivElement>,
  ref: React.Ref<HTMLDivElement>,
) => {
  const { inPrintMode } = useAppPrintMode();
  return inPrintMode ? null : (
    <ShimmerBlock ref={ref} role="progressbar" aria-valuetext="block loading" aria-busy {...props} />
  );
};

export default forwardRef(StudioShimmerBlock);
