import React, { useState } from 'react';
import styled from 'styled-components';
import type { OptionType } from 'venn-ui-kit';
import { Icon, GetColor, FullWidthMenuItem, TriggerableMenuLeft } from 'venn-ui-kit';
import type { GeneralAnalysisTemplate } from 'venn-api';
import { ConfirmationModal } from 'venn-components';
import { compact } from 'lodash';

interface TemplateMenuState {
  open: boolean;
  deleting: boolean;
  deleteTemplateModalOpen: boolean;
}

interface TemplateMenuProps {
  template: GeneralAnalysisTemplate;
  templateDeleted?: (template: GeneralAnalysisTemplate) => void;
  editTemplate?: (template: GeneralAnalysisTemplate) => void;
}

const CONFIRMATION_MESSAGE = 'Are you sure you wish to delete this custom template?';

const TemplateMenu = ({ editTemplate, template, templateDeleted }: TemplateMenuProps) => {
  const [state, setState] = useState<TemplateMenuState>({
    open: false,
    deleting: false,
    deleteTemplateModalOpen: false,
  });

  const { deleting, deleteTemplateModalOpen, open } = state;

  const toggleOpen = () => {
    setState((prevState) => ({
      ...prevState,
      open: !prevState.open,
    }));
  };

  const onCancelDelete = () => {
    setState((prevState) => ({
      ...prevState,
      deleteTemplateModalOpen: false,
    }));
  };

  const onDelete = (e: React.MouseEvent<HTMLDivElement>) => {
    if (deleting) {
      e.stopPropagation();
      return;
    }
    setState((prevState) => ({
      ...prevState,
      deleteTemplateModalOpen: true,
    }));
  };

  const onDeleteClick = () => {
    if (!templateDeleted) {
      return;
    }

    templateDeleted(template);
    setState((prevState) => ({
      ...prevState,
      deleteTemplateModalOpen: false,
    }));
  };

  const options: OptionType[] = compact([
    editTemplate
      ? {
          children: <FullWidthMenuItem label="Edit Template" onClick={() => editTemplate(template)} />,
        }
      : null,
    templateDeleted
      ? {
          children: <FullWidthMenuItem label="Delete" disabled={deleting} loading={deleting} onClick={onDelete} />,
        }
      : null,
  ]);

  const confirmationHeader = CONFIRMATION_MESSAGE;
  const confirmationCheckboxLabel = 'I understand that all saved views using this template will also be deleted.';

  return (
    <span>
      <TriggerableMenuLeft options={options} onToggle={toggleOpen}>
        <StyledIcon
          type="chevron-circle-down"
          prefix={open ? 'fas' : 'far'}
          className={open ? 'open template-menu-trigger' : 'template-menu-trigger'}
        />
      </TriggerableMenuLeft>
      {deleteTemplateModalOpen ? (
        <ConfirmationModal
          subhead={confirmationHeader}
          text=""
          confirmationCheckboxLabel={confirmationCheckboxLabel}
          destructive
          onCancel={onCancelDelete}
          onProceed={onDeleteClick}
        />
      ) : null}
    </span>
  );
};

const StyledIcon = styled(Icon)`
  cursor: pointer;
  color: ${GetColor.Grey};

  &.open {
    color: ${GetColor.Black};
  }
`;
export default TemplateMenu;
