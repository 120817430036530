import React, { useCallback, useContext } from 'react';
import { PanelLabel } from '../../../../shared';
import { SliderToggle, UserContext } from 'venn-components';
import { useRecoilState } from 'recoil';
import { blockContributionToPercentage } from 'venn-state';

interface PercentageContributionSettingsProps {
  selected: string;
}

export const PercentageContributionSettings = ({ selected }: PercentageContributionSettingsProps) => {
  const { hasPermission } = useContext(UserContext);
  const isReadOnly = !hasPermission('STUDIO_EDIT_FORMAT');
  const [contributionToPercentage, setContributionToPercentage] = useRecoilState(
    blockContributionToPercentage(selected),
  );

  const onTogglePercentage = useCallback(() => {
    setContributionToPercentage((current) => !current);
  }, [setContributionToPercentage]);

  return (
    <>
      <PanelLabel>Percentage Contribution:</PanelLabel>
      <SliderToggle toggled={contributionToPercentage} onToggle={onTogglePercentage} disabled={isReadOnly} />
    </>
  );
};
