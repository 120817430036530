import React from 'react';
import { useCommonGridProps } from '../../logic/columnUtils';
import { DATA_TYPE_EXCEL_STYLES } from '../../logic/customBlockUtils';
import { useIsBlockGridCompact } from '../../logic/useIsBlockGridCompact';
import usePrivateAssetsSummaryGrid from '../../logic/usePrivateAssetsSummaryGrid';
import StyledEmptyState from '../StyledEmptyState';
import ExportableGrid from './ExportableGrid';

type PrivateAssetSummaryGridProps = Readonly<{ selectedRefId: string }>;

const PrivateAssetSummaryGrid = ({ selectedRefId }: PrivateAssetSummaryGridProps) => {
  const { columnDefs, rowData, needsSubject, isExportable } = usePrivateAssetsSummaryGrid(selectedRefId);
  const isCompact = useIsBlockGridCompact();

  const commonGridProps = useCommonGridProps();

  if (needsSubject) {
    return <StyledEmptyState header="Please select a Private Portfolio to analyze" selectedRefId={selectedRefId} />;
  }

  return (
    <ExportableGrid
      {...commonGridProps}
      exportable={isExportable}
      selectedRefId={selectedRefId}
      rowData={rowData}
      columnDefs={columnDefs}
      excelStyles={DATA_TYPE_EXCEL_STYLES}
      isCompact={isCompact}
      tooltipShowDelay={1200}
    />
  );
};

export default PrivateAssetSummaryGrid;
