import { noop } from 'lodash';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { StudioPrintSettingsContext, UserContext } from 'venn-components';
import { StudioIcon } from 'venn-ui-kit';
import { analyticsService, getBlankStudio, MAX_SUBJECT_GROUP_SIZE, navigateToStudioView } from 'venn-utils';
import { StyledButtonIcon } from './styles';
import type { PrivateBulkActionProps } from './types';

export const AnalyzeInStudio = ({ items, onComplete }: PrivateBulkActionProps) => {
  const history = useHistory();
  const { Colors } = useContext(ThemeContext);
  const { addDisclosurePage } = useContext(StudioPrintSettingsContext);
  const { currentContext } = useContext(UserContext);

  const navigate = () => {
    const newDocument = getBlankStudio(
      'TEARSHEET',
      addDisclosurePage,
      items.map((item) =>
        item.itemType === 'PORTFOLIO' ? { privatePortfolioId: item.id } : { privateFundId: item.id },
      ),
      currentContext,
    );

    analyticsService.creatingNewStudios({
      source: 'add to studio library button',
      name: 'blank tearsheet',
    });
    navigateToStudioView(history, { newDocument });
    onComplete('Analyze in Studio', noop);
  };

  return (
    <StyledButtonIcon
      onClick={navigate}
      customIcon={<StudioIcon color={Colors.Primary.Dark} size={20} hoverHighlight />}
      text={`Analyze in studio (${items.length})`}
      className="qa-navigate-to-studio"
      disabled={items.length > MAX_SUBJECT_GROUP_SIZE}
      tooltip={
        items.length > MAX_SUBJECT_GROUP_SIZE ? `Please select ${MAX_SUBJECT_GROUP_SIZE} investments or less` : ''
      }
    />
  );
};
