import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import type { LegendSeries as SeriesProps } from './Legend';
import { PureNameWithOptionalFee } from './NameWithOptionalFee';
import { Icon } from 'venn-ui-kit';

export interface LegendEntryIndicatorProps {
  color: string;
  height?: number;
}

const LegendEntryWrapper = styled.div<{ fontSize?: string }>`
  font-size: ${({ fontSize }) => fontSize || '12px'};
  font-weight: bold;
  display: flex;
  align-items: center;

  & + div {
    margin-left: 20px;
  }
`;

export interface LegendEntryIndicatorWrapperProps {
  borderColor?: string;
  fillColor?: string;
}

const LegendEntryIndicatorWrapper = styled.div<LegendEntryIndicatorWrapperProps>`
  display: flex;
  align-items: center;
  border-radius: 1px;
  height: 14px;
  width: 14px;
  margin-right: 10px;
  ${(props) => props.borderColor && `border: 1px solid ${props.borderColor};`}
  ${(props) => props.fillColor && `background-color: ${props.fillColor};`}
`;

const LegendEntryIndicator = styled.div<LegendEntryIndicatorProps>`
  display: inline-block;

  width: 14px;
  ${(props: LegendEntryIndicatorProps) => `
    background-color: ${props.color};
    height: ${props.height ?? 2}px;
  `};
`;

export const LegendEntry: FC<React.PropsWithChildren<SeriesProps>> = ({
  iconType,
  color = 'white',
  name = '',
  legend,
  borderColor,
  fillColor,
  height,
  fee,
  fontSize,
  feesExcluded,
  hasProxy,
}) => {
  return (
    <LegendEntryWrapper fontSize={fontSize}>
      {iconType && <Icon type={iconType} style={{ color, padding: 4 }} />}
      {!iconType && (
        <LegendEntryIndicatorWrapper
          className="legend-entry-indicator-wrapper"
          borderColor={borderColor}
          fillColor={fillColor}
        >
          <LegendEntryIndicator color={color} height={height} />
        </LegendEntryIndicatorWrapper>
      )}
      {legend || (
        <PureNameWithOptionalFee
          name={name}
          fee={fee ?? 0}
          hasProxy={!!hasProxy}
          feesExcluded={!!feesExcluded}
          supportsFees
        />
      )}
    </LegendEntryWrapper>
  );
};

export default LegendEntry;
