import React, { PureComponent } from 'react';
import { createPortal } from 'react-dom';
import { GetColor, ZIndex } from 'venn-ui-kit';
import type { Portfolio } from 'venn-api';
import styled from 'styled-components';

function isFund(portfolio: Portfolio): boolean {
  return portfolio.fund !== undefined;
}

interface DragOverlayProps {
  initialX: number;
  initialY: number;
  portfolioNode: Portfolio;
}

interface DragOverlayState {
  x: number;
  y: number;
}

class DragOverlay extends PureComponent<DragOverlayProps, DragOverlayState> {
  state = {
    x: this.props.initialX,
    y: this.props.initialY,
  };

  private name?: string = DragOverlay.getStrategyOverlayName(this.props.portfolioNode);

  private listener?: (this: Window, ev: WindowEventMap['mousemove']) => unknown = undefined;

  private defaultNode?: HTMLElement;

  static getStrategyOverlayName = (portfolioNode: Portfolio) => {
    if (isFund(portfolioNode)) {
      return portfolioNode.name;
    }

    const { name, children } = portfolioNode;
    const fundsCount = getFundsCount(children);
    const strategyCount = getStrategyCount(children);

    const fundsString = fundsCount === 1 ? '1 investment' : fundsCount > 1 ? `${fundsCount} investments` : undefined;
    const strategyString =
      strategyCount === 1 ? '1 strategy' : strategyCount > 1 ? `${strategyCount} strategies` : undefined;

    if (fundsString && strategyString) {
      return `${name} (+ ${strategyString} and ${fundsString})`;
    }

    if (fundsString) {
      return `${name} (+ ${fundsString})`;
    }

    if (strategyString) {
      return `${name} (+ ${strategyString})`;
    }

    return name;
  };

  componentDidMount() {
    this.listener = (event) => {
      if (this.defaultNode) {
        this.setState({ x: event.clientX, y: event.clientY });
      }
    };
    window.addEventListener('mousemove', this.listener);
  }

  componentWillUnmount() {
    if (this.listener) {
      // @ts-expect-error: TODO fix strictFunctionTypes
      document.removeEventListener('mousemove', this.listener);
    }
    this.listener = undefined;
    this.name = undefined;

    if (this.defaultNode) {
      document.body.removeChild(this.defaultNode);
    }
    this.defaultNode = undefined;

    document.body.style.cssText = document.body.style.cssText.replace('cursor: move !important;', '');
  }

  public render() {
    if (!this.defaultNode) {
      this.defaultNode = document.createElement('div');
      document.body.appendChild(this.defaultNode);
      document.body.style.cssText = 'cursor: move !important;';
    }

    const { x, y } = this.state;

    return createPortal(
      <Overlay>
        <DraggedNode style={{ transform: `translate(${x - 5}px, ${y - 15}px)` }} data-testid="qa-drag-name">
          {this.name}
        </DraggedNode>
      </Overlay>,
      this.defaultNode,
    );
  }
}

const getFundsCount = (childNodes: Portfolio[]) => {
  if (!childNodes.length) {
    return 0;
  }

  const subNodesCount: number = childNodes
    .filter((node) => !isFund(node))
    .map((node) => getFundsCount(node.children))
    .reduce((a, b) => a + b, 0);

  return childNodes.filter((node) => isFund(node)).length + subNodesCount;
};

const getStrategyCount = (childNodes: Portfolio[]): number => {
  if (!childNodes.length) {
    return 0;
  }

  const subNodesCount = childNodes
    .filter((node) => !isFund(node))
    .map((node) => getStrategyCount(node.children))
    .reduce((a, b) => a + b, 0);

  return childNodes.filter((node) => !isFund(node)).length + subNodesCount;
};

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: ${ZIndex.Cover};
  cursor: move;
  pointer-events: none;
`;

const DraggedNode = styled.div`
  display: inline-flex;
  min-width: 160px;
  min-height: 32px;
  align-items: center;
  padding: 0 10px;
  box-shadow: 0 0 4px 0 rgba(16, 22, 27, 0.4);
  background: ${GetColor.Primary.Main};
  line-height: 120%;
  color: #fff;
  pointer-events: none;

  /* Fix IE align items problem */
  &:after {
    content: '';
    display: block;
    min-height: inherit;
    font-size: 0;
  }
`;

export default DragOverlay;
