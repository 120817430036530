import styled from 'styled-components';
import { Button, Icon, GetColor } from 'venn-ui-kit';

const Wrapper = styled.div`
  border-radius: 4px;
  border: 1px solid ${GetColor.Grey};
  padding: 40px 60px;
`;

export const StyledWrapper = styled(Wrapper)`
  margin-top: 20px;
`;

export const FlexWrapper = styled.div`
  display: flex;
`;

export const InfoWrapper = styled.div`
  margin-left: 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Hint = styled.p`
  font-size: 14px;
  color: ${GetColor.HintGrey};
`;

export const Name = styled.div`
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px;
  margin-right: 11px;
`;

export const Info = styled.div`
  color: ${GetColor.DarkGrey};
  font-size: 14px;
`;

export const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export const StyledButton = styled(Button)`
  height: 40px;
`;

export const SubHeader = styled.p`
  font-size: 14px;
  color: ${GetColor.DarkGrey};
  margin: 0;
  i {
    margin-right: 7px;
  }
`;

export const StyledIcon = styled(Icon)`
  font-size: 20px;
  margin-right: 8px;
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  font-size: 12px;
  font-weight: bold;
  margin: 20px 0;
`;

export const Action = styled.div`
  padding-top: 40px;
  display: flex;
  justify-content: flex-end;
`;

export const LeftAction = styled(Action)`
  justify-content: flex-start;
`;

export const CenterAction = styled(Action)`
  justify-content: center;
  padding: 0 0 10px;
`;

export const Content = styled.div`
  padding: 0 20px;
`;

export const Input = styled.input`
  border-radius: 4px;
  width: 100%;
  font-weight: normal;
  padding: 9px;
`;

export const Error = styled.div`
  color: ${GetColor.DEPRECATED_DivergingColor.A3};
  font-size: 12px;
`;
