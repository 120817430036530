import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import type { History } from 'history';
/* We use a custom version of this function from venn-api so we can control the relative path */
/* This is required for use on the marketing website */
import { analyticsService, VennEvents, withSuspense } from 'venn-utils';
import CookiePreferencesModal from './components/CookiePreferencesModal';
import CookieBannerContent from './components/CookieBannerContent';
import { domain, getDate90DaysFromToday } from './utils';
import isNil from 'lodash/isNil';
import { COOKIE_SETTINGS, CookiesAllowed, deleteAdvertisingCookies, isTrackingBlockedBySignal } from './CookiesCommon';
import { useRemoteCookieAcceptPolicy } from './logic/useRemoteCookieAcceptPolicy';

export const useAdvertisingTrackingAllowed = () => {
  const [cookies] = useCookies([COOKIE_SETTINGS]);
  // If the user is not required to have preferences or the user has not specified a category that excludes
  // advertising tracking
  return (
    ![CookiesAllowed.FunctionalOnly, CookiesAllowed.None].includes(parseInt(cookies[COOKIE_SETTINGS], 10)) &&
    !isTrackingBlockedBySignal()
  );
};

export const useFunctionalTrackingAllowed = () => {
  const [cookies] = useCookies([COOKIE_SETTINGS]);
  // If the user is not required to have preferences or the user has not specified a category that excludes
  // functional tracking
  return ![CookiesAllowed.AdvertisingOnly, CookiesAllowed.None].includes(parseInt(cookies[COOKIE_SETTINGS], 10));
};

type CookieBannerProps = {
  history?: History;
};

export const CookieBanner = ({ history }: CookieBannerProps) => {
  const mountedRef = useRef<boolean>(false);
  const [acknowledged, setAcknowledged] = useState(false);
  const isOpenInitially = history?.location?.pathname?.includes('cookie-manager');
  const [showCookiePreferencesModal, setShowCookiePreferencesModal] = useState(isOpenInitially);
  const [cookies, setCookie] = useCookies([COOKIE_SETTINGS]);
  const [remoteAcceptCookiePolicy, setRemoteUpdateCookiePolicy] = useRemoteCookieAcceptPolicy();

  const functionalTrackingAllowedByCookie = useFunctionalTrackingAllowed();

  if (!remoteAcceptCookiePolicy.setCookiePolicy && isTrackingBlockedBySignal()) {
    setRemoteUpdateCookiePolicy(
      functionalTrackingAllowedByCookie ? CookiesAllowed.FunctionalOnly : CookiesAllowed.None,
    );
  }

  const advertisingTrackingAllowed = remoteAcceptCookiePolicy.consentedCookieTypes.includes('ADVERTISING');

  useEffect(() => {
    if (!advertisingTrackingAllowed) {
      deleteAdvertisingCookies();
    }
  }, [advertisingTrackingAllowed]);

  const dispatchFunctionalAcceptedEvent = () => {
    const functionalEvent = new CustomEvent(VennEvents.functionalCookiesAccepted);
    document.dispatchEvent(functionalEvent);
  };
  const dispatchAdvertisingAcceptedEvent = () => {
    const advertisingEvent = new CustomEvent(VennEvents.advertisingCookiesAccepted);
    document.dispatchEvent(advertisingEvent);
  };

  const dispatchAcceptedEvent = useCallback(() => {
    dispatchAdvertisingAcceptedEvent();
    dispatchFunctionalAcceptedEvent();
  }, []);

  const accept = useCallback(() => {
    setAcknowledged(true);
    const expires = getDate90DaysFromToday();
    const cookieOpts = { domain, expires, path: '/' };
    // Set cookies for both their acknowledgement and for the enabling of cookies
    setRemoteUpdateCookiePolicy(CookiesAllowed.All);
    setCookie(COOKIE_SETTINGS, CookiesAllowed.All, cookieOpts);
    analyticsService.cookiePreferencesModified({
      cookiesAllowed: ['required', 'functional', 'advertising'],
    });
    analyticsService.ctaClicked({
      text: 'Accept All',
      locationOnPage: 'EU Cookie Banner',
      type: 'button',
      filled: true,
      purpose: 'Allow all cookie types and acknowledge banner',
    });
    dispatchAcceptedEvent();
  }, [dispatchAcceptedEvent, setCookie, setRemoteUpdateCookiePolicy]);

  const toggleShowCookiePreferences = () => {
    if (!showCookiePreferencesModal) {
      analyticsService.ctaClicked({
        text: 'Cookie Preferences Manager',
        locationOnPage: 'EU Cookie Banner',
        type: 'link',
        purpose: 'Open Cookie Preferences Manager',
      });
    }
    setShowCookiePreferencesModal((prev) => !prev);
  };

  // Convert cookies to integers for enum comparison
  const cookieSettingForUser = !isNil(cookies[COOKIE_SETTINGS]) ? parseInt(cookies[COOKIE_SETTINGS], 10) : undefined;

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  });

  useEffect(() => {
    if (!isOpenInitially) {
      // First check cookies to see if the user has already accepted
      if (cookieSettingForUser === CookiesAllowed.All) {
        setAcknowledged(true);
        dispatchAcceptedEvent();
        // User who has allowed only functional cookies
      } else if (cookieSettingForUser === CookiesAllowed.FunctionalOnly) {
        setAcknowledged(true);
        dispatchFunctionalAcceptedEvent();
        // User who has allowed only advertising cookies
      } else if (cookieSettingForUser === CookiesAllowed.AdvertisingOnly) {
        setAcknowledged(true);
        dispatchAdvertisingAcceptedEvent();
        // User who has declined all cookies
      } else if (cookieSettingForUser === CookiesAllowed.None) {
        setAcknowledged(true);
      }
    }
  }, [cookieSettingForUser, cookies, dispatchAcceptedEvent, isOpenInitially]);

  if (acknowledged) {
    return null;
  }

  if (showCookiePreferencesModal) {
    return (
      <CookiePreferencesModal originalCookiePreferences={cookieSettingForUser} onClose={toggleShowCookiePreferences} />
    );
  }

  return <CookieBannerContent displayPreferences={toggleShowCookiePreferences} onAccept={accept} />;
};

export default withSuspense(null, CookieBanner);
