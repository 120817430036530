import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import type { AuthPageProps } from './components';
import {
  AuthButton,
  AuthButtonGroup,
  AuthenticationFooter,
  AuthLoader,
  AuthLogo,
  AuthMessage,
  AuthFormErrors,
  AuthValidators,
  ContentWrapper,
} from './components';
import { Routes } from 'venn-utils';
import type { FormValues } from 'venn-components';
import { FormInput, useForm, createField } from 'venn-components';
import { getAppTitle } from 'venn-ui-kit';

const { required } = AuthValidators;

const emailValidator = AuthValidators.email;

export const ForgotPasswordRoute = '/forgot-password';

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
`;

const StyledMessage = styled(AuthMessage)`
  margin: 30px 0;
  font-size: 16px;
`;

const ForgotPassword = ({ onSubmit, pending, error, history }: AuthPageProps) => {
  const historyRef = useRef(history);
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      await onSubmit({ email: values.email, password: '' });
      setSubmitted(true);
    },
    [onSubmit],
  );

  const [fields, isValid, isSubmitting, submit] = useForm(
    [createField('email', '', [required('Email'), emailValidator])],
    handleSubmit,
  );

  useEffect(() => {
    if (submitted) {
      historyRef.current.push(Routes.SIGN_IN_PATH);
    }
  }, [submitted, history]);

  const disabled = !isValid || isSubmitting;

  return (
    <>
      <ContentWrapper isLoading={!!pending}>
        {pending && <AuthLoader title="Loading" />}
        {!pending && (
          <FormWrapper>
            <AuthLogo />
            <form onSubmit={submit}>
              <StyledMessage>{`Submit the email address you use on ${getAppTitle()} to reset your password.`}</StyledMessage>
              <FormInput inputId="email" label="Email" {...fields.email} />
              <AuthFormErrors error={error?.message} />
              <AuthButtonGroup>
                <Link to={Routes.SIGN_IN_PATH}>
                  <AuthButton type="button">Cancel</AuthButton>
                </Link>
                <AuthButton dominant disabled={disabled} type="submit">
                  Submit
                </AuthButton>
              </AuthButtonGroup>
            </form>
          </FormWrapper>
        )}
      </ContentWrapper>
      <AuthenticationFooter />
    </>
  );
};

export default ForgotPassword;
