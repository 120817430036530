import type { HoldingsCategory } from 'venn-api';

export const mockHoldingsSectorCategoriesData: HoldingsCategory[] = [
  {
    id: {
      id: 'EQUITY',
    },
    categoryName: 'Equity',
    children: [
      {
        id: {
          id: '796',
        },
        categoryName: 'Cyclical',
        children: [
          {
            id: {
              id: '797',
            },
            categoryName: 'Basic Materials',
            children: [],
          },
          {
            id: {
              id: '818',
            },
            categoryName: 'Consumer Cyclical',
            children: [],
          },
          {
            id: {
              id: '851',
            },
            categoryName: 'Financial Services',
            children: [],
          },
          {
            id: {
              id: '873',
            },
            categoryName: 'Real Estate',
            children: [],
          },
        ],
      },
      {
        id: {
          id: '888',
        },
        categoryName: 'Defensive',
        children: [
          {
            id: {
              id: '889',
            },
            categoryName: 'Consumer Defensive',
            children: [],
          },
          {
            id: {
              id: '908',
            },
            categoryName: 'Healthcare',
            children: [],
          },
          {
            id: {
              id: '927',
            },
            categoryName: 'Utilities',
            children: [],
          },
        ],
      },
      {
        id: {
          id: '936',
        },
        categoryName: 'Sensitive',
        children: [
          {
            id: {
              id: '937',
            },
            categoryName: 'Communication Services',
            children: [],
          },
          {
            id: {
              id: '948',
            },
            categoryName: 'Energy',
            children: [],
          },
          {
            id: {
              id: '959',
            },
            categoryName: 'Industrials',
            children: [],
          },
          {
            id: {
              id: '994',
            },
            categoryName: 'Technology',
            children: [],
          },
        ],
      },
    ],
  },
  {
    id: {
      id: 'BOND',
    },
    categoryName: 'Fixed Income',
    children: [
      {
        id: {
          id: '1321',
        },
        categoryName: 'Cash And Equivalents',
        children: [
          {
            id: {
              id: '1351',
            },
            categoryName: 'Cash And Equivalents',
            children: [],
          },
        ],
      },
      {
        id: {
          id: '1310',
        },
        categoryName: 'Corporate',
        children: [
          {
            id: {
              id: '1311',
            },
            categoryName: 'Bank Loan',
            children: [],
          },
          {
            id: {
              id: '1312',
            },
            categoryName: 'Convertible',
            children: [],
          },
          {
            id: {
              id: '1313',
            },
            categoryName: 'Corporate Bond',
            children: [],
          },
          {
            id: {
              id: '1314',
            },
            categoryName: 'Preferred',
            children: [],
          },
        ],
      },
      {
        id: {
          id: '1322',
        },
        categoryName: 'Derivative',
        children: [
          {
            id: {
              id: '1324',
            },
            categoryName: 'Future Forward',
            children: [],
          },
          {
            id: {
              id: '1325',
            },
            categoryName: 'Option Warrant',
            children: [],
          },
          {
            id: {
              id: '1323',
            },
            categoryName: 'Swap',
            children: [],
          },
        ],
      },
      {
        id: {
          id: '1348',
        },
        categoryName: 'Government',
        children: [
          {
            id: {
              id: '1305',
            },
            categoryName: 'Government',
            children: [],
          },
          {
            id: {
              id: '1306',
            },
            categoryName: 'Government Related',
            children: [],
          },
        ],
      },
      {
        id: {
          id: '1307',
        },
        categoryName: 'Municipal',
        children: [
          {
            id: {
              id: '1308',
            },
            categoryName: 'Municipal Taxable',
            children: [],
          },
          {
            id: {
              id: '1309',
            },
            categoryName: 'US Municipal Tax Advantaged',
            children: [],
          },
        ],
      },
      {
        id: {
          id: '1315',
        },
        categoryName: 'Securitized',
        children: [
          {
            id: {
              id: '1316',
            },
            categoryName: 'Agency Mortgage Backed',
            children: [],
          },
          {
            id: {
              id: '1320',
            },
            categoryName: 'Asset Backed',
            children: [],
          },
          {
            id: {
              id: '1318',
            },
            categoryName: 'Commercial Mortgage Backed',
            children: [],
          },
          {
            id: {
              id: '1319',
            },
            categoryName: 'Covered Bond',
            children: [],
          },
          {
            id: {
              id: '1317',
            },
            categoryName: 'Non Agency Residential Mortgage Backed',
            children: [],
          },
        ],
      },
    ],
  },
  {
    id: {
      id: 'unknown',
    },
    categoryName: '',
    children: [
      {
        id: {
          id: 'UNKNOWN',
        },
        categoryName: 'Unknown',
        children: [],
      },
    ],
  },
];
