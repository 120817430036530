import React from 'react';
import { useCookies } from 'react-cookie';
import styled from 'styled-components';
import { COOKIE_SETTINGS, CookiePreferencesTrigger, LocalizedFooterLink } from 'venn-components';
import { GetColor, getTextThemeProvider, ZIndex } from 'venn-ui-kit';
import AuthenticationFooterAttribution from './AuthenticationFooterAttribution';
import isNil from 'lodash/isNil';

const StyledFooter = styled('footer')`
  margin-top: 150px;
  padding: 56px 60px;
  background-color: ${GetColor.DarkGrey};
  transform: translate3d(0, 0, 0);
  z-index: ${ZIndex.Front};
  position: relative;

  @media print {
    display: none;
  }
`;

const StyledAttributionWrapper = styled('div')`
  margin: 0 auto;
`;

const StyledAttribution = styled(AuthenticationFooterAttribution)`
  padding-top: 10px;
  color: ${GetColor.White};
`;
const StyledLinks = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
`;
const StyledList = styled.ul`
  float: right;
  list-style-type: none;
  padding: 0px;
  margin: 0px;
  li {
    display: inline-block;
    margin-left: 10px;
    height: 16px;
  }
`;

export const AuthenticationFooter = () => {
  // Ensure we only render cookie links for EU-users who have cookie preferences set
  const [cookies] = useCookies([COOKIE_SETTINGS]);
  const showCookieFooter = !isNil(cookies[COOKIE_SETTINGS]);
  const cookiePolicy = getTextThemeProvider().legalDocuments.COOKIE_POLICY;
  return (
    <StyledFooter>
      {showCookieFooter ? (
        <StyledLinks>
          <nav>
            <StyledList>
              <li>
                <LocalizedFooterLink link={cookiePolicy} />
              </li>
              <CookiePreferencesTrigger />
            </StyledList>
          </nav>
        </StyledLinks>
      ) : null}
      <StyledAttributionWrapper>
        <StyledAttribution />
      </StyledAttributionWrapper>
    </StyledFooter>
  );
};

export default AuthenticationFooter;
