import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  type BlockId,
  blockLoadingState,
  DEFAULT_BLOCK_HEIGHT,
  studioPrintOrientationType,
  viewPages,
} from 'venn-state';
import React, { useEffect, useState } from 'react';
import StudioShimmerBlock from './StudioShimmerBlock';
import { useBlockId } from '../contexts/BlockIdContext';
import { Body1, ZIndex } from 'venn-ui-kit';
import styled, { css, useTheme } from 'styled-components';
import { Grid } from 'react-loader-spinner';
import { useBlockWidth } from '../contexts/BlockWidthContext';
import { calcGridColWidth, dimensions, RL_GRID_ROW_HEIGHT } from '../../utils/studioPage';

export const useBlockIsSmall = (id: BlockId) => {
  const printOrientationType = useRecoilValue(studioPrintOrientationType);
  const pages = useRecoilValue(viewPages);
  const blockWidth = useBlockWidth();
  const gridSettings = dimensions[printOrientationType].gridSettings;

  const STUDIO_ROW_HEIGHT = RL_GRID_ROW_HEIGHT;
  const STUDIO_BLOCK_WIDTH = calcGridColWidth(gridSettings);

  const layout = pages.flatMap((p) => p.layout).find((l) => l.i === id);
  const width = layout ? layout.w * STUDIO_BLOCK_WIDTH : blockWidth.raw.innerWidth;
  const height = layout ? layout.h * STUDIO_ROW_HEIGHT : DEFAULT_BLOCK_HEIGHT;

  return width < 400 || height < 200;
};

export const BlockSuspenseFallback = () => {
  const theme = useTheme();
  const id = useBlockId();
  const small = useBlockIsSmall(id);
  const setLoading = useSetRecoilState(blockLoadingState(id));
  const [showSlowBlockSpinner, setShowBlockSpinner] = useState(false);

  useEffect(() => {
    setLoading(true);
    const timeId = setTimeout(() => {
      setShowBlockSpinner(true);
    }, 3000);

    return () => {
      setLoading(false);
      setShowBlockSpinner(false);
      clearTimeout(timeId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StudioShimmerBlock height={DEFAULT_BLOCK_HEIGHT} width="100%">
      {showSlowBlockSpinner && (
        <SpinnerFadeInWrapper>
          <Grid
            visible
            height={small ? 16 : 64}
            width={small ? 16 : 64}
            color={theme.Colors.HighlightLight}
            ariaLabel="grid-loading"
            radius="12.5"
            wrapperClass="grid-wrapper"
          />
          <StyledLabel small={small}>Thank you for your patience while the analysis is loading...</StyledLabel>
        </SpinnerFadeInWrapper>
      )}
    </StudioShimmerBlock>
  );
};

const SpinnerFadeInWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: ${ZIndex.Cover};
  height: 100%;
  width: 100%;
  max-height: ${DEFAULT_BLOCK_HEIGHT}px;
  animation: fadein 1s;
  overflow: clip;
`;

const StyledLabel = styled(Body1)<{ small: boolean }>`
  ${({ small }) =>
    small
      ? css`
          padding-top: 16px;
          font-size: 8px;
        `
      : css`
          padding-top: 36px;
        `}
`;
