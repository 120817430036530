import React from 'react';
import type { ReleaseNoteContent } from 'venn-api';
import Markdown from '../../markdown/Markdown';
import { getAppTitle, GetColor } from 'venn-ui-kit';
import styled from 'styled-components';

interface ReleaseNoteProps {
  content: ReleaseNoteContent;
}

const ReleaseNote = ({ content }: ReleaseNoteProps) => {
  const date = new Date(content.publishDate).toLocaleDateString();
  return (
    <Wrapper>
      <MarkdownDate>{date}</MarkdownDate>
      <Markdown source={content.markdown} />
      <ReleaseNoteDisclaimer>
        <ReleaseNoteDisclaimerHeader>Important Disclaimer and Disclosure Information:</ReleaseNoteDisclaimerHeader>
        <ReleaseNoteDisclaimerBody>{`This release log is intended to notify subscribers of certain updates, fixes and various other changes to ${getAppTitle()} (collectively, “releases”).  As an overview, it does not discuss all new or important releases or all important or relevant details about releases that are disclosed.  For example, many software, hardware, data, research and other errors, omissions, imperfections and malfunctions will not be disclosed, addressed or fixed.  Notification about one instance or type of release does not require or imply that Two Sigma Investor Solutions, LP (“TSIS”) will provide notice of any additional or further release of the same or similar nature.  TSIS may provide additional proactive notice to certain, but not all, Subscribers.  Subscribers are encouraged to contact TSIS should they have any follow-up questions or concerns and are reminded to review TSIS’s Form ADV and applicable legal agreements for important disclosures, including disclosures about the limitations of ${getAppTitle()}`}</ReleaseNoteDisclaimerBody>
      </ReleaseNoteDisclaimer>
    </Wrapper>
  );
};

export default ReleaseNote;

const ReleaseNoteDisclaimer = styled.div`
  padding-top: 30px;
`;

const ReleaseNoteDisclaimerHeader = styled.div`
  font-weight: bold;
  font-size: 14px;
  padding-bottom: 5px;
`;

const ReleaseNoteDisclaimerBody = styled.div`
  font-size: 12px;
`;

const MarkdownDate = styled.div`
  font-size: 14px;
  font-weight: normal;
  color: ${GetColor.DarkGrey};
  text-align: right;
`;

const Wrapper = styled.div`
  border-bottom: 1px solid ${GetColor.Grey};
  padding-bottom: 20px;
  margin-bottom: 20px;

  h1,
  h2,
  h3 {
    font-weight: 300;
  }
`;
