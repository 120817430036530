import { isNil, sortBy } from 'lodash';
import { useMemo } from 'react';
import type { ReturnsGridRow, MetricRowData } from '../../../types';
import { convertToAnnualReturns } from '../../grid/returnsGridConverter';
import type { ReturnsGridProps } from './returnsGridParserHelper';

export type ReturnsGridRows = ReturnsGridRow | MetricRowData;

/** Generates rows of returns data for calendar/period based returns grids. */
export const useReturnsGridData = (returnsGridData: ReturnsGridProps): ReturnsGridRow[] => {
  const { returnsFrequency, matchingAnalysis, selectedMetric } = returnsGridData;

  /** We only care about the first subject because returns grid should be a single subject block. */
  const RETURNS_GRID_SUBJECT_INDEX = 0;
  const returnsGridMap = matchingAnalysis.returnsGrids?.[RETURNS_GRID_SUBJECT_INDEX]?.returnsGridMap;

  return useMemo(() => {
    const annualReturns = convertToAnnualReturns(returnsGridMap, returnsFrequency);

    /**
     * Backend doesn't actually apply the analysis range directly to our data for calendar returns, because of {@link https://twosigma.atlassian.net/browse/VENN-20659 VENN-20659}.
     *
     * We intentionally include a full year's data even if some of that data is is out of range, because otherwise the year total would be inaccurate
     * and confusing. We do use styling to indicate that the data is out of range when it does get included.
     */
    const filteredReturns = annualReturns.filter((annualReturn) =>
      annualReturn.returns.some((returnsData) => !isNil(returnsData) && returnsData.isInRange),
    );

    // We want rows with more recent years at the top of the table.
    const descendingFilteredReturns = sortBy(filteredReturns, (ar) => ar.year).reverse();

    return descendingFilteredReturns.map((annualReturn) => ({
      kind: 'ReturnsGridRow' as const,
      key: `${annualReturn.year}`,
      label: `${annualReturn.year}`,
      value: annualReturn.returns,
      type: selectedMetric.dataType,
      total: annualReturn.total,
    }));
  }, [returnsFrequency, returnsGridMap, selectedMetric.dataType]);
};
