import React from 'react';
import { REORDERABLE_LIST_HOVER_CLASS, ShockInput } from 'venn-components';
import type { Scenario } from 'venn-api';
import styled from 'styled-components';
import { Body2, Icon, Warning, TooltipPosition } from 'venn-ui-kit';
import { IconButton } from '../components/shared';
import { useRecoilCallback } from 'recoil';
import { isReportState } from 'venn-state';
import { analyticsService } from 'venn-utils';

interface ScenarioInputProps {
  scenario: Scenario;
  isReadOnly: boolean;
  onChange: (scenario: Scenario) => void;
  onDelete: () => void;
}

export const ScenarioInput = ({ scenario, isReadOnly, onChange, onDelete }: ScenarioInputProps) => {
  const onShockChange = useRecoilCallback(
    ({ snapshot }) =>
      (value: number, rawValue: number) => {
        onChange({ ...scenario, shock: value });
        snapshot.getPromise(isReportState).then((isReport) =>
          analyticsService.scenarioModified({
            entryInRange: rawValue === value,
            entryReturn: rawValue,
            scenarioReturn: value,
            scenarioStd: (value - scenario.mean) / scenario.std,
            scenarioIndexName: scenario.fundName,
            location: isReport ? 'Report Lab' : 'Studio',
            type: scenario.type,
          }),
        );
      },
    [onChange, scenario],
  );

  const onShockDelete = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        onDelete();
        snapshot.getPromise(isReportState).then((isReport) =>
          analyticsService.scenarioDeleted({
            entryInRange: true,
            entryReturn: scenario.shock,
            scenarioReturn: scenario.shock,
            scenarioStd: (scenario.shock - scenario.mean) / scenario.std,
            scenarioIndexName: scenario.fundName,
            location: isReport ? 'Report Lab' : 'Studio',
            type: scenario.type,
          }),
        );
      },
    [onDelete, scenario],
  );

  return (
    <Wrapper>
      <InputRow>
        <Name>{scenario.fundName}</Name>
        <ShockInput
          tooltipPosition={TooltipPosition.Right}
          tooltipOffset={{ left: 18 }}
          disabled={isReadOnly}
          shock={scenario.shock}
          units={scenario.units ?? 'PERCENTAGE'}
          mean={scenario.mean ?? NaN}
          fundName={scenario.fundName}
          onChange={onShockChange}
          setInputRef={undefined}
          shockRange={{
            maxShock: scenario.maxShock,
            maxShockZScore: scenario.maxShockZScore,
            minShock: scenario.minShock,
            minShockZScore: scenario.minShockZScore,
          }}
        />
        <IconButton title="Delete" className={REORDERABLE_LIST_HOVER_CLASS} onClick={onShockDelete}>
          <Icon type="trash" />
        </IconButton>
      </InputRow>
      {scenario.seriesType === 'YOY_DELTA' && (
        <Warning text="Shock value is a percentage point increase (e.g. entering 0.25% increases an original value of 5% to 5.25%)" />
      )}
    </Wrapper>
  );
};

const Name = styled(Body2)`
  flex: 1;
  line-height: 14px;
`;

const Wrapper = styled.div`
  margin: 8px 0;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  width: 100%;
`;

const InputRow = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
`;
