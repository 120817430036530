/*
 * Venn Platform API 1.0.0 Operations for "contact"
 * Automatically generated file - do not modify!
 */

import type { FeatureInformationRequest } from '../types';
import type { OperationResult } from '../../utils';
import { buildRequestInit, extractError } from '../../utils';

/** Request Information on Venn Feature */
export async function requestVennFeatureInfo(
  body?: Partial<FeatureInformationRequest>,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = '/api/contact/request-feature-info';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
