import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { ConfirmationModal, REORDERABLE_LIST_HOVER_CLASS } from 'venn-components';
import { GetColor, Icon } from 'venn-ui-kit';
import { IconButton } from '../../../studio-right-panel/tabs/sections/components/shared';

interface ItemActionsProps {
  customDeleteText?: string;
  onDuplicate?: () => void;
  onDelete?: () => void;
}

type ConfirmationModalActionType = 'DELETE';

const ItemActions = ({ customDeleteText = 'item', onDuplicate, onDelete }: ItemActionsProps) => {
  const [confirmationModal, setConfirmationModal] = useState<ConfirmationModalActionType>();

  const [subhead, text, onProceed] = useMemo(() => {
    switch (confirmationModal) {
      case 'DELETE':
        return [
          `You are about to delete this ${customDeleteText}.`,
          `The configuration for this ${customDeleteText} will be lost permanently.`,
          () => {
            setConfirmationModal(undefined);
            onDelete?.();
          },
        ];
      default:
        return [undefined, undefined, undefined];
    }
  }, [confirmationModal, customDeleteText, onDelete]);

  return (
    <ActionGroup>
      {onDuplicate && (
        <IconButton
          title="Copy"
          onClick={(e) => {
            e.stopPropagation();
            onDuplicate?.();
          }}
          className={REORDERABLE_LIST_HOVER_CLASS}
        >
          <Icon type="clone" />
        </IconButton>
      )}
      {onDelete && (
        <IconButton
          title="Delete"
          onClick={(e) => {
            e.stopPropagation();
            setConfirmationModal('DELETE');
          }}
          className={REORDERABLE_LIST_HOVER_CLASS}
        >
          <Icon type="trash" />
        </IconButton>
      )}
      {confirmationModal && text && onProceed && (
        <ConfirmationModal
          destructive
          text={text}
          subhead={subhead}
          onCancel={() => setConfirmationModal(undefined)}
          onProceed={onProceed}
        />
      )}
    </ActionGroup>
  );
};

export default ItemActions;

export const ActionGroup = styled.div`
  display: flex;
  color: ${GetColor.Primary.Dark};
  column-gap: 6px;
`;
