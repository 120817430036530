import { useMemo } from 'react';
import { sum, sumBy } from 'lodash';
import type { ColDef, ColGroupDef } from 'ag-grid-community';
import { useBlockWidth } from '../contexts/BlockWidthContext';

/**
 * Returns a max width for the label column that prevents intrusion on the minWidths of other columns, and a
 * minWidth that intelligently shrinks as needed down to a minimum.
 */
export const useLabelColumnWidth = <TData>(
  columnDefs: ColGroupDef<TData>[],
  { minMinWidth = 100 }: { minMinWidth?: number } = {},
) => {
  const blockWidth = useBlockWidth().raw.innerWidth;

  const computedMaxWidth = useMemo(() => {
    const columnWidths = sum(columnDefs.map((cd) => sumBy(cd.children, 'minWidth')));

    const countLeafChildren = (cd: (ColDef<TData> | ColGroupDef<TData>)[]): number => {
      // If there are no children, then this is a leaf node, and we return 1
      // If there are children, then we recurse onto the children.
      return sumBy(cd, (c) => ('children' in c && c.children.length ? countLeafChildren(c.children) : 1));
    };
    // Every column has a 1px border, including this one, and additionally the block starts with one.
    const borderWidths = countLeafChildren(columnDefs) + 1 + 1;

    return Math.floor(blockWidth - columnWidths - borderWidths);
  }, [blockWidth, columnDefs]);

  // Don't let maxWidth go below minMinWidth, or else the label column gets crushed and overflow onto hundreds of lines.
  const maxWidth = Math.max(minMinWidth, computedMaxWidth);
  // Set minWidth small and let ag-grid figure out the sizing between minWidth and maxWidth
  const minWidth = minMinWidth;

  return { maxWidth, minWidth };
};
