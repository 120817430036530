import type { FC, CSSProperties, SVGAttributes } from 'react';
import React from 'react';
import { Numbers } from 'venn-utils';

export interface CellProps extends SVGAttributes<SVGTextElement> {
  value?: number | null;
  percentage?: boolean;
  style?: CSSProperties;
}

const Cell: FC<React.PropsWithChildren<CellProps>> = (props: CellProps) => {
  const { value, percentage, style, ...restProps } = props;
  const formatValue = Numbers.createNumberFormatter({
    forceSign: false,
    percentage: !!percentage,
  });
  return (
    <text
      className="qa-factor-summary-value"
      {...restProps}
      style={{
        textAnchor: 'end',
        ...style,
      }}
    >
      {formatValue(value!)}
    </text>
  );
};

export default Cell;
