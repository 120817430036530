import { useCallback } from 'react';
import type { ImageDetails } from 'venn-api';
import { storeImageWithBasePath } from 'venn-api';
import { Notifications, NotificationType } from 'venn-ui-kit';
import { logExceptionIntoSentry } from 'venn-utils';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { studioImageUploading, viewCoverPageImage, viewImages } from 'venn-state';

export const useStudioImages = () => {
  const [images, setImages] = useRecoilState(viewImages);
  const setCoverImage = useSetRecoilState(viewCoverPageImage);
  const [imageUploading, setImageUploading] = useRecoilState(studioImageUploading);

  const uploadReportImage = useCallback(
    async (input: HTMLInputElement | null, coverImage: boolean, onUpload?: (imageId: string) => void) => {
      if (!input || !input.files) {
        return;
      }

      setImageUploading(true);
      const toastId = Notifications.notify('Uploading image', NotificationType.LOADING);
      const image = input.files[0];
      const basePath = 'report';
      await storeImageWithBasePath(image, basePath)
        .then((response) => {
          const imageId = response.content;

          const existingImages = images ?? [];
          const newImage: ImageDetails = {
            id: imageId,
            basePath,
            contentStream: undefined,
            contentType: 'image',
          };

          const newImages = [...existingImages, newImage];
          setImages(newImages);

          if (coverImage) {
            setCoverImage(imageId);
          }
          onUpload?.(imageId);

          setImageUploading(false);
          Notifications.notifyUpdate(toastId, 'Uploaded image', NotificationType.SUCCESS);
        })
        .catch(() => {
          setImageUploading(false);
          Notifications.notifyUpdate(toastId, 'Upload image failed', NotificationType.ERROR);
          logExceptionIntoSentry('Unable to upload image for user');
        });
    },
    [images, setCoverImage, setImageUploading, setImages],
  );

  return {
    imageUploading,
    uploadReportImage,
    images,
  };
};
