import mockFund from './fund';
import mockPortfolio from './portfolio';

const mockSubjectMapper = [
  {
    customBlockType: 'PERFORMANCE_SUMMARY',
    active: true,
    accessFeatures: [],
    contributionToPercentage: false,
    customType: 'UNKNOWN',
    description: 'description',
    hasFactors: false,
    id: 'PERFORMANCE_SUMMARY',
    metrics: [],
    relativeToBenchmark: false,
    title: 'Performance and Risk Summary',
    updated: 0,
  },
  {
    customBlockType: 'GROWTH_SIMULATION',
    active: true,
    accessFeatures: [],
    contributionToPercentage: false,
    customType: 'UNKNOWN',
    description: 'description',
    hasFactors: false,
    id: 'GROWTH_SIMULATION',
    metrics: [],
    relativeToBenchmark: false,
    title: 'Asset Growth Simulation',
    updated: 0,
  },
  {
    customBlockType: 'PAGE_BREAK',
    active: true,
    accessFeatures: [],
    contributionToPercentage: false,
    customType: 'UNKNOWN',
    description: 'description',
    hasFactors: false,
    id: 'PAGE_BREAK',
    metrics: [],
    relativeToBenchmark: false,
    title: 'Page Break',
    updated: 0,
  },
  {
    customBlockType: 'TIMESERIES',
    active: true,
    accessFeatures: [],
    contributionToPercentage: false,
    customType: 'CUMULATIVE_RETURN',
    description: 'description',
    hasFactors: false,
    id: 'TIMESERIES',
    metrics: [],
    relativeToBenchmark: false,
    title: 'Timeseries',
    updated: 0,
  },
];

export const mockStudioApi = () => ({
  analysis: jest.fn(() =>
    Promise.resolve({
      content: {
        analyses: [],
        startTime: new Date('2021/09/01'),
        endTime: new Date('2021/10/01'),
      },
      status: 200,
    }),
  ),
  getFund: jest.fn(() => Promise.resolve({ content: mockFund })),
  getSpecificPortfolioV3: jest.fn(() => Promise.resolve({ content: mockPortfolio })),
  getSystemCustomizedBlockSettings: jest.fn(() =>
    Promise.resolve({
      content: mockSubjectMapper,
    }),
  ),
  getAllFactorLensesForUser: jest.fn(() =>
    Promise.resolve({
      content: [],
    }),
  ),
  getHoldingsCategories: jest.fn(() =>
    Promise.resolve({
      content: [],
    }),
  ),
});
