import type { FC } from 'react';
import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { UserContext, AnalysisContext, withPageByolWatermark } from 'venn-components';
import { getDefaultAnalysisType } from 'venn-utils';
import type { AnalysisRouteParams } from './utils';
import { getParam, getParsedSearch } from './utils';
import AnalysisPage from './components/AnalysisPage';
import AnalysisViewStore from './logic/AnalysisViewStore';

const AnalysisDetailsPage: FC<React.PropsWithChildren<AnalysisRouteParams>> = (props) => {
  const objectType = getParam(props, 'objectType', false);
  const objectId = getParam(props, 'objectId', false);
  const strategyId = getParam(props, 'strategyId', false);
  const { settings, profileSettings } = useContext(UserContext);
  const { templates } = useContext(AnalysisContext);
  const analysisType = getParam(props, 'analysisType');
  const redirectToDefaultAnalysis = () => {
    const defaultAnalysisType = getDefaultAnalysisType(objectType, settings, templates, profileSettings);
    const objectTypeLink = objectType ? `/${objectType}` : '';
    const objectIdLink = objectId ? `/${objectId}` : '';
    let newLink = `/analysis/results/${defaultAnalysisType}${objectTypeLink}${objectIdLink}`;
    if (strategyId) {
      newLink += `/${strategyId}`;
    }
    return (
      <Redirect
        to={{
          pathname: newLink,
          search: props.location?.search,
          state: props.location?.state,
        }}
      />
    );
  };

  if (templates.length === 0) {
    return null;
  }

  if (analysisType === 'default' || !templates.find((template) => template.id === analysisType)) {
    const { savedId } = getParsedSearch(props.history.location.search);
    if (!savedId) {
      return redirectToDefaultAnalysis();
    }
  }
  return (
    <AnalysisViewStore>
      <AnalysisPage {...props} templates={templates} />
    </AnalysisViewStore>
  );
};

export default withPageByolWatermark(AnalysisDetailsPage);
