import React from 'react';
import type { PortfolioCompare } from 'venn-api';
import type { DropMenuItem } from 'venn-ui-kit';
import { asyncNoop } from 'venn-utils';

export interface BenchmarksContextValue {
  benchmarksLoading: boolean;
  benchmarks: PortfolioCompare[];
  benchmarksError?: string;
  onBenchmarkChange: (selectedOption: DropMenuItem) => Promise<void>;
  onBenchmarkCreate: (benchmark: PortfolioCompare) => Promise<void>;
  onUpdateCompositeBenchmark: () => Promise<void>;
  onClearBenchmark: () => Promise<void>;
}

const BenchmarksContext = React.createContext<BenchmarksContextValue>({
  benchmarks: [],
  benchmarksLoading: false,
  benchmarksError: undefined,
  onBenchmarkChange: asyncNoop,
  onBenchmarkCreate: asyncNoop,
  onUpdateCompositeBenchmark: asyncNoop,
  onClearBenchmark: asyncNoop,
} as BenchmarksContextValue);
BenchmarksContext.displayName = 'BenchmarksContext';

export default BenchmarksContext;
