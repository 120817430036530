import type { ReactNode } from 'react';
import React from 'react';
import { EnhancedMetricName } from '../EnhancedMetricName';
import type { Metric } from '../../types';

export const MetricDescription = ({
  metric: { name, description, learnMoreUrl },
  children,
}: {
  metric: Metric;
  children: ReactNode;
}) => {
  if (description || learnMoreUrl) {
    return <EnhancedMetricName name={name} description={description} learnMoreUrl={learnMoreUrl} />;
  }
  return <>{children}</>;
};
