import { useContext, useState } from 'react';
import { AnalysisViewContext } from 'venn-components';
import type { NotablePeriodZipped } from './notablePeriodsColumns';
import { isNil, zipWith } from 'lodash';
import type { NotablePeriod } from 'venn-api';
import type { AnalysesResults, AnalysisConfig } from 'venn-utils';
import { getAnalysisLabels } from 'venn-utils';

function ratioToPercentageChange<T extends number | null | undefined>(ratio: T) {
  return isNil(ratio) ? ratio : (ratio! - 1) * 100;
}

export interface useNotablePeriodChartProps {
  analyses?: AnalysesResults;
  analysisConfig: AnalysisConfig;
  loading?: number[];
}

export const useNotablePeriodChart = ({ analysisConfig, analyses, loading = [] }: useNotablePeriodChartProps) => {
  const { subject, category } = analysisConfig;
  const data = analyses?.results?.notablePeriods;

  const [notablePeriods, setNotablePeriods] = useState(data);

  const { onUpdateAnalysisViewParam } = useContext(AnalysisViewContext);

  const userUploaded = analyses?.subject?.fund?.userUploaded;

  const hasBenchmark = Boolean(notablePeriods?.benchmark && notablePeriods.benchmark.length > 0) && !analyses?.relative;
  const hasComparison = !!analysisConfig?.subject?.hasSecondarySubject;

  const hasCategory =
    analysisConfig.category === 'ON' &&
    subject?.type === 'investment' &&
    (!!category || userUploaded) &&
    !!subject?.categoryGroup;

  const chartData = zipWith<NotablePeriod, NotablePeriod, NotablePeriod, NotablePeriodZipped>(
    notablePeriods?.subject ?? [],
    notablePeriods?.benchmark ?? [],
    notablePeriods?.category ?? [],
    (subjectDataPoint, benchmarkDataPoint, categoryDataPoint) => ({
      subject: ratioToPercentageChange(subjectDataPoint.extremumValue),
      benchmark: ratioToPercentageChange(benchmarkDataPoint?.extremumValue),
      category: ratioToPercentageChange(categoryDataPoint?.extremumValue),
      start: subjectDataPoint?.start,
      end: subjectDataPoint?.extremumDate,
      recovery: subjectDataPoint?.end,
      type: subjectDataPoint?.type,
      name: subjectDataPoint.name,
      id: subjectDataPoint.id,
      loading: loading.includes(subjectDataPoint.id),
    }),
  );

  const labels = getAnalysisLabels(
    analysisConfig.subject?.type,
    analysisConfig.subject?.secondaryLabel,
    analysisConfig.subject?.secondaryPortfolio?.updated,
  );

  return {
    setNotablePeriods,
    notablePeriods,
    subject,
    hasBenchmark,
    hasComparison,
    hasCategory,
    labels,
    onUpdateAnalysisViewParam,
    chartData,
    data,
  };
};
