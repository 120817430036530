import React from 'react';
import { Button } from 'venn-ui-kit';
import ManageInvestmentDataTrigger from './ManageInvestmentDataTrigger';

export interface ManageDataLinkProps {
  fundId?: string;
  label?: string;
  customRenderer?: (onClick: () => void, disabled?: boolean) => React.ReactNode;
  canEditReturn?: boolean;
  disabled?: boolean;
  defaultEditData?: boolean;
}

const ManageDataLink = ({
  fundId,
  canEditReturn,
  label,
  disabled,
  customRenderer,
  defaultEditData,
}: ManageDataLinkProps) => {
  const text = label || 'Manage Data';

  if (!canEditReturn) {
    // We won't show manage data button if it's system funds or composite benchmark
    return null;
  }

  return (
    <ManageInvestmentDataTrigger
      fundId={fundId}
      defaultEditData={defaultEditData}
      customRenderer={(onClick) =>
        customRenderer ? (
          customRenderer(onClick)
        ) : (
          <Button disabled={disabled} onClick={onClick} icon="cog">
            {text}
          </Button>
        )
      }
    />
  );
};

export default ManageDataLink;
