import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import type { CustomFactorForecast } from 'venn-api';
import { GetColor, Subtitle2, Icon, Body1, Headline3 } from 'venn-ui-kit';

import type { BasicTableColumn, BasicTableProps } from '../../../basictable/BasicTable';
import BasicTable from '../../../basictable/BasicTable';
import TestClassNames from '../TestClassNames';
import { FactorLensesContext } from '../../../contexts';
import { getForecastType, getHistoricalPeriod } from '../components/FactorForecastsContainerV2';
import { getColumnsUsingState } from '../components/specificFactorForecastColumnsUtils';
import { useRecoilState } from 'recoil';
import type { PartialFactorForecast, FactorNameType } from 'venn-state';
import {
  factorCurrentPendingOverrideAtom,
  factorEditValueBufferAtom,
  factorTextboxIsEditingAtom,
  getTableDataForForecast,
  isSystemForecast,
  useResetFactorOverrideState,
} from 'venn-state';

type SpecificForecastViewV2Props = {
  forecast: CustomFactorForecast;
  onCancel: () => void;
};

const SpecificForecastViewV2 = ({ forecast, onCancel }: SpecificForecastViewV2Props) => {
  const factorContext = useContext(FactorLensesContext);
  const tableData: PartialFactorForecast[] = useMemo(
    () =>
      getTableDataForForecast({
        ...forecast,
      }),
    [forecast],
  );
  const [factorTextboxIsEditing, setFactorTextboxIsEditing] = useRecoilState(factorTextboxIsEditingAtom);
  const [factorCurrentPendingOverride, setFactorCurrentPendingOverride] = useRecoilState(
    factorCurrentPendingOverrideAtom,
  );
  const [factorEditValueBuffer, setFactorEditValueBuffer] = useRecoilState(factorEditValueBufferAtom);
  const [hoveredFactor, setHoveredFactor] = useState<FactorNameType | undefined>();

  const getColumns = getColumnsUsingState({
    factorTextboxIsEditing,
    setFactorTextboxIsEditing,
    factorCurrentPendingOverride,
    setFactorCurrentPendingOverride,
    factorEditValueBuffer,
    setFactorEditValueBuffer,
    hoveredFactor,
    setHoveredFactor,
  });

  /**
   * `systemContext` is the context this forecast is creating,
   * i.e. Historical Forecast creates `HISTORICAL_FORECASTS` context.
   *
   * `forecastContext` is the context this forecast's missing factor forecasts are backfilled with,
   * i.e. user-created forecast can forecasts for some factors, but others are backfilled using Historical Forecast.
   *
   * Since factor forecasts of Historical Forecast are coming from... Historical Forecast (obviously),
   * it's `forecastContext` is also `HISTORICAL_FORECASTS`. However, we don't want to show the markers
   * in the table or the legend, because the context is the same as the forecast that's being viewed.
   */
  const hasContextMarker = forecast.forecastContext && forecast.forecastContext !== forecast.systemContext;

  const isForecastEditable = !isSystemForecast(forecast);

  const { resetAllState } = useResetFactorOverrideState(tableData);

  useEffect(() => {
    resetAllState();
    return () => resetAllState();
  }, [forecast, resetAllState]);

  return (
    <div data-testid="qa-specific-forecast-view">
      <ForecastsHeadline>Forecasts</ForecastsHeadline>
      <Row>
        <Subtitle2>{forecast.name}</Subtitle2>
        <IconButton
          data-testid="qa-exit-forecast-view-button"
          type="button"
          onClick={() => {
            onCancel();
            resetAllState();
          }}
        >
          <Icon type="x" />
        </IconButton>
      </Row>
      <Body1>{getHistoricalPeriod(forecast, factorContext)}</Body1>
      <Body1>{getForecastType(forecast)}</Body1>
      <TableContainer>
        <ForecastFactorsTable
          className={TestClassNames.ForecastFactorsTable}
          columns={getColumns({
            allowContextMarker: hasContextMarker,
            isForecastEditable,
          })}
          data={tableData}
          isForecastEditable={isForecastEditable}
        />
      </TableContainer>
    </div>
  );
};

export default SpecificForecastViewV2;

const TableContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  margin-top: 10px;
`;

const IconButton = styled.button`
  font-weight: bold;
  margin-left: auto;
  font-size: 14px;
`;

const Row = styled.div`
  height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ForecastFactorsTable = styled(
  <T extends BasicTableColumn<K>, K extends PartialFactorForecast>(
    props: BasicTableProps<T, K> & { isForecastEditable: boolean },
  ) => <BasicTable<T, K> {...props} />,
)`
  > tbody {
    > tr {
      height: 45px;
      td {
        height: inherit;
      }
    }
  }

  > tbody,
  > thead {
    margin-left: 0px;
    text-align: left;
    padding: 0;
    > tr {
      > td {
        padding: 0;
      }

      > td:last-child {
        > div,
        > span {
          padding-right: ${({ isForecastEditable }) => (isForecastEditable ? 5 : 36)}px;
        }
      }

      > td:first-child {
        > div {
          padding-left: 5px;
        }
      }

      > th:last-child {
        > div,
        > span {
          margin-right: 42px;
        }
      }
    }
  }

  > thead {
    > tr {
      border-top: 1px solid ${GetColor.Grey};
      border-bottom: 1px solid ${GetColor.Grey} !important;
      > th {
        color: ${GetColor.Black};
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
`;

const ForecastsHeadline = styled(Headline3)`
  margin-top: 18px;
  margin-bottom: 30px;
`;
