import React from 'react';
import styled from 'styled-components';
import { Tooltip, TooltipPosition, BoxButton } from 'venn-ui-kit';
import type { RollingPeriod, SerieValue } from '../types';
import { RollingPeriodEnum } from '../types';
import type { ContentChartLegendItem } from '../../../content-chart-legend';
import { ContentChartLegend } from '../../../content-chart-legend';
import { compact, isNil } from 'lodash';
import RollingWindowDropMenu from './rolling-window-dropmenu/RollingWindowDropMenu';

export interface AnalysisChartLegendProps {
  isWithRollingPeriods?: boolean;
  disableRollingPeriodKeys?: RollingPeriodEnum[];
  mainSerie?: SerieValue;
  estimatedSerie?: SerieValue;
  secondarySerie?: SerieValue;
  benchmarkSerie?: SerieValue;
  categorySerie?: SerieValue;
  activePeriod?: RollingPeriod;
  customPeriod?: number;
  updatePeriod?: (period?: RollingPeriod, customPeriodMonths?: number) => void;
  /**
   * By default, the legend won't render the markers for items that have 0 returns.
   * Set this flag to override. Example: Venncast.
   */
  includeAll?: boolean;
  noBorder?: boolean;
  allowCustomWindow?: boolean;
}

export const PERIODS: RollingPeriod[] = [
  { key: RollingPeriodEnum.ONE_YEAR, name: '1Y' },
  { key: RollingPeriodEnum.THREE_YEAR, name: '3Y' },
  { key: RollingPeriodEnum.FIVE_YEAR, name: '5Y' },
];

const AnalysisChartLegend = ({
  mainSerie,
  estimatedSerie,
  secondarySerie,
  benchmarkSerie,
  categorySerie,
  activePeriod,
  customPeriod,
  updatePeriod = () => {
    /**/
  },
  isWithRollingPeriods = false,
  allowCustomWindow = false,
  includeAll = false,
  disableRollingPeriodKeys,
  noBorder,
}: AnalysisChartLegendProps) => {
  const mapFn = (serie?: SerieValue, estimated = false) => getItemsForSerie(includeAll, serie, estimated);
  const legend: ContentChartLegendItem[] = [
    ...mapFn(mainSerie),
    ...mapFn(estimatedSerie, true),
    ...mapFn(secondarySerie),
    ...mapFn(benchmarkSerie),
    ...mapFn(categorySerie),
  ];

  if (!isWithRollingPeriods) {
    return legend.length > 0 ? <ContentChartLegend items={legend} hideBorder={noBorder} /> : null;
  }

  if (!allowCustomWindow) {
    return (
      <LegendWrapper>
        <ContentChartLegend items={legend} hideBorder={noBorder} />
        <Flex>
          <div>Rolling Period:</div>
          {PERIODS.map((period) => {
            const disabled = !!disableRollingPeriodKeys?.includes(period.key);
            return (
              <Tooltip
                key={period.key}
                content="There’s not enough history to perform this analysis"
                isHidden={!disabled}
                position={TooltipPosition.Left}
              >
                <BoxButton
                  active={activePeriod?.key === period.key}
                  onClick={() => updatePeriod(period)}
                  disabled={disabled}
                >
                  {period.name}
                </BoxButton>
              </Tooltip>
            );
          })}
        </Flex>
      </LegendWrapper>
    );
  }

  return (
    <LegendWrapper>
      <ContentChartLegend items={legend} hideBorder={noBorder} />
      <Flex>
        <LegendLabel>Rolling Period:</LegendLabel>
        <DropDownWrapper>
          <RollingWindowDropMenu
            selected={
              activePeriod
                ? { label: activePeriod.name, value: -activePeriod.key }
                : !isNil(customPeriod)
                  ? { label: `${customPeriod}M`, value: customPeriod, isCustom: true }
                  : undefined
            }
            onSelect={(item) => {
              const selectedPeriod = PERIODS.find((period) => period.name === item.label);
              updatePeriod(selectedPeriod, item.isCustom ? item.value : undefined);
            }}
            items={PERIODS.map((period) => {
              const disabled = !!disableRollingPeriodKeys?.includes(period.key);
              return { label: period.name, value: -period.key, disabled };
            })}
            width={60}
            dropdownWidth={110}
          />
        </DropDownWrapper>
      </Flex>
    </LegendWrapper>
  );
};
export default AnalysisChartLegend;
function getItemsForSerie(alwaysInclude: boolean, serie?: SerieValue, estimated = false): ContentChartLegendItem[] {
  if (!serie) {
    return [];
  }
  const numReturns = serie?.data?.data?.length;
  if (!alwaysInclude && (numReturns === undefined || numReturns === 0)) {
    return [];
  }
  return compact([
    {
      name: serie.legendLabel,
      color: serie.color,
      estimated,
      dashLine: serie?.data?.dashStyle === 'ShortDot',
    },
    !serie.fullPeriodValueLine
      ? undefined
      : { name: serie.fullPeriodValueLine.label, color: serie.color, dashLine: true },
  ]);
}

const LegendWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const LegendLabel = styled.div`
  margin-right: 8px;
`;

const DropDownWrapper = styled.div`
  width: 60px;
`;

const Flex = styled.div`
  display: flex;
  padding: 0 20px;
  font-size: 1.125rem;
  align-items: center;
  font-weight: bold;

  @media print {
    font-size: 8px;
    padding-right: 10px;
  }
`;
