import React, { useContext } from 'react';
import { SupportedErrorCodes } from 'venn-api';
import type { TimeFrame, AnalysesPeriod, AnalysisSubject, ComparisonSubject } from 'venn-utils';
import { Loading } from 'venn-ui-kit';
import { StyledEmptyState } from './components/styled';
import type { AnalysisStatusForTracking } from './TrackAnalysis';
import { TrackFailedAnalysis } from './TrackAnalysis';
import { formatDateRangeForTracking } from './tracking-utils';
import ErrorSwitch from './ErrorSwitch';
import ComparisonError from './comparison-errors/ComparisonError';
import type { FailedAnalysisError } from './types';
import RangeDebugContext from '../../contexts/range-debug-context';
import { AnalysisConfigContext } from '../../contexts';

export interface FailedAnalysisInfoProps {
  subject?: AnalysisSubject;
  relativeToBenchmark: boolean;
  categoryActive?: boolean;
  analysesPeriod?: AnalysesPeriod;
  actualTimeFrame: TimeFrame;
  error: FailedAnalysisError;
  isAdvancedAnalysis?: boolean;
  isScenarioAnalysis?: boolean;
  isFactorTrend?: boolean;
  regressionName: string;
  onResetAnalysisPeriod?: () => void;
  onResetRollingPeriod?: () => void;
  trackingId: number;
  updateAnalysisStatusForTracking?: (
    actionAnalysisStatuses: AnalysisStatusForTracking[],
    actionTrackingId: number,
    dateRange?: string,
  ) => void;
  blockNames: string[];
  blockTitles: string[];
  /**
   * List of subjects used in the Comparison Page
   */
  comparisonSubjects?: ComparisonSubject[];
  /**
   * Callback to remove items from the comparison page by specifying array index in {@link comparisonSubjects}
   */
  removeComparisonSubject?: (idx: number) => void;
}

const FailedAnalysisInfoContent = ({
  subject,
  error,
  comparisonSubjects,
  isAdvancedAnalysis,
  isScenarioAnalysis,
  isFactorTrend,
  regressionName,
  actualTimeFrame,
  onResetAnalysisPeriod,
  onResetRollingPeriod,
  removeComparisonSubject,
}: FailedAnalysisInfoProps) => {
  const { loading, rangeDebugGroup } = useContext(RangeDebugContext);
  if (!error) {
    return null;
  }

  if (!subject && !comparisonSubjects) {
    return <>{error.message}</>;
  }

  if (error.code === SupportedErrorCodes.InvalidRollingPeriod) {
    return (
      <StyledEmptyState
        header="Unable to run analysis"
        message={error.message}
        actionLabel="Reset Rolling Period"
        onAction={onResetRollingPeriod}
      />
    );
  }

  if (error.code === SupportedErrorCodes.PeriodTooNarrowForFactorTrend) {
    return (
      <StyledEmptyState
        header="Unable to run analysis"
        message={error.message}
        actionLabel="Reset Analysis Period"
        onAction={onResetAnalysisPeriod}
      />
    );
  }

  return (
    <StyledEmptyState
      header="Unable to run analysis"
      message={
        <>
          {loading ? (
            <Loading title="Loading details..." />
          ) : subject?.type ? (
            <ErrorSwitch
              loading={loading}
              rangeDebugGroup={rangeDebugGroup}
              subject={subject}
              actualTimeFrame={actualTimeFrame}
              error={error}
              isAdvancedAnalysis={isAdvancedAnalysis}
              isScenarioAnalysis={isScenarioAnalysis}
              isFactorTrend={isFactorTrend}
              regressionName={regressionName}
              onResetAnalysisPeriod={onResetAnalysisPeriod}
            />
          ) : comparisonSubjects ? (
            <ComparisonError
              error={error}
              onResetAnalysisPeriod={onResetAnalysisPeriod}
              comparisonSubjects={comparisonSubjects}
              removeComparisonSubject={removeComparisonSubject}
            />
          ) : (
            error.message
          )}
        </>
      }
    />
  );
};

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default (props: Omit<FailedAnalysisInfoProps, 'actualTimeFrame'>) => {
  const {
    analysesPeriod,
    subject,
    updateAnalysisStatusForTracking,
    trackingId,
    blockNames,
    blockTitles,
    ...restProps
  } = props;

  const { actualTimeFrame } = useContext(AnalysisConfigContext);

  if (!subject) {
    return <FailedAnalysisInfoContent {...props} actualTimeFrame={actualTimeFrame} />;
  }

  const start = actualTimeFrame?.startTime ?? analysesPeriod?.maxStartTime;
  const end = actualTimeFrame?.endTime ?? analysesPeriod?.maxEndTime;

  const { regressionName, relativeToBenchmark } = restProps;

  return (
    <TrackFailedAnalysis
      analysisType={regressionName}
      blockNames={blockNames}
      blockTitles={blockTitles}
      trackingId={trackingId}
      updateAnalysisStatusForTracking={updateAnalysisStatusForTracking}
      dateRange={start !== undefined && end !== undefined ? formatDateRangeForTracking(start, end) : undefined}
      analysesPeriod={analysesPeriod}
      hasBenchmark={!!subject.activeBenchmarkId}
      hasProxy={subject.hasProxy}
      objectType={subject.type}
      relativeToBenchmark={relativeToBenchmark}
    >
      <FailedAnalysisInfoContent {...props} actualTimeFrame={actualTimeFrame} />
    </TrackFailedAnalysis>
  );
};
