import type { Id } from '../types';
import { useCallback } from 'react';
import { benchmarkFromProto, benchmarkToProto, logExceptionIntoSentry, useCachedSubjects, useClient } from 'venn-utils';
import { Notifications, NotificationType } from 'venn-ui-kit';
import type { Benchmark, OperationResult } from 'venn-api';
import { getFundBenchmarks, getPortfolioBenchmarks, setFundBenchmarks, setPortfolioBenchmarks } from 'venn-api';
import { BenchmarkService } from 'venn-api-client/public_api/portfolio/v1/private_benchmark_connect';

/** Wrapper for getting/setting list of benchmark for a subject */
export const useBenchmarks = (itemId: Id, onSubmit: (errorMessage?: string) => void) => {
  const benchmarkApiClient = useClient(BenchmarkService);
  const fetchBenchmarks = useCallback(async () => {
    if (!itemId) {
      return [];
    }
    if (itemId.privateFundId) {
      const privatesResponse = await benchmarkApiClient.getPrivateFundBenchmarks({ fundUuid: itemId.privateFundId });
      if (privatesResponse.isOk()) {
        return (privatesResponse.value.benchmarks ?? []).map((bench) => benchmarkFromProto(bench));
      }
      Notifications.notify('Failed to fetch benchmark data', NotificationType.ERROR);
      logExceptionIntoSentry(privatesResponse.error.message);
    }

    if (itemId.privatePortfolioId) {
      const privatesResponse = await benchmarkApiClient.getPrivatePortfolioBenchmarks({
        portfolioUuid: itemId.privatePortfolioId,
      });
      if (privatesResponse.isOk()) {
        return (privatesResponse.value.benchmarks ?? []).map((bench) => benchmarkFromProto(bench));
      }
      Notifications.notify('Failed to fetch benchmark data', NotificationType.ERROR);
      logExceptionIntoSentry(privatesResponse.error.message);
    }
    if (itemId.fundId) {
      try {
        const { content } = await getFundBenchmarks(itemId.fundId);
        return content;
      } catch (e) {
        logExceptionIntoSentry(e);
      }
    }

    if (itemId.portfolioId) {
      try {
        const { content } = await getPortfolioBenchmarks(itemId.portfolioId);
        return content;
      } catch (e) {
        logExceptionIntoSentry(e);
      }
    }

    return [];
  }, [itemId, benchmarkApiClient]);

  const { updateCachedAnalysisSubject } = useCachedSubjects();
  const onBenchmarkSubmit = async (benchmarks: Benchmark[]): Promise<void> => {
    const handleResponse = (success: boolean) => {
      if (success) {
        if (!benchmarks.length) {
          Notifications.notify('Benchmark removed successfully.', NotificationType.SUCCESS);
        } else {
          Notifications.notify('Benchmark saved successfully.', NotificationType.SUCCESS);
        }
        onSubmit();
      } else {
        onSubmit("We've encountered an error configuring your benchmarks. Please try again.");
      }
    };
    try {
      // handle private assets
      if (itemId.privateFundId) {
        const privatesResponse = await benchmarkApiClient.setPrivateFundBenchmarks({
          fundUuid: itemId.privateFundId,
          benchmarks: benchmarks.map((bench) => benchmarkToProto(bench)),
        });
        handleResponse(privatesResponse.isOk());
        return;
      }
      if (itemId.privatePortfolioId) {
        const privatesResponse = await benchmarkApiClient.setPrivatePortfolioBenchmarks({
          portfolioUuid: itemId.privatePortfolioId,
          benchmarks: benchmarks.map((bench) => benchmarkToProto(bench)),
        });
        handleResponse(privatesResponse.isOk());
        return;
      }

      let response: OperationResult<null> | undefined;
      if (itemId.fundId) {
        response = await setFundBenchmarks(itemId.fundId, benchmarks);
        // Update cached subject with benchmark info
        updateCachedAnalysisSubject(itemId.fundId);
      }

      if (itemId.portfolioId) {
        response = await setPortfolioBenchmarks(itemId.portfolioId, benchmarks);
        updateCachedAnalysisSubject(itemId.portfolioId);
      }

      if (response && response?.status >= 200 && response?.status < 300) {
        onSubmit();
      } else {
        onSubmit("We've encountered an error configuring your benchmarks. Please try again.");
      }
    } catch (e) {
      const error = await e;
      onSubmit(error.content?.message);
    }
  };

  return { fetchBenchmarks, onBenchmarkSubmit };
};
