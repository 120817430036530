import React from 'react';
import { Consumer } from '../context';
import FaqLink from '../../../faq-link/FaqLink';
import { DataUploaderMode } from '../types';
import { INVESTMENT_UPLOADER_FAQ_HREF, PRIVATE_ASSET_UPLOADER_FAQ_HREF, RETURNS_UPLOADER_FAQ_HREF } from 'venn-ui-kit';

const getLink = (mode: DataUploaderMode) => {
  switch (mode) {
    case DataUploaderMode.NewNavs:
      return INVESTMENT_UPLOADER_FAQ_HREF;
    case DataUploaderMode.Returns:
      return RETURNS_UPLOADER_FAQ_HREF;
    case DataUploaderMode.Privates:
      return PRIVATE_ASSET_UPLOADER_FAQ_HREF;
    default:
      throw new Error(`Unknown uploader mode ${mode}`);
  }
};

const UploaderFaqLink = () => <Consumer>{({ mode }) => <FaqLink link={getLink(mode)} />}</Consumer>;
export const UploaderFaqLinkShared = (mode: DataUploaderMode) => <FaqLink link={getLink(mode)} />;

export default UploaderFaqLink;
