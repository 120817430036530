import { hot } from 'react-hot-loader/root';
import { setConfig } from 'react-hot-loader';
import React from 'react';
import Shell from './components/Shell';
import { GlobalStyles, ShellThemeProvider } from 'venn-ui-kit';
import { LicenseManager, AgGridLicenseKey, AppPrintModeProvider } from 'venn-components';
import { QueryClientProvider, VennQueryClient } from 'venn-utils';
import { RecoilRoot } from 'recoil';
import { Router } from 'react-router-dom';

import { history } from './components/history';

setConfig({
  reloadHooks: false,
});

LicenseManager.setLicenseKey(AgGridLicenseKey);

const App = () => {
  return (
    <RecoilRoot>
      <QueryClientProvider client={VennQueryClient.getInstance()}>
        <Router history={history}>
          <ShellThemeProvider>
            <AppPrintModeProvider>
              <Shell />
              <GlobalStyles />
            </AppPrintModeProvider>
          </ShellThemeProvider>
        </Router>
      </QueryClientProvider>
    </RecoilRoot>
  );
};

export default hot(App);
