import moment from 'moment';
import type { MomentInput } from 'moment/moment';
import type { FrequencyEnum } from 'venn-api';

export default class Dates {
  // Ref: https://twosigma.atlassian.net/browse/VENN-6317, -8519
  static DRAWDOWN_DATE_FORMAT = 'D MMM YYYY';

  static CHART_DATE_FORMATS = {
    // Ref: https://api.highcharts.com/highcharts/xAxis.dateTimeLabelFormats
    YEAR_MONTH: "%b '%y", // Mar '18
    MONTH_DAY: '%e %b', // 3 Mar
    YEAR_MONTH_DAY: "%e %b '%y", // 3 Mar '18
  };

  static toDDMMMYYYY(time: number, frequency?: FrequencyEnum, shortYearForDaily?: boolean) {
    switch (frequency) {
      case 'YEARLY':
        return Dates.toYear(time);
      case 'WEEKLY':
      case 'MONTHLY':
      case 'UNKNOWN':
        return Dates.toMonthYear(time);
      case 'QUARTERLY':
        return Dates.toQuarterYear(time);
      case 'DAILY':
        return shortYearForDaily ? Dates.toDayMonthShortYear(time) : Dates.toDayMonthYear(time);
      default:
        return Dates.toDayMonthYear(time);
    }
  }

  static toYYYYMMDD(time: number, separator = '') {
    return Dates.parseAndFormat(time, ['YYYY', 'MM', 'DD'].join(separator));
  }

  static toYear(time: number) {
    return Dates.parseAndFormat(time, 'YYYY');
  }

  static toMonthYear(time: number) {
    return Dates.parseAndFormat(time, 'MMM YYYY');
  }

  static toFullMonthOptionalYear(time: number, skipCurrentYear?: boolean) {
    const timeMoment = Dates.parse(time);
    return skipCurrentYear && timeMoment.get('year') === moment.utc().get('year')
      ? timeMoment.format('MMMM')
      : timeMoment.format('MMMM YYYY');
  }

  static toDayMonthYear(time: number) {
    return Dates.parseAndFormat(time, 'D MMM YYYY');
  }

  static toQuarterYear(time: MomentInput) {
    return Dates.parseAndFormat(time, '[Q]Q YYYY');
  }

  static parseAndFormat(timestamp: MomentInput, format: string) {
    const date = Dates.parse(timestamp);

    return date.isValid() ? date.format(format) : '—';
  }

  static toDayMonthShortYear(time: number) {
    return Dates.parse(time).format("DD MMM 'YY");
  }

  static toDayMonthShortYearTime(time: number) {
    return Dates.parse(time).format("DD MMM 'YY hh:mm:ss a");
  }

  static toDayMonthYearTime(time?: number, convertUTCtoLocal?: boolean) {
    if (convertUTCtoLocal) {
      return this.parse(time).local().format('DD MMM YYYY, hh:mm:ss A');
    }
    return Dates.parse(time).format('DD MMM YYYY, hh:mm:ss A');
  }

  static parse(timestamp?: MomentInput) {
    return moment.utc(timestamp);
  }

  static secondsDiff(timestampOne: number, timestampTwo: number) {
    return (timestampTwo - timestampOne) / 1000;
  }

  static minutesDiff(timestampOne: number, timestampTwo: number) {
    return Dates.secondsDiff(timestampOne, timestampTwo) / 60;
  }

  static hoursDiff(timestampOne: number, timestampTwo: number) {
    return Dates.minutesDiff(timestampOne, timestampTwo) / 60;
  }

  static daysDiff(timestampOne: number, timestampTwo: number) {
    return Dates.hoursDiff(timestampOne, timestampTwo) / 24;
  }

  static monthsDiff(timestampOne: number, timestampTwo: number) {
    return Dates.daysDiff(timestampOne, timestampTwo) / 30;
  }
}
