import React from 'react';
import type { InputProps } from './types';
import { FormInput } from 'venn-components';

interface EmailInputProps extends InputProps<string> {
  disabled?: boolean;
}

const EmailInput = ({ disabled = false, field }: EmailInputProps) => (
  <FormInput
    errorHidden={disabled}
    disabled={disabled}
    autoComplete="email"
    inputId="email"
    required
    type="email"
    label="Your Work Email"
    {...field}
    name="email"
  />
);

export default EmailInput;
