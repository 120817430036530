const factorsData = {
  baselineDataSetName: undefined,
  categoryDataSetName: undefined,
  comparisonDataSetName: 'Credit Suisse Hedge Fund USD',
  mainDataSetName: 'St',
  rows: [
    {
      baselineDataPoint: undefined,
      categoryDataPoint: undefined,
      comparisonDataPoint: {
        statisticallySignificant: true,
        tvalue: 12.693117486087909,
        value: 0.26345469375856334,
      },
      factorId: 10000,
      factorName: 'Equity',
      mainDataPoint: {
        statisticallySignificant: true,
        tvalue: 12.875369699320782,
        value: 0.13692692494835523,
      },
    },
    {
      baselineDataPoint: undefined,
      categoryDataPoint: undefined,
      comparisonDataPoint: {
        statisticallySignificant: false,
        tvalue: 1.8882416416021524,
        value: 0.04542207816911797,
      },
      factorId: 10002,
      factorName: 'Credit',
      mainDataPoint: {
        statisticallySignificant: false,
        tvalue: 1.6907793985067652,
        value: 0.020839528238027885,
      },
    },
    {
      baselineDataPoint: undefined,
      categoryDataPoint: undefined,
      comparisonDataPoint: {
        statisticallySignificant: true,
        tvalue: 2.218255979491839,
        value: 0.32112025257197574,
      },
      factorId: -1,
      factorName: 'Residual',
      mainDataPoint: {
        statisticallySignificant: true,
        tvalue: 2.218255979491839,
        value: 0.42112025257197574,
      },
    },
    {
      baselineDataPoint: undefined,
      categoryDataPoint: undefined,
      comparisonDataPoint: {
        statisticallySignificant: true,
        tvalue: undefined,
        value: 0.32112025257197574,
      },
      factorId: -3,
      factorName: 'Risk-Free Rate',
      mainDataPoint: {
        statisticallySignificant: true,
        tvalue: undefined,
        value: 0.32112025257197574,
      },
    },
    {
      baselineDataPoint: undefined,
      categoryDataPoint: undefined,
      comparisonDataPoint: {
        statisticallySignificant: true,
        tvalue: undefined,
        value: 0.12112025257197574,
      },
      factorId: -2,
      factorName: 'Total',
      mainDataPoint: {
        statisticallySignificant: true,
        tvalue: undefined,
        value: 0.72112025257197574,
      },
    },
  ],
};

export default factorsData;
