import type { FC } from 'react';
import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { GetColor } from 'venn-ui-kit';
import Bar from '../../../bar/Bar';
import { formatValue } from '../utils';

const StyledRow = styled.tr`
  font-size: 10px;
  line-height: 1.6;
  text-align: right;
`;

const FirstCell = styled.td`
  text-align: left;
  width: 138px;
  font-weight: 700;
`;

const ContributionCell = styled.td`
  color: ${GetColor.DataLineColor.Gold};
  width: 60px;
`;

const RiskCell = styled.td`
  width: 60px;
`;

const BarCell = styled.td`
  width: 80px;
`;

export const StyledBar = styled(Bar)`
  border: 1px solid ${GetColor.Grey};
`;

const FirstCellContent = styled.div`
  width: 133px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

interface Props {
  max: number;
  name: string;
  contribution: number;
  unweightedExposure: number;
  usePercentages: boolean;
}

const Row: FC<React.PropsWithChildren<Props>> = ({ max, name, contribution, unweightedExposure, usePercentages }) => {
  const { Colors } = useContext(ThemeContext);
  return (
    <StyledRow>
      <FirstCell>
        <FirstCellContent>{name}</FirstCellContent>
      </FirstCell>
      <BarCell>
        <StyledBar
          barColor={Colors.DataBarColor.LightGold}
          value={(contribution * 100) / max}
          barHeight={10}
          barWidth={80}
        />
      </BarCell>
      <ContributionCell>{formatValue(contribution, true)}</ContributionCell>
      <RiskCell>{formatValue(unweightedExposure, usePercentages)}</RiskCell>
    </StyledRow>
  );
};

export default Row;
