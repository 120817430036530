import React from 'react';
import { Body1, BrandLogo, GetImages, Headline1 } from 'venn-ui-kit';
import styled from 'styled-components';

export const UpgradesStatusPage = () => {
  return (
    <Container>
      <BackgroundImage />
      <Wrapper>
        <AnimatedWrapper>
          <BrandLogo height={50} />
        </AnimatedWrapper>
        <Headline1>Venn is currently being updated...</Headline1>
        <div>
          <Body1 center>This is a scheduled maintenance and we should be back online soon!</Body1>
          <Body1 center>
            If you have any urgent needs, please contact us at{' '}
            <a href="mailto:venncss@twosigma.com">venncss@twosigma.com</a>.
          </Body1>
        </div>
      </Wrapper>
    </Container>
  );
};

const AnimatedWrapper = styled.div`
  @keyframes logoSpin {
    0% {
      transform: rotate(0deg);
    }

    20% {
      transform: rotate(120deg);
    }

    100% {
      transform: rotate(120deg);
    }
  }

  transform-origin: center 19.5px;
  animation: logoSpin 3s infinite;
`;

const Wrapper = styled.div`
  margin-top: 85px;
  width: 700px;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
`;

const BackgroundImage = styled.div`
  width: 100vw;
  height: 250px;
  background-image: url(${GetImages.trianglesRepeating});
  background-repeat: repeat-x;
  position: absolute;
  opacity: 0.5;
`;

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
`;
