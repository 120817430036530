import styled, { css } from 'styled-components';
import { GetColor, Icon, Tooltip, TooltipBodyDirection, TooltipPosition } from 'venn-ui-kit';
import React, { useEffect, useRef, useState } from 'react';
import type { ComputedInvestmentResidual } from 'venn-api';
import type { FontAwesomePrefixes } from 'venn-utils';

export const FORECAST_INPUT_TEXT = 'Forecast name';

export enum EditType {
  CMA = 'CMA',
  HistoricalPeriod = 'HistoricalPeriod',
  ResidualOverride = 'ResidualOverride',
}

export const StyledIcon = styled(Icon)<{ solid?: boolean; small?: boolean }>`
  font-size: ${(props) => (props.small ? 18 : 30)}px;
  ${(props) =>
    props.solid &&
    css`
      color: ${GetColor.HighlightDark};
    `}
`;

export const ButtonWrapper = styled.button`
  width: unset;
  color: ${GetColor.Grey};
  &:hover,
  :focus {
    color: ${GetColor.Primary.Dark};
  }
`;

interface ActionButtonProps {
  tooltip?: React.ReactNode;
  type: string;
  prefix: FontAwesomePrefixes;
  onClick: () => void;
  tooltipMaxWidth?: number;
  small?: boolean;
  disabled?: boolean;
  className?: string;
  leftTooltip?: boolean;
}

export const ActionButton = ({
  tooltip,
  type,
  prefix,
  onClick,
  tooltipMaxWidth,
  small,
  disabled,
  className,
  leftTooltip,
}: ActionButtonProps) => (
  <Tooltip
    position={leftTooltip ? TooltipPosition.Left : undefined}
    bodyDirection={TooltipBodyDirection.Left}
    content={tooltip}
    maxWidth={tooltipMaxWidth}
  >
    <ButtonWrapper className={className} disabled={disabled} onClick={onClick}>
      <StyledIcon type={type} prefix={prefix} small={small} />
    </ButtonWrapper>
  </Tooltip>
);

export const Banner = styled.div`
  width: calc(100% + 2px);
  height: 30px;
  min-height: 30px;
  position: relative;
  left: -1px;
  font-size: 14px;
  display: flex;
  align-items: center;
  background-color: ${GetColor.HighlightDark};
  color: ${GetColor.White};
  padding: 0 20px;
`;

export const useManagedFocusMenu = (isValidItem: boolean) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const [emptyMenuSelected, setEmptyMenuSelected] = useState(false);
  const [menuValueSelected, setMenuValueSelected] = useState(false);

  useEffect(() => {
    if (menuValueSelected) {
      // If the index was just selected from the menu, focus the return input field for convenience.
      // This is triggered by `onChange()` and happens whenever a value is selected from the selector.
      setMenuValueSelected(false);
      if (inputRef && inputRef.current) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    } else if (
      // Focus the index selector if it has no value. This happens once per the row's lifecycle, with the goal being
      // to focus it when it's just added. If many empty rows are added, the last one will be focused.
      !emptyMenuSelected &&
      menuRef.current &&
      isValidItem
    ) {
      setEmptyMenuSelected(true);
      menuRef.current.focus();
    }
  }, [isValidItem, emptyMenuSelected, menuValueSelected]);

  return {
    inputRef,
    menuRef,
    setMenuValueSelected,
  };
};

/* Index used when the override picker is not open (i.e. it is not targeting any existing, or new, row) */
export const pickerUnselectedIndex = -1;

/* General purpose utility to determine whether a given item matches a search query by prefix matching */
export const startsWithFilter = (item: string, query: string) => {
  return item.toLowerCase().startsWith(query.toLowerCase());
};

export interface IndexedInvestmentResidual extends ComputedInvestmentResidual {
  unfilteredIndex: number;
}
