import type { ProxyMetrics } from 'venn-api';

export const mockExtrapolatedProxyMetrics = {
  proxy: {
    id: '78119952-e262-4f31-9e53-824b056c5c17',
    name: 'SPDR Dow Jones REIT ETF',
    startRange: 988156799999,
    endRange: 1686700799999,
    created: 1579903196577,
    updated: 1686712485694,
    interpolated: false,
    extrapolated: false,
    fundSourceType: {
      id: 1,
      name: 'Investment',
    },
    userUploaded: false,
    investmentBenchmarks: [],
    active: true,
    live: true,
    symbol: 'RWR',
    currency: 'USD',
    domicile: 'United States',
    riskContribution: [],
    groupedRiskContribution: [],
    investmentSource: 'VENN',
    favorite: false,
    viewed: false,
    needsProxy: false,
    needsInterpolation: false,
  },
  correlation: 0.527872437830704,
  percentMatch: null,
  proxyCategory: 'REAL_ESTATE',
  proxyType: 'DESMOOTH_INTERPOLATE',
  proxiedStart: 1420156799999,
  proxiedEnd: 1633046399999,
  extrapolatedStart: 1609545599999,
  extrapolatedEnd: 1633046399999,
  investmentReturns: [
    [1420070399999, 0.0],
    [1500000000000, 0.0],
  ],
  proxyReturns: [
    [1420070399999, 0.0],
    [1500000000000, 0.0],
  ],
  cumulativeProxiedReturns: [
    [1420156899999, 0.0],
    [1609545599999, 0.0],
  ],
  numLags: 3,
  suggestedNumLags: 1,
  extrapolated: true,
  warning: null,
  error: null,
} as unknown as ProxyMetrics;

export default mockExtrapolatedProxyMetrics;
