import styled from 'styled-components';
import type { StylingProps } from './styles';
import { getInputStyles } from './styles';
import React from 'react';

const ReactPhoneInput = React.lazy(() => import('react-phone-input-2'));

interface PhoneInputProps extends React.ComponentProps<typeof ReactPhoneInput>, StylingProps {}

const PhoneInput = ({ hasError, hasValue, ...restProps }: PhoneInputProps) => {
  return (
    <StyledReactPhoneInput hasError={hasError} hasValue={hasValue} hasLabel={false}>
      <ReactPhoneInput {...restProps} />
    </StyledReactPhoneInput>
  );
};

export default PhoneInput;

const StyledReactPhoneInput = styled.div<StylingProps>`
  .react-tel-input {
    input[type='tel'] {
      font-weight: normal;
      ${(props) => getInputStyles(props)}
    }
    .flag-dropdown {
      border-right: none;
      background: none;
    }
  }
`;
