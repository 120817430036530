import React, { useMemo, useState } from 'react';
import type { SearchFilter } from '../types';
import noop from 'lodash';

export interface FiltersContext {
  selectedFilters: SearchFilter[];
  setSelectedFilters: (fn: (currentFilters: SearchFilter[]) => SearchFilter[]) => void;
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export const FiltersContext = React.createContext<FiltersContext>({
  selectedFilters: [],
  setSelectedFilters: noop,
});

const FiltersProvider = ({ children }: { children: React.ReactNode }) => {
  const [selectedFilters, setSelectedFilters] = useState<SearchFilter[]>([]);

  const context = useMemo(
    () => ({
      selectedFilters,
      setSelectedFilters,
    }),
    [selectedFilters],
  );

  return <FiltersContext.Provider value={context}>{children}</FiltersContext.Provider>;
};

export default FiltersProvider;
