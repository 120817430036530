import { SavedViewsQuickFilter } from 'venn-utils';
import { getAppTitle } from 'venn-ui-kit';

export const PAGE_SIZE = 50;
export const RECENT_SEARCH_MAX_ITEMS = 5;
export const REPORTING_TABLE_CLASSNAME = 'reporting-table';
export const defaultSearchState = {
  results: [],
  totalCount: 0,
  loading: true,
};

export const LABELS = {
  [SavedViewsQuickFilter.RECENTLY_ANALYZED]: 'Recent Views',
  [SavedViewsQuickFilter.REQUIRES_ATTENTION]: 'Requires Attention',
  [SavedViewsQuickFilter.SCHEDULED]: 'Scheduled',
  [SavedViewsQuickFilter.SAVED_BY_ME]: 'Saved by Me',
  [SavedViewsQuickFilter.VENN_PROVIDED]: `${getAppTitle()} Provided`,
};

export const BUTTON_SIZE = 30;
