import type { AttributionAnalysis, SharedProps } from '../../types';
import React, { useCallback, useMemo } from 'react';
import type { AttributionSubject, AttributionView, TrackAnalysisProps } from 'venn-components';
import { PrintContainerDimensions } from 'venn-components';
import type { AnalysisSubject } from 'venn-utils';
import { logMessageToSentry } from 'venn-utils';
import type { PortfolioPerformanceAttributions, PerformanceAttribution } from 'venn-api';
import AttributionBlock from './AttibutionBlock';
import sortBy from 'lodash/sortBy';
import type { AttributionRow } from './types';

const hasStrategies = (contribution: PortfolioPerformanceAttributions | undefined, relative: boolean) => {
  if (!contribution) {
    return false;
  }
  const returns = relative ? contribution.periodExcessReturn : contribution.periodReturn;
  if (returns.funds.length !== returns.strategies.length) {
    return true;
  }

  const sortedFunds = sortBy(returns.funds, 'name');
  const sortedStrategies = sortBy(returns.strategies, 'name');

  for (let i = 0; i < sortedFunds.length; i++) {
    if (sortedFunds[i].name !== sortedStrategies[i].name) {
      return true;
    }
  }

  return false;
};

const createRows =
  (attributions: AttributionAnalysis, relative: boolean, subject: AnalysisSubject) =>
  (view: AttributionView, selectedSubject: AttributionSubject): AttributionRow[] => {
    const historicalContributions = attributions.historicalContribution[selectedSubject];
    const forecastContributions = attributions.forecastContribution[selectedSubject];

    if (!historicalContributions && !forecastContributions) {
      return [];
    }

    if (view === 'strategies' && !hasStrategies(historicalContributions, relative)) {
      return [];
    }

    const sortByName = (contributions: PerformanceAttribution[] | undefined): PerformanceAttribution[] | undefined =>
      contributions?.sort((a, b) => {
        if (!a.name && !b.name) {
          return 0;
        }
        if (!a.name) {
          return 1;
        }
        if (!b.name) {
          return -1;
        }
        return a.name.localeCompare(b.name);
      });
    const getReturns = (contributions?: PortfolioPerformanceAttributions): PerformanceAttribution[] | undefined =>
      sortByName(relative ? contributions?.periodExcessReturn[view] : contributions?.periodReturn[view]);
    const getVolatility = (contributions?: PortfolioPerformanceAttributions): PerformanceAttribution[] | undefined =>
      sortByName(relative ? contributions?.trackingError[view] : contributions?.volatility[view]);
    const getSharpe = (contributions?: PortfolioPerformanceAttributions): PerformanceAttribution[] | undefined =>
      sortByName(relative ? contributions?.informationRatio[view] : contributions?.sharpe[view]);

    const returns = getReturns(historicalContributions);
    const forecastReturns = getReturns(forecastContributions);
    const volatility = getVolatility(historicalContributions);
    const forecastVolatility = getVolatility(forecastContributions);
    const sharpe = getSharpe(historicalContributions);
    const forecastSharpe = getSharpe(forecastContributions);
    const rowNames = returns?.map((r) => r.name);

    const rows = rowNames?.map(
      (name, idx) =>
        ({
          name,
          weight: returns?.[idx]?.weight,
          return: returns?.[idx]?.value,
          returnContribution: returns?.[idx]?.contributionValue,
          returnForecastContribution: forecastReturns?.[idx]?.contributionValue,
          volatility: volatility?.[idx]?.value,
          volatilityContribution: volatility?.[idx]?.contributionValue,
          volatilityForecastContribution: forecastVolatility?.[idx]?.contributionValue,
          sharpe: sharpe?.[idx]?.value,
          sharpeContribution: sharpe?.[idx]?.contributionValue,
          sharpeForecastContribution: forecastSharpe?.[idx]?.contributionValue,
          start: historicalContributions?.analysisStart,
          end: historicalContributions?.analysisEnd,
        }) as AttributionRow,
    );

    if (!rows?.length) {
      return [];
    }

    if (subject.type === 'investment') {
      return rows.filter((r) => r.name === subject.name);
    }

    return rows;
  };

export interface AttributionProps extends SharedProps {
  trackingProps: TrackAnalysisProps;
}

const Attribution = ({ analyses, analysisConfig, trackingProps, downloadMetaData }: AttributionProps) => {
  const { subject, relative } = analysisConfig;

  const attributions = analyses?.results?.attributions;

  const cumulativeReturn = !attributions?.historicalContribution?.subject?.periodAnnualized;

  const getRows = useMemo(() => {
    if (!attributions || !subject) {
      return undefined;
    }
    return createRows(attributions, relative, subject);
  }, [attributions, relative, subject]);

  const getAnalysisTimeFrame = useCallback(
    (selectedSubject) => {
      if (!attributions) {
        return {
          startTime: undefined,
          endTime: undefined,
        };
      }
      const selectedAnalysis = attributions.historicalContribution[selectedSubject];
      return {
        startTime: selectedAnalysis?.analysisStart,
        endTime: selectedAnalysis?.analysisEnd,
      };
    },
    [attributions],
  );

  if (!subject || !getRows || !attributions) {
    logMessageToSentry('Failed to render Attribution Block because of incorrect initialization.');
    return null;
  }

  return (
    <PrintContainerDimensions>
      {({ print }) => (
        <AttributionBlock
          subject={subject}
          getRows={getRows}
          relative={relative}
          trackingProps={trackingProps}
          showTooltip
          showTotal
          cumulativeReturn={cumulativeReturn}
          errorMessage={attributions.historicalContribution.message?.text}
          errorCode={attributions.historicalContribution.message?.code}
          emptyMessage={`Add this investment to the ${
            subject.isInvestmentInPortfolio ? 'current portfolio' : 'Master Portfolio'
          } to view Performance Attribution Analysis.`}
          hasComparison={!!attributions.historicalContribution.category}
          getAnalysisTimeFrame={getAnalysisTimeFrame}
          downloadMetaData={downloadMetaData}
          print={print}
        />
      )}
    </PrintContainerDimensions>
  );
};

export default Attribution;
