import { compact, merge } from 'lodash';
import isNil from 'lodash/isNil';
import React, { useMemo, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import styled, { withTheme } from 'styled-components';
import { blockFonts } from 'venn-state';
import { FORECASTS_FAQ_HREF, GetColor, Icon, REPORT_LAB_FONT_BODY, Tooltip } from 'venn-ui-kit';
import { assertNotNil, DEFAULT_USE_LOGARITHMIC_SCALE, Numbers } from 'venn-utils';
import type { growthSimulationConfigProps } from '../../../charts/analysis-charts/chart-config-logic';
import {
  createGrowthSimulationLineChartConfig,
  getLineSerie,
} from '../../../charts/analysis-charts/chart-config-logic';
import HighchartsReact from 'highcharts-react-official';
import Highstock from 'highcharts/highstock';
import { useChartReflow } from '../../../hooks';
import { NameWithOptionalFee } from '../../../legend';
import HighchartsUtils from '../../../utils/highcharts';
import { useBlockId } from '../../contexts/BlockIdContext';
import { getCurrencySymbol } from './chartsUtils';
import type { GrowthSimulationChartProps, SubjectInfo } from './types';

const navigateToForecastsFAQ = () => {
  window.open(FORECASTS_FAQ_HREF, '_blank', 'noopener,noreferrer');
};

const GrowthSimulationLineChart = ({
  start,
  end,
  theme,
  series,
  frequency,
  inPrintMode,
  hasDrawdownLines,
  height,
  useLogarithmicScale,
  currency,
  subjectInfo,
  additionalSeries,
}: GrowthSimulationChartProps) => {
  const blockId = useBlockId();
  const chartRef = useRef<HighchartsReact.RefObject>(null);
  useChartReflow(blockId, chartRef);
  const currencySymbol = currency ? getCurrencySymbol(currency) : '';
  const legendFont = useRecoilValue(blockFonts.blockChartLegend(blockId));
  const axisFont = useRecoilValue(blockFonts.blockChartAxis(blockId));

  const seriesValues = useMemo(
    () =>
      compact(
        series.map((serie) => {
          const color = assertNotNil(serie.color, 'Every serie should be assigned a color');
          const lineSerie = getLineSerie(serie.data, serie.name, color, serie.type);
          return lineSerie
            ? {
                data: {
                  ...lineSerie,
                  id: serie.id,
                  linkedTo: serie.linkedTo,
                },
                name: serie.name,
                legendLabel: serie.name,
                color: serie.color,
                key: serie.key,
              }
            : undefined;
        }),
      ),
    [series],
  );

  const options = useMemo(() => {
    const config = {
      ...createGrowthSimulationLineChartConfig({
        start,
        end,
        series: seriesValues,
        theme,
        frequency,
        print: inPrintMode,
        hasDrawdownLines,
        currencySymbol,
        useLogarithmicScale: useLogarithmicScale ?? DEFAULT_USE_LOGARITHMIC_SCALE,
        axisFontSize: axisFont.fontSizePt,
      } as growthSimulationConfigProps),
    };

    return {
      ...config,
      chart: {
        ...config.chart,
        height,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        ...config.tooltip,
        useHTML: true,
        formatter: HighchartsUtils.fundingFailureFormatter(
          (context) => context.point,
          assertNotNil(config?.tooltip?.formatter),
          theme,
        ),
      },
      xAxis: merge({}, config.xAxis, {
        labels: {
          formatter:
            frequency === 'QUARTERLY' ? HighchartsUtils.quarterlyFormatter : HighchartsUtils.endOfTheYearFormatter,
        },
      }),
      plotOptions: {
        ...config.plotOptions,
        line: {
          ...config.plotOptions?.line,
          marker: {
            // TODO: customize the marker as requested by Design
            symbol: 'circle' as const,
          },
        },
      },
      series: [...(config.series ?? []), ...(additionalSeries ?? [])],
    };
  }, [
    start,
    end,
    seriesValues,
    theme,
    frequency,
    inPrintMode,
    hasDrawdownLines,
    currencySymbol,
    useLogarithmicScale,
    axisFont.fontSizePt,
    height,
    additionalSeries,
  ]);

  const getCustomLegend = (subjectInfo: SubjectInfo[]) => (
    <CustomLegendWrapper fontSize={legendFont.fontSizePt}>
      {subjectInfo.map((info, idx) => {
        const valuesForSubject = isNil(info.key)
          ? []
          : seriesValues.filter((serieValue) => serieValue?.key === info.key);
        return (
          <>
            <RowWrapper data-testid="qa-custom-legend-growth-row-wrapper">
              <NameWithOptionalFee subject={subjectInfo[idx].subject} />
              {subjectInfo[idx].privateSubject && (
                <>
                  &nbsp; + &nbsp;
                  <NameWithOptionalFee subject={assertNotNil(subjectInfo[idx].privateSubject)} />
                </>
              )}
              {!isNil(info.key) ? (
                valuesForSubject.map((value) => {
                  return (
                    <div data-testid="qa-growth-simulation-series-icon" key={value.name}>
                      <Icon style={{ color: value?.data.color }} type="circle" />
                      &nbsp;{value.name}
                    </div>
                  );
                })
              ) : (
                <>&nbsp;-&nbsp;</>
              )}
              {!isNil(info.return) && (
                <>
                  <b>
                    Return (
                    <Tooltip content={<div>Click to learn more about Forecasting</div>}>
                      <StyledLink onClick={navigateToForecastsFAQ}>forecast</StyledLink>
                    </Tooltip>
                    ):
                  </b>
                  &nbsp;{Numbers.safeFormatPercentage(info.return)}&nbsp;
                </>
              )}
              {!isNil(info.volitility) && (
                <>
                  <b>
                    Volatility (
                    <Tooltip content={<div>Click to learn more about Forecasting</div>}>
                      <StyledLink onClick={navigateToForecastsFAQ}>forecast</StyledLink>
                    </Tooltip>
                    ):
                  </b>
                  &nbsp;{Numbers.safeFormatPercentage(info.volitility)}&nbsp;
                </>
              )}
              {!isNil(info.fundingFailureProb) && (
                <>
                  &nbsp; | &nbsp;
                  <b data-testid="qa-failure-funding-prob">
                    Probability of Funding Failure: {Numbers.safeFormatPercentage(info.fundingFailureProb, 1)}
                  </b>
                </>
              )}
            </RowWrapper>
          </>
        );
      })}
    </CustomLegendWrapper>
  );

  return (
    <>
      <HighchartsReact
        highcharts={Highstock}
        ref={chartRef}
        options={options}
        containerProps={{ style: { height: '100%' } }}
      />
      <LegendWrapper className="qa-growth-simulation-legend">
        {subjectInfo && getCustomLegend(subjectInfo)}
      </LegendWrapper>
    </>
  );
};

export default withTheme(GrowthSimulationLineChart);

const RowWrapper = styled.div`
  color: ${GetColor.Black};
  font-weight: bold;
  display: flex;
  flex-wrap: wrap;
  padding: 0.3em 0.5em;
  column-gap: 0.4em;
`;

const LegendWrapper = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
`;

const StyledLink = styled.button`
  font-weight: bold;
  color: ${GetColor.Primary.Dark};
`;

const CustomLegendWrapper = styled.div<{ fontSize: number | undefined }>`
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}pt` : REPORT_LAB_FONT_BODY)};
  display: flex;
  flex-direction: column;
  padding: 0.3em;
`;
