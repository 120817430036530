import type { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import type { PrivatesTableRow } from './privatesColumns';
import { partition } from 'lodash';
import HomePageContext from './contexts/HomePageContext';
import React, { useContext } from 'react';
import { deletePrivateFunds, deletePrivatePortfolios } from 'venn-api';
import { ConfirmationModal } from 'venn-components';
import { logExceptionIntoSentry } from 'venn-utils';

interface PrivateDeleteConfirmationModalProps extends RouteComponentProps {
  items?: PrivatesTableRow[];
  onCancelDelete: () => void;
  onDeleteComplete: () => void;
}

const PrivateDeleteConfirmationModal = ({
  items = [],
  onCancelDelete,
  onDeleteComplete,
}: PrivateDeleteConfirmationModalProps) => {
  const { loading: deleting } = useContext(HomePageContext);
  const [funds, portfolios] = partition(items, { itemType: 'INVESTMENT' });

  const handleDelete = async () => {
    if (deleting) {
      return;
    }
    try {
      await Promise.all([
        portfolios.length ? deletePrivatePortfolios(portfolios.map((p) => p.id)) : Promise.resolve(),
        funds.length ? deletePrivateFunds(funds.map((f) => f.id)) : Promise.resolve(),
      ] as Promise<unknown>[]);
    } catch (e) {
      logExceptionIntoSentry(e);
    }
    onDeleteComplete();
  };

  const hasNonSavedViewsText = funds.length || portfolios.length;
  const savedViewsWarning = ` ${
    funds.length && portfolios.length
      ? 'These portfolios and investments'
      : funds.length > 1
        ? 'These investments'
        : funds.length === 1
          ? 'This investment'
          : portfolios.length > 1
            ? 'These portfolios'
            : portfolios.length === 1
              ? 'This portfolio'
              : 'These items'
  } will ${hasNonSavedViewsText ? 'also ' : ''}be removed from saved views.`;

  const itemName = funds.length + portfolios.length > 1 ? 'items' : 'item';
  const modalTextProps = {
    text: (funds.length ? ' Deleted funds will be removed from portfolios.' : '') + savedViewsWarning,
    subhead: `${
      items.length > 1 ? 'These items' : 'This item'
    } will be deleted permanently for everyone in your organization. You cannot undo this action.`,
    header: `Delete ${items.length} ${itemName}?`,
  };

  return (
    <ConfirmationModal
      destructive
      {...modalTextProps}
      onCancel={onCancelDelete}
      onProceed={handleDelete}
      proceedDisabled={deleting}
    />
  );
};

export default withRouter(PrivateDeleteConfirmationModal);
