import type { FC } from 'react';
import React from 'react';
import styled, { css } from 'styled-components';
import { Icon, GetColor } from 'venn-ui-kit';

export interface SplitButtonDropdownItemProps {
  label: React.ReactNode;
  tooltipContent?: string;
  icon?: string;
  onClick?: () => void;
}

const SplitButtonDropdownItemWrapper = styled.span<{ disabled?: boolean }>`
  display: inline-flex;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  cursor: pointer;
  i {
    margin-right: 10px;
  }
  :hover {
    background: ${GetColor.PaleGrey};
  }
  ${(props) =>
    props.disabled &&
    css`
      color: ${GetColor.Grey};
      cursor: not-allowed;
      :hover {
        background: transparent;
      }
    `}
`;

const SplitButtonDropdownItem: FC<React.PropsWithChildren<SplitButtonDropdownItemProps>> = ({
  label,
  icon,
  onClick,
}) => (
  <SplitButtonDropdownItemWrapper onClick={onClick} disabled={!onClick} className="split-item" tabIndex={0}>
    {icon && <Icon type={icon} />}
    <span>{label}</span>
  </SplitButtonDropdownItemWrapper>
);

export default SplitButtonDropdownItem;
