import React from 'react';
import noop from 'lodash/noop';

interface HomePageContextValue {
  setLoading: (loading: boolean) => void;
  loading: boolean;
}

const HomePageContext = React.createContext<HomePageContextValue>({
  loading: false,
  setLoading: noop,
});
HomePageContext.displayName = 'HomePageContext';

export default HomePageContext;
