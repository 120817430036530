import { useEffect, useRef, useState, useCallback } from 'react';
import type { AnalysisSubject, SubjectId } from 'venn-utils';
import {
  parseValueFromLocation,
  updateUrlParam,
  getRecentBenchmarkId,
  getRecentRelative,
  shouldUseRecent,
  useCachedSubjects,
} from 'venn-utils';
import type { History } from 'history';

const useBenchmark = (history: History) => {
  const isMounted = useRef(false);
  const [benchmark, setBenchmark] = useState<AnalysisSubject | undefined>(undefined);
  const [relative, setRelative] = useState(() => parseValueFromLocation(history.location, 'relative') === 'true');
  const { getCachedAnalysisSubject } = useCachedSubjects();

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const updateBenchmark = useCallback(
    async (benchmarkId?: SubjectId, updatedRelative?: boolean) => {
      if (!benchmarkId) {
        return;
      }
      const benchmark = await getCachedAnalysisSubject(benchmarkId);
      if (isMounted.current) {
        if (benchmark) {
          setBenchmark(benchmark);
          setRelative(!!updatedRelative);
        } else {
          setBenchmark(undefined);
          setRelative(false);
        }
      }
    },
    [getCachedAnalysisSubject],
  );

  // Parse and fetch benchmark from url on load.
  useEffect(() => {
    const fetchBenchmark = async () => {
      let benchmarkId: SubjectId | undefined = parseValueFromLocation(history.location, 'benchmark');
      let recentRelative = parseValueFromLocation(history.location, 'relative') === 'true';
      if (benchmarkId === undefined && shouldUseRecent(history.location)) {
        benchmarkId = getRecentBenchmarkId();
        recentRelative = !!getRecentRelative();
      }
      if (benchmarkId) {
        await updateBenchmark(benchmarkId, recentRelative);
      } else {
        setBenchmark(undefined);
        setRelative(false);
      }
    };
    fetchBenchmark();
  }, [history, updateBenchmark]);

  useEffect(
    () => updateUrlParam(history, 'REPLACE', 'benchmark', benchmark ? String(benchmark.id) : undefined),
    [benchmark, history],
  );

  useEffect(() => updateUrlParam(history, 'REPLACE', 'relative', relative ? 'true' : undefined), [history, relative]);

  return {
    benchmark,
    setBenchmark,
    relative,
    setRelative,
    updateBenchmark,
  };
};

export default useBenchmark;
