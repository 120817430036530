import React from 'react';
import { RESIDUAL_ID, RISK_FREE_RATE_ID, TOTAL_ID } from '../../../factor-chart';
import type { FactorProps } from '../types';
import Factor from './Factor';
import Residual from './Residual';
import RiskFreeRate from './RiskFreeRate';
import Total from './Total';

export interface BarBaseProps extends FactorProps {
  factorId: number;
  opacity?: number;
}

const BarBase = ({ factorId, waterfall, ...restProps }: BarBaseProps) => {
  switch (factorId) {
    case RESIDUAL_ID:
      return <Residual {...restProps} />;
    case RISK_FREE_RATE_ID:
      return <RiskFreeRate {...restProps} />;
    case TOTAL_ID:
      return <Total {...restProps} />;
    default:
      return <Factor waterfall={waterfall} {...restProps} />;
  }
};

export default BarBase;
