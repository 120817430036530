import { useAllCashflowSettingsOverrides } from './logic/useAllCashflowSettingsOverrides';
import { useActiveCashflowSetting } from './logic/useActiveCashflowSetting';
import { useSystemCashflowSetting } from './logic/useSystemCashflowSetting';
import React, { useCallback, useEffect, useState } from 'react';
import { activate, type CashFlowSetting, type PrivateFund } from 'venn-api';
import {
  CashflowSettingOption,
  CLASSIFICATION_SETTING_EXPLANATION,
  DEFAULT_SETTING_EXPLANATION,
  PARAMETER_SETTING_EXPLANATION,
} from './CashflowSettingOption';
import { CashflowActiveSettingInfo } from './CashflowSettingInfo';
import styled from 'styled-components';
import { EditCashflowSettings } from './EditCashflowSettings';
import { Flexbox, GetColor, Notifications, NotificationType, ZIndex } from 'venn-ui-kit';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  cashflowModalActiveSettingIdAtom,
  cashflowSettingSelectedToActivateNext,
  cashflowSettingsModalIsEditing,
  editedCashflowSettingAtom,
  useResetCashflowSettingsModalState,
} from '../../../../venn-state/src/cashflow-settings/cashflowSettingsModalAtoms';
import { ModalFooter } from '../../modal';
import { useMutation } from '@tanstack/react-query';

type SettingOverrideType = 'SYSTEM' | 'CLASSIFICATION' | 'PARAMETER' | 'UNKNOWN';

const getOverrideTypeFromActiveSetting = (setting: CashFlowSetting | undefined): SettingOverrideType => {
  if (!setting || !setting.userCreated) {
    return 'SYSTEM';
  }
  return setting.overrideType;
};

type FundCashflowSettingsProps = {
  privateFund: PrivateFund;
  editViewHeight: number;
  editViewTop: number;
  onClose: () => void;
  onChangesApplied: () => void;
};

type MutationProps = {
  selectedFundId: string;
  settingToActivate: string;
};

export const FundCashflowSettings = ({
  editViewHeight,
  editViewTop,
  privateFund,
  onClose,
  onChangesApplied,
}: FundCashflowSettingsProps) => {
  const selectedFundId = privateFund.id;
  const { data: allUserSettings } = useAllCashflowSettingsOverrides();
  const { data: activeSettings, refetch: refetchActiveSetting } = useActiveCashflowSetting([
    [selectedFundId, privateFund.name],
  ]);
  const { data: systemSetting } = useSystemCashflowSetting(privateFund);
  const isEditing = useRecoilValue(cashflowSettingsModalIsEditing);
  const editedCashflowSetting = useRecoilValue(editedCashflowSettingAtom);
  const [selectedToActivateNext, setSelectedToActivateNext] = useRecoilState(cashflowSettingSelectedToActivateNext);
  const [activeSettingId, setActiveSettingId] = useRecoilState(cashflowModalActiveSettingIdAtom);
  const activeSetting = activeSettings?.[0]?.setting;
  // sync state when changes are fetched
  useEffect(() => {
    setActiveSettingId(activeSetting?.settingId);
  }, [setActiveSettingId, activeSetting?.settingId]);

  const [activeType, setActiveType] = useState<SettingOverrideType>(
    getOverrideTypeFromActiveSetting(activeSettings?.[0]?.setting),
  );

  useEffect(() => {
    setActiveType((_) => getOverrideTypeFromActiveSetting(activeSettings?.[0]?.setting));
  }, [activeSettings]);

  const resetCashflowSettingsModalState = useResetCashflowSettingsModalState();

  const persistActiveSettingChanges = useMutation(
    ({ settingToActivate, selectedFundId }: MutationProps) => activate(settingToActivate, [selectedFundId]),
    {
      onSuccess: () => {
        onChangesApplied();
        refetchActiveSetting();
        setSelectedToActivateNext(undefined);
        Notifications.notify(`Settings applied to ${privateFund.name}`, NotificationType.SUCCESS);
      },
      onError: () => {
        Notifications.notify(
          'Failed to apply changes. Please try again or contact Venn Support.',
          NotificationType.ERROR,
        );
      },
    },
  );

  const onApplyChanges = useCallback(() => {
    if (!selectedToActivateNext || !selectedFundId) {
      return;
    }

    persistActiveSettingChanges.mutate({
      settingToActivate: selectedToActivateNext,
      selectedFundId,
    });
  }, [persistActiveSettingChanges, selectedToActivateNext, selectedFundId]);

  const onExitModal = useCallback(() => {
    resetCashflowSettingsModalState();
    onClose();
  }, [resetCashflowSettingsModalState, onClose]);

  const onApplyChangesAndClose = useCallback(() => {
    onApplyChanges();
    onExitModal();
  }, [onApplyChanges, onExitModal]);

  if (!allUserSettings || !activeSetting || !systemSetting) {
    return null;
  }
  const { classificationSettings, parameterSettings } = allUserSettings;

  const disallowApplyingChanges = !selectedFundId || isEditing || !selectedToActivateNext;
  return (
    <Flexbox direction="column">
      <ContentContainer>
        <ChooseSettingContainer>
          <SlidingRightOverlay height={editViewHeight} top={editViewTop} isOpen={isEditing}>
            {isEditing && editedCashflowSetting && (
              <EditCashflowSettings
                refetchActiveSetting={refetchActiveSetting}
                onChangesApplied={onChangesApplied}
                systemSetting={systemSetting}
              />
            )}
          </SlidingRightOverlay>
          <CashflowSettingOption
            title="Venn Default Setting"
            sectionId="SYSTEM"
            explanation={DEFAULT_SETTING_EXPLANATION}
            expanded={activeType === 'SYSTEM'}
            onRadioClick={() => {
              setActiveType('SYSTEM');
              setSelectedToActivateNext(
                systemSetting.settingId === activeSettingId ? undefined : systemSetting.settingId,
              );
            }}
            options={[systemSetting]}
            systemSetting={systemSetting}
          />
          <CashflowSettingOption
            title="Classification Override"
            sectionId="CLASSIFICATION"
            explanation={CLASSIFICATION_SETTING_EXPLANATION}
            expanded={activeType === 'CLASSIFICATION'}
            onRadioClick={() => {
              setActiveType('CLASSIFICATION');
              setSelectedToActivateNext(undefined);
            }}
            options={classificationSettings}
            systemSetting={systemSetting}
          />
          <CashflowSettingOption
            title="Parameter Override"
            sectionId="PARAMETER"
            explanation={PARAMETER_SETTING_EXPLANATION}
            expanded={activeType === 'PARAMETER'}
            onRadioClick={() => {
              setActiveType('PARAMETER');
              setSelectedToActivateNext(undefined);
            }}
            options={parameterSettings}
            systemSetting={systemSetting}
          />
        </ChooseSettingContainer>
        <CashflowActiveSettingInfo setting={activeSetting} />
      </ContentContainer>
      <ModalFooter
        className="qa-modal-footer-wrapper"
        secondaryLabel="APPLY CHANGES"
        secondaryDisabled={disallowApplyingChanges}
        onSecondaryClick={onApplyChanges}
        primaryLabel="APPLY CHANGES & CLOSE"
        primaryDisabled={disallowApplyingChanges}
        onPrimaryClick={onApplyChangesAndClose}
        onCancel={onExitModal}
        cancelLabel={selectedToActivateNext || isEditing ? 'Cancel' : 'Close'}
      />
    </Flexbox>
  );
};

const SlidingRightOverlay = styled.div<{ isOpen: boolean; top: number; height: number }>`
  position: absolute;
  background: ${GetColor.White};
  z-index: ${ZIndex.ModalCover};

  right: 0;
  overflow: hidden;
  height: ${(props) => `${props.height}px`};
  top: ${(props) => `${props.top}px`};
  transition: 0.5s ease;

  left: ${(props) => (props.isOpen ? '0' : '70%')};
  width: ${(props) => (props.isOpen ? '714px' : '0')};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 24px 24px 24px;
  align-items: flex-start;
  gap: 24px;
  flex: 1 1 0;
`;

const ChooseSettingContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 690px;
  gap: 16px;
`;
