import React from 'react';
import type { Benchmark } from 'venn-api';
import { ManageBenchmarks, Modal, ModalSize } from 'venn-components';
import type { AnalysisSubjectType } from 'venn-utils';
import type { Id } from './types';
import { useQuery } from '@tanstack/react-query';
import { useBenchmarks } from './logic/useBenchmarks';

interface ConfigureBenchmarksModalProps {
  onClose: () => void;
  onSubmit: (errorMessage?: string) => void;
  itemId: Id;
  name: string;
  benchmarkContext?: AnalysisSubjectType;
}

const GET_BENCHMARKS_KEY = 'get_benchmarks_key';

const ConfigureBenchmarksModal = ({
  onClose,
  benchmarkContext,
  name,
  onSubmit,
  itemId,
}: ConfigureBenchmarksModalProps) => {
  const { fetchBenchmarks, onBenchmarkSubmit } = useBenchmarks(itemId, onSubmit);
  const { data: benchmarks = [], isLoading } = useQuery<Benchmark[]>([GET_BENCHMARKS_KEY, itemId], fetchBenchmarks, {
    cacheTime: 0,
  });

  return (
    <Modal onClose={onClose} noPadding closeOnEsc size={ModalSize.Large}>
      <ManageBenchmarks
        ownerName={name}
        benchmarks={benchmarks}
        benchmarksLoading={isLoading}
        onSubmit={onBenchmarkSubmit}
        onCancel={onClose}
        benchmarkContext={benchmarkContext}
      />
    </Modal>
  );
};

export default ConfigureBenchmarksModal;
