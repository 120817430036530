import type { CSSProperties, FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import type { HeatMapTypes } from './Types';
import { RESIDUAL_ID, RISK_FREE_RATE_ID } from '../factor-chart/constants';
import { calculateMaxCharacters } from 'venn-utils';

interface YAxisProps {
  series: HeatMapTypes.Root[];
  width: number;
  height: number;
  hoveringId: number | null;
  textAnchor?: 'start' | 'middle' | 'end' | 'inherit';
  labelFormatter?: (serie: HeatMapTypes.Root) => CSSProperties;
  fontSize?: string;
}

const ROW_SHIFT = 0.7;
const defaultFormatter = () => ({});

const StyledText = styled.text<{ text: string; italic?: boolean; height: number; fontSize?: string }>`
  font-family: ${(props) => props.theme.Typography.fontFamily};
  font-size: ${(props) => props.fontSize};
  font-style: ${(props) => (props.italic ? 'italic' : 'normal')};
`;

const RIGHT_PADDING = 15;
const LEFT_PADDING = 3;
const YAxis: FC<React.PropsWithChildren<YAxisProps>> = React.memo(function YAxisInternal({
  series,
  width,
  height,
  textAnchor,
  fontSize = height < 20 ? '10px' : '14px',
  labelFormatter = defaultFormatter,
}) {
  return (
    <>
      {series.map((serie, i) => {
        let text = serie.name;
        if (text) {
          const maxCharacters = calculateMaxCharacters(text, width - RIGHT_PADDING - LEFT_PADDING, {
            fontSize,
            fontFamily: 'Roboto',
            fontWeight: 'normal',
          });

          if (text.length > maxCharacters) {
            text = `${text.substring(0, maxCharacters - 3)}...`;
          }
        }
        const italic = serie.factorId === RESIDUAL_ID || serie.factorId === RISK_FREE_RATE_ID;
        return (
          <g key={serie.name}>
            <StyledText
              className="qa-yaxis"
              x={width - RIGHT_PADDING}
              y={(i + ROW_SHIFT) * height}
              height={height}
              textAnchor={textAnchor}
              text={text}
              italic={italic}
              style={labelFormatter(serie)}
              fontSize={fontSize}
            >
              {text}
            </StyledText>
          </g>
        );
      })}
    </>
  );
});

export default YAxis;
