import type { FactorLensWithReturns } from 'venn-api';
import type { FactorLensesContextValue } from 'venn-components';

const factorLenses = [
  {
    id: 61,
    name: 'TSFLWE V3 (USD)',
    remoteFactorLensId: 0,
    currency: 'USD',
    factors: [
      {
        id: 10000,
        name: 'Equity',
        description: 'Exposure to the long-term economic growth and profitability of companies',
        oldInputDescription: null,
        inputDescription:
          'The Equity factor is created from a GDP-weighted sum of constituent country equity gross returns from 1971-2001 and the MSCI All Country World Index, currency hedged from 2002. The factor is designed to be in excess of the risk-free rate.',
        category: 'Core Macro',
        inputsMarketNeutral: false,
        excessCashAdjusted: true,
        factorPeriods: [
          {
            periodId: null,
            factorIds: [10000],
            startDate: 1221436800000,
            endDate: 1236556800000,
            name: 'Global Financial Crisis of 2008',
            periodContext:
              "The Equity factor experienced poor performance during the Global Financial Crisis.','Excessive borrowing and lending in the United States resulted in the rise of the subprime residential mortgage market. These loans were packaged together as securitized products that received high-quality ratings from agencies. They eventually severely underperformed when the underlying borrowers started to default on their loans. Borrowers failed to meet their loan obligations in part because introductory periods of low interest rates ended, and higher interest rates kicked in (per the structure on most subprime mortgages, interest rates were initially low, but increased after one to five years)",
            created: null,
            updated: null,
            active: true,
            sequence: null,
          },
        ],
      },
      {
        id: 10001,
        name: 'Interest Rates',
        description: 'Exposure to the time value of money (interest rates and inflation risk)',
        oldInputDescription: null,
        inputDescription:
          'The Interest Rates factor is created from a GDP-weighted sum of constituent country interest rate gross returns from 1971-2001 and the Bloomberg Global Treasury 7-10 Year Index, currency hedged from 2002. The factor is designed to be in excess of the risk-free rate.',
        category: 'Core Macro',
        inputsMarketNeutral: false,
        excessCashAdjusted: true,
        factorPeriods: [
          {
            periodId: null,
            factorIds: [10001],
            startDate: 1055721600000,
            endDate: 1062460800000,
            name: 'Global Yields Spike in Mid 2003',
            periodContext:
              "The Interest Rates factor suffered in Q3 2003 when global yields spiked, resulting in lower global bond prices. Because the Interest Rates factor is based on global 7-10 year government bonds, a reduction in bond prices negatively impacts the factor’s returns.'",
            created: null,
            updated: null,
            active: true,
            sequence: null,
          },
        ],
      },
      {
        id: 10002,
        name: 'Credit',
        description:
          'Exposure to corporate default and failure-to-pay risks specific to developed market corporate bonds',
        oldInputDescription: null,
        inputDescription:
          'The Credit factor is created from an equal-weighted combination of various indices (specifics below). The factor is designed to be orthogonal to the Equity and Interest Rates factors and in excess of the risk-free rate.\\n\\n\\n\\n**1994-1998**\\n\\n50% Bloomberg US Corporate Index\\n\\n50% Bank of America Merrill Lynch High Yield Master II Index\\n\\n\\n\\n**1998-2000**\\n\\n50% Bloomberg US Corporate Index\\n\\n50% Bloomberg US Corporate High Yield Index\\n\\n\\n\\n**from 2000**\\n\\n25% Bloomberg US Corporate Index\\n\\n25% Bloomberg US Corporate High Yield Index\\n\\n25% Bloomberg Pan-European Aggregate Corporate Index, currency hedged\\n\\n25% Bloomberg Pan-European High Yield Index, currency hedged\\n\\n',
        category: 'Core Macro',
        inputsMarketNeutral: false,
        excessCashAdjusted: true,
        factorPeriods: [
          {
            periodId: null,
            factorIds: [10002],
            startDate: 1221436800000,
            endDate: 1236556800000,
            name: 'Global Financial/Credit Crisis of 2008',
            periodContext:
              "Credit markets suffered during the Global Financial/Credit Crisis.','Excessive borrowing and lending in the U.S. resulted in the rise of the subprime residential mortgage market. These loans were packaged together as securitized products that received high quality ratings from agencies. They eventually severely underperformed when the underlying borrowers started to default on their loans. Borrowers failed to meet their loan obligations in part because introductory periods of low interest rates ended, and higher interest rates kicked in (per the structure on most subprime mortgages, interest rates were initially low, but increased after one to five years)",
            created: null,
            updated: null,
            active: true,
            sequence: null,
          },
        ],
      },
      {
        id: 10003,
        name: 'Commodities',
        description: 'Exposure to changes in prices for hard assets',
        oldInputDescription: null,
        inputDescription:
          'The Commodities factor is created from the Bloomberg Commodity Index Total Return. The factor is designed to be orthogonal to the Equity and Interest Rates factors and a global currency basket (Short USD, Long G10 FX ex USD) and in excess of the risk-free rate.',
        category: 'Core Macro',
        inputsMarketNeutral: false,
        excessCashAdjusted: true,
        factorPeriods: [
          {
            periodId: null,
            factorIds: [10003],
            startDate: 1403136000000,
            endDate: 1453248000000,
            name: 'Oil Price Shocks of 2014 and 2015',
            periodContext:
              "['Commodities have generally exhibited poor performance since the Global Financial Crisis due to weak global demand and robust supply. The period covering mid-2014 to the beginning of 2016 was particularly troublesome for commodity markets as oil prices fell dramatically.','Oil prices dropped during this period after the Organization of the Petroleum Exporting Countries (OPEC), a cartel of certain oil-producing countries, announced its decision to maintain production levels to put pressure on the booming U.S. shale oil industry]",
            created: null,
            updated: null,
            active: true,
            sequence: null,
          },
        ],
      },
      {
        id: 10006,
        name: 'Emerging Markets',
        description: 'Exposure to the sovereign and economic risks of emerging markets relative to developed markets',
        oldInputDescription: null,
        inputDescription:
          'The Emerging Markets factor is created from a weighted combination of various indices (specifics below). The factor is designed to be orthogonal to the Equity, Interest Rates, Credit, and Commodities factors and a global currency basket (short USD, long G10 FX ex USD) and in excess of the risk-free rate.\\n\\n\\n\\n**1994-2000**\\n\\n33% Bloomberg Emerging Markets USD Aggregate Total Return Index\\n\\n67% MSCI Emerging Markets Index\\n\\n\\n\\n**from 2000**\\n\\n33% Bloomberg Emerging Markets USD Aggregate Total Return Index\\n\\n33% MSCI Emerging Markets Index\\n\\n33% MSCI Emerging Markets Total Currency Index',
        category: 'Secondary Macro',
        inputsMarketNeutral: false,
        excessCashAdjusted: true,
        factorPeriods: [],
      },
      {
        id: 10004,
        name: 'Foreign Currency',
        description: 'Exposure to moves in foreign currency values versus the portfolio’s local currency',
        oldInputDescription: null,
        inputDescription:
          'The G10 GDP-weighted basket is built with a short position in the local currency and long positions in the other nine currencies. The factor is designed to be orthogonal to the Equity, Interest Rates, Credit, and Commodities factors.',
        category: 'Secondary Macro',
        inputsMarketNeutral: false,
        excessCashAdjusted: true,
        factorPeriods: [],
      },
      {
        id: 10005,
        name: 'Local Inflation',
        description:
          'Exposure to inflation-linked rates relative to fixed nominal rates within the local currency area (only available in USD or GBP).',
        oldInputDescription: null,
        inputDescription:
          'The Local Inflation factor is created from the Bloomberg Inflation Linked 7-10 Year Total Return Index minus Bloomberg Treasury 7-10 Year Total Return Index. The factor is designed to be orthogonal to the Equity, Interest Rates, Credit, and Commodities factors.',
        category: 'Secondary Macro',
        inputsMarketNeutral: false,
        excessCashAdjusted: true,
        factorPeriods: [],
      },
      {
        id: 10008,
        name: 'Local Equity',
        description: 'Exposure to home bias (the tendency to invest in domestic over foreign equity).',
        oldInputDescription: null,
        inputDescription:
          'The Local Equity factor is created from Ken Frenchs U.S. Market Factor from 1971-1999 and the MSCI USA Net Total Return Local Index from 2000. The factor is designed to be orthogonal to the Equity factor and in excess of the local risk-free rate.',
        category: 'Secondary Macro',
        inputsMarketNeutral: false,
        excessCashAdjusted: true,
        factorPeriods: [],
      },
      {
        id: 10007,
        name: 'Equity Short Volatility',
        description: 'Negative exposure to the moves in equity market volatility',
        oldInputDescription: null,
        inputDescription:
          'The Equity Short Volatility factor is created from CBOE S&P 500 PutWrite Index. The factor is designed to be orthogonal to the Equity factor and in excess of the risk-free rate.',
        category: 'Macro Styles',
        inputsMarketNeutral: false,
        excessCashAdjusted: true,
        factorPeriods: [],
      },
      {
        id: 10037,
        name: 'Fixed Income Carry',
        description: 'Exposure to high-yielding 10-year bond futures funded by low-yielding 10-year bond futures',
        oldInputDescription: null,
        inputDescription:
          'The Fixed Income Carry factor is constructed using various carry metrics such as term spread for 10 year bond futures in the following 6 countries/regions: United States, United Kingdom, Canada, Australia, Japan, and Europe. The factor is designed to be orthogonal to the Interest Rates factor.',
        category: 'Macro Styles',
        inputsMarketNeutral: false,
        excessCashAdjusted: true,
        factorPeriods: [],
      },
      {
        id: 10038,
        name: 'Foreign Exchange Carry',
        description: 'Exposure to high-yielding G10 currencies funded by low-yielding G10 currencies',
        oldInputDescription: null,
        inputDescription:
          'The Foreign Exchange Carry factor is constructed using interest rate differentials for G10 currencies. The factor is designed to be orthogonal to the Equity factor.',
        category: 'Macro Styles',
        inputsMarketNeutral: false,
        excessCashAdjusted: true,
        factorPeriods: [],
      },
      {
        id: 10039,
        name: 'Trend Following',
        description: 'Long-short exposure to multi-asset-class futures based on 6- to 12-month trailing returns',
        oldInputDescription: null,
        inputDescription:
          'The Trend Following factor is constructed using 6 and 12 month trailing returns trends for futures contracts across fixed income, commodities, equities, and currencies.',
        category: 'Macro Styles',
        inputsMarketNeutral: false,
        excessCashAdjusted: true,
        factorPeriods: [],
      },
      {
        id: 10040,
        name: 'Low Risk',
        description: 'Exposure to stocks with low market betas and residual volatility funded by higher-risk stocks',
        oldInputDescription: null,
        inputDescription: null,
        category: 'Equity Styles',
        inputsMarketNeutral: true,
        excessCashAdjusted: true,
        factorPeriods: [
          {
            periodId: null,
            factorIds: [10040],
            startDate: 1241136000000,
            endDate: 1540944000000,
            name: 'Post Global Financial Crisis of 2008',
            periodContext:
              "['There are many competing hypotheses for why the Low Risk factor has performed well in the years following the Global Financial Crisis, including:','Declining interest rates providing a tailwind for lower-risk equities with more bond-like returns",
            created: null,
            updated: null,
            active: true,
            sequence: null,
          },
        ],
      },
      {
        id: 10041,
        name: 'Momentum',
        description: 'Exposure to stocks that have outperformed recently funded by underperforming stocks',
        oldInputDescription: null,
        inputDescription: null,
        category: 'Equity Styles',
        inputsMarketNeutral: true,
        excessCashAdjusted: true,
        factorPeriods: [
          {
            periodId: null,
            factorIds: [10041],
            startDate: 1214870400000,
            endDate: 1251676800000,
            name: '2009 Market Reversal',
            periodContext:
              "['The Momentum factor experienced poor performance during the 2009 market reversal.','The Momentum factor tends to suffer most at market \\'turning points\\'",
            created: null,
            updated: null,
            active: true,
            sequence: null,
          },
        ],
      },
      {
        id: 10042,
        name: 'Quality',
        description:
          'Exposure to stocks with low leverage, stable and high-quality earnings, and high profitability and investment quality, funded by lower-quality stocks',
        oldInputDescription: null,
        inputDescription: null,
        category: 'Equity Styles',
        inputsMarketNeutral: true,
        excessCashAdjusted: true,
        factorPeriods: [
          {
            periodId: null,
            factorIds: [10042],
            startDate: 1241136000000,
            endDate: 1540944000000,
            name: 'Post Global Financial Crisis of 2008',
            periodContext:
              "['The Quality factor, typically considered a defensive factor that outperforms in \\'flight-to-quality\\' environments",
            created: null,
            updated: null,
            active: true,
            sequence: null,
          },
        ],
      },
      {
        id: 10043,
        name: 'Value',
        description:
          'Exposure to stocks with low prices relative to accounting fundamentals and past prices, funded by higher-priced stocks',
        oldInputDescription: null,
        inputDescription: null,
        category: 'Equity Styles',
        inputsMarketNeutral: true,
        excessCashAdjusted: true,
        factorPeriods: [
          {
            periodId: null,
            factorIds: [10043],
            startDate: 1177977600000,
            endDate: 1217462400000,
            name: 'Pre Global Financial Crisis of 2008',
            periodContext:
              "['The Value factor experienced poor performance in the period leading up to the Global Financial Crisis.','While Value stocks tend to outperform on average, they may be more sensitive than growth stocks to large shocks in expected aggregate cash flows",
            created: null,
            updated: null,
            active: true,
            sequence: null,
          },
        ],
      },
      {
        id: 10044,
        name: 'Small Cap',
        description: 'Exposure to stocks with smaller market caps funded by larger-cap stocks',
        oldInputDescription: null,
        inputDescription: null,
        category: 'Equity Styles',
        inputsMarketNeutral: true,
        excessCashAdjusted: true,
        factorPeriods: [
          {
            periodId: null,
            factorIds: [10044],
            startDate: 1143849600000,
            endDate: 1228003200000,
            name: 'Pre Global Financial Crisis of 2008',
            periodContext:
              "['The Small Cap factor experienced poor performance in the period leading up to and during the Global Financial Crisis.','Smaller capitalization stocks tend to be more exposed to downturns in the equity market.','Recessions could affect the viability of a small cap company more so than a larger one, as worsening economic conditions are associated with a systematically larger decline in sales and investment for smaller firms than larger firms]",
            created: null,
            updated: null,
            active: true,
            sequence: null,
          },
        ],
      },
    ],
    primary: true,
    currencyAbbr: 'USD',
    earliestStartDate: 794102399999,
    latestStartDate: 794102399999,
    earliestEndDate: 1583798399999,
  },
  {
    id: 1,
    name: 'Barra Global Equity Model (GEM3)',
    remoteFactorLensId: 37,
    currency: 'USD',
    indexable: false,
    currencyAgnostic: true,
    factors: [
      {
        id: 381,
        name: 'World',
        description: 'World',
        inputDescription: null,
        category: null,
      },
      {
        id: 382,
        name: 'Beta',
        description: 'Beta',
        inputDescription: null,
        category: null,
      },
      {
        id: 383,
        name: 'Momentum',
        description: 'Momentum',
        inputDescription: null,
        category: null,
      },
      {
        id: 384,
        name: 'Size',
        description: 'Size',
        inputDescription: null,
        category: null,
      },
      {
        id: 385,
        name: 'Earnings Yield',
        description: 'Earnings Yield',
        inputDescription: null,
        category: null,
      },
      {
        id: 386,
        name: 'Residual Volatility',
        description: 'Residual Volatility',
        inputDescription: null,
        category: null,
      },
      {
        id: 387,
        name: 'Growth',
        description: 'Growth',
        inputDescription: null,
        category: null,
      },
      {
        id: 388,
        name: 'Dividend Yield',
        description: 'Dividend Yield',
        inputDescription: null,
        category: null,
      },
      {
        id: 389,
        name: 'Book to Price',
        description: 'Book to Price',
        inputDescription: null,
        category: null,
      },
      {
        id: 390,
        name: 'Leverage',
        description: 'Leverage',
        inputDescription: null,
        category: null,
      },
      {
        id: 391,
        name: 'Liquidity',
        description: 'Liquidity',
        inputDescription: null,
        category: null,
      },
      {
        id: 392,
        name: 'Size Nonlinearity',
        description: 'Size Nonlinearity',
        inputDescription: null,
        category: null,
      },
    ],
    currencyAbbr: 'USD',
    primary: false,
  },
] as unknown as FactorLensWithReturns[];

const notablePeriods = [
  {
    periodId: null,
    factorIds: [10000],
    startDate: 1584575999999,
    endDate: 1584662399999,
    name: 'Global Financial Crisis of 2008',
    periodContext:
      "['The Equity factor experienced poor performance during the Global Financial Crisis.','Excessive borrowing and lending in the United States resulted in the rise of the subprime residential mortgage market. These loans were packaged together as securitized products that received high-quality ratings from agencies. They eventually severely underperformed when the underlying borrowers started to default on their loans. Borrowers failed to meet their loan obligations in part because introductory periods of low interest rates ended, and higher interest rates kicked in (per the structure on most subprime mortgages, interest rates were initially low, but increased after one to five years)",
    created: null,
    updated: null,
    active: true,
    sequence: null,
  },
];

const factorLensesContext: FactorLensesContextValue = {
  factorLenses,
  primaryFactorLens: factorLenses[0],
  loading: false,
} as unknown as FactorLensesContextValue;

export { factorLensesContext, factorLenses, notablePeriods };
