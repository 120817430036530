import type {
  CreatedByOptionEnum,
  EntityFilterEnum,
  InvestmentSourceTypeEnum,
  LibraryItemTypeEnum,
  LibraryQuickFilterEnum,
  RemoteTypeEnum,
  SearchQuery,
} from 'venn-api';
import compact from 'lodash/compact';
import intersection from 'lodash/intersection';
import type { SelectedFilters } from '../types';
import { LibraryItemType, QuickFilter } from '../types';

export const PORTFOLIO_ITEM_TYPES = new Set([
  LibraryItemType.ALL,
  LibraryItemType.PORTFOLIO,
  LibraryItemType.PORTFOLIO_INTEGRATION,
  LibraryItemType.PRO_FORMA,
]);

export const INVESTMENT_ITEMS_TYPES = new Set([
  LibraryItemType.ALL,
  LibraryItemType.INVESTMENT,
  LibraryItemType.UPLOAD,
  LibraryItemType.INVESTMENT_INTEGRATION,
  LibraryItemType.MASTER_PORTFOLIO_HOLDING,
  LibraryItemType.VENN_PROVIDED,
  LibraryItemType.COMPOSITE_BENCHMARK,
]);

export const INTEGRATION_ITEM_TYPES = new Set([
  LibraryItemType.PORTFOLIO_INTEGRATION,
  LibraryItemType.INVESTMENT_INTEGRATION,
]);

/** Helper function to convert between string types and enums for the same thing */
// eslint-disable-next-line consistent-return
export function getLibraryQuickFilterFromString(item: LibraryQuickFilterEnum): QuickFilter {
  switch (item) {
    case 'recentlyAnalyzed':
      return QuickFilter.RECENTLY_ANALYZED;
    case 'uploadedByAnyone':
      return QuickFilter.UPLOADED_BY_ANYONE;
    case 'uploadedByMe':
      return QuickFilter.UPLOADED_BY_ME;
    case 'integrations':
      return QuickFilter.INTEGRATIONS;
    case 'requiresAttention':
      return QuickFilter.REQUIRES_ATTENTION;
  }
}

/** Helper function to convert between string types and enums for the same thing */
export function getLibraryItemTypeFromString(item: LibraryItemTypeEnum): LibraryItemType {
  switch (item) {
    case 'all':
      return LibraryItemType.ALL;
    case 'portfolio':
      return LibraryItemType.PORTFOLIO;
    case 'portfolioIntegration':
      return LibraryItemType.PORTFOLIO_INTEGRATION;
    case 'proForma':
      return LibraryItemType.PRO_FORMA;
    case 'compositeBenchmark':
      return LibraryItemType.COMPOSITE_BENCHMARK;
    case 'investment':
      return LibraryItemType.INVESTMENT;
    case 'upload':
      return LibraryItemType.UPLOAD;
    case 'investmentIntegration':
      return LibraryItemType.INVESTMENT_INTEGRATION;
    case 'masterPortfolioHolding':
      return LibraryItemType.MASTER_PORTFOLIO_HOLDING;
    case 'vennProvided':
      return LibraryItemType.VENN_PROVIDED;
    case 'none':
      return LibraryItemType.NONE;
    case 'tags':
      return LibraryItemType.TAGS;
  }
  return LibraryItemType.NONE;
}

/**
 * Get the API library search query params based on the selected filters.
 * Excludes entityFilters / investmentSources if the user does not have access.
 * @param quickFilters the quick filters to apply (an intersection of all these should apply to the resulting items)
 * @param itemType the item types to include
 * @param tags the tags that the resulting items must have
 */
export const getQueryParamsFromFilters = ({ quickFilters, itemType, tags }: SelectedFilters): Partial<SearchQuery> => {
  return {
    entityFilters: getEntityFilters(itemType),
    investmentSources: getInvestmentSources(quickFilters, itemType),
    recentlyViewed: quickFilters.includes(QuickFilter.RECENTLY_ANALYZED),
    remoteTypes: getRemoteTypes(quickFilters, itemType),
    createdBy: getCreatedBy(quickFilters),
    requiresAttention: quickFilters.includes(QuickFilter.REQUIRES_ATTENTION),
    inMasterOnly: itemType === LibraryItemType.MASTER_PORTFOLIO_HOLDING,
    tags: tags.length ? tags : [],
    quickFilters,
    itemType,
  };
};

export const getCreatedBy = (quickFilters: QuickFilter[]): CreatedByOptionEnum | undefined => {
  if (quickFilters.includes(QuickFilter.UPLOADED_BY_ME)) {
    return 'SELF';
  }
  if (quickFilters.includes(QuickFilter.UPLOADED_BY_ANYONE)) {
    return 'ANY_USER';
  }
  return undefined;
};

const getEntityFilters = (itemType: LibraryItemType): EntityFilterEnum[] => {
  return compact([
    PORTFOLIO_ITEM_TYPES.has(itemType) ? 'PORTFOLIO' : undefined,
    INVESTMENT_ITEMS_TYPES.has(itemType) ? 'FUND' : undefined,
  ]);
};

const getInvestmentSourcesForQuickFilter = (quickFilter: QuickFilter): InvestmentSourceTypeEnum[] | undefined => {
  switch (quickFilter) {
    case QuickFilter.INTEGRATIONS:
      return ['CUSTODIAN', 'EXTERNAL_INTEGRATION', 'EXTERNAL_BYOL'];
    default:
      return undefined;
  }
};

const getInvestmentSourcesForItemType = (itemType: LibraryItemType): InvestmentSourceTypeEnum[] | undefined => {
  switch (itemType) {
    case LibraryItemType.COMPOSITE_BENCHMARK:
      return ['CUSTOM'];
    case LibraryItemType.INVESTMENT:
    case LibraryItemType.MASTER_PORTFOLIO_HOLDING:
      return ['VENN', 'UPLOAD', 'CUSTODIAN', 'EXTERNAL_INTEGRATION', 'EXTERNAL_BYOL'];
    case LibraryItemType.INVESTMENT_INTEGRATION:
      return ['CUSTODIAN', 'EXTERNAL_INTEGRATION', 'EXTERNAL_BYOL'];
    case LibraryItemType.UPLOAD:
      return ['UPLOAD'];
    case LibraryItemType.VENN_PROVIDED:
      return ['VENN'];
    default:
      return undefined;
  }
};

const getInvestmentSources = (
  quickFilters: QuickFilter[],
  itemType: LibraryItemType,
): InvestmentSourceTypeEnum[] | undefined => {
  const investmentSources = compact([
    ...quickFilters.map((filter) => getInvestmentSourcesForQuickFilter(filter)),
    getInvestmentSourcesForItemType(itemType),
  ]);
  if (investmentSources.length) {
    return intersection(...investmentSources);
  }
  return undefined;
};

const getRemoteTypes = (quickFilters: QuickFilter[], itemType: LibraryItemType): RemoteTypeEnum[] | undefined => {
  if (quickFilters.includes(QuickFilter.INTEGRATIONS) || INTEGRATION_ITEM_TYPES.has(itemType)) {
    return ['CUSTODIAN', 'EXTERNAL', 'API'];
  }
  if (itemType === LibraryItemType.PRO_FORMA) {
    return ['LOCAL'];
  }
  return undefined;
};
