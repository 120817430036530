import React from 'react';
import styled from 'styled-components';
import { StyledLabel } from '../../../shared';
import PrivatesCashflowPacingSettingsTooltip from './components/PrivatesCashflowPacingSettingsTooltip';
import RiskPercentageDropDown from './components/RiskPercentageDropDown';
import { DropMenu, type DropMenuItem, Flexbox, GetColor, Icon, type Theme, Tooltip } from 'venn-ui-kit';
import { isNil, range } from 'lodash';
import { DEFAULT_YEARS } from '../../../../logic/studioUtils';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  blockConfidenceLevels,
  blockConfidenceLevelsVisibility,
  blockForecastWindow,
  blockInfoGraphicType,
  blockPrivateAssetAllocation,
  blockPrivateAssetAllocationVisibility,
  blockSettings,
  blockStartingNAV,
} from 'venn-state';
import { PanelSection } from '../../../PanelSection';
import { isPublicPrivateAssetGrowthBlock, useHasFF } from 'venn-utils';
import { ItemAllocation } from 'venn-components';

interface GrowthSimulationSectionProps {
  selected: string;
}

const getYearOptions = (n: number) => {
  return range(n).map((v) => ({ label: String(v + 1), value: v + 1 })); // exclude 0
};

const getRiskOptions = () => {
  const riskOptions: DropMenuItem<number>[] = [];
  for (let i = 0; i <= 100; i += 5) {
    if (i === 0) {
      riskOptions.push({ label: '1%', value: 0.01 });
    } else if (i === 100) {
      riskOptions.push({ label: '99%', value: 0.99 });
    } else {
      riskOptions.push({ label: `${String(i)}%`, value: i / 100 });
    }
  }
  return riskOptions;
};

const GrowthSimulationSection = ({ selected }: GrowthSimulationSectionProps) => {
  const blockSetting = useRecoilValue(blockSettings(selected));
  const customBlockType = blockSetting.customBlockType;
  const infographicType = useRecoilValue(blockInfoGraphicType(selected));
  const [confidenceLevels, setConfidenceLevels] = useRecoilState(blockConfidenceLevels(selected));
  const [forecastWindow, setForecastWindow] = useRecoilState(blockForecastWindow(selected));
  const [confidenceLevelsVisibility, setConfidenceLevelsVisibility] = useRecoilState(
    blockConfidenceLevelsVisibility(selected),
  );
  const [startingNav, setStartingNav] = useRecoilState(blockStartingNAV(selected));
  const [privateAssetAllocationPct, setPrivateAssetAllocationPct] = useRecoilState(
    blockPrivateAssetAllocation(selected),
  );
  const [privateAssetAllocationVisibility, setPrivateAssetAllocationVisibility] = useRecoilState(
    blockPrivateAssetAllocationVisibility(selected),
  );

  const allocationHasError =
    isNil(privateAssetAllocationPct) || privateAssetAllocationPct < 0 || privateAssetAllocationPct > 1;
  const yearOptions = getYearOptions(30);
  const riskOptions = getRiskOptions();

  const isBoxChart = infographicType === 'BOX_CHART';

  const hasPrivatesCashFlowSettingsFF = useHasFF('privates_hyperparameters_ff');
  const shouldShowCashflowPacingTooltip =
    hasPrivatesCashFlowSettingsFF && isPublicPrivateAssetGrowthBlock(customBlockType);

  return (
    <PanelSection header="Simulation Settings" className="qa-growth-simulation-settings">
      <Container>
        <div>
          <StyledLabel>Years Projected:</StyledLabel>
          <DropMenu
            items={yearOptions}
            selected={forecastWindow ?? DEFAULT_YEARS}
            className="qa-years-projected-dropdown"
            width={110}
            onChange={(item) => setForecastWindow(item.value)}
          />
        </div>
      </Container>
      <PaddingWrapper>
        <StyledLabel>Risk Percentile:</StyledLabel>
        <Container>
          {confidenceLevels.map((risk, i) => (
            <RiskPercentageDropDown
              selected={risk}
              onSelectRiskPercentage={setConfidenceLevels}
              onSelectVisibility={
                customBlockType === 'PUBLIC_PRIVATE_ASSET_GROWTH_BREAKDOWN' ? undefined : setConfidenceLevelsVisibility
              }
              items={riskOptions}
              index={i}
              visible={confidenceLevelsVisibility[i]}
              visibility={confidenceLevelsVisibility}
              riskPercentages={confidenceLevels}
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              disabled={isBoxChart}
            />
          ))}
        </Container>
      </PaddingWrapper>
      {isPublicPrivateAssetGrowthBlock(customBlockType) && (
        <PaddingWrapper>
          <StyledLabel>
            <Flexbox direction="row">
              Returns-Based Subject Starting NAV ($) &nbsp;
              <Tooltip usePortal content={<div>Starting NAV applies to benchmarks as well</div>} maxWidth={300}>
                <StyledIcon prefix="fas" type="circle-info" colorGetter={GetColor.Primary.Dark} />
              </Tooltip>
            </Flexbox>
            <StyledAllocation
              hasError={false}
              isGhost={false}
              isStrategy={false}
              isRoot={false}
              isModified={false}
              value={startingNav}
              originalValue={startingNav}
              onUpdateAllocation={setStartingNav}
              isOutsideOfSelectedSubtree={false}
              isPercentageMode={false}
            />
          </StyledLabel>
        </PaddingWrapper>
      )}
      {customBlockType === 'PUBLIC_PRIVATE_ASSET_GROWTH_BREAKDOWN' && (
        <PaddingWrapper>
          <StyledLabel>
            <Flexbox direction="row">Target Private Asset Allocation (%)</Flexbox>
            <StyledAllocation
              hasError={allocationHasError}
              isGhost={false}
              isStrategy={false}
              isRoot={false}
              isModified={false}
              baseAllocation={1.0}
              value={privateAssetAllocationPct}
              originalValue={privateAssetAllocationPct}
              onUpdateAllocation={setPrivateAssetAllocationPct}
              isOutsideOfSelectedSubtree={false}
              isPercentageMode
            />
            <ToggleVisibilityButton
              className="hide-private-asset-allocation-button"
              onClick={() => setPrivateAssetAllocationVisibility(!privateAssetAllocationVisibility)}
            >
              <Icon type={privateAssetAllocationVisibility ? 'eye' : 'eye-slash'} />
            </ToggleVisibilityButton>
          </StyledLabel>
        </PaddingWrapper>
      )}
      {shouldShowCashflowPacingTooltip && (
        <PaddingWrapper>
          <PrivatesCashflowPacingSettingsTooltip />
        </PaddingWrapper>
      )}
    </PanelSection>
  );
};

export default GrowthSimulationSection;

const ToggleVisibilityButton = styled.button`
  font-size: 14px;
`;

const PaddingWrapper = styled.div`
  padding-top: 15px;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledAllocation = styled(ItemAllocation)<{ hasError: boolean }>`
  .item-allocation-value-wrapper {
    padding-left: 0;
    width: 150px;
  }
  .key-blocking-numeric-input {
    border-radius: 4px;
    border-color: ${(props) => (props.hasError ? GetColor.Error : GetColor.Grey)};
    height: 35px;
  }
`;

export const StyledIcon = styled(Icon)<{ colorGetter: (props: { theme: Theme }) => string }>`
  color: ${(props) => props.colorGetter};
`;
