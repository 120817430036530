import isFunction from 'lodash/isFunction';
import type { DataExportEvent, AnalysisSubjectTypeEnum, AnalysisTypeEnumForDataExport } from 'venn-api';
import analyticsService from './generated';
import AnalyticsUtils, { getCustomTrackingOptions } from './analytics-utils';
import { IS_LOCAL } from '../environment';
import type { AnyDuringEslintMigration } from '../type/any';

export { AnalyticsUtils };
export type { DropdownOpened } from './generated';

const initPushStateListener = (history: History, location: Location) => {
  // Compose function from pushState
  const { pushState } = history;
  history.pushState = function historyPushState(state: unknown) {
    // TODO: extend history type in the same way window-extensions extends window type
    if (isFunction((history as AnyDuringEslintMigration).onpushstate)) {
      (history as AnyDuringEslintMigration).onpushstate({ state });
    }
    // Allow pushState to propagate
    const response = pushState.apply(history, arguments);
    // Custom page view event after pushState is used
    AnalyticsUtils.page(location.pathname);

    // Return pushState response to preserve history API;
    return response;
  };
};

const initReplaceStateListener = (history: History, location: Location) => {
  // Compose function from replaceState
  const replaceState = history?.replaceState;
  history.replaceState = function historyReplaceState() {
    // Allow replaceState to propagate
    const response = replaceState?.apply(history, arguments);
    // Custom page view event after replaceState is used
    AnalyticsUtils.page(location.pathname);

    // Return replaceState response to preserve history API;
    return response;
  };
};

const initPrintListener = (window: Window) => {
  // Safari + Chrome printing support
  if (window.matchMedia) {
    const mediaQueryList = window.matchMedia('print');
    mediaQueryList.addListener((mql) => {
      if (mql.matches) {
        // Custom track event before user has printed
        window.analytics.track('Printing Initialized');
      } else {
        // Custom track event after user has printed
        window.analytics.track('Printing Completed');
      }
    });
    // IE, Chrome, Firefox support
  } else {
    // Compose function from window.onbeforeprint
    const onbeforeprint = window?.onbeforeprint;
    window.onbeforeprint = function windowOnBeforePrint() {
      let response;
      // Allow onbeforeprint to propagate if already initialized
      if (onbeforeprint) {
        response = onbeforeprint.apply(window, arguments);
      }
      // Custom track event after user has printed
      window.analytics.track('Printing Initialized');
      // Return onbeforeprint response to preserve API;
      return response;
    };

    // Compose function from window.onafterprint
    const onafterprint = window?.onafterprint;
    window.onafterprint = function windowOnAfterPrint() {
      let response;
      // Allow onafterprint to propagate if already initialized
      if (onafterprint) {
        response = onafterprint.apply(window, arguments);
      }
      // Custom track event after user has printed
      window.analytics.track('Printing Completed');
      // Return onafterprint response to preserve API;
      return response;
    };
  }
};

const initPushAndReplaceStateListeners = () => {
  (({ history, location }: Window) => {
    initPushStateListener(history, location);
    initReplaceStateListener(history, location);
  })(window as Window);
};

const customTrack = (
  event: string,
  properties?: Record<string, unknown>,
  options?: SegmentAnalytics.SegmentOpts,
  callback?: () => void,
) => {
  const constructedOptions = {
    ...options,
    ...getCustomTrackingOptions(),
    integrations: {
      ...options?.integrations,
      ...getCustomTrackingOptions().integrations,
    },
    context: {
      ...options?.context,
      ...getCustomTrackingOptions().context,
    },
  };

  return window.analytics.track(event, properties, constructedOptions, callback);
};

// Wraps the window.analytics adding custom tracking only if window.analytics is not undefined.
const extendedAnalyics: SegmentAnalytics.AnalyticsJS | undefined =
  window.analytics !== undefined
    ? ({
        ...window.analytics,
        track: customTrack,
      } as SegmentAnalytics.AnalyticsJS)
    : undefined;

export const initialize = () => {
  function yourViolationHandler(message: Record<string, unknown>, violations: unknown[]) {
    if (IS_LOCAL) {
      // eslint-disable-next-line no-console
      console.error(`Typewriter Violation found in ${message.event}`, violations);
    }
  }

  analyticsService.setTypewriterOptions({
    onViolation: yourViolationHandler,
    analytics: extendedAnalyics,
  });

  // Custom analytics logic for page views
  initPushAndReplaceStateListeners();

  // High-level print listener
  initPrintListener(window);
};

export const generateExportEvent = (
  objectType: AnalysisSubjectTypeEnum,
  objectId: string | number | undefined,
  outputDescription: AnalysisTypeEnumForDataExport,
  userUploaded: boolean,
  relativeToBenchmark: boolean,
) => {
  const dataExportEvent: DataExportEvent = {
    subjectId: objectId ? String(objectId) : undefined,
    objectType,
    outputDescription,
    userUploaded,
    relativeToBenchmark,
  };
  return dataExportEvent;
};

export { analyticsService };
