import React from 'react';
import styled from 'styled-components';
import { GetColor, Icon, Tooltip } from 'venn-ui-kit';
import { SpecialCssClasses } from 'venn-utils';
import { CellWrapper } from './shared';

export const ScenarioReturnsTooltip = () => {
  const content = 'Performance of input over next 30 days.';
  return (
    <Tooltip content={content} maxWidth={240} usePortal>
      <InfoIcon type="info-circle" prefix="fas" className={SpecialCssClasses.NotDownloadable} />
    </Tooltip>
  );
};
const ScenarioHeaderCellRenderer = () => {
  const header = 'Shock Value';
  return (
    <ReturnHeader>
      {header} <ScenarioReturnsTooltip />
    </ReturnHeader>
  );
};

export default ScenarioHeaderCellRenderer;

const InfoIcon = styled(Icon)`
  margin-left: 5px;
`;

const ReturnHeader = styled(CellWrapper)`
  height: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  color: ${GetColor.Black};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
