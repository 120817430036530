import React from 'react';
import { Icon, Tooltip } from 'venn-ui-kit';
import type { Portfolio } from 'venn-api';
import { ActionIcon, Actions, ActiveIcon, HighlightIcon } from './styled';
import { isNil } from 'lodash';

interface ConstraintActionsProps {
  portfolio: Portfolio;
  isInPortfolioPolicy: boolean;
  isExperimental?: boolean;
  canApplyConstraint?: boolean;
  onApplyConstraint?: () => void;
  onDeleteConstraint: () => void;
  canResetConstraint?: boolean;
  onResetConstraint?: () => void;
  disabled?: boolean;
}

export const NEW_CONSTRAINT_MESSAGE =
  'This constraint automatically applies to all funds that are being considered as a new addition to this portfolio.';

const ConstraintActions = ({
  portfolio,
  isInPortfolioPolicy,
  isExperimental,
  canApplyConstraint,
  onApplyConstraint,
  onDeleteConstraint,
  canResetConstraint,
  onResetConstraint,
  disabled,
}: ConstraintActionsProps) => {
  return (
    <Actions>
      {isInPortfolioPolicy && (
        <Tooltip
          usePortal
          content={
            <div>
              This constraint is a part of <strong>{portfolio.name}'s</strong> investment policy.
            </div>
          }
        >
          <ActiveIcon type="file-check" />
        </Tooltip>
      )}
      {canApplyConstraint && !isInPortfolioPolicy && (
        <Tooltip
          usePortal
          isHidden={isNil(onApplyConstraint)}
          content={
            <div>
              Add this constraint to <strong>{portfolio.name}'s</strong> investment policy.
            </div>
          }
        >
          <ActionIcon type="button" onClick={onApplyConstraint} disabled={isNil(onApplyConstraint) || disabled}>
            <Icon type="file-export" />
          </ActionIcon>
        </Tooltip>
      )}
      {isExperimental && (
        <Tooltip usePortal content={<div>{NEW_CONSTRAINT_MESSAGE}</div>}>
          <HighlightIcon type="flask" />
        </Tooltip>
      )}
      {canResetConstraint && !isNil(onResetConstraint) && (
        <Tooltip content="Restore to existing constraint" usePortal>
          <ActionIcon type="button" onClick={onResetConstraint} disabled={disabled}>
            <Icon type="undo" />
          </ActionIcon>
        </Tooltip>
      )}
      <Tooltip content="Delete constraint" usePortal>
        <ActionIcon destructive type="button" onClick={onDeleteConstraint} disabled={disabled}>
          <Icon type="times-circle" />
        </ActionIcon>
      </Tooltip>
    </Actions>
  );
};

export default ConstraintActions;
