import type { History, Location, Action } from 'history';
import queryString from 'query-string';

/**
 * Updates the location object with a new query parameter.
 * @param history
 * @param name The parameter name.
 * @param value The parameter value.
 * @param action
 */
export const updateUrlParam = (history: History, action: Action, name: string, value?: string | string[]) => {
  const params = queryString.parse(history.location.search.replace('?', ''));
  params[name] = Array.isArray(value) ? value.join(',') : value;
  Object.keys(params).forEach((key) => params[key] === undefined && delete params[key]);
  const update = action === 'REPLACE' ? history.replace : history.push;
  const query = queryString.stringify(params);
  if (`?${query}` !== history.location.search) {
    update(`${history.location.pathname}?${query}`, action === 'REPLACE' ? history.location.state : undefined);
  }
};

/**
 * Parses a single value from the location's query parameters.
 * @param location
 * @param name
 */
export const parseValueFromLocation = (location: Location<unknown>, name: string): string | undefined => {
  const value = queryString.parse(location.search.replace('?', ''))[name];
  if (!value) {
    return undefined;
  }
  if (Array.isArray(value)) {
    return value[0];
  }
  return value;
};

/**
 * Attempts to parse an array of query params.
 * @param location
 * @param name
 */
export const parseValuesFromLocation = (location: Location<unknown>, name: string): string[] => {
  const value = queryString.parse(location.search.replace('?', ''))[name];
  if (!value) {
    return [];
  }
  return Array.isArray(value) ? value : value.split(',');
};

/**
 * Same as parseValueFromLocation, but parses the value to a number
 */
export const parseIntValueFromLocation = <T>(location: Location<T>, name: string): number | undefined => {
  const stringValue = parseValueFromLocation(location, name);
  if (stringValue === undefined) {
    return undefined;
  }
  const numericValue = Number.parseInt(stringValue, 10);
  return Number.isNaN(numericValue) ? undefined : numericValue;
};

/**
 * Updates the location object with several params in object.
 * @param history
 * @param newParams new params to update in url
 */
export const updateUrlParams = (history: History, newParams: Record<string, unknown>) => {
  const params = queryString.parse(history.location.search.replace('?', ''));
  Object.keys(newParams).forEach((key) => {
    const value = newParams[key];
    params[key] = Array.isArray(value) ? value.join(',') : value;
  });

  Object.keys(params).forEach(
    (key) => (params[key] === undefined || params[key] === false || params[key] === '') && delete params[key],
  );
  const query = queryString.stringify(params);
  if (`?${query}` !== history.location.search) {
    history.replace(`${history.location.pathname}?${query}`);
  }
};
