import React, { Component } from 'react';
import styled from 'styled-components';
import { ExternalActivityListener, LegacyRelativePortal } from 'venn-ui-kit';
import { ProxyPicker } from '../pickers/proxy-picker';
import type { FundProxy, FundToProxy } from '../pickers/types';
import { Modal } from '../../modal';

type ProxyDropdownDirection = 'left' | 'right';
type DropdownVerticalDirection = 'down' | 'up';

export interface ProxyTriggerProps extends ProxySelectorState {
  onToggleSelector: () => void;
  isProxyUpdating: boolean;
}

export interface ProxySelectorProps {
  investment: FundToProxy;
  proxy: FundProxy | null;
  dropdownDirection?: ProxyDropdownDirection;
  dropdownVerticalDirection?: DropdownVerticalDirection;
  hoverAddProxy?: boolean;
  onOpen?: (fundId: string) => void;
  onClose?: (fundId: string) => void;

  /** The function to call with the new proxy (undefined if proxy was removed)
   * if the investment's proxy has been changed. */
  onProxyChange(proxy?: FundProxy): void | Promise<void>;

  usePortal?: boolean;
  portalLeftOffset?: number;
  portalTopOffset?: number;
  children: (renderProps: ProxyTriggerProps) => React.ReactNode;
  loading?: boolean;
  readOnly?: boolean;
}

interface ProxySelectorState {
  selectorOpen: boolean;
  selectedProxy: FundProxy | null;
}

export default class ProxySelector extends Component<ProxySelectorProps, ProxySelectorState> {
  state: ProxySelectorState = {
    selectorOpen: false,
    selectedProxy: null,
  };

  static getDerivedStateFromProps(nextProps: ProxySelectorProps) {
    return {
      selectedProxy: nextProps.proxy,
    };
  }

  toggleSelector = () => {
    const { selectorOpen } = this.state;
    const { onClose, onOpen, investment } = this.props;
    if (selectorOpen) {
      onClose?.(investment.id);
    } else {
      onOpen?.(investment.id);
    }
    this.setState({ selectorOpen: !selectorOpen });
  };

  closeSelector = () => {
    const { onClose, investment } = this.props;
    onClose?.(investment.id);
    this.setState({ selectorOpen: false });
  };

  saveAndCloseSelector = (saveFn: () => void) => () => {
    const { onClose, investment } = this.props;
    this.setState({ selectorOpen: false }, () => {
      saveFn();
      onClose?.(investment.id);
    });
  };

  onProxyChange = async (proxy?: FundProxy) => {
    const { onProxyChange } = this.props;
    this.closeSelector();
    await onProxyChange(proxy);
  };

  renderRelativeWrapper(children: JSX.Element) {
    const { usePortal, portalLeftOffset, portalTopOffset } = this.props;
    return usePortal ? (
      <LegacyRelativePortal left={portalLeftOffset ?? 50} top={portalTopOffset}>
        {children}
      </LegacyRelativePortal>
    ) : (
      children
    );
  }

  render() {
    const { investment, usePortal, children, loading, readOnly } = this.props;
    const { selectedProxy, selectorOpen } = this.state;
    return (
      <ProxyPicker
        wrapperRenderer={({ picker, isProxyUpdating, saveUnsavedChanges }) => (
          <StyledProxySelector className="qa-proxy-cell">
            {children({
              ...this.state,
              onToggleSelector: this.toggleSelector,
              isProxyUpdating: Boolean(loading) || isProxyUpdating,
            })}
            {selectorOpen
              ? this.renderRelativeWrapper(
                  <SelectorContainer closeOnEsc noPadding>
                    <ExternalActivityListener onExternalActivity={this.saveAndCloseSelector(saveUnsavedChanges)}>
                      {picker}
                    </ExternalActivityListener>
                  </SelectorContainer>,
                )
              : null}
          </StyledProxySelector>
        )}
        reset={selectorOpen}
        proxy={selectedProxy}
        investment={investment}
        onProxyChange={this.onProxyChange}
        disableAutofocus={usePortal}
        loadPickerData={selectorOpen}
        closePicker={this.closeSelector}
        readOnly={readOnly}
      />
    );
  }
}

const StyledProxySelector = styled.div`
  position: relative;
  width: 100%;
`;

const SelectorContainer = styled(Modal)`
  &.modal-wrapper {
    width: auto;
  }
`;
