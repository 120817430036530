import { DATEPICKER_FREQUENCY_FORMATS, DISPLAY_FREQUENCY_FORMATS, FrequencyTypes } from '../frequency';
import type { FrequencyEnum, LibrarySearchEntity } from 'venn-api';
import type { Moment } from 'moment';
import moment from 'moment';
import type { DateRange, Granularity } from 'venn-ui-kit';
import isNil from 'lodash/isNil';

export const getDateFormat = (frequencyId?: FrequencyTypes | FrequencyEnum): string =>
  DATEPICKER_FREQUENCY_FORMATS[frequencyId || FrequencyTypes.MONTHLY];

export const getDateFormatForDisplay = (frequencyId?: FrequencyTypes | FrequencyEnum): string =>
  DISPLAY_FREQUENCY_FORMATS[frequencyId || FrequencyTypes.MONTHLY];

export const getFormattedFrequency = (frequency: FrequencyEnum): string => {
  switch (frequency) {
    case 'DAILY':
      return 'daily';
    case 'MONTHLY':
      return 'monthly';
    case 'QUARTERLY':
      return 'quarterly';
    case 'YEARLY':
      return 'yearly';
    default:
      return 'unknown';
  }
};

export function toEndOfPeriod(timestamp: number | string, granularity: Granularity): number;
export function toEndOfPeriod(timestamp: number | string | undefined, granularity: Granularity): number | undefined;
export function toEndOfPeriod(timestamp: moment.MomentInput, granularity: Granularity) {
  return timestamp !== undefined ? moment.utc(timestamp).endOf(granularity).valueOf() : undefined;
}

function lastBusinessDayOfMonth(dateMoment: Moment): Moment {
  const lastBusinessDayOfMonthMoment = dateMoment.endOf('month');

  if (lastBusinessDayOfMonthMoment.day() === 0) {
    lastBusinessDayOfMonthMoment.subtract(2, 'days');
  } else if (lastBusinessDayOfMonthMoment.day() === 6) {
    lastBusinessDayOfMonthMoment.subtract(1, 'day');
  }
  return lastBusinessDayOfMonthMoment;
}

export const containsLastWorkingDayOfMonth = (start: number, end: number): boolean => {
  const startMoment = moment.utc(start);
  const endMoment = moment.utc(end);

  const lastBusinessDayOfStartMonthEnd = lastBusinessDayOfMonth(moment.utc(startMoment));
  const lastBusinessDayOfStartMonthStart = moment.utc(lastBusinessDayOfStartMonthEnd).startOf('day');

  return (
    startMoment.valueOf() <= lastBusinessDayOfStartMonthEnd.valueOf() &&
    lastBusinessDayOfStartMonthStart.valueOf() <= endMoment.valueOf()
  );
};

export const isLessThanOneFactorTrendDataPoint = (
  start: number,
  end: number,
  frequency: FrequencyEnum,
): boolean | undefined => {
  const validFrequency = frequency === 'DAILY' || frequency === 'MONTHLY' || frequency === 'QUARTERLY';

  if (!validFrequency) {
    return undefined;
  }

  // For monthly and quarterly frequency, we can never have less than one data point, which is 1 month and 1 quarter,
  // respectively. The problem can only occur for daily frequency, for which 1 data point = 1 month.
  if (frequency === 'MONTHLY' || frequency === 'QUARTERLY') {
    return false;
  }

  return !containsLastWorkingDayOfMonth(start, end);
};

/**
 * Get the first day of negative returns in the drawdown.
 * This always occurs the day after the start of the drawdown.
 * See https://twosigma.atlassian.net/browse/VENN-9593
 */
export const getFirstNegativeDay = (start?: number) => {
  const negativeStart = moment.utc(start);
  negativeStart.add(1, 'days');
  return negativeStart;
};

// Using (end + 1) for `moment.diff` when the analyzed item is of monthly frequency because `moment.diff` is not
// precise for "months"/"years" units (especially the months of the different length). Adding 1ms is a hack that
// will help us show the right length in months that are shorter than average.
export const getPeriodLengthWithFrequency = (frequency: FrequencyEnum | undefined, start: number, end: number) => {
  // Special case to handle quarterly dates that have the same start and end which indicates that period is the entire quarterø
  if (frequency === 'QUARTERLY' && start === end) {
    return '3.0';
  }

  const months = moment.utc(end).diff(moment.utc(start), 'months', true);
  // Moment has some odd handling of months so we just round it for monthly/quarterly frequency ourselves
  return frequency === 'DAILY' ? months.toFixed(1) : Math.round(months).toFixed(1);
};

export const getCurrentTimeStamp = (): string => moment().format('YYYY-MM-DD hh:mm A');

export const hasNoOverlap = (dateRange?: DateRange, entity?: LibrarySearchEntity): boolean => {
  if (!entity || isNil(dateRange?.from) || isNil(dateRange?.to)) {
    return false;
  }
  return dateRange.from > entity.endRange || dateRange.to < entity.startRange;
};
