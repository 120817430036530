import React from 'react';
import styled from 'styled-components';
import { buttonize } from 'venn-utils';
import { GetColor } from 'venn-ui-kit';
import type { DateRangeInputId } from 'venn-state';
import {
  DateRangeInputRow,
  InputRowWrapper,
} from '../../../../studio-main-left-panel/panels/components/DateRangeInputRow';
import { HoverIcon } from '../subject-input/HoverIcon';

export const SelectDateRangeInputSection = ({
  inputId,
  isChecked,
  onClick,
  isPrivate,
}: {
  inputId: DateRangeInputId;
  /** Whether to start out checked, otherwise will show a checkmark icon on hover. */
  isChecked: boolean;
  /** Invoked with the same inputId provided to this component, for ease of use. */
  onClick: (inputId: DateRangeInputId) => void;
  isPrivate: boolean;
}) => {
  return (
    <DateRangeInputSectionWrapper {...buttonize(() => onClick(inputId))}>
      <HoverIcon
        // Whole row is selectable, so icon shouldn't be.
        tabIndex={-1}
        style={{ padding: '6px' }}
        type="check"
        visible={isChecked}
        hoverColor={GetColor.Primary.Dark}
      />
      <DateRangeInputRow inputId={inputId} readonly isPrivate={isPrivate} deletable />
    </DateRangeInputSectionWrapper>
  );
};

/** Provides layout, spacing, and container styling. */
const DateRangeInputSectionWrapper = styled(InputRowWrapper)`
  display: flex;
  align-items: baseline;

  margin-top: 2px;
  padding-top: 6px;
  padding-bottom: 6px;
  margin-bottom: 10px;

  > div > div {
    padding: 5px 15px 5px 0;
  }

  &:hover {
    cursor: pointer;
    background-color: ${GetColor.WhiteGrey};

    i {
      opacity: 1;
    }
  }
`;
