import { useQuery } from '@tanstack/react-query';
import { type CashFlowSetting, getFundsUsingSetting, getUserCreatedCashflowSettings, type PrivateFund } from 'venn-api';
import { logExceptionIntoSentry } from 'venn-utils';

export const WORKSPACE_CASHFLOW_SETTINGS_KEY = 'WORKSPACE_CASHFLOW_SETTINGS_KEY';
export const FUNDS_USING_SETTINGS_KEY = 'FUNDS_USING_SETTINGS_KEY';

export const useWorkspaceCashflowSettings = () => {
  return useQuery<CashFlowSetting[]>(
    [WORKSPACE_CASHFLOW_SETTINGS_KEY],
    async () => {
      return (await getUserCreatedCashflowSettings()).content;
    },
    {
      onError: (error) => {
        return logExceptionIntoSentry(error as Error);
      },
      suspense: true,
    },
  );
};

export const useFundsUsingSetting = (settingId: string) => {
  return useQuery<PrivateFund[]>(
    [FUNDS_USING_SETTINGS_KEY, settingId],
    async () => {
      return Object.values((await getFundsUsingSetting(settingId)).content);
    },
    {
      onError: (error) => {
        return logExceptionIntoSentry(error as Error);
      },
      suspense: true,
    },
  );
};
