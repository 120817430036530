// Older browsers may return 'Spacebar' instead of ' ' for the Space Bar key.
const BUTTON_CLICK_KEY = new Set(['Enter', 'Spacebar', ' ']);

/**
 *  Helper function to convert an onClick handler into all the necessary props for an accessible "clickable" element.
 *  This is useful when you would like button behaviour but cannot use a button element.
 * @param handlerFunc The handlerFunc which will be call onClick and on "Enter" pressed.
 * @param isLink Whether a link is being buttonized or not. If it is a link, then the onKeyPress handler isn't set
 */
export const buttonize = <T extends HTMLElement>(
  handlerFunc: (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => void,
  isLink = false,
) => {
  return {
    role: 'button',
    onClick: handlerFunc,
    tabIndex: 0,
    onKeyPress: isLink
      ? undefined
      : (event: React.KeyboardEvent<T>) => BUTTON_CLICK_KEY.has(event.key) && handlerFunc(event),
  };
};
