import styled, { type FlattenSimpleInterpolation } from 'styled-components';

interface FlexboxProps {
  display?: 'flex' | 'inline-flex';
  direction: 'row' | 'column';
  alignItems?: 'baseline' | 'center' | 'flex-end' | 'flex-start' | 'stretch';
  justifyContent?: 'center' | 'flex-end' | 'flex-start' | 'space-around' | 'space-between' | 'space-evenly';
  grow?: number;
  gap?: number;
  rowGap?: number;
  columnGap?: number;
  css?: FlattenSimpleInterpolation;
}

const Flexbox = styled.div<FlexboxProps>`
  display: ${({ display = 'flex' }) => display};
  flex-direction: ${({ direction }) => direction};
  ${({ alignItems }) => (alignItems ? `align-items: ${alignItems}` : '')};
  ${({ justifyContent }) => (justifyContent ? `justify-content: ${justifyContent}` : '')};
  ${({ grow }) => (grow ? `flex-grow: ${grow}` : '')};
  ${({ gap }) => (gap ? `gap: ${gap}px` : '')};
  ${({ rowGap }) => (rowGap ? `row-gap: ${rowGap}px` : '')};
  ${({ columnGap }) => (columnGap ? `column-gap: ${columnGap}px` : '')};
  ${({ css }) => css}
`;

export default Flexbox;
