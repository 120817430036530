import { useState, useCallback, useEffect } from 'react';
import type { OperationResult } from 'venn-api';
import { trim } from 'lodash';
import { useDebounce } from './useDebounce';

const NAME_VALIDATION_DEBOUNCE = 500;

/**
 * Check if name already used
 */
const useNameExist = (
  onChange: (field: string | undefined) => void,
  checkFunc: (name: string) => Promise<OperationResult<boolean>>,
  isRename: boolean,
  initialValue?: string,
  customLabel = 'view',
) => {
  const [name, setName] = useState<string | undefined>(initialValue);
  const [debouncedName] = useDebounce(name, NAME_VALIDATION_DEBOUNCE);
  const [error, setError] = useState<string | undefined>();
  const [isChecking, setIsChecking] = useState(false);

  const checkIfNameExists = useCallback(
    async (input: string) => {
      if (!input) {
        return;
      }

      try {
        setIsChecking(true);
        const isUsed = (await checkFunc(input)).content;
        setError(
          isUsed
            ? `"${input.trim()}" already exists in your organization. Please select a different name for this ${customLabel}. `
            : '',
        );
        onChange(debouncedName);
      } catch (e) {
        const { content } = e;
        setError(content?.message);
        onChange(debouncedName);
      }
      setIsChecking(false);
    },
    [debouncedName, onChange, checkFunc, customLabel],
  );

  const onInputChange = useCallback((input: string) => {
    setName(input);
  }, []);

  useEffect(() => {
    setError('');
  }, [name]);

  useEffect(() => {
    if (debouncedName !== undefined) {
      if (debouncedName.length === 0) {
        setError(`${customLabel} name must not be empty`);
        onChange(debouncedName);
        // Not need to check name if it's reanme and initial value equals to debounced value
      } else if (initialValue === undefined || trim(initialValue) !== trim(debouncedName) || !isRename) {
        checkIfNameExists(debouncedName);
      }
    } else {
      onChange(debouncedName);
    }
  }, [checkIfNameExists, debouncedName, onChange, initialValue, customLabel, isRename]);

  return {
    onInputChange,
    error,
    name,
    isChecking,
  };
};

export default useNameExist;
