import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import LegendEntry from './LegendEntry';

const LegendWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 5px;
`;

export interface LegendSeries {
  color: string;
  name?: string;
  borderColor?: string;
  fillColor?: string;
  height?: number;
  fee?: number;
  iconType?: string;
  legend?: string;
  fontSize?: string;
  feesExcluded?: boolean;
  hasProxy?: boolean;
}

export interface LegendProps {
  /** Legend container classname */
  className?: string;
  /** { color: string, name: string } */
  series: LegendSeries[];
  /** Icon type to display on the legend
   * If individual items have iconType defined, that takes precedence over this field */
  iconType?: string;
  fontSize?: string;
}

export const Legend: FC<React.PropsWithChildren<LegendProps>> = ({
  className,
  iconType,
  series,
  children,
  fontSize,
}) => (
  <LegendWrapper className={className}>
    {series.map((legendEntry) => (
      <LegendEntry
        fontSize={fontSize}
        key={legendEntry?.name}
        iconType={legendEntry?.iconType ?? iconType}
        {...legendEntry}
      />
    ))}
    {children}
  </LegendWrapper>
);

export default Legend;
