import { useState, useEffect, useRef, useCallback } from 'react';
import type {
  AnalysisRequest,
  FactorForecast,
  PerformanceSummaryResponse,
  Portfolio,
  PortfolioSummary,
} from 'venn-api';
import { analysis, getCurrentForecast, getDraftSummary } from 'venn-api';
import { useApi } from 'venn-utils';
import { isNil } from 'lodash';

export interface UsePortfolioSummaryValue {
  summary?: PortfolioSummary;
  summaryLoading: boolean;
  summaryError: boolean;
  historicalReturn?: number;
  historicalVolatility?: number;
  factorForecasts: FactorForecast[];
  onForecastUpdated: () => void;
}

type HistoricalSummaryWithPortfolioId = PerformanceSummaryResponse & { portfolioId: number };

export default (portfolio: Portfolio | undefined): UsePortfolioSummaryValue => {
  const [summaryLoading, setSummaryLoading] = useState<boolean>(false);
  const [summaryError, setSummaryError] = useState<boolean>(false);
  const [summary, setSummary] = useState<PortfolioSummary | undefined>();

  const summaryApiRef = useRef(useApi(getDraftSummary));
  const fetchSummary = useCallback(async () => {
    if (isNil(portfolio)) {
      return;
    }

    setSummaryLoading(true);
    try {
      const result = (await summaryApiRef.current(portfolio)).content;
      setSummary(result);
      setSummaryError(false);
      setSummaryLoading(false);
    } catch (e) {
      if (e.name !== 'AbortError') {
        setSummary(undefined);
        setSummaryError(true);
        setSummaryLoading(false);
      }
    }
  }, [portfolio]);

  useEffect(() => {
    if (!isNil(portfolio) && portfolio.id !== summary?.id) {
      fetchSummary();
    }
  }, [portfolio, fetchSummary, summary]);

  const [historicalLoading, setHistoricalLoading] = useState<boolean>(false);
  const [historicalError, setHistoricalError] = useState<boolean>(false);
  const [historicalSummary, setHistoricalSummary] = useState<HistoricalSummaryWithPortfolioId | undefined>(undefined);

  const historicalApiRef = useRef(useApi(analysis));

  useEffect(() => {
    if (!summaryError) {
      return;
    }

    const fetchHistoricalSummary = async () => {
      setHistoricalLoading(true);
      try {
        const result = (await historicalApiRef.current(getHistoricalPerformanceRequestConfig(portfolio!))).content
          .analyses[0].historicalPerformanceSummary[0];
        setHistoricalSummary({ ...result, portfolioId: portfolio!.id });
        setHistoricalError(false);
        setHistoricalLoading(false);
      } catch (e) {
        if (e.name !== 'AbortError') {
          setHistoricalSummary(undefined);
          setHistoricalError(true);
          setHistoricalLoading(false);
        }
      }
    };
    if (!isNil(portfolio) && historicalSummary?.portfolioId !== portfolio.id) {
      fetchHistoricalSummary();
    }
  }, [summaryError, portfolio, historicalSummary]);

  const historicalIrrelevant = summaryLoading || historicalLoading || !summaryError;

  const [factorForecasts, setFactorForecasts] = useState<FactorForecast[]>();
  const [loadingForecast, setLoadingForecast] = useState(false);

  const fetchForecasts = useCallback(async () => {
    try {
      const { content } = await getCurrentForecast();
      setFactorForecasts(content.factorForecasts);
    } catch (e) {
      setFactorForecasts([]);
    }
    setLoadingForecast(false);
  }, []);

  useEffect(() => {
    if (isNil(factorForecasts) && !loadingForecast) {
      setLoadingForecast(true);
      fetchForecasts();
    }
  }, [factorForecasts, loadingForecast, fetchForecasts]);

  const onForecastUpdated = useCallback(() => {
    fetchSummary();
    fetchForecasts();
  }, [fetchSummary, fetchForecasts]);

  return {
    summary: summaryLoading ? undefined : summary,
    summaryLoading: summaryLoading || historicalLoading,
    summaryError: summaryError && historicalError,
    historicalReturn: historicalIrrelevant ? undefined : historicalSummary?.totalReturn,
    historicalVolatility: historicalIrrelevant ? undefined : historicalSummary?.volatility,
    factorForecasts: factorForecasts ?? [],
    onForecastUpdated,
  };
};

function getHistoricalPerformanceRequestConfig(portfolio: Portfolio): Partial<AnalysisRequest> {
  return {
    analyses: [
      {
        analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
        relative: false,
        scenarios: [],
        selectedNotablePeriods: [],
      },
    ],
    subjects: [
      {
        subjectType: 'PORTFOLIO',
        comparisonType: 'PRIMARY',
        portfolio,
        id: portfolio.id.toString(),
        primary: true,
        isPrivate: false,
      },
    ],
  };
}
