import type { SponsoredAccountsResults } from 'venn-api';

const sponsoredAccountResults = {
  activeAccounts: 20,
  invitedAccounts: 30,
  total: 50,
  accounts: [
    {
      accountId: 2,
      accountName: 'Sponsored account',
      status: 'ACTIVE',
    },
    {
      invitationId: 'abcd',
      invitationEmail: 'invited@acd.com',
      accountName: 'Invited account',
      invitationExpirationDate: 1579907541781,
    },
    {
      accountId: 5,
      accountName: 'Inactive account',
      status: 'INACTIVE',
    },
  ],
} as unknown as SponsoredAccountsResults;

export default sponsoredAccountResults;
