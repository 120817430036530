import type { Portfolio } from 'venn-api';
import { sortBy, partition, sumBy } from 'lodash';
import { logMessageToSentry } from '../error-logging';

export const mapPortfolioToNodes = (portfolio?: Portfolio): Map<number, Portfolio> => {
  const mapped = new Map<number, Portfolio>();
  const mapRecursive = (node?: Portfolio) => {
    if (!node) {
      return;
    }
    mapped.set(node.id, node);
    if (!node.children) {
      return;
    }
    node.children.forEach((child: Portfolio) => {
      mapRecursive(child);
    });
  };
  mapRecursive(portfolio);
  return mapped;
};

/**
 * Transforms a `Portfolio` into a "normalized" portfolio, meaning that we can quarantee that each strategy has no more
 * than 1 occurrence of a `Fund` with a specific `fund.id`.
 */
export const normalizePortfolio = (portfolio: Portfolio): Portfolio => {
  if (portfolio.fund || !portfolio.children || portfolio.children.length === 0) {
    return { ...portfolio };
  }

  const childFunds = [...portfolio.children].filter((child: Portfolio) => child.fund !== undefined);
  const sortedChildFunds = sortBy(childFunds, ['fund.id']);

  // Map from `strategy.fund.id` to `strategy.allocation` for FUNDS.
  // It will be used to sum up the allocations of repeated funds under the same strategy.
  const childFundToAllocation = new Map<string, number | undefined>();
  sortedChildFunds.forEach((childFund: Portfolio) => {
    if (!childFund.fund) {
      return;
    }
    if (childFundToAllocation.has(childFund.fund.id)) {
      // If this fund is already a part of this strategy, add the allocations together.
      let partialChildFundAllocation = childFundToAllocation.get(childFund.fund.id) ?? 0;
      partialChildFundAllocation += childFund.allocation ?? 0;
      childFundToAllocation.set(childFund.fund.id, partialChildFundAllocation);
      return;
    }
    childFundToAllocation.set(childFund.fund.id, childFund.allocation);
  });

  const normalizedPortfolio = { ...portfolio, children: [] as Portfolio[] };
  portfolio.children.forEach((child: Portfolio) => {
    // For children that are STRATEGIES, normalize them first and then add them as children
    if (!child.fund) {
      const normalizedChild = normalizePortfolio(child);
      if (normalizedChild === undefined) {
        return;
      }
      normalizedPortfolio.children.push({ ...normalizedChild });
      return;
    }
    // Only add child fund if it's present in `childFundToAllocation` map. For funds that were doubled/tripled etc,
    // they will get deleted after their first read, so they won't appear twice in strategy's children.
    if (childFundToAllocation.has(child.fund.id)) {
      normalizedPortfolio.children.push({ ...child, allocation: childFundToAllocation.get(child.fund.id) });
      // Delete the child after it's been used.
      childFundToAllocation.delete(child.fund.id);
    }
  });
  return normalizedPortfolio;
};

export const calculateComparison = (
  // `portfolio` has to be NORMALIZED, see: `normalizePortfolio(portfolio: Portfolio);`
  portfolio?: Portfolio,
  // `comparePortfolio` has to be NORMALIZED, see: `normalizePortfolio(portfolio: Portfolio);`
  comparePortfolio?: Portfolio,
): [Map<number, Portfolio>, Map<number, Portfolio[]>] => {
  if (!portfolio || !comparePortfolio) {
    return [new Map(), new Map()];
  }

  const allCompareNodes = new Map<number, Portfolio>();
  const allGhostChildren = new Map<number, Portfolio[]>();
  mapPortfolioToCompare(portfolio, comparePortfolio, allCompareNodes, allGhostChildren);

  return [allCompareNodes, allGhostChildren];
};

function mapPortfolioToCompare(
  // `portfolio` has to be NORMALIZED, see: `normalizePortfolio(portfolio: Portfolio);`
  portfolio: Portfolio,
  // `comparePortfolio` has to be NORMALIZED, see: `normalizePortfolio(portfolio: Portfolio);`
  comparePortfolio: Portfolio | undefined,
  allCompareNodes: Map<number, Portfolio>,
  allGhostChildren: Map<number, Portfolio[]>,
) {
  if (!comparePortfolio || !portfolio) {
    return;
  }

  allCompareNodes.set(portfolio.id, comparePortfolio);

  const isStrategy = !portfolio.fund;
  if (!isStrategy) {
    return;
  }

  const [compareChildFunds, compareChildStrategies] = partition(
    comparePortfolio.children !== undefined ? [...comparePortfolio.children] : [],
    (child: Portfolio) => child.fund !== undefined,
  );

  // (1-funds): For each fund child of `comparePortfolio` node, add an entry to `compareChildFundIdToNode`
  //   that retrieves the node for the fund id.

  // Map from fund id to Portfolio node, for each fund that's a child of this compare node.
  const compareChildFundIdToNode = new Map<string, Portfolio>();
  compareChildFunds.forEach((compareChildFund: Portfolio) => {
    if (!compareChildFund.fund) {
      logMessageToSentry('Fund portfolio should have fund child');
      return;
    }
    if (compareChildFundIdToNode.has(compareChildFund.fund.id)) {
      // If compare node has more than one occurrence of this fund, it means that the `comparePortfolio` is not
      // normalized and this method can't return deterministic results.
      throw new Error(
        `\`comparePortfolio\` passed to \`mapPortfolioToCompare\` is not normalized: ${portfolio.name} has two child funds \`${compareChildFund.name}\` (id: ${compareChildFund.fund.id}). Normalize your portfolio using \`normalizePortfolio(portfolio: Portfolio);\` before calling this function.`,
      );
    }
    compareChildFundIdToNode.set(compareChildFund.fund.id, compareChildFund);
  });

  // (1-strategies): For each strategy child of `comparePortfolio`, add an entry to the end of the array in
  //   `compareChildStrategyNameToNodes`. Since we can't enforce different strategy names for all children, we're going
  //   to match them using our "best guess": the original order. The "best guess" can be improved later if we want to.

  // Map from strategy name to Portfolio nodes, for each strategy that's a child of this compare node. Strategies of the
  // same name go together on the list under the same name key.
  const compareChildStrategyNameToNodes = new Map<string, Portfolio[]>();
  compareChildStrategies.forEach((compareChildStrategy: Portfolio) => {
    if (compareChildStrategyNameToNodes.has(compareChildStrategy.name)) {
      const nodes = compareChildStrategyNameToNodes.get(compareChildStrategy.name);

      if (nodes === undefined) {
        logMessageToSentry("Can't find compare child");
        return;
      }
      nodes.push(compareChildStrategy);
      compareChildStrategyNameToNodes.set(compareChildStrategy.name, nodes);
      return;
    }
    compareChildStrategyNameToNodes.set(compareChildStrategy.name, [compareChildStrategy]);
  });

  // (2-both): Go over children of `portfolio`. For each child, check if it has a corresponding node in
  //   `comparePortfolio` using the right map (for funds/strategies). Delete the node from the map after "using" it.
  (portfolio.children || []).forEach((child: Portfolio) => {
    if (child.fund) {
      if (compareChildFundIdToNode.has(child.fund.id)) {
        mapPortfolioToCompare(child, compareChildFundIdToNode.get(child.fund.id), allCompareNodes, allGhostChildren);
        compareChildFundIdToNode.delete(child.fund.id);
      }
      return;
    }
    // Child strategies need a recursive call if they have a corresponding node
    if (compareChildStrategyNameToNodes.has(child.name)) {
      const nodes = [...compareChildStrategyNameToNodes.get(child.name)!];
      if (nodes.length === 0) {
        // This shouldn't happen; we should remove empty arrays. But just to be safe.
        compareChildStrategyNameToNodes.delete(child.name);
        return;
      }
      const [usedNode, ...restNodes] = nodes;
      mapPortfolioToCompare(child, usedNode, allCompareNodes, allGhostChildren);
      if (restNodes.length > 0) {
        compareChildStrategyNameToNodes.set(child.name, restNodes);
      } else {
        compareChildStrategyNameToNodes.delete(child.name);
      }
    }
  });

  // (3-both): Go over leftover children of `comparePortfolio` in both maps. These will become "ghost children" of the
  //   current strategy.
  let leftoverCompareChildren: Portfolio[] = [];
  for (const node of compareChildFundIdToNode.values()) {
    leftoverCompareChildren.push(node);
  }
  for (const nodes of compareChildStrategyNameToNodes.values()) {
    leftoverCompareChildren = [...leftoverCompareChildren, ...nodes];
  }
  compareChildFundIdToNode.clear();
  compareChildStrategyNameToNodes.clear();
  if (leftoverCompareChildren.length > 0) {
    allGhostChildren.set(portfolio.id, leftoverCompareChildren);
  }
}

export const deleteConstrainingFundFromPortfolio = (portfolio: Portfolio, fundId: string): Portfolio => {
  if (!portfolio?.children?.length) {
    return portfolio;
  }
  const children = portfolio.children
    .filter((strategy) => !strategy.fund || strategy.fund.id !== fundId)
    .map((strategy) => deleteConstrainingFundFromPortfolio(strategy, fundId));

  return {
    ...portfolio,
    children,
    allocation: sumBy(children, 'allocation'),
  };
};
