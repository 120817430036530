import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Button, getTextThemeProvider } from 'venn-ui-kit';
import type { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { Routes } from 'venn-utils';
import {
  AuthLogo,
  AuthenticationFooter,
  StyledHeadline1,
  AuthSubParagraph,
  Anchor,
  ContentWrapper,
} from '../components';

interface CreatePasswordFailureProps extends RouteComponentProps {
  title: string;
}

const CreatePasswordFailure = ({ history, title }: CreatePasswordFailureProps) => {
  const onLogIn = useCallback(() => {
    history.push(Routes.SIGN_IN_PATH);
  }, [history]);

  return (
    <>
      <ContentWrapper>
        <FormWrapper>
          <AuthLogo />
          <StyledHeadline1>{title}</StyledHeadline1>
          <LogInButton onClick={onLogIn} dominant>
            Log In
          </LogInButton>
        </FormWrapper>
        <AuthSubParagraph>
          Need help with signup?&nbsp;
          <Anchor href={`mailto:${getTextThemeProvider().supportEmail}`}>Contact Us</Anchor>
        </AuthSubParagraph>
      </ContentWrapper>
      <AuthenticationFooter />
    </>
  );
};

export default withRouter(CreatePasswordFailure);

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LogInButton = styled(Button)`
  width: 180px;
`;
