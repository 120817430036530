import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Body1, getAppTitle } from 'venn-ui-kit';
import { InfoContainer, InfoHeading, PreviewArea } from './styles';

const AddeparStep5 = () => {
  const { Images } = useContext(ThemeContext);
  return (
    <Container>
      <StyledPreviewArea>
        <StyledImg src={Images.addeparStep5Sync} alt={`addepar to ${getAppTitle()} sync graphic`} />
      </StyledPreviewArea>
      <Info>
        <div>
          <InfoHeading>Sync schedule</InfoHeading>
          <Body1>
            All integration data are synced daily. Data may take up to an hour or so to fully synchronize depending on
            the amount of data.
          </Body1>
        </div>
      </Info>
    </Container>
  );
};

export default AddeparStep5;

const StyledPreviewArea = styled(PreviewArea)`
  padding: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Info = styled(InfoContainer)`
  width: 50%;
  padding-right: 5px;
  > * {
    margin-bottom: 40px;
  }
`;

const StyledImg = styled.img`
  width: 70%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;
