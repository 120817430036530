import React from 'react';
import { Tooltip } from '../tooltip/Tooltip';
import Icon from '../icon/Icon';
import styled from 'styled-components';
import type { TooltipPosition } from '../enums';

interface InfoLinkProps {
  to: string;
  tooltip: string;
  tooltipPosition?: TooltipPosition;
  portalTooltip?: boolean;
}

export const InfoLink = ({ to, tooltip, tooltipPosition, portalTooltip }: InfoLinkProps) => (
  <Tooltip usePortal={portalTooltip} position={tooltipPosition} content={tooltip}>
    <a href={to} target="_blank" rel="noopener noreferrer">
      <StyledIcon type="info-circle" />
    </a>
  </Tooltip>
);

const StyledIcon = styled(Icon)`
  margin: 0 5px;
`;
