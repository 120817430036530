import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import { GetColor } from 'venn-ui-kit';

interface InitialAvatarProps {
  displayName?: string;
  avatarId?: string;
  width?: number;
  className?: string;
  inactive?: boolean;
}

class Avatar extends PureComponent<InitialAvatarProps> {
  getInitials(name = '') {
    return name
      .split(/\s+/)
      .filter((word) => word !== '')
      .map((word) => word.charAt(0))
      .join('')
      .substr(0, 2)
      .toUpperCase();
  }

  render() {
    const { avatarId, displayName, className } = this.props;
    return (
      <AvatarWrapper className={className} member={this.props}>
        {!avatarId && <span>{this.getInitials(displayName)}</span>}
      </AvatarWrapper>
    );
  }
}

export default Avatar;

const AvatarWrapper = styled.div<{ member: InitialAvatarProps }>`
  display: inline-block;
  color: ${GetColor.White};
  height: ${({ member }) => (member.width ? member.width : 36)}px;
  width: ${({ member }) => (member.width ? member.width : 36)}px;
  font-size: ${({ member }) => (member.width ? member.width * 0.46 : 16)}px;
  font-weight: bold;
  background-position: 50%;
  background-size: cover;
  border-radius: 50%;
  position: relative;

  ${({ member }) =>
    member.avatarId
      ? css`
          background-image: url(/api/avatar/${member.avatarId}?h=100);
        `
      : css`
          background-color: ${member.inactive ? GetColor.LightGrey : GetColor.Primary.Main};

          span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        `};
`;
