import React from 'react';
import { GetColor, ColorUtils, Icon, EllipsisTooltipSpan, TooltipPosition, Tooltip } from 'venn-ui-kit';
import type { BasicTableColumn, BasicTableProps, StyledTableType } from '../basictable/BasicTable';
import BasicTable, { SORTDIR } from '../basictable/BasicTable';
import styled from 'styled-components';
import type { GeneralAnalysisTemplate } from 'venn-api';
import { buttonize, Dates } from 'venn-utils';

interface TableProps {
  templates: GeneralAnalysisTemplate[];
  selectTemplate: (template: GeneralAnalysisTemplate) => void;
  onDelete: (template: GeneralAnalysisTemplate) => void;
  onEdit: (template: GeneralAnalysisTemplate) => void;
}

const Columns = (
  selectTemplate: (template: GeneralAnalysisTemplate) => void,
  onDelete: (template: GeneralAnalysisTemplate) => void,
  onEdit: (template: GeneralAnalysisTemplate) => void,
): BasicTableColumn<GeneralAnalysisTemplate>[] => [
  {
    label: 'Name',
    accessor: 'name',
    sortable: true,
    cellRenderer: (template: GeneralAnalysisTemplate) => (
      <EllipsisTooltipSpan maxWidth={250} content={template.name}>
        <NameCell
          {...buttonize(() => {
            selectTemplate(template);
          })}
        >
          {template.name}
          <StyledCalculator type="calculator" className="analyze-icon" />
        </NameCell>
      </EllipsisTooltipSpan>
    ),
  },
  {
    label: 'Type',
    accessor: 'type',
    sortable: true,
    cellStyle: { minWidth: 90 },
    cellRenderer: ({ analysisTemplateType }: GeneralAnalysisTemplate) => (
      <span>
        {analysisTemplateType === 'BLOCK' ? (
          <>
            <StyledIcon type="cube" prefix="far" />
            <span>Block</span>
          </>
        ) : (
          <>
            <StyledIcon type="cubes" />
            Template
          </>
        )}
      </span>
    ),
  },
  {
    label: 'Created by...',
    accessor: 'owner',
    sortable: true,
    cellStyle: { minWidth: 100 },
    cellRenderer: ({ appUser }: GeneralAnalysisTemplate) => (
      <EllipsisTooltipSpan maxWidth={100} content={appUser?.displayName}>
        {appUser?.displayName}
      </EllipsisTooltipSpan>
    ),
  },
  {
    label: 'Last Updated Date',
    accessor: 'updated',
    sortable: true,
    sorted: SORTDIR.DESC,
    cellStyle: { minWidth: 110 },
    cellRenderer: ({ updated }: GeneralAnalysisTemplate) => (
      <span>{updated ? Dates.toDayMonthYear(updated) : '--'}</span>
    ),
  },
  {
    label: '',
    cellStyle: { width: 55, textAlign: 'right' },
    cellRenderer: (template: GeneralAnalysisTemplate) =>
      template.appUser?.displayName !== 'Venn' ? (
        <ActionCell>
          <StyledTooltip position={TooltipPosition.Top} maxWidth={100} content="Edit">
            <Icon onClick={() => onEdit(template)} type="edit" />
          </StyledTooltip>
          <StyledTooltip position={TooltipPosition.Top} maxWidth={100} content="Delete">
            <Icon onClick={() => onDelete(template)} type="trash" />
          </StyledTooltip>
        </ActionCell>
      ) : (
        <span />
      ),
  },
];

export const TemplatesTable = ({ templates, selectTemplate, onDelete, onEdit }: TableProps) => (
  <StyledTable
    columns={Columns(selectTemplate, onDelete, onEdit)}
    data={templates}
    className="qa-template-list-table"
  />
);

const NameCell = styled.span`
  cursor: pointer;
  font-weight: bold;
  color: ${GetColor.Primary.Dark};
`;

const ActionCell = styled.span`
  display: flex;
  color: ${GetColor.Grey};
  text-align: center;
`;

const StyledCalculator = styled(Icon)`
  margin-left: 10px;
  display: none;
`;

const StyledTooltip = styled(Tooltip)`
  flex: 1;
`;

const StyledIcon = styled(Icon)`
  margin-right: 10px;
`;

const StyledTable: StyledTableType<unknown> = styled(
  <T extends BasicTableColumn<K>, K>(props: BasicTableProps<T, K>) => <BasicTable<T, K> {...props} />,
)`
  margin-top: 20px;
  > thead {
    > tr {
      > th:first-child {
        padding-left: 10px;
      }
    }
  }

  > tbody {
    > tr {
      line-height: 19px;
      > td:first-child {
        padding-left: 10px;
      }
    }

    > tr:hover {
      background: ${ColorUtils.hex2rgbaFrom(GetColor.Primary.Main, 0.1)};
      .button-icon i {
        color: ${GetColor.Primary.Dark};
      }
      ${StyledCalculator} {
        display: inline;
      }
    }
  }
`;
