import { getSpecificPortfolioV3, getFund } from 'venn-api';
import type { ComparisonSubject, SubjectId } from './types';
import { AnalysisSubject } from '../analysis';

export const subjectIdWithBenchmark = (subject: ComparisonSubject, defaultId: number): string => {
  // Generate a subject ID with benchmark information. Necessary to guarantee ID uniqueness when both
  // subjects and benchmarks with the same subject ID are selected for comparison.
  return `${subject.analysisSubject?.id || defaultId}${subject.isBenchmark ? '-benchmark' : ''}`;
};

/**
 * Retrieves the specified fund or portfolio by ID.
 * @param id The id of the fund or portfolio.
 */
export const fetchSubject = async (id: SubjectId): Promise<AnalysisSubject | undefined> => {
  if (typeof id === 'number') {
    const subject = await getSpecificPortfolioV3(id).then(
      (resp) => resp.content,
      () => undefined,
    );
    return subject && new AnalysisSubject(subject, 'portfolio');
  }
  const subject = await getFund(id).then(
    (resp) => resp.content,
    () => undefined,
  );
  return subject && new AnalysisSubject(subject, 'investment');
};
