import React from 'react';
import { useQuery } from '@tanstack/react-query';
import type { ProxyErrorEnum, ProxyMetrics } from 'venn-api';
import { investmentProxyMetrics } from 'venn-api';
import { isRequestSuccessful, logExceptionIntoSentry } from 'venn-utils';
import { ColorUtils, GetColor, Icon, Tooltip, TooltipPosition } from 'venn-ui-kit';
import styled from 'styled-components';
import { getInvalidProxyMessage } from 'venn-components';

type ExtrapolationFailingMsgProps = {
  reason: ProxyErrorEnum;
};
type ExtrapolationInfoProps = {
  investmentId: string;
};

const ExtrapolationFailingMsg = ({ reason }: ExtrapolationFailingMsgProps) => {
  return (
    <MsgContainer>
      <div>
        <b>Extrapolation is currently not applied: </b>
        {getInvalidProxyMessage(reason)}
        <br />
      </div>
      <InfoContainer>
        <Icon type="info-circle" prefix="far" />
        &nbsp; CLICK TO LEARN MORE
      </InfoContainer>
    </MsgContainer>
  );
};
const ExtrapolationRunningMsg = () => {
  return (
    <MsgContainer>
      <div>
        <b>Extrapolation in use: </b>
        Parts of this investment's returns are extrapolated and may affect your analysis results.
        <br />
      </div>
      <InfoContainer>
        <Icon type="info-circle" prefix="far" />
        &nbsp; CLICK TO LEARN MORE
      </InfoContainer>
    </MsgContainer>
  );
};

const ExtrapolationInfo = ({ investmentId }: ExtrapolationInfoProps) => {
  const { data } = useQuery<ProxyMetrics | null>(
    ['proxy-metrics', investmentId],
    async () => {
      const request = await investmentProxyMetrics(investmentId);
      if (isRequestSuccessful(request)) {
        return request.content;
      }
      logExceptionIntoSentry(`Failed to fetch proxy metrics for investment: ${investmentId}`);
      return null;
    },
    {
      onError: (error) => {
        logExceptionIntoSentry(error as Error);
      },
      suspense: true,
    },
  );

  if (!data) return null;
  const messageComponent = data.error ? <ExtrapolationFailingMsg reason={data.error} /> : <ExtrapolationRunningMsg />;
  return (
    <Tooltip position={TooltipPosition.Top} maxWidth={260} content={messageComponent}>
      <ProxyIcon type="exclamation-triangle" />
    </Tooltip>
  );
};

const MsgContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 4px;
`;
const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 8px 4px 4px 0px;
`;

const HoverIcon = styled(Icon)`
  padding: 3px;
  border-radius: 2px;
  :hover {
    background-color: ${GetColor.Grey};
  }
`;

const ProxyIcon = styled(HoverIcon)`
  color: ${GetColor.Warning};
  :hover {
    color: ${ColorUtils.opacifyDarkFrom(GetColor.Warning, 0.5)};
    background-color: ${GetColor.Grey};
  }
`;
export default React.memo(ExtrapolationInfo);
