import profile from './profile';
import type { LoginAuditEntry } from 'venn-api';

export const mockLoginHistory: LoginAuditEntry[] = [
  {
    userId: profile.user.id,
    organizationId: profile.organization.id,
    created: 1618925956517,
    ipAddress: '1.2.3.4',
  },
  {
    userId: profile.user.id,
    organizationId: profile.organization.id,
    created: 1618512664464,
    ipAddress: '2.2.3.4',
  },
];
