import React from 'react';
import { useLocation } from 'react-router-dom';
import { Routes, SpecialCssClasses } from 'venn-utils';
import styled, { css } from 'styled-components';
import { useShouldShowByolWatermark } from './hooks/useShouldShowByolWatermark';
import { WatermarkText } from './components/watermarkText';

/** Block level 'Bring Your Own License' watermark that will automatically display or hide as needed. */
export const BlockByolWatermark = () => {
  const location = useLocation();
  const shouldShowWatermark = useShouldShowByolWatermark();

  if (!shouldShowWatermark) {
    return null;
  }

  const isStudioOrReportLab =
    location.pathname.startsWith(Routes.STUDIO_PATH) || location.pathname.startsWith(Routes.REPORT_LAB_PATH);

  return isStudioOrReportLab ? <StudioBlockByolWatermark /> : <AnalysisBlockByolWatermark />;
};

const StudioBlockByolWatermark = () => {
  return (
    <StudioWatermark className={SpecialCssClasses.OnlyInDownloadable}>
      <WatermarkText />
    </StudioWatermark>
  );
};

const AnalysisBlockByolWatermark = () => {
  return (
    <AnalysisWatermark className={SpecialCssClasses.OnlyInDownloadable}>
      <WatermarkText />
    </AnalysisWatermark>
  );
};

const BlockWatermarkCss = css`
  // Display is none for now but will become visible when SpecialCssClasses.OnlyInDownloadable kicks in
  display: none;

  position: absolute;
  min-width: max-content;
`;

const AnalysisWatermark = styled.div`
  ${BlockWatermarkCss}
  bottom: 60px;
  right: 48px;
`;

const StudioWatermark = styled.div`
  ${BlockWatermarkCss}
  bottom: 32px;
  right: 32px;
`;
