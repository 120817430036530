import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Routes, useHasFF } from 'venn-utils';

/**
 * Custom hook that navigates to a downtime status page according to feature flag enablement.
 * After the downtime is complete, it returns to the home page.
 *
 * The routes added in this effect must be added to UNBLOCKABLE_ROUTES if we want to FORCE navigation, otherwise
 * unsaved changes will block the navigation.
 */
export function useDowntimeEffect() {
  const shouldForceDowntime = useHasFF('frontend_driven_downtime');
  const history = useHistory();
  const location = useLocation();
  const isOnStatusPage = location.pathname.startsWith(Routes.UPGRADES_STATUS_PAGE);

  useEffect(() => {
    if (shouldForceDowntime) {
      if (!isOnStatusPage) {
        // We could use window.location.href but it causes ugly double loads because it navigates the window rather than performing client-side routing.
        // It also doesn't gain us much because while setting window.location.href overrides <Prompt> components, it doesn't override history.block()
        // which we use in multiple parts of the codebase.
        history.push(Routes.UPGRADES_STATUS_PAGE);
      }
    } else if (isOnStatusPage) {
      history.push(Routes.HOME_PATH);
    }
  }, [shouldForceDowntime, isOnStatusPage, history]);
}
