import React, { createContext, useContext } from 'react';
import UserContext from '../../../contexts/user-context';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  activeForecastIdAtom,
  defaultForecastPanelViewForTab,
  forecastPanelViewSelector,
  ForecastTab,
  remoteFactorForecastList,
} from 'venn-state';
import type { CustomFactorForecast } from 'venn-api';
import { addOrUpdateFactorForecastForUser, markFactorForecastAsDefault, removeFactorForecastForUser } from 'venn-api';
import { Notifications, NotificationType } from 'venn-ui-kit';
import { analyticsService, assertExhaustive, asyncNoop, isRequestSuccessful, logExceptionIntoSentry } from 'venn-utils';
import { noop } from 'lodash';
import { useRefreshInvestmentOverridesList } from '../hooks/useRefreshInvestmentOverridesList';

export const defaultForecastPanelActionsContext: ForecastPanelActions = {
  onUpdateOrCreateFactorForecast: asyncNoop,
  changeTab: noop,
  onDeleteForecast: asyncNoop,
  onSetForecastAsDefault: asyncNoop,
};
// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export const ForecastPanelActionsContext: React.Context<ForecastPanelActions> = createContext(
  defaultForecastPanelActionsContext,
);

type ForecastPanelActionsProviderProps = {
  onDefaultForecastUpdated?: (defaultForecastId: string) => void;
  children: React.ReactNode;
};

export type ForecastPanelActions = {
  onUpdateOrCreateFactorForecast: (forecast: CustomFactorForecast, isCreating: boolean) => Promise<void>;
  changeTab: (tab: { tabId: ForecastTab }) => void;
  onDeleteForecast: (forecast: CustomFactorForecast) => Promise<void>;
  onSetForecastAsDefault: (forecast: CustomFactorForecast) => Promise<void>;
};
export const ForecastPanelOverrideActionsProvider = ({
  onDefaultForecastUpdated,
  children,
}: ForecastPanelActionsProviderProps) => {
  const { profileSettings } = useContext(UserContext);
  const setView = useSetRecoilState(forecastPanelViewSelector);
  const defaultFactorView = useRecoilValue(defaultForecastPanelViewForTab(ForecastTab.FactorForecast));
  const defaultInvestmentView = useRecoilValue(defaultForecastPanelViewForTab(ForecastTab.InvestmentForecast));
  const [activeForecastId, setActiveForecastId] = useRecoilState(activeForecastIdAtom);
  const setFactorForecasts = useSetRecoilState(remoteFactorForecastList);
  const refreshInvestmentOverridesList = useRefreshInvestmentOverridesList();

  const onDeleteForecast = async (forecast: CustomFactorForecast) => {
    const toastId = Notifications.notify('Deleting...', NotificationType.LOADING);
    try {
      const response = await removeFactorForecastForUser(forecast.forecastId);
      if (isRequestSuccessful(response)) {
        const updatedForecastList = response.content;
        Notifications.notifyUpdate(toastId, 'Factor Forecast has been deleted', NotificationType.SUCCESS);
        setFactorForecasts(updatedForecastList);
      } else {
        Notifications.notifyUpdate(toastId, 'Unable to delete forecasts', NotificationType.ERROR);
      }
    } catch (error) {
      logExceptionIntoSentry(error);
      Notifications.notifyUpdate(toastId, 'Unable to delete forecasts', NotificationType.ERROR);
    }
  };

  const onSetForecastAsDefault = async (forecast: CustomFactorForecast) => {
    if (forecast.forecastId === activeForecastId) {
      return;
    }
    const toastId = Notifications.notify('Updating active forecast for your organization', NotificationType.LOADING);
    try {
      const response = await markFactorForecastAsDefault(forecast.forecastId);
      if (isRequestSuccessful(response)) {
        const updatedForecastList = response.content;
        setFactorForecasts(updatedForecastList);
        refreshInvestmentOverridesList();
        Notifications.notifyUpdate(toastId, 'Active forecast updated successfully', NotificationType.SUCCESS);
        setActiveForecastId(forecast.forecastId);
        onDefaultForecastUpdated?.(forecast.forecastId);
      } else {
        Notifications.notifyUpdate(toastId, 'Unable to set active forecast', NotificationType.ERROR);
      }
    } catch (error) {
      logExceptionIntoSentry(error);
      Notifications.notifyUpdate(toastId, 'Unable to set active forecast', NotificationType.ERROR);
    }
  };

  const onUpdateOrCreateFactorForecast = async (forecast: CustomFactorForecast, isCreating: boolean) => {
    const currentUser = profileSettings?.user;
    if (isCreating) {
      forecast = {
        ...forecast,
        forecastContext: 'HISTORICAL_FORECASTS',
        default: false,
        updatedBy: currentUser!,
      };
    }

    const toastId = Notifications.notify(isCreating ? 'Creating...' : 'Updating...', NotificationType.LOADING);
    try {
      const response = await addOrUpdateFactorForecastForUser(forecast);
      if (isRequestSuccessful(response)) {
        const factorForecasts = response.content;
        setFactorForecasts(factorForecasts);
        refreshInvestmentOverridesList();
        if (isCreating) {
          setView({
            tab: ForecastTab.FactorForecast,
            detail: { type: 'Empty' },
          });
        }
        if (forecast.forecastId === activeForecastId) {
          onDefaultForecastUpdated?.(forecast.forecastId);
        }
        Notifications.notifyUpdate(
          toastId,
          isCreating ? 'Forecast successfully created' : 'Forecast successfully updated',
          NotificationType.SUCCESS,
        );
      } else {
        Notifications.notifyUpdate(
          toastId,
          isCreating ? 'Unable to create forecast' : 'Unable to update forecast',
          NotificationType.ERROR,
        );
      }
    } catch {
      Notifications.notifyUpdate(
        toastId,
        isCreating ? 'Unable to create forecast' : 'Unable to update forecast',
        NotificationType.ERROR,
      );
    }
  };

  const changeTab = (tab: { tabId: ForecastTab }) => {
    const { tabId } = tab;
    if (tabId === ForecastTab.InvestmentForecast) {
      setView(defaultInvestmentView);
    } else if (tabId === ForecastTab.FactorForecast) {
      setView(defaultFactorView);
    } else {
      assertExhaustive(tabId, 'unexpected forecast tab');
    }
    analyticsService.ctaClicked({
      purpose: `Navigate to ${tabId === ForecastTab.FactorForecast ? 'Factor' : 'Investment'} Overrides Tab`,
      text: `${tabId === ForecastTab.FactorForecast ? 'Factor Forecasts' : 'Investment Forecasts'}`,
    });
  };

  return (
    <ForecastPanelActionsContext.Provider
      value={{
        onUpdateOrCreateFactorForecast,
        changeTab,
        onDeleteForecast,
        onSetForecastAsDefault,
      }}
    >
      {children}
    </ForecastPanelActionsContext.Provider>
  );
};
