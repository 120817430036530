import React from 'react';
import type { PortfolioRange } from 'venn-api';
import { EmptyStateContent } from '../components/styled';
import { getPortfolioFrequency, getRequiredReturnYearsForDrawdown } from '../PortfolioRangesUtils';
import type { AnalysisSubjectType } from 'venn-utils';

export interface ErrorResidualRiskTooHighProps {
  defaultMessage: string;
  portfolioRange: PortfolioRange;
  type: AnalysisSubjectType;
}

const ErrorResidualRiskTooHigh: React.FC<React.PropsWithChildren<ErrorResidualRiskTooHighProps>> = ({
  defaultMessage,
  portfolioRange,
  type,
}) => {
  if (!portfolioRange) {
    return <EmptyStateContent>{defaultMessage}</EmptyStateContent>;
  }
  const frequency =
    type === 'investment' ? portfolioRange.investments?.[0]?.frequency : getPortfolioFrequency(portfolioRange);
  if (!frequency) {
    return <EmptyStateContent>{defaultMessage}</EmptyStateContent>;
  }

  const numYears = getRequiredReturnYearsForDrawdown(frequency);
  return (
    <EmptyStateContent>
      Drawdown analysis is unavailable for {type}s with residual risk greater than 40% over the previous {numYears}
      -year period.
    </EmptyStateContent>
  );
};

export default ErrorResidualRiskTooHigh;
