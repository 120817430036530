import type { DynamicThemeType, Videos } from '../../style/themes';

const getPath = (theme: DynamicThemeType, relativePath: string) => {
  const baseUrl = theme !== 'default' ? 'https://dev.altivant.com' : 'https://app.venn.twosigma.com';
  return `${baseUrl}/${relativePath}`;
};

export const getVideoTheme = (theme: DynamicThemeType): Videos => ({
  splashScreenAnimation: getPath(theme, 'splash-screen-animation.mp4'),
  factorLensVideo: getPath(theme, 'TSFL.mp4'),
  productTourVideo: getPath(theme, 'productWalkthrough.mp4'),
  aisDemoVideo: getPath(theme, 'ais_demo.mp4'),
});
