import React, { useContext, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { GetColor, Icon } from 'venn-ui-kit';
import { analyticsService, Routes } from 'venn-utils';
import type { PermissionedStickyMenuItem } from 'venn-components';
import { ModalBackToPrev, UserContext } from 'venn-components';
import UserSwitchOrg from './UserSwitchOrg';
import styled, { css } from 'styled-components';
import { TopNavigationMenu } from './NavigationMenu';

interface SettingsMenuProps {
  firstName: string;
  lastName: string;
  items: PermissionedStickyMenuItem[];
  organizationId: number;
  organizationName: string;
  onLogout: () => void;
}

const analyticsOpts = {
  location: 'profile ball',
  itemType: 'link',
  userIntent: 'navigate',
};

const SettingsMenu = ({
  firstName,
  lastName,
  items,
  onLogout,
  organizationName,
  organizationId,
}: SettingsMenuProps) => {
  const { organizations, hasPermission } = useContext(UserContext);
  const options = organizations?.slice(0, 10) || [];
  const [showUserSwitchOrg, setShowUserSwitchOrg] = useState(false);
  const isMountedRef = useRef(false);
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  if (showUserSwitchOrg && options.length > 0) {
    return (
      <TopNavigationMenu>
        <ModalBackToPrev backToLabel="settings" onClick={() => setShowUserSwitchOrg(false)} />
        <UserSwitchOrg organizationId={organizationId} options={options} />
      </TopNavigationMenu>
    );
  }

  return (
    <TopNavigationMenu>
      <Header>
        <Name className="qa-settings-display-name">
          {firstName} {lastName}
        </Name>
        <Org>{organizationName}</Org>
      </Header>
      <MenuGroup>
        {items
          .filter((item) => !item.hidden && (!item.requiredPermission || hasPermission(item.requiredPermission)))
          .map((menu) => (
            <StyledLink
              key={menu.id}
              to={`${Routes.ACCOUNT_PATH}/${menu.id}`}
              onClick={() => {
                analyticsService.navigationTriggered({
                  ...analyticsOpts,
                  destinationPageTitle: 'Account Settings',
                  location: menu.title,
                });
              }}
              className="qa-view-profile"
            >
              {menu.title}
            </StyledLink>
          ))}
      </MenuGroup>
      {options.length > 1 && (
        <Workspaces onClick={() => setShowUserSwitchOrg(true)}>
          Workspaces
          <ChevronIcon type="chevron-right" prefix="fal" />
        </Workspaces>
      )}
      <Logout onClick={onLogout} className="qa-logout-btn">
        Logout
      </Logout>
    </TopNavigationMenu>
  );
};

export default SettingsMenu;

const MenuGroup = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
`;

const hoverStyles = css`
  :hover,
  :focus {
    background: ${GetColor.PaleGrey};
  }
`;

const StyledLink = styled(Link)`
  padding: 0 20px;
  color: ${GetColor.Black};
  ${hoverStyles};
`;

const Workspaces = styled.button`
  border-top: 1px solid ${GetColor.Grey};
  display: block;
  padding: 8px 0 8px 20px;
  text-align: left;
  width: 100%;
  line-height: 1.71;
  color: ${GetColor.Black};
  ${hoverStyles};
`;

const Logout = styled(Workspaces)`
  color: ${GetColor.DEPRECATED_DivergingColor.A4};
`;

const Header = styled.div`
  background: ${GetColor.PaleGrey};
  padding: 13px 20px 20px;
`;

const Name = styled.div`
  color: ${GetColor.Black};
  font-size: 18px;
  line-height: 20px;
`;

const Org = styled.div`
  font-size: 10px;
  color: ${GetColor.DarkGrey};
  line-height: 10px;
`;

const ChevronIcon = styled(Icon)`
  font-size: 14px;
  margin-left: 10px;
  position: relative;
  top: 1px;
`;
