import type { ColDef, ICellRendererParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { isNil } from 'lodash';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import type { PrivatePerformanceSummaryResponse } from 'venn-api';
import { blockCustomizableMetrics, blockMetrics } from 'venn-state';
import { assertNotNil, Dates } from 'venn-utils';
import { formatData } from '../../data-grid/gridUtils';
import { generateSampleValueToMeasure, useMeasureGridText, useMeasureHeader } from '../../utils/grids';
import { useBlockId } from '../contexts/BlockIdContext';
import { RIGHT_ALIGN_CLASS, VALUE_CELL_RENDERER } from '../customAnalysisContants';
import { getCellClassForDataType } from './gridStyling';

export interface ParsedPrivatePerformanceSummaryResponse extends PrivatePerformanceSummaryResponse {
  path: string[];
}

const formatDate = (date: number | undefined) => (date ? Dates.toQuarterYear(date) : '--');
const formatVintage = (date: number | undefined) => (date ? Dates.toYear(date) : '--');

function usePrivatesPerformanceSummaryDataGridColumnDefs() {
  const blockId = useBlockId();
  const selectedMetrics = useRecoilValue(blockMetrics(blockId));
  const allMetrics = useRecoilValue(blockCustomizableMetrics(blockId));
  const measureHeader = useMeasureHeader();
  const measureText = useMeasureGridText();

  return useMemo(() => {
    return selectedMetrics.map((metric) => {
      const metricMetadata = assertNotNil(allMetrics.find((customizableMetric) => customizableMetric.key === metric));
      const width =
        metric === 'asOfDate'
          ? measureText('As of Date', 'bold')
          : Math.max(
              measureText(
                generateSampleValueToMeasure({
                  mode: 'currency',
                  integerDigits: 3,
                  fractionalDigits: 2,
                  canBeNegative: true,
                }),
                'normal',
              ),
              measureHeader(metricMetadata.label.replaceAll('-', '- ')),
            );

      return {
        flex: 1,
        headerName: assertNotNil(metricMetadata).label,
        cellClass: () => [RIGHT_ALIGN_CLASS, getCellClassForDataType(metricMetadata.dataType)],
        cellRenderer: VALUE_CELL_RENDERER,
        cellRendererParams: ({ data }: ICellRendererParams<PrivatePerformanceSummaryResponse>) => {
          return { errors: (data?.errors?.[metric] ?? []).map((error) => error.message) };
        },
        valueGetter: ({ data }: ValueGetterParams<PrivatePerformanceSummaryResponse>) => {
          const value = data?.[metric];
          switch (metric) {
            case 'asOfDate':
              return formatDate(value);
            case 'vintage':
              return formatVintage(value);
            default:
              return value;
          }
        },
        valueFormatter: ({ value }: ValueFormatterParams<PrivatePerformanceSummaryResponse>) => {
          if (isNil(value)) {
            return '--';
          }
          return formatData(value, metricMetadata.dataType, 2);
        },
        minWidth: width,
        maxWidth: width,
      };
    });
  }, [selectedMetrics, allMetrics, measureText, measureHeader]) as ColDef<PrivatePerformanceSummaryResponse>[];
}

export const usePrivatesPerformanceSummaryDataGrid = (data: PrivatePerformanceSummaryResponse[]) => {
  const columnDefs = usePrivatesPerformanceSummaryDataGridColumnDefs();

  const rowData: PrivatePerformanceSummaryResponse[] = [];
  data.forEach((row) => {
    rowData.push({
      ...row,
      path: [row.name],
    } as ParsedPrivatePerformanceSummaryResponse);
    row.children?.forEach((child) => {
      rowData.push({
        ...child,
        path: [row.name, child.name],
      } as ParsedPrivatePerformanceSummaryResponse);
    });
  });
  return {
    rowData,
    columnDefs,
  };
};
