import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Redirect, useHistory } from 'react-router-dom';
import { Modal, ModalHeader, ModalSize, ModalContent } from '../modal';
import { Headline1 } from 'venn-ui-kit';
import type { TemplateNavigationState } from 'venn-utils';
import { Routes, analyticsService, getRedirectLinkFromHistory, getPreviousState, getAnalysisPath } from 'venn-utils';
import { autocompleteSearch } from 'venn-api';
import type { SearchMenuItem } from '../search-menu';
import { SearchMenuBar } from '../search-menu';

interface LaunchAnalysisTemplateProps {
  templateId: string;
  templateName: string;
  onCancel: () => void;
}

const qaClass = 'qa-view-analysis-modal';

export const LaunchAnalysisTemplate = ({ templateId, templateName, onCancel }: LaunchAnalysisTemplateProps) => {
  const [redirect, setRedirect] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const basePath = useRef(`${Routes.ANALYSIS_PATH}/results/${templateId}/`);
  // TODO: VENN-23554 :Fix routing types
  const history = useHistory<TemplateNavigationState & { state?: TemplateNavigationState }>();
  const historyRef = useRef(history);
  const onSelection = useCallback(
    ({ value: selected }: SearchMenuItem) => {
      if (!selected) {
        return;
      }
      const url = getAnalysisPath(selected.superType, selected.id, templateId);
      analyticsService.navigationTriggered({
        location: 'search bar',
        itemType: 'dropdown',
        userIntent: 'navigate',
        destinationPageTitle: 'Analysis Results',
      });
      setRedirect(url);
    },
    [templateId],
  );

  useEffect(() => {
    const redirectIfMostRecentExists = async () => {
      try {
        const previousRedirectLink = getRedirectLinkFromHistory(historyRef.current, basePath.current);

        if (previousRedirectLink) {
          setLoading(false);
          // Redirect to previous analysis view with preserve state
          const state = getPreviousState(previousRedirectLink, historyRef.current.location.state?.state);
          historyRef.current.push(previousRedirectLink, state);
          return;
        }
        const response = await autocompleteSearch({
          query: '',
          scopes: ['PORTFOLIO', 'INVESTMENT'],
          count: 1,
          investmentParams: { recentlyViewed: true },
        });
        if (response && response.content && response.content.results && response.content.results.length === 1) {
          const mostRecentlyViewed = response.content.results[0];
          const subjectPath = mostRecentlyViewed
            ? `${mostRecentlyViewed.fund ? 'investment' : 'portfolio'}/${
                mostRecentlyViewed.fund ? mostRecentlyViewed.fund.id : mostRecentlyViewed.portfolio?.id ?? ''
              }`
            : '';
          setRedirect(`${basePath.current}${subjectPath}`);
        }
      } catch {
        // Suppress error and launch picker modal
      }
      setLoading(false);
    };
    setLoading(true);
    redirectIfMostRecentExists();
  }, []);

  if (loading) {
    return null;
  }

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <Modal className={qaClass} size={ModalSize.XLarge} closeOnEsc closeOnOutsideClick onClose={onCancel}>
      <ModalHeader>
        <Headline1>View {templateName} for...</Headline1>
      </ModalHeader>
      <PickerWrapper>
        <SearchMenuBar
          onSelected={onSelection}
          autofocus
          location="launchAnalysisTemplate"
          privateAssetSearchMode="PUBLIC_ONLY"
        />
      </PickerWrapper>
    </Modal>
  );
};

const PickerWrapper = styled(ModalContent)`
  margin-bottom: 100px;
`;
