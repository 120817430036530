import type { FC, ComponentProps } from 'react';
import React from 'react';
import styled from 'styled-components';
import MiniChart from './MiniChart';
import type { DataPoint } from '../../types';

type ObservationsProps = {
  /**
   * Time series for the new data (black line)
   */
  data: DataPoint[];
  /**
   * Time series for the secondary, historic data (grey line)
   */
  previousData?: DataPoint[];
  /**
   * Date at which the chart is starting. Defaults to the first data point of
   * either data or previousData if provided
   */
  from?: number;
  /**
   * If true, draws a band between the `from` date and the first `data` date
   */
  bands?: boolean;

  newDataCount?: number;

  className?: string;
} & ComponentProps<typeof MiniChart>;

export const Observations: FC<React.PropsWithChildren<ObservationsProps>> = (props) => (
  <Container className={props.className}>
    <MiniChart {...props} />
  </Container>
);

const Container = styled.div`
  display: flex;
`;

export default Observations;
