import type { FC } from 'react';
import React, { useContext } from 'react';
import { LegendSquare } from '../../factor-chart/LegendSquare';
import Gradient from './Gradient';
import ValueIndicator from './ValueIndicator';
import Label from './Label';

import { getValueIndicatorPosition } from './utils';
import { ThemeContext } from 'styled-components';

export type TopLegendFormatterType = (value?: number | null, isPercentage?: boolean) => string;

export interface Range {
  min: number;
  max: number;
}

interface Props {
  y: number;
  palette: string[];
  thickness: number;
  width: number;
  print: boolean;
  isPercentage?: boolean;
  extremes?: Range | null;
  value?: number | null;
  labelFormatter?: TopLegendFormatterType;
  name?: string;
  showInsignificant?: boolean;
  fontSize?: string;
}

const TEXT_TOP_OFFSET = 10;
const MARGIN = 5;

const TopLegend: FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    width,
    thickness,
    palette,
    isPercentage,
    extremes,
    value,
    labelFormatter,
    y,
    name,
    showInsignificant = true,
    print,
    fontSize,
  } = props;
  const { Typography, Colors } = useContext(ThemeContext);

  if (!extremes) {
    return null;
  }

  const labelFormatterFactory = (percent?: boolean) => (val: number) =>
    labelFormatter ? labelFormatter(val, percent) : `${val}`;

  return (
    <svg y={y + TEXT_TOP_OFFSET + MARGIN} x={20} data-testid="toplegend">
      <g>
        {name && (
          <text
            style={{ fontFamily: Typography.fontFamily, fontSize, fontWeight: 'bold' }}
            textAnchor="start"
            x={0}
            y={TEXT_TOP_OFFSET}
          >
            {' '}
            {name}{' '}
          </text>
        )}
        <Gradient y={TEXT_TOP_OFFSET + MARGIN} thickness={thickness} width={width} palette={palette} />
        {typeof value === 'number' && (
          <ValueIndicator
            height={thickness}
            transform={`translate(
              ${getValueIndicatorPosition(value, extremes.min, extremes.max, width)}, ${TEXT_TOP_OFFSET + MARGIN}
            )`}
          />
        )}
        <g>
          <Label
            fontSize={fontSize}
            y={TEXT_TOP_OFFSET + thickness + MARGIN + 18}
            textAnchor="start"
            formatter={labelFormatterFactory(isPercentage)}
            value={extremes.min}
            print={print}
          />
          <Label
            fontSize={fontSize}
            y={TEXT_TOP_OFFSET + thickness + MARGIN + 18}
            x={width / 2}
            textAnchor="middle"
            value={0}
            print={print}
          />
          <Label
            fontSize={fontSize}
            y={TEXT_TOP_OFFSET + thickness + MARGIN + 18}
            x={width}
            textAnchor="end"
            formatter={labelFormatterFactory(isPercentage)}
            value={extremes.max}
            print={print}
          />
        </g>
        {showInsignificant && (
          <LegendSquare
            color={print ? Colors.HintGrey : 'url(#trend-diagonal-hatch)'}
            x={width + 30}
            y={TEXT_TOP_OFFSET + 7}
            name="Excluded factor"
            print={print}
            fontSize={fontSize}
          />
        )}
      </g>
    </svg>
  );
};

export default TopLegend;
