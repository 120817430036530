import React from 'react';
import styled from 'styled-components';
import { BigBrandLogoRight } from 'venn-components';
import { ZIndex } from 'venn-ui-kit';

const Background: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Container>
    <BigBrandLogoRight />
  </Container>
);

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: ${ZIndex.Background};
  overflow: hidden;
`;

export default Background;
