import { get } from 'lodash';
import type { Analysis } from 'venn-api';
import type { ReturnsHistogramAnalysis } from '../../types';
import type { AnalysisRequest } from '../../../../types';
import type { CustomizableMetric } from 'venn-utils';

export type ReturnsDistributionAnalyses = {
  analyses: ReturnsHistogramAnalysis[];
  exportableAnalyses: ReturnsHistogramAnalysis[];
};

type GetReturnsDistributionSeries = (props: {
  metric?: CustomizableMetric;
  requests: AnalysisRequest[];
  analysis: Analysis | undefined;
}) => ReturnsDistributionAnalyses;

export const getReturnsDistributionAnalyses: GetReturnsDistributionSeries = ({ requests, analysis, metric }) => {
  if (!analysis || !metric) return { analyses: [], exportableAnalyses: [] };

  const analysisResults = get(analysis, metric.analysisResultKey!);

  const allAnalyses = requests.map((request, index) => ({
    analysisData: analysisResults[index],
    subject: request.subject,
    frequency: request.frequency,
    relative: request.relative,
    exportable: analysis.exportable[index],
  }));

  return {
    analyses: allAnalyses,
    exportableAnalyses: allAnalyses.filter((a) => a.exportable),
  };
};
