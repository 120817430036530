import { Button } from 'venn-ui-kit';
import styled from 'styled-components';

export const IconButton = styled(Button)`
  border-radius: 2px 0px 0px 2px;
  padding: 6px 8px;
  && {
    min-width: auto;
  }
`;

export interface ExportButtonProps {
  dominant?: boolean;
  disabled?: boolean;
  densed?: boolean;
  printLabel?: string;
  downloadPNGs?: () => void;
  downloadXlsx?: () => void;
  downloadPdf?: () => void;
  downloadAll?: () => void;
  onPrintClick?: () => void;
  // save view information
  savedId?: string;
  subjectId?: string;
  currentViewName?: string;
  onSave?: (name?: string) => Promise<string | undefined>;
  // Compare view only has download xlsx and print option
  isCompare?: boolean;
  hasSavedModified?: boolean;
  noAccessModifiedView?: boolean;
}
