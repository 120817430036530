import React from 'react';
import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';

interface AlignProps {
  align?: 'center' | 'left' | 'right';
}

const SuperHeader = styled.th`
  padding: 0;
  height: 25px;
`;

export const SuperHeaderRow = styled.tr``;

const SuperHeaderInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 3px solid ${GetColor.Black};
  margin: 0 2px;
  height: 25px;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  vertical-align: center;
`;

type Foo = React.HTMLProps<HTMLTableHeaderCellElement> & { children: JSX.Element | JSX.Element[] };
export const SuperHeaderCell = ({ children, ref, as, ...props }: Foo) => (
  <SuperHeader {...props}>
    <SuperHeaderInner>{children}</SuperHeaderInner>
  </SuperHeader>
);

export const HeaderCell = styled.th<AlignProps>`
  text-align: ${(props) => props.align};
`;

export const Cell = styled.td<AlignProps>`
  text-align: ${(props) => props.align};
  max-width: 0;
`;

export const Table = styled.table`
  ${Cell},
  ${HeaderCell} {
    padding: 0 10px;
  }

  /* stylelint-disable-next-line */
  ${Cell}:first-child {
    padding-left: 20px;
  }
  /* stylelint-disable-next-line */
  ${HeaderCell}:first-child {
    padding-left: 20px;
  }

  ${SuperHeader} {
    padding: 0;
  }

  tbody {
    font-size: 14px;
    @media print {
      font-size: 12px;
    }
  }
`;

export const LargeRow = styled.tr`
  height: 36px;
  border-bottom: 1px solid ${GetColor.Grey};
  &:last-child {
    border-bottom: none;
  }
  @media print {
    height: 20px;
  }
`;

export const HeaderRow = styled.tr`
  height: 25px;
  font-size: 12px;
  font-weight: bold;
  border-bottom: 1px solid ${GetColor.Black};
`;

export const TableContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

export const Blocks = styled.div<{ print: boolean }>`
  display: flex;
  margin-top: 20px;
  height: 340px;
  > div:first-child {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  ${({ print }) => print && 'height: auto; width: 100%;'}
`;
