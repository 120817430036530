import React from 'react';

const ImageIcon = () => (
  <svg viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.5347 0.5H1.90972C0.960503 0.5 0.222222 1.27344 0.222222 2.1875V12.3125C0.222222 13.2617 0.960503 14 1.90972 14H16.5347C17.4488 14 18.2222 13.2617 18.2222 12.3125V2.1875C18.2222 1.27344 17.4488 0.5 16.5347 0.5ZM16.3238 12.3125H2.12066C1.98003 12.3125 1.90972 12.2422 1.90972 12.1016V2.39844C1.90972 2.29297 1.98003 2.1875 2.12066 2.1875H16.3238C16.4293 2.1875 16.5347 2.29297 16.5347 2.39844V12.1016C16.5347 12.2422 16.4293 12.3125 16.3238 12.3125ZM4.72222 3.59375C3.91363 3.59375 3.31597 4.22656 3.31597 5C3.31597 5.80859 3.91363 6.40625 4.72222 6.40625C5.49566 6.40625 6.12847 5.80859 6.12847 5C6.12847 4.22656 5.49566 3.59375 4.72222 3.59375ZM3.59722 10.625H14.8472V7.8125L11.7535 4.75391C11.5777 4.57812 11.3316 4.57812 11.1558 4.75391L6.97222 8.9375L5.56597 7.56641C5.39019 7.39062 5.1441 7.39062 4.96832 7.56641L3.59722 8.9375V10.625Z"
      fill="#00818A"
    />
  </svg>
);

export default ImageIcon;
