import React from 'react';
import styled from 'styled-components';
import type { DropMenuItem } from 'venn-ui-kit';
import { Icon, GetColor } from 'venn-ui-kit';
import Weight from './benchmark/Weight';
import Fund from './benchmark/Fund';
import BenchmarkScalingSign from './benchmark/ScalingSign';
import ScalingValue from './benchmark/ScalingValue';
import type { BenchmarkShape, ScalingSign } from '../types';
import type { SearchMenuItem } from '../../../search-menu';

const BenchmarkWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6px;

  input::placeholder {
    color: ${GetColor.LightGrey};
    opacity: 1;
  }
`;

const RemoveFundWrapper = styled.div`
  cursor: pointer;
  color: ${GetColor.HintGrey};
  margin-left: 10px;
  font-size: 20px;
  font-weight: normal;
`;

export interface BenchmarkProps extends BenchmarkShape {
  index: number;
  onChange(index: number, modifier: (benchmark: BenchmarkShape) => BenchmarkShape): void;
  onRemove?(index: number): void;
  resultsFilter(item: SearchMenuItem): boolean;
}

export default class Benchmark extends React.Component<BenchmarkProps> {
  onWeightChange = (value: string) =>
    this.props.onChange(this.props.index, (benchmark) => ({
      ...benchmark,
      weight: value,
    }));

  onScalingSignChange = (value: DropMenuItem<ScalingSign>) =>
    this.props.onChange(this.props.index, (benchmark) => ({
      ...benchmark,
      scalingSign: value.value,
    }));

  onScalingValueChange = (value: string) =>
    this.props.onChange(this.props.index, (benchmark) => ({
      ...benchmark,
      scalingValue: value,
    }));

  onFundSelect = (item: SearchMenuItem) => {
    this.props.onChange(this.props.index, (benchmark) => ({
      ...benchmark,
      item,
    }));
  };

  onRemove = () => {
    if (this.props.onRemove) {
      this.props.onRemove(this.props.index);
    }
  };

  render() {
    const { scalingValue, scalingSign, weight, onRemove, item, resultsFilter } = this.props;
    return (
      <BenchmarkWrapper>
        <Weight value={weight} onValueChange={this.onWeightChange} />
        <Fund onFundSelect={this.onFundSelect} value={item} resultsFilter={resultsFilter} />
        <BenchmarkScalingSign value={scalingSign} onChange={this.onScalingSignChange} />
        <ScalingValue value={scalingValue} onChange={this.onScalingValueChange} />
        {onRemove && (
          <RemoveFundWrapper onClick={this.onRemove}>
            <Icon prefix="far" type="times" />
          </RemoveFundWrapper>
        )}
      </BenchmarkWrapper>
    );
  }
}
