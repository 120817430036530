import React from 'react';
import { Modal, ModalFooter, ModalHeader, ModalSubhead } from '../../modal';

interface UnsavedStudioAllocatorChangesModalProps {
  message?: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const UnsavedStudioAllocatorChangesModal = ({
  message,
  onConfirm,
  onCancel,
}: UnsavedStudioAllocatorChangesModalProps) => {
  return (
    <Modal extraPadding>
      <ModalHeader extraPadding>Are you sure you want to close the Allocator panel?</ModalHeader>
      <ModalSubhead extraPadding>{message}</ModalSubhead>
      <ModalFooter onCancel={onCancel} onPrimaryClick={onConfirm} primaryLabel="Yes, discard changes" />
    </Modal>
  );
};

export default UnsavedStudioAllocatorChangesModal;
