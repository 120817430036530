import { useCallback, useMemo, useEffect } from 'react';
import type { Page, StudioSidePanelContextProps } from 'venn-components';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { selectedBlockState, selectedReportPage, viewPages, virtualScrollRef } from 'venn-state';
import { useScrollToBlock } from './useScrollToBlock';
import { useVirtualization } from './useVirtualization';

/** Logic for selecting */
const useSelectionSystem = (): StudioSidePanelContextProps => {
  const onSelectBlock: StudioSidePanelContextProps['onSelectBlock'] = useRecoilCallback(
    ({ set, snapshot }) =>
      async (refId, { scrollIntoView, isGlobal }) => {
        if (isGlobal) {
          set(selectedBlockState, undefined);
        } else {
          if (!refId) {
            return;
          }
          set(selectedBlockState, { refId, scrollToBlock: scrollIntoView });
        }

        const pages = await snapshot.getPromise(viewPages);
        const selectedPageIndex = pages?.findIndex((p: Page) => p.layout?.find((l) => l.i === refId)) ?? 0;
        const isOnReportLabPage = selectedPageIndex > -1;
        if (isOnReportLabPage) {
          set(selectedReportPage, selectedPageIndex);
        }

        triggerResizeEvent();
      },
    [],
  );

  const onSelectGlobal: StudioSidePanelContextProps['onSelectGlobal'] = useCallback(
    (scrollToTop) => {
      onSelectBlock(undefined, { scrollIntoView: false, isGlobal: true });
      if (scrollToTop) {
        document.getElementById('report-content-container')?.scrollIntoView();
      }
    },
    [onSelectBlock],
  );

  const onFocusSpecialPage: StudioSidePanelContextProps['onFocusSpecialPage'] = useRecoilCallback(
    ({ set }) =>
      (pageNumber) => {
        set(selectedReportPage, pageNumber);
        // right nav fallback to global
        onSelectGlobal();
      },
    [onSelectGlobal],
  );

  const isVirtualized = useVirtualization();
  const scrollToSelectedPage = useRecoilCallback(
    ({ snapshot }) =>
      async (selectedPageIndex: number) => {
        if (isVirtualized) {
          const ref = await snapshot.getPromise(virtualScrollRef);
          ref?.current?.scrollToIndex({
            index: selectedPageIndex,
            align: 'center',
          });
        } else {
          document.getElementById(`page-${selectedPageIndex}`)?.scrollIntoView({ behavior: 'smooth' });
        }
      },
    [isVirtualized],
  );

  const scrollToBlock = useScrollToBlock();
  const selectedBlock = useRecoilValue(selectedBlockState);
  useEffect(() => {
    if (selectedBlock?.scrollToBlock) {
      scrollToBlock(selectedBlock.refId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only scroll to the selected block when it changes.
  }, [selectedBlock]);

  const onSelectPage = useRecoilCallback(
    ({ set }) =>
      (pageIndex: number) => {
        set(selectedReportPage, pageIndex);
        scrollToSelectedPage(pageIndex);
        onSelectGlobal();
      },
    [onSelectGlobal, scrollToSelectedPage],
  );

  return useMemo(
    () => ({
      onSelectBlock,
      onSelectGlobal,
      onFocusSpecialPage,
      onSelectPage,
    }),
    [onFocusSpecialPage, onSelectBlock, onSelectGlobal, onSelectPage],
  );
};

export default useSelectionSystem;

export const triggerResizeEvent = () => {
  setTimeout(() => window.dispatchEvent(new Event('resize')), 0);
};
