import React from 'react';
import { Button } from 'venn-ui-kit';
import { EmptyStateContent, ScreenOnly } from '../components/styled';

interface ErrorInvalidAnalysisPeriodProps {
  defaultMessage?: string;
  onResetAnalysisPeriod?: () => void;
}

const ErrorInvalidAnalysisPeriod: React.FC<React.PropsWithChildren<ErrorInvalidAnalysisPeriodProps>> = ({
  defaultMessage,
  onResetAnalysisPeriod,
}) => {
  return (
    <EmptyStateContent>
      {defaultMessage || 'Your selected analysis period is preventing the analysis from running.'}
      <ScreenOnly style={{ marginTop: 40 }}>
        <Button className="qa-reset-button" onClick={onResetAnalysisPeriod}>
          Reset Analysis Period
        </Button>
      </ScreenOnly>
    </EmptyStateContent>
  );
};
export default ErrorInvalidAnalysisPeriod;
