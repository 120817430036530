import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { AuthButtonGroup, AuthButton, AuthCopyright, AuthLogo } from '../components';

import { Markdown } from 'venn-components';
import { GetColor, ZIndex } from 'venn-ui-kit';
import LegalLinks from './LegalLinks';

export interface TermsProps {
  acceptTerm?: () => void;
  acceptButtonText?: string;
  enableLegalLinks?: boolean;
  children: string;
}

const FixedContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${GetColor.Black};
  z-index: ${ZIndex.Front};
`;

const SplashVideo = styled.video`
  position: fixed;
  top: 50%;
  left: 50%;
  width: auto;
  min-width: 100%;
  height: auto;
  min-height: 100%;
  transform: translateX(-50%) translateY(-50%);
  background-size: cover;
  z-index: ${ZIndex.Background};
`;

const PageContainer = styled.div`
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
`;

const TermsInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 700px;
  min-height: 700px;
  margin: 100px auto 20px;
`;

const MarkdownContainer = styled.div`
  width: 100%;
  margin: 48px -180px 24px;
  padding: 45px 40px 42px;
  border: 1px solid ${GetColor.Black};
  background-color: rgba(52, 61, 67, 0.25);
`;

const StyledMarkdown = styled(Markdown)`
  padding-right: 10px;
  max-height: 300px;
  font-size: 16px;
  text-align: left;
  overflow: auto;
  color: ${GetColor.UNSAFE.Silver};

  p {
    font-size: 16px;
    line-height: 25px;
  }
`;

export const TermsLayout = ({
  children,
  acceptTerm,
  acceptButtonText = 'Agree',
  enableLegalLinks = false,
}: TermsProps) => {
  const { Videos } = useContext(ThemeContext);
  return (
    <FixedContainer>
      <SplashVideo autoPlay loop muted>
        <source src={Videos.splashScreenAnimation} type="video/mp4" />
      </SplashVideo>
      <PageContainer>
        <TermsInnerWrapper className="qa-terms-container">
          <AuthLogo />
          <MarkdownContainer className="qa-markdown-container">
            <StyledMarkdown source={children} />
          </MarkdownContainer>
          {enableLegalLinks && <LegalLinks />}
          {!!acceptTerm && (
            <AuthButtonGroup>
              <AuthButton darkText dominant onClick={acceptTerm} className="qa-submit-btn">
                <span className="qa-submitAccept">{acceptButtonText}</span>
              </AuthButton>
            </AuthButtonGroup>
          )}
          <AuthCopyright lineBreak />
        </TermsInnerWrapper>
      </PageContainer>
    </FixedContainer>
  );
};
