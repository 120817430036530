import React, { PureComponent } from 'react';
import { first, isEmpty, last, orderBy } from 'lodash';
import type { Theme } from 'venn-ui-kit';
import { ColorUtils } from 'venn-ui-kit';
import type { default as d3Selection } from 'd3-selection';
import { select } from 'd3-selection';
import { extent } from 'd3-array';
import type { default as d3Scale } from 'd3-scale';
import { scaleLinear, scaleTime } from 'd3-scale';
import type { DataPoint } from 'venn-utils';
import { Dates } from 'venn-utils';
import { withTheme } from 'styled-components';
import type { ProxyTypeEnum } from 'venn-api';

const height = 34;
const width = 260;
const BANDS_OPACITY = 0.2;

type SVGType = d3Selection.Selection<SVGSVGElement, Record<string, never>, null, undefined>;

/**
 * Helper that calls func on a non-empty array and returns T instead of T | undefined
 */
function callOnNonEmptyArray<T>(func: (array: T[]) => T | undefined, nonEmptyArray: T[]): T {
  return func(nonEmptyArray) as T;
}

interface LineChartProps {
  /**
   * Time series for the proxy
   */
  proxy: DataPoint[];
  /**
   * Time series for the investment
   */
  investment: DataPoint[];
  /**
   * Time series for the investment
   */
  extrapolation: DataPoint[];
  /**
   * The current theme
   */
  theme: Theme;
  /**
   * The x value of the DataPoint where the use of proxied data ends
   */
  proxyEnd: number | undefined;
  /**
   * Method of proxying
   */
  proxyType: ProxyTypeEnum;
}

class LineChart extends PureComponent<LineChartProps> {
  node: SVGSVGElement | null;

  constructor(props: LineChartProps) {
    super(props);
    this.buildChart = this.buildChart.bind(this);
  }

  componentDidMount() {
    this.buildChart();
  }

  componentDidUpdate() {
    this.buildChart();
  }

  async buildChart() {
    const { node } = this;
    if (!node) {
      return;
    }
    node.innerHTML = '';

    const shape = await import('d3-shape');
    const line = shape.line;

    const {
      proxy,
      investment,
      proxyType,
      proxyEnd,
      extrapolation,
      theme: { Colors, Schemes },
    } = this.props;
    const investmentColor = Schemes.Proxy.invertedSubjectLine;
    const proxyColor = Schemes.Proxy.proxyLine;
    const extrapolationColor = Schemes.Proxy.extrapolation;

    /* If investment is null or it is an empty array it will not be displayed on the chart, but it will still try to
     * display the proxy data if possible */
    const displayInvestment = investment.length > 0;
    /* Similarly, there might be no "proxy" series (for example, if proxyType === EXTRAPOLATION) */
    const displayProxy = proxy.length > 0;
    if (!displayProxy && !displayInvestment) {
      return;
    }

    const startProxy = displayProxy ? callOnNonEmptyArray(first, proxy).x : undefined;
    const endProxy = displayProxy ? callOnNonEmptyArray(last, proxy).x : undefined;

    /* If the investment returns are not displayed the start-end range corresponds to that of the proxy */
    const { startInv, endInv } = displayInvestment
      ? {
          startInv: callOnNonEmptyArray(first, investment).x,
          endInv: callOnNonEmptyArray(last, investment).x,
        }
      : { startInv: undefined, endInv: undefined };

    // need to start the visual highlight as soon as the other highlights end, otherwise there would be a gap
    const extrapolationHighlightStart = isEmpty(extrapolation) ? undefined : Math.max(endInv ?? 0, endProxy ?? 0);
    const extrapolationHighlightEnd = isEmpty(extrapolation) ? undefined : callOnNonEmptyArray(last, extrapolation).x;
    const start = Math.min(startProxy ?? Infinity, startInv ?? Infinity);
    const end = Math.max(endProxy ?? 0, endInv ?? 0, extrapolationHighlightEnd ?? 0);
    const margins = { top: 0, bottom: 10, right: 0, left: 0 };
    const seriesMargin = 3;
    const svg: SVGType = select(node);
    const series = svg.append('g').attr('transform', `translate(${margins.left}, ${margins.top})`);
    const proxyDatum = proxy.map((d) => [d.x, d.y]);
    const investmentDatum = investment.map((d) => [d.x, d.y]);
    const extrapolationDatum = extrapolation.map((d) => [d.x, d.y]);

    const allData = [...investmentDatum, ...proxyDatum, ...extrapolationDatum];
    const allOrdered = orderBy(allData, (datum) => datum[0]);

    // we can make a type assertion because we're sure `allOrdered` is nonempty
    const xRange = extent(allOrdered, (d) => d[0]) as [number, number];
    const x = scaleTime()
      .rangeRound([0, width - margins.left - margins.right])
      .domain(xRange);
    const y = scaleLinear()
      .rangeRound([height - margins.top - margins.bottom - seriesMargin * 2, seriesMargin])
      .domain(extent(allOrdered, (d) => d[1]) as [number, number]);
    const serie = line()
      .x((d) => x(d[0]))
      .y((d) => y(d[1]));

    if (displayProxy) {
      svg
        .append('g')
        .append('rect')
        .attr('x', x(startProxy!))
        .attr('y', 0)
        .attr('width', x(proxyEnd!) - x(startProxy!))
        .attr('height', height - margins.bottom)
        .attr('fill', ColorUtils.hex2rgba(proxyColor, BANDS_OPACITY));
    }

    /* Do not render investment-specific SVG elements if investments have no returns */
    if (displayInvestment) {
      // if the investment returns aren't being used, don't highlight the investment range
      // and don't draw the line where the proxy ends either
      if (proxyType !== 'SUBSTITUTE') {
        svg
          .append('g')
          .append('rect')
          .attr('x', x(startInv!))
          .attr('y', 0)
          .attr('width', x(endInv!) - x(startInv!))
          .attr('height', height - margins.bottom)
          .attr('fill', ColorUtils.hex2rgba(investmentColor, BANDS_OPACITY));

        if (displayProxy) {
          // show a vertical separator between proxy and investment
          svg
            .append('line')
            .attr('x1', x(startInv!))
            .attr('y1', 0)
            .attr('x2', x(startInv!))
            .attr('y2', height - margins.bottom)
            .attr('stroke-dasharray', '4 1')
            .attr('stroke', Schemes.Proxy.proxyLine);
        }
      }

      series
        .append('path')
        .datum(investmentDatum)
        .attr('fill', 'none')
        .attr('stroke', investmentColor)
        .attr('stroke-linejoin', 'round')
        .attr('stroke-linecap', 'round')
        .attr('stroke-width', 1)
        // @ts-expect-error: TODO fix strictFunctionTypes
        .attr('d', serie);

      svg
        .append('circle')
        .attr('cx', x(startInv!))
        .attr('cy', y(investment[0].y))
        .attr('r', 2.5)
        .attr('fill', Schemes.Proxy.invertedSubjectLine);

      svg
        .append('circle')
        .attr('cx', x(endInv!))
        .attr('cy', y(callOnNonEmptyArray(last, investment).y))
        .attr('r', 2.5)
        .attr('fill', Schemes.Proxy.invertedSubjectLine);
    }

    series
      .append('path')
      .datum(proxyDatum)
      .attr('fill', 'none')
      .attr('stroke', proxyColor)
      .attr('stroke-linejoin', 'round')
      .attr('stroke-linecap', 'round')
      .attr('stroke-width', 1)
      // @ts-expect-error: TODO fix strictFunctionTypes
      .attr('d', serie);

    if (!isEmpty(extrapolation)) {
      // draw extrapolation line
      series
        .append('path')
        .datum(extrapolationDatum)
        .attr('fill', 'none')
        .attr('stroke', extrapolationColor)
        .attr('stroke-linejoin', 'round')
        .attr('stroke-linecap', 'round')
        .attr('stroke-width', 1)
        // @ts-expect-error: TODO fix strictFunctionTypes
        .attr('d', serie);

      // show visual highlight for extrapolation
      svg
        .append('g')
        .append('rect')
        .attr('x', x(extrapolationHighlightStart!))
        .attr('y', 0)
        .attr('width', x(extrapolationHighlightEnd!) - x(extrapolationHighlightStart!))
        .attr('height', height - margins.bottom)
        .attr('fill', ColorUtils.hex2rgba(extrapolationColor, BANDS_OPACITY));

      // show a vertical separator where extrapolation starts
      svg
        .append('line')
        .attr('x1', x(extrapolationHighlightStart!))
        .attr('y1', 0)
        .attr('x2', x(extrapolationHighlightStart!))
        .attr('y2', height - margins.bottom)
        .attr('stroke-dasharray', '4 1')
        .attr('stroke', Colors.DEPRECATED_DataLineColor.PaleBlue);
    }

    // add dates below the chart
    addLegend(svg, x, start, Colors.MidGrey2);
    addLegend(svg, x, end, Colors.MidGrey2, 10);
    if (displayInvestment) {
      // minor offset hacking to prevent dates occluding one another
      if (startInv !== start) addLegend(svg, x, startInv!, Colors.MidGrey2, 16);
      if (endInv !== end) addLegend(svg, x, endInv!, Colors.MidGrey2, 16);
    }
  }

  render() {
    return <svg width={width} height={height} ref={(node) => (this.node = node)} />;
  }
}

const addLegend = (
  svg: SVGType,
  scaleX: d3Scale.ScaleTime<number, number>,
  date: number,
  color: string,
  offset?: number,
) => {
  const year = Dates.parse(date).format("'YY");
  svg
    .append('text')
    .attr('x', scaleX(date) - (offset || 0))
    .attr('y', 32)
    .attr('fill', color)
    .attr('font-size', 8)
    .text(year);
};

export default withTheme(LineChart);
