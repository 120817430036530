import type { FactorSummaryChartDataPoint } from '../../../factor-summary/factorSummaryTypes';
import type { FactorDescription } from '../../../factor-chart/tooltips/FactorSummaryTooltips';
import type { Analysis, AnalysisTypeEnum, FactorLensWithReturns } from 'venn-api';
import type { AnalysisSubject, AnalysesPeriod, TimeFrame } from 'venn-utils';
import type { TrackAnalysisProps } from '../../chart-errors/TrackAnalysis';
import type { RangeType } from 'venn-ui-kit';
import type { DownloadMetaData } from '../../../downloadable';

export interface FactorsDataPoint {
  risk?: FactorSummaryChartDataPoint;
  return?: FactorSummaryChartDataPoint;
  exposure?: FactorSummaryChartDataPoint;
}

export interface FactorsData {
  factorId: number;
  factorName: string;
  mainDataPoint?: FactorsDataPoint;
  baselineDataPoint?: FactorsDataPoint;
  comparisonDataPoint?: FactorsDataPoint;
  categoryDataPoint?: FactorsDataPoint;
}

export interface FactorAnalysisGroup {
  risk?: Analysis;
  return?: Analysis;
  exposure?: Analysis;
}

export type factorAPIResponseKey =
  | 'factorContributionToRisk'
  | 'factorContributionToReturn'
  | 'factorExposures'
  | 'factorContributionToRiskTrend'
  | 'factorContributionToReturnTrend'
  | 'factorExposuresTrend';

export interface FactorChartsProps {
  defaultFactorsName?: string;
  factorsDescriptions: FactorDescription[];
  analysisSubject: AnalysisSubject;
  relative: boolean;
  categoryActive: boolean;
  activeFactorLens?: FactorLensWithReturns;
  analysisGroup: FactorAnalysisGroup;
  width: number;
  portfolioHasMultipleFunds: boolean;
  analysesPeriod?: AnalysesPeriod;
  selectedTimeFrame: TimeFrame;
  onResetTimeFrame?: () => void;
  selectedPeriod?: RangeType;
  onPrintUpdate?: (newTrend: FactorAnalysisGroup) => void;
  block?: string;
  onUpdateRollingPeriod?: (rollingYears: number) => void;
  trackingProps: TrackAnalysisProps;
  downloadMetaData?: DownloadMetaData;
  /** Prevent unnecessary async call in print */
  isPrint?: boolean;
}

export interface FactorTrendTitle {
  title: string;
  subtitle: string;
  activeTitle: string;
}

export interface RangeGenerator {
  extremes: {
    min: number;
    max: number;
  };

  colorGetter: (value: number) => string | undefined;
}

export const EXPOSURE_BLOCK: AnalysisTypeEnum = 'FACTOR_EXPOSURES_TREND';
export const RISK_BLOCK: AnalysisTypeEnum = 'FACTOR_CONTRIBUTION_TO_RISK_TREND';
export const RETURN_BLOCK: AnalysisTypeEnum = 'FACTOR_CONTRIBUTION_TO_RETURN_TREND';
