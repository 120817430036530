import React from 'react';
import { Modal, ModalDescription, ModalFooter, ModalHeader, ModalSize } from 'venn-components';
import { getAppTitle } from 'venn-ui-kit';

interface ResetConfirmationModalProps {
  onCancel: () => void;
  onConfirm: () => void;
}

const ResetConfirmationModal = ({ onCancel, onConfirm }: ResetConfirmationModalProps) => {
  return (
    <Modal onClose={onCancel} size={ModalSize.Large}>
      <ModalHeader>Reset “What’s New?” Content?</ModalHeader>
      <ModalDescription>
        {`This will revert to ${getAppTitle()}’s content in the “What’s New?” newsfeed on the home page.`}
      </ModalDescription>
      <ModalFooter
        primaryLabel={`Reset to ${getAppTitle()}’s Content`}
        onPrimaryClick={onConfirm}
        onCancel={onCancel}
        primaryClassName="qa-reset-btn"
      />
    </Modal>
  );
};

export default ResetConfirmationModal;
