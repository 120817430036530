import React, { useCallback, useContext } from 'react';
import { UserContext } from 'venn-components';
import { analyticsService, useHasFF } from 'venn-utils';
import type { PrivatesTableRow } from '../privatesColumns';
import { AddToReportLab } from './AddToReportLab';
import { AnalyzeInStudio } from './AnalyzeInStudio';
import PrivateAddToPortfolio from './PrivateAddToPortfolio';
import { PrivateDeleteItems } from './PrivateDeleteItems';
import type { BulkAction, PrivateBulkActionProps } from './types';

interface PrivateBulkActionsIconsProps {
  /** The items on which to perform the action */
  selectedItems: PrivatesTableRow[];
  /** Function to call after executing a bulk action to clear the selected items */
  clearSelectedItems: () => void;
  updateData: () => void;
}

export const PrivateBulkActionsIcons = ({
  selectedItems,
  clearSelectedItems,
  updateData,
}: PrivateBulkActionsIconsProps) => {
  const { hasPermission } = useContext(UserContext);
  const hasStudio = useHasFF('studio_ff');
  const hasReportlab = useHasFF('studio_report_editor');

  const hasAccessToPortfolios = hasPermission('CREATE_PORTFOLIO');
  const onComplete = useCallback(
    (action: BulkAction, closeModalFn: () => void, clearSelectedAndRefresh = false) => {
      analyticsService.bulkActionPerformed({
        type: action,
        numberOfItems: selectedItems.length,
        subjects: {
          privateFundIds: selectedItems.filter(({ itemType }) => itemType === 'INVESTMENT').map(({ id }) => id),
          privatePortfolioIds: selectedItems.filter(({ itemType }) => itemType === 'PORTFOLIO').map(({ id }) => id),
        },
      });
      if (clearSelectedAndRefresh) {
        clearSelectedItems();
      }
      closeModalFn();
    },
    [clearSelectedItems, selectedItems],
  );

  const bulkActionProps: PrivateBulkActionProps = {
    items: selectedItems,
    onComplete,
    updateData,
  };

  return (
    <>
      {hasAccessToPortfolios && <PrivateAddToPortfolio {...bulkActionProps} />}
      {hasStudio && <AnalyzeInStudio {...bulkActionProps} />}
      {hasReportlab && <AddToReportLab {...bulkActionProps} />}
      <PrivateDeleteItems {...bulkActionProps} />
    </>
  );
};
