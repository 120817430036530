import type { ReactNode } from 'react';
import React, { useCallback, useContext } from 'react';
import styled, { ThemeContext, css } from 'styled-components';
import { get } from 'lodash';
import HeatMap, { topMargin } from './HeatMap';
import type { HeatMapTypes } from './Types';
import { getProformaPortfolioPalette } from './utils';
import type { DataExportEvent, AnalysisTypeEnum, Message } from 'venn-api';
import { SupportedErrorCodes } from 'venn-api';
import type { ExcelCell } from 'venn-utils';
import DownloadableContentBlock from '../content-block/DownloadableContentBlock';
import { toDownloadTrackingEvent } from '../downloadable/helper';
import { TREND_TOOLTIP_OFFSET } from '../factor-summary/charts/constants';
import { RollingPeriodPicker } from '../rolling-period-picker/RollingPeriodPicker';
import { Loading } from 'venn-ui-kit';
import EmptyState from '../empty-state/EmptyState';
import type { DownloadMetaData } from '../downloadable';
import { useAppPrintMode } from '../print';

export type TopLegendFormatter = (value?: number | null) => string;
export interface FactorDescriptionType {
  id: number;
  description: string;
}
export type OnCellClickType = (
  data: HeatMapTypes.DataEntity,
  coords: { x: number; y: number },
  newPosition: { x: number; y: number; tooltipRight?: boolean },
) => void;

const relativeNumberFormatter = new Intl.NumberFormat('en-US', {
  useGrouping: true,
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
  style: 'percent',
});

const defaultTopLegendFormatter: TopLegendFormatter = (value?: number | null) =>
  value == null ? '--' : relativeNumberFormatter.format(value);

interface Props {
  title: string;
  subtitle?: string | ReactNode;
  timestamps: number[];
  seriesDataSize: number;
  factorsDescriptions: FactorDescriptionType[];
  series: HeatMapTypes.Root[];
  topLegendFormatter?: TopLegendFormatter;
  isPercentage?: boolean;
  onCellClick?: OnCellClickType;
  width: number;
  isCellClickable?: boolean;
  filterCount?: number;
  isFund?: boolean;
  exportData?: ExcelCell[][];
  dataExportEvent: DataExportEvent;
  chartReloading?: AnalysisTypeEnum[];
  block: AnalysisTypeEnum;
  onUpdateRollingPeriod?: (rollingYears: number | undefined, trendBlock: AnalysisTypeEnum) => void;
  onResetAnalysisPeriod?: () => void;
  metricType?: 'risk' | 'return';
  errorMessage?: Message;
  regressionYears?: number | null;
  regressionYearsOptions: { [key: string]: boolean } | undefined;
  downloadMetaData?: DownloadMetaData;
  ActionsComponent?: ReactNode;
}

const MapWrapper = ({
  timestamps,
  seriesDataSize,
  factorsDescriptions,
  title,
  subtitle,
  series,
  width,
  isPercentage = true,
  onUpdateRollingPeriod,
  topLegendFormatter = defaultTopLegendFormatter,
  isCellClickable,
  filterCount,
  isFund,
  exportData,
  dataExportEvent,
  metricType,
  onCellClick,
  chartReloading,
  block,
  errorMessage,
  regressionYears,
  regressionYearsOptions,
  onResetAnalysisPeriod,
  downloadMetaData,
  ActionsComponent = (
    <RollingPeriodPicker
      selectedPeriod={regressionYears}
      periods={regressionYearsOptions}
      block={block}
      onUpdateRollingPeriod={onUpdateRollingPeriod}
    />
  ),
}: Props) => {
  const theme = useContext(ThemeContext);
  const { Colors } = theme;

  const handleClick = useCallback(
    (data, coords, cellPosition) => {
      if (onCellClick && cellPosition) {
        const newPos = {
          x: cellPosition.x,
          y: cellPosition.y + cellPosition.height / 2 + topMargin + TREND_TOOLTIP_OFFSET,
          tooltipRight: cellPosition.tooltipRight,
        };

        onCellClick(data, coords, newPos);
      }
    },
    [onCellClick],
  );

  const resetRollingPeriod = useCallback(() => {
    onUpdateRollingPeriod?.(undefined, block);
  }, [onUpdateRollingPeriod, block]);

  const portfolioName = get(series, [0, 'series', 0, 'name']);
  const errorAction = getErrorAction(errorMessage?.code, resetRollingPeriod, onResetAnalysisPeriod);

  const getHeatmap = (downloading: boolean, print: boolean) => (
    <>
      {ActionsComponent}
      {chartReloading?.includes(block) ? (
        <LoadingWrapper>
          <Loading pageLoader title="Loading details..." />
        </LoadingWrapper>
      ) : series.length !== 0 ? (
        <HeatMap
          cellHeight={print ? 17 : 33}
          downloadable={downloading}
          factorsColumnWidth={print ? 110 : 170}
          factorsDescriptions={factorsDescriptions}
          filterCount={filterCount}
          isCellClickable={isCellClickable}
          isFund={isFund}
          isPercentage={isPercentage}
          metricType={metricType}
          onCellClick={handleClick}
          palette={getProformaPortfolioPalette(Colors)}
          print={print}
          series={series}
          seriesDataSize={seriesDataSize}
          timestamps={timestamps}
          topLegendFormatter={topLegendFormatter}
          width={width}
          showSubjectsValues
          theme={theme}
          // We can't have negative margin in-app because the overlap prevents interaction with the action bar
          marginTop="0px"
          // We can make the heatmap overlap the actions bar during export to reduce whitespace, because the actions bar is right aligned
          // and the header of the heatmap is left aligned, and the action bar is not interactive during export.
          exportMarginTop="-30px"
        />
      ) : errorMessage ? (
        <StyledEmptyState
          header="Unable to run analysis"
          width={print ? width - 40 : undefined}
          message={errorMessage.text}
          {...errorAction}
        />
      ) : (
        <StyledEmptyState header="No factors show significant results." />
      )}
    </>
  );

  const { inPrintMode } = useAppPrintMode();

  return (
    <Wrapper>
      <DownloadableContentBlock
        header={title}
        subHeader={subtitle}
        downloadableContent={() => getHeatmap(true, inPrintMode)}
        downloadable={{
          png: true,
          excel: exportData,
          options: {
            fileName: `${title} - ${portfolioName} - Trend`,
            metaData: downloadMetaData,
          },
          tracking: toDownloadTrackingEvent(dataExportEvent),
        }}
      >
        {getHeatmap(false, inPrintMode)}
      </DownloadableContentBlock>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 20px;
`;

const StyledEmptyState = styled(EmptyState)<{ width?: number }>`
  margin: 20px;
  ${({ width }) =>
    width &&
    css`
      width: ${width}px;
    `}
`;

const LoadingWrapper = styled.div`
  height: 300px;
`;

export default MapWrapper;

interface ErrorInfo {
  actionLabel?: string;
  onAction?: () => void;
}

const getErrorAction = (
  errorCode?: number,
  resetRollingPeriod?: () => void,
  resetAnalysisPeriod?: () => void,
): ErrorInfo => {
  switch (errorCode) {
    case SupportedErrorCodes.InvalidRollingPeriod:
      return { actionLabel: 'Reset Rolling Period', onAction: resetRollingPeriod };
    case SupportedErrorCodes.PeriodTooNarrowForFactorTrend:
      return { actionLabel: 'Reset Analysis Period', onAction: resetAnalysisPeriod };
    default:
      return {};
  }
};
