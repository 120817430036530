import React from 'react';
import {
  Body1,
  GetColor,
  GROWTH_SIMULATOR_FAQ_HREF,
  InfoLink,
  NOTABLE_PERIODS_FAQ_HREF,
  SENSITIVITY_ANALYSIS_FAQ_HREF,
  PORTFOLIO_COMPARISON_HELP_HREF,
  TooltipPosition,
  PUBLIC_PRIVATE_ASSET_GROWTH_HREF,
  INVESTMENT_CORRELATION_TOOL_HREF,
  PEER_GROUP_ANALYSIS_FAQ_HREF,
  HOLDINGS_FAQ_HREF,
  PRIVATE_ASSET_CASH_FLOW_HELP_HREF,
  PRIVATE_PERFORMANCE_HELP_HREF,
} from 'venn-ui-kit';
import { withSuspense } from 'venn-utils';
import { useRecoilState, useRecoilValue } from 'recoil';
import { blockSettings, studioLeftBlockConfigPanelExpandedAtom, studioLeftPanelOpen } from 'venn-state';
import BlockTabs from './tabs/BlockTabs';
import styled from 'styled-components';
import { ExpandCollapseButton } from 'venn-components';
import type { CustomBlockTypeEnum } from 'venn-utils';

// TODO(will): Change this back to constant map post macro release
const getBlockDefinitionLinks = (): Partial<Record<CustomBlockTypeEnum, string>> => ({
  GROWTH_SIMULATION: GROWTH_SIMULATOR_FAQ_HREF,
  SCENARIO: SENSITIVITY_ANALYSIS_FAQ_HREF,
  NOTABLE_PERIODS: NOTABLE_PERIODS_FAQ_HREF,
  PORTFOLIO_COMPARISON: PORTFOLIO_COMPARISON_HELP_HREF,
  PUBLIC_PRIVATE_ASSET_GROWTH_BREAKDOWN: PUBLIC_PRIVATE_ASSET_GROWTH_HREF,
  PUBLIC_PRIVATE_ASSET_GROWTH_PERCENTILES: PUBLIC_PRIVATE_ASSET_GROWTH_HREF,
  CORRELATION: INVESTMENT_CORRELATION_TOOL_HREF,
  PEER_GROUPS: PEER_GROUP_ANALYSIS_FAQ_HREF,
  ASSET_EXPOSURE: HOLDINGS_FAQ_HREF,
  GEOGRAPHY_EXPOSURE: HOLDINGS_FAQ_HREF,
  SECTOR_EXPOSURE: HOLDINGS_FAQ_HREF,
  PRIVATE_CASH_FLOW: PRIVATE_ASSET_CASH_FLOW_HELP_HREF,
  PRIVATE_CAPITAL_SUMMARY: PRIVATE_ASSET_CASH_FLOW_HELP_HREF,
  PRIVATE_PERFORMANCE_SUMMARY: PRIVATE_PERFORMANCE_HELP_HREF,
  PRIVATE_PERFORMANCE_TIME_SERIES: PRIVATE_PERFORMANCE_HELP_HREF,
});

/** Content for the panel that allows users to adjust a selected block's configuration, settings, options, and other similar synonyms. */
export const StudioBlockConfigPanelContent = withSuspense(
  // TODO: SHIMMER: add a shimmer block during suspense
  null,
  React.memo(function StudioBlockConfigPanelContent({ selectedBlockId }: { selectedBlockId: string }) {
    const [isExpanded, setIsExpanded] = useRecoilState(studioLeftBlockConfigPanelExpandedAtom);
    const isMainLeftPanelExpanded = useRecoilValue(studioLeftPanelOpen);

    return (
      <StudioBlockConfigWindow>
        <ExpandCollapseWrapper isExpanded={isExpanded} isMainLeftPanelExpanded={isMainLeftPanelExpanded}>
          <ExpandCollapseButton isOpen={isExpanded} onToggle={() => setIsExpanded(!isExpanded)} isLeftOrientation />
        </ExpandCollapseWrapper>
        {isExpanded && (
          <>
            <BlockConfigHeader selectedBlockId={selectedBlockId} />
            <BlockTabs selected={selectedBlockId} />
          </>
        )}
      </StudioBlockConfigWindow>
    );
  }),
);

const StudioBlockConfigWindow = styled.div`
  height: 100%;
  background-color: ${GetColor.White};
  border-left: 1px solid ${GetColor.Grey};
  border-right: 1px solid ${GetColor.Grey};
  overflow: auto;
  position: relative;
`;

const ExpandCollapseWrapper = styled.div<{ isExpanded: boolean; isMainLeftPanelExpanded: boolean }>`
  position: absolute;
  top: ${({ isMainLeftPanelExpanded }) => (isMainLeftPanelExpanded ? 14 : 11)}px;
  right: ${({ isExpanded }) => (isExpanded ? '2px' : 0)};
  ${({ isExpanded }) => !isExpanded && '& > button { margin-right: 6px; }'}
`;

const BlockConfigHeader = ({ selectedBlockId }: { selectedBlockId: string }) => {
  const blockSetting = useRecoilValue(blockSettings(selectedBlockId));

  const blockDefinitionUrl = getBlockDefinitionLinks()[blockSetting.customBlockType];

  return (
    <BlockConfigHeaderContainer>
      <Body1 style={{ display: 'inline-block' }}>
        <Title>
          {`${blockSetting?.title} Block`}
          {blockDefinitionUrl && (
            <InfoLink
              portalTooltip
              to={blockDefinitionUrl}
              tooltip="Block Definition"
              tooltipPosition={TooltipPosition.Bottom}
            />
          )}
        </Title>
        <ActiveBar />
      </Body1>
    </BlockConfigHeaderContainer>
  );
};

const Title = styled.div`
  font-size: 13px;
  font-weight: bold;
  padding: 4px 0 4px 2px;
`;

const BlockConfigHeaderContainer = styled.div`
  border-bottom: 1px solid ${GetColor.GreyScale.Grey10};
  height: 46px;
  padding-left: 10px;
  display: flex;
  align-items: flex-end;
`;

const ActiveBar = styled.div`
  background-color: ${GetColor.Primary.Main};
  height: 6px;
  width: 100%;
`;
