import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import type { SearchMenuItem } from '../../../../search-menu';
import { SearchMenuBar } from '../../../../search-menu';

const qaClass = 'qa-fund';

const FundWrapper = styled.div`
  flex: 0 0 356px;
  margin-right: 10px;
  flex-grow: 1;
  flex-shrink: 1;
  .select__control {
    border-width: 1px;
    border-radius: 4px;
  }
`;

export interface FundProps {
  onFundSelect: (select: SearchMenuItem) => void;
  value: SearchMenuItem | null;
  resultsFilter(item: SearchMenuItem): boolean;
}

const Fund: FC<React.PropsWithChildren<FundProps>> = ({ onFundSelect, value, resultsFilter }) => {
  return (
    <FundWrapper className={qaClass}>
      <SearchMenuBar
        value={value}
        onSelected={onFundSelect}
        investmentsOnly
        defaultMenuIsOpen={false}
        isOptionDisabled={resultsFilter}
        shortPlaceholder
        fixedMenuWidth
        autofocus={false}
        location="createCompositeBenchmark"
        privateAssetSearchMode="PUBLIC_ONLY"
      />
    </FundWrapper>
  );
};

export default Fund;
