import React, { useContext } from 'react';
import type {
  Portfolio,
  PortfolioSummary,
  OptimizationConfiguration,
  AllocationConstraint,
  ActionEnum,
} from 'venn-api';
import PortfoliosContext from '../contexts/portfolios-context';
import type { OptimizationErrorType } from '../contexts/optimal-portfolio-context';
import OptimalPortfolioContext from '../contexts/optimal-portfolio-context';
import type { ComparisonContextValue } from '../contexts/comparison-context';
import ComparisonContext from '../contexts/comparison-context';
import type { RangeDebugContextValue } from '../contexts/range-debug-context';
import RangeDebugContext from '../contexts/range-debug-context';
import type { AnalysisViewContextValue } from '../contexts/analysis-view-context';
import AnalysisViewContext from '../contexts/analysis-view-context';
import type { AnalysisConfigContextValue } from '../contexts/analysis-config-context';
import AnalysisConfigContext from '../contexts/analysis-config-context';
import UserContext from '../contexts/user-context';

interface MasterAllocationPanelProps {
  master: Portfolio;
  refreshPortfoliosInContext: () => void;
  hasPermission: (permission: ActionEnum) => boolean;
}

interface OptimizationAllocationPanelProps {
  updateOptimizationContextAfterSave: (
    savedPortfolio: Portfolio,
    nodeIdMapper?: Map<number, number>,
    clearAllNew?: boolean,
  ) => void;
  setOptimizationResult: (
    resultPortfolioId: number,
    resultSummary: PortfolioSummary,
    resultConfig: OptimizationConfiguration,
    resultPortfolio?: Portfolio,
    customAllocationConstraints?: AllocationConstraint[],
  ) => void;
  optimizeAdHoc: () => void;
  optimizationError?: OptimizationErrorType;
  canRerunOptimization?: boolean;
  setCanRerunOptimization?: (canRerunOptimization: boolean) => void;
  customAllocationConstraints?: AllocationConstraint[];
}

export type ContextDerivedProps = Partial<MasterAllocationPanelProps> &
  Partial<OptimizationAllocationPanelProps> &
  Partial<ComparisonContextValue> &
  Partial<Pick<RangeDebugContextValue, 'setPortfolioFundDeleter'>> &
  Partial<AnalysisViewContextValue> &
  Partial<AnalysisConfigContextValue>;

export default <P extends ContextDerivedProps>(Component: React.ComponentType<React.PropsWithChildren<P>>) =>
  // TODO(VENN-24534): add a display name to this React component
  // eslint-disable-next-line react/display-name
  (props: P) => {
    const { masterPortfolio, refresh } = useContext(PortfoliosContext);
    const {
      setOptimizationResult,
      updateOptimizationContextAfterSave,
      optimizeAdHoc,
      optimizationError,
      canRerunOptimization,
      setCanRerunOptimization,
      customAllocationConstraints,
    } = useContext(OptimalPortfolioContext);
    const { compareType, comparePortfolio, compareLoading, updateCompareType } = useContext(ComparisonContext);
    const { setPortfolioFundDeleter } = useContext(RangeDebugContext);
    const { isTradesView, isPercentageMode, onUpdateAnalysisViewParam } = useContext(AnalysisViewContext);
    const { unsavedPortfolioChanges, setUnsavedPortfolioChanges } = useContext(AnalysisConfigContext);
    const { hasPermission } = useContext(UserContext);
    return (
      <Component
        {...props}
        compareType={compareType}
        comparePortfolio={comparePortfolio}
        compareLoading={compareLoading}
        updateCompareType={updateCompareType}
        updateOptimizationContextAfterSave={updateOptimizationContextAfterSave}
        master={masterPortfolio}
        refreshPortfoliosInContext={refresh}
        setOptimizationResult={setOptimizationResult}
        optimizeAdHoc={optimizeAdHoc}
        optimizationError={optimizationError}
        canRerunOptimization={canRerunOptimization}
        setCanRerunOptimization={setCanRerunOptimization}
        customAllocationConstraints={customAllocationConstraints}
        setPortfolioFundDeleter={setPortfolioFundDeleter}
        isTradesView={isTradesView}
        isPercentageMode={isPercentageMode}
        onUpdateAnalysisViewParam={onUpdateAnalysisViewParam}
        unsavedPortfolioChanges={unsavedPortfolioChanges}
        setUnsavedPortfolioChanges={setUnsavedPortfolioChanges}
        hasPermission={hasPermission}
      />
    );
  };
