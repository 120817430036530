import React, { useContext, useEffect, useRef } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { UnsavedChangesModal, UserContext } from 'venn-components';
import { Routes, FS } from 'venn-utils';
import ShellLayoutContainer from './shell-layout/ShellLayoutContainer';
import Logout from './shell-navigation/components/Logout';
import moduleConfigInitializer from '../config/shellModuleConfigInitializer';
import AnimatedRouterSwitch from './AnimatedRouterSwitch';
import { useDowntimeEffect } from '../hooks/useDowntimeEffect';

export interface ShellRouterProps extends RouteComponentProps {
  isAppExpired: boolean;
}

const PORTFOLIO_DETAILS_GENERAL_PATH = '/portfolio/:id';

const ShellRouter = (props: ShellRouterProps) => {
  const { hasPermission } = useContext(UserContext);
  const { history, location, isAppExpired } = props;
  const historyRef = useRef(history);
  const isAppExpiredRef = useRef(isAppExpired);

  useEffect(() => {
    UnsavedChangesModal.history = historyRef.current;
    historyRef.current.listen(() => {
      isAppExpiredRef.current && window.location.reload();
    });
  }, []);

  useDowntimeEffect();

  return (
    <AnimatedRouterSwitch>
      <Switch location={location}>
        {/* make sure to pass location into Switch so that route animations work */}
        {moduleConfigInitializer(hasPermission).map((config) =>
          FS.hasAll(config.requiredFeatures) && !config.disabled ? (
            <Route
              path={config.path}
              key={config.path}
              exact={config.exact}
              render={(renderProps) => (
                <ShellLayoutContainer {...config.layoutOptions} {...props}>
                  <config.module {...renderProps} />
                </ShellLayoutContainer>
              )}
            />
          ) : undefined,
        )}
        <Route exact path={Routes.LOGOUT_PATH} render={() => <Logout />} />
        <Redirect exact from={PORTFOLIO_DETAILS_GENERAL_PATH} to={`${PORTFOLIO_DETAILS_GENERAL_PATH}/performance`} />
        <Redirect
          from={Routes.FACTOR_PERFORMANCE_LEGACY_PATH}
          to={{
            pathname: Routes.FACTOR_LENS_INSIGHTS,
            search: location.search,
          }}
        />
        <Redirect
          from={Routes.FACTOR_PERFORMANCE_PATH}
          to={{
            pathname: Routes.FACTOR_LENS_INSIGHTS,
            search: location.search,
          }}
        />

        <Redirect from={Routes.INDEX_PATH} to={Routes.HOME_PATH} />
      </Switch>
    </AnimatedRouterSwitch>
  );
};

export default withRouter(ShellRouter);
