import React, { useCallback, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { BodyHighlight, GetColor, Headline3, Icon } from 'venn-ui-kit';
import { analyticsService, useModal } from 'venn-utils';
import VideoModal from '../modal/VideoModal';

import VideoBackgroundImage from './factors-video-background.png';

const LEARN_FACTORS = "Learn Two Sigma's Factor Approach in 2.5 Minutes";

interface VideoAnalyticsProps {
  pageName: string;
  locationOnPage: string;
}

interface FactorsOnboardingVideoProps {
  analyticsUtils: VideoAnalyticsProps;
}

const FactorsOnboardingVideo = ({ analyticsUtils: { pageName, locationOnPage } }: FactorsOnboardingVideoProps) => {
  const [isOpen, open, close] = useModal();

  const { Videos } = useContext(ThemeContext);

  const trackHeartbeat = useCallback(
    (timeElapsed?: number, timeTotal?: number) => {
      analyticsService.videoPlaying({
        locationOnPage,
        pageName,
        videoTitle: LEARN_FACTORS,
        timeElapsed,
        timeTotal,
      });
    },
    [locationOnPage, pageName],
  );

  return (
    <>
      <VideoContainer>
        <Image src={VideoBackgroundImage} alt={LEARN_FACTORS} />
        <VideoCaption>
          <Headline3>{LEARN_FACTORS}</Headline3>
          <BodyHighlight>
            <Clock type="clock" prefix="far" /> 2:30
          </BodyHighlight>
        </VideoCaption>
        <Play>
          <PlayBackground />
          <IconWrapper type="button" onClick={open}>
            <Icon type="play-circle" />
          </IconWrapper>
        </Play>
      </VideoContainer>
      {isOpen && (
        <VideoModal onClose={close} trackHeartbeat={trackHeartbeat}>
          <source src={Videos.factorLensVideo} type="video/mp4" />
        </VideoModal>
      )}
    </>
  );
};

export default FactorsOnboardingVideo;

const VideoContainer = styled.div`
  position: relative;
  border: 1px solid ${GetColor.Grey};
  border-radius: 4px;
  overflow: hidden;
`;

const VideoCaption = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 10px 28px;
  background: linear-gradient(243.07deg, rgba(255, 255, 255, 0) 17.54%, #ffffff 66.85%);
`;

const Clock = styled(Icon)`
  margin-right: 4px;
`;

const Play = styled.div`
  position: absolute;
  top: calc(50% - 25px);
  right: calc(50% - 25px);
  font-size: 50px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const PlayBackground = styled.div`
  height: 42px;
  width: 42px;
  background-color: ${GetColor.White};
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: 50%;
`;

const IconWrapper = styled.button`
  position: absolute;
  top: -4px;
  right: -4px;
  color: ${GetColor.Black};
  &:hover {
    color: ${GetColor.DarkGrey};
    border-radius: 50%;
  }
`;

const Image = styled.img`
  top: -100%;
  position: relative;
  width: 100%;
`;
