import type { FactorWithNonSerializedReturns as FactorEntity } from 'venn-api';
import { some } from 'lodash';

export interface FactorCategory {
  name: string;
  factors: CheckableFactor[];
}

export interface CheckableFactor {
  checked: boolean;
  factor: FactorEntity;
}

export default (factors: FactorEntity[], selectedFactors?: FactorEntity[]): FactorCategory[] => {
  const results: Map<string, CheckableFactor[]> = new Map<string, CheckableFactor[]>();
  factors.forEach((factor) => {
    if (!results.has(factor.category)) {
      results.set(factor.category, []);
    }
    const category = results.get(factor.category);
    const checked = selectedFactors?.length ? some(selectedFactors, { id: factor.id }) : true;
    category!.push({
      checked,
      factor,
    });
  });

  return Array.from(results.keys()).map((key) => ({
    name: key,
    factors: results.get(key)!,
  }));
};
