import type { CSSProperties } from 'react';
import type { PortalAnchorPlacement } from './implementations/BaseDropMenu';
import type { TooltipPosition } from 'venn-ui-kit';

export enum OptionIconPosition {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export interface DropMenuItem<T = string> {
  /**
   * Unique value for each dropmenu item
   */
  value: T;
  /**
   * Label displayed on each item on the menu. Also displayed on the trigger
   * if that item was selected.
   */
  label: string;
  /**
   * Description displayed on each item on the menu.(Optional)
   */
  description?: string;
  /**
   * Makes the items hierarchical by moving the content to the right.
   * Please always start with level 0 for all root items.
   */
  level?: number;

  /**
   * Optional custom styling for element in drop menu
   */
  style?: CSSProperties;

  /**
   * Optional custom class for element in drop menu
   */
  className?: string;

  /**
   * Icon to be displayed to the left of the option label
   */
  icon?: JSX.Element;
  /**
   * Badge/icon to be displayed to the right of the option label
   */
  badge?: JSX.Element;
  /**
   * Icon position - left or right
   */
  iconPosition?: OptionIconPosition;
  /**
   * Whether this item should be disabled or not
   */
  disabled?: boolean;
  /**
   * Optional tooltip text to be shown when hovering over the item
   */
  tooltipContent?: string;
  /**
   * Optional position of tooltip when shown
   */
  tooltipPosition?: TooltipPosition;
  /**
   * If present, the item will be rendered as a link
   */
  link?: string;
  /**
   * If true, the item's tooltip will be shown even if the full item label is already shown
   */
  alwaysShowTooltip?: boolean;
}

export interface DropMenuCheckboxItem<T = string> extends DropMenuItem<T> {
  /** whether or not to show the checkbox */
  hideCheckbox?: boolean;
  /**
   * True if the item is selected (checked)
   */
  checked: boolean;
  /**
   * True if the item is indeterminate - i.e it is partially checked
   */
  indeterminate?: boolean;
  /**
   * Items to show beneath a checkable category to given context on what is included
   */
  contextualChildItems?: string[];
}

export interface MenuContainerProps {
  /**
   * This drives the **max-height** of the menu (not the trigger)
   */
  height?: string | number;
  /**
   * This drives the **min-width** of the menu (not the trigger)
   */
  width?: number;
  className?: string;
}

export interface TriggerComponentProps {
  /**
   * Please use for QA purpose only
   */
  className?: string;
  /**
   * Label displayed on top of the trigger. If not specified, no label will be shown.
   */
  label?: string;
  /**
   * Label displayed on the right hand side of the trigger. If not specified, no label will be shown.
   */
  rightLabel?: string;
  /**
   * Drives the text displayed on the trigger.
   */
  selection?: string;
  /** Custom icon in selected item */
  selectedIcon?: JSX.Element;
  /**
   * Icon to display to the left of the trigger text.
   */
  selectionIcon?: JSX.Element;
  /**
   * Whether the trigger should display its expanded state (i.e. menu opened)
   */
  expanded?: boolean;
  /**
   * Wheter the trigger is disabled
   */
  disabled?: boolean;
  /**
   * Used if the trigger currently has an invalid option selected
   */
  invalid?: boolean;
  /**
   * Highlights trigger, usually to indicate that value has been changed from the initial one
   */
  highlight?: boolean;
  /**
   * Reference to the Trigger
   */
  innerRef?: React.RefObject<HTMLDivElement>;
  /**
   * Fires when the user clicks on the trigger
   * If "open" is true, force the menu to open
   * If "open" is false, force the menu to close
   * If "open" is not set (default), toggle the menu
   */
  onClick?: (open?: boolean) => void;

  onKeyUp?: () => void;
  onKeyDown?: () => void;
  onKeyEsc?: () => void;
  onKeyEnter?: () => void;
}

export interface BaseDropMenuProps<T> {
  /**
   * Label displayed on top of the trigger. If not specified, no label will be shown.
   */
  label?: string;
  /**
   * Label displayed on the right hand side of the trigger. If not specified, no label will be shown.
   */
  rightLabel?: string;
  /**
   * Wheter the trigger is disabled
   */
  disabled?: boolean;
  /**
   * Used if the trigger currently has an invalid option selected
   */
  invalid?: boolean;
  /**
   * Highlights trigger, usually to indicate that value has been changed from the initial one
   */
  highlight?: boolean;
  /**
   * Please use for QA purpose only
   */
  className?: string;
  /**
   * If true, the menu will be opened by default, on mount.
   */
  openByDefault?: boolean;
  /**
   * If true, the menu will be moved into a React Portal. Use this if you want to render the dropdown
   * within a modal
   */
  usePortal?: boolean;
  /**
   Specify if portal should be anchored to the left or right of the component
   */
  portalAnchorPlacement?: PortalAnchorPlacement;
  /**
   * Reference to the Trigger element
   */
  innerRef?: React.RefObject<HTMLDivElement>;
  /**
   * Custom equality function, allowing to find the selected item from the list of items.
   * If not provided, it defaults to a regular equality check (===).
   */
  comparer?: (option: T, selected: T) => boolean;
  /**
   * Custom trigger display label
   */
  getSelectionLabel?: (option: DropMenuItem<T>) => string;
  /**
   * Defaults to the label of the selected item
   * @param selected the label of the selected item
   */
  getTooltipContent?: (selected: string) => string;
  /**
   * Test ID for locating dropmenu in unit and E2E tests
   */
  'data-testid'?: string;
}

export interface MenuCategory<TValue = string, TItem = DropMenuItem<TValue>> {
  name: string;
  value?: TValue;
  disabled?: boolean;
  disabledMessage?: string;
  items: TItem[];
}
