import { compact, isNil } from 'lodash';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Flexbox, GetColor } from 'venn-ui-kit';
import { PortfoliosContext } from '../../../contexts';
import { UniversalUploaderFooter } from '../components/page-parts/UniversalUploaderFooter';
import {
  IChooseActionPortfolioView,
  ICreateMasterPortfolioView,
  ICreatePrivatePortfolioView,
  IUploadPrivatesView,
  IUploadReturnsView,
} from '../constants';
import { type PortfolioMultiUploaderView } from '../types';
import { getSupportingImageSrc } from '../utils';
import { MainViewContainer, RightContainer, SectionContainer } from './shared/layout';
import { OptionTile } from './shared/OptionTile';
import { Routes } from 'venn-utils';

type ChooseActionSection = {
  title: string;
  options: PortfolioMultiUploaderView[];
};

const useSections = () => {
  const { masterPortfolio } = useContext(PortfoliosContext);

  const sections: ChooseActionSection[] = [
    {
      title: 'Investments',
      options: compact([IUploadReturnsView, IUploadPrivatesView]),
    },
    {
      title: 'Portfolios',
      options: compact([
        isNil(masterPortfolio) ? ICreateMasterPortfolioView : IChooseActionPortfolioView,
        ICreatePrivatePortfolioView,
      ]),
    },
  ];

  return sections;
};

type ChooseActionViewProps = {
  setView: (view: PortfolioMultiUploaderView) => void;
};

export const ChooseActionView = ({ setView }: ChooseActionViewProps) => {
  const sections = useSections();
  const [viewTileHovered, setViewTileHovered] = React.useState<PortfolioMultiUploaderView | undefined>(undefined);
  const imgSrc = getSupportingImageSrc(viewTileHovered);
  const history = useHistory();

  return (
    <ViewAndFooterContainer direction="column">
      <MainViewContainer>
        <LeftContainer className="choose-action-view-container">
          {sections.map((section) => (
            <SectionContainer key={section.title}>
              {section.title}
              {section.options.map((option) => {
                return (
                  <OptionTile
                    setViewTileHovered={setViewTileHovered}
                    onClick={() => {
                      if (option.id === 'CREATE_MASTER_PORTFOLIO') {
                        history.push(Routes.CREATE_PORTFOLIO);
                      } else {
                        setView(option);
                      }
                    }}
                    option={option}
                    key={option.id}
                  />
                );
              })}
            </SectionContainer>
          ))}
        </LeftContainer>
        <RightContainer className="choose-action-view-container">
          <Container>{!!imgSrc && <StyledImage alt="hover" src={getSupportingImageSrc(viewTileHovered)} />}</Container>
        </RightContainer>
      </MainViewContainer>
      <UniversalUploaderFooter noMargin />
    </ViewAndFooterContainer>
  );
};

const Container = styled.div`
  background-color: ${GetColor.GreyScale.Grey10};
  display: flex;
  align-self: stretch;
  flex: 1;
`;

const ViewAndFooterContainer = styled(Flexbox)`
  align-self: stretch;
  flex: 1;
  overflow: hidden;
`;

const StyledImage = styled.img`
  width: 100%;
  height: auto;
  align-self: flex-start;
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 24px 0 0 24px;
  max-width: 511px;
`;
