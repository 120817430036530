import type { CustomFactorForecast, ComputedInvestmentResidual, AppUser } from 'venn-api';

export const mockForecastWithInvestmentAssumptions: CustomFactorForecast = {
  created: 0,
  default: false,
  forecastContext: 'HISTORICAL_FORECASTS',
  systemContext: undefined!,
  updatedBy: {
    active: true,
    created: 0,
    avatarId: '',
    displayName: 'Mr Test McTesty',
    email: 'test@test.com',
    actualUser: false,
    id: 1,
    name: 'Test McTesty',
    pwdLastUpdated: 0,
    accountId: 2,
  },
  forecastId: '002',
  investmentForecasts: [
    {
      fundId: '00-001',
      returnVal: 0.2,
      cashForecast: false,
    },
  ],
  name: 'My Forecast',
  cashForecast: 0,
  cashForecastContext: 'HISTORICAL_FORECASTS',
  cashComputedByCustomForecast: false,
  cashOverrideRange: {
    maxOverride: 3.0,
    minOverride: 3.0,
    minOverrideZScore: 1,
    maxOverrideZScore: 1,
    std: 3.0,
    mean: 0,
  },
  startDate: undefined!,
  endDate: undefined!,
  regressionStart: undefined!,
  regressionEnd: undefined!,
  regressionPeriod: undefined!,
  factorForecasts: [
    {
      annualizedReturn: 0.007482551773433599,
      annualizedSharpe: 0.007482551773433599,
      annualizedVolatility: 0.08289318019034284,
      forecastContext: 'HISTORICAL_FORECASTS',
      id: 483,
      name: 'Local Equity',
      returnEnd: 1559260799999,
      returnStart: 1010447999999,
      computedByCustomForecast: false,
      factorOverrideRange: {
        maxOverride: 0.007482551773433599 + 1.5,
        minOverride: 0.007482551773433599 - 1.5,
        minOverrideZScore: 1.5,
        maxOverrideZScore: 1.5,
        std: 1.0,
        mean: 0.007482551773433599,
      },
    },
    {
      annualizedReturn: 0.13424138256563012,
      annualizedSharpe: 0.007482551773433599,
      annualizedVolatility: 0.09340560948670923,
      forecastContext: undefined!,
      id: 394,
      name: 'Low Risk',
      returnEnd: 1559260799999,
      returnStart: 1010447999999,
      computedByCustomForecast: true,
      factorOverrideRange: {
        maxOverride: 0.13424138256563012 + 2.25,
        minOverride: 0.13424138256563012 - 2.25,
        minOverrideZScore: 2.25,
        maxOverrideZScore: 2.25,
        std: 1.5,
        mean: 0.13424138256563012,
      },
    },
    {
      annualizedReturn: 0.09291793918097557,
      annualizedSharpe: 0.007482551773433599,
      annualizedVolatility: 0.1144756408135883,
      forecastContext: 'HISTORICAL_FORECASTS',
      id: 395,
      name: 'Momentum',
      returnEnd: 1559260799999,
      returnStart: 1010447999999,
      computedByCustomForecast: false,
      factorOverrideRange: {
        maxOverride: 0.09291793918097557 + 3.0,
        minOverride: 0.09291793918097557 - 3.0,
        minOverrideZScore: 3.0,
        maxOverrideZScore: 3.0,
        std: 2.0,
        mean: 0.09291793918097557,
      },
    },
  ],
};

export const mockResidualOverridesList: ComputedInvestmentResidual[] = [
  {
    fundId: 'e5afc479-fca5-4d3c-b002-2de0183894ca',
    fundName: 'KURIMOTO',
    permissionGroupId: 2,
    workspaceId: 1,
    workspaceName: 'Two SIgma Dev',
    userId: 3123530,
    userName: 'Kacper Walentynowicz',
    created: 1665732363523,
    forecastResidual: 0.068,
    defaultResidual: 0.04953,
    defaultReturn: 0.86,
    overriddenReturn: 0.88,
    computedResidual: 0.068,
    computedReturn: 0.88,
    isManualOverride: true,
  },
  {
    fundId: 'd331ddcd-d7e2-4367-8b3d-8b9089fedaf2',
    fundName: 'TESLA INC',
    permissionGroupId: 2,
    workspaceId: 1,
    workspaceName: 'Two SIgma Dev',
    userId: 3123530,
    userName: 'Kacper Walentynowicz',
    created: 1665731990154,
    forecastResidual: 1.25,
    defaultResidual: 1.17,
    defaultReturn: 1.86,
    overriddenResidual: 1.25,
    computedResidual: 1.25,
    computedReturn: 1.94,
    isManualOverride: true,
  },
];

export const mockResidual: ComputedInvestmentResidual = {
  fundId: '6fad39ef-bd75-4046-8fb0-d207bf406b3d',
  fundName: 'CCL INDUSTRIES INC',
  permissionGroupId: 2,
  workspaceId: 1,
  workspaceName: 'Two SIgma Dev',
  userId: 3123530,
  userName: 'Kacper Walentynowicz',
  created: 1665731992137,
  forecastResidual: 0.1,
  defaultResidual: 0.2,
  defaultReturn: 0.26,
  overriddenResidual: 0.1,
  computedResidual: 0.1,
  computedReturn: 0.16,
  isManualOverride: true,
};

export const mockSimpleHistoricalForecastList: CustomFactorForecast[] = [
  {
    created: 0,
    default: true,
    factorForecasts: [],
    forecastContext: 'HISTORICAL_FORECASTS',
    forecastId: '001',
    investmentForecasts: [],
    name: 'Historical Return Forecasts',
    cashOverrideRange: {
      maxOverride: 1 + 3.0,
      minOverride: 1 - 3.0,
      minOverrideZScore: 3.0,
      maxOverrideZScore: 3.0,
      std: 1.5,
      mean: 1,
    },
    cashComputedByCustomForecast: false,
    cashForecast: 1,
    endDate: 1,
    startDate: 0,
    cashForecastContext: 'HISTORICAL_FORECASTS',
    regressionStart: 0,
    regressionEnd: 1,
    regressionPeriod: 'YEAR_3',
    cashForecastOverride: undefined,
    updatedBy: {} as AppUser,
  },
];

export const mockForecastsList: CustomFactorForecast[] = [
  {
    created: 0,
    default: false,
    factorForecasts: [],
    forecastContext: 'HISTORICAL_FORECASTS',
    forecastId: '001',
    investmentForecasts: [],
    name: 'name_Historical_Return_Forecasts',
    cashOverrideRange: {
      maxOverride: 1 + 3.0,
      minOverride: 1 - 3.0,
      minOverrideZScore: 3.0,
      maxOverrideZScore: 3.0,
      std: 1.5,
      mean: 1,
    },
    cashComputedByCustomForecast: false,
    cashForecast: 1,
    endDate: 1,
    startDate: 0,
    cashForecastContext: 'HISTORICAL_FORECASTS',
    regressionStart: 0,
    regressionEnd: 1,
    regressionPeriod: 'YEAR_3',
    cashForecastOverride: undefined,
    updatedBy: {} as AppUser,
  },
  {
    created: 0,
    default: true,
    factorForecasts: [],
    forecastContext: 'HISTORICAL_FORECASTS',
    forecastId: '2',
    investmentForecasts: [
      {
        cashForecast: true,
        fundId: 'a',
        returnVal: 0,
      },
    ],
    name: 'name_CMA_forecast',
    cashOverrideRange: {
      maxOverride: 1 + 3.0,
      minOverride: 1 - 3.0,
      minOverrideZScore: 3.0,
      maxOverrideZScore: 3.0,
      std: 1.5,
      mean: 1,
    },
    cashComputedByCustomForecast: false,
    cashForecast: 1,
    endDate: 1,
    startDate: 0,
    cashForecastContext: 'HISTORICAL_FORECASTS',
    regressionStart: 0,
    regressionEnd: 1,
    regressionPeriod: 'YEAR_3',
    cashForecastOverride: undefined,
    updatedBy: {} as AppUser,
  },
];

export const mockForecastsListWithDifferentDefaultForecast: CustomFactorForecast[] = [
  {
    created: 0,
    default: true,
    factorForecasts: [],
    forecastContext: 'HISTORICAL_FORECASTS',
    forecastId: '001',
    investmentForecasts: [],
    name: 'name_Historical_Return_Forecasts',
    cashOverrideRange: {
      maxOverride: 1 + 3.0,
      minOverride: 1 - 3.0,
      minOverrideZScore: 3.0,
      maxOverrideZScore: 3.0,
      std: 1.5,
      mean: 1,
    },
    cashComputedByCustomForecast: false,
    cashForecast: 1,
    endDate: 1,
    startDate: 0,
    cashForecastContext: 'HISTORICAL_FORECASTS',
    regressionStart: 0,
    regressionEnd: 1,
    regressionPeriod: 'YEAR_3',
    cashForecastOverride: undefined,
    updatedBy: {} as AppUser,
  },
  {
    created: 0,
    default: false,
    factorForecasts: [],
    forecastContext: 'HISTORICAL_FORECASTS',
    forecastId: '2',
    investmentForecasts: [
      {
        cashForecast: true,
        fundId: 'a',
        returnVal: 0,
      },
    ],
    name: 'name_CMA_forecast',
    cashOverrideRange: {
      maxOverride: 1 + 3.0,
      minOverride: 1 - 3.0,
      minOverrideZScore: 3.0,
      maxOverrideZScore: 3.0,
      std: 1.5,
      mean: 1,
    },
    cashComputedByCustomForecast: false,
    cashForecast: 1,
    endDate: 1,
    startDate: 0,
    cashForecastContext: 'HISTORICAL_FORECASTS',
    regressionStart: 0,
    regressionEnd: 1,
    regressionPeriod: 'YEAR_3',
    cashForecastOverride: undefined,
    updatedBy: {} as AppUser,
  },
];

export const mockForecastsListWithVennForecast: CustomFactorForecast[] = [
  {
    created: 0,
    default: true,
    factorForecasts: [],
    forecastContext: 'HISTORICAL_FORECASTS',
    systemContext: 'HISTORICAL_FORECASTS',
    forecastId: '001',
    investmentForecasts: [],
    name: 'Historical Return Forecasts',
    cashOverrideRange: {
      maxOverride: 1 + 3.0,
      minOverride: 1 - 3.0,
      minOverrideZScore: 3.0,
      maxOverrideZScore: 3.0,
      std: 1.5,
      mean: 1,
    },
    cashComputedByCustomForecast: false,
    cashForecast: 1,
    endDate: 1,
    startDate: 0,
    cashForecastContext: 'HISTORICAL_FORECASTS',
    regressionStart: 0,
    regressionEnd: 1,
    regressionPeriod: 'YEAR_3',
    cashForecastOverride: undefined,
    updatedBy: {} as AppUser,
  },
  {
    created: 0,
    default: false,
    factorForecasts: [],
    forecastContext: 'HISTORICAL_FORECASTS',
    forecastId: '2',
    investmentForecasts: [
      {
        cashForecast: true,
        fundId: 'a',
        returnVal: 0,
      },
    ],
    name: 'CMA forecast',
    cashOverrideRange: {
      maxOverride: 1 + 3.0,
      minOverride: 1 - 3.0,
      minOverrideZScore: 3.0,
      maxOverrideZScore: 3.0,
      std: 1.5,
      mean: 1,
    },
    cashComputedByCustomForecast: false,
    cashForecast: 1,
    endDate: 1,
    startDate: 0,
    cashForecastContext: 'HISTORICAL_FORECASTS',
    regressionStart: 0,
    regressionEnd: 1,
    regressionPeriod: 'YEAR_3',
    cashForecastOverride: undefined,
    updatedBy: {} as AppUser,
  },
];

export const mockForecastsListWithPeriodSet: CustomFactorForecast[] = [
  {
    created: 0,
    default: true,
    factorForecasts: [],
    forecastContext: 'HISTORICAL_FORECASTS',
    forecastId: '001',
    investmentForecasts: [],
    name: 'Historical Return Forecasts',
    regressionPeriod: 'YEAR_3',
    cashOverrideRange: {
      maxOverride: 1 + 3.0,
      minOverride: 1 - 3.0,
      minOverrideZScore: 3.0,
      maxOverrideZScore: 3.0,
      std: 1.5,
      mean: 1,
    },
    cashComputedByCustomForecast: false,
    cashForecast: 1,
    endDate: 1,
    startDate: 0,
    cashForecastContext: 'HISTORICAL_FORECASTS',
    regressionStart: 0,
    regressionEnd: 1,
    cashForecastOverride: undefined,
    updatedBy: {} as AppUser,
  },
];
