import React from 'react';
import styled from 'styled-components';
import { Icon, Tooltip, TooltipPosition } from 'venn-ui-kit';
import { focusColor } from './styles';

interface InfoIconProps {
  text?: string;
}

const InfoIcon = ({ text }: InfoIconProps) => {
  if (!text) {
    return null;
  }
  return (
    <Container>
      <Tooltip content={<TooltipMessageWrapper>{text}</TooltipMessageWrapper>} position={TooltipPosition.Left}>
        <StyledIcon type="info-circle" prefix="fas" />
      </Tooltip>
    </Container>
  );
};

export default InfoIcon;

const TooltipMessageWrapper = styled.div`
  padding: 10px;
`;

const Container = styled.div`
  position: absolute;
  right: -18px;
  top: 15px;
`;

const StyledIcon = styled(Icon)`
  color: ${focusColor};
`;
