import React, { useCallback } from 'react';
import { Label } from '../../typography';
import { Icon } from '../../icon/Icon';
import type { TriggerComponentProps } from '../types';
import TriggerContainer from './TriggerContainer';
import { JumboTriggerInput, JumboSelection } from './JumboTrigger';

const JumboCheckboxTrigger: React.FC<React.PropsWithChildren<TriggerComponentProps>> = ({
  label,
  expanded,
  disabled,
  className,
  innerRef,
  onClick,
  onKeyDown,
  onKeyUp,
  onKeyEnter,
  onKeyEsc,
}) => {
  const handleClick = useCallback(() => {
    if (!disabled) {
      onClick?.();
    }
  }, [disabled, onClick]);

  return (
    <TriggerContainer
      className={className}
      disabled={disabled}
      expanded={expanded}
      innerRef={innerRef}
      onClick={handleClick}
      onKeyDown={onKeyDown}
      onKeyEnter={onKeyEnter}
      onKeyUp={onKeyUp}
      onKeyEsc={onKeyEsc}
    >
      <JumboTriggerInput disabled={disabled}>
        <JumboSelection>
          <Label className="qa-jumbo-checkbox-trigger">{label}</Label>
        </JumboSelection>
        <Icon prefix="far" type={expanded ? 'chevron-up' : 'chevron-down'} />
      </JumboTriggerInput>
    </TriggerContainer>
  );
};

export default JumboCheckboxTrigger;
