import type { BenchmarkAction } from './benchmarkActions';
import { BenchmarkUIView } from './benchmarkActions';
import type { BenchmarksCompositeState } from './compositeState';
import { DEFAULT_COMPOSITE_CHILD } from './compositeState';

export default function reduceComposite(
  composite: BenchmarksCompositeState,
  action: BenchmarkAction,
): BenchmarksCompositeState {
  switch (action.type) {
    case 'BenchmarkAppendNewCompositeChild':
      return {
        ...composite,
        children: [...composite.children, { ...DEFAULT_COMPOSITE_CHILD }],
      };
    case 'BenchmarkEditCompositeChild':
      return {
        ...composite,
        children: composite.children.map((child) =>
          child === action.payload.previous ? action.payload.updated : child,
        ),
      };
    case 'BenchmarkRemoveCompositeChild':
      return {
        ...composite,
        children: composite.children.filter((child) => child !== action.payload),
      };
    case 'BenchmarkRenameComposite':
      return {
        ...composite,
        name: action.payload,
      };
    case 'BenchmarkAdd':
    case 'BenchmarkUpdate':
      return {
        name: '',
        children: [DEFAULT_COMPOSITE_CHILD],
      };
    case 'BenchmarkLoadCompositeSuccess':
      return action.payload;
    case 'BenchmarkUINavigation':
      if (action.payload.target.kind === 'View' && action.payload.target.viewId === BenchmarkUIView.ListView) {
        return {
          name: '',
          children: [DEFAULT_COMPOSITE_CHILD],
        };
      }
      return composite;
    default:
      return composite;
  }
}
