import React from 'react';
import styled from 'styled-components';
import { Headline3, Hint, GetColor, EmptyStateIcon } from 'venn-ui-kit';

export const LinkButton = styled.button`
  color: ${GetColor.Primary.Dark};
`;

interface EmptyStateProps {
  onClear: () => void;
  canClearQuery: boolean;
  canClearFilters: boolean;
}

const getActionText = (canClearQuery: boolean, canClearFilters: boolean) => {
  const baseText = 'Reset all filters';
  if (canClearQuery && canClearFilters) {
    return `${baseText} and clear query`;
  }

  if (canClearQuery) {
    return 'Clear query';
  }

  return baseText;
};

const EmptyState = ({ onClear, canClearQuery, canClearFilters }: EmptyStateProps) => (
  <Wrapper>
    <EmptyStateIcon />
    <Headline3>No items found</Headline3>
    <Hint data-testid="qa-reporting-empty-state">
      <LinkButton className="qa-creporting-lear-filters" data-testid="qa-reporting-clear-filters" onClick={onClear}>
        {getActionText(canClearQuery, canClearFilters)}
      </LinkButton>{' '}
      to search all your saved views
    </Hint>
  </Wrapper>
);

export default EmptyState;

const Wrapper = styled.div`
  text-align: center;
  margin-top: 60px;
  font-weight: bold;
  h3 {
    text-align: center;
  }
`;
