const mockedResponse = {
  maxStartTime: 796694399999,
  maxEndTime: 1583020799999,
  maxFrequency: 'MONTHLY',
  analyses: [
    {
      analysisType: 'CORRELATION',
      correlationMatrix: {
        start: 786239999999,
        end: 1583020799999,
        correlations: [
          {
            entity: {
              id: '0115968d-1e0a-49e8-bd6e-5e3a2c684b49',
              name: 'Managed Futures Investment Category (MF)',
            },
            correlations: [
              {
                entity: {
                  id: '0115968d-1e0a-49e8-bd6e-5e3a2c684b49',
                  name: 'Managed Futures Investment Category (MF)',
                },
                correlation: 1,
              },
              {
                entity: {
                  id: '090891d4-b30b-4e50-9331-d5a52ae27ae8',
                  name: 'Other Investment Category (HF)',
                },
                correlation: 0.313303563374169,
              },
              {
                entity: {
                  id: '090891d4-b30b-4e50-9331-d5a52ae27ae8',
                  name: 'Other Investment Category (MF)',
                },
                correlation: 0.313303563374169,
              },
            ],
          },
          {
            entity: {
              id: '090891d4-b30b-4e50-9331-d5a52ae27ae8',
              name: 'Other Investment Category (HF)',
            },
            correlations: [
              {
                entity: {
                  id: '0115968d-1e0a-49e8-bd6e-5e3a2c684b49',
                  name: 'Managed Futures Investment Category (MF)',
                },
                correlation: 0.31330356337416915,
              },
              {
                entity: {
                  id: '090891d4-b30b-4e50-9331-d5a52ae27ae8',
                  name: 'Other Investment Category (HF)',
                },
                correlation: 1,
              },
              {
                entity: {
                  id: '090891d4-b30b-4e50-9331-d5a52ae27ae8',
                  name: 'Other Investment Category (MF)',
                },
                correlation: 1,
              },
            ],
          },
          {
            entity: {
              id: '090891d4-b30b-4e50-9331-d5a52ae27ae8',
              name: 'Other Investment Category (MF)',
            },
            correlations: [
              {
                entity: {
                  id: '0115968d-1e0a-49e8-bd6e-5e3a2c684b49',
                  name: 'Managed Futures Investment Category (MF)',
                },
                correlation: 0.31330356337416915,
              },
              {
                entity: {
                  id: '090891d4-b30b-4e50-9331-d5a52ae27ae8',
                  name: 'Other Investment Category (HF)',
                },
                correlation: 1,
              },
              {
                entity: {
                  id: '090891d4-b30b-4e50-9331-d5a52ae27ae8',
                  name: 'Other Investment Category (MF)',
                },
                correlation: 1,
              },
            ],
          },
        ],
      },
    },
  ],
};
export default mockedResponse;
