import React from 'react';
import type { CellRenderer } from './types';
import { isCellDisabled } from './helpers';
import styled from 'styled-components';
import type { ColumnMapping, FileUploadMetadata } from 'venn-api';
import type { ErrorViewModel } from '../../views/review/helpers';
import type { VennColors } from 'venn-ui-kit';
import { GetColor } from 'venn-ui-kit';

export interface PrivateFundUploadMetadataCount {
  metadataRows: number;
  cashFlowRows: number;
  performanceRows: number;
}

interface PrivateFundUploadMetadataCountRendererProps {
  metadata: FileUploadMetadata;
  columnMapping: ColumnMapping;
  errors: ErrorViewModel[];
  colors: VennColors;
  privateObservationMetadata: { [fundId: string]: PrivateFundUploadMetadataCount };
}

// @ts-expect-error: TODO fix strictFunctionTypes
export const PrivateFundUploadMetadataCountRenderer: CellRenderer = ({
  metadata,
  columnMapping,
  errors,
  privateObservationMetadata,
}: PrivateFundUploadMetadataCountRendererProps) => {
  const { seriesId } = columnMapping;
  const isDisabled = isCellDisabled(columnMapping, metadata, errors, seriesId);
  const { metadataRows, cashFlowRows, performanceRows } = privateObservationMetadata[seriesId];
  const isNew = true;

  return (
    <PerformanceCell isDisabled={isDisabled}>
      {!!metadataRows && (
        <div>
          <StyledObservationCount isNew={isNew}>+{metadataRows}</StyledObservationCount>
          <LabelText>Metadata</LabelText>
        </div>
      )}
      {!!performanceRows && (
        <div>
          <StyledObservationCount isNew={isNew}>+{performanceRows}</StyledObservationCount>
          <LabelText>Performance</LabelText>
        </div>
      )}
      {!!cashFlowRows && (
        <div>
          <StyledObservationCount isNew={isNew}>+{cashFlowRows}</StyledObservationCount>
          <LabelText>Cash Flow Transaction</LabelText>
        </div>
      )}
    </PerformanceCell>
  );
};

const PerformanceCell = styled.div<{ isDisabled?: boolean }>`
  ${({ isDisabled }) =>
    isDisabled &&
    `
    opacity: 0.25;
  `} display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 1.25;
  > div {
    span:first-child {
      margin-right: 3px;
    }
  }
`;

const LabelText = styled.span`
  color: ${GetColor.MidGrey2};
  margin-right: 3px;
`;

const StyledObservationCount = styled.span<{ isNew: boolean }>`
  color: ${({ isNew }) => (isNew ? GetColor.HighlightDark : GetColor.Black)};
  font-weight: bold;
  font-size: 14px;
`;
