import { selector } from 'recoil';
import { listDocumentSummaries } from 'venn-api';
import { VennQueryClient } from 'venn-utils';

export interface FooterLink {
  titles: { language: string; text: string }[];
  baseUrl: string;
}

const LEGAL_DOCS_QUERY_KEY = ['legalDocumentSummaries'];

export const legalDocLinks = selector<FooterLink[]>({
  key: 'legalDocLinks',
  get: async () => {
    const queryClient = VennQueryClient.getInstance();
    await queryClient.invalidateQueries(LEGAL_DOCS_QUERY_KEY);
    return queryClient
      .fetchQuery(LEGAL_DOCS_QUERY_KEY, () => listDocumentSummaries())
      .then((response) =>
        response.content.map((documentSummary) => ({
          baseUrl: `/api/legal/doc/${documentSummary.identifier}`,
          titles: Object.keys(documentSummary.languageCodeToFriendlyName).map((key) => ({
            language: key,
            text: documentSummary.languageCodeToFriendlyName[key],
          })),
        })),
      );
  },
});
