import React, { useMemo } from 'react';
import type { DateRange } from 'venn-ui-kit';
import {
  PrintSubjectBar,
  PrintSubjectLeft,
  PrintSubjectRight,
  PrintSubjectLabel,
  PrintAnalysisTemplate,
} from 'venn-ui-kit';
import type { AnalysisSubject, ComparisonSubject } from 'venn-utils';
import { Dates } from 'venn-utils';
import { compact } from 'lodash';
import type { FrequencyEnum } from 'venn-api';

interface PrintHeaderProps {
  subjects: ComparisonSubject[];
  dateRange: DateRange;
  maxDateRange?: DateRange;
  maxFrequency: FrequencyEnum;
  benchmark?: AnalysisSubject;
  relative: boolean;
  currentViewName?: string;
}

const PrintHeader: React.FC<React.PropsWithChildren<PrintHeaderProps>> = ({
  subjects,
  dateRange,
  maxDateRange,
  maxFrequency,
  benchmark,
  relative,
  currentViewName,
}) => {
  const dateRangeText = useMemo(() => {
    const fromDate = dateRange?.from ?? maxDateRange?.from;
    const toDate = dateRange?.to ?? maxDateRange?.to;
    const fromStr = fromDate ? Dates.toDDMMMYYYY(fromDate, maxFrequency) : '--';
    const toStr = toDate ? Dates.toDDMMMYYYY(toDate, maxFrequency) : '--';
    return `${fromStr} - ${toStr}`;
  }, [dateRange, maxDateRange, maxFrequency]);
  const subjectNames = compact(
    subjects.filter((s) => !relative || s?.analysisSubject !== benchmark).map((s) => s.analysisSubject?.name),
  ).join(', ');
  return (
    <>
      {currentViewName && (
        <PrintSubjectBar>
          <PrintSubjectLeft>View Name: {currentViewName}</PrintSubjectLeft>
        </PrintSubjectBar>
      )}
      <PrintSubjectBar>
        <PrintSubjectLeft>{subjectNames}</PrintSubjectLeft>
        <PrintSubjectRight>
          <PrintSubjectLabel>Analysis Period:</PrintSubjectLabel> {dateRangeText}
          {benchmark && relative && (
            <>
              <PrintSubjectLabel>Benchmark:</PrintSubjectLabel>
              {benchmark.name}
            </>
          )}
        </PrintSubjectRight>
      </PrintSubjectBar>
      <PrintAnalysisTemplate>Compare</PrintAnalysisTemplate>
    </>
  );
};

export default PrintHeader;
