import type { InfoGraphicTypeEnum } from 'venn-api';
import type { CustomBlockTypeEnum } from 'venn-utils';
import {
  MAX_BOX_CHART_SUBJECTS,
  MAX_ROLLING_CORRELATION_SUBJECTS,
  MAX_SUBJECT_SIZE,
  MAX_SUBJECTS_SIZE_INCREASED,
  SUPPORT_30_OBJECTS,
} from './constants';

export const getMaxSubjects = (
  blockType: CustomBlockTypeEnum,
  infoGraphicType: InfoGraphicTypeEnum,
  selectedMetrics: string[],
): number => {
  if (
    blockType === 'PUBLIC_PRIVATE_ASSET_GROWTH_PERCENTILES' ||
    blockType === 'PUBLIC_PRIVATE_ASSET_GROWTH_BREAKDOWN'
  ) {
    return 2;
  }
  // resolving VENN-20565, infographic type for calendar returns is GRID which is true but too generic
  if (blockType === 'RETURNS_GRID') {
    return 1;
  }
  if (blockType === 'PRIVATE_CAPITAL_SUMMARY') {
    return 1;
  }
  if (blockType === 'PEER_GROUPS') {
    return MAX_SUBJECT_SIZE;
  }
  if (blockType === 'PORTFOLIO_COMPARISON') {
    return 2;
  }
  if (blockType === 'PRIVATE_CASH_FLOW') {
    return infoGraphicType === 'PRIVATE_CASH_FLOW' ? 1 : MAX_SUBJECT_SIZE;
  }
  if (blockType === 'PRIVATE_PERFORMANCE_TIME_SERIES') {
    return infoGraphicType === 'BAR' ? 4 : MAX_SUBJECT_SIZE;
  }
  if (blockType === 'SECTOR_EXPOSURE' && infoGraphicType === 'BAR') {
    return 1;
  }
  switch (infoGraphicType) {
    case 'CORRELATION':
      if (
        selectedMetrics.includes('portfolioCorrelation') ||
        selectedMetrics.includes('residualPortfolioCorrelation')
      ) {
        return 1;
      }
      break;
    case 'DIVERGENT_BAR':
    case 'HEATMAP':
    case 'GROWTH_SIMULATION':
    case 'PIE':
    case 'CALENDAR_GRID':
    case 'MAP':
      return 1;
    case 'BOX_CHART':
      return MAX_BOX_CHART_SUBJECTS;
    case 'LINE':
      if (selectedMetrics.includes('rollingCorrelation')) {
        return MAX_ROLLING_CORRELATION_SUBJECTS;
      }
      break;
  }
  return SUPPORT_30_OBJECTS.includes(blockType) ? MAX_SUBJECTS_SIZE_INCREASED : MAX_SUBJECT_SIZE;
};

export const getBlockAllowsSecondaryAboveSubjectLimit = (infoGraphicType: InfoGraphicTypeEnum | undefined): boolean =>
  infoGraphicType === 'HEATMAP' || infoGraphicType === 'DIVERGENT_BAR';
