import React from 'react';
import styled from 'styled-components';
import { times } from 'lodash';
import { Container, Row, Col } from '../grid/Grid';
import { Shimmer } from '../../shimmer/shimmer';

interface TablePlaceholderProps {
  rows?: number;
  columns?: number;
}

/**
 * A shimmering table placeholder.
 */
const TablePlaceholder: React.FC<React.PropsWithChildren<TablePlaceholderProps>> = ({ rows = 5, columns = 3 }) => {
  return (
    <Container data-testid="table-placeholder" fluid>
      {times(rows, (r) => (
        <Row key={r}>
          {times(columns, (c) => (
            <StyledCol duration={3} key={c} />
          ))}
        </Row>
      ))}
    </Container>
  );
};

const StyledCol = styled(Col)`
  margin: 8px;
  height: 20px;
  ${Shimmer}
`;
export default TablePlaceholder;
