import { useState, useEffect, useCallback } from 'react';
import { analyticsService, logExceptionIntoSentry, logMessageToSentry, useApi } from 'venn-utils';
import type { FundMetadata, CategoryAssetTypeOption, CategoryGroupOption, FundMetadataOptions } from 'venn-api';
import { cachedGetCategoriesOptions, getFundMetadata, updateFundMetadata } from 'venn-api';
import { isEqual, keys, pickBy } from 'lodash';

/** hack to force the null vs undefined distinction for categoryGroup */
export interface UnsavedMetadata extends Partial<Omit<FundMetadata, 'categoryGroup'>> {
  // null to indicate no category group, undefined to indicate no changes to category group
  categoryGroup?: CategoryGroupOption | null;
}

export interface UseMetaDataReturn extends FundMetadataOptions {
  metaData?: FundMetadata;
  metaDataError: boolean;
  metaDataLoading: boolean;
  /** The function to call to update the fund's metadata with the given metadata. */
  updateMetaData: (updatedMetaData: UnsavedMetadata) => Promise<void>;
  unsavedMetadata: UnsavedMetadata;
  updateUnsavedMetadata: (updatedFields: UnsavedMetadata) => void;
  saveUnsavedMetadata: (onSuccess?: () => void) => void;
  refreshMetadata: () => Promise<void>;
}

export default (fundId?: string): UseMetaDataReturn => {
  const [categoryOptions, setCategoryOptions] = useState({});
  const [currencyOptions, setCurrencyOptions] = useState({});
  const [error, setError] = useState(false);
  const [categoryAssetTypeOptions, setCategoryAssetTypeOptions] = useState<CategoryAssetTypeOption[]>([]);
  const [categoryGroupOptions, setCategoryGroupOptions] = useState<CategoryGroupOption[]>([]);
  const abortableCachedGetCategoriesOptions = useApi(cachedGetCategoriesOptions);
  const abortableGetFundMetadata = useApi(getFundMetadata);
  const abortableUpdateFundMetadata = useApi(updateFundMetadata);
  const [metaDataLoading, setMetaDataLoading] = useState<boolean>(false);

  const [unsavedMetadata, setUnsavedMetadata] = useState<UnsavedMetadata>({});
  const [metaData, setMetaData] = useState<FundMetadata | undefined>();

  const fetchMetaData = useCallback(async () => {
    if (fundId) {
      setMetaDataLoading(true);
      try {
        const metaDataContent = await abortableGetFundMetadata(fundId);
        if (metaDataContent.content) {
          setMetaData(metaDataContent.content);
        }
        setMetaDataLoading(false);
      } catch (e) {
        logExceptionIntoSentry(e);
      }
    }
  }, [abortableGetFundMetadata, fundId]);

  const refreshMetadata = useCallback(async () => {
    await Promise.all([fetchMetaData()]);
  }, [fetchMetaData]);

  useEffect(() => {
    // Fetch categories and metadata on load.
    const fetchCategories = async () => {
      try {
        const categoryContent = await abortableCachedGetCategoriesOptions();
        if (categoryContent && categoryContent.content) {
          setCategoryOptions(categoryContent.content.categoryOptions);
          setCurrencyOptions(categoryContent.content.currencyOptions);
          setCategoryAssetTypeOptions(categoryContent.content.categoryAssetTypes);
          setCategoryGroupOptions(categoryContent.content.categoryGroups);
        }
      } catch (e) {
        logExceptionIntoSentry(e);
      }
    };
    fetchCategories();
    fetchMetaData();
  }, [abortableCachedGetCategoriesOptions, abortableGetFundMetadata, fetchMetaData, fundId]);

  const updateMetaData = useCallback(
    async (updatedMetaData: Partial<FundMetadata>, onSuccess?: () => void) => {
      if (!fundId) {
        return;
      }

      const fieldsModified = keys(pickBy(updatedMetaData, (value, key) => !isEqual(metaData?.[key], value)));
      try {
        setError(false);
        const response = await abortableUpdateFundMetadata(fundId, updatedMetaData);
        if (response?.content) {
          setMetaData(response.content);
          setUnsavedMetadata((prev) => pickBy(prev, (value, key) => !isEqual(response.content[key], value)));
        }

        analyticsService.investmentMetadataSaved({
          fieldsModified,
          investmentId: fundId,
        });
        onSuccess?.();
      } catch (e) {
        const err = await e;
        if (err?.name !== 'AbortError') {
          logExceptionIntoSentry(e);
          setError(true);
        }
      }
    },
    [metaData, abortableUpdateFundMetadata, fundId],
  );

  const updateUnsavedMetadata = (updatedFields: Partial<FundMetadata>) => {
    setUnsavedMetadata((prev) => ({ ...prev, ...updatedFields }));
  };

  const saveUnsavedMetadata = useCallback(
    async (onSuccess?: () => void) => {
      if (!metaData) {
        logMessageToSentry('Failed to save because of missing meta data');
        return;
      }

      const newMetadata = {
        ...metaData,
        ...unsavedMetadata,
      };

      await updateMetaData(newMetadata as Partial<FundMetadata>, onSuccess);
    },
    [metaData, unsavedMetadata, updateMetaData],
  );

  return {
    metaDataLoading,
    metaData,
    metaDataError: error,
    refreshMetadata,
    // @ts-expect-error: TODO fix strictFunctionTypes
    updateMetaData,
    unsavedMetadata,
    // @ts-expect-error: TODO fix strictFunctionTypes
    updateUnsavedMetadata,
    saveUnsavedMetadata,
    categoryOptions,
    currencyOptions,
    categoryAssetTypes: categoryAssetTypeOptions,
    categoryGroups: categoryGroupOptions,
  };
};
