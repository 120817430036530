import {
  DEFAULT_ANALYSIS_FOR_INVESTMENT,
  DEFAULT_ANALYSIS_FOR_PORTFOLIO,
  type GeneralAnalysisTemplate,
  type UserProfileSettings,
  type UserSettings,
} from 'venn-api';
import type { AnalysisSubjectType } from '../analysis';
import { getTemplateById } from './templates';
import type { NonQueryViewSettings, UserContextValue } from 'venn-components';
import { compact, orderBy } from 'lodash';
import { getUserInfo } from '../user';
import userActivityService from '../user-activity/user-activity-service';

const analysisTypeToKey = {
  portfolio: DEFAULT_ANALYSIS_FOR_PORTFOLIO,
  investment: DEFAULT_ANALYSIS_FOR_INVESTMENT,
} as const;

export const getDefaultAnalysisType = (
  type: AnalysisSubjectType | undefined,
  userSettings: UserSettings,
  templates: GeneralAnalysisTemplate[],
  profileSettings?: UserProfileSettings,
): string => {
  const defaultValue = type ? userSettings?.user?.[analysisTypeToKey[type]] : undefined;
  const template = defaultValue ? getTemplateById(templates, defaultValue) : null;

  const { shouldShowTutorial } = getUserInfo(profileSettings, userSettings);

  if (template) {
    return template.id;
  }

  if (shouldShowTutorial) {
    return 'welcome';
  }

  return 'tearsheet';
};

export const setDefaultAnalysisType = async (
  type: AnalysisSubjectType,
  userContext: UserContextValue,
  template: GeneralAnalysisTemplate,
): Promise<void> => {
  // Don't set the "Welcome To Venn" template as default - it should be used as default only during the first analysis.
  if (template.id === 'welcome') {
    return;
  }
  const updatedUserSettings = {
    [analysisTypeToKey[type]]: template.id,
  } as const;
  await userContext.updateSettings(updatedUserSettings);
};

export const RECENT_ANALYSIS_STORAGE_KEY = 'venn-recent-analysis-location';
const EXPIRED_TIME = 1000 * 60 * 60 * 24; // 24 hours

interface StoredAnalysis {
  pathname: string;
  search?: string;
  state?: Partial<NonQueryViewSettings>;
}

interface StoredAnalysisWithExpiredTime {
  expiredTime: number;
  value: StoredAnalysis;
  subjectName: string;
  subjectId: string;
  createdTime: number;
}

export const storeAnalysis = (
  userId: number,
  orgId: number,
  subjectId: string,
  subjectName: string,
  url: string,
  state?: Partial<NonQueryViewSettings>,
) => {
  const [pathname, search] = url.split('?');
  const valueToStore = {
    pathname,
    search: search ? `?${search}` : undefined,
    state,
  };

  const createdTime = Date.now();

  const valueWithExpireDate: StoredAnalysisWithExpiredTime = {
    createdTime,
    expiredTime: createdTime + EXPIRED_TIME,
    value: valueToStore,
    subjectId,
    subjectName,
  };

  const allStoredValues = getAllStoredAnalyses();
  if (!allStoredValues[userId]) {
    allStoredValues[userId] = {};
  }

  // Using "splice" and "unshift" below, which modify the "const" object
  let updatedValues = orderBy(allStoredValues[userId][orgId], ['expiredTime'], ['desc']);

  // Remove previous same subject's record
  updatedValues = updatedValues.filter((value) => value.subjectId !== subjectId);
  updatedValues.unshift(valueWithExpireDate);
  if (updatedValues.length > 10) {
    updatedValues = updatedValues.slice(0, 10);
  }
  allStoredValues[userId][orgId] = updatedValues;

  storeAllAnalyses(allStoredValues);
};

export interface RecentAnalysisRedirect {
  url: string;
  state?: Partial<NonQueryViewSettings>;
  subjectName: string;
  createdTime: number;
}

export const getMostRecentAnalysis = (userId: number, orgId: number): RecentAnalysisRedirect | null => {
  const allStoredValues = getAllStoredAnalyses();
  const valuesForUser = allStoredValues[userId];
  if (!valuesForUser) {
    return null;
  }

  const listForOrg = orderBy(valuesForUser[orgId] ?? [], ['expiredTime'], ['desc']);

  if (listForOrg.length === 0) {
    return null;
  }

  for (let i = 0; i < listForOrg.length; i++) {
    const expiringLast = listForOrg[i];

    const { pathname, search, state } = expiringLast.value;
    if (pathname) {
      return {
        url: `${pathname}${search ?? ''}`,
        state,
        subjectName: expiringLast.subjectName,
        createdTime: expiringLast.createdTime,
      };
    }
  }

  return null;
};

export const getAllRecentAnalysesForUser = (userId: number, orgId: number): RecentAnalysisRedirect[] => {
  const allStoredValues = getAllStoredAnalyses();
  return (allStoredValues[userId]?.[orgId] ?? []).map(
    ({ value: { pathname, search, state }, subjectName, createdTime }) => ({
      url: `${pathname}${search ?? ''}`,
      state,
      subjectName,
      createdTime,
    }),
  );
};

export const storeAllRecentAnalysesForUser = (userId: number, orgId: number, views: RecentAnalysisRedirect[]) => {
  const allStoredValues = getAllStoredAnalyses();
  if (allStoredValues[userId] === undefined) {
    allStoredValues[userId] = {};
  }
  allStoredValues[userId][orgId] = compact(
    views.map((view) => {
      const [pathname, search] = view.url.split('?');
      const urlParts = pathname.split('/');
      const investmentTypeIdx = urlParts.lastIndexOf('investment');
      const subjectIdIdx = (investmentTypeIdx !== -1 ? investmentTypeIdx : urlParts.lastIndexOf('portfolio')) + 1;
      if (subjectIdIdx < 0 || subjectIdIdx >= urlParts.length) {
        // This should never happen.
        return null;
      }
      return {
        expiredTime: view.createdTime + EXPIRED_TIME,
        value: {
          pathname,
          search: search ? `?${search}` : undefined,
          state: view.state,
        },
        subjectName: view.subjectName,
        subjectId: urlParts[subjectIdIdx],
        createdTime: view.createdTime,
      };
    }),
  );
  storeAllAnalyses(allStoredValues);
};

type UserToOrgToRecentAnalysis = { [userIdKey: number]: { [orgIdKey: number]: StoredAnalysisWithExpiredTime[] } };

const getAllStoredAnalyses = (): UserToOrgToRecentAnalysis => {
  const storedJSON = userActivityService.getLocalStorageItemPerUser(RECENT_ANALYSIS_STORAGE_KEY);
  if (!storedJSON) {
    return {};
  }

  try {
    const parsedContent = JSON.parse(storedJSON) as { [key: string]: StoredAnalysisWithExpiredTime };
    if (!parsedContent) {
      return {};
    }

    const result = {};
    Object.keys(parsedContent).forEach((key: string) => {
      const [userIdString, orgIdString] = key.split('|');
      const userId = Number.parseInt(userIdString, 10);
      const orgId = Number.parseInt(orgIdString, 10);
      if (!userId && !orgId) {
        return;
      }

      try {
        const parsedValue = parsedContent[key];
        if (!parsedValue) {
          return;
        }

        const parsedValuesArray = compact(
          (Array.isArray(parsedValue) ? parsedValue : [parsedValue]).filter((item) => {
            if (!item.expiredTime || item.expiredTime < Date.now()) {
              return null;
            }
            if (!item.createdTime || !item.subjectId || !item.subjectName) {
              return null;
            }
            const value = item.value;
            if (!value.pathname || typeof value.pathname !== 'string') {
              return null;
            }
            return item;
          }),
        );

        if (result[userId] === undefined) {
          result[userId] = {};
        }

        result[userId][orgId] = parsedValuesArray;
      } catch (e) {
        /**/
      }
    });
    return result;
  } catch (e) {
    return {};
  }
};

const storeAllAnalyses = (toStore: UserToOrgToRecentAnalysis) => {
  const storeObject = {};

  Object.keys(toStore).forEach((userId) => {
    Object.keys(toStore[userId]).forEach((orgId) => {
      const key = `${userId}|${orgId}`;
      storeObject[key] = toStore[userId][orgId];
    });
  });

  userActivityService.setLocalStorageItemPerUser(RECENT_ANALYSIS_STORAGE_KEY, JSON.stringify(storeObject));
};
