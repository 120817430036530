import React, { useState } from 'react';
import styled from 'styled-components';
import { AuthParagraph, AuthTitle, ContentWrapper } from './AuthComponents';
import { Loading, LoadingSize, GetColor, getAppTitle, getTextThemeProvider } from 'venn-ui-kit';
import type { SignupRequest, UserStatusEnum } from 'venn-api';
import { attemptUserSignupV3 } from 'venn-api';
import AuthNotification from './AuthNotification';
import AuthLogo from './AuthLogo';

interface PendingEmailConfirmationProps {
  signupValues: Partial<SignupRequest>;
  status: UserStatusEnum | 'WAITLISTED';
  countryLabel?: string;
  stateLabel?: string;
}

const StyledWrapper = styled(ContentWrapper)`
  height: 100%;
`;

const AuthLink = styled.span`
  color: ${GetColor.Primary.Dark};
  font-size: 14px;

  &:hover {
    cursor: pointer;
    color: ${GetColor.White};
  }
`;

const Anchor = styled.a`
  color: ${GetColor.Primary.Dark};
  font-size: 14px;

  &:hover {
    color: ${GetColor.White};
  }
`;

const AuthSubParagraph = styled(AuthParagraph)`
  font-size: 14px;
  margin-top: 0;
`;

const StyledAuthParagraph = styled(AuthParagraph)`
  margin-bottom: 5px;
`;

const PendingEmailConfirmation = ({
  status,
  signupValues,
  countryLabel = 'your country',
  stateLabel,
}: PendingEmailConfirmationProps) => {
  const [emailResent, setEmailResent] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values: Partial<SignupRequest>) => {
    setLoading(true);
    const response = await attemptUserSignupV3(values);
    setLoading(false);
    return response;
  };

  const handleEmailResent = () => {
    setEmailResent(false);
  };

  const resendEmail = async () => {
    setEmailResent(false);
    setLoading(true);
    const response = await onSubmit(signupValues);
    if (response.status === 200) {
      setEmailResent(false);
    }
    setLoading(false);
  };

  const showTitle = () => {
    switch (status) {
      case 'WAITLISTED':
        return "You're on the waitlist. ";
      case 'RESTRICTED':
        return 'Your account is pending authorization.';
      default:
        return `A verification email was sent to ${signupValues.email}`;
    }
  };

  const showCopy = () => {
    switch (status) {
      case 'WAITLISTED':
        return `You will receive an email at ${signupValues.email} if ${getAppTitle()} becomes available in ${
          stateLabel || countryLabel
        }`;
      case 'RESTRICTED':
        return `Thanks for your interest in ${getAppTitle()}, our team has been notified about your signup request. You will receive an email verifying that your request has been received.`;
      default:
        return "To keep your data secure, please check your email and use the link to verify your account. If you don't see an email, please check your junk mail folder.";
    }
  };

  const supportEmailLink = `mailto:${getTextThemeProvider().supportEmail}`;
  const showCTA = () => {
    switch (status) {
      case 'WAITLISTED':
        return (
          <>
            <StyledAuthParagraph>Didn't receive an email?</StyledAuthParagraph>
            <AuthSubParagraph>
              <Anchor href={supportEmailLink}>Contact Us</Anchor>
            </AuthSubParagraph>
          </>
        );
      default:
        return (
          <>
            <StyledAuthParagraph>Didn't receive an email?</StyledAuthParagraph>
            {loading && <Loading size={LoadingSize.micro} />}
            <AuthSubParagraph>
              <AuthLink onClick={resendEmail}>Resend Email</AuthLink>
              &nbsp;or&nbsp;
              <Anchor href={supportEmailLink}>Contact Us</Anchor>
            </AuthSubParagraph>
          </>
        );
    }
  };

  return (
    <>
      {emailResent && (
        <AuthNotification
          title="We've re-sent your verification email."
          description={`If you do not receive an email, contact ${getTextThemeProvider().supportEmail}.`}
          onClose={handleEmailResent}
          position="relative"
        />
      )}
      <StyledWrapper noFooter>
        <AuthLogo top={0} />
        <AuthTitle className="qa-auth-title">{showTitle()}</AuthTitle>
        <AuthParagraph>{showCopy()}</AuthParagraph>
        {showCTA()}
      </StyledWrapper>
    </>
  );
};

export default PendingEmailConfirmation;
