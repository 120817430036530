import React from 'react';
import { copyToClipboard } from 'venn-utils';
import styled from 'styled-components';
import { GetColor, Icon, Notifications, NotificationType } from 'venn-ui-kit';

export interface ClickToCopyLinkProps {
  text: string;
  successMessage?: string;
  errorMessage?: string;
  colorInverted?: boolean;
}

const ClickToCopyLink = ({ text, successMessage, errorMessage, colorInverted }: ClickToCopyLinkProps) => {
  return (
    <>
      <ClickToCopyLinkContainer
        inverted={colorInverted}
        onClick={async () => {
          const response = await copyToClipboard(text);
          if (response) {
            Notifications.notify(successMessage ?? 'Link copied', NotificationType.INFO);
          } else {
            Notifications.notify(errorMessage ?? 'Failed to copy', NotificationType.ERROR);
          }
        }}
      >
        {text}
        <ClickToCopyLinkIcon type="copy" />
      </ClickToCopyLinkContainer>
    </>
  );
};

export default ClickToCopyLink;

interface ColorProps {
  inverted?: boolean;
}

const ClickToCopyLinkContainer = styled.span<ColorProps>`
  font-size: 14px;
  line-height: 1.71;
  cursor: pointer;
  text-decoration: none;
  color: ${({ inverted }) => (inverted ? GetColor.Primary.Main : GetColor.Primary.Dark)};
  transition: color 0.15s linear;
`;
const ClickToCopyLinkIcon = styled(Icon)`
  font-size: 14px;
  line-height: 1.71;
  padding-left: 2px;
  cursor: pointer;
  color: ${GetColor.Primary.Main};
`;
