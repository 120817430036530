import type { ExcelCell } from 'venn-utils';
import { isString } from 'lodash';
import type { BasicTableColumn } from './BasicTable';

/**
 * Renders data into an array of Excel cells, ready for data export.
 * @param data Table data
 * @param columns Table column definitions
 */
function convertToExcel<TColumn extends BasicTableColumn<TRow>, TRow>(data: TRow[], columns: TColumn[]): ExcelCell[][] {
  const rows = data.map(
    (row, index) =>
      [
        ...columns
          // Use all columns as long as they have at least either an accessor or a cell renderer
          .filter(includeColumn)
          .map((colDef) => {
            // Allow excelCellRenderer to return what was generated by cellRenderer (to avoid duplicating logic)
            const cellContent = colDef.cellRenderer ? colDef.cellRenderer(row, index, data.length) : null;
            return colDef.excelCellRenderer
              ? colDef.excelCellRenderer(row, isString(cellContent) ? cellContent : null, index, data.length)
              : {
                  value: cellContent || (colDef.accessor ? row[colDef.accessor] : undefined),
                };
          }),
      ] as ExcelCell[],
  );

  return [
    columns.filter(includeColumn).map((col) =>
      col.excelHeaderRenderer
        ? col.excelHeaderRenderer(col.label ?? '')
        : {
            value: col.label ?? '',
            bold: true,
          },
    ),
    ...rows,
  ];
}

function includeColumn<TRow>(column: BasicTableColumn<TRow>) {
  return !!column.accessor || !!column.excelCellRenderer || !!column.cellRenderer;
}

export default convertToExcel;
