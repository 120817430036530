import React, { useContext, useEffect } from 'react';
import { Modal } from '../../modal';
import { analyticsService } from 'venn-utils';
import PortfoliosContext from '../../contexts/portfolios-context';
import type { CreatePortfolioModalContentWithoutRefreshProps } from './CreatePortfolioModalContent';
import CreatePortfolioModalContent from './CreatePortfolioModalContent';

const CreatePortfolioModal = (props: CreatePortfolioModalContentWithoutRefreshProps) => {
  const { refresh } = useContext(PortfoliosContext);
  useEffect(() => {
    analyticsService.modalDisplayed({
      topic: 'Create a new Portfolio',
    });
  }, []);

  return (
    <Modal onClose={props.onClose}>
      <CreatePortfolioModalContent {...props} refreshPortfolioList={refresh} />
    </Modal>
  );
};

export default CreatePortfolioModal;
