import type { PageAnalysisViewSearchResult } from 'venn-api';

export const mockAnalysisViewSearch = {
  pageNumber: 1,
  resultsPerPage: 50,
  totalResults: 5,
  results: [
    {
      id: '292f710c-7d33-46f7-8958-80a8bdfcbcb2',
      name: 'S&P NEW',
      subjectId: '24beb76f-b76f-43e8-aa3d-6781c33a5504',
      analysisSubjectType: 'INVESTMENT',
      subjectName: 'S&P 1000 Index',
      customTemplateId: null,
      systemTemplate: 'residual',
      numSnapshots: 0,
      created: 1612971337695,
      updated: 1612971337695,
      analysisViewType: 'ANALYSIS',
      owner: {
        accountId: 1,
        active: true,
        actualUser: false,
        avatarId: '',
        created: 99999999,
        displayName: 'tt',
        email: 't@t.t',
        id: -1,
        name: 'tt',
        pwdLastUpdated: null,
      },
      subjects: [
        {
          id: '24beb76f-b76f-43e8-aa3d-6781c33a5504',
          subjectType: 'INVESTMENT',
          comparisonType: 'PRIMARY',
          fund: {
            id: '24beb76f-b76f-43e8-aa3d-6781c33a5504',
            name: 'S&P 1000 Index',
            fundSourceType: { id: 1, name: 'Investment' },
            created: 1579907542670,
            updated: 1613040832308,
            createdById: -42,
            updatedById: -42,
            currency: 'USD',
            userUploaded: false,
            assetType: 'INDEX',
            remoteType: 'DATA',
            symbol: 'SP1000',
            active: true,
            live: true,
            owner: {
              id: -42,
              name: null,
              displayName: null,
              email: null,
              active: false,
              created: null,
              pwdLastUpdated: null,
              avatarId: null,
              actualUser: false,
              accountId: null,
            },
            updatedBy: {
              id: -42,
              name: null,
              displayName: null,
              email: null,
              active: false,
              created: null,
              pwdLastUpdated: null,
              avatarId: null,
              actualUser: false,
              accountId: null,
            },
            investmentBenchmarks: [],
            favorite: false,
            viewed: false,
            needsProxy: false,
            needsInterpolation: false,
            proxyable: true,
            interpolatable: false,
            outOfDate: false,
            hasMinDataForInterpolation: false,
            investmentSource: 'VENN',
            userEditable: false,
          },
          primary: true,
        },
      ],
    },
    {
      id: 'ead1545e-7b45-499e-a9b5-af72e5ec4d3e',
      name: 'S&P NEW 2',
      subjectId: '24beb76f-b76f-43e8-aa3d-6781c33a5504',
      analysisSubjectType: 'INVESTMENT',
      subjectName: 'S&P 1000 Index',
      customTemplateId: null,
      systemTemplate: 'residual',
      numSnapshots: 0,
      created: 1612971333007,
      updated: 1612971333007,
      analysisViewType: 'ANALYSIS',
      owner: {
        accountId: 1,
        active: true,
        actualUser: false,
        avatarId: '',
        created: 99999999,
        displayName: 'tt',
        email: 't@t.t',
        id: -1,
        name: 'tt',
        pwdLastUpdated: null,
      },
      subjects: [
        {
          id: '24beb76f-b76f-43e8-aa3d-6781c33a5504',
          subjectType: 'INVESTMENT',
          comparisonType: 'PRIMARY',
          fund: {
            id: '24beb76f-b76f-43e8-aa3d-6781c33a5504',
            name: 'S&P 1000 Index',
            fundSourceType: { id: 1, name: 'Investment' },
            created: 1579907542670,
            updated: 1613040832308,
            createdById: -42,
            updatedById: -42,
            currency: 'USD',
            userUploaded: false,
            assetType: 'INDEX',
            remoteType: 'DATA',
            symbol: 'SP1000',
            active: true,
            live: true,
            owner: {
              id: -42,
              name: null,
              displayName: null,
              email: null,
              active: false,
              created: null,
              pwdLastUpdated: null,
              avatarId: null,
              actualUser: false,
              accountId: null,
            },
            updatedBy: {
              id: -42,
              name: null,
              displayName: null,
              email: null,
              active: false,
              created: null,
              pwdLastUpdated: null,
              avatarId: null,
              actualUser: false,
              accountId: null,
            },
            investmentBenchmarks: [],
            favorite: false,
            viewed: false,
            needsProxy: false,
            needsInterpolation: false,
            proxyable: true,
            interpolatable: false,
            outOfDate: false,
            hasMinDataForInterpolation: false,
            investmentSource: 'VENN',
            userEditable: false,
          },
          primary: true,
        },
      ],
    },
    {
      id: 'e1f0ddb8-80ce-4264-b5b2-d512aa748468',
      name: 'S&P NEW 3',
      subjectId: '24beb76f-b76f-43e8-aa3d-6781c33a5504',
      analysisSubjectType: 'INVESTMENT',
      subjectName: 'S&P 1000 Index',
      customTemplateId: null,
      systemTemplate: 'residual',
      numSnapshots: 0,
      created: 1612971328816,
      updated: 1612971328816,
      analysisViewType: 'ANALYSIS',
      owner: {
        accountId: 1,
        active: true,
        actualUser: false,
        avatarId: '',
        created: 99999999,
        displayName: 'tt',
        email: 't@t.t',
        id: -1,
        name: 'tt',
        pwdLastUpdated: null,
      },
      subjects: [
        {
          id: '24beb76f-b76f-43e8-aa3d-6781c33a5504',
          subjectType: 'INVESTMENT',
          comparisonType: 'PRIMARY',
          fund: {
            id: '24beb76f-b76f-43e8-aa3d-6781c33a5504',
            name: 'S&P 1000 Index',
            fundSourceType: { id: 1, name: 'Investment' },
            created: 1579907542670,
            updated: 1613040832308,
            createdById: -42,
            updatedById: -42,
            currency: 'USD',
            userUploaded: false,
            assetType: 'INDEX',
            remoteType: 'DATA',
            symbol: 'SP1000',
            active: true,
            live: true,
            owner: {
              id: -42,
              name: null,
              displayName: null,
              email: null,
              active: false,
              created: null,
              pwdLastUpdated: null,
              avatarId: null,
              actualUser: false,
              accountId: null,
            },
            updatedBy: {
              id: -42,
              name: null,
              displayName: null,
              email: null,
              active: false,
              created: null,
              pwdLastUpdated: null,
              avatarId: null,
              actualUser: false,
              accountId: null,
            },
            investmentBenchmarks: [],
            favorite: false,
            viewed: false,
            needsProxy: false,
            needsInterpolation: false,
            proxyable: true,
            interpolatable: false,
            outOfDate: false,
            hasMinDataForInterpolation: false,
            investmentSource: 'VENN',
            userEditable: false,
          },
          primary: true,
        },
      ],
    },
    {
      id: 'a3439ee8-53ae-4d15-bd01-02518b6a7f39',
      name: 'S&P NEW 4',
      subjectId: '24beb76f-b76f-43e8-aa3d-6781c33a5504',
      analysisSubjectType: 'INVESTMENT',
      subjectName: 'S&P 1000 Index',
      customTemplateId: null,
      systemTemplate: 'residual',
      numSnapshots: 0,
      created: 1612971325088,
      updated: 1612971325087,
      analysisViewType: 'ANALYSIS',
      owner: {
        accountId: 1,
        active: true,
        actualUser: false,
        avatarId: '',
        created: 99999999,
        displayName: 'tt',
        email: 't@t.t',
        id: -1,
        name: 'tt',
        pwdLastUpdated: null,
      },
      subjects: [
        {
          id: '24beb76f-b76f-43e8-aa3d-6781c33a5504',
          subjectType: 'INVESTMENT',
          comparisonType: 'PRIMARY',
          fund: {
            id: '24beb76f-b76f-43e8-aa3d-6781c33a5504',
            name: 'S&P 1000 Index',
            fundSourceType: { id: 1, name: 'Investment' },
            created: 1579907542670,
            updated: 1613040832308,
            createdById: -42,
            updatedById: -42,
            currency: 'USD',
            userUploaded: false,
            assetType: 'INDEX',
            remoteType: 'DATA',
            symbol: 'SP1000',
            active: true,
            live: true,
            owner: {
              id: -42,
              name: null,
              displayName: null,
              email: null,
              active: false,
              created: null,
              pwdLastUpdated: null,
              avatarId: null,
              actualUser: false,
              accountId: null,
            },
            updatedBy: {
              id: -42,
              name: null,
              displayName: null,
              email: null,
              active: false,
              created: null,
              pwdLastUpdated: null,
              avatarId: null,
              actualUser: false,
              accountId: null,
            },
            investmentBenchmarks: [],
            favorite: false,
            viewed: false,
            needsProxy: false,
            needsInterpolation: false,
            proxyable: true,
            interpolatable: false,
            outOfDate: false,
            hasMinDataForInterpolation: false,
            investmentSource: 'VENN',
            userEditable: false,
          },
          primary: true,
        },
      ],
    },
    {
      id: '6d56482c-2d76-4363-8ce5-3483ed417c5e',
      name: 'S&P 5',
      subjectId: '24beb76f-b76f-43e8-aa3d-6781c33a5504',
      analysisSubjectType: 'INVESTMENT',
      subjectName: 'S&P 1000 Index',
      customTemplateId: null,
      systemTemplate: 'residual',
      numSnapshots: 0,
      created: 1612965935922,
      updated: 1612965935922,
      analysisViewType: 'ANALYSIS',
      owner: {
        accountId: 1,
        active: true,
        actualUser: false,
        avatarId: '',
        created: 99999999,
        displayName: 'tt',
        email: 't@t.t',
        id: -1,
        name: 'tt',
        pwdLastUpdated: null,
      },
      subjects: [
        {
          id: '24beb76f-b76f-43e8-aa3d-6781c33a5504',
          subjectType: 'INVESTMENT',
          comparisonType: 'PRIMARY',
          fund: {
            id: '24beb76f-b76f-43e8-aa3d-6781c33a5504',
            name: 'S&P 1000 Index',
            fundSourceType: { id: 1, name: 'Investment' },
            created: 1579907542670,
            updated: 1613040832308,
            createdById: -42,
            updatedById: -42,
            currency: 'USD',
            userUploaded: false,
            assetType: 'INDEX',
            remoteType: 'DATA',
            symbol: 'SP1000',
            active: true,
            live: true,
            owner: {
              id: -42,
              name: null,
              displayName: null,
              email: null,
              active: false,
              created: null,
              pwdLastUpdated: null,
              avatarId: null,
              actualUser: false,
              accountId: null,
            },
            updatedBy: {
              id: -42,
              name: null,
              displayName: null,
              email: null,
              active: false,
              created: null,
              pwdLastUpdated: null,
              avatarId: null,
              actualUser: false,
              accountId: null,
            },
            investmentBenchmarks: [],
            favorite: false,
            viewed: false,
            needsProxy: false,
            needsInterpolation: false,
            proxyable: true,
            interpolatable: false,
            outOfDate: false,
            hasMinDataForInterpolation: false,
            investmentSource: 'VENN',
            userEditable: false,
          },
          primary: true,
        },
      ],
    },
  ],
} as unknown as PageAnalysisViewSearchResult;
