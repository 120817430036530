import type { CustomFactorForecast, FactorOverrideRange, ForecastContextEnum } from 'venn-api';
import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import {
  factorCurrentPendingOverrideAtom,
  factorEditValueBufferAtom,
  factorTextboxIsEditingAtom,
  isFactorForecastEditingAtom,
} from './factorForecastAtoms';
import type { FactorNameType } from 'venn-state';

export const isSystemForecast = (forecast: CustomFactorForecast) => !!forecast?.systemContext;

export type PartialFactorForecast = {
  name: FactorNameType;
  annualizedReturn: number;
  annualizedVolatility: number;
  forecastContext: ForecastContextEnum;
  computedByCustomForecast: boolean;
  annualizedReturnOverride?: number;
  factorOverrideRange: FactorOverrideRange;
};

export const CASH_FACTOR_NAME = 'Cash';
export const getTableDataForForecast = ({
  cashForecast,
  cashForecastOverride,
  cashForecastContext,
  cashComputedByCustomForecast,
  factorForecasts,
  cashOverrideRange,
}: Pick<
  CustomFactorForecast,
  | 'cashForecast'
  | 'cashForecastOverride'
  | 'cashForecastContext'
  | 'cashComputedByCustomForecast'
  | 'factorForecasts'
  | 'cashOverrideRange'
>): PartialFactorForecast[] => {
  return [
    ...factorForecasts,
    {
      name: CASH_FACTOR_NAME,
      annualizedReturn: cashForecast,
      annualizedReturnOverride: cashForecastOverride,
      annualizedVolatility: 0,
      forecastContext: cashForecastContext,
      computedByCustomForecast: cashComputedByCustomForecast,
      factorOverrideRange: cashOverrideRange,
    },
  ];
};

export const useResetFactorOverrideState = (tableData: PartialFactorForecast[]) => {
  const setFactorTextboxIsEditing = useSetRecoilState(factorTextboxIsEditingAtom);
  const setFactorCurrentPendingOverride = useSetRecoilState(factorCurrentPendingOverrideAtom);
  const setFactorEditValueBuffer = useSetRecoilState(factorEditValueBufferAtom);
  const setIsFactorForecastGroupEditing = useSetRecoilState(isFactorForecastEditingAtom);

  const createDefaultTextboxIsEditing = useCallback(
    () => Object.fromEntries(tableData.map(({ name }) => [name, false])),
    [tableData],
  );
  const createDefaultFactorCurrentPendingOverride = useCallback(
    () => Object.fromEntries(tableData.map(({ name }) => [name, 'no change' as const])),
    [tableData],
  );
  const createDefaultEditValues = useCallback(
    () =>
      Object.fromEntries(
        tableData.map(({ name, annualizedReturnOverride, annualizedReturn }) => {
          const defaultValue = defaultFactorCurrentEditValue({
            annualizedReturn,
            annualizedReturnOverride,
          });
          return [name, defaultValue];
        }),
      ),
    [tableData],
  );
  const resetNonInputEditingState = useCallback(() => {
    setFactorCurrentPendingOverride(createDefaultFactorCurrentPendingOverride);
    setFactorTextboxIsEditing(createDefaultTextboxIsEditing);
    setIsFactorForecastGroupEditing(false);
  }, [
    createDefaultFactorCurrentPendingOverride,
    createDefaultTextboxIsEditing,
    setFactorCurrentPendingOverride,
    setFactorTextboxIsEditing,
    setIsFactorForecastGroupEditing,
  ]);
  const resetInputState = useCallback(() => {
    setFactorEditValueBuffer(createDefaultEditValues);
  }, [createDefaultEditValues, setFactorEditValueBuffer]);
  const resetAllState = useCallback(() => {
    resetInputState();
    resetNonInputEditingState();
  }, [resetInputState, resetNonInputEditingState]);
  return {
    resetNonInputEditingState,
    resetInputState,
    resetAllState,
  };
};

export const roundTo2DecimalPlaces = (n: number): string => roundToNDecimalPlaces(n, 2);
export const roundToNDecimalPlaces = (n: number, decimalPlaces: number) => (n * 100).toFixed(decimalPlaces);
export const defaultFactorCurrentEditValue = ({
  annualizedReturn,
  annualizedReturnOverride,
}: {
  annualizedReturn: number;
  annualizedReturnOverride?: number;
}) => roundTo2DecimalPlaces(annualizedReturnOverride ?? annualizedReturn);
