import React, { useEffect, useRef, useState } from 'react';
import type { AuthPageProps } from '../components';
import { AuthenticationFooter, AuthValidators, AuthWrapperWithFooter, PendingEmailConfirmation } from '../components';
import { analyticsService, Routes } from 'venn-utils';
import type { SignupRequest, UserStatusEnum } from 'venn-api';
import { SignUpInviteRoute } from '../SignUpInvite/SignUpInvitePage';
import { getSignUpParams } from '../queryParamParsers';
import SignupForm from './SignupForm';
import SignupTrackingPixel from './SignupTrackingPixel';
import { required } from '../components/validators/Validators';
import { createField } from 'venn-components';

const { isEmailValid } = AuthValidators;

const pendingStatuses: UserStatusEnum[] = ['PENDING_ACTIVATION', 'RESTRICTED'];

export interface SignUpPageState {
  status?: UserStatusEnum;
  signupValues: Partial<SignupRequest>;
  error: string;
  existingAccount: boolean;
}

const SignUpPage = ({ history }: AuthPageProps) => {
  const historyRef = useRef(history);
  const fields = {
    email: createField('email', '', isEmailValid),
    firstName: createField('firstName', '', required('First name')),
    lastName: createField('lastName', '', required('Last name')),
    company: createField('company', '', required('Organization name')),
  };

  const [{ error, status, signupValues, existingAccount }, setPageState] = useState<SignUpPageState>({
    signupValues: {
      email: '',
      firstName: '',
      lastName: '',
      organizationLegalName: '',
    },
    existingAccount: false,
    error: '',
  });

  const { activationCode } = getSignUpParams();

  useEffect(() => {
    if (!activationCode) {
      analyticsService.signupStepViewed({
        stepDescription: 'account details',
      });
    } else {
      historyRef.current.replace(`${SignUpInviteRoute}${window.location.search}`);
    }
  }, [activationCode]);

  useEffect(() => {
    // Existing user, redirect them to sign in screen
    if (status === 'ACTIVE' || existingAccount) {
      historyRef.current.push(Routes.SIGN_IN_PATH);
    }
  }, [status, existingAccount]);

  if (status && pendingStatuses.includes(status)) {
    return <PendingEmailConfirmation signupValues={signupValues} status={status} />;
  }

  return (
    <>
      <AuthWrapperWithFooter>
        <SignupTrackingPixel />
        <SignupForm error={error} fields={fields} setPageState={setPageState} />
        <AuthenticationFooter />
      </AuthWrapperWithFooter>
    </>
  );
};

export default SignUpPage;
