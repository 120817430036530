import { useMemo } from 'react';
import type { DateRange } from '../types';
import { validate } from './logic';

const useNonOverlapping = (maxRange: DateRange | undefined, from?: number, to?: number) => {
  const isValid = useMemo(() => validate({ from, to }, maxRange), [from, to, maxRange]);

  return {
    maxRange,
    isValid,
  };
};

export default useNonOverlapping;
