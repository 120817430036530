import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { CashflowSettingsModal, DataGrid } from 'venn-components';
import { GetColor } from 'venn-ui-kit';
import {
  type CashFlowSettingRow,
  PRIVATES_CASHFLOW_PACING_PARAMETERS_HEADER_GROUP_DIV_GREEN_4,
  PRIVATES_CASHFLOW_PACING_PARAMETERS_HEADER_GROUP_DIV_GREEN_5,
  usePrivatesCashflowPacingSettingsColumns,
} from './PrivateCashflowPacingSettingsColumns';
import type { PrivatePortfolioNode } from 'venn-api';

type PrivateSubjectCashflowPacingSettingsProps = {
  settingsRowData: CashFlowSettingRow[] | undefined;
  portfolio?: PrivatePortfolioNode;
  refetchActiveSettings: () => void;
};

type FundInfo = {
  id: string | undefined;
  name: string | undefined;
};

const PrivateSubjectCashflowPacingSettings = ({
  refetchActiveSettings,
  settingsRowData,
  portfolio,
}: PrivateSubjectCashflowPacingSettingsProps) => {
  const [fundTriggeringModal, setFundTriggeringModal] = useState<FundInfo | undefined>(undefined);

  const onEditButtonClick = useCallback(
    (id: string | undefined, name: string | undefined) => {
      setFundTriggeringModal({
        id,
        name,
      });
    },
    [setFundTriggeringModal],
  );

  const onClose = useCallback(() => {
    setFundTriggeringModal(undefined);
  }, [setFundTriggeringModal]);

  return (
    <>
      <Wrapper>
        <DataGrid
          columnDefs={usePrivatesCashflowPacingSettingsColumns(onEditButtonClick)}
          defaultColDef={{
            resizable: false,
            lockPinned: true,
            sortable: true,
            suppressMenu: true,
            suppressMovable: true,
            editable: false,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            // The following 3 properties ensure that very long fund/setting names are nicely wrapped
            wrapText: true,
            autoHeight: true,
            cellStyle: { wordBreak: 'normal' },
          }}
          domLayout="autoHeight"
          rowData={settingsRowData}
          headerHeight={41}
          rowHeight={37}
          suppressPaginationPanel
          suppressRowClickSelection
          suppressContextMenu
        />
      </Wrapper>
      {fundTriggeringModal && (
        <CashflowSettingsModal
          onChangesApplied={refetchActiveSettings}
          onClose={onClose}
          privatePortfolio={portfolio}
          triggeringFundId={fundTriggeringModal?.id}
          subjectName={portfolio ? portfolio.name : fundTriggeringModal?.name}
          source="MANAGE_DATA_PAGE"
        />
      )}
    </>
  );
};

const Wrapper = styled.div`
  .ag-cell {
    padding: 2px 6px;
  }
  .ag-header-cell {
    padding: 2px 6px;
  }
  .ag-header-group-cell {
    padding: 2px 6px;
  }

  .ag-body-horizontal-scroll {
    min-height: 16px !important;
    .ag-body-horizontal-scroll-viewport {
      min-height: 16px !important;
    }
  }

  .ag-center-cols-viewport {
    height: calc(100% + 16px) !important;
  }

  .ag-right-aligned-header .ag-header-cell-text {
    text-align: right;
  }

  & .${PRIVATES_CASHFLOW_PACING_PARAMETERS_HEADER_GROUP_DIV_GREEN_4} {
    background-color: ${GetColor.DEPRECATED_DivergingColor.B4};
    pointer-events: none;
    color: ${GetColor.White};
  }

  & .${PRIVATES_CASHFLOW_PACING_PARAMETERS_HEADER_GROUP_DIV_GREEN_5} {
    pointer-events: none;
    background-color: ${GetColor.DEPRECATED_DivergingColor.B5};
    --ag-header-cell-hover-background-color: ${GetColor.DEPRECATED_DivergingColor.B5};
  }

  & .${PRIVATES_CASHFLOW_PACING_PARAMETERS_HEADER_GROUP_DIV_GREEN_5}:hover {
    background-color: ${GetColor.DEPRECATED_DivergingColor.B5};
  }
`;

export default PrivateSubjectCashflowPacingSettings;
