import type { AnalysisBlockTypeEnum } from 'venn-api';

export const PRINT_NARROW_BLOCKS: AnalysisBlockTypeEnum[] = [
  'CUMULATIVE_RETURN',
  'RETURNS_DISTRIBUTION',
  'ROLLING_VOLATILITY',
  'ROLLING_SHARPE_RATIO',
  'FACTOR_CONTRIBUTION_TO_RETURN',
  'FACTOR_CONTRIBUTION_TO_RISK',
  'FACTOR_CONTRIBUTION_TO_EXPOSURE',
  'FACTOR_CONTRIBUTION_TO_RETURN_TREND',
  'FACTOR_CONTRIBUTION_TO_RISK_TREND',
  'FACTOR_CONTRIBUTION_TO_EXPOSURE_TREND',
];
