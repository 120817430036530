import React from 'react';
import styled from 'styled-components';
import { Numbers } from 'venn-utils';

const PADDING = 10;
const LINE_OFFSET = 17;

interface AnalysisDetail {
  portfolioName?: string;
  value: string | number | undefined;
}

interface CorrelationRowTooltipProps {
  investment: string;
  details: AnalysisDetail[];
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default ({ investment, details }: CorrelationRowTooltipProps) => {
  if (details.length < 1) {
    return null;
  }
  const forMatFunc = Numbers.createNumberFormatter({
    forceSign: false,
    percentage: false,
  });

  return (
    <div>
      <Title>Correlation</Title>
      <Detail>{investment}</Detail>
      <Separator>vs.</Separator>
      {details.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Detail key={index}>
          {item.portfolioName}: <strong>{forMatFunc(item.value)}</strong>
        </Detail>
      ))}
    </div>
  );
};

const Title = styled.div`
  font-weight: bold;
  font-size: 14px;
  margin-bottom: ${LINE_OFFSET}px;
`;

const Separator = styled.div`
  margin-top: ${PADDING}px;
`;

const Detail = styled.div`
  font-size: 14px;
  margin-top: ${PADDING}px;
`;
