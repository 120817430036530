import React from 'react';
import styled from 'styled-components';
import { Button } from '../../button';
import { GetColor } from '../../../style';

export const APPLY_BUTTON_CLASSNAME = 'qa-confirm-button';

interface MenuActionsProps {
  applyLabel?: string;
  onCancel(): void;
  onApply(): void;
  disabled?: boolean;
}

const MenuActions = ({ applyLabel = 'Apply', onCancel, onApply, disabled }: MenuActionsProps) => {
  return (
    <Actions>
      <Button key="cancel" className="qa-cancel-filter" dense flat onClick={onCancel}>
        Cancel
      </Button>
      <Button key="submit" className={APPLY_BUTTON_CLASSNAME} type="submit" dense onClick={onApply} disabled={disabled}>
        {applyLabel}
      </Button>
    </Actions>
  );
};

export default MenuActions;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${GetColor.Grey};
  padding: 15px;
  padding-left: 0;
  height: 58px;
`;
