import type { DownloadMetaData } from 'venn-components';
import { getImagesFooter } from 'venn-components';
import { getDownloadMetaData } from '../../../utils';
import type { ExportMetaData } from './types';
import { getBlocksParams } from './getBlocksParams';
import type { MetaData } from 'venn-utils';
import { getRawMetadataLabel } from 'venn-utils';

interface ExtraFooterValues {
  value: string;
  label: string;
}

const getExtraFooterValues = (blocksParams: MetaData[]): ExtraFooterValues[] | undefined =>
  blocksParams.map((param) => {
    return {
      label: getRawMetadataLabel(param[0]),
      value: param[1],
    };
  });

const footerMetaData = (exportMetaData: ExportMetaData): DownloadMetaData => {
  const blocksParams = getBlocksParams(exportMetaData, exportMetaData.analysisConfig.analysisTemplate?.analysisBlocks);

  return {
    ...getDownloadMetaData(
      exportMetaData.analysisConfig,
      exportMetaData.startDate,
      exportMetaData.endDate,
      exportMetaData.frequency,
    ),
    extra: getExtraFooterValues(blocksParams),
  };
};

const getFooter = (exportMetaData: ExportMetaData) => {
  return getImagesFooter(footerMetaData(exportMetaData));
};

export default getFooter;
