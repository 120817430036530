import { atomFamily } from 'recoil';
import type { BlockId } from '../types';
import { DEFAULT_PRIVATE_ASSET_ALLOCATION, DEFAULT_PRIVATE_ASSET_ALLOCATION_VISIBILITY } from 'venn-utils';

export const DEFAULT_CAPITAL_COMMITMENT_ALLOCATION = 100000000; // 100 M
export const blockPrivateDefaultAsOfDateBehavior = atomFamily<boolean, BlockId>({
  key: 'blockPrivateDefaultAsOfDateBehavior',
  default: true,
});

export const blockPrivateAssetIsCumulative = atomFamily<boolean | undefined, BlockId>({
  key: 'blockPrivateAssetIsCumulative',
  default: undefined,
});

export const blockStartingNAV = atomFamily<number, BlockId>({
  key: 'blockStartingNAV',
  default: DEFAULT_CAPITAL_COMMITMENT_ALLOCATION,
});

export const blockPrivateAssetAllocation = atomFamily<number, BlockId>({
  key: 'blockPrivateAssetAllocation',
  default: DEFAULT_PRIVATE_ASSET_ALLOCATION,
});

export const blockPrivateAssetAllocationVisibility = atomFamily<boolean, BlockId>({
  key: 'blockPrivateAssetAllocationVisibility',
  default: DEFAULT_PRIVATE_ASSET_ALLOCATION_VISIBILITY,
});
