import type { FC } from 'react';
import React from 'react';

export interface NewTabLinkProps {
  href: string;
}

export const NewTabLink: FC<React.PropsWithChildren<NewTabLinkProps>> = ({ href, children }) => (
  <a href={href} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
);

export default NewTabLink;
