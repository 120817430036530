interface Page {
  regex: RegExp;
  title: string;
}

export enum Routes {
  INDEX_PATH = '/',
  HOME_PATH = '/home',
  COOKIE_MANAGER = '/cookie-manager',
  LOGOUT_PATH = '/logout',
  SIGN_IN_PATH = '/sign-in',
  SIGN_UP_PATH = '/sign-up',

  DATA_MANAGER_PATH = '/data',
  RETURN_STREAM_EDITOR_PATH = '/data/:id',

  ACCOUNT_PATH = '/account',

  FACTOR_PERFORMANCE_PATH = '/factor-insights',
  FACTOR_PERFORMANCE_LEGACY_PATH = '/factor-performance',
  FACTOR_LENS_HOMEPAGE = '/factor-lens',
  FACTOR_LENS_BASICS = '/factor-lens/basics',
  FACTOR_LENS_INSIGHTS = '/factor-lens/insights',
  FACTOR_LENS_DEEP_DIVE = '/factor-lens/deep-dive',

  ANALYSIS_PATH = '/analysis',
  DEFAULT_ANALYSIS_PATH = '/analysis/results/default',
  LIBRARIES_PATH = '/libraries',
  REPORTS = '/reports',
  ANALYSIS_RESULTS_PATH = '/analysis/results/:analysisType/:objectType?/:objectId?/:strategyId?',
  ANALYSIS_COMPARE_PATH = '/analysis/compare',

  PORTFOLIO_LAB_PATH = '/portfolio-lab',
  PORTFOLIO_LAB_RESULTS_PATH = '/portfolio-lab/results',

  CREATE_PORTFOLIO = '/create/portfolio',
  CREATE_TEMPLATE = '/create/template',
  EDIT_TEMPLATE_PATH = '/edit/template',
  EDIT_TEMPLATE_BY_ID = '/edit/template/:id',

  MANAGE_DATA_PATH = '/manage-data',
  MANAGE_DATA = '/manage-data/:objectType/:objectId/:section?',

  ACCOUNT_MANAGE_DATA_SET = '/account/datasetmanagement',
  CUSTOM_BLOCK_PATH = '/custom/block',
  STUDIO_PATH = '/studio',
  REPORT_LAB_PATH = '/reportlab',

  ARCHIVE_PAGE_PATH = '/archive',
  SERVICE_TIERS_PATH = '/service-tiers',
  UPGRADES_STATUS_PAGE = '/upgrades-status-page',
}

/**
 * Routes that should not be blocked by Prompts or history.block.
 * This is a rare case and mainly used for downtime.
 * New prompts and history.block calls should respect these routes as unblockable.
 */
export const UNBLOCKABLE_ROUTES = [Routes.UPGRADES_STATUS_PAGE] as const;

const pages: Page[] = [
  /* HOME */
  { regex: /\/home(\/)?$/, title: 'Home' },

  /* LIBRARIES */
  { regex: /\/libraries(\/)?$/, title: 'Library' },

  /* REPORTS */
  { regex: /\/reports(\/)?$/, title: 'Reports' },

  /* PORTFOLIO LAB */
  { regex: /\/portfolio-lab(\/)?$/, title: 'Portfolio Lab' },

  /* ANALYSIS */
  { regex: /\/analysis\/results(\/)?/, title: 'Analysis Results' },

  /* COMPARE */
  { regex: /\/analysis\/compare(\/)?/, title: 'Compare' },

  /* CUSTOM REPORTS */
  { regex: /\/custom\/block(\/)?/, title: 'Builder' },

  /* STUDIO */
  { regex: /\/studio(\/)?/, title: 'Studio' },

  /* REPORT LAB */
  { regex: /\/reportlab(\/)?/, title: 'Report Lab' },

  /* ARCHIVE */
  { regex: /\/archive(\/)?/, title: 'Archive' },

  /* SERVICE TIERS */
  { regex: /\/service-tiers(\/)?/, title: 'Service Tiers' },

  /* TEMPLATES */
  { regex: /\/edit\/template(\/)?/, title: 'Edit Template' },
  { regex: /\/create\/template(\/)?$/, title: 'Create Template' },

  /* MANAGE DATA */
  { regex: /\/manage-data(\/)?/, title: 'Manage Data' },
  { regex: /\/manage-privates-data(\/)?/, title: 'Manage Privates Data' },
  /* NUX */
  { regex: /\/create\/portfolio(\/)?/, title: 'New Portfolio' },

  /* ACCOUNT */
  { regex: /\/account(\/)?$/, title: 'Account Settings' },

  /* FACTOR EDUCATION */
  { regex: /\/factor-lens(\/)?$/, title: 'Factor Lens' },
  { regex: /\/factor-lens\/basics(\/)?$/, title: 'Factor Lens - Basics' },
  { regex: /\/factor-lens\/insights(\/)?$/, title: 'Factor Lens - Insights' },
  { regex: /\/factor-lens\/deep-dive(\/)?/, title: 'Factor Lens - Deep Dive' },
  { regex: /\/forgot-password(\/)?$/, title: 'Forgot Password' },
  { regex: /\/sign-in(\/)?$/, title: 'Sign In' },
  { regex: /\/activate(\/)?$/, title: 'Activate' },
  { regex: /\/sign-up\/invitation(\/)?$/, title: 'Create Password' },
  { regex: /\/sign-up(\/)?$/, title: 'Sign Up' },
];

export const getTitle = (url: string) => {
  return pages.find((page) => page.regex.test(url))?.title;
};
