import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';
import { analyticsService } from 'venn-utils';
import CookiePreferencesModal from './CookiePreferencesModal';
import { COOKIE_SETTINGS } from '../CookiesCommon';

/* Provides a launch point for the cookie preferences modal for EU users */
const CookiePreferencesTrigger = () => {
  const [cookies] = useCookies([COOKIE_SETTINGS]);
  const [showLink, setShowLink] = useState(false);
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    // If the user has set their cookie settings, insert a link to the modal in the footer
    if (cookies[COOKIE_SETTINGS]) {
      setShowLink(true);
    }
  }, [cookies]);

  useEffect(() => {
    if (showModal) {
      analyticsService.ctaClicked({
        locationOnPage: 'footer',
        purpose: 'Open Cookie Preferences Manager',
        text: 'Cookie Preferences Manager',
        type: 'link',
      });
    }
  }, [showModal]);

  return (
    <>
      {showLink ? (
        <li>
          <StyledFooterButton type="button" onClick={() => setShowModal(true)}>
            Do Not Share My Personal Information
          </StyledFooterButton>
        </li>
      ) : null}
      {showModal ? (
        <CookiePreferencesModal
          originalCookiePreferences={parseInt(cookies[COOKIE_SETTINGS], 10)}
          onClose={() => setShowModal(false)}
        />
      ) : null}
    </>
  );
};

export default CookiePreferencesTrigger;

const StyledFooterButton = styled.button`
  color: ${GetColor.Primary.Main};
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-align: left;

  :hover,
  :focus {
    border-bottom: 1px solid ${GetColor.Primary.Main};
  }
`;
