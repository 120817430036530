import React, { useEffect, useContext, useState } from 'react';
import { ThemeContext } from 'styled-components';
import type { InvestmentForecast, CMAGrouping, Fund } from 'venn-api';
import { CategorizedDropMenu, Loading } from 'venn-ui-kit';
import compact from 'lodash/compact';
import ForecastEditRow from './ForecastEditRow';
import { useManagedFocusMenu } from './shared';

interface CMARowProps {
  index: number;
  assumption: Partial<InvestmentForecast>;
  onDelete: (idx: number) => void;
  onChange: (idx: number, assumption: Partial<InvestmentForecast>) => void;
  allGroups: CMAGrouping[];
  currentGroup?: CMAGrouping;
  excludedGroups: CMAGrouping[];
  isLoadingCMAGroups: boolean;
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default ({
  assumption,
  index,
  currentGroup,
  allGroups,
  excludedGroups,
  isLoadingCMAGroups,
  onDelete,
  onChange,
}: CMARowProps) => {
  const { Colors } = useContext(ThemeContext);

  const [isValidItem, setIsValidItem] = useState(false);
  useEffect(() => {
    setIsValidItem(assumption.fundId === undefined && assumption.returnVal === undefined);
  }, [assumption]);

  const { inputRef, menuRef, setMenuValueSelected } = useManagedFocusMenu(isValidItem);

  const onChangeWrapper = (idx: number, newValue?: number) => {
    onChange(idx, { ...assumption, returnVal: newValue });
  };

  return (
    <ForecastEditRow
      alignRowItems="center"
      rowItem={assumption}
      index={index}
      baseValue={assumption.returnVal ?? 0.0}
      placeholder="0.0"
      onRowDelete={onDelete}
      onValueChange={onChangeWrapper}
      inputRef={inputRef}
      leftViewProvider={(dropdownWidth: number) => (
        <CategorizedDropMenu<Fund>
          width={dropdownWidth}
          innerRef={menuRef}
          openByDefault={false}
          height={220}
          headerComponent={isLoadingCMAGroups ? <Loading /> : undefined}
          categories={compact(
            (allGroups || []).map((group: CMAGrouping) => {
              const isCash = !!group.cashFactor;

              if (!isCash && !group.factor) {
                return null;
              }

              const disabled =
                excludedGroups.find((excludedGroup: CMAGrouping) => isSameGroup(group, excludedGroup)) &&
                !(currentGroup !== undefined && isSameGroup(currentGroup, group));

              return {
                name: isCash ? 'Cash' : group.factor.name,
                disabled,
                disabledMessage: 'This category is already represented in your assumptions.',
                items: group.options.map((fund: Fund) => ({
                  value: fund,
                  label: fund.name,
                  style: {
                    ...(disabled ? { color: Colors.HintGrey } : {}),
                  },
                })),
              };
            }),
          )}
          selected={
            assumption && currentGroup
              ? currentGroup.options.find((fund: Fund) => fund.id === assumption.fundId)
              : undefined
          }
          onChange={({ value }, category) => {
            setMenuValueSelected(true);
            onChange(index, {
              ...assumption,
              fundId: value.id,
              cashForecast: category && category.name === 'Cash',
            });
          }}
        />
      )}
    />
  );
};

function isSameGroup(group: CMAGrouping, compare: CMAGrouping): boolean {
  if (group.factor && compare.factor) {
    return group.factor.id === compare.factor.id;
  }
  if (group.cashFactor && compare.cashFactor) {
    return group.cashFactor === compare.cashFactor;
  }
  return false;
}
