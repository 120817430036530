import type moment from 'moment';
import type { CustomizableMetric } from 'venn-utils';
import { assertExhaustive } from '../type/typeUtils';

/** Frequencies supported by calendar returns grid blocks. */
export const RETURNS_FREQUENCIES = ['MONTHLY', 'QUARTERLY', 'YEARLY'] as const;
export type ReturnsFrequency = (typeof RETURNS_FREQUENCIES)[number];

export function isReturnsFrequency(maybePeriod: string): maybePeriod is ReturnsFrequency {
  return RETURNS_FREQUENCIES.includes(maybePeriod as ReturnsFrequency);
}

/** Warning: YEARLY will return empty array because it should generally be handled by the total column. */
export function returnsFrequencyToColumns(returnsFrequency: ReturnsFrequency): { name: string; tooltip?: string }[] {
  switch (returnsFrequency) {
    case 'QUARTERLY':
      return [
        { name: 'Q1', tooltip: 'January to end of March' },
        { name: 'Q2', tooltip: 'April to end of June' },
        { name: 'Q3', tooltip: 'July to end of September' },
        { name: 'Q4', tooltip: 'October to end of December' },
      ];
    case 'MONTHLY':
      return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].map((month) => ({
        name: month,
      }));
    case 'YEARLY':
      return [];
    default:
      throw assertExhaustive(returnsFrequency, `Unexpected returnsFrequency value: ${returnsFrequency}`);
  }
}

/**
 * Gets the index of a returnsFrequency for a moment.
 *
 * ```ts
 * getPeriodIndexInYear('MONTHLY', 2020-03-01); // 2
 * getPeriodIndexInYear('QUARTERLY', 2020-12-01); // 3
 * getPeriodIndexInYear('ANNUAL', 2020-12-01); // always 0 because there is only 1 year per year
 * ```
 */
export function getReturnsFrequencyIndexForMoment(moment: moment.Moment, returnsFrequency: ReturnsFrequency): number {
  switch (returnsFrequency) {
    case 'QUARTERLY':
      return moment.quarter() - 1; // date.quarter is base 1 so we convert it to base 0
    case 'MONTHLY':
      return moment.month();
    case 'YEARLY':
      return 0;
    default:
      throw assertExhaustive(returnsFrequency, `Unexpected returnsFrequency value: ${returnsFrequency}`);
  }
}

/**
 * Gets the number of periods that exist in a year given a frequency.
 *
 * Example: QUARTERLY => 4
 */
export function periodsPerYear(returnsFrequency: ReturnsFrequency) {
  switch (returnsFrequency) {
    case 'QUARTERLY':
      return 4;
    case 'MONTHLY':
      return 12;
    case 'YEARLY':
      return 1;
    default:
      throw assertExhaustive(returnsFrequency, `Unexpected returnsFrequency value: ${returnsFrequency}`);
  }
}

export function returnsFrequencyToDurationUnit(
  returnsFrequency: ReturnsFrequency,
): moment.unitOfTime.DurationConstructor {
  switch (returnsFrequency) {
    case 'QUARTERLY':
      return 'quarter';
    case 'MONTHLY':
      return 'month';
    case 'YEARLY':
      return 'year';
    default:
      throw assertExhaustive(returnsFrequency, `Unexpected returnsFrequency value: ${returnsFrequency}`);
  }
}

/** Takes a calendar returns metric and returns the period for it.  */
export function extractMetricReturnsFrequency(
  isRelative: boolean,
  selectedMetric: CustomizableMetric,
): ReturnsFrequency {
  const path =
    isRelative && selectedMetric.relativeMetricPath ? selectedMetric.relativeMetricPath : selectedMetric.metricPath;
  const returnsFrequency = path!.split('.')[1];
  if (!isReturnsFrequency(returnsFrequency)) {
    throw new Error(`Expected a returnsFrequency in ${path}: ${path}`);
  }
  return returnsFrequency;
}
