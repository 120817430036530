import React, { useState, useContext, useCallback, useMemo } from 'react';
import type { PortfolioCompare, Benchmark } from 'venn-api';
import { setFundBenchmarks, setPortfolioBenchmarks } from 'venn-api';
import type { AnalysisSubject } from 'venn-utils';
import { logMessageToSentry, useCachedSubjects } from 'venn-utils';
import type { DropMenuItem } from 'venn-ui-kit';
import { BenchmarksContext, PortfoliosContext } from 'venn-components';
import isEqual from 'lodash/isEqual';

interface BenchmarksContextStoreProps {
  subject: AnalysisSubject | undefined;
  onBenchmarksUpdate: (compare: PortfolioCompare[]) => Promise<void>;
}

const BenchmarksContextStore: React.FC<React.PropsWithChildren<BenchmarksContextStoreProps>> = ({
  subject,
  onBenchmarksUpdate,
  children,
}) => {
  const benchmarks = useMemo(() => subject?.benchmarks ?? [], [subject?.benchmarks]);

  const portfoliosContext = useContext(PortfoliosContext);
  const [benchmarksLoading, setBenchmarkLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const { updateCachedAnalysisSubject } = useCachedSubjects();

  const setBenchmark = useCallback(
    async (updatedbenchmarks: Benchmark[]) => {
      if (!subject) {
        logMessageToSentry('Failed to set benchmark when there is no subject');
        return;
      }
      try {
        if (subject.type === 'portfolio' && subject.strategy) {
          await setPortfolioBenchmarks(subject.strategy.id, updatedbenchmarks);
          // async update cached subject with benchmarks
          updateCachedAnalysisSubject(subject.strategy.id);
          if (subject.master) {
            await portfoliosContext.refresh();
          }
        } else if (subject.type === 'investment') {
          await setFundBenchmarks(subject.id as string, updatedbenchmarks);
          updateCachedAnalysisSubject(subject.id);
        }
        setError(undefined);
      } catch (e) {
        setError(e.message || e?.content?.message);
      }
    },
    [portfoliosContext, subject, updateCachedAnalysisSubject],
  );

  const performAllBenchmarkUpdates = useCallback(
    async (benchmarkEntities: Benchmark[], compares: PortfolioCompare[]) => {
      setError(undefined);
      await setBenchmark(benchmarkEntities);
      await onBenchmarksUpdate(compares);
    },
    [onBenchmarksUpdate, setBenchmark],
  );

  const onUpdateCompositeBenchmark = useCallback(async () => {
    await onBenchmarksUpdate(benchmarks);
  }, [benchmarks, onBenchmarksUpdate]);

  const handleBenchmarkChange = useCallback(
    async (selectedOption: DropMenuItem) => {
      setBenchmarkLoading(true);
      const compares = benchmarks.map((compare) => {
        const isPrimary =
          isEqual(compare.fundId, selectedOption.value) || isEqual(compare.portfolioId, Number(selectedOption.value));
        return {
          ...compare,
          primary: isPrimary,
          benchmark: isPrimary,
        };
      });
      const benchmarkEntities = compares.map((compare) => mapCompareToBenchmark(compare, compare.primary));
      await performAllBenchmarkUpdates(benchmarkEntities, compares);
      setBenchmarkLoading(false);
    },
    [benchmarks, performAllBenchmarkUpdates],
  );

  const onClearBenchmark = useCallback(async () => {
    setBenchmarkLoading(true);
    const compares = benchmarks.map((compare) => ({
      ...compare,
      primary: false,
      benchmark: false,
    }));
    const benchmarkEntities = compares.map((compare) => mapCompareToBenchmark(compare, compare.primary));
    await performAllBenchmarkUpdates(benchmarkEntities, compares);
    setBenchmarkLoading(false);
  }, [benchmarks, performAllBenchmarkUpdates]);

  const onBenchmarkSubmit = useCallback(
    async (benchmark: PortfolioCompare) => {
      setBenchmarkLoading(true);
      const baseBenchmarks = benchmarks.filter((b) =>
        benchmark.fundId ? b.fundId !== benchmark.fundId : b.portfolioId !== benchmark.portfolioId,
      );
      const benchmarkEntities: Benchmark[] = [
        mapCompareToBenchmark(benchmark, true),
        ...baseBenchmarks.map((compare) => mapCompareToBenchmark(compare, false)),
      ];
      await performAllBenchmarkUpdates(benchmarkEntities, [
        { ...benchmark, primary: true, benchmark: true },
        ...baseBenchmarks.map((b) => ({ ...b, primary: false, benchmark: false })),
      ]);
      setBenchmarkLoading(false);
    },
    [benchmarks, performAllBenchmarkUpdates],
  );

  return (
    <BenchmarksContext.Provider
      value={{
        benchmarks,
        benchmarksLoading,
        benchmarksError: error,
        onBenchmarkChange: handleBenchmarkChange,
        onBenchmarkCreate: onBenchmarkSubmit,
        onUpdateCompositeBenchmark,
        onClearBenchmark,
      }}
    >
      {children}
    </BenchmarksContext.Provider>
  );
};

export default BenchmarksContextStore;

function mapCompareToBenchmark(compare: PortfolioCompare, isPrimary: boolean): Benchmark {
  return {
    fundId: compare.fundId,
    portfolioId: compare.portfolioId,
    name: compare.name,
    type: compare.type,
    primary: isPrimary,
  };
}
