import { pick, merge } from 'lodash';
import { type DataLineColors, type DivergingColors, type PeerGroupsColors, type VennColors } from './color';
import { ColorUtils } from './colorUtils';
import tinycolor from 'tinycolor2';

/**
 * Keys within {@link VennColors} that are customizable and may be persisted to backend when customized.
 */
export const CUSTOM_COLORS_KEYS = [
  'StudioSubjectColor',
  'HoldingsColor',
  'DivergingColor',
  'CashFlowColor',
  'PeerGroupColor',
] as const satisfies (keyof VennColors)[];

/** Union of all keys in {@link CUSTOM_COLORS_KEYS}. */
export type CustomColorsKeys = (typeof CUSTOM_COLORS_KEYS)[number];

/**
 * The portion of {@link VennColors} that is customizable and may be persisted to backend when customized.
 * Based on {@link CustomColorsKeys}.
 */
export type CustomColors = Pick<VennColors, CustomColorsKeys>;

/**
 * The portion of {@link VennColors} that, in the new color theme introduced within VER-583 / VENN-25479, can be
 * mapped wholly from the content of {@link CustomColors}.
 */
export type MappedColors = Pick<VennColors, 'DataBarColor' | 'DataLineColor' | 'Optimization'>;
/**
 * The portion of {@link VennColors} that is statically defined.
 */
export type BaseColors = Omit<VennColors, keyof MappedColors | keyof CustomColors>;

/** Picks only the portion of the color theme that is customizable and separately persistable. */
export function pickCustomColors(theme: VennColors): CustomColors {
  return pick(theme, CUSTOM_COLORS_KEYS);
}

/**
 * Transforms the custom colors to a full VennColors through mapping and merging.
 */
export function transformCustomColorsToVennColors(baseColors: BaseColors, customColors: CustomColors): VennColors {
  const mappedOldColors = mapCustomColorsToOldColors(customColors);
  return mergeColorsTheme(baseColors, { ...customColors, ...mappedOldColors });
}

/** Maps new-theme (as of VER-583 / VENN-25479) customizable colors back to old-theme's cross-module colors.  */
export function mapCustomColorsToOldColors(customColors: CustomColors): MappedColors {
  const dataLineColor: DataLineColors = {
    Gold: getColorOnIndex(customColors, 0),
    PaleGold: getColorOnIndex(customColors, 1),
    Yellow: getColorOnIndex(customColors, 2),
    Green: getColorOnIndex(customColors, 3),
    PaleBlue: getColorOnIndex(customColors, 4),
    DarkBlue: getColorOnIndex(customColors, 5),
    Pink: getColorOnIndex(customColors, 6),
    DeepGreen: getColorOnIndex(customColors, 7),
  };

  return {
    DataBarColor: {
      LightGold: dataLineColor.Gold,
      LightPaleBlue: dataLineColor.PaleBlue,
      LightDarkBlue: dataLineColor.DarkBlue,
    },
    DataLineColor: dataLineColor,
    Optimization: {
      Positive: customColors.DivergingColor.B5,
      Negative: customColors.DivergingColor.A5,
    },
  };
}

/**
 * Merges custom colors on top of base Venn colors.
 *
 * Special case to not inner merge StudioSubjectColor, which is an ordered variable-length list of colors.
 */
export function mergeColorsTheme(baseColors: BaseColors, customColors: CustomColors & MappedColors): VennColors {
  const deepMerged = merge({}, baseColors, customColors);
  return {
    ...deepMerged,
    // For Subject color we don't merge because it represents a varying length Record of colors,
    // and if it differs in Length versus the base theme, that is intentional.
    StudioSubjectColor: customColors.StudioSubjectColor,
  };
}

/**
 * Picks a color from the provided color theme based on an index.
 *
 * Use useSubjectColor when a color is explicitly tied to a subject, to provide consistent and stable colors across a view.
 */
export const getColorOnIndex = (colors: Pick<VennColors, 'StudioSubjectColor'>, index: number): string => {
  const colorList = Object.values(colors.StudioSubjectColor);
  if (colorList.length === 0) {
    throw new Error('Cannot pass in an empty color array');
  }

  const baseColor = colorList[index % colorList.length];
  const opacity = 1 / (Math.floor(index / colorList.length) + 1);
  return ColorUtils.opacify(baseColor, opacity);
};

/**
 * Creates the diverging colour set by interpolating values in between a defined warm, cool, and midpoint
 */
export function createDivergingColors(warm: string, cool: string, mid: string): DivergingColors {
  return {
    A5: warm,
    A4: tinycolor.mix(warm, mid, 20).toHexString().toUpperCase(),
    A3: tinycolor.mix(warm, mid, 40).toHexString().toUpperCase(),
    A2: tinycolor.mix(warm, mid, 60).toHexString().toUpperCase(),
    A1: tinycolor.mix(warm, mid, 80).toHexString().toUpperCase(),
    MID: mid,
    B1: tinycolor.mix(cool, mid, 80).toHexString().toUpperCase(),
    B2: tinycolor.mix(cool, mid, 60).toHexString().toUpperCase(),
    B3: tinycolor.mix(cool, mid, 40).toHexString().toUpperCase(),
    B4: tinycolor.mix(cool, mid, 20).toHexString().toUpperCase(),
    B5: cool,
  };
}

/**
 * Creates the peer group colors from the gradient high color
 */
export function createPeerGroupColors(GradientHigh: string): PeerGroupsColors {
  return {
    GradientHigh,
    GradientLow: ColorUtils.opacify(GradientHigh, 0.5),
  };
}
