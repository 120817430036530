import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { isFunction, identity, isUndefined, omit } from 'lodash';
import queryString from 'query-string';
import type { History, Location } from 'history';
import { useHistory } from 'react-router-dom';
import type { UrlParam, UrlParamToType, UrlSetting } from 'venn-utils';
import { urlParamToSetting } from 'venn-utils';

type ReturnType<T> = [T, Dispatch<SetStateAction<T>>];

export function useUrlParamState<UrlParamT extends UrlParam>(
  urlParam: UrlParamT,
  initialValue?: UrlParamToType[UrlParamT],
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TS does not understand that settings fields are related to eachother after destructuring
  const settings = urlParamToSetting[urlParam] as UrlSetting<any>;

  return useUrlState<UrlParamToType[UrlParamT]>(
    urlParam,
    initialValue ?? settings.defaultValue,
    settings.serializer,
    settings.deserializer,
    settings.allowUndefined,
  );
}

/**
 * Store a piece of state in the URL, and ensures this is always synchronised with the URL. You must provide a unique URL Param key,
 * but the rest works exactly the same as the useState hook.
 * @param urlParamKey URL parameter. This must be unique across the application
 * @param initialValue Similar to the initialValue on useState, this defines what should be the value if the URL param is not present.
 * @param serialize If your state value type is not a string, you must provide a way to serialize it to string
 * @param deserialize If your state value type is not a string, you must provide a way to deserialize from a string value
 * @param allowUndefined Whether to respect setting the value as undefined and clearing the query param
 */
export function useUrlState<T>(
  urlParamKey: string,
  initialValue: T,
  serialize: (value: T) => string | undefined = identity,
  deserialize: (serialized: string | undefined) => T = identity,
  allowUndefined = false,
): ReturnType<T> {
  const [currentSerialized, setCurrentSerialized] = useSyncUrl(
    urlParamKey,
    serialize(initialValue) ?? '',
    allowUndefined,
  );
  const handleSetValue = useCallback(
    (newValue: SetStateAction<T>) => {
      setCurrentSerialized((prev) => {
        const actualNewValue = isFunction(newValue) ? newValue(deserialize(prev)) : newValue;
        return serialize(actualNewValue);
      });
    },
    [serialize, setCurrentSerialized, deserialize],
  );

  const returnValue = useMemo(() => {
    return deserialize(currentSerialized);
  }, [currentSerialized, deserialize]);

  return [returnValue, handleSetValue];
}

/**
 * This is an internal hook that syncs the URL with a string value and a param key.
 * @param urlParamKey Url Param Key
 * @param initialValue Initial value
 * @param allowUndefined Whether to respect setting the value as undefined and clearing the query param
 */
function useSyncUrl(
  urlParamKey: string,
  initialValue: string,
  allowUndefined: boolean,
): ReturnType<string | undefined> {
  const history = useHistory();
  const [current, setCurrent] = useState<string | undefined>(
    getValueFromUrl(history.location, urlParamKey) ?? initialValue,
  );
  const currentRef = useRef(current);
  currentRef.current = current;

  const handleSetValue = useCallback(
    (newValue: SetStateAction<string | undefined>) => {
      setCurrent((prevValue) => {
        const actualNewValue = isFunction(newValue) ? newValue(prevValue) : newValue;
        (allowUndefined || actualNewValue) && setValueInUrl(urlParamKey, actualNewValue, history);
        return actualNewValue;
      });
    },
    [history, urlParamKey, allowUndefined],
  );

  // Watches current to sync to the URL if changed
  useEffect(() => {
    const urlCurrent = getValueFromUrl(history.location, urlParamKey);
    if (current && urlCurrent !== current) {
      setValueInUrl(urlParamKey, current, history);
    }
  }, [current, history, urlParamKey, initialValue]);

  // Watches the URL to sync to current if changed
  useEffect(() => {
    const unlisten = history.listen((loc) => {
      const urlValue = getValueFromUrl(loc, urlParamKey);
      if (!allowUndefined && currentRef.current && urlValue === undefined) {
        setValueInUrl(urlParamKey, currentRef.current, history);
      } else {
        setCurrent(urlValue);
      }
    });
    return () => {
      unlisten();
      setValueInUrl(urlParamKey, undefined, history);
    };
  }, [history, urlParamKey, allowUndefined]);

  return [current, handleSetValue];
}

export function getValueFromUrl(location: Location, key: string): string | undefined {
  const parsed = queryString.parse(location.search);
  return parsed[key];
}

function setValueInUrl(key: string, value: string | undefined, history: History) {
  setValuesInUrl({ [key]: value }, history);
}

export function setValuesInUrl(keyValues: { [key: string]: string | undefined }, history: History) {
  const location = history.location;
  const parsed = queryString.parse(location.search);
  const newQueryObj = Object.entries(keyValues).reduce(
    (prev, [key, value]) => (isUndefined(value) ? omit(prev, key) : { ...prev, [key]: value }),
    parsed,
  );
  const newQueryString = queryString.stringify(newQueryObj);
  const hash = location.hash ?? '';
  const newUrl = `${location.pathname}?${newQueryString}${hash}`;
  history.replace(newUrl, location.state);
}
