import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import Input from '../../../input/Input';
import { GetColor, Label } from 'venn-ui-kit';

const NameWrapper = styled.div`
  > div {
    display: flex;
    margin-bottom: 20px;
  }

  input::placeholder {
    color: ${GetColor.LightGrey};
    opacity: 1;
  }
`;

const qaClass = 'qa-benchmarks-name';

export interface NameProps {
  value: string;
  onChange: (value: string) => void;
}

const Name: FC<React.PropsWithChildren<NameProps>> = ({ value, onChange }) => (
  <NameWrapper className={qaClass}>
    <Label>Benchmark Name</Label>
    <Input placeholder="Name" value={value} onChange={onChange} />
  </NameWrapper>
);

export default Name;
