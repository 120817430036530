import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import CellButton from '../cell-button/CellButton';
import type { NamedEntityString } from 'venn-api';
import { Icon, Tooltip } from 'venn-ui-kit';
import { MANAGE_DATA_SECTION, navigateToManageDataPage } from 'venn-utils';
import { useHistory } from 'react-router-dom';

interface UpdateCellProps {
  item: NamedEntityString;
  context: 'Analysis' | 'Library';
}

const NeedsAttentionButton = ({ item, context }: UpdateCellProps) => {
  const history = useHistory();
  const { Colors } = useContext(ThemeContext);
  return (
    <StyledTooltip
      maxWidth={175}
      content="This investment is more than 6 months out of date or has an insufficient number of data points. Please update your return data."
    >
      <CellButton
        backgroundColor={Colors.Alert}
        borderColor={Colors.Error}
        textColor={Colors.Black}
        hoverTextColor={Colors.White}
        onClick={() => navigateToManageDataPage(history, { fundId: item.id }, context, false, MANAGE_DATA_SECTION.DATA)}
      >
        <Icon type="cog" />
        Update
      </CellButton>
    </StyledTooltip>
  );
};

const StyledTooltip = styled(Tooltip)`
  text-align: center;
  height: 20px;
`;

export default NeedsAttentionButton;
