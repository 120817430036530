import React, { useState, useCallback, useMemo } from 'react';
import { isEmpty } from 'lodash';

import { Modal, ModalDescription, ModalFooter, ModalHeader, Input, ModalContent, RequiredLabel } from 'venn-components';
import styled from 'styled-components';
import type { WhatsNewContent } from 'venn-api';
import { getAppTitle } from 'venn-ui-kit';

interface EditDataModalProps {
  close: () => void;
  onConfirm: (data: Partial<WhatsNewContent>) => void;
  whatsNewData: WhatsNewContent;
}

interface EditModalErrors {
  title: string;
  text: string;
  link: string;
  url: string;
}

interface EditModalState {
  values: Partial<WhatsNewContent>;
  errors: EditModalErrors;
  isDefault: boolean;
}

const validate = (values: Partial<WhatsNewContent>) => {
  const isMissingError = 'This field is required';
  const errors = { title: '', text: '', link: '', url: '' };

  if (!values.title) {
    errors.title = isMissingError;
  }

  if (!values.text) {
    errors.text = isMissingError;
  }

  if (!values.link) {
    errors.link = isMissingError;
  }

  if (!values.url) {
    errors.url = isMissingError;
  }

  return errors;
};

const getTooltipErrorMsg = (title?: string, text?: string, link?: string, url?: string, isDefault?: boolean) => {
  const errorsObj = { title, text, link, url };
  const fieldsWithError = Object.keys(errorsObj)
    .filter((key) => errorsObj[key])
    .map((key) => {
      if (key === 'text') {
        return 'CONTENT PREVIEW TEXT';
      }

      if (key === 'link') {
        return 'LINK TEXT';
      }
      return key.toUpperCase();
    });

  if (isEmpty(fieldsWithError) && isDefault) {
    return 'You have to provide custom data';
  }

  const errorsString = fieldsWithError.join(', ');

  return `You have to provide custom data for ${errorsString}`;
};

const EditDataModal = ({ close, onConfirm, whatsNewData }: EditDataModalProps) => {
  const [form, setForm] = useState<EditModalState>({
    values: { title: '', text: '', link: '', url: '' },
    errors: { title: '', text: '', link: '', url: '' },
    isDefault: true,
  });

  const onChange = useCallback(
    (value: string, name: string) =>
      setForm((currentForm) => {
        const newValues = { ...currentForm.values, [name]: value };
        const newErrors = validate(newValues);
        const isDefault = whatsNewData[name] === value;

        return { values: newValues, errors: newErrors, isDefault };
      }),
    [whatsNewData],
  );

  const handleOnConfirm = () => {
    onConfirm(form.values);
    close();
  };

  const { values, errors, isDefault } = form;
  const isSaveButtonDisabled =
    isDefault ||
    Boolean(errors.title || errors.text || errors.link || errors.url) ||
    !values.title ||
    !values.text ||
    !values.link ||
    !values.url;

  const tooltipErrorMsg = useMemo(
    () => getTooltipErrorMsg(errors.title, errors.text, errors.link, errors.url, isDefault),
    [errors.title, errors.text, errors.link, errors.url, isDefault],
  );

  return (
    <Modal onClose={close}>
      <ModalHeader>Edit “What’s New?” Content</ModalHeader>
      <ModalDescription>{`This will replace ${getAppTitle()}’s newsfeed content on the home page.`}</ModalDescription>
      <ModalContent>
        <FieldWrapper>
          <RequiredLabel required htmlFor="title">
            Title:
          </RequiredLabel>
          <InputField
            placeholder={whatsNewData.title}
            value={values.title}
            errorMessage={errors.title}
            name="title"
            id="title"
            onChange={onChange}
            charactersLimit={65}
          />
        </FieldWrapper>
        <FieldWrapper>
          <RequiredLabel required htmlFor="text">
            Content preview text:
          </RequiredLabel>
          <InputField
            placeholder={whatsNewData.text}
            value={values.text}
            errorMessage={errors.text}
            name="text"
            id="text"
            onChange={onChange}
            charactersLimit={150}
          />
        </FieldWrapper>
        <FieldWrapper>
          <RequiredLabel required htmlFor="link">
            Link text:
          </RequiredLabel>
          <InputField
            placeholder={whatsNewData.link}
            value={values.link}
            errorMessage={errors.link}
            name="link"
            id="link"
            onChange={onChange}
            charactersLimit={50}
          />
        </FieldWrapper>
        <FieldWrapper>
          <RequiredLabel required htmlFor="url">
            URL:
          </RequiredLabel>
          <InputField
            placeholder={whatsNewData.url}
            value={values.url}
            errorMessage={errors.url}
            name="url"
            id="url"
            onChange={onChange}
          />
        </FieldWrapper>
      </ModalContent>
      <ModalFooter
        primaryDisabled={isSaveButtonDisabled}
        primaryTooltip={isSaveButtonDisabled ? tooltipErrorMsg : undefined}
        primaryLabel="Save"
        onPrimaryClick={handleOnConfirm}
        onCancel={close}
        primaryClassName="qa-save-btn"
      />
    </Modal>
  );
};

export default EditDataModal;

const InputField = styled(Input)`
  display: flex;

  & > span {
    font-size: 12px;
    left: 0;
  }
`;

const FieldWrapper = styled.div`
  margin-top: 20px;
`;
