/**
 * @fileoverview This file exports a set of hooks to interact with the feature flag map state in Recoil. This is preferred
 * over the imperative API because Recoil is reactive to changes while imperative code is not.
 */

import { selector, selectorFamily, useRecoilCallback, useRecoilValue, waitForAll } from 'recoil';
import type { FeatureFlagRecord, FeatureName, PartialFeatureFlagRecord } from '.';
import isNil from 'lodash/isNil';
import { featureDebugOverridesMap, featureMapState } from './state/recoilState';
import { UserFeature, AvailabilityStatus } from 'venn-api-client/public_api/misc/v1/feature_availability_pb';

/**
 * Returns true if the feature flag map has been initialized.
 */
export function useIsFFMapInitialized(): boolean {
  return useRecoilValue(featureMapIsInitializedSelector);
}

/**
 * Returns the entire feature flag map, thus observing changes to any feature flag in the entire map.
 *
 * Generally you shouldn't need this hook and can instead use the more specific hooks like {@link useFF} or {@link useHasFF}.
 */
export function useFFMap(): FeatureFlagRecord | undefined {
  return useRecoilValue(featureMapState);
}

/**
 * Returns the feature flag for the given feature name, observing changes only to that specific feature.
 */
export function useFF(featureName: FeatureName): UserFeature | undefined {
  return useRecoilValue(featureSelector(featureName));
}

/**
 * Returns the feature flag for the given feature name, observing changes only to that specific feature.
 */
export function useFFs(featureNames: FeatureName[]): (UserFeature | undefined)[] {
  return useRecoilValue(featuresSelector(featureNames));
}

/**
 * Returns true if the given feature flag is available.
 */
export function useHasFF(featureName: FeatureName): boolean {
  return useRecoilValue(hasFeatureSelector(featureName));
}

export function useHasDebugOverride(featureName: FeatureName): boolean {
  return useRecoilValue(featureHasOverrideSelector(featureName));
}

/**
 * Returns true if all of the given feature flags are available.
 */
export function useHasAllFFs(featureNames: FeatureName[]): boolean {
  return useRecoilValue(hasAllFeaturesSelector(featureNames));
}

/**
 * Returns true if any of the given feature flags are available.
 */
export function useHasAnyFF(featureNames: FeatureName[]): boolean {
  return useRecoilValue(hasAnyFeatureSelector(featureNames));
}

/**
 * Returns true if the feature threshold has already been reached.
 */
export function useFFHasReachedThreshold(featureName: FeatureName): boolean {
  return useRecoilValue(ffHasReachedThresholdSelector(featureName));
}

/**
 * Returns true if the threshold would be surpassed given the current usage count and proposed new usage count.
 */
export function useFFWillSurpassThreshold(featureName: FeatureName, newUsageCount: number): boolean {
  return useRecoilValue(ffWillSurpassThresholdSelector({ featureName, newUsageCount }));
}

/**
 * Returns the entire feature object with usage count and threshold info
 * or undefined if the feature is completely unavailable.
 */
export function useFFDetails(featureName: FeatureName): UserFeature | undefined {
  return useRecoilValue(ffDetailsSelector(featureName));
}

export function useFFDebugOverridesMap(): PartialFeatureFlagRecord {
  return useRecoilValue(featureDebugOverridesMap);
}

/** Resets feature flag debug overrides to having zero overrides. */
export function useClearFFDebugOverridesCallback() {
  return useRecoilCallback(
    ({ reset, set }) =>
      () => {
        set(featureDebugOverridesMap, {});
        reset(featureDebugOverridesMap);
      },
    [],
  );
}

/** Feature flag calculations are very cheap and the hit rate is low (because it depends on the FF map which is highly variable), so no need to add caching. */
const FF_CACHE_POLICY = { eviction: 'most-recent' } as const;

const featureSelector = selectorFamily<UserFeature | undefined, FeatureName>({
  key: 'featureSelector',
  get:
    (featureName) =>
    ({ get }) =>
      get(featureMapState)?.[featureName],
  cachePolicy_UNSTABLE: FF_CACHE_POLICY,
});

const featuresSelector = selectorFamily<(UserFeature | undefined)[], FeatureName[]>({
  key: 'featuresSelector',
  get:
    (featureNames) =>
    ({ get }) =>
      get(waitForAll(featureNames.map((featureName) => featureSelector(featureName)))),
  cachePolicy_UNSTABLE: FF_CACHE_POLICY,
});

export const hasFeatureSelector = selectorFamily<boolean, FeatureName>({
  key: 'featureIsAvailableSelector',
  get:
    (featureName) =>
    ({ get }) =>
      get(featureSelector(featureName))?.status === AvailabilityStatus.AVAILABLE,
  cachePolicy_UNSTABLE: FF_CACHE_POLICY,
});

const hasAnyFeatureSelector = selectorFamily<boolean, FeatureName[]>({
  key: 'hasAnyFeatureSelector',
  get:
    (featureNames) =>
    ({ get }) =>
      get(featuresSelector(featureNames)).some((value) => value?.status === AvailabilityStatus.AVAILABLE),
  cachePolicy_UNSTABLE: FF_CACHE_POLICY,
});

const hasAllFeaturesSelector = selectorFamily<boolean, FeatureName[]>({
  key: 'hasAllFeaturesSelector',
  get:
    (featureNames) =>
    ({ get }) =>
      get(featuresSelector(featureNames)).every((value) => value?.status === AvailabilityStatus.AVAILABLE),
  cachePolicy_UNSTABLE: FF_CACHE_POLICY,
});

const featureMapIsInitializedSelector = selector<boolean>({
  key: 'featureMapIsInitializedSelector',
  get: ({ get }) => !!get(featureMapState),
  cachePolicy_UNSTABLE: FF_CACHE_POLICY,
});

const featureHasOverrideSelector = selectorFamily<boolean, FeatureName>({
  key: 'featureHasOverrideSelector',
  get:
    (featureName) =>
    ({ get }) =>
      !!get(featureDebugOverridesMap)[featureName],
  cachePolicy_UNSTABLE: FF_CACHE_POLICY,
});

const ffHasReachedThresholdSelector = selectorFamily<boolean, FeatureName>({
  key: 'ffHasReachedThresholdSelector',
  get:
    (featureName) =>
    ({ get }) => {
      const feature = get(featureSelector(featureName));
      return feature?.limitReached ?? true;
    },
  cachePolicy_UNSTABLE: FF_CACHE_POLICY,
});

const ffWillSurpassThresholdSelector = selectorFamily<boolean, { featureName: FeatureName; newUsageCount: number }>({
  key: 'ffWillSurpassThresholdSelector',
  get:
    ({ featureName, newUsageCount }) =>
    ({ get }) => {
      const hasReachedThreshold = get(ffHasReachedThresholdSelector(featureName));
      const featureFlag = get(featureSelector(featureName));
      if (hasReachedThreshold && newUsageCount > 0) {
        return true;
      }

      if (isNil(featureFlag)) {
        return true;
      }

      if (isNil(featureFlag.threshold) || isNil(featureFlag.usageCount)) {
        return false;
      }

      return featureFlag.usageCount + newUsageCount > featureFlag.threshold.count;
    },
  cachePolicy_UNSTABLE: FF_CACHE_POLICY,
});

const ffDetailsSelector = selectorFamily<UserFeature | undefined, FeatureName>({
  key: 'ffDetailsSelector',
  get:
    (featureName) =>
    ({ get }) => {
      const feature = get(featureSelector(featureName));

      return feature
        ? new UserFeature({
            ...feature,
            usageCount: feature.usageCount ?? 0,
          })
        : undefined;
    },
  cachePolicy_UNSTABLE: FF_CACHE_POLICY,
});
