import type { ActionEnum, Context, TreeActionableContext } from 'venn-api';
import type { DropMenuItem } from 'venn-ui-kit';

const GLOBAL_LABEL = 'Global';

export const getContextMenuItems = (
  contextTree?: TreeActionableContext[],
  currentContext?: string,
  requiredAction?: ActionEnum,
): DropMenuItem<string>[] => {
  const availableContextItems = getAvailableContextItems(contextTree ?? []);

  if (requiredAction) {
    const hasRequiredPermissions = new Set<string>();
    (contextTree ?? []).forEach((context) =>
      findHasRequiredPermissions(context, requiredAction, hasRequiredPermissions, currentContext),
    );
    return availableContextItems.filter((item) => hasRequiredPermissions.has(item.value) || item.label === '');
  }
  return availableContextItems;
};

const getAvailableContextItems = (contextTree: TreeActionableContext[]) => {
  return (contextTree ?? [])
    .flatMap((tree) => flattenContextTree(tree, 0))
    .map((item) => ({
      value: item.context.id,
      label: item.context.name,
      level: item.level,
    }));
};

export const getContextNameById = (contextTree: TreeActionableContext[], contextId: string): string | undefined => {
  const availableContextItems = getAvailableContextItems(contextTree);
  const contextItem = availableContextItems.find((item) => item.value === contextId);
  return contextItem?.label;
};

export const flattenContextTree = (
  root: TreeActionableContext,
  level: number,
): { context: Context; level: number }[] => {
  return [
    {
      context: root.value.context,
      level,
    },
    ...root.children.flatMap((child) => flattenContextTree(child, level + 1)),
  ];
};

const findHasRequiredPermissions = (
  root: TreeActionableContext,
  requiredAction: ActionEnum,
  hasRequiredPermissions: Set<string>,
  currentContext?: string,
): boolean => {
  let isRootOnPath = root.value.actionEnums.includes(requiredAction);

  if (currentContext !== root.value.context.id && GLOBAL_LABEL !== root.value.context.name) {
    isRootOnPath =
      isRootOnPath &&
      root.children
        .map((child) => findHasRequiredPermissions(child, requiredAction, hasRequiredPermissions, currentContext))
        .reduce((a, b) => a || b, false);
  }

  if (isRootOnPath) {
    hasRequiredPermissions.add(root.value.context.id);
  }

  return isRootOnPath;
};
