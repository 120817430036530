import type { AnalysisPortfolioComparisonTypeEnum, Portfolio } from 'venn-api';
import type { AnalysisSubjectSecondaryLabel } from '../analysis';
import Dates from '../dates';
import { isNil } from 'lodash';
import type { StudioRequestSubject } from 'venn-state';

const getMasterString = (name: string, strategyName: string | undefined): string =>
  `Master Portfolio (${name})${strategyName ? `: ${strategyName}` : ''}`;
const getUpdatedDateString = (updated: number): string => Dates.toDayMonthYearTime(updated).replace(',', ' |');

const getSecondaryLabelFromPortfolioComparisonTypeEnum = (
  compareType?: AnalysisPortfolioComparisonTypeEnum,
): AnalysisSubjectSecondaryLabel => {
  switch (compareType) {
    case 'MASTER':
      return 'Master';
    case 'SAVED':
      return 'Last Saved';
    case 'OPTIMIZED':
      return 'Optimized';
    case 'NONE':
    default:
      return undefined;
  }
};

/**
 * Returns a "short" comparison label:
 *  - for Master: `Master Portfolio (<name>): <optional strategy name>`
 *  - for Saved:
 *     - for the latest saved version: `<name>: <optional strategy name>`
 *     - for a historical version: formatted saved date/time, i.e. `26 Jul 2021 | 10:45:56 PM`
 */
export const getShortComparisonLabel = (
  secondaryPortfolio: Portfolio,
  secondaryStrategy: Portfolio | undefined,
  portfolioComparisonType: AnalysisPortfolioComparisonTypeEnum,
  primaryPortfolio: Portfolio | undefined,
): string => {
  const secondaryLabel = getSecondaryLabelFromPortfolioComparisonTypeEnum(portfolioComparisonType);
  const strategyName =
    secondaryStrategy && secondaryStrategy.id !== secondaryPortfolio.id ? secondaryStrategy.name : undefined;
  if (secondaryLabel === 'Master') {
    return getMasterString(secondaryPortfolio.name, strategyName);
  }
  if (secondaryLabel === 'Last Saved' && !isNil(secondaryPortfolio.updated)) {
    return !isNil(primaryPortfolio) && primaryPortfolio.version === secondaryPortfolio.version
      ? `${secondaryPortfolio.name}${strategyName ? `: ${strategyName}` : ''}`
      : getUpdatedDateString(secondaryPortfolio.updated);
  }
  return secondaryPortfolio.draft ? '' : secondaryLabel ?? '';
};

/**
 * Returns contents of a tooltip to go with the "short" label from `getShortComparisonLabel()`, meant to provide
 * additional context for the case of a historically saved version (that only has the date/time in the "short" label):
 *  - for the latest saved version: `Last Saved Allocations for <name>`
 *  - for a historical version: `Historical Allocations for <name>`
 *
 *  Note that we're using the primary portfolio's name, since a historical version might have since been renamed.
 */
export const getComparisonLabelForTooltip = (
  secondaryPortfolio: Portfolio,
  secondaryStrategy: Portfolio | undefined,
  portfolioComparisonType: AnalysisPortfolioComparisonTypeEnum,
  primaryPortfolio: Portfolio,
): string => {
  const secondaryLabel = getSecondaryLabelFromPortfolioComparisonTypeEnum(portfolioComparisonType);
  const strategyName =
    secondaryStrategy && secondaryStrategy.id !== secondaryPortfolio.id ? secondaryStrategy.name : undefined;
  if (secondaryLabel === 'Last Saved') {
    return primaryPortfolio.version === secondaryPortfolio.version
      ? `Last Saved Allocations for ${primaryPortfolio.name}${strategyName ? `: ${strategyName}` : ''}`
      : `Historical Allocations for ${primaryPortfolio.name}${strategyName ? `: ${strategyName}` : ''}`;
  }
  return `${secondaryLabel} Portfolio${strategyName ? `: ${strategyName}` : ''}`;
};

/**
 * Returns a "long" comparison label for places like the block legend, where you want to give maximum context without
 * tooltips and such.
 *  - for Master: `Master Portfolio (<name>)`
 *  - for Saved:
 *     - for the latest saved version: `<name> (Last Saved)`
 *     - for a historical version: i.e. `<name> (Historical - as of 26 Jul 2021 | 10:45:56 PM)`
 */
export const getComparisonLabelForBlockLegend = (subject: StudioRequestSubject): string => {
  const { portfolioComparisonType, portfolio, primaryPortfolio, strategy } = subject;
  if (isNil(portfolioComparisonType) || isNil(portfolio) || isNil(primaryPortfolio)) {
    if (portfolio && strategy && portfolio.id !== strategy.id) {
      return `${portfolio.name}: ${strategy.name}`;
    }
    return subject.name;
  }

  const strategyString =
    !isNil(portfolio) && !isNil(strategy) && portfolio.id !== strategy.id ? `: ${strategy.name}` : '';

  if (portfolioComparisonType === 'MASTER') {
    return getMasterString(subject.name, strategy?.name);
  }
  if (portfolioComparisonType === 'SAVED' && !isNil(portfolio.updated)) {
    return `${
      primaryPortfolio.version === portfolio.version
        ? `${primaryPortfolio.name} (Last Saved)`
        : `${primaryPortfolio.name} (Historical - as of ${getUpdatedDateString(portfolio.updated)})`
    }${strategyString}`;
  }
  return `${portfolio.name} (${getSecondaryLabelFromPortfolioComparisonTypeEnum(
    portfolioComparisonType,
  )})${strategyString}`;
};
