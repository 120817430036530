/**
 * Returns the subjects and metrics needed for returns grid rows and columns to be
 * generated, or undefined if no metric is set.
 */

import type { CustomizedBlock, Analysis } from 'venn-api';
import { extractMetricReturnsFrequency } from 'venn-utils';
import type { CustomizableMetric } from 'venn-utils';

export type ReturnsGridProps = {
  subjectIndex: number;
  matchingAnalysis: Analysis;
  returnsFrequency: 'MONTHLY' | 'QUARTERLY' | 'YEARLY';
  selectedMetric: CustomizableMetric;
};

export type ReturnsGridError = 'NO_METRIC' | 'NO_ANALYSIS';

/** Returns the extra props needed for returns grid to render, or undefined there is no valid returns grid metric or analysis. */
export const returnsGridParserHelper = ({
  metrics,
  selectedBlock,
  analysesGroup,
}: {
  metrics: CustomizableMetric[];
  selectedBlock: CustomizedBlock;
  analysesGroup: (Analysis | undefined)[][] | undefined;
}): ReturnsGridProps | ReturnsGridError => {
  /** Currently only a single selectedMetric is supported. */
  const selectedMetricKey = selectedBlock.selectedMetrics?.[0];
  const selectedMetric = selectedMetricKey && metrics.find((m) => m.key === selectedMetricKey);
  if (!selectedMetric) {
    return 'NO_METRIC';
  }

  /** Only a single subject (the first subject) is used. */
  const SUBJECT_INDEX = 0;
  const subjectAnalyses = analysesGroup?.[SUBJECT_INDEX];
  const matchingAnalysis = subjectAnalyses?.find((a) => a?.analysisType === selectedMetric.analysisType);
  if (!matchingAnalysis) {
    return 'NO_ANALYSIS';
  }

  const isRelative = selectedBlock.relativeToBenchmark;
  /** Path example: 'returnsGridMap.MONTHLY.returnCells'. */
  const returnsFrequency = extractMetricReturnsFrequency(isRelative, selectedMetric);

  return { subjectIndex: SUBJECT_INDEX, matchingAnalysis, returnsFrequency, selectedMetric };
};
