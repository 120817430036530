import { useRef, useLayoutEffect } from 'react';

const useIsMounted = () => {
  const isMountedRef = useRef<boolean>(false);
  useLayoutEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return isMountedRef;
};

export default useIsMounted;
