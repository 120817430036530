import React, { useContext } from 'react';
import InputTitle from './InputTitle';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import {
  getNewDateRangeInputId,
  dateRangeInputNameState,
  nextDateRangeInputNameState,
  dateRangeInputsState,
  dateRangeInputConsistencyState,
  DEFAULT_CONSISTENCY,
  dateRangeInputDateRangeState,
  isReportState,
  analysisViewIdState,
} from 'venn-state';
import { DateRangeInputRow } from './DateRangeInputRow';
import { UserContext } from 'venn-components';
import { analyticsService } from 'venn-utils';

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
const DateRangesSection = React.memo(() => {
  const { hasPermission } = useContext(UserContext);
  const readonly = !hasPermission('STUDIO_EDIT');
  const isReport = useRecoilValue(isReportState);
  const viewId = useRecoilValue(analysisViewIdState);
  const dateRangeIds = useRecoilValue(dateRangeInputsState);

  const onAddNew = useRecoilCallback(
    ({ set, snapshot }) =>
      () => {
        const newId = getNewDateRangeInputId();
        const newNameLoadable = snapshot.getLoadable(nextDateRangeInputNameState).valueOrThrow();

        analyticsService.inputsDateRangeGroupCreated({
          name: newNameLoadable,
          consistency: DEFAULT_CONSISTENCY,
          range: 'FULL_HISTORY',
          sourcePage: isReport ? 'REPORT_LAB' : 'STUDIO',
          viewId,
        });

        // TODO(collin.irwin): IMV2 extract this callback to date range input venn-state file so it can be used in multiple places
        // and is more likely to stay up to date
        set(dateRangeInputNameState(newId), newNameLoadable);
        set(dateRangeInputConsistencyState(newId), DEFAULT_CONSISTENCY);
        set(dateRangeInputDateRangeState(newId), { period: 'full' });
        set(dateRangeInputsState, (existingIds) => [...existingIds, newId]);
      },
    [isReport, viewId],
  );

  return (
    <>
      <InputTitle title="Date Ranges" onAddNew={onAddNew} numberOfInputs={dateRangeIds.length} />
      {dateRangeIds.map((id) => (
        <DateRangeInputRow
          key={id}
          inputId={id}
          readonly={readonly}
          isPrivate={false}
          deletable={dateRangeIds.length > 1}
        />
      ))}
    </>
  );
});

export default DateRangesSection;
