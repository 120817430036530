const userSettings = {
  system: {
    'features.optimization.enabled': true,
    'features.drawdown.enabled': true,
    'features.translation.enabled': true,
  },
  user: { 'analysis.investment.default': 'tearsheet', 'analysis.portfolio.default': 'performance' },
};

export default userSettings;
