/*
 * Venn Platform API 1.0.0 Operations for "fundtags"
 * Automatically generated file - do not modify!
 */

import {} from '../types';
import type { OperationResult } from '../../utils';
import { buildRequestInit, extractError, retryGeneratedFetch } from '../../utils';

/** Get the tags for the given fund */
export async function getFundTagsOld(id: string, signal?: AbortSignal): Promise<OperationResult<string[]>> {
  const endpoint = `/api/fundtags/fund/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: string[] = (await response.json()) as string[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get the tags for the given portfolio */
export async function getPortfolioTagsOld(id: number, signal?: AbortSignal): Promise<OperationResult<string[]>> {
  const endpoint = `/api/fundtags/portfolio/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: string[] = (await response.json()) as string[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Gets a list of all tags a user has */
export async function getAllTagsOld(signal?: AbortSignal): Promise<OperationResult<string[]>> {
  const endpoint = '/api/fundtags';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: string[] = (await response.json()) as string[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
