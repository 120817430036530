import React, { useContext } from 'react';
import type { AnalysisViewSearchResultWithUIState, StudioTemplateState } from 'venn-utils';
import {
  analyticsService,
  buttonize,
  getBlankStudio,
  navigateToReportingItemType,
  navigateToReportLab,
  navigateToStudioView,
  ReportingStyleEnum,
  useHasFF,
  useRecentStudio,
  assertNotNil,
  navigateToSavedView,
  isReport,
} from 'venn-utils';
import {
  PortfoliosContext,
  StudioPrintSettingsContext,
  UserContext,
  useSavedViewListWithExtraName,
} from 'venn-components';
import styled, { css } from 'styled-components';
import {
  ColorUtils,
  ExternalActivityListener,
  getAppTitle,
  GetColor,
  Headline3,
  Icon,
  ItemType,
  ItemTypePillWithIcon,
  ShimmerBlock,
  ZIndex,
} from 'venn-ui-kit';
import { useHistory } from 'react-router-dom';
import type { AnalysisView } from 'venn-api';
import { times } from 'lodash';
import type { History } from 'history';

interface StudioSlideoutMenuProps {
  isReport: boolean;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

const onClickItem = (history: History<unknown>, document: StudioTemplateState, reportType: ReportingStyleEnum) => {
  const documentType = assertNotNil(document.newDocument.analysisViewType);
  const isReportView = isReport(documentType);

  analyticsService.creatingNewStudios({
    source: `${isReportView ? 'Report lab' : 'studio'} sideout menu`,
    name: `blank ${reportType.toLowerCase()}`,
  });

  switch (documentType) {
    case 'TEARSHEET':
      navigateToSavedView(history, document.newDocument);
      return;
    case 'REPORT':
      navigateToSavedView(history, document.newDocument);
      return;
    case 'TEARSHEET_TEMPLATE':
      navigateToStudioView(history, document);
      return;
    case 'REPORT_TEMPLATE':
      navigateToReportLab(history, document);
      return;
    default:
      throw new Error(`Unhandled document type: ${documentType}`);
  }
};

const SlideoutMenu = React.memo(function SlideoutMenu({ isOpen, setIsOpen, isReport }: StudioSlideoutMenuProps) {
  const hasContextSwitching = useHasFF('context_switching');
  const history = useHistory();

  const closeSlideoutMenu = () => {
    setIsOpen(false);
  };

  const renderView = (item: AnalysisViewSearchResultWithUIState, purpose: string) => {
    const onSelectView = (purpose: string) => {
      analyticsService.ctaClicked({
        purpose,
        locationOnPage: 'studio sideout menu',
      });
      closeSlideoutMenu();
    };

    return (
      <StudioLinkContainer key={item.id}>
        <StyledLink
          {...buttonize(() => {
            onSelectView(purpose);
            onClickItem(
              history,
              {
                id: item.id,
                newDocument: {
                  id: item.id,
                  analysisViewType: item.analysisViewType,
                } as AnalysisView,
              },
              isReport ? ReportingStyleEnum.REPORT : ReportingStyleEnum.TEARSHEET,
            );
          })}
        >
          <Name>{item.name}</Name>
          {hasContextSwitching && `Ownership: ${item.ownershipName}`}
        </StyledLink>
      </StudioLinkContainer>
    );
  };

  const onViewMoreTemplates = (report: boolean) => {
    analyticsService.ctaClicked({
      purpose: 'view more templates',
      locationOnPage: report ? 'report slideout menu' : 'studio sideout menu',
      destination: 'reporting page',
    });
    navigateToReportingItemType(history, report ? ItemType.ReportTemplate : ItemType.TearsheetTemplate);
    closeSlideoutMenu();
  };

  return (
    <StyledExternalActivityListener
      open={isOpen}
      onExternalActivity={() => setIsOpen(false)}
      ignoreFocusEvents
      listeningEnabled={isOpen}
    >
      {isOpen && (
        <>
          <MenuGroup>
            <SectionHeader>
              <SpacedFlex>
                <Headline3 inverted>Templates</Headline3>
                <ItemTypePillWithIcon itemType={isReport ? ItemType.ReportTemplate : ItemType.TearsheetTemplate} />
              </SpacedFlex>
              <TemplateButton {...buttonize(() => onViewMoreTemplates(isReport))}>
                <TemplateLabel>
                  See more templates <Icon type="angle-right" />
                </TemplateLabel>
              </TemplateButton>
            </SectionHeader>

            <TemplateGroups>
              <TemplateGroup>
                <VennTemplatesSection isReport={isReport} />
              </TemplateGroup>
              <TemplateGroup>
                <OrgCreatedTemplatesSection isReport={isReport} renderView={renderView} />
              </TemplateGroup>
            </TemplateGroups>
          </MenuGroup>

          <MenuDivider />

          <MenuGroup>
            <LastSavedViews isReport={isReport} closeSlideoutMenu={closeSlideoutMenu} renderView={renderView} />
          </MenuGroup>

          <MenuDivider />

          <MenuGroup isLast>
            <SlideoutFooter isReport={isReport} onClose={closeSlideoutMenu} />
          </MenuGroup>
        </>
      )}
    </StyledExternalActivityListener>
  );
});

const MenuGroup = styled.div<{ isLast?: boolean }>`
  padding-bottom: ${({ isLast }) => (isLast ? '10px' : '24px')};
  margin: 16px 30px 0;
`;

const TemplateButton = styled.div`
  cursor: pointer;
  margin-bottom: 12px;
  &:hover {
    color: ${GetColor.Primary.Main};
  }
`;

const CreateNewButton = styled.div`
  cursor: pointer;
  margin-bottom: 12px;
  &:hover {
    color: ${GetColor.Primary.Main};
  }
  text-align: right;
`;

const SpacedFlex = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 5px;
`;

const TemplateGroups = styled.div`
  display: flex;
  column-gap: 21px;
`;

const TemplateGroup = styled.div`
  min-width: 100px;
`;

const SectionHeader = styled(SpacedFlex)`
  margin-top: 16px;
  margin-bottom: 16px;
  column-gap: 24px;
`;

const TemplateTypeLabel = styled.div`
  color: ${GetColor.GreyScale.Grey50};
  font-weight: bold;
`;

const TemplateHeader = styled.span`
  font-size: 20px;
  font-weight: bold;
`;

const TemplateLabel = styled.div`
  font-size: 14px;
  font-weight: bold;
`;

const StyledExternalActivityListener = styled(ExternalActivityListener)<{ open: boolean }>`
  font-size: 1rem;
  position: fixed;
  color: ${GetColor.White};
  border-radius: 12px;
  background-color: ${GetColor.NavigationBarColor.Active};
  border-left: 1px solid ${GetColor.NavigationBarColor.ActiveLighter};
  left: 70px;
  top: 70px;
  z-index: ${ZIndex.NavigationSlideout};

  max-width: 690px;

  transform: ${({ open }) => (open ? 'translateX(10px)' : 'translateX(-100%)')};
  transition: transform 0.3s ease-in-out;
  line-height: 24px;

  ${(props) =>
    props.open &&
    css`
      box-shadow: 4px 0px 4px ${ColorUtils.opacifyFrom(GetColor.Black, 0.25)};
    `}

  @media print {
    display: none;
  }
`;

const StyledLink = styled.div`
  cursor: pointer;
  transition: color 0.15s linear 0s;
  color: ${GetColor.White};

  &:hover {
    color: ${GetColor.Primary.Main};
  }
`;

const RecentItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: self-start;

  a {
    color: ${GetColor.White};
    font-size: 14px;
    line-height: 32px;
    font-weight: bold;

    :hover {
      color: ${GetColor.Primary.Main};
    }
  }
`;

const StudioLinkContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`;

const Name = styled.div`
  font-weight: bold;
  font-size: 14px;
  margin-top: 3px;
`;

const MenuDivider = styled.div`
  height: 1px;
  background-color: ${GetColor.GreyScale.Grey70};
`;

export default SlideoutMenu;

function OrgCreatedTemplatesSection({
  isReport,
  renderView,
}: {
  isReport: boolean;
  renderView: (item: AnalysisViewSearchResultWithUIState, purpose: string) => JSX.Element;
}) {
  const maxOrgCreatedTemplates = 3;
  const { studioItems: orgsTemplates, isLoading } = useRecentStudio({
    includeTypes: isReport ? ['REPORT_TEMPLATE'] : ['TEARSHEET_TEMPLATE'],
    vennTemplate: false,
    excludeVennCreated: true,
    maxSize: maxOrgCreatedTemplates,
  });
  const { resultsWithTemplates: orgsTemplatesWithOwnership } = useSavedViewListWithExtraName(orgsTemplates);

  return (
    <>
      <TemplateTypeLabel>Organization created</TemplateTypeLabel>

      {isLoading && times(maxOrgCreatedTemplates, (num) => <ViewShimmer key={num} width="300px" />)}
      {!isLoading && orgsTemplatesWithOwnership.map((template) => renderView(template, 'open orgs template'))}
      {!isLoading && !orgsTemplatesWithOwnership.length && 'No organization created templates'}
    </>
  );
}

function VennTemplatesSection({ isReport }: { isReport: boolean }) {
  const history = useHistory();
  const maxVennTemplates = 3;
  const { studioItems: vennTemplates, isLoading } = useRecentStudio({
    includeTypes: isReport ? ['REPORT_TEMPLATE'] : ['TEARSHEET_TEMPLATE'],
    vennTemplate: true,
    maxSize: maxVennTemplates,
  });

  return (
    <>
      <TemplateTypeLabel>{`${getAppTitle()} created`}</TemplateTypeLabel>
      {isLoading && times(maxVennTemplates, (num) => <ViewShimmer key={num} width="250px" />)}

      {!isLoading &&
        vennTemplates.map((template) => (
          <TemplateButton
            key={template.name}
            {...buttonize(() => {
              onClickItem(
                history,
                {
                  id: template.id,
                  newDocument: {
                    id: template.id,
                    analysisViewType: isReport ? ReportingStyleEnum.REPORT : ReportingStyleEnum.TEARSHEET,
                  } as AnalysisView,
                },
                isReport ? ReportingStyleEnum.REPORT : ReportingStyleEnum.TEARSHEET,
              );
            })}
          >
            <TemplateLabel>{template.name}</TemplateLabel>
            {template.description}
          </TemplateButton>
        ))}

      {!isLoading && !vennTemplates.length && 'No Venn created templates'}
    </>
  );
}

function LastSavedViews({
  isReport,
  closeSlideoutMenu,
  renderView,
}: {
  isReport: boolean;
  closeSlideoutMenu: () => void;
  renderView: (item: AnalysisViewSearchResultWithUIState, purpose: string) => JSX.Element;
}) {
  const history = useHistory();
  const maxSavedViews = 1;

  const { studioItems, isLoading } = useRecentStudio({
    includeTypes: isReport ? ['REPORT'] : ['TEARSHEET'],
    vennTemplate: false,
    excludeVennCreated: false,
    maxSize: maxSavedViews,
  });
  const { resultsWithTemplates: lastSavedViews } = useSavedViewListWithExtraName(studioItems);

  const onViewMoreViews = (report: boolean) => {
    analyticsService.ctaClicked({
      purpose: 'view more saved views',
      locationOnPage: report ? 'report slideout menu' : 'studio sideout menu',
      destination: 'reporting page',
    });
    navigateToReportingItemType(history, report ? ItemType.StudioReport : ItemType.StudioTearsheet);
    closeSlideoutMenu();
  };

  return (
    <>
      <SectionHeader>
        <TemplateHeader>Last Saved {isReport ? 'Report Lab' : 'Studio'} View</TemplateHeader>
        <TemplateButton {...buttonize(() => onViewMoreViews(isReport))}>
          <TemplateLabel>
            See more saved views <Icon type="angle-right" />
          </TemplateLabel>
        </TemplateButton>
      </SectionHeader>

      <RecentItems>
        {isLoading && times(maxSavedViews, (num) => <ViewShimmer key={num} width="100%" />)}
        {!isLoading && lastSavedViews.map((item) => renderView(item, 'open last saved view'))}
        {!isLoading && !lastSavedViews.length && 'No recently saved views'}
      </RecentItems>
    </>
  );
}

function SlideoutFooter({ isReport, onClose }: { isReport: boolean; onClose: () => void }) {
  const history = useHistory();
  const { demoPortfolio } = useContext(PortfoliosContext);
  const { currentContext, hasPermissionForResource } = useContext(UserContext);
  const { addDisclosurePage } = useContext(StudioPrintSettingsContext);

  const handleBlankStudio = (reportType: ReportingStyleEnum) => {
    const newDocument = getBlankStudio(
      reportType,
      addDisclosurePage,
      [{ portfolioId: demoPortfolio?.id }],
      currentContext,
    );
    onClickItem(history, { newDocument }, reportType);
  };

  return (
    <>
      {hasPermissionForResource('STUDIO_INSERT_BLOCKS', { ownerContextId: currentContext }) && (
        <CreateNewButton
          onClick={() => {
            handleBlankStudio(isReport ? ReportingStyleEnum.REPORT_TEMPLATE : ReportingStyleEnum.TEARSHEET_TEMPLATE);
            onClose();
          }}
          className={isReport ? 'qa-new-report-button' : 'qa-new-studio-button'}
        >
          <TemplateLabel>
            {isReport ? 'Create New Report' : 'Create New Studio'} <Icon type="angle-right" />
          </TemplateLabel>
        </CreateNewButton>
      )}
    </>
  );
}

function ViewShimmer({ width }: { width: string }) {
  return (
    <ShimmerBlock
      height={27}
      width={width}
      margin={10}
      backgroundColor={GetColor.NavigationBarColor.ShimmerBackground} // This color shows during the shimmer on the slideout
      shimmerColor={GetColor.NavigationBarColor.Active}
      duration={5}
    />
  );
}
