import { useMemo } from 'react';
import type { VennColors } from 'venn-ui-kit';
import type { Analysis, CustomizedBlock } from 'venn-api';
import type { AnalysisResponseParser, AnalysisRequest } from '../types';
import { BENCHMARK_KEY, AVAILABILITY_KEY, FREQUENCY_KEY } from '../customAnalysisContants';
import { compact } from 'lodash';
import { getAvailableRangeValue, getMaxFrequencyValue, getBenchmarkNameRangeMap } from './customBlockUtils';
import { getRangeMapKeyFromRequest, useRequestsRangeMap } from './useRequestsRangeMap';
import type { CustomBlockTypeEnum } from 'venn-utils';
import { useTheme } from 'styled-components';
import { useAgGridStyle } from '../components/grid/AgGridThemeOverrides';
import { useRecoilValue } from 'recoil';
import { blockBenchmarkType, blockSettings } from 'venn-state';
import { useBlockId } from '../contexts/BlockIdContext';
import { useAppPrintMode } from '../../print/AppPrintMode';

export const useMetaRows = (requests: AnalysisRequest[]) => {
  const blockId = useBlockId();
  const blockSetting = useRecoilValue(blockSettings(blockId));
  const customBlockType = blockSetting.customBlockType;
  const benchmarkType = useRecoilValue(blockBenchmarkType(blockId));
  const rangeMap = useRequestsRangeMap(requests);
  const isPrinting = useAppPrintMode().inPrintModeOrReportLab;

  return useMemo(() => {
    const subjectIds = requests.map(getRangeMapKeyFromRequest);

    if (isPrinting || customBlockType === 'SCENARIO') {
      return [];
    }

    return compact([
      {
        key: AVAILABILITY_KEY,
        label: AVAILABILITY_KEY,
        value: getAvailableRangeValue(rangeMap, subjectIds),
        isMetadata: true,
      },
      {
        key: FREQUENCY_KEY,
        label: FREQUENCY_KEY,
        value: getMaxFrequencyValue(rangeMap, subjectIds),
        isMetadata: true,
      },
      benchmarkType === 'COMMON' || benchmarkType === 'INDIVIDUAL'
        ? {
            key: BENCHMARK_KEY,
            label: BENCHMARK_KEY,
            value: requests.map((request) => request.benchmark?.name),
            customBenchmarkType: benchmarkType,
            benchmarkNameRangeMap: getBenchmarkNameRangeMap(rangeMap, requests),
            isMetadata: true,
          }
        : undefined,
    ]);
  }, [requests, isPrinting, customBlockType, rangeMap, benchmarkType]);
};

const useAnalysisGridRowData = (
  requests: AnalysisRequest[],
  responseParser: AnalysisResponseParser,
  analysesGroup: (Analysis | undefined)[][],
  selectedBlock: CustomizedBlock,
) => {
  const metaRows = useMetaRows(requests);

  const rowData = useMemo(() => {
    const rows = responseParser.parser(selectedBlock, analysesGroup);
    if (rows.length === 0) {
      return metaRows;
    }

    return [...metaRows, ...rows];
  }, [responseParser, selectedBlock, analysesGroup, metaRows]);

  return rowData;
};

const useAnalysisGrid = (
  requests: AnalysisRequest[],
  responseParser: AnalysisResponseParser,
  Colors: VennColors,
  analysesGroup: (Analysis | undefined)[][],
  selectedBlock: CustomizedBlock,
  customBlockType: CustomBlockTypeEnum,
) => {
  const theme = useTheme();

  const rowData = useAnalysisGridRowData(requests, responseParser, analysesGroup, selectedBlock);

  const metricsSettings = responseParser.metricsSettings;
  const gridStyle = useAgGridStyle();
  const columnDefs = useMemo(() => {
    return responseParser.columnParser({
      customBlockType,
      requests,
      analysesGroup,
      metricsSettings,
      theme,
      selectedBlock,
      gridStyle,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    requests,
    analysesGroup,
    metricsSettings,
    Colors,
    selectedBlock?.contributionToPercentage,
    selectedBlock?.selectedMetrics,
    selectedBlock?.selectedFactors,
    gridStyle,
    theme,
  ]);

  return {
    rowData,
    columnDefs,
  };
};

export default useAnalysisGrid;
