import React from 'react';
import type { PrivatePortfolioNode } from 'venn-api';
import { useHasFF, useModal } from 'venn-utils';
import CreatePortfolioModal from './CreatePortfolioModal';
import type { CreatePortfolioModalContentWithoutRefreshProps } from './CreatePortfolioModalContent';
import CreatePortfolioModalWithPrivates from './CreatePortfolioModalWithPrivates';

interface CreatePortfolioModalWrapperProps extends CreatePortfolioModalContentWithoutRefreshProps {
  onPrivatePortfolioCreated: (portfolio: PrivatePortfolioNode) => void;
}

const CreatePortfolioModalWrapper = ({ onPrivatePortfolioCreated, ...rest }: CreatePortfolioModalWrapperProps) => {
  const userHasPrivates = useHasFF('private_analytics');
  const [isProFormaModalOpen, openProFormaModal] = useModal();

  if (!userHasPrivates || isProFormaModalOpen) {
    return <CreatePortfolioModal {...rest} />;
  }

  return (
    <CreatePortfolioModalWithPrivates
      onClose={rest.onClose}
      openProFormaModal={openProFormaModal}
      onPrivatePortfolioCreated={onPrivatePortfolioCreated}
    />
  );
};

export default CreatePortfolioModalWrapper;
