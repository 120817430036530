import React, { useCallback } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';
import { Routes } from 'venn-utils';
import FactorSummary from './FactorSummary';
import { PageHeader } from '../shared/page-header';
import { DisplayButton } from '../shared/display-button';

type HomepageProps = RouteComponentProps;

const Homepage: React.FC<React.PropsWithChildren<HomepageProps>> = ({ history }) => {
  const navigateToBasics = useCallback(() => {
    history.push(Routes.FACTOR_LENS_BASICS);
  }, [history]);
  const navigateToInsights = useCallback(() => {
    history.push(Routes.FACTOR_LENS_INSIGHTS);
  }, [history]);
  const navigateToDeepDive = useCallback(
    (factorId?: number) => {
      const targetUrl = factorId ? `${Routes.FACTOR_LENS_DEEP_DIVE}/${factorId}` : Routes.FACTOR_LENS_DEEP_DIVE;
      history.push(targetUrl);
    },
    [history],
  );

  return (
    <PageContent>
      <PageHeader title="Two Sigma Factor Lens" />
      <Container>
        <Row>
          <Button
            title="Factor Basics"
            subtitle="What are factors and which factors are in the Two Sigma Factor Lens?"
            icon="book"
            onClick={navigateToBasics}
            className="qa-factor-basics"
          />
          <Button
            title="Factor Deep Dive"
            subtitle="Get to know the individual factors."
            icon="dna"
            onClick={() => navigateToDeepDive()}
            className="qa-factor-deep-dive"
          />
          <Button
            title="Factor Insights"
            subtitle="Analyze the historical performance of the factors in the Two Sigma Factor Lens."
            icon="lightbulb"
            onClick={navigateToInsights}
            className="qa-factor-insights"
          />
        </Row>
        <Separator />
        <FactorSummary id="recent-factor-activity" viewFactor={navigateToDeepDive} />
      </Container>
    </PageContent>
  );
};

export default withRouter(Homepage);

const PageContent = styled.div`
  margin: 60px;
  & > div {
    min-height: auto;
    padding-bottom: 30px;
  }
`;

const Button = styled(DisplayButton)`
  height: 180px;
  min-width: 25%;
`;

const Row = styled.div`
  display: flex;

  ${Button} {
    height: 180px;
    margin: 20px;
    flex: 1;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: -20px;
`;

const Separator = styled.div`
  height: 0;
  border-top: 1px solid ${GetColor.Grey};
`;
