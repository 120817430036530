import React, { useContext, useMemo } from 'react';
import type { NodeType } from 'venn-components';
import {
  Markdown,
  STUDIO_DISCLOSURE_PAGE_DEFAULT_DISCLOSURE,
  StudioPrintSettingsContext,
  StudioShimmerBlock,
  UserContext,
} from 'venn-components';
import styled, { css } from 'styled-components';
import {
  assertNotNil,
  Dates,
  findInPortfolio,
  getProxyEndDate,
  getProxyStartDate,
  getProxyTypeNoun,
  hasFeesAndExcludedInvestments,
  Numbers,
  STUDIO_DEFAULT_DISCLOSURE_FONT_SIZE,
  useHasFF,
  withSuspense,
} from 'venn-utils';
import { useRecoilValue } from 'recoil';
import { allBlockIdsState, allSubjects, requestSubjects, investmentProxiesState } from 'venn-state';
import { compact } from 'lodash';
import { BlocksDataModificationsDisclosure } from './PrivatesDataModificationsDisclosure';
import { GetColor } from 'venn-ui-kit';

const DISALLOWED_MARKDOWN: NodeType[] = [
  'root',
  'break',
  'emphasis',
  'strong',
  'thematicBreak',
  'blockquote',
  'delete',
  'link',
  'image',
  'linkReference',
  'imageReference',
  'table',
  'tableHead',
  'tableBody',
  'tableRow',
  'tableCell',
  'list',
  'listItem',
  'definition',
  'heading',
  'inlineCode',
  'code',
  'html',
  'virtualHtml',
];

const useExcludedFeesDisclosure = (organizationDisplayName: string) => {
  const allSubjectsData = useRecoilValue(allSubjects);
  const subjectsWithExcludedFees = useRecoilValue(requestSubjects(allSubjectsData)).filter(
    hasFeesAndExcludedInvestments,
  );
  const excludedNodes = subjectsWithExcludedFees.map((subject) =>
    compact(
      Object.keys(assertNotNil(subject.feesMapping))
        .filter((key) => subject.feesMapping?.[key] === 0 && key !== subject.id)
        .map((node) => findInPortfolio(subject.portfolio!, Number(node))),
    ),
  );

  if (subjectsWithExcludedFees.length === 0) {
    return undefined;
  }

  return `
  ### **Investments excluded from advisory fees**
  The following portfolios' investments have been excluded from the stated advisory fees. Some investments' returns are already presented net of fees, while other investments are not subject to fees.
  Please contact ${organizationDisplayName} for additional information.
  ${subjectsWithExcludedFees
    .map(
      (subject, idx) => `
  - **${subject.name}** *(${Numbers.safeFormatPercentage(
    subjectsWithExcludedFees[idx].feesMapping?.[subject.id],
  )} fee)* **excludes**:
  ${excludedNodes[idx].map((node) => node.name).join(', ')}
  `,
    )
    .join('')}
  `;
};

const PROXY_DISCLOSURE_TEXT = `
  ### ** Proxy disclosures **
  
  This table displays each investment within this report with a proxy applied. These investments can appear directly in this report, or be included in portfolios or benchmarks that appear in this report. 
  
  The proxy range displayed in this table reflects the proxy settings configured by the user in Venn, and does not reflect the analysis period used in this report. Date Ranges used for analysis are reflected on each block in this report.
`;

const ProxyDisclosures = ({ fontSize }: { fontSize: number }) => {
  const usedProxies = useRecoilValue(investmentProxiesState);

  const sortedUsedProxies = useMemo(() => {
    const proxies = [...usedProxies];
    proxies.sort((a, b) => a.fundName.localeCompare(b.fundName));
    return proxies;
  }, [usedProxies]);

  if (!sortedUsedProxies.length) {
    return null;
  }

  return (
    <div data-testid="qa-proxy-disclosure">
      <StyledMarkdown noMargin fontSize={fontSize} source={PROXY_DISCLOSURE_TEXT} />
      <TableWrapper fontSize={fontSize}>
        <TableCell>
          <div>
            <b>Investment Name</b>
          </div>
          <div>(Portfolio or Benchmark)</div>
        </TableCell>
        <TableCell>
          <b>Proxy Type</b>
        </TableCell>
        <TableCell>
          <b>Proxy Name</b>
        </TableCell>
        <TableCell>
          <b>Proxy Start</b>
        </TableCell>
        <TableCell>
          <b>Proxy End</b>
        </TableCell>
        {sortedUsedProxies.map((proxy, i) => {
          const isOdd = i % 2 !== 0;
          return (
            <React.Fragment key={proxy.fundId}>
              <TableCell isOdd={isOdd}>
                <div>
                  <b>{proxy.fundName}</b>
                </div>
                <div>{!!proxy.parentSubjectNames.length && `(${proxy.parentSubjectNames.join(', ')})`}</div>
              </TableCell>
              <TableCell isOdd={isOdd}>{getProxyTypeNoun(proxy.proxyType)}</TableCell>
              <TableCell isOdd={isOdd}>{proxy.proxyName}</TableCell>
              <TableCell isOdd={isOdd}>{Dates.toDDMMMYYYY(getProxyStartDate(proxy), proxy.proxiedFrequency)}</TableCell>
              <TableCell isOdd={isOdd}>{Dates.toDDMMMYYYY(getProxyEndDate(proxy), proxy.proxiedFrequency)}</TableCell>
              {proxy.extrapolate && proxy.proxyType !== 'EXTRAPOLATE' && (
                <>
                  <TableCell isOdd={isOdd} />
                  <TableCell isOdd={isOdd}>{getProxyTypeNoun('EXTRAPOLATE')}</TableCell>
                  <TableCell isOdd={isOdd}>{proxy.proxyName}</TableCell>
                  <TableCell isOdd={isOdd}>
                    {Dates.toDDMMMYYYY(
                      getProxyStartDate({ ...proxy, proxyType: 'EXTRAPOLATE' }),
                      proxy.proxiedFrequency,
                    )}
                  </TableCell>
                  <TableCell isOdd={isOdd}>
                    {Dates.toDDMMMYYYY(getProxyEndDate({ ...proxy, proxyType: 'EXTRAPOLATE' }), proxy.proxiedFrequency)}
                  </TableCell>
                </>
              )}
            </React.Fragment>
          );
        })}
      </TableWrapper>
    </div>
  );
};

const TableCell = styled.div<{ isOdd?: boolean }>`
  padding: 4px 8px;
  ${({ isOdd }) =>
    isOdd &&
    css`
      background-color: ${GetColor.GreyScale.Grey10};
    `}
`;

const TableWrapper = styled.div<{ fontSize: number }>`
  display: grid;
  grid-template-columns: 1fr auto 1fr auto auto;
  grid-template-areas:
    'header header header header header'
    'body body body body body';
  font-size: ${({ fontSize }) => fontSize}pt;
  border: 1px solid ${GetColor.GreyScale.Grey30};
  > :nth-child(-n + 5) {
    border-bottom: 1px solid ${GetColor.GreyScale.Grey30};
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  margin: 8px 0;
`;

const DisclosurePage = () => {
  const { profileSettings } = useContext(UserContext);
  const { organizationPrintSettings } = useContext(StudioPrintSettingsContext);
  const hasPrivatesStrengthenFF = useHasFF('privates_strengthen_ff');
  const organizationDisplayName = assertNotNil(
    organizationPrintSettings?.organizationName ?? profileSettings?.organization?.name,
  );
  const organizationDefaultDisclosure = STUDIO_DISCLOSURE_PAGE_DEFAULT_DISCLOSURE(organizationDisplayName);
  const excludedFeesDisclosureText = useExcludedFeesDisclosure(organizationDisplayName);
  const disclosure = organizationPrintSettings?.disclosure ?? organizationDefaultDisclosure;
  const disclosureFontSize = organizationPrintSettings?.disclosureFontSize ?? STUDIO_DEFAULT_DISCLOSURE_FONT_SIZE;
  const allBlockIds = useRecoilValue(allBlockIdsState);

  return (
    <Wrapper>
      <Header>Disclosure</Header>
      {disclosure && (
        <StyledMarkdown
          noMargin
          fontSize={disclosureFontSize}
          disallowedTypes={DISALLOWED_MARKDOWN}
          source={disclosure}
        />
      )}
      <OptionalSections>
        {excludedFeesDisclosureText && (
          <StyledMarkdown noMargin fontSize={disclosureFontSize} source={excludedFeesDisclosureText} />
        )}
        {hasPrivatesStrengthenFF && (
          <BlocksDataModificationsDisclosure blockIds={allBlockIds} fontSize={disclosureFontSize} />
        )}
        <ProxyDisclosures fontSize={disclosureFontSize} />
      </OptionalSections>
      <Breaker />
    </Wrapper>
  );
};

export default withSuspense(<StudioShimmerBlock height={450} />, DisclosurePage);

const OptionalSections = styled.div`
  > div {
    border-top: 1px solid ${GetColor.Grey};
    margin: 1em 0;
    page-break-inside: avoid;
  }
`;

const Breaker = styled.div`
  width: 100%;
  height: 10px;
  margin-top: -10px;
`;

const Wrapper = styled.div`
  padding: 40px 40px 0 40px;
  font-size: 10px;
  overflow: auto;

  p {
    page-break-inside: avoid;
    padding: 1em 0 0 0;
  }
`;

const Header = styled.h3`
  font-weight: bold;
`;

const StyledMarkdown = styled(Markdown)<{ fontSize: number }>`
  font-size: ${({ fontSize }) => fontSize}pt;
`;
