import React, { useContext, useRef } from 'react';
import styled from 'styled-components';
import type { ContentBlockProps } from './ContentBlock';
import ContentBlock from './ContentBlock';
import type { DownloadableProps } from '../downloadable/Downloadable';
import Downloadable from '../downloadable/Downloadable';
import type { PartialBy } from 'venn-utils';
import { UserContext } from '../contexts';
import { Router, useHistory } from 'react-router-dom';
import { BlockByolWatermark } from '../byol-watermark';
import { useRecoilBridgeAcrossReactRoots_UNSTABLE } from 'recoil';
import { AnalysisBlockWatermark, vennBlockWatermarkClass } from '../block-watermark/BlockWatermark';
import { blockEmptyStateClass } from '../empty-state/EmptyState';

/** Indicate if data is downloadable. */
export const DOWNLOAD_DATA_BLOCK_CLASS = 'download-data-block';
/** Indicate if whole block is downloadable. */
export const DOWNLOAD_BLOCK_CLASS = 'download-block';

type DownloadableOptions = PartialBy<DownloadableProps, 'target'>;

interface DownloadableContentBlockProps extends ContentBlockProps {
  downloadable?: DownloadableOptions;
  /**
   * Vertical alignment of right-hand options. Defaults to center.
   */
  alignOptions?: 'center' | 'bottom' | 'top';
  /**
   * If the downloadable (image) content is different from the content of the analysis block, you can specify it here.
   */
  downloadableContent?: () => JSX.Element;
  /**
   * If enabled the options icon for downloading will be floating above the content of the block rather than its own separate row
   */
  floatingOptions?: boolean;
}

const DownloadableContentBlock = ({
  floatingOptions,
  downloadable,
  alignOptions,
  downloadableContent: printedContent,
  ...contentBlockProps
}: DownloadableContentBlockProps) => {
  const contentBlockRef = useRef<HTMLDivElement>(null);

  const RecoilBridge = useRecoilBridgeAcrossReactRoots_UNSTABLE();
  const userContextValue = useContext(UserContext);
  const history = useHistory();

  const enableDownloadClassName = !!downloadable && !downloadable.disabled ? DOWNLOAD_BLOCK_CLASS : '';
  const enableDownloadDataClassName = !!downloadable && downloadable.excel ? DOWNLOAD_DATA_BLOCK_CLASS : '';
  const downloadableContentRef = downloadable?.target || contentBlockRef;

  const renderPrintedContentWithWatermark = () => {
    /**
     * {@link renderPrintedContentWithWatermark} gets called outside of the Venn Application tree, and thus is
     * not wrapped in all contexts we normally have access to. {@link withWatermark} needs {@link UserContext} to
     * get the account name and `history` is needed to determine the location of the item, the right ff, and if report is final
     *
     * TODO(VENN-23056): after refactor, remove usage of usercontext and router
     */
    return (
      // TODO(VENN-23056): after refactor, remove usage of RecoilBridge
      <RecoilBridge>
        <UserContext.Provider value={userContextValue}>
          <Router history={history}>
            <ContentBlock
              {...contentBlockProps}
              rightOptions={<RightOptions alignOptions={alignOptions}>{contentBlockProps.rightOptions}</RightOptions>}
            >
              <InnerContentStack>
                <ChildrenLayer>{printedContent?.()}</ChildrenLayer>
                <ContentWatermarkLayer>
                  <AnalysisBlockWatermark />
                </ContentWatermarkLayer>
              </InnerContentStack>
              <BlockByolWatermark />
            </ContentBlock>
          </Router>
        </UserContext.Provider>
      </RecoilBridge>
    );
  };

  // Apply watermark directly to target if `printedContent` is present,
  // or to `Downloadable` if the content to print is in a ref
  const downloadableTarget = printedContent ? renderPrintedContentWithWatermark : downloadableContentRef;

  return (
    <ContentBlockWithWatermark
      {...contentBlockProps}
      innerRef={contentBlockRef}
      className={`${enableDownloadClassName} ${enableDownloadDataClassName}`}
      dataTitle={downloadable?.options?.fileName}
      rightOptions={
        <>
          <RightOptions alignOptions={alignOptions}>
            {contentBlockProps.rightOptions}
            {downloadable ? <Downloadable {...downloadable} target={downloadableTarget} /> : null}
          </RightOptions>
        </>
      }
      floatingOptions={floatingOptions}
    >
      <InnerContentStack>
        <ChildrenLayer>{contentBlockProps.children}</ChildrenLayer>
        <ContentWatermarkLayer>
          <AnalysisBlockWatermark />
        </ContentWatermarkLayer>
      </InnerContentStack>
      <BlockByolWatermark />
    </ContentBlockWithWatermark>
  );
};

const InnerContentStack = styled.div`
  display: grid;
  grid-template-areas: 'stack';
  height: 100%;
`;

const ChildrenLayer = styled.div`
  grid-area: stack;
  min-height: 0;
  min-width: 0;
`;

const ContentWatermarkLayer = styled.div`
  grid-area: stack;
  position: relative;
  pointer-events: none;
`;

const ContentBlockWithWatermark = styled(ContentBlock)`
  :has(.${blockEmptyStateClass}) .${vennBlockWatermarkClass} {
    display: none;
  }
`;

const RightOptions = styled.div<{ alignOptions?: 'center' | 'bottom' | 'top' }>`
  display: flex;
  align-items: ${(props) => {
    const dictionary = { center: 'center', bottom: 'flex-end', top: 'flex-start' };
    return dictionary[props.alignOptions || 'center'];
  }};

  > * {
    margin-left: 20px;
  }
`;

export const EmptyHeaderSpace = styled.div`
  height: 20px;
`;

export default DownloadableContentBlock;
