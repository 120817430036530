import type { PropsWithChildren } from 'react';
import React from 'react';
import type { TriggerableMenuProps } from '../TriggerableMenu';
import TriggerableMenu from '../TriggerableMenu';
import { GetColor } from '../../../style/color';
import { ColorUtils } from '../../../style/colorUtils';
import styled from 'styled-components';

const TriggerableMenuLeft = <V,>(props: PropsWithChildren<TriggerableMenuProps<V>>) => {
  return (
    <StyledTriggerableMenu {...props} innerClassName="triggerable-menu-left-dropdown">
      {props.children}
    </StyledTriggerableMenu>
  );
};

export const StyledTriggerableMenu = styled(TriggerableMenu)`
  min-height: 20px;
  .triggerable-menu-left-dropdown {
    transform: translate3d(-90%, 6px, 0);
    box-shadow: 0 2px 10px 0 ${ColorUtils.hex2rgbaFrom(GetColor.Black, 0.4)};
    width: 200px;

    .disabled-option {
      background-color: ${GetColor.White};
      cursor: not-allowed;
    }
  }
` as typeof TriggerableMenuLeft; // sort of a hack for TriggerableMenu being a class component not working well with styeld component types

export default TriggerableMenuLeft;
