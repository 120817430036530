import React from 'react';
import type { Portfolio } from 'venn-api';
import type { AnalysisSubjectSecondaryLabel } from 'venn-utils';
import noop from 'lodash/noop';

export type CompareType = 'None' | AnalysisSubjectSecondaryLabel;
export type QueryParamCompareType = 'master' | 'saved' | 'optimized' | 'none';

export interface ComparisonContextValue {
  compareType: CompareType;
  comparePortfolio?: Portfolio;
  compareLoading: boolean;
  updateCompareType: (
    compareType: CompareType,
    customPortfolio?: Portfolio,
    skipOptimization?: boolean,
    optimizationId?: string,
  ) => void;
}

export default React.createContext<ComparisonContextValue>({
  compareType: 'None',
  comparePortfolio: undefined,
  compareLoading: false,
  updateCompareType: noop,
} as ComparisonContextValue);
