import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { debounce, isEmpty, uniqBy } from 'lodash';

import type { ItemId, PrivateAssetSearchModeEnum } from 'venn-api';
import { globalSearch } from 'venn-api';
import { getDefaultInvestmentSearchResults, useApi, useCallIfMounted } from 'venn-utils';

import type { SearchFilter, SearchMenuItem } from './types';
import { search } from './search';
import { itemEqualityCheck } from './utils';

import useTagsDropDown from './useTagsDropDown';

const useMultiSelectSearch = ({
  location,
  selectedFilters,
  menuIsOpen,
  excludedItems,
  investmentsOnly,
  portfoliosOnly,
  showRecentlyAnalyzed,
  privateAssetSearchMode,
  proxyable,
  initialQuery = '',
  placeholderIds,
  isOptionDisabled,
  initialSelection,
  onSelectionsChange,
  includeTags = true,
}: {
  location: string;
  selectedFilters: SearchFilter[];
  menuIsOpen: boolean;
  initialSelection: SearchMenuItem[];
  excludedItems?: ItemId[];
  investmentsOnly?: boolean;
  portfoliosOnly?: boolean;
  showRecentlyAnalyzed?: boolean;
  privateAssetSearchMode: PrivateAssetSearchModeEnum;
  proxyable?: boolean;
  initialQuery?: string;
  placeholderIds?: ItemId[];
  isOptionDisabled?: (option: SearchMenuItem) => boolean;
  onSelectionsChange?: (selected: SearchMenuItem[]) => void;
  includeTags?: boolean;
}) => {
  const [selections, setSelections] = useState<SearchMenuItem[]>(initialSelection);
  const [query, setQuery] = useState(initialQuery);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<SearchMenuItem[]>([]);
  const [totalResults, setTotalResults] = useState<number>();
  const searchApi = useApi(globalSearch);
  const getDefaultInvestments = useApi(getDefaultInvestmentSearchResults);

  /* When new results are loaded. Propagate them to the onSearchResults callback */
  const onResults = useCallIfMounted(
    useCallback((searchMenuItems: SearchMenuItem[], newTotalResults?: number) => {
      setResults(searchMenuItems);
      setTotalResults(newTotalResults);
    }, []),
  );

  const safeSetLoading = useCallIfMounted(setLoading);

  const debouncedSearchRef = useRef<typeof search>(
    debounce(async (...args) => {
      await search(...args);
    }, 200),
  );

  const onSearch = useCallback((newSearch: string) => {
    setQuery(newSearch);
  }, []);

  const onSelect = useCallback((newSelections: SearchMenuItem[]) => {
    setSelections((prevSelections) => {
      const areItemsRemoved = prevSelections.length > newSelections.length;
      if (areItemsRemoved) {
        return newSelections;
      }
      // After typing new query, react select can't remove items, so selecting same item simply adds it to last index
      const lastClickedItem = newSelections.slice(-1)[0];
      const setNewSelections = new Set(prevSelections);
      const isItemRemoved = prevSelections.find((prevSelection) => itemEqualityCheck(prevSelection, lastClickedItem));

      isItemRemoved
        ? setNewSelections.forEach((setNewSelection) =>
            itemEqualityCheck(setNewSelection, lastClickedItem)
              ? setNewSelections.delete(setNewSelection)
              : setNewSelection,
          )
        : setNewSelections.add(lastClickedItem);

      return Array.from(setNewSelections);
    });
  }, []);

  const onSelectAll = useCallback(
    (newSelections: SearchMenuItem[]) => {
      const filterOutDisabledAndExcluded =
        isOptionDisabled || !isEmpty(excludedItems)
          ? newSelections.filter(
              (newSelection) =>
                !(
                  (isOptionDisabled ? isOptionDisabled(newSelection) : false) ||
                  !!excludedItems?.filter(
                    (excludedItem) =>
                      excludedItem.id === newSelection.searchResult?.fundId ||
                      excludedItem.id === String(newSelection.searchResult?.portfolioId),
                  ).length
                ),
            )
          : newSelections;

      setSelections((prevSelections) => {
        if (isEmpty(prevSelections)) {
          return filterOutDisabledAndExcluded;
        }

        const filterSelected = filterOutDisabledAndExcluded.filter(
          (newSelection) =>
            !prevSelections.filter((prevSelection) => itemEqualityCheck(newSelection, prevSelection)).length,
        );

        return [...prevSelections, ...filterSelected];
      });
    },
    [excludedItems, isOptionDisabled],
  );

  const onMultiClear = useCallback(
    (newSelections: SearchMenuItem[]) => {
      setSelections((prevSelections) =>
        prevSelections.filter(
          (prevSelection) =>
            !newSelections.filter((newSelection) => itemEqualityCheck(prevSelection, newSelection)).length,
        ),
      );
    },
    [setSelections],
  );

  const {
    selectedTagDropdowns,
    openedTags,
    onTagsDropdown,
    onTagsClose,
    onUnselectAllTags,
    onBulkTagSelect,
    onBulkTagUnselect,
    setOpenedTags,
  } = useTagsDropDown({ results, onResults, onSelectAll, onMultiClear });

  const refetchSearch = useCallback(() => {
    // We only show the default search if there is no query and filters selected
    const defaultSearch = !query && !selectedFilters.length;
    // If we are not showing default results, reset the results to empty
    if (defaultSearch && !showRecentlyAnalyzed) {
      onResults([]);
    }
    return debouncedSearchRef.current?.(
      defaultSearch ? '' : query,
      onResults,
      searchApi,
      getDefaultInvestments,
      safeSetLoading,
      location,
      selectedFilters,
      setOpenedTags,
      privateAssetSearchMode,
      excludedItems,
      investmentsOnly,
      portfoliosOnly,
      defaultSearch,
      showRecentlyAnalyzed,
      proxyable,
      placeholderIds,
      includeTags,
    );
  }, [
    onResults,
    searchApi,
    getDefaultInvestments,
    query,
    excludedItems,
    setOpenedTags,
    privateAssetSearchMode,
    investmentsOnly,
    portfoliosOnly,
    safeSetLoading,
    proxyable,
    showRecentlyAnalyzed,
    location,
    selectedFilters,
    placeholderIds,
    includeTags,
  ]);

  useEffect(() => {
    if (menuIsOpen) {
      refetchSearch();
    }
  }, [refetchSearch, menuIsOpen]);

  const onSelectAllWithTags = useCallback(
    (newSelections: SearchMenuItem[]) => {
      const getTags = newSelections.filter((newSelection) => !isEmpty(newSelection.searchResult?.tagIds));

      if (!isEmpty(getTags)) {
        getTags.forEach((tag) => onBulkTagSelect(tag));
      }
      onSelectAll(newSelections);
    },
    [onSelectAll, onBulkTagSelect],
  );

  const onUnselectSelectAllWithTags = useCallback(
    (newSelections: SearchMenuItem[]) => {
      const getTags = newSelections.filter((newSelection) => !isEmpty(newSelection.searchResult?.tagIds));

      if (!isEmpty(getTags)) {
        getTags.forEach((tag) => onBulkTagUnselect(tag));
      }
      onMultiClear(newSelections);
    },
    [onMultiClear, onBulkTagUnselect],
  );

  const onClear = useCallback(() => {
    onUnselectAllTags();
    setSelections([]);
  }, [onUnselectAllTags]);

  const onMenuClose = useCallback(() => {
    onUnselectAllTags();
    setOpenedTags([]);
    setSelections([]);
    setResults([]);
  }, [onUnselectAllTags, setOpenedTags]);

  const items = useMemo(() => {
    const tags = selectedTagDropdowns.flatMap((t: SearchMenuItem) => [
      t,
      ...results.filter((s) => s.taggedWith === t.label),
    ]);
    return uniqBy([...tags, ...selections.filter((s) => !s.isTagged), ...results], (item) =>
      item.category === 'tag' ? item.label : item.value?.id,
    );
  }, [results, selectedTagDropdowns, selections]);

  useEffect(() => onSelectionsChange?.(selections), [onSelectionsChange, selections]);

  return {
    query,
    items,
    totalResults,
    selections,
    loading,
    onSearch,
    onSelect,
    onClear,
    onSelectAllWithTags,
    onUnselectSelectAllWithTags,
    onTagsDropdown,
    onTagsClose,
    onBulkTagSelect,
    onBulkTagUnselect,
    selectedTagDropdowns,
    openedTags,
    onMenuClose,
  };
};

export default useMultiSelectSearch;
