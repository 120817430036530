import type { ReactNode, FC } from 'react';
import React from 'react';
import { GetColor } from 'venn-ui-kit';
import { CHART_TOP_TEXT_PADDING, CHART_PADDING } from './constants';
import styled from 'styled-components';

interface Props {
  children: ReactNode;
  className?: string;
  print: boolean;
}

const SubTitle: FC<React.PropsWithChildren<Props>> = ({ children, className, print }: Props) => (
  <StyledText x={CHART_PADDING} y={18 + CHART_TOP_TEXT_PADDING} print={print} className={className}>
    {children}
  </StyledText>
);

export default SubTitle;

const StyledText = styled.text<{ print: boolean }>`
  font-family: ${(props) => props.theme.Typography.fontFamily};
  font-size: ${(props) => (props.print ? '12px' : '14px')};
  fill: ${GetColor.DarkGrey};
`;
