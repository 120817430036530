import type { Theme } from 'venn-ui-kit';
import { compact, uniq } from 'lodash';
import type { CustomizableMetric, ExcelCell } from 'venn-utils';
import { Dates } from 'venn-utils';
import type { CurrencyEnum, FrequencyEnum } from 'venn-api';
import type { Serie } from './types';
import { formatExportableSubjectWithOptionalFee } from '../../../legend/NameWithOptionalFee';
import type { StudioRequestSubject } from 'venn-state';

export type SubjectPoint = {
  subject: StudioRequestSubject;
  subjectColor: string;
  coordinates: { name: string; chartValue: number | undefined; excelValue: number | undefined }[];
};

export const getCurrencySymbol = (currency?: CurrencyEnum) => {
  switch (currency) {
    case 'USD':
    case 'AUD':
    case 'NZD':
    case 'CAD':
      return '$';
    case 'CHF':
      return 'CHF';
    case 'EUR':
      return '€';
    case 'GBP':
      return '£';
    case 'JPY':
      return '¥';
    case 'SEK':
    case 'NOK':
      return 'kr';
    default:
      return '';
  }
};

export const parseDatesCategoriesWithSeries = (
  series: Serie[],
  frequency: FrequencyEnum,
  theme: Theme,
  start?: number,
  end?: number,
) => {
  const allDates: number[] = [];

  const mappedSeries = compact(
    series.map((serie) => {
      const dateMapToValue = {};
      serie.data
        .filter((item) => {
          if (start && end) {
            return item[0] <= end && item[0] >= start;
          }
          return true;
        })
        .forEach((item) => {
          allDates.push(item[0]);
          const date = Dates.toDDMMMYYYY(item[0], frequency);
          dateMapToValue[date] = item[1];
        });

      return {
        mapper: dateMapToValue,
        name: serie.name,
        color: serie.color,
      };
    }),
  );

  const categories = uniq(allDates)
    .sort((a, b) => a - b)
    .map((date) => Dates.toDDMMMYYYY(date, frequency));

  const seriesValue = mappedSeries.map((mappedSerie) => {
    return {
      type: 'column',
      data: categories.map((date) => mappedSerie.mapper[date] ?? {}), // highchart throw error for undefined. use an empty object instead for missing value
      name: mappedSerie.name,
      color: mappedSerie.color,
    };
  });

  return {
    categories,
    seriesValue,
  };
};

/**
 * Note: The ordering of the coordinates must match the ordering of the provided displayedMetrics.
 * In other words, if displayedMetrics is [A, B] then the subjectPoints coordinates must share the same ordering of [coordinateA, coordinateB].
 */
export const convertScatterSeriesDataToExcel = (
  subjectPoints: SubjectPoint[],
  displayedMetrics: CustomizableMetric[],
) => {
  const headerRow: ExcelCell[] = [
    '',
    ...subjectPoints.map((point) => formatExportableSubjectWithOptionalFee(point.subject)),
  ].map((headerLabel) => ({ value: headerLabel, bold: true }));

  const dataRows: ExcelCell[][] = [
    ...displayedMetrics.map((m, index) => [
      { value: m.label, bold: true },
      ...subjectPoints.map((x) => ({
        value: x.coordinates[index].excelValue ?? '--',
        percentage: m.dataType === 'PERCENTAGE',
        digits: 2,
      })),
    ]),
  ];

  return [headerRow, ...dataRows];
};
