import type { AssetTypeEnum, CurrencyEnum, Portfolio, SimpleFund } from 'venn-api';

export const buildStrategy = (values: Partial<Portfolio>, ...children: Portfolio[]) =>
  build(false, values, ...children);

export const buildFund = (values: Partial<Portfolio>, ...children: Portfolio[]) => build(true, values, ...children);

const simpleFund: SimpleFund = {
  isin: 'isin',
  morningstarCategory: undefined!,
  id: '123',
  updated: 1526309984642,
  name: 'fund',
  startRange: 1526309984642,
  endRange: 1526309984642,
  currency: 'USD' as CurrencyEnum,
  userUploaded: false,
  assetType: 'MANAGER' as AssetTypeEnum,
  remoteType: 'LOCAL',
  accountNumber: undefined!,
  active: undefined!,
  category: undefined!,
  exchangeCountry: undefined!,
  fundSourceType: undefined!,
  investmentRegion: undefined!,
  investmentSource: undefined!,
  live: undefined!,
  returnFrequency: undefined!,
  symbol: undefined!,
  dataSource: undefined!,
  created: 0,
  createdById: 0,
  domicile: '',
  exchange: '',
  investmentObjective: '',
  netExpenseRatio: 0,
  preferredFrequency: 0,
  primaryBenchmark: '',
  updatedById: 0,
  uploadName: '',
  userEditable: true,
  updatedByDataSource: undefined!,
};

const build = (isFund = false, values: Partial<Portfolio>, ...children: Portfolio[]): Portfolio => {
  const portfolio = {
    id: 0,
    name: 'no-name',
    children,
    fund: isFund ? simpleFund : undefined,
    allocation: 0,
    rebalanceStrategy: 'NONE',
    updated: null,
    compare: [],
    frequencyId: 'MONTHLY',
  };

  Object.assign(portfolio, values);

  return portfolio as unknown as Portfolio;
};
