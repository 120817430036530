import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

interface UseRemoteStateProps<T, U, V> {
  key: [string] | [string, U];
  getter: (args?: U) => Promise<T>;
  updater: (args: V) => Promise<unknown>;
}

export const useRemoteState = <T, U, V>({
  key,
  getter,
  updater,
}: UseRemoteStateProps<T, U, V>): [T, (v: V) => void] => {
  const queryClient = useQueryClient();

  const { data: state } = useQuery<T, Error, T, [string] | [string, U]>(key, async () => getter(key[1]), {
    suspense: true,
  });

  const mutation = useMutation(updater, {
    onSuccess: () => {
      queryClient.invalidateQueries(key);
    },
  });

  return [state, mutation.mutate] as [T, (v: V) => void];
};
