import React from 'react';
import type { FlattenSimpleInterpolation } from 'styled-components';
import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';
import type { TabOption, TabWrapperProps, TypeClickHandler } from './Tab';
import Tab from './Tab';

export interface StaticTabsProps<T extends string = string> {
  className?: string;
  tabClassName?: string;

  tabs: TabOption<T>[];

  selectedTabId: string;

  /** Callback fired on Tab click */
  onTabSelected: TypeClickHandler<T>;

  /** whether the colored border is on the bottom or the top of container */
  borderLocation?: 'top' | 'bottom';

  /** Custom tab renderer component */
  tabRenderer?: React.ComponentType<TabWrapperProps>;

  /** CSS overrides */
  rootCssOverrides?: FlattenSimpleInterpolation;
  positionerCssOverrides?: FlattenSimpleInterpolation;
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default <T extends string = string>({
  className,
  tabs = [],
  selectedTabId,
  onTabSelected = () => {
    /**/
  },
  borderLocation,
  tabClassName,
  tabRenderer,
  rootCssOverrides,
  positionerCssOverrides,
}: StaticTabsProps<T>) => (
  <Root className={className} css={rootCssOverrides}>
    <Positioner css={positionerCssOverrides}>
      {tabs.map((tab, index) => {
        const isSelected = tab.tabId === selectedTabId;
        return (
          <Tab<T>
            className={tabClassName}
            {...tab}
            isSelected={isSelected}
            data-testid={`static-tab-${index}${isSelected ? '-selected' : ''}`}
            key={tab.tabId}
            onClick={onTabSelected}
            borderLocation={borderLocation}
            tabRenderer={tabRenderer}
          />
        );
      })}
    </Positioner>
  </Root>
);

const Root = styled.div<{ css?: FlattenSimpleInterpolation }>`
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  background: ${GetColor.PaleGrey};
  border-bottom: 1px solid ${GetColor.Grey};
  ${({ css }) => css};
`;

const Positioner = styled.div<{ css?: FlattenSimpleInterpolation }>`
  margin-bottom: -1px;
  display: flex;
  flex-direction: row;
  flex: 1;
  ${({ css }) => css};
`;
