import styled, { css } from 'styled-components';
import { Body1, GetColor, Icon } from 'venn-ui-kit';

export const PREVIEW_AREA_COLOR = GetColor.WhiteGrey;
export const HALF_COLUMN_MARGIN = 40;
export const LARGE_SPACING = 24;
export const SMALL_SPACING = 16;

export const InfoContainer = styled.div`
  width: 50%;
`;

export const InfoHeading = styled(Body1)`
  font-weight: bold;
`;

export const PreviewArea = styled.div`
  background-color: ${PREVIEW_AREA_COLOR};
  border-radius: 5px;
  margin-right: ${HALF_COLUMN_MARGIN}px;
  width: 50%;
`;

export const Row = styled.div`
  display: flex;
  align-items: baseline;
  & > :not(:last-child) {
    margin-right: ${SMALL_SPACING}px;
  }
`;

export const RowSpaceBetween = styled(Row)`
  justify-content: space-between;
  align-items: center;
`;

export const ErrorMessage = styled(Body1)`
  color: ${GetColor.Error};
  display: inline;
`;

export const ViewConfigurationArea = styled(PreviewArea)`
  width: 100%;
  padding: ${LARGE_SPACING}px;
  margin: ${SMALL_SPACING}px 0;
`;

export const HalfConfigurationArea = styled.div`
  width: 50%;
`;

export const DeleteIcon = styled(Icon)<{ disabled?: boolean }>`
  color: ${GetColor.MidGrey1};
  ${({ disabled }) =>
    !disabled &&
    css`
      :hover {
        color: ${GetColor.Primary.Dark};
      }
    `}
`;

export const roundedBorder = css`
  border: 1px solid ${GetColor.MidGrey2};
  border-radius: 4px;
`;

export const StyledHr = styled.hr`
  background-color: ${GetColor.MidGrey2};
  border: none;
  height: 1px;
  margin-top: 8px;
`;

export const PrefixBorder = styled(StyledHr)`
  margin: ${SMALL_SPACING}px 0;
`;
