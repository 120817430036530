import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import type { WhatsNewContent } from 'venn-api';
import { getHomeContent } from 'venn-api';
import {
  Body1,
  getAppTitle,
  GetColor,
  GetImages,
  Headline3,
  Icon,
  Label,
  Link as LinkStyle,
  Shimmer,
  TOP_NAV_HEIGHT,
} from 'venn-ui-kit';
import { analyticsService, getUserInfo, isWealthManagerTabDisplayed, Routes, SKIPPED_TO_HOME } from 'venn-utils';
import { UserContext } from 'venn-components';
import { useHistory } from 'react-router-dom';
import type { SelectedTabConfig, TabConfig } from './HomePageTabs';
import HomePageTabs from './HomePageTabs';
import onboardingTabs from './OnboardingTutorial';
import getHomeTabs from './HomeTutorial';
import useRecentFundId from './useRecentFundId';
import moment from 'moment';
import VennOnboardingVideo from './VennOnboardingVideo';

interface WhatsNewContentState extends WhatsNewContent {
  colorScheme?: string;
  displayName?: string;
}

const getLink = (url?: string) => {
  if (!url) {
    return '';
  }

  const httpPatern = /^https?:\/{2}\w/i;
  const matches = httpPatern.test(url);

  if (matches) {
    return url;
  }

  return `//${url}`;
};

const HomePage = () => {
  const { profileSettings, settings, hasPermission } = useContext(UserContext);
  const { shouldShowTutorial } = getUserInfo(profileSettings, settings, hasPermission);
  const history = useHistory();
  const recentFundId = useRecentFundId();

  const homeTabs = getHomeTabs(hasPermission('OPTIMIZATION'), isWealthManagerTabDisplayed(hasPermission));

  const [isHome, setIsHomeValue] = useState(!shouldShowTutorial);
  const setIsHome = useCallback((value: boolean) => {
    setIsHomeValue(value);
  }, []);

  const [whatsNewContent, setWhatsNewContent] = useState<WhatsNewContentState>();
  const [loadingWhatsNew, setLoadingWhatsNew] = useState<boolean>(false);

  useEffect(() => {
    const fetchWhatsNewContent = async () => {
      setLoadingWhatsNew(true);
      try {
        const { content } = await getHomeContent();
        setWhatsNewContent({
          ...content.whatsNewContent,
          ...(content.whatsNewContent.sponsored &&
            profileSettings?.sponsor?.colorScheme && { colorScheme: profileSettings?.sponsor.colorScheme }),
          ...(content.whatsNewContent.sponsored &&
            profileSettings?.sponsor?.displayName && { displayName: profileSettings?.sponsor.displayName }),
        });
      } catch (e) {
        setWhatsNewContent(undefined);
      }
      setLoadingWhatsNew(false);
    };
    fetchWhatsNewContent();
  }, [profileSettings?.sponsor]);

  const showWhatsNew = !isHome ? undefined : whatsNewContent;

  const defaultTabIdx = isHome ? 0 : 1;
  const [selectedTab, setSelectedTab] = useState<TabConfig>((isHome ? homeTabs : onboardingTabs)[defaultTabIdx]);

  return (
    <Background>
      <Main displayBackground={selectedTab.displayBackground}>
        <HomePageTabs
          chapterConfig={[
            { tabs: onboardingTabs, defaultSelectedTabIdx: 1, chapterName: 'Introduction' },
            { tabs: homeTabs, defaultSelectedTabIdx: 0, chapterName: 'Home' },
          ]}
          defaultSelectedTab={{ selectedTab, selectedTabIdx: defaultTabIdx, chapterIdx: isHome ? 1 : 0 }}
          onTabChange={({ selectedTab: updatedSelectedTab, chapterIdx }: SelectedTabConfig) => {
            setIsHome(chapterIdx === 1);
            setSelectedTab(updatedSelectedTab);
          }}
          onHasSkipped={isHome ? undefined : () => localStorage?.setItem(SKIPPED_TO_HOME, 'true')}
        >
          <Content>
            {!selectedTab.hideWelcomeHeader && (
              <WelcomeLabel>
                <Label>
                  {selectedTab.customWelcomeHeaderLabel ??
                    (isHome ? `Discover ${getAppTitle()}:` : `Welcome to ${getAppTitle()}:`)}
                </Label>
              </WelcomeLabel>
            )}
            <selectedTab.Component fundId={selectedTab.fundId ?? recentFundId} />
          </Content>
        </HomePageTabs>
      </Main>
      <Bottom>
        <MainFooterBar>
          <MainBox>
            <VennOnboardingVideo />
          </MainBox>
          {loadingWhatsNew ? (
            <WhatsNewLoader />
          ) : !showWhatsNew ? (
            <MainBox hidden />
          ) : (
            <MainBox>
              <LeftRight iconColor={whatsNewContent?.colorScheme}>
                <div>
                  <Icon type="sparkles" /> What's new?
                </div>
                <div>
                  {showWhatsNew.displayName ? `Sponsored By: ${showWhatsNew.displayName} • ` : null}
                  {moment.utc(showWhatsNew.publishedAt).format('MMM DD, YYYY')}
                </div>
              </LeftRight>
              <NewsWrapper>
                <Headline3 className="qa-title">{showWhatsNew.title}</Headline3>
                <Body1 className="qa-preview">
                  {showWhatsNew.text}{' '}
                  <LinkStyle className="qa-url">
                    <a
                      href={getLink(showWhatsNew?.url)}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => {
                        analyticsService.linkClicked({
                          locationOnPage: whatsNewContent?.sponsored
                            ? 'Customized Whats New Section'
                            : 'Default Whats New Section',
                          destinationUrl: showWhatsNew.url,
                          label: showWhatsNew.link,
                          ...(profileSettings?.sponsor?.accountId && { sponsorId: profileSettings?.sponsor.accountId }),
                        });
                      }}
                    >
                      {showWhatsNew.link}
                    </a>
                  </LinkStyle>
                </Body1>
              </NewsWrapper>
            </MainBox>
          )}
        </MainFooterBar>
        <Links>
          <FooterLink>
            <button
              type="button"
              className="qa-link-to-factor-education"
              onClick={() => {
                history.push(Routes.FACTOR_LENS_HOMEPAGE);
                analyticsService.navigationTriggered({
                  location: 'homepage bottom bar',
                  itemType: 'link',
                  userIntent: 'navigate',
                  destinationPageTitle: 'Factor Lens Homepage',
                });
              }}
            >
              <Icon type="graduation-cap" />
              Two Sigma Factor Lens Education
            </button>
          </FooterLink>
        </Links>
      </Bottom>
    </Background>
  );
};

export default HomePage;

const BOTTOM_HEIGHT = 228;

const Background = styled.div`
  height: 100%;
`;

const NewsWrapper = styled.div`
  width: 100%;
`;

const Main = styled.div<{ displayBackground?: boolean }>`
  min-height: calc(100vh - ${BOTTOM_HEIGHT + TOP_NAV_HEIGHT}px);
  display: flex;
  padding: 50px 10% 4px 0;
  ${(props) =>
    props.displayBackground &&
    css`
      background-image: url(${GetImages.vennBackground});
      background-position: 78px 54px;
      background-repeat: no-repeat;
    `};
`;

const Content = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const Bottom = styled.div`
  height: ${BOTTOM_HEIGHT}px;
  border-top: 1px solid ${GetColor.Grey};
`;

const MainFooterBar = styled.div`
  height: 156px;
  display: flex;
`;

const MainBox = styled.div<{ hidden?: boolean }>`
  ${({ hidden }) => hidden && 'visibility: hidden;'};

  :not(:first-child) {
    border-left: 1px solid ${GetColor.Grey};
    flex-grow: 1;
  }
  padding: 18px 40px 20px;
  min-width: 466px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: 1.125rem;
`;

const LeftRight = styled.div<{ iconColor?: string }>`
  color: ${GetColor.DarkGrey};
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
  i {
    color: ${({ iconColor }) => iconColor || GetColor.HighlightDark};
  }
`;

const Links = styled.div`
  border-top: 1px solid ${GetColor.Grey};
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FooterLink = styled(Body1)`
  :not(:first-child) {
    margin-left: 36px;
  }

  & > button {
    i {
      color: ${GetColor.Black};
      margin-right: 4px;
    }

    :hover {
      color: ${GetColor.Primary.Main};
      i {
        color: ${GetColor.Primary.Main};
      }
      cursor: pointer;
    }
  }
`;

const WelcomeLabel = styled.div`
  & > label {
    color: ${GetColor.HighlightBackground};
  }
`;

const WhatsNewLoader = () => (
  <MainBox>
    <LeftRight>
      <ShimmerBlock percentWidth={30} height={14} />
      <ShimmerBlock percentWidth={20} height={14} />
    </LeftRight>
    <div style={{ width: '100%' }}>
      <Headline3>
        <ShimmerBlock percentWidth={60} height={20} space={14} />
      </Headline3>
      <ShimmerBlock percentWidth={100} height={14} space={8} />
      <ShimmerBlock percentWidth={70} height={14} space={8} />
    </div>
  </MainBox>
);

const ShimmerBlock = styled.div<{ percentWidth?: number; height?: number; space?: number }>`
  ${Shimmer}
  ${({ percentWidth }) => percentWidth && `width: ${percentWidth}%;`}
  ${({ height }) => height && `height: ${height}px;`}
  ${({ space }) => space && `margin: ${space}px 0;`}
  animation-duration: 5s;
`;
