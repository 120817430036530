import type { ReactNode } from 'react';
import { createContext } from 'react';
import noop from 'lodash/noop';
import { DataUploaderMode } from './types';
import type { Portfolio } from 'venn-api';

export interface DataUploaderContext {
  step: number;
  mode: DataUploaderMode;
  error: string | ReactNode;
  portfolio?: Portfolio;
  setStep: (step: number) => void;
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
const context = createContext<DataUploaderContext>({
  step: 0,
  mode: DataUploaderMode.Returns,
  setStep: noop,
  portfolio: undefined,
  error: '',
});

export const { Provider, Consumer } = context;
export default context;
