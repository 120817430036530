import type { FC, HTMLAttributes } from 'react';
import React, { cloneElement, Children } from 'react';
import styled, { css } from 'styled-components';
import type { DropDirection } from 'venn-ui-kit';
import { GetColor, ZIndex } from 'venn-ui-kit';

export type DropdownMenuItemsDropDirection = DropDirection.Down | DropDirection.Up | DropDirection.Left;

interface DropdownMenuItemsWrapperProps {
  anchorMargin?: boolean;
}

const DropdownMenuItemsWrapper = styled.div<DropdownMenuItemsWrapperProps>`
  position: absolute;
  top: 100%;
  padding: 4px 0px;
  left: 0;
  border: 1px solid ${GetColor.HintGrey};
  border-radius: 3px;
  background-color: ${GetColor.White};
  font-size: 13px;
  box-shadow: 0 1px 2px 0 ${GetColor.LightGrey};
  z-index: ${ZIndex.ModalCover};

  ${(props) =>
    props.anchorMargin
      ? css`
          margin-top: 10px;
          margin-bottom: 10px;
        `
      : ''};
`;

interface DropdownMenuItemsProps extends HTMLAttributes<HTMLDivElement> {
  dropDirection?: DropdownMenuItemsDropDirection;
  anchorMargin?: boolean;
  onClose?: () => void;
  footer?: JSX.Element;
}

export const DropdownMenuItems: FC<React.PropsWithChildren<DropdownMenuItemsProps>> = ({
  children,
  className,
  dropDirection,
  anchorMargin,
  footer,
  style,
  ...restProps
}) => (
  <DropdownMenuItemsWrapper
    anchorMargin={anchorMargin}
    className={className ?? 'dropdown-menu-items-wrapper'}
    style={style}
  >
    {Children.map(children, (child) => (React.isValidElement(child) ? cloneElement(child, restProps) : child))}
    {footer}
  </DropdownMenuItemsWrapper>
);

export default DropdownMenuItems;
