import { Routes } from 'venn-utils';
import styled, { css } from 'styled-components';
import { GetColor, ZIndex } from 'venn-ui-kit';

/**
 * If route changes to one of the keys from one of its values, the key route should slide in.
 * If route changes from one of the keys to one of its values, the key route should slide out.
 */
const PATH_TRANSITIONS: { [key: string]: Routes[] } = {
  [Routes.MANAGE_DATA_PATH]: [Routes.LIBRARIES_PATH, Routes.ANALYSIS_PATH],
};

export const SLIDE_IN_RIGHT = 'slide-in-right';
export const SLIDE_OUT_RIGHT = 'slide-out-right';

/**
 * Checks whether there is a transition associated from going from the previous path to the current one, or vice versa,
 * and returns the appropriate animation class name.
 * @param currentPathname from the current location.pathname
 * @param previousPathMatch the matched route/path of the previous url, i.e. Routes.*_PATH
 */
export const getAnimationClassName = (currentPathname: string, previousPathMatch?: Routes): string => {
  // animation is too slow in IE so just disable it
  if (!previousPathMatch) {
    return '';
  }

  const isCurrentPathMatch = (path: string) => currentPathname.startsWith(path);
  const currentPathMatch = Object.keys(PATH_TRANSITIONS).find(isCurrentPathMatch);

  // Should the current path slide in?
  if (currentPathMatch && PATH_TRANSITIONS[currentPathMatch]?.includes(previousPathMatch)) {
    return SLIDE_IN_RIGHT;
  }

  // Should the previous path slide out?
  if (PATH_TRANSITIONS[previousPathMatch]?.find(isCurrentPathMatch)) {
    return SLIDE_OUT_RIGHT;
  }

  return '';
};

const absolutePositioning = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

/*
 * In the animations below, we apply the following ideas:
 * - We only want the <main></main> part to be seen when moving so that we don't duplicate the main nav.
 * - The moving part should have a white background so that the page below it is covered.
 * - The exiting part should not affect the positioning of the entering part so give it styling
 *    so that it's absolutely positioned while maintaining it's original position in the page.
 * - The non-moving part should be below the moving part in terms of z-index.
 */
export const AnimationStyles = styled.div`
  /* SLIDE IN */

  .${SLIDE_IN_RIGHT}-enter main {
    background-color: ${GetColor.White};
    transform: translateX(100%);
  }

  .${SLIDE_IN_RIGHT}-enter.${SLIDE_IN_RIGHT}-enter-active main {
    transition: transform 0.5s ease-in;
    transform: translateX(0%);
  }

  .${SLIDE_IN_RIGHT}-exit, .${SLIDE_IN_RIGHT}-exit-active, .${SLIDE_IN_RIGHT}-exit-done {
    ${absolutePositioning}
    z-index: ${ZIndex.Background};
  }

  /* SLIDE OUT */

  .${SLIDE_OUT_RIGHT}-exit {
    ${absolutePositioning}
    visibility: hidden;
    transform: translateX(0%);

    main {
      visibility: visible;
      background-color: ${GetColor.White};
    }
  }

  .${SLIDE_OUT_RIGHT}-exit-active {
    transition: transform 0.5s ease-out;
    transform: translateX(100%);
  }
`;
