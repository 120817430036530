import { compact } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { TABS, UPLOAD_TYPE_KEY } from 'venn-api';
import { analyticsService } from 'venn-utils';
import { UserContext } from '../../../contexts';
import SideMenu from '../../../side-menu/SideMenu';
import DataGallery from '../components/DataGallery';
import DataInput from '../components/DataInput';
import { ErrorMessage } from '../components/page-parts';
import PasteInput from '../components/PasteInput';
import Context from '../context';
import { DataUploaderMode, type MultiUploaderUploadViewId } from '../types';
import { getTabName } from '../utils';
import SampleFile from './sample';
import { UniversalUploaderFooter } from '../components/page-parts/UniversalUploaderFooter';
import { MainUploadRow, MainUploadWrapper, UploadContent, UploadSideBar } from './shared/layout';

interface UploadProps {
  loading: boolean;
  onCancel: () => void;
  viewId: MultiUploaderUploadViewId;
  onUpload: (data: File) => void;
  onPaste: (data: Blob, isSample?: boolean) => void;
}

export const InvestmentUploadView = ({ onCancel, onUpload, onPaste, loading, viewId }: UploadProps) => {
  const { error } = useContext(Context);
  const { hasCompletedAnUpload, updateSettings, settings } = useContext(UserContext);

  // TODO: When the capability to paste is added for privates, the following line should be only settings?.user?.[UPLOAD_TYPE_KEY];
  const defaultUploader = viewId === 'UPLOAD_PRIVATES' ? TABS.UPLOAD : settings?.user?.[UPLOAD_TYPE_KEY];
  const [tab, setTab] = useState<TABS>(defaultUploader || TABS.UPLOAD);

  const [errorMessage, setErrorMessage] = useState<string | React.ReactNode>(error);
  useEffect(() => {
    setErrorMessage(error);
  }, [error]);

  const showProceedWithSample = viewId === 'UPLOAD_RETURNS' && !hasCompletedAnUpload;
  const onContinueWithSample = () => {
    const data = new Blob([SampleFile], { type: 'text/plain' });
    onPaste(data, true);
  };

  const onSwitchTab = useCallback(
    (selectedTab: TABS) => {
      setTab(selectedTab);
      const updatedUserSettings = {
        [UPLOAD_TYPE_KEY]: selectedTab,
      };
      updateSettings(updatedUserSettings);
      analyticsService.uploadTabChanged({
        TabName: getTabName(selectedTab),
        Nav: false,
        isPrivatesUploader: viewId === 'UPLOAD_PRIVATES',
      });
    },
    [updateSettings, viewId],
  );

  const uploadType = viewId === 'UPLOAD_PRIVATES' ? 'Data' : 'Returns';
  const items = compact([
    {
      label: `Upload ${uploadType}`,
      value: TABS.UPLOAD,
    },
    viewId !== 'UPLOAD_PRIVATES'
      ? {
          label: `Paste ${uploadType}`,
          value: TABS.PASTE,
        }
      : null,
    {
      label: 'Formats and Templates',
      value: TABS.TEMPLATES,
    },
  ]);

  const proceedWithSampleProps = showProceedWithSample
    ? {
        primaryLabel: 'Proceed with sample data',
        primaryClassName: 'qa-upload-sample',
        onContinue: onContinueWithSample,
      }
    : {};

  const modalFooterProps = {
    onCancel,
    ...proceedWithSampleProps,
  };

  const legacyMode = viewId === 'UPLOAD_PRIVATES' ? DataUploaderMode.Privates : DataUploaderMode.Returns;
  return (
    <MainUploadWrapper>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <MainUploadRow>
        <UploadSideBar>
          <SideMenu
            refreshedStyling
            items={items}
            onClick={onSwitchTab}
            selectedItems={tab}
            withoutContainer
            allowItemOverflow
          />
        </UploadSideBar>
        <UploadContent>
          {tab === TABS.UPLOAD && (
            <DataInput
              isUniversalUploader
              onFileChange={onUpload}
              loading={loading}
              setError={setErrorMessage}
              mode={legacyMode}
            />
          )}
          {tab === TABS.PASTE && <PasteInput onPaste={onPaste} mode={legacyMode} isUploading={loading} />}
          {tab === TABS.TEMPLATES && <DataGallery mode={legacyMode} isUniversalUploader />}
        </UploadContent>
      </MainUploadRow>
      <UniversalUploaderFooter {...modalFooterProps} />
    </MainUploadWrapper>
  );
};
