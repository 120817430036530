import {
  STUDIO_COVER_PAGE_DISCLOSURE_DEFAULT_FONT_SIZE,
  STUDIO_DEFAULT_DISCLAIMER_FONT_SIZE,
  STUDIO_DEFAULT_DISCLOSURE_FONT_SIZE,
  pxToPt,
} from 'venn-utils';
import type { CustomFonts } from './types';

export const STUDIO_DEFAULT_TABLE_DATA = pxToPt(12);
export const STUDIO_COMPACT_TABLE_DATA = pxToPt(9);

export const tableSpaceSettings = {
  extraSmall: 0.18,
  small: 0.24,
  medium: 0.317,
  large: 0.4,
};

export const STUDIO_DEFAULT_GRID_SIZE_RATIO = tableSpaceSettings.medium;
export const STUDIO_COMPACT_GRID_SIZE_RATIO = tableSpaceSettings.small;
export const REPORT_LAB_GRID_SIZE_RATIO_DEFAULT = tableSpaceSettings.small;

export const DEFAULT_FONT_SIZE_OVERRIDES = Object.freeze({
  titlePageFontOverrides: {
    dateFontSize: 14,
    titleFontSize: 28,
    subtitleFontSize: 20,
    additionalTextFontSize: 20,
  },
  sectionPageFontOverrides: {
    nameFontSize: 14,
    titleFontSize: 36,
    subtitleFontSize: 14,
    orgDisplayNameFontSize: 14,
  },
  blockFontOverrides: {
    titleFontSize: 12,
    subtitleFontSize: 10,
    headerFontSize: 8,
    tableFontSize: 8,
    chartAxisFontSize: 8,
    chartLegendFontSize: 8,
  },
  legalFontOverrides: {
    coverPageDisclosureFontSize: STUDIO_COVER_PAGE_DISCLOSURE_DEFAULT_FONT_SIZE,
    disclaimerFontSize: STUDIO_DEFAULT_DISCLAIMER_FONT_SIZE,
    disclosureFontSize: STUDIO_DEFAULT_DISCLOSURE_FONT_SIZE,
  },
});

export const FONT_SIZE_RANGES = Object.freeze({
  titlePageFontOverrides: {
    dateFontSize: { min: 5, max: 20 },
    titleFontSize: { min: 5, max: 40 },
    subtitleFontSize: { min: 5, max: 40 },
    additionalTextFontSize: { min: 5, max: 40 },
  },
  sectionPageFontOverrides: {
    nameFontSize: { min: 5, max: 20 },
    titleFontSize: { min: 5, max: 40 },
    subtitleFontSize: { min: 5, max: 40 },
    orgDisplayNameFontSize: { min: 5, max: 20 },
  },
  blockFontOverrides: {
    titleFontSize: { min: 5, max: 20 },
    subtitleFontSize: { min: 5, max: 20 },
    headerFontSize: { min: 5, max: 14 },
    tableFontSize: { min: 5, max: 12 },
    chartAxisFontSize: { min: 5, max: 14 },
    chartLegendFontSize: { min: 5, max: 14 },
  },
  legalFontOverrides: {
    coverPageDisclosureFontSize: { min: 5, max: 20 },
    disclaimerFontSize: { min: 5, max: 20 },
    disclosureFontSize: { min: 5, max: 20 },
  },
});

const SHARED_FONT_DEFAULTS = Object.freeze({
  sectionPageName: {
    fontSizePt: DEFAULT_FONT_SIZE_OVERRIDES.sectionPageFontOverrides.nameFontSize,
  },
  sectionPageTitle: {
    fontSizePt: DEFAULT_FONT_SIZE_OVERRIDES.sectionPageFontOverrides.titleFontSize,
  },
  sectionPageSubtitle: {
    fontSizePt: DEFAULT_FONT_SIZE_OVERRIDES.sectionPageFontOverrides.subtitleFontSize,
  },
  sectionPageOrgName: {
    fontSizePt: DEFAULT_FONT_SIZE_OVERRIDES.sectionPageFontOverrides.orgDisplayNameFontSize,
  },
  titlePageContent: {
    fontSizePt: DEFAULT_FONT_SIZE_OVERRIDES.titlePageFontOverrides.additionalTextFontSize,
  },
  titlePageDate: {
    fontSizePt: DEFAULT_FONT_SIZE_OVERRIDES.titlePageFontOverrides.dateFontSize,
  },
  titlePageSubtitle: {
    fontSizePt: DEFAULT_FONT_SIZE_OVERRIDES.titlePageFontOverrides.subtitleFontSize,
  },
  titlePageTitle: {
    fontSizePt: DEFAULT_FONT_SIZE_OVERRIDES.titlePageFontOverrides.titleFontSize,
  },
} as const satisfies Partial<CustomFonts>);

export const REPORT_LAB_FONT_DEFAULTS = Object.freeze({
  ...SHARED_FONT_DEFAULTS,
  blockTitle: {
    fontSizePt: DEFAULT_FONT_SIZE_OVERRIDES.blockFontOverrides.titleFontSize,
  },
  blockSubtitle: {
    fontSizePt: DEFAULT_FONT_SIZE_OVERRIDES.blockFontOverrides.subtitleFontSize,
  },
  blockHeaderInfo: {
    fontSizePt: DEFAULT_FONT_SIZE_OVERRIDES.blockFontOverrides.headerFontSize,
  },
  blockTableData: {
    fontSizePt: DEFAULT_FONT_SIZE_OVERRIDES.blockFontOverrides.tableFontSize,
  },
  blockChartAxis: {
    fontSizePt: DEFAULT_FONT_SIZE_OVERRIDES.blockFontOverrides.chartAxisFontSize,
  },
  blockChartLegend: {
    fontSizePt: DEFAULT_FONT_SIZE_OVERRIDES.blockFontOverrides.chartLegendFontSize,
  },
} as const satisfies CustomFonts);

/**
 * Represents the values used for studio block font size defaults when a block is first created.
 *
 * In Studio, mapped values from before the custom font size project and new default values should be the same.
 */
export const STUDIO_FONT_DEFAULTS = Object.freeze({
  ...SHARED_FONT_DEFAULTS,
  blockTitle: {
    fontSizePt: pxToPt(22),
  },
  blockSubtitle: {
    fontSizePt: pxToPt(12),
  },
  blockHeaderInfo: {
    fontSizePt: pxToPt(14),
  },
  blockTableData: {
    fontSizePt: STUDIO_DEFAULT_TABLE_DATA,
  },
  blockChartAxis: {
    fontSizePt: pxToPt(10),
  },
  blockChartLegend: {
    fontSizePt: pxToPt(12),
  },
} as const satisfies CustomFonts);

export const NEW_LINE_HEIGHT_DEFAULTS = Object.freeze({
  sectionPageName: {
    lineHeight: String(1),
  },
  sectionPageTitle: {
    lineHeight: String(1.25),
  },
  sectionPageSubtitle: {
    lineHeight: String(1.5),
  },
  sectionPageOrgName: {
    lineHeight: String(1),
  },
  titlePageContent: {
    lineHeight: String(1.25),
  },
  titlePageDate: {
    lineHeight: String(1),
  },
  titlePageSubtitle: {
    lineHeight: String(1.25),
  },
  titlePageTitle: {
    lineHeight: String(1.25),
  },
});
