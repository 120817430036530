import type { SignupRequest } from 'venn-api';

const hubspotFieldToSignUpValue = {
  email: 'email',
  firstname: 'firstName',
  lastname: 'lastName',
  company: 'organizationLegalName',
};

const sendHubspotTrackingInformation = (signupValues: Partial<SignupRequest>) => {
  // Send form data to hubspot form
  try {
    const requestInit = {
      method: 'POST',
      body: JSON.stringify({
        fields: ['email', 'firstname', 'lastname', 'company'].map((name) => ({
          name,
          value: signupValues[hubspotFieldToSignUpValue[name]],
        })),
        context: {
          pageUri: window.location.href,
          pageName: 'Sign Up',
        },
      }),
      headers: { 'Content-Type': 'application/json' },
    };
    const portalId = 5638584;
    const formId = '49f9b981-c65c-414b-99a6-3d4224360c94';
    fetch(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`, requestInit);
  } catch {
    // Do nothing, if there's flakiness with hubspot, we don't want the user to
    // be notified
  }
};
export default sendHubspotTrackingInformation;
