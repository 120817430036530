import type { CSSProperties, ReactNode } from 'react';
import React, { useMemo } from 'react';
import SimpleTrigger from '../triggers/SimpleTrigger';
import BaseDropMenu from './BaseDropMenu';
import CategorizedMenuItem from '../menus/CategorizedMenuItem';
import type { CategorizedMenuProps } from '../menus/CategorizedMenu';
import CategorizedMenu from '../menus/CategorizedMenu';
import type { BaseDropMenuProps, DropMenuItem, MenuCategory, MenuContainerProps } from '../types';
import { flatten, isEqual } from 'lodash';

interface CategorizedDropMenuProps<T> extends CategorizedMenuProps<T>, BaseDropMenuProps<T>, MenuContainerProps {
  menuClassName?: string;
  onChange: (item: DropMenuItem<T>, category?: MenuCategory<T, DropMenuItem<T>>) => void;
  triggerStyle?: CSSProperties;
  menuStyle?: CSSProperties;
  renderInnerCategoryItem?: (item: DropMenuItem<T>, isSelected?: boolean) => ReactNode;
  alwaysShowTriggerTooltip?: boolean;
  selectionComparator?: (a: T | undefined, b: T | undefined) => boolean;
  getHeaderComponent?: (onCollapse?: () => void) => JSX.Element;
  triggerIcon?: JSX.Element;
  // Tooltip positioning props
  tooltipUsePortal?: boolean;
  tooltipFlex?: boolean;
  placeholder?: string;
}

export function CategorizedDropMenu<T>({
  label,
  categories,
  selected,
  className,
  menuClassName,
  openByDefault,
  usePortal,
  disabled,
  width,
  height,
  innerRef,
  onChange,
  triggerStyle,
  menuStyle,
  headerComponent,
  getHeaderComponent,
  footerComponent,
  renderInnerCategoryItem,
  hideSeparator,
  getSelectionLabel,
  getTooltipContent,
  alwaysShowTriggerTooltip,
  selectionComparator = isEqual,
  triggerIcon,
  tooltipUsePortal,
  tooltipFlex,
  placeholder,
}: CategorizedDropMenuProps<T>) {
  const items = useMemo(() => flatten(categories.map(({ items: categoryItems }) => categoryItems)), [categories]);
  const filteredItems = useMemo(
    () =>
      flatten(
        categories
          .filter(({ disabled: categoryDisabled }) => !categoryDisabled)
          .map(({ items: categoryItems }) => categoryItems),
      ),
    [categories],
  );
  const selectedItem = useMemo(
    () => items.find((item) => selectionComparator(item.value, selected)),
    [selected, items, selectionComparator],
  );
  const selection =
    getSelectionLabel?.(selectedItem!) ?? (selected !== null && selectedItem ? selectedItem.label : undefined);

  return (
    <BaseDropMenu
      style={menuStyle}
      className={menuClassName}
      openByDefault={openByDefault}
      usePortal={usePortal}
      filteredItems={filteredItems}
      selectedItem={selectedItem}
      triggerComponent={(expanded, _, onToggle, handleKeyEsc, handleKeyEnter, handleKeyUp, handleKeyDown) => (
        <SimpleTrigger
          style={triggerStyle}
          className={className}
          placeholder={placeholder}
          disabled={disabled}
          label={label}
          expanded={expanded}
          selection={selection}
          selectionIcon={triggerIcon}
          innerRef={innerRef}
          onClick={onToggle}
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
          onKeyEsc={handleKeyEsc}
          onKeyEnter={handleKeyEnter}
          getTooltipContent={getTooltipContent}
          alwaysShowTooltip={alwaysShowTriggerTooltip}
          tooltipUsePortal={tooltipUsePortal}
          tooltipFlex={tooltipFlex}
        />
      )}
      menuComponent={(highlighted, onCollapse, dropMenuClassName) => (
        <CategorizedMenu<T>
          className={dropMenuClassName}
          categories={categories}
          renderItem={(item, category, index) => (
            <CategorizedMenuItem<T>
              key={index}
              item={item}
              disabled={category.disabled}
              highlighted={!!highlighted && item.value === highlighted.value}
              selected={selectionComparator(item.value, selected)}
              onClick={() => {
                onCollapse();
                onChange(item, category);
              }}
              renderItem={renderInnerCategoryItem}
            />
          )}
          selected={selected}
          width={width}
          height={height}
          onChange={(item) => {
            onCollapse();
            onChange(item);
          }}
          headerComponent={getHeaderComponent?.(onCollapse) ?? headerComponent}
          footerComponent={footerComponent}
          hideSeparator={hideSeparator}
        />
      )}
      onChange={onChange}
    />
  );
}

export default CategorizedDropMenu;
