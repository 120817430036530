import React, { useCallback, useEffect, useRef, useContext, useState } from 'react';
import type Select from 'react-select';

import { analyticsService, LibraryItemType } from 'venn-utils';
import type { SelectTypes } from 'venn-ui-kit';

import useSearchMenu from './useSearchMenu';
import { getDefaultColumns } from './components/Columns';
import FiltersProvider, { FiltersContext } from './components/FiltersProvider';
import type { GenericSearchMenuProps, SearchMenuItem, CustomSelectProps } from './types';

import { StyledSelect } from './styled';
import ValueContainer from './components/ValueContainer';
import MenuList from './components/MenuList';
import Menu from './components/Menu';
import Option from './components/Option';
import { placeholder } from './components/shared';
import { getOptionDisabledFunc, getOptionDisabledMessageFunc, itemEqualityCheck } from './utils';
import { compact } from 'lodash';

interface SearchMenuProps extends GenericSearchMenuProps {
  forceHideResults?: boolean;
  onSelected: (selected: SearchMenuItem) => void;
}

const menuComponents = {
  ValueContainer,
  MenuList,
  Menu,
  Option,
};

const alwaysTrue = () => true;

const SearchMenuBar = ({
  onSelected,
  onBlur,
  autofocus = true,
  defaultMenuIsOpen = true,
  investmentsOnly = false,
  portfoliosOnly = false,
  privateAssetSearchMode = 'PUBLIC_ONLY',
  excludedItems,
  defaultValue,
  onClear,
  value,
  getOptionValue,
  disabled,
  isOptionDisabled,
  onQueryChange,
  showRecentlyAnalyzed = true,
  className,
  classNamePrefix,
  initialQuery,
  shortPlaceholder,
  fixedMenuWidth,
  optionDisabledTooltipContent,
  usePortal,
  customPlaceholder,
  proxyable = false,
  displayResultsAsBlock,
  smallScreen = false,
  columns = getDefaultColumns(portfoliosOnly, smallScreen),
  location,
  showQuickFilters = false,
  footer,
  clearQueryOnBlur = false,
  darkPlaceholder = false,
  placeholderIds,
  menuStyles,
  canSearchEverything,
  closeOnSelect,
  forceHideResults = false,
  includeTags = true,
  includeBenchmarks = true,
  refreshedStyling = false,
}: SearchMenuProps) => {
  const selectRef = useRef<Select<SearchMenuItem>>(null);
  // Flag for if a value has been selected from the menu
  const hasSelectedValueRef = useRef<boolean>(false);
  const { selectedFilters, setSelectedFilters } = useContext(FiltersContext);
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const tagsPresent = selectedFilters.find((item) => item.itemType === LibraryItemType.TAGS);
  const {
    items,
    totalResults,
    loading,
    query,
    onSearch,
    onSelect,
    onTagsDropdown,
    onTagsClose,
    selectedTagDropdowns,
    openedTags,
    onCloseAllTags,
  } = useSearchMenu({
    onSelected,
    location,
    selectedFilters,
    menuIsOpen,
    excludedItems,
    investmentsOnly,
    portfoliosOnly,
    privateAssetSearchMode,
    selectedValue: getOptionValue && value ? getOptionValue(value) : undefined,
    showRecentlyAnalyzed,
    proxyable,
    initialQuery,
    placeholderIds,
    includeTags,
  });

  const noOptionsMessage = useCallback(() => {
    if (!forceHideResults) {
      if (loading) {
        return 'Searching...';
      }
      return (
        <>
          <div>
            <b>No results found for your query</b>
          </div>
          <div>
            {/* // VENN-20517 tags will not work in the library, we shouldn't suggest going to the library */}
            {footer && !tagsPresent
              ? 'You may want to try using different keywords or click below to see all of the items in the Library.'
              : `You may want to try using different keywords or searching by name${
                  !portfoliosOnly ? ' or ticker' : ''
                }.`}
          </div>
        </>
      );
    }
    return loading && !forceHideResults ? 'Loading...' : null;
  }, [forceHideResults, loading, footer, tagsPresent, portfoliosOnly]);

  const closeMenu = useCallback(() => {
    // blur causes onMenuBlur to fire which closes the menu
    // we don't directly close the menu because the input won't blur and we get to a weird state
    selectRef.current?.blur();
  }, []);

  const onInputChange = useCallback(
    (search: string, { action }: SelectTypes.InputActionMeta) => {
      switch (action) {
        case 'input-change':
          hasSelectedValueRef.current = false;
          onQueryChange?.(search);
          onSearch(search);
          break;
        case 'set-value':
          hasSelectedValueRef.current = true;
          break;
      }
    },
    [onQueryChange, onSearch],
  );

  const onChange = useCallback(
    (selection: SelectTypes.ValueType<SearchMenuItem>) => {
      const newValue: SearchMenuItem =
        selection && !Array.isArray(selection)
          ? selection
          : Array.isArray(selection) && selection.length > 0
            ? selection[0]
            : undefined;
      if (newValue) {
        onSelect(newValue);
        closeMenu();
      }
    },
    [closeMenu, onSelect],
  );

  // used to differentiate the initial closed state from the actual close event
  const hasMenuOpened = useRef(false);
  useEffect(() => {
    // Whenever menuIsOpen is false, it must be a search dismissal
    // aside from the very first time, as denoted by hasMenuOpened.current being false
    if (hasMenuOpened.current && !menuIsOpen) {
      const noResultsShowing = !query && !showRecentlyAnalyzed;
      if (!hasSelectedValueRef.current && !noResultsShowing) {
        const quickFilters = compact(selectedFilters.map((filter) => filter.quickFilter));
        const typeFilters = compact(selectedFilters.map((filter) => filter.itemType));
        analyticsService.searchDismissed({
          query,
          location,
          totalResults,
          visibleResults: items.length,
          quickFilters,
          typeFilters,
        });
      }
      if (clearQueryOnBlur) {
        onClearSearch();
        setSelectedFilters(() => []);
      }
    } else if (menuIsOpen) {
      hasMenuOpened.current = true;
    }
    // Disabled 'location', 'query', and 'showRecentlyAnalyzed', as we want to track this event based only on menuIsOpen flag
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuIsOpen]);

  const onClearSearch = useCallback(() => {
    onSearch('');
  }, [onSearch]);

  // Do not show a placeholder if filters are selected
  const showPlaceholder = !selectedFilters.length;

  const onMenuBlur = useCallback(() => {
    onBlur?.();
    setMenuIsOpen(false);
    onCloseAllTags();
  }, [onBlur, onCloseAllTags]);

  const onMenuFocus = useCallback(() => {
    setMenuIsOpen(true);
    hasSelectedValueRef.current = false;
  }, []);

  const handleKeyEvents = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key !== 'Enter') return;

      const focusedClickedOption = selectRef.current?.select.state.focusedOption;
      if (!focusedClickedOption) {
        return;
      }

      if (focusedClickedOption?.category !== 'tag') {
        return;
      }
      e.preventDefault();

      const isTagOpen =
        openedTags.some((openedTag: SearchMenuItem) => itemEqualityCheck(openedTag, focusedClickedOption)) || false;

      isTagOpen ? onTagsClose(focusedClickedOption) : onTagsDropdown(focusedClickedOption);
    },
    [onTagsClose, onTagsDropdown, openedTags],
  );

  useEffect(() => {
    if (autofocus) {
      selectRef.current?.focus();
    }
  }, [autofocus, selectRef]);

  const customProps: CustomSelectProps = {
    columns,
    onClearSearch,
    fixedMenuWidth,
    showQuickFilters,
    investmentsOnly,
    portfoliosOnly,
    privateAssetSearchMode,
    footer,
    closeSearchMenu: closeMenu,
    onClear,
    onTagsDropdown,
    onTagsClose,
    selectedTagDropdowns,
    openedTags,
    smallScreen,
    menuStyles,
    canSearchEverything,
    includeTags,
    includeBenchmarks,
  };

  return (
    <StyledSelect
      refreshedStyling={refreshedStyling}
      ref={selectRef}
      classNamePrefix={classNamePrefix ?? 'select'}
      className={className ?? 'qa-search-menu-bar'}
      value={value}
      getOptionValue={getOptionValue}
      defaultValue={defaultValue}
      captureMenuScroll={false}
      placeholder={
        showPlaceholder &&
        placeholder({
          customPlaceholder,
          condition: shortPlaceholder || (darkPlaceholder && !menuIsOpen),
          portfoliosOnly,
          investmentsOnly,
          smallScreen,
        })
      }
      options={!forceHideResults ? items : undefined}
      inputValue={query}
      onInputChange={onInputChange}
      onChange={onChange}
      onBlur={onMenuBlur}
      onFocus={onMenuFocus}
      onKeyDown={handleKeyEvents}
      filterOption={alwaysTrue}
      isOptionDisabled={getOptionDisabledFunc(isOptionDisabled, investmentsOnly, portfoliosOnly)}
      optionDisabledTooltipContent={getOptionDisabledMessageFunc(
        optionDisabledTooltipContent,
        investmentsOnly,
        portfoliosOnly,
      )}
      defaultMenuIsOpen={defaultMenuIsOpen}
      noOptionsMessage={noOptionsMessage}
      isDisabled={disabled}
      menuPortalTarget={usePortal ? document.body : undefined}
      displayResultsAsBlock={displayResultsAsBlock}
      hasFooter={!!footer}
      menuIsOpen={displayResultsAsBlock && !closeOnSelect ? true : menuIsOpen}
      closeMenuOnScroll={usePortal ? () => true : undefined}
      darkPlaceholder={darkPlaceholder}
      tabSelectsValue={false}
      {...customProps}
      components={menuComponents}
    />
  );
};

const MemoizedSearchMenuBar = React.memo((props: SearchMenuProps) => (
  <FiltersProvider>
    <SearchMenuBar {...props} />
  </FiltersProvider>
));

MemoizedSearchMenuBar.displayName = 'MemoizedSearchMenuBar';

export default MemoizedSearchMenuBar;
