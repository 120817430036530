import React from 'react';
import styled, { css } from 'styled-components';
import { noop } from 'lodash';
import type { FontAwesomePrefixes } from 'venn-utils';
import { buttonize } from 'venn-utils';
import { GetColor, Icon, Tooltip, TooltipPosition } from 'venn-ui-kit';
import type { TopBarDropdownMenuOption } from './TopBarDropdownMenu';
import TopBarDropdownMenu from './TopBarDropdownMenu';
import MenuCaret from './MenuCaret';
import { getTopBarColor, type GetTopBarColorType, TOP_TOOLBAR_HEIGHT } from './shared';
import { useRecoilValue } from 'recoil';
import { isReportState, isTemplateState } from 'venn-state';

export interface TopBarIconProps {
  prefix?: FontAwesomePrefixes;
  type: string;
  tooltip?: string;
  menu?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  options?: TopBarDropdownMenuOption[];
}

const emptyOptionsArr: TopBarDropdownMenuOption[] = [];
const TopBarIcon = React.memo(function TopBarIcon({
  prefix,
  type,
  tooltip,
  menu,
  onClick,
  options = emptyOptionsArr,
  disabled = false,
}: TopBarIconProps) {
  const isReport = useRecoilValue(isReportState);
  const isTemplate = useRecoilValue(isTemplateState);
  const barColors = getTopBarColor({ isReport, isTemplate });

  const menuCaret = menu && <MenuCaret />;
  const click = !disabled ? onClick ?? noop : noop;

  const button = (
    <Tooltip content={tooltip} position={TooltipPosition.Bottom} usePortal>
      <GiantToolbarButtonWrapper data-testid={tooltip} {...buttonize(click)} disabled={disabled} barColors={barColors}>
        <TopBarIconStyled prefix={prefix} type={type} disabled={disabled} barColors={barColors} />
        {menuCaret}
      </GiantToolbarButtonWrapper>
    </Tooltip>
  );

  return menu ? <TopBarDropdownMenu options={options}>{button}</TopBarDropdownMenu> : button;
});

export default TopBarIcon;

const GiantToolbarButtonWrapper = styled.div<{ disabled?: boolean; barColors: GetTopBarColorType }>`
  cursor: pointer;
  justify-self: left;
  width: ${TOP_TOOLBAR_HEIGHT};
  min-width: ${TOP_TOOLBAR_HEIGHT};
  height: ${TOP_TOOLBAR_HEIGHT};
  top: 1px;
  color: ${GetColor.White};
  display: flex;
  justify-content: center;
  align-items: center;

  transition: all 0.2s ease-out;
  ${({ disabled, barColors }) =>
    disabled
      ? css`
          color: ${barColors.MidGrey2};
          cursor: not-allowed;
        `
      : css`
          :hover {
            background-color: ${barColors.ActiveBackground};
          }
        `}
`;

const TopBarIconStyled = styled(Icon)<{ disabled?: boolean; barColors: GetTopBarColorType }>`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
  color: ${({ disabled, barColors }) => (disabled ? barColors.MidGrey2 : GetColor.White)};
`;
