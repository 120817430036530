import React, { Component } from 'react';
import styled from 'styled-components';
import type { Benchmark } from 'venn-api';
import { Icon, GetColor, Tooltip, Radio } from 'venn-ui-kit';
import type { BenchmarkAction } from '../../state/benchmarkActions';
import { BenchmarkUIView } from '../../state/benchmarkActions';
import EmptyBenchmarksList from './EmptyBenchmarksList';
import BenchmarksListFooter from './BenchmarksListFooter';
import isNil from 'lodash/isNil';

export interface BenchmarksListViewProps {
  benchmarks: Benchmark[];
  onAction: (action: BenchmarkAction) => void;
}

class BenchmarksListView extends Component<BenchmarksListViewProps> {
  addNewBenchmark = () => {
    this.props.onAction({
      type: 'BenchmarkUINavigation',
      payload: {
        target: {
          kind: 'View',
          viewId: BenchmarkUIView.SearchView,
        },
      },
    });
  };

  handleSubmit = () => {
    this.props.onAction({
      type: 'BenchmarkUINavigation',
      payload: {
        target: {
          kind: 'Exit',
          persistChanges: true,
        },
      },
    });
  };

  handleExit = () => {
    this.props.onAction({
      type: 'BenchmarkUINavigation',
      payload: {
        target: {
          kind: 'Exit',
        },
      },
    });
  };

  changeDefault = (benchmark?: Benchmark) => {
    this.props.onAction({
      type: 'BenchmarkSetDefault',
      payload: benchmark,
    });
  };

  deleteBenchmark = (benchmark: Benchmark) => {
    this.props.onAction({
      type: 'BenchmarkDelete',
      payload: benchmark,
    });
  };

  editBenchmark = (benchmark: Benchmark) => {
    if (!isNil(benchmark.fundId)) {
      this.props.onAction({
        type: 'BenchmarkLoadComposite',
        payload: {
          fundId: benchmark.fundId,
        },
      });
    }
  };

  render() {
    const { benchmarks } = this.props;
    // TODO(VENN-20115): remove eslint-disable if it is safe to do so
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare
    const currentBenchmark = benchmarks.find((benchmark) => benchmark.primary === true);
    const content =
      benchmarks.length === 0 ? (
        <Container>
          <EmptyBenchmarksList onAddNew={this.addNewBenchmark} />
        </Container>
      ) : (
        <Container>
          <Header>
            <span>Default</span>
            <span>Name</span>
          </Header>
          <BenchmarkList>
            {benchmarks.map((benchmark) => (
              <BenchmarkRow key={benchmark.fundId}>
                <StyledRadio
                  inputId={benchmark.fundId || String(benchmark.portfolioId) || benchmark.name}
                  checked={benchmark.primary}
                  onChange={() => this.changeDefault(benchmark)}
                />
                <BenchmarkName>
                  {isComposite(benchmark) && (
                    <Tooltip content="Composite benchmark" usePortal>
                      <Icon type="share-alt" />
                    </Tooltip>
                  )}
                  {benchmark.name}
                </BenchmarkName>
                <Links>
                  {isComposite(benchmark) && (
                    <button
                      onClick={() => this.editBenchmark(benchmark)}
                      data-testid="qa-benchmark-item-edit"
                      type="button"
                    >
                      Edit
                    </button>
                  )}
                  <button
                    onClick={() => this.deleteBenchmark(benchmark)}
                    data-testid="qa-benchmark-item-delete"
                    type="button"
                  >
                    Delete
                  </button>
                </Links>
              </BenchmarkRow>
            ))}
            <BenchmarkRow>
              <StyledRadio
                inputId="noBenchmark"
                checked={!currentBenchmark}
                onChange={() => this.changeDefault(currentBenchmark)}
              />
              <DisabledBenchmarkName>None</DisabledBenchmarkName>
              <Links />
            </BenchmarkRow>
          </BenchmarkList>
          <AddNew>
            <button onClick={this.addNewBenchmark} type="button" className="qa-add-new-benchmark">
              <Icon type="plus" />
              <span>Add new</span>
            </button>
          </AddNew>
        </Container>
      );
    return (
      <>
        {content}
        <BenchmarksListFooter onOKButtonClick={this.handleSubmit} onCancelButtonClick={this.handleExit} />
      </>
    );
  }
}

export default BenchmarksListView;

const isComposite = (benchmark: Benchmark): boolean => benchmark.type === 'COMPOSITE';

const columns = '110px auto 150px';

const StyledRadio = styled(Radio)`
  cursor: pointer;
`;

const Container = styled.div`
  height: 490px;
  overflow-x: hidden;
  overflow-y: auto;
`;

const BenchmarkName = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const DisabledBenchmarkName = styled(BenchmarkName)`
  color: ${GetColor.HintGrey};
  font-style: italic;
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: ${columns};
  padding: 5px 20px;
  background-color: ${GetColor.PaleGrey};
  border-bottom: 1px solid ${GetColor.Black};
  color: ${GetColor.Black};
  font-size: 12px;
  font-weight: bold;
`;

const BenchmarkList = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
`;

const BenchmarkRow = styled.li`
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 110px 1fr 150px;
  grid-template-columns: ${columns};
  padding: 0 20px;
  height: 36px;
  line-height: 36px;
  font-size: 14px;
  border-bottom: 1px solid ${GetColor.PaleGrey};
  color: ${GetColor.Black};

  button {
    display: none;
  }

  &:hover {
    background-color: #f1f1f299;
    button {
      margin-right: 20px;
      display: inline-block;
    }
  }

  i {
    font-weight: normal;
    color: ${GetColor.MidGrey2};
    margin-right: 10px;
  }
`;

const Links = styled.span`
  margin-right: 10px;
  display: inline-block;
  text-align: right;
`;

const AddNew = styled.div`
  padding: 0 0 0 130px;
  height: 36px;
  line-height: 36px;
  font-size: 14px;
  i {
    margin-right: 12px;
  }
`;
