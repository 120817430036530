import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { ButtonIcon, DropMenu, GetColor, Icon, RelativePortal, TooltipPosition } from 'venn-ui-kit';
import type { TableWorkspace } from 'venn-api';
import { cachedGetOrganizations } from 'venn-api';
import { map } from 'lodash';
import { logExceptionIntoSentry } from 'venn-utils';

export interface SwitchOrgDropdownProps {
  profileId: number;
  organizationId: number;
  onChangeOrganization: (profileId: number, organizationId: number) => void | Promise<void>;
}

interface Option {
  value: number;
  label: string;
}

export interface SwitchOrgDropdownState {
  options?: Option[];
  expanded: boolean;
}

class SwitchOrgDropdown extends PureComponent<SwitchOrgDropdownProps, SwitchOrgDropdownState> {
  private abortController: AbortController;

  state = {
    options: [],
    expanded: false,
  };

  async componentDidMount() {
    if (this.abortController) {
      this.abortController.abort();
    }

    try {
      const abortController = new AbortController();
      this.abortController = abortController;
      // @ts-expect-error: TODO fix strictFunctionTypes
      const { content } = await cachedGetOrganizations(abortController.signal);
      const options = map(content, (org: TableWorkspace) => ({
        label: `${org.name || 'No name'} (${org.id})`,
        value: org.id,
      })).sort((a: { label: string; value: number }, b: { label: string; value: number }) => {
        const cmp = a.label.localeCompare(b.label, undefined, { sensitivity: 'base' });
        if (cmp !== 0) {
          return cmp;
        }
        return a.value < b.value ? -1 : 1; // they cannot be the same unless this is the exact same org
      });
      if (!abortController.signal.aborted) {
        this.setState({
          options,
        });
      }
    } catch (e) {
      if (e.name !== 'AbortError') {
        logExceptionIntoSentry(e);
      }
    }
  }

  componentWillUnmount() {
    if (this.abortController) {
      this.abortController.abort();
    }
  }

  onChange = async (option: Option) => {
    if (option.value === this.props.organizationId) {
      return;
    }
    const { profileId, onChangeOrganization } = this.props;
    try {
      await onChangeOrganization(profileId, option.value);
    } catch (e) {
      if (e.name === 'AbortError') {
        return;
      }
      logExceptionIntoSentry(e);
    }
  };

  render() {
    const { organizationId } = this.props;
    const { options, expanded } = this.state;
    if (options.length < 2) {
      return null;
    }

    return (
      <Relative>
        <RelativePortal>
          {expanded ? (
            <section>
              <SubHeader>
                Switch Organization{' '}
                <button type="button" onClick={() => this.setState({ expanded: false })}>
                  <Icon type="caret-up" />
                </button>
              </SubHeader>
              <DropMenu
                items={options}
                selected={organizationId}
                onChange={this.onChange}
                searchable
                minSearchLength={3}
              />
            </section>
          ) : (
            <ButtonIcon
              iconType="users"
              tooltip="Switch Organization"
              tooltipPosition={TooltipPosition.Right}
              onClick={() => this.setState({ expanded: true })}
            />
          )}
        </RelativePortal>
      </Relative>
    );
  }
}

export default SwitchOrgDropdown;

const Relative = styled.div`
  position: relative;
  overflow: hidden;
  max-width: 70px;
`;

const SubHeader = styled.h2`
  font-size: 16px;
  font-weight: bold;
  margin-top: 15px;
  color: ${GetColor.DarkGrey};
`;
