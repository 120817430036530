import { useCallback, useState, useMemo } from 'react';
import type { SplitButtonDropdownItemProps } from '../split-button-dropdown/SplitButtonDropdownItem';
import { analyticsService, IS_HOSTED, useHasFF } from 'venn-utils';
import { getTextThemeProvider } from 'venn-ui-kit';

/**
 * Handle logic of export button (print and downloads) and tracking
 * @param isCompare show different option for compare and analysis view
 * @param downloadPNGs called when click download png option
 * @param downloadXlsx called when click download xlsx option
 * @param downloadAll called when click download all option
 * @param savedId saved view id
 * @param subjectId primary subject id in analysis view
 */

interface UseExportButtonParams {
  isCompare?: boolean;
  downloadPNGs?: () => void;
  downloadXlsx?: () => void;
  downloadPdf?: () => void;
  downloadAll?: () => void;
  onPrintClick?: () => void;
  savedId?: string;
  subjectId?: string;
}

const useExportButton = ({
  isCompare,
  downloadPNGs,
  downloadXlsx,
  downloadPdf,
  downloadAll,
  onPrintClick,
  savedId,
  subjectId,
}: UseExportButtonParams) => {
  const analyticsDescription = isCompare ? 'comparison results' : 'analytics results';
  const [isOpen, setOpen] = useState(false);
  const hasExportsDisabled = useHasFF('disable_exports_ff');
  const hasReportLab = useHasFF('studio_report_editor');
  const hasWatermark = useHasFF('venn_branded_watermark_ff');

  const hasExportsDisabledReason = hasWatermark
    ? `In order to remove Venn branding and export Venn output, access to Report Lab is required. 
     [Contact Us](<mailto:${getTextThemeProvider().salesEmail}?subject=Report Lab Inquiry>) to learn more.`
    : `In order export Venn output, access to Report Lab is required.
     [Contact Us](<mailto:${getTextThemeProvider().salesEmail}?subject=Report Lab Inquiry>) to learn more.`;

  const handlePrint = useCallback(() => {
    if (onPrintClick) {
      onPrintClick();
    } else if (IS_HOSTED) {
      setTimeout(window.print);
    } else {
      // eslint-disable-next-line no-console
      console.warn(
        `Unfortunately due to a Chrome error (https://bugs.chromium.org/p/chromium/issues/detail?id=956832),
        we can only print in production builds.`,
      );
    }

    if (analyticsDescription) {
      analyticsService.ctaClicked({
        destination: undefined,
        text: 'Print',
        purpose: `Print out ${analyticsDescription}`,
        type: 'button',
        filled: false,
        viewId: savedId,
        subjectId,
      });
    }
  }, [analyticsDescription, onPrintClick, savedId, subjectId]);

  const handlePrintClick = useCallback(() => {
    handlePrint();
    setOpen(false);
  }, [handlePrint]);

  const handleDownloadXlsxClick = useCallback(() => {
    setOpen(false);
    downloadXlsx?.();
    analyticsService.ctaClicked({
      destination: undefined,
      text: 'Download XLSXs',
      purpose: 'Download data',
      type: 'button',
      filled: false,
      subjectId,
      viewId: savedId,
    });
  }, [downloadXlsx, subjectId, savedId]);

  const handleDownloadPdfClick = useCallback(() => {
    setOpen(false);
    downloadPdf?.();
    analyticsService.ctaClicked({
      destination: undefined,
      text: 'Download PDFs',
      purpose: 'Download data',
      type: 'button',
      filled: false,
      subjectId,
      viewId: savedId,
    });
  }, [downloadPdf, subjectId, savedId]);

  const handleDownloadPngsClick = useCallback(() => {
    setOpen(false);
    downloadPNGs?.();
    analyticsService.ctaClicked({
      destination: undefined,
      text: 'Download PNGs',
      purpose: 'Download images',
      type: 'button',
      filled: false,
      subjectId,
      viewId: savedId,
    });
  }, [downloadPNGs, subjectId, savedId]);

  const handleDownloadAllClick = useCallback(() => {
    setOpen(false);
    downloadAll?.();
    analyticsService.ctaClicked({
      destination: undefined,
      text: 'Download Both Formats',
      purpose: 'Download Both Formats',
      type: 'button',
      filled: false,
      subjectId,
      viewId: savedId,
    });
  }, [downloadAll, subjectId, savedId]);

  const exportOptions: SplitButtonDropdownItemProps[] = useMemo(() => {
    const downloadXLSXOption = {
      label: 'Download XLSX',
      icon: 'download',
      onClick: downloadXlsx ? handleDownloadXlsxClick : undefined,
    };

    if (isCompare) {
      return [downloadXLSXOption];
    }

    return [
      ...(downloadXlsx ? [downloadXLSXOption] : []),
      ...(downloadPdf
        ? [
            {
              label: 'Download PDF',
              icon: 'download',
              onClick: handleDownloadPdfClick,
            },
          ]
        : []),
      ...(downloadPNGs && !hasExportsDisabled
        ? [
            {
              label: 'Download PNGs',
              icon: 'download',
              onClick: handleDownloadPngsClick,
            },
          ]
        : []),
      ...(downloadAll && !hasExportsDisabled
        ? [
            {
              label: 'Download Both Formats',
              icon: 'download',
              onClick: handleDownloadAllClick,
            },
          ]
        : []),
    ];
    // When the menu open, query the current dom to see if there are downloadable items
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return {
    isOpen,
    setOpen,
    handlePrintClick,
    exportOptions,
    hasExportsDisabled,
    hasExportsDisabledReason,
    hasReportLab,
  };
};

export default useExportButton;
