import type { FrequencyEnum, RollingAnalysisResponse } from 'venn-api';
import type { AnalysisSubject, AnalysisGroup, TimeFrame } from 'venn-utils';
import type { TrackAnalysisProps } from '../chart-errors/TrackAnalysis';
import type { PlotLineOptions, PlotArearangeOptions, PlotAreaOptions, PlotPieOptions } from 'highcharts';
import type { DownloadMetaData } from '../../downloadable';

export enum RollingPeriodEnum {
  ONE_YEAR,
  THREE_YEAR,
  FIVE_YEAR,
}

export interface RollingPeriod {
  key: number;
  name: string;
}

export interface SerieData {
  absolute: number[][];
  relative: number[][];
}

export interface FullPeriodValue {
  label: string;
  value?: number;
}

export interface FullPeriodValueData {
  absolute: FullPeriodValue;
  relative: FullPeriodValue;
}

export interface ChartSerieWithRollingPeriods {
  name: string;
  data: { [key in RollingPeriodEnum]: SerieData };
  customData?: SerieData;
  fullPeriodValueLine?: FullPeriodValueData;
}

export interface ChartDataWithRollingPeriods {
  main: ChartSerieWithRollingPeriods;
  secondary?: ChartSerieWithRollingPeriods;
  benchmark?: ChartSerieWithRollingPeriods;
  category?: ChartSerieWithRollingPeriods;
}

export interface ChartSerie {
  name: string;
  data: SerieData;
  fullPeriodValueLine?: FullPeriodValueData;
  estimated?: {
    data: SerieData;
    estimatedBounds: [number, number, number][];
  };
}

export interface ChartData {
  main: ChartSerie;
  secondary?: ChartSerie;
  benchmark?: ChartSerie;
  category?: ChartSerie;
}

export type HighchartChartType = 'line' | 'arearange' | 'dashline' | 'area' | 'pie' | 'column' | 'scatter';
type PlotOptions = PlotLineOptions & PlotArearangeOptions & PlotAreaOptions & PlotPieOptions;
export interface PartialHighchartSerieData extends PlotOptions {
  name: string;
  type: HighchartChartType;
  zIndex?: number;
  color: string;
  data: (number | [number, number] | [number, number, number])[];
  fillColor?: string;
  lineColor?: string;
  lineWidth?: number;
  dashStyle?: 'ShortDot';
}

export interface SerieValue {
  name: string;
  legendLabel: string;
  data: PartialHighchartSerieData;
  color: string;
  fullPeriodValueLine?: FullPeriodValue;
}

export interface ExternalChartProps {
  subject: AnalysisSubject;
  relative: boolean;
  noChartErrorComponent: JSX.Element;
  noChartTrackingProps?: TrackAnalysisProps;
  downloadMetaData?: DownloadMetaData;
}

export interface RollingChartProps {
  subject: AnalysisSubject;
  frequency?: FrequencyEnum;
  actualTimeFrame: TimeFrame;
  relative: boolean;
  analysisGroup: AnalysisGroup<RollingAnalysisResponse>[];
  exportableGroup?: AnalysisGroup<boolean>;
  noChartErrorComponent: JSX.Element;
  noChartTrackingProps?: TrackAnalysisProps;
  downloadMetaData?: DownloadMetaData;
  categoryActive: boolean;
}

export interface FullPeriodLabels {
  absolute: string;
  relative: string;
}

export type UnitFormat = 'percent' | 'ratio' | 'allocation';
