import type { FC } from 'react';
import React from 'react';
import { Loading } from 'venn-ui-kit';
import styled from 'styled-components';

export interface StyledLoadProps {
  loading: boolean;
  width: number;
}

const Wrapper = styled.div`
  position: absolute;
  @media print {
    display: none;
  }
`;

const TOP = 200;

const StyledLoad: FC<React.PropsWithChildren<StyledLoadProps>> = ({ loading, width }) => (
  <Wrapper style={{ top: TOP, left: width / 2 }}>{loading && <Loading />}</Wrapper>
);

export default StyledLoad;
