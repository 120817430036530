import React from 'react';
import type { AllocationConstraint, Fund, OptimizationConfiguration, Portfolio, PortfolioSummary } from 'venn-api';
import type { AnalysisSubjectSecondaryLabel, InvestmentMixedType } from 'venn-utils';
import {
  addInvestmentsToPortfolio,
  arePortfoliosEqual,
  analyticsService,
  AnalyticsUtils,
  calculateComparison,
  deleteConstrainingFundFromPortfolio,
  logMessageToSentry,
  mapPortfolioToNodes,
  normalizePortfolio,
  selectStrategy,
  getAnalysisPathForPortfolio,
} from 'venn-utils';
import type { DropMenuItem } from 'venn-ui-kit';
import AllocationPanelContent from './AllocationPanelContent';
import AllocationPlus from './AllocationPlus';
import { clone, isNil, noop } from 'lodash';
import UnsavedChangesModal from '../unsaved-changes/UnsavedChangesModal';
import {
  checkHasQuantDiff,
  getCurrentBaseAllocation,
  getWithReplacedStrategy,
  TreeItemUpdateType,
} from './AllocationUtils';
import type { CompareType } from '../contexts/comparison-context';
import type { ContextDerivedProps } from './withContextsProps';
import withContextsProps from './withContextsProps';
import type { ItemPercentageProps } from './ItemAllocation';
import type { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

interface AllocationPanelProps extends ContextDerivedProps, RouteComponentProps {
  portfolio: Portfolio;
  selectedStrategyId?: number;
  hasAllocationError?: boolean;
  onUpdateAllocationError?: (hasAllocationError: boolean) => void;
  onSelectStrategy?: (
    selectedStrategy: Portfolio,
    secondaryStrategy: Portfolio | undefined,
    secondaryLabel: AnalysisSubjectSecondaryLabel,
  ) => void;
  onPortfolioUpdate?: (portfolio: Portfolio, updateType: TreeItemUpdateType) => void;
  onSecondaryPortfolioChange?: (secondaryPortfolio?: Portfolio, secondaryLabel?: AnalysisSubjectSecondaryLabel) => void;
  blockHistoryTest: (portfolioId?: number) => RegExp;
  allUpdatedFunds?: Map<string, Fund>;
  updateFund?: (fundId: string) => Promise<Fund | undefined>;
  updateAllFunds?: (funds: Fund[], preventSubjectUpdate?: boolean) => void;
  hasAccessToCompare?: boolean;
  canUploadInvestments?: boolean;
}

interface AllocationPanelState extends ItemPercentageProps {
  // The most current version of the portfolio displayed in the AP
  current?: Portfolio;
  // For each node id from `props.portfolio`, keep its original version in a map
  allOriginalNodes: Map<number, Portfolio>;
  // For each node id from `state.current`, keep its comparison node in a map
  allCompareNodes: Map<number, Portfolio>;
  // For each node id from `state.current`, keep all the children present in its comparison node, but absent in this node
  allGhostChildren: Map<number, Portfolio[]>;
  // For re-confirmation on navigate away
  showUnsavedChangesModal: boolean;
  isTradesView: boolean;
}

interface LocationState {
  addToStrategy?: {
    id?: number;
    name?: string;
  };
  investments?: InvestmentMixedType[];
}

class AllocationPanel extends React.Component<AllocationPanelProps, AllocationPanelState> {
  constructor(props: AllocationPanelProps) {
    super(props);
    this.state = {
      current: undefined,
      allOriginalNodes: new Map(),
      allCompareNodes: new Map(),
      allGhostChildren: new Map(),
      showUnsavedChangesModal: false,
      isPercentageMode: props.isPercentageMode ?? false,
      isTradesView: props.isTradesView ?? false,
      baseAllocation: 1,
      orignalBaseAllocation: 0,
    };
  }

  isMount = false;

  static defaultProps = {
    allUpdatedFunds: new Map(),
    onPortfolioUpdate: noop,
    onSecondaryPortfolioChange: noop,
  };

  static getDerivedStateFromProps(nextProps: AllocationPanelProps, prevState: AllocationPanelState) {
    const { portfolio } = nextProps;
    const { current } = prevState;

    if (!current && !!portfolio) {
      // Normalization merges together the allocations of funds that have the same id under the same parent.
      // This is important for the calculating of the comparison to work.
      const normalizedPortfolio = normalizePortfolio(portfolio);
      const allOriginalNodes = mapPortfolioToNodes(normalizedPortfolio);
      return {
        current: normalizedPortfolio,
        allOriginalNodes,
        baseAllocation: normalizedPortfolio.allocation,
        orignalBaseAllocation: normalizedPortfolio.allocation,
      };
    }

    // Pick up the changes coming from a refreshed context
    if (
      nextProps.isTradesView !== prevState.isTradesView ||
      nextProps.isPercentageMode !== prevState.isPercentageMode
    ) {
      return {
        isTradesView: nextProps.isTradesView,
        isPercentageMode: nextProps.isPercentageMode,
      };
    }

    return null;
  }

  componentDidMount() {
    this.applyChangesFromUrl();
    this.maybeUpdateSelectedStrategy();
    this.isMount = true;
    if (this.state.current) {
      // Errors have inline actions to delete constraining funds from portfolio. Since the portfolio state is kept
      // in the Allocation Panel, we expose its internal "portfolio fund deleter" to the context, which is then
      // accessed by the error component.
      this.props.setPortfolioFundDeleter?.(this.onDeleteFundFromPortfolio);
    }
    this.updateOnComparePortfolioChange();
  }

  componentDidUpdate(prevProps: AllocationPanelProps) {
    const { current, allOriginalNodes } = this.state;

    if (!prevProps.portfolio && !!current && !!current.optimizationId) {
      this.blockHistory();
    }

    if (!prevProps.portfolio && this.props.portfolio) {
      this.maybeUpdateSelectedStrategy();
      // Errors have inline actions to delete constraining funds from portfolio. Since the portfolio state is kept
      // in the Allocation Panel, we expose its internal "portfolio fund deleter" to the context, which is then
      // accessed by the error component.
      this.props.setPortfolioFundDeleter?.(this.onDeleteFundFromPortfolio);
    }

    if (
      prevProps.compareType !== this.props.compareType ||
      prevProps.compareLoading !== this.props.compareLoading ||
      prevProps.comparePortfolio !== this.props.comparePortfolio
    ) {
      this.updateOnComparePortfolioChange();
    }

    const updatedName = this.props.portfolio.name;
    if (this.props.portfolio && current && updatedName !== current.name) {
      // Update allocator portfolio name when the parent's portfolio change its name
      const portfolioWithNewName = {
        ...current,
        name: updatedName,
      };
      const originalPortfolio = allOriginalNodes.get(current.id);
      if (!originalPortfolio) {
        logMessageToSentry('There is no original portfolio in allocation panel');
        return;
      }
      const originalPortfolioWithNewName = {
        ...originalPortfolio,
        name: updatedName,
      };
      const newAllOriginalNodes = clone(allOriginalNodes).set(current.id, originalPortfolioWithNewName);
      this.setState({
        current: portfolioWithNewName,
        allOriginalNodes: newAllOriginalNodes,
      });
    }
  }

  componentWillUnmount() {
    this.unblockHistory();
    this.isMount = false;
  }

  onDeleteFundFromPortfolio = (fundId: string) => {
    if (!this.isMount) {
      return;
    }
    const { current } = this.state;
    if (!current) {
      logMessageToSentry('Failed to delete fund from portfolio because of missing current portfolio');
      return;
    }

    const updatedPortfolio = deleteConstrainingFundFromPortfolio(current, fundId);
    this.onUpdatePortfolio(updatedPortfolio, TreeItemUpdateType.EXTERNAL_DELETE);
  };

  applyChangesFromUrl = () => {
    const { location } = this.props;
    const { current } = this.state;
    if (!location.state || !current) {
      return;
    }

    const { addToStrategy, investments } = location.state as LocationState;
    if (!investments) {
      return;
    }

    const { updatedPortfolio } = addInvestmentsToPortfolio(
      investments,
      current,
      addToStrategy?.id,
      addToStrategy?.name,
    );
    this.onUpdatePortfolio(updatedPortfolio, TreeItemUpdateType.UPLOAD_FUND);
  };

  // `recomputeCompare` returns the result of the re-computed `allCompareNodes`, to allow other pieces of code use the
  // updated version immediately, and not to have to wait for the state to be updated first.
  recomputeCompare = (compareType: CompareType, specificPortfolio?: Portfolio): Map<number, Portfolio> => {
    const { current } = this.state;
    const { comparePortfolio } = this.props;
    const [allCompareNodes, allGhostChildren] =
      compareType === 'None'
        ? [new Map(), new Map()]
        : calculateComparison(current, specificPortfolio ?? comparePortfolio);

    this.setState({
      allCompareNodes,
      allGhostChildren,
    });

    return allCompareNodes;
  };

  onSelectStrategy = (strategy: Portfolio) => {
    const { compareType } = this.props;
    const { allCompareNodes } = this.state;

    const compareStrategy = compareType === 'None' ? undefined : allCompareNodes.get(strategy.id);

    const { onSelectStrategy } = this.props;
    onSelectStrategy?.(strategy, compareStrategy, this.getCompareLabel());
  };

  onChangeComparison = ({ value }: DropMenuItem<CompareType>, specificPortfolioVersion?: Portfolio) => {
    const { updateCompareType } = this.props;
    const updateWithPortfolioValue =
      value === 'Last Saved' ? this.getSavedPortfolioForComparison(specificPortfolioVersion) : undefined;
    updateCompareType?.(value, updateWithPortfolioValue);

    const allCompareNodes = this.recomputeCompare(value, updateWithPortfolioValue);
    this.onUpdateAnalysisSecondarySubject(value, allCompareNodes);
  };

  onUpdateAnalysisSecondarySubject = (compareType: CompareType, allCompareNodes: Map<number, Portfolio>) => {
    const { selectedStrategyId, onSecondaryPortfolioChange } = this.props;
    const { current } = this.state;
    if (!current) {
      logMessageToSentry('Failed to change secondary subject because missing current portfolio');
      return;
    }

    const portfolio = compareType === 'None' ? undefined : allCompareNodes.get(selectedStrategyId || current.id);
    const label = compareType === 'None' ? undefined : compareType;

    onSecondaryPortfolioChange?.(portfolio, label);
  };

  /**
   * It's important to call it after every structural update to the portfolio tree, so that funds and strategies that
   * are indicated to re-create the compare portfolio tree nodes are correctly matched to their corresponding nodes.
   *
   * Future improvement TODO: don't re-calculate the entire comparison on every change. Instead, be "smart" about it and
   * update specific entries in `allCompareNodes` and `allGhostChildren` maps.
   */
  applyChangesToTree = async (updateType: TreeItemUpdateType) => {
    const { hasAllocationError, onUpdateAllocationError, onPortfolioUpdate, compareType, setCanRerunOptimization } =
      this.props;
    const { current } = this.state;
    if (!current) {
      logMessageToSentry('Failed to change portfolio because missing current portfolio');
      return;
    }
    const isInvalidStatus = this.percentageIsInvalid();
    // if the error status is not correct, update it
    if ((isInvalidStatus && !hasAllocationError) || (!isInvalidStatus && hasAllocationError)) {
      onUpdateAllocationError && (await onUpdateAllocationError(!hasAllocationError));
    }
    // only update the analysis portfolio when it has valid allocation
    if (!isInvalidStatus) {
      onPortfolioUpdate && (await onPortfolioUpdate(current, updateType));
    }

    let upToDateCompareNodes = this.state.allCompareNodes;
    // Changing allocation (individual or bulk) doesn't impact the portfolio tree structure, so we don't need to
    // re-compute the comparison data.
    if (updateType !== TreeItemUpdateType.CHANGE_ALLOCATION && updateType !== TreeItemUpdateType.EDIT_CAPITAL) {
      // Re-compute the comparison tree
      upToDateCompareNodes = this.recomputeCompare(compareType);

      // Update the selected strategy to root if the current one just disappeared for some reason, for example,
      // it was deleted as a result of an UNDO action.
      this.maybeUpdateSelectedStrategy(upToDateCompareNodes);
    }

    setCanRerunOptimization?.(true);
  };

  maybeUpdateSelectedStrategy = (allCompareNodes?: Map<number, Portfolio>) => {
    const { selectedStrategyId, compareType } = this.props;
    const { current } = this.state;
    if (!current) {
      logMessageToSentry('Failed to update strategy because of missing current portfolio');
      return;
    }

    const strategy = selectStrategy(selectedStrategyId || current.id, current);
    if (!strategy) {
      const compareStrategy = compareType === 'None' || !allCompareNodes ? undefined : allCompareNodes.get(current.id);
      const compareStrategyLabel = compareStrategy ? compareType : undefined;
      this.props.onSelectStrategy &&
        this.props.onSelectStrategy(current, compareStrategy, compareStrategyLabel as AnalysisSubjectSecondaryLabel);
    }
  };

  onUpdatePortfolio = (updatedPortfolio: Portfolio, updateType: TreeItemUpdateType) =>
    this.setState(
      (pendingState: AllocationPanelState) => {
        const { current, isPercentageMode, baseAllocation } = pendingState;
        if (!current) {
          logMessageToSentry('Failed to update portfolio because of missing current portfolio');
          return {
            current,
            baseAllocation,
          };
        }
        if (arePortfoliosEqual(current, updatedPortfolio)) {
          return {
            current: updatedPortfolio,
            baseAllocation,
          };
        }

        return {
          current: updatedPortfolio,
          baseAllocation: getCurrentBaseAllocation(updatedPortfolio, baseAllocation, isPercentageMode, updateType),
        };
      },
      () => {
        this.applyChangesToTree(updateType);
      },
    );

  onApplyComparisonAllocationsToCurrent = () => {
    const { selectedStrategyId } = this.props;
    const { current, allCompareNodes } = this.state;
    const strategyId = selectedStrategyId || current?.id;
    if (!strategyId || !current) {
      return;
    }
    const replacementStrategy = allCompareNodes.get(strategyId);
    if (isNil(replacementStrategy)) {
      return;
    }

    this.onUpdatePortfolio(
      getWithReplacedStrategy(current, strategyId, allCompareNodes),
      TreeItemUpdateType.APPLY_ALLOCATIONS_FROM_COMPARE,
    );
  };

  onReset = () =>
    this.setState(
      (pendingState: AllocationPanelState) => {
        const { current, allOriginalNodes, isPercentageMode } = pendingState;
        if (!current) {
          logMessageToSentry('Failed to reset portfolio because of missing current portfolio');
          return null;
        }
        const original = allOriginalNodes.get(current.id);
        if (!original || original.allocation === undefined) {
          return null;
        }

        if (original.allocation === 0 && isPercentageMode) {
          // Handle the edge case when the user is in percentage mode and reset to base 0
          this.togglePercentageMode(false);
        }

        return {
          current: original,
          baseAllocation: original.allocation,
        };
      },
      () => {
        this.applyChangesToTree(TreeItemUpdateType.RESET);
        const { compareType, compareLoading, comparePortfolio, optimizeAdHoc } = this.props;
        if (compareType === 'Optimized' && !compareLoading && !comparePortfolio) {
          optimizeAdHoc?.();
        }
      },
    );

  onSaveComplete = (saved: Portfolio) => {
    const { current } = this.state;

    const allOriginalNodes = mapPortfolioToNodes(saved);
    if (saved.allocation === undefined) {
      logMessageToSentry('Failed to save portfolio because of missing allocation.');
      return;
    }

    this.setState(
      {
        current: saved,
        allOriginalNodes,
        orignalBaseAllocation: saved.allocation,
      },
      () => this.applyChangesToTree(TreeItemUpdateType.SAVE),
    );
    // Analytics track user save portfolio
    const hasBenchmark = !!(saved.compare && saved.compare.find((_compare) => _compare.benchmark));
    analyticsService.portfolioSaved({
      isMaster: saved.master,
      dateRange: `${saved.periodStart ? AnalyticsUtils.dateFormat(saved.periodStart) : ''}-${
        saved.periodEnd ? AnalyticsUtils.dateFormat(saved.periodEnd) : ''
      }`,
      portfolioId: saved.id,
      hasBenchmark,
    });

    const { refreshPortfoliosInContext, compareType, updateCompareType } = this.props;

    // Update all portfolios list and/or master portfolio in PortfoliosContexg
    refreshPortfoliosInContext?.();

    if (compareType === 'Optimized') {
      // After saving, all the newly-added strategies (if there were any) will have changed their node `id`.
      // We need to map the `current` portfolio to the `saved` portfolio (assuming identical structure), and update
      // the allocation constraints to use the correct `portfolioId` where the old if was used.
      const [currentToSaved] = calculateComparison(current, saved);
      const { updateOptimizationContextAfterSave } = this.props;
      const mapToIdsOnly = new Map<number, number>();
      for (const [key, value] of currentToSaved.entries()) {
        mapToIdsOnly.set(key, value.id);
      }
      updateOptimizationContextAfterSave?.(saved, mapToIdsOnly, true);
    }
    // Update "last saved" value if needed
    if (compareType === 'Last Saved') {
      updateCompareType?.('Last Saved', saved);
    }
  };

  showUnsavedChangesModal = () => this.setState({ showUnsavedChangesModal: true });

  hideUnsavedChangesModal = () => this.setState({ showUnsavedChangesModal: false });

  blockHistory = () => {
    const { blockHistoryTest, unsavedPortfolioChanges, setUnsavedPortfolioChanges } = this.props;
    const { allOriginalNodes, current } = this.state;

    const portfolioId = this.props.portfolio.id;
    const test = blockHistoryTest(portfolioId);

    UnsavedChangesModal.blockHistory(this.showUnsavedChangesModal, (pathName: string) => !pathName.match(test));

    const currentPortfolio = current ? allOriginalNodes.get(current.id) : undefined;
    if (currentPortfolio && !unsavedPortfolioChanges) {
      setUnsavedPortfolioChanges?.(true);
    }
  };

  unblockHistory() {
    UnsavedChangesModal.unblockHistory();

    const { unsavedPortfolioChanges, setUnsavedPortfolioChanges, onPortfolioUpdate } = this.props;
    const { current, allOriginalNodes } = this.state;
    const currentPortfolio = current ? allOriginalNodes.get(current.id) : undefined;

    if (currentPortfolio && unsavedPortfolioChanges) {
      setUnsavedPortfolioChanges?.(false);
      onPortfolioUpdate?.(currentPortfolio, TreeItemUpdateType.RESET);
    }
  }

  isModified = () => {
    const { current, allOriginalNodes } = this.state;
    if (!current) {
      return false;
    }
    const original = allOriginalNodes.get(current.id);
    if (!original) {
      return true;
    }
    return !arePortfoliosEqual(current, original);
  };

  onOptimizeSuccess = (
    resultPortfolioId: number,
    resultSummary: PortfolioSummary,
    resultConfig: OptimizationConfiguration,
    resultPortfolio?: Portfolio,
    customAllocationConstraints?: AllocationConstraint[],
  ) => {
    this.props.setOptimizationResult?.(
      resultPortfolioId,
      resultSummary,
      resultConfig,
      resultPortfolio,
      customAllocationConstraints,
    );
  };

  redirectToNewAnalysisPage = (newPortfolio: Portfolio) => {
    const { history } = this.props;
    history.push(getAnalysisPathForPortfolio(newPortfolio.id));
  };

  render() {
    const {
      current,
      allOriginalNodes,
      showUnsavedChangesModal,
      allCompareNodes,
      allGhostChildren,
      isPercentageMode,
      isTradesView,
      baseAllocation,
      orignalBaseAllocation,
    } = this.state;
    const {
      master,
      compareType,
      comparePortfolio,
      compareLoading,
      selectedStrategyId,
      hasAllocationError,
      optimizationError,
      canRerunOptimization,
      optimizeAdHoc,
      allUpdatedFunds,
      updateFund,
      updateAllFunds,
      customAllocationConstraints,
      hasPermission,
    } = this.props;

    const isModified = this.isModified();
    if (!current) {
      logMessageToSentry('Failed to render AllocationPanel because of missing current portofio');
      return null;
    }
    const canSave = current.draft || !!current.optimizationId || isModified;
    if (canSave) {
      this.blockHistory();
    } else {
      this.unblockHistory();
    }

    const tradesToggleDisabled =
      !current ||
      !allCompareNodes ||
      !allCompareNodes.get(current.id) ||
      (compareType === 'Optimized' && !!optimizationError);

    const lastSavedPortfolio = this.getLastSavedPortfolio();
    const isDraftCreatedPortfolio = current.draft;
    return (
      <>
        {showUnsavedChangesModal && (
          <UnsavedChangesModal
            isPortfolio
            onCancel={this.hideUnsavedChangesModal}
            onAccept={this.hideUnsavedChangesModal}
          />
        )}
        <AllocationPanelContent
          portfolio={current}
          selectedStrategyId={selectedStrategyId || current.id}
          allUpdatedFunds={allUpdatedFunds}
          allOriginalNodes={allOriginalNodes}
          allCompareNodes={allCompareNodes}
          allGhostChildren={allGhostChildren}
          hideCompareValue={compareType === 'None'}
          compareLoading={!!compareLoading}
          onSelectStrategy={this.onSelectStrategy}
          onUpdatePortfolio={this.onUpdatePortfolio}
          hideMaster={
            !master ||
            current.master ||
            (compareType === 'Master' && !comparePortfolio && !compareLoading) ||
            isDraftCreatedPortfolio ||
            !hasPermission?.('MANAGE_MASTER_PORTFOLIO')
          }
          selected={compareType}
          masterName={master?.name}
          onChangeComparison={this.onChangeComparison}
          canSave={canSave}
          canReset={isModified}
          onSave={this.onSaveComplete}
          onSavedAsNewPortfolio={this.redirectToNewAnalysisPage}
          onReset={this.onReset}
          lastSavedPortfolio={lastSavedPortfolio}
          baseAllocation={baseAllocation}
          isPercentageMode={isPercentageMode}
          togglePercentageMode={this.togglePercentageMode}
          hasAllocationError={hasAllocationError}
          invalidTotal={current.allocation === 0}
          showOptimizerConstraintsButton={compareType === 'Optimized'}
          onOptimizeSuccess={this.onOptimizeSuccess}
          optimizationError={optimizationError}
          isTradesView={isTradesView && !tradesToggleDisabled}
          toggleTradesView={this.toggleTradesView}
          tradesToggleDisabled={tradesToggleDisabled}
          orignalBaseAllocation={orignalBaseAllocation}
          canRerunOptimization={canRerunOptimization}
          onRerunOptimization={optimizeAdHoc}
          updateFund={updateFund}
          customAllocationConstraints={customAllocationConstraints}
          hasComparison={!isNil(allCompareNodes.get(selectedStrategyId ?? current.id)) && !compareLoading}
          onApplyComparisonAllocationsToCurrent={this.onApplyComparisonAllocationsToCurrent}
          hasAccessToCompare={this.props.hasAccessToCompare}
          isStudio={false}
        />
        <AllocationPlus
          updatePortfolio={this.onUpdatePortfolio}
          portfolio={current}
          strategyId={selectedStrategyId || current.id}
          updateAllFunds={updateAllFunds}
          canUploadInvestments={this.props.canUploadInvestments}
        />
      </>
    );
  }

  private updateOnComparePortfolioChange = () => {
    const { compareType, compareLoading, updateCompareType, comparePortfolio } = this.props;

    if (!compareLoading) {
      // If compare portfolio finished loading successfully, compute the comparison tree for the Allocation Panel.
      const updatedCompareNodes = this.recomputeCompare(compareType, comparePortfolio);
      this.onUpdateAnalysisSecondarySubject(compareType, updatedCompareNodes);

      if (compareType === 'Last Saved') {
        const lastSavedPortfolio = this.getSavedPortfolioForComparison();
        const updatedCompareType = lastSavedPortfolio ? 'Last Saved' : 'None';
        updateCompareType?.(updatedCompareType, lastSavedPortfolio);
      }
    }
  };

  private getLastSavedPortfolio = () => {
    const { allOriginalNodes, current } = this.state;
    if (!current) {
      return undefined;
    }

    const original = allOriginalNodes.get(current.id);
    return !original || !original.children || original.children.length === 0 || original.draft ? undefined : original;
  };

  private getSavedPortfolioForComparison = (specificPortfolioVersion?: Portfolio) => {
    const { comparePortfolio, compareLoading } = this.props;
    if (specificPortfolioVersion) {
      return specificPortfolioVersion;
    }
    if (compareLoading) {
      return undefined;
    }
    return comparePortfolio;
  };

  private getCompareLabel = (): AnalysisSubjectSecondaryLabel => {
    const { compareType } = this.props;
    return compareType === 'None' ? undefined : compareType;
  };

  private toggleTradesView = () => {
    const { isTradesView } = this.state;
    this.setState({ isTradesView: !isTradesView });
    const { onUpdateAnalysisViewParam } = this.props;
    onUpdateAnalysisViewParam?.({ isTradesView: !isTradesView });
  };

  private togglePercentageMode = (isPercentageMode: boolean) => {
    this.setState({
      isPercentageMode,
    });
    const { onUpdateAnalysisViewParam } = this.props;
    onUpdateAnalysisViewParam?.({ isPercentageMode });
  };

  private percentageIsInvalid = () => {
    const { current, isPercentageMode, baseAllocation } = this.state;
    if (!isPercentageMode) {
      return false;
    }
    // We would ignore difference smaller than 0.1%
    return checkHasQuantDiff((current?.allocation ?? 0) / baseAllocation, 1, 1000);
  };
}

export default withRouter(withContextsProps(AllocationPanel));
