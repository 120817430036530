import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import type { PortfolioLabStoredSettings } from './logic/useValuesFromHistoryState';
import { RECENT_PORTFOLIO_LAB_SETTINGS_KEY } from './logic/useValuesFromHistoryState';
import { AnalysisSubject, Routes, userActivity } from 'venn-utils';
import { isNil, omit } from 'lodash';
import type { Fund, Portfolio } from 'venn-api';
import queryString from 'query-string';
import { UserContext } from 'venn-components';

const RecentPortfolioLabPage = () => {
  const { profileSettings } = useContext(UserContext);
  const storedItem = userActivity.getLocalStorageItemPerUser(RECENT_PORTFOLIO_LAB_SETTINGS_KEY);
  const [recentSettings, search]: [PortfolioLabStoredSettings | undefined, string | undefined] = isNil(storedItem)
    ? [undefined, undefined]
    : parseStoredItem(storedItem);

  const recentSearchWorkspaceId = isNil(search)
    ? undefined
    : Number.parseInt(queryString.parse(search)?.workspaceId, 10);
  const currentWorkspaceId = profileSettings?.organization?.workspaceId;
  const recentMatchesCurrentWorkspace = !isNil(currentWorkspaceId) && currentWorkspaceId === recentSearchWorkspaceId;

  if (isNil(recentSettings) || isNil(recentSettings.portfolioId) || !recentMatchesCurrentWorkspace) {
    return <Redirect to={Routes.PORTFOLIO_LAB_RESULTS_PATH} />;
  }

  return (
    <Redirect
      to={{
        pathname: Routes.PORTFOLIO_LAB_RESULTS_PATH,
        search: search ?? `?portfolioId=${recentSettings.portfolioId}`,
        state: recentSettings,
      }}
    />
  );
};

export default RecentPortfolioLabPage;

const parseStoredItem = (
  storedItem: string | undefined,
): [PortfolioLabStoredSettings | undefined, string | undefined] => {
  if (isNil(storedItem)) {
    return [undefined, undefined];
  }

  const settings: PortfolioLabStoredSettings & { queryParams?: string } = JSON.parse(storedItem);

  // `benchmarkSubject` is of a complex type `AnalysisSubject` that is not restored properly from JSON.
  // We need to re-create the subject from the partial structure that we get from JSON. So we need to
  // access the private fields directly rather than trying to use the functions which won't exist yet.
  const item: Fund | Portfolio | undefined =
    // eslint-disable-next-line dot-notation
    settings.benchmarkSubject?.['_fund'] ?? settings.benchmarkSubject?.['_portfolio'] ?? undefined;
  // eslint-disable-next-line dot-notation
  const type = settings.benchmarkSubject?.['_type'];

  settings.benchmarkSubject = isNil(item) || isNil(type) ? undefined : new AnalysisSubject(item, type);

  return [omit(settings, 'queryParams'), settings.queryParams];
};
