import { useCallback, useState } from 'react';
import type { CookieSection } from '../utils';
import { CookiesAllowed } from '../CookiesCommon';

interface CookieModalState {
  advertisingToggled: boolean;
  toggleAdvertising: () => void;
  functionalToggled: boolean;
  toggleFunctional: () => void;
  selectedSection: CookieSection;
  setSelectedSection: (section: CookieSection) => void;
}

const useCookieTabState = (originalCookiePreferences: CookiesAllowed): CookieModalState => {
  const [advertisingToggled, setAdvertisingToggled] = useState<boolean>(
    [CookiesAllowed.All, CookiesAllowed.AdvertisingOnly].includes(originalCookiePreferences),
  );
  const [functionalToggled, setFunctionalToggled] = useState<boolean>(
    [CookiesAllowed.All, CookiesAllowed.FunctionalOnly].includes(originalCookiePreferences),
  );
  const [selectedSection, setSelectedSection] = useState<CookieSection>('required');

  const toggleAdvertising = useCallback(() => setAdvertisingToggled((a) => !a), []);

  const toggleFunctional = useCallback(() => setFunctionalToggled((f) => !f), []);

  return {
    advertisingToggled,
    toggleAdvertising,
    functionalToggled,
    toggleFunctional,
    selectedSection,
    setSelectedSection,
  };
};

export default useCookieTabState;
