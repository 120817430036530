import React, { useContext } from 'react';
import styled, { css, ThemeContext } from 'styled-components';
import { GetColor } from 'venn-ui-kit';
import type { BarType } from './types';

const DIAMOND_SIZE = 5;
interface DynamicBarProps {
  width: number;
  height?: number;
  type: BarType;
  inSignificant?: boolean;
  negative?: boolean;
  startPosition: number;
}

const DynamicBar = (props: DynamicBarProps) => {
  const theme = useContext(ThemeContext);
  const color = props.negative ? theme.Colors.DivergingColor.A3 : theme.Colors.DivergingColor.B3;
  return (
    <>
      <CenterLine />
      <ValueBar
        {...props}
        color={color}
        style={{
          width: `${(props.width * 100).toFixed(5)}%`,
          left: `calc(${(props.startPosition * 100).toFixed(5)}% ${
            props.startPosition === 0.5 && props.negative ? '-' : '+'
          } 1px)`,
          height: props.type === 'TOTAL' ? '1px' : '100%',
        }}
      />
      {props.type === 'TOTAL' && props.width && (
        <TotalSymbol
          color={color}
          style={{
            left: `calc(${((props.startPosition + (props.negative ? -1 * props.width : props.width)) * 100).toFixed(5)}%
            - ${DIAMOND_SIZE}px
            + 1px)`,
          }}
        />
      )}
    </>
  );
};

export const backgroundStyles = css<{ type?: BarType; color?: string }>`
  ${({ type, color }) =>
    (type === 'RESIDUAL' || type === 'RISK-FREE') &&
    css`
      background: repeating-linear-gradient(
        ${type === 'RISK-FREE' ? 90 : -45}deg,
        ${color ?? GetColor.Black},
        ${color ?? GetColor.Black} 2px,
        ${GetColor.White} 4px,
        ${GetColor.White} 3px
      );
    `};
`;

export const TotalSymbol = styled.div<{ color?: string }>`
  width: 0;
  height: 0;
  border: ${DIAMOND_SIZE}px solid transparent;
  border-bottom-color: ${({ color }) => color ?? GetColor.Black};
  position: relative;
  /** Move to center **/
  top: -${DIAMOND_SIZE + 1}px;
  &:after {
    content: '';
    position: absolute;
    left: -${DIAMOND_SIZE}px;
    top: ${DIAMOND_SIZE}px;
    width: 0;
    height: 0;
    border: ${DIAMOND_SIZE}px solid transparent;
    border-top-color: ${({ color }) => color ?? GetColor.Black};
  }
`;

const ValueBar = styled.div<DynamicBarProps & { color: string }>`
  background-color: ${({ color }) => color};
  opacity: ${({ inSignificant }) => inSignificant && '0.4'};
  margin: 1px 0;
  top: ${({ type }) => (type === 'TOTAL' ? 5 : 0)}px;
  position: relative;
  ${({ negative }) =>
    negative &&
    css`
      transform: translate(-100%);
    `}

  ${backgroundStyles}
  @media print {
    page-break-inside: avoid;
  }
`;

const CenterLine = styled.div`
  width: 1px;
  height: 100%;
  background-color: ${GetColor.GreyScale.Grey30};
  position: absolute;
  left: 50%;
  top: 0;
`;

export default DynamicBar;
