import React from 'react';
import styled from 'styled-components';
import { Label, TooltipPosition } from 'venn-ui-kit';
import SliderToggle from '../../../slider-toggle/SliderToggle';
import MediaQuery from 'react-responsive';
import { SpecialCssClasses } from 'venn-utils';

export interface ResidualCorrelationToggleProps {
  disabled: boolean;
  toggled: boolean;
  onToggle: () => void;
}

const Wrapper = styled.div`
  margin-top: -15px;

  > label {
    padding: 4px 0;
    display: inline-block;
  }

  @media print {
    display: none;
  }
`;

const ResidualForDownloadable = styled.div<{ isPrinting?: boolean }>`
  ${({ isPrinting }) => (!isPrinting ? 'display: none; margin-right: 135px;' : 'position: relative;')}
`;

const PrintPosition = styled.div`
  position: absolute;
  margin-top: 40px;
  margin-left: 40px;
`;

const TOOLTIP_TEXT = 'Would you like to compute on the residuals?';
const DISABLED_TOOLTIP_TEXT = 'Residualized excess return correlations are not available.';

const ResidualCorrelationToggle = ({ disabled, toggled, onToggle }: ResidualCorrelationToggleProps) => (
  <MediaQuery print>
    {(print) => (
      <>
        <Wrapper className={SpecialCssClasses.NotDownloadable}>
          <Label>Use Residual:</Label>
          <SliderToggle
            disabled={disabled}
            toggled={toggled}
            onToggle={onToggle}
            tooltipPosition={TooltipPosition.Left}
            tooltipMessage={disabled ? DISABLED_TOOLTIP_TEXT : TOOLTIP_TEXT}
          />
        </Wrapper>
        <ResidualForDownloadable className={SpecialCssClasses.OnlyInDownloadable} isPrinting={print}>
          <PrintPosition>
            <Label>Use Residual:</Label> {toggled ? 'ON' : 'OFF'}
          </PrintPosition>
        </ResidualForDownloadable>
      </>
    )}
  </MediaQuery>
);

export default ResidualCorrelationToggle;
