import { isNil } from 'lodash';
import { selector } from 'recoil';
import { openAllocatorSubject, openAllocatorSubjectConfig, openPrivateAllocatorPortfolio } from './allocator';
import { modifiedPortfolioForSubject, modifiedPrivatePortfolioForSubject } from './subjects';

/**
 * A selector indicating if the user has changes in the allocator that can be saved
 */
export const hasUnsavedPortfolioChangesInAllocator = selector<boolean>({
  key: 'hasUnsavedPortfolioChanges',
  get: ({ get }) => {
    const openSubject = get(openAllocatorSubject);
    const modifiedPortfolio = openSubject && get(modifiedPortfolioForSubject(openSubject));
    return !isNil(modifiedPortfolio);
  },
});

export const hasUnsavedViewChangesInAllocator = selector<boolean>({
  key: 'hasUnsavedViewChangesInAllocator',
  get: ({ get }) => {
    const config = get(openAllocatorSubjectConfig);
    return !config ? false : config.portfolioId !== config.selectedStrategyId || !isNil(config.comparisonConfig);
  },
});

export const hasUnsavedChangesInPrivatesAllocator = selector<boolean>({
  key: 'hasUnsavedChangesInPrivatesAllocator',
  get: ({ get }) => {
    const openPortfolio = get(openPrivateAllocatorPortfolio);

    if (isNil(openPortfolio)) {
      return false;
    }

    const subject = { privatePortfolioId: openPortfolio.id };
    const modifiedPrivatePortfolio = get(modifiedPrivatePortfolioForSubject(subject));
    return !isNil(modifiedPrivatePortfolio);
  },
});
