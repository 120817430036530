import React, { useMemo, useRef } from 'react';
import styled, { withTheme } from 'styled-components';
import HighchartsReact from 'highcharts-react-official';
import Highstock from 'highcharts/highstock';
import type { Theme } from 'venn-ui-kit';
import { GetColor, REPORT_LAB_FONT_BODY } from 'venn-ui-kit';
import Legend from '../../../../legend/Legend';
import { getFactorTrendChartConfig } from './chartConfig';
import type { LineChartLegendItem } from '../../../../charts/analysis-charts/factor-charts/components/parseData';
import ExcludedFactor from '../../../../charts/analysis-charts/factor-charts/components/ExcludedFactor';
import { uniqBy } from 'lodash';
import { getFactorColor } from './factorLineUtils';
import { useMemoWithArgs } from 'venn-utils';
import { blockFonts } from 'venn-state';
import { useChartReflow } from '../../../../hooks';
import { useBlockId } from '../../../contexts/BlockIdContext';
import { useRecoilValue } from 'recoil';

interface FactorTrendLineChartDisplayOptions {
  percentageValue: boolean;
}

interface FactorTrendLineChartProps {
  items: LineChartLegendItem[];
  theme: Theme;
  displayOptions: FactorTrendLineChartDisplayOptions;
}

export const FactorTrendLineChart = withTheme(
  ({
    items,
    theme: { Typography, Colors },
    displayOptions: { percentageValue = false },
  }: FactorTrendLineChartProps) => {
    const blockId = useBlockId();
    const chartRef = useRef<HighchartsReact.RefObject>(null);
    useChartReflow(blockId, chartRef);
    const legendFont = useRecoilValue(blockFonts.blockChartLegend(blockId));
    const axisFont = useRecoilValue(blockFonts.blockChartAxis(blockId));
    const highChartOptions = useMemoWithArgs(getFactorTrendChartConfig, [
      items,
      Typography,
      Colors,
      percentageValue,
      axisFont.fontSizePt,
    ]);
    const legendSeries = useMemo(
      () =>
        uniqBy(items, 'name').map((item, index) => ({
          color: getFactorColor(Colors.Black, index),
          name: item.name,
          height: 1,
        })),
      [Colors.Black, items],
    );

    const subjectSeries = useMemo(
      () =>
        uniqBy(items, (item) => item.seriesTypes?.mainSerieType).map((item) => ({
          name: item.seriesTypes.mainSerieType ?? '',
          feesExcluded: item?.feesExcluded,
          fee: item.fee,
          color: item.color,
          height: item.lineWidth,
          hasProxy: item.hasProxy,
        })),
      [items],
    );

    const fontSize = `${legendFont.fontSizePt}pt`;
    return (
      <FactorLineChartContainer>
        <SubjectsLegend fontSize={fontSize} series={subjectSeries} />
        <StyledLegend fontSize={fontSize} className="qa-trend-line-chart-legend" series={legendSeries}>
          <ExcludedFactor fontSize={fontSize} />
        </StyledLegend>
        <HighchartsReact
          highcharts={Highstock}
          ref={chartRef}
          options={highChartOptions}
          containerProps={{ style: { flex: 1 } }}
        />
      </FactorLineChartContainer>
    );
  },
);

export const FactorLineChartContainer = styled.div`
  transform: translate3d(0, 0, 0);
  display: flex;
  flex-direction: column;
  height: 100%;
  font-size: ${REPORT_LAB_FONT_BODY};

  .highcharts-container:hover {
    .highcharts-line-series {
      &:not(.highcharts-series-hover) {
        path {
          stroke: ${GetColor.Grey};
          stroke-width: 1;
        }
      }
    }
  }

  .highcharts-xaxis-labels span {
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
  }

  .highcharts-tooltip-circle {
    display: inline-block;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    border: 1px solid white;
  }
`;

const SubjectsLegend = styled(Legend)`
  padding: 7px 14px;
  border-bottom: 1px solid ${GetColor.Grey};
`;

const StyledLegend = styled(Legend)`
  padding: 7px 14px;
  border-bottom: 1px solid ${GetColor.Grey};

  > div {
    margin: 0 20px 0 0;
  }
`;
