import { useHistory } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  logExceptionIntoSentry,
  parseIntValueFromLocation,
  parseValueFromLocation,
  parseValuesFromLocation,
  updateUrlParam,
  useApi,
  useIsMounted,
} from 'venn-utils';
import { compact, isNil } from 'lodash';
import type { Fund, Portfolio } from 'venn-api';
import { getFund, getSpecificPortfolioV3 } from 'venn-api';
import { updatePortfolioAt } from 'venn-components';

export interface NewOpportunitiesProps {
  funds: Fund[];
  parentId: number;
  maxAllocation?: number;
}

const useSelectedPortfolio = () => {
  const history = useHistory();
  const [portfolioId, setPortfolioId] = useState(() => {
    const portfolioIdFromLocation = parseValueFromLocation(history.location, 'portfolioId');
    if (isNil(portfolioIdFromLocation)) {
      return undefined;
    }
    const numericValue = Number.parseInt(portfolioIdFromLocation, 10);
    return Number.isNaN(numericValue) ? undefined : numericValue;
  });

  const [newInvestmentIds, parentStrategyId, maxAllocation] = useMemo(
    () => [
      parseValuesFromLocation(history.location, 'investmentIds'),
      parseIntValueFromLocation(history.location, 'parentStrategyId'),
      parseIntValueFromLocation(history.location, 'maxAllocation'),
    ],
    [history.location],
  );

  const [portfolio, setPortfolio] = useState<Portfolio>();
  const [newFunds, setNewFunds] = useState<Fund[]>();
  const currentPortfolioIdRef = useRef<number>();
  const [loading, setLoading] = useState(false);
  const getPortfolioApi = useRef(useApi(getSpecificPortfolioV3));
  const isMountedRef = useIsMounted();
  const [originalPortfolio, setOriginalPortfolio] = useState<Portfolio>();

  const clearNewInvestmentValues = useCallback(() => {
    updateUrlParam(history, 'REPLACE', 'investmentIds');
    updateUrlParam(history, 'REPLACE', 'parentStrategyId');
    updateUrlParam(history, 'REPLACE', 'maxAllocation');
    setNewFunds(undefined);
  }, [history]);

  useEffect(() => {
    const fetchPortfolio = async (id: number) => {
      try {
        const [portfolioResult, fundResults] = await Promise.all([
          Promise.resolve(getPortfolioApi.current(id, undefined)).then((value) => value.content),
          Promise.all(newInvestmentIds.map((newInvestmentId: string) => getFund(newInvestmentId)))
            .then(
              (values) => values.map((value) => value.content),
              (e) => {
                logExceptionIntoSentry(e);
                return undefined;
              },
            )
            .then((values) => compact(values)),
        ]);
        if (isMountedRef.current) {
          setNewFunds(fundResults);
          setOriginalPortfolio(portfolioResult);
          setPortfolio(getPortfolioWithNewFunds(portfolioResult, fundResults, parentStrategyId));
          currentPortfolioIdRef.current = portfolioResult?.id;
          if (!fundResults.length || isNil(portfolioResult)) {
            clearNewInvestmentValues();
          }
        }
      } catch (e) {
        if (e.name !== 'AbortError' && isMountedRef.current) {
          setOriginalPortfolio(undefined);
          setPortfolio(undefined);
          currentPortfolioIdRef.current = undefined;
          clearNewInvestmentValues();
          setPortfolioId(undefined);
          updateUrlParam(history, 'REPLACE', 'portfolioId');
        }
      }
      setLoading(false);
    };
    if (!isNil(portfolioId) && currentPortfolioIdRef.current !== portfolioId && !loading) {
      setLoading(true);
      fetchPortfolio(portfolioId);
    }
  }, [portfolioId, loading, isMountedRef, newInvestmentIds, parentStrategyId, clearNewInvestmentValues, history]);

  const onSelectPortfolioId = useCallback(
    (selectedId: number) => {
      setPortfolioId(selectedId);
      updateUrlParam(history, 'REPLACE', 'portfolioId', selectedId.toString());
      clearNewInvestmentValues();
    },
    [history, clearNewInvestmentValues],
  );

  const onUpdateNewOpportunities = useCallback(
    (funds: Fund[], strategyId: number) => {
      setPortfolio(getPortfolioWithNewFunds(originalPortfolio, funds, strategyId));
      updateUrlParam(
        history,
        'REPLACE',
        'investmentIds',
        funds.map(({ id }) => id),
      );
      updateUrlParam(history, 'REPLACE', 'parentStrategyId', strategyId.toString());
      setNewFunds(funds);
    },
    [history, originalPortfolio],
  );

  const newOpportunitiesProps: NewOpportunitiesProps | undefined = useMemo(
    () =>
      isNil(newFunds) || isNil(portfolio)
        ? undefined
        : {
            funds: newFunds,
            parentId: parentStrategyId ?? portfolio?.id,
            maxAllocation,
          },
    [newFunds, portfolio, parentStrategyId, maxAllocation],
  );

  return {
    locationPortfolioId: portfolioId,
    portfolio,
    portfolioLoading: loading,
    onSelectPortfolioId,
    newOpportunitiesProps,
    onUpdateNewOpportunities,
  };
};

export default useSelectedPortfolio;

const getPortfolioWithNewFunds = (
  portfolio: Portfolio | undefined,
  funds: Fund[],
  parentId: number | undefined,
): Portfolio | undefined => {
  if (!funds.length || isNil(portfolio)) {
    return portfolio;
  }
  const strategyId = parentId ?? portfolio.id;
  const now = Date.now();
  const newFunds: Partial<Portfolio>[] = funds.map((fund, idx) => ({
    allocation: 0,
    children: [],
    compare: [],
    id: now + idx,
    fund,
    name: fund.name,
    demo: false,
    master: false,
    draft: true,
  }));
  const resultPortfolio = updatePortfolioAt({ ...portfolio }, strategyId, (node) => ({
    ...(node as Portfolio),
    children: [...node.children, ...newFunds] as Portfolio[],
  }));
  return {
    ...resultPortfolio,
    draft: true,
  };
};
