import { isEmpty, isNil, noop } from 'lodash';
import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import styled, { css } from 'styled-components';
import { type PrivatePortfolioNode } from 'venn-api';
import { originalAnalysisSubjectQuery, useResetCashflowSettingsModalState } from 'venn-state';
import {
  DropMenu,
  type DropMenuItem,
  GetColor,
  Headline2,
  Headline3,
  ItemIcon,
  ItemType,
  Link as LinkStyle,
  Loading,
  PRIVATE_ASSET_CASH_FLOW_HELP_HREF,
} from 'venn-ui-kit';
import { analyticsService } from 'venn-utils';
import {
  cashflowSettingSelectedToActivateNext,
  cashflowSettingsModalIsEditing,
} from '../../../../venn-state/src/cashflow-settings/cashflowSettingsModalAtoms';
import { Modal, ModalSize } from '../../modal';
import ModalFooter from '../../modal/ModalFooter';
import { FundCashflowSettings } from './FundCashflowSettings';

type Source = 'MANAGE_DATA_PAGE' | 'STUDIO_SUBJECTS_INPUTS' | 'STUDIO_PRIVATE_ALLOCATOR_PANEL';

type CashflowSettingsModalProps = {
  /** Function to apply when the modal is closed */
  onClose: () => void;
  /** Function to apply when changes to settings are successfully persisted */
  onChangesApplied: () => void;
  /** Portfolio from which user opened the modal. Can be undefined if user entered the modal from an analysis subject being a fund. */
  privatePortfolio?: PrivatePortfolioNode;
  /** Taken from the private portfolio if not provided */
  subjectName?: string;
  /** Fund which triggered the modal.
   * When multiple funds are available within a portfolio, it populates initially selected option.
   * If portfolio is undefined (a.k.a. viewing fund settings only), the dropdown will not appear,
   * and the user is unable to choose a different fund */
  triggeringFundId?: string;
  /**
   * Denotes where in the app this modal is being accessed from. Needed for tracking purposes
   */
  source: Source;
};

type FundCashflowSettingsWrapperProps = {
  selectedFundId: string;
  editViewHeight: number;
  editViewTop: number;
  onClose: () => void;
  onChangesApplied: () => void;
};

const FundCashflowSettingsWrapper = (props: FundCashflowSettingsWrapperProps) => {
  const privateFund = useRecoilValue(
    originalAnalysisSubjectQuery({
      privateFundId: props.selectedFundId,
    }),
  )?.privateFund;

  if (!privateFund) {
    return null;
  }

  return <FundCashflowSettings {...props} privateFund={privateFund} />;
};

const DROPDOWN_WIDTH = 1024 - 2 * 24;
const DROPDOWN_HEIGHT = 82;
const HEADER_HEIGHT = 136;
const BASE_MAIN_HEIGHT = 640;

export const CashflowSettingsModal = ({
  onClose,
  onChangesApplied,
  privatePortfolio,
  triggeringFundId,
  subjectName,
  source,
}: CashflowSettingsModalProps) => {
  const [selectedFundId, setSelectedFundId] = useState<string | undefined>(triggeringFundId);
  const onExitModal = useResetCashflowSettingsModalState();
  const dropdownItems: DropMenuItem<string>[] = useMemo(
    () =>
      privatePortfolio?.children?.map((node) => {
        return {
          label: node.name,
          value: node.fundId,
        };
      }) ?? [],
    [privatePortfolio?.children],
  );

  useEffect(() => {
    analyticsService.cashFlowSettingsModalOpened({
      source,
      triggeringFundId,
      subjectId: privatePortfolio?.id ?? triggeringFundId,
      subjectType: privatePortfolio?.id ? 'PRIVATE_PORTFOLIO' : 'PRIVATE_FUND',
    });
  }, [privatePortfolio?.id, source, triggeringFundId]);

  const onChangesAppliedWithLogging = () => {
    onChangesApplied();

    if (!isNil(selectedToActivateNext) && !isNil(selectedFundId)) {
      analyticsService.cashFlowSettingsApplied({
        settingId: selectedToActivateNext,
        fundId: selectedFundId,
        modalSubjectId: privatePortfolio?.id ?? triggeringFundId,
        modalSubjectType: privatePortfolio?.id ? 'PRIVATE_PORTFOLIO' : 'PRIVATE_FUND',
      });
    }
  };

  const activeDropdownHeight = privatePortfolio ? DROPDOWN_HEIGHT : 0;
  const mainHeight = BASE_MAIN_HEIGHT + activeDropdownHeight;
  const selectedToActivateNext = useRecoilValue(cashflowSettingSelectedToActivateNext);
  const isEditing = useRecoilValue(cashflowSettingsModalIsEditing);

  const getTooltipContent = useCallback(() => {
    if (isEditing) {
      return 'Please finish editing the setting before switching funds';
    }
    if (selectedToActivateNext) {
      return 'Please confirm or cancel the active setting selection before switching funds';
    }
    return 'Select a fund to view its active cash flow setting';
  }, [isEditing, selectedToActivateNext]);

  return (
    <Modal size={ModalSize.XXLarge} onClose={onClose} noPadding closeOnEsc={false} closeOnOutsideClick={false}>
      <MainContainer height={mainHeight} data-testid="cashflow-settings-modal-container">
        <CashflowSettingsModalContainer>
          <SubjectHeader>
            <StyledHeadline2>Cash Flow Pacing Model Settings</StyledHeadline2>
            <StyledHeadline3>
              <ItemIcon item={privatePortfolio ? ItemType.PrivatePortfolio : ItemType.PrivateInvestment} />
              {subjectName ?? privatePortfolio?.name}
            </StyledHeadline3>
            <MoreQuestionsContainer>
              Have more questions? Visit the
              <LinkStyle>
                <button
                  type="button"
                  onClick={() => window.open(PRIVATE_ASSET_CASH_FLOW_HELP_HREF, '_blank')}
                  data-testid="qa-cf-pacing-params-faq-button"
                >
                  &nbsp; FAQ
                </button>
              </LinkStyle>
            </MoreQuestionsContainer>
          </SubjectHeader>
          {!!privatePortfolio && (
            <ChooseFundContainer>
              <b>Select a fund to view parameter settings</b>
              <DropMenu
                data-testid="cashflow-modal-fund-selector"
                placeholder={isEmpty(dropdownItems) ? 'Your portfolio is empty' : 'Not selected yet'}
                items={dropdownItems}
                selected={selectedFundId}
                className="qa-select-fund-in-portfolio-dropdown"
                width={DROPDOWN_WIDTH}
                onChange={(fundId: DropMenuItem<string>) => setSelectedFundId(fundId.value)}
                disabled={isEditing || !!selectedToActivateNext}
                getTooltipContent={getTooltipContent}
                disableTooltip={!(isEditing || !!selectedToActivateNext)}
                alwaysShowTooltip
                minimumItemsToTrigger={0}
              />
            </ChooseFundContainer>
          )}
          <FlexWrapper>
            <Suspense fallback={<Loading title="Cashflow pacing model parameters are loading... " />}>
              {selectedFundId !== undefined && (
                <FundCashflowSettingsWrapper
                  onChangesApplied={onChangesAppliedWithLogging}
                  editViewTop={activeDropdownHeight + HEADER_HEIGHT - 2}
                  editViewHeight={BASE_MAIN_HEIGHT - HEADER_HEIGHT - 30}
                  selectedFundId={selectedFundId}
                  onClose={onClose}
                />
              )}
            </Suspense>
          </FlexWrapper>
          {/* show a disabled modal footer even if fund was not yet chosen, otherwise the user could be stuck in a limbo */}
          {!selectedFundId && (
            <ModalFooter
              className="qa-modal-footer-wrapper"
              secondaryLabel="APPLY CHANGES"
              onSecondaryClick={noop}
              onPrimaryClick={noop}
              primaryDisabled
              secondaryDisabled
              primaryLabel="APPLY CHANGES & CLOSE"
              cancelLabel="Close"
              onCancel={() => {
                onExitModal();
                onClose();
              }}
            />
          )}
        </CashflowSettingsModalContainer>
      </MainContainer>
    </Modal>
  );
};

const MainContainer = styled.div<{ height: number }>`
  ${(props) => css`
    height: ${props.height}px;
  `}
  display: flex;
  flex-direction: column;

  .qa-modal-footer-wrapper {
    margin-top: 0;
    margin-right: 2px;
    height: 50px;
  }
`;

const CashflowSettingsModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex: 1 1 0;
`;

const SubjectHeader = styled.div`
  display: flex;
  padding: 20px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  height: ${HEADER_HEIGHT}px;
`;

const StyledHeadline2 = styled(Headline2)`
  margin: 0;
`;
const StyledHeadline3 = styled(Headline3)`
  display: flex;
  gap: 4px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
`;

const MoreQuestionsContainer = styled.span`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  color: ${GetColor.DarkGrey};
`;

const ChooseFundContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px 24px 24px;
  align-items: flex-start;
  gap: 4px;
`;
