import differenceBy from 'lodash/differenceBy';
import maxBy from 'lodash/maxBy';
import type {
  Portfolio,
  DrawdownContribution,
  DrawdownFactorInfo,
  DrawdownFactorExposure,
  FactorDeltaCorrelation,
  DrawdownRangeAnalysis,
} from 'venn-api';
import type { AnalysisSubject, ExcelCell, Series } from 'venn-utils';
import { convertMultipleSeriesDataToExcel, getSecondaryDisplayLabel } from 'venn-utils';
import { SORTDIR } from '../../../../basictable/BasicTable';
import { compact } from 'lodash';

export const DRAWDOWN_DATE_FORMAT = 'D MMM YYYY';
export const RESIDUAL_NAME = 'Residual';
export const RISK_FREE_RATE_NAME = 'Risk-Free Rate';
export const EXPAND_NAV_WIDTH = 280;
export const COLLAPSED_NAV_WIDTH = 70;

interface FormatColumnNumberOptions {
  percent?: boolean;
  precision?: number;
  multiplier?: boolean;
}

export function formatColumnNumber(value: number, options: FormatColumnNumberOptions = {}): string {
  const { percent, precision, multiplier } = options;
  return value !== null && value !== undefined
    ? // there is a distinction between false and undefined here
      `${(value * (multiplier !== false ? 100 : 1)).toFixed(precision !== undefined ? precision : 1)}${
        percent ? '%' : ''
      }`
    : '--';
}

export function getMaxCorrelation(drawdownFactorInfoList: DrawdownFactorInfo[]) {
  let maxCorrelation: { factor: DrawdownFactorInfo; correlation: FactorDeltaCorrelation } | undefined;
  drawdownFactorInfoList.forEach((factor: DrawdownFactorInfo) => {
    factor.factorDeltaCorrelations.forEach((correlation: FactorDeltaCorrelation) => {
      if (!maxCorrelation || maxCorrelation.correlation.delta < correlation.delta) {
        maxCorrelation = { factor, correlation };
      }
    });
  });
  return maxCorrelation;
}

export function doCorrelationsExist(drawdownFactorInfoList: DrawdownFactorInfo[]): boolean {
  const factorIds = drawdownFactorInfoList.map((info) => info.factorId);
  return drawdownFactorInfoList.some((info) =>
    factorIds.some((compareFactorId) => {
      const correlationFactor = info.factorDeltaCorrelations.find((c) => c.id === compareFactorId);
      const correlation = correlationFactor ? correlationFactor.delta : null;
      return correlation !== null && correlation !== undefined;
    }),
  );
}

/**  Root level portfolio.allocation property does not account for changes made in  allocator panel (only fund nodes are modified) */
export function getPortfolioAllocation(node: Portfolio): number {
  if (node.children.length) {
    return node.children.reduce((memo, child) => memo + getPortfolioAllocation(child), 0);
  }
  if (node.fund && node.allocation) {
    return node.allocation;
  }
  return 0;
}

export function sortDrawdownFactorExposures<T extends DrawdownFactorExposure>(dataSet: T[], dir: SORTDIR) {
  return sortDrawdownWithAccessors<T>(
    'portfolioBeta',
    (item: T) => item.portfolioBeta !== null && item.portfolioBeta !== undefined,
    (residualItem: T) => residualItem.factorName === RESIDUAL_NAME,
    (cashItem: T) => cashItem.factorName === RISK_FREE_RATE_NAME,
  )(dataSet, dir);
}

export function sortDrawdownFactorExposuresByContribution<T extends DrawdownFactorExposure>(
  dataSet: T[],
  dir: SORTDIR,
) {
  return sortDrawdownWithAccessors<T>(
    'contribution',
    (item: T) => !!item.contribution,
    (residualItem: T) => residualItem.factorName === RESIDUAL_NAME,
    (cashItem: T) => cashItem.factorName === RISK_FREE_RATE_NAME,
  )(dataSet, dir);
}

export function sortDrawdownContribution<T extends DrawdownContribution>(dataSet: T[], dir: SORTDIR) {
  return sortDrawdownWithAccessors<T>(
    'contribution',
    (item: T) => !!item.contribution,
    (residualItem: T) => residualItem.name === RESIDUAL_NAME,
    (cashItem: T) => cashItem.name === RISK_FREE_RATE_NAME,
  )(dataSet, dir);
}

const sortDrawdownWithAccessors =
  <T>(
    accessor: keyof T,
    filterCondition: (item: T) => boolean,
    isResidual: (item: T) => boolean,
    isCash: (item: T) => boolean,
  ) =>
  (dataSet: T[], dir: SORTDIR) => {
    const sortedDataWith = dataSet
      .filter((item) => filterCondition(item) && !isResidual(item) && !isCash(item))
      .sort((a, b) => {
        const comparison = a[accessor] < b[accessor];

        if (dir === SORTDIR.DESC) {
          return comparison ? 1 : -1;
        }
        return comparison ? -1 : 1;
      });

    const dataWithout = differenceBy(dataSet, sortedDataWith).filter((item) => !isResidual(item) && !isCash(item));

    const residualRow = dataSet.find(isResidual);
    const cashRow = dataSet.find(isCash);

    return compact([...sortedDataWith, ...dataWithout, residualRow, cashRow]);
  };

export function getTopDrawdownDriver(
  strategyDrawdownContribution: DrawdownContribution[],
  fundDrawdownContribution: DrawdownContribution[],
) {
  const maxStrategy = maxBy(strategyDrawdownContribution, (s) => s.contribution);
  const maxFund = maxBy(fundDrawdownContribution, (s) => s.contribution);

  // The thing that prevents this from being a simple maxBy([maxStrategy, maxFund])
  // one-liner is that we would lose information about whether the max was a fund
  // or a strategy (isFund), so we need to do an explicit check.
  // That being said there is probably a nicer way to do this but this needs to be
  // fixed yesterday.
  let topDriver;
  if (maxStrategy && maxFund) {
    topDriver =
      maxStrategy.contribution > maxFund.contribution
        ? { name: maxStrategy.name, isFund: false }
        : { name: maxFund.name, isFund: true };
  } else if (maxStrategy && !maxFund) {
    topDriver = { name: maxStrategy.name, isFund: true };
  } else if (!maxStrategy && maxFund) {
    topDriver = { name: maxFund.name, isFund: true };
  } else {
    topDriver = null;
  }

  return topDriver;
}

export const getExcelData = (subject: AnalysisSubject, data: DrawdownRangeAnalysis): ExcelCell[][] => {
  const series: Series[] = [];
  series.push({
    name: subject.name,
    series: data.portfolioDrawdown,
  });

  series.push({
    name: `${subject.name} (+/- Error)`,
    series: data.errorDrawdown,
  });

  if (subject.hasSecondarySubject && data.secondaryPortfolioDrawdown) {
    series.push({
      name: `${subject.secondaryPortfolio?.name} (${getSecondaryDisplayLabel(subject, 'as of')})`,
      series: data.secondaryPortfolioDrawdown,
    });
  }
  if (subject.hasBenchmark && data.benchmarkDrawdown) {
    series.push({
      name: `${subject?.activeBenchmarkName}(Benchmark)`,
      series: data.benchmarkDrawdown,
    });
  }
  if (subject.categoryGroup && data.secondaryPortfolioDrawdown) {
    series.push({
      name: `${subject.categoryGroup.name}(Category)`,
      series: data.secondaryPortfolioDrawdown,
    });
  }
  return convertMultipleSeriesDataToExcel(series, true);
};
