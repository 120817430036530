import React, { useContext, useMemo } from 'react';
import type { OptimizedPortfolio, Portfolio } from 'venn-api';
import { PortfolioLabContext } from 'venn-components';
import type { TradeSolution } from './chartConfigUtils';
import { getTradeStatistics } from '../../logic/tradesUtils';
import TradesVsCapitalCardView from './TradesVsCapitalCardView';
import { compact, isNil } from 'lodash';

interface TradesVsCapitalCardContainerProps {
  portfolio?: Portfolio;
  optimizedPortfolio?: OptimizedPortfolio;
  alternatePortfolios?: OptimizedPortfolio[];
}

const TradesVsCapitalCardContainer = ({
  portfolio,
  optimizedPortfolio,
  alternatePortfolios,
}: TradesVsCapitalCardContainerProps) => {
  const { selectedSolution, onSelectSolution } = useContext(PortfolioLabContext);

  const current: TradeSolution | undefined = useMemo(
    () =>
      isNil(portfolio)
        ? undefined
        : {
            solution: { category: 'Current', name: portfolio.name },
            trade: { tradeCount: 0, capitalMoved: 0, investments: [] },
          },
    [portfolio],
  );
  const optimized: TradeSolution | undefined = useMemo(
    () =>
      isNil(portfolio) || isNil(optimizedPortfolio?.portfolio)
        ? undefined
        : {
            solution: { category: 'Optimized' },
            trade: getTradeStatistics(portfolio, optimizedPortfolio.portfolio),
          },
    [portfolio, optimizedPortfolio],
  );
  const alternate: TradeSolution[] = useMemo(
    () =>
      isNil(portfolio) || isNil(alternatePortfolios) || alternatePortfolios.length === 0
        ? []
        : compact(
            alternatePortfolios.map(({ portfolio: alternatePortfolio, summary }, idx) =>
              isNil(portfolio) || isNil(summary.annualizedTotalReturn) || isNil(summary.annualizedVolatility)
                ? undefined
                : {
                    solution: { category: 'Alternate', name: portfolio.name, alternateSolutionIdx: idx },
                    trade: getTradeStatistics(portfolio, alternatePortfolio),
                  },
            ),
          ),
    [portfolio, alternatePortfolios],
  );

  return (
    <TradesVsCapitalCardView
      {...{ current, optimized, alternate, selectedSolution, onSelectSolution }}
      solutionsLoading={isNil(current)}
    />
  );
};

export default TradesVsCapitalCardContainer;
