import React from 'react';
import styled from 'styled-components';

type LetterBadgeProps = {
  letter: string;
} & LetterBadgeDivProps;

type LetterBadgeDivProps = { size: number; color: string; textColor: string; fontSize: number };

export const LetterBadge = ({ size, letter, color, textColor, fontSize }: LetterBadgeProps) => (
  <LetterBadgeDiv size={size} color={color} textColor={textColor} fontSize={fontSize}>
    {letter}
  </LetterBadgeDiv>
);

const LetterBadgeDiv = styled.div<LetterBadgeDivProps>`
  background-color: ${({ color }) => color};
  color: ${({ textColor }) => textColor};
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  font-size: ${({ fontSize }) => fontSize}px;
  border-radius: ${({ size }) => size / 6}px;
  align-items: center;
  justify-content: center;
  display: flex;
`;
