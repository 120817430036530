import type { Theme } from 'venn-ui-kit';
import { mockTheme, IS_JEST_TEST } from 'venn-utils';
import { atom } from 'recoil';

/**
 * Atom storing the theme, synced with the theme context
 * No default is provided, so atom is pending until synced with the theme context
 */
export const theme = atom<Theme>({
  key: 'theme',
  ...(IS_JEST_TEST ? { default: mockTheme } : {}),
});
