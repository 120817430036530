import type { LibraryFilterSectionKey, Nominal, QuickFilter } from 'venn-utils';
import { LibraryItemType } from 'venn-utils';
import type {
  AssetTypeEnum,
  ComputableMetricEnum,
  CurrencyEnum,
  MetricFilter,
  OrderEnum,
  TimePeriodEnum,
} from 'venn-api';

export type MorningstarCategory = Nominal<string, 'M*Id'>;
export type DataSource = Nominal<string, 'Ds'>;

/** Base params to apply each time a filter is selected */
export const BASE_PARAMS: Partial<SearchParams> = {
  page: 1,
  order: 'desc' as OrderEnum,
  sortBy: undefined,
  lastTrackTime: undefined,
};

/** Default params when no filter is specified */
export const DEFAULT_PARAMS_V2: SearchParams = {
  ...BASE_PARAMS,
  page: 1,
  name: '',
  itemType: LibraryItemType.ALL,
  tags: [],
  filters: [],
  selectedIds: [],
  currency: [],
  assetTypes: [],
  metrics: [],
  dataSource: [],
  morningstarCategories: [],
};

export const DEFAULT_PRIVATE_PARAMS: PrivatesSearchParams = {
  ...BASE_PARAMS,
  page: 1,
  itemType: LibraryItemType.ALL,
  selectedIds: [],
  filters: [],
  fundIds: undefined,
  name: '',
};

export interface BaseSearchParams {
  /** Used to mark library items as 'New' if the item was created after lastTrackTime */
  lastTrackTime?: number;
  sortBy?: string;
  order?: OrderEnum;
  page: number;
  selectedIds: string[];
  fundIds?: string[];
  name: string;
  itemType: LibraryItemType;
  [LibraryFilterSectionKey.FILTERS]: QuickFilter[];
}
export interface SearchParams extends BaseSearchParams {
  [LibraryFilterSectionKey.TAGS]: string[];
  metrics: MetricFilter[];
  currency: CurrencyEnum[];
  assetTypes: AssetTypeEnum[];
  dataSource: DataSource[];
  morningstarCategories: MorningstarCategory[];
}
export interface PrivatesSearchParams extends BaseSearchParams {
  pageSize?: number;
}

export type QueryOperator = 'leq' | 'geq';

export interface AdvancedQuery {
  timePeriod: TimePeriodEnum;
  metric: ComputableMetricEnum;
  operator: QueryOperator;
  value: number;
}

export interface FiltersState {
  quickFilters: QuickFilter[];
  itemType: LibraryItemType;
  currency: CurrencyEnum[];
  morningstarCategories: MorningstarCategory[];
  assetTypes: AssetTypeEnum[];
  dataSource: DataSource[];
  advancedQueries: AdvancedQuery[];
  tags: string[];
}
