import type { AnalysisView } from 'venn-api';

export const mockSavedCompareView: AnalysisView = {
  version: 1,
  id: '0dc5576f-63f7-4a0f-8ebe-7c917ef2719a',
  refId: '0dc5576f-63f7-4a0f-8ebe-7c917ef2719a',
  name: 'My first saved comparison',
  created: 1590085941872,
  updated: 1590085941872,
  template: false,
  published: false,
  analysisViewType: 'COMPARE',
  owner: {
    id: 3000209,
    name: 'Ms Anna P',
    displayName: 'Ms Anna P',
    email: 'annap+twosigmadev@twosigma.com',
    active: true,
    created: 1539770288516,
    pwdLastUpdated: undefined,
    avatarId: '3cfd31ca-2d20-45b0-8948-5f48c7927a1d',
    actualUser: false,
    accountId: 1,
  },
  customTemplateId: undefined!,
  systemTemplate: 'ROLLING_RETURN',
  analysisPeriod: {
    startDate: undefined,
    endDate: undefined,
    periodToDate: '1yr',
  },
  subjects: [
    {
      comparisonType: 'BENCHMARK',
      id: '4b41f40e-f0b0-4d18-8314-52bc99616652',
      subjectType: 'INVESTMENT',
      isPrivate: false,
    },
    {
      comparisonType: 'PRIMARY',
      id: '4b41f40e-f0b0-4d18-8314-52bc99616652',
      subjectType: 'INVESTMENT',
      isPrivate: false,
    },
  ],
  customAnalysisParams: [
    {
      id: '8de65e7d-50bc-4ebd-978a-5728e373100f',
      clientId: undefined,
      analysisType: 'ROLLING_RETURN',
      relative: true,
      rollingYears: 3.0,
      buckets: undefined,
      bucketSize: undefined,
      factorLensId: undefined,
      drawdownThreshold: undefined,
      residual: false,
      maximumPoints: 1000,
      analysisDate: undefined,
      scenarios: [],
      contextPortfolio: undefined,
      selectedNotablePeriods: undefined,
    },
  ],
  customViewOptions: { relative: true },
  width: 1,
  templateDescription: '',
  vennTemplate: false,
};

export const mockSavedEmptyReportLabView: AnalysisView = {
  version: 1,
  id: '0dc5576f-63f7-4a0f-8ebe-7c917ef2719a',
  refId: '0dc5576f-63f7-4a0f-8ebe-7c917ef2719a',
  name: 'My first saved analysis',
  created: 1590085941872,
  updated: 1590085941872,
  template: false,
  published: false,
  analysisViewType: 'REPORT',
  owner: {
    id: 12345678,
    name: 'test user name',
    displayName: 'test user display name',
    email: 'test-user-email@testdomain.com',
    active: true,
    created: 1539770288516,
    pwdLastUpdated: undefined,
    avatarId: 'test-avatar-id',
    actualUser: false,
    accountId: 1,
  },
  customTemplateId: undefined!,
  systemTemplate: 'ROLLING_RETURN',
  benchmarkSettings: [],
  dateRanges: [
    {
      id: 'test-date-range-id-1yr',
      label: '1 Year',
      period: {
        periodToDate: 'full',
      },
      resolution: 'BLOCK',
    },
  ],
  subjectGroups: [],
  subjects: [],
  customAnalysisParams: [],
  customViewOptions: { relative: true },
  width: 1,
  templateDescription: '',
  vennTemplate: false,
};

export const mockSavedReportLabView: AnalysisView = {
  version: 1,
  id: '0dc5576f-63f7-4a0f-8ebe-7c917ef2719a',
  refId: '0dc5576f-63f7-4a0f-8ebe-7c917ef2719a',
  name: 'My first saved analysis',
  created: 1590085941872,
  updated: 1590085941872,
  template: false,
  published: false,
  analysisViewType: 'REPORT',
  owner: {
    id: 12345678,
    name: 'test user name',
    displayName: 'test user display name',
    email: 'test-user-email@testdomain.com',
    active: true,
    created: 1539770288516,
    pwdLastUpdated: undefined,
    avatarId: 'test-avatar-id',
    actualUser: false,
    accountId: 1,
  },
  customTemplateId: undefined!,
  systemTemplate: 'ROLLING_RETURN',
  benchmarkSettings: [
    {
      id: 'test-benchmark-input-id',
      label: 'test benchmark input',
      relative: false,
      subject: {
        id: '4b41f40e-f0b0-4d18-8314-52bc99616652',
        isPrivate: false,
        subjectType: 'INVESTMENT',
        comparisonType: 'BENCHMARK',
      },
      type: 'COMMON',
    },
  ],
  dateRanges: [
    {
      id: 'test-date-range-id-1yr',
      label: '1 Year',
      period: {
        periodToDate: 'full',
      },
      resolution: 'BLOCK',
    },
  ],
  subjectGroups: [
    {
      id: 'test-subject-group-id',
      label: 'test subject group label',
      subjects: [
        {
          id: '4b41f40e-f0b0-4d18-8314-52bc99616652',
          isPrivate: false,
          subjectType: 'INVESTMENT',
          comparisonType: 'PRIMARY',
        },
      ],
    },
  ],
  subjects: [
    {
      comparisonType: 'BENCHMARK',
      id: '4b41f40e-f0b0-4d18-8314-52bc99616652',
      subjectType: 'INVESTMENT',
      isPrivate: false,
    },
    {
      comparisonType: 'PRIMARY',
      id: '4b41f40e-f0b0-4d18-8314-52bc99616652',
      subjectType: 'INVESTMENT',
      isPrivate: false,
    },
  ],
  customAnalysisParams: [],
  customViewOptions: { relative: true },
  width: 1,
  templateDescription: '',
  vennTemplate: false,
};
