import React from 'react';
import type { InvestmentRange, PortfolioRange, FrequencyEnum } from 'venn-api';
import type { AnalysisSubject } from 'venn-utils';
import { getSecondaryDisplayLabel } from 'venn-utils';
import { getMaxFrequency, getPrimaryPortfolioStartAndEnd, getRangeFrequency } from '../PortfolioRangesUtils';
import { DefaultText, Frequency, FundName, ListItemWithFrequency } from './styled';
import ExpandableList from './ExpandableList';

interface FrequencyDebugProps {
  subject: AnalysisSubject;
  portfolioRange: PortfolioRange;
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default ({ subject, portfolioRange }: FrequencyDebugProps) => {
  const totalFrequency = getRangeFrequency(portfolioRange);
  const primaryFrequency = getMaxFrequency(portfolioRange.investments.map(({ frequency }) => frequency));
  const benchmarkFrequency = portfolioRange?.benchmark?.frequency;
  const secondaryFrequency = portfolioRange?.secondary?.frequency;

  const totalInvalidFrequency = isInvalidFrequency(totalFrequency);
  const [hidePrimary, hideSecondary, hideBenchmark] = [primaryFrequency, secondaryFrequency, benchmarkFrequency].map(
    (frequency) => !frequency || (totalInvalidFrequency && !isInvalidFrequency(frequency)),
  );
  const [primaryStart, primaryEnd] = getPrimaryPortfolioStartAndEnd(portfolioRange);
  return (
    <>
      {!totalInvalidFrequency ? (
        <>
          The frequency of your analysis is <Frequency>{totalFrequency}</Frequency>. It is calculated by taking the
          lowest frequency of the subjects in your analysis:
        </>
      ) : (
        <>
          The frequency of your analysis is constrained to <Frequency>{totalFrequency}</Frequency> by the following
          items being analyzed:
        </>
      )}
      <ExpandableList initiallyShown={3}>
        {hidePrimary ? null : subject.type === 'investment' ? (
          <ListItemWithFrequency name={subject.name} label="Current Investment" range={portfolioRange.investments[0]} />
        ) : (
          <ListItemWithFrequency
            name="Current Portfolio"
            label={subject.name}
            range={{
              frequency: primaryFrequency,
              start: primaryStart,
              end: primaryEnd,
              id: subject.id.toString(),
              name: portfolioRange.name,
              numReturns: undefined,
            }}
            suffix={
              totalInvalidFrequency
                ? ' because of the following investments:'
                : ' - containing the following investments:'
            }
          >
            <ul>
              {portfolioRange.investments
                .filter(({ frequency }) => !totalInvalidFrequency || isInvalidFrequency(frequency))
                .map((range: InvestmentRange) => (
                  <li key={range.id}>
                    <FundName investment={{ range }} />
                    <DefaultText>
                      {' '}
                      – <Frequency>{range.frequency}</Frequency> frequency
                    </DefaultText>
                  </li>
                ))}
            </ul>
          </ListItemWithFrequency>
        )}

        {!hideBenchmark && (
          <ListItemWithFrequency
            name="Benchmark"
            label={subject.activeBenchmarkName}
            range={portfolioRange.benchmark}
          />
        )}

        {!hideSecondary && (
          <ListItemWithFrequency
            name={
              subject.type === 'portfolio'
                ? getSecondaryDisplayLabel(subject, 'Portfolio as of', 'Portfolio')
                : 'Category'
            }
            label={portfolioRange.secondary.name}
            range={portfolioRange.secondary}
          />
        )}
      </ExpandableList>
    </>
  );
};

export function isInvalidFrequency(frequency: FrequencyEnum): boolean {
  return frequency && frequency !== 'DAILY' && frequency !== 'MONTHLY';
}
