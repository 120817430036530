import React, { useContext, useMemo } from 'react';
import styled, { css, ThemeContext } from 'styled-components';
import type { Theme } from 'venn-ui-kit';
import { EllipsisTooltipSpan, GetColor, Headline2, Hint, Icon, Label, Tooltip } from 'venn-ui-kit';
import type { BasicTableColumn, Solution, StyledTableType } from 'venn-components';
import { ColumnAlign, ContentBlock, PrintContainerDimensions } from 'venn-components';
import { isNil } from 'lodash';
import ConstraintsStatusBar, { ConstraintsStatusBarShimmer } from './ConstraintsStatusBar';
import { formatAllocation, Numbers } from 'venn-utils';
import { getSolutionAttributes } from '../solutionUtils';
import type { PortfolioNodeTradeStatistic, TradesStatistics } from '../../logic/tradesUtils';
import type { Portfolio } from 'venn-api';
import { Table, TableEmptyState, TableShimmer, TableShimmerRow } from './styled-table';
import SuggestedTrade from './SuggestedTrade';

interface OverviewCardViewProps {
  selectedSolution: Solution;
  solutionPortfolio: Portfolio | undefined;
  tradeStatistics: TradesStatistics | undefined;
  portfolioTradeStatistics: PortfolioNodeTradeStatistic[] | undefined;
  loading: boolean;
  objectiveMet: boolean | undefined;
  allocationConstraintsMet: number | undefined;
  factorConstraintsMet: number | undefined;
}

const getColumns = (itemsCount: number, theme: Theme): BasicTableColumn<PortfolioNodeTradeStatistic>[] => {
  return [
    {
      label: 'Name',
      accessor: 'name',
      align: ColumnAlign.LEFT,
      cellRenderer: (
        { investmentTrade, name, level, previousItemLevel, isNew, isDeleted }: PortfolioNodeTradeStatistic,
        index: number,
      ) => (
        <>
          {index > 0 && <PseudoBorder level={Math.max(level, previousItemLevel)} />}
          <PrintContainerDimensions>
            {({ width }) => (
              <Text large={isNil(investmentTrade)} level={level} highlighted={isNew} isDeleted={isDeleted}>
                <EllipsisTooltipSpan content={name} maxWidth={Math.max(width - level * 15, 40)} tooltipMaxWidth={450}>
                  {name}
                  {isNew && <NewOpportunityMarkerIcon type="plus-circle" />}
                </EllipsisTooltipSpan>
              </Text>
            )}
          </PrintContainerDimensions>
        </>
      ),
    },
    {
      label: 'Forecast Return',
      accessor: 'forecastReturn',
      align: ColumnAlign.RIGHT,
      headerStyle: { maxWidth: 45, width: 45 },
      cellStyle: ({ investmentTrade }: PortfolioNodeTradeStatistic, index: number) => ({
        maxWidth: 45,
        width: 45,
        borderBottom: index !== itemsCount - 1 ? `1px solid ${theme.Colors.Grey}` : undefined,
        fontSize: isNil(investmentTrade) ? 14 : 12,
        fontWeight: isNil(investmentTrade) ? 'bold' : undefined,
      }),
      cellRenderer: ({ investmentTrade }: PortfolioNodeTradeStatistic) =>
        Numbers.safeFormatPercentage(investmentTrade?.forecastReturn, 0),
    },
    {
      label: 'Total Trade ($)',
      accessor: 'tradeValue',
      align: ColumnAlign.RIGHT,
      cellStyle: ({ investmentTrade }: PortfolioNodeTradeStatistic, index: number) => ({
        borderBottom: index !== itemsCount - 1 ? `1px solid ${theme.Colors.Grey}` : undefined,
        fontWeight: isNil(investmentTrade) ? 'bold' : undefined,
      }),
      cellRenderer: ({ tradeValue, originalValue, investmentTrade }: PortfolioNodeTradeStatistic) =>
        tradeValue === 0 ? (
          '--'
        ) : (
          <SuggestedTrade
            tradeType={tradeValue > 0 ? 'PURCHASE' : 'SELL'}
            value={tradeValue}
            originalValue={originalValue}
            largeText={isNil(investmentTrade)}
          />
        ),
    },
  ];
};

const OverviewCardView = ({
  selectedSolution,
  solutionPortfolio,
  tradeStatistics,
  portfolioTradeStatistics,
  loading,
  objectiveMet,
  allocationConstraintsMet,
  factorConstraintsMet,
}: OverviewCardViewProps) => {
  const theme = useContext(ThemeContext);
  const { title } = getSolutionAttributes(theme, selectedSolution);

  const [rows, columns] = useMemo(
    () => [portfolioTradeStatistics ?? [], getColumns(portfolioTradeStatistics?.length ?? 0, theme)],
    [portfolioTradeStatistics, theme],
  );

  return (
    <Card>
      <ContentBlock
        header={`${title} Overview`}
        subHeader={
          <Hint>
            These metrics reflect your constraint settings{' '}
            <Tooltip
              content={
                <TooltipContent>
                  Constraints include the objective (set above), as well as the allocation and factor exposure
                  constraints (set in the side "Inputs" menu).
                </TooltipContent>
              }
            >
              <Icon type="info-circle" prefix="far" />
            </Tooltip>
          </Hint>
        }
      >
        {loading ? (
          <ConstraintsStatusBarShimmer />
        ) : (
          <ConstraintsStatusBar
            solutionName={title}
            objectiveConstraintsMet={objectiveMet}
            allocationConstraintsMet={allocationConstraintsMet}
            factorConstraintsMet={factorConstraintsMet}
          />
        )}
        <MainStats>
          <Stat>
            <Label>Total Capital Moved</Label>
            <Headline2>
              {isNil(tradeStatistics?.capitalMoved) ? '--' : formatAllocation(tradeStatistics.capitalMoved, false)}
            </Headline2>
          </Stat>
          <Stat>
            <Label>Number of Trades</Label>
            <Headline2>{tradeStatistics?.tradeCount ?? 0}</Headline2>
          </Stat>
          <Stat>
            <Label>Total AUM</Label>
            <Headline2>
              {isNil(solutionPortfolio?.allocation) ? '--' : formatAllocation(solutionPortfolio.allocation, false)}
            </Headline2>
          </Stat>
        </MainStats>
      </ContentBlock>
      <ContentBlock header="Portfolio Trades Overview" className="qa-trades-overview">
        {loading ? (
          <TableShimmer>
            <TableShimmerRow />
            <TableShimmerRow />
            <TableShimmerRow />
          </TableShimmer>
        ) : rows.length === 0 ? (
          <TableEmptyState header="No trades found" />
        ) : (
          <NoRowBorderTable data={rows} columns={columns} />
        )}
      </ContentBlock>
    </Card>
  );
};

export default OverviewCardView;

const Card = styled.div`
  border: 1px solid ${GetColor.Grey};
  & > div {
    border: none;
  }
`;

const TooltipContent = styled.div`
  text-align: center;
  width: 236px;
  padding: 5px;
`;

const MainStats = styled.div`
  padding: 25px 20px 0 20px;
`;

const Stat = styled.div`
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  & > h2 {
    margin-top: 2px;
    margin-bottom: 2px;
  }
`;

const Text = styled.span<{ large?: boolean; level: number; isDeleted?: boolean; highlighted?: boolean }>`
  position: absolute;
  height: 35px;
  margin-top: -17px;
  padding-top: 8px;
  ${({ large }) =>
    large
      ? css`
          font-size: 14px;
          font-weight: bold;
          padding-top: 9px;
        `
      : css`
          font-size: 12px;
          padding-top: 12px;
        `}
  ${({ level }) =>
    level > 1
      ? css`
          margin-left: ${(level - 2) * 15}px;
          border-left: 1px dashed ${GetColor.MidGrey1};
          padding-left: 10px;
        `
      : level === 1
        ? 'margin-left: -5px;'
        : null}
   ${({ isDeleted }) =>
    isDeleted &&
    css`
      span {
        text-decoration: line-through;
      }
    `}
   ${({ highlighted }) =>
    highlighted &&
    css`
      color: ${GetColor.HighlightDark};
    `}

  // For "new opportunity" marker icon - it can be closer to "..." when the text content is truncated
  .ellipsis-overflowing > i {
    right: -10px;
  }
`;

const NoRowBorderTable: StyledTableType<unknown> = styled(Table)`
  tbody {
    tr {
      border-bottom: none;
    }
  }

  @media print {
    tbody tr > td:nth-child(1) {
      width: 100%;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
      position: relative;
      padding-top: 18px;
    }
  }
`;

const PseudoBorder = styled.div<{ level: number }>`
  position: absolute;
  border-bottom: 1px solid ${GetColor.Grey};
  width: 100%;
  margin-top: -18.5px;
  ${({ level }) => css`
    margin-left: ${(level - 1) * 15 - 4}px;
  `}
`;

const NewOpportunityMarkerIcon = styled(Icon)`
  position: absolute;
  top: 0;
  right: -14px;
`;
