import React from 'react';
import { type PortfolioMultiUploaderView } from '../types';
import { IChooseActionView, IDuplicatePortfolioView, INewPortfolioView, IUploadPortfolioView } from '../constants';
import { LeftContainer, MainViewContainer, SectionContainer } from './shared/layout';
import styled from 'styled-components';
import { OptionTile } from './shared/OptionTile';
import { UniversalUploaderFooter } from '../components/page-parts/UniversalUploaderFooter';

type ChooseActionViewProps = {
  setView: (view: PortfolioMultiUploaderView) => void;
};

export const ChoosePortfolioActionView = ({ setView }: ChooseActionViewProps) => {
  const options: PortfolioMultiUploaderView[] = [INewPortfolioView, IDuplicatePortfolioView, IUploadPortfolioView];

  return (
    <MainContainer>
      <MainViewContainer>
        <LeftContainer>
          <SectionContainer key="portfolios-section">
            {options.map((option) => (
              <OptionTile onClick={() => setView(option)} option={option} key={option.id} />
            ))}
          </SectionContainer>
        </LeftContainer>
      </MainViewContainer>
      <UniversalUploaderFooter onCancel={() => setView(IChooseActionView)} />
    </MainContainer>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
