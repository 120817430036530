import BigNumber from 'bignumber.js';
import { isNil } from 'lodash';

const FORMATTING_PRECISION = 2;
const TRILLION = 10 ** 12;
const BILLION = 10 ** 9;
const MILLION = 10 ** 6;

const noNegativeZero = (valueString: string): string =>
  Number(valueString) === 0 && valueString.startsWith('-') ? valueString.substr(1) : valueString;

const formatPercentage = (value: number | string, precision = FORMATTING_PRECISION, usePercentageSign = true) => {
  const sign = usePercentageSign ? '%' : '';
  return `${initBigNumber(value, precision, 100)}${sign}`;
};

const initBigNumber = (value: number | string, precision: number, multiplier = 1) =>
  noNegativeZero(new BigNumber(String(value)).times(multiplier).toFormat(precision));
const isOfAtLeastTrillionScale = (num: number) => Math.abs(num) >= TRILLION;
const isOfAtLeastBillionScale = (num: number) => Math.abs(num) >= BILLION;
const isOfAtLeastMillionScale = (num: number) => Math.abs(num) >= MILLION;

interface FormatterOptions {
  forceSign: boolean;
  percentage: boolean;
  safe?: boolean;
  precision?: number;
}

export default class Numbers {
  static formatCorrelation(correlation: number | null | undefined) {
    const none = '--';
    if (correlation === null || correlation === undefined || Number.isNaN(correlation)) {
      return none;
    }
    if (correlation > 1) {
      return none;
    }
    if (correlation < -1) {
      return none;
    }
    const rounded = correlation.toFixed(2);

    if (rounded === '1.00' || rounded === '-1.00' || rounded === '0.00') {
      return rounded;
    }

    return correlation < 0 ? rounded : rounded.substr(1);
  }

  static formatPercentMatch(percentMatch: number | null | undefined) {
    const none = '--';
    if (percentMatch === null || percentMatch === undefined || Number.isNaN(percentMatch)) {
      return none;
    }
    if (percentMatch > 1) {
      return none;
    }
    if (percentMatch < 0) {
      return none;
    }
    const rounded = (percentMatch * 100).toFixed(0);

    return `${rounded}%`;
  }

  static safeFormatPercentage = (
    value: number | string | null | undefined,
    precision = FORMATTING_PRECISION,
    usePercentageSign = true,
    removeTrailingZeros?: boolean,
  ) => {
    if (typeof value !== 'number' || isNil(value) || Number.isNaN(value)) {
      return '--';
    }
    let percentage = formatPercentage(value, precision, usePercentageSign);

    if (removeTrailingZeros) {
      percentage = percentage.replace(`.${'0'.repeat(precision)}`, '');
    }
    return percentage;
  };

  static formatNumber = (value: number | string, precision = FORMATTING_PRECISION) => initBigNumber(value, precision);

  static safeFormatNumber = (value?: number | string | null, precision?: number) => {
    if (typeof value !== 'number' || value === null || Number.isNaN(value)) {
      return '--';
    }
    return Numbers.formatNumber(value, precision);
  };

  static createNumberFormatter = (options: FormatterOptions) => {
    const { forceSign, percentage, safe = true, precision = FORMATTING_PRECISION } = options;

    return (value: number | string | undefined) => {
      if (safe && typeof value !== 'number') {
        return '--';
      }

      const multiplier = percentage ? 100 : 1;
      // TODO: do we really want to just return NaN? Should we log to sentry or something? Can value really be undefined?
      const formattedValue = value === undefined ? NaN : initBigNumber(value, precision, multiplier);
      const prefix = forceSign && Math.sign(Number(formattedValue)) === 1 ? '+' : '';
      const suffix = percentage ? '%' : '';
      return `${prefix}${formattedValue}${suffix}`;
    };
  };

  static getNumberShorthand = (num: number) => {
    if (isOfAtLeastTrillionScale(num)) {
      return 'T';
    }

    if (isOfAtLeastBillionScale(num)) {
      return 'B';
    }

    if (isOfAtLeastMillionScale(num)) {
      return 'M';
    }

    return '';
  };

  static shortenNumber = (num: number) => {
    if (isOfAtLeastTrillionScale(num)) {
      return num / TRILLION;
    }

    if (isOfAtLeastBillionScale(num)) {
      return num / BILLION;
    }

    if (isOfAtLeastMillionScale(num)) {
      return num / MILLION;
    }

    return num;
  };
}
