import React, { Component } from 'react';
import { Content } from '../account-modals/shared';
import EnableSMS from './EnableSMS';
import EnableAuthenticator from './EnableAuthenticator';
import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';
import type { Method } from './utils';
import ModalFooter from '../../modal/ModalFooter';
import TwoFactorOptions from './TwoFactorOptions';

interface EnableTwoFactorProps {
  onClose: () => void;
  refresh: () => void;
  initializeMethod: Method | null;
}

interface EnableTwoFactorState {
  selectedSMS: boolean;
  selectedGoogleAuthentication: boolean;
}

export class EnableTwoFactor extends Component<EnableTwoFactorProps, EnableTwoFactorState> {
  constructor(props: EnableTwoFactorProps) {
    super(props);

    const { initializeMethod } = props;

    this.state = {
      selectedSMS: initializeMethod === 'Text',
      selectedGoogleAuthentication: initializeMethod === 'Google Authenticator',
    };
  }

  onSelectSMS = () => {
    this.setState({
      selectedSMS: true,
    });
  };

  onSelectGoogleAuthentication = () => {
    this.setState({
      selectedGoogleAuthentication: true,
    });
  };

  render() {
    const { initializeMethod, onClose, refresh } = this.props;
    const { selectedSMS, selectedGoogleAuthentication } = this.state;
    // if the prompt was initialized with a method, then we show Cancel instead of Back
    const showBack = !initializeMethod;

    if (selectedSMS) {
      return (
        <EnableSMS
          refresh={refresh}
          onClose={onClose}
          onBack={showBack ? () => this.setState({ selectedSMS: false }) : undefined}
        />
      );
    }

    if (selectedGoogleAuthentication) {
      return (
        <EnableAuthenticator
          refresh={refresh}
          onClose={onClose}
          onBack={showBack ? () => this.setState({ selectedGoogleAuthentication: false }) : undefined}
        />
      );
    }

    return (
      <>
        <StyledContent>
          <StyledText>Select a primary method of secondary authentication.</StyledText>
          <TwoFactorOptions
            onSelectGoogleAuthentication={this.onSelectGoogleAuthentication}
            onSelectSMS={this.onSelectSMS}
          />
        </StyledContent>
        <ModalFooter onCancel={this.props.onClose} />
      </>
    );
  }
}

const StyledText = styled.p`
  font-size: 32px;
  font-weight: 300;
  color: ${GetColor.Black};
  line-height: normal;
  margin-top: 7px;
`;

const StyledContent = styled(Content)`
  padding-bottom: 70px;
`;
