/**
 * A function that returns a delay in milliseconds given the number of attempts so far, where zero attempts so far means this is the very first attempt.
 */
export type BackoffFn = (attemptsSoFar: number) => number;

export const RECOMMENDED_BACKOFF_FN = fullJitteredExponentialBackoff(750, 30_000);

/**
 * Returns a {@link BackoffFn} that will use a full jittered exponential backoff strategy.
 *
 * @see {@link https://aws.amazon.com/blogs/architecture/exponential-backoff-and-jitter/} for more information on the backoff strategy.
 */
export function fullJitteredExponentialBackoff(baseDelayMs: number, maxDelayMs: number): BackoffFn {
  return (attemptsSoFar: number): number => {
    if (attemptsSoFar === 0) return 0;

    const exponentialDelay = baseDelayMs * 2 ** attemptsSoFar;
    const delay = Math.min(maxDelayMs, exponentialDelay);
    return delay * Math.random();
  };
}
