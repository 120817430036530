import React from 'react';
import styled from 'styled-components';
import { Icon, Tooltip } from 'venn-ui-kit';

interface MissedTargetIconProps {
  name: string;
  value: number;
  color?: string;
  formatter: (v: number) => string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  position?: 'left' | 'right';
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default ({
  name,
  value,
  color,
  formatter,
  onMouseEnter,
  onMouseLeave,
  position = 'left',
}: MissedTargetIconProps) => (
  <MissedTargetIconContainer color={color} position={position} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
    <Tooltip
      largerPointer
      content={
        <MissedTargetTooltip>
          <h2>
            It wasn't possible to meet your goal of {formatter(value)} {name.toLowerCase()}.
          </h2>
          <p>Try loosening allocation and/or factor constraints.</p>
        </MissedTargetTooltip>
      }
    >
      <Icon type="info-circle" />
    </Tooltip>
  </MissedTargetIconContainer>
);

const MissedTargetIconContainer = styled.span<{ color?: string; position: 'left' | 'right' }>`
  i {
    color: ${(props) => props.color || 'inherit'};
    ${({ position }) => (position === 'left' ? 'margin-right: 5px;' : 'margin-left: 5px;')}
  }
`;

const MissedTargetTooltip = styled.div`
  padding: 5px 10px;
  text-align: left;
  font-size: 10px;
  h2 {
    font-size: 12px;
    font-weight: bold;
    margin: 0 0 7px 0;
  }
`;
