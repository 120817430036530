import type { ReactNode } from 'react';
import React from 'react';
import { Icon } from 'venn-ui-kit';
import { ConstraintList, ToggleLink } from './styled';

export interface ExpandableListProps {
  initiallyShown?: number;
  children?: ReactNode;
}

export interface ExpandableListState {
  expanded: boolean;
}

export default class ExpandableList extends React.Component<ExpandableListProps, ExpandableListState> {
  state = { expanded: false };

  render() {
    const { expanded } = this.state;
    const { initiallyShown = 2 } = this.props;

    const children = React.Children.toArray(this.props.children);

    if (initiallyShown >= children.length) {
      return <ConstraintList>{this.props.children}</ConstraintList>;
    }

    return (
      <ConstraintList>
        {children.slice(0, initiallyShown)}
        {expanded && children.slice(initiallyShown, children.length)}
        <ToggleLink onClick={() => this.setState({ expanded: !expanded })}>
          {!expanded && (
            <>
              <Icon type="arrow-circle-down" />
              <span style={{ textDecoration: 'italic' }}>
                {children.length - initiallyShown === 1
                  ? '+1 other investment'
                  : `+${children.length - initiallyShown} other investments`}
              </span>
            </>
          )}
          {expanded && (
            <>
              <Icon type="arrow-circle-up" />
              Collapse list
            </>
          )}
        </ToggleLink>
      </ConstraintList>
    );
  }
}
