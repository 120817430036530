import React from 'react';
import styled, { withTheme } from 'styled-components';
import type { FundingFailure } from 'venn-api';
import { GetColor, Icon, type Theme } from 'venn-ui-kit';
import { Dates } from 'venn-utils';
import type { StudioRequestSubject } from '../../../../venn-state/src';
import { formatData } from '../../data-grid';
import { formatExportableSubjectWithOptionalFee } from '../../legend';
import type { ConfidenceLevel } from '../logic/useConfidenceLevels';
import { seriesNameFromPercentage } from './blocks/public-private/utils';

type FundingFailureTooltipContentProps = Readonly<{
  fundingFailure: FundingFailure;
  confidenceLevel: ConfidenceLevel;
  publicSubject: StudioRequestSubject;
  privateSubject: StudioRequestSubject;
  theme: Theme;
}>;

const FUNDS_TO_DISPLAY = 5;

const FundingFailureTooltipContent = ({
  fundingFailure,
  confidenceLevel,
  publicSubject,
  privateSubject,
}: FundingFailureTooltipContentProps) => {
  return (
    <Wrapper>
      <Header>
        <Icon type="triangle-exclamation" prefix="far" />
        <div>Projected funding failure: insufficient funds to meet private capital call</div>
      </Header>
      <Content>
        <div>
          {formatExportableSubjectWithOptionalFee(publicSubject)}
          {' + '}
          {formatExportableSubjectWithOptionalFee(privateSubject)}
        </div>
        <div>
          {Dates.toQuarterYear(fundingFailure.date)}, Risk Percentile {seriesNameFromPercentage(confidenceLevel.level)}
        </div>
        <div>Total Projected Returns-Based NAV: {formatData(fundingFailure.projectedTotalNav, 'CURRENCY')}</div>
        <div>Total Projected Distributions: {formatData(fundingFailure.projectedTotalDistributions, 'CURRENCY')}</div>
        <div>Total Projected Capital Call: {formatData(fundingFailure.projectedTotalCapitalCall, 'CURRENCY')}</div>
        <ul>
          {fundingFailure.fundCapitalCalls.slice(0, FUNDS_TO_DISPLAY).map((fundCapitalCall) => (
            <li key={fundCapitalCall.fundId}>
              {fundCapitalCall.name}: {formatData(fundCapitalCall.projectedCapitalCall, 'CURRENCY')}
            </li>
          ))}
          {fundingFailure.fundCapitalCalls.length > FUNDS_TO_DISPLAY && (
            <li key="other">...plus {fundingFailure.fundCapitalCalls.length - FUNDS_TO_DISPLAY} other(s)</li>
          )}
        </ul>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 300px;
  min-width: 300px;
  word-break: break-word;
  white-space: break-spaces;
  height: auto;
  font-size: 12px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid ${GetColor.White};
  padding-bottom: 4px;

  & i {
    display: block;
    font-size: 26px;
  }

  & div {
    flex-grow: 1;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-top: 4px;

  ul {
    padding-left: 0;
  }

  ul > li {
    display: flex;
    align-items: center;
    padding-bottom: 4px;
  }

  ul > li::before {
    content: '';
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: ${GetColor.Error};
    margin-right: 0.5rem;
  }
`;

export default withTheme(FundingFailureTooltipContent);
