import React from 'react';
import { useRecoilValue } from 'recoil';
import { blockLimitedHoldingsSubjectsWithFees, isPrintingState, isReportState } from 'venn-state';
import { useSectorHoldingsTreeGrid } from '../../logic/useSectorHoldingsTreeGrid';
import { useCommonGridProps } from '../../logic/columnUtils';

import ExportableGrid from '../grid/ExportableGrid';
import { GraphicSubjectLabel } from './GraphicSubjectLabel';
import { useIsBlockGridCompact } from '../../logic/useIsBlockGridCompact';

export const HoldingsBarChart = ({ selectedRefId }: { selectedRefId: string }) => {
  const isReport = useRecoilValue(isReportState);
  const isPrinting = useRecoilValue(isPrintingState);
  const subject = useRecoilValue(blockLimitedHoldingsSubjectsWithFees(selectedRefId))[0];
  const { chartData, columnDefs } = useSectorHoldingsTreeGrid({ selectedRefId, isBarChart: true });

  const gridProps = useCommonGridProps();
  const isCompact = useIsBlockGridCompact();

  return (
    <div>
      {subject && (isReport || isPrinting) && <GraphicSubjectLabel subject={subject} />}
      <ExportableGrid
        {...gridProps}
        exportable
        selectedRefId={selectedRefId}
        rowData={chartData}
        columnDefs={columnDefs}
        isCompact={isCompact}
      />
    </div>
  );
};
