import { PanelSection } from '../../../PanelSection';
import { SelectableMetricsGroup, UserContext } from 'venn-components';
import { noop } from 'lodash';
import React, { useCallback, useContext } from 'react';
import type { DropMenuCheckboxItem } from 'venn-ui-kit';
import { useRecoilState } from 'recoil';
import { viewSelectedInvestmentGroupInformation } from 'venn-state';

type InvestmentGroupInformationSectionProps = {
  selectedBlockId: string;
};
export const InvestmentGroupInformationSection = ({ selectedBlockId }: InvestmentGroupInformationSectionProps) => {
  const { hasPermission } = useContext(UserContext);
  const isReadOnly = !hasPermission('STUDIO_EDIT_METRICS');
  const [selectedItems, setSelectedInvestmentGroupSummaryItems] = useRecoilState(
    viewSelectedInvestmentGroupInformation(selectedBlockId),
  );
  const items: DropMenuCheckboxItem[] = [
    {
      label: '5th Percentile',
      value: 'p5',
    },
    {
      label: '25th Percentile',
      value: 'p25',
    },
    {
      label: 'Median',
      value: 'p50',
    },
    {
      label: '75th Percentile',
      value: 'p75',
    },
    {
      label: '95th Percentile',
      value: 'p95',
    },
    {
      label: '# of Investments',
      value: 'constituents',
    },
  ].map((item) => {
    return {
      ...item,
      checked: !selectedItems || selectedItems.includes(item.value),
    };
  });

  const onChange = useCallback(
    (items: DropMenuCheckboxItem<string>[]) => {
      setSelectedInvestmentGroupSummaryItems(items.filter((item) => item.checked).map((item) => item.value));
    },
    [setSelectedInvestmentGroupSummaryItems],
  );

  return (
    <PanelSection header="Peer Group Summary">
      <SelectableMetricsGroup
        selectedBlockId={selectedBlockId}
        onChangeFilter={onChange}
        items={items}
        selectedMetricText=""
        reorderableItemGroups={[]}
        onReorderItemsInGroups={noop}
        className="qa-periods-dropdown"
        disabled={isReadOnly}
      />
    </PanelSection>
  );
};
