import React from 'react';
import type { Portfolio } from 'venn-api';
import { getSpecificPortfolioV3 } from 'venn-api';
import buildOptions from './build-options';
import EvaluateInPortfolioModalContent from './EvaluateInPortfolioModalContent';
import { isNil } from 'lodash';
import { logExceptionIntoSentry } from 'venn-utils';
import PortfoliosContext from '../../contexts/portfolios-context';

const SCRATCH_PORTFOLIO_ID = undefined;

export interface EvaluateInPortfolioModalProps {
  headerText: string;
  subheaderText?: string;
  buttonText: string;
  portfolioSelectorText?: string;
  strategySelectorText?: string;
  allocationText: string;

  masterPortfolio?: Portfolio;

  portfolioLabStyles?: boolean;

  onSubmit(
    portfolio: Portfolio | undefined,
    allocation: number | null,
    addToStrategyId?: number,
    addToNewStrategyName?: string,
  ): void;
  onClose(): void;
}

export interface EvaluateInPortfolioModalState {
  isLoading: boolean;

  selectedPortfolio?: Portfolio;

  selectedStrategy?: Portfolio;

  allocation: string | null;
  allocationError?: string;

  loadedDefaults: boolean;
}

class EvaluateInPortfolioModal extends React.Component<EvaluateInPortfolioModalProps, EvaluateInPortfolioModalState> {
  static getDerivedStateFromProps(nextProps: EvaluateInPortfolioModalProps, prevState: EvaluateInPortfolioModalState) {
    if (prevState.loadedDefaults) {
      return null;
    }

    const { masterPortfolio } = nextProps;
    return {
      selectedPortfolio: masterPortfolio,
      allocation: null,
      loadedDefaults: true,
    };
  }

  constructor(props: EvaluateInPortfolioModalProps) {
    super(props);
    this.state = {
      isLoading: false,
      selectedPortfolio: undefined,
      selectedStrategy: undefined,
      allocation: null,
      allocationError: undefined,
      loadedDefaults: false,
    };
  }

  startLoading = () => this.setState({ isLoading: true });

  render() {
    const {
      headerText,
      subheaderText,
      buttonText,
      allocationText,
      onClose,
      portfolioSelectorText,
      strategySelectorText,
      portfolioLabStyles,
    } = this.props;
    const { isLoading, selectedPortfolio, selectedStrategy, allocationError } = this.state;
    return (
      <EvaluateInPortfolioModalContent
        isLoading={isLoading}
        headerText={headerText}
        subheaderText={subheaderText}
        buttonText={buttonText}
        portfolioSelectorText={portfolioSelectorText}
        strategySelectorText={strategySelectorText}
        allocationText={allocationText}
        onSubmit={this.onSubmit}
        onClose={onClose}
        selectedPortfolio={selectedPortfolio}
        onSelectPortfolio={this.onSelectPortfolio}
        strategies={buildOptions(selectedPortfolio)}
        selectedStrategy={selectedStrategy}
        onSelectStrategy={this.onSelectStrategy}
        allocationError={allocationError}
        onChangeAllocation={this.onChangeAllocation}
        portfolioLabStyles={portfolioLabStyles}
      />
    );
  }

  onSelectPortfolio = async (portfolio: Portfolio) => {
    const { selectedPortfolio: currentSelectedPortfolio } = this.state;

    const bothUndefined = currentSelectedPortfolio === undefined && portfolio.id === SCRATCH_PORTFOLIO_ID;
    const haveSameId =
      (currentSelectedPortfolio === undefined ? undefined : currentSelectedPortfolio.id) === portfolio.id;
    if (bothUndefined || haveSameId) {
      // The selection didn't change
      return;
    }

    let selectedPortfolio: Portfolio | undefined;
    // Otherwise, start loading portfolio data
    this.startLoading();
    try {
      selectedPortfolio = (await getSpecificPortfolioV3(portfolio.id)).content;
    } catch (error) {
      logExceptionIntoSentry(error);
      selectedPortfolio = undefined;
    }

    this.setState({
      selectedPortfolio,
      selectedStrategy: undefined,
      isLoading: false,
    });
  };

  onSelectStrategy = (selectedStrategy: Portfolio) => {
    this.setState({
      selectedStrategy,
    });
  };

  onChangeAllocation = (allocation: string | null) => {
    this.setState({
      allocation: allocation !== '' ? allocation : null,
      allocationError: Number.isNaN(Number(allocation)) ? 'Invalid number' : undefined,
    });
  };

  onSubmit = () => {
    const { selectedPortfolio, allocation, selectedStrategy } = this.state;

    const addToNewStrategy = selectedStrategy === undefined || selectedStrategy.id === undefined;
    const addToNewStrategyName = addToNewStrategy && selectedStrategy !== undefined ? selectedStrategy.name : undefined;

    const addToStrategyId = addToNewStrategy ? undefined : selectedStrategy.id;

    this.props.onSubmit(
      selectedPortfolio,
      isNil(allocation) ? null : Number(allocation),
      addToStrategyId,
      addToNewStrategyName,
    );
  };
}

const WithMaster = (props: Omit<EvaluateInPortfolioModalProps, 'masterPortfolio'>) => (
  <PortfoliosContext.Consumer>
    {({ masterPortfolio }) => <EvaluateInPortfolioModal {...props} masterPortfolio={masterPortfolio} />}
  </PortfoliosContext.Consumer>
);

export default WithMaster;
