import type { FC } from 'react';
import React, { useContext } from 'react';
import { upperFirst, compact } from 'lodash';
import { Highchart } from '../../highchart/Highchart';
import { DownloadableContentBlock } from '../../content-block';
import type { ContentChartLegendItem } from '../../content-chart-legend';
import { ContentChartLegend } from '../../content-chart-legend';
import type { AnalysisSubject, AnalysisGroup } from 'venn-utils';
import { getAnalysisLabels, convertReturnDataToExcel } from 'venn-utils';
import type { FrequencyEnum, ReturnsHistogram } from 'venn-api';
import { getReturnsDistributionChartConfigOptions } from './internal/returns-distribution-utils';
import type { TrackAnalysisProps } from '../chart-errors/TrackAnalysis';
import { TrackSuccessfulAnalysis } from '../chart-errors/TrackAnalysis';
import { ThemeContext } from 'styled-components';
import type { DownloadMetaData } from '../../downloadable';

interface ReturnsDistributionChartProps {
  subject: AnalysisSubject;
  relative: boolean;
  frequency?: FrequencyEnum;
  analysisGroup?: AnalysisGroup<ReturnsHistogram>;
  print: boolean;
  noChartTrackingProps: TrackAnalysisProps;
  downloadMetaData?: DownloadMetaData;
}

const ReturnsDistributionChart: FC<React.PropsWithChildren<ReturnsDistributionChartProps>> = ({
  subject,
  frequency,
  relative,
  analysisGroup,
  print,
  noChartTrackingProps,
  downloadMetaData,
}) => {
  const theme = useContext(ThemeContext);
  const {
    Schemes: { BarChartColors },
  } = theme;

  if (!analysisGroup || !frequency) {
    return null;
  }
  const header = relative ? 'Relative Returns Distribution' : 'Returns Distribution';
  const hasBenchmark = !!subject.activeBenchmark;
  const hasSecondary = subject.type === 'portfolio' && !!analysisGroup.category;
  const hasCategory = subject.type === 'investment' && !!analysisGroup.category;
  const labels = getAnalysisLabels(subject.type, subject.secondaryLabel, subject.secondaryPortfolio?.updated);
  const colors = [BarChartColors.mainColor, BarChartColors.secondaryColor, BarChartColors.comparisonColor];
  const legend: ContentChartLegendItem[] = compact([
    {
      name: labels.main,
      color: colors.shift() || BarChartColors.mainColor,
    },
    hasSecondary
      ? {
          name: labels.comparison ?? '',
          color: colors.shift() || BarChartColors.secondaryColor,
        }
      : null,
    hasBenchmark && !relative
      ? {
          name: labels.benchmark,
          color: colors.shift() || BarChartColors.comparisonColor,
        }
      : null,
    hasCategory
      ? {
          name: labels.category ?? '',
          color: colors.shift() || BarChartColors.comparisonColor,
        }
      : null,
  ]);
  const chartConfig = getReturnsDistributionChartConfigOptions(
    subject,
    frequency,
    analysisGroup,
    legend,
    theme,
    relative,
    print,
  );
  const frequencyType = upperFirst(frequency.toLowerCase());
  const excelData = convertReturnDataToExcel(chartConfig, relative);

  return (
    <DownloadableContentBlock
      header={header}
      subHeader={`${frequencyType} returns for ${labels.main}`}
      downloadable={{
        png: true,
        excel: excelData,
        options: {
          fileName: header,
          metaData: downloadMetaData,
        },
        tracking: {
          description: 'RETURNS_DISTRIBUTION',
          relativeToBenchmark: relative,
          subjectType: subject.type,
          subjectId: subject.id,
          userUploaded: subject.userUploaded,
        },
      }}
    >
      <TrackSuccessfulAnalysis {...noChartTrackingProps}>
        <ContentChartLegend items={legend} histogram />
        <Highchart options={chartConfig} className="qa-chart-container" />
      </TrackSuccessfulAnalysis>
    </DownloadableContentBlock>
  );
};

export default ReturnsDistributionChart;
