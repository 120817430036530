import type { ReactNode } from 'react';
import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import KeyCodes from '../../../KeyCode';
import { ZIndex } from '../../../zIndexValues';
import { GetColor } from '../../../style';
import { ExternalActivityListener } from '../../external-activity-listener';
import { RelativePortal } from '../../relative-portal';
import { portalMenuIgnoreActivityClassName } from './BaseDropMenu';
import classNames from 'classnames';
import type { DropdownOpened } from 'venn-utils';
import { analyticsService } from 'venn-utils';

const IGNORE_ACTIVITY_CLASSNAME = 'portal-ignore-activity';

type ApplyFunction<T extends unknown[]> = (...args: T) => void;

export type MenuPosition = 'left' | 'right' | 'center';

interface SkeletalDropMenuProps<T extends unknown[]> {
  /**
   * The class name to pass to the drop menu container
   */
  className?: string;
  /**
   * Provides the menu component. onApply calls the prop onApply and closes the drop menu
   */
  menuComponent: (onApply: ApplyFunction<T>, onClose: () => void) => React.ReactNode;
  /**
   * Provides the trigger component
   */
  triggerComponent: (
    expanded: boolean,
    toggleMenu: (open?: boolean) => void,
    onApply: ApplyFunction<T>,
  ) => React.ReactNode;
  /**
   * Whether the trigger should be disabled or not
   */
  disabled?: boolean;
  /**
   * The function to pass through to the menu component
   */
  onApply?: ApplyFunction<T>;
  /**
   * Whether the picker will open on the right. The default is on the left.
   */
  menuPosition?: MenuPosition;
  /**
   * Whether menu opens in a portal or not. Useful when the drop menu is located in a modal or another drop menu.
   */
  usePortal?: boolean;
  /**
   * Function to call when there is activity (e.g. a click) outside the dropmenu
   */
  onExternalActivity?: () => void;
  /**
   * Tracking event fields to use.
   * If unspecified, tracking events are not fired
   * (useful when more specific tracking events are needed).
   */
  analyticsProps?: DropdownOpened;
  children?: ReactNode;
  /** Enable Auto scroll when menu open */
  autoScrollSelector?: string;
  /** Enable auto scroll back when menu close */
  autoScrollParentSelector?: string;
}

const getMenuPositionValues = (menuPosition?: MenuPosition) => {
  switch (menuPosition) {
    case 'right':
      return {
        left: 0,
        right: undefined,
      };
    case 'center':
      return {
        left: '50%',
        right: undefined,
        transform: 'translateX(-50%)',
      };
    case 'left':
    // fall through, default is left
    default:
      return {
        left: undefined,
        right: 0,
      };
  }
};

/**
 * Skeletal drop menu whose trigger and menu components must be specified.
 * Only use this when the other drop menu implementations don't fit your use case.
 */
export function SkeletalDropMenu<T extends unknown[]>({
  className,
  menuPosition,
  disabled,
  onApply,
  triggerComponent,
  menuComponent,
  usePortal,
  onExternalActivity,
  analyticsProps,
  autoScrollParentSelector,
  autoScrollSelector,
}: SkeletalDropMenuProps<T>) {
  const [opened, setOpened] = useState(false);
  // Only use the callback to read previous position. Try to avoid bug for outdated original parent position when call requestAnimationFrame.
  const [, setOriginalParentPosition] = useState<number>();

  useEffect(() => {
    if (!autoScrollSelector) {
      return;
    }
    // Auto scroll to action item when it's open
    if (opened) {
      window.requestAnimationFrame(() => {
        // If we want to remember original parent position to scroll back
        if (autoScrollParentSelector) {
          setOriginalParentPosition(document.querySelector(autoScrollParentSelector)?.scrollTop);
        }

        const targetElement = document.querySelector(autoScrollSelector);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' });
        }
      });

      // Auto scroll back to original position when close
    } else if (autoScrollParentSelector) {
      window.requestAnimationFrame(() => {
        setOriginalParentPosition((prev) => {
          if (prev !== undefined) {
            const container = document.querySelector(autoScrollParentSelector);
            container?.scroll({ top: prev, behavior: 'smooth' });
          }
          return undefined;
        });
      });
    }
  }, [opened, autoScrollSelector, autoScrollParentSelector]);

  const sendAnalyticsEvent = useCallback(
    (open: boolean) => {
      if (!analyticsProps) {
        return;
      }
      if (open) {
        analyticsService.dropdownOpened(analyticsProps);
      } else {
        analyticsService.dropdownClosed(analyticsProps);
      }
    },
    [analyticsProps],
  );

  const handleTriggerClick = useCallback(() => {
    if (!disabled) {
      setOpened((prev) => {
        const resultingState = !prev;
        sendAnalyticsEvent(resultingState);
        return resultingState;
      });
    }
  }, [disabled, sendAnalyticsEvent]);

  const onClose = useCallback(() => {
    setOpened(false);
    sendAnalyticsEvent(false);
  }, [sendAnalyticsEvent]);

  const handleFilter = useCallback(
    (...updated: T) => {
      onApply?.(...updated);
      setOpened(false);
      sendAnalyticsEvent(false);
    },
    [onApply, sendAnalyticsEvent],
  );
  const handleKeyUp = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.keyCode === KeyCodes.Escape) {
        e.preventDefault();
        setOpened(false);
        sendAnalyticsEvent(false);
      }
    },
    [sendAnalyticsEvent],
  );

  const handleExternalActivity = useCallback(() => {
    setOpened(false);
    sendAnalyticsEvent(false);
    onExternalActivity?.();
  }, [onExternalActivity, sendAnalyticsEvent]);

  const menuPositionValues = getMenuPositionValues(menuPosition);

  return (
    <ExternalActivityListener
      onExternalActivity={handleExternalActivity}
      listeningEnabled={opened}
      debounce={false}
      ignoreActivityFromClassName={IGNORE_ACTIVITY_CLASSNAME}
    >
      <Container onKeyUp={handleKeyUp} className={className}>
        {triggerComponent(opened, handleTriggerClick, handleFilter)}
        {opened && (
          <PortalPlaceholder role="dialog" aria-modal {...menuPositionValues}>
            {usePortal ? (
              <RelativePortal
                component="div"
                leftOffset={menuPositionValues.right === undefined ? 0 : undefined}
                rightOffset={menuPositionValues.left === undefined ? 0 : undefined}
                className={classNames(IGNORE_ACTIVITY_CLASSNAME, portalMenuIgnoreActivityClassName)}
                style={menuPositionValues.transform ? { transform: menuPositionValues.transform } : undefined}
              >
                <Popup data-testid="qa-skeletal-drop-menu-popup">{menuComponent(handleFilter, onClose)}</Popup>
              </RelativePortal>
            ) : (
              <Popup data-testid="qa-skeletal-drop-menu-popup">{menuComponent(handleFilter, onClose)}</Popup>
            )}
          </PortalPlaceholder>
        )}
      </Container>
    </ExternalActivityListener>
  );
}

export default SkeletalDropMenu;

const Container = styled.div`
  position: relative;
`;

const Popup = styled.div`
  border-radius: 4px;
  border: solid 1px ${GetColor.Grey};
  box-shadow: 0 0 20px ${GetColor.Grey};
  background-color: ${GetColor.White};
`;

const PortalPlaceholder = styled.div<{ left?: number | string; right?: number; transform?: string }>`
  position: absolute;
  z-index: ${ZIndex.Front};
  top: 45px;
  right: ${(props) => (props.right !== undefined ? props.right : 'unset')};
  left: ${(props) => (props.left !== undefined ? props.left : 'unset')};
  ${(props) => props.transform && `transform: ${props.transform};`}
`;
