import styled from 'styled-components';
import { Copyright } from 'venn-components';
import { CheckboxWrapper, GetColor, Headline1, Loading, Subtitle1 } from 'venn-ui-kit';
import type { AuthenticationError, SignupUser, AccountCreationRequest, AuthenticationStatus } from 'venn-api';
import type { History } from 'history';

export const AuthButtonGroup = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 15px 0;

  > a {
    outline: none;
  }

  > a,
  > button {
    flex: 1 1 50%;
    display: block;

    &:first-child {
      margin-right: 15px;
    }
  }
`;

export const AuthCopyright = styled(Copyright)`
  margin-top: 6px;
  text-align: center;
  color: ${GetColor.White};
`;

export const AuthMessage = styled.div`
  width: 100%;
  margin: 0 0 15px;
  font-weight: normal;
  line-height: 25px;
  text-align: left;
  color: ${GetColor.Grey};
`;

export const AuthLoader = styled(Loading)`
  margin: 15px auto;
`;

export const AuthTitle = styled.h1`
  font-size: 46px;
  font-weight: 300;
  color: ${GetColor.White};
  text-align: center;
`;

export const AuthParagraph = styled.p`
  font-size: 20px;
  font-weight: bold;
  color: ${GetColor.White};
  text-align: center;
  max-width: 700px;
  line-height: 1.5;
`;

export const AuthWrapperWithFooter = styled.div`
  height: 100%;
`;

export const ContentWrapper = styled.div<{ isLoading?: boolean; noFooter?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  // need 100% so that loading spinner is centered in IE, but need auto for long forms
  height: ${({ isLoading }) => (isLoading ? '100%' : 'auto')};
  ${({ noFooter }) =>
    !noFooter
      ? `
    margin-bottom: -360px;
    &:after {
      content: '';
      display: block;
      height: 360px;
    }
  `
      : undefined}
`;

export const AuthInputRow = styled.div`
  display: inline-flex;
  width: 100%;
  position: relative;
`;

export const StyledCheckboxWrapper = styled(CheckboxWrapper)`
  padding: 10px 0;
  justify-content: flex-start;

  input[type='checkbox'] {
    width: 13px;
    height: 13px;
    min-width: 13px;
  }

  input[type='checkbox']:checked::before {
    top: 0px;
    left: 0px;
    color: ${GetColor.Black};
  }

  span {
    color: ${GetColor.White};
    font-size: 10px;
    line-height: 15px;
  }
`;

export const StyledHeadline1 = styled(Headline1)`
  max-width: 480px;
  text-align: center;
  margin-bottom: 20px;
  color: ${GetColor.White};
`;

export const Info = styled.div`
  max-width: 478px;
  text-align: center;

  ${Subtitle1} {
    color: ${GetColor.Grey};
  }
`;

export const AuthSubParagraph = styled(AuthParagraph)`
  font-size: 14px;
  max-width: none;
  font-weight: normal;
`;

export const Anchor = styled.a`
  color: ${GetColor.Primary.Dark};
  font-size: 14px;
  font-weight: bold;

  &:hover {
    color: ${GetColor.White};
  }
`;

export interface AuthPageProps {
  error?: AuthenticationError;
  pending?: boolean;
  onSubmit: (user: Partial<SignupUser> | Partial<AccountCreationRequest>) => Promise<AuthenticationStatus> | void;
  onSuccess?: () => void;
  history: History;
}
