import React, { useContext } from 'react';
import styled from 'styled-components';
import { useHasFF } from 'venn-utils';
import { ForecastPanelActionsContext } from '../contexts/ForecastPanelActionsContext';
import { LeftForecastPanel } from './LeftForecastPanel';
import { RightForecastPanel } from './RightForecastPanel';

export interface ForecastPanelContentProps {
  onResidualForecastUpdated?: (fundId?: string) => void;
  residualForecastsSortFirst?: string[];
  isReadOnly: boolean;
}

const ForecastPanelContent = ({
  onResidualForecastUpdated,
  residualForecastsSortFirst,
  isReadOnly,
}: ForecastPanelContentProps) => {
  const { onSetForecastAsDefault, onDeleteForecast } = useContext(ForecastPanelActionsContext);
  const hasRedesignFF = useHasFF('forecast_panel_redesign_ff');

  return (
    <ForecastPanelWrapper>
      <LeftPanelWrapper hasRedesignFF={hasRedesignFF}>
        <LeftForecastPanel
          investmentForecastsSortFirst={residualForecastsSortFirst}
          onInvestmentForecastUpdated={onResidualForecastUpdated}
          onSetFactorForecastAsDefault={onSetForecastAsDefault}
          isReadOnly={isReadOnly}
          onDelete={onDeleteForecast}
        />
      </LeftPanelWrapper>
      <RightPanelWrapper hasRedesignFF={hasRedesignFF}>
        <RightForecastPanel />
      </RightPanelWrapper>
    </ForecastPanelWrapper>
  );
};

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default (props: ForecastPanelContentProps) => <ForecastPanelContent {...props} />;

const ForecastPanelWrapper = styled.div`
  display: flex;
  padding-top: 10px;
`;

const RightPanelWrapper = styled.div<{ hasRedesignFF: boolean }>`
  flex: 1;
  margin-top: ${({ hasRedesignFF }) => (hasRedesignFF ? 102 : 60)}px;
`;

const LeftPanelWrapper = styled.div<{ hasRedesignFF: boolean }>`
  width: ${({ hasRedesignFF }) => (hasRedesignFF ? 480 : 440)}px;
`;
