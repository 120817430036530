import type { Analysis } from 'venn-api';

const analysis = {
  analysisType: 'FACTOR_CONTRIBUTION_TO_RETURN_TREND',
  factorContributionToReturnTrend: [
    [
      {
        regressionStart: 1488326399999,
        regressionEnd: 1583020799999,
        regressionPeriods: 36,
        residualReturn: -0.03436690176386395,
        periodResidualReturn: -0.03436690176386395,
        periodAnnualized: true,
        regressionPeriod: 3,
        regressionYears: 3,
        residualStdError: 0.0034789288501912573,
        residualTStat: -2.8373713306950696,
        residualRisk: 0.09865912728070814,
        residualVariance: 0.0003503320027951416,
        riskFreeReturn: 0.017343144823153578,
        periodTotalReturn: 0.014467123351903588,
        factors: [
          {
            id: 10000,
            name: 'Equity',
            contribution: 0.011915315355797202,
            periodAnnualized: true,
            significant: true,
            tstat: 5.6508158981718495,
          },
          {
            id: 10002,
            name: 'Credit',
            contribution: 0.0037361043945890572,
            periodAnnualized: true,
            significant: false,
            tstat: 1.6352286597657897,
          },
        ],
        groupedFactors: [
          {
            id: -1,
            name: 'Secondary Macro',
            contribution: 0,
            periodAnnualized: true,
            significant: true,
            tstat: null,
          },
          {
            id: -1,
            name: 'Macro Styles',
            contribution: 0,
            periodAnnualized: true,
            significant: true,
            tstat: null,
          },
          {
            id: -1,
            name: 'Residual',
            contribution: null,
            periodAnnualized: true,
            significant: true,
            tstat: null,
          },
          {
            id: -1,
            name: 'Core Macro',
            contribution: 0,
            periodAnnualized: true,
            significant: true,
            tstat: null,
          },
        ],
        residualSignificant: true,
      },
    ],
    [
      {
        regressionStart: 1488326399999,
        regressionEnd: 1583020799999,
        regressionPeriods: 36,
        residualReturn: -0.007858877007003351,
        periodResidualReturn: -0.007858877007003351,
        periodAnnualized: true,
        regressionPeriod: 3,
        regressionYears: 3,
        residualStdError: 0.003974610094944704,
        residualTStat: -0.55590718064392,
        residualRisk: 0.2607865887731209,
        residualVariance: 0.0004983022232448269,
        riskFreeReturn: 0.017343144823153578,
        periodTotalReturn: 0.02391135613636375,
        factors: [
          {
            id: 10000,
            name: 'Equity',
            contribution: 0.010341065983214928,
            periodAnnualized: true,
            significant: true,
            tstat: 4.294542407620226,
          },
        ],
        groupedFactors: [
          {
            id: -1,
            name: 'Secondary Macro',
            contribution: 0,
            periodAnnualized: true,
            significant: true,
            tstat: null,
          },
          {
            id: -1,
            name: 'Residual',
            contribution: null,
            periodAnnualized: true,
            significant: true,
            tstat: null,
          },
          {
            id: -1,
            name: 'Core Macro',
            contribution: 0,
            periodAnnualized: true,
            significant: true,
            tstat: null,
          },
        ],
        residualSignificant: false,
      },
    ],
  ],
};

export default analysis as unknown as Analysis;
