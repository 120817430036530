import React, { useEffect, useRef, useState } from 'react';
import styled, { withTheme } from 'styled-components';
import { Label } from '../../typography';
import type { DropMenuItem } from '../../drop-menu/types';
import Tooltip from '../../tooltip/Tooltip';
import { monthItems, Actions, Button, Dropdown, Form, Row } from '../granularities/MonthPicker';
import moment from 'moment';
import type { Theme } from '../../../style/themes';

interface MonthYearFilterProps {
  month?: number;
  year?: number;
  onFilter: (month?: number, year?: number) => void;
  className?: string;
  theme: Theme;
}

const fillNumbers = (start: number, end: number): number[] =>
  Array.from({ length: end - start + 1 }, (_, i) => end - i);

const ALL_VALUE = -1;

const items: DropMenuItem<number>[] = [{ label: 'All', value: ALL_VALUE }, ...monthItems];

const yearSelectorItems: DropMenuItem<number>[] = [
  { label: 'All', value: ALL_VALUE },
  ...fillNumbers(1900, moment.utc().year()).map((year: number) => ({
    label: year.toString(),
    value: year,
  })),
];

const DateFilterMenu = ({ month, year, onFilter, className, theme }: MonthYearFilterProps) => {
  const monthInputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    monthInputRef.current?.focus();
  }, []);
  const yearInputRef = useRef<HTMLInputElement>(null);

  const [unsavedMonth, setMonth] = useState(month ?? ALL_VALUE);
  const [unsavedYear, setYear] = useState(year ?? ALL_VALUE);

  const disabled = unsavedYear === ALL_VALUE && unsavedMonth !== ALL_VALUE;

  return (
    <Container className={className}>
      <Form>
        <Row>
          <Label htmlFor="date" style={{ color: theme.Colors.MidGrey2 }}>
            Date:
          </Label>
          &nbsp;
          <Dropdown
            items={items}
            selected={unsavedMonth}
            className="qa-date-filter-month"
            width={77}
            innerRef={monthInputRef}
            // @ts-expect-error: TODO fix strictFunctionTypes
            onChange={({ value }: DropMenuItem<number>) => {
              setMonth(value);
              yearInputRef.current?.focus();
            }}
          />
          <Dropdown
            items={yearSelectorItems}
            selected={unsavedYear}
            innerRef={yearInputRef}
            width={112}
            placeholder="All"
            className="qa-date-filter-year"
            // @ts-expect-error: TODO fix strictFunctionTypes
            onChange={({ value }: DropMenuItem<number>) => setYear(value)}
          />
        </Row>
        <Actions>
          <Button
            type="submit"
            dense
            onClick={() =>
              onFilter(
                unsavedMonth === ALL_VALUE ? undefined : unsavedMonth,
                unsavedYear === ALL_VALUE ? undefined : unsavedYear,
              )
            }
            disabled={disabled}
          >
            <Tooltip content={disabled ? 'Select a year or allow all months' : undefined} isHidden={!disabled}>
              Filter
            </Tooltip>
          </Button>
        </Actions>
      </Form>
    </Container>
  );
};

export default withTheme(DateFilterMenu);

const Container = styled.div`
  width: 278px;
`;
