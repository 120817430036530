import React from 'react';
import styled from 'styled-components';

import { Hint, ItemType, Link as LinkStyle } from 'venn-ui-kit';
import { useHistory } from 'react-router-dom';
import { analyticsService, Routes } from 'venn-utils';

const GoToSavedViewsAction = () => {
  const history = useHistory();
  const navigate = () => {
    history.push(`${Routes.REPORTS}?itemType=${ItemType.StudioReport}&itemType=${ItemType.ReportTemplate}`);
    analyticsService.ctaClicked({
      destination: 'reporting page',
      locationOnPage: 'Wealth onboarding tutorial',
      purpose: 'see organization reports',
      type: 'button',
    });
  };
  return (
    <Hint>
      Or go to{' '}
      <LinkStyle>
        <StyledButton onClick={navigate} data-testid="qa-goto-saved-views">
          Saved Views
        </StyledButton>{' '}
      </LinkStyle>{' '}
      to see your organization's reports.
    </Hint>
  );
};
const StyledButton = styled.button`
  font-weight: bold;
`;

export default GoToSavedViewsAction;
