import type { CSSProperties, ReactNode } from 'react';
import React from 'react';
import styled, { css } from 'styled-components';
import type { DropMenuItem } from 'venn-ui-kit';
import { BaseDropMenu, GetColor, Icon, Tooltip } from 'venn-ui-kit';
import type { Fund } from 'venn-api';
import { isNil } from 'lodash';

interface NewOpportunitiesDropdownProps {
  items: DropMenuItem<Fund>[];
  children: (open: boolean, onToggle: (open?: boolean) => void) => ReactNode;
  onRemoveItem: (removedItem: Fund) => void;
  onRemoveAllItems: () => void;
  style?: CSSProperties;
}

const NewOpportunitiesDropdown = ({
  items,
  children,
  onRemoveItem,
  onRemoveAllItems,
  style,
}: NewOpportunitiesDropdownProps) => {
  return (
    <BaseDropMenu
      usePortal
      style={style}
      filteredItems={items}
      triggerComponent={(expanded, __, onToggle) => <>{children(expanded, onToggle)}</>}
      menuComponent={(_, onMenuCollapse, menuClassName) => (
        <MenuContainer tabIndex={-1} className={menuClassName}>
          <MenuItems>
            {items.map((item) => (
              <Item key={item.value.id}>
                <FundName excluded={!isNil(item.description)}>
                  {item.label}
                  {item.description ? (
                    <>
                      {' '}
                      (excluded{' '}
                      <Tooltip content={item.description}>
                        <Icon type="info-circle" prefix="far" />
                      </Tooltip>
                      )
                    </>
                  ) : null}
                </FundName>
                <TrashIcon type="button" onClick={() => onRemoveItem(item.value)}>
                  <Icon type="trash" prefix="far" />
                </TrashIcon>
              </Item>
            ))}
          </MenuItems>
          <MenuFooter>
            <RemoveAllButton role="button" onClick={onRemoveAllItems} className="qa-remove-all-btn">
              <Icon type="trash" /> Remove All Opportunities
            </RemoveAllButton>
          </MenuFooter>
        </MenuContainer>
      )}
    />
  );
};

export default NewOpportunitiesDropdown;

const MenuContainer = styled.div`
  background-color: ${GetColor.White};
  min-width: 235px;
  border: 1px solid ${GetColor.Grey};
  border-radius: 4px;
`;

const MenuItems = styled.div`
  padding: 10px 0;
`;

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  padding: 0 20px;
  &:hover {
    background-color: ${GetColor.WhiteGrey};
  }
`;

const FundName = styled.div<{ excluded?: boolean }>`
  color: ${GetColor.HintGrey};
  ${({ excluded }) =>
    excluded &&
    css`
      font-style: italic;
      color: ${GetColor.Error};
    `};
`;

const TrashIcon = styled.button`
  font-size: 12px;
  margin-left: 4px;
  color: ${GetColor.Grey};
  &:hover {
    color: ${GetColor.Primary.Dark};
  }
`;

const MenuFooter = styled.div`
  background-color: ${GetColor.WhiteGrey};
  border-top: 1px solid ${GetColor.Grey};
  border-radius: 0 0 4px 4px;
  min-height: 30px;
  padding: 0 20px;
  display: flex;
  align-items: center;
`;

const RemoveAllButton = styled.button`
  color: ${GetColor.Error};
  &:hover {
    color: ${GetColor.Primary.Main};
    i {
      color: ${GetColor.Primary.Main};
    }
  }
  font-weight: bold;
  i {
    margin-right: 4px;
    font-weight: normal;
  }
  line-height: 30px;
`;
