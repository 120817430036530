/*
 * Venn Platform API 1.0.0 Operations for "fundtags-v2"
 * Automatically generated file - do not modify!
 */

import type { TaggedEntities } from '../types';
import type { OperationResult } from '../../utils';
import { buildRequestInit, extractError, retryGeneratedFetch } from '../../utils';

/** Add a tag to the given set of funds and portfolios */
export async function addTagV2Old(
  tag: string,
  body?: Partial<TaggedEntities>,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = `/api/fundtags/v2/${encodeURIComponent(tag)}`;
  const requestInit = buildRequestInit({
    method: 'PUT',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await retryGeneratedFetch('PUT', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Remove a tag from the given set of funds and portfolios */
export async function removeTagV2Old(
  tag: string,
  body?: Partial<TaggedEntities>,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = `/api/fundtags/v2/${encodeURIComponent(tag)}/delete`;
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
