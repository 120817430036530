import React from 'react';
import styled from 'styled-components';
import { browserTest, isMobile } from 'venn-utils';
import { BannerMessage } from '../banner-message/BannerMessage';
import { getAppTitle } from 'venn-ui-kit';

const isBrowserSupported = browserTest();

const BrowserWarning = () => {
  if (isBrowserSupported) {
    return null;
  }
  const title = !isMobile() ? `${getAppTitle()} works best in the latest version of Google Chrome.` : '';
  const subtitle = !isMobile()
    ? 'Views in any other browser may cause unexpected glitches.'
    : `${getAppTitle()} works best on Chrome for desktop.`;
  return (
    <StyledBannerMessage errorType="warning" title={title}>
      {subtitle}
    </StyledBannerMessage>
  );
};
export default BrowserWarning;
const StyledBannerMessage = styled(BannerMessage)`
  padding-left: 60px;
`;
