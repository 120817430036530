import type { FC } from 'react';
import React, { useCallback, useState } from 'react';
import type { TimeFrame } from 'venn-utils';
import type { FactorTrendRollingPeriods } from 'venn-components';
import { AnalysisConfigContext } from 'venn-components';

interface AnalysisConfigStoreProps {
  /** Actual analysis timeFrame from backend */
  actualTimeFrame: TimeFrame;
}

const AnalysisConfigStore: FC<React.PropsWithChildren<AnalysisConfigStoreProps>> = ({ actualTimeFrame, children }) => {
  const [unsavedPortfolioChanges, setUnsavedChanges] = useState(false);
  const [actualFactorTrendRollingPeriods, setActualFactorTrendRollingPeriods] = useState<FactorTrendRollingPeriods>({});
  const [venncastOn, setVenncastOn] = useState<boolean>(false);
  const setUnsavedPortfolioChanges = useCallback(
    (hasUnsavedChanges: boolean) => {
      setUnsavedChanges((prevValue) => {
        if (unsavedPortfolioChanges === hasUnsavedChanges) {
          return prevValue;
        }
        return hasUnsavedChanges;
      });
    },
    [unsavedPortfolioChanges],
  );

  const updateFactorTrendRollingResults = useCallback((factorRolling: Partial<FactorTrendRollingPeriods>) => {
    setActualFactorTrendRollingPeriods((prev) => ({ ...prev, ...factorRolling }));
  }, []);

  return (
    <AnalysisConfigContext.Provider
      value={{
        actualTimeFrame,
        unsavedPortfolioChanges,
        setUnsavedPortfolioChanges,
        actualFactorTrendRollingPeriods,
        updateFactorTrendRollingResults,
        venncastOn,
        setVenncastOn,
      }}
    >
      {children}
    </AnalysisConfigContext.Provider>
  );
};

export default AnalysisConfigStore;
