import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { withSuspense } from 'venn-utils';
import type { CustomBlockProps } from './types';
import {
  blockInfoGraphicType,
  blockSettings,
  customizedBlock,
  PRINT_CHART_HEIGHT,
  useScopedSetRecoilState,
  blockExcelFunction,
} from 'venn-state';
import { useHoldings } from './logic/useHoldings';
import HoldingsPieChart from './components/charts/HoldingsPieChart';
import HoldingsDataGrid from './components/grid/HoldingsDataGrid';
import MapChart from './components/charts/map-chart/MapChart';
import HoldingsTreeGrid from './components/grid/HoldingsTreeGrid';
import { HoldingsBarChart } from './components/charts/HoldingsBarChart';
import { StyledErrorState } from './components/StyledErrorState';
import type { CustomBlockTypeEnum } from 'venn-utils';
import { InfoGraphicTypeErrorWrapper } from './components/error-wrappers/InfoGraphicTypeErrorWrapper';
import { ErrorMultiWrapper } from './components/error-wrappers/ErrorMultiWrapper';
import { SubjectsErrorWrapper } from './components/error-wrappers/SubjectsErrorWrapper';
import { SectorExposureErrorWrapper } from './components/error-wrappers/SectorExposureErrorWrapper';
import { AssetExposureErrorWrapper } from './components/error-wrappers/AssetExposureErrorWrapper';
import { BlockSuspenseFallback } from './components/BlockSuspenseFallback';
import { UnexpectedErrorWrapper } from './components/error-wrappers/UnexpectedErrorWrapper';
import { useAppPrintMode } from '../print/AppPrintMode';

const CustomHoldingsBlock = ({ isReport, selectedRefId, downloadableContentRef }: CustomBlockProps) => {
  const infoGraphicType = useRecoilValue(blockInfoGraphicType(selectedRefId));
  const selectedBlock = useRecoilValue(customizedBlock(selectedRefId));
  const { inPrintMode } = useAppPrintMode();
  const { data } = useHoldings(selectedRefId);
  const height = inPrintMode && !isReport ? PRINT_CHART_HEIGHT : undefined;

  const blockSetting = useRecoilValue(blockSettings(selectedRefId));
  const type = blockSetting.customBlockType;
  const treeDataBlocks: CustomBlockTypeEnum[] = ['SECTOR_EXPOSURE', 'GEOGRAPHY_EXPOSURE'];
  const isTreeDataBlock = treeDataBlocks.includes(type);

  const setExportFunction = useScopedSetRecoilState(blockExcelFunction(selectedRefId));

  useEffect(() => {
    setExportFunction({ excelFunction: undefined });
  }, [setExportFunction]);

  if (!data || data.error) {
    return (
      <StyledErrorState
        selectedRefId={selectedRefId}
        header="Failed to fetch holdings data"
        message={data?.error?.message ?? 'Please refresh the page.'}
      />
    );
  }
  if (infoGraphicType === 'PIE') {
    return (
      <HoldingsPieChart
        height={height}
        selectedBlock={selectedBlock}
        selectedRefId={selectedRefId}
        downloadableContentRef={downloadableContentRef}
        inPrintMode={inPrintMode}
      />
    );
  }
  if (infoGraphicType === 'MAP') {
    return (
      <MapChart
        height={height}
        holdingsData={data}
        selectedRefId={selectedRefId}
        selectedBlock={selectedBlock}
        downloadableContentRef={downloadableContentRef}
      />
    );
  }

  if (isTreeDataBlock && infoGraphicType === 'GRID') {
    return <HoldingsTreeGrid selectedRefId={selectedRefId} isBarChart={false} inPrintMode={isReport} />;
  }

  if (!isTreeDataBlock && infoGraphicType === 'GRID') {
    return <HoldingsDataGrid selectedRefId={selectedRefId} isBarChart={false} inPrintMode={isReport} />;
  }
  if (infoGraphicType === 'BAR') {
    return <HoldingsBarChart selectedRefId={selectedRefId} />;
  }

  throw new Error('unhandled block type for holdings block');
};

/**
 * Wraps {@link CustomHoldingsBlock} to check for error or empty states before analysis begins.
 */
const PreHoldingsWrapper = (props: CustomBlockProps) => {
  return (
    <ErrorMultiWrapper
      blockId={props.selectedRefId}
      wrappers={[
        UnexpectedErrorWrapper,
        InfoGraphicTypeErrorWrapper,
        AssetExposureErrorWrapper,
        SectorExposureErrorWrapper,
        SubjectsErrorWrapper,
      ]}
    >
      <CustomHoldingsBlock {...props} />
    </ErrorMultiWrapper>
  );
};

export default React.memo(withSuspense(<BlockSuspenseFallback />, PreHoldingsWrapper));
