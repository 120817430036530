import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import { GetColor } from '../../../style/color';
import { Hint } from '../../typography';

export interface CheckboxMenuHeaderProps {
  text?: string;
  height?: number;
}

const CheckboxMenuHeader: FC<React.PropsWithChildren<CheckboxMenuHeaderProps>> = ({ text, height, children }) => (
  <Header className="qa-dropmenu-checkbox-header" height={height}>
    {text && <Hint>{text}</Hint>}
    {children}
  </Header>
);

const Header = styled.div<{ height?: number }>`
  min-height: ${({ height }) => height ?? 45}px;
  justify-content: center;
  display: flex;
  flex: 1;
  align-items: center;
  border-bottom: 1px solid ${GetColor.PaleGrey};
  padding: 0 8px 8px 8px;
`;

export default CheckboxMenuHeader;
