import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import { Headline1, Headline3 } from 'venn-ui-kit';

interface PageHeaderProps {
  title: string;
  subtitle?: string;
}

export const PageHeader: FC<React.PropsWithChildren<PageHeaderProps>> = ({ title, subtitle, children }) => (
  <Container>
    <TitleContainer>
      <Headline1>{title}</Headline1>
      <Components>{children}</Components>
    </TitleContainer>
    {subtitle && (
      <SubtitleContainer>
        <Headline3>{subtitle}</Headline3>
      </SubtitleContainer>
    )}
  </Container>
);

const Container = styled.div`
  padding: 0 0 20px 0;
  min-height: 140px;
  width: 100%;
`;

const TitleContainer = styled.div`
  height: 55px;
  display: flex;
  align-items: flex-end;
  ${Headline1} {
    flex: 1;
    margin: 0;
  }
`;

const SubtitleContainer = styled.div`
  height: 24px;
  margin-top: 20px;
  ${Headline3} {
    margin: 0;
  }
`;

const Components = styled.div``;

export default PageHeader;
