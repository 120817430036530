import noop from 'lodash/noop';
import React, { useCallback, useContext } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { createPortfolioV3 } from 'venn-api';
import { AddToPortfolioModal, PortfoliosContext } from 'venn-components';
import { Notifications, NotificationType, TooltipPosition } from 'venn-ui-kit';
import { createPortfolioFromFunds, Routes, useModal } from 'venn-utils';
import type { SearchResultWithUIState } from '../types';
import { convertToFund } from '../utils';
import { StyledButtonIcon } from './styles';
import type { BulkActionProps } from './types';

interface AddToPortfolioProps extends BulkActionProps, RouteComponentProps {}

const AddToPortfolio = ({ items, history, onComplete }: AddToPortfolioProps) => {
  const [isOpen, open, close] = useModal();
  const { demoPortfolio, masterPortfolio, refresh } = useContext(PortfoliosContext);

  const createPortfolioFlow = !masterPortfolio;
  const portfolioButtonText = createPortfolioFlow ? 'Create Portfolio' : 'Add to Portfolio';
  const investments = getFunds(items).map(convertToFund);

  const handlePortfolioIconClick = useCallback(async () => {
    if (createPortfolioFlow) {
      try {
        const newPortfolioDraft = (await createPortfolioV3(createPortfolioFromFunds(investments))).content;
        onComplete('Create Portfolio', noop);
        history.push(`${Routes.CREATE_PORTFOLIO}?id=${newPortfolioDraft.id}`);
      } catch (e) {
        Notifications.notify('Portfolio creation failed. Try again later.', NotificationType.ERROR);
      }
    } else {
      open();
    }
  }, [createPortfolioFlow, history, investments, onComplete, open]);

  // if the action can't be performed on any of the selected items, don't show the option
  if (!investments.length) {
    return null;
  }

  const disabled = investments.length < items.length;
  const tooltip = disabled ? 'Only investments can be added to a portfolio.' : undefined;

  return (
    <>
      <StyledButtonIcon
        tooltip={tooltip}
        tooltipPosition={TooltipPosition.Bottom}
        onClick={handlePortfolioIconClick}
        iconType="th"
        disabled={disabled}
        text={`${portfolioButtonText} (${items.length})`}
        className="qa-bulk-add-to-portfolio"
      />

      {isOpen && (
        <AddToPortfolioModal
          investments={investments}
          onCancel={close}
          initialSelectedPortfolio={masterPortfolio ?? demoPortfolio}
          refreshPortfolios={refresh}
          onComplete={() => onComplete('Add to Portfolio', close)}
        />
      )}
    </>
  );
};

export default withRouter(AddToPortfolio);

const getFunds = (items: SearchResultWithUIState[]) => items.filter((item) => item.fundId);
