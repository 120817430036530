import React, { useCallback, useEffect, useState } from 'react';
import type { Location, Action } from 'history';
import { Prompt, useHistory } from 'react-router-dom';
import { UNBLOCKABLE_ROUTES } from 'venn-utils';

interface UnsavedChangesTriggerState {
  isOpen: boolean;
  handleConfirmNavigation: () => void;
  setIsNavigationConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleReject: () => void;
}

interface UnsavedChangesTriggerProps {
  isNotSaved: boolean;
  children: (state: UnsavedChangesTriggerState) => React.ReactNode;
  ignoreSearchParam?: boolean;
}

const UnsavedChangesTrigger = React.memo(function UnsavedChangesTrigger({
  isNotSaved,
  children,
  ignoreSearchParam,
}: UnsavedChangesTriggerProps) {
  const { location, push, replace } = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location>();
  const [lastAction, setLastAction] = useState<Action>();
  const [isNavigationConfirmed, setIsNavigationConfirmed] = useState(false);

  const handleBlockedNavigation = (nextLocation: Location<unknown>, action: Action): boolean => {
    const { search: currentSearch, pathname: currentPathname } = location;
    const { search: nextSearch, pathname: nextPathname } = nextLocation;

    if (UNBLOCKABLE_ROUTES.some((route) => nextPathname.startsWith(route))) {
      return true;
    }

    const samePathname = currentPathname === nextPathname;
    const sameSearch = currentSearch === nextSearch;

    const isSameUrl = ignoreSearchParam ? samePathname : samePathname && sameSearch;

    if (!isNavigationConfirmed && !isSameUrl) {
      setIsOpen(true);
      setLastLocation(nextLocation);
      setLastAction(action);
      return false;
    }
    return true;
  };

  const handleConfirmNavigation = useCallback(() => {
    setIsNavigationConfirmed(true);
    setIsOpen(false);
  }, []);

  const handleReject = useCallback(() => {
    setLastLocation(undefined);
    setLastAction(undefined);
    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (isNavigationConfirmed && lastLocation && lastAction) {
      const update = lastAction === 'PUSH' ? push : replace;
      update(lastLocation);
    } else if (isNavigationConfirmed && !lastLocation) {
      setIsNavigationConfirmed(false);
    }
  }, [isNavigationConfirmed, lastLocation, lastAction, push, replace]);

  return (
    <>
      {/* @ts-expect-error TODO: fix react-router-types */}
      <Prompt when={isNotSaved} message={handleBlockedNavigation} />
      {children({ isOpen, handleConfirmNavigation, setIsNavigationConfirmed, setIsOpen, handleReject })}
    </>
  );
});

export default UnsavedChangesTrigger;
