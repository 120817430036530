import type { ColDef, ColGroupDef } from 'ag-grid-community';
import { compact, flatten, isNil, max, min, zip } from 'lodash';
import moment from 'moment';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { useTheme } from 'styled-components';
import type { CashFlowResponse, CashFlowSeries, PrivatesAnalysisResponse } from 'venn-api';
import {
  blockCustomizableMetrics,
  blockMetrics,
  blockPrivateAssetIsCumulative,
  blockRequestSubjects,
  type StudioRequestSubject,
} from 'venn-state';
import { getItemColor } from 'venn-ui-kit';
import { assertNotNil, Dates } from 'venn-utils';
import { convertRequestSubjectToItemType } from '../../analysis';
import { formatData } from '../../data-grid';
import { useMeasureGridText } from '../../utils/grids';
import { useBlockId } from '../contexts/BlockIdContext';
import {
  CURRENCY_CLASS_PREFIX,
  JUSTIFY_HEADER_END_CLASS,
  LEFT_ALIGN_CLASS,
  METRIC_NAME_HEADER_RENDERER,
  RIGHT_ALIGN_CLASS,
  SUBJECT_HEADER_RENDERER,
} from '../customAnalysisContants';
import { getAnalysisMetricLevelErrorsAndWarnings, getMetricDisplayName } from './privatesUtils';

interface RowData {
  isMetadata: boolean;
  metadataTitle?: string;
  quarter?: string;
  subjectToProjectionStartDateMap?: Map<string, string | undefined>;
  subjectToDataMap?: Map<
    string,
    {
      type: string;
      amount: number;
    }
  >;
}

function generateQuarters(from: number, to: number) {
  const fromDate = moment.utc(from);
  const toDate = moment.utc(to);

  const quarters = [];

  for (let current = fromDate.clone(); current.isSameOrBefore(toDate); current.add(1, 'quarter')) {
    quarters.push(Dates.toQuarterYear(current));
  }

  return quarters;
}

function reformatCashFlowData(series: CashFlowSeries | undefined, metric: string, type: string) {
  return ((series?.[metric] ?? []) as number[][]).map(([timestamp, amount]) => ({
    type,
    timestamp,
    amount,
  }));
}

function getSubjectToProjectionStartDateMap(subjects: StudioRequestSubject[], cashFlows: CashFlowResponse[]) {
  const keyValueTuples: ([string, string | undefined] | null)[] = zip(subjects, cashFlows).map(
    ([subject, cashFlow]) => {
      if (isNil(subject) || isNil(cashFlow)) {
        return null;
      }
      return [
        subject.id,
        !isNil(cashFlow.projectionAsOfDate) ? Dates.toQuarterYear(cashFlow.projectionAsOfDate) : undefined,
      ];
    },
  );

  return new Map(compact(keyValueTuples));
}

function usePrivatesDataGridColumnDefs(data: PrivatesAnalysisResponse) {
  const blockId = useBlockId();
  const requestSubjects = useRecoilValue(blockRequestSubjects(blockId));
  const theme = useTheme();
  const selectedMetrics = useRecoilValue(blockMetrics(blockId));
  const metrics = useRecoilValue(blockCustomizableMetrics(blockId)).filter((x) => selectedMetrics.includes(x.key));
  const metric = metrics?.[0]?.analysisResultKey;
  const measureText = useMeasureGridText();

  const metricNames = metrics.map((metric) => metric.analysisResultKey);

  return useMemo(() => {
    if (requestSubjects.length === 0) {
      return [];
    }

    return [
      // This trick with wrapping date column is needed to make sure columns are aligned properly in Excel export
      {
        headerName: '',
        marryChildren: true,
        children: [
          {
            headerName: 'Date',
            valueGetter: ({ data }) => data?.quarter ?? data?.metadataTitle ?? '--',
            cellClass: () => ['TEXT', RIGHT_ALIGN_CLASS],
            minWidth: measureText('Projection start', 'normal'),
            maxWidth: measureText('Projection start', 'normal'),
          },
        ],
      },
      ...requestSubjects.map((subject, subjectIndex) => {
        const errors = data?.cashFlows
          ? getAnalysisMetricLevelErrorsAndWarnings(data?.cashFlows, metricNames, requestSubjects, subjectIndex)
          : [];

        return {
          marryChildren: true,
          headerName: subject.name,
          cellClass: () => [RIGHT_ALIGN_CLASS],
          headerGroupComponent: SUBJECT_HEADER_RENDERER,
          headerGroupComponentParams: {
            color: getItemColor(theme.Colors, convertRequestSubjectToItemType(subject)),
            subject,
            isCommonBenchmark: false,
            displayName: subject.name,
            noLink: true,
          },
          children: [
            {
              headerName: 'Type',
              headerClass: () => [JUSTIFY_HEADER_END_CLASS],
              valueGetter: ({ data }) => {
                if (data?.isMetadata) {
                  return data?.subjectToProjectionStartDateMap?.get(subject.id) ?? '--';
                }
                return data?.subjectToDataMap?.get(subject.id)?.type ?? '--';
              },
              cellClass: () => ['TEXT', LEFT_ALIGN_CLASS],
              colSpan: ({ data }) => (data?.isMetadata ? 2 : 1),
            },
            {
              headerComponent: METRIC_NAME_HEADER_RENDERER,
              headerComponentParams: {
                displayName: assertNotNil(getMetricDisplayName(metric)),
                errors: errors.map((error) => error.error.message),
              },
              headerName: getMetricDisplayName(metric),
              valueGetter: ({ data }) => (errors.length ? undefined : data?.subjectToDataMap?.get(subject.id)?.amount),
              valueFormatter: ({ value }) => (isNil(value) ? '--' : `$${formatData(value, 'NUMERIC', 2)}`), // Cell class for excel export style
              cellClass: () => ['NUMERIC', RIGHT_ALIGN_CLASS, `${CURRENCY_CLASS_PREFIX}$`],
            },
          ],
        } as ColGroupDef<RowData>;
      }),
    ];
  }, [data?.cashFlows, measureText, metric, metricNames, requestSubjects, theme.Colors]) as (
    | ColDef<RowData>
    | ColGroupDef<RowData>
  )[];
}

function usePrivatesDataGridRowData(data: PrivatesAnalysisResponse): RowData[] {
  const blockId = useBlockId();
  const selectedMetrics = useRecoilValue(blockMetrics(blockId));
  const metrics = useRecoilValue(blockCustomizableMetrics(blockId)).filter((x) => selectedMetrics.includes(x.key));
  const metric = metrics?.[0]?.analysisResultKey ?? '';
  const isCumulative = useRecoilValue(blockPrivateAssetIsCumulative(blockId));
  const subjects = useRecoilValue(blockRequestSubjects(blockId));

  const includeHistorical = metrics.some((x) => x.metricPath === 'actual');
  const includeProjected = metrics.some((x) => x.metricPath === 'projected');

  const timeseries = data.cashFlows.map((cashFlow) => [
    ...(includeHistorical
      ? reformatCashFlowData(isCumulative ? cashFlow.actualCumulative : cashFlow.actual, metric, 'Historical')
      : []),
    ...(includeProjected
      ? reformatCashFlowData(isCumulative ? cashFlow.projectedCumulative : cashFlow.projected, metric, 'Projected')
      : []),
  ]);

  const timestamps = flatten(timeseries.map((ts) => ts.map((data) => data.timestamp)));

  if (timestamps.length === 0) {
    return [];
  }

  const quarters = generateQuarters(assertNotNil(min(timestamps)), assertNotNil(max(timestamps)));

  const quarterToDataMap = new Map(quarters.map((quarter) => [quarter, new Map() as RowData['subjectToDataMap']]));

  zip(subjects, timeseries).forEach(([subject, ts]) => {
    if (subject == null || ts == null) {
      return;
    }

    ts.forEach(({ type, timestamp, amount }) => {
      const quarter = Dates.toQuarterYear(timestamp);
      quarterToDataMap.get(quarter)?.set(subject.id, {
        type,
        amount,
      });
    });
  });

  const metadataRows = [
    {
      isMetadata: true,
      metadataTitle: 'Projection Start',
      subjectToProjectionStartDateMap: getSubjectToProjectionStartDateMap(subjects, data.cashFlows),
    },
  ];

  const dataRows = Array.from(quarterToDataMap.entries()).map(([quarter, subjectToDataMap]) => ({
    isMetadata: false,
    quarter,
    subjectToDataMap,
  }));

  return [...metadataRows, ...dataRows];
}

export default function usePrivatesDataGrid(data: PrivatesAnalysisResponse) {
  const columnDefs = usePrivatesDataGridColumnDefs(data);

  const rowData = usePrivatesDataGridRowData(data);

  return {
    rowData,
    columnDefs,
  };
}
