/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import XAxisTick from './XAxisTick';
import type { GridProps, GridState } from './deriveState';
import deriveState from './deriveState';
import { withTheme } from 'styled-components';

class Grid extends Component<GridProps, GridState> {
  static getDerivedStateFromProps(props: GridProps): Partial<GridState> {
    return deriveState(props);
  }

  constructor(props: GridProps) {
    super(props);
    this.state = {
      ticks: [],
      width: 0,
      height: 0,
      rowHeight: 0,
      rowsCount: 0,
      labelPosition: 0,
      middleTickIndex: 0,
    };
  }

  render() {
    const { ticks, height, labelPosition, middleTickIndex } = this.state;
    const {
      theme: { Colors },
      viewPort,
    } = this.props;
    return (
      <g>
        {ticks.map(({ position, label }, index) => {
          if (index === middleTickIndex) {
            return (
              <XAxisTick
                key={label}
                position={position}
                label={label}
                gridColor={Colors.PaleGrey}
                labelPosition={labelPosition}
                height={height + 5}
                rowHeight={viewPort.rowHeight}
              />
            );
          }
          return null;
        })}
      </g>
    );
  }
}
/* eslint-enable react/no-unused-state */

// @ts-expect-error: TODO fix strictFunctionTypes
export default withTheme(Grid);
