import type { FC } from 'react';
import React from 'react';
import styled, { css } from 'styled-components';
import CloseButton from './CloseButton';
import Icon from './icon';
import { Severity } from './Types';

const Body = styled.div<{ severity: Severity }>`
  display: flex;
  align-items: center;
  padding: 18px 16px;

  ${(props) => {
    switch (props.severity) {
      case Severity.Info:
        return css`
          background-color: #e9f7fc;
          color: #53bde6;
        `;
      case Severity.Error:
        return css`
          background-color: #f7e4e4;
          color: #b82a2b;
        `;
      case Severity.Success:
        return css`
          background-color: #e1f1e9;
          color: #009650;
        `;
      case Severity.Warn:
        return css`
          background-color: #fef0c7;
          color: #757575;
        `;
      default:
        return '';
    }
  }};
`;

const Content = styled.div`
  flex: 1;
  margin-left: 12px;
  font-size: 14px;
  font-weight: bold;
`;

const ButtonWrapper = styled.div`
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
`;

interface Props {
  severity: Severity;
  buttonLabel?: string;
  onClick?(): void;
  onClose?(): void;
}

const Notification: FC<React.PropsWithChildren<Props>> = ({
  severity = Severity.Info,
  children,
  buttonLabel,
  onClick,
  onClose,
}) => (
  <div>
    <Body severity={severity}>
      <Icon severity={severity} />
      <Content>{children}</Content>
      {onClose && <CloseButton onClose={onClose} severity={severity} />}
      {buttonLabel && onClick && <ButtonWrapper onClick={onClick}>{buttonLabel}</ButtonWrapper>}
    </Body>
  </div>
);

export default Notification;
