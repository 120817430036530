import React, { PureComponent } from 'react';
import styled, { withTheme } from 'styled-components';
import type { Theme } from 'venn-ui-kit';
import { GetColor } from 'venn-ui-kit';
import { FACTOR_SUMMARY_CHART_TRANSFORM, SUMMARY_TOOLTIP_OFFSET } from '../constants';
import { TOOLTIP_WIDTH, POPOVER_WIDTH } from '../../../factor-chart';
import type { ViewPort } from '../types';
import type { ToolTipContent } from '../../../factor-chart/tooltips/types';
import type { FactorSummaryChartRow } from '../../factorSummaryTypes';
import { getFactorDetails } from './utils';
import type { AnalysisSubject, AnalysisSubjectSecondaryLabel } from 'venn-utils';

export interface BackgroundClickEvent {
  id: number;
  factorName: string;
  value: number | null;
  position: {
    x: number;
    y: number;
    tooltipRight?: boolean;
  };
  statisticallySignificant?: boolean;
}

export interface BackgroundProps {
  activeFactorId?: number | undefined;
  baselineDataSetName?: string;
  breakdownAvailable?: boolean;
  comparisonDataSetName?: string;
  categoryDataSetName?: string;
  factors: FactorSummaryChartRow[];
  handleRowEnter?: (positionX: number, positionY: number, data: ToolTipContent) => void;
  mainDataSetName: string;
  onClick: (event: BackgroundClickEvent) => void;
  onDismissPopup: () => void;
  resetTooltip: () => void;
  updateActiveFactorId?: (activeFactorId: number) => void;
  viewPort: ViewPort;
  relative?: boolean;
  transform?: number;
  isFund?: boolean; // tearsheet flag
  secondaryLabel?: AnalysisSubjectSecondaryLabel;
  analysisSubject: AnalysisSubject;
  theme: Theme;
}

const Row = styled.rect<{ clickable: boolean; selected: boolean }>`
  cursor: ${(p) => (p.clickable ? 'pointer' : 'default')};
  fill: ${(p) => (p.selected ? 'rgba(74, 204, 255, 0.4)' : GetColor.White)};
  &:hover {
    fill: rgba(74, 204, 255, 0.2);
    stroke: rgba(211, 216, 220, 0.13);
  }
`;

class Background extends PureComponent<BackgroundProps> {
  constructor(props: BackgroundProps) {
    super(props);
    this.onBarHoverTargetClick = this.onBarHoverTargetClick.bind(this);
  }

  onBarHoverTargetClick(
    factor: FactorSummaryChartRow,
    index: number,
    event: React.MouseEvent<SVGRectElement, MouseEvent>,
  ) {
    const {
      viewPort,
      onClick,
      resetTooltip,
      updateActiveFactorId,
      transform = FACTOR_SUMMARY_CHART_TRANSFORM,
    } = this.props;
    const { mainDataPoint, factorId: id, factorName } = factor;
    const { value, statisticallySignificant } = mainDataPoint ?? {
      value: null,
      statisticallySignificant: false,
    };
    const dimension = (event.target as SVGRectElement).getBoundingClientRect();
    const popupX = event.clientX - dimension.left;
    const popupY = viewPort.rowHeight * index + transform + viewPort.rowHeight / 2 + SUMMARY_TOOLTIP_OFFSET;
    const tooltipRight = dimension.right - event.clientX < POPOVER_WIDTH;
    onClick({
      id,
      factorName,
      value,
      position: { x: popupX, y: popupY, tooltipRight },
      statisticallySignificant,
    });
    if (updateActiveFactorId) {
      updateActiveFactorId(id);
    }
    resetTooltip();
  }

  handleOnEnter = (event: React.MouseEvent<SVGRectElement, MouseEvent>, index: number) => {
    const {
      relative,
      mainDataSetName,
      baselineDataSetName,
      comparisonDataSetName,
      categoryDataSetName,
      isFund,
      secondaryLabel,
      analysisSubject,
    } = this.props;
    const dimension = (event.target as SVGRectElement).getBoundingClientRect();
    let x = event.clientX - dimension.left;
    if (dimension.right - event.clientX < TOOLTIP_WIDTH / 2) {
      x = event.clientX - dimension.left - (dimension.right - event.clientX) / 2;
    } else if (x < TOOLTIP_WIDTH / 2) {
      x += TOOLTIP_WIDTH / 2;
    }
    const y = this.props.viewPort.rowHeight * index + 1;
    if (this.props.handleRowEnter) {
      const factor = this.props.factors[index];
      const content = {
        rowName: factor.factorName,
        factorId: factor.factorId,
        details: getFactorDetails(
          factor,
          mainDataSetName,
          baselineDataSetName,
          comparisonDataSetName,
          categoryDataSetName,
          relative,
          isFund,
          secondaryLabel,
          analysisSubject.secondaryPortfolio?.updated,
        ),
      };
      this.props.handleRowEnter(x, y, content);
    }
  };

  render() {
    const {
      breakdownAvailable,
      factors,
      viewPort,
      onDismissPopup,
      activeFactorId,
      resetTooltip,
      theme: { Colors },
    } = this.props;

    return (
      <g style={{ fill: Colors.White, stroke: Colors.White }}>
        {factors.map((factor, index) => {
          const { mainDataPoint } = factor;
          const clickable = breakdownAvailable && mainDataPoint && mainDataPoint.value !== null;
          const onClick = clickable
            ? (event: React.MouseEvent<SVGRectElement, MouseEvent>): void =>
                this.onBarHoverTargetClick(factor, index, event)
            : onDismissPopup;
          const selected = factor.factorId === activeFactorId;
          return (
            <Row
              clickable={!!clickable}
              selected={selected}
              key={factor.factorId}
              x={1}
              y={index * viewPort.rowHeight}
              width={viewPort.width - 10}
              height={viewPort.rowHeight}
              onClick={onClick}
              onMouseEnter={(event: React.MouseEvent<SVGRectElement, MouseEvent>) => this.handleOnEnter(event, index)}
              onMouseLeave={resetTooltip}
              className="qa-factor-summary-row"
            />
          );
        })}
      </g>
    );
  }
}

export default withTheme(Background);
