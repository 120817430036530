import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import { GetColor, ZIndex } from 'venn-ui-kit';

export interface HeaderProps {
  className?: string;
  bottomShadow?: boolean;
}

export const Header: FC<React.PropsWithChildren<HeaderProps>> = ({ children, className, bottomShadow }) => (
  <StyledHeader className={className} bottomShadow={!!bottomShadow}>
    {children}
  </StyledHeader>
);

export default Header;

const StyledHeader = styled.div<{ bottomShadow: boolean }>`
  z-index: ${ZIndex.Front};
  padding: 15px 20px 10px;
  ${({ bottomShadow }) => bottomShadow && 'box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);'}

  h1 {
    font-size: 32px;
    font-weight: 300;
  }

  h2 {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
  }

  h3 {
    font-size: 14px;
    font-weight: normal;
    color: ${GetColor.DarkGrey};
    margin: 0;
    margin-bottom: 5px;
  }
`;
