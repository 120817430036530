import type { FC } from 'react';
import React, { useState, useCallback, useContext, useMemo } from 'react';
import styled from 'styled-components';
import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalDescription,
  SearchMenuBar,
  portfolioToSearchMenuItem,
  PortfoliosContext,
} from 'venn-components';
import type { SearchMenuItem } from 'venn-components';
import { CellLoader, Notifications, NotificationType } from 'venn-ui-kit';
import { useModal, logMessageToSentry } from 'venn-utils';
import type { ItemId, OperationError, OperationResult, Portfolio } from 'venn-api';
import { compact } from 'lodash';
import { useMutation } from '@tanstack/react-query';

interface MasterDropMenuProps {
  disabled?: boolean;
  globalContextId?: string;
}

const MasterDropMenu: FC<React.PropsWithChildren<MasterDropMenuProps>> = ({ disabled, globalContextId }) => {
  const [isModalOpen, openModal, closeModal] = useModal();
  const [selectedPortfolio, setSelectedPortfolio] = useState<Portfolio | undefined>();
  const { setAsMaster, masterPortfolio, demoPortfolio } = useContext(PortfoliosContext);

  const onChangeSelection = useCallback(
    (item: SearchMenuItem) => {
      setSelectedPortfolio(item.value?.portfolio);
      openModal();
    },
    [openModal],
  );

  const { mutate: onChangeMaster, isLoading } = useMutation({
    mutationFn: setAsMaster,
    onError: (e: OperationResult<OperationError>) => {
      Notifications.notify(e.content?.message, NotificationType.ERROR);
    },
  });

  const onConfirmUpdate = useCallback(() => {
    // selectedPortfolio has to be defined when the modal is open
    if (!selectedPortfolio) {
      logMessageToSentry('Failed to update master because nothing selected');
      return;
    }
    onChangeMaster(selectedPortfolio.id);
    closeModal();
  }, [selectedPortfolio, closeModal, onChangeMaster]);

  const excludedItems: ItemId[] = useMemo(
    () =>
      compact([
        masterPortfolio && { type: 'PORTFOLIO', id: masterPortfolio.id.toString() },
        demoPortfolio && { type: 'PORTFOLIO', id: demoPortfolio.id.toString() },
      ]),
    [masterPortfolio, demoPortfolio],
  );

  return (
    <DropMenuWrapper>
      {isLoading ? (
        <LoadingWrapper>
          <CellLoader />
        </LoadingWrapper>
      ) : (
        <SearchMenuBar
          smallScreen
          autofocus={false}
          isOptionDisabled={(option) =>
            !!globalContextId && option.value?.portfolio?.ownerContextId === globalContextId
          }
          value={masterPortfolio && portfolioToSearchMenuItem(masterPortfolio)}
          isClearable
          portfoliosOnly
          onSelected={onChangeSelection}
          location="Change master portfolio"
          disabled={disabled}
          clearQueryOnBlur
          excludedItems={excludedItems}
          privateAssetSearchMode="PUBLIC_ONLY"
        />
      )}
      {isModalOpen && (
        <Modal>
          <ModalHeader>Are you sure?</ModalHeader>
          <ModalDescription>
            Changing your master portfolio will change your home screen, and may affect existing pro forma analysis
            views.
          </ModalDescription>
          <ModalFooter
            primaryLabel="Yes, Proceed"
            primaryClassName="qa-proceed"
            onPrimaryClick={onConfirmUpdate}
            onCancel={closeModal}
          />
        </Modal>
      )}
    </DropMenuWrapper>
  );
};

export default MasterDropMenu;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const DropMenuWrapper = styled.div`
  width: 300px;
`;
