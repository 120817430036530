import type { FC } from 'react';
import React, { useContext, useState, useCallback } from 'react';
import styled from 'styled-components';
import type { Portfolio } from 'venn-api';
import {
  recursiveUpdatePortfolioName,
  recursiveUpdateAllocation,
  recursiveClearBenchmarks,
  logMessageToSentry,
} from 'venn-utils';
import { Modal, ModalFooter, ModalHeader, ModalContent } from '../../modal';
import ConditionalOverlay from '../../conditional-overlay';
import PortfoliosContext from '../../contexts/portfolios-context';

interface UpdatePortfolioModalProps {
  portfolio: Portfolio;
  isPercentageMode: boolean;
  total: number;
  clearBenchmarksBeforeSaving?: boolean;
  onClose: () => void;
  onSubmitCallback?: (newPortfolio: Portfolio | undefined) => void;
  openSaveNewPortfolioModal: () => void;
}

const UpdatePortfolioModal: FC<React.PropsWithChildren<UpdatePortfolioModalProps>> = ({
  onClose,
  portfolio,
  onSubmitCallback,
  openSaveNewPortfolioModal,
  isPercentageMode,
  total,
  clearBenchmarksBeforeSaving,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const portfoliosContext = useContext(PortfoliosContext);

  const onSubmit = useCallback(async () => {
    setSubmitting(true);

    try {
      let updatedPortfolio = recursiveUpdatePortfolioName(portfolio);
      if (isPercentageMode && total !== portfolio.allocation) {
        updatedPortfolio = recursiveUpdateAllocation(updatedPortfolio, total);
      }
      if (clearBenchmarksBeforeSaving) {
        updatedPortfolio = recursiveClearBenchmarks(updatedPortfolio);
      }
      const saved = await portfoliosContext.updateMaster(updatedPortfolio);
      onSubmitCallback?.(saved);
    } catch (error) {
      const awaitedError = await error;
      logMessageToSentry(awaitedError);
      onSubmitCallback?.(undefined);
    }

    setSubmitting(false);
    onClose();
  }, [onClose, portfolio, isPercentageMode, total, portfoliosContext, onSubmitCallback, clearBenchmarksBeforeSaving]);

  return (
    <Modal onClose={onClose}>
      <ConditionalOverlay condition={submitting}>
        <ModalHeader extraPadding>Update Master</ModalHeader>
        <ModalContent extraPadding>Are you sure you want to overwrite your master portfolio?</ModalContent>
        <StyledFooter
          onCancel={onClose}
          primaryLabel="Yes, overwrite portfolio"
          primaryClassName="qa-create-portfolio"
          onPrimaryClick={onSubmit}
          secondaryLabel="No, save as new portfolio"
          secondaryClassName="qa-create-new"
          onSecondaryClick={openSaveNewPortfolioModal}
        />
      </ConditionalOverlay>
    </Modal>
  );
};

export default UpdatePortfolioModal;

// Fix IE
const StyledFooter = styled(ModalFooter)`
  .qa-create-portfolio,
  .qa-create-new {
    width: 210px;
  }
`;
