import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import { GetColor } from 'venn-ui-kit';
import type { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { calculateScrollTo, scrollToElementById, updateSearchQuery } from 'venn-utils';

export interface StickyMenuItem<T = string> {
  id: T;
  title: string;
  hidden?: boolean;
}

const getSelected = (search: string): string | undefined => {
  if (search.length > 0 && search.includes('?')) {
    const params = queryString.parse(search.substring(1));
    return params.selected ? (params.selected as string) : undefined;
  }
  return undefined;
};

interface StickyMenusProps extends RouteComponentProps {
  items: StickyMenuItem[];
  fullWidth?: boolean;
  /**
   * Provides the ability to render a custom element after each tab text.
   */
  customTab?: (id: string) => JSX.Element;
  getStartOffset?: () => number;
  /** Toggle to make the sticky menu (re)scroll to the selected item */
  trigger?: boolean;
  /** The margin separating the scrollable sections */
  marginBetweenSections?: number;
}

interface StickyMenusState {
  activeTab: string;
}

class StickyMenus extends PureComponent<StickyMenusProps, StickyMenusState> {
  static defaultProps = {
    marginBetweenSections: 0,
  };

  state = {
    activeTab: '',
  };

  componentDidMount() {
    const { search } = this.props.location;
    // Check if any section selected
    const selected = getSelected(search);
    if (selected) {
      this.scrollToSelected(selected);
    } else if (this.props.items.length) {
      this.setState({
        activeTab: this.props.items[0].id,
      });
    }
    window.addEventListener('scroll', this.handleScroll, true);
  }

  componentDidUpdate(prevProps: StickyMenusProps) {
    const prevSelected = getSelected(prevProps.location.search);
    const selected = getSelected(this.props.location.search);
    if (prevSelected === selected && prevProps.trigger === this.props.trigger) {
      return;
    }
    if (selected) {
      this.scrollToSelected(selected);
    }
  }

  scrollToSelected(key: string) {
    scrollToElementById(key, this.getScrollOffset());
    // It's will work for 99% time
    // After 500ms we would double check if the element is in the view
    // Because of async api call the position we calculate before is not correct
    setTimeout(() => {
      const element = document.getElementById(key);
      if (element && !this.isElementInView(element)) {
        scrollToElementById(key, this.getScrollOffset());
      } else if (!this.isElementInView(element) && this.props.items.length) {
        // fallback to set an active tab in case async call wasn't done
        this.setState({
          activeTab: this.props.items[0].id,
        });
      }
    }, 500);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, true);
  }

  handleScroll = () => {
    const { items } = this.props;
    let activeTab;
    items.forEach((menu) => {
      const element = document.getElementById(menu.id);
      if (element && this.isElementInView(element)) {
        activeTab = menu.id;
      }
    });
    if (activeTab !== undefined) {
      this.setState({
        activeTab,
      });
    }
  };

  isElementInView = (el: HTMLElement | null): boolean => {
    if (!el) {
      return false;
    }
    const scrollMain = document.getElementsByTagName('main')[0];
    if (!scrollMain) {
      return false;
    }
    const top = calculateScrollTo(el, scrollMain, this.getStartOffset());
    const scrolledToBottom = scrollMain.scrollTop + scrollMain.offsetHeight >= scrollMain.scrollHeight;
    return scrollMain.scrollTop + 1 > top || scrolledToBottom;
  };

  clickSection = (id: string) => (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    scrollToElementById(id, this.getScrollOffset());
    updateSearchQuery('selected', id, this.props.history);
  };

  render() {
    const { activeTab } = this.state;
    const { items, customTab, fullWidth } = this.props;
    return (
      <MenuWrapper>
        <Menus fullWidth={fullWidth} numItems={items.length}>
          {items.map((menu: StickyMenuItem) => {
            const Tab = menu.id === activeTab ? ActiveList : List;
            return (
              <Tab key={menu.id}>
                <a href={`#${menu.id}`} onClick={this.clickSection(menu.id)}>
                  {menu.title}&nbsp;{customTab ? customTab(menu.id) : null}
                </a>
              </Tab>
            );
          })}
        </Menus>
      </MenuWrapper>
    );
  }

  private getStartOffset(): number {
    const { getStartOffset, marginBetweenSections = 0 } = this.props;
    return (getStartOffset?.() || 0) + marginBetweenSections;
  }

  private getScrollOffset(): number {
    const { marginBetweenSections = 0 } = this.props;
    return this.getStartOffset() - marginBetweenSections / 2;
  }
}

export default withRouter(StickyMenus);

const MenuWrapper = styled.div`
  background-color: ${GetColor.White};
  border-bottom: 2px solid ${GetColor.PaleGrey};
  border-top: 2px solid ${GetColor.Grey};
  @media print {
    display: none;
  }
`;

const Menus = styled.ul<{ fullWidth?: boolean; numItems?: number }>`
  padding: 0;
  margin-bottom: -1px;
  margin-top: 0;
  display: table;
  font-size: 14px;
  ${({ fullWidth, numItems }) =>
    fullWidth &&
    numItems &&
    css`
      width: 100%;
      & > li {
        max-width: ${100 / numItems}%;
        text-align: center;
      }
    `}
  li {
    display: table-cell;
    font-weight: bold;
    transition: all 0.3s ease-in-out;
    a {
      transition: all 0.3s ease-in-out;
      padding: 12px 70px;
      display: inline-block;
      position: relative;
      [class*='icon-'] {
        right: 40px;
      }
    }
    @media (max-width: 1680px) {
      a {
        padding: 12px 40px;
        [class*='icon-'] {
          right: 0;
        }
      }
    }

    @media (max-width: 1480px) {
      a {
        padding: 12px 20px;
        [class*='icon-'] {
          right: -15px;
        }
      }
    }
  }
`;

const ActiveList = styled.li`
  border-bottom: 2px solid ${GetColor.Black};
  a {
    color: ${GetColor.Black};
  }
`;

const List = styled.li`
  a {
    color: ${GetColor.MidGrey2};
    &:hover {
      color: ${GetColor.Black};
    }
  }
`;
