import React, { type CSSProperties } from 'react';
import { Icon, GetColor } from 'venn-ui-kit';
import styled from 'styled-components';
import useSearch from './useSearch';

export interface SearchBarProps {
  value?: string;
  debounce?: number;
  placeholder?: string;
  onChange: (value: string) => void;
  onFocus?: () => void;
  disableAutofocus?: boolean;
  style?: CSSProperties;
}

const SearchBar = ({
  disableAutofocus,
  style,
  debounce,
  value = '',
  onChange,
  onFocus,
  placeholder,
}: SearchBarProps) => {
  const { inputRef, searchString, onInputChange, clearSearchQuery } = useSearch(
    value,
    onChange,
    disableAutofocus,
    debounce,
  );

  return (
    <Container style={style}>
      <Input
        ref={inputRef}
        type="search"
        placeholder={placeholder}
        value={searchString}
        className="qa-search-bar"
        onChange={onInputChange}
        onFocus={onFocus}
      />
      {searchString && <SearchIcon type="times-circle" onClick={clearSearchQuery} prefix="fas" />}
      {!searchString && <SearchIcon type="search" prefix="fas" />}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

export const Input = styled.input`
  width: 100%;
  border: 1px solid ${GetColor.Grey};
  border-radius: 4px;
  height: 35px;
  font-family: ${(props) => props.theme.Typography.fontFamily};
  font-weight: normal;
  font-size: 14px;
  padding-left: 20px;

  &::placeholder {
    color: ${GetColor.HintGrey};
  }
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
`;

export const SearchIcon = styled(Icon)`
  font-size: 14px;
  position: absolute;
  right: 20px;
  top: 10px;
  color: ${GetColor.MidGrey2};

  &.fa-times-circle {
    cursor: pointer;
  }
`;

export default SearchBar;
