import type { Portfolio } from 'venn-api';
import type { DropMenuItem } from 'venn-ui-kit';
import type { AllocationItem } from 'venn-utils';
import { isNil } from 'lodash';

export const EXPERIMENTAL_FUND_CLASSNAME = 'fund-experimental';

export const getPortfolioMenuItems = (portfolio: Portfolio, level = 0): DropMenuItem<AllocationItem>[] => {
  let items: DropMenuItem<AllocationItem>[] = [];

  const { name, id, allocation, fund, draft } = portfolio;

  items.push({
    label: portfolio.name,
    value: { name, id, allocation, fund, draft },
    level,
    className: !isNil(fund) && draft ? EXPERIMENTAL_FUND_CLASSNAME : undefined,
  });

  if (portfolio.fund) {
    return items;
  }

  portfolio.children.forEach((child: Portfolio) => {
    items = [...items, ...getPortfolioMenuItems(child, level + 1)];
  });

  return items;
};

export const mapStrategiesToChildNames = (portfolio: Portfolio): Map<number, string[]> => {
  const strategyToChildNames = new Map<number, string[]>();
  getChildNames(portfolio, strategyToChildNames);
  return strategyToChildNames;
};

const getChildNames = (node: Portfolio, map: Map<number, string[]>): string[] => {
  if (node.fund) {
    return [node.name];
  }
  let childNames: string[] = [];
  node.children.forEach((child) => {
    childNames = [...childNames, ...getChildNames(child, map)];
  });
  map.set(node.id, childNames);
  return [node.name, ...childNames];
};
