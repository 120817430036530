import { capitalize, isEmpty, isNil } from 'lodash';
import React from 'react';
import { useRecoilValue } from 'recoil';
import styled, { css } from 'styled-components';
import type { FrequencyEnum, InfoGraphicTypeEnum } from 'venn-api';
import {
  type DataModificationsCategory,
  DataModificationsModal,
  DeveloperFallbackComponent,
  StudioShimmerBlock,
  useAnalysisBlockDataModifications,
  useAppPrintMode,
  useBlockId,
  useHoldings,
  usePrivateAnalysis,
  usePrivateAnalysisBlockDataModifications,
  withErrorBoundary,
} from 'venn-components';
import {
  blockBenchmarkConfig,
  blockBenchmarkType,
  blockCustomizableMetricsMap,
  blockDateRange,
  blockDisplayHeader,
  blockFonts,
  blockInfoGraphicType,
  blockLimitedRequestSubjects,
  blockPrivateDefaultAsOfDateBehavior,
  blockSettings,
  customizedBlock,
  getCustomFontSize,
  isReportState,
  viewShowVenncast,
} from 'venn-state';
import { Body1, GetColor, getTextThemeProvider, Headline3, Icon, REPORT_LAB_FONT_BODY } from 'venn-ui-kit';
import {
  blockShowsInvestments,
  type CustomBlockTypeEnum,
  type DATE_BEHAVIOR_ENUM,
  Dates,
  FootnoteSymbols,
  getDateBehavior,
  getSelectedRollingPeriod,
  hasDisabledBenchmark,
  hasDisabledIndividualBenchmark,
  hasDisabledRelativeToBenchmark,
  isFactorTrendMetric,
  isHoldingsBlock,
  isPrivatesBlock,
  isPrivatesPerformanceBlock,
  isPublicPrivateAssetGrowthBlock,
  isRollingMetric,
  shouldForceHideBenchmark,
  useHasFF,
  useModal,
  withSuspense,
} from 'venn-utils';
import BlockObjects from './BlockObjects';

interface BlockHeaderProps {
  id: string;
}

interface BlockHeaderColumnProps extends BlockHeaderProps {
  isCompact: boolean;
  dateBehavior: DATE_BEHAVIOR_ENUM;
  from?: number;
  to?: number;
  frequency?: FrequencyEnum;
  endDateRangePrefix?: string;
  blockGraphicType?: InfoGraphicTypeEnum;
  blockType?: CustomBlockTypeEnum;
}

interface HoldingsDateProps {
  isReport: boolean;
  blockId: string;
}

interface HoldingsCalculationInfoProps {
  isReport: boolean;
  blockType: CustomBlockTypeEnum;
}

const benchmarkTypes = {
  COMMON: 'Common Benchmark',
  INDIVIDUAL: 'Individual Benchmarks',
  NONE: 'No Benchmark',
};

const PrivatesDateRangeColumn = withErrorBoundary(
  DeveloperFallbackComponent,
  ({ id, isCompact, dateBehavior, blockType, blockGraphicType }: BlockHeaderColumnProps) => {
    const { data } = usePrivateAnalysis(id);
    const startAsOfLastTransaction = useRecoilValue(blockPrivateDefaultAsOfDateBehavior(id));
    // do not show projection start date for grid format of cash flow block
    if (blockType === 'PRIVATE_CASH_FLOW' && blockGraphicType === 'GRID') {
      return null;
    }
    // do not show as-of-date for PME block headers, since it is already incorporated inside the charts
    if (!blockType || isPrivatesPerformanceBlock(blockType)) {
      return null;
    }

    return (
      <DateRangeColumn
        isCompact={isCompact}
        dateBehavior={dateBehavior}
        id={id}
        to={startAsOfLastTransaction ? data?.cashFlows?.[0]?.projectionAsOfDate : undefined}
        frequency="QUARTERLY"
        endDateRangePrefix="Projection Start: "
      />
    );
  },
);

const PrivateAssetGrowthDateRangeColumn = withErrorBoundary(
  DeveloperFallbackComponent,
  ({ id, isCompact, dateBehavior }: BlockHeaderColumnProps) => {
    const dateRange = useRecoilValue(blockDateRange(id));

    return (
      <>
        <MetadataColumn compact={isCompact}>
          <StyledIcon type="clock" compact={isCompact} />
          <Text>Quarterly</Text>
        </MetadataColumn>
        <DateRangeColumn
          isCompact={isCompact}
          dateBehavior={dateBehavior}
          id={id}
          to={dateRange?.range.to}
          frequency="QUARTERLY"
          endDateRangePrefix="Simulation Start Date: "
        />
      </>
    );
  },
);

const DateRangeColumn = ({
  id,
  isCompact,
  dateBehavior,
  to: toOverride,
  from: fromOverride,
  frequency: frequencyOverride,
  endDateRangePrefix = '',
}: BlockHeaderColumnProps) => {
  const computedDateRange = useRecoilValue(blockDateRange(id));

  if (dateBehavior !== 'DATE_RANGE' && dateBehavior !== 'END_DATE_RANGE') {
    return null;
  }
  const from = fromOverride ?? computedDateRange?.range.from;
  const to = toOverride ?? computedDateRange?.range.to;
  const frequency = frequencyOverride ?? computedDateRange?.frequency;

  const frequencyText = capitalize(frequency ?? '--');
  const fromText = from && frequency ? Dates.toDDMMMYYYY(from, frequency) : '--';
  const toText = to && frequency ? Dates.toDDMMMYYYY(to, frequency) : '--';
  const rangeText = `${fromText} - ${toText}`;

  if (dateBehavior === 'END_DATE_RANGE') {
    return (
      <>
        <MetadataColumn compact={isCompact}>
          <StyledIcon type="calendar" compact={isCompact} />
          <Text>
            {endDateRangePrefix}
            {toText}
          </Text>
        </MetadataColumn>
      </>
    );
  }
  return (
    <>
      <MetadataColumn compact={isCompact}>
        <StyledIcon type="clock" compact={isCompact} />
        <Text>{frequencyText}</Text>
      </MetadataColumn>
      <MetadataColumn compact={isCompact}>
        <StyledIcon type="calendar" compact={isCompact} />
        <Text>{rangeText}</Text>
      </MetadataColumn>
    </>
  );
};

const HoldingsCalculationInfo = ({ blockType, isReport }: HoldingsCalculationInfoProps) => {
  const text = blockType === 'ASSET_EXPOSURE' ? 'Net Rescaled Values' : 'Long Rescaled Values';
  return (
    <>
      <StyledIcon type="info-circle" compact={isReport} />
      <Text>{text}</Text>
    </>
  );
};

const HoldingsDate = React.memo(function HoldingsDate({ blockId, isReport }: HoldingsDateProps) {
  const { data } = useHoldings(blockId);
  if (!data) {
    return null;
  }
  const text = data.asOfDate ? `As of ${Dates.toDDMMMYYYY(data.asOfDate)}` : 'Most Recent Available';
  return (
    <>
      <StyledIcon type="calendar" compact={isReport} />
      <Text>{text}</Text>
    </>
  );
});

type DataModificationsInfoProps = {
  blockId: string;
  isCompact: boolean;
  modifications: DataModificationsCategory[];
};

const DataModificationsButton = styled.button<{ isReport: boolean }>`
  color: unset;
  ${(props) =>
    props.isReport &&
    css`
      pointer-events: none;
    `};
`;

const DataModificationsInfoInternal = ({ isCompact, modifications }: DataModificationsInfoProps) => {
  const [isOpen, open, close] = useModal();
  const isReport = useRecoilValue(isReportState);
  return (
    <>
      <DataModificationsButton isReport={isReport} onClick={open}>
        <MetadataColumn compact={isCompact}>
          <StyledIcon type="info-circle" compact={isCompact} />
          <Text>Contains Venn-Modified Data&nbsp;{`${isReport ? FootnoteSymbols.vennModifiedData : ''}`}</Text>
        </MetadataColumn>
      </DataModificationsButton>
      {isOpen && <DataModificationsModal onClose={close} modifications={modifications} />}
    </>
  );
};

const PrivateAnalysisBlockDataModifications = ({
  isCompact,
  blockId,
}: Omit<DataModificationsInfoProps, 'modifications'>) => {
  const { modifications } = usePrivateAnalysisBlockDataModifications(blockId);
  if (isEmpty(modifications)) {
    return null;
  }
  return <DataModificationsInfoInternal blockId={blockId} isCompact={isCompact} modifications={modifications} />;
};

const PublicPrivateBlockDataModifications = ({
  isCompact,
  blockId,
}: Omit<DataModificationsInfoProps, 'modifications'>) => {
  const { modifications } = useAnalysisBlockDataModifications(blockId);
  if (isEmpty(modifications)) {
    return null;
  }
  return <DataModificationsInfoInternal blockId={blockId} isCompact={isCompact} modifications={modifications} />;
};

const BlockHeader = React.memo(function BlockHeader() {
  const blockId = useBlockId();
  const { inPrintModeOrReportLab } = useAppPrintMode();
  const isReport = useRecoilValue(isReportState);
  const blockSetting = useRecoilValue(blockSettings(blockId));
  const blockGraphicType = useRecoilValue(blockInfoGraphicType(blockId));
  const block = useRecoilValue(customizedBlock(blockId));
  const subjects = useRecoilValue(blockLimitedRequestSubjects(blockId));
  const benchmarkConfig = useRecoilValue(blockBenchmarkConfig(blockId));
  const header = useRecoilValue(blockDisplayHeader(blockId));
  const hasStudioVenncastFF = useHasFF('studio_venncast_ff');
  const showVenncast = useRecoilValue(viewShowVenncast(blockId)) && hasStudioVenncastFF;
  const customBenchmarkType = useRecoilValue(blockBenchmarkType(blockId));

  const { relativeToBenchmark, contributionToPercentage, rollingYears, selectedMetrics } = block || {};
  const factorTrendMetricSelected = isFactorTrendMetric(selectedMetrics);
  const rollingTimeseriesSelected = isRollingMetric(selectedMetrics);
  const selectedOption = getSelectedRollingPeriod(rollingYears, factorTrendMetricSelected);
  const showIndividualBenchmarksOnLegend =
    benchmarkConfig.type === 'INDIVIDUAL' && !hasDisabledIndividualBenchmark(blockSetting?.customBlockType);

  const compactMetadataColumn = inPrintModeOrReportLab;
  const dateBehavior = getDateBehavior(blockSetting.customBlockType);
  const blockType = blockSetting?.customBlockType;
  const isPrivate = isPrivatesBlock(blockType);

  const metric = selectedMetrics && selectedMetrics.length ? selectedMetrics[0] : '';
  const metricDefinitionsMap = useRecoilValue(blockCustomizableMetricsMap(blockId));
  const requiresBenchmark = metricDefinitionsMap[metric]?.needsBenchmark;
  const forceHideBenchmark = shouldForceHideBenchmark(blockType, metricDefinitionsMap, selectedMetrics);

  const isBlockRelativeToBenchmark =
    relativeToBenchmark &&
    !hasDisabledBenchmark(blockType, blockGraphicType) &&
    !hasDisabledRelativeToBenchmark(blockType);

  const showBlockSubjects =
    (!inPrintModeOrReportLab || (inPrintModeOrReportLab && (isBlockRelativeToBenchmark || requiresBenchmark))) &&
    !isEmpty(subjects);

  const titleFont = useRecoilValue(blockFonts.blockTitle(blockId));
  const subTitleFont = useRecoilValue(blockFonts.blockSubtitle(blockId));
  const headerInfoFont = useRecoilValue(blockFonts.blockHeaderInfo(blockId));

  return (
    <>
      <TopMetaData inPrintMode={inPrintModeOrReportLab}>
        <HeaderArea>
          <div style={{ display: 'flex' }}>
            <Headline
              style={{ fontSize: getCustomFontSize(titleFont) }}
              data-testid="qa-block-header"
              isReport={Boolean(isReport)}
            >
              {header}
            </Headline>
          </div>
          {block.subHeader && <Body1 style={{ fontSize: getCustomFontSize(subTitleFont) }}>{block.subHeader}</Body1>}
        </HeaderArea>

        <MetadataSection
          inPrintMode={inPrintModeOrReportLab}
          fontSize={headerInfoFont.fontSizePt}
          className="qa-metadata-section"
        >
          <MetadataWrapper>
            <MetadataRow paddingBottom="0">
              {isPrivate ? (
                <>
                  <PrivatesDateRangeColumn
                    id={blockId}
                    blockType={blockType}
                    blockGraphicType={blockGraphicType}
                    isCompact={compactMetadataColumn}
                    dateBehavior={dateBehavior}
                  />
                  <PrivateAnalysisBlockDataModifications isCompact={compactMetadataColumn} blockId={blockId} />
                </>
              ) : isPublicPrivateAssetGrowthBlock(blockType) ? (
                <>
                  <PrivateAssetGrowthDateRangeColumn
                    isCompact={compactMetadataColumn}
                    dateBehavior={dateBehavior}
                    id={blockId}
                  />
                  <PublicPrivateBlockDataModifications isCompact={compactMetadataColumn} blockId={blockId} />
                </>
              ) : (
                <DateRangeColumn id={blockId} isCompact={compactMetadataColumn} dateBehavior={dateBehavior} />
              )}

              {!forceHideBenchmark &&
                customBenchmarkType &&
                !hasDisabledBenchmark(blockType, blockGraphicType) &&
                customBenchmarkType !== 'NONE' &&
                !(customBenchmarkType === 'INDIVIDUAL' && hasDisabledIndividualBenchmark(blockType)) &&
                !(customBenchmarkType === 'COMMON' && isNil(benchmarkConfig.subject)) && (
                  <MetadataColumn compact={compactMetadataColumn}>
                    <StyledIcon type="arrows-h" compact={compactMetadataColumn} />
                    <Text>{benchmarkTypes[customBenchmarkType]}</Text>
                  </MetadataColumn>
                )}
              {isBlockRelativeToBenchmark && (
                <MetadataColumn compact={compactMetadataColumn}>
                  <StyledIcon type="calculator" compact={compactMetadataColumn} />
                  <Text>Relative to Benchmark</Text>
                </MetadataColumn>
              )}
              {contributionToPercentage && (
                <MetadataColumn compact={compactMetadataColumn}>
                  <StyledIcon type="percent" compact={compactMetadataColumn} />
                  <Text>Percentage Contribution: On</Text>
                </MetadataColumn>
              )}
              {showVenncast && (
                <MetadataColumn compact={compactMetadataColumn}>
                  <StyledIcon type="chart-line" compact={compactMetadataColumn} />
                  <Text>{getTextThemeProvider().VenncastName} Missing Data: On</Text>
                </MetadataColumn>
              )}
              {(rollingTimeseriesSelected || factorTrendMetricSelected) && (
                <MetadataColumn compact={compactMetadataColumn}>
                  <StyledIcon type="wave-sine" compact={compactMetadataColumn} />
                  <Text>Rolling Period: {selectedOption.label}</Text>
                </MetadataColumn>
              )}
              {dateBehavior === 'SERVER_SENT' && (
                <MetadataColumn compact={compactMetadataColumn}>
                  <HoldingsDate blockId={blockId} isReport={compactMetadataColumn} />
                </MetadataColumn>
              )}
              {isHoldingsBlock(blockType) && (
                <MetadataColumn compact={compactMetadataColumn}>
                  <HoldingsCalculationInfo blockType={blockType} isReport={compactMetadataColumn} />
                </MetadataColumn>
              )}
            </MetadataRow>
          </MetadataWrapper>
        </MetadataSection>
      </TopMetaData>
      {showBlockSubjects ? (
        <BlockObjects
          forceHideBenchmarks={forceHideBenchmark}
          blockId={blockId}
          subjects={subjects}
          commonBenchmark={benchmarkConfig.subject}
          individualBenchmarks={showIndividualBenchmarksOnLegend}
          showInvestments={blockShowsInvestments(blockType)}
        />
      ) : null}
    </>
  );
});

export default withSuspense(<StudioShimmerBlock />, BlockHeader);

const TopMetaData = styled.div<{ inPrintMode: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: ${({ inPrintMode }) => `${inPrintMode ? '0.7rem 0.7rem 0.3rem 0.7rem' : '13px 15px 5px 15px'}`};
  height: auto;

  label {
    font-size: 14px;
    padding-right: 5px;
  }

  border-bottom: 1px solid ${GetColor.Grey};
`;

const HeaderArea = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  word-break: break-word;
  flex: 0 0 100%;
  padding-bottom: 5px;

  ${Body1} {
    font-size: 1rem;
  }
`;

const MetadataSection = styled.div<{
  inPrintMode: boolean;
  fontSize: number | undefined;
}>`
  display: flex;
  font-size: ${({ inPrintMode, fontSize }) =>
    fontSize ? `${fontSize}pt` : inPrintMode ? REPORT_LAB_FONT_BODY : 'calc(1rem * 7 / 6)'};
  flex: 0 0 100%;
`;

const MetadataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

const MetadataRow = styled.div<{ paddingBottom: string }>`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-bottom: ${({ paddingBottom }) => paddingBottom};
`;

const MetadataColumn = styled.div<{ compact?: boolean }>`
  ${({ compact }) =>
    !compact
      ? css`
          margin: 5px 5px 0 0;
          border: 1px solid ${GetColor.GreyScale.Grey50};
          border-radius: 4px;
          padding: 3px 5px;
        `
      : 'padding-left: 0px;'}
`;

const Text = styled.span`
  padding-left: 3px;
  padding-right: 10px;
`;

const StyledIcon = styled(Icon)<{ compact?: boolean }>`
  padding: 4px;
  ${({ compact }) =>
    !compact
      ? css`
          color: ${GetColor.GreyScale.Grey60};
          background-color: ${GetColor.GreyScale.Grey20};
          border-radius: 2px;
        `
      : 'padding-left: 0px;'}
`;

const Headline = styled(Headline3)<{ isReport: boolean }>`
  display: flex;
  flex: 1;
  margin: 0 10px 0 0;
  vertical-align: middle;
  font-size: ${({ isReport }) => (isReport ? '1.225rem' : '21px')};
  ${({ isReport }) => (isReport ? '' : '@media print { font-size: 16pt; }')}
`;
