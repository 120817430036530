import React from 'react';
import {
  Tooltip,
  Button,
  Icon,
  Hint,
  GetColor,
  ExternalActivityListener,
  LegacyRelativePortal,
  TooltipPosition,
  portalMenuIgnoreActivityClassName,
  Alert,
  ColorUtils,
} from 'venn-ui-kit';
import styled, { css } from 'styled-components';
import useExportButton from './useExportButton';
import type { ExportButtonProps } from './shared';
import { IconButton } from './shared';
import useScheduled from './useScheduled';
import { getScheduledOptions } from './helper';
import ConfirmationModal from '../modals/confirmation/ConfirmationModal';
import ScheduledHistory from './ScheduledHistory';
import { PrintDisabledModal } from '../print/PrintDisabledModal';
import Markdown from '../markdown/Markdown';

const HAS_FREQUENCY_WIDTH = 168;
const NO_FREQUENCY_WIDTH = 110;
const OPTION_WIDTH = 300;
const PADDING_SPACE = 30;

const ExportButtonWithScheduled = ({
  dominant,
  disabled,
  downloadAll,
  downloadXlsx,
  downloadPNGs,
  savedId,
  isCompare,
  subjectId,
  onSave,
  hasSavedModified,
  noAccessModifiedView,
  printLabel,
}: ExportButtonProps) => {
  const {
    isOpen,
    setOpen,
    handlePrintClick,
    exportOptions,
    hasExportsDisabled,
    hasExportsDisabledReason,
    hasReportLab,
  } = useExportButton({
    isCompare,
    downloadPNGs,
    downloadXlsx,
    downloadAll,
    savedId,
    subjectId,
  });

  const {
    isLoading,
    onUpdateFrequency,
    currentFrequency,
    handleClickFrequency,
    showUnsavedChangeModal,
    unsavedFrequency,
    cancelUpdateFrequency,
    scheduledDisabledMessage,
  } = useScheduled(savedId, onSave, setOpen, hasSavedModified, noAccessModifiedView);

  const options = getScheduledOptions();
  const selectedOption = currentFrequency ? options.find((option) => option.value === currentFrequency) : undefined;
  const buttonWidth = selectedOption?.short ? HAS_FREQUENCY_WIDTH : NO_FREQUENCY_WIDTH;
  return (
    <div>
      <Flex>
        {(!hasExportsDisabled || hasReportLab) && (
          <Tooltip content={printLabel ?? 'Print'} position={TooltipPosition.Bottom}>
            <IconButton noMargin dense onClick={handlePrintClick} disabled={disabled}>
              <Icon type="print" />
            </IconButton>
          </Tooltip>
        )}
        {hasExportsDisabled && hasReportLab && (
          <Button noMargin dense onClick={downloadXlsx}>
            Download XLSX
          </Button>
        )}
        {(!hasReportLab || !hasExportsDisabled) && (
          <Dropdown width={buttonWidth}>
            <Tooltip
              maxWidth={320}
              usePortal
              position={TooltipPosition.Left}
              isHidden={!hasExportsDisabled}
              content={<Markdown noMargin source={hasExportsDisabledReason} />}
              interactive
            >
              <Button
                dominant={dominant}
                noMargin
                dense
                onClick={() => setOpen(!isOpen)}
                disabled={disabled || isLoading || hasExportsDisabled}
              >
                <Icon type="share-square" />
                <span>&nbsp; Export</span>
                {selectedOption?.short ? (
                  <>
                    {' '}
                    | <Icon type="calendar-check" /> {selectedOption?.short}.{' '}
                  </>
                ) : (
                  ''
                )}
                <Icon type={isOpen ? 'caret-up' : 'caret-down'} />
              </Button>
            </Tooltip>
            {isOpen && (
              <div>
                <LegacyRelativePortal left={buttonWidth - OPTION_WIDTH + PADDING_SPACE}>
                  <ExternalActivityListener
                    listeningEnabled={isOpen}
                    onExternalActivity={() => setOpen(false)}
                    ignoreActivityFromClassName={portalMenuIgnoreActivityClassName}
                  >
                    <OptionWrapper>
                      <OptionGroupHeader>Export Once...</OptionGroupHeader>
                      <OptionBody>
                        {exportOptions.map((exportOption) => (
                          <Button
                            key={exportOption.tooltipContent}
                            noMargin
                            dense
                            onClick={exportOption.onClick}
                            disabled={!exportOption.onClick}
                            className="qa-export-button"
                          >
                            <Icon type="download" />
                            <span>&nbsp; {exportOption.label}</span>
                          </Button>
                        ))}
                      </OptionBody>
                      <OptionGroupHeader>
                        <div>Scheduled Export...</div>
                        <OptionGroupHeaderHint>
                          Recieve a scheduled .xlsx version of this view via email
                        </OptionGroupHeaderHint>
                      </OptionGroupHeader>

                      {options.map(({ value, label, hint, nextTime, icon }) => (
                        <Tooltip
                          isHidden={!scheduledDisabledMessage}
                          content={scheduledDisabledMessage}
                          key={value}
                          block
                        >
                          <Option
                            onClick={() => handleClickFrequency(value)}
                            active={value === currentFrequency}
                            disabled={!!scheduledDisabledMessage}
                          >
                            <OptionLabel>
                              <Icon type={icon} />
                              &nbsp; {label}
                            </OptionLabel>
                            {hint && (
                              <Hint>
                                {hint} | <strong>Next Export:</strong> {nextTime}
                              </Hint>
                            )}
                          </Option>
                        </Tooltip>
                      ))}
                      <ScheduledHistory savedId={savedId} scheduledDisabledMessage={scheduledDisabledMessage} />
                    </OptionWrapper>
                  </ExternalActivityListener>
                </LegacyRelativePortal>
              </div>
            )}
          </Dropdown>
        )}
      </Flex>
      <PrintDisabledModal isPrintDisabled={hasExportsDisabled} />
      {showUnsavedChangeModal && unsavedFrequency && (
        <ConfirmationModal
          header="Save view?"
          subhead={`You’ve made changes to this comparison view. Would you like to save them before scheduling ${unsavedFrequency.toLocaleLowerCase()} exports? `}
          text=""
          onCancel={cancelUpdateFrequency}
          secondaryLabel="Schedule without saving"
          onSecondaryClick={() => onUpdateFrequency(unsavedFrequency, false)}
          proceedLabel="Save & Schedule"
          onProceed={() => onUpdateFrequency(unsavedFrequency, true)}
        />
      )}
      {currentFrequency !== 'UNKNOWN' && hasSavedModified && (
        <Message>
          <Alert dismissable>
            Some settings in this comparison are not being included in your scheduled export.{' '}
            <strong>Save this view to update your {currentFrequency.toLocaleLowerCase()} export.</strong>
          </Alert>
        </Message>
      )}
    </div>
  );
};

export default ExportButtonWithScheduled;

const Flex = styled.div`
  display: flex;
`;

const Dropdown = styled.div<{ width: number }>`
  font-weight: normal;
  && button {
    border-left: none;
    border-radius: 0px 2px 2px 0px;
    min-width: ${(props) => props.width}px;
  }
`;

const OptionWrapper = styled.div`
  width: ${OPTION_WIDTH}px;
  height: calc(100vh - 40px);
  position: relative;
  overflow-y: auto;
  padding-bottom: 40px;
  background: ${GetColor.White};
  border: 1px solid ${GetColor.Grey};
  box-shadow: 0px 0px 11px 1px ${GetColor.Grey};
  ${Hint} {
    font-size: 10px;
  }
  animation: slideIn 0.5s forwards;
  @-webkit-keyframes slideIn {
    0% {
      right: -${OPTION_WIDTH}px;
    }
    100% {
      right: 0;
    }
  }
  @keyframes slideIn {
    0% {
      right: -${OPTION_WIDTH}px;
    }
    100% {
      right: 0;
    }
  }
`;

const OptionGroupHeader = styled.div`
  background-color: ${GetColor.WhiteGrey};
  border: 1px solid ${GetColor.Grey};
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: bold;
  padding: 10px 20px;
`;

const OptionGroupHeaderHint = styled(Hint)`
  font-style: italic;
`;

const OptionBody = styled.div`
  padding: 20px;
`;

const backgroundColor = ColorUtils.hex2rgbaFrom(GetColor.Primary.Main, 0.2);
const hoverColor = ColorUtils.hex2rgbaFrom(GetColor.Primary.Main, 0.05);

const Option = styled.button<{ active: boolean }>`
  width: 100%;
  color: ${GetColor.Black};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 20px;
  ${(props) =>
    props.active &&
    css`
      background-color: ${backgroundColor};
    `}
  &:hover {
    background: ${hoverColor};
    cursor: pointer;
    color: ${GetColor.Black};
  }
  &:disabled {
    ${Hint} {
      color: ${GetColor.Grey};
    }
  }
`;

const OptionLabel = styled.div`
  display: flex;
`;

const Message = styled.div`
  position: fixed;
  width: calc(100vw - 70px);
  right: 1px;
  top: 90px;
  font-weight: normal;
  animation: fadeIn 0.5s forwards;
  opacity: 0;
  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
