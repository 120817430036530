import React from 'react';
import styled from 'styled-components';
import type { ToastOptions } from 'react-toastify';
import { toast } from 'react-toastify';
import { GetColor } from '../../style/color';

export enum NotificationType {
  INFO,
  SUCCESS,
  WARNING,
  ERROR,
  PROMISE,
  LOADING,
}

interface ToastIconProps {
  prefix?: string;
  className: string;
}

const ToastIcon = ({ prefix, className }: ToastIconProps) => {
  return (
    <>
      <ToastIconContainer className={`${prefix ?? 'fal'} fa-${className}`} />
    </>
  );
};

const _toast = (
  text: string | JSX.Element,
  type: NotificationType,
  options: ToastOptions,
  toastId?: React.ReactText,
): React.ReactText | undefined => {
  const formattedText = <div style={{ marginLeft: '12px' }}>{text}</div>;

  switch (type) {
    case NotificationType.WARNING:
      if (toastId) {
        toast.update(toastId, {
          render: formattedText,
          type: 'warning',
          icon: <ToastIcon className="exclamation-triangle" />,
          ...options,
        });
        return toastId;
      }
      return toast.warning(formattedText, options);

    case NotificationType.ERROR:
      if (toastId) {
        toast.update(toastId, {
          render: formattedText,
          type: 'error',
          icon: <ToastIcon className="exclamation-triangle" />,
          ...options,
        });
        return toastId;
      }
      return toast.error(formattedText, options);

    case NotificationType.SUCCESS:
      if (toastId) {
        toast.update(toastId, {
          render: formattedText,
          type: 'success',
          icon: <ToastIcon className="check" />,
          ...options,
        });
      } else {
        return toast.success(formattedText, options);
      }
      break;
    case NotificationType.PROMISE:
      const promise = new Promise((resolve) => setTimeout(resolve, 1000));
      // const promise = () => new Promise(resolve => setTimeout(resolve, 3000));

      toast.promise(
        promise,
        {
          pending: text,
          success: {
            render() {
              return `${text} succeeded`;
            },
            icon: <ToastIcon className="check" />,
          },
          error: {
            render() {
              return `${text} failed`;
            },
            icon: <ToastIcon className="exclamation-triangle" />,
          },
        },
        options,
      );
      break;
    case NotificationType.LOADING:
      return toast.loading(formattedText, options);
    default:
    case NotificationType.INFO:
      if (toastId) {
        toast.update(toastId, {
          render: formattedText,
          type: 'info',
          icon: <ToastIcon className="info" />,
          ...options,
        });
      } else {
        return toast.info(formattedText, options);
      }
      break;
  }

  return toastId;
};

export const Notifications = {
  dismissAll() {
    toast.dismiss();
  },
  dismiss(toastId: React.ReactText) {
    toast.dismiss(toastId);
  },
  isActive(toastId: React.ReactText) {
    return toast.isActive(toastId);
  },
  notifyPersistent: (
    text: string | JSX.Element,
    type: NotificationType = NotificationType.INFO,
  ): React.ReactText | undefined => {
    const autoClose = type === NotificationType.WARNING || type === NotificationType.ERROR ? false : undefined;
    const options = {
      position: toast.POSITION.TOP_CENTER,
      containerId: 'wide',
      autoClose,
    } as ToastOptions;

    return _toast(text, type, options);
  },
  notifyPersistentCustom: (
    text: string | JSX.Element,
    icon: string,
    autoClose: boolean,
  ): React.ReactText | undefined => {
    const options = {
      position: toast.POSITION.TOP_CENTER,
      containerId: 'wide',
      autoClose,
      icon: <ToastIcon className={icon} />,
    } as ToastOptions;

    return _toast(text, NotificationType.INFO, options);
  },
  notify: (
    text: string | JSX.Element,
    type: NotificationType = NotificationType.INFO,
    position = toast.POSITION.BOTTOM_RIGHT,
  ): React.ReactText | undefined => {
    const autoClose = type === NotificationType.WARNING || type === NotificationType.ERROR ? false : undefined;
    const options = {
      position,
      closeButton: false,
      containerId: 'default',
      autoClose,
    } as ToastOptions;

    return _toast(text, type, options);
  },
  notifyUpdate: (
    toastId: React.ReactText | undefined,
    text: string | JSX.Element,
    type: NotificationType = NotificationType.INFO,
  ): React.ReactText | undefined => {
    const autoClose = type === NotificationType.WARNING || type === NotificationType.ERROR ? false : undefined;
    const options = {
      position: toast.POSITION.BOTTOM_RIGHT,
      closeButton: false,
      containerId: 'default',
      closeOnClick: true,
      isLoading: false,
      autoClose,
    } as ToastOptions;

    return _toast(text, type, options, toastId);
  },
  notifyUpdatePersistent: (
    toastId: React.ReactText | undefined,
    text: string | JSX.Element,
    type: NotificationType = NotificationType.INFO,
  ): React.ReactText | undefined => {
    const autoClose = type === NotificationType.WARNING || type === NotificationType.ERROR ? false : undefined;
    const options = {
      position: toast.POSITION.TOP_CENTER,
      closeButton: false,
      containerId: 'wide',
      closeOnClick: true,
      isLoading: false,
      autoClose,
    } as ToastOptions;

    return _toast(text, type, options, toastId);
  },
};

const ToastIconContainer = styled.i`
  color: ${GetColor.White};
  font-size: 24px;
`;
