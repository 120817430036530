import type { FrequencyEnum } from 'venn-api';
import { FREQUENCY_DATEPICKER_MODES, FrequencyTypes } from './types';

export const getDateMode = (frequencyId: FrequencyTypes | FrequencyEnum | undefined) =>
  FREQUENCY_DATEPICKER_MODES[frequencyId || FrequencyTypes.MONTHLY];

export const getFrequencyType = (frequency?: FrequencyTypes | FrequencyEnum) =>
  typeof frequency === 'string' ? FrequencyTypes[frequency] : frequency;

const frequencyTypeToReturnFrequencyEnum: { [k in FrequencyTypes]: FrequencyEnum } = {
  [FrequencyTypes.DAILY]: 'DAILY',
  [FrequencyTypes.MONTHLY]: 'MONTHLY',
  [FrequencyTypes.QUARTERLY]: 'QUARTERLY',
  [FrequencyTypes.YEARLY]: 'YEARLY',
};

export const toReturnFrequency = (frequencyType: FrequencyTypes): FrequencyEnum => {
  return frequencyTypeToReturnFrequencyEnum[frequencyType];
};
