import React from 'react';
import { useRecoilValue } from 'recoil';
import { blockSettings } from 'venn-state';
import { GridWrapper } from './common';
import { BlockSuspenseFallback } from './components/BlockSuspenseFallback';
import { DateRangeErrorWrapper } from './components/error-wrappers/DateRangeErrorWrapper';
import { ErrorMultiWrapper } from './components/error-wrappers/ErrorMultiWrapper';
import { InfoGraphicTypeErrorWrapper } from './components/error-wrappers/InfoGraphicTypeErrorWrapper';
import { SubjectsErrorWrapper } from './components/error-wrappers/SubjectsErrorWrapper';
import { UnexpectedErrorWrapper } from './components/error-wrappers/UnexpectedErrorWrapper';
import PrivateAssetSummaryGrid from './components/grid/PrivateAssetSummaryGrid';
import PrivatesAnalysisBlock from './PrivatesAnalysisBlock';
import type { CustomBlockProps } from './types';

const PrivatesBlock = ({ selectedRefId, isReport, downloadableContentRef }: CustomBlockProps) => {
  const blockSetting = useRecoilValue(blockSettings(selectedRefId));
  const customBlockType = blockSetting.customBlockType;

  if (
    ['PRIVATE_CASH_FLOW', 'PRIVATE_PERFORMANCE_TIME_SERIES', 'PRIVATE_PERFORMANCE_SUMMARY'].includes(customBlockType)
  ) {
    return <PrivatesAnalysisBlock isReport={isReport} downloadableContentRef={downloadableContentRef} />;
  }

  if (customBlockType === 'PRIVATE_CAPITAL_SUMMARY') {
    return (
      <GridWrapper isReport={isReport}>
        <PrivateAssetSummaryGrid selectedRefId={selectedRefId} />
      </GridWrapper>
    );
  }

  throw new Error('unhandled block type in privates block');
};

export default React.memo(function PrivatesBlockMemoized(props: CustomBlockProps) {
  return (
    <React.Suspense fallback={<BlockSuspenseFallback />}>
      <ErrorMultiWrapper
        blockId={props.selectedRefId}
        wrappers={[UnexpectedErrorWrapper, SubjectsErrorWrapper, DateRangeErrorWrapper, InfoGraphicTypeErrorWrapper]}
      >
        <PrivatesBlock {...props} />
      </ErrorMultiWrapper>
    </React.Suspense>
  );
});
