import React from 'react';
import { noop } from 'lodash';
import type { FactorLensWithReturns } from 'venn-api';

export interface FactorLensesContextValue {
  factorLenses?: FactorLensWithReturns[];
  primaryFactorLens?: FactorLensWithReturns;
  loading: boolean;
  refresh: () => void;
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
const factorLensesContext = React.createContext<FactorLensesContextValue>({
  factorLenses: undefined,
  primaryFactorLens: undefined,
  loading: false,
  refresh: noop,
  helpArticles: [],
} as FactorLensesContextValue);

export default factorLensesContext;
