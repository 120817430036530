import type { Portfolio } from 'venn-api';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import type { AllocConstraint, FactorExposureConstraint } from 'venn-utils';
import { flattenNode, parseConstraintsToPolicy, updateUrlParam, usePortfolioPolicy } from 'venn-utils';
import { compact, isEqual, isNil } from 'lodash';
import type { PortfolioConstraintsState } from 'venn-components';
import type { NewOpportunitiesProps } from './useSelectedPortfolio';
import type { PortfolioLabStoredSettings } from './useValuesFromHistoryState';
import { useHistory } from 'react-router-dom';

const useConstraints = (
  portfolio: Portfolio | undefined,
  newOpportunitiesProps: NewOpportunitiesProps | undefined,
  settings: PortfolioLabStoredSettings,
  storeSettings: (values: Partial<PortfolioLabStoredSettings>) => void,
): PortfolioConstraintsState => {
  const [allocationConstraints, setAllocationConstraintsInState] = useState<AllocConstraint[]>();
  const [factorConstraints, setFactorConstraintsInState] = useState<FactorExposureConstraint[]>();
  const constraintsPortfolioIdRef = useRef<number | undefined>(portfolio?.id);

  const newOpportunitiesConstraint: AllocConstraint | undefined = useMemo(() => {
    if (isNil(portfolio) || isNil(newOpportunitiesProps) || isNil(newOpportunitiesProps.maxAllocation)) {
      return undefined;
    }
    const newFundNodes = flattenNode(portfolio).filter((item) => !isNil(item.fund) && item.draft);
    return !newFundNodes.length
      ? undefined
      : {
          condition: 'maxAllocation',
          valueType: 'fixedValue',
          value: newOpportunitiesProps.maxAllocation,
          allInvestments: false,
          isInPortfolioPolicy: false,
          items: newFundNodes.map((newFundNode) => ({
            allocation: 0,
            fund: newFundNode.fund,
            id: newFundNode.id,
            name: newFundNode.fund!.name,
            draft: newFundNode.draft,
          })),
          isNew: true,
        };
  }, [portfolio, newOpportunitiesProps]);

  const history = useHistory();
  const setAllocationConstraints = useCallback(
    (constraints: AllocConstraint[]) => {
      setAllocationConstraintsInState(constraints);
      storeSettings({ allocationConstraints: constraints.filter((constraint) => !constraint.isNew) });
    },
    [storeSettings],
  );

  const setFactorConstraints = useCallback(
    (constraints: FactorExposureConstraint[]) => {
      setFactorConstraintsInState(constraints);
      storeSettings({ factorConstraints: constraints });
    },
    [storeSettings],
  );

  const {
    portfolioPolicy,
    loadingPolicy,
    policyFetchingError,
    policyAllocationConstraints,
    policyFactorConstraints,
    factorBreakdownData,
    onUpdateAllocationConstraints,
    onUpdateFactorConstraints,
  } = usePortfolioPolicy(portfolio, 'Portfolio Lab');

  useEffect(() => {
    if (isNil(portfolioPolicy)) {
      return;
    }
    if (isNil(allocationConstraints) || constraintsPortfolioIdRef.current !== portfolioPolicy.portfolioId) {
      // Restore allocation constraints from local state, if present
      if (settings.portfolioId === portfolioPolicy.portfolioId && !isNil(settings.allocationConstraints)) {
        setAllocationConstraints(
          compact(fixConstraintsRemovedFromPolicy(policyAllocationConstraints, settings.allocationConstraints)),
        );
      } else {
        setAllocationConstraints(compact(policyAllocationConstraints));
      }
    }
    if (isNil(factorConstraints) || constraintsPortfolioIdRef.current !== portfolioPolicy.portfolioId) {
      if (settings.portfolioId === portfolioPolicy.portfolioId && !isNil(settings.factorConstraints)) {
        setFactorConstraints(fixConstraintsRemovedFromPolicy(policyFactorConstraints, settings.factorConstraints));
      } else {
        setFactorConstraints([...policyFactorConstraints]);
      }
    }
    if (constraintsPortfolioIdRef.current !== portfolioPolicy.portfolioId) {
      constraintsPortfolioIdRef.current = portfolioPolicy.portfolioId;
    }
  }, [
    portfolio,
    portfolioPolicy,
    allocationConstraints,
    policyAllocationConstraints,
    factorConstraints,
    policyFactorConstraints,
    settings.portfolioId,
    settings.allocationConstraints,
    settings.factorConstraints,
    setAllocationConstraints,
    setFactorConstraints,
  ]);

  const onApplyAllocationConstraints = useCallback(
    (constraints: AllocConstraint[]) => {
      if (isNil(portfolioPolicy) || isNil(allocationConstraints)) {
        return;
      }
      onUpdateAllocationConstraints(constraints.filter((constraint) => !constraint.isNew));
      setAllocationConstraints(constraints);
    },
    [allocationConstraints, onUpdateAllocationConstraints, portfolioPolicy, setAllocationConstraints],
  );

  const onApplyFactorConstraint = useCallback(
    (constraint: FactorExposureConstraint) => {
      if (isNil(portfolioPolicy) || isNil(factorConstraints)) {
        return;
      }
      const updatedConstraints = policyFactorConstraints.filter(
        (item) => item.factorId !== constraint.factorId || item.condition !== constraint.condition,
      );
      onUpdateFactorConstraints([...updatedConstraints, constraint]);

      const constraintIdx = factorConstraints.indexOf(constraint);
      if (constraintIdx !== -1) {
        const updatedConstraints = [...factorConstraints];
        updatedConstraints[constraintIdx] = {
          ...updatedConstraints[constraintIdx],
          isInPortfolioPolicy: true,
        };
        setFactorConstraints(updatedConstraints);
      }
    },
    [factorConstraints, policyFactorConstraints, onUpdateFactorConstraints, portfolioPolicy, setFactorConstraints],
  );

  const onOverrideFactorPolicy = useCallback(
    (constraints: FactorExposureConstraint[]) => {
      onUpdateFactorConstraints(constraints);
      setFactorConstraints(constraints);
    },
    [onUpdateFactorConstraints, setFactorConstraints],
  );

  const onResetToPolicyAllocationConstraints = useCallback(() => {
    setAllocationConstraints(policyAllocationConstraints);
  }, [policyAllocationConstraints, setAllocationConstraints]);

  const onResetToPolicyFactorConstraints = useCallback(
    () => setFactorConstraints(policyFactorConstraints),
    [setFactorConstraints, policyFactorConstraints],
  );

  const allAllocationConstraints = useMemo(() => {
    const cleanConstraints = adjustTargetsOfNewOpportunities(allocationConstraints ?? [], portfolio);
    return compact([...cleanConstraints, newOpportunitiesConstraint]);
  }, [allocationConstraints, newOpportunitiesConstraint, portfolio]);

  const currentPolicyWithConstraints = useMemo(
    () =>
      isNil(portfolio)
        ? undefined
        : parseConstraintsToPolicy(allAllocationConstraints, factorConstraints, portfolioPolicy, portfolio, false),
    [allAllocationConstraints, factorConstraints, portfolioPolicy, portfolio],
  );

  const updateAllocationConstraints = useCallback(
    (constraints: AllocConstraint[]) => {
      const newConstraintIdx = constraints.findIndex((constraint) => constraint.isNew);
      const newConstraint = newConstraintIdx === -1 ? undefined : constraints[newConstraintIdx];

      if (newOpportunitiesProps?.maxAllocation !== newConstraint?.value) {
        // If the constraint for the new funds (coming from the library selection) was modified, update it in query params
        updateUrlParam(
          history,
          'REPLACE',
          'maxAllocation',
          isNil(newConstraint?.value) ? undefined : newConstraint.value.toString(),
        );
      }
      setAllocationConstraints(constraints.filter((constraint) => !constraint.isNew));
    },
    [newOpportunitiesProps?.maxAllocation, history, setAllocationConstraints],
  );

  return {
    policy: portfolioPolicy,
    loadingPolicy,
    policyError: policyFetchingError,
    currentPolicyWithConstraints,

    factorBreakdownData,

    policyAllocationConstraints,
    allocationConstraints: allAllocationConstraints,
    onUpdateAllocationConstraints: updateAllocationConstraints,
    onApplyAllocationConstraintsToPolicy: onApplyAllocationConstraints,
    onResetToPolicyAllocationConstraints,

    policyFactorConstraints,
    factorConstraints: factorConstraints ?? [],
    onUpdateFactorConstraints: setFactorConstraints,
    onApplyFactorConstraintToPolicy: onApplyFactorConstraint,
    onOverrideFactorPolicy,
    onResetToPolicyFactorConstraints,
  };
};

export default useConstraints;

// If any stored constraints that WERE in policy AT THE TIME THEY WERE STORED have been REMOVED from the policy SINCE
// THEN, mark them as not present in policy (anymore)
const fixConstraintsRemovedFromPolicy = <T extends { isInPortfolioPolicy: boolean }>(
  policyConstraints: T[],
  storedConstraints: T[],
) =>
  storedConstraints.map((storedConstraint: T) =>
    !storedConstraint.isInPortfolioPolicy ||
    policyConstraints.find((policyConstraint) => isEqual(policyConstraint, storedConstraint))
      ? storedConstraint
      : { ...storedConstraint, isInPortfolioPolicy: false },
  );

export const adjustTargetsOfNewOpportunities = (
  constraints: AllocConstraint[],
  portfolio: Portfolio | undefined,
): AllocConstraint[] => {
  if (isNil(portfolio) || constraints.length === 0) {
    return [];
  }
  const draftNodes = flattenNode(portfolio).filter((node) => node.draft);
  return constraints.map((constraint) => {
    if (constraint.allInvestments) {
      return constraint;
    }
    const updatedItems = constraint.allInvestments
      ? constraint.items
      : compact(
          constraint.items.map((item) => {
            if (!item.draft || isNil(item.fund)) {
              return item;
            }
            const itemNode = draftNodes.find((node) => node.fund?.id === item.fund!.id);
            if (isNil(itemNode)) {
              return null;
            }
            return { ...item, id: itemNode.id };
          }),
        );
    return {
      ...constraint,
      items: updatedItems,
    };
  });
};
