import type { Portfolio } from 'venn-api';
import type { CapitalEditMode, RedistributionMode } from './types';
import { getStrategyWithAddedCapital } from './editCapitalUtils';
import { flattenNode } from 'venn-utils';
import { isNil, minBy } from 'lodash';
import { useMemo } from 'react';

type UseStrategyWithModifiedCapitalValue =
  | { draftStrategy: Portfolio; error?: undefined }
  | { draftStrategy?: undefined; error: string };

const useStrategyWithModifiedCapital = (
  strategy: Portfolio,
  editMode: CapitalEditMode,
  amount: number,
  redistributionMode: RedistributionMode,
): UseStrategyWithModifiedCapitalValue =>
  useMemo(() => {
    // Adding capital never results in an error
    if (editMode === 'add') {
      return {
        draftStrategy: getStrategyWithAddedCapital(strategy, amount, redistributionMode),
        error: undefined,
      };
    }

    const totalAllocation = strategy.allocation ?? 0;

    // Reducing capital proportionately
    if (redistributionMode === 'prorata') {
      if (amount > totalAllocation) {
        return {
          draftStrategy: undefined,
          error: `Attempting to reduce capital by more than is currently allocated. The maximum you can reduce by is $${totalAllocation}.`,
        };
      }

      return {
        draftStrategy: getStrategyWithAddedCapital(strategy, -amount, 'prorata'),
        error: undefined,
      };
    }

    // Reducing capital evenly
    const allChildFunds: Portfolio[] = flattenNode(strategy).filter((node) => !isNil(node.fund));
    const minChildFundAllocation: number = minBy(allChildFunds, 'allocation')?.allocation ?? 0;
    const perChildReductionValue: number = amount / allChildFunds.length;

    // Cannot reduce each by more than the allocation of the smallest fund in this strategy subtree
    if (minChildFundAllocation < perChildReductionValue) {
      return {
        draftStrategy: undefined,
        error:
          allChildFunds.length === 1
            ? `Attempting to reduce the allocation of 1 investment by $${totalAllocation}, but it is currently allocated less than that. The maximum you can reduce by is $${minChildFundAllocation}.`
            : `Attempting to reduce the allocation of ${
                allChildFunds.length
              } investments by $${perChildReductionValue} each, but some of them are currently allocated less than that. The maximum you can reduce by is $${minChildFundAllocation} per investment ($${
                minChildFundAllocation * allChildFunds.length
              } total).`,
      };
    }

    return {
      draftStrategy: getStrategyWithAddedCapital(strategy, -amount, 'evenly'),
      error: undefined,
    };
  }, [strategy, editMode, amount, redistributionMode]);

export default useStrategyWithModifiedCapital;
