import { createBrowserHistory } from 'history';
import { UnsavedChangesModal } from 'venn-components';

const getUserConfirmation = (message: string, callback: (ok: boolean) => void) => {
  const { userConfirmation } = UnsavedChangesModal;
  if (userConfirmation) {
    userConfirmation(message, callback);
  }
};
export const history = createBrowserHistory({ basename: process.env.PUBLIC_URL, getUserConfirmation });
