import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { getPrivatePortfolio } from 'venn-api';
import { allocatorAnalysisSubject, openPrivateAllocatorConfig, openPrivateAllocatorPortfolioAtom } from 'venn-state';
import type { StudioTemplateState } from 'venn-utils';
import { AnalysisSubject, logExceptionIntoSentry } from 'venn-utils';

export const useSyncPrivateAllocatorConfigToView = () => {
  const location = useLocation();
  const privateAllocatorConfig = useRecoilValue(openPrivateAllocatorConfig);
  const setOpenPrivateAllocatorPortfolio = useSetRecoilState(openPrivateAllocatorPortfolioAtom);
  const [analysisSubject, setAllocatorAnalysisSubject] = useRecoilState(
    allocatorAnalysisSubject(privateAllocatorConfig),
  );
  const hasInitialized = useRef(false);

  useEffect(() => {
    if (hasInitialized.current || !privateAllocatorConfig || analysisSubject) {
      return;
    }

    const portfolioInLocationState = (location.state as StudioTemplateState)?.openAllocatorForPrivatePortfolio;

    if (portfolioInLocationState) {
      return;
    }

    hasInitialized.current = true;

    (async () => {
      try {
        const portfolio = (await getPrivatePortfolio(privateAllocatorConfig.privatePortfolioId)).content;
        setOpenPrivateAllocatorPortfolio(portfolio);
        setAllocatorAnalysisSubject(new AnalysisSubject(portfolio, 'private-portfolio'));
      } catch (e) {
        logExceptionIntoSentry(e);
      }
    })();
  }, [
    analysisSubject,
    location.state,
    privateAllocatorConfig,
    setAllocatorAnalysisSubject,
    setOpenPrivateAllocatorPortfolio,
  ]);
};
