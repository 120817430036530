import type { MutableRefObject } from 'react';

/**
 * Helper function such that the return value of this function can be directly assigned
 * as the ref prop of a React component.
 * @param refs the refs that will end up pointing to the React component
 */
export default function mergeRefs<T>(...refs: (MutableRefObject<T | null> | undefined)[]) {
  const filteredRefs = refs.filter(Boolean) as MutableRefObject<T | null>[];
  if (!filteredRefs.length) return undefined;
  if (filteredRefs.length === 1) return filteredRefs[0];

  return (element: T | null) => {
    for (const ref of filteredRefs) {
      ref.current = element;
    }
  };
}
