import { atom, selector } from 'recoil';
import type { UserCookiePolicyStatus } from 'venn-api';
import { getUserAcceptCookiePolicy, updateUserAcceptCookiePolicy } from 'venn-api';
import { isRequestSuccessful, logMessageToSentry } from 'venn-utils';

export const userIsAuthenticated = atom<boolean>({
  key: 'AuthStatus',
  default: false,
});

const noCookiesResponse = {
  setCookiePolicy: false,
  consentedCookieTypes: [],
};

export const cookiePolicy = selector<UserCookiePolicyStatus>({
  key: 'cookiePolicy',
  get: async () => {
    try {
      const result = await getUserAcceptCookiePolicy();
      if (isRequestSuccessful(result)) {
        return result.content;
      }
      return noCookiesResponse;
    } catch (e) {
      logMessageToSentry('Unable to retrieve cookie policy.');
      return noCookiesResponse;
    }
  },
  set: async () => {
    await updateUserAcceptCookiePolicy();
  },
});
