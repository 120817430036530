import type { LibrarySearchResult, OperationResult, SearchQuery } from 'venn-api';
import type { PrivatesSearchParams } from 'venn-state';
import { analyticsService, getQueryParamsFromFilters, LibraryItemType } from 'venn-utils';
import { PRIVATES_PAGE_SIZE } from '../utils';

/**
 * Get the API library search query params based on the selected filters and SearchParams kept in the URL.
 */
export const getPrivatesSearchQueryParams = (params: PrivatesSearchParams): Partial<SearchQuery> => {
  const apiParamsFromFilters = getQueryParamsFromFilters({
    quickFilters: params.filters ?? [],
    tags: [],
    itemType: params.itemType ?? LibraryItemType.ALL,
  });

  return {
    ...apiParamsFromFilters,
    ...params,
    pageSize: params?.pageSize ?? PRIVATES_PAGE_SIZE,
    // this function should never be called for non-private assets
    privateAssetSearchMode: 'PRIVATES_ONLY',
  };
};

export const searchPrivates = async (
  params: PrivatesSearchParams,
  searchApi: (query: Partial<SearchQuery>) => Promise<OperationResult<LibrarySearchResult>>,
) => {
  const { name, filters = [], itemType = LibraryItemType.ALL } = params;

  const apiQueryParams = getPrivatesSearchQueryParams(params);
  const request = await searchApi(apiQueryParams);
  analyticsService.searchQueryEntered({
    totalResults: request.content.total,
    visibleResults: request.content.results.length,
    query: name ?? '',
    location: 'private-library',
    quickFilters: filters,
    tagFilters: [],
    typeFilters: [itemType],
    currencyFilters: [],
    dataSourceFilters: [],
    metricFilters: [],
    assetTypeFilters: [],
  });

  return request;
};
