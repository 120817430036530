/*
 * Venn Platform API 1.0.0 Operations for "notableperiods"
 * Automatically generated file - do not modify!
 */

import type { NotablePeriod } from '../types';
import type { OperationResult } from '../../utils';
import { buildRequestInit, extractError, retryGeneratedFetch } from '../../utils';

/** Retrieve list of notable periods */
export async function getPredefinedNotablePeriods(signal?: AbortSignal): Promise<OperationResult<NotablePeriod[]>> {
  const endpoint = '/api/notableperiods/predefined';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: NotablePeriod[] = (await response.json()) as NotablePeriod[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
