import type { FrequencyEnum } from 'venn-api';

export const getYAxisLabel = (frequency: FrequencyEnum): string => {
  switch (frequency) {
    case 'DAILY':
      return 'days';
    case 'MONTHLY':
      return 'mos.';
    case 'YEARLY':
      return 'years';
    case 'QUARTERLY':
      return 'quarters';
    default:
      return 'mos.';
  }
};
