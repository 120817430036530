import React, { Suspense } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { forceZeroResidualForecastSelector, setForceZeroResidualForecastRecoilCallback } from 'venn-state';
import { Body1, GetColor, Headline3, ShimmerBlock, Subtitle1 } from 'venn-ui-kit';
import SliderToggle from '../../../slider-toggle/SliderToggle';

export const ResidualForecastOverridePanel = ({ isReadOnly }: { isReadOnly: boolean }) => {
  const forceZeroResidualForecast = useRecoilValue(forceZeroResidualForecastSelector);
  const setForceZeroResidualForecast = useRecoilCallback(setForceZeroResidualForecastRecoilCallback);

  return (
    <Wrapper forceZeroResidualForecast={forceZeroResidualForecast} id="residual-forecast-override-panel">
      <Body1 style={{ display: 'flex', flexDirection: 'row' }}>
        <Suspense fallback={<ShimmerBlock />}>
          <Headline3>
            {forceZeroResidualForecast ? 'All Residual Forecasts Set to 0%' : 'Set Residual Forecast to 0%'}
          </Headline3>
          <span style={{ paddingLeft: 24 }} />
          <SliderToggle
            disabled={isReadOnly}
            toggled={forceZeroResidualForecast}
            onToggle={() => setForceZeroResidualForecast(!forceZeroResidualForecast)}
          />
        </Suspense>
      </Body1>
      {forceZeroResidualForecast ? (
        <StyledSubtitle>
          You have set the Residual Forecast returns for all investments in the workspace to "0%," unless overridden
          below. You can select additional investments to override above. You can also toggle this setting off to revert
          back to the default Residual Forecasts. Note: any overrides currently set up below will <b>not</b> be affected
          when this setting is toggled off.
        </StyledSubtitle>
      ) : (
        <StyledSubtitle>
          Set the Residual Forecasts of all investments in the workspace to "0%". Note: Any <i>existing overrides</i>{' '}
          set by members of your workspace will <b>not</b> be set to zero and you and other members of your workspace
          may continue to add new overrides even after this setting is enabled.
        </StyledSubtitle>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<{
  forceZeroResidualForecast: boolean;
}>`
  border-top: 1px solid ${GetColor.HighlightDark};
  padding: 16px;
  background: ${({ forceZeroResidualForecast, theme }) =>
    forceZeroResidualForecast ? theme.Colors.HighlightLightBackground : theme.Colors.PaleGrey};
`;

const StyledSubtitle = styled(Subtitle1)`
  line-height: 25px;
`;
