const rollingData = [
  {
    subject: {
      rolling: [
        [1572566399999, 0.36438351672834235],
        [1575158399999, 0.8557926405564424],
        [1577836799999, 1.1378208527872844],
        [1580515199999, 0.914299422588601],
        [1583020799999, 0.3367294903561355],
      ],
      annualized: 0.7605628264428655,
    },
    benchmark: {
      rolling: [
        [1572566399999, -0.05181724679536165],
        [1575158399999, 0.536465656190984],
        [1577836799999, 1.5994399327491549],
        [1580515199999, 0.9240276272707797],
        [1583020799999, -0.16808407999414657],
      ],
      annualized: 1.1947668370721822,
    },
  },
  {
    subject: {
      rolling: [
        [1572566399999, 0.105517738575362],
        [1575158399999, 0.2066859604754968],
        [1577836799999, 0.22222516638210613],
        [1580515199999, 0.1265243095476528],
        [1583020799999, -0.023729724143335594],
      ],
      annualized: 0.7605628264428655,
    },
    benchmark: {
      rolling: [
        [1572566399999, 0.39670156845509874],
        [1575158399999, 0.5133648493410834],
        [1577836799999, 0.5425617439404703],
        [1580515199999, 0.368585905379216],
        [1583020799999, 0.1560282178689749],
      ],
      annualized: 1.1947668370721822,
    },
  },
  {
    subject: {
      rolling: [
        [1572566399999, 0.041459771379878586],
        [1575158399999, -0.041860547816348],
        [1577836799999, 0.03369164117793869],
        [1580515199999, -0.03441949508099474],
        [1583020799999, -0.12879208087626368],
      ],
      annualized: 0.7605628264428655,
    },
    benchmark: {
      rolling: [
        [1572566399999, 0.3475513509580877],
        [1575158399999, 0.3427464197696204],
        [1577836799999, 0.91316291984322896],
        [1580515199999, 0.45496405563635545],
        [1583020799999, 0.29786183253405885],
      ],
      annualized: 1.1947668370721822,
    },
  },
];

export default rollingData;
