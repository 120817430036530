import React from 'react';
import styled from 'styled-components';
import type { ShimmerProps } from 'venn-ui-kit';
import { GetColor, Shimmer } from 'venn-ui-kit';

export const AllocationShimmer = () => (
  <div>
    <Line key={0}>
      <ShimmerLine duration={3} />
      <IdxSeparator />
      <SubjectSeparator left={65} />
    </Line>
    <Line key={1}>
      <ShimmerLine duration={3} />
      <IdxSeparator />
      <SubjectSeparator left={125} />
    </Line>
    <Line key={2}>
      <ShimmerLine duration={3} />
      <IdxSeparator />
      <SubjectSeparator left={90} />
    </Line>
  </div>
);

export const FactorShimmer = () => (
  <div>
    <Line key={0}>
      <ShimmerLine key={0} duration={3} />
      <SubjectSeparator left={40} />
    </Line>
    <Line key={1}>
      <ShimmerLine key={1} duration={3} />
      <SubjectSeparator left={50} />
    </Line>
    <Line key={2}>
      <ShimmerLine key={2} duration={3} />
      <SubjectSeparator left={85} />
    </Line>
  </div>
);

const Line = styled.div`
  position: relative;
`;

const Separator = styled.div`
  height: 20px;
  width: 5px;
  background-color: ${GetColor.White};
  position: absolute;
  top: 0;
`;

const IdxSeparator = styled(Separator)`
  left: 20px;
`;

const SubjectSeparator = styled(Separator)<{ left: number }>`
  left: ${({ left }) => left}px;
`;

const ShimmerLine = styled.div<ShimmerProps>`
  height: 20px;
  width: 500px;
  margin-bottom: 5px;
  ${Shimmer};
`;
