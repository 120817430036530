import { atom, useResetRecoilState } from 'recoil';
import type { CashFlowSetting } from 'venn-api';
import { useCallback } from 'react';

export const cashflowSettingsModalIsEditing = atom<boolean>({
  key: 'cashflowSettingsModalIsEditing',
  default: false,
});

export const cashflowSettingSelectedToActivateNext = atom<string | undefined>({
  key: 'cashflowSettingSelectedToActivateNext',
  default: undefined,
});

export const cashflowModalActiveSettingIdAtom = atom<string | undefined>({
  key: 'cashflowModalActiveSettingId',
  default: undefined,
});

export const editedCashflowSettingAtom = atom<CashFlowSetting | undefined>({
  key: 'editedCashflowSettingAtom',
  default: undefined,
});

/**
 * Function to exit cashflow settings modal, dropping all changes
 */
export const useResetCashflowSettingsModalState = () => {
  const resetCashflowSettingsModalIsEditing = useResetRecoilState(cashflowSettingsModalIsEditing);
  const resetCashflowSettingSelectedToActivateNext = useResetRecoilState(cashflowSettingSelectedToActivateNext);
  const resetEditedCashflowSettingAtom = useResetRecoilState(editedCashflowSettingAtom);
  const resetCashflowModalActiveSettingIdAtom = useResetRecoilState(cashflowModalActiveSettingIdAtom);

  return useCallback(() => {
    resetCashflowSettingsModalIsEditing();
    resetEditedCashflowSettingAtom();
    resetCashflowSettingSelectedToActivateNext();
    resetCashflowModalActiveSettingIdAtom();
  }, [
    resetCashflowSettingsModalIsEditing,
    resetCashflowSettingSelectedToActivateNext,
    resetEditedCashflowSettingAtom,
    resetCashflowModalActiveSettingIdAtom,
  ]);
};
