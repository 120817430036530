import type { FC } from 'react';
import React, { useContext, useState, useCallback } from 'react';
import styled, { css, ThemeContext } from 'styled-components';
import { Icon, GetColor, CellLoader } from 'venn-ui-kit';
import type { PortfolioPerformanceAttribution } from 'venn-api';
import { HIDE_FORECASTS_IN_EXPORTS_KEY } from 'venn-api';
import { SpecialCssClasses } from 'venn-utils';
import { OptimalPortfolioContext, ContributionPopover, UserContext } from 'venn-components';
import type { Metric } from '../../types';
import type { Cell as TableCell } from './types';
import { Cell } from './Table';
import MissedTargetIcon from './MissedTargetIcon';

interface HoverProps {
  metric: Metric;
  cell: TableCell;
  subjectName?: string;
  forecast?: boolean;
  centered?: boolean;
  loading?: boolean;
  isAnalyzingStrategy?: boolean;
  highlighted?: boolean;
  print: boolean | undefined;
  onClick: (attributions: PortfolioPerformanceAttribution, metric: Metric, metricValue: number | undefined) => void;
}

interface CellProps {
  forecast?: boolean;
  centered?: boolean;
  contributions: boolean;
  cumulative: boolean;
}

const ValueCell: FC<React.PropsWithChildren<HoverProps>> = ({
  metric,
  cell,
  subjectName,
  forecast,
  centered,
  onClick,
  loading,
  isAnalyzingStrategy,
  highlighted,
  print,
}) => {
  const { Colors } = useContext(ThemeContext);
  const { missedTarget } = useContext(OptimalPortfolioContext);
  const { settings } = useContext(UserContext);
  const [hover, setHover] = useState(false);
  const [childHover, setChildHover] = useState<boolean>(false);
  const shouldShowContributions =
    metric.contributionsTooltip &&
    !!cell.value &&
    cell.contributions &&
    !!(cell.contributions.funds && cell.contributions.funds.length > 1);
  const showMissedTarget =
    !isAnalyzingStrategy &&
    missedTarget &&
    forecast &&
    metric.name === missedTarget.targetMetric &&
    subjectName === 'Optimized Portfolio';
  const showPopup = useCallback(() => setHover(true), []);
  const hidePopup = useCallback(() => setHover(false), []);
  const hide = !!settings?.user?.[HIDE_FORECASTS_IN_EXPORTS_KEY] && !!forecast;
  return (
    <CellInner
      onClick={() => onClick && shouldShowContributions && onClick(cell.contributions, metric, cell.value)}
      onMouseEnter={showPopup}
      onMouseLeave={hidePopup}
      forecast={forecast}
      centered={centered}
      contributions={shouldShowContributions}
      cumulative={metric.cumulative}
      style={{
        padding: print ? '3px 4px' : '9px 10px',
        ...(hide ? { color: 'transparent' } : {}),
      }}
      className={hide ? (print ? SpecialCssClasses.HiddenInDownloadable : SpecialCssClasses.HideInReports) : undefined}
    >
      {!forecast && !loading ? (
        <HighlightableText highlighted={highlighted}>{metric.formatter(cell.value)}</HighlightableText>
      ) : null}{' '}
      {shouldShowContributions && !childHover ? (
        <>
          <Ico type="expand-alt" />
          <ContributionPopover
            popoverPosition={forecast ? 'left' : 'right'}
            visible={hover}
            subjectName={subjectName}
            value={cell.value}
            timeframe={forecast ? 'Forecast' : 'Historical'}
            data={cell.contributions.funds}
            metricName={metric.name}
            abbreviatedMetricName={metric.abbreviatedName}
            formatter={metric.formatter}
            dataItemLabel="Investment"
            onHover={hidePopup}
          />
        </>
      ) : null}
      {forecast && !loading ? (
        <>
          <HighlightableText highlighted={highlighted}>{metric.formatter(cell.value)}</HighlightableText>
          {showMissedTarget && (
            <MissedTargetIcon
              color={Colors.Error}
              name={missedTarget.targetMetric}
              value={missedTarget.value}
              formatter={metric.formatter}
              onMouseEnter={() => setChildHover(true)}
              onMouseLeave={() => setChildHover(false)}
              position="right"
            />
          )}
        </>
      ) : null}
      {loading && <CellLoader />}
    </CellInner>
  );
};

const Ico = styled(Icon)``;

const CellInner = styled(Cell)<CellProps>`
  position: relative;
  text-align: ${(props) => (props.centered ? 'center' : props.forecast ? 'left' : 'right')};
  font-style: ${(props) => (props.cumulative && !props.forecast ? 'italic' : 'normal')};
  animation: fadein 2s;
  min-width: 90px;

  .${SpecialCssClasses.ExportAsImage} & {
    animation: none;
  }

  @media print {
    animation: none;
  }

  ${Ico} {
    display: none;
    position: absolute;
    right: ${(props) => (!props.forecast ? 'unset' : '10px')};
    left: ${(props) => (!props.forecast ? '10px' : 'unset')};
    top: 10px;
  }

  ${(props) =>
    props.contributions
      ? css`
          &:hover {
            ${Ico} {
              display: block;
            }

            background-color: ${GetColor.DEPRECATED_DivergingColor.B2};
            cursor: pointer;
          }
        `
      : ''}
`;

const HighlightableText = styled.span<{ highlighted?: boolean }>`
  ${({ highlighted }) =>
    highlighted &&
    css`
      color: ${GetColor.HighlightDark};
    `}
`;

export default ValueCell;
