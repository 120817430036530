import React, { useEffect, useState } from 'react';
import AddeparStep1 from './AddeparStep1';
import AddeparStep2 from './AddeparStep2';
import styled, { css } from 'styled-components';
import { Body1, Button, GetColor, Headline2, ShimmerBlock, Stepper, ZIndex } from 'venn-ui-kit';
import { STEPS } from './steps';
import AddeparStep3 from './AddeparStep3';
import type { AddeparViewConfigurationMainState } from './useAddeparViewConfiguration';
import { StickyNode } from 'venn-components';
import AddeparStep4 from './AddeparStep4';
import AddeparStep5 from './AddeparStep5';

interface AddeparPanelContentProps extends AddeparViewConfigurationMainState {
  step: number;
  isAuthorizing: boolean;
  addeparFirmBaseUrl: string | undefined;
  addeparFirmId: string | undefined;
  /** The selector for the scrollable container of this component */
  containerSelector: string;
}

const getMainContent = (
  step: number,
  addeparFirmBaseUrl: string | undefined,
  addeparFirmId: string | undefined,
  viewConfigState: AddeparViewConfigurationMainState,
) => {
  switch (step) {
    case 0:
      return <AddeparStep1 />;
    case 1:
      return <AddeparStep2 />;
    case 2:
      return <AddeparStep3 addeparFirmBaseUrl={addeparFirmBaseUrl!} {...viewConfigState} />;
    case 3:
      if (!addeparFirmId) {
        throw new Error('Missing firmId in Addepar Step 4');
      }
      return <AddeparStep4 firmId={addeparFirmId} views={viewConfigState.views} />;
    case 4:
      return <AddeparStep5 />;
    default:
      return null;
  }
};

const AddeparPanelContent = ({
  step,
  isAuthorizing,
  addeparFirmBaseUrl,
  addeparFirmId,
  containerSelector,
  ...viewConfigState
}: AddeparPanelContentProps) => {
  const [isScrolled, setScrolled] = useState(false);
  useEffect(() => {
    const scrollContainer = document.querySelector(containerSelector);
    const handleScroll = () => {
      window.requestAnimationFrame(() => {
        const scrollTop = scrollContainer?.scrollTop ?? 0;
        setScrolled(scrollTop > 0);
      });
    };
    window.addEventListener('scroll', handleScroll, true);
    return () => window.removeEventListener('scroll', handleScroll, true);
  }, [containerSelector]);

  const bottomMargin = step === 0 ? 40 : 24;
  return (
    <ContentContainer bottomMargin={bottomMargin}>
      <StickyHeader shadow={isScrolled} bottomMargin={bottomMargin}>
        <StepperContainer>
          <Stepper currentStep={step} steps={STEPS} stepWidth={270} />
        </StepperContainer>
        {!isAuthorizing && (
          <HeaderContainer>
            <div>
              <Headline2>{STEPS[step].label}</Headline2>
              <Body1>{STEPS[step].description}</Body1>
            </div>
            {step === 2 && (
              <div>
                <Button
                  icon="sync"
                  dense
                  disabled={viewConfigState.isRefreshing}
                  onClick={viewConfigState.refreshOptions}
                >
                  {viewConfigState.isRefreshing ? 'Refreshing Data...' : 'Refresh Data'}
                </Button>
              </div>
            )}
          </HeaderContainer>
        )}
      </StickyHeader>
      {isAuthorizing ? (
        <ShimmerContainer>
          <ShimmerBlock height={20} width="50%" />
          <ShimmerBlock height={20} width="100%" />
          <ShimmerBlock width="100%" />
        </ShimmerContainer>
      ) : (
        <MainContent>{getMainContent(step, addeparFirmBaseUrl, addeparFirmId, viewConfigState)}</MainContent>
      )}
    </ContentContainer>
  );
};

export default AddeparPanelContent;

const ContentContainer = styled.div<{ bottomMargin: number }>`
  & > * {
    margin-bottom: ${({ bottomMargin }) => bottomMargin}px;
  }
  display: flex;
  flex-direction: column;
`;

const StickyHeader = styled(StickyNode)<{ shadow?: boolean; bottomMargin: number }>`
  background-color: ${GetColor.White};
  ${({ shadow, bottomMargin }) =>
    shadow
      ? css`
          box-shadow: 0 0 ${10 + bottomMargin}px -10px  rgba(0, 0, 0, 0.17)};
        `
      : ''}
  transition: box-shadow 0.5s;
  z-index: ${ZIndex.Sticky};
`;

const ShimmerContainer = styled.div`
  height: calc(100% - 200px);
`;

const StepperContainer = styled.div`
  margin-bottom: 60px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const MainContent = styled.div`
  height: 100%;
`;
