import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import type { UserProfileSettings } from 'venn-api';
import { removeUserFromMyWorkspace } from 'venn-api';
import { StyledWrapper, FlexWrapper, InfoWrapper, Name, Info, FlexRow } from './shared';
import { ConfirmationModal, UserContext, InitialAvatar } from 'venn-components';
import { Button, Icon } from 'venn-ui-kit';
import { logExceptionIntoSentry } from 'venn-utils';

interface CompanyInfoProps {
  profileSettings: UserProfileSettings;
  invitedUsers: number;
  activeUsers: number;
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default ({ activeUsers, profileSettings, invitedUsers }: CompanyInfoProps) => {
  const { organizations: workspaces } = useContext(UserContext);
  const { organization, user } = profileSettings;
  const { name, accountAdministrator } = organization;
  const activeUserText = activeUsers === 1 ? 'user' : 'users';
  const invitedMemberText = invitedUsers === 1 ? 'user' : 'users';
  const adminText = accountAdministrator ? `${accountAdministrator} is the account admin.` : '';
  const info = `${name} has ${activeUsers} active ${activeUserText} and ${invitedUsers} invited ${invitedMemberText}. ${adminText}`;

  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const onCloseModal = () => {
    setShowConfirmationModal(false);
    setError(false);
  };

  const leaveWorkspaceDisabled = !workspaces || workspaces.length < 2;

  const onLeaveOrganization = async () => {
    if (leaveWorkspaceDisabled) {
      onCloseModal();
      return;
    }
    try {
      await removeUserFromMyWorkspace(user.id);
    } catch (e) {
      setError(true);
      logExceptionIntoSentry(e, {
        context: `User with id ${user.id} failed to leave organization "${name}" (${organization.id})`,
      });
      return;
    }
    onCloseModal();
    window.location.reload();
  };

  return (
    <StyledWrapper>
      <FlexWrapper>
        <AvatarWrapper>
          <InitialAvatar displayName={name} width={100} />
        </AvatarWrapper>
        <Wrapper>
          <FlexRow>
            <Name>{name}</Name>
            {!leaveWorkspaceDisabled && (
              <LeaveButton onClick={() => setShowConfirmationModal(true)} disabled={leaveWorkspaceDisabled}>
                <LargeIcon type="sign-out" />
                Leave workspace
              </LeaveButton>
            )}
          </FlexRow>
          <StyledInfo>{info}</StyledInfo>
        </Wrapper>
      </FlexWrapper>
      {showConfirmationModal && (
        <ConfirmationModal
          destructive
          subhead={`Are you sure you want to leave ${name}?`}
          text="You won't be able to access this workspace unless one of your teammates invites you again in the future."
          onCancel={onCloseModal}
          onProceed={onLeaveOrganization}
          error={error ? 'Failed to perform action.' : undefined}
        />
      )}
    </StyledWrapper>
  );
};

const StyledInfo = styled(Info)`
  margin-top: 10px;
`;

const Wrapper = styled(InfoWrapper)`
  width: 100%;
`;

const AvatarWrapper = styled.div`
  min-width: 100px;
`;

const LeaveButton = styled(Button)`
  & > div {
    display: flex;
    align-items: center;
  }
  margin-right: 0;
`;

const LargeIcon = styled(Icon)`
  margin-right: 4px;
  font-size: 16px;
`;
