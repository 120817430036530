import styled, { css } from 'styled-components';
import React, { useCallback, useState } from 'react';
import type { AccordionMenuProps } from './types';
import Icon from '../icon/Icon';
import { GetColor } from '../../style/color';
import { isNil } from 'lodash';

const ItemContentWrapper = styled.div<{ level: number; bold: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-right: 10px;
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  padding-left: ${(props) => 5 + props.level * 10}px;
  color: ${GetColor.Black};
`;

const ItemContentWrapperButton = styled.button<{ level: number; bold: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
  padding-right: 10px;
  font-weight: ${(props) => (props.bold ? 'bold' : 'unset')};
  padding-left: ${(props) => 5 + (props.level ?? 0) * 10}px;
  color: ${GetColor.Black};
  text-align: left;
`;

const Caret = styled(Icon)<{ studioStyle: boolean }>`
  color: ${(props) => (props.studioStyle ? GetColor.DarkGrey : GetColor.Primary.Dark)};
  ${(props) =>
    props.studioStyle &&
    css`
      font-size: 13px;
    `};
`;

const AccordionMenuBodyWrapper = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
`;
interface NoRendererProps<T> extends AccordionMenuProps<T> {
  onClick: () => void;
}
interface RendererProps<T> extends AccordionMenuProps<T> {
  onCaretClick: () => void;
}
function AccordionInternalComponentWithoutRenderer<T>(props: NoRendererProps<T>) {
  const { items, value, label, expanded, level = 0, onClick, studioStyle = false } = props;
  const caretType = studioStyle ? (expanded ? 'angle-up' : 'angle-down') : expanded ? 'caret-up' : 'caret-down';
  return (
    <ItemContentWrapperButton
      className="accordion-content-wrapper-btn"
      data-testid="qa-accordion-body"
      bold={!!items}
      level={level}
      onClick={onClick}
    >
      <div>{label || value}</div> {items && <Caret studioStyle={studioStyle} prefix="fas" type={caretType} />}{' '}
    </ItemContentWrapperButton>
  );
}
function AccordionInternalComponentWithRenderer<T>(props: RendererProps<T>) {
  const {
    items,
    value,
    expanded,
    onSelect,
    selectedId,
    idGetter,
    level,
    ItemRenderer,
    onCaretClick,
    studioStyle = false,
  } = props;
  const caretType = studioStyle ? (expanded ? 'angle-up' : 'angle-down') : expanded ? 'caret-up' : 'caret-down';
  return (
    <ItemContentWrapper data-testid="qa-accordion-body" bold={!!items} level={level ?? 0}>
      {ItemRenderer ? (
        <ItemRenderer
          value={value!}
          expanded={expanded}
          onSelect={onSelect}
          selected={isNil(idGetter) || isNil(selectedId) ? false : idGetter(value!) === selectedId}
        />
      ) : null}
      {items && <Caret onClick={onCaretClick} studioStyle={studioStyle} type={caretType} className="caret-button" />}{' '}
    </ItemContentWrapper>
  );
}

function AccordionMenu<T>(props: AccordionMenuProps<T>) {
  const { items, value, onSelect, selectedId, idGetter, expanded, level, ItemRenderer, studioStyle = false } = props;
  const [isExpanded, setExpanded] = useState<boolean>(!!expanded);
  const onClick = !items && value ? () => onSelect(value) : () => setExpanded(!isExpanded);

  const onClickWhenRenderer = useCallback(() => {
    // the renderer should handle the clicks on its own
    setExpanded(!isExpanded);
  }, [isExpanded]);

  return (
    <AccordionMenuBodyWrapper>
      {value && ItemRenderer && (
        <AccordionInternalComponentWithRenderer {...props} expanded={isExpanded} onCaretClick={onClickWhenRenderer} />
      )}
      {value && !ItemRenderer && (
        <AccordionInternalComponentWithoutRenderer {...props} expanded={isExpanded} onClick={onClick} />
      )}
      {isExpanded &&
        items?.map((item) => (
          <AccordionMenu
            key={idGetter ? idGetter(item.value!) : item.label}
            selectedId={selectedId}
            idGetter={idGetter}
            ItemRenderer={ItemRenderer}
            onSelect={onSelect}
            {...item}
            level={(level ?? 0) + 1}
            studioStyle={studioStyle}
          />
        ))}
    </AccordionMenuBodyWrapper>
  );
}

export default AccordionMenu;
