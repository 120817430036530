import React, { useContext, useMemo, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { GetColor, GetScheme, LoadingSize, Spinner } from 'venn-ui-kit';
import type { DataPoint } from 'venn-utils';
import {
  Dates,
  FREQUENCY_TO_ENUM,
  getFormattedProxyType,
  isRequestSuccessful,
  Numbers,
  ReturnsCalculations,
} from 'venn-utils';
import type { LegendSeries } from '../../legend/Legend';
import { Legend } from '../../legend/Legend';
import type { FrequencyEnum, ProxyMetrics } from 'venn-api';
import { investmentProxyMetrics } from 'venn-api';
import Chart from './ProxyInfoChart';
import InterpolationSnapshot from './InterpolationSnapshot';
import type { FundProxy } from '../pickers/types';
import { compact } from 'lodash';
import { useQuery } from '@tanstack/react-query';

interface ProxyInfoProps {
  investmentId: string;
  investmentLastUpdated: number;
  proxy: FundProxy;
  investmentFrequency: number;
}

const getDate = (start: number, end: number, frequency: FrequencyEnum) => {
  return `${Dates.toDDMMMYYYY(start, frequency)} - ${Dates.toDDMMMYYYY(end, frequency)}`;
};

const extractProxiedReturns = (
  proxiedInvestment: DataPoint[],
  proxyStartDate?: number,
  proxyEndDate?: number,
  extrapolateStartDate?: number,
) => {
  const extrapolated: DataPoint[] = [];
  const proxy: DataPoint[] = [];

  proxiedInvestment.forEach((point) => {
    if (extrapolateStartDate && point.x >= extrapolateStartDate) {
      extrapolated.push(point);
    } else if (proxyStartDate && proxyEndDate && proxyStartDate <= point.x && point.x <= proxyEndDate) {
      proxy.push(point);
    }
  });

  return [proxy, extrapolated];
};
const ProxyInfo: React.FC<React.PropsWithChildren<ProxyInfoProps>> = ({
  investmentId,
  investmentLastUpdated,
  proxy,
  investmentFrequency,
}) => {
  const [error, setError] = useState<string | null>(null);
  const { Schemes } = useContext(ThemeContext);

  const { data: suggestion, status } = useQuery<ProxyMetrics | null>(
    ['proxyMetrics', investmentId, investmentLastUpdated, proxy.id, proxy.proxyType, proxy.numLags, proxy.extrapolate],
    async () => {
      try {
        const result = await investmentProxyMetrics(investmentId);
        if (isRequestSuccessful(result)) {
          setError(null);
          return result.content;
        }
        setError('Proxy fetch failed');
        return null;
      } catch (e) {
        if (e.name !== 'AbortError') {
          setError(e.content?.message || 'Proxy fetch failed');
        }
        return null;
      }
    },
  );

  const extrapolate = !!(suggestion?.extrapolatedStart && suggestion?.extrapolatedEnd);
  const [proxyReturns, extrapolatedReturns] = useMemo(() => {
    const allTogether = suggestion?.cumulativeProxiedReturns
      ? ReturnsCalculations.toDataPoints(suggestion.cumulativeProxiedReturns)
      : [];
    return extractProxiedReturns(
      allTogether,
      suggestion?.proxiedStart,
      suggestion?.proxiedEnd,
      suggestion?.extrapolatedStart,
    );
  }, [suggestion]);
  const unproxiedInvestmentReturns = ReturnsCalculations.toDataPoints(suggestion?.investmentReturns ?? []);

  if (error) {
    return <p>{error}</p>;
  }
  if (status === 'loading' || !suggestion) {
    return <Spinner size={LoadingSize.medium} />;
  }

  const legend: LegendSeries[] = compact([
    proxyReturns.length > 0 ? { color: Schemes.Proxy.proxyLine, name: 'Proxy' } : null,
    unproxiedInvestmentReturns.length > 0 ? { color: Schemes.Proxy.invertedSubjectLine, name: 'Investment' } : null,
    extrapolatedReturns.length > 0 ? { color: Schemes.Proxy.extrapolation, name: 'Extrapolation' } : null,
  ]);

  const { name, proxyType } = proxy;
  return (
    <Container>
      {name && (
        <Stat>
          <Title>Proxy:</Title>
          <HighlightedValue>{name}</HighlightedValue>
        </Stat>
      )}
      {proxyType && (
        <Stat>
          <Title>Proxy Type:</Title>
          <HighlightedValue>{getFormattedProxyType(proxyType)}</HighlightedValue>
        </Stat>
      )}
      {extrapolate && proxyType !== 'EXTRAPOLATE' && (
        <Stat>
          <Title>Extrapolation:</Title>
          <HighlightedValue>On</HighlightedValue>
        </Stat>
      )}
      <Stats>
        <Stat>
          <Title>Proxy Range:</Title>
          {suggestion.proxiedStart && suggestion.proxiedEnd && (
            <HighlightedValue>
              {getDate(suggestion.proxiedStart, suggestion.proxiedEnd, FREQUENCY_TO_ENUM[investmentFrequency])}
            </HighlightedValue>
          )}
          {extrapolate && (
            <HighlightedValue>
              {getDate(
                suggestion.extrapolatedStart!,
                suggestion.extrapolatedEnd!,
                FREQUENCY_TO_ENUM[investmentFrequency],
              )}
            </HighlightedValue>
          )}
        </Stat>
        <Stat>
          <Title>Correlation:</Title>
          <Value>{Numbers.formatCorrelation(suggestion.correlation)}</Value>
        </Stat>
      </Stats>
      {/* TODO: unify the two charts below into one chart */}
      {unproxiedInvestmentReturns && proxyReturns && suggestion.proxyType && (
        <>
          <ChartContainer>
            {proxyType === 'INTERPOLATE' ? (
              <InterpolationSnapshot
                primarySeries={unproxiedInvestmentReturns}
                overlaySeries={proxyReturns}
                extrapolateSeries={extrapolatedReturns}
                height={54}
                invert
              />
            ) : (
              <Chart
                investment={unproxiedInvestmentReturns}
                proxy={proxyReturns ?? []}
                extrapolation={extrapolatedReturns ?? []}
                proxyEnd={suggestion.proxiedEnd}
                proxyType={suggestion.proxyType}
              />
            )}
          </ChartContainer>
          <LegendContainer>
            <Legend className="proxy-info-modal-legend" series={legend} />
          </LegendContainer>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  color: ${GetColor.White};
  width: 300px;
  padding: 15px 20px 0 20px;
`;

const Stats = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Stat = styled.div`
  display: flex;
  flex-direction: column;
  &:first-child {
    margin-right: 20px;
  }
`;

const Title = styled.div`
  font-size: 10px;
  font-weight: bold;
`;

const Value = styled.div`
  font-size: 14px;
  padding: 3px 0 4px;
`;

const HighlightedValue = styled(Value)`
  color: ${GetScheme.Proxy.proxyLine};
`;

const ChartContainer = styled.div`
  margin-top: 14px;
`;

const LegendContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  border-top: 1px solid ${GetColor.DarkGrey};
  padding-top: 5px;
  margin-top: 10px;
  font-size: 1rem;
  font-weight: normal;
  color: ${GetColor.Grey};
  .proxy-info-modal-legend {
    flex-wrap: nowrap;
  }
  .legend-entry-indicator-wrapper {
    width: 11px;
    margin-right: 4px;
  }
`;

export default ProxyInfo;
