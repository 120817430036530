import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Headline1, Icon, GetColor, getAppTitle } from 'venn-ui-kit';
import { Info } from '../account-modals/shared';

export class TwoFactorEmpty extends PureComponent {
  render() {
    return (
      <>
        <StyledHeader>Protect your account. Turn on Two Factor Authentication.</StyledHeader>
        <Info>
          {`Each time you sign in to your ${getAppTitle()} account you will be prompted to provide both your password and your
          secondary authentication code.`}
        </Info>
        <Content>
          <MainRow>
            <MainColumn>
              <Row>
                <Column>
                  <BigIcon>
                    <StyledIcon type="chess" prefix="fas" />
                  </BigIcon>
                </Column>
                <Column>
                  <TinyHeader>An extra layer of security…</TinyHeader>
                  <StyledParagraph>
                    Use SMS, an authenticator app, or backup codes to increase account security so you and your company
                    know your data is safe.
                  </StyledParagraph>
                </Column>
              </Row>
            </MainColumn>
            <MainColumn>
              <Row>
                <Column>
                  <BigIcon>
                    <StyledIcon type="user-lock" prefix="fas" />
                  </BigIcon>
                </Column>
                <Column>
                  <TinyHeader>that keeps your account safe…</TinyHeader>
                  <StyledParagraph>
                    Passwords can be compromised. A secondary level of security ensures that only you have access to
                    your account.
                  </StyledParagraph>
                </Column>
              </Row>
            </MainColumn>
            <MainColumn>
              <Row>
                <Column>
                  <BigIcon>
                    <StyledIcon type="check-circle" prefix="fas" />
                  </BigIcon>
                </Column>
                <Column>
                  <TinyHeader>and keeps your sign in quick and simple.</TinyHeader>
                  <StyledParagraph>
                    Your secondary authentication codes are easy to access and will keep your sign efficient and secure.
                  </StyledParagraph>
                </Column>
              </Row>
            </MainColumn>
          </MainRow>
        </Content>
      </>
    );
  }
}

export default TwoFactorEmpty;

const Content = styled.div`
  padding: 60px 0 15px;
`;

const StyledHeader = styled(Headline1)`
  font-size: 32px;
  font-weight: 300;
  margin-bottom: 22px;
`;

const MainRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding-right: 15px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  &:first-of-type {
    flex-basis: 15%;
  }
  &:last-of-type {
    flex-basis: 60%;
  }
`;

const StyledParagraph = styled.p`
  font-size: 14px;
  color: ${GetColor.Black};
  line-height: 1.43;
  margin: 0;
`;

const TinyHeader = styled.h3`
  font-size: 12px;
  font-weight: bold;
  color: ${GetColor.Black};
`;

const MainColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
`;

const BigIcon = styled.div`
  height: 95px;
  width: 95px;
  background-color: ${GetColor.Black};
  margin-right: 5px;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  font-size: 30px;
  width: 30px;
  height: 30px;
  color: ${GetColor.White};
`;
