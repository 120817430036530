import React from 'react';
import {
  AllUploadIcons,
  ExcelAndCsvIcon,
  FilesTrifecta,
  OldUploaderBackground,
  OnlyExcel,
  OnlyExcelIcon,
} from './Icons';
import { Body1, getAppTitle, Headline2 } from 'venn-ui-kit';
import { BrowseButton } from './Buttons';
import { DataUploaderMode } from '../types';

interface FilesLayoutProps {
  mode: DataUploaderMode;
  onFileChange: (data: File) => void;
  setError: (error: string | undefined) => void;
  isUniversalUploader?: boolean;
}

const FilesLayout = ({ mode, isUniversalUploader, onFileChange, setError }: FilesLayoutProps) => {
  const secondaryMsg =
    mode === DataUploaderMode.Privates
      ? `${getAppTitle()} supports Excel files for Private Asset Data`
      : mode === DataUploaderMode.Portfolios
        ? 'Venn supports Excel and .csv files for portfolio uploads'
        : `${getAppTitle()} supports almost any data layout!`;
  return (
    <>
      {isUniversalUploader ? (
        <>
          {mode === DataUploaderMode.Privates && <OnlyExcelIcon />}
          {mode === DataUploaderMode.Portfolios && <ExcelAndCsvIcon />}
          {mode === DataUploaderMode.Returns && <AllUploadIcons />}
        </>
      ) : (
        <>
          <OldUploaderBackground />
          {mode !== DataUploaderMode.Privates && <FilesTrifecta />}
          {mode === DataUploaderMode.Privates && <OnlyExcel />}
        </>
      )}

      <Headline2 className="files-title">Drag and drop files here</Headline2>
      <Body1>{secondaryMsg}</Body1>
      <div>
        <BrowseButton onFileChange={onFileChange} setError={setError} />
      </div>
    </>
  );
};

export default FilesLayout;
