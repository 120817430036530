const fundData = {
  fileId: 48857,
  name: 'blob',
  columns: [
    {
      seriesId: 'bd76e590-77e8-4822-a0ad-475fcf5c188b',
      name: 'Demo Investment',
      origin: {
        rowIndex: 0,
        colIndex: 1,
      },
      fundId: null,
      portfolioNodeId: null,
      fundName: 'Demo Investment',
      isPercent: true,
      strategyName: null,
      currency: 'USD',
      periodReturn: 0.009532351700241914,
      periodAnnualized: true,
      annualizedReturn: 0.009532351700241914,
      annualizedVolatility: 0.11073907154754871,
      cumulativeReturn: [
        [1425167999999, 0],
        [1427846399999, -0.019100000000000006],
        [1430438399999, -0.03175360999999999],
        [1433116799999, -0.018198160540000008],
        [1438387199999, -0.02379682662141025],
        [1441065599999, -0.028287361218951812],
        [1443657599999, -0.021096687691972038],
        [1448927999999, 0.0010888833071833748],
        [1451606399999, -0.0041167788860140275],
        [1454284799999, -0.00421636720812546],
        [1456790399999, -0.014771673715719302],
        [1462060799999, 0.009491268411752296],
        [1464739199999, -0.011708048224894463],
        [1467331199999, -0.021986284523355604],
        [1472687999999, -0.0676479551338608],
        [1475279999999, -0.030260638134728568],
        [1477958399999, -0.010187033344117413],
        [1483228799999, -0.02210841989955814],
        [1485907199999, 0.010748737191816682],
        [1488326399999, 0.024191695396467816],
        [1491004799999, 0.04375375677854043],
        [1496275199999, 0.11129827714125051],
        [1498867199999, 0.12074431249695117],
        [1501545599999, 0.16837594577807158],
        [1506815999999, 0.19239233395888045],
        [1509494399999, 0.17295633891535078],
        [1512086399999, 0.1649802358107264],
        [1517443199999, 0.21919878877903298],
        [1519862399999, 0.2301715778780442],
        [1522540799999, 0.23423114408504175],
        [1525132799999, 0.23139241245364617],
        [1530403199999, 0.2444725212511354],
        [1533081599999, 0.26425963433902844],
        [1535759999999, 0.20926434024528073],
        [1541030399999, 0.24531916118186392],
        [1543622399999, 0.23871896962759998],
        [1546300799999, 0.19722188414507547],
        [1551398399999, 0.1839425983116092],
        [1554076799999, 0.19021749408266064],
        [1556668799999, 0.0547707432560538],
        [1559347199999, 0.0068841515122288754],
        [1564617599999, 0.05086221321380613],
        [1567295999999, 0.061896266452551085],
        [1569887999999, 0.04012739299027368],
        [1575158399999, -0.007161541275976235],
        [1577836799999, 0.03562979629502916],
        [1580515199999, 0.04836804278945794],
        [1585699199999, 0.04940845371880265],
      ],
      recentMonthlyReturns: null,
      startOfNewData: 1425167999999,
      newDataCount: 61,
      startOfNeededForFactorAnalysis: 1491004799999,
      needsBackfill: false,
      newNav: null,
      oldNav: null,
      typeId: 3,
      frequencyId: 2,
      metricId: 1,
      appendId: null,
      dateFormatId: 1,
    },
  ],
};
export default fundData;
