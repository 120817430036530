import { CircularBuffer } from 'venn-utils';
import type { AtomEffect } from 'recoil';

export type Action = {
  undo: () => void;
  redo: () => void;
};

/** Syncs the atom to a global history stack (RecoilHistory)
 *  Stores an undo action restoring the atom to it's previous value, and redo action restoring the atom to it's current
 *  value. */
export class RecoilHistory {
  private readonly redoStack: CircularBuffer<Action>;

  private readonly undoStack: CircularBuffer<Action>;

  constructor(private readonly sizeLimit: number) {
    this.redoStack = new CircularBuffer<Action>(this.sizeLimit);
    this.undoStack = new CircularBuffer<Action>(this.sizeLimit);
  }

  public add(action: Action) {
    this.undoStack.push(action);
  }

  public undo() {
    const undoAction = this.undoStack.pop();
    undoAction?.undo();
    undoAction && this.redoStack.push(undoAction);
  }

  public redo() {
    const redoAction = this.redoStack.pop();
    redoAction?.redo();
    redoAction && this.undoStack.push(redoAction);
  }
}

export type HistoryEffectParams<T> = Pick<Parameters<AtomEffect<T>>[0], 'setSelf' | 'onSet'>;

export const studioRecoilHistory = new RecoilHistory(10);
export const recoilHistoryEffect = <T>({ setSelf, onSet }: HistoryEffectParams<T>) => {
  onSet((newValue, oldValue) => {
    studioRecoilHistory.add({
      redo: () => {
        setSelf(newValue);
      },
      undo: () => {
        setSelf(oldValue);
      },
    });
  });
};
