/* eslint-disable:max-line-length */
import React from 'react';

const SuccessIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24" data-testid="success-icon">
    <g fill="#009650" fillRule="evenodd">
      <path
        fillRule="nonzero"
        d="M2.5 12c0 5.79 4.706 10.5 10.5 10.5 5.79 0 10.5-4.706 10.5-10.5 0-5.79-4.706-10.5-10.5-10.5C7.21 1.5 2.5 6.206 2.5 12zM1 12C1 5.373 6.386 0 13 0c6.627 0 12 5.386 12 12 0 6.627-5.386 12-12 12-6.627 0-12-5.386-12-12z"
      />
      <path d="M11.607 16.91c-.06.06-.13.09-.208.09a.267.267 0 0 1-.197-.079l-4.123-4.28A.267.267 0 0 1 7 12.443c0-.079.03-.148.09-.208l1.506-1.506a.28.28 0 0 1 .202-.09c.075 0 .142.034.202.102l2.393 2.516 5.09-5.168A.25.25 0 0 1 16.68 8c.078 0 .148.026.208.079l1.472 1.483a.267.267 0 0 1 .078.196c0 .08-.03.148-.09.208l-6.741 6.944z" />
    </g>
  </svg>
);

export default SuccessIcon;
