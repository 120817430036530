import React from 'react';

const HeatmapIcon = () => (
  <svg viewBox="0 0 45 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="22.4023" width="11.3952" height="6.81824" fill="#3E9397" />
    <rect x="22.4023" y="6.8183" width="11.3952" height="6.81824" fill="#8CC8CC" />
    <rect x="22.4023" y="13.6365" width="11.3952" height="6.81824" fill="#CAE4E6" />
    <rect width="11.3952" height="6.81824" fill="#B7793A" />
    <rect y="6.8183" width="11.3952" height="6.81824" fill="#EEDDC7" />
    <rect y="13.6365" width="11.3952" height="6.81824" fill="#DCB887" />
    <rect x="33.6055" width="11.3952" height="6.81824" fill="#CAE4E6" />
    <rect x="33.6055" y="6.8183" width="11.3952" height="6.81824" fill="#3E9397" />
    <rect x="33.6055" y="13.6365" width="11.3952" height="6.81824" fill="#00818A" />
    <rect x="11.2012" width="11.3952" height="6.81824" fill="#DCB887" />
    <rect x="11.2012" y="6.8183" width="11.3952" height="6.81824" fill="#B7793A" />
    <rect x="11.2012" y="13.6365" width="11.3952" height="6.81824" fill="#EEDDC7" />
  </svg>
);

export default HeatmapIcon;
