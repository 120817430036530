import type { ReactNode } from 'react';
import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { GetColor, Icon } from 'venn-ui-kit';
import { getPortfolioLabColors } from '../../logic/getPortfolioLabColors';

interface ChartLegendProps {
  hasOptimized?: boolean;
  hasBenchmark?: boolean;
  rightChildren?: ReactNode;
}

const ChartLegend = ({ hasOptimized, hasBenchmark, rightChildren }: ChartLegendProps) => {
  const theme = useContext(ThemeContext);
  const plColors = getPortfolioLabColors(theme);
  return (
    <Legend>
      <Items>
        <LegendItem iconColor={plColors.current}>
          <Icon type="map-marker" />
          Current
        </LegendItem>
        {hasOptimized && (
          <>
            <LegendItem iconColor={plColors.optimized}>
              <Icon type="flask" />
              Optimized
            </LegendItem>
            <LegendItem iconColor={plColors.alternate}>
              <Icon type="circle" />
              Alternate
            </LegendItem>
          </>
        )}
        {hasBenchmark && (
          <LegendItem iconColor={plColors.benchmark}>
            <Icon type="arrows-h" />
            Benchmark
          </LegendItem>
        )}
      </Items>
      <div>{rightChildren}</div>
    </Legend>
  );
};

export default ChartLegend;

const Legend = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  border-top: 1px solid ${GetColor.PaleGrey};
  border-bottom: 1px solid ${GetColor.PaleGrey};
  padding: 0 20px;
`;

const Items = styled.div`
  display: flex;
`;

const LegendItem = styled.div<{ iconColor: string }>`
  font-size: 10px;
  font-weight: bold;
  i {
    color: ${({ iconColor }) => iconColor};
    margin-right: 4px;
  }
  &:not(:last-child) {
    margin-right: 6px;
  }
`;
