import type { SideMenuItem } from '../side-menu/SideMenu';

export const domain = window.document.location.hostname.replace('app.', '');

export const getDate90DaysFromToday = (): Date => {
  const future = new Date();
  future.setDate(future.getDate() + 90);
  return future;
};

export type CookieSection = 'required' | 'functional' | 'advertising';
export const SideMenuItems: SideMenuItem<CookieSection>[] = [
  {
    label: 'Required Cookies',
    value: 'required',
  },
  {
    label: 'Functional Cookies',
    value: 'functional',
  },
  {
    label: 'Advertising Cookies',
    value: 'advertising',
  },
];
