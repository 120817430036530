import React, { useContext } from 'react';
import type { Portfolio, AnalysisPortfolioComparisonTypeEnum } from 'venn-api';
import { buildQuery, getSpecificPortfolioV3 } from 'venn-api';
import type { AnalysisSubject } from 'venn-utils';
import { normalizePortfolio } from 'venn-utils';
import { useHistory } from 'react-router-dom';
import type { CompareType, QueryParamCompareType } from 'venn-components';
import { PortfoliosContext, OptimalPortfolioContext, ComparisonContext, AnalysisViewContext } from 'venn-components';
import type { History } from 'history';
import { isEqual, isNil } from 'lodash';
import queryString from 'query-string';

interface ComparisonContextStoreProps {
  optimizationId?: string;
  analysisSubject: AnalysisSubject | undefined;
  children: React.ReactNode;
}

interface ComparisonContextStorePropsWithAddOns
  extends ComparisonContextStoreProps,
    WithOptimizationProps,
    WithMasterPortfolioProps,
    WithAnalysisViewProps {
  history: History;
}

interface ComparisonContextStoreState {
  compareType: CompareType;
  comparePortfolio?: Portfolio;
  compareLoading: boolean;
}

class ComparisonContextStore extends React.PureComponent<
  ComparisonContextStorePropsWithAddOns,
  ComparisonContextStoreState
> {
  state: ComparisonContextStoreState = {
    compareType: 'None' as CompareType,
    comparePortfolio: undefined,
    compareLoading: false,
  };

  static getDerivedStateFromProps(
    nextProps: ComparisonContextStorePropsWithAddOns,
    prevState: ComparisonContextStoreState,
  ) {
    const { compareType, compareLoading, comparePortfolio } = prevState;
    const {
      master,
      masterLoading,
      optimized,
      optimalLoading,
      compare: compareFromQueryParam,
      canSeeOptimal,
    } = nextProps;

    if (!compareFromQueryParam) {
      return null;
    }

    if (compareFromQueryParam !== compareType && compareFromQueryParam === 'Master' && !masterLoading && !master) {
      // Intentionally don't update anything if we need to automatically swap from 'Master' to 'None'.
      // The discrepancy between `compareType` and `compareFromQueryParam` will be picked up by `componentDidUpdate`,
      // the query params will be updated, which will trigger another `getDerivedStateFromProps`
      return null;
    }

    if (compareFromQueryParam === 'Optimized' && !canSeeOptimal) {
      // Intentionally don't update anything if we need to automatically swap from 'Optimized' to 'None'.
      return null;
    }

    if (compareFromQueryParam === 'Last Saved' || compareFromQueryParam === 'None') {
      return compareFromQueryParam !== compareType ? { compareType: compareFromQueryParam } : null;
    }

    if (compareFromQueryParam !== compareType) {
      const loading =
        (compareFromQueryParam === 'Optimized' && optimalLoading) ||
        (compareFromQueryParam === 'Master' && masterLoading);
      const portfolio = loading ? null : compareFromQueryParam === 'Optimized' ? optimized : master;

      return { compareType: compareFromQueryParam, compareLoading: loading, comparePortfolio: portfolio };
    }

    if (
      compareFromQueryParam === 'Optimized' &&
      (compareLoading !== optimalLoading || !isEqual(optimized, comparePortfolio))
    ) {
      return { compareLoading: optimalLoading, comparePortfolio: optimized };
    }

    if (compareFromQueryParam === 'Master' && compareLoading !== masterLoading) {
      return { compareLoading: masterLoading, comparePortfolio: master };
    }

    return null;
  }

  componentDidMount() {
    const { analysisSubject, compare, onUpdateCompareType } = this.props;
    if (analysisSubject?.portfolio) {
      this.initializeComparison();
    }

    if (analysisSubject && !compare && analysisSubject?.superType === 'portfolio') {
      onUpdateCompareType?.('master');
    }
  }

  componentDidUpdate(prevProps: ComparisonContextStoreProps) {
    const {
      analysisSubject,
      compare: compareFromQueryParam,
      masterLoading,
      master,
      canSeeOptimal,
      onUpdateCompareType,
    } = this.props;
    const { compareType } = this.state;

    const portfolio = analysisSubject?.portfolio;
    const prevPortfolio = prevProps?.analysisSubject?.portfolio;
    if (!prevPortfolio && portfolio) {
      this.initializeComparison();
    }

    if (compareType !== compareFromQueryParam && compareFromQueryParam === 'Master' && !masterLoading && !master) {
      onUpdateCompareType?.('none');
    }

    if (compareFromQueryParam === 'Optimized' && !canSeeOptimal) {
      onUpdateCompareType?.('none');
    }
  }

  initializeComparison = () => {
    const {
      compare: compareFromQueryParam,
      compareVersion: compareVersionFromQueryParam,
      analysisSubject,
      history,
      optimizationId,
      onUpdateCompareType,
    } = this.props;
    const currentPortfolio = analysisSubject?.portfolio;

    let comparePortfolioValue;
    switch (compareFromQueryParam) {
      case 'Last Saved':
        comparePortfolioValue = !isNil(currentPortfolio)
          ? {
              id: currentPortfolio.id,
              version: compareVersionFromQueryParam,
              children: [],
              demo: false,
              draft: true,
              master: currentPortfolio.master,
              name: currentPortfolio.name,
            }
          : analysisSubject?.portfolio;
        break;
      default:
        break;
    }

    const isMaster = analysisSubject?.master;
    const updatedCompareType =
      isMaster && (!compareFromQueryParam || compareFromQueryParam === 'Master') ? 'None' : compareFromQueryParam;

    this.updateCompareType(updatedCompareType, comparePortfolioValue, false);

    if (optimizationId) {
      replaceSearchParam('optimizationId', undefined, history);
    }

    if (isMaster && !compareFromQueryParam) {
      onUpdateCompareType?.('none');
    }
  };

  loadSavedVersion = async (id: number, version?: number) => {
    this.setState({ compareLoading: true, comparePortfolio: undefined });
    try {
      const { content } = await getSpecificPortfolioV3(id, version);
      this.setState({ compareLoading: false, comparePortfolio: content });
    } catch (e) {
      if (e.name !== 'AbortError') {
        this.setState({ compareLoading: false, comparePortfolio: undefined });
      }
    }
  };

  updateCompareType = (
    updatedCompareType: CompareType,
    customComparePortfolio?: Portfolio,
    skipOptimization?: boolean,
  ) => {
    const { optimizeAdHoc, onUpdateCompareType } = this.props;
    let version;

    if (!skipOptimization && updatedCompareType === 'Optimized') {
      optimizeAdHoc?.();
    }
    if (updatedCompareType === 'Last Saved' && !isNil(customComparePortfolio)) {
      if (
        !this.state.compareLoading &&
        (this.state.comparePortfolio?.id !== customComparePortfolio?.id ||
          this.state.comparePortfolio?.version !== customComparePortfolio?.version)
      ) {
        this.loadSavedVersion(customComparePortfolio.id, customComparePortfolio.version);
      }
      version = customComparePortfolio.version;
    }
    if (customComparePortfolio !== undefined || updatedCompareType === 'None') {
      this.setState({ comparePortfolio: customComparePortfolio });
    }

    onUpdateCompareType?.(toQueryStringCompareType(updatedCompareType), version);
  };

  render() {
    const { compareType, comparePortfolio, compareLoading } = this.state;

    return (
      <ComparisonContext.Provider
        value={{ compareType, comparePortfolio, compareLoading, updateCompareType: this.updateCompareType }}
      >
        {this.props.children}
      </ComparisonContext.Provider>
    );
  }
}

interface WithOptimizationProps {
  canSeeOptimal?: boolean;
  optimized?: Portfolio;
  optimalLoading?: boolean;
  optimizeAdHoc?: () => void;
}

interface WithMasterPortfolioProps {
  master?: Portfolio;
  masterLoading?: boolean;
}

interface WithAnalysisViewProps {
  compare?: CompareType;
  compareVersion?: number;
  onUpdateCompareType?: (compare: QueryParamCompareType, version?: number) => void;
}

const ComparisonContextStoreWithContexts = (props: ComparisonContextStoreProps) => {
  const {
    hasAccessToOptimization,
    optimalPortfolio,
    loading: optimalPortfolioLoading,
    optimizeAdHoc,
  } = useContext(OptimalPortfolioContext);
  const { masterPortfolio, loading } = useContext(PortfoliosContext);
  const { compare, compareVersion, onUpdateAnalysisViewParam } = useContext(AnalysisViewContext);
  const history = useHistory();

  return (
    <ComparisonContextStore
      {...props}
      canSeeOptimal={hasAccessToOptimization}
      optimized={optimalPortfolio ? normalizePortfolio(optimalPortfolio) : undefined}
      optimalLoading={optimalPortfolioLoading}
      optimizeAdHoc={optimizeAdHoc}
      master={masterPortfolio ? normalizePortfolio(masterPortfolio) : undefined}
      masterLoading={loading}
      compare={toCompareType(compare ?? 'none')}
      compareVersion={compareVersion}
      onUpdateCompareType={(updatedCompare: QueryParamCompareType, updatedCompareVersion?: number) =>
        onUpdateAnalysisViewParam({ compare: updatedCompare, compareVersion: updatedCompareVersion })
      }
      history={history}
    />
  );
};

export default ComparisonContextStoreWithContexts;

function replaceSearchParam(key: string, value: QueryParamCompareType | undefined, history: History) {
  const path = history?.location?.pathname;
  const params = queryString.parse(history?.location?.search?.replace('?', ''));
  const queryParams = buildQuery({
    ...params,
    [key]: value,
  });
  if (path && queryParams) {
    history.replace(`${path}${queryParams}`, history.location.state);
  }
}

export function toCompareType(propsCompareType: string | string[] | undefined): CompareType | undefined {
  if (propsCompareType === undefined) {
    return undefined;
  }

  if (Array.isArray(propsCompareType)) {
    return undefined;
  }

  switch (propsCompareType) {
    case 'master':
      return 'Master';
    case 'saved':
      return 'Last Saved';
    case 'optimized':
      return 'Optimized';
    case 'none':
      return 'None';
    default:
      return undefined;
  }
}

export function fromPortfolioComparisonTypeEnum(
  compareType?: AnalysisPortfolioComparisonTypeEnum,
): QueryParamCompareType {
  switch (compareType) {
    case 'MASTER':
      return 'master';
    case 'SAVED':
      return 'saved';
    case 'OPTIMIZED':
      return 'optimized';
    case 'NONE':
    default:
      return 'none';
  }
}

function toQueryStringCompareType(compareType: CompareType): QueryParamCompareType {
  switch (compareType) {
    case 'Master':
      return 'master';
    case 'Last Saved':
      return 'saved';
    case 'Optimized':
      return 'optimized';
    case 'None':
    default:
      return 'none';
  }
}
