import type { ReturnsRangeInfo } from './utils';
import { getDisabledProxyTypeMessage } from './utils';
import type { FundToProxy } from 'venn-components';
import type { ProxyErrorEnum, ProxyOption, ProxyTypeEnum } from 'venn-api';
import { getCustomProxyOptionForFund } from 'venn-api';
import { useQuery } from '@tanstack/react-query';
import { isRequestSuccessful, logExceptionIntoSentry } from 'venn-utils';

type ProxyErrorProps = {
  investment: FundToProxy;
  selectedProxyId?: string;
  selectedProxyType?: ProxyTypeEnum;
  numLags?: number;
  extrapolate?: boolean;
  rawInvestmentReturnsRange?: ReturnsRangeInfo;
  rawProxyReturnsRange?: ReturnsRangeInfo;
};

export const getInvalidProxyMessage = (reason: ProxyErrorEnum | undefined): string | undefined => {
  if (!reason) {
    return undefined;
  }
  switch (reason) {
    case 'INVALID_FREQUENCY':
      return 'Unable to apply this proxy. Proxy frequency is invalid.';
    case 'BELOW_THRESHOLD':
      return 'Unable to apply this proxy. The proxy sits outside our recommended threshold for this investment.';
    case 'NO_OVERLAP':
      return 'Unable to apply this proxy. Your investment and the chosen proxy do not have any overlapping period.';
    case 'INVESTMENT_SERIES_TOO_SHORT':
      return 'Unable to proxy. Your investment does not have enough datapoints.';
    case 'PROXY_SERIES_TOO_SHORT':
      return 'Unable to apply this proxy. The proxy does not have enough datapoints.';
    case 'NON_RETURNS_SERIES':
      return 'Unable to apply this proxy. Proxying is only allowed for returns timeseries';
    case 'UNABLE_TO_DESMOOTH_NOT_ENOUGH_OVERLAP':
      return 'Unable to desmooth. There is not enough overlap between your investment and the chosen proxy.';
    case 'UNABLE_TO_DESMOOTH_INVALID_N_LAGS':
      return 'Unable to desmooth. Invalid number of lags.';
    case 'UNABLE_TO_EXTRAPOLATE_SERIES_TOO_SHORT':
      return (
        'This investment does not have enough history to enable extrapolation. ' +
        'Choose an investment with a longer history of returns data, or continue without extrapolation.'
      );
    case 'UNABLE_TO_EXTRAPOLATE_NOT_ENOUGH_OVERLAP':
      return (
        'This proxy does not have sufficient overlapping history to extrapolate the returns of the investment. ' +
        'Choose a proxy with a longer history of returns data, or continue without extrapolation.'
      );
    case 'UNABLE_TO_EXTRAPOLATE_PROXY_NOT_EXTENDING':
      return (
        'This proxy does not extend the returns of your investment. ' +
        'Choose a proxy with a more recent history of returns data, or continue without extrapolation.'
      );
    case 'UNABLE_TO_EXTRAPOLATE':
      return (
        'Unable to extrapolate. ' +
        'Choose a proxy with a longer history of returns data, or continue without extrapolation.'
      );
    default:
      return 'Unable to apply proxy.';
  }
};

type ErrorInfo = {
  /** Reason for disabling the proxy */
  errorMessage: string | undefined;
  /** Whether current state is invalid and unable to be saved */
  disableSave: boolean;
};

/** Custom hook for error-related logic for proxies. */
const useProxyErrorMessage = ({
  investment,
  selectedProxyId,
  selectedProxyType,
  numLags,
  extrapolate,
  rawInvestmentReturnsRange,
  rawProxyReturnsRange,
}: ProxyErrorProps): ErrorInfo => {
  /** A check for proxy validity performed by the FE only. This is a quick, simplified check that doesn't catch
   * all reasons why the proxy could be invalid, but good enough in most cases */
  const disabledProxyReason = selectedProxyType
    ? getDisabledProxyTypeMessage(selectedProxyType, investment, rawInvestmentReturnsRange, rawProxyReturnsRange)
    : undefined;
  /** A "perfect" check for proxy validity fetched from the BE trying to do the proxy operation itself.
   *  The downside is that it's slower to fetch, but if this check passes then it's guaranteed that proxy is valid. */
  const { data: proxyFeasibilityInfo, status } = useQuery<ProxyOption | null>(
    ['proxyFeasibility', investment.id, selectedProxyId, selectedProxyType, numLags, extrapolate],
    async () => {
      if (!selectedProxyId || !selectedProxyType) {
        return null;
      }
      const result = await getCustomProxyOptionForFund(
        investment.id,
        selectedProxyId,
        selectedProxyType,
        numLags,
        extrapolate,
      );
      if (isRequestSuccessful(result)) {
        return result.content;
      }
      logExceptionIntoSentry(
        `Fetching proxy info failed for investment ${investment.id} and potential proxy ${selectedProxyId}.`,
      );
      return null;
    },
  );
  if (!selectedProxyId || !investment) {
    return { errorMessage: 'Please specify a proxy to enable save.', disableSave: true };
  }
  if (disabledProxyReason !== undefined) {
    return {
      errorMessage: disabledProxyReason,
      disableSave: true,
    };
  }
  switch (status) {
    case 'loading':
      return {
        errorMessage: undefined,
        disableSave: true,
      };
    case 'success':
      return {
        errorMessage: proxyFeasibilityInfo ? getInvalidProxyMessage(proxyFeasibilityInfo.error) : undefined,
        disableSave: proxyFeasibilityInfo ? !proxyFeasibilityInfo.valid : true,
      };
    default:
      return {
        errorMessage: 'Something went wrong. Please refresh the page or try again later.',
        disableSave: true,
      };
  }
};

export default useProxyErrorMessage;
