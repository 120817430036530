import type { StickyMenuItem } from 'venn-components';
import compact from 'lodash/compact';

export enum tearsheetTabs {
  PERFORMANCE_SUMMARY = 'summary',
  PERFORMANCE_ANALYSIS = 'performance',
  FACTOR_ANALYSIS = 'factor',
  RESIDUAL_ANALYSIS = 'residual',
  STRESS_TESTING = 'stress',
}

const getTearsheetMenu = (): StickyMenuItem[] => {
  return compact([
    { title: 'Performance Summary', id: tearsheetTabs.PERFORMANCE_SUMMARY },
    { title: 'Performance & Risk', id: tearsheetTabs.PERFORMANCE_ANALYSIS },
    { title: 'Factor Analysis', id: tearsheetTabs.FACTOR_ANALYSIS },
    { title: 'Residual Analysis', id: tearsheetTabs.RESIDUAL_ANALYSIS },
    { title: 'Stress Testing', id: tearsheetTabs.STRESS_TESTING },
  ]);
};

export enum perfRiskTabs {
  PERFORMANCE_SUMMARY = 'summary',
  PERFORMANCE = 'performance',
  RISK = 'risk',
}

const getPerformanceAndRiskMenu = (): StickyMenuItem[] => [
  { title: 'Performance Summary', id: perfRiskTabs.PERFORMANCE_SUMMARY },
  { title: 'Performance Analysis', id: perfRiskTabs.PERFORMANCE },
  { title: 'Risk Analysis', id: perfRiskTabs.RISK },
];

export enum shockRiskTabs {
  PERFORMANCE_SUMMARY = 'summary',
  HISTORICAL_ANALYSIS = 'historical',
  HYPOTHETICAL_ANALYSIS = 'hypothetical',
  SCENARIO_ANALYSIS = 'scenario',
}

const getShockAnalysisMenu = (): StickyMenuItem[] => [
  { title: 'Performance Summary', id: shockRiskTabs.PERFORMANCE_SUMMARY },
  { title: 'Historical Analysis', id: shockRiskTabs.HISTORICAL_ANALYSIS },
  { title: 'Hypothetical Analysis', id: shockRiskTabs.HYPOTHETICAL_ANALYSIS },
  {
    title: 'Sensitivity Analysis',
    id: shockRiskTabs.SCENARIO_ANALYSIS,
  },
];

export const getMenu = (templateId: string): StickyMenuItem[] | undefined => {
  switch (templateId) {
    case 'all':
    case 'tearsheet':
      return getTearsheetMenu();
    case 'performance_risk':
      return getPerformanceAndRiskMenu();
    case 'shock':
      return getShockAnalysisMenu();
    default:
      return undefined;
  }
};
