import React from 'react';
import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';

const CorrelationColorLegend: React.FC<React.PropsWithChildren<{ fontSize?: string }>> = ({ fontSize }) => {
  return (
    <Wrapper fontSize={fontSize}>
      <ColorRamp height={fontSize ? '1.2em' : undefined} />
      <ValuesContainer>
        <span>-1.0</span>
        <span>0</span>
        <span>+1.0</span>
      </ValuesContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ fontSize?: string }>`
  width: ${({ fontSize }) => (fontSize ? '25em' : '280px')};
  margin-left: ${({ fontSize }) => (fontSize ? '1.4em' : '20px')};
  margin-top: ${({ fontSize }) => (fontSize ? '0.6em' : '10px')};
  ${({ fontSize }) => fontSize && `font-size: ${fontSize};`}
`;

const ColorRamp = styled.div<{ height: string | undefined }>`
  width: 100%;
  height: ${({ height }) => height ?? '14px'};
  background-image: linear-gradient(
    to right,
    ${GetColor.DivergingColor.A5},
    ${GetColor.DivergingColor.A4} 12%,
    ${GetColor.DivergingColor.A3} 21%,
    ${GetColor.DivergingColor.A2} 30%,
    ${GetColor.DivergingColor.A1} 40%,
    ${GetColor.DivergingColor.MID} 50%,
    ${GetColor.DivergingColor.B1} 61%,
    ${GetColor.DivergingColor.B2} 70%,
    ${GetColor.DivergingColor.B3} 79%,
    ${GetColor.DivergingColor.B4} 88%,
    ${GetColor.DivergingColor.B5}
  );
`;

const ValuesContainer = styled.div`
  margin-top: 2px;
  display: flex;
  justify-content: space-between;
`;

export default CorrelationColorLegend;
