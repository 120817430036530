export interface ReturnsData {
  /**
   * Year as a number (ex. 2014)
   */
  year: number;
  /**
   * Length is 12 for monthly, 4 for quarterly, 1 for annual. Value is null for unknown or empty values.
   */
  returns: (ReturnValue | null)[];
  /**
   * Cumulative returns for the given year
   */
  total?: ReturnValue;
}

export interface ReturnValue {
  value: number;
  /**
   * True if the return is in the analysis range
   */
  isInRange: boolean;
  /**
   * True if the return is calculated via a proxy
   */
  isProxy: boolean;
  /**
   * True if the return is a raw return (not calculated, or extrapolated)
   */
  isRaw: boolean;
}

export enum DataGridSizeType {
  DEFAULT = 'DEFAULT',
  COMPACT = 'COMPACT',
}

export enum PortfolioStrategyFilterType {
  DEFAULT = 'DEFAULT',
  TOP_LEVEL_STRATEGIES = 'TOP_LEVEL_STRATEGIES',
  ALL_STRATEGIES = 'ALL_STRATEGIES',
}

export enum GeoLevelFilterType {
  DEFAULT = 'COUNTRY',
  REGION = 'REGION',
  SUB_REGION = 'SUB_REGION',
}

export enum SectorLevelFilterType {
  DEFAULT = 'INDIVIDUAL',
  TOP_LEVEL = 'TOP_LEVEL',
  SUB_SECTOR = 'SUB_SECTOR',
}
