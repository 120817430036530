export async function copyToClipboard(text: string) {
  if (navigator.clipboard) {
    try {
      await navigator.clipboard.writeText(text);
      return true;
    } catch (e) {
      // Suppress error and attempt another method
    }
  }

  // IE: prevent textarea being shown while dialog is visible.
  if (window.clipboardData?.setData) {
    // TODO(VENN-20707): delete this IE11 specific code
    return window.clipboardData.setData('Text', text);
  }

  if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    const textarea = document.createElement('textarea');
    textarea.style.opacity = '0';
    textarea.textContent = text;
    // Prevent scrolling to bottom of page
    textarea.style.position = 'fixed';
    document.body.appendChild(textarea);
    textarea.select();
    try {
      return document.execCommand('copy');
    } catch (e) {
      // Security exception may be thrown by some browsers.
      return false;
    } finally {
      document.body.removeChild(textarea);
    }
  }
  return false;
}
