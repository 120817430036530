import { getTitle } from '../pages/routes';
import { isFunction } from 'lodash';
import type { AnyDuringEslintMigration } from '../type/any';
import { getTextThemeProvider } from '../../../venn-ui-kit/src/themes/src/getTextThemeProvider';

let prevPageTitle: string | undefined;

/** For tracking purposes to know which page a user is coming from. */
export const getPrevPageTitle = () => {
  const appTitle: string = getTextThemeProvider().AppTitle;

  if (prevPageTitle === undefined || prevPageTitle === appTitle) {
    return undefined;
  }
  return prevPageTitle.replace(`${appTitle} - `, '');
};

const setTitleAndCachePreviousTitle = (location: Location, document: Document) => {
  const appTitle = getTextThemeProvider().AppTitle;

  prevPageTitle = document.title || undefined;
  const title = getTitle(location.pathname);
  document.title = title ? `${appTitle} - ${title}` : appTitle;
};

export const initDynamicPageTitles = () => {
  // Set initial page title
  setTitleAndCachePreviousTitle(window.location, document);

  // Initialize pushState and replaceState wrappers to set title dynamically on path changes
  ((
    { history, location }: { history: AnyDuringEslintMigration; location: (typeof window)['location'] },
    document: Document,
  ) => {
    const { pushState } = history;
    // eslint-disable-next-line
    history.pushState = function (state: any) {
      // Allow pushState to propagate
      if (isFunction(history.onpushstate)) {
        history.onpushstate({ state });
      }
      const response = pushState.apply(history, arguments);
      setTitleAndCachePreviousTitle(location, document);
      // Return pushState response to preserve history API;
      return response;
    };

    const { replaceState } = history;
    // eslint-disable-next-line
    history.replaceState = function (state: any) {
      // Allow replaceState to propagate
      if (isFunction(history.onreplacestate)) {
        history.onreplacestate({ state });
      }
      const response = replaceState.apply(history, arguments);
      setTitleAndCachePreviousTitle(location, document);
      // Return replaceState response to preserve history API;
      return response;
    };
  })(window, document);
};

export const setFavicons = () => {
  const icon16 = document.querySelector<HTMLElement>("link[rel='icon'][sizes='16x16']");
  const icon32 = document.querySelector<HTMLElement>("link[rel='icon'][sizes='32x32']");
  const shortCutIcon = document.querySelector<HTMLElement>("link:not([type])[rel='shortcut icon']");
  const shortCutIcon2 = document.querySelector<HTMLElement>("link[rel='shortcut icon'][type='image/x-icon']");
  const appleTouchIcon = document.querySelector<HTMLElement>("link[rel='apple-touch-icon']");

  icon16?.setAttribute('href', '/venn-icon-only-favicon-16-16.png');
  icon32?.setAttribute('href', '/venn-icon-only-favicon-32-32.png');
  shortCutIcon?.setAttribute('href', '/venn-icon-only-favicon-48-48.ico');
  shortCutIcon2?.setAttribute('href', '/venn-icon-only-favicon-48-48.ico');
  appleTouchIcon?.setAttribute('href', '/venn-icon-only-favicon-192-192.png');
};
