import styled, { css } from 'styled-components';
import type { Theme } from 'venn-ui-kit';
import { Icon, GetColor, Tooltip, ZIndex, EllipsisTooltipSpan } from 'venn-ui-kit';
import type { BasicTableProps, BasicTableColumn, StyledTableType } from 'venn-components';
import { BasicTable } from 'venn-components';
import React from 'react';

export const LinkButton = styled.button`
  color: ${GetColor.Primary.Dark};
`;

export const StyledIcon = styled(Icon)<{ colorGetter: (props: { theme: Theme }) => string }>`
  color: ${(props) => props.colorGetter};
`;

export const StyledCell = styled.span<{ type?: string; hoverEmpty?: boolean }>`
  ${(props) =>
    props.hoverEmpty &&
    `
    tr:hover & {
      display: none;
    }
  `};
  color: ${GetColor.Black};
`;

export const StyledInfoTooltip = styled(Tooltip)`
  text-align: center;
`;

export const StyledIdentifier = styled(EllipsisTooltipSpan)`
  > div {
    left: 0;
  }
`;

export const StyledInfoLink = styled.a`
  color: inherit;
`;

export const StyledInfoIcon = styled(Icon)`
  margin-left: 3px;
`;

export const TableContainer = styled.div`
  position: relative;
`;

export const StyledTable: StyledTableType<{
  selectedFundActive: boolean;
}> = styled(<T extends BasicTableColumn<K>, K>(props: BasicTableProps<T, K>) => <BasicTable<T, K> {...props} />)<{
  selectedFundActive: boolean;
}>`
  table-layout: auto;
  white-space: nowrap;
  border-collapse: separate;
  border-spacing: 0;

  > thead {
    height: 26px;
  }

  > thead > tr th {
    font-size: 12px;
    position: sticky;
    top: 85px;
    background-color: ${GetColor.White};
    z-index: ${ZIndex.Front};
    border-bottom: 1px solid ${GetColor.Grey};
    padding: 5px 3px;
    vertical-align: middle;
  }

  .empty-table-cell {
    display: block;
    margin: 0 0 0 20px;
  }

  > tbody > tr {
    height: 34px;

    td {
      border-bottom: 1px solid ${GetColor.PaleGrey};
    }

    .library-name-cell {
      position: relative;
      padding-right: 20px;
      :before {
        content: '&nbsp;';
        visibility: hidden;
      }
    }

    ${(props) =>
      !props.selectedFundActive &&
      css`
        &:hover,
        &:active,
        &:focus {
          .investment-menu-trigger {
            color: ${GetColor.Black};
          }
          .fave-col .fa-bookmark {
            opacity: 1;
          }
          .library-analyze-icon,
          .tag-item-icon {
            display: inline;
          }
        }
      `};
  }
`;

export const StyledHint = styled.div`
  margin-top: 15px;
  font-style: italic;
`;
