import { assertNotNil, intBinarySearchLow } from '../utils';

export const capitalizeFirstLetter = (name: string) => name.charAt(0).toUpperCase() + name.slice(1);

export const toClassName = (str: string) =>
  `qa-${str
    // replace spaces with '-' and remove all other disallowed characters in class names
    .replace(/[^_a-zA-Z0-9-\s]/g, '')
    .replace(/\s+/g, '-')
    .toLowerCase()}`;

export const isValidEmail = (str: string) =>
  /^[_A-Za-z0-9-.+]+(\.[_A-Za-z0-9-.]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z-]{2,})$/.test(str);

let measureStringCanvas: HTMLCanvasElement | undefined;

/**
 * Measures the size of a string in pixels given a set of font properties.
 * Technically this function is O(n) on string length but in practice it is mostly O(1) unless the string is very large.
 * This function is fairly cheap but not free: it was benchmarked at few hundred thousand invocations per second using a string of several characters.
 */
export const measureString = (
  str: string,
  properties: { fontSize: string; fontFamily: string; fontWeight: string | number },
) => {
  measureStringCanvas ??= document.createElement('canvas');

  const context = assertNotNil(measureStringCanvas.getContext('2d'));
  context.font = `${properties.fontWeight} ${properties.fontSize} ${properties.fontFamily}`;
  const metrics = context.measureText(str);

  return metrics.width;
};

/** Type safe string split for template string literals. Return type is tuple of the constituent parts of the template string. */
export function templateStringSplit<T extends string, TSplit extends string>(
  str: T,
  split: TSplit,
): SplitString<T, TSplit> {
  return str.split(split) as SplitString<T, TSplit>;
}
type SplitString<T extends string, TSplit extends string> = T extends `${infer A}${TSplit}${infer B}`
  ? [A, ...SplitString<B, TSplit>]
  : [T];

/**
 * Calculate how many of the first N characters of the provided string can fit in the provided width.
 */
export function calculateMaxCharacters(
  text: string,
  widthPx: number,
  properties: { fontSize: string; fontFamily: string; fontWeight: string | number },
): number {
  if (!text) {
    return 0;
  }

  return intBinarySearchLow(0, text.length, (mid) => measureString(text.substring(0, mid), properties) < widthPx);
}
