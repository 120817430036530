import { useEffect, useState } from 'react';

type ImportModuleFunction<T> = () => Promise<T>;

/**
 * A hook to load modules lazily in other hooks.
 * Returns the module, or null if the module hasn't been loaded yet.
 *
 * Usage:
 *   If it's a module's default export, it will be useModuleLazily(...).default
 *   Other exports can be accessed like this:
 *      const { Component1, Component2, helperMethod } = useModuleLazily(...);
 *
 * @param importFunction the import statement for the module to load lazily wrapping in a function,
 *  usually useCallback(() => import('module-name'), [])
 */
export default function useModuleLazily<T>(importFunction: ImportModuleFunction<T>): T | null {
  const [module, setModule] = useState<T | null>(null);

  useEffect(() => {
    const importModule = async () => {
      const importedModule = await importFunction();
      setModule(importedModule);
    };
    importModule();
  }, [importFunction]);

  return module;
}
