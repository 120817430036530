import React, { useContext, useState, useCallback } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Icon, FEATURE_OVERVIEW_REPORT_LAB_HREF, GetColor } from 'venn-ui-kit';
import { Modal, ModalHeader, ModalSubhead, ModalFooter, ModalContent, ModalSize, useUser } from 'venn-components';
import { SEEN_CUSTOM_FONTS_KEY } from 'venn-api';

/**
 * This component, when rendered will render a modal asking the user to confirm view.
 * Once confirmed a request will be dispatched to the backend and this dialog will
 * not be rendered again. If the user closes the browser window, the dialog will
 * continue to appear until it is closed with the close button.
 */
const openInNewTab = (url: string) => window.open(url, '_blank', 'noopener,noreferrer');

export const FirstTimeCustomFontsModal = () => {
  const { settings, updateSettings } = useUser();
  const { Images } = useContext(ThemeContext);

  const hasSeenCustomFonts = settings.user[SEEN_CUSTOM_FONTS_KEY];
  const initiallyOpen = !hasSeenCustomFonts;
  const [open, setOpen] = useState(initiallyOpen);

  const setSeenCustomFonts = useCallback(() => {
    updateSettings({
      [SEEN_CUSTOM_FONTS_KEY]: true,
    });
  }, [updateSettings]);

  const onPrimaryClick = useCallback(() => {
    setSeenCustomFonts();
    openInNewTab(FEATURE_OVERVIEW_REPORT_LAB_HREF);
  }, [setSeenCustomFonts]);

  const submit = useCallback(() => {
    setSeenCustomFonts();
    setOpen(false);
  }, [setSeenCustomFonts]);

  return open ? (
    <Modal size={ModalSize.Large}>
      <ModalHeader>We’ve added additional flexibility to customize font sizes within your reports!</ModalHeader>
      <ModalSubhead>
        You can now customize the sizes of elements within blocks in your report, as well as set workspace defaults
        which apply to all newly-created blocks!
      </ModalSubhead>
      <ModalContent>
        <Image key={Images.customFonts} src={Images.customFonts} alt="customFonts" />
      </ModalContent>
      <ModalFooter
        primaryLabel={
          <AISFaqLink rel="noopener noreferrer" target="_blank" href={FEATURE_OVERVIEW_REPORT_LAB_HREF}>
            <Icon type="info-circle" /> View Additional Information
          </AISFaqLink>
        }
        onPrimaryClick={onPrimaryClick}
        cancelLabel="Close"
        onCancel={submit}
      />
    </Modal>
  ) : null;
};

const AISFaqLink = styled.a`
  color: ${GetColor.Black};
`;

const Image = styled.img`
  width: 100%;
`;
