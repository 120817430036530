import React, { PureComponent } from 'react';
import type { AnalysisSubject, AnalysisSubjectSecondaryLabel } from 'venn-utils';
import ColumnLabel from './ColumnLabel';
import { getLabels } from '../utils';
import { createXPositionGetter } from './utils';

export interface ViewPort {
  padding: {
    left: number;
    right: number;
  };
  rowHeight: number;
  width: number;
}

export interface ColumnLabelsProps {
  viewPort: ViewPort;
  mainDataSetName: string;
  baselineDataSetName?: string;
  comparisonDataSetName?: string;
  categoryDataSetName?: string;
  columnWidth: number;
  labelHeight: number;
  lineHeight: number;
  maxLines: number;
  padding?: number;
  relative?: boolean;
  categoryActive: boolean;
  isFund?: boolean;
  secondaryLabel?: AnalysisSubjectSecondaryLabel;
  analysisSubject: AnalysisSubject;
}

class ColumnLabels extends PureComponent<ColumnLabelsProps> {
  render() {
    const {
      viewPort,
      mainDataSetName,
      baselineDataSetName,
      comparisonDataSetName,
      categoryDataSetName,
      columnWidth,
      labelHeight,
      lineHeight,
      maxLines,
      padding = 3,
      relative,
      categoryActive,
      isFund,
      secondaryLabel,
      analysisSubject,
    } = this.props;
    const labels = getLabels(
      mainDataSetName,
      baselineDataSetName,
      comparisonDataSetName,
      categoryDataSetName,
      categoryActive,
      relative,
      isFund,
      undefined,
      secondaryLabel,
      analysisSubject.secondaryPortfolio?.updated,
    );

    const getX = createXPositionGetter(viewPort.width, columnWidth, labels.length);
    return (
      <>
        {labels.map((label, index) =>
          label ? (
            <ColumnLabel
              key={`${label.value}-${getX(index)}`}
              x={getX(index)}
              y={-labelHeight}
              width={columnWidth}
              lineHeight={lineHeight}
              maxLines={maxLines}
              padding={padding}
              text={label.name}
            />
          ) : null,
        )}
      </>
    );
  }
}

export default ColumnLabels;
