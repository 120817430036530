import type { TimeFrame, AnalysisSubject } from 'venn-utils';
import { getSecondaryDisplayLabel } from 'venn-utils';
import React from 'react';
import { GetColor, Hint } from 'venn-ui-kit';
import styled from 'styled-components';
import moment from 'moment';
import type { AttributionSubject } from 'venn-components';

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default ({
  timeFrame,
  selectedSubject,
  subject,
}: {
  timeFrame: TimeFrame;
  selectedSubject: AttributionSubject;
  subject: AnalysisSubject;
}) => {
  const timeFrameConstrained =
    subject.type === 'investment' &&
    (subject.fund!.startRange !== timeFrame.startTime || subject.fund!.endRange !== timeFrame.endTime);
  if (!timeFrameConstrained) {
    return null;
  }

  const dateFormat = 'MMM D, YYYY';
  const formatStartTime = timeFrame.startTime ? moment.utc(timeFrame.startTime).format(dateFormat) : '--';
  const formatEndTime = timeFrame.endTime ? moment.utc(timeFrame.endTime).format(dateFormat) : '--';
  const constraint =
    selectedSubject === 'subject'
      ? subject.isInvestmentInPortfolio
        ? 'current portfolio'
        : 'master portfolio'
      : getSecondaryDisplayLabel(subject, `comparison portfolio (${subject.name} as of)`, 'portfolio');

  return (
    <Wrapper>
      <Hint>
        This analysis is constrained by your {constraint}. Data shown is for{' '}
        <MessageHighlight>{formatStartTime}</MessageHighlight> - <MessageHighlight>{formatEndTime}</MessageHighlight>.
      </Hint>
    </Wrapper>
  );
};

const MessageHighlight = styled.span`
  color: ${GetColor.HighlightDark};
`;

const Wrapper = styled.div`
  min-height: 40px;
  padding: 10px 20px;
  border-top: 1px solid ${GetColor.Grey};
`;
