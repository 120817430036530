import React from 'react';
import { getCurrentTheme, BrandLogo } from 'venn-ui-kit';
import { useHasFF } from 'venn-utils';
import { LogoColor, UpdatedLogoColor } from '../../../venn-ui-kit/src/themes/default/BrandLogo';

const Logo = () => {
  const brandMarkUpdate = useHasFF('brand_mark_update_ff');
  if (getCurrentTheme() !== 'default') {
    return <BrandLogo opacity={0.1} height={1100} />;
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1316" height="1141" viewBox="0 0 1316 1141">
      <g fill="none" fillRule="nonzero" opacity=".1">
        <path
          fill={brandMarkUpdate ? UpdatedLogoColor.Logo2 : LogoColor.Logo1}
          d="M318.79 0H0l159.875 276.958h318.79zM1155.875 276.958L1315.75 0H996.96L837.085 276.958z"
        />
        <path
          fill={brandMarkUpdate ? UpdatedLogoColor.Logo1 : LogoColor.Logo2}
          d="M837.085 276.958L996.96 0H318.79l159.875 276.958z"
        />
        <path
          fill={brandMarkUpdate ? UpdatedLogoColor.Logo2 : LogoColor.Logo1}
          d="M817.27 863.392L657.847 587.337 498.48 863.392l159.367 276.958z"
        />
        <path
          fill={brandMarkUpdate ? UpdatedLogoColor.Logo1 : LogoColor.Logo2}
          d="M657.847 587.337L478.665 276.958h-318.79L498.48 863.392zM657.847 587.337L817.27 863.392l338.605-586.434h-318.79z"
        />
        <path
          fill={brandMarkUpdate ? UpdatedLogoColor.Logo3 : LogoColor.Logo3}
          d="M478.665 276.958l179.182 310.379 179.238-310.379z"
        />
      </g>
    </svg>
  );
};

export default Logo;
