import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import { ScalingSign } from '../../types';
import type { DropMenuItem } from 'venn-ui-kit';
import { DropMenu } from 'venn-ui-kit';

const OPTIONS: DropMenuItem<ScalingSign>[] = [
  {
    value: ScalingSign.Plus,
    label: '+',
  },
  {
    value: ScalingSign.Minus,
    label: '-',
  },
];

const ScalignSignWrapper = styled.div`
  font-size: 12px;
  margin-right: 6px;
`;

export interface BenchmarkScalingSignProps {
  value: ScalingSign;
  onChange: (value: DropMenuItem<ScalingSign>) => void;
}

const MENU_WIDTH = 46;

const BenchmarkScalingSign: FC<React.PropsWithChildren<BenchmarkScalingSignProps>> = ({ value, onChange }) => (
  <ScalignSignWrapper>
    <DropMenu width={MENU_WIDTH} items={OPTIONS} selected={value} onChange={onChange} />
  </ScalignSignWrapper>
);

export default BenchmarkScalingSign;
