import { rpcCodeToHttpCode, StatusCode } from './statusCodes';
import { type Code } from '@connectrpc/connect';

export function fetchErrorShouldRetry(error: unknown): boolean {
  const maybeDomException = typeof error === 'object' && error ? (error as DOMException) : undefined;
  // AbortError are user agent initiated, so we can't/don't retry.
  if (maybeDomException?.name === 'AbortError') {
    return false;
  }

  // NetworkErrors are often transient, so we retry.
  if (maybeDomException?.name === 'NetworkError') {
    return true;
  }

  // If the error is an Error and it's message includes 'Failed to fetch', it's likely also a transient network error.
  if (error instanceof Error && error.message.includes('Failed to fetch')) {
    return true;
  }

  return false;
}

export function canRpcRetry(code: Code): boolean {
  const httpEquivalentCode = rpcCodeToHttpCode[code];
  if (httpEquivalentCode === undefined) {
    // eslint-disable-next-line no-console
    console.error(new Error(`Unknown RPC code: ${code}`));
    return true;
  }
  return canHttpRetry(httpEquivalentCode);
}

export function canHttpRetry(statusCode: StatusCode): boolean {
  // 5xx statuses are server errors, which are often retryable
  if (statusCode >= 500 && statusCode < 600) {
    const noRetry500s = [
      StatusCode.NotImplemented_501,
      StatusCode.HttpVersionNotSupported_505,
      StatusCode.VariantAlsoNegotiates_506,
      StatusCode.LoopDetected_508,
      StatusCode.NotExtended_510,
      StatusCode.NetworkAuthenticationRequired_511,
    ];
    return !noRetry500s.includes(statusCode);
  }

  // 4xx statuses are client errors, which are not retryable with few exceptions
  if (statusCode >= 400 && statusCode < 500) {
    const retryable400s = [StatusCode.RequestTimeout_408, StatusCode.ImATeapot_418];
    return retryable400s.includes(statusCode);
  }

  // 3xx statuses are redirections, which are not retryable
  if (statusCode >= 300 && statusCode < 400) {
    return false;
  }

  // 2xx statuses are successful, so they shouldn't be retried.
  if (statusCode >= 200 && statusCode < 300) {
    return false;
  }

  return true;
}
