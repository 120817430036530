import type {
  FactorLensWithReturns,
  FactorWithNonSerializedReturns as FactorEntity,
  HoldingsBreakdownTypeEnum,
  HoldingsCategory,
  NotablePeriod,
} from 'venn-api';
import { getAllFactorLensesForUser, getHoldingsCategories, getPredefinedNotablePeriods } from 'venn-api';
import type { CategoryMetric, CustomizableBlockSetting } from 'venn-utils';
import { getBlockSettingsMapForUser } from 'venn-utils';
import { selectorWithAuth } from '../utils';
import { selectorFamily } from 'recoil';

const TOTAL_LABEL = 'Total';
const TOTAL_FACTOR_ID = '-2';

const RISK_FREE_RATE_LABEL = 'Risk-Free Rate';
const RISK_FREE_RATE_FACTOR_ID = '-3';

const RESIDUAL_LABEL = 'Residual';
const RESIDUAL_FACTOR_ID = '-1';

const getFactorMetrics = (factors: FactorEntity[]): CategoryMetric[] => {
  return [
    {
      id: TOTAL_FACTOR_ID,
      name: TOTAL_LABEL,
    },
    ...factors.map((factor) => ({
      id: String(factor.id),
      name: factor.name,
      category: factor.category,
      description: factor.description,
    })),
    {
      id: RISK_FREE_RATE_FACTOR_ID,
      name: RISK_FREE_RATE_LABEL,
      description: 'Represents the return of short-term sovereign bond yields.',
    },
    {
      id: RESIDUAL_FACTOR_ID,
      name: RESIDUAL_LABEL,
      description: 'Represents the risk/return not explained by the Two Sigma Factor Lens.',
    },
  ];
};

export const primaryFactorLens = selectorWithAuth<FactorLensWithReturns | undefined>({
  key: 'primaryFactorLens',
  get: async () => {
    const { content: factorLenses } = await getAllFactorLensesForUser();
    return factorLenses.find((factorLens) => factorLens.primary);
  },
});

export const availableFactorMetrics = selectorWithAuth<CategoryMetric[]>({
  key: 'availableFactorMetrics',
  get: ({ get }) => getFactorMetrics(get(primaryFactorLens)?.factors ?? []),
});

// This selector is only in recoil due to refactor of legacy code.
export const blockSettingsMap = selectorWithAuth<{
  [key in string]: CustomizableBlockSetting;
}>({
  key: 'blockSettingsMap',
  get: () => getBlockSettingsMapForUser(),
});

export const predefinedNotablePeriods = selectorWithAuth<NotablePeriod[]>({
  key: 'predefinedNotablePeriods',
  get: async () => {
    const { content } = await getPredefinedNotablePeriods();
    return content;
  },
});

export const holdingsCategoriesTree = selectorFamily<HoldingsCategory[], HoldingsBreakdownTypeEnum>({
  key: 'holdingsCategoriesTree',
  get: (breakdownType) => async () => {
    return (await getHoldingsCategories(breakdownType)).content;
  },
});

export interface HoldingsCategoryInfo {
  color?: string;
  parentId?: string;
  parentName: string;
  name: string;
  level: number;
  id: string;
}

export const holdingsCategoriesData = selectorFamily<
  { [key: string]: HoldingsCategoryInfo },
  HoldingsBreakdownTypeEnum
>({
  key: 'holdingsCategoriesData',
  get:
    (breakdownType) =>
    ({ get }) => {
      const categoryTree = get(holdingsCategoriesTree(breakdownType));
      const data: { [key: string]: HoldingsCategoryInfo } = {};
      function addAll(parentName: string, category: HoldingsCategory, level: number, parentId?: string): void {
        const id = category.id.id;
        const name = category.categoryName;
        data[id] = {
          level,
          name,
          color: '#DE9BC0',
          parentId,
          parentName,
          id,
        };

        if (!category.children) return;
        category.children.forEach((child) => addAll(name, child, level + 1, id));
      }

      categoryTree.forEach((node) => addAll('', node, 0, undefined));
      return data;
    },
});
