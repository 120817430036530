import type { InputHTMLAttributes } from 'react';
import React from 'react';
import omit from 'lodash/omit';
import type { Omit } from '../types';
import StatefulInput from '../stateful-input/StatefulInput';
import type { Formatter } from './utils/prepareNumber';
import { prepareNumber } from './utils/prepareNumber';

export interface StatefulNumericInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  max?: number;
  min?: number;
  precision?: number;
  value: string;
  formatter: Formatter;
  selectOnFocus?: boolean;
  onValueChange(localValue: string | number, event: React.SyntheticEvent<HTMLInputElement>): void;
}

export interface StatefulNumericInputState {
  localValue: string;
  isEditing: boolean;
}

export default class StatefulNumericInput extends React.PureComponent<
  StatefulNumericInputProps,
  StatefulNumericInputState
> {
  static getDerivedStateFromProps(
    nextProps: StatefulNumericInputProps,
    prevState: StatefulNumericInputState,
  ): StatefulNumericInputState | null {
    if (!prevState.isEditing && nextProps.value !== prevState.localValue) {
      return {
        localValue: nextProps.value,
        isEditing: false,
      };
    }
    return null;
  }

  constructor(props: StatefulNumericInputProps) {
    super(props);

    this.state = {
      localValue: props.value || '',
      isEditing: false,
    };
  }

  onChange = (localValue: string) => {
    this.setState({ localValue, isEditing: true });
  };

  onValueChange = (value: string, event: React.SyntheticEvent<HTMLInputElement>) => {
    const { precision, onValueChange, min, max, formatter } = this.props;
    const result = prepareNumber(Number(value), {
      formatter,
      min,
      max,
      precision,
    });

    onValueChange(result, event);
    this.setState({ localValue: String(result), isEditing: false });
  };

  render() {
    const { localValue } = this.state;
    const { selectOnFocus } = this.props;
    const inputProps = omit(this.props, ['formatter', 'precision', 'value']);

    return (
      <StatefulInput
        {...inputProps}
        value={localValue}
        type="number"
        onChange={this.onChange}
        // @ts-expect-error: TODO fix strictFunctionTypes
        onValueChange={this.onValueChange}
        selectOnFocus={selectOnFocus}
      />
    );
  }
}
