/**
 * NOTE: In order to keep the usage of charts outside venn-modals as simple as possible, please DO NOT export
 * `AnalysisLineChart`, `AnalysisChartLegend` or `AnalysisLineChartUtils` from this module.
 * If you want to do it, add a new class here that uses `AnalysisLineChart` and export it to use in your module.
 *
 *  The reason for that is to relieve engineers of processing the data for the charts inside the pages that render them.
 */

export type { VennLineChartData } from './chart-config-logic';
export { addVenncast, getLineSerie } from './chart-config-logic';

export { default as CumulativeReturnChart } from './CumulativeReturnChart';
export { default as AnalysisReturnsDistributionChart } from './AnalysisReturnsDistributionChart';
export { default as AnalysisRollingVolatilityChart } from './AnalysisRollingVolatilityChart';
export { default as AnalysisRollingSharpeChart } from './AnalysisRollingSharpeChart';
export { default as AnalysisRollingBetaChart } from './AnalysisRollingBetaChart';
export { default as AnalysisRollingCorrelationChart } from './AnalysisRollingCorrelationChart';
export * from './drawdown-charts';
export { default as FactorSummaryCharts } from './factor-charts/FactorSummaryCharts';
export { default as FactorTrendCharts } from './factor-charts/FactorTrendCharts';
export { default as FactorSummaryChart } from './factor-charts/FactorSummaryChart';
export * from './factor-charts/utils';
export type { FactorAnalysisGroup } from './factor-charts/types';
export { default as FundCorrelationTable } from './correlation-analysis/FundCorrelationTable';
export { default as ResidualReturnsChart } from './ResidualReturnsChart';
export { default as AnalysisRollingReturnsChart } from './AnalysisRollingReturnsChart';
export { default as RollingWindowDropMenu } from './internal/rolling-window-dropmenu/RollingWindowDropMenu';
export { RollingPeriodEnum } from './types';
