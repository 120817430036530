import React, { useContext } from 'react';
import styled from 'styled-components';
import { UserContext } from 'venn-components';
import GrowthSimulationSection from './sections/GrowthSimulationSection';
import MetricsSection from './sections/MetricsSection';
import { useRecoilValue } from 'recoil';
import { blockInfoGraphicType, blockSettings } from 'venn-state';
import ScenarioSection from './sections/ScenarioSection';
import { SubjectInputSection } from './sections/subject-input/SubjectInputSection';
import { BenchmarkInputSection } from './sections/subject-input/benchmark/BenchmarkInputSection';
import { DateInputSection } from './sections/date-range-input/DateInputSection';
import { PanelSection } from '../../PanelSection';
import FormatSection from './sections/FormatSection';
import TitleSection from './sections/TitleSection';
import MoreSection from './sections/MoreSection';
import PrivateAssetSection from './sections/PrivateAssetSection';
import { hasDisabledBenchmark, hasDisabledDateRange, isPublicPrivateAssetGrowthBlock, isTreeBlock } from 'venn-utils';
import HoldingsTreeSection from './sections/HoldingsTreeSection';
import HoldingsBlockSection from './sections/HoldingsBlockSection';
import PeerGroupsSection from './sections/PeerGroupsSection';
import { CustomFontSection } from './sections/CustomFontSection';
import { TableSpacingSection } from './sections/TableSpacingSection';

/**
 * Input Management component for modifying block settings/config for analysis blocks (e.g. not text, image, etc).
 */
export const AnalysisBlockTab = React.memo(function AnalysisBlockTab({ selected }: { selected: string }) {
  const { hasPermission } = useContext(UserContext);
  const settings = useRecoilValue(blockSettings(selected));

  return (
    <>
      <BlockInputsSection selected={selected} />
      {(settings.customBlockType === 'GROWTH_SIMULATION' ||
        isPublicPrivateAssetGrowthBlock(settings.customBlockType)) && <GrowthSimulationSection selected={selected} />}
      {isTreeBlock(settings.customBlockType) && (
        <HoldingsTreeSection selected={[]} blockType={settings.customBlockType} selectedRefId={selected} />
      )}
      {settings.customBlockType === 'ASSET_EXPOSURE' && <HoldingsBlockSection selectedRefId={selected} />}

      <PrivateAssetSection selected={selected} />
      <ScenarioSection selected={selected} />
      {settings.customBlockType === 'PEER_GROUPS' && <PeerGroupsSection selected={selected} />}
      <FormatSection selected={selected} isReadOnly={!hasPermission('STUDIO_EDIT_FORMAT')} />

      <MetricsSection selected={selected} />
      <TitleSection selected={selected} />
      <MoreSection selected={selected} />
      <TableSpacingSection selected={selected} isReadOnly={!hasPermission('STUDIO_EDIT_FORMAT')} />
      <CustomFontSection selected={selected} isReadOnly={!hasPermission('STUDIO_EDIT_FORMAT')} />
    </>
  );
});

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
const BlockInputsSection = React.memo(({ selected }: { selected: string }) => {
  const settings = useRecoilValue(blockSettings(selected));
  const blockGraphicType = useRecoilValue(blockInfoGraphicType(selected));
  return (
    <PanelSection header="Set Inputs on Block">
      <InputSection>
        <SubjectInputSection selectedBlockId={selected} />
        {!hasDisabledDateRange(settings.customBlockType) && <DateInputSection selectedBlockId={selected} />}
        {!hasDisabledBenchmark(settings.customBlockType, blockGraphicType) && (
          <BenchmarkInputSection selectedBlockId={selected} />
        )}
      </InputSection>
    </PanelSection>
  );
});

const InputSection = styled.div`
  display: flex;
  row-gap: 20px;
  flex-direction: column;
`;
