import React, { useState } from 'react';
import { isNil } from 'lodash';
import { InputCSS } from './styled';
import styled, { css } from 'styled-components';
import Input from '../../input/Input';

interface ConstraintValueInputProps {
  placeholder?: string;
  defaultValue?: number;
  hasError?: boolean;
  isPercentMode?: boolean;
  onFocus?: () => void;
  onSetValue: (value: number) => void;
}

const ConstraintValueInput = ({
  placeholder,
  defaultValue,
  hasError,
  isPercentMode,
  onFocus,
  onSetValue,
}: ConstraintValueInputProps) => {
  const [inputValue, setInputValue] = useState(isNil(defaultValue) ? '' : defaultValue.toString());

  return (
    <ValueInput
      placeholder={placeholder ?? '--'}
      value={inputValue}
      onChange={(typedValue) => setInputValue(typedValue)}
      error={hasError}
      onFocus={onFocus}
      onBlur={() => {
        if (isNil(inputValue) || inputValue === '') {
          return;
        }
        const numericValue = Number.parseFloat(inputValue);
        if (Number.isNaN(numericValue)) {
          setInputValue('');
          return;
        }
        setInputValue(numericValue.toString());
        onSetValue(numericValue);
      }}
      className="qa-constraint-value-input"
      showPercent={isPercentMode}
    />
  );
};

export default ConstraintValueInput;

const ValueInput = styled(Input)<{ showPercent?: boolean }>`
  ${InputCSS}
  border-radius: 0;
  max-width: 50px;
  ${({ showPercent }) =>
    showPercent &&
    css`
      &:after {
        content: '%';
      }
    `}
  input {
    margin: 0;
  }
`;
