import type { CSSProperties } from 'react';
import React, { useState, useMemo } from 'react';
import SimpleTrigger from '../triggers/SimpleTrigger';
import type { SimpleMenuPropsWithLink } from '../menus/SimpleMenu';
import { default as SimpleMenu } from '../menus/SimpleMenu';
import type { DropMenuProps } from './BaseDropMenu';
import BaseDropMenu from './BaseDropMenu';
import type { BaseDropMenuProps, MenuContainerProps, DropMenuItem } from '../types';
import { analyticsService } from 'venn-utils';
import JumboTrigger from '../triggers/JumboTrigger';

export interface SimpleDropMenuProps<T> extends SimpleMenuPropsWithLink<T>, BaseDropMenuProps<T>, MenuContainerProps {
  /**
   * Text displayed when no item is selected. Defaults to "(None)"
   */
  placeholder?: string;
  /**
   * Flag for showing the trigger as dark when not selected
   */
  darkPlaceholder?: boolean;
  /**
   * If true, the dropdown will be searchable (like a typeahead)
   */
  searchable?: boolean;

  /**
   * If set the menu will only display items once the search string reaches this length.
   */
  minSearchLength?: number | undefined;

  style?: CSSProperties;
  leftContent?: JSX.Element;
  disableTooltip?: boolean;
  /**
   * By default, tooltip is only shown when the content overflows.
   * If this is true, the tooltip is always shown.
   */
  alwaysShowTooltip?: boolean;

  /**
   * If set, trigger will not be disabled when this many or more items are provided. Defaults to 2.
   * Note: link triggers are always enabled unless explicitly disabled.
   */
  minimumItemsToTrigger?: number;
  /**
   * Use "jumbo" (large) trigger
   */
  jumbo?: boolean;
  /**
   * Provides a menu component to override the simple menu component used by default.
   */
  menuComponent?: DropMenuProps<T>['menuComponent'];

  onCollapse?: DropMenuProps<T>['onCollapse'];
}

export function SimpleDropMenu<T>({
  label,
  rightLabel,
  items,
  selected,
  className,
  openByDefault,
  usePortal,
  portalAnchorPlacement,
  disabled,
  invalid,
  highlight,
  link,
  width,
  height,
  comparer,
  placeholder,
  searchable,
  minSearchLength,
  innerRef,
  onLinkClick,
  onChange,
  getSelectionLabel,
  style,
  leftContent,
  disableTooltip,
  getTooltipContent,
  alwaysShowTooltip,
  jumbo,
  minimumItemsToTrigger = 2,
  darkPlaceholder,
  menuComponent,
  onCollapse,
  'data-testid': dataTestId,
}: SimpleDropMenuProps<T>) {
  const [search, setSearch] = useState('');
  const selectedItem = useMemo(
    () => items.find((item) => (comparer && selected ? comparer(item.value, selected) : item.value === selected)),
    [comparer, selected, items],
  );
  const filteredItems = useMemo(() => {
    if (searchable) {
      if (minSearchLength !== undefined && search.length < minSearchLength) {
        return [];
      }
      return items.filter((i) => i.label.toLowerCase().includes(search.toLowerCase()));
    }
    return items;
  }, [items, searchable, search, minSearchLength]);

  const triggerDisabled = disabled || (!link && items.length < minimumItemsToTrigger);
  const Trigger = jumbo ? JumboTrigger : SimpleTrigger;
  const triggerProps = jumbo
    ? {}
    : {
        placeholder,
        searchable,
        search,
        onSearch: setSearch,
        leftContent,
        disableTooltip,
        getTooltipContent,
        alwaysShowTooltip,
        /* override the min-width set in SimpleTrigger */
        style: width ? { minWidth: width } : undefined,
      };

  const selectionText =
    selected !== null && selectedItem
      ? getSelectionLabel
        ? getSelectionLabel(selectedItem)
        : selectedItem.label
      : undefined;
  return (
    <BaseDropMenu
      data-testid={dataTestId}
      openByDefault={openByDefault}
      usePortal={usePortal}
      portalAnchorPlacement={portalAnchorPlacement}
      filteredItems={filteredItems}
      selectedItem={selectedItem}
      onChange={onChange}
      style={style}
      label={label}
      onCollapse={onCollapse}
      triggerComponent={(expanded, _, onToggle, handleKeyEsc, handleKeyEnter, handleKeyUp, handleKeyDown) => (
        <Trigger
          className={className}
          disabled={triggerDisabled}
          invalid={invalid}
          highlight={highlight}
          label={label}
          rightLabel={rightLabel}
          expanded={expanded}
          selection={selectionText}
          selectionIcon={selectedItem?.icon}
          innerRef={innerRef}
          onClick={(open) => {
            setSearch('');
            onToggle(open);
          }}
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
          onKeyEsc={handleKeyEsc}
          onKeyEnter={handleKeyEnter}
          darkPlaceholder={darkPlaceholder}
          {...triggerProps}
        />
      )}
      menuComponent={
        menuComponent ||
        ((
          highlighted: DropMenuItem<T> | undefined,
          onCollapse: () => void,
          menuClassName: string | undefined,
        ): JSX.Element => (
          <SimpleMenu<T>
            className={menuClassName}
            items={filteredItems}
            selected={selected}
            link={link}
            width={width}
            height={height}
            highlighted={highlighted}
            onLinkClick={() => {
              onCollapse();
              onLinkClick?.();
            }}
            onChange={(item) => {
              onCollapse();
              onChange?.(item);
              setSearch('');
              analyticsService.dropdownItemSSelected({
                label,
                type: 'primary dropdown',
                selectType: 'single-select',
                selection: item.label,
              });
            }}
          />
        ))
      }
    />
  );
}

export default SimpleDropMenu;
