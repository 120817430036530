import { TOTAL_ID } from '../../factor-chart';
import type { BoundingBox, Scale, ViewPort } from './types';
import type { FactorSummaryChartRow, FactorSummaryRow } from '../factorSummaryTypes';

export const scaleX = (value: number, scale: Scale, ratio: number): number => (value - scale.min) * ratio;

export const scaleWidth = (value: number, ratio: number): number => value * ratio;

export const calculateBarBoundingBox = (
  viewPort: ViewPort,
  scale: Scale,
  bar: { x: number; value: number },
): BoundingBox => {
  const { x, value } = bar;
  const actualX = value < 0 ? x + value : x;
  const { padding = { left: 0, right: 0 }, width: chartWidth, rowHeight } = viewPort;
  const scaleSize = scale.max - scale.min;
  const ratio = scaleSize ? (chartWidth - padding.left - padding.right) / scaleSize : 0;
  const scaledX = scaleX(actualX, scale, ratio);
  const y = 1;
  const scaledWidth = Math.max(scaleWidth(Math.abs(value), ratio), 0.1);
  const height = rowHeight - 2;
  const errorSize = 0;

  return { x: scaledX, y, width: scaledWidth, height, errorSize };
};

interface FactorSummaryAgg {
  prevFactor: FactorSummaryChartRow;
  preparedFactors: FactorSummaryChartRow[];
}

export function prepareWaterfallFactors(factors: FactorSummaryRow[]) {
  const initFactor: FactorSummaryChartRow = { factorId: -100, factorName: '', bar: { value: 0, x: 0 } };
  return factors.reduce(
    (agg: FactorSummaryAgg, factor: FactorSummaryRow) => {
      if (factor.factorId === TOTAL_ID) {
        return {
          ...agg,
          preparedFactors: [
            ...agg.preparedFactors,
            {
              ...factor,
              bar: { x: 0, value: factor?.mainDataPoint?.value ?? 0 },
            },
          ],
        };
      }
      const newPrepared: FactorSummaryChartRow = {
        ...factor,
        bar: {
          x: agg.prevFactor.bar.x + agg.prevFactor.bar.value,
          value: factor?.mainDataPoint?.value ?? 0,
        },
      };
      return {
        prevFactor: newPrepared,
        preparedFactors: [...agg.preparedFactors, newPrepared],
      };
    },
    { prevFactor: initFactor, preparedFactors: [] },
  ).preparedFactors;
}

export function prepareBarsFactors(factors: FactorSummaryRow[]): FactorSummaryChartRow[] {
  return factors.map((factor) => ({
    ...factor,
    bar: { x: 0, value: factor?.mainDataPoint?.value ?? 0 },
  }));
}
