/*
 * Venn Platform API 1.0.0 Operations for "permission"
 * Automatically generated file - do not modify!
 */

import type {
  CheckPermissionRequest,
  CheckPermissionResponse,
  CheckRolesRequest,
  GetPermissionedResourcesRequest,
  GetPermissionedResourcesResponse,
  PermissionAction,
  PermissionEntry,
  PermissionRole,
  ResourceEntity,
  TreeResourceEntity,
  UserEntity,
} from '../types';
import type { OperationResult } from '../../utils';
import { buildQuery, buildRequestInit, extractError, retryGeneratedFetch } from '../../utils';

/** Create or update a permission action's information */
export async function createOrUpdatePermissionAction(
  body?: Partial<PermissionAction>,
  signal?: AbortSignal,
): Promise<OperationResult<PermissionAction>> {
  const endpoint = '/api/permission/action';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: PermissionAction = (await response.json()) as PermissionAction;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Create or update a role's information */
export async function createOrUpdatePermissionRole(
  body?: Partial<PermissionRole>,
  signal?: AbortSignal,
): Promise<OperationResult<PermissionRole>> {
  const endpoint = '/api/permission/role';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: PermissionRole = (await response.json()) as PermissionRole;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Delete a permission action */
export async function deletePermissionAction(actionId: string, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/permission/action/${encodeURIComponent(actionId)}`;
  const requestInit = buildRequestInit({ method: 'DELETE', signal });
  const response = await retryGeneratedFetch('DELETE', () => fetch(endpoint, requestInit));
  if ((response.status >= 200 && response.status < 400) || response.status === 404) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Delete a resource */
export async function removeResource(resourceId: string, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/permission/resource/${encodeURIComponent(resourceId)}`;
  const requestInit = buildRequestInit({ method: 'DELETE', signal });
  const response = await retryGeneratedFetch('DELETE', () => fetch(endpoint, requestInit));
  if ((response.status >= 200 && response.status < 400) || response.status === 404) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Delete a role */
export async function deletePermissionRole(roleId: string, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/permission/role/${encodeURIComponent(roleId)}`;
  const requestInit = buildRequestInit({ method: 'DELETE', signal });
  const response = await retryGeneratedFetch('DELETE', () => fetch(endpoint, requestInit));
  if ((response.status >= 200 && response.status < 400) || response.status === 404) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get actions allowed for the resource */
export async function getActionsAllowedFor(
  body?: Partial<CheckPermissionRequest>,
  signal?: AbortSignal,
): Promise<OperationResult<string[]>> {
  const endpoint = '/api/permission/actions';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await retryGeneratedFetch('POST', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: string[] = (await response.json()) as string[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get all permission entries associated with a batch of users */
export async function getPermissionEntriesForUsers(
  userId?: string[],
  signal?: AbortSignal,
): Promise<OperationResult<{ [key: string]: PermissionEntry[] }>> {
  const endpoint = `/api/permission/permission-entries${buildQuery({ userId })}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: { [key: string]: PermissionEntry[] } = (await response.json()) as {
      [key: string]: PermissionEntry[];
    };
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get all permission entries associated with the user */
export async function getPermissionEntriesForUser(
  userId: string,
  signal?: AbortSignal,
): Promise<OperationResult<PermissionEntry[]>> {
  const endpoint = `/api/permission/permission-entries/${encodeURIComponent(userId)}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: PermissionEntry[] = (await response.json()) as PermissionEntry[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get roles allowed for the resource */
export async function getRolesAllowedFor(
  body?: Partial<CheckRolesRequest>,
  signal?: AbortSignal,
): Promise<OperationResult<PermissionRole[]>> {
  const endpoint = '/api/permission/roles';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await retryGeneratedFetch('POST', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: PermissionRole[] = (await response.json()) as PermissionRole[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** No summary or description available. Please update the summary or description field in the Openapi spec. */
export async function addPermission(
  body?: Partial<PermissionEntry>,
  signal?: AbortSignal,
): Promise<OperationResult<PermissionEntry>> {
  const endpoint = '/api/permission';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: PermissionEntry = (await response.json()) as PermissionEntry;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** No summary or description available. Please update the summary or description field in the Openapi spec. */
export async function checkPermission(
  verbose?: boolean,
  body?: Partial<CheckPermissionRequest>,
  signal?: AbortSignal,
): Promise<OperationResult<CheckPermissionResponse>> {
  const endpoint = `/api/permission/check${buildQuery({ verbose })}`;
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await retryGeneratedFetch('POST', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: CheckPermissionResponse = (await response.json()) as CheckPermissionResponse;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** No summary or description available. Please update the summary or description field in the Openapi spec. */
export async function createOrUpdateResourceEntity(
  body?: Partial<ResourceEntity>,
  signal?: AbortSignal,
): Promise<OperationResult<ResourceEntity>> {
  const endpoint = '/api/permission/resource';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: ResourceEntity = (await response.json()) as ResourceEntity;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** No summary or description available. Please update the summary or description field in the Openapi spec. */
export async function createOrUpdateUserEntity(
  body?: Partial<UserEntity>,
  signal?: AbortSignal,
): Promise<OperationResult<UserEntity>> {
  const endpoint = '/api/permission/user';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: UserEntity = (await response.json()) as UserEntity;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** No summary or description available. Please update the summary or description field in the Openapi spec. */
export async function getChildrenResources(
  resourceId: string,
  signal?: AbortSignal,
): Promise<OperationResult<TreeResourceEntity>> {
  const endpoint = `/api/permission/resource/${encodeURIComponent(resourceId)}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: TreeResourceEntity = (await response.json()) as TreeResourceEntity;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** No summary or description available. Please update the summary or description field in the Openapi spec. */
export async function getPermissionedResources(
  body?: Partial<GetPermissionedResourcesRequest>,
  signal?: AbortSignal,
): Promise<OperationResult<GetPermissionedResourcesResponse>> {
  const endpoint = '/api/permission/permissioned-resources';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await retryGeneratedFetch('POST', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: GetPermissionedResourcesResponse = (await response.json()) as GetPermissionedResourcesResponse;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** No summary or description available. Please update the summary or description field in the Openapi spec. */
export async function getUserEntity(userId: string, signal?: AbortSignal): Promise<OperationResult<UserEntity>> {
  const endpoint = `/api/permission/user/${encodeURIComponent(userId)}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: UserEntity = (await response.json()) as UserEntity;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** No summary or description available. Please update the summary or description field in the Openapi spec. */
export async function removePermission(
  body?: Partial<PermissionEntry>,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = '/api/permission/remove-permission';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Retrieve all action and their information */
export async function getAllActions(signal?: AbortSignal): Promise<OperationResult<PermissionAction[]>> {
  const endpoint = '/api/permission/actions/all';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: PermissionAction[] = (await response.json()) as PermissionAction[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Retrieve all roles and their information */
export async function getAllRoles(signal?: AbortSignal): Promise<OperationResult<PermissionRole[]>> {
  const endpoint = '/api/permission/roles/all';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: PermissionRole[] = (await response.json()) as PermissionRole[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Retrieve the information of a permission action */
export async function getPermissionAction(
  actionId: string,
  signal?: AbortSignal,
): Promise<OperationResult<PermissionAction>> {
  const endpoint = `/api/permission/action/${encodeURIComponent(actionId)}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: PermissionAction = (await response.json()) as PermissionAction;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Retrieve the information of a role */
export async function getPermissionRole(
  roleId: string,
  signal?: AbortSignal,
): Promise<OperationResult<PermissionRole>> {
  const endpoint = `/api/permission/role/${encodeURIComponent(roleId)}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: PermissionRole = (await response.json()) as PermissionRole;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** return if the resource is assigned to a user */
export async function isAssignedResource(resourceId: string, signal?: AbortSignal): Promise<OperationResult<boolean>> {
  const endpoint = `/api/permission/resource/${encodeURIComponent(resourceId)}/is-assigned`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: boolean = (await response.json()) as boolean;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
