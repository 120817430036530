import { ButtonIcon } from 'venn-ui-kit';
import React from 'react';
import styled from 'styled-components';
import { useSetRecoilState } from 'recoil';
import type { FactorOverrideType, PartialFactorForecast } from 'venn-state';
import { isFactorForecastEditingAtom } from 'venn-state';
import { analyticsService } from 'venn-utils';

export type SpecificFactorForecastActionsProps = {
  partialForecast: PartialFactorForecast;
  setCurrentPendingOverride: (override: FactorOverrideType) => void;
  currentPendingOverride: FactorOverrideType;
  setIsTextboxEditing: (isEditing: boolean) => void;
  isEditing: boolean;
  setEditValueBuffer: (value: string) => void;
  roundNumberToString: (value: number) => string;
  isVisible: boolean;
};
const SpecificFactorForecastActions = ({
  partialForecast,
  setCurrentPendingOverride,
  currentPendingOverride,
  setIsTextboxEditing,
  isEditing,
  setEditValueBuffer,
  roundNumberToString,
  isVisible,
}: SpecificFactorForecastActionsProps) => {
  const { annualizedReturn, annualizedReturnOverride } = partialForecast;
  const buttonIconSize = 14;
  const buttonHoverSize = 20;
  const emptyButton = <EmptyButtonIcon iconFontSize={buttonIconSize} size={buttonHoverSize} />;
  const setIsFactorForecastGroupBeingEdited = useSetRecoilState(isFactorForecastEditingAtom);
  const editButton = (
    <SpecificFactorForecastActionButtonIcon
      testId="qa-edit-factor-forecast-return-override"
      iconType="pen-to-square"
      onClick={() => {
        setIsTextboxEditing(true);
        setIsFactorForecastGroupBeingEdited(true);
        analyticsService.ctaClicked({
          text: 'Edit',
          purpose: 'Override factor Forecast',
        });
      }}
      iconFontSize={buttonIconSize}
      size={buttonHoverSize}
    />
  );
  const revertButton = (
    <SpecificFactorForecastActionButtonIcon
      testId="qa-revert-factor-forecast-return-override"
      iconType="clock-rotate-left"
      onClick={() => {
        setCurrentPendingOverride(annualizedReturnOverride === undefined ? 'no change' : 'reset');
        setEditValueBuffer(roundNumberToString(annualizedReturn));
        setIsFactorForecastGroupBeingEdited(true);
        analyticsService.ctaClicked({
          text: 'Reset',
          purpose: 'Reset forecast',
        });
      }}
      iconFontSize={buttonIconSize}
      size={buttonHoverSize}
    />
  );
  const hasPendingReset = currentPendingOverride === 'reset';
  const hasPendingOverrideChanges = currentPendingOverride !== 'no change';
  const hasStoredOverride = annualizedReturnOverride !== undefined;
  const shouldShowRevertButton = !hasPendingReset && (hasStoredOverride || hasPendingOverrideChanges);
  const allActions =
    isEditing || !isVisible ? (
      // render two empty buttons to get the spacing right
      <>
        {emptyButton}
        {emptyButton}
      </>
    ) : (
      <>
        {shouldShowRevertButton ? revertButton : emptyButton}
        {editButton}
      </>
    );
  return <ActionGroup>{allActions}</ActionGroup>;
};

export default SpecificFactorForecastActions;

const ActionGroup = styled.div`
  display: flex;
`;

const SpecificFactorForecastActionButtonIcon = styled(ButtonIcon)`
  background-color: transparent;
`;

const EmptyButtonIcon = styled(ButtonIcon)`
  visibility: hidden;
`;
