import type { FC } from 'react';
import React from 'react';
import { ModalFooter } from '../../../../modal';

interface BenchmarksCompositeFooterProps {
  disableAddButton?: boolean;
  isNew?: boolean;
  hideExitWithoutSaving?: boolean;
  onAddButtonClick: () => void;
  onExitWithoutSavingClick: () => void;
  onCancelButtonClick: () => void;
}

const BenchmarksCompositeFooter: FC<React.PropsWithChildren<BenchmarksCompositeFooterProps>> = ({
  disableAddButton,
  isNew,
  hideExitWithoutSaving,
  onAddButtonClick,
  onExitWithoutSavingClick,
  onCancelButtonClick,
}) => (
  <ModalFooter
    onCancel={onCancelButtonClick}
    primaryLabel={isNew ? 'Add' : 'Save'}
    primaryDisabled={disableAddButton}
    onPrimaryClick={onAddButtonClick}
    secondaryLabel={!hideExitWithoutSaving ? 'Exit without Saving' : undefined}
    onSecondaryClick={!hideExitWithoutSaving ? onExitWithoutSavingClick : undefined}
    primaryClassName="qa-benchmark-save"
  />
);

export default BenchmarksCompositeFooter;
