import type { AnalysisBlock } from 'venn-api';
import type { AnalysisSubject, MetaData } from 'venn-utils';
import { formatDownloadMessage } from 'venn-utils';
import type { AttributionSubject, ReturnsGridSubject, RollingPeriodSettings } from 'venn-components';
import { RollingPeriodEnum } from 'venn-components';
import type { ExportMetaData } from './types';
import { getTextThemeProvider } from 'venn-ui-kit';

export const getBlocksParams = (exportMetaData: ExportMetaData, blocks?: AnalysisBlock[]): MetaData[] => {
  if (!blocks) {
    return [];
  }
  const { analysisConfig } = exportMetaData;
  const { relative, subject } = analysisConfig;
  const params: MetaData[] = [];
  blocks.forEach((block) => {
    switch (block.analysisBlockType) {
      case 'DRAWDOWN':
        if (!relative) {
          params.push(
            formatDownloadMessage(
              'Hypothetical Drawdown Periods Threshold',
              `${exportMetaData.hypotheticalDrawdownThreshold}%`,
            ),
          );
          if (exportMetaData.drawdownPeriod) {
            params.push(formatDownloadMessage('Drawdown Period', exportMetaData.drawdownPeriod));
          }
        }
        break;
      case 'ROLLING_VOLATILITY':
        params.push(
          formatDownloadMessage(
            relative ? 'Rolling Tracking Error Period' : 'Rolling Volatility Period',
            convertRollingSettings(exportMetaData.rollingVolatilityPeriod),
          ),
        );
        break;
      case 'ROLLING_SHARPE_RATIO':
        params.push(
          formatDownloadMessage(
            relative ? 'Rolling Information Ratio Period' : 'Rolling Sharpe Ratio Period',
            convertRollingSettings(exportMetaData.rollingSharpePeriod),
          ),
        );
        break;
      case 'ROLLING_BETA':
        params.push(
          formatDownloadMessage('Rolling Beta Period', convertRollingSettings(exportMetaData.rollingBetaPeriod)),
        );
        break;
      case 'ROLLING_BENCHMARK_CORRELATION':
        params.push(
          formatDownloadMessage(
            'Rolling Benchmark Correlation Period',
            convertRollingSettings(exportMetaData.rollingBenchmarkCorrelationPeriod),
          ),
        );
        break;
      case 'ROLLING_RETURN':
        params.push(
          formatDownloadMessage(
            relative ? 'Rolling Excess Return Period' : 'Rolling Return Period',
            convertRollingSettings(exportMetaData.rollingReturnPeriod),
          ),
        );
        break;
      case 'CUMULATIVE_RETURN':
        const venncastName = getTextThemeProvider().VenncastName;
        params.push(
          formatDownloadMessage(venncastName, relative ? 'Disabled' : exportMetaData.venncast ? 'On' : 'Off'),
        );
        break;
      case 'RETURNS_GRID':
        params.push(
          formatDownloadMessage(
            `${relative ? 'Relative ' : ''}Calendar Returns`,
            getSubjectNameForGrid(exportMetaData.returnsGridSubject, subject),
          ),
        );
        params.push(
          formatDownloadMessage(
            `${relative ? 'Relative ' : ''}Calendar Returns Frequency`,
            exportMetaData.returnsGridFrequency,
          ),
        );
        break;
      case 'CORRELATION':
        params.push(
          formatDownloadMessage(
            `${relative ? 'Relative ' : ''}Relative Correlation Residual`,
            exportMetaData.residualCorrelation ? 'On' : 'Off',
          ),
        );
        break;
      case 'HISTORICAL_DRAWDOWN_PERIODS':
        params.push(
          formatDownloadMessage(
            relative ? 'Historical Underperformance Periods  Threshold' : 'Historical Drawdown Periods Threshold',
            `${exportMetaData.historicalDrawdownThreshold}%`,
          ),
        );
        break;
      case 'PERFORMANCE_ATTRIBUTION':
        params.push(
          formatDownloadMessage(
            `${relative ? 'Relative ' : ''}Performance Attribution`,
            exportMetaData.attributionsView,
          ),
        );
        params.push(
          formatDownloadMessage(
            `${relative ? 'Relative ' : ''}Performance Attribution Subject`,
            getSubjectNameForAttr(exportMetaData.attributionsSubject, subject),
          ),
        );
        break;
      case 'FACTOR_CONTRIBUTION_TO_EXPOSURE_TREND':
        params.push(
          formatDownloadMessage(
            relative ? 'Active Factor Exposures (β) Rolling Period' : 'Factor Exposures (β) Rolling Period',
            `${parseRollingYear(exportMetaData.rollingFactorExposuresPeriod)}`,
          ),
        );
        break;
      case 'FACTOR_CONTRIBUTION_TO_RETURN_TREND':
        params.push(
          formatDownloadMessage(
            relative
              ? 'Active Factor Contributions to Return Rolling Period'
              : 'Factor Contributions to Return Rolling Period',
            `${parseRollingYear(exportMetaData.rollingFactorReturnPeriod)}`,
          ),
        );
        break;
      case 'FACTOR_CONTRIBUTION_TO_RISK_TREND':
        params.push(
          formatDownloadMessage(
            relative
              ? 'Active Factor Contributions to Risk Rolling Period'
              : 'Factor Contributions to Risk Rolling Period',
            `${parseRollingYear(exportMetaData.rollingFactorRiskPeriod)}`,
          ),
        );
        break;
    }
  });
  return params;
};

const convertRollingYear = (rollingPeriod?: RollingPeriodEnum) =>
  rollingPeriod === RollingPeriodEnum.ONE_YEAR ? '1Y' : rollingPeriod === RollingPeriodEnum.THREE_YEAR ? '3Y' : '5Y';

const convertRollingSettings = (rollingSettings?: RollingPeriodSettings) => {
  return rollingSettings?.customMonths
    ? `${rollingSettings?.customMonths}M`
    : convertRollingYear(rollingSettings?.period);
};

const getSubjectNameForAttr = (attributeSubject: AttributionSubject, subject?: AnalysisSubject) => {
  const name = attributeSubject === 'category' ? subject?.categoryGroup?.name : subject?.name;
  return name ?? attributeSubject;
};

const getSubjectNameForGrid = (returnsGridSubject: ReturnsGridSubject, subject?: AnalysisSubject) => {
  const name =
    returnsGridSubject === 'category'
      ? subject?.categoryGroup?.name
      : returnsGridSubject === 'benchmark'
        ? subject?.activeBenchmarkName
        : subject?.name;
  return name ?? returnsGridSubject;
};

const parseRollingYear = (rollingYear?: number): string => {
  if (!rollingYear) {
    return 'None';
  }
  if (rollingYear < 1) {
    return `${Math.round(rollingYear * 12)}M`;
  }
  return `${Math.round(rollingYear)}Y`;
};
