import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import { Numbers } from 'venn-utils';
import StatefulNumericInput from '../../../../stateful-numeric-input/StatefulNumericInput';

const Wrapper = styled.div`
  width: 56px;
  margin-right: 10px;
  font-weight: normal;
  text-align: right;

  input {
    text-align: left;
  }

  & > div {
    width: 56px;
  }
`;

export interface WeightProps {
  value: string;
  onValueChange(value: string): void;
}

const formatter = (value: string | number) => Numbers.formatNumber(value, 1);

const qaClass = 'qa-weight';

const Weight: FC<React.PropsWithChildren<WeightProps>> = ({ onValueChange, value }) => {
  return (
    <Wrapper className={qaClass}>
      <StatefulNumericInput
        formatter={formatter}
        value={value}
        placeholder="0.0"
        min={0}
        max={100}
        precision={1}
        onValueChange={onValueChange}
      />
    </Wrapper>
  );
};

export default Weight;
