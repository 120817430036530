import React from 'react';
import { LocalizedFooterLink } from 'venn-components';
import { getAppTitle, getTextThemeProvider } from 'venn-ui-kit';

type Term = 'subscriber' | 'eula' | 'privacy' | 'marketing';

interface SignUpTermsProps {
  term: Term;
  jurisdiction?: string;
  buttonText?: string;
}

export const SignUpTerms = (props: SignUpTermsProps) => {
  const { term, jurisdiction, buttonText = 'Sign Up' } = props;
  const legalDocLinks = getTextThemeProvider().legalDocuments;
  switch (term) {
    case 'subscriber':
      // Ireland will show different term
      if (jurisdiction === 'IE') {
        return (
          <>
            By clicking "{buttonText}," I understand and accept the{' '}
            <LocalizedFooterLink link={legalDocLinks.SUBSCRIBER_AGREEMENT_LINK} jurisdiction={jurisdiction} /> on behalf
            of my company, accept the{' '}
            <LocalizedFooterLink link={legalDocLinks.USER_AGREEMENT_LINK} jurisdiction={jurisdiction} /> and represent
            that the legal entity or organization I represent in signing up for {getAppTitle()} is a Professional Client
            or otherwise provides Investment Business Services on a professional basis. Professional Client in this
            context has the meaning prescribed by Schedule 2 of S.I. No. 375 of 2017 European Union (Markets In
            Financial Instruments) Regulations 2017 and Investment Business Services has the meaning prescribed by the
            Irish Investment Intermediaries Act, 1995.
          </>
        );
      }
      if (jurisdiction === 'CA') {
        return (
          <>
            By clicking "{buttonText}," I understand and accept the{' '}
            <LocalizedFooterLink link={legalDocLinks.SUBSCRIBER_AGREEMENT_LINK} jurisdiction={jurisdiction} /> on behalf
            of my company, accept the{' '}
            <LocalizedFooterLink link={legalDocLinks.USER_AGREEMENT_LINK} jurisdiction={jurisdiction} /> and represent
            that my organization is a “permitted client” under National Instrument 31-103 or a registered adviser or
            dealer in Canada.
          </>
        );
      }
      return (
        <>
          By clicking “{buttonText}”, I accept the{' '}
          <LocalizedFooterLink link={legalDocLinks.SUBSCRIBER_AGREEMENT_LINK} jurisdiction={jurisdiction} /> on behalf
          of my company, and I accept the{' '}
          <LocalizedFooterLink link={legalDocLinks.USER_AGREEMENT_LINK} jurisdiction={jurisdiction} />.
        </>
      );
    case 'privacy':
      return (
        <>
          Subscriber has read the{' '}
          <LocalizedFooterLink link={legalDocLinks.PRIVACY_POLICY_LINK} jurisdiction={jurisdiction} /> and, if it is a
          resident of or located in the European Economic Area, consents to the transfer of its Personal Information as
          described in the Privacy Policy. If Subscriber is a single family office, it acknowledges it has received and
          read Provider’s <LocalizedFooterLink link={legalDocLinks.CRS_LINK} jurisdiction={jurisdiction} />.
        </>
      );
    case 'marketing':
      return <>I agree to receive other communications from Two Sigma.</>;
    case 'eula':
      return (
        <>
          By clicking “{buttonText}”, I accept the{' '}
          <LocalizedFooterLink link={legalDocLinks.USER_AGREEMENT_LINK} jurisdiction={jurisdiction} />.
        </>
      );
    default:
      return null;
  }
};
