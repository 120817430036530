import React from 'react';
import { default as styled } from 'styled-components';
import { Caption, GetColor, Icon, Link as LinkStyle, CURRENCY_CONVERSION_ARTICLE_HREF } from 'venn-ui-kit';

const CurrencyConversionWarning = () => (
  <Caption data-testid="currency-conversion-warning">
    <WarningIcon type="exclamation-circle" prefix="fas" /> Analyses convert your data into your organization currency.{' '}
    <LinkStyle>
      <a target="_blank" rel="noopener noreferrer" href={CURRENCY_CONVERSION_ARTICLE_HREF}>
        Learn more
      </a>
    </LinkStyle>
  </Caption>
);

const WarningIcon = styled(Icon)`
  color: ${GetColor.Alert};
`;

export default CurrencyConversionWarning;
