import { logExceptionIntoSentry, userActivity } from 'venn-utils';
import type { AtomEffect } from 'recoil';

export type LocalStorageEffectParams<T> = Pick<Parameters<AtomEffect<T>>[0], 'setSelf' | 'onSet'>;

/** Syncs the atom to persistent storage for the current user (both read and write) rather than memory. */
export const localStorageEffect =
  <T>(key: string) =>
  ({ setSelf, onSet }: LocalStorageEffectParams<T>) => {
    const savedValue = userActivity.getLocalStorageItemPerUser(key);
    if (savedValue !== undefined) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue, _, isReset) => {
      try {
        isReset
          ? userActivity.removeLocalStorageItemPerUser(key)
          : userActivity.setLocalStorageItemPerUser(key, JSON.stringify(newValue));
      } catch (error) {
        // Don't bother syncing the new value
        logExceptionIntoSentry(error);
      }
    });
  };
