import React, { useState, useCallback, useRef } from 'react';
import { css, default as styled } from 'styled-components';
import type { CellLoaderProps } from 'venn-ui-kit';
import { Icon, GetColor, KeyCodes, EllipsisTooltipSpan, CellLoader } from 'venn-ui-kit';
import Input from '../../../input/Input';

interface EditableTextProps {
  text: string;
  placeholder?: string;
  allowEmptyStrings?: boolean;
  displayEditIconByDefault?: boolean;
  displayEditViewByDefault?: boolean;
  onSubmit: (updatedText: string) => void;
  onStartEditing?: () => void;
  onStopEditing?: () => void;
}

const HEIGHT = 28;
const MAX_WIDTH = 415;

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default ({
  text,
  placeholder,
  allowEmptyStrings,
  displayEditIconByDefault,
  displayEditViewByDefault,
  onSubmit,
  onStartEditing,
  onStopEditing,
}: EditableTextProps) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const onBlurInput = useCallback(
    async (event: React.FocusEvent<HTMLInputElement>) => {
      if (event.target.value === '' && !allowEmptyStrings) {
        return;
      }
      setIsEditing(false);
      if (event.target.value === text) {
        onStopEditing && onStopEditing();
        return;
      }
      setIsSubmitting(true);
      await onSubmit(event.target.value);
      setIsSubmitting(false);
      onStopEditing && onStopEditing();
    },
    [text, allowEmptyStrings, onSubmit, onStopEditing],
  );

  const onFocusInput = useCallback(() => {
    onStartEditing && onStartEditing();
    setIsEditing(true);
  }, [onStartEditing]);

  if (isEditing || displayEditViewByDefault) {
    return (
      <StyledInput
        setInputRef={inputRef}
        defaultValue={text}
        onBlur={onBlurInput}
        autoFocus
        selectOnFocus
        placeholder={placeholder}
        className="qa-edit-name"
        onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.keyCode !== KeyCodes.Enter) {
            return;
          }
          inputRef && inputRef.current && inputRef.current.blur();
        }}
      />
    );
  }
  return (
    <EditableContainer
      tabIndex={0}
      hideIconByDefault={!displayEditIconByDefault}
      onClick={onFocusInput}
      className="qa-edit-name-button"
    >
      <TextWrapper>
        {/* Loader's margin bottom is (row height / 2 - 1.5 * CellLoader height) */}
        <EllipsisTooltipSpan flex maxWidth={MAX_WIDTH}>
          {text === '' ? placeholder : text}
        </EllipsisTooltipSpan>{' '}
        {isSubmitting && <Loader marginBottom={HEIGHT / 2 - 9} />}
      </TextWrapper>
      <Icon type="pen" />
    </EditableContainer>
  );
};

const EditableContainer = styled.div<{ hideIconByDefault?: boolean }>`
  flex: 1;
  position: relative;
  left: -5px;
  padding: 0 5px;

  height: ${HEIGHT}px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ hideIconByDefault }) =>
    hideIconByDefault &&
    css`
      i {
        display: none;
      }
    `}

  &:hover,
  &:focus {
    background-color: ${GetColor.PaleGrey};
    border-radius: 2px;
    i {
      font-size: 14px;
      display: inline-block;
      color: ${GetColor.MidGrey2};
    }
  }
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  height: ${HEIGHT}px;
  flex: 1 1 auto;
`;

const StyledInput = styled(Input)`
  flex: 1;
  position: relative;
  left: -6px;
  padding: 0 5px;

  height: ${HEIGHT}px;

  input {
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    margin: 0;
  }
`;

const Loader = styled(CellLoader)<CellLoaderProps & { marginBottom: number }>`
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
  margin-left: 4px;
`;
