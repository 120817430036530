import React from 'react';
import { getCurrentTheme } from 'venn-ui-kit';
import { LogoColor, UpdatedLogoColor } from '../../../venn-ui-kit/src/themes/default/BrandLogo';
import { useHasFF } from 'venn-utils';

const Logo = () => {
  const brandMarkUpdate = useHasFF('brand_mark_update_ff');
  if (getCurrentTheme() !== 'default') {
    // altivant logo looks poorly as this kind of background, better to remove
    // TODO (VENN-22409)
    return null;
  }
  return (
    <svg width="1178px" height="1077px" viewBox="0 0 1178 1077" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="wireframes" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.1">
        <g id="factorEducation_factorBasics" transform="translate(-742.000000, -98.000000)" fillRule="nonzero">
          <g id="BG">
            <g id="logo_BG" transform="translate(742.000000, 98.000000)">
              <polygon
                id="Fill-19"
                fill={brandMarkUpdate ? UpdatedLogoColor.Logo1 : LogoColor.Logo1}
                points="300.34931 0 0 0 150.650691 262 451 262"
              />
              <polygon
                id="Fill-20"
                fill={brandMarkUpdate ? UpdatedLogoColor.Logo1 : LogoColor.Logo1}
                points="1090.34931 262 1241 0 940.65069 0 790 262"
              />
              <polygon id="Fill-21" fill={LogoColor.Logo5} points="788.105908 262 939 0 299 0 449.894093 262" />
              <polygon
                id="Fill-22"
                fill={brandMarkUpdate ? UpdatedLogoColor.Logo1 : LogoColor.Logo1}
                points="769.600946 815.573545 619.000002 554.978382 468.399054 815.573545 148.480062 262 148 262 619.000002 1077 1090 262 1089.51994 262"
              />
              <polygon
                id="Fill-23"
                fill={LogoColor.Logo5}
                points="621 556.201575 451.874379 263 151 263 470.565474 817"
              />
              <polygon
                id="Fill-24"
                fill={LogoColor.Logo5}
                points="620 556.201575 770.434525 817 1090 263 789.12562 263"
              />
              <polygon
                id="Fill-25"
                fill={brandMarkUpdate ? UpdatedLogoColor.Logo3 : LogoColor.Logo3}
                points="451 260 620.997324 554 791 260"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Logo;
