import { assertExhaustive } from 'venn-utils';

type ClassOrClassFunc<T extends unknown[]> =
  | undefined
  | null
  | string
  | string[]
  | ((...args: T) => null | undefined | string | string[]);

export function lazyMergeClasses<T extends unknown[]>(
  ...classOrFunc: ClassOrClassFunc<T>[]
): (...args: T) => undefined | string | string[] {
  return (...params: T) => classOrFunc.flatMap((classOrFunc) => classToArr(classOrFunc, ...params));
}

export function classToArr<T extends unknown[]>(classOrFunc: ClassOrClassFunc<T>, ...params: T): string[] {
  if (!classOrFunc) return [];
  if (typeof classOrFunc === 'string') return [classOrFunc];
  if (Array.isArray(classOrFunc)) return classOrFunc;

  if (typeof classOrFunc === 'function') {
    return classToArr(classOrFunc(...params), ...params);
  }

  throw assertExhaustive(classOrFunc, `unexpected type of classOrClassFunc: ${classOrFunc}`);
}
