import React from 'react';
import styled from 'styled-components';
import Stage from './Stage';
import type { Step } from './Stage';

interface StepperProps {
  steps: Step[];
  currentStep: number;
  stepWidth?: number;
}

const Stepper = ({ steps, currentStep, stepWidth }: StepperProps) => {
  return (
    <Dots>
      {steps.map((step) => (
        <Stage step={step} currentStep={currentStep} key={step.key} stepWidth={stepWidth} />
      ))}
    </Dots>
  );
};

export default Stepper;

const Dots = styled.div`
  display: flex;
  justify-content: center;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
`;
