import React from 'react';
import styled from 'styled-components';
import { blockFonts, getCustomFontSize } from 'venn-state';
import { REPORT_LAB_FONT_BODY } from 'venn-ui-kit';
import { useRecoilValue } from 'recoil';
import { useBlockId } from '../../../contexts/BlockIdContext';

const VerticalHeader: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const blockId = useBlockId();
  const tableFont = useRecoilValue(blockFonts.blockTableData(blockId));
  return (
    <div>
      <VerticalHeaderWrapper fontSize={getCustomFontSize(tableFont)}>
        <VerticalHeaderInner>{children}</VerticalHeaderInner>
      </VerticalHeaderWrapper>
    </div>
  );
};

export default VerticalHeader;

const VerticalHeaderWrapper = styled.div<{ fontSize: string }>`
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  ${({ fontSize }) => (fontSize ? `font-size: ${fontSize};` : `font-size: ${REPORT_LAB_FONT_BODY};`)}
`;

const VerticalHeaderInner = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  height: 105px;
  padding: 0px;
  margin: 0px;
  transform: translateY(-100%) rotate(180deg);
  writing-mode: vertical-rl;

  -line-clamp: 1;
  line-clamp: 1;
  white-space: nowrap;
`;
