import { compact } from 'lodash';
import type { ActionEnum, AnalysisViewTypeEnum } from 'venn-api';
import type { ItemType } from 'venn-ui-kit';
import { FS } from 'venn-utils';

export const ALL_ITEM_TYPE: AnalysisViewTypeEnum[] = [
  'ANALYSIS',
  'COMPARE',
  'CUSTOM',
  'ASSEMBLY',
  'ASSEMBLY_CHILD',
  'TEARSHEET',
  'TEARSHEET_TEMPLATE',
  'REPORT',
  'REPORT_TEMPLATE',
];

export const getExcludedAnalysisTypes = (
  hasPermission: (permission: ActionEnum) => boolean,
  itemTypes: ItemType[] = [],
) => {
  const allTypes = ALL_ITEM_TYPE;
  const enableStudio = FS.has('studio_ff');
  const enableReport = FS.has('studio_report_editor');
  const enableCompare = hasPermission('COMPARE');

  const defaultExcludedAnalysisViewTypes: AnalysisViewTypeEnum[] = compact([
    'ASSEMBLY',
    'ASSEMBLY_CHILD',
    enableStudio ? null : 'TEARSHEET',
    enableReport ? null : 'REPORT',
    enableStudio ? null : 'TEARSHEET_TEMPLATE',
    enableReport ? null : 'REPORT_TEMPLATE',
    enableCompare ? null : 'COMPARE',
  ]);

  return !itemTypes.length
    ? defaultExcludedAnalysisViewTypes
    : allTypes.filter((type) => !itemTypes.find((t) => t === type));
};
