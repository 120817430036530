import React from 'react';
import { FlexHeader } from '../../../../../shared';
import { BlockBenchmarkInput } from './BlockBenchmarkInputList';
import { Warning } from 'venn-ui-kit';
import { hasDisabledIndividualBenchmark, hasDisabledRelativeToBenchmark, isPrivatesPerformanceBlock } from 'venn-utils';
import { useRecoilValue } from 'recoil';
import { blockBenchmarkConfig, blockSettings } from 'venn-state';

/** Section for editing a block config with regard to its {@link blockBenchmarkInput}: viewing, adding, editing, and deleting benchmark input groups. */
export const BenchmarkInputSection = React.memo(function BenchmarkInputSection({
  selectedBlockId,
}: {
  selectedBlockId: string;
}) {
  const settings = useRecoilValue(blockSettings(selectedBlockId));
  const blockTypeWithDisabledIndividualBenchmark = hasDisabledIndividualBenchmark(settings.customBlockType);
  const blockTypeWithDisabledRelativeToBenchmark = hasDisabledRelativeToBenchmark(settings.customBlockType);
  const benchmarkConfig = useRecoilValue(blockBenchmarkConfig(selectedBlockId));
  const isPerformanceBasedPrivate = isPrivatesPerformanceBlock(settings.customBlockType);

  return (
    <div data-testid="qa-block-benchmark">
      <FlexHeader style={{ justifyContent: 'space-between', marginBottom: '12px' }}>
        <span>{isPerformanceBasedPrivate ? 'Benchmark for Public Market Equivalent' : 'Benchmark'}</span>
      </FlexHeader>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        {benchmarkConfig.relative && blockTypeWithDisabledRelativeToBenchmark && (
          <Warning text="Relative to benchmark is not supported for this block type." />
        )}
        {benchmarkConfig.type === 'INDIVIDUAL' && blockTypeWithDisabledIndividualBenchmark && (
          <Warning text="Individual benchmarks will not be shown in this block. Please select common benchmark." />
        )}
        <BlockBenchmarkInput selectedBlockId={selectedBlockId} />
      </div>
    </div>
  );
});
