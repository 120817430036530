import type React from 'react';
import { useContext } from 'react';
import { RangeDebugContext } from '../../contexts';

interface PortfolioManageDataTrigger {
  children?: (onClick: () => void) => JSX.Element | null;
  hidden?: boolean;
}

const PortfolioManageDataTrigger: React.FC<PortfolioManageDataTrigger> = ({
  hidden,
  children,
}: PortfolioManageDataTrigger) => {
  const { openManageData } = useContext(RangeDebugContext);
  if (hidden || !children) {
    return null;
  }

  return children(openManageData);
};

export default PortfolioManageDataTrigger;
