import React from 'react';
import styled from 'styled-components';
import { BaselineAlignedFlexWrapper, FlexRow, FlexWrapper, Info, Name, SmallName, StyledIcon } from '../shared';
import useWhatsNewSectionData from './logic/useWhatsNewSectionData';

import ResetButton from './components/ResetButton';
import EditButton from './components/EditButton';
import { getAppTitle } from 'venn-ui-kit';

const WhatsNewCustomization = ({ sponsorAccountId }: { sponsorAccountId: number }) => {
  const { isDefault, whatsNewData, isLoading, handleOnEdit, handleOnReset } = useWhatsNewSectionData(sponsorAccountId);

  return (
    <>
      <FlexRow>
        <div>
          <FlexWrapper>
            <SponsorIcon type="sparkles" />
            <Name>“What’s New?” Content Customization</Name>
          </FlexWrapper>
          <Subtitle>{`Override ${getAppTitle()}’s home page “What’s New?” content.`}</Subtitle>
        </div>
      </FlexRow>
      <InfoWrapper className="qa-title">
        <SmallName>Title:</SmallName>
        <Info>{whatsNewData?.title || '--'}</Info>
      </InfoWrapper>
      <InfoWrapper className="qa-preview">
        <SmallName>Content Preview Text:</SmallName>
        <Info>{whatsNewData?.text || '--'}</Info>
      </InfoWrapper>
      <InfoWrapper className="qa-link">
        <SmallName>Link Text:</SmallName>
        <Info>{whatsNewData?.link || '--'}</Info>
      </InfoWrapper>
      <InfoWrapper className="qa-url">
        <SmallName>URL:</SmallName>
        <Info>{whatsNewData?.url || '--'}</Info>
      </InfoWrapper>
      <ActionsSectionWrapper>
        <EditButton disabled={isLoading} whatsNewData={whatsNewData} onConfirm={handleOnEdit} />
        <ResetButton disabled={isLoading || isDefault} isDefault={isDefault} onConfirm={handleOnReset} />
      </ActionsSectionWrapper>
    </>
  );
};

export default WhatsNewCustomization;

const Subtitle = styled(Info)`
  margin-bottom: 30px;
`;

const InfoWrapper = styled(BaselineAlignedFlexWrapper)`
  margin-bottom: 10px;
`;

const SponsorIcon = styled(StyledIcon)`
  margin-bottom: 10px;
`;

const ActionsSectionWrapper = styled.div`
  margin-top: 25px;
`;
