import type { ReactNode } from 'react';
import React, { useContext } from 'react';
import type { NotablePeriodZipped } from '../logic/notablePeriodsColumns';
import type { AnalysisLabelShape } from 'venn-utils';
import { SpecialCssClasses } from 'venn-utils';
import type { DropMenuItem } from 'venn-ui-kit';
import { GetColor, ColorUtils, Icon } from 'venn-ui-kit';
import type { BasicTableColumn, BasicTableProps, StyledTableType } from 'venn-components';
import { ContentChartLegend, BasicTable, EmptyState, NumberInput } from 'venn-components';
import styled, { ThemeContext, css } from 'styled-components';
import { BarChartAxisFooter } from './scenario-analysis/BarChart';
import NotablePeriodPicker from './NotablePeriodPicker';
import { compact } from 'lodash';

export interface ContentChartLegendItem {
  name: string;
  color: string;
  estimated?: boolean;
  dashLine?: boolean;
}

interface PickerProps {
  items: DropMenuItem<number>[];
  onAdd: (id: number) => void;
  onDelete: (id: number) => void;
}

interface NotablePeriodChartProps {
  chartData: NotablePeriodZipped[];
  hasBenchmark: boolean;
  hasComparison: boolean;
  hasCategory: boolean;
  labels: AnalysisLabelShape;
  max: number;
  filter?: {
    threshold: number;
    setThreshold: (threshold: number) => void;
  };
  filterAbove?: boolean;
  picker?: PickerProps;
  columns: BasicTableColumn<NotablePeriodZipped>[];
  emptyHeader: string;
  emptyMessage: string;
  selectable?: boolean;
  onSelect?: (period: NotablePeriodZipped) => void;
}

const NotablePeriodChart: React.FunctionComponent<React.PropsWithChildren<NotablePeriodChartProps>> = ({
  hasBenchmark,
  hasComparison,
  hasCategory,
  chartData,
  labels,
  max,
  filter,
  filterAbove,
  columns,
  picker,
  emptyHeader,
  emptyMessage,
  selectable,
  onSelect,
}) => {
  const {
    Schemes: { BarChartColors },
  } = useContext(ThemeContext);
  const legendItems: ContentChartLegendItem[] = compact([
    {
      name: labels.main,
      color: BarChartColors.mainColor,
    },
    hasComparison
      ? {
          name: labels.comparison!,
          color: BarChartColors.secondaryColor,
        }
      : null,
    hasBenchmark
      ? {
          name: labels.benchmark,
          color: hasComparison ? BarChartColors.comparisonColor : BarChartColors.secondaryColor,
        }
      : null,
    hasCategory
      ? {
          name: labels.category!,
          color: hasBenchmark ? BarChartColors.comparisonColor : BarChartColors.secondaryColor,
        }
      : null,
  ]);

  const colSpan = columns && columns.length;
  return (
    <>
      {filter && filterAbove && (
        <InputContainerDiv tooHigh={chartData.length === 0}>
          <strong>Threshold:</strong>
          <NumberInput
            value={filter.threshold}
            onChange={(value) => filter.setThreshold(value ?? 0)}
            precision={1}
            step={1}
            error={chartData.length === 0}
            className="qa-threshold-input"
            formatter={(value) => `+/- ${value} %`}
            parser={(value) => value.replace(/\+|\/|-|%| /g, '')}
          />
        </InputContainerDiv>
      )}
      <StyledBasicTable
        data={chartData}
        columns={columns}
        selectable={selectable}
        onRowSelect={onSelect}
        renderTail={() => {
          if (chartData.length) {
            return (
              <BarChartAxisFooter
                colSpan={colSpan - 1}
                max={max / 100}
                contents={
                  picker ? (
                    <AddPeriodContainer>
                      <PlusIcon type="plus-circle" prefix="far" />
                      <NotablePeriodPicker items={picker.items} onAdd={picker.onAdd} />
                    </AddPeriodContainer>
                  ) : undefined
                }
              />
            );
          }
          if (!picker) {
            return (
              <NoHoverTr>
                <EmptyStateTd colSpan={colSpan}>
                  <StyledEmptyState header={emptyHeader} message={emptyMessage} />
                </EmptyStateTd>
              </NoHoverTr>
            );
          }

          return (
            <>
              <AddPeriodContainer>
                <PlusIcon type="plus-circle" prefix="far" />
                <NotablePeriodPicker items={picker.items} onAdd={picker.onAdd} />
              </AddPeriodContainer>
              {chartData.length === 0 ? (
                <NoHoverTr>
                  <EmptyStateTd colSpan={colSpan}>
                    <StyledEmptyState header={emptyHeader} message={emptyMessage} />
                  </EmptyStateTd>
                </NoHoverTr>
              ) : undefined}
            </>
          );
        }}
      >
        <LegendRow>
          <td colSpan={2} style={{ paddingLeft: '10px' }}>
            {filter && !filterAbove ? (
              <InputContainerDiv tooHigh={chartData.length === 0}>
                Threshold:
                <NumberInput
                  value={filter.threshold}
                  onChange={(value) => filter.setThreshold(value ?? 0)}
                  precision={1}
                  step={1}
                  error={chartData.length === 0}
                  className="qa-threshold-input"
                  formatter={(value) => `+/- ${value} %`}
                  parser={(value) => value.replace(/\+|\/|-|%| /g, '')}
                />
              </InputContainerDiv>
            ) : null}
          </td>
          <LegendTd colSpan={colSpan - 2}>
            <ContentChartLegend items={legendItems} histogram />
          </LegendTd>
        </LegendRow>
      </StyledBasicTable>
    </>
  );
};

const HoverBackgroundColor = ColorUtils.hex2rgbaFrom(GetColor.Primary.Main, 0.1);

export const StyledBasicTable: StyledTableType<{
  children?: ReactNode;
}> = styled(<T extends BasicTableColumn<K>, K>(props: BasicTableProps<T, K>) => <BasicTable<T, K> {...props} />)<{
  children?: ReactNode;
}>`
  table-layout: fixed;
  margin: 30px 0 35px;
  width: 100%;

  color: ${GetColor.Black};

  && tr {
    border-bottom: 1px solid ${GetColor.Grey};
    height: 35px;
  }

  && tr:last-child {
    border-bottom: none;
  }

  && th {
    color: ${GetColor.Black};
  }

  tr:not(:first-child):hover {
    background-color: ${HoverBackgroundColor};
  }

  && thead tr:hover {
    background-color: transparent;
  }

  @media print {
    .${SpecialCssClasses.NotDownloadable} {
      display: none;
    }
  }
`;

const LegendTd = styled.td`
  > div {
    border-top: none;
    border-bottom: none;
    padding-left: 0;
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
  }
`;

const LegendRow = styled.tr`
  background-color: ${GetColor.WhiteGrey};
  border-top: 2px solid ${GetColor.Black};
  height: 56px;
`;

const InputContainerDiv = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
  ${(props: { tooHigh: boolean }) => props.tooHigh && errorColor}
  padding-left: 20px;
`;

const errorColor = css`
  color: ${GetColor.Error};
`;

const StyledEmptyState = styled(EmptyState)`
  margin: 20px;
`;

const NoHoverTr = styled.tr`
  &&&:hover {
    background-color: transparent;
  }
`;

const AddPeriodContainer = styled.div`
  flex-direction: row;
  display: inline-flex;
  align-items: center;

  .hover-show {
    opacity: 0;
    display: block;
    transition: all 0.2s linear;
  }

  .hover-hide {
    opacity: 1;
    display: block;
    transition: all 0.2s linear;
  }

  &:hover .hover-show {
    opacity: 1;
  }

  &:hover .hover-hide {
    opacity: 0;
  }
`;

const PlusIcon = styled(Icon)`
  color: ${GetColor.Primary.Dark};
  font-size: 20px;
  margin: 10px;
`;

const EmptyStateTd = styled.td`
  column-span: all;
  min-width: 100%;
`;

export default NotablePeriodChart;
