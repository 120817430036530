import moment from 'moment';
import sortBy from 'lodash/sortBy';
import type { AutocompleteResponse, AutocompleteResult, ItemId } from 'venn-api';
import { autocomplete } from 'venn-api';
import type { Scope } from './types';
import {
  SCOPE_OPTION_ALL_FUNDS,
  SCOPE_OPTION_INDEX_FUNDS,
  SCOPE_OPTION_USER_UPLOADED_FUNDS,
  NEW_INSTRUMENT,
} from './types';
import type { AnyDuringEslintMigration } from 'venn-utils';

const YEAR_FORMAT = 'YYYY';
let fetchToken = 0;

export const getScopeOptions = (): (() => Scope)[] => [
  SCOPE_OPTION_ALL_FUNDS,
  SCOPE_OPTION_INDEX_FUNDS,
  SCOPE_OPTION_USER_UPLOADED_FUNDS,
];

const sortResponsesByName = (response: AutocompleteResponse, query: string) => {
  // exact match goes first
  const exactMatchComparator = (result: AutocompleteResult) => result.name.toLowerCase() !== query.toLowerCase();
  const nameSortComparator = (result: AutocompleteResult) => result.name.toLowerCase();
  return sortBy(response.results, [exactMatchComparator, nameSortComparator]);
};

export const getYearRange = (startRange?: number, endRange?: number) => {
  if (!startRange && !endRange) {
    return '';
  }

  const startYear = moment.utc(startRange).format(YEAR_FORMAT);
  const endYear = moment.utc(endRange).format(YEAR_FORMAT);

  if (startYear === endYear) {
    return startYear;
  }

  return `${startYear}-${endYear}`;
};

interface OptionsFetcherOptions {
  scope: Scope | string;
  excludedItems: ItemId[];
  forceSelection?: boolean;
}

export const fetchOptions = async (
  query: string,
  { scope, excludedItems, forceSelection }: OptionsFetcherOptions,
): Promise<AutocompleteResult[] | null> => {
  const activeFetchToken = fetchToken + 1;
  fetchToken = activeFetchToken;

  try {
    const response = await autocomplete({
      query,
      count: 10,
      excludedItems,
      scope: scope as AnyDuringEslintMigration,
      returnScopes: false,
    });

    if (fetchToken === activeFetchToken) {
      const sortedResults = sortResponsesByName(response.content, query);
      if (!forceSelection) {
        sortedResults.unshift({
          name: query,
          type: NEW_INSTRUMENT,
        });
      }
      return sortedResults;
    }

    return null;
  } catch (error) {
    if (fetchToken === activeFetchToken) {
      throw error;
    }
  }

  return null;
};
