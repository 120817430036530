import React from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import {
  analysisViewIdState,
  benchmarkInputName,
  benchmarkInputs,
  benchmarkInputSubject,
  benchmarkInputType,
  getNewBenchmarkInputId,
  isReportState,
  nextBenchmarkInputNameState,
} from 'venn-state';
import InputTitle from './InputTitle';
import Benchmark from './Benchmark';
import { analyticsService } from 'venn-utils';
import type { CustomBenchmarkTypeEnum } from 'venn-api';

const DEFAULT_BENCHMARK_TYPE: CustomBenchmarkTypeEnum = 'COMMON';

const BenchmarksSection = () => {
  const viewId = useRecoilValue(analysisViewIdState);
  const isReport = useRecoilValue(isReportState);
  const inputIds = useRecoilValue(benchmarkInputs);

  const onAddNew = useRecoilCallback(
    ({ set, snapshot }) =>
      () => {
        const newId = getNewBenchmarkInputId();
        const newNameLoadable = snapshot.getLoadable(nextBenchmarkInputNameState).valueOrThrow();

        analyticsService.inputsBenchmarkGroupCreated({
          name: newNameLoadable,
          type: DEFAULT_BENCHMARK_TYPE,
          benchmark: undefined,
          relative: false,
          sourcePage: isReport ? 'REPORT_LAB' : 'STUDIO',
          viewId,
        });

        set(benchmarkInputName(newId), newNameLoadable);
        set(benchmarkInputSubject(newId), undefined);
        set(benchmarkInputType(newId), DEFAULT_BENCHMARK_TYPE);
        set(benchmarkInputs, [...inputIds, newId]);
      },
    [inputIds, isReport, viewId],
  );

  return (
    <>
      <InputTitle title="Benchmarks" onAddNew={onAddNew} numberOfInputs={inputIds.length} />
      {inputIds.map((id) => (
        <Benchmark key={id} benchmarkId={id} deletable={inputIds.length > 1} />
      ))}
    </>
  );
};

export default BenchmarksSection;
