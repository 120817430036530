import type { FC } from 'react';
import React, { useContext } from 'react';
import { times } from 'lodash';
import { ThemeContext } from 'styled-components';

interface Props {
  num: number;
  cellWidth: number;
  height: number;
  offset: number;
}

const TopGridLines: FC<React.PropsWithChildren<Props>> = ({ num, cellWidth, height, offset }) => {
  const { Colors } = useContext(ThemeContext);
  return (
    <>
      {times(num + 1, (i) => (
        <g key={i}>
          <line
            x1={offset + i * cellWidth}
            y1={0}
            x2={offset + i * cellWidth}
            y2={height}
            stroke={Colors.PaleGrey}
            strokeWidth={1}
          />
        </g>
      ))}
    </>
  );
};

export default TopGridLines;
