import { datadogRum } from '@datadog/browser-rum';
import { parse } from 'cookie';
import { IS_PROD } from '../environment';

export const COOKIE_DD_RUM_CONTEXT = 'dd_rum_context_attributes';

export const initializeDatadogRUM = () => {
  if (!process.env.DD_CLIENT_TOKEN) {
    return;
  }
  // This is used to be able to distinguish metrics from Datadog Real User Monitoring (RUM) produced by live users and those generated by our performance tests.
  const cookies = parse(document.cookie);
  if (cookies?.[COOKIE_DD_RUM_CONTEXT]) {
    const cookiePayload = JSON.parse(cookies[COOKIE_DD_RUM_CONTEXT]);
    if (typeof cookiePayload === 'object') {
      if (cookiePayload?.user_name) {
        datadogRum.setUser({ name: cookiePayload.user_name });
      }

      for (const [key, value] of Object.entries(cookiePayload)) {
        if (key !== 'user_name') {
          datadogRum.addRumGlobalContext(key, value);
        }
      }
    }
  }
  datadogRum.init({
    applicationId: '94218630-2bba-4b2a-921d-038d8c60ecd1',
    clientToken: process.env.DD_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: process.env.DD_SERVICE,
    env: process.env.ACTUAL_ENV,
    version: process.env.DD_VERSION,
    sampleRate: 100,
    trackUserInteractions: true,
    trackFrustrations: true,
    trackLongTasks: true,
    trackResources: true,
    allowedTracingOrigins: [
      'https://app.venn.twosigma.com',
      'https://dev.tsvenn.com',
      'https://staging.tsvenn.com',
      'https://preview.tsvenn.com',
      'https://merge-requests.tsvenn.com',
    ],
  });
  if (!IS_PROD) {
    datadogRum.startSessionReplayRecording();
  }
};
