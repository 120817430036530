import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useRef } from 'react';
import type { PeerGroupAnalysisResponse } from 'venn-api';
import { usePeerGroupsChartConfig } from './usePeerGroupsChartConfig';
import { useBlockId } from '../../../contexts/BlockIdContext';
import { useChartReflow } from '../../../../hooks/useChartReflow';

type ChartProps = {
  data: PeerGroupAnalysisResponse;
};

export const PeerGroupPercentileRankChart = ({ data }: ChartProps) => {
  const { columnStackOptions, isEmpty } = usePeerGroupsChartConfig(data);
  const blockId = useBlockId();
  const chartRef = useRef<HighchartsReact.RefObject>(null);
  useChartReflow(blockId, chartRef);

  if (isEmpty) {
    return null;
  }

  return (
    <HighchartsReact
      ref={chartRef}
      highcharts={Highcharts}
      options={columnStackOptions}
      containerProps={{ className: 'qa-chart-container' }}
    />
  );
};
