import React, { useEffect, useState, useRef } from 'react';
import type { AnalysisConfig } from 'venn-utils';
import { useApi, getAnalysisRequest } from 'venn-utils';
import { analysis } from 'venn-api';
import { Alert } from 'venn-ui-kit';

interface ConstrainedAnalysisAlertProps {
  analysisConfig: AnalysisConfig;
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default ({ analysisConfig }: ConstrainedAnalysisAlertProps) => {
  const [hasAlert, setHasAlert] = useState<boolean>(false);
  const apiRef = useRef(useApi(analysis));

  useEffect(() => {
    const loadAlert = async () => {
      const configs = getAnalysisRequest(
        ['RANGE_DEBUG'],
        analysisConfig.subject,
        {
          startTime: NaN,
          endTime: NaN,
        },
        analysisConfig.relative,
        analysisConfig.category === 'ON',
      );
      if (!configs) {
        return;
      }
      const categoryIdx =
        analysisConfig?.subject?.type !== 'investment'
          ? -1
          : configs?.subjects?.findIndex((subjectConfig) => subjectConfig.comparisonType === 'CATEGORY') ?? -1;
      if (categoryIdx === -1) {
        return;
      }

      try {
        const rangeDebugAnalysis = (await apiRef.current(configs)).content;
        const categoryRangeDebug = rangeDebugAnalysis?.analyses?.[0]?.rangeDebug?.[categoryIdx];
        if (!categoryRangeDebug) {
          return;
        }
        if (categoryRangeDebug.constrainingPrimaryStart || categoryRangeDebug.constrainingPrimaryEnd) {
          setHasAlert(true);
        }
      } catch (e) {
        // fail silently
      }
    };
    setHasAlert(false);
    if (analysisConfig) {
      loadAlert();
    }
  }, [analysisConfig]);

  return hasAlert ? (
    <Alert dismissable>The date range for this analysis has been restricted by your category selection.</Alert>
  ) : null;
};
