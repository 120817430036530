import React from 'react';
import { Link as LinkStyle } from 'venn-ui-kit';

export interface FactorEducationConfig {
  [key: string]: FactorEducation;
}

export interface FactorEducation {
  name: string;
  description: string;
  notablePeriods?: NotablePeriod[];
}

export interface NotablePeriod {
  start: number;
  end: number;
  contents: (JSX.Element | string)[];
}

export const AxiomaEquityStyleFactorsConstruction: { [key: string]: string } = {
  Value:
    'The Value factor is constructed using the following equally weighted metrics: Book to Price, Earnings Yield, and Dividend Yield.',
  Momentum: 'The Momentum factor is constructed using the following metric: Trailing Performance.',
  'Low Risk':
    'The Low Risk factor is constructed using the following equally weighted metrics: Beta and Residual Volatility.',
  Quality:
    'The Quality factor is constructed using the following equally weighted metrics: Profitability and Leverage.',
  'Small Cap': 'The Small Cap factor is constructed using the following metric: Market Capitalization.',
  Crowding: 'The Crowding factor is constructed using the following metric: Residual Short Interest.',
};

interface ParsedContent {
  content: JSX.Element | string;
  key: string;
}

export function parseContent(stringContent: string): ParsedContent[] {
  let jsonContent;
  try {
    jsonContent = JSON.parse(stringContent);
  } catch (e) {
    jsonContent = stringContent;
  }
  const arrayContent = Array.isArray(jsonContent) ? jsonContent : [jsonContent];
  const resultArray: ParsedContent[] = [];
  arrayContent.forEach((content: string) => {
    // Get all [label](https://link.shape)
    // Parts: "[" + {something else than "]"} + "](" + {something else then ")"} + ")"
    const allHyperlinks = content.match(/\[[^\]]+\]\([^\)]+\)/g);
    if (allHyperlinks === null) {
      resultArray.push({
        content,
        key: content,
      });
    } else {
      let position = 0;
      const contentParts: (JSX.Element | string)[] = [];
      allHyperlinks.forEach((hyperlink: string) => {
        const hyperlinkStart = content.indexOf(hyperlink, position);
        if (hyperlinkStart > position) {
          contentParts.push(content.substring(position, hyperlinkStart));
        }
        contentParts.push(getHyperlink(hyperlink));
        position = hyperlinkStart + hyperlink.length;
      });
      if (position < content.length - 1) {
        contentParts.push(content.substring(position, content.length));
      }
      resultArray.push({
        content: <div>{contentParts}</div>,
        key: content,
      });
    }
  });
  return resultArray;
}

function getHyperlink(hyperlink: string): JSX.Element {
  const endOfLabelIndex = hyperlink.indexOf(']');
  const label = hyperlink.substring(1, endOfLabelIndex);
  const link = hyperlink.substring(endOfLabelIndex + 2, hyperlink.length - 1);
  return (
    <LinkStyle key={hyperlink}>
      <a target="_blank" rel="noopener noreferrer" href={link}>
        {label}
      </a>
    </LinkStyle>
  );
}
