import React from 'react';
import { useModal } from 'venn-utils';
import { StyledNavigationPseudoLink } from './NavigationLinks';
import { NavigationMenu, TopNavigationMenu } from './NavigationMenu';
import styled, { css } from 'styled-components';
import { ExternalActivityListener, GetColor, Icon, Tooltip, TooltipPosition } from 'venn-ui-kit';
import type { NavigationSubItem } from './shellNavigationConfigInitializer';

interface NavigationSubLinksProps {
  subItems: NavigationSubItem[];
  trigger: React.ReactNode;
  tabIndex?: number;
  active?: boolean;
  showTopNav?: boolean;
  subItemsHeader?: string;
  tooltipContent?: string;
}

const NavigationSubLinks = ({
  tabIndex,
  active,
  showTopNav,
  trigger,
  subItemsHeader,
  subItems,
  tooltipContent,
}: NavigationSubLinksProps) => {
  const [isOpen, open, close] = useModal();

  const toggle = () => {
    isOpen ? close() : open();
  };

  const Menu = showTopNav ? TopNavigationMenu : NavigationMenu;
  const menuTrigger = tooltipContent ? (
    <Tooltip key={tooltipContent} position={TooltipPosition.Bottom} content={tooltipContent}>
      <StyledNavigationPseudoLink showTopNav={showTopNav} onClick={toggle} tabIndex={tabIndex} active={active}>
        {trigger}
      </StyledNavigationPseudoLink>
    </Tooltip>
  ) : (
    <StyledNavigationPseudoLink showTopNav={showTopNav} onClick={toggle} tabIndex={tabIndex} active={active}>
      {trigger}
    </StyledNavigationPseudoLink>
  );

  return (
    <ExternalActivityListener listeningEnabled={isOpen} onExternalActivity={close}>
      {menuTrigger}
      {isOpen && (
        <Menu>
          <Container>
            {subItemsHeader && (
              <Header>
                <Name>{subItemsHeader}</Name>
              </Header>
            )}
            {subItems.map((item) => (
              <StyledLink
                className={item.className}
                as="a"
                href={item.path}
                target="_blank"
                onClick={item.onClick}
                key={item.label}
              >
                {item.label}
                {item.notification && <Notification type="circle" />}
              </StyledLink>
            ))}
          </Container>
        </Menu>
      )}
    </ExternalActivityListener>
  );
};

export default NavigationSubLinks;

const Notification = styled(Icon)`
  color: ${GetColor.Primary.Main};
  font-size: 10px;
  padding-left: 5px;
`;

const hoverStyles = css`
  :hover,
  :focus {
    background: ${GetColor.PaleGrey};
    color: ${GetColor.Black};
  }
`;

const StyledLink = styled.button`
  padding: 0 20px;
  width: 100%;
  height: 23px;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: normal;
  display: flex;
  align-items: center;
  color: ${GetColor.Black};
  ${hoverStyles};
`;

const Container = styled.div`
  padding: 0px 0px 7px;
`;

const Header = styled.div`
  background: ${GetColor.PaleGrey};
  padding: 13px 20px 20px;
  font-weight: normal;
  letter-spacing: normal;
  margin-bottom: 7px;
`;

const Name = styled.div`
  color: ${GetColor.Black};
  font-size: 18px;
  line-height: 20px;
`;
