import type {
  FrequencyEnum,
  LibrarySearchEntity,
  LibrarySearchResult,
  MetricFilter,
  OperationResult,
  OrderEnum,
  SearchQuery,
} from 'venn-api';
import { getFactorCMAs } from 'venn-api';
import omit from 'lodash/omit';
import debounce from 'lodash/debounce';
import flatMap from 'lodash/flatMap';
import { SORTDIR } from 'venn-components';
import { FundUtils, getAnalysisPath } from 'venn-utils';
import type { SearchResultWithUIState, SearchState } from './types';
import { ItemType } from 'venn-ui-kit';
import type React from 'react';
import { compact, isMatch, get } from 'lodash';
import isNil from 'lodash/isNil';
import type { SearchParams } from 'venn-state';
import type { RowNode } from 'ag-grid-community';

export const VENN_DEMO_PORTFOLIO_ID = -999999;
export const MASTER_PORTFOLIO_NO_DELETION_MESSAGE =
  "Your workspace's master portfolio can not be deleted. Visit Workspace Configuration to change your master.";

export const getAnalysisRouteForItem = (item: SearchResultWithUIState) =>
  getAnalysisPath(item.portfolioId ? 'portfolio' : 'investment', item.portfolioId ?? item.fundId);

const getOnChangeForField = (
  updateData: React.Dispatch<React.SetStateAction<SearchState>>,
  field: keyof SearchResultWithUIState,
  item?: SearchResultWithUIState,
  value?: boolean,
) => {
  updateData((prev) => ({
    ...prev,
    results: prev.results.map((dataItem) => {
      if (item === undefined || isMatch(item, dataItem)) {
        return {
          ...dataItem,
          [field]: value ?? !dataItem[field],
        };
      }
      return dataItem;
    }),
  }));
};

export const toggleItemSelected = (
  item: SearchResultWithUIState,
  updateData: React.Dispatch<React.SetStateAction<SearchState>>,
) => {
  return getOnChangeForField(updateData, 'selected', item);
};

export const setAllSelected = (selected: boolean, updateData: React.Dispatch<React.SetStateAction<SearchState>>) => {
  return getOnChangeForField(updateData, 'selected', undefined, selected);
};

export const convertToFund = (item: SearchResultWithUIState) => ({
  ...omit(item, ['fundId', 'portfolioId']),
  id: item.fundId,
  tags: item.tags ?? [],
});

export const getSortDirection = (sortDir: SORTDIR): OrderEnum => (sortDir === SORTDIR.ASC ? 'asc' : 'desc');
export const convertToSortDir = (order: OrderEnum): SORTDIR => (order === 'asc' ? SORTDIR.ASC : SORTDIR.DESC);

export const getId = (item: SearchResultWithUIState) => {
  if (!item) {
    return null;
  }
  return item.fundId ?? item.portfolioId;
};

export const getFrequency = (frequency: FrequencyEnum): number => {
  const fre = ['UNKNOWN', 'DAILY', 'MONTHLY', 'QUARTERLY', 'YEARLY'].indexOf(frequency);
  // Default to be MONTHLY
  return fre < 1 ? 2 : fre;
};

const StateZeroTickerSet = new Set([
  '38J',
  '1S',
  '39S',
  'BCOMTR',
  'PUT',
  'VIX',
  '892400',
  '990300',
  '891800',
  'RUT',
  'SP500TR',
]);

const debouncedSearchStateZero = debounce(
  async (
    params: SearchParams,
    setSearchState: React.Dispatch<React.SetStateAction<SearchState>>,
    searchApi: (query: Partial<SearchQuery>) => Promise<OperationResult<LibrarySearchResult>>,
  ) => {
    const { order, sortBy, name, lastTrackTime } = params;
    try {
      const { content: cmas } = await getFactorCMAs();
      const allOptions = flatMap(cmas, (grouping) => grouping.options);
      const fundIds = allOptions.filter((fund) => StateZeroTickerSet.has(fund.symbol)).map((fund) => fund.id);
      const { content } = await searchApi({
        fundIds,
        order,
        sortBy,
        name,
        entityFilters: ['FUND'],
        investmentSources: ['VENN'],
        page: 1,
        pageSize: PAGE_SIZE,
      });

      const results = content.results.map((result: LibrarySearchEntity): SearchResultWithUIState => {
        return {
          ...result,
          isNew: !!lastTrackTime && result.created > lastTrackTime,
        };
      });

      setSearchState({
        loading: false,
        results,
        totalCount: content.total,
      });
    } catch (e) {
      setSearchState((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  },
  300,
);

export const searchStateZero = async (
  params: SearchParams,
  setSearchState: React.Dispatch<React.SetStateAction<SearchState>>,
  searchApi: (query: Partial<SearchQuery>) => Promise<OperationResult<LibrarySearchResult>>,
) => {
  setSearchState((prev) => ({
    ...prev,
    loading: true,
  }));
  await debouncedSearchStateZero(params, setSearchState, searchApi);
};

export const PAGE_SIZE = 50;
export const PRIVATES_PAGE_SIZE = 20;
export const getItemType = (item: SearchResultWithUIState) => {
  if (item.portfolioId) {
    return ItemType.Portfolio;
  }
  switch (item.assetType) {
    case 'BENCHMARK':
      return ItemType.Benchmark;
    default:
      return ItemType.Investment;
  }
};

export const isDeletable = (item: SearchResultWithUIState) =>
  /*
   * All non-master/non-venn-demo portfolios are deletable, even integrated ones just because we don't have a
   * good way to set the `live` field for integrated portfolios unlike integrated investments.
   * We want integrated portfolios to be deletable since that's the only way clients can clean them up.
   * They'll simply get recreated the next sync if the portfolio is still "live".
   */
  (!!item.portfolioId && !item.master && item.portfolioId !== VENN_DEMO_PORTFOLIO_ID) ||
  item.userUploaded ||
  (FundUtils.isUserIntegration(item.investmentSource) && !item.live);

export const toTrackingFormat = (metricFilters?: MetricFilter[]) =>
  metricFilters?.flatMap(({ metric, timePeriod, minimum, maximum }) =>
    compact([
      isNil(minimum) ? undefined : `${timePeriod}-min-${metric}`,
      isNil(maximum) ? undefined : `${timePeriod}-max-${metric}`,
    ]),
  );

export const SHARPE_KEY = 'metricsOnTimePeriod.FULL.SHARPE_RATIO';
export const ANN_RET_KEY = 'metricsOnTimePeriod.FULL.ANNUALIZED_RETURN';
export const ANN_VOL_KEY = 'metricsOnTimePeriod.FULL.ANNUALIZED_VOLATILITY';

export const getSharpe = (item: SearchResultWithUIState) => get(item, SHARPE_KEY);
export const getAnnRet = (item: SearchResultWithUIState) => get(item, ANN_RET_KEY);
export const getAnnVol = (item: SearchResultWithUIState) => get(item, ANN_VOL_KEY);
export const get10YearRet = (item: SearchResultWithUIState) =>
  get(item, 'metricsOnTimePeriod.YEAR_10.CUMULATIVE_RETURN');
export const getCumRet = (item: SearchResultWithUIState) => get(item, 'metricsOnTimePeriod.FULL.CUMULATIVE_RETURN');

/**
 * Custom comparator for ag-grid columns with missing values
 *
 * The interface is a bit weird, because that's what ag-grid expects.
 * We always want nil/wildcards to go last.
 * ag-grid uses isDescending parameter on its own as sortDir, so that's why in most cases it's not taken into account
 */
export const agGridCustomColumnsComparator = (
  valueA: string | number,
  valueB: string | number,
  nodeA?: RowNode,
  nodeB?: RowNode,
  isDescending?: boolean,
) => {
  if (valueA === valueB) return 0;
  if (isNil(valueA) || valueA === '—') {
    return isDescending ? -1 : 1;
  }
  if (isNil(valueB) || valueB === '—') {
    return isDescending ? 1 : -1;
  }

  if (typeof valueA === 'string' && typeof valueB === 'string') {
    return valueA.localeCompare(valueB);
  }
  return (valueA as number) - (valueB as number);
};
