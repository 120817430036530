import React, { useRef, useState } from 'react';
import type { ScenarioAnalysisIndex, ShockUnitsEnum } from 'venn-api';
import type { DropMenuItem } from 'venn-ui-kit';
import { DropMenu, ExternalActivityListener, GetColor, Icon, TooltipPosition } from 'venn-ui-kit';
import styled, { css } from 'styled-components';
import { SpecialCssClasses } from 'venn-utils';
import { isNil } from 'lodash';
import { ShockInput } from 'venn-components';

interface NewScenarioProps {
  hasScenario: boolean;
  onAdd: (fundId: string, shock: number, rawValue: number) => void;
  indices: ScenarioAnalysisIndex[];
}

const emptyScenarioIndice: Partial<ScenarioAnalysisIndex> = {
  fundId: 'none',
  fundName: 'Select an index',
  id: 'none',
  units: 'PERCENTAGE' as ShockUnitsEnum,
};

const NewScenario = ({ onAdd, indices, hasScenario }: NewScenarioProps) => {
  const [selectedIndice, setSelectedIndice] = useState<Partial<ScenarioAnalysisIndex>>(emptyScenarioIndice);
  const [openDropdown, setOpenDropdown] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownOptions = indices
    .filter((scenarioIndex) => scenarioIndex.type === 'MARKET')
    .map((item) => ({
      label: item.fundName,
      value: item.fundId,
    }));

  const onChange = async (item: DropMenuItem) => {
    const indice = indices.find((list) => list.fundId === item.value);
    if (!indice) {
      setSelectedIndice(emptyScenarioIndice);
      return;
    }
    await setSelectedIndice(indice);
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  };

  const onReset = () => {
    setSelectedIndice(emptyScenarioIndice);
  };

  const onAddNew = async (value: number, rawValue: number) => {
    if (!isNil(selectedIndice.fundId)) await onAdd(selectedIndice.fundId, value, rawValue);
    onReset();
  };

  if (!selectedIndice) {
    return null;
  }
  const empty = selectedIndice.fundId === 'none' && !openDropdown;
  return (
    <NewScenarioTr className={`${SpecialCssClasses.NotDownloadable} ${hasScenario && 'has-scenario'}`}>
      <td>
        {selectedIndice.fundId === 'none' ? (
          <PlusIcon type="plus-circle" prefix="far" />
        ) : (
          <StyledTrashIcon type="trash" onClick={onReset} />
        )}
      </td>
      <td>
        <ExternalActivityListener onExternalActivity={() => openDropdown && setOpenDropdown(false)}>
          <DropdownWrapper onClick={() => !openDropdown && setOpenDropdown(true)}>
            {empty && <AddText className="hover-hide">Add market index...</AddText>}
            <DropMenu
              searchable
              items={dropdownOptions}
              onChange={onChange}
              selected={selectedIndice.fundId}
              placeholder="Select an index"
              className={`dropdown ${empty && 'hover-show'}`}
            />
          </DropdownWrapper>
        </ExternalActivityListener>
      </td>
      <td className={empty ? 'hover-show' : undefined}>
        <InputWrapper>
          <ShockInput
            shock={undefined}
            units={selectedIndice.units ?? 'PERCENTAGE'}
            mean={selectedIndice.mean ?? NaN}
            fundName={selectedIndice.fundName}
            onChange={onAddNew}
            addNewShock
            setInputRef={inputRef}
            shockRange={selectedIndice.shockRange}
            tooltipPosition={TooltipPosition.Bottom}
          />
        </InputWrapper>
      </td>
    </NewScenarioTr>
  );
};

export default React.memo(NewScenario);

export const TrashIcon = styled(Icon)`
  color: ${GetColor.Grey};
  padding-left: 4px;
  padding-right: 4px;
`;

export const NoHoverTr = styled.tr`
  &&&:hover {
    background-color: transparent;
  }
`;

const alignTop = css`
  .has-scenario & {
    position: relative;
    top: -20px;
  }
`;

const DropdownWrapper = styled.div`
  ${alignTop}
  max-width: 600px;
  .dropdown {
    min-width: auto;
  }
`;

const InputWrapper = styled.div`
  text-align: right;
  ${alignTop}
`;

const AddText = styled.div`
  color: ${GetColor.HintGrey};
  height: 34px;
  line-height: 34px;
  position: absolute;
  top: 0;
`;

const PlusIcon = styled(Icon)`
  ${alignTop}
  color: ${GetColor.Primary.Dark};
  font-size: 20px;
`;

const StyledTrashIcon = styled(TrashIcon)`
  ${alignTop}
`;

const NewScenarioTr = styled(NoHoverTr)`
  .hover-show {
    opacity: 0;
    display: block;
    transition: all 0.2s linear;
  }
  .hover-hide {
    opacity: 1;
    display: block;
    transition: all 0.2s linear;
  }
  &:hover .hover-show {
    opacity: 1;
  }
  &:hover .hover-hide {
    opacity: 0;
  }
`;
