import React, { useState } from 'react';
import { kebabCase } from 'lodash';
import Icon from '../icon/Icon';
import styled from 'styled-components';

interface CollapsibleProps {
  label: string;
}

export const Collapsible: React.ComponentType<CollapsibleProps> = ({ children, label }) => {
  const [open, setOpen] = useState(false);
  const idName = kebabCase(label);

  return (
    <>
      <button
        type="button"
        aria-expanded={open}
        aria-controls={`${idName}-content`}
        id={`${idName}-trigger`}
        onClick={() => setOpen((current) => !current)}
      >
        <ButtonContent>
          <Icon type={open ? 'caret-down' : 'caret-up'} />
          {label}
        </ButtonContent>
      </button>
      <Content
        id={`${idName}-content`}
        role="region"
        aria-labelledby={`${idName}-trigger`}
        aria-hidden={!open}
        open={open}
      >
        {children}
      </Content>
    </>
  );
};

const Content = styled.div<{ open: boolean }>`
  transition: all 0.3s ease-in-out;
  max-height: ${({ open }) => (open ? '100vh' : 0)};
  overflow: hidden;
`;

const ButtonContent = styled.span`
  font-size: 12px;
  display: inline-flex;
  gap: 5px;
`;
