import { createContext } from 'react';
import type {
  FactorExposure,
  FactorForecast,
  OptimizedPortfolio,
  Portfolio,
  PortfolioPolicy,
  PortfolioSummary,
} from 'venn-api';
import { noop } from 'lodash';
import type { AllocConstraint, AnalysisSubject, FactorExposureConstraint, ObjectiveType } from 'venn-utils';

export interface PortfolioConstraintsState {
  policy: PortfolioPolicy | undefined;
  loadingPolicy: boolean;
  policyError: boolean;
  currentPolicyWithConstraints: PortfolioPolicy | undefined;

  factorBreakdownData: FactorExposure[]; // Current factor exposures for portfolio

  policyAllocationConstraints: AllocConstraint[]; // Current portfolio policy allocation constraints
  allocationConstraints: AllocConstraint[]; // Current allocation constraints
  onUpdateAllocationConstraints: (constraints: AllocConstraint[]) => void; // Update current constraints (without saving to policy)
  onApplyAllocationConstraintsToPolicy: (constraints: AllocConstraint[]) => void; // Replace policy with the given allocation constraints
  onResetToPolicyAllocationConstraints: () => void; // Replace allocation constraints with those saved in portfolio policy

  policyFactorConstraints: FactorExposureConstraint[]; // Current portfolio policy factor exposure constraints
  factorConstraints: FactorExposureConstraint[]; // Current factor exposure constraints
  onUpdateFactorConstraints: (constrains: FactorExposureConstraint[]) => void; // Update current constraints (without saving to policy)
  onApplyFactorConstraintToPolicy: (constraint: FactorExposureConstraint) => void; // Add constraint to saved policy
  onOverrideFactorPolicy: (constraints: FactorExposureConstraint[]) => void; // Override policy factor constraints with the given list
  onResetToPolicyFactorConstraints: () => void; // Replace factor exposure constraints with those saved in portfolio policy
}

export interface PortfolioLabBenchmarkState {
  /** Actual benchmark if it's a portfolio; a portfolio with benchmark as an only child if benchmark is an investment. */
  benchmarkPseudoPortfolio: Portfolio | undefined;
  benchmarkSubject: AnalysisSubject | undefined;
  onChangeBenchmark: (benchmark: AnalysisSubject | undefined) => void;
}

type SolutionCategory = 'Current' | 'Optimized' | 'Alternate' | 'Benchmark';
export interface Solution {
  category: SolutionCategory;
  name?: string;
  alternateSolutionIdx?: number;
}

export interface PortfolioLabContextValue extends PortfolioConstraintsState, PortfolioLabBenchmarkState {
  portfolio: Portfolio | undefined;
  portfolioSummary: PortfolioSummary | undefined;
  portfolioLoading: boolean;
  onSelectPortfolioId: (id: number) => void;

  objective?: ObjectiveType;
  objectiveConstraintValue?: number;
  onChangeObjective: (objective: ObjectiveType, value: number) => void;

  onEditObjectiveConstraintValue: () => void; // Focus objective constraint value input from anywhere
  onShowAllocationSection: () => void; // Open side bar & expand Allocation Constraints section
  onShowFactorSection: () => void; // Open side bar & expand Factor Exposure Constraints section

  factorForecasts: FactorForecast[];
  onForecastUpdated: () => void; // Triggers a re-fetch of optimization and factor exposure values

  selectedSolution?: Solution; // Currently focused solution from: Current, Optimized, Alternate with specific idx, or Benchmark
  solutionPortfolio?: Partial<OptimizedPortfolio>; // Portfolio & summary of the selected solution
  onSelectSolution: (solution: Solution) => void; // Focus a specific solution
}

export const portfolioLabContextDefaultValue: PortfolioLabContextValue = {
  policy: undefined,
  portfolio: undefined,
  portfolioSummary: undefined,
  portfolioLoading: false,
  onSelectPortfolioId: noop,

  benchmarkPseudoPortfolio: undefined,
  benchmarkSubject: undefined,
  onChangeBenchmark: noop,

  objective: undefined,
  objectiveConstraintValue: undefined,
  onChangeObjective: noop,

  onEditObjectiveConstraintValue: noop,
  onShowAllocationSection: noop,
  onShowFactorSection: noop,

  factorForecasts: [],
  onForecastUpdated: noop,

  loadingPolicy: false,
  policyError: false,
  currentPolicyWithConstraints: undefined,
  factorBreakdownData: [],
  policyAllocationConstraints: [],
  allocationConstraints: [],
  onUpdateAllocationConstraints: noop,
  onApplyAllocationConstraintsToPolicy: noop,
  onResetToPolicyAllocationConstraints: noop,
  policyFactorConstraints: [],
  factorConstraints: [],
  onUpdateFactorConstraints: noop,
  onApplyFactorConstraintToPolicy: noop,
  onOverrideFactorPolicy: noop,
  onResetToPolicyFactorConstraints: noop,

  selectedSolution: undefined,
  solutionPortfolio: undefined,
  onSelectSolution: noop,
};

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
const PortfolioLabContext = createContext<PortfolioLabContextValue>(portfolioLabContextDefaultValue);
export default PortfolioLabContext;
