import React, { useContext, useEffect, useState } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { getQueryParams, getTemplateById, RECENT_ANALYSIS_STORAGE_KEY, Routes, userActivity } from 'venn-utils';
import { AnalysisContext, LaunchAnalysisTemplate } from 'venn-components';
import HomePageContext from './components/contexts/HomePageContext';
import HomePage from './components/onboarding/HomePage';
import type { GeneralAnalysisTemplate } from 'venn-api';
import { Notifications, NotificationType } from 'venn-ui-kit';

const HomePageContainer = ({ history, location }: RouteComponentProps) => {
  const { templates } = useContext(AnalysisContext);
  const [homePageLoading, setHomePageLoading] = useState(false);
  const [launchAnalysisTemplateVisible, setLaunchAnalysisTemplateVisible] = useState<GeneralAnalysisTemplate | null>();

  useEffect(() => {
    if (location.hash === '#recent-factor-activity') {
      // Redirect to factor lens
      history.replace(Routes.FACTOR_LENS_HOMEPAGE);
      return;
    }
    const { launch, invalidSubject, invalidWorkspace } = getQueryParams(location.search);

    // Test if the user has been redirected to due invalid analysis subject
    const errorMessage = invalidSubject
      ? 'Your selection could not be opened'
      : invalidWorkspace
        ? 'Failed to open link because workspace is invalid.'
        : undefined;

    if (errorMessage) {
      Notifications.notify(errorMessage, NotificationType.ERROR);
      history.replace(Routes.HOME_PATH);
      if (invalidSubject) {
        // Prevent getting stuck locked out of Analysis due to storage of a broken subject key
        userActivity.removeLocalStorageItemPerUser(RECENT_ANALYSIS_STORAGE_KEY);
      }
      return;
    }

    if (launch) {
      history.replace(Routes.HOME_PATH);
      setLaunchAnalysisTemplateVisible(getTemplateById(templates, String(launch)));
    }
  }, [location, history, templates]);

  return (
    <HomePageContext.Provider
      value={{
        loading: homePageLoading,
        setLoading: setHomePageLoading,
      }}
    >
      <HomePage />
      {launchAnalysisTemplateVisible && (
        <LaunchAnalysisTemplate
          templateId={launchAnalysisTemplateVisible.id}
          templateName={launchAnalysisTemplateVisible.name}
          onCancel={() => setLaunchAnalysisTemplateVisible(null)}
        />
      )}
    </HomePageContext.Provider>
  );
};

export default withRouter(HomePageContainer);
