import type { CallbackInterface, GetRecoilValue } from 'recoil';
import { atom, DefaultValue, selector, selectorFamily } from 'recoil';
import { getForceZeroResidualForecastForWorkspace, setForceZeroResidualForecastForWorkspace } from 'venn-api';
import { assertExhaustive } from 'venn-utils';
import type { EmptyRightPanelView } from './commonTypes';
import { ForecastTab } from './commonTypes';
import type {
  FactorForecastRightPanelViewWithForecast,
  FactorForecastRightPanelViewWithId,
  FactorForecastRightPanelViewWithoutForecastId,
  FactorForecastView,
  FactorForecastViewInner,
} from './factorForecastAtoms';
import { activeForecastIdAtom, remoteFactorForecast } from './factorForecastAtoms';
import type { InvestmentForecastView } from './investmentForecastAtoms';

/**
 * Defining a view with object ids instead of concrete objects
 * for just-in-time loading
 */
type ForecastPanelViewInner = FactorForecastViewInner | InvestmentForecastView;

/**
 * We're using a discriminated union to represent ForecastPanelView
 * to ensure type-safety, and make sure it isn't possible to have combinations
 * of tab/detail (e.g. factor forecast tab with investment override detail)
 * that results in undefined behavior.
 */
export type ForecastPanelView = FactorForecastView | InvestmentForecastView;

export const defaultForecastPanelInnerViewForTab = selectorFamily<ForecastPanelViewInner, ForecastTab>({
  key: 'defaultForecastPanelInnerViewForTab',
  get:
    (tab) =>
    ({ get }) => {
      const view = get(defaultForecastPanelViewForTab(tab));
      return toForecastPanelViewInner(view);
    },
});
export const defaultForecastPanelViewForTab = selectorFamily<ForecastPanelView, ForecastTab>({
  key: 'defaultForecastPanelViewForTab',
  get:
    (tab) =>
    ({ get }) => {
      if (tab === ForecastTab.InvestmentForecast) {
        return {
          tab: ForecastTab.InvestmentForecast,
          detail: { type: 'Empty' },
        };
      }
      if (tab === ForecastTab.FactorForecast) {
        const activeForecastId = get(activeForecastIdAtom);
        const activeForecast = get(remoteFactorForecast(activeForecastId));
        return {
          tab: ForecastTab.FactorForecast,
          detail: activeForecast
            ? {
                type: 'SpecificForecast',
                forecast: activeForecast,
              }
            : { type: 'Empty' },
        };
      }
      return assertExhaustive(tab, 'unexpected forecast tab');
    },
});

const isForecastViewWithId = (
  view: FactorForecastRightPanelViewWithId | FactorForecastRightPanelViewWithoutForecastId | EmptyRightPanelView,
): view is FactorForecastRightPanelViewWithId =>
  ['SpecificForecast', 'CMAsEditor', 'HistoricalPeriodEditor'].includes(view.type);

const isForecastViewWithForecast = (
  view: FactorForecastRightPanelViewWithForecast | FactorForecastRightPanelViewWithoutForecastId | EmptyRightPanelView,
): view is FactorForecastRightPanelViewWithForecast =>
  ['SpecificForecast', 'CMAsEditor', 'HistoricalPeriodEditor'].includes(view.type);

export const forecastPanelViewSelector = selector<ForecastPanelView>({
  key: 'forecastPanelViewSelector',
  get: ({ get }) => {
    const innerView = get(forecastPanelInnerViewAtom);
    return toForecastPanelView(innerView, get);
  },
  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      set(forecastPanelInnerViewAtom, newValue);
      return;
    }
    const innerView = toForecastPanelViewInner(newValue);
    set(forecastPanelInnerViewAtom, innerView);
  },
});

export const forecastPanelInnerViewAtom = atom<ForecastPanelViewInner>({
  key: 'forecastPanelInnerViewAtom',
  default: defaultForecastPanelInnerViewForTab(ForecastTab.FactorForecast),
});

const forceZeroResidualForecastInitializer = selector({
  key: 'forceZeroResidualForecastInitializer',
  get: async () => (await getForceZeroResidualForecastForWorkspace()).content,
});

export const forceZeroResidualForecastAtom = atom<boolean>({
  key: 'forceZeroResidualForecastAtom',
  default: forceZeroResidualForecastInitializer,
});

export const forceZeroResidualForecastChangedAtom = atom<number>({
  key: 'forceZeroResidualForecastChangedAtom',
  default: 0,
});

export const forceZeroResidualForecastSelector = selector<boolean>({
  key: 'forceZeroResidualForecastSelector',
  get: ({ get }) => get(forceZeroResidualForecastAtom),
  set: ({ get, set }, newValue) => {
    set(forceZeroResidualForecastAtom, newValue);
    set(forceZeroResidualForecastChangedAtom, get(forceZeroResidualForecastChangedAtom) + 1);
  },
});

export const setForceZeroResidualForecastRecoilCallback =
  ({ set }: CallbackInterface) =>
  async (newValue: boolean) => {
    set(forceZeroResidualForecastSelector, (await setForceZeroResidualForecastForWorkspace(newValue)).content);
  };

const toForecastPanelView = (innerView: ForecastPanelViewInner, get: GetRecoilValue): ForecastPanelView => {
  switch (innerView.tab) {
    case ForecastTab.InvestmentForecast:
      return innerView;
    case ForecastTab.FactorForecast:
      const detail = innerView.detail;
      if (isForecastViewWithId(detail)) {
        const forecast = get(remoteFactorForecast(detail.forecastId));
        return {
          tab: ForecastTab.FactorForecast,
          detail: forecast
            ? {
                type: detail.type,
                forecast,
              }
            : {
                type: 'Empty',
              },
        };
      }
      return {
        tab: ForecastTab.FactorForecast,
        detail,
      };
    default:
      return assertExhaustive(innerView, 'unexpected view');
  }
};

const toForecastPanelViewInner = (view: ForecastPanelView): ForecastPanelViewInner => {
  switch (view.tab) {
    case ForecastTab.InvestmentForecast:
      return view;
    case ForecastTab.FactorForecast:
      const detail = view.detail;
      if (isForecastViewWithForecast(detail)) {
        return {
          tab: ForecastTab.FactorForecast,
          detail: {
            type: detail.type,
            forecastId: detail.forecast.forecastId,
          },
        };
      }
      return {
        tab: ForecastTab.FactorForecast,
        detail,
      };
    default:
      return assertExhaustive(view, 'unexpected forecast view');
  }
};
