import { useContext, useCallback, useEffect, useRef } from 'react';
import {
  UserContext,
  PortfoliosContext,
  FactorLensesContext,
  AnalysisContext,
  useUrlParamState,
} from 'venn-components';
import { useModal, Routes, getWorkspaceIdFromLocation, UrlParam } from 'venn-utils';
import { switchWorkspaces } from 'venn-api';
import { useHistory } from 'react-router-dom';

/**
 * Hooks to handle switch workspace
 * Flow 1: if the user lands the app in different workspace, and
 * if the user has the access to new workspace, isSwitchModalOpen will be true.
 * Flow 2: user switch workspace in app, then we won't open the modal but switch directly
 */
const useWorkspaceSwitch = () => {
  const [isSwitchModalOpen, openSwitchModal, closeSwitchModal] = useModal();
  const userContext = useContext(UserContext);
  const { profileSettings, refresh: refreshUser } = userContext;
  const { refresh: refreshPortfolios } = useContext(PortfoliosContext);
  const { refresh: refreshFactorLenses, loading: factorLoading } = useContext(FactorLensesContext);
  const { refresh: refreshAnalysis } = useContext(AnalysisContext);
  const isLoading = useRef(false);
  const history = useHistory();
  const workspaceId = getWorkspaceIdFromLocation(history.location) ?? profileSettings?.organization?.workspaceId;
  const [urlWorkspaceId, setUrlWorkspaceId] = useUrlParamState(UrlParam.WORKSPACE_ID, workspaceId);
  const refresh = useCallback(
    () => Promise.all([refreshUser(), refreshPortfolios(), refreshFactorLenses(), refreshAnalysis()]),
    [refreshUser, refreshPortfolios, refreshFactorLenses, refreshAnalysis],
  );

  const redirectCallback = useCallback(
    (hideInvalidMessage?: boolean) => {
      if (hideInvalidMessage) {
        history.push(`${Routes.HOME_PATH}?workspaceId=${profileSettings?.organization?.workspaceId}`);
        return;
      }
      history.push(
        `${Routes.HOME_PATH}?invalidWorkspace=true&workspaceId=${profileSettings?.organization?.workspaceId}`,
      );
    },
    [history, profileSettings?.organization?.workspaceId],
  );

  const onSwitchWorkspace = useCallback(
    async (targetWorkspaceId?: number) => {
      try {
        await switchWorkspaces({
          userId: profileSettings?.user.id,
          organizationId: targetWorkspaceId ?? Number(urlWorkspaceId),
        });
        if (targetWorkspaceId) {
          return;
        }
        window.location.reload();
      } catch (e) {
        redirectCallback();
      }
    },
    [redirectCallback, profileSettings?.user.id, urlWorkspaceId],
  );

  useEffect(() => {
    if (isLoading.current) {
      return;
    }
    if (urlWorkspaceId && Number(urlWorkspaceId) !== profileSettings?.organization?.workspaceId) {
      openSwitchModal();
    } else if (isSwitchModalOpen) {
      closeSwitchModal();
    }
  }, [
    redirectCallback,
    openSwitchModal,
    closeSwitchModal,
    isSwitchModalOpen,
    urlWorkspaceId,
    profileSettings?.organization?.workspaceId,
  ]);

  useEffect(() => {
    const unlisten = history.listen(async (location) => {
      if (isLoading.current) {
        return;
      }
      if (location.hash === '#switch-workspace') {
        if (location.state?.orgId) {
          // Skip the modal and update workspace directly
          isLoading.current = true;
          history.replace(Routes.HOME_PATH);
          await onSwitchWorkspace(location.state?.orgId);
          setUrlWorkspaceId(location.state?.orgId);

          // Sometimes you need to fight bad code with more bad code
          // TODO Fix
          history.push({ search: new URLSearchParams([['workspaceId', location.state?.orgId]]).toString() });
          await refresh();
          window.location.reload();
          isLoading.current = false;
        }
      }
    });
    return unlisten;
  }, [onSwitchWorkspace, setUrlWorkspaceId, history, refresh]);

  const onCancelSwitchWorkspace = useCallback(() => {
    closeSwitchModal();
    redirectCallback(true);
  }, [redirectCallback, closeSwitchModal]);

  return {
    isSwitchModalOpen,
    onCancelSwitchWorkspace,
    onSwitchWorkspace,
    /** analysis page or comparison page will show different text */
    isView: history.location.pathname.includes('/analysis/'),
    factorLoading,
  };
};

export default useWorkspaceSwitch;
