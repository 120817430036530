import React from 'react';
import styled from 'styled-components';
import { EllipsisTooltipSpan, GetColor, Headline2, Headline3, Loading } from 'venn-ui-kit';
import { useHistory } from 'react-router-dom';
import { logExceptionIntoSentry, navigateToManageDataPage, withSuspense } from 'venn-utils';
import type { BasicTableColumn, BasicTableProps, StyledTableType } from 'venn-components';
import { BasicTable, formatDate } from 'venn-components';
import { useQuery } from '@tanstack/react-query';
import type { PrivatePortfolioInfo } from 'venn-api';
import { getPrivatePortfoliosWithFund } from 'venn-api';

interface InvestmentPortfoliosProps {
  fundId: string;
  name: string;
}
const FIVE_MINUTES = 1000 * 60 * 5;
const PORTFOLIOS_CONTAINING_INVESTMENT_KEY = 'portfolios_containing_investment';

export const PrivateInvestmentPortfolios = withSuspense(
  <Loading title="Portfolios are loading... " />,
  ({ fundId, name }: InvestmentPortfoliosProps) => {
    const history = useHistory();
    const { data: portfolios = [] } = useQuery<PrivatePortfolioInfo[]>(
      [PORTFOLIOS_CONTAINING_INVESTMENT_KEY, fundId],
      async () => {
        const response = await getPrivatePortfoliosWithFund(fundId);
        return response.content;
      },
      {
        onError: (error) => {
          return logExceptionIntoSentry(error as Error);
        },
        suspense: true,
        staleTime: FIVE_MINUTES,
      },
    );

    if (!portfolios.length) {
      return (
        <EmptyStateContainer>
          <Headline2>This fund is not part of any portfolio yet.</Headline2>
        </EmptyStateContainer>
      );
    }

    return (
      <Container>
        <Headline3>
          Portfolios containing <FundNameWrapper maxWidth={500}>{name}</FundNameWrapper>
        </Headline3>
        <StyledTable
          data={portfolios}
          columns={columns}
          selectable
          onRowClick={(e, portfolio: PrivatePortfolioInfo) =>
            navigateToManageDataPage(history, { privatePortfolioId: portfolio.id }, 'Manage Investment', false)
          }
        />
      </Container>
    );
  },
);

const columns = [
  {
    label: 'Name',
    cellRenderer: (portfolio: PrivatePortfolioInfo) => (
      <PortfolioNameWrapper content={portfolio.name}>{portfolio.name}</PortfolioNameWrapper>
    ),
  },
  {
    label: 'Created By',
    cellRenderer: (portfolio: PrivatePortfolioInfo) => <span>{portfolio.createdBy.displayName}</span>,
  },
  {
    label: 'Date Updated',
    cellRenderer: (portfolio: PrivatePortfolioInfo) => <span>{formatDate(portfolio.updated)}</span>,
  },
  {
    label: 'Updated By',
    cellRenderer: (portfolio: PrivatePortfolioInfo) => <span>{portfolio.updatedBy.displayName}</span>,
  },
];

const Container = styled.div`
  border-radius: 4px;
  border: 1px solid ${GetColor.Grey};
  padding: 40px 60px;
`;

const EmptyStateContainer = styled(Container)`
  text-align: center;
  h2 {
    text-align: center;
  }
`;

const PortfolioNameWrapper = styled(EllipsisTooltipSpan)`
  .qa-ellipsis-text {
    color: ${GetColor.Primary.Dark};
  }

  max-width: 500px;
  @media (max-width: 1279px) {
    max-width: 400px;
  }
`;

const StyledTable: StyledTableType<unknown> = styled(
  <T extends BasicTableColumn<K>, K>(props: BasicTableProps<T, K>) => <BasicTable<T, K> {...props} />,
)`
  width: 100%;
  tr:hover {
    cursor: pointer;
    .fa-calculator {
      visibility: visible;
    }
  }
`;

const FundNameWrapper = styled(EllipsisTooltipSpan)`
  vertical-align: text-top;
`;
