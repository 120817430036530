import type { PrivatesAnalysisResponse } from 'venn-api';
import { type BlockId, blockPrivateAssetIsCumulative, MetricError, useMetricsErrors } from 'venn-state';
import { useRecoilValue } from 'recoil';
import { useEffect } from 'react';
import { compact, isEmpty } from 'lodash';

export const HISTORICAL_METRICS = ['contributions', 'distributions', 'nav', 'net'];

const useCashFlowBlockMetricErrors = (multiSubject: boolean, data: PrivatesAnalysisResponse, blockId: BlockId) => {
  const cumulative = useRecoilValue(blockPrivateAssetIsCumulative(blockId));

  // const cfSingleData = data?.cashFlows?.[0];

  const { clearMetricErrors, setMetricErrors } = useMetricsErrors(blockId);
  useEffect(() => {
    // detect metrics errors for this block
    const errorCheckingData = compact(multiSubject ? data?.cashFlows ?? [] : [data?.cashFlows?.[0]]);
    const erroneousMetrics = compact(
      errorCheckingData.flatMap((cfSingleData) =>
        compact([
          ...HISTORICAL_METRICS.map((met) => {
            const dataObj = cumulative ? cfSingleData.actualCumulative : cfSingleData.actual;
            return isEmpty(dataObj?.[met])
              ? { metricKey: met, error: MetricError.HISTORICAL_CASHFLOWS_UNAVAILABLE }
              : null;
          }),
          ...(cfSingleData.errors?.PROJECTION_UNAVAILABLE
            ? HISTORICAL_METRICS.map((met) => {
                return { metricKey: `${met}Projected`, error: MetricError.PROJECTION_UNAVAILABLE };
              })
            : []),
        ]),
      ),
    );
    setMetricErrors(erroneousMetrics);

    return () => {
      clearMetricErrors();
    };
  }, [multiSubject, data, cumulative, setMetricErrors, clearMetricErrors]);
};

export const useCashFlowChartMetricErrors = (data: PrivatesAnalysisResponse, blockId: BlockId) => {
  useCashFlowBlockMetricErrors(false, data, blockId);
};
export const useCashFlowGridMetricErrors = (data: PrivatesAnalysisResponse, blockId: BlockId) => {
  useCashFlowBlockMetricErrors(true, data, blockId);
};
