import type { ScheduledLog } from 'venn-api';

export const mockScheduledLogs: ScheduledLog[] = [
  {
    scheduledExportId: 'd6c1d57f-21dc-422a-9efa-e645ebcab326',
    documentId: '5e18a711-475b-44ad-b6c5-bf9001f37005',
    exportsStatus: 'EMAIL_SENT',
    emails: ['yuanyuan@twosigma.com'],
    scheduledFrequency: 'MONTHLY',
    exportFileName: 'Test compare 4 benchmark -2021-02-19T18:18:01.511068.xlsx',
    exportType: 'XLSX',
    benchmarkName: 'Manager 1',
    startDate: 1359676799999,
    endDate: 1496275199999,
    created: 1613758682076,
    updated: 1613758682076,
  },
  {
    scheduledExportId: 'd6c1d57f-21dc-422a-9efa-e645ebcab326',
    documentId: '267e3fa8-d39d-4339-8081-0c8b29d6018c',
    exportsStatus: 'EMAIL_SENT',
    emails: ['yuanyuan@twosigma.com'],
    scheduledFrequency: 'MONTHLY',
    exportFileName: 'Test compare 4 benchmark -2021-02-19T18:18:27.057238.xlsx',
    exportType: 'XLSX',
    benchmarkName: 'Manager 1',
    startDate: 1359676799999,
    endDate: 1496275199999,
    created: 1613758707439,
    updated: 1613758707439,
  },
];
