import React, { PureComponent } from 'react';
import styled, { withTheme } from 'styled-components';
import { last, get, range } from 'lodash';
import type { Theme } from 'venn-ui-kit';
import { ColorUtils } from 'venn-ui-kit';
import type { HighchartsSeriesOptions, HighchartsDashStyle } from '../highchart/Highchart';
import Highchart from '../highchart/Highchart';
import type * as Highcharts from 'highcharts';

interface CumulativeReturnSnapshotChartProps {
  cumulativeReturn: [number, number][];
  // Last point will have a circle for hightlighting
  highlightLastPoint?: boolean;
  // First point will have a line for hightlighting
  highlightFirstPoint?: boolean;
  max?: number;
  min?: number;
  proxyStart?: number;
  proxyEnd?: number;
  extrapolateStart?: number;
  theme: Theme;
}

/** Max points to improve performance. */
const MAX_POINTS = 30;
class CumulativeReturnSnapshotChart extends PureComponent<CumulativeReturnSnapshotChartProps> {
  render() {
    const {
      cumulativeReturn,
      highlightLastPoint,
      highlightFirstPoint,
      min,
      max,
      proxyStart,
      proxyEnd,
      extrapolateStart,
      theme: { Colors, Schemes },
    } = this.props;
    if (!cumulativeReturn || cumulativeReturn.length === 0) {
      return null;
    }
    const { unProxyReturn, proxyReturn } = parseProxyData(cumulativeReturn, proxyStart, proxyEnd);

    const lastProxyReturn = last(proxyReturn);
    const lastProxyTimestamp = !lastProxyReturn ? 0 : lastProxyReturn[0];

    const firstReturn = unProxyReturn[0];
    const firstReturnValue = get(firstReturn, 1);
    const firstTimestamp = get(firstReturn, 0);
    const lastReturn = last(unProxyReturn);
    const lastTimestamp = get(lastReturn, 0);

    const value = get(lastReturn, 1);
    const color = (value ?? 0) >= 0 ? Colors.DivergingColor.B4 : Colors.DivergingColor.A4;
    const lastPoint = {
      x: lastTimestamp,
      y: value,
      marker: { enabled: true, radius: 4, fillColor: color },
    };

    const firstPoint = {
      x: firstTimestamp,
      y: firstReturnValue,
      marker: {
        enabled: true,
        symbol: 'shortLine',
        radius: 6,
      },
    };

    let extraXAxis = {};
    if (min !== undefined && max !== undefined) {
      extraXAxis = { max, min, startOnTick: false, endOnTick: false };
    }
    const data = highlightLastPoint
      ? ([...unProxyReturn.slice(0, -1), lastPoint] as [[number, number] | Highcharts.SeriesLineDataOptions])
      : ([firstPoint, ...unProxyReturn.slice(1)] as [[number, number] | Highcharts.SeriesLineDataOptions]);

    const series: HighchartsSeriesOptions[] = [
      {
        name: 'proxy return',
        type: 'line',
        color: Schemes.Proxy.darkProxyLine,
        states: { hover: { enabled: false } },
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
        data: proxyReturn as [[number, number] | Highcharts.SeriesLineDataOptions],
      },
      {
        name: 'cumulative return',
        type: 'line',
        lineWidth: 1,
        color: highlightFirstPoint ? color : Colors.MidGrey2,
        states: { hover: { enabled: false } },
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
        data,
      },
    ];

    const plotLines = highlightLastPoint
      ? [
          {
            color: Colors.PaleGrey,
            dashStyle: 'ShortDash' as HighchartsDashStyle,
            value: firstTimestamp,
            width: 1,
          },
        ]
      : [];

    const plotBands = [
      {
        color: ColorUtils.opacify(Schemes.Proxy.proxyLine, 0.3),
        from: proxyStart,
        to: proxyEnd && extrapolateStart ? Math.min(proxyEnd, extrapolateStart) : proxyEnd,
      },
      {
        color: ColorUtils.opacify(Schemes.Proxy.extrapolation, 0.35),
        from: extrapolateStart,
        to: Math.max(lastTimestamp ?? 0, lastProxyTimestamp ?? 0),
      },
    ].filter((band) => band.from && band.to);

    const config = {
      ...defaultConfig,
      xAxis: {
        ...defaultConfig.xAxis,
        plotLines,
        plotBands,
        ...extraXAxis,
      },
      series,
    };

    return (
      <CumulativeReturnWrapper>
        <Highchart options={config} />
      </CumulativeReturnWrapper>
    );
  }
}

export default withTheme(CumulativeReturnSnapshotChart);

export const parseProxyData = (cumulativeReturn: [number, number][], proxyStart?: number, proxyEnd?: number) => {
  if (!proxyStart || !proxyEnd) {
    return {
      unProxyReturn: resampleArray(cumulativeReturn, MAX_POINTS),
      proxyReturn: [],
    };
  }

  const unProxyReturn: [number, number][] = [];
  const proxyReturn: [number, number][] = [];
  cumulativeReturn.forEach((item, index) => {
    // Cumulative returns have an extra data point with value 0 prepended to it.
    // To decide where it goes, we use the date of the data point right after it.
    const date = index === 0 && cumulativeReturn.length > 1 ? cumulativeReturn[1][0] : item[0];

    if (date > proxyStart && date < proxyEnd) {
      proxyReturn.push(item);
    } else if (date === proxyStart || date === proxyEnd) {
      proxyReturn.push(item);

      // To connect the proxied and unproxied returns, we also add the proxy start and end data points
      // to the unproxied returns array. However, we should only do that if there are any unproxied
      // returns to connect to on those ends, i.e. index - 1 and index + 1 fall within the bounds of cumulativeReturn.
      // index - 1 === 0 does not count since that's just an extra 0 value prepended to the cumulative return.
      if (index - 1 > 0 && index + 1 < cumulativeReturn.length) {
        unProxyReturn.push(item);
      }
    } else {
      unProxyReturn.push(item);
    }
  });

  const relativeSize = unProxyReturn.length / (proxyReturn.length + unProxyReturn.length);

  return {
    unProxyReturn: resampleArray(unProxyReturn, MAX_POINTS * relativeSize),
    proxyReturn: resampleArray(proxyReturn, MAX_POINTS * (1 - relativeSize)),
  };
};

const resampleArray = (series: [number, number][], maxPoints: number): [number, number][] => {
  const numPoints = Math.floor(maxPoints);
  if (series.length <= numPoints) {
    return series;
  }
  if (series.length === 0 || numPoints === 0) {
    return [];
  }
  if (numPoints === 1) {
    return series.slice(0, 1);
  }
  const slope = (series.length - 1) / (numPoints - 1);
  return range(numPoints)
    .map((i) => Math.round(i * slope))
    .map((i) => series[i]);
};

const CumulativeReturnWrapper = styled.div`
  padding: 0;
`;

const defaultConfig = {
  chart: {
    margin: [3, 4, 1, 1],
    height: 25,
    spacing: [0, 0, 0, 0],
    backgroundColor: 'transparent',
  },
  title: undefined,
  scrollbar: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  rangeSelector: {
    enabled: false,
  },
  navigator: {
    enabled: false,
  },
  exporting: {
    enabled: true,
    fallbackToExportServer: true,
  },
  tooltip: {
    enabled: false,
  },
  yAxis: {
    visible: false,
    maxPadding: 0,
  },
  legend: {
    enabled: false,
  },
  xAxis: {
    // visible: false,
    tickLength: 0,
    lineWidth: 0,
    crosshair: {
      width: 0,
    },
  },
  series: [],
};
