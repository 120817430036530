import type { FC } from 'react';
import React, { useState } from 'react';
import styled from 'styled-components';
import type { DropMenuItem } from 'venn-ui-kit';
import { DropMenu, GetColor, Icon } from 'venn-ui-kit';
import { EditName, AccountModalWrapper, EditAvatar, DEFAULT_IMAGE_EXPORT_SCALE, SliderToggle } from 'venn-components';
import type { TypedUserSettings, UserProfileSettings, UserSettings } from 'venn-api';
import { HIDE_FORECASTS_IN_EXPORTS_KEY, IMAGE_EXPORT_SCALE_KEY } from 'venn-api';
import {
  StyledWrapper,
  StyledIcon,
  FlexRow,
  FlexWrapper,
  InfoWrapper,
  Name,
  Info,
  SmallName,
  BaselineAlignedFlexWrapper,
} from './components/shared';

interface UserProps {
  profileSettings: UserProfileSettings;
  userSettings: UserSettings;
  updateUserSettings: (settings: TypedUserSettings) => void;
  refresh: () => void;
}

const IMAGE_EXPORT_SCALE_OPTIONS: DropMenuItem<number>[] = [
  { label: 'Low-Res (@1x)', value: 1 },
  { label: 'Medium-Res [Default] (@2x)', value: 2 },
  { label: 'Hi-Res (@4x)', value: 4 },
  { label: 'Super Hi-Res (@8x)', value: 8 },
];

const User: FC<React.PropsWithChildren<UserProps>> = ({
  profileSettings,
  userSettings,
  updateUserSettings,
  refresh,
}) => {
  const [showEditProfileModal, setShowEditProfileModal] = useState<boolean>(false);
  const { firstName, lastName, user, displayName } = profileSettings;
  const imageExportScale = userSettings?.user?.[IMAGE_EXPORT_SCALE_KEY] ?? DEFAULT_IMAGE_EXPORT_SCALE;
  const { email, avatarId } = user;

  const [hasForecastsInExports, setHasForecastsInExports] = useState<boolean>(
    !userSettings?.user?.[HIDE_FORECASTS_IN_EXPORTS_KEY],
  );

  return (
    <>
      <StyledWrapper>
        <FlexWrapper>
          <EditAvatar edit refresh={refresh} avatarId={avatarId} />
          <InfoWrapper>
            <FlexWrapper>
              <LargerName className="qa-first-last-name">
                {firstName} {lastName}
              </LargerName>
              <StyledEditIcon type="edit" onClick={() => setShowEditProfileModal(true)} className="edit-name" />
              {showEditProfileModal && (
                <AccountModalWrapper
                  onClose={() => setShowEditProfileModal(false)}
                  title="Edit Your Profile"
                  icon="edit"
                >
                  <EditName
                    onClose={() => setShowEditProfileModal(false)}
                    firstName={firstName}
                    lastName={lastName}
                    displayName={displayName}
                    refresh={refresh}
                  />
                </AccountModalWrapper>
              )}
            </FlexWrapper>
            <BaselineAlignedFlexWrapper>
              <SmallName>Display Name:</SmallName>
              <SmallText className="qa-display-name">{displayName || '--'}</SmallText>
            </BaselineAlignedFlexWrapper>
            <EmailWrapper>
              <SmallName>Email:</SmallName>
              <Info className="qa-email-address">{email}</Info>
            </EmailWrapper>
          </InfoWrapper>
        </FlexWrapper>
      </StyledWrapper>
      <StyledWrapper>
        <FlexRow>
          <FlexWrapper>
            <StyledIcon type="images" />
            <Name>Image Export Resolution</Name>
          </FlexWrapper>
          <RightContent>
            <DropMenu
              className="qa-image-export-scale"
              items={IMAGE_EXPORT_SCALE_OPTIONS}
              selected={imageExportScale}
              onChange={(item) => updateUserSettings?.({ [IMAGE_EXPORT_SCALE_KEY]: item.value })}
            />
          </RightContent>
        </FlexRow>
        <Info>Sets the scale used when exporting images individually or through bulk export.</Info>
      </StyledWrapper>
      <StyledWrapper>
        <FlexRow>
          <FlexWrapper>
            <StyledIcon type="file-medical-alt" prefix="far" />
            <Name>Forecasts in Analysis Page</Name>
          </FlexWrapper>
          <RightContent>
            <SliderToggle
              toggled={hasForecastsInExports}
              onToggle={() => {
                setHasForecastsInExports(!hasForecastsInExports);
                updateUserSettings?.({ [HIDE_FORECASTS_IN_EXPORTS_KEY]: hasForecastsInExports });
              }}
            />
          </RightContent>
        </FlexRow>
        <Info>Set the visibility of forecast metrics on-screen, and in PDF exports, from the Analysis page</Info>
      </StyledWrapper>
    </>
  );
};

export default User;

const StyledEditIcon = styled(Icon)`
  font-size: 14px;
  color: ${GetColor.Grey};
  cursor: pointer;
  margin-top: -1px;
`;

const SmallText = styled.div`
  font-size: 12px;
`;

const LargerName = styled(Name)`
  margin-bottom: 5px;
`;

const RightContent = styled.div`
  display: flex;
  align-items: center;
`;

const EmailWrapper = styled(BaselineAlignedFlexWrapper)`
  margin-top: 10px;
`;
