import React, { PureComponent } from 'react';

const getGradientOffset = (index: number, palette: string[]) => (index / (palette.length - 1)) * 100;

interface Props {
  palette: string[];
  thickness: number;
  width: number;
  y?: number;
}

class Gradient extends PureComponent<Props> {
  render() {
    const { width, thickness, palette, y = 0 } = this.props;

    return (
      <g>
        <defs>
          <linearGradient data-testid="qa-legend-gradient" id="legendGradient">
            {palette.map((color, index) => (
              <stop offset={`${getGradientOffset(index, palette)}%`} stopColor={color} key={color} />
            ))}
          </linearGradient>
        </defs>
        <rect x={0} y={y + 2} width={width} height={thickness} fill="url(#legendGradient)" />
      </g>
    );
  }
}

export default Gradient;
