import styled from 'styled-components';
import React from 'react';
import type { AnalysisViewSearchResultWithUIState } from 'venn-utils';
import { GetColor, Icon } from 'venn-ui-kit';
import { capitalize } from 'lodash';
import { useHasFF } from 'venn-utils';

interface SavedViewDetailsProps {
  item: AnalysisViewSearchResultWithUIState;
  onScheduledExportClick: (item: AnalysisViewSearchResultWithUIState) => void;
}

export const SavedViewDetails = ({ item, onScheduledExportClick }: SavedViewDetailsProps) => {
  const canScheduleExports = !useHasFF('disable_exports_ff');
  return (
    <Wrapper>
      {canScheduleExports && (
        <div className="pro-box">
          <DetailsTable>
            <thead>
              <tr>
                <th>Scheduled Export</th>
                <th>Export History</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {!item.numScheduledExport ? (
                    '--'
                  ) : (
                    <LinkButton
                      onClick={(event) => {
                        event.stopPropagation();
                        onScheduledExportClick(item);
                      }}
                    >
                      {item.numScheduledExport}
                      <ShareIcon type="share-square" />
                    </LinkButton>
                  )}
                </td>
                <td>
                  {!item.scheduledFrequency || item.scheduledFrequency === 'UNKNOWN' ? (
                    '--'
                  ) : (
                    <>
                      <CalendarIcon type="calendar-check" />
                      {capitalize(item.scheduledFrequency)}
                    </>
                  )}
                </td>
              </tr>
            </tbody>
          </DetailsTable>
        </div>
      )}

      <div className="subjects">
        <DetailsTable>
          <thead>
            <tr>
              <th>Investments/Portfolios</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {!item.subjects.length ? (
                  '--'
                ) : (
                  <Subjects>
                    {item.subjects.map((subject) => (
                      <div key={subject.id}>
                        {subject.fund?.name ||
                          subject.portfolio?.name ||
                          subject.privateFund?.name ||
                          subject.privatePortfolio?.name}
                      </div>
                    ))}
                  </Subjects>
                )}
              </td>
            </tr>
          </tbody>
        </DetailsTable>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  margin-left: 35px;

  & > div {
    flex: 0 0;

    &.subjects {
      flex-grow: 1;
    }

    &.pro-box {
      width: 30%;
    }

    & + div {
      margin-left: 10px;
    }
  }
`;

const DetailsTable = styled.table`
  background-color: ${GetColor.GreyScale.Grey20};
  border: 1px solid ${GetColor.GreyScale.Grey30};
  width: 100%;

  thead {
    border-bottom: 1px solid ${GetColor.GreyScale.Grey30};

    th {
      padding: 10px 20px;
    }
  }

  tbody {
    td {
      padding: 10px 20px;
      border-bottom: 1px solid ${GetColor.GreyScale.Grey30};
      line-height: 24px;
    }
  }
`;

const LinkButton = styled.button`
  display: inline-block;
  color: ${GetColor.Primary.Dark};
`;

const Subjects = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    width: 50%;
    padding-right: 10px;
    white-space: normal;
  }
`;

const CalendarIcon = styled(Icon)`
  margin-right: 5px;
`;

const ShareIcon = styled(Icon)`
  margin-left: 5px;
`;
