import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import type { GeneralAnalysisTemplate, AnalysisTypeEnum, AnalysisBlockTypeEnum } from 'venn-api';
import { hasMultipleFunds, FundUtils, findAnyDataExportDisabled } from 'venn-utils';
import type { SharedProps } from './types';
import PerformanceSummary, { ResidualPerformanceSummary } from './components/performance-summary/index';
import Drawdown from './components/Drawdown';
import Scenario from './components/scenario-analysis/ScenarioAnalysisBlock';
import ReturnsGrid from './components/returns-grid/ReturnsGridBlock';
import { RollingErrorMessage } from './components/RollingErrorMessage';
import type { ManageDataLinkProps } from 'venn-components';
import {
  AnalysisRollingBetaChart,
  AnalysisRollingCorrelationChart,
  AnalysisReturnsDistributionChart,
  AnalysisRollingVolatilityChart,
  AnalysisRollingSharpeChart,
  CumulativeReturnChart,
  EmptyState,
  ProformaDrawdownAnalysis,
  getFailedAnalysisTrackingProps,
  PrintContainerDimensions,
  FactorSummaryCharts,
  FactorTrendCharts,
  SortByOption,
  getBlockTitle,
  FundCorrelationTable,
  AnalysisRollingReturnsChart,
  AnalysisConfigContext,
} from 'venn-components';
import MediaQuery from 'react-responsive';
import Loading from './components/Loading';
import RiskStatistics from './components/risk-statistics/RiskStatistics';
import PerformanceAttribution from './components/attribution/Attribution';
import PercentageDrawdownAnalysis from './components/PercentageDrawdownAnalysis';
import CumulativeResidualReturn from './components/CumulativeResidualReturn';
import NotableHistoricalPeriod from './components/NotableHistoricalPeriod';
import HistoricalDrawdownAndRallyScanner from './components/HistoricalDrawdownAndRallyScanner';

export const ComponentFromBlock = ({
  block,
  sharedProps,
  PerfSummaryBlock,
  getHeaderOffset,
  factorChartSortBy,
}: {
  block: AnalysisBlockTypeEnum;
  sharedProps: SharedProps;
  PerfSummaryBlock?: JSX.Element;
  getHeaderOffset?: () => number;
  factorChartSortBy?: SortByOption;
}) => {
  const {
    analyses,
    analysisConfig,
    analysesError,
    analysisLoading,
    factorLenses,
    downloadMetaData,
    onResetTimeFrame,
    updateAnalysisStatusForTracking,
  } = sharedProps;

  const analysesPeriod = analyses?.analysesPeriod;
  const { relative, subject, category, trackingId, selectedPeriod, selectedTimeFrame } = analysisConfig;
  const { actualTimeFrame } = useContext(AnalysisConfigContext);

  const sharedNoChartTrackingProps = useMemo(
    () =>
      getFailedAnalysisTrackingProps(
        analysisConfig?.analysisTemplate?.name || '',
        getBlockTitle(block, relative),
        actualTimeFrame,
        analysesPeriod,
        analysesError,
        subject,
        relative,
        block,
        trackingId,
        updateAnalysisStatusForTracking,
      ),
    [
      actualTimeFrame,
      analysesError,
      analysesPeriod,
      analysisConfig?.analysisTemplate?.name,
      block,
      relative,
      subject,
      trackingId,
      updateAnalysisStatusForTracking,
    ],
  );

  /* Self-loading blocks should be resolved before logic to determine whether or not
   * to show loading icon
   */
  if (block === 'PERFORMANCE_SUMMARY') {
    if (analysesError) {
      return null;
    }
    return (
      PerfSummaryBlock || (
        <PerformanceSummary
          {...sharedProps}
          noChartTrackingProps={sharedNoChartTrackingProps}
          analysisLoading={false}
        />
      )
    );
  }

  if (block === 'SCENARIO') {
    return analysesError ? null : (
      <Section className="qa-scenario">
        <Scenario {...sharedProps} noChartTrackingProps={sharedNoChartTrackingProps} />
      </Section>
    );
  }

  if (block === 'CORRELATION') {
    return analysesError ? null : (
      <PrintContainerDimensions>
        {({ width }) => (
          <FundCorrelationTable
            subject={subject}
            benchmarkRelative={relative}
            selectedTimeFrame={selectedTimeFrame}
            selectedPeriod={selectedPeriod}
            width={width}
            categoryActive={category === 'ON'}
            trackingProps={sharedNoChartTrackingProps}
            onResetTimeFrame={onResetTimeFrame}
            downloadMetaData={downloadMetaData}
          />
        )}
      </PrintContainerDimensions>
    );
  }
  /* End of self-loading blocks */

  if (!subject || analysisLoading || !analyses || !analyses.results) {
    return <Loading />;
  }

  const sharedExternalChartProps = {
    actualTimeFrame,
    subject,
    relative,
    noChartTrackingProps: sharedNoChartTrackingProps,
    downloadMetaData,
  };

  const activeFactorLens = factorLenses?.find((factorLens) => factorLens.primary);
  const defaultFactors = activeFactorLens?.factors || [];
  const trend = analyses.results?.factorAnalysis?.trend;
  const summary = analyses.results?.factorAnalysis?.summary;
  const sharedFactorProps = {
    analysisSubject: subject,
    portfolioHasMultipleFunds: hasMultipleFunds(subject.strategy),
    defaultFactorsName: activeFactorLens?.name,
    factorsDescriptions: [
      ...defaultFactors.map(({ id, description }) => ({
        id,
        description,
      })),
    ],
    relative,
    categoryActive: category === 'ON',
    activeFactorLens,
    analysesPeriod,
    secondaryLabel: subject?.secondaryLabel,
    benchmarkName: subject.activeBenchmarkName,
    selectedTimeFrame,
    trackingProps: sharedNoChartTrackingProps,
    selectedPeriod,
    onResetTimeFrame,
    downloadMetaData,
  };

  const sharedRollingProps: ManageDataLinkProps & { block: string } = {
    canEditReturn: FundUtils.isPerformanceEditable(subject?.fund),
    fundId: subject?.fund?.id,
    block,
  };

  switch (block) {
    case 'CUMULATIVE_RETURN':
      const hasCumulativeReturnsLoaded = !!analyses.results.cumulativeReturns.subject?.length;
      const cumulativeReturns = analyses.results.cumulativeReturns;
      const venncast = analyses.results.venncast;
      const disableExport = findAnyDataExportDisabled(analyses.resultsExportable?.cumulativeReturns);

      return !hasCumulativeReturnsLoaded ? (
        <Loading />
      ) : (
        <Section className="qa-cumulative-return">
          <CumulativeReturnChart
            frequency={analysesPeriod?.frequency}
            analysisGroup={cumulativeReturns}
            venncast={venncast}
            dataExportable={!disableExport}
            {...sharedExternalChartProps}
            // This chart should never error-out: if we can't show it, can't show anything else, so we should have
            // displayed an error at the earlier stage.
            noChartErrorComponent={
              <EmptyState
                header="Couldn't display Cumulative Return"
                message="There are insufficient returns available to analyze."
              />
            }
          />
        </Section>
      );
    case 'RETURNS_DISTRIBUTION':
      const returnsHistogram = analyses.results?.returnsHistogram;
      return (
        <Section className="qa-returns-distribution">
          <MediaQuery print>
            {(print) => (
              <AnalysisReturnsDistributionChart
                {...sharedExternalChartProps}
                frequency={analysesPeriod?.frequency}
                analysisGroup={returnsHistogram}
                print={print}
              />
            )}
          </MediaQuery>
        </Section>
      );
    case 'ROLLING_VOLATILITY':
      return (
        <Section className="qa-rolling-volatility">
          <AnalysisRollingVolatilityChart
            analysisGroup={analyses.results?.rollingVolatility}
            frequency={analysesPeriod?.frequency}
            categoryActive={category === 'ON'}
            {...sharedExternalChartProps}
            noChartErrorComponent={
              <RollingErrorMessage
                subjectType={subject.type}
                analysisType={relative ? 'Tracking Error' : 'Volatility'}
                {...sharedRollingProps}
              />
            }
            noChartTrackingProps={sharedNoChartTrackingProps}
          />
        </Section>
      );
    case 'ROLLING_SHARPE_RATIO':
      return (
        <Section className="qa-rolling-sharpe-ratio">
          <AnalysisRollingSharpeChart
            analysisGroup={analyses.results?.rollingSharpe}
            frequency={analysesPeriod?.frequency}
            categoryActive={category === 'ON'}
            {...sharedExternalChartProps}
            noChartErrorComponent={
              <RollingErrorMessage
                subjectType={subject.type}
                analysisType={relative ? 'Information Ratio' : 'Sharpe Ratio'}
                {...sharedRollingProps}
              />
            }
          />
        </Section>
      );
    case 'ROLLING_BETA':
      return (
        <Section className="qa-rolling-beta">
          <AnalysisRollingBetaChart
            analysisGroup={analyses.results?.rollingBeta}
            frequency={analysesPeriod?.frequency}
            categoryActive={category === 'ON'}
            {...sharedExternalChartProps}
            noChartErrorComponent={
              <RollingErrorMessage
                subjectType={subject.type}
                analysisType="Rolling Beta"
                analysisError={analyses.results?.rollingBeta[0]?.message}
                {...sharedRollingProps}
              />
            }
          />
        </Section>
      );
    case 'ROLLING_BENCHMARK_CORRELATION':
      return (
        <Section className="qa-rolling-correlation">
          <AnalysisRollingCorrelationChart
            analysisGroup={analyses.results?.rollingBenchmarkCorrelation}
            frequency={analysesPeriod?.frequency}
            categoryActive={category === 'ON'}
            {...sharedExternalChartProps}
            noChartErrorComponent={
              <RollingErrorMessage
                subjectType={subject.type}
                analysisType="Rolling Benchmark Correlation"
                analysisError={analyses.results?.rollingBenchmarkCorrelation[0]?.message}
                {...sharedRollingProps}
              />
            }
          />
        </Section>
      );
    case 'ROLLING_RETURN':
      return (
        <Section className="qa-rolling-return">
          <AnalysisRollingReturnsChart
            analysisGroup={analyses.results?.rollingReturn}
            exportableGroup={analyses.resultsExportable?.rollingReturn}
            frequency={analysesPeriod?.frequency}
            categoryActive={category === 'ON'}
            {...sharedExternalChartProps}
            noChartTrackingProps={{
              ...sharedExternalChartProps.noChartTrackingProps,
              errorCode:
                sharedExternalChartProps.noChartTrackingProps.errorCode ??
                analyses.results?.rollingReturn[0]?.message?.code,
            }}
            noChartErrorComponent={
              <RollingErrorMessage
                subjectType={subject.type}
                analysisType={relative ? 'Excess Return' : 'Return'}
                analysisError={analyses.results?.rollingReturn[0]?.message}
                {...sharedRollingProps}
              />
            }
          />
        </Section>
      );
    case 'HISTORICAL_DRAWDOWN_PERIODS':
      return (
        <Section className="qa-historical-drawdown">
          <ProformaDrawdownAnalysis
            {...sharedExternalChartProps}
            analysisGroup={analyses.results?.proformaDrawdown}
            frequency={analysesPeriod?.frequency}
            noChartTrackingProps={sharedNoChartTrackingProps}
          />
        </Section>
      );
    case 'FACTOR_CONTRIBUTION_TO_RETURN':
    case 'FACTOR_CONTRIBUTION_TO_RISK':
    case 'FACTOR_CONTRIBUTION_TO_EXPOSURE':
      return (
        <Section className="qa-factor-summary">
          <PrintContainerDimensions>
            {({ width }) => (
              <FactorSummaryCharts
                block={block}
                sortBy={factorChartSortBy ?? SortByOption.SortByDefault}
                analysisGroup={summary}
                {...sharedFactorProps}
                width={width}
              />
            )}
          </PrintContainerDimensions>
        </Section>
      );
    case 'FACTOR_CONTRIBUTION_TO_RETURN_TREND':
    case 'FACTOR_CONTRIBUTION_TO_RISK_TREND':
    case 'FACTOR_CONTRIBUTION_TO_EXPOSURE_TREND':
      return (
        <Section className="qa-factor-trend">
          <PrintContainerDimensions>
            {({ width }) => (
              <FactorTrendCharts block={block} analysisGroup={trend} {...sharedFactorProps} width={width} />
            )}
          </PrintContainerDimensions>
        </Section>
      );

    case 'RISK_STATISTICS':
      return (
        <Section className="qa-risk-statistics">
          <RiskStatistics {...sharedProps} />
        </Section>
      );

    case 'DRAWDOWN':
      return (
        <Section className="qa-drawdown">
          <Drawdown {...sharedProps} getHeaderOffset={getHeaderOffset} trackingProps={sharedNoChartTrackingProps} />
        </Section>
      );

    case 'RETURNS_GRID':
      return (
        <Section className="qa-returns-grid">
          <ReturnsGrid {...sharedProps} trackingProps={sharedNoChartTrackingProps} />
        </Section>
      );

    case 'PERFORMANCE_ATTRIBUTION':
      return (
        <Section className="qa-performance-attribution">
          <PerformanceAttribution {...sharedProps} trackingProps={sharedNoChartTrackingProps} />
        </Section>
      );

    case 'CUMULATIVE_RESIDUAL_RETURN':
      return (
        <Section className="qa-cumulative-residual-return">
          <CumulativeResidualReturn {...sharedProps} trackingProps={sharedNoChartTrackingProps} />
        </Section>
      );
    case 'PERCENTAGE_DRAWDOWN':
      return (
        <Section className="qa-historical-drawdown-chart">
          <PercentageDrawdownAnalysis {...sharedProps} trackingProps={sharedNoChartTrackingProps} />
        </Section>
      );
    case 'RESIDUAL_PERFORMANCE_SUMMARY':
      return (
        <Section className="qa-residual-performance_summary">
          <ResidualPerformanceSummary {...sharedProps} trackingProps={sharedNoChartTrackingProps} />
        </Section>
      );
    case 'NOTABLE_HISTORICAL_PERIODS':
      return (
        <Section className="qa-notable-historical-periods">
          <NotableHistoricalPeriod
            {...sharedProps}
            trackingProps={sharedNoChartTrackingProps}
            analysisFrequency={analysesPeriod?.frequency}
          />
        </Section>
      );
    case 'HISTORICAL_DRAWDOWN_AND_RALLY_SCANNER':
      return (
        <Section className="qa-historical-drawdown-and-rally-scanner">
          <HistoricalDrawdownAndRallyScanner
            {...sharedProps}
            trackingProps={sharedNoChartTrackingProps}
            analysisFrequency={analysesPeriod?.frequency}
          />
        </Section>
      );

    default:
      return null;
  }
};

export const getAnalysisTypeEnumFromTemplate = (template: GeneralAnalysisTemplate): AnalysisTypeEnum[] | undefined => {
  if (!template) {
    return undefined;
  }
  if (!template.analysisBlocks) {
    return undefined;
  }
  const enums: AnalysisTypeEnum[] = [];
  template.analysisBlocks.forEach((block) => {
    if (block && block.analysisList) {
      enums.push(...block.analysisList);
    }
  });
  // Remove duplicates from analyses
  return [...new Set(enums)];
};

const Section = styled.section`
  padding-top: 20px;
  @media print {
    width: 100%;
  }
`;
