import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import { Tooltip, TooltipPosition, BoxButton } from 'venn-ui-kit';
import type { AnalysisTypeEnum } from 'venn-api';

export interface RollingPeriodPickerProps {
  periods: { [key: string]: boolean } | undefined;
  selectedPeriod: number | null | undefined;
  block: AnalysisTypeEnum;
  onUpdateRollingPeriod?: (rollingYears: number, trendBlock: AnalysisTypeEnum) => void;
}

export const RollingPeriodPicker: FC<React.PropsWithChildren<RollingPeriodPickerProps>> = ({
  periods,
  selectedPeriod,
  block,
  onUpdateRollingPeriod,
}) => {
  return (
    <Flex>
      <div>Rolling Period:</div>
      {periods &&
        Object.entries(periods)
          .sort()
          .map(([key, value]) => {
            const rollingPeriod = Number(key);
            const cantRunRollingAnalysis = !value;
            const activePeriod = selectedPeriod === rollingPeriod;
            return (
              <Tooltip
                key={key}
                content="There’s not enough history to perform this analysis"
                isHidden={!cantRunRollingAnalysis}
                position={TooltipPosition.Left}
              >
                <BoxButton
                  active={activePeriod}
                  disabled={cantRunRollingAnalysis}
                  onClick={
                    !activePeriod && onUpdateRollingPeriod && block
                      ? () => {
                          onUpdateRollingPeriod(rollingPeriod, block);
                        }
                      : undefined
                  }
                >
                  {rollingPeriod === 0.5 ? '6M' : `${rollingPeriod}Y`}
                </BoxButton>
              </Tooltip>
            );
          })}
    </Flex>
  );
};

const Flex = styled.div`
  display: flex;
  padding: 0 20px;
  align-items: center;
  font-weight: bold;
  justify-content: flex-end;

  @media print {
    font-size: 8px;
    position: absolute;
    top: 10px;
    right: 0;
    padding-right: 10px;
  }
`;

export default RollingPeriodPicker;
