import React from 'react';
import styled, { css } from 'styled-components';
import { Carousel, CarouselSlide, Markdown } from 'venn-components';
import type { NotablePeriod } from '../../shared/FactorEducationUtils';
import { Icon, GetColor, Headline3, Body1, ZIndex } from 'venn-ui-kit';
import { SpecialCssClasses } from 'venn-utils';

export interface FactorEducationBoxProps {
  selectedNotablePeriod?: number;
  notablePeriods?: NotablePeriod[];
  factorName: string;
  factorDescription: string;
  suggestShowFactorInputs?: boolean;
  onShowFactorInputs?: () => void;
  onSelectSlide: (slideIdx: number, trigger?: string) => void;
}

export interface FactorEducationBoxState {
  expanded: boolean;
}

const FACTOR_CONSTRUCTION_NAME = 'qa-factor-construction-name';

export default class FactorEducationBox extends React.Component<FactorEducationBoxProps, FactorEducationBoxState> {
  state = { expanded: true };

  static getDerivedStateFromProps(nextProps: FactorEducationBoxProps, prevState: FactorEducationBoxState) {
    return !prevState.expanded && nextProps.selectedNotablePeriod !== undefined ? { expanded: true } : null;
  }

  onToggle = () => {
    if (this.state.expanded) {
      this.props.onSelectSlide(0);
    }
    this.setState((prevState) => ({ expanded: !prevState.expanded }));
  };

  render() {
    const {
      selectedNotablePeriod,
      notablePeriods,
      factorName,
      factorDescription,
      suggestShowFactorInputs,
      onShowFactorInputs,
      onSelectSlide,
    } = this.props;
    const { expanded } = this.state;
    return (
      <>
        {expanded && (
          <CollapseButtonContainer className={SpecialCssClasses.NotDownloadable}>
            <CollapseButton onClick={this.onToggle}>
              Collapse
              <Icon type="chevron-up" />
            </CollapseButton>
          </CollapseButtonContainer>
        )}
        {!expanded && (
          <CollapsedBar onClick={this.onToggle} className={SpecialCssClasses.NotDownloadable}>
            Show Factor Details
            <Icon type="chevron-down" />
          </CollapsedBar>
        )}
        {expanded && (
          <Carousel
            useExternalState
            selectedItem={selectedNotablePeriod !== undefined ? selectedNotablePeriod + 1 : 0}
            onSelectionChanged={onSelectSlide}
          >
            {[
              <CarouselSlide description="Factor construction" key={0}>
                <Headline3 className={FACTOR_CONSTRUCTION_NAME}>The {factorName} Factor</Headline3>
                <Body1>
                  <StyledMarkdown
                    source={
                      factorDescription ? factorDescription.split('\\n').join('\n') : 'This factor has no description'
                    }
                  />
                  {suggestShowFactorInputs && (
                    <>
                      {' '}
                      <ShowFactorInputsPseudoLink onClick={onShowFactorInputs}>
                        Show factor inputs
                      </ShowFactorInputsPseudoLink>{' '}
                      to view in detail.
                    </>
                  )}
                </Body1>
              </CarouselSlide>,
              ...(notablePeriods || []).map((notablePeriod: NotablePeriod, idx: number) => (
                // eslint-disable-next-line react/no-array-index-key
                <CarouselSlide description="Notable performance period" key={idx + 1}>
                  <Headline3 className={FACTOR_CONSTRUCTION_NAME}>{factorName}</Headline3>
                  <Body1>
                    <List>
                      {notablePeriod.contents.map(
                        // @ts-expect-error: TODO fix strictFunctionTypes
                        (content: string, i: number) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <ListItem key={i}>{content}</ListItem>
                        ),
                      )}
                    </List>
                  </Body1>
                </CarouselSlide>
              )),
            ]}
          </Carousel>
        )}
      </>
    );
  }
}

const StyledMarkdown = styled(Markdown)`
  p {
    margin-top: 2px;
    margin-bottom: 2px;
  }
`;

const collapseTriggerStyles = css`
  &:hover {
    cursor: pointer;
  }
  color: ${GetColor.Primary.Dark};
  i {
    padding-left: 10px;
    color: ${GetColor.MidGrey2};
  }
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  padding-right: 30px;
`;

const CollapseButtonContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
`;

const CollapseButton = styled.div`
  ${collapseTriggerStyles}
  position: absolute;
  height: 60px;
  line-height: 60px;
  z-index: ${ZIndex.Front};
`;

const CollapsedBar = styled.div`
  ${collapseTriggerStyles}
  height: 30px;
  min-height: 20px;
  line-height: 30px;
  background-color: ${GetColor.PaleGrey};
  border-top: 1px solid ${GetColor.Grey};
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const ListItem = styled.li`
  line-height: 20px;
`;

const List = styled.ul`
  padding-top: 18px;
  padding-left: 18px;
`;

const ShowFactorInputsPseudoLink = styled.strong`
  color: ${GetColor.Primary.Dark};
  :hover {
    cursor: pointer;
  }
`;
