import React, { useCallback, useContext, useEffect, useState } from 'react';
import type {
  ColumnErrors,
  FileMapping,
  FilePreview,
  FileUploadMetadata,
  ParsedPrivateFunds,
  Portfolio,
  UploadTypeEnum,
} from 'venn-api';
import { cachedGetFileUploadMetadata, storePrivatesXLS } from 'venn-api';
import { Review } from './views';
import {
  AppendType,
  DataUploaderMode,
  type MultiUploaderReviewViewId,
  type MultiUploaderUploadViewId,
  type PortfolioMultiUploaderView,
} from './types';
import {
  IChooseActionView,
  IReviewPrivatesView,
  IReviewReturnsView,
  IUploadPrivatesView,
  IUploadReturnsView,
} from './constants';
import {
  fetchCorrectData,
  fetchParsedFile,
  fetchPersistSeries,
  fetchPrivatesUploadFile,
  fetchUpdateMapping,
  fetchUploadFile,
} from './fetchers';
import { Provider } from './context';
import type { ErrorViewModel } from './views/review/helpers';
import {
  addInvestmentsToPortfolio,
  analyticsService,
  createPortfolioFromFunds,
  logExceptionIntoSentry,
} from 'venn-utils';
import { UserContext } from '../../contexts';
import { getErrorCounts, uploadConfig } from './utils';
import { ReviewPrivates } from './views/review/ReviewPrivates';
import { useRecoilValue } from 'recoil';
import { globalUploadAppendTypePrivateFund, uploadAppendTypePrivateFundMap } from 'venn-state';
import { InvestmentUploadView } from './views/InvestmentUploadView';
import styled from 'styled-components';
import { noop } from 'lodash';
import Loading from './components/Loading';

export interface InlineDataUploaderProps {
  viewId: MultiUploaderUploadViewId | MultiUploaderReviewViewId;
  setView: (view: PortfolioMultiUploaderView) => void;
  portfolio?: Portfolio;
  strategyId?: number;
  onComplete?: (portfolio: Portfolio, newFunds?: Portfolio[]) => void;
  onCompleteNavigate?: (mode: DataUploaderMode, uploadedFundIds?: string[]) => void;
  /**
   * Custom text to display on the Upload Review screen
   */
  customUploadReviewButtonLabel?: string;
}

interface DataUploaderState {
  loading: boolean;
  error: string | React.ReactNode;
  uploadType: UploadTypeEnum | null;
  metadata?: FileUploadMetadata;
  mapping?: FileMapping;
  preview?: FilePreview;
  errors?: ColumnErrors[];
  parsedPrivateFunds?: ParsedPrivateFunds;
}

const trackColumnErrors = (errors: ColumnErrors[], step: number, mode: DataUploaderMode = DataUploaderMode.Returns) => {
  if (errors.length > 0) {
    const errorCountMap = getErrorCounts(errors);
    const errorTypes: string[] = Object.keys(errorCountMap);
    const errorCounts: number[] = errorTypes.map((type) => errorCountMap[type]);
    analyticsService.uploadStepFailed({
      dataType: getStringMode(mode),
      step,
      errorTypes,
      errorCounts,
    });
  }
};

const getStringMode = (mode: DataUploaderMode) => uploadConfig[mode].dataType;

const ANALYTICS_UPLOAD_TYPE: { [K in UploadTypeEnum]?: string } = {
  FILE: 'file',
  COPY_PASTE: 'copy-paste',
  SAMPLE_FILE: 'samplefile',
};

export const InvestmentDataUploader = ({
  viewId,
  setView,
  portfolio,
  strategyId,
  onComplete,
  onCompleteNavigate,
  customUploadReviewButtonLabel,
}: InlineDataUploaderProps) => {
  const legacyMode =
    viewId === 'UPLOAD_PRIVATES' || viewId === 'REVIEW_PRIVATES' ? DataUploaderMode.Privates : DataUploaderMode.Returns;
  const legacyStep = viewId === 'UPLOAD_RETURNS' || viewId === 'UPLOAD_PRIVATES' ? 0 : 1;

  const { setUserCompletedUpload } = useContext(UserContext);
  const [state, setState] = useState<DataUploaderState>({
    loading: false,
    error: '',
    uploadType: null,
  });
  const globalAppendType = useRecoilValue(globalUploadAppendTypePrivateFund);
  const fundAppendTypes = useRecoilValue(uploadAppendTypePrivateFundMap);

  useEffect(() => {
    const analyticsOpts = {
      step: legacyStep,
      dataType: getStringMode(legacyMode),
    };
    analyticsService.uploadStepViewed(analyticsOpts);
  }, [legacyMode, legacyStep]);

  const onUploadContinueWithPaste = (data: Blob, isSample = false) =>
    onUploadContinue(data, isSample ? 'SAMPLE_FILE' : 'COPY_PASTE');

  const onUploadContinue = async (data: File | Blob, uploadType: UploadTypeEnum = 'FILE') => {
    setState((s) => ({
      ...s,
      uploadType,
      loading: true,
      error: '',
    }));

    const dataType = getStringMode(legacyMode);
    try {
      if (viewId === 'UPLOAD_PRIVATES') {
        await privatesOnUploadContinue(data);
      } else if (viewId === 'UPLOAD_RETURNS') {
        await returnOnUploadContinue(data, uploadType);
      }
    } catch (e) {
      const error = e?.message;
      const code = e?.code;
      const message = [10423, 10424, 10421, 10428, 10425, 10434].includes(code)
        ? error
        : 'Unable to load data. Double-check your format and try again.';
      analyticsService.uploadStepFailed({
        dataType,
        step: legacyStep,
        error,
      });
      setState((s) => ({
        ...s,
        loading: false,
        error: message,
      }));
    }
  };

  const onMappingChange = async (newMapping: FileMapping) => {
    setState((s) => ({
      ...s,
      loading: true,
      error: '',
    }));
    try {
      const { mapping } = await fetchUpdateMapping(newMapping.fileId, newMapping);
      const { errors } = await fetchCorrectData(newMapping.fileId, []);
      setState((s) => ({
        ...s,
        loading: false,
        errors,
        mapping,
      }));
    } catch (e) {
      const error = e?.message || 'An error occurred updating mapping';
      analyticsService.uploadStepFailed({
        dataType: getStringMode(legacyMode),
        step: legacyStep,
        error,
      });
      setState((s) => ({
        ...s,
        loading: false,
        error,
      }));
    }
  };

  const onReviewContinue = async (mapping: FileMapping, corrections: ErrorViewModel[]) => {
    const { mapping: { fileId } = mapping, uploadType, metadata } = state;
    setState((s) => ({
      ...s,
      loading: true,
      error: '',
    }));
    try {
      await fetchCorrectData(fileId, corrections);
      const { errorCount, createdCount, updatedCount, responseObject } = await fetchPersistSeries(fileId, uploadType!);
      setState((s) => ({
        ...s,
        loading: false,
      }));
      const analyticsObj = {
        dataType: getStringMode(legacyMode),
        numberOfDiscarded: errorCount,
        numberOfExisting: updatedCount,
        numberOfInvestments: updatedCount + createdCount,
        numberOfNew: createdCount,
        uploadType: uploadType ? ANALYTICS_UPLOAD_TYPE[uploadType] : undefined,
      };

      analyticsService.investmentsUploaded(analyticsObj);
      if (onComplete) {
        const { updatedPortfolio, newFunds } = portfolio
          ? addInvestmentsToPortfolio(responseObject, portfolio, strategyId)
          : {
              updatedPortfolio: createPortfolioFromFunds(responseObject),
              newFunds: undefined,
            };
        onComplete(updatedPortfolio, newFunds);
      }
      if (onCompleteNavigate && metadata) {
        onCompleteNavigate(
          legacyMode,
          responseObject.map((fund) => fund.id),
        );
      }
      setUserCompletedUpload(true);
    } catch (e) {
      const error = e?.message || 'Unknown error in review step';
      analyticsService.uploadStepFailed({
        dataType: getStringMode(legacyMode),
        step: legacyStep,
        error,
      });
      setState((s) => ({
        ...s,
        loading: false,
        error,
      }));
    }
  };

  const onPrivatesReviewContinue = async (parsedPrivateFunds: ParsedPrivateFunds) => {
    setState((s) => ({
      ...s,
      loading: true,
      error: '',
    }));
    try {
      // updated mappings
      const newMappings = parsedPrivateFunds.mappings.map((m) => {
        let writeType = m.writeType;
        switch (fundAppendTypes[m.rawFundName] ?? globalAppendType) {
          case AppendType.OVERWRITE_ALL:
            writeType = 'OVERWRITE';
            break;
          case AppendType.OVERWRITE_OVERLAPPING:
            writeType = 'UPSERT';
            break;
          case AppendType.APPEND:
            writeType = 'APPEND';
            break;
          default:
            logExceptionIntoSentry(`Unknown append type: ${fundAppendTypes[m.rawFundName] ?? globalAppendType}`);
        }
        return { ...m, writeType };
      });

      const result = await storePrivatesXLS(parsedPrivateFunds.fileId, newMappings);
      setState((s) => ({
        ...s,
        loading: false,
      }));

      if (onCompleteNavigate && parsedPrivateFunds.mappings) {
        onCompleteNavigate(
          legacyMode,
          result.content.map((fund) => fund.id),
        );
      }
      setUserCompletedUpload(true);
    } catch (e) {
      const error = e?.content?.message || 'Unknown error in review step';
      analyticsService.uploadStepFailed({
        dataType: getStringMode(legacyMode),
        step: legacyStep,
        error,
      });
      setState((s) => ({
        ...s,
        loading: false,
        error,
      }));
    }
  };

  const navigateToChooseActionView = useCallback(() => {
    setView(IChooseActionView);
  }, [setView]);

  const renderStep = () => {
    const { parsedPrivateFunds, mapping, metadata, errors, loading, uploadType } = state;
    switch (viewId) {
      case 'UPLOAD_PRIVATES':
      case 'UPLOAD_RETURNS':
        return (
          <InvestmentUploadView
            onCancel={navigateToChooseActionView}
            viewId={legacyMode === DataUploaderMode.Privates ? 'UPLOAD_PRIVATES' : 'UPLOAD_RETURNS'}
            onPaste={onUploadContinueWithPaste}
            onUpload={onUploadContinue}
            loading={loading}
          />
        );
      case 'REVIEW_RETURNS': {
        if (!mapping || !metadata) {
          return null;
        }
        return (
          <Review
            multiUploaderLayout
            metadata={metadata}
            mapping={mapping}
            errorColumns={errors}
            onMappingChange={onMappingChange}
            onCancel={() => setView(IUploadReturnsView)}
            onStartOver={navigateToChooseActionView}
            onContinue={onReviewContinue}
            loading={loading}
            isSample={uploadType === 'SAMPLE_FILE'}
            customUploadReviewButtonLabel={customUploadReviewButtonLabel}
          />
        );
      }
      case 'REVIEW_PRIVATES': {
        if (!parsedPrivateFunds || !metadata) {
          return null;
        }
        return (
          <ReviewPrivates
            multiUploaderLayout
            parsedPrivateFunds={parsedPrivateFunds}
            onMappingChange={onMappingChange}
            onCancel={() => setView(IUploadPrivatesView)}
            onStartOver={navigateToChooseActionView}
            onContinue={onPrivatesReviewContinue}
            loading={loading}
            customUploadReviewButtonLabel={customUploadReviewButtonLabel}
            errors={[]}
            metadata={metadata}
          />
        );
      }
      default:
        return null;
    }
  };

  const processFileMapping = async (mapping: FileMapping, uploadType: UploadTypeEnum) => {
    const { columns, fileId } = mapping;
    if (columns.length === 0) {
      const error = 'There is no valid data to upload. Double-check your format and try again.';
      analyticsService.uploadStepFailed({
        dataType: getStringMode(legacyMode),
        step: legacyStep,
        error,
      });
      setState((s) => ({
        ...s,
        loading: false,
        error,
      }));
      return;
    }

    const metadata = await cachedGetFileUploadMetadata();
    const { errors } = await fetchCorrectData(fileId, []);
    trackColumnErrors(errors, legacyStep + 1);
    goToNextStep({
      mapping,
      metadata: metadata.content,
      errors,
      uploadType,
    });
  };

  const returnOnUploadContinue = async (data: File | Blob, uploadType: UploadTypeEnum) => {
    // Set the uploaded file to "draft" status so that it does not appear in the app. The file will
    // be marked as non-draft when the extracted returns are complete.
    const document = await fetchUploadFile(data, uploadType, true);
    const { mapping } = await fetchParsedFile(document);

    await processFileMapping(mapping, uploadType);
  };

  const privatesOnUploadContinue = async (data: File | Blob) => {
    // Set the uploaded file to "draft" status so that it does not appear in the app. The file will
    // be marked as non-draft when the extracted returns are complete.
    const parsedPrivateFunds = await fetchPrivatesUploadFile(data);
    const metadata = await cachedGetFileUploadMetadata();

    goToNextStep({
      metadata: metadata.content,
      parsedPrivateFunds,
    });
  };
  const goToNextStep = (stateUpdates: Partial<DataUploaderState>) => {
    const uploadType = state.uploadType || stateUpdates.uploadType;
    analyticsService.uploadStepCompleted({
      step: legacyStep,
      dataType: getStringMode(legacyMode),
      uploadType: uploadType ? String(uploadType) : undefined,
    });

    setState((s) => ({
      ...s,
      ...stateUpdates,
      loading: false,
    }));

    setView(viewId === 'UPLOAD_RETURNS' ? IReviewReturnsView : IReviewPrivatesView);
  };

  return (
    <Provider
      value={{
        mode: legacyMode,
        error: state.error,
        step: legacyStep,
        setStep: noop,
        portfolio,
      }}
    >
      <UploadModalWrapper data-testid="uploadModal">
        {renderStep()}
        {state.loading && legacyStep > 0 && <Loading />}
      </UploadModalWrapper>
    </Provider>
  );
};

const UploadModalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-self: stretch;
  overflow: hidden;
`;
