const specialCssClasses = {
  /**
   * Any element with this class will be removed on downloaded/generated images
   */
  NotDownloadable: 'not-downloadable',
  /**
   * Any element with this class with still be present, but will have its contents removed, on downloaded/generated images
   */
  HiddenInDownloadable: 'hidden-in-downloadable',
  /**
   * Any element with this class will be have `display` attribute to `inline-block` on downloaded/generated images.
   */
  OnlyInDownloadable: 'only-in-downloadable',
  /**
   * Any Element with this class will have max-height: fit-content set on downloaded/generated images.
   * This is useful when the element has a max height set on the app since the default behavior
   * is only for the visible content to be downloaded, so the non-scrolled part won't get shown.
   */
  DownloadableHeightFitContent: 'downloadable-height-fit-content',
  /**
   * This class is added at the root of the element that is going to be
   * converted into an image (for the Downloadable component).
   * Use this to modify/hide elements when exporting as image.
   */
  ExportAsImage: 'export-as-image',

  HideInReports: 'hide-in-reports',
};

export default specialCssClasses;
