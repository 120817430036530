import { DefaultValue, atomFamily, selectorFamily } from 'recoil';
import type { BlockId } from '../studio';
import { reportZoom } from './pageConfiguration';

type Size = {
  height: number;
  width: number;
};
export type SizeState = Size | undefined;

const capitalizeFirstChar = <S extends string>(str: S): Capitalize<S> =>
  (str.charAt(0).toUpperCase() + str.slice(1)) as Capitalize<S>;

const createState = (keyPart: string) => {
  const keyPartCapitalized = capitalizeFirstChar(keyPart);

  const rawInternalKey = `raw${keyPartCapitalized}Internal` as const;
  const transformedInternalKey = `transformed${keyPartCapitalized}Internal` as const;
  const rawSelectorKey = `raw${keyPartCapitalized}State` as const;
  const transformedSelectorKey = `transformed${keyPartCapitalized}State` as const;

  /*
   * We store two atoms, because we only want to scale/transform on set. Because the transform can change when the size hasn't yet changed,
   * performing the transformation on read would create a read-write consistency (read-write ordering) problem. But if we perform the transform
   * on write, then we know that the read will always be correct.
   */
  const rawInternalState = atomFamily<SizeState, BlockId>({
    key: rawInternalKey,
    default: undefined,
  });
  const transformedInternalState = atomFamily<SizeState, BlockId>({
    key: transformedInternalKey,
    default: undefined,
  });

  const createSelectorFamily = (stateKey: string, isInputRaw: boolean) =>
    selectorFamily<SizeState, BlockId>({
      key: stateKey,
      set:
        (blockId: BlockId) =>
        ({ get, set, reset }, newValue) => {
          const internalRawState = rawInternalState(blockId);
          const internalTransformedState = transformedInternalState(blockId);

          if (newValue instanceof DefaultValue) {
            reset(internalRawState);
            reset(internalTransformedState);
            return;
          }

          const zoomFactor = get(reportZoom);
          const toRawFactor = isInputRaw ? 1 : 1 / zoomFactor;
          const toTransformFactor = isInputRaw ? zoomFactor : 1;

          const [rawNewValue, transformedNewValue] = newValue
            ? [
                { width: newValue.width * toRawFactor, height: newValue.height * toRawFactor },
                { width: newValue.width * toTransformFactor, height: newValue.height * toTransformFactor },
              ]
            : [undefined, undefined];

          set(internalRawState, rawNewValue);
          set(internalTransformedState, transformedNewValue);
        },
      get:
        (blockId: BlockId) =>
        ({ get }) =>
          isInputRaw ? get(rawInternalState(blockId)) : get(transformedInternalState(blockId)),
    });

  return {
    rawState: createSelectorFamily(rawSelectorKey, true),
    transformedState: createSelectorFamily(transformedSelectorKey, false),
  } as const;
};

type MessagePosition = 'Bottom' | 'Right' | 'Bottom-Right' | 'Surround';

export interface OverflowError {
  message: string;
  position: MessagePosition;
}

/**
 * Error indicating if a block is overflowing
 * Either due to content size exceeding the max size, or overlap with the footer
 * TODO: Remove this if possible in the future, as this is only needed to style the grid resize handle
 */
export const blockOverflowErrorState = atomFamily<OverflowError | undefined, BlockId>({
  key: 'blockOverflowErrorState',
  default: undefined,
});

/**
 * Container object for Recoil state indicating the size of a block's contents.
 * For example, the dimensions of the content inside a block if it were not contained by some limiting container.
 *
 * Contains two writable 'raw' and 'transformed' states which are kept synchronized.
 *
 * Transformed is the value after taking into account any zoom or scaling being performed in Report Lab. We receive and use
 * transformed values almost everywhere except ag-grid events and configuration.
 *
 * Raw is the unscaled/untransformed value. Ag-grid tends to output and be configured using raw values.
 *
 * Warning: You should generally use the debounced useSetBlockSize custom hook rather than setting these states directly.
 */
export const recoilBlockContentSize = createState('blockContentSize');

/**
 * NOTE: use the hook useBlockWidth instead if you want instant responses to block width.
 * These states are not only in Recoil which incurs a 1 render delay to changes, but it also may be delayed due to debouncing.
 *
 * This is a container object for Recoil state indicating the max size available to a block within a grid in studio.
 * For example, the dimensions of the block container itself.
 *
 * Contains two writable 'raw' and 'transformed' states which are kept synchronized.
 * Transformed is the value after taking into account any zoom or scaling being performed in Report Lab.
 * Raw is the unscaled/untransformed value. Ag-grid tends to work with raw values, while the rest of our UI tends to use transformed values.
 *
 * Warning: you should generally use the debounced useSetBlockSize custom hook rather than setting these states directly.
 *
 */
export const recoilBlockMaxSize = createState('blockContentMaxSize');
