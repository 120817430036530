import React from 'react';
import { Button, Icon, Tooltip, TooltipPosition } from 'venn-ui-kit';
import styled from 'styled-components';
import useShare from './useShare';
import { ShareModal } from '../modals';
import type { ShareMetadata } from 'venn-api';
import { IconButton } from './shared';

export type ShareTriggerClickCallback = (openShareModal: () => void) => void;

interface ShareEmailButtonProps {
  analyticsDescription: string;
  disabled?: boolean;
  densed?: boolean;
  shareMetaData: Partial<ShareMetadata>;
  onShareTriggerClick?: ShareTriggerClickCallback;
}

const ShareEmailButton = ({
  analyticsDescription,
  disabled,
  densed,
  shareMetaData,
  onShareTriggerClick,
}: ShareEmailButtonProps) => {
  const { handleShare, handleCopyToClipboard, isShareModalOpen, closeShareModal } = useShare(
    analyticsDescription,
    disabled,
  );

  const handleShareTriggerClick = onShareTriggerClick ? () => onShareTriggerClick(handleShare) : handleShare;

  return (
    <Wrapper className="share-button-container">
      <Tooltip content="Copy URL" position={TooltipPosition.Bottom}>
        <IconButton noMargin dense onClick={handleCopyToClipboard} disabled={disabled}>
          <Icon type="link" />
        </IconButton>
      </Tooltip>
      <ShareButton noMargin dense onClick={handleShareTriggerClick} disabled={disabled} className="qa-share-button">
        <Icon type="envelope" />
        {!densed && <span>&nbsp; Share</span>}
      </ShareButton>
      {isShareModalOpen && <ShareModal onClose={closeShareModal} shareMetaData={shareMetaData} />}
    </Wrapper>
  );
};

export default ShareEmailButton;

const Wrapper = styled.div`
  min-width: 130px;
`;

const ShareButton = styled(Button)`
  border-left: none;
  border-radius: 0px 2px 2px 0px;

  && {
    margin-left: 0;
  }
`;
