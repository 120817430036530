import React, { useMemo } from 'react';
import styled from 'styled-components';
import { ColumnAlign, ContentBlock, SmallToggle } from 'venn-components';
import { GetColor, Tooltip, TooltipBodyDirection, TooltipLink, Icon, METRIC_DEFINITIONS_HREF } from 'venn-ui-kit';
import type { Message } from 'venn-api';
import { isNil } from 'lodash';
import { Delta, ShimmerTr, SuperHeader, Table, TableShimmer, TableShimmerRow } from './styled-table';
import ErrorContent from './ErrorContent';
import type { Contributions } from './Cell';
import Cell from './Cell';

export interface PerformanceRiskMetricRow {
  metricName: string;
  historical: number | null;
  historicalBase: number | null;
  forecast?: number;
  forecastBase?: number;
  isPercentage: boolean;
  cumulative?: boolean;
}

export interface PerformanceMetricRow extends PerformanceRiskMetricRow {
  contributions: Contributions;
}

interface PortfolioPerformanceCardViewProps {
  portfolioName?: string;
  portfolioId?: number;
  solutionName: string;
  performanceData: PerformanceMetricRow[];
  riskData: PerformanceRiskMetricRow[];
  loading: boolean;
  onChangeObjective: () => void;
  relative: boolean;
  onToggleRelative: () => void;
  canToggleRelative: boolean;
  relativeToggleTooltip: string | undefined;
  errors?: (Message | undefined)[];
}

const getColumns = (
  metricColumnLabel: string,
  portfolioName: string,
  portfolioId: number,
  relative: boolean,
  onEditObjective?: () => void,
) => [
  {
    label: metricColumnLabel,
    accessor: 'metricName',
    align: ColumnAlign.LEFT,
    headerStyle: { minWidth: 200 },
    cellStyle: { minWidth: 200 },
  },
  {
    label: '',
    accessor: 'historical',
    align: ColumnAlign.RIGHT,
    cellRenderer: ({ historical, isPercentage, contributions, metricName }: PerformanceMetricRow) => (
      <Cell
        value={historical || undefined}
        isPercentage={isPercentage}
        contributions={contributions}
        metricName={metricName}
        portfolioName={portfolioName}
        portfolioId={portfolioId}
        relative={relative}
      />
    ),
  },
  {
    label: '',
    accessor: 'historicalDelta',
    align: ColumnAlign.LEFT,
    cellRenderer: ({ historical, historicalBase, isPercentage }: PerformanceMetricRow) => (
      <Delta base={historicalBase || undefined} value={historical || undefined} isPercentage={isPercentage} />
    ),
  },
  {
    label: '',
    accessor: 'forecast',
    align: ColumnAlign.RIGHT,
    cellStyle: { padding: 0 },
    cellRenderer: ({ forecast, isPercentage, contributions, metricName }: PerformanceMetricRow) => (
      <Cell
        value={forecast}
        isPercentage={isPercentage}
        contributions={contributions}
        metricName={metricName}
        portfolioName={portfolioName}
        portfolioId={portfolioId}
        onClick={onEditObjective}
        relative={relative}
        isForecast
      />
    ),
  },
  {
    label: '',
    accessor: 'forecastDelta',
    align: ColumnAlign.LEFT,
    cellRenderer: ({ forecast, forecastBase, isPercentage }: PerformanceMetricRow) => (
      <Delta base={forecastBase} value={forecast} isPercentage={isPercentage} />
    ),
  },
];

const getShimmer = (rows: number) => (
  <TableShimmer>
    {Array(rows)
      .fill(0)
      .map((_, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <TableShimmerRow key={idx} />
      ))}
  </TableShimmer>
);

const PortfolioPerformanceCardView = ({
  portfolioName = '',
  portfolioId = -1,
  solutionName,
  performanceData,
  riskData,
  onChangeObjective,
  loading,
  relative,
  onToggleRelative,
  canToggleRelative,
  relativeToggleTooltip,
  errors,
}: PortfolioPerformanceCardViewProps) => {
  const [performanceColumns, riskColumns] = useMemo(
    () => [
      getColumns('Performance (vs. Current)', portfolioName, portfolioId, relative, onChangeObjective),
      getColumns('Risk (vs. Current)', portfolioName, portfolioId, relative),
    ],
    [onChangeObjective, portfolioName, portfolioId, relative],
  );

  return (
    <ContentBlock
      header={
        <LeftRight>
          <div>
            {solutionName} Performance <Slim>(Vs.&nbsp;Current&nbsp;Portfolio)</Slim>
          </div>
          <RelativeToggle>
            <div>Relative to benchmark: </div>
            <Tooltip
              content={relativeToggleTooltip}
              isHidden={isNil(relativeToggleTooltip)}
              bodyDirection={TooltipBodyDirection.Left}
            >
              <SmallToggle toggled={relative} onToggle={onToggleRelative} disabled={!canToggleRelative} />
            </Tooltip>
          </RelativeToggle>
        </LeftRight>
      }
      subHeader="Click delta metrics to add or adjust constraints."
    >
      {!errors ? (
        <>
          <Table
            className="qa-perf-constraint-table"
            renderHead={() => (
              <Tr>
                <th />
                <SuperHeader colSpan={2}>
                  <div>
                    Historical
                    <TooltipLink
                      positions={{
                        top: -65,
                        left: -530,
                      }}
                      header="Historical Metrics"
                      text={
                        <>
                          These metrics represent a historical view on performance. They are calculated using historical
                          daily or monthly data over your selected analysis period.
                          <SubText>Hover over any forecast metric to view investment-level attribution.</SubText>
                        </>
                      }
                      href={METRIC_DEFINITIONS_HREF}
                    />
                  </div>
                </SuperHeader>
                <SuperHeader colSpan={2}>
                  <div>
                    Forecast
                    <TooltipLink
                      positions={{
                        top: -95,
                        left: -530,
                      }}
                      header="Forecast Metrics"
                      text={
                        <>
                          Forecast metrics represent a long-term, forward looking view on performance. They are
                          calculated using up to 3 years (minimum of 1 year) of daily or monthly data to estimate factor
                          betas and residual, which are then lined up with your long-term factor forecasts{' '}
                          <Icon type="compass" />.
                          <SubText>Hover over any forecast metric to view investment-level attribution.</SubText>
                        </>
                      }
                      href={METRIC_DEFINITIONS_HREF}
                    />
                  </div>
                </SuperHeader>
              </Tr>
            )}
            columns={performanceColumns}
            data={loading ? [] : performanceData}
            renderTail={
              !loading
                ? undefined
                : () => (
                    <ShimmerTr className="qa-shimmer">
                      <td colSpan={5}>{getShimmer(4)}</td>
                    </ShimmerTr>
                  )
            }
          />
          <Separator />
          <Table
            renderHead={() => (
              <Tr>
                <th />
                <SuperHeader colSpan={2} />
                <SuperHeader colSpan={2} />
              </Tr>
            )}
            columns={riskColumns}
            data={loading ? [] : riskData}
            renderTail={
              !loading
                ? undefined
                : () => (
                    <ShimmerTr>
                      <td colSpan={5}>{getShimmer(6)}</td>
                    </ShimmerTr>
                  )
            }
          />
        </>
      ) : (
        <ErrorContent errors={errors} />
      )}
    </ContentBlock>
  );
};

export default PortfolioPerformanceCardView;

const Slim = styled.span`
  font-weight: normal;
`;

const Tr = styled.tr`
  border: none;
`;

const Separator = styled.div`
  height: 1px;
  border-bottom: 1px solid ${GetColor.Grey};
  margin-bottom: 10px;
`;

const LeftRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const RelativeToggle = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: ${GetColor.DarkGrey};
  display: flex;
  align-items: center;
  margin-right: -15px;
  min-width: 185px;
  & > :first-child {
    min-width: 125px;
    margin-right: 4px;
  }
`;

const SubText = styled.div`
  padding: 20px 0 5px 0;
  color: ${GetColor.DarkGrey};
  font-style: italic;
`;
