import type { RemoteTypeEnum } from 'venn-api';

/**
 * Returns a string representing what the object was created by given an object with a remote type and datasource.
 * Used for tracking purposes.
 */
export const getCreatedByTrackingField = (item?: { remoteType?: RemoteTypeEnum; dataSource?: string }): string => {
  if (!item) {
    return '';
  }
  switch (item.remoteType) {
    case 'CUSTODIAN':
    case 'EXTERNAL':
      return item.dataSource ?? 'integration';
    case 'LOCAL':
      return item.dataSource ? 'returnsInbox' : 'userUploaded';
    case 'DATA':
      return 'vennProvided';
  }
  return '';
};
