import React, { useContext } from 'react';
import type { HeatMapTypes } from './Types';
import CanvasRow from './Map/CanvasRow';
import type { MouseEventType } from './Map/Cell';
import { ThemeContext } from 'styled-components';

interface MapRendererProps {
  series: HeatMapTypes.Root[];
  cellHeight: number;
  cellWidth: number;
  onCellMouseEnter: MouseEventType;
  onCellClick?: MouseEventType;
  leftOffset?: number;
  rightOffset?: number;
  isCellClickable?: boolean;
  print?: boolean;
  showValues?: boolean;
}

const CrossHatchPattern = ({
  id,
  size,
  color,
  backgroundColor,
  strokeWidth,
}: {
  id: string;
  size: number;
  color: string;
  backgroundColor: string;
  strokeWidth: number;
}) => (
  <pattern id={id} patternUnits="userSpaceOnUse" width={size} height={size}>
    <rect width="3" height="3" fill={backgroundColor} />
    <path
      d="M 0,3 l 3,-3 M -0.75,0.75 l 1.5,-1.5 M 2.25,3.75 l 1.5,-1.5"
      strokeWidth={strokeWidth}
      shapeRendering="auto"
      stroke={color}
      strokeLinecap="square"
    />
  </pattern>
);

const MapRenderer = ({
  series,
  cellHeight,
  cellWidth,
  onCellMouseEnter,
  leftOffset,
  print,
  showValues,
}: MapRendererProps) => {
  const { Colors } = useContext(ThemeContext);

  return (
    <g>
      <defs>
        <CrossHatchPattern
          id="trend-diagonal-hatch"
          size={3}
          strokeWidth={1}
          color={Colors.HintGrey}
          backgroundColor={Colors.White}
        />
      </defs>

      <rect
        x={leftOffset}
        width={cellWidth * series[0].series[0].data.length}
        height={cellHeight * series.length}
        fill={print ? Colors.HintGrey : 'url(#trend-diagonal-hatch)'}
      />

      <foreignObject
        x={leftOffset}
        y={0}
        height={cellHeight * series.length}
        width={cellWidth * series[0].series[0].data.length}
      >
        {series.map((serie, i) => (
          <CanvasRow
            onCellMouseEnter={onCellMouseEnter}
            key={serie.name}
            rowData={serie?.series?.[0]?.data}
            cellWidth={cellWidth}
            cellHeight={cellHeight}
            rowId={i}
            print={print}
            showValue={showValues}
          />
        ))}
      </foreignObject>
    </g>
  );
};

export default MapRenderer;
