import React from 'react';
import { FAStackWrapper, PrivateIcon, type PrivateIconProps } from './shared';
import { useColorTheme } from '../../themes/src/useColorTheme';
import { Icon } from '../icon/Icon';

export const PrivateInvestmentIcon = ({ color, className }: PrivateIconProps) => {
  const Colors = useColorTheme();

  return (
    <FAStackWrapper data-testid="qa-private-investment-icon" className={`fa-stack ${className ?? ''}`}>
      <Icon type="square" className="fa-stack-2x" style={{ color: color ?? Colors.colors.Gold }} />
      <PrivateIcon type="p" className="fa-stack-1x" />
    </FAStackWrapper>
  );
};
