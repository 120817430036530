import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import styled from 'styled-components';
import { type CashFlowSetting } from 'venn-api';
import { TableRowOptionsMenu } from 'venn-ui-kit';
import { Dates } from 'venn-utils';
import { useWorkspaceCashflowSettings } from '../../utils/cashflow-settings/queries';
import { Cell, StyledTable } from '../shared';
import CashflowSettingDeletionModal from './CashflowSettingDeletionModal';
import CashflowSettingFundsModal from './CashflowSettingFundsModal';

const getColumns = ({
  setSettingForFunds,
  setSettingForDeletion,
}: {
  setSettingForFunds: (setting: CashFlowSetting | undefined) => void;
  setSettingForDeletion: (setting: CashFlowSetting | undefined) => void;
}) => [
  {
    label: 'Setting Name',
    accessor: 'name',
    sortable: true,
    sortValueFunc: ({ name }: CashFlowSetting) => name.toLowerCase(),
    cellRenderer: ({ name }: CashFlowSetting) => <Cell>{name}</Cell>,
  },
  {
    label: 'Setting Type',
    accessor: 'overrideType',
    sortable: true,
    cellRenderer: ({ overrideType }: CashFlowSetting) => (
      <Cell>{overrideType === 'CLASSIFICATION' ? 'Classification override' : 'Parameter override'}</Cell>
    ),
  },
  {
    label: 'Owner',
    accessor: 'owner.name',
    sortable: true,
    sortValueFunc: ({ owner }: CashFlowSetting) => owner?.name.toLowerCase() ?? '',
    cellRenderer: ({ owner }: CashFlowSetting) => <Cell>{owner?.displayName}</Cell>,
  },
  {
    label: 'Last Updated',
    accessor: 'updated',
    sortable: true,
    cellRenderer: ({ updated }: CashFlowSetting) => <Cell>{Dates.toDayMonthYear(updated)}</Cell>,
  },
  {
    label: '',
    cellStyle: {
      width: 70,
      textAlign: 'right' as const,
    },
    cellRenderer: (setting: CashFlowSetting) => (
      <TableRowOptionsMenu
        usePortal
        portalProps={{
          topOffset: 20,
          rightOffset: 180,
        }}
        options={[
          {
            label: 'View funds using this setting',
            onClick: () => setSettingForFunds(setting),
          },
          {
            label: 'Delete',
            onClick: () => setSettingForDeletion(setting),
          },
        ]}
      />
    ),
  },
];

const CashflowSettingsTable = () => {
  const { data } = useWorkspaceCashflowSettings();
  const [settingForFunds, setSettingForFunds] = useState<CashFlowSetting>();
  const [settingForDeletion, setSettingForDeletion] = useState<CashFlowSetting>();

  return (
    <>
      <StyledTable
        data={data ?? []}
        columns={getColumns({
          setSettingForFunds,
          setSettingForDeletion,
        })}
        renderTail={() =>
          isEmpty(data) && (
            <tr>
              <td>
                <EmptyMessage>No settings available</EmptyMessage>
              </td>
            </tr>
          )
        }
      />
      {settingForFunds && (
        <CashflowSettingFundsModal setting={settingForFunds} onClose={() => setSettingForFunds(undefined)} />
      )}
      {settingForDeletion && (
        <CashflowSettingDeletionModal setting={settingForDeletion} onClose={() => setSettingForDeletion(undefined)} />
      )}
    </>
  );
};

const EmptyMessage = styled.span`
  font-style: italic;
`;

export default CashflowSettingsTable;
