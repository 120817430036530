import type { ReactNode } from 'react';
import React from 'react';
import styled from 'styled-components';
import type { DropMenuItem } from 'venn-ui-kit';
import { Caption, GetColor, Icon, Tooltip, TooltipPosition } from 'venn-ui-kit';
import {
  FIRST_VERSION_IN_GROUP_CLASSNAME,
  LAST_VERSION_IN_GROUP_CLASSNAME,
  ROW_HEIGHT,
  ITEM_CLASSNAME,
} from './versionItemUtils';

interface VersionItemProps<T> {
  item: DropMenuItem<T>;
  isSelected: boolean;
  renderRightChild?: (item: T) => ReactNode;
}

const VersionItem = <T,>({ item, isSelected, renderRightChild }: VersionItemProps<T>) => {
  const { label, icon, description, className } = item;
  return (
    <VersionItemContainer>
      <LineBlock className={className} />
      <TextBlock>
        <Item className={ITEM_CLASSNAME}>
          {label} {renderRightChild && renderRightChild(item.value)}
        </Item>
        {description && (
          <div>
            <SavedBy>
              {icon} {description}
            </SavedBy>
          </div>
        )}
      </TextBlock>
      <SelectionIndicatorBlock>
        {isSelected && (
          <Tooltip content="Currently selected version" position={TooltipPosition.Left}>
            <CheckIcon type="check-circle" />
          </Tooltip>
        )}
      </SelectionIndicatorBlock>
    </VersionItemContainer>
  );
};

export default VersionItem;

const LINE_PADDING = 8;

const VersionItemContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  & > div:first-child {
    padding-left: 19px;
  }
  & > .${FIRST_VERSION_IN_GROUP_CLASSNAME} {
    max-height: calc(100% - ${LINE_PADDING}px);
    margin-top: ${LINE_PADDING}px;
  }
  & > .${LAST_VERSION_IN_GROUP_CLASSNAME} {
    max-height: calc(100% - ${LINE_PADDING}px);
    margin-bottom: ${LINE_PADDING}px;
  }
  & > .${FIRST_VERSION_IN_GROUP_CLASSNAME}.${LAST_VERSION_IN_GROUP_CLASSNAME} {
    max-height: calc(100% - ${LINE_PADDING} * 2px);
    margin-top: ${LINE_PADDING}px;
    margin-bottom: ${LINE_PADDING}px;
  }
`;

const LineBlock = styled.div`
  border-left: 1px solid ${GetColor.Grey};
`;

const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  flex-grow: 1;
`;

const SavedBy = styled(Caption)`
  color: ${GetColor.DarkGrey};
`;

const SelectionIndicatorBlock = styled.div`
  right: 30px;
  height: ${ROW_HEIGHT}px;
  display: flex;
  align-items: center;
`;

const CheckIcon = styled(Icon)`
  color: ${GetColor.Primary.Dark};
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
