import React from 'react';

const CustomTextIcon = () => (
  <svg viewBox="0 0 55 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.77">
      <rect width="55" height="4.61532" fill="#B7793A" />
      <rect width="55" height="4.61532" stroke="#D3AE89" />
    </g>
    <g opacity="0.77">
      <rect y="7.6925" width="31.24" height="4.61532" fill="#B7793A" />
      <rect y="7.6925" width="31.24" height="4.61532" stroke="#D3AE89" />
    </g>
    <g opacity="0.77">
      <rect y="15.3846" width="19.36" height="4.61532" fill="#B7793A" />
      <rect y="15.3846" width="19.36" height="4.61532" stroke="#D3AE89" />
    </g>
  </svg>
);

export default CustomTextIcon;
