import React, { useContext } from 'react';
import type { PrivateBulkActionProps } from './types';
import { StyledButtonIcon } from './styles';
import { analyticsService, getBlankStudio, MAX_SUBJECT_GROUP_SIZE, navigateToReportLab } from 'venn-utils';
import { useHistory } from 'react-router-dom';
import { StudioPrintSettingsContext, UserContext } from 'venn-components';
import { noop } from 'lodash';

export const AddToReportLab = ({ items, onComplete }: PrivateBulkActionProps) => {
  const history = useHistory();
  const { addDisclosurePage } = useContext(StudioPrintSettingsContext);
  const { currentContext } = useContext(UserContext);

  const navigate = () => {
    const newDocument = getBlankStudio(
      'REPORT',
      addDisclosurePage,
      items.map((item) =>
        item.itemType === 'PORTFOLIO' ? { privatePortfolioId: item.id } : { privateFundId: item.id },
      ),
      currentContext,
    );

    analyticsService.creatingNewStudios({
      source: 'add to report lab library button',
      name: 'blank report',
    });
    navigateToReportLab(history, { newDocument });
    onComplete('Add to Report Lab', noop);
  };
  return (
    <StyledButtonIcon
      onClick={navigate}
      iconType="file-chart-column"
      iconFontSize={20}
      text={`Add to Report Lab (${items.length})`}
      className="qa-navigate-to-reportlab"
      disabled={items.length > MAX_SUBJECT_GROUP_SIZE}
      tooltip={
        items.length > MAX_SUBJECT_GROUP_SIZE ? `Please select ${MAX_SUBJECT_GROUP_SIZE} investments or less` : ''
      }
    />
  );
};
