import React from 'react';
import { Section } from '../../shared';
import SubjectsSection from './components/SubjectsSection';
import DateRangesSection from './components/DateRangesSection';
import BenchmarksSection from './components/BenchmarksSection';

const InputsPanel = () => {
  return (
    <>
      <SubjectsSection />
      <Section />
      <DateRangesSection />
      <Section />
      <BenchmarksSection />
      <Section noBorder />
    </>
  );
};

export default InputsPanel;
