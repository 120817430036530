import React from 'react';
import styled from 'styled-components';
import { ColorUtils, GetColor } from 'venn-ui-kit';

type PrivatesUpsellProps = Readonly<{
  className?: string;
  content?: React.ReactNode;
}>;

export const SensitivityChangesInfo = ({ className, content }: PrivatesUpsellProps) => {
  return (
    <Container className={className}>
      <div>
        <Badge>New</Badge>
      </div>
      <Title>Sensitivity Analysis</Title>
      <div>{content}</div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px;

  border-top: 1px solid ${({ theme }) => ColorUtils.opacify(theme.Colors.HighlightDark, 0.26)};
  border-bottom: 1px solid ${({ theme }) => ColorUtils.opacify(theme.Colors.HighlightDark, 0.26)};
  background: ${({ theme }) => ColorUtils.opacify(theme.Colors.HighlightDark, 0.07)};
`;

const Badge = styled.span`
  display: inline-block;
  padding: 0 4px;
  border-radius: 4px;
  background-color: ${GetColor.HighlightDark};
  color: ${GetColor.White};
  font-size: 10px;
  line-height: 1.5;
  font-weight: 700;
`;

const Title = styled.div`
  color: ${GetColor.HighlightDark};
  font-size: 14px;
  font-weight: 700;
  margin-top: 4px;
`;
