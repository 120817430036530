import React from 'react';
import styled from 'styled-components';
import type { ShimmerProps } from 'venn-ui-kit';
import { Shimmer, GetColor, ZIndex } from 'venn-ui-kit';

const Loading = () => (
  <LoadingWrapper className="qa-shimmer">
    <Block duration={2} />
    <Block duration={2} />
    <Block duration={2} />
  </LoadingWrapper>
);

const Block = styled.div<ShimmerProps>`
  height: 368px;
  margin-top: 20px;
  ${Shimmer};
`;

const LoadingWrapper = styled.div`
  top: 670px;
  background: ${GetColor.White};
  right: 0;
  left: 0;
  bottom: 0;
  z-index: ${ZIndex.Cover};
`;

export default Loading;
