import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import { Hint } from '../../typography';

const NoItem: FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <Item className="qa-dropmenu-no-item">
    <Hint>{children}</Hint>
  </Item>
);

const Item = styled.div`
  height: 37px;
  padding-left: 9px;
  padding-right: 9px;
  display: flex;
  align-items: center;
  cursor: cancel;
`;

export default NoItem;
