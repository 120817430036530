import { isNil, omitBy, pick } from 'lodash';
import React from 'react';
import { ObjectPrettyPrinter } from './ObjectPrettyPrinter';

export const BrowserInfo = () => {
  const browserInfo = useBrowserInfo();

  return (
    <>
      <h3>Browser Info</h3>
      <ObjectPrettyPrinter object={browserInfo} />
    </>
  );
};

const useBrowserInfo = (): Record<string, unknown> => {
  return omitBy(pick(navigator, ['userAgent', 'userAgentData']), isNil);
};
