import type { FactorSummaryChartRow } from '../factorSummaryTypes';
import type { Scale } from './types';

export function getChartScale(factors: FactorSummaryChartRow[], defaultExtreme: number): Scale {
  const extreme = getExtremes(factors, defaultExtreme);
  return getScale(factors, extreme);
}

function getExtremes(factors: FactorSummaryChartRow[], defaultExtreme: number) {
  const extremes = factors
    .map((factor) => factor.bar.x + factor.bar.value)
    .reduce(
      ({ min, max }, value) => ({
        min: Math.min(min, value),
        max: Math.max(max, value),
      }),
      { min: Infinity, max: -Infinity },
    );

  return getMaxValue(defaultExtreme, Math.abs(extremes.min), Math.abs(extremes.max));
}

function getMaxValue(...values: number[]) {
  return Math.max(...values.filter(Number.isFinite));
}

const EPSILON = 0.000000001;
function getScale(factors: FactorSummaryChartRow[], extreme: number) {
  const size = extreme * 2;
  const order = Math.floor(Math.log(size) / Math.LN10 + EPSILON);
  const modifier = 10 ** order;
  const tickCount = size / modifier;
  let step = modifier;
  if (tickCount < 2) {
    step /= 4;
  } else if (tickCount < 6) {
    step /= 2;
  } else if (tickCount > 9) {
    step *= 2;
  }

  const ceiledExtreme = Math.ceil(extreme / step) * step;
  return {
    min: -ceiledExtreme,
    max: ceiledExtreme,
    step,
  };
}
