import React from 'react';
import { useRecoilValue } from 'recoil';
import { blockSettings, holdingsCategories } from 'venn-state';
import type { ErrorWrapper } from './errorWrapperTypes';
import { StyledErrorState } from '../StyledErrorState';
import { useDebugValue } from 'venn-utils';

export const AssetExposureErrorWrapper: ErrorWrapper = ({ blockId, children }) => {
  const blockSetting = useRecoilValue(blockSettings(blockId));
  const categories = useRecoilValue(holdingsCategories(blockId));

  const forceError = useDebugValue('debugBlockErrorState') === 'AssetExposure';

  if (forceError || (blockSetting.customBlockType === 'ASSET_EXPOSURE' && categories.length === 0)) {
    return (
      <StyledErrorState
        selectedRefId={blockId}
        header="Select at least one asset class"
        message="Configure assets in the block settings panel."
      />
    );
  }

  return <>{children}</>;
};
