import type { CurrencyEnum, MappingAndErrors } from 'venn-api';

export const mockMapAndErrors: MappingAndErrors = {
  mapping: {
    fileId: 28841,
    name: 'blob',
    columns: [
      {
        seriesId: 'ff91c098-edf5-46e6-9928-500b704e165e',
        name: 'Swf',
        origin: { rowIndex: 0, colIndex: 1 },
        fundId: undefined!,
        portfolioNodeId: undefined!,
        fundName: 'Swf',
        isPercent: false,
        strategyName: undefined!,
        currency: 'USD' as CurrencyEnum,
        periodReturn: 1.4406757459049873,
        periodAnnualized: true,
        annualizedReturn: 1.4406757459049873,
        annualizedVolatility: 0.08207252711310442,
        cumulativeReturn: [
          [1391212799999, 0.0],
          [1393631999999, 0.12531328320802015],
          [1396310399999, 0.2506265664160403],
          [1398902399999, 0.37593984962406046],
          [1401580799999, 0.5012531328320804],
          [1404172799999, 0.6265664160401003],
          [1406851199999, 0.7518796992481205],
          [1409529599999, 0.8771929824561404],
          [1412121599999, 1.0025062656641603],
          [1414799999999, 1.1278195488721803],
          [1417391999999, 1.2531328320802002],
          [1420070399999, 1.3784461152882201],
          [1422748799999, 1.50375939849624],
          [1425167999999, 1.62907268170426],
        ],
        recentMonthlyReturns: undefined!,
        startOfNewData: 1391212799999,
        newDataCount: 13,
        startOfNeededForFactorAnalysis: 1330559999999,
        needsBackfill: true,
        newNav: undefined!,
        oldNav: undefined!,
        typeId: 3,
        frequencyId: 2,
        metricId: 2,
        appendId: undefined!,
        dateFormatId: 1,
        isUploadPercent: false,
      },
    ],
  },
  errors: [],
  hasInvalidOrMissingDates: false,
};
