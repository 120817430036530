import React from 'react';
import styled from 'styled-components';
import { GetColor, Icon } from 'venn-ui-kit';

const REQUIRED_ERROR_MESSAGE = 'Field is required';

interface ValidationErrorProps {
  message?: string;
}

const ValidationError = ({ message = REQUIRED_ERROR_MESSAGE }: ValidationErrorProps) => {
  return (
    <Container>
      <ErrorIcon type="triangle-exclamation" />
      <ErrorText>{message}</ErrorText>
    </Container>
  );
};

export default ValidationError;

const Container = styled.span`
  white-space: nowrap;
`;

const ErrorIcon = styled(Icon)`
  color: ${GetColor.HighlightDark};
  margin-right: 5px;
`;

const ErrorText = styled.span`
  color: ${GetColor.Black};
`;
