import React, { useRef } from 'react';
import PageHeader from './print-layout/PageHeader';
import {
  studioPrintPages,
  studioContentHeight,
  studioPageHeight,
  studioFooterHeight,
  DEFAULT_MARGIN,
} from 'venn-state';
import PageFooter from './print-layout/PageFooter';
import Block from './block/Block';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import styled, { css } from 'styled-components';
import { assertNotNil } from 'venn-utils';

/** Handles print layout, including all pages and blocks, for Studio only. */
const StudioPrintContent = ({ width }: { width: number }) => {
  const focusBlockIndex = useRef<number>();
  const focusBlockRef = useRef<HTMLDivElement>(null);
  const printPages = useRecoilValue(studioPrintPages);
  const contentHeight = useRecoilValue(studioContentHeight);
  const pageHeight = useRecoilValue(studioPageHeight);
  const setStudioFooterHeight = useSetRecoilState(studioFooterHeight);

  return (
    <div>
      {printPages?.map((page, index) => (
        <React.Fragment key={page.startIndex}>
          <PrintPage
            isLastPage={printPages.length - 1 === index}
            style={{
              height: pageHeight,
            }}
          >
            <PageHeader />
            <ContentPage
              key={page.startIndex}
              style={{
                height: contentHeight,
              }}
            >
              {page.views.map((view, index) => (
                <Block
                  id={assertNotNil(view.refId)}
                  key={view.id ?? view.refId}
                  index={page.startIndex + index}
                  focusBlockIndex={focusBlockIndex}
                  focusBlockRef={focusBlockRef}
                  width={width}
                />
              ))}
            </ContentPage>
            <PageFooter
              currentPage={index + 1}
              totalPage={printPages.length}
              setStudioFooterHeight={index === 0 ? setStudioFooterHeight : undefined}
              reportLabPage={undefined}
            />
          </PrintPage>
        </React.Fragment>
      ))}
    </div>
  );
};

export default StudioPrintContent;

const PrintPage = styled.div<{ isLastPage: boolean }>`
  position: relative;

  @media print {
    page-break-inside: avoid;
    ${(props) =>
      !props.isLastPage &&
      css`
        page-break-after: always;
      `}
  }
`;

const ContentPage = styled.div`
  margin: 0 ${DEFAULT_MARGIN}px;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  align-content: flex-start;
  column-gap: ${DEFAULT_MARGIN}px;

  /* Setting contain strict prevents wide blocks from harming page layout. */
  contain: strict;
`;
