import type { FC } from 'react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import type { TableWorkspace } from 'venn-api';
import { GetColor } from 'venn-ui-kit';

interface UserSwitchOrgProps {
  organizationId: number;
  options: TableWorkspace[];
}

const UserSwitchOrg: FC<React.PropsWithChildren<UserSwitchOrgProps>> = ({ organizationId, options }) => {
  const history = useHistory();

  const switchOrg = (orgId: number) => {
    history.replace('/home#switch-workspace', { orgId });
  };

  return (
    <SwitchOptions>
      {options.map((option) => (
        <Button active={option.id === organizationId} key={option.id} onClick={() => switchOrg(option.id)}>
          {option.name}
        </Button>
      ))}
    </SwitchOptions>
  );
};

export default UserSwitchOrg;

const SwitchOptions = styled.div`
  color: ${GetColor.Black};
  padding: 0 0 20px;
  button {
    padding-left: 20px;
    display: block;
  }
`;

const Button = styled.button<{ active: boolean }>`
  padding: 0 20px;
  color: ${GetColor.Black};
  :hover,
  :focus {
    background: ${GetColor.PaleGrey};
  }
  ${(props) =>
    props.active &&
    css`
      background: ${GetColor.PaleGrey};
      pointer-events: none;
    `}
  text-align: left;
  line-height: 1.71;
  width: 100%;
`;
