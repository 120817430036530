import type * as Highcharts from 'highcharts';
import { compact, isNil, zip } from 'lodash';
import capitalize from 'lodash/capitalize';
import type { FrequencyEnum, Portfolio, ProxyTypeEnum, VenncastReturn } from 'venn-api';
import type { AnalysisSubjectType } from '../analysis';
import Dates from '../dates';
import { calculateComparison, getFlattenedNodesWithCompareAndLevel, normalizePortfolio } from '../portfolio';
import type { ExcelCell, ExcelCellValue } from './types';

export function formatDate(date: number) {
  if (!date) {
    return '';
  }
  const d = new Date(date);
  return `${d.getUTCMonth() + 1}/${d.getUTCDate()}/${d.getUTCFullYear()}`;
}

export function formatExportValue(value: ExcelCellValue, isPercentage: boolean, isCurrency?: boolean): ExcelCell {
  return {
    value: isNil(value) ? '--' : value,
    percentage: isPercentage,
    currency: isCurrency,
    digits: 2,
  };
}

export const convertReturnDataToExcel = (
  chartConfig: Highcharts.Options,
  relative: boolean,
): ExcelCell[][] | undefined => {
  // We do not support charts with multiple x-axis
  if (!chartConfig || Array.isArray(chartConfig.xAxis)) {
    return undefined;
  }
  const relativeHeaderTitle = relative ? 'Relative Return Count' : 'Return Count';
  const header: ExcelCell[] = [{ value: 'Returns Distribution', bold: true }];

  chartConfig.series?.forEach((serie) => {
    header.push({ value: `${serie.name} ${relativeHeaderTitle}`, bold: true });
  });

  const excelSheet: ExcelCell[][] = [header];
  chartConfig.xAxis?.categories?.forEach((bucket: string, index: number) => {
    if (index !== 0) {
      const excelRow: ExcelCell[] = [{ value: bucket }];

      chartConfig.series?.forEach((serie: Highcharts.SeriesOptionsType) => {
        const value = 'data' in serie ? serie.data?.[index] : undefined;
        excelRow.push({ value: typeof value === 'number' ? value : value?.toString() });
      });

      excelSheet.push(excelRow);
    }
  });
  return excelSheet;
};

export const DISABLE_ANALYSIS_EXPORT_MESSAGE = 'Analyses that contain third-party returns data cannot be exported';

export interface Series {
  name: string;
  series: number[][];
}

export const convertSeriesToExcel = (investment?: string, series?: number[][]) => {
  if (!series) {
    return null;
  }
  const header = ['Date', investment ?? ''];

  const excelSheet = [header, ...series.map((entry) => [formatDate(entry[0]), entry[1]?.toString() ?? ''])];

  return excelSheet;
};

export const convertSeriesDataToExcel = (investment?: string, series?: Series) => {
  return convertSeriesToExcel(investment, series?.series);
};

interface TimeMapper {
  [key: number]: ExcelCell[];
}

export const convertMultipleSeriesDataToExcel = (
  series?: Series[],
  enablePercentage?: boolean,
  currencySymbol?: string,
  dateFormattingFrequency?: FrequencyEnum,
): ExcelCell[][] => {
  if (!series || series.length === 0) {
    return [];
  }
  const header = [{ value: 'Date', bold: true }, ...series.map((fund) => ({ value: fund.name, bold: true }))];

  const timeToFundIndexToCell: TimeMapper = {};
  series.forEach((fund, index) => {
    fund.series.forEach((item) => {
      const timeStamp = item[0];
      if (!timeToFundIndexToCell[timeStamp]) {
        timeToFundIndexToCell[timeStamp] = [];
      }

      timeToFundIndexToCell[timeStamp][index] = {
        currencySymbol,
        value: item[1],
        percentage: enablePercentage,
        digits: 2,
      };
    });
  });

  const excelSheet = [
    header,
    ...Object.keys(timeToFundIndexToCell)
      .sort((timeA, timeB) => Number(timeA) - Number(timeB))
      .map((time) => [
        {
          value: dateFormattingFrequency
            ? Dates.toDDMMMYYYY(Number(time), dateFormattingFrequency)
            : formatDate(Number(time)),
        },
        ...timeToFundIndexToCell[time],
      ]),
  ];
  return excelSheet;
};

export const getSafeWorkSheetName = (workSheetName: string): string => {
  if (!workSheetName) {
    return 'sheet';
  }
  // The sheet name can't contain \, /, *, [, ], :, and ?
  const safeWorkSheetName = workSheetName.replace(/[\\/*\[\]:?]/g, '');

  // Sheet name should be unique and less than 31 character
  // When the sheet's index is larger than 0, adding index prefix to avoid dupliate sheet name
  return safeWorkSheetName.length > 28 ? `${safeWorkSheetName.slice(0, 26)}..` : safeWorkSheetName;
};

export const getPortfolioNavs = (
  portfolio: Portfolio,
  secondary?: Portfolio,
  secondaryLabel?: string,
  isPercentage?: boolean,
): ExcelCell[][] => {
  const normalizedStrategy = normalizePortfolio(portfolio);
  const normalizedSecondary = isNil(secondary) ? undefined : normalizePortfolio(secondary);
  const [allCompareNodes, allGhostChildren] = calculateComparison(normalizedStrategy, normalizedSecondary);

  const nodes = getFlattenedNodesWithCompareAndLevel(
    normalizedStrategy,
    normalizedSecondary,
    allCompareNodes,
    allGhostChildren,
  );

  const headers = compact([
    { value: 'Investment', bold: true },
    { value: 'Allocation', bold: true },
    isNil(secondary) ? null : { value: `${secondaryLabel} Allocation`, bold: true },
  ]);
  const nameCells: ExcelCell[] = nodes.map(([n, c]) => ({
    value: n?.name ?? c?.name,
    bold: isNil(n?.fund ?? c?.fund),
    digits: 2,
    style: {
      indent: (n?.level ?? c?.level ?? 0) * 2,
      italic: isNil(n) && !isNil(c),
    },
  }));
  const rootAllocation = nodes[0][0]?.allocation;
  const allocationCells: ExcelCell[] = nodes.map(([n, c]) => ({
    value: isPercentage && rootAllocation && n?.allocation ? n.allocation / rootAllocation : n?.allocation,
    bold: isNil(n?.fund ?? c?.fund),
    currency: !isPercentage,
    percentage: isPercentage,
  }));
  const compareRootAllocation = nodes[0][1]?.allocation;
  const compareAllocationCells: ExcelCell[] = nodes.map(([n, c]) => ({
    value:
      isPercentage && compareRootAllocation && c?.allocation ? c.allocation / compareRootAllocation : c?.allocation,
    bold: isNil(n?.fund ?? c?.fund),
    currency: !isPercentage,
    percentage: isPercentage,
  }));
  return [headers, ...(zip<ExcelCell>(nameCells, allocationCells, compareAllocationCells) as ExcelCell[][])];
};

export const formatAnalysisPeriod = (startDate?: number, endDate?: number, frequency?: FrequencyEnum) =>
  startDate && endDate ? `${Dates.toDDMMMYYYY(startDate, frequency)} - ${Dates.toDDMMMYYYY(endDate, frequency)}` : '--';

export const formatDownloadMessage = (title: string, data?: string): MetaData => {
  const prefix = title ? `${title}: ` : '';
  return [prefix, `${data || 'None'}`];
};

export const getRawMetadataLabel = (formattedLabel: string): string => {
  // undoes the formatting done by formatDownloadMessage
  // using split to support both the presence and the absence of ": "
  return formattedLabel.split(/: /)[0];
};

export type MetaData = [string, string]; // [formatted label, value]

export interface GeneralMetaData {
  /** Portoflio or investment name */
  subjectName: string;
  type?: AnalysisSubjectType;
  benchmarkName?: string;
  relative?: boolean;
  proxyName?: string;
  proxyType?: ProxyTypeEnum;
  interpolationName?: string;
  startTime?: number;
  endTime?: number;
  frequency?: FrequencyEnum;
  secondaryName?: string;
  extrapolate?: boolean;
  /** Other extra meta data to include */
}

const getProxyType = (metaData: GeneralMetaData) => {
  const fullProxyName =
    metaData.extrapolate && metaData.proxyType !== 'EXTRAPOLATE'
      ? capitalize(`${metaData.proxyType}_extrapolate`)
      : capitalize(metaData.proxyType);
  return metaData.proxyName ? formatDownloadMessage('Proxy Type', fullProxyName) : undefined;
};

export const formatGeneralDownloadMetaData = (metaData: GeneralMetaData): MetaData[] => {
  const isInvestment = metaData.type === 'investment';
  const subjectName = formatDownloadMessage('Subject', metaData.subjectName);
  const benchmarkName = formatDownloadMessage('Benchmark', metaData.benchmarkName);
  const relativeToBenchmark = formatDownloadMessage('Relative to Benchmark', metaData.relative ? 'On' : 'Off');
  const proxyName = isInvestment ? formatDownloadMessage('Proxy', metaData.proxyName) : undefined;
  const proxyType = getProxyType(metaData);
  const secondaryName = metaData.secondaryName
    ? formatDownloadMessage('Compare to', metaData.secondaryName)
    : undefined;

  const period = formatDownloadMessage(
    'Analysis Period',
    formatAnalysisPeriod(metaData.startTime, metaData.endTime, metaData.frequency),
  );
  const frequencyValue = metaData.frequency
    ? formatDownloadMessage('Frequency', capitalize(metaData.frequency))
    : undefined;
  return compact([
    subjectName,
    benchmarkName,
    relativeToBenchmark,
    proxyName,
    proxyType,
    secondaryName,
    period,
    frequencyValue,
  ]);
};

/** Returns undefined if Venncast is undefined, otherwise returns the excel data for the Venncast. */
export function getCumulativeVenncastExportData(
  name: string,
  venncast: VenncastReturn,
  showVenncast?: boolean,
): ExcelCell[][];
export function getCumulativeVenncastExportData(name: string, venncast?: undefined, showVenncast?: boolean): undefined;
export function getCumulativeVenncastExportData(
  name: string,
  venncast?: VenncastReturn,
  showVenncast?: boolean,
): ExcelCell[][] | undefined;
export function getCumulativeVenncastExportData(
  name: string,
  venncast?: VenncastReturn,
  showVenncast?: boolean,
): ExcelCell[][] | undefined {
  if (!venncast) {
    return undefined;
  }

  const seriesData: Series[] = [];
  if (venncast.cumulativeReturn) {
    seriesData.push({
      name,
      series: venncast.cumulativeReturn,
    });
  }

  if (venncast.estimated && showVenncast) {
    seriesData.push({
      name: 'Estimated',
      series: venncast.estimatedCumulativeReturns,
    });

    seriesData.push({
      name: 'Error Range (+/-)',
      series: venncast.estimateError,
    });
  }

  return convertMultipleSeriesDataToExcel(seriesData, true);
}
