import { useState, useCallback } from 'react';

import { userActivity } from 'venn-utils';

import { RECENT_SEARCH_MAX_ITEMS } from '../constants';

export const useRecentSearches = () => {
  const [recentSearches, setRecentSearches] = useState<string[]>(() => {
    const storageParams = userActivity.getLocalStorageItemPerUser(userActivity.USER_REPORTS_PAGE_RECENT_QUERIES);
    const queries = storageParams ? JSON.parse(storageParams) : [];

    return queries;
  });

  const saveRecentSearch = useCallback(
    (query: string | undefined) => {
      if (!query || !query.trim()) {
        return;
      }
      const storageParams = userActivity.getLocalStorageItemPerUser(userActivity.USER_REPORTS_PAGE_RECENT_QUERIES);
      let queries: string[] = storageParams ? JSON.parse(storageParams) : [];
      queries = queries.filter((q) => q && query.toLocaleLowerCase() !== q.toLocaleLowerCase());
      queries.unshift(query);
      queries = queries.slice(0, RECENT_SEARCH_MAX_ITEMS);
      setRecentSearches(queries);
      userActivity.setLocalStorageItemPerUser(userActivity.USER_REPORTS_PAGE_RECENT_QUERIES, JSON.stringify(queries));
    },
    [setRecentSearches],
  );

  return { recentSearches, saveRecentSearch };
};
