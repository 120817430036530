import { blockDisplayHeader, blockSettings } from 'venn-state';
import { useRecoilValue } from 'recoil';
import { IS_JEST_TEST, isPrivatesBlock, isPublicPrivateAssetGrowthBlock } from 'venn-utils';
import {
  BlockIdContext,
  type DataModificationsCategory,
  DataModificationsContent,
  useAnalysisBlockDataModifications,
  usePrivateAnalysisBlockDataModifications,
} from 'venn-components';
import React from 'react';
import styled, { css } from 'styled-components';
import { isEmpty } from 'lodash';

type BlockDataModificationsProps = {
  blockId: string;
  title: string;
  modifications?: DataModificationsCategory[];
};

const BlockDataModificationsDisclosureInternal = ({ title, modifications }: BlockDataModificationsProps) => {
  if (!modifications || isEmpty(modifications)) {
    return null;
  }

  return (
    <>
      <DataModificationsText>Contains Venn-Modified Data for block: '{title}'</DataModificationsText>
      <DataModificationsContent isReportDisclosure modifications={modifications} />
    </>
  );
};

/** Wrapper component for calling the data fetching hook */
const PrivateAnalysisBlockDataModificationsDisclosure = ({ blockId, title }: BlockDataModificationsProps) => {
  const { modifications } = usePrivateAnalysisBlockDataModifications(blockId);
  return <BlockDataModificationsDisclosureInternal blockId={blockId} title={title} modifications={modifications} />;
};

/** Wrapper component for calling the data fetching hook */
const AnalysisBlockDataModificationsDisclosure = ({ blockId, title }: BlockDataModificationsProps) => {
  const { modifications } = useAnalysisBlockDataModifications(blockId);
  return <BlockDataModificationsDisclosureInternal blockId={blockId} title={title} modifications={modifications} />;
};

type BlockDataModificationsDisclosureProps = {
  blockId: string;
};

const BlockDataModificationsDisclosure = ({ blockId }: BlockDataModificationsDisclosureProps) => {
  const { customBlockType } = useRecoilValue(blockSettings(blockId));
  const title = useRecoilValue(blockDisplayHeader(blockId));
  if (isPrivatesBlock(customBlockType)) {
    return <PrivateAnalysisBlockDataModificationsDisclosure title={title} blockId={blockId} />;
  }
  if (isPublicPrivateAssetGrowthBlock(customBlockType)) {
    return <AnalysisBlockDataModificationsDisclosure title={title} blockId={blockId} />;
  } // other blocks cannot have privates data modifications
  return null;
};

type BlocksDataModificationsDisclosureProps = {
  fontSize: number;
  blockIds: string[];
};

export const BlocksDataModificationsDisclosure = ({ fontSize, blockIds }: BlocksDataModificationsDisclosureProps) => {
  return (
    <AllBlocksDataModificationsDisclosureWrapper fontSize={fontSize}>
      {blockIds.map((blockId) => (
        <BlockIdContext.Provider value={blockId} key={blockId}>
          <BlockDataModificationsDisclosure blockId={blockId} />
        </BlockIdContext.Provider>
      ))}
    </AllBlocksDataModificationsDisclosureWrapper>
  );
};

const DataModificationsText = styled.span`
  font-weight: bold;
`;

const AllBlocksDataModificationsDisclosureWrapper = styled.div<{ fontSize: number }>`
  font-size: ${({ fontSize }) => fontSize}pt;
  display: flex;
  flex-direction: column;
  gap: ${({ fontSize }) => fontSize * 0.5}pt;
  padding: 1em 0;

  ${() =>
    // Our version of jest does not support the :has pseudo-class
    IS_JEST_TEST
      ? null
      : css`
          &:not(:has(${DataModificationsText})) {
            display: none;
          }
        `}
`;
