import type { Theme } from 'venn-ui-kit';
import { ColorUtils, REPORT_LAB_FONT_BODY } from 'venn-ui-kit';
import type * as Highcharts from 'highcharts';

/** Returns fairly static chart options that generally won't change between rerenders. */
export const getDefaultChartConfig = (theme: Theme, print: boolean): Highcharts.Options => {
  const {
    Colors,
    Schemes: { BarChartColors },
  } = theme;
  return {
    rangeSelector: {
      enabled: false,
    },
    exporting: {
      enabled: true,
      fallbackToExportServer: true,
    },
    navigator: {
      enabled: false,
    },
    scrollbar: {
      enabled: false,
    },
    title: undefined,
    chart: {
      type: 'column',
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      gridLineWidth: 1,
      labels: {
        style: {
          color: Colors.MidGrey2,
          fontSize: print ? REPORT_LAB_FONT_BODY : '14px',
        },
      },
      lineColor: BarChartColors.lineColor,
      tickColor: BarChartColors.lineColor,
      lineWidth: 1,
      endOnTick: true,
      startOnTick: true,
    },
    yAxis: {
      gridLineWidth: 1,
      gridLineColor: BarChartColors.lineColor,
      startOnTick: true,
      endOnTick: true,
      showLastLabel: true,
      title: {
        text: null,
      },
      tickWidth: 0,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      backgroundColor: ColorUtils.hex2rgba(Colors.Black, 0.9),
      borderRadius: 10,
      borderWidth: 0,
      style: {
        color: Colors.White,
      },
      useHTML: true,
      formatter(this: Highcharts.TooltipFormatterContextObject) {
        const { color, series, x, y } = this;
        return `<div>${x}</div>
      <span style="color:${color}">\u25CF</span> ${series.name}: <b>${y}</b>`;
      },
    },
  };
};
