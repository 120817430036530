import React, { useMemo } from 'react';
import { ThemeProvider } from 'styled-components';
import type { Schemes, Theme } from 'venn-ui-kit';
import { getCurrentTheme, getImageTheme, getTypographyTheme, getVideoTheme, useColorTheme } from '../themes';

const ShellThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const sessionStorage = window.sessionStorage;
  if (
    sessionStorage.getItem('theme') === null &&
    (document.cookie.includes('allowbeta=true') || process.env.USE_GREEN === 'true')
  ) {
    sessionStorage.setItem('theme', 'green');
  }

  // if we cared about live changes we can subscribe or use the useSessionStorage hook from https://usehooks-ts.com/react-hook/use-session-storage
  const sessionTheme = getCurrentTheme();
  const typography = getTypographyTheme(sessionTheme);

  const { colors, customColors, setCustomColors, setDebugColors } = useColorTheme();

  const images = useMemo(() => getImageTheme(sessionTheme), [sessionTheme]);
  const videos = useMemo(() => getVideoTheme(sessionTheme), [sessionTheme]);
  const schemes = useMemo(
    (): Schemes => ({
      FactorColors: {
        mainColor: colors.DivergingColor.B3,
        comparisonColor: colors.DivergingColor.A3,
        labelColor: colors.DarkGrey,
      },
      BarChartColors: {
        mainColor: colors.StudioSubjectColor.A1,
        secondaryColor: colors.StudioSubjectColor.A2,
        comparisonColor: colors.StudioSubjectColor.A3,
        lineColor: colors.PaleGrey,
      },
      LineChartColors: {
        mainColor: colors.StudioSubjectColor.A1,
        secondaryColor: colors.StudioSubjectColor.A2,
        comparisonColor: colors.StudioSubjectColor.A3,
        lineColor: colors.PaleGrey,
      },
      Proxy: {
        proxyLine: colors.DEPRECATED_DataLineColor.PaleGold,
        darkProxyLine: colors.DEPRECATED_DataLineColor.Gold,
        extrapolation: colors.DEPRECATED_DataLineColor.PaleBlue,
        subjectLine: colors.Black,
        invertedSubjectLine: colors.White,
        badgeText: colors.White,
        badgeBg: colors.PaleGold,
      },
    }),
    [colors],
  );

  const theme: Theme = useMemo(
    () => ({
      Images: images,
      Typography: typography,
      Videos: videos,
      Colors: colors,
      Schemes: schemes,
      customColors,
      setCustomColors,
      setDebugColors,
      inPrintMode: false,
      inPrintModeOrReportLab: false,
    }),
    [images, typography, videos, colors, schemes, customColors, setCustomColors, setDebugColors],
  );

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default ShellThemeProvider;
