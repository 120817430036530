import React from 'react';
import type { ViewPort } from './types';

export interface FactorChartSvgProps {
  viewPort: ViewPort;
  footerHeight: number;
  chartHeight: number;
}

const FactorChartSvg: React.FC<React.PropsWithChildren<FactorChartSvgProps>> = ({
  children,
  viewPort,
  footerHeight,
  chartHeight,
}) => (
  <svg
    width={viewPort.width}
    height={chartHeight + footerHeight}
    xmlns="http://www.w3.org/2000/svg"
    shapeRendering="geometricPrecision"
  >
    {children}
  </svg>
);
export default FactorChartSvg;
