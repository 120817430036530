import React from 'react';
import styled from 'styled-components';
import EmptyState from '../../../empty-state/EmptyState';
import { GetColor, Link as LinkStyle } from 'venn-ui-kit';
import type { ConstrainingInvestment } from '../PortfolioRangesUtils';
import { getFormattedPeriod } from '../PortfolioRangesUtils';
import { Dates, logMessageToSentry, itemHasNoReturns, getAnalysisPathForInvestment } from 'venn-utils';
import type { InvestmentRange } from 'venn-api';
import moment from 'moment';
import { isNil } from 'lodash';

export const StyledEmptyState = styled(EmptyState)`
  margin-top: 35px;
`;

export const EmptyStateContent = styled.div`
  color: ${GetColor.HintGrey};
  max-width: 700px;
`;

export const ScreenOnly = styled.div`
  @media print {
    display: none;
  }
`;

export const ConstraintList = styled.ul`
  margin: 20px 0;
  text-align: left;
  color: ${GetColor.Black};
`;

export const FundListItem = styled.li`
  color: ${GetColor.Black};
  list-style-position: inside;
  line-height: 1.3em;
  > ul {
    margin-top: 0;
  }
`;

const FundNameNoLink = styled.span`
  font-weight: bold;
  color: ${GetColor.Black};
  cursor: default;
`;

const FundNameLink = styled.a`
  font-weight: bold;
  color: ${GetColor.Primary.Dark};
  cursor: pointer;
`;

export const FundName: React.FC<React.PropsWithChildren<{ investment: ConstrainingInvestment }>> = ({ investment }) => {
  const { range, type } = investment;
  if (range.id) {
    return (
      <FundNameLink href={getAnalysisPathForInvestment(range.id)} target="_blank">
        {range.name}
        {type && <i>{` (${type})`}</i>}
      </FundNameLink>
    );
  }
  return (
    <FundNameNoLink>
      {range.name}
      {type && <i>{` (${type})`}</i>}
    </FundNameNoLink>
  );
};

export const ToggleLink = styled.li`
  cursor: pointer;
  color: ${GetColor.MidGrey1};
  padding-top: 7px;
  list-style: none;
  i {
    color: ${GetColor.Primary.Dark};
    margin-right: 10px;
  }
`;

export const ActionFooter = styled.div`
  margin-top: 12px;
`;

export const Frequency = styled.span`
  font-weight: bold;
  font-style: italic;
  text-transform: lowercase;
`;

export const DefaultText = styled.span`
  color: ${GetColor.MidGrey1};
`;

export const FloatRight = styled.div`
  float: right;
`;

interface ListItemProps {
  name: string;
  label?: string;
  range?: InvestmentRange;
  suffix?: string;
  availableStart?: number;
  availableEnd?: number;
}

export const ListItemWithFrequency: React.FC<React.PropsWithChildren<ListItemProps>> = ({
  name,
  label,
  range,
  suffix,
  children,
}) => (
  <FundListItem>
    <strong>
      {name} {label && <i>({label})</i>}
    </strong>{' '}
    <DefaultText>
      – <Frequency>{range && range.frequency}</Frequency> frequency{suffix}
    </DefaultText>
    {children}
  </FundListItem>
);

/** Renders a given returns range as dates alongside an input name+label.
 * If the input range is empty, the returns range is not printed and instead "has no returns" is displayed.
 * */
export const ListItemWithReturns: React.FC<React.PropsWithChildren<ListItemProps>> = ({
  name,
  label,
  range,
  suffix,
  children,
}) => (
  <FundListItem>
    <strong>
      {name} {label && <i>({label})</i>}
    </strong>{' '}
    <DefaultText>
      has{' '}
      {range && !itemHasNoReturns(range.start, range.end) ? (
        <>
          <strong>
            <i>{getFormattedPeriod(range.frequency, range.start, range.end)}</i>
          </strong>{' '}
          of returns ({Dates.toDDMMMYYYY(moment.utc(range.start).valueOf(), range.frequency)} -{' '}
          {Dates.toDDMMMYYYY(moment.utc(range.end).valueOf(), range.frequency)})
        </>
      ) : (
        <strong>no returns</strong>
      )}
      {suffix}
    </DefaultText>
    {children}
  </FundListItem>
);

export const ListItemConstraining: React.FC<React.PropsWithChildren<ListItemProps>> = ({
  name,
  label,
  range,
  availableStart,
  availableEnd,
  suffix,
  children,
}) => {
  if (isNil(availableStart) || isNil(availableEnd) || isNil(range)) {
    logMessageToSentry('Failed to render FundListItem because of missing range.');
    return null;
  }
  return (
    <FundListItem>
      <strong>
        {name} {label && <i>({label})</i>}
      </strong>{' '}
      – ({Dates.toDDMMMYYYY(moment.utc(range.start).valueOf(), range.frequency)} -{' '}
      {Dates.toDDMMMYYYY(moment.utc(range.end).valueOf(), range.frequency)}){' '}
      <DefaultText>
        {range.end < availableStart ? (
          <>
            <strong>ends before</strong> some other items start
          </>
        ) : range.start > availableEnd ? (
          <>
            <strong>starts after</strong> some other items end
          </>
        ) : range.start === availableStart && range.end === availableEnd ? (
          <>
            is constraining your analysis <strong>start</strong> and <strong>end</strong>
          </>
        ) : range.start === availableStart ? (
          <>
            is constraining your analysis <strong>start</strong>
          </>
        ) : (
          <>
            is constraining your analysis <strong>end</strong>
          </>
        )}
        {suffix}
      </DefaultText>
      {children}
    </FundListItem>
  );
};

export const QuickAction: React.FC<React.PropsWithChildren<{ onClick: () => void }>> = ({ children, onClick }) => (
  <LinkStyle>
    <button type="button" onClick={onClick}>
      <strong>
        <i>{children}</i>
      </strong>
    </button>
  </LinkStyle>
);
