import React, { useCallback, useContext, useMemo } from 'react';
import type { DropMenuItem } from 'venn-ui-kit';
import { DropMenu } from 'venn-ui-kit';
import { getContextMenuItems, UserContext } from 'venn-components';

const qaClass = 'qa-context-switch';

const ContextSwitcher = () => {
  const { currentContext, profileSettings, setCurrentContext } = useContext(UserContext);

  const items: DropMenuItem<string>[] = useMemo(
    () => getContextMenuItems(profileSettings?.availableContexts),
    [profileSettings?.availableContexts],
  );

  const onChange = useCallback(
    (item: DropMenuItem<string>) => {
      setCurrentContext(item.value);
    },
    [setCurrentContext],
  );

  const getSelectionLabel = useCallback((selected: DropMenuItem<string>) => `Ownership: ${selected.label}`, []);

  return (
    <DropMenu
      getSelectionLabel={getSelectionLabel}
      darkPlaceholder
      searchable
      selected={currentContext}
      items={items}
      onChange={onChange}
      className={qaClass}
    />
  );
};

export default ContextSwitcher;
