import type { FC } from 'react';
import React from 'react';
import styled, { css } from 'styled-components';
import ReactMarkdown from 'react-markdown';
import NewTabLinkRenderer from './NewTabLinkRenderer';
import { GetColor } from 'venn-ui-kit';

export type NodeType = ReactMarkdown.NodeType;

export interface MarkdownProps extends Omit<ReactMarkdown.ReactMarkdownProps, 'skipHtml' | 'renderers'> {
  source: string;
  noMargin?: boolean;
}

const baseRenderers = {
  // Links SHOULD be opened in new tabs in order to keep users on the site.
  // See https://venndesk.atlassian.net/browse/VENN-1976
  link: NewTabLinkRenderer,
};

const StyledReactMarkdown = styled(ReactMarkdown)<{ noMargin?: boolean }>`
  code {
    font-size: 1em;
    white-space: pre-wrap;
  }
  hr {
    border: 1px solid ${GetColor.Grey};
  }
  ${({ noMargin }) =>
    noMargin &&
    css`
      p {
        margin-block-start: unset;
        margin-block-end: unset;
      }
    `}
`;

export const Markdown: FC<React.PropsWithChildren<MarkdownProps>> = (props) => (
  <StyledReactMarkdown {...props} renderers={baseRenderers} skipHtml />
);

export default Markdown;
