import React, { useContext } from 'react';
import styled from 'styled-components';
import { buttonize, formatAllocation } from 'venn-utils';
import { GetColor, Icon, Tooltip } from 'venn-ui-kit';
import { PortfolioLabContext } from 'venn-components';
import { isNil } from 'lodash';

type TradeType = 'PURCHASE' | 'SELL';

interface SuggestedTradeProps {
  tradeType: TradeType;
  value: number;
  originalValue: number | undefined;
  largeText?: boolean;
}

const SuggestedTrade = ({ tradeType, value, originalValue, largeText }: SuggestedTradeProps) => {
  const { onShowAllocationSection } = useContext(PortfolioLabContext);
  const tradeValue = tradeType === 'PURCHASE' ? Math.abs(value) : -Math.abs(value);

  return (
    <Tooltip
      content={
        <TooltipContent className="qa-tooltip">
          <div>
            <strong>{tradeType === 'PURCHASE' ? 'Increase allocation' : 'Decrease allocation'}</strong>
          </div>
          <LeftRight>
            <div>Current Value</div>
            <div>
              <strong>{isNil(originalValue) ? '--' : formatAllocation(Math.abs(originalValue), false)}</strong>
            </div>
          </LeftRight>
          <LeftRight>
            <div>Suggested Value</div>
            <div>
              <strong className="qa-suggested-value">
                {formatAllocation(Math.abs((originalValue ?? 0) + tradeValue), false)}
              </strong>
            </div>
          </LeftRight>
          <ActionFootnote>
            <i>Can't make this trade? Click to add an allocation constraint.</i>
          </ActionFootnote>
        </TooltipContent>
      }
    >
      <StyledTrade type={tradeType} largeText={largeText} {...buttonize(onShowAllocationSection)}>
        <Icon type={tradeType === 'PURCHASE' ? 'arrow-up' : 'arrow-down'} />
        {formatAllocation(Math.abs(value), false)}
      </StyledTrade>
    </Tooltip>
  );
};

export default SuggestedTrade;

const StyledTrade = styled.div<{ type: TradeType; largeText?: boolean }>`
  color: ${({ type }) => (type === 'PURCHASE' ? GetColor.Optimization.Positive : GetColor.Optimization.Negative)};
  font-size: ${({ largeText }) => (largeText ? 14 : 12)}px;
  & > i {
    margin-right: 4px;
  }
  &:hover {
    cursor: pointer;
  }
`;

const TooltipContent = styled.div`
  min-width: 150px;
  max-width: 236px;
  padding: 5px;
  text-align: left;
  line-height: 1.5;
`;

const LeftRight = styled.div`
  display: flex;
  justify-content: space-between;
  & > :last-child {
    text-align: right;
  }
`;

const ActionFootnote = styled.div`
  margin-top: 5px;
  font-size: 12px;
`;
