import styled, { css } from 'styled-components';
import { Select } from 'venn-ui-kit';

const searchBlockStyle = (prefix = 'select') => css`
  .${prefix}__menu {
    position: relative;
    box-shadow: none;

    > div {
      box-shadow: none;
    }

    .${prefix}__menu-list {
      padding: 20px 0 0 0;
      overflow: visible;
    }
  }
`;

export const StyledSelect = styled(Select)<{ displayResultsAsBlock?: boolean; maxHeightCss?: string }>`
  .select__input {
    font-family: ${(props) => props.theme.Typography.fontFamily};
    width: 100%;

    input {
      /* Override react-select default input auto width */
      width: 100% !important;
    }
  }
  .select__menu-list {
    ${({ maxHeightCss }) => (maxHeightCss ? `max-height: ${maxHeightCss}` : '')};
  }

  ${(props) => props.displayResultsAsBlock && searchBlockStyle()}
`;
