/*
 * Venn Platform API 1.0.0 Operations for "tags"
 * Automatically generated file - do not modify!
 */

import type { TaggedEntities } from '../types';
import type { OperationResult } from '../../utils';
import { buildRequestInit, extractError, retryGeneratedFetch } from '../../utils';

/** Add a tag to the given set of funds and portfolios */
export async function addTagV2(
  tag: string,
  body?: Partial<TaggedEntities>,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = `/api/tags/${encodeURIComponent(tag)}`;
  const requestInit = buildRequestInit({
    method: 'PUT',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await retryGeneratedFetch('PUT', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Admin gets a list of all tags a user has */
export async function getAllTagsAdmin(signal?: AbortSignal): Promise<OperationResult<string[]>> {
  const endpoint = '/api/tags/admin';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: string[] = (await response.json()) as string[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get the tags for the given fund */
export async function getFundTags(id: string, signal?: AbortSignal): Promise<OperationResult<string[]>> {
  const endpoint = `/api/tags/fund/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: string[] = (await response.json()) as string[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get the tags for the given portfolio */
export async function getPortfolioTags(id: number, signal?: AbortSignal): Promise<OperationResult<string[]>> {
  const endpoint = `/api/tags/portfolio/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: string[] = (await response.json()) as string[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Gets a list of all tags a user has */
export async function getAllTags(signal?: AbortSignal): Promise<OperationResult<string[]>> {
  const endpoint = '/api/tags';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: string[] = (await response.json()) as string[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Remove a tag from the given set of funds and portfolios */
export async function removeTagV2(
  tag: string,
  body?: Partial<TaggedEntities>,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = `/api/tags/${encodeURIComponent(tag)}/delete`;
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Remove all tags for the given tagged entities */
export async function removeTagsFromEntities(
  body?: Partial<TaggedEntities>,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = '/api/tags/all';
  const requestInit = buildRequestInit({
    method: 'DELETE',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await retryGeneratedFetch('DELETE', () => fetch(endpoint, requestInit));
  if ((response.status >= 200 && response.status < 400) || response.status === 404) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
