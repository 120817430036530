import { compact } from 'lodash';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { FilterPill, getAppTitle, Icon } from 'venn-ui-kit';
import { LibraryItemType, QuickFilter, useHasFF } from 'venn-utils';
import type { SearchFilter } from '../types';
import { COLLAPSE_ANIMATION_SIZE, LeftCollapseWrapper } from './shared';
import type { PrivateAssetSearchModeEnum } from 'venn-api';

interface QuickFilterBarProps {
  privateAssetSearchMode?: PrivateAssetSearchModeEnum;
  selectedFilters: SearchFilter[];
  onSelectFilter: (selected: SearchFilter) => void;
  investmentsOnly?: boolean;
  portfoliosOnly?: boolean;
  smallScreen?: boolean;
  canSearchEverything?: boolean;
  includeTags?: boolean;
  includeBenchmarks?: boolean;
}

type FilterMap = { [key: string]: SearchFilter };

export const typeFilters: FilterMap = {
  DATA_LIBRARY: { name: 'Data Library' },
  VIEWS: { name: 'Saved Views' },
  HELP_ARTICLE: { name: 'Help Articles' },
};

export const viewFilters: FilterMap = {
  VENN_PROVIDED: { name: `${getAppTitle()} Template` },
  SAVED_BY_ME: { name: 'My Saved Views' },
};

export const searchFilters: FilterMap = {
  PUBLIC_RETURNS_DATA: { name: 'Returns Data', privateAssetSearchMode: 'PUBLIC_ONLY' },
  PRIVATE_ASSET_DATA: { name: 'Private Asset Data', privateAssetSearchMode: 'PRIVATES_ONLY' },
  UPLOADED_BY_ME: { name: 'Uploaded by Me', quickFilter: QuickFilter.UPLOADED_BY_ME },
  UPLOADED_BY_TEAM: { name: 'Uploaded by My Team', quickFilter: QuickFilter.UPLOADED_BY_ANYONE },
  INVESTMENTS: { name: 'Investments', itemType: LibraryItemType.INVESTMENT },
  PORTFOLIOS: { name: 'Portfolios', itemType: LibraryItemType.PORTFOLIO },
  BENCHMARKS: { name: 'Benchmarks', itemType: LibraryItemType.COMPOSITE_BENCHMARK },
  TAGS: { name: 'Tags', itemType: LibraryItemType.TAGS },
};

export const isSavedViews = (selectedFilters: SearchFilter[]) =>
  selectedFilters.some((item) => [typeFilters.VIEWS, ...Object.values(viewFilters)].includes(item));
export const isHelpArticles = (selectedFilters: SearchFilter[]) => selectedFilters.includes(typeFilters.HELP_ARTICLE);

const QuickFilterBar = ({
  privateAssetSearchMode,
  selectedFilters,
  onSelectFilter,
  investmentsOnly,
  portfoliosOnly,
  smallScreen,
  canSearchEverything,
  includeTags = true,
  includeBenchmarks = true,
}: QuickFilterBarProps) => {
  const hasPrivatesFF = useHasFF('private_analytics');
  const [hideMore, setHideMore] = useState<boolean>(!!smallScreen);
  const filters = useMemo(() => {
    let options = compact([
      hasPrivatesFF && privateAssetSearchMode === 'ALL' ? searchFilters.PUBLIC_RETURNS_DATA : undefined,
      hasPrivatesFF && privateAssetSearchMode === 'ALL' ? searchFilters.PRIVATE_ASSET_DATA : undefined,
      searchFilters.UPLOADED_BY_TEAM,
      searchFilters.UPLOADED_BY_ME,
      !portfoliosOnly && !hideMore ? searchFilters.INVESTMENTS : undefined,
      !investmentsOnly && !hideMore ? searchFilters.PORTFOLIOS : undefined,
      !!includeBenchmarks && !portfoliosOnly && !hideMore ? searchFilters.BENCHMARKS : undefined,
      !!includeTags && !hideMore ? searchFilters.TAGS : undefined,
    ]);

    if (canSearchEverything) {
      if (selectedFilters.length === 0) {
        options = Object.values(typeFilters);
      } else if (isSavedViews(selectedFilters)) {
        options = Object.values(viewFilters);
      } else if (isHelpArticles(selectedFilters)) {
        options = [];
      }
    }
    return options.map((filter) => ({ selected: selectedFilters?.includes(filter), filter }));
  }, [
    hasPrivatesFF,
    portfoliosOnly,
    hideMore,
    investmentsOnly,
    includeBenchmarks,
    includeTags,
    canSearchEverything,
    selectedFilters,
    privateAssetSearchMode,
  ]);

  return (
    <Wrapper>
      Filter search by...
      <Filters>
        {filters.map(({ selected, filter }) => (
          <LeftCollapseWrapper collapsed={selected} key={filter.name}>
            {!selected && <FilterPill name={filter.name} onClick={() => onSelectFilter(filter)} />}
          </LeftCollapseWrapper>
        ))}
        {smallScreen && (
          <LeftCollapseWrapper collapsed={!hideMore}>
            <FilterPill name={<Icon type="ellipsis-h" />} onClick={() => setHideMore(false)} />
          </LeftCollapseWrapper>
        )}
      </Filters>
    </Wrapper>
  );
};

export default QuickFilterBar;

const Wrapper = styled.div`
  margin-bottom: 20px;
  font-size: 13px;
  font-weight: bold;
`;

const Filters = styled.div`
  display: flex;
  margin-top: 5px;
  flex-wrap: wrap;
  padding-left: ${COLLAPSE_ANIMATION_SIZE}px;
`;
