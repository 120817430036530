import React, { useContext, useEffect, useRef, useState } from 'react';
import { AuthValidators } from '../components';
import CreatePasswordForm from './CreatePasswordFormV3';
import CreatePasswordFailure from './CreatePasswordFailure';
import type { AccountCreationRequest, AuthenticationStatus, SignupUser, Country } from 'venn-api';
import { getCreatePasswordParams } from '../queryParamParsers';
import { analyticsService, Routes } from 'venn-utils';
import { createField, UserContext } from 'venn-components';
import type { History } from 'history';
import type { DropMenuItem } from 'venn-ui-kit';

export const CreatePasswordRouteV3 = '/v3/activate';

const { isInitialPasswordValid, required } = AuthValidators;

interface CreatePasswordProps {
  pending?: boolean;
  onSubmit: (user: Partial<SignupUser> | Partial<AccountCreationRequest>) => Promise<AuthenticationStatus> | void;
  history: History;
  onVerificationCodeCheck: (user: AccountCreationRequest) => Promise<AuthenticationStatus>;
}

const CreatePassword = ({ history, pending, onSubmit, onVerificationCodeCheck }: CreatePasswordProps) => {
  const userContext = useContext(UserContext);
  const pageLoaded = useRef(false);
  const [submitted, setSubmitted] = useState(false);
  const onFormSubmit = async (values: Partial<AccountCreationRequest>) => {
    setSubmitted(true);
    await onSubmit({
      ...values,
      legalAgreements: ['subscriber-agreement', 'user-agreement'],
    });
  };

  const { email, activationCode, organization, firstName, lastName, sponsorKey } = getCreatePasswordParams();
  const redirectToSignIn = !email || !activationCode;
  useEffect(() => {
    if (pageLoaded.current || pending) {
      return;
    }
    if (redirectToSignIn) {
      history.replace(Routes.SIGN_IN_PATH);
      return;
    }

    const checkVerificationCode = async (
      onVerificationCodeCheckAPI: (user: AccountCreationRequest) => Promise<AuthenticationStatus>,
    ) => {
      await onVerificationCodeCheckAPI({
        email,
        activationCode,
        legalAgreements: [],
        password: '',
        aumRangeId: '',
        companyType: '',
        countryCode: '',
        locality: '',
      });
    };
    checkVerificationCode(onVerificationCodeCheck);

    analyticsService.signupStepViewed({
      stepDescription: 'password creation',
    });
    pageLoaded.current = true;
  }, [onVerificationCodeCheck, history, pending, redirectToSignIn, email, activationCode]);

  if (redirectToSignIn) {
    return null;
  }

  if (userContext.error) {
    const error = submitted ? 'Unable to activate account.' : 'Invalid activation link.';
    return <CreatePasswordFailure title={error} />;
  }

  const fields = {
    email: createField('email', email, required()),
    firstName: createField('firstName', firstName, required()),
    lastName: createField('lastName', lastName, required()),
    company: createField('company', organization, required()),
    activationCode: createField('activationCode', activationCode, required()),
    sponsorKey: createField('sponsorKey', sponsorKey),
    country: createField<Country | undefined>(
      'country',
      undefined,
      required('Country', (country) => country?.code),
      true,
    ),
    password: createField('password', '', isInitialPasswordValid, true, true),
    state: createField<DropMenuItem<string> | undefined>('state', undefined, required('State'), true),
    aum: createField<DropMenuItem<string> | undefined>('aum', undefined, required('AUM'), true),
    type: createField<DropMenuItem<string> | undefined>('type', undefined, required('Organization type'), true),
    // TODO(VENN-20115): remove eslint-disable if it is safe to do so
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare
    termsCheckbox: createField('termsCheckbox', false, (value) => (value === true ? '' : 'error'), true),
  };

  return <CreatePasswordForm pending={!!pending} fields={fields} onSubmit={onFormSubmit} />;
};

const areEqual = (prevProps: CreatePasswordProps, nextProps: CreatePasswordProps) =>
  prevProps.pending === nextProps.pending;

export default React.memo(CreatePassword, areEqual);
