import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  ConfirmationModal,
  DoubleObjectiveSelector,
  DoubleObjectiveSelectorLoader,
  ForecastsMenuLauncher,
  PortfolioLabContext,
  StickyNode,
  SubjectSelector,
  UserContext,
} from 'venn-components';
import {
  ButtonIcon,
  ColorUtils,
  GetColor,
  Headline2,
  Icon,
  PrintAnalysisTemplate,
  PrintSubjectLabel,
  ZIndex,
} from 'venn-ui-kit';
import type { ObjectiveType } from 'venn-utils';
import {
  AnalysisSubject,
  analyticsService,
  MANAGE_DATA_SECTION,
  navigateToManageDataPage,
  Numbers,
  SpecialCssClasses,
} from 'venn-utils';
import { isNil } from 'lodash';
import type { FactorForecast, Fund, Portfolio, PortfolioSummary } from 'venn-api';
import { useHistory } from 'react-router-dom';
import BenchmarkEditorButton from './BenchmarkEditorButton';
import OpportunitiesSelector from './OpportunitiesSelector';
import OpportunitiesSelectorLoader from './OpportunitiesSelectorLoader';
import type { MenuOpenerProps } from '../../../../venn-components/src/modals/forecasts/ForecastsMenuLauncher';

const COLLAPSIBLE_HEADER_HEIGHT = 106;
export const OBJECTIVE_HEADER_HEIGHT = 42;
export const HEADER_HEIGHT = COLLAPSIBLE_HEADER_HEIGHT + OBJECTIVE_HEADER_HEIGHT;

const ICON_BUTTON_INNER_HEIGHT = 38;

const ForecastsMenuButton = ({ onClick }: MenuOpenerProps) => (
  <ButtonIcon
    iconType="compass"
    onClick={onClick}
    tooltip="Edit forecasts"
    className="qa-forecast-link"
    size={ICON_BUTTON_INNER_HEIGHT}
  />
);

export interface LabHeaderProps {
  benchmarkSummaryError: boolean;
  constraintInputFocused: boolean;
  setConstraintInputFocused: (focused: boolean) => void;
  newFunds?: Fund[];
  newFundsParentStrategyId?: number;
  onUpdateNewOpportunities: (funds: Fund[], strategyId: number) => void;
  hideAllButtons?: boolean;
  summary?: PortfolioSummary;
  summaryLoading: boolean;
  summaryError: boolean;
  historicalReturn?: number;
  historicalVolatility?: number;
  factorForecasts: FactorForecast[];
  onForecastUpdated: () => void;
}

const LabHeader = ({
  constraintInputFocused,
  setConstraintInputFocused,
  newFunds,
  newFundsParentStrategyId,
  onUpdateNewOpportunities,
  benchmarkSummaryError,
  hideAllButtons,
  ...usePortfolioSummaryProps
}: LabHeaderProps) => {
  const stickyNode = useRef<HTMLDivElement>(null);
  const opportunityNode = useRef<HTMLDivElement>(null);
  const { hasPermission } = useContext(UserContext);
  const [nextPortfolio, setNextPortfolio] = useState<Portfolio>();
  const {
    portfolio,
    onSelectPortfolioId,
    onForecastUpdated,
    objective,
    objectiveConstraintValue,
    onChangeObjective,
    benchmarkSubject,
    onChangeBenchmark,
  } = useContext(PortfolioLabContext);
  const portfolioSubject = useMemo(
    () => (isNil(portfolio) ? undefined : new AnalysisSubject(portfolio, 'portfolio')),
    [portfolio],
  );
  const onAttemptToChangeSubject = useCallback(
    (subject: AnalysisSubject) => {
      if (isNil(subject.portfolio)) {
        return;
      }

      /** Initial Empty State */
      if (isNil(portfolio)) {
        onSelectPortfolioId(subject.portfolio.id);
        return;
      }

      if (portfolio.id === subject.portfolio.id) {
        return;
      }

      setNextPortfolio(subject.portfolio);
    },
    [portfolio, onSelectPortfolioId],
  );

  const onChangeSubject = useCallback(() => {
    if (!nextPortfolio) {
      return;
    }
    onSelectPortfolioId(nextPortfolio.id);
    setNextPortfolio(undefined);
  }, [nextPortfolio, onSelectPortfolioId]);

  const { summary, summaryLoading, historicalReturn, historicalVolatility } = usePortfolioSummaryProps;
  const defaultMaxVolatility = rounded(summary?.annualizedVolatility ?? historicalVolatility);
  const defaultMinReturn = rounded(summary?.annualizedTotalReturn ?? historicalReturn);

  const history = useHistory();
  const openManageDataPage = () => {
    if (isNil(portfolio)) {
      return;
    }
    navigateToManageDataPage(
      history,
      { portfolioId: portfolio.id },
      'Portfolio Lab',
      false,
      MANAGE_DATA_SECTION.PORTFOLIO_POLICY,
    );
    analyticsService.ctaClicked({
      destination: 'Manage Data',
      locationOnPage: 'Portfolio Lab header',
      subjectId: portfolio.id.toString(),
      purpose: 'Manage portfolio policy',
      type: 'button',
    });
  };

  return (
    <>
      <CollapsibleHeader>
        <StyledHeadline>
          <SubjectSelector
            selectedSubject={portfolioSubject}
            onChangeSubject={onAttemptToChangeSubject}
            portfoliosOnly
            placeholder="Select a portfolio"
            analyticsProps={{
              location: 'portfolioLabSubjectSelector',
              destinationPageTitle: 'Portfolio Lab',
            }}
          />
        </StyledHeadline>
        <HeaderButtons hidden={hideAllButtons}>
          <ButtonGroup className={SpecialCssClasses.NotDownloadable}>
            <BenchmarkEditorButton
              benchmarkSubject={benchmarkSubject}
              benchmarkSummaryError={benchmarkSummaryError}
              onChangeBenchmark={(benchmark) => {
                onChangeBenchmark(benchmark);
                analyticsService.portfolioLabConstraintsModified({
                  originPage: 'Portfolio Lab',
                  locationOnPage: 'benchmark selector',
                  constraintType: 'benchmark',
                  benchmarkId: isNil(benchmark?.id) ? undefined : benchmark.id.toString(),
                  portfolioId: portfolio?.id,
                });
              }}
              iconSize={ICON_BUTTON_INNER_HEIGHT}
            />
            <ForecastsMenuLauncher
              iconOnly
              onDefaultForecastUpdated={onForecastUpdated}
              onResidualForecastUpdated={onForecastUpdated}
              portfolio={portfolioSubject?.portfolio}
              fund={portfolioSubject?.fund}
              ctaPurpose="Edit forecasts from Portfolio Lab header"
              renderCustomMenuOpener={(props) => <ForecastsMenuButton {...props} />}
              isReadOnly={!hasPermission('EDIT_FORECASTS')}
            />
          </ButtonGroup>

          <PrintElement>
            <PrintSubjectLabel>Benchmark:</PrintSubjectLabel> {benchmarkSubject?.name ?? '--'}
          </PrintElement>

          <ManageDataButtonWrapper className={SpecialCssClasses.NotDownloadable}>
            <ButtonIcon
              className="qa-manage-data-button"
              iconType="cog"
              onClick={openManageDataPage}
              tooltip="Manage data"
              disabled={isNil(portfolio)}
              border
              size={ICON_BUTTON_INNER_HEIGHT + 2}
            />
          </ManageDataButtonWrapper>
        </HeaderButtons>
      </CollapsibleHeader>
      <PrintElement>
        <PrintAnalysisTemplate>{formatObjectiveForPrint(objective, objectiveConstraintValue)}</PrintAnalysisTemplate>
      </PrintElement>
      <StickyHeader reference={stickyNode}>
        <PermanentHeader>
          <HeaderSectionLabel>
            <Icon type="function" />
          </HeaderSectionLabel>
          {!isNil(portfolio) && (
            <>
              <HeaderSectionContent className="qa-objective-selector">
                {summaryLoading ? (
                  <DoubleObjectiveSelectorLoader />
                ) : (
                  <DoubleObjectiveSelector
                    selectedObjective={objective}
                    selectedConstraintValue={objectiveConstraintValue}
                    onSelectObjective={(objective, value) => {
                      onChangeObjective(objective, value);
                      analyticsService.portfolioLabConstraintsModified({
                        originPage: 'Portfolio Lab',
                        locationOnPage: 'objective selector',
                        constraintType: 'target',
                        target: objective,
                        volatilityConstraint: objective === 'maximizeReturns' ? value : undefined,
                        returnConstraint: objective === 'maximizeReturns' ? undefined : value,
                        portfolioId: portfolio.id,
                      });
                    }}
                    defaultMaxVolatility={isNil(defaultMaxVolatility) ? undefined : defaultMaxVolatility * 100}
                    defaultMinReturn={isNil(defaultMinReturn) ? undefined : defaultMinReturn * 100}
                    constraintInputFocused={constraintInputFocused}
                    setConstraintInputFocused={setConstraintInputFocused}
                  />
                )}
              </HeaderSectionContent>
              <HeaderSectionLabel>Opportunity Bench:</HeaderSectionLabel>
              <HeaderSectionContent ref={opportunityNode} className="qa-opportunity-bench">
                {isNil(portfolio) ? (
                  <OpportunitiesSelectorLoader />
                ) : (
                  <OpportunitiesSelector
                    parentRef={opportunityNode}
                    portfolio={portfolio}
                    funds={newFunds}
                    parentStrategyId={newFundsParentStrategyId}
                    onUpdateNewOpportunities={(funds, strategyId, isInitializing) => {
                      onUpdateNewOpportunities(funds, strategyId);
                      if (!isInitializing) {
                        analyticsService.portfolioLabConstraintsModified({
                          originPage: 'Portfolio Lab',
                          locationOnPage: 'new opportunities selector',
                          constraintType: 'new opportunities',
                          newOpportunities: funds.map(({ id }) => id),
                          portfolioId: portfolio.id,
                          parentStrategyId: strategyId,
                        });
                      }
                    }}
                  />
                )}
              </HeaderSectionContent>
            </>
          )}
        </PermanentHeader>
      </StickyHeader>
      {nextPortfolio && (
        <ConfirmationModal
          header="Switching portfolios will reset your constraints."
          subhead="Would you like to continue?"
          text=""
          onCancel={() => setNextPortfolio(undefined)}
          onProceed={() => onChangeSubject()}
          proceedLabel="Continue"
        />
      )}
    </>
  );
};

export default LabHeader;

const StickyHeader = styled(StickyNode)`
  box-shadow: 0 20px 20px -20px ${ColorUtils.hex2rgbaFrom(GetColor.Black, 0.4)};
  background: ${GetColor.White};
  z-index: ${ZIndex.StickyFront};
  && {
    margin: 0;
  }

  @media print {
    display: none;
  }
`;

const CollapsibleHeader = styled.div`
  height: ${COLLAPSIBLE_HEADER_HEIGHT}px;
  border-bottom: 1px solid ${GetColor.Grey};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  @media print {
    border-bottom: none;
    padding: 20px 0px;
  }
`;

const PermanentHeader = styled.div`
  height: ${OBJECTIVE_HEADER_HEIGHT}px;
  border-bottom: 1px solid ${GetColor.Grey};
  position: relative;
  display: flex;
`;

const HeaderSectionLabel = styled.div`
  min-width: 40px;
  height: 100%;
  background-color: ${GetColor.WhiteGrey};
  border-right: 1px solid ${GetColor.Grey};
  padding-left: 10px;
  padding-right: 10px;
  font-weight: bold;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonGroup = styled.div`
  border: 1px solid ${GetColor.Primary.Dark};
  border-radius: 2px;
  display: flex;
  height: ${ICON_BUTTON_INNER_HEIGHT + 2}px;
  > div:first-child {
    margin-left: 0;
  }
  button {
    margin-right: 0;
  }
  .button-icon-wrapper {
    border-radius: 0;
  }
  & > :first-child {
    .button-icon-wrapper {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
  & > :last-child {
    .button-icon-wrapper {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
  position: relative;

  @media print {
    display: none;
  }
`;

const HeaderSectionContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  border-right: 1px solid ${GetColor.Grey};
`;

const HeaderButtons = styled.div<{ hidden?: boolean }>`
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
`;

const ManageDataButtonWrapper = styled.div`
  margin-left: 10px;
  span[role='button'] {
    margin-right: 0;
    border-radius: 2px;
  }
  @media print {
    display: none;
  }
`;

const PrintElement = styled.div`
  display: none;
  @media print {
    display: block;
  }
`;

const StyledHeadline = styled(Headline2)`
  display: inline-block;
  overflow: hidden;
  max-width: 100%;
`;

export const formatObjectiveForPrint = (objective?: ObjectiveType, value?: number) => {
  switch (objective) {
    case 'maximizeReturns':
      return `Maximize returns with volatility less than ${Numbers.safeFormatNumber(value, 2)}%`;
    case 'targetReturn':
      return `Target return of ${Numbers.safeFormatNumber(value, 2)}% with minimum volatility`;
    case 'maximizeSharpe':
      return `Maximize sharpe with a return greater than ${Numbers.safeFormatNumber(value, 2)}%`;
    default:
      return '';
  }
};

const rounded = (value: number | undefined): number | undefined =>
  isNil(value) ? undefined : Math.round(value * 10000000) / 10000000;
