import type { ComponentProps, ReactNode } from 'react';
import React from 'react';
import type { ErrorWrapper } from './errorWrapperTypes';
import { uniq } from 'lodash';

/**
 * Wraps the provided child or children (presumably a Block or Blocks) with layers of error wrappers.
 *
 * The wrappers are processed such that lower indices (such as 0) are higher-level parents of the later indices.
 * In the case of [FooWrapper, BarWrapper] FooWrapper will wrap BarWrapper which will wrap the provided children.
 * If an error wrapper does not render its children, then the rendering ceases at that step and no further wrappers and no children will be rendered.
 *
 * As a result, it is recommended to put simple, fast, and general-case wrappers first.
 *
 * Duplicate wrappers are removed so that it is safe to compose wrappers using subarrays like so: [DateRangeWrapper, ...FooWrappers, ...BarWrappers]
 * where Foowrappers might be [DateRangeWrapper, FooWrapper] and BarWrapper might be [DateRangeWrapper, BarWrapper]. The end result will just be
 * [DateRangeWrapper, FooWrapper, BarWrapper].
 *
 * See the unit tests for more examples of usage.
 */
export const ErrorMultiWrapper = ({
  wrappers,
  children,
  ...wrapperProps
}: {
  wrappers: ErrorWrapper[];
  children: ReactNode;
} & ComponentProps<ErrorWrapper>) => {
  const uniqWrappers = uniq(wrappers);
  return (
    <>
      {uniqWrappers.reduceRight(
        (child, Wrapper) => (
          <Wrapper {...wrapperProps}>{child}</Wrapper>
        ),
        children,
      )}
    </>
  );
};
