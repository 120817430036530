import React from 'react';
import { Button, Icon, ZIndex, GetColor, Tooltip, TooltipPosition } from 'venn-ui-kit';
import styled from 'styled-components';

interface BackToCahpterProps {
  backToChapterName?: string;
  onBackToChapter?: () => void;
}

const BackToCahpter = ({ backToChapterName, onBackToChapter }: BackToCahpterProps) => {
  if (!backToChapterName || !onBackToChapter) {
    return null;
  }
  return (
    <Container>
      <ContainerInner>
        <Tooltip content={`Back to ${backToChapterName}`} position={TooltipPosition.Bottom} usePortal>
          <StyledButton noMargin dense onClick={onBackToChapter}>
            <StyledMenuIcon type="backward" />
          </StyledButton>
        </Tooltip>
      </ContainerInner>
    </Container>
  );
};

export default BackToCahpter;

const Container = styled.div`
  position: absolute;
  z-index: ${ZIndex.StickyCover};
`;

const StyledMenuIcon = styled(Icon)`
  font-size: 24px;
  color: ${GetColor.MidGrey1};
  margin-top: 4px;
`;

const StyledButton = styled(Button)`
  margin-top: 4px;
  height: 55px;
  padding: 26px;
  border: none;
  min-width: 0;
  background-color: transparent;
  &:hover {
    background-color: transparent;
    i {
      color: ${GetColor.Primary.Main};
    }
  }
`;

const ContainerInner = styled.div`
  font-weight: normal;

  > div {
    line-height: 28px;
    max-height: inherit;
    position: relative;
    z-index: ${ZIndex.StickyCover};
  }
  > div > div {
    padding-bottom: 0;
  }
  .split-item {
    padding: 0 10px;
  }
`;
