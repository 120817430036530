import type { ReactNode, RefObject } from 'react';
import React, { Component } from 'react';
import styled from 'styled-components';

interface StickyNodeProps {
  reference?: RefObject<HTMLDivElement>;
  className?: string;
  top?: number;
  children?: ReactNode;
}

export default class StickyNode extends Component<StickyNodeProps> {
  render() {
    const { children, className, reference, top } = this.props;

    return (
      <Wrapper className={className} ref={reference} top={top}>
        {children}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div<{ top?: number }>`
  position: sticky;
  ${({ top }) => `top: ${top ?? 0}px;`}
`;
