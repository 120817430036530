import React, { useContext } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ShimmerBlock } from 'venn-ui-kit';
import { PRIVATE_NAV_BLOCK_SETTINGS, withSuspense } from 'venn-utils';
import { blockPrivateAssetIsCumulative, blockSettings } from 'venn-state';
import { SliderToggle, UserContext } from 'venn-components';
import { FlexHeader, Section } from '../../../shared';
import styled from 'styled-components';

interface ScenarioSectionProps {
  selected: string;
}

const Content = styled.div`
  padding: 0 10px;
`;

const PrivateAssetSection = ({ selected }: ScenarioSectionProps) => {
  const { hasPermission } = useContext(UserContext);
  const isReadOnly = !hasPermission('STUDIO_EDIT_METRICS');
  const blockSetting = useRecoilValue(blockSettings(selected));
  const label = blockSetting.title;
  const [privateAssetIsCumulative, setPrivateAssetIsCumulative] = useRecoilState(
    blockPrivateAssetIsCumulative(selected),
  );
  if (blockSetting.customBlockType !== 'PRIVATE_CASH_FLOW' || !label) {
    return null;
  }

  const disableCumulative = blockSetting.id === PRIVATE_NAV_BLOCK_SETTINGS.id;

  return (
    <Section>
      <FlexHeader>Private Assets</FlexHeader>
      <Content>
        <ToggleContainer>
          Cumulative {label}
          <SliderToggle
            disabled={isReadOnly || disableCumulative}
            toggled={disableCumulative ? false : !!privateAssetIsCumulative}
            onToggle={() => setPrivateAssetIsCumulative(!privateAssetIsCumulative)}
          />
        </ToggleContainer>
      </Content>
    </Section>
  );
};

const ToggleContainer = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
`;

export default withSuspense(<ShimmerBlock />, PrivateAssetSection);
