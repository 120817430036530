import './polyfills';
// Allow import of all css styles in the style module of venn-ui-kit
import 'venn-ui-kit/style';
import React from 'react';
import ReactDOM from 'react-dom';
import { initializeSentryLogger } from 'venn-utils';
import 'focus-visible';
import App from './app';

initializeSentryLogger();
ReactDOM.render(<App />, document.getElementById('root'));
