import { useQuery } from '@tanstack/react-query';
import { type CashFlowSetting, getSystemCashflowSetting, type PrivateFund } from 'venn-api';
import { logExceptionIntoSentry } from 'venn-utils';

const KEY = 'FETCH_SYSTEM_CASH_FLOW_PACING_SETTING';

export const useSystemCashflowSetting = (fund: PrivateFund) => {
  return useQuery<CashFlowSetting>(
    [KEY, fund.id],
    async () => {
      return (
        await getSystemCashflowSetting({
          assetClass: fund.assetClass,
          strategy: fund.strategy,
          vintage: fund.vintage,
        })
      ).content;
    },
    {
      onError: (error) => {
        return logExceptionIntoSentry(error as Error);
      },
      suspense: true,
    },
  );
};
