import { useEffect, useMemo, useRef, useState } from 'react';
import type { AnalysisGroup, AnalysisSubject, TimeFrame } from 'venn-utils';
import { convert, getAnalysisRequest, useApi } from 'venn-utils';
import type { AnalysisTypeEnum, RollingAnalysisResponse } from 'venn-api';
import { analysis } from 'venn-api';
import type { RollingPeriodSettings } from '../../../contexts';
import { isNil } from 'lodash';
import { buildDataWithRollingPeriodsForAnalysis } from './AnalysisLineChartUtils';
import type { FullPeriodLabels } from '../types';

const useCustomRollingWindow = (
  subject: AnalysisSubject,
  predefinedPeriodsAnalysisGroups: AnalysisGroup<RollingAnalysisResponse>[],
  rollingPeriodSettings: RollingPeriodSettings,
  relative: boolean,
  categoryActive: boolean,
  actualTimeFrame: TimeFrame,
  analysisType: AnalysisTypeEnum,
  analysisGroupAccessor: string,
  fullPeriodLabels: FullPeriodLabels,
  trackingId?: number,
) => {
  const analysisRef = useRef(useApi(analysis));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [customPeriodAnalysisGroup, setCustomPeriodAnalysisGroup] = useState<
    AnalysisGroup<RollingAnalysisResponse> | undefined
  >();

  const { customMonths } = rollingPeriodSettings;

  const customPeriodLoading = !isNil(customMonths) ? loading : false;

  // It takes 1-2 render cycles before `customPeriodLoading` is updated to `true` after the custom period is selected.
  // This trick with local optimistic loading helps
  const [optimisticLoading, setOptimisticLoading] = useState(false);
  useEffect(() => {
    if (!customPeriodLoading) {
      setOptimisticLoading(false);
    }
  }, [customPeriodLoading]);

  useEffect(() => {
    setCustomPeriodAnalysisGroup(undefined);
  }, [rollingPeriodSettings.customMonths]);

  useEffect(() => {
    const fetchDataForCustomPeriod = async () => {
      if (isNil(customMonths)) {
        setCustomPeriodAnalysisGroup(undefined);
        setLoading(false);
        return;
      }

      const request = getAnalysisRequest(
        [analysisType],
        subject,
        actualTimeFrame,
        relative,
        categoryActive,
        undefined,
        trackingId,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        customMonths / 12,
      );

      try {
        const { content } = await analysisRef.current(request);
        const analysesResults =
          subject && content.analyses ? convert(request, content.analyses, subject, relative) : undefined;
        const analysisGroup: AnalysisGroup<RollingAnalysisResponse> =
          analysesResults?.results?.[analysisGroupAccessor]?.[0];
        setCustomPeriodAnalysisGroup(analysisGroup);
      } catch (e) {
        const err = await e;
        if (err.name === 'AbortError') {
          return;
        }
        setError(err.content?.message);
        setCustomPeriodAnalysisGroup(undefined);
      }
      setLoading(false);
    };

    if (!isNil(customMonths) && !loading && !customPeriodAnalysisGroup && !error) {
      setLoading(true);
      fetchDataForCustomPeriod();
    }
  }, [
    customMonths,
    loading,
    analysisType,
    subject,
    actualTimeFrame,
    relative,
    categoryActive,
    trackingId,
    analysisGroupAccessor,
    customPeriodAnalysisGroup,
    error,
  ]);

  const chartDataWithRollingPeriods = useMemo(
    () =>
      buildDataWithRollingPeriodsForAnalysis(
        predefinedPeriodsAnalysisGroups,
        subject,
        fullPeriodLabels,
        customPeriodAnalysisGroup,
      ),
    [fullPeriodLabels, predefinedPeriodsAnalysisGroups, subject, customPeriodAnalysisGroup],
  );

  return {
    chartDataWithRollingPeriods,
    customPeriodError: !isNil(customMonths) ? error : undefined,
    customPeriodLoading: optimisticLoading || (!isNil(customMonths) ? loading : false),
    setOptimisticLoading,
  };
};

export default useCustomRollingWindow;
