import queryString from 'query-string';
import type { History } from 'history';
import { omit } from 'lodash';

export const updateSearchQuery = (key: string, value: string, history: History, deleteKey = false) => {
  const path = history?.location?.pathname;
  const params = queryString.parse(history?.location?.search?.replace?.('?', ''));

  const queryParams = queryString.stringify(
    deleteKey
      ? omit(params, key)
      : {
          ...params,
          [key]: value,
        },
  );

  if (path && queryParams) {
    history.push(`${path}?${queryParams}`, history?.location?.state);
  }
};

export const getQueryParams = (search: string) =>
  queryString.parse(search?.length > 0 && search.startsWith('?') ? search.substring(1) : search);
