import React, { useEffect, useState } from 'react';
import { isEqual, isFunction, noop } from 'lodash';
import type { FlattenSimpleInterpolation } from 'styled-components';
import styled, { css } from 'styled-components';
import { Button, ColorUtils, GetColor, Icon } from 'venn-ui-kit';
import type { BasicTableColumn, ColumnAlign } from 'venn-components';
import type { RowWithID } from './types';

type RowStyleFunction<TRow> = (data: TRow) => FlattenSimpleInterpolation;
type RowClassNameFunction<TRow> = (data: TRow) => string | undefined;

interface TableRowProps<TColumn extends BasicTableColumn<TRow>, TRow> {
  dataItem: TRow & RowWithID;
  isSelected: boolean;
  selectable?: boolean;
  itemIndex: number;
  columns: TColumn[];
  highlightCell?: boolean;
  hoverColumn: number | null;
  dataLength: number;
  setHoverColumn: (column: number | null) => void;

  onRowClick?(e: React.MouseEvent<HTMLTableRowElement> | React.KeyboardEvent<HTMLTableRowElement>, d: TRow): void;

  rowStyle?: FlattenSimpleInterpolation | RowStyleFunction<TRow>;
  rowClassName?: string | RowClassNameFunction<TRow>;
  onRowHoverToggle: (index: number, off: boolean) => () => void;
  handleRowClick: (
    selectable: boolean,
    dataItem: TRow,
  ) => (e: React.MouseEvent<HTMLTableRowElement> | React.KeyboardEvent<HTMLTableRowElement>) => void;
  handleEnterKeyUp: (selectable: boolean, dataItem: TRow) => (e: React.KeyboardEvent<HTMLTableRowElement>) => void;
  expandable?: boolean;
  expandableContent?: (item: TRow) => React.ReactNode;
}

const TableRow = <TColumn extends BasicTableColumn<TRow>, TRow>({
  dataItem,
  selectable,
  itemIndex,
  onRowHoverToggle,
  handleRowClick,
  handleEnterKeyUp,
  isSelected,
  columns,
  highlightCell,
  setHoverColumn,
  hoverColumn,
  onRowClick,
  dataLength,
  rowClassName,
  rowStyle,
  expandable,
  expandableContent,
}: TableRowProps<TColumn, TRow>) => {
  const [expanded, setExpanded] = useState(false);

  const isOdd = itemIndex % 2;

  useEffect(() => {
    if (expandable) {
      setExpanded(false);
    }
  }, [dataItem.rowId, expandable]);

  return (
    <>
      <StyledRow
        onMouseEnter={onRowHoverToggle(itemIndex, false)}
        onMouseLeave={onRowHoverToggle(itemIndex, true)}
        selectable={!!selectable}
        onClick={handleRowClick(!!selectable, dataItem)}
        onKeyUp={handleEnterKeyUp(!!selectable, dataItem)}
        selected={isSelected}
        cssStyles={typeof rowStyle === 'function' ? rowStyle(dataItem) : rowStyle}
        className={[
          typeof rowClassName === 'function' ? rowClassName(dataItem) : rowClassName,
          'basic-table-row',
          isOdd ? 'basic-table-row-odd' : '',
        ]
          .filter(Boolean)
          .join(' ')}
        tabIndex={onRowClick ? 0 : undefined}
        role={onRowClick ? 'button' : undefined}
      >
        {expandable && (
          <td>
            <ExpandButton data-testid="qa-expand-trigger-button" onClick={() => setExpanded(!expanded)}>
              <Icon type={expanded ? 'chevron-up' : 'chevron-down'} />
            </ExpandButton>
          </td>
        )}
        {columns.map((column, colIndex) => (
          <StyledCell
            // eslint-disable-next-line react/no-array-index-key
            key={colIndex}
            className={column.className}
            style={isFunction(column.cellStyle) ? column.cellStyle(dataItem, itemIndex) : column.cellStyle}
            alignment={column.align}
            columnHover={highlightCell && hoverColumn === colIndex}
            onMouseOver={() => (highlightCell ? setHoverColumn(colIndex) : noop)}
            onFocus={() => (highlightCell ? setHoverColumn(colIndex) : noop)}
            onMouseOut={() => (highlightCell ? setHoverColumn(null) : noop)}
            onBlur={() => (highlightCell ? setHoverColumn(null) : noop)}
            {...(column.cellProps ? column.cellProps(dataItem, itemIndex, dataLength) : {})}
          >
            {column.cellRenderer
              ? column.cellRenderer(dataItem, itemIndex, dataLength)
              : column.accessor
                ? dataItem[column.accessor]
                : ''}
          </StyledCell>
        ))}
      </StyledRow>
      {expandable ? (
        <StyledRow
          selected={false}
          selectable={false}
          cssStyles={typeof rowStyle === 'function' ? rowStyle(dataItem) : rowStyle}
          className={[
            typeof rowClassName === 'function' ? rowClassName(dataItem) : rowClassName,
            'basic-table-row-expandable',
            isOdd ? 'basic-table-row-odd' : '',
          ]
            .filter(Boolean)
            .join(' ')}
        >
          {expanded ? <StyledCell colSpan={columns.length + 1}>{expandableContent?.(dataItem)}</StyledCell> : null}
        </StyledRow>
      ) : null}
    </>
  );
};

// TODO Get rid of comparison function VENN-16440
const areEqual = <TColumn extends BasicTableColumn<TRow>, TRow>(
  prevProps: TableRowProps<TColumn, TRow>,
  nextProps: TableRowProps<TColumn, TRow>,
) =>
  isEqual(prevProps.dataItem, nextProps.dataItem) &&
  isEqual(prevProps.columns, nextProps.columns) &&
  prevProps.isSelected === nextProps.isSelected &&
  prevProps.hoverColumn === nextProps.hoverColumn &&
  prevProps.highlightCell === nextProps.highlightCell &&
  prevProps.dataLength === nextProps.dataLength &&
  prevProps.itemIndex === nextProps.itemIndex;
// Cast to typeof tableRow is to pull over the generic types properly, which calling React.memo loses
export default React.memo(TableRow, areEqual) as typeof TableRow;

const backgroundColor = ColorUtils.hex2rgbaFrom(GetColor.Primary.Main, 0.2);
const selectedHoverColor = ColorUtils.hex2rgbaFrom(GetColor.Primary.Main, 0.25);
const selectableHoverColor = ColorUtils.hex2rgbaFrom(GetColor.Primary.Main, 0.05);

const StyledRow = styled.tr<{ selected: boolean; selectable: boolean; cssStyles?: FlattenSimpleInterpolation }>`
  ${(props) =>
    props.selected &&
    css`
      background-color: ${backgroundColor(props)};

      &:hover {
        background-color: ${selectedHoverColor(props)};
      }
    `}
  ${(props) =>
    !props.selected &&
    props.selectable &&
    `
    &:hover {
      background: ${selectableHoverColor(props)};
    }
  `};
  ${(props) => props.cssStyles}
`;

interface StyledHeaderCellProps extends React.TdHTMLAttributes<HTMLTableCellElement> {
  alignment?: ColumnAlign;
  sortable?: boolean;
  columnHover?: boolean;
}

const StyledCell = styled.td<StyledHeaderCellProps>`
  text-align: ${(props) => props.alignment || 'left'};
  background-color: ${(props) => (props.columnHover ? GetColor.PaleGrey : 'unset')};
`;

const ExpandButton = styled(Button)`
  width: 24px;
  height: 24px;
  border-color: ${GetColor.GreyScale.Grey30};
  min-width: auto;
  min-height: auto;
  padding: 0;
`;
