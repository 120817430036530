import React from 'react';
import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';
import { errorColor, leftRightPadding } from './styles';

const SubText = ({
  errorHidden = false,
  error,
  hint,
}: {
  errorHidden: boolean | undefined;
  error?: React.ReactNode;
  hint?: React.ReactNode;
}) => (
  <Container>
    {error && !errorHidden && <ErrorText className="qa-form-error">{error}</ErrorText>}
    {(!error || errorHidden) && hint && <Hint>{hint}</Hint>}
  </Container>
);

export default SubText;

const Container = styled.div`
  margin-top: 4px;
  padding: 0 ${leftRightPadding}px;
  font-size: 10px;
  min-height: 11px;
`;

const Hint = styled.div`
  color: ${GetColor.WhiteGrey};
`;

const ErrorText = styled.div`
  color: ${errorColor};
`;
