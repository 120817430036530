import type {
  AllocationConstraintConditionEnum,
  AllocationConstraintValueTypeEnum,
  ConstraintTarget,
  ConstraintTypeEnum,
  Portfolio,
  PortfolioConstraint,
  PortfolioPolicy,
} from 'venn-api';
import type {
  AllocConstraint,
  AllocationCondition,
  AllocationItem,
  ConstraintValueType,
  FactorCondition,
  FactorExposureConstraint,
} from './types';
import { mapPortfolioIdsToNewPortfolioIds, mapPortfolioToNodes, mapPortfolioToParentNodes } from '../portfolio';
import { isNil, omitBy, isUndefined } from 'lodash';

export const parseFactorExposureConstraint = (
  portfolioConstraints: PortfolioConstraint[],
): FactorExposureConstraint[] =>
  portfolioConstraints
    .filter(
      (constraint: PortfolioConstraint) =>
        constraint.constraintType === 'FACTOR' && !isNil(constraint.value) && !isNil(constraint.targets?.[0].factorId),
    )
    .map((constraint: PortfolioConstraint) => ({
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      factorId: constraint.targets?.[0].factorId!,
      condition: constraint.condition === 'MIN' ? 'minExposure' : 'maxExposure',
      value: constraint.value!,
      isInPortfolioPolicy: true,
    }));

export const parseAllocConstraints = (
  portfolioConstraints: PortfolioConstraint[],
  portfolio: Portfolio,
): AllocConstraint[] =>
  sortAllocationConstraints(
    portfolioConstraints
      .filter((constraint: PortfolioConstraint) => constraint.constraintType === 'ALLOCATION')
      .map((constraint: PortfolioConstraint) => ({
        allInvestments: constraint.allEntities,
        items: parseAllocationItems(portfolio, constraint.targets),
        condition: parseAllocConstraintCondition(constraint.condition),
        valueType: parseConstraintValueType(constraint.valueType),
        value:
          constraint.valueType === 'PERCENT' && !isNil(constraint.value) ? constraint.value * 100 : constraint.value,
        isInPortfolioPolicy: true,
      })),
    portfolio.id,
  );

export const parseConstraintsToPolicy = (
  allocationConstraints: AllocConstraint[] | undefined,
  factorExposureConstraints: FactorExposureConstraint[] | undefined,
  previousPolicy: PortfolioPolicy | undefined,
  portfolio: Portfolio,
  skipNewConstraints = true,
): PortfolioPolicy => {
  const allocConstraints: PortfolioConstraint[] = isNil(allocationConstraints)
    ? (previousPolicy?.constraints ?? []).filter((constraint) => constraint.constraintType === 'ALLOCATION')
    : allocationConstraints
        .filter((constraint) => !constraint.isNew || !skipNewConstraints)
        .map((constraint) => ({
          allEntities: !!constraint.allInvestments,
          condition: parseAllocationConstraintConditionEnum(constraint.condition),
          constraintType: 'ALLOCATION' as ConstraintTypeEnum,
          targets: parseConstraintTargets(portfolio, constraint.items),
          value:
            constraint.valueType === 'percentOfPortfolio' && !isNil(constraint.value)
              ? constraint.value / 100
              : constraint.value,
          valueType: parseAllocationConstraintValueTypeEnum(constraint.valueType),
        }));
  const factorConstraints: PortfolioConstraint[] = isNil(factorExposureConstraints)
    ? (previousPolicy?.constraints ?? []).filter((constraint) => constraint.constraintType === 'FACTOR')
    : factorExposureConstraints.map((constraint) => ({
        allEntities: false,
        condition: parseAllocationConstraintConditionEnum(constraint.condition),
        constraintType: 'FACTOR' as ConstraintTypeEnum,
        targets: [{ factorId: constraint.factorId }],
        value: constraint.value,
        valueType: 'CURRENCY',
      }));

  return {
    ...(previousPolicy ?? {
      portfolioId: portfolio.id,
    }),
    constraints: [...allocConstraints, ...factorConstraints],
  };
};

export const mapAllocationConstraintsToNewPortfolio = (
  prevPortfolio: Portfolio,
  newPortfolio: Portfolio,
  allocationConstraints: AllocConstraint[],
): AllocConstraint[] => {
  const prevIdsToNewIds = mapPortfolioIdsToNewPortfolioIds(prevPortfolio, newPortfolio);
  return allocationConstraints.map((a) => ({
    ...a,
    items: a.items.map((i) => ({ ...i, id: prevIdsToNewIds.get(i.id) ?? i.id })),
  }));
};

const parseAllocConstraintCondition = (condition: AllocationConstraintConditionEnum): AllocationCondition => {
  switch (condition) {
    case 'MAX':
      return 'maxAllocation';
    case 'MIN':
      return 'minAllocation';
    case 'LOCKED':
      return 'lockedAllocation';
    case 'MAX_TURNOVER':
    default:
      return 'maxTurnover';
  }
};

const parseAllocationConstraintConditionEnum = (
  condition: AllocationCondition | FactorCondition,
): AllocationConstraintConditionEnum => {
  switch (condition) {
    case 'maxAllocation':
    case 'maxExposure':
      return 'MAX';
    case 'minAllocation':
    case 'minExposure':
      return 'MIN';
    case 'lockedAllocation':
      return 'LOCKED';
    case 'maxTurnover':
    default:
      return 'MAX_TURNOVER';
  }
};

const parseConstraintValueType = (valueType: AllocationConstraintValueTypeEnum): ConstraintValueType => {
  switch (valueType) {
    case 'CURRENCY':
      return 'fixedValue';
    case 'PERCENT':
      return 'percentOfPortfolio';
    case 'CURRENT_VALUE':
    default:
      return 'currentValue';
  }
};

const parseAllocationConstraintValueTypeEnum = (valueType: ConstraintValueType): AllocationConstraintValueTypeEnum => {
  switch (valueType) {
    case 'fixedValue':
      return 'CURRENCY';
    case 'percentOfPortfolio':
      return 'PERCENT';
    case 'currentValue':
    default:
      return 'CURRENT_VALUE';
  }
};

export const parseAllocationItems = (portfolio: Portfolio, targets: ConstraintTarget[]): AllocationItem[] => {
  const mappedNodes = mapPortfolioToNodes(portfolio);
  const items: AllocationItem[] = [];

  targets.forEach((target) => {
    const node = isNil(target.strategyId)
      ? undefined
      : !isNil(target.fundId)
        ? // Constraint is for a fund under a specific parent strategy
          (mappedNodes.get(target.strategyId)?.children ?? []).find((child) => child.fund?.id === target.fundId)
        : // Constraint is for a specific strategy
          mappedNodes.get(target.strategyId);
    if (!isNil(node)) {
      const { id, name, fund, allocation, draft } = node;

      const removeUndefineds = omitBy<AllocationItem>(
        { id, name, fund, allocation, draft },
        isUndefined,
      ) as AllocationItem;

      items.push(removeUndefineds);
    }
  });

  return items;
};

export const parseConstraintTargets = (portfolio: Portfolio, items: AllocationItem[]): ConstraintTarget[] => {
  const parentNodes = mapPortfolioToParentNodes(portfolio);

  return items.map((item: AllocationItem) => ({
    fundId: item.fund?.id,
    fundName: item.fund?.name,
    strategyId: !isNil(item.fund) ? parentNodes.get(item.id) : item.id, // parent strategy id for fund-items
    strategyName: isNil(item.fund) ? item.name : undefined,
  }));
};

export const splitAllocationConstraints = (
  constraints: AllocConstraint[],
  rootId: number,
): { portfolio: AllocConstraint[]; strategy: AllocConstraint[]; investment: AllocConstraint[] } => {
  const portfolio: AllocConstraint[] = [];
  const strategy: AllocConstraint[] = [];
  const investment: AllocConstraint[] = [];

  constraints.forEach((constraint) => {
    // NOTE(collin.irwin): Why are all of these using optional modifier? Are the types all wrong, or is the code wrong?
    if (constraint.items?.[0]?.id === rootId) {
      portfolio.push(constraint);
    } else if (isNil(constraint.items?.[0]?.fund) && !constraint.allInvestments) {
      strategy.push(constraint);
    } else {
      investment.push(constraint);
    }
  });

  return { portfolio, strategy, investment };
};

export const sortAllocationConstraints = (constraints: AllocConstraint[], rootId: number) => {
  const { portfolio, strategy, investment } = splitAllocationConstraints(constraints, rootId);
  return [...portfolio, ...strategy, ...investment];
};
