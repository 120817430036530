import React from 'react';
import { Button, GetColor } from 'venn-ui-kit';
import styled, { css } from 'styled-components';

interface ForecastButtonProps {
  onClick: () => void;
  disabled?: boolean;
  children?: React.ReactNode;
  dataTestId?: string;
  icon?: string;
}

const ForecastButton = ({
  onClick,
  disabled = false,
  dataTestId = 'qa-forecast-button',
  children = undefined,
  icon = 'plus-circle',
}: ForecastButtonProps) => {
  return (
    <StyledButton expanded={false} data-testid={dataTestId} dominant disabled={disabled} icon={icon} onClick={onClick}>
      {children}
    </StyledButton>
  );
};

export default ForecastButton;
const StyledButton = styled(Button)<{ expanded: boolean }>`
  &&& {
    border-radius: 27px;
    ${(props) =>
      props.expanded &&
      css`
        border-color: ${GetColor.Primary.Dark};
        background-color: ${GetColor.Primary.Dark};
        color: ${GetColor.White};
      `}
  }
`;
