import React, { Component } from 'react';
import type { UserOneTimePassword } from 'venn-api';
import { generateMoreBackupCodes, getBackupCodes } from 'venn-api';
import { Error, LeftAction, Content } from '../account-modals/shared';
import styled from 'styled-components';
import { Button, GetColor, Loading, LoadingSize, CheckboxWrapper } from 'venn-ui-kit';
import { saveAs } from 'file-saver';
import { map } from 'lodash';
import { BackupCodeList } from './BackupCodeList';
import { ModalFooter } from '../../modal';

interface BackupCodesProps {
  onClose: () => void;
  header?: string;
  confirmMessage?: string;
}

interface BackupCodesState {
  loading: boolean;
  error: string;
  confirmCheckboxClicked: boolean;
  backupCodes: UserOneTimePassword[];
}
const BASE_CONFIRM_MESSAGE =
  'I have my backup codes saved in a safe place so I won’t need to start the 3-5 day manual authentication process or potentially permanently lose access to my account if I am unable to access ';

export class BackupCodes extends Component<BackupCodesProps, BackupCodesState> {
  state: BackupCodesState = {
    loading: false,
    error: '',
    backupCodes: [],
    confirmCheckboxClicked: false,
  };

  componentDidMount = async () => {
    this.setState({
      loading: true,
    });
    try {
      const response = await getBackupCodes();
      let codes = response.content;
      if (!codes.length) {
        const newResponse = await generateMoreBackupCodes();
        codes = newResponse.content;
      }
      this.setState({
        backupCodes: codes,
      });
    } catch (e) {
      const error = await e;
      this.setState({
        error: error.content?.message,
      });
    }
    this.setState({
      loading: false,
    });
  };

  onGenerateMoreCodes = async () => {
    try {
      this.setState({
        loading: true,
      });
      const response = await generateMoreBackupCodes();
      this.setState({
        backupCodes: response.content,
      });
    } catch (e) {
      const error = await e;
      this.setState({ error: error.content?.message });
    }
    this.setState({
      loading: false,
    });
  };

  downloadCodes = () => {
    try {
      const blob = new Blob([map(this.state.backupCodes, 'code').join('\r\n')], { type: 'text/plain;charset=utf-8' });
      saveAs(blob, 'venn-backup-codes.txt');
    } catch (e) {
      // Suppress errors on downloads
    }
  };

  render() {
    const { backupCodes, confirmCheckboxClicked, error, loading } = this.state;
    const { header, onClose, confirmMessage } = this.props;
    return (
      <>
        <Content>
          <SubTitle>{header || 'Save your backup codes.'}</SubTitle>
          <StyledParagraph>
            We strongly recommend saving your backup codes in a safe place. These codes will allow you to gain access to
            your account in the event that you lose access to your primary authentication method.
          </StyledParagraph>
          <Error>{error}</Error>
          <BackupCodeList loading={loading} backupCodes={backupCodes}>
            <LeftAction>
              <Button dominant onClick={this.downloadCodes} disabled={loading}>
                Download Codes
              </Button>
              <Button onClick={this.onGenerateMoreCodes} disabled={loading}>
                Reset Codes
                {loading && <StyledLoading size={LoadingSize.nano} title="" />}
              </Button>
            </LeftAction>
          </BackupCodeList>
        </Content>
        {confirmMessage ? (
          <>
            <Content>
              <StyledCheckboxWrapper
                checked={confirmCheckboxClicked}
                onChange={() =>
                  this.setState({
                    confirmCheckboxClicked: !confirmCheckboxClicked,
                  })
                }
                required
              >
                {BASE_CONFIRM_MESSAGE + confirmMessage}
              </StyledCheckboxWrapper>
            </Content>
            <ModalFooter primaryLabel="Ok" primaryDisabled={!confirmCheckboxClicked} onPrimaryClick={onClose} />
          </>
        ) : (
          <ModalFooter primaryLabel="Ok" onPrimaryClick={onClose} />
        )}
      </>
    );
  }
}

export default BackupCodes;

const StyledLoading = styled(Loading)`
  display: inline;
`;

const SubTitle = styled.p`
  font-size: 12px;
  font-weight: bold;
`;

const StyledParagraph = styled.p`
  font-size: 14px;
  line-height: 24px;
  color: ${GetColor.Black};
`;

const StyledCheckboxWrapper = styled(CheckboxWrapper)`
  padding-bottom: 5px;
  input[type='checkbox'] {
    width: 19px;
    height: 13px;
  }

  input[type='checkbox']:checked::before {
    top: 0px;
    left: 0px;
  }
  span {
    color: ${GetColor.Black};
    font-size: 10px;
    line-height: 2;
    margin-top: 15px;
  }
`;
