import React, { Suspense } from 'react';
import { useHasFF } from 'venn-utils';

const LazyVennDebug = React.lazy(() => import('venn-debug'));

/** Venn-created debug tools loaded lazily ONLY when using the frontend_debug_panel FF. */
export const LazyVennDebugButton = () => {
  const hasDebugFF = useHasFF('frontend_debug_panel');

  const LazyLoadedDebugTools = hasDebugFF ? LazyVennDebug : null;

  return (
    LazyLoadedDebugTools && (
      <Suspense fallback={null}>
        <LazyLoadedDebugTools />
      </Suspense>
    )
  );
};
