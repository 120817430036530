import moment from 'moment';
import type { LineChartData, SeriesTypes } from './parseData';
import type { AnyDuringEslintMigration } from 'venn-utils';
import type { Typography } from 'venn-ui-kit';

const displayValue = (y: number | string | null, asPlainValue: boolean) =>
  asPlainValue ? `<b>${Number(y).toFixed(2)}</b>` : `<b>${(Number(y) * 100).toFixed(2)}%</b> `;
const displayTValue = (tValue?: number | string | null) =>
  tValue ? `<i>(T-Stat: ${`${Number(tValue).toFixed(2)}`})</i>` : '';

const getTooltipFormatter = (
  name: string,
  x: number,
  y: number,
  typography: Typography,
  seriesTypes: SeriesTypes,
  isExposureBlock: boolean,
  mainSerie: LineChartData,
  benchmark?: LineChartData,
  category?: LineChartData,
): string => {
  const mainSerieType = seriesTypes.mainSerieType;
  const benchmarkType = seriesTypes.benchmarkSerieType;
  const categorySerieType = seriesTypes.categorySerieType;
  const [, , mainSerieTValue] = (mainSerie.find((mainSeries: AnyDuringEslintMigration) => mainSeries[0] === x) ||
    []) as AnyDuringEslintMigration;
  const displayMainSerieTValue = displayTValue(mainSerieTValue);

  if (benchmark && category) {
    const [, benchmarkY, benchmarkTValue] =
      benchmark.find((benchmark: AnyDuringEslintMigration) => benchmark[0] === x) || ([] as AnyDuringEslintMigration);
    const displayBenchmark = benchmarkY ? `${displayValue(benchmarkY, isExposureBlock)} ` : '<b>Excluded</b>';
    const displayBenchmarkTValue = displayTValue(benchmarkTValue);
    const [, categoryY, categoryTValue] =
      category.find((category: AnyDuringEslintMigration) => category[0] === x) || ([] as AnyDuringEslintMigration);
    const displayCategoryk = categoryY ? `${displayValue(categoryY, isExposureBlock)} ` : '<b>Excluded</b>';
    const displayCategorykTValue = displayTValue(categoryTValue);

    return `
    <div style="font-family:${typography.fontFamily}">
      <b style="display: block; padding-bottom: 5px;">${moment.utc(x).format('MMM, YYYY')}</b>
      <b>${name}</b><br/>
      ${mainSerieType}: ${displayValue(y, isExposureBlock)} ${displayMainSerieTValue}<br/>
      ${benchmarkType}: ${displayBenchmark} ${displayBenchmarkTValue}<br/>
      ${categorySerieType}: ${displayCategoryk} ${displayCategorykTValue}
    </div>
  `;
  }

  if (benchmark || category) {
    const secondLineData = benchmark || category || [];

    const [, secondLineDataY, secondLineDataTValue] =
      secondLineData.find((secondLineData: AnyDuringEslintMigration) => secondLineData[0] === x) ||
      ([] as AnyDuringEslintMigration);
    const displaySecondLineData = secondLineDataY
      ? `${displayValue(secondLineDataY, isExposureBlock)} `
      : '<b>Excluded</b>';
    const displaySecondLineDataTValue = displayTValue(secondLineDataTValue);

    return `
    <div style="font-family:${typography.fontFamily}">
      <b style="display: block; padding-bottom: 5px;">${moment.utc(x).format('MMM, YYYY')}</b>
      <b>${name}</b><br/>
      ${mainSerieType}: ${displayValue(y, isExposureBlock)}  ${displayMainSerieTValue}<br/>
      ${benchmark ? benchmarkType : categorySerieType}: ${displaySecondLineData} ${displaySecondLineDataTValue}<br/>
    </div>
  `;
  }

  return `
  <div style="font-family:${typography.fontFamily}">
    <b style="display: block; padding-bottom: 5px;">${moment.utc(x).format('MMM, YYYY')}</b>
    <b>${name}</b><br/>
    ${mainSerieType}: ${displayValue(y, isExposureBlock)} ${displayMainSerieTValue}
  </div>
`;
};

export default getTooltipFormatter;
