import React, { useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { FlexHeader, Section } from '../../../shared';
import {
  blockInfoGraphicType,
  blockSettings,
  isReportState,
  type BlockId,
  blockTableSpacingRatioState,
  tableSpaceSettings,
} from 'venn-state';
import { DropMenu, NewBadge, type DropMenuItem } from 'venn-ui-kit';
import { isTextOrFormatBlock, supportsTableSpacing } from 'venn-utils';

const baseMenuItems: DropMenuItem<number>[] = [
  { label: 'Extra Small', value: tableSpaceSettings.extraSmall },
  { label: 'Small', value: tableSpaceSettings.small },
  { label: 'Medium', value: tableSpaceSettings.medium },
  { label: 'Large', value: tableSpaceSettings.large },
];

export const TableSpacingSection = ({ selected, isReadOnly }: { selected: BlockId; isReadOnly?: boolean }) => {
  const [gridSizeRatio, setGridSizeRatio] = useRecoilState(blockTableSpacingRatioState(selected));

  const currentItem = useMemo(() => findBaseItem(gridSizeRatio), [gridSizeRatio]);
  const menuItems = useMemo(
    () => (baseMenuItems.some((item) => item === currentItem) ? baseMenuItems : [...baseMenuItems, currentItem]),
    [currentItem],
  );

  const settings = useRecoilValue(blockSettings(selected));
  const isReport = useRecoilValue(isReportState);
  const infoGraphicType = useRecoilValue(blockInfoGraphicType(selected)) ?? 'DEFAULT';

  const blockType = settings.customBlockType;
  const blockSupportsTableSpacing = settings.supportedGraphicTypes.some((type) =>
    supportsTableSpacing(type, blockType),
  );
  const tableSpacingEnabled = supportsTableSpacing(infoGraphicType, blockType);

  if (isTextOrFormatBlock(blockType) || !blockSupportsTableSpacing) {
    return <></>;
  }

  if (!isReport) {
    return <></>;
  }

  return (
    <Section>
      <FlexHeader style={{ columnGap: 8 }}>
        Table Cell Spacing
        <NewBadge noMargin />
      </FlexHeader>
      <DropMenu
        disabled={isReadOnly || !tableSpacingEnabled}
        items={menuItems}
        onChange={(item) => setGridSizeRatio(item.value)}
        selected={currentItem.value}
      />
    </Section>
  );
};

function findBaseItem(fontSizeToGridSizeRatio: number) {
  const foundItem = baseMenuItems.find((item) => {
    return Math.abs(item.value - fontSizeToGridSizeRatio) < 0.01;
  });

  if (!foundItem) {
    return { label: `Custom Ratio - ${fontSizeToGridSizeRatio}`, value: fontSizeToGridSizeRatio };
  }
  return foundItem;
}
