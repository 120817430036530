import React from 'react';
import type { ICellRendererParams } from '../../../../data-grid';
import type { ScenarioRowData } from '../../../types';
import ValueCellRenderer from './ValueCellRenderer';
import { GetColor, Icon, Tooltip } from 'venn-ui-kit';
import styled from 'styled-components';

interface ScenarioIndexCellRendererProps extends ICellRendererParams {
  data: ScenarioRowData;
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
const ScenarioIndexCellRenderer = React.memo((props: ScenarioIndexCellRendererProps) => {
  const row = props.data;
  const ErrorComponent = row.hasError && (
    <Tooltip
      content="We could not return some values for your scenario return input. Try decreasing the value."
      maxWidth={240}
      usePortal
    >
      <ErrorIcon type="exclamation-triangle" />
    </Tooltip>
  );
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <ValueCellRenderer {...props} />
      {ErrorComponent}
    </div>
  );
});

export default ScenarioIndexCellRenderer;

const ErrorIcon = styled(Icon)`
  color: ${GetColor.Error};
`;
