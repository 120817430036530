import React from 'react';
import styled from 'styled-components';
import Modal from '../modal/Modal';
import { Body1, Button, GetColor, Headline2 } from 'venn-ui-kit';
import { ModalSubhead } from '../modal/ModalHeader';
import triangles from './background-triangles.png';
import chart from './line-chart-asset.png';

interface AnalysisWelcomeModalProps {
  onClose: () => void;
}

const AnalysisWelcomeModal = ({ onClose }: AnalysisWelcomeModalProps) => (
  <Modal inverted closeOnEsc size={750} noPadding noBorder closeIconWidth={3} onClose={onClose}>
    <Background>
      <AssetContainer>
        <Circle>
          <ChartAsset src={chart} />
        </Circle>
      </AssetContainer>
      <Info>
        <Headline2 inverted>Welcome to Analysis</Headline2>
        <ModalSubhead>Quantitative decision-making starts here, in "Analyze".</ModalSubhead>
        <Narrow>
          <Body1 inverted>
            In this view, we'll show you familiar performance metrics and the Two Sigma Factor Lens in action.
          </Body1>
        </Narrow>
        <Button dominant dense onClick={onClose} className="qa-started">
          Get Started
        </Button>
      </Info>
    </Background>
  </Modal>
);

export default AnalysisWelcomeModal;

const Background = styled.div`
  background-image: url(${triangles});
  background-size: cover;
  overflow: hidden;
`;

const Info = styled.div`
  & > h3 {
    color: ${GetColor.White};
    margin-left: 0;
    margin-right: 0;
  }
  padding: 80px 178px 76px 80px;
`;

const Narrow = styled.div`
  max-width: 366px;
  margin-top: 20px;
  margin-bottom: 28px;
`;

const AssetContainer = styled.div`
  position: relative;
  top: 91px;
  left: 473px;
`;

const Circle = styled.div`
  position: absolute;
  width: 378px;
  height: 378px;
  border-radius: 50%;
  overflow: hidden;
  background: linear-gradient(180deg, rgba(102, 0, 255, 0) 0%, #6600ff 100%);
`;

const ChartAsset = styled.img`
  width: 278px;
  top: 40px;
  position: relative;
`;
