import { PageContent, TopControl } from './shared';
import { TopBarWrapper } from './top-bar/TopBar';
import { StudioShimmerBlock, SidePanelHeader, SidePanelWrapper } from 'venn-components';
import React from 'react';
import styled from 'styled-components';
import { getTopBarColor } from './top-bar/shared';

export const StudioPageLoadingState = () => (
  <PageContent
    isMainLeftPanelOpen
    isAllocatorOpen={false}
    isCompareOpen={false}
    isLeftBlockConfigPanelOpen={false}
    isLeftBlockConfigPanelExpanded={false}
    inPrintMode={false}
    orientation="PORTRAIT"
  >
    <TopControl>
      <TopBarWrapper barColors={getTopBarColor({ isTemplate: false })} />
    </TopControl>
    <SidePanelWrapper>
      <SidePanelHeader>
        <PanelWrapper>
          <StudioShimmerBlock height={25} />
        </PanelWrapper>
      </SidePanelHeader>
      <PanelWrapper>
        <StudioShimmerBlock />
      </PanelWrapper>
    </SidePanelWrapper>
    <StudioShimmerBlock />
  </PageContent>
);

const PanelWrapper = styled.div`
  margin: 10px 20px;
`;
