import type { DataUploaderProps } from './DataUploaderModal';
import React, { useState } from 'react';
import { DataUploaderMode } from './types';
import DataUploaderModal from './DataUploaderModal';
import { useHasFF } from 'venn-utils';

/** Finds the correct data uploader mode to start with. If the user doesn't have privates,
 * the behaviour should remain unchanged. However, if the user has privates, we want to start with a different screen
 * which allows toggling between different upload modes. */
const getInitialMode = (suggestedMode: DataUploaderMode, userHasPrivates: boolean): DataUploaderMode => {
  if (userHasPrivates && suggestedMode !== DataUploaderMode.NewNavs) {
    return DataUploaderMode.Initial;
  }
  return suggestedMode;
};
/** A wrapper around the data uploader component to allow for switching different modes */
const DataUploaderModalWrapper = (props: Omit<DataUploaderProps, 'setMode'>) => {
  const userHasPrivates = useHasFF('private_analytics');
  const [mode, setMode] = useState<DataUploaderMode>(getInitialMode(props.mode, userHasPrivates));
  return <DataUploaderModal {...props} mode={mode} setMode={setMode} />;
};

export default DataUploaderModalWrapper;
