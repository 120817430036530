import React from 'react';
import type { DropMenuItem } from 'venn-ui-kit';
import { DropMenu } from 'venn-ui-kit';

export enum SortByOption {
  SortByExposure = 'SORT_BY_EXPOSURE',
  SortByContributionToRisk = 'SORT_BY_CONTRIBUTION_TO_RISK',
  SortByContributionToReturn = 'SORT_BY_CONTRIBUTION_TO_RETURN',
  SortByDefault = 'SORT_BY_DEFAULT',
}

export interface SortByProps {
  value: SortByOption;
  disable?: boolean;
  onChange: (value: DropMenuItem<SortByOption>) => void;
  jumbo?: boolean;
}

export const SORT_BY_OPTIONS: DropMenuItem<SortByOption>[] = [
  {
    label: 'Exposure',
    value: SortByOption.SortByExposure,
  },
  {
    label: 'Contribution to risk',
    value: SortByOption.SortByContributionToRisk,
  },
  {
    label: 'Contribution to return',
    value: SortByOption.SortByContributionToReturn,
  },
  {
    label: 'Default factor grouping',
    value: SortByOption.SortByDefault,
  },
];

const SortBy = ({ value, onChange, disable, jumbo }: SortByProps) => (
  <DropMenu
    items={SORT_BY_OPTIONS}
    selected={value}
    onChange={onChange}
    disabled={disable}
    label="Sort by:"
    jumbo={jumbo}
  />
);

export default SortBy;
