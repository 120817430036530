import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { isPrintingState, isReportState, showInaccessibleSubjectsModal } from 'venn-state';
import { Modal, ModalContent, ModalFooter, ModalHeader, ModalSize } from 'venn-components';
import { GetColor, MISSING_INVESTMENTS_FAQ_HREF } from 'venn-ui-kit';
import styled from 'styled-components';

export const InaccessibleSubjectsWarningModal = ({ viewId }: { viewId: string }) => {
  const [modalOpen, setModalOpen] = useRecoilState(showInaccessibleSubjectsModal(viewId));
  const isReport = useRecoilValue(isReportState);
  const isPrinting = useRecoilValue(isPrintingState);

  if (!modalOpen || isPrinting) {
    return null;
  }

  return (
    <Modal size={ModalSize.Large}>
      <ModalHeader>
        Some investments or portfolios used in this {isReport ? 'Report' : 'View'} are no longer available in Venn
      </ModalHeader>
      <ModalContent>
        <b>
          Click{' '}
          <a rel="noopener noreferrer" target="_blank" href={MISSING_INVESTMENTS_FAQ_HREF}>
            learn more
          </a>{' '}
          for more information
        </b>
      </ModalContent>
      <ModalFooter
        primaryLabel={
          <HelpLink rel="noopener noreferrer" target="_blank" href={MISSING_INVESTMENTS_FAQ_HREF}>
            Learn more
          </HelpLink>
        }
        onPrimaryClick={() => {}}
        cancelLabel="Dismiss"
        onCancel={() => setModalOpen(false)}
      />
    </Modal>
  );
};

const HelpLink = styled.a`
  color: ${GetColor.Black};
`;
