import styled from 'styled-components';
import { GetColor, ZIndex } from 'venn-ui-kit';

export const UnclickableTrigger = styled.span`
  position: relative;
  padding: 0 10px;
  max-width: 660px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  display: block;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;

  z-index: ${ZIndex.Front};

  color: ${GetColor.MidGrey2};
  cursor: default;
`;

const DisabledTrigger = styled(UnclickableTrigger)`
  user-select: none;
  cursor: not-allowed;
`;

export default DisabledTrigger;
