import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { useHover, assert } from 'venn-utils';
import { GetColor, ColorUtils, Warning } from 'venn-ui-kit';
import { useRecoilValue, useRecoilState } from 'recoil';
import type { SubjectInputId } from 'venn-state';
import { blockSubjectInputGroups, subjectInputGroups } from 'venn-state';
import { Message, Flex } from '../../../../shared';
import { without } from 'lodash';
import { HoverIcon } from './HoverIcon';
import { SubjectInputDropMenu } from './SubjectInputDropMenu';
import { UserContext } from 'venn-components';
import { useSubjectGroupErrors } from './useSubjectGroupErrors';

/** List of all the subject inputs on a block, with support for replacing or adding subject groups. */
export const BlockSubjectInputList = React.memo(function BlockSubjectInputList({
  selectedBlockId,
  isAdding,
  onAddingFinished,
}: {
  selectedBlockId: string;
  isAdding?: boolean;
  onAddingFinished?: () => void;
}) {
  const { hasPermission } = useContext(UserContext);
  const blockSubjectGroups = useRecoilValue(blockSubjectInputGroups(selectedBlockId));
  const readonly = !hasPermission('STUDIO_EDIT_SUBJECTS') || !hasPermission('STUDIO_EDIT');

  if (blockSubjectGroups.length || isAdding) {
    return (
      <SubjectInputRowsGrid>
        {blockSubjectGroups.map((inputId) => (
          <SubjectInputDropMenuRow
            key={inputId}
            selectedBlockId={selectedBlockId}
            selectedInputId={inputId}
            readonly={readonly}
          />
        ))}
        {isAdding && (
          <SubjectInputDropMenuRow
            selectedBlockId={selectedBlockId}
            selectedInputId={undefined}
            onCollapse={onAddingFinished}
            readonly={readonly}
          />
        )}
      </SubjectInputRowsGrid>
    );
  }
  return <Message colorOverride={ColorUtils.opacifyFrom(GetColor.Warning, 0.1)}>No subject input groups added</Message>;
});

const SubjectInputRowsGrid = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 8px;
`;

/** Row supporting the selection of a subject input group from the list of all {@link subjectInputGroups}. */
// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
const SubjectInputDropMenuRow = React.memo(
  ({
    selectedBlockId,
    selectedInputId,
    onCollapse,
    readonly,
  }: {
    selectedBlockId: string;
    selectedInputId: SubjectInputId | undefined;
    onCollapse?: () => void;
    readonly: boolean;
  }) => {
    const [blockInputIds, setBlockInputIds] = useRecoilState(blockSubjectInputGroups(selectedBlockId));
    const allInputIds = useRecoilValue(subjectInputGroups);
    const menuInputIds = useMemo(() => {
      const excludedIds = selectedInputId ? without(blockInputIds, selectedInputId) : blockInputIds;
      return without(allInputIds, ...excludedIds);
    }, [allInputIds, blockInputIds, selectedInputId]);

    const [isRootHovered, rootHoverHandlers] = useHover();
    const subjectGroupErrors = useSubjectGroupErrors(selectedBlockId, selectedInputId);

    const setSubjectGroup = (newInputId: SubjectInputId) => {
      setBlockInputIds((oldInputIds) => {
        if (selectedInputId) {
          const indexOfOldInputId = oldInputIds.indexOf(selectedInputId);
          assert(indexOfOldInputId !== -1);

          const newArr = [...oldInputIds];
          newArr[indexOfOldInputId] = newInputId;
          return newArr;
        }

        return [...oldInputIds, newInputId];
      });
    };

    return (
      <>
        <Flex style={{ justifyContent: 'space-between' }} {...rootHoverHandlers}>
          <SubjectInputDropMenu
            menuInputIds={menuInputIds}
            selectedInputId={selectedInputId}
            onSelectGroup={setSubjectGroup}
            onCollapse={onCollapse}
            readonly={readonly}
            invalid={subjectGroupErrors.some((error) => error.type === 'ERROR')}
          />
          {!readonly && selectedInputId && (
            <HoverIcon
              type="trash"
              tabIndex={0}
              visible={isRootHovered}
              onClick={() => {
                setBlockInputIds((oldGroups) => without(oldGroups, selectedInputId));
              }}
            />
          )}
        </Flex>
        {subjectGroupErrors.map((error) => (
          <Warning key={error.message} text={error.message} />
        ))}
      </>
    );
  },
);
