export class CircularBuffer<T> {
  private pointer = 0;

  private length = 0;

  private readonly size: number;

  private buffer: T[] = [];

  constructor(size: number) {
    this.size = size;
  }

  public push(item: T) {
    this.buffer[this.pointer] = item;
    this.pointer = (this.pointer + 1 + this.size) % this.size;
    this.length = Math.min(this.length + 1, this.size);
  }

  public pop(): T | undefined {
    if (this.length === 0) {
      return undefined;
    }
    this.pointer = (this.pointer - 1 + this.size) % this.size;
    this.length -= 1;
    return this.buffer[this.pointer];
  }
}
