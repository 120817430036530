import React, { useCallback } from 'react';
import { EllipsisAutoPositionTooltipSpan } from 'venn-ui-kit';
import type { ShockUnitsEnum } from 'venn-api';
import InputRangeTooltip from './InputRangeTooltip';
import styled from 'styled-components';

interface ShockInputTooltip {
  fundName: string | undefined;
  mean: number;
  shock: number;
  units: ShockUnitsEnum;
  minVal: number;
  maxVal: number;
  minShockZScore: number;
  maxShockZScore: number;
}

const ShockInputTooltip = (props: ShockInputTooltip) => {
  const { fundName, shock, minShockZScore, maxShockZScore, maxVal, minVal, mean, units } = props;
  const renderHeader = useCallback(
    () => (
      <>
        <BoldSpan>Input:</BoldSpan>{' '}
        <EllipsisAutoPositionTooltipSpan maxWidth={220} content={fundName}>
          {fundName}
        </EllipsisAutoPositionTooltipSpan>{' '}
      </>
    ),
    [fundName],
  );
  return (
    <InputRangeTooltip
      renderHeader={renderHeader}
      rangeLegend="Available Range"
      meanLegend="Historical Avg"
      currentValLegend="Shock Value"
      currentVal={shock}
      minZScore={minShockZScore}
      maxZScore={maxShockZScore}
      maxVal={maxVal}
      minVal={minVal}
      mean={mean}
      units={units}
    />
  );
};

export default ShockInputTooltip;

const BoldSpan = styled.span`
  font-weight: bold;
  position: relative;
  // Slight hack to get the alignment right with the ellipsis
  // TODO replace EllipsisAutoPositionTooltipSpan with better implementation
  top: -1px;
`;
