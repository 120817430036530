import { useCallback, useState } from 'react';
import type { ClientIntegration } from 'venn-api';
import { getAllIntegrations, deleteIntegration } from 'venn-api';
import { Notifications, NotificationType } from 'venn-ui-kit';
import { logExceptionIntoSentry, useQuery } from 'venn-utils';

const ADDEPAR = 'ADDEPAR';
const ADDEPAR_LIST_CACHE_KEY = 'ADDEPAR_LIST';

interface AddeparListState {
  clientIntegrations: ClientIntegration[];
  refetchClientIntegrations: () => void;
  onDeleteClient: (integration: ClientIntegration) => void;
  onDeleteKeepData: () => void;
  onDeleteData: () => void;
  onDeleteCancel: () => void;
  isDeleting: boolean;
  isDeleteModalOpen: boolean;
  clientName: string | undefined;
}

const useAddeparList = (): AddeparListState => {
  const { data: clientIntegrations, refetch: refetchClientIntegrations } = useQuery<ClientIntegration[]>(
    [ADDEPAR_LIST_CACHE_KEY],
    async () => (await getAllIntegrations(ADDEPAR)).content.filter((i) => i.active && i.configVersion === 'V2'),
    {
      onError: (error) => {
        Notifications.notify('Unable to load Addepar integrations', NotificationType.ERROR);
        return logExceptionIntoSentry(error as Error);
      },
      initialData: [],
    },
  );

  const [clientToDelete, setClientToDelete] = useState<ClientIntegration>();
  const [isDeleting, setIsDeleting] = useState(false);

  const onDeleteClient = useCallback((integration: ClientIntegration) => {
    setClientToDelete(integration);
  }, []);

  const onDeleteData = useCallback(async () => {
    if (!clientToDelete) {
      return;
    }
    setClientToDelete(undefined);
    Notifications.notify(
      'Data deletion happens in the background to ensure a seamless user experience',
      NotificationType.INFO,
    );

    try {
      await deleteIntegration(clientToDelete.integrationUuid, ADDEPAR, true);
      refetchClientIntegrations();
    } catch (e) {
      Notifications.notify('Failed to delete integration', NotificationType.ERROR);
      logExceptionIntoSentry(e);
    }
  }, [clientToDelete, refetchClientIntegrations]);

  const onDeleteKeepData = useCallback(async () => {
    if (!clientToDelete) {
      return;
    }
    setIsDeleting(true);
    try {
      await deleteIntegration(clientToDelete.integrationUuid, ADDEPAR);
      refetchClientIntegrations();
      setClientToDelete(undefined);
      setIsDeleting(false);
      Notifications.notify('Integration deleted successfully', NotificationType.SUCCESS);
    } catch (e) {
      Notifications.notify('Failed to delete integration', NotificationType.ERROR);
      logExceptionIntoSentry(e);
    }
  }, [clientToDelete, refetchClientIntegrations]);

  const onDeleteCancel = useCallback(() => {
    setClientToDelete(undefined);
  }, []);

  return {
    clientIntegrations,
    isDeleteModalOpen: !!clientToDelete,
    refetchClientIntegrations,
    onDeleteClient,
    onDeleteKeepData,
    onDeleteData,
    onDeleteCancel,
    isDeleting,
    clientName: clientToDelete?.clientName,
  };
};

export default useAddeparList;
