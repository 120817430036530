import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Display1, GetColor, Headline2, Headline3, Body1 } from 'venn-ui-kit';
import { Markdown, UserContext } from 'venn-components';
import type { FactorWithNonSerializedReturns as FactorEntity } from 'venn-api';
import type { Factor, DeepDiveContentAspect } from './types';
import { getDeepDiveContent } from './content';
import Pyramid from '../basics/pyramid';
import Notable from './Notable';
import { useHasFF } from 'venn-utils';

interface PageContentProps {
  factor: Factor;
  factorEntity?: FactorEntity;
}

const PageContent = ({ factor, factorEntity }: PageContentProps) => {
  const content = getDeepDiveContent(useHasFF('mstar_indices_factors_ff'))[factor];
  const userContext = useContext(UserContext);
  const userOrg = userContext?.profileSettings?.organization;
  const { Typography } = useContext(ThemeContext);
  const shouldShowAspect = (aspect: DeepDiveContentAspect) => {
    return !aspect?.userOrgFilter || aspect?.userOrgFilter(userOrg);
  };
  return (
    <Container>
      <Header>
        <Display1>
          <Markdown source={content.title} />
        </Display1>
        <Pyramid category={content.category} typography={Typography} />
      </Header>
      <AspectsContainer>
        <Headline2>What are some key aspects of the {content.name} factor’s construction?</Headline2>
        <Aspects>
          {content.aspects
            .filter((aspect) => shouldShowAspect(aspect))
            .map((aspect) => (
              <Aspect key={aspect.title}>
                <AspectTitle>
                  <Headline3>
                    <Markdown source={aspect.title} />
                  </Headline3>
                </AspectTitle>
                <AspectContent>
                  <Body1>
                    <Markdown source={aspect.description} />
                  </Body1>
                </AspectContent>
              </Aspect>
            ))}
        </Aspects>
      </AspectsContainer>
      <Notable factor={factor} factorName={content.name} factorEntity={factorEntity} />
    </Container>
  );
};

const Container = styled.div`
  b,
  strong {
    color: ${GetColor.HighlightDark};
  }
`;

const Header = styled.div`
  display: flex;
  align-items: flex-start;

  > ${Display1} {
    flex: 1;
  }

  &:nth-child(2) {
  }
`;

const AspectsContainer = styled.div``;

const Aspects = styled.div`
  background-color: ${GetColor.WhiteGrey};
  padding: 40px;
  margin-top: 40px;
`;

const Aspect = styled.div`
  display: flex;
  min-height: 140px;
  margin-top: 10px;

  &:first-child {
    margin: 0;
  }
`;

const AspectTitle = styled.div`
  display: flex;
  width: 250px;
  margin-right: 10px;
  background-color: ${GetColor.White};

  ${Headline3}, a {
    color: ${GetColor.HighlightDark};
  }

  justify-content: center;
  align-items: center;
`;

const AspectContent = styled.div`
  display: flex;
  background-color: ${GetColor.White};
  justify-content: left;
  align-items: center;
  flex: 1;
  padding: 40px 60px;
`;

export default PageContent;
