import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { UserContext, getMenus } from 'venn-components';
import type { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { ExternalActivityListener } from 'venn-ui-kit';
import { buttonize, getAvatarUrl, Routes, useModal } from 'venn-utils';
import ShellNavigationLink from './ShellNavigationLink';
import SettingsMenu from './SettingsMenu';

interface AccountDropdownProps extends RouteComponentProps {
  showTopNav?: boolean;
  rightOptions?: React.ReactNode;
}

const AccountDropdownWithoutContext = (props: AccountDropdownProps) => {
  const [isOpen, open, close] = useModal();
  const [redirectToLogout, setRedirectToLogout] = useState(false);
  const historyRef = useRef(props.history);
  const { profileSettings } = useContext(UserContext);

  useEffect(() => {
    if (redirectToLogout) {
      historyRef.current.push(Routes.LOGOUT_PATH);
      setRedirectToLogout(false);
    }
  }, [redirectToLogout]);

  const toggleMenu = () => {
    isOpen ? close() : open();
  };

  if (!profileSettings) {
    return null;
  }
  const { showTopNav, rightOptions } = props;
  const { user, firstName, lastName, mfaEnabled, organization } = profileSettings;
  const avatarURL = getAvatarUrl(user.avatarId, 200);
  const { id, name } = organization;

  const menuTriggerImage = (
    <ProfileDropdownLink {...buttonize(toggleMenu)} className="qa-profile-icon">
      <Avatar data-html2canvas-ignore avatarURL={avatarURL} />
      <Locker className={mfaEnabled ? 'icon-locked-xs' : 'icon-unlocked-xs'} />
    </ProfileDropdownLink>
  );

  const menuTrigger = (
    <ShellNavigationLink
      navId="settings"
      label="Settings"
      iconComponent={<NavigationLinkWrapper>{menuTriggerImage}</NavigationLinkWrapper>}
      showTopNav={showTopNav}
      onClick={toggleMenu}
      tooltipContent="Settings"
      hideLabel
    />
  );

  const MENUS = getMenus();
  return (
    <NavOptionsWrapper>
      <MenuAnchor>
        <ExternalActivityListener listeningEnabled={isOpen} onExternalActivity={close}>
          {menuTrigger}
          {isOpen && (
            <SettingsMenu
              items={MENUS}
              firstName={firstName}
              lastName={lastName}
              onLogout={() => setRedirectToLogout(true)}
              organizationId={id}
              organizationName={name}
            />
          )}
        </ExternalActivityListener>
      </MenuAnchor>
      {rightOptions && <RightOptions>{rightOptions}</RightOptions>}
    </NavOptionsWrapper>
  );
};

const NavigationLinkWrapper = styled.div`
  position: relative;
  margin-left: -5px;
`;

const NavOptionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ProfileDropdownLink = styled.div`
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 100%;
  transform: scale(0.7);
`;

const Avatar = styled.div<{ avatarURL: string }>`
  height: 40px;
  width: 40px;
  background-size: cover;
  background-position: 50% center;
  border-radius: 50%;

  ${(props) => `
    background-image: url(${props.avatarURL});
  `};
`;

const MenuAnchor = styled.div`
  position: relative;
  width: 100%;
`;

const Locker = styled.div`
  position: absolute;
  top: -2px;
  right: -5px;
`;

const RightOptions = styled.div`
  display: flex;
  width: 40%;
  span {
    width: 54px;
  }
`;

export default withRouter(AccountDropdownWithoutContext);
