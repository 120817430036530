import React from 'react';
import styled, { css } from 'styled-components';
import { useRecoilValue } from 'recoil';
import { viewImageId } from 'venn-state';

interface ImageBlockProps {
  id: string;
  isReport?: boolean;
}

const ImageBlock = ({ id, isReport }: ImageBlockProps) => {
  const imageId = useRecoilValue(viewImageId(id));
  const noImage = !imageId;
  return (
    <ImageWrapper data-testid="image-block" noImage={noImage}>
      {noImage ? (
        <div>
          <i className="fa-solid fa-image" style={{ paddingRight: '8px' }} />
          Please Select or Upload an Image
        </div>
      ) : (
        <Image src={`/api/images/${imageId}`} alt={imageId} isReportMode={isReport} />
      )}
    </ImageWrapper>
  );
};

export default ImageBlock;
const ImageWrapper = styled.div<{ noImage?: boolean }>`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 5px;
  height: 100%;
  width: 100%;
  ${({ noImage }) =>
    noImage &&
    css`
      min-height: 100px;
    `}
`;

const Image = styled.img<{ isReportMode?: boolean }>`
  display: inline-block;
  object-fit: scale-down;
  ${({ isReportMode }) =>
    isReportMode &&
    css`
      object-fit: contain;
      width: 100%;
      height: 100%;
    `}
`;
