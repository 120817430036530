import { GetColor, Icon, Tooltip, TooltipPosition } from 'venn-ui-kit';
import React from 'react';
import styled from 'styled-components';

export const InterpolationThresholdExceededWarning = () => {
  return (
    <Tooltip
      position={TooltipPosition.Bottom}
      content="This proxy sits outside our recommended threshold for this investment."
    >
      <AlertIcon type="exclamation-triangle" />
    </Tooltip>
  );
};

const AlertIcon = styled(Icon)`
  color: ${GetColor.Warning};
`;
