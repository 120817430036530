export type Primitive = undefined | null | boolean | number | symbol | string;

/**
 * Type that excludes data that does not safely serialize, such as Dates, BigInts, functions, class instances, and promises.
 * This type cannot check for circular references, which can also break serialization.
 *
 * Other edge cases can also cause unexpected behavior in serialization, such as `NaN`, `Infinity`, `-Infinity`.
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#description | MDN}
 */
export type Serializable = Primitive | readonly Serializable[] | Readonly<{ [key: string]: Serializable }>;

export function serializeObject(x: Serializable): string {
  return JSON.stringify(x);
}
/**
 * Wrapper for JSON.parse with extra handling for edge cases.
 *
 * Cases:
 *  '' string input - returns undefined.
 *  'undefined' string input - returns undefined.
 */

export function deserializeObject<T extends Serializable>(x: string | undefined): T | undefined {
  if (x === '') return undefined;
  if (x === 'undefined' || x === undefined) return undefined;
  return JSON.parse(x) as T;
}

export function serializeBoolean(bool: boolean | undefined) {
  if (bool === undefined) return undefined;
  return String(bool);
}
/** Treats all values other than `'true'` as falsey. */

export function deserializeBoolean(bool: string | undefined) {
  return bool === 'true';
}
