import React, { useContext } from 'react';
import { StudioButton } from 'venn-ui-kit';
import styled from 'styled-components';
import { UserContext } from 'venn-components';
import { MAX_INPUTS_PER_KIND } from 'venn-utils';

interface SectionTitleProps {
  title: string;
  onAddNew: () => void;
  numberOfInputs: number;
}

const InputTitle = ({ title, onAddNew, numberOfInputs }: SectionTitleProps) => {
  const { hasPermission } = useContext(UserContext);
  const readonly = !hasPermission('STUDIO_EDIT');
  const canAdd = !readonly && numberOfInputs < MAX_INPUTS_PER_KIND;

  return (
    <FlexRow>
      {title}
      {canAdd && (
        <StudioButton data-testid={`new-${title}`} size="medium" onClick={onAddNew}>
          New
        </StudioButton>
      )}
    </FlexRow>
  );
};

export default InputTitle;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
  padding: 0 15px;
  height: 30px;
  margin-bottom: 10px;
`;
