import React, { useState } from 'react';
import { debounce } from 'lodash';
import styled from 'styled-components';

import { Icon } from '../icon/Icon';
import { LegacyRelativePortal } from '../relative-portal';

import { Container, Arrow, Header, Text, LearnMore, StyledIcon } from './styled';

import { SpecialCssClasses } from 'venn-utils';

interface Positions {
  top: number;
  left: number;
}

interface TooltipLinkProps {
  header?: string | JSX.Element;
  text?: string | JSX.Element;
  href: string;
  positions: Positions;
  top?: boolean;
}

const LEARN_MORE_WIDTH = 183;

const TooltipLink = ({ header, text, href, positions, top }: TooltipLinkProps) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <Wrapper
      className={SpecialCssClasses.NotDownloadable}
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={debounce(() => setIsVisible(false), 100)}
    >
      {isVisible ? (
        <LegacyRelativePortal
          component="span"
          top={positions.top}
          left={positions.left}
          style={{ minWidth: LEARN_MORE_WIDTH }}
        >
          <Container>
            <Arrow top={Math.abs(positions.top) - 5} left={top ? Math.abs(positions.left) : undefined} />
            {header ? <Header>{header}</Header> : null}
            {text ? <Text>{text}</Text> : null}
            <Icon type="info-circle" prefix="far" />
            <LearnMore>CLICK TO LEARN MORE</LearnMore>
          </Container>
        </LegacyRelativePortal>
      ) : null}
      <StyledIcon>
        <a href={href} target="_blank" rel="noopener noreferrer">
          <Icon type="info-circle" prefix="far" />
        </a>
      </StyledIcon>
    </Wrapper>
  );
};

const Wrapper = styled.span`
  @media print {
    display: none;
  }
`;

export default TooltipLink;
