import React from 'react';
import type { BasePortfolioField, NameField } from './types';

export interface CreatePortfolioContext {
  name: NameField;
  basePortfolio: BasePortfolioField;
  submitting: boolean;
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
const context = React.createContext<CreatePortfolioContext>({
  name: {
    validating: false,
    isError: false,
  },
  basePortfolio: {
    disabled: false,
    hidden: false,
    allowFromScratch: false,
    fromMaster: false,
    loading: false,
    baseline: undefined,
  },
  submitting: false,
});

export const { Provider, Consumer } = context;
export default context;
