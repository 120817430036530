import React from 'react';
import { Modal, ModalHeader, ModalFooter, ModalSubhead } from 'venn-components';

interface SwitchWorkspaceModalProps {
  isView: boolean;
  onCancel: () => void;
  onContinue: () => void;
}

const SwitchWorkspaceModal = ({ isView, onCancel, onContinue }: SwitchWorkspaceModalProps) => (
  <Modal>
    <ModalHeader>You are trying to access a {isView ? 'view' : 'link'} in a different workspace.</ModalHeader>
    <ModalSubhead>Do you want to switch workspaces?</ModalSubhead>
    <ModalFooter onCancel={onCancel} onPrimaryClick={() => onContinue()} primaryLabel="Continue" />
  </Modal>
);

export default SwitchWorkspaceModal;
