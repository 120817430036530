import React, { useCallback } from 'react';
import InputRangeTooltip from '../../../input/ShockInput/InputRangeTooltip';
import styled from 'styled-components';

type FactorForecastOverrideRangeTooltipProps = {
  currentVal: number;
  minVal: number;
  maxVal: number;
  minZScore: number;
  maxZScore: number;
  mean: number;
  isCurrentValUserSpecified: boolean;
};

const FactorForecastOverrideRangeTooltip = (props: FactorForecastOverrideRangeTooltipProps) => {
  const renderHeader = useCallback(() => <BoldSpan>Override Factor Forecast Return</BoldSpan>, []);
  return (
    <InputRangeTooltip
      renderHeader={renderHeader}
      rangeLegend="Override range"
      meanLegend="Default"
      currentValLegend="Selected Factor Return"
      units="PERCENTAGE"
      {...props}
    />
  );
};

export default FactorForecastOverrideRangeTooltip;

const BoldSpan = styled.span`
  font-weight: bold;
`;
