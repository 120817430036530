import React from 'react';
import { ColorUtils, GetColor, SelectTypes } from 'venn-ui-kit';
import type { CustomSelectProps, SearchMenuItem } from 'venn-components';
import styled, { css } from 'styled-components';

const SelectMenu = SelectTypes.components.Menu;

const Menu = (props: SelectTypes.MenuProps<SearchMenuItem>) => {
  const { columns, fixedMenuWidth, menuStyles } = props.selectProps as CustomSelectProps;
  return (
    <SelectMenu {...props}>
      <StyledMenu style={menuStyles} columns={columns.length} fixedMenuWidth={fixedMenuWidth}>
        {props.children}
      </StyledMenu>
    </SelectMenu>
  );
};

export default Menu;

const StyledMenu = styled.div<{ fixedMenuWidth?: boolean; columns: number }>`
  box-shadow: 0 12px 24px ${ColorUtils.hex2rgbaFrom(GetColor.Black, 0.2)};
  border-radius: 2px;
  background-color: white;
  ${(props) =>
    props.fixedMenuWidth &&
    css`
      min-width: ${550 + 50 * props.columns}px;
    `}
`;
