/*
 * Venn Platform API 1.0.0 Operations for "factorlenses"
 * Automatically generated file - do not modify!
 */

import type {
  AnalysisFactorLensRequest,
  FactorAlert,
  FactorAnalysis,
  FactorHistoryPerformance,
  FactorLensWithReturns,
  FrequencyEnum,
  LensPerformanceRequest,
  NotableFactorPeriod,
  SimpleFactorPerformance,
} from '../types';
import type { OperationResult } from '../../utils';
import { buildQuery, buildRequestInit, extractError, retryGeneratedFetch } from '../../utils';

/** Analysis the result of factor contribution as the date of factor lens */
export async function analysisFactorLensAsOfDate(
  body?: Partial<AnalysisFactorLensRequest>,
  signal?: AbortSignal,
): Promise<OperationResult<FactorAnalysis>> {
  const endpoint = '/api/factorlenses/analysis';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: FactorAnalysis = (await response.json()) as FactorAnalysis;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get a factor periods by ID */
export async function getNotableFactorLensPeriodsById(
  id: number,
  signal?: AbortSignal,
): Promise<OperationResult<NotableFactorPeriod[]>> {
  const endpoint = `/api/factorlenses/${encodeURIComponent(id)}/periods`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: NotableFactorPeriod[] = (await response.json()) as NotableFactorPeriod[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get a zip file of all factor returns across all lenses */
export async function getFactorLensExport(signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = '/api/factorlenses/export';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get notable factor periods for user */
export async function getNotableFactorLensPeriods(
  signal?: AbortSignal,
): Promise<OperationResult<NotableFactorPeriod[]>> {
  const endpoint = '/api/factorlenses/periods';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: NotableFactorPeriod[] = (await response.json()) as NotableFactorPeriod[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get the list of factor alerts for a specific user on a given date */
export async function getUserFactorAlerts(
  factorLensId?: number,
  asOfDate?: number,
  signal?: AbortSignal,
): Promise<OperationResult<FactorAlert[]>> {
  const endpoint = `/api/factorlenses/alerts/userAlerts${buildQuery({ factorLensId, asOfDate })}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: FactorAlert[] = (await response.json()) as FactorAlert[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Gets a factor lens by ID */
export async function getFactorLens(id: number, signal?: AbortSignal): Promise<OperationResult<FactorLensWithReturns>> {
  const endpoint = `/api/factorlenses/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: FactorLensWithReturns = (await response.json()) as FactorLensWithReturns;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Gets all factor lenses as an admin */
export async function getAllFactorLenses(
  showReturns?: boolean,
  signal?: AbortSignal,
): Promise<OperationResult<FactorLensWithReturns[]>> {
  const endpoint = `/api/factorlenses/all${buildQuery({ showReturns })}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: FactorLensWithReturns[] = (await response.json()) as FactorLensWithReturns[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Gets all factor lenses in given user context */
export async function getAllFactorLensesForUser(
  signal?: AbortSignal,
): Promise<OperationResult<FactorLensWithReturns[]>> {
  const endpoint = '/api/factorlenses';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: FactorLensWithReturns[] = (await response.json()) as FactorLensWithReturns[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Gets all factor lenses names and ids in given user context */
export async function getAllFactorLensesList(signal?: AbortSignal): Promise<OperationResult<FactorLensWithReturns[]>> {
  const endpoint = '/api/factorlenses/options';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: FactorLensWithReturns[] = (await response.json()) as FactorLensWithReturns[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Gets returns for the default factor lens */
export async function getDefaultFactorPerformance(
  factorId?: number,
  frequency?: FrequencyEnum,
  signal?: AbortSignal,
): Promise<OperationResult<SimpleFactorPerformance>> {
  const endpoint = `/api/factorlenses/performance${buildQuery({ factorId, frequency })}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: SimpleFactorPerformance = (await response.json()) as SimpleFactorPerformance;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Gets the factor performance with a comparable asset */
export async function getFactorLensPerformance(
  id: number,
  overlapping?: boolean,
  body?: Partial<LensPerformanceRequest>,
  signal?: AbortSignal,
): Promise<OperationResult<FactorHistoryPerformance>> {
  const endpoint = `/api/factorlenses/${encodeURIComponent(id)}/performance${buildQuery({ overlapping })}`;
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await retryGeneratedFetch('POST', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: FactorHistoryPerformance = (await response.json()) as FactorHistoryPerformance;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Refreshes all caches for factor lens */
export async function refreshCache(signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = '/api/factorlenses/update';
  const requestInit = buildRequestInit({ method: 'POST', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Run backtest on all factor lenses starting from factor availability (currently 2002 */
export async function backtestFactorAlerts(signal?: AbortSignal): Promise<OperationResult<FactorAlert[]>> {
  const endpoint = '/api/factorlenses/alerts/backtest';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: FactorAlert[] = (await response.json()) as FactorAlert[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Sends a factor digest to the account id */
export async function sendFactorDigestEmail(id: number, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/factorlenses/digest/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({ method: 'POST', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
