import type { MetricFilter } from 'venn-api';
import { compact } from 'lodash';
import { LibraryFilterSectionKey } from 'venn-utils';
import type { AdvancedQuery, FiltersState, PrivatesSearchParams, SearchParams } from './commonTypes';

const toAdvancedQueries = (metricFilters: MetricFilter[]): AdvancedQuery[] =>
  metricFilters.flatMap(({ minimum, maximum, metric, timePeriod }) => {
    return compact([
      minimum !== null &&
        minimum !== undefined && {
          // cannot use minimum && because minimum=0 should not be filtered
          timePeriod,
          operator: 'geq',
          metric,
          value: minimum,
        },
      maximum !== null &&
        maximum !== undefined && {
          // cannot use maximum && because maximum=0 should not be filtered
          timePeriod,
          operator: 'leq',
          metric,
          value: maximum,
        },
    ]);
  });
/**
 * Helper function to extract FilterState from the SearchParams object
 */
export const getFiltersStateFromSearchParams = (params: SearchParams): FiltersState => {
  return {
    ...params,
    advancedQueries: toAdvancedQueries(params.metrics),
    quickFilters: params[LibraryFilterSectionKey.FILTERS],
  };
};
/**
 * Helper function to extract FilterState from the PrivatesSearchParams object
 */
export const getFiltersStateFromPrivatesSearchParams = (params: PrivatesSearchParams): FiltersState => {
  return {
    ...params,
    assetTypes: [],
    currency: [],
    dataSource: [],
    morningstarCategories: [],
    tags: [],
    advancedQueries: [],
    quickFilters: params[LibraryFilterSectionKey.FILTERS],
  };
};
