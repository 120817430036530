import React from 'react';
import styled from 'styled-components';
import { Button } from 'venn-ui-kit';

interface AddNewConstraintProps {
  onClick: () => void;
  className?: string;
  disabled?: boolean;
}

const AddNewConstraint = ({ onClick, className, disabled }: AddNewConstraintProps) => (
  <StyledButton
    dominant
    iconPrefix="far"
    icon="plus-circle"
    onClick={onClick}
    className={`${className} qa-add-constraint-btn`}
    disabled={disabled}
  >
    Add new constraint
  </StyledButton>
);

const StyledButton = styled(Button)`
  max-width: 205px;
`;

export default AddNewConstraint;
