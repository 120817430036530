import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import { Label } from 'venn-ui-kit';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 10px;
`;

const InvestmentLabel = styled(Label)`
  margin-left: 10px;
`;

const BenchmarksLabels: FC<React.PropsWithChildren<unknown>> = () => (
  <Wrapper>
    <div>
      <Label>Weight (%)</Label>
      <InvestmentLabel>Investment</InvestmentLabel>
    </div>
    <Label>Over / Underperformance (%)</Label>
  </Wrapper>
);

export default BenchmarksLabels;
