import profile from './profile';
import type { Portfolio } from 'venn-api';

export const mockEmptyPortfolio = {
  id: 1,
  name: 'Current Portfolio Cool Test',
  baselineActive: true,
  owner: profile,
  allocation: 153,
  nodeFactorLensId: 2,
  updated: 1526309984642,
  children: [],
  compare: [],
  order: 1,
  master: false,
  draft: false,
  locked: false,
  editable: true,
  frequencyId: 1,
  messages: [],
  periodStart: 1325289599999,
  periodEnd: 1501631999999,
  rebalanceStrategy: 'QUARTERLY',
  version: 897867,
} as unknown as Portfolio;

export const mockSingleFundPortfolio = {
  ...mockEmptyPortfolio,
  children: [
    {
      id: 14557,
      name: 'iShares US Credit Bond ETF',
      fund: {
        id: 'a153b80b-433a-4a30-b7b8-ad2edec8bf32',
        name: 'iShares US Credit Bond ETF',
        fundSourceType: {
          id: 1,
          name: 'Fund',
        },
        startRange: 1301356799999,
        endRange: 1501631999999,
        currency: 'USD',
        userUploaded: false,
      },
      baselineActive: true,
      owner: profile,
      allocation: 51,
      nodeFactorLensId: 2,
      updated: 1526309984642,
      children: [],
      compare: [],
      order: 4,
      master: true,
      draft: false,
      locked: false,
      editable: true,
      frequencyId: 1,
      messages: [],
      periodStart: 1301356799999,
      periodEnd: 1501631999999,
      rebalanceStrategy: 'QUARTERLY',
    },
  ],
} as unknown as Portfolio;

const portfolio = {
  ...mockEmptyPortfolio,
  children: [
    {
      id: 1248,
      name: 'test',
      baselineActive: true,
      owner: profile,
      allocation: 102,
      nodeFactorLensId: 2,
      updated: 1526309984642,
      children: [
        {
          id: 1359,
          name: 'new strategy',
          baselineActive: true,
          owner: profile,
          allocation: 102,
          nodeFactorLensId: 2,
          updated: 1526309984642,
          children: [
            {
              id: 1909,
              name: 'iShares Agency Bond ETF',
              fund: {
                id: 'bbdf5fc0-20cb-4bfa-8565-b6cab6ef8a90',
                name: 'iShares Agency Bond ETF',
                fundSourceType: {
                  id: 1,
                  name: 'Fund',
                },
                startRange: 1318291199999,
                endRange: 1526342399999,
                currency: 'USD',
                userUploaded: false,
              },
              baselineActive: true,
              owner: profile,
              allocation: 52,
              nodeFactorLensId: 2,
              updated: 1526309984642,
              children: [],
              compare: [
                {
                  name: 'iShares Global Industrials ETF',
                  fundId: 'bf8f8840-064d-4ad6-974b-19a527bb51b7',
                  type: 'INVESTMENT',
                  benchmark: true,
                },
              ],
              order: 4,
              master: true,
              draft: false,
              locked: false,
              editable: true,
              frequencyId: 1,
              messages: [],
              periodStart: 1318291199999,
              periodEnd: 1526342399999,
              rebalanceStrategy: 3,
            },
            {
              id: 1935,
              name: '130310',
              baselineActive: true,
              owner: profile,
              allocation: 50,
              nodeFactorLensId: 2,
              updated: 1526309984642,
              children: [
                {
                  id: 1936,
                  name: '130311',
                  baselineActive: true,
                  owner: profile,
                  allocation: 50,
                  nodeFactorLensId: 2,
                  updated: 1526309984642,
                  children: [
                    {
                      id: 1951,
                      name: 'MSCI Index EUROPE IMI',
                      fund: {
                        id: '1252bd66-4b74-49b4-a34f-dbc18fd49bd9',
                        name: 'MSCI Index EUROPE IMI',
                        fundSourceType: {
                          id: 1,
                          name: 'Fund',
                        },
                        startRange: 1325289599999,
                        endRange: 1526342399999,
                        currency: 'USD',
                        userUploaded: false,
                      },
                      baselineActive: true,
                      owner: profile,
                      allocation: 50,
                      nodeFactorLensId: 2,
                      updated: 1526309984642,
                      children: [],
                      compare: [],
                      order: 7,
                      master: true,
                      draft: false,
                      locked: false,
                      editable: true,
                      frequencyId: 1,
                      messages: [],
                      periodStart: 1325289599999,
                      periodEnd: 1526342399999,
                      rebalanceStrategy: 'QUARTERLY',
                    },
                  ],
                  compare: [],
                  order: 6,
                  master: true,
                  draft: false,
                  locked: false,
                  editable: true,
                  frequencyId: 1,
                  messages: [],
                  periodStart: 1325289599999,
                  periodEnd: 1526342399999,
                  rebalanceStrategy: 'QUARTERLY',
                },
              ],
              compare: [],
              order: 5,
              master: true,
              draft: false,
              locked: false,
              editable: true,
              frequencyId: 1,
              messages: [],
              periodStart: 1325289599999,
              periodEnd: 1526342399999,
              rebalanceStrategy: 'QUARTERLY',
            },
          ],
          compare: [],
          order: 3,
          master: true,
          draft: false,
          locked: false,
          editable: true,
          frequencyId: 1,
          messages: [],
          periodStart: 1325289599999,
          periodEnd: 1526342399999,
          rebalanceStrategy: 'QUARTERLY',
        },
      ],
      compare: [
        {
          name: 'iShares Europe ETF',
          fundId: '199fbe96-b0d5-4a2f-bab8-79069fc037de',
          type: 'INVESTMENT',
          benchmark: true,
        },
      ],
      order: 2,
      master: true,
      draft: false,
      locked: false,
      editable: true,
      frequencyId: 1,
      messages: [],
      periodStart: 1325289599999,
      periodEnd: 1526342399999,
      rebalanceStrategy: 'QUARTERLY',
    },
    {
      id: 1360,
      name: 'another test',
      baselineActive: true,
      owner: profile,
      allocation: 0,
      nodeFactorLensId: 2,
      updated: 1526309984642,
      children: [],
      compare: [
        {
          name: 'iShs Gold Trust -TU',
          fundId: '9de12fb0-2d24-44a9-8cb9-045528758137',
          type: 'INVESTMENT',
          benchmark: true,
        },
      ],
      order: 3,
      master: true,
      draft: false,
      locked: false,
      editable: true,
      frequencyId: 1,
      messages: [],
      rebalanceStrategy: 'QUARTERLY',
    },
    { ...mockSingleFundPortfolio.children[0] },
  ],
  compare: [
    {
      name: 'iShs Gold Trust -TU',
      fundId: '9de12fb0-2d24-44a9-8cb9-045528758137',
      type: 'INVESTMENT',
      benchmark: true,
    },
    {
      name: 'iShares Agency Bond ETF',
      fundId: 'bbdf5fc0-20cb-4bfa-8565-b6cab6ef8a90',
      fundType: {
        id: 1,
        name: 'Fund',
      },
      benchmark: false,
    },
    {
      name: 'iShares MBS ETF',
      fundId: '495198bb-1fd7-46e1-9e87-36e5c885cffb',
      fundType: {
        id: 1,
        name: 'Fund',
      },
      benchmark: false,
    },
    {
      name: 'iShs Gold Trust -TU',
      fundId: '9de12fb0-2d24-44a9-8cb9-045528758137',
      fundType: {
        id: 1,
        name: 'Fund',
      },
      benchmark: false,
    },
    {
      name: 'testingjakub321',
      fundId: 'e100faa9-121a-4a18-b3e6-e04308d756ae',
      fundType: {
        id: 5,
        name: 'Composite Benchmark',
      },
      benchmark: false,
    },
    {
      name: 'anything',
      fundId: '43037715-3b58-4858-a2e2-54098e3f2521',
      fundType: {
        id: 5,
        name: 'Composite Benchmark',
      },
      benchmark: false,
    },
    {
      id: 1909,
      fundId: 'bbdf5fc0-20cb-4bfa-8565-b6cab6ef8a90',
      name: 'iShares Agency Bond ETF',
      fund: {
        id: 'bbdf5fc0-20cb-4bfa-8565-b6cab6ef8a90',
        name: 'iShares Agency Bond ETF',
        fundSourceType: {
          id: 1,
          name: 'Fund',
        },
        startRange: 1318291199999,
        endRange: 1526342399999,
        currency: 'USD',
        userUploaded: false,
      },
      baselineActive: true,
      owner: profile,
      allocation: 52,
      nodeFactorLensId: 2,
      updated: 1526309984642,
      children: [],
      compare: [
        {
          name: 'iShares Global Industrials ETF',
          fundId: 'bf8f8840-064d-4ad6-974b-19a527bb51b7',
          type: 'INVESTMENT',
          benchmark: true,
        },
      ],
      order: 4,
      master: true,
      draft: false,
      locked: false,
      editable: true,
      frequencyId: 1,
      messages: [],
      periodStart: 1318291199999,
      periodEnd: 1526342399999,
      rebalanceStrategy: 'QUARTERLY',
    },
  ],
};

export const OPTIMIZED_MOCK_PORTFOLIO = Object.freeze({
  id: 1,
  name: 'Current Portfolio Cool Test',
  children: [
    {
      id: 1248,
      name: 'test',
      children: [
        {
          id: 1359,
          name: 'new strategy',
          children: [
            {
              id: 1909,
              name: 'iShares Agency Bond ETF',
              children: [],
              fund: {
                id: 'bbdf5fc0-20cb-4bfa-8565-b6cab6ef8a90',
                name: 'iShares Agency Bond ETF',
              },
              allocation: 1,
            },
            {
              id: 1935,
              name: '130310',
              children: [
                {
                  id: 1936,
                  name: '130311',
                  children: [
                    {
                      id: 1951,
                      name: 'MSCI Index EUROPE IMI',
                      children: [],
                      fund: {
                        id: '1252bd66-4b74-49b4-a34f-dbc18fd49bd9',
                        name: 'MSCI Index EUROPE IMI',
                      },
                      allocation: 1,
                    },
                  ],
                  allocation: 1,
                },
              ],
              allocation: 1,
            },
          ],
          allocation: 1,
        },
      ],
      allocation: 1,
    },
    { id: 1360, name: 'another test', children: [], allocation: 0 },
    {
      id: 14557,
      name: 'iShares US Credit Bond ETF',
      children: [],
      fund: {
        id: 'a153b80b-433a-4a30-b7b8-ad2edec8bf32',
        name: 'iShares US Credit Bond ETF',
      },
      allocation: 1,
    },
  ],
  allocation: 1,
});

export default portfolio as Portfolio;
