import RcInput from 'rc-input-number';
import React, { useCallback, useEffect, useState } from 'react';
import 'rc-input-number/assets/index.css';
import styled, { css } from 'styled-components';
import { GetColor } from 'venn-ui-kit';
import { isNil } from 'lodash';

export interface NumberInputProps {
  name?: string;
  className?: string;
  value?: number;
  originalValue?: number;
  precision?: number;
  step?: number;
  onChange?: (value: number) => void;
  min?: number;
  max?: number;
  error?: boolean;
  formatter?: (value: string) => string;
  parser?: (value: string) => string;
  width?: number;
  height?: number;
  /** Label to be used for the aria-label of the input */
  label?: string;
  controlled?: boolean; // If true, the internal value of the input will be modified to `value` prop any time the `value` prop changes
  disabled?: boolean;
}

const NumberInput = ({
  onChange,
  value,
  step = 1,
  controlled,
  max,
  min,
  width = 100,
  height = 26,
  label,
  originalValue,
  ...rest
}: NumberInputProps) => {
  const [internalValue, setInternalValue] = useState<number>(value ?? 0);
  const updateExternalValue = useCallback(() => onChange?.(internalValue), [internalValue, onChange]);

  useEffect(() => {
    if (controlled && !isNil(value)) {
      setInternalValue(value);
    }
  }, [controlled, value]);

  const onInputChange = useCallback(
    (value?: number | string | null) => {
      const parsedValue = Number(value);
      let newValue = Number.isNaN(parsedValue) ? 0 : parsedValue;
      if (max !== undefined) {
        newValue = Math.min(max, newValue);
      }
      if (min !== undefined) {
        newValue = Math.max(min, newValue);
      }

      setInternalValue(newValue);
    },
    [min, max],
  );

  // Call onChange straight away when the +/- buttons are clicked
  // the internal value will already be set by onInputChange
  const onStep = useCallback(
    (value: number) => {
      onChange?.(value);
    },
    [onChange],
  );

  return (
    <StyledInput
      aria-label={label}
      step={step}
      onPressEnter={updateExternalValue}
      onChange={onInputChange}
      // @ts-expect-error legacy code; unknown reason why value is being used and not currentValue
      value={internalValue}
      highlight={!isNil(originalValue) && originalValue !== internalValue}
      onBlur={updateExternalValue}
      max={max}
      min={min}
      onStep={onStep}
      width={width}
      height={height}
      {...rest}
    />
  );
};

export default NumberInput;

const StyledInput = styled(RcInput)<{ error?: boolean; highlight: boolean; width: number; height: number }>`
  input {
    width: ${({ width }) => width}px;
    font-weight: normal;
    ${({ highlight }) =>
      highlight
        ? css`
            color: ${GetColor.HighlightDark};
          `
        : css`
            color: ${GetColor.Black};
          `};
  }

  &&,
  && .rc-input-number-handler-wrap,
  && .rc-input-number-handler {
    border-color: ${({ error }) => (error ? GetColor.Error : GetColor.Grey)};
  }

  &&,
  .rc-input-number {
    height: ${({ height }) => height}px;
  }

  .rc-input-number-handler {
    height: 50%;
  }

  :hover {
    &&,
    && .rc-input-number-handler-wrap,
    && .rc-input-number-handler {
      border-color: ${GetColor.Primary.Main};
    }
  }
`;
