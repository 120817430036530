export const PX_TO_PT = 0.75;
const PT_TO_PX = 1 / PX_TO_PT;

export const pxToPt = (px: number) => px * PX_TO_PT;
export const ptToPx = (pt: number) => pt * PT_TO_PX;

const REM_TO_PX = 12;
const REM_TO_PT = REM_TO_PX * PX_TO_PT;

export const remToPt = (rem: number) => rem * REM_TO_PT;
