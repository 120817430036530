import type { DropMenuCheckboxItem } from 'venn-ui-kit';
import type { CategoryMetric } from './types';

export interface CheckableMetric {
  checked: boolean;
  metric: CategoryMetric;
  tooltipContent?: string;
}

export interface MetricCategory {
  category: string;
  metrics: CheckableMetric[];
}

export const buildMetricCategories = (
  metrics: CategoryMetric[] | undefined,
  selectedMetrics?: string[],
): DropMenuCheckboxItem[] => {
  if (!metrics) {
    return [];
  }

  const nonCategoryItems = metrics
    .filter((m) => !m.category)
    .map((m) => ({
      label: m.name,
      value: m.id,
      checked: m.disabled ? false : !!selectedMetrics?.includes(m.id),
      level: 0,
      disabled: m.disabled,
      tooltipContent: m.tooltipContent,
    }));
  const results: Map<string, CheckableMetric[]> = new Map<string, CheckableMetric[]>();

  metrics.forEach((m) => {
    if (!m.category) {
      return;
    }
    if (!results.has(m.category)) {
      results.set(m.category, []);
    }
    const metricsList = results.get(m.category);
    metricsList!.push({
      checked: !!selectedMetrics?.includes(String(m.id)),
      metric: m,
      tooltipContent: m.tooltipContent,
    });
  });

  const items = Array.from(results.keys()).map((key) => ({
    category: key,
    metrics: results.get(key)!,
  }));
  const categoriesItems = buildFilterItems(items);
  return [...categoriesItems, ...nonCategoryItems];
};

const buildFilterItems = (items: MetricCategory[]): DropMenuCheckboxItem[] =>
  items.reduce<DropMenuCheckboxItem[]>(
    (prev, category) => [
      ...prev,
      {
        label: category.category,
        value: category.category,
        checked: category.metrics.every((factor: CheckableMetric) => factor.checked),
        level: 0,
      },
      ...category.metrics.map((m) => ({
        label: m.metric.name,
        value: m.metric.id,
        checked: m.checked,
        level: 1,
      })),
    ],
    [],
  );
