import type { FC } from 'react';
import React from 'react';
import { GetColor } from 'venn-ui-kit';
import styled from 'styled-components';

export interface CrossHatchPatternProps {
  id: string;
  color?: string;
  size?: number;
}

const CrossHatchPattern: FC<React.PropsWithChildren<CrossHatchPatternProps>> = ({ id, size = 4, color }) => (
  <pattern id={id} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" width={size} height={size}>
    <StyledRect width={(3 * size) / 4} height={size} color={color} />
  </pattern>
);

export default CrossHatchPattern;

const StyledRect = styled.rect<{ color?: string }>`
  fill: ${({ color }) => color || GetColor.LightGrey};
`;
