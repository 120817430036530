import type { History } from 'history';
import { useCallback, useEffect, useState } from 'react';
import { parseValueFromLocation, updateUrlParam, getRecentPivoted, shouldUseRecent } from 'venn-utils';
import { isNil } from 'lodash';

const useIsPivoted = (history: History) => {
  const [isPivoted, setIsPivoted] = useState<boolean>(() => {
    const valueFromLocation = parseValueFromLocation(history.location, 'isPivoted');
    if (isNil(valueFromLocation) && shouldUseRecent(history.location)) {
      return getRecentPivoted();
    }
    return valueFromLocation === 'true' || isNil(valueFromLocation);
  });
  const togglePivoted = useCallback(() => {
    setIsPivoted((prev) => !prev);
    const scrollContainer = document.getElementById('comparison-main');
    window.requestAnimationFrame(() => {
      scrollContainer?.scrollTo({ top: 0 });
    });
  }, []);

  useEffect(() => {
    updateUrlParam(history, 'REPLACE', 'isPivoted', isPivoted ? 'true' : 'false');
  }, [isPivoted, history]);

  return {
    isPivoted: !!isPivoted,
    togglePivoted,
    setIsPivoted,
  };
};

export default useIsPivoted;
