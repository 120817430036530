import { uniqBy } from 'lodash';
import type { PrivateAnalysisSubjectError } from '../components/PrivatesErrorMessages';
import type { CashFlowResponse, PrivatePerformanceTimeSeriesResponse } from 'venn-api';
import type { StudioRequestSubject } from 'venn-state';

export const getMetricDisplayName = (metric: string | undefined) => {
  switch (metric) {
    case 'contributions':
      return 'Contribution';
    case 'distributions':
      return 'Distribution';
    case 'nav':
      return 'NAV';
    case 'net':
      return 'Net Cash Flow';
    default:
      return 'Unknown Metric';
  }
};

export const getAnalysisMetricLevelErrorsAndWarnings = (
  selectedData: CashFlowResponse[] | PrivatePerformanceTimeSeriesResponse[],
  metricNames: (string | undefined)[],
  subjects: StudioRequestSubject[],
  singleSubjectIndex?: number,
) =>
  uniqBy(
    selectedData
      .flatMap((response, subjectIndex) => {
        return singleSubjectIndex === undefined || singleSubjectIndex === subjectIndex
          ? metricNames
              .filter((metric) => !!metric)
              .flatMap((selectedMetric) => {
                return (response.errors?.[selectedMetric!] ?? []).map((error) => {
                  return {
                    subject: subjects[subjectIndex].name,
                    error,
                  } as PrivateAnalysisSubjectError;
                });
              })
          : [];
      })
      .filter((privateAnalysisSubjectError) => privateAnalysisSubjectError.error.severity === 'ERROR'),
    (error) => error?.toString(),
  );
