import type { FC } from 'react';
import React, { useState } from 'react';
import styled from 'styled-components';
import { GetColor } from '../../style/color';
import { ColorUtils } from '../../style/colorUtils';
import { Body1 } from '../typography';
import Button from '../button/Button';
import Icon from '../icon/Icon';

interface AlertProps {
  dismissable?: boolean;
}

const Alert: FC<React.PropsWithChildren<AlertProps>> = ({ children, dismissable }) => {
  const [dismissed, setDismissed] = useState<boolean>(false);
  const dismissAlert = () => setDismissed(true);

  if (dismissed) {
    return null;
  }

  return (
    <Container>
      <Content>
        <Left>
          <Icon type="bell" />
          <Body1>{children}</Body1>
        </Left>
        {dismissable && (
          <Button flat onClick={dismissAlert}>
            OK
          </Button>
        )}
      </Content>
    </Container>
  );
};
export default Alert;

const Container = styled.div`
  width: 100%;
  padding: 12px 20px;
  margin: 0 !important;
  border: 2px solid ${GetColor.HighlightLight};
  background-color: ${ColorUtils.opacifyFrom(GetColor.HighlightLight, 0.2)};
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  color: ${GetColor.Black};
  min-height: 35px;
  i {
    color: ${GetColor.HighlightDark};
    font-size: 20px;
    width: 60px;
    text-align: center;
  }
`;

const Left = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
`;
