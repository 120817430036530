import { selector, selectorFamily } from 'recoil';
import { merge } from 'lodash';
import {
  REPORT_LAB_GRID_SIZE_RATIO_DEFAULT,
  REPORT_LAB_FONT_DEFAULTS,
  STUDIO_DEFAULT_GRID_SIZE_RATIO,
  STUDIO_FONT_DEFAULTS,
  STUDIO_COMPACT_GRID_SIZE_RATIO,
  STUDIO_COMPACT_TABLE_DATA,
} from './constants';
import type { CustomFonts } from './types';
import { isReportState } from '../configuration/view';
import { getOrganizationPrintSettings, type PrintSettings } from 'venn-api';
import { VennQueryClient, pxToPt } from 'venn-utils';
import { type BlockId } from '../types';
import { blockSettings } from '../configuration/blockSettings';
import { studioPrintOrientationType } from '../configuration/print';
import { viewDataGridSizeType } from '../configuration/customViewOptions';

const PRINT_SETTINGS_QUERY_KEY = ['printSettings'];
export const apiPrintSettings = selector<PrintSettings>({
  key: 'printSettings',
  get: async () => {
    const queryClient = VennQueryClient.getInstance();
    await queryClient.invalidateQueries(PRINT_SETTINGS_QUERY_KEY);
    return queryClient
      .fetchQuery(PRINT_SETTINGS_QUERY_KEY, () => getOrganizationPrintSettings())
      .then((response) => response.content);
  },
});

export const printSettings = selector<{ fonts: CustomFonts }>({
  key: 'customFonts',
  get: ({ get }) => {
    const printSettings = get(apiPrintSettings);
    return {
      fonts: merge({}, REPORT_LAB_FONT_DEFAULTS, {
        blockTitle: { fontSizePt: printSettings.blockFontOverrides?.titleFontSize },
        blockSubtitle: { fontSizePt: printSettings.blockFontOverrides?.subtitleFontSize },
        blockHeaderInfo: { fontSizePt: printSettings.blockFontOverrides?.headerFontSize },
        blockTableData: { fontSizePt: printSettings.blockFontOverrides?.tableFontSize },
        blockChartAxis: { fontSizePt: printSettings.blockFontOverrides?.chartAxisFontSize },
        blockChartLegend: { fontSizePt: printSettings.blockFontOverrides?.chartLegendFontSize },
        titlePageDate: { fontSizePt: printSettings.titlePageFontOverrides?.dateFontSize },
        titlePageTitle: { fontSizePt: printSettings.titlePageFontOverrides?.titleFontSize },
        titlePageSubtitle: { fontSizePt: printSettings.titlePageFontOverrides?.subtitleFontSize },
        titlePageContent: { fontSizePt: printSettings.titlePageFontOverrides?.additionalTextFontSize },
        sectionPageName: { fontSizePt: printSettings.sectionPageFontOverrides?.nameFontSize },
        sectionPageTitle: { fontSizePt: printSettings.sectionPageFontOverrides?.titleFontSize },
        sectionPageSubtitle: { fontSizePt: printSettings.sectionPageFontOverrides?.subtitleFontSize },
        sectionPageOrgName: { fontSizePt: printSettings.sectionPageFontOverrides?.orgDisplayNameFontSize },
      }),
    };
  },
});

/** Does not apply any block specific font defaults. See {@link blockFontDefaults} for blocks. */
export const baseFontDefaults = selector<CustomFonts>({
  key: 'fontDefaults',
  get: ({ get }) => {
    const isReport = get(isReportState);
    const printSettingsValue = get(printSettings);
    return isReport ? printSettingsValue.fonts : STUDIO_FONT_DEFAULTS;
  },
});

export const blockFontDefaults = selectorFamily<CustomFonts, BlockId>({
  key: 'fontDefaults',
  get:
    (blockId) =>
    ({ get }) => {
      const isReport = get(isReportState);
      if (isReport) {
        const printSettingsValue = get(printSettings);
        return printSettingsValue.fonts;
      }
      const correlationBlockOverrides =
        get(studioPrintOrientationType) === 'LANDSCAPE' && get(blockSettings(blockId)).customBlockType === 'CORRELATION'
          ? { blockTableData: { fontSizePt: pxToPt(10) } }
          : {};

      const studioCompactBlockOverrides =
        get(viewDataGridSizeType(blockId)) === 'COMPACT'
          ? { blockTableData: { fontSizePt: STUDIO_COMPACT_TABLE_DATA } }
          : {};

      return merge({}, STUDIO_FONT_DEFAULTS, studioCompactBlockOverrides, correlationBlockOverrides);
    },
});

export const newBlockTableSpacingDefault = selectorFamily<number, BlockId>({
  key: 'tableSpacingDefault',
  get:
    (blockId) =>
    ({ get }) => {
      const isReport = get(isReportState);
      if (isReport) {
        return REPORT_LAB_GRID_SIZE_RATIO_DEFAULT;
      }
      const isCompact = get(viewDataGridSizeType(blockId)) === 'COMPACT';
      return isCompact ? STUDIO_COMPACT_GRID_SIZE_RATIO : STUDIO_DEFAULT_GRID_SIZE_RATIO;
    },
});
