import { createContext } from 'react';
import { noop } from 'lodash';
import type { RangeType } from 'venn-ui-kit';
import { RollingPeriodEnum } from '../charts/analysis-charts/types';
import type { QueryParamCompareType } from './comparison-context';
import { SortByOption } from '../configuration';
import type { Scenario } from 'venn-api';
import type { AnalysisConfig } from 'venn-utils';
import { asyncNoop } from 'venn-utils';

export type AttributionView = 'funds' | 'strategies';
export type AttributionSubject = 'subject' | 'category';
export type ReturnsGridFrequency = 'monthly' | 'quarterly';
export type ReturnsGridSubject = 'subject' | 'benchmark' | 'category';

interface TrendChartModes {
  FACTOR_EXPOSURES_TREND: TrendChartMode;
  FACTOR_CONTRIBUTION_TO_RISK_TREND: TrendChartMode;
  FACTOR_CONTRIBUTION_TO_RETURN_TREND: TrendChartMode;
}

type TrendChartMode = 'heatmap' | 'linechart';

export interface RollingPeriodSettings {
  period?: RollingPeriodEnum;
  customMonths?: number;
}

/** Analysis view settings that aren't saved in the URL, and are specific to separate blocks. */
export interface BlockLevelSettings extends FactorTrendRollingPeriods {
  venncast: boolean; // 'Venncast Missing Data' toggle default state in Cumulative Return block
  rollingReturnPeriod: RollingPeriodSettings; // Rolling period of Rolling Return block
  rollingSharpePeriod: RollingPeriodSettings; // Rolling period of Rolling Sharpe block
  rollingVolatilityPeriod: RollingPeriodSettings; // Rolling period of Rolling Volatility block
  rollingBetaPeriod: RollingPeriodSettings;
  rollingBenchmarkCorrelationPeriod: RollingPeriodSettings;
  hypotheticalDrawdownThreshold: number; // Drawdown threshold for Hypothetical Drawdown Periods block
  historicalDrawdownThreshold: number; // Drawdown threshold for Historical Drawdown Periods block
  attributionsView: AttributionView; // Performance Attribution block toggle state
  attributionsSubject: AttributionSubject; // Performance Attribution block selected portfolio
  returnsGridFrequency: ReturnsGridFrequency; // Returns Grid block frequency toggle state
  returnsGridSubject: ReturnsGridSubject; // Returns Grid block selected subject
  residualCorrelation: boolean; // 'Use Residual' toggle default state in Correlations block
  showCorrelationValues: boolean; // Show values in correlation matrix in Correlations block
  factorAnalysisSortBy: SortByOption; // 'Sort by' selector option in Factor Analysis
  scenariosCustomParamId?: string; // CustomParamId used to retrieve scenario list for the view
  isTradesView: boolean; // Trades view in allocation panel
  isPercentageMode: boolean; // Percentage mode in allocation panel
  isCategoryOff: boolean; // Whether the user has explicitly turned OFF the category comparison for investments
  scenarios?: Scenario[]; // List of scenarios for Scenario Analysis block
  selectedNotablePeriods?: number[]; // List of notable period ids to be displayed
  notablePeriodsThreshold: number;
  trendChartModes: TrendChartModes; // Trend charts modes (heatmap or linechart)
}

export interface FactorTrendRollingPeriods {
  rollingFactorExposuresPeriod?: number; // Rolling period of Factor Exposures Trend block
  rollingFactorReturnPeriod?: number; // Rolling period of Factor Contributions to Return Trend block
  rollingFactorRiskPeriod?: number; // Rolling period of Factor Contributions to Risk Trend block
}

/** Analysis view settings that aren't saved in the URL query, including, but not limited to specific block settings. */
export interface NonQueryViewSettings extends BlockLevelSettings {
  templateId?: string; // Selected analysis view template id
  currentViewName?: string;
  /** State passed from previous navigation will override saved view's params */
  reservedState?: URLLevelSettings & NonQueryViewSettings;
}

export const blockLevelDefaultSettings: BlockLevelSettings = {
  venncast: true,
  rollingReturnPeriod: { period: RollingPeriodEnum.ONE_YEAR },
  rollingSharpePeriod: { period: RollingPeriodEnum.ONE_YEAR },
  rollingVolatilityPeriod: { period: RollingPeriodEnum.ONE_YEAR },
  rollingBetaPeriod: { period: RollingPeriodEnum.ONE_YEAR },
  rollingBenchmarkCorrelationPeriod: { period: RollingPeriodEnum.ONE_YEAR },
  hypotheticalDrawdownThreshold: -5.0,
  historicalDrawdownThreshold: -5.0,
  attributionsView: 'funds' as AttributionView,
  attributionsSubject: 'subject' as AttributionSubject,
  returnsGridFrequency: 'monthly' as ReturnsGridFrequency,
  returnsGridSubject: 'subject' as ReturnsGridSubject,
  residualCorrelation: false,
  showCorrelationValues: true,
  factorAnalysisSortBy: SortByOption.SortByDefault,
  scenariosCustomParamId: undefined,
  isTradesView: false,
  isPercentageMode: false,
  isCategoryOff: false,
  scenarios: undefined,
  rollingFactorExposuresPeriod: undefined,
  rollingFactorReturnPeriod: undefined,
  rollingFactorRiskPeriod: undefined,
  selectedNotablePeriods: undefined,
  notablePeriodsThreshold: 5.0,
  trendChartModes: {
    FACTOR_EXPOSURES_TREND: 'heatmap' as TrendChartMode,
    FACTOR_CONTRIBUTION_TO_RISK_TREND: 'heatmap' as TrendChartMode,
    FACTOR_CONTRIBUTION_TO_RETURN_TREND: 'heatmap' as TrendChartMode,
  },
};

export interface URLLevelSettings {
  relative: boolean; // Benchmark-relative analysis
  start?: number; // Manually selected analysis period start timestamp
  end?: number; // Manually selected analysis period end timestamp
  period?: RangeType; // Pre-set analysis period, i.e. ytd, 1yr, etc.
  compare?: QueryParamCompareType; // Comparison subject for portfolios
  compareVersion?: number; // Comparison subject version id
  savedId?: string; // Id of the baseline saved analysis
}

export const urlLevelDefaultSettings = {
  relative: false,
  start: undefined,
  end: undefined,
  period: undefined,
  compare: 'none' as QueryParamCompareType,
  compareVersion: undefined,
  savedId: undefined,
};

export type UpdatableViewSettings = NonQueryViewSettings & URLLevelSettings & { subjectName?: string };
export interface AnalysisViewContextValue extends BlockLevelSettings, URLLevelSettings {
  onSave: (updatedName: string, analysisConfig: AnalysisConfig, ownerContextId?: string) => Promise<void>;
  onSaveAs: (updatedName: string, analysisConfig: AnalysisConfig, ownerContextId?: string) => Promise<void>;
  onRenameSavedView: (updatedName: string) => Promise<void>;
  onDeleteCurrentView: () => void;
  onUpdateAnalysisViewParam: (value: Partial<UpdatableViewSettings>) => void;
  onStartNewAnalysis: () => void;
  hasSavedBaseline: boolean;
  hasUnsavedChanges: boolean;
  currentViewName?: string;
  baselineViewName?: string;
  noAccessModifiedView: boolean;
}

export const analysisViewContextDefaultValue = {
  ...blockLevelDefaultSettings,
  ...urlLevelDefaultSettings,
  onSave: asyncNoop,
  onSaveAs: asyncNoop,
  onRenameSavedView: asyncNoop,
  onDeleteCurrentView: noop,
  onUpdateAnalysisViewParam: noop,
  onStartNewAnalysis: noop,
  hasSavedBaseline: false,
  hasUnsavedChanges: false,
  currentViewName: undefined,
  baselineViewName: undefined,
  noAccessModifiedView: false,
};

export default createContext<AnalysisViewContextValue>(analysisViewContextDefaultValue);
