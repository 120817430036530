import styled, { css } from 'styled-components';
import type { BasicTableColumn, BasicTableProps, StyledTableType } from 'venn-components';
import { BasicTable } from 'venn-components';
import { GetColor } from 'venn-ui-kit';
import { COLUMN_WIDTH, LABEL_COLUMN_WIDTH, SUBJECT_NAME_WIDTH } from '../constants';
import { isNil } from 'lodash';
import React from 'react';

const stickyFirstColumnCss = css`
  min-width: ${SUBJECT_NAME_WIDTH}px;
  width: ${SUBJECT_NAME_WIDTH}px;
  position: absolute;
  margin-left: -${SUBJECT_NAME_WIDTH}px;
  padding: 0;
`;

const ComparisonTable: StyledTableType<{
  isPrinting: boolean;
  fetchingInBackground: boolean;
  flexibleTableLayout?: boolean;
  highlightedColumnIdx?: number;
}> = styled(<T extends BasicTableColumn<K>, K>(props: BasicTableProps<T, K>) => <BasicTable<T, K> {...props} />)<{
  isPrinting: boolean;
  fetchingInBackground: boolean;
  flexibleTableLayout?: boolean;
  highlightedColumnIdx?: number;
}>`
  width: 100%;
  ${({ flexibleTableLayout, isPrinting }) =>
    !flexibleTableLayout ? 'table-layout: fixed; margin: 0;' : !isPrinting ? 'margin-left: 0;' : 'margin-top: 40px;'}
  > thead {
    > tr {
      height: 26px;
      line-height: 26px;
      > th:first-child {
        padding-left: 20px;
        width: ${(props) =>
          props.isPrinting
            ? undefined
            : props.flexibleTableLayout
              ? `${SUBJECT_NAME_WIDTH}px`
              : `${LABEL_COLUMN_WIDTH}px`};

        ${({ flexibleTableLayout }) => (!flexibleTableLayout ? undefined : stickyFirstColumnCss)}
        ${({ flexibleTableLayout }) =>
          flexibleTableLayout &&
          css`
            height: 100%;
            margin-top: -46px;
          `}
        text-align: left;
      }
      > th {
        color: ${GetColor.DarkGrey};
        width: ${(props) => (props.isPrinting ? undefined : `${COLUMN_WIDTH}px`)};
        ${({ flexibleTableLayout }) => flexibleTableLayout && 'min-width: 64px;'}
        ${({ highlightedColumnIdx }) =>
          !isNil(highlightedColumnIdx) &&
          css`
            :nth-child(${highlightedColumnIdx + 2}) {
              background-color: ${GetColor.WhiteGrey};
            }
          `}
        padding: 10px;
        @media print {
          overflow-x: hidden;
        }
      }
      > th:last-child {
        width: auto;
      }
    }
  }

  > tbody {
    > tr {
      height: 37px;
      border-bottom: 1px solid ${GetColor.PaleGrey};
      :hover {
        background-color: ${GetColor.WhiteGrey};
        @media print {
          background-color: ${GetColor.White};
        }
      }
      > td:first-child {
        padding-left: ${({ flexibleTableLayout }) => (!flexibleTableLayout ? '20px' : '0')};
        width: ${(props) =>
          props.isPrinting
            ? undefined
            : props.flexibleTableLayout
              ? `${SUBJECT_NAME_WIDTH}px`
              : `${LABEL_COLUMN_WIDTH}px`};
        ${({ flexibleTableLayout }) => (!flexibleTableLayout ? undefined : stickyFirstColumnCss)}
        ${({ flexibleTableLayout }) =>
          flexibleTableLayout &&
          css`
            border-bottom: 1px solid ${GetColor.PaleGrey};
          `}
        text-align: left;
      }
      > td {
        max-height: 37px;
        width: ${(props) => (props.isPrinting ? undefined : `${COLUMN_WIDTH}px`)};
        text-align: right;
        padding: 10px;
        ${({ highlightedColumnIdx }) =>
          !isNil(highlightedColumnIdx) &&
          css`
            :nth-child(${highlightedColumnIdx + 2}) {
              background-color: ${GetColor.WhiteGrey};
            }
          `}
      }
      > td:last-child {
        width: auto;
      }
      td:not(:first-child) {
        opacity: ${(props) => (props.fetchingInBackground ? 0.3 : 1)};
        transition: opacity 0.15s ease-out;
      }
    }
  }
`;

export default ComparisonTable;
