import React, { useCallback, useContext } from 'react';
import type { TitleSettings, TitlePageT } from 'venn-components';
import {
  StudioPrintSettingsContext,
  StudioSidePanelContext,
  useAppPrintMode,
  useDebounceToGlobal,
  UserContext,
} from 'venn-components';
import { Img } from '../shared';
import moment from 'moment';
import { CoverPageOrganizationName, CoverPageRow, InlineInput, MultilineInput, useUpdatePage } from './Shared';
import styled from 'styled-components';
import { validate as uuidValidate } from 'uuid';
import { isNil } from 'lodash';
import { useRecoilValue } from 'recoil';
import { DEFAULT_STOCK_PHOTO, pageFonts, viewCoverPageImage } from 'venn-state';
import { STUDIO_COVER_PAGE_DEFAULT_DISCLOSURE, STUDIO_COVER_PAGE_DISCLOSURE_DEFAULT_FONT_SIZE } from 'venn-utils';

const TitlePage = ({ pageNumber, page }: { pageNumber: number; page: TitlePageT }) => {
  const { organizationPrintSettings } = useContext(StudioPrintSettingsContext);
  const { inPrintMode } = useAppPrintMode();
  const { defaultLogoId } = useContext(StudioPrintSettingsContext);
  const { profileSettings } = useContext(UserContext);
  const { onFocusSpecialPage } = useContext(StudioSidePanelContext);
  const customCoverPageImage = useRecoilValue(viewCoverPageImage);
  const updatePage = useUpdatePage(pageNumber);
  const coverPageImageId = uuidValidate(customCoverPageImage) ? customCoverPageImage : DEFAULT_STOCK_PHOTO;
  const coverPageImage = `/api/images/${coverPageImageId}`;

  const onChangeSettings = useCallback(
    (settings: TitleSettings) => {
      updatePage({
        titleSettings: settings,
      });
    },
    [updatePage],
  );

  const [titleSettings, setTitleSettings] = useDebounceToGlobal(page.titleSettings, onChangeSettings);

  const onTitleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const title = e.target.value;
      setTitleSettings((cur) => ({
        ...cur,
        title,
      }));
    },
    [setTitleSettings],
  );

  const onSubtitleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const subTitle = e.target.value;
      setTitleSettings((cur) => ({
        ...cur,
        subTitle,
      }));
    },
    [setTitleSettings],
  );

  const onAdditionalTextChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const additionalText = e.target.value;
      setTitleSettings((cur) => ({
        ...cur,
        additionalText,
      }));
    },
    [setTitleSettings],
  );

  const onCaptionChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const caption = e.target.value;
      setTitleSettings((cur) => ({
        ...cur,
        caption,
      }));
    },
    [setTitleSettings],
  );

  const currentDate = moment(new Date()).format('MMMM Do, YYYY');

  const fontSettings = useFontSettings();

  return (
    <>
      <CoverPageRow height="25%">
        <CoverPageTopRow>
          <div style={{ flex: 1 }}>
            <InlineInput
              $inPrintMode={inPrintMode}
              placeholder={`<Enter caption, i.e. ${currentDate}>`}
              value={isNil(titleSettings.caption) ? currentDate : titleSettings.caption}
              onChange={onCaptionChange}
              onFocus={() => onFocusSpecialPage(pageNumber)}
              style={{
                textAlign: 'right',
                marginRight: '-10px',
                ...fontSettings.caption,
              }}
              data-testid="qa-title-page-date"
            />
            <MultilineInput
              style={fontSettings.title}
              inPrintMode={inPrintMode}
              placeholder="<Enter Document Title>"
              value={titleSettings.title}
              maxRows={3}
              onChange={onTitleChange}
              onFocus={() => onFocusSpecialPage(pageNumber)}
              data-testid="qa-title-page-title"
            />
            <MultilineInput
              style={{
                ...fontSettings.subTitle,
                fontWeight: 'lighter',
              }}
              inPrintMode={inPrintMode}
              placeholder="<Enter SubTitle>"
              value={titleSettings.subTitle}
              maxRows={3}
              onChange={onSubtitleChange}
              onFocus={() => onFocusSpecialPage(pageNumber)}
              data-testid="qa-title-page-subtitle"
            />
          </div>
        </CoverPageTopRow>
      </CoverPageRow>
      <CoverPageRow height="50%">
        <CoverPageLogo src={coverPageImage} alt="logo" data-testid="logo-image" />
      </CoverPageRow>
      <CoverPageRow height="25%">
        <div
          style={{
            margin: '40px',
          }}
        >
          <MultilineInput
            style={{
              ...fontSettings.additionalText,
              fontWeight: 'lighter',
            }}
            inPrintMode={inPrintMode}
            placeholder="<Enter Additional Text>"
            value={titleSettings.additionalText}
            maxRows={3}
            onChange={onAdditionalTextChange}
            onFocus={() => onFocusSpecialPage(pageNumber)}
            data-testid="qa-title-page-additional-text"
          />
        </div>
        <BottomRow>
          <CoverPageDisclosure
            fontSize={
              organizationPrintSettings?.coverPageDisclosureFontSize ?? STUDIO_COVER_PAGE_DISCLOSURE_DEFAULT_FONT_SIZE
            }
            data-testid="qa-title-page-disclosure"
          >
            {organizationPrintSettings?.coverPageDisclosure ?? STUDIO_COVER_PAGE_DEFAULT_DISCLOSURE}
          </CoverPageDisclosure>
          {defaultLogoId ? (
            <ImgWrapper>
              <Img src={`/api/printing/logo/${defaultLogoId}`} />
            </ImgWrapper>
          ) : (
            <CoverPageOrganizationName>{profileSettings?.organization.name}</CoverPageOrganizationName>
          )}
        </BottomRow>
      </CoverPageRow>
    </>
  );
};

export default TitlePage;

function useFontSettings() {
  const dateFont = useRecoilValue(pageFonts.titlePageDate);
  const titleFont = useRecoilValue(pageFonts.titlePageTitle);
  const subTitleFont = useRecoilValue(pageFonts.titlePageSubtitle);
  const contentFont = useRecoilValue(pageFonts.titlePageContent);

  return {
    caption: {
      fontSize: `${dateFont.fontSizePt}pt`,
      lineHeight: dateFont.lineHeight,
    },
    title: {
      fontSize: `${titleFont.fontSizePt}pt`,
      lineHeight: titleFont.lineHeight,
    },
    subTitle: {
      fontSize: `${subTitleFont.fontSizePt}pt`,
      lineHeight: subTitleFont.lineHeight,
    },
    additionalText: {
      fontSize: `${contentFont.fontSizePt}pt`,
      lineHeight: contentFont.lineHeight,
    },
  };
}

const CoverPageTopRow = styled.div`
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  justify-content: flex-end;
  display: flex;
  margin: 0 40px;
`;

const CoverPageLogo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const BottomRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-flow: nowrap;
  overflow: hidden;
  margin-left: 40px;
  margin-right: 40px;
  position: absolute;
  bottom: 40px;
  left: 0px;
  right: 0px;
  padding: 0px;
  align-items: flex-end;
`;
const CoverPageDisclosure = styled.span<{ fontSize: number }>`
  flex: 1;
  white-space: pre-wrap;
  text-align: left;
  font-size: ${({ fontSize }) => fontSize}pt;
  font-weight: 100;
  margin-right: 16px;
`;

export const ImgWrapper = styled.div`
  height: 60px;
  margin: 0px;
  padding: 0px;
`;
