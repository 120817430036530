import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import { ModalContent } from '../../../modal/components/ModalWrapper';

export interface BenchmarkModalLayoutProps {
  header?: React.ReactNode;
  footer?: React.ReactNode;
}

const BenchmarkModalLayout: FC<React.PropsWithChildren<BenchmarkModalLayoutProps>> = ({ children, header, footer }) => {
  return (
    <Container>
      {header}
      <Content>{children}</Content>
      {footer}
    </Container>
  );
};

export default BenchmarkModalLayout;

const Container = styled.div`
  height: 100%;
  min-height: 700px;
  display: flex;
  flex-direction: column;
`;

const Content = styled(ModalContent)`
  height: 100%;
  flex-shrink: 1;
  flex-grow: 1;
`;
