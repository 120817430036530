import type { ReactNode } from 'react';
import React, { Children, Component } from 'react';
import styled, { css } from 'styled-components';
import { GetColor } from '../../../style/color';
import Highlighter from '../../highlighter/Highlighter';
import { assertNotNil } from 'venn-utils';

export interface OptionProps<V> {
  isHovered: boolean;
  onHover: (index: number) => void;
  index: number;
  searchWords?: string;
  /** Will not render if value is undefined. */
  value?: V;
  separator?: boolean;
  className?: string;
  onMouseDown?: (value: V) => void;
  disabled?: boolean;
  children?: ReactNode;
  'data-testid'?: string;
}

type StyleProps = Pick<Partial<OptionProps<unknown>>, 'isHovered' | 'disabled' | 'separator'>;
const StyledOption = styled.div<StyleProps>`
  display: flex;
  flex-shrink: 0;
  align-content: center;
  max-width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  ${({ disabled }) => (disabled ? 'cursor: not-allowed' : 'cursor: pointer')};

  ${(props) =>
    props.isHovered &&
    css`
      background: ${GetColor.PaleGrey};
    `};

  ${(props) => props.separator && `border-bottom: solid 1px ${GetColor.PaleGrey};`};
`;

const StyledOptionDescription = styled.div<StyleProps>`
  ${(props) =>
    props.className ||
    `
    text-overflow: ellipsis;
    white-space: nowrap;

    display: inline-block;
    align-items: center;
    width: 100%;
    height: 36px;
    color: ${GetColor.Black};
    font-size: 14px;
    font-weight: normal;
    line-height: 36px;
  `};
`;

export class Option<V> extends Component<OptionProps<V>> {
  static defaultProps = {
    separator: false,
    searchWords: '',
  };

  onHover = () => {
    this.props.onHover(this.props.index);
  };

  onMouseDown = (event: React.MouseEvent<unknown>) => {
    event.preventDefault();
    const { value, onMouseDown } = this.props;
    // We don't render if value is undefined, so value must not be undefined here
    onMouseDown && onMouseDown(assertNotNil(value));
  };

  /* There are multiple listeners for click events in the parent chain of this component.
   * If the button is disabled we must prevent those listeners from triggering.
   * NOTE: This is necessary because Options are triggered open by onMouseDown events (and not onClick). */
  onClick = (event: React.MouseEvent) => {
    if (this.props.disabled) {
      event.stopPropagation();
    }
  };

  render() {
    const {
      children,
      value,
      separator,
      className = '',
      isHovered,
      searchWords,
      disabled,
      'data-testid': dataTestId,
    } = this.props;

    if (Children.count(children) !== 1 || value === undefined) {
      return null;
    }

    const label =
      searchWords && searchWords.length > 0 && typeof children === 'string' ? (
        <Highlighter searchWords={[searchWords]} text={children} />
      ) : (
        children
      );

    const classNameWithQA = `qa-search-option ${className}`;

    return (
      <StyledOption
        onMouseEnter={this.onHover}
        isHovered={isHovered}
        className={classNameWithQA}
        onMouseDown={this.onMouseDown}
        onClick={this.onClick}
        separator={separator}
        disabled={disabled}
      >
        <StyledOptionDescription data-testid={dataTestId}>{label}</StyledOptionDescription>
      </StyledOption>
    );
  }
}

export default Option;
