import { isNil, partition } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import type { ComputedInvestmentResidual } from 'venn-api';
import { clearInvestmentResidualForecasts } from 'venn-api';
import type { InvestmentForecastRightPanelView } from 'venn-state';
import {
  investmentOverrideSearchQuery,
  investmentOverrideShouldScrollToAddForm,
  remoteInvestmentOverridesList,
  selectedInvestmentOverrideAtom,
  selectedInvestmentOverrideAtomSyncedWithOverrideType,
} from 'venn-state';
import {
  Body1,
  BodyEmphasis,
  Flexbox,
  getAppTitle,
  GetColor,
  Headline3,
  Loading,
  Notifications,
  NotificationType,
  Tooltip,
} from 'venn-ui-kit';
import { analyticsService, logExceptionIntoSentry, useHasFF } from 'venn-utils';
import SearchBar from '../../../search-bar/SearchBar';
import { useRefreshInvestmentOverridesList } from '../hooks/useRefreshInvestmentOverridesList';
import TestClassNames from '../TestClassNames';
import { ResidualForecastOverridePanel } from '../views/ResidualForecastOverridePanel';
import EmptyState from './EmptyState';
import ForecastButton from './ForecastButton';
import ForecastsListItem from './ForecastsListItem';
import InvestmentForecastOverrideForm from './InvestmentForecastOverrideForm';
import InvestmentOverridesContainerFooter, {
  makeDefaultInvestmentDataFromForecast,
} from './InvestmentOverridesContainerFooter';
import NewResidualOverridesEditActions from './NewResidualOverridesEditActions';
import { startsWithFilter } from './shared';

interface ResidualOverridesContainerProps {
  onInvestmentForecastUpdated?: (fundId?: string) => void;
  isReadOnly: boolean;
  investmentForecastsSortFirst?: string[];
  setRightPanelView: (view: InvestmentForecastRightPanelView) => void;
}

const reorderOverrides = (
  overrides: ComputedInvestmentResidual[],
  investmentForecastsSortFirst: string[] | undefined,
) => {
  if (!investmentForecastsSortFirst) {
    return overrides;
  }
  const investmentForecastsCopy = [...overrides];
  investmentForecastsCopy.sort((a, b) => a.fundName.localeCompare(b.fundName));
  /* Pick out investments which have overrides and are present in the sortFirst list.
   * Display those investments at the top. */
  const shouldSortFirst = (item: ComputedInvestmentResidual) => investmentForecastsSortFirst?.includes(item.fundId);
  const [preList, postList] = partition(investmentForecastsCopy, shouldSortFirst);
  return [...preList, ...postList];
};

/** Fixed height for each row element */
const ROW_HEIGHT = 140;

const InvestmentOverridesContainerInner = ({
  isReadOnly,
  investmentForecastsSortFirst,
  onInvestmentForecastUpdated,
  setRightPanelView,
  searchQuery,
  setIsEmptyOverrides,
}: {
  searchQuery: string;
  setIsEmptyOverrides: (empty: boolean) => void;
} & ResidualOverridesContainerProps) => {
  const hasRedesignFF = useHasFF('forecast_panel_redesign_ff');
  /* Persist override deletions onto the backend */
  const onDelete = async (forecastOverride: ComputedInvestmentResidual) => {
    const toastId = Notifications.notify('Deleting...', NotificationType.LOADING);
    try {
      await clearInvestmentResidualForecasts(forecastOverride.fundId);
      analyticsService.deletedInvestmentOverride({
        investmentId: forecastOverride.fundId,
      });
      Notifications.notifyUpdate(toastId, 'Investment Override deleted', NotificationType.SUCCESS);
      onInvestmentForecastUpdated?.(forecastOverride.fundId);
      refreshInvestmentOverridesList();
    } catch (e) {
      logExceptionIntoSentry(e);
      Notifications.notifyUpdate(toastId, 'Failed to delete Investment Override', NotificationType.ERROR);
    }
  };

  const setSelectedOverride = useSetRecoilState(selectedInvestmentOverrideAtomSyncedWithOverrideType('EDIT'));
  const remoteResidualOverrides = useRecoilValue(remoteInvestmentOverridesList);
  const refreshInvestmentOverridesList = useRefreshInvestmentOverridesList();
  const investmentOverrides = reorderOverrides(remoteResidualOverrides, investmentForecastsSortFirst);

  const filteredForecastOverrides =
    searchQuery && investmentOverrides
      ? investmentOverrides.filter((residual) => {
          return startsWithFilter(residual.fundName, searchQuery);
        })
      : investmentOverrides;

  const isEmptyOverrides = !investmentOverrides || investmentOverrides.length === 0;
  const isEmptyFilteredlist = !filteredForecastOverrides || filteredForecastOverrides.length === 0;
  useEffect(() => setIsEmptyOverrides(isEmptyOverrides), [isEmptyOverrides, setIsEmptyOverrides]);

  const renderedFilteredForecasts = () => {
    return (
      filteredForecastOverrides &&
      filteredForecastOverrides.map((forecastOverride: ComputedInvestmentResidual) => (
        <ForecastsListItem
          isReadOnly={isReadOnly}
          key={`${forecastOverride.fundId}-display`}
          forecast={forecastOverride}
          forecastName={forecastOverride.fundName}
          organizationName={forecastOverride.workspaceName}
          userName={forecastOverride.userName}
          height={ROW_HEIGHT}
          maxWidth={440}
          overrideType={!isNil(forecastOverride?.overriddenResidual) ? 'RESIDUAL' : 'RETURN'}
          customFooter={
            <InvestmentOverridesContainerFooter
              investmentData={makeDefaultInvestmentDataFromForecast(forecastOverride, hasRedesignFF)}
            >
              {!hasRedesignFF ? (
                <InvestmentType>
                  {!isNil(forecastOverride?.overriddenResidual) ? 'Residual Override' : 'Return Override'}
                </InvestmentType>
              ) : null}
            </InvestmentOverridesContainerFooter>
          }
          customAction={
            isReadOnly ? undefined : (
              <NewResidualOverridesEditActions
                onDelete={() => onDelete(forecastOverride)}
                onEdit={() => {
                  setSelectedOverride(forecastOverride);
                  !hasRedesignFF &&
                    setRightPanelView({
                      type: 'InvestmentOverrideEditor',
                    });
                  analyticsService.ctaClicked({
                    purpose: 'Edit existing investment override',
                    text: 'Edit Override',
                  });
                }}
              />
            )
          }
        />
      ))
    );
  };

  return (
    <>
      <section className={TestClassNames.ForecastsTabResiduals}>
        {isEmptyOverrides ? (
          <EmptyState title="No Investment Overrides yet" subtitle="All Investment Overrides will appear here." />
        ) : (
          isEmptyFilteredlist && (
            <EmptyState
              title="No Investment Overrides found"
              subtitle={`Add a New Investment Override for "${searchQuery}"`}
            />
          )
        )}
        <Flexbox direction="column" gap={hasRedesignFF ? 18 : 0}>
          {renderedFilteredForecasts()}
        </Flexbox>
      </section>
    </>
  );
};

const Description = () => {
  const hasRedesignFF = useHasFF('forecast_panel_redesign_ff');
  if (!hasRedesignFF) {
    return (
      <Body1>
        {`${getAppTitle()} allows users to override the forecasted residual return or forecasted total return for any investment.\n` +
          ' The overrides are mutually exclusive, and only one can be selected at a time.'}
      </Body1>
    );
  }
  return (
    <Body1>
      {`${getAppTitle()} allows users to override either the forecast total return or the forecast residual return for any investment.
    The overrides are mutually exclusive, and`}
      <i> only one can be selected at a time.</i>
    </Body1>
  );
};

const InvestmentOverridesContainer = ({
  isReadOnly,
  investmentForecastsSortFirst,
  onInvestmentForecastUpdated,
  setRightPanelView,
}: ResidualOverridesContainerProps) => {
  const hasForceResidualForecastZero = useHasFF('residual_forecast_zero_ff');
  const hasRedesignFF = useHasFF('forecast_panel_redesign_ff');
  const resetSelectedOverride = useResetRecoilState(selectedInvestmentOverrideAtom('EDIT'));
  const [isEmptyOverrides, setIsEmptyOverrides] = useState(true);
  /* Search query used to filter down which residual overrides to display. */
  const [searchQuery, setSearchQuery] = useRecoilState(investmentOverrideSearchQuery);
  const [shouldScrollToAddForm, setShouldScrollToAddForm] = useRecoilState(investmentOverrideShouldScrollToAddForm);
  const addFormRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (shouldScrollToAddForm) {
      addFormRef.current?.scrollIntoView({ block: 'end' });
      setShouldScrollToAddForm(false);
    }
  }, [setShouldScrollToAddForm, shouldScrollToAddForm]);

  useEffect(() => {
    return () => {
      if (hasRedesignFF) {
        resetSelectedOverride();
      }
    };
  }, [hasRedesignFF, resetSelectedOverride]);

  return (
    <>
      <ForecastsContainer>
        {!hasRedesignFF && <SubHeading>Investment Overrides</SubHeading>}
        <Description />
        {!hasRedesignFF && (
          <Wrapper>
            <Tooltip isHidden={!isReadOnly} content={"You don't have permission to create new investment overrides"}>
              <ForecastButton
                onClick={() => {
                  setRightPanelView({ type: 'InvestmentOverrideCreator' });
                  resetSelectedOverride();
                  analyticsService.ctaClicked({
                    purpose: 'Create new investment override',
                    text: 'New Override',
                  });
                }}
                disabled={isReadOnly}
              >
                New Override
              </ForecastButton>
            </Tooltip>
          </Wrapper>
        )}
      </ForecastsContainer>
      {hasRedesignFF && (
        <ListInfoWrapper>
          <div ref={addFormRef}>
            <InvestmentForecastOverrideForm isCreating isReadOnly={isReadOnly} />
          </div>
          {hasForceResidualForecastZero && <ResidualForecastOverridePanel isReadOnly={isReadOnly} />}
        </ListInfoWrapper>
      )}

      <ListInfoWrapper>
        {!isEmptyOverrides && (
          <SearchBox hasRedesignFF={hasRedesignFF}>
            <SearchBar
              disableAutofocus={hasRedesignFF}
              value={searchQuery}
              placeholder="Search for an Investment Override"
              onChange={setSearchQuery}
            />
          </SearchBox>
        )}
      </ListInfoWrapper>
      <React.Suspense
        fallback={
          <LoadingWrapper>
            <Loading title="Overrides are loading..." />
          </LoadingWrapper>
        }
      >
        <InvestmentOverridesContainerInner
          isReadOnly={isReadOnly}
          investmentForecastsSortFirst={investmentForecastsSortFirst}
          onInvestmentForecastUpdated={onInvestmentForecastUpdated}
          setRightPanelView={setRightPanelView}
          searchQuery={searchQuery}
          setIsEmptyOverrides={setIsEmptyOverrides}
        />
      </React.Suspense>
    </>
  );
};

export default React.memo(InvestmentOverridesContainer);

const SearchBox = styled.div<{
  hasRedesignFF: boolean;
}>`
  padding: 10px;
  border-bottom: 1px solid ${GetColor.PaleGrey};

  ${({ hasRedesignFF }) => hasRedesignFF && 'padding: 0 0 10px 0; border: none;'}
`;

const ForecastsContainer = styled(Body1)`
  margin-top: 27px;
`;

const SubHeading = styled(Headline3)`
  margin-top: 24px;
`;

const ListInfoWrapper = styled.div`
  justify-content: flex-start;
  padding-left: 0px;
  margin-top: 24px;
`;

const Wrapper = styled.div`
  margin-top: 10px;
`;

const InvestmentType = styled(BodyEmphasis)`
  font-weight: 500;
`;

const LoadingWrapper = styled.div`
  margin-bottom: 30px;
  overflow: hidden;
`;
