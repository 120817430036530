import type { CompositeBenchmark, CompositeBenchmarkChild, Fund } from 'venn-api';
import type { Option as AllocationTypeaheadOption } from '../../../fund-typeahead/types';

export interface BenchmarksCompositeState {
  name: string;
  children: BenchmarkCompositeChild[];
  existingComposite?: {
    id: number;
    fundId: string;
    originalName: string;
  };
}

export interface BenchmarkCompositeChild {
  scalingSign: ScalingSign;
  scalingValue: string;
  weight: string;
  fund?: AllocationTypeaheadOption;
}

export enum ScalingSign {
  Plus = 'PLUS',
  Minus = 'MINUS',
}

export const DEFAULT_COMPOSITE_CHILD: BenchmarkCompositeChild = {
  scalingSign: ScalingSign.Plus,
  scalingValue: '',
  weight: '',
};

const MULTIPLIERS = {
  [ScalingSign.Minus]: -1,
  [ScalingSign.Plus]: 1,
};

export const benchmarkCompositeChildToCompositeBenchmarkChild = (
  benchmark: BenchmarkCompositeChild,
): CompositeBenchmarkChild => ({
  weight: Number(benchmark.weight) / 100,
  fundId: benchmark.fund!.value as string,
  annualizedOffset: getAnnualizedOffset(benchmark) / 100,
});

export function createBenchmarkCompositeChild(
  child: CompositeBenchmarkChild,
  fund: Fund | null,
): BenchmarkCompositeChild {
  return {
    weight: (child.weight * 100).toFixed(1),
    scalingSign: child.annualizedOffset < 0 ? ScalingSign.Minus : ScalingSign.Plus,
    scalingValue: (Math.abs(child.annualizedOffset) * 100).toFixed(1),
    fund: fund
      ? {
          label: fund.name,
          value: fund.id,
          fund,
          children: null,
          type: '',
        }
      : undefined,
  };
}

export function createPayload(state: BenchmarksCompositeState): Partial<CompositeBenchmark> {
  const compositeBenchmark: Partial<CompositeBenchmark> = {
    name: state.name,
    children: state.children
      .filter((benchmark) => benchmark.fund)
      .map(benchmarkCompositeChildToCompositeBenchmarkChild),
  };
  if (state.existingComposite) {
    compositeBenchmark.id = state.existingComposite.id;
    compositeBenchmark.fundId = state.existingComposite.fundId;
  }
  return compositeBenchmark;
}

const getAnnualizedOffset = (benchmark: BenchmarkCompositeChild): number => {
  const { scalingSign, scalingValue } = benchmark;
  const multiplier = getMultiplier(scalingSign);
  return multiplier * Number(scalingValue);
};

const getMultiplier = (scalingSign: ScalingSign): number => {
  if (!(scalingSign in MULTIPLIERS)) {
    throw new Error(`Unknown scaling sign: ${scalingSign}`);
  }
  return MULTIPLIERS[scalingSign];
};

export function createState(payload: CompositeBenchmark, funds: Fund[]): BenchmarksCompositeState {
  return {
    name: payload.name,
    existingComposite: {
      id: payload.id,
      fundId: payload.fundId,
      originalName: payload.name,
    },
    children: payload.children.map((child, index) => createBenchmarkCompositeChild(child, funds[index])),
  };
}
