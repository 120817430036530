import { first, isEmpty, isNil } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import type { FundErrorMessages, MessageWithContext, PrivateAnalysisError } from 'venn-api';
import {
  Flexbox,
  GetColor,
  Icon,
  PRIVATE_ASSET_CASH_FLOW_HELP_HREF,
  PRIVATE_ASSET_LAB_FAQ_HREF,
  PRIVATE_PERFORMANCE_HELP_HREF,
  PUBLIC_PRIVATE_ASSET_GROWTH_HREF,
} from 'venn-ui-kit';
import { useBlockId } from '../contexts/BlockIdContext';

import StyledEmptyState from './StyledEmptyState';
import { useRecoilValue } from 'recoil';
import { blockSettings } from 'venn-state';
import type { CustomBlockTypeEnum } from 'venn-utils';
import type { AllSubjectsErrorMessages } from './blocks/public-private';

export interface PrivateAnalysisSubjectError {
  subject: string;
  error: PrivateAnalysisError;
}
type PrivatesErrorMessagesProps = Readonly<{ errorMessages?: FundErrorMessages[] }>;

export const getPrivateHelpCenterLink = (customBlockType: CustomBlockTypeEnum): string => {
  switch (customBlockType) {
    case 'PRIVATE_CASH_FLOW':
      return PRIVATE_ASSET_CASH_FLOW_HELP_HREF;
    case 'PRIVATE_PERFORMANCE_SUMMARY':
    case 'PRIVATE_PERFORMANCE_TIME_SERIES':
      return PRIVATE_PERFORMANCE_HELP_HREF;
    case 'PUBLIC_PRIVATE_ASSET_GROWTH_BREAKDOWN':
    case 'PUBLIC_PRIVATE_ASSET_GROWTH_PERCENTILES':
      return PUBLIC_PRIVATE_ASSET_GROWTH_HREF;
    default:
      return PRIVATE_ASSET_LAB_FAQ_HREF;
  }
};

const splitErrorMessages = (
  errorMessages: FundErrorMessages[],
): [FundErrorMessages | undefined, FundErrorMessages[]] => {
  return [first(errorMessages.filter((e) => isNil(e.fundId))), errorMessages.filter((e) => !isNil(e.fundId))];
};

const ErrorRow = ({ errorMessages }: { errorMessages?: FundErrorMessages }) => {
  if (isNil(errorMessages) || isEmpty(errorMessages.errorMessages)) {
    return null;
  }

  return (
    <div>
      {errorMessages.fundName && <FundName>{errorMessages.fundName}: </FundName>}
      <span>{errorMessages.errorMessages.join('; ')}</span>
    </div>
  );
};

const ErrorRowFromMessagesWithContext = ({ messagesWithContext }: { messagesWithContext: MessageWithContext[] }) => {
  const filteredMessages = messagesWithContext.filter((message) => message.message.type === 'ERROR');
  if (isEmpty(filteredMessages)) {
    return null;
  }
  const subjectName = first(filteredMessages.map((messageWithContext) => messageWithContext.subjectName));

  return (
    <div>
      {subjectName && <FundName>{subjectName}: </FundName>}
      <span>{filteredMessages.map((message) => message.message.text).join('; ')}</span>
    </div>
  );
};

/** TODO: delete this component once privates are migrated to flow-based analysis endpoint
 * The error handling from messagesWithContext is resilient enough to be extended to all analyses */
const PrivatesErrorMessages = ({ errorMessages }: PrivatesErrorMessagesProps) => {
  const blockId = useBlockId();
  const blockSetting = useRecoilValue(blockSettings(blockId));
  const customBlockType = blockSetting.customBlockType;

  if (isNil(errorMessages) || isEmpty(errorMessages)) {
    return null;
  }

  const helpLink = getPrivateHelpCenterLink(customBlockType);

  const [portfolioErrors, fundErrors] = splitErrorMessages(errorMessages);

  return (
    <StyledEmptyState
      header=""
      selectedRefId={blockId}
      message={
        <Flexbox direction="column" rowGap={20}>
          <ErrorTitle>
            <Icon type="exclamation-circle" />
            <span>
              Unable to run analysis. Please review and fix the issue(s) below before retrying or{' '}
              <a href={helpLink} target="_blank" rel="noreferrer">
                click here
              </a>{' '}
              to learn more:
            </span>
          </ErrorTitle>
          <ErrorList>
            <ErrorRow errorMessages={portfolioErrors} />
            {fundErrors.map((errors) => (
              <ErrorRow key={errors.fundId} errorMessages={errors} />
            ))}
          </ErrorList>
        </Flexbox>
      }
    />
  );
};

type PublicPrivateErrorMessagesProps = {
  errorMessages: AllSubjectsErrorMessages;
};

/** TODO: rename this component once this becomes generic analysis handling.
 * There is nothing privates-specific in this component and we can safely use it for all blocks
 * We only need to make sure that proper help center links are returned for other blocks, too */
export const PublicPrivateErrorMessages = ({ errorMessages }: PublicPrivateErrorMessagesProps) => {
  const blockId = useBlockId();
  const blockSetting = useRecoilValue(blockSettings(blockId));
  const customBlockType = blockSetting.customBlockType;

  if (isNil(errorMessages) || isEmpty(errorMessages)) {
    return null;
  }

  const helpLink = getPrivateHelpCenterLink(customBlockType);

  return (
    <StyledEmptyState
      header=""
      selectedRefId={blockId}
      message={
        <Flexbox direction="column" rowGap={20}>
          <ErrorTitle>
            <Icon type="exclamation-circle" />
            <span>
              Unable to run analysis. Please review and fix the issue(s) below before retrying or{' '}
              <a href={helpLink} target="_blank" rel="noreferrer">
                click here
              </a>{' '}
              to learn more:
            </span>
          </ErrorTitle>
          <ErrorList>
            {Object.entries(errorMessages).map((entry) => {
              const [subjectKey, errorMessagesForSubject] = entry;
              return <ErrorRowFromMessagesWithContext key={subjectKey} messagesWithContext={errorMessagesForSubject} />;
            })}
          </ErrorList>
        </Flexbox>
      }
    />
  );
};

const ErrorTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  font-size: 20px;
  text-align: left;
  color: ${GetColor.Black};

  i {
    color: ${GetColor.Error};
  }
`;

const ErrorList = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  text-align: left;
  font-size: 16px;
  margin-left: 30px;
  color: ${GetColor.Black};
`;

const FundName = styled.span`
  font-weight: bold;
`;

export default PrivatesErrorMessages;
