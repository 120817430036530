import React from 'react';
import { EmptyState } from 'venn-components';

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default () => {
  const content = {
    header: 'No inputs yet.',
    message: 'To get started, add a new input.',
  };
  return <EmptyState header={content.header} message={content.message} />;
};
