import React from 'react';
import type { Fund, Portfolio } from 'venn-api';
import { useHistory } from 'react-router-dom';
import EvaluateInPortfolioModal from './EvaluateInPortfolioModal';
import { getPortfolioLabPath } from 'venn-utils';

interface OptimizeInPortfolioModalProps {
  funds: Fund[];
  closeModal: () => void;
}

const OptimizeInPortfolioModal = ({ funds, closeModal }: OptimizeInPortfolioModalProps) => {
  const history = useHistory();

  const onSubmitOptimizeModal = (
    selectedPortfolio: Portfolio | undefined,
    allocation: number | null,
    addToStrategyId?: number,
  ) => {
    if (!selectedPortfolio) {
      return;
    }

    closeModal();

    history.push(
      getPortfolioLabPath(
        selectedPortfolio.id,
        funds.map(({ id }) => id),
        addToStrategyId ?? selectedPortfolio.id,
        allocation ?? undefined,
      ),
    );
  };

  return (
    <EvaluateInPortfolioModal
      headerText="Optimize in a Portfolio"
      subheaderText={`How would you like to optimize your ${
        funds.length === 1 ? 'selected investment' : `${funds.length} selected investments`
      }?`}
      buttonText="OPTIMIZE WITH PORTFOLIO LAB"
      portfolioSelectorText="Select a portfolio:"
      strategySelectorText="Select a strategy:"
      allocationText="Max allocation per investment (optional):"
      onClose={closeModal}
      onSubmit={onSubmitOptimizeModal}
      portfolioLabStyles
    />
  );
};

export default OptimizeInPortfolioModal;
