import React, { useEffect, useMemo, useState } from 'react';
import type { BaseDropMenuProps } from '../types';
import type { SingleCustomOptionMenuItem, SingleCustomOptionMenuProps } from '../menus/SingleCustomOptionMenu';
import SingleCustomOptionMenu from '../menus/SingleCustomOptionMenu';
import { compact } from 'lodash';
import BaseDropMenu from './BaseDropMenu';
import SimpleTrigger from '../triggers/SimpleTrigger';

interface SingleCustomOptionDropMenuProps
  extends BaseDropMenuProps<string>,
    Omit<SingleCustomOptionMenuProps, 'items'> {
  /**
   * Predefined menu options
   */
  items: SingleCustomOptionMenuItem[];
  /**
   * Optional single custom item
   */
  customItem?: SingleCustomOptionMenuItem;
  /**
   * Callback called on each change to the input
   */
  onInputChange: (input: string) => void;
  placeholder?: string;
  menuWidth?: number;
  menuClassName?: string;
  headerComponent?: JSX.Element;
}

const SingleCustomOptionDropMenu = ({
  items,
  customItem,
  selected,
  onSelect,
  openByDefault,
  usePortal,
  menuWidth,
  width,
  height,
  className,
  menuClassName,
  onInputChange,
  placeholder,
  disabled,
  headerComponent,
}: SingleCustomOptionDropMenuProps) => {
  const [search, setSearch] = useState('');

  useEffect(() => onInputChange(search), [search, onInputChange]);

  const filteredItems = useMemo(
    () =>
      compact([customItem ? { ...customItem, isCustom: true } : null, selected?.isCustom ? selected : null, ...items]),
    [customItem, items, selected],
  );

  const triggerProps = {
    placeholder,
    searchable: true,
    search,
    onSearch: setSearch,
    /* override the min-width set in SimpleTrigger */
    style: width ? { minWidth: width } : undefined,
  };

  return (
    <BaseDropMenu
      openByDefault={openByDefault}
      usePortal={usePortal}
      filteredItems={filteredItems}
      selectedItem={selected}
      onChange={onSelect}
      triggerComponent={(expanded, _, onToggle, handleKeyEsc, handleKeyEnter, handleKeyUp, handleKeyDown) => (
        <SimpleTrigger
          className={className}
          disabled={disabled}
          expanded={expanded}
          selection={selected?.label}
          onClick={(open) => {
            setSearch('');
            onToggle(open);
          }}
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
          onKeyEsc={handleKeyEsc}
          onKeyEnter={handleKeyEnter}
          {...triggerProps}
        />
      )}
      menuComponent={(_highlighted, onCollapse) => (
        <SingleCustomOptionMenu
          headerComponent={headerComponent}
          className={menuClassName}
          items={filteredItems}
          selected={selected}
          onSelect={(item) => {
            if (item.value !== selected?.value) {
              onSelect(item);
            }
            onCollapse();
          }}
          width={menuWidth ?? width}
          height={height}
        />
      )}
    />
  );
};

export default React.memo(SingleCustomOptionDropMenu);
