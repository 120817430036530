import type { Portfolio } from 'venn-api';
import { type Snapshot, waitForAll, useRecoilCallback } from 'recoil';
import {
  analysisViewIdState,
  type Subject,
  openAllocatorSubject,
  subjectInputGroups,
  subjectInputGroupSubjects,
  benchmarkInputs,
  benchmarkInputSubject,
  benchmarkInputIsRelative,
  benchmarkInputName,
  requestSubjects,
  subjectInputGroupName,
} from 'venn-state';
import { useCallback } from 'react';
import { analyticsService } from 'venn-utils';

type ReplacePortfolioInViewFunction = (newPortfolio: Portfolio) => void;

export const useReplacePortfolioInView = (): ReplacePortfolioInViewFunction => {
  const replacePortfolio = useRecoilCallback(
    ({ set, snapshot }) =>
      async (newPortfolio: Portfolio) => {
        const allocatorSubject = await snapshot.getPromise(openAllocatorSubject);
        const portfolioIdToReplace = allocatorSubject?.portfolioId;
        if (!portfolioIdToReplace) {
          return;
        }

        const allSubjectGroups = await snapshot.getPromise(subjectInputGroups);

        allSubjectGroups.forEach((group) =>
          set(subjectInputGroupSubjects(group), (current: Subject[]) => {
            const trackChanges = async (snap: Snapshot) => {
              const [viewId, fullSubjects] = await snap.getPromise(
                waitForAll([analysisViewIdState, requestSubjects(current)]),
              );
              const subjects = fullSubjects.map((subject) =>
                subject.portfolio?.id === portfolioIdToReplace
                  ? { type: 'portfolio', name: newPortfolio.name, id: newPortfolio.id }
                  : { type: subject.subjectType.toLowerCase(), name: subject.name, id: subject.id },
              );

              const groupName = await snap.getPromise(subjectInputGroupName(group));

              analyticsService.inputsSubjectGroupModified({
                name: groupName,
                subjects,
                sourcePage: 'STUDIO',
                viewId,
              });
            };

            const hasAnyToSwap = current.some((subject) => subject.portfolioId === portfolioIdToReplace);
            if (hasAnyToSwap) {
              trackChanges(snapshot);
            }

            return current.map((subject) =>
              subject.portfolioId === portfolioIdToReplace ? { portfolioId: newPortfolio.id } : subject,
            );
          }),
        );
      },
    [],
  );

  const replaceCommonBenchmark = useRecoilCallback(
    ({ set, snapshot }) =>
      async (newPortfolio: Portfolio) => {
        const allocatorSubject = await snapshot.getPromise(openAllocatorSubject);
        const portfolioIdToReplace = allocatorSubject?.portfolioId;
        if (!portfolioIdToReplace) {
          return;
        }

        const allBenchmarkGroups = await snapshot.getPromise(benchmarkInputs);
        allBenchmarkGroups.forEach((group) =>
          set(benchmarkInputSubject(group), (current) => {
            if (current?.portfolioId === portfolioIdToReplace) {
              const trackChanges = async (snap: Snapshot) => {
                const [viewId, benchmarkIsRelative, benchmarkGroupName] = await snap.getPromise(
                  waitForAll([analysisViewIdState, benchmarkInputIsRelative(group), benchmarkInputName(group)]),
                );

                analyticsService.inputsBenchmarkGroupModified({
                  name: benchmarkGroupName,
                  benchmark: { id: newPortfolio.id, name: newPortfolio.name },
                  type: 'COMMON',
                  relative: benchmarkIsRelative,
                  sourcePage: 'STUDIO',
                  viewId,
                });
              };
              trackChanges(snapshot);

              return { portfolioId: newPortfolio.id };
            }
            return current;
          }),
        );
      },
    [],
  );

  const replacePortfolioInView = useCallback(
    (newPortfolio: Portfolio) => {
      replacePortfolio(newPortfolio);
      replaceCommonBenchmark(newPortfolio);
    },
    [replacePortfolio, replaceCommonBenchmark],
  );

  return replacePortfolioInView;
};
