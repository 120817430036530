import React from 'react';
import styled from 'styled-components';
import type { BasicTableColumn, BasicTableProps, StyledTableType } from 'venn-components';
import { BasicTable } from 'venn-components';
import { GetColor } from 'venn-ui-kit';

const AttributionTable: StyledTableType<unknown> = styled(
  <T extends BasicTableColumn<K>, K>(props: BasicTableProps<T, K>) => <BasicTable<T, K> {...props} />,
)`
  table-layout: fixed;
  thead {
    tr {
      th {
        width: 80px;
        padding-right: 12px;
      }
      th:first-child {
        width: auto;
        padding-left: 20px;
      }
      th:last-child {
        padding-right: 15px;
      }
    }
    tr:first-child {
      th {
        padding: 0;
        width: 160px;
      }
      th:first-child {
        width: auto;
      }
      th:nth-child(2) {
        width: 80px;
      }
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid ${GetColor.Grey};
      td {
        padding-right: 12px;
        width: 80px;
      }
      td:first-child {
        padding-left: 20px;
        width: auto;
      }
      td:last-child {
        padding-right: 20px;
      }
    }
    tr:hover {
      background-color: ${GetColor.DEPRECATED_DivergingColor.B1};
    }
    tr:last-child {
      border-bottom: none;
    }
  }
`;

export default AttributionTable;
