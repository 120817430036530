import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import { Input } from '../../../../input/Input';
import { TextAlign } from 'venn-ui-kit';

const qaClass = 'qa-percentage';

export interface BenchmarkScalingValueProps {
  value: string;
  onChange(value: string): void;
}

const BenchmarkScalingValue: FC<React.PropsWithChildren<BenchmarkScalingValueProps>> = ({ value, onChange }) => (
  <ScalingValueWrapper className={qaClass}>
    <Input
      textAlign={TextAlign.Right}
      type="number"
      value={value}
      placeholder="0.0"
      onChange={onChange}
      selectOnFocus
    />
  </ScalingValueWrapper>
);

export default BenchmarkScalingValue;

const ScalingValueWrapper = styled.div`
  & > div {
    width: 56px;
    height: 36px;
    margin-right: 10px;
  }
`;
