import styled from 'styled-components';
import { DropMenu, GetColor } from 'venn-ui-kit';

export const IconButton = styled.button`
  i {
    font-size: 12px;
    color: ${GetColor.Grey};
    :hover {
      color: ${GetColor.Primary.Dark};
      cursor: pointer;
    }
  }
`;

export const InputDropMenu = styled(DropMenu)`
  width: 210px;
` as typeof DropMenu;
