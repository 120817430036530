import React, { useCallback, useEffect, useState } from 'react';
import { compact } from 'lodash';
import styled, { css } from 'styled-components';
import AutosizeInput from 'react-input-autosize';
import { GetColor, Subtitle2, StudioButton, Icon } from 'venn-ui-kit';

interface InputRowProps {
  name: string;
  onNameChange: (name: string) => void;
  onEdit: () => void;
  onDelete?: () => void;
  hoverForOnlyName?: boolean;
  readonly?: boolean;
  noHoverStyles?: boolean;
  children: React.ReactNode;
  testid?: string;
  className?: string;
}

export const InputRow = ({
  name,
  onNameChange,
  onEdit,
  onDelete,
  children,
  hoverForOnlyName,
  readonly,
  noHoverStyles,
  testid,
  className,
}: InputRowProps) => {
  const [nameHovered, setNameHovered] = useState(false);
  const [tempName, setTempName] = useState(name);

  useEffect(() => setTempName(name), [name]);
  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setTempName(e.target.value), []);
  const onApply = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // If the new name is valid, use it, otherwise reset the temp name back to the valid current name.
      return e.target.value ? onNameChange(e.target.value) : setTempName(name);
    },
    [name, onNameChange],
  );

  return (
    <Wrapper
      nameHovered={nameHovered}
      hoverForOnlyName={hoverForOnlyName}
      noHoverStyles={noHoverStyles}
      data-testid={testid}
      className={className}
    >
      <Row onMouseEnter={() => setNameHovered(true)} onMouseLeave={() => setNameHovered(false)}>
        {readonly ? (
          <Subtitle2>{name}</Subtitle2>
        ) : (
          <NameInput value={tempName} onChange={onChange} onBlur={onApply} minWidth={64} placeholder="Missing name" />
        )}
        {!readonly && (
          <ButtonContainer>
            <StudioButton data-testid={compact([testid, name, 'edit']).join('-')} size="small" onClick={onEdit}>
              <Icon type="pen" />
            </StudioButton>
            <StudioButton
              data-testid={compact([testid, name, 'delete']).join('-')}
              size="small"
              onClick={onDelete}
              disabled={!onDelete}
            >
              <Icon type="trash" />
            </StudioButton>
          </ButtonContainer>
        )}
      </Row>
      {children}
    </Wrapper>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  gap: 4px;
`;

export const NameInput = styled(AutosizeInput)`
  flex: 1;
  margin: -2px;
  > input {
    max-width: 200px;
    padding: 2px;
    background-color: transparent;
    border: none;
    text-overflow: ellipsis;
    :focus {
      background-color: ${GetColor.White};
    }
    :hover,
    :focus {
      outline: 1px solid ${GetColor.Primary.Main};
      outline-offset: 0;
    }
  }
`;

const Wrapper = styled.div<{ nameHovered: boolean; hoverForOnlyName?: boolean; noHoverStyles?: boolean }>`
  padding: 5px 15px 5px 15px;
  ${({ nameHovered, hoverForOnlyName, noHoverStyles }) =>
    (!hoverForOnlyName || nameHovered) &&
    !noHoverStyles &&
    css`
      :hover {
        background-color: ${GetColor.GreyScale.Grey10};
      }
    `}
  > ul {
    margin-left: -25px;
    margin-right: -15px;
    padding: 0;
    min-height: 10px;
  }
`;

const Row = styled.div<{ hoverForOnlyName?: boolean }>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  height: 30px;
`;
