import React, { useContext } from 'react';
import { Icon, Link as LinkStyle } from 'venn-ui-kit';
import type { AnalysisSubject } from 'venn-utils';
import { itemHasNoReturns } from 'venn-utils';
import type { RangeDebugGroup } from '../../../hooks/useRangesDebug';
import type { ConstrainingInvestment } from '../PortfolioRangesUtils';
import { mapRangeToConstrainingInvestments } from '../PortfolioRangesUtils';
import { EmptyStateContent, FundListItem, FloatRight } from '../components/styled';
import ExpandableList from '../components/ExpandableList';
import RangeDebugContext from '../../../contexts/range-debug-context';
import InvestmentWithInlineActions from '../components/InvestmentWithInlineActions';
import { ManagePortfolioDataTrigger } from '../../../manage-data';

interface ErrorConstrainedFrequencyProps {
  subject: AnalysisSubject;
  rangeDebugGroup?: RangeDebugGroup;
}

/**
 * Should be rendered only on errors with code {@link SupportedErrorCodes.NoFundReturns}.
 * If the analysis subject is a portfolio shows all children with no returns.
 * Otherwise shows the current fund as having no returns.
 */
// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default ({ subject, rangeDebugGroup }: ErrorConstrainedFrequencyProps) => {
  const { onFundDataUpdated, onDeleteFundFromPortfolio } = useContext(RangeDebugContext);

  const portfolioRange = rangeDebugGroup?.primary?.response?.portfolioRange;

  let investmentsWithNoReturns: ConstrainingInvestment[] = [];
  if (portfolioRange) {
    const investments = mapRangeToConstrainingInvestments(portfolioRange);
    investmentsWithNoReturns = investments.filter((investmentRange) =>
      itemHasNoReturns(investmentRange.range.start, investmentRange.range.end),
    );
  }

  return (
    <EmptyStateContent style={{ maxWidth: '100%' }}>
      One or more of the items you are analyzing do not have a return stream.
      <ExpandableList initiallyShown={3}>
        {subject.type === 'portfolio' && investmentsWithNoReturns.length > 0 ? (
          <FundListItem>
            Investments in your portfolio:
            <ExpandableList initiallyShown={3}>
              {investmentsWithNoReturns.map(({ range }: ConstrainingInvestment) => (
                <InvestmentWithInlineActions
                  key={range.id}
                  infoType="returns"
                  subject={subject}
                  fundId={range.id}
                  fundName={range.name}
                  range={range}
                  onFundDataUpdated={onFundDataUpdated}
                  onDeleteFundFromPortfolio={onDeleteFundFromPortfolio}
                />
              ))}
            </ExpandableList>
          </FundListItem>
        ) : (
          subject.type === 'investment' && (
            <InvestmentWithInlineActions
              infoType="returns"
              subject={subject}
              fundId={subject.fund!.id}
              fundName={subject.name}
              label="Current Investment"
              range={{
                start: subject.fund!.startRange,
                end: subject.fund!.endRange,
                numReturns: 0,
                frequency: subject.fund!.returnFrequency,
                id: subject.fund!.id,
                name: subject.name,
              }}
              onFundDataUpdated={onFundDataUpdated}
              onDeleteFundFromPortfolio={onDeleteFundFromPortfolio}
            />
          )
        )}
      </ExpandableList>
      <ManagePortfolioDataTrigger hidden={subject.type !== 'portfolio'}>
        {(openManageDataPage) => (
          <FloatRight>
            <LinkStyle>
              <button type="button" onClick={openManageDataPage}>
                <Icon type="question-circle" /> How is my available analysis period calculated?
              </button>
            </LinkStyle>
          </FloatRight>
        )}
      </ManagePortfolioDataTrigger>
    </EmptyStateContent>
  );
};
