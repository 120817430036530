import React, { useMemo } from 'react';
import SplitButtonDropdownItem from '../split-button-dropdown/SplitButtonDropdownItem';
import styled from 'styled-components';
import type { OptionType } from 'venn-ui-kit';
import { ButtonIcon, TriggerableMenu, ZIndex, Tooltip } from 'venn-ui-kit';

const SPLIT_MENU_ITEM_TRIGGER = 'menu_item_trigger';

interface ButtonDropdownOption {
  className?: string;
  label: string;
  disableDownloadMessage?: string;
  onClick?: (triggerKey: string) => void;
}

export interface DownloadableDropdownProps {
  /** If none of disableDownloadDataMessage and onDownloadData have value,
   * we would skip the download data option. If disableDownloadDataMessage
   * is presented and onDownloadData is not, we will show the disabled
   * download data option with the message to explain why it's disabled.
   * */
  disableDownloadDataMessage?: string;
  onDownloadPNG?: () => void;
  onDownloadData?: () => void;
  onCopyImage?: () => void;
  disabled?: boolean;
  buttonClassName?: string;
  className?: string;
  absolutelyPositioned: boolean;
}

const DownloadableDropdown: React.FC<React.PropsWithChildren<DownloadableDropdownProps>> = ({
  onDownloadData,
  onCopyImage,
  onDownloadPNG,
  buttonClassName,
  className,
  disabled,
  absolutelyPositioned,
  disableDownloadDataMessage,
}) => {
  const mappedOptions: OptionType[] = useMemo(() => {
    const options: ButtonDropdownOption[] = [];
    if (onDownloadData || disableDownloadDataMessage) {
      options.push({
        onClick: onDownloadData,
        label: 'Download Data',
        disableDownloadMessage: disableDownloadDataMessage,
      });
    }
    if (onDownloadPNG) {
      options.push({
        onClick: onDownloadPNG,
        label: 'Download Image',
      });
    }
    if (onCopyImage) {
      options.push({
        onClick: onCopyImage,
        label: 'Copy Image',
      });
    }

    return options
      ? options.map((option: ButtonDropdownOption) => ({
          ...option,
          children: (
            <Tooltip
              isHidden={!!option.onClick || !option.disableDownloadMessage}
              content={option.disableDownloadMessage}
              usePortal
              block
              maxWidth={200}
              key={option.label}
            >
              <SplitButtonDropdownItem
                label={option.label}
                onClick={option.onClick ? () => option.onClick?.(SPLIT_MENU_ITEM_TRIGGER) : undefined}
              />
            </Tooltip>
          ),
        }))
      : [];
  }, [onDownloadData, onDownloadPNG, onCopyImage, disableDownloadDataMessage]);

  return (
    <StyledTriggerableIconMenu
      options={mappedOptions}
      disabled={disabled}
      className={className}
      innerClassName="inner-item-dropdown"
      absolutelyPositioned={absolutelyPositioned}
    >
      <ButtonIcon
        iconType="download"
        tooltip={!disabled && 'Download'}
        border
        className={buttonClassName}
        disabled={disabled}
      />
    </StyledTriggerableIconMenu>
  );
};

const StyledTriggerableIconMenu = styled(TriggerableMenu)<{ absolutelyPositioned: boolean }>`
  ${(props) =>
    props.absolutelyPositioned &&
    `
  position: absolute;
  top: 12px;
  right: 33px;
`}
  .inner-item-dropdown {
    z-index: ${ZIndex.Cover};
    transform: translate3d(-108px, 6px, 0);
    border-radius: 2px 0 0 2px;
    margin-right: 1px;
    min-width: 150px;
    max-width: 150px;
  }
`;

export default DownloadableDropdown;
