import React from 'react';
import { isNil } from 'lodash';
import { Numbers } from 'venn-utils';

const { getNumberShorthand, formatNumber, shortenNumber } = Numbers;

export interface NumericSpanProps {
  children: number | string;
  convertToLocaleFormat?: boolean;
  precision?: number;
  prefix?: string;
  shorten?: boolean;
  suffix?: string;
  className?: string;
}

const NumericSpan: React.FC<React.PropsWithChildren<NumericSpanProps>> = ({
  className,
  prefix,
  suffix,
  convertToLocaleFormat = true,
  precision = 2,
  shorten = false,
  children = 0,
}) => {
  const getFormattedValue = () => {
    const value = children;
    let result: string | number = value as number;

    if (shorten) {
      result = shortenNumber(result);
    }

    result = formatNumber(result, precision);

    if (convertToLocaleFormat) {
      result = result.toLocaleString();
    }

    if (shorten) {
      result = `${result}${getNumberShorthand(value as number)}`;
    }

    return result;
  };

  if (isNil(children)) {
    return <span className={className}>—</span>;
  }

  return (
    <span className={className}>
      {prefix}
      {getFormattedValue()}
      {suffix}
    </span>
  );
};

export default NumericSpan;
