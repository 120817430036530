import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { getAppTitle } from '../../themes';

export interface VennProvidedLogoProps {
  height: number;
}

export const VennProvidedIcon = ({ height }: VennProvidedLogoProps) => {
  const { Images } = useContext(ThemeContext);
  return <img src={Images.vennProvidedLogo} alt={`${getAppTitle()} Created`} height={height} />;
};

export default VennProvidedIcon;
