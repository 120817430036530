import React from 'react';
import { ARCHIVE_TABLE_PADDING, ArchiveTable } from './components/ArchiveTable';
import styled from 'styled-components';
import { getAppTitle, Headline1 } from 'venn-ui-kit';

const ArchivePage = () => {
  return (
    <Content>
      <TableAndAnnotationContainer>
        <Headline1>{`${getAppTitle()}'s Document Archive`}</Headline1>
        <StyledH3>A repository for all final-version reports generated via Report Lab.</StyledH3>
        <ArchiveTable />
      </TableAndAnnotationContainer>
    </Content>
  );
};

const StyledH3 = styled.h3`
  font-size: calc(1rem * 4 / 3);
`;

const Content = styled.div`
  padding: ${ARCHIVE_TABLE_PADDING}px;
  height: 100%;
  width: 100%;
`;

const TableAndAnnotationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
  padding-top: 0;
`;

export default ArchivePage;
