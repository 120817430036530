import React, { useContext } from 'react';
import { PortfolioLabContext } from 'venn-components';
import type { EfficientFrontierCardData } from './EfficientFrontierCardView';
import EfficientFrontierCardView from './EfficientFrontierCardView';

export type EfficientFrontierCardProps = EfficientFrontierCardData;

const EfficientFrontierCardContainer = ({
  current,
  optimized,
  benchmark,
  alternate,
  efficientFrontier,
  summaryLoading,
  summaryError,
}: EfficientFrontierCardProps) => {
  const { selectedSolution, onSelectSolution, factorForecasts } = useContext(PortfolioLabContext);

  return (
    <EfficientFrontierCardView
      current={current}
      optimized={optimized}
      benchmark={benchmark}
      alternate={alternate}
      efficientFrontier={efficientFrontier}
      factorForecasts={factorForecasts}
      summaryLoading={summaryLoading}
      summaryError={summaryError}
      selectedSolution={selectedSolution}
      onSelectSolution={onSelectSolution}
    />
  );
};

export default EfficientFrontierCardContainer;
