import React, { useEffect, useContext, useState, useCallback } from 'react';
import type { PortfolioPolicy } from 'venn-api';
import {
  EmptyState,
  FactorExposureConstraintsManagement,
  PortfolioLabContext,
  ConfirmationModal,
} from 'venn-components';
import { isNil, isEmpty } from 'lodash';
import { GetColor, Icon, Loading, Tooltip } from 'venn-ui-kit';
import styled from 'styled-components';
import type { FactorExposureConstraint } from 'venn-utils';
import { analyticsService, useModal } from 'venn-utils';
import InputsSectionHeaderWithClearAll from './InputsSectionHeaderWithClearAll';
import ConstraintsPolicyStatusFooter from './ConstraintsPolicyStatusFooter';

interface FactorExposureConstraints {
  isSideBarMaximized: boolean;
  isInEditState: boolean;
  setIsInEditState: React.Dispatch<React.SetStateAction<boolean>>;
}

const FactorExposureConstraints = ({
  isSideBarMaximized,
  isInEditState,
  setIsInEditState,
}: FactorExposureConstraints) => {
  const {
    portfolio,
    policy,
    loadingPolicy,
    policyError,
    policyFactorConstraints,
    factorConstraints,
    factorBreakdownData,
    onUpdateFactorConstraints,
    onApplyFactorConstraintToPolicy,
    onOverrideFactorPolicy,
  } = useContext(PortfolioLabContext);
  const [isConfirmationModalOpen, openConfirmationModal, closeConfirmationModal] = useModal();
  const [constraints, setConstraintsState] = useState<FactorExposureConstraint[]>(factorConstraints);
  const [isSavingConstraint, setIsSavingConstraint] = useState(false);

  const setConstraints = useCallback(
    (
      setNewConstraints:
        | FactorExposureConstraint[]
        | ((prevConstraints: FactorExposureConstraint[]) => FactorExposureConstraint[]),
    ) => {
      !isInEditState && setIsInEditState(true);

      if (typeof setNewConstraints === 'function') {
        setConstraintsState((prevState) => setNewConstraints(prevState));
      } else {
        setConstraintsState(setNewConstraints);
      }
    },
    [isInEditState, setIsInEditState],
  );

  const onUpdateFactorConstraint = useCallback(
    (constraint: FactorExposureConstraint) => {
      const updatedConstraints = constraints.filter(
        (item) => item.factorId !== constraint.factorId || item.condition !== constraint.condition,
      );
      setConstraints([...updatedConstraints, constraint]);
    },
    [constraints, setConstraints],
  );

  const onDeleteConstraint = useCallback(
    (constraint: FactorExposureConstraint) => {
      const constraintIdx = constraints.findIndex(
        ({ condition, factorId }) => condition === constraint.condition && factorId === constraint.factorId,
      );
      if (constraintIdx !== -1) {
        setConstraints([...constraints.slice(0, constraintIdx), ...constraints.slice(constraintIdx + 1)]);
      }
    },
    [constraints, setConstraints],
  );

  const onApplyConstraint = useCallback(
    (constraint: FactorExposureConstraint) => {
      onApplyFactorConstraintToPolicy(constraint);
    },
    [onApplyFactorConstraintToPolicy],
  );

  const onUpdateAndTrackConstraints = useCallback(
    (constraints: FactorExposureConstraint[]) => {
      if (!portfolio?.id) {
        return;
      }

      onUpdateFactorConstraints(constraints);
      analyticsService.portfolioLabConstraintsModified({
        originPage: 'Portfolio Lab',
        locationOnPage: 'factor exposure constraints editor',
        constraintType: 'factor',
        factorConstraintsCount: constraints.length,
        constraintsMatchPolicy: getConstraintsMatchPolicy(policy, constraints),
        portfolioId: portfolio.id,
      });
    },
    [onUpdateFactorConstraints, policy, portfolio?.id],
  );

  useEffect(() => {
    if (isSavingConstraint) {
      onUpdateAndTrackConstraints(constraints);
      setIsSavingConstraint(false);
    }
  }, [constraints, isSavingConstraint, onUpdateAndTrackConstraints]);

  const onConstraintsSave = () => {
    if (!portfolio?.id) {
      return;
    }

    setIsInEditState(false);
    setIsSavingConstraint(true);
  };

  const onConstraintsRevert = () => {
    setIsInEditState(false);
    setConstraints(factorConstraints);
  };

  const onApplyToPolicy = () => {
    const newFactoryPolicyConstraints = constraints.map((constraint) => ({
      ...constraint,
      isInPortfolioPolicy: true,
    }));

    onOverrideFactorPolicy(newFactoryPolicyConstraints);
    setConstraintsState(newFactoryPolicyConstraints);
  };

  if (loadingPolicy || isNil(portfolio)) {
    return <Loading title="Loading portfolio policy" />;
  }

  if (policyError || factorBreakdownData.length === 0) {
    return <EmptyState header="Failed to load constraints from portfolio policy" message="Please try again later" />;
  }

  const matchesPolicy = getConstraintsMatchPolicy(policy, constraints);

  return (
    <Container>
      <Content>
        <InputsSectionHeaderWithClearAll
          isSideBarMaximized={isSideBarMaximized}
          text={
            <>
              Your portfolio has {factorBreakdownData.filter((data) => data.significant).length} significant historical
              factor exposures{' '}
              <Tooltip
                usePortal
                content="Statistical significance is based on the factor exposures of your current portfolio using up to the last 3 years of returns"
              >
                <Icon type="info-circle" prefix="far" />
              </Tooltip>
              . Set min and max constraints below.
            </>
          }
          clearAllLabel="Clear All Exposure Constraints"
          onClearAll={() => openConfirmationModal()}
          canClearAll={constraints.length > 0}
        />
        {isConfirmationModalOpen ? (
          <ConfirmationModal
            className="clear-all-constraints-modal"
            header="Clear all constraints?"
            subhead="This action cannot be undone. To save these constraints, save them as your Portfolio Policy."
            proceedLabel="clear all constraints"
            onCancel={closeConfirmationModal}
            onProceed={() => {
              if (isInEditState && isEmpty(factorConstraints)) {
                onConstraintsRevert();
                closeConfirmationModal();

                return;
              }
              setConstraintsState([]);
              onConstraintsSave();
              closeConfirmationModal();
            }}
          />
        ) : null}
        <FactorExposureConstraintsManagement
          portfolio={portfolio}
          factorExposures={factorBreakdownData}
          factorConstraints={constraints}
          onApplyConstraint={onApplyConstraint}
          onUpdateFactorConstraint={onUpdateFactorConstraint}
          onDeleteConstraint={onDeleteConstraint}
        />
      </Content>
      <Relative>
        <ConstraintsPolicyStatusFooter
          portfolio={portfolio}
          matchesPolicy={matchesPolicy}
          isSideBarMaximized={isSideBarMaximized}
          onResetToPolicyConstraints={() => {
            setConstraints(policyFactorConstraints);
            analyticsService.portfolioLabConstraintsModified({
              originPage: 'Portfolio Lab',
              locationOnPage: 'factor exposure constraints editor',
              constraintType: 'factor',
              allocationConstraintsCount: (policy?.constraints ?? []).filter(
                (constraint) => constraint.constraintType === 'FACTOR',
              ).length,
              constraintsMatchPolicy: true,
              portfolioId: portfolio.id,
            });
          }}
          onApplyToPolicy={onApplyToPolicy}
          onConstraintsSave={onConstraintsSave}
          onConstraintsRevert={onConstraintsRevert}
          isInEditState={isInEditState}
          hasConstraintsRevamp
        />
      </Relative>
    </Container>
  );
};

export default FactorExposureConstraints;

const getConstraintsMatchPolicy = (
  policy: PortfolioPolicy | undefined,
  constraints: FactorExposureConstraint[],
): boolean => {
  const hasUnsavedConstraints = constraints.some((constraint) => !constraint.isInPortfolioPolicy);
  const hasDeletedConstraints =
    (policy?.constraints ?? []).filter((constraint) => constraint.constraintType === 'FACTOR').length >
    constraints.length;

  return !hasUnsavedConstraints && !hasDeletedConstraints;
};

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 90px 20px;
`;

const Relative = styled.div`
  position: relative;
  padding-top: 24px;
  border-bottom: 1px solid ${GetColor.Grey};
`;
