import React, { useCallback, useContext, useMemo, useState } from 'react';
import Modal from '../modal/Modal';
import { ChromePicker } from 'react-color';
import type { VennColors } from 'venn-ui-kit';
import { Headline3 } from 'venn-ui-kit';
import styled, { css, ThemeContext } from 'styled-components';
import ModalHeader from '../modal/ModalHeader';
import ModalFooter from '../modal/ModalFooter';
import { ModalContent, ModalSize } from '../modal/components/ModalWrapper';
import { get, setWith } from 'lodash';

interface ColorItem {
  name: string;
  getter: (colors: VennColors) => string;
  setter: (value: string) => void;
}

interface ColorScheme {
  name?: string;
  colors: ColorItem[];
  children: ColorScheme[];
}

const generateHeaders = (
  theme: VennColors,
  setColors: (setter: (colors: VennColors) => VennColors) => void,
  path: string[],
): ColorScheme => {
  const colorKeyValuePairs = Object.keys(theme).map((key) => ({ key, value: theme[key] }));
  const name = path.length > 0 ? path[path.length - 1] : undefined;
  const colors: ColorItem[] = colorKeyValuePairs
    .filter(({ value }) => typeof value !== 'object')
    .map(
      ({ key }): ColorItem => ({
        name: key,
        getter: (c) => get(c, [...path, key]),
        setter: (value) =>
          setColors((currentColors) => {
            return setWith({ ...currentColors }, [...path, key], value, (obj) => ({ ...obj }));
          }),
      }),
    );
  const children = colorKeyValuePairs
    .filter(({ value }) => typeof value === 'object')
    .map(({ key, value }) => generateHeaders(value, setColors, [...path, key]));

  return { name, colors, children };
};

interface ColorPickerProps {
  close: () => void;
}
export const ColorSchemePicker = ({ close }: ColorPickerProps) => {
  const { Colors, setDebugColors } = useContext(ThemeContext);
  const [newColors, setNewColors] = useState(Colors);

  const scheme = useMemo(() => generateHeaders(Colors, setNewColors, []), [Colors]);

  const [selectedColor, setSelectedColor] = useState(scheme.colors[0]);

  const onSave = useCallback(() => {
    setDebugColors(() => newColors);
    close();
  }, [close, newColors, setDebugColors]);

  const selectedValue = selectedColor.getter(newColors);

  const renderScheme = ({ name, colors, children }: ColorScheme) => {
    return (
      <div key={`${name}`} style={{ paddingLeft: '10px' }}>
        {name && <Header>{name}</Header>}
        {colors.map((color) => {
          const value = color.getter(newColors);
          return (
            <div key={name + color.name}>
              <Inline>
                {color.name}:
                <ColorPatch
                  data-testid={color.name}
                  color={value}
                  onClick={() => setSelectedColor(color)}
                  selected={value === selectedValue}
                />
              </Inline>
            </div>
          );
        })}
        {children.map((child) => renderScheme(child))}
      </div>
    );
  };

  return (
    <Modal size={ModalSize.Large} onClose={close}>
      <ModalHeader>Color Scheme Customizer</ModalHeader>
      <ModalContent>
        <Wrapper>
          <ColorsWrapper>{renderScheme(scheme)}</ColorsWrapper>
          <PickerWrapper>
            <ChromePicker color={selectedValue} onChangeComplete={(c) => selectedColor.setter(c.hex)} />
          </PickerWrapper>
        </Wrapper>
      </ModalContent>
      <ModalFooter
        primaryLabel="Save"
        onPrimaryClick={onSave}
        onCancel={close}
        secondaryLabel="Reset"
        onSecondaryClick={() => setNewColors(Colors)}
      />
    </Modal>
  );
};

const PickerWrapper = styled.div`
  margin-left: 20px;
`;

const Header = styled(Headline3)`
  margin-top: 10px;
`;

const Inline = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 60px;
  margin-bottom: 5px;
`;

const ColorsWrapper = styled.div`
  flex: 1;
  max-height: 500px;
  overflow-y: scroll;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const ColorPatch = styled.div<{ color: string; selected: boolean }>`
  width: 40px;
  height: 20px;
  background-color: ${(props) => props.color};
  ${(props) =>
    props.selected &&
    css`
      border: 2px solid black;
    `}
  margin-left: 5px;
`;
