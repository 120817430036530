import React from 'react';
import styled from 'styled-components';
import Background from './Background';
import { ZIndex } from 'venn-ui-kit';

const Page: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <>
    <PageContent>{children}</PageContent>
    <Background />
  </>
);

const PageContent = styled.div`
  margin: 60px;
  position: relative;
  z-index: ${ZIndex.Front};
  @media print {
    margin: 0;
    min-width: 100vw;
    width: 100vw;
    max-width: 100vw;
  }
`;

export default Page;
