import React from 'react';

import { Hint } from 'venn-ui-kit';
import { useHistory } from 'react-router-dom';
import { analyticsService, LibraryItemType, navigateToLibrary } from 'venn-utils';
import styled from 'styled-components';

const GoToDataLibraryAction = () => {
  const history = useHistory();
  const navigate = () => {
    navigateToLibrary(history, {
      selectedFilters: {
        itemType: LibraryItemType.PORTFOLIO,
        tags: [],
        quickFilters: [],
      },
    });
    analyticsService.ctaClicked({
      destination: 'Library page',
      locationOnPage: 'Wealth onboarding tutorial',
      purpose: 'see organization portfolios',
      type: 'button',
    });
  };

  return (
    <Hint>
      Or go to{' '}
      <StyledButton onClick={navigate} data-testid="qa-goto-library">
        Data Library
      </StyledButton>{' '}
      to see your organization's portfolios.
    </Hint>
  );
};

const StyledButton = styled.button`
  font-weight: bold;
`;

export default GoToDataLibraryAction;
