import styled from 'styled-components';

export const HeaderItemLoader = styled.div`
  height: 35px;
  display: flex;
  align-items: center;
`;

export const LabelText = styled.label`
  display: block;
  padding: 4px 0;
  font-size: 12px;
  font-weight: bold;
`;
