import type { ColumnMapping, Portfolio } from 'venn-api';
import { selectStrategy } from 'venn-utils';
import { DO_NOT_MAP_ID } from '../../types';
import React from 'react';
import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';
import type { CellRenderer } from './types';

interface LinkInformationRendererProps {
  columnMapping: ColumnMapping;
  portfolio: Portfolio;
  strategyId: number;
}

// @ts-expect-error: TODO fix strictFunctionTypes
export const LinkInformationRenderer: CellRenderer = ({
  columnMapping,
  portfolio,
  strategyId,
}: LinkInformationRendererProps) => {
  const strategy = selectStrategy(strategyId || portfolio.id, portfolio) || portfolio;
  const { portfolioNodeId, fundId, typeId } = columnMapping;
  return (
    <StrategyLocationCell>
      {!!portfolioNodeId &&
        !!fundId &&
        typeId !== DO_NOT_MAP_ID &&
        `In strategy ${columnMapping.strategyName} of ${portfolio ? portfolio.name : 'your portfolio'}`}
      {!portfolioNodeId &&
        !!fundId &&
        typeId !== DO_NOT_MAP_ID &&
        `Will be added as a new investment to ${strategy ? strategy.name : 'your portfolio'}`}
    </StrategyLocationCell>
  );
};

const StrategyLocationCell = styled.span`
  color: ${GetColor.HintGrey};
`;
