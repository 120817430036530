import styled from 'styled-components';
import BasicTable from '../../../../basictable/BasicTable';
import { GetColor } from 'venn-ui-kit';
import { Content } from '../../components/page-parts';

// @ts-expect-error: TODO fix strictFunctionTypes
export const StyledBasicTable = styled(BasicTable)`
  > thead > tr {
    border-bottom-color: ${GetColor.DarkGrey};
  }

  > thead,
  > tbody {
    > tr {
      > th {
        color: ${GetColor.DarkGrey};
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 0;
        padding-right: 0;

        &:first-child {
          padding-left: 15px;
        }
      }

      > td {
        padding: 0px 0;
        border-bottom: 1px solid ${GetColor.PaleGrey};
      }
    }
  }
`;

export const StyledContent = styled(Content)`
  padding: 5px 0;
  overflow-y: auto;

  section + section {
    margin-top: 30px;
  }
`;

export const SectionHeader = styled.h2`
  margin-left: 15px;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
`;
