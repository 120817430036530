import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import type AnalysisSubject from '../analysis/AnalysisSubject';
import { fetchSubject } from '../comparison/utils';

const CACHED_SUBJECT = 'CACHED_SUBJECT';

export const useCachedSubjects = () => {
  const queryClient = useQueryClient();

  const updateCachedAnalysisSubject = useCallback(
    async (id?: string | number) => {
      if (!id) {
        return undefined;
      }
      const analysisSubject = await fetchSubject(id);
      queryClient.setQueryData([CACHED_SUBJECT, id], analysisSubject);
      return analysisSubject;
    },
    [queryClient],
  );

  const getCachedAnalysisSubject = useCallback(
    async (id: string | number) => {
      const subjectId = Number.isNaN(Number(id)) ? id : Number(id);
      const cachedResult = queryClient.getQueryData<AnalysisSubject | undefined>([CACHED_SUBJECT, subjectId]);
      if (cachedResult) {
        return cachedResult;
      }

      return updateCachedAnalysisSubject(subjectId);
    },
    [queryClient, updateCachedAnalysisSubject],
  );

  return {
    getCachedAnalysisSubject,
    updateCachedAnalysisSubject,
  };
};
