import React from 'react';
import styled, { keyframes } from 'styled-components';
import type { LoadingSize } from '../loading';
import { GetColor } from '../../style/color';

export interface SpinnerProps {
  size: LoadingSize;
  className?: string;
}

const sizes: { [k: string]: string } = {
  nano: '16px',
  micro: '25px',
  small: '30px',
  large: '75px',
  xlarge: '100px',
};

const Rotator = keyframes`
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }

  100% {
    transform: rotate3d(0, 0, 1, 270deg);
  }
`;

const Dash = keyframes`
  0% {
    stroke-dashoffset: 187;
  }

  50% {
    transform: rotate3d(0, 0, 1, 135deg);
    stroke-dashoffset: 46.75;
  }

  100% {
    transform: rotate3d(0, 0, 1, 450deg);
    stroke-dashoffset: 187;
  }
`;

const Svg = styled.svg<SpinnerProps>`
  max-height: 50px;
  transform-origin: center;
  animation: ${Rotator} 1.4s linear infinite;
  max-width: ${(props) => sizes[props.size]};
`;

const Circle = styled.circle`
  transform-origin: center;
  animation: ${Dash} 1.4s ease-in-out infinite;
  stroke: ${GetColor.Primary.Dark};
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
`;

const Spinner = ({ className, size }: SpinnerProps) => (
  <Svg
    size={size}
    data-cy="qa-allocator-loader"
    className={className}
    width="100%"
    height="100%"
    viewBox="0 0 70 70"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Circle fill="none" strokeWidth="10%" strokeLinecap="round" cx="50%" cy="50%" r="40%" />
  </Svg>
);

export default Spinner;
