import type { Editor } from 'slate';
import type { TextControlsState } from 'venn-components';
import { noop } from 'lodash';
import React from 'react';

interface EditorContextProps {
  activeEditor?: Editor;
  textControlsState?: TextControlsState;
  setActiveEditor: (editor: Editor) => void;
  recalculateTextControlsState: () => void;
}

const defaultEditorContextValue: EditorContextProps = {
  setActiveEditor: noop,
  recalculateTextControlsState: noop,
};

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export const EditorContext = React.createContext<EditorContextProps>(defaultEditorContextValue);
