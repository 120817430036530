import type { FC } from 'react';
import React from 'react';
import styled, { css } from 'styled-components';
import PrintContainerDimensions from '../print/PrintContainerDimensions';
import { Button, GetColor } from 'venn-ui-kit';
import classNames from 'classnames';

export interface EmptyStateProps {
  header: string;
  message?: string | JSX.Element[] | JSX.Element;
  className?: string;
  actionLabel?: string;
  onAction?: () => void;
  noBorder?: boolean;
  backgroundImg?: string;
  small?: boolean;
}

const isSmall = (width: number) => width <= 600;

export const blockEmptyStateClass = 'block-empty-state';

const EmptyState: FC<React.PropsWithChildren<EmptyStateProps>> = ({
  header,
  message,
  className,
  actionLabel,
  onAction,
  children,
  noBorder,
  backgroundImg,
  small,
}) => {
  return (
    <PrintContainerDimensions>
      {({ width }) => (
        <Container
          className={classNames(className, blockEmptyStateClass)}
          small={isSmall(width) || !!small}
          noBorder={noBorder || !!backgroundImg}
          backgroundImg={!!backgroundImg}
        >
          {backgroundImg && <BackgroundImage src={backgroundImg} />}
          <Content backgroundImg={!!backgroundImg}>
            <StyledHeader>{header}</StyledHeader>
            {message !== undefined && <StyledMessage>{message}</StyledMessage>}
            {actionLabel ? <StyledButton onClick={onAction}>{actionLabel}</StyledButton> : null}
            {children}
          </Content>
        </Container>
      )}
    </PrintContainerDimensions>
  );
};

export default EmptyState;

interface ContainerProps {
  small: boolean;
  noBorder?: boolean;
  backgroundImg?: boolean;
}

const Container = styled.div<ContainerProps>`
  position: relative;
  ${({ backgroundImg, small }) =>
    !backgroundImg &&
    `border-radius: 20px;
     padding: ${small ? '20px' : '94px 45px'};`}
  max-width: 100%;
  flex: 1;
  ${(props) =>
    !props.noBorder &&
    css`
      border: solid 2px ${GetColor.PaleGrey};
    `}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media print {
    border-radius: 0;
    padding: 10px;
  }
`;

const Content = styled.div<{ backgroundImg: boolean }>`
  ${({ backgroundImg }) => backgroundImg && 'position: absolute;'}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const BackgroundImage = styled.img`
  position: relative;
  width: 100%;
  height: 100%;
`;

const StyledHeader = styled.span`
  font-size: 2rem;
  font-weight: 300;
  width: 100%;
  text-align: center;
  color: ${GetColor.Black};
`;

const StyledMessage = styled.div`
  max-width: 100%;
  margin-top: 10px;
  font-size: 14px;
  min-height: 14px;
  text-align: center;
  color: ${GetColor.HintGrey};
  @media print {
    font-size: 10px;
  }

  p {
    margin: 0;
    padding: 0;
  }

  a {
    color: ${GetColor.Primary.Dark};
    font-weight: bold;
    text-decoration: none;
  }
`;

const StyledButton = styled(Button)`
  position: absolute;
  bottom: 20px;
  @media print {
    display: none;
  }
`;
