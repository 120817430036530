import React from 'react';
import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';

const AllRequiredNotes = () => (
  <StyledAuthFormLegend>
    <RequiredSymbol>*</RequiredSymbol> Required Field
  </StyledAuthFormLegend>
);

export default AllRequiredNotes;

const StyledAuthFormLegend = styled.p`
  font-size: 10px;
  color: ${GetColor.White};
`;

const RequiredSymbol = styled.span`
  color: ${GetColor.Primary.Main};
`;
