import React, { useContext } from 'react';
import { Display1 } from 'venn-ui-kit';
import type { PortfolioCompare } from 'venn-api';
import { AnalysisCover, Grid } from '../Layout';
import type { SidePanelProps } from '../side-panel/SidePanel';
import SidePanel from '../side-panel/SidePanel';
import { getQueryStringParam } from '../../utils';
import OptimalPortfolioContextStore from '../OptimizationContextStore';
import ComparisonContextStore from '../ComparisonContextStore';
import type { AnalysisContentProps } from '../AnalysisContent';
import AnalysisContent from '../AnalysisContent';
import BenchmarksContextStore from '../BenchmarksContextStore';
import TagsContextStore from '../TagsContextStore';
import EmptyWorkspace from './EmptyWorkspace';
import type { History } from 'history';
import PortfolioManageDataAnalysisStore from '../PortfolioManageDataAnalysisStore';
import { UserContext } from 'venn-components';

export interface AnalysisProps extends AnalysisContentProps, SidePanelProps {
  history: History;
  onBenchmarksUpdate: (compare: PortfolioCompare[]) => Promise<void>;
}

const Analysis = ({
  history,
  /** Template */
  templateId,
  templates,
  onChangeAnalysisTemplate,
  onEditTemplate,
  /** Subject */
  objectId,
  objectType,
  loadingSubject,
  onChangeSubject,
  analysisConfig,
  /** Analyses */
  analysisLoading,
  analyses,
  analysesError,
  updateAnalysisStatusForTracking,
  /** Portfolio */
  hasAllocationError,
  onUpdateAllocationError,
  allUpdatedFunds,
  onStrategyChange,
  onPortfolioChange,
  onComparisonChange,
  onPortfolioCreate,
  /** Funds */
  updateFund,
  onFundUpdated,
  updateAllFunds,
  /** Parameters */
  onToggleRelative,
  canToggleRelative,
  setTimeFrame,
  onResetTimeFrame,
  defaultAnalysesPeriod,
  setCategoryConfig,
  factorLenses,
  onForecastUpdate,
  onBenchmarksUpdate,
  currentViewName,
}: AnalysisProps) => {
  const { hasPermission } = useContext(UserContext);
  const hasAccessToCompare = hasPermission('COMPARE');
  const canUploadInvestments = hasPermission('UPLOAD_RETURNS');

  const gridPanelWidth = objectType === 'investment' ? 0 : 410;
  if (!objectId) {
    return <EmptyWorkspace onChangeSubject={onChangeSubject} />;
  }

  return (
    <Grid panelWidth={gridPanelWidth}>
      <OptimalPortfolioContextStore portfolio={analysisConfig.subject?.portfolio} onForecastUpdate={onForecastUpdate}>
        <ComparisonContextStore
          analysisSubject={analysisConfig.subject}
          optimizationId={getQueryStringParam(history.location, 'optimizationId') as string}
        >
          <BenchmarksContextStore subject={analysisConfig.subject} onBenchmarksUpdate={onBenchmarksUpdate}>
            <TagsContextStore>
              <PortfolioManageDataAnalysisStore
                config={analysisConfig}
                setCategoryConfig={setCategoryConfig}
                onFundUpdated={onFundUpdated}
              >
                {objectType === 'portfolio' ? (
                  <SidePanel
                    objectType={objectType}
                    objectId={objectId}
                    subject={analysisConfig.subject}
                    loadingSubject={loadingSubject}
                    allUpdatedFunds={allUpdatedFunds}
                    onStrategyChange={onStrategyChange}
                    onPortfolioChange={onPortfolioChange}
                    onComparisonChange={onComparisonChange}
                    onPortfolioCreate={onPortfolioCreate}
                    hasAllocationError={hasAllocationError}
                    onUpdateAllocationError={onUpdateAllocationError}
                    updateFund={updateFund}
                    updateAllFunds={updateAllFunds}
                    hasAccessToCompare={hasAccessToCompare}
                    canUploadInvestments={canUploadInvestments}
                  />
                ) : null}
                {templateId && (
                  <AnalysisContent
                    defaultAnalysesPeriod={defaultAnalysesPeriod}
                    onToggleRelative={onToggleRelative}
                    canToggleRelative={!!canToggleRelative}
                    setTimeFrame={setTimeFrame}
                    setCategoryConfig={setCategoryConfig}
                    templateId={templateId}
                    analysisConfig={analysisConfig}
                    analysesError={analysesError}
                    analysisLoading={analysisLoading}
                    factorLenses={factorLenses}
                    onResetTimeFrame={onResetTimeFrame}
                    analyses={analyses}
                    onForecastUpdate={onForecastUpdate}
                    templates={templates}
                    onChangeAnalysisTemplate={onChangeAnalysisTemplate}
                    onEditTemplate={onEditTemplate}
                    onFundUpdated={onFundUpdated}
                    onChangeSubject={onChangeSubject}
                    updateAnalysisStatusForTracking={updateAnalysisStatusForTracking}
                    currentViewName={currentViewName}
                  />
                )}
              </PortfolioManageDataAnalysisStore>
            </TagsContextStore>
          </BenchmarksContextStore>
        </ComparisonContextStore>
        {hasAllocationError && (
          <AnalysisCover>
            <Display1>To resume analysis, ensure investment allocations sum up to 100%</Display1>
          </AnalysisCover>
        )}
      </OptimalPortfolioContextStore>
    </Grid>
  );
};

export default Analysis;
