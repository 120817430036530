import React from 'react';
import styled, { css, withTheme } from 'styled-components';
import type { Fund, Portfolio } from 'venn-api';
import type { Theme } from 'venn-ui-kit';
import { GetColor, Icon, ColorUtils, ZIndex, CellLoader, Tooltip } from 'venn-ui-kit';
import type { ItemPercentageProps } from './ItemAllocation';
import ItemAllocation, {
  IncreasedSpecificityGhostFundWrapperClass,
  GhostFundAllocationInputClass,
  GhostFundAllocationValueClass,
} from './ItemAllocation';
import ItemName, { GhostFundNameClass, OutOfSubtreeNameClass } from './AllocationItemName';
import PrintContainerDimensions from '../print/PrintContainerDimensions';
import { Constants } from './Layout';
import { debounce, compact } from 'lodash';
import Handle from './drag-and-drop/Handle';
import AllocationStrategyActions, { ActionsButton } from './AllocationStrategyActions';
import { AllocationValue, AllocationDifference } from './AllocationDifferenceTooltip';
import { getMissingReturnsMessage, Dates, flattenNode, Routes, formatAllocation, analyticsService } from 'venn-utils';
import { withRouter } from 'react-router-dom';
import type { RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';

const eps = 0.0000000001;

interface AllocationPanelRowProps extends ItemPercentageProps, RouteComponentProps {
  strategy?: Portfolio;
  compareStrategy?: Portfolio;
  originalNode?: Portfolio;
  totalWidth: number;
  isRoot: boolean;
  isGhost: boolean;
  isStrategy: boolean;
  isSelected: boolean;
  isInSelectedSubtree: boolean;
  hideCompareValue: boolean;
  compareLoading: boolean;
  isDragged: boolean;
  isInDraggedSubtree: boolean;
  isDraggingInProgress: boolean;
  isCollapsed: boolean;
  className?: string;
  secondaryTotal?: number;
  isTradesView?: boolean;
  updatedFund?: Fund | undefined;
  toggleIsCollapsed: (isCollapsed: boolean) => void;
  onClickAddFund: () => void;
  onUpdateStrategyName: (newName: string) => void;
  onDelete: () => void;
  onSelectStrategy?: () => void;
  onUpdateAllocation: (newAllocation: number) => void;
  onAddStrategy: () => void;
  onDrag: (event: React.MouseEvent<HTMLElement>) => void;
  onToggleDropIndicatorForRow: (showDropIndicator: boolean) => void;
  theme: Theme;
  hasAccessToCompare?: boolean;
  onUpdateChildrenAllocations: (updatedStrategy: Portfolio) => void;
  hideComparisonColumn?: boolean;
  rootName: string;
}

interface AllocationPanelRowState {
  isEditingName: boolean;
}

class AllocationPanelRow extends React.Component<AllocationPanelRowProps, AllocationPanelRowState> {
  state = {
    isEditingName: false,
  };

  debounceOnClickAddFund: () => void = () => null;

  componentDidMount() {
    if (!this.props.strategy) {
      return;
    }
    if (this.props.strategy.name === '') {
      this.setState({
        isEditingName: true,
      });
    }
  }

  constructor(props: AllocationPanelRowProps) {
    super(props);
    this.debounceOnClickAddFund = debounce(this.onClickAddFund, 200);
  }

  onClickAddFund = () => {
    this.props.toggleIsCollapsed(false);
    this.props.onClickAddFund();
  };

  startEditingName = () => {
    this.setState({
      isEditingName: true,
    });
  };

  onSubmitEditingName = (value: string) => {
    this.setState({
      isEditingName: false,
    });
    const { strategy } = this.props;
    if (!value || value === strategy?.name) {
      return;
    }
    this.props.onUpdateStrategyName(value);
  };

  onCompare = () => {
    analyticsService.navigationTriggered({
      destinationPageTitle: 'Comparison',
      itemType: 'dropdown',
      location: 'Strategy actions - Allocation Panel',
      userIntent: 'navigate',
    });
  };

  handleMouseEnter = (e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.FocusEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    const {
      isGhost,
      isSelected,
      isStrategy,
      isCollapsed,
      isInSelectedSubtree,
      isDragged,
      isInDraggedSubtree,
      isDraggingInProgress,
    } = this.props;

    if (isDraggingInProgress && !isDragged && !isInDraggedSubtree && !isGhost && (isSelected || isInSelectedSubtree)) {
      if (isStrategy && isCollapsed) {
        // Uncollapse strategy after .5 seconds and only then show the drop indicator
        setTimeout(() => {
          if (
            this.props.isDraggingInProgress &&
            !this.props.isDragged &&
            !this.props.isInDraggedSubtree &&
            !this.props.isGhost &&
            (this.props.isSelected || this.props.isInSelectedSubtree)
          ) {
            this.props.toggleIsCollapsed(false);
            this.props.onToggleDropIndicatorForRow(true);
          }
        }, 500);
      } else {
        // If it's not a collapsed strategy, show dropindicator immediately
        this.props.onToggleDropIndicatorForRow(true);
      }
    }
  };

  handleMouseLeave = (e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.FocusEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.onToggleDropIndicatorForRow(false);
  };

  render() {
    const {
      strategy: incompleteStrategy,
      compareStrategy: incompleteCompareStrategy,
      originalNode: incompleteOriginalNode,
      isRoot,
      isGhost,
      isStrategy,
      isSelected,
      totalWidth,
      isInSelectedSubtree,
      hideCompareValue,
      compareLoading,
      isDragged,
      isInDraggedSubtree,
      isDraggingInProgress,
      isCollapsed,
      className,
      isPercentageMode,
      toggleIsCollapsed,
      onDelete,
      onSelectStrategy,
      onUpdateAllocation,
      onAddStrategy,
      onDrag,
      baseAllocation,
      orignalBaseAllocation,
      secondaryTotal,
      isTradesView,
      updatedFund,
      theme,
      onUpdateChildrenAllocations,
      hideComparisonColumn,
      rootName,
    } = this.props;
    const { isEditingName } = this.state;

    // Fund information might have been updated globally. Update this node with the data that's now auto-saved,
    // so it should apply to all current strategy, original strategy and compare strategy.
    const strategy = getWithUpdatedFund(incompleteStrategy, updatedFund);
    const compareStrategy = getWithUpdatedFund(incompleteCompareStrategy, updatedFund);
    const originalNode = getWithUpdatedFund(incompleteOriginalNode, updatedFund);

    const hasAddFund = isStrategy && !isGhost;
    const name = !isGhost ? strategy!.name : compareStrategy!.name;

    const originalName = originalNode ? originalNode.name : undefined;
    const originalAllocation = originalNode ? originalNode.allocation : undefined;

    const isAllocationModified =
      !isGhost && (!originalNode || Math.abs((originalAllocation ?? 0) - (strategy?.allocation ?? 0)) > eps);

    const isOutsideOfSelectedSubtree = !isSelected && !isInSelectedSubtree;
    const allowActions = !isGhost && !isOutsideOfSelectedSubtree && !isDraggingInProgress;
    const allowHighlight = !(isGhost && isStrategy) && !isDraggingInProgress;
    const childFunds = compact(flattenNode(strategy).map(({ fund }) => fund?.id));
    const isEmptyStrategy = isStrategy && childFunds.length === 0;

    const showIsBeingDraggedHighlight = isDraggingInProgress && (isDragged || isInDraggedSubtree);
    const { Colors } = theme;
    const draggedHighlightColor = !showIsBeingDraggedHighlight
      ? 'none'
      : ColorUtils.hex2rgba(Colors.Primary.Main, isDragged ? 0.6 : 0.2);

    const [fundStart, fundEnd] =
      isStrategy || isGhost
        ? [undefined, undefined]
        : [strategy?.fund?.startRange || strategy?.periodStart, strategy?.fund?.endRange || strategy?.periodEnd];
    const investmentTimeRange =
      !fundStart || !fundEnd
        ? ''
        : `(${Dates.toDDMMMYYYY(fundStart, strategy?.fund?.returnFrequency)} - ${Dates.toDDMMMYYYY(
            fundEnd,
            strategy?.fund?.returnFrequency,
          )})`;
    const investmentSymbol = !isGhost ? strategy!.fund?.symbol : compareStrategy!.fund?.symbol;

    const isMissingReturnsMessage =
      !isStrategy && !isGhost && strategy && (strategy.allocation ?? 0) > 0
        ? getMissingReturnsMessage(strategy, Colors.HighlightLight)
        : undefined;

    return (
      <PrintContainerDimensions>
        {({ width }) => (
          <>
            <Row
              className={className ? `${className} qa-allocation-row` : 'qa-allocation-row'}
              hasAlert={!!isMissingReturnsMessage}
              isDraggingInProgress={isDraggingInProgress}
              onMouseOver={this.handleMouseEnter}
              onMouseOut={this.handleMouseLeave}
              // For a11y
              onFocus={this.handleMouseEnter}
              onBlur={this.handleMouseLeave}
            >
              {allowHighlight && (
                <RowHighlight totalWidth={totalWidth} className="row-highlight" hasAlert={!!isMissingReturnsMessage}>
                  <Handle
                    className="drag-and-drop-handle"
                    showIcon={allowActions && !isRoot && !isSelected}
                    onMouseDown={allowActions && !isRoot && !!onDrag ? onDrag : undefined}
                    height={30}
                  />
                </RowHighlight>
              )}

              {showIsBeingDraggedHighlight && <DragOverlay totalWidth={totalWidth} color={draggedHighlightColor} />}

              <ItemName
                originalName={originalName}
                name={name}
                width={width}
                isGhost={isGhost}
                isStrategy={isStrategy}
                isEditing={isEditingName}
                hasAddFund={hasAddFund}
                isSelected={isSelected}
                isCollapsed={isCollapsed}
                investmentTimeRange={investmentTimeRange}
                isMissingReturnsMessage={isMissingReturnsMessage}
                isOutsideOfSelectedSubtree={isOutsideOfSelectedSubtree}
                onSelectStrategyClick={onSelectStrategy}
                onAddFundClick={this.debounceOnClickAddFund}
                onTriangleClick={() => toggleIsCollapsed(!isCollapsed)}
                onEditNameClick={this.onSubmitEditingName}
                investmentSymbol={investmentSymbol}
                hideComparisonColumn={hideComparisonColumn}
              />

              <ItemAllocation
                isGhost={isGhost}
                isStrategy={isStrategy}
                isRoot={isRoot}
                isModified={isAllocationModified}
                value={strategy ? strategy.allocation : undefined}
                originalValue={originalAllocation}
                onUpdateAllocation={onUpdateAllocation}
                isOutsideOfSelectedSubtree={isOutsideOfSelectedSubtree}
                isPercentageMode={isPercentageMode}
                baseAllocation={baseAllocation}
                orignalBaseAllocation={orignalBaseAllocation}
              />

              <ItemActions hideActions={!allowActions}>
                {isStrategy ? (
                  <AllocationStrategyActions
                    isRoot={isRoot}
                    onAddStrategy={onAddStrategy}
                    onAddInvestment={this.debounceOnClickAddFund}
                    onEditName={this.startEditingName}
                    onDelete={onDelete}
                    onCompare={!this.props.hasAccessToCompare || isEmptyStrategy ? undefined : this.onCompare}
                    compareLink={`${Routes.ANALYSIS_COMPARE_PATH}?${queryString.stringify({ subjects: childFunds })}`}
                    strategy={strategy!}
                    onUpdateChildrenAllocations={onUpdateChildrenAllocations}
                    isPercentageMode={isPercentageMode}
                    rootName={rootName}
                  />
                ) : (
                  <FundActionsButton onClick={onDelete}>
                    <BlackIcon type="trash" />
                  </FundActionsButton>
                )}
              </ItemActions>

              {!hideComparisonColumn && (
                <ItemCompareAllocation isStrategy={isStrategy} isRoot={isRoot} isGreyedOut={isOutsideOfSelectedSubtree}>
                  {!hideCompareValue &&
                    (compareLoading ? (
                      <CellLoader className="qa-loader" />
                    ) : !isTradesView ? (
                      <CompareAllocationCell
                        compareStrategy={compareStrategy}
                        isRoot={isRoot}
                        isPercentageMode={isPercentageMode}
                        secondaryTotal={secondaryTotal}
                      />
                    ) : (
                      <CompareTradeCell
                        strategy={strategy}
                        compareStrategy={compareStrategy}
                        isRoot={isRoot}
                        isPercentageMode={isPercentageMode}
                        total={baseAllocation}
                        secondaryTotal={secondaryTotal}
                      />
                    ))}
                </ItemCompareAllocation>
              )}
            </Row>
          </>
        )}
      </PrintContainerDimensions>
    );
  }
}

const getWithUpdatedFund = (strategy?: Portfolio, fund?: Fund): Portfolio | undefined =>
  !strategy || !fund
    ? strategy
    : {
        ...strategy,
        fund,
        name: fund.name,
      };

export const CompareAllocationCell = ({
  compareStrategy,
  isRoot,
  isPercentageMode,
  secondaryTotal,
}: {
  compareStrategy: Portfolio | undefined;
  isRoot: boolean;
  isPercentageMode: boolean;
  secondaryTotal: number | undefined;
}) => {
  if (!compareStrategy || compareStrategy.allocation === null || compareStrategy.allocation === undefined) {
    return <>--</>;
  }
  return (
    <Tooltip
      usePortal
      content={
        <AllocationValue
          testId="qa-value-compare"
          isPercentageMode={isPercentageMode}
          value={
            isPercentageMode ? (compareStrategy?.allocation * 100) / (secondaryTotal ?? 1) : compareStrategy.allocation
          }
        />
      }
    >
      {formatAllocation(compareStrategy.allocation, isRoot, isPercentageMode, secondaryTotal)}
    </Tooltip>
  );
};

export const CompareTradeCell = ({
  strategy,
  compareStrategy,
  isRoot,
  isPercentageMode,
  total,
  secondaryTotal,
}: {
  strategy: Portfolio | undefined;
  compareStrategy: Portfolio | undefined;
  isRoot: boolean;
  isPercentageMode: boolean;
  total: number;
  secondaryTotal: number | undefined;
}) => {
  const strategyAllocation = strategy?.allocation ?? 0;
  const compareAllocation = compareStrategy?.allocation ?? 0;

  const strategyValue =
    strategyAllocation === 0 ? 0 : !isPercentageMode ? strategyAllocation : (strategyAllocation * 100) / total;
  const compareValue =
    compareAllocation === 0 ? 0 : !isPercentageMode ? compareAllocation : (compareAllocation * 100) / secondaryTotal!;

  const value = compareValue - strategyValue;

  if (value.toFixed(1) === '0.0') {
    return <>--</>;
  }

  return (
    <Tooltip
      usePortal
      content={<AllocationDifference testId="qa-diff-compare" difference={value} isPercentageMode={isPercentageMode} />}
    >
      {formatAllocation(
        value,
        isRoot,
        false, // isPercentage: false bc we are displaying the relative difference between the % values
        undefined, // total: unnecessary when isPercentage is false
        true, // isTrade
      )}
    </Tooltip>
  );
};

export default withRouter(withTheme(AllocationPanelRow));

const FullWidthSpace = styled.div<{ totalWidth: number; className?: string }>`
  position: absolute;
  right: 0;
  width: ${({ totalWidth }) => totalWidth}px;
  height: 30px;
`;

const RowHighlight = styled(FullWidthSpace)<{ hasAlert: boolean }>`
  /* The transparent background is there to allow for the hover state to be triggered (and the highlight background
   * color changed to grey) at any place in the row.
   */

  background-color: ${({ hasAlert }) =>
    hasAlert ? ColorUtils.hex2rgbaFrom(GetColor.Alert, 0.2) : 'rgba(255, 255, 255, 0)'};
  & > .drag-and-drop-handle {
    display: none;
  }
  top: 0;
`;

const DragOverlay = styled(FullWidthSpace)<{ color: string }>`
  background-color: ${({ color }) => color};
  z-index: ${ZIndex.Front};
  top: 0;
`;

export const Row = styled.div<{ isDraggingInProgress: boolean; hasAlert: boolean }>`
  display: flex;
  align-items: center;
  height: 30px;
  padding-left: ${Constants.NAME_MARGIN}px;

  ${({ isDraggingInProgress, hasAlert }) =>
    !isDraggingInProgress
      ? css`
          &:hover {
            & > .row-highlight {
              background-color: ${hasAlert ? GetColor.Alert : ColorUtils.hex2rgbaFrom(GetColor.DarkGrey, 0.1)};
              & > .drag-and-drop-handle {
                display: inline;
              }
            }
            .${IncreasedSpecificityGhostFundWrapperClass} .${GhostFundAllocationInputClass} {
              display: block !important;
            }
            .${IncreasedSpecificityGhostFundWrapperClass} .${GhostFundAllocationValueClass} {
              display: none !important;
            }
            & > .${GhostFundNameClass}, .${OutOfSubtreeNameClass} {
              color: ${GetColor.Black};
            }
            ${ActionsButton} {
              i {
                opacity: 1;
              }
            }
          }
          ${ActionsButton}:focus {
            i {
              opacity: 1;
            }
          }
        `
      : css`
          & > * {
            pointer-events: none;
          }
        `}
`;

const BlackIcon = styled(Icon)`
  color: ${GetColor.Black};
  &:hover {
    color: ${GetColor.Primary.Main};
  }
`;

const ItemActions = styled.div<{ hideActions: boolean }>`
  ${({ hideActions }) => hideActions && 'visibility: hidden;'}
  width: ${Constants.ACTIONS_WIDTH}px;
  min-width: ${Constants.ACTIONS_WIDTH}px;
  height: 100%;
  z-index: ${ZIndex.Front};
`;

const ItemCompareAllocation = styled.div<{ isRoot: boolean; isStrategy: boolean; isGreyedOut: boolean }>`
  ${({ isRoot }) => css`
    font-size: ${isRoot ? 16 : 14}px;
  `}
  ${({ isStrategy }) =>
    isStrategy &&
    css`
      font-weight: bold;
    `}
  ${({ isGreyedOut }) =>
    isGreyedOut &&
    css`
      color: ${GetColor.MidGrey1};
    `}
  width: ${Constants.COMPARE_WIDTH}px;
  min-width: ${Constants.COMPARE_WIDTH}px;
  height: 100%;
  padding-right: 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const FundActionsButton = styled(ActionsButton)`
  i {
    opacity: 0;
  }
`;
