import type { RefObject } from 'react';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { noop } from 'lodash';
import type { ExcelCell } from 'venn-utils';

/**
 * Defined if data is available; undefined if data is not available for the block.
 *
 * This allows the UI to determine if data is availale to be exported at all.
 */
export type ExcelDataFn = undefined | (() => ExcelCell[][]);
export interface ExportDataKeyValue {
  // Only valid excel data functions end up in the map.
  [key: string]: NonNullable<ExcelDataFn>;
}

interface ExportContextValue {
  /** Download loader will show when downloadFileName is not empty */
  downloadFileName?: string;
  /** Show different message based on if there are savedId */
  hasSavedId: boolean;
  updateDownloadInfo?: (
    downloadFileName: string,
    hasSavedId?: boolean,
    hideFooter?: boolean,
    isError?: boolean,
  ) => void;
  /** Note that exportData changes reference every time a block renders. */
  exportData: ExportDataKeyValue;
  /**
   * Ref variant of exportData, allowing usage in callbacks without losing performance or extra callback deps.
   * Should always be defined in practice, unless the provider isn't initialized.
   */
  exportDataRef: RefObject<ExportDataKeyValue> | undefined;
  updateExportData: (key: string, value: ExcelDataFn) => void;
  hideFooter: boolean;
  isError: boolean;
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
const ExportContext = React.createContext<ExportContextValue>({
  downloadFileName: undefined,
  updateDownloadInfo: undefined,
  hasSavedId: false,
  exportData: {},
  exportDataRef: undefined,
  updateExportData: noop,
  hideFooter: false,
  isError: false,
  isExportingPdf: false,
} as ExportContextValue);

export default ExportContext;

export const AnalysisExportStore: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [exportData, setExportData] = useState<ExportDataKeyValue>({});
  const exportDataRef = useRef<ExportDataKeyValue>(exportData);
  exportDataRef.current = exportData;

  const [downloadFileName, setDownloadFileName] = useState<string | undefined>(undefined);
  const [isError, setIsError] = useState<boolean>(false);
  const [hasSavedId, setHasSavedId] = useState<boolean>(false);
  const [hideFooter, setHideFooter] = useState<boolean>(false);

  const updateExportData = useCallback((key: string, value: ExcelDataFn) => {
    setExportData((prev) => {
      // Upsert valid values
      if (value) return { ...prev, [key]: value };

      // Remove the key from the map if the new value for the key is invalid
      const { [key]: _omitted, ...remaining } = prev;
      return remaining;
    });
  }, []);

  const updateDownloadInfo = useCallback(
    (name: string, hasSaved?: boolean, hideFooter?: boolean, isError?: boolean) => {
      setHasSavedId(!!hasSaved);
      setDownloadFileName(name);
      setIsError(!!isError);
      setHideFooter(!!hideFooter);
    },
    [],
  );

  // All consumers will rerender if the actual object changes reference
  const contextValue = useMemo(() => {
    return {
      exportData,
      exportDataRef,
      updateExportData,
      downloadFileName,
      hasSavedId,
      updateDownloadInfo,
      hideFooter,
      isError,
    };
  }, [
    downloadFileName,
    exportData,
    exportDataRef,
    hasSavedId,
    hideFooter,
    isError,
    updateDownloadInfo,
    updateExportData,
  ]);

  return <ExportContext.Provider value={contextValue}>{children}</ExportContext.Provider>;
};
