import React from 'react';
import { GetColor, Icon } from 'venn-ui-kit';
import styled from 'styled-components';

interface ExpandCollapseButtonProps {
  isOpen: boolean;
  isLeftOrientation?: boolean;
  onToggle: () => void;
}

const ExpandCollapseButton = ({ isOpen, isLeftOrientation, onToggle }: ExpandCollapseButtonProps) => {
  const chevronPointingRight = (isLeftOrientation && !isOpen) || (!isLeftOrientation && isOpen);
  return (
    <ToggleButton
      data-testid="expand-collapse-button"
      onClick={onToggle}
      leftPanel={isLeftOrientation}
      leftChevron={!chevronPointingRight}
    >
      <Icon type={chevronPointingRight ? 'chevron-double-right' : 'chevron-double-left'} />
    </ToggleButton>
  );
};

export default ExpandCollapseButton;

const ToggleButton = styled.button<{ leftPanel?: boolean; leftChevron?: boolean }>`
  color: ${GetColor.Primary.Dark};
  font-weight: bold;
  font-size: 1rem;
  text-transform: capitalize;
  display: inline-flex;
  ${(props) => (props.leftPanel ? 'justify-content: right;' : 'justify-content: left;')}
  ${(props) => (props.leftPanel ? 'margin-right: 10px;margin-left: 5px;' : 'margin-right: 10px;margin-left: 5px;')}

  &:hover {
    color: ${GetColor.MidGrey2};
  }

  &:focus {
    color: ${GetColor.Primary.Dark};
  }

  border: 1px solid #dde3ed;
  box-sizing: border-box;
  border-radius: 6px;
  padding: ${({ leftChevron }) => (leftChevron ? '2px 3px 2px 2px' : '2px 2px 2px 3px')};
`;
