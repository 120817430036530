import { useRecoilCallback } from 'recoil';
import { allBlockIdsState, viewPages, virtualScrollRef } from 'venn-state';
import type { Page } from 'venn-components';
import { Routes, logExceptionIntoSentry } from 'venn-utils';
import { useVirtualization } from './useVirtualization';
import { useRouteMatch } from 'react-router-dom';

export const useScrollToBlock = () => {
  const isVirtualized = useVirtualization();
  const isReport = !!useRouteMatch(Routes.REPORT_LAB_PATH);

  return useRecoilCallback(
    ({ snapshot }) =>
      async (id: string) => {
        if (!isVirtualized) {
          document.getElementById(id)?.scrollIntoView();
          return;
        }

        const blockIds = await snapshot.getPromise(allBlockIdsState);
        const pages = await snapshot.getPromise(viewPages);
        const scrollRef = await snapshot.getPromise(virtualScrollRef);

        const getIndex = () => {
          if (isReport) {
            // Report lab uses pages, not blocks, for scrolling. So we use the page index.
            // During insertion we use the pageIndex, because the block may not be in the page layout yet, but we know where it will end up.
            return pages.findIndex((p: Page) => p.layout?.find((l) => l.i === id));
          }
          return blockIds.indexOf(id);
        };

        const index = getIndex();

        if (index > -1) {
          scrollRef?.current?.scrollToIndex({
            index,
            align: 'center',
          });
        } else {
          logExceptionIntoSentry('Could not find block id to scroll to', {
            isReport,
            index,
          });
        }
      },
    [isReport, isVirtualized],
  );
};
