import type { HoldingsColumnParserProps, HeaderComponentParamsType, CustomRowData } from '../types';
import { createLabelColumn } from './columnParsers';
import type { ColDef } from 'ag-grid-community';
import { getComparisonLabelForBlockLegend } from 'venn-utils';
import HeaderCellRenderer from '../components/grid/renderers/HeaderCellRenderer';
import { getDefaultCellClass, getDefaultValueFormat } from './gridStyling';
import ValueCellRenderer from '../components/grid/renderers/ValueCellRenderer';
import { compact } from 'lodash';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { blockLimitedHoldingsSubjectsWithFees } from 'venn-state';

export const useHoldingsColumns = <T extends CustomRowData>({
  selectedRefId,
  valueGetter,
  isTree,
}: HoldingsColumnParserProps<T>) => {
  const subjects = useRecoilValue(blockLimitedHoldingsSubjectsWithFees(selectedRefId));

  return useMemo(() => {
    const labelColumn = isTree ? undefined : createLabelColumn({});

    const columns: ColDef[] = subjects.map((subject, index) => {
      const headerComponentParams: HeaderComponentParamsType = {
        subject,
        isCommonBenchmark: subject.isBenchmark,
      };

      return {
        headerName: getComparisonLabelForBlockLegend(subject),
        headerComponent: HeaderCellRenderer,
        headerComponentParams,
        field: String(index),
        valueGetter,
        valueFormatter: getDefaultValueFormat,
        cellRenderer: ValueCellRenderer,
        cellClass: getDefaultCellClass,
        autoHeight: false,
      };
    });

    return compact([labelColumn, ...columns]);
  }, [isTree, subjects, valueGetter]);
};
