import React from 'react';
import styled from 'styled-components';
import type { ShimmerProps } from 'venn-ui-kit';
import { GetColor, Headline1, Shimmer } from 'venn-ui-kit';
import type { AnalysisSubject } from 'venn-utils';
import type { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

interface TitleProps extends RouteComponentProps {
  subject?: AnalysisSubject;
}

const Title = ({ subject }: TitleProps) => {
  if (!subject || !subject.id) {
    return (
      <TitleWrapper>
        <LoadingHeadline />
      </TitleWrapper>
    );
  }

  return <Header>{subject.name}</Header>;
};

export default withRouter(Title);

const Header = styled(Headline1)`
  animation: fadein 1s;
  min-height: 119px;
  padding-top: 60px;
  @media print {
    animation: none;
  }
  color: ${GetColor.MidGrey1};
`;

const PlaceholderTitle = styled.div`
  min-width: 200px;
`;

const TitleWrapper = styled(PlaceholderTitle)`
  animation: fadein 1s;
  margin-top: auto;
  margin-bottom: auto;
  max-width: 100%;
  flex-grow: 1;
  @media print {
    animation: none;
  }
`;

const LoadingHeadline = styled.div<ShimmerProps>`
  ${Shimmer}
  width: 100%;
  height: 62px;
`;
