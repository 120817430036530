import type React from 'react';
import { useEffect, useState } from 'react';
import { useDebounce } from './useDebounce';

export function useDebounceToGlobal<T>(
  globalValue: T,
  updateGlobalValue: (value: T) => void,
  delay = 500,
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [value, setValue] = useState(globalValue);
  const [debouncedValue] = useDebounce(value, delay);

  useEffect(() => {
    if (debouncedValue !== globalValue) {
      updateGlobalValue(debouncedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  useEffect(() => {
    setValue(globalValue);
  }, [globalValue]);

  return [value, setValue];
}
