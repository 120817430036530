import { useEffect, useMemo, useState, useCallback } from 'react';
import type { AnalysisRequest, Message, RangeAnalysisResponse, RangeDebugResponse, FrequencyEnum } from 'venn-api';
import { analysis } from 'venn-api';
import type { AnalysisSubject } from 'venn-utils';
import { getAnalysisRequest, useApi, useIsMounted } from 'venn-utils';
import { isNil, negate } from 'lodash';

export interface RangeDebugResult {
  response: RangeDebugResponse | null;
  subjectError: Message | null;
}

export interface RangeDebugGroup {
  primary: RangeDebugResult;
  secondary?: RangeDebugResult;
  benchmark?: RangeDebugResult;
  master?: RangeDebugResult;
  maxStartTime: number;
  maxEndTime: number;
  maxFrequency: FrequencyEnum;
}

export interface UseRangeAnalysisReturn {
  refresh: () => Promise<void>;
  loading: boolean;
  rangeAnalysis?: RangeAnalysisResponse;
  analysisRequest?: Partial<AnalysisRequest>;
}

const useRangeAnalysis = (subject: AnalysisSubject | undefined): UseRangeAnalysisReturn => {
  const [loading, setLoading] = useState<boolean>(false);
  const [rangeAnalysis, setRangeAnalysis] = useState<RangeAnalysisResponse>();
  const analysisApi = useApi(analysis);
  const isMountedRef = useIsMounted();

  const analysisRequest = useMemo(() => {
    if (subject?.private) {
      return undefined;
    }
    return getAnalysisRequest(['RANGE_ANALYSIS'], subject, {}, false, true);
  }, [subject]);

  const refresh = useCallback(async () => {
    setLoading(true);
    try {
      const analysisResult = await analysisApi(analysisRequest);
      if (!isMountedRef.current) {
        return;
      }
      const analysisResponse = analysisResult.content.analyses.map((a) => a.rangeAnalysis).find(negate(isNil));
      setRangeAnalysis(analysisResponse);
      setLoading(false);
    } catch (e) {
      if (e.name !== 'AbortError' && isMountedRef.current) {
        setRangeAnalysis(undefined);
        setLoading(false);
      }
    }
  }, [analysisApi, analysisRequest, isMountedRef]);
  useEffect(() => {
    refresh();
  }, [refresh]);

  return { loading, rangeAnalysis, refresh, analysisRequest };
};

export default useRangeAnalysis;
