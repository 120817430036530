import styled, { css } from 'styled-components';
import {
  BOLD_CLASS,
  GREY_BG_CLASS,
  ITALIC_CLASS,
  LEFT_ALIGN_CLASS,
  METADATA_GREY_BG_CLASS,
  OUT_OF_RANGE_CELL_CLASS,
  PROXY_CELL_CLASS,
  RIGHT_ALIGN_CLASS,
  ROW_HAS_ERROR_CLASS,
} from './customAnalysisContants';
import { ColorUtils, GetColor } from 'venn-ui-kit';

export const GridWrapper = styled.div<{ isReport?: boolean }>`
  ${({ isReport }) =>
    !isReport &&
    css`
      overflow: auto;
    `}
  .${BOLD_CLASS} {
    font-weight: bold;
  }

  .${ITALIC_CLASS} {
    font-style: italic;
  }

  .${GREY_BG_CLASS} {
    background: ${GetColor.WhiteGrey};
    color: ${GetColor.HintGrey};
  }

  .${METADATA_GREY_BG_CLASS} {
    background: ${GetColor.WhiteGrey};
    color: ${GetColor.HintGrey};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .${LEFT_ALIGN_CLASS} {
    text-align: left;
  }

  .${RIGHT_ALIGN_CLASS} {
    text-align: right;
  }

  .${ROW_HAS_ERROR_CLASS} {
    background-color: ${ColorUtils.opacifyFrom(GetColor.Error, 0.1)};
  }

  .${PROXY_CELL_CLASS} {
    background-color: ${ColorUtils.hex2rgbaFrom(GetColor.DEPRECATED_DataLineColor.PaleGold, 0.1)};
  }

  .${OUT_OF_RANGE_CELL_CLASS} {
    color: ${GetColor.HintGrey};
  }
`;

export const BLOCK_BORDER_WIDTH_PX = 2;
