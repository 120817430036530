import type { Country, CurrencyEnum } from 'venn-api';

export const restrictedCountry: Country = {
  restrictedAccess: true,
  code: 'AF',
  gdprApplied: false,
  defaultCurrency: 'USD' as CurrencyEnum,
  defaultLanguage: 'EN',
  displayName: 'Afghanistan',
  iso2Code: 'AF',
  iso3Code: 'AFG',
  supportedJurisdiction: false,
};

export const supportedCountryNoState: Country = {
  restrictedAccess: false,
  code: 'UK',
  gdprApplied: true,
  defaultCurrency: 'GBP' as CurrencyEnum,
  defaultLanguage: 'EN',
  displayName: 'United Kingdom',
  iso2Code: 'GB',
  iso3Code: 'GBR',
  supportedJurisdiction: true,
};

export const supportedCountryAllStates: Country = {
  restrictedAccess: false,
  code: 'US',
  gdprApplied: false,
  defaultCurrency: 'USD' as CurrencyEnum,
  defaultLanguage: 'EN',
  displayName: 'United States of America',
  iso2Code: 'US',
  iso3Code: 'USA',
  supportedJurisdiction: true,
};

export const supportedCountrySomeStates: Country = {
  restrictedAccess: false,
  code: 'CA',
  gdprApplied: false,
  defaultCurrency: 'CAD' as CurrencyEnum,
  defaultLanguage: 'EN',
  displayName: 'Canada',
  iso2Code: 'CA',
  iso3Code: 'CAN',
  supportedJurisdiction: false,
};

export const mockCountryList: Country[] = [
  restrictedCountry,
  supportedCountrySomeStates,
  supportedCountryNoState,
  supportedCountryAllStates,
];

export const mockCompanyTypes = [
  {
    key: 'ADMIN',
    name: 'Admin',
    notes: [],
  },
];

export const mockAumRanges = [
  {
    approximateAUM: 0.1,
    id: '1_100',
    name: 'US$ 1 - 100M',
  },
  {
    approximateAUM: 1,
    id: '100_1',
    name: 'US$ 100M - 1B',
  },
];

const getState = (name: string, restricted = false) => ({
  restricted,
  name,
});

export const mockLocalities = {
  [supportedCountrySomeStates.code]: [getState('State 1'), getState('State restricted', true)],
  [supportedCountryAllStates.code]: [getState('State 2.1'), getState('State 2.2')],
};

export const mockSignupOptions = {
  companyTypes: mockCompanyTypes,
  aumRanges: mockAumRanges,
  localities: mockLocalities,
  countries: mockCountryList,
};
