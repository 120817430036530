import React, { useState } from 'react';
import type { Portfolio } from 'venn-api';
import { Modal, ModalContent, ModalFooter, ModalSize, ModalSubhead } from '../../modal';
import { isNil } from 'lodash';
import styled from 'styled-components';
import useStrategyWithModifiedCapital from './useStrategyWithModifiedCapital';
import type { CapitalEditMode, RedistributionMode } from './types';
import Input from '../../input/Input';
import { GetColor, TextAlign, Radio } from 'venn-ui-kit';

interface EditCapitalModalProps {
  isRoot: boolean;
  strategy: Portfolio;
  onClose: () => void;
  onSubmit: (updatedStrategy: Portfolio) => void;
}

const EditCapitalModal = ({ isRoot, strategy, onClose, onSubmit }: EditCapitalModalProps) => {
  const [editMode, setEditMode] = useState<CapitalEditMode>('add');
  const [amount, setAmount] = useState(0);
  const [redistributionMode, setRedistributionMode] = useState<RedistributionMode>('prorata');

  const { draftStrategy, error } = useStrategyWithModifiedCapital(strategy, editMode, amount, redistributionMode);

  return (
    <Modal size={ModalSize.Small}>
      <ModalSubhead>Edit capital of {strategy.name}</ModalSubhead>
      <ModalContent>
        <Section>
          <SectionHeader>
            {isRoot ? 'How would you want to edit your capital?' : 'Edit your strategy allocation'}
          </SectionHeader>
          <div>
            <Radio inputId="add" checked={editMode === 'add'} onChange={() => setEditMode('add')} value="add">
              Add Capital
            </Radio>
          </div>
          <div>
            <Radio
              inputId="reduce"
              checked={editMode === 'reduce'}
              onChange={() => setEditMode('reduce')}
              value="reduce"
            >
              Reduce Capital
            </Radio>
          </div>
        </Section>
        <Section>
          <SectionHeader>Capital Amount</SectionHeader>
          <CapitalInput
            type="number"
            textAlign={TextAlign.Right}
            value={`${amount}`}
            onChange={(value) => setAmount(Number.parseFloat(value))}
            selectOnFocus
          />
        </Section>
        <Section>
          <SectionHeader>{isRoot ? 'Redistribute rest of portfolio' : 'Distribute to strategy'}</SectionHeader>
          <div>
            <Radio
              inputId="prorata"
              checked={redistributionMode === 'prorata'}
              onChange={() => setRedistributionMode('prorata')}
              value="prorata"
            >
              ProRata
            </Radio>
          </div>
          <div>
            <Radio
              inputId="evenly"
              checked={redistributionMode === 'evenly'}
              onChange={() => setRedistributionMode('evenly')}
              value="evenly"
            >
              Evenly
            </Radio>
          </div>
        </Section>
        <ErrorSection>{error}</ErrorSection>
      </ModalContent>
      <ModalFooter
        primaryLabel="Apply"
        primaryDisabled={isNil(draftStrategy)}
        onPrimaryClick={() => {
          if (!isNil(draftStrategy)) {
            onSubmit(draftStrategy);
            onClose();
          }
        }}
        onCancel={onClose}
      />
    </Modal>
  );
};

export default EditCapitalModal;

const Section = styled.div`
  :not(:first-child) {
    margin-top: 24px;
  }
  :not(:last-child) {
    margin-bottom: 12px;
  }
`;

const SectionHeader = styled.h2`
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  margin-top: 0;
`;

const CapitalInput = styled(Input)`
  &:before {
    content: '$';
    left: 8px;
    position: absolute;
    color: ${GetColor.MidGrey2};
    font-size: 14px;
  }
`;

const ErrorSection = styled(Section)`
  font-size: 13px;
  color: ${GetColor.Error};
  text-align: right;
`;
