import type { RowWithID } from './types';
import { SORTDIR } from './BasicTable';
import { isNil } from 'lodash';

type SortValue = string | number | undefined | null;
/**
 * Custom sort function
 * @param getter method to get target field to sort
 */
export const sortTableFunc =
  <T>(getter: (data: T & RowWithID) => SortValue) =>
  (rows: (T & RowWithID)[], dir: SORTDIR) =>
    rows.sort((a, b) => {
      const first = getter(a);
      const second = getter(b);

      if (isNil(first)) {
        return dir === SORTDIR.DESC ? 1 : -1;
      }
      if (isNil(second)) {
        return dir === SORTDIR.DESC ? -1 : 1;
      }

      const comparison =
        isString(first) && isString(second)
          ? (first as string).toLocaleLowerCase() < (second as string).toLocaleLowerCase()
          : first < second;
      if (dir === SORTDIR.DESC) {
        return comparison ? 1 : -1;
      }
      return comparison ? -1 : 1;
    });

const isString = (value: SortValue): boolean => typeof value === 'string';
