import type { FC } from 'react';
import React from 'react';
import styled, { css } from 'styled-components';
import { Ellipsis, GetColor, Icon, TooltipPosition, Tooltip, OverflowText } from 'venn-ui-kit';
import type { SearchResultWithUIState } from './types';
import { getAnalysisRouteForItem, MASTER_PORTFOLIO_NO_DELETION_MESSAGE } from './utils';
import ItemOverview from './ItemOverview';
import { Link } from 'react-router-dom';

const LOCK_ICON_SIZE = 16;

interface NameCellProps {
  item: SearchResultWithUIState;
  trackSelectionMade: (item: SearchResultWithUIState) => void;
}

const NameCell: FC<React.PropsWithChildren<NameCellProps>> = ({ item, trackSelectionMade }) => {
  return (
    <StyledName isMaster={item.master}>
      <StyledNameOverflowContainer>
        {item.master && (
          <AbsoluteContainer>
            <StyledTooltip
              maxWidth={310}
              position={TooltipPosition.Left}
              content={MASTER_PORTFOLIO_NO_DELETION_MESSAGE}
            >
              <StyledLock type="lock" />
            </StyledTooltip>
          </AbsoluteContainer>
        )}
        <SingleLineText className={item.master ? 'master-label' : ''}>
          <StyledTooltip maxWidth={400} className="name-tooltip" flex content={<ItemOverview item={item} />}>
            <Ellipsis singleLine>
              {item.isNew && <NewItemLabel>New</NewItemLabel>}
              <Link
                onClick={() => trackSelectionMade(item)}
                to={getAnalysisRouteForItem(item)}
                className="library-name"
              >
                {item.name}
              </Link>
            </Ellipsis>
          </StyledTooltip>
        </SingleLineText>
      </StyledNameOverflowContainer>
    </StyledName>
  );
};

export default NameCell;

const SingleLineText = OverflowText.SingleLine;

const StyledName = styled.span<{ isMaster: boolean }>`
  font-weight: bold;
  color: ${GetColor.Primary.Dark};
  position: absolute;
  left: 0;
  right: 0;
  ${(props) =>
    props.isMaster &&
    css`
      margin-left: ${LOCK_ICON_SIZE}px;
    `}
`;

const StyledLock = styled(Icon)`
  margin-right: 5px;
`;

const AbsoluteContainer = styled.span`
  position: absolute;
  left: -${LOCK_ICON_SIZE}px;
`;

const NewItemLabel = styled.span`
  vertical-align: middle;
  border-radius: 4px;
  font-size: 11px;
  text-align: center;
  color: ${GetColor.White};
  display: inline;
  background: ${GetColor.Primary.Main};
  padding: 1px 5px;
  margin-right: 4px;
  font-style: normal;
`;

const StyledNameOverflowContainer = styled.span`
  .master-label {
    font-style: italic;
  }
`;

const StyledTooltip = styled(Tooltip)`
  display: inline-flex;
  max-width: 100%;

  h1 {
    font-weight: bold;
    margin-bottom: 20px;
    font-size: 1.5rem;
    margin: 0 0 0.67em 0;
  }

  .footer {
    display: block;
    margin-top: 21px;
    margin-bottom: 12px;
    color: ${GetColor.Primary.Main};

    i {
      padding-left: 3px;
    }
  }

  p {
    margin: 0;
    min-width: 240px;
  }

  .tooltip-value {
    float: right;
    font-weight: bold;
    padding-left: 13px;
  }

  /* set relative position to the tooltip ContentContainer (IE Fix) */
  & > div > div {
    position: relative;
  }
`;
