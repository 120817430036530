import React, { useContext } from 'react';
import type { AnalysisConfig } from 'venn-utils';
import type { AnalysisStatusForTracking, TrackAnalysisProps, DownloadMetaData } from 'venn-components';
import { AnalysisViewContext } from 'venn-components';
import { ScenarioAnalysis } from './ScenarioAnalysis';

interface ScenarioAnalysisBlockProps {
  analysisConfig: AnalysisConfig;
  downloadMetaData?: DownloadMetaData;
  onResetTimeFrame?: () => void;
  noChartTrackingProps: TrackAnalysisProps;
  updateAnalysisStatusForTracking?: (
    actionAnalysisStatuses: AnalysisStatusForTracking[],
    actionTrackingId: number,
    dateRange?: string,
  ) => void;
}

const ScenarioAnalysisBlock = (props: ScenarioAnalysisBlockProps) => {
  const { scenariosCustomParamId, scenarios, onUpdateAnalysisViewParam } = useContext(AnalysisViewContext);

  return (
    <ScenarioAnalysis
      {...props}
      scenariosCustomParamId={scenariosCustomParamId}
      scenarios={scenarios}
      onUpdateAnalysisViewParam={onUpdateAnalysisViewParam}
    />
  );
};

export default ScenarioAnalysisBlock;
