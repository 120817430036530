import type { Analyses } from '../../../analysis';

const resultsResponse: Pick<Analyses, 'returnsGrid'> = {
  returnsGrid: {
    subject: {
      returnsGridMap: {
        MONTHLY: {
          returnCells: [
            {
              date: 1580515199999,
              val: -0.002922329158763559,
              cellTypes: ['ANALYSIS', 'ORIGINAL'],
            },
            {
              date: 1583020799999,
              val: -0.02501732865428108,
              cellTypes: ['ANALYSIS', 'ORIGINAL'],
            },
          ],
          yearlyCumulativeReturn: [
            {
              date: 1577923199999,
              val: -0.027866548944043834,
              cellTypes: ['TOTAL'],
            },
          ],
        },
        QUARTERLY: {
          returnCells: [
            {
              date: 1585699199999,
              val: -0.027866548944043834,
              cellTypes: ['ANALYSIS', 'ORIGINAL'],
            },
          ],
          yearlyCumulativeReturn: [
            {
              date: 1577923199999,
              val: -0.567866548944043834,
              cellTypes: ['TOTAL'],
            },
          ],
        },
        YEARLY: {
          returnCells: [
            {
              date: 1577923199999,
              val: 0.0722370787355604,
              cellTypes: ['TOTAL'],
            },
          ],
          yearlyCumulativeReturn: [
            {
              date: 1577923199999,
              val: -0.027866548944043834,
              cellTypes: ['TOTAL'],
            },
          ],
        },
      },
      disabledFrequencies: [],
    },
    benchmark: {
      returnsGridMap: {
        QUARTERLY: {
          returnCells: [
            {
              date: 1585699199999,
              val: 0.0722370787355604,
              cellTypes: ['ANALYSIS', 'ORIGINAL'],
            },
          ],
          yearlyCumulativeReturn: [
            {
              date: 1577923199999,
              val: -0.027866548944043834,
              cellTypes: ['TOTAL'],
            },
          ],
        },
        YEARLY: {
          returnCells: [
            {
              date: 1577923199999,
              val: 0.0722370787355604,
              cellTypes: ['TOTAL'],
            },
          ],
          yearlyCumulativeReturn: [
            {
              date: 1577923199999,
              val: -0.027866548944043834,
              cellTypes: ['TOTAL'],
            },
          ],
        },
      },
      disabledFrequencies: ['MONTHLY'],
    },
  },
};

export default resultsResponse;
