import type { ComputedInvestmentResidual } from 'venn-api';

export const mockDefaultResidualOverride = {
  created: 0,
  defaultResidual: 0.43,
  forecastResidual: null,
  fundId: 'default-fund-id',
  fundName: 'Default Fund',
  permissionGroupId: 42,
  userId: 42,
  userName: 'Bobby',
  workspaceId: 42,
  workspaceName: 'Workspace',
} as unknown as ComputedInvestmentResidual;

export const mockForecastResidualOverride = {
  created: 0,
  defaultResidual: 0.42,
  overriddenResidual: 0.995,
  overriddenReturn: undefined,
  forecastResidual: 0.995,
  computedResidual: 0.995,
  defaultReturn: 0.24,
  computedReturn: 0.599,
  fundId: 'forecast-fund-id',
  fundName: 'Forecast Fund',
  permissionGroupId: 42,
  userId: 42,
  userName: 'Bobby 2',
  workspaceId: 42,
  workspaceName: 'Workspace 2',
} as unknown as ComputedInvestmentResidual;
