import { CenterAction } from '../account-modals/shared';
import { IconButton } from './components';
import React from 'react';

interface TwoFactorOptionsProps {
  onSelectGoogleAuthentication(): void;
  onSelectSMS(): void;
}

const TwoFactorOptions = ({ onSelectGoogleAuthentication, onSelectSMS }: TwoFactorOptionsProps) => {
  return (
    <>
      <CenterAction>
        <IconButton
          onClick={onSelectGoogleAuthentication}
          iconType="google"
          iconPrefix="fab"
          title="Google Authenticator"
          line="Use the Authenticator app to get codes."
          hint="(Most secure option)"
        />
      </CenterAction>
      <CenterAction>
        <IconButton
          onClick={onSelectSMS}
          iconType="mobile-alt"
          iconPrefix="fas"
          title="Text message"
          line="Receive a code by text."
        />
      </CenterAction>
    </>
  );
};

export default TwoFactorOptions;
