import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';

const Wrapper = styled.div`
  display: flex;
  margin: 20px 10px 6px 2px;
`;

const Label = styled.div`
  margin-right: 10px;
  color: ${GetColor.Black};
  font-size: 11px;
  font-weight: bold;
`;

const WeightLabel = styled(Label)`
  width: 66px;
  text-align: right;
`;

const FundLabel = styled(Label)`
  width: 420px;
`;

const PercentageLabel = styled(Label)`
  width: 56px;
  text-align: right;
`;

const BenchmarkLabels: FC<React.PropsWithChildren<unknown>> = () => (
  <Wrapper>
    <WeightLabel>Weight (%)</WeightLabel>
    <FundLabel>Investment</FundLabel>
    <PercentageLabel>%</PercentageLabel>
  </Wrapper>
);

export default BenchmarkLabels;
