import Icon from '../icon/Icon';
import { GetColor } from '../../style';
import { ZIndex } from '../../zIndexValues';
import React from 'react';
import styled from 'styled-components';

export interface Step {
  key: number;
  label: string;
}

interface StageProps {
  step: Step;
  currentStep: number;
  stepWidth?: number;
}

const Stage = ({ step: { key, label }, currentStep, stepWidth = 140 }: StageProps) => {
  const active = currentStep === key;
  return (
    <Dot
      done={currentStep > key}
      active={active}
      text={label}
      data-testid={active ? 'active-step' : ''}
      stepWidth={stepWidth}
    >
      {currentStep > key ? <Icon type="check" /> : <div data-testid="unfinished-step">{key + 1}</div>}
    </Dot>
  );
};

export default Stage;

const Dot = styled.div<{ active: boolean; done: boolean; text?: string; stepWidth: number }>`
  background: ${(props) => (props.done ? GetColor.HighlightDark : GetColor.White)};
  color: ${(props) => (props.done ? GetColor.White : props.active ? GetColor.HighlightDark : GetColor.HintGrey)};
  width: 25px;
  height: 25px;
  border: 2px solid ${(props) => (props.active || props.done ? GetColor.HighlightDark : GetColor.Grey)};
  border-radius: 50%;
  position: relative;
  transition: all 0.5s linear;
  &:not(:last-child) {
    margin-right: ${(props) => props.stepWidth - 20}px;
    &:before {
      content: '';
      display: inline-block;
      width: ${(props) => props.stepWidth}px;
      background-color: ${(props) => (props.done ? GetColor.HighlightDark : GetColor.Grey)};
      transition: all 0.5s linear;
      height: 5px;
      position: absolute;
      z-index: ${ZIndex.Background};
      top: calc(50% - 3px);
    }
  }
  &:after {
    content: '${(props) => props.text}';
    display: inline-block;
    white-space: nowrap;
    width: fit-content;
    position: relative;
    translate: calc(-50% + 10px);
    top: 10px;
    transition: all 0.5s linear;
    color: ${(props) => (props.active || props.done ? GetColor.HighlightDark : GetColor.Black)};
  }
  @media (max-width: 1280px) {
    &:not(:last-child) {
      margin-right: ${(props) => props.stepWidth - 60}px;
      &:before {
        width: ${(props) => props.stepWidth - 40}px;
      }
    }
  }
`;
