import { useEffect, useState } from 'react';
import type { UserOneTimePassword } from 'venn-api';
import { generateMoreBackupCodes, getBackupCodes } from 'venn-api';
import { map } from 'lodash';
import { saveAs } from 'file-saver';

interface UseBackupCodes {
  backupCodes: UserOneTimePassword[];
  confirmCheckboxClicked: boolean;
  onConfirmClick: () => void;
  downloadCodes: () => void;
  onGenerateMoreCodes: () => void;
  loading: boolean;
  error: string;
}

const useBackupCodes = (): UseBackupCodes => {
  const [backupCodes, setBackupCodes] = useState<UserOneTimePassword[]>([]);
  const [confirmCheckboxClicked, setConfirmCheckboxClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const onConfirmClick = () => {
    setConfirmCheckboxClicked((prev) => !prev);
  };

  const downloadCodes = () => {
    try {
      const blob = new Blob([map(backupCodes, 'code').join('\r\n')], { type: 'text/plain;charset=utf-8' });
      saveAs(blob, 'venn-backup-codes.txt');
    } catch (e) {
      // Suppress errors on downloads
    }
  };

  const onGenerateMoreCodes = async () => {
    setLoading(true);
    try {
      const { content } = await generateMoreBackupCodes();
      setBackupCodes(content);
    } catch (e) {
      setError(e.content?.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    const loadBackupCodes = async () => {
      setLoading(true);
      try {
        const { content } = await getBackupCodes();
        let codes = content;
        if (!codes.length) {
          const newResponse = await generateMoreBackupCodes();
          codes = newResponse.content;
        }
        setBackupCodes(codes);
      } catch (e) {
        setError(e.content?.message);
      }
      setLoading(false);
    };
    loadBackupCodes();
  }, []);

  return {
    backupCodes,
    confirmCheckboxClicked,
    onConfirmClick,
    downloadCodes,
    onGenerateMoreCodes,
    loading,
    error,
  };
};

export default useBackupCodes;
