import React from 'react';
import styled from 'styled-components';
import { DeleteIcon, ErrorMessage } from './styles';
import { GetColor, Icon } from 'venn-ui-kit';

interface ViewActionsProps {
  includedAttributesError?: string;
  isActive: boolean;
  onlyHasOneView: boolean;
  removeCurrentView: () => void;
  onToggle: () => void;
}

const ViewActions = ({
  includedAttributesError,
  isActive,
  onlyHasOneView,
  removeCurrentView,
  onToggle,
}: ViewActionsProps) => {
  if (onlyHasOneView) {
    return null;
  }

  return (
    <ViewActionsGroup>
      {includedAttributesError && <ErrorMessage>{includedAttributesError}</ErrorMessage>}
      <ViewDeleteIcon prefix="fas" type="trash" onClick={removeCurrentView} />
      <ToggleIconContainer>
        <Icon type={isActive ? 'caret-up' : 'caret-down'} onClick={onToggle} />
      </ToggleIconContainer>
    </ViewActionsGroup>
  );
};

export default ViewActions;

const ToggleIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  font-size: 16px;
  line-height: 24px;
  :hover {
    background-color: ${GetColor.Grey};
  }
`;

const ViewActionsGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
`;

const ViewDeleteIcon = styled(DeleteIcon)`
  font-size: 16px;
`;
