import { createContext } from 'react';
import type { TimeFrame } from 'venn-utils';
import { noop } from 'lodash';
import type { FactorTrendRollingPeriods } from './analysis-view-context';

export interface AnalysisConfigContextValue {
  /** Actual analysis timeFrame return from backend */
  actualTimeFrame: TimeFrame;
  unsavedPortfolioChanges: boolean;
  setUnsavedPortfolioChanges: (hasUnsavedChanges: boolean) => void;
  /** factor trend regression years from backend  */
  actualFactorTrendRollingPeriods: FactorTrendRollingPeriods;
  updateFactorTrendRollingResults?: (factorRolling: Partial<FactorTrendRollingPeriods>) => void;
  /** Actual venncast toggle value when we can't venncast */
  venncastOn?: boolean;
  setVenncastOn?: (venncastOn: boolean) => void;
}

export default createContext<AnalysisConfigContextValue>({
  actualTimeFrame: {},
  unsavedPortfolioChanges: false,
  setUnsavedPortfolioChanges: noop,
  actualFactorTrendRollingPeriods: {
    rollingFactorExposuresPeriod: undefined,
    rollingFactorReturnPeriod: undefined,
    rollingFactorRiskPeriod: undefined,
  },
  updateFactorTrendRollingResults: noop,
});
