/*
 * Venn Platform API 1.0.0 Operations for "summary"
 * Automatically generated file - do not modify!
 */

import type { Portfolio, PortfolioSummary } from '../types';
import type { OperationResult } from '../../utils';
import { buildRequestInit, extractError, retryGeneratedFetch } from '../../utils';

/** Get draft portfolio summary */
export async function getDraftSummary(
  body?: Partial<Portfolio>,
  signal?: AbortSignal,
): Promise<OperationResult<PortfolioSummary>> {
  const endpoint = '/api/summary/portfolio/draft';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await retryGeneratedFetch('POST', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: PortfolioSummary = (await response.json()) as PortfolioSummary;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get portfolio summary */
export async function getSummary(id: number, signal?: AbortSignal): Promise<OperationResult<PortfolioSummary>> {
  const endpoint = `/api/summary/portfolio/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: PortfolioSummary = (await response.json()) as PortfolioSummary;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
