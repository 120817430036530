import React from 'react';
import type { StoredSearch } from 'venn-api';
import { deleteSavedSearch } from 'venn-api';
import type { AccordionRendererProps } from 'venn-ui-kit';
import { Icon, Notifications, NotificationType, Radio } from 'venn-ui-kit';
import styled from 'styled-components';
import { isRequestSuccessful, useApi, withSuspense } from 'venn-utils';
import { useRecoilRefresher_UNSTABLE } from 'recoil';
import { savedFiltersSelector } from 'venn-state';
import { FilterPillsComponent } from './FilterPillsComponent';

export const ManageFilterMenuItemRenderer = ({
  value,
  onSelect,
  expanded,
  selected,
}: AccordionRendererProps<StoredSearch>) => {
  const deleteSearch = useApi(deleteSavedSearch);
  const refreshFilters = useRecoilRefresher_UNSTABLE(savedFiltersSelector);

  const onDelete = () => {
    deleteSearch(value.id).then((result) => {
      if (isRequestSuccessful(result)) {
        refreshFilters();
        Notifications.notify(`Deleted filter: ${value?.name}`, NotificationType.SUCCESS);
      } else {
        Notifications.notify(`Failed to delete filter: ${value?.name}`, NotificationType.ERROR);
      }
    });
  };
  return (
    <Container>
      <TopPanel expanded={expanded}>
        <StyledRadio inputId={value.id} checked={selected} onChange={() => onSelect(value)} className="labelFullWidth">
          {value.name}
          {'   '}
          <TrashButton type="submit" onClick={onDelete}>
            <Icon type="trash" tooltip="Delete saved filter" />
          </TrashButton>
        </StyledRadio>
      </TopPanel>
      {expanded && <FilterPillsComponent filterId={value.id} />}
    </Container>
  );
};

const Container = styled.div<{ expanded?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

const TopPanel = styled.div<{ expanded?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  max-width: 280px;
  width: 100%;
  margin-right: 4px;
`;

const TrashButton = styled.button`
  margin-top: 2px;
`;

const StyledRadio = styled(Radio)`
  flex-grow: 1;
  margin-left: 6px;
  & > :last-child {
    width: calc(100% - 16px);
  }
  i {
    opacity: 0;
  }
  :hover {
    i {
      opacity: 1;
    }
  }
`;

export default withSuspense(null, ManageFilterMenuItemRenderer);
