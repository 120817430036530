import type { ComponentType } from 'react';
import type { BlockId } from 'venn-state';

export type ErrorWrapper = ComponentType<{ blockId: BlockId }>;

export const allErrorKinds = [
  'AssetExposure',
  'ComparisonMetrics',
  'ComparisonSubjects',
  'Correlation',
  'NoDateRange',
  'NoOverlap',
  'MissingInfoGraphicType',
  'Metrics',
  'SectorExposure',
  'NoSubjects',
  'TimeSeries',
] as const;

export type ErrorKind = (typeof allErrorKinds)[number];
