import React, { useContext } from 'react';
import { UserContext } from '../../../contexts';
import InvestmentForecastOverridePicker from '../components/InvestmentForecastOverridePicker';
import { Body1, GetColor, Headline3, Icon } from 'venn-ui-kit';
import styled from 'styled-components';
import { selectedInvestmentOverrideValueAtom } from 'venn-state';
import { useSetRecoilState } from 'recoil';

type InvestmentOverrideViewProps = {
  isCreating: boolean;
};

export const InvestmentOverrideView = ({ isCreating }: InvestmentOverrideViewProps) => {
  const setOverrideValue = useSetRecoilState(selectedInvestmentOverrideValueAtom('EDIT'));

  const { hasPermission } = useContext(UserContext);

  return (
    <ViewContainer>
      <ResidualHeadline>{isCreating ? 'Create new' : 'Edit'} Investment Override</ResidualHeadline>
      <ResidualSubHeader>
        <LightbulbIcon type="lightbulb" /> When creating investment overrides, only one can be applied at a time.
      </ResidualSubHeader>
      <Header>
        <Investment>Investment</Investment>
      </Header>
      <InvestmentForecastOverridePicker
        key="custom-selection-picker"
        setOverrideValue={setOverrideValue}
        isCreating={isCreating}
        isReadOnly={!hasPermission('EDIT_FORECASTS')}
      />
    </ViewContainer>
  );
};

const ViewContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const Header = styled(Body1)`
  width: 100%;
  height: 24px;
  min-height: 24px;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-top: 14px;
`;
const Investment = styled.div`
  flex-grow: 1;
`;

const ResidualHeadline = styled(Headline3)`
  margin-top: 18px;
`;

const ResidualSubHeader = styled(Header)`
  color: ${GetColor.DEPRECATED_DivergingColor.B3};
  margin-top: 3px;
`;

const LightbulbIcon = styled(Icon)`
  background-color: ${GetColor.DEPRECATED_DivergingColor.MID};
  padding: 3px 8px 3px 8px;
  margin-right: 10px;
`;
