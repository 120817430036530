import type { DateRange, RangeType } from 'venn-ui-kit';
import type { History, Location } from 'history';
import type { SubjectId } from './types';
import { parseValuesFromLocation, parseValueFromLocation, updateUrlParam } from '../navigation';
import { getRecentDateRange } from './useSavedComparisons';

/**
 * Parses subject ids from the query params of the location.
 * @param location
 * @returns a set of unique IDs. Duplicate subject IDs are ignored.
 */
export const getUniqueSubjectIdsFromLocation = <T>(location: Location<T>): SubjectId[] => {
  const raw = parseValuesFromLocation(location, 'subjects');
  return [...new Set(raw.map((r) => (Number.isNaN(Number(r)) ? r : parseInt(r, 10))))];
};

/**
 * Parses a date range from the location's query parameter.
 * @param location
 */
export const getDateRangeFromLocation = (location: Location<unknown>): DateRange => {
  const from = parseValueFromLocation(location, 'from');
  const to = parseValueFromLocation(location, 'to');
  const period = parseValueFromLocation(location, 'period');
  return {
    from: from ? parseFloat(from) : undefined,
    to: to ? parseFloat(to) : undefined,
    period: period ? (period as RangeType) : undefined,
  };
};

export const getViewIdFromLocation = (location: Location): string | undefined =>
  parseValueFromLocation(location, 'savedId');

export const getWorkspaceIdFromLocation = (location: Location): string | undefined =>
  parseValueFromLocation(location, 'workspaceId');

/** A new view will from a link in omni search or report table.
 * That would look like "/?savedId=xx", and there is no subject ids.
 *  */
export const isNewView = (location: Location) =>
  getViewIdFromLocation(location) && getUniqueSubjectIdsFromLocation(location).length === 0;

/**
 * Use when loading the comparison to determine if a recent comparison should be used
 * If there are no subjects in the URL and there is no saved view, load a recent comparison.
 */
export const shouldUseRecent = (location: Location<unknown>): boolean => {
  return !getUniqueSubjectIdsFromLocation(location).length && !getViewIdFromLocation(location);
};

/**
 * Serializes and stores an {@link DateRange} to the url query parameters.
 * @param history
 * @param dateRange
 */
export const updateUrlDateRange = (history: History, dateRange: DateRange) => {
  if (dateRange.period) {
    updateUrlParam(history, 'REPLACE', 'to', undefined);
    updateUrlParam(history, 'REPLACE', 'from', undefined);
    updateUrlParam(history, 'REPLACE', 'period', dateRange.period);
    return;
  }

  updateUrlParam(history, 'REPLACE', 'to', dateRange.to ? String(dateRange.to) : undefined);
  updateUrlParam(history, 'REPLACE', 'from', dateRange.from ? String(dateRange.from) : undefined);
  updateUrlParam(history, 'REPLACE', 'period', undefined);
};

export const getInitialDateRange = (location: Location): DateRange => {
  const dateRangeFromLocation = getDateRangeFromLocation(location);
  if (
    dateRangeFromLocation &&
    (dateRangeFromLocation.from || dateRangeFromLocation.to || dateRangeFromLocation.period)
  ) {
    return dateRangeFromLocation;
  }
  return shouldUseRecent(location) ? getRecentDateRange() : {};
};
