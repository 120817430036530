import React, { useCallback } from 'react';
import type { LibraryFilterOption } from '../types';
import styled from 'styled-components';
import SimpleFilter from '../SimpleFilter';
import type { QuickFilter } from 'venn-utils';

const fieldName = 'Quick Filters';

interface SourceQuickFilterProps {
  className?: string;
  onFilter: (updated: QuickFilter[]) => void;
  selected: string[];
  closeOnOnlyClick?: boolean;
  sourceItems: LibraryFilterOption<string>[];
}

const SourceQuickFilter = ({
  className,
  selected,
  onFilter,
  closeOnOnlyClick,
  sourceItems,
}: SourceQuickFilterProps) => {
  const handleFilter = useCallback(
    (updated: string | string[]) => {
      const updatedSourceFilters = updated as QuickFilter[];
      onFilter(Array.from(updatedSourceFilters));
    },
    [onFilter],
  );

  return (
    <StyledFilter
      className={className}
      label={fieldName}
      items={sourceItems}
      initialSelected={selected}
      onFilter={handleFilter}
      closeOnOnlyClick={closeOnOnlyClick}
      isAdvanced
      menuWidth={295}
    />
  );
};

const StyledFilter = styled(SimpleFilter)`
  display: inline-flex;
`;

export default SourceQuickFilter;
