import React from 'react';

const AttributionIcon = () => (
  <svg viewBox="0 0 205 115" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity="0.77" x="0.5" y="0.5" width="204" height="113.525" fill="#F7F7F7" stroke="#D8D8D8" />
    <g opacity="0.77">
      <rect x="13.743" y="11.4531" width="177.514" height="4.58101" fill="#B7793A" />
      <rect x="13.743" y="11.4531" width="177.514" height="4.58101" stroke="#D8D8D8" />
    </g>
    <g opacity="0.77">
      <rect x="13.743" y="19.4688" width="177.514" height="9.16201" fill="#B7793A" />
      <rect x="13.743" y="19.4688" width="177.514" height="9.16201" stroke="#D8D8D8" />
    </g>
    <rect opacity="0.77" x="14.243" y="29.1309" width="176.514" height="74.5866" fill="white" stroke="#C8D1E0" />
    <line x1="33.7123" y1="29.7773" x2="33.7123" y2="104.219" stroke="#C8D1E0" />
    <line x1="191.257" y1="41.7285" x2="13.743" y2="41.7285" stroke="#C8D1E0" />
    <line x1="191.257" y1="66.9238" x2="13.743" y2="66.9238" stroke="#C8D1E0" />
    <line x1="191.257" y1="92.1191" x2="13.743" y2="92.1191" stroke="#C8D1E0" />
    <line x1="191.257" y1="54.3262" x2="13.743" y2="54.3262" stroke="#C8D1E0" />
    <line x1="191.257" y1="79.5215" x2="13.743" y2="79.5215" stroke="#C8D1E0" />
    <line x1="53.1816" y1="29.7773" x2="53.1816" y2="104.219" stroke="#C8D1E0" />
    <line x1="72.6508" y1="29.7773" x2="72.6508" y2="104.219" stroke="#C8D1E0" />
    <line x1="92.1201" y1="29.7773" x2="92.1201" y2="104.219" stroke="#C8D1E0" />
    <line x1="111.589" y1="29.7773" x2="111.589" y2="104.219" stroke="#C8D1E0" />
    <line x1="131.059" y1="29.7773" x2="131.059" y2="104.219" stroke="#C8D1E0" />
    <line x1="150.528" y1="29.7773" x2="150.528" y2="104.219" stroke="#C8D1E0" />
    <line x1="169.997" y1="29.7773" x2="169.997" y2="104.219" stroke="#C8D1E0" />
    <rect x="56.1173" y="32.0664" width="13.743" height="6.87151" rx="2" fill="#3E9397" />
    <rect x="95.0558" y="44.6641" width="13.743" height="6.87151" rx="2" fill="#3E9397" />
    <rect x="114.525" y="57.2617" width="13.743" height="6.87151" rx="2" fill="#3E9397" />
    <rect x="114.525" y="69.8594" width="13.743" height="6.87151" rx="2" fill="#3E9397" />
    <rect x="114.525" y="82.459" width="13.743" height="6.87151" rx="2" fill="#3E9397" />
    <rect x="17.1788" y="82.459" width="13.743" height="6.87151" rx="2" fill="#3E9397" />
  </svg>
);

export default AttributionIcon;
