import type { PeerGroupAnalysisResponse } from 'venn-api';

export const mockPGAData: PeerGroupAnalysisResponse = {
  subjectsData: {
    YTD: {
      SHARPE_RATIO: [
        {
          value: 0.671,
          rank: 5,
          percentile: 80,
        },
        {
          value: 0.6,
          rank: 5,
          percentile: 80,
        },
      ],
      PERIOD_RETURN: [
        {
          value: 0.06,
          rank: 1,
          percentile: 2,
        },
        {
          value: 0.6,
          rank: 1,
          percentile: 2,
        },
      ],
      PERIOD_EXCESS_RETURN: [
        {
          value: 0.6,
          rank: 3,
          percentile: 20,
        },
        {
          value: 0.65,
          rank: 3,
          percentile: 20,
        },
      ],
    },
    YEAR_5: {
      SHARPE_RATIO: [
        {
          value: 0.912,
          rank: 5,
          percentile: 15,
        },
        {
          value: 0.9,
          rank: 50,
          percentile: 1,
        },
      ],
      PERIOD_RETURN: [
        {
          value: 0.37,
          rank: 1,
          percentile: 67,
        },
        {
          value: 0.3,
          rank: 10,
          percentile: 6,
        },
      ],
    },
  },
  universeData: {
    YTD: {
      SHARPE_RATIO: {
        constituents: 27,
        p5: 0.0,
        p25: 0.25,
        p50: 0.5,
        p75: 0.88,
        p95: 2.0,
      },
      PERIOD_RETURN: {
        constituents: 40,
        p5: -0.5,
        p25: 0,
        p50: 0.5,
        p75: 0.79,
        p95: 2.0,
      },
      PERIOD_EXCESS_RETURN: {
        constituents: 20,
        p5: -0.4,
        p25: 0.1,
        p50: 0.4,
        p75: 0.8,
        p95: 2.15,
      },
    },
    YEAR_5: {
      SHARPE_RATIO: {
        constituents: 25,
        p5: 0.0,
        p25: 0.25,
        p50: 0.5,
        p75: 0.88,
        p95: 3.0,
      },
      PERIOD_RETURN: {
        constituents: 35,
        p5: -0.5,
        p25: 0,
        p50: 0.5,
        p75: 0.79,
        p95: 0.85,
      },
    },
  },
};
