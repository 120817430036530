import moment from 'moment';
import type { AppUser } from 'venn-api';
import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import { EllipsisTooltipSpan, GetColor, TooltipPosition } from 'venn-ui-kit';

export interface ChangeIndicatorProps {
  changed?: boolean;
  className?: string;
}

export const ChangeIndicator = styled.span<ChangeIndicatorProps>`
  input,
  span {
    color: ${(props) => (props.changed ? GetColor.Primary.Main : 'inherit')};
    transition: color 0.3s ease-out;
  }
`;

export interface NameProps {
  name: string;
  changed?: boolean;
  className?: string;
}

export const Name: FC<React.PropsWithChildren<NameProps>> = ({ name, changed, className }) => (
  <ChangeIndicator changed={changed} className={className}>
    <EllipsisTooltipSpan block className={className} position={TooltipPosition.Right}>
      {name}
    </EllipsisTooltipSpan>
  </ChangeIndicator>
);

export interface ReturnsProps {
  start?: number;
  end?: number;
  outdated?: boolean;
}

const format = (timestamp: number) => moment(timestamp).format('MM/DD/YYYY');

export const Returns: FC<React.PropsWithChildren<ReturnsProps>> = ({ start, end, outdated }) =>
  start && end ? (
    <StyledReturns outdated={outdated}>
      {format(start)} - {format(end)}
    </StyledReturns>
  ) : (
    <span>--</span>
  );

const StyledReturns = styled.span<{ outdated?: boolean }>`
  color: ${(props) => (props.outdated ? GetColor.Error : 'inherit')};
`;

export interface UserProps {
  user: AppUser;
  className?: string;
}

export const User = ({ user, className }: UserProps) => (
  <EllipsisTooltipSpan className={className} block>
    {user.name}
  </EllipsisTooltipSpan>
);
