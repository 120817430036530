import { isNil } from 'lodash';
import { assertNotNil, FootnoteSymbols, Numbers, type TabularDataTypeEnum } from 'venn-utils';
import { formatData } from '../../../../data-grid';
import type { PGARowData } from './types';
import { getAppTitle } from 'venn-ui-kit';

export const PGA_RANK_DISCLAIMER = `${FootnoteSymbols.pgaRank} 'Rank' (Percentile Rank) and 'Percentile' displayed based on funds 
and data available within ${getAppTitle()}’s Data library at the time of analysis.`;

type GetterProps = {
  data: PGARowData | undefined;
  index: number;
};
type FormatterProps = {
  data: PGARowData | undefined;
  index: number;
  dataType?: TabularDataTypeEnum;
};

export const valueColumnGetter = ({ data, index }: GetterProps) => {
  if (data?.rowId === 'constituents') {
    const value = data?.rowData[index]?.value;
    if (isNil(value)) {
      return 0;
    }
    return Math.floor(value);
  }
  if (data?.isMetadata) {
    return null;
  }
  const value = data?.rowData[index]?.value;
  if (isNil(value)) {
    return null;
  }
  return value;
};

export const valueColumnFormatter = ({ data, index, dataType }: FormatterProps) => {
  const value = valueColumnGetter({
    data,
    index,
  });
  if (data?.rowId === 'constituents') {
    return Numbers.formatNumber(assertNotNil(value), 0);
  }
  if (data?.isMetadata) {
    return '';
  }
  return isNil(value) ? '--' : `${formatData(value, dataType, 2)}`;
};

export const rankColumnGetter = ({ data, index }: GetterProps) => {
  if (data?.isMetadata || data?.isUniverse) {
    return null;
  }
  const value = data?.rowData[index]?.rank;
  return value ?? null;
};

export const rankColumnFormatter = ({ data, index }: FormatterProps) => {
  if (data?.isMetadata || data?.isUniverse) {
    return '';
  }
  const value = rankColumnGetter({
    data,
    index,
  });
  return isNil(value) ? '--' : Numbers.formatNumber(value, 0);
};
