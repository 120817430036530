import React, { useState } from 'react';
import { FlexRow, FlexWrapper, Info, Name, StyledButton, StyledIcon, StyledWrapper } from '../shared';
import styled from 'styled-components';
import IntegrationRequestModal from './IntegrationRequestModal';
import { getAppTitle, Link as LinkText } from 'venn-ui-kit';
import { AddeparSetupPanel, useAddeparSetupPanel } from './addepar';
import useAddeparList from './addepar/useAddeparList';
import AddeparInstancesTable from './addepar/AddeparInstancesTable';
import AddeparHelpModal from './addepar/AddeparHelpModal';
import AddeparDeleteConfirmationModal from './addepar/AddeparDeleteConfirmationModal';
import { analyticsService } from 'venn-utils';

const AddeparIntegration = () => {
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);

  const {
    refetchClientIntegrations,
    isDeleteModalOpen,
    onDeleteCancel,
    onDeleteData,
    onDeleteKeepData,
    isDeleting,
    clientName,
    ...listState
  } = useAddeparList();
  const state = useAddeparSetupPanel();
  const hasIntegration = listState.clientIntegrations.length > 0;
  const buttonWidth = hasIntegration ? ANOTHER_ACCOUNT_BUTTON_WIDTH : NEW_ACCOUNT_BUTTON_WIDTH;
  const linkText = hasIntegration ? 'Link Another Addepar Account' : 'Link Addepar Account';
  return (
    <>
      <StyledWrapper id="addepar">
        <FlexRow>
          <FlexWrapper>
            <StyledIcon type="exchange" />
            <Name>Addepar Integration</Name>
          </FlexWrapper>
          <StyledButton
            onClick={() => {
              state.onOpen();
              analyticsService.ctaClicked({
                purpose: 'Link Addepar Account',
                text: linkText,
              });
            }}
          >
            {linkText}
          </StyledButton>
        </FlexRow>
        <MaxWidthInfo buttonWidth={buttonWidth}>
          Integrate your Addepar portfolio data with {getAppTitle()} in just a few minutes! Daily syncs ensure your
          portfolios are never out of date. Interested in a different integration?{' '}
          <LinkText onClick={() => setShowRequestModal(true)}>Request other integrations here</LinkText>.
        </MaxWidthInfo>
        <AddeparInstancesTable {...listState} onEditClient={state.editClientIntegration} onOpenPanel={state.onOpen} />
      </StyledWrapper>
      {showRequestModal && <IntegrationRequestModal request="" onClose={() => setShowRequestModal(false)} />}
      <AddeparSetupPanel
        {...state}
        refetchClientIntegrations={refetchClientIntegrations}
        openHelpModal={() => setShowHelpModal(true)}
      />
      {showHelpModal && <AddeparHelpModal onCancel={() => setShowHelpModal(false)} />}
      {isDeleteModalOpen && clientName && (
        <AddeparDeleteConfirmationModal
          onCancel={onDeleteCancel}
          clientName={clientName}
          isDeleting={isDeleting}
          onDeleteData={onDeleteData}
          onDeleteKeepData={onDeleteKeepData}
        />
      )}
    </>
  );
};

export default AddeparIntegration;

const NEW_ACCOUNT_BUTTON_WIDTH = 202;
const ANOTHER_ACCOUNT_BUTTON_WIDTH = 252;
const MaxWidthInfo = styled(Info)<{ buttonWidth: number }>`
  max-width: calc(100% - ${({ buttonWidth }) => buttonWidth}px);
`;
