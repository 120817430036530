import React, { PureComponent } from 'react';
import type { Theme } from 'venn-ui-kit';
import type { DataPoint } from '../../types';
import { select } from 'd3-selection';
import { extent } from 'd3-array';
import { scaleLinear, scaleTime } from 'd3-scale';
import { withTheme } from 'styled-components';

const height = 60;
const width = 122;

interface MiniChartProps {
  /**
   * Time series for the new data (black line)
   */
  data: DataPoint[];
  /**
   * Time series for the secondary, historic data (grey line)
   */
  previousData?: DataPoint[];
  /**
   * Date at which the chart is starting. Defaults to the first data point of
   * either data or previousData if provided
   */
  from?: number;
  /**
   * Override color for the new data. If all the data are new, default is GetTheme.HighlightDark
   * and if not, the new data line color is black.
   */
  newDataColor?: string;
  /**
   * The current theme
   */
  theme: Theme;
}

export class MiniChart extends PureComponent<MiniChartProps> {
  node: SVGSVGElement | null;

  constructor(props: MiniChartProps) {
    super(props);
    this.buildChart = this.buildChart.bind(this);
  }

  componentDidMount() {
    this.buildChart();
  }

  componentDidUpdate() {
    this.buildChart();
  }

  async buildChart() {
    const { node } = this;
    if (!node) {
      return;
    }
    node.innerHTML = '';

    const shape = await import('d3-shape');
    const line = shape.line;

    const {
      data,
      from,
      previousData = [],
      newDataColor,
      theme: { Colors },
    } = this.props;

    if (!data.length && !previousData.length) {
      return;
    }

    const margins = { top: 3, bottom: 3, right: 3, left: 3 };
    const svg = select(node);
    const series = svg.append('g').attr('transform', `translate(${margins.left}, ${margins.top})`);

    const datum = data.map((d) => [d.x, d.y]);
    const previousDatum = previousData ? previousData.map((d) => [d.x, d.y]) : [];
    const both = [...previousDatum, ...datum];

    const xRange = extent(both, (d) => d[0]) as [number, number];
    const x = scaleTime()
      .rangeRound([0, width - margins.left - margins.right])
      .domain(from ? [from, xRange[1]] : xRange);
    const y = scaleLinear()
      .rangeRound([height - margins.top - margins.bottom, 0])
      .domain(extent(both, (d) => d[1]) as [number, number]);
    const serie = line()
      .x((d) => x(d[0]))
      .y((d) => y(d[1]));

    const newDataLineColor = newDataColor ?? (previousDatum.length ? Colors.Black : Colors.HighlightDark);

    series
      .append('path')
      .datum(datum)
      .attr('fill', 'none')
      .attr('stroke', newDataLineColor)
      .attr('stroke-linejoin', 'round')
      .attr('stroke-linecap', 'round')
      .attr('stroke-width', 3)
      // @ts-expect-error: TODO fix strictFunctionTypes
      .attr('d', serie);

    series
      .append('path')
      .datum(previousDatum)
      .attr('fill', 'none')
      .attr('stroke', Colors.LightGrey)
      .attr('stroke-linejoin', 'round')
      .attr('stroke-linecap', 'round')
      .attr('stroke-width', 1)
      // @ts-expect-error: TODO fix strictFunctionTypes
      .attr('d', serie);
  }

  render() {
    return <svg data-testid="qa-minichart" width={width} height={height} ref={(node) => (this.node = node)} />;
  }
}

export default withTheme(MiniChart);
