import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';

interface FaqLinkProps {
  link: string;
  className?: string;
}

const FaqLink: FC<React.PropsWithChildren<FaqLinkProps>> = ({ className, link }) => (
  <Link target="_blank" rel="noopener noreferrer" href={link} className={className}>
    FAQ
  </Link>
);

export default FaqLink;

const Link = styled.a`
  font-size: 14px;
  text-decoration: none;
  color: ${GetColor.Primary.Dark};
  font-weight: bold;
`;
