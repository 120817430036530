import type { FC } from 'react';
import React from 'react';
import Benchmark from './Benchmark';
import type { BenchmarkShape } from '../types';
import type { SearchMenuItem } from 'venn-components';

export interface BenchmarksListProps {
  benchmarks: BenchmarkShape[];
  onChange(index: number, modifier: (benchmark: BenchmarkShape) => BenchmarkShape): void;
  onRemove(index: number): void;
  resultsFilter(item: SearchMenuItem): boolean;
}

const BenchmarksList: FC<React.PropsWithChildren<BenchmarksListProps>> = ({
  benchmarks,
  onChange,
  onRemove,
  resultsFilter,
}) => (
  <div>
    {benchmarks.map((benchmark, index) => (
      <Benchmark
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        index={index}
        {...benchmark}
        onChange={onChange}
        onRemove={(benchmarks.length > 1 && onRemove) || undefined}
        resultsFilter={resultsFilter}
      />
    ))}
  </div>
);

export default BenchmarksList;
