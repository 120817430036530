import React, { useContext, useState } from 'react';
import type { CustomFactorForecast, RegressionPeriodEnum } from 'venn-api';
import type { FactorLensesContextValue } from '../../../contexts';
import { FactorLensesContext } from '../../../contexts';
import {
  Body1,
  getAppTitle,
  GetColor,
  Headline3,
  Icon,
  Radio,
  Tooltip,
  TooltipBodyDirection,
  TooltipPosition,
} from 'venn-ui-kit';
import styled from 'styled-components';
import { analyticsService, assertExhaustive, Dates } from 'venn-utils';
import type { FactorForecastRightPanelView } from 'venn-state';
import {
  activeForecastIdAtom,
  isFactorForecastEditingAtom,
  isSystemForecast,
  remoteFactorForecast,
  remoteFactorForecastList,
} from 'venn-state';
import { useRecoilValue } from 'recoil';
import { flatten, isNil, partition } from 'lodash';
import ForecastButton from './ForecastButton';

const TOOLTIP_MAX_WIDTH = 340;

const DEFAULT_REGRESSION_PERIOD = '3 Year';

interface FactorForecastsContainerV2Props {
  onSetFactorForecastAsDefault: (forecast: CustomFactorForecast) => void;
  isReadOnly?: boolean;
  onDelete: (forecast: CustomFactorForecast) => void;
  setRightPanelView: (view: FactorForecastRightPanelView) => void;
}

const getRegressionPeriodText = (period: RegressionPeriodEnum) => {
  switch (period) {
    case 'FULL':
      return 'Full History';
    case 'YEAR_3':
      return '3 Year';
    case 'YEAR_5':
      return '5 Year';
    case 'YEAR_7':
      return '7 Year';
    case 'YEAR_10':
      return '10 Year';
    default:
      throw assertExhaustive(period, 'unexpected RegressionPeriodEnum');
  }
};

export const getForecastType = (forecast: CustomFactorForecast) =>
  forecast.investmentForecasts.length ? 'CMA Forecast' : 'Historical Returns Forecast';

const DisabledButtonForVennForecast = ({
  testid,
  iconType,
  message,
}: {
  testid: string;
  iconType: string;
  message?: string;
}) => (
  <StyledTooltip
    position={TooltipPosition.Right}
    bodyDirection={TooltipBodyDirection.Right}
    content={<>{message ?? `Cannot edit ${getAppTitle()} default forecast.`}</>}
    maxWidth={TOOLTIP_MAX_WIDTH}
  >
    <IconButton data-testid={testid} type="button" disabled>
      <Icon type={iconType} />
    </IconButton>
  </StyledTooltip>
);

export const getHistoricalPeriod = (forecast: CustomFactorForecast, factorContext: FactorLensesContextValue) => {
  const range = {
    from: factorContext.primaryFactorLens?.latestStartDate,
    to: factorContext.primaryFactorLens?.earliestEndDate,
  };
  const historicalPeriod = {
    from: forecast?.startDate ?? range.from,
    to: forecast?.endDate ?? range.to,
  };

  return (
    historicalPeriod.from &&
    historicalPeriod.to &&
    `${Dates.toDayMonthYear(historicalPeriod.from).toLocaleUpperCase()}-${Dates.toDayMonthYear(
      historicalPeriod.to,
    ).toLocaleUpperCase()}`
  );
};

const getUpdatedByText = (forecast: CustomFactorForecast) => {
  const lastUpdatedUser = forecast.updatedBy?.displayName;
  const lastUpdatedDate = forecast.created ? Dates.toDayMonthYear(forecast.created) : '';

  return `Last updated by ${lastUpdatedUser} ${lastUpdatedDate}`;
};

const getRegressionPeriodLabel = (forecast: CustomFactorForecast) => {
  if (forecast.regressionEnd && forecast.regressionStart) {
    return `${Dates.toDayMonthYear(forecast.regressionStart)}-${Dates.toDayMonthYear(forecast.regressionEnd)}`;
  }

  if (forecast.regressionPeriod) {
    return getRegressionPeriodText(forecast.regressionPeriod);
  }

  return DEFAULT_REGRESSION_PERIOD;
};

/**
 * return the factor forecast list sorted in descending order by date created
 * with the system forecast at the beginning of the list
 * */
const getSortedForecastList = (forecasts: CustomFactorForecast[]) => {
  const sortedForecastsList = forecasts.slice(0).sort((a, b) => b.created - a.created);

  return flatten(partition(sortedForecastsList, (forecast) => isSystemForecast(forecast)));
};

const numberOfFactorsModified = (forecast: CustomFactorForecast): number => {
  const factorOverrides = forecast.factorForecasts
    .map((factorForecast) => factorForecast.annualizedReturnOverride)
    .filter((override) => override !== undefined).length;
  const cashOverrides = isNil(forecast.cashForecastOverride) ? 0 : 1;
  return factorOverrides + cashOverrides;
};

export const FactorForecastsContainerV2 = ({
  onSetFactorForecastAsDefault,
  isReadOnly,
  onDelete,
  setRightPanelView,
}: FactorForecastsContainerV2Props) => {
  const [forecastListExpanded, setForecastListExpanded] = useState(false);
  const factorForecasts = useRecoilValue(remoteFactorForecastList);
  const activeForecastId = useRecoilValue(activeForecastIdAtom);
  const activeForecast = useRecoilValue(remoteFactorForecast(activeForecastId));
  const isFactorForecastEditing = useRecoilValue(isFactorForecastEditingAtom);

  const factorContext = useContext(FactorLensesContext);

  const onCreateNewForecast = (historicalForecast: boolean) => {
    setRightPanelView(historicalForecast ? { type: 'HistoricalPeriodCreator' } : { type: 'CMAsCreator' });
  };

  const forecastListWithoutActiveForecast = factorForecasts.filter(({ forecastId }) => forecastId !== activeForecastId);
  const sortedForecastsList = getSortedForecastList(forecastListWithoutActiveForecast);
  const isForecastEditable = activeForecast && !isSystemForecast(activeForecast);

  return (
    <ForecastsContainer>
      <ForecastHeadline>Active Forecast</ForecastHeadline>
      {activeForecast && (
        <ActiveForecastContainer>
          <ForecastItem
            forecast={activeForecast}
            onDelete={onDelete}
            isListItem={false}
            factorContext={factorContext}
            isReadOnly={isReadOnly}
            onSetFactorForecastAsDefault={onSetFactorForecastAsDefault}
            setRightPanelView={setRightPanelView}
            isFactorForecastEditing={isFactorForecastEditing}
          />
        </ActiveForecastContainer>
      )}
      <FactorExposuresContainer>
        <Row>
          Factor Exposure Period &nbsp;
          <Tooltip
            position={TooltipPosition.Right}
            bodyDirection={TooltipBodyDirection.Right}
            content={
              <>
                {`This setting controls the analysis period over which ${getAppTitle()} calculates the exposure of the subject being
                analyzed to ${getAppTitle()}’s factors. By default, ${getAppTitle()} calculates the exposure over the past 3 years.`}
              </>
            }
            maxWidth={TOOLTIP_MAX_WIDTH}
          >
            <StyledIcon type="info-circle" prefix="far" />
          </Tooltip>
        </Row>
        {activeForecast && (
          <Row>
            {getRegressionPeriodLabel(activeForecast)}
            {isSystemForecast(activeForecast) ? (
              <DisabledButtonForVennForecast testid="qa-edit-exposure-period" iconType="edit" />
            ) : (
              !isReadOnly && (
                <IconButton
                  data-testid="qa-edit-exposure-period"
                  type="button"
                  onClick={() => setRightPanelView({ type: 'ExposurePeriodEditor' })}
                  disabled={isFactorForecastEditing}
                >
                  <Icon type="edit" />
                </IconButton>
              )
            )}
          </Row>
        )}
        <Body1>{activeForecast && getUpdatedByText(activeForecast)}</Body1>
        {isForecastEditable && (
          <NumberOfFactorsModifiedContainer>
            {`Factors Modified: ${numberOfFactorsModified(activeForecast)}`}
          </NumberOfFactorsModifiedContainer>
        )}
      </FactorExposuresContainer>
      <hr />
      <ForecastHeadline>Manage Forecasts</ForecastHeadline>
      {!isReadOnly && (
        <ButtonRow>
          <ForecastButton dataTestId="qa-create-historical-forecast" onClick={() => onCreateNewForecast(true)}>
            Create New Historical
          </ForecastButton>
          <ForecastButton dataTestId="qa-create-CMA-forecast" onClick={() => onCreateNewForecast(false)}>
            Create New CMA
          </ForecastButton>
        </ButtonRow>
      )}
      <ForecastListContainer data-testid="qa-factor-forecast-list">
        <Row>
          <StyledIconButton
            data-testid="qa-expand-forecast-list"
            onClick={() => setForecastListExpanded(!forecastListExpanded)}
          >
            <Icon type={forecastListExpanded ? 'chevron-down' : 'chevron-right'} />
            <SeeForecast>
              &nbsp;
              {sortedForecastsList.length > 0 && `See Forecast List (${sortedForecastsList.length})`}
            </SeeForecast>
          </StyledIconButton>
        </Row>
        {forecastListExpanded &&
          sortedForecastsList.map((forecast) => (
            <ForecastItem
              key={forecast.forecastId}
              forecast={forecast}
              onDelete={onDelete}
              isListItem
              factorContext={factorContext}
              isReadOnly={isReadOnly}
              onSetFactorForecastAsDefault={onSetFactorForecastAsDefault}
              setRightPanelView={setRightPanelView}
              isFactorForecastEditing={isFactorForecastEditing}
            />
          ))}
      </ForecastListContainer>
    </ForecastsContainer>
  );
};

export default FactorForecastsContainerV2;

interface ForecastItemProps {
  forecast: CustomFactorForecast;
  /** whether this forecast is being displayed in the expandable list of forecasts */
  isListItem: boolean;
  factorContext: FactorLensesContextValue;
  onSetFactorForecastAsDefault: (forecast: CustomFactorForecast) => void;
  isReadOnly?: boolean;
  setRightPanelView: (view: FactorForecastRightPanelView) => void;
  onDelete: (forecast: CustomFactorForecast) => void;
  isFactorForecastEditing: boolean;
}

const ForecastItem = ({
  forecast,
  isListItem,
  onSetFactorForecastAsDefault,
  onDelete,
  factorContext,
  isReadOnly,
  setRightPanelView,
  isFactorForecastEditing,
}: ForecastItemProps) => {
  const onViewForecasts = (forecast: CustomFactorForecast) => {
    setRightPanelView({
      type: 'SpecificForecast',
      forecast,
    });
  };
  const activeForecastId = useRecoilValue(activeForecastIdAtom);
  const isActiveForecast = forecast.forecastId === activeForecastId;

  const getDeleteButtonForForecast = () => {
    // do not show delete button in read only mode
    if (isReadOnly) {
      return null;
    }

    if (isSystemForecast(forecast)) {
      return (
        <DisabledButtonForVennForecast
          testid="qa-delete-forecast-button"
          iconType="trash"
          message={`Cannot delete ${getAppTitle()} default forecast`}
        />
      );
    }

    if (isActiveForecast) {
      return (
        <DisabledButtonForVennForecast
          testid="qa-delete-forecast-button"
          iconType="trash"
          message="Cannot delete active forecast"
        />
      );
    }

    if (isFactorForecastEditing) {
      return (
        <DisabledButtonForVennForecast
          testid="qa-delete-forecast-button"
          iconType="trash"
          message="Cannot delete forecast when currently editing individual factors"
        />
      );
    }

    return (
      <IconButton data-testid="qa-delete-forecast-button" onClick={() => onDelete(forecast)}>
        <Icon type="trash" />
      </IconButton>
    );
  };

  const getEditButtonForForecast = () => {
    if (isReadOnly) {
      return null;
    }

    if (isSystemForecast(forecast)) {
      return <DisabledButtonForVennForecast testid="qa-edit-forecast-button" iconType="edit" />;
    }

    if (isFactorForecastEditing) {
      return (
        <DisabledButtonForVennForecast
          testid="qa-edit-forecast-button"
          iconType="edit"
          message="Cannot edit forecasts when currently editing individual factors"
        />
      );
    }

    return (
      <IconButton
        data-testid="qa-edit-forecast-button"
        onClick={() => {
          setRightPanelView(
            forecast.investmentForecasts.length
              ? { type: 'CMAsEditor', forecast }
              : { type: 'HistoricalPeriodEditor', forecast },
          );
        }}
      >
        <Icon type="edit" />
      </IconButton>
    );
  };

  const getViewOrModifyFactorButtonForForecast = () => {
    const button = (
      <IconButton
        data-testid="qa-view-forecasts-button"
        onClick={() => {
          onViewForecasts(forecast);
          if (isForecastEditable) {
            analyticsService.ctaClicked({
              text: 'Modify forecasts',
              purpose: 'Modify forecasts',
            });
          }
        }}
        disabled={isFactorForecastEditing}
      >
        {' '}
        {isForecastEditable ? 'View/Override' : 'View'} Forecasts
      </IconButton>
    );
    if (isFactorForecastEditing) {
      return (
        <StyledTooltip
          position={TooltipPosition.Right}
          bodyDirection={TooltipBodyDirection.Right}
          content="Cannot edit forecasts when currently editing individual factors."
          maxWidth={TOOLTIP_MAX_WIDTH}
        >
          {button}
        </StyledTooltip>
      );
    }
    return button;
  };

  const getSelectToActivateRadio = () => {
    return (
      <>
        <Tooltip
          position={TooltipPosition.Right}
          bodyDirection={TooltipBodyDirection.Right}
          content={
            isFactorForecastEditing ? (
              'Cannot edit forecasts when currently editing individual factors.'
            ) : (
              <>
                Set this as your organisation's active forecast.
                <Warning>This will change the forecasts for your entire organization.</Warning>
              </>
            )
          }
          maxWidth={TOOLTIP_MAX_WIDTH}
        >
          <Radio
            inputId={`${forecast.name}_is_active`}
            value={`${forecast.name}_is_active`}
            checked={isActiveForecast}
            onChange={() => onSetFactorForecastAsDefault(forecast)}
            disabled={isFactorForecastEditing}
          />
        </Tooltip>
        <Body1>Select to Activate</Body1>
      </>
    );
  };

  const isForecastEditable = !isSystemForecast(forecast);

  return (
    <div id={`factor_forecast_list_item_${forecast.forecastId}`}>
      {!isListItem ? (
        <>
          <IconsRow>
            <Name>{forecast.name}</Name>
            <ForecastManageIcons>{getEditButtonForForecast()}</ForecastManageIcons>
          </IconsRow>
          <Row>
            {getForecastType(forecast)}
            {getViewOrModifyFactorButtonForForecast()}
          </Row>
          <Row>{getHistoricalPeriod(forecast, factorContext)}</Row>
        </>
      ) : (
        <>
          <IconsRow>
            <StyledForecastName>{forecast.name}</StyledForecastName>
            <ForecastManageIcons>
              {getDeleteButtonForForecast()}
              &nbsp; &nbsp;
              {getEditButtonForForecast()}
            </ForecastManageIcons>
          </IconsRow>
          <Row>
            {getForecastType(forecast)}
            {getViewOrModifyFactorButtonForForecast()}
          </Row>
          <Row>
            Factor Exposure Period: &nbsp;
            {getRegressionPeriodLabel(forecast)}
          </Row>
          {isForecastEditable && (
            <NumberOfFactorsModifiedContainer>
              {`Factors Modified: ${numberOfFactorsModified(forecast)}`}
            </NumberOfFactorsModifiedContainer>
          )}
          <Row>{!isReadOnly && getSelectToActivateRadio()}</Row>
        </>
      )}
    </div>
  );
};

const ForecastsContainer = styled(Body1)`
  margin-top: 28px;
  padding-right: 20px;
`;

const StyledTooltip = styled(Tooltip)`
  margin-left: auto;
  margin-top: 3px;
`;

const ActiveForecastContainer = styled(Body1)``;

const ForecastHeadline = styled(Headline3)`
  margin-top: 26px;
`;

const IconButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  margin-left: auto;
  font-size: 14px;
  margin-top: 4px;
`;

const StyledIconButton = styled(IconButton)`
  margin-left: 0px;
`;

const StyledIcon = styled(Icon)`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  margin-left: auto;
  font-size: 14px;
  color: ${GetColor.Primary.Dark};
`;
const SeeForecast = styled.div`
  padding-left: 3px;
  font-weight: normal;
  color: ${GetColor.Black};
`;

const Name = styled(Body1)`
  font-weight: 700;
  line-height: 24px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const NumberOfFactorsModifiedContainer = styled.div`
  font-style: italic;
  font-weight: 700;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const FactorExposuresContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const ForecastListContainer = styled.div`
  margin-top: 6px;
`;

const Warning = styled.div`
  font-size: 10px;
  font-style: italic;
  color: ${GetColor.Alert};
`;

const ForecastManageIcons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
`;

const IconsRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledForecastName = styled(Name)`
  margin-top: 24px;
`;
