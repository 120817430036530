import type AnalysisSubject from './AnalysisSubject';
import Dates from '../dates';
import type { StudioRequestSubject, SubjectWithOptionalFee } from 'venn-state';

export const getSecondaryDisplayLabel = (
  subject: AnalysisSubject,
  savedVersionPrefix?: string,
  nonSavedVersionSuffix?: string,
): string => {
  if (subject.secondaryLabel === 'Last Saved') {
    return subject.secondaryPortfolio?.updated !== undefined
      ? `${savedVersionPrefix ? `${savedVersionPrefix} ` : ''}${Dates.toDayMonthShortYearTime(
          subject.secondaryPortfolio.updated,
        )}`
      : '';
  }
  return `${subject.secondaryLabel}${nonSavedVersionSuffix ? ` ${nonSavedVersionSuffix}` : ''}`;
};

export const isRequestSubject = (
  subject: StudioRequestSubject | SubjectWithOptionalFee,
): subject is StudioRequestSubject => 'id' in subject;

export const getBaseFee = (subject: StudioRequestSubject | SubjectWithOptionalFee) => {
  const id = isRequestSubject(subject) ? subject.id : subject.portfolioId ?? subject.fundId;
  return (id && subject.feesMapping?.[id]) || 0;
};

export const hasFeesExcluded = (subject: StudioRequestSubject | SubjectWithOptionalFee) =>
  Object.keys(subject.feesMapping ?? {}).length > 1;

export const hasFeesAndExcludedInvestments = (subject: StudioRequestSubject | SubjectWithOptionalFee) =>
  getBaseFee(subject) && hasFeesExcluded(subject);
