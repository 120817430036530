import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import type { AnalysisViewTypeEnum } from 'venn-api';
import { getSpecificAnalysisView, saveAnalysisView, viewEntity } from 'venn-api';
import type { DateRange } from 'venn-ui-kit';
import { Notifications, NotificationType } from 'venn-ui-kit';
import type { AnalysisSubject, SubjectId } from 'venn-utils';
import {
  analyticsService,
  getRecentComparisonIds,
  getRecentViewId,
  getUniqueSubjectIdsFromLocation,
  getViewIdFromLocation,
  getWorkspaceIdFromLocation,
  isNewView,
  logExceptionIntoSentry,
  Routes,
  saveComparionsToLocal,
  shouldUseRecent,
  updateUrlDateRange,
  updateUrlParam,
  useCachedSubjects,
  useIsMounted,
} from 'venn-utils';
import { SavedViewMessage, UserContext } from 'venn-components';
import compact from 'lodash/compact';
import isNil from 'lodash/isNil';
import useBenchmark from './useBenchmark';
import useIsPivoted from './useIsPivoted';
import type { ComparisonViewBaseline } from './comparisonViewUtils';
import {
  compareDiff,
  convertToView,
  convertViewToBaseline,
  emptyBaseline,
  getInitialDateRange,
} from './comparisonViewUtils';
import moment from 'moment';

const VIEW_ERROR_MESSAGE = 'Saved View failed to open';

/**
 * Manage view related params: subjects, daterange and benchmark
 */
const useComparisonView = () => {
  const history = useHistory();
  const [subjects, setSubjects] = useState<AnalysisSubject[]>([]);
  const [dateRange, setDateRange] = useState<DateRange>(getInitialDateRange(history.location));
  const { benchmark, setBenchmark, relative, setRelative, updateBenchmark } = useBenchmark(history);
  const [savedId, setSavedId] = useState<string>();
  const [baseline, setBaseline] = useState<ComparisonViewBaseline>(emptyBaseline);
  const { profileSettings } = useContext(UserContext);
  const isMountedRef = useIsMounted();
  const isViewInitLoading = useRef(true);
  const { isPivoted, togglePivoted, setIsPivoted } = useIsPivoted(history);
  const { getCachedAnalysisSubject } = useCachedSubjects();

  // Init
  useEffect(() => {
    const fetchSubjects = async (subjectIds: SubjectId[], viewId?: string) => {
      const newSubjects = await Promise.all(subjectIds.map((subjectId) => getCachedAnalysisSubject(subjectId)));
      // Track all analyzed subjects
      const savedInfo = viewId ? { viewId, viewType: 'COMPARE' as AnalysisViewTypeEnum } : undefined;
      Promise.all(subjectIds.map((subjectId) => viewEntity(subjectId.toString(), savedInfo)));
      const validSubjects = compact(newSubjects);

      if (isMountedRef.current) {
        setSubjects(validSubjects);
      }
      isViewInitLoading.current = false;
    };

    /** If fetch a new view, override the subjects, date range and benchmark */
    const fetchView = async (viewId: string, isNew: boolean) => {
      try {
        const { content: savedView } = await getSpecificAnalysisView(viewId);
        const newBaseline = convertViewToBaseline(savedView, profileSettings);
        if (isNew) {
          fetchSubjects(
            newBaseline.subjects.map((s) => (s.subjectType === 'PORTFOLIO' ? Number(s.id) : s.id)),
            viewId,
          );
          setDateRange(newBaseline.dateRange);
          if (newBaseline.benchmark?.id) {
            updateBenchmark(newBaseline.benchmark.id, newBaseline.relative);
          } else {
            setBenchmark(undefined);
            setRelative(false);
          }
          if (!isNil(newBaseline.isPivoted)) {
            setIsPivoted(newBaseline.isPivoted);
          }
        }
        setBaseline(newBaseline);
        setSavedId(viewId);
      } catch (e) {
        Notifications.notify(VIEW_ERROR_MESSAGE, NotificationType.ERROR);
        isViewInitLoading.current = false;
        // Go to the root page and re init
        history.push(Routes.ANALYSIS_COMPARE_PATH);
        saveComparionsToLocal({});
        init();
      }
    };

    // Saved View initialization
    const init = () => {
      const workspaceId = getWorkspaceIdFromLocation(history.location);
      if (
        !isNil(workspaceId) &&
        !isNil(profileSettings?.organization?.workspaceId) &&
        Number(workspaceId) !== profileSettings?.organization?.workspaceId
      ) {
        // While users try to open a different workspace,
        // stop the rest logic to let the switch workspace action finish first
        return;
      }
      const viewIdFromLocation = getViewIdFromLocation(history.location);
      const isNew = isNewView(history.location);
      /** Only get the recent view id when it's in init loading */
      const viewId =
        shouldUseRecent(history.location) && isViewInitLoading.current ? getRecentViewId() : viewIdFromLocation;
      if (viewId && savedId !== viewId) {
        fetchView(viewId, !!isNew);
        if (isNew) {
          // subject and date range will be updated using view info
          return;
        }
      }

      if (!isViewInitLoading.current) {
        return;
      }

      let subjectIds = getUniqueSubjectIdsFromLocation(history.location);
      if (!subjectIds.length) {
        subjectIds = getRecentComparisonIds();
      }
      if (subjectIds.length > 0) {
        fetchSubjects(subjectIds);
      } else {
        // Turn off initial loading flag
        isViewInitLoading.current = false;
      }
    };

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update url when subjects changes.
  useEffect(() => {
    if (!isViewInitLoading.current) {
      updateUrlParam(
        history,
        'REPLACE',
        'subjects',
        subjects.map((s) => String(s?.id)),
      );
    }
  }, [history, subjects]);

  // Update url when date range changes.
  useEffect(() => {
    updateUrlDateRange(history, dateRange);
  }, [history, dateRange]);

  // Update savedId
  useEffect(() => {
    if (savedId) {
      updateUrlParam(history, 'REPLACE', 'savedId', savedId);
    }
  }, [history, savedId]);

  const save = useCallback(
    async (name: string, id?: string, ownerContextId?: string): Promise<string | undefined> => {
      try {
        const toSaveView = convertToView(name, subjects, dateRange, relative, benchmark, id, isPivoted, ownerContextId);
        const { content: updatedSavedView } = await saveAnalysisView(toSaveView);
        const newBaseline = convertViewToBaseline(updatedSavedView, profileSettings);
        setBaseline(newBaseline);
        setSavedId(updatedSavedView.id);
        if (!id) {
          Notifications.notify(<SavedViewMessage type="COMPARE" />);
        }
        return updatedSavedView.id;
      } catch (e) {
        logExceptionIntoSentry(e);
        return undefined;
      }
    },
    [subjects, dateRange, profileSettings, benchmark, relative, isPivoted],
  );

  const onSave = useCallback(
    async (name?: string, ownerContextId?: string): Promise<string | undefined> => {
      const id = baseline.isOwner ? savedId : undefined;
      const timestamp = moment().format('YYYY-MM-DD hh:mm A');
      // If the name field is empty, fallback to save with original name
      const savedName = name ?? baseline.name ?? `Saved Compare View - ${timestamp}`;
      const updatedId = await save(savedName, id, ownerContextId ?? baseline.ownerContextId);
      analyticsService.ctaClicked({
        destination: undefined,
        text: 'Save',
        purpose: 'Save compare',
        type: 'button',
        filled: false,
      });
      return updatedId;
    },
    [baseline.isOwner, baseline.name, baseline.ownerContextId, savedId, save],
  );

  const onSaveAs = useCallback(
    (name: string, ownerContextId?: string) => {
      save(name, undefined, ownerContextId);
      analyticsService.ctaClicked({
        destination: undefined,
        text: 'Save As...',
        purpose: 'Save compare',
        type: 'button',
        filled: false,
      });
    },
    [save],
  );

  const onRename = useCallback(
    async (name: string) => {
      try {
        const toSaveView = convertToView(
          name,
          [],
          baseline.dateRange,
          baseline.relative,
          undefined,
          savedId,
          baseline.isPivoted,
          baseline.ownerContextId,
        );
        const subjectList = compact([baseline.benchmark, ...baseline.subjects]);
        const { content: updatedSavedView } = await saveAnalysisView({ ...toSaveView, subjects: subjectList });
        const newBaseline = convertViewToBaseline(updatedSavedView, profileSettings);
        setBaseline(newBaseline);
      } catch (e) {
        logExceptionIntoSentry(e);
      }
    },
    [savedId, profileSettings, baseline],
  );

  return {
    // view related
    subjects,
    benchmark,
    dateRange,
    relative,
    isPivoted,
    // Prevent to show in between state while we still init the page with isViewInitLoading.current flag
    hasUnsavedChanges:
      !isViewInitLoading.current && compareDiff(baseline, subjects, dateRange, relative, benchmark, isPivoted),
    currentViewName: baseline.name,
    savedId,
    isViewInitLoading: isViewInitLoading.current,
    setSubjects,
    setBenchmark,
    setDateRange,
    setRelative,
    onSave,
    onSaveAs,
    onRename,
    togglePivoted,
    noAccessModifiedView: !!savedId && !baseline.isOwner,
  };
};

export default useComparisonView;
