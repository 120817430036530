/**
 * @fileoverview contains all feature flags used in the frontend of the application.
 *
 * Feature flag keys must be added to this file in one of the arrays {@link featureFlagKeys}, {@link adminFeatureKeys}, or {@link featureKeys} or they will
 * not be accessible on the frontend.
 */

import type { UserFeature } from 'venn-api-client/public_api/misc/v1/feature_availability_pb';
import { assert } from '../utils';

type FeatureFlagKey = (typeof featureFlagKeys)[number];

/**
 * Temporary feature flags.
 * Please append these with _ff to make distinguishable from features used for pricing.
 */
export const featureFlagKeys = [
  'test_hide_category_ff', // Test flag for hiding categories on Analysis Page & checking if anyone missed them
  'studio_ff', // flag to rollout studio
  'date_range_in_data_ff', // flag to show pagination in data range
  'non_primary_share_class_ff', // Flag for showing additional share classes of mutual funds
  'studio_venncast_ff', // Temporary flag to disable venncast in studio till it is ready
  'restrict_cma_forecast_ff', // Temporary flag to limit wealth managers from using CMA forecasts
  'import_export_strategies_ff', // Temporary flag for importing portfolios as strategies to a portfolio / saving strategies as portfolios
  'ftue_first_phase_ff', // ff for first time user experience flows
  'virtualization_ff', // ff for enabling virtualization of scrolling in studio and report lab
  'canvas_heatmap_ff', // ff for enabling faster-rendering heatmaps using canvas
  'swiss_disclosure_ff', // ff for showing Swiss securities disclaimer in RL/Studio among default Venn disclosures
  'extrapolation_ff', // ff for enabling extrapolation configuration
  'compare_export_watermark_ff', // ff for forcing a watermark on compare page print-outs
  'analysis_export_watermark_ff', // ff for forcing a watermark on analysis page print-outs
  'studio_export_watermark_ff', // ff for forcing a watermark on studio page print-outs
  'report_lab_export_watermark_ff', // ff for forcing a watermark on report lab print-outs
  'macro_scenario_ff', // ff for enabling macroeconomic scenarios in scenario analysis on studio/report lab
  'mfa_updates_ff', // ff to enable new MFA changes
  'categories_search_ff', // ff for morningstar search in data library
  'privates_reveal_ff', // ff for letting users know privates module exists, OFF until we are ready to launch
  'investment_advisory_fees_ff', // ff to enabling user to add advisory fees to investments in report lab
  'modern_layout_no_revert_ff', // ff for disabling the ability to revert back to the legacy layout in report lab
  'full_access_seat_cap_rollout_ff', // ff for showing the users their progress towards the seatcap limit
  'mstar_indices_factors_ff', // ff for enabling Morningstar indices for factors
  'brand_mark_update_ff', // ff for using new brand mark icons
  'residual_forecast_zero_ff', // ff to allow forcing residual forecast to 0
  'forecast_panel_redesign_ff', // ff for enabling new design of the forecast panel
  'disable_exports_ff', // ff for disabling the ability to export, if the user does not have report lab
  'peer_group_analytics_ff', // ff for enabling peer group analytics
  'public_private_asset_growth_ff', // ff for building public-private asset growth,
  'privates_hyperparameters_ff', // ff for displaying cash flow pacing hyperparameters for privates
  'privates_hyperparameters_editing_ff', // ff for allowing users to edit cash flow pacing hyperparameters for privates
  'privates_strengthen_ff', // ff for cash flow data modifications effort
  'yield_income_ff', // ff for enabling user to be able to enter and see custom yield metrics
  'upsell_improvements_ff', // ff for upsell improvements (PAL + reportlab)
  'proxy_disclosure_ff', // ff for enabling simple proxy disclosures in report lab
  'extend_full_history_ff', // ff for enabling VER-796: Extend Analysis Beyond TSFL for Performance Based Metrics
] as const;

type AdminFeature = (typeof adminFeatureKeys)[number];
/** Admin features. */
export const adminFeatureKeys = [
  'admin_workspace_switch', // User has access to organization switch (Venn system admins only)
  'admin_data_reader', // User is a venn team member with access to read admin data
] as const;

type FeatureKey = (typeof featureKeys)[number];
/** More permanent features, such as used for pricing. Should not end in _ff. */
export const featureKeys = [
  'venn_branded_watermark_ff', // Whether to show the Venn branded watermark on blocks in non-RL modules.
  'optimization', // User has access to optimization (pro & trial users except certain countries)
  'full_access_seat_cap', // Read the limit on the number of users in organization, OR if this feature is not present (i.e. the limit is 0) used to hide the ability to invite others to the workspace
  'workspace_admin_actions', // User can remove teammates from workspace, revoke invitations and modify roles
  'sponsor',
  'admin_switch_organizations', // Not really an admin feature just enables a searchable workspace switcher
  'cookie_preferences', // Easy way for devs not in EU to check the GDPR cookie preferences banner
  'absolute_path', // Feature used for debugging tests for absolute path to feature service
  'studio_report_editor', // Ability to access the report editing functionality in studio
  'context_switching', // Ability to switch contexts within an organisation
  'wealth_management_organization', // Whether an organization is a wealth management organization, affects disclaimers and disclosures
  'canada_restriction', // Canadian users who should be restricted from accessing stocks and other functionality
  'addepar_self_service', // Users who are pre-cleared to use the Addepar self-service functionality
  'studio_use_parallel_queries_ff', // enable parallel queries with useQueries in Studio/Report Lab
  'private_analytics', // enable private analytics experience within the application
  'categories_search_library_ff', // Show morningstar categories filter on the library page directly
  'frontend_driven_downtime',
  'public_market_equivalents_ff', // whether user has PME features (a.k.a privates v2)
  'frontend_debug_panel', // Show the debug panel in the frontend
  'original_ais_org', // used to prevent onboarding video showing for existing users
  'new_rebalance_ff', // adds support for the NO_REBALANCE and CUSTOM_REBALANCE options
  'color_scheme_picker', // Enables a picker which changes color settings for the entire workspace when saved
  'yield_income_ff', // Visibility of yield metrics and metadata
  'hide_borders_on_text_and_image_blocks_ff', // Visibility of option to hide borders on text and image blocks in Report Lab
  'portfolio_multi_uploader_ff', // Enable the multi uploader for portfolios
] as const;

/** Union of all possible feature keys or names. */
export type FeatureName = FeatureKey | FeatureFlagKey | AdminFeature;
export type PathType = 'RELATIVE_PATH' | 'ABSOLUTE_PATH';

/**
 * Ensures all keys in the provided object are lowercase at both compile time and runtime.
 * We use this because feature keys are normalized to lowercase to prevent casing issues.
 *
 * Alternative considered: the TS4.9 satisfies operator (satisfies Lowercase<string>) would be a clean compile-time solution,
 * but it doesn't do any runtime check and we don't have TS4.9 yet.
 */
function checkLowercaseKeys(arr: Readonly<Lowercase<string>[]>) {
  arr.forEach((v) => assert(v.toLowerCase() === v, `expected ${v} to be lowercase`));
}
checkLowercaseKeys(featureFlagKeys);
checkLowercaseKeys(adminFeatureKeys);
checkLowercaseKeys(featureKeys);

export type FeatureFlagRecord = { [name in FeatureName]?: UserFeature };
export type PartialFeatureFlagRecord = { [name in FeatureName]?: Partial<UserFeature> };
