import type { OperationResult } from './utils';

/**
 * Wrap an API function with this to cache the result.
 * This also caches the request Promise in case another request is issued
 * before the request Promise resolves
 *
 * Generally prefer to use useQuery instead.
 *
 * @param fn API function
 * @param args function arguments
 */
export function cached<T extends unknown[], U>(
  fn: (...args: T) => Promise<OperationResult<U>>,
  ...args: T
): (...args: T) => Promise<OperationResult<U>> {
  let cachedResult: OperationResult<U> | null = null;
  let cachedRequest: Promise<OperationResult<U>> | null = null;

  return async function cachedInner(): Promise<OperationResult<U>> {
    // If the result is available, return that immediately
    if (cachedResult !== null) {
      return cachedResult;
    }

    // If we have not initiated the request, do so now and cache reference
    if (!cachedRequest) {
      cachedRequest = fn(...args);
    }

    // Await resolution of the request Promise
    cachedResult = await cachedRequest;
    // Once we've extracted the result, clear the cached request value
    cachedRequest = null;

    return cachedResult;
  };
}
