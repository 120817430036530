/** Should be: 1123px @ 96PPI. */
export const DEFAULT_LANDSCAPE_WIDTH = 1122;
/** Should be: 794px @ 96PPI */
export const DEFAULT_PORTRAIT_WIDTH = 792;
/** Should be: 794px @ 96PPI */
export const DEFAULT_LANDSCAPE_HEIGHT = 792;
/** Should be: 1123px @ 96PPI */
export const DEFAULT_PORTRAIT_HEIGHT = 1122;
export const DEFAULT_MARGIN = 24;
export const CONTENT_PADDING = 40;
/** Default hightchart is 400 */
export const PRINT_CHART_HEIGHT = 215;

export const PAGE_FOOTER_HEIGHT = 85;
export const PAGE_HEADER_HEIGHT = 50;

export const PRINT_ROW_HEIGHT = 24;
export const PRINT_GRID_HEADER_HEIGHT = 26;

// TODO(will.woodacre): Remove this -6 when virtualization is rolled out
// This is currently to take into account the width of the scroll bar
export const VIRTUALIZATION_SCROLL_BAR_PX = 6;
export const COLUMN_GAP = DEFAULT_MARGIN;
