import type { UserActivityServiceInterface } from './user-activity-service';

/**
 * An in memory version of the {@link UserActivityService}. Useful for tests.
 */
class EphemeralUserActivityService implements UserActivityServiceInterface {
  USER_LIBRARY_RECENT_QUERIES = 'library_recent_queries';

  SESSION_ID_LOCAL_STORAGE = 'thirty_minute_session_id';

  // Use current timestamp as random user id for anonymous tracking before user is authenticated
  private _userId: number = Date.now();

  private _orgId?: number;

  private _storage: Map<string, string>;

  constructor() {
    this._storage = new Map<string, string>();
  }

  get orgId() {
    return this._orgId;
  }

  initialize(): void {
    throw new Error('Not implemented');
  }

  setUser = (): void => {
    throw new Error('Not implemented');
  };

  getLocalStorageItemPerUser = (key: string): string | undefined =>
    this._storage.get(`${key}-${this._userId}`) ?? undefined;

  setLocalStorageItemPerUser = (key: string, value: string): void => {
    this._storage.set(`${key}-${this._userId}`, value);
  };
}
export default new EphemeralUserActivityService();
