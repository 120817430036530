import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import type {
  BlockLevelSettings,
  NonQueryViewSettings,
  UpdatableViewSettings,
  URLLevelSettings,
} from 'venn-components';
import {
  AnalysisViewContext,
  blockLevelDefaultSettings,
  getValueFromUrl,
  urlLevelDefaultSettings,
  UserContext,
} from 'venn-components';
import type { AnalysisView } from 'venn-api';
import { buildQuery, getSpecificAnalysisView, saveAnalysisView } from 'venn-api';
import type { AnalysisConfig } from 'venn-utils';
import { assert, IS_LOCAL, IS_JEST_TEST, Routes, storeAnalysis, useIsMounted } from 'venn-utils';
import { getRangeFromString, Loading, Notifications, NotificationType } from 'venn-ui-kit';
import { isEmpty, isEqual, keys, omit, pick } from 'lodash';
import {
  areViewSettingsEqual,
  getAnalysisView,
  getLocationWithReplacedStrategyId,
  parseAnalysisView,
} from './analysisViewUtils';
import { getParsedSearch } from '../utils';

const SAVING_ERROR = 'Failed to save view, please try again later.';
const RENAMING_ERROR = 'Failed to rename view, please try again later.';

export const defaultBaselineSettings: NonQueryViewSettings & URLLevelSettings = {
  ...urlLevelDefaultSettings,
  ...blockLevelDefaultSettings,
  templateId: undefined,
  currentViewName: undefined,
};

const AnalysisViewStore: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { profileSettings } = useContext(UserContext);
  const userId = profileSettings?.user.id;
  const orgId = profileSettings?.organization.id;
  const history = useHistory<NonQueryViewSettings>();
  const historyRef = useRef(history);
  /** Store previous saved view's parameters */
  const prevParams = useRef<(URLLevelSettings & NonQueryViewSettings) | undefined>(defaultBaselineSettings);
  useEffect(() => {
    historyRef.current = history;
  }, [history]);

  // Specific parameters from URL query
  const { relative, start, end, period, compare, compareVersion, savedId } = getParsedSearch(
    historyRef.current.location.search,
  );
  const { analysisType, objectId: subjectId } = useParams<{ objectId: string; analysisType: string }>();
  const [subjectName, setSubjectName] = useState<string | undefined>(undefined);
  const subjectNameRef = useRef<string | undefined>(undefined);
  useEffect(() => {
    if (subjectNameRef.current !== subjectName) {
      subjectNameRef.current = subjectName;
    }
  }, [subjectName]);

  const [savedAnalysisView, setSavedAnalysisView] = useState<AnalysisView | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [fetchingError, setFetchingError] = useState(false);
  const [baselineSettings, setBaselineSettings] = useState<NonQueryViewSettings & URLLevelSettings>(
    defaultBaselineSettings,
  );

  useEffect(() => {
    if (
      baselineSettings.templateId === undefined ||
      (baselineSettings.templateId === 'default' && baselineSettings.templateId !== analysisType)
    ) {
      setBaselineSettings((prevSettings) => ({
        ...prevSettings,
        templateId: analysisType,
      }));
    }
  }, [baselineSettings.templateId, analysisType]);

  // Fetch saved analysis view when AnalysisViewStore is first rendered
  const isMountedRef = useIsMounted();
  useEffect(() => {
    const loadSavedView = async (viewId: string) => {
      setLoading(true);
      try {
        const result = await getSpecificAnalysisView(viewId);
        const { selectedStrategyId } = result.content.customViewOptions;
        if (selectedStrategyId) {
          const { pathname, search } = historyRef.current.location;
          historyRef.current.replace(
            getLocationWithReplacedStrategyId(pathname, search, selectedStrategyId),
            historyRef.current.location.state,
          );
        }
        if (!isMountedRef.current) {
          return;
        }
        setSavedAnalysisView(result.content);
      } catch (e) {
        if (!isMountedRef.current) {
          return;
        }
        setFetchingError(true);
      }
      setLoading(false);
    };
    if (!!savedId && savedAnalysisView?.id !== savedId && !loading && !saving) {
      setSubjectName(undefined);
      subjectNameRef.current = undefined;
      loadSavedView(savedId);
    }
  }, [savedId, savedAnalysisView, loading, saving, isMountedRef]);

  // Block settings, not present in the URL
  const [blockSettings, setBlockSettings] = useState<BlockLevelSettings>(blockLevelDefaultSettings);

  // Update block-level settings based on the contents of history.location.state
  // Storing them in history.location.state allows us to redirect to "recent analysis view" with those settings applied
  // while not putting them in the URL
  useEffect(() => {
    const updatedSettingsFromState = {
      ...blockLevelDefaultSettings,
      ...pick<BlockLevelSettings>(history.location.state, keys(blockLevelDefaultSettings)),
    };
    if (history.location.state && !areViewSettingsEqual(updatedSettingsFromState, blockSettings)) {
      setBlockSettings(updatedSettingsFromState);
    }
  }, [history.location.state, blockSettings]);

  const onUpdateAnalysisViewParam = useCallback(
    (value: Partial<UpdatableViewSettings>) => {
      if (loading || saving) {
        return;
      }
      const currentParsedSearch = getParsedSearch(historyRef.current.location.search);
      const updatedParsedSearch = {};
      const updatedState = {};
      let updatedTemplate;
      let updatedSubjectName = null;
      Object.keys(value).forEach((paramKey) => {
        switch (paramKey) {
          case 'relative':
          case 'start':
          case 'end':
          case 'period':
          case 'compare':
          case 'compareVersion':
          case 'savedId':
            updatedParsedSearch[paramKey] = value[paramKey];
            break;
          case 'venncast':
          case 'rollingReturnPeriod':
          case 'rollingSharpePeriod':
          case 'rollingVolatilityPeriod':
          case 'rollingBetaPeriod':
          case 'rollingBenchmarkCorrelationPeriod':
          case 'rollingFactorExposuresPeriod':
          case 'rollingFactorReturnPeriod':
          case 'rollingFactorRiskPeriod':
          case 'hypotheticalDrawdownThreshold':
          case 'historicalDrawdownThreshold':
          case 'attributionsView':
          case 'attributionsSubject':
          case 'marginalAttributionsView':
          case 'marginalAttributionsSubject':
          case 'returnsGridFrequency':
          case 'returnsGridSubject':
          case 'residualCorrelation':
          case 'showCorrelationValues':
          case 'factorAnalysisSortBy':
          case 'scenariosCustomParamId':
          case 'isTradesView':
          case 'isPercentageMode':
          case 'isCategoryOff':
          case 'selectedNotablePeriods':
          case 'notablePeriodsThreshold':
          case 'trendChartModes':
            updatedState[paramKey] = value[paramKey] ?? blockLevelDefaultSettings[paramKey];
            break;
          case 'scenarios':
          case 'currentViewName':
            updatedState[paramKey] = value[paramKey] ?? defaultBaselineSettings[paramKey];
            break;
          case 'templateId':
            updatedTemplate = value[paramKey];
            break;
          case 'subjectName':
            updatedSubjectName = value[paramKey];
            break;
          default:
            if (IS_LOCAL || IS_JEST_TEST) {
              throw new Error(`Unsupported parameter passed to AnalysisViewStore: ${paramKey}`);
            }
        }
      });

      const pathname = getPathnameWithUpdatedTemplate(historyRef.current.location.pathname, updatedTemplate);
      const query = buildQuery({
        ...currentParsedSearch,
        ...updatedParsedSearch,
      });
      const updatedLocation = `${pathname}${query}`;
      const updatedLocationState = {
        ...historyRef.current.location.state,
        ...updatedState,
      };

      const previousLocation = `${historyRef.current.location.pathname}${historyRef.current.location.search}`;
      if (previousLocation !== updatedLocation || !isEqual(historyRef.current.location.state, updatedLocationState)) {
        historyRef.current.replace(
          updatedLocation,
          omit(omit(updatedLocationState, 'previousPathMatch'), 'reservedState'),
        );
      }
      const actualSubjectName = updatedSubjectName ?? subjectNameRef.current;
      if (userId && orgId && subjectId && actualSubjectName) {
        storeAnalysis(userId, orgId, subjectId, actualSubjectName, updatedLocation, updatedLocationState);
      }
      if (updatedSubjectName !== null && updatedSubjectName !== subjectNameRef.current) {
        setSubjectName(updatedSubjectName);
      }
    },
    [loading, saving, userId, orgId, subjectId],
  );

  // Update the URL- and block-level settings based on the saved analysis view
  useEffect(() => {
    const fetchingPending = savedId && !savedAnalysisView && !fetchingError;
    if (loading || saving || fetchingPending) {
      return;
    }

    // If we switched from a saved view or to a different view
    if (savedAnalysisView && (!savedId || savedId !== savedAnalysisView.id)) {
      setSavedAnalysisView(undefined);
      onUpdateAnalysisViewParam({ scenariosCustomParamId: undefined });
      setBaselineSettings(defaultBaselineSettings);
      prevParams.current = defaultBaselineSettings;
      return;
    }

    const urlParams = {
      ...pick<URLLevelSettings>(getParsedSearch(history.location.search), keys(urlLevelDefaultSettings)),
      templateId: analysisType,
    };
    const reservedState = historyRef.current.location.state?.reservedState
      ? omit(historyRef.current.location.state.reservedState, 'reservedState')
      : {};
    const viewState = historyRef.current.location.state
      ? pick<BlockLevelSettings>(historyRef.current.location.state, keys(blockLevelDefaultSettings))
      : {};

    const params = parseAnalysisView(savedAnalysisView);
    const savedBaselineChanged = params && !areViewSettingsEqual(prevParams.current, params);
    const stateClearedOnNavigationToSavedView = params && isEmpty(viewState);
    if (savedBaselineChanged || stateClearedOnNavigationToSavedView || !isEmpty(reservedState)) {
      prevParams.current = params;
      // Load the view with saved view params + reserved state + url params
      onUpdateAnalysisViewParam(
        omit(
          {
            ...params,
            ...reservedState,
            ...urlParams,
          },
          'reservedState',
        ),
      );
      setBaselineSettings(params ?? defaultBaselineSettings);
      return;
    }

    if (savedId && !loading && !saving && fetchingError) {
      onUpdateAnalysisViewParam({ savedId: undefined, scenariosCustomParamId: undefined });
      setBaselineSettings(defaultBaselineSettings);
    }
  }, [
    savedAnalysisView,
    onUpdateAnalysisViewParam,
    savedId,
    loading,
    saving,
    fetchingError,
    history.location.search,
    analysisType,
    relative,
    compare,
  ]);

  const currentSettings = useMemo(
    () => ({
      relative,
      start,
      end,
      period,
      compare,
      compareVersion,
      savedId,
      currentViewName: savedAnalysisView?.name,
      ...blockSettings,
    }),
    [relative, start, end, period, compare, compareVersion, savedId, blockSettings, savedAnalysisView],
  );

  const userIsSavedViewOwner = !!savedAnalysisView && savedAnalysisView.owner?.id === userId;

  const savingSuccessMessage = useMemo(
    () => `Success. ${savedAnalysisView?.name ?? 'View'} was saved.`,
    [savedAnalysisView],
  );

  const renamingSuccessMessage = useMemo(
    () => (savedAnalysisView?.name ? `Success. The view was renamed to ${savedAnalysisView.name}.` : undefined),
    [savedAnalysisView],
  );

  const save = useCallback(
    async (view?: Partial<AnalysisView>) => {
      try {
        const updatedSavedView = await saveAnalysisView(view);
        const { id, analysisPeriod, customViewOptions } = updatedSavedView.content;
        const { startDate, endDate, periodToDate } = analysisPeriod!;
        const [startParam, endParam, periodParam] = periodToDate
          ? [undefined, undefined, getRangeFromString(periodToDate)]
          : [startDate, endDate, undefined];
        //  - update `savedId` to prevent fetching old view based on the difference between old `savedId` and new `savedAnalysisView`
        //  - update `start` and `end` because BE "fixes" the timestamps we pass them
        //  - update `relative` to be aligned with actual relative in charts
        setSaving(true);
        onUpdateAnalysisViewParam({
          savedId: id,
          start: startParam,
          end: endParam,
          relative: customViewOptions.relative,
        });
        // Before setting analysis view, make sure to correct start, end and period
        setSavedAnalysisView({
          ...updatedSavedView.content,
          analysisPeriod: {
            startDate: startParam,
            endDate: endParam,
            periodToDate: periodParam,
          },
        });
        Notifications.notify(savingSuccessMessage, NotificationType.SUCCESS);
        setSaving(false);
      } catch (e) {
        Notifications.notify(SAVING_ERROR, NotificationType.ERROR);
      }
    },
    [onUpdateAnalysisViewParam, savingSuccessMessage],
  );

  const onSave = useCallback(
    async (updatedName: string, analysisConfig: AnalysisConfig, ownerContextId?: string) => {
      const toSave = getAnalysisView(
        updatedName,
        analysisConfig,
        { ...currentSettings, templateId: analysisType },
        ownerContextId,
        savedAnalysisView,
      );
      await save(toSave);
    },
    [currentSettings, analysisType, savedAnalysisView, save],
  );

  const onSaveAs = useCallback(
    async (updatedName: string, analysisConfig: AnalysisConfig, ownerContextId?: string) => {
      const toSave = getAnalysisView(
        updatedName,
        analysisConfig,
        {
          ...currentSettings,
          templateId: analysisType,
          savedId: undefined,
          scenariosCustomParamId: undefined,
        },
        ownerContextId,
      );
      await save(toSave);
    },
    [currentSettings, analysisType, save],
  );

  const onRenameSavedView = useCallback(
    async (updatedName: string) => {
      if (!savedAnalysisView) {
        Notifications.notify(RENAMING_ERROR, NotificationType.ERROR);
        return;
      }

      const updatedBaselineView = { ...savedAnalysisView, name: updatedName };
      try {
        await saveAnalysisView(updatedBaselineView);
        assert(prevParams.current);
        prevParams.current = {
          ...prevParams.current,
          currentViewName: updatedName,
        };
        setSavedAnalysisView(updatedBaselineView);
        setBaselineSettings({
          ...baselineSettings,
          currentViewName: updatedName,
        });
        onUpdateAnalysisViewParam({ currentViewName: updatedName });
        Notifications.notify(renamingSuccessMessage ?? '', NotificationType.SUCCESS);
      } catch (e) {
        Notifications.notify(RENAMING_ERROR, NotificationType.ERROR);
      }
    },
    [savedAnalysisView, baselineSettings, onUpdateAnalysisViewParam, renamingSuccessMessage],
  );

  const onStartNewAnalysis = useCallback(() => {
    // Potential TODO: clear out the entire state to reset all the view settings to defaults(?)
    subjectNameRef.current = undefined;
    onUpdateAnalysisViewParam({ savedId: undefined, scenariosCustomParamId: undefined });
    setSavedAnalysisView(undefined);
    setBaselineSettings(defaultBaselineSettings);
    prevParams.current = defaultBaselineSettings;
  }, [onUpdateAnalysisViewParam]);

  const onDeleteCurrentView = useCallback(() => {
    if (!savedAnalysisView) {
      return;
    }
    setLoading(true);
    setBaselineSettings(defaultBaselineSettings);
    const newCurrentSettings = { ...savedAnalysisView };
    setSavedAnalysisView(undefined);
    onUpdateAnalysisViewParam({
      ...parseAnalysisView(newCurrentSettings),
      ...currentSettings,
      templateId: analysisType,
      savedId: undefined,
      scenariosCustomParamId: undefined,
    });
    setLoading(false);
  }, [savedAnalysisView, onUpdateAnalysisViewParam, currentSettings, analysisType]);

  const activeTab = getValueFromUrl(history.location, 'active') ?? 'analysis';
  // `loading` may not be `true` yet, but if `savedAnalysisView.id` is different than `savedId`, we are due to start
  // loading soon
  const preLoading = savedId !== undefined && savedAnalysisView?.id !== savedId && !saving;
  const hideContentWhileLoading = (loading || preLoading) && activeTab === 'analysis';

  return (
    <AnalysisViewContext.Provider
      value={{
        ...currentSettings,
        baselineViewName: savedAnalysisView?.name,
        hasSavedBaseline: !!savedAnalysisView && userIsSavedViewOwner,
        hasUnsavedChanges:
          !loading &&
          !saving &&
          (!areViewSettingsEqual(omit(baselineSettings, 'templateId'), currentSettings) ||
            analysisType !== baselineSettings.templateId ||
            !userIsSavedViewOwner),
        onSave,
        onSaveAs,
        onRenameSavedView,
        onDeleteCurrentView,
        onUpdateAnalysisViewParam,
        onStartNewAnalysis,
        noAccessModifiedView: !!savedAnalysisView?.owner?.id && !userIsSavedViewOwner,
      }}
    >
      {fetchingError ? <Redirect to={`${Routes.DEFAULT_ANALYSIS_PATH}?invalidViewId=true`} /> : null}
      {hideContentWhileLoading ? <Loading /> : children}
    </AnalysisViewContext.Provider>
  );
};

export default AnalysisViewStore;

const getPathnameWithUpdatedTemplate = (pathname: string, templateId?: string): string => {
  if (!templateId) {
    return pathname;
  }
  const parts = pathname.split('/');
  const templateIdx = parts.indexOf('results') + 1;
  if (templateIdx === 0 || templateIdx > parts.length) {
    return pathname;
  }
  parts[templateIdx] = templateId;
  return parts.join('/');
};
