import { default as mockPortfolio } from './portfolio';
import type { StudioRequestSubject } from 'venn-state';
import { default as mockFund } from './fund';

export const mockStudioPortfolio: StudioRequestSubject = {
  id: String(mockPortfolio.id),
  name: mockPortfolio.name,
  subjectType: 'PORTFOLIO' as const,
  portfolio: mockPortfolio,
  private: false,
};

export const mockStudioFund: StudioRequestSubject = {
  id: mockFund.id,
  name: mockFund.name,
  subjectType: 'INVESTMENT' as const,
  fund: mockFund,
  private: false,
};

export const mockStudioPortfolioComparison: StudioRequestSubject = {
  ...mockStudioPortfolio,
  portfolioComparisonType: 'SAVED',
  primaryPortfolio: mockStudioPortfolio.portfolio,
};
