import type { PropsWithChildren } from 'react';
import React from 'react';
import type { OptionsProps } from './options/Options';
import Options from './options/Options';
import styled from 'styled-components';
import { GetColor } from '../../style/color';

export enum MenuType {
  Search = 'search',
  Leveled = 'leveled',
}

export interface MenuProps<V> extends OptionsProps<V> {
  type?: MenuType;
  className?: string;
  style?: Record<string, unknown>;
  shouldRender?: boolean;
  isLoading?: boolean;
  showOptions?: boolean;
}

const StyledMenu = styled.div<MenuProps<unknown>>`
  display: inline-flex;
  position: relative;
  flex-direction: column;
  max-width: 284px;
  max-height: 172px;
  border: 1px solid ${GetColor.Grey};
  background: ${GetColor.White};
  ${(props) =>
    props.type === MenuType.Search &&
    `
    width: 100%;
    min-width: 208px;
    max-width: 324px;
    border-width: 0
  `};

  ${(props) =>
    props.type === MenuType.Leveled &&
    `
    max-width: 224px
  `};
`;

export const Menu = <V,>({
  children,
  className,
  style,
  shouldRender = true,
  showOptions = true,
  ...optionsProps
}: PropsWithChildren<MenuProps<V>>) => {
  if (!shouldRender) {
    return null;
  }

  return (
    <StyledMenu className={className} style={style}>
      {children}
      {showOptions && <Options {...optionsProps} />}
    </StyledMenu>
  );
};

export default Menu;
