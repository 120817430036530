import { useMemo, useContext } from 'react';
import type { GeneralAnalysisTemplate } from 'venn-api';
import { AnalysisContext, UserContext } from '../contexts';
import type { AnalysisViewSearchResultWithUIState } from 'venn-utils';
import { findSelectedContext } from 'venn-utils';

const getTemplateName = (templates: GeneralAnalysisTemplate[], customTemplateId: string, systemTemplate: string) =>
  templates.find(({ id }) => (customTemplateId ? customTemplateId === id : systemTemplate === id))?.name;

export const useSavedViewListWithExtraName = (results: AnalysisViewSearchResultWithUIState[]) => {
  const { templates } = useContext(AnalysisContext);
  const { profileSettings } = useContext(UserContext);

  const resultsWithTemplates = useMemo(
    () =>
      results.map((view) => ({
        ...view,
        ownershipName:
          findSelectedContext(view.ownerContextId, profileSettings?.availableContexts)?.value.context.name ?? 'Global',
        templateName: getTemplateName(templates, view.customTemplateId, view.systemTemplate),
      })),
    [results, profileSettings?.availableContexts, templates],
  );

  return { resultsWithTemplates };
};
