import type { ViewPort, Scale } from '../../types';
import type { FactorSummaryChartRow } from '../../../factorSummaryTypes';
import type { Theme } from 'venn-ui-kit';

export interface GridProps {
  factors: FactorSummaryChartRow[];
  viewPort: ViewPort;
  scale: Scale;
  percentage: boolean;
  theme: Theme;
}

export interface GridState {
  ticks: GridTick[];
  width: number;
  height: number;
  rowHeight: number;
  rowsCount: number;
  labelPosition: number;
  middleTickIndex: number;
}

export interface GridTick {
  label: string;
  position: number;
}

function deriveState(props: GridProps): GridState {
  const { factors, viewPort, scale, percentage } = props;
  const ticks = getTicks(viewPort, scale, percentage);
  return {
    ticks,
    width: viewPort.width,
    height: getHeight(viewPort, factors),
    labelPosition: getLabelPosition(viewPort, factors),
    rowHeight: viewPort.rowHeight,
    rowsCount: getRowsCount(factors),
    middleTickIndex: Math.floor(ticks.length / 2),
  };
}

const getRowsCount = (factors: FactorSummaryChartRow[]): number => factors.length;

const getHeight = (viewPort: ViewPort, factors: FactorSummaryChartRow[]): number => viewPort.rowHeight * factors.length;

const getLabelPosition = (viewPort: ViewPort, factors: FactorSummaryChartRow[]): number =>
  viewPort.rowHeight * (factors.length + 0.5) + 9;

const getTicks = (viewPort: ViewPort, scale: Scale, percentage: boolean): GridTick[] => {
  const { padding = { left: 0, right: 0 }, width } = viewPort;
  const scaleSize = scale.max - scale.min;
  const ratio = scaleSize ? (width - padding.left - padding.right) / scaleSize : 0;
  return getTickValues(scale.min, scale.max)
    .map((value) => ({
      position: scaleX(value, scale, ratio, padding.left),
      label: formatLabel(value, percentage),
    }))
    .filter(({ position }) => isWithinBounds(position, padding, width));
};

const getTickValues = (min: number, max: number): number[] => [min, 0, max];

const scaleX = (value: number, scale: Scale, ratio: number, padding: number): number =>
  (value - scale.min) * ratio + padding;

const numberFormatter = new Intl.NumberFormat('en-US', {
  useGrouping: true,
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

const relativeNumberFormatter = new Intl.NumberFormat('en-US', {
  useGrouping: true,
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  style: 'percent',
});

const formatLabel = (value: number, percentage: boolean): string =>
  percentage ? relativeNumberFormatter.format(value) : numberFormatter.format(value);

const isWithinBounds = (position: number, padding: { left: number; right: number }, width: number): boolean =>
  position >= padding.left && position <= width - padding.right;

export default deriveState;
