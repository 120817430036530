import type { BenchmarkAction } from './benchmarkActions';
import { BenchmarkUIView } from './benchmarkActions';

export default function reduceViewId(viewId: BenchmarkUIView, action: BenchmarkAction): BenchmarkUIView {
  switch (action.type) {
    case 'BenchmarkUINavigation':
      if (action.payload.target.kind === 'View') {
        return action.payload.target.viewId;
      }
      return viewId;
    case 'BenchmarkLoadCompositeSuccess':
      return BenchmarkUIView.CompositeEditView;
    case 'BenchmarkAdd':
    case 'BenchmarkAddFromSearch':
    case 'BenchmarkUpdate':
      return BenchmarkUIView.ListView;
    default:
      return viewId;
  }
}
