import type { CashFlowSetting } from 'venn-api';

export const mockUserClassificationCashflowSetting: CashFlowSetting = {
  settingId: 'f47ac10b-58cc-4372-a567-0e02b2c3d479',
  name: 'User classification setting',
  userCreated: true,
  overrideType: 'CLASSIFICATION',
  updated: 1720176674911,
  created: 1720176674889,
  vintage: 1720176674911,
  assetClass: 'REAL_ESTATE',
  strategyName: 'Real Estate Opportunistic',
  lifeExpectancy: 36,
  growth: 0.018,
  rateOfContribution0: 0.053,
  rateOfContribution4: 0.02,
  rateOfContribution8: 0.09,
  bow: 5.0,
  yield: 0.01,
  assetClassName: 'Real Estate',
  owner: {
    id: 3123530,
    name: 'Kacper Walentynowicz',
    displayName: 'Kacper Walentynowicz',
    email: 'kacper@twosigma.com',
    active: true,
    created: 1648459460385,
    actualUser: false,
    accountId: 1,
  },
};

export const mockUserParameterCashflowSetting: CashFlowSetting = {
  settingId: 'a1b2c3d4-e5f6-7890-abcd-ef1234567890',
  name: 'User parameter setting',
  userCreated: true,
  overrideType: 'PARAMETER',
  updated: 1720176674911,
  created: 1720176674889,
  vintage: 1720176674911,
  assetClass: 'REAL_ESTATE',
  strategyName: 'Real Estate Opportunistic',
  lifeExpectancy: 36,
  growth: 0.05,
  rateOfContribution0: 0.053,
  rateOfContribution4: 0.02,
  rateOfContribution8: 0.09,
  bow: 5.0,
  yield: 0.01,
  assetClassName: 'Real Estate',
  owner: {
    id: 3123530,
    name: 'Kacper Walentynowicz',
    displayName: 'Kacper Walentynowicz',
    email: 'kacper@twosigma.com',
    active: true,
    created: 1648459460385,
    actualUser: false,
    accountId: 1,
  },
};

export const mockSystemDefaultAllWildcardsSetting: CashFlowSetting = {
  settingId: '8c8eed6a-75a7-4cce-831f-fbb9bef4a814',
  name: 'Venn Default Setting',
  userCreated: false,
  overrideType: 'CLASSIFICATION',
  updated: 1720176674911,
  created: 1720176674889,
  vintage: undefined,
  assetClass: undefined,
  strategyName: undefined,
  lifeExpectancy: 42,
  growth: 0.01,
  rateOfContribution0: 0.05,
  rateOfContribution4: 0.03,
  rateOfContribution8: 0.15,
  bow: 4.76,
  yield: 0.02,
  assetClassName: undefined,
  owner: {
    accountId: 1,
    active: true,
    actualUser: false,
    displayName: 'Venn Admin',
    id: -42,
    name: 'Venn Admin',
  },
};
