import type { CSSProperties } from 'react';
import styled, { css } from 'styled-components';
import { GetColor } from '../../style/color';

interface AlignmentProps {
  center?: boolean;
  left?: boolean;
  right?: boolean;
}

interface StyleProps {
  inverted?: boolean;
  style?: CSSProperties;
  disabled?: boolean;
}

interface AlignmentAndStyleProps extends AlignmentProps, StyleProps {}

const getTextAlign = (props: AlignmentProps) => {
  if (props.center) {
    return 'center';
  }
  if (props.right) {
    return 'right';
  }
  return 'left';
};

const baseInline = css`
  font-family: ${(props) => props.theme.Typography.fontFamily};
`;

const base = css`
  ${baseInline};
  text-align: ${getTextAlign};
`;

export const Display1 = styled.div<AlignmentAndStyleProps>`
  ${base};
  font-size: 52px;
  font-weight: 900;
  color: ${(props) => (props.inverted ? GetColor.White : GetColor.Black)};
`;

export const Headline1 = styled.h1<AlignmentAndStyleProps>`
  ${base};
  font-size: 46px;
  font-weight: 300;
  color: ${(props) => (props.inverted ? GetColor.White : GetColor.Black)};
`;

export const Headline2 = styled.h2<AlignmentAndStyleProps>`
  ${base};
  font-size: 32px;
  font-weight: 300;
  color: ${(props) => (props.inverted ? GetColor.White : GetColor.Black)};
`;

export const Headline3 = styled.h3<AlignmentAndStyleProps>`
  ${base};
  font-size: 20px;
  font-weight: bold;
  color: ${(props) => (props.inverted ? GetColor.White : GetColor.Black)};
  @media print {
    font-size: 14px;
  }
`;

export const Headline4 = styled.h3<AlignmentAndStyleProps>`
  ${base};
  font-size: 16px;
  font-weight: bold;
  color: ${(props) => (props.inverted ? GetColor.White : GetColor.Black)};
  @media print {
    font-size: 14px;
  }
`;

export const Subtitle1 = styled.h4<AlignmentAndStyleProps>`
  ${baseInline};
  font-size: 14px;
  font-weight: normal;
  color: ${(props) => (props.inverted ? GetColor.PaleGrey : GetColor.DarkGrey)};

  a {
    color: ${(props) => (props.inverted ? GetColor.Primary.Main : GetColor.Primary.Dark)};
  }

  @media print {
    font-size: 12px;
  }
`;

export const Subtitle2 = styled.h4<AlignmentAndStyleProps>`
  ${baseInline};
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  color: ${(props) => (props.inverted ? GetColor.White : GetColor.Black)};
`;

export const Body1 = styled.div<AlignmentAndStyleProps>`
  ${base};
  font-size: 14px;
  line-height: 1.71;
  color: ${(props) => (props.inverted ? GetColor.White : GetColor.Black)};
  @media print {
    font-size: 12px;
  }
`;

export const Body2 = styled.div<AlignmentAndStyleProps>`
  ${base};
  font-size: 12px;
  line-height: 1.71;
  color: ${(props) => (props.inverted ? GetColor.White : GetColor.Black)};
  @media print {
    font-size: 10px;
  }
`;

export const BodyHighlight = styled.span<AlignmentAndStyleProps>`
  ${baseInline};
  font-size: 14px;
  font-weight: normal;
  color: ${(props) => (props.inverted ? GetColor.HighlightLight : GetColor.HighlightDark)};
  @media print {
    font-size: 12px;
  }
`;

export const BodyEmphasis = styled.span<AlignmentAndStyleProps>`
  ${base};
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  color: ${(props) => (props.inverted ? GetColor.White : GetColor.Black)};
  @media print {
    font-size: 12px;
  }
`;

export const Hint = styled.span`
  ${baseInline};
  font-size: 14px;
  font-weight: normal;
  color: ${GetColor.HintGrey};
  @media print {
    font-size: 12px;
  }
`;

export const Link = styled.span<AlignmentAndStyleProps>`
  ${baseInline};
  font-weight: bold;
  color: ${(props) => (props.inverted ? GetColor.Primary.Main : GetColor.Primary.Dark)};
  font-size: inherit;

  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;

export const ButtonText = styled.span<StyleProps>`
  ${baseInline};
  font-weight: bold;
  color: ${(props) => (props.inverted ? GetColor.White : GetColor.Black)};
  font-size: 11px;
  text-transform: uppercase;
`;

export const Caption = styled.span<AlignmentAndStyleProps>`
  ${baseInline};
  font-weight: normal;
  color: ${(props) => (props.inverted ? GetColor.White : GetColor.Black)};
  font-size: 10px;
`;

export const Label = styled.label<StyleProps>`
  ${baseInline};
  font-size: 12px;
  font-weight: bold;
  color: ${(props) => (props.inverted ? GetColor.White : GetColor.Black)};
  cursor: inherit;
  @media print {
    font-size: 10px;
  }
`;

export const SecondaryLabel = styled(Label)`
  color: ${GetColor.HintGrey};
  font-weight: normal;
`;

export const HyperLink = styled.a<StyleProps>`
  ${baseInline};
  color: ${GetColor.Primary.Dark};

  &:hover {
    color: ${GetColor.Primary.Main};
  }
`;

export const Highlight = styled.span<StyleProps>`
  ${baseInline};
  color: ${(props) => (props.inverted ? GetColor.HighlightLight : GetColor.HighlightDark)};
`;

export const Highlight2 = styled.span<StyleProps>`
  ${baseInline};
  background-color: ${(props) => (props.inverted ? GetColor.HighlightLight : GetColor.HighlightDark)};
  color: ${GetColor.White};
  font-weight: 500;
  line-height: 14px;
  align-items: center;
  padding: 2px;
`;
