import { camelCase, compact, entries, groupBy, isNil, snakeCase, startCase } from 'lodash';
import type { FactorLensWithReturns, TimePeriodEnum } from 'venn-api';
import { ComputableMetricEnum } from 'venn-api';
import type { AdvancedQuery, QueryOperator } from 'venn-state';
import type { AccordionMenuItem, DropMenuItem } from 'venn-ui-kit';
import { toClassName } from 'venn-utils';
import type { AdvancedQueryRow } from '../types';

interface AdvancedQueryMetric {
  label: string;
  value: ComputableMetricEnum;
  isPercentage: boolean;
  className: string;
}

const toTitleCase = (str: string) => startCase(camelCase(str));

export const isPercentage = (metric: ComputableMetricEnum): boolean => {
  return !metric.startsWith('FACTOR_EXPOSURE') && metric !== 'SHARPE_RATIO';
};

const PREFIXES = new Set(['FACTOR EXPOSURE', 'FACTOR CONTRIBUTION TO RISK', 'FACTOR CONTRIBUTION TO RETURN']);

/**
 * Format the prefix of the computable metric string if it exists, e.g. into
 * Factor Contribution To Risk: Equity
 * @param metric the computable metric
 */
const formatPrefix = (metric: string): string => {
  for (const prefix of PREFIXES) {
    const metricPrefix = metric.substr(0, prefix.length);
    if (metricPrefix.toUpperCase() === prefix) {
      return `${metricPrefix}:${metric.substr(prefix.length)}`;
    }
  }
  return metric;
};

export const getMetricLabel = (metric: ComputableMetricEnum): string => {
  // preserve all-caps words and keep known preposition as all lowercase
  // hardcoding them seems fine for the metrics we have now, but can be generalized in the future if needed
  return formatPrefix(toTitleCase(metric))
    .replace(/\bfi\b/i, 'FI')
    .replace(/\bfx\b/i, 'FX')
    .replace(/\bto\b/i, 'to');
};

export const METRIC_OPTIONS: AdvancedQueryMetric[] = Object.values(ComputableMetricEnum || {}).map((metric) => ({
  label: getMetricLabel(metric),
  value: metric,
  isPercentage: isPercentage(metric),
  className: toClassName(metric),
}));

export const TIME_PERIOD_TO_DISPLAY_NAME: { [key in TimePeriodEnum]: string } = {
  FULL: 'Full History',
  YTD: 'YTD',
  YEAR_1: '1 Year',
  YEAR_3: '3 Year',
  YEAR_5: '5 Year',
  YEAR_7: '7 Year',
  YEAR_10: '10 Year',
  YEAR_15: '15 Year',
  YEAR_20: '20 Year',
};

export const TIME_PERIOD_OPTIONS = ['FULL', 'YTD', 'YEAR_1', 'YEAR_3', 'YEAR_5', 'YEAR_7', 'YEAR_10'].map((key) => ({
  label: TIME_PERIOD_TO_DISPLAY_NAME[key],
  value: key,
  className: toClassName(key),
})) as DropMenuItem<TimePeriodEnum>[];

export const QUERY_OPERATOR_OPTIONS = [
  {
    label: 'less than or equal to',
    value: 'leq',
    className: 'qa-leq',
  },
  {
    label: 'greater than or equal to',
    value: 'geq',
    className: 'qa-geq',
  },
] as DropMenuItem<QueryOperator>[];

export const toInitialAdvancedQueryRows = (advancedQueries?: AdvancedQuery[]) => {
  return advancedQueries?.length
    ? advancedQueries
        .map((query, index) => ({
          ...query,
          key: index,
        }))
        .map(getValidatedQuery)
    : [{ key: 0 }];
};

export const toAdvancedQueryValue = (value: string | number) => parseFloat(value.toString().trim());

export const isEmptyQuery = ({ timePeriod, metric, operator, value }: AdvancedQuery | AdvancedQueryRow) =>
  !timePeriod && !metric && !operator && isNil(value);

export const isValidAdvancedQuery = ({ timePeriod, metric, operator, value }: AdvancedQuery | AdvancedQueryRow) =>
  !!(timePeriod && metric && operator && !isNil(value) && !Number.isNaN(toAdvancedQueryValue(value)));

export const getValidatedQuery = (query: AdvancedQueryRow): AdvancedQueryRow => ({
  ...query,
  // Don't be too strict. If it's completely empty then the user probably didn't forget to fill it out
  error: !isValidAdvancedQuery(query) && !isEmptyQuery(query),
});

export const parseNumValue = (value: string | number | undefined, isPercentage?: boolean) => {
  if (isNil(value) || value === '') {
    return undefined;
  }
  const num = toAdvancedQueryValue(value);
  return Number.isNaN(num) ? undefined : isPercentage ? fixFloatingPoint(num, 0.01) : num;
};

export const roundDouble = (value: number, places: number) => {
  return value % 1 === 0 ? value : value.toFixed(places);
};

export const toDisplayValue = (value: string | number | undefined, isPercentage?: boolean) => {
  if (isNil(value)) {
    return '';
  }
  const num = toAdvancedQueryValue(value);
  return Number.isNaN(num) ? '' : isPercentage ? roundDouble(fixFloatingPoint(num, 100), 2) : roundDouble(num, 2);
};

export const toDisplayString = ({ timePeriod, metric, operator, value }: AdvancedQuery): string => {
  const { label, isPercentage } = METRIC_OPTIONS.find(({ value }) => value === metric) ?? {};
  const displayValue = `${toDisplayValue(value, isPercentage)}${isPercentage ? '%' : ''}`;

  return `${TIME_PERIOD_TO_DISPLAY_NAME[timePeriod]} ${label} ${operator === 'leq' ? '≤' : '≥'} ${displayValue}`;
};

export enum ComputableMetricType {
  PERFORMANCE = 'PERFORMANCE',
  FACTOR_EXPOSURE = 'FACTOR_EXPOSURE',
  FACTOR_CONTRIBUTION_TO_RISK = 'FACTOR_CONTRIBUTION_TO_RISK',
  FACTOR_CONTRIBUTION_TO_RETURN = 'FACTOR_CONTRIBUTION_TO_RETURN',
}

export const factorNameToEnum = (name: string): string => {
  if (name === 'Foreign Currency') {
    return 'FX';
  }
  if (name === 'Foreign Exchange Carry') {
    return 'FX_CARRY';
  }
  if (name === 'Fixed Income Carry') {
    return 'FI_CARRY';
  }
  if (name === 'Crowding') {
    return 'RESIDUAL_CROWDING';
  }
  return snakeCase(name).toUpperCase();
};

const prependPrefix = (item: AccordionMenuItem<string>, prefix?: string): void => {
  item.value = `${prefix}_${item.value}`;
  item.items?.forEach((childItem) => prependPrefix(childItem, prefix));
};

export const generateFactorLensAccordionItems = (factorLenses?: FactorLensWithReturns[]) => {
  const factors = factorLenses?.[0].factors ?? [];
  const items: AccordionMenuItem<string>[] = Object.values(ComputableMetricType)
    .filter((metricType) => metricType !== ComputableMetricType.PERFORMANCE)
    .map((metricType) => ({
      value: metricType as string,
      label: startCase(metricType.toLowerCase()),
      items: compact([
        ...entries(
          groupBy(
            factors.map((factor) => ({
              label: factor.name,
              value: factorNameToEnum(factor.name),
              category: factor.category,
            })),
            'category',
          ),
        ).map(([key, value]) => ({
          label: key,
          value: snakeCase(key).toUpperCase(),
          items: value,
        })),
        metricType !== ComputableMetricType.FACTOR_EXPOSURE
          ? {
              label: 'Residual',
              value: 'RESIDUAL',
            }
          : null,
      ]),
    }));

  items.forEach((item) => {
    item.items?.forEach((childItem) => prependPrefix(childItem, item.value));
  });

  // performance would be the first item
  items.unshift({
    value: ComputableMetricType.PERFORMANCE,
    label: startCase(ComputableMetricType.PERFORMANCE.toLowerCase()),
    items: [
      {
        value: ComputableMetricEnum.CUMULATIVE_RETURN,
        label: startCase(ComputableMetricEnum.CUMULATIVE_RETURN.toLowerCase()),
      },
      {
        value: ComputableMetricEnum.ANNUALIZED_RETURN,
        label: startCase(ComputableMetricEnum.ANNUALIZED_RETURN.toLowerCase()),
      },
      {
        value: ComputableMetricEnum.ANNUALIZED_VOLATILITY,
        label: startCase(ComputableMetricEnum.ANNUALIZED_VOLATILITY.toLowerCase()),
      },
      {
        value: ComputableMetricEnum.SHARPE_RATIO,
        label: startCase(ComputableMetricEnum.SHARPE_RATIO.toLowerCase()),
      },
    ],
  });
  return items;
};

// Extracts list of supported data library metrics from accordion items
export const getSupportedMetrics = (factorLenses?: FactorLensWithReturns[]): string[] => {
  const metrics: string[] = [];
  const items = generateFactorLensAccordionItems(factorLenses);

  const recrusivelyAddMetrics = (item: AccordionMenuItem<string>) => {
    if (isNil(item.items)) {
      // Leaf node
      if (!isNil(item.value)) {
        metrics.push(item.value);
      }
    } else {
      item.items.forEach(recrusivelyAddMetrics);
    }
  };

  items.forEach(recrusivelyAddMetrics);

  return metrics;
};

const fixFloatingPoint = (num: number, multiplier: number) => Number((num * multiplier).toFixed(8));
