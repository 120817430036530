import React from 'react';
import styled from 'styled-components';
import { GetColor, Icon, PRIVATE_ASSET_CASH_FLOW_HELP_HREF, Tooltip } from 'venn-ui-kit';

const PrivatesCashflowPacingSettingsTooltip = () => {
  return (
    <Wrapper>
      <Row>
        <Text>CF Pacing model parameter settings</Text>
        <Tooltip
          usePortal
          content='Settings can be accessed by clicking the "..." next to any private asset subject in the Inputs panel'
        >
          <StyledIcon type="circle-info" prefix="far" />
        </Tooltip>
      </Row>
      <HelpText>
        To learn more,{' '}
        <a target="_blank" rel="noopener noreferrer" href={PRIVATE_ASSET_CASH_FLOW_HELP_HREF}>
          visit the FAQ
        </a>
        .
      </HelpText>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
`;

const Text = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: ${GetColor.Primary.Dark};
`;

const StyledIcon = styled(Icon)`
  color: ${GetColor.GreyScale.Grey60};
  display: block;
`;

const HelpText = styled.div`
  margin-top: 4px;
  font-size: 12px;
  color: ${GetColor.GreyScale.Grey70};
`;

export default PrivatesCashflowPacingSettingsTooltip;
