import { getCreatePasswordErrors, getPasswordErrors, validateEmail, MINIMUM_PASSWORD_LENGTH } from './Logic';
import capitalize from 'lodash/capitalize';
import isNil from 'lodash/isNil';
import type { Validator, FormValues } from 'venn-components';
import type { AnyDuringEslintMigration } from 'venn-utils';

export const email: Validator<string> = (value: string) => {
  const error = 'This is not a valid email.';
  if (typeof value !== 'string') {
    return error;
  }

  const parts = value.split('@');
  return parts.length !== 2 || parts.some((part) => part.length < 1) ? error : undefined;
};

const defaultGetValue = (value: AnyDuringEslintMigration) => {
  if (isNil(value) || typeof value === 'string') {
    return value;
  }
  return value.value;
};

export const required =
  <T>(fieldName?: string, getValueString: (value: T) => string | undefined | null = defaultGetValue): Validator<T> =>
  (value) => {
    const valueString = getValueString(value);
    if (isNil(valueString) || valueString.trim() === '') {
      return fieldName ? `${capitalize(fieldName)} is required.` : 'This is a required field.';
    }
    return undefined;
  };

export const withMessage =
  <T>(validator: Validator<T>, message: string): Validator<T> =>
  (value: T, values?: FormValues) =>
    (validator(value, values) && message) || undefined;

export const matchesPasswordValue = <T>(value: T, values?: FormValues) =>
  value === values?.password ? undefined : 'Passwords must match.';

export const isPasswordValid: Validator<string> = (password: string) => {
  const errorMessages = getPasswordErrors(password || '');

  const isPasswordIncorrect = errorMessages.length > 0;

  if (isPasswordIncorrect) {
    const errorMessageToShow = `New password ${errorMessages.join(', and ')}.`;
    return errorMessageToShow;
  }

  return undefined;
};

export const isInitialPasswordValid: Validator<string> = (password: string) => {
  const errorMessages = getCreatePasswordErrors(password || '');

  const isPasswordIncorrect = errorMessages.length > 0;

  if (isPasswordIncorrect) {
    const errorMessageToShow = `Password ${errorMessages.join(', and ')}.`;
    return errorMessageToShow;
  }

  return undefined;
};

export const isEmailValid: Validator<string> = (emailString: string) => {
  const errorMessages = validateEmail(emailString);

  const isValid = errorMessages.length === 0;

  if (!isValid) {
    const errorMessageToShow = errorMessages[0];
    return errorMessageToShow;
  }

  return undefined;
};

export const PASSWORD_RULES_TEXT = `At least ${MINIMUM_PASSWORD_LENGTH} characters`;
