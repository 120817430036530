import styled, { css } from 'styled-components';
import { CenteredText } from '../components/styled';
import { ColorUtils, GetColor } from 'venn-ui-kit';

export const BoldValue = styled(CenteredText)<{ color?: string }>`
  font-weight: bold;
  color: ${({ color }) => color ?? GetColor.Black};
  margin-left: 5px;
  margin-right: 5px;
  line-height: 16px;
`;

export const BoldValueButton = styled(BoldValue)<{ disabled?: boolean }>`
  padding: 0 4px;
  ${({ disabled }) =>
    disabled
      ? css`
          pointer-events: none;
        `
      : css`
          &:hover {
            position: relative;
            left: -1px;
            margin-right: 3px;
            border: 1px solid ${GetColor.Primary.Dark};
            border-radius: 2px;
            background-color: ${ColorUtils.opacifyFrom(GetColor.Primary.Dark, 0.1)};
            cursor: pointer;
          }
        `}
`;

export const FactorConstraintRow = styled.div`
  display: flex;
  align-items: center;
  color: ${GetColor.HintGrey};
  border-left: 1px dashed ${GetColor.Grey};
  height: 28px;
  padding-left: 10px;
  margin-left: 10px;
  &:last-child {
    margin-bottom: 5px;
  }
`;
