import React, { useCallback, useMemo } from 'react';
import type { HoldingsDataProps } from '../../types';
import { DATA_TYPE_EXCEL_STYLES } from '../../logic/customBlockUtils';
import ExportableGrid from './ExportableGrid';
import { useSectorHoldingsTreeGrid } from '../../logic/useSectorHoldingsTreeGrid';
import { useCommonTreeGridProps } from '../../logic/columnUtils';
import type { CellClassParams } from '../../../data-grid';
import { useIsBlockGridCompact } from '../../logic/useIsBlockGridCompact';
import { GeoLevelFilterType, SectorLevelFilterType } from './types';
import { useRecoilValue } from 'recoil';
import { geoLevelFilterType, sectorLevelFilterType } from 'venn-state';

const extraDefaultColDef = {
  cellStyle: (params: CellClassParams) => (params.data.isParent ? { 'font-weight': 'bold' } : null),
};

const HoldingsTreeGrid = ({ selectedRefId, ...exportProps }: HoldingsDataProps) => {
  const geoLevelFilter = useRecoilValue(geoLevelFilterType(selectedRefId));
  const sectorLevelFilter = useRecoilValue(sectorLevelFilterType(selectedRefId));

  const {
    rowData: rawRowData,
    columnDefs,
    autoGroupColumnDef,
  } = useSectorHoldingsTreeGrid({
    selectedRefId,
    isBarChart: false,
  });

  const rowData = useMemo(
    () =>
      rawRowData.filter((row) => {
        const noFiltersPresent = !geoLevelFilter && !sectorLevelFilter;
        if (row.isMetadata || noFiltersPresent) {
          return true;
        }

        switch (geoLevelFilter) {
          case GeoLevelFilterType.REGION:
            return row.path.length === 1;
          case GeoLevelFilterType.SUB_REGION:
            return row.path.length <= 2;
          case GeoLevelFilterType.DEFAULT:
            return true;
        }

        switch (sectorLevelFilter) {
          case SectorLevelFilterType.TOP_LEVEL:
            return row.path.length === 1;
          case SectorLevelFilterType.SUB_SECTOR:
            return row.path.length <= 2;
          case SectorLevelFilterType.DEFAULT:
            return true;
        }

        return true;
      }),
    [geoLevelFilter, sectorLevelFilter, rawRowData],
  );

  const treeGridProps = useCommonTreeGridProps(extraDefaultColDef);
  const isCompact = useIsBlockGridCompact();

  return (
    <ExportableGrid
      {...treeGridProps}
      {...exportProps}
      isCompact={isCompact}
      rowData={rowData}
      selectedRefId={selectedRefId}
      exportable={false}
      columnDefs={columnDefs}
      onGridReady={useCallback((event) => {
        // TODO(collin.irwin): seems redundant with the sizeColumnsToFit in ExportableGrid?
        event.api.sizeColumnsToFit();
      }, [])}
      excelStyles={DATA_TYPE_EXCEL_STYLES}
      autoGroupColumnDef={autoGroupColumnDef}
    />
  );
};

export default HoldingsTreeGrid;
