import { useMutation } from '@tanstack/react-query';
import type { ReactText } from 'react';
import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import type { OperationError, OperationResult, PrivateFund } from 'venn-api';
import { renamePrivateFund } from 'venn-api';
import { EditableText, GetColor, Notifications, NotificationType } from 'venn-ui-kit';

type PrivateFundEditableNameProps = Readonly<{
  onChange?: (name: string) => void;
  disabled: boolean;
  privateFund: PrivateFund;
}>;

const PrivateFundEditableName = ({ onChange, disabled, privateFund }: PrivateFundEditableNameProps) => {
  const [editedName, setEditedName] = useState(privateFund.name);
  const [error, setError] = useState('');

  const toastRef = useRef<ReactText>();

  useEffect(() => {
    setEditedName(privateFund.name);
  }, [privateFund.name]);

  const mutation = useMutation(
    async (newName: string) => {
      await renamePrivateFund(privateFund.id, newName);
    },
    {
      onMutate: () => {
        toastRef.current = Notifications.notify('Renaming investment...', NotificationType.LOADING);
      },
      onError: (error: OperationResult<OperationError>) => {
        setError(error.content.message);
        setEditedName(privateFund.name);
        Notifications.notifyUpdate(toastRef.current, 'Failed to update investment name', NotificationType.ERROR);
      },
      onSuccess: (data, newName) => {
        Notifications.notifyUpdate(toastRef.current, 'Investment name updated successfully', NotificationType.SUCCESS);
        onChange?.(newName);
      },
    },
  );

  const submit = (newName: string) => {
    if (newName === privateFund.name) {
      return;
    }
    if (newName === '') {
      setEditedName(privateFund.name);
      return;
    }
    mutation.mutate(newName);
  };

  return (
    <>
      <EditableText
        onChange={(e) => {
          setEditedName(e.target.value);
          setError('');
        }}
        onSubmit={submit}
        value={editedName}
        error={!!error}
        disabled={disabled}
      />
      {error && <ErrorComponent>{error}</ErrorComponent>}
    </>
  );
};

const ErrorComponent = styled.div`
  margin-left: 20px;
  font-size: 22px;
  font-style: normal;
  font-weight: normal;
  color: ${GetColor.Error};
`;

export default PrivateFundEditableName;
