import React from 'react';

import { Tooltip, Button, getAppTitle } from 'venn-ui-kit';
import { useModal } from 'venn-utils';
import ResetConfirmationModal from './ResetConfirmationModal';

interface ResetButtonProps {
  isDefault: boolean;
  disabled: boolean;
  onConfirm: () => void;
}

const ResetButton = ({ isDefault, disabled, onConfirm }: ResetButtonProps) => {
  const [isOpen, open, close] = useModal();

  return (
    <>
      <Tooltip maxWidth={300} content={isDefault ? `Default ${getAppTitle()} content is already selected` : undefined}>
        <Button className="qa-reset-content-btn" dominant disabled={disabled} onClick={open}>
          {`Reset to ${getAppTitle()} Content`}
        </Button>
      </Tooltip>
      {isOpen ? (
        <ResetConfirmationModal
          onCancel={close}
          onConfirm={() => {
            onConfirm();
            close();
          }}
        />
      ) : null}
    </>
  );
};

export default ResetButton;
