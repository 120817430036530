import React from 'react';
import styled from 'styled-components';
import { GetColor, ZIndex } from 'venn-ui-kit';

export interface CloseIconProps {
  /** Function fired to close Modal */
  onClose?: () => void;
  color?: string;
  opacity?: string;
  width?: string;
}

const IconWrapper = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 1.6rem;
  cursor: pointer;
  z-index: ${ZIndex.Front};
  path {
    stroke: ${(props) => props.color ?? GetColor.Primary.Main};
  }
  :hover path {
    stroke: ${GetColor.Primary.Main};
  }
`;

const CloseIcon = ({ color, opacity = '0.5', width = '1', onClose }: CloseIconProps) =>
  onClose ? (
    <IconWrapper data-testid="qa-close-button" onClick={onClose} aria-label="Close" color={color}>
      <svg width="20" height="26" viewBox="0 0 23 22" className="qa-close-button">
        <path
          fill="none"
          fillRule="evenodd"
          strokeLinecap="square"
          strokeOpacity={opacity}
          strokeWidth={width}
          d="M11.284 11.284l10.607 10.607-10.607-10.607L21.891.678 11.284 11.284zm0 0L.678.678l10.606 10.606L.678 21.891l10.606-10.607z"
        />
      </svg>
    </IconWrapper>
  ) : null;

export default CloseIcon;
