import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import InvestmentActions from '../../../investment-actions/InvestmentActions';
import InlineActionPillButton from './InlineActionPillButton';
import { ListItemConstraining, ListItemWithFrequency, ListItemWithReturns } from './styled';
import type { Fund, InvestmentRange } from 'venn-api';
import { getFund } from 'venn-api';
import type { AnalysisSubject } from 'venn-utils';
import { useApi } from 'venn-utils';
import { CellLoader, getAppTitle } from 'venn-ui-kit';

export type ListItemType = 'frequency' | 'returns' | 'constraining';
interface InvestmentWithInlineActionsProps {
  subject: AnalysisSubject;
  fundName: string;
  fundId: string;
  label?: string;
  range: InvestmentRange;
  infoType: ListItemType;
  onFundDataUpdated?: (fundId: string, throwOnFailure?: boolean) => Promise<void>;
  onDeleteFundFromPortfolio: (fundId: string) => void;
  availableStart?: number;
  availableEnd?: number;
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default ({
  subject,
  fundName,
  fundId,
  range,
  infoType,
  label,
  onFundDataUpdated,
  onDeleteFundFromPortfolio,
  availableStart,
  availableEnd,
}: InvestmentWithInlineActionsProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [fund, setFund] = useState<Fund | undefined>(undefined);
  const getFundRef = useRef(useApi(getFund));

  useEffect(() => {
    const fetchFund = async () => {
      setLoading(true);
      try {
        const { content } = await getFundRef.current(fundId);
        setFund(content);
      } catch (e) {
        /**/
      }
      setLoading(false);
    };
    if (subject.fund?.id === fundId) {
      setFund(subject.fund);
    } else {
      fetchFund();
    }
  }, [fundId, subject.fund]);

  const ListItem =
    infoType === 'frequency'
      ? ListItemWithFrequency
      : infoType === 'returns'
        ? ListItemWithReturns
        : ListItemConstraining;

  const cantRemoveYearlyPrimarySubject =
    subject.superType === 'investment' && subject.fund?.returnFrequency === 'YEARLY';

  return (
    <ListItem
      name={fundName}
      label={label}
      range={range}
      suffix=". "
      availableStart={availableStart}
      availableEnd={availableEnd}
    >
      {cantRemoveYearlyPrimarySubject ? (
        `${getAppTitle()} has limited support for yearly investments.`
      ) : (
        <>
          Suggested actions:
          <FlexBox>
            {loading && (
              <LoaderWrapper>
                <CellLoader />
              </LoaderWrapper>
            )}
            {fund && (
              <>
                <InvestmentActions
                  context="error"
                  fund={fund}
                  onFundDataUpdated={onFundDataUpdated}
                  availableStart={availableStart}
                  availableEnd={availableEnd}
                />
              </>
            )}
            {subject.superType === 'portfolio' && (
              <>
                <InlineActionPillButton onClick={() => onDeleteFundFromPortfolio(fundId)}>
                  Remove from portfolio
                </InlineActionPillButton>
              </>
            )}
          </FlexBox>
        </>
      )}
    </ListItem>
  );
};

const FlexBox = styled.div`
  display: flex;
  align-items: center;
`;

const LoaderWrapper = styled.div`
  margin-left: 20px;
  height: 35px;
  display: flex;
  align-items: center;
`;
