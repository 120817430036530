import type { FC } from 'react';
import React from 'react';
import { LEGEND_TEXT_PADDING_GAP } from './constants';

export interface LegendSquareProps {
  size?: number;
  color: string;
  rotate?: boolean;
  x: number;
  y: number;
  name: string;
  print: boolean;
  fontSize?: string;
}

export const LegendSquare: FC<React.PropsWithChildren<LegendSquareProps>> = ({
  size = 6,
  color,
  rotate = false,
  x,
  y,
  name,
  print,
  fontSize,
}) => {
  const width = rotate ? size * 1.5 : size * 2;
  const transform = rotate ? `translate(0, ${size / 2}) rotate(45 ${x + size} ${y + size})` : '';
  const textX = x + LEGEND_TEXT_PADDING_GAP + size * 2;
  return (
    <g>
      <rect x={x} y={y} width={width} height={width} fill={color} transform={transform} />
      <text x={textX} y={y + 12} fontSize={fontSize || (print ? '10px' : '14px')} textAnchor="start">
        {name}
      </text>
    </g>
  );
};

export default LegendSquare;
