import React, { useCallback, useEffect, useState } from 'react';
import type { Portfolio } from 'venn-api';
import type { AllocationCondition, AllocationItem, AllocConstraint, ConstraintValueType } from 'venn-utils';
import { isEqual, isNil, keys } from 'lodash';
import { splitConstraintsByType } from '../constraintsFormatUtils';
import EditableAllocationConstraintParts, { QueryCreationStep } from './EditableAllocationConstraintView';

interface EditableAllocationConstraintProps {
  portfolio: Portfolio;
  idx: number;
  existingConstraint?: AllocConstraint;
  onDeleteConstraint: () => void;
  onCreateConstraint: (constraints: AllocConstraint[]) => void;
  disabled?: boolean;
}

const EditableAllocationConstraint = ({
  portfolio,
  idx,
  existingConstraint,
  onCreateConstraint,
  onDeleteConstraint,
  disabled,
}: EditableAllocationConstraintProps) => {
  // Initialize the values with existing constraint, if passed
  const [confirmedItems, setConfirmedItems] = useState<AllocationItem[] | undefined>(
    existingConstraint?.allInvestments ? undefined : existingConstraint?.items,
  );
  const [allInvestments, setAllInvestments] = useState<boolean | undefined>(existingConstraint?.allInvestments);
  const [condition, setCondition] = useState<AllocationCondition | undefined>(existingConstraint?.condition);
  const [valueType, setValueType] = useState<ConstraintValueType | undefined>(existingConstraint?.valueType);
  const [value, setValue] = useState<number | undefined>(existingConstraint?.value);

  const [creating, setCreating] = useState(false);

  const clearToConstraintValues = useCallback(() => {
    if (!isNil(existingConstraint)) {
      setConfirmedItems(existingConstraint.allInvestments ? undefined : existingConstraint.items);
      setAllInvestments(!!existingConstraint.allInvestments);
      setCondition(existingConstraint.condition);
      setValueType(existingConstraint.valueType);
      setValue(existingConstraint.value);
      setCreating(false);
    }
  }, [existingConstraint]);

  // If at any point we get passed the existing constraint, make sure to keep the values in sync
  useEffect(() => {
    clearToConstraintValues();
  }, [clearToConstraintValues]);

  const creationStep: QueryCreationStep =
    isNil(confirmedItems) && isNil(allInvestments)
      ? QueryCreationStep.SELECT_SUBJECT
      : isNil(condition)
        ? QueryCreationStep.SELECT_CONDITION
        : isNil(valueType) || (valueType !== 'currentValue' && isNil(value))
          ? QueryCreationStep.SELECT_VALUE
          : isNil(existingConstraint) ||
              !isEqual(
                {
                  ...existingConstraint,
                  value: existingConstraint?.value,
                  allInvestments: !!existingConstraint?.allInvestments,
                  isNew: existingConstraint?.isNew,
                },
                {
                  items: confirmedItems ?? [],
                  allInvestments: !!allInvestments,
                  condition,
                  valueType,
                  value,
                  isInPortfolioPolicy: existingConstraint?.isInPortfolioPolicy,
                  isNew: existingConstraint?.isNew,
                },
              )
            ? QueryCreationStep.READY
            : QueryCreationStep.CREATED;

  useEffect(() => {
    const canCreate = creationStep === QueryCreationStep.READY && !creating;
    const valueValidForType = valueType === 'currentValue' || !isNil(value);
    const subjectsNotEmpty = allInvestments || !!confirmedItems?.length;

    if (canCreate && !isNil(condition) && !isNil(valueType) && valueValidForType && subjectsNotEmpty) {
      setCreating(true);
      // Only keep that constraint marked as "new" if all settings surrounding it are kept the same (only value can change)
      const isNew =
        existingConstraint?.isNew &&
        valueType === 'fixedValue' &&
        condition === 'maxAllocation' &&
        !allInvestments &&
        isEqual(confirmedItems, existingConstraint?.items);
      onCreateConstraint(
        splitConstraintsByType(
          {
            items: confirmedItems ?? [],
            allInvestments,
            condition,
            valueType,
            value,
            isInPortfolioPolicy: false,

            ...(isNew && {
              isNew,
            }),
          },
          portfolio.id,
        ),
      );
    }
  }, [
    creationStep,
    creating,
    portfolio.id,
    confirmedItems,
    allInvestments,
    condition,
    valueType,
    value,
    onCreateConstraint,
    existingConstraint?.isNew,
    existingConstraint?.items,
  ]);

  const onUpdateValues = useCallback((updatedValues: Partial<AllocConstraint>) => {
    const keysToUpdate = keys(updatedValues);
    for (const key of keysToUpdate) {
      const value = updatedValues[key];
      switch (key) {
        case 'items':
          setConfirmedItems(value);
          break;
        case 'allInvestments':
          setAllInvestments(value);
          break;
        case 'condition':
          setCondition(value);
          break;
        case 'valueType':
          setValueType(value);
          break;
        case 'value':
          setValue(value);
          break;
        default:
          break;
      }
    }
  }, []);

  return (
    <EditableAllocationConstraintParts
      creationStep={creationStep}
      portfolio={portfolio}
      idx={idx}
      existingConstraint={existingConstraint}
      items={confirmedItems}
      allInvestments={allInvestments}
      condition={condition}
      valueType={valueType}
      value={value}
      onUpdateValues={onUpdateValues}
      onDeleteConstraint={onDeleteConstraint}
      onResetConstraint={clearToConstraintValues}
      disabled={disabled}
    />
  );
};

export default EditableAllocationConstraint;
