import React, { PureComponent } from 'react';
import type { ActionEnum, MemberSortEnum, UserProfileSettings, WorkspaceMember } from 'venn-api';
import { searchOrganizationMembers } from 'venn-api';
import { InviteTeamModal, SearchBar, SORTDIR } from 'venn-components';
import { analyticsService, FS, getResultRangeEnd, getResultRangeStart, VennEvents } from 'venn-utils';
import CompanyInfo from './components/CompanyInfo';
import MembersTable from './components/members-table/MembersTable';
import {
  FlexRow,
  FlexWrapper,
  Info,
  PaginationWrapper,
  ResultHint,
  SearchBox,
  StyledButton,
  StyledIcon,
  StyledWrapper,
  TableName,
} from './components/shared';
import { GetColor, Icon, Notifications, NotificationType, Pagination, ProgressBar } from 'venn-ui-kit';
import styled from 'styled-components';

const PAGE_SIZE = 25;

interface CompanyProps {
  profileSettings: UserProfileSettings;
  hasPermission: (permission: ActionEnum) => boolean;
}

interface CompanyState {
  showInvitationModal: boolean;
  members: WorkspaceMember[];
  invitedUsers: number;
  activeUsers: number;
  totalCount: number;
  page: number;
  search: string;
  sortField: MemberSortEnum;
  descending: boolean;
}

class Company extends PureComponent<CompanyProps, CompanyState> {
  state: CompanyState = {
    showInvitationModal: false,
    members: [],
    invitedUsers: 0,
    activeUsers: 0,
    totalCount: 0,
    page: 1,
    search: '',
    sortField: 'NAME',
    descending: false,
  };

  async componentDidMount() {
    const { sortField, descending } = this.state;

    document.addEventListener(VennEvents.invitationSent, this.refreshSearch);

    try {
      const membersRes = await searchOrganizationMembers({
        pageSize: PAGE_SIZE,
        sortField,
        descending,
      });
      const { members, total, invitedUsers, activeUsers } = membersRes.content;

      this.setState({
        members,
        invitedUsers,
        activeUsers,
        totalCount: total,
      });
    } catch (e) {
      const error = await e;
      Notifications.notify(error.content?.message, NotificationType.ERROR);
    }
  }

  componentWillUnmount() {
    document.removeEventListener(VennEvents.invitationSent, this.refreshSearch);
  }

  componentDidUpdate(prevProps: CompanyProps, prevState: CompanyState) {
    const { profileSettings } = this.props;
    const { search, page, sortField, descending } = this.state;
    if (
      search !== prevState.search ||
      page !== prevState.page ||
      sortField !== prevState.sortField ||
      descending !== prevState.descending ||
      profileSettings?.user?.displayName !== prevProps?.profileSettings?.user?.displayName ||
      profileSettings?.user?.avatarId !== prevProps?.profileSettings?.user?.avatarId
    ) {
      this.searchOrg(page, search, sortField, descending);
    }
  }

  openInvitationModal = () => {
    analyticsService.modalDisplayed({ topic: 'invite modal' });
    this.setState({
      showInvitationModal: true,
    });
  };

  closeInvitationModal = () => {
    analyticsService.modalClosed({
      topic: 'invite modal',
      cancelled: true,
    });
    this.setState({
      showInvitationModal: false,
    });
  };

  onDidSendInvitations = () => {
    analyticsService.modalClosed({
      topic: 'invite modal',
      cancelled: false,
    });
  };

  refreshSearch = () => {
    const { search, page, sortField, descending } = this.state;
    this.searchOrg(page, search, sortField, descending);
  };

  render() {
    const { showInvitationModal, members, invitedUsers, totalCount, page, search, sortField, descending, activeUsers } =
      this.state;
    const { profileSettings, hasPermission } = this.props;
    const rangeStart = getResultRangeStart(members.length, page, PAGE_SIZE);
    const rangeEnd = getResultRangeEnd(members.length, page, PAGE_SIZE);
    const numPages = Math.ceil(totalCount / PAGE_SIZE);
    const adminEmail = profileSettings?.organization?.accountAdministrator;
    const seatUsage = FS.getFeatureDetails('full_access_seat_cap');
    const showInvite = !!seatUsage;
    const usageCount = seatUsage?.usageCount ?? 0;
    const thresholdCount = seatUsage?.threshold?.count ?? 0;

    return (
      <div>
        <CompanyInfo profileSettings={profileSettings} activeUsers={activeUsers} invitedUsers={invitedUsers} />
        <StyledWrapper>
          <FlexRow>
            <div>
              <FlexWrapper>
                <StyledIcon type="users" />
                <TableName>Members</TableName>
              </FlexWrapper>
              <Info>Know who is sharing your workspace profile and data.</Info>
              {showInvite && FS.has('full_access_seat_cap_rollout_ff') && (
                <InfoContainer>
                  <Icon type="info-circle" /> Current Seat usage: {seatUsage?.usageCount} out of {thresholdCount}
                  <ProgressBar value={usageCount / thresholdCount} error={usageCount >= thresholdCount} />
                </InfoContainer>
              )}
            </div>
            {showInvite && (
              <>
                <StyledButton onClick={this.openInvitationModal} disabled={!hasPermission('INVITE_USERS')} dominant>
                  Invite Teammates
                </StyledButton>
                {showInvitationModal && (
                  <InviteTeamModal
                    onCancel={this.closeInvitationModal}
                    onDidSendInvitations={this.onDidSendInvitations}
                  />
                )}
              </>
            )}
          </FlexRow>
          <SearchBox>
            <SearchBar
              debounce={500}
              value={search}
              onChange={this.onSearch}
              placeholder="Search by name."
              disableAutofocus
            />
            <ResultHint>
              Showing {rangeStart} - {rangeEnd} of {totalCount}
            </ResultHint>
          </SearchBox>
          {members.length > 0 && (
            <MembersTable
              members={members}
              sortField={sortField}
              descending={descending}
              onSort={this.onSort}
              adminEmail={adminEmail}
              refreshSearch={this.refreshSearch}
              readOnly={!hasPermission('MANAGE_USERS')}
            />
          )}
          {numPages > 1 ? (
            <PaginationWrapper>
              <Pagination pagesCount={numPages} selectedPage={page} onPageChange={this.onPageChange} />
            </PaginationWrapper>
          ) : null}
        </StyledWrapper>
      </div>
    );
  }

  private onPageChange = (page: number) => {
    this.setState({
      page,
    });
  };

  private onSearch = (search: string) => {
    this.setState({
      search,
      page: 1,
    });
  };

  private searchOrg = async (page: number, name: string, sortField: MemberSortEnum, descending: boolean) => {
    try {
      const membersRes = await searchOrganizationMembers({
        name,
        page,
        pageSize: PAGE_SIZE,
        sortField,
        descending,
      });
      const { members, total } = membersRes.content;
      this.setState({
        members,
        totalCount: total,
      });
    } catch (e) {
      const error = await e;
      Notifications.notify(error.content?.message, NotificationType.ERROR);
    }
  };

  private onSort = (key: MemberSortEnum, dir: SORTDIR) => {
    this.setState({
      page: 1,
      sortField: key,
      descending: dir === SORTDIR.DESC,
    });
  };
}

export default Company;

const InfoContainer = styled.div`
  background-color: ${GetColor.GreyScale.Grey20};
  padding: 15px;
  margin-top: 10px;
  border-radius: 4px;
`;
