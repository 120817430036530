import styled from 'styled-components';
import { ColorUtils, GetColor, ZIndex } from 'venn-ui-kit';
import React from 'react';
import type { ReadonlyPortfolio } from 'venn-components';
import { ReadonlyAllocationTree } from 'venn-components';
import { PREVIEW_AREA_COLOR } from './styles';

interface PreviewPortfolioAllocatorProps {
  portfolio: ReadonlyPortfolio;
}

const PreviewPortfolioAllocator = ({ portfolio }: PreviewPortfolioAllocatorProps) => {
  return (
    <Container>
      <HeaderContainer>
        <HeaderText>Portfolio Allocator</HeaderText>
        <ActiveBar />
      </HeaderContainer>
      <ReadonlyAllocationTree portfolio={portfolio} itemNameMaxWidth={475} />
      {/* make it clear that portfolio is truncated */}
      <GradientOverlay />
    </Container>
  );
};

export default PreviewPortfolioAllocator;

const Container = styled.div`
  position: relative;
`;

const HeaderContainer = styled.div`
  padding: 10px;
  position: relative;
`;

const HeaderText = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${GetColor.Black};
  padding: 4px 2px;
  margin: 0 8px;
`;

const ActiveBar = styled.div`
  left: 20px;
  background-color: ${GetColor.Primary.Main};
  position: absolute;
  bottom: 0;
  height: 6px;
  width: 133px;
`;

const GradientOverlay = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50%;
  background: linear-gradient(${ColorUtils.hex2rgbaFrom(PREVIEW_AREA_COLOR, 0)}, ${PREVIEW_AREA_COLOR});
  z-index: ${ZIndex.Cover};
`;
