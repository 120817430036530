import React, { useContext, useState } from 'react';
import { FactorLensesContext } from '../../../contexts';
import type { DateRange, RangeType } from 'venn-ui-kit';
import { Headline3, Link as LinkStyle, Body1, FORECASTS_FAQ_HREF } from 'venn-ui-kit';
import type { CustomFactorForecast, RegressionPeriodEnum } from 'venn-api';
import { assertExhaustive, logMessageToSentry } from 'venn-utils';
import { useRecoilValue } from 'recoil';
import { activeForecastIdAtom, remoteFactorForecast } from 'venn-state';
import { ForecastPanelDateEditor } from '../components/ForecastPanelDateEditor';
import styled from 'styled-components';
import { ForecastPanelActionsContext } from '../contexts/ForecastPanelActionsContext';

const DATE_RANGE_OPTIONS = ['3yr', '5yr', '7yr', '10yr', 'full'] as RangeType[];

const getRegressionPeriodEnum = (period: RangeType) => {
  switch (period) {
    case '3yr':
      return 'YEAR_3';
    case '5yr':
      return 'YEAR_5';
    case '7yr':
      return 'YEAR_7';
    case '10yr':
      return 'YEAR_10';
    case 'full':
      return 'FULL';
    default:
      logMessageToSentry('unexpected date range for regression period');
      return '';
  }
};

const getRangeTypeFromRegressionPeriod = (period: RegressionPeriodEnum) => {
  switch (period) {
    case 'YEAR_3':
      return '3yr';
    case 'YEAR_5':
      return '5yr';
    case 'YEAR_7':
      return '7yr';
    case 'YEAR_10':
      return '10yr';
    case 'FULL':
      return 'full';
    default:
      logMessageToSentry('unexpected date range for regression period');
      return assertExhaustive(period);
  }
};

const getDefaultValue = (range: DateRange, forecast?: CustomFactorForecast): DateRange => {
  if (forecast && forecast.regressionStart && forecast.regressionEnd) {
    return {
      from: forecast.regressionStart,
      to: forecast.regressionEnd,
    };
  }

  if (forecast && forecast.regressionPeriod) {
    return {
      period: getRangeTypeFromRegressionPeriod(forecast.regressionPeriod),
    };
  }
  return range;
};

interface EsposurePerdiodEditorProps {
  onCancel: () => void;
}

export const ExposurePeriodEditor = ({ onCancel }: EsposurePerdiodEditorProps) => {
  const { onUpdateOrCreateFactorForecast } = useContext(ForecastPanelActionsContext);
  const activeForecastId = useRecoilValue(activeForecastIdAtom);
  const forecast = useRecoilValue(remoteFactorForecast(activeForecastId));
  const factorContext = useContext(FactorLensesContext);

  const range = {
    from: factorContext.primaryFactorLens?.latestStartDate,
    to: factorContext.primaryFactorLens?.earliestEndDate,
  } as DateRange;

  const [dateRangeValue, setDateRangeValue] = useState(getDefaultValue(range, forecast));
  const onUpdateDate = async (dateRange: DateRange) => {
    const updatedForecast = dateRange.period
      ? {
          ...forecast,
          regressionPeriod: getRegressionPeriodEnum(dateRange.period) as RegressionPeriodEnum,
          regressionStart: null,
          regressionEnd: null,
        }
      : {
          ...forecast,
          regressionStart: dateRange.from,
          regressionEnd: dateRange.to,
        };
    await onUpdateOrCreateFactorForecast(updatedForecast as CustomFactorForecast, false);
    setDateRangeValue(dateRange);
  };

  return (
    <>
      <ExposureEditorHeadline>Select a Factor Exposure Period</ExposureEditorHeadline>
      <Body1>Select either a relative period or a specified date range.</Body1>
      <ExposureEditorBody>
        <LinkStyle>
          <a target="_blank" rel="noopener noreferrer" href={FORECASTS_FAQ_HREF}>
            Learn more{' '}
          </a>
        </LinkStyle>{' '}
        about how the selected period is translated into your forecasts.
      </ExposureEditorBody>
      <ForecastPanelDateEditor
        dateRange={dateRangeValue}
        onApply={onUpdateDate}
        onCancel={onCancel}
        disabled={false}
        options={DATE_RANGE_OPTIONS}
        layout="right"
        relativeDatePeriod
      />
    </>
  );
};

const ExposureEditorHeadline = styled(Headline3)`
  margin-top: 18px;
`;

const ExposureEditorBody = styled(Body1)`
  margin-bottom: 30px;
`;
