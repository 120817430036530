import React from 'react';
import styled, { css } from 'styled-components';
import { Button, ColorUtils, Flexbox, GetColor, Icon } from 'venn-ui-kit';
import { isEmpty } from 'lodash';

export interface UpsellProps {
  onClose: () => void;
}

export type UpsellFeatureProps = {
  title: string;
  description: string;
};

const StyledIcon = styled(Icon)`
  color: ${ColorUtils.opacifyFrom(GetColor.HighlightDark, 0.2)};
`;

export const UpsellFeature = ({ title, description }: UpsellFeatureProps) => {
  return (
    <FeatureContainer titleOnly={isEmpty(description)}>
      <StyledIcon type="fas fa-circle-check" />
      <Flexbox direction="column">
        <span className="qa-feature-span">{title}</span>
        <UpsellDescriptionSpan>{description}</UpsellDescriptionSpan>
      </Flexbox>
    </FeatureContainer>
  );
};

const FeatureContainer = styled.div<{ titleOnly: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  ${({ titleOnly }) =>
    titleOnly
      ? css`
          border-left: none;
          padding: 4px 0;
          gap: 8px;
        `
      : css`
          border-left: 2px solid ${GetColor.GreyScale.Grey20};
          padding: 8px 8px 8px 16px;
          font-size: 14px;
          gap: 8px;
          font-weight: bold;
        `}
`;

export const UpsellDescriptionSpan = styled.span`
  color: ${GetColor.GreyScale.Grey80};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const UpsellAccessFeatures = styled.span`
  margin-bottom: 8px;
  font-weight: bold;
`;

export const UpsellStyledInput = styled.textarea`
  width: 100%;
  height: 120px;
  padding: 6px;
  font-weight: normal;
  border-radius: 4px;
  :focus {
    border: 1px solid ${ColorUtils.opacifyFrom(GetColor.HighlightDark, 0.5)};
  }
  resize: none; /* Disable resizing */
`;

export const UpsellSecondPageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
`;

export const UpsellMainContainer = styled.div`
  display: flex;
  padding: 40px 40px 24px 40px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: ${GetColor.White};
  gap: 61px;
`;

export const UpsellIconWithMargin = styled(Icon)`
  margin-right: 8px;
`;

export const UpsellBackButton = styled(Button)`
  border-radius: 100px;
  width: 86px;
  padding: 8px 16px;
  opacity: 0.85;
  background-color: ${GetColor.GreyScale.Grey30};
  color: ${GetColor.Black};
  border: 1px solid ${GetColor.GreyScale.Grey30};

  :hover {
    background-color: ${GetColor.GreyScale.Grey30};
    color: ${GetColor.Black};
    border: 1px solid ${GetColor.GreyScale.Grey30};
  }
`;

export const UpsellFurtherContactButton = styled(Button)`
  width: 100%;
  background-color: ${GetColor.HighlightDark};
  color: ${GetColor.White};

  :hover {
    background-color: ${GetColor.HighlightDeepDark};
    color: ${GetColor.White};
  }
`;

export const UpsellSecondPageLeftContainer = styled.div`
  display: flex;
  min-width: 490px;
  flex-direction: column;
  padding: 16px 40px;
`;

export const UpsellContactFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${GetColor.GreyScale.Grey10};
  color: ${GetColor.Black};
  width: 100%;
  padding: 40px 65px 40px 55px;
  gap: 16px;
`;

export const UpsellFooter = styled.div`
  height: 35px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`;

export const UpsellStyledButton = styled(Button)`
  color: ${GetColor.HighlightDark};
  border: 1px solid ${GetColor.HighlightDark};

  :hover {
    background-color: ${ColorUtils.opacifyFrom(GetColor.HighlightDark, 0.05)};
    border: 1px solid ${GetColor.HighlightDark};
    color: ${GetColor.HighlightDark};
  }
`;

export const UpsellStyledUnlockedButton = styled(Button)`
  color: ${GetColor.DarkBlue};
  border: 1px solid ${GetColor.DarkBlue} !important;

  :hover {
    color: ${GetColor.DeepDarkBlue};
    border: 1px solid ${GetColor.DarkBlue};
  }
`;

export const UpsellStyledUnlockedPrimaryButton = styled(Button)`
  color: ${GetColor.White} !important;
  background: ${GetColor.DarkBlue};
  border: none !important;

  :hover {
    background: ${GetColor.DeepDarkBlue} !important;
  }
`;

export const UpsellFooterText = styled.span`
  color: ${GetColor.Black};
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
`;

export const UpsellMainTextContainer = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 16px 0;
`;

export const UpsellTopContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 40px;
`;

export const UpsellLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin-top: 20px;
`;

export const UpsellRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 568px;
  height: 434px;
  margin-top: 20px;
  background: var(--Grey-10, #f5f7fa);
  align-items: center;
  justify-content: center;
`;
