import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { EditableText, GetColor, Icon, Notifications, NotificationType } from 'venn-ui-kit';
import type { Portfolio, PrivatePortfolioNode } from 'venn-api';
import { checkPortfolioNameV3, renamePrivatePortfolio, updatePortfolioName } from 'venn-api';
import { isPortfolioEditable, logExceptionIntoSentry, useApi } from 'venn-utils';
import PortfoliosContext from '../../contexts/portfolios-context';
import { useDebounce } from '../../hooks';
import { checkPrivatePortfolioName } from '../../utils/useValidatePrivatePortfolioName';
import styled from 'styled-components';

interface PortfolioNameProps {
  portfolio: Portfolio | PrivatePortfolioNode;
  onChange?: (name: string) => void;
  disabled?: boolean;
  isPrivate?: boolean;
}

const DEBOUNCE_MS = 200;
const EditablePortfolioName: React.FC<React.PropsWithChildren<PortfolioNameProps>> = ({
  portfolio,
  onChange,
  disabled,
  isPrivate,
}) => {
  const [originalName, setOriginalName] = useState<string>(portfolio.name);
  const [edited, setEdited] = useState<string>(originalName);
  const portfoliosContext = useContext(PortfoliosContext);
  const [error, setError] = useState<string>('');
  const editedAnything = useRef<boolean>(false);
  const checkApi = useApi(isPrivate ? checkPrivatePortfolioName : checkPortfolioNameV3);
  const [debouncedEdited] = useDebounce(edited, DEBOUNCE_MS);

  // Should the portfolio change update the state to reflect the new name.
  useEffect(() => {
    setOriginalName(portfolio.name);
    setEdited(portfolio.name);
    editedAnything.current = false;
  }, [portfolio.name]);

  useEffect(() => {
    const checkName = async () => {
      if (!editedAnything.current) return;
      if (!debouncedEdited) {
        setError((_) => 'Name cannot be empty.');
        return;
      }
      if (debouncedEdited === originalName) {
        setError((_) => '');
        return;
      }
      try {
        const isUsed = (await checkApi(debouncedEdited)).content;
        if (!editedAnything.current) return;
        if (isUsed && debouncedEdited !== originalName) {
          setError((_) => 'This name already exists.');
        } else {
          setError((_) => '');
        }
      } catch (e) {
        logExceptionIntoSentry(e);
      }
    };
    checkName();
  }, [checkApi, debouncedEdited, originalName, portfolio.name]);

  const resetToDefault = useCallback(() => {
    setEdited(originalName);
    setError('');
    editedAnything.current = false;
  }, [setError, setEdited, originalName]);

  const onEditName = useCallback(
    async (portfolioName: string) => {
      if (portfolioName === originalName) {
        return;
      }
      if (error) {
        resetToDefault();
        return;
      }

      const toastId = Notifications.notify('Renaming portfolio...', NotificationType.LOADING);
      try {
        if (isPrivate) {
          await renamePrivatePortfolio((portfolio as PrivatePortfolioNode).id, portfolioName);
        } else {
          await updatePortfolioName((portfolio as Portfolio).id, portfolioName);
        }
        setOriginalName(portfolioName);
        setEdited((_) => edited);
        editedAnything.current = false;
        Notifications.notifyUpdate(toastId, 'Portfolio name updated successfully', NotificationType.SUCCESS);
        onChange?.(portfolioName);
      } catch (e) {
        if (e.name !== 'AbortError') {
          logExceptionIntoSentry(e);
          setEdited(originalName);
          editedAnything.current = false;
        }
        Notifications.notifyUpdate(toastId, 'Failed to update portfolio name', NotificationType.ERROR);
      }
      portfoliosContext.refresh();
    },
    [edited, error, isPrivate, onChange, originalName, portfolio, portfoliosContext, resetToDefault],
  );

  const handleInputChange = useCallback(
    (e) => {
      setEdited(e.target.value);
      editedAnything.current = true;
    },
    [editedAnything],
  );

  return (
    <>
      <EditableText
        onChange={handleInputChange}
        onSubmit={onEditName}
        value={edited}
        error={!!error}
        disabled={(!isPrivate && !isPortfolioEditable(portfolio as Portfolio)) || disabled}
      />
      {error && (
        <ErrorComponent>
          &nbsp; {error}
          <StyledIcon type="rotate-left" />
          <LinkButton onClick={resetToDefault}>Go back</LinkButton>
          or enter another name
        </ErrorComponent>
      )}
    </>
  );
};

export const LinkButton = styled.button`
  color: ${GetColor.Primary.Dark};
  margin-right: 2px;
`;

const StyledIcon = styled(Icon)`
  font-size: 20px;
  color: ${GetColor.Primary.Dark};
`;

const ErrorComponent = styled.h3`
  display: flex;
  flex-direction: row;
  font-size: 22px;
  font-style: normal;
  color: ${GetColor.Error};
  align-items: baseline;
`;

export default EditablePortfolioName;
