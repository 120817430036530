export const SUBJECT_HEADER_RENDERER = 'headerCellRenderer';
export const DUAL_SUBJECT_HEADER_RENDERER = 'headerDualSubjectCellRenderer';
export const METRIC_NAME_HEADER_RENDERER = 'metricNameHeaderRenderer';
export const SUBJECT_ROW_CELL_RENDERER = 'subjectRowCellRenderer';
export const VALUE_CELL_RENDERER = 'valueCellRenderer';
export const ALLOCATION_CELL_RENDERER = 'allocationCellRenderer';
export const MAPPED_PORTFOLIO_NODE_CELL_RENDERER = 'mappedPortfolioNodeCellRenderer';
export const BENCHMARK_KEY = 'Benchmark';
export const AVAILABILITY_KEY = 'Availability';
export const FREQUENCY_KEY = 'Max Frequency';
export const ALLOCATION_KEY = 'allocation';
export const GRID_COLUMN_WIDTH = 160;
export const LABEL_COLUMN_WIDTH = 250;
export const TOTAL_LABEL = 'Total';
export const TOTAL_LABEL_CUMULATIVE = 'Total (Cumulative)';
export const RESIDUAL_LABEL = 'Residual';
export const RISK_FREE_RATE_LABEL = 'Risk-Free Rate';
export const BOLD_CLASS = 'bold';
export const ITALIC_CLASS = 'italic';
export const GREY_BG_CLASS = 'grey_bg';
export const METADATA_GREY_BG_CLASS = 'metadata_grey_bg';
export const LEFT_ALIGN_CLASS = 'left_align';
export const JUSTIFY_HEADER_END_CLASS = 'justify_end';
export const DARK_COLUMN_CLASS = 'dark_column';
export const RIGHT_ALIGN_CLASS = 'right_align';
export const ROW_HAS_ERROR_CLASS = 'row_has_error';
export const PERCENTAGE_CLASS = 'PERCENTAGE';
export const PROXY_CELL_CLASS = 'proxy';
export const OUT_OF_RANGE_CELL_CLASS = 'out_of_range';
export const FACTOR_CONTRIBUTION_ANALYSIS_TYPES = ['FUND_FACTOR_CONTRIBUTION'];
export const WATERMARK_POSITION_TOP = 143;
export const CURRENCY_CLASS_PREFIX = 'CURRENCY:';
