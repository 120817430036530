import type { CurrencyEnum, UserProfileSettings } from 'venn-api';

const profile: UserProfileSettings = {
  actualUser: true,
  availableContexts: [
    {
      value: {
        context: {
          global: true,
          name: 'Global',
          id: 'globalId',
          workspaceId: 105,
        },
        actions: [],
        actionEnums: [],
      },
      children: [],
    },
    {
      value: {
        context: {
          global: false,
          name: 'Office A',
          id: 'id1',
          workspaceId: 105,
        },
        actions: ['create_portfolio'],
        actionEnums: ['CREATE_PORTFOLIO'],
      },
      children: [
        {
          value: {
            context: {
              global: false,
              name: 'Client 1',
              id: 'id2',
              workspaceId: 105,
            },
            actions: ['create_portfolio', 'edit_portfolio'],
            actionEnums: ['CREATE_PORTFOLIO', 'EDIT_PORTFOLIO'],
          },
          children: [],
        },
        {
          value: {
            context: {
              global: false,
              name: 'Client 2',
              id: 'id3',
              workspaceId: 105,
            },
            actions: ['create_portfolio', 'edit_portfolio'],
            actionEnums: ['CREATE_PORTFOLIO', 'EDIT_PORTFOLIO'],
          },
          children: [],
        },
      ],
    },
  ],
  user: {
    id: 3944,
    name: 'two sigma test demo Admin',
    displayName: 'two sigma test demo Admin',
    email: 'test+testfree@twosigma.com',
    actualUser: false,
    active: true,
    created: 1558629815645,
    pwdLastUpdated: 1558629818657,
    avatarId: undefined!,
    accountId: 1,
  },
  sponsor: undefined!,
  firstName: 'two sigma test demo',
  lastName: 'Admin',
  displayName: 'two sigma test demo Admin',
  organization: {
    id: 105,
    accountId: 105,
    accountName: 'two sigma test demo',
    name: 'two sigma test demo',
    created: 1539632637740,
    currency: 'USD' as CurrencyEnum,
    language: 'EN',
    country: 'US',
    accountTier: 'BASIC',
    description: undefined!,
    type: undefined!,
    members: 0,
    member: undefined!,
    open: false,
    mfaRequired: false,
    organizationType: 'SWF',
    workspaceName: 'two sigma test demo',
    aum: { id: '1_10', name: 'US$ 1 - 10B', approximateAUM: 10.0 },
    locality: 'California',
    accountAdministrator: 'test+mon6@twosigma.com',
    avatarId: undefined!,
    accountType: 'INSTITUTION',
    status: 'ACTIVE',
    jurisdiction: {
      code: 'US',
      gdprApplied: false,
      iso2Code: 'US',
      iso3Code: 'USA',
      displayName: 'United States of America',
      defaultCurrency: 'USD' as CurrencyEnum,
      defaultLanguage: 'EN',
      supportedJurisdiction: true,
      restrictedAccess: false,
    },
    issuesPublicEquity: false,
    trial: { defaultLengthDays: 30, status: 'AVAILABLE', activeTrial: undefined! },
    workspaceId: 105,
  },
  position: undefined!,
  bio: undefined!,
  phoneNumber: undefined!,
  intercomHash: 'eafe415cc152ec08e4a25c53327c0673ee2335a57c2918bee290d3c352392aaa',
  mfaEnabled: false,
  status: 'ACTIVE',
  expertiseTags: undefined!,
  expertise: undefined!,
  country: undefined!,
  state: undefined!,
  locale: undefined!,
  role: undefined!,
  publicProfile: undefined!,
  language: undefined!,
  timezone: undefined!,
  mfaStatus: {
    enabled: false,
    primaryType: 'DISABLED',
    phoneNumber: undefined!,
    verifiedPhone: false,
    verifiedTotp: false,
  },
  notificationSettings: {},
};

export default profile;
