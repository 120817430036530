import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Headline2, Loading, Subtitle1, Link as LinkText, GetColor, Icon, FACTOR_ALERTS_FAQ_HREF } from 'venn-ui-kit';
import { EmptyState } from 'venn-components';
import type { RecentPortfolioFactorHistory } from 'venn-api';
import { getRecentFactorPerformance } from 'venn-api';
import FactorSummaryTable from './FactorSummaryTable';

interface FactorPerformanceSummaryProps {
  id?: string;
  viewFactor: (factorId: number) => void;
}

const renderLoading = () => {
  return <Loading />;
};

const renderError = () => <EmptyState header="Recent Factor Activity is not available" message="" />;

const FactorPerformanceSummary = ({ id, viewFactor }: FactorPerformanceSummaryProps) => {
  const isMount = useRef<boolean>(false);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>('');
  const [factorHistory, setFactorHistory] = useState<RecentPortfolioFactorHistory | undefined>(undefined);

  useEffect(() => {
    isMount.current = true;
    const fetchFactorHistory = async () => {
      setLoading(true);
      try {
        const response = await getRecentFactorPerformance();
        if (response && response.content) {
          isMount.current && setFactorHistory(response.content);
        } else {
          isMount.current && setError('');
        }
      } catch (e) {
        const err = await e;
        const errorMessage = err?.content?.message || 'Failed to fetch recent factor performance';
        isMount.current && setError(errorMessage);
      }
      isMount.current && setLoading(false);
    };
    fetchFactorHistory();
    return () => {
      isMount.current = false;
    };
  }, []);

  const renderContent = () => {
    if (error) {
      return renderError();
    }

    if (loading || !factorHistory) {
      return renderLoading();
    }

    return <FactorSummaryTable factorHistory={factorHistory} viewFactor={viewFactor} />;
  };

  return (
    <>
      <HeaderContainer id={id}>
        <Header>
          <FactorTitle>Recent Factor Activity</FactorTitle>
        </Header>
        <StyledSubtitle1>
          Performance of factors over time and alerts for their movements. Visit the{' '}
          <LinkText>
            <a target="_blank" rel="noopener noreferrer" href={FACTOR_ALERTS_FAQ_HREF}>
              FAQ
            </a>
          </LinkText>{' '}
          to learn more.
        </StyledSubtitle1>
      </HeaderContainer>
      {renderContent()}
      <StyledSubtitle1>
        As of the last available factor date, highlighted factors have experienced a notable movement over the last
        week.{' '}
        <a href={FACTOR_ALERTS_FAQ_HREF} target="_blank" rel="noopener noreferrer">
          <StyledIcon type="info-circle" />
        </a>
      </StyledSubtitle1>
    </>
  );
};

export default FactorPerformanceSummary;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderContainer = styled.div`
  margin-bottom: 20px;
`;

const FactorTitle = styled(Headline2)`
  margin: 30px 20px 0;
`;

const StyledIcon = styled(Icon)`
  color: ${GetColor.Black};
`;

const StyledSubtitle1 = styled(Subtitle1)`
  padding-left: 20px;
`;
