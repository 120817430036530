import React from 'react';
import styled from 'styled-components';
import Background from './Background';
import { ButtonIcon, getAppTitle, GetColor, getTextThemeProvider, Headline1, Headline2, Headline3 } from 'venn-ui-kit';
import ServiceTiersTable from './ServiceTiersTable';

const HeaderRow = () => {
  const tiers = getTextThemeProvider().legalDocuments.SERVICE_TIERS_LINK;
  return (
    <HeaderRowContainer>
      <TextWrapper>
        <StyledHeadline1>
          <PurpleHighlight>Specialist</PurpleHighlight> Service Levels
        </StyledHeadline1>
        <Headline2>Tailored to your organization's needs.</Headline2>
      </TextWrapper>
      <a href={tiers} target="_blank" rel="noopener noreferrer">
        <StyledButton
          iconType="download"
          border
          iconFontSize={11}
          text="Download PDF"
          textFontSize={10}
          textTransform="none"
        />
      </a>
    </HeaderRowContainer>
  );
};

const FooterRow = () => {
  return (
    <FooterRowContainer>
      <StyledHeadline3>Questions?</StyledHeadline3>
      <MsgContainer>
        Contact <TealHighlight>{getTextThemeProvider().salesEmail}</TealHighlight>
        {`to learn more about how ${getAppTitle()}'s coverage tiers can level up your organization’s financial insights.`}
      </MsgContainer>
    </FooterRowContainer>
  );
};
const ServiceTiersPage = () => {
  return (
    <>
      <Content>
        <HeaderRow />
        <ServiceTiersTable />
        <FooterRow />
      </Content>
      <Background />
    </>
  );
};

const Content = styled.div`
  height: 100%;
  width: 1408px;
  margin-left: 102px;
`;

const StyledButton = styled(ButtonIcon)`
  width: 128px;
  height: 40px;
  font-weight: normal;
`;

const MsgContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 14px;
  color: ${GetColor.DarkGrey};
  gap: 4px;
`;
const TextWrapper = styled.div`
  width: 1200px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 24px;
`;

const PurpleHighlight = styled.span`
  font-style: italic;
  color: ${GetColor.HighlightDark};
`;
const TealHighlight = styled.span`
  color: ${GetColor.Primary.Main};
  font-weight: bold;
`;

const StyledHeadline1 = styled(Headline1)`
  font-weight: bold;
`;
const StyledHeadline3 = styled(Headline3)`
  font-style: italic;
`;

const HeaderRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 76px 0px 36px 0px;
`;

const FooterRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 68px;
  padding: 0px 158px 0px 158px;
  margin: 0px 80px 0px 40px;
  border-left: 1px solid ${GetColor.PaleGrey};
  border-bottom: 1px solid ${GetColor.PaleGrey};
  border-right: 1px solid ${GetColor.PaleGrey};
  background-color: ${GetColor.White};
`;

export default React.memo(ServiceTiersPage);
