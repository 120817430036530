import type { ColDef, ColGroupDef, ValueGetterFunc } from 'ag-grid-community';
import type { RefObject } from 'react';
import type {
  Analysis,
  AnalysisViewTypeEnum,
  CustomBenchmarkTypeEnum,
  CustomizedBlock,
  FrequencyEnum,
  HoldingsCategoryId,
  Scenario,
  ScenarioAnalysis,
} from 'venn-api';
import type {
  AnalysesResult,
  AnalysisResult,
  BlockId,
  GridStyle,
  StudioAnalysisRequest,
  StudioRequestSubject,
} from 'venn-state';
import type { Theme } from 'venn-ui-kit';

import type {
  AvailableRange,
  CustomBlockTypeEnum,
  CustomizableBlockSetting,
  CustomizableMetric,
  ExcelCell,
  TabularDataTypeEnum,
} from 'venn-utils';
import type { ReturnValue } from './components/grid/types';

export type AnalysisFetchingFunction = (req: AnalysisRequest) => Promise<AnalysisResult>;
export type AnalysesFetchingFunction = (requests: AnalysisRequest[]) => Promise<AnalysesResult>;
export type AnalysisRequest = StudioAnalysisRequest;

export const isPairwiseAnalysisQuery = (blockSetting: CustomizableBlockSetting): boolean =>
  blockSetting.customBlockType === 'CORRELATION';

export type DataValue = number | string | undefined | null;

export type ValueMapper = { [key: string]: DataValue };
export type ScatterSeries = (
  | { name: string; type: 'scatter'; data: [number, number][] }
  | { name: string; type: 'scatter3d'; data: [number, number, number][] }
)[];

export type HeaderComponentParamsType = {
  subject?: StudioRequestSubject;
  color?: string;
  isCommonBenchmark?: boolean;
  relativeTo?: StudioRequestSubject;
  noLink?: boolean;
};

export interface CustomRowData {
  key?: string;
  isMetadata?: boolean;
  /** Benchmark row using the map to get range from name key */
  benchmarkNameRangeMap?: { [key in string]: AvailableRange };
  customBenchmarkType?: CustomBenchmarkTypeEnum;
  headerComponentParams?: HeaderComponentParamsType;
}

export interface HoldingsRowData extends CustomRowData {
  id: HoldingsCategoryId;
  allocation: number;
}

export interface ReturnsGridRow extends CustomRowData {
  kind: 'ReturnsGridRow';
  label: string;
  value: (ReturnValue | null)[];
  type: TabularDataTypeEnum;
  total?: ReturnValue;
}

export interface HoldingsTreeRow extends CustomRowData {
  label: string;
  path: string[];
  value: number[];
  type: TabularDataTypeEnum;
  allocation?: number;
  isParent?: boolean;
  isLeaf?: boolean;
}

export interface MetricRowData extends CustomRowData {
  label: string;
  value: DataValue[];
  type?: TabularDataTypeEnum;
}

export interface ScenarioRowData extends CustomRowData {
  label: string;
  scenarioShockXLS: ExcelCell;
  scenarioAnalysis: ScenarioAnalysis[];
  scenario: Scenario;
  relative: boolean;
  hasError: boolean;
}

export interface FactorRowData extends CustomRowData {
  label: string;
  value: ValueMapper[];
}

export interface PortfolioRowData<T> extends CustomRowData {
  allocation: DataValue[];
  value: T[];
  path: string[];
  isStrategy: boolean;
  label: string;
  fundId?: string;
}

/**  Time Series row data */
export interface TsRowData extends CustomRowData {
  date: number;
  value: ValueMapper[];
}

/**  Used for tables where there are multiple columns that come before metric columns of specific subjects  I.e. for Notable Periods */
export interface MultiGlobalColumnsRowData extends CustomRowData {
  label: string;
  globalValue: ValueMapper;
  value: ValueMapper[];
}

export interface PortfolioBreakdownRowData extends CustomRowData {
  weight: number;
}

export interface ColumnData extends CustomRowData {
  xAxisCategories?: string[]; // Expected to be of the same `length` as `seriesData`
  yAxisLabel?: string; // Option for column chart; should be the same across all subjects
  yAxisTitle?: string; // Option for column chart; should be the same across all subjects
  percent?: boolean; // Option for column chart to display value in %
  seriesData: (number | null)[];
  exportable: boolean;
}

export interface ScenarioColumnData extends ColumnData {
  xAxisCategories?: string[] /** Expected to be of the same `length` as `seriesData` */;
  yAxisLabel?: string /** Option for column chart; should be the same across all subjects */;
  yAxisTitle?: string /** Option for column chart; should be the same across all subjects */;
  percent?: boolean /** Option for column chart to display value in % */;
  seriesData: (number | null)[];
  exportable: boolean;
  scenarios?: Scenario[];
  scenarioAnalyses?: ScenarioAnalysis[];
}

export type CustomRow<T = unknown> =
  | ReturnsGridRow
  | MetricRowData
  | FactorRowData
  | PortfolioRowData<T>
  | TsRowData
  | MultiGlobalColumnsRowData
  | ColumnData
  | ScenarioColumnData
  | ScenarioRowData
  | HoldingsRowData;

export type ColumnParserProps = Pick<
  AnalysisGridProps,
  'theme' | 'customBlockType' | 'analysesGroup' | 'requests' | 'selectedBlock'
> &
  Pick<AnalysisResponseParser, 'metricsSettings'> & { gridStyle: GridStyle };

export type ColumnParser<T = unknown> = (columnParserProps: ColumnParserProps) => (ColDef<T> | ColGroupDef<T>)[];

export interface HoldingsColumnParserProps<TData> {
  selectedRefId: BlockId;
  /** The index of the column is accessible using Number(colDef.field). */
  valueGetter: ValueGetterFunc<TData>;
  isTree?: boolean;
}

export interface AnalysisResponseParser {
  parser: (selectedBlock: CustomizedBlock, analyses?: (Analysis | undefined)[][]) => CustomRow[];

  /**
   * Parser to return a set of partial column definitions to be used alongside the parser's row definitions.
   *
   * TODO(collin.irwin): newer version of ag grid allows the typing of ColDef to include the data type,
   * which would drastically improve how easy it is to work with their code and our code safely. For example, we
   * could make sure that the columnParser is processing the data returned by the parser, and when writing the column parser,
   * you'd know what data you have to work with. It would also make it easier to mix and match parsers without breaking things.
   *
   * TODO: convert this to live within react/recoil so that we don't need to prop drill so many props
   */
  columnParser: ColumnParser;

  excelParser?: (
    requests: AnalysisRequest[],
    customBlockType: CustomBlockTypeEnum | undefined,
    metrics: CustomizableMetric[] | undefined,
    customRows: CustomRow[],
  ) => ExcelCell[][];

  isTree?: boolean;
  hasFactors?: boolean;
  // Used in group grid (one column per metric, all repeated per-subject)
  metricsSettings?: CustomizableMetric[];
}

export interface CustomBlockProps {
  isPrinting?: boolean;
  downloadableContentRef?: RefObject<HTMLDivElement>;
  /** @deprecated use isReportState recoil state */
  isReport?: boolean;
  /** @deprecated use useBlockId hook */
  selectedRefId: string;
}

export interface ExportMetaData {
  customBlockType: CustomBlockTypeEnum;
  startDate?: number;
  endDate?: number;
  frequency?: FrequencyEnum;
  // Projection start date for private blocks (e.g. cashflow pacing)
  projectionStartDate?: number;
  // Simulation start date for public/private asset growth simulation
  simulationStartDate?: number;
  relativeToBenchmark?: boolean;
  benchmarkType?: CustomBenchmarkTypeEnum;
  contributionToPercentage?: boolean;
  rollingPeriod?: string;
  peerGroupName?: string;
  hasVennDataModifications?: boolean;
}

export interface ExportInfo {
  inPrintMode?: boolean;
  selectedRefId: string;
  exportMetaData?: ExportMetaData;
}

export interface AnalysisGridProps extends ExportInfo {
  requests: AnalysisRequest[];
  responseParser: AnalysisResponseParser;
  theme: Theme;
  analysesGroup: (Analysis | undefined)[][];
  selectedBlock: CustomizedBlock;
  selectedRefId: string;
  pivoted?: boolean;
  analysisViewType?: AnalysisViewTypeEnum;
  customBlockType: CustomBlockTypeEnum;
  selectedMetricIds: string[];
  isCompact: boolean;
}

export interface HoldingsDataProps extends ExportInfo {
  selectedRefId: string;
  isBarChart: boolean;
}

export interface CreateLabelColumnProps {
  requests?: AnalysisRequest[];
}

export type RowToPivot = MetricRowData | FactorRowData;

export type PivotedRow = Omit<RowToPivot, 'value'> & {
  value: (DataValue | ValueMapper)[];
};

export const STUDIO_ANALYSIS_CACHE_KEY = 'analysis_cached';
