import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import { Body1, BodyEmphasis, GetColor, Headline3, Icon } from 'venn-ui-kit';

type Level = 'error' | 'warning' | 'ok';

export interface BannerMessageProps {
  title?: string;
  errorType: Level;
  small?: boolean;
  backgroundColor?: string;
  iconType?: string;
}

interface StyleProps {
  errorType: Level;
  small: boolean;
  backgroundColor?: string;
}

interface TitleProps {
  title?: string;
}

export const BannerMessage: FC<React.PropsWithChildren<BannerMessageProps>> = ({
  title,
  children,
  backgroundColor,
  errorType,
  iconType,
  small,
}) => {
  const isInverted = errorType !== 'warning';
  return (
    <StyledContainer errorType={errorType} small={!!small} backgroundColor={backgroundColor}>
      <Body1 inverted={isInverted}>
        <StyledIcon
          title={title}
          prefix="fas"
          type={iconType ?? (errorType !== 'ok' ? 'exclamation-circle' : 'check-circle')}
        />
        {title && <StyledTitle inverted={isInverted}>{title}</StyledTitle>}
        <StyledSubtitle title={title} inverted={isInverted}>
          {children}
        </StyledSubtitle>
      </Body1>
    </StyledContainer>
  );
};

export default BannerMessage;

const StyledIcon = styled(Icon)<TitleProps>`
  margin-right: 10px;
  font-size: ${(props) => (props.title ? '20px' : '14px')};
`;

const StyledContainer = styled.div<StyleProps>`
  background-color: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor
      : props.errorType === 'warning'
        ? GetColor.Alert
        : props.errorType === 'error'
          ? GetColor.Error
          : GetColor.DEPRECATED_DataLineColor.Green};
  min-height: ${(props) => (props.small ? '52px' : '60px')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  width: 100%;
`;

const StyledTitle = styled(Headline3)`
  display: inline-block;
  padding-right: 45px;
`;

const StyledSubtitle = styled(BodyEmphasis)<TitleProps>`
  display: ${(props) => (props.title ? 'inline-block' : 'inline')};
  font-style: normal;
`;
