import React, { useContext, useMemo } from 'react';
import styled, { ThemeContext } from 'styled-components';
import moment from 'moment';
import type { DrawdownRangeAnalysis, PortfolioCompare } from 'venn-api';
import type { Theme } from 'venn-ui-kit';
import { GetColor, ColorUtils } from 'venn-ui-kit';
import DrawdownChart from './charts/DrawdownChart';
import type { AnalysisSubject } from 'venn-utils';
import { Dates, getSecondaryDisplayLabel } from 'venn-utils';
import type { LegendSeries } from '../../../../legend/Legend';
import { Legend } from '../../../../legend/Legend';
import { DownloadableContentBlock } from '../../../../content-block';
import { getExcelData } from './utils';
import { getLineColors } from '../../internal/utils';
import type { DownloadMetaData } from '../../../../downloadable';

export interface WhatIfProps {
  subject: AnalysisSubject;
  data: DrawdownRangeAnalysis;
  print?: boolean;
  downloadMetaData?: DownloadMetaData;
}

const qaClass = 'qa-what-if-chart';

const WhatIf = ({ subject, data, print, downloadMetaData }: WhatIfProps) => {
  const { portfolioDrawdown } = data;
  const start = portfolioDrawdown[0][0];
  const end = portfolioDrawdown[portfolioDrawdown.length - 1][0];
  const compare = subject.activeBenchmark;
  const secondaryPortfolioName = subject?.secondaryPortfolio?.name;
  const excel = useMemo(() => getExcelData(subject, data), [subject, data]);
  const theme = useContext(ThemeContext);

  return (
    <DrawdownContainer className={qaClass}>
      <DownloadableContentBlock
        header="What-if Drawdown Chart"
        subHeader={`If the selected period happened today, what would the drawdown for ${subject.name} look like?`}
        downloadable={{
          png: true,
          disabled: !portfolioDrawdown,
          tracking: {
            description: 'FACTOR_DRAWDOWN_PERIOD',
            relativeToBenchmark: false,
            subjectType: subject.type,
            subjectId: subject.id,
            userUploaded: subject.userUploaded,
          },
          options: {
            fileName: `What-if Drawdown Chart for ${subject.name}`,
            metaData: downloadMetaData,
          },
          excel,
        }}
      >
        <SelectedPeriod>
          Selected period:
          <DateSpan>
            {moment.utc(start).format(Dates.DRAWDOWN_DATE_FORMAT)}
            &ndash; {moment.utc(end).format(Dates.DRAWDOWN_DATE_FORMAT)}
          </DateSpan>
        </SelectedPeriod>
        <StyledLegend series={getLegendData(subject, data, theme, compare, secondaryPortfolioName)} />
        <DrawdownChart subject={subject} data={data} start={start} end={end} print={print} />
      </DownloadableContentBlock>
    </DrawdownContainer>
  );
};
export default WhatIf;

function getLegendData(
  subject: AnalysisSubject,
  data: DrawdownRangeAnalysis,
  theme: Theme,
  compare?: PortfolioCompare,
  secondaryPortfolioName?: string,
): LegendSeries[] {
  let colorIdx = 0;
  const lineColors = getLineColors(theme);
  const series: LegendSeries[] = [
    {
      name: subject.name,
      color: lineColors[colorIdx],
      borderColor: ColorUtils.hex2rgba(lineColors[colorIdx], 0.4),
      fillColor: ColorUtils.hex2rgba(lineColors[colorIdx++], 0.2),
    },
  ];
  if (subject.type === 'portfolio' && data.secondaryPortfolioDrawdown) {
    series.push({
      name: `${secondaryPortfolioName} (${getSecondaryDisplayLabel(subject, 'as of')})`,
      color: lineColors[colorIdx++],
    });
  }
  if (data.benchmarkDrawdown && compare) {
    series.push({
      name: compare.name,
      color: lineColors[colorIdx++],
    });
  }
  if (subject.type === 'investment' && data.secondaryPortfolioDrawdown) {
    series.push({
      name: `Category (${subject?.categoryGroup?.name})`,
      color: lineColors[colorIdx++],
    });
  }
  return series;
}

const DrawdownContainer = styled.div`
  margin-bottom: 20px;

  @media print {
    page-break-inside: avoid;
  }

  .highcharts-tooltip {
    span {
      font-family: ${(props) => props.theme.Typography.fontFamily} !important;
      font-size: 12px !important;
    }
  }

  .drawdown-tooltip-values {
    display: flex;
    flex-direction: row;

    > div + div {
      margin-left: 5px;
    }

    &-left,
    &-middle {
      display: flex;
      flex-direction: column;
    }

    &-left {
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-middle {
      text-align: right;
    }
  }
`;

const StyledLegend = styled(Legend)`
  padding: 0 20px;
  height: 50px;
  border-bottom: 1px solid ${GetColor.Grey};

  > div {
    margin: 0 20px 0 0;
  }
`;

const SelectedPeriod = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 20px;
  color: ${GetColor.Primary.Dark};
  font-weight: bold;
  border-top: 1px solid ${GetColor.Grey};
  border-bottom: 1px solid ${GetColor.Grey};
  font-size: 12px;
  @media print {
    display: none;
  }
`;

const DateSpan = styled.span`
  padding-left: 5px;
  color: ${GetColor.Primary.Main};
  font-weight: normal;
  font-size: 14px;
`;
