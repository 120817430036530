import type { AnalysisView, AnalysisViewSubject, AnalysisViewTypeEnum } from 'venn-api';
import { getRandomId } from './getRandomId';
import { PageType } from './types';
import type { AnyDuringEslintMigration } from '../type';
import { compact, isEqual, uniqWith } from 'lodash';
import { MAX_SUBJECT_GROUP_SIZE } from './constants';
import type { Subject } from 'venn-state';
import { assertNotNil } from '../utils';

const getSubjects = (subjectIds: Subject[]): AnalysisViewSubject[] =>
  compact(
    uniqWith(subjectIds, isEqual)
      .slice(0, MAX_SUBJECT_GROUP_SIZE)
      .map((subject) => {
        if (!subject.fundId && !subject.portfolioId && !subject.privateFundId && !subject.privatePortfolioId) {
          return null;
        }
        const itemType = subject.portfolioId || subject.privatePortfolioId ? 'PORTFOLIO' : 'INVESTMENT';
        const isPrivate = !!subject.privatePortfolioId || !!subject.privateFundId;
        const subjectId =
          itemType === 'PORTFOLIO'
            ? assertNotNil(subject.privatePortfolioId ?? subject.portfolioId).toString()
            : assertNotNil(subject.privateFundId ?? subject.fundId);
        return {
          comparisonType: 'COMPARISON',
          subjectType: itemType,
          id: subjectId,
          isPrivate,
        } as AnalysisViewSubject;
      }),
  );
export const getBlankStudio = (
  reportType: AnalysisViewTypeEnum,
  addDisclosurePage: boolean,
  subjectIds: Subject[],
  ownerContextId?: string,
): AnalysisView => ({
  version: 2,
  created: 0,
  customAnalysisParams: undefined as AnyDuringEslintMigration,
  customTemplateId: null as AnyDuringEslintMigration,
  owner: undefined as AnyDuringEslintMigration,
  published: false,
  template: false,
  templateDescription: '',
  vennTemplate: false,
  width: null as AnyDuringEslintMigration,
  updated: 0,
  refId: getRandomId(),
  analysisViewType: reportType,
  systemTemplate: 'custom',
  subjects: getSubjects(subjectIds),
  customizedViews: [],
  printOrientationType: 'PORTRAIT',
  ownerContextId,
  customViewOptions: {
    pages: compact([
      {
        layout: [],
        type: PageType.GRID,
      },
      addDisclosurePage && {
        layout: [],
        type: PageType.DISCLOSURE,
      },
    ]),
  },
  subjectGroups: [
    {
      id: getRandomId(),
      label: 'Default Subject Group',
      subjects: getSubjects(subjectIds),
    },
  ],
  dateRanges: [
    {
      id: getRandomId(),
      label: 'Default Date Range',
      period: { periodToDate: 'full' },
      resolution: 'GLOBAL',
    },
  ],
  benchmarkSettings: [
    {
      id: getRandomId(),
      label: 'Default Benchmark Setting',
      type: 'NONE',
      subject: undefined,
      relative: false,
    },
  ],
});
