import React, { useMemo } from 'react';
import type { CellClassParams } from '../../../data-grid';
import useAnalysisGrid from '../../logic/useAnalysisGrid';
import type { AnalysisGridProps } from '../../types';
import { LEFT_ALIGN_CLASS, METADATA_GREY_BG_CLASS, ROW_HAS_ERROR_CLASS } from '../../customAnalysisContants';
import { DATA_TYPE_EXCEL_STYLES } from '../../logic/customBlockUtils';
import ExportableGrid from './ExportableGrid';
import { pivotGridData } from '../../logic/pivotGridData';
import { isReadyToPivotData } from '../../logic/typeUtils';
import { useCommonGridProps } from '../../logic/columnUtils';
import { useIsBlockGridCompact } from '../../logic/useIsBlockGridCompact';
import type { ColDef } from 'ag-grid-community';

const getMetadataGreyBgClass = ({ data, colDef }: CellClassParams) =>
  data?.isMetadata || colDef.cellRendererParams?.isMetadata;

const getRowHasErrorClass = ({ data }: CellClassParams) => data?.hasError;

const getLeftAlignClass = (params: CellClassParams) => typeof params.value === 'string';

export const analysisGridDefaultColDefOverrides: ColDef = {
  cellClassRules: {
    [METADATA_GREY_BG_CLASS]: getMetadataGreyBgClass,
    [LEFT_ALIGN_CLASS]: getLeftAlignClass,
    [ROW_HAS_ERROR_CLASS]: getRowHasErrorClass,
  },
};

const AnalysisGrid = ({
  requests,
  responseParser,
  theme,
  analysesGroup,
  selectedBlock,
  customBlockType,
  pivoted = false,
  ...exportProps
}: AnalysisGridProps) => {
  const analysisGridData = useAnalysisGrid(
    requests,
    responseParser,
    theme.Colors,
    analysesGroup,
    selectedBlock,
    customBlockType,
  );

  const { rowData, columnDefs } = useMemo(
    () =>
      pivoted && isReadyToPivotData(analysisGridData.rowData)
        ? pivotGridData(analysisGridData.rowData, analysisGridData.columnDefs)
        : { rowData: analysisGridData.rowData, columnDefs: analysisGridData.columnDefs },
    [analysisGridData.columnDefs, analysisGridData.rowData, pivoted],
  );

  const sharedProps = useCommonGridProps(analysisGridDefaultColDefOverrides);
  const isCompact = useIsBlockGridCompact();

  return (
    <ExportableGrid
      {...sharedProps}
      {...exportProps}
      exportable
      rowData={rowData}
      columnDefs={columnDefs}
      excelStyles={DATA_TYPE_EXCEL_STYLES}
      isCompact={isCompact}
    />
  );
};

export default AnalysisGrid;
