import type { SearchMenuItem } from '../../../search-menu';
import type { BenchmarkAction } from './benchmarkActions';
import { BenchmarkUIView } from './benchmarkActions';

export default function reduceSearch(option: SearchMenuItem | null, action: BenchmarkAction): SearchMenuItem | null {
  switch (action.type) {
    case 'BenchmarkSelectSearchResult':
      return action.payload;
    case 'BenchmarkUINavigation':
      if (action.payload.target.kind === 'View' && action.payload.target.viewId === BenchmarkUIView.ListView) {
        return null;
      }
      return option;
    case 'BenchmarkAddFromSearch':
      return null;
    default:
      return option;
  }
}
