import React from 'react';
import styled, { css } from 'styled-components';
import { ColorUtils, GetColor, Icon } from 'venn-ui-kit';

interface StudioTileProps {
  small?: boolean;
  onClick?: () => void;
  type: string;
  disabled?: boolean;
  header?: boolean;
  prefix?: string;
  tooltip?: string;
  selected?: boolean;
  bold?: boolean;
}

const StudioTile = ({ small, onClick, type, disabled, header, prefix, tooltip, selected, bold }: StudioTileProps) => {
  return (
    <IconWrapper
      as={onClick ? 'button' : undefined}
      title={tooltip}
      onClick={onClick}
      prefix={prefix}
      small={small}
      disabled={disabled}
      header={header}
      clickable={!!onClick}
      selected={selected}
      bold={bold}
    >
      <Icon type={type} />
    </IconWrapper>
  );
};

export default StudioTile;

const IconWrapper = styled.div<{
  small?: boolean;
  disabled?: boolean;
  clickable?: boolean;
  header?: boolean;
  selected?: boolean;
  bold?: boolean;
}>`
  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
    `}
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ small }) => (small ? 32 : 36)}px;
  height: ${({ small }) => (small ? 32 : 36)}px;

  font-size: ${({ small }) => (small ? 14 : 18)}px;
  color: ${({ header }) => (header ? GetColor.White : GetColor.Primary.Dark)};
  :hover {
    // In order to override the default button hover styles
    color: ${({ header }) => (header ? GetColor.White : GetColor.Primary.Dark)};
  }
  background-color: ${({ header }) => (header ? GetColor.GreyScale.Grey30 : GetColor.White)};
  border: 1px solid ${({ header }) => (header ? GetColor.White : GetColor.GreyScale.Grey30)};
  border-radius: 6px;

  transition: all 0.2s ease-out;
  ${({ bold }) =>
    !bold &&
    css`
      > i {
        font-weight: normal;
      }
    `}

  ${({ disabled, clickable, header, selected }) => css`
    ${disabled &&
    css`
      cursor: not-allowed;
    `}
    ${disabled &&
    header &&
    css`
      color: ${GetColor.MidGrey2};
    `}
    ${disabled &&
    !header &&
    css`
      background-color: ${GetColor.GreyScale.Grey10};
    `}
    ${!disabled &&
    clickable &&
    !selected &&
    css`
      :hover {
        background-color: ${header ? GetColor.GreyScale.Grey30 : ColorUtils.opacifyFrom(GetColor.Primary.Dark, 0.1)};
      }
    `}
    ${selected &&
    !header &&
    css`
      background-color: ${GetColor.GreyScale.Grey10};
      cursor: default;
    `}
  `};
`;
