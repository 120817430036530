import { atomFamily, atom, selector, waitForAll, selectorFamily } from 'recoil';
import type { BlockId, SubjectWithOptionalFee } from '../../types';
import {
  validateSetEffect,
  noDuplicatesValidatorEffect,
  truthyKeyValidatorEffect,
  maxLengthValidatorEffect,
} from '../../../effects/validateSetEffect';
import type { Nominal } from 'venn-utils';
import { MAX_INPUTS_PER_KIND, MAX_BLOCK_SUBJECT_INPUTS, MAX_SUBJECT_GROUP_SIZE, getRandomId } from 'venn-utils';
import { getNextInputName } from './getNextInputName';
import { resetOnStudioReset } from '../../../effects/signalEffects';

export type SubjectInputId = Nominal<string, 'SubjectInputId'>;
export const getNewSubjectInputId = getRandomId as () => SubjectInputId;

export const nextSubjectInputNameState = selector<string>({
  key: 'nextSubjectInputName',
  get: ({ get }) => {
    const allIds = get(subjectInputGroups);
    if (allIds.length === 0) {
      return 'Default Subject Group';
    }
    const allNames = get(waitForAll(allIds.map((id) => subjectInputGroupName(id))));
    return getNextInputName('Untitled Subject Group ', allNames);
  },
  cachePolicy_UNSTABLE: {
    // Only the most recent is used; don't need to cache all historical name states created during this session.
    eviction: 'most-recent',
  },
});

/**
 * The user defined name for a subject input group.
 */
export const subjectInputGroupName = atomFamily<string, SubjectInputId>({
  key: 'subjectInputGroupName',
  effects: (key) => [truthyKeyValidatorEffect(key)],
});

/**
 * Atom storing the individual subjects (by ID) that are members of a subject input group.
 */
export const subjectInputGroupSubjects = atomFamily<SubjectWithOptionalFee[], SubjectInputId>({
  key: 'subjectInputGroupSubjectsState',
  default: [],
  effects: (key) => [
    validateSetEffect((newValue) =>
      newValue.length > MAX_SUBJECT_GROUP_SIZE ? 'too many subjects in a subject input group' : '',
    ),
    noDuplicatesValidatorEffect,
    truthyKeyValidatorEffect(key),
  ],
});

export const multiSubjectInputGroupSubjects = selectorFamily<
  Record<SubjectInputId, SubjectWithOptionalFee[]>,
  SubjectInputId[]
>({
  key: 'multiSubjectInputGroupSubjects',
  get:
    (groupIds) =>
    ({ get }) =>
      Object.fromEntries(groupIds.map((groupId) => [groupId, get(subjectInputGroupSubjects(groupId))])),
  set:
    (groupIds) =>
    ({ set }, value) =>
      groupIds.forEach((groupId) => set(subjectInputGroupSubjects(groupId), value[groupId])),
});

/**
 * All subject input groups (by ID) for a particular view, by insertion or user-defined order.
 */
export const subjectInputGroups = atom<SubjectInputId[]>({
  key: 'subjectInputGroups',
  default: [],
  effects: [maxLengthValidatorEffect(MAX_INPUTS_PER_KIND), noDuplicatesValidatorEffect, resetOnStudioReset],
});

/** The input groups applied to a block, by insertion or user-defined order. */
export const blockSubjectInputGroups = atomFamily<SubjectInputId[], BlockId>({
  key: 'blockSubjectInputGroups',
  default: [],
  effects: (key) => [
    maxLengthValidatorEffect(MAX_BLOCK_SUBJECT_INPUTS),
    noDuplicatesValidatorEffect,
    truthyKeyValidatorEffect(key),
  ],
});
