import type { ColDef } from 'ag-grid-community';
import type { PivotedRow, RowToPivot } from 'venn-components';
import { getSubjectsColumnDefinition, mapColumnToRow, mapRowsToColumns } from './pivotGridDataUtils';

type PivotedGridData = {
  rowData: PivotedRow[];
  columnDefs: ColDef[];
};

type PivotGridData = (rows: RowToPivot[], columns: ColDef[]) => PivotedGridData;

export const pivotGridData: PivotGridData = (rows, columns) => {
  const subjectColumns = columns.slice(1); // omit label column

  const newSubjectsColumn = getSubjectsColumnDefinition();
  const mappedColumns = mapRowsToColumns(rows, subjectColumns);
  const mappedRows = subjectColumns.map((column, index) => mapColumnToRow(column, index, rows));

  return {
    columnDefs: [newSubjectsColumn, ...mappedColumns],
    rowData: mappedRows,
  };
};
