import React from 'react';
import { Icon } from 'venn-ui-kit';
import type { ConditionOption } from '../dropdowns/ConstraintConditionDropdown';
import type { SmallMultiToggleItem } from '../../toggle';
import type { AllocationCondition, ConstraintValueType } from 'venn-utils';
import { compact } from 'lodash';

export const getAllocationConditionOptions = (): ConditionOption<AllocationCondition>[] =>
  compact([
    {
      label: 'Minimum Allocation',
      value: 'minAllocation',
      description: 'Minimum required allocation for selection(s) ($/%)',
    },
    {
      label: 'Maximum Allocation',
      value: 'maxAllocation',
      description: 'Maximum allowed allocation for selection(s) ($/%)',
    },
    {
      label: 'Lock Allocation',
      value: 'lockedAllocation',
      description: 'Lock current allocation(s) for selection',
    },
  ]);

export const allocationValueTypeOptions: SmallMultiToggleItem[] = [
  {
    icon: '$',
    value: 'fixedValue' as ConstraintValueType,
    tooltip: 'Set all selections to a fixed value',
  },
  {
    icon: '%',
    value: 'percentOfPortfolio' as ConstraintValueType,
    tooltip: 'Set all selections to % of total portfolio allocation',
  },
  {
    icon: <Icon type="lock" prefix="far" />,
    value: 'currentValue' as ConstraintValueType,
    tooltip: 'Set all selections to “current value”',
  },
];
