import styled from 'styled-components';
import { GetColor } from './style/color';
import { Headline2 } from './components/typography/Typography';
import { GetTypography } from './style/themes';

export const PrintSubjectBar = styled.div`
  margin: 0px 2px 10px 2px;
  display: flex;
  align-items: center;
`;

export const PrintSubjectLeft = styled.div`
  padding: 2px;
  flex-grow: 1;
  font-family: ${GetTypography.fontFamily};
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
  overflow-x: hidden;
`;

export const PrintSubjectRight = styled.div`
  padding: 2px;
  text-align: right;
  flex-grow: 1;
  font-family: ${GetTypography.fontFamily};
  font-size: 14px;
  white-space: nowrap;
`;

export const PrintSubjectLabel = styled.span`
  margin-left: 20px;
  margin-right: 4px;
  font-weight: bold;
`;

export const PrintAnalysisTemplate = styled.div`
  margin: 10px 0px 0px 0px;
  padding: 4px 8px;
  background-color: ${GetColor.Black};
  color: ${GetColor.White};
  font-family: ${GetTypography.fontFamily};
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

export const Section = styled.section<{ pageBreak?: boolean }>`
  margin-top: 60px;
  @media print {
    display: block;
    ${({ pageBreak }) => pageBreak && 'page-break-before: always;'};
  }
`;

export const SectionHeadline = styled(Headline2)`
  @media print {
    font-size: 24px;
    width: 100%;
  }
`;

export const BlockRow = styled.div<{ avoidPageBreak?: boolean }>`
  @media print {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    ${({ avoidPageBreak }) => avoidPageBreak && 'page-break-inside: avoid;'}
  }
`;

export const BlockCell = styled.div<{ col: number; numCellsInRow: number }>`
  @media print {
    width: ${({ numCellsInRow }) => (numCellsInRow === 1 ? '100vw' : '49vw')};
    max-width: ${({ numCellsInRow }) => (numCellsInRow === 1 ? '100vw' : '49vw')};
    min-width: ${({ numCellsInRow }) => (numCellsInRow === 1 ? '100vw' : '49vw')};
  }
`;
