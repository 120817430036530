import type { CSSProperties } from 'react';
import React from 'react';
import { concat } from 'lodash';
import type { PortfolioCompare } from 'venn-api';
import type { DropMenuItem, Theme } from 'venn-ui-kit';
import { Tooltip, DropMenu } from 'venn-ui-kit';
import { withTheme } from 'styled-components';

interface BenchmarkProps {
  benchmarks: PortfolioCompare[];
  disabled?: boolean;
  usePortal?: boolean;
  style?: CSSProperties;
  onChange: (value: DropMenuItem) => void;
  onAddNew: () => void;
  disabledTooltipMessage?: string;
  theme: Theme;
}

const BENCHMARK_NONE_VALUE = 'none';

class Benchmark extends React.Component<BenchmarkProps> {
  onChangeHandler = (option: DropMenuItem) => {
    const { benchmarks } = this.props;
    const activeId = selectActiveBenchmarkId(benchmarks);

    if (activeId === option.value || (!activeId && option.value === BENCHMARK_NONE_VALUE)) {
      return;
    }

    this.props.onChange(option);
  };

  render() {
    const {
      benchmarks = [],
      onAddNew,
      disabled,
      usePortal,
      style,
      disabledTooltipMessage,
      theme: { Colors },
    } = this.props;
    const activeId = selectActiveBenchmarkId(benchmarks);
    const items: DropMenuItem[] = benchmarks.map((comparison) => ({
      value: comparison.fundId || comparison.portfolioId!.toString(),
      label: comparison.name,
    }));
    const itemsWithNone = concat(items, [
      { label: 'None', value: BENCHMARK_NONE_VALUE, style: { color: Colors.HintGrey } },
    ]);

    const menu = (
      <DropMenu
        items={activeId ? itemsWithNone : items}
        onChange={this.onChangeHandler}
        // @ts-expect-error: TODO fix strictFunctionTypes
        selected={activeId}
        link="Add new"
        onLinkClick={onAddNew}
        disabled={disabled}
        usePortal={usePortal}
        style={style}
        label="Benchmark:"
        placeholder="Select a benchmark"
      />
    );

    return disabledTooltipMessage ? <Tooltip content={disabledTooltipMessage}>{menu}</Tooltip> : menu;
  }
}

export default withTheme(Benchmark);

const selectActiveBenchmarkId = (benchmarks: PortfolioCompare[]) => {
  if (!benchmarks) {
    return null;
  }

  const active = benchmarks.find(({ benchmark }) => benchmark);

  if (!active) {
    return null;
  }

  return active.fundId || active.portfolioId!.toString();
};
