import { atom, selector, selectorFamily } from 'recoil';
import type { CustomFactorForecast } from 'venn-api';
import { getFactorForecasts } from 'venn-api';
import { selectorWithAuth } from '../utils/selectorWithAuth';
import { isRequestSuccessful, logExceptionIntoSentry } from 'venn-utils';
import { Notifications, NotificationType } from 'venn-ui-kit';
import type { EmptyRightPanelView, ForecastTab } from './commonTypes';

type CustomFactorForecastId = string;
export type FactorOverrideType = number | 'no change' | 'reset' | 'invalid change';
export type FactorForecastViewInner = {
  tab: ForecastTab.FactorForecast;
  detail: FactorForecastRightPanelViewWithId | FactorForecastRightPanelViewWithoutForecastId | EmptyRightPanelView;
};

export type FactorForecastRightPanelView = FactorForecastView['detail'];

export type FactorForecastView = {
  tab: ForecastTab.FactorForecast;
  detail:
    | FactorForecastRightPanelViewWithForecast
    | FactorForecastRightPanelViewWithoutForecastId
    | EmptyRightPanelView;
};

export type FactorForecastRightPanelViewWithForecast =
  | {
      type: 'SpecificForecast';
      forecast: CustomFactorForecast;
    }
  | {
      type: 'CMAsEditor';
      forecast: CustomFactorForecast;
    }
  | {
      type: 'HistoricalPeriodEditor';
      forecast: CustomFactorForecast;
    };

export type FactorForecastRightPanelViewWithId =
  | {
      type: 'SpecificForecast';
      forecastId: CustomFactorForecastId;
    }
  | {
      type: 'CMAsEditor';
      forecastId: CustomFactorForecastId;
    }
  | {
      type: 'HistoricalPeriodEditor';
      forecastId: CustomFactorForecastId;
    };

export type FactorForecastRightPanelViewWithoutForecastId =
  | {
      type: 'CMAsCreator';
    }
  | {
      type: 'HistoricalPeriodCreator';
    }
  | {
      type: 'ExposurePeriodEditor';
    };

export const defaultFactorForecastList = selectorWithAuth<CustomFactorForecast[]>({
  key: 'defaultFactorForecastList',
  get: async () => {
    try {
      const response = await getFactorForecasts();
      if (isRequestSuccessful(response)) {
        return response.content;
      }
      Notifications.notify('Unable to load forecasts', NotificationType.ERROR);
      return [];
    } catch (error) {
      logExceptionIntoSentry(error);
      Notifications.notify('Unable to load forecasts', NotificationType.ERROR);
      return [];
    }
  },
});
export const remoteFactorForecastList = atom<CustomFactorForecast[]>({
  key: 'remoteFactorForecastList',
  default: defaultFactorForecastList,
});

export const isFactorForecastEditingAtom = atom<boolean>({
  key: 'isFactorForecastEditingAtom',
  default: false,
});

export const remoteFactorForecast = selectorFamily<
  CustomFactorForecast | undefined,
  CustomFactorForecastId | undefined
>({
  key: 'remoteFactorForecast',
  get:
    (key) =>
    ({ get }) =>
      get(remoteFactorForecastList).find((forecast) => forecast.forecastId === key),
});

const defaultActiveForecastId = selector<CustomFactorForecastId | undefined>({
  key: 'defaultActiveForecastId',
  get: ({ get }) =>
    // TODO(VENN-20115): remove eslint-disable if it is safe to do so
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare
    get(remoteFactorForecastList).find((forecast: CustomFactorForecast) => forecast.default === true)?.forecastId,
});

export const activeForecastIdAtom = atom<CustomFactorForecastId | undefined>({
  key: 'activeForecastIdAtom',
  default: defaultActiveForecastId,
});

export type FactorNameType = string;
export const factorTextboxIsEditingAtom = atom<{ [name: FactorNameType]: boolean }>({
  key: 'factorTextboxIsEditingAtom',
  default: {},
});

export const factorCurrentPendingOverrideAtom = atom<{ [name: FactorNameType]: FactorOverrideType }>({
  key: 'factorCurrentPendingOverrideAtom',
  default: {},
});

export const factorEditValueBufferAtom = atom<{ [name: FactorNameType]: string }>({
  key: 'factorEditValueBufferAtom',
  default: {},
});

export const shouldShowFactorOverrideFooterAtom = selector<boolean>({
  key: 'shouldShowFactorOverrideFooterAtom',
  get: ({ get }) => {
    const isSubmitButtonDisabled = get(isFactorOverrideSubmitButtonDisabledAtom);
    const isCancelButtonDisabled = get(isFactorOverrideCancelButtonDisabled);
    return !(isSubmitButtonDisabled && isCancelButtonDisabled);
  },
});

export const isFactorOverrideSubmitButtonDisabledAtom = selector<boolean>({
  key: 'isFactorOverrideSubmitButtonDisabledAtom',
  get: ({ get }) => {
    const factorCurrentPendingOverride = get(factorCurrentPendingOverrideAtom);
    const allChanges = Object.keys(factorCurrentPendingOverride).map((key) => factorCurrentPendingOverride[key]);
    const hasChanges = allChanges.some((override) => override !== 'no change');
    const hasInvalidChanges = allChanges.some((override) => override === 'invalid change');
    return !hasChanges || hasInvalidChanges;
  },
});

export const isFactorOverrideCancelButtonDisabled = selector<boolean>({
  key: 'isFactorOverrideCancelButtonDisabled',
  get: ({ get }) => {
    const factorCurrentPendingOverride = get(factorCurrentPendingOverrideAtom);
    const factorTextboxIsEditing = get(factorTextboxIsEditingAtom);
    const allChanges = Object.keys(factorCurrentPendingOverride).map((key) => factorCurrentPendingOverride[key]);
    const isAnyFactorTextboxEditing = Object.keys(factorTextboxIsEditing)
      .map((key) => factorTextboxIsEditing[key])
      .some((value) => value);
    const isAnyFactorReverted = allChanges.some((value) => value === 'reset');
    return !(isAnyFactorTextboxEditing || isAnyFactorReverted);
  },
});
