import React, { type ReactNode } from 'react';
import {
  Button,
  ExternalActivityListener,
  GetColor,
  Icon,
  portalMenuIgnoreActivityClassName,
  RelativePortal,
  ShimmerBlock,
  Tooltip,
  ZIndex,
} from 'venn-ui-kit';
import { Input } from '../input';
import styled from 'styled-components';

interface InputEditorProps {
  title: ReactNode;
  name: string;
  setName: (name: string) => void;
  onClose: () => void;
  onSave: () => void;
  isNew?: boolean;
  children: React.ReactNode;
  disabled?: boolean;
  maxHeight?: number;
  maxSelectionsReached?: boolean;
  warningSelectionsReached?: boolean;
  ref?: React.RefObject<HTMLDivElement>;
}

export const InputEditor = ({
  name,
  setName,
  onClose,
  onSave,
  isNew,
  children,
  title,
  disabled,
  maxHeight,
  maxSelectionsReached,
}: InputEditorProps) => (
  <RelativePortal expectedHeight={maxHeight} style={{ zIndex: ZIndex.Cover }}>
    <ExternalActivityListener
      ignoreActivityFromClassName={portalMenuIgnoreActivityClassName}
      onExternalActivity={onClose}
    >
      <Container>
        <Title>
          {title}
          <button type="button" aria-label="Close dialog" onClick={onClose}>
            <CloseButton type="times" />
          </button>
        </Title>
        {children}
        <Footer>
          <GroupName>
            <b id="input-label">Input Name</b>
            <Input
              value={name}
              onChange={setName}
              placeholder="Missing name"
              style={{ width: '300px', height: '35px' }}
              aria-labelledby="input-label"
            />
          </GroupName>
          <Tooltip
            isHidden={!disabled || !maxSelectionsReached}
            content={maxSelectionsReached ? 'Maximum number of selections reached' : undefined}
          >
            <Button disabled={disabled} dominant dense noMargin onClick={onSave}>
              {isNew ? 'Add Selected' : 'Save Changes'}
            </Button>
          </Tooltip>
        </Footer>
      </Container>
    </ExternalActivityListener>
  </RelativePortal>
);

export const InputEditorLoadState = ({ maxHeight }: Pick<InputEditorProps, 'maxHeight'>) => (
  <RelativePortal expectedHeight={maxHeight} style={{ zIndex: ZIndex.Cover }}>
    <Container>
      <ShimmerBlock height={maxHeight} margin={0} />
    </Container>
  </RelativePortal>
);

const GroupName = styled.div`
  display: flex;
  flex-direction: column;
`;

const CloseButton = styled(Icon)`
  color: ${GetColor.Primary.Dark};
`;

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
`;

const Title = styled(SpaceBetween)`
  padding-bottom: 20px;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
`;

const Footer = styled(SpaceBetween)`
  box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.1);
  align-items: flex-end;
  height: 80px;
`;

const Container = styled.div`
  z-index: ${ZIndex.Front};
  padding: 20px 0;
  border: 4px solid ${GetColor.Primary.Main};
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.12);
  background-color: ${GetColor.White};
  width: 635px;
  display: flex;
  flex-direction: column;
`;
