import type { ErrorWrapper } from './errorWrapperTypes';
import { StyledErrorState } from '../StyledErrorState';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { blockSubjects } from 'venn-state';
import { ComparisonHelpLink } from './shared';
import { useDebugValue } from 'venn-utils';

export const ComparisonSubjectsErrorWrapper: ErrorWrapper = ({ children, blockId }) => {
  const allSubjects = useRecoilValue(blockSubjects(blockId));
  const publicPortfolios = allSubjects.filter((subject) => !!subject.portfolioId);
  const forceError = useDebugValue('debugBlockErrorState') === 'ComparisonSubjects';

  if (forceError || publicPortfolios.length === 0) {
    return (
      <StyledErrorState
        selectedRefId={blockId}
        header="Add 2 returns-based portfolios in order to compare"
        message={<ComparisonHelpLink />}
      />
    );
  }
  return <>{children}</>;
};
