import styled, { css } from 'styled-components';
import { ColorUtils, GetColor, ZIndex } from 'venn-ui-kit';

const TextTrigger = styled.button<{ isOpen: boolean }>`
  position: relative;
  padding: 0 10px;
  max-width: 675px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;

  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  display: flex;
  align-items: center;

  ${({ isOpen }) =>
    isOpen
      ? css`
          color: ${GetColor.White} !important;
          background-color: ${GetColor.Primary.Dark};
          z-index: ${ZIndex.Cover};
        `
      : css`
          color: ${GetColor.Primary.Dark} !important;
          z-index: ${ZIndex.Front};
          :hover {
            background-color: ${ColorUtils.opacifyFrom(GetColor.Primary.Dark, 0.1)};
          }
        `};
`;

export default TextTrigger;
