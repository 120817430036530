import type { ObjectiveType } from 'venn-utils';
import { useCallback, useEffect, useState } from 'react';
import type { PortfolioSummary } from 'venn-api';
import { isNil } from 'lodash';
import type { PortfolioLabStoredSettings } from './useValuesFromHistoryState';

interface ObjectiveStateValue {
  objective: ObjectiveType;
  objectiveConstraintValue?: number;
  onChangeObjective: (objective: ObjectiveType, value: number) => void;
  constraintInputFocused: boolean;
  setConstraintInputFocused: (focused: boolean) => void;
  onEditObjectiveConstraintValue: () => void;
}

// This hook keeps a part of the state of the header, so that we can open & focus the constraint value input
// from anywhere on the page.
const useObjectiveState = (
  portfolioId: number | undefined,
  summary: PortfolioSummary | undefined,
  historicalVolatility: number | undefined,
  historicalReturn: number | undefined,
  settings: PortfolioLabStoredSettings,
  storeSettings: (values: Partial<PortfolioLabStoredSettings>) => void,
): ObjectiveStateValue => {
  const defaultMaxVolatility =
    (summary?.id === portfolioId ? summary?.annualizedVolatility : undefined) ?? historicalVolatility;
  const defaultMinReturn =
    (summary?.id === portfolioId ? summary?.annualizedTotalReturn : undefined) ?? historicalReturn;

  const [objective, setObjective] = useState<ObjectiveType>(settings.objective ?? 'maximizeReturns');
  const [constraintValue, setConstraintValue] = useState<number | undefined>(settings.constraintValue ?? undefined);

  useEffect(() => {
    // Reset objective constraint value when portfolio is changed
    if (!isNil(portfolioId) && !isNil(settings.portfolioId) && portfolioId !== settings.portfolioId) {
      setConstraintValue(undefined);
    }
  }, [portfolioId, settings.portfolioId]);

  const [constraintInputFocused, setConstraintInputFocused] = useState(false);

  const onChangeObjective = useCallback(
    (updatedObjective: ObjectiveType, value: number) => {
      setObjective(updatedObjective);
      setConstraintValue(value);
      storeSettings({ objective: updatedObjective, constraintValue: value });
    },
    [storeSettings],
  );

  const onEditObjectiveConstraintValue = useCallback(() => setConstraintInputFocused(true), []);
  const defaultConstraintValue =
    objective === 'maximizeReturns'
      ? isNil(defaultMaxVolatility)
        ? undefined
        : rounded(defaultMaxVolatility * 100)
      : isNil(defaultMinReturn)
        ? undefined
        : rounded(defaultMinReturn * 100);

  return {
    objective,
    objectiveConstraintValue: constraintValue ?? defaultConstraintValue,
    onChangeObjective,
    constraintInputFocused,
    setConstraintInputFocused,
    onEditObjectiveConstraintValue,
  };
};

export default useObjectiveState;

const rounded = (value: number): number => Math.round(value * 100) / 100;
