import { createGrpcWebTransport } from '@connectrpc/connect-web';
import type { ServiceType } from '@bufbuild/protobuf';
import type { Interceptor } from '@connectrpc/connect';
import { vennHeadersInterceptor } from 'venn-api';
import { useMemo } from 'react';
import { logExceptionIntoSentry } from '../../error-logging';
import type { ResultClient } from './resultClient';
import { createResultClient } from './resultClient';
import type { PathType } from '../../feature-availability/features';

const loggingInterceptor: Interceptor = (next) => async (req) => {
  const resp = next(req);

  resp.catch((reason) => {
    logExceptionIntoSentry(reason);
  });

  return resp;
};

const transportOptions = {
  useBinaryFormat: true,
  interceptors: [vennHeadersInterceptor, loggingInterceptor],
};

const grpcTransportRelative = createGrpcWebTransport({
  ...transportOptions,
  baseUrl: '/api',
});

const grpcTransportAbsolute = createGrpcWebTransport({
  ...transportOptions,
  baseUrl: `${process.env.DOMAIN ?? 'https://app.venn.twosigma.com'}/api`,
});

export function useClient<T extends ServiceType>(service: T, pathType: PathType = 'RELATIVE_PATH'): ResultClient<T> {
  return useMemo(() => {
    if (pathType === 'RELATIVE_PATH') {
      return createResultClient(service, grpcTransportRelative);
    }

    return createResultClient(service, grpcTransportAbsolute);
  }, [service, pathType]);
}
