import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import { noop } from 'lodash';
import { GetColor } from '../../../style/color';
import { Loading, LoadingSize, LoadingContainer } from '../../loading';
import type { ButtonDropdownOption, ButtonDropdownProps } from '../../button-dropdown';
import { ButtonDropdown } from '../../button-dropdown';

export interface SearchProps {
  setInputRef?: (element?: HTMLInputElement) => void;
  buttonProps?: ButtonDropdownProps<string>;
  disabled?: boolean;
  isLoading?: boolean;
  placeholder?: string;
  value?: string;
  query?: string;
  onChange?: (newVal: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  autoFocus?: boolean;
}

export interface SearchState {
  isFocused: boolean;
}

const InputWrapper = styled.section<SearchState>`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  position: relative;

  ${(props) =>
    props.isFocused
      ? css`
          border: 1px solid ${GetColor.Primary.Main};
          & + div {
            visibility: visible;
          }
        `
      : css`
          border: 1px solid ${GetColor.PaleGrey};
          & + div {
            visibility: hidden;
          }
        `};
`;

const ButtonWrapper = styled.div`
  display: flex;
  padding-left: 4px;
`;

const RelativeInputContainer = styled.div`
  position: relative;
  width: 100%;
  min-width: 150px;
  height: 35px;
`;

const AbsoluteInputContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
`;

const Input = styled.input`
  padding: 5px 40px 5px 10px;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 0;
  font-size: 14px;
  font-weight: normal;
  background: ${GetColor.White};

  &:active,
  &:focus {
    border: 0;
  }
`;

const QueryInput = styled(Input)`
  background: transparent;
`;

const LoadingWrapper = styled.div`
  position: absolute;
  right: 0;

  & ${LoadingContainer} {
    margin-right: 10px;
    margin-left: 10px;
    height: 100%;
    width: 20px;
  }
`;

class Search extends PureComponent<SearchProps, SearchState> {
  static defaultProps = {
    onChange: noop,
    onFocus: noop,
  };

  input: HTMLInputElement;

  wrapperRef = React.createRef<HTMLDivElement>();

  constructor(props: SearchProps) {
    super(props);
    this.state = {
      isFocused: false,
    };
  }

  setInputRef = (element: HTMLInputElement) => {
    this.input = element;
    this.props.setInputRef && this.props.setInputRef(element);
  };

  handleFocus = () => {
    this.setState({ isFocused: true });
    this.props.onFocus && this.props.onFocus();
  };

  handleBlur = () => {
    this.setState({ isFocused: false });
    this.props.onBlur && this.props.onBlur();
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChange && this.props.onChange(event.target.value);
  };

  handleDropdownSelect = (value: ButtonDropdownOption<string>) => {
    const { buttonProps: { onChange = noop } = {} } = this.props;
    onChange(value);
    this.input.focus();
  };

  handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      this.input.blur();
    }
  };

  render() {
    const { isFocused } = this.state;
    const { value, query, disabled, buttonProps, isLoading, placeholder, autoFocus } = this.props;

    return (
      <InputWrapper isFocused={isFocused} ref={this.wrapperRef}>
        {buttonProps && (
          <ButtonWrapper>
            <ButtonDropdown
              {...buttonProps}
              initialValue={buttonProps.initialValue}
              onChange={this.handleDropdownSelect}
            />
          </ButtonWrapper>
        )}
        <RelativeInputContainer>
          <AbsoluteInputContainer>
            <Input readOnly value={query || value === null ? '' : value} />
          </AbsoluteInputContainer>
          <AbsoluteInputContainer>
            <QueryInput
              ref={this.setInputRef}
              disabled={disabled}
              data-testid="search-input"
              type="text"
              value={query}
              placeholder={placeholder}
              onChange={this.handleChange}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              onKeyPress={this.handleKeyPress}
              autoFocus={autoFocus}
            />
          </AbsoluteInputContainer>
        </RelativeInputContainer>
        {isLoading && isFocused && (
          <LoadingWrapper>
            <Loading title="" size={LoadingSize.micro} />
          </LoadingWrapper>
        )}
      </InputWrapper>
    );
  }
}

export default Search;
