export interface DataPoint {
  /**
   * Timestamp, in UTC
   */
  x: number;
  /**
   * Value
   */
  y: number;
}

export interface DoubleDataPoint {
  /**
   * Timestamp, in UTC
   */
  x: number;
  /**
   * Value #1
   */
  y1: number;
  /**
   * Value #2
   */
  y2: number;
}

export interface Range {
  start: number;
  end: number;
}

export type Returns = DataPoint[];

export enum QuickFilter {
  RECENTLY_ANALYZED = 'recentlyAnalyzed',
  UPLOADED_BY_ANYONE = 'uploadedByAnyone',
  UPLOADED_BY_ME = 'uploadedByMe',
  INTEGRATIONS = 'integrations',
  REQUIRES_ATTENTION = 'requiresAttention',
}

export enum LibraryItemType {
  ALL = 'all',
  /* Portfolio */
  PORTFOLIO = 'portfolio',
  PORTFOLIO_INTEGRATION = 'portfolioIntegration',
  PRO_FORMA = 'proForma',
  /* --------- */
  COMPOSITE_BENCHMARK = 'compositeBenchmark',
  /* Investment */
  INVESTMENT = 'investment',
  UPLOAD = 'upload',
  INVESTMENT_INTEGRATION = 'investmentIntegration',
  MASTER_PORTFOLIO_HOLDING = 'masterPortfolioHolding',
  VENN_PROVIDED = 'vennProvided',
  /* --------- */
  NONE = 'none',

  TAGS = 'tags',
}

/**
 * Represents the selected filters on the library side menu
 */
export interface SelectedFilters {
  quickFilters: QuickFilter[];
  itemType: LibraryItemType;
  tags: string[];
}

/**
 * Uniquely identify each library side menu filter section and used as URL query param keys
 */
export enum LibraryFilterSectionKey {
  FILTERS = 'filters',
  ITEM_TYPE = 'itemType',
  TAGS = 'tags',
}

/**
 * The type of the filter in the advance search
 */
export enum FilterType {
  SOURCE = 'sourceFilter',
  ITEM_TYPE = 'itemTypeFilter',
  CURRENCY = 'currencyFilter',
  ASSET_TYPE = 'assetTypeFilter',
  DATA_SOURCE = 'dataSourceFilter',
  MORNINGSTAR_CATEGORY = 'morningstarCategoryFilter',
  ADVANCED_QUERY = 'advancedQuery',
}

export enum SavedViewsQuickFilter {
  RECENTLY_ANALYZED = 'RECENTLY_ANALYZED',
  REQUIRES_ATTENTION = 'REQUIRES_ATTENTION',
  SCHEDULED = 'SCHEDULED',
  SAVED_BY_ME = 'SAVED_BY_ME',
  VENN_PROVIDED = 'VENN_PROVIDED',
}
