/**
 * Insert element into array at given index
 * @param arr Source array
 * @param index Insert index
 * @param newItem Item to insert
 */
export const insert = <T = unknown>(arr: T[], index: number, newItem: T): T[] => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index),
];
