import type { SVGAttributes, CSSProperties } from 'react';
import React, { PureComponent } from 'react';
import BarBase from './BarBase';
import ErrorBar from '../ErrorBar';
import { calculateBarBoundingBox } from '../logic';
import type { Scale, ViewPort, BoundingBox } from '../types';

export interface BarProps extends SVGAttributes<SVGGElement> {
  color: string;
  factorId: number;
  index: number;
  value: { x: number; value: number };
  error: number;
  barScale: Scale;
  viewPort: ViewPort;
  errorBar?: boolean;
  statisticallySignificant?: boolean;
  waterfall?: boolean;
  opacity?: number;
}

const barRootStyle: CSSProperties = {
  pointerEvents: 'none',
};

export default class Bar extends PureComponent<BarProps> {
  calculateDimensions(): BoundingBox {
    return calculateBarBoundingBox(this.props.viewPort, this.props.barScale, this.props.value);
  }

  render() {
    const { color, error, errorBar, factorId, statisticallySignificant, value, waterfall, ...restProps } = this.props;
    const { x, y, width, height, errorSize } = this.calculateDimensions();
    const barEnd = Math.max(width, 0);
    const { opacity, barScale, viewPort, ...gProps } = restProps;

    return (
      <g style={barRootStyle} {...gProps}>
        <g transform={`translate(${x}, 0)`}>
          <BarBase
            color={color}
            factorId={factorId}
            x={0}
            y={y}
            width={width}
            height={height}
            negative={value.value < 0}
            opacity={opacity}
            statisticallySignificant={statisticallySignificant}
            waterfall={waterfall}
          />
          {error && <ErrorBar hidden={!errorBar} size={errorSize} x={barEnd} y={height / 2 + 1} />}
        </g>
      </g>
    );
  }
}
