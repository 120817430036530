import React from 'react';
import type { CellRenderer } from './types';
import { isCellDisabled, isDataNew } from './helpers';
import styled from 'styled-components';
import { ColorUtils, GetColor } from 'venn-ui-kit';
import type { ColumnMapping, FileUploadMetadata } from 'venn-api';
import type { ErrorViewModel } from '../../views/review/helpers';
import { DataUploaderMode } from '../../types';

interface FundLinkerRendererProps {
  columnMapping: ColumnMapping;
  metadata: FileUploadMetadata;
  mode: DataUploaderMode;
  errors: ErrorViewModel[];
}

// @ts-expect-error: TODO fix strictFunctionTypes
export const FundLinkerRenderer: CellRenderer = ({
  columnMapping,
  metadata,
  mode,
  errors,
}: FundLinkerRendererProps) => {
  const isReturns = mode === DataUploaderMode.Returns;
  const { fundId, portfolioNodeId, seriesId } = columnMapping;
  const isDisabled = isCellDisabled(columnMapping, metadata, errors, seriesId);
  const isNew = isDataNew(isReturns, !!fundId, !!portfolioNodeId);
  return (
    <MappedLabel className="qa-investment-status" isNew={isNew} disabled={isDisabled}>
      {isNew ? 'New!' : 'Mapped'}
    </MappedLabel>
  );
};

const MappedLabel = styled.span<{ disabled: boolean; isNew: boolean }>`
  font-weight: bold;
  font-size: 11px;
  border-radius: 2px;
  color: ${({ disabled, isNew }) =>
    disabled ? GetColor.Grey : isNew ? GetColor.White : GetColor.DEPRECATED_DataLineColor.Green};
  background-color: ${({ disabled, isNew }) =>
    disabled
      ? GetColor.WhiteGrey
      : isNew
        ? GetColor.HighlightDark
        : ColorUtils.hex2rgbaFrom(GetColor.DEPRECATED_DataLineColor.Green, 0.2)};
  margin: 0 20px;
  padding: 2px 10px;
  display: inline-block;
`;
