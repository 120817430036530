import { includes, isNil, omit } from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import type { Portfolio } from 'venn-api';
import {
  getFund,
  getFundReturnsRange,
  getPrivateFund,
  getPrivatePortfolio,
  getSpecificPortfolioV3,
  getUploadedSeriesForFund,
  SupportedErrorCodes,
  updatePortfolioV3,
} from 'venn-api';
import {
  convertFundSeriesToUserData,
  type DataRangeInfo,
  DataUploaderModalWrapper,
  DataUploaderMode,
  PageContentContainer,
  PortfoliosContext,
  SideMenu,
  useMetaData,
  useRangeAnalysis,
  UserContext,
  type UserData,
} from 'venn-components';
import { ColorUtils, GetColor, Headline1, Icon, Tooltip, ZIndex } from 'venn-ui-kit';
import type { AnalysisSubjectType, MANAGE_DATA_SECTION, ManageDataState, TemplateNavigationState } from 'venn-utils';
import {
  AnalysisSubject,
  analyticsService,
  assertExhaustive,
  FundUtils,
  getDefaultSection,
  logExceptionIntoSentry,
  Routes,
  useApi,
  useHasFF,
  useModal,
} from 'venn-utils';
import InvestmentManageData from './InvestmentManageData';
import ManageDataHeader from './ManageDataHeader';
import PortfolioManageData from './PortfolioManageData';
import { PrivateInvestmentManageData } from './PrivateInvestmentManageData';
import PrivatePortfolioManageData from './PrivatePortfolioManageData';
import { getMenu, getSection } from './sections';

const PAGE_MARGIN = 60;

interface ManageDataPageParams {
  objectType: AnalysisSubjectType;
  objectId: string;
  section?: MANAGE_DATA_SECTION;
}

const ManageDataPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const hasPrivateAnalytics = useHasFF('private_analytics');
  const { hasPermission } = useContext(UserContext);
  const { updateMaster } = useContext(PortfoliosContext);
  // TODO: VENN-23554 :Fix routing types
  const history = useHistory<ManageDataState & TemplateNavigationState>();
  const location = history.location;
  const { objectType, objectId, section } = useParams<ManageDataPageParams>();
  const { secondaryPortfolio, secondaryLabel } = location.state ?? {};
  const [subject, setSubject] = useState<AnalysisSubject>();
  const abortableGetPortfolio = useApi(getSpecificPortfolioV3);
  const abortableGetFund = useApi(getFund);
  const abortableUpdatePortfolio = useApi(updatePortfolioV3);

  const canOptimize = hasPermission('OPTIMIZATION');
  const privateAnalyticsFF = useHasFF('private_analytics');
  const hasPrivatesCashFlowSettingsFF = useHasFF('privates_hyperparameters_ff');
  const showCashFlowPacingSettings = privateAnalyticsFF && hasPrivatesCashFlowSettingsFF;
  const menu = useMemo(
    () =>
      getMenu(subject, {
        canOptimize,
        showCashFlowPacingSettings,
      }),
    [subject, canOptimize, showCashFlowPacingSettings],
  );
  const [selectedSection, setSelectedSection] = useState(
    getSection(menu, objectType as AnalysisSubjectType, section as MANAGE_DATA_SECTION),
  );

  useEffect(() => {
    if (objectType) {
      setSelectedSection(getSection(menu, objectType as AnalysisSubjectType, section as MANAGE_DATA_SECTION));
    }
  }, [menu, objectType, section]);

  // use when previous section shouldn't be part of the history, e.g. user has no access
  const redirectToSection = useCallback(
    (newSection: MANAGE_DATA_SECTION) => {
      history.replace(`${Routes.MANAGE_DATA_PATH}/${objectType}/${objectId}/${newSection}`, location.state);
    },
    [history, objectType, objectId, location.state],
  );

  const switchSection = useCallback(
    (newSection: MANAGE_DATA_SECTION) => {
      analyticsService.navigationTriggered({
        destinationPageTitle: newSection,
        itemType: 'tab',
        location: 'Manage Data Page',
        userIntent: 'Change tabs',
      });
      history.replace(
        `${Routes.MANAGE_DATA_PATH}/${objectType}/${objectId}/${newSection}`,
        // don't include when switching sections so that there's no animation
        omit(location.state, 'previousPathMatch'),
      );
    },
    [history, objectType, objectId, location.state],
  );

  useEffect(() => {
    // make sure user is in a valid section, i.e. section is in `menu`
    // also ensure subject has loaded before checking `menu` so that we know if TABS.DATA is accessible or not
    if (!section || (subject && !menu.find(({ value }) => value === section))) {
      redirectToSection(getDefaultSection(objectType as AnalysisSubjectType));
    }
  }, [redirectToSection, objectType, section, menu, subject]);

  const fetchSubject = useCallback(async () => {
    try {
      if (objectType === 'private-portfolio') {
        const portfolio = (await getPrivatePortfolio(objectId)).content;
        setSubject(new AnalysisSubject(portfolio, 'private-portfolio'));
        return;
      }
      if (objectType === 'private-investment') {
        const investment = (await getPrivateFund(objectId)).content;
        setSubject(new AnalysisSubject(investment, 'private-investment'));
        return;
      }

      if (objectType === 'portfolio') {
        const portfolio = (await abortableGetPortfolio(Number(objectId), undefined)).content;
        setSubject(
          new AnalysisSubject(portfolio, 'portfolio', {
            secondaryPortfolio,
            secondaryLabel,
          }),
        );
        return;
      }
      if (objectType === 'investment') {
        const investment = (await abortableGetFund(objectId)).content;
        setSubject(new AnalysisSubject(investment, 'investment', {}));
        return;
      }
    } catch (e) {
      logExceptionIntoSentry(e);
    }
    // handle both fetch subject exception and invalid object type
    const errorQueryParam = 'invalidSubject';
    history.replace(`${Routes.HOME_PATH}?${errorQueryParam}=true`);
  }, [abortableGetFund, abortableGetPortfolio, history, objectId, objectType, secondaryLabel, secondaryPortfolio]);

  const updatePortfolio = useCallback(
    async (portfolio: Portfolio) => {
      try {
        const updatedPortfolio = portfolio.master
          ? (await abortableUpdatePortfolio(portfolio.id, portfolio)).content
          : await updateMaster(portfolio);
        updatedPortfolio &&
          setSubject(
            new AnalysisSubject(updatedPortfolio, 'portfolio', {
              secondaryPortfolio,
              secondaryLabel,
            }),
          );
      } catch (e) {
        logExceptionIntoSentry(e);
      }
    },
    [abortableUpdatePortfolio, secondaryLabel, secondaryPortfolio, updateMaster],
  );

  const shouldFetch = (() => {
    if (!subject || subject.type !== objectType) {
      return true;
    }

    switch (objectType) {
      case 'portfolio': {
        return objectId !== String(subject?.portfolio?.id ?? 0);
      }
      case 'investment': {
        return objectId !== subject?.fund?.id;
      }
      case 'private-investment': {
        return objectId !== subject?.privateFund?.id;
      }
      case 'private-portfolio': {
        return objectId !== subject?.privatePortfolio?.id;
      }
      default: {
        throw assertExhaustive(objectType, 'Unexpected subject type');
      }
    }
  })();

  useEffect(() => {
    if (shouldFetch) {
      fetchSubject();
    }
  }, [fetchSubject, shouldFetch]);

  const onFundUpdated = useCallback(
    (id: string) => {
      if (subject?.id === id) {
        fetchSubject();
      }
    },
    [fetchSubject, subject],
  );

  const [investmentUserData, setInvestmentUserData] = useState<UserData>();
  const [investmentReturnsRange, setInvestmentReturnsRange] = useState<DataRangeInfo>();
  const [investmentHasNoReturns, setInvestmentHasNoReturns] = useState(false);
  const fetchFundSeries = useCallback(async () => {
    if (isNil(objectId) || !subject?.fund) {
      return;
    }

    try {
      const { content: returnsRange } = await getFundReturnsRange(true, subject.fund.id);
      setInvestmentReturnsRange(returnsRange);
      if (!FundUtils.isPerformanceExportable(subject.fund)) {
        return;
      }

      const { content } = await getUploadedSeriesForFund(objectId);
      setInvestmentUserData(convertFundSeriesToUserData(subject.fund.name, content));
    } catch (error) {
      if (error.status === 404 && error.content.code === SupportedErrorCodes.NoFundReturns) {
        /* Change the view if the fund has no returns */
        setInvestmentHasNoReturns(true);
        setInvestmentReturnsRange({});
        return;
      }
      logExceptionIntoSentry(error);
    }
  }, [objectId, subject]);

  useEffect(() => {
    fetchFundSeries();
  }, [fetchFundSeries]);

  const useRangeAnalysisReturn = useRangeAnalysis(subject);
  const useMetaDataReturn = useMetaData(subject?.fund?.id);

  /* If the fund has no returns the modal is used to upload returns. */
  const [isUploadModalOpen, openUploadModal, closeUploadModal] = useModal();

  /* Handle behavior of return modal being closed.
   * If the currently selected investment was uploaded re-fetch return data. */
  const onUploadComplete = (portfolio: Portfolio) => {
    /* Determine if the current investment was uploaded. */
    const uploadedFundIds = portfolio.children.map((p) => p.fund?.id);
    if (includes(uploadedFundIds, objectId)) {
      setInvestmentHasNoReturns(false);
      fetchFundSeries();
    }
    closeUploadModal();
  };

  const handleFundUpdated = useCallback(async () => {
    await Promise.all([fetchSubject(), useRangeAnalysisReturn.refresh(), useMetaDataReturn.refreshMetadata()]);
  }, [fetchSubject, useMetaDataReturn, useRangeAnalysisReturn]);

  const showBackButton = !!location.state; // show back button if the prior state is set in the app

  return (
    <>
      <HeaderWrapper>
        <PageTitle>
          {showBackButton ? (
            <LinkWrapper>
              <Tooltip content="Back">
                <div data-testid="back">
                  <Icon type="arrow-left" onClick={history.goBack} />
                </div>
              </Tooltip>
            </LinkWrapper>
          ) : null}
          Manage Data
        </PageTitle>
        <ManageDataHeader
          subject={subject}
          rangeAnalysis={useRangeAnalysisReturn.rangeAnalysis}
          onNameUpdated={handleFundUpdated}
          investmentUserData={investmentUserData}
          investmentUseMetaDataReturn={useMetaDataReturn}
          investmentReturnsRange={investmentReturnsRange}
        />
      </HeaderWrapper>
      <MainContainer>
        <TabsContainer>
          <SideMenu<MANAGE_DATA_SECTION>
            items={menu}
            selectedItems={selectedSection}
            onClick={switchSection}
            maxHeight={undefined}
            allowItemOverflow
          />
        </TabsContainer>
        <PageContentWrapper minHeight={700}>
          <PageContentContainer className="page-content">
            {hasPrivateAnalytics && objectType === 'private-investment' ? (
              <PrivateInvestmentManageData subject={subject} selectedSection={selectedSection} />
            ) : hasPrivateAnalytics && objectType === 'private-portfolio' ? (
              <PrivatePortfolioManageData portfolio={subject?.privatePortfolio} selectedSection={selectedSection} />
            ) : objectType === 'investment' && selectedSection ? (
              <InvestmentManageData
                subject={subject}
                useRangeAnalysisReturn={useRangeAnalysisReturn}
                onFundUpdated={handleFundUpdated}
                selectedSection={selectedSection}
                userData={investmentUserData}
                useMetaDataReturn={useMetaDataReturn}
                updateUserData={setInvestmentUserData}
                missingReturns={investmentHasNoReturns}
                openUploadModal={openUploadModal}
              />
            ) : objectType === 'portfolio' ? (
              <PortfolioManageData
                subject={subject}
                useRangeAnalysisReturn={useRangeAnalysisReturn}
                onFundUpdated={onFundUpdated}
                selectedSection={selectedSection}
                updatePortfolio={updatePortfolio}
              />
            ) : null}
          </PageContentContainer>
        </PageContentWrapper>
      </MainContainer>
      {isUploadModalOpen && (
        <DataUploaderModalWrapper
          mode={DataUploaderMode.Returns}
          onCancel={closeUploadModal}
          onComplete={onUploadComplete}
          customUploadReviewButtonLabel="Upload"
        />
      )}
    </>
  );
};

export default ManageDataPage;

const HeaderWrapper = styled.div`
  z-index: ${ZIndex.StickyFront};
  background-color: ${GetColor.White};
  position: sticky;
  top: 0;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 60px 46px;
`;

const TabsContainer = styled.div`
  width: 200px;
  padding-top: 40px;
`;

const PageContentWrapper = styled.div<{ minHeight?: number }>`
  padding-left: 20px;
  flex: 1;
  ${(props) => props.minHeight && `min-height: ${props.minHeight}px;`}
`;

const LinkWrapper = styled.div`
  display: inline;
  margin-right: 20px;
  font-size: 36px;

  &:hover {
    background-color: ${ColorUtils.opacifyFrom(GetColor.Primary.Dark, 0.1)};
    color: ${GetColor.Primary.Dark};
  }

  i {
    font-weight: normal;
  }
`;

const PageTitle = styled(Headline1)`
  position: sticky;
  margin: 0 ${PAGE_MARGIN}px;
  padding: 46px 0 15px;
  border-bottom: 2px solid ${GetColor.PaleGrey};
`;
