import type { FC } from 'react';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import type { CompareType } from '../../contexts';
import { ComparisonContext, PortfoliosContext } from '../../contexts';
import type { AnalysisSubject } from 'venn-utils';
import { logExceptionIntoSentry, useApi } from 'venn-utils';
import type { Portfolio } from 'venn-api';
import { getSpecificPortfolioV3 } from 'venn-api';
import { isNil } from 'lodash';

interface StudioComparisonContextStoreProps {
  analysisSubject: AnalysisSubject;
}

const StudioComparisonContextStore: FC<React.PropsWithChildren<StudioComparisonContextStoreProps>> = ({
  analysisSubject,
  children,
}) => {
  const { masterPortfolio, loading: masterLoading } = useContext(PortfoliosContext);

  const [selectedHistoricalPortfolio, setSelectedHistoricalPortfolio] = useState<Portfolio | undefined>(
    analysisSubject?.secondaryLabel === 'Last Saved' ? analysisSubject?.secondaryPortfolio : undefined,
  );
  const getPortfolioVersion = useApi(getSpecificPortfolioV3);
  const [loadingVersion, setLoadingVersion] = useState(false);
  const loadSavedVersion = useCallback(
    async (portfolioId: number, version: number | undefined) => {
      setLoadingVersion(true);
      try {
        // TODO: find a way to use the recoil-fetched value historical portfolio
        const { content } = await getPortfolioVersion(portfolioId, version);
        setSelectedHistoricalPortfolio(content);
      } catch (e) {
        if (e.name !== 'AbortError') {
          logExceptionIntoSentry(e);
        }
      }
      setLoadingVersion(false);
    },
    [getPortfolioVersion, setLoadingVersion, setSelectedHistoricalPortfolio],
  );

  const updateCompareType = useCallback(
    (compareType: CompareType, customPortfolio?: Portfolio | undefined) => {
      const primary = analysisSubject?.portfolio;
      if (isNil(primary)) {
        return;
      }

      if (
        compareType === 'Last Saved' &&
        !isNil(customPortfolio) &&
        !loadingVersion &&
        (selectedHistoricalPortfolio?.id !== customPortfolio.id ||
          selectedHistoricalPortfolio?.version !== customPortfolio.version ||
          selectedHistoricalPortfolio?.draft) // draft means we didn't load the actual value before the panel was closed
      ) {
        loadSavedVersion(customPortfolio?.id ?? primary.id, customPortfolio?.version ?? primary.version);
      }
    },
    [analysisSubject, loadSavedVersion, loadingVersion, selectedHistoricalPortfolio],
  );
  const comparePortfolio = useMemo(() => {
    if (analysisSubject?.secondaryLabel === 'Master') {
      return masterPortfolio;
    }
    if (analysisSubject?.secondaryLabel === 'Last Saved') {
      return loadingVersion ? undefined : selectedHistoricalPortfolio;
    }
    return undefined;
  }, [analysisSubject, masterPortfolio, loadingVersion, selectedHistoricalPortfolio]);
  const compareLoading = useMemo(() => {
    if (analysisSubject?.secondaryLabel === 'Master') {
      return masterLoading;
    }
    if (analysisSubject?.secondaryLabel === 'Last Saved') {
      return loadingVersion;
    }
    return false;
  }, [analysisSubject, masterLoading, loadingVersion]);

  // All consumers will rerender if the actual object changes reference
  const contextValue = useMemo(
    () => ({ compareType: analysisSubject?.secondaryLabel, comparePortfolio, compareLoading, updateCompareType }),
    [analysisSubject?.secondaryLabel, compareLoading, comparePortfolio, updateCompareType],
  );
  return <ComparisonContext.Provider value={contextValue}>{children}</ComparisonContext.Provider>;
};

export default StudioComparisonContextStore;
