import { useEffect, useRef } from 'react';
import type { History } from 'history';
import { updateNavigation } from './updateNavigation';
import type { AnalysisConfig } from 'venn-utils';
import { useIsMounted } from 'venn-utils';

/**
 * This handles navigation changes and URL updates. URL is updated on analysis config updates.
 * @param analysisConfig Object
 * @param history Router History
 */
const useNavigation = (analysisConfig: AnalysisConfig, history: History) => {
  // We do not want to trigger effects when this object changes, so we decouple from deps
  // array for useEffect by using a ref to store the value of history
  const historyRef = useRef(history);
  const isMounted = useIsMounted();

  useEffect(() => {
    if (analysisConfig.subject && isMounted.current) {
      updateNavigation(analysisConfig, historyRef.current);
    }
  }, [analysisConfig, analysisConfig.analysisTemplate, isMounted]);
};

export default useNavigation;
