import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import type { ButtonProps } from 'venn-ui-kit';
import { GetColor, ColorUtils } from 'venn-ui-kit';

const InlineActionPillButton: FC<React.PropsWithChildren<ButtonProps>> = ({ children, ...buttonProps }) => (
  <Wrapper>
    <StyledButton {...buttonProps}>{children}</StyledButton>
  </Wrapper>
);
export default InlineActionPillButton;

const Wrapper = styled.div`
  margin-left: 20px;
  height: 35px;
  display: flex;
  align-items: center;
`;

const StyledButton = styled.button`
  min-width: 85px;
  height: 20px;
  border-radius: 10px;
  border: solid 1px ${GetColor.Primary.Dark};
  background-color: ${GetColor.White};
  font-size: 10px;
  color: ${GetColor.Primary.Dark};
  text-align: center;
  cursor: pointer;
  line-height: 1.6;
  &:hover {
    background-color: ${ColorUtils.opacifyFrom(GetColor.Primary.Dark, 0.1)};
    color: ${GetColor.Primary.Dark};
  }
  i {
    margin-right: 5px;
  }
  padding-left: 6px;
  padding-right: 6px;
`;
