import type { AnalysisSubjectType } from './types';
import type { AnalysisSubjectSecondaryLabel } from './AnalysisSubject';
import Dates from '../dates';
import { isNil } from 'lodash';

export type AnalysisLabelType =
  | 'Investment'
  | 'Current Portfolio'
  | 'Master Portfolio'
  | 'Optimized Portfolio'
  | 'Benchmark'
  | 'Category'
  | string;

export interface AnalysisLabelShape {
  main: AnalysisLabelType;
  comparison?: AnalysisLabelType;
  benchmark: AnalysisLabelType;
  category?: AnalysisLabelType;
}

export function getAnalysisLabels(
  mode?: AnalysisSubjectType,
  secondaryLabel?: AnalysisSubjectSecondaryLabel,
  lastSavedDate?: number,
): AnalysisLabelShape {
  if (mode === 'investment') {
    return {
      main: 'Investment',
      comparison: undefined,
      benchmark: 'Benchmark',
      category: 'Category',
    };
  }
  return {
    main: 'Current Portfolio',
    comparison: secondaryLabelToAnalysisLabel(secondaryLabel, lastSavedDate),
    benchmark: 'Benchmark',
    category: undefined,
  };
}

function secondaryLabelToAnalysisLabel(
  secondaryLabel?: AnalysisSubjectSecondaryLabel,
  lastSavedDate?: number,
): AnalysisLabelType | undefined {
  switch (secondaryLabel) {
    case undefined:
      return undefined;
    case 'Last Saved':
      return isNil(lastSavedDate) ? '--' : Dates.toDayMonthShortYearTime(lastSavedDate);

    case 'Optimized':
      return 'Optimized Portfolio';
    case 'Master':
    default:
      return 'Master Portfolio';
  }
}
