import { Label, isColor } from 'venn-ui-kit';
import React, { useCallback, useEffect, useState } from 'react';
import {
  ConditionalOverlay,
  Modal,
  ModalDescription,
  ModalFooter,
  ModalHeader,
  StatefulInput,
  ModalContent,
} from 'venn-components';
import styled from 'styled-components';
import type { ColorChangeHandler } from 'react-color';
import { ChromePicker } from 'react-color';

interface SponsorCustomizationModalProps {
  onSave: (displayName?: string, contactEmail?: string, colorScheme?: string) => void;
  loading: boolean;
  currentDisplayName?: string;
  currentContactEmail?: string;
  currentColorScheme?: string;
  onClose: () => void;
}

const SponsorCustomizationModal = ({
  onSave,
  loading,
  currentContactEmail,
  currentDisplayName,
  currentColorScheme,
  onClose,
}: SponsorCustomizationModalProps) => {
  const [displayName, setDisplayName] = useState(currentDisplayName);
  const [contactEmail, setContactEmail] = useState(currentContactEmail);

  // If color scheme is falsey (null included) we just use undefined, because the color picker library supports undefined but
  // does not support null.
  const [primaryGraphColor, setPrimaryGraphColor] = useState<string | undefined>(currentColorScheme || undefined);
  const [colorError, setColorError] = useState<string | undefined>();

  const onColorChange: ColorChangeHandler = useCallback((color) => setPrimaryGraphColor(color.hex), []);

  useEffect(() => {
    const error = isColor(primaryGraphColor) ? undefined : 'Primary color must be a valid color';
    setColorError(error);
  }, [primaryGraphColor]);

  return (
    <Modal>
      <ConditionalOverlay center condition={loading}>
        <ModalHeader>Edit Sponsor Details</ModalHeader>
        <ModalDescription>These details will appear for any accounts sponsored by you.</ModalDescription>
        <ModalContent>
          <FieldWrapper>
            <Label>Display name:</Label>
            <InputField value={displayName} onChange={setDisplayName} />
          </FieldWrapper>
          <FieldWrapper>
            <Label>Contact email:</Label>
            <InputField value={contactEmail} onChange={setContactEmail} />
          </FieldWrapper>
          <FieldWrapper>
            <Label>Primary color:</Label>
            <PickerWrapper>
              <ChromePicker color={primaryGraphColor} onChange={onColorChange} />
            </PickerWrapper>
          </FieldWrapper>
        </ModalContent>
        <ModalFooter
          onPrimaryClick={() => onSave(displayName, contactEmail, primaryGraphColor)}
          primaryLabel="Save"
          primaryDisabled={!!colorError}
          primaryTooltip={colorError}
          onCancel={onClose}
        />
      </ConditionalOverlay>
    </Modal>
  );
};

export default SponsorCustomizationModal;

const InputField = styled(StatefulInput)`
  display: flex;
`;

const FieldWrapper = styled.div`
  margin-top: 20px;
`;

const PickerWrapper = styled.div`
  margin-top: 10px;
`;
