import styled from 'styled-components';
import { GetColor } from '../../style/color';

export type ButtonSize = 'tiny' | 'small' | 'medium' | 'large';
const buttonWidth: Record<ButtonSize, string> = {
  tiny: '24px',
  small: '24px',
  medium: '36px',
  large: '40px',
};

const buttonHeight: Record<ButtonSize, string> = {
  tiny: '20px',
  small: '24px',
  medium: '24px',
  large: '40px',
};

const fontSize: Record<ButtonSize, string> = {
  tiny: '11px',
  small: '11px',
  medium: '12px',
  large: '12px',
};

export const StudioButton = styled.button<{ size: ButtonSize; color?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: ${({ size }) => buttonWidth[size]};
  height: ${({ size }) => buttonHeight[size]};
  font-size: ${({ size }) => fontSize[size]};
  padding: 6px;
  border-radius: 4px;
  border: 1px solid ${GetColor.GreyScale.Grey30};
  ${({ color }) => color && `color: ${color}`};
`;
