import React, { useCallback, useContext } from 'react';
import type { BulkActionProps } from './types';
import noop from 'lodash/noop';
import type { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { Routes } from 'venn-utils';
import { MAX_SUBJECTS, UserContext } from 'venn-components';
import { getAnalysisRouteForItem, getId } from '../utils';
import queryString from 'query-string';
import { TooltipPosition } from 'venn-ui-kit';
import { StyledButtonIcon } from './styles';

interface CompareItemsProps extends BulkActionProps, RouteComponentProps {}

const CompareItems = ({ items, onComplete, history }: CompareItemsProps) => {
  const { hasPermission } = useContext(UserContext);
  const hasAccessToAnalysisPage = hasPermission('ANALYSIS_PAGE');
  const hasAccessToCompare = hasPermission('COMPARE');

  const openComparison = useCallback(() => {
    const subjects = queryString.stringify({ subjects: items.map(getId) });
    onComplete('Compare', noop);
    history.push(`${Routes.ANALYSIS_COMPARE_PATH}?${subjects}`);
  }, [history, items, onComplete]);

  const openAnalysis = useCallback(() => {
    onComplete('Analyze', noop);
    history.push(getAnalysisRouteForItem(items[0]));
  }, [history, items, onComplete]);

  if (items.length === 1) {
    return hasAccessToAnalysisPage ? (
      <StyledButtonIcon
        className="qa-bulk-analyze"
        onClick={openAnalysis}
        iconType="calculator"
        tooltipPosition={TooltipPosition.Bottom}
        text={`Analyze (${items.length})`}
      />
    ) : null;
  }

  const maxItemsReached = items.length > MAX_SUBJECTS;
  const tooltipContent = maxItemsReached ? 'Maximum items reached.' : undefined;

  return hasAccessToCompare ? (
    <StyledButtonIcon
      className="qa-bulk-compare"
      onClick={openComparison}
      disabled={maxItemsReached}
      iconType="line-columns"
      tooltip={tooltipContent}
      tooltipPosition={TooltipPosition.Bottom}
      text={`Compare (${items.length})`}
    />
  ) : null;
};

export default withRouter(CompareItems);
