/*
 * Venn Platform API 1.0.0 Operations for "helparticles"
 * Automatically generated file - do not modify!
 */

import type { HelpArticle, HelpArticleQuery, HelpArticleSearchResult } from '../types';
import type { OperationResult } from '../../utils';
import { buildRequestInit, extractError, retryGeneratedFetch } from '../../utils';

/** Index all help articles */
export async function indexHelpArticles(signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = '/api/helparticles/index';
  const requestInit = buildRequestInit({ method: 'POST', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Query Intercom for all the help articles */
export async function getHelpArticles(signal?: AbortSignal): Promise<OperationResult<HelpArticle[]>> {
  const endpoint = '/api/helparticles/all';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: HelpArticle[] = (await response.json()) as HelpArticle[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Search help articles */
export async function searchHelpArticles(
  body?: Partial<HelpArticleQuery>,
  signal?: AbortSignal,
): Promise<OperationResult<HelpArticleSearchResult>> {
  const endpoint = '/api/helparticles/search';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await retryGeneratedFetch('POST', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: HelpArticleSearchResult = (await response.json()) as HelpArticleSearchResult;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
