import React from 'react';
import styled from 'styled-components';
import { GetColor } from '../../../style/color';
import { Link as LinkStyle } from '../../typography';

export interface SimpleMenuLinkProps {
  label: string;
  onClick: () => void;
}

const SimpleMenuLink = ({ label, onClick }: SimpleMenuLinkProps) => (
  <Item className="qa-dropmenu-link" onClick={onClick}>
    <LinkStyle>{label}</LinkStyle>
  </Item>
);

const Item = styled.div`
  height: 50px;
  flex: 0 0 50px;
  padding-left: 9px;
  display: flex;
  align-items: center;
  border-top: 1px solid ${GetColor.PaleGrey};
  cursor: pointer;
  font-size: 14px;
  &:hover span {
    color: ${GetColor.Primary.Main};
  }
`;

export default SimpleMenuLink;
