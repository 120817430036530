import { compact, uniqBy } from 'lodash';
import type { Analysis, NotablePeriod } from 'venn-api';

export const getAllPredefinedPeriods = (analysesGroup: (Analysis | undefined)[][]): NotablePeriod[] =>
  uniqBy(
    analysesGroup?.flatMap((analysisList: (Analysis | undefined)[]) =>
      compact(
        analysisList &&
          analysisList
            .filter((analysis: Analysis | undefined) => analysis?.analysisType === 'NOTABLE_PERIODS')
            .flatMap((analysis: Analysis | undefined) =>
              analysis?.notablePeriods?.flatMap((periods: NotablePeriod[]) =>
                periods?.filter((period: NotablePeriod) => period.type === 'PREDEFINED'),
              ),
            ),
      ),
    ),
    'id',
  );
