import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import type { AnalysisBlockProps } from '../../types';
import ComparisonTable from '../ComparisonTable';
import ActionBar from './ActionBar';
import type { BasicTableColumn } from 'venn-components';
import { ColumnAlign } from 'venn-components';
import CorrelationColorLegend from './CorrelationColorLegend';
import { compact } from 'lodash';

const PairwiseCorrelation: React.FC<React.PropsWithChildren<AnalysisBlockProps>> = ({
  subjects,
  analysis,
  relative,
  extractor,
  factorLens,
  setHovered,
  isLoading,
  isPrinting,
  fetchingInBackground,
  cellRenderer,
}) => {
  const data = extractor(subjects, analysis, relative, factorLens);
  const { Colors } = useContext(ThemeContext);

  const getColumns = (): BasicTableColumn<unknown>[] =>
    compact([
      {
        label: 'Correlation',
        accessor: 'label',
      },
      ...subjects.map((subject, colNum) =>
        subject.isBenchmark && relative
          ? null
          : {
              label: isPrinting ? subject.analysisSubject?.name : undefined,
              align: ColumnAlign.RIGHT,
              cellStyle: {
                backgroundColor: subject.isHovered ? Colors.WhiteGrey : undefined,
              },
              cellRenderer: cellRenderer(isLoading, colNum, 0, undefined, relative, subject.isHovered && !isPrinting),
              cellProps: () => {
                return {
                  onMouseEnter: () => setHovered(colNum, true),
                  onMouseLeave: () => setHovered(colNum, false),
                };
              },
            },
      ),
      isPrinting
        ? undefined
        : {
            // Empty cell to fill remaining space
          },
    ]);
  return (
    <>
      <StyledComparisonTable
        columns={getColumns()}
        data={data}
        isPrinting={isPrinting}
        fetchingInBackground={fetchingInBackground}
      />
      <ActionBar
        labelContent={<CorrelationColorLegend />}
        subjects={subjects}
        setHovered={setHovered}
        relative={relative}
      />
    </>
  );
};

const StyledComparisonTable = styled(ComparisonTable)`
  tr:hover {
    div.correlation-cell {
      opacity: 1;
    }
  }
  tbody > tr > td {
    padding: 0;
  }
`;

export default PairwiseCorrelation;
