import React from 'react';
import { useSetRecoilState } from 'recoil';
import {
  forecastPanelViewSelector,
  ForecastTab,
  investmentOverrideSearchQuery,
  investmentOverrideShouldScrollToAddForm,
  selectedInvestmentOverrideAtomSyncedWithOverrideType,
} from 'venn-state';
import { ButtonIcon, TooltipPosition } from 'venn-ui-kit';
import { useHasFF } from 'venn-utils';
import type { MenuOpenerProps } from './ForecastsMenuLauncher';

interface ForecastsMenuLinkProps extends MenuOpenerProps {
  tooltip: string;
}

const ForecastsMenuLink = ({ onClick, isReadOnly, openResidualForecastTarget, tooltip }: ForecastsMenuLinkProps) => {
  const hasRedesignFF = useHasFF('forecast_panel_redesign_ff');

  const operation = hasRedesignFF ? (openResidualForecastTarget?.isManualOverride ? 'EDIT' : 'CREATE') : 'EDIT';

  const setView = useSetRecoilState(forecastPanelViewSelector);
  const setSelectedResidualOverride = useSetRecoilState(
    selectedInvestmentOverrideAtomSyncedWithOverrideType(operation),
  );
  const setSearchQuery = useSetRecoilState(investmentOverrideSearchQuery);
  const setShouldScrollToAddForm = useSetRecoilState(investmentOverrideShouldScrollToAddForm);

  return (
    <ButtonIcon
      iconType="compass"
      onClick={() => {
        setView({
          tab: ForecastTab.InvestmentForecast,
          detail: {
            type: hasRedesignFF ? 'Empty' : 'InvestmentOverrideEditor',
          },
        });
        if (!isReadOnly) {
          setSelectedResidualOverride(openResidualForecastTarget);
          hasRedesignFF && operation === 'EDIT' && setSearchQuery(openResidualForecastTarget?.fundName ?? '');
          hasRedesignFF && operation === 'CREATE' && setShouldScrollToAddForm(true);
        }
        onClick();
      }}
      tooltip={tooltip}
      tooltipPosition={TooltipPosition.Top}
      className="qa-forecast-override-link"
      size={15}
    />
  );
};

export default ForecastsMenuLink;
