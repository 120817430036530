import type { SetterOrUpdater, RecoilValue, RecoilState } from 'recoil';
import { useRecoilValueLoadable, useRecoilStateLoadable } from 'recoil';
import { useRef } from 'react';

const emptyResultSymbol = Symbol('emptyResult');
/**
 * Like `useRecoilValue`, but when the value has been updated, it keeps returning the previous value
 * throughout the time of the update.
 *
 * @param recoilState - the recoil atom or selector,
 * @param defaultValue - the value to return before initial load
 */
export function useCachedLoadableValue<T>(recoilState: RecoilValue<T>, defaultValue: T): T;
export function useCachedLoadableValue<T>(recoilState: RecoilValue<T>): T | undefined;
export function useCachedLoadableValue<T>(recoilState: RecoilValue<T>, defaultValue?: T): T | undefined {
  const previousResultRef = useRef<typeof defaultValue | typeof emptyResultSymbol>(emptyResultSymbol);

  const loadable = useRecoilValueLoadable(recoilState);
  const value =
    loadable.state === 'hasValue'
      ? loadable.getValue()
      : previousResultRef.current !== emptyResultSymbol
        ? previousResultRef.current
        : defaultValue;

  previousResultRef.current = value;

  return value;
}

/**
 * Like `useRecoilState`, but when the value has been updated, it keeps returning the previous value
 * throughout the time of the update.
 *
 * @param recoilState - the recoil atom or selector`,
 * @param defaultValue - the value to return before initial load
 */
export function useCachedLoadableState<T>(recoilState: RecoilState<T>, defaultValue: T): [T, SetterOrUpdater<T>];
export function useCachedLoadableState<T>(
  recoilState: RecoilState<T | undefined>,
): [T | undefined, SetterOrUpdater<T | undefined>];
export function useCachedLoadableState<T>(
  recoilState: RecoilState<T | undefined>,
  defaultValue?: T,
): [T | undefined, SetterOrUpdater<T | undefined>] {
  const [loadable, setRecoilState] = useRecoilStateLoadable(recoilState);
  const previousResultRef = useRef<typeof defaultValue | typeof emptyResultSymbol>(emptyResultSymbol);

  const value =
    loadable.state === 'hasValue'
      ? loadable.getValue()
      : previousResultRef.current !== emptyResultSymbol
        ? previousResultRef.current
        : defaultValue;

  previousResultRef.current = value;

  return [value, setRecoilState];
}
