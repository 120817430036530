import type { ReactNode } from 'react';
import React from 'react';
import styled from 'styled-components';
import { COLUMN_WIDTH, LABEL_COLUMN_WIDTH } from '../../constants';
import { ButtonLink, GetColor, Icon, Tooltip, TooltipPosition } from 'venn-ui-kit';
import type { ComparisonSubject } from 'venn-utils';
import { getAnalysisPath, getPortfolioLabPath, subjectIdWithBenchmark, useHasFF } from 'venn-utils';
import { AnalyzeInvestmentModalsLauncher } from 'venn-components';

interface ActionBarProps {
  subjects: ComparisonSubject[];
  setHovered: (idx: number, isHovered: boolean) => void;
  relative: boolean;
  labelContent?: ReactNode;
}

const ActionBar: React.FC<React.PropsWithChildren<ActionBarProps>> = ({
  subjects,
  setHovered,
  relative,
  labelContent,
}) => {
  const hasOptimization = useHasFF('optimization');
  return (
    <ActionBarWrapper>
      <LabelColumn>{labelContent}</LabelColumn>
      {subjects.map((subject, idx) => {
        if (!subject.analysisSubject) {
          // eslint-disable-next-line react/no-array-index-key
          return <SubjectColumn key={idx} />;
        }
        if (subjects?.[idx]?.isBenchmark && relative) {
          return null;
        }
        const type = subject.analysisSubject.type;
        return (
          <SubjectColumn
            key={subjectIdWithBenchmark(subject, idx)}
            onMouseEnter={() => setHovered(idx, true)}
            onMouseLeave={() => setHovered(idx, false)}
          >
            {hasOptimization ? (
              <SplitButtons hidden={!subject.isHovered}>
                <Tooltip content="Analyze">
                  <ButtonLink
                    linkText={<Icon type="calculator" />}
                    to={getAnalysisPath(type, subject.analysisSubject.id)}
                    className="qa-analyze-button"
                    ctaTrackingOptions={{
                      ctaText: 'calculator icon',
                      ctaPurpose: `Analyze ${subject.analysisSubject.type}`,
                      ctaDestination: 'Analysis Results',
                    }}
                  />
                </Tooltip>
                {subject.analysisSubject.type === 'portfolio' ? (
                  <Tooltip content="Optimize with Portfolio Lab">
                    <ButtonLink
                      linkText={<Icon type="flask" />}
                      to={getPortfolioLabPath(subject.analysisSubject.portfolio!.id)}
                      className="qa-optimize-button"
                      ctaTrackingOptions={{
                        ctaText: 'flask icon',
                        ctaPurpose: 'Optimize portfolio in Portfolio Lab',
                        ctaDestination: 'Portfolio Lab',
                      }}
                    />
                  </Tooltip>
                ) : (
                  <AnalyzeInvestmentModalsLauncher
                    subject={subject.analysisSubject}
                    size={24}
                    tooltipPosition={TooltipPosition.Top}
                    Wrapper={OptimizeInvestmentPseudoLink}
                  />
                )}
              </SplitButtons>
            ) : (
              <AnalyzeLink
                linkText="Analyze"
                to={getAnalysisPath(type, subject.analysisSubject.id)}
                hidden={!subject.isHovered}
                className="qa-analyze-button"
              />
            )}
          </SubjectColumn>
        );
      })}
    </ActionBarWrapper>
  );
};

const ActionBarWrapper = styled.div`
  display: flex;
  height: 24px;
  max-height: 24px;
`;

const LabelColumn = styled.div`
  display: inline-block;
  padding: 10px 18px;
  width: ${LABEL_COLUMN_WIDTH}px;
`;

const SubjectColumn = styled.div`
  display: inline-block;
  width: ${COLUMN_WIDTH}px;
  height: 100%;
`;

const AnalyzeLink = styled(ButtonLink)<{ hidden: boolean }>`
  button {
    height: 100%;
    min-height: 24px;
    padding: 0;
    width: 100%;
    ${(props) => (props.hidden ? 'display: none;' : '')};
    @media print {
      display: none;
    }
  }
`;

const SplitButtons = styled.div<{ hidden: boolean }>`
  display: ${(props) => (props.hidden ? 'none' : 'flex')};
  width: 100%;
  button {
    height: 100%;
    min-height: 24px;
    padding: 0;
    width: 50%;
    min-width: 70px;
    @media print {
      display: none;
    }
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  & > :not(:last-child) {
    button {
      border-bottom-right-radius: 0;
    }
  }
  & > :not(:first-child) {
    button {
      border-bottom-left-radius: 0;
    }
  }
`;

const OptimizeInvestmentPseudoLink = styled.div`
  i {
    font-size: 11px;
  }
  span[role='button'] {
    min-width: 70px;
    border: 1px solid ${GetColor.Primary.Dark};
    border-radius: 0 0 2px 0;
  }
`;

export default ActionBar;
