import React, { useMemo, useCallback } from 'react';
import type { Analysis, PerformanceSummary, CustomizedBlock } from 'venn-api';
import type { DragStoppedEvent } from '../../data-grid';
import { compact } from 'lodash';
import { AVAILABILITY_KEY, FREQUENCY_KEY, BENCHMARK_KEY, ITALIC_CLASS } from '../customAnalysisContants';
import type { AnalysisRequest, AnalysisResponseParser, PortfolioRowData } from '../types';
import {
  getAvailableRangeValue,
  getMaxFrequencyValue,
  getBenchmarkNameRangeMap,
  handleMetricsReorder,
} from './customBlockUtils';
import { getGroupColDef } from './getGroupColDef';
import type { CellClassParams, ColDef } from 'ag-grid-community';
import { getRangeMapKeyFromRequest, useRequestsRangeMap } from './useRequestsRangeMap';
import { useTheme } from 'styled-components';
import { autoGroupColumnDefStaticConstants, useAgGridStyle } from '../components/grid/AgGridThemeOverrides';
import { useMemoWithArgs, FootnoteSymbols } from 'venn-utils';
import { useLabelColumnWidth } from './useLabelColumnMaxWidth';
import { constSelector, useRecoilValue } from 'recoil';
import { blockBenchmarkType, flattenedProxyMapState, type BlockId, isReturnsBasedBlockState } from 'venn-state';

const autoColumnCellClass = ({ data }: CellClassParams) => compact(data.isMetadata ? ITALIC_CLASS : undefined);

const emptyObjectConstSelector = constSelector({});

const useAnalysisTreeGrid = (
  requests: AnalysisRequest[],
  responseParser: AnalysisResponseParser,
  analyses: (Analysis | undefined)[][],
  blockId: BlockId,
  selectedBlock: CustomizedBlock,
  onReorderMetrics: (newMetricsOrder: string[]) => void,
  inPrintMode?: boolean,
) => {
  const customBenchmarkType = useRecoilValue(blockBenchmarkType(blockId));
  const rangeMap = useRequestsRangeMap(requests);
  const isReturnsBasedBlock = useRecoilValue(isReturnsBasedBlockState(blockId));
  const proxyMap = useRecoilValue(isReturnsBasedBlock ? flattenedProxyMapState : emptyObjectConstSelector);
  const theme = useTheme();

  const rowData = useMemo(() => {
    const subjectIds = requests.map(getRangeMapKeyFromRequest);
    const rows: PortfolioRowData<PerformanceSummary | string | undefined>[] = inPrintMode
      ? []
      : compact([
          {
            label: AVAILABILITY_KEY,
            key: AVAILABILITY_KEY,
            path: [AVAILABILITY_KEY],
            value: getAvailableRangeValue(rangeMap, subjectIds),
            isStrategy: false,
            allocation: [],
            isMetadata: true,
          },
          {
            label: FREQUENCY_KEY,
            key: FREQUENCY_KEY,
            path: [FREQUENCY_KEY],
            value: getMaxFrequencyValue(rangeMap, subjectIds),
            isStrategy: false,
            allocation: [],
            isMetadata: true,
          },
          customBenchmarkType === 'COMMON' || customBenchmarkType === 'INDIVIDUAL'
            ? {
                label: BENCHMARK_KEY,
                key: BENCHMARK_KEY,
                path: [BENCHMARK_KEY],
                value: requests.map((request) => request.benchmark?.name),
                customBenchmarkType,
                benchmarkNameRangeMap: getBenchmarkNameRangeMap(rangeMap, requests),
                isStrategy: false,
                allocation: [],
                isMetadata: true,
              }
            : undefined,
        ]);
    return [...rows, ...responseParser.parser(selectedBlock, analyses)];
  }, [requests, inPrintMode, rangeMap, customBenchmarkType, responseParser, selectedBlock, analyses]);

  const metricsSettings = responseParser.metricsSettings;

  const gridStyle = useAgGridStyle();
  // TODO(VENN-19715): would like to make getGroupColDef a hook, but it is currently used imperatively by the metricsSettingsColumnParser.
  const columnDefs = useMemoWithArgs(getGroupColDef, [
    requests,
    analyses,
    metricsSettings,
    theme,
    gridStyle,
    selectedBlock?.relativeToBenchmark,
    selectedBlock?.contributionToPercentage,
    selectedBlock?.selectedMetrics,
    selectedBlock?.selectedFactors,
  ]);

  const onDragStoped = useCallback(
    (e: DragStoppedEvent) => {
      handleMetricsReorder(e, onReorderMetrics, columnDefs);
    },
    [columnDefs, onReorderMetrics],
  );

  const { minWidth: labelColumnMinWidth, maxWidth: labelColumnMaxWidth } = useLabelColumnWidth(columnDefs);

  const autoGroupColumnDef = useMemo(
    (): ColDef => ({
      ...autoGroupColumnDefStaticConstants,
      minWidth: labelColumnMinWidth,
      maxWidth: labelColumnMaxWidth,
      cellClass: autoColumnCellClass,
      cellRenderer: ({ data }: { data: PortfolioRowData<PerformanceSummary | string | undefined> }) =>
        !data.fundId ? data.label : [data.label, proxyMap?.[data.fundId] && <sup>{FootnoteSymbols.proxyUsage}</sup>],
      autoHeight: true,
    }),
    [labelColumnMaxWidth, labelColumnMinWidth, proxyMap],
  );
  return {
    rowData,
    columnDefs,
    autoGroupColumnDef,
    onDragStoped,
  };
};

export default useAnalysisTreeGrid;
