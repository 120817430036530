import React, { useEffect, useContext, useState, useCallback } from 'react';
import styled from 'styled-components';
import type { PortfolioPolicy } from 'venn-api';
import { AllocationConstraintsManagement, EmptyState, PortfolioLabContext, ConfirmationModal } from 'venn-components';
import { Headline2, Subtitle1, Loading } from 'venn-ui-kit';
import PaleVennTriangleIcon from './paleVennTriangleIcon.png';
import type { AllocConstraint } from 'venn-utils';
import { analyticsService, useModal, splitAllocationConstraints } from 'venn-utils';
import { isNil, isEmpty } from 'lodash';
import InputsSectionHeaderWithClearAll from './InputsSectionHeaderWithClearAll';
import ConstraintsPolicyStatusFooter from './ConstraintsPolicyStatusFooter';

const addNewClassName = 'allocation-constraints-add-new';

interface AllocationConstraintsProps {
  isSideBarMaximized: boolean;
  isInEditState: boolean;
  setIsInEditState: React.Dispatch<React.SetStateAction<boolean>>;
}

const AllocationConstraints = ({ isSideBarMaximized, isInEditState, setIsInEditState }: AllocationConstraintsProps) => {
  const {
    portfolio,
    policy,
    loadingPolicy,
    policyError,
    policyAllocationConstraints,
    allocationConstraints,
    onUpdateAllocationConstraints,
    onApplyAllocationConstraintsToPolicy,
  } = useContext(PortfolioLabContext);
  const [isConfirmationModalOpen, openConfirmationModal, closeConfirmationModal] = useModal();
  const [constraints, setConstraintsState] = useState<AllocConstraint[]>(allocationConstraints);
  const [isAddingConstraint, setIsAddingConstraint] = useState(false);
  const [isSavingConstraint, setIsSavingConstraint] = useState(false);

  const setConstraints = useCallback(
    (setNewConstraints: AllocConstraint[] | ((prevConstraints: AllocConstraint[]) => AllocConstraint[])) => {
      !isInEditState && setIsInEditState(true);

      if (typeof setNewConstraints === 'function') {
        setConstraintsState((prevState) => setNewConstraints(prevState));
      } else {
        setConstraintsState(setNewConstraints);
      }
    },
    [isInEditState, setIsInEditState],
  );

  const onNewConstraintsAdded = useCallback(
    (newConstraints: AllocConstraint[]) => {
      setConstraints((prevConstraints = []) => [...prevConstraints, ...newConstraints]);
      setIsAddingConstraint(false);
    },
    [setConstraints],
  );

  const onUpdateAndTrackConstraints = useCallback(
    (savedConstraints: AllocConstraint[]) => {
      if (!portfolio?.id) {
        return;
      }

      onUpdateAllocationConstraints(savedConstraints);
      analyticsService.portfolioLabConstraintsModified({
        originPage: 'Portfolio Lab',
        locationOnPage: 'allocation constraints editor',
        constraintType: 'allocation',
        allocationConstraintsCount: savedConstraints.length,
        constraintsMatchPolicy: getConstraintsMatchPolicy(policy, savedConstraints),
        portfolioId: portfolio.id,
      });
    },
    [onUpdateAllocationConstraints, policy, portfolio?.id],
  );

  useEffect(() => {
    if (isSavingConstraint && portfolio?.id) {
      const {
        portfolio: portfolioConstraints,
        strategy,
        investment,
      } = splitAllocationConstraints(constraints, portfolio.id);
      const savedConstraints = [...portfolioConstraints, ...strategy, ...investment];

      onUpdateAndTrackConstraints(savedConstraints);

      setIsSavingConstraint(false);
      setIsAddingConstraint(false);
    }
  }, [constraints, isSavingConstraint, onUpdateAndTrackConstraints, portfolio?.id]);

  const onConstraintsSave = () => {
    if (!portfolio?.id) {
      return;
    }

    setIsInEditState(false);
    setIsSavingConstraint(true);
  };

  const onConstraintsRevert = () => {
    setIsInEditState(false);
    setConstraints(allocationConstraints);
  };

  const onApplyToPolicy = () => {
    const newPolicyConstraints = constraints.map((constraint) =>
      constraint.isNew ? constraint : { ...constraint, isInPortfolioPolicy: true },
    );

    onApplyAllocationConstraintsToPolicy(newPolicyConstraints);
    setConstraintsState(newPolicyConstraints);
  };

  if (loadingPolicy || isNil(portfolio)) {
    return <Loading title="Loading portfolio policy" />;
  }

  if (policyError) {
    return <EmptyState header="Failed to load constraints from portfolio policy" message="Please try again later" />;
  }
  const isEmptyState = constraints.length === 0;
  const Container = isEmptyState ? NoConstraintsBackground : ConstraintsContainer;

  const matchesPolicy = getConstraintsMatchPolicy(policy, constraints);

  return (
    <WrapperWithFooter>
      <Container className="qa-allocation-constraints">
        {isEmptyState ? (
          <>
            <Headline2>Add constraints to refine your results</Headline2>
            <Subtitle1>
              Constraints can help you achieve optimization results that better meet your portfolio objectives and
              allocation restrictions. Get started by adding your first constraints.
            </Subtitle1>
          </>
        ) : (
          <>
            <InputsSectionHeaderWithClearAll
              isSideBarMaximized={isSideBarMaximized}
              text="Set allocation constraints for your strategies and investments."
              clearAllLabel="Clear All Allocation Constraints"
              onClearAll={() => openConfirmationModal()}
              canClearAll={constraints.length > 0}
            />
            {isConfirmationModalOpen ? (
              <ConfirmationModal
                className="clear-all-constraints-modal"
                header="Clear all constraints?"
                subhead="This action cannot be undone. To save these constraints, save them as your Portfolio Policy."
                proceedLabel="clear all constraints"
                onCancel={closeConfirmationModal}
                onProceed={() => {
                  if (isInEditState && isEmpty(allocationConstraints)) {
                    onConstraintsRevert();
                    closeConfirmationModal();

                    return;
                  }
                  setConstraintsState([]);
                  onConstraintsSave();
                  closeConfirmationModal();
                }}
              />
            ) : null}
          </>
        )}
        <AllocationConstraintsManagement
          portfolio={portfolio}
          allocationConstraints={constraints}
          addNewConstraintClassName={addNewClassName}
          onNewConstraintsAdded={onNewConstraintsAdded}
          setConstraints={setConstraints}
          isAddingConstraint={isAddingConstraint}
          setIsAddingConstraint={setIsAddingConstraint}
          isSavingConstraint={isSavingConstraint}
        />
      </Container>

      <ConstraintsPolicyStatusFooter
        portfolio={portfolio}
        matchesPolicy={matchesPolicy}
        isSideBarMaximized={isSideBarMaximized}
        onResetToPolicyConstraints={() => {
          setConstraints(policyAllocationConstraints);
          analyticsService.portfolioLabConstraintsModified({
            originPage: 'Portfolio Lab',
            locationOnPage: 'allocation constraints editor',
            constraintType: 'allocation',
            allocationConstraintsCount: (policy?.constraints ?? []).filter(
              (constraint) => constraint.constraintType === 'ALLOCATION',
            ).length,
            constraintsMatchPolicy: true,
            portfolioId: portfolio.id,
          });
        }}
        onApplyToPolicy={onApplyToPolicy}
        onConstraintsSave={onConstraintsSave}
        onConstraintsRevert={onConstraintsRevert}
        isInEditState={isInEditState}
        hasConstraintsRevamp
      />
    </WrapperWithFooter>
  );
};

export default AllocationConstraints;

const getConstraintsMatchPolicy = (policy: PortfolioPolicy | undefined, constraints: AllocConstraint[]): boolean => {
  const hasUnsavedConstraints = constraints.some((constraint) => !constraint.isNew && !constraint.isInPortfolioPolicy);
  const hasDeletedConstraints =
    (policy?.constraints ?? []).filter((constraint) => constraint.constraintType === 'ALLOCATION').length >
    constraints.filter((constraint) => !constraint.isNew).length;

  return !hasUnsavedConstraints && !hasDeletedConstraints;
};

const WrapperWithFooter = styled.div`
  position: relative;
  padding: 20px 20px 60px 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const NoConstraintsBackground = styled.div`
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  background-image: url(${PaleVennTriangleIcon});
  background-size: 242px;
  background-repeat: no-repeat;
  background-position: center;

  & h2,
  h4 {
    max-width: 560px;
  }

  & > :last-child {
    margin-top: 20px;
  }
`;

const ConstraintsContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  & > div:not(:first-child) {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .${addNewClassName} {
      margin-top: auto;
    }
  }
`;
