import React, { useState } from 'react';
import styled from 'styled-components';
import { analyticsService, isMobile, userActivity } from 'venn-utils';
import {
  Body1,
  ButtonLink,
  ColorUtils,
  getAppTitle,
  GetColor,
  Headline2,
  Icon,
  BrandLogo,
  ZIndex,
  getTextThemeProvider,
} from 'venn-ui-kit';

export const LOCAL_STORAGE_KEY = 'mobile_warning_dismissed';

const MobileWarning = () => {
  const [isWarningDismissed, setWarningDismissed] = useState(
    userActivity.getLocalStorageItemPerUser(LOCAL_STORAGE_KEY) === 'true',
  );

  const continueText = 'Continue with the mobile experience';
  const dismissWarning = () => {
    analyticsService.ctaClicked({
      text: continueText,
      purpose: `Continue using ${getAppTitle()} on a mobile device`,
      type: 'text',
      filled: false,
    });
    userActivity.setLocalStorageItemPerUser(LOCAL_STORAGE_KEY, 'true');
    setWarningDismissed(true);
  };

  const visitBlogText = 'Visit the blog instead';
  const ctaTrackingOpts = {
    ctaDestination: 'Blog',
    ctaPurpose: 'Send to a better mobile experience',
    ctaText: visitBlogText,
  };

  return isMobile() && !isWarningDismissed ? (
    <Overlay>
      <BrandLogo height={40} />
      <Headline2 center inverted>
        {`${getAppTitle()} works best on Chrome for desktop`}
      </Headline2>
      <Body1 center inverted>
        {`Get the most value from ${getAppTitle()}'s investment and portfolio insights by logging in from your desktop.`}
      </Body1>

      <Actions>
        <ButtonLink
          dominant
          url={getTextThemeProvider().MarcomSiteLinks.VENNSIGHTS_URL}
          linkText={visitBlogText}
          ctaTrackingOptions={ctaTrackingOpts}
          className="qa-mobile-visit-blog"
        />
        <StyledDismiss type="button" onClick={dismissWarning} className="qa-mobile-dismiss-warning">
          {continueText}
          <ProceedIcon type="chevron-right" />
        </StyledDismiss>
      </Actions>
    </Overlay>
  ) : null;
};
export default MobileWarning;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 0 30px;
  background-color: ${ColorUtils.hex2rgbaFrom(GetColor.Black, 0.9)};
  z-index: ${ZIndex.Cover};
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
`;

const StyledDismiss = styled.button`
  margin-top: 15px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.71;
  color: ${GetColor.Primary.Main};
`;

const ProceedIcon = styled(Icon)`
  margin-left: 7.5px;
`;
