import type { AnalysesResults, AnalysisSubject, ExcelCell } from 'venn-utils';
import { FS, getSecondaryDisplayLabel, Numbers } from 'venn-utils';
import type { Row } from './types/row';
import { statistics } from './statistics';
import { isNil } from 'lodash';
import type { BasicTableColumn } from 'venn-components';
import { ColumnAlign } from 'venn-components';

const formatNumber = (value: number | null) => Numbers.safeFormatNumber(value, 2);
const formatPercentage = (value: number | null) => Numbers.safeFormatPercentage(value, 1);

const cellRenderer = (accessor: string) => (row: Row) => {
  return row.percentage ? formatPercentage(row[accessor]) : formatNumber(row[accessor]);
};

const excelCellRenderer =
  (accessor: string) =>
  (row: Row): ExcelCell => {
    return {
      value: row[accessor],
      percentage: row.percentage,
      digits: row.percentage ? 1 : 2,
    };
  };

const buildColumn = (label: string, accessor: string): BasicTableColumn<Row> => ({
  label,
  accessor,
  align: ColumnAlign.RIGHT,
  cellRenderer: cellRenderer(accessor),
  excelCellRenderer: excelCellRenderer(accessor),
});

export const getColumns = (
  hasBaselineColumn: boolean,
  hasBenchmarkColumn: boolean,
  hasCategoryColumn: boolean,
  subject: AnalysisSubject,
): BasicTableColumn<Row>[] => {
  const columns: BasicTableColumn<Row>[] = [
    {
      label: 'Metrics',
      accessor: 'name',
      align: ColumnAlign.LEFT,
    },
    buildColumn(subject.type === 'portfolio' ? 'Current Portfolio' : 'Investment', 'subject'),
  ];
  if (hasBaselineColumn) {
    columns.push(buildColumn(getSecondaryDisplayLabel(subject, undefined, 'Portfolio'), 'category'));
  }
  if (hasBenchmarkColumn) {
    columns.push(buildColumn('Benchmark', 'benchmark'));
  }
  if (hasCategoryColumn) {
    columns.push(buildColumn(`Category (${subject.categoryGroup?.name ?? ''})`, 'category'));
  }
  columns.push({
    label: '',
    accessor: undefined,
    align: ColumnAlign.LEFT,
  });
  return columns;
};

export const getRows = (
  hasBaselineColumn: boolean,
  hasBenchmarkColumn: boolean,
  hasCategoryColumn: boolean,
  analyses?: AnalysesResults,
): Row[] => {
  const results = analyses?.results?.performance?.historical;
  if (!results?.subject) {
    return [];
  }
  return statistics
    .filter((stat) => !stat.featureFlag || FS.has(stat.featureFlag))
    .map((stat) => ({
      name: stat.name,
      description: stat.description,
      learnMoreUrl: stat.learnMoreUrl,
      subject: stat.getter(results.subject!),
      category:
        (hasBaselineColumn || hasCategoryColumn) && !isNil(results.category)
          ? stat.getter(results.category)
          : undefined,
      benchmark: hasBenchmarkColumn && results.benchmark ? stat.getter(results.benchmark) : undefined,
      percentage: !!stat.percentage,
    }));
};
