import profile from './profile';
import type { RecentUserActivity } from 'venn-api';

const activities = {
  recentOrganizationInvestments: [
    {
      id: 'c28b80fa-0ecf-47f8-82de-dfe5a55a06d7',
      name: 'ZFirstFund333',
      owner: profile,
      created: 1537535980255,
      updated: 1537535980255,
      fundSourceType: {
        id: 1,
        name: 'Fund',
      },
      userUploaded: true,
      benchmarks: [],
      tags: [
        {
          id: -1,
          name: 'My uploads',
          systemTag: true,
        },
      ],
      active: true,
      assetType: 'UPLOADED_INSTRUMENT',
      remoteType: 'LOCAL',
      currency: 'USD',
    },
  ],
  recentUserInvestments: [
    {
      id: 'd02a413b-f0cf-4f88-a31c-ec1f0b905950',
      name: 'ShortFund',
      owner: profile,
      created: 1536672953900,
      updated: 1536672953900,
      fundSourceType: {
        id: 1,
        name: 'Fund',
      },
      userUploaded: true,
      benchmarks: [],
      tags: [
        {
          id: -1,
          name: 'My uploads',
          systemTag: true,
        },
      ],
      active: true,
      assetType: 'UPLOADED_INSTRUMENT',
      remoteType: 'LOCAL',
      currency: 'USD',
    },
  ],
  recentOrganizationPortfolios: [
    {
      id: 143446,
      name: 'VENN-7780',
      baselinePortfolio: {
        portfolioId: 1,
        benchmark: false,
      },
      baselineActive: true,
      owner: profile,
      updatedBy: profile,
      allocation: 0.0,
      nodeFactorLensId: 2,
      updated: 1537551121480,
      order: 1,
      master: false,
      draft: false,
      editable: true,
      children: [],
      compare: [],
      messages: [],
      locked: false,
      rebalanceStrategy: 3,
    },
  ],
  recentUserPortfolios: [],
};

export default activities as unknown as RecentUserActivity;
