import React, { useMemo } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import type { DrawdownRangeAnalysis } from 'venn-api';
import { Headline2, Subtitle1, GetColor, ZIndex } from 'venn-ui-kit';
import type { AnalysisSubject } from 'venn-utils';
import CorrelationsChart from './charts/CorrelationsChart';
import ReturnsChart from './charts/ReturnsChart';
import { DRAWDOWN_DATE_FORMAT, doCorrelationsExist } from './utils';
import DownloadableContentBlock from '../../../../content-block/DownloadableContentBlock';
import { factorCorrelationsToExcel, factorReturnsToExcel } from './toExcel';
import { INVESTMENT_COLUMN_WIDTH } from '../../../../correlation-chart/BaseCorrelationChart';
import type {
  Entity as CorrelationsChartEntity,
  DrawdownCorrelation,
} from '../../../../factor-correlations-chart/types';
import { isNil } from 'lodash';
import type { DownloadMetaData } from '../../../../downloadable';

// In order to properly size the columns we have to use calc() as
// HighCharts doesn't play nice with flexbox. Therefore we have to
// calculate the correlation chart dims here so the other chart
// styles can know about it.
const CORRELATIONS_CELL_SIZE = 32;
const NOCORRELATIONS_WIDTH = 610;
const RETURNS_MIN_WIDTH = 576;
const MIN_BASE_WIDTH = 1050;
const BREAK_POINT_WITHOUT_CORRELATION = MIN_BASE_WIDTH + NOCORRELATIONS_WIDTH;

const qaClasses = {
  marketEnvironment: 'qa-market-environment',
  factorReturns: 'qa-factor-returns',
  factorCorrelations: 'qa-factor-correlations',
};

export interface MarketEnvironmentProps {
  data: DrawdownRangeAnalysis;
  subject: AnalysisSubject;
  print?: boolean;
  downloadMetaData?: DownloadMetaData;
}

const MarketEnvironment = ({ data, subject, print, downloadMetaData }: MarketEnvironmentProps) => {
  const { drawdownFactorInfoList, portfolioDrawdown, maxDrawdownDate } = data;

  const start = portfolioDrawdown?.[0][0];
  const trough = maxDrawdownDate;
  const startDate = isNil(start) ? undefined : moment.utc(start);
  const noCorrelations = !doCorrelationsExist(data.drawdownFactorInfoList);
  const correlationsData: CorrelationsChartEntity<DrawdownCorrelation>[] | undefined = useMemo(() => {
    if (!start || !data) {
      return [];
    }
    return !noCorrelations
      ? data.drawdownFactorInfoList.map((factorData) => ({
          id: factorData.factorId.toString(),
          name: factorData.factorName,
          correlations: factorData.factorDeltaCorrelations.map((correlation) => ({
            ...correlation,
            id: correlation.id.toString(),
            value: correlation.delta,
          })),
        }))
      : undefined;
  }, [noCorrelations, data, start]);

  const periodAsString = `${startDate?.format(DRAWDOWN_DATE_FORMAT) ?? '--'} – ${moment
    .utc(trough)
    .format(DRAWDOWN_DATE_FORMAT)}`;
  const factorCorrelationExcelData = useMemo(() => factorCorrelationsToExcel(correlationsData), [correlationsData]);
  const factorReturnsExcelData = useMemo(
    () => factorReturnsToExcel(data.drawdownFactorInfoList, periodAsString),
    [data.drawdownFactorInfoList, periodAsString],
  );

  if (!portfolioDrawdown) {
    return null;
  }

  return (
    <>
      <Divider />
      <Header>Market Environment</Header>
      <SectionContainer
        className={qaClasses.marketEnvironment}
        factorCount={data.drawdownFactorInfoList.length}
        noCorrelations={noCorrelations}
      >
        <ReturnsContainer
          factorCount={data.drawdownFactorInfoList.length}
          noCorrelations={noCorrelations}
          className={qaClasses.factorReturns}
        >
          <DownloadableContentBlock
            header="Factor Returns"
            subHeader={`
          How do peak-to-trough factor returns in ${periodAsString} compare to their historical averages?
        `}
            downloadable={{
              disabled: !factorReturnsExcelData || factorReturnsExcelData.length === 0,
              png: true,
              excel: factorReturnsExcelData,
              tracking: {
                description: 'DRAWDOWN_MARKET_ENV_RETURNS',
                relativeToBenchmark: false,
                subjectType: subject.type,
                subjectId: subject.id,
                userUploaded: subject.userUploaded,
              },
              options: {
                fileName: `Market Environment Factor Returns - ${subject.name}`,
                metaData: downloadMetaData,
              },
            }}
          >
            <ReturnsChartContainer>
              <ReturnsLegend>
                <FactorReturn>Factor return during this period</FactorReturn>
                <HistoricalAvg>Historical avg.</HistoricalAvg>
              </ReturnsLegend>
              <ReturnsChart drawdownFactorInfoList={data.drawdownFactorInfoList} print={print} />
            </ReturnsChartContainer>
          </DownloadableContentBlock>
        </ReturnsContainer>
        <CorrelationsContainer
          factorCount={data.drawdownFactorInfoList.length}
          noCorrelations={noCorrelations}
          className={qaClasses.factorCorrelations}
        >
          <DownloadableContentBlock
            header="Δ in Factor Correlations"
            subHeader="Which factors became more correlated from the peak to trough of this period?"
            downloadable={{
              disabled: !factorCorrelationExcelData || factorCorrelationExcelData.length === 0,
              png: true,
              excel: factorCorrelationExcelData,
              tracking: {
                description: 'DRAWDOWN_MARKET_ENV_CORRELATIONS',
                relativeToBenchmark: false,
                subjectType: subject.type,
                subjectId: subject.id,
                userUploaded: subject.userUploaded,
              },
              options: {
                fileName: `Market Environment - Delta in Factor Correlations - ${subject.name}`,
                metaData: downloadMetaData,
              },
            }}
          >
            <CorrelationsContentWrapper noCorrelations={noCorrelations} className="correlations-chart">
              {noCorrelations ? (
                <CorrelationsUnavailable>
                  {'Correlation changes are not shown for start-to-trough periods with < 36 trading days.'}
                </CorrelationsUnavailable>
              ) : (
                <CorrelationsChart
                  correlationsData={correlationsData ?? []}
                  drawdownFactorInfoList={drawdownFactorInfoList}
                />
              )}
            </CorrelationsContentWrapper>
          </DownloadableContentBlock>
        </CorrelationsContainer>
      </SectionContainer>
    </>
  );
};
export default MarketEnvironment;
const Header = styled(Headline2)`
  margin: 45px 0 20px;
  @media print {
    page-break-before: always;
    margin: 10px 0px 0px 0px;
    padding: 4px 8px;
    background-color: ${GetColor.Black};
    color: ${GetColor.White};
    font-family: ${(props) => props.theme.Typography.fontFamily};
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
`;

const Divider = styled.div`
  height: 15px;
  background: ${GetColor.PaleGrey};
  @media print {
    display: none;
  }
`;

const CorrelationsUnavailable = styled.div`
  border-radius: 10px;
  border: solid 1px ${GetColor.Grey};
  padding: 30px 55px;
  margin-top: 15px;
  font-size: 20px;
  color: ${GetColor.MidGrey1};
  text-align: center;
`;

const SectionContainer = styled.div<{ factorCount: number; noCorrelations: boolean }>`
  display: flex;
  flex-direction: row;
  @media print {
    display: block;
  }
  ${({ noCorrelations, factorCount }) =>
    noCorrelations
      ? `
        @media (max-width: ${BREAK_POINT_WITHOUT_CORRELATION}px) {
          flex-direction: column;
          .correlations-chart {
            margin-bottom: 0;
          }
          .${qaClasses.factorCorrelations} {
            width: 100%;
          }
          .${qaClasses.factorReturns} {
            padding-right: 0;
          }
        }
      `
      : `
        @media (max-width: ${calcBreakPointWithCorrelation(factorCount)}px) {
          flex-direction: column;
          .correlations-chart {
            margin-bottom: 0;
          }
          .${qaClasses.factorCorrelations} {
            width: 100%;
          }
          .${qaClasses.factorReturns} {
            padding-right: 0;
          }
        }
      `}
  @media screen {
    min-width: 800px;
  }
`;

const ReturnsContainer = styled.div<{
  factorCount: number;
  noCorrelations: boolean;
  className?: string;
}>`
  display: inline-block;
  @media print {
    display: block;
  }
  padding-right: 15px;
  min-width: ${RETURNS_MIN_WIDTH}px;
  ${({ noCorrelations, factorCount }) =>
    noCorrelations
      ? `
        width: calc(100% - ${NOCORRELATIONS_WIDTH}px);
        @media (max-width: ${BREAK_POINT_WITHOUT_CORRELATION}px) {
          width: 100%;
          margin-bottom: 30px;
        }
      `
      : `
        width: calc(100% - ${factorCount * CORRELATIONS_CELL_SIZE + INVESTMENT_COLUMN_WIDTH}px);
        @media (max-width: ${calcBreakPointWithCorrelation(factorCount)}px) {
          width: 100%;
          margin-bottom: 30px;
        }
      `}
`;

const ReturnsChartContainer = styled.div`
  border-top: 1px solid ${GetColor.Grey};
  width: 100%;

  .highcharts-xaxis-labels span {
    left: 20px !important;
  }

  .highcharts-tooltip {
    span {
      font-family: ${(props) => props.theme.Typography.fontFamily} !important;
    }
  }

  @media print {
    page-break-inside: avoid;
  }
`;

const ReturnsLegend = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 50px;
  width: 100%;
  background: rgba(241, 241, 242, 0.8);
  justify-self: flex-end;
  margin-top: auto;
  z-index: ${ZIndex.Front};
  font-weight: bold;
  border-bottom: 1px solid ${GetColor.Grey};

  span {
    display: inline-block;
    position: relative;
    margin: 0 15px;

    &:before {
      content: '';
      position: absolute;
    }
  }
`;

const FactorReturn = styled.span`
  &:before {
    background: ${GetColor.DataBarColor.LightGold};
    height: 10px;
    width: 14px;
    top: 2px;
    left: -20px;
    border-radius: 1px;
  }
`;

const HistoricalAvg = styled.span`
  margin-left: 22px;

  &:before {
    height: 2px;
    width: 14px;
    border-top: dotted 2px #10161b;
    top: 50%;
    left: -20px;
  }
`;

const CorrelationsContainer = styled.div<{ factorCount: number; noCorrelations: boolean; className?: string }>`
  display: inline-block;
  width: ${({ noCorrelations, factorCount }) =>
    noCorrelations
      ? `${NOCORRELATIONS_WIDTH}px`
      : `${factorCount * CORRELATIONS_CELL_SIZE + INVESTMENT_COLUMN_WIDTH + 2}px`};

  ${Subtitle1} {
    height: 32px;
  }

  @media print {
    page-break-inside: avoid;
    display: block;
  }
`;

const CorrelationsContentWrapper = styled.div<{ noCorrelations: boolean }>`
  ${({ noCorrelations }) =>
    noCorrelations &&
    `
    padding: 20px;
    margin-bottom: 378px;
  `};
`;

const calcBreakPointWithCorrelation = (factorCount: number) =>
  MIN_BASE_WIDTH + factorCount * CORRELATIONS_CELL_SIZE + INVESTMENT_COLUMN_WIDTH;
