import { isNil } from 'lodash';
import React, { type RefObject, useMemo } from 'react';
import Measure from 'react-measure';
import { useRecoilValue } from 'recoil';
import styled, { css, useTheme } from 'styled-components';
import type { PrivatesAnalysisResponse } from 'venn-api';
import {
  blockCustomizableMetricsMap,
  blockExportMetadata,
  blockInfoGraphicType,
  blockMaxSubjects,
  blockMetrics,
  blockRequestSubjects,
  isReportState,
  recoilBlockContentSize,
} from 'venn-state';
import { assertNotNil, convertMultipleSeriesDataToExcel } from 'venn-utils';
import DownloadableContentBlock, { EmptyHeaderSpace } from '../../../content-block/DownloadableContentBlock';
import { useBlockId } from '../../contexts/BlockIdContext';
import { WATERMARK_POSITION_TOP } from '../../customAnalysisContants';
import { getThirdPartyExportMessageRow } from '../../logic/exportUtils';
import useExportUpdate from '../../logic/useExportUpdate';
import { useSetBlockSize } from '../../logic/useSetBlockSize';
import StyledEmptyState from '../StyledEmptyState';
import BarChart from './BarChart';
import LineChart from './LineChart';
import type { ChartProps, Serie } from './types';

type PrivateAssetPerformanceChartProps = Readonly<{
  data: PrivatesAnalysisResponse;
  isExportable: boolean;
  downloadableContentRef?: RefObject<HTMLDivElement>;
  subjectColors: string[];
}>;

export const PrivateAssetPerformanceChart = ({
  data,
  isExportable,
  downloadableContentRef,
  subjectColors,
}: PrivateAssetPerformanceChartProps) => {
  const theme = useTheme();
  const blockId = useBlockId();
  const isReport = useRecoilValue(isReportState);
  const onResize = useSetBlockSize(recoilBlockContentSize.transformedState(blockId));
  const maxSubjects = useRecoilValue(blockMaxSubjects(blockId));
  const subjects = useRecoilValue(blockRequestSubjects(blockId));
  const selectedMetrics = useRecoilValue(blockMetrics(blockId));
  // if this chart is rendered, at least one metric is guaranteed to be selected
  const metric = assertNotNil(selectedMetrics[0]);
  const metricDefinitionsMap = useRecoilValue(blockCustomizableMetricsMap(blockId));
  const metricLabel = assertNotNil(metricDefinitionsMap[metric]).label;
  const isMetricCurrency = assertNotNil(metricDefinitionsMap[metric]).dataType === 'CURRENCY';
  const isMetricPercentage = assertNotNil(metricDefinitionsMap[metric]).dataType === 'PERCENTAGE';
  const blockGraphicType = useRecoilValue(blockInfoGraphicType(blockId));
  const exportMetaData = useRecoilValue(blockExportMetadata(blockId));

  const chartProps = useMemo(() => {
    if (!data || !data.performanceTimeSeries) {
      return undefined;
    }
    const perfSeries = data.performanceTimeSeries.slice(0, maxSubjects);

    const series = perfSeries.map(
      (t, idx) =>
        ({
          name: subjects[idx].name,
          data: (t[metric] ?? []).map((x: number[]) => [x[0], x[1]]),
          color: subjectColors[idx],
        }) as Serie,
    );

    return {
      series,
      frequency: 'QUARTERLY',
      yAxisUnitFormat: metric === 'nav' ? 'allocation' : isMetricPercentage ? 'percent' : 'ratio',
      theme,
      inPrintMode: isReport,
      start: series.length > 0 ? series[0][0] : undefined,
      end: series.length > 0 ? series[series.length - 1][0] : undefined,
      pointPadding: 0,
      groupPadding: 0,
      borderWidth: 1,
    } as ChartProps;
  }, [data, isReport, maxSubjects, metric, isMetricPercentage, subjects, theme, subjectColors]);

  const excelDataFn = useMemo(() => {
    return () => {
      if (!isExportable) {
        return [getThirdPartyExportMessageRow()];
      }

      return convertMultipleSeriesDataToExcel(
        (chartProps?.series ?? []).map((serie) => ({
          name: serie.name,
          series: serie.data,
        })),
        isMetricPercentage,
        isMetricCurrency ? '$' : undefined,
        'QUARTERLY',
      );
    };
  }, [chartProps?.series, isExportable, isMetricCurrency, isMetricPercentage]);

  useExportUpdate({
    selectedRefId: blockId,
    exportMetaData,
    excelDataFn,
  });

  if (isNil(chartProps) || chartProps?.series?.filter((s) => isNil(s?.data))?.length > 0) {
    return <StyledEmptyState header="Unknown error occurred. Please contact Venn Support" selectedRefId={blockId} />;
  }

  const chartComponent = (
    <Wrapper isReport={isReport}>
      <Measure bounds onResize={onResize}>
        {({ measureRef }) => (
          <Container ref={measureRef} isReport={isReport} data-testid="qa-privates-performance-line-chart">
            {blockGraphicType === 'BAR' ? (
              <BarChart className="qa-private-performance-bar-highchart" {...chartProps} />
            ) : (
              <LineChart className="qa-private-performance-line-highchart" {...chartProps} />
            )}
          </Container>
        )}
      </Measure>
    </Wrapper>
  );

  if (isReport) {
    return chartComponent;
  }

  return (
    <DownloadableContentBlock
      header={<EmptyHeaderSpace />}
      noBorder
      downloadable={{
        png: true,
        options: {
          fileName: `Private Assets - ${metricLabel} Time Series`,
          watermark: {
            top: WATERMARK_POSITION_TOP,
            right: 20,
          },
        },
        tracking: {
          description: 'PRIVATE_PERFORMANCE_TIME_SERIES',
          relativeToBenchmark: false, // TODO: should this be true for PME?  update when adding
          userUploaded: false, // TODO: revisit if we want to mark that we are analysing user uploaded private funds
          subjectType: subjects?.[0]?.subjectType
            ? subjects?.[0]?.subjectType === 'PORTFOLIO'
              ? 'private-portfolio'
              : 'private-investment'
            : undefined,
          subjectId: subjects?.[0]?.id,
        },
        disabled: (chartProps?.series.length ?? 0) === 0,
        target: downloadableContentRef,
      }}
      floatingOptions
    >
      {chartComponent}
    </DownloadableContentBlock>
  );
};

const Container = styled.div<{ isReport: boolean }>`
  min-height: 100px;
  min-width: 200px;

  .qa-private-performance-line-highchart,
  .qa-private-performance-bar-highchart {
    height: 100%;
  }

  ${({ isReport }) =>
    isReport &&
    css`
      width: 100%;
      height: 100%;

      .qa-private-performance-line-highchart,
      .qa-private-performance-bar-highchart {
        width: 100%;
        height: 100%;
      }
    `}
`;
const Wrapper = styled.div<{ isReport: boolean }>`
  ${({ isReport }) =>
    isReport &&
    css`
      width: 100%;
      height: 100%;
    `}
`;
