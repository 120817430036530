import React, { useCallback, useContext, useEffect, useState } from 'react';
import type { DropMenuCheckboxItem, TooltipPosition } from 'venn-ui-kit';
import { IconCheckboxDropMenu } from 'venn-ui-kit';
import TagsContext from '../contexts/tags-context';
import { useAsyncQueue } from 'venn-utils';
import TagsEmptyState from './TagsEmptyState';
import type { TagableItem } from './utils';
import { addTagToItems, getTagsTooltip, removeTagFromItems } from './utils';

interface TagDropdownProps {
  itemsToTag: TagableItem[];
  onClose?: () => void;
  onOpen?: () => void;
  dark?: boolean;
  solid?: boolean;
  border?: boolean;
  borderSize?: number;
  iconSize?: number;
  tooltip?: React.ReactNode;
  placeholderTooltip?: React.ReactNode;
  tooltipPosition?: TooltipPosition;
  currentTags: Set<string>;
  currentIndeterminateTags?: Set<string>;
  onChange?: (changedItems: DropMenuCheckboxItem<string>[]) => void;
  left?: number;
  className?: string;
  triggerText?: string;
  isReadOnly?: boolean;
}

const TagDropdown = ({
  onClose,
  onOpen,
  dark,
  itemsToTag,
  iconSize,
  solid,
  border,
  borderSize,
  tooltip,
  placeholderTooltip,
  tooltipPosition,
  currentTags,
  currentIndeterminateTags,
  onChange,
  left,
  className,
  triggerText,
  isReadOnly,
}: TagDropdownProps) => {
  const { tags: contextTags, refreshTags } = useContext(TagsContext);
  const [availableTags, setAvailableTags] = useState<string[]>(contextTags);
  const [items, setItems] = useState<DropMenuCheckboxItem<string>[]>([]);
  const [tags, setTags] = useState<Set<string>>(currentTags);
  const [indeterminateTags, setIndeterminateTags] = useState<Set<string> | undefined>(currentIndeterminateTags);
  const addToRequestQueue = useAsyncQueue();

  // Sync local state to sources of truth
  useEffect(() => setAvailableTags(contextTags), [contextTags]);
  useEffect(() => setTags(currentTags), [currentTags]);
  useEffect(() => setIndeterminateTags(currentIndeterminateTags), [currentIndeterminateTags]);

  useEffect(
    () =>
      setItems(
        availableTags.map((t) => ({
          value: t,
          label: t,
          checked: tags.has(t),
          indeterminate: indeterminateTags?.has(t),
        })),
      ),
    [availableTags, tags, indeterminateTags],
  );

  const onCheckboxChange = useCallback(
    (changedItems: DropMenuCheckboxItem<string>[]) => {
      // Optimistically update local state
      setAvailableTags(changedItems.map((i) => i.value));
      setTags(new Set(changedItems.filter((i) => i.checked).map((i) => i.value)));
      setIndeterminateTags(new Set(changedItems.filter((i) => i.indeterminate).map((i) => i.value)));

      onChange?.(changedItems);

      addToRequestQueue(() =>
        Promise.all(
          changedItems.map((changedItem) => {
            const wasChecked = currentTags.has(changedItem.value);
            const wasIndeterminate = currentIndeterminateTags?.has(changedItem.value);
            if (changedItem.checked && (!wasChecked || wasIndeterminate)) {
              return addTagToItems(changedItem.value, itemsToTag);
            }
            if (!changedItem.checked && wasChecked) {
              return removeTagFromItems(changedItem.value, itemsToTag);
            }
            return Promise.resolve();
          }),
        ).then(() => refreshTags()),
      );
    },
    [refreshTags, itemsToTag, currentTags, addToRequestQueue, currentIndeterminateTags, onChange],
  );

  return (
    <IconCheckboxDropMenu
      className={className}
      left={left}
      icon="tag"
      items={items}
      onChange={onCheckboxChange}
      onCollapse={onClose}
      onOpen={onOpen}
      dark={dark}
      solid={solid}
      width={400}
      height={226}
      placeholder="Type to add tags"
      emptyState={<TagsEmptyState />}
      iconSize={iconSize}
      border={border}
      borderSize={borderSize}
      tooltip={tooltip ?? getTagsTooltip(tags, placeholderTooltip)}
      tooltipPosition={tooltipPosition}
      triggerText={triggerText}
      isReadOnly={isReadOnly}
    />
  );
};

export default TagDropdown;
