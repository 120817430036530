import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import type { FactorExposure, Portfolio } from 'venn-api';
import type { FactorExposureConstraint } from 'venn-utils';
import { GetColor, Icon } from 'venn-ui-kit';
import FactorConstraint from './FactorConstraint';
import { isNil, sortBy } from 'lodash';

interface FactorExposureConstraintsManagementProps {
  portfolio: Portfolio;
  factorExposures: FactorExposure[];
  factorConstraints: FactorExposureConstraint[];

  canApplyConstraintsToPolicy?: boolean;
  onApplyConstraint?: (constraint: FactorExposureConstraint) => void;
  onUpdateFactorConstraint: (constraint: FactorExposureConstraint) => void;
  onDeleteConstraint: (constraint: FactorExposureConstraint) => void;
  alwaysShowAllFactors?: boolean;
  disabled?: boolean;
}

const FactorExposureConstraintsManagement = ({
  portfolio,
  factorExposures,
  factorConstraints,
  canApplyConstraintsToPolicy,
  onApplyConstraint,
  alwaysShowAllFactors,
  onUpdateFactorConstraint,
  onDeleteConstraint,
  disabled,
}: FactorExposureConstraintsManagementProps) => {
  const [minExposureConstraints, maxExposureConstraints] = useMemo(() => {
    const minResult: { [key: number]: FactorExposureConstraint } = {};
    const maxResult: { [key: number]: FactorExposureConstraint } = {};

    factorConstraints.forEach((constraint) => {
      if (constraint.condition === 'minExposure') {
        minResult[constraint.factorId] = constraint;
      } else {
        maxResult[constraint.factorId] = constraint;
      }
    });

    return [minResult, maxResult];
  }, [factorConstraints]);

  const [significant, insignificant] = useMemo(
    () => [
      sortBy(
        factorExposures.filter((item) => !isNil(item.exposure) && item.significant),
        (exp) => -exp.exposure,
      ),
      sortBy(
        factorExposures.filter((item) => isNil(item.exposure) || !item.significant),
        (exp) => -exp.exposure,
      ),
    ],
    [factorExposures],
  );

  const [showAll, setShowAll] = useState(false);

  if (alwaysShowAllFactors) {
    return (
      <>
        {factorExposures.map((factorBreakdown) => (
          <FactorConstraint
            key={factorBreakdown.name}
            portfolio={portfolio}
            factorBreakdown={factorBreakdown}
            minConstraint={minExposureConstraints[factorBreakdown.id]}
            maxConstraint={maxExposureConstraints[factorBreakdown.id]}
            canApplyConstraint={canApplyConstraintsToPolicy}
            onApplyConstraint={onApplyConstraint}
            onAddConstraint={onUpdateFactorConstraint}
            onDeleteConstraint={onDeleteConstraint}
            disabled={disabled}
          />
        ))}
      </>
    );
  }

  return (
    <>
      {significant.map((factorBreakdown) => (
        <FactorConstraint
          key={factorBreakdown.name}
          portfolio={portfolio}
          factorBreakdown={factorBreakdown}
          minConstraint={minExposureConstraints[factorBreakdown.id]}
          maxConstraint={maxExposureConstraints[factorBreakdown.id]}
          canApplyConstraint={canApplyConstraintsToPolicy}
          onApplyConstraint={onApplyConstraint}
          onAddConstraint={onUpdateFactorConstraint}
          onDeleteConstraint={onDeleteConstraint}
          disabled={disabled}
        />
      ))}
      {showAll
        ? insignificant.map((factorBreakdown) => (
            <FactorConstraint
              key={factorBreakdown.name}
              portfolio={portfolio}
              factorBreakdown={factorBreakdown}
              minConstraint={minExposureConstraints[factorBreakdown.id]}
              maxConstraint={maxExposureConstraints[factorBreakdown.id]}
              canApplyConstraint={canApplyConstraintsToPolicy}
              onApplyConstraint={onApplyConstraint}
              onAddConstraint={onUpdateFactorConstraint}
              onDeleteConstraint={onDeleteConstraint}
              disabled={disabled}
            />
          ))
        : null}
      <ShowAll type="button" onClick={() => setShowAll(!showAll)} className="qa-show-all-factors-btn">
        {showAll ? 'Hide insignificant factors' : 'Show all factors'}
        <Icon type={showAll ? 'chevron-up' : 'chevron-down'} />
      </ShowAll>
    </>
  );
};

export default FactorExposureConstraintsManagement;

const ShowAll = styled.button`
  color: ${GetColor.MidGrey2};
  font-size: 12px;
  font-weight: bold;
  i {
    margin-left: 5px;
  }
  margin-top: 10px;
`;
