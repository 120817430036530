import { useBlockId } from '../contexts/BlockIdContext';
import { orderBy } from 'lodash';
import { useRecoilValue } from 'recoil';
import { blockConfidenceLevels, blockConfidenceLevelsVisibility } from 'venn-state';
import { logExceptionIntoSentry } from 'venn-utils';

export type ConfidenceLevel = {
  visible: boolean;
  level: number;
  originalIndex: number;
};

export const useConfidenceLevels = (): ConfidenceLevel[] => {
  const blockId = useBlockId();
  const rawLevels = useRecoilValue(blockConfidenceLevels(blockId));
  const rawVisibility = useRecoilValue(blockConfidenceLevelsVisibility(blockId));

  if (rawLevels.length !== rawVisibility.length) {
    logExceptionIntoSentry(`Inconsistent confidence levels recoil state: ${rawLevels.length}!=${rawVisibility.length}`);
  }

  return orderBy(
    rawLevels.map((level, index) => {
      return {
        visible: rawVisibility[index],
        level,
        originalIndex: index,
      } as ConfidenceLevel;
    }),
    ['level'],
    'desc',
  );
};
