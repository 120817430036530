import type { FC } from 'react';
import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { GetColor } from 'venn-ui-kit';

interface DataStatusBarProps {
  /**
   * Number of investments that are new
   */
  newCount: number;
  /**
   * Number of investments that will be updated
   */
  updateCount: number;
}

export const DataStatusBar: FC<React.PropsWithChildren<DataStatusBarProps>> = ({ newCount, updateCount }) => {
  const { Colors } = useContext(ThemeContext);
  const hasData = newCount || updateCount;
  const dataCount = hasData ? (
    <>
      {!!newCount && <NewText>{`${newCount} New ${newCount === 1 ? 'Investment' : 'Investments'}`}</NewText>}
      {!!updateCount && (
        <UpdateText>{`${updateCount} ${updateCount === 1 ? 'Investment' : 'Investments'} to Update`}</UpdateText>
      )}
    </>
  ) : (
    'No New Data!'
  );

  return (
    <Container>
      <Bar>
        {hasData ? (
          <>
            {!!updateCount && (
              <BarPart
                first
                only={!newCount}
                width={(updateCount / (newCount + updateCount)) * 100}
                color={Colors.DEPRECATED_DataLineColor.Green}
              />
            )}
            {!!newCount && (
              <BarPart
                only={!updateCount}
                width={(newCount / (newCount + updateCount)) * 100}
                color={Colors.HighlightDark}
              />
            )}
          </>
        ) : null}
      </Bar>
      <Text>{dataCount}</Text>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 28px;
`;
const Text = styled.div`
  font-size: 12px;
  font-weight: bold;
  text-align: right;
  width: 200px;
  line-height: 1.17;
  color: ${GetColor.DarkGrey};
`;
const NewText = styled.div`
  color: ${GetColor.HighlightDark};
`;
const UpdateText = styled.div`
  color: ${GetColor.Green};
`;
const BarPart = styled.div<{ width: number; color: string; first?: boolean; only?: boolean }>`
  height: 6px;
  width: ${(props) => props.width}%;
  background-color: ${(props) => props.color};
`;
const Bar = styled.div`
  width: 100%;
  height: 6px;
  display: flex;
`;

export default DataStatusBar;
