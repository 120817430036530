import type { LibrarySearchResult, LibrarySearchEntity, AssetTypeEnum } from 'venn-api';
import { getAppTitleSimplifiedUtil } from '../../utils';

export const privatesMockSearchResult = {
  results: [
    {
      fundId: null,
      privateFundId: 'ca2560c4-4378-4887-8b49-309e1231c414',
      portfolioId: null,
      privatePortfolioId: null,
      owner: {
        id: 3123530,
        name: 'Kacper Walentynowicz',
        displayName: 'Kacper Walentynowicz',
        email: 'kacper@twosigma.com',
        active: true,
        created: 1648459460385,
        pwdLastUpdated: null,
        avatarId: null,
        actualUser: false,
        accountId: 1,
      },
      ownerContextId: null,
      ownerContextFriendlyName: null,
      name: 'Kacper’s Sad Fund',
      symbol: null,
      organizationId: null,
      created: 1693573912570,
      updated: 1693573991546,
      startRange: null,
      endRange: null,
      proxyStartRange: null,
      proxyEndRange: null,
      unproxiedStartRange: null,
      unproxiedEndRange: null,
      unproxiedNumReturns: null,
      interpolated: false,
      extrapolated: false,
      live: null,
      frequency: null,
      unproxiedFrequency: null,
      currency: 'USD',
      currencyName: 'United States dollar',
      active: true,
      permissionGroupId: 2,
      userUploaded: true,
      investmentSourceName: null,
      dataSource: null,
      shareClass: null,
      primaryShareClass: null,
      assetType: null,
      remoteType: null,
      accountNumber: null,
      morningstarCategories: null,
      category: null,
      tags: null,
      tagIds: [],
      periodAnnualized: null,
      periodReturn: null,
      annualizedTotalReturn: null,
      annualizedVolatility: null,
      annualizedSharpeRatio: null,
      annualizedResidualReturn: null,
      tenYearCumulativeReturn: null,
      cumulativeReturn: null,
      tenYearCumulativeReturnSeries: null,
      maxDrawdown: null,
      factorLensId: 71,
      factorLensName: 'V4-USD',
      periodAlpha: null,
      annualizedAlpha: null,
      annualizedAlphaTStat: null,
      metricsOnTimePeriod: null,
      investmentSource: null,
      proxyId: null,
      proxyName: null,
      proxyType: null,
      proxyNumLags: null,
      proxyCategory: null,
      interpolatedFundId: null,
      master: false,
      currentlyProcessing: false,
      hasFactorAnalysis: false,
      fileSize: null,
      version: null,
      assetClass: 'PRIVATE',
      externalId: 'Kacper’s Sad Fund',
      vintage: 1672531199999,
      size: 2147483647,
      status: 'Closed',
      privateFundAssetClass: 'Private Equity',
      strategy: 'Buyout',
      subStrategy: null,
      manager: 'Sentinel Capital Partners',
      geographicFocus: null,
      industries: ['Business Services'],
      privateFundPerformance: null,
      shareClassName: null,
      interpolatable: false,
      proxyable: true,
      morningstarCategory: null,
      needsInterpolation: false,
      hasMinDataForInterpolation: false,
      interpolationCategory: null,
      needsProxy: true,
      outOfDate: false,
    },
    {
      fundId: null,
      privateFundId: '7f0de9c4-c0e5-4440-83cb-fd05ec6b506f',
      portfolioId: null,
      privatePortfolioId: null,
      owner: {
        id: 3123530,
        name: 'Kacper Walentynowicz',
        displayName: 'Kacper Walentynowicz',
        email: 'kacper@twosigma.com',
        active: true,
        created: 1648459460385,
        pwdLastUpdated: null,
        avatarId: null,
        actualUser: false,
        accountId: 1,
      },
      ownerContextId: null,
      ownerContextFriendlyName: null,
      name: 'Kacper’s Capital Fund',
      symbol: null,
      organizationId: null,
      created: 1693559103973,
      updated: 1693573821302,
      startRange: null,
      endRange: null,
      proxyStartRange: null,
      proxyEndRange: null,
      unproxiedStartRange: null,
      unproxiedEndRange: null,
      unproxiedNumReturns: null,
      interpolated: false,
      extrapolated: false,
      live: null,
      frequency: null,
      unproxiedFrequency: null,
      currency: 'USD',
      currencyName: 'United States dollar',
      active: true,
      permissionGroupId: 2,
      userUploaded: true,
      investmentSourceName: null,
      dataSource: null,
      shareClass: null,
      primaryShareClass: null,
      assetType: null,
      remoteType: null,
      accountNumber: null,
      morningstarCategories: null,
      category: null,
      tags: null,
      tagIds: [],
      periodAnnualized: null,
      periodReturn: null,
      annualizedTotalReturn: null,
      annualizedVolatility: null,
      annualizedSharpeRatio: null,
      annualizedResidualReturn: null,
      tenYearCumulativeReturn: null,
      cumulativeReturn: null,
      tenYearCumulativeReturnSeries: null,
      maxDrawdown: null,
      factorLensId: 71,
      factorLensName: 'V4-USD',
      periodAlpha: null,
      annualizedAlpha: null,
      annualizedAlphaTStat: null,
      metricsOnTimePeriod: null,
      investmentSource: null,
      proxyId: null,
      proxyName: null,
      proxyType: null,
      proxyNumLags: null,
      proxyCategory: null,
      interpolatedFundId: null,
      master: false,
      currentlyProcessing: false,
      hasFactorAnalysis: false,
      fileSize: null,
      version: null,
      assetClass: 'PRIVATE',
      externalId: 'Kacper’s Capital Fund',
      vintage: 1672531199999,
      size: 2147483647,
      status: 'Closed',
      privateFundAssetClass: 'Private Equity',
      strategy: 'Buyout',
      subStrategy: null,
      manager: 'Sentinel Capital Partners',
      geographicFocus: null,
      industries: ['Business Services'],
      privateFundPerformance: {
        asOfDate: 1585699199999,
        netIrrPct: -9.7,
        netMultiple: 0.45,
        rvpiPct: 0,
        dpiPct: 44.72,
        calledPct: 88.57,
        quartile: 4,
      },
      shareClassName: null,
      interpolatable: false,
      proxyable: true,
      morningstarCategory: null,
      needsInterpolation: false,
      hasMinDataForInterpolation: false,
      interpolationCategory: null,
      needsProxy: true,
      outOfDate: false,
    },
  ],
  total: 2,
} as unknown as LibrarySearchResult;

export const mockLibrarySearch: LibrarySearchResult = {
  results: [
    {
      fundId: '6fad39ef-bd75-4046-8fb0-d207bf406b3d',
      portfolioId: undefined!,
      owner: {
        id: -1,
        name: `${getAppTitleSimplifiedUtil()}`,
        displayName: `${getAppTitleSimplifiedUtil()}`,
        email: undefined!,
        active: true,
        created: undefined!,
        pwdLastUpdated: undefined!,
        avatarId: undefined!,
        actualUser: false,
        accountId: 1,
      },
      morningstarCategories: [],
      morningstarCategoryCodes: [],
      morningstarCategory: undefined!,
      name: 'CCL INDUSTRIES INC',
      symbol: 'CCL/A',
      organizationId: undefined!,
      created: 1539427438295,
      updated: 1574333218993,
      startRange: 426988799999,
      endRange: 1573862399999,
      proxyStartRange: undefined!,
      proxyEndRange: undefined!,
      proxyNumLags: undefined!,
      unproxiedStartRange: 426988799999,
      unproxiedEndRange: 1573862399999,
      unproxiedNumReturns: 9483,
      unproxiedFrequency: 'DAILY',
      interpolated: false,
      live: true,
      frequency: 'DAILY',
      currency: 'CAD',
      currencyName: 'Canadian dollar',
      active: true,
      permissionGroupId: 1,
      userUploaded: false,
      investmentSourceName: `${getAppTitleSimplifiedUtil()}`,
      assetType: 'STOCK' as AssetTypeEnum,
      remoteType: 'DATA',
      accountNumber: undefined!,
      category: undefined!,
      periodAnnualized: true,
      periodReturn: 0.1873962844315371,
      annualizedTotalReturn: 0.1873962844315371,
      annualizedVolatility: 0.30304839250570886,
      annualizedSharpeRatio: 0.6750480574927503,
      annualizedResidualReturn: undefined!,
      tenYearCumulativeReturn: 8.280522881310645,
      cumulativeReturn: 17.17826535216514,
      tenYearCumulativeReturnSeries: [
        [1259625599999, 0.0],
        [1262303999999, 0.14274069072315587],
        [1264982399999, 0.058279175425597796],
        [1267401599999, 0.03692852770463739],
        [1270079999999, 0.19909278415764353],
        [1272671999999, 0.19652736229899026],
        [1275350399999, 0.11554072800770632],
        [1277942399999, 0.12101604566002999],
        [1280620799999, 0.09603481293081573],
        [1283299199999, 0.21160379098709847],
        [1285891199999, 0.2553281558173308],
        [1288569599999, 0.242722250723302],
        [1291161599999, 0.23122788660845295],
        [1293839999999, 0.29388691360913954],
        [1296518399999, 0.42779139281567957],
        [1298937599999, 0.4490073859133874],
        [1301615999999, 0.4516886640368747],
        [1304207999999, 0.4882543583723573],
        [1306886399999, 0.4575322784139446],
        [1309478399999, 0.48503441852080886],
        [1312156799999, 0.5338856335065907],
        [1314835199999, 0.2565976319501404],
        [1317427199999, 0.13670476341904658],
        [1320105599999, 0.2307485764825028],
        [1322697599999, 0.32667857371542564],
        [1325375999999, 0.26262777481776833],
        [1328054399999, 0.3765920066645858],
        [1330559999999, 0.5300012286073379],
        [1333238399999, 0.5260774732994871],
        [1335830399999, 0.6296103875649552],
        [1338508799999, 0.533876925533807],
        [1341100799999, 0.5289701644159102],
        [1343779199999, 0.564008636678146],
        [1346457599999, 0.6314268454128178],
        [1349049599999, 0.6004963361328421],
        [1351727999999, 0.5755961680212156],
        [1354319999999, 0.5781002230249555],
        [1356998399999, 0.8509551818649723],
        [1359676799999, 0.9193468436964447],
        [1362095999999, 1.3490689884652327],
        [1364774399999, 1.8353577249984938],
        [1367366399999, 1.6966066601158638],
        [1370044799999, 1.807981443468484],
        [1372636799999, 1.7305385854409092],
        [1375315199999, 1.8848215647818654],
        [1377993599999, 1.8113952125089248],
        [1380585599999, 1.9967490658284626],
        [1383263999999, 1.9741594817610442],
        [1385855999999, 2.206944586279172],
        [1388534399999, 2.195475043287674],
        [1391212799999, 2.134323023631231],
        [1393631999999, 2.5699725590430624],
        [1396310399999, 2.847855628750936],
        [1398902399999, 2.8714931507564705],
        [1401580799999, 3.1382320941337394],
        [1404172799999, 3.2282273555783423],
        [1406851199999, 3.330143288135689],
        [1409529599999, 3.6610154306269447],
        [1412121599999, 3.4389582726270014],
        [1414799999999, 3.3822247592969],
        [1417391999999, 3.6131845266180944],
        [1420070399999, 3.7136410197808507],
        [1422748799999, 3.4294000909090734],
        [1425167999999, 3.5477832501499353],
        [1427846399999, 3.8668124685203313],
        [1430438399999, 4.234050468429287],
        [1433116799999, 3.972258988972097],
        [1435708799999, 4.276045693708868],
        [1438387199999, 4.936891247196599],
        [1441065599999, 4.6440972968448735],
        [1443657599999, 4.955915635456278],
        [1446335999999, 5.048629047447013],
        [1448927999999, 5.9982666250460985],
        [1451606399999, 6.008384429255054],
        [1454284799999, 5.365031696670763],
        [1456790399999, 5.580634458567595],
        [1459468799999, 6.940349963435892],
        [1462060799999, 7.02704773677014],
        [1464739199999, 6.686476565309648],
        [1467331199999, 6.475485065348869],
        [1470009599999, 6.461396996904965],
        [1472687999999, 7.075093732659887],
        [1475279999999, 7.242466584035499],
        [1477958399999, 6.888586328720525],
        [1480550399999, 6.605260041950963],
        [1483228799999, 6.241479142197598],
        [1485907199999, 7.65967907588851],
        [1488326399999, 8.507913045480803],
        [1491004799999, 8.464141301991331],
        [1493596799999, 9.174295574541263],
        [1496275199999, 9.267128313600159],
        [1498867199999, 10.239483559198495],
        [1501545599999, 9.801175512582098],
        [1504223999999, 9.512955420891977],
        [1506815999999, 9.908528980260492],
        [1509494399999, 9.840542620574201],
        [1512086399999, 9.335578457419313],
        [1514764799999, 9.348602892325323],
        [1517443199999, 9.748796903820148],
        [1519862399999, 10.119382219201604],
        [1522540799999, 10.089272576458367],
        [1525132799999, 10.220787208099857],
        [1527811199999, 10.164883664963819],
        [1530403199999, 9.84767993575403],
        [1533081599999, 10.330684324750958],
        [1535759999999, 9.69081065861055],
        [1538351999999, 9.17652149042875],
        [1541030399999, 8.286202155634333],
        [1543622399999, 8.150635442770563],
        [1546300799999, 7.158090658782436],
        [1548979199999, 8.306784874499819],
        [1551398399999, 8.054648682542165],
        [1554076799999, 7.82141423353254],
        [1556668799999, 8.370671148020719],
        [1559347199999, 9.142145146674228],
        [1561939199999, 9.871656539780979],
        [1564617599999, 10.180752417789046],
        [1567295999999, 9.050510236949675],
        [1569887999999, 8.34533353268054],
        [1572566399999, 8.126228776531152],
      ],
      metricsOnTimePeriod: {},
      maxDrawdown: {
        start: undefined!,
        end: undefined!,
        trough: undefined!,
        troughDrawdown: -0.6855405532587115,
        troughDrawdownError: 0.0,
        completed: true,
      },
      factorLensId: 29,
      factorLensName: 'TSFLWE V3',
      hasFactorAnalysis: true,
      periodAlpha: undefined!,
      annualizedAlpha: undefined!,
      annualizedAlphaTStat: undefined!,
      investmentSource: 'VENN',
      proxyId: undefined!,
      proxyName: undefined!,
      interpolationCategory: undefined!,
      proxyCategory: undefined!,
      interpolatedFundId: undefined!,
      master: false,
      currentlyProcessing: false,
      needsProxy: false,
      needsInterpolation: false,
      outOfDate: false,
      proxyable: true,
      interpolatable: false,
      hasMinDataForInterpolation: true,
      tags: [],
      tagIds: [],
      dataSource: '',
      fileSize: 0,
      version: 0,
      extrapolated: false,
      assetClass: 'PUBLIC',
      externalId: undefined!,
      geographicFocus: undefined!,
      industries: undefined!,
      manager: undefined!,
      privateFundAssetClass: undefined!,
      privateFundPerformance: undefined!,
      privatePortfolioId: undefined!,
      size: undefined!,
      strategy: undefined!,
      subStrategy: undefined!,
      vintage: undefined!,
      status: undefined!,
      privateFundId: undefined!,
      capitalCommitment: undefined!,
      privateFundType: undefined!,
      finalCloseDate: undefined!,
      finalCloseSize: undefined!,
      coreIndustries: undefined!,
      historicalPortfolioId: undefined!,
      investmentType: undefined!,
    },
    {
      fundId: undefined!,
      portfolioId: 454140,
      owner: {
        id: 3000073,
        name: 'yy',
        displayName: 'yy',
        email: 'yuanyuan@twosigma.com',
        active: true,
        created: 1532032184901,
        pwdLastUpdated: undefined!,
        avatarId: 'd381a008-05c5-4e33-94e2-b21a0e486446',
        actualUser: false,
        accountId: 1,
      },
      morningstarCategories: [],
      morningstarCategoryCodes: [],
      morningstarCategory: undefined!,
      name: 'New Portfolio2',
      symbol: undefined!,
      organizationId: 1,
      created: 1574102337447,
      updated: 1574102337459,
      startRange: undefined!,
      endRange: undefined!,
      proxyStartRange: undefined!,
      proxyEndRange: undefined!,
      proxyNumLags: undefined!,
      unproxiedStartRange: undefined!,
      unproxiedEndRange: undefined!,
      unproxiedNumReturns: undefined!,
      unproxiedFrequency: undefined!,
      interpolated: false,
      live: undefined!,
      frequency: undefined!,
      currency: 'USD',
      currencyName: 'United States dollar',
      active: true,
      permissionGroupId: 2,
      userUploaded: undefined!,
      investmentSourceName: undefined!,
      assetType: undefined!,
      remoteType: undefined!,
      accountNumber: undefined!,
      category: undefined!,
      periodAnnualized: undefined!,
      periodReturn: undefined!,
      annualizedTotalReturn: undefined!,
      annualizedVolatility: undefined!,
      annualizedSharpeRatio: undefined!,
      annualizedResidualReturn: undefined!,
      tenYearCumulativeReturn: undefined!,
      cumulativeReturn: undefined!,
      tenYearCumulativeReturnSeries: undefined!,
      metricsOnTimePeriod: {},
      maxDrawdown: undefined!,
      factorLensId: 29,
      factorLensName: 'TSFLWE V3',
      hasFactorAnalysis: false,
      periodAlpha: undefined!,
      annualizedAlpha: undefined!,
      annualizedAlphaTStat: undefined!,
      investmentSource: undefined!,
      proxyId: undefined!,
      proxyName: undefined!,
      interpolationCategory: undefined!,
      proxyCategory: undefined!,
      interpolatedFundId: undefined!,
      master: true,
      currentlyProcessing: false,
      needsProxy: false,
      needsInterpolation: false,
      outOfDate: false,
      proxyable: false,
      interpolatable: false,
      hasMinDataForInterpolation: false,
      tags: [],
      tagIds: [],
      dataSource: '',
      fileSize: 0,
      version: 0,
      extrapolated: false,
      assetClass: 'PUBLIC',
      externalId: undefined!,
      geographicFocus: undefined!,
      industries: undefined!,
      manager: undefined!,
      privateFundAssetClass: undefined!,
      privateFundPerformance: undefined!,
      privatePortfolioId: undefined!,
      size: undefined!,
      strategy: undefined!,
      subStrategy: undefined!,
      vintage: undefined!,
      status: undefined!,
      privateFundId: undefined!,
      capitalCommitment: undefined!,
      privateFundType: undefined!,
      finalCloseDate: undefined!,
      finalCloseSize: undefined!,
      coreIndustries: undefined!,
      historicalPortfolioId: undefined!,
      investmentType: undefined!,
    },
    {
      fundId: undefined!,
      portfolioId: 45939,
      owner: {
        id: 3000008,
        name: '(deleted account)',
        displayName: '(deleted account)',
        email: 'zsolt@twosigma.com',
        active: true,
        created: 1481587109721,
        pwdLastUpdated: undefined!,
        avatarId: '75adcd0d-e08d-4c75-9b91-ee6bc7dbe05e',
        actualUser: false,
        accountId: 1,
      },
      morningstarCategories: [],
      morningstarCategoryCodes: [],
      morningstarCategory: undefined!,
      name: 'AnnasFancyPortfolio',
      symbol: undefined!,
      organizationId: 1,
      created: 1561496683899,
      updated: 1574167709201,
      startRange: undefined!,
      endRange: undefined!,
      proxyStartRange: undefined!,
      proxyEndRange: undefined!,
      proxyNumLags: undefined!,
      unproxiedStartRange: undefined!,
      unproxiedEndRange: undefined!,
      unproxiedNumReturns: undefined!,
      unproxiedFrequency: undefined!,
      interpolated: false,
      live: undefined!,
      frequency: undefined!,
      currency: 'USD',
      currencyName: 'United States dollar',
      active: true,
      permissionGroupId: 2,
      userUploaded: undefined!,
      investmentSourceName: undefined!,
      assetType: undefined!,
      remoteType: undefined!,
      accountNumber: undefined!,
      category: undefined!,
      periodAnnualized: undefined!,
      periodReturn: undefined!,
      annualizedTotalReturn: undefined!,
      annualizedVolatility: undefined!,
      annualizedSharpeRatio: undefined!,
      annualizedResidualReturn: undefined!,
      tenYearCumulativeReturn: undefined!,
      cumulativeReturn: undefined!,
      tenYearCumulativeReturnSeries: undefined!,
      maxDrawdown: undefined!,
      metricsOnTimePeriod: {},
      factorLensId: 29,
      factorLensName: 'TSFLWE V3',
      hasFactorAnalysis: false,
      periodAlpha: undefined!,
      annualizedAlpha: undefined!,
      annualizedAlphaTStat: undefined!,
      investmentSource: undefined!,
      proxyId: undefined!,
      proxyName: undefined!,
      interpolationCategory: undefined!,
      proxyCategory: undefined!,
      interpolatedFundId: undefined!,
      master: false,
      currentlyProcessing: false,
      needsProxy: false,
      needsInterpolation: false,
      outOfDate: false,
      proxyable: false,
      interpolatable: false,
      hasMinDataForInterpolation: false,
      tags: [],
      tagIds: [],
      dataSource: '',
      fileSize: 0,
      version: 0,
      extrapolated: false,
      assetClass: 'PUBLIC',
      externalId: undefined!,
      geographicFocus: undefined!,
      industries: undefined!,
      manager: undefined!,
      privateFundAssetClass: undefined!,
      privateFundPerformance: undefined!,
      privatePortfolioId: undefined!,
      size: undefined!,
      strategy: undefined!,
      subStrategy: undefined!,
      vintage: undefined!,
      status: undefined!,
      privateFundId: undefined!,
      capitalCommitment: undefined!,
      privateFundType: undefined!,
      finalCloseDate: undefined!,
      finalCloseSize: undefined!,
      coreIndustries: undefined!,
      historicalPortfolioId: undefined!,
      investmentType: undefined!,
    },
  ],
  total: 3,
};

export const mockTags = [
  {
    fundId: null,
    portfolioId: null,
    documentId: null,
    owner: null,
    name: 'BM2',
    symbol: null,
    organizationId: null,
    created: null,
    updated: null,
    startRange: null,
    endRange: null,
    proxyStartRange: null,
    proxyEndRange: null,
    proxyNumLags: null,
    unproxiedStartRange: null,
    unproxiedEndRange: null,
    unproxiedNumReturns: null,
    interpolated: false,
    live: null,
    frequency: null,
    unproxiedFrequency: null,
    currency: null,
    currencyName: null,
    active: null,
    permissionGroupId: null,
    userUploaded: null,
    investmentSourceName: null,
    dataSource: null,
    assetType: null,
    remoteType: null,
    accountNumber: null,
    category: null,
    tags: null,
    tagIds: ['1800351'],
    periodAnnualized: null,
    periodReturn: null,
    annualizedTotalReturn: null,
    annualizedVolatility: null,
    annualizedSharpeRatio: null,
    annualizedResidualReturn: null,
    tenYearCumulativeReturn: null,
    cumulativeReturn: null,
    tenYearCumulativeReturnSeries: null,
    maxDrawdown: null,
    factorLensId: null,
    factorLensName: null,
    riskContribution: [],
    groupedRiskContribution: [],
    periodAlpha: null,
    annualizedAlpha: null,
    annualizedAlphaTStat: null,
    investmentSource: null,
    proxyId: null,
    proxyName: null,
    proxyType: null,
    interpolationCategory: null,
    interpolatedFundId: null,
    master: false,
    currentlyProcessing: false,
    mappingIsComplete: false,
    hasFactorAnalysis: false,
    fileSize: null,
    version: null,
    needsProxy: false,
    needsInterpolation: false,
    proxyable: false,
    interpolatable: false,
    outOfDate: false,
    hasMinDataForInterpolation: false,
  },
  {
    fundId: null,
    portfolioId: null,
    documentId: null,
    owner: null,
    name: 'BM20',
    symbol: null,
    organizationId: null,
    created: null,
    updated: null,
    startRange: null,
    endRange: null,
    proxyStartRange: null,
    proxyEndRange: null,
    proxyNumLags: null,
    unproxiedStartRange: null,
    unproxiedEndRange: null,
    unproxiedNumReturns: null,
    interpolated: false,
    live: null,
    frequency: null,
    unproxiedFrequency: null,
    currency: null,
    currencyName: null,
    active: null,
    permissionGroupId: null,
    userUploaded: null,
    investmentSourceName: null,
    dataSource: null,
    assetType: null,
    remoteType: null,
    accountNumber: null,
    category: null,
    tags: null,
    tagIds: ['2b1fcf86-ec00-4334-a1c2-f9a285e638db'],
    periodAnnualized: null,
    periodReturn: null,
    annualizedTotalReturn: null,
    annualizedVolatility: null,
    annualizedSharpeRatio: null,
    annualizedResidualReturn: null,
    tenYearCumulativeReturn: null,
    cumulativeReturn: null,
    tenYearCumulativeReturnSeries: null,
    maxDrawdown: null,
    factorLensId: null,
    factorLensName: null,
    riskContribution: [],
    groupedRiskContribution: [],
    periodAlpha: null,
    annualizedAlpha: null,
    annualizedAlphaTStat: null,
    investmentSource: null,
    proxyId: null,
    proxyName: null,
    proxyType: null,
    interpolationCategory: null,
    interpolatedFundId: null,
    master: false,
    currentlyProcessing: false,
    mappingIsComplete: false,
    hasFactorAnalysis: false,
    fileSize: null,
    version: null,
    needsProxy: false,
    needsInterpolation: false,
    proxyable: false,
    interpolatable: false,
    outOfDate: false,
    hasMinDataForInterpolation: false,
  },
  {
    fundId: null,
    portfolioId: null,
    documentId: null,
    owner: null,
    name: 'hello',
    symbol: null,
    organizationId: null,
    created: null,
    updated: null,
    startRange: null,
    endRange: null,
    proxyStartRange: null,
    proxyEndRange: null,
    proxyNumLags: null,
    unproxiedStartRange: null,
    unproxiedEndRange: null,
    unproxiedNumReturns: null,
    interpolated: false,
    live: null,
    frequency: null,
    unproxiedFrequency: null,
    currency: null,
    currencyName: null,
    active: null,
    permissionGroupId: null,
    userUploaded: null,
    investmentSourceName: null,
    dataSource: null,
    assetType: null,
    remoteType: null,
    accountNumber: null,
    category: null,
    tags: null,
    tagIds: ['7197cc89-a431-41f6-8ffc-9d502d1cbf53', '803004'],
    periodAnnualized: null,
    periodReturn: null,
    annualizedTotalReturn: null,
    annualizedVolatility: null,
    annualizedSharpeRatio: null,
    annualizedResidualReturn: null,
    tenYearCumulativeReturn: null,
    cumulativeReturn: null,
    tenYearCumulativeReturnSeries: null,
    maxDrawdown: null,
    factorLensId: null,
    factorLensName: null,
    riskContribution: [],
    groupedRiskContribution: [],
    periodAlpha: null,
    annualizedAlpha: null,
    annualizedAlphaTStat: null,
    investmentSource: null,
    proxyId: null,
    proxyName: null,
    proxyType: null,
    interpolationCategory: null,
    interpolatedFundId: null,
    master: false,
    currentlyProcessing: false,
    mappingIsComplete: false,
    hasFactorAnalysis: false,
    fileSize: null,
    version: null,
    needsProxy: false,
    needsInterpolation: false,
    proxyable: false,
    interpolatable: false,
    outOfDate: false,
    hasMinDataForInterpolation: false,
  },
] as unknown as LibrarySearchEntity[];

export const mockTagsContents = {
  BM2: [
    {
      fundId: null,
      portfolioId: 1800351,
      documentId: null,
      owner: {
        id: 3114478,
        name: 'A-BM-QA',
        displayName: 'A-BM-QA',
        email: 'bartlomiej.majewski+test@codete.com',
        active: true,
        created: 1603724509356,
        pwdLastUpdated: null,
        avatarId: null,
        actualUser: false,
        accountId: 1,
      },
      name: 'BartekMajewski2',
      symbol: null,
      organizationId: 1,
      created: 1606288807778,
      updated: 1609838603062,
      startRange: 1488326399999,
      endRange: 1554076799999,
      proxyStartRange: null,
      proxyEndRange: null,
      proxyNumLags: null,
      unproxiedStartRange: null,
      unproxiedEndRange: null,
      unproxiedNumReturns: null,
      interpolated: false,
      live: null,
      frequency: 'MONTHLY',
      unproxiedFrequency: null,
      currency: 'USD',
      currencyName: 'United States dollar',
      active: true,
      permissionGroupId: 2,
      userUploaded: null,
      investmentSourceName: null,
      dataSource: null,
      assetType: null,
      remoteType: 'LOCAL',
      accountNumber: null,
      category: null,
      tags: ['BM2'],
      tagIds: [],
      periodAnnualized: true,
      periodReturn: 0.05558894724854091,
      annualizedTotalReturn: 0.05558894724854091,
      annualizedVolatility: 0.07740385654474971,
      annualizedSharpeRatio: 0.5327651355141706,
      annualizedResidualReturn: null,
      tenYearCumulativeReturn: 0.12436022594711793,
      cumulativeReturn: 0.12436022594711793,
      tenYearCumulativeReturnSeries: [
        [1485907199999, 0],
        [1488326399999, 0.010121258137324007],
        [1491004799999, 0.011541046666829757],
        [1493596799999, 0.02167093329081604],
        [1496275199999, 0.018894762916194408],
        [1498867199999, 0.028266484217085885],
        [1501545599999, 0.03985187305959981],
        [1504223999999, 0.037219622295967536],
        [1506815999999, 0.06346653750851128],
        [1509494399999, 0.0757898383166169],
        [1512086399999, 0.09036295577923092],
        [1514764799999, 0.09310912287693585],
        [1517443199999, 0.10628596008866209],
        [1519862399999, 0.07779630310787433],
        [1522540799999, 0.08120586876931468],
        [1525132799999, 0.08209473539819068],
        [1527811199999, 0.11217853649315224],
        [1530403199999, 0.11546262503898763],
        [1533081599999, 0.13315178223781654],
        [1535759999999, 0.15875950333146083],
        [1538351999999, 0.1450458996827655],
        [1541030399999, 0.08518278487759612],
        [1543622399999, 0.09560206546959571],
        [1546300799999, 0.03845534085986846],
        [1548979199999, 0.09499126595480845],
        [1551398399999, 0.12317572410132605],
        [1554076799999, 0.12436022594711793],
      ],
      maxDrawdown: null,
      factorLensId: 71,
      factorLensName: 'V4-USD',
      riskContribution: [
        { id: -1, name: 'Residual', value: 0.06928801540269514 },
        { id: 10000, name: 'Equity', value: 0.719160687895451 },
        { id: 10070, name: 'Small Cap', value: 0.2115512967018539 },
      ],
      groupedRiskContribution: [
        { id: -1, name: 'Residual', value: 0.06928801540269514 },
        { id: null, name: 'Core Macro', value: 0.719160687895451 },
        { id: null, name: 'Equity Styles', value: 0.2115512967018539 },
      ],
      periodAlpha: null,
      annualizedAlpha: null,
      annualizedAlphaTStat: null,
      investmentSource: null,
      proxyId: null,
      proxyName: null,
      proxyType: null,
      interpolationCategory: null,
      interpolatedFundId: null,
      master: false,
      currentlyProcessing: false,
      mappingIsComplete: false,
      hasFactorAnalysis: true,
      fileSize: null,
      version: null,
      needsProxy: false,
      needsInterpolation: false,
      proxyable: false,
      interpolatable: false,
      outOfDate: true,
      hasMinDataForInterpolation: false,
    },
  ],
  BM20: [
    {
      fundId: '2b1fcf86-ec00-4334-a1c2-f9a285e638db',
      portfolioId: null,
      documentId: null,
      owner: {
        id: -42,
        name: `${getAppTitleSimplifiedUtil()}`,
        displayName: `${getAppTitleSimplifiedUtil()}`,
        email: null,
        active: true,
        created: null,
        pwdLastUpdated: null,
        avatarId: null,
        actualUser: false,
        accountId: null,
      },
      name: 'BofA Merrill Lynch US High Yield Cash Pay Constrained Index Gross TR',
      symbol: 'JUC0',
      organizationId: null,
      created: 1579907546433,
      updated: 1619268414129,
      startRange: 1283558399999,
      endRange: 1619222399999,
      proxyStartRange: null,
      proxyEndRange: null,
      unproxiedStartRange: 1283558399999,
      unproxiedEndRange: 1619222399999,
      unproxiedNumReturns: 2776,
      interpolated: false,
      live: true,
      frequency: 'DAILY',
      unproxiedFrequency: 'DAILY',
      currency: 'USD',
      currencyName: 'United States dollar',
      active: true,
      permissionGroupId: 1,
      userUploaded: false,
      investmentSourceName: `${getAppTitleSimplifiedUtil()}`,
      dataSource: null,
      assetType: 'INDEX',
      remoteType: 'DATA',
      accountNumber: null,
      category: null,
      tags: ['BM20'],
      tagIds: [],
      periodAnnualized: true,
      periodReturn: 0.06909595680089575,
      annualizedTotalReturn: 0.06909595680089575,
      annualizedVolatility: 0.0490696436114968,
      annualizedSharpeRatio: 1.272436450213901,
      annualizedResidualReturn: null,
      tenYearCumulativeReturn: 0.8371186027153501,
      cumulativeReturn: 1.035269519868658,
      tenYearCumulativeReturnSeries: [
        [1304207999999, 0],
        [1306886399999, 0.005175794854208826],
        [1309478399999, -0.004686433988077221],
        [1312156799999, 0.007251113563867317],
        [1314835199999, -0.03194419142939964],
        [1317427199999, -0.06617802019596009],
        [1320105599999, -0.011280303760936916],
        [1322697599999, -0.03266215883153689],
        [1325375999999, -0.008547741260266917],
        [1328054399999, 0.01949585114856789],
        [1330559999999, 0.042588683262071214],
        [1333238399999, 0.04160996152980534],
        [1335830399999, 0.0521258621859777],
        [1338508799999, 0.039631086202523536],
        [1341100799999, 0.06044499691024008],
        [1343779199999, 0.0811874680754554],
        [1346457599999, 0.09392156652628936],
        [1349049599999, 0.10874884357240755],
        [1351727999999, 0.11843604553556641],
        [1354319999999, 0.12646585011269762],
        [1356998399999, 0.14411498908761167],
        [1359676799999, 0.15954235829074026],
        [1362095999999, 0.16499319538357993],
        [1364774399999, 0.17650210568051805],
        [1367366399999, 0.19885553852913374],
        [1370044799999, 0.19232239236740156],
        [1372636799999, 0.16034605314387806],
        [1375315199999, 0.18254946295324226],
        [1377993599999, 0.17530906532074808],
        [1380585599999, 0.18723232496419318],
        [1383263999999, 0.21633679457916943],
        [1385855999999, 0.22150901745625373],
        [1388534399999, 0.22844579703311685],
        [1391212799999, 0.23756148265626553],
        [1393631999999, 0.2624045835610478],
        [1396310399999, 0.26517286583296795],
        [1398902399999, 0.27352414835135397],
        [1401580799999, 0.2856295788281775],
        [1404172799999, 0.29661340848773254],
        [1406851199999, 0.2794321985162018],
        [1409529599999, 0.29874587883139303],
        [1412121599999, 0.27186675096532764],
        [1414799999999, 0.28651542915519257],
        [1417391999999, 0.2769818222084117],
        [1420070399999, 0.2585789960601106],
        [1422748799999, 0.2671017334804995],
        [1425167999999, 0.29671342384723354],
        [1427846399999, 0.29033387270187916],
        [1430438399999, 0.30598270448676046],
        [1433116799999, 0.3096368370856939],
        [1435708799999, 0.28988380358412025],
        [1438387199999, 0.2822433445136203],
        [1441065599999, 0.25948985022699733],
        [1443657599999, 0.22729204842172024],
        [1446335999999, 0.26094007293977084],
        [1448927999999, 0.2311069199912823],
        [1451606399999, 0.2017024042978004],
        [1454284799999, 0.1825101712048618],
        [1456790399999, 0.188400361484083],
        [1459468799999, 0.24053336762431132],
        [1462060799999, 0.28957304157423946],
        [1464739199999, 0.2983351014620079],
        [1467331199999, 0.3122086606157346],
        [1470009599999, 0.34525302099965094],
        [1472687999999, 0.3754790914318966],
        [1475279999999, 0.38443761006154387],
        [1477958399999, 0.3885882474808615],
        [1480550399999, 0.3830909746853961],
        [1483228799999, 0.41008440581946237],
        [1485907199999, 0.4290301724907737],
        [1488326399999, 0.45134788556813943],
        [1491004799999, 0.4482509814007136],
        [1493596799999, 0.46417128344709924],
        [1496275199999, 0.47786981572169895],
        [1498867199999, 0.4794307697253506],
        [1501545599999, 0.4965548280628802],
        [1504223999999, 0.4962369220987506],
        [1506815999999, 0.5094318055987164],
        [1509494399999, 0.5149540822340561],
        [1512086399999, 0.5112463699782457],
        [1514764799999, 0.515379147511938],
        [1517443199999, 0.5252663801940995],
        [1519862399999, 0.5108641684258632],
        [1522540799999, 0.5011233868058289],
        [1525132799999, 0.5113606732462463],
        [1527811199999, 0.5111356386873676],
        [1530403199999, 0.5162007022506998],
        [1533081599999, 0.5334604957189808],
        [1535759999999, 0.5444764731726615],
        [1538351999999, 0.5529134831420497],
        [1541030399999, 0.5280132306032679],
        [1543622399999, 0.5144290015966704],
        [1546300799999, 0.48130962969312807],
        [1548979199999, 0.5493415060169939],
        [1551398399999, 0.575441942869797],
        [1554076799999, 0.590608557742794],
        [1556668799999, 0.6131727372417883],
        [1559347199999, 0.5926017209785766],
        [1561939199999, 0.6312112217233334],
        [1564617599999, 0.6400661530163532],
        [1567295999999, 0.6466635947663368],
        [1569887999999, 0.6518536775290455],
        [1572566399999, 0.6557578485267344],
        [1575158399999, 0.6598656222205508],
        [1577836799999, 0.6946066717388693],
        [1580515199999, 0.6947316909382468],
        [1583020799999, 0.668016874019935],
        [1585699199999, 0.4730440746257438],
        [1588291199999, 0.5290348160610361],
        [1590969599999, 0.5981740052936682],
        [1593561599999, 0.6132548927156645],
        [1596239999999, 0.6898773740252944],
        [1598918399999, 0.7068335494379467],
        [1601510399999, 0.6891629786002826],
        [1604188799999, 0.6968248695335342],
        [1606780799999, 0.7642352218376383],
        [1609459199999, 0.7980225534635679],
        [1612137599999, 0.8034876784649074],
        [1614556799999, 0.8086384694792423],
        [1617235199999, 0.8126998074704341],
      ],
      maxDrawdown: {
        start: null,
        end: null,
        trough: null,
        troughDrawdown: -0.21539130832989906,
        troughDrawdownError: 0,
        completed: true,
      },
      factorLensId: 71,
      factorLensName: 'V4-USD',
      riskContribution: [
        { id: -1, name: 'Residual', value: 0.21652870220238118 },
        { id: 10000, name: 'Equity', value: 0.3997510661352516 },
        { id: 10001, name: 'Interest Rates', value: 0.00957407286328355 },
        { id: 10002, name: 'Credit', value: 0.23955579254237766 },
        { id: 10066, name: 'Low Risk', value: 0.01883463381431912 },
        { id: 10003, name: 'Commodities', value: 0.0005544883695598768 },
        { id: 10004, name: 'Foreign Currency', value: 0.035014154405832956 },
        { id: 10068, name: 'Quality', value: 0.04008307808750182 },
        { id: 10005, name: 'Local Inflation', value: -0.008353260354396845 },
        { id: 10037, name: 'Fixed Income Carry', value: -0.0006299873906092077 },
        { id: 10069, name: 'Value', value: 0.0021963736136472777 },
        { id: 10006, name: 'Emerging Markets', value: 0.01366107638467171 },
        { id: 10070, name: 'Small Cap', value: 0.01705930966086904 },
        { id: 10039, name: 'Trend Following', value: 0.003638328285638617 },
        { id: 10071, name: 'Crowding', value: 0.016149943385971522 },
        { id: 10045, name: 'Local Equity', value: -0.0053181007971027814 },
        { id: 10046, name: 'Equity Short Volatility', value: 0.0017003287908030054 },
      ],
      groupedRiskContribution: [
        { id: -1, name: 'Residual', value: 0.21652870220238118 },
        { id: null, name: 'Core Macro', value: 0.6494354199104726 },
        { id: null, name: 'Equity Styles', value: 0.09432333856230878 },
        { id: null, name: 'Secondary Macro', value: 0.03500386963900504 },
        { id: null, name: 'Macro Styles', value: 0.004708669685832415 },
      ],
      periodAlpha: null,
      annualizedAlpha: null,
      annualizedAlphaTStat: null,
      investmentSource: 'VENN',
      proxyId: null,
      proxyName: null,
      proxyType: null,
      interpolationCategory: null,
      interpolatedFundId: null,
      master: false,
      currentlyProcessing: false,
      mappingIsComplete: false,
      hasFactorAnalysis: true,
      fileSize: null,
      version: null,
      needsProxy: false,
      needsInterpolation: false,
      proxyable: true,
      interpolatable: false,
      outOfDate: false,
      hasMinDataForInterpolation: true,
    },
  ],
  hello: [
    {
      fundId: '7197cc89-a431-41f6-8ffc-9d502d1cbf53',
      portfolioId: null,
      documentId: null,
      owner: {
        id: -42,
        name: `${getAppTitleSimplifiedUtil()}`,
        displayName: `${getAppTitleSimplifiedUtil()}`,
        email: null,
        active: true,
        created: null,
        pwdLastUpdated: null,
        avatarId: null,
        actualUser: false,
        accountId: null,
      },
      name: 'CHIPOTLE MEXICAN GRILL INC',
      symbol: 'CMG',
      organizationId: null,
      created: 1579902572373,
      updated: 1619262120180,
      startRange: 1138406399999,
      endRange: 1619135999999,
      proxyStartRange: null,
      proxyEndRange: null,
      unproxiedStartRange: 1138406399999,
      unproxiedEndRange: 1619135999999,
      unproxiedNumReturns: 3975,
      interpolated: false,
      live: true,
      frequency: 'DAILY',
      unproxiedFrequency: 'DAILY',
      currency: 'USD',
      currencyName: 'United States dollar',
      active: true,
      permissionGroupId: 1,
      userUploaded: false,
      investmentSourceName: `${getAppTitleSimplifiedUtil()}`,
      dataSource: null,
      assetType: 'STOCK',
      remoteType: 'DATA',
      accountNumber: null,
      category: null,
      tags: ['hello'],
      tagIds: [],
      periodAnnualized: true,
      periodReturn: 0.2596528037125394,
      annualizedTotalReturn: 0.2596528037125394,
      annualizedVolatility: 0.3991180192565486,
      annualizedSharpeRatio: 0.7506126297509988,
      annualizedResidualReturn: null,
      tenYearCumulativeReturn: 4.33717994951318,
      cumulativeReturn: 32.6363636363636,
      tenYearCumulativeReturnSeries: [
        [1304207999999, 0],
        [1306886399999, 0.08351137598860503],
        [1309478399999, 0.15517823006859288],
        [1312156799999, 0.2166123168034786],
        [1314835199999, 0.1745942501593014],
        [1317427199999, 0.13553731399227864],
        [1320105599999, 0.2598673113684917],
        [1322697599999, 0.2052925521946094],
        [1325375999999, 0.26593950297987146],
        [1328054399999, 0.37670077589114936],
        [1330559999999, 0.4626485250571595],
        [1333238399999, 0.5667753663930415],
        [1335830399999, 0.5523445406499485],
        [1338508799999, 0.5482964129090289],
        [1341100799999, 0.4241538288541542],
        [1343779199999, 0.09573072453989995],
        [1346457599999, 0.08189962142509044],
        [1349049599999, 0.1902245211589635],
        [1351727999999, -0.045953746392294104],
        [1354319999999, -0.011282281944601569],
        [1356998399999, 0.11495933130926872],
        [1359676799999, 0.15075527568499458],
        [1362095999999, 0.18741332133888],
        [1364774399999, 0.22144758049402036],
        [1367366399999, 0.36133288354136117],
        [1370044799999, 0.3531241800667184],
        [1372636799999, 0.3656808725964231],
        [1375315199999, 0.5452977997676061],
        [1377993599999, 0.5299299074178185],
        [1380585599999, 0.6068818171595631],
        [1383263999999, 0.975223958918999],
        [1385855999999, 0.9635668503317212],
        [1388534399999, 0.9970013868585772],
        [1391212799999, 1.068893136924172],
        [1393631999999, 1.1185576670789756],
        [1396310399999, 1.1292027437310241],
        [1398902399999, 0.8685108137486408],
        [1401580799999, 1.0506390794257645],
        [1404172799999, 1.2208853405300033],
        [1406851199999, 1.5207091720079449],
        [1409529599999, 1.5437610105326272],
        [1412121599999, 1.498556917425689],
        [1414799999999, 1.3913939802841169],
        [1417391999999, 1.4874245661381598],
        [1420070399999, 1.5657258517935442],
        [1422748799999, 1.6606694403838214],
        [1425167999999, 1.4924847258143092],
        [1427846399999, 1.4383972412759074],
        [1430438399999, 1.3289478616139987],
        [1433116799999, 1.3071329510101548],
        [1435708799999, 1.2676637055361866],
        [1438387199999, 1.782075789947147],
        [1441065599999, 1.661306645676372],
        [1443657599999, 1.6996888938865746],
        [1446335999999, 1.3997526144158297],
        [1448927999999, 1.1723078076389633],
        [1451606399999, 0.7986056448892356],
        [1454284799999, 0.6978522433374534],
        [1456790399999, 0.9084673338580875],
        [1459468799999, 0.7653210390194505],
        [1462060799999, 0.5779077176805709],
        [1464739199999, 0.6565838299786324],
        [1467331199999, 0.5096517860489493],
        [1470009599999, 0.589227482289437],
        [1472687999999, 0.550770268750699],
        [1475279999999, 0.5873908317403163],
        [1477958399999, 0.35222459612428914],
        [1480550399999, 0.48555043292476907],
        [1483228799999, 0.41429588815172647],
        [1485907199999, 0.5796694029011544],
        [1488326399999, 0.5695490835488553],
        [1491004799999, 0.6699276584579585],
        [1493596799999, 0.7784399715131705],
        [1496275199999, 0.7892349788222901],
        [1498867199999, 0.559653660182162],
        [1501545599999, 0.2885415495333379],
        [1504223999999, 0.18711346002473506],
        [1506815999999, 0.15382885415494973],
        [1509494399999, 0.019153641440830427],
        [1512086399999, 0.14093481764683546],
        [1514764799999, 0.08336144533153145],
        [1517443199999, 0.21728700476029528],
        [1519862399999, 0.1934855129502573],
        [1522540799999, 0.21110236515611258],
        [1525132799999, 0.586753626447764],
        [1527811199999, 0.61242925147119],
        [1530403199999, 0.6168896885190542],
        [1533081599999, 0.6254732186363747],
        [1535759999999, 0.7811012406761804],
        [1538351999999, 0.7036620562989562],
        [1541030399999, 0.7254394842385343],
        [1543622399999, 0.7737171558154303],
        [1546300799999, 0.6184639604183024],
        [1548979199999, 0.9851193822856872],
        [1551398399999, 1.2771843022601979],
        [1554076799999, 1.6624311256043982],
        [1556668799999, 1.5789572322800618],
        [1559347199999, 1.4737433936804134],
        [1561939199999, 1.7470294988567665],
        [1564617599999, 1.981858390494383],
        [1567295999999, 2.1426215375388717],
        [1569887999999, 2.1503054837137663],
        [1572566399999, 1.9167510026612549],
        [1575158399999, 2.0507890100828217],
        [1577836799999, 2.137711308519793],
        [1580515199999, 2.2488474080737477],
        [1583020799999, 1.8995839424266112],
        [1585699199999, 1.4528655496832577],
        [1588291199999, 2.2930394692454543],
        [1590969599999, 2.762922148506294],
        [1593561599999, 2.9445256568836684],
        [1596239999999, 3.3298474455564033],
        [1598918399999, 3.911278533678141],
        [1601510399999, 3.661756437647557],
        [1604188799999, 3.5034671464447387],
        [1606780799999, 3.8331271786798293],
        [1609459199999, 4.197758536676754],
        [1612137599999, 4.5474343116308376],
        [1614556799999, 4.405000187413289],
        [1617235199999, 4.325611904494141],
      ],
      maxDrawdown: {
        start: null,
        end: null,
        trough: null,
        troughDrawdown: -0.7460619585192965,
        troughDrawdownError: 0,
        completed: true,
      },
      factorLensId: 71,
      factorLensName: 'V4-USD',
      riskContribution: [
        { id: -1, name: 'Residual', value: 0.7339387907364292 },
        { id: 10000, name: 'Equity', value: 0.18792977504609626 },
        { id: 10001, name: 'Interest Rates', value: -0.002794706571723688 },
        { id: 10002, name: 'Credit', value: 0.00019624023307594673 },
        { id: 10066, name: 'Low Risk', value: 0.0024853527328860905 },
        { id: 10003, name: 'Commodities', value: 0.002264248026074259 },
        { id: 10004, name: 'Foreign Currency', value: 0.00037140160037764657 },
        { id: 10068, name: 'Quality', value: -0.0053895919550599036 },
        { id: 10037, name: 'Fixed Income Carry', value: 0.0009170521099385016 },
        { id: 10069, name: 'Value', value: 0.00012986439685114376 },
        { id: 10006, name: 'Emerging Markets', value: 0.0012282426182479733 },
        { id: 10038, name: 'Foreign Exchange Carry', value: 0.002459407405027689 },
        { id: 10070, name: 'Small Cap', value: 0.04271965665147122 },
        { id: 10039, name: 'Trend Following', value: -0.0008070860266956389 },
        { id: 10045, name: 'Local Equity', value: 0.03435135299700333 },
      ],
      groupedRiskContribution: [
        { id: -1, name: 'Residual', value: 0.7339387907364292 },
        { id: null, name: 'Core Macro', value: 0.18759555673352277 },
        { id: null, name: 'Equity Styles', value: 0.03994528182614855 },
        { id: null, name: 'Secondary Macro', value: 0.03595099721562895 },
        { id: null, name: 'Macro Styles', value: 0.002569373488270552 },
      ],
      periodAlpha: null,
      annualizedAlpha: null,
      annualizedAlphaTStat: null,
      investmentSource: 'VENN',
      proxyId: null,
      proxyName: null,
      proxyType: null,
      interpolationCategory: null,
      interpolatedFundId: null,
      master: false,
      currentlyProcessing: false,
      mappingIsComplete: false,
      hasFactorAnalysis: true,
      fileSize: null,
      version: null,
      needsProxy: false,
      needsInterpolation: false,
      proxyable: true,
      interpolatable: false,
      outOfDate: false,
      hasMinDataForInterpolation: true,
    },
    {
      fundId: null,
      portfolioId: 803004,
      documentId: null,
      owner: {
        id: -42,
        name: `${getAppTitleSimplifiedUtil()}`,
        displayName: `${getAppTitleSimplifiedUtil()}`,
        email: null,
        active: true,
        created: null,
        pwdLastUpdated: null,
        avatarId: null,
        actualUser: false,
        accountId: null,
      },
      name: 'Allocation & Deviation: Rozelia Sullivan Herring (273316)',
      symbol: null,
      organizationId: 1,
      created: 1595477959555,
      updated: 1597776885121,
      startRange: null,
      endRange: null,
      proxyStartRange: null,
      proxyEndRange: null,
      unproxiedStartRange: null,
      unproxiedEndRange: null,
      unproxiedNumReturns: null,
      interpolated: false,
      live: null,
      frequency: null,
      unproxiedFrequency: null,
      currency: 'USD',
      currencyName: 'United States dollar',
      active: true,
      permissionGroupId: 2,
      userUploaded: null,
      investmentSourceName: null,
      dataSource: 'Addepar',
      assetType: null,
      remoteType: 'CUSTODIAN',
      accountNumber: null,
      category: null,
      tags: ['🌎', 'hello', 'Hold', 'new tag6', 'Sell'],
      tagIds: [],
      periodAnnualized: null,
      periodReturn: null,
      annualizedTotalReturn: null,
      annualizedVolatility: null,
      annualizedSharpeRatio: null,
      annualizedResidualReturn: null,
      tenYearCumulativeReturn: null,
      cumulativeReturn: null,
      tenYearCumulativeReturnSeries: null,
      maxDrawdown: null,
      factorLensId: 71,
      factorLensName: 'V4-USD',
      riskContribution: [],
      groupedRiskContribution: [],
      periodAlpha: null,
      annualizedAlpha: null,
      annualizedAlphaTStat: null,
      investmentSource: null,
      proxyId: null,
      proxyName: null,
      proxyType: null,
      interpolationCategory: null,
      interpolatedFundId: null,
      master: false,
      currentlyProcessing: false,
      mappingIsComplete: false,
      hasFactorAnalysis: false,
      fileSize: null,
      version: null,
      needsProxy: false,
      needsInterpolation: false,
      proxyable: false,
      interpolatable: false,
      outOfDate: false,
      hasMinDataForInterpolation: false,
    },
  ],
};

export const privatePortfolioMockSearchResult: LibrarySearchResult = {
  results: [
    {
      fundId: null!,
      portfolioId: null!,
      privateFundId: null!,
      privatePortfolioId: '2da938a3-069d-4e4d-ac83-12064ab1b809',
      owner: {
        id: -1,
        name: `${getAppTitleSimplifiedUtil()}`,
        displayName: `${getAppTitleSimplifiedUtil()}`,
        email: undefined!,
        active: true,
        created: null!,
        pwdLastUpdated: null!,
        avatarId: null!,
        actualUser: false,
        accountId: 1,
      },
      ownerContextId: null!,
      ownerContextFriendlyName: null!,
      name: 'Existing Private Portfolio',
      symbol: null!,
      organizationId: null!,
      created: 1692352342689,
      updated: 1695305100946,
      startRange: null!,
      endRange: null!,
      proxyStartRange: null!,
      proxyEndRange: null!,
      unproxiedStartRange: null!,
      unproxiedEndRange: null!,
      unproxiedNumReturns: null!,
      interpolated: false,
      extrapolated: false,
      live: null!,
      frequency: null!,
      unproxiedFrequency: null!,
      currency: null!,
      currencyName: null!,
      active: true,
      permissionGroupId: 2,
      userUploaded: true,
      investmentSourceName: null!,
      dataSource: null!,
      shareClass: null!,
      primaryShareClass: null!,
      assetType: null!,
      remoteType: null!,
      accountNumber: null!,
      morningstarCategories: null!,
      morningstarCategoryCodes: null!,
      category: null!,
      tags: null!,
      tagIds: [],
      periodAnnualized: null!,
      periodReturn: null!,
      annualizedTotalReturn: null!,
      annualizedVolatility: null!,
      annualizedSharpeRatio: null!,
      annualizedResidualReturn: null!,
      tenYearCumulativeReturn: null!,
      cumulativeReturn: null!,
      tenYearCumulativeReturnSeries: null!,
      maxDrawdown: null!,
      factorLensId: 71,
      factorLensName: 'V4-USD',
      periodAlpha: null!,
      annualizedAlpha: null!,
      annualizedAlphaTStat: null!,
      metricsOnTimePeriod: null!,
      investmentSource: null!,
      proxyId: null!,
      proxyName: null!,
      proxyType: null!,
      proxyNumLags: null!,
      proxyCategory: null!,
      interpolatedFundId: null!,
      master: false,
      currentlyProcessing: false,
      hasFactorAnalysis: false,
      fileSize: null!,
      version: null!,
      assetClass: 'PRIVATE',
      externalId: null!,
      vintage: null!,
      size: null!,
      status: null!,
      privateFundAssetClass: null!,
      strategy: null!,
      subStrategy: null!,
      manager: null!,
      geographicFocus: null!,
      industries: null!,
      privateFundPerformance: null!,
      shareClassName: null!,
      proxyable: false,
      morningstarCategory: null!,
      interpolatable: false,
      needsInterpolation: false,
      hasMinDataForInterpolation: false,
      interpolationCategory: null!,
      needsProxy: false,
      outOfDate: false,
      capitalCommitment: undefined!,
      privateFundType: undefined!,
      finalCloseDate: undefined!,
      finalCloseSize: undefined!,
      coreIndustries: undefined!,
      historicalPortfolioId: undefined!,
      investmentType: undefined!,
    },
  ],
  total: 1,
};
