import React from 'react';
import styled from 'styled-components';
import Cell from './Cell';
import { NumberCol } from './styles';

interface RowProps extends React.BaseHTMLAttributes<HTMLTableRowElement> {
  y: number;
  onChangedValue: ({ x, y }: { x: number; y: number }, value: string) => void;
  handlePaste: (x: number, y: number, data: string[][]) => void;
  rowData: string[];
  originRowData: string[];
  isNewRow: boolean;
  isReturn: boolean;
  /**
   *  When true the Row cannot be edited and appears greyed out
   */
  disabled?: boolean;
  /**
   *  When the Row is disabled this content will be displayed in the inner Cell's tooltip
   */
  disabledTooltipContent?: string;
}

const Row = ({
  style,
  className,
  y,
  onChangedValue,
  rowData,
  handlePaste,
  isNewRow,
  isReturn,
  originRowData,
  disabled = false,
  disabledTooltipContent,
}: RowProps) => (
  <Tr className={className} style={style}>
    <NumberCol>{y}</NumberCol>
    {rowData &&
      rowData.map((cell, i) => (
        <Cell
          // eslint-disable-next-line react/no-array-index-key
          key={`${i}-${y}`}
          y={y}
          x={i}
          onChangedValue={onChangedValue}
          value={cell || ''}
          handlePaste={handlePaste}
          isDate={i === 0}
          isNewRow={isNewRow}
          isReturn={isReturn}
          originData={originRowData[i] ?? ''}
          disabled={disabled}
          disabledTooltipContent={disabledTooltipContent}
        />
      ))}
  </Tr>
);

export default Row;

const Tr = styled.div`
  height: 35px;
  display: flex;
`;
