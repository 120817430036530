/*
 * Venn Platform API 1.0.0 Operations for "user-v2"
 * Automatically generated file - do not modify!
 */

import type { MFAResponse, MFAStatus, SetupPhoneRequest, UserOneTimePassword, VerificationCode } from '../types';
import type { OperationResult } from '../../utils';
import { buildRequestInit, extractError, retryGeneratedFetch } from '../../utils';

/** Allow the user to verify that they can generate TOTP codes */
export async function verifyTOTPMFA(
  body?: Partial<VerificationCode>,
  signal?: AbortSignal,
): Promise<OperationResult<MFAStatus>> {
  const endpoint = '/api/user/mfa/v2/totp/verify';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: MFAStatus = (await response.json()) as MFAStatus;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Allow the user to verify that they got the text and enable SMS 2FA */
export async function verifyPhoneMFA(
  body?: Partial<VerificationCode>,
  signal?: AbortSignal,
): Promise<OperationResult<MFAStatus>> {
  const endpoint = '/api/user/mfa/v2/phone/verify';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: MFAStatus = (await response.json()) as MFAStatus;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Disable 2FA */
export async function disableTwoFactor(signal?: AbortSignal): Promise<OperationResult<MFAStatus>> {
  const endpoint = '/api/user/mfa/v2/enable';
  const requestInit = buildRequestInit({ method: 'DELETE', signal });
  const response = await retryGeneratedFetch('DELETE', () => fetch(endpoint, requestInit));
  if ((response.status >= 200 && response.status < 400) || response.status === 404) {
    const content: MFAStatus = (await response.json()) as MFAStatus;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Disable TOTP as a second factor */
export async function disableTOTPMFA(signal?: AbortSignal): Promise<OperationResult<MFAStatus>> {
  const endpoint = '/api/user/mfa/v2/totp';
  const requestInit = buildRequestInit({ method: 'DELETE', signal });
  const response = await retryGeneratedFetch('DELETE', () => fetch(endpoint, requestInit));
  if ((response.status >= 200 && response.status < 400) || response.status === 404) {
    const content: MFAStatus = (await response.json()) as MFAStatus;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Disable phone as a second factor */
export async function disablePhoneMFA(signal?: AbortSignal): Promise<OperationResult<MFAStatus>> {
  const endpoint = '/api/user/mfa/v2/phone';
  const requestInit = buildRequestInit({ method: 'DELETE', signal });
  const response = await retryGeneratedFetch('DELETE', () => fetch(endpoint, requestInit));
  if ((response.status >= 200 && response.status < 400) || response.status === 404) {
    const content: MFAStatus = (await response.json()) as MFAStatus;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Enable 2FA assuming secondary factors are setup */
export async function enableTwoFactor(signal?: AbortSignal): Promise<OperationResult<MFAStatus>> {
  const endpoint = '/api/user/mfa/v2/enable';
  const requestInit = buildRequestInit({ method: 'POST', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: MFAStatus = (await response.json()) as MFAStatus;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Generate a new collection of backup codes for the user, destroying old ones */
export async function generateMoreBackupCodes(signal?: AbortSignal): Promise<OperationResult<UserOneTimePassword[]>> {
  const endpoint = '/api/user/mfa/v2/backupcodes/generate';
  const requestInit = buildRequestInit({ method: 'POST', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: UserOneTimePassword[] = (await response.json()) as UserOneTimePassword[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get a users MFA status */
export async function getUserMFAStatus(signal?: AbortSignal): Promise<OperationResult<MFAStatus>> {
  const endpoint = '/api/user/mfa/v2/status';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: MFAStatus = (await response.json()) as MFAStatus;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Retrieve backup codes for a user */
export async function getBackupCodes(signal?: AbortSignal): Promise<OperationResult<UserOneTimePassword[]>> {
  const endpoint = '/api/user/mfa/v2/backupcodes';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: UserOneTimePassword[] = (await response.json()) as UserOneTimePassword[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Setup SMS as a valid MFA, will send SMS code, can also hit multiple times to resend */
export async function setupPhoneMFA(
  body?: Partial<SetupPhoneRequest>,
  signal?: AbortSignal,
): Promise<OperationResult<MFAStatus>> {
  const endpoint = '/api/user/mfa/v2/phone/setup';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: MFAStatus = (await response.json()) as MFAStatus;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Setup TOTP as a valid MFA, will generate secret key and QR code */
export async function setupTOTPMFA(signal?: AbortSignal): Promise<OperationResult<MFAResponse>> {
  const endpoint = '/api/user/mfa/v2/totp/setup';
  const requestInit = buildRequestInit({ method: 'POST', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: MFAResponse = (await response.json()) as MFAResponse;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Workspace admin disable user MFA */
export async function workspaceAdminDisableUserMFA(
  userId: number,
  signal?: AbortSignal,
): Promise<OperationResult<MFAStatus>> {
  const endpoint = `/api/user/mfa/v2/admin/disable-user-mfa/${encodeURIComponent(userId)}`;
  const requestInit = buildRequestInit({ method: 'POST', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: MFAStatus = (await response.json()) as MFAStatus;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
