import { isNil, noop } from 'lodash';
import type { Dispatch, SetStateAction } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import styled, { css } from 'styled-components';
import { getInvestmentResidual } from 'venn-api';
import type { InvestmentOverrideType } from 'venn-state';
import { selectedInvestmentOverrideAtomSyncedWithOverrideType, selectedInvestmentOverrideTypeAtom } from 'venn-state';
import { BodyEmphasis, GetColor, LoadingSize, Radio, Spinner } from 'venn-ui-kit';
import { assertExhaustive, logExceptionIntoSentry, Numbers, useHasFF } from 'venn-utils';
import type { SearchMenuItem } from '../../../search-menu';
import { SearchMenuBar } from '../../../search-menu';
import ForecastEditRow from './ForecastEditRow';
import { useManagedFocusMenu } from './shared';

interface InvestmentForecastOverridePickerProps {
  setOverrideValue: Dispatch<SetStateAction<number | undefined>>;
  isCreating: boolean;
  isReadOnly: boolean;
}

export default function InvestmentForecastOverridePicker({
  setOverrideValue,
  isCreating,
  isReadOnly,
}: InvestmentForecastOverridePickerProps) {
  const hasRedesignFF = useHasFF('forecast_panel_redesign_ff');

  const operation = hasRedesignFF ? (isCreating ? 'CREATE' : 'EDIT') : 'EDIT';

  /** Stores the name of the fund selected in the row's search box. Useful to display the name when the search box has lost focus * */
  const [selectedFundName, setSelectedFundName] = useState<string | null>(null);

  /** Stores the investment override associated with the selected fund */
  const [selectedInvestmentForecast, setSelectedInvestmentForecast] = useRecoilState(
    selectedInvestmentOverrideAtomSyncedWithOverrideType(operation),
  );

  /** Stores the currently selected override type */
  const [overrideType, setOverrideType] = useRecoilState(selectedInvestmentOverrideTypeAtom(operation));

  /** References held to manage focus of children input fields */
  const { inputRef: returnInputRef, setMenuValueSelected: setReturnMenuValueSelected } = useManagedFocusMenu(true);
  const { inputRef: residualInputRef, setMenuValueSelected: setResidualMenuValueSelected } = useManagedFocusMenu(true);

  /** When present holds the default residual value for the selected fund. To be overridden. */
  const fundSelectionDefaultResidualForecast = selectedInvestmentForecast
    ? Numbers.safeFormatPercentage(selectedInvestmentForecast.defaultResidual, 1)
    : undefined;

  const fundSelectionDefaultReturnForecast = selectedInvestmentForecast
    ? Numbers.safeFormatPercentage(selectedInvestmentForecast.defaultReturn, 1)
    : undefined;

  const stealTextboxFocus = useCallback(
    (override: InvestmentOverrideType) => {
      switch (override) {
        case 'residual':
          setReturnMenuValueSelected(false);
          setResidualMenuValueSelected(true);
          break;
        case 'return':
          setResidualMenuValueSelected(false);
          setReturnMenuValueSelected(true);
          break;
        default:
          throw assertExhaustive(override, 'unexpected overrideType');
      }
    },
    [setReturnMenuValueSelected, setResidualMenuValueSelected],
  );

  /** Synchronize the name and menu status with the source of truth atom */
  useEffect(() => {
    if (selectedInvestmentForecast) {
      setSelectedFundName(selectedInvestmentForecast.fundName);
    } else {
      setSelectedFundName(null);
      if (hasRedesignFF) {
        if (returnInputRef.current) {
          returnInputRef.current.value = '';
        }
        if (residualInputRef.current) {
          residualInputRef.current.value = '';
        }
      }
    }
  }, [hasRedesignFF, residualInputRef, returnInputRef, selectedInvestmentForecast]);

  useEffect(() => {
    const isResidualOverride = !isNil(selectedInvestmentForecast?.overriddenResidual);
    const isReturnOverride = !isNil(selectedInvestmentForecast?.overriddenReturn);
    const newOverrideValue = (() => {
      if (isReturnOverride && overrideType === 'return') {
        return selectedInvestmentForecast?.overriddenReturn;
      }
      if (isResidualOverride && overrideType === 'residual') {
        return selectedInvestmentForecast?.overriddenResidual;
      }
      return undefined;
    })();
    setOverrideValue(newOverrideValue);
  }, [overrideType, setOverrideValue, selectedInvestmentForecast]);

  /** Wrapper to save changes to a given investment's override */
  const onChange = (idx: number | null, newValue?: number) => {
    setOverrideValue(newValue);
  };

  /** When a selection is made from the investment dropdown, fetch the corresponding persisted residual (if any) and
   * autofocus the input field for the override. */
  const onSearchSelect = async (item: SearchMenuItem) => {
    try {
      if (item.value?.fund) {
        setSelectedFundName(item.value.fund.name);
        const investmentResidual = (await getInvestmentResidual(item.value.fund.id)).content;
        setSelectedInvestmentForecast(investmentResidual);
      }
    } catch (e) {
      logExceptionIntoSentry(e);
    }
  };

  const searchBarValue: SearchMenuItem | undefined =
    !isCreating && selectedFundName
      ? {
          category: 'recent',
          label: selectedFundName,
        }
      : undefined;

  return (
    <SearchAndRadioStack>
      {!(hasRedesignFF && !isCreating) && (
        <StyledSearchBar
          hasRedesignFF={hasRedesignFF}
          autofocus={!hasRedesignFF}
          onSelected={onSearchSelect}
          investmentsOnly
          defaultMenuIsOpen={isCreating}
          fixedMenuWidth
          customPlaceholder={isCreating ? (hasRedesignFF ? 'Search' : 'Select an investment') : selectedFundName}
          disabled={!isCreating || isReadOnly}
          location="investmentForecast"
          smallScreen
          menuStyles={{
            fontWeight: 'normal',
            minWidth: '480px',
            overflow: 'hidden',
          }}
          value={
            hasRedesignFF
              ? selectedFundName
                ? {
                    category: 'investment',
                    label: selectedFundName,
                  }
                : null
              : searchBarValue
          }
          privateAssetSearchMode="PUBLIC_ONLY"
        />
      )}
      <Separator hasRedesignFF={hasRedesignFF}>
        <SeparatorLeft hasRedesignFF={hasRedesignFF}>Override</SeparatorLeft>
        <SeparatorRight hasRedesignFF={hasRedesignFF}>Forecast</SeparatorRight>
      </Separator>
      <ForecastEditRow
        rowItem={selectedFundName}
        placeholder={fundSelectionDefaultReturnForecast}
        onRowDelete={noop}
        showDelete={false}
        baseValue={overrideType === 'residual' ? undefined : selectedInvestmentForecast?.overriddenReturn}
        onValueChange={onChange}
        inputRef={returnInputRef}
        significantDecimalDigits={1}
        placeholderOnSelected
        inputLoading={!!(selectedFundName && !fundSelectionDefaultResidualForecast)}
        useFixedMargins={false}
        alignRowItems="center"
        leftViewProvider={() => (
          <RadioData>
            <Radio
              inputId={`${InvestmentForecastOverridePicker.name}-returnOverride-${operation}`}
              checked={overrideType === 'return'}
              onChange={() => {
                setOverrideType('return');
                stealTextboxFocus('return');
              }}
              value="return"
              disabled={isReadOnly}
            >
              {hasRedesignFF ? 'Total Return Override' : 'Return Override'}
            </Radio>
            {selectedInvestmentForecast && (
              <StyledBodyEmphasis hasRedesignFF={hasRedesignFF}>
                {hasRedesignFF ? 'Unmodified Total Return: ' : 'Default return forecast: '}
                {fundSelectionDefaultReturnForecast || <Spinner size={LoadingSize.nano} />}
              </StyledBodyEmphasis>
            )}
          </RadioData>
        )}
        inputDisabled={isReadOnly || overrideType === 'residual'}
      />
      <ForecastEditRow
        rowItem={selectedFundName}
        placeholder={fundSelectionDefaultResidualForecast}
        onRowDelete={noop}
        showDelete={false}
        baseValue={overrideType === 'return' ? undefined : selectedInvestmentForecast?.overriddenResidual}
        onValueChange={onChange}
        inputRef={residualInputRef}
        significantDecimalDigits={1}
        placeholderOnSelected
        inputLoading={!!(selectedFundName && !fundSelectionDefaultResidualForecast)}
        useFixedMargins={false}
        alignRowItems="center"
        leftViewProvider={() => (
          <RadioData>
            <Radio
              inputId={`${InvestmentForecastOverridePicker.name}-residualOverride-${operation}`}
              checked={overrideType === 'residual'}
              onChange={() => {
                setOverrideType('residual');
                stealTextboxFocus('residual');
              }}
              value="residual"
              disabled={isReadOnly}
            >
              Residual Override
            </Radio>
            {selectedInvestmentForecast && (
              <StyledBodyEmphasis hasRedesignFF={hasRedesignFF}>
                {hasRedesignFF ? 'Unmodified Residual: ' : 'Default residual forecast: '}
                {fundSelectionDefaultResidualForecast || <Spinner size={LoadingSize.nano} />}
              </StyledBodyEmphasis>
            )}
          </RadioData>
        )}
        inputDisabled={isReadOnly || overrideType === 'return'}
      />
    </SearchAndRadioStack>
  );
}

const SearchAndRadioStack = styled.div`
  display: flex;
  flex-direction: column;
`;

const RadioData = styled.div`
  height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;

  > span {
    color: ${GetColor.DarkGrey};
  }
`;

const StyledSearchBar = styled(SearchMenuBar)<{ hasRedesignFF: boolean }>`
  > span {
    overflow-y: hidden;
  }

  .select__control {
    border-radius: 1px;
    min-height: 35px;
    border-width: 1px;
  }

  .select__value-container {
    padding-left: 10px;
  }

  /* Used to remove the scrollbar from search menu */

  .select__menu-list > div > div {
    overflow: hidden;
  }

  ${({ hasRedesignFF }) =>
    hasRedesignFF &&
    css`
      margin-bottom: 17px;

      .select__control {
        border-radius: 4px;
      }

      .search-value-container {
        padding: 0 10px;
      }
    `}
`;

const Separator = styled.div<{ hasRedesignFF: boolean }>`
  border-top: 2px solid ${GetColor.PaleGrey};
  border-bottom: 2px solid ${GetColor.PaleGrey};
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 10px;
  padding: 10px 0 10px 0;

  ${({ hasRedesignFF, theme }) =>
    hasRedesignFF &&
    css`
      margin-top: 0;
      border-top: 1px solid ${theme.Colors.MidGrey2};
      border-bottom: 1px solid ${theme.Colors.MidGrey2};
    `}
`;

const SeparatorLeft = styled.div<{ hasRedesignFF: boolean }>`
  align-self: flex-start;
  margin-left: 22px;

  ${({ hasRedesignFF }) =>
    hasRedesignFF &&
    css`
      margin-left: 0;
    `}
`;

const SeparatorRight = styled.div<{ hasRedesignFF: boolean }>`
  align-self: flex-end;
  margin-right: 50px;

  ${({ hasRedesignFF }) =>
    hasRedesignFF &&
    css`
      margin-right: 5px;
    `}
`;

const StyledBodyEmphasis = styled(BodyEmphasis)<{ hasRedesignFF: boolean }>`
  ${({ hasRedesignFF }) =>
    hasRedesignFF &&
    css`
      font-size: 11px;
    `}
`;
