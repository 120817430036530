type Checker = (value: string, length: number) => boolean;

export const isLongEnough: Checker = (value = '', length = 1) => value.length >= length;

export const hasEnoughDigits: Checker = (value = '', minCount = 1) => {
  const regexp = /[\d]/g;
  const matches = value.match(regexp);

  return matches ? matches.length >= minCount : false;
};

export const hasEnoughLowercase: Checker = (value = '', minCount = 1) => {
  const regexp = /[a-z]/g;
  const matches = value.match(regexp);

  return matches ? matches.length >= minCount : false;
};

export const hasEnoughUppercase: Checker = (value = '', minCount = 1) => {
  const regexp = /[A-Z]/g;
  const matches = value.match(regexp);

  return matches ? matches.length >= minCount : false;
};
