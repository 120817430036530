import React from 'react';
import type { BulkActionProps } from './types';
import TagSelector from '../TagSelector';
import { TooltipPosition } from 'venn-ui-kit';

const TagItems = ({ updateData, items }: BulkActionProps) => {
  return (
    <div className="qa-bulk-tag">
      <TagSelector
        updateData={updateData}
        searchItems={items}
        iconSize={18}
        solid
        border
        borderSize={6}
        tooltipPosition={TooltipPosition.Bottom}
        triggerText={`Tag (${items.length})`}
      />
    </div>
  );
};

export default TagItems;
