import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';

const LabelText = styled.div`
  padding: 4px 0;
  color: ${GetColor.Black};
  font-size: 12px;
  font-weight: bold;
  @media print {
    display: inline;
  }
`;

const LabelChildren = styled.div`
  @media print {
    display: inline;
    margin-left: 4px;
  }
`;

interface LabelProps {
  className?: string;
  label: string;
}

const Label: FC<React.PropsWithChildren<LabelProps>> = ({ children, className, label }) => (
  <div className={className}>
    <LabelText>{label}</LabelText>
    <LabelChildren>{children}</LabelChildren>
  </div>
);

export default Label;
