import { useRef, useMemo, useEffect } from 'react';

/**
 * This wraps an API function and returns a function with the same signature.
 * The returned function will have an abort functionality automatically, both on calling
 * the function while a previous call is still "in flight", and both on unmount.
 */
function useApi<F>(apiFunction: F) {
  const abortController = useRef<AbortController>();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const fn: F = useMemo(
    () =>
      async (...params: unknown[]) => {
        if (params[params.length - 1] instanceof AbortSignal) {
          throw Error('Please do not pass an AbortSignal to this function');
        }
        if (abortController.current) {
          abortController.current.abort();
        }
        abortController.current = new AbortController();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        const result = await apiFunction(...params, abortController.current.signal);
        return result;
      },
    [apiFunction],
  );

  useEffect(() => {
    return () => {
      if (abortController.current) {
        abortController.current.abort();
      }
    };
  }, []);

  return fn;
}

export default useApi;
