export enum NodeType {
  Fund = 'venn_fund',
  Portfolio = 'venn_scenario',
}

export const getNodeComparison = (id: string, name: string, type: NodeType): unknown => {
  if (type === NodeType.Portfolio) {
    return {
      name,
      portfolioId: Number(id),
      fundType: {
        id: 2,
        name: NodeType.Portfolio,
      },
    };
  }

  return {
    name,
    fundId: id,
    fundType: {
      id: 1,
      name: NodeType.Fund,
    },
  };
};
