import type { ColumnMapping, FileUploadMetadata } from 'venn-api';
import type { ErrorViewModel } from '../../views/review/helpers';
import { hasUncorrectedErrors } from '../../views/review/helpers';
import { isColumnDeleted } from '../../views/mapping/helpers';
import type { CellRenderer, CellRendererProps } from './types';

export const isDataNew = (isReturns: boolean, hasFundId: boolean, hasPortfolioNodeId: boolean): boolean => {
  return !hasFundId && (isReturns || !hasPortfolioNodeId);
};

export const isCellDisabled = (
  columnMapping: ColumnMapping,
  metadata: FileUploadMetadata,
  errors: ErrorViewModel[],
  seriesId: string,
) => {
  const { newDataCount } = columnMapping;
  const isDeleted = isColumnDeleted(columnMapping, metadata);
  return isDeleted || newDataCount === 0 || hasUncorrectedErrors(errors, seriesId);
};

export const createCellRenderer =
  ({ renderer, ...rendererProps }: CellRendererProps & { renderer: CellRenderer }) =>
  (columnMapping: ColumnMapping, index: number, count: number) =>
    renderer({ columnMapping, index, count, ...rendererProps });

export const getFirstCustomErrorMessage = (
  errors: ErrorViewModel[],
  seriesId: string,
  useCustomErrorMessage?: boolean,
) =>
  !useCustomErrorMessage ? undefined : errors.filter((e) => e.seriesId === seriesId).map((e) => e.errors?.[0])?.[0];
