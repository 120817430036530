import { selector } from 'recoil';
import { type CustomFont, type CustomPageFonts } from './types';
import { baseFontDefaults } from './workspaceDefaults';
import { merge } from 'lodash';
import { NEW_LINE_HEIGHT_DEFAULTS } from './constants';

export const pageFonts = {
  titlePageDate: createPageFont('titlePageDate'),
  titlePageTitle: createPageFont('titlePageTitle'),
  titlePageSubtitle: createPageFont('titlePageSubtitle'),
  titlePageContent: createPageFont('titlePageContent'),
  sectionPageName: createPageFont('sectionPageName'),
  sectionPageTitle: createPageFont('sectionPageTitle'),
  sectionPageSubtitle: createPageFont('sectionPageSubtitle'),
  sectionPageOrgName: createPageFont('sectionPageOrgName'),
} satisfies { [k in keyof CustomPageFonts]: ReturnType<typeof createPageFont> };

function createPageFont(key: keyof CustomPageFonts) {
  return selector<CustomFont & { lineHeight: string }>({
    key: `${key}Font`,
    get: ({ get }) => {
      const newFonts = get(baseFontDefaults);
      return merge({}, NEW_LINE_HEIGHT_DEFAULTS[key], newFonts[key]);
    },
  });
}
