import React from 'react';
import { AuthLoading as Loading } from 'venn-components';
import type { NamedEntityInteger } from 'venn-api';
import { TermsLayout as Layout } from './TermsLayout';

interface TermsProps {
  terms: NamedEntityInteger[];
  error: string;
  loading: boolean;
  accept(term: NamedEntityInteger): void;
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default ({ accept, terms, error, loading }: TermsProps) => {
  if (error) {
    return <Layout>{error}</Layout>;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <Layout acceptTerm={() => accept(terms[0])} acceptButtonText="Continue" enableLegalLinks>
      {terms[0] ? terms[0].name : ''}
    </Layout>
  );
};
