import type { FC } from 'react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import type { AnalysisContextValue, FactorLensesContextValue, PortfoliosContextValue } from 'venn-components';
import {
  AnalysisContext,
  FactorLensesContext,
  PortfoliosContext,
  AnalysisExportStore,
  UserContext,
} from 'venn-components';
import { Loading } from 'authentication-page';

import type { Portfolio } from 'venn-api';
import {
  getAllFactorLensesForUser,
  getMasterPortfolioV3,
  setAsMasterPortfolioV3,
  updatePortfolioV3,
  getAllGeneralTemplates,
  getVennDemoPortfolio,
} from 'venn-api';
import { useHasFF } from 'venn-utils';
import StudioPrintSettingsStore from './StudioPrintSettingsStore';
import { ThemeContext } from 'styled-components';
import { useSetRecoilState } from 'recoil';
import { theme } from 'venn-state';

const Contexts: FC<React.PropsWithChildren<unknown>> = React.memo(function Contexts({ children }) {
  const hasContextSwitching = useHasFF('context_switching');

  const currentTheme = useContext(ThemeContext);
  const setRecoilTheme = useSetRecoilState(theme);

  // Sync the current theme to recoil
  useEffect(() => {
    setRecoilTheme(currentTheme);
  }, [currentTheme, setRecoilTheme]);

  const refreshPortfolios = useCallback(async () => {
    try {
      setPortfoliosContext((c) => ({
        ...c,
        loading: true,
      }));
      await Promise.all([setMasterPortfolio(), setDemoPortfolio()]);
    } catch {
      // Do nothing
    }
    setPortfoliosContext((c) => ({
      ...c,
      loading: false,
    }));
  }, []);

  const setTemplates = async () => {
    setAnalysisContext((c) => ({
      ...c,
      loading: true,
    }));
    try {
      const { content: templates } = await getAllGeneralTemplates();
      setAnalysisContext((c) => ({
        ...c,
        templates,
        loading: false,
      }));
    } catch {
      setAnalysisContext((c) => ({
        ...c,
        templates: [],
        loading: false,
      }));
    }
  };

  const setFactorLenses = async () => {
    setFactorLensContext((c) => ({
      ...c,
      loading: true,
    }));
    try {
      const { content: factorLenses } = await getAllFactorLensesForUser();
      setFactorLensContext((c) => ({
        ...c,
        factorLenses,
        primaryFactorLens: factorLenses && factorLenses.find((factorLens) => factorLens.primary),
        loading: false,
      }));
    } catch (e) {
      setFactorLensContext((c) => ({
        ...c,
        factorLenses: undefined,
        primaryFactorLens: undefined,
        loading: false,
      }));
    }
  };

  const setMasterPortfolioById = async (masterPortfolioId: number) => {
    setPortfoliosContext((c) => ({
      ...c,
      loading: true,
    }));
    await setAsMasterPortfolioV3(masterPortfolioId);
    await refreshPortfolios();
  };

  const updateMaster = async (master: Portfolio): Promise<Portfolio> => {
    setPortfoliosContext((c) => ({
      ...c,
      loading: true,
    }));
    const { content: masterPortfolio } = await updatePortfolioV3(master.id, master);
    setPortfoliosContext((c) => ({
      ...c,
      masterPortfolio,
      loading: false,
    }));

    return masterPortfolio;
  };

  const [analysisContext, setAnalysisContext] = useState<AnalysisContextValue>({
    ...useContext(AnalysisContext),
    refresh: setTemplates,
    loading: true,
  });

  const [factorLensContext, setFactorLensContext] = useState<FactorLensesContextValue>({
    ...useContext(FactorLensesContext),
    refresh: setFactorLenses,
    loading: true,
  });

  const [portfoliosContext, setPortfoliosContext] = useState<PortfoliosContextValue>({
    ...useContext(PortfoliosContext),
    setAsMaster: setMasterPortfolioById,
    updateMaster,
    refresh: refreshPortfolios,
    loading: true,
  });

  const setDemoPortfolio = async () => {
    try {
      const { content } = await getVennDemoPortfolio();
      setPortfoliosContext((c) => ({
        ...c,
        demoPortfolio: content,
      }));
    } catch {
      setPortfoliosContext((c) => ({
        ...c,
        demoPortfolio: undefined,
      }));
    }
  };

  const setMasterPortfolio = async () => {
    try {
      const { content } = await getMasterPortfolioV3();
      setPortfoliosContext((c) => ({
        ...c,
        masterPortfolio: content,
        failedToFetchMasterError: undefined,
      }));
    } catch (e) {
      const { content } = await e;
      setPortfoliosContext((c) => ({
        ...c,
        masterPortfolio: undefined,
        failedToFetchMasterError: content?.text,
      }));
    }
  };

  const { currentContext } = useContext(UserContext);

  useEffect(() => {
    // TODO: Remove this when we migrate all orgs to use contexts
    if ((!currentContext && hasContextSwitching) || (currentContext && !hasContextSwitching)) {
      return;
    }

    setTemplates();
    setFactorLenses();
    refreshPortfolios();
  }, [currentContext, hasContextSwitching, refreshPortfolios]);

  // Init loading
  if (factorLensContext.loading && !factorLensContext.factorLenses?.length) {
    return <Loading />;
  }

  return (
    <PortfoliosContext.Provider value={portfoliosContext}>
      <FactorLensesContext.Provider value={factorLensContext}>
        <AnalysisContext.Provider value={analysisContext}>
          <StudioPrintSettingsStore>
            <AnalysisExportStore>{children}</AnalysisExportStore>
          </StudioPrintSettingsStore>
        </AnalysisContext.Provider>
      </FactorLensesContext.Provider>
    </PortfoliosContext.Provider>
  );
});

export default Contexts;
