import type { FC } from 'react';
import React from 'react';
import { map, chunk, initial } from 'lodash';
import moment from 'moment';
import { GetColor } from 'venn-ui-kit';
import styled from 'styled-components';

const getTickPosition = (offset: number, cellWidth: number, index: number) => offset + cellWidth * index;

const MAX_TICKS = 8;
const PRINT_MAX_TICKS = 3;
const TICK_HEIGHT = 5;
const PRINT_TICK_HEIGHT = 2;
const LABEL_OFFSET = 15;
const DATE_FORMAT = "MMM 'YY";
const TICK_WIDTH = 30;
const formatLabel = (value: number) => moment.utc(value).format(DATE_FORMAT);

function getTicks(timestamps: number[], cellWidth: number, print: boolean) {
  if (!timestamps || timestamps.length === 0) {
    return [];
  }
  let ticks = map(timestamps, (value, index) => ({ value, index }));
  const lastTick = ticks[ticks.length - 1];
  const nextMonth = moment.utc(lastTick.value).add(1, 'months').valueOf();
  const endTick = { value: nextMonth, index: lastTick.index + 1 };
  const maxTicks = print ? PRINT_MAX_TICKS : MAX_TICKS;
  if (ticks.length > maxTicks) {
    const mapTicks = map(chunk(initial(ticks), Math.ceil(ticks.length / maxTicks)), (elem) => ({
      timestamp: elem[0],
      length: elem.length,
    }));
    if (mapTicks[mapTicks.length - 1].length * cellWidth < TICK_WIDTH) {
      mapTicks.pop();
    }
    ticks = map(mapTicks, (tick) => tick.timestamp);
  }
  ticks.push(endTick);
  return ticks;
}

interface Props {
  timestamps: number[];
  offset: number;
  cellWidth: number;
  print: boolean;
  y: number;
  showSubjectsValues?: boolean;
  fontSize?: string;
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
const Ticks: FC<React.PropsWithChildren<Props>> = React.memo(
  ({ timestamps, offset, y, cellWidth, print, showSubjectsValues, fontSize }) => {
    const tickHeight = print ? PRINT_TICK_HEIGHT : TICK_HEIGHT;
    const ticks = getTicks(timestamps, cellWidth, print);
    return (
      <>
        {ticks.map(({ value, index }, mapIndex) => (
          <g key={value + index}>
            <StyledLine
              x1={getTickPosition(offset, cellWidth, index)}
              y1={y}
              x2={getTickPosition(offset, cellWidth, index)}
              y2={y + tickHeight}
            />
            <StyledText
              fontSize={fontSize}
              x={getTickPosition(offset, cellWidth, index)}
              y={y + tickHeight + LABEL_OFFSET}
              cellWidth={cellWidth}
              lastItem={ticks.length === mapIndex + 1 && !showSubjectsValues}
            >
              {formatLabel(value)}
            </StyledText>
          </g>
        ))}
      </>
    );
  },
);

export default Ticks;

const StyledLine = styled.line`
  stroke: ${GetColor.PaleGrey};
`;

const StyledText = styled.text<{ cellWidth: number; lastItem: boolean; fontSize?: string }>`
  font-family: ${(props) => props.theme.Typography.fontFamily};
  font-size: ${(props) => (props.fontSize ? props.fontSize : props.cellWidth < 4 ? '10px' : '14px')};
  text-anchor: ${({ lastItem }) => (lastItem ? 'end' : 'middle')};
  fill: ${GetColor.MidGrey2};
`;
