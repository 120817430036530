import type { DynamicThemeType } from '../../style/themes';

/** gets the asset from currently active theme, or from default theme if it's not available */
export const getAssetWithDefault = (theme: DynamicThemeType, asset: string) => {
  try {
    // we do want dynamic imports here to enable dynamically theming the app
    // if it somehow breaks, it doesn't crash the entire page, just doesn't display the images
    // eslint-disable-next-line import/no-dynamic-require
    return require(`../${theme}/assets/${asset}`);
  } catch {
    // eslint-disable-next-line import/no-dynamic-require
    return require(`../default/assets/${asset}`);
  }
};

export default getAssetWithDefault;
