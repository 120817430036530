import type { FC } from 'react';
import React, { useContext } from 'react';
import type { DrawdownPeriodAnalysis } from 'venn-api';
import { Highchart } from '../highchart/Highchart';
import type { SeriesLineOptions, AxisTypeValue } from 'highcharts';
import { ThemeContext } from 'styled-components';

export interface HistoricalLineChartProps {
  globalStart: number | undefined;
  globalEnd: number | undefined;
  completed: boolean;
  hasAnalyzeError: boolean;
}

export const HistoricalLineChart: FC<
  React.PropsWithChildren<Partial<DrawdownPeriodAnalysis> & HistoricalLineChartProps>
> = ({ start, trough, end, completed, globalStart, globalEnd, hasAnalyzeError }) => {
  const { Colors } = useContext(ThemeContext);
  const data = [
    {
      x: start,
      y: 0,
      marker: {
        symbol: 'circle',
        lineWidth: hasAnalyzeError ? 1 : 4,
        fillColor: hasAnalyzeError ? Colors.White : Colors.Black,
        lineColor: Colors.Black,
      },
    },
    {
      x: trough,
      y: 0,
      marker: {
        symbol: 'cross',
        lineWidth: 1,
        lineColor: Colors.Black,
      },
    },
    {
      x: end,
      y: 0,
      marker: {
        symbol: 'circle',
        enabled: completed,
        lineWidth: hasAnalyzeError ? 1 : 4,
        fillColor: hasAnalyzeError ? Colors.White : Colors.Black,
        lineColor: Colors.Black,
      },
    },
  ];

  const series: SeriesLineOptions[] = [
    {
      type: 'line',
      data,
      color: Colors.Black,
      states: {
        hover: {
          enabled: false,
        },
      },
    },
  ];

  const chartConfig = {
    chart: {
      height: 25,
      marginTop: 0,
      marginBottom: 10,
      marginLeft: 10,
      marginRight: 10,
      borderWidth: 0,
      backgroundColor: 'transparent',
    },
    title: { text: undefined },
    xAxis: {
      type: 'datetime' as AxisTypeValue,
      title: { text: null },
      min: globalStart,
      max: globalEnd,
      lineWidth: 0,
      minorGridLineWidth: 0,
      gridLineWidth: 0,
      lineColor: 'transparent',
      gridLineColor: 'transparent',
      visible: false,
      labels: {
        enabled: false,
      },
      minorTickLength: 0,
      tickLength: 0,
    },
    yAxis: {
      title: { text: null },
      labels: {
        enabled: false,
      },
      gridLineColor: 'transparent',
      tickPositions: [0],
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      line: {
        animation: false,
        marker: {
          enabled: true,
        },
      },
    },
    series,
  };

  return <Highchart key={start} options={chartConfig} className="qa-historical-line-chart" />;
};

export default HistoricalLineChart;
