import { isNil } from 'lodash';
import type { ExcelCell, ExcelWorkbook } from 'venn-utils';

/**
 * Caches style objects for reuse across an entire worksheet, allowing cells to share their styles rather than duplicating the style thousands of times.
 *
 * When adding styles to individual cells, xlsx-populate creates a new style object for every style on every cell, unless we explicitly create and store
 * the style object ourselves. This class manages the caching (creation and reuse) of arbitrary style objecst.
 *
 * This is a necessary optimization to ensure that worksheets function properly in excel.
 *
 * Note that one style cache should be used for an entire document, not per page nor across multiple documents.
 */
export class ExcelStyleCache {
  private readonly ss;

  private readonly stylesMap;

  constructor(wb: ExcelWorkbook) {
    this.ss = wb.styleSheet();
    this.stylesMap = new Map<string, unknown>();
  }

  /**
   * Gets a memoized style object for the provided style. When styling cells, this is necessary
   * to ensure that excel can open our sheets, as excel can't handle thousands of styling objects.
   */
  public memoizedGetStyle(style: ExcelCell['style']) {
    if (!style) return style;

    // Remove values that don't matter because they are nil, and may cause cache lookup failures.
    const isAllowedKey = (key: string): boolean => !isNil(style[key]);
    // Sort keys to ensure the cache key is key-order independent.
    const orderedAllowedKeys = Object.keys(style).filter(isAllowedKey).sort();

    const cacheKey = JSON.stringify(style, orderedAllowedKeys);
    const cachedStyleObj = this.stylesMap.get(cacheKey);
    if (cachedStyleObj) {
      return cachedStyleObj;
    }

    const newStyleObj = this.ss.createStyle();
    Object.entries(style).forEach(([key, value]) => {
      if (isAllowedKey(key)) {
        newStyleObj.style(key, value);
      }
    });

    this.stylesMap.set(cacheKey, newStyleObj);
    return newStyleObj;
  }
}
