import { useCallback } from 'react';
import { getThirdPartyExportMessageRow } from '../../../../logic/exportUtils';
import { convertMultipleSeriesDataToExcel, type ExcelCell } from 'venn-utils';
import { reverse } from 'lodash';
import type { NAVBreakdownBlockExportFunctionProps } from '../types';
import { UnitFormat } from 'venn-state';
import { CurrencyEnum } from 'venn-api';
import { getCurrencySymbol } from '../../../charts/chartsUtils';

export const useNAVBreakdownChartExportDataFunction = ({
  subjectsSeries,
  exportable,
  dataFormat,
}: NAVBreakdownBlockExportFunctionProps) => {
  return useCallback(() => {
    if (!exportable) {
      return [getThirdPartyExportMessageRow()];
    }

    const data: ExcelCell[][] = convertMultipleSeriesDataToExcel(
      reverse([...subjectsSeries]).map((serie) => ({
        name: serie.name,
        // skip the first row for public-private asset growth which is historical and non-exportable
        series: serie.data.slice(1),
      })),
      dataFormat === UnitFormat.PERCENT,
      dataFormat === UnitFormat.ALLOCATION ? getCurrencySymbol(CurrencyEnum.USD) : undefined,
      'QUARTERLY',
    );
    return data;
  }, [subjectsSeries, exportable, dataFormat]);
};
