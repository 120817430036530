import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { GetColor, Notifications, NotificationType } from 'venn-ui-kit';
import { analyticsService, Routes, useApi } from 'venn-utils';
import type { GeneralAnalysisTemplate } from 'venn-api';
import { deleteReportTemplate } from 'venn-api';
import { AnalysisContext, LaunchAnalysisTemplate, UserContext } from 'venn-components';
import TemplatesTable from './TemplatesTable';
import { FlexRow, FlexWrapper, Info, Name, StyledButtonLink, StyledIcon } from '../shared';
import styled from 'styled-components';
import type { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

const Templates = ({ history }: RouteComponentProps) => {
  const { hasPermission } = useContext(UserContext);
  const isMountedRef = useRef(false);
  const { templates, refresh } = useContext(AnalysisContext);
  const [launchAnalysisTemplateVisible, setLaunchAnalysisTemplateVisible] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<GeneralAnalysisTemplate>();
  const abortableDeleteReportTemplate = useApi(deleteReportTemplate);
  const isReadOnly = !hasPermission('ANALYSIS_PAGE_CREATE_TEMPLATE');

  useLayoutEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const launchAnalysisModal = (template: GeneralAnalysisTemplate) => {
    analyticsService.ctaClicked({
      destination: undefined,
      text: template.name,
      purpose: `Select subject for ${template.name}`,
      type: 'display button',
      filled: false,
    });
    setSelectedTemplate(template);
    setLaunchAnalysisTemplateVisible(true);
  };

  const deleteTemplate = async (templateId: string) => {
    try {
      await abortableDeleteReportTemplate(templateId);
      refresh();
    } catch (e) {
      if (e.name !== 'AbortError' && isMountedRef.current) {
        Notifications.notify('Unable to delete template.', NotificationType.ERROR);
      }
    }
  };

  const customTemplates = templates.filter((template) => template.analysisTemplateType === 'CUSTOM');
  return (
    <Wrapper>
      <NestedWrapper>
        <FlexRow>
          <div>
            <FlexWrapper>
              <StyledIcon type="calculator" />
              <Name>Manage Analysis Templates</Name>
            </FlexWrapper>
          </div>
          {!isReadOnly && (
            <StyledButtonLink
              linkText="Create a New Template"
              to={Routes.CREATE_TEMPLATE}
              className="qa-create-template"
            />
          )}
        </FlexRow>
        <Info>Review, edit, and delete all of the custom analysis templates created by your organization.</Info>
      </NestedWrapper>
      {launchAnalysisTemplateVisible && selectedTemplate && (
        <LaunchAnalysisTemplate
          templateId={selectedTemplate.id}
          templateName={selectedTemplate.name}
          onCancel={() => setLaunchAnalysisTemplateVisible(false)}
        />
      )}
      {customTemplates.length !== 0 && (
        <TemplatesTable
          onDelete={isReadOnly ? undefined : ({ id }: GeneralAnalysisTemplate) => deleteTemplate(id)}
          onEdit={
            isReadOnly
              ? undefined
              : ({ id }: GeneralAnalysisTemplate) => {
                  history.push(`${Routes.EDIT_TEMPLATE_PATH}/${id}`);
                }
          }
          templates={customTemplates}
          launchAnalysisModal={launchAnalysisModal}
        />
      )}
    </Wrapper>
  );
};

export default withRouter(Templates);

const Wrapper = styled.div`
  border-radius: 4px;
  border: 1px solid ${GetColor.Grey};
  margin-top: 20px;
`;
const NestedWrapper = styled.div`
  padding: 40px 60px;
`;
