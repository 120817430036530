import React from 'react';
import type { PrivateFund } from 'venn-api';
import { Loading } from 'venn-ui-kit';
import { withSuspense } from 'venn-utils';
import PrivateSubjectCashflowPacingSettings from './PrivateSubjectCashflowPacingSettings';
import { useActiveCashflowSetting } from 'venn-components';

type PrivateInvestmentCashflowPacingSettingsProps = Readonly<{
  fund: PrivateFund;
}>;

const PrivateInvestmentCashflowPacingSettings = ({ fund }: PrivateInvestmentCashflowPacingSettingsProps) => {
  const { data, refetch: refetchActiveSettings } = useActiveCashflowSetting([[fund.id, fund.name]]);
  return <PrivateSubjectCashflowPacingSettings refetchActiveSettings={refetchActiveSettings} settingsRowData={data} />;
};

export default withSuspense(
  <Loading title="Cashflow pacing model parameters are loading... " />,
  PrivateInvestmentCashflowPacingSettings,
);
