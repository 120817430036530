import React, { Component } from 'react';
import styled from 'styled-components';
import { ExternalActivityListener, GetColor, Icon, Tooltip, TooltipPosition, ZIndex } from 'venn-ui-kit';
import FundTypeahead from '../../../../fund-typeahead/FundTypeahead';
import type { Option as FundTypeaheadOption } from '../../../../fund-typeahead/types';
import {
  SCOPE_OPTION_ALL_FUNDS,
  SCOPE_OPTION_USER_UPLOADED_FUNDS,
  SCOPE_TYPES,
} from '../../../../fund-typeahead/types';
import { DataUploaderMode } from '../../types';
import isNil from 'lodash/isNil';
import { buttonize } from 'venn-utils';

interface FundLinkerProps {
  disabled?: boolean;
  isNew: boolean;
  className?: string;
  value?: string;
  forceSelection?: boolean;
  onFundSelect?: (option: FundTypeaheadOption) => void;
  onNewSelect?: (option: FundTypeaheadOption) => void;
  leftPosition?: number;
  selectorOpen: boolean;
  updateSelector: (open: boolean) => void;
  alignInputTextToCheckMark?: boolean;
  /**
   * NAVs mode can map all funds.
   * Returns mode can only map user upload fund
   */
  mode: DataUploaderMode;
}

class FundLinker extends Component<FundLinkerProps> {
  onFundSelect = (option: FundTypeaheadOption) => {
    const { updateSelector, onFundSelect } = this.props;
    updateSelector?.(false);
    onFundSelect?.(option);
  };

  onNewSelect = (option: FundTypeaheadOption) => {
    const { updateSelector, onNewSelect } = this.props;
    updateSelector?.(false);
    onNewSelect?.(option);
  };

  render() {
    const {
      isNew,
      className,
      value,
      forceSelection,
      disabled,
      leftPosition,
      selectorOpen,
      updateSelector,
      alignInputTextToCheckMark,
      mode,
    } = this.props;
    const icon = <StyledIcon greyed={disabled} type={isNew ? 'unlink' : 'link'} />;
    const initialScope =
      mode === DataUploaderMode.Returns || mode === DataUploaderMode.Privates
        ? SCOPE_TYPES.USER_UPLOADED_FUNDS
        : SCOPE_TYPES.ALL_FUNDS;
    const scopeOptions =
      mode === DataUploaderMode.Returns || mode === DataUploaderMode.Privates
        ? SCOPE_OPTION_USER_UPLOADED_FUNDS
        : SCOPE_OPTION_ALL_FUNDS;
    return (
      <span className={className}>
        <span {...buttonize(() => updateSelector(!selectorOpen))}>
          {disabled ? (
            icon
          ) : (
            <StyledTooltip
              content={<TooltipContent>Rename or map to existing investment</TooltipContent>}
              position={TooltipPosition.Right}
              children={
                <IconsWrapper>
                  {icon}
                  <DropdownIcon type="angle-down" />
                </IconsWrapper>
              }
            />
          )}
        </span>
        {selectorOpen ? (
          <SelectorContainer leftPosition={leftPosition}>
            <ExternalActivityListener
              debounce={false}
              onExternalActivity={() => updateSelector(false)}
              listeningEnabled={selectorOpen}
            >
              <StyledFundTypeahead
                isNew={isNew}
                value={value}
                closeOnSelect
                forceSelection={forceSelection}
                onFundSelect={this.onFundSelect}
                onNewSelect={this.onNewSelect}
                initialScope={initialScope}
                scopeOptions={[scopeOptions]}
                hideButton
                autoFocus
                alignInputTextToCheckMark={alignInputTextToCheckMark}
              />
            </ExternalActivityListener>
          </SelectorContainer>
        ) : null}
      </span>
    );
  }
}

export default FundLinker;

const StyledFundTypeahead = styled(FundTypeahead)`
  width: 350px;
  padding: 0;

  button {
    border-radius: 0;

    &:after {
      display: none;
    }
  }

  section {
    border: 1px solid ${GetColor.Primary.Dark};

    + div {
      margin-top: 2px;
    }
  }

  input {
    border-radius: 0;
  }
`;

const IconsWrapper = styled.span`
  cursor: pointer;

  &:hover i {
    color: ${GetColor.Primary.Main};
  }
`;

const StyledIcon = styled(Icon)<{ greyed?: boolean }>`
  color: ${(props) => (props.greyed ? GetColor.MidGrey2 : GetColor.Primary.Dark)};
  cursor: pointer;
`;

const DropdownIcon = styled(StyledIcon)`
  font-size: 10px;
  font-weight: normal;
  margin-left: 3px;
`;

const SelectorContainer = styled.div<{ leftPosition?: number }>`
  position: absolute;
  ${({ leftPosition }) => !isNil(leftPosition) && `left: ${leftPosition}px;`};
  top: 0;
  z-index: ${ZIndex.Front};
`;

const StyledTooltip = styled(Tooltip)`
  display: unset;
`;

const TooltipContent = styled.span`
  font-size: 10px;
`;
