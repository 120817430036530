import React, { useContext } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { SwitchOrgDropdown, UserContext } from 'venn-components';
import { get } from 'lodash';
import { switchWorkspaces } from 'venn-api';
import { GetColor } from 'venn-ui-kit';
import { logExceptionIntoSentry, useHasFF } from 'venn-utils';
import type { History } from 'history';

const onChangeOrganization =
  (history: History, refreshProfile: () => void) => async (profileId: number, organizationId: number) => {
    try {
      await switchWorkspaces({
        userId: profileId,
        organizationId,
      });
      await refreshProfile();
      history.replace('/');
      window.location.reload();
    } catch (e) {
      logExceptionIntoSentry(await e);
    }
  };
const AdminSwitchOrg = ({ history }: RouteComponentProps) => {
  const { profileSettings, refresh } = useContext(UserContext);

  if (!useHasFF('admin_switch_organizations')) {
    return null;
  }

  return (
    <Wrapper>
      <SwitchOrgDropdown
        profileId={get(profileSettings, 'user.id')}
        organizationId={get(profileSettings, 'organization.id')}
        onChangeOrganization={onChangeOrganization(history, refresh)}
      />
    </Wrapper>
  );
};

export default withRouter(AdminSwitchOrg);

const Wrapper = styled.div`
  padding: 20px;
  h2 {
    color: ${GetColor.DarkGrey};
  }
`;
