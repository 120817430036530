import type { StudioRequestSubject } from 'venn-state';

/**
 * Returns a `StudioRequestSubject` if the second subject is a secondary of the first subject.
 *
 * @param primaryRequest - a `StudioRequestSubject` that appears as the first on the list
 * @param maybeSecondaryRequest - a `StudioRequestSubject` that is next on the list and may or may not be
 * secondary to the first request
 */
export const getSecondarySubjectFromRequests = (
  primarySubject: StudioRequestSubject | undefined,
  maybeSecondarySubject: StudioRequestSubject | undefined,
): StudioRequestSubject | undefined => {
  if (!primarySubject?.portfolio || !maybeSecondarySubject) {
    return undefined;
  }

  const primaryPortfolio = maybeSecondarySubject?.primaryPortfolio;
  if (primaryPortfolio && primaryPortfolio.id === primarySubject.portfolio.id) {
    return maybeSecondarySubject;
  }

  return undefined;
};
