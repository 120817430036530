import type { SizeState } from 'venn-state';
import { useScopedSetRecoilState } from 'venn-state';
import { useCallback, useMemo } from 'react';
import { debounce } from 'lodash';
import type { RecoilState } from 'recoil';

const SECOND_MS = 1000;

// TODO(collin.irwin): create a reusable wrapper component  that also manages the Measure wrapper.

/** Debounced state setter that also handles temporary 'loading' state during the debouncing by setting the size to undefined. */
export const useSetBlockSize = (state: RecoilState<SizeState>) => {
  const setState = useScopedSetRecoilState(state);
  const debouncedSetState = useMemo(() => debounce(setState, SECOND_MS / 2), [setState]);

  return useCallback(
    ({ bounds }: { bounds?: SizeState }) => {
      const newSize = bounds ? { height: bounds.height, width: bounds.width } : undefined;

      if (!newSize) {
        return;
      }

      // We set the state to undefiend temporarily as a sort of 'loading' state.
      setState(undefined);
      debouncedSetState(newSize);
    },
    [debouncedSetState, setState],
  );
};
