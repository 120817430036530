import type { Subject } from 'venn-state';
import { toTypeSafeSubjectId } from 'venn-state';
import { isRequestSuccessful, logExceptionIntoSentry, useQuery } from 'venn-utils';
import { isEmpty } from 'lodash';
import { batchGetExportMetadata } from 'venn-api';

const EXPORT_METADATA_KEY = 'export_metadata_cached';
const ONE_HOUR = 1000 * 60 * 60;

export const usePrivatesExportInfo = (subjects: Subject[]): boolean => {
  const { data: isExportable } = useQuery<boolean>(
    [EXPORT_METADATA_KEY, subjects],
    async () => {
      if (isEmpty(subjects)) {
        return true;
      }
      const subjectIds = subjects.map((subject) => {
        return toTypeSafeSubjectId(subject);
      });

      const response = await batchGetExportMetadata({ subjectIds });
      if (!isRequestSuccessful(response)) {
        // can't check, default to no access
        return false;
      }
      return response.content.subjectExportMetadata.every((metadatum) => metadatum.exportable);
    },
    {
      onError: (error) => {
        logExceptionIntoSentry(error as Error);
      },
      suspense: true,
      staleTime: ONE_HOUR,
    },
  );

  return isExportable ?? false;
};
