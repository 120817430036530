import React, { useState } from 'react';
import { FlexRow, FlexWrapper, Info, Name, StyledButton, StyledIcon, StyledWrapper } from '../shared';
import styled from 'styled-components';
import IntegrationRequestModal from './IntegrationRequestModal';
import { getAppTitle } from 'venn-ui-kit';

const Integrations = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <StyledWrapper>
        <FlexRow>
          <FlexWrapper>
            <StyledIcon type="exchange" />
            <Name>Performance Data Integrations</Name>
          </FlexWrapper>
          <StyledButton onClick={() => setShowModal(true)}>Request an integration</StyledButton>
        </FlexRow>
        <MaxWidthInfo>
          Integrate your 3rd-party portfolio data with {getAppTitle()}! Daily syncs ensure your portfolios are never out
          of date. Some integrations may require manual set-up steps.
        </MaxWidthInfo>
      </StyledWrapper>
      {showModal && <IntegrationRequestModal request="" onClose={() => setShowModal(false)} />}
    </>
  );
};

export default Integrations;

const BUTTON_WIDTH_SPACE = 202;
const MaxWidthInfo = styled(Info)`
  max-width: calc(100% - ${BUTTON_WIDTH_SPACE}px);
`;
