import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';
import type { DrawdownFactorInfo } from 'venn-api';
import CorrelationsChart from '../../../../../factor-correlations-chart/FactorCorrelationsChart';
import type {
  Entity as CorrelationsChartEntity,
  DrawdownCorrelation,
} from '../../../../../factor-correlations-chart/types';
import PrintContainerDimensions from '../../../../../print/PrintContainerDimensions';
import type { HeatMapTypes } from '../../../../../heat-map/Types';

const tooltipFormatter =
  (drawdownFactorInfoList: DrawdownFactorInfo[]) =>
  // TODO(VENN-24534): add a display name to this React component
  // eslint-disable-next-line react/display-name
  (factor: HeatMapTypes.Root, compareFactor: HeatMapTypes.Root, value?: number): JSX.Element | null => {
    if (value === null || value === undefined || factor.factorId === compareFactor.factorId) {
      return null;
    }
    const compareFactorDeltas = drawdownFactorInfoList?.find(
      (info: DrawdownFactorInfo) => compareFactor.factorId === info.factorId,
    );

    const factorDeltaCorrelation = compareFactor
      ? compareFactorDeltas?.factorDeltaCorrelations.find((c) => c.id === factor.factorId)
      : null;

    const periodCorr = factorDeltaCorrelation ? factorDeltaCorrelation.periodCorr : 0;
    const histCorr = factorDeltaCorrelation ? factorDeltaCorrelation.histCorr : 0;

    return (
      <div>
        <TooltipTitle>
          <strong>
            {factor.name} & {compareFactor.name}
          </strong>
        </TooltipTitle>
        <TooltipRow>
          <span>Correlation for Selected Period:</span>
          <strong>{periodCorr.toFixed(2)}</strong>
        </TooltipRow>
        <TooltipRow>
          <span>Historical average:</span>
          <strong>{histCorr.toFixed(2)}</strong>
        </TooltipRow>
        <TooltipDelta>
          Δ{' '}
          <strong>
            {value > 0 ? '+' : ''}
            {value.toFixed(2)}
          </strong>
        </TooltipDelta>
      </div>
    );
  };

export interface DrawdownCorrelationsChartProps {
  correlationsData: CorrelationsChartEntity<DrawdownCorrelation>[];
  drawdownFactorInfoList: DrawdownFactorInfo[];
}

export const DrawdownCorrelationsChart: FC<React.PropsWithChildren<DrawdownCorrelationsChartProps>> = ({
  correlationsData,
  drawdownFactorInfoList,
}) => (
  <PrintContainerDimensions>
    {({ width }) => (
      <StyledCorrelationsChart
        width={width}
        data={correlationsData}
        tooltipFormatter={tooltipFormatter(drawdownFactorInfoList)}
      />
    )}
  </PrintContainerDimensions>
);

export default DrawdownCorrelationsChart;

const StyledCorrelationsChart = styled(CorrelationsChart)`
  .highcharts-tooltip span {
    font-family: ${(props) => props.theme.Typography.fontFamily} !important;
    font-size: 12px !important;

    .correlations-tooltip-header {
      font-size: 14px !important;
    }
  }
  .correlations-tooltip-values {
    display: flex;
    margin: 5px 0;

    &-left,
    &-right {
      display: flex;
      flex-direction: column;
    }

    &-left {
      text-align: left;
    }

    &-right {
      margin-left: 5px;
      text-align: right;
      flex: 1;
    }
  }

  .correlations-tooltip-delta {
    font-size: 14px;
    text-align: right;
  }

  .correlations-color-axis rect {
    stroke-width: 1;
    stroke: ${GetColor.Grey};
  }
`;

const TooltipTitle = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
`;

const TooltipRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
`;

const TooltipDelta = styled.div`
  margin-top: 5px;
  text-align: end;
  font-size: 14px;
`;
