import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { Button, DropMenu, type DropMenuItem, GetColor, Radio } from 'venn-ui-kit';
import type { CashFlowSetting } from 'venn-api';
import { CashflowSettingInfoTable } from './CashflowSettingInfo';
import { filter, isEmpty } from 'lodash';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  cashflowModalActiveSettingIdAtom,
  cashflowSettingSelectedToActivateNext,
  cashflowSettingsModalIsEditing,
  editedCashflowSettingAtom,
} from '../../../../venn-state/src/cashflow-settings/cashflowSettingsModalAtoms';
import { assertNotNil } from 'venn-utils';

type SectionId = 'SYSTEM' | 'CLASSIFICATION' | 'PARAMETER';

type CashflowSettingOptionProps = {
  sectionId: SectionId;
  title: string;
  explanation: string;
  expanded: boolean;
  onRadioClick: () => void;
  options: CashFlowSetting[];
  systemSetting: CashFlowSetting;
};

export const DEFAULT_SETTING_EXPLANATION =
  'Venn-calibrated parameters based on the fund’s metadata (asset class, strategy, and vintage).';

export const CLASSIFICATION_SETTING_EXPLANATION =
  'Venn will automatically override the fund’s default parameters with ' +
  "pre-calibrated parameters for the chosen classification instead of the fund's metadata. Note: this setting will not modify the fund's metadata.";

export const PARAMETER_SETTING_EXPLANATION =
  'Customize the parameters by overriding the fund’s default rate of contribution(s), ' +
  'life expectancy, bow, yield and/or growth. ' +
  'Note: this will not impact the default parameters of other funds with the same classification.';

const findSelectedSettingById = (options: CashFlowSetting[], settingId: string | undefined, sectionId: SectionId) => {
  if (sectionId === 'SYSTEM') {
    return options[0];
  }
  return options.find((option) => option.settingId === settingId);
};

export const CashflowSettingOption = ({
  sectionId,
  title,
  explanation,
  options,
  onRadioClick,
  expanded,
  systemSetting,
}: CashflowSettingOptionProps) => {
  const [selectedToActivateNext, setSelectedToActivateNext] = useRecoilState(cashflowSettingSelectedToActivateNext);
  const activeSettingId = useRecoilValue(cashflowModalActiveSettingIdAtom);
  const setEditedCashflowSetting = useSetRecoilState(editedCashflowSettingAtom);
  const setIsEditing = useSetRecoilState(cashflowSettingsModalIsEditing);

  const dropdownItems: DropMenuItem<string>[] = useMemo(() => {
    return filter(options, (setting) => !!setting?.settingId).map((setting) => {
      return {
        label: setting.name,
        value: assertNotNil(setting.settingId), // filtered above, no idea why typescript doesn't pick it up
      };
    });
  }, [options]);

  const selectedSetting = useMemo(() => {
    return findSelectedSettingById(options, selectedToActivateNext ?? activeSettingId, sectionId);
  }, [options, selectedToActivateNext, activeSettingId, sectionId]);

  const onClickEditOrAdd = useCallback(
    (editedSetting: CashFlowSetting | undefined) => {
      setEditedCashflowSetting((_) => editedSetting);
      setIsEditing((_) => true);
    },
    [setIsEditing, setEditedCashflowSetting],
  );

  return (
    <MainContainer>
      <HeaderContainer htmlFor={`${sectionId}`} data-testid={`override-selector-label-for-${sectionId}`}>
        <Radio
          className="qa-radio-label"
          inputId={`${sectionId}`}
          value={`${sectionId}`}
          checked={expanded}
          onChange={onRadioClick}
          disabled={false}
        />
        {title}
      </HeaderContainer>
      <Explanation>{explanation}</Explanation>
      {expanded && (
        <>
          {sectionId !== 'SYSTEM' && (
            <ActionsDropdownContainer>
              <DropMenu
                data-testid="cashflow-settings-selector"
                placeholder={isEmpty(dropdownItems) ? 'No settings available in this workspace' : 'Select a setting'}
                items={dropdownItems}
                selected={selectedSetting?.settingId}
                className="qa-select-setting-dropdown"
                width={477}
                onChange={(setting: DropMenuItem<string>) => {
                  const newlySelectedSettingId = setting.value;
                  setSelectedToActivateNext(() =>
                    newlySelectedSettingId === activeSettingId ? undefined : newlySelectedSettingId,
                  );
                }}
                disabled={false}
                minimumItemsToTrigger={1}
              />
              <Button
                data-testid="edit-cashflow-setting-button"
                dense
                disabled={!selectedSetting}
                onClick={() => onClickEditOrAdd(selectedSetting)}
                noMargin
              >
                EDIT
              </Button>
              <Button
                dense
                data-testid="add-new-cashflow-setting-button"
                onClick={() =>
                  onClickEditOrAdd({
                    ...systemSetting,
                    //
                    settingId: undefined, // backend will assign the UUID for new setting
                    name: '',
                    overrideType: sectionId,
                    userCreated: true,
                    owner: undefined,
                  })
                }
                noMargin
              >
                ADD NEW
              </Button>
            </ActionsDropdownContainer>
          )}
          <CashflowSettingInfoTable setting={selectedSetting} />
        </>
      )}
    </MainContainer>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
`;

const ActionsDropdownContainer = styled.div`
  height: 36px;
  padding-left: 21px;
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  gap: 8px;
`;

const HeaderContainer = styled.label`
  display: flex;
  flex-direction: row;
  font-weight: bold;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  cursor: pointer;
  .qa-radio-label {
    margin-right: 0;
  }
`;

const Explanation = styled.div`
  color: ${GetColor.HintGrey};
  padding-left: 21px;
`;
