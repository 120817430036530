import { ForecastOverrideActions } from '../views/ForecastOverrideActions';
import { analyticsService, assertExhaustive, assertNotNil } from 'venn-utils';
import React, { useContext } from 'react';
import { cloneDeep, find } from 'lodash';
import type { CustomFactorForecast } from 'venn-api';
import { useRecoilValue } from 'recoil';
import {
  CASH_FACTOR_NAME,
  factorCurrentPendingOverrideAtom,
  getTableDataForForecast,
  isFactorOverrideCancelButtonDisabled,
  isFactorOverrideSubmitButtonDisabledAtom,
  useResetFactorOverrideState,
} from 'venn-state';
import UserContext from '../../../contexts/user-context';
import { ForecastPanelActionsContext } from '../contexts/ForecastPanelActionsContext';

export type FactorForecastPanelFooterProps = {
  forecast: CustomFactorForecast;

  onResidualForecastUpdated?: (fundId?: string) => void;
};
export const FactorForecastPanelFooter = ({ forecast }: FactorForecastPanelFooterProps) => {
  const { profileSettings } = useContext(UserContext);
  const { onUpdateOrCreateFactorForecast } = useContext(ForecastPanelActionsContext);
  const factorCurrentPendingOverride = useRecoilValue(factorCurrentPendingOverrideAtom);
  const trackOverrides = () => {
    const defaultValueForFactor = (name: string): number => {
      if (name === CASH_FACTOR_NAME) {
        return forecast.cashForecast;
      }
      const result = find(forecast.factorForecasts, { name })?.annualizedReturn;
      return assertNotNil(result);
    };
    const updated = new Date().toISOString();
    Object.keys(factorCurrentPendingOverride).forEach((name) => {
      const pendingOverride = factorCurrentPendingOverride[name];
      if (pendingOverride === 'no change' || pendingOverride === 'invalid change') {
        return;
      }
      if (pendingOverride === 'reset' || typeof pendingOverride === 'number') {
        const forecastValue = pendingOverride === 'reset' ? defaultValueForFactor(name) : pendingOverride;
        analyticsService.forecastFactorOverride({
          factor: name,
          forecastId: forecast.forecastId,
          forecastValue,
          updated,
          userId: profileSettings?.user.id,
        });
        return;
      }
      assertExhaustive(pendingOverride, 'unexpected override provided');
    });
  };
  const updateFactorForecast = async () => {
    const updatedValueForFactor = (name: string, priorOverride: number | undefined): number | undefined => {
      const override = factorCurrentPendingOverride[name];
      if (override === 'reset') {
        return undefined;
      }
      if (override === 'no change') {
        return priorOverride;
      }
      if (override === 'invalid change') {
        throw new Error('attempted to override factor with an invalid number');
      }
      if (typeof override === 'number') {
        return override;
      }
      return assertExhaustive(override, 'unexpected override provided');
    };
    const updatedForecast = cloneDeep(forecast);
    updatedForecast.cashForecastOverride = updatedValueForFactor(CASH_FACTOR_NAME, forecast.cashForecastOverride);
    updatedForecast.factorForecasts = forecast.factorForecasts.map(({ name, annualizedReturnOverride, ...rest }) => {
      return {
        ...rest,
        name,
        annualizedReturnOverride: updatedValueForFactor(name, annualizedReturnOverride),
      };
    });
    await onUpdateOrCreateFactorForecast(updatedForecast, false);
  };
  const tableData = getTableDataForForecast(forecast);
  const { resetAllState, resetNonInputEditingState } = useResetFactorOverrideState(tableData);
  const isSubmitButtonDisabled = useRecoilValue(isFactorOverrideSubmitButtonDisabledAtom);
  const isCancelButtonDisabled = useRecoilValue(isFactorOverrideCancelButtonDisabled);
  return (
    <ForecastOverrideActions
      isSubmitButtonDisabled={isSubmitButtonDisabled}
      isCancelButtonDisabled={isCancelButtonDisabled}
      onCancelClick={() => {
        resetAllState();
        analyticsService.ctaClicked({
          text: 'Cancel',
          purpose: 'Cancel Forecast Changes',
        });
      }}
      onApplyClick={async () => {
        await updateFactorForecast();
        resetNonInputEditingState();
        analyticsService.ctaClicked({
          text: 'Apply',
          purpose: 'Apply Forecast Changes',
        });
        trackOverrides();
      }}
      tooltipContent="Factor forecast overrides will disregard any inputs used to create the Venn factor forecasts"
      tooltipSideText="Apply forecast changes?"
    />
  );
};
