import type { ReactNode } from 'react';
import React from 'react';
import { RecoilTestWrapper } from './RecoilTestWrapper';
import { type RecoilWrapperState } from './testRecoilState';

/**
 * Convenience utility to wrap a hook with a {@link RecoilTestWrapper} and optional observers.
 *
 * @param states An optional array of Recoil initial state values
 * @param observers An optional array of JSX elements representing observers for the Recoil state, used as prepended siblings to the hook component.
 */
export const createRecoilHookWrapper = (states?: RecoilWrapperState[], observers?: JSX.Element[]) =>
  function RecoilHookWrapper({ children }: { children?: ReactNode }) {
    return (
      <RecoilTestWrapper states={states}>
        {observers}
        {children}
      </RecoilTestWrapper>
    );
  };
