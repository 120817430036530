import React, { useCallback, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { SIDE_PANEL_CONTENT_CLASSNAME, TypeaheadSearchBar } from 'venn-components';
import { ButtonFilterDropMenu, GetColor } from 'venn-ui-kit';
import { getValue, type IncludedAttributeOption } from './useAddeparViewConfiguration';

interface AttributeFilterProps {
  viewId: string;
  applyToAttributeOptions: (viewid: string, selected: string[]) => void;
  includedAttributes: IncludedAttributeOption[];
  disabled: boolean;
  hasError: boolean;
}

const AttributeFilter = ({
  viewId,
  applyToAttributeOptions,
  includedAttributes,
  disabled,
  hasError,
}: AttributeFilterProps) => {
  const handleFilter = useCallback(
    (selected: string | string[]) => {
      const selectedItems = Array.isArray(selected) ? selected : [selected];
      applyToAttributeOptions(viewId, selectedItems);
    },
    [applyToAttributeOptions, viewId],
  );
  const [textFilter, setTextFilter] = useState<string>('');

  const searchComponent = useMemo(() => {
    return (
      <SearchBarContainer>
        <TypeaheadSearchBar
          disableAutofocus
          placeholder="Search attributes"
          value={textFilter}
          onChange={setTextFilter}
        />
      </SearchBarContainer>
    );
  }, [textFilter, setTextFilter]);

  const items = useMemo(
    () => includedAttributes.map((i) => ({ checked: i.selected, value: getValue(i), label: i.name })),
    [includedAttributes],
  );

  const selectedItems = useMemo(() => includedAttributes.filter((i) => i.selected), [includedAttributes]);

  return (
    <StyledFilter
      header={searchComponent}
      label={`Select included attributes (${selectedItems.length}/${includedAttributes.length})`}
      items={items}
      onFilter={handleFilter}
      closeOnOnlyClick
      textFilter={textFilter}
      fullWidth
      menuHeight={300}
      autoScrollParentClassName={SIDE_PANEL_CONTENT_CLASSNAME}
      disabled={includedAttributes.length === 0 || disabled}
      hasError={hasError}
    />
  );
};

const SearchBarContainer = styled.div`
  padding: 4px;
`;

const StyledFilter = styled(ButtonFilterDropMenu)<{ hasError: boolean }>`
  > button {
    width: 100%;
    ${({ hasError }) =>
      hasError &&
      css`
        border-color: ${GetColor.Error};
      `}
  }

  > div {
    width: 100%;
  }
`;

export default AttributeFilter;
