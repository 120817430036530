import { useEffect, type RefObject } from 'react';
import { useRecoilValue, waitForAll } from 'recoil';
import {
  blockFonts as blockFontsState,
  blockSubHeader as blockSubHeaderState,
  reportZoom as reportZoomState,
} from 'venn-state';
import type HighchartsReact from 'highcharts-react-official';

export const useChartReflow = (selectedRefId: string, chartRef: RefObject<HighchartsReact.RefObject>) => {
  const blockFonts = useRecoilValue(waitForAll(Object.values(blockFontsState).map((state) => state(selectedRefId))));
  const blockSubHeader = useRecoilValue(blockSubHeaderState(selectedRefId));
  const reportZoom = useRecoilValue(reportZoomState);
  useEffect(() => {
    chartRef.current?.chart?.reflow?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, blockSubHeader, reportZoom, ...blockFonts]);
};
