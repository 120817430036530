import { useContext, useState, useEffect } from 'react';
import { FactorLensesContext } from 'venn-components';
import type { NotableFactorPeriod } from 'venn-api';
import { getFactorLensPerformance } from 'venn-api';
import { useApi, useHasFF } from 'venn-utils';
import type { Factor } from './types';
import { getDeepDiveContent } from './content';

interface FactorPerformance {
  name: string;
  returns: number[][];
}

function useFactorPerformance(factor: Factor, period: NotableFactorPeriod): FactorPerformance | undefined {
  const { factorLenses } = useContext(FactorLensesContext);
  const [performances, setPerformances] = useState<FactorPerformance | undefined>(undefined);
  const abortableGetFactorLensPerformance = useApi(getFactorLensPerformance);
  const hasNewFactorLens = useHasFF('mstar_indices_factors_ff');

  useEffect(() => {
    const fetchPerformance = async () => {
      const content = getDeepDiveContent(hasNewFactorLens);
      if (factorLenses && period.startDate && period.endDate) {
        const factorEntity = factorLenses[0].factors.find((f) => f.name === content[factor].name);
        if (factorEntity) {
          try {
            const performanceResponse = await abortableGetFactorLensPerformance(factorLenses[0].id, false, {
              factorIds: [factorEntity.id],
              fundId: '',
              showInputs: false,
              start: period.startDate,
              end: period.endDate,
            });

            if (performanceResponse.content) {
              setPerformances({
                name: factorEntity.name,
                returns: performanceResponse.content.entities[0].cumulativeReturns,
              });
            }
          } catch (e) {
            if (e.name !== 'AbortError') {
              setPerformances({
                name: factorEntity.name,
                returns: [[]],
              });
            }
          }
        }
      } else {
        setPerformances(undefined);
      }
    };
    fetchPerformance();
  }, [factor, period, factorLenses, abortableGetFactorLensPerformance, hasNewFactorLens]);

  return performances;
}

export default useFactorPerformance;
