import React from 'react';
import { noop } from 'lodash';

interface ModalContextValue {
  isModalOpen: boolean;
  title: string;
  activeFactorLens: string | undefined;
  openModal: () => void;
  closeModal: () => void;
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
const ModalContext = React.createContext<ModalContextValue>({
  isModalOpen: false,
  title: '',
  activeFactorLens: undefined,
  openModal: noop,
  closeModal: noop,
});

export const { Consumer, Provider } = ModalContext;
export default ModalContext;
