import React from 'react';
import { canToggleHideBlockBorders, hideBlockBorders } from 'venn-state';
import { useRecoilState, useRecoilValue } from 'recoil';
import { FlexHeader, Section } from '../../../../shared';
import { Label } from 'venn-ui-kit';
import { SliderToggle } from 'venn-components';
import styled from 'styled-components';

interface BlockStylesTabProps {
  selectedBlockId: string;
}

const BlockStylesTab = ({ selectedBlockId }: BlockStylesTabProps) => {
  const canSeeToggle = useRecoilValue(canToggleHideBlockBorders(selectedBlockId));
  const [hideBorders, setHideBorders] = useRecoilState(hideBlockBorders(selectedBlockId));

  if (!canSeeToggle) {
    return null;
  }

  return (
    <Section>
      <FlexHeader>Block Styles</FlexHeader>
      <LeftRight>
        <Label>Show Block Border</Label>
        <SliderToggle toggled={!hideBorders} onToggle={() => setHideBorders(!hideBorders)} />
      </LeftRight>
    </Section>
  );
};

export default BlockStylesTab;

const LeftRight = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
