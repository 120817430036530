import React, { useState } from 'react';
import styled from 'styled-components';
import { Icon, GetColor, EllipsisTooltipSpan } from 'venn-ui-kit';
import { convertToLetters } from '../utils';
import type { Option as FundTypeaheadOption } from '../../../../fund-typeahead/types';
import type { Fund } from 'venn-api';
import type { DropdownOption, DropdownValue } from '../Dropdown';
import type { DataCell } from '../../types';
import { DataUploaderMode } from '../../types';
import FundLinker from './FundLinker';
import { isNil } from 'lodash';

export interface FundCellProps {
  /**
   * Name of the fund displayed
   */
  fund: string;
  /**
   * Cell coordinates where this fund is coming from
   */
  origin: DataCell;
  /**
   * Whether this is a new fund
   */
  isNew: boolean;
  /**
   * Disables the entire control
   */
  disabled: boolean;
  /**
   * Enables the append type selector even if the the cell is disabled
   */
  alwaysEnableAppendTypeSelector: boolean;
  /**
   * List of dropdowns options
   */
  settingsOptions: FundCellSetting[];
  /**
   * Values for the dropdowns
   */
  settings: (string | number)[];
  /**
   * Fires when one of the dropdown value changes
   */
  onSettingsChanged?: (settings: DropdownValue[]) => void;

  /**
   * Whether to display the link icon to link the fund to an existing fund
   */
  canLink: boolean;
  /**
   * Fires when selecting an existing fund to link to
   */
  onLink: (fund: Fund) => void;
  /**
   * Fires when selecting "New Investment", returns the name
   */
  onLinkNew: (name: string) => void;

  className?: string;

  /**
   * Whether dropdowns are opening up or down
   */
  opensUp: boolean;
}

export interface FundCellSetting {
  /**
   * List of options for a given column.
   */
  options: DropdownOption[];
  /**
   * If readonly, the value will be displayed but won't be changeable.
   */
  readonly?: boolean;
  /**
   * If isAppendSetting, this setting can be enabled even when the cell is disabled
   */
  isAppendSetting: boolean;
}

const FundCell = ({ onLink, fund, origin, isNew, disabled, canLink, className }: FundCellProps) => {
  const [selectorOpen, updateSelector] = useState(false);

  const onFundSelect = (selectedFund: FundTypeaheadOption) => {
    if (selectedFund.fund) {
      onLink(selectedFund.fund);
    }
  };

  return (
    <Container className={className}>
      <Left>
        {isNew ? (
          <DiamondContainer greyed={disabled}>
            <Diamond type="diamond" />
          </DiamondContainer>
        ) : null}
      </Left>
      <Center>
        <GreyableContainer greyed={disabled}>
          <FundName>
            <EllipsisTooltipSpan maxWidth={200} onClick={() => updateSelector(!selectorOpen)}>
              {fund}
            </EllipsisTooltipSpan>
            {canLink ? (
              <StyledFundLinker
                leftPosition={5}
                isNew={isNew}
                onFundSelect={onFundSelect}
                value={fund}
                selectorOpen={selectorOpen}
                updateSelector={updateSelector}
                forceSelection
                alignInputTextToCheckMark
                mode={DataUploaderMode.NewNavs}
              />
            ) : null}
          </FundName>
          {!isNil(origin?.column) && !isNil(origin?.row) && (
            <Origin>
              Origin: {convertToLetters(origin.column)}:{origin.row + 1}
              &nbsp;
              {origin.value}
            </Origin>
          )}
        </GreyableContainer>
      </Center>
    </Container>
  );
};

const GreyableContainer = styled.div<{ greyed: boolean }>`
  opacity: ${(props) => (props.greyed ? 0.25 : 1)};
`;

const Container = styled.div`
  width: 250px;
  display: flex;
`;

const FundName = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${GetColor.Black};
  display: flex;
  align-items: center;
`;

const Origin = styled.div`
  font-size: 10px;
  margin-top: -4px;
  color: ${GetColor.MidGrey2};
`;

const Left = styled.div`
  width: 15px;
`;

const Center = styled.div`
  flex: 1;
`;

const Diamond = styled(Icon)`
  color: ${GetColor.Primary.Dark};
  font-size: 6px;
`;

const DiamondContainer = styled(GreyableContainer)`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding-bottom: 2px;
`;

const StyledFundLinker = styled(FundLinker)`
  font-size: 12px;
  vertical-align: super;
  margin-left: 6px;
`;

export default FundCell;
