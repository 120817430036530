import Numbers from '../numbers';

const { getNumberShorthand, formatNumber, shortenNumber } = Numbers;

/**
 * Formats the allocation value in Allocation Panel.
 *
 * @param value the value to format.
 * @param isRoot indicates the larger font size that cuts one digit from what we can display.
 */
export const formatAllocation = (
  value: number,
  isRoot: boolean,
  isPercentage?: boolean,
  total?: number,
  isTrade?: boolean,
  maxDecimalPlaces?: number,
): string => {
  const hasDecimalPoints = Number(value.toFixed(0)) !== value;
  const hasShorthandSuffix = getNumberShorthand(value) !== '';
  // We can show roughly 5 characters for root, 6 characters for other strategies.
  // That number grows to 7 characters for root, 8 characters for other strategies if `isCompare` is true.
  // If we need to show a decimal point, this number goes down by one.
  const maxDigitsThatFit = (isRoot ? 5 : 6) - (hasShorthandSuffix ? 1 : 0) - (isTrade ? 1 : 0);
  const power = hasDecimalPoints ? maxDigitsThatFit - 1 : maxDigitsThatFit;
  const maxValueThatFits = 10 ** power - (hasDecimalPoints ? 0.1 : 1);
  let result: string | number = isPercentage && total ? (value * 100) / total : value;
  const shouldShorten = Math.abs(Number(value.toFixed(1))) > maxValueThatFits;
  if (shouldShorten && !isPercentage) {
    result = shortenNumber(value);
    if (result === value && isRoot) {
      // We're below 1M and the shortening method didn't shorten
      result = formatNumber(result, 0).toLocaleString();
      if (isRoot) {
        return `$${result}`;
      }
    }
  }

  if (!hasDecimalPoints && value.toFixed(0).length === maxDigitsThatFit) {
    result = formatNumber(result, 0).toLocaleString();
  } else {
    result = formatNumber(result, maxDecimalPlaces ?? 1).toLocaleString();
  }

  if (shouldShorten && !isPercentage) {
    result = `${result}${getNumberShorthand(value)}`;
  }

  if (isRoot) {
    result = isTrade ? result : isPercentage ? `${result}%` : `$${result}`;
  }

  if (isTrade) {
    return formatTrade(result);
  }

  return result;
};

const formatTrade = (value: string): string => (Number.parseFloat(value) > 0 ? `+${value}` : value);
