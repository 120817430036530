import styled, { css } from 'styled-components';
import { ColorUtils, GetColor } from 'venn-ui-kit';
import { InputCSS } from '../../components/styled';

export const Idx = styled.div`
  height: 20px;
  width: 20px;
  background-color: ${GetColor.Black};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PartWrapperCSS = css`
  height: 20px;
  border-radius: 4px;
  max-width: 370px;
  display: flex;
  align-items: center;
  padding: 2px 5px;
  margin: 0 5px;
`;

export const PartWrapperButton = styled.button<{ empty?: boolean; active?: boolean; disabled?: boolean }>`
  ${PartWrapperCSS}

  border: 1px solid ${({ empty }) => (empty ? GetColor.Primary.Dark : GetColor.Grey)};
  background-color: ${({ empty }) => (empty ? GetColor.White : GetColor.WhiteGrey)};
  color: ${({ empty }) => (empty ? GetColor.Primary.Dark : GetColor.Black)};

  &:hover {
    ${({ empty }) =>
      empty &&
      css`
        background-color: ${ColorUtils.opacifyFrom(GetColor.Primary.Dark, 0.1)};
        color: ${GetColor.Primary.Dark};
      `}
  }

  ${({ active }) =>
    active
      ? css`
          font-weight: bold;
          color: ${GetColor.Primary.Dark};
        `
      : null}

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${GetColor.HintGrey} !important;
    `}
`;

export const PartWrapper = styled.div`
  ${PartWrapperCSS}
  border: 1px solid ${GetColor.Grey};
  background-color: ${GetColor.WhiteGrey};
  color: ${GetColor.Black};
`;

export const LockedInput = styled.button`
  ${InputCSS}
  background-color: ${GetColor.WhiteGrey};
  border: 1px solid ${GetColor.Grey};
  color: ${GetColor.Black};
`;
