import React, { useContext, useEffect, useState } from 'react';
import { type MultiPortfolioParseResult, parseMultiPortfolioFile, TABS, type UploadTypeEnum } from 'venn-api';
import UniversalUploaderContext from '../../../contexts/universal-uploader';
import SideMenu from '../../../side-menu/SideMenu';
import DataGallery from '../components/DataGallery';
import DataInput from '../components/DataInput';
import { ErrorMessage } from '../components/page-parts';
import PasteInput from '../components/PasteInput';
import { DataUploaderMode, DataUploaderView } from '../types';
import { IChooseActionPortfolioView } from '../constants';
import { UniversalUploaderFooter } from '../components/page-parts/UniversalUploaderFooter';
import { getTabName, uploadConfig } from '../utils';
import { MainUploadRow, MainUploadWrapper, UploadContent, UploadSideBar } from './shared/layout';
import { analyticsService } from 'venn-utils';

type MultiPortfolioUploadStepProps = Readonly<{
  goToReviewStep: (data: MultiPortfolioParseResult) => void;
}>;

const getTabs = () => {
  return [
    {
      label: 'Upload Portfolio Data',
      value: TABS.UPLOAD,
    },
    {
      label: 'Paste Portfolio Data',
      value: TABS.PASTE,
    },
    {
      label: 'Formats and Templates',
      value: TABS.TEMPLATES,
    },
  ];
};

const MultiPortfolioUploadStep = ({ goToReviewStep }: MultiPortfolioUploadStepProps) => {
  const [tab, setTab] = useState(TABS.UPLOAD);
  const [errorMessage, setErrorMessage] = useState('');
  const [isUploading, setIsUploading] = useState(false);

  const { setView } = useContext(UniversalUploaderContext);

  useEffect(() => {
    analyticsService.uploadStepViewed({
      dataType: uploadConfig[DataUploaderMode.Portfolios].dataType,
      step: 0,
      stepName: DataUploaderView.Upload,
    });
  }, []);

  const changeTab = (tab: TABS) => {
    setTab(tab);
    analyticsService.uploadTabChanged({
      isMultiPortfolioUploader: true,
      TabName: getTabName(tab),
    });
  };

  const uploadFile = async (file: File) => {
    await uploadFileImpl(file, 'FILE', file.name);
  };

  const pasteFile = async (file: Blob) => {
    await uploadFileImpl(file, 'COPY_PASTE', undefined);
  };

  const uploadFileImpl = async (file: Blob, uploadType: UploadTypeEnum, filename: string | undefined) => {
    setErrorMessage('');
    setIsUploading(true);

    const formData = new FormData();
    formData.append('file', file);

    analyticsService.uploadAttempted({
      dataType: uploadConfig[DataUploaderMode.Portfolios].dataType,
      filename,
      type: uploadType,
    });

    try {
      const parsedData = (await parseMultiPortfolioFile(formData)).content;

      analyticsService.uploadStepCompleted({
        step: 0,
        dataType: uploadConfig[DataUploaderMode.Portfolios].dataType,
        stepName: DataUploaderView.Upload,
        uploadType,
      });

      goToReviewStep(parsedData);
    } catch (e) {
      setErrorMessage(e?.content?.message ?? 'Unable to process file. Please try again or contact Venn Support.');

      analyticsService.uploadStepFailed({
        step: 0,
        dataType: uploadConfig[DataUploaderMode.Portfolios].dataType,
        stepName: DataUploaderView.Upload,
        uploadType,
        error: e?.content?.message ?? e?.message,
      });
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <MainUploadWrapper>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <MainUploadRow>
        <UploadSideBar data-testid="sidebar">
          <SideMenu
            refreshedStyling
            items={getTabs()}
            onClick={changeTab}
            selectedItems={tab}
            withoutContainer
            allowItemOverflow
          />
        </UploadSideBar>
        <UploadContent>
          {tab === TABS.UPLOAD && (
            <DataInput
              onFileChange={uploadFile}
              loading={isUploading}
              setError={setErrorMessage}
              mode={DataUploaderMode.Portfolios}
              isUniversalUploader
            />
          )}
          {tab === TABS.PASTE && (
            <PasteInput onPaste={pasteFile} mode={DataUploaderMode.Portfolios} isUploading={isUploading} />
          )}
          {tab === TABS.TEMPLATES && <DataGallery mode={DataUploaderMode.Portfolios} isUniversalUploader />}
        </UploadContent>
      </MainUploadRow>
      <UniversalUploaderFooter onCancel={() => setView(IChooseActionPortfolioView)} />
    </MainUploadWrapper>
  );
};

export default MultiPortfolioUploadStep;
