import React from 'react';
import styled from 'styled-components';
import type { BasicTableProps, BasicTableColumn, StyledTableType } from 'venn-components';
import { BasicTable, EmptyState } from 'venn-components';
import { GetColor, Icon, Shimmer } from 'venn-ui-kit';
import { Numbers } from 'venn-utils';
import { isNil } from 'lodash';

export const Table: StyledTableType<unknown> = styled(
  <T extends BasicTableColumn<K>, K>(props: BasicTableProps<T, K>) => <BasicTable<T, K> {...props} rowHeight={36} />,
)`
  table-layout: fixed;
  thead {
    tr:last-child {
      th {
        color: ${GetColor.DarkGrey};
        padding-left: 6px;
        width: 95px;
      }
      th:first-child {
        padding-left: 20px;
        width: 100%;
        min-width: 96px;
      }
      th:last-child {
        padding-right: 20px;
        min-width: 88px;
      }
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid ${GetColor.Grey};
      td:first-child {
        padding-left: 20px;
      }
      td:last-child {
        padding-right: 20px;
      }
    }
    tr:hover {
      background-color: ${GetColor.DEPRECATED_DivergingColor.B1};
    }
    tr:last-child {
      border-bottom: none;
      padding-right: 20px;
    }
  }
`;

export const TableEmptyState = styled(EmptyState)`
  margin: 0 20px 20px 20px;
  & > div > span {
    color: ${GetColor.Grey};
  }
`;

export const TableShimmer = styled.div`
  & > :not(:last-child) {
    margin-bottom: 1px;
  }
`;

export const TableShimmerRow = styled.div`
  height: 36px;
  ${Shimmer};
  animation-duration: 5s;
`;

export const ShimmerTr = styled.tr`
  &&& {
    padding: 0;
    & > td {
      padding: 0;
    }
    pointer-events: none;
  }
`;

const WIDE_SUPERHEADER_WIDTH = 310;
const NARROW_SUPERHEADER_WIDTH = 160;
const LAST_COLUMN_PADDING = 20;
export const SuperHeader = styled.th<{ wide?: boolean }>`
  & > div {
    border-top: 2px solid ${GetColor.Black};
    margin-left: 10px;
    padding-top: 2px;
  }
  text-transform: uppercase;
  && {
    color: ${GetColor.Black};
    text-align: center;
    font-size: 11px;
    width: ${({ wide }) => (wide ? WIDE_SUPERHEADER_WIDTH : NARROW_SUPERHEADER_WIDTH)}px;
    &:last-child {
      width: ${({ wide }) => (wide ? WIDE_SUPERHEADER_WIDTH : NARROW_SUPERHEADER_WIDTH) + LAST_COLUMN_PADDING}px;
    }
    padding-left: 0;
  }
`;

interface DeltaProps {
  base: number | undefined;
  value: number | undefined;
  isPercentage?: boolean;
}

export const Delta = ({ base, value, isPercentage }: DeltaProps) => {
  if (isNil(base) || isNil(value)) {
    return <DeltaPlaceholder>--</DeltaPlaceholder>;
  }

  const delta = value - base;
  const formattedDelta = isPercentage ? Numbers.safeFormatPercentage(delta, 1) : Numbers.safeFormatNumber(delta, 1);
  if (formattedDelta === '0.0' || formattedDelta === '0.0%') {
    return <DeltaPlaceholder>--</DeltaPlaceholder>;
  }
  return (
    <StyledDelta value={delta}>
      <Icon type={delta > 0 ? 'caret-up' : 'caret-down'} />
      {formattedDelta}
    </StyledDelta>
  );
};

const StyledDelta = styled.div<{ value: number }>`
  padding-left: 10px;
  color: ${({ value }) => (value > 0 ? GetColor.DivergingColor.B4 : GetColor.DivergingColor.A4)};
  & > i {
    margin-right: 4px;
  }
`;

const DeltaPlaceholder = styled.div`
  padding-left: 10px;
`;
