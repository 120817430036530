import React from 'react';
import styled from 'styled-components';
import { type PrivatePortfolioNode } from 'venn-api';
import { Flexbox } from 'venn-ui-kit';
import CreatePrivatePortfolioStepContainer from '../create-portfolio/CreatePrivatePortfolioStepContainer';

interface CreatePrivatePortfolioUniversalUploaderStepProps {
  onCancel: () => void;
  onPrivatePortfolioCreated: (portfolio: PrivatePortfolioNode) => void;
}

const CreatePrivatePortfolioUniversalUploaderStep = (props: CreatePrivatePortfolioUniversalUploaderStepProps) => {
  return (
    <CreatePrivatePortfolioStepContainer.Root>
      <CreatePrivatePortfolioStepContainer.Overlay>
        <BodyLayout direction="column" grow={1} alignItems="baseline" justifyContent="flex-start">
          <StyledFlexbox direction="column">
            <CreatePrivatePortfolioStepContainer.Body />
          </StyledFlexbox>
        </BodyLayout>
        <CreatePrivatePortfolioStepContainer.Footer {...props} primaryLabel="Create & open in studio" />
      </CreatePrivatePortfolioStepContainer.Overlay>
    </CreatePrivatePortfolioStepContainer.Root>
  );
};

export default CreatePrivatePortfolioUniversalUploaderStep;

const BodyLayout = styled(Flexbox)`
  padding: 20px 24px;
`;

const StyledFlexbox = styled(Flexbox)`
  gap: 20px;
  width: 100%;
`;
