import { useEffect, useRef } from 'react';
import { type RecoilValue, useRecoilValueLoadable } from 'recoil';

interface RecoilObserverProps<T> {
  node: RecoilValue<T>;
  onChange: (value: T) => void;
  ignoreFirstValue?: boolean;
}

export const RecoilObserver = <T,>({ node, onChange, ignoreFirstValue = true }: RecoilObserverProps<T>) => {
  const isFirstValue = useRef(ignoreFirstValue);

  const loadable = useRecoilValueLoadable(node);

  useEffect(() => {
    if (loadable.state === 'hasValue') {
      if (isFirstValue.current) {
        isFirstValue.current = false;
      } else {
        onChange(loadable.contents);
      }
    }
  }, [onChange, loadable]);

  return null;
};
