import React from 'react';
import styled from 'styled-components';
import type { HeatMapTypes } from '../heat-map/Types';

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default (factor: HeatMapTypes.Root, compareFactor: HeatMapTypes.Root, value: number): JSX.Element | null => {
  if (value === null || value === undefined) {
    return null;
  }

  const correlationVal = value.toFixed(2);
  const correlationString = correlationVal === '-0.00' ? '0.00' : correlationVal;

  return (
    <>
      <Line>
        <strong>{factor.name}</strong> & <strong>{compareFactor.name}</strong>
      </Line>
      <Line>
        Correlation: <strong>{correlationString}</strong>
      </Line>
    </>
  );
};

const Line = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;
