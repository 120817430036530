import React, { useCallback } from 'react';
import KeyCodes from '../../../KeyCode';
import { Label } from '../../typography';
import { CalendarIcon, Container, IconContainer, IconContainerInner, LabelContainer } from '../trigger/Trigger';
import { isNil } from 'lodash';
import styled from 'styled-components';
import { GetColor } from '../../../style/color';
import { Dates } from 'venn-utils';
import moment from 'moment';
import Icon from '../../icon/Icon';

interface DateFilterTriggerProps {
  placeholder?: string;
  month?: number;
  year?: number;
  opened?: boolean;
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
  onClear?: () => void;
}

const DateFilterTrigger = ({
  placeholder,
  month,
  year,
  opened,
  disabled,
  className,
  onClick,
  onClear,
}: DateFilterTriggerProps) => {
  const handleKeyUp = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.keyCode === KeyCodes.Enter || e.keyCode === KeyCodes.Space) {
        if (onClick && !disabled) {
          e.preventDefault();
          onClick();
        }
      }
    },
    [onClick, disabled],
  );
  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.keyCode === KeyCodes.Enter || e.keyCode === KeyCodes.Space) {
      e.preventDefault();
    }
  }, []);

  const label = formatSelection(month, year);
  return (
    <Container
      fullWidth
      granularity="month"
      disabled={disabled}
      onClick={onClick}
      onKeyUp={handleKeyUp}
      onKeyDown={handleKeyDown}
      className={className}
      tabIndex={disabled ? -1 : 0}
      role="button"
      aria-expanded={opened}
      aria-haspopup
      data-testid="qa-date-filter"
    >
      <IconContainer opened={opened} disabled={disabled}>
        <IconContainerInner opened={opened} disabled={disabled}>
          <CalendarIcon type="calendar" />
        </IconContainerInner>
      </IconContainer>
      <StyledLabelContainer disabled={disabled}>
        {label ? (
          <>
            <Label>{label}</Label>
            {!isNil(onClear) && (
              <ClearIcon
                type="times-circle"
                className="qa-clear-date-filter-icon"
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  event?.stopPropagation();
                  onClear();
                }}
              />
            )}
          </>
        ) : (
          <Placeholder>{placeholder}</Placeholder>
        )}
      </StyledLabelContainer>
    </Container>
  );
};

export default DateFilterTrigger;

const formatSelection = (month?: number, year?: number): string | undefined => {
  if (isNil(year)) {
    return undefined;
  }
  if (isNil(month)) {
    return Dates.toYear(moment().utc().year(year).valueOf());
  }
  return Dates.toMonthYear(moment.utc().year(year).month(month).valueOf());
};

const Placeholder = styled(Label)`
  &&& {
    color: ${GetColor.DarkGrey};
  }
`;

const StyledLabelContainer = styled(LabelContainer)`
  justify-content: space-between;
  padding: 2px 10px 0 20px;
`;

const ClearIcon = styled(Icon)`
  color: ${GetColor.MidGrey1};
  &:hover {
    color: ${GetColor.Primary.Main};
    cursor: pointer;
  }
`;
