import React, { Fragment, useContext, useState } from 'react';
import type { AnalysisSubject } from 'venn-utils';
import { useHasFF } from 'venn-utils';
import type { TooltipBodyDirection, TooltipPosition } from 'venn-ui-kit';
import { ButtonIcon } from 'venn-ui-kit';
import AnalyzeInvestmentModals from './AnalyzeInvestmentModals';
import UserContext from '../../contexts/user-context';

const OPTIMIZE_BUTTON_TEXT = 'Optimize In A Portfolio';

const Classes = {
  analyze: 'qa-analyze-button',
  optimize: 'qa-optimize-button',
  mainButton: 'qa-optimize-main-split-button',
  splitButton: 'qa-analyze-optimize-split-button',
};

interface AnalyzeInvestmentModalsLauncherProps {
  subject?: AnalysisSubject;
  disabled?: boolean;
  size?: number;
  border?: boolean;
  tooltipPosition?: TooltipPosition;
  tooltipDirection?: TooltipBodyDirection;
  Wrapper?: React.ComponentType<React.PropsWithChildren<unknown>>;
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default ({
  subject,
  disabled,
  size,
  border,
  tooltipPosition,
  tooltipDirection,
  Wrapper = Fragment,
}: AnalyzeInvestmentModalsLauncherProps) => {
  const { hasPermission } = useContext(UserContext);
  const hasOptimization = useHasFF('optimization');

  const [optimizeModal, setOptimizeModal] = useState(false);

  if (!subject || !subject.fund) {
    return null;
  }

  const optimizerEnabled = hasOptimization && hasPermission('OPTIMIZATION');

  return (
    <>
      {optimizerEnabled && (
        <Wrapper>
          <ButtonIcon
            iconType="flask"
            onClick={() => setOptimizeModal(true)}
            ctaTrackingOptions={{
              ctaDestination: `${OPTIMIZE_BUTTON_TEXT} Modal`,
              ctaPurpose: `${OPTIMIZE_BUTTON_TEXT} modal`,
              ctaText: OPTIMIZE_BUTTON_TEXT,
            }}
            tooltip="Optimize in a portfolio"
            className={Classes.optimize}
            disabled={disabled}
            size={size}
            border={border}
            tooltipPosition={tooltipPosition}
            tooltipDirection={tooltipDirection}
          />
        </Wrapper>
      )}

      <AnalyzeInvestmentModals
        fund={subject.fund}
        optimizerEnabled={optimizerEnabled}
        createOptimizeModal={optimizeModal}
        closeOptmizeModal={() => setOptimizeModal(false)}
      />
    </>
  );
};
