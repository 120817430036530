import React from 'react';
import styled from 'styled-components';
import { GetColor } from '../../style/color';

interface ProgressBarProps {
  value: number;
  error?: boolean;
}

const ProgressBar = (props: ProgressBarProps) => {
  return (
    <Container>
      <Bar {...props} data-testid={props.error ? 'progress-error' : 'progress'} />
    </Container>
  );
};

export default ProgressBar;

const Container = styled.div`
  height: 8px;
  width: 100%;
  background-color: ${GetColor.White};
  border: 2px solid ${GetColor.White};
  border-radius: 99px;
`;

const Bar = styled.div<ProgressBarProps>`
  border-radius: 99px;
  width: ${(props) => Math.max(0, Math.min(100, props.value * 100))}%;
  transition: width 1s linear;
  height: 100%;
  background: linear-gradient(
    45deg,
    ${(props) => (props.error ? GetColor.Alert : GetColor.HighlightLight)},
    ${(props) => (props.error ? GetColor.Error : GetColor.HighlightDark)}
  );
`;
