import { GetColor, getTextThemeProvider, Icon, Tooltip } from 'venn-ui-kit';
import React from 'react';
import styled from 'styled-components';

interface VenncastWarningsProps {
  warningMessage: string;
}

export const VenncastWarnings = ({ warningMessage }: VenncastWarningsProps) => (
  <Tooltip content={warningMessage} usePortal>
    <Wrapper>
      <WarningIcon type="triangle-exclamation" />
      {getTextThemeProvider().VenncastName} not available.
    </Wrapper>
  </Tooltip>
);

const Wrapper = styled.div`
  display: flex;
  column-gap: 5px;
  color: ${GetColor.GreyScale.Grey60};
  white-space: nowrap;
`;

const WarningIcon = styled(Icon)`
  font-size: 12px;
`;
