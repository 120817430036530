import React, { useContext } from 'react';
import styled from 'styled-components';
import ExportContext from '../contexts/export-context';
import {
  GetColor,
  ColorUtils,
  Icon,
  Headline3,
  Hint,
  Loading,
  ZIndex,
  LoadingSize,
  EllipsisTooltipSpan,
} from 'venn-ui-kit';

const ExportLoader = () => {
  const { downloadFileName, hasSavedId, updateDownloadInfo, hideFooter, isError } = useContext(ExportContext);
  if (!downloadFileName) {
    return null;
  }

  return (
    <LoaderBox>
      <LoaderContent>
        <DownloadIcon type="download" isError={isError} />
        <LoaderFile>
          <FileHeadline isError={isError}>
            <EllipsisTooltipSpan maxWidth={370}>{downloadFileName}</EllipsisTooltipSpan>
          </FileHeadline>
          {isError ? (
            <Hint>Download failed. Please try again or contact support if the error persists.</Hint>
          ) : (
            <Hint>Preparing your download...</Hint>
          )}
        </LoaderFile>
        {isError ? null : <Loading title="" size={LoadingSize.micro} />}
      </LoaderContent>
      {hideFooter ? null : (
        <LoaderFooter>
          {hasSavedId ? (
            <span>Access export history in the export menu or Views tab.</span>
          ) : (
            <span>Save this view to unlock export history.</span>
          )}
        </LoaderFooter>
      )}
      <CloseIcon type="times" onClick={() => updateDownloadInfo?.('')} />
    </LoaderBox>
  );
};

export default ExportLoader;

const LoaderBox = styled.div`
  position: fixed;
  right: 40px;
  top: 60px;
  background: ${GetColor.White};
  border: 1px solid ${GetColor.Grey};
  box-sizing: border-box;
  box-shadow: 0px 4px 4px ${ColorUtils.hex2rgbaFrom(GetColor.Black, 0.2)};
  z-index: ${ZIndex.StickyCover};
`;

const LoaderContent = styled.div`
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${Headline3} {
    margin-bottom: 0;
  }
`;

const LoaderFooter = styled.div`
  text-align: center;
  background-color: ${GetColor.PaleGrey};
  font-size: 10px;
  padding: 5px;
`;

const LoaderFile = styled.div`
  padding: 0 15px;
`;

const CloseIcon = styled(Icon)`
  color: ${GetColor.Primary.Dark};
  font-size: 12px;
  position: absolute;
  right: 5px;
  top: 10px;
`;

const FileHeadline = styled(Headline3)<{ isError?: boolean }>`
  color: ${(props) => (props.isError ? GetColor.Error : props.inverted ? GetColor.White : GetColor.Black)};
`;

const DownloadIcon = styled(Icon)<{ isError?: boolean }>`
  font-size: 24px;
  color: ${(props) => (props.isError ? GetColor.Error : GetColor.HighlightDark)};
`;
