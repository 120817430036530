import { useRecoilCallback } from 'recoil';
import { getUnitBase } from 'venn-components';
import { blockScenarios, isReportState } from 'venn-state';
import type { DropMenuItem } from 'venn-ui-kit';
import { analyticsService } from 'venn-utils';
import type { Scenario, ScenarioAnalysisIndex } from 'venn-api';
import { useCallback } from 'react';

const getScenario = ({ shockRange, ...restConfig }: ScenarioAnalysisIndex): Scenario => {
  const base = getUnitBase(restConfig.units);
  return {
    ...restConfig,
    ...shockRange,
    shock: restConfig.defaultScenarioShock / base,
  };
};

export const useScenarioRecoilState = (selected: string) => {
  const addScenarioInternal = useRecoilCallback(
    ({ snapshot, set }) =>
      (scenario: Scenario) => {
        set(blockScenarios(selected), (selectedScenarios) => [...selectedScenarios, scenario]);

        snapshot.getPromise(isReportState).then((isReport) =>
          analyticsService.scenarioCreated({
            entryInRange: true,
            entryReturn: scenario.shock,
            scenarioReturn: scenario.shock,
            scenarioStd: (scenario.shock - scenario.mean) / scenario.std,
            scenarioIndexName: scenario.fundName,
            location: isReport ? 'Report Lab' : 'Studio',
            type: scenario.type,
          }),
        );
      },
    [selected],
  );

  const addScenario = useCallback(
    (item: DropMenuItem<ScenarioAnalysisIndex>) => {
      const scenario = getScenario(item.value);
      addScenarioInternal(scenario);
    },
    [addScenarioInternal],
  );

  const addCategorizedScenario = useCallback(
    (item: ScenarioAnalysisIndex) => {
      const scenario = getScenario(item);
      addScenarioInternal(scenario);
    },
    [addScenarioInternal],
  );

  return { addScenario, addCategorizedScenario };
};
