import React from 'react';
import { noop } from 'lodash';
import type { GeneralAnalysisTemplate } from 'venn-api';

export interface AnalysisContextValue {
  templates: GeneralAnalysisTemplate[];
  loading: boolean;
  refresh: () => void;
}

const analysisContext = React.createContext<AnalysisContextValue>({
  templates: [],
  loading: false,
  refresh: noop,
} as AnalysisContextValue);
analysisContext.displayName = 'AnalysisContext';

export default analysisContext;
