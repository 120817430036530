import { atom, atomFamily, selector, selectorFamily } from 'recoil';
import { compact } from 'lodash';

/**
 * Has a private fund been marked as deleted in the upload dialog (so we can skip the upload)
 */
export const isDeletedPrivateFund = atomFamily<boolean, string>({
  key: 'isDeletedPrivateFund',
  default: false,
});

/**
 * The set of private fund IDs that have been mapped in the upload dialog, we use the rawFundName as
 * the key here as the IDs may not exist yet (eg: for completely new funds)
 */
const uploadAppendTypePrivateFundIds = atom<Set<string>>({
  key: 'uploadAppendTypePrivateFundIds',
  default: new Set(),
});

/**
 * This is the backing store atom family of private fund ID to upload append type in the private upload dialog, it allows
 * per fund overrides and `undefined` is reserved for the global override.
 */
const uploadAppendTypePrivateFundValues = atomFamily<number | undefined, string | undefined>({
  key: 'uploadAppendTypePrivateFund',
  default: undefined,
});

/**
 * This is the selector family of private fund ID to upload append type in the private upload dialog, it allows
 * per fund overrides and `undefined` is reserved for the global override.
 */
export const uploadAppendTypePrivateFund = selectorFamily<number, string | undefined>({
  key: 'uploadAppendTypePrivateFund',
  get:
    (id) =>
    ({ get }) => {
      return get(uploadAppendTypePrivateFundValues(id)) ?? get(uploadAppendTypePrivateFundValues(undefined)) ?? 2;
    },
  set:
    (id) =>
    ({ get, set, reset }, newValue) => {
      id === undefined &&
        get(uploadAppendTypePrivateFundIds).forEach((id) => reset(uploadAppendTypePrivateFundValues(id)));
      id === undefined && reset(uploadAppendTypePrivateFundIds);

      set(uploadAppendTypePrivateFundValues(id), newValue);
      id && set(uploadAppendTypePrivateFundIds, new Set([...get(uploadAppendTypePrivateFundIds), id]));
    },
});

/**
 * This is the last selected global upload strategy for privates uploading.
 * It can never be set to undefined.  Setting this value will update all the individual funds to the same value.
 */
export const globalUploadAppendTypePrivateFund = selector<number | undefined>({
  key: 'globalUploadAppendTypePrivateFund',
  get: ({ get }) => {
    return get(uploadAppendTypePrivateFund(undefined));
  },
  set: ({ set }, newValue) => set(uploadAppendTypePrivateFund(undefined), newValue ?? 2),
});

/**
 * This is the global upload strategy for privates uploading to be used as a dropdown option so 'Mixed' is a value.
 * Mixed is undefined and computed off of the other funds not all being equal.
 */
export const globalUploadAppendTypeOptionPrivateFund = selector<number | undefined>({
  key: 'globalUploadAppendTypeOptionPrivateFund',
  get: ({ get }) => {
    const values = compact([
      ...Array.from(get(uploadAppendTypePrivateFundIds)).map((id) => get(uploadAppendTypePrivateFundValues(id))),
      get(uploadAppendTypePrivateFund(undefined)),
    ]);
    if (!values.every((val) => val === values[0])) {
      return undefined; // Mixed strategy
    }
    return get(uploadAppendTypePrivateFund(undefined));
  },
  set: ({ set }, newValue) => set(uploadAppendTypePrivateFund(undefined), newValue ?? 2),
});

/**
 * This is the map of the private fund to upload append type, it generates a map used at saving time.  Don't use
 * for regular rendering as it will change whenever any underlying value changes
 */
export const uploadAppendTypePrivateFundMap = selector<{ [key: string]: number }>({
  key: 'uploadAppendTypePrivateFundMap',
  get: ({ get }) => {
    const entries = Array.from(get(uploadAppendTypePrivateFundIds)).map((id) => [
      id,
      get(uploadAppendTypePrivateFund(id)),
    ]);
    return Object.fromEntries(entries);
  },
});
