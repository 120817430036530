import React, { Component } from 'react';
import styled from 'styled-components';
import { GetColor, KeyCodes, ZIndex } from 'venn-ui-kit';

interface StrategyInputProps {
  name: string;
  onChange: (value: string) => void;
}

interface StrategyInputState {
  value: string;
}

class StrategyInput extends Component<StrategyInputProps, StrategyInputState> {
  state: StrategyInputState = {
    value: this.props.name,
  };

  render() {
    const { value } = this.state;
    return (
      <StyledInput
        autoFocus
        placeholder="Untitled Strategy"
        value={value}
        onBlur={this.onBlur}
        onClick={this.onClick}
        onKeyDown={this.onKeyDown}
        onChange={this.onChange}
        onFocus={this.onFocus}
      />
    );
  }

  private onFocus = (event: React.FocusEvent<HTMLInputElement>) => event.target.select();

  private onChange = (event: React.FocusEvent<HTMLInputElement>) => {
    this.setState({
      value: event.target.value,
    });
  };

  private onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { onChange, name } = this.props;
    onChange(event.target.value || name);
  };

  private onClick = (event: React.MouseEvent<HTMLInputElement>) => event.stopPropagation();

  private onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === KeyCodes.Enter) {
      event.currentTarget.blur();
    }
  };
}

const StyledInput = styled.input`
  height: 24px;
  width: 100%;
  font-size: 14px;
  padding: 0 5px;
  border: 1px solid ${GetColor.HighlightDark};
  outline: none;
  background: transparent;
  font-weight: bold;
  &:focus-within {
    border-color: ${GetColor.HighlightDark};
  }
  &::placeholder {
    font-weight: normal;
  }
  z-index: ${ZIndex.Front};
`;

export default StrategyInput;
