import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import BaseModalFooter from '../../../modal/ModalFooter';

const Error = styled.div`
  padding: 10px 0;
  float: left;
  color: #b82a2b;
  font-size: 12px;
  margin-right: auto;
`;

const qaClass = 'qa-modal-buttons';

export interface ModalFooterProps {
  error?: string;
  canSubmit: boolean;
  onClose(): void;
  onSubmit?(): void;
}

const ModalFooter: FC<React.PropsWithChildren<ModalFooterProps>> = ({ error, onClose, canSubmit, onSubmit }) => (
  <BaseModalFooter
    className={qaClass}
    rightChildren={error ? <Error>{error}</Error> : null}
    primaryLabel="Save"
    primaryDisabled={!canSubmit}
    onPrimaryClick={onSubmit}
    onCancel={onClose}
  />
);

export default ModalFooter;
