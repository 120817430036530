import { useContext } from 'react';
import { assertNotNil } from 'venn-utils';
import type { UserContextValue } from '../contexts/user-context';
import UserContext from '../contexts/user-context';

/**
 * Returns the current user information
 */
export function useUser(): UserContextValue {
  const userContext = useContext(UserContext);
  assertNotNil(userContext, 'useUser must be used within a UserProvider');
  return userContext;
}
