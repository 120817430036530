import type { Fund, Portfolio, SimpleFund } from 'venn-api';
import { isNil } from 'lodash';
import type { AnalysisSubject } from 'venn-utils';

/* When either the start date or the end date of an investment are not defined (or null), the investment has no returns */
export function itemHasNoReturns(start: number | undefined | null, end: number | undefined | null): boolean {
  return isNil(start) || isNil(end);
}

export function hasFundNoReturns(fund: Fund | SimpleFund): boolean {
  return itemHasNoReturns(fund.startRange, fund.endRange);
}

export function hasPortfolioFundWithNoReturns(portfolio: Portfolio): boolean {
  return portfolio.children.some(
    (item) => (item.fund && hasFundNoReturns(item.fund)) || hasPortfolioFundWithNoReturns(item),
  );
}

export function hasAnalysisSubjectNoReturns(subject?: AnalysisSubject): boolean {
  return (
    (subject?.fund && hasFundNoReturns(subject.fund)) ||
    (subject?.portfolio && hasPortfolioFundWithNoReturns(subject.portfolio)) ||
    false
  );
}
