import { FilterMenuTrigger } from '../shared';
import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { FactorLensesContext, Input } from 'venn-components';
import { GetColor, Icon, SimpleMenu, SkeletalDropMenu, AccordionMenu } from 'venn-ui-kit';
import type { AdvancedQueryRow } from '../types';
import {
  METRIC_OPTIONS,
  parseNumValue,
  QUERY_OPERATOR_OPTIONS,
  TIME_PERIOD_OPTIONS,
  TIME_PERIOD_TO_DISPLAY_NAME,
  toDisplayValue,
  generateFactorLensAccordionItems,
} from './advancedQueryUtils';
import ValidationError from './ValidationError';
import type { AdvancedQuery } from 'venn-state';

interface AdvancedQueryInputProps {
  onRemoveQuery: () => void;
  onUpdateQuery: (updated: AdvancedQueryRow) => void;
  query: AdvancedQueryRow;
  showErrors: boolean;
}

const AdvancedQueryInput = ({ onRemoveQuery, onUpdateQuery, query, showErrors }: AdvancedQueryInputProps) => {
  const { Colors } = useContext(ThemeContext);
  const { factorLenses } = useContext(FactorLensesContext);
  const items = generateFactorLensAccordionItems(factorLenses);

  const { timePeriod, metric, operator, value } = query;
  const { label: metricValue, isPercentage } = METRIC_OPTIONS.find(({ value }) => value === metric) ?? {};
  const selectedOperator = QUERY_OPERATOR_OPTIONS.find(({ value }) => value === operator);
  const displayValue = toDisplayValue(value, isPercentage);
  const showValueError = showErrors && displayValue === '';

  const onChangeField = (fieldName: keyof AdvancedQuery, updatedValue: string, onClose: () => void) => {
    onUpdateQuery({ ...query, [fieldName]: updatedValue });
    onClose();
  };

  return (
    <AdvancedQueryInputRow showErrors={showErrors}>
      <SkeletalDropMenu
        menuPosition="right"
        triggerComponent={(expanded, toggleMenu) => (
          <>
            <StyledTrigger
              className="qa-adv-query-time-period"
              width={135}
              label="Time Period"
              isOpen={expanded}
              onClick={toggleMenu}
              aria-expanded={expanded}
              aria-haspopup
              value={timePeriod && TIME_PERIOD_TO_DISPLAY_NAME[timePeriod]}
              error={showErrors && !timePeriod}
              errorMessage={<ValidationError />}
            />
          </>
        )}
        menuComponent={(applyStateAndClose, onClose) => (
          <SimpleMenu
            className="qa-adv-query-time-period-menu"
            items={TIME_PERIOD_OPTIONS}
            onChange={({ value }) => onChangeField('timePeriod', value, onClose)}
          />
        )}
      />
      <SkeletalDropMenu
        menuPosition="right"
        triggerComponent={(expanded, toggleMenu) => (
          <StyledTrigger
            className="qa-adv-query-metric"
            width={240}
            label="Metric"
            isOpen={expanded}
            onClick={toggleMenu}
            aria-expanded={expanded}
            aria-haspopup
            value={metricValue}
            error={showErrors && !metricValue}
            errorMessage={<ValidationError />}
          />
        )}
        menuComponent={(applyStateAndClose, onClose) => (
          <AccordionMenu expanded onSelect={(value) => onChangeField('metric', value, onClose)} items={items} />
        )}
      />
      <SkeletalDropMenu
        menuPosition="right"
        triggerComponent={(expanded, toggleMenu) => (
          <StyledTrigger
            className="qa-adv-query-operator"
            width={206} // ensure all options, when selected, will be displayed on a single line
            label="Operator"
            isOpen={expanded}
            onClick={toggleMenu}
            aria-expanded={expanded}
            aria-haspopup
            value={selectedOperator?.label}
            error={showErrors && !selectedOperator}
            errorMessage={<ValidationError />}
          />
        )}
        menuComponent={(applyStateAndClose, onClose) => (
          <SimpleMenu
            className="qa-adv-query-operator-menu"
            selected={operator}
            items={QUERY_OPERATOR_OPTIONS}
            onChange={({ value }) => onChangeField('operator', value, onClose)}
          />
        )}
      />
      <StyledInput
        className="qa-adv-query-value"
        type="number"
        percentage={isPercentage}
        value={displayValue}
        onChange={(updated: string) => {
          onUpdateQuery({ ...query, value: parseNumValue(updated, isPercentage) });
        }}
        error={showValueError}
        errorMessage={showValueError ? <ValidationError message="Enter a valid number" /> : undefined}
        errorColor={Colors.HighlightDark}
      />
      <TrashIcon onClick={onRemoveQuery} />
    </AdvancedQueryInputRow>
  );
};

export default AdvancedQueryInput;

const TrashIcon = styled(Icon).attrs(() => ({
  type: 'trash',
}))`
  color: ${GetColor.Primary.Dark};
  margin-left: 20px;
  font-size: 11px;
`;

const AdvancedQueryInputRow = styled.div<{ showErrors: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  > :not(:last-child) {
    margin-right: 10px;
  }
  margin-bottom: ${({ showErrors }) => (showErrors ? 25 : 10)}px;
`;

const StyledInput = styled(Input).attrs(() => ({
  textAlign: 'right',
}))<{ percentage?: boolean }>`
  padding-right: 10px;
  position: relative;
  width: 70px;

  ${({ percentage }) =>
    percentage
      ? `&:before {
    content: '%';
    right: 8px;
    position: absolute;
    color: ${GetColor.MidGrey2};
    line-height: 15px;
    font-size: 13px;
  }`
      : null}
`;

const StyledTrigger = styled(FilterMenuTrigger)<{ width?: number; error?: boolean }>`
  border-color: ${(props) => (props.error ? GetColor.HighlightDark : GetColor.GreyScale.Grey30)};
  ${({ width }) => width !== undefined && `width: ${width}px;`}
`;
