import { Benchmark as ProtoBenchmark } from 'venn-api-client/public_api/portfolio/v1/private_benchmark_pb';
import type { Benchmark, BenchmarkTypeEnum } from 'venn-api';
import { assertNotNil } from '../../utils/assert';

const extractBenchmarkType = (benchmark: ProtoBenchmark): BenchmarkTypeEnum => {
  switch (benchmark.id.case) {
    case 'portfolioId':
      return 'PORTFOLIO';
    case 'compositeUuid':
      return 'COMPOSITE';
    default:
      return 'INVESTMENT';
  }
};
export const benchmarkFromProto = (benchmark: ProtoBenchmark): Benchmark => {
  return {
    fundId: benchmark.id.case === 'fundUuid' || benchmark.id.case === 'compositeUuid' ? benchmark.id.value : undefined,
    name: benchmark.name,
    portfolioId: benchmark.id.case === 'portfolioId' ? Number(benchmark.id.value) : undefined,
    primary: benchmark.primary,
    type: extractBenchmarkType(benchmark),
  };
};
export const benchmarkToProto = (benchmark: Benchmark): ProtoBenchmark => {
  switch (benchmark.type) {
    case 'PORTFOLIO': {
      return new ProtoBenchmark({
        name: benchmark.name,
        primary: benchmark.primary,
        id: {
          case: 'portfolioId',
          value: assertNotNil(benchmark.portfolioId) as unknown as bigint,
        },
      });
    }
    case 'COMPOSITE': {
      return new ProtoBenchmark({
        name: benchmark.name,
        primary: benchmark.primary,
        id: {
          case: 'compositeUuid',
          value: assertNotNil(benchmark.fundId),
        },
      });
    }
    default: {
      return new ProtoBenchmark({
        name: benchmark.name,
        primary: benchmark.primary,
        id: {
          case: 'fundUuid',
          value: assertNotNil(benchmark.fundId),
        },
      });
    }
  }
};
