import React, { useCallback, useContext, useEffect, useState } from 'react';
import type { Fund, FundEntities, Portfolio } from 'venn-api';
import { getEntitiesForFund } from 'venn-api';
import styled from 'styled-components';
import { EllipsisTooltipSpan, GetColor, Headline2, Headline3, Icon, TablePlaceholder, Hint } from 'venn-ui-kit';
import { Link, useHistory } from 'react-router-dom';
import { buttonize, Routes, useModal } from 'venn-utils';
import type { BasicTableColumn, BasicTableProps, StyledTableType } from 'venn-components';
import { AddToPortfolioModal, BasicTable, formatDate, PortfoliosContext } from 'venn-components';

interface InvestmentPortfoliosProps {
  fundId?: string;
  fund?: Fund;
}

const InvestmentPortfolios = ({ fundId, fund }: InvestmentPortfoliosProps) => {
  const [fundEntities, setFundEntities] = useState<FundEntities>();

  const fetchFundEntities = useCallback(async () => {
    if (fundId) {
      const { content } = await getEntitiesForFund(fundId);
      setFundEntities(content);
    }
  }, [fundId]);

  useEffect(() => {
    fetchFundEntities();
  }, [fetchFundEntities]);

  const history = useHistory();
  const { demoPortfolio, masterPortfolio, refresh } = useContext(PortfoliosContext);
  const [showAddToPortfolioModal, openAddToPortfolioModal, closeAddToPortfolioModal] = useModal();

  if (!fundEntities || !fund) {
    return <TablePlaceholder />;
  }

  const entity = fund.assetType === 'BENCHMARK' ? 'composite benchmark' : 'investment';

  if (!fundEntities.portfolios.length) {
    return (
      <EmptyStateContainer>
        <Headline2>This {entity} is not part of any portfolio yet.</Headline2>
        <Hint>
          <LinkButton {...buttonize(openAddToPortfolioModal)} data-testid="add-to-portfolio">
            Add it to an existing or a new portfolio.
          </LinkButton>
        </Hint>
        {showAddToPortfolioModal && (
          <AddToPortfolioModal
            onCancel={closeAddToPortfolioModal}
            initialSelectedPortfolio={masterPortfolio || demoPortfolio}
            refreshPortfolios={refresh}
            investments={[fund]}
          />
        )}
      </EmptyStateContainer>
    );
  }

  return (
    <Container>
      <Headline3>
        Portfolios containing <FundNameWrapper maxWidth={500}>{fund?.name}</FundNameWrapper>
      </Headline3>
      <StyledTable
        data={fundEntities.portfolios}
        columns={columns}
        selectable
        onRowClick={(e, portfolio: Portfolio) =>
          history.push(`${Routes.DEFAULT_ANALYSIS_PATH}/portfolio/${portfolio.id}`)
        }
      />
    </Container>
  );
};

export default InvestmentPortfolios;

const columns = [
  {
    label: 'Name',
    cellRenderer: (portfolio: Portfolio) => (
      <Link to={`${Routes.DEFAULT_ANALYSIS_PATH}/portfolio/${portfolio.id}`} data-testid="link-to-analysis">
        <PortfolioNameWrapper content={portfolio.name}>
          {portfolio.name}
          <StyledCalculator type="calculator" />
        </PortfolioNameWrapper>
      </Link>
    ),
  },
  {
    label: 'Created By',
    cellRenderer: (portfolio: Portfolio) => <span>{portfolio.owner?.displayName}</span>,
  },
  {
    label: 'Date Updated',
    cellRenderer: (portfolio: Portfolio) => <span>{formatDate(portfolio.updated)}</span>,
  },
  {
    label: 'Updated By',
    cellRenderer: (portfolio: Portfolio) => <span>{portfolio.updatedBy?.displayName}</span>,
  },
];

const Container = styled.div`
  border-radius: 4px;
  border: 1px solid ${GetColor.Grey};
  padding: 40px 60px;
`;

const EmptyStateContainer = styled(Container)`
  text-align: center;
  h2 {
    text-align: center;
  }
`;

const StyledCalculator = styled(Icon)`
  margin-left: 10px;
  visibility: hidden; // only show on table row hover
`;

const PortfolioNameWrapper = styled(EllipsisTooltipSpan)`
  max-width: 500px;
  @media (max-width: 1279px) {
    max-width: 400px;
  }
`;

const StyledTable: StyledTableType<unknown> = styled(
  <T extends BasicTableColumn<K>, K>(props: BasicTableProps<T, K>) => <BasicTable<T, K> {...props} />,
)`
  width: 100%;
  tr:hover {
    cursor: pointer;
    .fa-calculator {
      visibility: visible;
    }
  }
`;

const LinkButton = styled.button`
  color: ${GetColor.Primary.Dark}l;
`;

const FundNameWrapper = styled(EllipsisTooltipSpan)`
  vertical-align: text-top;
`;
