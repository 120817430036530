import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  allocatorAnalysisSubject,
  compareColumnOpen,
  openAllocatorSubject,
  openAllocatorSubjectConfig,
} from 'venn-state';
import { useEffect, useRef, useState } from 'react';
import { AnalysisSubject, selectStrategy } from 'venn-utils';
import type { Portfolio } from 'venn-api';
import { getSpecificPortfolioV3 } from 'venn-api';

/**
 * When we open a saved view, it may have `allocatorConfig` saved with it. That means we need to open Allocator Panel
 * with its settings, which requires fetching the primary and (optionally) secondary portfolio, to fill the
 * AnalysisSubject with. This hook deals with determining if there's anything to fetch, fetching it, and updating the
 * recoil state.
 */
export const useSyncAllocatorConfigToView = () => {
  const [viewAllocatorConfig, setViewAllocatorConfig] = useRecoilState(openAllocatorSubjectConfig);
  const [tempLocalSubject, setTempLocalSubject] = useState<AnalysisSubject | undefined>();
  const [allocatorSubject, setOpenAllocatorSubject] = useRecoilState(openAllocatorSubject);
  const [analysisSubject, setAllocatorAnalysisSubject] = useRecoilState(allocatorAnalysisSubject(allocatorSubject));
  const setCompareColumnOpen = useSetRecoilState(compareColumnOpen);

  const viewAllocatorConfigRef = useRef(viewAllocatorConfig);
  viewAllocatorConfigRef.current = viewAllocatorConfig;
  const allocatorSubjectRef = useRef(allocatorSubject);
  allocatorSubjectRef.current = allocatorSubject;

  useEffect(() => {
    // Fetch portfolios required for initializing Allocator panel if the config is present, but analysisSubject is empty
    // (which means the portfolios haven't been fetched yet).
    if (!viewAllocatorConfig || analysisSubject) {
      return;
    }
    const fetchPortfolioForAllocator = async (id?: number, version?: number): Promise<Portfolio | undefined> => {
      if (!id) {
        return undefined;
      }
      try {
        const portfolio = await getSpecificPortfolioV3(id, version);
        return portfolio.content;
      } catch (e) {
        return undefined;
      }
    };
    const fetchAllocatorData = async (): Promise<void> => {
      const { portfolioId, selectedStrategyId, comparisonConfig } = viewAllocatorConfig;
      const [primaryPortfolio, secondaryPortfolio] = await Promise.all([
        fetchPortfolioForAllocator(portfolioId),
        fetchPortfolioForAllocator(
          comparisonConfig?.comparisonPortfolioId,
          comparisonConfig?.comparisonPortfolioVersion,
        ),
      ]);
      // Repeat check for whether `viewAllocatorConfig` is present, and we still need to set `allocatorSubject`
      // (this might have changed while we were fetching portfolio, thus the use of refs).
      if (!primaryPortfolio || !viewAllocatorConfigRef.current || allocatorSubjectRef.current) {
        return;
      }

      const analysisSubject = new AnalysisSubject(primaryPortfolio, 'portfolio', {
        strategyId: selectedStrategyId,
        secondaryPortfolio,
        secondaryStrategy:
          secondaryPortfolio && comparisonConfig?.comparisonSelectedStrategyId
            ? selectStrategy(comparisonConfig?.comparisonSelectedStrategyId, secondaryPortfolio) ?? undefined
            : undefined,
        secondaryLabel: !comparisonConfig?.comparisonType
          ? undefined
          : comparisonConfig.comparisonType === 'MASTER'
            ? 'Master'
            : 'Last Saved',
      });
      setOpenAllocatorSubject({ portfolioId });
      secondaryPortfolio && setCompareColumnOpen(true);
      setTempLocalSubject(analysisSubject);
    };
    fetchAllocatorData();
  }, [
    viewAllocatorConfig,
    analysisSubject,
    setOpenAllocatorSubject,
    setViewAllocatorConfig,
    setCompareColumnOpen,
    setTempLocalSubject,
  ]);

  useEffect(() => {
    // Sync AnalysisSubject from `tempLocalSubject` to recoil, once `openAllocatorSubject` is updated, and
    // `allocatorAnalysisSubject` setter is tied to `openAllocatorSubject.portfolioId`
    if (!tempLocalSubject) {
      return;
    }
    setAllocatorAnalysisSubject(tempLocalSubject);
    setTempLocalSubject(undefined);
  }, [tempLocalSubject, setAllocatorAnalysisSubject, setOpenAllocatorSubject, setTempLocalSubject]);
};
