import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import type { AppUser, ShareMetadata } from 'venn-api';
import { getAllWorkspaceMembers, shareViewLink } from 'venn-api';
import { Modal, ModalContent, ModalFooter, ModalHeader, ModalSubhead } from '../../modal';
import ConditionalOverlay from '../../conditional-overlay';
import {
  Button,
  ColorUtils,
  EllipsisTooltipSpan,
  ExternalActivityListener,
  GetColor,
  Hint,
  Icon,
  Loading,
  Notifications,
  NotificationType,
  ZIndex,
} from 'venn-ui-kit';
import { logExceptionIntoSentry, plural } from 'venn-utils';
import { MultiSearchBar } from '../../search-bar';
import { InitialAvatar } from '../../avatar';

const AVATAR_SIZE = 30;

interface ShareModalProps {
  shareMetaData: Partial<ShareMetadata>;
  onClose: () => void;
  closeOnOutsideClick?: boolean;
}

const ShareModal = ({ onClose, closeOnOutsideClick, shareMetaData }: ShareModalProps) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');
  const [message, setMessage] = useState('');
  const [members, setMembers] = useState<AppUser[]>([]);
  const [showMenu, setShowMenu] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState<AppUser[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const fetchMember = async () => {
      setLoading(true);
      try {
        const { content } = await getAllWorkspaceMembers();
        setMembers(content.filter((member) => member.active));
      } catch (e) {
        logExceptionIntoSentry(e);
      }
      setLoading(false);
    };
    fetchMember();
  }, []);

  const filteredMember = useMemo(
    () => members.filter((member) => searchContains(member.name, value) || searchContains(member.email, value)),
    [value, members],
  );

  const onToggle = useCallback((member: AppUser) => {
    setSelectedMembers((prev) => {
      const newSelectedMembers = prev.filter((m) => m.email !== member.email);
      if (newSelectedMembers.length < prev.length) {
        return newSelectedMembers;
      }
      return [...newSelectedMembers, member];
    });
    setValue('');
    inputRef.current?.focus();
  }, []);

  const onDelete = useCallback((email: string) => {
    setSelectedMembers((prev) => prev.filter((m) => m.email !== email));
    inputRef.current?.focus();
  }, []);

  const onSend = useCallback(() => {
    setLoading(true);
    try {
      const relativeLink = window.location.pathname + window.location.search;
      const emails = selectedMembers.map((member) => member.email || '');

      shareViewLink({
        ...shareMetaData,
        relativeLink,
        emails,
        message,
      });
      const num = selectedMembers.length;
      Notifications.notify(
        plural(num, { 1: '{{count}} email sent', other: '{{count}} emails sent' }),
        NotificationType.SUCCESS,
      );
      setLoading(false);
      onClose();
    } catch (e) {
      Notifications.notify('Failure to share', NotificationType.ERROR);
      setLoading(false);
    }
  }, [selectedMembers, onClose, shareMetaData, message]);

  const onSelectAll = useCallback(() => {
    setSelectedMembers(members);
    inputRef.current?.focus();
  }, [members]);

  const onRemoveAll = useCallback(() => {
    setSelectedMembers([]);
    inputRef.current?.focus();
  }, []);

  return (
    <Modal
      onClose={onClose}
      closeOnEsc
      className="qa-share-modal"
      initialFocus=".cancel-button"
      closeOnOutsideClick={closeOnOutsideClick}
    >
      <ConditionalOverlay condition={loading} overlay={<Loading title="Loading..." />}>
        <ModalHeader>
          <Icon type="envelope" /> Share via Email
        </ModalHeader>
        <ModalSubhead>Send a copy of this view to anyone in your organization. </ModalSubhead>
        <StyledContent>
          <ExternalActivityListener listeningEnabled={showMenu} onExternalActivity={() => setShowMenu(false)}>
            <SearchWrapper>
              <MultiSearchBar
                placeholder="Search by name or email..."
                value={value}
                onChange={setValue}
                debounce={500}
                onFocus={() => setShowMenu(true)}
                selectedItems={selectedMembers.map((m) => ({
                  label: m.name,
                  value: m.email || '',
                }))}
                onDelete={onDelete}
                disableAutofocus
                outerRef={inputRef}
              />
              <Menu visible={showMenu}>
                {filteredMember.length ? (
                  <Options>
                    {filteredMember.map((member) => {
                      const selected = selectedMembers.find((m) => m.email === member.email);
                      return (
                        <Flex
                          key={member.id}
                          selected={!!selected}
                          onClick={() => onToggle(member)}
                          data-testid="qa-member"
                        >
                          <InitialAvatar
                            className="avatar"
                            avatarId={member.avatarId}
                            displayName={member.avatarId ? undefined : member.email}
                            width={AVATAR_SIZE}
                            inactive={!member.active}
                          />
                          <Info>
                            <Name>
                              <EllipsisTooltipSpan maxWidth={400}>{member.name}</EllipsisTooltipSpan>
                            </Name>
                            <Hint>
                              <EllipsisTooltipSpan maxWidth={400}>{member.email ?? '--'}</EllipsisTooltipSpan>
                            </Hint>
                          </Info>
                        </Flex>
                      );
                    })}
                  </Options>
                ) : (
                  <Empty>{loading ? 'Loading...' : 'No results found'}</Empty>
                )}
                <Bottom>
                  {selectedMembers.length < members.length ? (
                    <Button flat onClick={onSelectAll}>
                      + Add All
                    </Button>
                  ) : (
                    <Button flat onClick={onRemoveAll}>
                      - Remove All
                    </Button>
                  )}
                </Bottom>
              </Menu>
            </SearchWrapper>
          </ExternalActivityListener>
          <TextArea
            placeholder="Add a message (optional)"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            rows={4}
          />
        </StyledContent>
        <StyledFooter
          submitOnEnter
          onCancel={selectedMembers.length === 0 ? undefined : onClose}
          primaryLabel="Send"
          secondaryLabel="Done"
          onSecondaryClick={selectedMembers.length === 0 ? onClose : undefined}
          onPrimaryClick={selectedMembers.length === 0 ? undefined : onSend}
          cancelClassName="cancel-button"
          primaryClassName="qa-send-button"
          secondaryClassName="cancel-button"
        />
      </ConditionalOverlay>
    </Modal>
  );
};

export default ShareModal;

const SHADOW_COLOR = ColorUtils.hex2rgbaFrom(GetColor.Black, 0.2);

const SELECTED_COLOR = ColorUtils.hex2rgbaFrom(GetColor.Primary.Main, 0.1);

const StyledContent = styled(ModalContent)`
  margin-top: 20px;
`;

const StyledFooter = styled(ModalFooter)`
  position: relative;
  bottom: -10px;
`;

const SearchWrapper = styled.div`
  position: relative;
  background: ${GetColor.White};
  display: inline-flex;
  flex-direction: column;
  max-height: 172px;
  width: 100%;
`;

const Menu = styled.div<{ visible: boolean }>`
  width: 100%;
  box-shadow: 0 2px 24px 0 ${SHADOW_COLOR};
  position: absolute;
  top: 100%;
  border: 1px solid ${GetColor.PaleGrey};
  border-top-width: 0;
  background-color: ${GetColor.White};
  z-index: ${ZIndex.Front};
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
`;

const Options = styled.div`
  padding-top: 7px;
  padding-bottom: 7px;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  max-height: 200px;

  &:empty {
    padding: 0;
  }
`;

const Empty = styled.div`
  padding: 10px 10px 8px;
  color: ${GetColor.Grey};
`;

const TextArea = styled.textarea`
  border: 1px solid ${GetColor.Grey};
  width: 100%;
  height: 200px;
  font-size: 14px;
  margin-top: 20px;
  border-radius: 4px;
  padding: 20px;
  font-family: ${(props) => props.theme.Typography.fontFamily};
  font-weight: normal;
  max-height: calc(100vh - 280px);

  &::placeholder {
    color: ${GetColor.HintGrey};
  }

  resize: none;
`;

const Flex = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  background: ${(props) => (props.selected ? SELECTED_COLOR : GetColor.White)};

  &:hover {
    background: ${GetColor.PaleGrey};
  }

  padding: 5px 20px;
`;

const Info = styled.div`
  margin-left: 10px;
`;

const Name = styled.div`
  font-weight: bold;
`;

const Bottom = styled.div`
  border-top: 1px solid ${GetColor.PaleGrey};
  padding: 6px;
`;

const searchContains = (search = '', target = '') => search.toLocaleLowerCase().includes(target.toLocaleLowerCase());
