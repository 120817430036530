import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import type { CellRendererType, HeaderRendererType, TabularDataRow, TabularDataType } from '../types';
import { CellLoader, Tooltip } from 'venn-ui-kit';
import { isNil } from 'lodash';
import { NumericSpan, PercentageSpan } from 'venn-components';
import CorrelationColorLegend from '../components/analyses/CorrelationColorLegend';

export const basicComparisonTableCellRenderer: CellRendererType =
  (
    isLoading: boolean,
    colNum: number,
    colOffset = 0,
    overrideType?: TabularDataType, // This is for the pivoted table
    _relative?: boolean,
    _isHovered?: boolean,
  ) =>
  // TODO(VENN-24534): add a display name to this React component
  // eslint-disable-next-line react/display-name
  (rowData: TabularDataRow) => {
    if (isLoading) {
      return <CellLoader className="qa-cell-loader" />;
    }
    if (isNil(rowData[colOffset + colNum]) || isNil(rowData[colOffset + colNum].value)) {
      return '--';
    }
    const dataType = overrideType ?? rowData.type;
    const value = rowData[colOffset + colNum].value;
    return dataType === 'percentage' ? <PercentageSpan value={value as number} /> : <NumericSpan>{value}</NumericSpan>;
  };

export const pairwiseCorrelationCellRenderer: CellRendererType =
  (
    isLoading: boolean,
    colNum: number,
    colOffset = 0,
    _overrideType?: TabularDataType,
    relative?: boolean,
    isHovered?: boolean,
  ) =>
  // TODO(VENN-24534): add a display name to this React component
  // eslint-disable-next-line react/display-name
  (rowData: TabularDataRow, rowNum: number) => {
    if (relative ? colNum > rowNum + 1 : colNum > rowNum) {
      // For correlation tables we only want to show the lower left half of the matrix.
      return null;
    }
    if (isLoading) {
      return <CellLoader className="qa-cell-loader" />;
    }
    const col = rowData[colOffset + colNum];
    if (!col || !col.value) {
      return '--';
    }
    return <CorrelationCell isHovered={!!isHovered} value={col.value as number} />;
  };

const HEADER_MAX_CHARS = 30;
const HEADER_MAX_CHARS_LINE = 9;

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export const subjectNameHeaderRenderer: HeaderRendererType = (isLoading: boolean) => (label: string) => {
  if (isLoading) {
    return null;
  }
  const parts = label.split(' ');
  let truncatedLabel = '';
  for (let i = 0; i < parts.length; i++) {
    const part = parts[i];
    if (part.length > HEADER_MAX_CHARS_LINE) {
      truncatedLabel = truncatedLabel.concat(part.slice(0, HEADER_MAX_CHARS_LINE - 3)).concat('...');
      break;
    }
    truncatedLabel = truncatedLabel.concat(part);
    if (truncatedLabel.length > HEADER_MAX_CHARS) {
      truncatedLabel = truncatedLabel.slice(0, HEADER_MAX_CHARS).concat('...');
      break;
    }
    truncatedLabel = truncatedLabel.concat(' ');
  }

  return truncatedLabel.length === label.length ? (
    label
  ) : (
    <Tooltip usePortal content={label}>
      {truncatedLabel}
    </Tooltip>
  );
};

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export const factorMetricHeaderRenderer: HeaderRendererType = (isLoading: boolean) => (label: string) =>
  isLoading ? null : label === 'Total' ? (
    <strong>{label}</strong>
  ) : label === 'Residual' || label === 'Risk-Free Rate' ? (
    <em>{label}</em>
  ) : (
    label
  );

export const CorrelationLegendComponent = () => (
  <LegendContainer>
    <CorrelationColorLegend />
  </LegendContainer>
);

const LegendContainer = styled.div`
  width: 300px;
`;

const CorrelationCell = ({ value, isHovered }: { value: number; isHovered: boolean }) => {
  const { Colors } = useContext(ThemeContext);
  if (value === null || value === undefined) {
    return null;
  }

  let color;
  const colorScale = Object.values(Colors.DivergingColor);
  const colorIdx = Math.floor(((value + 1) / 2) * colorScale.length);
  if (colorIdx !== colorScale.length) {
    color = colorScale[colorIdx];
  } else {
    color = colorScale[colorScale.length - 1];
  }

  const textColor = value > 0.5 || value < -0.5 ? Colors.White : Colors.Black;
  return (
    <CorrelationCellWrapper color={color} textColor={textColor} isHovered={isHovered}>
      <NumericSpan className="correlation-value">{value}</NumericSpan>
    </CorrelationCellWrapper>
  );
};

const CorrelationCellWrapper = styled.div<{ color: string; textColor: string; isHovered: boolean }>`
  background-color: ${(props) => props.color};
  color: ${(props) => props.textColor};
  height: 100%;
  padding: 10px;
  opacity: ${(props) => (props.isHovered ? 0.9 : 0.8)};
  &:hover {
    opacity: 1;
  }
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;
