import React, { PureComponent } from 'react';
import { ExternalActivityListener } from '../external-activity-listener';
import type { MenuProps } from './Menu';
import Menu from './Menu';
import styled from 'styled-components';
import { GetColor } from '../../style/color';
import { ZIndex } from '../../zIndexValues';
import { RelativePortal } from '../relative-portal';
import type { RelativePortalProps } from '../relative-portal/RelativePortal';

export interface TriggerableMenuProps<V> extends MenuProps<V> {
  scroll?: number;
  innerClassName?: string;
  // TODO: (VENN-20577 / TYPES) this looks like it never even gets used?
  initialValue?: V;
  disabled?: boolean;
  usePortal?: boolean;
  /** Props passed to the portal component, only effective is usePortal is true */
  portalProps?: Partial<RelativePortalProps>;
  onToggle?: (open: boolean) => void;
}

export interface TriggerableMenuState {
  open: boolean;
}

const StyledTriggerableMenu = styled.span`
  display: inline-flex;
  position: relative;
  align-items: center;
  min-height: 36px;
`;

const StyledMenu = styled(Menu)`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: ${GetColor.White};
  z-index: ${ZIndex.Front};
` as typeof Menu;

export class TriggerableMenu<V> extends PureComponent<
  React.PropsWithChildren<TriggerableMenuProps<V>>,
  TriggerableMenuState
> {
  constructor(props: TriggerableMenuProps<V>) {
    super(props);

    this.state = {
      open: false,
    };
  }

  onToggleOpen = (open: boolean) => {
    if (this.props.onToggle) {
      this.props.onToggle(open);
    }
    this.setState({
      open,
    });
  };

  onToggle = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    if (event.nativeEvent && event.nativeEvent.stopImmediatePropagation) {
      event.nativeEvent.stopImmediatePropagation();
    }
    const { disabled } = this.props;
    if (!disabled) {
      this.onToggleOpen(!this.state.open);
    }
  };

  onKeyUp = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    event.stopPropagation();
  };

  onExternalActivity = () => {
    this.onToggleOpen(false);
  };

  render() {
    const { className, children, style, innerClassName, onSelect, usePortal, portalProps, ...menuProps } = this.props;
    const menuComponent = usePortal ? (
      this.state.open && (
        <RelativePortal {...portalProps}>
          <StyledMenu
            shouldRender={this.state.open}
            className={innerClassName}
            onSelect={onSelect}
            style={style}
            {...menuProps}
          />
        </RelativePortal>
      )
    ) : (
      <StyledMenu
        shouldRender={this.state.open}
        className={innerClassName}
        onSelect={onSelect}
        style={style}
        {...menuProps}
      />
    );
    return (
      <StyledTriggerableMenu className={className} onClick={this.onToggle} onKeyUp={this.onKeyUp}>
        <ExternalActivityListener listeningEnabled={this.state.open} onExternalActivity={this.onExternalActivity}>
          {menuComponent}
          {children}
        </ExternalActivityListener>
      </StyledTriggerableMenu>
    );
  }
}

export default TriggerableMenu;
