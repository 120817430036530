import React from 'react';
import { Numbers } from 'venn-utils';
import type { HeatMapTypes } from '../Types';
import type { Typography } from 'venn-ui-kit';

const getSerieValue = (serie: HeatMapTypes.SeriesEntity, selectedColumn?: number): Value => {
  if (typeof selectedColumn !== 'number') {
    return {};
  }

  if (!serie || !serie.data) {
    return {};
  }

  const factor = serie.data[selectedColumn];

  if (factor) {
    const { nonSignificant, value, residual } = factor;
    return { nonSignificant, value, residual };
  }

  return {};
};

const getValueColor = ({ value }: Value, colors: HeatMapTypes.Colors) => {
  if (value === null || value === undefined) {
    return colors.positive;
  }
  if (value < 0) {
    return colors.negative;
  }
  return colors.positive;
};

const getOpacity = ({ nonSignificant }: Value) => (nonSignificant ? 0.4 : 1);

const getValue = (isPercentage: boolean, factorValue?: Value): string => {
  const formatValue = Numbers.createNumberFormatter({
    forceSign: false,
    percentage: isPercentage,
  });

  if (!factorValue || typeof factorValue.value !== 'number') {
    return '--';
  }

  return formatValue(factorValue.value);
};

interface Props {
  x: number;
  y: number;
  height: number;
  selectedColumn?: number;
  serie: HeatMapTypes.SeriesEntity;
  colors: HeatMapTypes.Colors;
  isPercentage: boolean;
  bold?: boolean;
  typography: Typography;
}

interface Value {
  nonSignificant?: boolean;
  value?: number | null;
  residual?: boolean;
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
const Value = React.memo(({ y, x, height, serie, selectedColumn, colors, isPercentage, bold, typography }: Props) => {
  const value = getSerieValue(serie, selectedColumn);
  return (
    <text
      x={x}
      y={y}
      alignmentBaseline="middle"
      textAnchor="end"
      fontFamily={typography.fontFamily}
      fontSize={height < 20 ? '10px' : '14px'}
      fontWeight={bold && height >= 20 ? 'bold' : ''}
      fill={getValueColor(value, colors)}
      fillOpacity={getOpacity(value)}
      fontStyle={value.residual ? 'italic' : 'normal'}
    >
      {getValue(isPercentage, value)}
    </text>
  );
});

export default Value;
