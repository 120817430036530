import React from 'react';
import styled from 'styled-components';
import { useShouldShowByolWatermark } from './hooks/useShouldShowByolWatermark';
import { WatermarkText } from './components/watermarkText';

/** Page level 'Bring Your Own License' watermark that will automatically display or hide as needed. */
export const PageByolWatermark = () => {
  const shouldShowWatermark = useShouldShowByolWatermark();
  return shouldShowWatermark ? (
    <PageWatermark>
      <WatermarkText />
    </PageWatermark>
  ) : null;
};

/** Helper HOC to add a {@link PageByolWatermark} as a sibling of the provided Component. */
export const withPageByolWatermark =
  <P,>(Component: React.ComponentType<P>) =>
  // TODO(VENN-24534): add a display name to this React component
  // eslint-disable-next-line react/display-name
  (props: P & JSX.IntrinsicAttributes) => {
    return (
      <>
        <Component {...props} />
        <PageByolWatermark />
      </>
    );
  };

const PageWatermark = styled.div`
  display: none;
  @media print {
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 30%;
    top: 5%;
    right: 40px;
  }
`;
