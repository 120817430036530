import { useEffect, useState } from 'react';
import type { Portfolio } from 'venn-api';
import { forecastInvestmentReturns } from 'venn-api';
import { flattenNode, useFetchApi } from 'venn-utils';
import { isEqual } from 'lodash';

export default (
  portfolio: Portfolio | undefined,
  /** Passed to inform the InvestmentForecastProvider that the default forecast has changed */
  defaultForecastId?: string | null,
) => {
  const [investmentIds, setInvestmentIds] = useState(getInvestmentIds(portfolio));

  // Only update the investment Ids if they have changed
  useEffect(() => {
    const newInvestmentIds = getInvestmentIds(portfolio);
    setInvestmentIds((oldInvestmentIds) => {
      if (!isEqual(oldInvestmentIds, newInvestmentIds)) {
        return newInvestmentIds;
      }
      return oldInvestmentIds;
    });
  }, [portfolio]);

  const { result, loading, refresh } = useFetchApi(forecastInvestmentReturns, investmentIds);

  useEffect(() => {
    refresh();
    // Re-run investment forecasts when default forecast id changes (but we don't care when refresh changes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultForecastId]);

  return { investmentForecasts: result, investmentForecastsLoading: loading, refreshInvestments: refresh };
};

const getInvestmentIds = (portfolio: Portfolio | undefined) =>
  flattenNode(portfolio)
    .filter((node: Portfolio) => !!node.fund)
    .map((node: Portfolio) => node.fund!.id);
