import React from 'react';
import {
  BaselineAlignedFlexWrapper,
  FlexRow,
  FlexWrapper,
  Info,
  Name,
  SmallName,
  StyledButton,
  StyledIcon,
} from './shared';
import { EditAvatar } from 'venn-components';
import styled from 'styled-components';
import useSponsorCustomization from './logic/useSponsorCustomization';
import { useModal } from 'venn-utils';
import SponsorCustomizationModal from './SponsorCustomizationModal';
import type { UserProfileSettings } from 'venn-api';
import { getAppTitle, isColor } from 'venn-ui-kit';

interface SponsorCustomizationProps {
  profileSettings: UserProfileSettings;
}

const SponsorCustomization = ({ profileSettings }: SponsorCustomizationProps) => {
  const [isOpen, open, close] = useModal();
  const { sponsor, refresh, update, loading } = useSponsorCustomization(profileSettings.organization.accountId, close);

  const colorScheme = sponsor?.colorScheme;
  return (
    <>
      <FlexRow>
        <div>
          <FlexWrapper>
            <SponsorIcon type="edit" />
            <Name>Sponsor Customization</Name>
          </FlexWrapper>
          <Info>{`Customize the theme of ${getAppTitle()} for accounts sponsored by you.`}</Info>
        </div>
        <div>
          <AvatarWrapper>
            <EditAvatar edit isSponsor avatarId={sponsor?.avatarId} refresh={refresh} />
          </AvatarWrapper>
          <LogoInfo>Your Logo</LogoInfo>
        </div>
      </FlexRow>
      <BaselineAlignedFlexWrapper>
        <SmallName>Display Name:</SmallName>
        <Info>{sponsor?.displayName || '--'}</Info>
      </BaselineAlignedFlexWrapper>
      <BaselineAlignedFlexWrapper>
        <SmallName>Contact Email:</SmallName>
        <Info>{sponsor?.contactEmail || '--'}</Info>
      </BaselineAlignedFlexWrapper>
      <BaselineAlignedFlexWrapper>
        <SmallName>Primary Color:</SmallName>
        <Info>
          {colorScheme || '--'}
          {isColor(colorScheme) && <ColorSwatch color={colorScheme} />}
        </Info>
      </BaselineAlignedFlexWrapper>
      <EditButton dominant onClick={open}>
        Edit
      </EditButton>
      {isOpen && (
        <SponsorCustomizationModal
          onSave={update}
          loading={loading}
          currentDisplayName={sponsor?.displayName}
          currentContactEmail={sponsor?.contactEmail}
          currentColorScheme={colorScheme}
          onClose={close}
        />
      )}
    </>
  );
};

export default SponsorCustomization;

const ColorSwatch = styled.div<{ color: string }>`
  display: inline-block;
  vertical-align: bottom;
  margin-left: 0.5em;
  width: 1em;
  height: 1em;
  border-radius: 2px;
  background: ${(props) => props.color};
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
`;

const AvatarWrapper = styled.div`
  display: flex;
`;

const SponsorIcon = styled(StyledIcon)`
  margin-bottom: 10px;
`;

const EditButton = styled(StyledButton)`
  margin-top: 10px;
`;

const LogoInfo = styled(Info)`
  text-align: center;
`;
