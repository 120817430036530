import { isNil } from 'lodash';
import { type TabularDataTypeEnum } from 'venn-utils';

const toNumberStyle = (dataType?: TabularDataTypeEnum) => {
  if (dataType === 'PERCENTAGE') {
    return { style: 'percent' };
  }
  if (dataType === 'NUMERIC') {
    return { style: 'decimal' };
  }
  if (dataType === 'CURRENCY') {
    return { style: 'currency', currency: 'USD' };
  }
  return undefined;
};

export const formatData = (num?: number, dataType?: TabularDataTypeEnum, precision = 2, maxChars = 8) => {
  if (isNil(num)) {
    return '--';
  }
  const longString = num.toLocaleString('en-us', {
    ...toNumberStyle(dataType),
    maximumFractionDigits: precision,
    minimumFractionDigits: precision,
  });
  return longString.length > maxChars
    ? num.toLocaleString('en-us', {
        ...toNumberStyle(dataType),
        notation: 'compact',
        compactDisplay: 'short',
        maximumFractionDigits: precision,
        minimumFractionDigits: precision,
      })
    : longString;
};
