import type * as Highcharts from 'highcharts';
import { isNil, last } from 'lodash';
import type { Typography } from 'venn-ui-kit';
import { type CustomBlockTypeEnum } from 'venn-utils';
import { calculateLegendPadding } from '../../../../../charts/analysis-charts/chart-config-logic';

interface GetOptionsProps {
  customBlockType: CustomBlockTypeEnum | undefined;
  series: Highcharts.SeriesColumnOptions[];
  print: boolean;
  yAxisLabel: string;
  yAxisTitle?: string;
  xAxisCategories?: string[];
  height?: number | string | null;
  percent?: boolean;
  typography: Typography;
  legendFontSize: number;
  axisFontSize: number;
}

/** Returns highly dynamic options as opposed to getDefaultChartConfig which returns generally static options.  */
export const getOptions = ({
  customBlockType,
  series,
  yAxisLabel,
  yAxisTitle,
  xAxisCategories = [],
  height,
  percent,
  typography,
  legendFontSize,
  axisFontSize,
}: GetOptionsProps): Highcharts.Options => {
  const tooltip = percent
    ? {
        tooltip: {
          formatter(this: Highcharts.TooltipFormatterContextObject) {
            const { color, series, x, y } = this;
            return `<div>${x}</div>
              <span style="color:${color}">\u25CF</span> ${series.name}: <b>${y}%</b>`;
          },
        },
      }
    : {};

  const yTitle = !isNil(yAxisTitle)
    ? {
        title: {
          text: yAxisTitle,
          style: {
            fontSize: `${axisFontSize}pt`,
          },
        },
      }
    : {};

  return {
    chart: {
      height,
      spacingBottom: 0,
    },
    xAxis: {
      categories: xAxisCategories,
      labels: {
        reserveSpace: true,
        useHTML: customBlockType !== 'SCENARIO',
        formatter(this: Highcharts.AxisLabelsFormatterContextObject) {
          // this is needed because we are using a bar chart instead to display a histogram throughout the app.
          // bar charts might show non-existent (n+1) categories when the x-axis resamples.
          const value = this.pos >= xAxisCategories?.length ? last(xAxisCategories) : this.value;
          return (value?.toString() ?? '').replace('\n', '<br/>');
        },
        style: {
          fontSize: `${axisFontSize}pt`,
          fontFamily: typography.fontFamily,
          textAlign: customBlockType === 'SCENARIO' ? 'left' : 'center',
          textOverflow: customBlockType === 'SCENARIO' ? 'none' : undefined,
        },
      },
    },
    yAxis: {
      labels: {
        align: 'left',
        reserveSpace: true,
        style: {
          fontSize: `${axisFontSize}pt`,
        },
        formatter(this: Highcharts.AxisLabelsFormatterContextObject) {
          if (this.isFirst) {
            return `${this.value}${percent ? '%' : ''} ${yAxisLabel}`;
          }
          return `${this.value}${percent ? '%' : ''}`;
        },
      },
      ...yTitle,
    },
    series,
    plotOptions: {
      series: {
        animation: false,
      },
      column: {
        dataLabels: {},
        events: {
          legendItemClick() {
            return false;
          },
        },
        pointPadding: 0,
        groupPadding: series.length > 1 ? 0.1 : 0,
        maxPointWidth: 200,
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
    },
    legend: {
      enabled: true,
      useHTML: true,
      padding: calculateLegendPadding(legendFontSize),
      margin: 0,
      itemStyle: {
        fontSize: `${legendFontSize}pt`,
        itemMarginTop: 1,
        itemMarginBottom: 1,
      },
    },
    ...tooltip,
  };
};
