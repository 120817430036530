import type { CashFlowSetting } from 'venn-api';
import { ClassificationCellRenderer, vintageFormatter } from './logic/utils';
import React from 'react';
import styled, { css } from 'styled-components';
import { GetColor, Icon, PRIVATE_ASSET_CASH_FLOW_HELP_HREF, Tooltip, TooltipPosition } from 'venn-ui-kit';
import { formatData } from '../../data-grid';

type CashflowSettingInfoProps = {
  setting: CashFlowSetting | undefined;
  hideHeader?: boolean;
  qtrSuffix?: string;
};

type CashflowSettingParameterProps = {
  name: string;
  value?: string;
  placeholder: string;
};
const CashflowSettingClassificationParameter = ({ name, value, placeholder }: CashflowSettingParameterProps) => {
  return (
    <CashflowSettingWrapper data-testid={`qa-cf-setting-${name}`}>
      <b>{name}</b>
      <ClassificationCellRenderer placeholder={placeholder} value={value} />
    </CashflowSettingWrapper>
  );
};

export const CashflowActiveSettingInfo = ({ setting, hideHeader }: CashflowSettingInfoProps) => {
  return (
    <CashflowSettingContainer>
      {!hideHeader && (
        <CashflowSettingHeader>
          <b>Active Setting</b>
          <CashflowSettingHeaderName>{setting?.name ?? ''}</CashflowSettingHeaderName>
        </CashflowSettingHeader>
      )}
      <CashflowSettingMainContainer>
        <CashflowSettingColumn>
          <CashflowSettingClassificationInnerComponent setting={setting} />
        </CashflowSettingColumn>
        <CashflowSettingColumn>
          <CashflowSettingParametersInnerComponent setting={setting} />
        </CashflowSettingColumn>
      </CashflowSettingMainContainer>
    </CashflowSettingContainer>
  );
};

export const CashflowSettingInfoTable = ({ setting }: CashflowSettingInfoProps) => {
  return (
    <CashflowSettingTableContainer>
      <CashflowSettingTableColumn isClassification>
        <CashflowSettingTableHeaderRow isClassification>Classification</CashflowSettingTableHeaderRow>
        <CashflowSettingTableDataColumn>
          <CashflowSettingClassificationInnerComponent setting={setting} />
        </CashflowSettingTableDataColumn>
      </CashflowSettingTableColumn>
      <CashflowSettingTableColumn isClassification={false}>
        <CashflowSettingTableHeaderRow isClassification={false}>
          Parameters
          <Tooltip
            usePortal
            position={TooltipPosition.Top}
            content="Parameter values are denoted on a quarterly basis."
          >
            <a target="_blank" rel="noopener noreferrer" href={PRIVATE_ASSET_CASH_FLOW_HELP_HREF}>
              <InfoIcon type="info-circle" prefix="fas" />
            </a>
          </Tooltip>
        </CashflowSettingTableHeaderRow>
        <CashflowSettingTableDataColumn>
          <CashflowSettingParametersInnerComponent qtrSuffix=" qtr." setting={setting} />
        </CashflowSettingTableDataColumn>
      </CashflowSettingTableColumn>
    </CashflowSettingTableContainer>
  );
};

const InfoIcon = styled(Icon)`
  color: ${GetColor.White};
  padding-left: 2px;
`;

const CashflowSettingClassificationInnerComponent = ({ setting }: CashflowSettingInfoProps) => {
  const hideValues = setting?.overrideType === 'PARAMETER';
  return (
    <>
      <CashflowSettingClassificationParameter
        name="Asset Class"
        placeholder={setting ? 'All Asset Classes' : '--'}
        value={hideValues ? '--' : setting?.assetClassName}
      />
      <CashflowSettingClassificationParameter
        name="Strategy"
        placeholder={setting ? 'All Strategies' : '--'}
        value={hideValues ? '--' : setting?.strategyName}
      />
      <CashflowSettingClassificationParameter
        name="Vintage"
        placeholder={setting ? 'All Vintages' : '--'}
        value={hideValues ? '--' : vintageFormatter(setting?.vintage)}
      />
    </>
  );
};

export const CashflowSettingParametersInnerComponent = ({ setting, qtrSuffix = '' }: CashflowSettingInfoProps) => {
  return (
    <>
      <CashflowSettingWrapper>
        <b>Rates of Contribution</b>
        <ContributionRatesContainer>
          <CashflowSettingClassificationParameter
            name={`0-4${qtrSuffix}`}
            placeholder="--"
            value={formatData(setting?.rateOfContribution0, 'PERCENTAGE', 1)}
          />
          <CashflowSettingClassificationParameter
            name={`4-8${qtrSuffix}`}
            placeholder="--"
            value={formatData(setting?.rateOfContribution4, 'PERCENTAGE', 1)}
          />
          <CashflowSettingClassificationParameter
            name=">8 qtr."
            placeholder="--"
            value={formatData(setting?.rateOfContribution8, 'PERCENTAGE', 1)}
          />
        </ContributionRatesContainer>
      </CashflowSettingWrapper>
      <CashflowSettingClassificationParameter
        name="Life Expectancy (qtr.)"
        placeholder="--"
        value={formatData(setting?.lifeExpectancy, 'NUMERIC', 0)}
      />
      <CashflowSettingClassificationParameter
        name="Bow"
        placeholder="--"
        value={formatData(setting?.bow, 'NUMERIC', 2)}
      />
      <CashflowSettingClassificationParameter
        name="Yield"
        placeholder="--"
        value={formatData(setting?.yield, 'PERCENTAGE', 2)}
      />
      <CashflowSettingClassificationParameter
        name="Growth"
        placeholder="--"
        value={formatData(setting?.growth, 'PERCENTAGE', 2)}
      />
    </>
  );
};

const CashflowSettingTableContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  margin: 8px 0 0 21px;
  border: 1px solid ${GetColor.GreyScale.Grey30};
`;

const CashflowSettingTableColumn = styled.div<{ isClassification: boolean }>`
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  background: ${GetColor.GreyScale.Grey10};
  ${(props) =>
    props.isClassification &&
    css`
      border-right: 1px solid ${GetColor.GreyScale.Grey30};
    `}
`;

const CashflowSettingTableHeaderRow = styled.span<{ isClassification: boolean }>`
  padding: 4px 0 4px 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
  color: ${GetColor.White};
  ${(props) => css`
    background: ${props.isClassification
      ? GetColor.DEPRECATED_DivergingColor.B4
      : GetColor.DEPRECATED_DivergingColor.B5};
  `}
`;

const CashflowSettingTableDataColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px 8px;
  gap: 8px;
`;

const CashflowSettingContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  width: 264px;
  border: 1px solid ${GetColor.GreyScale.Grey30};
`;

const CashflowSettingHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 16px;
  border-bottom: 1px solid ${GetColor.GreyScale.Grey30};
`;

const CashflowSettingMainContainer = styled.div`
  display: flex;
  padding: 12px 4px 12px 16px;
  align-items: flex-start;
  gap: 20px;
`;

const CashflowSettingColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const CashflowSettingWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const CashflowSettingHeaderName = styled.span`
  font-size: 14px;
`;

const ContributionRatesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 6px;
`;
