import type { CashFlowResponse, PrivateFund, PrivatePortfolioNode, PrivatesAnalysisResponse } from 'venn-api';

export const mockPrivatePortfolioNodeWithoutChildren: PrivatePortfolioNode = {
  id: 'a9ef259a-9486-4000-8b01-b1091c7662ce',
  rootNodeId: 'a9ef259a-9486-4000-8b01-b1091c7662ce',
  parentNodeId: null!,
  name: 'New Private Portfolio',
  fundId: null!,
  capitalCommitment: null!,
  children: [],
  active: true,
  created: 0,
  dataSource: '',
  ownerId: 0,
  permissionGroupId: 0,
  updated: 0,
  updatedById: 0,
  ownerContextId: null!,
};

export const mockPrivatePortfolioNodeWithChildren: PrivatePortfolioNode = {
  id: 'a9ef259a-9486-4000-8b01-b1091c7662ce',
  rootNodeId: 'a9ef259a-9486-4000-8b01-b1091c7662ce',
  parentNodeId: null!,
  name: 'New Private Portfolio',
  fundId: null!,
  capitalCommitment: null!,
  children: [
    {
      id: '56ebf34f-fbfc-489a-8b49-8f6bc2e87afc',
      rootNodeId: '750b3615-7a39-4ca7-aa06-ff2af4012c01',
      parentNodeId: '750b3615-7a39-4ca7-aa06-ff2af4012c01',
      name: 'Sentinel Capital Partners III',
      fundId: '67283f3c-0d4f-465c-8c35-7a2fba82044c',
      capitalCommitment: 1.5e9,
      children: [],
      dataSource: null!,
      created: 1698246582563,
      updated: 1698249709646,
      ownerId: 3151461,
      updatedById: 3151461,
      permissionGroupId: 2,
      ownerContextId: null!,
      active: true,
    },
  ],
  active: true,
  created: 0,
  dataSource: '',
  ownerId: 0,
  permissionGroupId: 0,
  updated: 0,
  updatedById: 0,
  ownerContextId: null!,
};

export const mockPrivatePortfolioNodeWithChildrenAndOwnerContext: PrivatePortfolioNode = {
  id: 'a9ef259a-9486-4000-8b01-b1091c7662ce',
  rootNodeId: 'a9ef259a-9486-4000-8b01-b1091c7662ce',
  parentNodeId: null!,
  name: 'New Private Portfolio',
  fundId: null!,
  capitalCommitment: null!,
  ownerContextId: 'd9ef259a-9486-4000-8b01-b1091c7662ce',
  children: [
    {
      id: '56ebf34f-fbfc-489a-8b49-8f6bc2e87afc',
      rootNodeId: '750b3615-7a39-4ca7-aa06-ff2af4012c01',
      parentNodeId: '750b3615-7a39-4ca7-aa06-ff2af4012c01',
      name: 'Sentinel Capital Partners III',
      fundId: '67283f3c-0d4f-465c-8c35-7a2fba82044c',
      capitalCommitment: 1.5e9,
      children: [],
      dataSource: null!,
      created: 1698246582563,
      updated: 1698249709646,
      ownerId: 3151461,
      updatedById: 3151461,
      permissionGroupId: 2,
      ownerContextId: null!,
      active: true,
    },
  ],
  active: true,
  created: 0,
  dataSource: '',
  ownerId: 0,
  permissionGroupId: 0,
  updated: 0,
  updatedById: 0,
};

export const mockPrivateFund: PrivateFund = {
  id: '67283f3c-0d4f-465c-8c35-7a2fba82044c',
  externalId: 'Sentinel Capital Partners III',
  dataSource: null!,
  name: 'Sentinel Capital Partners III',
  fundType: null!,
  vintage: 1104537599999,
  size: 3.19e8,
  finalCloseDate: null!,
  finalCloseSize: null!,
  currency: 'USD',
  status: 'LIQUIDATED',
  assetClass: 'PRIVATE_EQUITY',
  strategy: 'BUYOUT',
  subStrategy: null!,
  manager: 'Sentinel Capital Partners',
  geographicFocus: 'NORTH_AMERICA',
  geographicFocusName: 'North America',
  coreIndustries: ['INFORMATION_TECHNOLOGY'],
  industries: ['INFORMATION_TECHNOLOGY'],
  capitalCommitment: null!,
  created: 1692352342689,
  updated: 1695305100946,
  ownerId: 3000030,
  updatedById: 3123530,
  permissionGroupId: 2,
  userUploaded: true,
  active: true,
};

export const mockPrivateResponseWithPerformance: PrivatesAnalysisResponse = {
  cashFlows: [],
  performanceTimeSeries: [
    {
      name: '',
      subject: null!,
      vintage: null!,
      capitalCommitment: null!,
      error: '',
      errorMessages: [],
      projectionAsOfDate: 1706659199999,
      calledPercentage: [[1625097599999, 1]],
      dpiPercentage: [],
      rvpiPercentage: [],
      netMultiple: [],
      nav: [],
      netIRRPercentage: [],
      pmeDirectAlpha: [],
      pmeKaplanSchoar: [[1625097599999, 1.044712647907304]],
      pmeLongNickels: [],
      pmeCapitalDynamics: [],
      errors: {},
    },
    {
      name: '',
      subject: null!,
      vintage: null!,
      capitalCommitment: null!,
      error: '',
      errorMessages: [],
      projectionAsOfDate: 1706659199999,
      calledPercentage: [[1625097599999, 0.2276]],
      dpiPercentage: [],
      rvpiPercentage: [],
      netMultiple: [],
      nav: [],
      netIRRPercentage: [],
      pmeDirectAlpha: [],
      pmeKaplanSchoar: [[1625097599999, 0.761708532058274]],
      pmeCapitalDynamics: [],
      pmeLongNickels: [],
      errors: {},
    },
  ],
  performanceSummary: [],
};

export const mockPrivateCashflowResponseWarningsOnly: PrivatesAnalysisResponse = {
  cashFlows: [
    {
      errors: {
        navForwardFill: [
          {
            severity: 'WARNING',
            message: 'MISSING_DATA',
            fundInfo: {
              fundId: '123-123-123',
              fundName: 'Fund A',
            },
          },
          {
            severity: 'WARNING',
            message: 'MISSING_DATA',
            fundInfo: {
              fundId: '123-123-124',
              fundName: 'Fund B',
            },
          },
        ],
        someOtherERR: [
          {
            severity: 'WARNING',
            message: 'OUTDATED_PIC',
            fundInfo: {
              fundId: '123-123-123',
              fundName: 'Fund A',
            },
          },
          {
            severity: 'WARNING',
            message: 'OUTDATED_CUMULATIVE',
            fundInfo: {
              fundId: '123-123-124',
              fundName: 'Fund B',
            },
          },
        ],
      },
    } as unknown as CashFlowResponse,
    {
      errors: {
        navForwardFill: [
          {
            severity: 'WARNING',
            message: 'MISSING_DATA',
            fundInfo: {
              fundId: '123-123-123',
              fundName: 'Fund A',
            },
          },
          {
            severity: 'WARNING',
            message: 'MISSING_DATA',
            fundInfo: {
              fundId: '123-123-124',
              fundName: 'Fund B',
            },
          },
        ],
        someOtherERR: [],
      },
    } as unknown as CashFlowResponse,
  ],
  performanceTimeSeries: [],
  performanceSummary: [],
};

export const mockPrivatePerformanceSummaryResponse: PrivatesAnalysisResponse = {
  cashFlows: [],
  performanceTimeSeries: [],
  performanceSummary: [
    {
      error: '',
      errorMessages: [],
      errors: {},
      subject: {
        type: 'private-fund-id',
        id: 'd664c295-d08d-496f-bf45-bd1d32c774d0',
      },
      name: 'Thoma Bravo Fund XII',
      asOfDate: 1696118399999,
      capitalCommitment: 1.0e7,
      vintage: 1467331199999,
      calledPercentage: 1.0903,
      dpiPercentage: 1.0338,
      rvpiPercentage: 1.0252,
      netMultiple: 2.06,
      nav: 1.117803727e7,
      netIRRPercentage: 0.16172516653956845,
      pmeDirectAlpha: 0.0350351796946071,
      pmeKaplanSchoar: 1.187201420193222,
      pmeLongNickels: 0.12309003526395319,
      pmeCapitalDynamics: 0.11923164464253569,
      children: [],
    },
  ],
};
