import type { FC, ChangeEventHandler } from 'react';
import React from 'react';
import styled from 'styled-components';
import { GetColor } from '../../style/color';
import { Body1 } from '../typography/Typography';

const RadioCircle = styled.span`
  display: inline-block;
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  border: 1px solid ${GetColor.MidGrey2};
  border-radius: 50%;
  background: ${GetColor.White};
  vertical-align: text-top;
  transition: all 0.25s ease-in-out;
`;

const RadioContainer = styled.label`
  margin-right: 10px;
  box-sizing: content-box;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  min-height: 30px;
`;

const RadioMainContainer = styled.span`
  display: inline-flex;
  align-items: center;
`;

const RadioLabel = styled.span<{ hasDescription: boolean }>`
  padding-left: 6px;
  font-size: 14px;
  color: ${GetColor.Black};
  font-weight: ${({ hasDescription }) => (hasDescription ? 'bold' : 'normal')};
`;

const RadioInput = styled.input`
  display: none;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    + ${RadioCircle} {
      cursor: not-allowed;
      border: 1px solid ${GetColor.HintGrey};
    }
    ~ ${RadioLabel} {
      cursor: not-allowed;
      color: ${GetColor.HintGrey};
    }
  }

  &:not(:disabled) {
    + ${RadioCircle} {
      cursor: pointer;
    }

    ~ ${RadioLabel} {
      cursor: pointer;
    }

    &:checked + ${RadioCircle} {
      border: 5px solid ${GetColor.Primary.Dark};
    }

    &:not(:checked) {
      /* stylelint-disable-next-line */
      &:focus + ${RadioCircle}, &:hover + ${RadioCircle} {
        border: 1px solid ${GetColor.Primary.Main};
      }
    }
  }
`;

export interface RadioProps {
  /** Radio button id */
  inputId: string;

  /** Allows you to provide a custom className prop */
  className?: string;

  /** onChange event handler */
  onChange?: ChangeEventHandler<HTMLInputElement>;

  /** Radio value */
  value?: string | string[] | number;

  /** Distinguish is radio checked or not */
  checked?: boolean;

  /** Distinguish is radio disabled or not */
  disabled?: boolean;

  /** Used for grouping of radio buttons */
  name?: string;
  /** initial value for checked */
  defaultChecked?: boolean;
  /** Text to show below the radio input to describe the option */
  description?: string;
}

export const Radio: FC<React.PropsWithChildren<RadioProps>> = ({
  children,
  className,
  disabled,
  inputId,
  onChange,
  value,
  checked,
  name,
  defaultChecked,
  description,
}) => (
  <RadioContainer className={className} htmlFor={inputId}>
    <RadioMainContainer>
      <RadioInput
        type="radio"
        id={inputId}
        data-testid={inputId}
        disabled={disabled}
        onChange={onChange}
        value={value}
        checked={checked}
        name={name}
        defaultChecked={defaultChecked}
      />
      <RadioCircle />
      <RadioLabel data-testid={`qa-radio-label-for-${inputId}`} hasDescription={!!description}>
        {children}
      </RadioLabel>
    </RadioMainContainer>
    {description && <Body1 style={{ fontWeight: 'normal' }}>{description}</Body1>}
  </RadioContainer>
);

export default Radio;
