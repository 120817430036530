import { atomFamily, DefaultValue, selector, selectorFamily, waitForAll } from 'recoil';
import { compact, uniqWith, isEqual } from 'lodash';
import { getAnalyzedSubjectFromRequestSubject, getRequestSubjectFromAnalysisSubject } from 'venn-utils';
import type { Nominal } from 'venn-utils';
import type { Subject, StudioRequestSubject, SubjectWithOptionalFee, SubjectWithFee } from '../types';
import { subjectInputGroups, subjectInputGroupSubjects } from './input-management/subjectInput';
import { benchmarkInputs, benchmarkInputSubject } from './input-management/benchmarkInput';
import { originalAnalysisSubjectQuery, requestSubjects } from './subjects';
import { resetOnStudioReset } from '../../effects/signalEffects';
import { convertToSubjectWithFee } from '../utils';

export type ColorIndex = Nominal<number, 'colorIndex'>;

/**
 * Defines the color to be used when displaying the given subject in a chart
 * If unset, atom will suspend in pending state
 */
export const subjectColorIndexState = atomFamily<ColorIndex, SubjectWithFee>({
  key: 'subjectColorIndex',
  effects: [resetOnStudioReset],
});

/**
 * Maps a list of subjects to their colors.
 */
export const colorIndicesForSubjectsState = selectorFamily<ColorIndex[], SubjectWithOptionalFee[]>({
  key: 'colorsForSubjects',
  get:
    (subjects) =>
    ({ get }) => {
      return get(waitForAll(subjects.map((subject) => subjectColorIndexState(convertToSubjectWithFee(subject)))));
    },
});

/**
 * Gets all subjects and commons benchmarks in a view.
 */
export const allSubjectsSelector = selector<StudioRequestSubject[]>({
  key: 'allSubjectsSelector',
  get: ({ get }) => {
    const allSubjectGroups = get(subjectInputGroups);
    const allSubjects = get(waitForAll(allSubjectGroups.map(subjectInputGroupSubjects))).flat();
    const allRequestSubjects = get(requestSubjects(allSubjects));
    const allBenchmarkSubjects = compact(get(waitForAll(get(benchmarkInputs).map(benchmarkInputSubject))));
    const allBenchmarkRequestSubjects = compact(
      get(waitForAll(allBenchmarkSubjects.map(originalAnalysisSubjectQuery))),
    ).map((subject) => getRequestSubjectFromAnalysisSubject(subject));
    return [...allRequestSubjects, ...allBenchmarkRequestSubjects];
  },
});

/**
 * Maps colors to their respective subjects in a view
 * Used for persisting color selections
 */
export type ColorIndexSubjectTuple = [ColorIndex, Subject];
export const colorIndexSubjectTupleState = selector<ColorIndexSubjectTuple[]>({
  key: 'colorToSubjectMap',
  get: ({ get }) =>
    uniqWith(
      get(allSubjectsSelector)
        .map(getAnalyzedSubjectFromRequestSubject)
        .map((subject) => [get(subjectColorIndexState(convertToSubjectWithFee(subject))), subject]),
      isEqual,
    ),
  set: ({ set }, value) => {
    value instanceof DefaultValue ||
      uniqWith(value, isEqual).forEach(
        ([colorIndex, subject]) =>
          colorIndex !== undefined &&
          subject &&
          set(subjectColorIndexState(convertToSubjectWithFee(subject)), colorIndex),
      );
  },
});
