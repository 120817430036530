import type { StudioRequestSubject, Subject } from '../types';
import { getSubjectFromRequestSubject, isRequestSubject, type Nominal } from 'venn-utils';

const subjectKeyPrefix = 'sk#' as const;
type SubjectKeyUnbranded = `${typeof subjectKeyPrefix}${string}`;

export type SubjectKey = Nominal<SubjectKeyUnbranded, 'SubjectKey'>;
export const isSubjectKey = (key: string): key is SubjectKey => key.startsWith(subjectKeyPrefix);

/** @returns a branded and prefixed string that allows for deep equality checking given a {@link Subject}. */
export const subjectToKeyString = (subject: Subject | StudioRequestSubject): SubjectKey => {
  subject = isRequestSubject(subject) ? getSubjectFromRequestSubject(subject) : subject;
  return `${subjectKeyPrefix}${[
    subject.fundId,
    subject.portfolioId,
    subject.portfolioVersion,
    subject.privateFundId,
    subject.privatePortfolioId,
  ].join('')}` satisfies SubjectKeyUnbranded as SubjectKey;
};
