import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { analyticsService, Dates } from 'venn-utils';
import type { DropMenuItem, MenuCategory } from 'venn-ui-kit';
import {
  CategorizedDropMenu,
  GetColor,
  Icon,
  Loading,
  Tooltip,
  SimpleMenuItem,
  Expander,
  DateFilter,
} from 'venn-ui-kit';
import type { Portfolio } from 'venn-api';
import { getPortfolioVersions } from 'venn-api';
import { isNil } from 'lodash';
import useVersions from '../../version-item/useVersions';
import moment from 'moment';
import VersionItem from '../../version-item/VersionItem';
import {
  toPortfolioVersionMenuItem,
  toPortfolioMenuItems,
  groupByMonths,
  LoadingContainer,
  FooterContainer,
} from '../../version-item/versionItemUtils';
import { ComparisonContext } from '../../contexts';

const QA_DROPDOWN_CLASSNAME = 'dropdown-container';

interface PortfolioCompareSubjectSelectorProps {
  lastSavedPortfolio?: Portfolio;
  comparisonColor: string;
  options: DropMenuItem[];
  selectedValue?: string;
  onChange: (item: DropMenuItem, specificPortfolioVersion?: Portfolio) => void;
}

const PortfolioCompareSubjectSelector = ({
  lastSavedPortfolio,
  comparisonColor,
  options,
  selectedValue,
  onChange,
}: PortfolioCompareSubjectSelectorProps) => {
  const {
    versions,
    versionsLoading,
    versionsExpanded,
    setVersionsExpanded,
    month,
    year,
    filterVersions,
    filteredVersionsCount,
    totalVersionsCount,
  } = useVersions(getPortfolioVersions, lastSavedPortfolio, lastSavedPortfolio?.id);
  const categories = useMemo(() => {
    const currentArray = getCurrentGroup(versions, lastSavedPortfolio);
    const lastWeekArray = getLastWeekGroup(versions, currentArray.length > 0);
    const groupedByMonths = groupByMonths<Portfolio>(
      versions,
      countItems(currentArray) + countItems(lastWeekArray),
      toPortfolioMenuItems,
    );
    return [...currentArray, ...lastWeekArray, ...groupedByMonths];
  }, [versions, lastSavedPortfolio]);
  const { comparePortfolio, compareType } = useContext(ComparisonContext);

  const getSelectionLabelFn = useCallback(
    () => (isNil(selectedValue) ? 'None' : getNewSelectionLabel(selectedValue, comparePortfolio)),
    [selectedValue, comparePortfolio],
  );

  const getTooltipContentFn = useCallback(
    () => (isNil(selectedValue) ? 'None' : getNewComparisonTooltipContent(selectedValue, comparePortfolio)),
    [selectedValue, comparePortfolio],
  );

  useEffect(() => {
    // If subject was switched while comparison to a saved version was selected previously,
    // update the comparison version to the latest one for the new subject
    if (
      selectedValue === 'Last Saved' &&
      !isNil(lastSavedPortfolio) &&
      !isNil(comparePortfolio) &&
      comparePortfolio?.id !== lastSavedPortfolio.id
    ) {
      onChange({ ...toPortfolioVersionMenuItem(lastSavedPortfolio), value: 'Last Saved' }, lastSavedPortfolio);
    }
  }, [comparePortfolio, lastSavedPortfolio, selectedValue, onChange]);

  return (
    <Container color={comparisonColor}>
      <CategorizedDropMenu<Portfolio | string>
        usePortal
        className={QA_DROPDOWN_CLASSNAME}
        getHeaderComponent={(onCollapse) => (
          <>
            {options.map((item) => (
              <SimpleMenuItem
                key={item.label}
                item={{ ...item, style: { ...item.style, padding: '2px 10px 3px 10px' } }}
                descriptionStyle={{ padding: '0 10px' }}
                selected={selectionComparator(item.value, selectedValue)}
                highlighted={false}
                onClick={() => {
                  onCollapse?.();
                  onChange(item);
                  analyticsService.portfolioComparisonSelected({
                    compareType: item.value.toLowerCase(),
                    portfolioId: lastSavedPortfolio?.id,
                    portfolioName: lastSavedPortfolio?.name,
                  });
                }}
              />
            ))}
            <VersionsHeader>
              <HistoricalAllocations>
                <StyledStrong>Historical Allocations</StyledStrong>
                <Tooltip
                  content={
                    <PaddedContent>
                      <TooltipTitle>Historical Allocations</TooltipTitle>
                      Historical allocations may be impacted by data changes that occured after your portfolio was saved
                      and may not reflect your previous saved portfolio exactly.
                    </PaddedContent>
                  }
                  maxWidth={405}
                >
                  <Icon type="info-circle" />
                </Tooltip>
              </HistoricalAllocations>
              <DateFilter
                placeholder="Filter Historical Allocations..."
                month={month}
                year={year}
                disabled={totalVersionsCount === 0 || versionsLoading}
                onFilter={filterVersions}
              />
            </VersionsHeader>
          </>
        )}
        footerComponent={
          versionsLoading ? (
            <LoadingContainer>
              <Loading title="Loading portfolio history..." />
            </LoadingContainer>
          ) : filteredVersionsCount > versions.length || versionsExpanded ? (
            <FooterContainer>
              <Expander expanded={versionsExpanded} toggleExpanded={setVersionsExpanded} />
            </FooterContainer>
          ) : undefined
        }
        // @ts-expect-error: TODO fix strictFunctionTypes
        renderInnerCategoryItem={(item: DropMenuItem<Portfolio>, isSelected: boolean) => (
          <VersionItem item={item} isSelected={isSelected} />
        )}
        // @ts-expect-error: TODO fix strictFunctionTypes
        onChange={(item: DropMenuItem<Portfolio>) => {
          if (!selectionComparator(item.value, comparePortfolio)) {
            onChange({ ...item, value: 'Last Saved' }, item.value);
            analyticsService.portfolioComparisonSelected({
              compareType: item.value.version === lastSavedPortfolio ? 'last saved' : 'saved',
              historicalUpdatedTime: item.label,
              portfolioId: lastSavedPortfolio?.id,
              portfolioName: lastSavedPortfolio?.name,
            });
          }
        }}
        categories={categories}
        hideSeparator
        width={320}
        height={570}
        getTooltipContent={getTooltipContentFn}
        selected={comparePortfolio}
        getSelectionLabel={getSelectionLabelFn}
        alwaysShowTriggerTooltip
        tooltipUsePortal
        tooltipFlex
        selectionComparator={selectionComparator}
        triggerIcon={compareType === 'Optimized' ? <FlaskIcon type="flask" /> : undefined}
      />
    </Container>
  );
};

export default PortfolioCompareSubjectSelector;

const getNewComparisonTooltipContent = (selectedValue?: string, comparePortfolio?: Portfolio) =>
  `Comparison: ${
    selectedValue !== 'Last Saved' || !comparePortfolio?.updated
      ? selectedValue ?? '(None)'
      : Dates.toDayMonthShortYearTime(comparePortfolio.updated)
  }`;
const getNewSelectionLabel = (selectedValue?: string, comparePortfolio?: Portfolio) =>
  selectedValue !== 'Last Saved' || !comparePortfolio?.updated
    ? selectedValue ?? '(None)'
    : Dates.toDayMonthShortYear(comparePortfolio.updated);

const selectionComparator = (a: Portfolio | string | undefined, b: Portfolio | string | undefined): boolean => {
  if (typeof a !== typeof b) {
    return false;
  }
  if (typeof a === 'string' || typeof b === 'string') {
    return a === b;
  }
  return a?.id === b?.id && a?.version === b?.version;
};

const countItems = function <T>(categories: MenuCategory<T>[]): number {
  return categories.reduce((itemsCount: number, category: MenuCategory<T>) => itemsCount + category.items.length, 0);
};

const getCurrentGroup = (versions: Portfolio[], lastSavedPortfolio?: Portfolio): MenuCategory<Portfolio>[] => {
  return versions.length >= 1 && lastSavedPortfolio && versions[0].version === lastSavedPortfolio.version
    ? [mapToCategoryItem('Current Portfolio (Last Saved)', [versions[0]])]
    : [];
};

const getLastWeekGroup = (versions: Portfolio[], skipFirst: boolean): MenuCategory<Portfolio>[] => {
  if (skipFirst && versions.length <= 1) {
    return [];
  }
  const lastWeekVersions: Portfolio[] = versions
    .slice(skipFirst ? 1 : 0)
    .filter(
      (version) => !isNil(version.updated) && moment.utc(version.updated).isAfter(moment.utc().subtract(7, 'day')),
    );
  return lastWeekVersions.length > 0
    ? [
        {
          name: 'This Week',
          items: toPortfolioMenuItems(lastWeekVersions),
        },
      ]
    : [];
};

const mapToCategoryItem = (categoryName: string, versions: Portfolio[]): MenuCategory<Portfolio> => {
  return {
    name: categoryName,
    items: toPortfolioMenuItems(versions),
  };
};

const MenuCSS = (color: string) => css`
  &.${QA_DROPDOWN_CLASSNAME}, .${QA_DROPDOWN_CLASSNAME} {
    min-width: 50px;
    :focus-within {
      > div:last-child {
        border: none;
      }
    }
  }
  .qa-dropmenu-trigger {
    border: none;
    box-shadow: none;
    background-color: ${color};
    color: ${GetColor.White};
    label,
    .button-icon i {
      color: ${GetColor.White};
    }
  }
`;

const StyledStrong = styled.strong`
  font-size: 1.125rem;
`;

const Container = styled.div<{ color: string }>`
  ${({ color }) => MenuCSS(color)}
  max-height: 580px;
`;

const VersionsHeader = styled.div`
  padding: 15px 20px 10px 20px;
  border-top: 1px solid ${GetColor.Grey};
`;

const HistoricalAllocations = styled.div`
  i {
    margin-left: 4px;
  }
  padding-bottom: 10px;
`;

const PaddedContent = styled.div`
  padding: 9px 13px;
`;

const TooltipTitle = styled.div`
  line-height: 14px;
  font-size: 11px;
  font-weight: bold;
`;

const FlaskIcon = styled(Icon)`
  margin-right: 2px;
`;
