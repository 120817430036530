import type { AnalysisSnapshot, SnapshotMetadata, UploadMetaData } from 'venn-api';

export const mockUploadMetaData: UploadMetaData = {
  name: 'Return Rolling-06 15 2020 15:24 PM.zip',
  subjectId: '45939',
  subjectName: 'One Investment',
  savedId: '835d02c1-0f9d-4281-8bc1-d865502f447c',
  benchmarkName: 'Demo Portfolio',
  templateName: 'Shock Analysis',
  templateId: 'shock',
  startDate: 1560383999999,
  endDate: 1591919999999,
};

export const mockSnapshotMetaData: SnapshotMetadata = {
  name: 'Return Rolling-06 15 2020 15:24 PM.zip',
  subjectId: '45939',
  analysisViewId: '835d02c1-0f9d-4281-8bc1-d865502f447c',
  analysisViewName: 'Custom Analysis',
  benchmarkName: 'Demo Portfolio',
  exportTemplateName: 'Shock Analysis',
  templateId: 'shock',
  exportSubjectName: 'One Investment',
  exportType: 'ALL',
  startDate: 1560383999999,
  endDate: 1591919999999,
  analysisViewActive: true,
};

const analysisSnapshots: AnalysisSnapshot[] = [
  {
    fileId: 'c0e4c47e-fd7d-4cc8-b753-ca21e5cccebf',
    organizationId: 1,
    owner: {
      id: 3000073,
      name: 'yy',
      displayName: 'yy',
      email: 'yuanyuan@twosigma.com',
      active: true,
      created: 1532032184901,
      avatarId: 'd381a008-05c5-4e33-94e2-b21a0e486446',
      actualUser: false,
      accountId: 1,
    },
    metadata: mockSnapshotMetaData,
    active: true,
    created: 1592249078787,
  },
];

export default analysisSnapshots;
