import styled, { css } from 'styled-components';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import {
  ColorUtils,
  ExternalActivityListener,
  GetColor,
  ZIndex,
  SimpleTrigger,
  EllipsisTooltipSpan,
  Expander,
  NewBadge,
  Icon,
} from 'venn-ui-kit';
import type { GeneralAnalysisTemplate } from 'venn-api';
import {
  hasAccessToTemplate,
  getDefaultAnalysisType,
  isNewBlock,
  Routes,
  toClassName,
  getDisplayTemplates,
} from 'venn-utils';
import { Link, useLocation } from 'react-router-dom';
import { UserContext } from 'venn-components';

interface TemplateSelectorProps {
  currentTemplate: GeneralAnalysisTemplate;
  templates: GeneralAnalysisTemplate[];
  onEditTemplate?: (id: string) => void;
  onSelectTemplate: (template: GeneralAnalysisTemplate) => void;
}

// Used to obtain the default state of the settings for a new user
const emptySettings = {
  system: {},
  user: {},
};

const SELECTOR_WIDTH = 450;

const TemplateSelector = ({ currentTemplate, templates, onEditTemplate, onSelectTemplate }: TemplateSelectorProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [templatesExpanded, setTemplatesExpanded] = useState<boolean>(false);
  const [blocksExpanded, setBlocksExpanded] = useState<boolean>(false);

  const userContext = useContext(UserContext);
  const isReadOnly = !userContext.hasPermission('ANALYSIS_PAGE_CREATE_TEMPLATE');

  const location = useLocation();

  const openDropdown = useCallback(() => {
    setOpen(true);
  }, []);

  const closeDropdown = useCallback(() => {
    setOpen(false);
    setTemplatesExpanded(false);
    setBlocksExpanded(false);
    setSearch('');
  }, []);

  const toggleTemplatesExpanded = useCallback(() => {
    setTemplatesExpanded((expanded) => !expanded);
  }, []);

  const toggleBlocksExpanded = useCallback(() => {
    setBlocksExpanded((expanded) => !expanded);
  }, []);

  const maxTemplateResults = 5;
  const maxBlockResults = 4;

  const [noRecentTemplates, visibleTemplates, standardTemplates, visibleBlocks, blocks] = useMemo(() => {
    if (!templates) {
      return [false, [], [], [], []];
    }

    const templateResults = getDisplayTemplates(templates).filter((t) =>
      t.name.toLowerCase().includes(search.toLowerCase()),
    );
    const standardTemplatesInner = templateResults.filter((t) => t.analysisTemplateType !== 'BLOCK');
    const blocksInner = templateResults.filter((t) => t.analysisTemplateType === 'BLOCK');

    const lastInvestmentId = getDefaultAnalysisType(
      'investment',
      userContext.settings,
      templates,
      userContext.profileSettings,
    );
    const lastPortfolioId = getDefaultAnalysisType(
      'portfolio',
      userContext.settings,
      templates,
      userContext.profileSettings,
    );
    const noRecentTemplatesInner =
      lastInvestmentId ===
        getDefaultAnalysisType('investment', emptySettings, templates, userContext.profileSettings) &&
      lastPortfolioId === getDefaultAnalysisType('portfolio', emptySettings, templates, userContext.profileSettings);
    standardTemplatesInner.sort((a, _) => (a.id === lastInvestmentId || a.id === lastPortfolioId ? -1 : 1));
    const visibleTemplatesInner = standardTemplatesInner.slice(
      0,
      templatesExpanded ? standardTemplatesInner.length : maxTemplateResults,
    );

    blocksInner.sort((a, b) => (b.created ?? 0) - (a.created ?? 0));
    const visibleBlocksInner = blocksInner.slice(0, blocksExpanded ? blocksInner.length : maxBlockResults);
    return [noRecentTemplatesInner, visibleTemplatesInner, standardTemplatesInner, visibleBlocksInner, blocksInner];
  }, [blocksExpanded, search, templates, templatesExpanded, userContext.settings, userContext.profileSettings]);

  return (
    <StyledActivityListener listeningEnabled={open} onExternalActivity={closeDropdown}>
      <SimpleTrigger
        searchable
        expanded={open}
        search={search}
        onSearch={setSearch}
        onClick={openDropdown}
        selection={currentTemplate.name}
        disableTooltip
        style={{ width: `${SELECTOR_WIDTH}px` }}
        className="qa-template"
      />
      <MenuWrapper open={open}>
        <Items>
          <Heading flex>{!noRecentTemplates && search === '' && 'Recent '}Analysis Templates</Heading>
          {visibleTemplates.length === 0 ? (
            <NoResultsMessage>(None)</NoResultsMessage>
          ) : (
            visibleTemplates.map((t) => {
              const isUserAllowedTemplate = hasAccessToTemplate(t);
              const isVennTemplate = t.appUser?.displayName === 'Venn';
              return (
                <Item
                  disabled={!isUserAllowedTemplate}
                  key={t.id}
                  onClick={
                    isUserAllowedTemplate
                      ? () => {
                          onSelectTemplate(t);
                          closeDropdown();
                        }
                      : undefined
                  }
                  className={t.name ? toClassName(t.name) : 'qa-undefined'}
                >
                  <Row>
                    <Label usePortal>
                      {t.name}
                      {isNewBlock(t) && isVennTemplate && <NewBadge />}
                    </Label>
                    {!isReadOnly && (
                      <EditButton
                        visible={!isVennTemplate}
                        onClick={(evt) => {
                          evt.stopPropagation();
                          onEditTemplate?.(t.id);
                        }}
                        className="qa-edit"
                      >
                        Edit
                      </EditButton>
                    )}
                  </Row>
                </Item>
              );
            })
          )}
          {standardTemplates.length > maxTemplateResults && (
            <Expander expanded={templatesExpanded} toggleExpanded={toggleTemplatesExpanded} />
          )}
          <Heading>{search === '' && 'Featured '} Analysis Blocks</Heading>
          {visibleBlocks.length === 0 ? (
            <NoResultsMessage>(None)</NoResultsMessage>
          ) : (
            visibleBlocks.map((block) => {
              const isUserAllowedBlock = hasAccessToTemplate(block);
              return (
                <Item
                  disabled={!isUserAllowedBlock}
                  key={block.id}
                  onClick={
                    isUserAllowedBlock
                      ? () => {
                          onSelectTemplate(block);
                          closeDropdown();
                        }
                      : undefined
                  }
                  className={block.name ? toClassName(block.name) : 'qa-undefined'}
                >
                  <NewItemHeading>
                    <Label usePortal>{block.name}</Label>
                    <BadgeWrapper>{isNewBlock(block) && <NewBadge />}</BadgeWrapper>
                  </NewItemHeading>
                  <Description>{block.description}</Description>
                </Item>
              );
            })
          )}
          {blocks.length > maxBlockResults && (
            <Expander expanded={blocksExpanded} toggleExpanded={toggleBlocksExpanded} />
          )}
        </Items>
        {!isReadOnly ? (
          <FooterWrapper>
            <NewTemplateButton
              to={{
                pathname: Routes.CREATE_TEMPLATE,
                state: {
                  previousPathname: location.pathname,
                  previousSearch: location.search,
                  state: location.state,
                },
              }}
              className="qa-new-template"
            >
              <Plus type="plus-circle" />
              Create A New Template
            </NewTemplateButton>
          </FooterWrapper>
        ) : (
          <div style={{ height: '16px' }} />
        )}
      </MenuWrapper>
    </StyledActivityListener>
  );
};

export default TemplateSelector;

const Plus = styled(Icon)`
  font-weight: normal;
  font-size: 24px;
  margin-right: 5px;
  margin-bottom: 2px;
`;

const MenuWrapper = styled.div<{ open: boolean }>`
  position: absolute;
  ${(props) => !props.open && 'display: none;'}
  z-index: ${ZIndex.StickyCover};
  width: ${SELECTOR_WIDTH}px;
  background-color: ${GetColor.White};
  border-radius: 2px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
`;

const Items = styled.div`
  max-height: 550px;
  overflow: auto;
`;

const Heading = styled.div<{ flex?: boolean }>`
  height: 24px;
  font-weight: bold;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  padding: 0 20px;
  margin-top: 20px;
  ${(props) =>
    props.flex &&
    css`
      display: flex;
      align-items: center;
      justify-content: space-between;
    `}
`;

const Item = styled.button`
  color: ${GetColor.Black};
  width: 100%;
  text-align: left;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0 20px;
  :hover {
    color: ${GetColor.Black};
    background-color: ${ColorUtils.opacifyFrom(GetColor.Primary.Dark, 0.1)};
    :disabled {
      color: ${GetColor.Black};
      background-color: ${GetColor.PaleGrey};
    }
  }
  :disabled {
    color: ${GetColor.Black};
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
`;

const Label = styled(EllipsisTooltipSpan)`
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: auto;
  margin-bottom: auto;
  /* stylelint-disable-next-line */
  ${Row}:hover & {
    width: 80%;
  }
`;

const EditButton = styled.span<{ visible: boolean }>`
  color: ${GetColor.Primary.Dark};
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: auto;
  margin-bottom: auto;
  display: none;

  &:hover {
    color: ${ColorUtils.opacifyDarkFrom(GetColor.Primary.Dark, 0.1)};
  }
  /* stylelint-disable-next-line */
  ${Item}:hover & {
    ${(props) => props.visible && 'display: flex;'}
  }
`;

const NewTemplateButton = styled(Link)`
  color: ${GetColor.Primary.Dark};
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  &:hover {
    color: ${ColorUtils.opacifyDarkFrom(GetColor.Primary.Dark, 0.1)};
    background-color: ${GetColor.PaleGrey};
  }
`;

const FooterWrapper = styled.div`
  border-top: 1px solid ${GetColor.Primary.Dark};
  text-align: center;
  height: 50px;
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const Description = styled.div`
  font-size: 10px;
  color: ${GetColor.LightGrey};
  white-space: normal;
  padding-bottom: 5px;
`;

const StyledActivityListener = styled(ExternalActivityListener)`
  overflow: hidden;
`;

const NoResultsMessage = styled(Description)`
  padding: 0 20px;
`;

const NewItemHeading = styled.div`
  display: inline-flex;
  align-items: center;
  width: 100%;
  padding-top: 5px;
`;

const BadgeWrapper = styled.div`
  display: flex;
  padding-bottom: 3px;
`;
