const values = [
  '-1.91%',
  '-1.29%',
  '1.4%',
  '0.04%',
  '-0.61%',
  '-0.46%',
  '0.74%',
  '2.42%',
  '-0.15%',
  '-0.52%',
  '-0.01%',
  '-1.06%',
  '-3.41%',
  '6.08%',
  '-2.1%',
  '-1.04%',
  '-6.29%',
  '1.73%',
  '4.01%',
  '2.07%',
  '1.36%',
  '-2.53%',
  '3.36%',
  '1.33%',
  '1.91%',
  '1.17%',
  '5.24%',
  '0.85%',
  '4.25%',
  '1.73%',
  '0.32%',
  '-1.63%',
  '-0.68%',
  '-0.32%',
  '4.99%',
  '0.9%',
  '0.33%',
  '-0.23%',
  '-1.47%',
  '2.57%',
  '1.59%',
  '-4.35%',
  '-0.26%',
  '3.25%',
  '-0.53%',
  '-3.35%',
  '1.75%',
  '-2.81%',
  '0.53%',
  '-11.38%',
  '-4.54%',
  '-3.13%',
  '7.74%',
  '1.05%',
  '-2.05%',
  '3.26%',
  '-7.56%',
  '4.31%',
  '1.23%',
  '0.33%',
  '-0.23%',
];

const getLastDayInMonth = (month: number, year: number) => {
  return 32 - new Date(year, month, 32).getDate();
};

export default (() => {
  let currentYear = new Date().getFullYear() - 5;
  let currentMonth = new Date().getMonth() + 1;
  let csv = 'Date,Demo Investment\n';
  values.forEach((value: string) => {
    if (currentMonth === 13) {
      currentMonth = 1;
      currentYear += 1;
    }
    csv += `${currentMonth}/${getLastDayInMonth(currentMonth, currentYear)}/${currentYear},${value}\n`;
    currentMonth += 1;
  });
  return csv;
})();
