import type { AnalysisSubject, ExcelCell } from 'venn-utils';
import { assertExhaustive, formatExportValue } from 'venn-utils';
import type { ScenarioColumnData, ScenarioRowData } from '../types';
import type { Scenario, ShockUnitsEnum } from 'venn-api';

const formatAsPercentage = (unit: ShockUnitsEnum) => {
  switch (unit) {
    case 'ABS_PERCENTAGE':
    case 'PERCENTAGE':
    case 'BPS':
      return true;
    case 'POINTS':
    case 'PRICE':
      return false;
    default:
      throw assertExhaustive(unit);
  }
};

export const getExcelFormattedScenarioShock = (scenario: Scenario): ExcelCell => {
  const units = scenario.units || 'PERCENTAGE';
  return formatExportValue(scenario.shock, formatAsPercentage(units), units === 'PRICE');
};

export const convertScenarioDataToExcel = (
  subjects: AnalysisSubject[],
  subjectLabels: string[],
  rowData?: ScenarioRowData[] | undefined | null,
  commonBenchmarkName?: string,
): ExcelCell[][] => {
  const excel: ExcelCell[][] = [];
  const isRelative = rowData?.some((r) => r.relative);
  const relativeText = isRelative ? 'Relative ' : '';
  excel.push([
    { value: 'Input', bold: true },
    { value: 'Shock Value', bold: true },
    ...subjectLabels.flatMap((label) => [
      { value: `${label} Estimated ${relativeText}Return`, bold: true },
      { value: `${label} Estimated ${relativeText}Return Error (+/-)`, bold: true },
    ]),
  ]);
  if (isRelative) {
    excel.push([
      { value: null },
      { value: null },
      ...subjects.flatMap((subject) => {
        const benchmarkText = commonBenchmarkName
          ? `Benchmark: ${commonBenchmarkName}`
          : subject.activeBenchmark
            ? `Benchmark: ${subject.activeBenchmark.name}`
            : null;
        return [
          { value: benchmarkText, italic: true },
          { value: benchmarkText, italic: true },
        ];
      }),
    ]);
  }
  rowData?.forEach((row) => {
    excel.push([
      { value: row.label },
      row.scenarioShockXLS,
      ...row.scenarioAnalysis.flatMap((scenario) => [
        { value: scenario?.predicted || '--', percentage: !!scenario?.predicted },
        { value: scenario?.predictedError || '--', percentage: !!scenario?.predictedError },
      ]),
    ]);
  });
  return excel;
};

export const convertScenarioColumnChartDataToExcel = (
  columnData: ScenarioColumnData[],
  subjectNames: string[],
  relative: boolean[],
  benchmarkNames: (string | undefined)[],
): ExcelCell[][] | undefined => {
  if (!columnData.length) {
    return undefined;
  }
  const headers: ExcelCell[] = [
    { value: 'Input', bold: true },
    { value: 'Shock Value', bold: true },
    ...subjectNames.flatMap((subjectName, index) => [
      { value: `${subjectName} Estimated ${relative[index] ? 'Relative ' : ''}Return`, bold: true },
      {
        value: `${subjectName} Estimated ${relative[index] ? 'Relative ' : ''}Return Error (+/-)`,
        bold: true,
      },
    ]),
  ];

  if (columnData.length === 0) {
    return [headers];
  }

  const rows: ExcelCell[][] = [];

  if (benchmarkNames.some((name) => !!name)) {
    rows.push([
      { value: null },
      { value: null },
      ...benchmarkNames.flatMap((benchmarkName) => {
        const benchmarkText = benchmarkName ? `Benchmark: ${benchmarkName}` : null;
        return [
          { value: benchmarkText, italic: true },
          { value: benchmarkText, italic: true },
        ];
      }),
    ]);
  }

  const scenarios = columnData[0].scenarios;
  if (scenarios) {
    scenarios.forEach((scenario, index) =>
      rows.push([
        { value: scenario.fundName },
        getExcelFormattedScenarioShock(scenario),
        ...columnData.flatMap((column) => [
          { value: column.scenarioAnalyses?.[index].predicted || '--', percentage: true },
          { value: column.scenarioAnalyses?.[index].predictedError || '--', percentage: true },
        ]),
      ]),
    );
  }

  return [headers, ...rows];
};
