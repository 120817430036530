import React, { useContext } from 'react';
import styled from 'styled-components';
import { EmptyState, PortfolioLabContext } from 'venn-components';
import { Hint, Icon, Link as LinkStyle } from 'venn-ui-kit';

const NoOptimizationError = () => {
  const { onEditObjectiveConstraintValue, onShowAllocationSection, onShowFactorSection } =
    useContext(PortfolioLabContext);
  return (
    <ErrorContainer>
      <EmptyState header="Portfolio Lab was unable to find a solution">
        <ErrorMessage>
          <div>No optimized solution was found for your set constraints.</div>
          Adjust your{' '}
          <ErrorLink tabIndex={0} onClick={onEditObjectiveConstraintValue}>
            objective <Icon type="function" />
          </ErrorLink>
          ,{' '}
          <ErrorLink tabIndex={0} onClick={onShowAllocationSection}>
            allocation constraints <Icon type="sliders-h" />
          </ErrorLink>{' '}
          or{' '}
          <ErrorLink tabIndex={0} onClick={onShowFactorSection}>
            factor exposure constraints <Icon type="dna" />
          </ErrorLink>{' '}
          to retry.
        </ErrorMessage>
      </EmptyState>
    </ErrorContainer>
  );
};

export default NoOptimizationError;

const ErrorContainer = styled.div`
  padding: 40px 20px 0 20px;
`;

const ErrorMessage = styled(Hint)`
  max-width: 500px;
  margin-top: 30px;
  text-align: center;
  & > :first-child {
    margin-bottom: 8px;
  }
  i {
    margin-left: 2px;
  }
`;

const ErrorLink = styled(LinkStyle)`
  &:hover {
    cursor: pointer;
  }
`;
