import type React from 'react';
import BarsChartRows from './BarsChartRows';
import { prepareBarsFactors } from './logic';
import type { ChartRowProviderProps } from './summary-chart/SummaryChart';

const BarsChartRowsProvider: React.FC<React.PropsWithChildren<ChartRowProviderProps>> = ({ factors, children }) =>
  children({
    preparedFactors: prepareBarsFactors(factors),
    RowsComponent: BarsChartRows,
  });

export default BarsChartRowsProvider;
