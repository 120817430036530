import type { Theme, VennColors } from 'venn-ui-kit';

type PortfolioLabColors = { current: string; alternate: string; optimized: string; benchmark: string };
export function getPortfolioLabColors(themeOrColors: Theme | VennColors): PortfolioLabColors {
  const colors = 'Colors' in themeOrColors ? themeOrColors.Colors : themeOrColors;

  return {
    current: colors.StudioSubjectColor.A1,
    alternate: colors.StudioSubjectColor.A2,
    optimized: colors.DEPRECATED_DataBarColor.LightPaleBlue,
    benchmark: colors.DarkGrey,
  };
}
