import { clamp, range } from 'lodash';
import { ELLIPSIS_ITEM } from './Constants';

function* paginationElementsGenerator(pagesCount: number, selectedPage: number) {
  const protectedSelectedPage = clamp(selectedPage, 1, pagesCount);
  let start = clamp(protectedSelectedPage - 2, 1, Infinity);
  let end = clamp(protectedSelectedPage + 2, start, pagesCount);

  if (start === 1) {
    end = Math.min(5, pagesCount);
  }

  if (end === pagesCount) {
    start = Math.max(pagesCount - 4, 1);
  }

  yield* start <= 2 ? range(1, start) : [1, ELLIPSIS_ITEM];

  yield* range(start, end + 1);

  yield* end >= pagesCount - 2 ? range(end + 1, pagesCount + 1) : [ELLIPSIS_ITEM, pagesCount];
}

export const getPaginationItems = (pagesCount: number, selectedPage: number) =>
  Array.from(paginationElementsGenerator(pagesCount, selectedPage));

export const getPaginationKey = (item: number | string, idx: number) => {
  if (item !== ELLIPSIS_ITEM) {
    return item;
  }

  return idx === 1 ? 'left-el' : 'right-el';
};
