import React, { Component } from 'react';
import styled from 'styled-components';
import type { ItemTypeEnum, Benchmark, ItemId } from 'venn-api';
import { GetColor } from 'venn-ui-kit';
import type { BenchmarkAction } from '../../state/benchmarkActions';
import { BenchmarkUIView } from '../../state/benchmarkActions';
import BenchmarksSearchFooter from './BenchmarksSearchFooter';
import type { SearchMenuItem } from '../../../../search-menu';
import { SearchMenuBar } from '../../../../search-menu';

export interface BenchmarksSearchViewProps {
  active?: SearchMenuItem;
  exclude?: Benchmark[];
  investmentsOnly?: boolean;
  onAction: (action: BenchmarkAction) => void;
}

class BenchmarksSearchView extends Component<BenchmarksSearchViewProps> {
  static defaultProps: Partial<BenchmarksSearchViewProps> = {
    exclude: [],
  };

  handleFundSelect = (option: SearchMenuItem) => {
    this.props.onAction({
      type: 'BenchmarkSelectSearchResult',
      payload: option,
    });
  };

  handleSubmit = () => {
    if (!this.props.active) {
      return;
    }
    this.props.onAction({
      type: 'BenchmarkAddFromSearch',
      payload: this.props.active,
    });
  };

  handleCancel = () => {
    this.props.onAction({
      type: 'BenchmarkUINavigation',
      payload: {
        target: {
          kind: 'View',
          viewId: BenchmarkUIView.ListView,
        },
      },
    });
  };

  handleExit = () => {
    this.props.onAction({
      type: 'BenchmarkUINavigation',
      payload: {
        target: {
          kind: 'Exit',
        },
      },
    });
  };

  getExcludedItems(): ItemId[] | undefined {
    return this.props.exclude
      ?.filter((compare) => compare.fundId)
      .map((compare) => ({
        id: compare.fundId!,
        type: 'FUND' as ItemTypeEnum,
      }));
  }

  render() {
    const { active, investmentsOnly } = this.props;
    return (
      <>
        <Container>
          <h2>Select a benchmark.</h2>
          <SearchMenuBar
            onSelected={this.handleFundSelect}
            excludedItems={this.getExcludedItems()}
            investmentsOnly={investmentsOnly}
            defaultMenuIsOpen={false}
            location="addBenchmark"
            usePortal
            privateAssetSearchMode="PUBLIC_ONLY"
          />
        </Container>
        <BenchmarksSearchFooter
          disableAddButton={!active}
          onAddButtonClick={this.handleSubmit}
          onCancelButtonClick={this.handleExit}
          onReturnToListButtonClick={this.handleCancel}
        />
      </>
    );
  }
}

export default BenchmarksSearchView;

const Container = styled.div`
  padding: 35px 10px 0;
  height: 460px;
  overflow-x: hidden;
  overflow-y: auto;

  h2 {
    color: ${GetColor.Black};
    font-size: 20px;
    font-weight: bold;
    margin: 0 10px 20px;
  }
`;
