import { useRecoilRefresher_UNSTABLE, useResetRecoilState } from 'recoil';
import { defaultInvestmentOverridesList, remoteInvestmentOverridesList } from 'venn-state';

/**
 * This hook should be used instead of useResetRecoilState(remoteInvestmentOverridesList)
 * if you want to update remoteInvestmentOverridesList with the latest data from the server.
 * It does this by invalidating the cached network request made by remoteInvestmentOverridesList's
 * default selector (defaultInvestmentOverridesList) before performing a recoil reset state.
 */
export const useRefreshInvestmentOverridesList = () => {
  const resetRemoteInvestmentOverridesList = useResetRecoilState(remoteInvestmentOverridesList);
  const refreshRemoteInvestmentOverridesList = useRecoilRefresher_UNSTABLE(defaultInvestmentOverridesList);
  return () => {
    refreshRemoteInvestmentOverridesList();
    resetRemoteInvestmentOverridesList();
  };
};
