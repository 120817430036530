import type { FC } from 'react';
import React from 'react';
import styled, { css } from 'styled-components';
import classNames from 'classnames';
import { Headline2, Body1, GetColor, Icon } from 'venn-ui-kit';

export interface DisplayButtonProps extends InverseProp, BasicProp {
  /**
   * Main title
   */
  title: string;
  /**
   * Subtitle
   */
  subtitle: string;
  /**
   * Left-hand-side icon. Only displayed if this prop has a value.
   */
  icon?: string | JSX.Element;
  className?: string;
  /**
   * Allows adding some content beneath the titles
   */
  children?: JSX.Element[] | JSX.Element | string;
  /**
   * Fires when the button is clicked
   */
  onClick?: () => void;
}

interface InverseProp {
  /**
   * Displays the button in inversed colours (white on black)
   */
  inverse?: boolean;
}

interface BasicProp {
  /**
   * Displays the button without icons, right-hand-side chevron, and with titles centered
   */
  basic?: boolean;
}

export const DisplayButton: FC<React.PropsWithChildren<DisplayButtonProps>> = ({
  title,
  subtitle,
  icon,
  basic,
  inverse,
  children,
  className,
  onClick,
}) => (
  <Container inverse={inverse} onClick={onClick} className={classNames(className, 'qa-display-button')}>
    <Content>
      {icon && !basic && typeof icon === 'string' && <StyledIcon type={icon} />}
      {icon && !basic && typeof icon !== 'string' && <CustomIcon>{icon}</CustomIcon>}
      <Titles basic={basic}>
        <Headline2 inverted={inverse}>{title}</Headline2>
        <Body1 inverted={inverse}>{subtitle}</Body1>
      </Titles>
      {!basic && <ChevronIcon type="chevron-right" />}
    </Content>
    <ExtraContent className="qa-display-button-extra-content">{children}</ExtraContent>
  </Container>
);

const CustomIcon = styled.div`
  height: 50px;
  width: 50px;
  margin-right: 28px;
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: ${GetColor.Black};
`;

const Container = styled.button<InverseProp>`
  border: 1px solid ${GetColor.Grey};
  border-radius: 4px;
  position: relative;
  min-height: 100px;
  min-width: 700px;
  display: flex;
  align-items: center;
  color: ${GetColor.Black};
  transition: all 0.2s ease-out;

  ${(props) =>
    props.inverse
      ? css`
          border: 1px solid ${GetColor.PaleGrey};
          background-color: ${GetColor.Black};
          color: ${GetColor.White};

          ${Headline2}, ${Body1} {
            color: ${GetColor.White};
          }

          ${CustomIcon} {
            fill: ${GetColor.White};
          }
        `
      : ''}

  &:hover {
    background-color: ${GetColor.Primary.Dark};
    color: ${GetColor.White};
    ${Headline2}, ${Body1} {
      color: ${GetColor.White};
    }
    ${CustomIcon} {
      fill: ${GetColor.White};
    }
  }
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: 32px;
  margin-right: 22px;
`;

const ExtraContent = styled.div`
  position: absolute;
  bottom: 25px;
  left: 120px;
`;

const Titles = styled.div<BasicProp>`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 8px;
  margin-right: 22px;

  ${Headline2}, ${Body1} {
    margin: 0;
  }

  ${(props) =>
    props.basic
      ? `
      ${Headline2}, ${Body1} {
        text-align: center;
      }
  `
      : ''}
`;

const ChevronIcon = styled(Icon)`
  font-size: 30px;
`;

const StyledIcon = styled(Icon)`
  font-size: 50px;
  margin-right: 28px;
`;

export default DisplayButton;
