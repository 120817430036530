import styled, { css } from 'styled-components';
import { ColorUtils, GetColor, Icon, Pill, Spinner } from 'venn-ui-kit';

export const Constraint = styled.div`
  display: flex;
  :not(:last-child) {
    margin-bottom: 5px;
  }
  color: ${GetColor.HintGrey};
  flex-wrap: wrap;
  & > div {
    margin-bottom: 5px;
  }
`;

export const ConstraintSubjectTypeLabel = styled(Pill)<{ color: string }>`
  background: ${(props) => props.color};
  display: block;
  min-width: 72px;
  margin-right: 5px;
  margin-left: -3px;
  top: 0;
  line-height: 14px;
`;

export const CenteredText = styled.div`
  display: flex;
  align-items: center;
`;

export const ActionIcon = styled.button<{ destructive?: boolean }>`
  font-size: 12px;
  color: ${GetColor.Grey};
  &:hover {
    color: ${({ destructive }) => (destructive ? ColorUtils.opacifyFrom(GetColor.Error, 0.5) : GetColor.Primary.Dark)};
  }
`;

export const ActiveIcon = styled(Icon)`
  font-size: 12px;
  color: ${GetColor.Primary.Dark};
`;

export const HighlightIcon = styled(Icon)`
  font-size: 12px;
  color: ${GetColor.HighlightDark};
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-top: 4px;

  & > :not(:last-child) {
    margin-right: 5px;
  }
`;

export const InputCSS = css`
  min-width: 36px;
  height: 20px;
  margin: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
`;

export const RelativeContainer = styled.div`
  position: relative;
  display: flex;
`;

export const RelativeSpinner = styled(Spinner)`
  position: relative;
  left: 10px;
  top: 2px;
`;
