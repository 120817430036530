const searchStudioViews = {
  results: [
    {
      analysisSubjectType: 'PORTFOLIO',
      analysisViewType: 'ASSEMBLY',
      created: 1629121623523,
      customTemplateId: null,
      exportsErrorType: null,
      id: '71a750de-ac5f-4165-bbb2-78528a04121b',
      name: 'custom saved studio view',
      numScheduledExport: 0,
      numSnapshots: 0,
      scheduledFrequency: null,
      scheduledId: null,
      subjectId: '4437382',
      subjectName: 'Portfolio Test',
    },
  ],
};

export default searchStudioViews;
