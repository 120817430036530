import React from 'react';
import styled from 'styled-components';
import { type AnalysisSubject, getRequestSubjectFromAnalysisSubject } from 'venn-utils';
import { GetColor, Icon, ItemIcon, ItemType } from 'venn-ui-kit';
import {
  convertRequestSubjectToItemType,
  getUniqueKeyFromStudioRequestSubject,
  NameWithOptionalFee,
  PureNameWithOptionalFee,
} from 'venn-components';
import {
  blockDoesNotSupportBenchmark,
  blockDoesNotSupportPrivateBenchmark,
  blockSelectedPeerGroupId,
  blockSettings,
  blockFonts,
  getCustomFontSize,
  useCachedLoadableValue,
  hasProxyState,
  type StudioRequestSubject,
  type CustomFont,
} from 'venn-state';
import { useRecoilValue } from 'recoil';
import type { PortfolioCompare } from 'venn-api';

interface BlockObjectsProps {
  blockId: string;
  subjects: StudioRequestSubject[];
  individualBenchmarks: boolean;
  commonBenchmark?: AnalysisSubject;
  showInvestments?: boolean;
  forceHideBenchmarks?: boolean;
}

const BlockObjects = ({
  blockId,
  subjects,
  commonBenchmark,
  individualBenchmarks,
  showInvestments,
  forceHideBenchmarks,
}: BlockObjectsProps) => {
  const blockSetting = useRecoilValue(blockSettings(blockId));
  const headerInfoFont = useRecoilValue(blockFonts.blockHeaderInfo(blockId));
  const hideBenchmark = useRecoilValue(blockDoesNotSupportBenchmark(blockId)) || forceHideBenchmarks;
  const hidePrivateBenchmark = useRecoilValue(blockDoesNotSupportPrivateBenchmark(blockId)) || forceHideBenchmarks;
  const peerGroupInformation = useRecoilValue(blockSelectedPeerGroupId(blockId));
  const hideAllSubjects = blockSetting.customBlockType === 'SCENARIO';
  const trimmedSubjects = hideAllSubjects ? [] : subjects;

  return (
    <Wrapper fontSize={headerInfoFont} data-testid="qa-block-subjects">
      {commonBenchmark && !hideBenchmark && (
        <ItemPair>
          <ItemIcon item={ItemType.CommonBenchmark} />
          <NameWithOptionalFee subject={getRequestSubjectFromAnalysisSubject(commonBenchmark)} />
          <SeparatorIcon type="pipe" />
        </ItemPair>
      )}
      {trimmedSubjects.map((subject) => {
        return (
          (subject.subjectType !== 'INVESTMENT' ||
            (subject.subjectType === 'INVESTMENT' && (showInvestments === undefined || showInvestments))) && (
            <ItemPair key={getUniqueKeyFromStudioRequestSubject(subject)}>
              {subject.portfolioComparisonType === 'SAVED' ? (
                <Icon type="clock-rotate-left" />
              ) : (
                <ItemIcon item={convertRequestSubjectToItemType(subject)} />
              )}
              <Name>
                <NameWithOptionalFee subject={subject} />
              </Name>
              {individualBenchmarks &&
              !hideBenchmark &&
              (!subject.private || (subject.private && !hidePrivateBenchmark)) ? (
                <>
                  <SeparatorIcon type="pipe" />
                  <ItemIcon item={ItemType.CommonBenchmark} />
                  {subject.individualBenchmark?.name ? (
                    <BenchmarkName compare={subject.individualBenchmark} />
                  ) : (
                    'No Benchmark'
                  )}
                </>
              ) : null}
            </ItemPair>
          )
        );
      })}
      {peerGroupInformation && (
        <ItemPair key={peerGroupInformation.categoryName}>
          <ItemIcon item={ItemType.PeerGroup} className="far" />
          <Name>{peerGroupInformation.categoryName}</Name>
        </ItemPair>
      )}
    </Wrapper>
  );
};

const BenchmarkName = ({ compare }: { compare: PortfolioCompare }) => {
  const hasProxy = useCachedLoadableValue(hasProxyState((compare?.fundId ?? compare?.portfolioId?.toString())!), false);
  return (
    <PureNameWithOptionalFee
      name={compare.name}
      fee={0}
      hasProxy={hasProxy}
      supportsFees={false}
      feesExcluded={false}
    />
  );
};

const Wrapper = styled.div<{ fontSize: CustomFont | undefined }>`
  display: flex;
  flex-wrap: wrap;
  column-gap: ${({ fontSize }) => (fontSize ? '0.6em' : '20px')};
  row-gap: ${({ fontSize }) => (fontSize ? '0.3em' : '10px')};
  align-items: center;
  ${({ fontSize }) => `font-size: ${fontSize ? getCustomFontSize(fontSize) : '11px'};`}
  max-width: 100%;
  padding: 0.5em 20px;
  border-bottom: 1px solid ${GetColor.Grey};
`;

const ItemPair = styled.div`
  display: flex;
  column-gap: 5px;
  overflow-x: clip;
  /* Prevent superscripts from being cut off */
  overflow-y: visible;
  white-space: nowrap;
  align-items: center;
`;

const Name = styled.span`
  overflow-x: clip;
  /* Prevent superscripts from being cut off */
  overflow-y: visible;
  text-overflow: ellipsis;
`;

const SeparatorIcon = styled(Icon)`
  color: ${GetColor.Grey};
`;

export default BlockObjects;
