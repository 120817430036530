import useIsMounted from '../useIsMounted/useIsMounted';
import { useCallback } from 'react';

type FunctionType<T extends unknown[]> = (...args: T) => void;

/**
 * Thin wrapper that makes sure a component is mounted before calling the function {@param func}.
 * Useful for functions that set state and that are passed around to other components.
 */
const useCallIfMounted = <T extends unknown[]>(func: FunctionType<T>): FunctionType<T> => {
  const isMountedRef = useIsMounted();
  return useCallback(
    (...args: T) => {
      if (isMountedRef.current) {
        func(...args);
      }
    },
    [func, isMountedRef],
  );
};

export default useCallIfMounted;
