import React from 'react';
import styled from 'styled-components';
import { Loading, LoadingSize } from 'venn-ui-kit';

export const OptionsLoading = () => (
  <LoadingContainer data-testid="qa-proxy-options-loading">
    <Loading size={LoadingSize.small} title="" />
  </LoadingContainer>
);

const LoadingContainer = styled.div`
  padding: 15px;
`;
