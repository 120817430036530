import type { CustomBlockTypeEnum } from 'venn-utils';

export const MAX_ROLLING_CORRELATION_SUBJECTS = 4;
export const MAX_BOX_CHART_SUBJECTS = 3;
export const DEFAULT_CONFIDENCE_LEVELS = [0.1, 0.25, 0.5, 0.75, 0.9];
export const BOX_CHART_CONFIDENCE_LEVELS = [0.05, 0.25, 0.5, 0.75, 0.95];
export const DEFAULT_BREAKDOWN_CHART_CONFIDENCE_LEVELS = [0.5];
export const DEFAULT_BREAKDOWN_CHART_VISIBILITY = [true];

export const DEFAULT_FORECAST_WINDOW = 10;
export const DEFAULT_PRIVATE_ASSET_ALLOCATION = 0.5;
export const DEFAULT_PRIVATE_ASSET_ALLOCATION_VISIBILITY = true;
export const DEFAULT_USE_LOGARITHMIC_SCALE = true;

export const SUPPORT_30_OBJECTS: CustomBlockTypeEnum[] = [
  'FACTOR_CONTRIBUTION',
  'FACTOR_EXPOSURES',
  'PORTFOLIO_CONTRIBUTION',
  'PERFORMANCE_SUMMARY',
];
export const MAX_SUBJECT_SIZE = 10;
export const MAX_SUBJECTS_SIZE_INCREASED = 30;
export const RISK_FREE_RATE_FACTOR_ID = '-3';
export const RESIDUAL_FACTOR_ID = '-1';
export const TOTAL_FACTOR_ID = '-2';
export const DEFAULT_CONFIDENCE_LEVELS_VISIBILITY = [true, true, true, true, true];

/** The maximum count of each kind of input. e.g. there can be this many subject groups, this many date ranges, and this many benchmarks. */
export const MAX_INPUTS_PER_KIND = 30;
/** A subject group can contain this many subjects. */
export const MAX_SUBJECT_GROUP_SIZE = 30;
/** A warning will be display when subject group exceeds this size. */
export const WARNING_SUBJECT_GROUP_SIZE = 10;
/** The number of subject input groups that can be added to a block. */
export const MAX_BLOCK_SUBJECT_INPUTS = 5;
