import type { ReactNode } from 'react';
import React from 'react';
import styled from 'styled-components';
import { Icon } from '../icon/Icon';
import { Button } from '../button/Button';
import { analyticsService } from 'venn-utils';
import { Link } from 'react-router-dom';

export interface ButtonLinkProps {
  linkText: ReactNode;
  className?: string;
  to?: string; // If set, the button will be rendered as a react-router link to provide in-app anchor tags
  url?: string;
  dominant?: boolean; // Defaults to true
  flat?: boolean; // Defaults to false
  ctaTrackingOptions?: {
    ctaDestination: string;
    ctaPurpose: string;
    ctaText: string;
  };
}

const ButtonLink: React.FC<React.PropsWithChildren<ButtonLinkProps>> = ({
  className = '',
  ctaTrackingOptions,
  linkText,
  to,
  url,
  dominant = false,
  flat = false,
}) => {
  const trackLinkClick = () => {
    if (!ctaTrackingOptions) {
      return;
    }
    const { ctaDestination, ctaPurpose, ctaText } = ctaTrackingOptions;
    const analyticsOpts = {
      destination: ctaDestination,
      text: ctaText,
      purpose: ctaPurpose,
      type: 'button',
      filled: dominant,
    };
    analyticsService.ctaClicked(analyticsOpts);
  };

  if (!url && !to) {
    return null;
  }

  const button = (
    <Button dominant={dominant} onClick={trackLinkClick} flat={flat}>
      {url ? <StyledIcon type="external-link" /> : null}
      {linkText}
    </Button>
  );

  if (url) {
    return (
      <a href={url} className={className} target="_blank" rel="noopener noreferrer">
        {button}
      </a>
    );
  }
  // Internal links are not opened in a new window
  return to ? (
    <Link to={to} className={className}>
      {button}
    </Link>
  ) : (
    button
  );
};

const StyledIcon = styled(Icon)`
  padding-right: 5px;
`;

export default ButtonLink;
