import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import React from 'react';
import { PrivateAssetLibraryRoute, ReturnsDataLibraryRoute, useHasFF } from 'venn-utils';
import styled from 'styled-components';
import RedirectWithQueryParams from '../../venn-shell/src/components/RedirectWithQueryParams';
import ReturnsDataLibrary from './ReturnsDataLibrary';
import PrivateAssetLibrary from './PrivateAssetLibrary';

const Library = () => {
  const shouldRevealPrivates = useHasFF('privates_reveal_ff');
  const userHasPrivates = useHasFF('private_analytics');
  return (
    <MainContainer>
      {shouldRevealPrivates ? (
        <Switch>
          <Route path={ReturnsDataLibraryRoute}>
            <ReturnsDataLibrary />
          </Route>
          {userHasPrivates ? (
            <Route path={PrivateAssetLibraryRoute}>
              <PrivateAssetLibrary />
            </Route>
          ) : (
            <Redirect exact from={PrivateAssetLibraryRoute} to={ReturnsDataLibraryRoute} />
          )}
          <RedirectWithQueryParams exact from="/libraries" to={ReturnsDataLibraryRoute} />
        </Switch>
      ) : (
        // before privates are revealed, show everything as before
        <ReturnsDataLibrary />
      )}
    </MainContainer>
  );
};

const MainContainer = styled.div`
  padding-left: 40px;
  padding-right: 40px;
`;

export default withRouter(Library);
