import React, { useCallback } from 'react';
import { AssetTypeEnum } from 'venn-api';
import styled from 'styled-components';
import SimpleFilter from './SimpleFilter';
import { FundUtils, useHasFF } from 'venn-utils';
import compact from 'lodash/compact';
import { getAppTitle, GetColor, Icon, NewBadge } from 'venn-ui-kit';

const fieldName = 'Asset Type';

interface AssetTypeFilterProps {
  className?: string;
  onFilter: (updated: AssetTypeEnum[]) => void;
  selected: AssetTypeEnum[];
  closeOnOnlyClick?: boolean;
  isAdvanced?: boolean;
  menuWidth?: number;
}

const AssetTypeFilter = ({
  className,
  selected,
  onFilter,
  closeOnOnlyClick,
  isAdvanced,
  menuWidth,
}: AssetTypeFilterProps) => {
  const hasCanadaRestriction = useHasFF('canada_restriction');
  const FILTERED_TYPES: AssetTypeEnum[] = compact([
    AssetTypeEnum.ETF,
    hasCanadaRestriction ? undefined : AssetTypeEnum.STOCK,
    AssetTypeEnum.MUTUAL_FUND,
    AssetTypeEnum.INDEX,
    AssetTypeEnum.CURRENCY,
    AssetTypeEnum.CRYPTO_REFERENCE_RATE,
    AssetTypeEnum.CATEGORY_GROUPING,
  ]);

  const getBadge = (assetType: AssetTypeEnum) => {
    switch (assetType) {
      case AssetTypeEnum.CRYPTO_REFERENCE_RATE:
        return <NewBadge />;
      case AssetTypeEnum.CATEGORY_GROUPING:
        return <InfoIcon type="info-circle" prefix="far" />;
      default:
        return undefined;
    }
  };

  const ASSET_TYPE_OPTIONS = FILTERED_TYPES.map((assetType) => ({
    label: FundUtils.getAssetTypeName(assetType),
    value: assetType,
    // can remove NewBadge by the end of the year 2022
    badge: getBadge(assetType),
    tooltip:
      assetType === AssetTypeEnum.CATEGORY_GROUPING
        ? `Investment categories are non-investable equal-weighted indices created by ${getAppTitle()} from investments in the same mutual fund, ETF, or hedge fund category.`
        : undefined,
  }));

  const handleFilter = useCallback(
    (updated: string | string[]) => {
      const updatedAssetTypeEnum = updated as AssetTypeEnum[];
      onFilter(Array.from(updatedAssetTypeEnum));
    },
    [onFilter],
  );

  return (
    <StyledFilter
      className={className}
      label={fieldName}
      items={ASSET_TYPE_OPTIONS}
      initialSelected={selected}
      menuWidth={menuWidth}
      onFilter={handleFilter}
      closeOnOnlyClick={closeOnOnlyClick}
      isAdvanced={isAdvanced}
    />
  );
};

const StyledFilter = styled(SimpleFilter)`
  display: inline-flex;
`;

export default AssetTypeFilter;

const InfoIcon = styled(Icon)`
  padding-left: 2px;
  color: ${GetColor.DarkGrey};
  line-height: normal;
  font-size: 13px;
`;
