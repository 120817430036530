import { FS } from '../feature-availability';
import type { ActionEnum } from 'venn-api';

// Only allow wealth management users to access when all conditions are met
// See VENN-22130 for details
export const isWealthManagerTabDisplayed = (hasPermission: (permission: ActionEnum) => boolean): boolean => {
  return (
    FS.hasAll(['context_switching', 'ftue_first_phase_ff', 'studio_ff', 'studio_report_editor']) &&
    hasPermission('WEALTH_ONBOARDING_FLOW')
  );
};
