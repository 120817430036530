import { pickBy } from 'lodash';
import { useContext, useEffect } from 'react';
import { AnalysisConfigContext } from '../../../contexts';

/**
 * Sync factor trend rolling result with export metadata
 * @param rollingFactorExposuresPeriod exposure regression year
 * @param rollingFactorReturnPeriod return regression year
 * @param rollingFactorRiskPeriod risk regression
 * @param isPrint not update export data in print
 */
const useTrendExportMetadata = (
  rollingFactorExposuresPeriod?: number,
  rollingFactorReturnPeriod?: number,
  rollingFactorRiskPeriod?: number,
  isPrint?: boolean,
) => {
  const { updateFactorTrendRollingResults } = useContext(AnalysisConfigContext);
  useEffect(() => {
    if (isPrint) {
      return;
    }
    const toUpdate = pickBy(
      {
        rollingFactorExposuresPeriod,
        rollingFactorReturnPeriod,
        rollingFactorRiskPeriod,
      },
      (value) => value !== undefined,
    );

    // Only update the one that has value
    updateFactorTrendRollingResults?.(toUpdate);
  }, [
    rollingFactorExposuresPeriod,
    rollingFactorReturnPeriod,
    rollingFactorRiskPeriod,
    updateFactorTrendRollingResults,
    isPrint,
  ]);
};

export default useTrendExportMetadata;
