import React from 'react';
import type { ViewPort } from './charts/types';
import {
  COLUMN_SPACING,
  TITLE_COLUMN_WIDTH,
  PRINT_TITLE_COLUMN_WIDTH,
  WIDE_VALUE_COLUMN_OFFSET,
} from './charts/constants';
import { ROW_HEIGHT, ROW_PRINT_HEIGHT } from '../factor-chart';

export interface ViewPortRendererProps {
  width: number;
  print: boolean;
  hideBaselineColumn?: boolean;
  hideComparisonColumn?: boolean;
  hideCategoryColumn?: boolean;
  render: (viewPort: ViewPort) => React.ReactNode;
}

export interface ViewPortRendererState {
  prevWidth?: number;
  prevHideBaselineColumn?: boolean;
  prevHideComparisonColumn?: boolean;
  prevHideCategoryColumn?: boolean;
  prevPrint?: boolean;
  viewPort: ViewPort;
}

/**
 * This component cacluates the correct `ViewPort` from the props passed,
 * then renders
 * the results of render(viewPort).
 */
export default class ViewPortRenderer extends React.Component<ViewPortRendererProps, ViewPortRendererState> {
  static getDerivedStateFromProps(
    props: ViewPortRendererProps,
    state: ViewPortRendererState,
  ): Partial<ViewPortRendererState> | null {
    if (
      props.width === state.prevWidth &&
      props.hideBaselineColumn === state.prevHideBaselineColumn &&
      props.hideComparisonColumn === state.prevHideComparisonColumn &&
      props.hideCategoryColumn === state.prevHideCategoryColumn &&
      props.print === state.prevPrint
    ) {
      return null;
    }

    const columnCount =
      1 +
      (props.hideBaselineColumn ? 0 : 1) +
      (props.hideComparisonColumn ? 0 : 1) +
      (props.hideCategoryColumn ? 0 : 1);

    return {
      prevWidth: props.width,
      prevHideBaselineColumn: props.hideBaselineColumn,
      prevHideComparisonColumn: props.hideComparisonColumn,
      prevHideCategoryColumn: props.hideCategoryColumn,
      prevPrint: props.print,
      viewPort: {
        rowHeight: props.print ? ROW_PRINT_HEIGHT : ROW_HEIGHT,
        padding: {
          left: (props.print ? PRINT_TITLE_COLUMN_WIDTH : TITLE_COLUMN_WIDTH) + COLUMN_SPACING,
          right: columnCount * WIDE_VALUE_COLUMN_OFFSET,
        },
        width: props.width,
      },
    };
  }

  constructor(props: ViewPortRendererProps) {
    super(props);
    this.state = {
      viewPort: {
        rowHeight: ROW_HEIGHT,
        padding: { left: 0, right: 0 },
        width: 0,
      },
    };
  }

  render() {
    return this.props.render(this.state.viewPort);
  }
}
