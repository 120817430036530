import React from 'react';
import JumboCheckboxTrigger from '../triggers/JumboCheckboxTrigger';
import type { JumboCheckboxMenuProps } from '../menus/JumboCheckboxMenu';
import { default as JumboCheckboxMenu } from '../menus/JumboCheckboxMenu';
import BaseDropMenu from './BaseDropMenu';
import type { BaseDropMenuProps, MenuContainerProps } from '../types';

interface JumboCheckboxDropMenuProps<T> extends JumboCheckboxMenuProps<T>, BaseDropMenuProps<T>, MenuContainerProps {}

export function JumboCheckboxDropMenu<T>({
  label,
  items,
  className,
  openByDefault,
  usePortal,
  disabled,
  width,
  height,
  innerRef,
  onChange,
}: JumboCheckboxDropMenuProps<T>) {
  return (
    <BaseDropMenu
      openByDefault={openByDefault}
      usePortal={usePortal}
      filteredItems={items}
      triggerComponent={(expanded, _, onToggle, handleKeyEsc, handleKeyEnter, handleKeyUp, handleKeyDown) => (
        <JumboCheckboxTrigger
          className={className}
          disabled={disabled}
          label={label}
          expanded={expanded}
          innerRef={innerRef}
          onClick={onToggle}
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
          onKeyEsc={handleKeyEsc}
          onKeyEnter={handleKeyEnter}
        />
      )}
      menuComponent={(_highlightedItem, _onCollapse, menuClassName) => (
        <JumboCheckboxMenu<T>
          className={menuClassName}
          width={width}
          height={height}
          items={items}
          onChange={onChange}
        />
      )}
    />
  );
}

export default JumboCheckboxDropMenu;
