import type { EntityPerformance } from 'venn-api';
import groupBy from 'lodash/groupBy';
import toPairs from 'lodash/toPairs';
import type { VennColors } from 'venn-ui-kit';
import { ColorUtils } from 'venn-ui-kit';
import { assertExhaustive } from 'venn-utils';
import type { FactorCategory } from 'src/factor-lens-page/src/types';

class LegendItem {
  lines: Partial<EntityPerformance>[];

  color: string;

  constructor(
    public name: string,
    public baseColor: string,
    public opacity: number = 1,
    public italic: boolean = false,
  ) {
    this.lines = [];
    this.color = ColorUtils.opacify(baseColor, opacity);
  }
}

const getColorForCategory = (category: string, colors: VennColors) => {
  const castedCategory = category as FactorCategory;
  switch (castedCategory) {
    case 'Core Macro':
      return colors.StudioSubjectColor.A1;
    case 'Secondary Macro':
      return colors.StudioSubjectColor.A2;
    case 'Macro Styles':
      return colors.StudioSubjectColor.A3;
    case 'Equity Styles':
      return colors.StudioSubjectColor.A4;
    default:
      throw assertExhaustive(castedCategory);
  }
};

export default (data: Partial<EntityPerformance>[], colors: VennColors): LegendItem[] => {
  const results: LegendItem[] = [];

  // Factor series
  const categories = toPairs(
    groupBy(
      data.filter((item) => item.type === 'FACTOR'),
      'category',
    ),
  );
  categories.forEach(([category, items]) => {
    items.forEach((item, itemIndex) => {
      const legend = new LegendItem(item.name!, getColorForCategory(category, colors), 1 - itemIndex / items.length);
      legend.lines.push(item);
      results.push(legend);
    });
  });

  // Factor inputs (if any)
  const factorInputs = data.filter((item) => item.type === 'FACTOR_INPUT');
  if (factorInputs.length) {
    // In case if there are any input factors we can get the name
    // of only category selected and use it in getColor method
    const factorInputsColor = colors.DataLineColor.Green;
    const singleSelectedFactorColor = categories[0] ? getColorForCategory(categories[0][0], colors) : factorInputsColor;
    const factorInputLegend = new LegendItem('Factor Input', singleSelectedFactorColor, 0.4);
    factorInputs.forEach((factorInput) => {
      factorInputLegend.lines.push(factorInput);
    });
    results.push(factorInputLegend);
  }

  // Fund / Portfolio (if any)
  const subject = data.find((item) => item.type === 'FUND' || item.type === 'PORTFOLIO');
  if (subject) {
    const subjectColor = colors.StudioSubjectColor.A8;
    const subjectLegend = new LegendItem(subject.name!, subjectColor, 1, true);
    subjectLegend.lines.push(subject);
    results.push(subjectLegend);
  }

  return results;
};
