import React from 'react';
import styled from 'styled-components';

import { GetColor } from '../../style';
import Icon from '../icon/Icon';

const EmptyWorkspaceIcon = () => (
  <Relative>
    <BackgroundInvestmentIcon type="square" />
    <BackgroundPortfolioIcon type="th" />
    <InvestmentIcon type="square" />
    <CalculatorIcon type="calculator" />
    <PortfolioIcon type="th" />
  </Relative>
);

export default EmptyWorkspaceIcon;

const InvestmentIcon = styled(Icon)`
  transform: rotate(-10deg);
  font-size: 30px;
  color: ${GetColor.HighlightLight};
`;

const CalculatorIcon = styled(Icon)`
  font-size: 50px;
  color: ${GetColor.HighlightDark};
  margin: 0 10px;
`;

const PortfolioIcon = styled(Icon)`
  transform: rotate(10deg);
  font-size: 30px;
  color: ${GetColor.HighlightLight};
`;

const Relative = styled.div`
  position: relative;
`;

const BackgroundInvestmentIcon = styled(InvestmentIcon)`
  font-size: 110px;
  opacity: 0.08;
  right: calc(50% - 10px);
  position: absolute;
  bottom: -30px;
`;

const BackgroundPortfolioIcon = styled(PortfolioIcon)`
  font-size: 120px;
  opacity: 0.08;
  left: calc(50% - 10px);
  position: absolute;
  bottom: -15px;
`;
