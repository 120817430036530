import type { FunctionComponent, ReactNode } from 'react';
import React from 'react';
import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';

export interface ScrollableProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  height?: string;
}

const StyledScrollable = styled.div<ScrollableProps>`
  overflow: auto;
  -ms-overflow-style: scrollbar;
  height: ${(props: ScrollableProps) => (props.height ? props.height : '100%')};
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: ${GetColor.PaleGrey};
  }
  ::-webkit-scrollbar-thumb {
    background: ${GetColor.MidGrey2};
  }
`;

/**
 * Scrollable section.
 *
 * Any props will be passed directly to scrollable.
 */
export const Scrollable: FunctionComponent<React.PropsWithChildren<ScrollableProps>> = (props: ScrollableProps) => (
  <StyledScrollable {...props} className={`${props.className} scrollable`}>
    {props.children}
  </StyledScrollable>
);

export default Scrollable;
