import React from 'react';
import styled from 'styled-components';
import type { SearchMenuItem } from 'venn-components';
import { SearchMenuBar } from 'venn-components';
import { ExternalActivityListener, RelativePortal } from 'venn-ui-kit';
import type { AnalysisSubject } from 'venn-utils';
import { analyticsService, useHasFF } from 'venn-utils';
import { getSubjectId, type Subject } from 'venn-state';
import type { ItemId } from 'venn-api';

export const SINGLE_SUBJECT_POPOVER_MENU_BAR_CLASS_NAME = 'singleSubjectSearchModal-searchMenuBar';

/** A modal containing search functionality for subjects, that closes once a single subject is clicked or the user clicks outside the menu. */
// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export const SingleSubjectSearchPopover = React.memo(
  ({
    onSubjectSelected,
    onClose,
    currentLocationForAnalytics,
    autofocus,
    value,
    excludedSubjects = [],
    leftOffset,
  }: {
    onSubjectSelected: (selectedSubject: AnalysisSubject) => void;
    onClose: () => void;
    currentLocationForAnalytics: string;
    value?: SearchMenuItem | null;
    autofocus?: boolean;
    excludedSubjects?: Subject[];
    leftOffset?: number;
  }) => {
    const hasPrivateAnalytics = useHasFF('private_analytics');
    const searchMenuBarClassName = SINGLE_SUBJECT_POPOVER_MENU_BAR_CLASS_NAME;
    const excludedItems: ItemId[] = excludedSubjects.map((subject) => ({
      id: getSubjectId(subject),
      // [HACK] privates are not supported by autocomplete service, but for this purpose it's sufficient
      // as both private funds and portfolios use UUID as their identifier
      type: subject.fundId || subject.privateFundId || subject.privatePortfolioId ? 'FUND' : 'PORTFOLIO',
    }));
    return (
      <Anchor>
        <ExternalActivityListener onExternalActivity={onClose} ignoreActivityFromClassName={searchMenuBarClassName}>
          <RelativePortal leftOffset={leftOffset}>
            <SearchWrapper>
              <SearchMenuBar
                onBlur={onClose}
                className={searchMenuBarClassName}
                onSelected={(selectedItem) => {
                  onClose();
                  if (!selectedItem.value) {
                    return;
                  }
                  onSubjectSelected(selectedItem.value);

                  analyticsService.navigationTriggered({
                    itemType: 'dropdown',
                    userIntent: 'change object',
                    destinationPageTitle: currentLocationForAnalytics,
                  });
                }}
                location={currentLocationForAnalytics}
                value={value}
                autofocus={autofocus}
                excludedItems={excludedItems}
                privateAssetSearchMode={hasPrivateAnalytics ? 'ALL' : 'PUBLIC_ONLY'}
              />
            </SearchWrapper>
          </RelativePortal>
        </ExternalActivityListener>
      </Anchor>
    );
  },
);

const Anchor = styled.div`
  position: absolute;
  top: 0;
`;

const SearchWrapper = styled.div`
  width: 700px;
  font-weight: normal;
  font-size: 14px;
`;
