// Adapted from https://github.com/sunify/react-relative-portal

import type { CSSProperties } from 'react';
import React from 'react';
import styled from 'styled-components';
import Portal from './Portal';
import { useRect } from 'venn-utils';
import { getPageOffset } from './utils';
import { ZIndex } from '../../zIndexValues';

export interface RelativePortalProps {
  rightOffset?: number;
  leftOffset?: number;
  fullWidth?: boolean;
  fullHeight?: boolean;
  topOffset?: number;
  expectedHeight?: number;
  children: React.ReactNode;
  onOutClick?: () => void;
  component?: keyof HTMLElementTagNameMap;
  style?: CSSProperties;
  className?: string;
  /**
   * The position is by default calculated based on the parent component.
   * Specifying this prop will make the position of the portal be relative to the given element.
   */
  relativeElement?: Element;
}

/**
 * Renders the contents as a modal inside of a portal at the window level, positioned relative to the react component container.
 * */
export const RelativePortal = ({
  component = 'span',
  topOffset = 0,
  leftOffset = 0,
  rightOffset,
  fullWidth,
  fullHeight,
  expectedHeight = 30, // This at least prevents the pop-up from going completely below the window
  style = {},
  className,
  onOutClick,
  children,
  relativeElement,
}: React.PropsWithChildren<RelativePortalProps>) => {
  const [rect, ref] = useRect<HTMLDivElement>();
  const pageOffset = getPageOffset();

  const getPositionStyle = () => {
    const relativeRect = relativeElement?.getBoundingClientRect() ?? rect;
    const top =
      topOffset + pageOffset.y + Math.max(0, Math.min(relativeRect?.top || 0, window.innerHeight - expectedHeight));
    const right = (rightOffset || 0) + document.documentElement.clientWidth - (relativeRect?.right || 0) - pageOffset.x;
    const left = leftOffset + pageOffset.x + (relativeRect?.left || 0);

    return {
      top,
      ...(fullWidth || rightOffset === undefined ? { left } : {}),
      ...(fullWidth || rightOffset !== undefined ? { right } : {}),
      ...(fullHeight ? { height: `calc(100vh - ${top}px)` } : {}),
    };
  };

  const portalContent = rect ? (
    <Portal onOutClick={onOutClick}>
      <PortalDiv
        style={{
          ...getPositionStyle(),
          ...style,
        }}
        className={className}
      >
        {children}
      </PortalDiv>
    </Portal>
  ) : null;

  return React.createElement(component, { ref }, portalContent);
};

const PortalDiv = styled.div`
  position: absolute;
  z-index: ${ZIndex.ModalFront};
  @media print {
    display: none;
  }
`;
