/* eslint-disable:max-line-length */
import React from 'react';

const WarningIcon = () => (
  <svg width="26" height="26" xmlns="http://www.w3.org/2000/svg" data-testid="warn-icon">
    <path
      fill="#757575"
      d="M25.22 20.192L14.894 3.07a2.273 2.273 0 0 0-1.936-1.093c-.788 0-1.53.419-1.937 1.093L.698 20.192a2.265 2.265 0 0 0-.033 2.28c.4.709 1.155 1.15 1.97 1.15h20.648c.814 0 1.569-.441 1.969-1.15.4-.71.388-1.583-.033-2.28zm-1.395 1.474a.624.624 0 0 1-.542.316H2.634a.623.623 0 0 1-.533-.943L12.425 3.916a.626.626 0 0 1 .534-.3c.216 0 .42.115.533.3L23.816 21.04c.116.192.12.432.009.627z"
      stroke="null"
    />
    <path
      fill="#757575"
      d="M12.888 7.965c-.673 0-1.198.36-1.198 1.001 0 1.954.23 4.762.23 6.716 0 .509.443.722.968.722.394 0 .953-.213.953-.722 0-1.954.23-4.762.23-6.716 0-.64-.542-1.001-1.183-1.001zM12.88 17.609c-.83 0-1.452.66-1.452 1.451 0 .773.622 1.452 1.451 1.452.773 0 1.433-.679 1.433-1.452 0-.792-.66-1.451-1.433-1.451z"
      stroke="null"
    />
  </svg>
);

export default WarningIcon;
