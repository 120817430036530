import React from 'react';
import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';
import { SpecialCssClasses } from 'venn-utils';

interface SorTableField {
  content: React.ReactNode;
  value: number;
}

type SorTableFeedRow<KEYS extends string> = { [id in KEYS]: SorTableField };

type SorTableFeed<KEYS extends string> = SorTableFeedRow<KEYS>[];

interface SorTableColumn<KEYS extends string> {
  key: KEYS;
  label?: string;
  width: number | string;
}

interface SorTableProps<KEYS extends string> {
  columns: SorTableColumn<KEYS>[];
  children?: SorTableFeed<KEYS>;
  className?: string;
}

export enum SorTableDirection {
  UP,
  DOWN,
}

interface SorTableOrder<KEYS extends string> {
  field: KEYS;
  direction: SorTableDirection;
}

interface SorTableState<KEYS extends string> {
  order: SorTableOrder<KEYS> | null;
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RowFirst = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  user-select: none;
`;

export const RowNext = styled(RowFirst)<{ order?: number }>`
  border-top: 1px solid ${GetColor.Grey};
  ${({ order }) => (order != null ? `order: ${order};` : '')};
`;

export const SortDirection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Cell = styled.div`
  display: flex;
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  width: ${(props: { width: number | string }) => props.width};
  padding: 10px;
  ${(props) => (props.onClick ? 'cursor: pointer;' : '')};
`;

export const CellHeader = styled(Cell)`
  font-family: ${(props) => props.theme.Typography.fontFamily};
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #999999;
`;

export const CellData = styled(Cell)`
  font-family: ${(props) => props.theme.Typography.fontFamily};
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: ${GetColor.Black};
`;

const IconPositioned = styled.div`
  display: inline-block;
  margin-left: 5px;
`;

export class SorTable<KEYS extends string = string> extends React.Component<SorTableProps<KEYS>, SorTableState<KEYS>> {
  static preSort = preSortContent;

  constructor(props: SorTableProps<KEYS>) {
    super(props);
    this.state = {
      order: null,
    };
  }

  render() {
    const { children = [], className } = this.props;
    const isReversed = this.state.order != null && this.state.order.direction === SorTableDirection.DOWN;
    const rowCount = children.length;

    return (
      <div className={className}>
        <Root>
          <RowFirst>
            {fragment(
              this.props.columns.map((col) => (
                <CellHeader
                  width={col.width}
                  onClick={() => {
                    this.setState((prev) => ({
                      order:
                        prev.order == null ||
                        (prev.order.field === col.key && prev.order.direction === SorTableDirection.UP)
                          ? {
                              field: col.key,
                              direction: SorTableDirection.DOWN,
                            }
                          : { field: col.key, direction: SorTableDirection.UP },
                    }));
                  }}
                >
                  {col.label}
                  {col.label && (
                    <IconPositioned>
                      <i className={`${this.getIcon(col.key)} ${SpecialCssClasses.NotDownloadable}`} />
                    </IconPositioned>
                  )}
                </CellHeader>
              )),
            )}
          </RowFirst>
          <SortDirection className={SpecialCssClasses.DownloadableHeightFitContent}>
            {fragment(
              children instanceof Array
                ? children.map((row) => (
                    <RowNext
                      {...(this.state.order
                        ? {
                            order: isReversed
                              ? rowCount - row[this.state.order.field].value
                              : row[this.state.order.field].value,
                          }
                        : {})}
                    >
                      {fragment(
                        this.props.columns.map((col) => (
                          <CellData key={col.key} width={col.width}>
                            {row[col.key].content}
                          </CellData>
                        )),
                      )}
                    </RowNext>
                  ))
                : [],
            )}
          </SortDirection>
        </Root>
      </div>
    );
  }

  private getIcon = (columnKey: string) => {
    const { order } = this.state;

    if (!order || order.field !== columnKey) {
      return 'fa fa-sort';
    }

    return order.direction === SorTableDirection.UP ? 'fa fa-sort-up' : 'fa fa-sort-down';
  };
}

const fragment = (children: React.ReactNode[]) => React.createElement(React.Fragment, null, ...children);

/**
 * Static method that prepares the input data to be used in SorTable, as
 * component uses flexbox order property for sorting. Basically this method
 * overrides the `value` property for each entry in column, with it's position
 * in sorted list of all values in column of table. Reversing of column order is
 * done with toggling `flex-direction` between `column` and `reverse-column`.
 *
 * @param tableContent data source in form of array of object that have
 * `content` and `value` properties.
 * @param {({ [id in K]: 'string' | 'number' })} sortStrategy object with
 * description of type of properties of tableContent item for sorting.
 */
function preSortContent<K extends string>(
  tableContent: Record<
    K,
    {
      content: React.ReactNode;
      value: string | number;
    }
  >[],
  sortStrategy: { [id in K]: 'string' | 'number' },
): Record<K, SorTableField>[] {
  const keys: K[] = uniq(tableContent.flatMap((row) => Object.keys(row) as K[]));

  const sorted = flattenMap(
    keys.map((key: K): Partial<Record<K, (string | number)[]>> => {
      const columnValues: (string | number)[] = tableContent.map((row) => row[key].value);

      function caseInsensitiveSortPredicate(a: string, b: string) {
        const normalizedA = a.toLowerCase();
        const normalizedB = b.toLowerCase();
        return normalizedA === normalizedB ? 0 : normalizedA > normalizedB ? 1 : -1;
      }

      const columnValuesSorted: (string | number)[] =
        sortStrategy[key] === 'string'
          ? (columnValues as string[]).sort(caseInsensitiveSortPredicate)
          : sortStrategy[key] === 'number'
            ? (columnValues as number[]).sort((a, b) => a - b)
            : columnValues;

      return { [key]: columnValuesSorted } as Partial<Record<K, (string | number)[]>>;
    }),
  );

  return tableContent.map((row) =>
    flattenMap(
      keys.map(
        (key) =>
          ({
            [key]: {
              content: row[key].content,
              value: sorted[key].indexOf(row[key].value),
            },
          }) as Partial<Record<K, SorTableField>>,
      ),
    ),
  );
}

function uniq<T>(input: T[]): T[] {
  return [...new Set<T>(input)];
}

/** Warning: This isn't entirely safe as it does nothing to ensure that the Partial<T> inputs combine to be a full T. */
function flattenMap<T>(input: Partial<T>[]): T {
  return Object.assign({}, ...input);
}

export default SorTable;
