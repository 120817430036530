import type { CSSProperties } from 'react';
import React, { useContext, useMemo } from 'react';
import { SORTDIR } from '../basictable/BasicTable';
import type { Entity, Correlation } from './types';
import BaseCorrelationChart from '../correlation-chart/BaseCorrelationChart';
import type { HeatMapTypes, XAxisLabel } from '../heat-map/Types';
import { generateColor } from '../heat-map/utils';
import defaultTooltipFormatter from './CorrelationTooltip';
import type { FactorPerformanceTypeEnum } from 'venn-api';
import { isNil } from 'lodash';
import { ThemeContext } from 'styled-components';
import type { AnyDuringEslintMigration } from 'venn-utils';

export interface FactorCorrelationsChartProps<T extends Correlation> {
  data: Entity<T>[];
  labelFormatter?: (id: string, type?: FactorPerformanceTypeEnum) => CSSProperties;
  sortKey?: string;
  sortDir?: SORTDIR;
  width: number;
  tooltipFormatter?: (
    factor: HeatMapTypes.Root,
    compareFactor: HeatMapTypes.Root,
    value?: number,
  ) => JSX.Element | null;
  showValues?: boolean;
}
const defaultLabelFormatter = () => ({});

function sortData<T extends Correlation>(data: Entity<T>[], sortKey?: string, sortDir?: SORTDIR) {
  if (!sortKey) {
    return data;
  }
  return data.slice(0).sort((a, b) => {
    if (sortDir === SORTDIR.DESC) {
      return a[sortKey] < b[sortKey] ? 1 : -1;
    }
    return a[sortKey] < b[sortKey] ? -1 : 1;
  });
}

const VALUE_ON_DIAGONAL = 1;

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default <T extends Correlation>({
  data,
  sortKey,
  sortDir,
  width,
  labelFormatter = defaultLabelFormatter,
  // @ts-expect-error: TODO fix strictFunctionTypes
  tooltipFormatter = defaultTooltipFormatter,
  showValues,
}: FactorCorrelationsChartProps<T>) => {
  const { Colors } = useContext(ThemeContext);
  const [roots, nodeNames]: [HeatMapTypes.Root[], XAxisLabel[]] = useMemo(() => {
    const colorGetter = generateColor(-1.0, 1.0, Colors);
    const sortedData = sortData(data, sortKey, sortDir);
    return [
      sortedData.map(({ id, name, correlations, type }: Entity<T>, idx: number) => ({
        factorId: isNil(id) || Number.isNaN(Number.parseInt(id, 10)) ? undefined : Number.parseInt(id, 10),
        name,
        type: type ?? 'UNKNOWN',
        series: [
          {
            name,
            data: sortedData
              .slice(0, idx)
              .map((correlatedFactor) => {
                const value =
                  correlatedFactor?.correlations?.find((correlation) => correlation?.id === id)?.value ||
                  correlations?.find((correlation) => correlation?.id === correlatedFactor?.id)?.value;
                return {
                  value,
                  nonSignificant: false,
                  color: colorGetter(value && (value.toFixed(6) as AnyDuringEslintMigration)) ?? 'none',
                };
              })
              .concat(
                ...[
                  {
                    value: VALUE_ON_DIAGONAL,
                    nonSignificant: false,
                    color: colorGetter(VALUE_ON_DIAGONAL) ?? 'none',
                  },
                ],
              ),
            colors: { positive: Colors.DivergingColor.B3, negative: Colors.DivergingColor.A3 },
          },
        ],
      })),
      sortedData.map(({ name, type, id }) => ({ name, type, id })),
    ];
  }, [data, sortKey, sortDir, Colors]);

  if (roots.length === 0 || nodeNames.length === 0) {
    return null;
  }

  return (
    <BaseCorrelationChart
      showValues={showValues}
      loading={false}
      width={width}
      data={roots}
      seriesDataSize={nodeNames ? nodeNames.length : 0}
      nodeNames={nodeNames}
      verticalLabelHeight={180}
      // @ts-expect-error: TODO fix strictFunctionTypes
      labelFormatter={(factorId: string, type: FactorPerformanceTypeEnum) => labelFormatter(factorId, type)}
      tooltipRenderer={(columnId, rowId) =>
        tooltipFormatter(roots[rowId], roots[columnId], roots?.[rowId]?.series?.[0]?.data?.[columnId]?.value)
      }
    />
  );
};
