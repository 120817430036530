import React, { Component } from 'react';
import styled from 'styled-components';
import { get } from 'lodash';
import { ExternalActivityListener } from 'venn-ui-kit';
import FactorPopoverRenderer from './FactorPopoverRenderer';
import { Provider } from './context';
import type { AnalysisSubject, TimeFrame } from 'venn-utils';
import { capitalizeFirstLetter } from 'venn-utils';

const Wrapper = styled.div`
  position: relative;
`;

export interface PopoverDataType {
  position: { x: number; y: number; tooltipRight?: boolean };
  timestamp?: number;
  factorName: string;
  factorId?: number;
  titleData: {
    factorType: string;
    factorValue?: number | null;
    regressionYears: number | undefined;
    factorSignificant: boolean | undefined;
  };
}

export type ShowPopoverFuncType = (options: PopoverDataType) => void;

interface Props {
  children: (fn: ShowPopoverFuncType) => React.ReactNode;
  usePercentages?: boolean;
  relative: boolean;
  categoryActive: boolean;
  analysisSubject: AnalysisSubject;
  actualTimeFrame?: TimeFrame;
  activeFactorLens?: string;
  updateActiveFactorId?: (activeFactorId: number) => void;
}

export interface State {
  isPopoverVisible: boolean;
  isModalOpen: boolean;
  popoverData: PopoverDataType;
}

class FactorPopover extends Component<Props, State> {
  state = {
    isPopoverVisible: false,
    isModalOpen: false,
    popoverData: {
      position: { x: 0, y: 0, tooltipRight: false },
      timestamp: 0,
      factorName: '',
      factorId: -1,
      titleData: {
        factorType: '',
        factorValue: 0,
        regressionYears: 0,
        factorSignificant: false,
      },
    },
  };

  showPopover: ShowPopoverFuncType = (popoverData) => {
    this.setState({ isPopoverVisible: true, popoverData });
  };

  hidePopover = () => {
    this.setState({ isPopoverVisible: false });
    if (this.props.updateActiveFactorId) {
      this.props.updateActiveFactorId(0);
    }
  };

  openModal = () => this.setState({ isModalOpen: true });

  closeModal = () => {
    this.setState({ isModalOpen: false, isPopoverVisible: false });
    if (this.props.updateActiveFactorId) {
      this.props.updateActiveFactorId(0);
    }
  };

  render() {
    const { popoverData, isPopoverVisible, isModalOpen } = this.state;
    const { children, usePercentages, relative, categoryActive, analysisSubject, activeFactorLens, actualTimeFrame } =
      this.props;
    const type = get(popoverData, 'titleData.factorType');
    const displayTitle = relative ? `Active ${type} contribution` : `${capitalizeFirstLetter(type)} contribution`;
    return (
      <Wrapper>
        {isPopoverVisible && (
          <Provider
            value={{
              isModalOpen,
              title: displayTitle,
              openModal: this.openModal,
              closeModal: this.closeModal,
              activeFactorLens,
            }}
          >
            <ExternalActivityListener
              key={popoverData.position.x + popoverData.position.y}
              listeningEnabled={!isModalOpen}
              onExternalActivity={this.hidePopover}
            >
              <FactorPopoverRenderer
                actualTimeFrame={actualTimeFrame}
                top={popoverData.position.y}
                left={popoverData.position.x}
                tooltipRight={popoverData.position.tooltipRight}
                onClose={this.hidePopover}
                relative={relative}
                categoryActive={categoryActive}
                usePercentages={!!usePercentages}
                analysisSubject={analysisSubject}
                timestamp={popoverData.timestamp}
                factorId={`${popoverData.factorId}`}
                factorName={popoverData.factorName}
                factorType={type}
                factorValue={popoverData.titleData.factorValue}
                factorSignificant={popoverData.titleData.factorSignificant}
                regressionYears={popoverData.titleData.regressionYears}
              />
            </ExternalActivityListener>
          </Provider>
        )}
        {children(this.showPopover)}
      </Wrapper>
    );
  }
}

export default FactorPopover;
