import { useCallback, useState } from 'react';

function useAsyncQueue() {
  const [, setQueue] = useState<Promise<void>>(Promise.resolve());

  const addToQueue = useCallback((item: () => Promise<void>) => setQueue((prev) => prev.then(item)), []);

  return addToQueue;
}

export default useAsyncQueue;
