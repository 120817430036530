import React from 'react';
import { noop } from 'lodash';
import type {
  ActionEnum,
  AuthenticationErrorType,
  Portfolio,
  TableWorkspace,
  TypedUserSettings,
  UserProfileSettings,
  UserSettings,
} from 'venn-api';
import type { CustomColors } from 'venn-ui-kit';
import { asyncNoop } from 'venn-utils';

export interface UserContextActions {
  refresh: () => void;
  /** This function is asynchronous, but legacy code prevents us from typing it as Promise<void> */
  updateSettings: (settings: TypedUserSettings) => void;
  setUserCompletedUpload: (completed: boolean) => void;
  hasPermission: (permission: ActionEnum) => boolean;
  debugForcePermission: (permission: ActionEnum, enabled: boolean) => void;
  hasDebugOverride: (permission: ActionEnum) => boolean;
  hasPermissionForResource: (permission: ActionEnum, resource: { ownerContextId?: string }) => boolean;
  canWriteToPortfolio: (portfolio: Portfolio) => boolean;
  setCurrentContext: (newContext: string) => void;
  saveCustomColors: (customColors: CustomColors | undefined) => Promise<void>;
}

export interface UserContextState {
  profileSettings?: UserProfileSettings;
  organizations: TableWorkspace[];
  settings: UserSettings;
  error?: {
    errorType: AuthenticationErrorType;
    message: string;
  };
  currentContext?: string;
  loading: boolean;
  hasCompletedAnUpload: boolean;
  hasOrgCompleteAnUpload: boolean;
}

export type UserContextValue = UserContextActions & UserContextState;

const defaultUserContextValue: UserContextValue = {
  profileSettings: undefined,
  organizations: [],
  loading: false,
  refresh: noop,
  error: undefined,
  settings: {
    system: {},
    user: {},
  },
  updateSettings: noop,
  hasCompletedAnUpload: false,
  hasOrgCompleteAnUpload: false,
  setUserCompletedUpload: noop,
  hasPermission: () => true,
  debugForcePermission: () => {},
  hasDebugOverride: () => false,
  setCurrentContext: noop,
  hasPermissionForResource: () => true,
  canWriteToPortfolio: () => true,
  saveCustomColors: asyncNoop,
};
// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
const userContext = React.createContext<UserContextValue>(defaultUserContextValue);

export default userContext;
