import type { FC } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { ColorUtils, GetColor } from '../../style';
import { Tooltip } from '../tooltip/Tooltip';
import styled, { css } from 'styled-components';
import type { FontAwesomePrefixes } from 'venn-utils';
import { analyticsService, buttonize } from 'venn-utils';
import { TooltipBodyDirection, TooltipPosition } from '../../components/enums';
import Icon from '../icon/Icon';
import { destructiveCSS, dominantCSS } from '../button/Button';

export interface ButtonIconProps {
  iconType?: string;
  /** [Font Awesome](https://fontawesome.com/) prefixes */
  iconPrefix?: FontAwesomePrefixes;
  customIcon?: React.ReactNode;
  to?: string;
  tooltip?: React.ReactNode;
  tooltipMaxWidth?: number;
  border?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
  tooltipPosition?: TooltipPosition;
  tooltipDirection?: TooltipBodyDirection;
  ctaTrackingOptions?: {
    ctaDestination: string;
    ctaPurpose: string;
    ctaText: string;
  };
  size?: number;
  dominant?: boolean;
  destructive?: boolean;
  active?: boolean;
  text?: string;
  textTransform?: string;
  textFontSize?: number;
  iconFontSize?: number;
  wrapperClassName?: string;
  testId?: string;
  usePortal?: boolean;
}

interface StyleProps {
  border: boolean;
  dominant: boolean;
  disabled: boolean;
  size: number;
  destructive: boolean;
  active: boolean;
  isText: boolean;
}

const ButtonIcon: FC<React.PropsWithChildren<ButtonIconProps>> = ({
  iconType,
  iconPrefix,
  customIcon,
  to,
  tooltip,
  tooltipMaxWidth,
  border = false,
  disabled = false,
  onClick,
  ctaTrackingOptions,
  className,
  tooltipPosition = TooltipPosition.Left,
  tooltipDirection = TooltipBodyDirection.Center,
  size = 40,
  dominant = false,
  destructive = false,
  active = false,
  text,
  textTransform = 'uppercase',
  textFontSize,
  iconFontSize,
  wrapperClassName,
  testId = 'buttonIcon',
  usePortal,
}) => {
  const onClickAndTrack = () => {
    onClick && onClick();

    if (!ctaTrackingOptions) {
      return;
    }
    const { ctaDestination, ctaText, ctaPurpose } = ctaTrackingOptions;
    const analyticsOpts = {
      destination: ctaDestination,
      text: ctaText,
      purpose: ctaPurpose,
      type: 'button',
      filled: dominant,
    };
    analyticsService.ctaClicked(analyticsOpts);
  };

  return (
    <Wrapper disabled={disabled} className={wrapperClassName}>
      <Tooltip
        maxWidth={tooltipMaxWidth ?? 170}
        content={tooltip}
        position={tooltipPosition}
        bodyDirection={tooltipDirection}
        usePortal={usePortal}
      >
        <IconWrapper
          {...(!disabled && buttonize(onClickAndTrack, !!to))}
          data-testid={testId}
          disabled={disabled}
          $styleProps={{
            border,
            disabled,
            size,
            dominant,
            destructive,
            active,
            isText: !!text,
          }}
          className={classNames(className, 'button-icon-wrapper')}
          as={to && !disabled ? Link : undefined}
          to={to!}
          iconFontSize={iconFontSize}
        >
          {customIcon || <Icon type={iconType} prefix={iconPrefix} />}
          {text && (
            <Text textFontSize={textFontSize} textTransform={textTransform}>
              {text}
            </Text>
          )}
        </IconWrapper>
      </Tooltip>
    </Wrapper>
  );
};

export default ButtonIcon;

const Wrapper = styled.div<{ disabled?: boolean }>`
  ${(props) => props.disabled && 'cursor: not-allowed;'}
`;

const Text = styled.span<{ textFontSize?: number; textTransform?: string }>`
  font-size: ${({ textFontSize }) => textFontSize ?? 14}px;
  text-transform: ${({ textTransform }) => textTransform ?? 'uppercase'};
  font-weight: bold;
  padding-left: 5px;
`;

const IconWrapper = styled.span<{
  $styleProps: StyleProps;
  disabled: boolean;
  iconFontSize?: number;
}>`
  ${({ $styleProps }) =>
    $styleProps.border &&
    css`
      border: 1px solid ${GetColor.Primary.Dark};
    `}
  text-align: center;
  cursor: pointer;
  color: ${GetColor.Primary.Dark};
  transition: all 0.2s ease-out;
  border-radius: 2px;
  font-size: ${({ iconFontSize }) => iconFontSize ?? 18}px;
  ${({ $styleProps }) => ($styleProps.isText ? 'padding: 0 5px;' : `width: ${$styleProps.size}px;`)}
  ${({ $styleProps }) => `height: ${$styleProps.size}px;`}
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${GetColor.White};

  ${({ $styleProps }) =>
    $styleProps.active &&
    css`
      background-color: ${ColorUtils.opacifyFrom(GetColor.Primary.Dark, 0.1)};
    `}

  ${({ $styleProps }) =>
    $styleProps.disabled
      ? css`
          border-color: ${GetColor.Grey};
          background-color: ${GetColor.Grey};
          cursor: not-allowed;
          color: ${GetColor.MidGrey2};
        `
      : css`
          &:hover {
            background-color: ${ColorUtils.opacifyFrom(GetColor.Primary.Dark, 0.1)};
          }
        `}

  ${({ $styleProps }) => ($styleProps.dominant ? dominantCSS : '')};
  ${({ $styleProps }) => ($styleProps.destructive ? destructiveCSS : '')}
`;
