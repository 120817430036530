import React, { Component } from 'react';
import { updateProfile } from 'venn-api';
import { Content, Field, Label, Input } from './shared';
import { ModalFooter } from '../../modal';
import { analyticsService } from 'venn-utils';

interface EditNameProps {
  onClose: () => void;
  refresh: () => void;
  firstName: string;
  lastName: string;
  displayName: string;
}

interface EditNameState {
  firstName: string;
  lastName: string;
  displayName: string;
}

export class EditName extends Component<EditNameProps, EditNameState> {
  constructor(props: EditNameProps) {
    super(props);
    const { firstName, lastName, displayName } = props;
    this.state = {
      firstName,
      lastName,
      displayName,
    };
  }

  onFieldChange = (fieldName: string) => (e: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (typeof e === 'string') {
      this.setState((prevState) => ({
        ...prevState,
        [fieldName]: e,
      }));
      return;
    }

    const { value } = e.target;
    this.setState((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  onSubmit = async () => {
    const { firstName, lastName, displayName } = this.state;
    if (firstName !== this.props.firstName) {
      analyticsService.profileEdited({
        fieldsChanged: 'first name',
      });
    }
    if (lastName !== this.props.lastName) {
      analyticsService.profileEdited({
        fieldsChanged: 'last name',
      });
    }
    if (displayName !== this.props.displayName) {
      analyticsService.profileEdited({
        fieldsChanged: 'display name',
      });
    }
    await updateProfile({ firstName, lastName, displayName });
    this.props.refresh();
    this.props.onClose();
  };

  render() {
    const { firstName, lastName, displayName } = this.state;
    return (
      <>
        <Content>
          <Field>
            <Label>First Name</Label>
            <Input defaultValue={firstName} onChange={this.onFieldChange('firstName')} className="qa-firstname" />
          </Field>
          <Field>
            <Label>Last Name</Label>
            <Input defaultValue={lastName} onChange={this.onFieldChange('lastName')} className="qa-lastname" />
          </Field>
          <Field>
            <Label>Display Name</Label>
            <Input defaultValue={displayName} onChange={this.onFieldChange('displayName')} className="qa-displayname" />
          </Field>
        </Content>
        <ModalFooter
          primaryLabel="Save Profile"
          primaryClassName="qa-save-profile-btn"
          onPrimaryClick={this.onSubmit}
          onCancel={this.props.onClose}
        />
      </>
    );
  }
}
