import React from 'react';
import styled from 'styled-components';
import type { FrequencyEnum } from 'venn-api';
import { getAppTitle, GetColor, Icon, Tooltip } from 'venn-ui-kit';
import { capitalizeFirstLetter, Numbers } from 'venn-utils';
import StatefulNumericInput from '../../../stateful-numeric-input/StatefulNumericInput';

interface LagInputProps {
  frequency: FrequencyEnum;
  numLags: number;
  suggestedNumLags: number | undefined;
  onLagUpdate: (value: number) => void;
}

const formatter = (value: string | number) => {
  return Numbers.formatNumber(value, 0);
};

const LagInput = ({ frequency, numLags, suggestedNumLags, onLagUpdate }: LagInputProps) => {
  const onValueChange = (val: number) => {
    onLagUpdate(Math.round(val));
  };

  // Limit to 1.5 years for quarterly, 1 year for monthly
  const maxNumLags = frequency === 'QUARTERLY' ? 6 : 12;

  const lagTooltip =
    suggestedNumLags && suggestedNumLags !== numLags
      ? `Default lag recommendation (${suggestedNumLags}) is based on ${getAppTitle()} analysis.`
      : `Default lag recommendation is based on ${getAppTitle()} analysis.`;

  return (
    <Container>
      <div data-testid="lag-header">
        {capitalizeFirstLetter(frequency.toLowerCase())} Lags:
        <Tooltip usePortal content={lagTooltip}>
          <QuestionMark type="question-circle" />
        </Tooltip>
      </div>
      <InputWrapper>
        <StatefulNumericInput
          min={1}
          max={maxNumLags}
          precision={0}
          value={`${numLags === 0 ? '' : numLags}`}
          formatter={formatter}
          onValueChange={onValueChange}
          className="lag-input"
        />
      </InputWrapper>
    </Container>
  );
};

const QuestionMark = styled(Icon)`
  margin-left: 4px;
  color: ${GetColor.Primary.Dark};
  font-size: 10px;
`;

const Container = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
`;

const InputWrapper = styled.div`
  height: 35px;
  .lag-input {
    height: 35px;
  }
`;

export default LagInput;
