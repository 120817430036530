import { useModal } from 'venn-utils';
import { useRecoilValue } from 'recoil';
import { hasUnsavedPortfolioChangesInAllocator, hasUnsavedViewChangesInAllocator } from 'venn-state';
import { useCallback } from 'react';

interface UseUnsavedAllocatorChangesModalValue {
  unsavedAllocatorChangesModalOpen: boolean;
  onCloseAllocatorClick: () => void;
  onConfirmCloseAndLooseChanges: () => void;
  unsavedAllocatorChangesModalMessage: string | undefined;
  closeUnsavedAllocatorChangesModal: () => void;
}

export const useUnsavedAllocatorChangesModal = (
  onCloseAllocatorClick: () => void,
): UseUnsavedAllocatorChangesModalValue => {
  const hasPortfolioChanges = useRecoilValue(hasUnsavedPortfolioChangesInAllocator);
  const hasViewChanges = useRecoilValue(hasUnsavedViewChangesInAllocator);

  const [unsavedAllocatorChangesModalOpen, openUnsavedAllocatorChangesModal, closeUnsavedAllocatorChangesModal] =
    useModal();

  const unsavedAllocatorChangesModalMessage =
    hasPortfolioChanges && hasViewChanges
      ? 'Strategy selection, comparison portfolio, and all changes to portfolio allocations will be reset when the Allocator panel is closed.'
      : hasPortfolioChanges
        ? 'All changes to portfolio allocations will be reset when the Allocator panel is closed.'
        : hasViewChanges
          ? 'Strategy selection and comparison portfolio will be reset when the Allocator panel is closed.'
          : undefined;

  const onCloseAllocator = useCallback(() => {
    if (!hasPortfolioChanges && !hasViewChanges) {
      onCloseAllocatorClick();
      return;
    }
    openUnsavedAllocatorChangesModal();
  }, [hasPortfolioChanges, hasViewChanges, onCloseAllocatorClick, openUnsavedAllocatorChangesModal]);

  const onConfirmClose = useCallback(() => {
    if (!unsavedAllocatorChangesModalOpen) {
      return;
    }
    closeUnsavedAllocatorChangesModal();
    onCloseAllocatorClick();
  }, [onCloseAllocatorClick, unsavedAllocatorChangesModalOpen, closeUnsavedAllocatorChangesModal]);

  return {
    unsavedAllocatorChangesModalOpen,
    onCloseAllocatorClick: onCloseAllocator,
    onConfirmCloseAndLooseChanges: onConfirmClose,
    unsavedAllocatorChangesModalMessage,
    closeUnsavedAllocatorChangesModal,
  };
};
