import type { CSSProperties } from 'react';
import React, { useContext, useMemo, useState } from 'react';
import styled, { css, ThemeContext } from 'styled-components';
import Select from 'react-select';
import type { StylesConfig } from 'react-select/lib/styles';

import type { VennColors } from 'venn-ui-kit';
import { ColorUtils, ZIndex } from 'venn-ui-kit';
import type { StylingProps } from './styles';
import {
  FormFieldBackground,
  Container,
  getInputStyles,
  getLabelStyles,
  leftRightPadding,
  hoverColor,
  RequiredSymbol,
} from './styles';
import InfoIcon from './InfoIcon';
import type { BaseFormFieldProps } from './types';
import type { Option as OptionType } from 'react-select/lib/filters';
import type { ActionMeta } from 'react-select/lib/types';
import type { FormatOptionLabelMeta } from 'react-select/lib/Select';
import SubText from './SubText';

export interface FormSelectProps<T = unknown> extends BaseFormFieldProps {
  /** Current option selected */
  value: T | null;
  /** Options from which to select */
  options: T[];
  /** Required if each option is not a string or if the value is not option.value */
  getOptionValue?: (option: T) => string;
  /** Function that returns true if option should show up as a search result given input */
  filterOption?: (option: OptionType, input: string) => boolean;
  /** Function for custom rendering of an option in the options menu or a selected option */
  formatOptionLabel?: (option: T, meta: FormatOptionLabelMeta<T>) => React.ReactNode;
  /** Function to call every time an option is selected */
  onChange?: (option: T) => void;
  /** Whether searching for an option should be disabled or not */
  disableSearch?: boolean;
}

const FormSelect = <T,>(props: FormSelectProps<T>) => {
  const {
    disabled,
    label,
    errorHidden,
    error,
    hint,
    infoIconText,
    onChange,
    onFocus,
    onBlur,
    disableSearch = false,
    value,
    required,
  } = props;
  const { Colors } = useContext(ThemeContext);
  const [focused, setFocused] = useState(false);
  const styles = useMemo(() => getReactSelectStyles(Colors), [Colors]);

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocused(true);
    onFocus && onFocus(e);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocused(false);
    onBlur && onBlur(e);
  };

  const handleChange = (option: T, meta: ActionMeta) => {
    if (meta.action === 'select-option') {
      setFocused(false);
    }
    onChange && onChange(option);
  };

  const stylingProps = { hasValue: !!value, hasError: !!error, hasLabel: !!label, focused, disabled, disableSearch };

  return (
    <Container>
      <SelectContainer {...stylingProps}>
        {
          // @ts-expect-error: TODO fix strictFunctionTypes
          <Select
            {...props}
            placeholder=""
            isSearchable={!disableSearch}
            classNamePrefix="select"
            blurInputOnSelect
            onBlur={handleBlur}
            onFocus={handleFocus}
            onChange={handleChange}
            styles={styles}
            isDisabled={disabled}
          />
        }
        <Label {...stylingProps} htmlFor={props.inputId}>
          {label}
          {required && <RequiredSymbol>*</RequiredSymbol>}
        </Label>
      </SelectContainer>
      <SubText errorHidden={errorHidden} error={error} hint={hint} />
      <InfoIcon text={infoIconText} />
    </Container>
  );
};

export default FormSelect;

const Label = styled.label<StylingProps & { disableSearch: boolean }>`
  ${({ hasValue, hasError, focused, disableSearch, disabled }) =>
    getLabelStyles({
      // treat an active/focused search as having a value when styling
      hasValue: hasValue || (!disableSearch && !!focused),
      hasError,
      focused,
      disabled,
    })}
`;

const SelectContainer = styled.div<StylingProps & { disableSearch: boolean }>`
  background-color: ${FormFieldBackground};
  width: 100%;

  ${({ focused, hasError }) =>
    !focused &&
    !hasError &&
    css`
      :hover label {
        color: ${hoverColor};
      }
    `}
  .select__control {
    cursor: pointer;
    ${(props) => getInputStyles(props)}
  }

  .select__input {
    & input,
    & div {
      font: inherit;
    }
  }
`;

const getReactSelectStyles = (colors: VennColors): StylesConfig => ({
  container: (base: CSSProperties): CSSProperties => ({
    ...base,
    pointerEvents: 'unset',
  }),
  control: (): CSSProperties => ({}),
  input: (): CSSProperties => ({
    position: 'absolute',
  }),
  valueContainer: (base, props): CSSProperties => ({
    display: 'flex',
    paddingBottom: props.hasValue ? 3 : 0,
  }),
  dropdownIndicator: (): CSSProperties => ({
    position: 'absolute',
    right: '12px',
    bottom: '5px',
  }),
  indicatorSeparator: (): CSSProperties => ({
    margin: 0,
  }),
  option: (base: CSSProperties, props): CSSProperties => ({
    ...base,
    backgroundColor: props.isFocused ? colors.DarkGrey : 'inherit',
    cursor: 'pointer',
    padding: `10px ${leftRightPadding}px`,
  }),
  singleValue: () => ({}),
  menu: (base: CSSProperties): CSSProperties => ({
    ...base,
    backgroundColor: ColorUtils.hex2rgba(colors.Black, 0.65),
    border: `1px solid ${colors.Primary.Main}`,
    borderRadius: '0px',
    fontSize: 14,
    color: colors.White,
    marginTop: '-1px',
    paddingTop: '-1px',
    zIndex: ZIndex.Front,
  }),
  menuList: (base: CSSProperties): CSSProperties => ({
    ...base,
    backgroundColor: ColorUtils.hex2rgba(colors.Black, 0.65),
  }),
});
