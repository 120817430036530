import React, { Fragment, useMemo } from 'react';
import styled from 'styled-components';
import CustomizableErrorBoundary from '../../../error-boundary/CustomizableErrorBoundary';
import type { Options as HighchartOptions, HighchartsSeriesOptions } from '../../../highchart/Highchart';
import { Highchart } from '../../../highchart/Highchart';
import type { AnalysisChartLegendProps } from './AnalysisChartLegend';
import AnalysisChartLegend from './AnalysisChartLegend';
import { DownloadableContentBlock } from '../../../content-block';
import type { DownloadTrackingEvent, DownloadMetaData } from '../../../downloadable/types';
import type { Series, ExcelCell } from 'venn-utils';
import { SpecialCssClasses, convertMultipleSeriesDataToExcel, DISABLE_ANALYSIS_EXPORT_MESSAGE } from 'venn-utils';
import type { TrackAnalysisProps } from '../../chart-errors/TrackAnalysis';
import {
  TrackFailedAnalysis as TrackFailedAnalysisComponent,
  TrackSuccessfulAnalysis as TrackSuccessfulAnalysisComponent,
} from '../../chart-errors/TrackAnalysis';
import { SmallToggle } from '../../../toggle';
import type { ContentChartLegendItem } from '../../../content-chart-legend';
import { ContentChartLegend } from '../../../content-chart-legend';
import { GetColor, GetScheme, getTextThemeProvider, Loading, Tooltip, ZIndex } from 'venn-ui-kit';
import { formatSeriesData } from './utils';
import { SupportedErrorCodes } from 'venn-api';

export interface ChartDataProps {
  exportFilename?: string;
  noChartErrorComponent?: JSX.Element;
  noChartTrackingProps?: TrackAnalysisProps;
  disableDownload?: boolean;
  exportPNGEnabled?: boolean;
  exportXLSXEnabled?: boolean;
  /** Custom export data like rolling return chart */
  exportData?: ExcelCell[][];
  dataExportEvent: DownloadTrackingEvent;
  exportPercentage?: boolean;
  downloadMetaData?: DownloadMetaData;
  loadingSerieData?: boolean;
}

interface VennLineChartProps extends ChartDataProps {
  fileName?: string;
  config?: HighchartOptions;
  header: string;
  subHeader?: React.ReactNode;
  legendProps?: AnalysisChartLegendProps;
  hasMainSerie: boolean;
  enabledVenncast?: boolean;
  venncastConfig?: HighchartOptions;
  venncastLegend?: ContentChartLegendItem[];
  venncastDisableMessage?: string;
  venncastOn?: boolean;
  onToggleVenncast?: () => void;
}

const VennChart = ({
  config,
  fileName = '',
  header,
  subHeader,
  legendProps,
  hasMainSerie,
  loadingSerieData,
  noChartErrorComponent,
  noChartTrackingProps,
  disableDownload = false,
  exportPNGEnabled,
  exportXLSXEnabled,
  dataExportEvent,
  exportData,
  exportFilename,
  venncastConfig,
  venncastLegend,
  enabledVenncast,
  venncastDisableMessage,
  downloadMetaData,
  exportPercentage,
  venncastOn,
  onToggleVenncast,
}: VennLineChartProps) => {
  const options = venncastOn && venncastConfig ? venncastConfig : config;

  const [TrackSuccessfulAnalysis, TrackFailedAnalysis, trackingProps] = noChartTrackingProps
    ? [TrackSuccessfulAnalysisComponent, TrackFailedAnalysisComponent, noChartTrackingProps]
    : [Fragment, Fragment, {} as TrackAnalysisProps];
  // Hide the last venncast estimated legend when (1) it's a venncast chart, and (2) showVenncast is off
  const dynamicVenncastLegend = venncastConfig && !venncastOn ? venncastLegend?.slice(0, -1) : venncastLegend;

  const excel = useMemo(() => {
    if (!exportXLSXEnabled) {
      return undefined;
    }
    /** Export custom excel data */
    if (exportData) {
      return exportData;
    }

    /** Export chart data */
    // @ts-expect-error: TODO fix strictFunctionTypes
    const seriesData: Series[] | undefined = options?.series?.map((serie: HighchartsSeriesOptions) => ({
      name: serie.name || '',
      series: formatSeriesData(serie.data as number[][]) || [],
    }));
    return convertMultipleSeriesDataToExcel(seriesData, exportPercentage);
  }, [exportXLSXEnabled, options, exportPercentage, exportData]);

  if (
    (!hasMainSerie || !options) &&
    trackingProps.blockNames?.length === 1 &&
    trackingProps.errorCode === SupportedErrorCodes.NotAllowed
  ) {
    return <TrackFailedAnalysis {...trackingProps}>{noChartErrorComponent}</TrackFailedAnalysis>;
  }

  return (
    <DownloadableContentBlock
      header={header}
      subHeader={subHeader}
      downloadable={{
        png: exportPNGEnabled,
        disabled: disableDownload,
        tracking: dataExportEvent,
        excel,
        options: {
          fileName: exportFilename || fileName,
          metaData: downloadMetaData,
        },
        disableExcelExportMessage: DISABLE_ANALYSIS_EXPORT_MESSAGE,
      }}
    >
      <Flex>
        {enabledVenncast ? (
          <ContentChartLegend items={dynamicVenncastLegend ?? []} hideBorder />
        ) : (
          <AnalysisChartLegend {...legendProps} noBorder />
        )}
        {enabledVenncast && (
          <Tooltip content={venncastDisableMessage}>
            <VenncastToggle className={SpecialCssClasses.NotDownloadable}>
              <VenncastText>{getTextThemeProvider().VenncastName} Missing Data:</VenncastText>
              <SmallToggle
                toggled={venncastOn && !!venncastConfig}
                onToggle={onToggleVenncast}
                disabled={!venncastConfig}
              />
            </VenncastToggle>
          </Tooltip>
        )}
      </Flex>

      {(hasMainSerie || loadingSerieData) && options ? (
        <CustomizableErrorBoundary>
          <TrackSuccessfulAnalysis {...trackingProps}>
            <StyledChartWrapper className="qa-chart-container">
              {loadingSerieData && (
                <LoadingOverlay>
                  <Loading />
                </LoadingOverlay>
              )}
              <Highchart options={options} />
            </StyledChartWrapper>
          </TrackSuccessfulAnalysis>
        </CustomizableErrorBoundary>
      ) : (
        <TrackFailedAnalysis {...trackingProps}>{noChartErrorComponent}</TrackFailedAnalysis>
      )}
    </DownloadableContentBlock>
  );
};

export default VennChart;

const StyledChartWrapper = styled.div`
  flex: 1;
  overflow: hidden;
  position: relative;
`;

const LoadingOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.9;
  background-color: ${GetColor.White};
  z-index: ${ZIndex.Cover};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${GetScheme.LineChartColors.lineColor};
  border-bottom: 1px solid ${GetScheme.LineChartColors.lineColor};
`;

const VenncastToggle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`;

const VenncastText = styled.div`
  margin-right: 10px;
  font-size: 1.125rem;
`;
