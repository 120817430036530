import type { CSSProperties, FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import type { XAxisLabel } from '../heat-map/Types';

export const X_AXIS_HEIGHT = 242;
const MAX_CHARACTERS = 32;

interface XAxisProps {
  ticks: XAxisLabel[];
  cellWidth: number;
  height: number;
  offset: number;
  bottomHeight: number;
  angle: number;
  labelFormatter?: (tick: XAxisLabel) => CSSProperties;
  print?: boolean;
}

const COL_SHIFT = 0.5;
const defaultFormatter = () => ({});

const StyledText = styled.text<{ text: string; weight: number | 'bold' | 'normal'; print?: boolean }>`
  font-family: ${(props) => props.theme.Typography.fontFamily};
  font-size: ${(props) => (props.print ? '10px' : '14px')};
  font-style: normal;
  font-weight: ${(props) => props.weight};
`;

const XAxis: FC<React.PropsWithChildren<XAxisProps>> = ({
  ticks,
  cellWidth,
  offset,
  angle,
  height,
  bottomHeight,
  labelFormatter = defaultFormatter,
  print,
}) => {
  const characterLimit = Math.floor((MAX_CHARACTERS * height) / X_AXIS_HEIGHT);
  return (
    <>
      {ticks.map((tick, i) => {
        let text = tick.name;
        const { type } = tick;
        if (text.length > characterLimit) {
          text = `${text.substr(0, characterLimit - 3)}...`;
        }
        const x = offset + (i + COL_SHIFT) * cellWidth;
        const y = height - bottomHeight;
        const weight = type === 'PORTFOLIO' ? 'bold' : 'normal';
        return (
          <g key={tick.name}>
            <StyledText
              transform={`translate(${x},${y})rotate(${angle})`}
              textAnchor="start"
              text={text}
              className="qa-xaxis"
              weight={weight}
              style={labelFormatter(tick)}
              print={print}
            >
              {text}
            </StyledText>
          </g>
        );
      })}
    </>
  );
};

export default XAxis;
