import { useCallback, useRef, useEffect } from 'react';
import { isNil } from 'lodash';

const useStickyHeader = (isStaticMode?: boolean) => {
  const headerElementRef = useRef<HTMLElement | undefined>();
  const bodyElementRef = useRef<HTMLElement | undefined>();
  const stickyRef = useRef(false);
  const originalStyles = useRef({ position: '', top: '', zIndex: '' });
  const originalHeaderTop = useRef(0);

  useEffect(() => {
    if (isStaticMode) {
      return;
    }
    headerElementRef.current = document.querySelector<HTMLElement>('[ref="headerRoot"]') ?? undefined;
    bodyElementRef.current = document.querySelector<HTMLElement>('[ref="eBodyViewport"]') ?? undefined;

    const header = headerElementRef.current;
    if (isNil(header)) {
      return;
    }

    originalStyles.current.position = header?.style?.position;
    originalStyles.current.top = header?.style?.top;
    originalStyles.current.zIndex = header?.style?.zIndex;
    originalHeaderTop.current = header.getBoundingClientRect().top ?? 0;
  }, [isStaticMode]);

  const onScroll = useCallback(() => {
    const header = headerElementRef.current;
    const body = bodyElementRef.current;
    if (isNil(header) || isNil(body)) {
      return;
    }

    let shouldStick = false;
    let shouldUnstick = false;

    if (!stickyRef.current) {
      const wouldBeBelowTable =
        originalHeaderTop.current >=
        body.getBoundingClientRect().top + body.getBoundingClientRect().height - header.getBoundingClientRect().height;
      shouldStick = header.getBoundingClientRect().top < originalHeaderTop.current && !wouldBeBelowTable;
      if (shouldStick) {
        stickyRef.current = true;
      }
    } else {
      const isBehindHeader =
        body.getBoundingClientRect().top >= originalHeaderTop.current + header.getBoundingClientRect().height;
      const isBelowTable =
        header.getBoundingClientRect().top + header.getBoundingClientRect().height >=
        body.getBoundingClientRect().top + header.getBoundingClientRect().height + body.getBoundingClientRect().height;

      shouldUnstick = isBehindHeader || isBelowTable;
      if (shouldUnstick) {
        stickyRef.current = false;
      }
    }

    if (shouldStick) {
      header.style.position = 'fixed';
      header.style.top = originalHeaderTop.toString();
      header.style.zIndex = '1';
      body.style.marginTop = `${header.getBoundingClientRect().height}px`;
    }
    if (shouldUnstick) {
      const original = originalStyles.current;
      header.style.position = original.position;
      header.style.top = original.top;
      header.style.zIndex = original.zIndex;
      body.style.marginTop = '';
    }
  }, []);

  useEffect(() => {
    if (!isStaticMode) {
      window.addEventListener('scroll', onScroll, true);
    }

    return () => window.removeEventListener('scroll', onScroll, true);
  }, [onScroll, isStaticMode]);

  return stickyRef;
};

export default useStickyHeader;
