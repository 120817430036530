import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import DropdownMenu from '../dropdown-menu/DropdownMenu';
import { GetColor, Icon, Options, Tooltip } from 'venn-ui-kit';
import TextTrigger from './TextTrigger';
import DisabledTrigger from './DisabledTrigger';
import { isNil } from 'lodash';

export interface DropdownOption {
  value: unknown;
  label: string;
  level: number;
}

export interface TextDropdownProps {
  options: DropdownOption[];
  selected?: DropdownOption;
  disableDropdown?: boolean;
  disableDropdownMessage?: string;
  preventAutoCloseOnClick?: boolean;
  limitHeight?: boolean;
  triggerContentTransformFn?: (label: string) => string;
  onChange: (selectedOption: DropdownOption) => void;
  onToggleOpen?: (isOpen: boolean) => void;
  customRenderer?: (option: DropdownOption, idx: number) => JSX.Element;
  footer?: JSX.Element;
  compact?: boolean;
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default ({
  options: targetOptions,
  selected,
  triggerContentTransformFn = (label: string) => label,
  disableDropdown,
  disableDropdownMessage,
  onChange,
  customRenderer,
  preventAutoCloseOnClick,
  limitHeight,
  onToggleOpen,
  footer,
  compact,
}: TextDropdownProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const options = useMemo(
    () =>
      targetOptions.map((option, idx) => ({
        value: option.value,
        separator: idx !== targetOptions.length - 1,
        children: customRenderer ? customRenderer(option, idx) : option.label,
      })),
    [customRenderer, targetOptions],
  );

  const onToggle = (open: boolean) => {
    onToggleOpen && onToggleOpen(open);
    setIsOpen(open);
  };

  const selectedOptionLabel = selected ? (
    triggerContentTransformFn(selected.label)
  ) : (
    <DefaultText>Select...</DefaultText>
  );

  if (disableDropdown) {
    return (
      <Tooltip content={<TooltipMessage>{disableDropdownMessage}</TooltipMessage>} isHidden={!disableDropdownMessage}>
        <DisabledTrigger>{selectedOptionLabel}</DisabledTrigger>
      </Tooltip>
    );
  }

  return (
    <DropdownMenu
      portal
      limitHeight={limitHeight}
      trigger={
        <TextTrigger isOpen={isOpen}>
          {selectedOptionLabel}
          <DropdownIcon type={isOpen ? 'angle-up' : 'angle-down'} compact={compact} />
        </TextTrigger>
      }
      onToggle={onToggle}
      className="modalDropdownMenu"
      debounceExternalActivityCallback={!preventAutoCloseOnClick}
      footer={footer}
    >
      {isOpen && (
        <Options
          options={options}
          onSelect={(selectedOption) => {
            const selectedTargetOption = targetOptions.find((option) => option.value === selectedOption.value);
            if (!isNil(selectedTargetOption)) {
              onChange(selectedTargetOption);
              onToggle(false);
            }
          }}
        />
      )}
    </DropdownMenu>
  );
};

const DefaultText = styled.span`
  color: ${GetColor.Grey};
`;

const TooltipMessage = styled.div`
  width: 231px;
  height: 34px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
`;

const DropdownIcon = styled(Icon)<{ compact?: boolean }>`
  opacity: 0.4;
  font-size: 0.7em;
  margin-left: ${({ compact }) => (compact ? 6 : 10)}px;
  position: relative;
`;
