import React from 'react';
import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';

interface Props {
  x: number;
  y: number;
  areaHeight: number;
  areaWidth: number;
  height: number;
  width: number;
}

const HeatmapCursor = ({ x, y, areaHeight, areaWidth, width, height }: Props) => (
  <Container>
    <line x1={x} y1={0} x2={x} y2={areaHeight} />
    <line x1={x + width} y1={0} x2={x + width} y2={areaHeight} />
    <line x1={0} y1={y} x2={areaWidth} y2={y} />
    <line x1={0} y1={y + height} x2={areaWidth} y2={y + height} />
  </Container>
);

export default React.memo(HeatmapCursor);

const Container = styled.g`
  stroke: ${GetColor.DarkGrey};
  pointer-events: none;
  stroke-width: 2;
`;
