import React from 'react';
import styled from 'styled-components';
import { Col, Container, Headline2, Row, BrandLogo } from 'venn-ui-kit';
import type { SearchMenuItem } from 'venn-components';
import { SearchMenuBar } from 'venn-components';
import { hasAnalysisSubjectNoReturns } from 'venn-utils';
import { isNil } from 'lodash';

interface EmptyPortfolioLabPageProps {
  onSelectPortfolioId: (id: number) => void;
}

const EmptyPortfolioLabPage = ({ onSelectPortfolioId }: EmptyPortfolioLabPageProps) => {
  return (
    <Wrapper>
      <BrandLogo height={300} isBackground scaleToFit />
      <Container fluid>
        <Row>
          <Col xs={{ span: 8, offset: 2 }}>
            <Headline2 center>Select a portfolio to start optimizing</Headline2>
            <SearchMenuBar
              portfoliosOnly
              onSelected={({ value: selectedItem }: SearchMenuItem) => {
                if (!selectedItem) {
                  return;
                }
                if (!isNil(selectedItem.portfolio?.id)) {
                  onSelectPortfolioId(selectedItem.portfolio.id);
                }
              }}
              optionDisabledTooltipContent={(option) =>
                hasAnalysisSubjectNoReturns(option.value) ? 'This item has no returns and cannot be analyzed' : ''
              }
              isOptionDisabled={(option) => hasAnalysisSubjectNoReturns(option.value)}
              location="emptyPortfolioLabPage"
              privateAssetSearchMode="PUBLIC_ONLY"
            />
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};

export default EmptyPortfolioLabPage;

const Wrapper = styled.div`
  padding-top: 220px;
  position: relative;
  height: 75vh;
  width: 100%;

  & > svg {
    top: 170px;
  }
`;
