import styled from 'styled-components';

export const TabsContainer = styled.div<{ gap?: number }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-right: -1px;
  ${({ gap }) => gap && `gap: ${gap}px;`}

  .single-tab {
    width: 50%;
  }

  .single-tab > div {
    width: 100%;
  }
`;
