import React from 'react';
import { FlexRow, FlexWrapper, Info, Name, StyledButton, StyledIcon, StyledWrapper } from './components/shared';
import { useModal } from 'venn-utils';
import SponsorInvitationModal from './components/SponsorInvitationModal';
import SponsoredAccountsTable from './components/SponsoredAccountsTable';
import styled from 'styled-components';
import useSponsoredAccounts from './components/logic/useSponsoredAccounts';
import type { UserProfileSettings } from 'venn-api';
import SponsorCustomization from './components/SponsorCustomization';
import WhatsNewCustomization from './components/whats-new-customization/WhatsNewCustomization';

interface SponsorshipsProps {
  profileSettings: UserProfileSettings;
}

const Sponsorships = ({ profileSettings }: SponsorshipsProps) => {
  const [isOpen, open, close] = useModal();
  const { accounts, numPages, setPage, page, resendInvite, removeInvite, removeAccount, refreshAccounts } =
    useSponsoredAccounts();

  return (
    <>
      <StyledWrapper>
        <FlexRow>
          <div>
            <FlexWrapper>
              <SponsorIcon type="money-check-edit-alt" />
              <Name>Sponsored Accounts</Name>
            </FlexWrapper>
            <Info>Know who is currently being sponsored, or invited to be.</Info>
          </div>
          <StyledButton dominant onClick={open}>
            Sponsor Account
          </StyledButton>
        </FlexRow>
        <TableWrapper>
          <SponsoredAccountsTable
            accounts={accounts}
            numPages={numPages}
            setPage={setPage}
            page={page}
            resendInvite={resendInvite}
            removeInvite={removeInvite}
            removeAccount={removeAccount}
          />
        </TableWrapper>
      </StyledWrapper>
      <StyledWrapper>
        <SponsorCustomization profileSettings={profileSettings} />
      </StyledWrapper>
      <StyledWrapper>
        <WhatsNewCustomization sponsorAccountId={profileSettings.organization.accountId} />
      </StyledWrapper>
      {isOpen && <SponsorInvitationModal onClose={close} refreshAccounts={refreshAccounts} />}
    </>
  );
};

export default Sponsorships;

const TableWrapper = styled.div`
  margin-top: 20px;
`;

const SponsorIcon = styled(StyledIcon)`
  margin-bottom: 10px;
`;
