import type { Theme } from 'venn-ui-kit';
import { MESSAGE_HEIGHT, MESSAGE_PRINT_HEIGHT } from '../../factor-chart/constants';
import type { ViewPort } from './types';
import { FACTOR_SUMMARY_CHART_TRANSFORM } from './constants';

export const getTextColor = (itemValue: number, theme: Theme): string => {
  const {
    Colors,
    Schemes: { FactorColors },
  } = theme;

  if (itemValue === 0) {
    return Colors.DarkGrey;
  }

  const barColor = itemValue > 0 ? FactorColors.mainColor : FactorColors.comparisonColor;
  return barColor;
};

export const getOpacity = (itemSignificant: boolean): number => (itemSignificant ? 1 : 0.4);

const FACTOR_SUMMARY_CHART_PADDING = 40;
export const getSvgHeight = (
  viewPort: ViewPort,
  factorCount: number,
  print: boolean,
  transform = FACTOR_SUMMARY_CHART_TRANSFORM,
  filterCount?: number,
): number => {
  const messageHeight = filterCount ? (print ? MESSAGE_PRINT_HEIGHT : MESSAGE_HEIGHT) : 0;
  return viewPort.rowHeight * factorCount + FACTOR_SUMMARY_CHART_PADDING + transform + messageHeight;
};
