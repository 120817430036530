import React, { useCallback } from 'react';
import { ColorUtils, GetColor, Icon, Tooltip, TooltipBodyDirection } from 'venn-ui-kit';
import {
  buttonize,
  MANAGE_DATA_SECTION,
  navigateToManageDataPage,
  showFactorAnalysisWarning,
  shouldUserUpdateDataForFund,
} from 'venn-utils';
import { useHistory } from 'react-router-dom';
import type { SearchResultWithUIState } from './types';
import styled from 'styled-components';

interface WarningProps {
  item: SearchResultWithUIState;
  openProxyModal: () => void;
}

const shouldDirectUserToManageData = (item: SearchResultWithUIState) => {
  if (['VENN', 'CUSTODIAN', 'EXTERNAL_INTEGRATION', 'EXTERNAL_BYOL'].includes(item.investmentSource)) {
    return true;
  }
  return !item.needsProxy;
};
const Warning = ({ item, openProxyModal }: WarningProps) => {
  const history = useHistory();

  const updateDataOnClick = useCallback(
    (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
      e?.stopPropagation();
      navigateToManageDataPage(history, { fundId: item.fundId }, 'Library', false, MANAGE_DATA_SECTION.DATA);
    },
    [history, item],
  );

  const proxyOnClick = useCallback(
    (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
      e?.stopPropagation();
      openProxyModal();
    },
    [openProxyModal],
  );

  const tooltipDirection = TooltipBodyDirection.Left;
  const manageDataMessage =
    'Please update return data by clicking this icon or selecting “Manage Data” from the Actions menu on the\n' +
    '                right';
  const addAProxyMessage =
    'Please choose a proxy by clicking this icon or selecting "Add a Proxy" from the Actions menu on the\n' +
    '                right';
  if (shouldUserUpdateDataForFund(item)) {
    return (
      <WarningWrapper>
        <Tooltip
          bodyDirection={tooltipDirection}
          content={
            <TooltipWrapper>
              <div>The investment is more than 6 months out of date or has an insufficient number of data points</div>
              <TooltipSuggestion>{manageDataMessage}</TooltipSuggestion>
            </TooltipWrapper>
          }
        >
          <UpdateDataIcon type="exclamation-triangle" {...buttonize(updateDataOnClick)} />
        </Tooltip>
      </WarningWrapper>
    );
  }

  if (showFactorAnalysisWarning(item)) {
    const directToManageData = shouldDirectUserToManageData(item);
    return (
      <WarningWrapper>
        <Tooltip
          bodyDirection={tooltipDirection}
          content={
            <TooltipWrapper>
              <div>
                There is not enough returns data to conduct factor analysis. At least 6 months of data is required for
                daily returns and at least 1 year of data is required for monthly returns
              </div>
              <TooltipSuggestion>{directToManageData ? manageDataMessage : addAProxyMessage}</TooltipSuggestion>
            </TooltipWrapper>
          }
        >
          <ProxyIcon
            type="exclamation-triangle"
            {...buttonize(directToManageData ? updateDataOnClick : proxyOnClick)}
          />
        </Tooltip>
      </WarningWrapper>
    );
  }

  return null;
};

export default Warning;

const WarningWrapper = styled.div`
  padding-right: 15px;
  position: absolute;
  top: 8px;
  left: -13px;
`;

const HoverIcon = styled(Icon)`
  padding: 3px;
  border-radius: 2px;
  :hover {
    background-color: ${GetColor.Grey};
  }
`;

const UpdateDataIcon = styled(HoverIcon)`
  color: ${GetColor.Error};
  :hover {
    color: ${ColorUtils.opacifyDarkFrom(GetColor.Error, 0.5)};
  }
`;

const ProxyIcon = styled(HoverIcon)`
  color: ${GetColor.Warning};
  :hover {
    color: ${ColorUtils.opacifyDarkFrom(GetColor.Warning, 0.5)};
    background-color: ${GetColor.Grey};
  }
`;

const TooltipWrapper = styled.div`
  width: 280px;
  text-align: left;
  padding: 10px 2px 10px 5px;
`;

const TooltipSuggestion = styled.div`
  padding-top: 15px;
  font-weight: bold;
`;
