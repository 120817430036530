import { type BlockId, type CustomFont, getCustomFontSize, blockSettings as blockSettingsState } from 'venn-state';
import {
  type RecoilState,
  type RecoilValueReadOnly,
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useRecoilCallback,
} from 'recoil';
import { FontSizeInput } from 'venn-components';
import { useLocation } from 'react-router-dom';
import React from 'react';
import { analyticsService, getViewIdFromLocation } from 'venn-utils';

export const BlockFontSizeInput = ({
  blockId,
  stateName,
  range,
  state,
  defaultState,
  isReadOnly,
}: {
  blockId: BlockId;
  stateName: string;
  range: { min: number; max: number };
  state: (blockId: BlockId) => RecoilState<CustomFont>;
  defaultState: RecoilValueReadOnly<CustomFont>;
  isReadOnly?: boolean;
}) => {
  const location = useLocation();
  const savedId = getViewIdFromLocation(location);
  const recoilState = state(blockId);
  const [size, setSize] = useRecoilState(recoilState);
  const resetSize = useResetRecoilState(recoilState);
  const defaultSize = useRecoilValue(defaultState);

  const resetMessage = `Restore workspace default (${getCustomFontSize(defaultSize)})`;

  const onChange = useRecoilCallback(
    ({ snapshot }) =>
      (value: number) => {
        if (size.fontSizePt === value) {
          // no change, no need to set size again
          return;
        }

        setSize({ ...size, fontSizePt: value });
        snapshot.getPromise(blockSettingsState(blockId)).then((blockSettings) => {
          analyticsService.blockFontSizeChanged({
            blockId,
            viewId: savedId,
            blockType: blockSettings.customBlockType,
            fontType: stateName,
            fontSize: value,
          });
        });
      },
    [blockId, savedId, stateName, size, setSize],
  );

  const onReset = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        snapshot.getPromise(blockSettingsState(blockId)).then((blockSettings) => {
          analyticsService.resetFontSizeToWorkspaceDefaults({
            blockId,
            viewId: savedId,
            blockType: blockSettings.customBlockType,
            fontType: stateName,
            fontSize: defaultSize.fontSizePt,
          });
        });
        resetSize();
      },
    [blockId, savedId, stateName, defaultSize.fontSizePt, resetSize],
  );

  return (
    <FontSizeInput
      label={stateName}
      value={size.fontSizePt}
      onChange={onChange}
      resetEnabled={size.fontSizePt !== defaultSize.fontSizePt}
      resetTooltip={resetMessage}
      onReset={onReset}
      isReadOnly={isReadOnly}
      {...range}
    />
  );
};
