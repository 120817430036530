import type { FC } from 'react';
import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Icon, Tooltip, TooltipPosition } from 'venn-ui-kit';

interface DataStatusCellProps {
  hover: boolean;
  isDeleted: boolean;
  noNewData: boolean;
  hasErrors: boolean;

  /**
   * Override default error message logic with custom message
   */
  errorMessage?: string;

  /**
   * Whether this is a new fund
   */
  isNew: boolean;
  /**
   * Fires when the undo/delete button is clicked
   */
  onDeleteToggle: (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => void;
}

const DataStatusIcon: FC<React.PropsWithChildren<DataStatusCellProps>> = ({
  hover,
  isDeleted,
  noNewData,
  hasErrors,
  isNew,
  onDeleteToggle,
  errorMessage,
}) => {
  const { Colors } = useContext(ThemeContext);
  if (noNewData || hasErrors) {
    const message =
      errorMessage ??
      (noNewData
        ? `No new observations were found. This data will not be uploaded
          unless you select 'overwrite,' or rename the investment to mark as new.`
        : 'This data contains errors such as gaps between date periods and will not be uploaded.');
    return (
      <Tooltip maxWidth={253} content={<TooltipContent>{message}</TooltipContent>} position={TooltipPosition.Left}>
        <ClickableIcon type="exclamation-circle" color={Colors.Error} />
      </Tooltip>
    );
  }
  if (hover) {
    const message = isDeleted ? 'Restore investment' : 'Discard investment';
    return (
      <Tooltip maxWidth={111} content={<TooltipContent>{message}</TooltipContent>} position={TooltipPosition.Left}>
        <ClickableIcon type={isDeleted ? 'undo' : 'trash'} color={Colors.Primary.Dark} onClick={onDeleteToggle} />
      </Tooltip>
    );
  }
  if (isDeleted) {
    return <StyledIcon type="minus-circle" color={Colors.Grey} />;
  }

  return (
    <GreyableContainer greyed={isDeleted || noNewData}>
      <StyledIcon type="check-circle" color={isNew ? Colors.HighlightDark : Colors.DEPRECATED_DataLineColor.Green} />
    </GreyableContainer>
  );
};

const GreyableContainer = styled.div<{ greyed: boolean }>`
  opacity: ${(props) => (props.greyed ? 0.25 : 1)};
`;

const StyledIcon = styled(Icon)<{ color: string }>`
  color: ${({ color }) => color};
`;

const ClickableIcon = styled(StyledIcon)`
  cursor: pointer;
`;

const TooltipContent = styled.span`
  font-size: 10px;
`;

export default DataStatusIcon;
