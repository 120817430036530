import { useState, useEffect } from 'react';
import { isNil } from 'lodash';

/**
 * Provides an optionally debounced value
 * If `delay` is not provided, value will be set automatically
 * If `delay` is provided, value will be set after delay of inactivity
 *
 * @return [ debouncedValue, setDebouncedValue ] Use setDebouncedValue to
 * immediately set the debounced value.
 */
export function useDebounce<T>(value: T, delay?: number): [T, React.Dispatch<React.SetStateAction<T>>] {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    // Set debouncedValue to value (passed in) after the specified delay
    const timeoutRef = delay
      ? setTimeout(() => {
          setDebouncedValue(value);
        }, delay)
      : null;

    if (!delay) {
      setDebouncedValue(value);
    }

    return () => {
      if (!isNil(timeoutRef)) {
        clearTimeout(timeoutRef);
      }
    };
  }, [delay, value]);

  return [debouncedValue, setDebouncedValue];
}
