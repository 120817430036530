import type { CellRenderer } from './types';
import { isCellDisabled, isDataNew } from './helpers';
import React from 'react';
import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';
import type { ColumnMapping, FileUploadMetadata } from 'venn-api';
import { DataUploaderMode } from '../../types';
import type { ErrorViewModel } from '../../views/review/helpers';

interface PerformanceCellRendererProps {
  mode: DataUploaderMode;
  metadata: FileUploadMetadata;
  columnMapping: ColumnMapping;
  errors: ErrorViewModel[];
}

// @ts-expect-error: TODO fix strictFunctionTypes
export const PerformanceCellRenderer: CellRenderer = ({
  mode,
  metadata,
  columnMapping,
  errors,
}: PerformanceCellRendererProps) => {
  const {
    periodAnnualized,
    periodReturn,
    annualizedVolatility,
    newNav,
    newDataCount,
    fundId,
    portfolioNodeId,
    seriesId,
  } = columnMapping;
  const isDisabled = isCellDisabled(columnMapping, metadata, errors, seriesId);
  const isReturns = mode === DataUploaderMode.Returns;
  const isNew = isDataNew(isReturns, !!fundId, !!portfolioNodeId);

  return isReturns ? (
    <PerformanceCell isDisabled={isDisabled}>
      <div>
        <StyledObservationCount isNew={isNew}>+{newDataCount}</StyledObservationCount>
        <LabelText>Observations</LabelText>
      </div>
      <div>
        <LabelText>{periodAnnualized ? 'Ann. ' : 'Cum. '}Ret.</LabelText>
        <ValueText>{periodReturn ? `${(periodReturn * 100).toFixed(2)}%` : '--'}</ValueText>
      </div>
      <div>
        <LabelText>{periodAnnualized ? 'Ann. ' : ''}Vol.</LabelText>
        <ValueText>
          {periodAnnualized && annualizedVolatility ? `${(annualizedVolatility * 100).toFixed(2)}%` : '--'}
        </ValueText>
      </div>
    </PerformanceCell>
  ) : (
    <PerformanceCell isDisabled={isDisabled}>
      <div>
        <span>{!Number.isNaN(Number(newNav)) && newNav !== null ? newNav.toFixed(2) : '--'}</span>
      </div>
    </PerformanceCell>
  );
};

const PerformanceCell = styled.div<{ isDisabled?: boolean }>`
  ${({ isDisabled }) =>
    isDisabled &&
    `
    opacity: 0.25;
  `} display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 1.25;
  > div {
    span:first-child {
      margin-right: 3px;
    }
  }
`;

const LabelText = styled.span`
  color: ${GetColor.MidGrey2};
  margin-right: 3px;
`;

const ValueText = styled.span`
  color: ${GetColor.Black};
  font-weight: bold;
`;

const StyledObservationCount = styled.span<{ isNew: boolean }>`
  color: ${({ isNew }) => (isNew ? GetColor.HighlightDark : GetColor.Black)};
  font-weight: bold;
  font-size: 14px;
`;
