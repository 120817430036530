import styled, { css } from 'styled-components';
import { Body1, ButtonIcon, GetColor } from 'venn-ui-kit';

export const YellowButtonContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
`;

export const YellowButtonIcon = styled(ButtonIcon)<{ disabled?: boolean }>`
  &.button-icon-wrapper {
    i {
      color: ${({ disabled }) => !disabled && GetColor.DEPRECATED_DataLineColor.PaleGold};
    }
    border: 1px solid ${({ disabled }) => !disabled && GetColor.DEPRECATED_DataLineColor.Gold};
    width: 100%;
    padding: 0 10px;
  }
`;

export const YellowButtonWrapper = styled.div`
  margin-left: 10px;
`;

export const YellowButtonProxyName = styled(Body1)<{ disabled?: boolean }>`
  margin-left: 8px;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ disabled }) =>
    disabled &&
    css`
      color: ${GetColor.MidGrey2};
    `}
`;
