import { isEmpty } from 'lodash';
import isNil from 'lodash/isNil';
import React from 'react';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';
import { GetColor, Icon, Tooltip, TooltipBodyDirection, TooltipPosition } from 'venn-ui-kit';
import { reportZoom } from 'venn-state';
import type { ICellRendererParams } from '../../../../data-grid';
import { BENCHMARK_KEY } from '../../../customAnalysisContants';
import type { CustomRowData } from '../../../types';
import MilionValueFormatterTooltip from './MilionValueFormatterTooltip';
import { CellWrapper, renderTimeRangeTooltip } from './shared';

const ICON_WIDTH = 13;

interface ValueCellRendererProps extends ICellRendererParams {
  data: CustomRowData;
  errors?: string[];
  errorTooltipMaxWidth?: number;
  errorTooltipContent?: React.ReactNode;
  errorTooltopPosition?: TooltipPosition;
}

/** Rich Cell Renderer */
const ValueCellRenderer = ({
  valueFormatted,
  value,
  data,
  colDef,
  errors,
  errorTooltipMaxWidth,
  errorTooltipContent,
  errorTooltopPosition,
}: ValueCellRendererProps) => {
  const zoom = useRecoilValue(reportZoom);

  const isBenchmark = [data?.key, colDef?.cellRendererParams?.key].includes(BENCHMARK_KEY);
  const customBenchmarkType = data?.customBenchmarkType || colDef?.cellRendererParams?.customBenchmarkType;
  const benchmarkNameRangeMap = data?.benchmarkNameRangeMap || colDef?.cellRendererParams?.benchmarkNameRangeMap;
  const dataType = colDef?.cellRendererParams?.type;

  if (!isEmpty(errors) || errorTooltipContent) {
    return (
      <Tooltip
        usePortal
        position={errorTooltopPosition ?? TooltipPosition.Top}
        maxWidth={errorTooltipMaxWidth ?? 300}
        content={errorTooltipContent ?? (errors ?? []).join('; ')}
        zoom={zoom}
      >
        <ErrorIcon type="exclamation-circle" prefix="far" />
      </Tooltip>
    );
  }

  if (isBenchmark) {
    return (
      <StyledCellWrapper>
        {value && customBenchmarkType !== 'NONE' ? (
          <>
            <StyledIcon type={customBenchmarkType === 'INDIVIDUAL' ? 'shapes' : 'triangle'} />
            <StyledTooltip
              usePortal
              content={renderTimeRangeTooltip(value, benchmarkNameRangeMap?.[value])}
              bodyDirection={TooltipBodyDirection.Left}
            >
              <span>{valueFormatted}</span>
            </StyledTooltip>
          </>
        ) : (
          <>
            <Icon type="ban" />
            No benchmark set
          </>
        )}
      </StyledCellWrapper>
    );
  }

  if (isNil(valueFormatted)) {
    return <>--</>;
  }

  return <MilionValueFormatterTooltip valueFormatted={valueFormatted} dataType={dataType} value={value} />;
};

export default ValueCellRenderer;

const StyledCellWrapper = styled(CellWrapper)`
  width: 100%;
`;

const StyledTooltip = styled(Tooltip)`
  max-width: 100%;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledIcon = styled(Icon)`
  width: ${ICON_WIDTH}px;
  margin-bottom: 10px;
  text-align: left;
  margin-right: 0px;
`;

const ErrorIcon = styled(Icon)`
  color: ${GetColor.Error};
`;
