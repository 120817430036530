import React, { useCallback, useContext } from 'react';
import type { SearchResultWithUIState, SearchState } from '../types';
import DeleteItems from './DeleteItems';
import AddToPortfolio from './AddToPortfolio';
import type { BulkAction, BulkActionProps } from './types';
import type { LibraryItemType, QuickFilter } from 'venn-utils';
import { analyticsService, useHasFF } from 'venn-utils';
import CompareItems from './CompareItems';
import TagItems from './TagItems';
import OptimizeItems from './OptimizeItems';
import { UserContext } from 'venn-components';

export interface TrackingProps {
  totalResults: number;
  visibleResults: number;
  queryDriven: boolean;
  typeFilters: LibraryItemType[];
  tagFilters: string[];
  quickFilters: QuickFilter[];
}

interface BulkActionsIconsProps {
  /** The items on which to perform the action */
  selectedItems: SearchResultWithUIState[];
  /** Function to call after executing a bulk action to clear the selected items */
  clearSelectedItems: () => void;
  /** Function to update the search state with any data that might have changed in the bulk action */
  updateData: React.Dispatch<React.SetStateAction<SearchState>>;
  trackingProps: TrackingProps;
}

const BulkActionsIcons = ({ selectedItems, clearSelectedItems, updateData, trackingProps }: BulkActionsIconsProps) => {
  const hasOptimization = useHasFF('optimization');
  const { hasPermission, hasPermissionForResource } = useContext(UserContext);
  const hasAccessToPortfolios = hasPermission('CREATE_PORTFOLIO');
  const hasAccessToTagging = hasPermission('MANAGE_TAGS');
  const hasAccessToPortfolioLab = hasOptimization && hasPermission('OPTIMIZATION');
  const hasAccessToDeleteItems = selectedItems.every((item) =>
    item.portfolioId ? hasPermissionForResource('CREATE_PORTFOLIO', item) : hasPermission('UPLOAD_RETURNS'),
  );

  const onComplete = useCallback(
    (action: BulkAction, closeModalFn: () => void, clearSelectedAndRefresh = false) => {
      analyticsService.bulkActionPerformed({
        ...trackingProps,
        numberOfItems: selectedItems.length,
        type: action,
      });
      if (clearSelectedAndRefresh) {
        // takes care of clearing selected items from query params which then triggers a library refresh
        clearSelectedItems();
      }
      closeModalFn();
    },
    [clearSelectedItems, trackingProps, selectedItems.length],
  );

  const bulkActionProps: BulkActionProps = {
    items: selectedItems,
    onComplete,
    updateData,
  };

  return (
    <>
      <CompareItems {...bulkActionProps} />
      {hasAccessToPortfolios && <AddToPortfolio {...bulkActionProps} />}
      {hasAccessToTagging && <TagItems {...bulkActionProps} />}
      {hasAccessToPortfolioLab && <OptimizeItems {...bulkActionProps} />}
      {hasAccessToDeleteItems && <DeleteItems {...bulkActionProps} />}
    </>
  );
};

export default BulkActionsIcons;
