import React, { Component } from 'react';
import styled from 'styled-components';
import { updatePassword, validateCurrentPassword } from 'venn-api';
import { Error, FlexRow, Field, Label, FlexWrapper, Input } from './shared';
import { getPasswordMessage } from './utils';
import { Hint, Icon, GetColor } from 'venn-ui-kit';
import moment from 'moment';
import { ModalFooter, ModalContent, ModalDescription } from '../../modal';

enum STEPS {
  CURRENT_PASSWORD,
  NEW_PASSWORD,
}

interface EditPasswordProps {
  onClose: () => void;
  updateCallback: () => void;
  goToForgotPassword: () => void;
  pwdLastUpdated?: number;
}

interface EditPasswordState {
  oldPassword: string;
  password: string;
  loading: boolean;
  error: string;
  step: STEPS;
  visiblePassword: boolean;
  visibleOldPassword: boolean;
}

export class EditPassword extends Component<EditPasswordProps, EditPasswordState> {
  state = {
    step: STEPS.CURRENT_PASSWORD,
    error: '',
    loading: false,
    password: '',
    oldPassword: '',
    visibleOldPassword: false,
    visiblePassword: false,
  };

  onChangeOldPassword = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({
      oldPassword: e.target.value,
    });
  };

  onChangePassword = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({
      password: e.target.value,
    });
  };

  goToNewPassword = async () => {
    const { oldPassword } = this.state;
    this.setState({ loading: true, error: '' });
    try {
      const res = await validateCurrentPassword({ oldPassword });
      this.setState({ loading: false });
      if (!(res.content && res.content.body)) {
        this.setState({
          error: 'Wrong password. Please try again.',
        });
      } else {
        this.setState({
          step: STEPS.NEW_PASSWORD,
        });
      }
    } catch (e) {
      const error = await e;
      this.setState({ loading: false, error: error.content?.message });
    }
  };

  onSubmit = async () => {
    const { oldPassword, password } = this.state;
    const { updateCallback } = this.props;
    this.setState({ loading: true, error: '' });
    try {
      await updatePassword({
        oldPassword,
        password,
      });
      this.setState({ loading: false });
      updateCallback && updateCallback();
      this.props.onClose();
    } catch (e) {
      const error = await e;
      this.setState({ loading: false, error: error.content?.message });
    }
  };

  toggleOldPassword = () => {
    this.setState((prevState) => ({
      visibleOldPassword: !prevState.visibleOldPassword,
    }));
  };

  goToForgotPassword = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    this.props.goToForgotPassword();
  };

  renderOldPassword = () => {
    const { oldPassword, visibleOldPassword, error, loading } = this.state;
    return (
      <Box>
        <ModalContent>
          <Field>
            <Label>Current Password</Label>
            <CenterAlign>
              <Input
                type={visibleOldPassword ? 'text' : 'password'}
                onChange={this.onChangeOldPassword}
                className="qa-oldPassword"
                value={oldPassword}
                placeholder="Enter your existing password"
              />
              <EyeIcon type="eye" active={visibleOldPassword} onClick={this.toggleOldPassword} />
            </CenterAlign>
            <Error className="qa-error-msg">{error}</Error>
            <ForgotPasswordButton type="button" onClick={this.goToForgotPassword}>
              Forgot password?
            </ForgotPasswordButton>
          </Field>
        </ModalContent>
        <ModalFooter
          primaryLabel="Next"
          primaryClassName="qa-next-btn"
          primaryDisabled={oldPassword.length === 0 || loading}
          onPrimaryClick={this.goToNewPassword}
          onCancel={this.props.onClose}
        />
      </Box>
    );
  };

  togglePassword = () => {
    this.setState((prevState) => ({
      visiblePassword: !prevState.visiblePassword,
    }));
  };

  renderPassword = () => {
    const { password, visiblePassword, error, loading } = this.state;
    const message = getPasswordMessage(password);
    return (
      <Box>
        <ModalContent>
          <Field>
            <Label>New Password</Label>
            <CenterAlign>
              <Input
                type={visiblePassword ? 'text' : 'password'}
                onChange={this.onChangePassword}
                className="qa-password"
                value={password}
                placeholder="Longer passwords are more secure - enter your new password here"
              />
              <EyeIcon type="eye" active={visiblePassword} onClick={this.togglePassword} />
            </CenterAlign>
            {message && (
              <FlexWrapper>
                <Circle />
                <Hint>{message} </Hint>
              </FlexWrapper>
            )}
            <Error>{error}</Error>
          </Field>
        </ModalContent>
        <ModalFooter
          primaryLabel="Change Password"
          primaryClassName="qa-submit-password-btn"
          primaryDisabled={!!message.length || loading}
          onPrimaryClick={this.onSubmit}
        />
      </Box>
    );
  };

  render() {
    const { step } = this.state;
    const { pwdLastUpdated } = this.props;
    const message = `Password last changed on ${moment(pwdLastUpdated).format('D MMM YYYY')}`;
    return (
      <>
        <Description>
          <FlexRow>
            <Title>
              <Icon type="lock" />
              <Text>Change your password</Text>
            </Title>
            <Hint>{message}</Hint>
          </FlexRow>
        </Description>
        {step === STEPS.NEW_PASSWORD ? this.renderPassword() : this.renderOldPassword()}
      </>
    );
  }
}

const Description = styled(ModalDescription)`
  padding-top: 20px;
`;

const Title = styled.div`
  display: flex;
  color: ${GetColor.DarkGrey};
`;

const Text = styled.div`
  margin-left: 8px;
`;

const Box = styled.div`
  margin-top: 20px;
  input {
    font-size: 14px;
  }
`;

const EyeIcon = styled(Icon)<{ active?: boolean }>`
  color: ${({ active }) => (active ? GetColor.Primary.Main : GetColor.HintGrey)};
  margin-left: 11px;
  cursor: pointer;
`;

const ForgotPasswordButton = styled.button`
  font-weight: bold;
  margin-top: 15px;
  width: 130px;
`;

const CenterAlign = styled(FlexWrapper)`
  align-items: center;
  margin-bottom: 5px;
`;

const Circle = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 1px solid ${GetColor.HintGrey};
  margin-right: 8px;
`;
