import { ColorUtils } from 'venn-ui-kit';

export const FUNDING_FAILURE_SERIES_PREFIX = 'funding_failure_series';
export const FUNDING_FAILURE_POINT_PREFIX = 'funding_failure_point';

export const confidenceKeyFromPercentage = (perc: number): string => {
  // format perc to have exactly 2 digits
  // 5% -> 05
  // 95% -> 95
  return `CONFIDENCE_LEVEL_0_${perc < 10 ? '0' : ''}${Math.round(perc)}`;
};

export const seriesNameFromPercentage = (perc: number): string => {
  return `${String(Math.round(perc * 100))}%`;
};

export const convertToTimeseries = (ts: number[][], scaleFactor: number, replaceWith?: number): number[][] | null => {
  if (!ts) {
    return null;
  }
  return ts.map((t) => [t[0], (replaceWith ?? t[1]) * scaleFactor]);
};

export const getSaturationLevel = (color: string, perc: number) => {
  const opacity = 1.5 - perc;
  return ColorUtils.opacify(color, opacity);
};
