import React, { useState } from 'react';
import styled from 'styled-components';
import SliderToggle from '../../slider-toggle/SliderToggle';
import { GetColor } from 'venn-ui-kit';
import type { SearchFund } from 'venn-api';
import SearchBar from '../../search-bar/SearchBar';
import InvestmentsSelector from './InvestmentsSelector';
import useInvestmentSearch from './useInvestmentSearch';

export interface AddInvestmentsProps {
  onSubmit: (investments: SearchFund[]) => void;
  onCancel: () => void;
  excludedItems?: string[];
  initialRestrictToRecentUploads?: boolean;
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default ({ onSubmit, onCancel, excludedItems, initialRestrictToRecentUploads }: AddInvestmentsProps) => {
  const [restrictToRecentUploads, setRestrictToRecentUploads] = useState<boolean>(!!initialRestrictToRecentUploads);
  const { query, loading, results, onSearch } = useInvestmentSearch(excludedItems, restrictToRecentUploads);

  return (
    <>
      <SearchContainer>
        <SearchBarContainer>
          <SearchBar
            placeholder="Search by investment name, Equity/ETF name, or Equity/ETF symbol."
            value={query}
            onChange={onSearch}
            debounce={500}
          />
        </SearchBarContainer>
        <ToggleContainer>
          <SliderToggle
            toggled={restrictToRecentUploads}
            onToggle={() => setRestrictToRecentUploads(!restrictToRecentUploads)}
          />
          <ToggleText>Recent uploads only</ToggleText>
        </ToggleContainer>
      </SearchContainer>

      <InvestmentsSelector loading={loading} searchResult={results} onSubmit={onSubmit} onCancel={onCancel} />
    </>
  );
};

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 30px 30px 20px;
`;

const SearchBarContainer = styled.div`
  flex: 3;
`;

const ToggleContainer = styled.div`
  margin-left: 10px;
  font-size: 10px;
  display: flex;
  flex: 1;
  p {
    margin-left: 5px;
    color: ${GetColor.DarkGrey};
  }
`;

const ToggleText = styled.p`
  font-size: 1.2rem;
`;
