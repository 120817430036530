// Adapted from https://github.com/sunify/react-relative-portal

import type { CSSProperties } from 'react';
import React from 'react';
import Portal from './Portal';
import { ZIndex } from '../../zIndexValues';
import styled from 'styled-components';
import isNil from 'lodash/isNil';

const listeners = new Set<() => void>();

/**
 * Runtime error-throwing assertion to verify that the listener is actually a listener.
 * @see https://gitlab.cf.twosigma.com/venn/frontend/-/merge_requests/9291
 */
const assertListener = (listener: () => void) => {
  if (typeof listener !== 'function') {
    throw new Error(`expected listener; received ${listener}`);
  }
};

function fireListeners() {
  listeners.forEach((listener) => {
    assertListener(listener);
    listener();
  });
}

function getPageOffset() {
  const node = document.documentElement || document.body.parentNode || document.body;
  return {
    x: window.pageXOffset !== undefined ? window.pageXOffset : (node as Element).scrollLeft,
    y: window.pageYOffset !== undefined ? window.pageYOffset : (node as Element).scrollTop,
  };
}

function initDOMListener() {
  document.body.addEventListener('wheel', fireListeners);
  window.addEventListener('resize', fireListeners);
}

if (document.body) {
  initDOMListener();
} else {
  document.addEventListener('DOMContentLoaded', initDOMListener);
}

function subscribe(fn: () => void) {
  assertListener(fn);
  listeners.add(fn);
  return () => listeners.delete(fn);
}

interface LegacyRelativePortalProps {
  right?: number;
  left?: number;
  fullWidth?: boolean;
  top?: number;
  expectedHeight?: number;
  children: React.ReactNode;
  onOutClick?: () => void;
  component?: keyof HTMLElementTagNameMap;
  style?: CSSProperties;
  className?: string;
  /**
   * The position is by default calculated based on the parent component.
   * Specifying this prop will make the position of the portal be relative to the given element.
   */
  relativeElement?: Element;
}

interface LegacyRelativePortalState {
  right: number;
  left: number;
  top: number;
}

export default class LegacyRelativePortal extends React.Component<
  React.PropsWithChildren<LegacyRelativePortalProps>,
  LegacyRelativePortalState
> {
  static defaultProps = {
    left: 0,
    top: 0,
    expectedHeight: 30, // This at least prevents the pop-up from going completely below the window
  };

  handleScroll: () => void;

  unsubscribe: () => boolean;

  element: HTMLElement | null;

  state = {
    right: 0,
    left: 0,
    top: 0,
  };

  componentDidMount() {
    this.handleScroll = () => {
      if (this.element && !isNil(this.props.children)) {
        const rect = this.props.relativeElement?.getBoundingClientRect() ?? this.element.getBoundingClientRect();
        const pageOffset = getPageOffset();
        const top = pageOffset.y + Math.max(0, Math.min(rect.top, window.innerHeight - this.props.expectedHeight!));
        const right = document.documentElement.clientWidth - rect.right - pageOffset.x;
        const left = pageOffset.x + rect.left;

        if (top !== this.state.top || left !== this.state.left || right !== this.state.right) {
          this.setState({ left, top, right });
        }
      }
    };
    this.unsubscribe = subscribe(this.handleScroll);
    this.handleScroll();
  }

  componentDidUpdate() {
    this.handleScroll();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const { component, top, left, right, fullWidth, style, className, onOutClick } = this.props;

    const fromLeftOrRight =
      right !== undefined ? { right: this.state.right + right } : { left: this.state.left + left! };

    const horizontalPosition = fullWidth
      ? { right: this.state.right + (right || 0), left: this.state.left + left! }
      : fromLeftOrRight;

    const extraStyle = style || {};

    return React.createElement(
      component ?? 'span',
      {
        ref: (element: HTMLElement) => {
          this.element = element;
        },
      },
      <Portal onOutClick={onOutClick}>
        <PortalDiv
          style={{
            top: this.state.top + top!,
            ...horizontalPosition,
            ...extraStyle,
          }}
          className={className}
        >
          {this.props.children}
        </PortalDiv>
      </Portal>,
    );
  }
}

const PortalDiv = styled.div`
  position: absolute;
  z-index: ${ZIndex.ModalFront};
  @media print {
    display: none;
  }
`;
