import { useState, useCallback } from 'react';

type UseModalReturn<T, O = unknown> = [boolean, () => void, () => void, (arg: T, options?: O) => void];

/**
 * This hook drives the opening/closing of modals.
 * It returns an array with 4 items:
 * - [1]: isOpen: boolean that drives whether to show or hide the modal
 * - [2]: open: function to call to show/open the modal
 * - [3]: close: function to call to hide/close the modal
 * - [4]: submit: function to call when the modal is submitted
 * @param onSubmit Callback called when submit() is called
 */
export default function useModal<T, O = unknown>(
  onSubmit?: (arg: T, options?: O) => void | undefined,
  isInitiallyOpen = false,
): UseModalReturn<T, O> {
  const [isOpen, setOpen] = useState<boolean>(!!isInitiallyOpen);

  const open = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const close = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const submit = useCallback(
    (arg: T, options?: O) => {
      if (onSubmit) {
        setOpen(false);
        onSubmit(arg, options);
      }
    },
    [onSubmit],
  );

  return [isOpen, open, close, submit];
}
