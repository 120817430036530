import React from 'react';
import type { AnalysesPeriod, AnalysisGroup, AnalysisSubject, TimeFrame } from 'venn-utils';
import type { ResidualReturnsResponse } from 'venn-api';
import AnalysisLineChart from './internal/AnalysisLineChart';
import type { TrackAnalysisProps } from 'venn-components';
import type { DownloadMetaData } from '../../downloadable';

export interface ResidualReturnsGraphProps {
  residualAnalyses: AnalysisGroup<ResidualReturnsResponse>;
  subject: AnalysisSubject;
  relative: boolean;
  trackingProps?: TrackAnalysisProps;
  analysesPeriod?: AnalysesPeriod;
  actualTimeFrame: TimeFrame;
  downloadMetaData?: DownloadMetaData;
}

const ResidualReturnsChart = ({
  residualAnalyses,
  subject,
  relative,
  trackingProps,
  analysesPeriod,
  actualTimeFrame,
  downloadMetaData,
}: ResidualReturnsGraphProps) => {
  if (!residualAnalyses?.subject?.residualCumulativeContribution) {
    return null;
  }

  const analysisGroup = {
    main: {
      name: subject.name,
      data: {
        absolute: residualAnalyses?.subject?.residualCumulativeContribution,
        relative: residualAnalyses?.subject?.residualCumulativeContribution,
      },
    },
    benchmark: residualAnalyses?.benchmark?.residualCumulativeContribution
      ? {
          name: subject.activeBenchmarkName ?? '',
          data: {
            absolute: residualAnalyses?.benchmark?.residualCumulativeContribution,
            relative: residualAnalyses?.benchmark?.residualCumulativeContribution,
          },
        }
      : undefined,
    category:
      subject.type === 'investment' && residualAnalyses?.category?.residualCumulativeContribution
        ? {
            name: subject.categoryGroup?.name ?? '',
            data: {
              absolute: residualAnalyses?.category?.residualCumulativeContribution,
              relative: residualAnalyses?.category?.residualCumulativeContribution,
            },
          }
        : undefined,
    secondary:
      subject.type === 'portfolio' && residualAnalyses?.category?.residualCumulativeContribution
        ? {
            name: subject?.secondaryPortfolio?.name ?? '',
            data: {
              absolute: residualAnalyses?.category?.residualCumulativeContribution,
              relative: residualAnalyses?.category?.residualCumulativeContribution,
            },
          }
        : undefined,
  };

  return (
    <AnalysisLineChart
      analysisSubject={subject}
      relative={relative}
      start={actualTimeFrame.startTime}
      end={actualTimeFrame.endTime}
      frequency={analysesPeriod?.frequency}
      header={`Cumulative ${relative ? 'Excess ' : ''}Residual Return`}
      subHeader="Calculated using static betas from the selected period"
      chartData={analysisGroup}
      noChartTrackingProps={trackingProps}
      yAxisUnitFormat="percent"
      exportPNGEnabled
      exportXLSXEnabled
      dataExportEvent={{
        description: 'CUMULATIVE_RESIDUAL_RETURN',
        relativeToBenchmark: relative,
        subjectType: subject.type,
        subjectId: subject.id,
        userUploaded: subject.userUploaded,
      }}
      downloadMetaData={downloadMetaData}
    />
  );
};

export default ResidualReturnsChart;
