import { atom, selector, selectorFamily } from 'recoil';
import type { SearchQuery, StoredSearch } from 'venn-api';
import { getMorningstarCategories, getSavedSearch, getSavedSearches, getViewableDataSources } from 'venn-api';
import { selectorWithAuth } from '../utils';
import type { DataSource, FiltersState, MorningstarCategory, PrivatesSearchParams, SearchParams } from './commonTypes';
import { DEFAULT_PARAMS_V2, DEFAULT_PRIVATE_PARAMS } from './commonTypes';
import { getFiltersStateFromPrivatesSearchParams, getFiltersStateFromSearchParams } from './utils';
import { LibraryTab } from 'venn-utils';
import { isEqual } from 'lodash';

export const savedFiltersSelector = selectorWithAuth<StoredSearch[]>({
  key: 'savedFiltersSelector',
  get: async () => (await getSavedSearches()).content,
});

export const filterSelectorById = selectorFamily<SearchQuery, string>({
  key: 'filterSelectorById',
  get: (filterId) => async () => {
    return (await getSavedSearch(filterId)).content;
  },
});

export const allMorningstarCategoriesSelector = selectorWithAuth<MorningstarCategory[]>({
  key: 'allMorningstarCategoriesSelector',
  get: async () => (await getMorningstarCategories()).content.map((value) => value as MorningstarCategory).sort(),
});

export const allDataSourcesSelector = selectorWithAuth<DataSource[]>({
  key: 'allDataSourcesSelector',
  get: async () => (await getViewableDataSources()).content.map((value) => value as DataSource),
});

export const selectedLibraryTabAtom = atom<LibraryTab>({
  key: 'selectedLibraryTabAtom',
  default: LibraryTab.ReturnsData,
});

export const librarySearchParamsAtom = atom<SearchParams>({
  key: 'librarySearchParamsAtom',
  default: DEFAULT_PARAMS_V2,
});

const privateLibrarySearchParamsAtom = atom<PrivatesSearchParams>({
  key: 'privateLibrarySearchParamsAtom',
  default: DEFAULT_PRIVATE_PARAMS,
});

export const privateLibrarySearchParams = selector<PrivatesSearchParams>({
  key: 'privateLibrarySearchParams',
  get: ({ get }) => get(privateLibrarySearchParamsAtom),
  set: ({ get, set }, newValue) => {
    if (!isEqual(get(privateLibrarySearchParamsAtom), newValue)) {
      set(privateLibrarySearchParamsAtom, newValue);
    }
  },
});

export const libraryFiltersStateSelector = selector<FiltersState>({
  key: 'libraryFiltersStateSelector',
  get: ({ get }) => {
    const selectedTab = get(selectedLibraryTabAtom);
    if (selectedTab === LibraryTab.ReturnsData) {
      return getFiltersStateFromSearchParams(get(librarySearchParamsAtom));
    }
    return getFiltersStateFromPrivatesSearchParams(get(privateLibrarySearchParamsAtom));
  },
});
