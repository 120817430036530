import React from 'react';
import { GetColor, ColorUtils } from 'venn-ui-kit';
import type { BasicTableColumn, BasicTableProps, StyledTableType } from 'venn-components';
import { BasicTable, SORTDIR } from 'venn-components';
import type { GeneralAnalysisTemplate } from 'venn-api';
import styled from 'styled-components';
import { Cell } from '../shared';
import TemplateMenu from './TemplateMenu';
import { buttonize } from 'venn-utils';

interface TableProps {
  templates: GeneralAnalysisTemplate[];
  launchAnalysisModal: (template: GeneralAnalysisTemplate) => void;
  onDelete?: (template: GeneralAnalysisTemplate) => void;
  onEdit?: (template: GeneralAnalysisTemplate) => void;
}

const Columns = (
  launchAnalysisModal: (template: GeneralAnalysisTemplate) => void,
  onDelete?: (template: GeneralAnalysisTemplate) => void,
  onEdit?: (template: GeneralAnalysisTemplate) => void,
): BasicTableColumn<GeneralAnalysisTemplate>[] => [
  {
    label: 'Analysis Name',
    accessor: 'name',
    sortable: true,
    sorted: SORTDIR.ASC,
    sortValueFunc: (template: GeneralAnalysisTemplate) => template.name.toLowerCase(),
    cellRenderer: (template: GeneralAnalysisTemplate) => (
      <Cell
        {...buttonize(() => {
          launchAnalysisModal(template);
        })}
      >
        {template.name}
      </Cell>
    ),
  },
  {
    label: 'Owner',
    sortable: false,
    cellRenderer: ({ appUser }: GeneralAnalysisTemplate) => <Cell>{appUser?.displayName}</Cell>,
  },
  {
    label: '',
    cellStyle: {
      width: 70,
      textAlign: 'right',
    },
    cellRenderer: (template: GeneralAnalysisTemplate) =>
      onDelete || onEdit ? <TemplateMenu templateDeleted={onDelete} editTemplate={onEdit} template={template} /> : null,
  },
];

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default ({ templates, launchAnalysisModal, onDelete, onEdit }: TableProps) => (
  <StyledTable
    columns={Columns(launchAnalysisModal, onDelete, onEdit)}
    data={templates}
    className="qa-template-list-table"
  />
);

const StyledTable: StyledTableType<unknown> = styled(
  <T extends BasicTableColumn<K>, K>(props: BasicTableProps<T, K>) => <BasicTable<T, K> {...props} />,
)`
  margin: 0 0 15px;

  > thead {
    > tr {
      > th:first-child {
        padding-left: 60px;
      }
    }
  }

  > tbody {
    > tr {
      > td:first-child {
        padding-left: 60px;
      }
      > td:last-child {
        padding-right: 60px;
      }

      border-bottom: 1px solid ${GetColor.PaleGrey};

      i {
        margin-right: 10px;
      }
    }

    > tr:hover {
      background: ${ColorUtils.hex2rgbaFrom(GetColor.Primary.Main, 0.05)};
      i {
        color: ${GetColor.Black};
      }
    }
    tr:last-child {
      border-bottom: none;
    }
  }
`;
