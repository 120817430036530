import React, { useCallback, useRef } from 'react';
import TriggerContainer from './TriggerContainer';
import { Label } from '../../typography';
import Tooltip from '../../tooltip/Tooltip';
import { GetColor, ColorUtils } from '../../../style';
import { Icon } from '../../icon/Icon';
import styled, { css } from 'styled-components';
import type { TriggerComponentProps } from '../types';

interface AdvancedFilterTriggerProps extends TriggerComponentProps {
  selectionLabel: React.ReactNode;
  selectionLabelTooltipContent: React.ReactNode;
  alwaysShowTooltip?: boolean;
}

const AdvancedFilterTriggerOld = ({
  className,
  expanded,
  label,
  selectionLabel,
  disabled,
  onClick,
  selectionLabelTooltipContent,
  alwaysShowTooltip,
}: AdvancedFilterTriggerProps) => {
  const handleClick = useCallback(() => {
    if (!disabled) {
      onClick?.();
    }
  }, [onClick, disabled]);

  const onArrowClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>) => {
      event?.stopPropagation();
      event?.preventDefault();
      handleClick();
    },
    [handleClick],
  );

  const ref = useRef<HTMLDivElement>(null);
  const tooltipLeftOffset =
    -(ref.current?.clientWidth ?? 0) + (document.getElementsByTagName('main')[0]?.offsetLeft ?? 0);

  return (
    <TriggerContainer
      className={className}
      onClick={handleClick}
      onKeyEnter={handleClick}
      disabled={disabled}
      expanded={expanded}
      innerRef={ref}
    >
      <Input disabled={disabled} className="qa-dropmenu-trigger" expanded={expanded} hasSelection={!!selectionLabel}>
        <Selection>
          {selectionLabel && !expanded ? (
            <Tooltip
              isHidden={!alwaysShowTooltip}
              maxWidth={300}
              content={selectionLabelTooltipContent}
              usePortal
              portalPosition={{
                left: tooltipLeftOffset,
              }}
            >
              <StyledLabel className="qa-dropmenu-selection-label">{selectionLabel}</StyledLabel>
            </Tooltip>
          ) : (
            <Label className="qa-dropmenu-input-label">{label}</Label>
          )}
        </Selection>

        <ArrowIcon
          type={expanded ? 'angle-up' : 'angle-down'}
          onClick={onArrowClick}
          tabIndex={-1}
          expanded={expanded}
        />
      </Input>
    </TriggerContainer>
  );
};

export default AdvancedFilterTriggerOld;

interface StateProps {
  hasSelection?: boolean;
  disabled?: boolean;
  expanded?: boolean;
}

const StyledLabel = styled(Label)`
  width: 100%;
`;

const Selection = styled.div`
  flex-grow: 1;
  min-width: 0;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;

  > * {
    display: inline-flex;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: bold;
    font-size: 12px;
    user-select: none;
  }
`;

const Input = styled.div<StateProps>`
  height: 35px;
  border-radius: 4px;
  ${(props) =>
    (props.disabled || (!props.expanded && !props.hasSelection)) &&
    css`
      border: 1px solid ${GetColor.Grey};
    `}
  padding-right: 10px;
  display: flex;
  align-items: center;
  color: ${(props) => (props.disabled ? GetColor.HintGrey : props.expanded ? GetColor.White : GetColor.Black)};
  background-color: ${(props) =>
    props.disabled
      ? GetColor.PaleGrey
      : props.expanded
        ? GetColor.Primary.Dark
        : props.hasSelection
          ? ColorUtils.opacifyFrom(GetColor.Primary.Dark, 0.2)
          : GetColor.White};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  font-style: ${(props) => (props.disabled ? 'italic' : 'normal')};

  label {
    color: ${(props) => (props.disabled ? GetColor.HintGrey : props.expanded ? GetColor.White : GetColor.Black)};
  }

  button {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  }
`;

const ArrowIcon = styled(Icon)<StateProps>`
  color: ${(props) => (props.expanded ? GetColor.White : GetColor.Black)};
`;
