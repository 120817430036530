import React, { useCallback, useContext, useMemo, useState } from 'react';
import {
  workspaceAdminDisableUserMFA,
  type WorkspaceMember,
  removeInvitations,
  removeUserFromMyWorkspace,
  revokeInvitations,
  sendInvitations,
} from 'venn-api';
import type { TableRowMenuOption } from 'venn-ui-kit';
import { TableRowOptionsMenu } from 'venn-ui-kit';
import { ConfirmationModal, UserContext } from 'venn-components';
import { analyticsService, hasPendingInvitation, useHasFF, useInitializeFeatures } from 'venn-utils';
import { isNil } from 'lodash';

interface MemberActionsMenuProps {
  member: WorkspaceMember;
  onChangeMembers: () => void;
}

type ConfirmationModalActionType = 'REVOKE_INVITATION' | 'REMOVE_FROM_WORKSPACE' | 'RESET_2FA';

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default ({ member, onChangeMembers }: MemberActionsMenuProps) => {
  const [confirmationModal, setConfirmationModal] = useState<ConfirmationModalActionType | undefined>(undefined);
  const { profileSettings } = useContext(UserContext);
  const workspace = profileSettings?.organization?.name ?? 'your workspace';
  const currentUserId = profileSettings?.user.id;

  const { created, invitedById } = member;
  const onClose = useCallback(() => setConfirmationModal(undefined), []);

  const options: TableRowMenuOption[] = [];
  const hasMfaUpdatesFF = useHasFF('mfa_updates_ff');
  const hasWorkspaceAdminActions = useHasFF('workspace_admin_actions');
  const canPerformWorkspaceManagement = hasWorkspaceAdminActions;
  const initializeFeatures = useInitializeFeatures();

  const onRevokeInvitation = useCallback(async () => {
    await revokeInvitations([member.email]);
    analyticsService.invitationAction({
      email: member.email,
      isWorkspaceAdmin: canPerformWorkspaceManagement,
      type: 'Revoked',
    });
    onChangeMembers();
    onClose();
  }, [member.email, onChangeMembers, onClose, canPerformWorkspaceManagement]);

  const onRemoveInvitation = useCallback(async () => {
    await removeInvitations([member.email]);
    analyticsService.invitationAction({
      email: member.email,
      isWorkspaceAdmin: canPerformWorkspaceManagement,
      type: 'Removed',
    });
    // Removing an invitation will effect full_access_seat_cap feature
    initializeFeatures();
    onChangeMembers();
    onClose();
  }, [member.email, canPerformWorkspaceManagement, initializeFeatures, onChangeMembers, onClose]);

  const sendInvitation = useCallback(async () => {
    await sendInvitations([member.email]);
    analyticsService.invitationAction({
      email: member.email,
      isWorkspaceAdmin: canPerformWorkspaceManagement,
      type: 'Resent',
    });
    onChangeMembers();
  }, [member.email, onChangeMembers, canPerformWorkspaceManagement]);

  const onRemoveFromWorkspace = useCallback(async () => {
    await removeUserFromMyWorkspace(member.userId);
    profileSettings?.organization?.id &&
      analyticsService.removedFromWorkspace({
        isWorkspaceAdmin: canPerformWorkspaceManagement,
        userId: member.userId,
        orgId: profileSettings?.organization?.id,
      });
    // Removing a user will effect full_access_seat_cap feature
    initializeFeatures();
    onChangeMembers();
    onClose();
  }, [
    member.userId,
    profileSettings?.organization?.id,
    canPerformWorkspaceManagement,
    initializeFeatures,
    onChangeMembers,
    onClose,
  ]);

  const onResetUser2FAToEmail = useCallback(async () => {
    await workspaceAdminDisableUserMFA(member.userId);
    // Removing a user will affect org_users feature
    initializeFeatures();
    onChangeMembers();
    onClose();
  }, [initializeFeatures, onChangeMembers, onClose, member.userId]);

  const [subhead, text, onProceed] = useMemo(() => {
    switch (confirmationModal) {
      case 'RESET_2FA':
        return [
          `You are about to reset the 2FA for ${member.email}.`,
          'This action will reset their 2FA to the organization default.  2FA can be re-enabled by the user from the Settings > Security tab.',
          () => onResetUser2FAToEmail(),
        ];
      case 'REVOKE_INVITATION':
        return [
          `You are about to revoke the invitation for ${member.email}.`,
          `This action will prevent this user from signing in to ${workspace}, unless you invite them again in the future.`,
          () => onRevokeInvitation(),
        ];
      case 'REMOVE_FROM_WORKSPACE':
        const person = member.email === member.name ? member.name : `${member.name} (${member.email})`;
        return [
          `You are about to remove ${person} from ${workspace}.`,
          'This action will prevent this user from signing in to your workspace, unless you invite them again in the future.',
          () => onRemoveFromWorkspace(),
        ];
      default:
        return [undefined, undefined, undefined];
    }
  }, [
    confirmationModal,
    member.email,
    member.name,
    workspace,
    onResetUser2FAToEmail,
    onRevokeInvitation,
    onRemoveFromWorkspace,
  ]);

  const hasPermissionsToEditInvite =
    canPerformWorkspaceManagement || (!isNil(invitedById) && invitedById === currentUserId);
  if (hasPendingInvitation(member) && hasPermissionsToEditInvite) {
    options.push({
      label: 'Revoke invitation',
      onClick: () => setConfirmationModal('REVOKE_INVITATION'),
    });
  }

  if (!created) {
    options.push({
      label: 'Re-send invitation',
      onClick: sendInvitation,
    });
  }

  if (!created && !hasPendingInvitation(member) && hasPermissionsToEditInvite) {
    options.push({
      label: 'Remove',
      onClick: onRemoveInvitation,
    });
  }

  if (created && member.userId !== currentUserId && canPerformWorkspaceManagement) {
    options.push({
      label: 'Remove from workspace',
      onClick: () => setConfirmationModal('REMOVE_FROM_WORKSPACE'),
    });
  }

  if (
    hasMfaUpdatesFF &&
    created &&
    member.userId !== currentUserId &&
    canPerformWorkspaceManagement &&
    member.mfaEnabled
  ) {
    options.push({
      label: 'Reset 2FA',
      onClick: () => setConfirmationModal('RESET_2FA'),
    });
  }

  if (!options.length) {
    return null;
  }

  return (
    <span>
      <TableRowOptionsMenu
        options={options}
        menuClassName="members-menu-dropdown"
        triggerClassName="members-menu-trigger"
      />
      {confirmationModal && text && onProceed && (
        <ConfirmationModal destructive text={text} subhead={subhead} onCancel={onClose} onProceed={onProceed} />
      )}
    </span>
  );
};
