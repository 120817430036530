import React from 'react';
import styled from 'styled-components';
import { Icon, Tooltip } from 'venn-ui-kit';

const SelectableSolutionChartSubheader = () => (
  <div>
    Click to view portfolio details or to update your settings{' '}
    <Tooltip
      content={
        <TooltipContent>Settings include your long-term forecasts, benchmark, and portfolio policy.</TooltipContent>
      }
    >
      <Icon type="info-circle" prefix="far" />
    </Tooltip>{' '}
    or constraints{' '}
    <Tooltip
      content={
        <TooltipContent>
          Constraints include the objective (set above), as well as the allocation and factor exposure constraints (set
          in the side "Inputs" menu).
        </TooltipContent>
      }
    >
      <Icon type="info-circle" prefix="far" />
    </Tooltip>
    .
  </div>
);

export default SelectableSolutionChartSubheader;

const TooltipContent = styled.div`
  text-align: center;
  width: 236px;
  padding: 5px;
`;
