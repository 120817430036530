import React, { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import type { AnalysesPeriod, AnalysisConfig, AnyDuringEslintMigration } from 'venn-utils';
import {
  analyticsService,
  getAnalysisRequest,
  getMostRecentAnalysis,
  logMessageToSentry,
  useApi,
  logExceptionIntoSentry,
  assertNotNil,
} from 'venn-utils';
import type {
  AnalysisStatusForTracking,
  DownloadMetaData,
  TrackAnalysisProps,
  UpdatableViewSettings,
  ScenarioAnalysisResult,
} from 'venn-components';
import {
  DownloadableContentBlock,
  FailedAnalysisInfo,
  TrackSuccessfulAnalysis as TrackSuccessfulAnalysisComponent,
  UserContext,
  convertLoading,
  convertResponse,
  convertScenarioDataToExcel,
  limitAvailShock,
} from 'venn-components';
import type { Message, Scenario, ScenarioAnalysisIndex } from 'venn-api';
import { analysis, getDefaultIndicesForUser, getSavedViewScenarios, getScenarios, SupportedErrorCodes } from 'venn-api';

import { v1 as uuid } from 'uuid';
import findIndex from 'lodash/findIndex';
import ScenarioAnalysisTable from './ScenarioAnalysisTable';
import EmptyMessage from './EmptyMessage';
import styled from 'styled-components';
import { Shimmer } from 'venn-ui-kit';

interface ScenarioAnalysisProps {
  analysisConfig: AnalysisConfig;
  downloadMetaData?: DownloadMetaData;
  onResetTimeFrame?: () => void;
  noChartTrackingProps: TrackAnalysisProps;
  updateAnalysisStatusForTracking?: (
    actionAnalysisStatuses: AnalysisStatusForTracking[],
    actionTrackingId: number,
    dateRange?: string,
  ) => void;
  onUpdateAnalysisViewParam: (value: Partial<UpdatableViewSettings>) => void;
  scenarios?: Scenario[];
  scenariosCustomParamId?: string;
}

export const ScenarioAnalysis = ({
  analysisConfig,
  downloadMetaData,
  onResetTimeFrame,
  updateAnalysisStatusForTracking,
  noChartTrackingProps,
  onUpdateAnalysisViewParam,
  scenarios,
  scenariosCustomParamId,
}: ScenarioAnalysisProps) => {
  const abortController = useRef<AbortController>();
  const updateQuietlyFlag = useRef(false);
  const apiIndicesRef = useRef(useApi(getDefaultIndicesForUser));
  const apiAnalysisRef = useRef(useApi(analysis));
  const [loading, setLoading] = useState(false);
  const [indices, setIndices] = useState<ScenarioAnalysisIndex[]>([]);
  const [scenariosList, setScenariosList] = useState<Scenario[]>();
  const [scenariosResult, setScenariosResult] = useState<ScenarioAnalysisResult[]>([]);
  const [analysesError, setAnalysesError] = useState<Partial<Message> | undefined>();
  const [analysesPeriod, setAnalysesPeriod] = useState<AnalysesPeriod>({ frequency: 'MONTHLY' });
  // `resultTrackingId` and `prevScenariosListRef` are used to determine when NOT to refresh analysis.
  const [resultTrackingId, setResultTrackingId] = useState<number>(-1);
  const prevScenariosListRef = useRef<Scenario[]>();
  const subjectId = analysisConfig?.subject?.id;

  const { profileSettings } = useContext(UserContext);
  const userId = profileSettings?.user.id;
  const orgId = profileSettings?.organization.id;

  useEffect(() => {
    if (scenarios) {
      setScenariosList(scenarios);
    }
  }, [scenarios]);

  const updateScenariosList = useCallback(
    (newList: Scenario[] | undefined) => {
      setScenariosList(newList);
      if (subjectId && !loading) {
        onUpdateAnalysisViewParam({ scenarios: newList });
      }
    },
    [subjectId, loading, onUpdateAnalysisViewParam],
  );

  useEffect(() => {
    const fetchScenariosList = async () => {
      try {
        setLoading(true);
        abortController.current = new AbortController();
        const recentScenarios = userId && orgId ? getMostRecentAnalysis(userId, orgId)?.state?.scenarios : undefined;
        const res = await (scenariosCustomParamId
          ? // Fetch scenarios associated with the current analysis view
            getSavedViewScenarios(scenariosCustomParamId, abortController.current.signal)
          : recentScenarios?.length
            ? // If no saved view present, try getting the most recently used scenarios in this browser
              Promise.resolve({ content: recentScenarios })
            : // Otherwise, fetch the default organization scenarios from the BE
              getScenarios(abortController.current.signal));
        updateScenariosList(limitAvailShock(res.content));
        setLoading(false);
      } catch {
        // fail
        setLoading(false);
      }
    };
    if (subjectId && !scenarios && !scenariosList) {
      fetchScenariosList();
    }
  }, [scenariosCustomParamId, subjectId, userId, orgId, scenarios, updateScenariosList, scenariosList]);

  useEffect(() => {
    const fetchIndicesRef = async () => {
      try {
        const res = await apiIndicesRef.current();
        setIndices(res.content.filter((index) => !index.category));
      } catch {
        // fail
      }
    };
    fetchIndicesRef();
  }, []);

  useEffect(() => {
    const fetchScenarioAnalysis = async () => {
      if (!analysisConfig || !analysisConfig.subject || !scenariosList || !scenariosList.length) {
        return;
      }
      try {
        if (!updateQuietlyFlag.current) {
          // set row loading for faster ui feedback when user update portfolio
          setScenariosResult(convertLoading(scenariosList));
        }

        const { subject, relative, category, trackingId, selectedPeriod, selectedTimeFrame } = analysisConfig;
        const configs = getAnalysisRequest(
          ['SCENARIO'],
          subject,
          selectedTimeFrame,
          relative,
          category === 'ON',
          selectedPeriod,
          trackingId,
          undefined,
          undefined,
          scenariosList,
        );
        const res = await apiAnalysisRef.current(configs);
        const analysisResult = convertResponse(configs, res?.content?.analyses?.[0]?.scenarios);
        setScenariosResult(analysisResult);
        if (res?.content?.analyses?.[0]?.message) {
          setAnalysesError(res?.content?.analyses?.[0]?.message);
          setAnalysesPeriod({
            maxStartTime: res?.content?.maxStartTime,
            maxEndTime: res?.content?.maxEndTime,
            frequency: res?.content?.maxFrequency,
          });
        } else {
          setAnalysesError(undefined);
        }
        setResultTrackingId(trackingId);
      } catch (e) {
        // TODO: add analysis error logic
        logExceptionIntoSentry(e);
      }
      // reset the flag
      if (updateQuietlyFlag.current) {
        updateQuietlyFlag.current = false;
      }
    };
    // Only re-fetch analysis results if analysisConfig.trackingId (or scenariosList) has changed.
    if (analysisConfig.trackingId !== resultTrackingId || prevScenariosListRef.current !== scenariosList) {
      fetchScenarioAnalysis();
    }
  }, [analysisConfig, scenariosList, resultTrackingId]);

  const onDelete = useCallback(
    (scenarioId: string) => {
      try {
        updateQuietlyFlag.current = true;
        const deletedScenario = assertNotNil(scenariosList?.find((s) => s.id === scenarioId));
        const newScenariosResult = scenariosResult.filter((item) => item.id !== scenarioId);
        // faster ui response
        setScenariosResult(newScenariosResult);
        const newScenarioList = scenariosList?.filter((item) => item.id !== scenarioId);
        updateScenariosList(newScenarioList);
        analyticsService.scenarioDeleted({
          entryInRange: true,
          entryReturn: deletedScenario.shock,
          scenarioReturn: deletedScenario.shock,
          scenarioStd: (deletedScenario.shock - deletedScenario.mean) / deletedScenario.std,
          scenarioIndexName: deletedScenario.fundName,
          location: 'Analysis',
          type: deletedScenario.type,
        });
      } catch {
        // fail
      }
    },
    [scenariosResult, scenariosList, updateScenariosList],
  );

  const onAdd = useCallback(
    (fundId: string, shock: number, rawValue: number) => {
      const config = indices.find((list) => list.fundId === fundId);
      if (!config) {
        return;
      }
      config.id = uuid();
      try {
        // set loading status for a faster ui feedback
        setScenariosResult((prev) => [
          ...prev,
          {
            ...config,
            shock,
            mainPredict: {
              predicted: 0,
              predictedError: 0,
              factorsPredictedContributionMap: {},
              factorsPredictedErrorMap: {},
              factorsPredictedImpactErrorMap: {},
              factorsPredictedImpactMap: {},
              factorsPredictedMap: {},
              cashRateContribution: 0,
              cashRateImpact: 0,
              betas: [],
              status: 'SUCCESS',
            },
            loading: true,
          },
        ]);

        const newScenario: Scenario = {
          id: config.id,
          fundId: config.fundId,
          type: config.type,
          shock,
          mean: config.mean,
          minShock: config.shockRange.minShock,
          maxShock: config.shockRange.maxShock,
          minShockZScore: config.shockRange.minShockZScore,
          maxShockZScore: config.shockRange.maxShockZScore,
          std: config.std,
          units: config.units,
          currency: config.currency,
          fundName: config.fundName,
          seriesType: config.seriesType,
        };

        analyticsService.scenarioCreated({
          entryInRange: rawValue === newScenario.shock,
          entryReturn: rawValue,
          scenarioReturn: newScenario.shock,
          scenarioStd: (newScenario.shock - newScenario.mean) / newScenario.std,
          scenarioIndexName: newScenario.fundName,
          location: 'Analysis',
          type: config.type,
        });

        updateQuietlyFlag.current = true;
        updateScenariosList(limitAvailShock([...(scenariosList ?? []), newScenario]));
      } catch {
        // fail
        setScenariosResult((prev) =>
          prev.map((list) => ({
            ...list,
            loading: false,
          })),
        );
      }
    },
    [indices, scenariosList, updateScenariosList],
  );

  const onChange = useCallback(
    (scenario: Partial<Scenario>, rawValue: number) => {
      try {
        if (!scenariosList) return;
        // set loading status
        setScenariosResult((prev) => {
          const changeIndex = findIndex(prev, (item) => item.id === scenario.id);
          const updateLoadingResult = {
            ...scenariosResult[changeIndex],
            loading: true,
          };
          const loadingResult = [...scenariosResult];
          loadingResult.splice(changeIndex, 1, updateLoadingResult);
          return loadingResult;
        });
        updateQuietlyFlag.current = true;
        // update list
        const changeIndex = findIndex(scenariosList, (item) => item.id === scenario.id);
        const newItem: Scenario = {
          ...scenariosList[changeIndex],
          shock: scenario.shock ?? 0,
        };
        const scenarioStd = (newItem.shock - newItem.mean) / newItem.std;
        if (!scenarioStd || Math.abs(scenarioStd) === Infinity) {
          logMessageToSentry(
            `Null scenario modified deviation. Shock ${newItem.shock}, mean ${newItem.mean}, std ${newItem.std}`,
          );
        } else {
          analyticsService.scenarioModified({
            entryInRange: rawValue === newItem.shock,
            entryReturn: rawValue,
            scenarioReturn: newItem.shock,
            scenarioStd,
            scenarioIndexName: newItem.fundName,
            location: 'Analysis',
            type: newItem.type,
          });
        }
        const newList: Scenario[] = [...scenariosList];
        newList.splice(changeIndex, 1, newItem);
        updateScenariosList(newList);
      } catch {
        setScenariosResult((prev) =>
          prev.map((list) => ({
            ...list,
            loading: false,
          })),
        );
      }
    },
    [scenariosList, scenariosResult, updateScenariosList],
  );

  useEffect(() => {
    prevScenariosListRef.current = scenariosList;
  }, [scenariosList]);

  const blockName = 'Sensitivity Analysis';
  const subject = analysisConfig.subject;
  const subjectName = analysisConfig?.subject?.name || '';
  const relative = analysisConfig?.relative;
  const fileName = relative ? `Relative ${blockName} - ${subjectName} ` : `${blockName} - ${subjectName} `;
  const excelData = useMemo(
    () => convertScenarioDataToExcel(analysisConfig, scenariosResult),
    [analysisConfig, scenariosResult],
  );
  const waitingForRequest = resultTrackingId < analysisConfig.trackingId;
  const [TrackSuccessfulAnalysis, trackingProps] =
    !analysesError && !loading && !waitingForRequest
      ? [TrackSuccessfulAnalysisComponent, noChartTrackingProps]
      : [Fragment, {} as TrackAnalysisProps];
  const hasLoadingStatus = scenariosResult.some((item) => item.loading);
  const errorTrackingProps = {
    subject,
    analysesPeriod,
    isAdvancedAnalysis: true,
    isScenarioAnalysis: true,
    onResetAnalysisPeriod: onResetTimeFrame,
    error: {
      // TODO: (VENN-20577 / TYPES) is autogenerated type for Message wrong, should it contain the .message field?
      message: analysesError?.text ?? (analysesError as AnyDuringEslintMigration)?.message,
      code: analysesError?.code,
    },
    regressionName: blockName,
    relativeToBenchmark: relative,
    categoryActive: analysisConfig.category === 'ON',
    blockNames: ['SCENARIO'],
    blockTitles: [blockName],
    trackingId: analysisConfig.trackingId,
    updateAnalysisStatusForTracking,
  };

  if (!loading && !waitingForRequest && analysesError && analysesError.code === SupportedErrorCodes.NotAllowed) {
    return <FailedAnalysisInfo {...errorTrackingProps} />;
  }

  const scenarioHeader = `${blockName}${relative ? ' Relative to Benchmark' : ''}`;
  const scenarioSubHeader = `Given your current factor exposures, how might your portfolio/investment perform over the next month under different market conditions${relative ? ', relative to benchmark?' : '?'}`;

  return (
    <DownloadableContentBlock
      header={scenarioHeader}
      subHeader={scenarioSubHeader}
      downloadable={{
        png: true,
        excel: excelData,
        tracking: {
          description: 'SCENARIO',
          relativeToBenchmark: relative,
          subjectType: subject?.type,
          subjectId: subject?.id,
          userUploaded: !!subject?.userUploaded,
        },
        disabled: scenariosResult.length === 0 || !!analysesError || hasLoadingStatus,
        options: {
          fileName,
          metaData: downloadMetaData,
        },
      }}
    >
      {!analysesError && (
        <TrackSuccessfulAnalysis {...trackingProps}>
          <ScenarioAnalysisTable
            scenariosResult={scenariosResult}
            analysisConfig={analysisConfig}
            onDelete={onDelete}
            indices={indices}
            onAdd={onAdd}
            onChange={onChange}
          />
        </TrackSuccessfulAnalysis>
      )}
      {loading && <Loading data-cy="qa-loading" />}
      {scenariosResult.length === 0 && !loading && !analysesError && <EmptyMessage />}
      {analysesError && !loading && !waitingForRequest && (
        <Wrapper>
          <FailedAnalysisInfo {...errorTrackingProps} />
        </Wrapper>
      )}
    </DownloadableContentBlock>
  );
};

const Loading = styled.div`
  height: 368px;
  margin-top: 20px;
  ${Shimmer};
`;

const Wrapper = styled.div`
  padding: 0 20px 20px 20px;
`;
