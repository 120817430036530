import { useQuery } from '@tanstack/react-query';
import { getActiveCashflowSettings } from 'venn-api';
import { logExceptionIntoSentry } from 'venn-utils';
import type { CashFlowSettingRow } from '../../../../../venn-frontend/src/manage-data-page/src/privates/cashflow-pacing/PrivateCashflowPacingSettingsColumns';

const KEY = 'FETCH_CASH_FLOW_PACING_SETTINGS';

export const useActiveCashflowSetting = (fundIdNamePairs: [string, string][]) => {
  const fundIds = fundIdNamePairs.map(([fundId, _]) => fundId);
  const fundNames = fundIdNamePairs.map(([_, fundName]) => fundName);
  return useQuery<CashFlowSettingRow[]>(
    [KEY, fundIds, fundNames],
    async () => {
      const parameters = (await getActiveCashflowSettings(fundIds)).content;

      return fundIdNamePairs.map(([fundId, fundName]) => ({
        fundName,
        fundId,
        setting: parameters[fundId],
      }));
    },
    {
      onError: (error) => {
        return logExceptionIntoSentry(error as Error);
      },
      suspense: true,
    },
  );
};
