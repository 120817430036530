import React from 'react';
import type { PrivatePortfolioNode } from 'venn-api';
import type { SearchMenuItem } from 'venn-components';

interface FieldType<T> {
  value: T;
  error: string;
  isLoading: boolean;
}

export type CreatePrivatePortfolioContext = {
  name: FieldType<string>;
  setName: React.Dispatch<React.SetStateAction<CreatePrivatePortfolioContext['name']>>;
  sourcePortfolio: FieldType<SearchMenuItem | null>;
  setSourcePortfolio: React.Dispatch<React.SetStateAction<CreatePrivatePortfolioContext['sourcePortfolio']>>;
  createPortfolio: (investments: PrivatePortfolioNode[]) => Promise<PrivatePortfolioNode>;
  isSubmitting: boolean;
};

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
const context = React.createContext<CreatePrivatePortfolioContext>({
  name: {
    value: '',
    error: '',
    isLoading: false,
  },
  setName: () => {},
  sourcePortfolio: {
    value: null,
    error: '',
    isLoading: false,
  },
  setSourcePortfolio: () => {},
  createPortfolio: (_) => Promise.reject(),
  isSubmitting: false,
});

export const { Provider, Consumer } = context;
export default context;
