import type {
  AnalysisTemplateTypeEnum,
  AppUser,
  GeneralAnalysisTemplate,
  AnalysisBlock,
  AnalysisBlockTypeEnum,
} from 'venn-api';
import type { FeatureName } from '../feature-availability/index';
import { FS } from '../feature-availability/index';
import type { AnalysisConfig } from './types';
import { getAppTitleSimplifiedUtil } from '../utils';

const UNSUPPORTED_BLOCK = 'PAIRWISE_CORRELATION';

export const vennAppUser: AppUser = {
  displayName: `${getAppTitleSimplifiedUtil()}`,
  active: true,
  id: -42,
  name: `${getAppTitleSimplifiedUtil()}`,
  actualUser: false,
  accountId: 1,
};

export const emptyAnalysisTemplate = (): GeneralAnalysisTemplate => ({
  analysisBlocks: [],
  appUser: vennAppUser,
  description: '',
  id: '',
  name: '',
  analysisTemplateType: 'CUSTOM',
});

export const getTemplateById = (
  templates: GeneralAnalysisTemplate[],
  templateId: string | undefined,
  templateType?: AnalysisTemplateTypeEnum,
): GeneralAnalysisTemplate | undefined =>
  templates.find((template) => {
    if (templateType) {
      return template.id === templateId && template.analysisTemplateType === templateType;
    }
    return template.id === templateId;
  });

export const getAvailableTemplates = (templates: GeneralAnalysisTemplate[]): GeneralAnalysisTemplate[] =>
  templates.filter(
    (template) =>
      !template.pricingFeatures ||
      template.pricingFeatures.every((feature) => FS.has(feature.toLocaleLowerCase() as FeatureName)),
  );

export const getAvailableBlocks = (templates: GeneralAnalysisTemplate[]): AnalysisBlock[] => {
  const blocks = new Set<AnalysisBlock>();
  templates.forEach((template) => {
    if (template.analysisTemplateType === 'BLOCK') {
      if (
        !template.pricingFeatures ||
        template.pricingFeatures.every((feature) => FS.has(feature.toLocaleLowerCase() as FeatureName))
      ) {
        template.analysisBlocks
          .filter((block) => block.analysisBlockType !== UNSUPPORTED_BLOCK)
          .forEach((block) => blocks.add(block));
      }
    }
  });
  return [...blocks];
};

export const getBlockByType = (
  templates: GeneralAnalysisTemplate[],
  blockType: AnalysisBlockTypeEnum,
): AnalysisBlock | undefined => getAvailableBlocks(templates).find((block) => block.analysisBlockType === blockType);

export const isNewBlock = (block: GeneralAnalysisTemplate) => {
  if (!block || !block.created) {
    return false;
  }
  const date = new Date();
  const createdBy = date.setMonth(date.getMonth() - 1);
  return createdBy < block.created;
};

export const hasAccessToTemplate = (template: GeneralAnalysisTemplate) =>
  !template.pricingFeatures ||
  template.pricingFeatures.every((feature) => FS.has(feature.toLocaleLowerCase() as FeatureName));

// Some list is just for display purpose, we won't show them from template list.
const LIST_HIDDEN_TEMPLATES = ['welcome', 'compare', 'custom', UNSUPPORTED_BLOCK];

/**
 *
 * @param templates All templates
 * @returns filter templates that should be hidden from search results or selectable list.
 */
export const getDisplayTemplates = (templates: GeneralAnalysisTemplate[]) =>
  templates.filter((template) => !LIST_HIDDEN_TEMPLATES.includes(template.id));

export const getBlocksExceptPerformance = (analysisConfig: AnalysisConfig): AnalysisBlockTypeEnum[] =>
  (analysisConfig.analysisTemplate?.analysisBlocks ?? [])
    .map((block) => block.analysisBlockType)
    .filter((blockType) => blockType !== 'PERFORMANCE_SUMMARY');
