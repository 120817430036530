import type { FC } from 'react';
import React, { useEffect, useRef } from 'react';
import type { AnalysesPeriod, AnalysisSubjectType } from 'venn-utils';

export interface AnalysisStatusForTracking {
  name: string;
  title: string;
  succeeded: boolean;
  errorCode?: number;
  analysisType: string;
  analysesPeriod?: AnalysesPeriod;
}

export interface TrackAnalysisProps {
  blockNames: string[];
  blockTitles: string[];
  analysisType: string;
  errorCode?: number;
  trackingId: number;
  updateAnalysisStatusForTracking?: (
    actionAnalysisStatuses: AnalysisStatusForTracking[],
    actionTrackingId: number,
    dateRange?: string,
  ) => void;
  dateRange: string | undefined;
  analysesPeriod?: AnalysesPeriod;
  hasBenchmark: boolean;
  hasProxy: boolean;
  objectType?: AnalysisSubjectType;
  relativeToBenchmark: boolean;
}

const TrackAnalysis: FC<React.PropsWithChildren<TrackAnalysisProps & { succeeded: boolean }>> = ({
  succeeded,
  children,
  blockNames,
  blockTitles,
  analysisType,
  errorCode,
  analysesPeriod,
  trackingId,
  updateAnalysisStatusForTracking,
  dateRange,
}) => {
  const prevTrackingIdRef = useRef<number>(-1);
  useEffect(() => {
    if (
      prevTrackingIdRef.current !== trackingId &&
      trackingId !== -1 &&
      updateAnalysisStatusForTracking &&
      blockNames
    ) {
      updateAnalysisStatusForTracking(
        blockNames.map((blockName, idx) => ({
          name: blockName,
          title: blockTitles?.[idx],
          succeeded,
          errorCode,
          analysisType,
          analysesPeriod,
        })),
        trackingId,
        dateRange,
      );
    }
  }, [
    trackingId,
    updateAnalysisStatusForTracking,
    blockNames,
    analysisType,
    errorCode,
    analysesPeriod,
    blockTitles,
    succeeded,
    dateRange,
  ]);

  useEffect(() => {
    prevTrackingIdRef.current = trackingId;
  }, [trackingId]);

  return <>{children}</>;
};

export const TrackFailedAnalysis: FC<React.PropsWithChildren<TrackAnalysisProps>> = (props) => (
  <TrackAnalysis succeeded={false} {...props} />
);
export const TrackSuccessfulAnalysis: FC<React.PropsWithChildren<TrackAnalysisProps>> = (props) => (
  <TrackAnalysis succeeded {...props} />
);
