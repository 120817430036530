import type { FC } from 'react';
import React from 'react';
import { GetColor, LegacyRelativePortal, ZIndex } from 'venn-ui-kit';
import styled from 'styled-components';

export interface PortalPosition {
  left: number;
  top: number;
}

interface PopoverProps {
  position?: 'right' | 'left';
  visible: boolean;
  className?: string;
  onHover: () => void;
  portalPosition?: PortalPosition;
}

const Popover: FC<React.PropsWithChildren<PopoverProps>> = ({
  children,
  position,
  className,
  visible,
  onHover,
  portalPosition = {},
}) => {
  if (!visible) {
    return null;
  }

  const passThroughProps = {
    top: position === 'right' ? -118 : -102,
    left: -432,
    ...portalPosition,
  };
  return (
    <LegacyRelativePortal component="div" {...passThroughProps}>
      <Container className={className} onMouseEnter={onHover}>
        <Arrow />
        {children}
      </Container>
    </LegacyRelativePortal>
  );
};

const Container = styled.div`
  display: inline-block;
  position: relative;
  box-shadow: 0 0 20px ${GetColor.Grey};
  padding: 17px;
  z-index: ${ZIndex.Front};
  background-color: ${GetColor.White};
`;

const Arrow = styled.div`
  position: absolute;
  right: -10px;
  top: 100px;
  height: 20px;
  width: 20px;
  background-color: ${GetColor.White};
  transform: rotate(45deg);
`;

export default Popover;
