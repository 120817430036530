import noop from 'lodash/noop';
import omit from 'lodash/omit';
import type { CSSProperties, InputHTMLAttributes } from 'react';
import React from 'react';
import { type IconPosition, KeyCodes } from 'venn-ui-kit';
import Input from '../input/Input';
import type { Omit } from '../types';

export interface StatefulInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  textInputStyle?: CSSProperties;
  blurOnChange?: boolean;
  forceValueCheck?: boolean;
  value?: string | number;
  selectOnFocus?: boolean;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (value: string) => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onValueChange?: (localValue: string | number, event: React.SyntheticEvent<HTMLInputElement>) => void;
  // passed through to Input component
  icon?: JSX.Element[] | JSX.Element | string | null;
  iconPosition?: IconPosition;
  error?: boolean;
  innerRef?: React.RefObject<HTMLInputElement>;
  charactersLimit?: number;

  refreshedStyling?: boolean;
}

export interface StatefulInputState {
  localValue: string | number;
}

export default class StatefulInput extends React.Component<StatefulInputProps, StatefulInputState> {
  static defaultProps: Partial<StatefulInputProps> = {
    blurOnChange: false,
    forceValueCheck: false,
    onBlur: noop,
    onChange: noop,
    onKeyUp: noop,
    onValueChange: noop,
  };

  static getDerivedStateFromProps(nextProps: StatefulInputProps): StatefulInputState {
    return {
      localValue: nextProps.value || '',
    };
  }

  constructor(props: StatefulInputProps) {
    super(props);

    this.state = {
      localValue: props.value || '',
    };
  }

  onChange = (localValue: string) => {
    this.setState({ localValue });
    this.props.onChange && this.props.onChange(localValue);
  };

  onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { blurOnChange, onKeyUp, onValueChange } = this.props;
    const { localValue } = this.state;
    const { target, keyCode } = event;
    if (onKeyUp) {
      onKeyUp(event);
    }
    if (keyCode === KeyCodes.Enter) {
      if (blurOnChange) {
        (target as HTMLInputElement).blur();
      } else if (onValueChange) {
        onValueChange(localValue, event);
      }
    }
  };

  onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { forceValueCheck, onBlur, onValueChange } = this.props;
    const { value } = event.target;
    const updateValue = !forceValueCheck || this.props.value !== value;

    if (updateValue && onValueChange) {
      onValueChange(value, event as React.SyntheticEvent<HTMLInputElement>);
    }

    if (onBlur) {
      onBlur(event);
    }
  };

  onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    const { selectOnFocus, onFocus } = this.props;
    if (selectOnFocus && event.currentTarget) {
      event.currentTarget.select();
    }
    if (onFocus) {
      onFocus(event);
    }
  };

  render() {
    const { localValue } = this.state;
    const inputProps = omit(this.props, ['blurOnChange', 'forceValueCheck', 'onValueChange', 'onFocus', 'innerRef']);
    return (
      <Input
        {...inputProps}
        setInputRef={this.props.innerRef}
        value={(localValue as string) || ''}
        onBlur={this.onBlur}
        onKeyUp={this.onKeyUp}
        onChange={this.onChange}
        onFocus={this.onFocus}
      />
    );
  }
}
