import type { FC } from 'react';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { TooltipPosition, Tooltip, TooltipBodyDirection } from 'venn-ui-kit';
import PercentageSpan from '../percentage-span/PercentageSpan';
import NumericSpan from '../numeric-span/NumericSpan';

const StyledTooltipContent = styled.div`
  min-width: 95px;
  display: flex;
  justify-content: space-around;
  height: 13px;
  font-size: 11px;
`;

const StyledValue = styled.div`
  font-weight: bold;
`;

const StyledDifference = styled.div`
  font-style: italic;
  margin-left: 6px;
`;

interface AllocationDifferenceTooltipProps {
  value: number;
  difference: number;
  hasDifference: boolean;
  position?: TooltipPosition;
  isPercentageMode: boolean;
  hidden?: boolean;
  usePortal?: boolean;
  className?: string;
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export const AllocationValue = React.memo(
  ({ isPercentageMode, value, testId }: { isPercentageMode: boolean; value: number; testId?: string }) => (
    <StyledValue data-testid={testId}>
      {isPercentageMode ? (
        <PercentageSpan precision={1}>{value / 100}</PercentageSpan>
      ) : (
        <NumericSpan prefix="$">{value}</NumericSpan>
      )}
    </StyledValue>
  ),
);

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export const AllocationDifference = React.memo(
  ({ difference, testId, isPercentageMode }: { difference: number; testId?: string; isPercentageMode?: boolean }) => (
    <StyledDifference data-testid={testId}>
      <NumericSpan precision={1} prefix={difference > 0 ? '+' : undefined} suffix={isPercentageMode ? '%' : ''}>
        {difference}
      </NumericSpan>
    </StyledDifference>
  ),
);

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
const AllocationDifferenceTooltip: FC<React.PropsWithChildren<AllocationDifferenceTooltipProps>> = React.memo(
  ({
    value,
    difference,
    hasDifference,
    children,
    position = TooltipPosition.Top,
    isPercentageMode,
    hidden,
    usePortal = false,
    className = 'allocation-diff-tooltip',
  }) => {
    const content = useMemo(
      () => (
        <StyledTooltipContent>
          <AllocationValue testId="qa-value" isPercentageMode={isPercentageMode} value={value} />
          {hasDifference && <AllocationDifference testId="qa-diff" difference={difference} />}
        </StyledTooltipContent>
      ),
      [difference, hasDifference, isPercentageMode, value],
    );
    return (
      <Tooltip
        usePortal={usePortal}
        isHidden={hidden}
        bodyDirection={TooltipBodyDirection.Center}
        position={position}
        maxWidth={250}
        content={content}
        className={className}
      >
        {children}
      </Tooltip>
    );
  },
);

export default AllocationDifferenceTooltip;
