import React from 'react';
import StudioContent from './components/StudioContent';
import StudioContextStore from './components/StudioContextStore';
import StudioEditorProvider from './components/StudioEditorProvider';
import { withPageByolWatermark } from 'venn-components';
import { StudioPageLoadingState } from './components/StudioPageLoadingState';

const StudioPage = React.memo(function StudioPage() {
  return (
    <StudioContextStore>
      <StudioEditorProvider>
        <React.Suspense fallback={<StudioPageLoadingState />}>
          <StudioContent />
        </React.Suspense>
      </StudioEditorProvider>
    </StudioContextStore>
  );
});

export default withPageByolWatermark(StudioPage);
