import { TypeaheadSearchBar } from 'venn-components';
import React, { useCallback } from 'react';
import { useRecentLibrarySearches } from './logic/useRecentLibrarySearches';

const RECENT_SEARCH_MAX_ITEMS = 5;
interface LibraryTypeaheadSearchBarProps {
  searchText?: string;
  onSearchTextChange: (arg0: string) => void;
  placeholder: string;
}
export const LibraryTypeaheadSearchBar = ({
  searchText = '',
  placeholder,
  onSearchTextChange,
}: LibraryTypeaheadSearchBarProps) => {
  const { recentSearches, saveRecentSearch } = useRecentLibrarySearches(RECENT_SEARCH_MAX_ITEMS);
  const handleSearchSelect = useCallback(
    (val: string) => {
      onSearchTextChange(val);
      saveRecentSearch(val);
    },
    [onSearchTextChange, saveRecentSearch],
  );
  return (
    <TypeaheadSearchBar
      placeholder={placeholder}
      value={searchText}
      onChange={onSearchTextChange}
      onSelect={handleSearchSelect}
      onBlur={() => saveRecentSearch(searchText)}
      debounce={500}
      dropdownOptions={recentSearches}
      dropdownOptionsHeader="Recent Searches"
      disableAutofocus
    />
  );
};
