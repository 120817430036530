import React, { useContext } from 'react';
import { Link as LinkStyle, FORECASTS_FAQ_HREF } from 'venn-ui-kit';
import { UserContext, ForecastsMenuLauncher } from 'venn-components';
import { StyledWrapper, Name, FlexWrapper, FlexRow, StyledButton, StyledIcon, Info } from './shared';
import type { MenuOpenerProps } from '../../../../venn-components/src/modals/forecasts/ForecastsMenuLauncher';

const ManageForecastsButton = ({ onClick }: MenuOpenerProps) => (
  <StyledButton style={{ marginLeft: 'auto', marginRight: 0 }} onClick={onClick} className="qa-manage-forecasts-btn">
    Manage Forecasts
  </StyledButton>
);

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default () => {
  const { hasPermission } = useContext(UserContext);
  const isReadOnly = !hasPermission('EDIT_FORECASTS');

  return (
    <StyledWrapper>
      <FlexRow>
        <FlexWrapper>
          <StyledIcon type="compass" />
          <Name>Forecasts</Name>
        </FlexWrapper>
        <ForecastsMenuLauncher
          ctaPurpose="Edit forecasts from Workspace Configuration page"
          renderCustomMenuOpener={(props) => <ManageForecastsButton {...props} />}
          isReadOnly={isReadOnly}
        />
      </FlexRow>
      <Info>
        Customize forecasts for assets.{' '}
        <LinkStyle>
          <a target="_blank" rel="noopener noreferrer" href={FORECASTS_FAQ_HREF}>
            Learn More
          </a>
        </LinkStyle>
      </Info>
    </StyledWrapper>
  );
};
