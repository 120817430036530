import React, { useCallback, useState } from 'react';
import type { DropMenuItem } from 'venn-ui-kit';
import { CheckboxWrapper, Label } from 'venn-ui-kit';
import styled from 'styled-components';
import {
  analysisViewIdState,
  type BenchmarkInputId,
  benchmarkInputIsRelative,
  benchmarkInputName,
  benchmarkInputs,
  benchmarkInputSubject,
  benchmarkInputType,
  getNewBenchmarkInputId,
  isReportState,
  nextBenchmarkInputNameState,
  originalAnalysisSubjectQuery,
} from 'venn-state';
import { constSelector, useRecoilCallback, useRecoilValue, waitForAll } from 'recoil';
import BenchmarkTypeDropdown from '../benchmark-type-dropdown/BenchmarkTypeDropdown';
import { InputEditor, InputEditorLoadState } from './InputEditor';
import { analyticsService, withSuspense } from 'venn-utils';
import type { CustomBenchmarkTypeEnum } from 'venn-api';
import { BenchmarkSelector } from '../analysis';

const COMMON_BENCH_MAX_HEIGHT = 625;
const NON_COMMON_BENCH_MAX_HEIGHT = 350;

interface BenchmarkEditorProps {
  /** The inputId to edit, or undefined if a new input should be created. */
  benchmarkId: BenchmarkInputId | undefined;
  /** Called with the saved input ID if the save was completed, or undefined if the user quit without saving. */
  onClose: (savedInputId?: BenchmarkInputId) => void;
}

export const BenchmarkEditor = withSuspense(
  <InputEditorLoadState maxHeight={NON_COMMON_BENCH_MAX_HEIGHT} />,
  ({ benchmarkId: initialBenchmarkId, onClose }: BenchmarkEditorProps) => {
    const benchmarkInputIds = useRecoilValue(benchmarkInputs);
    const nextBenchmarkInputName = useRecoilValue(nextBenchmarkInputNameState);
    const initialBenchmarkType = useRecoilValue(
      initialBenchmarkId ? benchmarkInputType(initialBenchmarkId) : constSelector('NONE'),
    );
    const initialBenchmarkIsRelative = useRecoilValue(
      initialBenchmarkId ? benchmarkInputIsRelative(initialBenchmarkId) : constSelector(false),
    );
    const benchmarkName = useRecoilValue(
      initialBenchmarkId ? benchmarkInputName(initialBenchmarkId) : constSelector(nextBenchmarkInputName),
    );
    const [tempName, setTempName] = useState(benchmarkName);

    const benchmarkSubject = useRecoilValue(
      initialBenchmarkId ? benchmarkInputSubject(initialBenchmarkId) : constSelector(undefined),
    );
    const benchmarkInputAnalysisSubject = useRecoilValue(
      benchmarkSubject ? originalAnalysisSubjectQuery(benchmarkSubject) : constSelector(undefined),
    );
    const [tempSubject, setTempSubject] = useState(benchmarkInputAnalysisSubject);
    const [tempType, setTempType] = useState(initialBenchmarkType);
    const [tempIsRelative, setTempIsRelative] = useState(initialBenchmarkIsRelative);

    const onChangeType = useCallback((item: DropMenuItem<CustomBenchmarkTypeEnum>) => {
      setTempType(item.value);
      setTempIsRelative(false);
      setTempSubject(undefined);
    }, []);

    const onSaveChanges = useRecoilCallback(
      ({ set, snapshot }) =>
        () => {
          const finalBenchmarkId = initialBenchmarkId ?? getNewBenchmarkInputId();
          const subject = tempSubject
            ? {
                fundId: tempSubject.fund?.id,
                portfolioId: tempSubject.portfolio?.id,
              }
            : undefined;

          snapshot.getPromise(waitForAll([isReportState, analysisViewIdState])).then(([isReport, viewId]) => {
            const trackingProps = {
              benchmark: tempSubject
                ? { name: tempSubject.name, id: tempSubject.id, type: tempSubject.type.toLowerCase() }
                : undefined,
              name: tempName || benchmarkName,
              relative: tempIsRelative,
              type: tempType,
              sourcePage: isReport ? 'REPORT_LAB' : 'STUDIO',
              viewId,
            };
            if (initialBenchmarkId) {
              analyticsService.inputsBenchmarkGroupModified(trackingProps);
            } else {
              analyticsService.inputsBenchmarkGroupCreated(trackingProps);
            }
          });

          set(benchmarkInputName(finalBenchmarkId), tempName || benchmarkName);
          set(benchmarkInputType(finalBenchmarkId), tempType);
          set(benchmarkInputIsRelative(finalBenchmarkId), tempIsRelative);
          set(benchmarkInputSubject(finalBenchmarkId), subject);
          if (!initialBenchmarkId) {
            set(benchmarkInputs, [...benchmarkInputIds, finalBenchmarkId]);
          }

          onClose(finalBenchmarkId);
        },
      [benchmarkName, initialBenchmarkId, tempName, tempType, tempIsRelative, tempSubject, onClose, benchmarkInputIds],
    );

    const onQuit = useCallback(() => {
      // TODO(will, collin, hesham): IMV2 onQuit should show an unsaved changes modal when benchmarkInputId is defined and changes are made.
      // Modal should only show if the benchmark input is actually used somewhere . Use a useRecoilCallback to do this
      // combined with modal state and <ConfirmationModal ../>

      onClose(undefined);
    }, [onClose]);

    return (
      <InputEditor
        title="Benchmarks"
        name={tempName}
        setName={setTempName}
        onClose={onQuit}
        onSave={onSaveChanges}
        isNew={!initialBenchmarkId}
        disabled={tempType === 'COMMON' && !tempSubject}
        maxHeight={tempType === 'COMMON' ? COMMON_BENCH_MAX_HEIGHT : NON_COMMON_BENCH_MAX_HEIGHT}
      >
        <div style={{ display: 'flex' }} data-testid="qa-benchmark-type-dropdown">
          <Section>
            <BenchmarkTypeDropdown
              onlyValue={false}
              onChangeBenchmarkType={onChangeType}
              customBenchmarkType={tempType}
            />
          </Section>
          {tempType !== 'NONE' && (
            <Section>
              <StyledLabel>Option</StyledLabel>
              <CheckboxWrapper checked={tempIsRelative} onChange={(event) => setTempIsRelative(event?.target?.checked)}>
                Relative to Benchmark
              </CheckboxWrapper>
            </Section>
          )}
        </div>
        <div style={{ marginBottom: 40 }}>
          {tempType === 'COMMON' && (
            <Section>
              <BenchmarkSelector value={tempSubject} onChange={setTempSubject} disabled={false} smallScreen />
            </Section>
          )}
        </div>
      </InputEditor>
    );
  },
);

const Section = styled.div`
  margin: 20px;
`;

const StyledLabel = styled(Label)`
  line-height: 1.2;
  margin-bottom: 8px;
  display: inline-block;
`;
