import { Body1, Button, FilterPill, GetColor } from 'venn-ui-kit';
import { getResultRangeEnd, getResultRangeStart, LibraryTab, plural } from 'venn-utils';
import SaveFiltersTriggerMenu from './components/filters/components/advanced/SaveFiltersTriggerMenu';
import type { ReactNode } from 'react';
import React from 'react';
import styled from 'styled-components';
import type { FilterPillProps } from './components/logic/filterPills';
import { selectedLibraryTabAtom } from 'venn-state';
import { useRecoilValue } from 'recoil';

interface LibraryTableAnnotationParams {
  bulkActionsComponent: ReactNode;
  pills: FilterPillProps[];
  userSelectedRowsCount: number;
  totalRowsCount: number;
  displayedRowsCount: number;
  displayedPage: number;
  clearAllFilters: () => void;
  pageSize: number;
}
export const LibraryTableAnnotation = ({
  pills,
  bulkActionsComponent,
  userSelectedRowsCount,
  totalRowsCount,
  displayedRowsCount,
  displayedPage,
  clearAllFilters,
  pageSize,
}: LibraryTableAnnotationParams) => {
  const selectedLibraryTab = useRecoilValue(selectedLibraryTabAtom);
  const rangeStart = getResultRangeStart(displayedRowsCount, displayedPage, pageSize);
  const rangeEnd = getResultRangeEnd(displayedRowsCount, displayedPage, pageSize);
  const showPills = !!pills.length;

  return userSelectedRowsCount ? (
    <ActionsContainer>
      <Body1>{userSelectedRowsCount} Selected</Body1>
      <ItemsSelectedDivider />
      {bulkActionsComponent}
    </ActionsContainer>
  ) : (
    <FilterPillContainer className="qa-filter-pills">
      <ResultCount>
        {showPills
          ? plural(totalRowsCount, { 1: '{{count}} result', other: '{{count}} results' })
          : `Showing ${rangeStart}-${rangeEnd} of ${totalRowsCount}`}
      </ResultCount>
      {showPills && (
        <>
          <ItemsSelectedDivider />
          <FilterPills>
            {pills?.map(({ key, label, onClick, icon }) => (
              <FilterPill key={key} name={label} iconType={icon} onClose={onClick} />
            ))}
          </FilterPills>
          {selectedLibraryTab === LibraryTab.ReturnsData && <SaveFiltersTriggerMenu />}
          <Button className="qa-clear-all" flat onClick={clearAllFilters} style={{ minHeight: 20, padding: 0 }}>
            Clear All
          </Button>
        </>
      )}
    </FilterPillContainer>
  );
};

const ItemsSelectedDivider = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin: 0 13px 0 19px;
  width: 1px;
  height: 24px;
  border: solid 0.5px ${GetColor.Grey};
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const FilterPillContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: calc(100% - 100px);
`;

const FilterPills = styled.div`
  overflow-x: auto;
  display: flex;
  max-width: calc(100vw - 500px);
  white-space: nowrap;
  ::-webkit-scrollbar {
    height: 8px;
  }
`;

const ResultCount = styled(Body1)`
  white-space: nowrap;
`;
