import React, { useRef, useEffect, useCallback, useContext } from 'react';
import type { HeatMapTypes } from 'venn-components';
import { ThemeContext } from 'styled-components';
import { isNil } from 'lodash';
import type { MouseEventType } from './Cell';

const PRINT_FONT_SIZE = 10;
const FONT_SIZE = 14;
const CELL_PADDING = 8;

interface RowProps {
  rowData: HeatMapTypes.DataEntity[];
  rowId: number;
  cellWidth: number;
  cellHeight: number;
  print?: boolean;
  showValue?: boolean;
  onCellMouseEnter: MouseEventType;
}

const formatValue = (value: number): string => (value === 1 ? '1' : value.toFixed(2));

const CanvasRow = ({ rowData, rowId, cellWidth, cellHeight, print, showValue, onCellMouseEnter }: RowProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const { Colors } = useContext(ThemeContext);

  const onMouseOver = useCallback(
    (mouseEvent: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
      const columnId = Math.floor(
        (mouseEvent.clientX - mouseEvent.currentTarget.getBoundingClientRect().left) / cellWidth,
      );
      onCellMouseEnter({
        columnId,
        rowId,
      });
    },
    [onCellMouseEnter, cellWidth, rowId],
  );

  useEffect(() => {
    if (!canvasRef.current) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d')!;

    // HACK: for some reason using ctx.clearRect doesn't work properly in some PDF viewers.
    // This is some sort of deep stack bug, either in Chrome or in the PDF engine itself. Instead we can use the commonly known hack of
    // canvas.width=canvas.width which forces canvas to completely reset itself, similarly to ctx.clearRect but even more of a total
    // reset and with somewhat worse performance.
    // eslint-disable-next-line no-self-assign
    canvas.width = canvas.width;

    rowData.forEach((data, i) => {
      const statisticallySignificant = !!data.value;
      const positionProps = {
        x: i * cellWidth,
        y: 0,
        height: cellHeight,
        width: cellWidth,
      };
      // Draw the rectangle

      if (statisticallySignificant) {
        ctx.fillStyle = data.color;
        ctx.fillRect(positionProps.x, positionProps.y, positionProps.width, positionProps.height);
      }

      if (showValue && !isNil(data.value)) {
        // Draw the text
        const fontSize = print ? PRINT_FONT_SIZE : FONT_SIZE;
        ctx.font = `${fontSize}px sans-serif`;
        ctx.textAlign = 'end';
        ctx.textBaseline = 'middle';
        ctx.fillStyle = data.value > 0.5 || data.value < -0.5 ? 'white' : 'black';
        ctx.fillText(
          formatValue(data.value),
          positionProps.x + cellWidth - CELL_PADDING,
          positionProps.y + cellHeight / 2,
        );
      }
    });
  }, [rowData, rowId, cellWidth, cellHeight, print, showValue, Colors]);

  return (
    <canvas
      style={{
        // Display block prevents small gaps between rows
        display: 'block',
      }}
      ref={canvasRef}
      width={cellWidth * rowData.length}
      height={cellHeight}
      onMouseMove={onMouseOver}
    />
  );
};

export default CanvasRow;
