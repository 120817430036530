import React from 'react';
import styled from 'styled-components';
import type { BasicTableColumn, ReturnsData } from 'venn-components';
import { ColumnAlign, SORTDIR } from 'venn-components';
import { Tooltip, TooltipPosition, GetColor, ColorUtils } from 'venn-ui-kit';
import { Numbers } from 'venn-utils';

interface Header {
  name: string;
  tooltip?: string;
}

export function getColumns(returns: ReturnsData[]) {
  const periods: Header[] =
    returns.length && returns[0].returns.length === 4
      ? [
          { name: 'Q1', tooltip: 'January to end of March' },
          { name: 'Q2', tooltip: 'April to end of June' },
          { name: 'Q3', tooltip: 'July to end of September' },
          { name: 'Q4', tooltip: 'October to end of December' },
        ]
      : ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].map((month) => ({
          name: month,
        }));
  const columns: BasicTableColumn<ReturnsData>[] = [
    {
      label: 'Year',
      accessor: 'year',
      cellRenderer: (row) => <Cell>{row.year}</Cell>,
      sortable: true,
      sorted: SORTDIR.DESC,
      align: ColumnAlign.RIGHT,
      cellStyle: { fontWeight: 'bold' },
      excelCellRenderer: (data) => ({ value: data?.year, bold: true }),
      excelHeaderRenderer: (label) => ({ value: label, width: 10, bold: true }),
    },
    ...periods.map(
      (period, index): BasicTableColumn<ReturnsData> => ({
        label: period.name,
        accessor: period.name,
        headerRenderer: (label) =>
          period.tooltip ? (
            <Tooltip content={period.tooltip} position={TooltipPosition.Bottom}>
              <span>{label}</span>
            </Tooltip>
          ) : (
            label
          ),
        cellRenderer: (row) => {
          const rowReturn = row.returns[index];
          return (
            <Cell proxy={rowReturn?.isProxy} greyedOut={!rowReturn?.isInRange}>
              {rowReturn ? Numbers.safeFormatPercentage(rowReturn.value) : ''}
            </Cell>
          );
        },
        sortValueFunc: (row) => row?.returns?.[index]?.value ?? NaN,
        sortable: true,
        align: ColumnAlign.RIGHT,
        excelCellRenderer: (data) => ({ value: data?.returns?.[index]?.value, percentage: true, digits: 2 }),
        excelHeaderRenderer: (label) => ({ value: label, width: 10, bold: true }),
      }),
    ),
    {
      label: 'Total',
      accessor: 'total',
      cellRenderer: (row) => <Cell>{Numbers.safeFormatPercentage(row.total?.value)}</Cell>,
      sortValueFunc: (row) => row.total?.value ?? NaN,
      sortable: true,
      align: ColumnAlign.RIGHT,
      cellStyle: { fontWeight: 'bold' },
      excelCellRenderer: (data) => ({ value: data?.total?.value, percentage: true, bold: true, digits: 2 }),
      excelHeaderRenderer: (label) => ({ value: label, width: 10, bold: true }),
    },
  ];

  return columns;
}

const Cell = styled.div<{ proxy?: boolean; greyedOut?: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-right: 15px;
  background-color: ${(props) =>
    props.proxy ? ColorUtils.hex2rgbaFrom(GetColor.DEPRECATED_DataLineColor.PaleGold, 0.1) : 'inherit'};
  color: ${(props) => (!props.greyedOut ? GetColor.Black : GetColor.HintGrey)};

  @media (max-width: 1500px) {
    padding-right: 3px;
  }
`;
