import React from 'react';
import styled from 'styled-components';
import { GetColor, Icon } from 'venn-ui-kit';
import { SpecialCssClasses } from 'venn-utils';

export interface CarouselButtonProps {
  selected: boolean;
  onClick: () => void;
}

const CarouselButton = ({ onClick, selected }: CarouselButtonProps) => (
  <CarouselButtonClickableContainer
    data-testid="carousel-button"
    onClick={onClick}
    className={SpecialCssClasses.NotDownloadable}
  >
    <StyledIcon type="circle" selected={selected} />
  </CarouselButtonClickableContainer>
);
export default CarouselButton;
const CarouselButtonClickableContainer = styled.a`
  margin-left: 8px;
  margin-right: 8px;
`;

const StyledIcon = styled(Icon)<{ selected: boolean }>`
  color: ${({ selected }) => (selected ? GetColor.Black : GetColor.Grey)};
  cursor: pointer;
`;
