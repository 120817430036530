import React, { useCallback, useState } from 'react';
import type { SingleCustomOptionMenuItem } from 'venn-ui-kit';
import { GetColor, SingleCustomOptionDropMenu } from 'venn-ui-kit';
import { isNil } from 'lodash';
import styled from 'styled-components';

const TRIGGER_CN = 'custom-rolling-window-trigger';
const MENU_CN = 'custom-rolling-window-menu';

interface RollingWindowDropMenuProps {
  items: SingleCustomOptionMenuItem[];
  selected?: SingleCustomOptionMenuItem;
  onSelect: (item: SingleCustomOptionMenuItem) => void;
  horizontalPosition?: 'left' | 'right';
  width?: number;
  dropdownWidth?: number;
  disabled?: boolean;
}

const RollingWindowDropMenu = ({
  items,
  selected,
  onSelect,
  horizontalPosition = 'right',
  width,
  dropdownWidth,
  disabled,
}: RollingWindowDropMenuProps) => {
  const [customItem, setCustomItem] = useState<number>();
  const onGetOptionFromInput = useCallback((input: string) => {
    // First check if the input contains a number
    let i = 0;
    while (i < input.length && input[i] >= '0' && input[i] <= '9') {
      i++;
    }
    // If no numeric characters are found, there is no selected option
    // If the number of months is too high, there is no selected option
    if (i === 0 || i > 3) {
      setCustomItem(undefined);
      return;
    }
    const numberOfMonths = Number.parseInt(input.slice(0, i), 10);
    setCustomItem(Number.isNaN(numberOfMonths) ? undefined : numberOfMonths);
  }, []);
  return (
    <Wrapper horizontalPosition={horizontalPosition}>
      <SingleCustomOptionDropMenu
        headerComponent={<MenuHeader>Input a period in months, e.g. "6M"</MenuHeader>}
        disabled={isNil(selected) || disabled}
        items={items}
        customItem={
          isNil(customItem)
            ? undefined
            : {
                label: `${customItem}M`,
                value: customItem,
              }
        }
        selected={selected}
        onInputChange={onGetOptionFromInput}
        onSelect={onSelect}
        width={width}
        menuWidth={dropdownWidth}
        className={TRIGGER_CN}
        menuClassName={MENU_CN}
      />
    </Wrapper>
  );
};

export default RollingWindowDropMenu;

interface RollingStylesProps {
  horizontalPosition: 'left' | 'right';
}

const Wrapper = styled.div<RollingStylesProps>`
  .${TRIGGER_CN} {
    .qa-dropmenu-trigger {
      height: 25px;
      padding: 2px;
      border-radius: 2px;

      > div {
        padding: 0 4px;
      }
    }
    input {
      height: 21px;
      padding-left: 5px;
      padding-right: 5px;
    }

    .qa-dropmenu-item > div {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .${MENU_CN} {
    ${(props) => (props.horizontalPosition === 'right' ? 'right: 50px;' : 'right: 0')};
    position: relative;
  }
`;

const MenuHeader = styled.div`
  padding: 8px 7px 0 7px;
  font-size: 12px;
  color: ${GetColor.HintGrey};
  font-weight: normal;
`;
