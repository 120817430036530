import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import type { SimpleMenuPropsWithLink } from '../menus/SimpleMenu';
import { default as SimpleMenu } from '../menus/SimpleMenu';
import BaseDropMenu from './BaseDropMenu';
import type { BaseDropMenuProps, MenuContainerProps } from '../types';
import IconTrigger from '../triggers/IconTrigger';

interface IconDropMenuProps<T> extends SimpleMenuPropsWithLink<T>, BaseDropMenuProps<T>, MenuContainerProps {
  icon: string;
  iconSize?: number;
  text?: string | ReactNode;
  iconSolid?: boolean;
  showCaret?: boolean;
  menuOnRight?: boolean;
  // Refresh results on open
  onUpdateItems?: () => Promise<void>;
  onToggleCallback?: (isOpen: boolean) => void;
}

export function IconDropMenu<T>({
  items,
  selected,
  openByDefault,
  usePortal,
  link,
  width,
  height,
  comparer,
  onLinkClick,
  onChange,
  icon,
  iconSize,
  disabled,
  text,
  iconSolid,
  showCaret,
  onUpdateItems,
  menuOnRight,
  onToggleCallback,
}: IconDropMenuProps<T>) {
  const selectedItem = useMemo(
    () => items.find((item) => (comparer && selected ? comparer(item.value, selected) : item.value === selected)),
    [comparer, selected, items],
  );
  return (
    <BaseDropMenu
      openByDefault={openByDefault}
      usePortal={usePortal}
      portalAnchorPlacement={menuOnRight ? 'right' : 'left'}
      filteredItems={items}
      selectedItem={selectedItem}
      onChange={onChange}
      triggerComponent={(expanded, _, onToggle) => (
        <IconTrigger
          icon={icon}
          size={iconSize}
          expanded={expanded}
          disabled={disabled}
          onClick={async (open) => {
            if (open && onUpdateItems) {
              await onUpdateItems?.();
            }
            onToggleCallback?.(!!open);

            onToggle(open);
          }}
          text={text}
          solid={iconSolid}
          showCaret={showCaret}
        />
      )}
      menuComponent={(highlighted, onCollapse, menuClassName) => (
        <SimpleMenu<T>
          className={menuClassName}
          items={items}
          selected={selected}
          link={link}
          width={width}
          height={height}
          highlighted={highlighted}
          onLinkClick={() => {
            onToggleCallback?.(false);

            onCollapse();
            onLinkClick?.();
          }}
          onChange={(item) => {
            onToggleCallback?.(false);

            onCollapse();
            onChange?.(item);
          }}
        />
      )}
    />
  );
}

export default IconDropMenu;
