import React from 'react';
import { Content, Field, SubHeader } from '../account-modals/shared';
import type { UserProfileSettings } from 'venn-api';
import { alternateMethod, currentMethod, primaryMethodIsSMS } from './utils';
import styled from 'styled-components';
import { Icon, GetColor } from 'venn-ui-kit';
import { ModalFooter } from '../../modal';

interface SwitchMethodProps {
  onSwitchMethod: () => void;
  onClose: () => void;
  profileSettings: UserProfileSettings;
}

const SwitchMethod = ({ profileSettings, onSwitchMethod, onClose }: SwitchMethodProps) => {
  const primaryIsSMS = primaryMethodIsSMS(profileSettings);

  return (
    <>
      <Content>
        <StyledSubHeader>
          <Icon type={primaryIsSMS ? 'mobile-alt' : 'google'} prefix={primaryIsSMS ? 'fas' : 'fab'} />
          &nbsp;You have enabled {currentMethod(profileSettings)} authentication
        </StyledSubHeader>
        <StyledField>Want to change how you are using 2FA?</StyledField>
      </Content>
      <ModalFooter
        primaryLabel={`Switch to ${alternateMethod(profileSettings)}`}
        onPrimaryClick={onSwitchMethod}
        onCancel={onClose}
      />
    </>
  );
};

export default SwitchMethod;

const StyledField = styled(Field)`
  font-size: 12px;
  font-weight: bold;
`;

const StyledSubHeader = styled(SubHeader)`
  border-bottom: 2px solid ${GetColor.Grey};
  padding: 0 0 15px;
  margin-bottom: 15px;
`;
