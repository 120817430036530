import styled from 'styled-components';
import { GetColor } from '../../../../style/color';

const PaginationButton = styled.button`
  cursor: pointer;
  margin: 0 5px;
  i {
    font-weight: normal;
    color: ${GetColor.Primary.Dark};
  }

  &:disabled {
    color: ${GetColor.Grey};
    pointer-events: none;

    i {
      color: ${GetColor.Grey};
    }
  }
`;

export default PaginationButton;
