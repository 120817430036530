import React, { useContext } from 'react';
import UserContext from '../../contexts/user-context';
import styled, { css } from 'styled-components';
import { GetColor, ZIndex } from 'venn-ui-kit';
import { useAccountNameFontSize } from '../hooks/useAccountNameFontSize';

export const WatermarkText = () => {
  const userContextValue = useContext(UserContext);
  // TODO: this should just use assertNotNull and not make developers think there is a case where the UI will randomly be broken.
  const accountName = userContextValue.profileSettings?.organization.accountName ?? '';
  const fontSize = useAccountNameFontSize(accountName);

  return (
    <>
      <Heading>Internal use only</Heading>
      <Subheading fontSize={fontSize}>{`For ${accountName}`} </Subheading>
    </>
  );
};

const WatermarkCss = css`
  text-align: right;
  z-index: ${ZIndex.Watermark};
  color: ${GetColor.DarkGrey};
  opacity: 33%;
`;

const Heading = styled.div`
  ${WatermarkCss}
  font-size: 24px;
`;

const Subheading = styled.div<{ fontSize: number }>`
  ${WatermarkCss}
  font-size: ${({ fontSize }) => fontSize}px;
`;
