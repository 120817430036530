import type { Fund } from 'venn-api';

export const NEW_INSTRUMENT = 'new-instrument';

export type OptionValue = string | number;

export interface Option {
  label: string;
  value: OptionValue;
  children: string | JSX.Element[] | JSX.Element | null;
  header?: string | JSX.Element[] | JSX.Element;
  fund?: Fund;
  type?: string;
}

export interface Scope {
  label: string;
  value: string;
  icon?: string;
  default: boolean;
}

export enum SCOPE_TYPES {
  ALL_FUNDS = 'FUND',
  ALL_FUNDS_AND_PORTFOLIOS = 'ALL',
  ALL_PORTFOLIOS = 'PORTFOLIO',
  INDEX_FUNDS = 'INDEX',
  USER_UPLOADED_FUNDS = 'USER_UPLOADED',
  PORTFOLIO_COMPARABLE = 'PORTFOLIO_COMPARABLE',
  STRATEGY_RETURN = 'STRATEGY_RETURN',
}

export const SCOPE_OPTION_ALL_FUNDS = (): Scope => ({
  label: 'All',
  value: SCOPE_TYPES.ALL_FUNDS,
  icon: undefined,
  default: true,
});

export const SCOPE_OPTION_INDEX_FUNDS = (): Scope => ({
  label: 'Indices',
  value: SCOPE_TYPES.INDEX_FUNDS,
  icon: 'fas fa-chart-line',
  default: false,
});

export const SCOPE_OPTION_USER_UPLOADED_FUNDS = (): Scope => ({
  label: 'Uploads',
  value: SCOPE_TYPES.USER_UPLOADED_FUNDS,
  icon: 'fas fa-database',
  default: false,
});
