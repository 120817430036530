import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Headline2, GetColor } from 'venn-ui-kit';
import EmptyState from '../../../../empty-state/EmptyState';
import type { DrawdownRangeAnalysis } from 'venn-api';
import { doCorrelationsExist, getMaxCorrelation, getTopDrawdownDriver } from './utils';
import WhatIf from './WhatIf';
import MarketEnvironment from './MarketEnvironment';
import FactorsReturns from './FactorsReturns';
import PortfolioInvestments from './PortfolioInvestments';
import type { AnalysisSubject } from 'venn-utils';
import { Dates, getFirstNegativeDay, formatAnalysisPeriod, plural, pluralCurry } from 'venn-utils';
import type { DownloadMetaData } from '../../../../downloadable';
import { capitalize } from 'lodash';

export interface RangeAnalysisProps {
  drawdownRangeAnalysis: DrawdownRangeAnalysis | null;
  subject: AnalysisSubject;
  error?: string;
  start?: number;
  end?: number;
  completed: boolean;
  canSelectDrawdownPeriod: boolean;
  hasDateControlPicker?: boolean;
  print?: boolean;
  downloadMetaData?: DownloadMetaData;
}

export const RangeAnalysis: FC<React.PropsWithChildren<RangeAnalysisProps>> = ({
  drawdownRangeAnalysis,
  error,
  subject,
  start,
  end,
  completed,
  canSelectDrawdownPeriod,
  hasDateControlPicker,
  print,
  downloadMetaData,
}) => {
  if (canSelectDrawdownPeriod && !(start && end)) {
    const message = hasDateControlPicker
      ? 'Select a period in the table above or use the date control to pick a custom period.'
      : 'Select a period in the table above to view analysis.';

    return <EmptyState header="No drawdown period selected." message={message} />;
  }

  if (!canSelectDrawdownPeriod && !(start && end) && !drawdownRangeAnalysis) {
    return null;
  }

  if (error || !drawdownRangeAnalysis) {
    return <EmptyState header="Unable to run analysis." message={error} />;
  }

  const {
    maxDrawdown,
    maxDrawdownDate,
    drawdownFactorInfoList,
    strategyDrawdownContribution,
    fundDrawdownContribution,
    canAnalyzeSecondary,
  } = drawdownRangeAnalysis;

  if (!drawdownFactorInfoList) {
    return <EmptyState header="Unable to run analysis." message="Some factor information is missing" />;
  }

  const correlationsExist = doCorrelationsExist(drawdownFactorInfoList);

  const startDate = moment.utc(start);
  const negativeStartDate = getFirstNegativeDay(start);
  const endDate = moment.utc(end);
  const troughDate = moment.utc(maxDrawdownDate);
  const maxCorrelation = getMaxCorrelation(drawdownFactorInfoList);
  const topDriver = getTopDrawdownDriver(strategyDrawdownContribution, fundDrawdownContribution);

  const startTroughDiff = troughDate.diff(startDate, 'months', true);
  const drawdownLength =
    startTroughDiff >= 1
      ? plural(Math.round(startTroughDiff), { 1: '{{count}} month', other: '{{count}} months' })
      : plural(Math.round(troughDate.diff(startDate, 'days', true)), {
          1: '{{count}} day',
          other: '{{count}} days',
        });

  const troughEndDiff = endDate.diff(troughDate, 'months', true);
  const recoveryPeriod = !completed
    ? {
        value: -1,
        suffix: () => '',
      }
    : troughEndDiff >= 1
      ? {
          value: Math.round(troughEndDiff),
          suffix: pluralCurry({ 1: 'month', other: 'months' }),
        }
      : {
          value: Math.round(endDate.diff(troughDate, 'days', true)),
          suffix: pluralCurry({ 1: 'day', other: 'days' }),
        };

  const maxDrawdownMessage = (
    <>
      <Bold>Max Drawdown</Bold> is <Bold>{(maxDrawdown * 100).toFixed(2)}%</Bold>. Drawdown length is{' '}
      <Bold>{drawdownLength}</Bold>
      {maxDrawdown <= -1 ? (
        ` and the ${subject.type === 'investment' ? 'investment' : 'portfolio'} does not recover.`
      ) : recoveryPeriod.value <= 0 ? (
        ' and has no recovery period.'
      ) : (
        <>
          {' '}
          with a recovery period of{' '}
          <Bold>
            {recoveryPeriod.value} {recoveryPeriod.suffix(recoveryPeriod.value)}
          </Bold>
          .
        </>
      )}
    </>
  );

  const rangeDownloadMetaData = downloadMetaData
    ? {
        ...downloadMetaData,
        startTime: undefined, // Analysis Period is not relevant to Drawdown Analysis charts
        endTime: undefined,
        extra: [
          ...(downloadMetaData.extra ?? []),
          {
            label: 'Drawdown Period',
            value: formatAnalysisPeriod(start, end, downloadMetaData.frequency),
          },
        ],
      }
    : undefined;
  return (
    <>
      <Divider />
      <Header>Drawdown Summary</Header>
      <Subheader>
        If {subject.name} experiences market conditions similar to{' '}
        <Bold>
          {negativeStartDate.format(Dates.DRAWDOWN_DATE_FORMAT)} &ndash; {endDate.format(Dates.DRAWDOWN_DATE_FORMAT)}
        </Bold>
        :
      </Subheader>
      <StyledList>
        {correlationsExist && maxCorrelation && (
          <li>
            <Bold>{maxCorrelation.factor.factorName}</Bold> becomes correlated with{' '}
            <Bold>{maxCorrelation.correlation.name}</Bold>.
          </li>
        )}
        <li>{maxDrawdownMessage}</li>
        {/* If the subject is a fund, it's obvious that the top driver is the fund itself so don't include the message */}
        {topDriver && subject.type === 'portfolio' && (
          <li>
            <Bold>{topDriver.name} </Bold> {topDriver.isFund ? '' : 'strategy'} becomes a top driver of drawdown.
          </li>
        )}
      </StyledList>
      <WhatIf subject={subject} data={drawdownRangeAnalysis} print={print} downloadMetaData={rangeDownloadMetaData} />
      <MarketEnvironment
        subject={subject}
        data={drawdownRangeAnalysis}
        print={print}
        downloadMetaData={rangeDownloadMetaData}
      />
      <Divider />
      <Header>{capitalize(subject.type)} Factors</Header>
      <FactorsReturns
        subject={subject}
        data={drawdownRangeAnalysis}
        canAnalyzeSecondary={canAnalyzeSecondary}
        downloadMetaData={rangeDownloadMetaData}
      />
      {subject.strategy && subject.type === 'portfolio' && (
        <>
          <Divider />
          <Header>Portfolio Investments</Header>
          <PortfolioInvestments
            subject={subject}
            data={drawdownRangeAnalysis}
            downloadMetaData={rangeDownloadMetaData}
          />
        </>
      )}
    </>
  );
};

export default RangeAnalysis;

const Divider = styled.div`
  height: 15px;
  margin-top: 20px;
  background: ${GetColor.PaleGrey};
  @media print {
    display: none;
  }
`;

const Header = styled(Headline2)`
  margin: 45px 0 10px;
  @media print {
    page-break-before: always;
    margin: 10px 0px 0px 0px;
    padding: 4px 8px;
    background-color: ${GetColor.Black};
    color: ${GetColor.White};
    font-family: ${(props) => props.theme.Typography.fontFamily};
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
`;

const Subheader = styled.h2`
  font-weight: 300 !important;
  margin-top: 10px;
  @media print {
    font-size: 14px;
  }
`;

const Bold = styled.span`
  font-weight: bold;
`;

const StyledList = styled.ul`
  margin: 10px 0 40px;
  font-weight: normal;
  font-size: 14px;
  line-height: normal;
  padding-left: 80px;
  color: ${GetColor.DarkGrey};
`;
