import React, { useCallback, useContext, useState } from 'react';
import {
  formatDate,
  MetaHeader,
  NumberInput,
  RebalanceStrategySelector,
  StyledMetaDataFields,
  UserContext,
} from 'venn-components';
import type { Portfolio, PortfolioRebalancingStrategyEnum } from 'venn-api';
import { analyticsService, useFF } from 'venn-utils';
import { Button, Calendar, Icon } from 'venn-ui-kit';
import moment from 'moment';

interface PortfolioInformationProps {
  portfolio: Portfolio;
  updatePortfolio: (portfolio: Portfolio) => Promise<void>;
}

const setRebalanceStrategy = (portfolio: Portfolio, strategy: PortfolioRebalancingStrategyEnum): Portfolio => {
  return {
    ...portfolio,
    rebalanceStrategy: strategy,
    children: portfolio.children.map((c) => setRebalanceStrategy(c, strategy)),
  };
};

const updateCustomRebalanceMonths = (portfolio: Portfolio, customRebalanceMonths?: number): Portfolio => {
  return {
    ...portfolio,
    customRebalanceMonths,
    children: portfolio.children.map((c) => updateCustomRebalanceMonths(c, customRebalanceMonths)),
  };
};

const updatePortfolioStartDate = (portfolio: Portfolio, portfolioStartDate?: number): Portfolio => {
  return {
    ...portfolio,
    portfolioStartDate,
    children: portfolio.children.map((c) => updatePortfolioStartDate(c, portfolioStartDate)),
  };
};

const PortfolioInformation = ({ portfolio, updatePortfolio }: PortfolioInformationProps) => {
  const hasNewRebalanceFF = useFF('new_rebalance_ff');
  const { hasPermissionForResource } = useContext(UserContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [portfolioStartDate, setPortfolioStartDate] = useState<number | undefined>(portfolio.portfolioStartDate);
  const [customRebalanceMonths, setCustomRebalanceMonths] = useState<number | undefined>(
    portfolio.customRebalanceMonths,
  );

  const isEditable = hasPermissionForResource('EDIT_PORTFOLIO', portfolio);

  const onSelect = useCallback(
    async (strategy: PortfolioRebalancingStrategyEnum) => {
      setLoading(true);
      await updatePortfolio(setRebalanceStrategy(portfolio, strategy));
      setLoading(false);
      analyticsService.portfolioRebalancingUpdated({
        portfolioId: portfolio.id,
        portfolioName: portfolio.name,
        rebalanceStrategy: strategy,
      });
    },
    [portfolio, updatePortfolio],
  );

  const onSave = useCallback(async () => {
    setLoading(true);
    await updatePortfolio(
      updateCustomRebalanceMonths(updatePortfolioStartDate(portfolio, portfolioStartDate), customRebalanceMonths),
    );
    setLoading(false);
  }, [customRebalanceMonths, portfolio, portfolioStartDate, updatePortfolio]);

  return (
    <>
      <RebalanceStrategySelector
        isEditable={isEditable}
        onSelect={onSelect}
        loading={loading}
        strategy={portfolio.rebalanceStrategy}
      />
      {hasNewRebalanceFF && (
        <>
          {portfolio.rebalanceStrategy === 'CUSTOM_REBALANCE' && (
            <div style={{ paddingTop: 8 }}>
              <StyledMetaDataFields>
                <MetaHeader label="Custom Rebalance Months" subLabel="The number of months to rebalance by." />
                <NumberInput
                  value={customRebalanceMonths}
                  label="Rebalance months..."
                  onChange={setCustomRebalanceMonths}
                  min={1}
                  disabled={portfolio.rebalanceStrategy !== 'CUSTOM_REBALANCE' || loading}
                />
              </StyledMetaDataFields>
            </div>
          )}
          <div style={{ paddingTop: 8 }}>
            <StyledMetaDataFields>
              <MetaHeader label="Portfolio Start" subLabel="The start date for the portfolio returns and rebalance." />
              Date: {portfolioStartDate !== undefined ? formatDate(portfolioStartDate) : 'Unset'}{' '}
              <Button
                destructive
                noMargin
                dense
                onClick={() => setPortfolioStartDate(undefined)}
                disabled={loading || !portfolioStartDate}
              >
                <Icon type="xmark-circle" /> Clear
              </Button>
              <Button
                noMargin
                dense
                onClick={() => setPortfolioStartDate(portfolio.portfolioStartDate)}
                disabled={loading || portfolio.portfolioStartDate === portfolioStartDate}
              >
                <Icon type="refresh" /> Reset
              </Button>
              <Calendar
                value={moment(portfolioStartDate).valueOf()}
                onChange={setPortfolioStartDate}
                className="qa-date-picker-from-calendar"
              />
            </StyledMetaDataFields>
          </div>
          <div style={{ paddingTop: 8 }}>
            <StyledMetaDataFields>
              <Button
                noMargin
                dense
                onClick={onSave}
                disabled={
                  loading ||
                  (portfolioStartDate === portfolio.portfolioStartDate &&
                    (portfolio.rebalanceStrategy !== 'CUSTOM_REBALANCE' ||
                      (portfolio.rebalanceStrategy === 'CUSTOM_REBALANCE' &&
                        customRebalanceMonths === portfolio.customRebalanceMonths)))
                }
              >
                <Icon type="save" /> Save
              </Button>
            </StyledMetaDataFields>
          </div>
        </>
      )}
    </>
  );
};

export default PortfolioInformation;
