import React from 'react';
import styled from 'styled-components';
import {
  FlexRow,
  FlexWrapper,
  Info,
  PaginationWrapper,
  ResultHint,
  StyledIcon,
  StyledTable,
  TableName,
} from './shared';
import { Headline2, Link as LinkStyle, Pagination, TablePlaceholder } from 'venn-ui-kit';
import { Dates, getResultRangeStart, getResultRangeEnd, buttonize } from 'venn-utils';
import type { LoginAuditEntry } from 'venn-api';

const LOGIN_HISTORY_COLUMNS = [
  {
    label: 'Login Time',
    accessor: 'created',
    cellRenderer: ({ created }: LoginAuditEntry) => (
      // test env should be timezone-agnostic / always be UTC time so that we can easily test
      <PaddedCell>{Dates.toDayMonthYearTime(created, process.env.NODE_ENV !== 'test')}</PaddedCell>
    ),
  },
  {
    label: 'IP Address',
    accessor: 'ipAddress',
  },
];

interface LoginHistoryProps {
  /** The current page number, 1-indexed */
  selectedPage: number;
  /** Function to go to the given paged */
  setSelectedPage: (page: number) => void;
  /** The number of entries in each page */
  pageSize: number;
  /** The data to show for the current page */
  data: LoginAuditEntry[];
  /** The total number of entries across all pages */
  total: number;
  /** Whether the data is being loaded or not */
  loading: boolean;
  /** The error, if any, encountered when loading the data */
  error?: Error;
  /** Function to call when Change Password is clicked */
  onChangePasswordClick: () => void;
}

const LoginHistory: React.FC<React.PropsWithChildren<LoginHistoryProps>> = ({
  selectedPage,
  setSelectedPage,
  pageSize,
  data,
  total,
  loading,
  error,
  onChangePasswordClick,
}) => {
  const rangeStart = getResultRangeStart(data.length, selectedPage, pageSize);
  const rangeEnd = getResultRangeEnd(data.length, selectedPage, pageSize);
  const numPages = Math.ceil(total / pageSize);

  let tableContent;
  if (loading) {
    tableContent = <TablePlaceholder columns={LOGIN_HISTORY_COLUMNS.length} />;
  } else if (error) {
    tableContent = <Headline2 center>Unable to load login history</Headline2>;
  } else {
    tableContent = (
      <>
        {data.length > 0 && <StyledTable data={data} columns={LOGIN_HISTORY_COLUMNS} />}
        {numPages > 1 && (
          <PaginationWrapper>
            <Pagination pagesCount={numPages} selectedPage={selectedPage} onPageChange={setSelectedPage} />
          </PaginationWrapper>
        )}
      </>
    );
  }

  return (
    <>
      <FlexRow>
        <div>
          <FlexWrapper>
            <StyledIcon type="sign-in-alt" />
            <TableName>Login History</TableName>
          </FlexWrapper>
          <Info>
            Verify that you recognize all the logins to your account in this workspace. If something looks suspicious,{' '}
            <LinkStyle {...buttonize(onChangePasswordClick)}>change your password</LinkStyle>.
          </Info>
        </div>
      </FlexRow>
      {!loading && !error && (
        <ResultHint>
          Showing {rangeStart} - {rangeEnd} of {total}
        </ResultHint>
      )}
      <TableWrapper>{tableContent}</TableWrapper>
    </>
  );
};

export default LoginHistory;

const TableWrapper = styled.div`
  margin-top: 20px;
`;

const PaddedCell = styled.div`
  padding: 6px 0;
`;
