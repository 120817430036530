import React, { useCallback } from 'react';
import type { LibraryFilterOption } from '../types';
import SimpleFilter from '../SimpleFilter';
import type { LibraryItemType } from 'venn-utils';
import styled from 'styled-components';

const fieldName = 'Item Type';
interface ItemTypeFilterProps {
  className?: string;
  onFilter: (updated: LibraryItemType) => void;
  selected: LibraryItemType;
  closeOnOnlyClick?: boolean;
  itemTypeItems: LibraryFilterOption<LibraryItemType>[];
}

const ItemTypeFilter = ({ className, selected, onFilter, closeOnOnlyClick, itemTypeItems }: ItemTypeFilterProps) => {
  const handleFilter = useCallback(
    (updated: string | string[]) => {
      const updatedItemType = updated as LibraryItemType;
      onFilter(updatedItemType);
    },
    [onFilter],
  );

  return (
    <>
      <StyledFilter
        className={className}
        label={fieldName}
        items={itemTypeItems}
        initialSelected={[selected]}
        onFilter={handleFilter}
        closeOnOnlyClick={closeOnOnlyClick}
        isAdvanced
        singleSelection
        menuWidth={285}
      />
    </>
  );
};

const StyledFilter = styled(SimpleFilter)`
  display: inline-flex;
`;

export default ItemTypeFilter;
