import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import type { RowNode } from 'ag-grid-community';
import { portfolioStrategyFilterType } from 'venn-state';
import { useBlockId } from '../contexts/BlockIdContext';
import { PortfolioStrategyFilterType } from '../components/grid/types';
import { assertExhaustive } from 'venn-utils';

export const shouldNodeExpand = (
  strategyFilterType: PortfolioStrategyFilterType,
  rowNode: RowNode<unknown>,
): boolean => {
  switch (strategyFilterType) {
    case PortfolioStrategyFilterType.DEFAULT:
      return true;
    case PortfolioStrategyFilterType.TOP_LEVEL_STRATEGIES:
      // If we want to show only the top level, we don't expand any nodes. Expanding a node inherently shows things that aren't top-level.
      return rowNode.level < 0;
    case PortfolioStrategyFilterType.ALL_STRATEGIES:
      return (rowNode.childrenAfterGroup ?? []).some((child) => child.hasChildren());
    default:
      throw assertExhaustive(strategyFilterType);
  }
};

export const usePortfolioGridDisplaySettings = (rowData: unknown[], columnDefs: unknown[]) => {
  const blockId = useBlockId();
  const strategyFilterType = useRecoilValue(portfolioStrategyFilterType(blockId));

  return useCallback(
    (rowNode: RowNode<unknown>) => shouldNodeExpand(strategyFilterType ?? PortfolioStrategyFilterType.DEFAULT, rowNode),
    // eslint-disable-next-line react-hooks/exhaustive-deps -- `rowData` and `columnDef` to make expansion work after re-renders.
    [strategyFilterType, rowData, columnDefs],
  );
};
