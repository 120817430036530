import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { Path, Rect } from './shared';

interface StudioIconV1Props {
  selected?: boolean;
  width?: number;
  height?: number;
}

export const StudioPreviewIcon = ({ selected, width = 28, height = 24 }: StudioIconV1Props) => {
  const { Colors } = useContext(ThemeContext);
  const hoverColor = Colors.White;
  const currentFillColor = selected ? hoverColor : Colors.MidGrey1;

  return (
    <svg width={width} height={height} viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Rect
        x="0.363647"
        y="15.1819"
        width="27.2727"
        height="8.72727"
        rx="1.09091"
        fillColor={currentFillColor}
        hoverColor={hoverColor}
      />
      <Path
        d="M3.52152 20.3636V22H2.58189V17.3466H4.16712C4.62734 17.3466 4.99382 17.4893 5.26655 17.7749C5.54141 18.0604 5.67883 18.4311 5.67883 18.8871C5.67883 19.343 5.54354 19.7031 5.27294 19.9673C5.00234 20.2315 4.62734 20.3636 4.14794 20.3636H3.52152ZM3.52152 19.5806H4.16712C4.34609 19.5806 4.48459 19.522 4.5826 19.4048C4.68061 19.2876 4.72962 19.1172 4.72962 18.8935C4.72962 18.6612 4.67954 18.4769 4.5794 18.3406C4.47926 18.2021 4.34503 18.1317 4.1767 18.1296H3.52152V19.5806ZM7.60881 20.2997H7.14219V22H6.20256V17.3466H7.70149C8.17237 17.3466 8.53565 17.4691 8.79133 17.7141C9.04915 17.957 9.17805 18.3033 9.17805 18.7528C9.17805 19.3707 8.95327 19.8033 8.50369 20.0504L9.31868 21.9553V22H8.30873L7.60881 20.2997ZM7.14219 19.5167H7.67592C7.86342 19.5167 8.00405 19.4549 8.0978 19.3313C8.19155 19.2056 8.23842 19.0384 8.23842 18.8295C8.23842 18.3629 8.05625 18.1296 7.6919 18.1296H7.14219V19.5167ZM12.118 19.9865H10.6542V21.2202H12.3864V22H9.71456V17.3466H12.38V18.1296H10.6542V19.229H12.118V19.9865ZM14.3356 20.7216L15.1186 17.3466H16.1669L14.8246 22H13.8466L12.5138 17.3466H13.5558L14.3356 20.7216ZM17.4768 22H16.5372V17.3466H17.4768V22ZM20.5606 19.9865H19.0968V21.2202H20.829V22H18.1572V17.3466H20.8227V18.1296H19.0968V19.229H20.5606V19.9865ZM24.2867 20.271L24.7278 17.3466H25.6578L24.83 22H23.8872L23.3439 19.2578L22.807 22H21.8609L21.03 17.3466H21.9664L22.4043 20.2678L22.9508 17.3466H23.7402L24.2867 20.271Z"
        fillColor="#10161B"
      />
      <Path
        d="M1.18183 0.54541H11C11.4091 0.54541 11.8182 0.954501 11.8182 1.36359V9.54541C11.8182 10.0056 11.4091 10.3636 11 10.3636H1.18183C0.721602 10.3636 0.363647 10.0056 0.363647 9.54541V1.36359C0.363647 0.954501 0.721602 0.54541 1.18183 0.54541Z"
        fillColor={currentFillColor}
        hoverColor={hoverColor}
      />
      <Path
        d="M8.8961 4.75331H6.7922V2.64942C6.7922 2.40104 6.57305 2.18188 6.32467 2.18188H5.85714C5.59415 2.18188 5.3896 2.40104 5.3896 2.64942V4.75331H3.28571C3.02272 4.75331 2.81818 4.97247 2.81818 5.22085V5.68838C2.81818 5.95137 3.02272 6.15591 3.28571 6.15591H5.3896V8.25981C5.3896 8.52279 5.59415 8.72734 5.85714 8.72734H6.32467C6.57305 8.72734 6.7922 8.52279 6.7922 8.25981V6.15591H8.8961C9.14447 6.15591 9.36363 5.95137 9.36363 5.68838V5.22085C9.36363 4.97247 9.14447 4.75331 8.8961 4.75331Z"
        fillColor="#2F3942"
      />
      <Path
        d="M24.0909 7.09087H26.2727C26.6818 7.09087 27.0909 7.49996 27.0909 7.90905V9.54541C27.0909 10.0056 26.6818 10.3636 26.2727 10.3636H24.0909C23.6307 10.3636 23.2727 10.0056 23.2727 9.54541V7.90905C23.2727 7.49996 23.6307 7.09087 24.0909 7.09087Z"
        fillColor="white"
        fillOpacity="0.4"
      />
      <Path
        d="M16.4545 0.54541H21.9091C22.3182 0.54541 22.7273 0.954501 22.7273 1.36359V2.99996C22.7273 3.46018 22.3182 3.81814 21.9091 3.81814H16.4545C15.9943 3.81814 15.6364 3.46018 15.6364 2.99996V1.36359C15.6364 0.954501 15.9943 0.54541 16.4545 0.54541Z"
        fillColor="white"
        fillOpacity="0.4"
      />
      <Path
        d="M26.8571 1.83112H25.8052V0.779176C25.8052 0.654988 25.6956 0.54541 25.5714 0.54541H25.3377C25.2062 0.54541 25.1039 0.654988 25.1039 0.779176V1.83112H24.0519C23.9204 1.83112 23.8182 1.9407 23.8182 2.06489V2.29866C23.8182 2.43015 23.9204 2.53242 24.0519 2.53242H25.1039V3.58437C25.1039 3.71586 25.2062 3.81814 25.3377 3.81814H25.5714C25.6956 3.81814 25.8052 3.71586 25.8052 3.58437V2.53242H26.8571C26.9813 2.53242 27.0909 2.43015 27.0909 2.29866V2.06489C27.0909 1.9407 26.9813 1.83112 26.8571 1.83112Z"
        fillColor={currentFillColor}
        hoverColor={hoverColor}
      />
      <Path
        d="M21.2468 8.14286H19.4935V6.38961C19.4935 6.18263 19.3109 6 19.1039 6H18.7143C18.4951 6 18.3247 6.18263 18.3247 6.38961V8.14286H16.5714C16.3523 8.14286 16.1818 8.32549 16.1818 8.53247V8.92208C16.1818 9.14123 16.3523 9.31169 16.5714 9.31169H18.3247V11.0649C18.3247 11.2841 18.4951 11.4545 18.7143 11.4545H19.1039C19.3109 11.4545 19.4935 11.2841 19.4935 11.0649V9.31169H21.2468C21.4537 9.31169 21.6364 9.14123 21.6364 8.92208V8.53247C21.6364 8.32549 21.4537 8.14286 21.2468 8.14286Z"
        fillColor={currentFillColor}
        hoverColor={hoverColor}
      />
    </svg>
  );
};
