import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import { selectedInvestmentOverrideAtom, selectedInvestmentOverrideValueAtom } from 'venn-state';
import { Body1, GetColor, Headline3 } from 'venn-ui-kit';
import InvestmentForecastOverridePicker from './InvestmentForecastOverridePicker';
import { InvestmentForecastPanelFooter } from './InvestmentForecastPanelFooter';

type InvestmentForecastOverrideFormProps = Readonly<{
  isCreating: boolean;
  isReadOnly: boolean;
}>;

const InvestmentForecastOverrideForm = ({ isCreating, isReadOnly }: InvestmentForecastOverrideFormProps) => {
  const operation = isCreating ? 'CREATE' : 'EDIT';
  const setOverrideValue = useSetRecoilState(selectedInvestmentOverrideValueAtom(operation));
  const selectedInvestmentOverride = useRecoilValue(selectedInvestmentOverrideAtom(operation));

  return (
    <ViewContainer>
      {isCreating && <ResidualHeadline>Add New Investment Forecast Override</ResidualHeadline>}
      <Header>
        <Investment>
          {isCreating
            ? 'Search Investment Name'
            : `Edit Investment Forecast Override for ${selectedInvestmentOverride?.fundName}`}
        </Investment>
      </Header>
      {!isCreating && (
        <Body1>
          When creating investment forecast overrides, either the total return or residual return override can be
          applied at a time per investment.{' '}
        </Body1>
      )}
      <InvestmentForecastOverridePicker
        setOverrideValue={setOverrideValue}
        isCreating={isCreating}
        isReadOnly={isReadOnly}
      />
      <InvestmentForecastPanelFooter isCreating={isCreating} />
    </ViewContainer>
  );
};

const ViewContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 16px;
  gap: 17px;
  background-color: ${GetColor.HighlightLightBackground};
`;

const Header = styled(Body1)`
  width: 100%;
  font-weight: bold;
  display: flex;
  align-items: center;
`;
const Investment = styled.div`
  flex-grow: 1;
`;

const ResidualHeadline = styled(Headline3)`
  margin: 0;
`;

export default InvestmentForecastOverrideForm;
