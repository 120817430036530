import type { FC } from 'react';
import React, { useRef } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { Route, withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';

import type { IconProps } from 'venn-ui-kit';
import { GetColor, Icon, Tooltip, TooltipPosition } from 'venn-ui-kit';
import { useHotkeys } from 'venn-utils';
import type { NavigationItemConfig, NavigationSubItem } from './shellNavigationConfigInitializer';
import { StyledNavigationLink, StyledNavigationPseudoLink } from './NavigationLinks';
import NavigationSubLinks from './NavigationSubLinks';

export const TOP_ICON_WIDTH = 60;

interface ShellNavigationLinkProps extends RouteComponentProps, Omit<NavigationItemConfig, 'wrapperStyle'> {
  condensed?: boolean;
  tabIndex?: number;
  subItemsHeader?: string;
  subItems?: NavigationSubItem[];
  showTopNav?: boolean;
  hideLabel?: boolean;
  iconFontSize?: number;
  tooltipContent?: string;
}

const StyledIconContainer = styled.div<{ showTopNav?: boolean }>`
  ${({ showTopNav }) => (showTopNav ? 'width: 60px' : '')};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledIcon = styled((props) => <Icon {...(props as IconProps)} />)`
  min-width: 20px;
  color: ${GetColor.GreyScale.Grey40};
  font-size: ${({ iconFontSize }) => iconFontSize}px;
  text-align: center;

  ${(props) =>
    props.active &&
    css`
      color: ${GetColor.White};
    `};
`;

const StyledLearnMoreTag = styled.div`
  display: none;
  font-weight: 700;
  font-size: 10px;
  position: absolute;
  bottom: -10px;
  background-color: ${GetColor.HighlightDark};
  padding: 6px 0px;
  width: 80px;
`;
const LinkContent = styled.div`
  &:hover ${StyledLearnMoreTag} {
    display: block;
  }
  position: relative;
  width: 100%;
  height: 100%;
`;

const StyledLabelContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

interface LabelStateProps {
  active?: boolean;
}

const StyledLabel = styled.div<LabelStateProps>`
  position: relative;
  margin-top: 5px;
  opacity: 1;
  ${(props) =>
    props.active &&
    css`
      color: ${GetColor.White};
    `};
  letter-spacing: 0px;
  text-align: center;
  font-weight: normal;
  font-size: 12px;
  font-family: 'Roboto Condensed', 'sans-serif';

  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1);
`;

export const TooltipContent = styled.div`
  font-weight: normal;
  letter-spacing: 0;
  font-size: 11px;
`;

const ShellNavigationLink: FC<React.PropsWithChildren<ShellNavigationLinkProps>> = ({
  navId,
  history,
  path,
  pseudoPath,
  excludedPath,
  external,
  onClick,
  icon,
  iconPrefix,
  iconComponent,
  label,
  className = '',
  hotkey,
  tabIndex,
  subItemsHeader,
  subItems,
  showTopNav,
  hideLabel,
  iconFontSize = 24,
  tooltipContent,
}) => {
  const historyRef = useRef(history);
  useHotkeys((event) => {
    onClick?.();
    if (path) {
      historyRef.current.push(path);
    }
    event.preventDefault();
  }, hotkey);

  return (
    <Route
      path={path ?? pseudoPath}
      exact={false}
      children={({ match }: RouteComponentProps) => {
        const styledProps = {
          active: !!(
            (path ?? pseudoPath) &&
            match &&
            (!excludedPath || !historyRef.current.location.pathname.startsWith(excludedPath))
          ),
          showTopNav,
        };

        const navLinkContent = (
          <LinkContent>
            <StyledLabelContainer>
              <StyledIconContainer showTopNav={showTopNav}>
                {icon ? (
                  <StyledIcon
                    iconFontSize={iconFontSize}
                    prefix={iconPrefix}
                    type={icon}
                    {...styledProps}
                    className="styled-icon"
                  />
                ) : null}
                {iconComponent}
              </StyledIconContainer>
              {hideLabel ? (
                <span />
              ) : (
                <StyledLabel {...styledProps}>
                  <span>{label}</span>
                </StyledLabel>
              )}
            </StyledLabelContainer>
            {className.includes('qa-navigation-no-access') && <StyledLearnMoreTag>LEARN MORE</StyledLearnMoreTag>}
          </LinkContent>
        );

        let navLinkComponent = (
          <StyledNavigationPseudoLink
            className={`${className} qa-navigation-to-${navId}`}
            onClick={onClick}
            tabIndex={tabIndex}
            {...styledProps}
          >
            {navLinkContent}
          </StyledNavigationPseudoLink>
        );

        if (path) {
          if (external) {
            navLinkComponent = (
              <StyledNavigationPseudoLink
                {...styledProps}
                as="a"
                href={path}
                target="_blank"
                rel="noopener noreferrer"
                onClick={onClick}
                className={`${className} qa-navigation-to-${navId}`}
              >
                {navLinkContent}
              </StyledNavigationPseudoLink>
            );
          } else {
            navLinkComponent = (
              <StyledNavigationLink
                className={`${className} qa-navigation-to-${navId}`}
                to={path}
                key={path}
                onClick={onClick}
                {...styledProps}
              >
                {navLinkContent}
              </StyledNavigationLink>
            );
          }
        }

        if (subItems) {
          navLinkComponent = (
            <NavigationSubLinks
              trigger={navLinkContent}
              {...styledProps}
              tabIndex={tabIndex}
              subItemsHeader={subItemsHeader}
              subItems={subItems}
              tooltipContent={tooltipContent}
            />
          );
        }

        if (tooltipContent && !subItems) {
          return (
            <Tooltip key={tooltipContent} position={TooltipPosition.Bottom} content={tooltipContent}>
              {navLinkComponent}
            </Tooltip>
          );
        }

        return navLinkComponent;
      }}
    />
  );
};

export default withRouter(ShellNavigationLink);
