/**
 * @fileoverview contains lazily initiated debug state that is initiated by the debug package or is undefined otherwise.
 * No atoms or selectors are actually created in this file. Instead, this is a collection of utilities to access state.
 *
 * Debug code should primarily use {@link useInitializeDebugState} while product code should only use {@link useDebugValue}.
 * To create a new debug state, add a field to  {@link InitializedDebugStateStore} and then make use of the aforementioned hooks.
 *
 * Note: ideally this file would live in venn-state or venn-debug, but that would create problems with circular dependencies or debug-package inclusion
 * into the production bundle. So instead this lives in venn-utils.
 */

import { useEffect, useMemo } from 'react';
import type { RecoilState, RecoilValue, UnwrapRecoilValue } from 'recoil';
import { useRecoilStoreID, useRecoilState, atom, useRecoilValue, constSelector } from 'recoil';
import type { CSSProperties } from 'styled-components';
import type { ErrorKind } from 'venn-components/src/studio-blocks/components/error-wrappers/errorWrapperTypes';
import type { DebugPrintPageState } from './debugPrintPageState';

/** Converts RecoilValue<T> fields of an object to RecoilState<T | undefined>, because Debug state can always be undefined if debug tooling isn't available. */
type MapRecoilStatesWithUndefined<T extends Record<string, RecoilValue<unknown>>> = {
  [K in keyof T]: RecoilState<UnwrapRecoilValue<T[K]> | undefined>;
};

type WatermarkStyle = Pick<
  CSSProperties,
  | 'opacity'
  | 'backgroundSize'
  | 'backgroundRepeat'
  | 'backgroundPositionX'
  | 'backgroundPositionY'
  | 'mixBlendMode'
  | 'backgroundImage'
>;
/**
 * The type that the Debug State Store would be if it and all containing state were initialized.
 * This is mainly useful for making utilities, and not for product feature creation.
 *
 * In practice, the true type of the state store is {@link DebugStateStore}.
 */
type InitializedDebugStateStore = {
  debugShowAllTooltips: RecoilState<boolean>;
  debugForceTooltipPortal: RecoilState<boolean>;
  debugForceTooltipInteractive: RecoilState<boolean>;
  /** TODO(collin.irwin, VENN-24627): clean up after watermark design is finalized. */
  debugWatermarkCoverOnlyContent: RecoilState<boolean>;
  debugWatermarkPadding: RecoilState<number>;
  debugWatermarkStyle: RecoilState<WatermarkStyle>;
  debugBlockErrorState: RecoilState<ErrorKind>;

  debugPrint: RecoilState<boolean>;
  /** Configuration for a print-oriented debug page that can be used to debug PDF output. */
  debugPrintPage: RecoilState<DebugPrintPageState>;
};

/**
 * The debug state store might not have any contents (it is 'Partial') and individual states might not be initialized (and so return undefined).
 * As a result, all entries are typed as RecoilState<T|undefined>|undefined.
 */
export type DebugStateStore = Partial<MapRecoilStatesWithUndefined<InitializedDebugStateStore>>;

const debugStateStore = atom<DebugStateStore>({ key: 'debugStateStore', default: {} });
/** Reads some debug state. Generally used by some product code wishing to acess debug state. */
export function useDebugValue<K extends keyof DebugStateStore>(key: K) {
  const storeId = useRecoilStoreID();
  // We don't want to throw errors if outside of a recoil wrapper, especially in tests.
  if (!storeId) {
    return undefined;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks -- the storeId should never change at runtime so this isn't really conditional, but be careful!
  return useRecoilValue(useRecoilValue(debugStateStore)[key] ?? constSelector(undefined));
}

/**
 * Initializes the state, if necessary, using the provided factory function.
 * This should only be used by the debug package to implement debug state.
 * Non-debug code should only use {@link useDebugValue}.
 *
 * @returns the current recoil state (atom, selector) including any potential initialization.
 */
export function useInitializeDebugState<K extends keyof DebugStateStore>(
  key: K,
  recoilStateFactory: () => NonNullable<DebugStateStore[K]>,
): NonNullable<DebugStateStore[K]> {
  const [store, setStore] = useRecoilState(debugStateStore);
  const oldState = store[key];

  const newState = useMemo(() => {
    return oldState || recoilStateFactory();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we just want to initialize once
  }, []);

  useEffect(() => {
    if (newState) {
      setStore((oldStore) => ({ ...oldStore, [key]: newState }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we just want to initialize once
  }, []);

  return oldState || newState;
}
