const metadataOptions = {
  appendType: [
    {
      id: 1,
      name: 'Overwrite Entire Series',
    },
    {
      id: 2,
      name: 'Overwrite Overlapping',
    },
    {
      id: 3,
      name: 'Append Only',
    },
  ],
  dataType: [
    {
      id: 1,
      name: 'Do Not Map',
    },
    {
      id: 2,
      name: 'Benchmark',
    },
    {
      id: 3,
      name: 'Fund',
    },
    {
      id: 5,
      name: 'Reported Composite Return',
    },
    {
      id: 4,
      name: 'Date',
    },
  ],
  metricType: [
    {
      id: 1,
      name: 'Return',
    },
    {
      id: 2,
      name: 'Price',
    },
  ],
  currency: [
    {
      id: 'AUD',
      name: 'Australian dollar',
    },
    {
      id: 'CAD',
      name: 'Canadian dollar',
    },
    {
      id: 'CHF',
      name: 'Swiss franc',
    },
    {
      id: 'CNY',
      name: 'Chinese yuan',
    },
    {
      id: 'EUR',
      name: 'European euro',
    },
    {
      id: 'GBP',
      name: 'Pound sterling',
    },
    {
      id: 'JPY',
      name: 'Japanese yen',
    },
    {
      id: 'NOK',
      name: 'Norwegian krone',
    },
    {
      id: 'NZD',
      name: 'New Zealand dollar',
    },
    {
      id: 'SEK',
      name: 'Swedish krona',
    },
    {
      id: 'USD',
      name: 'United States dollar',
    },
  ],
  dateFormat: [
    {
      id: 1,
      name: 'MM/DD/YYYY',
    },
    {
      id: 2,
      name: 'DD/MM/YYYY',
    },
    {
      id: 3,
      name: 'YYYY/MM/DD',
    },
    {
      id: 4,
      name: 'MM/YYYY',
    },
    {
      id: 5,
      name: 'MM-DD-YYYY',
    },
    {
      id: 6,
      name: 'DD-MM-YYYY',
    },
    {
      id: 7,
      name: 'YYYY-MM-DD',
    },
    {
      id: 8,
      name: 'MM-YYYY',
    },
    {
      id: 9,
      name: 'MM.DD.YYYY',
    },
    {
      id: 10,
      name: 'DD.MM.YYYY',
    },
    {
      id: 11,
      name: 'YYYY.MM.DD',
    },
    {
      id: 12,
      name: 'MM.YYYY',
    },
    {
      id: 13,
      name: 'M/D/YYYY',
    },
    {
      id: 14,
      name: 'D/M/YYYY',
    },
    {
      id: 15,
      name: 'YYYY/M/D',
    },
    {
      id: 16,
      name: 'M/YYYY',
    },
    {
      id: 17,
      name: 'M-D-YYYY',
    },
    {
      id: 18,
      name: 'D-M-YYYY',
    },
    {
      id: 19,
      name: 'YYYY-M-D',
    },
    {
      id: 20,
      name: 'M-YYYY',
    },
    {
      id: 21,
      name: 'M.D.YYYY',
    },
    {
      id: 22,
      name: 'D.M.YYYY',
    },
    {
      id: 23,
      name: 'YYYY.M.D',
    },
    {
      id: 24,
      name: 'M.YYYY',
    },
    {
      id: 25,
      name: 'YYYYMMDD',
    },
    {
      id: 26,
      name: 'MMM-YYYY',
    },
    {
      id: 27,
      name: 'MMM YYYY',
    },
    {
      id: 28,
      name: 'MMMM YYYY',
    },
    {
      id: 29,
      name: 'M-D-YY',
    },
    {
      id: 30,
      name: 'M/D/YY',
    },
    {
      id: 31,
      name: 'MM-DD-YY',
    },
    {
      id: 32,
      name: 'MM/DD/YY',
    },
    {
      id: 33,
      name: 'MM/YY',
    },
    {
      id: 34,
      name: 'MM-YY',
    },
    {
      id: 35,
      name: 'MM.yy',
    },
    {
      id: 36,
      name: 'MMM YY',
    },
    {
      id: 37,
      name: 'MMM-YY',
    },
    {
      id: 38,
      name: 'MMM/YY',
    },
    {
      id: 39,
      name: 'DD-MMM-YY',
    },
    {
      id: 40,
      name: 'DD/MM/YY',
    },
    {
      id: 41,
      name: 'DD-MM-YY',
    },
    {
      id: 42,
      name: 'MMMM-YYYY',
    },
    {
      id: 43,
      name: 'YYYYMM',
    },
    {
      id: 44,
      name: 'YYYY',
    },
    {
      id: 45,
      name: 'MMM.YYYY',
    },
    {
      id: 46,
      name: 'YYYY-MM-DD HH:MM:SS',
    },
    {
      id: 47,
      name: 'MM.DD.YYYY',
    },
  ],
  frequency: [
    {
      id: 1,
      name: 'Daily',
    },
    {
      id: 2,
      name: 'Monthly',
    },
    {
      id: 3,
      name: 'Quarterly',
    },
    {
      id: 4,
      name: 'Yearly',
    },
  ],
  errorTypes: [
    {
      id: 'DUPLICATE_DATE',
      name: 'Duplicated date',
    },
    {
      id: 'MISSING_VALUE',
      name: 'Missing value',
    },
    {
      id: 'VALUE_ANOMALY',
      name: 'Value anomaly',
    },
    {
      id: 'MISSING_DATE',
      name: 'Missing date',
    },
    {
      id: 'BAD_VALUE',
      name: 'Bad value',
    },
  ],
};
export default metadataOptions;
