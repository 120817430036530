import type { WorkspaceMember } from 'venn-api';

export const isInvitationExpired = (invitationExpiration?: number): boolean => {
  if (invitationExpiration === undefined) {
    return false;
  }
  // `invitationExpiration` is set server-side to `DSL.current_timestamp()`... which apparently ends up being roughly
  // 30 seconds in the future, so we'll consider an invitation expired if the expiration time is in the past or
  // "sort of now" (with 1 min tolerance).
  return invitationExpiration < Date.now() || Math.abs(invitationExpiration - Date.now()) < 60 * 1000;
};

export const hasPendingInvitation = (member: WorkspaceMember): boolean => {
  return !member.created && !isInvitationExpired(member.invitationExpiration);
};
