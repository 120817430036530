import React, { useContext } from 'react';
import styled from 'styled-components';
import { getAnalysisPathForPortfolio, Routes } from 'venn-utils';
import { ButtonLink, getAppTitle, GetColor, Icon } from 'venn-ui-kit';
import MasterDropMenu from './MasterDropMenu';
import { FlexRow, FlexWrapper, Info, Name, StyledIcon, StyledWrapper } from './shared';
import { getGlobalContext, PortfoliosContext, UserContext } from 'venn-components';
import type { UserProfileSettings } from 'venn-api';

const OrganizationCurrency = ({ profileSettings }: { profileSettings: UserProfileSettings }) => {
  const { hasPermission } = useContext(UserContext);
  const portfoliosContext = useContext(PortfoliosContext);
  const { masterPortfolio } = portfoliosContext;
  const { organization } = profileSettings;
  const { name } = organization;
  const isReadOnly = !hasPermission('MANAGE_MASTER_PORTFOLIO');
  const globalContextId = getGlobalContext(profileSettings)?.value.context.id;

  if (!masterPortfolio) {
    return (
      <StyledWrapper>
        <FlexRow>
          <FlexWrapper>
            <Name>{`Set up your portfolio to get started on ${getAppTitle()}.`}</Name>
          </FlexWrapper>
          <ButtonLink to={Routes.CREATE_PORTFOLIO} linkText="Set up your portfolio" />
        </FlexRow>
      </StyledWrapper>
    );
  }
  return (
    <StyledWrapper>
      <FlexRow>
        <FlexWrapper>
          <StyledIcon type="wrench" />
          <Name>{name}'s Master Portfolio</Name>
        </FlexWrapper>
        <FlexWrapper>
          <MasterDropMenu disabled={isReadOnly} globalContextId={globalContextId} />
          {masterPortfolio && (
            <StyledButtonLink
              linkText="Edit & Analyze Master"
              to={getAnalysisPathForPortfolio(masterPortfolio.id)}
              className="qa-edit-portfolio"
            />
          )}
        </FlexWrapper>
      </FlexRow>
      <StyledInfo>
        <StyledInfoIcon prefix="fas" type="info-circle" />
        {`Any changes made to the master portfolio will be seen by all members of your organization on the ${getAppTitle()} platform.`}
      </StyledInfo>
    </StyledWrapper>
  );
};

export default OrganizationCurrency;

const StyledButtonLink = styled(ButtonLink)`
  padding-left: 10px;
`;

const StyledInfo = styled(Info)`
  padding-top: 15px;
`;

const StyledInfoIcon = styled(Icon)`
  margin-right: 5px;
  color: ${GetColor.Black};
`;
