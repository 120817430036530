import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { hasUnsavedChangesInPrivatesAllocator } from 'venn-state';
import { useModal } from 'venn-utils';

export const useUnsavedPrivatesAllocatorChangesModal = (closePanel: () => void) => {
  const hasChanges = useRecoilValue(hasUnsavedChangesInPrivatesAllocator);

  const [isUnsavedChangesModalOpen, openUnsavedChangesModal, closeUnsavedChangesModal] = useModal();

  const closePanelOrTriggerUnsavedChangesModal = useCallback(() => {
    if (!hasChanges) {
      closePanel();
    } else {
      openUnsavedChangesModal();
    }
  }, [closePanel, hasChanges, openUnsavedChangesModal]);

  const discardChangesAndClosePanel = useCallback(() => {
    if (!isUnsavedChangesModalOpen) {
      return;
    }

    closeUnsavedChangesModal();
    closePanel();
  }, [closePanel, closeUnsavedChangesModal, isUnsavedChangesModalOpen]);

  return {
    isUnsavedChangesModalOpen,
    closePanelOrTriggerUnsavedChangesModal,
    discardChangesAndClosePanel,
    closeUnsavedChangesModal,
  };
};
