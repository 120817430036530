import React from 'react';
import type { QuickFilter } from 'venn-utils';
import { FilterType, LibraryItemType } from 'venn-utils';
import type { AssetTypeEnum, CurrencyEnum } from 'venn-api';
import { SelectedPills } from '../shared';
import styled from 'styled-components';
import type { DataSource, MorningstarCategory } from 'venn-state';

interface AdvancedPillsProps {
  sourceFilterSelected: QuickFilter[];
  itemTypeSelected: LibraryItemType;
  currencySelected: CurrencyEnum[];
  dataSourceSelected: DataSource[];
  morningstarCategorySelected: MorningstarCategory[];
  assetTypeSelected: AssetTypeEnum[];
  removeSourceFilter: (source: QuickFilter) => void;
  removeItemType: (itemType: LibraryItemType) => void;
  removeCurrency: (currency: CurrencyEnum) => void;
  removeDataSource: (dataSource: DataSource) => void;
  removeAssetType: (assetType: AssetTypeEnum) => void;
  removeMorningstarCategory: (category: MorningstarCategory) => void;
}

const AdvancedPills = ({
  sourceFilterSelected,
  itemTypeSelected,
  currencySelected,
  dataSourceSelected,
  assetTypeSelected,
  morningstarCategorySelected,
  removeSourceFilter,
  removeItemType,
  removeCurrency,
  removeDataSource,
  removeAssetType,
  removeMorningstarCategory,
}: AdvancedPillsProps) => {
  return (
    <>
      <Section className="qa-source-filter-bubble">
        {removeSourceFilter && (
          <SelectedPills
            selected={new Set(sourceFilterSelected)}
            onClick={removeSourceFilter}
            filterType={FilterType.SOURCE}
            iconType="filter"
          />
        )}
      </Section>
      <Section className="qa-item-type-filter-bubble">
        {removeItemType && itemTypeSelected !== LibraryItemType.ALL && (
          <SelectedPills
            selected={new Set([itemTypeSelected])}
            onClick={removeItemType}
            filterType={FilterType.ITEM_TYPE}
            iconType="filter"
          />
        )}
      </Section>
      <Section className="qa-currency-filter-bubble">
        {removeCurrency && (
          <SelectedPills
            selected={new Set(currencySelected)}
            onClick={removeCurrency}
            filterType={FilterType.CURRENCY}
            iconType="filter"
          />
        )}
      </Section>
      <Section className="qa-asset-type-filter-bubble">
        {removeAssetType && (
          <SelectedPills
            selected={new Set(assetTypeSelected)}
            onClick={removeAssetType}
            filterType={FilterType.ASSET_TYPE}
            iconType="filter"
          />
        )}
      </Section>
      <Section className="qa-data-source-filter-bubble">
        {removeDataSource && (
          <SelectedPills
            selected={new Set(dataSourceSelected)}
            onClick={removeDataSource}
            filterType={FilterType.DATA_SOURCE}
            iconType="filter"
          />
        )}
      </Section>
      <Section className="qa-morningstar-category-filter-bubble">
        {removeMorningstarCategory && (
          <SelectedPills
            selected={new Set(morningstarCategorySelected)}
            onClick={removeMorningstarCategory}
            filterType={FilterType.MORNINGSTAR_CATEGORY}
            iconType="star"
          />
        )}
      </Section>
    </>
  );
};

export default AdvancedPills;

const Section = styled.div``;
