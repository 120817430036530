import type { CSSProperties } from 'react';
import type { DataExtractor, TabularDataRow, TabularDataType } from './types';
import type { Analysis, FundCorrelationRowObject, Portfolio } from 'venn-api';
import { getComparisonLabelForBlockLegend } from 'venn-utils';
import { MAX_INVESTMENTS } from '../../../../charts/analysis-charts/correlation-analysis/logic';
import { compact } from 'lodash';
import type { StudioRequestSubject } from 'venn-state';

export const getSubjectLabel = (requestSubject: StudioRequestSubject): string =>
  getComparisonLabelForBlockLegend(requestSubject);

interface RowDef<T> {
  label: string;
  getter: (data: T) => number | undefined;
  type: TabularDataType;
  hide?: boolean;
  style?: CSSProperties;
}

const NUMERIC = 'numeric' as TabularDataType;

export const pairwiseCorrelationExtractor: DataExtractor<number[]> = (
  subjects: StudioRequestSubject[],
  analysis: Analysis | undefined,
) => {
  const analysisData =
    analysis?.analysisType === 'PAIRWISE_CORRELATION'
      ? analysis?.pairwiseCorrelation
      : analysis?.pairwiseDownsideCorrelation;
  const rows: RowDef<number[]>[] = [
    ...subjects.map((subject, subjectIdx) => ({
      label: getSubjectLabel(subject),
      type: NUMERIC,
      getter: (data: number[]) => data && data[subjectIdx],
    })),
  ];

  return extractDataFromAnalysis(subjects, rows, analysisData);
};

const flattenTree = (portfolio: Portfolio): Portfolio[] => {
  if (portfolio.children.length === 0) {
    return [portfolio];
  }

  return portfolio.children.reduce<Portfolio[]>(
    (portfolioList: Portfolio[], child) => portfolioList.concat(flattenTree(child)),
    [],
  );
};

const getTopPortfolioList = (portfolio: Portfolio, n: number, hasBenchmark: boolean) => {
  if (hasBenchmark) {
    n--;
  }
  const portfolioList = flattenTree(portfolio);
  portfolioList.sort((p1, p2) => (p2.allocation ?? 0) - (p1.allocation ?? 0)); // if a portfolio has no specified allocation, assume it is 0
  return portfolioList.slice(0, n);
};

const getSortedCorrelations = (
  portfolioList: Portfolio[],
  correlations: FundCorrelationRowObject[],
  hasBenchmark: boolean,
) => {
  return correlations.map((fund) => {
    const sortedCorrelatons = compact(
      portfolioList.map((p) =>
        fund.correlations.find((correlationsFund) => p.fund?.name === correlationsFund.entity.name),
      ),
    );

    if (hasBenchmark) {
      sortedCorrelatons.push(fund.correlations[fund.correlations.length - 1]);
    }

    return {
      ...fund,
      correlations: sortedCorrelatons,
    } as FundCorrelationRowObject;
  });
};

const getNLargestCorrelations = (
  portfolio: Portfolio,
  fundCorrelations: FundCorrelationRowObject[],
  n: number,
  hasBenchmark: boolean,
) => {
  if (fundCorrelations.length <= MAX_INVESTMENTS) {
    return fundCorrelations;
  }

  const benchmarkRow = hasBenchmark ? fundCorrelations[fundCorrelations.length - 1] : undefined;

  const portfolioList = getTopPortfolioList(portfolio, n, hasBenchmark);

  const correlations = compact(
    portfolioList.map((p) => fundCorrelations.find((fund) => fund.entity.id === p.fund?.id)),
  );

  if (benchmarkRow) {
    correlations.push(benchmarkRow); // add onto end of correlations
  }

  return getSortedCorrelations(portfolioList, correlations, hasBenchmark);
};

export const portfolioCorrelationExtractor = (
  fundCorrelations: FundCorrelationRowObject[],
  portfolio: Portfolio | undefined,
  hasBenchmark: boolean,
) => {
  if (!portfolio) {
    return [];
  }

  const correlations = getNLargestCorrelations(portfolio, fundCorrelations, MAX_INVESTMENTS, hasBenchmark);

  return correlations.map((row, idx) => {
    return {
      label: row.entity.name,
      fundId: row.entity.id,
      type: NUMERIC,
      getter: (data: number[]) => data && data[idx],
      ...row.correlations.map((fund) => {
        return {
          value: fund.correlation,
        };
      }),
    } as TabularDataRow;
  });
};

const extractDataFromAnalysis = <T>(
  subjects: StudioRequestSubject[],
  rows: RowDef<T>[],
  analysisData?: T[],
): TabularDataRow<T>[] => {
  return rows.map((row) => {
    return {
      ...row,
      ...subjects.map((_, subjectIdx) => {
        let value;
        try {
          if (analysisData?.[subjectIdx]) {
            value = row.getter(analysisData?.[subjectIdx]);
          }
        } catch {
          // Suppress error
        }
        return {
          value,
        };
      }),
    };
  });
};
