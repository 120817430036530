import styled from 'styled-components';
import React from 'react';
import { ZIndex, GetColor, getAppTitle, getTextThemeProvider } from 'venn-ui-kit';
import { isMobile } from 'venn-utils';
import CookieButton from './CookieButton';

const CookiePreferences = styled.button`
  color: ${GetColor.Primary.Main};
  font-weight: bold;
  background: none;
  border: none;
  font-size: 14px;
  padding: 0;
`;

const CookieBannerContent = styled.div`
  display: flex;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  background-color: rgba(16, 22, 27, 0.9);
  color: ${GetColor.White};
  opacity: 0.9;
  z-index: ${ZIndex.NavigationCover};

  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: ${isMobile() ? '40px 30px' : '60px 15%'};
    font-size: ${isMobile() ? '1.2rem' : '1.4rem'};
  }

  a {
    color: ${GetColor.Primary.Main};
    font-weight: bold;
  }
`;

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default ({ onAccept, displayPreferences }: { onAccept: () => void; displayPreferences: () => void }) => {
  return (
    <CookieBannerContent className="qa-cookie-banner">
      <div data-testid="cookie-statement">
        <p>
          {`We use cookies to ensure your experience on ${getAppTitle()} is meaningful and efficient. We use required cookies to make
          our site work and we would also like to set optional cookies to help us understand your use of the site, to
          deliver ${getAppTitle()}${
            getTextThemeProvider().tsParam
          } content to you, and to improve your experience and our website. For more information
          on the cookies we are using, read the`}{' '}
          <a href="/api/file/tag/cookie-policy" target="_blank" rel="noopener noreferrer">
            Cookie Policy
          </a>
          . To change your cookie preferences, see the{' '}
          <CookiePreferences onClick={displayPreferences} type="button" className="qa-preferences">
            Cookie Preferences Manager
          </CookiePreferences>
          .
        </p>
        <CookieButton dominant onClick={onAccept} className="qa-cookie-gotit">
          Accept & Continue
        </CookieButton>
      </div>
    </CookieBannerContent>
  );
};
