import { GetColor } from '../../style/color';
import styled from 'styled-components';

interface PillProps {
  disabled?: boolean;
}

const Pill = styled.span<PillProps>`
  ${(props) => props.disabled && 'opacity: 0.2;'}
  border-radius: 2px;
  position: relative;
  top: -1px;
  font-size: 12px;
  text-align: center;
  font-weight: bold;
  color: ${GetColor.White};
  display: inline;
  background: ${GetColor.DarkGrey};
  padding: 1px 5px;
  margin-right: 6px;
`;

export default Pill;
