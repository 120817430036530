import React from 'react';
import styled from 'styled-components';
import { Body1, GetColor, Headline3, Icon } from 'venn-ui-kit';
import triangle from './background-triangle-full.png';

const AnalysisWelcomeBanner = () => (
  <Banner>
    <BackgroundTriangle src={triangle} />
    <Info>
      <Headline3 inverted>Uncover Hidden Risks with Factors</Headline3>
      <Subtitle>Use a factor approach to identify diversification opportunities.</Subtitle>
      <Bullet text="Capture cross-sectional and time-series risk in typical institutional portfolios." />
      <Bullet text="See discrete, describable sources of common or systematic risk and return across a diverse set of investments." />
      <Bullet text="Translate desired factor exposure changes into asset allocation changes." />
    </Info>
  </Banner>
);

export default AnalysisWelcomeBanner;

const Banner = styled.div`
  background-color: ${GetColor.Black};
  border-radius: 4px;
  margin-top: 20px;
  margin-bottom: 35px;
  overflow: hidden;
  position: relative;
  display: flex;
`;

const BackgroundTriangle = styled.img`
  position: absolute;
  top: -30%;
  left: -20%;
`;

const Info = styled.div`
  width: 75%;
  padding: 5% 0 5% 10%;
`;

const Subtitle = styled(Body1)`
  color: ${GetColor.PaleGrey};
  margin-bottom: 26px;
`;

const BulletItem = styled.div`
  display: flex;
  margin: 4px 4px 8px 0;

  i {
    color: ${GetColor.HighlightLight};
    line-height: 22px;
    margin-right: 4px;
  }
`;

const Bullet = ({ text }: { text: string }) => (
  <BulletItem>
    <Icon type="square" /> <Body1 inverted>{text}</Body1>
  </BulletItem>
);
