import React, { PureComponent } from 'react';
import type { Fund, Portfolio } from 'venn-api';
import type { AnalysisSubject, AnalysisSubjectType, AnalysisSubjectSecondaryLabel } from 'venn-utils';
import { logMessageToSentry } from 'venn-utils';
import type { TreeItemUpdateType } from 'venn-components';
import { CreatePortfolioModal, AllocationPanel, PortfoliosContext } from 'venn-components';
import { Panel } from '../Layout';
import Loading from './Loading';
import { isAnalyzingCurrentPortfolioRegEx } from './utils';

export interface SidePanelProps {
  subject?: AnalysisSubject;
  objectType: AnalysisSubjectType;
  objectId: number | string;
  loadingSubject: boolean;
  hasAllocationError?: boolean;
  allUpdatedFunds?: Map<string, Fund>;
  onUpdateAllocationError?: (hasAllocationError: boolean) => void;
  onStrategyChange: (
    strategy: Portfolio,
    strategyId: number,
    secondaryStrategy?: Portfolio,
    secondaryLabel?: AnalysisSubjectSecondaryLabel,
  ) => void;
  onPortfolioChange: (portfolio: Portfolio, updateType: TreeItemUpdateType) => void;
  onComparisonChange: (secondaryPortfolio?: Portfolio, secondaryLabel?: AnalysisSubjectSecondaryLabel) => void;
  onPortfolioCreate: (portfolio: Portfolio) => void;
  refreshPortfolioList?: () => Promise<void>;
  updateFund?: (fundId: string) => Promise<Fund | undefined>;
  updateAllFunds?: (funds: Fund[], preventSubjectUpdate?: boolean) => void;
  hasAccessToCompare?: boolean;
  canUploadInvestments?: boolean;
}

interface SidePanelState {
  createPortfolioModal: boolean;
}

class SidePanel extends PureComponent<SidePanelProps, SidePanelState> {
  state: SidePanelState = {
    createPortfolioModal: false,
  };

  renderAllocationPanel = () => {
    const {
      onPortfolioChange,
      onComparisonChange,
      onStrategyChange,
      subject,
      onUpdateAllocationError,
      hasAllocationError,
      allUpdatedFunds,
      updateFund,
      updateAllFunds,
      hasAccessToCompare,
      canUploadInvestments,
    } = this.props;

    if (!subject?.portfolio) {
      logMessageToSentry('Failed to render allocation panel because of missing portfolio');
      return null;
    }

    return (
      <AllocationPanel
        portfolio={subject.portfolio}
        onPortfolioUpdate={onPortfolioChange}
        onSecondaryPortfolioChange={onComparisonChange}
        selectedStrategyId={subject.strategyId}
        onSelectStrategy={(
          selected: Portfolio,
          secondaryStrategy?: Portfolio,
          secondaryLabel?: AnalysisSubjectSecondaryLabel,
        ) => onStrategyChange(selected, selected.id, secondaryStrategy, secondaryLabel)}
        onUpdateAllocationError={onUpdateAllocationError}
        hasAllocationError={hasAllocationError}
        blockHistoryTest={isAnalyzingCurrentPortfolioRegEx}
        allUpdatedFunds={allUpdatedFunds}
        updateFund={updateFund}
        updateAllFunds={updateAllFunds}
        hasAccessToCompare={hasAccessToCompare}
        canUploadInvestments={canUploadInvestments}
      />
    );
  };

  render() {
    const { objectType, loadingSubject, subject } = this.props;
    const { createPortfolioModal } = this.state;

    if (loadingSubject || !subject || (objectType === 'portfolio' && !subject.portfolio)) {
      return (
        <Panel hasHeader aria-busy>
          <Loading />
        </Panel>
      );
    }

    if (objectType === 'investment') {
      return null;
    }

    return (
      <Panel hasHeader aria-busy={false}>
        {this.renderAllocationPanel()}
        {createPortfolioModal && (
          <CreatePortfolioModal
            baseline={subject.portfolio}
            disableBaselineSelection={subject.portfolio && subject.portfolio.master}
            hideBasePortfolio={!!subject.portfolio?.optimizationId}
            onClose={this.onCloseCreatePortfolioModal}
            onSubmit={this.onSubmitCreatePortfolioModal}
          />
        )}
      </Panel>
    );
  }

  private onCloseCreatePortfolioModal = () => {
    this.setState({
      createPortfolioModal: false,
    });
  };

  private onSubmitCreatePortfolioModal = async (createdPortfolio: Portfolio) => {
    this.onCloseCreatePortfolioModal();
    this.props.onPortfolioCreate(createdPortfolio);
    await this.props.refreshPortfolioList?.();
  };
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default (props: SidePanelProps) => (
  <PortfoliosContext.Consumer>
    {({ refresh }) => <SidePanel {...props} refreshPortfolioList={refresh} />}
  </PortfoliosContext.Consumer>
);
