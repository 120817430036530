import type { HotkeysEvent } from 'hotkeys-js';
import hotkeys from 'hotkeys-js';
import { useCallback, useEffect } from 'react';
import { analyticsService } from '../../analytics';

type CallbackFn = (event: KeyboardEvent, handler: HotkeysEvent) => void;

export interface Hotkey {
  keys: string;
  purpose: string;
}

export const Hotkeys: Record<string, Hotkey> = {
  UNIVERSAL_SEARCH: {
    keys: 's',
    purpose: 'search',
  },
  DEBUG_PANEL: {
    keys: 'ctrl+shift+d',
    purpose: 'open venn debug panel',
  },
  ESCAPE: {
    keys: 'Escape',
    purpose: 'Exit modals',
  },
  COLOR_SCHEME_PICKER: {
    keys: 'c',
    purpose: 'color scheme picker',
  },
};

export default function useHotkeysWrapper(callback: CallbackFn, hotkey?: Hotkey) {
  const memoisedCallback = useCallback(callback, [callback]);
  useEffect(() => {
    if (hotkey) {
      const hotkeysCallback = (event: KeyboardEvent, handler: HotkeysEvent) => {
        if (!document.querySelector('#modal-wrapper')) {
          analyticsService.keyboardShortcutUsed({
            shortcutPurpose: hotkey.purpose,
            shortcutString: hotkey.keys,
          });
          memoisedCallback(event, handler);
        }
      };
      hotkeys(hotkey.keys, hotkeysCallback);
      return () => hotkeys.unbind(hotkey.keys, hotkeysCallback);
    }
    return undefined;
  }, [hotkey, memoisedCallback]);
}
