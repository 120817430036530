import React from 'react';
import { ConfirmationModal, ModalSize } from 'venn-components';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { LibraryItemType, QuickFilter, analyticsService, getLibraryPath } from 'venn-utils';

const SUB_HEADER =
  'This instance will no longer sync to the accounts of everyone in your organization. You can choose to keep or delete the data that has been most recently synced.';

interface AddeparDeleteConfirmationModalProps {
  clientName: string;
  isDeleting: boolean;
  onDeleteKeepData(): void;
  onDeleteData(): void;
  onCancel(): void;
}

const AddeparDeleteConfirmationModal = ({
  clientName,
  isDeleting,
  onDeleteKeepData,
  onDeleteData,
  onCancel,
}: AddeparDeleteConfirmationModalProps) => {
  const addeparInvestmentDataLibraryLink = `${getLibraryPath({
    selectedFilters: {
      quickFilters: [QuickFilter.INTEGRATIONS],
      itemType: LibraryItemType.ALL,
      tags: [],
    },
  })}&dataSource=Addepar`;

  return (
    <ConfirmationModal
      destructive
      header={`Remove ${clientName}?`}
      size={ModalSize.Large}
      text={
        <div>
          <Note>
            Review the data which is about to be removed in your{' '}
            <Link to={addeparInvestmentDataLibraryLink} target="_blank" rel="noopener noreferrer">
              Data Library
            </Link>
            .
          </Note>
          <Note>
            <div>
              <strong>If you choose to keep your data</strong>
            </div>
            <div>
              Portfolios and investments will still be visible and available as benchmarks and proxies, however,
            </div>
          </Note>
          <Note>
            <div>
              <strong>If you choose to delete your data</strong>
            </div>
            <div>
              Deleted funds or composite benchmarks will be removed from portfolios and will no longer be accessible as
              benchmarks or proxies. Deleted portfolios will no longer be accessible as benchmarks. Saved views of these
              portfolios and investments will be deleted.
            </div>
          </Note>
        </div>
      }
      subhead={SUB_HEADER}
      onCancel={onCancel}
      onSecondaryClick={() => {
        onDeleteKeepData();
        analyticsService.ctaClicked({
          purpose: 'Remove Integration - Keep Data',
          text: 'Keep Data',
          locationOnPage: 'Addepar Delete Confirmation Modal',
        });
      }}
      onProceed={() => {
        onDeleteData();
        analyticsService.ctaClicked({
          purpose: 'Remove Integration - Delete Data',
          text: 'Delete Data',
          locationOnPage: 'Addepar Delete Confirmation Modal',
          filled: true,
        });
      }}
      proceedDisabled={isDeleting}
      proceedLabel="Delete Data"
      secondaryLabel="Keep Data"
    />
  );
};

export default AddeparDeleteConfirmationModal;

const Note = styled.div`
  margin-top: 0px;
  margin-bottom: 30px;
  line-height: 24px;
`;
