/* eslint-disable:max-line-length */
import React from 'react';

const InfoIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24" data-testid="info-icon">
    <g fill="#53bde6" fillRule="evenodd" transform="translate(1)">
      <path
        fillRule="nonzero"
        d="M1.5 12c0 5.79 4.706 10.5 10.5 10.5 5.79 0 10.5-4.706 10.5-10.5 0-5.79-4.706-10.5-10.5-10.5C6.21 1.5 1.5 6.206 1.5 12zM0 12C0 5.373 5.386 0 12 0c6.627 0 12 5.386 12 12 0 6.627-5.386 12-12 12-6.627 0-12-5.386-12-12z"
      />
      <rect width="3" height="7.5" x="10.5" y="10.5" rx="1.5" />
      <rect width="3" height="3" x="10.5" y="6" rx="1.5" />
    </g>
  </svg>
);

export default InfoIcon;
