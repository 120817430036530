import { useQuery } from '@tanstack/react-query';
import { type CashFlowSetting, getUserCreatedCashflowSettings } from 'venn-api';
import { logExceptionIntoSentry } from 'venn-utils';
import { partition } from 'lodash';

const KEY = 'FETCH_ALL_CASH_FLOW_PACING_SETTINGS';

type AllCashflowSettingsProps = {
  classificationSettings: CashFlowSetting[];
  parameterSettings: CashFlowSetting[];
};
export const useAllCashflowSettingsOverrides = () => {
  return useQuery<AllCashflowSettingsProps>(
    [KEY],
    async () => {
      const allUserCreatedSettings = (await getUserCreatedCashflowSettings()).content;
      const [classificationSettings, parameterSettings] = partition(
        allUserCreatedSettings,
        (setting) => setting.overrideType === 'CLASSIFICATION',
      );

      return {
        classificationSettings,
        parameterSettings,
      };
    },
    {
      onError: (error) => {
        return logExceptionIntoSentry(error as Error);
      },
      suspense: true,
    },
  );
};
