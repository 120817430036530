import styled from 'styled-components';
import React, { useState } from 'react';
import { Body1, EllipsisTooltipSpan, GetColor, Label } from 'venn-ui-kit';

const CookieAccordion = styled.div`
  box-sizing: border-box;
  min-height: 40px;
  border: solid 1px ${GetColor.MidGrey1};
  margin: 20px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
`;

const CookieOutlet = styled.ul`
  min-height: 40px;
  margin: 0;
  border: none;
  border: solid 1px ${GetColor.MidGrey1};
  border-top: none;
  padding: 10px;
`;

const StyledButton = styled.button`
  color: ${GetColor.White};
  font-size: 20px;
  font-weight: 700;
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  cursor: pointer;
  &:hover {
    color: ${GetColor.Primary.Main};
  }
`;

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default ({
  firstParty = false,
  thirdPartyCookies = false,
  cookies,
}: {
  firstParty?: boolean;
  thirdPartyCookies?: boolean;
  cookies: string[];
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div data-testid="cookie-accordion">
      <CookieAccordion>
        <Label inverted>
          {firstParty ? 'First-Party Cookies' : `Third-Party ${thirdPartyCookies ? 'Cookies' : 'Providers'}`}
        </Label>
        <StyledButton data-testid="button-on-click-trigger" type="button" onClick={() => setIsOpen((p) => !p)}>
          {isOpen ? '–' : '+'}
        </StyledButton>
      </CookieAccordion>
      {isOpen ? (
        <CookieOutlet data-testid="cookie-outlet">
          {cookies.map((cookie: string) => (
            <li key={cookie}>
              <Body1 inverted>
                <EllipsisTooltipSpan maxWidth={350}>{cookie}</EllipsisTooltipSpan>
              </Body1>
            </li>
          ))}
        </CookieOutlet>
      ) : null}
    </div>
  );
};
