import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import { Tooltip, TooltipBodyDirection, TooltipPosition } from 'venn-ui-kit';
import { LEGEND_LINE_HEIGHT } from '../constants';

interface Label {
  name: string;
  value: string;
}

interface Props {
  columnWidth: number;
  topOffset: number;
  leftOffset: number;
  labels: Label[];
  isFund?: boolean;
}

const Wrapper = styled.div<{ left: number; top: number }>`
  position: absolute;
  left: ${(props) => props.left}px;
  top: ${(props) => props.top}px;
`;

const Item = styled.div<{ columnWidth: number }>`
  font-size: 10px;
  opacity: 1;
  pointer-events: none;
  text-align: right;
  width: ${(props) => props.columnWidth}px;
`;

const TooltipWrapper = styled.div<{ i: number; columnWidth: number; height: number; top: number }>`
  position: absolute;
  width: ${(props) => props.columnWidth}px;
  left: ${(props) => props.i * props.columnWidth}px;
  top: ${(props) => props.top - props.height}px;
  > span {
    height: ${(props) => props.height}px;
  }
`;

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
const PortfolioNamesTooltip: FC<React.PropsWithChildren<Props>> = React.memo(
  ({ labels, columnWidth, topOffset, leftOffset, isFund }) => (
    <Wrapper left={leftOffset} top={topOffset}>
      {labels.map(
        (label: Label, i) =>
          !!label.value && (
            <TooltipWrapper
              /* eslint-disable-next-line react/no-array-index-key */
              key={i}
              i={i}
              height={isFund ? LEGEND_LINE_HEIGHT * 1.5 : LEGEND_LINE_HEIGHT * 2}
              top={LEGEND_LINE_HEIGHT * 2}
              columnWidth={columnWidth}
            >
              <Tooltip
                hideArrow
                content={<FullName {...label} />}
                position={TooltipPosition.Top}
                bodyDirection={TooltipBodyDirection.Left}
              >
                <Item columnWidth={columnWidth} />
              </Tooltip>
            </TooltipWrapper>
          ),
      )}
    </Wrapper>
  ),
);

export default PortfolioNamesTooltip;

const FullName = ({ name, value }: Label) => (
  <span>
    {name} <span style={{ fontStyle: 'italic' }}>({value})</span>
  </span>
);
