import React from 'react';
import styled from 'styled-components';
import { Shimmer } from 'venn-ui-kit';

const Loading = () => (
  <Container>
    {[1, 2, 3, 4].map((i) => (
      <Row key={i}>
        <Block duration={10} />
        <Block duration={20} />
      </Row>
    ))}
  </Container>
);

const Container = styled.div`
  margin: 20px 15px 0 20px;
`;

const Block = styled.div<{ duration?: number }>`
  height: 30px;
  margin-top: 20px;
  ${Shimmer};
`;
const Row = styled.div`
  display: flex;
  /* stylelint-disable-next-line */
  > ${Block}:first-child {
    flex: 1;
    margin-right: 10px;
  }
  /* stylelint-disable-next-line */
  > ${Block}:nth-child(2) {
    width: 60px;
  }
`;

export default Loading;
