import React from 'react';
import styled, { css } from 'styled-components';
import PrintContainerDimensions from '../print/PrintContainerDimensions';
import { EllipsisTooltipSpan, Tooltip, TooltipPosition, GetColor, Icon } from 'venn-ui-kit';
import SaveAndShare from './SaveAndShare';
import type { SaveAsModalCustomContent } from '../modals/save-view/SaveAnalysisViewModal';
import SaveAnalysisViewModal from '../modals/save-view/SaveAnalysisViewModal';
import useSave from './useSave';
import type { ExportButtonProps } from '../share-button/shared';
import type { ShareMetadata } from 'venn-api';
import SaveTab from './SaveTab';
import type { ShareTriggerClickCallback } from '../share-button/ShareEmailButton';

interface SaveShareTopBar extends ExportButtonProps {
  // When it's empty, we don't show buttons
  isEmpty: boolean;
  emptyName: string;
  loading: boolean;
  hasUnsavedChanges: boolean;
  hasSavedBaseline?: boolean;
  onRename?: (name: string) => void;
  onSaveAs?: (name: string) => void;
  shareMetaData: Partial<ShareMetadata>;
  viewTypeName?: string;
  // Show recent views dropdown
  disabledTooltipMsg?: string;
  leftContent?: JSX.Element;
  hideSave?: boolean;
  hideSaveAs?: boolean;
  saveAsModalCustomContent?: SaveAsModalCustomContent;
  printLabel?: string;
  onShareTriggerClick?: ShareTriggerClickCallback;
  canPrintAnalysis?: boolean;
}

const SaveShareTopBar = ({
  // ui params
  isEmpty,
  emptyName,
  isCompare,
  disabled,
  disabledTooltipMsg,
  leftContent,
  // view params
  currentViewName,
  hasUnsavedChanges,
  hasSavedBaseline,
  loading,
  onRename,
  onSave,
  onSaveAs,
  savedId,
  subjectId,
  viewTypeName,
  // share and export params
  shareMetaData,
  downloadXlsx,
  downloadPdf,
  downloadAll,
  downloadPNGs,
  noAccessModifiedView,
  onPrintClick,
  hideSave,
  hideSaveAs,
  saveAsModalCustomContent,
  printLabel,
  onShareTriggerClick,
  canPrintAnalysis = true,
}: SaveShareTopBar) => {
  const { saveModalMode, saveImmediately, onRenameClick, onSaveClick, onSaveAsClick, onCancel, onSubmit, timestamp } =
    useSave({
      hasUnsavedChanges,
      hasSavedBaseline,
      onSave,
      onSaveAs,
      onRenameSavedView: onRename,
      currentViewName,
      noAccessModifiedView,
      hideSave,
      hideSaveAs,
    });

  const getDefaultViewName = (viewTypeName: string | undefined, timestamp: string): string => {
    const viewName = ['Saved', viewTypeName, 'View'].filter(Boolean).join(' ');
    return `${viewName} - ${timestamp}`;
  };

  return (
    <SaveTab>
      {leftContent}
      <AnalysisText>
        {isEmpty ? (
          <NoAnalysis highlighted>{emptyName}</NoAnalysis>
        ) : (
          <PrintContainerDimensions>
            {({ width }) => (
              <AnalysisName unsaved={hasUnsavedChanges} highlighted greyedOut={!currentViewName}>
                <EllipsisTooltipSpan maxWidth={width} position={TooltipPosition.Bottom}>
                  {currentViewName ?? 'Unsaved View'}
                </EllipsisTooltipSpan>
                {!loading && hasSavedBaseline && !disabled && onRename && (
                  <Tooltip position={TooltipPosition.Bottom} content="Edit view name">
                    <EditIcon type="edit" tabIndex={0} onClick={onRenameClick} className="qa-rename-view" />
                  </Tooltip>
                )}
              </AnalysisName>
            )}
          </PrintContainerDimensions>
        )}
      </AnalysisText>
      {!isEmpty && (
        <SaveAndShare
          onSaveClick={onSave ? onSaveClick : undefined}
          onSaveAsClick={onSaveAs ? onSaveAsClick : undefined}
          savedId={savedId}
          subjectId={subjectId}
          shareMetaData={shareMetaData}
          isCompare={isCompare}
          downloadXlsx={downloadXlsx}
          downloadPdf={downloadPdf}
          downloadAll={downloadAll}
          downloadPNGs={downloadPNGs}
          onPrintClick={canPrintAnalysis ? onPrintClick : undefined}
          disabled={disabled}
          disabledTooltipMsg={disabledTooltipMsg}
          onSave={onSave}
          hasSavedModified={hasUnsavedChanges && hasSavedBaseline}
          noAccessModifiedView={noAccessModifiedView}
          printLabel={printLabel}
          onShareTriggerClick={onShareTriggerClick}
        />
      )}
      {saveModalMode && (
        <SaveAnalysisViewModal
          mode={saveModalMode}
          skipModal={saveImmediately}
          defaultName={
            saveModalMode === 'SAVE_AS'
              ? `${currentViewName} (Copy)`
              : currentViewName ?? getDefaultViewName(viewTypeName, timestamp)
          }
          onSubmit={onSubmit}
          onClose={onCancel}
          customContent={saveAsModalCustomContent}
        />
      )}
    </SaveTab>
  );
};

export default SaveShareTopBar;

const AnalysisText = styled.div`
  min-width: 120px;
  width: 100%;
`;

const NoAnalysis = styled.span<{ highlighted: boolean }>`
  ${({ highlighted }) => highlighted && 'font-weight: bold;'}
`;

const AnalysisName = styled.div<{ unsaved: boolean; highlighted: boolean; greyedOut: boolean }>`
  ${({ unsaved }) => unsaved && 'font-style: italic;'}
  ${({ highlighted }) => highlighted && 'font-weight: bold;'}
  ${({ greyedOut }) =>
    greyedOut &&
    css`
      color: ${GetColor.MidGrey2};
    `}

  display: flex;

  > span {
    line-height: 16px;
  }
`;

const EditIcon = styled(Icon)`
  display: inline;
  padding: 4px;
  color: ${GetColor.Grey};

  &:hover {
    color: ${GetColor.Primary.Main};
    cursor: pointer;
  }
`;
