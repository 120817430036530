import type { FC } from 'react';
import React from 'react';
import styled, { css } from 'styled-components';
import { AnimatedHeight } from 'venn-components';
import { GetColor } from 'venn-ui-kit';

const StyledAnimatedHeight = styled(AnimatedHeight)<{ empty?: boolean }>`
  width: 100%;
  margin: 0;
  margin-bottom: 15px;
  background-color: rgba(52, 61, 67, 0.25);
  color: ${GetColor.UNSAFE.LightBlue};
  ${({ empty }) =>
    !empty &&
    css`
      border: 1px solid ${GetColor.UNSAFE.LightBlue};
    `}
`;

const StyledAuthFormErrorsList = styled.ul<EmptyProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: opacity 0.5s linear;
  text-align: left;
  opacity: 1;
  list-style-type: none;
  padding: 0px 15px;
  margin: 0;
  ${(props: EmptyProps) =>
    props.empty &&
    `{
      height: 0;
      padding: 0;
      opacity: 0;
  }`};
`;

const StyledFormError = styled.li`
  margin: 3px 0;
  font-size: 11px;
  line-height: 16px;

  &:first-child {
    margin-top: 10px;
  }

  &:last-child {
    margin-bottom: 10px;
  }
`;

interface EmptyProps {
  empty: boolean;
}

interface AuthFormErrorsProps {
  error?: string;
  explanation?: string;
}

export const AuthFormErrors: FC<React.PropsWithChildren<AuthFormErrorsProps>> = ({ error, explanation }) => {
  const hasErrors = Boolean(error);

  return (
    <StyledAnimatedHeight empty={!hasErrors}>
      <StyledAuthFormErrorsList empty={!hasErrors}>
        {error && <StyledFormError>{error}</StyledFormError>}
        {error && explanation && <StyledFormError>{explanation}</StyledFormError>}
      </StyledAuthFormErrorsList>
    </StyledAnimatedHeight>
  );
};

export default AuthFormErrors;
