import React, { useCallback, useContext } from 'react';
import type { DropMenuItem } from 'venn-ui-kit';
import { IconDropMenu } from 'venn-ui-kit';
import type { Page } from 'venn-components';
import { StudioContext, UserContext } from 'venn-components';
import { PageType } from 'venn-utils';
import styled from 'styled-components';
import { FlexHeader } from '../../../shared';

const PAGE_OPTIONS: DropMenuItem<Page>[] = [
  {
    label: 'New Page',
    value: {
      type: PageType.GRID,
      layout: [],
    },
  },
  {
    label: 'New Title Page',
    value: {
      type: PageType.TITLE,
      layout: [],
      titleSettings: {
        title: '',
        subTitle: '',
        additionalText: '',
      },
    },
  },
  {
    label: 'New Section Page',
    value: {
      type: PageType.SECTION,
      layout: [],
      sectionSettings: {
        topText: '',
        title: '',
        subTitle: '',
      },
    },
  },
  {
    label: 'New Disclosure Page',
    value: {
      type: PageType.DISCLOSURE,
      layout: [],
    },
  },
];

const NewPageButton = () => {
  const { hasPermission } = useContext(UserContext);
  const { onAddNewPage } = useContext(StudioContext);

  const onChange = useCallback((option: DropMenuItem<Page>) => onAddNewPage(option.value), [onAddNewPage]);

  return (
    <Header data-testid="qa-add-pages">
      Pages
      <IconDropMenu
        usePortal
        text=""
        icon="plus"
        items={PAGE_OPTIONS}
        onChange={onChange}
        selected={undefined}
        disabled={!hasPermission('STUDIO_INSERT_BLOCKS')}
        iconSolid
      />
    </Header>
  );
};

export default NewPageButton;

const Header = styled(FlexHeader)`
  justify-content: space-between;
`;
