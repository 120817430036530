import styled from 'styled-components';
import { Shimmer, ShimmerBlock } from './shimmer';
import React from 'react';

export const LabelShimmer = styled.div`
  ${Shimmer};
  height: 12px;
  margin-bottom: 3px;
  border-radius: 6px;
  width: 50%;
`;

export const DropdownShimmer = styled.div`
  ${Shimmer};
  height: 35px;
  border-radius: 4px;
`;

const TitleShimmerInner = styled(ShimmerBlock)`
  width: 100%;
  border-radius: 5px;
  margin: 7px 0;
`;

const SubTitleShimmerInner = styled(ShimmerBlock)`
  width: 50%;
  border-radius: 5px;
  margin: 2px 0;
`;

export const TitleShimmer = () => <TitleShimmerInner height={16} margin={0} />;

export const SubTitleShimmer = () => <SubTitleShimmerInner height={12} margin={0} />;
