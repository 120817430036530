/*
 * Venn Platform API 1.0.0 Operations for "document"
 * Automatically generated file - do not modify!
 */

import type { Document, DocumentIdentifier, PageDocument, PagedSearchDocumentSearch, UploadTypeEnum } from '../types';
import type { OperationResult } from '../../utils';
import { buildQuery, buildRequestInit, extractError, retryGeneratedFetch } from '../../utils';

/** Delete a document by id. */
export async function deleteDocumentById(documentId: string, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/document/${encodeURIComponent(documentId)}`;
  const requestInit = buildRequestInit({ method: 'DELETE', signal });
  const response = await retryGeneratedFetch('DELETE', () => fetch(endpoint, requestInit));
  if ((response.status >= 200 && response.status < 400) || response.status === 404) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Delete documents by id. */
export async function deleteDocuments(body?: string[], signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = '/api/document/bulk';
  const requestInit = buildRequestInit({
    method: 'DELETE',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await retryGeneratedFetch('DELETE', () => fetch(endpoint, requestInit));
  if ((response.status >= 200 && response.status < 400) || response.status === 404) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get a document's content by id. */
export async function getDocumentContent(
  documentId: string,
  version?: number,
  signal?: AbortSignal,
): Promise<OperationResult<Blob>> {
  const endpoint = `/api/document/${encodeURIComponent(documentId)}/content${buildQuery({ version })}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: Blob = (await response.blob()) as Blob;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get a document's metadata by id. */
export async function getDocumentById(
  documentId: string,
  version?: number,
  signal?: AbortSignal,
): Promise<OperationResult<Document>> {
  const endpoint = `/api/document/${encodeURIComponent(documentId)}${buildQuery({ version })}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: Document = (await response.json()) as Document;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get a zip file containing the given document identifiers. */
export async function getDocumentsZip(
  body?: DocumentIdentifier[],
  signal?: AbortSignal,
): Promise<OperationResult<Blob>> {
  const endpoint = '/api/document/bulk';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await retryGeneratedFetch('POST', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: Blob = (await response.blob()) as Blob;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get all active document IDs */
export async function getAllActiveDocumentIds(signal?: AbortSignal): Promise<OperationResult<string[]>> {
  const endpoint = '/api/document/ids';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: string[] = (await response.json()) as string[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get all inactive document IDs */
export async function getAllInactiveDocumentIds(signal?: AbortSignal): Promise<OperationResult<string[]>> {
  const endpoint = '/api/document/inactiveIds';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: string[] = (await response.json()) as string[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Remove the draft status from a document. */
export async function activateDocument(
  documentId: string,
  version?: number,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = `/api/document/${encodeURIComponent(documentId)}/activate${buildQuery({ version })}`;
  const requestInit = buildRequestInit({ method: 'POST', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Search user documents. */
export async function searchUserDocuments(
  body?: Partial<PagedSearchDocumentSearch>,
  signal?: AbortSignal,
): Promise<OperationResult<PageDocument>> {
  const endpoint = '/api/document/search';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await retryGeneratedFetch('POST', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: PageDocument = (await response.json()) as PageDocument;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Update a document. */
export async function updateDocument(
  documentId: string,
  uploadType?: UploadTypeEnum,
  body?: any,
  signal?: AbortSignal,
): Promise<OperationResult<Document>> {
  const endpoint = `/api/document/${encodeURIComponent(documentId)}${buildQuery({ uploadType })}`;
  const requestInit = buildRequestInit({ method: 'PUT', body, signal });
  const response = await retryGeneratedFetch('PUT', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: Document = (await response.json()) as Document;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Upload a document. */
export async function uploadDocument(
  overwrite?: boolean,
  uploadType?: UploadTypeEnum,
  body?: any,
  signal?: AbortSignal,
): Promise<OperationResult<Document>> {
  const endpoint = `/api/document${buildQuery({ overwrite, uploadType })}`;
  const requestInit = buildRequestInit({ method: 'POST', body, signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: Document = (await response.json()) as Document;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** View a document's content by id. */
export async function viewDocumentContent(
  documentId: string,
  version?: number,
  signal?: AbortSignal,
): Promise<OperationResult<Blob>> {
  const endpoint = `/api/document/${encodeURIComponent(documentId)}/view${buildQuery({ version })}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: Blob = (await response.blob()) as Blob;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
