import React from 'react';
import { assertExhaustive, useHasFF, withSuspense } from 'venn-utils';
import SpecificForecastViewV2 from './SpecificForecastViewV2';
import styled from 'styled-components';
import { ExposurePeriodEditor } from './ExposurePeriodEditor';
import { HistoricalPeriodEditorV2 } from './HistoricalPeriodEditorV2';
import { CapitalMarketAssumptionsViewV2 } from './CapitalMarketAssumptionsViewV2';
import { useRecoilState } from 'recoil';
import type { ForecastPanelView } from 'venn-state';
import { forecastPanelViewSelector, ForecastTab } from 'venn-state';
import { InvestmentOverrideView } from './InvestmentOverrideView';
import { Loading } from 'venn-ui-kit';
import { LoadingOverlay } from './LeftForecastPanel';

const RightForecastPanelInner = () => {
  const [view, setView] = useRecoilState(forecastPanelViewSelector);
  const onCancelFactorRightPanel = () =>
    setView({
      tab: ForecastTab.FactorForecast,
      detail: { type: 'Empty' },
    });
  const hasRedesignFF = useHasFF('forecast_panel_redesign_ff');

  const getPanelContent = (view: ForecastPanelView['detail']) => {
    switch (view.type) {
      case 'Empty':
        return null;
      case 'SpecificForecast':
        return <SpecificForecastViewV2 forecast={view.forecast} onCancel={onCancelFactorRightPanel} />;
      case 'CMAsEditor':
        return <CapitalMarketAssumptionsViewV2 forecast={view.forecast} onClose={onCancelFactorRightPanel} />;
      case 'CMAsCreator':
        return <CapitalMarketAssumptionsViewV2 onClose={onCancelFactorRightPanel} />;
      case 'HistoricalPeriodEditor':
        return <HistoricalPeriodEditorV2 forecast={view.forecast} onCancel={onCancelFactorRightPanel} />;
      case 'HistoricalPeriodCreator':
        return <HistoricalPeriodEditorV2 onCancel={onCancelFactorRightPanel} />;
      case 'InvestmentOverrideCreator':
        return <InvestmentOverrideView isCreating />;
      case 'InvestmentOverrideEditor':
        return <InvestmentOverrideView isCreating={false} />;
      case 'ExposurePeriodEditor':
        return <ExposurePeriodEditor onCancel={onCancelFactorRightPanel} />;
      default:
        return assertExhaustive(view, 'unexpected right forecast panel view');
    }
  };

  return <Wrapper noBorderTop={hasRedesignFF}>{getPanelContent(view.detail)}</Wrapper>;
};
export const RightForecastPanel = withSuspense(
  <LoadingOverlay>
    <Loading />
  </LoadingOverlay>,
  RightForecastPanelInner,
);

const Wrapper = styled.div<{ noBorderTop: boolean }>`
  padding: 10px;
  ${({ noBorderTop, theme }) => !noBorderTop && `border-top: 1px solid ${theme.Colors.PaleGrey};`}
`;
