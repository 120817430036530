import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import { Icon, GetColor } from 'venn-ui-kit';

interface ErrorToggleProps {
  /**
   * Indicates whether the toggle is switched on or off
   */
  open: boolean;
  /**
   * Number of remaining errors
   */
  errors: number;
  onToggle: () => void;
  checkIcon: React.ReactNode;
}

const ErrorToggle: FC<React.PropsWithChildren<ErrorToggleProps>> = ({ errors, open, onToggle, checkIcon }) => {
  const done = errors === 0;
  const baseContainerProps = { done, onClick: () => onToggle() };
  if (!done) {
    const errorsLabel = 'Error';

    return (
      <WithErrors {...baseContainerProps}>
        <span>
          {errors} {errors === 1 ? errorsLabel : `${errorsLabel}s`} <Icon type={open ? 'angle-up' : 'angle-down'} />
        </span>
      </WithErrors>
    );
  }

  return (
    <NoRemainingErrorsContainer {...baseContainerProps}>
      <div>{checkIcon}</div>
      <NoRemainingErrors>
        {' '}
        No Errors <Icon style={{ marginLeft: '3px' }} type={open ? 'angle-down' : 'angle-up'} />{' '}
      </NoRemainingErrors>
    </NoRemainingErrorsContainer>
  );
};

const BaseContainer = styled.div<{ done: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  width: 125px;
  font-size: 12px;
  margin-left: 10px;
  cursor: pointer;
  ${({ done }) => done && 'margin-left: auto'};
`;

const WithErrors = styled(BaseContainer)`
  background-color: ${GetColor.Alert};
`;

const NoRemainingErrorsContainer = styled(BaseContainer)`
  font-size: 14px;
  margin-left: 150px;
  width: 100%;
`;

const NoRemainingErrors = styled.div`
  bottom: 5px;
  color: ${GetColor.HintGrey};
  font-size: 10px;
  margin-left: 15px;
`;

export default ErrorToggle;
