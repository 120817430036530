import React from 'react';
import { ButtonIcon } from 'venn-ui-kit';
import styled from 'styled-components';

interface DataExportButtonProps {
  disabled?: boolean;
  onExport: () => void;
  absolutePosition?: boolean;
}

const DataExportButton = ({ onExport, disabled, absolutePosition }: DataExportButtonProps) => {
  return (
    <ExportData absolutePosition={absolutePosition}>
      <ButtonIcon
        border
        iconType="download"
        disabled={disabled}
        onClick={onExport}
        tooltip={disabled ? 'There is no data to available export' : 'Export data'}
      />
    </ExportData>
  );
};

export default DataExportButton;

const ExportData = styled.div<{ absolutePosition?: boolean }>`
  ${({ absolutePosition }) =>
    absolutePosition
      ? `
    position: absolute;
    right: 30px;
    top: 30px;
    button {
      min-width: auto;
      margin: 0;
    }
  `
      : null}
`;
