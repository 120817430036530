import type { Typography } from '../../style';
import { default as DefaultTypography } from '../default/Typography';

const GreenTypography = (
  process.env.USE_GREEN === 'true' || process.env.NODE_ENV === 'test'
    ? require('../green/Typography').default
    : require('../default/Typography').default
) as Typography;

export const getTypographyTheme = (theme: string) => {
  return theme !== 'default' ? GreenTypography : DefaultTypography;
};
