import React, { useCallback } from 'react';
import type { AnalysisSubject } from 'venn-utils';
import BenchmarkDropMenu from '../../modals/benchmark-dropmenu/BenchmarkDropmenu';
import type { SearchMenuItem } from '../../search-menu';

interface BenchmarkSelectorProps {
  value?: AnalysisSubject;
  onChange: (benchmark?: AnalysisSubject) => void;
  disabled?: boolean;
  resultsFilter?: (item: SearchMenuItem) => boolean;
  resultDisabledMessage?: (item: SearchMenuItem) => string;
  smallScreen?: boolean;
  usePortal?: boolean;
  tooltipPosition?: { top: number; left: number };
}
const BenchmarkSelector: React.FC<React.PropsWithChildren<BenchmarkSelectorProps>> = ({
  value,
  onChange,
  disabled,
  resultsFilter,
  resultDisabledMessage,
  smallScreen,
  usePortal,
  tooltipPosition,
}) => {
  const onClear = useCallback(() => onChange(undefined), [onChange]);

  return (
    <BenchmarkDropMenu
      onSelectBenchmark={onChange}
      disabled={disabled}
      onClearBenchmark={onClear}
      value={value}
      resultsFilter={resultsFilter}
      resultDisabledMessage={resultDisabledMessage}
      smallScreen={smallScreen}
      usePortal={usePortal}
      tooltipPosition={tooltipPosition}
    />
  );
};

export default BenchmarkSelector;
