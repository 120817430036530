import React from 'react';
import styled from 'styled-components';

import { Hint, Headline3, EmptyStateIcon, GetColor } from 'venn-ui-kit';

interface EmptyStateProps {
  onClick: () => void;
}

const ZeroSavedViewsEmptyState = ({ onClick }: EmptyStateProps) => (
  <>
    <Wrapper>
      <EmptyStateIcon />
      <Headline3>No items found</Headline3>
      <Hint data-testid="qa-archive-empty-state-hint">
        <LinkButton className="qa-archive-link" onClick={onClick}>
          Clear filters
        </LinkButton>{' '}
        or export more final documents in Report Lab.
      </Hint>
    </Wrapper>
  </>
);

const Wrapper = styled.div`
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LinkButton = styled.button`
  color: ${GetColor.Primary.Dark};
`;

export default ZeroSavedViewsEmptyState;
