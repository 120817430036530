import React, { useEffect, useMemo, useContext } from 'react';
import type { SharedProps } from '../../types';
import type { BasicTableProps, BasicTableColumn, StyledTableType } from 'venn-components';
import {
  BasicTable,
  DownloadableContentBlock,
  exportToExcel,
  AnalysisConfigContext,
  formatDateRangeForTracking,
} from 'venn-components';
import styled from 'styled-components';
import type { AnyDuringEslintMigration } from 'venn-utils';
import { getAnalysisLabels, SpecialCssClasses } from 'venn-utils';
import { GetColor } from 'venn-ui-kit';
import { isNil } from 'lodash';
import { getColumns, getRows } from './utils';
import { EnhancedMetricName } from '../EnhancedMetricName';

const RiskStatistics = ({
  analyses,
  analysisConfig,
  updateAnalysisStatusForTracking,
  downloadMetaData,
}: SharedProps) => {
  const { subject, category, relative } = analysisConfig;
  const { actualTimeFrame } = useContext(AnalysisConfigContext);

  const categoryActive = category === 'ON';
  const header = `${relative ? 'Relative ' : ''}Historical Risk Statistics`;

  const performance = analyses?.results?.performance;
  const historicalBaseline = performance?.historical?.category;
  const historicalBenchmark = performance?.historical?.benchmark;
  const labels = getAnalysisLabels(
    subject?.type || 'portfolio',
    subject?.secondaryLabel,
    subject?.secondaryPortfolio?.updated,
  );
  const userUploaded = analyses?.subject?.fund?.userUploaded;

  const hasBaselineColumn = !!(subject?.type === 'portfolio' && historicalBaseline && labels.comparison);
  const hasBenchmarkColumn = !!historicalBenchmark;
  const hasCategoryColumn =
    categoryActive && subject?.type === 'investment' && (!!category || userUploaded) && !!subject?.categoryGroup;

  const rows = useMemo(
    () => getRows(hasBaselineColumn, hasBenchmarkColumn, hasCategoryColumn, analyses),
    [analyses, hasBaselineColumn, hasBenchmarkColumn, hasCategoryColumn],
  );

  const rowsToDisplay = rows.map((row) => ({
    ...row,
    name: <EnhancedMetricName name={row.name} description={row.description} learnMoreUrl={row.learnMoreUrl} />,
  }));

  const columns = useMemo(
    () => (subject ? getColumns(hasBaselineColumn, hasBenchmarkColumn, hasCategoryColumn, subject) : []),
    [hasBaselineColumn, hasBenchmarkColumn, hasCategoryColumn, subject],
  );

  useEffect(() => {
    if (analysisConfig.trackingId === -1 || !updateAnalysisStatusForTracking) {
      return;
    }
    let hasAnyValue = false;
    for (const row of rows) {
      if (!isNil(row.subject) || !isNil(row.benchmark) || !isNil(row.category)) {
        hasAnyValue = true;
        break;
      }
    }
    updateAnalysisStatusForTracking(
      [
        {
          name: 'RISK_STATISTICS',
          title: 'Risk Statistics',
          succeeded: hasAnyValue,
          analysisType: header,
          analysesPeriod: analyses?.analysesPeriod,
        },
      ],
      analysisConfig.trackingId,
      !isNil(actualTimeFrame.startTime) && !isNil(actualTimeFrame.endTime)
        ? formatDateRangeForTracking(actualTimeFrame.startTime, actualTimeFrame.endTime)
        : undefined,
    );
  }, [rows, analysisConfig.trackingId, updateAnalysisStatusForTracking, analyses, header, actualTimeFrame]);

  const excelData = useMemo(() => exportToExcel(rows, columns), [columns, rows]);

  return (
    <DownloadableContentBlock
      header={header}
      downloadable={{
        png: true,
        excel: excelData,
        options: {
          fileName: `${subject?.name || ''} - ${header}`,
          width: 900,
          metaData: downloadMetaData,
        },
        tracking: {
          subjectType: subject?.type,
          subjectId: subject?.id,
          description: 'RISK_STATISTICS',
          relativeToBenchmark: relative,
          userUploaded: !!subject?.fund?.userUploaded,
        },
      }}
    >
      <Wrapper>
        <Table data={rowsToDisplay} columns={columns as AnyDuringEslintMigration} rowHeight={36} />
      </Wrapper>
    </DownloadableContentBlock>
  );
};

export default RiskStatistics;

const Wrapper = styled.div`
  border-top: 1px solid ${GetColor.PaleGrey};
`;

const Table: StyledTableType<unknown> = styled(<T extends BasicTableColumn<K>, K>(props: BasicTableProps<T, K>) => (
  <BasicTable<T, K> {...props} />
))`
  table-layout: fixed;

  thead {
    tr {
      th {
        padding-left: 15px;
        min-width: 120px;
        width: 15%;
      }

      th:first-child {
        width: 200px;
        min-width: 120px;
      }

      th:last-child {
        width: 100%;

        .${SpecialCssClasses.ExportAsImage} & {
          width: 15px;
        }
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid ${GetColor.Grey};

      td:first-child {
        padding-left: 15px;
      }
    }

    tr:hover {
      background-color: ${GetColor.DEPRECATED_DivergingColor.B1};
    }

    tr:last-child {
      border-bottom: none;
    }
  }
`;
