import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { GetColor, type Theme, ZIndex } from 'venn-ui-kit';

import type {
  BarFormat,
  BarFormatterParams,
  ColDef,
  ICellRendererParams,
  TooltipRendererParams,
  TooltipRendererResult,
} from 'ag-grid-community';
import { Numbers } from 'venn-utils';
import { BasicHeaderRenderer } from '../components/grid/renderers/BasicHeaderRenderer';
import type { HoldingsTreeRow } from '../types';
import type { GridStyle } from 'venn-state';
import { measureHeader } from '../../utils/grids';

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
const NameCellRenderer = React.memo((params: ICellRendererParams) => {
  return (
    <div>
      <VerticalHoldingsGroupBar level={params.data.level} />
      <CategoryNameLabel isLeaf={params.data.isLeaf} level={params.data.level}>
        {params.data.name}
      </CategoryNameLabel>
    </div>
  );
});

const useSectorBarHoldingsColumns = (hasNegative: boolean, theme: Theme, gridStyle: GridStyle) => {
  const allocationColumnWidth = Math.max(measureHeader('Allocation', theme, gridStyle), 70);
  return useMemo(
    (): ColDef<HoldingsTreeRow>[] => [
      {
        colId: 'name',
        field: 'name',
        headerName: 'Industry',
        headerComponent: BasicHeaderRenderer,
        cellRendererParams: { style: { paddingLeft: '4px', textAlign: 'left', width: '100%' } },
        maxWidth: 260,
        headerComponentParams: {
          style: { textAlign: 'left', width: '100%', color: `${GetColor.Black}` },
        },
        suppressSizeToFit: true,
        cellRenderer: NameCellRenderer,
        autoHeight: true,
      },
      {
        colId: 'allocation',
        field: 'allocation',
        type: 'numericColumn',
        maxWidth: allocationColumnWidth,
        minWidth: allocationColumnWidth,
        headerComponent: BasicHeaderRenderer,
        headerComponentParams: {
          style: { width: '100%', color: `${GetColor.Black}` },
        },
        suppressSizeToFit: false,
        cellStyle: (params) => (params.data?.isLeaf ? null : { fontWeight: 'bold' }),
        valueFormatter: (params) =>
          params.data?.allocation ? `${Numbers.safeFormatNumber(params.data.allocation * 100, 2)}%` : '',
      },
      {
        colId: 'bar',
        field: 'bar',
        headerName: '',
        suppressSizeToFit: false,
        cellRenderer: 'agSparklineCellRenderer',
        cellRendererParams: {
          sparklineOptions: {
            tooltip: {
              enabled: true,
              renderer: (params: TooltipRendererParams): TooltipRendererResult => {
                return {
                  enabled: true,
                  title: 'Allocation:',
                  content: !params.yValue ? '' : `${Numbers.safeFormatNumber(params.yValue * 100, 2)}%`,
                };
              },
            },
            formatter: (params: BarFormatterParams): BarFormat => {
              return {
                fill: params.xValue.toString(),
                strokeWidth: 0,
              };
            },
            type: 'bar',
            valueAxisDomain: [hasNegative ? -1 : 0, 1],
            padding: {
              top: 0,
              bottom: 0,
            },
            axis: {
              strokeWidth: 0,
            },
          },
        },
      },
    ],
    [hasNegative, allocationColumnWidth],
  );
};

export default useSectorBarHoldingsColumns;

const VerticalHoldingsGroupBar = styled.span<{ level: number }>`
  position: absolute;
  height: calc(100% + 2px);
  top: -1px;
  z-index: ${ZIndex.Front};
  ${({ level }) =>
    level > 0 &&
    css`
      border-left: 2px solid ${GetColor.Primary.Main};
    `};
`;

const CategoryNameLabel = styled.span<{ level: number; isLeaf: boolean }>`
  ${({ isLeaf }) =>
    !isLeaf &&
    css`
      font-weight: bold;
    `};
  margin-left: ${({ level }) => level * 10}px;
  display: block;
`;
