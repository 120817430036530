import moment from 'moment';
import type { CreateUpdateMetadata } from '../bulk-management/types';
import type { AppUser, FrequencyEnum } from 'venn-api';
import { getDateFormatForDisplay } from 'venn-utils';

export interface DataRangeInfo {
  start?: number;
  end?: number;
  frequency?: FrequencyEnum;
}

export const formatDateByFrequency = (date: number, frequency: FrequencyEnum): string => {
  return moment.utc(date).format(getDateFormatForDisplay(frequency));
};

export const formatDate = (date?: number) => (date ? moment.utc(date).format('DD MMM YYYY') : undefined);

const getByLine = (user?: AppUser) => (user?.displayName ? ` by ${user?.displayName}` : '');

export const getCreatedDisplay = (data: CreateUpdateMetadata) => `${formatDate(data.created)}${getByLine(data.owner)}`;

export const getUpdatedDisplay = (data: CreateUpdateMetadata) =>
  `${formatDate(data.updated)}${getByLine(data.updatedBy)}`;
