import type { FC } from 'react';
import React from 'react';
import ErrorIcon from './Error';
import InfoIcon from './Info';
import WarningIcon from './Warning';
import SuccessIcon from './Success';
import { Severity } from '../Types';

const icons = {
  [Severity.Info]: <InfoIcon />,
  [Severity.Error]: <ErrorIcon />,
  [Severity.Warn]: <WarningIcon />,
  [Severity.Success]: <SuccessIcon />,
};

interface Props {
  severity: Severity;
}

const Icon: FC<React.PropsWithChildren<Props>> = ({ severity }) => <div>{icons[severity] || <InfoIcon />}</div>;

export default Icon;
