import React, { Component } from 'react';
import styled, { withTheme } from 'styled-components';
import { find, compact } from 'lodash';
import type { Theme } from 'venn-ui-kit';
import { TooltipPosition, Tooltip, GetColor, ColorUtils } from 'venn-ui-kit';
import type { BasicTableColumn, BasicTableProps, StyledTableType } from 'venn-components';
import { BasicTable, ColumnAlign, SORTDIR, FactorDescriptionTooltip, FactorLensesContext } from 'venn-components';
import { Numbers, Dates } from 'venn-utils';
import last from 'lodash/last';
import type {
  FactorWithNonSerializedReturns as FactorEntity,
  RecentPortfolioFactorHistory,
  FactorForecast,
  CustomFactorForecast,
  RecentFactorPerformance,
} from 'venn-api';
import { getCurrentForecast } from 'venn-api';
import FactorSummaryTooltip from '../../../home-page/src/components/FactorSummaryTooltip';

const emptyRenderer = () => <StyledCell className="empty-table-cell">--</StyledCell>;

const percentageRenderer = (value: number) => {
  const formattedValue = Numbers.safeFormatPercentage(value, 1);
  return <StyledCell>{formattedValue}</StyledCell>;
};

interface FactorSummaryTableProps {
  viewFactor: (factorId: number) => void;
  factorHistory: RecentPortfolioFactorHistory;
  factors?: FactorEntity[];
  theme: Theme;
}

interface FactorSummaryTableState {
  factorForecasts: FactorForecast[];
}

const sevenDayReturnRenderer = ({ sevenDayReturn }: RecentFactorPerformance): JSX.Element =>
  percentageRenderer(sevenDayReturn);
const mtdReturnRenderer = ({ mtdReturn }: RecentFactorPerformance): JSX.Element => percentageRenderer(mtdReturn);
const qtdReturnRenderer = ({ qtdReturn }: RecentFactorPerformance): JSX.Element => percentageRenderer(qtdReturn);

class FactorSummaryTable extends Component<FactorSummaryTableProps, FactorSummaryTableState> {
  state: FactorSummaryTableState = {
    factorForecasts: [],
  };

  static defaultProps = {
    factors: [],
  };

  async componentDidMount() {
    try {
      await this.fetchCurrentForecast();
    } catch {
      // No-op
    }
  }

  async fetchCurrentForecast() {
    try {
      const response = await getCurrentForecast();
      const currentForecast: CustomFactorForecast = response.content;
      const { factorForecasts } = currentForecast;
      this.setState({
        factorForecasts,
      });
    } catch {
      // No-op
    }
  }

  factorNameRenderer = ({ id }: RecentFactorPerformance) => {
    const {
      viewFactor,
      factors,
      theme: { Colors },
    } = this.props;
    const factor = find(factors, { id })!;
    if (!factor) {
      return emptyRenderer();
    }

    return (
      <Tooltip
        content={<FactorDescriptionTooltip name={factor.name} description={factor.description} />}
        position={TooltipPosition.Right}
        background={Colors.White}
        maxWidth={320}
        showShadow
        largerPointer
      >
        <StyledName onClick={() => viewFactor(factor.id)} className="qa-factor-tooltip-name">
          {factor.name}
        </StyledName>
      </Tooltip>
    );
  };

  longTermRenderer = ({ id }: RecentFactorPerformance) => {
    const { factorForecasts } = this.state;
    const factorForecast = find(factorForecasts, { id })!;
    if (!factorForecast) {
      return emptyRenderer();
    }

    return percentageRenderer(factorForecast.annualizedReturn);
  };

  cumulativeReturnRenderer = (item: RecentFactorPerformance) => (
    <ReturnWrapper>
      <FactorSummaryTooltip item={item} />
    </ReturnWrapper>
  );

  getTableCols = (): BasicTableColumn<RecentFactorPerformance>[] => {
    return compact([
      {
        label: 'Name',
        accessor: 'name',
        cellRenderer: this.factorNameRenderer,
        headerRenderer: (label: string) => <span style={{ marginLeft: 20 }}>{label}</span>,
      },
      {
        label: 'Cumulative Return',
        accessor: 'cumulativeReturn',
        headerStyle: {
          textAlign: 'left' as const,
          paddingLeft: '40px',
          width: 255,
        },
        cellRenderer: this.cumulativeReturnRenderer,
      },
      {
        label: 'Last Week',
        accessor: 'sevenDayReturn',
        align: ColumnAlign.RIGHT,
        sortable: true,
        sorted: SORTDIR.DESC,
        headerStyle: {
          width: 120,
        },
        cellRenderer: sevenDayReturnRenderer,
      },
      {
        label: 'MTD',
        accessor: 'mtdReturn',
        align: ColumnAlign.RIGHT,
        sortable: true,
        headerStyle: {
          width: 120,
        },
        cellRenderer: mtdReturnRenderer,
      },
      {
        label: 'QTD',
        accessor: 'qtdReturn',
        align: ColumnAlign.RIGHT,
        sortable: true,
        headerStyle: {
          width: 120,
        },
        cellRenderer: qtdReturnRenderer,
      },
      {
        label: 'My Long-Term Forecast',
        accessor: 'longTerm',
        align: ColumnAlign.RIGHT,
        headerStyle: {
          width: 120,
        },
        cellRenderer: this.longTermRenderer,
      },
    ]);
  };

  renderHead = () => (
    <StyledTr>
      <th />
      <th />
      <StyledTh colSpan={3}>Return</StyledTh>
      <th />
      <th />
    </StyledTr>
  );

  renderTail = () => {
    const { factorHistory } = this.props;
    const startDate = factorHistory?.factorPerformance[0]?.ytdCumulativeReturn?.[1]?.[0];
    const endDate = last(factorHistory?.factorPerformance[0]?.ytdCumulativeReturn)?.[0];
    const startText = startDate ? Dates.toDayMonthYear(startDate) : 'Start of year';
    const endText = endDate ? Dates.toDayMonthYear(endDate) : 'Today';
    return (
      <StyledTail className="bottom-legend">
        <td />
        <td colSpan={2}>
          <FlexTd>
            <span>{startText}</span>
            <SpaceSpan>{endText}</SpaceSpan>
          </FlexTd>
        </td>
      </StyledTail>
    );
  };

  render() {
    const { factorHistory } = this.props;
    return (
      <StyledTable
        key={1}
        data={factorHistory.factorPerformance}
        columns={this.getTableCols()}
        renderHead={this.renderHead}
        renderTail={this.renderTail}
        rowClassName={(data) =>
          !data.performanceAlert
            ? undefined
            : data.performanceAlert.positive
              ? 'row-alert-positive'
              : 'row-alert-negative'
        }
      />
    );
  }
}

export default withTheme((props: FactorSummaryTableProps) => (
  <FactorLensesContext.Consumer>
    {({ primaryFactorLens }) => <FactorSummaryTable factors={primaryFactorLens?.factors || []} {...props} />}
  </FactorLensesContext.Consumer>
));

const StyledTable: StyledTableType<unknown> = styled(
  <T extends BasicTableColumn<K>, K>(props: BasicTableProps<T, K>) => <BasicTable<T, K> {...props} />,
)`
  margin: 5px 0 0;

  > tbody,
  > thead {
    > tr {
      > th,
      > td {
        border-bottom: 1px solid ${GetColor.PaleGrey};
      }
    }
  }

  > tbody {
    > tr.row-alert-negative {
      background-color: ${GetColor.DivergingColor.A1};
    }
    > tr.row-alert-positive {
      background-color: ${GetColor.DivergingColor.B1};
    }
    > tr {
      :hover {
        background-color: ${ColorUtils.hex2rgbaFrom(GetColor.DEPRECATED_DataLineColor.PaleBlue, 0.2)};
      }
    }
  }

  > thead {
    border-bottom: 1px solid ${GetColor.Black};
    > tr {
      height: auto;
      :hover {
        background-color: transparent;
      }
      > th {
        color: ${GetColor.DarkGrey};
        border-bottom: 1px solid ${GetColor.Black};
      }
    }
  }
`;

const StyledCell = styled.span`
  color: ${GetColor.Black};
  &.empty-table-cell {
    display: table;
    margin: 0 auto;
  }
`;

const StyledName = styled(StyledCell)`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
  cursor: pointer;
  width: auto;
  margin-left: 20px;
  @media (max-width: 1024px) {
    max-width: 120px;
  }
  @media (max-width: 900px) {
    max-width: 60px;
  }
`;

const StyledTr = styled.tr`
  && {
    border-bottom: none;
    th {
      border-bottom: none;
    }
  }
`;

const StyledTh = styled.th`
  border-top: 2px solid ${GetColor.Black};
  text-transform: uppercase;
  && {
    color: ${GetColor.Black};
    text-align: center;
    font-size: 11px;
    padding-top: 2px;
  }
`;

const FlexTd = styled.div`
  display: flex;
`;

const SpaceSpan = styled.span`
  padding-left: 170px;
`;

const StyledTail = styled.tr`
  border: none;
  font-weight: bold;
  && {
    height: 15px;
    :hover {
      background-color: transparent !important;
    }
  }
  &.bottom-legend {
    > td {
      border: none;
      color: ${GetColor.DarkGrey};
      font-size: 12px;
      padding-top: 3px;
    }
  }
`;

const ReturnWrapper = styled.div`
  padding-left: 35px;
`;
