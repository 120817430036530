import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import {
  Button,
  Flexbox,
  GetColor,
  getTextThemeProvider,
  Headline1,
  Icon,
  PRIVATE_ASSET_LAB_FAQ_HREF,
  Subtitle1,
} from 'venn-ui-kit';
import { analyticsService } from 'venn-utils';

const includedFeatures = ['Fund Performance', 'Cash Flow Pacing Model', 'Fund Information', 'Indices'];
const FeatureContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  font-style: normal;
  padding: 6px;
  gap: 6px;
`;
const StyledFeatureName = styled.div`
  color: ${GetColor.HighlightDark};
`;

const HighlightedFeature = ({ name }: { name: string }) => {
  return (
    <FeatureContainer>
      <Icon type="check" />
      <StyledFeatureName>{name}</StyledFeatureName>
    </FeatureContainer>
  );
};

const BetaContainer = styled.div`
  background-color: ${GetColor.HighlightDark};
  color: ${GetColor.White};
  padding: 6px;
  border-radius: 4px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BetaTag = () => {
  return <BetaContainer>Beta</BetaContainer>;
};
export const PrivateAssetLabTab = () => {
  const { Images } = useContext(ThemeContext);
  return (
    <MainContainer>
      <Flexbox direction="column">
        <Flexbox direction="row" alignItems="center" gap={4}>
          <StyledHeadline>Private Asset Lab</StyledHeadline>
          <BetaTag />
        </Flexbox>
        <StyledSubtitle>Get access to specialized data and analytics for private asset portfolios.</StyledSubtitle>
        <Flexbox direction="row" gap={24}>
          {includedFeatures.map((feature) => (
            <HighlightedFeature key={feature} name={feature} />
          ))}
        </Flexbox>
        <StyledImg key={Images.privatesAsset} src={Images.privatesAsset} alt="private analytics in-app demo" />
        <Flexbox direction="row">
          <Button
            dominant
            onClick={() => {
              window.open(PRIVATE_ASSET_LAB_FAQ_HREF, '_blank');
              analyticsService.ctaClicked({
                destination: 'Private Asset Lab help article',
                filled: true,
                locationOnPage: 'Private Asset lab carousel tab',
                purpose: 'Learn more about Private Asset Lab',
                text: 'Learn more',
                type: 'button',
              });
            }}
            className="qa-learn-more"
            data-testid="qa-learn-more"
            style={{ width: 328, height: 40 }}
          >
            Learn more
          </Button>
          <Button
            dominant
            onClick={() => {
              window.location.href = `mailto:${getTextThemeProvider().salesEmail}?subject=Private Asset Lab Inquiry`;
              analyticsService.ctaClicked({
                destination: 'Private Asset Lab inquiry email',
                filled: true,
                locationOnPage: 'Private Asset lab carousel tab',
                purpose: 'Inquire about Private Asset lab',
                text: 'Questions? Contact us',
                type: 'button',
              });
            }}
            className="qa-questions-mailto"
            data-testid="qa-questions-mailto"
            style={{ width: 328, height: 40 }}
          >
            Questions? Contact us
          </Button>
        </Flexbox>
      </Flexbox>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  max-width: 700px;
  margin-bottom: 36px;
`;

const StyledHeadline = styled(Headline1)`
  font-weight: 500;
  font-style: normal;
  line-height: 60px;
  margin-top: 36px;
`;

const StyledSubtitle = styled(Subtitle1)`
  font-size: 19px;
  margin-bottom: 16px;
`;

const StyledImg = styled.img`
  width: 100%;
  padding: 15px 0px;
`;
