import React from 'react';
import { PrivatePortfolioInformation } from './privates';
import PrivatePortfolioCashflowPacingParameters from './privates/cashflow-pacing/PrivatePortfolioCashflowPacingSettings';
import { MANAGE_DATA_SECTION } from 'venn-utils';
import type { PrivatePortfolioNode } from 'venn-api';

type PrivatePortfolioManageDataProps = Readonly<{
  portfolio?: PrivatePortfolioNode;
  selectedSection?: MANAGE_DATA_SECTION;
}>;

const PrivatePortfolioManageData = ({
  portfolio,
  selectedSection = MANAGE_DATA_SECTION.PORTFOLIO_INFORMATION,
}: PrivatePortfolioManageDataProps) => {
  if (!portfolio) {
    return null;
  }
  switch (selectedSection) {
    case MANAGE_DATA_SECTION.PORTFOLIO_INFORMATION: {
      return <PrivatePortfolioInformation portfolio={portfolio} />;
    }

    case MANAGE_DATA_SECTION.CASH_FLOW_PACING_SETTINGS: {
      return <PrivatePortfolioCashflowPacingParameters portfolio={portfolio} />;
    }

    default: {
      return <></>;
    }
  }
};

export default PrivatePortfolioManageData;
