export enum CreatePortfolioStage {
  ADD_INVESTMENT,
  ADD_STRATEGIES,
  EDIT_PORTFOLIO,
  ADD_ALLOCATION,
  SAVE_AND_VIEW,
}
const { ADD_INVESTMENT, ADD_STRATEGIES, EDIT_PORTFOLIO, ADD_ALLOCATION, SAVE_AND_VIEW } = CreatePortfolioStage;
export const CREATE_PORTFOLIO_STEPS = [
  { key: ADD_INVESTMENT, label: 'Add Investments' },
  { key: ADD_STRATEGIES, label: 'Add Strategies' },
  { key: EDIT_PORTFOLIO, label: 'Edit Portfolio' },
  { key: ADD_ALLOCATION, label: 'Add Allocations' },
  { key: SAVE_AND_VIEW, label: 'Save & View' },
];
