import React, { PureComponent } from 'react';
import type { UserProfileSettings } from 'venn-api';
import styled from 'styled-components';
import { StyledWrapper, Name, FlexWrapper, FlexRow } from '../account-modals/shared';
import { AccountModalWrapper } from '../account-modals/ModalWrapper';
import DisableTwoFactor from './DisableTwoFactor';
import { EnableTwoFactor } from './EnableTwoFactor';
import { TwoFactorEmpty } from './TwoFactorEmpty';
import { TwoFactorEnabled } from './TwoFactorEnabled';
import { SliderToggle } from '../../slider-toggle/SliderToggle';
import { alternateMethod } from './utils';

interface TwoFactorProps {
  profileSettings: UserProfileSettings;
  refresh: () => void;
}

interface TwoFactorState {
  showConfirmDisableTwoFactorModal: boolean;
  showInitializeTwoFactorModal: boolean;
  initializeMethod: string | null;
}

const LOCK_CLASSNAME = 'icon-locked-xs';
const UNLOCK_CLASSNAME = 'icon-unlocked-xs';
const MODAL_TITLE = 'Two Factor Authentication';

export class TwoFactor extends PureComponent<TwoFactorProps, TwoFactorState> {
  state = {
    showInitializeTwoFactorModal: false,
    showConfirmDisableTwoFactorModal: false,
    initializeMethod: null,
  };

  closeInitializeTwoFactorModal = () => {
    this.setState({
      showInitializeTwoFactorModal: false,
    });
  };

  closeDisableTwoFactorModal = () => {
    this.setState({
      showConfirmDisableTwoFactorModal: false,
    });
  };

  onToggleTwoFactor = () => {
    const { profileSettings } = this.props;
    if (profileSettings.mfaEnabled) {
      this.setState({
        showConfirmDisableTwoFactorModal: true,
        initializeMethod: null,
      });
    } else {
      this.setState({
        showInitializeTwoFactorModal: true,
        initializeMethod: null,
      });
    }
  };

  switchMethod = () => {
    this.setState({
      showInitializeTwoFactorModal: true,
      initializeMethod: alternateMethod(this.props.profileSettings),
    });
  };

  render() {
    const { initializeMethod, showConfirmDisableTwoFactorModal, showInitializeTwoFactorModal } = this.state;
    const { profileSettings, refresh } = this.props;
    const iconClassName = profileSettings.mfaEnabled ? 'icon-locked' : 'icon-unlocked';

    return (
      <>
        <StyledWrapper>
          <FlexRow>
            <FlexWrapper>
              <StyledIcon className={iconClassName} />
              <Name>Two Factor Authentication</Name>
              <RightElement>
                <SliderToggle
                  tooltipMessage={
                    profileSettings.organization.mfaRequired
                      ? 'Your workspace requires two-factor authentication'
                      : undefined
                  }
                  disabled={profileSettings.organization.mfaRequired}
                  toggled={profileSettings.mfaEnabled}
                  onToggle={() => this.onToggleTwoFactor()}
                />
              </RightElement>
            </FlexWrapper>
          </FlexRow>
          {profileSettings.mfaEnabled ? (
            <TwoFactorEnabled
              modalTitle={MODAL_TITLE}
              iconClassName={LOCK_CLASSNAME}
              profileSettings={profileSettings}
              switchMethod={this.switchMethod}
              refresh={refresh}
            />
          ) : (
            <TwoFactorEmpty />
          )}
        </StyledWrapper>

        {showConfirmDisableTwoFactorModal && (
          <AccountModalWrapper
            onClose={this.closeDisableTwoFactorModal}
            title={`Disable ${MODAL_TITLE}`}
            iconClassName={UNLOCK_CLASSNAME}
          >
            <DisableTwoFactor onClose={this.closeDisableTwoFactorModal} refresh={refresh} />
          </AccountModalWrapper>
        )}
        {showInitializeTwoFactorModal && (
          <AccountModalWrapper
            onClose={this.closeInitializeTwoFactorModal}
            title={MODAL_TITLE}
            iconClassName={LOCK_CLASSNAME}
          >
            <EnableTwoFactor
              onClose={this.closeInitializeTwoFactorModal}
              refresh={refresh}
              initializeMethod={initializeMethod}
            />
          </AccountModalWrapper>
        )}
      </>
    );
  }
}

export default TwoFactor;

const StyledIcon = styled.div`
  transform: scale(0.7);
  margin-top: -8px;
`;

const RightElement = styled.div`
  position: absolute;
  right: 0;
`;
