import React, { useContext } from 'react';
import { getSelectStyles, getSelectTheme } from './getSelectStyles';
import type { SelectComponentsProps } from 'react-select/lib/Select';
import Creatable from 'react-select/creatable';
import { ThemeContext } from 'styled-components';

/**
 * A prestyled version of react-select https://github.com/JedWatson/react-select
 * @param props
 * @constructor
 */
// @ts-expect-error: TODO fix strictFunctionTypes
const CreatableSelect: React.FunctionComponent<React.PropsWithChildren<SelectComponentsProps>> = React.forwardRef<
  Creatable<SelectComponentsProps>
>(function CreatableSelect(props, ref) {
  const { Colors } = useContext(ThemeContext);
  return <Creatable ref={ref} theme={getSelectTheme(Colors)} styles={getSelectStyles(Colors)} {...props} />;
});

export default CreatableSelect;
