import type { MutableRefObject } from 'react';
import { useEffect, useRef, useState } from 'react';

/**
 * Custom hook calculating bounding client rect dimensions for given Ref.
 * It sets event listeners for 'resize' and 'scroll' events and updates values on every event.
 */
export const useRect = <T extends Element>(): [DOMRect | undefined, MutableRefObject<T | null>] => {
  const ref = useRef<T>(null);
  const [rect, setRect] = useState<DOMRect>();

  const updateRect = () => setRect(ref.current?.getBoundingClientRect());

  const useEffectInEvent = (event: 'resize' | 'scroll', useCapture?: boolean) => {
    useEffect(() => {
      updateRect();
      window.addEventListener(event, updateRect, useCapture);
      return () => window.removeEventListener(event, updateRect, useCapture);
    }, [event, useCapture]);
  };

  useEffectInEvent('resize');
  useEffectInEvent('scroll', true);

  return [rect, ref];
};
