import type { FC } from 'react';
import React from 'react';
import { GetColor } from 'venn-ui-kit';
import styled from 'styled-components';

interface Props {
  y?: number;
}

const Border: FC<React.PropsWithChildren<Props>> = ({ y = 0 }) => <StyledLine x1="0" y1={y} x2="100%" y2={y} />;

export default Border;

const StyledLine = styled.line`
  stroke: ${GetColor.PaleGrey};
  stroke-width: 1;
`;
