import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { IconCircle, GetColor, ProBadge, getAppTitle } from 'venn-ui-kit';
import { StyledWrapper, FlexWrapper, InfoWrapper, Name, FlexRow } from './components/shared';

const Plan = () => {
  const { Colors } = useContext(ThemeContext);

  return (
    <StyledWrapper>
      <FlexRow>
        <FlexWrapper>
          <IconCircle type="rocket" backgroundColor={Colors.HighlightDark}>
            <BadgeContainer>
              <ProBadge />
            </BadgeContainer>
          </IconCircle>
          <InfoWrapper>
            <FlexWrapper>
              <FlexRow>
                <LargerName>{`${getAppTitle()} Pro`}</LargerName>
              </FlexRow>
            </FlexWrapper>
          </InfoWrapper>
        </FlexWrapper>
      </FlexRow>
    </StyledWrapper>
  );
};

export default Plan;

const LargerName = styled(Name)`
  margin-bottom: 5px;
`;

const BadgeContainer = styled.div`
  position: absolute;
  bottom: 20px;
  right: -6px;
  border-radius: 3px;
  border: 2px solid ${GetColor.White};
  display: flex;

  > span {
    margin-left: 0;
  }
`;
