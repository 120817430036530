import React from 'react';
import styled from 'styled-components';
import { Icon, GetColor } from 'venn-ui-kit';

const Circle = styled.div<{ checked?: boolean }>`
  border: ${({ checked }) => (checked ? '' : `1px solid ${GetColor.DarkGrey}`)};
  border-radius: 5px;
  height: 10px;
  width: 10px;
  display: inline-block;
  margin-right: 4px;
  line-height: 10px;
  position: relative;
  margin-bottom: -1px;

  color: ${({ checked }) => (checked ? GetColor.White : GetColor.MidGrey2)};
  background-color: ${({ checked }) => (checked ? GetColor.Primary.Main : 'transparent')};
`;

const Check = styled(Icon)`
  color: ${GetColor.DarkGrey};
  font-size: 0.6em;
  right: 2px;
  top: 3px;
  position: absolute;
`;

const Error = styled(Icon)`
  color: ${GetColor.Alert};
  margin-right: 4px;
`;

interface ValidationCircleProps {
  checked?: boolean;
  showError?: boolean;
}

export const ValidationCircle = ({ checked, showError }: ValidationCircleProps) => (
  <>
    {!showError && <Circle checked={checked}>{checked && <Check type="check" />}</Circle>}
    {showError && <Error type="times-circle" />}
  </>
);
