import type { AssetTypeEnum, Fund, InvestmentSourceTypeEnum } from 'venn-api';
import { getAppTitleSimplifiedUtil } from '../utils';

/**
 * Only user uploaded funds can be edited.
 */
export const isPerformanceEditable = (fund?: Fund) => {
  return fund?.assetType !== 'BENCHMARK' && fund?.userUploaded;
};

/**
 * Both User uploaded and Custodian fund data can be viewed.
 */
export const isPerformanceViewable = (fund?: Fund) => {
  return isPerformanceEditable(fund) || isViewableUserIntegration(fund?.investmentSource);
};

/**
 * Both User uploaded and Custodian fund data can be exported.
 */
export const isPerformanceExportable = isPerformanceViewable;

/**
 * User integrations are determined by investmentSource.
 * There are investments whose remoteType may be EXTERNAL but are NOT user-owned data
 * and shouldn't be treated as an integration, so the remoteType field shouldn't be used.
 */
export const isUserIntegration = (investmentSource?: InvestmentSourceTypeEnum) => {
  return isViewableUserIntegration(investmentSource) || investmentSource === 'EXTERNAL_BYOL';
};

/**
 * Whether an investment of a particular source is a user integration whose performance is viewable.
 */
const isViewableUserIntegration = (investmentSource?: InvestmentSourceTypeEnum) => {
  return investmentSource === 'CUSTODIAN' || investmentSource === 'EXTERNAL_INTEGRATION';
};

/**
 * Get the user readable asset type
 */
export const getAssetTypeName = (assetType: AssetTypeEnum) => {
  switch (assetType) {
    case 'CATEGORY_GROUPING':
      return `${getAppTitleSimplifiedUtil()} Category`;
    case 'BENCHMARK':
      return 'Composite Benchmark';
    case 'CURRENCY':
      return 'Cash Rate';
    case 'CUSTODIAN_ACCOUNT':
      return 'Custodian Account';
    case 'ETF':
      return 'ETF';
    case 'EXTERNAL_INTEGRATION_INVESTMENT':
      return 'External Integration Investment';
    case 'INDEX':
      return 'Index';
    case 'MANAGER':
      return 'Hedge Fund';
    case 'MUTUAL_FUND':
      return 'Mutual Fund';
    case 'STOCK':
      return 'Stock';
    case 'UCIT':
      return 'UCITS';
    case 'UPLOADED_INSTRUMENT':
      return 'User Uploaded';
    case 'CRYPTO_REFERENCE_RATE':
      return 'Crypto Reference Rate';
    default:
      return '--';
  }
};
