import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import SimpleFilter from './SimpleFilter';
import { TypeaheadSearchBar } from 'venn-components';
import { useRecoilValue } from 'recoil';
import type { MorningstarCategory } from 'venn-state';
import { allMorningstarCategoriesSelector } from 'venn-state';
import { withSuspense } from 'venn-utils';

const fieldName = 'Morningstar category';

interface CurrencyFilterProps {
  className?: string;
  onFilter: (updated: MorningstarCategory[]) => void;
  selected: MorningstarCategory[];
  closeOnOnlyClick?: boolean;
  isAdvanced?: boolean;
}

const CategoryFilter = ({ className, selected, onFilter, closeOnOnlyClick, isAdvanced }: CurrencyFilterProps) => {
  const allMorningstarCategories = useRecoilValue(allMorningstarCategoriesSelector);
  const categories = useMemo(
    () =>
      allMorningstarCategories.map((category) => ({
        label: category,
        value: category,
      })),
    [allMorningstarCategories],
  );

  const handleFilter = useCallback(
    (updated: string | string[]) => {
      const updatedCategoryEnum = updated as MorningstarCategory[];
      onFilter(Array.from(updatedCategoryEnum));
    },
    [onFilter],
  );
  const [textFilter, setTextFilter] = useState<string>('');
  const searchComponent = useMemo(() => {
    return (
      <SearchBarContainer>
        <TypeaheadSearchBar
          disableAutofocus
          placeholder="Search Morningstar"
          value={textFilter}
          onChange={setTextFilter}
        />
      </SearchBarContainer>
    );
  }, [textFilter, setTextFilter]);

  return (
    <StyledFilter
      header={searchComponent}
      menuWidth={400}
      className={className}
      label={fieldName}
      items={categories}
      initialSelected={selected}
      onFilter={handleFilter}
      closeOnOnlyClick={closeOnOnlyClick}
      isAdvanced={isAdvanced}
      textFilter={textFilter}
    />
  );
};

const StyledFilter = styled(SimpleFilter)`
  display: inline-flex;
`;

const SearchBarContainer = styled.div`
  padding: 4px;
`;

export default withSuspense(null, CategoryFilter);
