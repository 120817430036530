import React from 'react';
import { noop } from 'lodash';
import type { PortfolioRange } from 'venn-api';
import type { RangeDebugGroup } from '../hooks/useRangesDebug';
import { asyncNoop } from 'venn-utils';

export interface RangeDebugContextValue {
  portfolioRange?: PortfolioRange;
  rangeDebugGroup?: RangeDebugGroup;
  loading: boolean;
  disabled: boolean;
  openManageData: () => void;
  onClearBenchmark: () => void;
  onClearComparison: () => void;
  onClearCategory: () => void;
  onFundDataUpdated: (fundId: string, throwOnFailure?: boolean) => Promise<void>;
  setPortfolioFundDeleter: (callback: (fundId: string) => void) => void;
  onDeleteFundFromPortfolio: (fundId: string) => void;
}

export default React.createContext<RangeDebugContextValue>({
  portfolioRange: undefined,
  rangeDebugGroup: undefined,
  loading: false,
  disabled: true,
  openManageData: noop,
  onClearBenchmark: noop,
  onClearComparison: noop,
  onClearCategory: noop,
  onFundDataUpdated: asyncNoop,
  setPortfolioFundDeleter: noop,
  onDeleteFundFromPortfolio: noop,
} as RangeDebugContextValue);
