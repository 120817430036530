import React, { useCallback, useMemo } from 'react';
import type { CellClassParams } from '../../../data-grid';
import useAnalysisTreeGrid from '../../logic/useAnalysisTreeGrid';
import type { AnalysisGridProps } from '../../types';
import { BOLD_CLASS, GREY_BG_CLASS, LEFT_ALIGN_CLASS } from '../../customAnalysisContants';
import { DATA_TYPE_EXCEL_STYLES } from '../../logic/customBlockUtils';
import ExportableGrid from './ExportableGrid';
import { isPortfolioRowData } from '../../logic/typeUtils';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { blockMetrics, portfolioStrategyFilterType } from 'venn-state';
import { useCommonTreeGridProps } from '../../logic/columnUtils';
import type { ColDef } from 'ag-grid-community';
import { useIsBlockGridCompact } from '../../logic/useIsBlockGridCompact';

const treeGridColDefOverrides: ColDef = {
  cellClassRules: {
    [BOLD_CLASS]: (params: CellClassParams) => params.data.isStrategy,
    [GREY_BG_CLASS]: (params: CellClassParams) => params.data.isMetadata,
    [LEFT_ALIGN_CLASS]: (params: CellClassParams) => typeof params.value === 'string',
  },
};
const AnalysisTreeGrid = ({
  requests,
  responseParser,
  analysesGroup,
  selectedBlock,
  ...exportProps
}: AnalysisGridProps) => {
  const selectedRefId = exportProps.selectedRefId;
  const portfolioStrategyFilter = useRecoilValue(portfolioStrategyFilterType(selectedRefId));
  const setSelectedMetricsIds = useSetRecoilState(blockMetrics(selectedRefId));

  const {
    rowData: rawRowData,
    columnDefs,
    autoGroupColumnDef,
    onDragStoped,
  } = useAnalysisTreeGrid(
    requests,
    responseParser,
    analysesGroup,
    selectedRefId,
    selectedBlock,
    setSelectedMetricsIds,
    exportProps.inPrintMode,
  );

  const rowData = useMemo(
    () =>
      rawRowData.filter((row) => {
        if (
          !portfolioStrategyFilter ||
          portfolioStrategyFilter === 'DEFAULT' ||
          !isPortfolioRowData(row) ||
          row.isMetadata
        ) {
          return true;
        }
        if (!row.isStrategy) {
          return false;
        }

        return portfolioStrategyFilter === 'TOP_LEVEL_STRATEGIES' ? row.path.length === 1 : true;
      }),
    [portfolioStrategyFilter, rawRowData],
  );

  const treeGridProps = useCommonTreeGridProps(treeGridColDefOverrides);
  const isCompact = useIsBlockGridCompact();

  return (
    <ExportableGrid
      {...treeGridProps}
      {...exportProps}
      rowData={rowData}
      exportable
      columnDefs={columnDefs}
      onGridReady={useCallback((event) => {
        // TODO(collin.irwin): seems redundant with the sizeColumnsToFit in ExportableGrid?
        event.api.sizeColumnsToFit();
      }, [])}
      excelStyles={DATA_TYPE_EXCEL_STYLES}
      autoGroupColumnDef={autoGroupColumnDef}
      onDragStopped={onDragStoped}
      isCompact={isCompact}
    />
  );
};

export default AnalysisTreeGrid;
