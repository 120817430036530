import React from 'react';
import type { Portfolio } from 'venn-api';
import { asyncNoop } from 'venn-utils';

export interface PortfoliosContextValue {
  masterPortfolio?: Portfolio;
  demoPortfolio?: Portfolio;
  loading: boolean;
  failedToFetchMasterError?: string;
  setAsMaster: (masterPortfolioId: number) => Promise<void>;
  updateMaster: (portfolio: Portfolio) => Promise<Portfolio | undefined>;
  refresh: () => Promise<void>;
}

export const defaultPortfoliosContextValue: PortfoliosContextValue = {
  masterPortfolio: undefined,
  demoPortfolio: undefined,
  loading: false,
  failedToFetchMasterError: undefined,
  setAsMaster: asyncNoop,
  updateMaster: () => Promise.resolve(undefined),
  refresh: asyncNoop,
};

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
const PortfoliosContext = React.createContext<PortfoliosContextValue>(defaultPortfoliosContextValue);

export default PortfoliosContext;
