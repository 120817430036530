import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';

const Currency = styled.div`
  display: flex;
  background: #212121;
  color: ${GetColor.White};
  padding: 8px 0;
  border-radius: 50%;
  font-weight: 700;
  margin-right: 6px;
  width: 36px;
  height: 36px;
  align-items: center;
  justify-content: center;
  font-size: 1.125rem;
`;

interface FactorLensCurrencyProps {
  activeCurrency?: string;
  className?: string;
}

const FactorLensCurrency: FC<React.PropsWithChildren<FactorLensCurrencyProps>> = ({ activeCurrency, className }) => (
  <Currency className={className}>{activeCurrency}</Currency>
);

export default FactorLensCurrency;
