import React from 'react';
import type {
  PerformanceSummaryResponse,
  ForecastedPerformanceSummary,
  PortfolioPerformanceAttributions,
  PortfolioPerformanceAttribution,
  Analysis,
  AnalysisRequest,
  AnalysisTypeEnum,
  Portfolio,
  Fund,
  ContributionExposure,
} from 'venn-api';
import type { Column, Cell, PerformanceSummaryColumnType } from './types';
import type { AnalysisGroup, AnalysisSubjectType, AnalysisSubjectSecondaryLabel, ExcelCell } from 'venn-utils';
import { Numbers, buildGroup, getAnalysisLabels } from 'venn-utils';
import findIndex from 'lodash/findIndex';
import type { AnalysesResults, Metric, PerformanceAnalysis, PerformanceAnalysisContribution } from '../../types';
import CategoryColumnSuperHeader from './CategoryColumnSuperHeader';
import { compact } from 'lodash';
import {
  EXCESS_RETURN_DEF_HREF,
  TRACKING_ERROR_DEF_HREF,
  INFORMATION_RATIO_DEF_HREF,
  MAX_UNDERPERFORMANCE_DEF_HREF,
} from 'venn-ui-kit';

const formatPercentage = (value: number | undefined) => Numbers.safeFormatPercentage(value, 1);
const formatNumber = (value: number | undefined) => Numbers.safeFormatNumber(value, 2);

interface MetricGetter extends Metric {
  getter: (data: PerformanceSummaryResponse) => number;
  getterForecast: (data: ForecastedPerformanceSummary) => number | undefined;
  getterContribution: (data: PortfolioPerformanceAttributions) => PortfolioPerformanceAttribution;
}

const absoluteMetrics = (
  cumulative: boolean,
  contributionsTooltip: boolean,
  metricPrefix: string,
  showDrawdown: boolean,
): MetricGetter[] =>
  compact([
    {
      name: `${metricPrefix}Return`,
      contributionsTooltip,
      cumulative,
      formatter: formatPercentage,
      percentage: true,
      getter: (data) => (cumulative ? data.totalReturn : data.periodReturn),
      getterForecast: (data) => data.annualizedReturn,
      getterContribution: (data) => data.periodReturn,
    },
    {
      name: `${metricPrefix}Volatility`,
      contributionsTooltip,
      cumulative: false,
      formatter: formatPercentage,
      percentage: true,
      getter: (data) => data.volatility,
      getterForecast: (data) => data.volatility,
      getterContribution: (data) => data.volatility,
    },
    {
      name: `${metricPrefix}Sharpe`,
      contributionsTooltip,
      cumulative: false,
      formatter: formatNumber,
      percentage: false,
      getter: (data) => data.sharpe,
      getterForecast: (data) => data.sharpe,
      getterContribution: (data) => data.sharpe,
    },
    showDrawdown && {
      name: `${metricPrefix}Max Drawdown`,
      contributionsTooltip,
      cumulative: false,
      formatter: formatPercentage,
      percentage: true,
      getter: (data) => data.maxDrawdown,
      getterForecast: () => undefined,
      getterContribution: (data) => data.maxDrawdown,
    },
  ]);

const relativeMetrics = (
  cumulative: boolean,
  contributionsTooltip: boolean,
  metricPrefix: string,
  showUnderperformance: boolean,
): MetricGetter[] =>
  compact([
    {
      name: `${metricPrefix}Excess Return`,
      description:
        'Excess return is the annualized return of the portfolio minus the annualized return of the specified benchmark.',
      learnMoreUrl: EXCESS_RETURN_DEF_HREF,
      contributionsTooltip,
      cumulative,
      formatter: formatPercentage,
      percentage: true,
      getter: (data) => (cumulative ? data.excessTotalReturn : data.periodExcessReturn),
      getterForecast: (data) => data.annualizedExcessReturn,
      getterContribution: (data) => data.periodExcessReturn,
    },
    {
      name: `${metricPrefix}Tracking Error`,
      description:
        'The tracking error, or the amount of risk that the investment or portfolio takes relative to its benchmark.',
      learnMoreUrl: TRACKING_ERROR_DEF_HREF,
      contributionsTooltip,
      cumulative: false,
      formatter: formatPercentage,
      percentage: true,
      getter: (data) => data.trackingError,
      getterForecast: (data) => data.trackingError,
      getterContribution: (data) => data.trackingError,
    },
    {
      name: `${metricPrefix}Information Ratio`,
      description:
        'The information ratio is calculated as the active return of the portfolio divided by the tracking error.',
      learnMoreUrl: INFORMATION_RATIO_DEF_HREF,
      contributionsTooltip,
      cumulative: false,
      formatter: formatNumber,
      percentage: false,
      getter: (data) => data.informationRatio,
      getterForecast: (data) => data.informationRatio,
      getterContribution: (data) => data.informationRatio,
    },
    showUnderperformance && {
      name: `${metricPrefix}Max Underperformance`,
      description:
        "Max underperformance is the max drawdown of the portfolio's excess return compared to the benchmark.",
      learnMoreUrl: MAX_UNDERPERFORMANCE_DEF_HREF,
      abbreviatedName: 'Max Underperf.',
      contributionsTooltip,
      cumulative: false,
      formatter: formatPercentage,
      percentage: true,
      getter: (data) => data.maxUnderperformance,
      getterForecast: () => undefined,
      getterContribution: (data) => data.maxUnderperformance,
    },
  ]);

const emptyContributions = {
  funds: [],
  strategies: [],
};

const mapCells = (
  metrics: MetricGetter[],
  performanceData?: PerformanceSummaryResponse,
  contributions?: PortfolioPerformanceAttributions,
): Cell[] =>
  metrics.map((metric) => {
    if (!performanceData) {
      return {
        value: undefined,
        contributions: emptyContributions,
      };
    }
    return {
      value: metric.getter(performanceData),
      contributions: contributions ? metric.getterContribution(contributions) : emptyContributions,
    };
  });

const mapForecastCells = (
  metrics: MetricGetter[],
  performanceData?: ForecastedPerformanceSummary,
  contributions?: PortfolioPerformanceAttributions,
): Cell[] =>
  metrics.map((metric) => {
    if (!performanceData) {
      return {
        value: undefined,
        contributions: emptyContributions,
      };
    }
    return {
      value: metric.getterForecast(performanceData),
      contributions: contributions ? metric.getterContribution(contributions) : emptyContributions,
    };
  });

interface Data {
  metrics: Metric[];
  columns: Column[];
  excelData: ExcelCell[][];
}

export default (
  analyses: AnalysesResults | undefined,
  type: AnalysisSubjectType,
  relative: boolean,
  categoryActive: boolean,
  contributions: PerformanceAnalysisContribution | undefined,
  secondaryPortfolio: Portfolio | undefined,
  secondaryLabel: AnalysisSubjectSecondaryLabel,
  onFundUpdated: (fund: Fund) => void,
  performance: PerformanceAnalysis | undefined,
  isResidual?: boolean,
): Data => {
  const isPortfolio = type === 'portfolio';
  const isCumulative = !performance?.historical?.subject?.periodAnnualized;
  const metricPrefix = isResidual ? 'Residual ' : '';
  const metrics = relative
    ? relativeMetrics(isCumulative, isPortfolio, metricPrefix, !isResidual)
    : absoluteMetrics(isCumulative, isPortfolio, metricPrefix, !isResidual);

  const historicalContributions = contributions?.historical;
  const forecastedContributions = contributions?.forecast;
  const historicalBaseline = performance?.historical?.category;
  const forecastBaseline = performance?.forecast?.category;
  const historicalBenchmark = performance?.historical?.benchmark;
  const forecastBenchmark = performance?.forecast?.benchmark;
  const baselineName = secondaryPortfolio?.name;
  const labels = getAnalysisLabels(type, secondaryLabel, secondaryPortfolio?.updated);
  const category = analyses?.subject?.categoryGroup;
  const userUploaded = analyses?.subject?.fund?.userUploaded;

  const hasBaselineColumn = type === 'portfolio' && historicalBaseline && labels.comparison;
  const hasBenchmarkColumn = !relative && historicalBenchmark;
  const hasCategoryColumn = categoryActive && type === 'investment' && (!!category || userUploaded);

  const columns: Column[] = compact([
    {
      name: labels.main,
      type: 'MAIN' as PerformanceSummaryColumnType,
      subjectName: analyses?.subject?.name,
      historical: mapCells(metrics, performance?.historical?.subject, historicalContributions?.subject),
      forecast: mapForecastCells(metrics, performance?.forecast?.subject, forecastedContributions?.subject),
    },
    hasBaselineColumn
      ? {
          name: labels.comparison ?? '',
          type: (secondaryLabel === 'Optimized' ? 'SECONDARY_OPTIMIZED' : 'SECONDARY') as PerformanceSummaryColumnType,
          subjectName: baselineName,
          historical: mapCells(metrics, historicalBaseline, historicalContributions?.category),
          forecast: mapForecastCells(metrics, forecastBaseline, forecastedContributions?.category),
        }
      : null,
    hasBenchmarkColumn
      ? {
          name: labels.benchmark,
          type: 'BENCHMARK' as PerformanceSummaryColumnType,
          subjectName: analyses?.subject?.activeBenchmarkName,
          historical: mapCells(metrics, historicalBenchmark, historicalContributions?.benchmark),
          forecast: mapForecastCells(metrics, forecastBenchmark, forecastedContributions?.benchmark),
        }
      : null,
    hasCategoryColumn
      ? {
          name: category ? `Category: ${category.name}` : '',
          type: 'CATEGORY' as PerformanceSummaryColumnType,
          component: <CategoryColumnSuperHeader analyses={analyses} />,
          subjectName: category?.name,
          historical: mapCells(metrics, historicalBaseline, historicalContributions?.category),
          forecast: mapForecastCells(metrics, forecastBaseline, forecastedContributions?.category),
        }
      : null,
  ]);
  return {
    columns,
    metrics,
    excelData: toExcelData(columns, metrics),
  };
};

function toExcelData(columns: Column[], metrics: Metric[]): ExcelCell[][] {
  const rows: ExcelCell[][] = [];
  const mainHeader: ExcelCell[] = [];
  const subHeader: ExcelCell[] = [];
  rows.push(mainHeader);
  rows.push(subHeader);
  mainHeader.push({ value: '' });
  subHeader.push({
    value: 'Metrics',
    bold: true,
  });
  columns.forEach((col) => {
    mainHeader.push({
      value: col.name,
      bold: true,
    });
    mainHeader.push({
      value: '',
    });
    subHeader.push({
      value: 'Historical',
      bold: true,
    });
    subHeader.push({
      value: 'Forecast',
      bold: true,
    });
  });
  metrics.forEach((metric, index) => {
    const row: ExcelCell[] = [];
    row.push({
      value: `${metric.name}${metric.cumulative ? ' (Cumulative)' : ''}`,
      bold: true,
    });
    rows.push(row);
    columns.forEach((col) => {
      row.push({
        value: col.historical[index].value,
        percentage: metric.percentage,
        digits: metric.percentage ? 1 : 2,
      });
      row.push({
        value: col.forecast[index].value,
        percentage: metric.percentage,
        digits: metric.percentage ? 1 : 2,
      });
    });
  });

  return rows;
}

export const convertContributionResponse = (request: Partial<AnalysisRequest>, analyses: Analysis[]) => {
  const subjectIndex = 0;
  const benchmarkIndex = findIndex(request.subjects, (s) => s.comparisonType === 'BENCHMARK');
  const comparisonIndex = findIndex(
    request.subjects,
    (s) => s.comparisonType === 'COMPARISON' || s.comparisonType === 'CATEGORY',
  );
  const indices = [subjectIndex, benchmarkIndex, comparisonIndex];
  const create = createGroup(analyses, indices);
  return {
    historical: create('PERFORMANCE_SUMMARY_HISTORICAL_CONTRIBUTION', (a) => a.historicalPerformanceAttributions),
    forecast: create('PERFORMANCE_SUMMARY_FORECAST_CONTRIBUTION', (a) => a.forecastedPerformanceAttributions),
  };
};

const convertToAttribution = (name: string, weight: number, exposure: ContributionExposure) => {
  return {
    name,
    weight,
    contribution: exposure.exposurePct,
    contributionValue: exposure.exposure,
    value: exposure.unweightedExposure,
  };
};

const extractSubjectResidualContribution = (fundFactorAnalysis: Analysis, index: number, relative: boolean) => {
  if (index < 0 || !fundFactorAnalysis.factorExposureComponents) {
    return undefined;
  }
  const subjectAttribution = fundFactorAnalysis.factorExposureComponents[index];
  if (!subjectAttribution) {
    return undefined;
  }

  const total = subjectAttribution.funds.map((item) => item.weight).reduce((prev, curr) => prev + curr, 0);

  const returnAttribution: PortfolioPerformanceAttribution = { funds: [], strategies: [] };
  const volAttribution: PortfolioPerformanceAttribution = { funds: [], strategies: [] };
  const sharpeAttribution: PortfolioPerformanceAttribution = { funds: [], strategies: [] };
  for (let i = 0; i < subjectAttribution.funds.length; i++) {
    const fund = subjectAttribution.funds[i];
    const fundResidual = fund.factorContribution[-1];
    if (fundResidual) {
      const wt = fund.weight / total;
      returnAttribution.funds.push(convertToAttribution(fund.fundName, wt, fundResidual.returnContribution));
      volAttribution.funds.push(convertToAttribution(fund.fundName, wt, fundResidual.volatilityContribution));
      sharpeAttribution.funds.push(convertToAttribution(fund.fundName, wt, fundResidual.sharpeContribution));
    }
  }

  for (let i = 0; i < subjectAttribution.strategies.length; i++) {
    const strategy = subjectAttribution.strategies[i];
    const strategyResidual = strategy.factorContribution[-1];
    if (strategyResidual) {
      const nm = strategy.strategyName;
      const wt = strategy.weight / total;
      returnAttribution.strategies.push(convertToAttribution(nm, wt, strategyResidual.returnContribution));
      volAttribution.strategies.push(convertToAttribution(nm, wt, strategyResidual.volatilityContribution));
      sharpeAttribution.strategies.push(convertToAttribution(nm, wt, strategyResidual.sharpeContribution));
    }
  }

  return relative
    ? {
        periodExcessReturn: returnAttribution,
        informationRatio: sharpeAttribution,
        trackingError: volAttribution,
      }
    : {
        periodReturn: returnAttribution,
        sharpe: sharpeAttribution,
        volatility: volAttribution,
      };
};

export const extractResidualContribution = (
  request: Partial<AnalysisRequest>,
  fundFactorAnalysis: Analysis,
  relative: boolean,
) => {
  const subjectIndex = 0;
  const benchmarkIndex = findIndex(request.subjects, (s) => s.comparisonType === 'BENCHMARK');
  const comparisonIndex = findIndex(
    request.subjects,
    (s) => s.comparisonType === 'COMPARISON' || s.comparisonType === 'CATEGORY',
  );
  return {
    historical: {
      subject: extractSubjectResidualContribution(fundFactorAnalysis, subjectIndex, relative),
      benchmark: extractSubjectResidualContribution(fundFactorAnalysis, benchmarkIndex, relative),
      category: extractSubjectResidualContribution(fundFactorAnalysis, comparisonIndex, relative),
    },
    forecast: undefined,
  } as PerformanceAnalysisContribution;
};

function createGroup(analyses: Analysis[], indicies: number[]) {
  return function createdGroup<T>(
    type: AnalysisTypeEnum,
    getter: (analysis: Analysis) => T[],
  ): AnalysisGroup<T> | undefined {
    const exposures = analyses.filter((a) => a.analysisType === type);
    if (!exposures.length) {
      return undefined;
    }
    const data = exposures[0];
    return buildGroup(getter(data), indicies, data?.message);
  };
}
