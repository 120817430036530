import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { createPortfolioV3, type Portfolio } from 'venn-api';
import { LoadingSize, Spinner } from 'venn-ui-kit';
import { getAnalysisPathForPortfolio, logExceptionIntoSentry, useHasFF } from 'venn-utils';
import UniversalUploaderContext from '../../../contexts/universal-uploader';
import UserContext from '../../../contexts/user-context';
import { OwnershipContextSelector } from '../../components/OwnershipContextSelector';
import BasePortfolio, {
  type BasePortfolioChangeCallback,
  type BasePortfolioStateUpdater,
} from '../../create-portfolio/BasePortfolio';
import Name from '../../create-portfolio/Name';
import type { BasePortfolioField, NameField } from '../../create-portfolio/types';
import { IChooseActionPortfolioView } from '../constants';
import { StyledModalFooter } from '../components/page-parts/UniversalUploaderFooter';

type Mode = 'NEW_PORTFOLIO' | 'DUPLICATE_PORTFOLIO';

type PortfolioCreatorProps = Readonly<{
  mode: Mode;
  onCreated?: (portfolio: Portfolio) => void;
}>;

const PortfolioCreator = ({ mode, onCreated }: PortfolioCreatorProps) => {
  const [name, setName] = React.useState<NameField>({
    isError: true,
    validating: false,
    value: undefined,
  });
  const [basePortfolio, setBasePortfolio] = React.useState<BasePortfolioField>({
    allowFromScratch: false,
    disabled: false,
    fromMaster: false,
    hidden: false,
    loading: false,
  });

  const hasContextSwitching = useHasFF('context_switching');
  const { currentContext } = useContext(UserContext);

  const [ownerContextId, setOwnerContextId] = React.useState(currentContext);

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const isSubmitEnabled =
    !name.isError &&
    !name.validating &&
    !basePortfolio.loading &&
    !isSubmitting &&
    !(mode === 'DUPLICATE_PORTFOLIO' && !basePortfolio.baseline);
  const isLoading = name.validating || basePortfolio.loading || isSubmitting;

  const { closeFlyout, setView } = useContext(UniversalUploaderContext);
  const history = useHistory();
  const onBasePortfolioChange = (updater: BasePortfolioStateUpdater, callback?: BasePortfolioChangeCallback) => {
    setBasePortfolio(updater);
    callback?.();
  };

  const createPortfolio = (callback: (portfolio: Portfolio) => void) => async () => {
    setIsSubmitting(true);

    try {
      const newPortfolio = (
        await createPortfolioV3({
          ...(basePortfolio.baseline ?? {}),
          name: name.value,
          optimizationId: undefined,
          draft: false,
          demo: false,
          ownerContextId,
        })
      ).content;
      onCreated?.(newPortfolio);
      callback(newPortfolio);
    } catch (error) {
      logExceptionIntoSentry(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container>
      <Form>
        <StyledName onChange={setName} initialValue={name.value} refreshedStyling />
        {mode === 'DUPLICATE_PORTFOLIO' && (
          <StyledBasePortfolio
            refreshedStyling
            showClearButton
            data-testid="base-portfolio"
            basePortfolio={basePortfolio}
            onChange={onBasePortfolioChange}
            placeholder="Select a portfolio"
            label="Duplicate from:"
            secondaryLabel="Investments, strategies, rebalance frequency, and portfolio policy will be copied. The existing portfolio will not be modified."
          />
        )}
        {hasContextSwitching && (
          <StyledOwnershipContextSelector
            requiredAction="CREATE_PORTFOLIO"
            ownerContextId={ownerContextId}
            setOwnerContextId={setOwnerContextId}
          />
        )}
      </Form>
      <StyledModalFooter
        simplifiedButtonStyles
        cancelLabel="< BACK"
        onCancel={() => setView(IChooseActionPortfolioView)}
        secondaryLabel="Create & close"
        primaryLabel="Create & open in analysis"
        secondaryDisabled={!isSubmitEnabled}
        primaryDisabled={!isSubmitEnabled}
        rightChildren={isLoading && <Spinner size={LoadingSize.small} />}
        onSecondaryClick={createPortfolio(closeFlyout)}
        onPrimaryClick={createPortfolio((portfolio) => {
          closeFlyout();
          history.push(getAnalysisPathForPortfolio(portfolio.id));
        })}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Form = styled.div`
  padding: 0 24px;
  width: 100%;
  flex: 1;
`;

const StyledName = styled(Name)`
  & label {
    font-size: 14px;
  }
`;

const StyledBasePortfolio = styled(BasePortfolio)`
  & label {
    font-size: 14px;
    margin-bottom: 5px;
    display: block;
  }
`;

const StyledOwnershipContextSelector = styled(OwnershipContextSelector)`
  & label {
    font-size: 14px;
  }
`;

export default PortfolioCreator;
