import type React from 'react';
import WaterfallChartRows from './WaterfallChartRows';
import { prepareWaterfallFactors } from './logic';
import type { ChartRowProviderProps } from './summary-chart/SummaryChart';

const WaterfallChartRowsProvider: React.FC<React.PropsWithChildren<ChartRowProviderProps>> = ({ factors, children }) =>
  children({
    preparedFactors: prepareWaterfallFactors(factors),
    RowsComponent: WaterfallChartRows,
  });

export default WaterfallChartRowsProvider;
