import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import { HeaderCell } from '../Table';
import { Icon } from 'venn-ui-kit';
import type { SortKey } from './useModalLogic';
import { SpecialCssClasses } from 'venn-utils';

interface SortableHeaderProps {
  sortKey: SortKey;
  current: SortKey;
  asc: boolean;
  right?: boolean;
  width?: number;
  onClick?: (sortKey: string) => void;
}

const SortableHeader: FC<React.PropsWithChildren<SortableHeaderProps>> = ({
  sortKey,
  current,
  asc,
  right,
  width,
  children,
  onClick,
}) => {
  const icon = sortKey === current ? (asc ? 'sort-up' : 'sort-down') : 'sort';
  return (
    <HeaderCell
      align={right ? 'right' : 'left'}
      style={{ width, cursor: 'pointer' }}
      onClick={() => onClick?.(sortKey)}
    >
      <CellContent>
        <span>{children}</span>
        <Icon type={icon} className={SpecialCssClasses.NotDownloadable} />
      </CellContent>
    </HeaderCell>
  );
};

const CellContent = styled.div`
  display: inline-flex;
  align-items: center;
  > span {
    padding-right: 5px;
  }
`;

export default SortableHeader;
