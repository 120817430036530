import type { FactorHistoryPerformance, FrequencyEnum } from 'venn-api';
import type { AnalysisSubject } from 'venn-utils';

export interface Ranges {
  start?: number;
  end?: number;
  maxTimestamp?: number;
  minTimestamp?: number;
  manualStart?: number;
  manualEnd?: number;
  frequency?: FrequencyEnum;
}

export const DefaultRange: Ranges = {};

export const whenFactorIsSelected = (current: Ranges): Ranges => ({
  ...current,
  start: undefined,
  end: undefined,
  manualStart: undefined,
  manualEnd: undefined,
});

export const whenSubjectIsSelected = (current: Ranges): Ranges => ({
  ...current,
  start: current.manualStart ? current.manualStart : undefined,
  end: current.manualEnd ? current.manualEnd : undefined,
});

export const whenUpdatingFactorAnalysis = (
  current: Ranges,
  factorPerformance: Partial<FactorHistoryPerformance>,
): Ranges => ({
  ...current,
  start:
    current.start && factorPerformance.start && current.start >= factorPerformance.start
      ? current.start
      : factorPerformance.start,
  end:
    current.end && factorPerformance.end && current.end <= factorPerformance.end ? current.end : factorPerformance.end,
  maxTimestamp: factorPerformance.maxEnd,
  minTimestamp: factorPerformance.maxStart,
  frequency: factorPerformance.frequency,
});

export const whenDateIsManuallyChanged = (
  current: Ranges,
  start: number | undefined,
  end: number | undefined,
): Ranges => ({
  ...current,
  start: start || current.start || current.minTimestamp,
  end: end || current.end || current.maxTimestamp,
  manualStart: start || current.manualStart || current.minTimestamp,
  manualEnd: end || current.manualEnd || current.maxTimestamp,
});

export const getSubjectRange = (subject?: AnalysisSubject) => {
  if (!subject) {
    return {};
  }
  return subject.fund
    ? {
        start: subject.fund.startRange,
        end: subject.fund.endRange,
      }
    : {
        start: subject.portfolio!.periodStart,
        end: subject.portfolio!.periodEnd,
      };
};
