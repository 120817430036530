import React from 'react';
import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';

export interface DownloadFooterItem {
  value: string;
  label: string;
}

interface DownloadFooterProps {
  items: DownloadFooterItem[];
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default ({ items }: DownloadFooterProps) => {
  return (
    <PrintFooter>
      {items.map((item) => (
        <div key={`${item.value}-${item.label}`}>
          <strong>{item.label}: </strong>
          {item.value}
        </div>
      ))}
    </PrintFooter>
  );
};

const PrintFooter = styled.div`
  background-color: ${GetColor.Grey};
  padding: 8px 20px;
  font-size: 10px;
  > div {
    margin: 4px 0;
  }
`;
