import type { InfoGraphicTypeEnum } from 'venn-api';

type FigureConfigAll = Record<InfoGraphicTypeEnum, string>;
type FigureConfigEnabled = Pick<
  FigureConfigAll,
  | 'GRID'
  | 'PIVOTED_GRID'
  | 'LINE'
  | 'BAR'
  | 'DIVERGENT_BAR'
  | 'HEATMAP'
  | 'SCATTER'
  | 'DISTRIBUTE_BAR'
  | 'CORRELATION'
  | 'GROWTH_SIMULATION'
  | 'PRIVATE_CASH_FLOW'
  | 'BOX_CHART'
  | 'IMAGE'
  | 'PIE'
  | 'GRID_TREE'
  | 'MAP'
  | 'PEER_GROUP'
>;

export const FIGURE_ICONS: FigureConfigEnabled = {
  GRID: 'table-columns',
  PIVOTED_GRID: 'table-rows',
  GRID_TREE: 'table-tree',
  LINE: 'chart-line',
  BAR: 'chart-bar',
  DIVERGENT_BAR: 'chart-bar',
  GROWTH_SIMULATION: 'chart-line-up',
  HEATMAP: 'game-board',
  SCATTER: 'chart-scatter',
  BOX_CHART: 'chart-candlestick',
  CORRELATION: 'game-board-alt',
  DISTRIBUTE_BAR: 'chart-column',
  IMAGE: 'image-landscape',
  PIE: 'chart-pie',
  MAP: 'globe',
  PRIVATE_CASH_FLOW: 'chart-line-up',
  PEER_GROUP: 'chart-waterfall',
};

export const FIGURE_TEXT: FigureConfigEnabled = {
  GRID: 'Grid',
  PIVOTED_GRID: 'Pivoted Grid',
  GRID_TREE: 'Grid with Portfolio Tree',
  LINE: 'Line',
  BAR: 'Bar',
  DIVERGENT_BAR: 'Bar',
  GROWTH_SIMULATION: 'Growth Simulation',
  HEATMAP: 'Heatmap',
  SCATTER: 'Scatter',
  CORRELATION: 'Correlation Matrix',
  BOX_CHART: 'Candlestick',
  PRIVATE_CASH_FLOW: 'Private Cash Flow',
  DISTRIBUTE_BAR: 'Bar',
  IMAGE: 'Image',
  PIE: 'Pie',
  MAP: 'Map',
  PEER_GROUP: 'Stacked Bar',
};
