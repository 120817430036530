import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import type { Portfolio } from 'venn-api';
import { getSpecificPortfolioV3 } from 'venn-api';
import { logExceptionIntoSentry, useApi } from 'venn-utils';
import { GetColor, Label, LoadingSize, Spinner } from 'venn-ui-kit';
import { Modal, ModalContent, ModalFooter, ModalHeader, ModalSubhead } from '../../modal';
import type { SearchMenuItem } from '../../search-menu';
import { SearchMenuBar } from '../../search-menu';
import { isNil } from 'lodash';

interface ImportStrategyModalProps {
  isAddingToRoot: boolean;
  onSubmit: (portfolio: Portfolio) => void;
  onClose: () => void;
}

const ImportStrategyModal = ({ isAddingToRoot, onSubmit, onClose }: ImportStrategyModalProps) => {
  const [fetchedPortfolio, setFetchedPortfolio] = useState<Portfolio | undefined>();
  const [fetching, setFetching] = useState(false);
  const [fetchingError, setFetchingError] = useState(false);

  const getPortfolio = useApi(getSpecificPortfolioV3);
  const fetchPortfolio = useCallback(
    async (portfolioId: number) => {
      setFetching(true);
      try {
        const { content } = await getPortfolio(portfolioId, undefined);
        setFetchedPortfolio(content);
        setFetchingError(false);
      } catch (e) {
        if (e.name !== 'AbortError') {
          logExceptionIntoSentry(e);
          return;
        }
        setFetchedPortfolio(undefined);
        setFetchingError(true);
      }
      setFetching(false);
    },
    [getPortfolio, setFetching, setFetchedPortfolio, setFetchingError],
  );

  return (
    <Modal onClose={onClose}>
      <ModalHeader>Import portfolio as strategy</ModalHeader>
      <ModalSubhead>
        Select item from the list to add it as a {isAddingToRoot ? 'strategy' : 'sub-strategy'} to your{' '}
        {isAddingToRoot ? 'portfolio' : 'strategy'}.
      </ModalSubhead>
      <ModalContent>
        <Label>Select portfolio:</Label>
        <SearchMenuBar
          portfoliosOnly
          autofocus
          usePortal
          onSelected={(item: SearchMenuItem) => {
            const portfolioId = item.searchResult?.portfolioId;
            if (isNil(portfolioId)) {
              setFetchingError(true);
              return;
            }
            fetchPortfolio(portfolioId);
          }}
          location="allocatorPanel-importStrategy"
          privateAssetSearchMode="PUBLIC_ONLY"
        />
      </ModalContent>
      <ModalFooter
        primaryLabel="Import"
        primaryDisabled={fetching || fetchingError || isNil(fetchedPortfolio)}
        onPrimaryClick={() => {
          if (isNil(fetchedPortfolio)) {
            return;
          }
          onSubmit(assignNewIds(fetchedPortfolio));
          onClose();
        }}
        rightChildren={
          fetching ? (
            <Spinner size={LoadingSize.small} />
          ) : fetchingError ? (
            <Error>Portfolio not found.</Error>
          ) : undefined
        }
        onCancel={onClose}
      />
    </Modal>
  );
};

export default ImportStrategyModal;

const Error = styled.div`
  color: ${GetColor.Error};
`;

export function assignNewIds(node: Portfolio): Portfolio {
  const { updatedNode } = assignNewIdsRecursive(node, Date.now());
  return updatedNode;
}

function assignNewIdsRecursive(node: Portfolio, id: number): { updatedNode: Portfolio; lastAssignedId: number } {
  if (!node.children) {
    return {
      updatedNode: { ...node, id },
      lastAssignedId: id,
    };
  }

  let lastAssignedChildId = id;
  const updatedChildren: Portfolio[] = node.children.map((child) => {
    const { updatedNode, lastAssignedId } = assignNewIdsRecursive(child, lastAssignedChildId + 1);
    lastAssignedChildId = lastAssignedId;
    return updatedNode;
  });

  return {
    updatedNode: { ...node, id, children: updatedChildren },
    lastAssignedId: lastAssignedChildId,
  };
}
