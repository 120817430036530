import { type AxisTypeValue, type SeriesOptionsType } from 'highcharts';
import { merge } from 'lodash';
import ordinal from 'ordinal';
import React, { useMemo, useRef } from 'react';
import Measure from 'react-measure';
import { useRecoilValue } from 'recoil';
import styled, { css, useTheme } from 'styled-components';
import {
  blockConfidenceLevels,
  blockExportMetadata,
  blockFonts,
  blockLimitedRequestSubjects,
  blockLogarithmicScale,
  blockYAxisUnitFormat,
  isReportState,
  recoilBlockContentSize,
  UnitFormat,
} from 'venn-state';
import { assertNotNil, DEFAULT_USE_LOGARITHMIC_SCALE, Numbers } from 'venn-utils';
import { getCustomTooltip } from '../../../../charts/analysis-charts/chart-config-logic';
import DownloadableContentBlock, { EmptyHeaderSpace } from '../../../../content-block/DownloadableContentBlock';
import HighchartsReact from 'highcharts-react-official';
import Highstock from 'highcharts/highstock';
import HighchartsUtils, { DEFAULT_QUARTERLY_TICK_INTERVAL } from '../../../../utils/highcharts';
import { useBlockId } from '../../../contexts/BlockIdContext';
import { WATERMARK_POSITION_TOP } from '../../../customAnalysisContants';
import useExportUpdate from '../../../logic/useExportUpdate';
import { useSetBlockSize } from '../../../logic/useSetBlockSize';
import { getLineChartOptions } from '../../charts/line-chart/utils';
import { useNAVBreakdownChartExportDataFunction } from './logic/useNAVBreakdownChartExportDataFunction';
import { usePublicPrivateGrowthNAVBreakdownChartData } from './logic/usePublicPrivateGrowthNAVBreakdownChartData';
import type { PublicPrivateAssetGrowthBlockProps } from './types';
import { useChartReflow } from '../../../../hooks/useChartReflow';

export const PublicPrivateAssetGrowthBreakdownBlock = ({
  data,
  downloadableContentRef,
  exportable,
  inPrintMode,
}: PublicPrivateAssetGrowthBlockProps) => {
  const blockId = useBlockId();
  const chartRef = useRef<HighchartsReact.RefObject>(null);
  useChartReflow(blockId, chartRef);
  const isReport = useRecoilValue(isReportState);
  const legendFont = useRecoilValue(blockFonts.blockChartLegend(blockId));
  const axisFont = useRecoilValue(blockFonts.blockChartAxis(blockId));
  const yAxisFormat = useRecoilValue(blockYAxisUnitFormat(blockId));
  const onResize = useSetBlockSize(recoilBlockContentSize.transformedState(blockId));
  const exportMetaData = useRecoilValue(blockExportMetadata(blockId));
  const useLogarithmicScale = useRecoilValue(blockLogarithmicScale(blockId)) ?? DEFAULT_USE_LOGARITHMIC_SCALE;
  const theme = useTheme();
  const confidenceLevel = useRecoilValue(blockConfidenceLevels(blockId))[0];
  const subjects = useRecoilValue(blockLimitedRequestSubjects(blockId)).filter((subject) => !subject.private);
  const firstSubject = subjects.length ? subjects[0] : undefined;

  const { series, fundingFailureSeries } = usePublicPrivateGrowthNAVBreakdownChartData(assertNotNil(data));
  const excelDataFn = useNAVBreakdownChartExportDataFunction({
    subjectsSeries: series,
    exportable,
    dataFormat: yAxisFormat,
  });
  useExportUpdate({
    exportMetaData,
    excelDataFn,
    selectedRefId: blockId,
  });

  const options = useMemo(() => {
    const chartConfig = getLineChartOptions({
      series: [],
      theme,
      yAxisUnitFormat: yAxisFormat === UnitFormat.PERCENT ? 'percent' : 'allocation',
      frequency: 'QUARTERLY',
      venncastEnabled: false,
      legendFontSize: legendFont.fontSizePt,
      axisFontSize: axisFont.fontSizePt,
    });

    const tooltipFormatter = (value?: number | string | null) => {
      if (typeof value !== 'number' || value === null || Number.isNaN(value)) {
        return '--';
      }
      if (yAxisFormat === UnitFormat.PERCENT) {
        return Numbers.safeFormatNumber(value, 1);
      }
      const shortenedNum = Numbers.formatNumber(Numbers.shortenNumber(value), 2);
      return `${shortenedNum}${Numbers.getNumberShorthand(value)}`;
    };

    const tooltip = getCustomTooltip(
      undefined,
      'QUARTERLY',
      theme.Colors,
      yAxisFormat === UnitFormat.ALLOCATION ? '$' : undefined,
      tooltipFormatter,
    );

    return {
      ...chartConfig,
      yAxis: merge(chartConfig.yAxis, {
        startOnTick: false,
        endOnTick: false,
        showFirstLabel: true,
        showLastLabel: true,
        maxPadding: HighchartsUtils.yAxisPaddingBasedOnFontSize(axisFont.fontSizePt),
        type:
          useLogarithmicScale && yAxisFormat === UnitFormat.ALLOCATION ? 'logarithmic' : ('linear' as AxisTypeValue),
      }),
      xAxis: merge(chartConfig.xAxis, {
        startOnTick: false,
        endOnTick: false,
        showFirstLabel: true,
        tickInterval: DEFAULT_QUARTERLY_TICK_INTERVAL,
        labels: {
          formatter: HighchartsUtils.quarterlyFormatter,
        },
        max: data[0]?.simulationRange?.end,
      }),
      plotOptions: {
        ...chartConfig.plotOptions,
        column: {
          grouping: false,
          pointPadding: 0,
          groupPadding: 0,
          borderWidth: 1,
          maxPointWidth: 20,
          stacking: 'normal' as const,
        },
      },
      tooltip: {
        ...tooltip,
        useHTML: true,
        formatter: HighchartsUtils.fundingFailureFormatter(
          (context) => context.points?.[0]?.point,
          tooltip.formatter,
          theme,
        ),
      },
      // TODO: '$' is hardcoded, this will need to change if we support other currencies.
      series: [...series, ...fundingFailureSeries] as SeriesOptionsType[],
    };
  }, [
    theme,
    yAxisFormat,
    legendFont.fontSizePt,
    axisFont.fontSizePt,
    useLogarithmicScale,
    data,
    series,
    fundingFailureSeries,
  ]);

  const chartComponent = (
    <Wrapper isReport={isReport}>
      <Measure bounds onResize={onResize}>
        {({ measureRef }) => (
          <AssetGrowthBreakdownChartContainer ref={measureRef} isReport={isReport} data-testid="qa-nav-breakdown-chart">
            <HighchartsReact
              highcharts={Highstock}
              ref={chartRef}
              containerProps={{ className: 'qa-nav-breakdown-highchart' }}
              options={options}
            />
          </AssetGrowthBreakdownChartContainer>
        )}
      </Measure>
    </Wrapper>
  );

  if (inPrintMode) {
    return chartComponent;
  }

  return (
    <DownloadableContentBlock
      header={<EmptyHeaderSpace />}
      noBorder
      downloadable={{
        png: true,
        options: {
          fileName: `Asset Growth NAV Breakdown - ${ordinal(Math.round(confidenceLevel * 100))} Percentile`,
          watermark: {
            top: WATERMARK_POSITION_TOP,
            right: 20,
          },
        },
        tracking: {
          description: 'GROWTH_SIMULATION_PUBLIC_PRIVATE',
          relativeToBenchmark: false,
          userUploaded: false,
          subjectType: firstSubject?.subjectType
            ? firstSubject?.subjectType === 'PORTFOLIO'
              ? 'portfolio'
              : 'investment'
            : undefined,
          subjectId: firstSubject?.id,
        },
        disabled: (series?.length ?? 0) === 0,
        target: downloadableContentRef,
      }}
      floatingOptions
    >
      {chartComponent}
    </DownloadableContentBlock>
  );
};

const AssetGrowthBreakdownChartContainer = styled.div<{ isReport: boolean }>`
  min-height: 100px;
  min-width: 200px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .qa-nav-breakdown-highchart {
    height: 100%;
  }

  ${({ isReport }) =>
    isReport &&
    css`
      width: 100%;
      height: 100%;

      .qa-nav-breakdown-highchart {
        width: 100%;
        height: 100%;
      }
    `}
`;

const Wrapper = styled.div<{ isReport: boolean }>`
  ${({ isReport }) =>
    isReport &&
    css`
      width: 100%;
      height: 100%;
    `}
`;
