import React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import type { PrivatePortfolioNode } from 'venn-api';
import { allocatorAnalysisSubject, isAnyAllocatorOpen, openPrivateAllocatorPortfolio } from 'venn-state';
import { StudioButton, Tooltip, TooltipPosition } from 'venn-ui-kit';
import type { AnalysisSubject } from 'venn-utils';
import { analyticsService } from 'venn-utils';
import { CalculatorIcon } from './AllocationPanelLauncherAction';

const getTooltipContent = (
  isDisabled: boolean,
  subject: AnalysisSubject,
  portfolioInPrivateAllocator?: PrivatePortfolioNode,
) => {
  if (!isDisabled) {
    return 'Open privates allocator';
  }
  return subject.id !== portfolioInPrivateAllocator?.id
    ? 'Close currently open allocations before viewing this item'
    : '';
};

export const PrivatesAllocatorLauncher = ({ subject }: { subject: AnalysisSubject }) => {
  const [portfolioInPrivateAllocator, setPortfolioInPrivateAllocator] = useRecoilState(openPrivateAllocatorPortfolio);
  const studioSubject = { privatePortfolioId: subject.privatePortfolio?.id };
  const setAllocatorAnalysisSubject = useSetRecoilState(allocatorAnalysisSubject(studioSubject));

  const disabled = useRecoilValue(isAnyAllocatorOpen);
  return (
    <Tooltip
      content={getTooltipContent(disabled, subject, portfolioInPrivateAllocator)}
      position={TooltipPosition.Left}
    >
      <StudioButton
        data-testid="open-allocator-button"
        size="tiny"
        disabled={disabled}
        onClick={() => {
          setAllocatorAnalysisSubject(subject);
          setPortfolioInPrivateAllocator(subject.privatePortfolio);
          analyticsService.allocatorPanelOpened({
            isPrivateAllocatorPanel: true,
            portfolioId: subject.privatePortfolio?.id,
          });
        }}
      >
        <CalculatorIcon type="calculator" disabled={disabled} />
      </StudioButton>
    </Tooltip>
  );
};
