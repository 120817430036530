import type { NotablePeriod } from 'venn-api';

const notablePeriods: { notablePeriods: { subject: NotablePeriod[]; benchmark: NotablePeriod[] } } = {
  notablePeriods: {
    subject: [
      {
        start: 1398902399999,
        end: 1404172799999,
        extremumDate: 1401580799999,
        extremumValue: 0.3974925552360011,
        type: 'DRAWDOWN',
      },
      {
        start: 1459468799999,
        end: 1512086399999,
        extremumDate: 1512086399999,
        extremumValue: 1.2489136985545477,
        type: 'RALLY',
      },
      {
        start: 1055807999999,
        extremumDate: 1062547199999,
        type: 'PREDEFINED',
        name: 'Treasuries Sell-Off',
        id: 1,
      },
      {
        start: 1087948799999,
        extremumDate: 1140566399999,
        type: 'PREDEFINED',
        name: 'EM Melt-Up',
        id: 2,
      },
      {
        start: 1169164799999,
        extremumDate: 1216079999999,
        type: 'PREDEFINED',
        name: 'Oil Price Melt-Up',
        id: 3,
      },
      {
        start: 1185407999999,
        extremumDate: 1186703999999,
        type: 'PREDEFINED',
        name: 'Quant Meltdown',
        id: 4,
      },
      {
        start: 1221523199999,
        extremumDate: 1236643199999,
        type: 'PREDEFINED',
        name: 'Global Financial Crisis',
        id: 5,
      },
      {
        start: 1236729599999,
        extremumDate: 1263254399999,
        type: 'PREDEFINED',
        name: 'Global V-Shaped Recovery',
        id: 6,
      },
      {
        start: 1309823999999,
        extremumDate: 1322265599999,
        type: 'PREDEFINED',
        name: 'European Sovereign Debt Crisis',
        id: 7,
      },
      {
        start: 1362182399999,
        extremumDate: 1395273599999,
        type: 'PREDEFINED',
        name: 'EM Fragile Five',
        id: 8,
      },
      {
        start: 1367452799999,
        extremumDate: 1378425599999,
        type: 'PREDEFINED',
        name: 'U.S. Taper Tantrum',
        id: 9,
      },
      {
        start: 1403222399999,
        extremumDate: 1422662399999,
        type: 'PREDEFINED',
        name: 'Oil Price Shock of 2014',
        id: 10,
      },
      {
        start: 1421193599999,
        extremumDate: 1421798399999,
        type: 'PREDEFINED',
        name: 'CHF unpegged from EUR',
        id: 11,
      },
      {
        start: 1435708799999,
        extremumDate: 1453334399999,
        type: 'PREDEFINED',
        name: 'Oil Price Shock of 2015',
        id: 12,
      },
      {
        end: 1564739199999,
        start: 1464739199999,
        extremumDate: 1470009599999,
        extremumValue: 1.0650793068242408,
        type: 'PREDEFINED',
        name: 'Brexit',
        id: 13,
      },
      {
        start: 1491436799999,
        extremumDate: 1536710399999,
        type: 'PREDEFINED',
        name: 'EM Meltdown',
        id: 14,
      },
      {
        start: 1582156799999,
        extremumDate: 1585007999999,
        type: 'PREDEFINED',
        name: 'COVID-19 Crisis',
        id: 15,
      },
    ] as NotablePeriod[],
    benchmark: [
      {
        start: 1398902399999,
        end: 1404172799999,
        extremumDate: 1401580799999,
        extremumValue: 0.2859084126278734,
        type: 'DRAWDOWN',
      },
      {
        start: 1459468799999,
        end: 1512086399999,
        extremumDate: 1512086399999,
        extremumValue: 1.091901128621309,
        type: 'RALLY',
      },
      {
        start: 1055807999999,
        extremumDate: 1062547199999,
        type: 'PREDEFINED',
        name: 'Treasuries Sell-Off',
        id: 1,
      },
      {
        start: 1087948799999,
        extremumDate: 1140566399999,
        type: 'PREDEFINED',
        name: 'EM Melt-Up',
        id: 2,
      },
      {
        start: 1169164799999,
        extremumDate: 1216079999999,
        type: 'PREDEFINED',
        name: 'Oil Price Melt-Up',
        id: 3,
      },
      {
        start: 1185407999999,
        extremumDate: 1186703999999,
        type: 'PREDEFINED',
        name: 'Quant Meltdown',
        id: 4,
      },
      {
        start: 1221523199999,
        extremumDate: 1236643199999,
        type: 'PREDEFINED',
        name: 'Global Financial Crisis',
        id: 5,
      },
      {
        start: 1236729599999,
        extremumDate: 1263254399999,
        type: 'PREDEFINED',
        name: 'Global V-Shaped Recovery',
        id: 6,
      },
      {
        start: 1309823999999,
        extremumDate: 1322265599999,
        type: 'PREDEFINED',
        name: 'European Sovereign Debt Crisis',
        id: 7,
      },
      {
        start: 1362182399999,
        extremumDate: 1395273599999,
        type: 'PREDEFINED',
        name: 'EM Fragile Five',
        id: 8,
      },
      {
        start: 1367452799999,
        extremumDate: 1378425599999,
        type: 'PREDEFINED',
        name: 'U.S. Taper Tantrum',
        id: 9,
      },
      {
        start: 1403222399999,
        extremumDate: 1422662399999,
        type: 'PREDEFINED',
        name: 'Oil Price Shock of 2014',
        id: 10,
      },
      {
        start: 1421193599999,
        extremumDate: 1421798399999,
        type: 'PREDEFINED',
        name: 'CHF unpegged from EUR',
        id: 11,
      },
      {
        start: 1435708799999,
        extremumDate: 1453334399999,
        type: 'PREDEFINED',
        name: 'Oil Price Shock of 2015',
        id: 12,
      },
      {
        end: 1564739199999,
        start: 1464739199999,
        extremumDate: 1467417599999,
        extremumValue: 2.0630793068242408,
        type: 'PREDEFINED',
        name: 'Brexit',
        id: 13,
      },
      {
        start: 1491436799999,
        extremumDate: 1536710399999,
        type: 'PREDEFINED',
        name: 'EM Meltdown',
        id: 14,
      },
      {
        start: 1582156799999,
        extremumDate: 1585007999999,
        type: 'PREDEFINED',
        name: 'COVID-19 Crisis',
        id: 15,
      },
    ] as NotablePeriod[],
  },
};
export default notablePeriods;
