import React from 'react';

const EmptyStateIcon = () => (
  <svg width="44" height="36" viewBox="0 0 44 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        opacity="0.4"
        d="M20.6656 18.1202C19.539 20.8486 19.5092 23.9212 20.5826 26.672C21.656 29.4228 23.7461 31.6301 26.4 32.8155V34.3237C26.3982 34.7696 26.2234 35.1966 25.9141 35.5109C25.6047 35.8252 25.186 36.001 24.75 35.9999H1.64176C1.42562 35.9994 1.2117 35.9553 1.01222 35.8702C0.81274 35.7851 0.631603 35.6606 0.479152 35.5039C0.326702 35.3472 0.205922 35.1614 0.12371 34.9569C0.0414977 34.7525 -0.000537199 34.5335 5.18257e-06 34.3124V1.67899C0.000546207 1.45794 0.043654 1.23916 0.126867 1.03515C0.21008 0.831135 0.331769 0.645882 0.484986 0.489966C0.638203 0.334051 0.819947 0.210526 1.01984 0.126445C1.21974 0.0423646 1.43387 -0.000625702 1.65001 -7.09936e-05H17.6V7.87493C17.6 8.1733 17.7159 8.45945 17.9222 8.67042C18.1285 8.8814 18.4083 8.99993 18.7 8.99993H26.4V12.1872C23.8171 13.3422 21.765 15.4654 20.6656 18.1202Z"
        fill="#6600FF"
      />
      <path
        d="M26.3996 8.57103C26.3996 8.35035 26.3571 8.13182 26.2745 7.92794C26.192 7.72405 26.0709 7.5388 25.9184 7.38275L19.1877 0.492124C19.0341 0.335552 18.8518 0.211491 18.6513 0.127037C18.4507 0.0425828 18.2359 -0.000607517 18.019 -6.35903e-05H17.5996V7.87494C17.5996 8.1733 17.7155 8.45945 17.9218 8.67043C18.1281 8.88141 18.4079 8.99994 18.6996 8.99994H26.3996V8.57103ZM43.6772 32.4885L38.3552 27.0456C39.1623 25.6725 39.5922 24.1021 39.5996 22.4999C39.5996 20.7199 39.0835 18.9798 38.1165 17.4998C37.1496 16.0198 35.7752 14.8662 34.1672 14.185C32.5592 13.5038 30.7898 13.3256 29.0828 13.6729C27.3758 14.0201 25.8078 14.8773 24.5771 16.136C23.3464 17.3946 22.5082 18.9983 22.1687 20.7441C21.8291 22.49 22.0034 24.2996 22.6695 25.9441C23.3355 27.5886 24.4634 28.9942 25.9106 29.9832C27.3577 30.9721 29.0591 31.4999 30.7996 31.4999C32.429 31.4999 33.9353 31.0162 35.2443 30.2266L40.5662 35.6702C40.6684 35.7747 40.7897 35.8576 40.9231 35.9141C41.0566 35.9707 41.1997 35.9998 41.3441 35.9998C41.4886 35.9998 41.6317 35.9707 41.7652 35.9141C41.8986 35.8576 42.0199 35.7747 42.122 35.6702L43.6772 34.0797C43.7793 33.9752 43.8604 33.8512 43.9157 33.7147C43.971 33.5782 43.9994 33.4319 43.9994 33.2841C43.9994 33.1364 43.971 32.99 43.9157 32.8535C43.8604 32.717 43.7793 32.593 43.6772 32.4885ZM30.7996 26.9999C29.9294 26.9999 29.0787 26.736 28.3551 26.2415C27.6315 25.7471 27.0676 25.0443 26.7345 24.222C26.4015 23.3997 26.3144 22.4949 26.4842 21.622C26.6539 20.7491 27.073 19.9473 27.6883 19.318C28.3037 18.6886 29.0877 18.26 29.9412 18.0864C30.7947 17.9128 31.6794 18.0019 32.4834 18.3425C33.2874 18.6831 33.9746 19.2598 34.4581 19.9999C34.9416 20.7399 35.1996 21.6099 35.1996 22.4999C35.1996 23.6934 34.736 24.838 33.9109 25.6819C33.0857 26.5258 31.9666 26.9999 30.7996 26.9999Z"
        fill="#6600FF"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="44" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default EmptyStateIcon;
