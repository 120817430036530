import styled from 'styled-components';
import { ZIndex } from 'venn-ui-kit';

export interface ModalOverlayProps {
  /** Html node to portal into - disables overlay styles */
  node?: HTMLElement;
  /** Default value is 11 */
  zIndex?: number;
}

const ModalOverlay = styled.div<ModalOverlayProps>`
  ${(props) => {
    const zIndex = props.zIndex === undefined ? ZIndex.Modal : props.zIndex;

    if (props.node) {
      return `z-index: ${zIndex};`;
    }

    return `
      display: flex;
      top: 0;
      overflow-y: scroll;
      justify-content: center;
      align-items: center;
      position: fixed;
      width: 100%;
      height: 100%;
      background-color: rgba(16, 22, 27, 0.75);
      z-index: ${zIndex};
    `;
  }};
`;

export default ModalOverlay;
