import { getAppTitleSimplifiedUtil } from '../../utils';

export const mockAutocomplete = {
  results: [
    {
      type: 'venn_fund',
      fund: {
        id: '17fc7437-a5e0-4d8d-acc0-dee7c68f9b14',
        name: 'TESSI',
        owner: {
          id: -1,
          name: `${getAppTitleSimplifiedUtil()}`,
          displayName: `${getAppTitleSimplifiedUtil()}`,
          email: null,
          active: true,
          created: null,
          pwdLastUpdated: null,
          avatarId: null,
          actualUser: false,
        },
        frequency: 1,
        startRange: 994982399999,
        endRange: 1564790399999,
        created: 1539340989937,
        userUploaded: false,
        benchmarks: [],
        active: true,
        live: true,
        symbol: 'TES',
        investmentSource: 'VENN',
        investmentType: 'EQUITY',
        investmentRegion: 'EMEA',
        assetType: 'STOCK',
        currency: 'EUR',
        favorite: false,
        viewed: false,
        returnFrequency: 'DAILY',
        investmentBenchmarks: [],
        userControlled: false,
      },
      investment: {
        id: '17fc7437-a5e0-4d8d-acc0-dee7c68f9b14',
        legacyId: null,
        name: 'TESSI',
        owner: {
          id: -1,
          name: `${getAppTitleSimplifiedUtil()}`,
          displayName: `${getAppTitleSimplifiedUtil()}`,
          email: null,
          active: true,
          created: null,
          pwdLastUpdated: null,
          avatarId: null,
          actualUser: false,
        },
        frequency: 1,
        startRange: 994982399999,
        endRange: 1564790399999,
        unproxiedStartRange: 994982399999,
        unproxiedEndRange: 1564790399999,
        analysisStart: 1040947199999,
        analysisEnd: 1564617599999,
        created: 1539340989937,
        updated: 1539340989937,
        fundSourceType: {
          id: 1,
          name: 'Investment',
        },
        userUploaded: false,
        benchmarkID: null,
        legacyBenchmarkId: null,
        benchmarkName: null,
        benchmarks: [],
        active: true,
        live: true,
        symbol: 'TES',
        currency: 'EUR',
        exchange: 'XPAR',
        exchangeCountry: 'FRA',
        assetType: 'STOCK',
        accountNumber: null,
        periodAnnualized: true,
        periodReturn: 0.1599379230500022,
        annualizedTotalReturn: 0.1599379230500022,
        annualizedVolatility: 0.4246519098909977,
        annualizedSharpeRatio: 0.46822384557015895,
        maxDrawdown: {
          start: null,
          end: null,
          trough: null,
          troughDrawdown: -0.6056380744530541,
          troughDrawdownError: 0.0,
        },
        annualizedExcessReturn: null,
        trackingError: null,
        factorLensId: 29,
        factorLensName: 'TSFLWE V3',
        riskContribution: [
          {
            id: 465,
            name: 'Equity',
            value: 0.010032940006948736,
          },
          {
            id: 467,
            name: 'Credit',
            value: 6.212377246491496e-4,
          },
          {
            id: 468,
            name: 'Commodities',
            value: 8.629492024903515e-5,
          },
          {
            id: 469,
            name: 'Foreign Currency',
            value: 0.03322701462957739,
          },
          {
            id: 472,
            name: 'Equity Short Volatility',
            value: 3.556232953860269e-4,
          },
          {
            id: 483,
            name: 'Local Equity',
            value: 1.9471907747663594e-4,
          },
          {
            id: 394,
            name: 'Low Risk',
            value: 0.0010379156824995414,
          },
          {
            id: 395,
            name: 'Momentum',
            value: 2.101458077034518e-4,
          },
          {
            id: 396,
            name: 'Quality',
            value: -0.0013659114660940174,
          },
          {
            id: 397,
            name: 'Value',
            value: 1.903269660569639e-4,
          },
          {
            id: 398,
            name: 'Small Cap',
            value: 0.006557174002963065,
          },
          {
            id: -1,
            name: 'Residual',
            value: 0.948852519352584,
          },
        ],
        groupedRiskContribution: [
          {
            id: null,
            name: 'Equity',
            value: 0.010032940006948736,
          },
          {
            id: null,
            name: 'Credit',
            value: 6.212377246491496e-4,
          },
          {
            id: null,
            name: 'Commodities',
            value: 8.629492024903515e-5,
          },
          {
            id: null,
            name: 'Secondary Macro',
            value: 0.033777357002440056,
          },
          {
            id: null,
            name: 'Equity Styles',
            value: 0.006629650993129005,
          },
          {
            id: -1,
            name: 'Residual',
            value: 0.948852519352584,
          },
        ],
        periodAlpha: 0.07846625289078041,
        annualizedAlpha: 0.07846625289078041,
        annualizedAlphaTStat: null,
        investmentType: 'EQUITY',
        investmentTypeName: 'Equity',
        investmentSource: 'VENN',
        investmentSourceName: `${getAppTitleSimplifiedUtil()}`,
        investmentRegion: 'EMEA',
        investmentRegionName: 'EMEA',
        proxyId: null,
        proxyName: null,
        favorite: false,
        viewed: false,
        investmentBenchmarks: [],
      },
      name: 'TESSI',
    },
    {
      type: 'venn_fund',
      fund: {
        id: 'f6e0ffef-21fa-4bcb-a8b4-79d4213d23f8',
        name: 'TESMEC',
        owner: {
          id: -1,
          name: `${getAppTitleSimplifiedUtil()}`,
          displayName: `${getAppTitleSimplifiedUtil()}`,
          email: null,
          active: true,
          created: null,
          pwdLastUpdated: null,
          avatarId: null,
          actualUser: false,
        },
        frequency: 1,
        startRange: 1278115199999,
        endRange: 1564790399999,
        created: 1539341735114,
        userUploaded: false,
        benchmarks: [],
        active: true,
        live: true,
        symbol: 'TES',
        investmentSource: 'VENN',
        investmentType: 'EQUITY',
        investmentRegion: 'EMEA',
        assetType: 'STOCK',
        currency: 'EUR',
        favorite: false,
        viewed: false,
        returnFrequency: 'DAILY',
        investmentBenchmarks: [],
        userControlled: false,
      },
      investment: {
        id: 'f6e0ffef-21fa-4bcb-a8b4-79d4213d23f8',
        legacyId: null,
        name: 'TESMEC',
        owner: {
          id: -1,
          name: `${getAppTitleSimplifiedUtil()}`,
          displayName: `${getAppTitleSimplifiedUtil()}`,
          email: null,
          active: true,
          created: null,
          pwdLastUpdated: null,
          avatarId: null,
          actualUser: false,
        },
        frequency: 1,
        startRange: 1278115199999,
        endRange: 1564790399999,
        unproxiedStartRange: 1278115199999,
        unproxiedEndRange: 1564790399999,
        analysisStart: 1278115199999,
        analysisEnd: 1564617599999,
        created: 1539341735114,
        updated: 1539341735114,
        fundSourceType: {
          id: 1,
          name: 'Investment',
        },
        userUploaded: false,
        benchmarkID: null,
        legacyBenchmarkId: null,
        benchmarkName: null,
        benchmarks: [],
        active: true,
        live: true,
        symbol: 'TES',
        currency: 'EUR',
        exchange: 'XMIL',
        exchangeCountry: 'ITA',
        assetType: 'STOCK',
        accountNumber: null,
        periodAnnualized: true,
        periodReturn: -0.02581930320317849,
        annualizedTotalReturn: -0.02581930320317849,
        annualizedVolatility: 0.6247190012705223,
        annualizedSharpeRatio: 0.13998133308757388,
        maxDrawdown: {
          start: null,
          end: null,
          trough: null,
          troughDrawdown: -0.6119463296121161,
          troughDrawdownError: 0.0,
        },
        annualizedExcessReturn: null,
        trackingError: null,
        factorLensId: 29,
        factorLensName: 'TSFLWE V3',
        riskContribution: [
          {
            id: 465,
            name: 'Equity',
            value: 0.011353281790104482,
          },
          {
            id: 466,
            name: 'Interest Rates',
            value: -5.825212555446078e-4,
          },
          {
            id: 467,
            name: 'Credit',
            value: 0.0021642301629587303,
          },
          {
            id: 468,
            name: 'Commodities',
            value: 0.0020662842094874255,
          },
          {
            id: 469,
            name: 'Foreign Currency',
            value: 0.01014353951417987,
          },
          {
            id: 470,
            name: 'Local Inflation',
            value: 2.7068124015940174e-4,
          },
          {
            id: 471,
            name: 'Emerging Markets',
            value: 0.01165656075433942,
          },
          {
            id: 483,
            name: 'Local Equity',
            value: 0.004148457728461636,
          },
          {
            id: 395,
            name: 'Momentum',
            value: -1.652528928213902e-4,
          },
          {
            id: 396,
            name: 'Quality',
            value: 0.011002314857599184,
          },
          {
            id: 397,
            name: 'Value',
            value: 0.0036150426414371076,
          },
          {
            id: 398,
            name: 'Small Cap',
            value: 0.005320286768922116,
          },
          {
            id: -1,
            name: 'Residual',
            value: 0.9390070944807166,
          },
        ],
        groupedRiskContribution: [
          {
            id: null,
            name: 'Equity',
            value: 0.011353281790104482,
          },
          {
            id: null,
            name: 'Interest Rates',
            value: -5.825212555446078e-4,
          },
          {
            id: null,
            name: 'Credit',
            value: 0.0021642301629587303,
          },
          {
            id: null,
            name: 'Commodities',
            value: 0.0020662842094874255,
          },
          {
            id: null,
            name: 'Secondary Macro',
            value: 0.02621923923714033,
          },
          {
            id: null,
            name: 'Equity Styles',
            value: 0.019772391375137015,
          },
          {
            id: -1,
            name: 'Residual',
            value: 0.9390070944807166,
          },
        ],
        periodAlpha: 0.0164758652823744,
        annualizedAlpha: 0.0164758652823744,
        annualizedAlphaTStat: null,
        investmentType: 'EQUITY',
        investmentTypeName: 'Equity',
        investmentSource: 'VENN',
        investmentSourceName: `${getAppTitleSimplifiedUtil()}`,
        investmentRegion: 'EMEA',
        investmentRegionName: 'EMEA',
        proxyId: null,
        proxyName: null,
        favorite: false,
        viewed: false,
        investmentBenchmarks: [],
      },
      name: 'TESMEC',
    },
  ],
  scopes: null,
};

export const mockAutocompleteWithPortfolio = {
  results: [
    {
      type: 'venn_scenario',
      portfolio: {
        id: 12345,
        name: 'PortfolioTest',
        allocation: 0.0,
        children: [],
        compare: [],
        locked: false,
        rebalanceStrategy: 'QUARTERLY_REBALANCING',
      },
      name: 'PortfolioTest',
    },
    {
      type: 'venn_fund',
      fund: {
        id: '04ba6101-d6cb-4304-8a9c-b82ed2bc0647',
        name: 'Demo Investment',
        owner: {
          id: 3000071,
          name: 'Selby Walker',
          displayName: 'Selby Walker',
          email: 'selby@twosigma.com',
          active: false,
          created: 1528291719292,
          pwdLastUpdated: null,
          avatarId: '8831451f-6552-4600-bee5-32730384aafe',
          actualUser: false,
        },
        frequency: 2,
        startRange: 1393631999999,
        endRange: 1590969599999,
        created: 1573181803311,
        userUploaded: true,
        investmentBenchmarks: [],
        active: true,
        live: true,
        investmentType: 'NOT_SPECIFIED',
        assetType: 'UPLOADED_INSTRUMENT',
        currency: 'USD',
        favorite: false,
        viewed: false,
        needsProxy: true,
        needsInterpolation: false,
        benchmarks: [],
        userControlled: true,
        returnFrequency: 'MONTHLY',
        proxyable: true,
        interpolatable: false,
        outOfDate: false,
        hasMinDataForInterpolation: false,
        userEditable: true,
      },
      investment: {
        id: '04ba6101-d6cb-4304-8a9c-b82ed2bc0647',
        legacyId: null,
        name: 'Demo Investment',
        owner: {
          id: 3000071,
          name: 'Selby Walker',
          displayName: 'Selby Walker',
          email: 'selby@twosigma.com',
          active: false,
          created: 1528291719292,
          pwdLastUpdated: null,
          avatarId: '8831451f-6552-4600-bee5-32730384aafe',
          actualUser: false,
        },
        frequency: 2,
        startRange: 1393631999999,
        endRange: 1590969599999,
        unproxiedStartRange: 1433116799999,
        unproxiedEndRange: 1590969599999,
        analysisStart: null,
        analysisEnd: null,
        created: 1573181803311,
        updated: 1598652402684,
        interpolated: false,
        fundSourceType: null,
        userUploaded: true,
        benchmarkID: null,
        legacyBenchmarkId: null,
        benchmarkName: null,
        investmentBenchmarks: [],
        active: true,
        live: true,
        symbol: null,
        currency: 'USD',
        domicile: null,
        exchange: null,
        exchangeCountry: null,
        assetType: 'UPLOADED_INSTRUMENT',
        accountNumber: null,
        periodAnnualized: true,
        periodReturn: 0.2157984803884927,
        annualizedTotalReturn: 0.2157984803884927,
        annualizedVolatility: 0.15043903303294343,
        annualizedSharpeRatio: 1.323338009324595,
        maxDrawdown: {
          start: null,
          end: null,
          trough: null,
          troughDrawdown: -0.228506035407963,
          troughDrawdownError: 0.0,
          completed: true,
        },
        annualizedExcessReturn: null,
        trackingError: null,
        factorLensId: 71,
        factorLensName: 'V4-USD',
        riskContribution: [
          { id: -1, name: 'Residual', value: 0.8105375439308832 },
          { id: 10004, name: 'Foreign Currency', value: 0.04554509371185912 },
          { id: 10005, name: 'Local Inflation', value: 0.05162711641322996 },
          { id: 10037, name: 'Fixed Income Carry', value: 0.048129412072451 },
          { id: 10069, name: 'Value', value: 0.029353060762345163 },
          { id: 10045, name: 'Local Equity', value: 0.014807773109231542 },
        ],
        groupedRiskContribution: [
          { id: -1, name: 'Residual', value: 0.8105375439308832 },
          { id: null, name: 'Secondary Macro', value: 0.11197998323432062 },
          { id: null, name: 'Macro Styles', value: 0.048129412072451 },
          { id: null, name: 'Equity Styles', value: 0.029353060762345163 },
        ],
        periodAlpha: null,
        annualizedAlpha: null,
        annualizedAlphaTStat: null,
        investmentType: 'NOT_SPECIFIED',
        investmentTypeName: 'Not specified',
        investmentSource: null,
        investmentSourceName: null,
        investmentRegion: null,
        investmentRegionName: null,
        proxyId: null,
        proxyName: null,
        favorite: false,
        viewed: false,
        needsProxy: false,
        needsInterpolation: false,
      },
      name: 'Demo Investment',
    },
    {
      type: 'venn_fund',
      fund: {
        id: 'bb6deba2-5872-4a31-8ca1-9e9b8689913e',
        name: 'No Returns Fund',
        userUploaded: true,
        investmentBenchmarks: [],
        active: true,
        live: true,
        assetType: 'UPLOADED_INSTRUMENT',
        currency: 'USD',
        favorite: false,
        viewed: false,
        needsProxy: true,
        needsInterpolation: false,
        benchmarks: [],
        userControlled: true,
        proxyable: true,
        interpolatable: false,
        outOfDate: false,
        hasMinDataForInterpolation: false,
        userEditable: true,
      },
      investment: {
        id: 'bb6deba2-5872-4a31-8ca1-9e9b8689913e',
        legacyId: null,
        name: 'No Returns Fund',
        owner: null,
        frequency: null,
        startRange: null,
        endRange: null,
        unproxiedStartRange: null,
        unproxiedEndRange: null,
        analysisStart: null,
        analysisEnd: null,
        created: null,
        updated: 1543228957372,
        interpolated: false,
        fundSourceType: null,
        userUploaded: true,
        benchmarkID: null,
        legacyBenchmarkId: null,
        benchmarkName: null,
        investmentBenchmarks: [],
        active: true,
        live: true,
        symbol: null,
        currency: 'USD',
        domicile: null,
        exchange: null,
        exchangeCountry: null,
        assetType: 'UPLOADED_INSTRUMENT',
        accountNumber: null,
        periodAnnualized: null,
        periodReturn: null,
        annualizedTotalReturn: null,
        annualizedVolatility: null,
        annualizedSharpeRatio: null,
        maxDrawdown: null,
        annualizedExcessReturn: null,
        trackingError: null,
        factorLensId: 71,
        factorLensName: 'V4-USD',
        riskContribution: [],
        groupedRiskContribution: [],
        periodAlpha: null,
        annualizedAlpha: null,
        annualizedAlphaTStat: null,
        investmentType: null,
        investmentTypeName: null,
        investmentSource: null,
        investmentSourceName: null,
        investmentRegion: null,
        investmentRegionName: null,
        proxyId: null,
        proxyName: null,
        favorite: false,
        viewed: false,
        needsProxy: true,
        needsInterpolation: false,
      },
      name: 'No Returns Fund',
    },
  ],
};
