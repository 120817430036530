export type FootnoteSymbol = (typeof FootnoteSymbols)[keyof typeof FootnoteSymbols];

export type FootnoteType = keyof typeof FootnoteSymbols;

export const FootnoteSymbols = {
  excludedInvestments: '*',
  customMetrics: '†',
  vennModifiedData: '‡',
  pgaRank: '*',
  proxyUsage: '§',
} as const;
