import type { FC } from 'react';
import React, { useCallback, useContext, useState } from 'react';
import type { SortByOption, TrackAnalysisProps, FactorAnalysisGroup } from 'venn-components';
import {
  SortBy,
  FactorSummaryCharts,
  FactorTrendCharts,
  PrintContainerDimensions,
  AnalysisViewContext,
} from 'venn-components';
import { hasMultipleFunds, logMessageToSentry } from 'venn-utils';
import styled from 'styled-components';
import type { SharedProps } from '../types';

interface FactorAnalysisPropsWithRouter extends SharedProps {
  trackingProps: TrackAnalysisProps;
}

const FactorAnalysis: FC<React.PropsWithChildren<FactorAnalysisPropsWithRouter>> = ({
  analyses,
  factorLenses,
  analysisConfig,
  onResetTimeFrame,
  onFundUpdated,
  trackingProps,
  downloadMetaData,
}) => {
  const { relative, category, selectedPeriod, selectedTimeFrame } = analysisConfig;
  const { factorAnalysisSortBy: sortFactorsBy, onUpdateAnalysisViewParam } = useContext(AnalysisViewContext);
  const setSortFactorsBy = useCallback(
    (value: SortByOption) => onUpdateAnalysisViewParam({ factorAnalysisSortBy: value }),
    [onUpdateAnalysisViewParam],
  );

  const factorAnalysisResult = analyses?.results?.factorAnalysis;
  const { summary, trend } = factorAnalysisResult || {};
  const [trendData, setTrendData] = useState(trend);
  const factorAnalysisSubject = analyses?.subject;
  const activeFactorLens = factorLenses?.find((factorLens) => factorLens.primary);
  const defaultFactors = activeFactorLens?.factors || [];
  const defaultFactorsName = activeFactorLens?.name;
  const factorsDescriptions = [
    ...defaultFactors.map(({ id, description }) => ({
      id,
      description,
    })),
  ];
  const analysesPeriod = analyses?.analysesPeriod;
  const portfolioHasMultipleFunds = hasMultipleFunds(factorAnalysisSubject?.strategy);

  if (!summary?.risk || !trend?.risk || !factorAnalysisSubject) {
    logMessageToSentry('Failed to load factor analysis because of missing initialization');
    return null;
  }

  const updatePrintData = (newTrend: FactorAnalysisGroup) => {
    setTrendData(newTrend);
  };

  const sharedProps = {
    analysisSubject: factorAnalysisSubject,
    portfolioHasMultipleFunds,
    defaultFactorsName,
    factorsDescriptions,
    relative,
    categoryActive: category === 'ON',
    activeFactorLens,
    analysesPeriod,
    onFundUpdated,
    selectedPeriod,
    selectedTimeFrame,
    onResetTimeFrame,
    downloadMetaData,
  };

  return (
    <PrintContainerDimensions>
      {({ print, width }) => {
        if (print) {
          return (
            <PrintFactorCharts>
              {['EXPOSURE', 'RISK', 'RETURN'].map((row) => (
                <PrintFactorChartsRow key={row}>
                  <FactorSummaryCharts
                    block={`FACTOR_CONTRIBUTION_TO_${row}`}
                    analysisGroup={summary}
                    sortBy={sortFactorsBy}
                    width={width / 2 - 10}
                    {...sharedProps}
                    trackingProps={trackingProps}
                  />
                  <FactorTrendCharts
                    block={`FACTOR_CONTRIBUTION_TO_${row}_TREND`}
                    analysisGroup={trendData || {}}
                    width={width / 2 - 10}
                    {...sharedProps}
                    trackingProps={trackingProps}
                    isPrint
                  />
                </PrintFactorChartsRow>
              ))}
            </PrintFactorCharts>
          );
        }

        return (
          <>
            <Section>
              <FlexSpaceBetween>
                <SortConfig>
                  <SortBy
                    value={sortFactorsBy}
                    onChange={(option) => setSortFactorsBy(option.value)}
                    disable={!summary?.risk?.factorContributionToRisk}
                  />
                </SortConfig>
              </FlexSpaceBetween>
            </Section>
            <Section className="qa-factor-charts">
              <FactorSummaryCharts
                analysisGroup={summary}
                sortBy={sortFactorsBy}
                width={width}
                {...sharedProps}
                trackingProps={trackingProps}
              />
            </Section>
            <Section className="qa-trend-charts">
              <FactorTrendCharts
                analysisGroup={trendData || {}}
                width={width}
                onPrintUpdate={updatePrintData}
                {...sharedProps}
                trackingProps={trackingProps}
              />
            </Section>
          </>
        );
      }}
    </PrintContainerDimensions>
  );
};

export default FactorAnalysis;

const SortConfig = styled.div`
  width: 200px;
`;

const Section = styled.section`
  padding-top: 20px;
`;

const FlexSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const PrintFactorCharts = styled.div`
  display: block;
  width: 100%;
`;

const PrintFactorChartsRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-right: 2px;
  margin-top: 20px;
  @media print {
    page-break-inside: avoid;
  }
`;
