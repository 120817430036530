import type { OperationResult } from 'venn-api';
import { useCallback, useEffect, useState } from 'react';
import useApi from '../useApi/useApi';
import useIsMounted from '../useIsMounted/useIsMounted';
import type { FetchApiResult } from './types';

/**
 * Same as {@link useFetchApi} but apiFunction is called only when condition is true.
 *
 * @param condition The first time this is true, apiFunction is called.
 * @param apiFunction The api function to call.
 * @param args The arguments to the api function.
 */
const useFetchApiConditionally = <T extends unknown[], U>(
  condition: boolean,
  apiFunction: (...args: T) => Promise<OperationResult<U>>,
  ...args: T
): FetchApiResult<U> => {
  const [loading, setLoading] = useState<boolean>(condition);
  const [error, setError] = useState<Error>();
  const [result, setResult] = useState<U>();
  const apiFn = useApi(apiFunction);
  const isMountedRef = useIsMounted();

  const refresh = useCallback(async () => {
    setLoading(true);
    setError(undefined);
    try {
      const response = await apiFn(...args);
      setResult(response.content);
      setLoading(false);
    } catch (e) {
      if (e.name !== 'AbortError' && isMountedRef.current) {
        setResult(undefined);
        setLoading(false);
        setError(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLoading, setResult, apiFn, isMountedRef, ...args]);

  useEffect(() => {
    if (condition) {
      refresh();
    }
  }, [condition, refresh]);

  return {
    loading,
    result,
    error,
    refresh,
  };
};

export default useFetchApiConditionally;
