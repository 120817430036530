import React from 'react';
import { Icon, Link as LinkStyle } from 'venn-ui-kit';
import styled from 'styled-components';

const LearnMore = ({ href }: { href: string }) => (
  <LinkStyle>
    <StyledLink target="_blank" rel="noopener noreferrer" href={href}>
      Learn More
    </StyledLink>
    <Icon type="external-link-alt" />
  </LinkStyle>
);

export default LearnMore;

export const StyledLink = styled.a`
  padding-right: 5px;
`;
