import type { ReactNode } from 'react';
import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import Spinner from './Spinner';
import { GetColor } from '../../style/color';

export enum LoadingSize {
  nano = 'nano',
  micro = 'micro',
  small = 'small',
  medium = 'medium',
  large = 'large',
  xlarge = 'xlarge',
}

export interface LoadingProps {
  size?: LoadingSize;
  title?: string;
  className?: string;
  pageLoader?: boolean;
  children?: ReactNode;
}

export const LoadingContainer = styled.div<LoadingProps>`
  padding: ${(props) => (props.pageLoader ? '100px' : 'initial')};

  margin: 0 auto;
  text-align: center;
  display: ${(props) => (props.size === LoadingSize.nano || props.size === LoadingSize.micro ? 'none' : 'block')};
`;

const Label = styled.div`
  color: ${GetColor.UNSAFE.Steel};
  text-align: center;
  text-transform: uppercase;
`;

const Loading: React.FC<React.PropsWithChildren<LoadingProps>> = ({
  className,
  size = LoadingSize.medium,
  title = 'Loading',
  pageLoader = false,
}: LoadingProps) => (
  <LoadingContainer pageLoader={pageLoader} className={classNames('qa-preloader' /* TODO! */, className)}>
    <Spinner size={size} />
    {title && <Label>{title}</Label>}
  </LoadingContainer>
);

export default Loading;
