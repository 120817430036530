import React from 'react';
import styled from 'styled-components';
import { Icon, GetColor } from 'venn-ui-kit';

export interface EmptyBenchmarksListViewProps {
  onAddNew: () => void;
}

const EmptyBenchmarksListView = ({ onAddNew }: EmptyBenchmarksListViewProps) => (
  <Container data-testid="qa-empty-benchmarks-container">
    <Icon type="exclamation" />
    <h2>No benchmarks configured.</h2>
    <button onClick={onAddNew} type="button" className="qa-add-new-benchmark">
      Add new
    </button>
  </Container>
);

export default EmptyBenchmarksListView;

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  i {
    font-size: 60px;
    line-height: 80px;
    width: 100px;
    height: 100px;
    text-align: center;
    border: 10px solid ${GetColor.PaleGrey};
    border-radius: 50%;
    color: ${GetColor.PaleGrey};
  }

  h2 {
    margin-top: 20px;
    font-size: 16px;
    font-weight: bold;
  }

  a {
    font-weight: bold;
  }
`;
