import { useLocation } from 'react-router-dom';
import type { UrlParam } from 'venn-utils';

/**
 * Returns a Boolean indicating if such a search parameter exists.
 *
 * This hook is reactive to location/parameter changes using react router.
 */
export const useHasUrlParam = (param: UrlParam) => {
  const location = useLocation();
  return new URLSearchParams(location.search).has(param);
};
