import React from 'react';
import type { ButtonTriggerProps } from 'venn-ui-kit';
import { ButtonTrigger } from 'venn-ui-kit';
import { AbsoluteErrorMessage } from 'venn-components';
import classNames from 'classnames';

interface FilterMenuTriggerProps extends ButtonTriggerProps {
  /** Whether there is an error or not */
  error?: boolean;
  /** Message to show when there is an error */
  errorMessage?: JSX.Element;
}

/**
 * The trigger / button to click to open up a library filter menu
 */
const FilterMenuTrigger = ({ error, errorMessage, className, ...buttonTriggerProps }: FilterMenuTriggerProps) => {
  return (
    <>
      <ButtonTrigger className={classNames('qa-library-filter-trigger', className)} {...buttonTriggerProps} />
      {error && errorMessage && <AbsoluteErrorMessage className="qa-error">{errorMessage}</AbsoluteErrorMessage>}
    </>
  );
};

export default FilterMenuTrigger;
