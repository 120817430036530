import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';

export const ErrorText = styled.span`
  color: ${GetColor.Error};
  padding: 2px 0;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  height: 14px;
`;

export const WarningContainer = styled.div`
  display: flex;
  color: ${GetColor.White};
  padding: 16px;
  align-items: center;
  gap: 6px;
  align-self: stretch;
  border-radius: 2px;
  margin-left: 24px;
  margin-top: 8px;
  background: ${GetColor.HighlightDark};
`;

export const SettingNameInput = styled.input`
  width: 100%;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const MainEditorContainer = styled.div`
  font-size: 12px;
  margin-left: 24px;
  border-radius: 2px 2px 0 0;
  background: ${GetColor.GreyScale.Grey10};
  display: flex;
  padding: 10px 20px;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
  align-self: stretch;
  flex-grow: 1;
`;

export const SettingNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
