import type { CSSProperties } from 'react';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { blockInfoGraphicType, blockLoadingState, isReportState } from 'venn-state';
import { Routes, useDebugValue, useHasFF } from 'venn-utils';

import vb2sWhite from './vb2s-white-background.svg';
import { merge } from 'lodash';
import { useBlockId } from '../studio-blocks/contexts/BlockIdContext';
import { useLocation } from 'react-router-dom';
import type { ComparisonBlockType } from 'src/comparison-page/analysisBlocks';

export const AnalysisBlockWatermark = () => {
  const location = useLocation();

  // The analysis block container may appear on many pages including Studio, but this particular component applies only to Analysis and factor lens.
  const isAnalysis = location.pathname.startsWith(Routes.ANALYSIS_PATH);
  const isFactorLens = location.pathname.startsWith(Routes.FACTOR_LENS_HOMEPAGE);
  if (!isAnalysis && !isFactorLens) return null;

  return <BaseBlockWatermark watermarkStyle="normal" />;
};

/** Watermark for the regular non-pivoted blocks that appear on Compare page, versus {@link ComparePivotedWatermark} used for pivot mode. */
export const CompareRegularBlockWatermark = ({ type }: { type: ComparisonBlockType }) => {
  return <BaseBlockWatermark watermarkStyle={type === 'PAIRWISE_CORRELATION' ? 'compareHeatmap' : 'compareNormal'} />;
};

/** Watermark for the super-wide pivoted block that appears in Compare page. */
export const ComparePivotedWatermark = () => {
  return <BaseBlockWatermark watermarkStyle="normal" />;
};

/** Renders a block watermark over the block content. Must be added on top of content (later in the DOM) to overlay properly. */
export const StudioBlockWatermark = React.memo(function StudioBlockWatermark() {
  const blockId = useBlockId();
  const infoGraphicType = useRecoilValue(blockInfoGraphicType(blockId));
  const isReport = useRecoilValue(isReportState);
  const isLoading = useRecoilValue(blockLoadingState(blockId));

  // TODO(collin.irwin): detect error state and don't render the watermark for error states.

  if (isReport) return null;
  if (isLoading) return null;

  const watermarkStyle = (() => {
    if (infoGraphicType === 'HEATMAP') return 'heatmap';
    if (infoGraphicType === 'MAP') return 'geo';
    return 'normal';
  })();

  return <BaseBlockWatermark watermarkStyle={watermarkStyle} />;
});

type WatermarkStyle = keyof typeof watermarkStyleCss;

const standardWatermarkStyle: CSSProperties = {
  backgroundImage: `url(${vb2sWhite})`,
  backgroundRepeat: 'no-repeat',
  opacity: 0.11,
  backgroundSize: 'auto 112px',
};

const watermarkStyleCss = {
  compareNormal: {
    ...standardWatermarkStyle,
    backgroundPosition: '50% max(90px, 43%)',
  },
  normal: {
    ...standardWatermarkStyle,
    backgroundPosition: '50% 43%',
  },
  geo: {
    ...standardWatermarkStyle,
    backgroundPosition: '50% calc(100% - 24px)',
  },
  compareHeatmap: {
    ...standardWatermarkStyle,
    backgroundSize: 'auto 50px',
    backgroundPosition: '50% 90px',
  },
  heatmap: {
    ...standardWatermarkStyle,
    backgroundSize: 'auto 50px',
    backgroundPosition: '50% 8px',
  },
} as const satisfies Record<string, CSSProperties>;

export const vennBlockWatermarkClass = 'venn-block-watermark';

const BaseBlockWatermark = ({ watermarkStyle }: { watermarkStyle: WatermarkStyle }) => {
  const hasVennBrandedWatermark = useHasFF('venn_branded_watermark_ff');
  const debugWatermarkStyle = useExperimentalWatermarkStyle();
  const hasReportLab = useHasFF('studio_report_editor');
  const watermarkCss = watermarkStyleCss[watermarkStyle];

  if (debugWatermarkStyle) {
    return (
      <ContentWatermark className={vennBlockWatermarkClass} style={merge({}, watermarkCss, debugWatermarkStyle)} />
    );
  }

  if (!hasVennBrandedWatermark) return null;
  if (hasReportLab) return null;

  return <ContentWatermark className={vennBlockWatermarkClass} style={watermarkCss} />;
};

const ContentWatermark = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

// TODO(collin.irwin, VENN-24627): delete when watermark design is finalized.
function useExperimentalWatermarkStyle(): React.CSSProperties | undefined {
  const debugWatermarkStyle = useDebugValue('debugWatermarkStyle');
  const debugWatermarkPadding = useDebugValue('debugWatermarkPadding');
  const [svgString, setSvgString] = useState('');

  useEffect(() => {
    if (debugWatermarkStyle?.backgroundImage) {
      loadSvgUrlToString(debugWatermarkStyle.backgroundImage).then(setSvgString);
    } else {
      setSvgString('');
    }
  }, [debugWatermarkStyle?.backgroundImage]);

  if (!debugWatermarkStyle || !svgString) {
    return undefined;
  }

  const dimensions = getSvgDimensions(svgString);
  const finalDimensions = {
    height: Number(dimensions.height) + (debugWatermarkPadding ?? 0),
    width: Number(dimensions.width) + (debugWatermarkPadding ?? 0),
  };
  const widthRatio = finalDimensions.width / Number(dimensions.width);
  /**  Wrapper that is intentionally larger than the actual image to allow for padding */
  const wrapperSvg = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" height="${finalDimensions.height}" width="${finalDimensions.width}">${svgString}</svg>`;

  const backgroundImage = `url('${encodeSvgToDataUrl(wrapperSvg)}')`;

  const backgroundSize = debugWatermarkStyle.backgroundSize
    ? `auto ${Number(debugWatermarkStyle.backgroundSize) * widthRatio}px`
    : undefined;

  return { ...debugWatermarkStyle, backgroundImage, backgroundSize };
}

async function loadSvgUrlToString(url: string) {
  const response = await fetch(url);
  const svg = await response.text();
  return svg;
}

function encodeSvgToDataUrl(svg: string) {
  const encodedSvg = encodeURIComponent(svg);
  const dataUrl = `data:image/svg+xml;utf8,${encodedSvg}`;
  return dataUrl;
}

function getSvgDimensions(svg: string) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(svg, 'image/svg+xml');
  const svgElement = doc.getElementsByTagName('svg')[0];
  const width = svgElement.getAttribute('width');
  const height = svgElement.getAttribute('height');
  return { width, height };
}
