import { compact } from 'lodash';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import type { CustomSelectProps, SearchMenuItem } from 'venn-components';
import type { SelectTypes } from 'venn-ui-kit';
import { Flexbox, GetColor, Icon, INTERCOM_HELP_ALL_HREF } from 'venn-ui-kit';
import type { QuickFilter } from 'venn-utils';
import { getLibraryPath, LibraryItemType, LibraryTab, Routes, SavedViewsQuickFilter, useHasFF } from 'venn-utils';
import { getPrivateAssetSearchMode } from '../utils';
import { FiltersContext } from './FiltersProvider';
import { isHelpArticles, isSavedViews, viewFilters } from './QuickFilterBar';

type LinkProps = {
  label: string;
  isEnabled: boolean;
  to: string;
  onClick: () => void;
};

const DataLibraryLinks = ({
  publicReturnsDataLink,
  privateAssetDataLink,
}: {
  publicReturnsDataLink: LinkProps;
  privateAssetDataLink: LinkProps;
}) => {
  const renderLink = ({ label, isEnabled, to, onClick }: LinkProps) => {
    return (
      <LibraryLink data-testid="search-in-library" onClick={onClick} to={isEnabled ? to : ''} $isDisabled={!isEnabled}>
        {label} <Icon type="chevron-right" />
      </LibraryLink>
    );
  };

  return (
    <Flexbox direction="row" gap={20}>
      <div>
        <SearchIcon type="search" />
        <Label>See all matching items in:</Label>
      </div>
      {renderLink(publicReturnsDataLink)}
      {renderLink(privateAssetDataLink)}
    </Flexbox>
  );
};

const LibraryLinkFooter = ({ selectProps, options }: SelectTypes.MenuListComponentProps<SearchMenuItem>) => {
  const { selectedFilters } = useContext(FiltersContext);
  const query = selectProps.inputValue;
  const { closeSearchMenu } = selectProps as CustomSelectProps;
  const noOptions = options.length === 0;
  const hasPrivatesFF = useHasFF('private_analytics');
  const privateAssetSearchMode = getPrivateAssetSearchMode(
    compact(selectedFilters.map((filter) => filter.privateAssetSearchMode)),
    'ALL',
  );

  if (!query && !selectedFilters.length) {
    return null;
  }
  // VENN-20517 tags will not work in the library, so disabling the link
  const hasTags = selectedFilters.find((item) => item.itemType === LibraryItemType.TAGS);
  if (hasTags) {
    return null;
  }

  const hasCompositeBenchmarks = selectedFilters.find((item) => item.itemType === LibraryItemType.COMPOSITE_BENCHMARK);

  const hasView = isSavedViews(selectedFilters);
  const hasArticles = isHelpArticles(selectedFilters);

  let selectedItemTypes = [];
  let itemType = LibraryItemType.ALL;
  let selectedQuickFilters: QuickFilter[] = [];
  let textSearch;
  let linkText = hasArticles ? 'Go to help center' : hasView ? 'See all saved views' : 'See all items in Library';

  if (!noOptions) {
    selectedItemTypes = compact(selectedFilters.map((filter) => filter.itemType));
    itemType = selectedItemTypes.length === 1 ? selectedItemTypes[0] : LibraryItemType.ALL;
    selectedQuickFilters = compact(selectedFilters.map((filter) => filter.quickFilter));
    textSearch = query;
    linkText = hasArticles
      ? 'See all matching articles'
      : hasView
        ? 'See all matching views'
        : 'See all matching items in Library';
  }

  const link = hasView
    ? getSavedViewsUrl(
        query ?? '',
        compact(
          selectedFilters.map((s) => {
            if (s === viewFilters.SAVED_BY_ME) {
              return SavedViewsQuickFilter.SAVED_BY_ME;
            }
            if (s === viewFilters.VENN_PROVIDED) {
              return SavedViewsQuickFilter.VENN_PROVIDED;
            }
            return undefined;
          }),
        ),
      )
    : getLibraryPath({
        selectedFilters: {
          quickFilters: selectedQuickFilters,
          itemType,
          tags: [],
        },
        name: textSearch,
        sortByUpdated: false,
      });

  const LinkComponent = (hasArticles ? ExternaLink : LibraryLink) as React.ElementType;
  const linkProps = hasArticles
    ? { href: `${INTERCOM_HELP_ALL_HREF}?q=${query ?? ''}`, target: '_blank', rel: 'noopener noreferrer' }
    : { to: link };

  const shouldDisplayTwoSeparateLibraryLinks = hasPrivatesFF && !hasArticles && !hasView;

  return (
    <Wrapper>
      <div>
        {shouldDisplayTwoSeparateLibraryLinks ? (
          <DataLibraryLinks
            publicReturnsDataLink={{
              label: 'Returns Data Library',
              isEnabled: ['PUBLIC_ONLY', 'ALL'].includes(privateAssetSearchMode),
              to: getLibraryPath({
                tab: LibraryTab.ReturnsData,
                selectedFilters: {
                  quickFilters: selectedQuickFilters,
                  itemType,
                  tags: [],
                },
                name: textSearch,
                sortByUpdated: false,
              }),
              onClick: closeSearchMenu,
            }}
            privateAssetDataLink={{
              label: 'Private Asset Data Library',
              isEnabled: ['PRIVATES_ONLY', 'ALL'].includes(privateAssetSearchMode) && !hasCompositeBenchmarks,
              to: getLibraryPath({
                tab: LibraryTab.PrivateAssets,
                selectedFilters: {
                  quickFilters: selectedQuickFilters,
                  itemType,
                  tags: [],
                },
                name: textSearch,
                sortByUpdated: false,
              }),
              onClick: closeSearchMenu,
            }}
          />
        ) : (
          <>
            <SearchIcon type="search" />
            <LinkComponent data-testid="search-in-library" onClick={closeSearchMenu} {...linkProps}>
              {linkText} <Icon type="chevron-right" />
            </LinkComponent>
          </>
        )}
      </div>
      <Help>
        Press <Icon prefix="fal" type="arrow-square-up" /> and <Icon prefix="fal" type="arrow-square-down" /> to move
        between items in the list
      </Help>
    </Wrapper>
  );
};

export default LibraryLinkFooter;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${GetColor.LightGrey};
  height: 20px;
  font-size: 14px;
  margin-top: 10px;
  padding-top: 20px;
`;

const linkStyle = css`
  color: ${GetColor.Primary.Dark};
  font-weight: bold;

  :hover {
    color: ${GetColor.Primary.Main};
  }
`;

const Label = styled.span`
  font-weight: bold;
`;

const LibraryLink = styled(Link)<{ $isDisabled: boolean }>`
  ${linkStyle}

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      cursor: default;
      pointer-events: none;
      opacity: 0.4;
    `}
`;

const ExternaLink = styled.a`
  ${linkStyle}
`;

const Help = styled.div`
  color: ${GetColor.MidGrey1};
`;

const SearchIcon = styled(Icon)`
  color: ${GetColor.HintGrey};
  margin-right: 5px;
`;

const getSavedViewsUrl = (query: string, filters: SavedViewsQuickFilter[]) =>
  `${Routes.REPORTS}?name=${query}${filters.map((filter) => `&filters=${filter}`)}`;
