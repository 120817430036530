import React from 'react';
import { Radio } from '../radio/Radio';
import { isEqual } from 'lodash';

interface LabelledType<T> {
  label: string;
  value: T;
}

export interface RadioGroupProps<T> {
  /** Used to group radios so that only one can be selected at a time. Must be unique. */
  groupName: string;

  /** onChange event handler. Uses only the value associated with radio (without label) */
  onChange: (value: T) => void;

  /** List of options presented. These must contain a value of any type and a string label */
  options: LabelledType<T>[];

  /** The value of the option to be checked on initial render. Deep equality check is performed. */
  defaultOption?: T;
}

export const RadioGroup = <T,>({ groupName, onChange, options, defaultOption }: RadioGroupProps<T>) => (
  <>
    {options.map(({ value, label }, index) => (
      <Radio
        key={`radio-group-${value}-index`}
        name={groupName}
        defaultChecked={defaultOption ? isEqual(value, defaultOption) : index === 0}
        value={label}
        onChange={() => onChange(value)}
        inputId={`${groupName}-${index}`}
      >
        {label}
      </Radio>
    ))}
  </>
);
