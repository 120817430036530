import {
  type CellClassParams,
  type ColDef,
  GroupCellRenderer,
  type ICellRendererParams,
  type RowClassRules,
  type ValueGetterParams,
} from 'ag-grid-community';
import type { Fund, Portfolio } from 'venn-api';
import {
  ALLOCATION_CELL_RENDERER,
  MAPPED_PORTFOLIO_NODE_CELL_RENDERER,
} from '../../../studio-blocks/customAnalysisContants';
import { HeaderRendererWithLink } from '../../../studio-blocks/components/grid/renderers/HeaderRendererWithLink';
import { isNil, noop } from 'lodash';
import type { PortfolioAccessMode, RowData } from '../types';
import { useMemo } from 'react';
import { getAllFundIds, nodeNeedsMapping } from './review/helpers';
import { NameWithMappingErrorRenderer } from './mapping/NameWithMappingErrorRenderer';
import { GetColor, MULTI_PORTFOLIO_UPLOADER_FAQ_HREF } from 'venn-ui-kit';

const useUploadPortfolioAllocatorColumnDefs = (
  portfolio: Portfolio,
  onApplyMapping: (path: number[], fund: Fund) => void,
): ColDef<RowData<Portfolio>>[] => {
  const allFundIdsInPortfolio = getAllFundIds(portfolio);
  const columns: ColDef<RowData<Portfolio>>[] = [
    {
      headerName: 'Mapped?',
      sortable: false,
      cellRenderer: MAPPED_PORTFOLIO_NODE_CELL_RENDERER,
      cellRendererParams: (params: ICellRendererParams) => {
        const { isFund, needsMapping } = nodeNeedsMapping(params.data.node);
        return {
          isFund,
          needsMapping,
          onApplyMapping,
          allFundIdsInPortfolio,
        };
      },
      colId: 'mapped',
      flex: 1,
      cellClass: 'overflow-visible',
    },
    {
      headerName: 'Allocation',
      headerClass: ['ag-right-aligned-header'],
      cellRenderer: ALLOCATION_CELL_RENDERER,
      sortable: false,
      cellRendererParams: (params: ICellRendererParams) => {
        const { allocation, fund } = params.data.node;
        return {
          value: allocation,
          isRoot: params.node.level === 0,
          originalValue: allocation,
          isStrategy: isNil(fund),
          onUpdateAllocation: noop,
        };
      },
      colId: 'allocation',
      flex: 1,
      minWidth: 130,
    },
  ];

  return columns;
};

export const getRowData = (node: Portfolio): RowData<Portfolio>[] => {
  return getRowDataImpl(node, node, 0);
};

const getRowDataImpl = (node: Portfolio, root: Portfolio, nodeId: number): RowData<Portfolio>[] => {
  if (node.fund) {
    return [
      {
        path: [nodeId],
        node,
        root,
      },
    ];
  }
  return [
    {
      path: [nodeId],
      node,
      root,
    },
    ...node.children.flatMap((child, childIndex) => {
      const childData = getRowDataImpl(child, root, childIndex);
      return childData.map((row) => {
        return {
          path: [nodeId, ...row.path],
          node: row.node,
          root,
        };
      });
    }),
  ];
};

const portfolioAllocatorStylingProps = {
  root: {
    fontWeight: 700,
    fontSize: 16,
  },
  strategy: {
    fontWeight: 700,
    fontSize: 14,
  },
  investment: {
    fontWeight: 400,
    fontSize: 14,
  },
};

const OVERWRITE_TOOLTIP_MSG =
  'Replaces all investments, strategies, strategy hierarchy, and allocations from the existing portfolio in the Data Library with the information below.';
const CREATE_NEW_TOOLTIP_MSG =
  'Creates a new portfolio with all investments, strategies, strategy hierarchy, and allocations in the Data Library with the information below.';

const useAutoGroupColumnDefs = (accessMode: PortfolioAccessMode) => {
  return {
    flex: 5,
    minWidth: 200,
    wrapText: true,
    autoHeight: true,
    headerComponent: HeaderRendererWithLink,
    headerComponentParams: {
      displayName: accessMode === 'OVERWRITE_EXISTING' ? 'Overwrite Entire Portfolio' : 'Create New Portfolio',
      linkTo: MULTI_PORTFOLIO_UPLOADER_FAQ_HREF,
      tooltip: accessMode === 'OVERWRITE_EXISTING' ? OVERWRITE_TOOLTIP_MSG : CREATE_NEW_TOOLTIP_MSG,
      iconColor: GetColor.Black,
    },
    sortable: false,
    field: 'path',
    valueGetter: (params: ValueGetterParams<RowData<Portfolio>>) => params.data?.node.name,
    cellStyle: (params: CellClassParams<RowData<Portfolio>>) => {
      const { level, data } = params.node;
      const baseStyle =
        level === 0
          ? portfolioAllocatorStylingProps.root
          : isNil(data?.node?.fund)
            ? portfolioAllocatorStylingProps.strategy
            : portfolioAllocatorStylingProps.investment;

      return {
        ...baseStyle,
        paddingLeft: 0,
      };
    },
    colId: 'name',
    cellRenderer: GroupCellRenderer,
    cellRendererParams: (params: ICellRendererParams<RowData<Portfolio>>) => {
      const portfolio = params?.data?.node;
      return {
        suppressCount: true,
        suppressDoubleClickExpand: true,
        innerRenderer: NameWithMappingErrorRenderer,
        innerRendererParams: {
          name: portfolio?.name,
          needsMapping: nodeNeedsMapping(portfolio).needsMapping,
        },
      };
    },
  };
};
export const useUploadPortfolioAllocator = (
  portfolio: Portfolio,
  accessMode: PortfolioAccessMode,
  applyInvestmentRemap: (path: number[], fund: Fund) => void,
) => {
  const columnDefs = useUploadPortfolioAllocatorColumnDefs(portfolio, applyInvestmentRemap);

  const rowData = getRowData(portfolio);

  const autoGroupColumnDefs = useAutoGroupColumnDefs(accessMode);

  const rowClassRules = useMemo<RowClassRules>(() => {
    return {
      // row style function creating a CSS class to style the background of the row
      'no-mapped-fund-row': (params) => {
        const { needsMapping } = nodeNeedsMapping(params.data.node);
        return needsMapping;
      },
    };
  }, []);

  return {
    rowData,
    columnDefs,
    autoGroupColumnDefs,
    rowClassRules,
  };
};
