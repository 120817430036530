import type { ColDef, ColGroupDef, ICellRendererParams, RowNode, ValueFormatterParams } from 'ag-grid-community';
import React, { useContext } from 'react';
import {
  ClassificationCellRenderer,
  HeaderRendererWithLink,
  numericValueFormatter,
  percentageValueFormatter,
  UserContext,
  vintageFormatter,
} from 'venn-components';
import { Dates, useHasFF } from 'venn-utils';
import { ButtonIcon, PRIVATE_ASSET_CASH_FLOW_HELP_HREF } from 'venn-ui-kit';
import type { CashFlowSetting } from 'venn-api';
import { compact, isNil } from 'lodash';
import { useTheme } from 'styled-components';

export type CashFlowSettingRow = {
  fundName: string;
  fundId: string;
  setting: CashFlowSetting;
};

export const PRIVATES_CASHFLOW_PACING_PARAMETERS_HEADER_GROUP_DIV_GREEN_4 =
  'privates-cashflow-pacing-parameters-div-green-4';
export const PRIVATES_CASHFLOW_PACING_PARAMETERS_HEADER_GROUP_DIV_GREEN_5 =
  'privates-cashflow-pacing-parameters-div-green-5';

const classificationComparator = (
  valueA: string | number | null | undefined,
  valueB: string | number | null | undefined,
  nodeA: RowNode<CashFlowSettingRow>,
  nodeB: RowNode<CashFlowSettingRow>,
) => {
  if (!isNil(valueA) && !isNil(valueB)) {
    // Both are non-null, use default comparison
    return valueA === valueB ? 0 : valueA < valueB ? -1 : 1;
  }

  if (isNil(valueA) && isNil(valueB)) {
    // Both are null. Make sure classification overrides come before parameter override for consistency
    const overrideTypeA = nodeA.data?.setting.overrideType;
    const overrideTypeB = nodeB.data?.setting.overrideType;

    return overrideTypeA === overrideTypeB ? 0 : overrideTypeA === 'PARAMETER' ? 1 : -1;
  }

  // One is null, other is not. Non-nulls should come first
  return isNil(valueA) ? 1 : -1;
};

export const usePrivatesCashflowPacingSettingsColumns = (
  onEditButtonClick: (fundId: string | undefined, fundName: string | undefined) => void,
): (ColDef<CashFlowSettingRow> | ColGroupDef<CashFlowSettingRow>)[] => {
  const { hasPermission } = useContext(UserContext);
  const hasEditHyperparametersFF = useHasFF('privates_hyperparameters_editing_ff');
  const hasEditHyperparametersPermission = hasPermission('EDIT_HYPERPARAMETERS');

  const { Colors } = useTheme();

  return compact([
    {
      headerName: 'Setting Information',
      headerGroupComponent: HeaderRendererWithLink,
      headerGroupComponentParams: {
        displayName: 'Setting Information',
        linkTo: PRIVATE_ASSET_CASH_FLOW_HELP_HREF,
        tooltip: 'Click to learn more',
      },
      children: [
        {
          headerName: 'Fund Name',
          field: 'fundName',
          minWidth: 300,
          flex: 1,
          pinned: 'left',
        },
        {
          headerName: 'Setting Name',
          field: 'setting.name',
          pinned: 'left',
          minWidth: 150,
          maxWidth: 150,
        },
        {
          headerName: 'Last Updated',
          headerClass: 'ag-right-aligned-header',
          minWidth: 122,
          maxWidth: 122,
          cellStyle: {
            paddingRight: 6,
            textAlign: 'right',
          },
          field: 'setting.updated',
          pinned: 'left',
          valueFormatter: ({ value }) => {
            return value ? Dates.toDayMonthYear(value) : '—';
          },
        },
      ],
    },
    {
      headerName: 'Classification',
      headerClass: [PRIVATES_CASHFLOW_PACING_PARAMETERS_HEADER_GROUP_DIV_GREEN_4],
      children: [
        {
          headerName: 'Asset Class',
          field: 'setting.assetClassName',
          minWidth: 170,
          flex: 1,
          comparator: classificationComparator,
          cellRenderer: ({ value, data }: ICellRendererParams<CashFlowSettingRow>) => {
            return (
              <ClassificationCellRenderer
                value={value}
                placeholder="All Asset Classes"
                overrideType={data?.setting.overrideType}
              />
            );
          },
        },
        {
          headerName: 'Strategy',
          field: 'setting.strategyName',
          minWidth: 170,
          flex: 1,
          comparator: classificationComparator,
          cellRenderer: ({ value, data }: ICellRendererParams<CashFlowSettingRow>) => {
            return (
              <ClassificationCellRenderer
                value={value}
                placeholder="All Strategies"
                overrideType={data?.setting.overrideType}
              />
            );
          },
        },
        {
          headerName: 'Vintage',
          headerClass: 'ag-right-aligned-header',
          minWidth: 104,
          flex: 1,
          cellStyle: {
            textAlign: 'right',
          },
          field: 'setting.vintage',
          comparator: classificationComparator,
          valueFormatter: ({ value }: ValueFormatterParams) => {
            return vintageFormatter(value);
          },
          cellRenderer: ({ valueFormatted, data }: ICellRendererParams<CashFlowSettingRow>) => {
            return (
              <ClassificationCellRenderer
                value={valueFormatted}
                placeholder="All Vintages"
                overrideType={data?.setting.overrideType}
              />
            );
          },
        },
      ],
    },
    {
      headerName: 'Parameters',
      headerClass: [PRIVATES_CASHFLOW_PACING_PARAMETERS_HEADER_GROUP_DIV_GREEN_5],
      headerGroupComponent: HeaderRendererWithLink,
      headerGroupComponentParams: {
        displayName: 'Parameters',
        linkTo: PRIVATE_ASSET_CASH_FLOW_HELP_HREF,
        tooltip: 'Parameter values are denoted on a quarterly basis.',
        iconColor: Colors.White,
        color: Colors.White,
      },
      children: [
        {
          headerName: 'Rate of Contribution (0-4 qtrs.)',
          headerClass: 'ag-right-aligned-header',
          minWidth: 116,
          maxWidth: 116,
          cellStyle: {
            textAlign: 'right',
          },
          field: 'setting.rateOfContribution0',
          valueFormatter: percentageValueFormatter,
        },

        {
          headerName: 'Rate of Contribution (4-8 qtrs.)',
          headerClass: 'ag-right-aligned-header',
          minWidth: 116,
          maxWidth: 116,
          cellStyle: {
            textAlign: 'right',
          },
          field: 'setting.rateOfContribution4',
          valueFormatter: percentageValueFormatter,
        },
        {
          headerName: 'Rate of Contribution (>8 qtrs.)',
          headerClass: 'ag-right-aligned-header',
          minWidth: 116,
          maxWidth: 116,
          cellStyle: {
            textAlign: 'right',
          },
          field: 'setting.rateOfContribution8',
          valueFormatter: percentageValueFormatter,
        },
        {
          headerName: 'Life Expectancy (qtr.)',
          headerClass: 'ag-right-aligned-header',
          minWidth: 110,
          maxWidth: 110,
          cellStyle: {
            textAlign: 'right',
          },
          field: 'setting.lifeExpectancy',
        },
        {
          headerName: 'Bow',
          headerClass: 'ag-right-aligned-header',
          minWidth: 62,
          maxWidth: 62,
          cellStyle: {
            textAlign: 'right',
          },
          field: 'setting.bow',
          valueFormatter: numericValueFormatter,
        },
        {
          headerName: 'Yield',
          headerClass: 'ag-right-aligned-header',
          minWidth: 72,
          maxWidth: 72,
          cellStyle: {
            textAlign: 'right',
          },
          field: 'setting.yield',
          valueFormatter: percentageValueFormatter,
        },
        {
          headerName: 'Growth',
          headerClass: 'ag-right-aligned-header',
          minWidth: 82,
          maxWidth: 82,
          cellStyle: {
            textAlign: 'right',
          },
          field: 'setting.growth',
          valueFormatter: percentageValueFormatter,
        },
      ],
    },
    hasEditHyperparametersFF && hasEditHyperparametersPermission
      ? {
          headerName: '',
          children: [
            {
              headerName: '',
              minWidth: 78,
              maxWidth: 78,
              pinned: 'right',
              cellRenderer: ({ data }: ICellRendererParams<CashFlowSettingRow>) => {
                return (
                  <ButtonIcon
                    text="EDIT"
                    iconType="edit"
                    testId="open-cashflow-settings-modal-button"
                    iconFontSize={14}
                    textFontSize={14}
                    size={30}
                    border
                    onClick={() => onEditButtonClick(data?.fundId, data?.fundName)}
                  />
                );
              },
            },
          ],
        }
      : null,
  ]);
};
