import styled, { css, keyframes } from 'styled-components';
import { ColorUtils, GetColor } from 'venn-ui-kit';

export interface StylingProps {
  hasValue: boolean;
  hasError: boolean;
  focused?: boolean;
  disabled?: boolean;
  hasLabel?: boolean;
}

export const leftRightPadding = 19;
export const FormFieldBackground = ColorUtils.hex2rgbaFrom(GetColor.Black, 0.65);
export const focusColor = GetColor.Primary.Main;
export const hoverColor = GetColor.DEPRECATED_DivergingColor.B2;
export const errorColor = GetColor.Alert;
const disabledColor = GetColor.MidGrey2;

export const RequiredSymbol = styled.span`
  color: ${GetColor.Primary.Main};
  margin-left: 2px;
`;

export const Container = styled.div`
  display: inline-block;
  width: 100%;
  position: relative;
  margin-bottom: 10px;
`;

const higherLabel = css`
  transform: translateY(-9px);
`;

export const getLabelStyles = ({ hasValue, hasError, disabled, focused }: StylingProps) => css`
  pointer-events: none;
  position: absolute;
  top: 14px;
  font-size: 12px;
  left: ${leftRightPadding + 1}px; // + 1 for the border
  color: ${disabled
    ? disabledColor
    : hasError
      ? errorColor
      : focused
        ? focusColor
        : hasValue
          ? GetColor.MidGrey2
          : GetColor.White};
  transform-origin: 0;
  transition: all 0.15s ease;
  font-weight: bold;
  line-height: normal;
  ${hasValue && higherLabel}
`;

export const onAutoFillStart = keyframes`
  from {}
`;

export const onAutoFillCancel = keyframes`
  to {}
`;

export const getInputStyles = ({ hasValue, hasError, hasLabel }: StylingProps) => css`
  transition: all 0.15s ease;
  height: 42px;
  width: 100%;
  padding: 13px ${leftRightPadding}px 3px;
  border: 1px solid ${hasError ? GetColor.Error : hasValue ? GetColor.DarkGrey : GetColor.MidGrey1};
  background-color: ${FormFieldBackground};
  color: ${GetColor.White};

  // Override Chrome's css when using autofill
  // The background will be black instead of FormFieldBackground
  // because opacity won't work in terms of covering the white background forced by Chrome
  -webkit-text-fill-color: ${GetColor.White};
  :-webkit-autofill {
    animation-name: ${onAutoFillStart};
    box-shadow: 0 0 0px 1000px black inset;
    & + label {
      ${higherLabel}
    }
  }

  &:not(:-webkit-autofill) {
    animation-name: ${onAutoFillCancel};
  }

  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;

  ${!hasError &&
  css`
    &:hover {
      border: 1px solid ${hoverColor};
      & + label {
        color: ${hoverColor};
      }
    }
  `}

  &:focus, &.select__control--is-focused {
    border: 1px solid ${hasError ? GetColor.Error : focusColor};
    & + label {
      ${!hasError &&
      css`
        color: ${focusColor};
      `}
      ${higherLabel}
    }
  }

  &:disabled,
  &.select__control--is-disabled {
    color: ${disabledColor};
    -webkit-text-fill-color: ${disabledColor};
    border: 1px solid ${disabledColor};
    cursor: not-allowed;
  }

  &:placeholder-shown {
    color: ${GetColor.MidGrey2};
    -webkit-text-fill-color: ${GetColor.MidGrey2};
    line-height: 1.71;
  }

  ${hasLabel
    ? undefined
    : css`
        padding-top: 0;
        padding-bottom: 0;
      `}
`;
