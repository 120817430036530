import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';

export interface SmallToggleProps {
  disabled?: boolean;
  toggled?: boolean;
  size?: number;
  toggledComponent?: JSX.Element;
  unToggledComponent?: JSX.Element;
  indicatorComponent?: JSX.Element;
  noToggledHighlight?: boolean;
  onToggle?(): unknown;
}

const SmallToggle: FC<React.PropsWithChildren<SmallToggleProps>> = ({
  disabled = false,
  toggled = false,
  onToggle,
  size = 15,
  toggledComponent,
  unToggledComponent,
  indicatorComponent,
  noToggledHighlight,
}) => {
  return (
    <Container disabled={disabled} onClick={disabled ? undefined : onToggle}>
      <Toggle
        disabled={disabled}
        toggled={toggled}
        size={size}
        className="qa-trades-toggle"
        noToggledHighlight={noToggledHighlight}
      >
        {toggled && <Text disabled={disabled}>{toggledComponent || 'ON'}</Text>}
        <Indicator toggled={toggled} disabled={disabled} size={size}>
          {indicatorComponent}
        </Indicator>
        {!toggled && <Text disabled={disabled}>{unToggledComponent || 'OFF'}</Text>}
      </Toggle>
    </Container>
  );
};

export default SmallToggle;

interface ToggleProps {
  disabled: boolean;
  toggled: boolean;
  size: number;
  noToggledHighlight?: boolean;
}

const Container = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  user-select: none;
`;

const Toggle = styled.div<ToggleProps>`
  width: 45px;
  height: ${({ size }) => size}px;
  border-radius: 15px;
  background-color: ${({ toggled, disabled, noToggledHighlight }) =>
    disabled
      ? GetColor.PaleGrey
      : noToggledHighlight
        ? GetColor.GreyScale.Grey40
        : toggled
          ? GetColor.Primary.Dark
          : GetColor.DarkGrey};
  box-sizing: content-box;

  ${({ disabled }) => disabled && 'cursor: not-allowed; pointer-events: none;'};

  border: 1px solid ${GetColor.DarkGrey};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Indicator = styled.div<ToggleProps>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  transition-duration: 0.3s;
  border-radius: 50%;
  border: 1px solid ${GetColor.DarkGrey};
  background-color: ${({ disabled }) => (disabled ? GetColor.MidGrey1 : GetColor.White)};
  ${({ toggled }) => (toggled ? 'transform: translateX(2px);' : 'margin-left: -2px;')};
`;

const Text = styled.div<{ disabled?: boolean }>`
  font-size: 11px;
  font-weight: bold;
  flex-grow: 1;
  text-align: center;
  line-height: normal;
  color: ${({ disabled }) => (disabled ? GetColor.MidGrey2 : GetColor.White)};
`;
