import type { FC } from 'react';
import React from 'react';
import styled, { css } from 'styled-components';
import { GetColor, LoadingSize, Spinner } from 'venn-ui-kit';
import type { UserOneTimePassword } from 'venn-api';

interface BackupCodeListProps {
  backupCodes: UserOneTimePassword[];
  loading: boolean;
  colorInverted?: boolean;
}

export const BackupCodeList: FC<React.PropsWithChildren<BackupCodeListProps>> = ({
  backupCodes,
  children,
  loading,
  colorInverted,
}) => (
  <Content inverted={colorInverted}>
    <CodeType>Backup Codes</CodeType>
    <CodeList>
      {loading ? (
        <StyledSpinner size={LoadingSize.small} />
      ) : (
        backupCodes.map(({ code }, i) => (
          <BackupCodeWrapper key={code}>
            {i + 1}. <BackupCode>{code}</BackupCode>
          </BackupCodeWrapper>
        ))
      )}
    </CodeList>
    {children}
  </Content>
);

interface ColorProps {
  inverted?: boolean;
}

const StyledSpinner = styled(Spinner)`
  position: relative;
  left: 209px;
`;

const Content = styled.div<ColorProps>`
  padding: 19px 23px;
  background-color: ${({ inverted }) => (inverted ? GetColor.GreyScale.Grey100 : GetColor.PaleGrey)};
  ${({ inverted }) =>
    inverted &&
    css`
      color: ${GetColor.White};
    `}
`;

const CodeType = styled.h2`
  font-size: 20px;
  font-weight: bold;
`;

const BackupCodeWrapper = styled.div`
  font-size: 14px;
  display: inline-flex;
`;

const BackupCode = styled.div`
  font-weight: bold;
  display: inline;
  padding-left: 10px;
`;

const CodeList = styled.ul`
  list-style: none;
  width: 100%;
  height: auto;
  padding-left: 0;
  column-count: 3;
`;
