import React from 'react';
import styled, { css } from 'styled-components';
import { GetColor, Icon, Shimmer } from 'venn-ui-kit';
import { OBJECTIVE_SELECTOR_HEIGHT } from './DoubleObjectiveSelector';

export const DoubleObjectiveSelectorLoader = () => (
  <LoaderContainer>
    <PrimaryPillLoader>
      <Icon type="function" />
    </PrimaryPillLoader>
    <Separator>
      <Icon type="plus" />
    </Separator>
    <SecondaryPillLoader />
  </LoaderContainer>
);

export const LoaderContainer = styled.div`
  height: ${OBJECTIVE_SELECTOR_HEIGHT}px;
  border-radius: 113px;
  border: 1px solid ${GetColor.PaleGrey};
  display: flex;
`;

const HalfPillCss = css`
  height: ${OBJECTIVE_SELECTOR_HEIGHT - 2}px;

  display: flex;
  align-items: center;

  i {
    margin-left: 10px;
    color: ${GetColor.MidGrey1};
  }
`;

const PrimaryPillLoader = styled.div`
  ${HalfPillCss}
  ${Shimmer}
  animation-duration: 7s;
  border-radius: 113px 0 0 113px;
  width: 130px;
`;

const Separator = styled.div`
  width: 25px;
  border-left: 1px solid ${GetColor.PaleGrey};
  border-right: 1px solid ${GetColor.PaleGrey};
  i {
    color: ${GetColor.MidGrey1};
  }

  height: ${OBJECTIVE_SELECTOR_HEIGHT - 2}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SecondaryPillLoader = styled.div`
  ${HalfPillCss}
  ${Shimmer}
  animation-duration: 7s;
  border-radius: 0 113px 113px 0;
  width: 150px;
`;
