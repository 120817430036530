import React from 'react';
import { CellLoader, GetColor, Icon, Label, Tooltip, TooltipPosition } from 'venn-ui-kit';
import styled from 'styled-components';
import type { AnalysisSubject, ComparisonSubject } from 'venn-utils';
import useSubjectAvailableRange from '../logic/useSubjectAvailableRange';

interface SubjectHeaderProps {
  subject: ComparisonSubject;
  onRemove: () => void;
  onHover: (hover: boolean) => void;
}

const NAME_MAX_CHARS = 30; // Seems to be about the length of two lines.

const formatName = (name: string) => {
  if (name.length < NAME_MAX_CHARS) {
    return name;
  }
  return name.slice(0, NAME_MAX_CHARS).concat('...');
};

const getIconType = (analysisSubject: AnalysisSubject) => {
  if (analysisSubject.portfolio) {
    return 'th';
  }
  return analysisSubject.isCompositeBenchmark ? 'layer-group' : 'square';
};

const SubjectHeader: React.FC<React.PropsWithChildren<SubjectHeaderProps>> = ({ subject, onRemove, onHover }) => {
  const { formattedRange, rangeLoading } = useSubjectAvailableRange(subject.analysisSubject);

  if (!subject.analysisSubject) {
    return (
      <Cell>
        <LoadingPlaceholder />
      </Cell>
    );
  }
  return (
    <Tooltip
      position={TooltipPosition.Top}
      block
      content={
        <Label inverted>
          {subject.isBenchmark
            ? 'Benchmark'
            : subject.analysisSubject.isCompositeBenchmark
              ? 'Composite Benchmark'
              : subject.analysisSubject.type === 'investment'
                ? 'Investment'
                : 'Portfolio'}
          : {subject.analysisSubject.name}
        </Label>
      }
    >
      <Wrapper
        color={subject.color}
        hovered={subject.isHovered}
        onMouseEnter={() => onHover(true)}
        onMouseLeave={() => onHover(false)}
      >
        <ColoredBar color={subject.color} />
        <Row>
          <ContentGutter />
          <NameWrapper>
            <Icon type={getIconType(subject.analysisSubject)} /> {formatName(subject.analysisSubject.name)}
          </NameWrapper>
          <ContentGutter>
            {subject.isHovered && (
              <button type="button" onClick={onRemove}>
                <Icon type="times" />
              </button>
            )}
          </ContentGutter>
        </Row>
        <div>{rangeLoading ? <CellLoader /> : <RangeInfo>{formattedRange}</RangeInfo>}</div>
      </Wrapper>
    </Tooltip>
  );
};

const Cell = styled.div`
  height: 72px;
  padding: 2px;
  padding-bottom: 12px;
`;

const RangeInfo = styled.span`
  color: ${GetColor.DarkGrey};
`;

const Wrapper = styled(Cell)<{ hovered: boolean; color: string }>`
  background-color: ${(props) => (props.hovered ? GetColor.WhiteGrey : 'initial')};
  color: ${(props) => props.color || GetColor.Black};
  font-size: 12px;
  text-align: center;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 42px;
`;

const ColoredBar = styled.div<{ color: string }>`
  background-color: ${(props) => props.color || GetColor.Black};
  margin-bottom: 7px;
  height: 5px;
  width: 100%;
`;

const ContentGutter = styled.div`
  min-width: 10%;
  flex: auto;
`;

const NameWrapper = styled.div`
  min-width: 80%;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LoadingPlaceholder = styled.div`
  background-color: ${GetColor.WhiteGrey};
  height: 100%;
  width: 90%;
  margin: 0 auto;
`;

export default SubjectHeader;
