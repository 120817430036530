import { useState, useCallback } from 'react';

/**
 * Small helper that returns hover state and handlers that update hover state when added to a component.
 *
 * For accessibility, focus also causes the isHoveirng state.
 */
export function useHover() {
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = useCallback(() => setIsHovering(true), []);
  const handleMouseOut = useCallback(() => setIsHovering(false), []);

  return [
    isHovering,
    {
      onFocus: handleMouseOver,
      onBlur: handleMouseOut,
      onMouseOver: handleMouseOver,
      onMouseLeave: handleMouseOut,
    },
  ] as const;
}
