import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { PanelSection } from '../../../PanelSection';
import type { HoldingsCategoryId } from 'venn-api';
import type { DropMenuCheckboxItem } from 'venn-ui-kit';
import { FilterDropMenu } from 'venn-ui-kit';
import { allHoldingsCategories, filterHoldingsToLevel, holdingsCategories } from 'venn-state';
import { useRecoilState, useRecoilValue } from 'recoil';
import { compact } from 'lodash';
import { HOLDINGS_TREE_MAX_DEPTH } from 'venn-components';

interface HoldingsBlockSectionProps {
  selectedRefId: string;
}

const HoldingsBlockSection = ({ selectedRefId }: HoldingsBlockSectionProps) => {
  const allHoldingsCategoryOptions = useRecoilValue(allHoldingsCategories(selectedRefId));
  const [categories, setCategories] = useRecoilState(holdingsCategories(selectedRefId));
  const categoryIds = useMemo(
    () => filterHoldingsToLevel(categories, HOLDINGS_TREE_MAX_DEPTH).map((category) => category.id),
    [categories],
  );

  const selectedCategories: DropMenuCheckboxItem<HoldingsCategoryId>[] = useMemo(
    () =>
      allHoldingsCategoryOptions.map((category) => ({
        value: category.id,
        label: category.categoryName,
        checked: categoryIds.includes(category.id),
      })),
    [allHoldingsCategoryOptions, categoryIds],
  );

  const setSelectedCategories = useCallback(
    (selection: DropMenuCheckboxItem<HoldingsCategoryId>[]) => {
      const mapped = compact(
        selection.map((item) => {
          const filtered = allHoldingsCategoryOptions.filter((category) => category.id === item.value && item.checked);
          if (filtered) {
            return filtered[0];
          }
          return undefined;
        }),
      );
      setCategories(mapped);
    },
    [allHoldingsCategoryOptions, setCategories],
  );

  return (
    <PanelSection header="Assets" className="qa-holdings-settings">
      <Container>
        <FilterDropMenu<HoldingsCategoryId> items={selectedCategories} onChange={setSelectedCategories} width={250} />
      </Container>
    </PanelSection>
  );
};

export default React.memo(HoldingsBlockSection);

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
