import React from 'react';
import styled from 'styled-components';
import { Icon, GetColor } from 'venn-ui-kit';

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default () => (
  <Container>
    <Icon type="exclamation" />
    <h2>Failed to load forecasts.</h2>
    Please try again later or refresh the page.
  </Container>
);

const Container = styled.div`
  padding-top: 100px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${GetColor.HintGrey};
  font-size: 14px;

  i {
    font-size: 60px;
    line-height: 80px;
    width: 100px;
    height: 100px;
    text-align: center;
    border: 10px solid ${GetColor.PaleGrey};
    border-radius: 50%;
    color: ${GetColor.PaleGrey};
  }

  h2 {
    margin-top: 20px;
    font-size: 16px;
    font-weight: bold;
    color: ${GetColor.Black};
  }
`;
