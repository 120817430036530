import React from 'react';
import DeleteConfirmationModal from '../DeleteConfirmationModal';
import { StyledButtonIcon } from './styles';
import type { BulkActionProps } from './types';
import { useModal } from 'venn-utils';
import { TooltipPosition } from 'venn-ui-kit';
import { isDeletable } from '../utils';

type DeleteItemsProps = BulkActionProps;

const DeleteItems = ({ items, onComplete }: DeleteItemsProps) => {
  const [isOpen, open, close] = useModal();
  const masterSelected = items.some((item) => item.master);
  const disabled = !items.every(isDeletable);

  let tooltip;

  if (disabled) {
    tooltip = masterSelected ? 'Cannot delete the Master Portfolio.' : 'Cannot delete non user created items.';
  }

  return (
    <>
      <StyledButtonIcon
        tooltip={tooltip}
        tooltipPosition={TooltipPosition.Bottom}
        onClick={open}
        iconType="trash"
        disabled={disabled}
        text={`Delete (${items.length})`}
        className="qa-bulk-delete"
        warningColor
      />
      {isOpen && (
        <DeleteConfirmationModal
          items={items}
          onCancelDelete={close}
          onDeleteComplete={() => onComplete('Delete', close, true)}
        />
      )}
    </>
  );
};

export default DeleteItems;
