import React from 'react';
import styled from 'styled-components';
import type { FactorCategory } from '../types';
import type { Typography } from 'venn-ui-kit';
import { GetColor, Label, Body1, BodyHighlight } from 'venn-ui-kit';

interface PyramidProps {
  typography: Typography;
  category?: FactorCategory;
}

const fillColor = GetColor.HighlightDark;
const nonFillColor = GetColor.White;

const Pyramid = ({ category, typography }: PyramidProps) => {
  return (
    <Container>
      <svg width="563px" height="463px" viewBox="0 -100 563 463" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1231.000000, -327.000000)">
            <g transform="translate(400.000000, 311.000000)">
              <g transform="translate(18.000000, 0.000000)">
                <g transform="translate(814.000000, 12.000000)">
                  <g>
                    <g transform="translate(0.000000, 93.000000)">
                      <text
                        id="Factors"
                        fontFamily={typography.fontFamily}
                        fontSize="12"
                        fontWeight="bold"
                        fill="#10161B"
                      >
                        <tspan x="62" y="42">
                          Factors
                        </tspan>
                      </text>
                      <polyline stroke="#767676" points="197 0 166.968389 0 0 270 26.8122823 270" />
                      <path d="M139.451352,45 L113.874066,45" stroke="#767676" strokeLinecap="square" />
                    </g>
                    <text fontFamily={typography.fontFamily} fontSize="12" fontWeight="bold" fill="#10161B">
                      <tspan x="333" y="38">
                        Residual
                      </tspan>
                    </text>
                    <text fontFamily={typography.fontFamily} fontSize="12" fontWeight="bold" fill="#10161B">
                      <tspan x="390" y="133">
                        Style Factors
                      </tspan>
                    </text>
                    <text fontFamily={typography.fontFamily} fontSize="12" fontWeight="bold" fill="#10161B">
                      <tspan x="445" y="225">
                        Secondary Macro
                      </tspan>
                    </text>
                    <text fontFamily={typography.fontFamily} fontSize="12" fontWeight="bold" fill="#10161B">
                      <tspan x="499" y="316">
                        Core Macro
                      </tspan>
                    </text>
                    <g transform="translate(54.000000, 0.000000)">
                      <mask fill="white">
                        <polygon points="223.975468 0 0 370 445 370" />
                      </mask>
                      <Row>
                        <TopTriangle
                          d="M224.396606,6.86949996 L169.689921,95.4979137 L278.331203,95.4136716 L224.396606,6.86949996 Z"
                          stroke="#10161B"
                          strokeWidth="3"
                          fill="#FFFFFF"
                          mask="url(#mask-2)"
                        />
                        <Tooltip height={160} start={65}>
                          <div>
                            <Label inverted>Residual</Label>
                            <Body1 inverted>
                              Idiosyncratic sources of risk (i.e., uncorrelated to other known factors) that are limited
                              in capacity and have historically commanded higher fees. These risks generally appear as
                              "residual" in a returns-based statistical factor analysis given their low correlation with
                              known factors.
                            </Body1>
                          </div>
                        </Tooltip>
                      </Row>
                      <Row>
                        <Triangle
                          d="M168.988512,96.501825 L114.675293,185.495816 L332.348071,185.1555 L279.099075,96.7403811 L168.988512,96.501825 Z"
                          stroke="#6600FF"
                          strokeWidth="3"
                          selected={category === 'Macro Styles' || category === 'Equity Styles'}
                          mask="url(#mask-2)"
                        />
                        <Tooltip height={130} start={136}>
                          <div>
                            <Label inverted>Style Factors</Label>
                            <Body1 inverted>
                              Lower-capacity risk factors shown to correspond to sizeable common risk drivers within
                              asset classes, such as individual stocks or bonds.
                            </Body1>
                            <Body1 inverted>
                              Ex. <BodyHighlight inverted>Value</BodyHighlight> and{' '}
                              <BodyHighlight inverted>Fixed Income Carry</BodyHighlight>
                            </Body1>
                          </div>
                        </Tooltip>
                      </Row>
                      <Row>
                        <Triangle
                          d="M113.111126,186.120547 L59.6659996,274.490372 L385.363602,273.315313 L332.955004,185.502396 L113.111126,186.120547 Z"
                          stroke="#6600FF"
                          strokeWidth="3"
                          selected={category === 'Secondary Macro'}
                          mask="url(#mask-2)"
                        />
                        <Tooltip height={130} start={225}>
                          <div>
                            <Label inverted>Secondary Macro Factors</Label>
                            <Body1 inverted>
                              Macro risk factors that can cut across multiple asset classes to explain additional
                              concentrated risks that frequently arise in diversified portfolios.
                            </Body1>
                            <Body1 inverted>
                              Ex. <BodyHighlight inverted>Foreign Currency</BodyHighlight> and{' '}
                              <BodyHighlight inverted>Emerging Markets</BodyHighlight>
                            </Body1>
                          </div>
                        </Tooltip>
                      </Row>
                      <Row>
                        <Triangle
                          d="M59.7776266,275.057811 L5.64593038,365.5 L440.371943,365.5 L385.446679,272.506721 L59.7776266,275.057811 Z"
                          stroke="#6600FF"
                          strokeWidth="3"
                          selected={category === 'Core Macro'}
                          mask="url(#mask-2)"
                        />
                        <Tooltip height={110} start={315}>
                          <div>
                            <Label inverted>Core Macro Factors</Label>
                            <Body1 inverted>
                              High-capacity risk factors shown to correspond to the principal drivers of asset class
                              returns.
                            </Body1>
                            <Body1 inverted>
                              Ex. <BodyHighlight inverted>Equity</BodyHighlight> and{' '}
                              <BodyHighlight inverted>Interest Rates</BodyHighlight>
                            </Body1>
                          </div>
                        </Tooltip>
                      </Row>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </Container>
  );
};

interface TooltipProps {
  start: number;
  height: number;
  children: JSX.Element;
}

const Tooltip = ({ children, start, height }: TooltipProps) => {
  return (
    <TooltipContainer>
      <path
        d={`M20,${start} L216.5,${start} L221.5,${start + 7} L226.5,${start} L423,${start} L423,${start - height} L20,${
          start - height
        } Z`}
        fill="#000000DD"
      />
      <foreignObject x="25" y={start - height + 10} width={393} height={height}>
        {children}
      </foreignObject>
    </TooltipContainer>
  );
};

const Container = styled.div`
  position: relative;
  top: -80px;
  overflow: hidden;
`;

const TooltipContainer = styled.g`
  display: none;
  pointer-events: none;
`;

const Row = styled.g`
  &:hover {
    ${TooltipContainer} {
      display: inherit;
    }
  }
`;

const Triangle = styled.path<{ selected: boolean }>`
  fill: ${(props) => (props.selected ? 'rgba(102, 0, 255, 0.1)' : nonFillColor)};
  pointer-events: all;

  &:hover {
    fill: ${fillColor};
  }
`;

const TopTriangle = styled.path`
  pointer-events: all;

  &:hover {
    fill: ${GetColor.Black};
  }
`;

export default Pyramid;
