import type { ReactNode } from 'react';
import React, { Component } from 'react';
import styled from 'styled-components';

const AnimatedHeightContainer = styled.div`
  transition: height 0.25s linear;
  overflow: hidden;
`;

class AnimatedHeight extends Component<{ className?: string; children?: ReactNode }> {
  private container: HTMLElement | null;

  private content: HTMLElement | null;

  componentDidMount() {
    this.adjustContainerHeight();
  }

  componentDidUpdate() {
    this.adjustContainerHeight();
  }

  adjustContainerHeight() {
    if (this.container && this.content) {
      const { style } = this.container;
      const { offsetHeight } = this.content;
      style.height = `${offsetHeight}px`;
    }
  }

  render() {
    const { children, className } = this.props;

    return (
      <AnimatedHeightContainer
        className={className}
        ref={(ref) => {
          this.container = ref;
        }}
      >
        <div
          ref={(ref) => {
            this.content = ref;
          }}
        >
          {children}
        </div>
      </AnimatedHeightContainer>
    );
  }
}

export default AnimatedHeight;
