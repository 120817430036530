import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';

const LabelText = styled.div`
  padding-bottom: 5px;
  color: ${GetColor.Black};
  font-size: 12px;
  font-weight: bold;
`;

interface LabelProps {
  label: string;
}

const Label: FC<React.PropsWithChildren<LabelProps>> = ({ children, label }) => (
  <>
    <LabelText>{label}</LabelText>
    <div>{children}</div>
  </>
);

export default Label;
