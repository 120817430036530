import React, { useContext } from 'react';
import { FlexHeader, Section } from '../../shared';
import { EditorContext, RichTextControls, UserContext } from 'venn-components';

const MarkdownTab = () => {
  const { hasPermission } = useContext(UserContext);
  const isReadOnly = !hasPermission('STUDIO_EDIT_FORMAT');
  const { activeEditor, textControlsState } = useContext(EditorContext);
  return (
    <Section>
      <FlexHeader>Text Styles</FlexHeader>
      {!isReadOnly && activeEditor && textControlsState && (
        <RichTextControls controlsState={textControlsState} editor={activeEditor} />
      )}
    </Section>
  );
};

export default MarkdownTab;
