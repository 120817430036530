export enum TooltipPosition {
  Left = 'left',
  Top = 'top',
  Bottom = 'bottom',
  Right = 'right',
}
export enum TooltipBodyDirection {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}
export enum TextAlign {
  Left = 'left',
  Right = 'right',
  Center = 'center',
}
export enum TriangleDirection {
  Top = 'top',
  Right = 'right',
  Bottom = 'bottom',
  Left = 'left',
}
export enum DropDirection {
  Up = 'up',
  Right = 'right',
  Down = 'down',
  Left = 'left',
}
