import React, { useMemo } from 'react';
import type { AnalysisTrackingProps, SharedProps } from '../types';
import {
  DownloadableContentBlock,
  FailedAnalysisInfo,
  PercentageDrawdownChart,
  TrackSuccessfulAnalysis,
} from 'venn-components';
import type { PercentageDrawdownAnalysis as PercentageDrawdownAnalysisData } from 'venn-api';
import type { AnalysisGroup, ExcelCell, Series, AnalysisConfig } from 'venn-utils';
import { convertMultipleSeriesDataToExcel, getSecondaryDisplayLabel } from 'venn-utils';

export interface PercentageDrawdownAnalysisProps extends SharedProps, AnalysisTrackingProps {}

const PERCENTAGE_DRAWDOWN = 'PERCENTAGE_DRAWDOWN';

const PercentageDrawdownAnalysis = ({
  analysisConfig,
  analyses,
  onResetTimeFrame,
  trackingProps,
  downloadMetaData,
  updateAnalysisStatusForTracking,
}: PercentageDrawdownAnalysisProps) => {
  const { subject, relative, trackingId } = analysisConfig;

  const data = analyses?.results.percentageDrawdown;
  const analysisError = analyses?.results.percentageDrawdown?.message;
  const title = analysisConfig.relative ? 'Historical Underperformance Chart' : 'Historical Drawdown Chart';

  const excelData = useMemo(() => {
    if (!data) {
      return undefined;
    }
    return getExcellData(data, analysisConfig);
  }, [data, analysisConfig]);

  if (analysisError !== undefined) {
    return (
      <FailedAnalysisInfo
        subject={subject}
        analysesPeriod={analyses?.analysesPeriod}
        onResetAnalysisPeriod={onResetTimeFrame}
        error={{
          message: analysisError.text,
          code: analysisError.code,
        }}
        regressionName={title}
        relativeToBenchmark={relative}
        blockNames={[PERCENTAGE_DRAWDOWN]}
        blockTitles={[title]}
        trackingId={trackingId}
        updateAnalysisStatusForTracking={updateAnalysisStatusForTracking}
      />
    );
  }

  if (!subject || !data) {
    return null;
  }

  return (
    <TrackSuccessfulAnalysis {...trackingProps}>
      <DownloadableContentBlock
        header={title}
        downloadable={{
          png: true,
          excel: excelData,
          options: {
            fileName: `${subject?.name || ''} - ${title}`,
            metaData: downloadMetaData,
          },
          tracking: {
            subjectType: subject?.type,
            subjectId: subject?.id,
            description: 'PERCENTAGE_DRAWDOWN_ANALYSIS',
            relativeToBenchmark: relative,
            userUploaded: Boolean(subject?.fund?.userUploaded),
          },
        }}
      >
        <PercentageDrawdownChart data={data} subject={subject} frequency={analyses?.analysesPeriod?.frequency} />
      </DownloadableContentBlock>
    </TrackSuccessfulAnalysis>
  );
};

export default PercentageDrawdownAnalysis;

export const getExcellData = (
  data: AnalysisGroup<PercentageDrawdownAnalysisData>,
  analysisConfig: AnalysisConfig,
): ExcelCell[][] | undefined => {
  if (!analysisConfig.subject || !data.subject) {
    return undefined;
  }
  const series: Series[] = [];
  series.push({
    name: analysisConfig.subject.name,
    series: data.subject.series,
  });
  const subject = analysisConfig.subject;
  if (subject.hasSecondarySubject && data.category) {
    series.push({
      name: `${subject.secondaryPortfolio?.name} (${getSecondaryDisplayLabel(subject, 'as of')})`,
      series: data.category.series,
    });
  }
  if (subject.hasBenchmark && data.benchmark) {
    series.push({
      name: `${subject?.activeBenchmarkName}(Benchmark)`,
      series: data.benchmark.series,
    });
  }
  if (subject.categoryGroup && data.category) {
    series.push({
      name: `${subject.categoryGroup.name}(Category)`,
      series: data.category.series,
    });
  }
  return convertMultipleSeriesDataToExcel(series, true);
};
