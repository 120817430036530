import styled from 'styled-components';
import type { EmptyStateProps } from '../../empty-state/EmptyState';
import EmptyState from '../../empty-state/EmptyState';
import React, { useEffect } from 'react';
import { blockExcelFunction, useScopedSetRecoilState } from 'venn-state';

export const StyledErrorState = (props: EmptyStateProps & { selectedRefId: string }) => {
  const setExportFunction = useScopedSetRecoilState(blockExcelFunction(props.selectedRefId));

  useEffect(() => {
    setExportFunction({ excelFunction: undefined });
  }, [setExportFunction]);

  return <StyledErrorStateComponent {...props} />;
};

const StyledErrorStateComponent = styled(EmptyState)`
  margin: 30px;
`;
