import type { ReadOnlySelectorOptions, RecoilValueReadOnly } from 'recoil';
import { selector } from 'recoil';
import { userIsAuthenticated } from '../user';
import { noop } from 'lodash';

/**
 * An async selector which waits until the user's auth status is authenticated til it evaluates.
 */
export const selectorWithAuth = <T>(options: ReadOnlySelectorOptions<T>): RecoilValueReadOnly<T> =>
  selector({
    ...options,
    get: (args) => {
      const authed = args.get(userIsAuthenticated);
      if (authed) {
        return options.get(args);
      }
      return new Promise(noop);
    },
  });
