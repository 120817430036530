import type { FC } from 'react';
import React, { useContext, useEffect } from 'react';
import { signOutAndClearStorage } from 'venn-api';
import { UserContext, AuthLoading } from 'venn-components';

const Logout: FC<React.PropsWithChildren<unknown>> = () => {
  const userContext = useContext(UserContext);
  useEffect(() => {
    const logout = async () => {
      try {
        await signOutAndClearStorage();
      } catch (_e) {
        // If there's a failure supress it because it is likely the user
        // is already un-authenticated, so reload the page to show the
        // login screen
      }
      window.location.href = `${process.env.PUBLIC_URL}/`;
    };
    // If we have a current user, log them out
    if (userContext?.profileSettings?.user?.id) {
      logout();
      // Otherwise reload the page so the user can see the sign-in screen
    } else {
      window.location.reload();
    }
  }, [userContext]);

  // The only thing rendered by this route is the loader
  return <AuthLoading />;
};

export default Logout;
