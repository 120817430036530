import React, { useContext } from 'react';
import type { VennColors } from 'venn-ui-kit';
import { GetColor, Icon, Label, Tooltip, TooltipPosition } from 'venn-ui-kit';
import styled, { ThemeContext } from 'styled-components';
import { useHasFF } from 'venn-utils';

const legendInfo = (colors: VennColors) => {
  return [
    {
      text: 'Investment Returns',
      color: colors.DEPRECATED_DataLineColor.Gold,
    },
    {
      text: 'Proxy Data',
      color: colors.DEPRECATED_DataLineColor.PaleGold,
    },
    {
      text: 'No Allocation',
      color: colors.MidGrey2,
    },
    {
      text: 'Proxy Data (No Allocation)',
      color: colors.MidGrey1,
    },
    {
      text: 'Extrapolation',
      color: colors.DEPRECATED_DataBarColor.LightPaleBlue,
    },
    {
      text: 'Benchmark / Factor / Comparison',
      color: colors.DEPRECATED_DataBarColor.LightDarkBlue,
    },
    {
      text: 'Quarterly Frequency',
      color: colors.DEPRECATED_DataLineColor.Gold,
      dashed: true,
    },
    {
      text: 'Full Analysis Period Available',
      color: colors.Primary.Medium,
    },
  ] as LegendEntryProps[];
};

const LegendContent = () => {
  const { Colors } = useContext(ThemeContext);
  const showExtrapolation = useHasFF('extrapolation_ff');
  return (
    <LegendWrapper data-testid="qa-legend-wrapper">
      {legendInfo(Colors)
        .filter((item) => showExtrapolation || item.text !== 'Extrapolation')
        .map((item) => {
          return <LegendEntry key={item.text} {...item} />;
        })}
    </LegendWrapper>
  );
};

/** Legend to display as tooltip on top of "Analysis Period" column */
const AnalysisPeriodLegend = ({ usePortal = true }) => {
  const { Colors } = useContext(ThemeContext);
  return (
    <Tooltip
      usePortal={usePortal}
      background={Colors.White}
      showShadow
      position={TooltipPosition.Top}
      content={<LegendContent />}
    >
      <Label>Analysis Period</Label>
      &nbsp;
      <Icon type="info-circle" />
    </Tooltip>
  );
};

interface LegendEntryProps {
  text: string;
  dashed: boolean;
  color: string;
}

const LegendWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${GetColor.Black};
  padding: 12px 20px 20px 20px;
`;

const LegendEntryWrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  padding-top: 8px;
  justify-content: flex-start;
`;

const RangeBar = styled.div<{
  dashed: boolean;
  color: string;
}>`
  ${({ dashed, color }) => `
    background: ${
      dashed ? `repeating-linear-gradient(to right, ${color} 0, ${color} 8px, transparent 8px,transparent 16px)` : color
    };
    height: 8px;
    width: 24px;
    margin: 0px 12px 0px 0px;
    padding: 0px;
    `}
`;
const LegendEntry = ({ text, color, dashed = false }: LegendEntryProps) => {
  return (
    <LegendEntryWrapper>
      <RangeBar color={color} dashed={dashed} />
      {text}
    </LegendEntryWrapper>
  );
};
export default React.memo(AnalysisPeriodLegend);
