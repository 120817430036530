import { isNil } from 'lodash';
import type { RefObject } from 'react';
import React from 'react';
import styled, { css } from 'styled-components';
import type { ScenarioShockRange, ShockUnitsEnum } from 'venn-api';
import type { TooltipPosition, TooltipProps } from 'venn-ui-kit';
import { GetColor, KeyCodes, Tooltip } from 'venn-ui-kit';
import { formatZScore, getInRangeValue } from './logic';

import { convertByUnits, getUnitBase, getUnitPrecision, getUnitSymbol } from '../../utils/scenario';
import ShockInputTooltip from './ShockInputTooltip';

interface ShockInputProps {
  fundName?: string;
  shock?: number;
  units: ShockUnitsEnum;
  mean: number;
  // New Shock will accept empty shock
  addNewShock?: boolean;
  className?: string;
  setInputRef?: RefObject<HTMLInputElement>;
  onChange: (value: number, rawValue: number) => void;
  shockRange?: ScenarioShockRange;
  disabled?: boolean;
  tooltipPosition: TooltipPosition;
  tooltipOffset?: TooltipProps['portalPosition'];
}

interface ShockInputState {
  focused: boolean;
  currentValue?: string;
}

class ShockInput extends React.PureComponent<ShockInputProps, ShockInputState> {
  private input = this.props.setInputRef ? this.props.setInputRef : React.createRef<HTMLInputElement>();

  state: ShockInputState = {
    focused: false,
    currentValue: convertByUnits(this.props.shock, this.props.units, this.props.addNewShock),
  };

  componentDidUpdate(prevProps: ShockInputProps) {
    if (prevProps.shock !== this.props.shock || prevProps.units !== this.props.units) {
      this.setState({
        currentValue: convertByUnits(this.props.shock, this.props.units, this.props.addNewShock),
      });
    }
  }

  onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key.match(/^[a-zA-Z\+\*\/\s]$/) && !(event.ctrlKey || event.metaKey || event.altKey)) {
      event.preventDefault();
    }
    if (event.keyCode === KeyCodes.Enter) {
      this.input.current!.blur();
    }
  };

  onChange = (event: React.ChangeEvent<HTMLInputElement> | string) => {
    if (typeof event === 'string') {
      this.setState({
        currentValue: event,
      });
    } else {
      const { value } = event.target;
      this.setState({
        currentValue: value,
      });
    }
  };

  onFocus = () => {
    requestAnimationFrame(() => {
      const { currentValue } = this.state;
      this.setState({ focused: true });
      const target = this.input.current;
      if (!currentValue) {
        return;
      }
      target && target.setSelectionRange(0, currentValue.length);
    });
  };

  onNewShockFlow = () => {
    const { currentValue } = this.state;
    const { units, onChange, shockRange } = this.props;
    const base = getUnitBase(units);
    const min = shockRange?.minShock;
    const max = shockRange?.maxShock;
    // When there is no value in input but user already select a range
    // We will show '--' for null
    if (isNil(currentValue) || invalidStringInput(currentValue)) {
      this.setState({
        focused: false,
        currentValue: undefined,
      });
      return;
    }

    const newRawValue = isNil(currentValue) ? NaN : parseFloat(currentValue) / base;
    const newInRangeValue = getInRangeValue(newRawValue, min, max);
    this.setState({
      focused: false,
      currentValue: '',
    });
    onChange(newInRangeValue, newRawValue);
  };

  onBlur = () => {
    requestAnimationFrame(() => {
      const { currentValue } = this.state;
      const { shock, units, onChange, addNewShock, shockRange } = this.props;
      const min = shockRange?.minShock;
      const max = shockRange?.maxShock;
      const base = getUnitBase(units);

      if (addNewShock) {
        this.onNewShockFlow();
        return;
      }

      if (currentValue !== convertByUnits(shock, units, addNewShock)) {
        // when user deletes previous value (or enters invalid value) and leave, fallback to previous value
        if (invalidStringInput(currentValue)) {
          this.setState({ focused: false, currentValue: convertByUnits(shock, units, addNewShock) });
          return;
        }

        const newRawValue = parseFloat(currentValue ?? '') / base;
        const inRangeValue = getInRangeValue(newRawValue, min, max);

        const displayString = inRangeValue ? convertByUnits(inRangeValue, units, !!addNewShock) : currentValue;
        this.setState({
          currentValue: displayString,
          focused: false,
        });

        onChange(inRangeValue, newRawValue);
      } else {
        this.setState({ focused: false });
      }
    });
  };

  getInputValue(): string {
    const { units } = this.props;
    const { focused, currentValue } = this.state;
    // Only in add new value mode
    if (currentValue === '') {
      return '';
    }
    if (isNil(currentValue)) {
      return focused ? '' : '--';
    }

    const value = parseFloat(currentValue);
    const valueString = value.toFixed(getUnitPrecision(units));
    return focused ? currentValue : value <= 0 ? valueString : `+${valueString}`;
  }

  render() {
    const { units, mean, fundName, className, addNewShock, shockRange, tooltipOffset, tooltipPosition } = this.props;
    const { focused, currentValue } = this.state;
    const base = getUnitBase(units);
    const inputValue = this.getInputValue();
    const disabled = this.props.disabled || (!!addNewShock && !shockRange);

    return (
      <Tooltip
        usePortal
        portalPosition={tooltipOffset}
        background="transparent"
        position={tooltipPosition}
        isHidden={disabled}
        content={
          <ShockInputTooltip
            shock={currentValue ? parseFloat(currentValue) / base : NaN}
            units={units}
            mean={mean}
            fundName={fundName}
            minVal={shockRange?.minShock ?? NaN}
            maxVal={shockRange?.maxShock ?? NaN}
            minShockZScore={shockRange?.minShockZScore ? formatZScore(shockRange.minShockZScore) : NaN}
            maxShockZScore={shockRange?.maxShockZScore ? formatZScore(shockRange.maxShockZScore) : NaN}
          />
        }
      >
        <Wrapper className={className} style={{ display: 'inline-block' }}>
          <Input
            ref={this.input}
            focused={focused}
            currentValue={currentValue}
            value={inputValue}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onKeyDown={this.onKeyDown}
            onChange={this.onChange}
            disabled={disabled}
          />
          <Units disabled={disabled}>{getUnitSymbol(units)}</Units>
        </Wrapper>
      </Tooltip>
    );
  }
}

function invalidStringInput(s: string | undefined): boolean {
  return typeof s === 'string' && !s.match(/^[+-]?((\d+(\.\d*)?)|(\.\d+))$/);
}

export default ShockInput;

const Wrapper = styled.div`
  position: relative;
`;

const Units = styled.span<{ disabled: boolean }>`
  position: absolute;
  left: 55px;
  top: 1px;
  line-height: 30px;
  color: ${({ disabled }) => (disabled ? GetColor.MidGrey2 : GetColor.Black)};
  pointer-events: none;
  font-weight: bold;
`;

const Input = styled.input<Partial<ShockInputState>>`
  text-align: right;
  padding-right: 20px;
  width: 76px;
  ${({ focused, currentValue }) =>
    focused
      ? css`
          border: 1px solid ${GetColor.Primary.Main};
        `
      : isNil(currentValue)
        ? css`
            border: 1px solid ${GetColor.Error};
          `
        : css`
            border: 1px solid ${GetColor.PaleGrey};
          `}
  &:disabled {
    border-color: ${GetColor.Grey};
    color: ${GetColor.MidGrey2};
    background-color: ${GetColor.PaleGrey};
    cursor: not-allowed;
  }
`;
