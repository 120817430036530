import { GetColor, Icon, Loading, LoadingSize } from 'venn-ui-kit';
import { QRCode } from './QRCode';
import ClickToCopyLink from '../../click-to-copy/ClickToCopyLink';
import React from 'react';
import type { MFAResponse } from 'venn-api';
import styled, { css } from 'styled-components';
import { Label } from '../account-modals/shared';

interface AuthenticatorDetailsProps {
  showManualEntry: boolean;
  authenticatorInfo: MFAResponse | null;
  loading: boolean;
  toggleManualEntry: (() => void) | undefined;
  colorInverted?: boolean;
}

const AuthenticatorDetails = ({
  showManualEntry,
  authenticatorInfo,
  loading,
  toggleManualEntry,
  colorInverted,
}: AuthenticatorDetailsProps) => {
  const compact = !toggleManualEntry;
  return (
    <Container compact={compact}>
      <QRLayout showBorder={!showManualEntry}>
        <QRCodeContainer inverted={colorInverted}>
          {loading || !authenticatorInfo ? (
            <Loading size={LoadingSize.small} />
          ) : (
            <QRCode text={authenticatorInfo.totpURI} colorInverted={colorInverted} />
          )}
        </QRCodeContainer>
        {toggleManualEntry && (
          <QRText>
            Download the Google Authenticator application from the App Store or Google Play Store and scan the code on
            the left with your smartphone.
            <StyledLabel>
              Can't scan the QR code?&nbsp;
              <button onClick={toggleManualEntry} type="button">
                Manual entry details <Icon type={showManualEntry ? 'chevron-up' : 'chevron-down'} prefix="fas" />
              </button>
            </StyledLabel>
          </QRText>
        )}
      </QRLayout>
      {showManualEntry && (
        <ManualEntry inverted={colorInverted} compact={compact}>
          <h5>Manual Entry</h5>
          <MfaDetailsPrompt compact={compact}>
            If you can't scan the code, you can manually enter your account and key details in the application on your
            phone:
          </MfaDetailsPrompt>
          <MfaDetails>
            <p>
              <ManualLabel>Account:</ManualLabel>{' '}
              {loading || !authenticatorInfo ? (
                <Loading size={LoadingSize.micro} />
              ) : (
                <ClickToCopyLink
                  colorInverted={colorInverted}
                  text={authenticatorInfo.email}
                  successMessage="Email copied"
                  errorMessage="Unable to copy email to clipboard"
                />
              )}
            </p>
            <p>
              <ManualLabel>Key:</ManualLabel>
              {loading || !authenticatorInfo ? (
                <Loading size={LoadingSize.micro} />
              ) : (
                <ClickToCopyLink
                  colorInverted={colorInverted}
                  text={authenticatorInfo.totpSecret}
                  successMessage="Key copied"
                  errorMessage="Unable to copy key to clipboard"
                />
              )}
            </p>
          </MfaDetails>
        </ManualEntry>
      )}
    </Container>
  );
};

export default AuthenticatorDetails;

interface ColorProps {
  inverted?: boolean;
}

interface SizingProps {
  compact: boolean;
}

const Container = styled.div<SizingProps>`
  ${({ compact }) => compact && 'display: flex; align-items: center;'};
`;

const ManualLabel = styled(Label)`
  font-size: 12px;
  font-weight: bold;
  padding-right: 5px;
`;

const StyledLabel = styled(Label)`
  display: block;
  margin: 10px 0 6px;
`;

const QRLayout = styled.div<{ showBorder: boolean }>`
  justify-content: left;
  display: inline-block;
  ${({ showBorder }) =>
    showBorder &&
    css`
      border-bottom: 2px solid ${GetColor.Grey};
    `}
`;

const QRCodeContainer = styled.div<ColorProps>`
  display: inline-block;
  vertical-align: middle;
  width: 105px;
  height: 105px;
`;

const QRText = styled.p`
  display: inline-block;
  vertical-align: middle;
  padding-left: 15px;
  width: 381px;
`;

const ManualEntry = styled.div<ColorProps & SizingProps>`
  display: inline-block;
  ${({ inverted }) => !inverted && `background: ${GetColor.DEPRECATED_DivergingColor.MID}`};
  color: ${({ inverted }) => (inverted ? GetColor.White : GetColor.Black)};
  padding: 10px 10px 14px;
  ${({ compact }) => !compact && 'margin: 0 -20px'};

  h5 {
    font-weight: bold;
    ${({ compact }) => (compact ? 'padding: 0 10px' : 'padding: 10px;')};
  }
`;

const MfaDetailsPrompt = styled.div<SizingProps>`
  display: inline-block;
  padding: 0 10px;
  ${({ compact }) => !compact && 'width: 272px'};
  line-height: 1.71;
  font-size: ${({ compact }) => (compact ? 12 : 14)}px;
`;

const MfaDetails = styled.div`
  display: inline-block;
  padding: 0 10px;
  vertical-align: top;
  p {
    margin: 0;
  }
`;
