import { useCallback, useEffect, useState, useRef } from 'react';
import { getAllTags } from 'venn-api';
import { logExceptionIntoSentry } from 'venn-utils';
import type { TagsContextValue } from '../contexts/tags-context';
import { isEqual } from 'lodash';

const useTags = (): TagsContextValue => {
  const [tags, setTags] = useState<string[]>([]);
  const isMounted = useRef(false);

  const refreshTags = useCallback(async () => {
    try {
      const response = await getAllTags();
      const newTags = response.content;

      // Case in-senstive sort
      newTags.sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }));

      // Only update the tags if they have changed
      if (!isEqual(newTags, tags) && isMounted.current) {
        setTags(newTags);
      }
    } catch (e) {
      logExceptionIntoSentry(e);
    }
  }, [tags]);

  useEffect(() => {
    isMounted.current = true;
    refreshTags();
    return () => {
      isMounted.current = false;
    };
  }, [refreshTags]);

  return { tags, refreshTags };
};

export default useTags;
