import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { GetColor, Loading, ZIndex } from 'venn-ui-kit';
import Copyright from '../copyright';

const FixedContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${GetColor.Black};
`;

const AuthCopyright = styled(Copyright)`
  margin-top: 6px;
  text-align: center;
  color: ${GetColor.White};
`;

const AuthLoader = styled(Loading)`
  margin: 15px auto;
`;

const SplashVideo = styled.video`
  position: fixed;
  top: 50%;
  left: 50%;
  width: auto;
  min-width: 100%;
  height: auto;
  min-height: 100%;
  transform: translateX(-50%) translateY(-50%);
  background-size: cover;
  z-index: ${ZIndex.Background};
`;

const PageContainer = styled.div`
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
`;

const LoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const AuthLoading = () => {
  const { Videos } = useContext(ThemeContext);
  return (
    <FixedContainer>
      <SplashVideo autoPlay loop muted>
        <source src={Videos.splashScreenAnimation} type="video/mp4" />
      </SplashVideo>
      <PageContainer>
        <LoaderContainer>
          <AuthLoader title="Loading..." />
          <AuthCopyright lineBreak />
        </LoaderContainer>
      </PageContainer>
    </FixedContainer>
  );
};
