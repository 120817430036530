import React from 'react';
import { getAppTitle, GetColor, DEMO_PORTFOLIO_FAQ_HREF } from 'venn-ui-kit';
import styled from 'styled-components';
import type { AnalysisViewTypeEnum } from 'venn-api';
import { useRecentStudio } from 'venn-utils';
import StudioCard from './StudioCard';
import { ContentBlock } from '../content-block';
import { StyledLink } from './LearnMore';

interface StudioCardsProps {
  header: string;
  location: string;
}

const STUDIO_TEMPLATE: AnalysisViewTypeEnum[] = ['TEARSHEET_TEMPLATE'];
const StudioCards = ({ header, location }: StudioCardsProps) => {
  const { studioItems, isLoading } = useRecentStudio({ includeTypes: STUDIO_TEMPLATE, vennTemplate: true });

  if (!isLoading && !studioItems.length) {
    return null;
  }

  return (
    <PageContentBlock header={header}>
      <ContentWrapper>
        {studioItems.map((item) => (
          <StudioCard item={item} location={location} key={item.id} />
        ))}
      </ContentWrapper>
      <LegalCopyText>
        {`The Studio analysis examples may include a placeholder investment or the ${getAppTitle()}`}{' '}
        <StyledLink target="_blank" rel="noopener noreferrer" href={DEMO_PORTFOLIO_FAQ_HREF}>
          Demo Portfolio
        </StyledLink>
        for demonstration purposes only.
      </LegalCopyText>
    </PageContentBlock>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  > :not(:last-child) {
    border-right: 1px solid ${GetColor.GreyScale.Grey30};
  }
`;

const PageContentBlock = styled(ContentBlock)`
  background-color: ${GetColor.White};
  border: 1px solid ${GetColor.GreyScale.Grey30};
`;

const LegalCopyText = styled.div`
  font-style: italic;
  font-size: 10px;
  line-height: 1.4;
  color: ${GetColor.GreyScale.Grey80};
  background-color: ${GetColor.GreyScale.Grey10};
  padding: 10px 20px;
  border-top: 1px solid ${GetColor.GreyScale.Grey30};
`;

export default StudioCards;
