import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';

export interface ButtonDropdownItemProps {
  label: React.ReactNode;
  icon?: string;
}

const ButtonDropdownItemWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  white-space: nowrap;

  i {
    margin-left: 10px;
  }
`;

const ButtonDropdownItem: FC<React.PropsWithChildren<ButtonDropdownItemProps>> = ({ label, icon }) => (
  <ButtonDropdownItemWrapper>
    <span>{label}</span>
    {icon && (
      <span>
        <i className={icon} />
      </span>
    )}
  </ButtonDropdownItemWrapper>
);

export default ButtonDropdownItem;
