import React from 'react';
import styled from 'styled-components';
import type { DropMenuItem } from 'venn-ui-kit';
import { Icon, GetColor } from 'venn-ui-kit';
import type { BenchmarkCompositeChild, ScalingSign } from '../../state/compositeState';
import Weight from './BenchmarkWeight';
import Fund from './BenchmarkInvestment';
import BenchmarkScalingSign from './BenchmarkScalingSign';
import ScalingValue from './BenchmarkScalingValue';
import type { SearchMenuItem } from '../../../../search-menu';
import { fundToSearchMenuItem } from '../../../../search-menu';

export interface BenchmarkCompositeRowProps {
  data: BenchmarkCompositeChild;
  onChange(data: BenchmarkCompositeChild): void;
  onRemove?(data: BenchmarkCompositeChild): void;
}

export default class BenchmarkCompositeRow extends React.Component<BenchmarkCompositeRowProps> {
  onWeightChange = (value: string) =>
    this.props.onChange({
      ...this.props.data,
      weight: value,
    });

  onScalingSignChange = (value: DropMenuItem<ScalingSign>) =>
    this.props.onChange({
      ...this.props.data,
      scalingSign: value.value,
    });

  onScalingValueChange = (value: string) =>
    this.props.onChange({
      ...this.props.data,
      scalingValue: value,
    });

  onSelectInvestment = (select: SearchMenuItem) => {
    if (!select.value) {
      return;
    }
    this.props.onChange({
      ...this.props.data,
      // This prop should ideally be converted from `fund: AllocationTypeaheadOptions` to `item: SearchMenuItem`.
      // On the other hand, we should only have one benchmarks modal, not two (and the other one is the one to stay).
      // So for the time being, let's manually map `SearchMenuItem` type here to `AllocationTypeaheadOptions`.
      fund: {
        label: select.label,
        value: select.value.id,
        children: select.label,
        type: select.value.type,
        fund: select.value.fund,
      },
    });
  };

  onRemove = () => {
    if (this.props.onRemove) {
      this.props.onRemove(this.props.data);
    }
  };

  render() {
    const { scalingValue, scalingSign, weight, fund } = this.props.data;
    const defaultValue = fund?.fund ? fundToSearchMenuItem(fund.fund) : undefined;
    return (
      <BenchmarkWrapper>
        <Weight value={weight} onValueChange={this.onWeightChange} />
        <Fund defaultValue={defaultValue} onFundSelect={this.onSelectInvestment} />
        <BenchmarkScalingSign value={scalingSign} onChange={this.onScalingSignChange} />
        <ScalingValue value={scalingValue} onChange={this.onScalingValueChange} />
        {this.props.onRemove && (
          <RemoveFundWrapper onClick={this.onRemove}>
            <Icon type="minus-circle" />
          </RemoveFundWrapper>
        )}
      </BenchmarkWrapper>
    );
  }
}

const BenchmarkWrapper = styled.div`
  display: flex;
  align-items: left;
  margin: 6px 10px;

  input::placeholder {
    color: ${GetColor.LightGrey};
    opacity: 1;
  }
`;

const RemoveFundWrapper = styled.div`
  margin-top: 9px;
  cursor: pointer;
  color: ${GetColor.Black};
  &:hover {
    color: ${GetColor.Primary.Main};
  }
`;
