import React from 'react';
import ReviewPortfolioViewSubtitle from './components/page-parts/ReviewPortfolioViewSubtitle';
import type { PortfolioMultiUploaderView } from './types';
import {
  MULTI_PORTFOLIO_UPLOADER_FAQ_HREF,
  PORTFOLIO_CONSTRUCTION_FAQ_HREF,
  PRIVATE_ASSET_LAB_FAQ_HREF,
  PRIVATE_ASSET_UPLOADER_FAQ_HREF,
  RETURNS_UPLOADER_FAQ_HREF,
} from 'venn-ui-kit';
import { StyledFaqLink } from './views/shared/layout';

export const IChooseActionView = {
  id: 'CHOOSE_ACTION',
  title: 'Create and/or update portfolios and investments',
  description: undefined,
  highlightText: undefined,
  requiredPermissions: [],
  requiredFeatures: [],
} as const satisfies PortfolioMultiUploaderView;

export const IUploadReturnsView = {
  id: 'UPLOAD_RETURNS',
  title: 'Upload investment return data',
  subtitle: (
    <>
      Add or update data in your library of investments. Visit the <StyledFaqLink link={RETURNS_UPLOADER_FAQ_HREF} />{' '}
      for more information.
    </>
  ),
  description: 'Add new or update existing returns-based investment(s)',
  highlightText: undefined,
  requiredPermissions: ['UPLOAD_RETURNS'],
  requiredFeatures: [],
} as const satisfies PortfolioMultiUploaderView;

export const IReviewReturnsView = {
  id: 'REVIEW_RETURNS',
  title: 'Review and validate returns data',
  description: undefined,
  highlightText: undefined,
  requiredPermissions: ['UPLOAD_RETURNS'],
  requiredFeatures: [],
} as const satisfies PortfolioMultiUploaderView;

export const IUploadPrivatesView = {
  id: 'UPLOAD_PRIVATES',
  title: 'Upload private asset data',
  subtitle: (
    <>
      Add or update data in your library of investments. Visit the{' '}
      <StyledFaqLink link={PRIVATE_ASSET_UPLOADER_FAQ_HREF} /> for more information.
    </>
  ),
  description:
    'Add new or update existing private asset investment(s), including cash flows and other private asset metadata',
  highlightText: undefined,
  requiredPermissions: ['UPLOAD_RETURNS'],
  requiredFeatures: ['private_analytics'],
} as const satisfies PortfolioMultiUploaderView;

export const IReviewPrivatesView = {
  id: 'REVIEW_PRIVATES',
  title: 'Review and validate private asset data',
  description: undefined,
  highlightText: undefined,
  requiredPermissions: ['UPLOAD_RETURNS'],
  requiredFeatures: ['private_analytics'],
} as const satisfies PortfolioMultiUploaderView;

export const ICreateMasterPortfolioView = {
  id: 'CREATE_MASTER_PORTFOLIO',
  title: 'Create master pro forma portfolio',
  description: 'Pro forma portfolio using static allocations, used for returns-based analytics',
  highlightText: undefined,
  requiredPermissions: ['CREATE_PORTFOLIO'],
  requiredFeatures: [],
} as const satisfies PortfolioMultiUploaderView;

export const IChooseActionPortfolioView = {
  id: 'CHOOSE_ACTION_PORTFOLIO',
  title: 'Create or update pro forma portfolio(s) using Portfolio Builder',
  description: 'Pro forma portfolio using static allocations, used for returns-based analytics',
  highlightText: 'Now upload multiple portfolios from a single file!',
  requiredPermissions: ['CREATE_PORTFOLIO'],
  requiredFeatures: [],
} as const satisfies PortfolioMultiUploaderView;

export const IUploadPortfolioView = {
  id: 'UPLOAD_PORTFOLIO',
  title: 'Upload portfolio data using Portfolio Builder',
  subtitle: (
    <>
      Uploaded file can contain one or more portfolios, including strategy hierarchies and allocations. Visit the{' '}
      <StyledFaqLink link={MULTI_PORTFOLIO_UPLOADER_FAQ_HREF} /> for more information.
    </>
  ),
  description:
    'Update existing or create new portfolio(s) by uploading NAVs and strategy hierarchies from a spreadsheet.',
  highlightText: 'Upload multiple portfolios’ NAVs & strategy hierarchies in a single file!',
  requiredPermissions: ['CREATE_PORTFOLIO', 'EDIT_PORTFOLIO'],
  requiredFeatures: [],
} as const satisfies PortfolioMultiUploaderView;

export const IReviewPortfolioView = {
  id: 'REVIEW_PORTFOLIO',
  title: 'Preview and adjust portfolio data',
  subtitle: () => <ReviewPortfolioViewSubtitle />,
  description: undefined,
  highlightText: undefined,
  requiredPermissions: ['CREATE_PORTFOLIO', 'EDIT_PORTFOLIO'],
  requiredFeatures: [],
} as const satisfies PortfolioMultiUploaderView;

export const IPortfolioUploadConfirmationView = {
  id: 'PORTFOLIO_UPLOAD_CONFIRMATION',
  title: 'Portfolio data successfully uploaded',
  description: undefined,
  highlightText: undefined,
  requiredPermissions: ['CREATE_PORTFOLIO', 'EDIT_PORTFOLIO'],
  requiredFeatures: [],
} as const satisfies PortfolioMultiUploaderView;

const NewPortfolioSubtitle = () => {
  return (
    <>
      Give your portfolio a name. Visit the <StyledFaqLink link={PORTFOLIO_CONSTRUCTION_FAQ_HREF} /> for more
      information.
    </>
  );
};
export const IDuplicatePortfolioView = {
  id: 'DUPLICATE_PORTFOLIO',
  title: 'Duplicate an existing portfolio',
  subtitle: NewPortfolioSubtitle(),
  description: 'Copy existing portfolio as a starting point to edit further',
  highlightText: undefined,
  requiredPermissions: ['CREATE_PORTFOLIO'],
  requiredFeatures: [],
} as const satisfies PortfolioMultiUploaderView;

export const INewPortfolioView = {
  id: 'NEW_PORTFOLIO',
  title: 'Build a new portfolio in Analysis',
  subtitle: NewPortfolioSubtitle(),
  description: "Build your portfolio by adding investments from Venn's Data Library and assigning allocations",
  highlightText: undefined,
  requiredPermissions: ['CREATE_PORTFOLIO'],
  requiredFeatures: [],
} as const satisfies PortfolioMultiUploaderView;

export const ICreatePrivatePortfolioView = {
  id: 'CREATE_PRIVATE_PORTFOLIO',
  title: 'Create new private asset portfolio',
  subtitle: (
    <>
      Give your portfolio a name. Then, either select an existing portfolio template or create a new portfolio from
      scratch. Visit the <StyledFaqLink link={PRIVATE_ASSET_LAB_FAQ_HREF} /> for more information.
    </>
  ),
  description: 'Contains only private asset data and is used for private asset-specific analytics',
  highlightText: undefined,
  requiredPermissions: ['CREATE_PORTFOLIO'],
  requiredFeatures: ['private_analytics'],
} as const satisfies PortfolioMultiUploaderView;
