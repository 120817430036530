import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import type { SearchFund } from 'venn-api';
import { PortfolioRow as Row, PortfolioCell as Cell, PortfolioHeaderCell as HeaderCell } from '../../portfolio-rows';
import { Name, Returns, User } from '../../portfolio-rows/common';
import { CheckboxWrapper } from 'venn-ui-kit';
import { FundUtils } from 'venn-utils';

interface InvestmentsResultsTableProps {
  rows: SearchFund[];
  selected: SearchFund[];
  previouslySelected: SearchFund[];
  onSelectionToggle: (investment: SearchFund) => void;
  onSelectAllClick: (investments?: SearchFund[]) => void;
}
const tooltipsOn = 'with-tooltips';

const rowRenderer = (investment: SearchFund, checked: boolean, onChange: () => void) => (
  <>
    <Cell checkbox>
      <CheckboxWrapper checked={checked} onChange={onChange} />
    </Cell>
    <Cell>
      <Name className={tooltipsOn} name={investment.name} />
    </Cell>
    <Cell>
      <span>{FundUtils.getAssetTypeName(investment.assetType)}</span>
    </Cell>
    <Cell>
      <Returns start={investment.startRange} end={investment.endRange} />
    </Cell>
    <Cell>{investment.owner ? <User className={tooltipsOn} user={investment.owner} /> : <span />}</Cell>
    <Cell>
      <Updated updated={investment.updated} />
    </Cell>
  </>
);

const InvestmentsResultsTable = ({
  rows,
  selected,
  previouslySelected,
  onSelectionToggle,
  onSelectAllClick,
}: InvestmentsResultsTableProps) => {
  const nonPreviouslySelectedRows = rows.filter(
    (investment) =>
      previouslySelected.findIndex((selectedInvestment) => selectedInvestment.id === investment.id) === -1,
  );
  const isRowSelectedList = nonPreviouslySelectedRows.map((investment) => selected.includes(investment));
  const areAllSelected = isRowSelectedList.every((isSelected) => isSelected);
  const noContent = !rows.length && !previouslySelected.length;
  return (
    <InvestmentsTable>
      <colgroup>
        <col style={{ width: 30 }} />
        <col style={{ width: 227 }} />
        <col style={{ width: 135 }} />
        <col style={{ width: 180 }} />
        <col style={{ width: 100 }} />
        <col style={{ width: 90 }} />
      </colgroup>
      <thead>
        <Row>
          <HeaderCell checkbox>
            <CheckboxWrapper
              disabled={noContent}
              checked={!noContent && areAllSelected}
              onChange={() => onSelectAllClick(areAllSelected ? undefined : rows)}
            />
          </HeaderCell>
          <HeaderCell>Investment name</HeaderCell>
          <HeaderCell>Type</HeaderCell>
          <HeaderCell>Available Date Range</HeaderCell>
          <HeaderCell>Uploader</HeaderCell>
          <HeaderCell>Updated</HeaderCell>
        </Row>
      </thead>
      <tbody>
        {previouslySelected.map((investment) => (
          <PreviousSearchRow key={investment.id}>
            {rowRenderer(investment, true, () => onSelectionToggle(investment))}
          </PreviousSearchRow>
        ))}
        {nonPreviouslySelectedRows.map((investment, index) => (
          <Row key={investment.id}>
            {rowRenderer(investment, isRowSelectedList[index], () => onSelectionToggle(investment))}
          </Row>
        ))}
      </tbody>
    </InvestmentsTable>
  );
};
export default InvestmentsResultsTable;

const InvestmentsTable = styled.table`
  table-layout: fixed;
  width: 100%;
`;

const Updated: FC<React.PropsWithChildren<{ updated: number }>> = ({ updated }) =>
  updated ? <span>{moment(updated).format('DD/MM/YYYY')}</span> : <span>--</span>;

const PreviousSearchRow = styled(Row)`
  opacity: 0.5;
  font-style: italic;
`;
