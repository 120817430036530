import React from 'react';
import type { ExportsErrorTypeEnum } from 'venn-api';
import styled from 'styled-components';
import { Icon, GetColor, ColorUtils, Tooltip, TooltipBodyDirection } from 'venn-ui-kit';

interface WarningProps {
  exportsErrorType: ExportsErrorTypeEnum;
}

const Warning = ({ exportsErrorType }: WarningProps) => {
  if (!exportsErrorType) {
    return null;
  }
  const message =
    exportsErrorType === 'FAILED_EXPORT_EXCEL'
      ? 'Failed to run analysis on this view.'
      : exportsErrorType === 'FAILED_SENT_EMAILS'
        ? 'Failed to send emails. You can download exports directly from history.'
        : 'Last scheduled exports failed to send.';
  return (
    <Wrapper>
      <Tooltip bodyDirection={TooltipBodyDirection.Left} content={message}>
        <AlertIcon type="exclamation-triangle" />
      </Tooltip>
    </Wrapper>
  );
};

export default Warning;

const Wrapper = styled.div`
  display: inline-block;
`;

const AlertIcon = styled(Icon)`
  color: ${GetColor.Error};
  :hover {
    color: ${ColorUtils.opacifyDarkFrom(GetColor.Error, 0.5)};
  }
`;
