import React, { createContext, useContext, useMemo } from 'react';
import { constSelector, useRecoilValue } from 'recoil';
import { isReportState, reportZoom } from 'venn-state';
import { BLOCK_BORDER_WIDTH_PX } from '../common';

type BlockWidthData = {
  raw: {
    outerWidth: number;
    innerWidth: number;
  };
  transformed: {
    outerWidth: number;
    innerWidth: number;
  };
};

const BlockWidthContext = createContext<BlockWidthData | undefined>(undefined);
BlockWidthContext.displayName = 'BlockWidthContext';

export const useBlockWidth = () => {
  const blockWidth = useContext(BlockWidthContext);

  if (!blockWidth) {
    throw new Error('useBlockWidth must be used within a BlockWidthContextProvider');
  }

  return blockWidth;
};

const STUDIO_ZOOM_SELECTOR = constSelector(1);

export const BlockWidthContextProvider = ({
  blockWidthPx,
  children,
}: {
  blockWidthPx: number;
  children: React.ReactNode;
}) => {
  const isReport = useRecoilValue(isReportState);
  const zoom = useRecoilValue(isReport ? reportZoom : STUDIO_ZOOM_SELECTOR);

  // TODO(collin.irwin): add some system to detect errors at runtime between the width here and the width computed by Measure
  const result = useMemo(
    () => ({
      // Because the measurement provided to this provider is already affected by zoom, we only need to remove scaleFactor to get a true raw value.
      raw: {
        outerWidth: blockWidthPx,
        innerWidth: blockWidthPx - BLOCK_BORDER_WIDTH_PX * 2,
      },
      // Because the size we receive is already affected by scaleFactor, we only need to handle zoom.
      transformed: {
        outerWidth: blockWidthPx * zoom,
        innerWidth: (blockWidthPx - BLOCK_BORDER_WIDTH_PX * 2) * zoom,
      },
    }),
    [blockWidthPx, zoom],
  );

  return <BlockWidthContext.Provider value={result}>{children}</BlockWidthContext.Provider>;
};
