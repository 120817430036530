import { useState, useCallback } from 'react';

import { logExceptionIntoSentry } from 'venn-utils';
import type { LibrarySearchResult } from 'venn-api';
import { globalSearch } from 'venn-api';

import { mapSearchResultToAnalysisSubject, itemEqualityCheck } from './utils';
import type { SearchMenuItem } from './types';

const useTagsDropDown = ({
  results,
  onResults,
  onSelectAll,
  onMultiClear,
}: {
  results: SearchMenuItem[];
  onResults: (items: SearchMenuItem[], totalResults: number) => void;
  onSelectAll?: (selections: SearchMenuItem[]) => void;
  onMultiClear?: (item: SearchMenuItem[]) => void;
}) => {
  const [selectedTagDropdowns, setSelectedTagDropdowns] = useState<SearchMenuItem[]>([]);
  const [openedTags, setOpenedTags] = useState<SearchMenuItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchTags = useCallback(async (item: SearchMenuItem) => {
    let parsedTags;
    try {
      const response = await globalSearch({ tags: [item.label], pageSize: item.searchResult?.tagIds.length });
      const tagResults: LibrarySearchResult = response.content;

      parsedTags = mapSearchResultToAnalysisSubject({
        result: tagResults,
        isTagged: true,
        taggedWith: item.label,
      });
    } catch (error) {
      logExceptionIntoSentry(error);
    }
    return parsedTags || [];
  }, []);

  const checkIfTagsAreFetched = useCallback(
    (item: SearchMenuItem) => {
      const tagIndex = results.findIndex((result) => itemEqualityCheck(result, item));
      // item right after tag dropdown should belong to this tag dropdown
      const areTagsAlreadyFetched = results[tagIndex + 1]?.searchResult?.tags?.includes(item.label);

      return { tagIndex, areTagsAlreadyFetched };
    },
    [results],
  );

  const onTagsDropdown = useCallback(
    async (item: SearchMenuItem) => {
      if (isLoading) {
        return;
      }
      setIsLoading(true);
      setOpenedTags((prevTags) => [...prevTags, item]);

      const parsedTags = await fetchTags(item);

      const indexOfSelectedTag = results.findIndex((result: SearchMenuItem) => itemEqualityCheck(result, item));

      const newResults = [
        ...results.slice(0, indexOfSelectedTag + 1),
        ...parsedTags,
        ...results.slice(indexOfSelectedTag + 1),
      ];
      setIsLoading(false);
      onResults(newResults, newResults.length);
    },
    [isLoading, fetchTags, results, onResults],
  );

  const onTagsClose = useCallback(
    (item: SearchMenuItem) => {
      if (isLoading) {
        return;
      }

      const removeTaggedItems = results.filter((result) => result.taggedWith !== item.label);

      onResults(removeTaggedItems, removeTaggedItems.length);
      setOpenedTags((prevSelectedTags) =>
        prevSelectedTags.filter((prevSelectedTag) => !itemEqualityCheck(prevSelectedTag, item)),
      );
    },
    [isLoading, results, onResults],
  );

  const onUnselectAllTags = useCallback(() => {
    const filterOutAllTags = results.filter(
      (result) =>
        !selectedTagDropdowns.filter((selectedTagDropdown) =>
          result.searchResult?.tags?.includes(selectedTagDropdown.label),
        ).length,
    );

    setSelectedTagDropdowns([]);
    onResults(filterOutAllTags, filterOutAllTags.length);
  }, [onResults, results, selectedTagDropdowns]);

  const onBulkTagSelect = useCallback(
    async (item: SearchMenuItem) => {
      if (isLoading) {
        return;
      }
      setIsLoading(true);
      const { areTagsAlreadyFetched } = checkIfTagsAreFetched(item);
      if (areTagsAlreadyFetched) {
        const selectedTags = results.filter((result) => result.taggedWith === item.searchResult?.name);
        onSelectAll && onSelectAll(selectedTags);
        setSelectedTagDropdowns((prevTags) => [...prevTags, item]);
        setIsLoading(false);

        return;
      }

      setSelectedTagDropdowns((prevTags) => [...prevTags, item]);

      const parsedTags = await fetchTags(item);

      onSelectAll && onSelectAll(parsedTags);
      setIsLoading(false);
    },
    [checkIfTagsAreFetched, fetchTags, isLoading, onSelectAll, results],
  );

  const onBulkTagUnselect = useCallback(
    async (item: SearchMenuItem) => {
      if (isLoading) {
        return;
      }
      setIsLoading(true);
      const { areTagsAlreadyFetched } = checkIfTagsAreFetched(item);
      if (areTagsAlreadyFetched) {
        const unselectedTags = results.filter((result) => result.taggedWith === item.searchResult?.name);
        onMultiClear && onMultiClear(unselectedTags);
        setSelectedTagDropdowns((prevSelectedTags) =>
          prevSelectedTags.filter((prevSelectedTag) => !itemEqualityCheck(prevSelectedTag, item)),
        );
        setIsLoading(false);

        return;
      }

      setSelectedTagDropdowns((prevSelectedTags) =>
        prevSelectedTags.filter((prevSelectedTag) => !itemEqualityCheck(prevSelectedTag, item)),
      );

      const parsedTags = await fetchTags(item);

      onMultiClear && onMultiClear(parsedTags);
      setIsLoading(false);
    },
    [checkIfTagsAreFetched, fetchTags, isLoading, onMultiClear, results],
  );

  const onCloseAllTags = useCallback(() => setOpenedTags([]), []);

  return {
    selectedTagDropdowns,
    openedTags,
    onTagsDropdown,
    onTagsClose,
    setSelectedTagDropdowns,
    onUnselectAllTags,
    onBulkTagSelect,
    onBulkTagUnselect,
    onCloseAllTags,
    setOpenedTags,
  };
};

export default useTagsDropDown;
