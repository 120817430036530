import type { Benchmark } from 'venn-api';
import type { BenchmarksCompositeState, BenchmarkCompositeChild } from './compositeState';
import type { SearchMenuItem } from '../../../search-menu';

export type BenchmarkAction =
  | BenchmarkAddAction
  | BenchmarkAddFromSearchAction
  | BenchmarkAppendNewCompositeChildAction
  | BenchmarkCreateCompositeAction
  | BenchmarkDeleteAction
  | BenchmarkEditCompositeChildAction
  | BenchmarkLoadCompositeAction
  | BenchmarkLoadCompositeSuccessAction
  | BenchmarkRemoveCompositeChildAction
  | BenchmarkRenameCompositeAction
  | BenchmarkSetDefaultAction
  | BenchmarkSelectSearchResultAction
  | BenchmarkUpdateAction
  | ErrorAction
  | BenchmarkUINavigationAction;

export interface ErrorAction {
  type: 'Error';
}

export interface BenchmarkDeleteAction {
  type: 'BenchmarkDelete';
  payload: Benchmark;
}

export interface BenchmarkSetDefaultAction {
  type: 'BenchmarkSetDefault';
  payload?: Benchmark;
}

export interface BenchmarkAddAction {
  type: 'BenchmarkAdd';
  payload: {
    benchmark: Benchmark;
    isNewComposite: boolean;
  };
}

export interface BenchmarkUpdateAction {
  type: 'BenchmarkUpdate';
  payload: Benchmark;
}

export interface BenchmarkSelectSearchResultAction {
  type: 'BenchmarkSelectSearchResult';
  payload: SearchMenuItem;
}

export interface BenchmarkAddFromSearchAction {
  type: 'BenchmarkAddFromSearch';
  payload: SearchMenuItem;
}

export interface BenchmarkCreateCompositeAction {
  type: 'BenchmarkCreateComposite';
  payload: BenchmarksCompositeState;
}

export interface BenchmarkLoadCompositeAction {
  type: 'BenchmarkLoadComposite';
  payload: {
    fundId: string;
  };
}

export interface BenchmarkLoadCompositeSuccessAction {
  type: 'BenchmarkLoadCompositeSuccess';
  payload: BenchmarksCompositeState;
}

export interface BenchmarkEditCompositeChildAction {
  type: 'BenchmarkEditCompositeChild';
  payload: {
    previous: BenchmarkCompositeChild;
    updated: BenchmarkCompositeChild;
  };
}

export interface BenchmarkRemoveCompositeChildAction {
  type: 'BenchmarkRemoveCompositeChild';
  payload: BenchmarkCompositeChild;
}

export interface BenchmarkAppendNewCompositeChildAction {
  type: 'BenchmarkAppendNewCompositeChild';
}

export interface BenchmarkRenameCompositeAction {
  type: 'BenchmarkRenameComposite';
  payload: string;
}

export interface BenchmarkUINavigationAction {
  type: 'BenchmarkUINavigation';
  payload: {
    target: BenchmarkUINavigationActionTarget;
  };
}

type BenchmarkUINavigationActionTarget =
  | { kind: 'View'; viewId: BenchmarkUIView }
  | { kind: 'Exit'; persistChanges?: boolean };

export enum BenchmarkUIView {
  ListView = 'ListView',
  SearchView = 'SearchView',
  CompositeCreateView = 'CompositeCreateView',
  CompositeEditView = 'CompositeEditView',
}
