import React, { useContext, useState, useMemo, useEffect } from 'react';
import type { RangeType, DateRange } from 'venn-ui-kit';
import { DayPicker, getRangeFromType } from 'venn-ui-kit';
import { FactorLensesContext } from '../../../contexts';

type ForecastPanelDateEditorProps = {
  options?: RangeType[];
  dateRange: DateRange;
  layout?: 'left' | 'right';
  disabled?: boolean;
  /* rather the period represents a relative date (ie. the last 5 years) */
  relativeDatePeriod?: boolean;
  onApply: (value: DateRange) => void;
  onCancel: () => void;
};

const GRANULARITY = 'day';

export const ForecastPanelDateEditor = ({
  options,
  layout,
  relativeDatePeriod,
  dateRange,
  disabled,
  onApply,
  onCancel,
}: ForecastPanelDateEditorProps) => {
  const factorContext = useContext(FactorLensesContext);

  const maxRange = useMemo(
    () => ({
      from: factorContext.primaryFactorLens?.latestStartDate,
      to: factorContext.primaryFactorLens?.earliestEndDate,
    }),
    [factorContext.primaryFactorLens?.latestStartDate, factorContext.primaryFactorLens?.earliestEndDate],
  );

  const [period, setPeriod] = useState(dateRange.period);
  const [value, setValue] = useState<DateRange>(dateRange);

  const onUpdatePeriod = (range: RangeType) => {
    const newRange = getRangeFromType(range, maxRange, GRANULARITY);
    setPeriod(newRange.period);
    if (!relativeDatePeriod) {
      setValue({ from: newRange.from, to: newRange.to, period: newRange.period });
    }
  };

  const onUpdateDate = () => {
    setPeriod(undefined);
  };

  useEffect(() => {
    setValue(dateRange);
  }, [dateRange]);

  return (
    <DayPicker
      value={value}
      period={period}
      disabled={disabled}
      maxRange={maxRange}
      onApply={onApply}
      onUpdatePeriod={onUpdatePeriod}
      onUpdateDate={onUpdateDate}
      onCancel={onCancel}
      options={options}
      layout={layout}
    />
  );
};
