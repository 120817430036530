import React, { useState, useCallback, useRef } from 'react';
import styled from 'styled-components';
import Input from '../../input/Input';
import type { DropMenuItem } from 'venn-ui-kit';
import { Icon, IconPosition, GetColor, DropMenu } from 'venn-ui-kit';
import type { Portfolio } from 'venn-api';
import type { AnyDuringEslintMigration } from 'venn-utils';

export interface StrategyDropdownProps {
  options: DropMenuItem<Portfolio>[];
  selected?: Portfolio;
  className?: string;
  allowCreateNew?: boolean;
  placeholder?: string;
  onChange(selectedStrategy?: Portfolio): void;
  maxWidth?: number;
}

const StrategyDropdown: React.FC<React.PropsWithChildren<StrategyDropdownProps>> = ({
  options,
  selected,
  className,
  allowCreateNew,
  placeholder,
  onChange,
  maxWidth,
}) => {
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [hasCreatedNew, setHasCreatedNew] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const onFocus = useCallback((e) => {
    e.target?.select();
  }, []);

  const onBlur = useCallback(
    (e) => {
      const inputValue = e.target.value;
      const hasValue = inputValue !== undefined && inputValue !== null && inputValue !== '';
      setIsCreatingNew(false);
      setHasCreatedNew(hasValue);
      onChange(hasValue ? ({ name: e.target.value } as AnyDuringEslintMigration) : undefined);
    },
    [onChange],
  );

  const onClearInput = useCallback(() => {
    onChange(undefined);
    setIsCreatingNew(false);
    setHasCreatedNew(false);
    setIsOpen(true);
  }, [onChange]);

  const onSelect = useCallback(
    (option: DropMenuItem<Portfolio>) => {
      setIsCreatingNew(false);
      setHasCreatedNew(false);
      onChange(option.value);
    },
    [onChange],
  );

  const onCreateNew = useCallback(() => {
    setIsCreatingNew(true);
    setIsOpen(false);
  }, []);

  return (
    <StrategyDropdownWrapper maxWidth={maxWidth}>
      {isCreatingNew || hasCreatedNew ? (
        <CreateNewInput
          autoFocus
          setInputRef={inputRef}
          placeholder="Enter strategy name..."
          onFocus={onFocus}
          onBlur={onBlur}
          icon={
            <InputClearIcon onClick={onClearInput}>
              <Icon type="times" prefix="far" />
            </InputClearIcon>
          }
          iconPosition={IconPosition.Right}
        />
      ) : (
        <DropMenu
          openByDefault={isOpen}
          className={className}
          items={options}
          selected={selected}
          onChange={onSelect}
          placeholder={placeholder}
          width={maxWidth}
          link={allowCreateNew ? 'Create new strategy...' : undefined}
          onLinkClick={onCreateNew}
        />
      )}
    </StrategyDropdownWrapper>
  );
};

export default StrategyDropdown;

const StrategyDropdownWrapper = styled.div<{ maxWidth?: number }>`
  width: 100%;
  user-select: none;
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px;`}
`;

const CreateNewInput = styled(Input)`
  width: 100%;
`;

const InputClearIcon = styled.div`
  padding: 0 10px;
  color: ${GetColor.MidGrey2};
  &:hover {
    color: ${GetColor.DarkGrey};
  }
  cursor: pointer;
`;
