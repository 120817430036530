import React, { useCallback, useMemo, useEffect, useState } from 'react';
import styled from 'styled-components';
import type { Portfolio } from 'venn-api';
import { getUploadedSeriesForFunds } from 'venn-api';
import type { ExcelCell } from 'venn-utils';
import {
  analyticsService,
  convertMultipleSeriesDataToExcel,
  Dates,
  getPortfolioNavs,
  getFundsAndBenchmarksFromPortfolio,
  logExceptionIntoSentry,
} from 'venn-utils';
import { onExportExcel } from '../../downloadable/helper';
import type { OptionType } from 'venn-ui-kit';
import { ButtonIcon, TriggerableMenu, ZIndex } from 'venn-ui-kit';
import type { SplitButtonDropdownOption } from 'venn-components';
import SplitButtonDropdownItem from '../../split-button-dropdown/SplitButtonDropdownItem';
import { compact } from 'lodash';

interface PortfolioDownloadButtonProps {
  portfolio: Portfolio;
}

const PortfolioDownloadButton: React.FC<React.PropsWithChildren<PortfolioDownloadButtonProps>> = ({ portfolio }) => {
  const [returnsData, setReturns] = useState<ExcelCell[][]>();
  useEffect(() => {
    const fetchFundReturns = async () => {
      try {
        const funds = getFundsAndBenchmarksFromPortfolio(portfolio);
        const { content } = await getUploadedSeriesForFunds(funds);
        if (!content || content.length === 0) {
          return;
        }
        const data = convertMultipleSeriesDataToExcel(content);
        setReturns(data);
      } catch (e) {
        if (e.name === 'AbortError') {
          return;
        }
        logExceptionIntoSentry(e);
      }
    };
    fetchFundReturns();
  }, [portfolio]);

  const downloadNAVs = useCallback(() => {
    if (!portfolio) {
      return;
    }
    analyticsService.dataExported({
      objectType: 'portfolio',
      outputDescription: 'navs',
      outputFileType: 'xlsx',
      subjectId: String(portfolio.id),
      relativeToBenchmark: false,
      userUploaded: true,
    });

    onExportExcel(getPortfolioNavs(portfolio), `${portfolio.name} NAVs ${Dates.toYYYYMMDD(Number(new Date()), '-')}`);
  }, [portfolio]);

  const downloadReturns = useCallback(() => {
    if (returnsData) {
      analyticsService.dataExported({
        objectType: 'portfolio',
        subjectId: String(portfolio.id),
        outputDescription: 'returns',
        outputFileType: 'xlsx',
        relativeToBenchmark: false,
        userUploaded: true,
      });
      onExportExcel(returnsData, `${portfolio.name} Returns ${Dates.toYYYYMMDD(Number(new Date()), '-')}`);
    }
  }, [portfolio, returnsData]);

  const downloadOptions: OptionType[] = useMemo(() => {
    const options: SplitButtonDropdownOption[] = compact([
      {
        label: 'Download Investment Allocations',
        onClick: downloadNAVs,
      },
      returnsData && {
        label: 'Download Investment Returns',
        onClick: downloadReturns,
      },
    ]);

    return options.map((option: SplitButtonDropdownOption) => ({
      ...option,
      children: <SplitButtonDropdownItem label={option.label} onClick={() => option.onClick(option.label)} />,
    }));
  }, [downloadNAVs, downloadReturns, returnsData]);

  return (
    <StyledTriggerableMenu options={downloadOptions} innerClassName="download-menu">
      <ButtonIcon iconType="download" tooltip="Download this portfolio's allocations or returns." border />
    </StyledTriggerableMenu>
  );
};

const StyledTriggerableMenu = styled(TriggerableMenu)`
  .download-menu {
    right: 0;
    left: auto;
    z-index: ${ZIndex.Cover};
  }
`;

export default PortfolioDownloadButton;
