import { useMemo } from 'react';
import { compact } from 'lodash';
import { useRecoilValue } from 'recoil';
import { useBlockId } from '../contexts/BlockIdContext';
import { blockSettings, blockMetrics } from 'venn-state';

/** Returns the selected metric objects for a block. */
export const useSelectedMetrics = () => {
  const blockId = useBlockId();
  const blockSetting = useRecoilValue(blockSettings(blockId));
  const selectedMetricIds = useRecoilValue(blockMetrics(blockId));

  return useMemo(() => {
    return compact(selectedMetricIds.map((metricId) => blockSetting.metrics.find((metric) => metric.key === metricId)));
  }, [selectedMetricIds, blockSetting.metrics]);
};
