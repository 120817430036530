import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Hint, Button, Headline1, Icon, GetColor, getAppTitle, getTextThemeProvider } from 'venn-ui-kit';
import { analyticsService, assertNotNil, getAnalysisPathForInvestment, Routes } from 'venn-utils';
import { useHistory } from 'react-router-dom';
import { DataUploaderLauncher, UserContext } from 'venn-components';
import {
  TutorialAlternativeAction,
  TutorialAssetContainer,
  TutorialContainer,
  InfoIcon,
  InfoTooltip,
  INFO_MESSAGE,
  TutorialActions,
  Tutorial,
} from './TutorialContent';
import type { TabConfig } from './HomePageTabs';
import StartButton from './StartButton';

const MSCIFundId = 'f7ca915d-77d1-43bb-b7c5-b3872947434e';
const BloombergFundId = 'b82a7e07-c81d-4af9-a1aa-41405865b9a2';
const BaronFundId = '8f5533f2-4969-4289-a210-0a7f38221fda';

const Tutorial_0_0_TSFactors = () => {
  const { Images } = useContext(ThemeContext);
  const tutorial_0_0_imgsrc = assertNotNil(Images.factorBreakdown);
  const history = useHistory();
  return (
    <TutorialContainer>
      <Headline1>
        Discover the power of Two Sigma’s factor approach
        <InfoTooltip maxWidth={500} content={INFO_MESSAGE}>
          <InfoIcon prefix="fas" type="info-circle" />
        </InfoTooltip>
      </Headline1>
      <TutorialAssetContainer>
        <StyledImg key={tutorial_0_0_imgsrc} src={tutorial_0_0_imgsrc} alt="line chart" />
      </TutorialAssetContainer>
      <TutorialActions>
        <div>
          <Button
            dominant
            onClick={() => {
              history.push(getAnalysisPathForInvestment(MSCIFundId, 'welcome'));
              analyticsService.ctaClicked({
                destination: 'Analysis Results',
                filled: true,
                locationOnPage: 'Home / Onboarding tab 0.0',
                purpose: 'Discover Factor Analysis',
                text: 'See it in action',
                type: 'button',
              });
            }}
          >
            See it in action
          </Button>
        </div>
        <Hint>
          Or check out the{' '}
          <button
            type="button"
            className="qa-tutorial-0-0-factor-education"
            onClick={() => {
              history.push(Routes.FACTOR_LENS_HOMEPAGE);
              analyticsService.ctaClicked({
                destination: 'Factor Lens Homepage',
                filled: false,
                locationOnPage: 'Home / Onboarding tab 0.0',
                purpose: 'Discover Factor Lens Education',
                text: 'See it in action',
                type: 'link',
              });
            }}
          >
            <strong>Two Sigma Factor Lens Education</strong>
          </button>{' '}
          to learn more.
        </Hint>
      </TutorialActions>
    </TutorialContainer>
  );
};

const Tutorial_0_1_BloombergCredit = () => {
  const { Images } = useContext(ThemeContext);
  return (
    <Tutorial
      headline="See how much risk the Credit factor drives in the Bloomberg Global High Yield Index"
      fundId={BloombergFundId}
      images={[assertNotNil(Images.credit)]}
      hasInfoMessage
      analyticsLocation="Home / Onboarding tab 0.1"
    />
  );
};

const Tutorial_0_2_MSCIFactor = () => {
  const { Images } = useContext(ThemeContext);
  return (
    <Tutorial
      headline="Explore a risk factor breakdown of the MSCI ACWI Index"
      fundId={MSCIFundId}
      images={[assertNotNil(Images.factorBreakdown)]}
      hasInfoMessage
      analyticsLocation="Home / Onboarding tab 0.2"
    />
  );
};

const Tutorial_0_3_BaronRisk = () => {
  const { Images } = useContext(ThemeContext);
  return (
    <Tutorial
      headline="Evaluate residual risk and return in the Baron Global Advantage Institutional Fund"
      fundId={BaronFundId}
      images={[assertNotNil(Images.riskReturn)]}
      hasInfoMessage
      analyticsLocation="Home / Onboarding tab 0.3"
    />
  );
};

export const Tutorial_0_4_WhatsNext = () => {
  const { hasPermission } = useContext(UserContext);
  const canUploadReturns = hasPermission('UPLOAD_RETURNS');
  const hasAccessToCompare = hasPermission('COMPARE');
  const history = useHistory();
  return (
    <TutorialContainer>
      <Headline1>
        {`Start getting the most out of ${getAppTitle()}`}
        <InfoTooltip maxWidth={500} content={INFO_MESSAGE}>
          <InfoIcon prefix="fas" type="info-circle" />
        </InfoTooltip>
      </Headline1>
      <TutorialAssetContainer hideBorder>
        <DataUploaderLauncher
          analyticsProps={{
            locationOnPage: 'Upload your data CTA',
            text: 'Upload your data',
            type: 'CTA',
          }}
        >
          {(onLaunchDataUploader) => (
            <StartButton
              step={1}
              title="Upload your data"
              icon="upload"
              onClick={onLaunchDataUploader}
              disabled={!canUploadReturns}
            />
          )}
        </DataUploaderLauncher>
        <StartButton
          step={2}
          title="Run an analysis"
          icon="calculator"
          onClick={() => {
            history.push(Routes.ANALYSIS_PATH);
            analyticsService.navigationTriggered({
              location: 'homepage - onboarding - 0.4',
              itemType: 'button',
              userIntent: 'navigate',
              destinationPageTitle: 'Analysis',
            });
          }}
        />
        <StartButton
          step={3}
          title="Compare investments"
          icon="line-columns"
          onClick={() => {
            history.push(Routes.ANALYSIS_COMPARE_PATH);
            analyticsService.navigationTriggered({
              location: 'homepage - onboarding - 0.4',
              itemType: 'button',
              userIntent: 'navigate',
              destinationPageTitle: 'Comparison',
            });
          }}
          disabled={!hasAccessToCompare}
        />
      </TutorialAssetContainer>

      <TutorialAlternativeAction>
        <HintContainer>
          <HintIcon type="comment-alt" />
          <Hint>
            Still have questions?{' '}
            <a href={`mailto:${getTextThemeProvider().supportEmail}`}>
              <strong>{`Contact the ${getAppTitle()} Team`}</strong>
            </a>{' '}
            for help
          </Hint>
        </HintContainer>
        <HintContainer>
          <HintIcon type="question-circle" />
          <Hint>Click the question mark on the top navigation to see recent product updates and releases</Hint>
        </HintContainer>
      </TutorialAlternativeAction>
    </TutorialContainer>
  );
};

const onboardingTabs: TabConfig[] = [
  {
    stepNumber: '0.0',
    title: 'New to factors?',
    Component: Tutorial_0_0_TSFactors,
  },
  {
    stepNumber: '0.1',
    title: 'Are asset classes obfuscating your risk?',
    fundId: BloombergFundId,
    Component: Tutorial_0_1_BloombergCredit,
  },
  {
    stepNumber: '0.2',
    title: 'What factors are really inside the MSCI ACWI?',
    fundId: MSCIFundId,
    Component: Tutorial_0_2_MSCIFactor,
  },
  {
    stepNumber: '0.3',
    title: 'How much of your risk do factors explain?',
    fundId: BaronFundId,
    Component: Tutorial_0_3_BaronRisk,
  },
  {
    stepNumber: '0.4',
    title: "What's next?",
    Component: Tutorial_0_4_WhatsNext,
  },
];

export default onboardingTabs;

const HintIcon = styled(Icon)`
  color: ${GetColor.Black};
`;

const StyledImg = styled.img`
  width: 100%;
`;

const HintContainer = styled.div`
  line-height: 24px;
  margin-top: 6px;
  i {
    font-size: 18px;
    margin-right: 8px;
  }
`;
