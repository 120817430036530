import React, { useContext, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ThemeContext, ThemeProvider } from 'styled-components';
import { isReportState } from 'venn-state';
import type { Theme } from 'venn-ui-kit';
import { assertNotNil, parseValueFromLocation, useDebugValue } from 'venn-utils';

/** Extends a parent {@link Theme} with print mode information. */
export const AppPrintModeProvider = ({ children }: { children: React.ReactNode }) => {
  const printMode = usePrintModeInternal();

  const theme = assertNotNil(useContext(ThemeContext));
  const themeWithPrintMode: Theme = useMemo(
    () => ({
      ...theme,
      ...printMode,
    }),
    [printMode, theme],
  );

  return <ThemeProvider theme={themeWithPrintMode}>{children}</ThemeProvider>;
};

/**
 * Hook for accessing the print mode state from the Theme.
 *
 * For recoil usage, see {@link isPrintingState}.
 */
export const useAppPrintMode = () => {
  const theme = useContext(ThemeContext);

  assertNotNil(theme, 'useAppPrintMode must be used within a ThemeProvider');
  assertNotNil(theme.inPrintMode, 'useAppPrintMode must be used within a ThemeProvider');

  return useMemo(
    () => ({
      inPrintMode: theme.inPrintMode,
      inPrintModeOrReportLab: theme.inPrintModeOrReportLab,
    }),
    [theme.inPrintMode, theme.inPrintModeOrReportLab],
  );
};

const printMediaQuery = { query: 'print' };
/**
 * Custom hook for determining the print mode based on media queries and location.
 *
 * Subcribes to changes in media queries or or location and will cause rerenders as a result.
 * Prefer using {@link useAppPrintMode} which utilizes a context to prevent unnecessary rerenders.
 */
function usePrintModeInternal() {
  const location = useLocation();
  const isReport = useRecoilValue(isReportState);

  const isDebugPrint = useDebugValue('debugPrint') ?? false;
  const inPrintMode =
    useMediaQuery(printMediaQuery) || parseValueFromLocation(location, 'print') === 'true' || isDebugPrint;

  const inPrintModeOrReportLab = inPrintMode || isReport;
  return useMemo(
    () => ({
      inPrintMode,
      inPrintModeOrReportLab,
    }),
    [inPrintMode, inPrintModeOrReportLab],
  );
}
