import React, { Component } from 'react';
import styled from 'styled-components';
import type { Benchmark } from 'venn-api';
import { createCompositeBenchmark, getCompositeBenchmarks, getFund, updateCompositeBenchmark } from 'venn-api';
import type { AnalysisSubjectType } from 'venn-utils';
import type { BenchmarkAction } from './state/benchmarkActions';
import { BenchmarkUIView } from './state/benchmarkActions';
import type { BenchmarksState } from './state/benchmarksState';
import { benchmarksReducer } from './state/benchmarksState';

import type { BenchmarksCompositeState } from './state/compositeState';
import { createPayload, createState, DEFAULT_COMPOSITE_CHILD } from './state/compositeState';
import BenchmarksLayout from './views/BenchmarksLayout';
import BenchmarksHeader from './views/BenchmarksHeader';
import BenchmarksListView from './views/listView/BenchmarksListView';
import BenchmarksSearchView from './views/searchView/BenchmarksSearchView';
import BenchmarksCompositeView from './views/compositeView/BenchmarksCompositeView';
import { ModalHeader } from '../../modal';
import { Notifications, NotificationType } from 'venn-ui-kit';

export interface BenchmarksProps {
  ownerName: string;
  benchmarks: Benchmark[];
  benchmarksLoading?: boolean;
  compositeBenchmark?: BenchmarksCompositeState;
  header?: string;
  onlyShowComposite?: boolean;
  onlyShowEditComposite?: boolean;
  onSubmit(benchmarks?: Benchmark[]): void;
  onBeforeAction?(action: BenchmarkAction): void;
  onCancel(): void;
  // Used to force extra top padding
  topPadding?: boolean;
  benchmarkContext?: AnalysisSubjectType;
}

export interface BenchmarksComponentState {
  model: BenchmarksState;
  prevProps?: BenchmarksProps;
}

const getViewId = (props: BenchmarksProps, state: BenchmarksComponentState): BenchmarkUIView => {
  if (props.onlyShowComposite) {
    return BenchmarkUIView.CompositeCreateView;
  }
  if (props.onlyShowEditComposite) {
    return BenchmarkUIView.CompositeEditView;
  }
  return state.model.viewId;
};

export class Benchmarks extends Component<BenchmarksProps, BenchmarksComponentState> {
  state: BenchmarksComponentState = {
    model: {
      viewId: BenchmarkUIView.ListView,
      benchmarks: [],
      search: null,
      composite: {
        name: '',
        children: [DEFAULT_COMPOSITE_CHILD],
      },
      busy: false,
    },
  };

  static getDerivedStateFromProps(
    props: BenchmarksProps,
    state: BenchmarksComponentState,
  ): Partial<BenchmarksComponentState> | null {
    if (state.prevProps && state.prevProps.benchmarks === props.benchmarks) {
      return null;
    }

    const composite = props.compositeBenchmark;

    if (composite) {
      if (state.prevProps && state.prevProps.compositeBenchmark === composite) {
        return null;
      }

      return {
        model: {
          ...state.model,
          viewId: getViewId(props, state),
          composite,
        },
        prevProps: props,
      };
    }

    return {
      model: {
        ...state.model,
        viewId: getViewId(props, state),
        benchmarks: props.benchmarks,
      },
      prevProps: props,
    };
  }

  handleAction = (action: BenchmarkAction) => {
    const actionToHandle = this.doSideEffectBeforeReduce(action);
    this.setState(
      (state) => ({
        model: benchmarksReducer(state.model, actionToHandle),
      }),
      () => this.doSideEffectAfterReduce(action),
    );
  };

  onExit = (persistChanges: boolean | undefined) => {
    if (!persistChanges || this.state.model.benchmarks === this.props.benchmarks) {
      this.props.onCancel();
    } else {
      this.props.onSubmit(this.state.model.benchmarks);
    }
  };

  renderHeader = (): string => {
    if (this.props.header) {
      return this.props.header;
    }

    if (this.state.model.viewId === BenchmarkUIView.ListView) {
      return 'Configure Benchmarks';
    }

    return 'Add New Benchmark';
  };

  render() {
    const { model } = this.state;
    const { benchmarksLoading, onlyShowComposite, onlyShowEditComposite, topPadding, benchmarkContext } = this.props;
    return (
      <BenchmarksLayout
        viewId={model.viewId}
        header={
          model.viewId === BenchmarkUIView.CompositeEditView ? (
            <ModalHeader>{model.composite.existingComposite!.originalName}</ModalHeader>
          ) : (
            <HeaderContainer topPadding={model.viewId === BenchmarkUIView.ListView || !!topPadding}>
              <ModalHeader>{this.renderHeader()}</ModalHeader>
              <BenchmarksHeader>
                {this.props.ownerName && (
                  <h2>
                    Currently configuring: <strong>{this.props.ownerName}</strong>
                  </h2>
                )}
              </BenchmarksHeader>
            </HeaderContainer>
          )
        }
        busy={model.busy || benchmarksLoading}
        onUINavigationAction={this.handleAction}
        hidePrevLink={onlyShowComposite || onlyShowEditComposite}
      >
        {(viewId) => {
          switch (viewId) {
            case BenchmarkUIView.ListView:
              return <BenchmarksListView benchmarks={model.benchmarks} onAction={this.handleAction} />;
            case BenchmarkUIView.SearchView:
              return (
                <BenchmarksSearchView
                  active={model.search || undefined}
                  exclude={model.benchmarks}
                  onAction={this.handleAction}
                  investmentsOnly={
                    benchmarkContext === 'investment' ||
                    benchmarkContext === 'private-investment' ||
                    benchmarkContext === 'private-portfolio'
                  }
                />
              );
            case BenchmarkUIView.CompositeCreateView:
            case BenchmarkUIView.CompositeEditView:
              return (
                <BenchmarksCompositeView
                  active={model.composite}
                  isNew={viewId === BenchmarkUIView.CompositeCreateView}
                  onAction={this.handleAction}
                  selfExit={
                    this.props.onlyShowComposite || this.props.onlyShowEditComposite ? this.props.onCancel : undefined
                  }
                />
              );
            default:
              return null;
          }
        }}
      </BenchmarksLayout>
    );
  }

  private doSideEffectBeforeReduce(action: BenchmarkAction) {
    if (this.props.onBeforeAction) {
      this.props.onBeforeAction(action);
    }
    // uncomment this line to get action tracking in your dev console:
    // console.log(action.type, { action });
    return action;
  }

  private doSideEffectAfterReduce(action: BenchmarkAction) {
    switch (action.type) {
      case 'BenchmarkCreateComposite':
        if (this.state.model.composite.existingComposite) {
          this.updateComposite();
        } else {
          this.createComposite();
        }
        return;
      case 'BenchmarkLoadComposite':
        this.loadComposite(action.payload.fundId);
        return;
      case 'BenchmarkUINavigation':
        if (action.payload.target.kind === 'Exit') {
          this.onExit(action.payload.target.persistChanges);
        }
    }
  }

  private async createComposite() {
    try {
      const compositeBenchmark = (await createCompositeBenchmark(createPayload(this.state.model.composite))).content;
      if (this.props.onlyShowComposite) {
        this.props.onSubmit();
        return;
      }
      Notifications.notify(`${compositeBenchmark.name} added successfully.`, NotificationType.SUCCESS);
      this.handleAction({
        type: 'BenchmarkAdd',
        payload: {
          benchmark: {
            fundId: compositeBenchmark.fundId,
            name: compositeBenchmark.name,
            primary: false,
            type: 'COMPOSITE',
          },
          isNewComposite: true,
        },
      });
    } catch (errorPromise) {
      const error = (await errorPromise).content;
      Notifications.notify((error && error.message) || 'Error creating composite benchmark.', NotificationType.ERROR);
      this.handleAction({ type: 'Error' });
    }
  }

  private async updateComposite() {
    try {
      await updateCompositeBenchmark(
        this.state.model.composite.existingComposite!.id,
        createPayload(this.state.model.composite),
      );

      // For case when we want composite update to trigger modal close
      if (this.props.onlyShowEditComposite) {
        this.props.onSubmit(this.state.model.benchmarks);
      }
      const { benchmarks, composite } = this.state.model;
      Notifications.notify(`${composite.name} updated successfully.`, NotificationType.SUCCESS);
      this.handleAction({
        type: 'BenchmarkUpdate',
        payload: {
          fundId: composite.existingComposite!.fundId,
          name: composite.name,
          primary: benchmarks.some(
            (benchmark: Benchmark) =>
              benchmark.fundId && benchmark.fundId === composite.existingComposite?.fundId && benchmark.primary,
          ),
          type: 'COMPOSITE',
        },
      });
    } catch (errorPromise) {
      const error = (await errorPromise).content;
      Notifications.notify((error && error.message) || 'Error updating composite benchmark.', NotificationType.ERROR);
      this.handleAction({ type: 'Error' });
    }
  }

  private async loadComposite(id: string) {
    try {
      const compositeBenchmarks = (await getCompositeBenchmarks()).content;
      const compositeBenchmark = compositeBenchmarks.find((benchmark) => benchmark.fundId === id);
      if (compositeBenchmark) {
        const loadFunds = compositeBenchmark.children.map((child) => getFund(child.fundId));
        const funds = (await Promise.all(loadFunds)).map((response) => response.content);

        this.handleAction({
          type: 'BenchmarkLoadCompositeSuccess',
          payload: createState(compositeBenchmark, funds),
        });
      } else {
        Notifications.notify('Error loading composite benchmark.', NotificationType.ERROR);
        this.handleAction({ type: 'Error' });
      }
    } catch (errorPromise) {
      const error = (await errorPromise).content;
      Notifications.notify((error && error.message) || 'Error loading composite benchmark.', NotificationType.ERROR);
      this.handleAction({ type: 'Error' });
    }
  }
}

export default Benchmarks;

const HeaderContainer = styled.div<{ topPadding: boolean }>`
  ${({ topPadding }) => topPadding && 'padding-top: 20px;'}
`;
