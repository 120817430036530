import React from 'react';
import { GetColor } from 'venn-ui-kit';
import moment from 'moment';
import { formatValue } from '../utils';
import { TOOLTIP_WIDTH, RISK_FREE_RATE_ID, TOTAL_ID } from '../constants';
import { Numbers } from 'venn-utils';
import type { AnalysisDetail, ToolTipContent } from './types';
import styled from 'styled-components';
import { isNil } from 'lodash';

const PADDING = 10;
const LINE_OFFSET = 17;
const BACKGROUND_COLOR = 'rgba(16, 22, 27, 0.9)';
const DATE_FORMAT = 'MMMM YYYY';

const formatTimestamp = (value: number): string => moment.utc(value).format(DATE_FORMAT);

const MAX_NAME_CHARS = 25;

/** Manually wrap the text for the name
 * Done as SVG's do not support text wrapping */
const nameFormat = (name: string | undefined) => {
  if (!name) return '';
  if (name.length < MAX_NAME_CHARS) return name;
  return `${name.slice(0, MAX_NAME_CHARS - 3).trimEnd()}...`;
};

const valueFormat = (value: number, percentage: boolean) => {
  const forMatFunc = Numbers.createNumberFormatter({
    forceSign: false,
    percentage,
  });
  return forMatFunc(value);
};

const getHeight = (details: AnalysisDetail[], timestampHeight: number): number =>
  (details.length + 1) * LINE_OFFSET + PADDING * 3 + timestampHeight;

interface Props {
  x: number;
  y: number;
  width?: number;
  percentage?: boolean;
  content?: ToolTipContent;
}

const RowTooltip = ({ x, y, content, width: propWidth, percentage = false }: Props) => {
  if (!x || !y || !content || content.details.length < 1) {
    return <g />;
  }
  const width = propWidth || TOOLTIP_WIDTH;
  const hideTstat = content.factorId === TOTAL_ID || content.factorId === RISK_FREE_RATE_ID;
  const TIMESTAMP_OFFSET = content.timestamp ? 25 : 0;
  const height = getHeight(content.details, TIMESTAMP_OFFSET);
  return (
    <TooltipBox>
      <g transform={`translate(${x - width / 2},${y - height})`}>
        <rect x={0} y={0} width={width} height={height} fill={BACKGROUND_COLOR} />
        <g transform={`translate(${PADDING}, ${LINE_OFFSET + PADDING})`}>
          {content.timestamp && (
            <StyledText x={0} y={0} fontWeight="bold" fontSize="14">
              {formatTimestamp(content.timestamp)}
            </StyledText>
          )}
          <StyledText x={0} y={TIMESTAMP_OFFSET} fontWeight="bold" fontSize="12">
            {content.rowName}
          </StyledText>
          {content.details.map((item, index) => (
            <StyledText
              x={0}
              y={LINE_OFFSET + index * LINE_OFFSET + TIMESTAMP_OFFSET}
              fontSize="14"
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            >
              {nameFormat(item.portfolioName)}
              {' : '}
              {isNil(item.value) ? (
                <tspan fontStyle="italic">Excluded</tspan>
              ) : (
                <>
                  <tspan fontWeight="bold">{valueFormat(item.value, percentage)}</tspan>
                  {!hideTstat && (
                    <>
                      {' '}
                      <tspan fontStyle="italic">(T-Stat: {formatValue(item.tvalue)}</tspan>)
                    </>
                  )}
                </>
              )}
            </StyledText>
          ))}
        </g>
      </g>
    </TooltipBox>
  );
};

const TooltipBox = styled.g`
  pointer-events: none;
  transform: translateY(0);
  @media print {
    display: none;
  }
`;

const StyledText = styled.text`
  font-family: ${(props) => props.theme.Typography.fontFamily};
  text-anchor: start;
  fill: ${GetColor.White};
`;

export default RowTooltip;
