import mockPortfolio from '../portfolio';
import type { HoldingsRequestSubject } from 'venn-state';

export const assetExposureBlockSetting = {
  customBlockType: 'ASSET_EXPOSURE' as const,
  active: true,
  accessFeatures: [],
  contributionToPercentage: false,
  defaultMetrics: [],
  description: 'description',
  hasFactors: false,
  id: 'asset_exposure_base_id',
  metrics: [],
  supportedGraphicTypes: ['GRID' as const, 'PIE' as const],
  relativeToBenchmark: false,
  title: 'Asset Class',
  updated: 0,
};

export const sectorExposureBlockSetting = {
  customBlockType: 'SECTOR_EXPOSURE' as const,
  active: true,
  accessFeatures: [],
  contributionToPercentage: false,
  defaultMetrics: [],
  description: 'description',
  hasFactors: false,
  id: 'sector_exposure_base_id',
  metrics: [],
  supportedGraphicTypes: ['GRID' as const, 'PIE' as const],
  relativeToBenchmark: false,
  title: 'Sector',
  updated: 0,
};

export const geographyExposureBlockSetting = {
  customBlockType: 'GEOGRAPHY_EXPOSURE' as const,
  active: true,
  accessFeatures: [],
  contributionToPercentage: false,
  defaultMetrics: [],
  description: 'description',
  hasFactors: false,
  id: 'geography_exposure_base_id',
  metrics: [],
  supportedGraphicTypes: ['GRID' as const, 'MAP' as const],
  relativeToBenchmark: false,
  title: 'Geography',
  updated: 0,
};

export const mockHoldingsSubject: HoldingsRequestSubject = {
  id: String(mockPortfolio.id),
  name: mockPortfolio.name,
  subjectType: 'PORTFOLIO' as const,
  portfolio: mockPortfolio,
  isBenchmark: false,
  feesMapping: { [String(mockPortfolio.id)]: 0.1 },
  private: false,
};

/** example analysis subject */
export const mockSubject = {
  id: '51542d24-03ba-4f39-bfd6-57a8be5fd986',
  name: 'Pictet-Global Equities Dvrs Alpha I EUR',
  fundSourceType: {
    id: 1,
    name: 'Investment',
  },
  startRange: 1468627199999,
  endRange: 1683158399999,
  returnFrequency: 'DAILY',
  created: 1680648005378,
  updated: 1683618461047,
  createdById: -42,
  updatedById: -42,
  currency: 'EUR',
  domicile: 'Luxembourg',
  netExpenseRatio: 0.12,
  userUploaded: false,
  assetType: 'MUTUAL_FUND',
  remoteType: 'DATA',
  primaryShareClass: false,
  symbol: 'PGDAIAE',
  category: 'NOT_SUPPORTED_CATEGORY',
  categoryAssetType: {
    key: 'MUTUAL_FUND',
    name: 'Mutual Fund',
  },
  categoryGroup: {
    assetType: 'MUTUAL_FUND',
    key: 'MF_',
    name: 'Multistrategy',
    categoryId: '352407ac-194d-4a08-9368-4b55d92631fe',
  },
  active: true,
  live: true,
  owner: {
    id: -42,
    name: 'Venn',
    displayName: 'Venn',
    email: null,
    active: false,
    created: null,
    pwdLastUpdated: null,
    avatarId: null,
    actualUser: false,
    accountId: null,
  },
  updatedBy: {
    id: -42,
    name: 'Venn',
    displayName: 'Venn',
    email: null,
    active: false,
    created: null,
    pwdLastUpdated: null,
    avatarId: null,
    actualUser: false,
    accountId: null,
  },
  frequency: 1,
  unproxiedFrequency: 'DAILY',
  investmentBenchmarks: [],
  favorite: false,
  viewed: false,
  unproxiedNumReturns: 1774,
  needsProxy: false,
  needsInterpolation: false,
  hasMinDataForInterpolation: true,
  outOfDate: false,
  proxyable: true,
  interpolatable: false,
  userEditable: false,
  investmentSource: 'VENN',
};
// all asset categories
export const mockAllHoldingsAssetCategories = [
  {
    id: {
      id: '1265',
    },
    categoryName: 'US Stock',
    children: [],
  },
  {
    id: {
      id: '1266',
    },
    categoryName: 'Non-US Stock',
    children: [],
  },
  {
    id: {
      id: '1267',
    },
    categoryName: 'US Fixed Income',
    children: [],
  },
  {
    id: {
      id: '1268',
    },
    categoryName: 'Non-US Fixed Income',
    children: [],
  },
  {
    id: {
      id: '1269',
    },
    categoryName: 'Preferred',
    children: [],
  },
  {
    id: {
      id: '1270',
    },
    categoryName: 'Convertible',
    children: [],
  },
  {
    id: {
      id: '1271',
    },
    categoryName: 'Cash',
    children: [],
  },
  {
    id: {
      id: '1272',
    },
    categoryName: 'Other',
    children: [],
  },
  {
    id: {
      id: 'UNKNOWN',
    },
    categoryName: 'Unknown',
    children: [],
  },
];
// example selected asset categories - all ones without US Fixed Income
export const mockSelectedActiveCategories = [
  {
    id: {
      id: '1265',
    },
    categoryName: 'US Stock',
    children: [],
  },
  {
    id: {
      id: '1266',
    },
    categoryName: 'Non-US Stock',
    children: [],
  },
  {
    id: {
      id: '1268',
    },
    categoryName: 'Non-US Fixed Income',
    children: [],
  },
  {
    id: {
      id: '1269',
    },
    categoryName: 'Preferred',
    children: [],
  },
  {
    id: {
      id: '1270',
    },
    categoryName: 'Convertible',
    children: [],
  },
  {
    id: {
      id: '1271',
    },
    categoryName: 'Cash',
    children: [],
  },
  {
    id: {
      id: '1272',
    },
    categoryName: 'Other',
    children: [],
  },
  {
    id: {
      id: 'UNKNOWN',
    },
    categoryName: 'Unknown',
    children: [],
  },
];

// example data for asset categories
export const mockHoldingsAssetCategoriesData = {
  1265: {
    level: 0,
    name: 'US Stock',
    color: '#DE9BC0',
    parentName: '',
    id: '1265',
  },
  1266: {
    level: 0,
    name: 'Non-US Stock',
    color: '#DE9BC0',
    parentName: '',
    id: '1266',
  },
  1267: {
    level: 0,
    name: 'US Fixed Income',
    color: '#DE9BC0',
    parentName: '',
    id: '1267',
  },
  1268: {
    level: 0,
    name: 'Non-US Fixed Income',
    color: '#DE9BC0',
    parentName: '',
    id: '1268',
  },
  1269: {
    level: 0,
    name: 'Preferred',
    color: '#DE9BC0',
    parentName: '',
    id: '1269',
  },
  1270: {
    level: 0,
    name: 'Convertible',
    color: '#DE9BC0',
    parentName: '',
    id: '1270',
  },
  1271: {
    level: 0,
    name: 'Cash',
    color: '#DE9BC0',
    parentName: '',
    id: '1271',
  },
  1272: {
    level: 0,
    name: 'Other',
    color: '#DE9BC0',
    parentName: '',
    id: '1272',
  },
  UNKNOWN: {
    level: 0,
    name: 'Unknown',
    color: '#DE9BC0',
    parentName: '',
    id: 'UNKNOWN',
  },
};
// example holdings data for asset class
export const mockHoldingsData = {
  asOfDate: 1675209599999,
  breakdowns: [
    {
      asOfDate: 1675209599999,
      holdingsContributions: [
        {
          id: {
            id: '1268',
          },
          allocation: -0.053099999999999994,
        },
        {
          id: {
            id: '1267',
          },
          allocation: 0.1231,
        },
        {
          id: {
            id: '1266',
          },
          allocation: 0.3514,
        },
        {
          id: {
            id: '1265',
          },
          allocation: -0.026099999999999998,
        },
        {
          id: {
            id: '1272',
          },
          allocation: -0.0374,
        },
        {
          id: {
            id: '1271',
          },
          allocation: 0.6389,
        },
        {
          id: {
            id: '1270',
          },
          allocation: 0.0032,
        },
      ],
    },
  ],
  error: undefined,
};

export const mockSelectedSectorCategories = [
  {
    id: {
      id: '796',
    },
    categoryName: 'Equity',
    children: [],
  },
  {
    id: {
      id: '797',
    },
    categoryName: 'Basic Materials',
    children: [],
  },
  {
    id: {
      id: '798',
    },
    categoryName: 'Agriculture Group',
    children: [],
  },
  {
    id: {
      id: 'UNKNOWN',
    },
    categoryName: 'Unknown',
    children: [],
  },
];

export const mockHoldingsSectorData = {
  asOfDate: 1675209599999,
  breakdowns: [
    {
      asOfDate: 1675209599999,
      holdingsContributions: [
        {
          id: {
            id: '796',
          },
          allocation: -0.053099999999999994,
        },
        {
          id: {
            id: '797',
          },
          allocation: 0.1231,
        },
        {
          id: {
            id: '798',
          },
          allocation: 0.3514,
        },
        {
          id: {
            id: '799',
          },
          allocation: -0.026099999999999998,
        },
        {
          id: {
            id: '800',
          },
          allocation: -0.0374,
        },
        {
          id: {
            id: '801',
          },
          allocation: 0.6389,
        },
        {
          id: {
            id: 'UNKNOWN',
          },
          allocation: 0.0032,
        },
      ],
    },
  ],
  error: undefined,
};

export const mockHoldingsSectorCategories = {
  796: { color: '#BBBDBF', level: 1, parentName: 'EQUITY', name: 'Cyclical', parentId: 'EQUITY', id: '796' },
  797: { color: '#33C08F', level: 2, parentName: 'Cyclical', name: 'Basic Materials', parentId: '796', id: '797' },
  798: {
    color: '#BBBDBF',
    level: 3,
    parentName: 'Basic Materials',
    name: 'Agriculture Group',
    parentId: '797',
    id: '798',
  },
  799: {
    color: '#BBBDBF',
    level: 4,
    parentName: 'Agricultural Group',
    name: 'Agricultural Inputs',
    parentId: '798',
    id: '799',
  },
  800: {
    color: '#BBBDBF',
    level: 3,
    parentName: 'Basic Materials',
    name: 'Building Materials Group',
    parentId: '797',
    id: '800',
  },
  801: {
    color: '#BBBDBF',
    level: 4,
    parentName: 'Building Materials Group',
    name: 'Building Materials',
    parentId: '800',
    id: '801',
  },
  802: {
    color: '#BBBDBF',
    level: 3,
    parentName: 'Basic Materials',
    name: 'Chemicals Group',
    parentId: '797',
    id: '802',
  },
  803: { color: '#BBBDBF', level: 4, parentName: 'Chemicals Group', name: 'Chemicals', parentId: '802', id: '803' },
  804: {
    color: '#BBBDBF',
    level: 4,
    parentName: 'Chemicals Group',
    name: 'Specialty Chemicals',
    parentId: '802',
    id: '804',
  },
  805: {
    color: '#BBBDBF',
    level: 3,
    parentName: 'Basic Materials',
    name: 'Forest Products Group',
    parentId: '797',
    id: '805',
  },
  806: {
    color: '#BBBDBF',
    level: 4,
    parentName: 'Forest Products Group',
    name: 'Lumber & Wood Production',
    parentId: '805',
    id: '806',
  },
  807: {
    color: '#BBBDBF',
    level: 4,
    parentName: 'Forest Products Group',
    name: 'Paper & Paper Products',
    parentId: '805',
    id: '807',
  },
  808: {
    color: '#BBBDBF',
    level: 3,
    parentName: 'Basic Materials',
    name: 'Metals & Mining Group',
    parentId: '797',
    id: '808',
  },
  809: {
    color: '#BBBDBF',
    level: 4,
    parentName: 'Metals & Mining Group',
    name: 'Aluminum',
    parentId: '808',
    id: '809',
  },
  810: { color: '#BBBDBF', level: 4, parentName: 'Metals & Mining Group', name: 'Copper', parentId: '808', id: '810' },
  811: {
    color: '#BBBDBF',
    level: 4,
    parentName: 'Metals & Mining Group',
    name: 'Other Industrial Metals & Mining',
    parentId: '808',
    id: '811',
  },
  812: { color: '#BBBDBF', level: 4, parentName: 'Metals & Mining Group', name: 'Gold', parentId: '808', id: '812' },
  EQUITY: { color: '#BBBDBF', level: 0, parentName: '', name: 'Equity', id: 'EQUITY' },
  unknown: { color: '#BBBDBF', level: 0, parentName: '', name: 'Not Classified', id: 'unknown' },
  UNKNOWN: { color: '#BBBDBF', level: 1, parentName: 'unknown', name: 'Unknown', id: 'UNKNOWN', parentId: 'unknown' },
};

export const mockHoldingsSectorTree = [
  {
    id: { id: 'EQUITY' },
    categoryName: 'Equity',
    children: [
      {
        id: { id: '796' },
        categoryName: 'Cyclical',
        children: [
          {
            id: { id: '797' },
            categoryName: 'Basic Materials',
            children: [
              {
                id: { id: '798' },
                categoryName: 'Agriculture Group',
                children: [{ id: { id: '799' }, categoryName: 'Agricultural Inputs', children: [] }],
              },
              {
                id: { id: '800' },
                categoryName: 'Building Materials Group',
                children: [{ id: { id: '801' }, categoryName: 'Building Materials', children: [] }],
              },
              {
                id: { id: '802' },
                categoryName: 'Chemicals Group',
                children: [
                  { id: { id: '803' }, categoryName: 'Chemicals', children: [] },
                  { id: { id: '804' }, categoryName: 'Specialty Chemicals', children: [] },
                ],
              },
              {
                id: { id: '805' },
                categoryName: 'Forest Products Group',
                children: [
                  { id: { id: '806' }, categoryName: 'Lumber & Wood Production', children: [] },
                  { id: { id: '807' }, categoryName: 'Paper & Paper Products', children: [] },
                ],
              },
              {
                id: { id: '808' },
                categoryName: 'Metals & Mining Group',
                children: [
                  { id: { id: '809' }, categoryName: 'Aluminum', children: [] },
                  { id: { id: '810' }, categoryName: 'Copper', children: [] },
                  { id: { id: '811' }, categoryName: 'Other Industrial Metals & Mining', children: [] },
                  { id: { id: '812' }, categoryName: 'Gold', children: [] },
                  { id: { id: '813' }, categoryName: 'Silver', children: [] },
                  { id: { id: '814' }, categoryName: 'Other Precious Metals & Mining', children: [] },
                ],
              },
              {
                id: { id: '815' },
                categoryName: 'Steel Group',
                children: [
                  { id: { id: '816' }, categoryName: 'Coking Coal', children: [] },
                  { id: { id: '817' }, categoryName: 'Steel', children: [] },
                ],
              },
            ],
          },
          {
            id: { id: '818' },
            categoryName: 'Consumer Cyclical',
            children: [
              {
                id: { id: '819' },
                categoryName: 'Vehicles & Parts Group',
                children: [
                  { id: { id: '820' }, categoryName: 'Auto & Truck Dealerships', children: [] },
                  { id: { id: '821' }, categoryName: 'Auto Manufacturers', children: [] },
                  { id: { id: '822' }, categoryName: 'Auto Parts', children: [] },
                  { id: { id: '823' }, categoryName: 'Recreational Vehicles', children: [] },
                ],
              },
              {
                id: { id: '824' },
                categoryName: 'Furnishings, Fixtures & Appliances Group',
                children: [
                  {
                    id: { id: '825' },
                    categoryName: 'Furnishings, Fixtures & Appliances',
                    children: [],
                  },
                ],
              },
              {
                id: { id: '826' },
                categoryName: 'Homebuilding & Construction Group',
                children: [
                  {
                    id: { id: '827' },
                    categoryName: 'Residential Construction',
                    children: [],
                  },
                ],
              },
              {
                id: { id: '828' },
                categoryName: 'Manufacturing—Apparel & Accessories Group',
                children: [
                  { id: { id: '829' }, categoryName: 'Textile Manufacturing', children: [] },
                  { id: { id: '830' }, categoryName: 'Apparel Manufacturing', children: [] },
                  { id: { id: '831' }, categoryName: 'Footwear & Accessories', children: [] },
                ],
              },
              {
                id: { id: '832' },
                categoryName: 'Packaging & Containers Group',
                children: [{ id: { id: '833' }, categoryName: 'Packaging & Containers', children: [] }],
              },
              {
                id: { id: '834' },
                categoryName: 'Personal Services Group',
                children: [{ id: { id: '835' }, categoryName: 'Personal Services', children: [] }],
              },
              {
                id: { id: '836' },
                categoryName: 'Restaurants Group',
                children: [{ id: { id: '837' }, categoryName: 'Restaurants', children: [] }],
              },
              {
                id: { id: '838' },
                categoryName: 'Retail—Cyclical Group',
                children: [
                  { id: { id: '839' }, categoryName: 'Apparel Retail', children: [] },
                  { id: { id: '840' }, categoryName: 'Department Stores', children: [] },
                  { id: { id: '841' }, categoryName: 'Home Improvement Retail', children: [] },
                  { id: { id: '842' }, categoryName: 'Luxury Goods', children: [] },
                  { id: { id: '843' }, categoryName: 'Internet Retail', children: [] },
                  { id: { id: '844' }, categoryName: 'Specialty Retail', children: [] },
                ],
              },
              {
                id: { id: '845' },
                categoryName: 'Travel & Leisure Group',
                children: [
                  { id: { id: '846' }, categoryName: 'Gambling', children: [] },
                  { id: { id: '847' }, categoryName: 'Leisure', children: [] },
                  { id: { id: '848' }, categoryName: 'Lodging', children: [] },
                  { id: { id: '849' }, categoryName: 'Resorts & Casinos', children: [] },
                  { id: { id: '850' }, categoryName: 'Travel Services', children: [] },
                ],
              },
            ],
          },
          {
            id: { id: '851' },
            categoryName: 'Financial Services',
            children: [
              {
                id: { id: '852' },
                categoryName: 'Asset Management Group',
                children: [{ id: { id: '853' }, categoryName: 'Asset Management', children: [] }],
              },
              {
                id: { id: '854' },
                categoryName: 'Banks Group',
                children: [
                  { id: { id: '855' }, categoryName: 'Banks—Diversified', children: [] },
                  { id: { id: '856' }, categoryName: 'Banks—Regional', children: [] },
                  { id: { id: '857' }, categoryName: 'Mortgage Finance', children: [] },
                ],
              },
              {
                id: { id: '858' },
                categoryName: 'Capital Markets Group',
                children: [
                  { id: { id: '859' }, categoryName: 'Capital Markets', children: [] },
                  { id: { id: '860' }, categoryName: 'Financial Data & Stock Exchanges', children: [] },
                ],
              },
              {
                id: { id: '861' },
                categoryName: 'Insurance Group',
                children: [
                  { id: { id: '862' }, categoryName: 'Insurance—Life', children: [] },
                  { id: { id: '863' }, categoryName: 'Insurance—Property & Casualty', children: [] },
                  { id: { id: '864' }, categoryName: 'Insurance—Reinsurance', children: [] },
                  { id: { id: '865' }, categoryName: 'Insurance—Specialty', children: [] },
                  { id: { id: '866' }, categoryName: 'Insurance Brokers', children: [] },
                  { id: { id: '867' }, categoryName: 'Insurance—Diversified', children: [] },
                ],
              },
              {
                id: { id: '868' },
                categoryName: 'Diversified Financial Services Group',
                children: [
                  { id: { id: '869' }, categoryName: 'Shell Companies', children: [] },
                  { id: { id: '870' }, categoryName: 'Financial Conglomerates', children: [] },
                ],
              },
              {
                id: { id: '871' },
                categoryName: 'Credit Services Group',
                children: [{ id: { id: '872' }, categoryName: 'Credit Services', children: [] }],
              },
            ],
          },
          {
            id: { id: '873' },
            categoryName: 'Real Estate',
            children: [
              {
                id: { id: '874' },
                categoryName: 'Real Estate Group',
                children: [
                  { id: { id: '875' }, categoryName: 'Real Estate—Development', children: [] },
                  { id: { id: '876' }, categoryName: 'Real Estate Services', children: [] },
                  { id: { id: '877' }, categoryName: 'Real Estate—Diversified', children: [] },
                ],
              },
              {
                id: { id: '878' },
                categoryName: 'REITs Group',
                children: [
                  { id: { id: '879' }, categoryName: 'REIT—Healthcare Facilities', children: [] },
                  { id: { id: '880' }, categoryName: 'REIT—Hotel & Motel', children: [] },
                  { id: { id: '881' }, categoryName: 'REIT—Industrial', children: [] },
                  { id: { id: '882' }, categoryName: 'REIT—Office', children: [] },
                  { id: { id: '883' }, categoryName: 'REIT—Residential', children: [] },
                  { id: { id: '884' }, categoryName: 'REIT—Retail', children: [] },
                  { id: { id: '885' }, categoryName: 'REIT—Mortgage', children: [] },
                  { id: { id: '886' }, categoryName: 'REIT—Specialty', children: [] },
                  { id: { id: '887' }, categoryName: 'REIT—Diversified', children: [] },
                ],
              },
            ],
          },
        ],
      },
      {
        id: { id: '888' },
        categoryName: 'Defensive',
        children: [
          {
            id: { id: '889' },
            categoryName: 'Consumer Defensive',
            children: [
              {
                id: { id: '890' },
                categoryName: 'Beverages—Alcoholic Group',
                children: [
                  { id: { id: '891' }, categoryName: 'Beverages—Brewers', children: [] },
                  {
                    id: { id: '892' },
                    categoryName: 'Beverages—Wineries & Distilleries',
                    children: [],
                  },
                ],
              },
              {
                id: { id: '893' },
                categoryName: 'Beverages—Non-Alcoholic Group',
                children: [
                  {
                    id: { id: '894' },
                    categoryName: 'Beverages—Non-Alcoholic',
                    children: [],
                  },
                ],
              },
              {
                id: { id: '895' },
                categoryName: 'Consumer Packaged Goods Group',
                children: [
                  { id: { id: '896' }, categoryName: 'Confectioners', children: [] },
                  { id: { id: '897' }, categoryName: 'Farm Products', children: [] },
                  { id: { id: '898' }, categoryName: 'Household & Personal Products', children: [] },
                  { id: { id: '899' }, categoryName: 'Packaged Foods', children: [] },
                ],
              },
              {
                id: { id: '900' },
                categoryName: 'Education Group',
                children: [
                  {
                    id: { id: '901' },
                    categoryName: 'Education & Training Services',
                    children: [],
                  },
                ],
              },
              {
                id: { id: '902' },
                categoryName: 'Retail—Defensive Group',
                children: [
                  { id: { id: '903' }, categoryName: 'Discount Stores', children: [] },
                  { id: { id: '904' }, categoryName: 'Food Distribution', children: [] },
                  { id: { id: '905' }, categoryName: 'Grocery Stores', children: [] },
                ],
              },
              {
                id: { id: '906' },
                categoryName: 'Tobacco Products Group',
                children: [{ id: { id: '907' }, categoryName: 'Tobacco', children: [] }],
              },
            ],
          },
          {
            id: { id: '908' },
            categoryName: 'Healthcare',
            children: [
              {
                id: { id: '909' },
                categoryName: 'Biotechnology Group',
                children: [{ id: { id: '910' }, categoryName: 'Biotechnology', children: [] }],
              },
              {
                id: { id: '911' },
                categoryName: 'Drug Manufacturers Group',
                children: [
                  { id: { id: '912' }, categoryName: 'Drug Manufacturers—General', children: [] },
                  {
                    id: { id: '913' },
                    categoryName: 'Drug Manufacturers—Specialty & Generic',
                    children: [],
                  },
                ],
              },
              {
                id: { id: '914' },
                categoryName: 'Healthcare Plans Group',
                children: [{ id: { id: '915' }, categoryName: 'Healthcare Plans', children: [] }],
              },
              {
                id: { id: '916' },
                categoryName: 'Healthcare Providers & Services Group',
                children: [
                  { id: { id: '917' }, categoryName: 'Medical Care Facilities', children: [] },
                  { id: { id: '918' }, categoryName: 'Pharmaceutical Retailers', children: [] },
                  { id: { id: '919' }, categoryName: 'Health Information Services', children: [] },
                ],
              },
              {
                id: { id: '920' },
                categoryName: 'Medical Devices & Instruments Group',
                children: [
                  { id: { id: '921' }, categoryName: 'Medical Devices', children: [] },
                  { id: { id: '922' }, categoryName: 'Medical Instruments & Supplies', children: [] },
                ],
              },
              {
                id: { id: '923' },
                categoryName: 'Medical Diagnostics & Research Group',
                children: [{ id: { id: '924' }, categoryName: 'Diagnostics & Research', children: [] }],
              },
              {
                id: { id: '925' },
                categoryName: 'Medical Distribution Group',
                children: [{ id: { id: '926' }, categoryName: 'Medical Distribution', children: [] }],
              },
            ],
          },
          {
            id: { id: '927' },
            categoryName: 'Utilities',
            children: [
              {
                id: { id: '928' },
                categoryName: 'Utilities—Independent Power Producers Group',
                children: [
                  {
                    id: { id: '929' },
                    categoryName: 'Utilities—Independent Power Producers',
                    children: [],
                  },
                  { id: { id: '930' }, categoryName: 'Utilities—Renewable', children: [] },
                ],
              },
              {
                id: { id: '931' },
                categoryName: 'Utilities—Regulated Group',
                children: [
                  { id: { id: '932' }, categoryName: 'Utilities—Regulated Water', children: [] },
                  { id: { id: '933' }, categoryName: 'Utilities—Regulated Electric', children: [] },
                  { id: { id: '934' }, categoryName: 'Utilities—Regulated Gas', children: [] },
                  { id: { id: '935' }, categoryName: 'Utilities—Diversified', children: [] },
                ],
              },
            ],
          },
        ],
      },
      {
        id: { id: '936' },
        categoryName: 'Sensitive',
        children: [
          {
            id: { id: '937' },
            categoryName: 'Communication Services',
            children: [
              {
                id: { id: '938' },
                categoryName: 'Telecommunication Services Group',
                children: [{ id: { id: '939' }, categoryName: 'Telecom Services', children: [] }],
              },
              {
                id: { id: '940' },
                categoryName: 'Media—Diversified Group',
                children: [
                  { id: { id: '941' }, categoryName: 'Advertising Agencies', children: [] },
                  { id: { id: '942' }, categoryName: 'Publishing', children: [] },
                  { id: { id: '943' }, categoryName: 'Broadcasting', children: [] },
                  { id: { id: '944' }, categoryName: 'Entertainment', children: [] },
                ],
              },
              {
                id: { id: '945' },
                categoryName: 'Interactive Media Group',
                children: [
                  { id: { id: '946' }, categoryName: 'Internet Content & Information', children: [] },
                  { id: { id: '947' }, categoryName: 'Electronic Gaming & Multimedia', children: [] },
                ],
              },
            ],
          },
          {
            id: { id: '948' },
            categoryName: 'Energy',
            children: [
              {
                id: { id: '949' },
                categoryName: 'Oil & Gas Group',
                children: [
                  { id: { id: '950' }, categoryName: 'Oil & Gas Drilling', children: [] },
                  { id: { id: '951' }, categoryName: 'Oil & Gas E&P', children: [] },
                  { id: { id: '952' }, categoryName: 'Oil & Gas Integrated', children: [] },
                  { id: { id: '953' }, categoryName: 'Oil & Gas Midstream', children: [] },
                  { id: { id: '954' }, categoryName: 'Oil & Gas Refining & Marketing', children: [] },
                  { id: { id: '955' }, categoryName: 'Oil & Gas Equipment & Services', children: [] },
                ],
              },
              {
                id: { id: '956' },
                categoryName: 'Other Energy Sources Group',
                children: [
                  { id: { id: '957' }, categoryName: 'Thermal Coal', children: [] },
                  { id: { id: '958' }, categoryName: 'Uranium', children: [] },
                ],
              },
            ],
          },
          {
            id: { id: '959' },
            categoryName: 'Industrials',
            children: [
              {
                id: { id: '960' },
                categoryName: 'Aerospace & Defense Group',
                children: [{ id: { id: '961' }, categoryName: 'Aerospace & Defense', children: [] }],
              },
              {
                id: { id: '962' },
                categoryName: 'Business Services Group',
                children: [
                  { id: { id: '963' }, categoryName: 'Specialty Business Services', children: [] },
                  { id: { id: '964' }, categoryName: 'Consulting Services', children: [] },
                  { id: { id: '965' }, categoryName: 'Rental & Leasing Services', children: [] },
                  { id: { id: '966' }, categoryName: 'Security & Protection Services', children: [] },
                  { id: { id: '967' }, categoryName: 'Staffing & Employment Services', children: [] },
                ],
              },
              {
                id: { id: '968' },
                categoryName: 'Conglomerates Group',
                children: [{ id: { id: '969' }, categoryName: 'Conglomerates', children: [] }],
              },
              {
                id: { id: '970' },
                categoryName: 'Construction Group',
                children: [
                  { id: { id: '971' }, categoryName: 'Engineering & Construction', children: [] },
                  { id: { id: '972' }, categoryName: 'Infrastructure Operations', children: [] },
                  { id: { id: '973' }, categoryName: 'Building Products & Equipment', children: [] },
                ],
              },
              {
                id: { id: '974' },
                categoryName: 'Farm & Heavy Construction Machinery Group',
                children: [
                  {
                    id: { id: '975' },
                    categoryName: 'Farm & Heavy Construction Machinery',
                    children: [],
                  },
                ],
              },
              {
                id: { id: '976' },
                categoryName: 'Industrial Distribution Group',
                children: [
                  {
                    id: { id: '977' },
                    categoryName: 'Industrial Distribution',
                    children: [],
                  },
                ],
              },
              {
                id: { id: '978' },
                categoryName: 'Industrial Products Group',
                children: [
                  { id: { id: '979' }, categoryName: 'Business Equipment & Supplies', children: [] },
                  { id: { id: '980' }, categoryName: 'Specialty Industrial Machinery', children: [] },
                  { id: { id: '981' }, categoryName: 'Metal Fabrication', children: [] },
                  { id: { id: '982' }, categoryName: 'Pollution & Treatment Controls', children: [] },
                  { id: { id: '983' }, categoryName: 'Tools & Accessories', children: [] },
                  { id: { id: '984' }, categoryName: 'Electrical Equipment & Parts', children: [] },
                ],
              },
              {
                id: { id: '985' },
                categoryName: 'Transportation Group',
                children: [
                  { id: { id: '986' }, categoryName: 'Airports & Air Services', children: [] },
                  { id: { id: '987' }, categoryName: 'Airlines', children: [] },
                  { id: { id: '988' }, categoryName: 'Railroads', children: [] },
                  { id: { id: '989' }, categoryName: 'Marine Shipping', children: [] },
                  { id: { id: '990' }, categoryName: 'Trucking', children: [] },
                  { id: { id: '991' }, categoryName: 'Integrated Freight & Logistics', children: [] },
                ],
              },
              {
                id: { id: '992' },
                categoryName: 'Waste Management Group',
                children: [{ id: { id: '993' }, categoryName: 'Waste Management', children: [] }],
              },
            ],
          },
          {
            id: { id: '994' },
            categoryName: 'Technology',
            children: [
              {
                id: { id: '995' },
                categoryName: 'Software Group',
                children: [
                  { id: { id: '996' }, categoryName: 'Information Technology Services', children: [] },
                  { id: { id: '997' }, categoryName: 'Software—Application', children: [] },
                  { id: { id: '998' }, categoryName: 'Software—Infrastructure', children: [] },
                ],
              },
              {
                id: { id: '999' },
                categoryName: 'Hardware Group',
                children: [
                  { id: { id: '1000' }, categoryName: 'Communication Equipment', children: [] },
                  { id: { id: '1001' }, categoryName: 'Computer Hardware', children: [] },
                  { id: { id: '1002' }, categoryName: 'Consumer Electronics', children: [] },
                  { id: { id: '1003' }, categoryName: 'Electronic Components', children: [] },
                  {
                    id: { id: '1004' },
                    categoryName: 'Electronics & Computer Distribution',
                    children: [],
                  },
                  {
                    id: { id: '1005' },
                    categoryName: 'Scientific & Technical Instruments',
                    children: [],
                  },
                ],
              },
              {
                id: { id: '1006' },
                categoryName: 'Semiconductors Group',
                children: [
                  {
                    id: { id: '1007' },
                    categoryName: 'Semiconductor Equipment & Materials',
                    children: [],
                  },
                  { id: { id: '1008' }, categoryName: 'Semiconductors', children: [] },
                  { id: { id: '1009' }, categoryName: 'Solar', children: [] },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: { id: 'BOND' },
    categoryName: 'Fixed Income',
    children: [
      { id: { id: '1313' }, categoryName: 'Corporate Bond', children: [] },
      {
        id: { id: '1315' },
        categoryName: 'Securitized',
        children: [
          { id: { id: '1316' }, categoryName: 'Agency Mortgage Backed', children: [] },
          { id: { id: '1317' }, categoryName: 'Non Agency Residential Mortgage Backed', children: [] },
          { id: { id: '1318' }, categoryName: 'Commercial Mortgage Backed', children: [] },
          { id: { id: '1319' }, categoryName: 'Covered Bond', children: [] },
          { id: { id: '1320' }, categoryName: 'Asset Backed', children: [] },
        ],
      },
      { id: { id: '1321' }, categoryName: 'Cash And Equivalents', children: [] },
      {
        id: { id: '1322' },
        categoryName: 'Derivative',
        children: [
          { id: { id: '1323' }, categoryName: 'Swap', children: [] },
          { id: { id: '1324' }, categoryName: 'Future Forward', children: [] },
          { id: { id: '1325' }, categoryName: 'Option Warrant', children: [] },
        ],
      },
      { id: { id: '1305' }, categoryName: 'Government', children: [] },
      { id: { id: '1306' }, categoryName: 'Government Related', children: [] },
      {
        id: { id: '1307' },
        categoryName: 'Municipal',
        children: [
          { id: { id: '1308' }, categoryName: 'Municipal Taxable', children: [] },
          { id: { id: '1309' }, categoryName: 'US Municipal Tax Advantaged', children: [] },
        ],
      },
      {
        id: { id: '1310' },
        categoryName: 'Corporate',
        children: [
          { id: { id: '1312' }, categoryName: 'Convertible', children: [] },
          { id: { id: '1314' }, categoryName: 'Preferred', children: [] },
          { id: { id: '1311' }, categoryName: 'Bank Loan', children: [] },
        ],
      },
    ],
  },
  {
    id: { id: 'unknown' },
    categoryName: 'Not Classified',
    children: [{ id: { id: 'UNKNOWN' }, categoryName: 'Unknown', children: [] }],
  },
];
