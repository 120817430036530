import { useEffect } from 'react';
import Highstock from 'highcharts/highstock';

const INITIAL_TIMEOUT = 100;

/**
 * Hook that redraws all highcharts charts on the page when any given dependencies change
 * Note that we initially wait 100 ms before the redraw. Although react has rerendered by the time the the useeffect is
 * called, the browser usually has not redrawn in time in my testing.
 * @param dependencies - any dependencies that the charts should be redrawn on their change
 */
const useRedrawCharts = (...dependencies: unknown[]) => {
  useEffect(() => {
    window.setTimeout(
      () =>
        window.requestAnimationFrame(() => {
          Highstock.charts.forEach((chart) => chart?.reflow());
        }),
      INITIAL_TIMEOUT,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};

export default useRedrawCharts;
