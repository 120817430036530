import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import type {
  ScenarioAnalysis,
  FactorWithNonSerializedReturns as FactorEntity,
  PerformanceAttribution,
} from 'venn-api';
import { Numbers } from 'venn-utils';
import { ContributionPopover, FactorLensesContext, RESIDUAL_ID } from 'venn-components';
import { ColorUtils, GetColor } from 'venn-ui-kit';
import compact from 'lodash/compact';

const { safeFormatPercentage } = Numbers;
const formatPercentage = (value: number) => safeFormatPercentage(value, 1);

const HoverCellBackgroundColor = ColorUtils.hex2rgbaFrom(GetColor.Primary.Main, 0.1);

interface ScenarioPredictCellProps {
  relative: boolean;
  subjectName: string | undefined;
  scenarioAnalysis: ScenarioAnalysis | undefined;
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default ({ relative, scenarioAnalysis, subjectName }: ScenarioPredictCellProps) => {
  const [popover, setPopover] = useState<boolean>();
  const { primaryFactorLens } = useContext(FactorLensesContext);
  const hidePopover = () => setPopover(false);
  const showPopover = () => setPopover(true);

  if (!scenarioAnalysis || !primaryFactorLens) {
    return null;
  }

  const { factors } = primaryFactorLens;
  const {
    factorsPredictedImpactMap: contributionMap,
    factorsPredictedMap: valueMap,
    cashRateImpact,
  } = scenarioAnalysis;
  const rows: Partial<PerformanceAttribution>[] = factors.map((factor: FactorEntity) => ({
    contribution: undefined,
    contributionValue: contributionMap[factor.id],
    name: factor.name,
    value: valueMap?.[factor.id],
    weight: undefined,
  }));
  rows.push({
    contribution: undefined,
    contributionValue: contributionMap[RESIDUAL_ID],
    name: 'Residual',
    value: valueMap[RESIDUAL_ID],
    weight: undefined,
  });
  if (!relative) {
    rows.push({
      contribution: undefined,
      contributionValue: cashRateImpact,
      name: 'Risk-Free Rate',
      value: cashRateImpact,
      weight: undefined,
    });
  }

  return (
    <CellWithHover onMouseEnter={showPopover} onMouseLeave={hidePopover}>
      <Highlight />
      <PredictedWrapper>{formatPercentage(scenarioAnalysis?.predicted)}</PredictedWrapper>
      {popover && (
        <PopoverWrapper>
          <ContributionPopover
            popoverPosition="left"
            visible
            subjectName={subjectName}
            value={scenarioAnalysis.predicted}
            data={rows}
            keepInitialDataOrder
            metricName={relative ? 'Estimated Relative Return' : 'Estimated Return'}
            abbreviatedMetricName="Return"
            // @ts-expect-error: TODO fix strictFunctionTypes
            formatter={formatPercentage}
            dataItemLabel="Factor"
            italicRows={compact([!relative ? rows.length - 2 : null, rows.length - 1])}
            onHover={hidePopover}
          />
        </PopoverWrapper>
      )}
    </CellWithHover>
  );
};

const PredictedWrapper = styled.div`
  padding-right: 10px;
`;

const CellWithHover = styled.div`
  height: 32px;
  max-width: 95px;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

const Highlight = styled.div`
  position: absolute;
  height: 38px;
  width: 95px;
  max-width: 100%;
  :hover {
    background-color: ${HoverCellBackgroundColor};
  }
`;

const PopoverWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 55px;
`;
