import type { AnalysesResults } from '../../types';
import { FundUtils } from 'venn-utils';

export interface Restrictions {
  disableToggle: boolean;
  showQuarterly: boolean;
  disableExport: boolean;
  error?: string;
}

/**
 * Get display resitrction for toggle button and export button
 * We only disable export button when there is error.
 */
export default function getRestrictions(
  analyses: AnalysesResults | undefined,
  subjectKey: string,
  showQuarterly: boolean,
): Restrictions {
  if (!analyses) {
    return { disableToggle: false, disableExport: false, showQuarterly: false };
  }
  const subject = analyses.subject;
  const disabledFrequencies = analyses.results.returnsGrid[subjectKey]
    ? analyses.results.returnsGrid[subjectKey].disabledFrequencies
    : [];

  if (subject.fund && (!FundUtils.isPerformanceViewable(subject.fund) || disabledFrequencies.length)) {
    const disableAll = disabledFrequencies.indexOf('MONTHLY') > -1 && disabledFrequencies.indexOf('QUARTERLY') > -1;
    const disableMonthly =
      disabledFrequencies.indexOf('MONTHLY') > -1 && !(disabledFrequencies.indexOf('QUARTERLY') > -1);

    if (disableAll) {
      return {
        disableToggle: true,
        showQuarterly: true,
        disableExport: true,
        error: 'Unable to run analysis. Try selecting a different data frequency to view analysis.',
      };
    }
    if (disableMonthly) {
      return {
        disableToggle: true,
        showQuarterly: true,
        disableExport: false,
      };
    }
  }
  return { disableToggle: false, showQuarterly, disableExport: false };
}
