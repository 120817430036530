import type { ReactNode } from 'react';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Icon, ZIndex } from 'venn-ui-kit';
import { Modal, ModalSize, ModalSubhead } from '../../modal';

interface AccountModalWrapperProps {
  onClose: () => void;
  title: string;
  icon?: string;
  iconClassName?: string;
  zIndex?: number;
  children?: ReactNode;
}

export class AccountModalWrapper extends PureComponent<AccountModalWrapperProps> {
  render() {
    const { children, onClose, icon, iconClassName, title, zIndex = ZIndex.Modal } = this.props;
    return (
      <Modal zIndex={zIndex} size={ModalSize.Medium} onClose={onClose}>
        <StyledModalSubhead>
          <StyledIcon type={icon} className={iconClassName} />
          {title}
        </StyledModalSubhead>
        {children}
      </Modal>
    );
  }
}

const StyledModalSubhead = styled(ModalSubhead)`
  display: flex;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  margin-right: 10px;
`;
