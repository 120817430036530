import { compact, isNil } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import type { ProxyTypeEnum } from 'venn-api';
import { DropMenu, TooltipPosition } from 'venn-ui-kit';
import { FS, getFormattedProxyType, getProxyTypeDescription } from 'venn-utils';
import type { FundToProxy } from '../types';
import { DisabledProxyReason } from '../types';
import type { ReturnsRangeInfo } from './utils';
import { getDisabledProxyTypeMessage } from './utils';

interface ProxyTypeOptionsProps {
  selectedProxyType: ProxyTypeEnum;
  onSelectProxyType: (value: ProxyTypeEnum) => void;
  investment: FundToProxy;
  /** Unproxied returns info of the investment */
  rawInvestmentRange?: ReturnsRangeInfo;
  /** Unproxied returns info of the selected proxy, if any */
  selectedProxyRange?: ReturnsRangeInfo;
}

interface ProxyTypeOption {
  type: ProxyTypeEnum;
  getTooltipContent?: () => React.ReactNode;
  getTooltipMaxWidth: (disabled: boolean) => number;
}

const getProxyTypes = (): ProxyTypeOption[] =>
  compact([
    {
      type: 'BACKFILL',
      getTooltipContent: () => <StyledTooltipContent>Extend past performance</StyledTooltipContent>,
      getTooltipMaxWidth: () => 240,
    },
    {
      type: 'SUBSTITUTE',
      getTooltipContent: () => <StyledTooltipContent>Replace with a different investment</StyledTooltipContent>,
      getTooltipMaxWidth: () => 320,
    },
    {
      type: 'DESMOOTH_INTERPOLATE',
      getTooltipContent: () => (
        <StyledTooltipContent>
          Reverse the smoothing process and increase return frequency to daily
        </StyledTooltipContent>
      ),
      getTooltipMaxWidth: (disabled) => (disabled ? 250 : 320),
    },
    {
      type: 'INTERPOLATE',
      getTooltipContent: () => <StyledTooltipContent>Increase return frequency to daily</StyledTooltipContent>,
      getTooltipMaxWidth: (disabled) => (disabled ? 250 : 320),
    },
    {
      type: 'DESMOOTH',
      getTooltipContent: () => <StyledTooltipContent>Reverse the smoothing process</StyledTooltipContent>,
      getTooltipMaxWidth: (disabled) => (disabled ? 250 : 320),
    },
    FS.has('extrapolation_ff') && {
      type: 'EXTRAPOLATE',
      getTooltipContent: () => <StyledTooltipContent>Extend performance forward </StyledTooltipContent>,
      getTooltipMaxWidth: () => 240,
    },
  ]);

const validProxyType = (reason?: DisabledProxyReason) => {
  return (
    isNil(reason) ||
    ![
      DisabledProxyReason.EXTRAPOLATE_QUARTERLY,
      DisabledProxyReason.BACKFILL_QUARTERLY,
      DisabledProxyReason.INTERPOLATE_NONQUARTERLY,
      DisabledProxyReason.DESMOOTH_DAILY,
      DisabledProxyReason.SUBSTITUTE_SYSTEM_FUND,
    ].includes(reason)
  );
};

const ProxyTypeOptions = ({
  selectedProxyType,
  onSelectProxyType,
  investment,
  rawInvestmentRange,
  selectedProxyRange,
}: ProxyTypeOptionsProps) => {
  // Only show enabled proxies once desmoothing is enabled
  const proxyTypes = getProxyTypes().filter((option) =>
    validProxyType(getDisabledProxyTypeMessage(option.type, investment, rawInvestmentRange, selectedProxyRange)),
  );

  const items = proxyTypes.map((option) => ({
    label: getFormattedProxyType(option.type),
    description: getProxyTypeDescription(option.type),
    value: option.type,
    tooltipContent: getDisabledProxyTypeMessage(option.type, investment, rawInvestmentRange, selectedProxyRange),
    tooltipPosition: TooltipPosition.Right,
    className: `qa-proxy-option-${option.type.toLowerCase()}`,
  }));

  return (
    <Container data-testid="qa-proxy-dropmenu">
      <ProxyTypeContainer>Proxy Type:</ProxyTypeContainer>
      <DropMenu
        height={400}
        items={items}
        selected={selectedProxyType}
        onChange={(item) => onSelectProxyType(item.value)}
        minimumItemsToTrigger={1}
      />
    </Container>
  );
};

const Container = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  width: 306px;
  .simple-menu-item {
    font-size: 16px;
  }
  .simple-menu-item-description {
    font-size: 12px;
    line-height: 16px;
  }
`;

const ProxyTypeContainer = styled.div`
  font-weight: bold;
  font-size: 12px;
`;

const StyledTooltipContent = styled.div`
  text-align: center;
  padding: 12px 15px;
  font-size: 14px;
`;

export default ProxyTypeOptions;
