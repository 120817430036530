import { useState } from 'react';
import moment from 'moment';
import type { SaveAnalysisViewModalMode } from '../modals/save-view/SaveAnalysisViewModal';

/**
 * Manage logic for save and save as button
 */
const useSave = ({
  hasUnsavedChanges,
  hasSavedBaseline,
  onSave,
  onSaveAs,
  onRenameSavedView,
  currentViewName,
  noAccessModifiedView,
  hideSave,
  hideSaveAs,
}: {
  hasUnsavedChanges: boolean;
  hasSavedBaseline?: boolean;
  onSave?: (name?: string, ownerContextId?: string) => Promise<string | undefined>;
  onSaveAs?: (name: string, ownerContextId?: string) => void;
  onRenameSavedView?: (name: string) => void;
  currentViewName?: string;
  noAccessModifiedView?: boolean;
  hideSave?: boolean;
  hideSaveAs?: boolean;
}) => {
  const [saveModalMode, setSaveModalMode] = useState<SaveAnalysisViewModalMode | undefined>(undefined);
  const onSaveAsClick = () => setSaveModalMode('SAVE_AS');
  const onSaveClick = () => (noAccessModifiedView ? setSaveModalMode('SAVE_NEW_OWNER') : setSaveModalMode('SAVE'));
  const onRenameClick = () => setSaveModalMode('RENAME');

  const onCancel = () => setSaveModalMode(undefined);
  const onSubmit = async (saveWithName: string, ownerContextId?: string) => {
    if (!saveModalMode) {
      return;
    }
    if (saveModalMode === 'RENAME') {
      await onRenameSavedView?.(saveWithName);
      return;
    }
    await (saveModalMode === 'SAVE'
      ? onSave?.(saveWithName, ownerContextId)
      : onSaveAs?.(saveWithName, ownerContextId));
  };

  const saveImmediately = saveModalMode === 'SAVE' && !!currentViewName;
  const timestamp = moment().format('YYYY-MM-DD hh:mm A');

  const isSaveHidden = hideSave ?? (!hasUnsavedChanges && hasSavedBaseline);
  const isSaveAsHidden = hideSaveAs ?? (hasUnsavedChanges && !hasSavedBaseline);

  return {
    onSaveClick: isSaveHidden ? undefined : onSaveClick,
    onSaveAsClick: isSaveAsHidden ? undefined : onSaveAsClick,
    onRenameClick,
    saveModalMode,
    onSubmit,
    onCancel,
    saveImmediately,
    timestamp,
  };
};

export default useSave;
