import type { ReactNode } from 'react';
import React, { useCallback, useContext, useRef } from 'react';
import type { ContentRect } from 'react-measure';
import Measure from 'react-measure';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import {
  BlockIdContext,
  CustomAnalysisBlock,
  CustomHoldingsBlock,
  PrivatesBlock,
  PortfolioComparisonBlock,
  StudioSidePanelContext,
  TextAndFormatBlock,
  BlockWidthContextProvider,
  useSetBlockSize,
  useBlockId,
  LEFT_ALIGN_CLASS,
  RIGHT_ALIGN_CLASS,
  BOLD_CLASS,
  DOWNLOAD_BLOCK_CLASS,
  StudioBlockWatermark,
  JUSTIFY_HEADER_END_CLASS,
  vennBlockWatermarkClass,
  blockEmptyStateClass,
  useAppPrintMode,
} from 'venn-components';
import {
  blockDisplayHeader,
  blockSettings,
  COLUMN_GAP,
  DEFAULT_MARGIN,
  isReportState,
  recoilBlockMaxSize,
  selectedBlockIdState,
  studioBlockCustomWidth,
  studioBlockHeight,
  VIRTUALIZATION_SCROLL_BAR_PX,
} from 'venn-state';
import {
  buttonize,
  isAnalysisBlock,
  isHoldingsBlock,
  isPortfolioBlock,
  isPrivatesBlock,
  isTextOrFormatBlock,
  withSuspense,
} from 'venn-utils';
import BlockHeader from './BlockHeader';
import { BuilderBlockWrapper } from './shared';
import { useVirtualization } from '../../logic/useVirtualization';
import { ShimmerBlock } from 'venn-ui-kit';

export const BLOCK_CONTAINER_CLASS = 'venn-block-container';
/**
 * You can set either the container width for the block or the intended width of the block, but not both.
 * TODO(collin.irwin): Receiving Width and ContainerWidth both is pretty confusing. Can we refactor this to just getting a single width,
 * or maybe some other clearer architecture?
 */
type BlockWidths =
  | {
      width: number;
      containerWidth?: undefined;
    }
  | { width?: undefined; containerWidth: number };

type BlockProps = {
  id: string;
  index: number;
  externalBlockWrapper?: boolean;
  focusBlockIndex: React.MutableRefObject<number | undefined>;
  focusBlockRef: React.RefObject<HTMLDivElement>;
} & BlockWidths;

const Block = React.memo(function Block({
  id,
  index,
  width,
  externalBlockWrapper,
  focusBlockIndex,
  focusBlockRef,
  containerWidth,
}: BlockProps) {
  const selected = useRecoilValue(selectedBlockIdState);
  const blockSetting = useRecoilValue(blockSettings(id));
  const localBlockRef = useRef<HTMLDivElement>(null);
  const isReport = useRecoilValue(isReportState);
  const { inPrintModeOrReportLab } = useAppPrintMode();
  const { onSelectBlock } = useContext(StudioSidePanelContext);
  const header = useRecoilValue(blockDisplayHeader(id));
  const setBlockHeight = useSetRecoilState(studioBlockHeight(id));
  const blockRelativeWidth = useRecoilValue(studioBlockCustomWidth(id));
  const hasVirtualization = useVirtualization();

  const onResize = useCallback(
    (contentRect: ContentRect) =>
      // TODO: the fact we use Math.max here means that a block never shrinks in size in printing, which is very brittle and error prone.
      // It has caused bugs where blocks have way too much space between them during printing. E.g., if a loading state is larger than the block itself,
      // this code will remember the size of the loading state forever.
      // However, without this behavior, blocks can infinite loop between small and large sizes when being laid out in the print layout.
      // It is not clear yet how to redesign  the print layout functionality to resolve these issues.
      setBlockHeight((currVal) => Math.ceil(Math.max(contentRect?.bounds?.height ?? 0, currVal))),
    [setBlockHeight],
  );

  const blockRef = (focusBlockIndex.current === index ? focusBlockRef : undefined) || localBlockRef;

  const fullWidth = blockSetting.customBlockType === 'PAGE_BREAK';

  const getStudioBlockWidth = (pageWidth: number) => {
    const numberOfColumnGaps = 1 / blockRelativeWidth - 1;
    const virtuosoScrollBarWidth = hasVirtualization && numberOfColumnGaps <= 0.0001 ? VIRTUALIZATION_SCROLL_BAR_PX : 0;
    const printMarginPx = DEFAULT_MARGIN * (inPrintModeOrReportLab ? 2 : 0);

    const availablePageWidth = pageWidth - printMarginPx - COLUMN_GAP * numberOfColumnGaps - virtuosoScrollBarWidth;

    return availablePageWidth * blockRelativeWidth;
  };

  const blockWidthPx = width !== undefined ? getStudioBlockWidth(width) : containerWidth;

  const blockWidthStyle = containerWidth || fullWidth ? '100%' : `${blockWidthPx}px`;

  const Wrapper = externalBlockWrapper ? UnstyledBlockWrapper : BuilderBlockWrapper;

  const active = selected ? selected === id : false;

  return (
    <BlockIdContext.Provider value={id}>
      <BlockWidthContextProvider blockWidthPx={blockWidthPx}>
        <Measure bounds key={id} onResize={onResize}>
          {({ measureRef }) => {
            return (
              <div
                className={BLOCK_CONTAINER_CLASS}
                ref={measureRef}
                id={id}
                key={id}
                data-testid={`qa-${lowerCaseHeaderName(header)}`}
                style={{
                  width: blockWidthStyle,
                }}
              >
                <div
                  {...buttonize((e) => {
                    e.stopPropagation();
                    onSelectBlock(id, { scrollIntoView: false });
                  })}
                  ref={blockRef}
                >
                  <Wrapper id={id}>
                    {isTextOrFormatBlock(blockSetting.customBlockType) ? (
                      <TextAndFormatBlock
                        id={id}
                        customBlockType={blockSetting.customBlockType}
                        active={active}
                        inPrintMode={inPrintModeOrReportLab}
                        isReport={!!isReport}
                      />
                    ) : (
                      <>
                        <BlockHeader />
                        <BlockWatermarkContainer>
                          <BlockContentWrapper>
                            {isHoldingsBlock(blockSetting.customBlockType) && (
                              <CustomHoldingsBlock
                                isPrinting={inPrintModeOrReportLab}
                                downloadableContentRef={blockRef}
                                isReport={isReport}
                                selectedRefId={id}
                              />
                            )}
                            {isPrivatesBlock(blockSetting.customBlockType) && (
                              <PrivatesBlock
                                isPrinting={inPrintModeOrReportLab}
                                downloadableContentRef={blockRef}
                                isReport={isReport}
                                selectedRefId={id}
                              />
                            )}
                            {isPortfolioBlock(blockSetting.customBlockType) && <PortfolioComparisonBlock />}
                            {isAnalysisBlock(blockSetting.customBlockType) && (
                              <CustomAnalysisBlock
                                isPrinting={inPrintModeOrReportLab}
                                downloadableContentRef={blockRef}
                                isReport={isReport}
                                selectedRefId={id}
                              />
                            )}
                          </BlockContentWrapper>
                          <StudioBlockWatermark />
                        </BlockWatermarkContainer>
                      </>
                    )}
                  </Wrapper>
                </div>

                <BlockSpacer />
              </div>
            );
          }}
        </Measure>
      </BlockWidthContextProvider>
    </BlockIdContext.Provider>
  );
});

export default withSuspense(null, Block);

const BlockContentWrapper = ({ children }: { children: ReactNode }) => {
  const setBlockContentMaxSizeState = useSetBlockSize(recoilBlockMaxSize.transformedState(useBlockId()));

  return (
    <Measure bounds onResize={setBlockContentMaxSizeState}>
      {({ measureRef }) => <BlockContentContainer ref={measureRef}>{children}</BlockContentContainer>}
    </Measure>
  );
};

/** Contains the block content container and the block watermark, enabling the block watermark to overlay the block content container. */
const BlockWatermarkContainer = styled.div`
  flex: 1;
  min-height: 0;
  position: relative;
  height: 100%;

  :has(.${blockEmptyStateClass}) .${vennBlockWatermarkClass} {
    display: none;
  }
`;

const BlockContentContainer = styled.div`
  display: flex;
  /* needed to stop overflow */
  min-height: 0;
  height: 100%;

  /* Warning: Removing this CSS breaks many blocks in unexpected ways, because they stop growing and instead shrink down to their content's minimum size. */
  > div:last-child:not(${ShimmerBlock}) {
    width: 100%;
    height: 100%;
  }

  .${DOWNLOAD_BLOCK_CLASS} {
    width: 100%;
  }

  .${LEFT_ALIGN_CLASS} {
    text-align: left;
  }

  .${JUSTIFY_HEADER_END_CLASS} .ag-header-cell-label {
    justify-content: flex-end;
  }

  .${RIGHT_ALIGN_CLASS} {
    text-align: right;
  }
  .${BOLD_CLASS} {
    font-weight: bold;
  }
`;

const BlockSpacer = styled.div`
  height: ${DEFAULT_MARGIN}px;
`;

const UnstyledBlockWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const lowerCaseHeaderName = (header: string) => header.toLowerCase().replace(/ /g, '-');
