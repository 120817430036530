import React, { useState } from 'react';
import {
  Input,
  Modal,
  ModalContent,
  ModalDescription,
  ModalFooter,
  ModalHeader,
  ModalSize,
  ModalSubhead,
} from 'venn-components';
import { Caption, getAppTitle, GetColor, getTextThemeProvider, HyperLink, Label } from 'venn-ui-kit';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { createIntegration } from 'venn-api';

const NO_RESULTS_MESSAGE = 'No results matched your search. Complete the form to request it.';
const SETUP_MESSAGE =
  "Connect your existing tools & systems. Fill out the form and we'll respond to your request as soon as we can.";

interface IntegrationSetupModalProps {
  /** Legacy. Remove when IntegrationsLegacy is removed. Name of integration being requested */
  request: string;
  onClose: () => void;
  /** Legacy. Remove when IntegrationsLegacy is removed. Whether there were no results when search for the requested integration */
  noResults?: boolean;
}
const IntegrationRequestModal: React.FC<React.PropsWithChildren<IntegrationSetupModalProps>> = ({
  request,
  onClose,
  noResults,
}) => {
  const [integrationName, setIntegrationName] = useState(request);
  const [website, setWebsite] = useState('');
  const [info, setInfo] = useState('');
  const [error, setError] = useState('');
  const [submitted, setSubmitted] = useState(false);

  return (
    <Modal onClose={onClose} size={ModalSize.Large} closeOnEsc>
      <ModalHeader>{submitted ? 'Request Successfully Submitted' : 'Request a new Integration'}</ModalHeader>
      <ModalSubhead>
        {submitted
          ? `Thank you for your request. A ${getAppTitle()} representative will contact you if this integration becomes available.`
          : noResults
            ? NO_RESULTS_MESSAGE
            : SETUP_MESSAGE}
      </ModalSubhead>
      <ModalDescription>
        <p>
          <Label>
            Questions?{' '}
            <HyperLink href={`mailto:${getTextThemeProvider().supportEmail}`}>{`Contact ${getAppTitle()}`}</HyperLink>
          </Label>
        </p>
      </ModalDescription>
      {!submitted && (
        <ModalContent>
          <form>
            {!isEmpty(error) && <Error>{error}</Error>}
            <FormRow>
              <FormHint>*required</FormHint>
              <Label htmlFor="integrationName">{`What tool do you want ${getAppTitle()} to integrate with?`}</Label>
              <Input
                name="integrationName"
                value={integrationName}
                onChange={setIntegrationName}
                style={{ width: '100%' }}
              />
            </FormRow>
            <FormRow>
              <FormHint>*required</FormHint>
              <Label htmlFor="website">What is the website for this integration?</Label>
              <Input name="website" value={website} onChange={setWebsite} style={{ width: '100%' }} />
            </FormRow>
            <FormRow>
              <Label htmlFor="info">Any additional information you would like to add?</Label>
              <TextArea
                name="info"
                value={info}
                onChange={(event) => event.target && setInfo(event.target.value)}
                style={{ width: '100%' }}
              />
            </FormRow>
          </form>
        </ModalContent>
      )}
      <ModalFooter
        onCancel={onClose}
        primaryLabel={submitted ? 'Done' : 'Submit Request'}
        onPrimaryClick={() => {
          if (submitted) {
            onClose();
            return;
          }
          if (isEmpty(integrationName) || isEmpty(website)) {
            setError('Please fill in the required fields.');
            return;
          }
          createIntegration({
            provider: integrationName,
            providerType: 'CUSTOM',
            website,
            info,
          })
            .then(() => {
              setSubmitted(true);
            })
            .catch((e) => {
              if (e.name !== 'AbortError') {
                setError('There was an error processing your request. Please reach out if this problem persists.');
              }
            });
        }}
      />
    </Modal>
  );
};

const FormRow = styled.div`
  margin-bottom: 20px;
  width: 100%;
  > label {
    display: block;
    margin-bottom: 5px;
  }
`;

const FormHint = styled(Caption)`
  float: right;
`;

const TextArea = styled.textarea`
  border-radius: 4px;
  border-color: ${GetColor.HintGrey};
  font-size: 13px;
  font-weight: normal;
  min-height: 150px;
  max-height: 400px;
  resize: vertical;
`;

const Error = styled(Label)`
  color: ${GetColor.Error};
  display: block;
  margin-bottom: 5px;
`;

export default IntegrationRequestModal;
