import type { FC } from 'react';
import React, { useEffect, useMemo, useState } from 'react';
import type { AuthPageProps } from '../components';
import { AuthValidators } from '../components';
import type { SignupRequest, Country, UserStatusEnum } from 'venn-api';
import { attemptUserSignupV3, getSupportedCountries } from 'venn-api';

import find from 'lodash/find';
import { getSignUpParams } from '../queryParamParsers';
import { analyticsService } from 'venn-utils';
import InviteSignupForm from './InviteSignupForm';
import { required } from '../components/validators/Validators';
import type { FormValues } from 'venn-components';
import { createField } from 'venn-components';
import { getTextThemeProvider } from 'venn-ui-kit';

const { isInitialPasswordValid } = AuthValidators;

export const SignUpInviteRoute = '/sign-up/invitation';

export type SignUpInvitePageProps = AuthPageProps;

const successfulStatuses = ['ACTIVE', 'GREYLISTED'];

const DEFAULT_SIGNUP_ERROR = `Your invitation is not valid. If you need additional support, contact ${
  getTextThemeProvider().supportEmail
}.`;

const SignUpInvitePage: FC<React.PropsWithChildren<SignUpInvitePageProps>> = ({ onSuccess }) => {
  const [error, setError] = useState<string>('');
  const [status, setStatus] = useState<UserStatusEnum | undefined>(undefined);
  const onSubmit = async (values: FormValues) => {
    let response;

    const { email, firstName, lastName, company, country, organizationId, password, activationCode } = values;

    const signupValues: Partial<SignupRequest> = {
      email,
      firstName,
      lastName,
      organizationLegalName: company,
      countryCode: country,
      organizationId,
      password,
      activationCode,
      legalAgreements: ['privacy-policy', 'user-agreement'],
    };

    try {
      response = await attemptUserSignupV3(signupValues);
      setStatus(response.content.status);
      onSuccess?.();
      analyticsService.signupStepCompleted({
        stepDescription: 'account details',
      });
      // Signup funnel is complete for invited accounts when they've signed up
      analyticsService.signupFunnelCompleted();
    } catch (e) {
      const { content } = await e;
      setError(content?.message || DEFAULT_SIGNUP_ERROR);
    }
    return response;
  };
  const queryParams = getSignUpParams();
  const fields = useMemo(
    () => ({
      email: createField<string>('email', queryParams.email, required('Email')),
      firstName: createField<string>('firstName', '', required('First name')),
      lastName: createField<string>('lastName', '', required('Last name')),
      company: createField<string>('company', queryParams.organizationLegalName, required('Company')),
      country: createField<number>('country', queryParams.countryCode, required('Country')),
      organizationId: createField<number>('organizationId', queryParams.organizationId, required()),
      activationCode: createField<number>('activationCode', queryParams.activationCode, required()),
      password: createField<string>('password', '', isInitialPasswordValid, false, true),
    }),
    [queryParams],
  );
  const [country, setCountry] = useState<Country | undefined>(undefined);

  const { countryCode } = useMemo(getSignUpParams, [getSignUpParams]);
  useEffect(() => {
    analyticsService.signupStepViewed({
      stepDescription: 'account details',
    });
    getSupportedCountries().then((response) => {
      if (response.status === 200) {
        setCountry(find(response.content, { code: countryCode }));
      }
    });
  }, [countryCode, setCountry]);

  if (status && successfulStatuses.includes(status)) {
    return null;
  }

  return <InviteSignupForm error={error} country={country} fields={fields} onSubmit={onSubmit} />;
};

export default SignUpInvitePage;
