import type { CSSProperties, FC } from 'react';
import React, { useContext } from 'react';
import type { Typography } from 'venn-ui-kit';
import { RESIDUAL_ID, RISK_FREE_RATE_ID } from '../../../factor-chart';
import type { ViewPort } from '../types';
import type { FactorSummaryRow } from '../../factorSummaryTypes';
import { WIDE_VALUE_COLUMN_OFFSET, COLUMN_SPACING, TITLE_COLUMN_WIDTH } from '../constants';
import Cell from './Cell';
import { getTextColor, getOpacity } from '../utils';
import { ThemeContext } from 'styled-components';

const getCellStyle = (
  typography: Typography,
  color: string,
  opacity: number,
  rowHeight: number,
  italic?: boolean,
  bold?: boolean,
): CSSProperties => ({
  fontFamily: typography.fontFamily,
  fontSize: rowHeight < 20 ? '10px' : '14px',
  fontStyle: italic ? 'italic' : 'normal',
  fontWeight: bold ? 'bold' : 'normal',
  letterSpacing: '1px',
  fill: color,
  fillOpacity: opacity,
});

const getFactorNameStyle = (
  factor: FactorSummaryRow,
  rowHeight: number,
  color: string,
  typography: Typography,
): CSSProperties => ({
  textAnchor: 'end',
  fill: color,
  fontStyle: factor.factorId === RESIDUAL_ID || factor.factorId === RISK_FREE_RATE_ID ? 'italic' : 'normal',
  fontSize: rowHeight < 20 ? '10px' : '14px',
  fontFamily: typography.fontFamily,
});

export interface RowProps {
  factor: FactorSummaryRow;
  factorIndex: number;
  viewPort: ViewPort;
  percentage?: boolean;
  categoryActive: boolean;
  typography: Typography;
}

const Row: FC<React.PropsWithChildren<RowProps>> = ({
  children,
  factor,
  factorIndex,
  percentage,
  viewPort,
  categoryActive,
  typography,
}) => {
  const { mainDataPoint, baselineDataPoint, comparisonDataPoint, categoryDataPoint, factorName, factorId } = factor;
  const theme = useContext(ThemeContext);
  const {
    Colors,
    Schemes: { FactorColors },
  } = theme;

  let mainOffset = viewPort.width - 10 - 3 * WIDE_VALUE_COLUMN_OFFSET;
  let baselineOffset = viewPort.width - 10 - 2 * WIDE_VALUE_COLUMN_OFFSET;
  let comparisonOffset = viewPort.width - 10 - 1 * WIDE_VALUE_COLUMN_OFFSET;
  const categoryOffset = viewPort.width - 10;
  const verticalShift = viewPort.rowHeight < 20 ? 12 : 19;

  if (!baselineDataPoint) {
    mainOffset += WIDE_VALUE_COLUMN_OFFSET;
  }

  if (!comparisonDataPoint) {
    mainOffset += WIDE_VALUE_COLUMN_OFFSET;
    baselineOffset += WIDE_VALUE_COLUMN_OFFSET;
  }

  if (!categoryDataPoint || !categoryActive) {
    mainOffset += WIDE_VALUE_COLUMN_OFFSET;
    baselineOffset += WIDE_VALUE_COLUMN_OFFSET;
    comparisonOffset += WIDE_VALUE_COLUMN_OFFSET;
  }

  return (
    <g transform={`translate(0, ${viewPort.rowHeight * factorIndex})`}>
      <text
        transform={`translate(${viewPort.padding.left * (1 - COLUMN_SPACING / TITLE_COLUMN_WIDTH)}, ${verticalShift})`}
        style={getFactorNameStyle(factor, viewPort.rowHeight, Colors.Black, typography)}
      >
        {factorName}
      </text>
      {children}
      {mainDataPoint && (
        <Cell
          percentage={percentage}
          style={getCellStyle(
            theme.Typography,
            getTextColor(mainDataPoint.value ?? 0, theme),
            getOpacity(mainDataPoint.statisticallySignificant),
            viewPort.rowHeight,
            factorId === RESIDUAL_ID || factorId === RISK_FREE_RATE_ID,
            true,
          )}
          transform={`translate(${mainOffset}, ${verticalShift})`}
          value={mainDataPoint.value}
        />
      )}
      {baselineDataPoint && (
        <Cell
          percentage={percentage}
          style={getCellStyle(
            typography,
            FactorColors.labelColor,
            getOpacity(baselineDataPoint.statisticallySignificant),
            viewPort.rowHeight,
            factorId === RESIDUAL_ID || factorId === RISK_FREE_RATE_ID,
          )}
          transform={`translate(${baselineOffset}, ${verticalShift})`}
          value={baselineDataPoint.value}
        />
      )}
      {comparisonDataPoint && (
        <Cell
          percentage={percentage}
          style={getCellStyle(
            typography,
            FactorColors.labelColor,
            getOpacity(comparisonDataPoint.statisticallySignificant),
            viewPort.rowHeight,
            factorId === RESIDUAL_ID || factorId === RISK_FREE_RATE_ID,
          )}
          transform={`translate(${comparisonOffset}, ${verticalShift})`}
          value={comparisonDataPoint.value}
        />
      )}
      {categoryActive && categoryDataPoint && (
        <Cell
          percentage={percentage}
          style={getCellStyle(
            typography,
            FactorColors.labelColor,
            getOpacity(categoryDataPoint.statisticallySignificant),
            viewPort.rowHeight,
            factorId === RESIDUAL_ID || factorId === RISK_FREE_RATE_ID,
          )}
          transform={`translate(${categoryOffset}, ${verticalShift})`}
          value={categoryDataPoint.value}
        />
      )}
    </g>
  );
};

export default Row;
