import styled, { css } from 'styled-components';
import type { ThemeProvider } from 'venn-ui-kit';
import { GetColor, Icon } from 'venn-ui-kit';

/** Button with support for becoming visible on hover, or forcing visibility as needed. */
export const HoverIcon = styled(Icon)<{ disabled?: boolean; visible?: boolean; hoverColor?: ThemeProvider }>`
  color: ${GetColor.Primary.Dark};
  text-align: center;
  opacity: 0;
  outline: none;
  padding-left: 8px;
  padding-right: 8px;

  &:hover {
    color: ${(props) => (props.disabled ? GetColor.Grey : props.hoverColor ? props.hoverColor : GetColor.Primary.Main)};
    opacity: 1;
  }

  ${({ visible }) =>
    visible &&
    css`
      opacity: 1;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${GetColor.Grey};
    `}
`;
