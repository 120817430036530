import React from 'react';
import styled from 'styled-components';
import { BigBrandLogoFull } from 'venn-components';
import { ZIndex, Headline1, Headline3, Body1, Button } from 'venn-ui-kit';
import type { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { logExceptionIntoSentry } from 'venn-utils';

interface ErrorBoundaryState {
  hasError: boolean;
}
type ErrorBoundaryProps = RouteComponentProps;

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  unregisterListener: () => void;

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
    this.refreshPage = this.refreshPage.bind(this);
    this.returnHome = this.returnHome.bind(this);
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidMount() {
    this.unregisterListener = this.props.history.listen(() => {
      this.setState({ hasError: false });
    });
  }

  componentWillUnmount() {
    if (this.unregisterListener) {
      this.unregisterListener();
    }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    logExceptionIntoSentry(error, errorInfo);
  }

  refreshPage() {
    window.location.reload();
  }

  returnHome() {
    this.props.history.push('/');
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container>
          <Logo>
            <BigBrandLogoFull />
          </Logo>
          <Content>
            <Headline1 center>Something went wrong.</Headline1>
            <Headline3 center>We are working to fix this error.</Headline3>
            <Body1 center>In the meantime, try refreshing the page, or return Home</Body1>
            <Actions>
              <Button onClick={this.returnHome}>Return Home</Button>
              <Button onClick={this.refreshPage} dominant>
                Refresh Page
              </Button>
            </Actions>
          </Content>
        </Container>
      );
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);

const Logo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: ${ZIndex.Background};
  overflow: hidden;
`;

const Container = styled.div`
  width: 100%;
  position: relative;
  text-align: center;
  margin: 0 auto;
`;

const Content = styled.div`
  margin-top: 330px;
  z-index: ${ZIndex.Base};
`;

const Actions = styled.div`
  margin-top: 40px;
`;
