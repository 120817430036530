import React from 'react';
import styled, { css } from 'styled-components';
import { GetColor, GetImages, Headline3, Hint, Icon, Link as LinkStyle } from 'venn-ui-kit';

interface StartButtonProps {
  step: number;
  title: string;
  icon: string;
  onClick?: () => void;
  disabled?: boolean;
}

const StartButton = ({ step, title, icon, onClick, disabled }: StartButtonProps) => (
  <ButtonContainer disabled={disabled} onClick={disabled ? undefined : onClick}>
    <ButtonGradient>
      <ButtonIcon>
        <Icon type={icon} />
      </ButtonIcon>
      <div>
        <Hint>
          <strong>Step {step}</strong>
        </Hint>
        <Headline3>{title}</Headline3>
        <LinkStyle disabled={disabled}>
          Go <Icon type="arrow-right" />
        </LinkStyle>
      </div>
    </ButtonGradient>
  </ButtonContainer>
);

export default StartButton;

const ButtonIcon = styled.div`
  i {
    color: ${GetColor.HighlightDark};
    font-size: 60px;
  }
  display: flex;
  justify-content: center;
  margin-top: 16px;
`;

const ButtonContainer = styled.div<{ disabled?: boolean }>`
  background-image: url(${GetImages.triangles});
  background-size: cover;
  border: 1px solid ${GetColor.WhiteGrey};
  border-radius: 4px;
  :not(:first-child) {
    margin-left: 20px;
  }
  height: 202px;
  width: 250px;
  min-width: 250px;

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${GetColor.Grey};
    `}
  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;

const ButtonGradient = styled.div`
  background: linear-gradient(57.31deg, #ffffff 0%, rgba(255, 255, 255, 0) 113.05%);
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &:hover {
    background: linear-gradient(57.31deg, #ffffff 0%, rgba(255, 255, 255, 0) 113.05%), rgba(0, 129, 138, 0.1);
  }
`;
