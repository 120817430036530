import React from 'react';
import styled from 'styled-components';
import { AuthInputRow } from '../AuthComponents';
import type { FormField } from 'venn-components';
import { FormInput } from 'venn-components';

interface NameInputsProps {
  firstName?: FormField<string>;
  lastName?: FormField<string>;
  disabled?: boolean;
}

const NameInputs = ({ disabled, firstName, lastName }: NameInputsProps) => (
  <NameInputsRow>
    <FormInput
      errorHidden={disabled}
      autoComplete="given-name"
      inputId="given-name"
      required
      name="firstName"
      label="First Name"
      disabled={disabled}
      {...firstName}
    />
    <FormInput
      errorHidden={disabled}
      autoComplete="family-name"
      inputId="family-name"
      required
      name="lastName"
      label="Last Name"
      disabled={disabled}
      {...lastName}
    />
  </NameInputsRow>
);

export default NameInputs;

const NameInputsRow = styled(AuthInputRow)`
  > div:first-of-type {
    margin-right: 16px;
  }
`;
