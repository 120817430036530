import React, { useState } from 'react';
import styled from 'styled-components';
import { Icon } from 'venn-ui-kit';

type ResidualForecastEditActionsProps = {
  onEdit: () => void;
  onDelete: () => Promise<void>;
};

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default ({ onEdit, onDelete }: ResidualForecastEditActionsProps) => {
  const [isDeleting, setIsDeleting] = useState(false);
  return (
    <ActionIconsWrapper>
      {!isDeleting && (
        <StyledButton
          type="submit"
          onClick={() => {
            setIsDeleting(true);
            onDelete().then(() => setIsDeleting(false));
          }}
        >
          <Icon type="trash" tooltip="Delete Investment Override" />
        </StyledButton>
      )}
      <StyledButton type="submit" onClick={onEdit}>
        <Icon type="edit" tooltip="Edit Investment Override" />
      </StyledButton>
    </ActionIconsWrapper>
  );
};

const ActionIconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledButton = styled.button`
  padding: 4px;
`;
