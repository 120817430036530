import type { CellClassParams, ColDef, ValueGetterParams } from 'ag-grid-community';
import React from 'react';
import type { PrivatePerformanceSummaryResponse, PrivatesAnalysisResponse } from 'venn-api';
import { useBlockId } from '../../contexts/BlockIdContext';
import { JUSTIFY_HEADER_END_CLASS, METADATA_GREY_BG_CLASS } from '../../customAnalysisContants';
import { useCommonGridProps } from '../../logic/columnUtils';
import { DATA_TYPE_EXCEL_STYLES } from '../../logic/customBlockUtils';
import { useIsBlockGridCompact } from '../../logic/useIsBlockGridCompact';
import ExportableGrid from './ExportableGrid';
import {
  type ParsedPrivatePerformanceSummaryResponse,
  usePrivatesPerformanceSummaryDataGrid,
} from '../../logic/usePrivatesPerformanceSummaryDataGrid';

type PrivatePerformanceSummaryGridProps = Readonly<{
  data: PrivatesAnalysisResponse;
  isExportable: boolean;
}>;

const gridOverrides: ColDef = {
  cellClassRules: {
    [METADATA_GREY_BG_CLASS]: ({ data }) => data.isMetadata,
  },
};

export const PrivatePerformanceSummaryGrid = ({ data, isExportable }: PrivatePerformanceSummaryGridProps) => {
  const blockId = useBlockId();
  const { rowData, columnDefs } = usePrivatesPerformanceSummaryDataGrid(data.performanceSummary);
  const isCompact = useIsBlockGridCompact();

  const commonGridProps = useCommonGridProps(gridOverrides);

  return (
    <ExportableGrid
      {...commonGridProps}
      exportable={isExportable}
      selectedRefId={blockId}
      rowData={rowData}
      columnDefs={columnDefs}
      excelStyles={DATA_TYPE_EXCEL_STYLES}
      isCompact={isCompact}
      tooltipShowDelay={1200}
      suppressRowVirtualisation
      treeData
      getDataPath={(data: ParsedPrivatePerformanceSummaryResponse) => {
        return data.path;
      }}
      groupDefaultExpanded={-1}
      autoGroupColumnDef={{
        flex: 2,
        minWidth: 200,
        wrapText: true,
        autoHeight: true,
        headerName: 'Name',
        field: 'path',
        headerClass: () => [JUSTIFY_HEADER_END_CLASS],
        valueGetter: (params: ValueGetterParams<ParsedPrivatePerformanceSummaryResponse>) => params.data?.name,
        cellStyle: (params: CellClassParams<PrivatePerformanceSummaryResponse>) => {
          const { level } = params.node;
          return {
            fontWeight: level === 0 ? 'bold' : 'inherit',
            paddingLeft: level * 16 + 8,
          };
        },
        cellRendererParams: {
          suppressCount: true,
          suppressDoubleClickExpand: true,
        },
      }}
    />
  );
};
