import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { type ReactText, useRef } from 'react';
import { type CashFlowSetting, deleteCashflowSettings } from 'venn-api';
import { Modal, ModalDescription, ModalFooter, ModalHeader, ModalSize, ModalSubhead } from 'venn-components';
import { GetColor, Notifications, NotificationType, ZIndex } from 'venn-ui-kit';
import { WORKSPACE_CASHFLOW_SETTINGS_KEY } from '../../utils/cashflow-settings/queries';
import { assertNotNil } from 'venn-utils';
import styled from 'styled-components';

type CashflowSettingsDeletionModalProps = Readonly<{
  setting: CashFlowSetting;
  onClose: () => void;
}>;

const useDeleteSettingMutation = (settingName: string, onSuccess: () => Promise<void>) => {
  const toastRef = useRef<ReactText>();

  return useMutation(
    async (settingId: string) => {
      await deleteCashflowSettings([settingId]);
    },
    {
      onMutate: () => {
        toastRef.current = Notifications.notify(`Deleting ${settingName}...`, NotificationType.LOADING);
      },
      onError: () => {
        Notifications.notifyUpdate(toastRef.current, `Failed to delete ${settingName}`, NotificationType.ERROR);
      },
      onSuccess: async () => {
        Notifications.notifyUpdate(toastRef.current, `${settingName} deleted successfully`, NotificationType.SUCCESS);
        await onSuccess();
      },
    },
  );
};

const CashflowSettingDeletionModal = ({ setting, onClose }: CashflowSettingsDeletionModalProps) => {
  const queryClient = useQueryClient();
  const mutation = useDeleteSettingMutation(setting.name, async () => {
    onClose();
    await queryClient.invalidateQueries({ queryKey: [WORKSPACE_CASHFLOW_SETTINGS_KEY] });
  });

  return (
    <Modal
      size={ModalSize.Small}
      zIndex={ZIndex.ModalFront}
      closeOnEsc
      onClose={onClose}
      testId="cashflow-setting-deletion-modal"
    >
      <ModalHeader>Are you sure you want to delete the following setting?</ModalHeader>
      <ModalSubhead>{setting.name}</ModalSubhead>
      <StyledModalDescription>
        By clicking Delete below, any funds using this setting will automatically switch back to the Venn Default
        Setting. This action cannot be undone.
      </StyledModalDescription>
      <ModalFooter
        destructive
        cancelLabel="Cancel"
        onCancel={onClose}
        primaryLabel="Delete"
        onPrimaryClick={() => mutation.mutate(assertNotNil(setting.settingId))}
      />
    </Modal>
  );
};

const StyledModalDescription = styled(ModalDescription)`
  font-weight: bold;
  color: ${GetColor.Black};
`;
export default CashflowSettingDeletionModal;
