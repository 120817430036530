import { compact } from 'lodash';
import React, { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { withResizeDetector } from 'react-resize-detector';
import { useRecoilValue } from 'recoil';
import styled, { css, ThemeContext } from 'styled-components';
import type { ComputedInvestmentResidual, Fund, OperationResult, Portfolio } from 'venn-api';
import { getBatchInvestmentResiduals, getFund, getInvestmentResidual, getSpecificPortfolioV3 } from 'venn-api';
import { GetColor, Pagination, TablePlaceholder, ZIndex } from 'venn-ui-kit';
import type { AnalysisSubject } from 'venn-utils';
import { logExceptionIntoSentry, logExhaustive, recursiveGetFunds, useFetchApi, useHasFF } from 'venn-utils';
import { forceZeroResidualForecastChangedAtom } from '../../../venn-state/src';
import BasicTable, { SORTDIR } from '../basictable/BasicTable';
import PortfoliosContext from '../contexts/portfolios-context';
import EmptyState from '../empty-state/EmptyState';
import type { UseRangeAnalysisReturn } from '../hooks/useRangeAnalysis';
import { PlaceholderWrapper } from '../manage-data';
import { getBulkManagementColumns, getBulkManagementData, getSortTableFunc } from './columns';
import type { BulkManageRow } from './types';
import { BulkManageAction } from './types';

const MAX_ROWS = 20;

interface BulkManagementTableProps {
  subject: AnalysisSubject;
  useRangeAnalysisReturn: UseRangeAnalysisReturn;
  onFundUpdated?: (fundId: string) => void;
  width: number;
  canEditForecasts: boolean;
  canEditProxies: boolean;
}

const BulkManagementTable: React.FC<React.PropsWithChildren<BulkManagementTableProps>> = ({
  subject,
  useRangeAnalysisReturn,
  onFundUpdated,
  width,
  canEditForecasts,
  canEditProxies,
}) => {
  const hasDateRangeInDataFF = useHasFF('date_range_in_data_ff');
  const { rangeAnalysis, refresh: refreshRange, analysisRequest: rangeAnalysisRequest } = useRangeAnalysisReturn;
  const fundIds = useMemo(() => {
    const allFunds = new Set(recursiveGetFunds(subject.portfolio));
    if (subject.activeBenchmarkType === 'investment' && typeof subject.activeBenchmarkId === 'string') {
      allFunds.add(subject.activeBenchmarkId);
    }
    if (subject.categoryGroup) {
      allFunds.add(subject.categoryGroup.categoryId);
    }
    return [...allFunds];
  }, [subject.portfolio, subject.activeBenchmarkId, subject.activeBenchmarkType, subject.categoryGroup]);
  const { loading: fundsLoading, result: fetchedFunds } = useFetchApi(fetchManyFunds, fundIds);
  const [data, setData] = useState<BulkManageRow[]>([]);
  const theme = useContext(ThemeContext);
  const [page, setPage] = useState(1);
  const [loadingNextPage, setLoadingNextPage] = useState(false);
  const [sortDir, setSortDir] = useState<SORTDIR>();
  const [sortKey, setSortKey] = useState<string>();

  const [funds, setFunds] = useState<Fund[]>([]);
  useLayoutEffect(() => {
    setFunds(compact(fetchedFunds));
  }, [setFunds, fetchedFunds]);

  const refreshSingleFund = useCallback(
    async (id: string) => {
      const newData = data.map((row) =>
        row.investmentId === id
          ? {
              ...row,
              investmentLoading: true,
            }
          : row,
      );
      setData(newData);
      const fund = await getFund(id).then((r) => r.content);
      const newFunds = funds.map((originalFund) => (originalFund.id === fund.id ? fund : originalFund));
      setFunds(newFunds);
    },
    [setFunds, funds, setData, data],
  );

  const { masterPortfolio } = useContext(PortfoliosContext);
  const secondaryPortfolio = subject.secondaryPortfolioComparisonType === 'MASTER' ? masterPortfolio : undefined;
  const { result: benchmarkPortfolio } = useFetchApi(fetchBenchmarkPortfolio, subject.activeBenchmarkId);

  /* Residual forecast lifecycle is distinct from that of the fund object as the data has to be fetched separately */
  const [residualForecasts, setResidualForecasts] = useState<{ [key: string]: ComputedInvestmentResidual } | undefined>(
    undefined,
  );
  const fundIdsForResidualForecasts = useMemo(
    () => (subject.fund?.id ? [...fundIds, subject.fund?.id] : fundIds),
    [fundIds, subject.fund],
  );
  const { result: fetchedResidualForecasts, refresh: refreshResidualForecasts } = useFetchApi(
    getBatchInvestmentResiduals,
    fundIdsForResidualForecasts,
  );
  useLayoutEffect(() => {
    setResidualForecasts(fetchedResidualForecasts);
  }, [fetchedResidualForecasts]);
  const forceZeroResidualForecastChanged = useRecoilValue(forceZeroResidualForecastChangedAtom);

  useEffect(() => {
    refreshResidualForecasts();
  }, [forceZeroResidualForecastChanged, refreshResidualForecasts]);

  /* Re-sync the residual data for a single investment. Do not override all others. */
  const refreshSingleForecastResidual = useCallback(
    async (id: string) => {
      const newResidual = (await getInvestmentResidual(id)).content;

      const updatedResidualForecasts = {
        ...residualForecasts,
        [id]: newResidual,
      };
      setResidualForecasts(updatedResidualForecasts);
    },
    [residualForecasts],
  );

  const derivedData = useMemo(
    () =>
      getBulkManagementData(
        subject,
        funds,
        rangeAnalysis,
        rangeAnalysisRequest,
        secondaryPortfolio,
        benchmarkPortfolio,
        residualForecasts,
      ),
    [subject, funds, rangeAnalysis, rangeAnalysisRequest, secondaryPortfolio, benchmarkPortfolio, residualForecasts],
  );

  useLayoutEffect(() => {
    setData(derivedData);
  }, [derivedData, setData]);

  const handleRowUpdate = useCallback(
    async (action: BulkManageAction, row: BulkManageRow, investmentId?: string) => {
      switch (action) {
        case BulkManageAction.FUND_MODIFIED:
          await refreshRange();
          if (row.investmentId) {
            onFundUpdated?.(row.investmentId);
            await refreshSingleFund(row.investmentId);
          }
          break;
        case BulkManageAction.INVESTMENT_FORECAST_MODIFIED:
          if (row.investmentId) {
            await refreshSingleForecastResidual(investmentId ?? row.investmentId);
          }
          break;
        default:
          logExhaustive(action);
      }
    },
    [refreshRange, onFundUpdated, refreshSingleFund, refreshSingleForecastResidual],
  );

  const columns = useMemo(
    () =>
      getBulkManagementColumns(
        subject,
        rangeAnalysis,
        handleRowUpdate,
        theme,
        canEditForecasts,
        canEditProxies,
        width,
        sortKey,
        sortDir,
      ),
    [subject, rangeAnalysis, handleRowUpdate, theme, canEditForecasts, canEditProxies, width, sortKey, sortDir],
  );

  const onChangePage = useCallback((page: number) => {
    setLoadingNextPage(true);
    setTimeout(() => {
      setPage(page);
      setLoadingNextPage(false);
    }, 0);
  }, []);

  const onSort = useCallback((sortKey: string, sortDir: SORTDIR) => {
    setLoadingNextPage(true);

    setTimeout(() => {
      setPage(1);
      setSortKey(sortKey);
      setSortDir(sortDir);
      setLoadingNextPage(false);
    }, 0);
  }, []);

  const filteredData = useMemo(() => {
    const sortFunc = getSortTableFunc(sortKey);
    const sortedData = sortFunc ? sortFunc(data, sortDir ?? SORTDIR.ASC) : data;

    if (!hasDateRangeInDataFF) {
      return sortedData;
    }
    return sortedData.slice((page - 1) * MAX_ROWS, page * MAX_ROWS);
  }, [sortKey, data, sortDir, hasDateRangeInDataFF, page]);

  if (fundIds.length === 0 && !subject.fund) {
    return <EmptyState header="There are no investments in this portfolio." />;
  }

  return (
    <>
      {!fundsLoading ? (
        <Wrapper>
          {data.length > MAX_ROWS && hasDateRangeInDataFF && (
            <PaginationWrapper>
              <Pagination
                pagesCount={Math.ceil(data.length / MAX_ROWS)}
                selectedPage={page}
                onPageChange={onChangePage}
              />
            </PaginationWrapper>
          )}
          <StyledTable
            stickyHeaderOffset={245}
            overlayClassName="bulk-management-table-wrapper"
            data={filteredData}
            columns={columns}
            rowStyle={getRowStyle}
            onSort={onSort}
            sortDir={sortDir}
          />
          {loadingNextPage && (
            <LoadingWrapper>
              <TablePlaceholder />
            </LoadingWrapper>
          )}
        </Wrapper>
      ) : (
        <PlaceholderWrapper>
          <TablePlaceholder />
        </PlaceholderWrapper>
      )}
    </>
  );
};

const PaginationWrapper = styled.div`
  & > div {
    text-align: right;
  }
`;

const LoadingWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 20px;
  bottom: 0;
  background: ${GetColor.White};

  && {
    width: calc(100% + 60px);
  }
`;

const getRowStyle = (data: BulkManageRow) =>
  data.secondary
    ? css`
        background-color: ${GetColor.PaleGrey};
      `
    : css``;

const Wrapper = styled.div`
  width: 100%;
  position: relative;

  .bulk-management-table-wrapper,
  & > div {
    width: 100%;
  }
`;

const StyledTable = styled(({ stickyHeaderOffset, ...rest }) => <BasicTable {...rest} />)<{
  stickyHeaderOffset: number;
}>`
  font-size: 14px;

  > thead {
    tr {
      th {
        height: 36px;
        padding-left: 6px;
        text-align: left;
        position: sticky;
        top: ${({ stickyHeaderOffset }) => stickyHeaderOffset}px;
        border-bottom: 1px solid ${GetColor.Black};
        box-shadow: 0px 1px 0px ${GetColor.Black};
        background-color: ${GetColor.White};
        z-index: ${ZIndex.Front};

        &:first-child {
          padding-left: 20px;
        }
      }
    }
  }

  > tbody {
    > tr {
      &:hover {
        background-color: ${GetColor.Primary.Light};
      }

      &:last-child {
        border-top: 2px solid ${GetColor.Grey};
        border-bottom: 2px solid ${GetColor.Grey};
      }

      > td {
        height: 36px;
        padding: 0;
        border: 1px solid ${GetColor.Grey};

        &:first-child {
          border-left: none;
        }

        &:last-child {
          border-right: none;
        }
      }
    }
  }

  > tfoot {
    font-weight: bold;
    font-size: 11px;
  }
`;

/**
 * Small function to emulate the behaviour of a {@link getFund} request that accepts multiple ids.
 */
const fetchManyFunds = async (ids: string[]) => {
  const funds = await Promise.all(
    ids.map((id) =>
      getFund(id).then(
        (resp) => resp.content,
        (e) => {
          logExceptionIntoSentry(e);
          return undefined;
        },
      ),
    ),
  );
  return {
    status: 200,
    headers: {},
    content: funds,
  };
};

const fetchBenchmarkPortfolio = async (
  benchmarkId?: string | number,
): Promise<OperationResult<Portfolio | undefined>> => {
  // if it's not a portfolio id, just return a response with undefined content
  if (typeof benchmarkId !== 'number') {
    return {
      status: 200,
      headers: {},
      content: undefined,
    };
  }
  return getSpecificPortfolioV3(benchmarkId);
};

export default withResizeDetector(BulkManagementTable);
