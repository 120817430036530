import { useContext, useMemo } from 'react';
import { ThemeContext } from 'styled-components';
import { useRecoilValue } from 'recoil';
import type { SubjectWithOptionalFee } from 'venn-state';
import { colorIndicesForSubjectsState } from 'venn-state';
import { getColorOnIndex } from 'venn-ui-kit';

export const useSubjectColors = (subjects: SubjectWithOptionalFee[]) => {
  const { Colors } = useContext(ThemeContext);
  const subjectColorIndices = useRecoilValue(colorIndicesForSubjectsState(subjects));
  return useMemo(
    () => subjectColorIndices.map((colorIndex) => getColorOnIndex(Colors, colorIndex)),
    [Colors, subjectColorIndices],
  );
};
