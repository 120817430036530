import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';

export const Footer: FC<React.PropsWithChildren<{ className?: string }>> = ({ children, className }) => (
  <StyledFooter className={className}>{children}</StyledFooter>
);

export default Footer;

const StyledFooter = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  border-top: 1px solid ${GetColor.Grey};
  padding: 0 20px;

  button {
    min-height: 25px;
    height: 25px;
    padding: 0;
  }
`;
