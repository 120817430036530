import React, { useContext, useState, useCallback } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Icon, STUDIO_REPORT_LAB_AIS_FAQ_HREF, GetColor, Body1 } from 'venn-ui-kit';
import { Modal, ModalHeader, ModalSubhead, ModalFooter, ModalContent, ModalSize, useUser } from 'venn-components';
import { VISITED_AIS_KEY } from 'venn-api';

/**
 * This component, when rendered will render a modal asking the user to confirm view.
 * Once confirmed a request will be dispatched to the backend and this dialog will
 * not be rendered again. If the user closes the browser window, the dialog will
 * continue to appear until it is closed with the close button.
 */
export const FirstTimeAISModal = () => {
  const { settings, updateSettings } = useUser();
  const { Videos } = useContext(ThemeContext);

  const hasVisitedAis = settings.user[VISITED_AIS_KEY];
  const initiallyOpen = !hasVisitedAis;
  const [open, setOpen] = useState(initiallyOpen);

  const setAisVisited = useCallback(() => {
    updateSettings({
      [VISITED_AIS_KEY]: true,
    });
  }, [updateSettings]);

  const submit = useCallback(() => {
    setAisVisited();
    setOpen(false);
  }, [setAisVisited]);

  return open ? (
    <React.Suspense fallback={null}>
      <Modal size={ModalSize.Large}>
        <ModalHeader>We made some recent improvements to Venn’s input settings!</ModalHeader>
        <ModalSubhead>Check out the video below for a quick, 6 minute tour.</ModalSubhead>
        <ModalContent>
          <Video controls src={Videos.aisDemoVideo} />
          <Body1>
            You may notice subtle differences between this tutorial and the Venn Application. We are continuously
            improving all areas of Venn based on your feedback.
          </Body1>
        </ModalContent>
        <ModalFooter
          primaryLabel={
            <AISFaqLink rel="noopener noreferrer" target="_blank" href={STUDIO_REPORT_LAB_AIS_FAQ_HREF}>
              <Icon type="info-circle" /> View Additional Information
            </AISFaqLink>
          }
          onPrimaryClick={setAisVisited}
          cancelLabel="Close"
          onCancel={submit}
        />
      </Modal>
    </React.Suspense>
  ) : null;
};

const AISFaqLink = styled.a`
  color: ${GetColor.Black};
`;

const Video = styled.video`
  width: 100%;
`;
