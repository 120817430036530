import { isNil } from 'lodash';
import type { FeatureName } from '.';
import { logExceptionIntoSentry } from '../error-logging';
import { getFeatureMap } from './state/staticState';
import { AvailabilityStatus, UserFeature } from 'venn-api-client/public_api/misc/v1/feature_availability_pb';

/** You should prefer to use hooks if possible instead of these static methods, because these static methods are not reactive to changes in feature flags. */
export class FS {
  /** Logging to sentry during tests is incredibly spammy and constitutes the bulk of our logs. So instead we turn off logging during testing by default. */
  static shouldLogToSentry = process.env.NODE_ENV !== 'test';

  /**
   * Returns whether the given feature is available (taking into account debug overrides).
   * Prefer to use the useHasFF hook instead, if possible.
   */
  static has(featureName: FeatureName): boolean {
    const ffMap = getFeatureMap();
    if (!ffMap) {
      FS.shouldLogToSentry &&
        logExceptionIntoSentry(
          new Error(
            'Features have not been loaded. Utilize useInitializeFeatures() before checking for feature availability',
          ),
        );
      return false;
    }
    return ffMap[featureName]?.status === AvailabilityStatus.AVAILABLE;
  }

  /**
   * Returns whether all the given features are available
   *
   * Prefer to use the useHasAllFFs hook instead, if possible.
   */
  static hasAll(featureNames?: FeatureName[]): boolean {
    if (!featureNames) return true;
    return featureNames.every(FS.has);
  }

  /**
   * Returns the entire feature object with usage count and threshold info
   * or undefined if the feature is completely not available, i.e. threshold is 0.
   *
   * Prefer to use the useFFDetails hook instead, if possible.
   */
  static getFeatureDetails(featureName: FeatureName): UserFeature | undefined {
    const ffMap = getFeatureMap();
    if (!ffMap) {
      FS.shouldLogToSentry &&
        logExceptionIntoSentry(
          new Error(
            'Features have not been loaded. Utilize useInitializeFeatures() before checking for feature details',
          ),
        );
      return undefined;
    }

    const feature = ffMap[featureName];

    if (isNil(feature)) {
      return undefined;
    }

    return new UserFeature({
      ...feature,
      usageCount: feature.usageCount ?? 0,
    });
  }
}
