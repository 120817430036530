import compact from 'lodash/compact';
import range from 'lodash/range';
import JSZip from 'jszip';

import type { ExportDataKeyValue } from 'venn-components';
import {
  generateCanvasFromHtml,
  onExportMultiSheets,
  getDownloadableImagesBlocks,
  getDownloadableDataKeys,
} from 'venn-components';
import type { UploadMetaData, ExportTypeEnum } from 'venn-api';
import { uploadSnapshotFile } from 'venn-api';
import moment from 'moment';
import type { ExcelCell, AnalysisSubject, AnyDuringEslintMigration, MetaData } from 'venn-utils';
import {
  logExceptionIntoSentry,
  getPortfolioNavs,
  formatDownloadMessage,
  formatGeneralDownloadMetaData,
  getSecondaryDisplayLabel,
} from 'venn-utils';
import type { Theme } from 'venn-ui-kit';
import getFooter from './getFooter';
import type { ExportMetaData } from './types';
import { getBlocksParams } from './getBlocksParams';
import { isNil } from 'lodash';

/**
 * By splitting all of the image generation microtasks into sets of macro tasks, it enables us to cede some macro-task time
 * back to the browser during image generation.
 *
 * Setting concurrency of ~9 is 10-20% faster than concurrency of 1, but the browser becomes much less responsive.
 *
 * See {@link https://javascript.info/event-loop#summary} for more information.
 */
const MAX_IMAGE_MICRO_TASK_CONCURRENCY = 2;
/** We don't need much delay between macro tasks because macro tasks are a queue and the microtask queue will complete before the macro task queue. */
const DELAY_BETWEEN_MACRO_TASKS_MS = 10;

/** Loop all images that has download-block class and convert them into a zip blob, then call the cb method with generated blob */
export const getImageZip = async (
  zip: JSZip,
  exportMetaData: ExportMetaData,
  cb: (blob: Blob, uploadMetaData: UploadMetaData, exportType: ExportTypeEnum) => void,
  theme: Theme,
  imageScale: number,
) => {
  const uploadMetaData = getUploadMetaData(exportMetaData);
  const images = zip.folder('images');
  const contents = getDownloadableImagesBlocks();
  if (contents?.length) {
    const contentsArr = [...contents];
    const exportFooter = getFooter(exportMetaData);

    const macroTasks: (() => Promise<unknown>)[] = [];
    for (let i = 0; i < contents.length; i += MAX_IMAGE_MICRO_TASK_CONCURRENCY) {
      macroTasks.push(() =>
        Promise.allSettled(
          contentsArr
            .slice(i, i + MAX_IMAGE_MICRO_TASK_CONCURRENCY)
            .map((content, index) =>
              generateSingleImage(
                content,
                exportFooter,
                content.dataset.title || content.querySelector('h3')?.innerText || `Analysis${index}`,
              ),
            ),
        ),
      );
    }

    await new Promise<void>((resolveTasksCompleted) => {
      const executeMacroTask = async (index: number) => {
        await macroTasks[index]();

        // Check index bounds here (instead of after the setTimeout) to avoid an unnecessary setTimeout delay after the last task.
        const nextIndex = index + 1;
        if (nextIndex >= macroTasks.length) {
          resolveTasksCompleted();
          return;
        }
        setTimeout(() => executeMacroTask(nextIndex), DELAY_BETWEEN_MACRO_TASKS_MS);
      };
      executeMacroTask(0);
    });
  }

  images.file(
    'metadata.txt',
    formatMetadata(exportMetaData)
      .map((labelAndValue) => labelAndValue.join(''))
      .join('\n'),
  );

  const { subject } = exportMetaData.analysisConfig;
  const strategy = subject?.strategy;
  if (!subject?.isInvestmentInPortfolio && strategy) {
    images.file(
      `NAVs-${subject?.name}.txt`,
      convertExcellToString(
        getPortfolioNavs(
          strategy,
          subject?.secondaryPortfolio,
          getComparisonLabel(subject),
          exportMetaData.isPercentageMode,
        ),
      ),
    );
  }

  zip
    .generateAsync({
      type: 'blob',
    })
    .then((blob) => {
      cb(blob, uploadMetaData, 'PNG');
    });

  async function generateSingleImage(content: HTMLElement, exportFooter: JSX.Element | undefined, imageTitle: string) {
    const watermark = { top: 20, right: 20 };
    const padding = { top: 20, right: 20, bottom: 20, left: 20 };
    const width = content.clientWidth;

    const canvas = await generateCanvasFromHtml(
      content.outerHTML,
      watermark,
      padding,
      theme,
      width,
      exportFooter,
      undefined,
      undefined,
      imageScale,
    );

    const fileName = `${imageTitle}.png`;
    const fileBlob = await new Promise<Blob | null>((r) => canvas.toBlob(r));
    if (fileBlob) {
      images.file(fileName, fileBlob, { binary: true });
    }
  }
};

/** Loop all data in exportData and convert them into a zip blob, then call the cb method with generated blob */
export const getDataZip = (
  zip: JSZip,
  exportData: ExportDataKeyValue,
  exportMetaData: ExportMetaData,
  cb: (blob: Blob | null, metaData: UploadMetaData, exportType: ExportTypeEnum) => void,
) => {
  const fileKeys = getDownloadableDataKeys(exportData);
  const uploadMetaData = getUploadMetaData(exportMetaData);

  if (fileKeys.length === 0) {
    cb(null, uploadMetaData, 'XLSX');
    return;
  }
  const sheetsData = fileKeys.map((item: string) => ({ sheetName: item, data: exportData[item]?.() }));
  const { subject } = exportMetaData.analysisConfig;
  const strategy = subject?.strategy;
  if (!subject?.isInvestmentInPortfolio && strategy) {
    const portfolioNavsTab = {
      sheetName: `NAVs-${subject?.name} `,
      data: getPortfolioNavs(
        strategy,
        subject?.secondaryPortfolio,
        getComparisonLabel(subject),
        exportMetaData.isPercentageMode,
      ),
    };
    sheetsData.unshift(portfolioNavsTab);
  }

  const metaTab = {
    sheetName: 'metadata',
    data: formatMetadata(exportMetaData).map((item) => [{ value: item[0], bold: true }, { value: item[1] }]),
  };
  sheetsData.unshift(metaTab);

  onExportMultiSheets(sheetsData, 'Data', (wb, name) => {
    // TODO: (VENN-20577 / TYPES) There are types packages for xlsxPopulate that we could start using or fork.
    (wb as AnyDuringEslintMigration).outputAsync().then((blob: Blob) => {
      zip.file(name, blob);

      zip
        .generateAsync({
          type: 'blob',
        })
        .then((blobzip) => {
          cb(blobzip, uploadMetaData, 'XLSX');
        });
    });
  });
};

/** Save the file into database */
export const autoSaveFile = (content: Blob, metaData: UploadMetaData) => {
  try {
    uploadSnapshotFile(content, metaData);
  } catch (e) {
    logExceptionIntoSentry(e);
  }
};

/** Get bundle file with images and csv. Then call the cb method that save the target zip file.
 * Auto save the bundle file (images + csv) to database
 * */
export const getBundleZip = (
  exportData: ExportDataKeyValue,
  zip: JSZip,
  exportMetaData: ExportMetaData,
  cb: (blob: Blob | null, metaData: UploadMetaData, exportType: ExportTypeEnum) => void,
  theme: Theme,
  imageScale: number,
) => {
  const uploadMetaData = getUploadMetaData(exportMetaData);
  getImageZip(
    new JSZip(),
    exportMetaData,
    (imagesBlob: Blob) => {
      zip.file('Images.zip', imagesBlob);
      getDataZip(new JSZip(), exportData, exportMetaData, (csvBlob: Blob | null) => {
        if (csvBlob != null) {
          zip.file('Data.zip', csvBlob);
        }
        zip.generateAsync({ type: 'blob' }).then((bundleBlob) => {
          cb(bundleBlob, uploadMetaData, 'ALL');
        });
      });
    },
    theme,
    imageScale,
  );
};

/** Add timestamp to analysis name */
export const generateZipFileName = (analysisName: string): string => {
  const timestamp = moment().format('YYYY-MM-DD hh:mm A');
  return `${analysisName}-${timestamp}.zip`;
};

export const formatMetadata = (exportMetaData: ExportMetaData): MetaData[] => {
  const fileName: MetaData = [exportMetaData.viewName, ''];
  const { analysisConfig, workspaceCurrency, compare, isPercentageMode, startDate, endDate, frequency } =
    exportMetaData;
  const { relative, subject } = analysisConfig;
  const templateName = formatDownloadMessage('Template', analysisConfig.analysisTemplate?.name);

  const generalMetaData = formatGeneralDownloadMetaData({
    type: subject?.type,
    subjectName: subject?.name || '',
    benchmarkName: subject?.activeBenchmarkName,
    startTime: startDate,
    endTime: endDate,
    frequency,
    relative,
    proxyName: subject?.fund?.proxyName,
    proxyType: subject?.fund?.proxyType,
    secondaryName:
      !compare || compare === 'none'
        ? undefined
        : compare === 'saved' && subject?.secondaryPortfolio
          ? getSecondaryDisplayLabel(subject, `${subject.secondaryPortfolio.name} as of`)
          : compare,
    extrapolate: subject?.fund?.extrapolate,
  });

  const isPercentage = subject?.portfolio
    ? formatDownloadMessage('Percentage Mode', isPercentageMode ? 'On' : 'Off')
    : undefined;
  const investmentCurrency = subject?.fund?.currency
    ? formatDownloadMessage('Investment Currency', subject?.fund?.currency)
    : undefined;
  const workspaceCurrencyValue = formatDownloadMessage('Workspace Currency', workspaceCurrency);
  const categoryName = subject?.fund?.categoryGroup?.name
    ? formatDownloadMessage('Category', subject?.fund?.categoryGroup?.name)
    : undefined;
  const blocksParams = getBlocksParams(exportMetaData, analysisConfig.analysisTemplate?.analysisBlocks);
  return [
    fileName,
    templateName,
    ...generalMetaData,
    ...compact([categoryName, investmentCurrency, isPercentage, workspaceCurrencyValue]),
    ...blocksParams,
  ];
};

const getUploadMetaData = (exportMetaData: ExportMetaData): UploadMetaData => ({
  name: exportMetaData.viewName,
  subjectId: exportMetaData.analysisConfig.subject?.id || '',
  subjectName: exportMetaData.analysisConfig.subject?.name,
  startDate: exportMetaData.startDate,
  endDate: exportMetaData.endDate,
  savedId: exportMetaData.savedId,
  benchmarkName: exportMetaData.analysisConfig.subject?.activeBenchmarkName,
  templateId: exportMetaData.analysisConfig.analysisTemplate?.id,
  templateName: exportMetaData.analysisConfig.analysisTemplate?.name,
});

const convertExcellToString = (excelData: ExcelCell[][]): string => {
  let str = '';
  excelData.forEach((line: ExcelCell[]) => {
    const lineItem = line.map((data) => {
      let lineStr = '';
      if (data) {
        if (data.style?.indent) {
          const spaces = range(data.style?.indent)
            .map(() => '')
            .join(' ');
          lineStr = `${lineStr}${spaces}`;
        }
        const value = isNil(data.value) ? '--' : data.percentage ? `${Number(data.value) * 100}%` : data.value;
        const currencyValue = !isNil(data.value) && data.currency ? `$${value}` : value;
        lineStr = `${lineStr}${currencyValue}`;
      }
      return lineStr;
    });
    str = `${str}${lineItem.join(', ')}\n`;
  });
  return str;
};

export const getComparisonLabel = (subject?: AnalysisSubject): string | undefined => {
  const secondaryLabel = subject?.secondaryLabel;
  return isNil(secondaryLabel) || isNil(subject)
    ? undefined
    : secondaryLabel === 'Last Saved'
      ? getSecondaryDisplayLabel(subject, `${subject.secondaryPortfolio?.name} as of`)
      : `${secondaryLabel} Portfolio`;
};
