import React from 'react';
import styled from 'styled-components';
import { Button, Icon, GetColor } from 'venn-ui-kit';
import type { FontAwesomePrefixes } from 'venn-utils';

interface IconButtonProps {
  onClick: () => void;
  iconType: string;
  /** [Font Awesome](https://fontawesome.com/) prefixes */
  iconPrefix: FontAwesomePrefixes;
  title: string;
  line: string;
  hint?: string;
}
export class IconButton extends React.PureComponent<IconButtonProps> {
  render() {
    const { onClick, iconType, iconPrefix, title, line, hint } = this.props;
    return (
      <StyledIconButton onClick={onClick} dominant>
        <StyledIcon type={iconType} prefix={iconPrefix} />
        <ButtonText>
          <ButtonTitle>{title}</ButtonTitle>
          <ButtonLine>{line}</ButtonLine>
          {hint && <ButtonHint>{hint}</ButtonHint>}
        </ButtonText>
      </StyledIconButton>
    );
  }
}

const StyledIconButton = styled(Button)`
  font-size: 12px;
  font-weight: bold;
  color: ${GetColor.White};
  background-color: ${GetColor.Primary.Dark};
  border-radius: 4px;
  text-transform: none;
  letter-spacing: normal;
  text-align: left;
  width: 286px;
  height: 77px;
  padding: 12px 9px;
  justify-content: left;
  display: inline-block;

  p {
    margin: 0;
  }
`;

const ButtonTitle = styled.p`
  font-size: 12px;
  font-weight: bold;
`;

const ButtonLine = styled.p`
  font-size: 12px;
  font-weight: normal;
`;

const ButtonHint = styled.p`
  font-size: 10px;
  font-weight: normal;
`;

const ButtonText = styled.div`
  display: inline-block;
  vertical-align: middle;
  padding-left: 9px;
`;

const StyledIcon = styled(Icon)`
  font-size: 36px;
  width: 44px;
  vertical-align: middle;
  text-align: center;
`;
