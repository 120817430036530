import React, { useCallback } from 'react';

import type { FactorExposure, Portfolio } from 'venn-api';
import type { FactorExposureConstraint } from 'venn-utils';

import FactorExposureConstraintsManagement from './FactorExposureConstraintsManagement';

interface UncontrolledFactorExposureConstraintsManagementProps {
  portfolio: Portfolio;
  factorExposures: FactorExposure[];
  factorConstraints: FactorExposureConstraint[];
  onUpdateFactorConstraints: (constraints: FactorExposureConstraint[]) => Promise<void>;
  onApplyConstraintToPolicy?: (constraint: FactorExposureConstraint) => void;
  alwaysShowAllFactors?: boolean;
  canApplyConstraintsToPolicy?: boolean;
  disabled?: boolean;
}

const UncontrolledFactorExposureConstraintsManagement = ({
  portfolio,
  factorConstraints,
  factorExposures,
  onUpdateFactorConstraints,
  onApplyConstraintToPolicy,
  alwaysShowAllFactors,
  canApplyConstraintsToPolicy,
  disabled,
}: UncontrolledFactorExposureConstraintsManagementProps) => {
  const onUpdateFactorConstraint = useCallback(
    async (constraint: FactorExposureConstraint) => {
      const updatedConstraints = factorConstraints.filter(
        (item) => item.factorId !== constraint.factorId || item.condition !== constraint.condition,
      );
      await onUpdateFactorConstraints([...updatedConstraints, constraint]);
    },
    [onUpdateFactorConstraints, factorConstraints],
  );

  const onApplyConstraint = useCallback(
    (constraint: FactorExposureConstraint) => {
      if (canApplyConstraintsToPolicy) {
        onApplyConstraintToPolicy?.(constraint);
      }
    },
    [canApplyConstraintsToPolicy, onApplyConstraintToPolicy],
  );

  const onDeleteConstraint = useCallback(
    (constraint: FactorExposureConstraint) => {
      const constraintIdx = factorConstraints.findIndex(
        ({ condition, factorId }) => condition === constraint.condition && factorId === constraint.factorId,
      );
      if (constraintIdx !== -1) {
        onUpdateFactorConstraints([
          ...factorConstraints.slice(0, constraintIdx),
          ...factorConstraints.slice(constraintIdx + 1),
        ]);
      }
    },
    [factorConstraints, onUpdateFactorConstraints],
  );

  return (
    <FactorExposureConstraintsManagement
      portfolio={portfolio}
      factorExposures={factorExposures}
      factorConstraints={factorConstraints}
      onUpdateFactorConstraint={onUpdateFactorConstraint}
      onDeleteConstraint={onDeleteConstraint}
      alwaysShowAllFactors={alwaysShowAllFactors}
      onApplyConstraint={onApplyConstraint}
      canApplyConstraintsToPolicy={canApplyConstraintsToPolicy}
      disabled={disabled}
    />
  );
};

export default UncontrolledFactorExposureConstraintsManagement;
