export enum ZIndex {
  Background = -1,

  /* Normal plan */
  Base = 0,
  Front = 1,
  Cover = 2,

  /* Sticky header plan */
  Sticky = 3,
  StickyFront = 4,
  StickyCover = 5,

  /* Left and top navigation bars plans */
  NavigationSlideout = 6,
  Navigation = 7,
  NavigationFront = 8,
  NavigationCover = 9,

  /* Full-page overlay plan */
  Modal = 10,
  ModalFront = 11,
  ModalCover = 12,
  Watermark = 9999,

  /** Intercom's z-index is 2147483001 */
  InterComFront = 2147483002,

  InterComFront2 = InterComFront + 10,
}

export const HighchartZIndex = {
  Serie: {
    Base: 0,
    Front: 1,
  },
  PlotBand: {
    BehindAll: 0,
    AboveGridBehindSeries: 3,
    AboveSeries: 4,
    AboveChart: 5,
  },
};
