import React from 'react';
import { blockMetrics, selectedBlockSettings } from 'venn-state';
import { useRecoilValue } from 'recoil';
import { PercentageContributionSettings } from './components/PercentageContributionSettings';
import { PanelSection } from '../../../PanelSection';
import { areCumulativeReturnMetrics, isFactorTrendMetric, isRollingMetric, useHasFF } from 'venn-utils';
import { VenncastSettings } from './components/VenncastSettings';
import { RollingPeriodSettings } from './components/RollingPeriodSettings';
import { compact } from 'lodash';

interface MoreSectionProps {
  selected: string;
}

const MoreSection = ({ selected }: MoreSectionProps) => {
  const hasStudioVenncastFF = useHasFF('studio_venncast_ff');

  const selectedMetrics = useRecoilValue(blockMetrics(selected));
  const blockSetting = useRecoilValue(selectedBlockSettings);
  const cumulativeReturnMetrics = areCumulativeReturnMetrics(selectedMetrics);
  const rollingSelected = isRollingMetric(selectedMetrics);
  const factorTrendMetricSelected = isFactorTrendMetric(selectedMetrics);

  const showVenncastSettings = hasStudioVenncastFF && cumulativeReturnMetrics;
  const showPercentageContributionSettings = blockSetting?.contributionToPercentage;
  const showRollingSettings = rollingSelected || factorTrendMetricSelected;

  const settingsComponents = compact([
    showVenncastSettings && <VenncastSettings selected={selected} />,
    showPercentageContributionSettings && <PercentageContributionSettings selected={selected} />,
    showRollingSettings && <RollingPeriodSettings selected={selected} />,
  ]);

  if (!settingsComponents.length) {
    return null;
  }

  return <PanelSection header="More Options">{settingsComponents}</PanelSection>;
};

export default MoreSection;
