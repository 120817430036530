import type { Portfolio } from 'venn-api';
import type { DropMenuItem } from 'venn-ui-kit';

export default (portfolio?: Portfolio) => {
  if (!portfolio) {
    return [];
  }
  return search(portfolio, [], 0);
};

const search = (strategy: Portfolio, array: DropMenuItem<Portfolio>[], level: number) => {
  if (!strategy.fund) {
    array.push({
      value: strategy,
      label: strategy.name,
      level,
    });

    if (strategy.children) {
      strategy.children.forEach((child) => search(child, array, level + 1));
    }
  }
  return array;
};
