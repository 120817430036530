import React from 'react';
import styled, { css } from 'styled-components';
import { type PrivatePortfolioNode } from 'venn-api';
import { Flexbox, PRIVATE_ASSET_LAB_FAQ_HREF } from 'venn-ui-kit';
import CreatePrivatePortfolioStepContainer from './CreatePrivatePortfolioStepContainer';
import { Header } from '../data-uploader/components/page-parts';
import { StyledFaqLink } from '../data-uploader/views/shared/layout';

interface CreatePrivatePortfolioStepProps {
  onCancel: () => void;
  onPrivatePortfolioCreated: (portfolio: PrivatePortfolioNode) => void;
  investments?: PrivatePortfolioNode[];
}

const CreatePrivatePortfolioStep = (props: CreatePrivatePortfolioStepProps) => {
  const header = (
    <StyledHeader>
      <h1>Create new privates portfolio</h1>
      <h2>
        Give your portfolio a name. Then, either select an existing portfolio template or create a new portfolio from
        scratch.
      </h2>
      <h3>
        Have more questions? Visit the <StyledFaqLink link={PRIVATE_ASSET_LAB_FAQ_HREF} />.
      </h3>
    </StyledHeader>
  );
  return (
    <CreatePrivatePortfolioStepContainer.Root>
      <CreatePrivatePortfolioStepContainer.Overlay>
        {header}
        <Flexbox direction="column" grow={1} alignItems="center" justifyContent="center">
          <Flexbox
            direction="column"
            gap={20}
            css={css`
              width: 500px;
            `}
          >
            <CreatePrivatePortfolioStepContainer.Body />
          </Flexbox>
        </Flexbox>
        <CreatePrivatePortfolioStepContainer.Footer {...props} useLegacyModalFooter primaryLabel="Create" />
      </CreatePrivatePortfolioStepContainer.Overlay>
    </CreatePrivatePortfolioStepContainer.Root>
  );
};

export default CreatePrivatePortfolioStep;

const StyledHeader = styled(Header)`
  padding-top: 0;
  padding-bottom: 25px;
`;
