import React, { useContext, useState } from 'react';
import { Modal, ModalSize, ModalFooter, UserContext, ModalHeader, ModalContent, ModalSubhead } from 'venn-components';
import { Icon, Button, GetColor, ColorUtils } from 'venn-ui-kit';
import styled, { css } from 'styled-components';
import type { LogoDetails } from 'venn-api';
import { isNil } from 'lodash';
import { buttonize } from 'venn-utils';

interface LogoManagementModalProps {
  onClose: () => void;
  allLogos: LogoDetails[];
  deleteLogo: (logoId?: string) => void;
  setDefaultLogo: (logoId?: string) => void;
  uploadOrganizationLogo: (input: HTMLInputElement | null, isDefault: boolean) => void;
}

const LogoManagementModal = ({
  onClose,
  allLogos,
  deleteLogo,
  setDefaultLogo,
  uploadOrganizationLogo,
}: LogoManagementModalProps) => {
  const { hasPermission } = useContext(UserContext);
  const [selected, setSelected] = useState<string | undefined>();
  const isReadOnly = !hasPermission('MANAGE_PRINT_CONFIGURATION');

  const isSelectedDefault = !isNil(selected) && !!allLogos.find((logo) => logo.id === selected)?.default;

  return (
    <StyledModal onClose={onClose} closeOnEsc size={ModalSize.Large} closeOnOutsideClick>
      <ModalHeader>Manage Organization Logos</ModalHeader>
      <ModalSubhead>Upload and set default organization logo to appear on your reports.</ModalSubhead>
      <StyledModalContent onClick={() => setSelected(undefined)}>
        {allLogos.map((logo) => (
          <BadgeWrapper key={logo.id}>
            {logo.default ? <DefaultBadge type="check-circle" /> : null}
            <LogoImage
              {...buttonize((event) => {
                setSelected(logo.id);
                event.stopPropagation();
              })}
              selected={selected === logo.id}
              src={`/api/printing/logo/${logo.id}`}
              alt={logo.id}
            />
          </BadgeWrapper>
        ))}
        <UploadLogo
          icon="file-image"
          iconPrefix="far"
          onClick={() => document.getElementById('logoUploadFileDialogId')?.click()}
          disabled={isReadOnly}
          flat
        >
          Upload
        </UploadLogo>
      </StyledModalContent>
      <ModalFooter
        onCancel={onClose}
        cancelClassName="qa-close-button"
        rightChildren={
          <input
            type="file"
            id="logoUploadFileDialogId"
            style={{ display: 'none' }}
            accept="image/*"
            onChange={(file) => uploadOrganizationLogo(file.target, false)}
          />
        }
        secondaryLabel="Delete"
        secondaryDisabled={isNil(selected) || isReadOnly}
        onSecondaryClick={() => {
          deleteLogo(selected);
          setSelected(undefined);
        }}
        primaryLabel="Set As Default"
        primaryDisabled={isNil(selected) || isSelectedDefault || isReadOnly}
        onPrimaryClick={() => {
          setDefaultLogo(selected);
          setSelected(undefined);
        }}
      />
    </StyledModal>
  );
};

export default LogoManagementModal;

const StyledModal = styled(Modal)`
  &.modal-wrapper {
    text-align: center;
    overflow-y: auto;
    max-height: 90%;
  }
`;
const StyledModalContent = styled(ModalContent)`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
`;

const tileCss = css`
  display: inline-block;
  width: 128px;
  height: 128px;
  object-fit: contain;
  margin: 5px;
  padding: 3px;
`;

const LogoImage = styled.img<{ selected?: boolean }>`
  ${tileCss}
  ${({ selected }) =>
    selected
      ? css`
          border: 3px solid ${GetColor.Primary.Main};
        `
      : css`
          border: 1px solid ${GetColor.Grey};
          padding: 5px;
        `}
  border-radius: 4px;
  &:hover {
    ${({ selected }) =>
      !selected &&
      css`
        cursor: pointer;
        border: 3px solid ${ColorUtils.hex2rgbaFrom(GetColor.Primary.Main, 0.5)};
        padding: 3px;
      `}
  }
`;

const UploadLogo = styled(Button)`
  ${tileCss}
  i {
    margin-right: 0;
    margin-bottom: 4px;
    font-size: 14px;
  }
  &:hover {
    background-color: ${ColorUtils.hex2rgbaFrom(GetColor.Primary.Dark, 0.1)};
  }
`;

const BadgeWrapper = styled.div``;
const DefaultBadge = styled(Icon)`
  position: absolute;
  padding: 10px;
  padding-left: 112px;
  color: ${GetColor.Primary.Main};
`;
