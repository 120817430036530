/*
 * Venn Platform API 1.0.0 Operations for "tags-v3"
 * Automatically generated file - do not modify!
 */

import type { TagsQuery } from '../types';
import type { OperationResult } from '../../utils';
import { buildRequestInit, extractError } from '../../utils';

/** Add a tag to the given set of funds and portfolios */
export async function addTagV3(body?: Partial<TagsQuery>, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = '/api/tags/v3/add';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Remove a tag from the given set of funds and portfolios */
export async function removeTagV3(body?: Partial<TagsQuery>, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = '/api/tags/v3/delete';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
