import type { FC } from 'react';
import React from 'react';
import { GetColor } from 'venn-ui-kit';
import styled from 'styled-components';

export interface ChartBordersProps {
  width: number;
  height: number;
}

const ChartBorders: FC<React.PropsWithChildren<ChartBordersProps>> = ({ width, height }) => (
  <Container>
    <line x1={0.5} y1={0} x2={width - 0.5} y2={0} />
    <line x1={0.5} y1={height} x2={width - 0.5} y2={height} />
  </Container>
);

export default ChartBorders;

const Container = styled.g`
  stroke: ${GetColor.PaleGrey};
`;
