import React from 'react';
import { FAStackWrapper, PrivateIcon, type PrivateIconProps } from './shared';
import { useColorTheme } from '../../themes/src/useColorTheme';
import { Icon } from '../icon/Icon';
import styled from 'styled-components';

export const PrivatePortfolioIcon = ({ color, className }: PrivateIconProps) => {
  const Colors = useColorTheme();

  return (
    <FAStackWrapper data-testid="qa-private-portfolio-icon" className={`fa-stack ${className ?? ''}`}>
      <Icon
        type="folder"
        className="fa-stack-2x"
        style={{ color: color ?? Colors.colors.DEPRECATED_DataLineColor.DarkBlue }}
      />
      <StyledPrivateIcon type="p" className="fa-stack-1x" />
    </FAStackWrapper>
  );
};

const StyledPrivateIcon = styled(PrivateIcon)`
  // Lower the P slightly, so that it is centered in the folder excluding the tab at the top of the folder
  top: 0.13em;
`;
