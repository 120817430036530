/**
 * @fileoverview contains a list of URL parameters used in the application, utilities to access them, as well as types and
 * assertions to help ensure they're used safely.
 */

import { identity } from 'lodash';
import type { DebugPrintPageState } from '../debug';
import { serializeObject, deserializeObject, serializeBoolean, deserializeBoolean } from '../type/serializable';

export enum UrlParam {
  FORCE_RECOILIZE = 'forceRecoilize',
  RELEASE_NOTES = 'releaseNotes',
  PRINT_DEBUG = 'printDebug',
  WORKSPACE_ID = 'workspaceId',
}

export type UrlSetting<T> = {
  defaultValue: T;
  serializer: (value: T) => string | undefined;
  deserializer: (value: string | undefined) => T;
} & {
  /** If false, setting undefined is ignored and the value will be re-synchronized to the URL if the URL param is cleared by any means. */
  allowUndefined: boolean;
};

const workspaceIdSetting: UrlSetting<string | number | undefined> = {
  defaultValue: '',
  serializer: identity,
  deserializer: identity,
  allowUndefined: false,
};

const printDebugSetting: UrlSetting<DebugPrintPageState | undefined> = {
  defaultValue: undefined,
  serializer: serializeObject,
  deserializer: deserializeObject,
  allowUndefined: true,
};

const releaseNotesSetting: UrlSetting<boolean | undefined> = {
  defaultValue: undefined,
  serializer: serializeBoolean,
  deserializer: deserializeBoolean,
  allowUndefined: true,
};
const forceRecoilizeSetting: UrlSetting<boolean | undefined> = {
  defaultValue: undefined,
  serializer: serializeBoolean,
  deserializer: deserializeBoolean,
  allowUndefined: true,
};

export const urlParamToSetting = {
  [UrlParam.FORCE_RECOILIZE]: forceRecoilizeSetting,
  [UrlParam.RELEASE_NOTES]: releaseNotesSetting,
  [UrlParam.PRINT_DEBUG]: printDebugSetting,
  [UrlParam.WORKSPACE_ID]: workspaceIdSetting,
};

export type UrlParamToType = {
  [k in keyof typeof urlParamToSetting]: (typeof urlParamToSetting)[k]['defaultValue'];
};

export const addUrlParam = <U extends UrlParam>(urlParams: URLSearchParams, key: U, value: UrlParamToType[U]) => {
  const { serializer = identity } = urlParamToSetting[key] as UrlSetting<typeof value>;
  const serializedValue = serializer(value);
  return urlParams.append(key, serializedValue ?? '');
};
