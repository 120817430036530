import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { GetColor, Headline3, Icon } from 'venn-ui-kit';
import { StyledLabel, GapWrapper, UploadImageText, PlusIcon } from '../shared';
import PageSection from './PageSection';
import { ChromePicker } from 'react-color';
import { useDebounceToGlobal, UserContext } from 'venn-components';
import type { ImageDetails } from 'venn-api';
import { buttonize } from 'venn-utils';
import { compact } from 'lodash';
import { validate as uuidValidate } from 'uuid';
import {
  DEFAULT_STOCK_PHOTO,
  isReportState,
  STOCK_PHOTOS,
  studioPrintOrientationType,
  viewCoverPageImage,
  viewPrimaryColor,
} from 'venn-state';
import { useStudioImages } from '../../logic/useStudioImages';
import { useRecoilState, useRecoilValue } from 'recoil';

const ReportingSection = () => {
  const { hasPermission } = useContext(UserContext);
  const isReport = useRecoilValue(isReportState);
  const [printOrientationType, setPrintOrientationType] = useRecoilState(studioPrintOrientationType);
  const { images, imageUploading, uploadReportImage } = useStudioImages();
  const [coverPageImage, setCoverPageImage] = useRecoilState(viewCoverPageImage);
  const [primaryColor, setPrimaryColor] = useRecoilState(viewPrimaryColor);

  const [debouncedPrimaryColor, setDebouncedPrimaryColor] = useDebounceToGlobal(primaryColor, setPrimaryColor);

  const canCreateTemplate = hasPermission('STUDIO_CREATE_TEMPLATE');
  const coverPageImageId = uuidValidate(coverPageImage) ? coverPageImage : DEFAULT_STOCK_PHOTO;
  const getImageURL = (imageId: string) => `/api/images/${imageId}`;

  return (
    <GapWrapper>
      <Headline3>Report Settings</Headline3>
      <StyledLabel htmlFor="print-orientation">Orientation</StyledLabel>
      <IconSelectionWrapper>
        <IconSelection>
          <IconLabelStack>
            <ReportingIcon
              type="rectangle-portrait"
              prefix="fad"
              selected={printOrientationType === 'PORTRAIT'}
              onClick={!canCreateTemplate ? undefined : () => setPrintOrientationType('PORTRAIT')}
              disabled={!canCreateTemplate}
              left
            />
            <IconLabel>Portrait</IconLabel>
          </IconLabelStack>
        </IconSelection>
        <IconSelection>
          <IconLabelStack>
            <ReportingIcon
              type="rectangle-landscape"
              prefix="fad"
              selected={printOrientationType === 'LANDSCAPE'}
              onClick={!canCreateTemplate ? undefined : () => setPrintOrientationType('LANDSCAPE')}
              disabled={!canCreateTemplate}
            />
            <IconLabel>Landscape</IconLabel>
          </IconLabelStack>
        </IconSelection>
      </IconSelectionWrapper>

      {isReport && (
        <>
          <PageSection />
          <GapWrapper>
            <StyledLabel htmlFor="cover-page-image">Title Page Image</StyledLabel>
            <div>
              {canCreateTemplate ? (
                <div>
                  <ImageGrid>
                    {compact([...STOCK_PHOTOS, ...(images ?? [])]).map((image: ImageDetails) => (
                      <BadgeWrapper key={image.id}>
                        {image.id === coverPageImageId ? <DefaultBadge type="check-circle" /> : null}
                        <Image
                          key={image.id}
                          src={getImageURL(image.id)}
                          alt={image.id}
                          selected={image.id === coverPageImageId}
                          {...buttonize(() => canCreateTemplate && setCoverPageImage(image.id))}
                        />
                      </BadgeWrapper>
                    ))}
                  </ImageGrid>
                  <UploadImageText
                    disabled={imageUploading || !canCreateTemplate}
                    {...buttonize(
                      () => canCreateTemplate && document.getElementById('imageUploadFileDialogId')?.click(),
                    )}
                  >
                    <PlusIcon>
                      <Icon prefix="fa" type="plus" />
                    </PlusIcon>
                    Upload Image
                  </UploadImageText>
                  <input
                    type="file"
                    id="imageUploadFileDialogId"
                    style={{ display: 'none' }}
                    accept="image/*"
                    onChange={(file) => uploadReportImage(file.target, true)}
                  />
                </div>
              ) : (
                <BadgeWrapper>
                  <Image src={getImageURL(coverPageImageId)} selected disabled />
                </BadgeWrapper>
              )}
            </div>
          </GapWrapper>

          <GapWrapper>
            <StyledLabel htmlFor="cover-page-image">Section Page Color</StyledLabel>
            {canCreateTemplate ? (
              <ChromePicker color={debouncedPrimaryColor} onChange={(color) => setDebouncedPrimaryColor(color.hex)} />
            ) : (
              <ColorInput type="color" value={primaryColor} disabled />
            )}
          </GapWrapper>
        </>
      )}
    </GapWrapper>
  );
};

export default ReportingSection;

const ImageGrid = styled.div`
  flex-direction: row;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  align-content: center;
  flex-flow: row wrap;
`;

interface ImageProps {
  selected?: boolean;
  left?: boolean;
  disabled?: boolean;
}

const IconSelectionWrapper = styled.div`
  display: table;
  width: fit-content;
`;

export const BadgeWrapper = styled.div`
  width: 47%;
  height: 100px;
  margin: 1.5%;
  margin-left: 0;
  margin-right: 3%;
  order: 1;
  background-position: center;
  background-size: cover;
  object-fit: cover;
  display: inline-block;
  position: relative;
`;
export const DefaultBadge = styled(Icon)`
  position: absolute;
  bottom: 8px;
  right: 8px;
  color: ${GetColor.Primary.Main};
  z-index: 10000;
`;
const Image = styled.img<ImageProps>`
  top: 0;
  left: 0;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  border: ${(props) =>
    props.selected
      ? css`
          3px solid ${GetColor.Primary.Main}
        `
      : '0px'};
  background-color: lightblue;
  width: 100%;
  height: 100px;
  order: 1;
  background-position: center;
  background-size: cover;
  object-fit: cover;
`;

const IconLabelStack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  margin: 5px;
  margin-right: 25px;
`;

const ReportingIcon = styled(Icon)<ImageProps>`
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  border: ${(props) =>
    props.selected
      ? css`
        3px solid ${GetColor.Primary.Main}
      `
      : '0px'};
  font-size: 50px;
  margin: 0px;
  color: orange;
  display: table-cell;
  float: left;
  white-space: nowrap;
  width: max-content;
`;

const IconSelection = styled.div`
  display: table-cell;
`;

const IconLabel = styled.div`
  margin: 5px;
`;

const ColorInput = styled.input`
  padding: 0;
  border: 0px;
`;
