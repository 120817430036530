import React, { useContext } from 'react';
import { GetColor, VennProvidedIcon, Icon, getAppTitle } from 'venn-ui-kit';
import styled, { ThemeContext, css } from 'styled-components';
import { PrintDisabledText } from './shared';

const reportLabAdHeader = 'Report Lab: Customizable, Flexible, and Archivable';
const reportLabAdDescription =
  'Quickly construct reports and proposals, craft your narrative, and present with confidence!';

export const PrintDisabledWarning = () => {
  const { Images } = useContext(ThemeContext);
  return (
    <WarningContainer>
      <VennProvidedIcon height={100} />
      <HeadingContainer>
        In order to print Venn output, access to{' '}
        <b>
          <ReportLabIcon type="file-chart-column" />
          Report Lab
        </b>{' '}
        is required
      </HeadingContainer>
      <BlackRectangle height="100%">
        <ReportLabHeadline>{reportLabAdHeader}</ReportLabHeadline>
        <Image src={Images.reportLabAsset} alt={getAppTitle()} height="212px" />
        <TextContainer hasBorder>{reportLabAdDescription}</TextContainer>
        <TextContainer>
          <EnvelopeIcon type="envelope-circle" />
          {PrintDisabledText.subHeader}
        </TextContainer>
      </BlackRectangle>
    </WarningContainer>
  );
};

const Image = styled.img`
  align-self: stretch;
`;

const EnvelopeIcon = styled(Icon)`
  margin-right: 8px;
`;

const ReportLabIcon = styled(Icon)`
  margin-right: 4px;
`;

const HeadingContainer = styled.h1`
  font-size: 24px;
`;

const TextContainer = styled.div<{ hasBorder?: boolean }>`
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  ${({ hasBorder }) =>
    hasBorder &&
    css`
      border-bottom: 1px solid ${GetColor.GreyScale.Grey60};
      padding-bottom: 12px;
    `}
`;

const ReportLabHeadline = styled.h2`
  font-size: 19px;
  font-weight: 700;
  line-height: 22px;
`;

const BlackRectangle = styled.div<{ height: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${GetColor.GreyScale.Grey100};
  width: 100%;
  height: ${({ height }) => `height: ${height}`};
  color: ${GetColor.White};
  gap: 12px;
  padding-top: 12px;
  padding-bottom: 12px;
  border: 2px solid ${GetColor.Black};
`;

const WarningContainer = styled.div`
  display: none;
  will-change: display;
  @media print {
    display: flex;
  }
  margin: 32px 16px;
  gap: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
