import React, { useEffect, useMemo, useState } from 'react';
import type { Portfolio } from 'venn-api';
import StrategyDropdown from '../analyze-investment-modals/StrategyDropdown';
import { InputRow, StyledLabel } from './styles';
import buildOptions from '../analyze-investment-modals/build-options';

interface AddToStrategyProps {
  label?: string;
  portfolio?: Portfolio;
  allowCreateNew?: boolean;
  onSelected: (selected?: Portfolio) => void;
  initialSelectedStrategy?: Portfolio;
}

const AddToStrategy = ({
  portfolio,
  allowCreateNew,
  label = 'Add investments to strategy:',
  onSelected,
  initialSelectedStrategy,
}: AddToStrategyProps) => {
  const strategies = useMemo(() => (!portfolio ? [] : buildOptions(portfolio)), [portfolio]);
  const [selectedStrategy, setSelectedStrategy] = useState(initialSelectedStrategy);

  useEffect(() => {
    onSelected(selectedStrategy);
  }, [onSelected, selectedStrategy]);

  useEffect(() => {
    if (
      !selectedStrategy ||
      // if selectedStrategy has an id (i.e. we're not creating from scratch), make sure it's one of the choices
      (selectedStrategy.id && !strategies.find((strategy) => selectedStrategy === strategy.value))
    ) {
      setSelectedStrategy(strategies?.[0]?.value);
    }
  }, [selectedStrategy, strategies]);

  return (
    <InputRow className="qa-add-to-strategy">
      <StyledLabel>{label}</StyledLabel>
      <StrategyDropdown
        options={strategies}
        selected={selectedStrategy}
        onChange={(selected) => selected && setSelectedStrategy(selected)}
        allowCreateNew={allowCreateNew}
      />
    </InputRow>
  );
};

export default AddToStrategy;
