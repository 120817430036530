import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';
import { CoverPageOrganizationName, CoverPageRow, InlineInput, useUpdatePage } from './Shared';
import {
  type SectionSettings,
  StudioPrintSettingsContext,
  StudioSidePanelContext,
  useDebounceToGlobal,
  UserContext,
  type SectionPageT,
  useAppPrintMode,
} from 'venn-components';
import { useRecoilValue } from 'recoil';
import { getCustomFontSize, pageFonts, viewPrimaryColor } from 'venn-state';

const SectionPage = ({ pageNumber, page }: { pageNumber: number; page: SectionPageT }) => {
  const { inPrintMode } = useAppPrintMode();
  const { organizationPrintSettings } = useContext(StudioPrintSettingsContext);
  const { profileSettings } = useContext(UserContext);
  const { onFocusSpecialPage } = useContext(StudioSidePanelContext);
  const updatePage = useUpdatePage(pageNumber);
  const primaryColor = useRecoilValue(viewPrimaryColor);

  const onChangeSettings = useCallback(
    (settings: SectionSettings) => {
      updatePage({
        sectionSettings: settings,
      });
    },
    [updatePage],
  );

  const [sectionSettings, setSectionSettings] = useDebounceToGlobal(page.sectionSettings, onChangeSettings);

  const onTitleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const title = e.target.value;
      setSectionSettings((cur) => ({ ...cur, title }));
    },
    [setSectionSettings],
  );

  const onSubtitleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const subTitle = e.target.value;
      setSectionSettings((cur) => ({ ...cur, subTitle }));
    },
    [setSectionSettings],
  );

  const onTopTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const topText = e.target.value;
      setSectionSettings((cur) => ({ ...cur, topText }));
    },
    [setSectionSettings],
  );

  const fontSizes = useFontSizes();

  return (
    <SectionPageWrapper color={primaryColor}>
      <CoverPageRow height="33%">
        <SectionPageRow>
          <InlineInput
            style={{
              ...fontSizes.name,
              fontWeight: 700,
            }}
            $inPrintMode={inPrintMode}
            placeholder="<Enter Section Name>"
            value={sectionSettings.topText}
            onChange={onTopTextChange}
            onFocus={() => onFocusSpecialPage(pageNumber)}
            data-testid="qa-section-page-name"
          />
        </SectionPageRow>
      </CoverPageRow>
      <SectionTitleRow height="34%">
        <SectionTitleGroup>
          <InlineInput
            style={{
              ...fontSizes.title,
              fontWeight: 700,
            }}
            $inPrintMode={inPrintMode}
            placeholder="<Enter Title>"
            value={sectionSettings.title}
            onChange={onTitleChange}
            onFocus={() => onFocusSpecialPage(pageNumber)}
            data-testid="qa-section-page-title"
          />
          <InlineInput
            style={{
              ...fontSizes.subTitle,
              fontWeight: 300,
            }}
            $inPrintMode={inPrintMode}
            placeholder="<Enter Subtitle>"
            value={sectionSettings.subTitle}
            onChange={onSubtitleChange}
            onFocus={() => onFocusSpecialPage(pageNumber)}
            data-testid="qa-section-page-subtitle"
          />
        </SectionTitleGroup>
      </SectionTitleRow>
      <CoverPageRow height="33%">
        <BottomRow>
          <CoverPageOrganizationName style={fontSizes.orgName} data-testid="qa-section-page-orgname">
            {organizationPrintSettings?.organizationName ?? profileSettings?.organization.name}
          </CoverPageOrganizationName>
        </BottomRow>
      </CoverPageRow>
    </SectionPageWrapper>
  );
};

export default SectionPage;

function useFontSizes() {
  const nameFont = useRecoilValue(pageFonts.sectionPageName);
  const titleFont = useRecoilValue(pageFonts.sectionPageTitle);
  const subTitleFont = useRecoilValue(pageFonts.sectionPageSubtitle);
  const orgNameFont = useRecoilValue(pageFonts.sectionPageOrgName);

  return {
    name: {
      fontSize: getCustomFontSize(nameFont),
      lineHeight: nameFont.lineHeight,
    },
    title: {
      fontSize: getCustomFontSize(titleFont),
      lineHeight: titleFont.lineHeight,
    },
    subTitle: {
      fontSize: getCustomFontSize(subTitleFont),
      lineHeight: subTitleFont.lineHeight,
    },
    orgName: {
      fontSize: getCustomFontSize(orgNameFont),
      lineHeight: orgNameFont.lineHeight,
    },
  };
}

export const BottomRow = styled.div`
  position: absolute;
  bottom: 40px;
  right: 40px;
  vertical-align: text-bottom;
`;

const SectionPageWrapper = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  color: white;
  height: 100%;
  display: grid;
`;

const SectionTitleRow = styled.div<{ height: string }>`
  position: relative;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  justify-content: flex-start;
  padding: 5px 40px;
  overflow: hidden;

  @media screen {
    width: 100%;
  }
  @media print {
    width: 100vw;
    height: ${(props) => props.height ?? '33vh'};
  }
`;

const SectionTitleGroup = styled.div`
  overflow: hidden;
  width: 100%;
`;

const SectionPageRow = styled.div`
  padding: 40px;
`;
