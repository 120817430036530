import styled from 'styled-components';
import { Button } from 'venn-ui-kit';
import { isMobile } from 'venn-utils';

export default styled(Button)`
  font-size: 11px;
  margin-top: 30px;
  min-width: 200px;
  align-self: ${isMobile() ? 'center' : 'flex-end'};
`;
