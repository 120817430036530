import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { OperationResult } from 'venn-api';
import { useApi, useIsMounted, logExceptionIntoSentry } from 'venn-utils';
import { isNil } from 'lodash';
import moment from 'moment';
import type { GroupByDateItem } from './versionItemUtils';

export const VERSIONS_COLLAPSED_LIMIT = 6;

const useVersions = <T extends GroupByDateItem, U>(
  api: (id: U) => Promise<OperationResult<T[]>>,
  initVersion?: T,
  id?: U,
) => {
  const [versions, setVersions] = useState<T[]>(!isNil(initVersion) ? [initVersion] : []);
  const [versionsLoading, setVersionsLoading] = useState<boolean>();
  const getApiRef = useRef(useApi(api));
  const isMountedRef = useIsMounted();

  useEffect(() => {
    const loadVersions = async (id?: U) => {
      if (!id || !isMountedRef.current) {
        return;
      }
      try {
        setVersionsLoading(true);
        const { content } = await getApiRef.current(id);
        if (isMountedRef.current) {
          setVersions(content);
        }
      } catch (e) {
        logExceptionIntoSentry(e);
      }
      if (isMountedRef.current) {
        setVersionsLoading(false);
      }
    };
    loadVersions(id);
  }, [id, isMountedRef]);

  const [versionsExpanded, setVersionsExpanded] = useState(false);
  const [month, setMonth] = useState<number | undefined>();
  const [year, setYear] = useState<number | undefined>();
  const [start, setStart] = useState<number | undefined>();
  const [end, setEnd] = useState<number | undefined>();

  const filterVersions = useCallback((selectedMonth?: number, selectedYear?: number) => {
    const [updatedStart, updatedEnd] = toRange(selectedMonth, selectedYear);
    setMonth(selectedMonth);
    setYear(selectedYear);
    setStart(updatedStart);
    setEnd(updatedEnd);
  }, []);

  const filteredVersions = useMemo(() => {
    if (isNil(start) || isNil(end)) {
      return versions;
    }
    return versions.filter(({ updated }) => updated && start <= updated && updated <= end);
  }, [versions, start, end]);

  return {
    versions: versionsExpanded ? filteredVersions : filteredVersions.slice(0, VERSIONS_COLLAPSED_LIMIT),
    filteredVersionsCount: filteredVersions.length,
    totalVersionsCount: versions.length,
    month,
    year,
    versionsLoading,
    versionsExpanded,
    setVersionsExpanded,
    filterVersions,
  };
};

export default useVersions;

const toRange = (month?: number, year?: number): [number | undefined, number | undefined] => {
  if (isNil(year)) {
    return [undefined, undefined];
  }
  if (isNil(month)) {
    return [moment.utc().year(year).startOf('year').valueOf(), moment.utc().year(year).endOf('year').valueOf()];
  }
  return [
    moment.utc().year(year).month(month).startOf('month').valueOf(),
    moment.utc().year(year).month(month).endOf('month').valueOf(),
  ];
};
