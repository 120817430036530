import type { ColDef, ColGroupDef } from 'ag-grid-community';
import { groupBy, isEmpty, isNil } from 'lodash';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { useTheme } from 'styled-components';
import type { PrivatePerformanceTimeSeriesResponse } from 'venn-api';
import { blockCustomizableMetrics, blockMetrics, blockRequestSubjects } from 'venn-state';
import { getItemColor } from 'venn-ui-kit';
import { assertNotNil, Dates } from 'venn-utils';
import { convertRequestSubjectToItemType } from '../../analysis';
import { formatData } from '../../data-grid';
import { useMeasureGridText } from '../../utils/grids';
import { useBlockId } from '../contexts/BlockIdContext';
import { METRIC_NAME_HEADER_RENDERER, RIGHT_ALIGN_CLASS, SUBJECT_HEADER_RENDERER } from '../customAnalysisContants';
import { getCellClassForDataType } from './gridStyling';

interface PerformanceRowData {
  date: number;
  dataMap?: Map<string, number>[];
}

function usePrivatesPerformanceDataGridColumnDefs(data: PrivatePerformanceTimeSeriesResponse[]) {
  const blockId = useBlockId();
  const theme = useTheme();
  const requestSubjects = useRecoilValue(blockRequestSubjects(blockId));
  const selectedMetrics = useRecoilValue(blockMetrics(blockId));
  const allMetrics = useRecoilValue(blockCustomizableMetrics(blockId));
  const measureText = useMeasureGridText();
  return useMemo(() => {
    if (requestSubjects.length === 0) {
      return [];
    }

    return [
      // This trick with wrapping date column is needed to make sure columns are aligned properly in Excel export
      {
        headerName: '',
        marryChildren: true,
        children: [
          {
            headerName: 'Date',
            valueGetter: ({ data }) => {
              const value = data?.date;
              if (value === undefined) {
                return '--';
              }
              return `${Dates.toQuarterYear(Number(value))}`;
            },
            cellClass: () => ['TEXT', RIGHT_ALIGN_CLASS],
            minWidth: measureText('Q1 2024', 'normal'),
            maxWidth: measureText('Q1 2024', 'normal'),
          },
        ],
      },
      ...requestSubjects.map((subject, subjectIndex) => {
        return {
          marryChildren: true,
          headerName: subject.name,
          cellClass: () => [RIGHT_ALIGN_CLASS],
          headerGroupComponent: SUBJECT_HEADER_RENDERER,
          headerGroupComponentParams: {
            color: getItemColor(theme.Colors, convertRequestSubjectToItemType(subject)),
            subject,
            isCommonBenchmark: false,
            displayName: subject.name,
            noLink: true,
          },
          children: selectedMetrics.map((metric) => {
            const metricMetadata = assertNotNil(
              allMetrics.find((customizableMetric) => customizableMetric.key === metric),
            );
            const errors = data[subjectIndex]?.errors?.[metric] ?? [];
            return {
              headerComponent: METRIC_NAME_HEADER_RENDERER,
              headerComponentParams: {
                displayName: assertNotNil(metricMetadata).label,
                errors: errors.map((error) => error.message),
              },
              headerName: assertNotNil(metricMetadata).label,
              valueGetter: errors.length
                ? undefined
                : ({ data }) => {
                    return data?.dataMap?.[subjectIndex]?.get(metric);
                  },
              valueFormatter: ({ value }) => {
                if (isNil(value)) {
                  return '--';
                }
                return formatData(value, metricMetadata.dataType, 2);
              },
              cellClass: () => [getCellClassForDataType(metricMetadata.dataType), RIGHT_ALIGN_CLASS],
            };
          }),
        } as ColGroupDef<PerformanceRowData>;
      }),
    ];
  }, [requestSubjects, measureText, theme.Colors, selectedMetrics, allMetrics, data]) as (
    | ColDef<PerformanceRowData>
    | ColGroupDef<PerformanceRowData>
  )[];
}

type PerformanceDataPoint = {
  date: number;
  subjectIndex: number;
  metricId: string;
  value: number;
};

function usePrivatesPerformanceDataGridRowData(data: PrivatePerformanceTimeSeriesResponse[]): PerformanceRowData[] {
  const blockId = useBlockId();
  const selectedMetrics = useRecoilValue(blockMetrics(blockId));
  const subjects = useRecoilValue(blockRequestSubjects(blockId));

  const dataFlattened: PerformanceDataPoint[] = [];
  (data ?? []).forEach((datum, subjectIndex) => {
    selectedMetrics.forEach((metric) => {
      const timeseries = datum[metric] ?? [];
      timeseries.forEach((entry: [number, number]) => {
        if (!entry) {
          return;
        }
        const [timestamp, value] = entry;
        dataFlattened.push({
          date: timestamp,
          subjectIndex,
          metricId: metric,
          value,
        } as PerformanceDataPoint);
      });
    });
  });

  const groupedByDate = groupBy(dataFlattened, 'date');
  const groupedFormatted = Object.entries(groupedByDate)
    .sort((first, second) => {
      const timestamp1 = first[0] as unknown as number;
      const timestamp2 = second[0] as unknown as number;
      return timestamp1 - timestamp2;
    })
    .map((entry) => {
      const [date, datapoints] = entry;
      const rowDataForDateSplitBySubjects = Object.values(groupBy(datapoints, 'subjectIndex'));
      const rowData = subjects.map(() => {
        return new Map<string, number>();
      });

      rowDataForDateSplitBySubjects.forEach((singleSubjectData) => {
        if (isEmpty(singleSubjectData)) {
          return;
        }
        const subjectIndex = singleSubjectData[0].subjectIndex;
        rowData[subjectIndex] = new Map(singleSubjectData.map((datapoint) => [datapoint.metricId, datapoint.value]));
      });

      return {
        date: date as unknown as number,
        dataMap: rowData,
      } as PerformanceRowData;
    });

  return groupedFormatted;
}

export const usePrivatesPerformanceDataGrid = (data: PrivatePerformanceTimeSeriesResponse[]) => {
  const columnDefs = usePrivatesPerformanceDataGridColumnDefs(data);

  const rowData = usePrivatesPerformanceDataGridRowData(data);
  return {
    rowData,
    columnDefs,
  };
};
