import React, { useEffect, useContext } from 'react';
import { Body2, Button, Headline2, Headline3 } from 'venn-ui-kit';
import type { FallbackComponent } from './CustomizableErrorBoundary';
import { ErrorWrapper } from './CustomizableErrorBoundary';
import { ThemeContext } from 'styled-components';
import { FS } from 'venn-utils';

const isVennTeam = () => {
  try {
    return FS.has('admin_data_reader');
  } catch {
    return false;
  }
};

/**
 * Fallback component that renders additional error info for Venn team members, but otherwise returns null.
 * This component is intended to wrap debug tooling. Anywhere else should generally have a real error boundary or error wrapper, and not
 * just be null after an error.
 */
const DeveloperFallbackComponent: FallbackComponent = (props) => {
  const { Colors } = useContext(ThemeContext);
  const [viewAsClient, setViewAsClient] = React.useState(false);
  const hideOutput = !isVennTeam() || viewAsClient;

  useEffect(() => {
    if (hideOutput) {
      return;
    }

    // eslint-disable-next-line no-console
    console.error(props);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (hideOutput) {
    return null;
  }

  const replaceNewlines = (s: string) => {
    return s.replaceAll(/(\\r)?\\n/g, '\n');
  };

  const getErrorString = (error: Error) => {
    try {
      return replaceNewlines(JSON.stringify(error, Object.getOwnPropertyNames(error), 2));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return 'Failed to convert error to string. See error in console.';
    }
  };

  return (
    <ErrorWrapper style={{ backgroundColor: Colors.White, padding: 16 }}>
      <Headline2>Developer Error Boundary</Headline2>
      {props.isRetrying ? (
        <Button dense onClick={props.onClearRetryInterval}>
          Stop Retrying
        </Button>
      ) : (
        <>
          <Button dense onClick={props.onRetry}>
            Retry
          </Button>
          <Button destructive dense onClick={() => props.onRetryRepeatedly(1000)}>
            Repeated Retry
          </Button>
          <Button dense destructive onClick={() => setViewAsClient(true)}>
            Hide
          </Button>
        </>
      )}
      <Body2>Pressing hide will hide the boundary, showing what a real client would see.</Body2>
      {props.error && <ErrorDetailSection header="Error Object" body={getErrorString(props.error)} />}
      <ErrorDetailSection header="Info" body={replaceNewlines(props.info)} />
    </ErrorWrapper>
  );
};

const ErrorDetailSection = ({ header, body }: { header: string; body: string }) => {
  return (
    <>
      <Headline3>{header}</Headline3>
      <div
        style={{ maxWidth: '80vw', maxHeight: '25vh', overflow: 'scroll', whiteSpace: 'pre', border: '1px solid grey' }}
      >
        {body}
      </div>
    </>
  );
};

export default DeveloperFallbackComponent;
