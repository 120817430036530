import { useCallback, useEffect, useMemo, useState } from 'react';
import type { OptimizedPortfolio, RiskReturnPoint } from 'venn-api';
import { compact, isNil } from 'lodash';
import type { Solution } from 'venn-components';

export interface SolutionData {
  solution: Solution;
  point: RiskReturnPoint;
}

interface UseOptimizationSolutionValue {
  current?: SolutionData;
  optimized?: SolutionData;
  alternate?: SolutionData[];
  benchmark?: SolutionData;

  efficientFrontier?: RiskReturnPoint[];

  selectedSolution?: Solution;
  solutionPortfolio?: Partial<OptimizedPortfolio>;
  onSelectSolution: (solution: Solution) => void;
}

const useOptimizationSolution = (
  portfolioResult: Partial<OptimizedPortfolio>,
  optimizedResult: OptimizedPortfolio | undefined,
  alternateResults: OptimizedPortfolio[] | undefined,
  benchmarkResult: Partial<OptimizedPortfolio>,
): UseOptimizationSolutionValue => {
  const [selectedSolution, setSelectedSolution] = useState<Solution>();

  useEffect(() => {
    if (isNil(selectedSolution)) {
      // Auto-select Optimized portfolio if present, otherwise default to the first available Alternate portfolio
      if (!isNil(optimizedResult?.summary) && !isNil(optimizedResult?.portfolio)) {
        setSelectedSolution({ category: 'Optimized' });
      } else {
        const existingAlternateIdx = (alternateResults ?? []).findIndex(
          (result) => !isNil(result.portfolio) && !isNil(result.summary),
        );
        if (existingAlternateIdx !== -1) {
          setSelectedSolution({ category: 'Alternate', alternateSolutionIdx: existingAlternateIdx });
        }
      }
      return;
    }
    switch (selectedSolution.category) {
      case 'Current':
        if (isNil(portfolioResult.summary)) {
          setSelectedSolution(undefined);
        }
        return;
      case 'Optimized':
        if (isNil(optimizedResult?.summary) || isNil(optimizedResult?.portfolio)) {
          setSelectedSolution(undefined);
        }
        return;
      case 'Alternate':
        if (
          isNil(selectedSolution.alternateSolutionIdx) ||
          isNil(alternateResults?.[selectedSolution.alternateSolutionIdx]?.summary) ||
          isNil(alternateResults?.[selectedSolution.alternateSolutionIdx]?.portfolio)
        ) {
          setSelectedSolution(undefined);
        }
        return;
      case 'Benchmark':
        setSelectedSolution(undefined);
    }
  }, [portfolioResult, optimizedResult, alternateResults, selectedSolution]);

  useEffect(() => {
    // After re-optimizing, auto-select Optimized solution
    if (!isNil(optimizedResult?.summary) && !isNil(optimizedResult?.portfolio)) {
      setSelectedSolution({ category: 'Optimized' });
    }
  }, [optimizedResult]);

  const solutionPortfolio = useMemo(() => {
    if (isNil(selectedSolution)) {
      return undefined;
    }
    switch (selectedSolution.category) {
      case 'Current':
        return portfolioResult;
      case 'Optimized':
        return optimizedResult;
      case 'Alternate':
        return isNil(selectedSolution.alternateSolutionIdx)
          ? undefined
          : alternateResults?.[selectedSolution.alternateSolutionIdx];
      case 'Benchmark':
      default:
        return undefined;
    }
  }, [selectedSolution, portfolioResult, optimizedResult, alternateResults]);

  const current: SolutionData | undefined = useMemo(
    () =>
      isNil(portfolioResult.summary) ||
      isNil(portfolioResult.summary.annualizedTotalReturn) ||
      isNil(portfolioResult.summary.annualizedVolatility)
        ? undefined
        : {
            solution: { category: 'Current', name: portfolioResult.portfolio?.name },
            point: {
              annualizedReturn: portfolioResult.summary.annualizedTotalReturn,
              annualizedVolatility: portfolioResult.summary.annualizedVolatility,
            },
          },
    [portfolioResult],
  );
  const [optimized, efficientFrontier]: [SolutionData | undefined, RiskReturnPoint[] | undefined] = useMemo(
    () =>
      isNil(optimizedResult)
        ? [undefined, undefined]
        : [
            {
              solution: { category: 'Optimized' },
              point: {
                annualizedReturn: optimizedResult.summary.annualizedTotalReturn,
                annualizedVolatility: optimizedResult.summary.annualizedVolatility,
              },
            },
            optimizedResult.summary.efficientFrontier,
          ],
    [optimizedResult],
  );

  const alternate: SolutionData[] | undefined = useMemo(
    () =>
      isNil(alternateResults)
        ? undefined
        : compact(
            alternateResults.map(({ portfolio, summary }, idx) =>
              isNil(portfolio) || isNil(summary.annualizedTotalReturn) || isNil(summary.annualizedVolatility)
                ? undefined
                : {
                    solution: { category: 'Alternate', alternateSolutionIdx: idx },
                    point: {
                      annualizedReturn: summary.annualizedTotalReturn,
                      annualizedVolatility: summary.annualizedVolatility,
                    },
                  },
            ),
          ),
    [alternateResults],
  );

  const benchmark: SolutionData | undefined = useMemo(
    () =>
      isNil(benchmarkResult.summary) ||
      isNil(benchmarkResult.summary.annualizedTotalReturn) ||
      isNil(benchmarkResult.summary.annualizedVolatility)
        ? undefined
        : {
            solution: { category: 'Benchmark', name: benchmarkResult.summary.name },
            point: {
              annualizedReturn: benchmarkResult.summary.annualizedTotalReturn,
              annualizedVolatility: benchmarkResult.summary.annualizedVolatility,
            },
          },
    [benchmarkResult],
  );

  const onSelectSolution = useCallback((solution: Solution | undefined) => {
    if (!isNil(solution)) {
      setSelectedSolution(solution);
    }
  }, []);

  return {
    current,
    optimized,
    alternate,
    benchmark,
    efficientFrontier,
    selectedSolution,
    solutionPortfolio,
    onSelectSolution,
  };
};

export default useOptimizationSolution;
