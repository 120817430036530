import { compact } from 'lodash';
import type { SideMenuItem } from 'venn-components';
import {
  type AnalysisSubject,
  type AnalysisSubjectType,
  FS,
  FundUtils,
  getDefaultSection,
  MANAGE_DATA_SECTION,
} from 'venn-utils';

export const getMenu = (
  subject?: AnalysisSubject,
  flags?: { canOptimize: boolean; showCashFlowPacingSettings: boolean },
): SideMenuItem<MANAGE_DATA_SECTION>[] => {
  const { canOptimize, showCashFlowPacingSettings } = flags ?? {};

  if (!subject) {
    return [];
  }
  if (subject?.portfolio || subject?.privatePortfolio) {
    return compact([
      {
        label: 'Portfolio Information',
        value: MANAGE_DATA_SECTION.PORTFOLIO_INFORMATION,
      },
      !subject.private && {
        label: 'Analysis Date Range',
        value: MANAGE_DATA_SECTION.ANALYSIS_RANGE,
      },
      !subject.private && FS.has('optimization') && canOptimize
        ? {
            label: 'Portfolio Policy',
            value: MANAGE_DATA_SECTION.PORTFOLIO_POLICY,
          }
        : null,
      subject.private &&
        showCashFlowPacingSettings && {
          label: 'Cash Flow Pacing Model Parameters',
          value: MANAGE_DATA_SECTION.CASH_FLOW_PACING_SETTINGS,
        },
    ]);
  }

  return compact([
    {
      label: 'Investment Information',
      value: MANAGE_DATA_SECTION.INVESTMENT_INFORMATION,
    },
    !subject.private && {
      label: 'Analysis Date Range',
      value: MANAGE_DATA_SECTION.ANALYSIS_RANGE,
    },
    FundUtils.isPerformanceViewable(subject?.fund)
      ? {
          label: 'Performance Data',
          value: MANAGE_DATA_SECTION.DATA,
        }
      : null,
    subject.private && {
      label: 'Performance Data',
      value: MANAGE_DATA_SECTION.DATA,
    },
    subject.private && {
      label: 'Cash Flow Transactions',
      value: MANAGE_DATA_SECTION.TRANSACTIONS,
    },
    subject.private &&
      showCashFlowPacingSettings && {
        label: 'Cash Flow Pacing Model Parameters',
        value: MANAGE_DATA_SECTION.CASH_FLOW_PACING_SETTINGS,
      },
    {
      label: 'Portfolios',
      value: MANAGE_DATA_SECTION.PORTFOLIOS,
    },
  ]);
};

export const getSection = (
  menu: SideMenuItem<MANAGE_DATA_SECTION>[],
  objectType: AnalysisSubjectType,
  section?: MANAGE_DATA_SECTION,
): MANAGE_DATA_SECTION | undefined => {
  if (!section) {
    return getDefaultSection(objectType);
  }

  if (menu.find((item) => item.value === section) ?? menu[0]) {
    return section;
  }

  return getDefaultSection(objectType);
};
