import { compound, zip, compoundWithInception, toPercentage, toDataPoints } from './returns';

export * from './returnsFrequency';
export class ReturnsCalculations {
  /**
   * Compounds a series of returns
   */
  static compound = compound;

  /**
   * Zip (aligns) two series of returns together
   */
  static zip = zip;

  /**
   * Compounds a series of returns, adding an inception point before the first return.
   * The inception value is 0.
   */
  static compoundWithInception = compoundWithInception;

  /**
   * Converts a numeric DataPoint into a percentage DataPoint
   */
  static toPercentage = toPercentage;

  /**
   * Converts a raw returns series (from the Backend) to an array of DataPoint
   */
  static toDataPoints = toDataPoints;
}
