export const fundContribution = {
  funds: [
    {
      contribution: 0.44272832319617195,
      contributionValue: 0.034650939753172995,
      name: 'Managed Futures Investment Category (MF)',
      value: 0.06649424218252986,
      weight: 0.5,
    },
    {
      contribution: 0.557271676803828,
      contributionValue: 0.04361588425080933,
      name: 'Other Investment Category (HF)',
      value: 0.0864520868095906,
      weight: 0.5,
    },
  ],
  strategies: [
    {
      contribution: 0.44272832319617195,
      contributionValue: 0.034650939753172995,
      name: 'Managed Futures Investment Category (MF)',
      value: 0.06649424218252986,
      weight: 0.5,
    },
    {
      contribution: 0.557271676803828,
      contributionValue: 0.04361588425080933,
      name: 'Other Investment Category (HF)',
      value: 0.0864520868095906,
      weight: 0.5,
    },
  ],
};

export const strategyContribution = {
  funds: [
    {
      contribution: 0.44272832319617195,
      contributionValue: 0.034650939753172995,
      name: 'Managed Futures Investment Category (MF)',
      value: 0.06649424218252986,
      weight: 0.5,
    },
    {
      contribution: 0.557271676803828,
      contributionValue: 0.04361588425080933,
      name: 'Other Investment Category (HF)',
      value: 0.0864520868095906,
      weight: 0.5,
    },
    {
      contribution: 0.35761719994016045,
      contributionValue: 0.029110714313254852,
      name: 'Vanguard STAR Inv',
      value: 0.08516833962136139,
      weight: 0.3333333333333333,
    },
  ],
  strategies: [
    {
      contribution: 0.3523863332882755,
      contributionValue: 0.028684911905710597,
      name: 'testStrategy',
      value: 0.08516833962136139,
      weight: 0.3333333333333333,
    },
    {
      contribution: 0.6476136667117244,
      contributionValue: 0.052716973456978615,
      name: 'Uncategorized',
      value: 0.07826958802529704,
      weight: 0.6666666666666666,
    },
  ],
};
