import React from 'react';
import styled from 'styled-components';
import { FilterPill } from 'venn-ui-kit';
import { LIBRARY_ITEM_TYPE_LABELS, QUICK_FILTER_LABELS } from '../../getFilters';
import { FilterType, FundUtils } from 'venn-utils';
import type { AssetTypeEnum } from 'venn-api';

interface SelectedPillsProps<T extends string> {
  selected: Set<T>;
  onClick: (value: T) => void;
  iconType?: string;
  filterType?: FilterType;
}

function SelectedPills<T extends string>({ selected, onClick, iconType, filterType }: SelectedPillsProps<T>) {
  return (
    <Container>
      {Array.from(selected).map((value) => (
        <FilterPill
          key={value}
          name={renderValue({ value, filterType })}
          iconType={iconType}
          onClose={() => onClick(value)}
        />
      ))}
    </Container>
  );
}

interface renderValueProps {
  value: string;
  filterType: FilterType | undefined;
}

function renderValue({ value, filterType }: renderValueProps) {
  switch (filterType) {
    case FilterType.SOURCE:
      return QUICK_FILTER_LABELS[value];
    case FilterType.ASSET_TYPE:
      return FundUtils.getAssetTypeName(value as AssetTypeEnum);
    case FilterType.ITEM_TYPE:
      return LIBRARY_ITEM_TYPE_LABELS[value];
    default:
      return value;
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 5px;
  > * {
    margin-top: 5px;
  }
`;

export default SelectedPills;
