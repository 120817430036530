import { compact } from 'lodash';
import React, { useContext } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { deleteFunds, deletePortfoliosV3 } from 'venn-api';
import type { PortfoliosContextValue } from 'venn-components';
import { ConfirmationModal } from 'venn-components';
import { logExceptionIntoSentry, plural, Routes, updateSearchQuery } from 'venn-utils';
import HomePageContext from './contexts/HomePageContext';
import type { SearchResultWithUIState } from './types';

interface DeleteConfirmationModalProps extends RouteComponentProps {
  items?: SearchResultWithUIState[];
  onCancelDelete: () => void;
  onDeleteComplete: () => void;
  portfoliosContext?: PortfoliosContextValue;
  refetchSearch?: (hideLoading?: boolean) => void;
}

const deletePortfoliosAndRefresh = async (portfolioIds: number[], refresh?: () => Promise<void>) => {
  await deletePortfoliosV3(portfolioIds);
  await refresh?.();
};

const DeleteConfirmationModal = ({
  items = [],
  onCancelDelete,
  onDeleteComplete,
  portfoliosContext,
  refetchSearch,
  location,
  history,
}: DeleteConfirmationModalProps) => {
  const { loading: deleting, setLoading: setHomePageLoading } = useContext(HomePageContext);
  if (items.length === 0) {
    return null;
  }
  const fundIds = compact(items.map((item) => item.fundId));
  const benchmarkIds = compact(items.map((item) => item.fundId && item.assetType === 'BENCHMARK'));
  const portfolioIds = compact(items.map((item) => item.portfolioId));

  const handleDelete = async () => {
    if (deleting) {
      return;
    }
    setHomePageLoading(true);
    try {
      await Promise.all([
        portfolioIds.length ? deletePortfoliosAndRefresh(portfolioIds, portfoliosContext?.refresh) : Promise.resolve(),
        fundIds.length ? deleteFunds(fundIds) : Promise.resolve(),
      ] as Promise<unknown>[]);

      // To trigger refresh of library home page
      if (location.pathname === Routes.LIBRARIES_PATH) {
        updateSearchQuery('lastTrackTime', Date.now().toString(), history);
      }
    } catch (e) {
      logExceptionIntoSentry(e);
    }
    setHomePageLoading(false);

    refetchSearch?.();
    onDeleteComplete();
  };

  const hasNonSavedViewsText = fundIds.length || portfolioIds.length;
  const savedViewsWarning = ` ${
    fundIds.length && portfolioIds.length
      ? 'These portfolios and investments'
      : fundIds.length > 1
        ? 'These investments'
        : fundIds.length === 1
          ? 'This investment'
          : portfolioIds.length > 1
            ? 'These portfolios'
            : portfolioIds.length === 1
              ? 'This portfolio'
              : 'These items'
  } will ${hasNonSavedViewsText ? 'also ' : ''}be removed from saved views.`;

  let itemTypeName = 'Item';
  if (portfolioIds.length === items.length) {
    itemTypeName = 'Portfolio';
  } else if (benchmarkIds.length === items.length) {
    itemTypeName = 'Benchmark';
  } else if (fundIds.length - benchmarkIds.length === items.length) {
    itemTypeName = 'Investment';
  }
  itemTypeName += plural(items.length, { 1: '', other: 's' });

  const modalTextProps = {
    text:
      (fundIds.length
        ? ' Deleted funds or composite benchmarks will be removed from portfolios and will no longer be accessible as benchmarks or proxies.'
        : '') +
      (portfolioIds.length ? ' Deleted portfolios will no longer be accessible as benchmarks.' : '') +
      savedViewsWarning,
    subhead: `${
      items.length > 1 ? 'These items' : 'This item'
    } will be deleted permanently for everyone in your organization. You cannot undo this action.`,
    header: `Delete ${items.length} ${itemTypeName}?`,
  };

  return (
    <ConfirmationModal
      destructive
      {...modalTextProps}
      onCancel={onCancelDelete}
      onProceed={handleDelete}
      proceedDisabled={deleting}
    />
  );
};

export default withRouter(DeleteConfirmationModal);
