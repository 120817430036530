import React, { Component } from 'react';
import styled from 'styled-components';
import { Icon, GetColor } from 'venn-ui-kit';

const Wrapper = styled.div<{ color?: string; position?: string }>`
  position: ${({ position }) => position || 'fixed'};
  background: ${({ color }) => color || 'rgba(0, 176, 114, 0.8)'};
  color: ${GetColor.White};
  padding: 20px 60px;
  width: 100%;
`;

const H1 = styled.h1`
  font-size: 20px;
  margin: 0;
  font-weight: bold;
  display: flex;
  align-items: baseline;
`;

const Description = styled.span`
  margin-left: 10px;
  font-size: 14px;
  font-weight: normal;
`;

const CheckIcon = styled(Icon)`
  margin-right: 10px;
  flex-basis: 25px;
`;

const Content = styled.div`
  flex: 1;
`;

interface AuthNotificationProps {
  icon?: string;
  color?: string;
  position?: string;
  title: string;
  description?: string;
  timeout?: number;
  onClose?(): void;
}

const DEFAULT_NOTIFICATION_TIMEOUT = 6000;

export class AuthNotification extends Component<AuthNotificationProps> {
  timer: number;

  componentDidMount() {
    const { onClose, timeout = DEFAULT_NOTIFICATION_TIMEOUT } = this.props;
    this.timer = window.setTimeout(() => onClose && onClose(), timeout);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    const { color, title, description, icon, position } = this.props;
    return (
      <Wrapper color={color} position={position}>
        <H1>
          <CheckIcon type={icon || 'check-circle'} />
          <Content>
            {title}
            {description && <Description>{description}</Description>}
          </Content>
        </H1>
      </Wrapper>
    );
  }
}

export default AuthNotification;
