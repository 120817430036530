import React from 'react';
import styled from 'styled-components';
import { GetColor } from '../../style/color';
import { ColorUtils } from '../../style/colorUtils';

interface BoxButtonProps {
  active: boolean;
  disabled?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
}

const BoxButton = (props: BoxButtonProps) => {
  const { active, disabled, children, onClick } = props;
  return (
    <Box active={active} disabled={disabled} onClick={onClick}>
      {children}
    </Box>
  );
};

export default BoxButton;

const Box = styled.button<{ active: boolean; disabled?: boolean }>`
  width: 30px;
  height: 25px;
  border-radius: 2px;
  font-weight: bold;
  font-size: 1.125rem;
  margin-left: 5px;
  text-align: center;
  line-height: 24px;
  color: ${GetColor.Primary.Dark};
  background-color: ${(props) => (props.active ? ColorUtils.opacifyFrom(GetColor.Primary.Dark, 0.1) : GetColor.White)};
  border: 1px solid ${(props) => (props.active && !props.disabled ? GetColor.Primary.Dark : GetColor.PaleGrey)};
  transition: all 0.2s ease-out;

  @media print {
    background-color: white;
    border: none;
    color: black;
    display: ${(props) => (props.active ? 'flex' : 'none')};
    width: 10px;
    height: 100%;
  }

  &:hover {
    cursor: pointer;
    color: ${GetColor.Primary.Dark};
    background-color: ${ColorUtils.opacifyFrom(GetColor.Primary.Dark, 0.1)};
  }

  &:disabled {
    background-color: ${GetColor.Grey};
    color: ${GetColor.MidGrey2};
    cursor: not-allowed;
    pointer-events: none;
  }
`;
