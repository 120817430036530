import type { AssetTypeEnum, Fund, InvestmentSourceTypeEnum, ProxyTypeEnum } from 'venn-api';
import type { PartialBy } from '../type';

export type CheckUpdateFundType = PartialBy<
  Pick<Fund, 'outOfDate' | 'assetType' | 'investmentSource' | 'hasMinDataForInterpolation' | 'proxyType'>,
  'proxyType'
>;

/* Returns true if the fund's returns are insufficient/incomplete and they should be updated using Manage Data */
export const shouldUserUpdateDataForFund = (fund?: CheckUpdateFundType) => {
  if (!fund) {
    return false;
  }
  return shouldUserUpdate(
    fund.outOfDate,
    fund.assetType,
    fund.investmentSource,
    fund.hasMinDataForInterpolation,
    fund.proxyType,
  );
};

/* Returns true if the fund's returns are insufficient/incomplete and they should be updated using Proxy/Backfill */
export const shouldUserUpdateProxyForFund = (fund?: CheckUpdateFundType) => {
  if (!fund) {
    return false;
  }
  return shouldUserUpdate(
    fund?.outOfDate,
    fund?.assetType,
    fund?.investmentSource,
    fund?.hasMinDataForInterpolation,
    fund?.proxyType,
    true,
  );
};

function shouldUserUpdate(
  outOfDate: boolean,
  assetType: AssetTypeEnum,
  investmentSource: InvestmentSourceTypeEnum,
  hasMinDataForInterpolation: boolean,
  proxyType?: ProxyTypeEnum,
  isProxyUpdate = false,
) {
  if (proxyType === 'SUBSTITUTE') {
    return false;
  }
  if (assetType === 'BENCHMARK') {
    return false;
  }
  if (investmentSource !== 'UPLOAD' && (!isProxyUpdate || investmentSource !== 'CUSTODIAN')) {
    return false;
  }
  return outOfDate || !hasMinDataForInterpolation;
}
