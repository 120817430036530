import React from 'react';
import styled from 'styled-components';
import { useRecoilValue, waitForAll } from 'recoil';
import { GetColor, Label } from 'venn-ui-kit';
import {
  blockRequestSubjects,
  blockSubjectInputGroups,
  subjectInputGroupName,
  subjectInputGroupSubjects,
} from 'venn-state';
import { withSuspense } from 'venn-utils';

export const ComparisonOrderSubSection = withSuspense(null, ({ selectedBlockId }: { selectedBlockId: string }) => {
  const subjects = useRecoilValue(blockRequestSubjects(selectedBlockId));
  const subjectGroupIds = useRecoilValue(blockSubjectInputGroups(selectedBlockId));
  const subjectGroupNames = useRecoilValue(
    waitForAll(subjectGroupIds.map((subjectGroupId) => subjectInputGroupName(subjectGroupId))),
  );
  const allSubjects = useRecoilValue(
    waitForAll(subjectGroupIds.map((subjectId) => subjectInputGroupSubjects(subjectId))),
  );

  const [firstGroupIndex, secondGroupIndex] = allSubjects
    .map((groupSubjects) => groupSubjects.filter((subject) => subject.portfolioId))
    .flatMap((groupSubjects, index) => groupSubjects.map(() => index))
    .slice(0, 2);

  return (
    <ComparisonOrderSubSectionContainer>
      <StyledLabel>Comparison Order</StyledLabel>
      <ComparisonOrderGrid>
        <ComparisonOrderItem />
        <ComparisonOrderItem>
          {subjects[1] ? (
            <>
              <span>{subjects[1].name}</span>
              <SubjectInputGroupName>&nbsp;({subjectGroupNames[secondGroupIndex]})</SubjectInputGroupName>
            </>
          ) : (
            <CursiveText>{ADD_SUBJECT_TEXT}</CursiveText>
          )}
        </ComparisonOrderItem>
        <ComparisonOrderItem>–</ComparisonOrderItem>
        <ComparisonOrderItem>
          {subjects[0] ? (
            <>
              <span>{subjects[0].name}</span>
              <SubjectInputGroupName>&nbsp;({subjectGroupNames[firstGroupIndex]})</SubjectInputGroupName>
            </>
          ) : (
            <CursiveText>{ADD_SUBJECT_TEXT}</CursiveText>
          )}
        </ComparisonOrderItem>
        <ComparisonOrderItem>=</ComparisonOrderItem>
        <ComparisonOrderItem>Changes</ComparisonOrderItem>
      </ComparisonOrderGrid>
    </ComparisonOrderSubSectionContainer>
  );
});

const ADD_SUBJECT_TEXT = 'Add subject group above';
const CursiveText = styled.div`
  color: ${GetColor.GreyScale.Grey70};
  font-style: italic;
`;

const ComparisonOrderSubSectionContainer = styled.div`
  background-color: ${GetColor.GreyScale.Grey10};
  margin: 8px -20px -20px -20px;
  padding: 8px 16px;
  font-size: 1rem;
`;

const ComparisonOrderGrid = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  row-gap: 8px;
  column-gap: 4px;
  margin-top: 8px;
  ::after {
    content: '';
    grid-row: 3;
    grid-column: 1 / span 2;
    border-bottom: 1px solid ${GetColor.GreyScale.Grey50};
  }
`;

const StyledLabel = styled(Label)`
  margin-bottom: 8px;
`;

const ComparisonOrderItem = styled.div<{ lastRow?: boolean }>`
  ${({ lastRow }) => lastRow && `border-top: 1px solid ${GetColor.GreyScale.Grey50}`}
`;

const SubjectInputGroupName = styled.span`
  font-size: 10px;
  color: ${GetColor.GreyScale.Grey70};
`;
