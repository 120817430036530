import type {
  MethodInfo,
  MethodInfoServerStreaming,
  MethodInfoUnary,
  PartialMessage,
  ServiceType,
  Message,
} from '@bufbuild/protobuf';
import { MethodKind } from '@bufbuild/protobuf';
import type { CallOptions, StreamResponse, Transport } from '@connectrpc/connect';
import { ConnectError, makeAnyClient } from '@connectrpc/connect';
import { ResultAsync } from 'neverthrow';

/**
 * ResultClient is a simple client that supports unary and server-streaming
 * methods. Methods will produce a promise for either a result or a ConnectError.
 * Implementation is largely copied from
 * https://github.com/connectrpc/connect-es/blob/36548d/packages/connect/src/promise-client.ts
 * with minor modifications.
 */
export type ResultClient<T extends ServiceType> = {
  [P in keyof T['methods']]: T['methods'][P] extends MethodInfoUnary<infer I, infer O>
    ? (request: PartialMessage<I>, options?: CallOptions) => ResultAsync<O, ConnectError>
    : T['methods'][P] extends MethodInfoServerStreaming<infer I, infer O>
      ? (request: PartialMessage<I>, options?: CallOptions) => AsyncIterable<O>
      : never;
};

/**
 * Creates ResultClient from a service definition generated by protobuf-es protoc plugin.
 */
export function createResultClient<T extends ServiceType>(service: T, transport: Transport) {
  return makeAnyClient(service, (method) => {
    switch (method.kind) {
      case MethodKind.Unary:
        return createUnaryFn(transport, service, method);
      case MethodKind.ServerStreaming:
        return createServerStreamingFn(transport, service, method);
      default:
        return null;
    }
  }) as ResultClient<T>;
}

/**
 * UnaryFn is the method signature for a unary method of a ResultClient.
 */
type UnaryFn<I extends Message<I>, O extends Message<O>> = (
  request: PartialMessage<I>,
  options?: CallOptions,
) => ResultAsync<O, ConnectError>;

/**
 * Creates a unary method that will be available on the result client.
 */
function createUnaryFn<I extends Message<I>, O extends Message<O>>(
  transport: Transport,
  service: ServiceType,
  method: MethodInfo<I, O>,
): UnaryFn<I, O> {
  return (input, options): ResultAsync<O, ConnectError> => {
    const response = transport.unary(service, method, options?.signal, options?.timeoutMs, options?.headers, input);
    const content = response.then((unaryResponse) => {
      options?.onHeader?.(unaryResponse.header);
      options?.onTrailer?.(unaryResponse.trailer);
      return unaryResponse.message;
    });

    return ResultAsync.fromPromise(content, (e) => ConnectError.from(e));
  };
}

// Code below is directly copied from https://github.com/connectrpc/connect-es/blob/36548d/packages/connect/src/promise-client.ts,
// as ResultClient handles servers streaming in exactly the same way as PromiseClient.The reasoning for that is that
// it's not very clear on the semantics of Result that can either contain a response or an error, because
// server streaming RPC can produce both 0 or more responses AND an error.

type ServerStreamingFn<I extends Message<I>, O extends Message<O>> = (
  request: PartialMessage<I>,
  options?: CallOptions,
) => AsyncIterable<O>;

/**
 * Creates a server streaming method that will be available on the result client.
 */
function createServerStreamingFn<I extends Message<I>, O extends Message<O>>(
  transport: Transport,
  service: ServiceType,
  method: MethodInfo<I, O>,
): ServerStreamingFn<I, O> {
  return (input, options): AsyncIterable<O> =>
    handleStreamResponse(
      transport.stream<I, O>(
        service,
        method,
        options?.signal,
        options?.timeoutMs,
        options?.headers,
        createAsyncIterable([input]),
        options?.contextValues,
      ),
      options,
    );
}

function handleStreamResponse<I extends Message<I>, O extends Message<O>>(
  stream: Promise<StreamResponse<I, O>>,
  options?: CallOptions,
): AsyncIterable<O> {
  async function* generator() {
    const response = await stream;
    options?.onHeader?.(response.header);
    yield* response.message;
    options?.onTrailer?.(response.trailer);
  }

  const it = generator()[Symbol.asyncIterator]();
  // Create a new iterable to omit throw/return.
  return {
    [Symbol.asyncIterator]: () => ({
      next: () => it.next(),
    }),
  };
}

// eslint-disable-next-line @typescript-eslint/require-await
async function* createAsyncIterable<T>(items: T[]): AsyncIterable<T> {
  yield* items;
}
