import { Numbers } from 'venn-utils';

export const contributionTypesMap = {
  risk: 'riskContribution',
  return: 'returnContribution',
  exposure: 'returnContribution',
};

export const riskTypesMap = {
  risk: 'riskContribution',
  return: 'returnContribution',
  exposure: 'beta',
};

export const formatValue = (value: number, isPercentage: boolean) =>
  isPercentage ? Numbers.safeFormatPercentage(value) : Numbers.safeFormatNumber(value);
