import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { MetricDescriptionTooltip } from 'venn-components';
import { Tooltip, TooltipPosition } from 'venn-ui-kit';

interface EnhancedMetricNameProps {
  name: string;
  description?: string;
  learnMoreUrl?: string;
}

export const EnhancedMetricName = ({ name, description, learnMoreUrl }: EnhancedMetricNameProps) => {
  const { Colors } = useContext(ThemeContext);

  return (
    <Tooltip
      content={<MetricDescriptionTooltip name={name} description={description} learnMore={!!learnMoreUrl} />}
      position={TooltipPosition.Right}
      maxWidth={320}
      background={Colors.White}
      showShadow
      largerPointer
    >
      <Name data-testid="enhanced-metric-name">
        {learnMoreUrl ? (
          <a href={learnMoreUrl} target="_blank" rel="noopener noreferrer">
            {name}
          </a>
        ) : (
          name
        )}
      </Name>
    </Tooltip>
  );
};

const Name = styled.span`
  & > a {
    color: unset;
  }
`;
