import { compact, isNil } from 'lodash';
import React, { useContext, useRef } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { StudioContext, UserContext } from 'venn-components';
import { allBlockIdsState, blockSettingsMap, isReportState, selectedBlockIdState } from 'venn-state';
import type { DropMenuItem } from 'venn-ui-kit';
import { getAppTitle, GetColor, getTextThemeProvider, Icon, PRIVATE_ASSET_LAB_FAQ_HREF } from 'venn-ui-kit';
import {
  type CustomizableBlockSetting,
  PRIVATES_PERFORMANCE_BLOCKS,
  analyticsService,
  assertNotNil,
  buttonize,
  HOLDINGS_DATA_BLOCKS,
  isPortfolioBlock,
  PRIVATES_CASH_FLOW_BLOCKS,
  PRIVATES_METADATA_BLOCKS,
  TEXT_AND_FORMAT_BLOCKS,
  useHasFF,
  PRIVATES_ASSET_GROWTH_BLOCKS,
  PRIVATE_CASH_FLOW_BLOCK_SETTINGS,
  PRIVATE_CONTRIBUTIONS_BLOCK_SETTINGS,
  PRIVATE_DISTRIBUTIONS_BLOCK_SETTINGS,
  PRIVATE_NAV_BLOCK_SETTINGS,
  PRIVATE_ASSET_PERFORMANCE_SUMMARY_BLOCK_SETTINGS,
  PRIVATE_ASSET_PERFORMANCE_TIMESERIES_BLOCK_SETTINGS,
  PUBLIC_PRIVATE_ASSET_GROWTH_PERCENTILES_BLOCK_SETTINGS,
  PUBLIC_PRIVATE_ASSET_GROWTH_BREAKDOWN_BLOCK_SETTINGS,
  PRIVATE_ASSET_SUMMARY_BLOCK_SETTINGS,
} from 'venn-utils';
import CustomTextIcon from '../../Icons/CustomTextIcon';
import DragCard from '../../Icons/DragCard';
import { DragIconMapper } from '../../Icons/DragIcons';
import { BorderBottom, FlexHeader, Section } from '../../shared';
import NewPageButton from './components/NewPageButton';
import PrivatesUpsell from './components/PrivatesUpsell';
import { SensitivityChangesInfo } from './components/SensitivityChangesInfo';

const DropBlocksPanel = () => {
  const hasPrivateAnalytics = useHasFF('private_analytics');
  const hasPrivatesFF = useHasFF('privates_reveal_ff');
  const hasExportsDisabled = useHasFF('disable_exports_ff');
  const hasPublicPrivateAssetGrowthFF = useHasFF('public_private_asset_growth_ff');

  const isReport = useRecoilValue(isReportState);
  const { hasPermission } = useContext(UserContext);
  const isStudio = !isReport;
  const blockSettingMapper = useRecoilValue(blockSettingsMap);
  // Make sure blocks are ordered alphabetically
  const allBlockSettingsItems = Object.values(blockSettingMapper).sort((a, b) => (a.title > b.title ? 1 : -1));
  const textAndFormattingBlocks = TEXT_AND_FORMAT_BLOCKS.filter((block) => isStudio || block !== 'PAGE_BREAK');

  const privateAssetsRef = useRef<HTMLDivElement>(null);
  const sensitivityAnalysisRef = useRef<HTMLDivElement>(null);

  const privateBlocksOrder: string[] = [
    PRIVATE_CONTRIBUTIONS_BLOCK_SETTINGS,
    PRIVATE_DISTRIBUTIONS_BLOCK_SETTINGS,
    PRIVATE_NAV_BLOCK_SETTINGS,
    PRIVATE_CASH_FLOW_BLOCK_SETTINGS,
    PRIVATE_ASSET_PERFORMANCE_SUMMARY_BLOCK_SETTINGS,
    PRIVATE_ASSET_PERFORMANCE_TIMESERIES_BLOCK_SETTINGS,
    PUBLIC_PRIVATE_ASSET_GROWTH_PERCENTILES_BLOCK_SETTINGS,
    PUBLIC_PRIVATE_ASSET_GROWTH_BREAKDOWN_BLOCK_SETTINGS,
    PRIVATE_ASSET_SUMMARY_BLOCK_SETTINGS,
  ].map((block) => block.id);

  let privateBlocks = allBlockSettingsItems
    .filter((block) => privateBlocksOrder.includes(block.id))
    .sort((a, b) => privateBlocksOrder.indexOf(a.id) - privateBlocksOrder.indexOf(b.id));

  if (!hasPublicPrivateAssetGrowthFF) {
    privateBlocks = privateBlocks.filter((block) => !PRIVATES_ASSET_GROWTH_BLOCKS.includes(block.customBlockType));
  }

  const blockSettingsItems = compact([
    {
      ref: sensitivityAnalysisRef,
      title: 'Performance & Risk',
      blocks: allBlockSettingsItems
        .filter(
          (block) =>
            !block.hasFactors &&
            !TEXT_AND_FORMAT_BLOCKS.includes(block.customBlockType) &&
            !HOLDINGS_DATA_BLOCKS.includes(block.customBlockType) &&
            !PRIVATES_CASH_FLOW_BLOCKS.includes(block.customBlockType) &&
            !PRIVATES_PERFORMANCE_BLOCKS.includes(block.customBlockType) &&
            !PRIVATES_ASSET_GROWTH_BLOCKS.includes(block.customBlockType) &&
            !PRIVATES_METADATA_BLOCKS.includes(block.customBlockType),
        )
        .filter((block) => !(isPortfolioBlock(block.customBlockType) && !isReport)),
    },
    {
      title: 'Factors',
      blocks: allBlockSettingsItems.filter((block) => block.hasFactors),
    },
    {
      title: 'Holdings',
      blocks: allBlockSettingsItems.filter((block) => HOLDINGS_DATA_BLOCKS.includes(block.customBlockType)),
    },
    hasPrivatesFF &&
      !hasPrivateAnalytics && {
        title: 'Privates Upsell',
        content: (
          <PrivatesUpsell
            content={
              <PrivatesLinks>
                <button
                  type="button"
                  onClick={() => {
                    window.open(
                      `mailto:${getTextThemeProvider().salesEmail}?subject=Private Asset Lab Inquiry`,
                      '_blank',
                      'noopener,noreferrer',
                    );
                    analyticsService.ctaClicked({
                      destination: 'Private Asset Lab inquiry email',
                      filled: true,
                      locationOnPage: 'Insert panel paywall link',
                      purpose: 'Inquire about Private Asset lab',
                      text: 'Click here',
                      type: 'button',
                    });
                  }}
                >
                  Click here
                </button>{' '}
                to contact your {getAppTitle()} representative or{' '}
                <button
                  type="button"
                  onClick={() => {
                    window.open(PRIVATE_ASSET_LAB_FAQ_HREF, '_blank', 'noopener,noreferrer');
                    analyticsService.ctaClicked({
                      destination: 'Private Asset Lab help article',
                      filled: true,
                      locationOnPage: 'Insert panel paywall link',
                      purpose: 'Inquire about Private Asset lab',
                      text: 'click here',
                      type: 'button',
                    });
                  }}
                >
                  click here
                </button>{' '}
                to learn more about Private Asset Lab
              </PrivatesLinks>
            }
          />
        ),
      },
    hasPrivatesFF && {
      title: 'Private Assets',
      blocks: privateBlocks,
      disabled: !hasPrivateAnalytics,
      ref: privateAssetsRef,
    },
    (isReport || !hasExportsDisabled) && {
      title: 'Formatting',
      blocks: allBlockSettingsItems.filter((block) => textAndFormattingBlocks.includes(block.customBlockType)),
    },
  ]);

  return (
    <div>
      {!isStudio && (
        <BorderBottom>
          <NewPageButton />
        </BorderBottom>
      )}
      {hasPrivatesFF && !hasPrivateAnalytics && (
        <PrivatesUpsell
          content={
            <ScrollToPrivateBlocksButton
              onClick={() => privateAssetsRef.current?.scrollIntoView({ block: 'end', behavior: 'smooth' })}
            >
              See our new Private Asset Blocks <Icon type="circle-down" prefix="far" />
            </ScrollToPrivateBlocksButton>
          }
        />
      )}
      <SensitivityChangesInfo
        content={
          <ScrollToPrivateBlocksButton
            onClick={() => sensitivityAnalysisRef.current?.scrollIntoView({ block: 'start', behavior: 'smooth' })}
          >
            Check out the new Shock Input options below <Icon type="circle-down" prefix="far" />
          </ScrollToPrivateBlocksButton>
        }
      />
      {blockSettingsItems.map(({ title, blocks, disabled, content, ref }, index) =>
        !isNil(content) ? (
          <React.Fragment key={title}>{content}</React.Fragment>
        ) : (
          <Section key={title} noBorder={blockSettingsItems.length === index + 1} disabled={disabled} ref={ref}>
            <FlexHeader style={{ paddingLeft: 10 }}>{title}</FlexHeader>
            <BlockGroup data-testid={`qa-block-group-${title}`}>
              {assertNotNil(blocks).map((setting) => (
                <Card
                  item={setting}
                  key={setting.id}
                  clickToAdd={isStudio}
                  disabled={!hasPermission('STUDIO_INSERT_BLOCKS') || disabled}
                />
              ))}
            </BlockGroup>
          </Section>
        ),
      )}
    </div>
  );
};

export default DropBlocksPanel;

const Card = ({
  item,
  clickToAdd,
  disabled,
}: {
  item: CustomizableBlockSetting;
  clickToAdd?: boolean;
  disabled?: boolean;
}) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const { onInsertBlock, setDraggingBlock } = useContext(StudioContext);

  const addBlock = useRecoilCallback(
    ({ snapshot }) =>
      async (block: CustomizableBlockSetting) => {
        const selectedViewId = await snapshot.getPromise(selectedBlockIdState);
        const allBlockIds = await snapshot.getPromise(allBlockIdsState);
        const insertIndex = selectedViewId ? allBlockIds.indexOf(selectedViewId) + 1 : undefined;
        onInsertBlock({ value: block } as DropMenuItem<CustomizableBlockSetting>, insertIndex);

        analyticsService.ctaClicked({
          purpose: 'insert studio block',
          locationOnPage: `${block.customBlockType} block from panel`,
        });
      },
    [onInsertBlock],
  );
  return (
    <div
      data-testid="qa-block-card"
      className="droppable-element"
      draggable={!clickToAdd && !disabled}
      onDragStart={(e: React.DragEvent) => {
        // this is a hack for firefox
        // Firefox requires some kind of initialization
        // which we can do by adding this attribute
        // @see https://bugzilla.mozilla.org/show_bug.cgi?id=568313
        setDraggingBlock(item);
        e.dataTransfer.setData('text/plain', '');
        const ghost = document.createElement('div');
        const icon = renderToStaticMarkup(
          <>{DragIconMapper[item.supportedGraphicTypes[0] ?? ''] ?? <CustomTextIcon />}</>,
        );
        ghost.style.width = '150px';
        ghost.innerHTML = icon;
        ghost.style.transform = 'translate(-10000px, -10000px)';
        document.body.appendChild(ghost);
        e.dataTransfer.setDragImage(ghost, 0, 0);
      }}
      onDragEnd={() => {
        setDraggingBlock(undefined);
      }}
      key={item.id}
      {...(!disabled ? buttonize(() => clickToAdd && addBlock(item)) : { 'aria-disabled': true })}
    >
      <DragCard
        iconsWrapperRef={cardRef}
        blockSetting={item}
        draggable={!clickToAdd && !disabled}
        disabled={disabled}
      />
    </div>
  );
};

const BlockGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
  padding-left: 10px;
`;

const ScrollToPrivateBlocksButton = styled.button`
  font-size: 11px;
  font-weight: 500;
  margin-top: 2px;
  text-align: left;
  &:hover {
    color: ${GetColor.Primary.Dark};
  }
`;

const PrivatesLinks = styled.div`
  font-size: 11px;
  margin-top: 2px;
  font-weight: 500;
`;
