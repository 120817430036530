import { useRecoilValue } from 'recoil';
import { viewDataGridSizeType } from 'venn-state';
import { DataGridSizeType } from '../components/grid/types';
import { useBlockId } from '../contexts/BlockIdContext';

export const useIsBlockGridCompact = () => {
  const blockId = useBlockId();
  const dataGridSizeType = useRecoilValue(viewDataGridSizeType(blockId));
  return dataGridSizeType === DataGridSizeType.COMPACT;
};
