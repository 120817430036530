import type { ThemeProvider } from 'venn-ui-kit';

export type CustomFont = {
  fontSizePt: number;
};

export type CustomBlockFonts = {
  blockTitle: CustomFont;
  blockSubtitle: CustomFont;
  blockHeaderInfo: CustomFont;
  blockTableData: CustomFont;
  blockChartAxis: CustomFont;
  blockChartLegend: CustomFont;
};

export const blockFontKeys = [
  'blockTitle',
  'blockSubtitle',
  'blockHeaderInfo',
  'blockTableData',
  'blockChartAxis',
  'blockChartLegend',
] as const;

export type CustomPageFonts = {
  titlePageDate: CustomFont;
  titlePageTitle: CustomFont;
  titlePageSubtitle: CustomFont;
  titlePageContent: CustomFont;
  sectionPageName: CustomFont;
  sectionPageTitle: CustomFont;
  sectionPageSubtitle: CustomFont;
  sectionPageOrgName: CustomFont;
};

export const titlePageFontKeys = ['titlePageDate', 'titlePageTitle', 'titlePageSubtitle', 'titlePageContent'] as const;

export const sectionPageFontKeys = [
  'sectionPageName',
  'sectionPageTitle',
  'sectionPageSubtitle',
  'sectionPageOrgName',
] as const;

export const customPageFontKeys = [...titlePageFontKeys, ...sectionPageFontKeys] as const;

export type CustomFonts = CustomBlockFonts & CustomPageFonts;

export type GridStyle = {
  gridSize: string;
  fontSize: string;
  cellHorizontalPaddingPx: number;
  indentPerAutoGroupLevel: number;
  fontFamily: ThemeProvider;
  className: string;
};
