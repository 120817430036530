import { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import type { AllocConstraint, AnalysisSubject, FactorExposureConstraint, ObjectiveType } from 'venn-utils';
import { userActivity } from 'venn-utils';
import { keys } from 'lodash';

export interface PortfolioLabStoredSettings {
  portfolioId?: number;
  objective?: ObjectiveType;
  constraintValue?: number;
  allocationConstraints?: AllocConstraint[];
  factorConstraints?: FactorExposureConstraint[];
  benchmarkSubject?: AnalysisSubject;
  relative?: boolean;
}

export interface UseValuesFromHistoryState {
  settings: PortfolioLabStoredSettings;
  storeSettings: (values: Partial<PortfolioLabStoredSettings>) => void;
}

export const RECENT_PORTFOLIO_LAB_SETTINGS_KEY = 'venn_portfolio_lab_recent_settings';

const useValuesFromHistoryState = (portfolioId?: number): UseValuesFromHistoryState => {
  const history = useHistory<PortfolioLabStoredSettings>();
  const { location } = history;

  const storeSettings = useCallback(
    (values: Partial<PortfolioLabStoredSettings>) => {
      const state = { ...history.location.state };
      for (const key of keys(values)) {
        state[key] = values[key];
      }
      const newLocation = `${history.location.pathname}${history.location.search ?? ''}`;
      history.replace(newLocation, state);
    },
    [history],
  );

  useEffect(() => {
    if (portfolioId !== location.state?.portfolioId) {
      storeSettings({
        portfolioId,
        objective: undefined,
        constraintValue: undefined,
        allocationConstraints: undefined,
        factorConstraints: undefined,
        benchmarkSubject: undefined,
        relative: undefined,
      });
    }
  }, [portfolioId, storeSettings, location.state]);

  const settings: PortfolioLabStoredSettings = useMemo(
    () => ({
      portfolioId: location.state?.portfolioId,
      objective: location.state?.objective,
      constraintValue: location.state?.constraintValue,
      allocationConstraints: location.state?.allocationConstraints,
      factorConstraints: location.state?.factorConstraints,
      benchmarkSubject: location.state?.benchmarkSubject,
      relative: location.state?.relative,
    }),
    [location.state],
  );

  useEffect(() => {
    userActivity.setLocalStorageItemPerUser(
      RECENT_PORTFOLIO_LAB_SETTINGS_KEY,
      JSON.stringify({ ...settings, queryParams: history.location.search }),
    );
  }, [settings, history.location.search]);

  return {
    settings,
    storeSettings,
  };
};

export default useValuesFromHistoryState;
