import queryString from 'query-string';

// TODO: add types to this function
export function getCreatePasswordParams() {
  const locationSearchString = window.location.search.slice(1);
  const { email, activationCode, organization, jurisdiction, firstName, lastName, sponsorKey } =
    queryString.parse(locationSearchString);
  return {
    email: Array.isArray(email) ? email[0] : email,
    activationCode: Array.isArray(activationCode) ? activationCode[0] : activationCode,
    sponsorKey: Array.isArray(sponsorKey) ? sponsorKey[0] : sponsorKey,
    organization: Array.isArray(organization) ? organization[0] : organization,
    jurisdiction: Array.isArray(jurisdiction) ? jurisdiction[0] : jurisdiction,
    firstName: Array.isArray(firstName) ? firstName[0] : firstName,
    lastName: Array.isArray(lastName) ? lastName[0] : lastName,
  };
}

export function getSignUpParams() {
  const locationSearchString = window.location.search.slice(1);
  const { email, activationCode, organizationId, countryCode, organizationLegalName } =
    queryString.parse(locationSearchString);

  return {
    email: Array.isArray(email) ? email[0] : email,
    activationCode: Array.isArray(activationCode) ? activationCode[0] : activationCode,
    organizationId: Array.isArray(organizationId) ? organizationId[0] : organizationId,
    countryCode: Array.isArray(countryCode) ? countryCode[0] : countryCode,
    organizationLegalName: Array.isArray(organizationLegalName) ? organizationLegalName[0] : organizationLegalName,
  };
}
