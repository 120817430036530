import type { ReactNode } from 'react';
import React from 'react';
import styled, { css } from 'styled-components';
import { GetColor, Hint, Icon } from 'venn-ui-kit';

interface InputsSectionHeaderWithClearAllProps {
  isSideBarMaximized: boolean;
  text: ReactNode;
  clearAllLabel: string;
  onClearAll: () => void;
  canClearAll: boolean;
}

const InputsSectionHeaderWithClearAll = ({
  isSideBarMaximized,
  text,
  clearAllLabel,
  onClearAll,
  canClearAll,
}: InputsSectionHeaderWithClearAllProps) => {
  return (
    <Header narrow={!isSideBarMaximized}>
      <Hint>{text}</Hint>
      <ClearAll type="button" disabled={!canClearAll} onClick={onClearAll} separateLine={!isSideBarMaximized}>
        {clearAllLabel} <Icon type="times-circle" />
      </ClearAll>
    </Header>
  );
};

export default InputsSectionHeaderWithClearAll;

const Header = styled.div<{ narrow?: boolean }>`
  margin-bottom: 20px;
  display: flex;
  flex-direction: ${({ narrow }) => (narrow ? 'column' : 'row')};
  justify-content: space-between;
  align-items: flex-start;
`;

const ClearAll = styled.button<{ separateLine?: boolean }>`
  min-width: 190px;
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
  ${({ separateLine }) =>
    separateLine &&
    css`
      text-align: right;
      width: 100%;
      margin-top: 16px;
    `}
  color: ${({ disabled }) => (disabled ? GetColor.MidGrey1 : GetColor.Primary.Dark)};
  &:hover {
    color: ${({ disabled }) => (disabled ? GetColor.MidGrey1 : GetColor.Primary.Main)};
  }
`;
