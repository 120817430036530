import React from 'react';
import type { PortfolioParseResult } from 'venn-api';
import styled from 'styled-components';
import { ColorUtils, GetColor, Icon } from 'venn-ui-kit';
import { hasUnmatchedFunds } from './helpers';

export type IndexedPortfolioParseResult = PortfolioParseResult & { originalIndex: number };

type ParseResultProps = {
  data: IndexedPortfolioParseResult;
  setSelectedIndex: React.Dispatch<number>;
  selected: boolean;
};

export const ParsedPortfolioOption = ({ data, selected, setSelectedIndex }: ParseResultProps) => {
  const hasError = hasUnmatchedFunds(data.parsedPortfolio);
  return (
    <PortfolioNameContainer
      data-testid="parsed-portfolio-option"
      data-is-selected={selected}
      hasError={hasError}
      onClick={() => setSelectedIndex(data.originalIndex)}
      selected={selected}
    >
      {hasError && <ErrorIcon type="triangle-exclamation" prefix="far" />}
      {data.parsedPortfolio.name}
    </PortfolioNameContainer>
  );
};

const ErrorIcon = styled(Icon)`
  padding-right: 2px;
`;

const PortfolioNameContainer = styled.button<{ selected: boolean; hasError: boolean }>`
  box-sizing: border-box;
  border-width: ${({ selected }) => (selected ? '1px 0' : '0 0 1px 0')};
  border-style: solid;
  border-color: ${({ selected }) => (selected ? GetColor.DarkBlue : GetColor.GreyScale.Grey30)};
  background: ${({ selected }) => (selected ? ColorUtils.opacifyFrom(GetColor.DarkBlue, 0.1) : GetColor.White)};
  color: ${({ hasError }) => (hasError ? GetColor.Error : GetColor.Black)};
  display: flex;
  padding: 8px 16px 8px 24px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  font-size: 14px;
  font-weight: ${({ selected }) => (selected ? 700 : 400)};
  min-height: 42px;
  text-align: left;

  :hover {
    color: ${GetColor.DarkBlue};
    background: ${ColorUtils.opacifyFrom(GetColor.DarkBlue, 0.1)};
  }

  // Previous sibling of selected element should not have border-bottom
  // Otherwise it creates double-border effect
  &:has(+ &[data-is-selected='true']) {
    border-width: 0;
  }
`;
