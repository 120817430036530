import React from 'react';
import styled from 'styled-components';
import { GetColor, Shimmer } from 'venn-ui-kit';
import { OBJECTIVE_SELECTOR_HEIGHT, LoaderContainer, SeparatorPill } from 'venn-components';

const OpportunitiesSelectorLoader = () => (
  <LoaderContainer>
    <SeparatorPill>Consider</SeparatorPill>
    <PillLoader />
    <SeparatorPill>within</SeparatorPill>
    <LastPillLoader />
  </LoaderContainer>
);

export default OpportunitiesSelectorLoader;

const PillLoader = styled.div`
  height: ${OBJECTIVE_SELECTOR_HEIGHT - 2}px;

  display: flex;
  align-items: center;

  i {
    margin-left: 10px;
    color: ${GetColor.MidGrey1};
  }
  ${Shimmer}
  animation-duration: 7s;
  width: 157px;
`;

const LastPillLoader = styled(PillLoader)`
  border-radius: 0 113px 113px 0;
`;
