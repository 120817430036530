import React, { useContext, useMemo, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import type { TradeSummary } from 'venn-api';
import { EllipsisTooltipSpan, Expander, GetColor, Icon, Tooltip } from 'venn-ui-kit';
import type { BasicTableColumn } from 'venn-components';
import { ColumnAlign, ContentBlock, PrintContainerDimensions } from 'venn-components';
import { Table, TableEmptyState } from './styled-table';
import { isNil } from 'lodash';
import SuggestedTrade from './SuggestedTrade';
import { Numbers } from 'venn-utils';

type CardType = 'INCREASE' | 'DECREASE';

interface LargestAverageCardProps {
  cardType: CardType;
  data: TradeSummary[] | undefined;
}

const getColumns = (cardType: CardType) => [
  {
    label: 'Name',
    accessor: 'name',
    align: ColumnAlign.LEFT,
    cellRenderer: (tradeSummary: TradeSummary) => (
      <PrintContainerDimensions>
        {({ width }) => (
          <EllipsisTooltipSpan maxWidth={width} tooltipMaxWidth={450}>
            {tradeSummary.fund.name}
          </EllipsisTooltipSpan>
        )}
      </PrintContainerDimensions>
    ),
  },
  {
    label: 'Average Trade Size',
    accessor: 'avgTradeSize',
    align: ColumnAlign.RIGHT,
    cellRenderer: (tradeSummary: TradeSummary) => (
      <SuggestedTrade
        tradeType={cardType === 'INCREASE' ? 'PURCHASE' : 'SELL'}
        value={tradeSummary.value}
        originalValue={tradeSummary.originalValue}
      />
    ),
  },
];

const COLLAPSED_ROWS_LIMIT = 3;

const LargestAverageCard = ({ cardType, data }: LargestAverageCardProps) => {
  const { Colors } = useContext(ThemeContext);
  const [collapsed, setCollapsed] = useState(true);

  const rows = useMemo(() => {
    if (isNil(data)) {
      return [];
    }
    const filteredData = data.filter((item) => {
      const displayValue = Numbers.safeFormatNumber(item.value, 1);
      return displayValue !== '0.0' && displayValue !== '-0.0';
    });
    return collapsed ? filteredData.slice(0, COLLAPSED_ROWS_LIMIT) : filteredData;
  }, [collapsed, data]);
  const columns: BasicTableColumn<TradeSummary>[] = useMemo(() => getColumns(cardType), [cardType]);

  return (
    <ContentBlock
      header={
        <>
          Largest Average {cardType === 'INCREASE' ? 'Increase' : 'Decrease'}&nbsp;
          <Tooltip
            content="Average trade size from your optimized and alternate portfolios listed on the efficient frontier chart."
            usePortal
          >
            <StyledIcon type="info-circle" prefix="far" />
          </Tooltip>
        </>
      }
    >
      {(data ?? []).length === 0 ? (
        <TableEmptyState header="No trades found" />
      ) : (
        <Content>
          <Table data={rows} columns={columns} />
          {(data ?? []).length > COLLAPSED_ROWS_LIMIT && (
            <Expander
              style={{
                color: Colors.HintGrey,
                fontSize: 14,
                fontWeight: 'normal',
                marginBottom: 10,
              }}
              expanded={!collapsed}
              toggleExpanded={() => setCollapsed(!collapsed)}
              expandedLabel="Show top three"
              collapsedLabel="Show all"
            />
          )}
        </Content>
      )}
    </ContentBlock>
  );
};

export default LargestAverageCard;

const Content = styled.div`
  border-top: 1px solid ${GetColor.PaleGrey};
`;

const StyledIcon = styled(Icon)`
  font-size: 14px;
  width: 1px;
  height: 16px;
  vertical-align: middle;
`;
