import styled from 'styled-components';
import { Button, ButtonLink, Icon, GetColor, ColorUtils } from 'venn-ui-kit';
import type { BasicTableProps, BasicTableColumn } from 'venn-components';
import { BasicTable } from 'venn-components';
import React from 'react';

const Wrapper = styled.div`
  border-radius: 4px;
  border: 1px solid ${GetColor.Grey};
  padding: 40px 60px;
`;

export const StyledWrapper = styled(Wrapper)`
  margin-top: 20px;
  .visible {
    top: 200px;
  }
`;

export const Section = styled.div`
  width: 100%;
  padding: 24px 60px;
`;

export const WrapperForSections = styled(StyledWrapper)`
  padding: 24px 0;
  ${Section}:not(:last-child) {
    border-bottom: 1px solid ${GetColor.Grey};
  }
`;

export const FlexWrapper = styled.div`
  display: flex;
`;

export const BaselineAlignedFlexWrapper = styled(FlexWrapper)`
  align-items: baseline;
  line-height: 14px;
`;

export const InfoWrapper = styled.div`
  margin-left: 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Name = styled.div`
  font-weight: bold;
  font-size: 20px;
  margin-right: 11px;
`;

export const Subtitle = styled.div`
  font-weight: bold;
  font-size: 16px;
`;

export const Info = styled.div`
  color: ${GetColor.DarkGrey};
  font-size: 14px;
`;

export const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export const StyledButton = styled(Button)`
  height: 40px;
  min-width: 170px;
`;
export const StyledButtonLink = styled(ButtonLink)`
  height: 40px;
  min-width: 170px;
`;

export const StyledIcon = styled(Icon)`
  font-size: 20px;
  margin-right: 8px;
`;

export const SmallName = styled.div`
  font-size: 12px;
  font-weight: bold;
  margin-right: 5px;
`;

export const TableName = styled(Name)`
  margin-bottom: 10px;
`;

export const PaginationWrapper = styled.div`
  margin-top: 20px;
`;

export const SearchBox = styled.div`
  padding: 40px 0 0;
`;

export const ResultHint = styled.div`
  padding-top: 20px;
  color: ${GetColor.HintGrey};
`;

export const StyledTable = styled(<T extends BasicTableColumn<K>, K>(props: BasicTableProps<T, K>) => (
  <BasicTable<T, K> {...props} />
))`
  thead tr {
    border-bottom: 1px solid ${GetColor.Black};
    th {
      color: ${GetColor.Black};
    }
  }
  tbody > tr {
    font-size: 14px;
    border-bottom: 1px solid ${GetColor.PaleGrey};

    &:hover {
      background-color: ${ColorUtils.hex2rgbaFrom(GetColor.Primary.Main, 0.1)};
      .members-menu-trigger {
        color: ${GetColor.Black};
      }
    }
  }
  tbody > tr > td > span {
    display: flex;
  }
  .avatar {
    margin-right: 1px;
    margin-bottom: 1px;
  }
`;

export const Cell = styled.span<{ default?: boolean }>`
  &[role='button'] {
    cursor: pointer;
  }
  color: ${(props) => (props.default ? GetColor.HighlightDark : GetColor.Black)};
  height: 34px;
  line-height: 34px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 1100px) {
    max-width: 500px;
    padding-right: 10px;
  }
`;
