import { compact, noop } from 'lodash';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import {
  blockBenchmarkConfig,
  blockCustomizableMetricsMap,
  blockMetrics,
  blockSettings,
  customizedBlock,
  MetricError,
  useMetricsErrors,
} from 'venn-state';
import { useBlockId } from '../contexts/BlockIdContext';
import { isPrivatesPerformanceBlock } from 'venn-utils';

export function usePrivatesBenchmarkMetricError() {
  const blockId = useBlockId();
  const block = useRecoilValue(customizedBlock(blockId));
  const blockSetting = useRecoilValue(blockSettings(blockId));
  const selectedMetrics = useRecoilValue(blockMetrics(blockId));
  const metricDefinitionsMap = useRecoilValue(blockCustomizableMetricsMap(blockId));
  const { clearMetricErrors, setMetricErrors } = useMetricsErrors(blockId);
  const benchmarkConfig = useRecoilValue(blockBenchmarkConfig(blockId));

  useEffect(() => {
    if (!isPrivatesPerformanceBlock(blockSetting.customBlockType)) {
      return noop;
    }

    if (block.infoGraphicType !== 'GRID' && selectedMetrics.length > 1) {
      setMetricErrors([
        {
          metricKey: 'blockLevel',
          error: MetricError.ONE_METRIC,
        },
      ]);
      return clearMetricErrors;
    }

    const errorsInMetrics = compact(
      selectedMetrics.map((metric) => {
        if (metricDefinitionsMap[metric].needsBenchmark && benchmarkConfig.type === 'NONE') {
          return {
            metricKey: metric,
            error: MetricError.PME_NEEDS_BENCHMARK,
          };
        }
        return null;
      }),
    );
    setMetricErrors(errorsInMetrics);
    return clearMetricErrors;
  }, [
    benchmarkConfig.type,
    block.infoGraphicType,
    blockSetting.customBlockType,
    metricDefinitionsMap,
    selectedMetrics,
    setMetricErrors,
    clearMetricErrors,
  ]);
}
