export const getPasswordMessage = (password: string) => {
  if (password.length < 9) {
    return 'Passwords should be a minimum of 9 characters.';
  }
  if (!hasEnoughLowercase(password)) {
    return 'Passwords must have at least 1 lowercase character';
  }
  if (!hasEnoughUppercase(password)) {
    return 'Passwords must have at least 1 uppercase character';
  }
  return '';
};

const hasEnoughLowercase = (value = '', minCount = 1) => {
  const regexp = /[a-z]/g;
  const matches = value.match(regexp);

  return matches ? matches.length >= minCount : false;
};

const hasEnoughUppercase = (value = '', minCount = 1) => {
  const regexp = /[A-Z]/g;
  const matches = value.match(regexp);

  return matches ? matches.length >= minCount : false;
};
