/* Returns true if we should prompt the user with a warning about inability to run factor analysis */

import type { OptionalLibrarySearchEntity } from 'venn-api';

export const showFactorAnalysisWarning = (item?: OptionalLibrarySearchEntity) => {
  if (!item) {
    return true;
  }
  if (item.portfolioId) {
    return false;
  }
  return !(item.hasFactorAnalysis || item.investmentSource === 'CUSTOM');
};
