import type { Serie } from '../types';
import type { SerieValue } from '../../../../charts/analysis-charts/types';
import { compact, noop } from 'lodash';
import type { VennLineChartData } from '../../../../charts/analysis-charts/chart-config-logic';
import {
  createVennLineChartConfig,
  drawVenncast,
  getLineSerie,
  calculateLegendPadding,
} from '../../../../charts/analysis-charts/chart-config-logic';
import type { Theme, VennColors } from 'venn-ui-kit';
import { getColorOnIndex, getTextThemeProvider, HighchartZIndex } from 'venn-ui-kit';
import type { CumulativeVenncastStatusEnum, FrequencyEnum } from 'venn-api';
import type { HighchartOptions } from 'venn-components';
import type { Chart } from 'highcharts';
import { type AnyDuringEslintMigration, assertExhaustive } from 'venn-utils';

interface LineChartOptions {
  theme: Theme;
  series: Serie[];
  frequency: FrequencyEnum;
  yAxisUnitFormat: 'percent' | 'ratio' | 'allocation';
  inPrintMode?: boolean;
  hasDrawdownLines?: boolean;
  height?: number;
  venncastEnabled: boolean;
  legendFontSize: number;
  axisFontSize: number;
}

export const getLineChartOptions = ({
  series,
  theme,
  yAxisUnitFormat,
  frequency,
  inPrintMode,
  hasDrawdownLines,
  height,
  venncastEnabled,
  legendFontSize,
  axisFontSize,
}: LineChartOptions) => {
  const requestSeries = series.reduce<SerieValue[]>((acc, rSeries, idx) => {
    const defaultColor = getColorOnIndex(theme.Colors, idx);
    const color = rSeries.color ?? defaultColor;

    const primarySeries = getPrimarySeries({
      series: rSeries,
      color,
    });
    const estimatedBoundSeries = venncastEnabled ? getEstimatedBoundSeries({ series: rSeries, color }) : undefined;
    const estimatedReturnSeries = venncastEnabled ? getEstimatedReturnSeries({ series: rSeries, color }) : undefined;

    const seriesToAdd = compact([primarySeries, estimatedBoundSeries, estimatedReturnSeries]);

    return [...acc, ...seriesToAdd];
  }, []);

  const config = createVennLineChartConfig(
    requestSeries,
    theme,
    axisFontSize,
    yAxisUnitFormat,
    frequency,
    undefined,
    inPrintMode,
    hasDrawdownLines,
    undefined,
  );

  return {
    ...config,
    legend: {
      enabled: true,
      useHTML: true,
      padding: calculateLegendPadding(legendFontSize),
      margin: 0,
      itemStyle: {
        fontSize: `${legendFontSize}pt`,
        itemMarginTop: 1,
        itemMarginBottom: 1,
      },
    },
    chart: {
      ...config.chart,
      height,
    },
  };
};

interface LineChartSeriesUtil {
  series: Serie;
  color: string;
}

export const getPrimarySeries = ({ series, color }: LineChartSeriesUtil): SerieValue | undefined => {
  const seriesData = getLineSerie(series.data, series.name, color);

  return seriesData
    ? {
        data: seriesData,
        name: series.name,
        legendLabel: series.name,
        color,
      }
    : undefined;
};

export const getEstimatedBoundSeries = ({ series, color }: LineChartSeriesUtil): SerieValue | undefined => {
  if (!series.venncast) return undefined;

  const seriesData = getLineSerie(
    // TODO: (VENN-20577 / TYPES) Are the autogenerated types wrong?
    series.venncast?.estimateBounds as AnyDuringEslintMigration,
    `${series.name} - Error range`,
    color,
    'arearange',
    // lastReturn, todo: lastReturn?
  );

  return seriesData
    ? {
        data: seriesData,
        name: series.name,
        legendLabel: series.name,
        color,
      }
    : undefined;
};

export const getEstimatedReturnSeries = ({ series, color }: LineChartSeriesUtil): SerieValue | undefined => {
  if (!series.venncast) return undefined;

  const seriesData = getLineSerie(
    series.venncast?.estimatedCumulativeReturns ?? [],
    `${series.name} - Estimated`,
    color,
    'dashline',
    // lastReturn, todo: lastReturn?
  );

  return seriesData
    ? {
        data: seriesData,
        name: series.name,
        legendLabel: series.name,
        color,
      }
    : undefined;
};

export const addVenncast = (
  config: HighchartOptions,
  data: VennLineChartData,
  colors: VennColors,
  print?: boolean,
) => ({
  ...config,
  chart: {
    ...config.chart,
    events: {
      ...(config?.chart?.events || {}),
      load(this: Chart) {
        ((config?.chart?.events?.load as () => void) || noop)();
        drawVenncast.bind(this, data, colors, print)();
      },
      redraw(this: Chart) {
        ((config?.chart?.events?.redraw as () => void) || noop)();
        drawVenncast.bind(this, data, colors, print)();
      },
    },
  },
  legend: {
    margin: 50,
  },
  xAxis: {
    ...config.xAxis,
    plotLines: [
      // TODO: (VENN-20577 / TYPES) can we just throw an error if xAxis is an array, to get rid of the any type?
      ...((config?.xAxis as AnyDuringEslintMigration)?.plotLines || []),
      ...(data && (data.persistentBottomBar || (data.estimated && data.visibleVenncastStart !== undefined))
        ? [
            {
              color: colors.DataLineColor.PaleBlue,
              value: data.visibleVenncastStart,
              width: data.persistentBottomBar ? 0 : 2,
              zIndex: HighchartZIndex.PlotBand.AboveSeries,
              id: 'venncast-line',
            },
          ]
        : []),
    ],
  },
});

type GetVenncastWarningMessage = (props: {
  seriesLength: number;
  frequency: FrequencyEnum;
  isFund: boolean;
  interpolated?: boolean;
  estimated?: boolean;
  venncastStatus?: CumulativeVenncastStatusEnum;
}) => string | undefined;

export const getVenncastWarningMessage: GetVenncastWarningMessage = ({
  seriesLength,
  frequency,
  isFund,
  interpolated = false,
  estimated,
  venncastStatus,
}) => {
  if (seriesLength > 1) {
    const venncastName = getTextThemeProvider().VenncastName;
    return `${venncastName} is available only for a single subject`;
  }

  return venncastStatus
    ? getVenncastDisableMessageByStatus(venncastStatus, isFund, frequency)
    : getDefaultVenncastDisableMessage(frequency, isFund, interpolated, estimated);
};

const getDefaultVenncastDisableMessage = (
  returnFrequency: FrequencyEnum,
  isFund: boolean,
  isInterpolated: boolean,
  estimated?: boolean,
) => {
  const venncastName = getTextThemeProvider().VenncastName;
  if (!estimated && returnFrequency === 'QUARTERLY') {
    return isFund
      ? `Interpolate this investment to enable ${venncastName}`
      : `Interpolate your quarterly investments to enable ${venncastName}`;
  }

  if (returnFrequency === 'YEARLY') {
    return `${venncastName} does not support yearly investments`;
  }

  return isInterpolated
    ? `${venncastName} can estimate returns for interpolated investments up to 9 months out`
    : `${venncastName} can estimate returns up to 6 months out`;
};

const getVenncastDisableMessageByStatus = (
  venncastStatus: CumulativeVenncastStatusEnum,
  isFund: boolean,
  returnFrequency?: FrequencyEnum,
): string | undefined => {
  const venncastName = getTextThemeProvider().VenncastName;
  switch (venncastStatus) {
    case 'INSUFFICIENT_RETURNS':
      return `${venncastName} requires at least ${
        returnFrequency === 'DAILY' ? '6 months' : '1 year'
      } of return history`;
    case 'UP_TO_DATE':
      return 'Returns for your selection are already up-to-date';
    case 'INVALID_END_DATE':
      return `${venncastName} is only available when your last return date is visible`;
    case 'RELATIVE':
      return `${venncastName} is not available when "Relative to Benchmark" is selected`;
    case 'QUARTERLY':
      return isFund
        ? `Quarterly subjects cannot be ${venncastName}ed. Interpolate this investment to enable ${venncastName}`
        : `Quarterly subjects cannot be ${venncastName}ed. Interpolate your quarterly investments to enable ${venncastName}`;
    case 'PRIMARY_SUBJECT_ERROR':
      return 'An error occurred with the given subject';
    case 'SECONDARY_SUBJECT':
      return undefined;
    case 'SUCCESSFUL':
      return undefined;
    default:
      throw assertExhaustive(venncastStatus);
  }
};
