import type { ReactNode } from 'react';
import React, { useCallback, useState } from 'react';
import { analyticsService, LibraryItemType, LibraryTab, navigateToLibrary, useIsMounted } from 'venn-utils';
import { useHistory } from 'react-router-dom';
import { DataUploaderMode } from './types';
import { Notifications, NotificationType } from 'venn-ui-kit';
import DataUploaderModalWrapper from './DataUploaderModalWrapper';

interface DataUploaderLauncherAnalyticsProps {
  locationOnPage: string;
  text: string;
  type: string;
  filled?: boolean;
}

interface DataUploaderLauncherProps {
  analyticsProps: DataUploaderLauncherAnalyticsProps;
  children: (onLaunchDataUploader: () => void) => ReactNode;
}

const DataUploaderLauncher = ({ analyticsProps, children }: DataUploaderLauncherProps) => {
  const { locationOnPage, text, type, filled } = analyticsProps;
  const [uploaderOpen, setUploaderOpen] = useState(false);
  const [lastTimeBeforeUpdate, setLastTimeBeforeUpdate] = useState<number>();
  const history = useHistory();
  const mounted = useIsMounted();

  const onCompleteUploader = useCallback(
    (mode: DataUploaderMode, uploadedFundIds?: string[]) => {
      Notifications.notify('Upload Successfully Completed!', NotificationType.SUCCESS);
      setUploaderOpen(false);
      navigateToLibrary(history, {
        tab: mode === DataUploaderMode.Privates ? LibraryTab.PrivateAssets : LibraryTab.ReturnsData,
        selectedIds: uploadedFundIds ?? [],
        selectedFilters: {
          itemType: LibraryItemType.UPLOAD,
          quickFilters: [],
          tags: [],
        },
        lastTimeBeforeUpdate,
      });
    },
    [history, lastTimeBeforeUpdate],
  );

  const onLaunchUploader = useCallback(() => {
    setUploaderOpen(true);
    setLastTimeBeforeUpdate(Date.now());
    analyticsService.ctaClicked({
      destination: undefined,
      filled: !!filled,
      locationOnPage,
      purpose: 'Upload returns',
      text,
      type,
    });
  }, [locationOnPage, text, type, filled]);

  return (
    <>
      {children(onLaunchUploader)}{' '}
      {uploaderOpen && (
        <DataUploaderModalWrapper
          mode={DataUploaderMode.Returns}
          onCancel={() => (mounted.current ? setUploaderOpen(false) : null)}
          onCompleteNavigate={onCompleteUploader}
        />
      )}
    </>
  );
};

export default DataUploaderLauncher;
