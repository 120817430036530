import React, { useContext, useMemo } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Icon, ItemIcon, ItemType, getItemLabel, BrandLogo } from 'venn-ui-kit';
import compact from 'lodash/compact';
import { SavedViewsQuickFilter, useHasFF } from 'venn-utils';
import { SectionKey } from './types';
import { LABELS } from './constants';
import { UserContext } from 'venn-components';

const StyledIcon = styled(Icon)<{ color?: string }>`
  color: ${(props) => props.color};
  padding-right: 5px;
`;
const CustomIconWrapper = styled.div`
  display: inline-block;
  padding-right: 5px;
`;

const useQuickFilters = () => {
  const { hasPermission } = useContext(UserContext);
  const { Colors } = useContext(ThemeContext);
  const enableStudio = useHasFF('studio_ff');
  const enableReport = useHasFF('studio_report_editor');
  const enableCompare = hasPermission('COMPARE');
  const canScheduleExports = !useHasFF('disable_exports_ff');

  return useMemo(
    () => [
      {
        key: SectionKey.ITEM_TYPE,
        title: 'View',
        checkbox: true,
        items: compact([
          ItemType.Analysis,
          enableCompare ? ItemType.Compare : null,
          enableStudio ? ItemType.StudioTearsheet : null,
          enableStudio ? ItemType.TearsheetTemplate : null,
          enableReport ? ItemType.StudioReport : null,
          enableReport ? ItemType.ReportTemplate : null,
        ]).map((itemType: ItemType) => ({
          label: getItemLabel(itemType),
          value: itemType,
          icon: (
            <CustomIconWrapper key={itemType}>
              <ItemIcon item={itemType} />
            </CustomIconWrapper>
          ),
        })),
      },
      {
        key: SectionKey.FILTERS,
        title: 'Quick filters',
        checkbox: true,
        items: compact([
          {
            label: LABELS[SavedViewsQuickFilter.REQUIRES_ATTENTION],
            value: SavedViewsQuickFilter.REQUIRES_ATTENTION,
            icon: <StyledIcon type="exclamation-triangle" color={Colors.Error} />,
          },
          {
            label: LABELS[SavedViewsQuickFilter.RECENTLY_ANALYZED],
            value: SavedViewsQuickFilter.RECENTLY_ANALYZED,
            icon: <StyledIcon type="clock" />,
          },
          {
            label: LABELS[SavedViewsQuickFilter.SAVED_BY_ME],
            value: SavedViewsQuickFilter.SAVED_BY_ME,
            icon: <StyledIcon type="save" />,
          },
          canScheduleExports && {
            label: LABELS[SavedViewsQuickFilter.SCHEDULED],
            value: SavedViewsQuickFilter.SCHEDULED,
            icon: <StyledIcon type="history" />,
          },
          {
            label: LABELS[SavedViewsQuickFilter.VENN_PROVIDED],
            value: SavedViewsQuickFilter.VENN_PROVIDED,
            icon: <StyledBrandLogo height={14} scaleToFit />,
          },
        ]),
      },
    ],
    [Colors.Error, canScheduleExports, enableCompare, enableReport, enableStudio],
  );
};

const StyledBrandLogo = styled(BrandLogo)`
  margin-right: 4px;
`;

export default useQuickFilters;
