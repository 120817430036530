import { throttle } from 'lodash';
import type { UserProfileSettings } from 'venn-api';

const THIRTY_MINUTES = 30 * 60 * 1000;
const LAST_ACTIVE_THROTTLE_DURATION = 10 * 1000;

export interface UserActivityServiceInterface {
  readonly orgId: number | undefined;
  initialize: () => void;
  setUser: (profileSettings: UserProfileSettings) => void;
  getLocalStorageItemPerUser: (key: string) => string | undefined;
  setLocalStorageItemPerUser: (key: string, value: string) => void;
}

class UserActivityService implements UserActivityServiceInterface {
  get orgId() {
    return this._orgId;
  }

  USER_LIBRARY_RECENT_QUERIES = 'library_recent_queries';

  USER_REPORTS_PAGE_RECENT_QUERIES = 'reports_page_recent_queries';

  SESSION_ID_LOCAL_STORAGE = 'thirty_minute_session_id';

  private _initialized = false;

  // Use current timestamp as random user id for anonymous tracking before user is authenticated
  private _userId: number = Date.now();

  private _orgId?: number;

  private setLastActive = throttle((): void => {
    const lastActive = this.getLastActive();
    const now = Date.now().toString();
    if (this.lastActiveTimeLongerThanThirtyMinutes(lastActive)) {
      this.setLocalStorageItemPerUser(this.SESSION_ID_LOCAL_STORAGE, now);
    }

    window.sessionStorage?.setItem('timeLastActive', now);
  }, LAST_ACTIVE_THROTTLE_DURATION);

  initialize(): void {
    // Only initialize once
    if (this._initialized) {
      return;
    }

    this._initialized = true;
    const doc: Document = document;
    doc.onload = this.setLastActive;
    doc.onmousemove = this.setLastActive;
    doc.onmousedown = this.setLastActive; // Also captures: touchscreen presses
    doc.ontouchstart = this.setLastActive;
    doc.onclick = this.setLastActive; // Also captures: touchpad clicks
    doc.onscroll = this.setLastActive;
    doc.onkeypress = this.setLastActive;

    if (!this.getLocalStorageItemPerUser(this.SESSION_ID_LOCAL_STORAGE)) {
      this.setLocalStorageItemPerUser(this.SESSION_ID_LOCAL_STORAGE, Date.now().toString());
    }

    this.setLastActive();
  }

  setUser = (profileSettings: UserProfileSettings): void => {
    const { id: userId } = profileSettings.user;
    const { id: orgId } = profileSettings.organization;
    // Transfer anonymous sessionId to user account once identified
    const currentSessionId = this.getLocalStorageItemPerUser(this.SESSION_ID_LOCAL_STORAGE);
    this.removeLocalStorageItemPerUser(this.SESSION_ID_LOCAL_STORAGE);
    this._userId = userId;
    this._orgId = orgId;
    this.setLocalStorageItemPerUser(this.SESSION_ID_LOCAL_STORAGE, currentSessionId ?? '');
  };

  getLocalStorageItemPerUser = (key: string, id?: string): string | undefined =>
    localStorage?.getItem(`${key}-${id ?? this._userId}`) ?? undefined;

  setLocalStorageItemPerUser = (key: string, value: string, id?: string): void => {
    localStorage?.setItem(`${key}-${id ?? this._userId}`, value);
  };

  removeLocalStorageItemPerUser = (key: string, id?: string): void => {
    localStorage?.removeItem(`${key}-${id ?? this._userId}`);
  };

  private lastActiveTimeLongerThanThirtyMinutes = (lastActive?: string): boolean =>
    !!(lastActive && Number(Date.now()) - Number(lastActive) > THIRTY_MINUTES);

  private getLastActive = (): string | undefined => window.sessionStorage?.getItem('timeLastActive') ?? undefined;
}

export default new UserActivityService();
