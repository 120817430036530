import type { FC } from 'react';
import React from 'react';
import { ModalFooter } from '../../../../modal';

export interface BenchmarksFooterProps {
  onOKButtonClick(): void;
  onCancelButtonClick(): void;
}

const BenchmarksListFooter: FC<React.PropsWithChildren<BenchmarksFooterProps>> = ({
  onOKButtonClick,
  onCancelButtonClick,
}) => (
  <ModalFooter
    onCancel={onCancelButtonClick}
    primaryLabel="Ok"
    onPrimaryClick={onOKButtonClick}
    primaryClassName="qa-benchmarks-ok"
  />
);

export default BenchmarksListFooter;
