import type { DeepMapTo } from 'venn-utils';
import { assertNotNil } from 'venn-utils';
import type { Theme as ColorTheme } from './color';

interface AppScreenshotImages {
  correlations?: string;
  cumulativeResidualReturn?: string;
  factorContributionsToExposureTrend?: string;
  factorContributionsToReturnTrend?: string;
  factorContributionsToRiskTrend?: string;
  historicalDrawdownChart?: string;
  performanceAttribution?: string;
  residualPerformanceSummary?: string;
  rollingReturn?: string;
  scenarioAnalysis?: string;
}

interface PartnerLogos {
  northernTrust?: string;
  addepar?: string;
  backstop?: string;
  bny?: string;
  citco?: string;
  citi?: string;
  dynamo?: string;
  jpMorgan?: string;
  mercer?: string;
  parilux?: string;
  solovis?: string;
  ssAndC?: string;
  stateStreet?: string;
  wilShire?: string;
  six?: string;
}

interface TutorialImages {
  riskReturn?: string;
  scenario?: string;
  macroScenario?: string;
  portfolioLabs?: string;
  triangles?: string;
  trend?: string;
  credit?: string;
  factorBreakdown?: string;
  drawdown?: string;
  holdingsAsset?: string;
  extrapolationAsset?: string;
  reportLabAsset?: string;
  morningstarCategoryAsset?: string;
  privatesAsset?: string;
  privateAssetAGSConeChart?: string;
  privateAssetCFPModelSettings?: string;
  peerGroupAnalysisAsset?: string;
}

interface SlideoutImages {
  reportlabSlideoutAsset?: string;
  reportlabSlideoutAsset2?: string;
  privateAssetLabSlideoutAsset?: string;
  privateAssetLabSlideoutAsset2?: string;
}

interface VennImages {
  vennProvidedLogo?: string;
  vennProvidedLogoGreyscale?: string;
  integrationBanner?: string;
  vennBackground?: string;
  brandLogo?: string;
  trianglesRepeating?: string;
  customFonts?: string;
}

interface AddeparSetupImages {
  addeparStep1AddeparLogin?: string;
  addeparStep1Authorization?: string;
  addeparStep2PortfolioPrefix?: string;
  addeparStep2PortfolioName?: string;
  addeparStep2InvestmentName?: string;
  addeparStep5Sync?: string;
}

export interface Images
  extends SlideoutImages,
    TutorialImages,
    VennImages,
    AppScreenshotImages,
    PartnerLogos,
    AddeparSetupImages {}

export interface Typography {
  fontFamily: string;
}

export interface Videos {
  splashScreenAnimation: string;
  factorLensVideo: string;
  productTourVideo: string;
  aisDemoVideo: string;
}

export type Theme = {
  Videos: Videos;
  Images: Images;
  inPrintMode: boolean;
  inPrintModeOrReportLab: boolean;
} & ColorTheme;

export type DynamicThemeType = 'green' | 'default';

/**
 * Function that can be used with styled-components, that takes in a theme and returns css. Used extensively by venn-ui-kit color system.
 */
export type ThemeProvider = (props: { theme: Theme }) => string;
type GetTypographyType = DeepMapTo<Typography, ThemeProvider>;
type GetImagesType = DeepMapTo<Images, ThemeProvider>;
export const GetTypography: GetTypographyType = {
  fontFamily: ({ theme }) => theme.Typography.fontFamily,
};

/** warning: this util does not fall back to default image theme */
export const GetImages: GetImagesType = {
  integrationBanner: ({ theme }) => assertNotNil(theme.Images.integrationBanner),
  triangles: ({ theme }) => assertNotNil(theme.Images.triangles),
  trianglesRepeating: ({ theme }) => assertNotNil(theme.Images.trianglesRepeating),
  vennBackground: ({ theme }) => assertNotNil(theme.Images.vennBackground),
};
