import React from 'react';
import noop from 'lodash/noop';

export interface TagsContextValue {
  tags: string[];
  refreshTags: () => void;
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
const TagsContext = React.createContext<TagsContextValue>({
  tags: [],
  refreshTags: noop,
});

export default TagsContext;
