import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Button, Icon, GetColor, getAppTitle } from 'venn-ui-kit';
import { Info, FlexRow } from '../account-modals/shared';
import type { UserProfileSettings } from 'venn-api';
import { BackupCodesModal } from './BackupCodesModal';
import SwitchMethod from './SwitchMethod';
import { AccountModalWrapper } from '../account-modals/ModalWrapper';
import { currentMethod, primaryMethodIsSMS } from './utils';
import { EnableTwoFactor } from './EnableTwoFactor';
import { Modal } from '../../modal';
import { FS } from 'venn-utils';

interface TwoFactorEnabledProps {
  profileSettings: UserProfileSettings;
  iconClassName: string;
  modalTitle: string;
  switchMethod: () => void;
  refresh: () => void;
}

interface TwoFactorState {
  showBackupCodesModal: boolean;
  showSwitchMethodModal: boolean;
  showChangePhoneDialog: boolean;
}

export class TwoFactorEnabled extends PureComponent<TwoFactorEnabledProps, TwoFactorState> {
  state = {
    showBackupCodesModal: false,
    showSwitchMethodModal: false,
    showChangePhoneDialog: false,
  };

  closeChangePhoneDialog = () => {
    this.setState({
      showChangePhoneDialog: false,
    });
  };

  openChangePhoneDialog = () => {
    this.setState({
      showChangePhoneDialog: true,
    });
  };

  renderButton = () => {
    const { profileSettings, refresh } = this.props;
    const mfaUpdatesFF = FS.has('mfa_updates_ff');
    const primaryIsSMS = primaryMethodIsSMS(profileSettings);
    const method = currentMethod(profileSettings);
    const title = `${method} is currently turned on`;
    const line = primaryIsSMS
      ? `You will receive your verification codes by text when you sign in to ${getAppTitle()}.`
      : `Use the Authenticator app to get your verification code when you sign in to ${getAppTitle()}.`;
    return (
      <>
        <IconDiv2FA showChangePhone={mfaUpdatesFF && primaryIsSMS}>
          <StyledIcon type={primaryIsSMS ? 'mobile-alt' : 'google'} prefix={primaryIsSMS ? 'fas' : 'fab'} />
          <ButtonText>
            <ButtonTitle>{title}</ButtonTitle>
            <ButtonLine>{line}</ButtonLine>
          </ButtonText>
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: 14 }}>
            <StyledButton dense onClick={this.showSwitchMethodModal}>
              Manage 2fa options
            </StyledButton>
            {mfaUpdatesFF && primaryIsSMS && (
              <StyledButtonChangePhone dense onClick={this.openChangePhoneDialog}>
                Change SMS Number
              </StyledButtonChangePhone>
            )}
          </div>
        </IconDiv2FA>
        {mfaUpdatesFF && this.state.showChangePhoneDialog && (
          <Modal onClose={this.closeChangePhoneDialog} closeOnEsc>
            <EnableTwoFactor onClose={this.closeChangePhoneDialog} refresh={refresh} initializeMethod="Text" />
          </Modal>
        )}
      </>
    );
  };

  openBackupCodesModal = () => {
    this.setState({
      showBackupCodesModal: true,
    });
  };

  closeBackupCodesModal = () => {
    this.setState({
      showBackupCodesModal: false,
    });
  };

  showSwitchMethodModal = () => {
    this.setState({
      showSwitchMethodModal: true,
    });
  };

  closeSwitchMethodModal = (confirmSwitch: boolean) => {
    this.setState({
      showSwitchMethodModal: false,
    });
    if (confirmSwitch) {
      this.props.switchMethod();
    }
  };

  render() {
    const { showBackupCodesModal, showSwitchMethodModal } = this.state;
    const { modalTitle, iconClassName, profileSettings } = this.props;
    return (
      <>
        <Info>
          {`Each time you sign in to your ${getAppTitle()} account you will be prompted to provide both your password and your
          secondary authentication code.`}
        </Info>
        <Content>
          <FlexRow>{this.renderButton()}</FlexRow>
          <FlexRow>
            <IconDiv>
              <StyledIcon type="check-circle" prefix="fas" />
              <ButtonText>
                <ButtonTitle>Backup Codes</ButtonTitle>
                <ButtonLine>Keep a list of backup codes for when you do not have access to your phone.</ButtonLine>
              </ButtonText>
              <StyledButton dense onClick={this.openBackupCodesModal}>
                Manage backup codes
              </StyledButton>
            </IconDiv>
          </FlexRow>
        </Content>
        {showBackupCodesModal && (
          <AccountModalWrapper onClose={this.closeBackupCodesModal} title={modalTitle} iconClassName={iconClassName}>
            <BackupCodesModal onClose={this.closeBackupCodesModal} />
          </AccountModalWrapper>
        )}
        {showSwitchMethodModal && (
          <AccountModalWrapper
            onClose={() => this.closeSwitchMethodModal(false)}
            title={modalTitle}
            iconClassName={iconClassName}
          >
            <SwitchMethod
              profileSettings={profileSettings}
              onSwitchMethod={() => this.closeSwitchMethodModal(true)}
              onClose={() => this.closeSwitchMethodModal(false)}
            />
          </AccountModalWrapper>
        )}
      </>
    );
  }
}

export default TwoFactorEnabled;

const Content = styled.div`
  padding: 40px 0 15px;
`;

const IconDiv = styled.div`
  font-size: 15px;
  font-weight: normal;
  color: ${GetColor.Black};
  background-color: ${GetColor.DEPRECATED_DivergingColor.B1};
  border-radius: 4px;
  text-transform: none;
  letter-spacing: normal;
  text-align: left;
  width: 100%;
  height: 60px;
  justify-content: left;
  margin-bottom: 10px;

  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }

  p {
    margin: 0;
  }
`;

const IconDiv2FA = styled(IconDiv)<{ showChangePhone: boolean }>`
  height: ${({ showChangePhone }) => (showChangePhone ? 90 : 60)}px;
`;

const ButtonTitle = styled.p`
  font-size: 14px;
`;

const StyledButton = styled(Button)`
  position: absolute;
  right: 2px;
  top: 14px;
  width: 200px;
  &:last-child {
    margin-right: 20px;
  }
`;
const StyledButtonChangePhone = styled(StyledButton)`
  top: 50px;
`;

const ButtonLine = styled.p`
  font-size: 12px;
  font-weight: normal;
`;

const ButtonText = styled.div`
  display: inline-block;
  padding-left: 9px;
  vertical-align: middle;
`;

const StyledIcon = styled(Icon)`
  font-size: 40px;
  width: 40px;
  height: 40px;
  color: ${GetColor.Black};
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
`;
