import React, { useEffect, useState } from 'react';
import type { FactorCondition, FactorExposureConstraint } from 'venn-utils';
import { CenteredText } from '../components/styled';
import ConstraintActions from '../components/ConstraintActions';
import type { Portfolio } from 'venn-api';
import { FactorConstraintRow } from './styled';
import { isNil } from 'lodash';
import ConstraintValueInput from '../components/ConstraintValueInput';

interface NewFactorConstraintProps {
  portfolio: Portfolio;
  initialValue?: number;
  factorId: number;
  condition: FactorCondition;
  hasError: (value: number) => boolean;
  onDeleteConstraint: () => void;
  onCreateConstraint: (constraint: FactorExposureConstraint) => void;
}

const NewFactorConstraint = ({
  portfolio,
  initialValue,
  factorId,
  condition,
  hasError,
  onDeleteConstraint,
  onCreateConstraint,
}: NewFactorConstraintProps) => {
  const [value, setValue] = useState<number | undefined>();
  const [isCreating, setIsCreating] = useState(false);

  useEffect(() => {
    if (!isNil(value) && !hasError(value) && !isCreating) {
      setIsCreating(true);
      onCreateConstraint({ factorId, condition, isInPortfolioPolicy: initialValue === value, value });
    }
  }, [value, factorId, condition, hasError, onCreateConstraint, isCreating, initialValue]);

  return (
    <FactorConstraintRow>
      <CenteredText>{condition === 'minExposure' ? 'Minimum' : 'Maximum'} exposure:</CenteredText>
      <ConstraintValueInput
        placeholder={isNil(initialValue) ? undefined : initialValue.toString()}
        hasError={!isNil(value) && hasError(value)}
        onSetValue={setValue}
      />
      <CenteredText>(β)</CenteredText>
      <ConstraintActions
        portfolio={portfolio}
        isInPortfolioPolicy={false}
        onDeleteConstraint={() => onDeleteConstraint()}
      />
    </FactorConstraintRow>
  );
};

export default NewFactorConstraint;
