import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import type { FrequencyEnum } from 'venn-api';
import { getScheduledExportByViewIds, scheduleExportForView } from 'venn-api';
import moment from 'moment';
import capitalize from 'lodash/capitalize';
import { useModal } from 'venn-utils';
import UserContext from '../contexts/user-context';
import { Notifications, NotificationType } from 'venn-ui-kit';

const NO_ACCESS_MESSAGE = "You don't have access to modify this view. Save a new view to schedule export.";

const useScheduled = (
  savedId?: string,
  onSave?: (name?: string) => Promise<string | undefined>,
  updateModal?: (isOpen: boolean) => void,
  hasSavedModified?: boolean,
  noAccessModifiedView?: boolean,
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentFrequency, setCurrentFrequency] = useState<FrequencyEnum>('UNKNOWN');
  const [unsavedFrequency, setUnsavedFrequency] = useState<FrequencyEnum>();
  const [scheduledExportId, setScheduledExportId] = useState<string>();
  const isUpdatingFrequency = useRef(false);
  const [showUnsavedChangeModal, openUnsavedChangeModal, closeUnsavedChangeModal] = useModal();
  const { profileSettings } = useContext(UserContext);

  const canScheduledExport = !noAccessModifiedView;

  useEffect(() => {
    const initExportInfo = async () => {
      setIsLoading(true);
      try {
        if (savedId) {
          const { content: exportList } = await getScheduledExportByViewIds([savedId]);
          if (exportList?.[0]?.scheduledFrequency) {
            setCurrentFrequency(exportList?.[0]?.scheduledFrequency);
          } else {
            setCurrentFrequency('UNKNOWN');
          }
          setScheduledExportId(exportList?.[0]?.scheduledExportId);
        }
      } catch (e) {
        Notifications.notify(e, NotificationType.ERROR);
      }
      setIsLoading(false);
    };

    // Prevent the race condition after we auto save a view, it would trigger frequency update here
    if (!isUpdatingFrequency.current && canScheduledExport) {
      initExportInfo();
    }
  }, [savedId, canScheduledExport]);

  const onUpdateFrequency = useCallback(
    async (frequency: FrequencyEnum, saveModified?: boolean) => {
      isUpdatingFrequency.current = true;
      setIsLoading(true);
      setCurrentFrequency(frequency);
      updateModal?.(false);
      closeUnsavedChangeModal();
      setUnsavedFrequency(undefined);
      try {
        let viewId = savedId;
        if (!viewId) {
          // Auto save the view
          const timestamp = moment().format('YYYY-MM-DD hh:mm A');
          viewId = await onSave?.(`Saved Compare View - ${timestamp}`);
        } else if (saveModified) {
          // Confirm to save with same name
          await onSave?.();
        }
        const { content: scheduledExport } = await scheduleExportForView({
          analysisViewId: viewId,
          scheduledFrequency: frequency,
          scheduledExportId,
        });
        setScheduledExportId(scheduledExport.scheduledExportId);
        const email = profileSettings?.user.email ? ` (${profileSettings?.user.email})` : '';
        const message =
          frequency === 'UNKNOWN'
            ? 'Scheduled export is off'
            : `${capitalize(frequency)} scheduled export is now active. Check your email${email} for a sample export.`;
        Notifications.notify(message);
      } catch (e) {
        if (e.name !== 'AbortError') {
          Notifications.notify(e?.message, NotificationType.ERROR);
        }
      }
      setIsLoading(false);
      isUpdatingFrequency.current = false;
    },
    [savedId, onSave, updateModal, scheduledExportId, closeUnsavedChangeModal, profileSettings],
  );

  const handleClickFrequency = useCallback(
    (frequency: FrequencyEnum) => {
      if (frequency === 'UNKNOWN' || !hasSavedModified) {
        onUpdateFrequency(frequency);
        return;
      }
      setUnsavedFrequency(frequency);
      openUnsavedChangeModal();
    },
    [openUnsavedChangeModal, onUpdateFrequency, hasSavedModified],
  );

  const cancelUpdateFrequency = useCallback(() => {
    setUnsavedFrequency(undefined);
    closeUnsavedChangeModal();
  }, [closeUnsavedChangeModal]);

  return {
    isLoading,
    currentFrequency,
    unsavedFrequency,
    handleClickFrequency,
    onUpdateFrequency,
    showUnsavedChangeModal,
    cancelUpdateFrequency,
    scheduledDisabledMessage: noAccessModifiedView ? NO_ACCESS_MESSAGE : '',
  };
};

export default useScheduled;
