import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { FactorExposure, Portfolio, PortfolioPolicy } from 'venn-api';
import { getPortfolioPolicyByPortfolioId, setPortfolioPolicy as setPortfolioPolicyApi } from 'venn-api';

import { isNil, values } from 'lodash';
import useApi from '../hooks/useApi/useApi';
import { logExceptionIntoSentry } from '../error-logging';
import type { AllocConstraint, FactorExposureConstraint } from './types';
import { parseAllocConstraints, parseConstraintsToPolicy, parseFactorExposureConstraint } from './parserUtils';
import { analyticsService } from '../analytics';

const usePortfolioPolicy = (portfolio: Portfolio | undefined, locationForTracking: string) => {
  const [portfolioPolicy, setPortfolioPolicy] = useState<PortfolioPolicy>();
  const [loadingPolicy, setLoadingPolicy] = useState(false);
  const [fetchingError, setFetchingError] = useState(false);

  const getPortfolioPolicyRef = useRef(useApi(getPortfolioPolicyByPortfolioId));

  const [saving, setSaving] = useState(false);
  const [savingError, setSavingError] = useState<boolean | undefined>();

  useEffect(() => {
    const fetchPolicy = async (portfolioId: number) => {
      try {
        const { content } = await getPortfolioPolicyRef.current(portfolioId);
        setPortfolioPolicy({
          ...content,
          constraints: content.constraints.filter((constraint) => constraint.condition !== 'MAX_TURNOVER'),
        });
        setFetchingError(false);
      } catch (e) {
        if (e.name !== 'AbortError') {
          logExceptionIntoSentry(e);
          setFetchingError(true);
        }
      }
      setLoadingPolicy(false);
    };

    if (
      !isNil(portfolio?.id) &&
      (isNil(portfolioPolicy) || portfolioPolicy.portfolioId !== portfolio.id) &&
      !loadingPolicy &&
      !fetchingError
    ) {
      setLoadingPolicy(true);
      fetchPolicy(portfolio.id);
    }
  }, [portfolio, portfolioPolicy, loadingPolicy, fetchingError]);

  const allocConstraints: AllocConstraint[] = useMemo(
    () =>
      isNil(portfolioPolicy) || isNil(portfolioPolicy.constraints) || isNil(portfolio)
        ? []
        : parseAllocConstraints(portfolioPolicy.constraints, portfolio),
    [portfolioPolicy, portfolio],
  );

  const factorConstraints: FactorExposureConstraint[] = useMemo(
    () =>
      isNil(portfolioPolicy) || isNil(portfolioPolicy.constraints)
        ? []
        : parseFactorExposureConstraint(portfolioPolicy.constraints),
    [portfolioPolicy],
  );

  const factorBreakdownData: FactorExposure[] = useMemo(
    () => (isNil(portfolioPolicy) || isNil(portfolioPolicy.factors) ? [] : values(portfolioPolicy.factors)),
    [portfolioPolicy],
  );

  const onUpdatePortfolioPolicy = useCallback(
    async (
      allocationConstraints: AllocConstraint[] | undefined,
      factorExpConstraints: FactorExposureConstraint[] | undefined,
    ) => {
      if (saving) {
        return;
      }
      setSaving(true);
      try {
        const updatedPolicy = parseConstraintsToPolicy(
          allocationConstraints,
          factorExpConstraints,
          portfolioPolicy,
          portfolio!,
        );
        const policyId = (await setPortfolioPolicyApi(updatedPolicy)).content;
        analyticsService.portfolioPolicyModified({
          location: locationForTracking,
          allocationConstraints: getAllocationConstraintsForTracking(updatedPolicy),
          factorConstraints: getFactorConstraintsForTracking(updatedPolicy),
          hasAllInvestmentsConstraints: updatedPolicy.constraints.some(
            (constraint) => constraint.constraintType === 'ALLOCATION' && constraint.allEntities,
          ),
          hasInvestmentConstraints: updatedPolicy.constraints.some((constraint) =>
            constraint.targets.some((target) => !isNil(target.fundId)),
          ),
          hasStrategyConstraints: updatedPolicy.constraints.some((constraint) =>
            constraint.targets.some((target) => isNil(target.fundId)),
          ),
        });
        setPortfolioPolicy({ ...updatedPolicy, id: policyId });
        setSavingError(false);
      } catch (e) {
        logExceptionIntoSentry(e);
        setSavingError(true);
        // Refresh portfolio policy to allow all constraints editors to update, so we're not stuck in a loading state forever
        !isNil(portfolioPolicy) && setPortfolioPolicy({ ...portfolioPolicy });
      }
      setSaving(false);
    },
    [saving, portfolio, portfolioPolicy, locationForTracking],
  );

  const onUpdateAllocationConstraints = useCallback(
    (allocationConstraints: AllocConstraint[]) => onUpdatePortfolioPolicy(allocationConstraints, undefined),
    [onUpdatePortfolioPolicy],
  );

  const onUpdateFactorConstraints = useCallback(
    (factorExpConstraints: FactorExposureConstraint[]) => onUpdatePortfolioPolicy(undefined, factorExpConstraints),
    [onUpdatePortfolioPolicy],
  );

  return {
    portfolioPolicy,
    loadingPolicy,
    policyFetchingError: fetchingError,

    policyAllocationConstraints: allocConstraints,
    policyFactorConstraints: factorConstraints,

    factorBreakdownData,

    savingPolicy: saving,
    savingPolicyError: savingError,

    onUpdateAllocationConstraints,
    onUpdateFactorConstraints,
  };
};

export default usePortfolioPolicy;

const getAllocationConstraintsForTracking = (policy: PortfolioPolicy) =>
  policy.constraints
    .filter((constraint) => constraint.constraintType === 'ALLOCATION')
    .map((constraint) => ({
      targets: constraint.targets.map((target) => target.fundId ?? target.strategyId),
      condition: constraint.condition,
      valueType: constraint.valueType,
      value: constraint.value,
    }));

const getFactorConstraintsForTracking = (policy: PortfolioPolicy) =>
  policy.constraints
    .filter((constraint) => constraint.constraintType === 'FACTOR')
    .map((constraint) => ({
      targets: constraint.targets.map((target) => target.factorId),
      condition: constraint.condition,
      value: constraint.value,
    }));
