import type { StudioAnalysisRequest } from 'venn-state';
import { range } from 'lodash';
import moment from 'moment';
import type { Analysis, ReturnsGridMapping } from 'venn-api';
import type { ReturnsFrequency } from '../../../returns/returnsFrequency';
import { periodsPerYear, returnsFrequencyToDurationUnit } from '../../../returns/returnsFrequency';
import { default as mockFund } from '../fund';

export const mockCalendarRequest: StudioAnalysisRequest = {
  dateRange: {
    to: 0,
    from: 999_999_999_999,
    period: 'full',
  },
  analysesTypes: ['RETURNS_GRID'],
  frequency: 'DAILY',
  subject: {
    id: mockFund.id,
    name: mockFund.name,
    fund: mockFund,
    subjectType: 'INVESTMENT',
    private: false,
  },
  benchmark: undefined,
  benchmarkType: 'NONE',
  relative: false,
};

export const TEST_VALUE = 123.56;

/** Makes a valid and fully filled returns grid map for the specific returnsFrequency and number of years. */
export function makeFullReturnsGrid(
  years: number[],
  returnsFrequency: ReturnsFrequency,
  valueFn: (year: number, periodIndex: number) => number = () => TEST_VALUE,
): ReturnsGridMapping['returnsGridMap'] {
  const numPeriodsPerYear = periodsPerYear(returnsFrequency);
  return {
    [returnsFrequency]: {
      returnCells: years.flatMap((year) =>
        range(numPeriodsPerYear).map((periodIndex) => ({
          cellTypes: ['ANALYSIS'],
          date: moment
            .utc(`${year}-01-01`)
            .add(periodIndex, returnsFrequencyToDurationUnit(returnsFrequency))
            .valueOf(),
          val: valueFn(year, periodIndex),
        })),
      ),
      yearlyCumulativeReturn: years.map((year) => ({
        cellTypes: ['TOTAL', 'ANALYSIS'],
        date: moment.utc(`${year}-01-01`).valueOf(),
        val: valueFn(year, numPeriodsPerYear),
      })),
    },
  };
}

export const mockCalendarAnalyses = (minYearInclusive: number, maxYearInclusive: number): (Analysis | undefined)[] => {
  const years = Array.from(
    { length: 1 + maxYearInclusive - minYearInclusive },
    (_v, index) => minYearInclusive + index,
  );
  return [
    {
      analysisType: 'RETURNS_GRID',
      exportable: [false],
      redistributable: [true],
      analysisRedistributable: true,
      returnsGrids: [
        {
          returnsGridMap: {
            ...makeFullReturnsGrid(years, 'MONTHLY'),
            ...makeFullReturnsGrid(years, 'QUARTERLY'),
            ...makeFullReturnsGrid(years, 'YEARLY'),
          },
          disabledFrequencies: [],
        },
      ],
    } as unknown as Analysis,
  ];
};
