import React from 'react';
import type { PrivatePortfolioNode } from 'venn-api';
import { Loading } from 'venn-ui-kit';
import { withSuspense } from 'venn-utils';
import PrivateSubjectCashflowPacingSettings from './PrivateSubjectCashflowPacingSettings';
import { useActiveCashflowSetting } from 'venn-components';

type PrivatePortfolioCashflowPacingSettingsProps = Readonly<{
  portfolio: PrivatePortfolioNode;
}>;

const PrivatePortfolioCashflowPacingSettings = ({ portfolio }: PrivatePortfolioCashflowPacingSettingsProps) => {
  const { data, refetch: refetchActiveSettings } = useActiveCashflowSetting(
    portfolio.children.map((child) => [child.fundId, child.name]),
  );
  return (
    <PrivateSubjectCashflowPacingSettings
      refetchActiveSettings={refetchActiveSettings}
      portfolio={portfolio}
      settingsRowData={data ?? []}
    />
  );
};

export default withSuspense(
  <Loading title="Cashflow pacing model parameters are loading... " />,
  PrivatePortfolioCashflowPacingSettings,
);
