import type {
  CustomRow,
  DataValue,
  FactorRowData,
  MetricRowData,
  ReturnsGridRow,
  ValueMapper,
  ScenarioRowData,
  PortfolioRowData,
} from '../types';
import { isNil, isObject } from 'lodash';
import type { ColDef, ColGroupDef } from 'ag-grid-community';
import { assertExhaustive } from 'venn-utils';

export const isDataValue = (value: unknown): value is DataValue =>
  ['string', 'number', 'undefined'].includes(typeof value) || value === null;

export const isValueMapper = (value: unknown): value is ValueMapper => {
  if (!isObject(value)) return false;
  return Object.values(value).every((v) => typeof v === 'number' || v === undefined || v === null);
};

export const isPortfolioRowData = (row: CustomRow): row is PortfolioRowData<unknown> => 'isStrategy' in row;

export const isMetricRow = (row: CustomRow): row is MetricRowData =>
  'label' in row && 'value' in row && Array.isArray(row.value) && isDataValue(row.value[0]);

export const isMetricRowData = (rows: CustomRow[]): rows is MetricRowData[] =>
  rows.length > 0 && rows.every((row) => isMetricRow(row));

export const isScenarioRow = (row: CustomRow): row is ScenarioRowData =>
  'label' in row && 'scenarioAnalysis' in row && 'scenario' in row;

export const isScenarioRowData = (rows: CustomRow[]): rows is ScenarioRowData[] =>
  rows.length > 0 && rows.every((row) => isMetricRow(row));

export const isFactorRow = (row: CustomRow): row is FactorRowData =>
  'label' in row && 'value' in row && Array.isArray(row.value) && isValueMapper(row.value[0]);

export const isFactorRowData = (rows: CustomRow[]): rows is FactorRowData[] =>
  rows.length > 0 && rows.every((row) => (row.isMetadata ? isMetricRow(row) : isFactorRow(row)));

export const isColGroupDef = (colDef: ColDef | ColGroupDef): colDef is ColGroupDef => 'children' in colDef;

export const isReadyToPivotData = (rows: CustomRow[]): rows is (FactorRowData | MetricRowData)[] => {
  return isFactorRowData(rows) || isMetricRowData(rows);
};

export function isReturnsGridRow(customRow: unknown): customRow is ReturnsGridRow {
  return (customRow as ReturnsGridRow).kind === 'ReturnsGridRow';
}

/**
 * Converts a data value to a number or undefined if no number could be created.
 * Strings will be converted to number using Number.
 * Will not return NaN or null, but instead return undefined. */
export const dataValueToNumber = (dataValue: DataValue) => {
  if (isNil(dataValue) || Number.isNaN(dataValue)) {
    return undefined;
  }

  if (typeof dataValue === 'string') {
    const convertedValue = Number(dataValue);
    if (Number.isNaN(convertedValue)) return undefined;
    return convertedValue;
  }

  if (typeof dataValue === 'number') {
    return dataValue;
  }

  throw assertExhaustive(dataValue);
};
