import React, { PureComponent } from 'react';
import { TextControls, PaginationButton } from './components';
import Icon from '../icon/Icon';

export interface LeftSideControlsProps {
  selectedPage: number;
  onPageChange: (pageIndex: number) => void;
}

export class LeftSideControls extends PureComponent<LeftSideControlsProps> {
  handlePreviousClick = () => this.props.onPageChange(this.props.selectedPage - 1);

  handleFirstClick = () => this.props.onPageChange(1);

  render() {
    const { selectedPage } = this.props;
    return (
      <TextControls>
        <PaginationButton disabled={selectedPage <= 1} onClick={this.handleFirstClick}>
          <Icon type="angle-double-left" />
        </PaginationButton>

        <PaginationButton disabled={selectedPage <= 1} onClick={this.handlePreviousClick}>
          <Icon type="angle-left" />
        </PaginationButton>
      </TextControls>
    );
  }
}
