import React from 'react';
import { Body1, Headline3 } from 'venn-ui-kit';
import CookieAccordion from './CookieAccordion';
import styled from 'styled-components';
import Scrollable from '../../scrollable/Scrollable';

interface CookieTabProps {
  headline: string | React.ReactNode;
  body: string;
  firstPartyCookies?: string[];
  thirdPartyCookies?: string[];
  cookies?: string[];
  dataTestId?: string;
}

const CookieTab = ({ headline, body, firstPartyCookies, thirdPartyCookies, cookies, dataTestId }: CookieTabProps) => {
  return (
    <StyledScrollable data-testid={dataTestId}>
      <StyledHeadline3 inverted>{headline}</StyledHeadline3>
      <Body1 inverted>{body}</Body1>
      {firstPartyCookies && <CookieAccordion firstParty cookies={firstPartyCookies} />}
      {thirdPartyCookies && <CookieAccordion thirdPartyCookies cookies={thirdPartyCookies} />}
      {cookies && <CookieAccordion cookies={cookies} />}
    </StyledScrollable>
  );
};

export default CookieTab;

const StyledScrollable = styled(Scrollable)`
  overflow-x: hidden;
  padding: 0 10px 0 0;
`;

const StyledHeadline3 = styled(Headline3)`
  display: flex;
  line-height: 1.3;
  margin: 5px 0;
`;
