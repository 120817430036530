import type React from 'react';
import { debounce } from 'lodash';
import type {
  ActionEnum,
  AnalysisSearch,
  AnalysisViewSortTypeEnum,
  OperationResult,
  OrderEnum,
  PageAnalysisViewSearchResult,
} from 'venn-api';

import type { SearchState } from '../types';
import { defaultSearchState, PAGE_SIZE } from '../constants';
import type { ItemType } from 'venn-ui-kit';
import { getExcludedAnalysisTypes } from 'venn-components';
import { SavedViewsQuickFilter } from 'venn-utils';

const buildFilters = (
  hasPermission: (permission: ActionEnum) => boolean,
  filters: SavedViewsQuickFilter[] | undefined = [],
  itemTypes: ItemType[] = [],
) => {
  const onlyRecentlyViewed = filters.includes(SavedViewsQuickFilter.RECENTLY_ANALYZED);
  const includeAllInWorkspace = !filters.includes(SavedViewsQuickFilter.SAVED_BY_ME);
  const vennTemplate = filters.includes(SavedViewsQuickFilter.VENN_PROVIDED);

  const excludedAnalysisViewTypes = getExcludedAnalysisTypes(hasPermission, itemTypes);
  const activeScheduledExport = filters.includes(SavedViewsQuickFilter.SCHEDULED);
  const requiresAttention = filters.includes(SavedViewsQuickFilter.REQUIRES_ATTENTION);
  return {
    onlyRecentlyViewed,
    excludedAnalysisViewTypes,
    activeScheduledExport,
    requiresAttention,
    includeAllInWorkspace,
    vennTemplate,
  };
};

export const search = debounce(
  async (
    name: string,
    page: number,
    filters: SavedViewsQuickFilter[],
    itemTypes: ItemType[],
    sortBy: AnalysisViewSortTypeEnum | undefined,
    order: OrderEnum | undefined,
    setSearchState: React.Dispatch<React.SetStateAction<SearchState>>,
    searchApi: (query: Partial<AnalysisSearch>) => Promise<OperationResult<PageAnalysisViewSearchResult>>,
    initialLoading: React.MutableRefObject<boolean>,
    hasPermission: (permission: ActionEnum) => boolean,
  ) => {
    setSearchState((prevState) => ({
      ...defaultSearchState,
      totalCount: prevState.totalCount,
      results: prevState.results,
    }));

    try {
      const {
        content: { results, totalResults },
      } = await searchApi({
        name,
        page,
        sortBy,
        descending: order === 'desc',
        pageSize: PAGE_SIZE,
        ...buildFilters(hasPermission, filters, itemTypes),
      });
      initialLoading.current = false;

      setSearchState({
        loading: false,
        results,
        totalCount: totalResults,
      });
    } catch (e) {
      initialLoading.current = false;
      setSearchState((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }
  },
  300,
);
