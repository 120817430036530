import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

const Watermark = () => {
  const theme = useContext(ThemeContext);
  const Width = 125;
  const Height = 40;
  return <img alt="Provided by Logo" src={theme.Images.vennProvidedLogoGreyscale} width={Width} height={Height} />;
};

export default Watermark;
