import type { BenchmarkShape } from '../types';
import { ScalingSign } from '../types';

const MULTIPLIERS = {
  [ScalingSign.Minus]: -1,
  [ScalingSign.Plus]: 1,
};

export const getAnnualizedOffset = (benchmark: BenchmarkShape): number => {
  const { scalingSign, scalingValue } = benchmark;
  const multiplier = getMultiplier(scalingSign);
  return multiplier * Number(scalingValue);
};

const getMultiplier = (scalingSign: ScalingSign): number => {
  if (!(scalingSign in MULTIPLIERS)) {
    throw new Error(`Unknown scaling sign: ${scalingSign}`);
  }
  return MULTIPLIERS[scalingSign];
};
