import type { CallbackInterface } from 'recoil';
import { selectorFamily, waitForAll } from 'recoil';
import type { BenchmarkConfig, BlockId, Subject } from '../types';
import type { CustomBenchmarkTypeEnum } from 'venn-api';
import { analysisSubjectQuery, blockSubjects, originalAnalysisSubjectQuery } from './subjects';
import { blockSettings } from './blockSettings';
import { assertExhaustive, hasDisabledIndividualBenchmark } from 'venn-utils';
import type { BenchmarkInputId } from './input-management/benchmarkInput';
import {
  blockBenchmarkInput,
  benchmarkInputSubject,
  benchmarkInputIsRelative,
  benchmarkInputType,
  benchmarkInputs,
  benchmarkInputName,
} from './input-management/benchmarkInput';
import { compact } from 'lodash';
import { allBlockIdsState } from '../grid';

const DEFAULT_RELATIVE_VALUE = false;

export const blockBenchmarkRelative = selectorFamily<boolean, BlockId>({
  key: 'blockBenchmarkRelative',
  get:
    (id) =>
    ({ get }) => {
      if (
        get(blockBenchmarkType(id)) === 'INDIVIDUAL' &&
        hasDisabledIndividualBenchmark(get(blockSettings(id)).customBlockType)
      ) {
        return DEFAULT_RELATIVE_VALUE;
      }

      const inputId = get(blockBenchmarkInput(id));
      return inputId ? get(benchmarkInputIsRelative(inputId)) : DEFAULT_RELATIVE_VALUE;
    },
});

const DEFAULT_TYPE: CustomBenchmarkTypeEnum = 'NONE';

export const blockBenchmarkType = selectorFamily<CustomBenchmarkTypeEnum, BlockId>({
  key: 'blockBenchmarkType',
  get:
    (id) =>
    ({ get }) => {
      const inputId = get(blockBenchmarkInput(id));
      return inputId ? get(benchmarkInputType(inputId)) : DEFAULT_TYPE;
    },
});

const DEFAULT_SUBJECT: Subject | undefined = undefined;

export const blockBenchmarkSubjects = selectorFamily<Subject[], BlockId>({
  key: 'benchmarkSubjects',
  get:
    (id) =>
    ({ get }) => {
      const benchmarkInputId = get(blockBenchmarkInput(id));
      if (benchmarkInputId === undefined) {
        return [];
      }

      const benchmarkType = get(benchmarkInputType(benchmarkInputId));
      switch (benchmarkType) {
        case 'COMMON':
          return compact([get(benchmarkInputSubject(benchmarkInputId))]);
        case 'INDIVIDUAL':
          const subjects = get(blockSubjects(id));
          const analysisSubjects = get(waitForAll(subjects.map((s) => analysisSubjectQuery(s))));
          const individualBenchmarks = analysisSubjects.map((subject): Subject | undefined => subject.activeBenchmark);
          return compact(individualBenchmarks);
        case 'NONE':
          return [];
        default:
          throw assertExhaustive(benchmarkType);
      }
    },
});

export const blockBenchmarkSubject = selectorFamily<Subject | undefined, BlockId>({
  key: 'blockBenchmarkSubject',
  get:
    (id) =>
    ({ get }) => {
      const inputId = get(blockBenchmarkInput(id));
      return inputId ? get(benchmarkInputSubject(inputId)) : DEFAULT_SUBJECT;
    },
});

/**
 * Provides the benchmark settings for a given block
 * Note that original analysis subject query is used for subject as allocation changes
 * should not affect the common benchmark
 * */
export const blockBenchmarkConfig = selectorFamily<BenchmarkConfig, BlockId>({
  key: 'blockBenchmarkConfig',
  get:
    (id) =>
    ({ get }) => {
      const subject = get(blockBenchmarkSubject(id));
      return {
        type: get(blockBenchmarkType(id)),
        relative: get(blockBenchmarkRelative(id)),
        subject: subject && get(originalAnalysisSubjectQuery(subject)),
      };
    },
});

/** use this in a useRecoilCallback hook to delete benchmarks */
export const onBenchmarkInputGroupDelete =
  ({ set, snapshot, reset }: CallbackInterface) =>
  async (groupId: BenchmarkInputId) => {
    set(benchmarkInputs, (ids) => ids.filter((id) => id !== groupId));

    reset(benchmarkInputName(groupId));
    reset(benchmarkInputSubject(groupId));
    reset(benchmarkInputType(groupId));

    const blockIds = await snapshot.getPromise(allBlockIdsState);
    blockIds.forEach((blockId) =>
      set(blockBenchmarkInput(blockId), (current) => (current === groupId ? undefined : current)),
    );
  };
