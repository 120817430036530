import React, { useState, useCallback, useContext } from 'react';
import styled from 'styled-components';
import type { TableRowMenuOption } from 'venn-ui-kit';
import { Tooltip, TableRowOptionsMenu, getAppTitle } from 'venn-ui-kit';
import type { Fund, Portfolio } from 'venn-api';
import { getSpecificPortfolioV3 } from 'venn-api';
import type { PortfoliosContextValue } from 'venn-components';
import {
  AddToPortfolioModal,
  AnalyzeInvestmentModals,
  CreatePortfolioModal,
  EditCompositeBenchmarkModal,
  UserContext,
} from 'venn-components';
import {
  FundUtils,
  getAnalysisPathForPortfolio,
  getPortfolioLabPath,
  MANAGE_DATA_SECTION,
  navigateToManageDataPage,
  useModal,
  logExceptionIntoSentry,
  useHasFF,
} from 'venn-utils';
import type { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import type { Id, SearchResultWithUIState, SearchState } from './types';
import ConfigureBenchmarksModal from './ConfigureBenchmarksModal';
import ProxyRenderer from './ProxyRenderer';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import { convertToFund, isDeletable, MASTER_PORTFOLIO_NO_DELETION_MESSAGE } from './utils';
import Warning from './Warning';
import { isEmpty } from 'lodash';
import { SIMILAR_TIMEFRAME } from './logic/similarItems';

interface OptionalMenuProps extends RouteComponentProps {
  item: SearchResultWithUIState;
  portfoliosContext: PortfoliosContextValue;
  refetchSearch: (hideLoading?: boolean) => Promise<void>;
  findSimilar: (item: SearchResultWithUIState) => void;
  setIdWithMenuOpen: (ItemId: Id) => void;
  updateData: React.Dispatch<React.SetStateAction<SearchState>>;
}

const OptionalMenu: React.FC<React.PropsWithChildren<OptionalMenuProps>> = ({
  item,
  portfoliosContext,
  refetchSearch,
  findSimilar,
  setIdWithMenuOpen,
  updateData,
  history,
}) => {
  const hasOptimization = useHasFF('optimization');
  const { hasPermission, hasPermissionForResource } = useContext(UserContext);
  const [deleting] = useState<boolean>(false);
  const [deleteConfirmationModalOpen, openDeleteConfirmationModal, closeDeleteConfirmationModal] = useModal();
  const [showConfigureBenchmarksModal, openConfigureBenchmarkModal, closeConfigureBenchmarkModal] = useModal();
  const [showCompositeBenchmarkModal, openCompositeBenchmarkModal, closeCompositeBenchmarkModal] = useModal();
  const [displayCreatePortfolio, openCreatePortfolioModal, closeCreatePortfolioModal] = useModal();
  const [showProxyModal, openProxyModal, closeProxyModal] = useModal();
  const [showOptimizeInPortfolioModal, openOptimizeInPortfolioModal, closeOptimizePortfolioModal] = useModal();

  const [basePortfolio, setBasePortfolio] = useState<Portfolio>();
  const [lockBaseline, setLockBaseline] = useState<boolean>();
  const [showAddToPortfolioModal, openAddToPortfolioModal, closeAddToPortfolioModal] = useModal();

  const toggleOpen = useCallback(
    (open: boolean) => {
      if (open) {
        setIdWithMenuOpen({
          fundId: undefined,
          portfolioId: undefined,
        });
      } else {
        setIdWithMenuOpen({
          fundId: item.fundId,
          portfolioId: item.portfolioId,
        });
      }
    },
    [item.fundId, item.portfolioId, setIdWithMenuOpen],
  );

  const getPortfolio = useCallback(async () => {
    if (item.portfolioId) {
      try {
        const { content } = await getSpecificPortfolioV3(item.portfolioId);
        return content;
      } catch (e) {
        logExceptionIntoSentry(e);
      }
    }
    return undefined;
  }, [item.portfolioId]);

  const onDelete = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (!isDeletable(item) || deleting) {
        e.stopPropagation();
        return;
      }
      openDeleteConfirmationModal();
    },
    [deleting, item, openDeleteConfirmationModal],
  );

  const onSaveEditCompositeBenchmarkModal = useCallback(async () => {
    closeCompositeBenchmarkModal();
    await refetchSearch();
  }, [closeCompositeBenchmarkModal, refetchSearch]);

  const openCreatePortfolio = useCallback(async () => {
    const portfolio = await getPortfolio();
    setBasePortfolio(portfolio);
    setLockBaseline(true);
    openCreatePortfolioModal();
  }, [getPortfolio, openCreatePortfolioModal]);

  const submitCreatePortfolio = useCallback(
    (portfolio: Portfolio, createdFromMaster: boolean) => {
      history.push(getAnalysisPathForPortfolio(portfolio.id, undefined, createdFromMaster));
    },
    [history],
  );

  const { demoPortfolio, masterPortfolio, refresh } = portfoliosContext;

  const options: TableRowMenuOption[] = [];

  if (!isEmpty(item.metricsOnTimePeriod?.[SIMILAR_TIMEFRAME])) {
    options.push({
      label: 'Search Similar',
      onClick: () => findSimilar(item),
    });
  }

  if (item.investmentSource === 'CUSTOM' && hasPermission('CONFIGURE_BENCHMARKS')) {
    options.push({
      label: 'Edit Composite Benchmark',
      onClick: openCompositeBenchmarkModal,
    });
  } else if (hasPermission('CONFIGURE_BENCHMARKS')) {
    options.push({
      label: 'Configure Benchmarks',
      onClick: openConfigureBenchmarkModal,
    });
  }

  if (item.fundId) {
    if (masterPortfolio) {
      options.unshift({
        label: 'Add to Portfolio',
        onClick: openAddToPortfolioModal,
      });
    }

    if (item.proxyable && hasPermission('MANAGE_PROXY')) {
      options.push({
        label: item.proxyType ? 'Edit Proxy' : 'Add a Proxy',
        onClick: openProxyModal,
        disabled: item.investmentSource === 'CUSTOM',
      });
    }

    if (hasOptimization && hasPermission('OPTIMIZATION')) {
      options.push({
        label: 'Optimize in a portfolio',
        onClick: openOptimizeInPortfolioModal,
      });
    }

    options.push({
      label: 'Manage Data',
      onClick: () =>
        navigateToManageDataPage(
          history,
          { fundId: item.fundId },
          'Library',
          false,
          MANAGE_DATA_SECTION.INVESTMENT_INFORMATION,
        ),
    });
  }

  if (item.portfolioId) {
    options.push({
      label: 'Create Portfolio from this Portfolio',
      onClick: openCreatePortfolio,
    });

    if (hasOptimization && hasPermission('OPTIMIZATION')) {
      options.push({
        label: 'Optimize with Portfolio Lab',
        onClick: () => history.push(getPortfolioLabPath(item.portfolioId)),
      });
    }

    options.push({
      label: 'Manage Data',
      onClick: () =>
        navigateToManageDataPage(
          history,
          { portfolioId: item.portfolioId },
          'Library',
          false,
          MANAGE_DATA_SECTION.ANALYSIS_RANGE,
        ),
    });
  }

  options.push({
    label: 'Delete',
    disabled:
      !isDeletable(item) ||
      deleting ||
      (item.portfolioId ? !hasPermissionForResource('CREATE_PORTFOLIO', item) : !hasPermission('UPLOAD_RETURNS')),
    loading: deleting,
    onClick: onDelete,
    renderWrapper: (content) => (
      <Tip
        block
        content={
          item.master
            ? MASTER_PORTFOLIO_NO_DELETION_MESSAGE
            : item.investmentSource === 'VENN'
              ? `${getAppTitle()} investments can not be deleted`
              : FundUtils.isUserIntegration(item.investmentSource) &&
                !isDeletable(item) &&
                'Integrated investments can not be deleted.'
        }
      >
        {content}
      </Tip>
    ),
  });

  return (
    <span>
      <Warning item={item} openProxyModal={openProxyModal} />
      <TableRowOptionsMenu
        options={options}
        onToggle={toggleOpen}
        menuClassName="investment-menu-dropdown"
        triggerClassName="investment-menu-trigger"
      />
      {deleteConfirmationModalOpen ? (
        <DeleteConfirmationModal
          items={[item]}
          onCancelDelete={closeDeleteConfirmationModal}
          onDeleteComplete={closeDeleteConfirmationModal}
          portfoliosContext={portfoliosContext}
          refetchSearch={refetchSearch}
        />
      ) : null}
      {showConfigureBenchmarksModal && hasPermission('CONFIGURE_BENCHMARKS') && (
        <ConfigureBenchmarksModal
          name={item.name}
          itemId={{
            fundId: item.fundId,
            portfolioId: item.portfolioId,
          }}
          onSubmit={closeConfigureBenchmarkModal}
          onClose={closeConfigureBenchmarkModal}
          benchmarkContext={item.fundId ? 'investment' : 'portfolio'}
        />
      )}
      {showCompositeBenchmarkModal && hasPermission('CONFIGURE_BENCHMARKS') && (
        <EditCompositeBenchmarkModal
          fundId={item.fundId}
          onSave={onSaveEditCompositeBenchmarkModal}
          onCancel={closeCompositeBenchmarkModal}
        />
      )}
      {displayCreatePortfolio && (
        <CreatePortfolioModal
          baseline={basePortfolio || masterPortfolio}
          onClose={closeCreatePortfolioModal}
          onSubmit={submitCreatePortfolio}
          disableBaselineSelection={lockBaseline}
        />
      )}
      {showProxyModal && (
        <ProxyRenderer
          item={item}
          setIdWithMenuOpen={setIdWithMenuOpen}
          updateData={updateData}
          refetchSearch={refetchSearch}
          showModal
          onModalClose={closeProxyModal}
          readOnly={!hasPermission('MANAGE_PROXY')}
        />
      )}

      {showAddToPortfolioModal && (
        <AddToPortfolioModal
          onCancel={closeAddToPortfolioModal}
          initialSelectedPortfolio={masterPortfolio || demoPortfolio}
          refreshPortfolios={refresh}
          investments={[convertToFund(item)]}
        />
      )}
      {item.fundId && hasOptimization && hasPermission('OPTIMIZATION') && (
        <AnalyzeInvestmentModals
          fund={
            {
              id: item.fundId,
              name: item.name,
            } as Fund
          }
          optimizerEnabled
          createOptimizeModal={showOptimizeInPortfolioModal}
          closeOptmizeModal={closeOptimizePortfolioModal}
        />
      )}
    </span>
  );
};

const Tip = styled(Tooltip)`
  height: 100%;
`;

export default withRouter(OptionalMenu);
