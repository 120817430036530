import React, { useContext } from 'react';
import type { AnalysisBlockProps, TabularDataRow } from '../../types';
import type { BasicTableColumn } from 'venn-components';
import { ColumnAlign } from 'venn-components';
import ComparisonTable from '../ComparisonTable';
import ActionBar from './ActionBar';
import compact from 'lodash/compact';
import { ThemeContext } from 'styled-components';

const BasicComparisonTable: React.FC<React.PropsWithChildren<AnalysisBlockProps>> = ({
  label,
  extractor,
  subjects,
  analysis,
  setHovered,
  relative,
  factorLens,
  isLoading,
  isPrinting,
  fetchingInBackground,
  cellRenderer,
}) => {
  const data = extractor(subjects, analysis, relative, factorLens);
  const { Colors } = useContext(ThemeContext);

  const getColumns = (): BasicTableColumn<TabularDataRow>[] =>
    compact([
      {
        label,
        accessor: 'label',
      },
      ...subjects.map((subject, colNum) =>
        subject.isBenchmark && relative
          ? undefined
          : {
              label: isPrinting ? subject.analysisSubject?.name : undefined,
              align: ColumnAlign.RIGHT,
              cellStyle: {
                backgroundColor: subject.isHovered && !isPrinting ? Colors.WhiteGrey : undefined,
              },
              cellRenderer: cellRenderer(isLoading, colNum),
              cellProps: () => {
                return {
                  onMouseEnter: () => setHovered(colNum, true),
                  onMouseLeave: () => setHovered(colNum, false),
                };
              },
            },
      ),
      isPrinting
        ? undefined
        : {
            // Empty cell to fill remaining space
          },
    ]);

  return (
    <>
      <ComparisonTable
        columns={getColumns()}
        data={data}
        rowStyle={(rowData) => (rowData.style ? rowData.style : [])}
        isPrinting={isPrinting}
        fetchingInBackground={fetchingInBackground}
      />
      <ActionBar subjects={subjects} setHovered={setHovered} relative={relative} />
    </>
  );
};

export default BasicComparisonTable;
