import React, { useContext } from 'react';
import type { AnalysisSubject } from 'venn-utils';
import { MANAGE_DATA_SECTION } from 'venn-utils';
import type { UseRangeAnalysisReturn } from 'venn-components';
import { BulkManagementTable, UserContext } from 'venn-components';
import { TablePlaceholder } from 'venn-ui-kit';
import PortfolioPolicy from './portfolio/PortfolioPolicy';
import PortfolioInformation from './portfolio/PortfolioInformation';
import type { Portfolio } from 'venn-api';

interface PortfolioManageDataProps {
  subject?: AnalysisSubject;
  useRangeAnalysisReturn: UseRangeAnalysisReturn;
  onFundUpdated: (fundId: string) => void;
  selectedSection?: MANAGE_DATA_SECTION;
  updatePortfolio: (portfolio: Portfolio) => Promise<void>;
}

const PortfolioManageData = ({
  subject,
  useRangeAnalysisReturn,
  onFundUpdated,
  selectedSection,
  updatePortfolio,
}: PortfolioManageDataProps) => {
  const { hasPermission } = useContext(UserContext);

  if (subject?.type === 'investment') {
    return null;
  }

  return (
    <>
      {selectedSection === MANAGE_DATA_SECTION.ANALYSIS_RANGE && subject ? (
        <BulkManagementTable
          subject={subject}
          useRangeAnalysisReturn={useRangeAnalysisReturn}
          onFundUpdated={onFundUpdated}
          canEditForecasts={hasPermission('EDIT_FORECASTS')}
          canEditProxies={hasPermission('MANAGE_PROXY')}
        />
      ) : null}
      {selectedSection === MANAGE_DATA_SECTION.ANALYSIS_RANGE && !subject && <TablePlaceholder />}
      {selectedSection === MANAGE_DATA_SECTION.PORTFOLIO_POLICY && <PortfolioPolicy portfolio={subject?.portfolio} />}
      {selectedSection === MANAGE_DATA_SECTION.PORTFOLIO_INFORMATION && subject?.portfolio ? (
        <PortfolioInformation portfolio={subject.portfolio} updatePortfolio={updatePortfolio} />
      ) : null}
    </>
  );
};

export default PortfolioManageData;
