import { isNil, pick, sortBy } from 'lodash';
import type { FrequencyEnum, Analysis, NotablePeriod } from 'venn-api';
import {
  getAllPredefinedPeriods,
  Dates,
  Numbers,
  getPeriodLengthWithFrequency,
  type SelectableCustomNotablePeriod,
} from 'venn-utils';
import {
  type NotablePeriodMetadata,
  type NotablePeriodMetadataUnionType,
  NotablePeriodMetadataPropertySet,
  getPredefinedPeriodAnalyses,
  getCustomPeriodAnalyses,
  ratioToPercentageChange,
  getRecoveryMonths,
} from './notablePeriodsUtils';
import type { MultiGlobalColumnsRowData, ColumnData } from '../../../types';
import { blockDateRange, viewCustomNotablePeriods, viewSelectedNotablePeriods } from 'venn-state';
import { useRecoilValue } from 'recoil';
import { useBlockId } from '../../../contexts/BlockIdContext';

export const useNotablePeriodsChartData = (analysesGroup: (Analysis | undefined)[][]): ColumnData[] => {
  const blockId = useBlockId();
  const defaultSelectedPeriodIds = useRecoilValue(viewSelectedNotablePeriods(blockId));
  const customNotablePeriods = useRecoilValue(viewCustomNotablePeriods(blockId));

  if (isNil(analysesGroup) || !analysesGroup?.length) {
    return [];
  }

  const { allMetadata, analyses, selectedNotablePeriodIds } = getNotablePeriodsData(
    analysesGroup,
    defaultSelectedPeriodIds,
    customNotablePeriods,
  );

  const xAxisCategories = sortBy(allMetadata, ({ id }) => selectedNotablePeriodIds.indexOf(id)).map(
    ({ name, start, extremumDate }) => `${name} (${Dates.toDDMMMYYYY(start)} - ${Dates.toDDMMMYYYY(extremumDate)})`,
  );

  const resultsGroupedBySubject: ColumnData[] = [];
  for (let j = 0; j < analyses?.[0]?.length; j++) {
    const seriesData: { value: number | null; periodId: number }[] = [];
    for (let i = 0; i < analyses.length; i++) {
      const value = ratioToPercentageChange(analyses[i][j]?.extremumValue);
      seriesData.push({
        value: !isNil(value) ? Number.parseFloat(Numbers.safeFormatNumber(value, 2)) : null,
        periodId: allMetadata[i].id,
      });
    }
    resultsGroupedBySubject.push({
      xAxisCategories,
      yAxisTitle: 'Return',
      percent: true,
      seriesData: sortBy(seriesData, ({ periodId }) => selectedNotablePeriodIds.indexOf(periodId)).map(
        ({ value }) => value,
      ),
      exportable: true,
    });
  }

  return resultsGroupedBySubject;
};

export const useNotablePeriodsGridData = (analysesGroup: (Analysis | undefined)[][]): MultiGlobalColumnsRowData[] => {
  const blockId = useBlockId();
  const dateRange = useRecoilValue(blockDateRange(blockId));
  const defaultSelectedPeriodIds = useRecoilValue(viewSelectedNotablePeriods(blockId));
  const customNotablePeriods = useRecoilValue(viewCustomNotablePeriods(blockId));

  const analysisFrequency: FrequencyEnum = dateRange?.frequency ?? 'UNKNOWN';

  if (isNil(analysesGroup) || !analysesGroup?.length) {
    return [];
  }

  const { allMetadata, analyses, selectedNotablePeriodIds } = getNotablePeriodsData(
    analysesGroup,
    defaultSelectedPeriodIds,
    customNotablePeriods,
  );

  return sortBy(
    allMetadata.map((notablePeriodMetadata: NotablePeriodMetadata, periodRowIdx: number) => ({
      key: `${notablePeriodMetadata.id}`, // keys need to be strings
      label: notablePeriodMetadata.name,
      globalValue: {
        start: Dates.toDDMMMYYYY(notablePeriodMetadata.start),
        end: Dates.toDDMMMYYYY(notablePeriodMetadata.extremumDate),
        period: isNil(notablePeriodMetadata.extremumDate)
          ? '--'
          : getPeriodLengthWithFrequency(
              analysisFrequency,
              notablePeriodMetadata.start,
              notablePeriodMetadata.extremumDate,
            ),
      },
      value: analyses[periodRowIdx].map((currentPeriod: NotablePeriod | undefined) => {
        if (isNil(currentPeriod)) {
          return {
            recovery: undefined,
            amount: undefined,
          };
        }
        const amount = ratioToPercentageChange(currentPeriod.extremumValue);
        const recovery = currentPeriod.end;
        const end = currentPeriod.extremumDate;
        return {
          recovery:
            !isNil(recovery) && !isNil(amount) && amount < 0
              ? getRecoveryMonths(end, recovery, analysisFrequency)
              : undefined,
          amount: isNil(amount) ? undefined : amount / 100,
        };
      }),
    })),
    ({ key }) => selectedNotablePeriodIds.indexOf(Number.parseInt(key, 10)),
  );
};

function getNotablePeriodsData(
  analysesGroup: (Analysis | undefined)[][],
  defaultSelectedPeriodIds: number[] | undefined,
  customNotablePeriods: SelectableCustomNotablePeriod[] | undefined,
): {
  allMetadata: NotablePeriodMetadata[];
  analyses: (NotablePeriod | undefined)[][];
  selectedNotablePeriodIds: number[];
} {
  const allPeriods = getAllPredefinedPeriods(analysesGroup);
  const selectedNotablePeriodIds = isNil(defaultSelectedPeriodIds)
    ? allPeriods.map((period) => period.id)
    : defaultSelectedPeriodIds;

  const periodById: { [key: number]: NotablePeriod } = {};
  allPeriods.forEach((period) => {
    periodById[period.id] = period;
  });

  const predefinedPeriodMetadata: NotablePeriodMetadata[] = selectedNotablePeriodIds
    .filter((notablePeriodId) => !isNil(periodById[notablePeriodId]))
    .map((notablePeriodId) =>
      pick<NotablePeriod, NotablePeriodMetadataUnionType>(
        periodById[notablePeriodId],
        NotablePeriodMetadataPropertySet,
      ),
    );

  const customPeriodMetadata: NotablePeriodMetadata[] =
    customNotablePeriods
      ?.filter((period) => selectedNotablePeriodIds.find((id) => id === period.id))
      ?.map(({ name, start, end, id }) => ({
        id,
        name,
        start,
        extremumDate: end,
      })) ?? [];

  const analyses: (NotablePeriod | undefined)[][] = [
    ...getPredefinedPeriodAnalyses(predefinedPeriodMetadata, analysesGroup),
    ...getCustomPeriodAnalyses(customNotablePeriods, customPeriodMetadata, analysesGroup),
  ];
  const allMetadata = [...predefinedPeriodMetadata, ...customPeriodMetadata];
  return { allMetadata, analyses, selectedNotablePeriodIds };
}
