if (!window.Symbol) {
  require('core-js/modules/es6.symbol');
  require('core-js/modules/es6.array.iterator');
}

if (!window.Promise) {
  require('core-js/modules/es6.promise');
}

if (!window.fetch) {
  require('whatwg-fetch');
}

if (!window.Proxy) {
  require('proxy-polyfill/proxy.min.js');
}

if (!Element.prototype.scrollTo) {
  const smoothscroll = require('smoothscroll-polyfill');
  smoothscroll.polyfill();
}
interface VendorElement extends Element {
  msMatchesSelector?: (selector: string) => boolean;
}

// Polyfills for IE
(() => {
  // https://developer.mozilla.org/en-US/docs/Web/API/Element/closest#Polyfill
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      (Element.prototype as VendorElement).msMatchesSelector || Element.prototype.webkitMatchesSelector;
  }

  if (!Element.prototype.closest) {
    Element.prototype.closest = function closestPolyfill(s: string) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      let el: Node | null = this;

      do {
        if (Element.prototype.matches.call(el, s)) return el;
        el = el.parentElement || el.parentNode;
      } while (el !== null && el.nodeType === 1);
      return null;
    };
  }

  if (!window.AbortController) {
    require('abortcontroller-polyfill/dist/abortcontroller-polyfill-only');
  }

  // https://developer.mozilla.org/en-US/docs/Web/API/NodeList/forEach#Polyfill
  if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = function forEachPolyfill(callback, thisArg) {
      thisArg = thisArg || window;
      for (let i = 0; i < this.length; i++) {
        callback.call(thisArg, this[i], i, this);
      }
    };
  }

  // https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent#Polyfill
  if (typeof window.CustomEvent !== 'function') {
    const CustomEvent = (
      event: string,
      params = { bubbles: false, cancelable: false, detail: undefined as unknown },
    ) => {
      const evt = document.createEvent('CustomEvent');
      evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
      return evt;
    };

    CustomEvent.prototype = window.Event.prototype;

    (window.CustomEvent as typeof CustomEvent) = CustomEvent;
  }

  // https://github.com/popperjs/popper.js/issues/515#issuecomment-355064871
  if (!Element.prototype.contains) {
    Element.prototype.contains = function contains(node) {
      if (!(0 in arguments)) {
        throw new TypeError('1 argument is required');
      }
      do {
        if (this === node) {
          return true;
        }
        // eslint-disable-next-line no-cond-assign
      } while ((node = node && node.parentNode));

      return false;
    };
  }
})();

// from:https://github.com/jserz/js_piece/blob/master/DOM/ChildNode/remove()/remove().md
((arr) => {
  arr.forEach((item) => {
    if (Object.prototype.hasOwnProperty.call(item, 'remove')) {
      return;
    }
    Object.defineProperty(item, 'remove', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function remove() {
        if (this.parentNode === null) {
          return;
        }
        this.parentNode.removeChild(this);
      },
    });
  });
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);
