import type { AnalysisTypeEnum, InfoGraphicTypeEnum } from 'venn-api';
import type { FeatureName } from '../feature-availability';
import { FS } from '../feature-availability';
import { keyBy } from 'lodash';
import type { DistributiveOmit } from '../type';
import { PERFORMANCE_BLOCK_METRICS } from './performanceBlockMetrics';
import { PEER_GROUPS_METRICS } from './peerGroupsBlockMetrics';

export type CustomBlockTypeEnum =
  | 'PERFORMANCE_SUMMARY'
  | 'PORTFOLIO_CONTRIBUTION'
  | 'FACTOR_CONTRIBUTION'
  | 'FACTOR_CONTRIBUTION_TREND'
  | 'PERFORMANCE_SUMMARY_HISTORICAL'
  | 'PERFORMANCE_SUMMARY_HISTORICAL_PORTFOLIO_CONTRIBUTION'
  | 'FACTOR_EXPOSURES'
  | 'FACTOR_CONTRIBUTION_TO_RISK'
  | 'FACTOR_CONTRIBUTION_TO_RETURN'
  | 'TIMESERIES'
  | 'PORTFOLIO_FACTOR_CONTRIBUTION'
  | 'CORRELATION'
  | 'DISTRIBUTION'
  | 'ROLLING_CORRELATION'
  | 'NOTABLE_PERIODS'
  | 'GROWTH_SIMULATION'
  | 'PRIVATE_CASH_FLOW'
  | 'MARKDOWN'
  | 'PAGE_BREAK'
  | 'IMAGE'
  | 'PORTFOLIO_BREAKDOWN'
  | 'RETURNS_GRID'
  | 'SCENARIO'
  | 'ASSET_EXPOSURE'
  | 'GEOGRAPHY_EXPOSURE'
  | 'SECTOR_EXPOSURE'
  | 'PRIVATE_CAPITAL_SUMMARY'
  | 'PRIVATE_PERFORMANCE_TIME_SERIES'
  | 'PRIVATE_PERFORMANCE_SUMMARY'
  | 'PORTFOLIO_COMPARISON'
  | 'PEER_GROUPS'
  | 'PUBLIC_PRIVATE_ASSET_GROWTH_PERCENTILES'
  | 'PUBLIC_PRIVATE_ASSET_GROWTH_BREAKDOWN';

export type TabularDataTypeEnum = 'TEXT' | 'NUMERIC' | 'PERCENTAGE' | 'CURRENCY';

export type BlockAnalysisType = AnalysisTypeEnum | 'CUSTOM_ENTRY' | 'CUSTOM_METADATA';
export interface CustomizableMetric {
  /** Analysis result field in `Analysis` response class from BE. */
  analysisResultKey?: string;
  analysisType?: BlockAnalysisType;
  /** Group category for this metric */
  category?: string;
  dataType: TabularDataTypeEnum;
  key: string;
  label: string;
  /** The path the find the metric, eg. periodReturn */
  metricPath?: string;
  relativeLabel?: string;
  /** The relative path if relative to benchmark. eg. periodAccessReturn */
  relativeMetricPath?: string;
  /**
   * Supported graphic types for this metric (e.g. only available in grid infographic)
   * Should be a subset of block's supported infographics. Other values are ignored
   */
  supportedGraphicTypes?: InfoGraphicTypeEnum[];
  /**
   * Whether this metric needs benchmark to be computed, false by default
   */
  needsBenchmark?: boolean;
  tooltipContent?: string;
  /** If the metric has rollout feature constraints or access constraints */
  accessFeatures?: FeatureName[];
}

interface AbstractBlockSetting {
  /** If the block has rollout feature constraints or access constraints */
  accessFeatures: FeatureName[];
  contributionToPercentage: boolean;
  customBlockType: CustomBlockTypeEnum;
  /** Subset of metrics that will be preselected * */
  defaultMetrics: string[];
  /** If the block has factors dimension */
  hasFactors: boolean;
  id: string;
  metrics: CustomizableMetric[];
  relativeToBenchmark: boolean;
  supportedGraphicTypes: InfoGraphicTypeEnum[];
  supportsCustomMetrics: boolean;
  supportsPublicInvestments: boolean;
  supportsPrivateInvestments: boolean;
  supportsPortfoliosOnly: boolean;
  title: string;
  /** The minimum number of subjects required for a block to run analysis. */
  requiredSubjects: number;
}

export const CORRELATION_BLOCK_SETTINGS = {
  id: 'b95e54b1-edbe-40f7-af67-f458f0ead18d',
  customBlockType: 'CORRELATION',
  title: 'Correlations',
  contributionToPercentage: false,
  relativeToBenchmark: true,
  metrics: [
    {
      key: 'pairwiseCorrelation',
      label: 'Compare Correlation',
      relativeLabel: 'Benchmark Relative Compare Correlation',
      dataType: 'NUMERIC',
      analysisType: 'PAIRWISE_CORRELATION',

      analysisResultKey: 'pairwiseCorrelation',
      metricPath: undefined,
    },
    {
      key: 'portfolioCorrelation',
      label: 'Portfolio Correlation',
      relativeLabel: 'Benchmark Relative Portfolio Correlation',
      dataType: 'NUMERIC',
      analysisType: 'CORRELATION',

      analysisResultKey: 'correlationMatrix',
      metricPath: undefined,
    },
    {
      key: 'residualPortfolioCorrelation',
      label: 'Residual Portfolio Correlation',
      relativeLabel: 'Benchmark Relative Residual Portfolio Correlation',
      dataType: 'NUMERIC',
      analysisType: 'CORRELATION',

      analysisResultKey: 'residualCorrelationMatrix',
      metricPath: undefined,
    },
    {
      key: 'pairwiseDownsideCorrelation',
      label: 'Compare Downside Correlation',
      relativeLabel: 'Benchmark Relative Compare Downside Correlation',
      dataType: 'NUMERIC',
      analysisType: 'PAIRWISE_DOWNSIDE_CORRELATION',

      analysisResultKey: 'pairwiseDownsideCorrelation',
      metricPath: undefined,
    },
    {
      key: 'portfolioDownsideCorrelation',
      label: 'Portfolio Downside Correlation',
      relativeLabel: 'Benchmark Relative Portfolio Downside Correlation',
      dataType: 'NUMERIC',
      analysisType: 'DOWNSIDE_CORRELATION',

      analysisResultKey: 'downsideCorrelationMatrix',
      metricPath: undefined,
    },
  ],
  defaultMetrics: ['pairwiseCorrelation'],
  accessFeatures: [],
  supportsCustomMetrics: false,
  supportsPublicInvestments: true,
  supportsPrivateInvestments: false,
  supportsPortfoliosOnly: false,
  hasFactors: false,
  supportedGraphicTypes: ['CORRELATION'],
  requiredSubjects: 1,
} as const satisfies AbstractBlockSetting;

export const FACTOR_CONTRIBUTION_BLOCK_SETTINGS = {
  id: 'c72928f9-0859-4a30-9405-b903bf091a63',
  customBlockType: 'FACTOR_CONTRIBUTION',
  title: 'Factor Exposure and Contribution',
  contributionToPercentage: true,
  relativeToBenchmark: true,
  metrics: [
    {
      key: 'contributionToExposure',
      label: 'Exposure (β)',
      relativeLabel: 'Relative Exposure (β)',
      dataType: 'NUMERIC',
      analysisType: 'FACTOR_EXPOSURES',

      analysisResultKey: 'factorExposures',
      metricPath: undefined,
    },
    {
      key: 'contributionToRisk',
      label: 'Cont. to Risk',
      relativeLabel: 'Relative Cont. to Risk',
      dataType: 'PERCENTAGE',
      analysisType: 'FACTOR_CONTRIBUTION_TO_RISK',

      analysisResultKey: 'factorContributionToRisk',
      metricPath: undefined,
    },
    {
      key: 'contributionToReturn',
      label: 'Cont. to Return',
      relativeLabel: 'Relative Cont. to Return',
      dataType: 'PERCENTAGE',
      analysisType: 'FACTOR_CONTRIBUTION_TO_RETURN',

      analysisResultKey: 'factorContributionToReturn',
      metricPath: undefined,
    },
  ],
  defaultMetrics: [],
  accessFeatures: [],
  supportsCustomMetrics: false,
  supportsPublicInvestments: true,
  supportsPrivateInvestments: false,
  supportsPortfoliosOnly: false,
  hasFactors: true,
  supportedGraphicTypes: ['GRID', 'PIVOTED_GRID', 'DIVERGENT_BAR'],
  requiredSubjects: 1,
} as const satisfies AbstractBlockSetting;

export const ASSET_EXPOSURE_BLOCK_SETTINGS = {
  id: '23ca1088-2953-4ced-b79f-6f5fc259c486',
  customBlockType: 'ASSET_EXPOSURE',
  title: 'Asset Class',
  contributionToPercentage: false,
  relativeToBenchmark: false,
  metrics: [],
  defaultMetrics: [],
  accessFeatures: [],
  supportsCustomMetrics: false,
  supportsPublicInvestments: true,
  supportsPrivateInvestments: false,
  supportsPortfoliosOnly: false,
  hasFactors: false,
  supportedGraphicTypes: ['GRID', 'PIE'],
  requiredSubjects: 1,
} as const satisfies AbstractBlockSetting;

export const PORTFOLIO_COMPARISON_BLOCK = {
  id: 'c29c71f0-bf59-11ec-8620-3b6d48f605d2',
  customBlockType: 'PORTFOLIO_COMPARISON',
  title: 'Portfolio Comparison',
  contributionToPercentage: false,
  relativeToBenchmark: false,
  metrics: [
    {
      key: 'weight',
      label: '% Weight',
      relativeLabel: '% Weight',
      dataType: 'PERCENTAGE',
    },
    {
      key: 'allocation',
      label: 'Value',
      relativeLabel: 'Value',
      dataType: 'CURRENCY',
    },
    {
      key: 'percentageYield',
      label: 'Estimated Annual Yield',
      relativeLabel: 'Estimated Annual Yield',
      dataType: 'PERCENTAGE',
      analysisType: 'CUSTOM_METADATA',
      accessFeatures: ['yield_income_ff'],
      tooltipContent: 'Estimated Annual Yield values are maintained on each investment’s Manage Data page.',
    },
    {
      key: 'income',
      label: 'Estimated Annual Income',
      relativeLabel: 'Estimated Annual Income',
      dataType: 'CURRENCY',
      analysisType: 'CUSTOM_METADATA',
      accessFeatures: ['yield_income_ff'],
      tooltipContent:
        'Estimated Annual Yield values used to calculate income are maintained on each investment’s Manage Data page.',
    },
  ],
  defaultMetrics: ['weight', 'allocation'],
  accessFeatures: [],
  supportsCustomMetrics: true,
  supportsPublicInvestments: true,
  supportsPrivateInvestments: false,
  supportsPortfoliosOnly: true,
  hasFactors: false,
  supportedGraphicTypes: ['GRID'],
  requiredSubjects: 2,
} as const satisfies AbstractBlockSetting;

export const PORTFOLIO_BREAKDOWN_BLOCK_SETTINGS = {
  id: '9e9aed6f-65f7-49cc-a923-f0cb8d7af34a',
  customBlockType: 'PORTFOLIO_BREAKDOWN',
  title: 'Portfolio Composition',
  contributionToPercentage: false,
  relativeToBenchmark: true,
  metrics: [
    {
      key: 'allocation',
      label: 'Strategy Allocation',
      relativeLabel: 'Strategy Allocation',
      dataType: 'NUMERIC',
      analysisType: undefined,

      analysisResultKey: undefined,
      metricPath: undefined,
    },
  ],
  defaultMetrics: [],
  accessFeatures: [],
  supportsCustomMetrics: false,
  supportsPublicInvestments: true,
  supportsPrivateInvestments: false,
  supportsPortfoliosOnly: false,
  hasFactors: false,
  supportedGraphicTypes: ['PIE'],
  requiredSubjects: 1,
} as const satisfies AbstractBlockSetting;

export const GROWTH_SIMULATION_BLOCK_SETTINGS = {
  id: 'c0e20098-e835-4dbb-b07f-b1f3a4f5a95d',
  customBlockType: 'GROWTH_SIMULATION',
  title: 'Asset Growth Simulation',
  contributionToPercentage: false,
  relativeToBenchmark: true,
  metrics: [
    {
      key: 'growthSimulation',
      label: 'Growth Simulation',
      relativeLabel: undefined,
      dataType: 'NUMERIC',
      analysisType: 'GROWTH_SIMULATION',

      analysisResultKey: 'growthSimulation',
      metricPath: undefined,
    },
  ],
  defaultMetrics: ['growthSimulation'],
  accessFeatures: [],
  supportsCustomMetrics: false,
  supportsPublicInvestments: true,
  supportsPrivateInvestments: false,
  supportsPortfoliosOnly: false,
  hasFactors: false,
  supportedGraphicTypes: ['GROWTH_SIMULATION'],
  requiredSubjects: 1,
} as const satisfies AbstractBlockSetting;

export const PUBLIC_PRIVATE_ASSET_GROWTH_PERCENTILES_BLOCK_SETTINGS = {
  id: 'be70551a-869f-48f0-af87-65c2f985c353',
  customBlockType: 'PUBLIC_PRIVATE_ASSET_GROWTH_PERCENTILES',
  title: 'Asset Growth - Percentiles',
  contributionToPercentage: false,
  relativeToBenchmark: false,
  metrics: [
    {
      key: 'growthSimulation',
      label: 'Growth Simulation',
      relativeLabel: undefined,
      dataType: 'NUMERIC',
      analysisType: 'GROWTH_SIMULATION_PUBLIC_PRIVATE',
      analysisResultKey: 'growthSimulationPublicPrivateResponse',
      metricPath: undefined,
    },
  ],
  defaultMetrics: ['growthSimulation'],
  accessFeatures: ['public_private_asset_growth_ff'],
  supportsCustomMetrics: false,
  supportsPublicInvestments: true,
  supportsPrivateInvestments: true,
  supportsPortfoliosOnly: false,
  hasFactors: false,
  supportedGraphicTypes: ['GROWTH_SIMULATION', 'GRID'],
  requiredSubjects: 1,
} as const satisfies AbstractBlockSetting;

export const PUBLIC_PRIVATE_ASSET_GROWTH_BREAKDOWN_BLOCK_SETTINGS = {
  id: 'e87c9ffc-ba7c-42e4-bebe-b9c3655db978',
  customBlockType: 'PUBLIC_PRIVATE_ASSET_GROWTH_BREAKDOWN',
  title: 'Asset Growth - NAV Breakdown',
  contributionToPercentage: false,
  relativeToBenchmark: false,
  metrics: [
    {
      key: 'growthSimulation',
      label: 'Growth Simulation',
      relativeLabel: undefined,
      dataType: 'NUMERIC',
      analysisType: 'GROWTH_SIMULATION_PUBLIC_PRIVATE',
      analysisResultKey: 'growthSimulationPublicPrivateResponse',
      metricPath: undefined,
    },
  ],
  defaultMetrics: ['growthSimulation'],
  accessFeatures: ['public_private_asset_growth_ff'],
  supportsCustomMetrics: false,
  supportsPublicInvestments: true,
  supportsPrivateInvestments: true,
  supportsPortfoliosOnly: false,
  hasFactors: false,
  supportedGraphicTypes: ['DISTRIBUTE_BAR', 'GRID'],
  requiredSubjects: 1,
} as const satisfies AbstractBlockSetting;

export const PEER_GROUP_ANALYTICS_BLOCK_SETTINGS = {
  id: 'c0e20098-e835-4dbb-b07f-b1f31237a95d',
  customBlockType: 'PEER_GROUPS',
  title: 'Peer Group Analysis',
  contributionToPercentage: false,
  relativeToBenchmark: false,
  metrics: PEER_GROUPS_METRICS,
  defaultMetrics: [],
  accessFeatures: ['peer_group_analytics_ff'],
  supportsCustomMetrics: false,
  supportsPublicInvestments: true,
  supportsPrivateInvestments: false,
  supportsPortfoliosOnly: false,
  hasFactors: false,
  supportedGraphicTypes: ['BOX_CHART', 'PEER_GROUP'],
  requiredSubjects: 1,
} as const satisfies AbstractBlockSetting;

export const GEOGRAPHY_EXPOSURE_BLOCK_SETTINGS = {
  id: '8e67112f-1151-4932-979f-39d774d6ef14',
  customBlockType: 'GEOGRAPHY_EXPOSURE',
  title: 'Geography',
  contributionToPercentage: false,
  relativeToBenchmark: false,
  metrics: [],
  defaultMetrics: [],
  accessFeatures: [],
  supportsCustomMetrics: false,
  supportsPublicInvestments: true,
  supportsPrivateInvestments: false,
  supportsPortfoliosOnly: false,
  hasFactors: false,
  supportedGraphicTypes: ['GRID', 'MAP'],
  requiredSubjects: 1,
} as const satisfies AbstractBlockSetting;

export const DISTRIBUTION_BLOCK_SETTINGS = {
  id: 'fb2ac74d-453b-4177-9fde-56882eda3453',
  customBlockType: 'DISTRIBUTION',
  title: 'Distribution',
  contributionToPercentage: false,
  relativeToBenchmark: true,
  metrics: [
    {
      key: 'returnsHistogram',
      label: 'Returns Distribution',
      relativeLabel: 'Relative Returns Distribution',
      dataType: 'NUMERIC',
      analysisType: 'RETURNS_HISTOGRAM',

      analysisResultKey: 'returnsHistogram',
      metricPath: undefined,
    },
  ],
  defaultMetrics: [],
  accessFeatures: [],
  supportsCustomMetrics: false,
  supportsPublicInvestments: true,
  supportsPrivateInvestments: false,
  supportsPortfoliosOnly: false,
  hasFactors: false,
  supportedGraphicTypes: ['DISTRIBUTE_BAR'],
  requiredSubjects: 1,
} as const satisfies AbstractBlockSetting;

export const PAGE_BREAK_BLOCK_SETTINGS = {
  id: 'f862b06a-00b9-4d73-9af2-4a9440ca8c00',
  customBlockType: 'PAGE_BREAK',
  title: 'Page Break',
  contributionToPercentage: false,
  relativeToBenchmark: false,
  metrics: [],
  defaultMetrics: [],
  accessFeatures: [],
  supportsCustomMetrics: false,
  supportsPublicInvestments: true,
  supportsPrivateInvestments: false,
  supportsPortfoliosOnly: false,
  hasFactors: false,
  supportedGraphicTypes: [],
  requiredSubjects: 1,
} as const satisfies AbstractBlockSetting;

export const MARKDOWN_BLOCK_SETTINGS = {
  id: '375ff19f-8b33-4f24-b35b-d7f170802074',
  customBlockType: 'MARKDOWN',
  title: 'Custom Text',
  contributionToPercentage: false,
  relativeToBenchmark: false,
  metrics: [],
  defaultMetrics: [],
  accessFeatures: [],
  supportsCustomMetrics: false,
  supportsPublicInvestments: true,
  supportsPrivateInvestments: false,
  supportsPortfoliosOnly: false,
  hasFactors: false,
  supportedGraphicTypes: [],
  requiredSubjects: 1,
} as const satisfies AbstractBlockSetting;

export const PERFORMANCE_SUMMARY_BLOCK_SETTINGS = {
  id: '8e434ca2-4ccb-4f29-ad31-4774779cfa90',
  customBlockType: 'PERFORMANCE_SUMMARY',
  title: 'Performance and Risk',
  contributionToPercentage: false,
  relativeToBenchmark: true,
  metrics: PERFORMANCE_BLOCK_METRICS,
  defaultMetrics: [],
  accessFeatures: [],
  supportsCustomMetrics: false,
  supportsPublicInvestments: true,
  supportsPrivateInvestments: false,
  supportsPortfoliosOnly: false,
  hasFactors: false,
  supportedGraphicTypes: ['GRID', 'PIVOTED_GRID', 'SCATTER'],
  requiredSubjects: 1,
} as const satisfies AbstractBlockSetting;

export const FACTOR_TREND_BLOCK_SETTINGS = {
  id: '20ee5214-612b-48fa-a3d3-a2507ce7afdc',
  customBlockType: 'TIMESERIES',
  title: 'Factor Trend',
  contributionToPercentage: false,
  relativeToBenchmark: true,
  metrics: [
    {
      key: 'factorExposuresTrend',
      label: 'Factor Exposures (β) Trend',
      relativeLabel: 'Relative Factor Exposures (β) Trend',
      dataType: 'NUMERIC',
      analysisType: 'FACTOR_EXPOSURES_TREND',
      category: '',
      analysisResultKey: 'factorExposuresTrend',
      metricPath: '',
      relativeMetricPath: '',
    },
    {
      key: 'factorContributionToRiskTrend',
      label: 'Factor Contributions to Risk Trend',
      relativeLabel: 'Relative Factor Contributions to Risk Trend',
      dataType: 'PERCENTAGE',
      analysisType: 'FACTOR_CONTRIBUTION_TO_RISK_TREND',
      category: '',
      analysisResultKey: 'factorContributionToRiskTrend',
      metricPath: '',
      relativeMetricPath: '',
    },
    {
      key: 'factorContributionToReturnTrend',
      label: 'Factor Contributions to Return Trend',
      relativeLabel: 'Relative Factor Contributions to Return Trend',
      dataType: 'PERCENTAGE',
      analysisType: 'FACTOR_CONTRIBUTION_TO_RETURN_TREND',
      category: '',
      analysisResultKey: 'factorContributionToReturnTrend',
      metricPath: '',
      relativeMetricPath: '',
    },
  ],
  defaultMetrics: [],
  accessFeatures: [],
  supportsCustomMetrics: false,
  supportsPublicInvestments: true,
  supportsPrivateInvestments: false,
  supportsPortfoliosOnly: false,
  hasFactors: true,
  supportedGraphicTypes: ['HEATMAP', 'LINE'],
  requiredSubjects: 1,
} as const satisfies AbstractBlockSetting;

export const IMAGE_BLOCK_SETTINGS = {
  id: '060b2f42-5ab3-4e6c-957b-cb8005fce5d2',
  customBlockType: 'IMAGE',
  title: 'Image',
  contributionToPercentage: false,
  relativeToBenchmark: false,
  metrics: [],
  defaultMetrics: [],
  accessFeatures: [],
  supportsCustomMetrics: false,
  supportsPublicInvestments: true,
  supportsPrivateInvestments: false,
  supportsPortfoliosOnly: false,
  hasFactors: false,
  supportedGraphicTypes: ['IMAGE'],
  requiredSubjects: 1,
} as const satisfies AbstractBlockSetting;

export const FACTOR_PORTFOLIO_CONTRIBUTION_BLOCK_SETTINGS = {
  id: '8ceeb1f3-4c0e-46bf-a00d-5e999322548e',
  customBlockType: 'PORTFOLIO_CONTRIBUTION',
  title: 'Factor Portfolio Contribution',
  contributionToPercentage: true,
  relativeToBenchmark: true,
  supportsPortfoliosOnly: true,
  metrics: [
    {
      key: 'contributionToExposure',
      label: 'Exposure Contribution (β)',
      relativeLabel: undefined,
      dataType: 'NUMERIC',
      analysisType: 'FUND_FACTOR_CONTRIBUTION',
      category: 'Factor Exposure (β)',
      analysisResultKey: 'factorExposureComponents',
      metricPath: 'beta.exposure',
    },
    {
      key: 'contributionToExposureUnweighted',
      label: 'Exposure (β)',
      relativeLabel: undefined,
      dataType: 'NUMERIC',
      analysisType: 'FUND_FACTOR_CONTRIBUTION',
      category: 'Factor Exposure (β)',
      analysisResultKey: 'factorExposureComponents',
      metricPath: 'beta.unweightedExposure',
    },
    {
      key: 'contributionToRisk',
      label: 'Cont. to Risk',
      relativeLabel: undefined,
      dataType: 'PERCENTAGE',
      analysisType: 'FUND_FACTOR_CONTRIBUTION',
      category: 'Factor Risk Exposure',
      analysisResultKey: 'factorExposureComponents',
      metricPath: 'riskContribution.exposure',
    },
    {
      key: 'contributionToRiskUnweighted',
      label: 'Risk Unweighted',
      relativeLabel: undefined,
      dataType: 'PERCENTAGE',
      analysisType: 'FUND_FACTOR_CONTRIBUTION',
      category: 'Factor Risk Exposure',
      analysisResultKey: 'factorExposureComponents',
      metricPath: 'riskContribution.unweightedExposure',
    },
    {
      key: 'contributionToReturn',
      label: 'Cont. to Return',
      relativeLabel: undefined,
      dataType: 'PERCENTAGE',
      analysisType: 'FUND_FACTOR_CONTRIBUTION',
      category: 'Factor Return Contributions',
      analysisResultKey: 'factorExposureComponents',
      metricPath: 'returnContribution.exposure',
    },
    {
      key: 'contributionToReturnUnweighted',
      label: 'Return Unweighted',
      relativeLabel: undefined,
      dataType: 'PERCENTAGE',
      analysisType: 'FUND_FACTOR_CONTRIBUTION',
      category: 'Factor Return Contributions',
      analysisResultKey: 'factorExposureComponents',
      metricPath: 'returnContribution.unweightedExposure',
    },
    {
      key: 'contributionToSharpe',
      label: 'Cont. to Sharpe',
      relativeLabel: undefined,
      dataType: 'NUMERIC',
      analysisType: 'FUND_FACTOR_CONTRIBUTION',
      category: 'Factor Sharpe Contributions',
      analysisResultKey: 'factorExposureComponents',
      metricPath: 'sharpeContribution.exposure',
    },
    {
      key: 'contributionToSharpeUnweighted',
      label: 'Sharpe Unweighted',
      relativeLabel: undefined,
      dataType: 'NUMERIC',
      analysisType: 'FUND_FACTOR_CONTRIBUTION',
      category: 'Factor Sharpe Contributions',
      analysisResultKey: 'factorExposureComponents',
      metricPath: 'sharpeContribution.unweightedExposure',
    },
  ],
  defaultMetrics: ['contributionToExposure'],
  accessFeatures: [],
  supportsCustomMetrics: false,
  supportsPublicInvestments: true,
  supportsPrivateInvestments: false,
  hasFactors: true,
  supportedGraphicTypes: ['GRID'],
  requiredSubjects: 1,
} as const satisfies AbstractBlockSetting;

export const ROLLING_CORRELATION_BLOCK_SETTINGS = {
  id: '9407570d-aeff-4041-bff0-d50ae3c4a2f7',
  customBlockType: 'ROLLING_CORRELATION',
  title: 'Rolling Correlation',
  contributionToPercentage: false,
  relativeToBenchmark: true,
  metrics: [
    {
      key: 'rollingCorrelation',
      label: 'Rolling Correlation',
      relativeLabel: 'Rolling Benchmark Relative Correlation',
      dataType: 'NUMERIC',
      analysisType: 'ROLLING_CORRELATION',
      category: '',
      analysisResultKey: 'rollingCorrelation',
      metricPath: 'rolling',
      relativeMetricPath: '',
    },
  ],
  defaultMetrics: [],
  accessFeatures: [],
  supportsCustomMetrics: false,
  supportsPublicInvestments: true,
  supportsPrivateInvestments: false,
  supportsPortfoliosOnly: false,
  hasFactors: false,
  supportedGraphicTypes: ['LINE'],
  requiredSubjects: 2,
} as const satisfies AbstractBlockSetting;

export const CALENDAR_RETURNS_BLOCK_SETTINGS = {
  id: '898cd06a-4cc7-405e-9569-ab44f60e91de',
  customBlockType: 'RETURNS_GRID',
  title: 'Calendar Returns',
  contributionToPercentage: false,
  relativeToBenchmark: true,
  metrics: [
    {
      key: 'monthlyCalendarReturn',
      label: 'Monthly Calendar Returns',
      relativeLabel: 'Monthly Relative Calendar Returns',
      dataType: 'PERCENTAGE',
      analysisType: 'RETURNS_GRID',
      category: 'Calendar Returns',
      analysisResultKey: 'returnsGrids',
      metricPath: 'returnsGridMap.MONTHLY.returnCells',
      relativeMetricPath: '',
    },
    {
      key: 'quarterlyCalendarReturn',
      label: 'Quarterly Calendar Returns',
      relativeLabel: 'Quarterly Relative Calendar Returns',
      dataType: 'PERCENTAGE',
      analysisType: 'RETURNS_GRID',
      category: 'Calendar Returns',
      analysisResultKey: 'returnsGrids',
      metricPath: 'returnsGridMap.QUARTERLY.returnCells',
      relativeMetricPath: '',
    },
    {
      key: 'yearlyCalendarReturn',
      label: 'Yearly Calendar Returns',
      relativeLabel: 'Yearly Relative Calendar Returns',
      dataType: 'PERCENTAGE',
      analysisType: 'RETURNS_GRID',
      category: 'Calendar Returns',
      analysisResultKey: 'returnsGrids',
      metricPath: 'returnsGridMap.YEARLY.returnCells',
      relativeMetricPath: '',
    },
  ],
  defaultMetrics: ['monthlyCalendarReturn'],
  accessFeatures: [],
  supportsCustomMetrics: false,
  supportsPublicInvestments: true,
  supportsPrivateInvestments: false,
  supportsPortfoliosOnly: false,
  hasFactors: false,
  supportedGraphicTypes: ['GRID'],
  requiredSubjects: 1,
} as const satisfies AbstractBlockSetting;

export const SECTOR_EXPOSURE_BLOCK_SETTINGS = {
  id: '13a7daf7-0f17-4cb5-9b7f-5cd6a13345d3',
  customBlockType: 'SECTOR_EXPOSURE',
  title: 'Sector',
  contributionToPercentage: false,
  relativeToBenchmark: false,
  metrics: [],
  defaultMetrics: [],
  accessFeatures: [],
  supportsCustomMetrics: false,
  supportsPublicInvestments: true,
  supportsPrivateInvestments: false,
  supportsPortfoliosOnly: false,
  hasFactors: false,
  supportedGraphicTypes: ['GRID', 'BAR'],
  requiredSubjects: 1,
} as const satisfies AbstractBlockSetting;

export const TIMESERIES_BLOCK_SETTINGS = {
  id: '3c67485d-afad-4647-ae1a-ad289fe7eae5',
  customBlockType: 'TIMESERIES',
  title: 'Time Series',
  contributionToPercentage: false,
  relativeToBenchmark: true,
  metrics: [
    {
      key: 'cumulativeReturn',
      label: 'Cumulative Return',
      relativeLabel: 'Cumulative Excess Return',
      dataType: 'PERCENTAGE',
      analysisType: 'CUMULATIVE_RETURN',
      category: '',
      analysisResultKey: 'cumulativeReturns',
      metricPath: '',
      relativeMetricPath: '',
    },
    {
      key: 'historicalDrawdown',
      label: 'Historical Drawdown',
      relativeLabel: 'Historical Underperformance Chart',
      dataType: 'PERCENTAGE',
      analysisType: 'PERCENTAGE_DRAWDOWN_ANALYSIS',
      category: '',
      analysisResultKey: 'percentageDrawdown',
      metricPath: 'series',
      relativeMetricPath: '',
    },
    {
      key: 'residualReturn',
      label: 'Cumulative Residual Returns',
      relativeLabel: 'Cumulative Excess Residual Return',
      dataType: 'PERCENTAGE',
      analysisType: 'RESIDUAL_RETURNS_ANALYSIS',
      category: '',
      analysisResultKey: 'residualReturnsAnalyses',
      metricPath: 'residualCumulativeContribution',
      relativeMetricPath: '',
    },
    {
      key: 'monthlyCalendarReturn',
      label: 'Monthly Calendar Returns',
      relativeLabel: 'Monthly Relative Calendar Returns',
      dataType: 'PERCENTAGE',
      analysisType: 'RETURNS_GRID',
      category: 'Calendar Returns',
      analysisResultKey: 'returnsGrids',
      metricPath: 'returnsGridMap.MONTHLY.returnCells',
      relativeMetricPath: '',
    },
    {
      key: 'quarterlyCalendarReturn',
      label: 'Quarterly Calendar Returns',
      relativeLabel: 'Quarterly Relative Calendar Returns',
      dataType: 'PERCENTAGE',
      analysisType: 'RETURNS_GRID',
      category: 'Calendar Returns',
      analysisResultKey: 'returnsGrids',
      metricPath: 'returnsGridMap.QUARTERLY.returnCells',
      relativeMetricPath: '',
    },
    {
      key: 'yearlyCalendarReturn',
      label: 'Yearly Calendar Returns',
      relativeLabel: 'Yearly Relative Calendar Returns',
      dataType: 'PERCENTAGE',
      analysisType: 'RETURNS_GRID',
      category: 'Calendar Returns',
      analysisResultKey: 'returnsGrids',
      metricPath: 'returnsGridMap.YEARLY.returnCells',
      relativeMetricPath: '',
    },
    {
      key: 'rollingReturn',
      label: 'Rolling Return',
      relativeLabel: 'Rolling Excess Return',
      dataType: 'PERCENTAGE',
      analysisType: 'ROLLING_RETURN',
      category: '',
      analysisResultKey: 'rollingReturn',
      metricPath: 'rolling',
      relativeMetricPath: '',
    },
    {
      key: 'rollingVolatility',
      label: 'Rolling Volatility',
      relativeLabel: 'Rolling Tracking Error',
      dataType: 'PERCENTAGE',
      analysisType: 'ROLLING_VOLATILITY',
      category: '',
      analysisResultKey: 'rollingVolatility',
      metricPath: 'rolling',
      relativeMetricPath: '',
    },
    {
      key: 'rollingSharpe',
      label: 'Rolling Sharpe Ratio',
      relativeLabel: 'Rolling Information Ratio',
      dataType: 'NUMERIC',
      analysisType: 'ROLLING_SHARPE',
      category: '',
      analysisResultKey: 'rollingSharpe',
      metricPath: 'rolling',
      relativeMetricPath: '',
    },
    {
      key: 'rollingAutocorrelation',
      label: 'Rolling Autocorrelation',
      relativeLabel: 'Rolling Autocorrelation',
      dataType: 'NUMERIC',
      analysisType: 'ROLLING_AUTOCORRELATION',
      category: '',
      analysisResultKey: 'rollingAutocorrelation',
      metricPath: 'rolling',
      relativeMetricPath: '',
    },
  ],
  defaultMetrics: [],
  accessFeatures: [],
  supportsCustomMetrics: false,
  supportsPublicInvestments: true,
  supportsPrivateInvestments: false,
  supportsPortfoliosOnly: false,
  hasFactors: false,
  supportedGraphicTypes: ['LINE', 'BAR'],
  requiredSubjects: 1,
} as const satisfies AbstractBlockSetting;

export const NOTABLE_PERIODS_BLOCK_SETTINGS = {
  id: 'b7035da7-cf56-4718-85d3-5139b04d1eaa',
  customBlockType: 'NOTABLE_PERIODS',
  title: 'Notable Historical Periods',
  contributionToPercentage: false,
  relativeToBenchmark: true,
  metrics: [
    {
      key: 'start',
      label: 'Start Date',
      relativeLabel: undefined,
      dataType: 'NUMERIC',
      analysisType: 'NOTABLE_PERIODS',
      category: 'Period Metadata',
      analysisResultKey: 'notablePeriods',
      metricPath: undefined,
    },
    {
      key: 'end',
      label: 'End Date',
      relativeLabel: undefined,
      dataType: 'NUMERIC',
      analysisType: 'NOTABLE_PERIODS',
      category: 'Period Metadata',
      analysisResultKey: 'notablePeriods',
      metricPath: undefined,
    },
    {
      key: 'period',
      label: 'Period Length (Months)',
      relativeLabel: undefined,
      dataType: 'NUMERIC',
      analysisType: 'NOTABLE_PERIODS',
      category: 'Period Metadata',
      analysisResultKey: 'notablePeriods',
      metricPath: undefined,
    },
    {
      key: 'recovery',
      label: 'Recovery Length (Months)',
      relativeLabel: undefined,
      dataType: 'NUMERIC',
      analysisType: 'NOTABLE_PERIODS',
      category: 'Period Metrics',
      analysisResultKey: 'notablePeriods',
      metricPath: undefined,
    },
    {
      key: 'amount',
      label: 'Return',
      relativeLabel: undefined,
      dataType: 'PERCENTAGE',
      analysisType: 'NOTABLE_PERIODS',
      category: 'Period Metrics',
      analysisResultKey: 'notablePeriods',
      metricPath: undefined,
    },
  ],
  defaultMetrics: ['start', 'end', 'period', 'recovery', 'amount'],
  accessFeatures: [],
  supportsCustomMetrics: false,
  supportsPublicInvestments: true,
  supportsPrivateInvestments: false,
  supportsPortfoliosOnly: false,
  hasFactors: false,
  supportedGraphicTypes: ['GRID', 'DISTRIBUTE_BAR'],
  requiredSubjects: 1,
} as const satisfies AbstractBlockSetting;

export const SCENARIO_ANALYSIS_BLOCK_SETTINGS = {
  id: '36dca1fd-720c-42aa-97d0-af49efff41cb',
  customBlockType: 'SCENARIO',
  title: 'Scenario Analysis' as string,
  contributionToPercentage: false,
  relativeToBenchmark: true,
  metrics: [
    {
      key: 'predicted',
      label: 'Estimated Return',
      relativeLabel: undefined,
      dataType: 'NUMERIC',
      analysisType: 'SCENARIO',

      analysisResultKey: 'scenarios',
      metricPath: undefined,
    },
    {
      key: 'predictedError',
      label: 'Estimated Return Error (+/-)',
      relativeLabel: undefined,
      dataType: 'NUMERIC',
      analysisType: 'SCENARIO',

      analysisResultKey: 'scenarios',
      metricPath: undefined,
    },
  ],
  defaultMetrics: ['predicted', 'predictedError'],
  accessFeatures: [],
  supportsCustomMetrics: false,
  supportsPublicInvestments: true,
  supportsPrivateInvestments: false,
  supportsPortfoliosOnly: false,
  hasFactors: false,
  supportedGraphicTypes: ['GRID', 'DISTRIBUTE_BAR'],
  requiredSubjects: 1,
} as const satisfies AbstractBlockSetting;

export const PORTFOLIO_CONTRIBUTION_BLOCK_SETTINGS = {
  id: 'e67c669c-d85f-49a8-ae2f-dd1649ff9c8c',
  customBlockType: 'PORTFOLIO_CONTRIBUTION',
  title: 'Portfolio Contribution',
  contributionToPercentage: true,
  relativeToBenchmark: true,
  metrics: [
    {
      key: 'allocation-VALUE',
      label: 'Value',
      relativeLabel: 'Value',
      dataType: 'NUMERIC',
      analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL_PORTFOLIO_CONTRIBUTION',

      analysisResultKey: 'historicalPerformancePortfolioContributions',
      metricPath: 'allocation',
    },
    {
      key: 'allocation-PERCENT',
      label: 'Weight',
      relativeLabel: 'Weight',
      dataType: 'PERCENTAGE',
      analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL_PORTFOLIO_CONTRIBUTION',

      analysisResultKey: 'historicalPerformancePortfolioContributions',
      metricPath: 'allocation',
    },
    {
      key: 'periodReturn',
      label: 'Return',
      relativeLabel: 'Excess Return',
      dataType: 'PERCENTAGE',
      analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL_PORTFOLIO_CONTRIBUTION',
      category: 'Historical Performance',
      analysisResultKey: 'historicalPerformancePortfolioContributions',
      metricPath: 'periodReturn',
      relativeMetricPath: 'periodExcessReturn',
    },
    {
      key: 'sharpe',
      label: 'Sharpe Ratio',
      relativeLabel: 'Information Ratio',
      dataType: 'NUMERIC',
      analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL_PORTFOLIO_CONTRIBUTION',
      category: 'Historical Performance',
      analysisResultKey: 'historicalPerformancePortfolioContributions',
      metricPath: 'sharpe',
      relativeMetricPath: 'informationRatio',
    },
    {
      key: 'volatility',
      label: 'Volatility',
      relativeLabel: 'Tracking Error',
      dataType: 'PERCENTAGE',
      analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL_PORTFOLIO_CONTRIBUTION',
      category: 'Historical Performance',
      analysisResultKey: 'historicalPerformancePortfolioContributions',
      metricPath: 'volatility',
      relativeMetricPath: 'trackingError',
    },
    {
      key: 'maxDrawdown',
      label: 'Max Drawdown',
      relativeLabel: 'Max Underperformance',
      dataType: 'PERCENTAGE',
      analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL_PORTFOLIO_CONTRIBUTION',
      category: 'Historical Performance',
      analysisResultKey: 'historicalPerformancePortfolioContributions',
      metricPath: 'maxDrawdown',
      relativeMetricPath: 'maxUnderperformance',
    },
    {
      key: 'FORECAST-annualizedReturn',
      label: 'Return (Forecast)',
      relativeLabel: 'Excess Return (Forecast)',
      dataType: 'PERCENTAGE',
      analysisType: 'PERFORMANCE_SUMMARY_FORECAST_PORTFOLIO_CONTRIBUTION',
      category: 'Forecast Performance',
      analysisResultKey: 'forecastedPerformancePortfolioContributions',
      metricPath: 'periodReturn',
      relativeMetricPath: 'periodExcessReturn',
    },
    {
      key: 'FORECAST-sharpe',
      label: 'Sharpe Ratio (Forecast)',
      relativeLabel: 'Information Ratio (Forecast)',
      dataType: 'NUMERIC',
      analysisType: 'PERFORMANCE_SUMMARY_FORECAST_PORTFOLIO_CONTRIBUTION',
      category: 'Forecast Performance',
      analysisResultKey: 'forecastedPerformancePortfolioContributions',
      metricPath: 'sharpe',
      relativeMetricPath: 'informationRatio',
    },
    {
      key: 'FORECAST-volatility',
      label: 'Volatility (Forecast)',
      relativeLabel: 'Tracking Error (Forecast)',
      dataType: 'PERCENTAGE',
      analysisType: 'PERFORMANCE_SUMMARY_FORECAST_PORTFOLIO_CONTRIBUTION',
      category: 'Forecast Performance',
      analysisResultKey: 'forecastedPerformancePortfolioContributions',
      metricPath: 'volatility',
      relativeMetricPath: 'trackingError',
    },
    {
      key: 'RESIDUAL-periodReturn',
      label: 'Return (Residual)',
      relativeLabel: 'Excess Return (Residual)',
      dataType: 'PERCENTAGE',
      analysisType: 'HISTORICAL_RESIDUAL_PORTFOLIO_RETURNS_ANALYSIS',
      category: 'Historical Residual Performance',
      analysisResultKey: 'portfolioHistoricalResidualReturnsResponses',
      metricPath: 'periodReturn',
      relativeMetricPath: 'periodExcessReturn',
    },
    {
      key: 'RESIDUAL-sharpe',
      label: 'Sharpe Ratio (Residual)',
      relativeLabel: 'Information Ratio (Residual)',
      dataType: 'NUMERIC',
      analysisType: 'HISTORICAL_RESIDUAL_PORTFOLIO_RETURNS_ANALYSIS',
      category: 'Historical Residual Performance',
      analysisResultKey: 'portfolioHistoricalResidualReturnsResponses',
      metricPath: 'sharpe',
      relativeMetricPath: 'informationRatio',
    },
    {
      key: 'RESIDUAL-volatility',
      label: 'Volatility (Residual)',
      relativeLabel: 'Tracking Error (Residual)',
      dataType: 'PERCENTAGE',
      analysisType: 'HISTORICAL_RESIDUAL_PORTFOLIO_RETURNS_ANALYSIS',
      category: 'Historical Residual Performance',
      analysisResultKey: 'portfolioHistoricalResidualReturnsResponses',
      metricPath: 'volatility',
      relativeMetricPath: 'trackingError',
    },
  ],
  defaultMetrics: ['allocation', 'periodReturn', 'sharpe', 'volatility', 'maxDrawdown'],
  accessFeatures: [],
  supportsCustomMetrics: false,
  supportsPublicInvestments: true,
  supportsPrivateInvestments: false,
  supportsPortfoliosOnly: true,
  hasFactors: false,
  supportedGraphicTypes: ['GRID'],
  requiredSubjects: 1,
} as const satisfies AbstractBlockSetting;

const TYPICAL_PROFILE_TOOLTIP =
  'Typical Profile reflects the Cash Flow Profile for a typical private asset investment in a given asset class/strategy and can serve as a reference point. For portfolios, the underlying fund level profiles are aggregated to form the portfolio’s typical cash flow profile. Actual cash flows will differ from the profile shown.';

export const PRIVATE_CASH_FLOW_BLOCK_SETTINGS = {
  id: 'b6e0f3e9-62e3-47c8-974e-eb468a7744b7',
  customBlockType: 'PRIVATE_CASH_FLOW',
  title: 'Cash Flow Pacing Net Cash Flow',
  contributionToPercentage: false,
  relativeToBenchmark: false,
  metrics: [
    {
      key: 'net',
      label: 'Historical Net Cash Flow',
      dataType: 'NUMERIC',
      analysisType: 'RANGE_DEBUG',

      analysisResultKey: 'net',
      metricPath: 'actual',
    },
    {
      key: 'netProjected',
      label: 'Projected Net Cash Flow',
      dataType: 'NUMERIC',
      analysisType: 'RANGE_DEBUG',

      analysisResultKey: 'net',
      metricPath: 'projected',
    },
    {
      key: 'netInitial',
      label: 'Typical Net Cash Flow Profile',
      dataType: 'NUMERIC',
      analysisType: 'RANGE_DEBUG',

      analysisResultKey: 'net',
      metricPath: 'initial',

      supportedGraphicTypes: ['PRIVATE_CASH_FLOW'],
      tooltipContent: TYPICAL_PROFILE_TOOLTIP,
    },
  ],
  defaultMetrics: ['net', 'netProjected', 'netInitial'],
  accessFeatures: [],
  supportsCustomMetrics: false,
  supportsPublicInvestments: false,
  supportsPrivateInvestments: true,
  supportsPortfoliosOnly: false,
  hasFactors: false,
  supportedGraphicTypes: ['PRIVATE_CASH_FLOW', 'GRID'],
  requiredSubjects: 1,
} as const satisfies AbstractBlockSetting;

export const PRIVATE_CONTRIBUTIONS_BLOCK_SETTINGS = {
  id: '036332da-c8ec-46ba-a7e4-f9af31c9c785',
  customBlockType: 'PRIVATE_CASH_FLOW',
  title: 'Cash Flow Pacing Contributions',
  contributionToPercentage: false,
  relativeToBenchmark: false,
  metrics: [
    {
      key: 'contributions',
      label: 'Historical Contributions',
      dataType: 'NUMERIC',
      analysisType: 'RANGE_DEBUG',

      analysisResultKey: 'contributions',
      metricPath: 'actual',
    },
    {
      key: 'contributionsProjected',
      label: 'Projected Contributions',
      dataType: 'NUMERIC',
      analysisType: 'RANGE_DEBUG',

      analysisResultKey: 'contributions',
      metricPath: 'projected',
    },
    {
      key: 'contributionsInitial',
      label: 'Typical Contribution Profile',
      dataType: 'NUMERIC',
      analysisType: 'RANGE_DEBUG',

      analysisResultKey: 'contributions',
      metricPath: 'initial',

      supportedGraphicTypes: ['PRIVATE_CASH_FLOW'],
      tooltipContent: TYPICAL_PROFILE_TOOLTIP,
    },
  ],
  defaultMetrics: ['contributions', 'contributionsProjected', 'contributionsInitial'],
  accessFeatures: [],
  supportsCustomMetrics: false,
  supportsPublicInvestments: false,
  supportsPrivateInvestments: true,
  supportsPortfoliosOnly: false,
  hasFactors: false,
  supportedGraphicTypes: ['PRIVATE_CASH_FLOW', 'GRID'],
  requiredSubjects: 1,
} as const satisfies AbstractBlockSetting;

export const PRIVATE_DISTRIBUTIONS_BLOCK_SETTINGS = {
  id: 'ffcea39e-d50c-4773-b1ac-6b9b6c8bd312',
  customBlockType: 'PRIVATE_CASH_FLOW',
  title: 'Cash Flow Pacing Distributions',
  contributionToPercentage: false,
  relativeToBenchmark: false,
  metrics: [
    {
      key: 'distributions',
      label: 'Historical Distributions',
      dataType: 'NUMERIC',
      analysisType: 'RANGE_DEBUG',

      analysisResultKey: 'distributions',
      metricPath: 'actual',
    },
    {
      key: 'distributionsProjected',
      label: 'Projected Distributions',
      dataType: 'NUMERIC',
      analysisType: 'RANGE_DEBUG',

      analysisResultKey: 'distributions',
      metricPath: 'projected',
    },
    {
      key: 'distributionsInitial',
      label: 'Typical Distribution Profile',
      dataType: 'NUMERIC',
      analysisType: 'RANGE_DEBUG',

      analysisResultKey: 'distributions',
      metricPath: 'initial',

      supportedGraphicTypes: ['PRIVATE_CASH_FLOW'],
      tooltipContent: TYPICAL_PROFILE_TOOLTIP,
    },
  ],
  defaultMetrics: ['distributions', 'distributionsProjected', 'distributionsInitial'],
  accessFeatures: [],
  supportsCustomMetrics: false,
  supportsPublicInvestments: false,
  supportsPrivateInvestments: true,
  supportsPortfoliosOnly: false,
  hasFactors: false,
  supportedGraphicTypes: ['PRIVATE_CASH_FLOW', 'GRID'],
  requiredSubjects: 1,
} as const satisfies AbstractBlockSetting;

export const PRIVATE_NAV_BLOCK_SETTINGS = {
  id: '21e587fa-30e0-4d81-be80-862cbde975f0',
  customBlockType: 'PRIVATE_CASH_FLOW',
  title: 'Cash Flow Pacing NAV',
  contributionToPercentage: false,
  relativeToBenchmark: false,
  metrics: [
    {
      key: 'nav',
      label: 'Historical NAV',
      dataType: 'NUMERIC',
      analysisType: 'RANGE_DEBUG',

      analysisResultKey: 'nav',
      metricPath: 'actual',
    },
    {
      key: 'navProjected',
      label: 'Projected NAV',
      dataType: 'NUMERIC',
      analysisType: 'RANGE_DEBUG',

      analysisResultKey: 'nav',
      metricPath: 'projected',
    },
    {
      key: 'navInitial',
      label: 'Typical NAV Profile',
      dataType: 'NUMERIC',
      analysisType: 'RANGE_DEBUG',

      analysisResultKey: 'nav',
      metricPath: 'initial',

      supportedGraphicTypes: ['PRIVATE_CASH_FLOW'],
      tooltipContent: TYPICAL_PROFILE_TOOLTIP,
    },
  ],
  defaultMetrics: ['nav', 'navProjected', 'navInitial'],
  accessFeatures: [],
  supportsCustomMetrics: false,
  supportsPublicInvestments: false,
  supportsPrivateInvestments: true,
  supportsPortfoliosOnly: false,
  hasFactors: false,
  supportedGraphicTypes: ['PRIVATE_CASH_FLOW', 'GRID'],
  requiredSubjects: 1,
} as const satisfies AbstractBlockSetting;

export const PRIVATE_ASSET_SUMMARY_BLOCK_SETTINGS = {
  id: 'e5987a6e-2224-464e-ac8e-dd20834f1445',
  customBlockType: 'PRIVATE_CAPITAL_SUMMARY',
  title: 'Private Asset Summary',
  contributionToPercentage: false,
  relativeToBenchmark: false,
  metrics: [],
  defaultMetrics: [],
  accessFeatures: [],
  supportsCustomMetrics: false,
  supportsPublicInvestments: false,
  supportsPrivateInvestments: true,
  supportsPortfoliosOnly: true,
  hasFactors: false,
  supportedGraphicTypes: ['GRID'],
  requiredSubjects: 1,
} as const satisfies AbstractBlockSetting;

export const PRIVATE_ASSET_PERFORMANCE_TIMESERIES_BLOCK_SETTINGS = {
  id: '52ae37ba-27dd-4eeb-9ab1-45c642bb774f',
  customBlockType: 'PRIVATE_PERFORMANCE_TIME_SERIES',
  title: 'Time Series',
  contributionToPercentage: false,
  relativeToBenchmark: false,
  metrics: [
    {
      key: 'calledPercentage',
      label: 'Called',
      dataType: 'PERCENTAGE',
      analysisType: 'RANGE_DEBUG',

      analysisResultKey: 'calledPercentage',
      metricPath: 'timeSeries',

      supportedGraphicTypes: ['LINE', 'BAR', 'GRID'],
    },
    {
      key: 'dpiPercentage',
      label: 'DPI',
      dataType: 'PERCENTAGE',
      analysisType: 'RANGE_DEBUG',

      analysisResultKey: 'dpiPercentage',
      metricPath: 'timeSeries',

      supportedGraphicTypes: ['LINE', 'BAR', 'GRID'],
    },
    {
      key: 'netMultiple',
      label: 'Net Multiple',
      dataType: 'NUMERIC',
      analysisType: 'RANGE_DEBUG',

      analysisResultKey: 'netMultiple',
      metricPath: 'timeSeries',

      supportedGraphicTypes: ['LINE', 'BAR', 'GRID'],
    },
    {
      key: 'rvpiPercentage',
      label: 'RVPI',
      dataType: 'PERCENTAGE',
      analysisType: 'RANGE_DEBUG',

      analysisResultKey: 'rvpiPercentage',
      metricPath: 'timeSeries',

      supportedGraphicTypes: ['LINE', 'BAR', 'GRID'],
    },
    {
      key: 'nav',
      label: 'NAV',
      dataType: 'CURRENCY',
      analysisType: 'RANGE_DEBUG',

      analysisResultKey: 'nav',
      metricPath: 'timeSeries',

      supportedGraphicTypes: ['LINE', 'BAR', 'GRID'],
    },
    {
      key: 'netIRRPercentage',
      label: 'Net IRR',
      dataType: 'PERCENTAGE',
      analysisType: 'RANGE_DEBUG',

      analysisResultKey: 'netIRRPercentage',
      metricPath: 'timeSeries',

      supportedGraphicTypes: ['LINE', 'BAR', 'GRID'],
    },

    {
      key: 'pmeDirectAlpha',
      label: 'PME - Direct Alpha',
      dataType: 'PERCENTAGE',
      analysisType: 'RANGE_DEBUG',
      analysisResultKey: 'pmeDirectAlpha',
      metricPath: 'timeSeries',
      supportedGraphicTypes: ['LINE', 'BAR', 'GRID'],
      needsBenchmark: true,
    },
    {
      key: 'pmeKaplanSchoar',
      label: 'PME - Kaplan-Schoar (KS)',
      dataType: 'NUMERIC',
      analysisType: 'RANGE_DEBUG',
      analysisResultKey: 'pmeKaplanSchoar',
      metricPath: 'timeSeries',
      supportedGraphicTypes: ['LINE', 'BAR', 'GRID'],
      needsBenchmark: true,
    },
    {
      key: 'pmeLongNickels',
      label: 'PME - Long-Nickels (LN)',
      dataType: 'PERCENTAGE',
      analysisType: 'RANGE_DEBUG',
      analysisResultKey: 'pmeLongNickels',
      metricPath: 'timeSeries',
      supportedGraphicTypes: ['LINE', 'BAR', 'GRID'],
      needsBenchmark: true,
    },
    {
      key: 'pmeCapitalDynamics',
      label: 'PME - Capital Dynamics (PME+)',
      dataType: 'PERCENTAGE',
      analysisType: 'RANGE_DEBUG',
      analysisResultKey: 'pmeCapitalDynamics',
      metricPath: 'timeSeries',
      supportedGraphicTypes: ['LINE', 'BAR', 'GRID'],
      needsBenchmark: true,
    },
  ],
  defaultMetrics: ['calledPercentage'],
  accessFeatures: ['public_market_equivalents_ff'],
  supportsCustomMetrics: false,
  supportsPublicInvestments: false,
  supportsPrivateInvestments: true,
  supportsPortfoliosOnly: false,
  hasFactors: false,
  supportedGraphicTypes: ['LINE', 'BAR', 'GRID'],
  requiredSubjects: 1,
} as const satisfies AbstractBlockSetting;

export const PRIVATE_ASSET_PERFORMANCE_SUMMARY_BLOCK_SETTINGS = {
  id: 'b3ee5a0b-5fce-4ac5-9e86-faac7fb16489',
  customBlockType: 'PRIVATE_PERFORMANCE_SUMMARY',
  defaultMetrics: [],
  title: 'Performance Summary',
  contributionToPercentage: false,
  relativeToBenchmark: false,
  metrics: [
    {
      key: 'asOfDate',
      label: 'As of Date',
      dataType: 'TEXT',
      analysisType: 'RANGE_DEBUG',
      analysisResultKey: 'asOfDate',
      metricPath: 'performanceSummary',
      supportedGraphicTypes: ['GRID'],
    },
    {
      key: 'vintage',
      label: 'Vintage',
      dataType: 'TEXT',
      analysisType: 'RANGE_DEBUG',
      analysisResultKey: 'vintage',
      metricPath: 'performanceSummary',
      supportedGraphicTypes: ['GRID'],
    },
    {
      key: 'capitalCommitment',
      label: 'Capital Commitment',
      dataType: 'CURRENCY',
      analysisType: 'RANGE_DEBUG',
      analysisResultKey: 'capitalCommitment',
      metricPath: 'performanceSummary',
      supportedGraphicTypes: ['GRID'],
    },
    {
      key: 'calledPercentage',
      label: 'Called',
      dataType: 'PERCENTAGE',
      analysisType: 'RANGE_DEBUG',
      analysisResultKey: 'calledPercentage',
      metricPath: 'performanceSummary',
      supportedGraphicTypes: ['GRID'],
    },
    {
      key: 'dpiPercentage',
      label: 'DPI',
      dataType: 'PERCENTAGE',
      analysisType: 'RANGE_DEBUG',
      analysisResultKey: 'dpiPercentage',
      metricPath: 'performanceSummary',
      supportedGraphicTypes: ['GRID'],
    },
    {
      key: 'netMultiple',
      label: 'Net Multiple',
      dataType: 'NUMERIC',
      analysisType: 'RANGE_DEBUG',

      analysisResultKey: 'netMultiple',
      metricPath: 'performanceSummary',
      supportedGraphicTypes: ['GRID'],
    },
    {
      key: 'rvpiPercentage',
      label: 'RVPI',
      dataType: 'PERCENTAGE',
      analysisType: 'RANGE_DEBUG',
      analysisResultKey: 'rvpiPercentage',
      metricPath: 'performanceSummary',
      supportedGraphicTypes: ['GRID'],
    },
    {
      key: 'nav',
      label: 'NAV',
      dataType: 'CURRENCY',
      analysisType: 'RANGE_DEBUG',
      analysisResultKey: 'nav',
      metricPath: 'performanceSummary',
      supportedGraphicTypes: ['GRID'],
    },
    {
      key: 'netIRRPercentage',
      label: 'Net IRR',
      dataType: 'PERCENTAGE',
      analysisType: 'RANGE_DEBUG',
      analysisResultKey: 'netIRRPercentage',
      metricPath: 'performanceSummary',
      supportedGraphicTypes: ['GRID'],
    },

    {
      key: 'pmeDirectAlpha',
      label: 'PME - Direct Alpha',
      dataType: 'PERCENTAGE',
      analysisType: 'RANGE_DEBUG',
      analysisResultKey: 'pmeDirectAlpha',
      metricPath: 'performanceSummary',
      supportedGraphicTypes: ['GRID'],
      needsBenchmark: true,
    },
    {
      key: 'pmeKaplanSchoar',
      label: 'PME - Kaplan-Schoar (KS)',
      dataType: 'NUMERIC',
      analysisType: 'RANGE_DEBUG',
      analysisResultKey: 'pmeKaplanSchoar',
      metricPath: 'performanceSummary',
      supportedGraphicTypes: ['GRID'],
      needsBenchmark: true,
    },
    {
      key: 'pmeLongNickels',
      label: 'PME - Long-Nickels (LN)',
      dataType: 'PERCENTAGE',
      analysisType: 'RANGE_DEBUG',
      analysisResultKey: 'pmeLongNickels',
      metricPath: 'performanceSummary',
      supportedGraphicTypes: ['GRID'],
      needsBenchmark: true,
    },
    {
      key: 'pmeCapitalDynamics',
      label: 'PME - Capital Dynamics (PME+)',
      dataType: 'PERCENTAGE',
      analysisType: 'RANGE_DEBUG',
      analysisResultKey: 'pmeCapitalDynamics',
      metricPath: 'performanceSummary',
      supportedGraphicTypes: ['GRID'],
      needsBenchmark: true,
    },
  ],
  accessFeatures: ['public_market_equivalents_ff'],
  supportsCustomMetrics: false,
  supportsPublicInvestments: false,
  supportsPrivateInvestments: true,
  supportsPortfoliosOnly: false,
  hasFactors: false,
  supportedGraphicTypes: ['GRID'],
  requiredSubjects: 1,
} as const satisfies AbstractBlockSetting;

export const STATIC_BLOCK_SETTINGS = [
  CORRELATION_BLOCK_SETTINGS,
  FACTOR_CONTRIBUTION_BLOCK_SETTINGS,
  ASSET_EXPOSURE_BLOCK_SETTINGS,
  PORTFOLIO_BREAKDOWN_BLOCK_SETTINGS,
  GROWTH_SIMULATION_BLOCK_SETTINGS,
  GEOGRAPHY_EXPOSURE_BLOCK_SETTINGS,
  DISTRIBUTION_BLOCK_SETTINGS,
  PAGE_BREAK_BLOCK_SETTINGS,
  MARKDOWN_BLOCK_SETTINGS,
  PERFORMANCE_SUMMARY_BLOCK_SETTINGS,
  FACTOR_TREND_BLOCK_SETTINGS,
  IMAGE_BLOCK_SETTINGS,
  FACTOR_PORTFOLIO_CONTRIBUTION_BLOCK_SETTINGS,
  ROLLING_CORRELATION_BLOCK_SETTINGS,
  CALENDAR_RETURNS_BLOCK_SETTINGS,
  SECTOR_EXPOSURE_BLOCK_SETTINGS,
  TIMESERIES_BLOCK_SETTINGS,
  NOTABLE_PERIODS_BLOCK_SETTINGS,
  SCENARIO_ANALYSIS_BLOCK_SETTINGS,
  PORTFOLIO_CONTRIBUTION_BLOCK_SETTINGS,
  PRIVATE_CASH_FLOW_BLOCK_SETTINGS,
  PRIVATE_CONTRIBUTIONS_BLOCK_SETTINGS,
  PRIVATE_DISTRIBUTIONS_BLOCK_SETTINGS,
  PRIVATE_NAV_BLOCK_SETTINGS,
  PRIVATE_ASSET_SUMMARY_BLOCK_SETTINGS,
  PRIVATE_ASSET_PERFORMANCE_TIMESERIES_BLOCK_SETTINGS,
  PRIVATE_ASSET_PERFORMANCE_SUMMARY_BLOCK_SETTINGS,
  PORTFOLIO_COMPARISON_BLOCK,
  PEER_GROUP_ANALYTICS_BLOCK_SETTINGS,
  PUBLIC_PRIVATE_ASSET_GROWTH_PERCENTILES_BLOCK_SETTINGS,
  PUBLIC_PRIVATE_ASSET_GROWTH_BREAKDOWN_BLOCK_SETTINGS,
] as const satisfies AbstractBlockSetting[];

// We omit metrics and then switch it to the abstract metrics type to allow for extension at runtime for the "custom metrics"/"custom fields" project.
// We use DistributiveOmit so that the union doesn't get merged together like Omit would cause.
export type CustomizableBlockSetting = DistributiveOmit<(typeof STATIC_BLOCK_SETTINGS)[number], 'metrics'> &
  Pick<AbstractBlockSetting, 'metrics'>;

export const getBlockSettingsMapForUser = () => {
  const availableBlocks = STATIC_BLOCK_SETTINGS.filter((b) => FS.hasAll(b.accessFeatures)).map((block) => ({
    ...block,
    metrics: block.metrics.filter(
      (metric: CustomizableMetric) => !metric.accessFeatures || FS.hasAll(metric.accessFeatures),
    ),
  }));

  // Override "Scenario Analysis" title with "Sensitivity Analysis" if the ff is on. Fix in the static block config
  // definition once the ff is launched.
  const scenarioBlockIdx = availableBlocks.findIndex(({ id }) => id === SCENARIO_ANALYSIS_BLOCK_SETTINGS.id);
  if (scenarioBlockIdx !== -1) {
    availableBlocks[scenarioBlockIdx] = {
      ...SCENARIO_ANALYSIS_BLOCK_SETTINGS,
      title: 'Sensitivity Analysis',
    };
  }

  return keyBy(availableBlocks, 'id');
};
