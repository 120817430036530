/*
 * Venn Platform API 1.0.0 Operations for "scheduled"
 * Automatically generated file - do not modify!
 */

import type { AnalysisExportRequest, FileContents, FrequencyEnum, ScheduledExport, ScheduledLog } from '../types';
import type { OperationResult } from '../../utils';
import { buildRequestInit, extractError, retryGeneratedFetch } from '../../utils';

/** Delete user saved schedule export by view id */
export async function deleteScheduledExport(id: string, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/scheduled/exports/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({ method: 'DELETE', signal });
  const response = await retryGeneratedFetch('DELETE', () => fetch(endpoint, requestInit));
  if ((response.status >= 200 && response.status < 400) || response.status === 404) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Export a file directly from analysis request */
export async function getExportExcel(
  body?: Partial<AnalysisExportRequest>,
  signal?: AbortSignal,
): Promise<OperationResult<FileContents>> {
  const endpoint = '/api/scheduled/file';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await retryGeneratedFetch('POST', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: FileContents = (await response.json()) as FileContents;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Retrieve list of exports logs by view Ids */
export async function getExportsLogsByViewIds(
  body?: string[],
  signal?: AbortSignal,
): Promise<OperationResult<ScheduledLog[]>> {
  const endpoint = '/api/scheduled/exports/views/logs';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await retryGeneratedFetch('POST', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: ScheduledLog[] = (await response.json()) as ScheduledLog[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Retrieve list of user active scheduled exports by view Ids */
export async function getScheduledExportByViewIds(
  body?: string[],
  signal?: AbortSignal,
): Promise<OperationResult<ScheduledExport[]>> {
  const endpoint = '/api/scheduled/exports/views';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await retryGeneratedFetch('POST', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: ScheduledExport[] = (await response.json()) as ScheduledExport[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Retrieve list of user saved/active schedule exports for views */
export async function getUserActiveScheduledExport(signal?: AbortSignal): Promise<OperationResult<ScheduledExport[]>> {
  const endpoint = '/api/scheduled/exports';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: ScheduledExport[] = (await response.json()) as ScheduledExport[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Schedule exports for a view */
export async function scheduleExportForView(
  body?: Partial<ScheduledExport>,
  signal?: AbortSignal,
): Promise<OperationResult<ScheduledExport>> {
  const endpoint = '/api/scheduled/exports';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: ScheduledExport = (await response.json()) as ScheduledExport;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Send out scheduled emails for a list of scheduled ID */
export async function sendExportsEmails(body?: string[], signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = '/api/scheduled/exports/send';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Send scheduled exports by frequency */
export async function scheduleExportByFrequency(
  body?: FrequencyEnum,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = '/api/scheduled/exports/frequency';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
