import type { Fund } from 'venn-api';
import { getAppTitleSimplifiedUtil } from '../../utils';

export const mockIntegratedFund: Fund = {
  id: '69a7628d-39c4-456f-adc3-278272fc2109',
  name: 'Private Asset 123',
  owner: {
    id: -42,
    name: `${getAppTitleSimplifiedUtil()}`,
    displayName: `${getAppTitleSimplifiedUtil()}`,
    active: false,
    actualUser: false,
  },
  updatedBy: {
    id: 3002490,
    name: 'Matthew Shepherd',
    displayName: 'Matthew Shepherd',
    email: 'mshepherd@twosigma.com',
    active: true,
    created: 1556210032268,
    actualUser: false,
  },
  frequency: 3,
  startRange: 1412121599999,
  endRange: 1593561599999,
  preferredFrequency: 3,
  created: 1578049204853,
  updated: 1598913808378,
  fundSourceType: 'CUSTODIAN_ACCOUNT',
  userUploaded: false,
  investmentBenchmarks: [],
  active: true,
  live: true,
  investmentSource: 'CUSTODIAN',
  investmentType: 'NOT_SPECIFIED',
  dataSource: 'BNY Mellon',
  assetType: 'CUSTODIAN_ACCOUNT',
  remoteType: 'CUSTODIAN',
  accountNumber: 'TUFF42530002',
  currency: 'USD',
  favorite: false,
  viewed: false,
  unproxiedNumReturns: 24,
  categoryAssetType: { key: 'HEDGE_FUND', name: 'Hedge Fund' },
  userControlled: false,
  needsProxy: false,
  needsInterpolation: false,
  benchmarks: [],
  returnFrequency: 'QUARTERLY',
  proxyable: true,
  interpolatable: true,
  outOfDate: false,
  hasMinDataForInterpolation: true,
  userEditable: false,
} as unknown as Fund;
