import type { ReactNode } from 'react';
import React from 'react';

export interface CarouselSlideProps {
  /**
   * The short description of this carousel slide to show at top left when this slide is active.
   */
  description: string;
  children?: ReactNode;
}

/**
 * Holds props and children that the containing Carousel component directly accesses and uses, otherwise this component
 * simply renders the provided children with no modification and no additional UI or functionality.
 *
 * TODO: usage of this component could be replaced with just an object {description: string, children?: React.ReactNode} and it would
 * probably be more understandable.
 */
export default class CarouselSlide extends React.Component<CarouselSlideProps> {
  render() {
    return this.props.children;
  }
}
