export const getMetricIgnoresPercentageContributionSetting = (metricKey: string): boolean => {
  return metricKey === 'allocation-VALUE' || metricKey === 'allocation-PERCENT';
};

export const isMetricAlwaysInPercentFormat = (metricKey: string): boolean | undefined => {
  if (!getMetricIgnoresPercentageContributionSetting(metricKey)) {
    return undefined;
  }

  switch (metricKey) {
    case 'allocation-PERCENT':
      return true;
    case 'allocation-VALUE':
      return false;
    default:
      return undefined;
  }
};
