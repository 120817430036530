import type { DrawdownRangeAnalysis, DrawdownFactorExposure, DrawdownPeriodAnalysis } from 'venn-api';
import type { AnalysisGroup } from '../../../analysis/types';

export const historicalDrawdownAnalysis: DrawdownRangeAnalysis = {
  benchmarkCashContribution: 0,
  benchmarkResidualContribution: undefined!,
  canAnalyzeBenchmark: false,
  canAnalyzeSecondary: false,
  cashContribution: 0,
  drawdownFactorInfoList: [
    {
      annualizedReturn: -0.06254783045220758,
      historicalAnnualizedReturn: 0.005209081046888109,
      factorId: 355,
      factorName: 'Equity',
      factorDeltaCorrelations: [
        {
          delta: -0.14886523936131785,
          histCorr: -0.3203806934323359,
          id: 356,
          name: 'Interest Rates',
          periodCorr: -0.46924593279365373,
        },
      ],
    },
    {
      annualizedReturn: -0.06254783045220758,
      historicalAnnualizedReturn: 0.005209081046888109,
      factorId: 356,
      factorName: 'Interest Rates',
      factorDeltaCorrelations: [],
    },
  ],
  portfolioDrawdown: [
    [1184630399999, -0.002756402495774579],
    [1184716799999, -0.0030977841388469818],
  ],
  errorDrawdown: [],
  factorExposureList: [],
  fundDrawdownContribution: [],
  lowerErrorDrawdown: [],
  secondaryCashContribution: undefined!,
  secondaryPortfolioDrawdown: undefined!,
  secondaryResidualContribution: undefined!,
  maxBenchmarkDrawdown: undefined!,
  maxBenchmarkDrawdownDate: undefined!,
  maxDrawdownDate: 1227225599999,
  maxDrawdown: -0.10556092380208182,
  maxSecondaryDrawdown: undefined!,
  maxSecondaryDrawdownDate: undefined!,
  portfolioAnalysis: undefined!,
  upperErrorDrawdown: [],
  strategyDrawdownContribution: [],
  residualContribution: undefined!,
  benchmarkDrawdown: undefined!,
};

export const drawdownPeriods = {
  subject: [
    {
      start: 1016668799999,
      trough: 1034207999999,
      end: 1101427199999,
      maxDrawdown: -0.1942002772730591,
      maxDrawdownError: 0.018001595023159227,
    },
    {
      start: 1147305599999,
      trough: 1150243199999,
      end: 1165276799999,
      maxDrawdown: -0.07109627401482477,
      maxDrawdownError: 0.007089690637238145,
    },
    {
      start: 1184630399999,
      trough: 1187308799999,
      end: 1191974399999,
      maxDrawdown: -0.0673151359051426,
      maxDrawdownError: 0.007147648129228197,
    },
    {
      start: 1193961599999,
      trough: 1236643199999,
      end: 1493164799999,
      maxDrawdown: -0.3358486995811004,
      maxDrawdownError: 0.028242219309500143,
    },
    {
      start: 1517270399999,
      trough: 1545782399999,
      end: 1554163199999,
      maxDrawdown: -0.13112162265587435,
      maxDrawdownError: 0.021992216740637536,
    },
  ],
  benchmark: [
    {
      start: 1055807999999,
      trough: 1091059199999,
      end: 1101427199999,
      maxDrawdown: -0.09528691600093164,
      maxDrawdownError: 0.010232403786896048,
    },
    {
      start: 1148428799999,
      trough: 1152575999999,
      end: 1157068799999,
      maxDrawdown: -0.01279837765657077,
      maxDrawdownError: 0.0033719349614071675,
    },
    {
      start: 1189555199999,
      trough: 1190332799999,
      end: 1191974399999,
      maxDrawdown: -0.014078290966958229,
      maxDrawdownError: 0.0015615588258680766,
    },
    {
      start: 1467849599999,
      trough: 1489449599999,
      end: 1493164799999,
      maxDrawdown: -0.05659778010708261,
      maxDrawdownError: 0.007736579068070888,
    },
    {
      start: 1517270399999,
      trough: 1542758399999,
      end: 1554163199999,
      maxDrawdown: -0.036204740658129286,
      maxDrawdownError: 0.008391164065830185,
    },
  ],
} as unknown as AnalysisGroup<DrawdownPeriodAnalysis[]>;

export const drawdownFactorExposures: DrawdownFactorExposure[] = [
  {
    factorId: 355,
    factorName: 'Equity',
    portfolioBeta: 0.679872183268229,
    secondaryPortfolioBeta: undefined!,
    benchmarkBeta: undefined!,
    contribution: 0.8994632159237756,
    benchmarkContribution: undefined!,
    secondaryContribution: undefined!,
  },
  {
    factorId: 356,
    factorName: 'Interest Rates',
    portfolioBeta: 0.34925707667517397,
    secondaryPortfolioBeta: undefined!,
    benchmarkBeta: undefined!,
    contribution: -0.09863524021358801,
    benchmarkContribution: undefined!,
    secondaryContribution: undefined!,
  },
  {
    factorId: 357,
    factorName: 'Credit',
    portfolioBeta: undefined!,
    secondaryPortfolioBeta: undefined!,
    benchmarkBeta: undefined!,
    contribution: undefined!,
    benchmarkContribution: undefined!,
    secondaryContribution: undefined!,
  },
  {
    factorId: 358,
    factorName: 'Commodities',
    portfolioBeta: 0.1077844865700803,
    secondaryPortfolioBeta: undefined!,
    benchmarkBeta: undefined!,
    contribution: -0.05120667709785785,
    benchmarkContribution: undefined!,
    secondaryContribution: undefined!,
  },
  {
    factorId: 359,
    factorName: 'Foreign Currency',
    portfolioBeta: 0.47490325254306054,
    secondaryPortfolioBeta: undefined!,
    benchmarkBeta: undefined!,
    contribution: 0.12655682930769196,
    benchmarkContribution: undefined!,
    secondaryContribution: undefined!,
  },
  {
    factorId: 360,
    factorName: 'Local Inflation',
    portfolioBeta: -0.03893873192197354,
    secondaryPortfolioBeta: undefined!,
    benchmarkBeta: undefined!,
    contribution: 0.02497633338842617,
    benchmarkContribution: undefined!,
    secondaryContribution: undefined!,
  },
  {
    factorId: 361,
    factorName: 'Emerging Markets',
    portfolioBeta: -0.0720332418208766,
    secondaryPortfolioBeta: undefined!,
    benchmarkBeta: undefined!,
    contribution: 0.010509942985013985,
    benchmarkContribution: undefined!,
    secondaryContribution: undefined!,
  },
  {
    factorId: 362,
    factorName: 'Equity Short Volatility',
    portfolioBeta: -0.15412797876784806,
    secondaryPortfolioBeta: undefined!,
    benchmarkBeta: undefined!,
    contribution: 0.0691849811959462,
    benchmarkContribution: undefined!,
    secondaryContribution: undefined!,
  },
];

export const sortedAscDrawdownFactorExposures: DrawdownFactorExposure[] = [
  {
    factorId: 362,
    factorName: 'Equity Short Volatility',
    portfolioBeta: -0.15412797876784806,
    secondaryPortfolioBeta: undefined!,
    benchmarkBeta: undefined!,
    contribution: 0.0691849811959462,
    benchmarkContribution: undefined!,
    secondaryContribution: undefined!,
  },
  {
    factorId: 361,
    factorName: 'Emerging Markets',
    portfolioBeta: -0.0720332418208766,
    secondaryPortfolioBeta: undefined!,
    benchmarkBeta: undefined!,
    contribution: 0.010509942985013985,
    benchmarkContribution: undefined!,
    secondaryContribution: undefined!,
  },
  {
    factorId: 360,
    factorName: 'Local Inflation',
    portfolioBeta: -0.03893873192197354,
    secondaryPortfolioBeta: undefined!,
    benchmarkBeta: undefined!,
    contribution: 0.02497633338842617,
    benchmarkContribution: undefined!,
    secondaryContribution: undefined!,
  },
  {
    factorId: 358,
    factorName: 'Commodities',
    portfolioBeta: 0.1077844865700803,
    secondaryPortfolioBeta: undefined!,
    benchmarkBeta: undefined!,
    contribution: -0.05120667709785785,
    benchmarkContribution: undefined!,
    secondaryContribution: undefined!,
  },
  {
    factorId: 356,
    factorName: 'Interest Rates',
    portfolioBeta: 0.34925707667517397,
    secondaryPortfolioBeta: undefined!,
    benchmarkBeta: undefined!,
    contribution: -0.09863524021358801,
    benchmarkContribution: undefined!,
    secondaryContribution: undefined!,
  },
  {
    factorId: 359,
    factorName: 'Foreign Currency',
    portfolioBeta: 0.47490325254306054,
    secondaryPortfolioBeta: undefined!,
    benchmarkBeta: undefined!,
    contribution: 0.12655682930769196,
    benchmarkContribution: undefined!,
    secondaryContribution: undefined!,
  },
  {
    factorId: 355,
    factorName: 'Equity',
    portfolioBeta: 0.679872183268229,
    secondaryPortfolioBeta: undefined!,
    benchmarkBeta: undefined!,
    contribution: 0.8994632159237756,
    benchmarkContribution: undefined!,
    secondaryContribution: undefined!,
  },
  {
    factorId: 357,
    factorName: 'Credit',
    portfolioBeta: undefined!,
    secondaryPortfolioBeta: undefined!,
    benchmarkBeta: undefined!,
    contribution: undefined!,
    benchmarkContribution: undefined!,
    secondaryContribution: undefined!,
  },
];

export const sortedDescDrawdownFactorExposures: DrawdownFactorExposure[] = [
  {
    factorId: 355,
    factorName: 'Equity',
    portfolioBeta: 0.679872183268229,
    secondaryPortfolioBeta: undefined!,
    benchmarkBeta: undefined!,
    contribution: 0.8994632159237756,
    benchmarkContribution: undefined!,
    secondaryContribution: undefined!,
  },
  {
    factorId: 359,
    factorName: 'Foreign Currency',
    portfolioBeta: 0.47490325254306054,
    secondaryPortfolioBeta: undefined!,
    benchmarkBeta: undefined!,
    contribution: 0.12655682930769196,
    benchmarkContribution: undefined!,
    secondaryContribution: undefined!,
  },
  {
    factorId: 356,
    factorName: 'Interest Rates',
    portfolioBeta: 0.34925707667517397,
    secondaryPortfolioBeta: undefined!,
    benchmarkBeta: undefined!,
    contribution: -0.09863524021358801,
    benchmarkContribution: undefined!,
    secondaryContribution: undefined!,
  },
  {
    factorId: 358,
    factorName: 'Commodities',
    portfolioBeta: 0.1077844865700803,
    secondaryPortfolioBeta: undefined!,
    benchmarkBeta: undefined!,
    contribution: -0.05120667709785785,
    benchmarkContribution: undefined!,
    secondaryContribution: undefined!,
  },
  {
    factorId: 360,
    factorName: 'Local Inflation',
    portfolioBeta: -0.03893873192197354,
    secondaryPortfolioBeta: undefined!,
    benchmarkBeta: undefined!,
    contribution: 0.02497633338842617,
    benchmarkContribution: undefined!,
    secondaryContribution: undefined!,
  },
  {
    factorId: 361,
    factorName: 'Emerging Markets',
    portfolioBeta: -0.0720332418208766,
    secondaryPortfolioBeta: undefined!,
    benchmarkBeta: undefined!,
    contribution: 0.010509942985013985,
    benchmarkContribution: undefined!,
    secondaryContribution: undefined!,
  },
  {
    factorId: 362,
    factorName: 'Equity Short Volatility',
    portfolioBeta: -0.15412797876784806,
    secondaryPortfolioBeta: undefined!,
    benchmarkBeta: undefined!,
    contribution: 0.0691849811959462,
    benchmarkContribution: undefined!,
    secondaryContribution: undefined!,
  },
  {
    factorId: 357,
    factorName: 'Credit',
    portfolioBeta: undefined!,
    secondaryPortfolioBeta: undefined!,
    benchmarkBeta: undefined!,
    contribution: undefined!,
    benchmarkContribution: undefined!,
    secondaryContribution: undefined!,
  },
];

export const drawdownFactorExposuresWithBenchmark = [
  {
    benchmarkBeta: 0.13530237550842472,
    benchmarkContribution: 0.8788607185419972,
    contribution: 0.8780428482396703,
    factorId: 10000,
    factorName: 'Equity',
    portfolioBeta: 0.19009357530822876,
  },
  {
    benchmarkBeta: null,
    benchmarkContribution: 0,
    contribution: 0,
    factorId: 10001,
    factorName: 'Interest Rates',
    portfolioBeta: null,
  },
  {
    benchmarkBeta: 0.10287992512464474,
    benchmarkContribution: 0.06313511016268743,
    contribution: -0.0261512300946121,
    factorId: 10002,
    factorName: 'Credit',
    portfolioBeta: 0.07744768553966787,
  },
];
export default {
  historicalDrawdownAnalysis,
  drawdownPeriods,
  drawdownFactorExposures,
  sortedAscDrawdownFactorExposures,
  sortedDescDrawdownFactorExposures,
  drawdownFactorExposuresWithBenchmark,
};
