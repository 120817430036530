import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { GetColor, getTextThemeProvider, Icon } from 'venn-ui-kit';
import { analyticsService, logExceptionIntoSentry, UrlParam } from 'venn-utils';
import { ReleaseNotesModal, UserContext, useUrlParamState } from 'venn-components';
import { getMostRecentReleaseNoteDate, LAST_READ_RELEASE_NOTE_KEY } from 'venn-api';
import ShellNavigationLink from './ShellNavigationLink';
import type { NavigationSubItem } from './shellNavigationConfigInitializer';
import styled from 'styled-components';
import moment from 'moment';

const HelpCenterDropdown = ({ showTopNav }: { showTopNav?: boolean }) => {
  const isMounted = useRef(false);

  const [releaseNotesOpen, setReleaseNotesOpen] = useUrlParamState(UrlParam.RELEASE_NOTES);
  const [mostRecentNote, setMostRecentNote] = useState<number | undefined>(undefined);
  const { updateSettings, settings } = useContext(UserContext);

  const lastReadNote = settings?.user?.[LAST_READ_RELEASE_NOTE_KEY]
    ? moment.utc(settings?.user?.[LAST_READ_RELEASE_NOTE_KEY]).valueOf()
    : 0;
  const newNoteNotification = mostRecentNote !== undefined && lastReadNote < mostRecentNote;

  const closeReleaseNotes = () => {
    setReleaseNotesOpen(undefined);
  };

  useEffect(() => {
    isMounted.current = true;
    const getMostRecentNote = async () => {
      try {
        const response = await getMostRecentReleaseNoteDate();
        if (isMounted.current) {
          setMostRecentNote(response.content);
        }
      } catch (e) {
        logExceptionIntoSentry(e);
      }
    };

    getMostRecentNote();
    return () => {
      isMounted.current = false;
    };
  }, []);

  const onReleaseNotesClick = useCallback(() => {
    setReleaseNotesOpen(true);
    updateSettings({
      [LAST_READ_RELEASE_NOTE_KEY]: moment.utc().valueOf(),
    });
  }, [updateSettings, setReleaseNotesOpen]);

  const subItems: NavigationSubItem[] = useMemo(
    () => [
      {
        label: "What's New?",
        onClick: onReleaseNotesClick,
        notification: newNoteNotification,
        className: 'qa-what-is-new',
      },
      {
        label: 'Help Articles',
        path: `https://${getTextThemeProvider().HelpCenterUrl}`,
        className: 'qa-help-center',
        onClick: () => {
          analyticsService.navigationTriggered({
            location: 'navigation bar',
            itemType: 'link',
            userIntent: 'navigate',
            destinationPageTitle: 'Help',
          });
        },
      },
    ],
    [newNoteNotification, onReleaseNotesClick],
  );

  return (
    <HelpWrapper>
      <ShellNavigationLink
        navId="help"
        subItems={subItems}
        icon="question-circle"
        iconComponent={newNoteNotification ? <NotificationIcon type="circle" /> : undefined}
        label="Help"
        showTopNav={showTopNav}
        subItemsHeader="Help Center"
        hideLabel={showTopNav}
        iconFontSize={showTopNav ? 20 : 24}
        tooltipContent="Help"
      />
      {releaseNotesOpen && <ReleaseNotesModal onClose={closeReleaseNotes} />}
    </HelpWrapper>
  );
};

export default HelpCenterDropdown;

const HelpWrapper = styled.div`
  position: relative;
`;

const NotificationIcon = styled(Icon)`
  color: ${GetColor.Primary.Main};
  position: absolute;
  right: calc(50% - 1.4em);
  top: calc(50% - 1em);
`;
