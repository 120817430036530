import { isLongEnough } from './PasswordChecks';

import { isPersonalEmail } from './EmailChecks';
import { getAppTitle } from 'venn-ui-kit';

export const MINIMUM_PASSWORD_LENGTH = 9;

const ERROR_MESSAGES = {
  SHORT_PASSWORD: `must be at least ${MINIMUM_PASSWORD_LENGTH} characters long`,
  PERSONAL_EMAIL: `Personal email domains are not permitted on ${getAppTitle()} at this time. Please use your work email.`,
};

export const getPasswordErrors = (password: string) => {
  const errorMessages: string[] = [];

  if (!isLongEnough(password, MINIMUM_PASSWORD_LENGTH)) {
    errorMessages.push(ERROR_MESSAGES.SHORT_PASSWORD);
  }

  return errorMessages;
};

export const getCreatePasswordErrors = (password: string) => {
  const errorMessages: string[] = [];

  if (!isLongEnough(password, MINIMUM_PASSWORD_LENGTH)) {
    errorMessages.push(ERROR_MESSAGES.SHORT_PASSWORD);
  }

  return errorMessages;
};

export const validateEmail = (email: string) => {
  const errorMessages: string[] = [];

  if (isPersonalEmail(email)) {
    errorMessages.push(ERROR_MESSAGES.PERSONAL_EMAIL);
  }

  const parts = email ? email.split('@') : null;
  if (parts && (parts.length !== 2 || parts.some((part) => part.length < 1))) {
    errorMessages.push('Must provide a valid email address.');
  }

  return errorMessages;
};
