import React from 'react';
import styled, { css } from 'styled-components';
import { Label, ColorUtils, GetColor } from 'venn-ui-kit';

export interface ContentChartLegendItem {
  name: string;
  color: string;
  estimated?: boolean;
  dashLine?: boolean;
}

interface ContentChartLegendProps {
  items: ContentChartLegendItem[];
  histogram?: boolean;
  hideBorder?: boolean;
}

const ContentChartLegend: React.FC<React.PropsWithChildren<ContentChartLegendProps>> = ({
  items,
  histogram,
  hideBorder,
}) => {
  const renderLegendBox = (item: ContentChartLegendItem) => {
    if (item.estimated) {
      return (
        <EstimatedBox color={item.color}>
          <div />
        </EstimatedBox>
      );
    }
    if (item.dashLine) {
      return (
        <DashLineWrapper>
          <DashLine color={item.color} />
          <DashLine color={item.color} />
          <DashLine color={item.color} />
        </DashLineWrapper>
      );
    }
    if (histogram) {
      return <Box color={item.color} />;
    }
    return <Line color={item.color} />;
  };
  return (
    <Container hideBorder={!!hideBorder}>
      {items.map((item) => (
        <Item key={item.name} className="qa-legend-item">
          {renderLegendBox(item)}
          <Label>{item.name}</Label>
        </Item>
      ))}
    </Container>
  );
};

export default ContentChartLegend;

const Container = styled.div<{ hideBorder: boolean }>`
  display: flex;
  align-items: center;
  height: 50px;
  ${(props) =>
    !props.hideBorder &&
    css`
      border-top: 1px solid ${GetColor.PaleGrey};
      border-bottom: 1px solid ${GetColor.PaleGrey};
    `}

  padding-left: 20px;
  @media print {
    height: 30px;
    padding-left: 10px;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`;

interface BoxProps {
  color: string;
}

const Box = styled.div<BoxProps>`
  background-color: ${(props) => props.color};
  height: 10px;
  width: 14px;
  margin-right: 3px;
`;

const Line = styled(Box)`
  height: 2px;
`;

const EstimatedBox = styled(Box)`
  background-color: ${(props) => ColorUtils.hex2rgba(props.color, 0.1)};
  border: thin solid ${(props) => ColorUtils.hex2rgba(props.color, 0.2)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  > div {
    height: 2px;
    border: 1px dashed ${(props) => props.color};
  }
`;

const DashLine = styled(Box)`
  height: 2px;
  width: 4px;
  margin-right: 2px;
`;

const DashLineWrapper = styled.div`
  margin-right: 3px;
  display: flex;
`;
