import React from 'react';
import styled from 'styled-components';
import { GetColor, Icon } from 'venn-ui-kit';

interface PaginationProps {
  current: number;
  count: number;
  onChange: (index: number) => void;
}

const Pagination = ({ current, count, onChange }: PaginationProps) => {
  return (
    <Container>
      <ChevronIcon disabled={current < 1} type="chevron-left" onClick={() => onChange(0)} />
      {new Array(count).fill(null).map((_, index) => (
        <CicleIcon
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          type="circle"
          prefix={current === index ? 'fas' : 'far'}
          onClick={() => onChange(index)}
        />
      ))}
      <ChevronIcon disabled={current >= count - 1} type="chevron-right" onClick={() => onChange(count - 1)} />
    </Container>
  );
};

const Container = styled.div`
  height: 50px;
  background-color: ${GetColor.PaleGrey};
  display: flex;
  justify-content: center;
`;

const CicleIcon = styled(Icon)`
  color: ${GetColor.Black};
  margin: 0 7px;
`;

const ChevronIcon = styled(Icon)<{ disabled: boolean }>`
  margin: 0 7px;
  color: ${(props) => (props.disabled ? GetColor.MidGrey1 : GetColor.Primary.Dark)};
`;

export default Pagination;
