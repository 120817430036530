import React from 'react';
import styled from 'styled-components';
import { Headline3, Hint, EmptyStateIcon } from 'venn-ui-kit';
import { LinkButton } from './StyledComponents';

interface EmptyStateProps {
  onClear: () => void;
  canClearQuery: boolean;
  canClearFilters: boolean;
}

const EmptyStateV2 = ({ onClear, canClearQuery, canClearFilters }: EmptyStateProps) => {
  let action = 'Reset all filters';
  // We should never reach the empty state without one of canClearQuery and canClearFilters being true
  // because there will always be Venn system investments.
  if (canClearQuery && canClearFilters) {
    action += ' and clear query';
  } else if (canClearQuery) {
    action = 'Clear query';
  }

  return (
    <Wrapper>
      <EmptyStateIcon />
      <Headline3>No items found</Headline3>
      <Hint data-testid="qa-empty-state">
        <LinkButton className="qa-clear-filters" data-testid="qa-clear-filters" onClick={onClear}>
          {action}
        </LinkButton>{' '}
        to search your entire library
      </Hint>
    </Wrapper>
  );
};

export default EmptyStateV2;

const Wrapper = styled.div`
  text-align: center;
  margin-top: 60px;
  font-weight: bold;
  h3 {
    text-align: center;
  }
`;
