import type React from 'react';
import { useEffect, useState } from 'react';

export const useSyncedState = <T>(valueToSync: T): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [state, setState] = useState<T>(valueToSync);

  useEffect(() => {
    setState(valueToSync);
  }, [valueToSync]);

  return [state, setState];
};
