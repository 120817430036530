import React, { useState, useRef, useCallback, useMemo, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { formatValue } from './utils';
import type { ChartType } from './chart/Chart';
import { Consumer } from './context';
import { Modal, ModalSubhead, ModalFooter, ModalSize } from '../../modal';
import { SorTable as BaseSorTable, RowFirst, RowNext, CellData, CellHeader, SortDirection } from './sortable/SorTable';
import Bar from '../../bar/Bar';
import type { AnalysisSubject, ExcelCell } from 'venn-utils';
import { Numbers, SpecialCssClasses } from 'venn-utils';
import Notification from '../../notification/Notification';
import { Severity as NotificationSeverityType } from '../../notification/Types';
import { Toggle, ToggleOption } from '../../toggle';
import Downloadable from '../../downloadable/Downloadable';
import type { VennColors } from 'venn-ui-kit';
import { GetColor, Loading } from 'venn-ui-kit';
import { BlockByolWatermark } from '../../byol-watermark';
import { AnalysisBlockWatermark } from '../../block-watermark/BlockWatermark';

type TableConfig = 'name' | 'contribution' | 'type' | 'weight';
export type DataType = {
  name: string;
  contribution: number;
  unweightedExposure: number;
  weight: number;
}[];

const qaClasses = {
  toggle: 'qa-toggle-options',
  close: 'qa-close-button',
};

interface FactorModalProps {
  name: string;
  title: string;
  explanation: JSX.Element;
  loading: boolean;
  usePercentage?: boolean;
  error?: string;
  type: ChartType;
  fundsData: DataType;
  strategiesData: DataType;
  subject: AnalysisSubject;
}

enum TableColumnName {
  Name = 'name',
  Contribution = 'contribution',
  Type = 'type',
  Weight = 'weight',
}

const createData = (data: DataType, colors: VennColors, usePercentage?: boolean) => {
  const maxContribution = data.reduce((memo, ddc) => Math.max(memo, Math.abs(ddc.contribution)), 0);

  const tableData = data.map((row) => ({
    [TableColumnName.Name]: {
      content: <FundName>{row.name}</FundName>,
      value: row.name,
    },
    [TableColumnName.Contribution]: {
      content: (
        <ContributionWrapper>
          <StyledBar
            value={(row.contribution * 100) / maxContribution}
            barHeight={15}
            barWidth={176}
            barColor={colors.DataBarColor.LightGold}
            centerLine
          />
          <ContributionValue>{Numbers.safeFormatPercentage(row.contribution)}</ContributionValue>
        </ContributionWrapper>
      ),
      value: row.contribution,
    },
    [TableColumnName.Type]: {
      content: <Values>{formatValue(row.unweightedExposure, !!usePercentage)}</Values>,
      value: row.unweightedExposure,
    },
    [TableColumnName.Weight]: {
      content: <Values>{Numbers.safeFormatPercentage(row.weight)}</Values>,
      value: row.weight,
    },
  }));
  const result = BaseSorTable.preSort<TableConfig>(tableData, {
    name: 'string',
    contribution: 'number',
    type: 'number',
    weight: 'number',
  });
  return result;
};

export const TabNames = {
  funds: 'funds',
  strategies: 'strategies',
};

const FactorModal = ({
  loading,
  title,
  explanation,
  fundsData,
  name,
  type,
  usePercentage,
  error,
  strategiesData,
  subject,
}: FactorModalProps) => {
  const { Colors } = useContext(ThemeContext);
  const modalContentRef = useRef<HTMLDivElement>(null);
  const [activeTab, setActiveTab] = useState(TabNames.funds);
  const handleTabChange = useCallback((n: string) => {
    setActiveTab(TabNames[n]);
  }, []);
  const displayFunds = activeTab === TabNames.funds;
  const excelData = useMemo(
    () => getExcelData(displayFunds, !!usePercentage, name, type, displayFunds ? fundsData : strategiesData),
    [usePercentage, fundsData, strategiesData, displayFunds, name, type],
  );

  return (
    <Consumer>
      {({ closeModal }) => (
        <Modal onClose={closeModal} size={ModalSize.XLarge}>
          <div ref={modalContentRef}>
            <HeaderWrapper>
              <ModalSubhead extraPadding>{title}</ModalSubhead>
              <Downloadable
                png
                excel={excelData}
                tracking={{
                  description: 'FACTOR_CONTRIBUTION_MODAL',
                  relativeToBenchmark: false,
                  subjectType: 'factor',
                  subjectId: undefined,
                  userUploaded: false,
                }}
                options={{
                  fileName: `${title} - ${subject.name}`,
                }}
                target={modalContentRef}
              />
            </HeaderWrapper>
            <ExplanationWrapper>
              {explanation}
              {strategiesData.length > 0 && (
                <Toggle
                  className={`${qaClasses.toggle} ${SpecialCssClasses.NotDownloadable}`}
                  value={activeTab}
                  onChange={handleTabChange}
                >
                  <ToggleOption value={TabNames.funds}>Investments</ToggleOption>

                  <ToggleOption value={TabNames.strategies} width={110}>
                    Strategies
                  </ToggleOption>
                </Toggle>
              )}
            </ExplanationWrapper>
            <div>
              {loading ? (
                <Loading />
              ) : (
                !error && (
                  <SorTable
                    columns={[
                      {
                        key: TableColumnName.Name,
                        width: '355px',
                        label: activeTab === TabNames.funds ? 'Investment Name' : 'Strategy Name',
                      },
                      {
                        key: TableColumnName.Contribution,
                        width: '285px',
                        label: `Contribution to ${name}`,
                      },
                      {
                        key: TableColumnName.Type,
                        width: '120px',
                        label: type,
                      },
                      {
                        key: TableColumnName.Weight,
                        width: '80px',
                        label: 'Weight',
                      },
                    ]}
                  >
                    {createData(activeTab === TabNames.funds ? fundsData : strategiesData, Colors, usePercentage)}
                  </SorTable>
                )
              )}
              {error && <Notification severity={NotificationSeverityType.Error}>{error}</Notification>}
            </div>
            <BlockByolWatermark />
            <AnalysisBlockWatermark />
          </div>
          <ModalFooter secondaryLabel="Close" secondaryClassName={qaClasses.close} onSecondaryClick={closeModal} />
        </Modal>
      )}
    </Consumer>
  );
};

function getExcelData(
  displayFunds: boolean,
  usePercentage: boolean,
  name: string,
  type: string,
  data: DataType,
): ExcelCell[][] | undefined {
  if (!data || !data.length) {
    return undefined;
  }
  return [
    [
      { value: `${displayFunds ? 'Investment' : 'Strategy'} Name`, bold: true },
      { value: `Contribution to ${name}`, bold: true },
      { value: type, bold: true },
      { value: 'Weight', bold: true },
    ],
    ...data.map(
      (item) =>
        [
          { value: item.name },
          { value: item.contribution, percentage: true, digits: 2 },
          { value: item.unweightedExposure, percentage: usePercentage, digits: 2 },
          { value: item.weight, percentage: true, digits: 2 },
        ] as ExcelCell[],
    ),
  ];
}

const ExplanationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 0 30px;
`;

const Values = styled.div`
  text-align: right;
`;

const SorTable = styled(BaseSorTable)`
  border-bottom: 1px solid #ececec;
  ${RowFirst} {
    padding: 0 30px;
    border-bottom: 1px solid ${GetColor.Black};
  }

  ${SortDirection} {
    overflow: auto;
    max-height: 350px;
  }

  ${CellData} {
    padding-left: 0;
    padding-right: 0;
    font-size: 14px;

    &:nth-child(3),
    &:nth-child(4) {
      justify-content: flex-end;
    }
  }

  ${CellHeader} {
    font-size: 12px;
    padding-left: 0;
    padding-right: 0;
    color: ${GetColor.DarkGrey};

    &:nth-child(3),
    &:nth-child(4) {
      margin-left: auto;
      justify-content: flex-end;
    }
  }

  ${RowNext} {
    min-height: 36px;
    border-top: 1px solid #ececec;
    border-bottom: 0;
    margin-left: 30px;
    margin-right: 30px;
    padding: 0;
    > div {
      line-height: 1.6rem;
    }
  }
`;

const ContributionValue = styled.span`
  color: ${GetColor.DataBarColor.LightGold};
`;

const ContributionWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  text-align: right;
`;

const StyledBar = styled(Bar)`
  height: 15px;
  border: 1px solid ${GetColor.Grey};
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
`;

const FundName = styled.div`
  padding-right: 10px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 340px;
`;

export default FactorModal;
