import React, { useCallback } from 'react';
import type { DropMenuItem } from 'venn-ui-kit';
import { DropMenu, Icon } from 'venn-ui-kit';
import styled from 'styled-components';

interface RiskPercentageDropDownProps {
  selected?: number;
  onSelectRiskPercentage: (item: number[]) => void;
  /** Callback to trigger when visibility is changed.
   * If undefined, the visibility icon will be removed, and only the dropdown will appear */
  onSelectVisibility?: (item: boolean[]) => void;
  index: number;
  riskPercentages: number[];
  visibility: boolean[];
  visible?: boolean;
  items: DropMenuItem<number>[];
  disabled?: boolean;
}

const RiskPercentageDropDown = ({
  selected,
  onSelectRiskPercentage,
  onSelectVisibility,
  items,
  index,
  visible,
  riskPercentages,
  visibility,
  disabled,
}: RiskPercentageDropDownProps) => {
  const iconClass = visible ? 'eye' : 'eye-slash';

  const onUpdateRiskPercentage = (risk: DropMenuItem<number>) => {
    onSelectRiskPercentage(
      riskPercentages.map((value, idx) => {
        if (idx === index) {
          return risk.value;
        }
        return value;
      }),
    );
  };

  const onToggleVisibility = useCallback(() => {
    onSelectVisibility &&
      onSelectVisibility(
        visibility.map((value, idx) => {
          if (idx === index) {
            return !visible;
          }
          return value;
        }),
      );
  }, [onSelectVisibility, visibility, visible, index]);

  return (
    <RiskDropDownWrapper className="qa-risk-dropdown">
      <DropMenu
        items={items}
        selected={selected}
        className="years-projected-selector"
        width={70}
        onChange={onUpdateRiskPercentage}
        disabled={disabled ?? false}
      />
      {!disabled && onSelectVisibility && (
        <ToggleVisibilityButton className="hide-risk-button" onClick={onToggleVisibility}>
          <Icon type={iconClass} />
        </ToggleVisibilityButton>
      )}
    </RiskDropDownWrapper>
  );
};

export default RiskPercentageDropDown;

const ToggleVisibilityButton = styled.button`
  font-size: 14px;
`;

const RiskDropDownWrapper = styled.div`
  display: flex;
  column-gap: 10px;
  margin-bottom: 5px;
`;
