import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';

const SaveTab = styled.div`
  color: ${GetColor.Black};
  background-color: ${GetColor.White};
  font-weight: bold;
  font-size: 14px;
  height: 100%;
  width: 100%;
  border-bottom: 1px solid ${GetColor.Grey};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  text-align: left;
  padding-left: 30px;
`;

export default SaveTab;
