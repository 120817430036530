import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import type { CellRendererType, TabularDataRow, TabularDataType } from './types';
import NumericSpan from '../../../../numeric-span/NumericSpan';

export const pairwiseCorrelationCellRenderer: CellRendererType =
  (colNum: number, colOffset = 0, _overrideType?: TabularDataType) =>
  // TODO(VENN-24534): add a display name to this React component
  // eslint-disable-next-line react/display-name
  (rowData: TabularDataRow, rowNum: number) => {
    if (colNum > rowNum) {
      // For correlation tables we only want to show the lower left half of the matrix.
      return null;
    }
    const col = rowData[colOffset + colNum];
    if (!col || !col.value) {
      return '--';
    }
    return <CorrelationCell value={col.value as number} />;
  };

const CorrelationCell = ({ value }: { value: number }) => {
  const { Colors } = useContext(ThemeContext);
  if (value === null || value === undefined) {
    return null;
  }

  let color;
  const colorScale = Object.values(Colors.DivergingColor);
  const colorIdx = Math.floor(((value + 1) / 2) * colorScale.length);
  if (colorIdx !== colorScale.length) {
    color = colorScale[colorIdx];
  } else {
    color = colorScale[colorScale.length - 1];
  }

  const textColor = value > 0.5 || value < -0.5 ? Colors.White : Colors.Black;
  return (
    <CorrelationCellWrapper color={color} textColor={textColor}>
      <NumericSpan className="correlation-value">{value}</NumericSpan>
    </CorrelationCellWrapper>
  );
};

const CorrelationCellWrapper = styled.div<{ color: string; textColor: string }>`
  background-color: ${(props) => props.color};
  color: ${(props) => props.textColor};
  height: 100%;
  padding: 0px;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
`;
