import React from 'react';
import styled from 'styled-components';
import { CellLoader } from '../../cell-loader/CellLoader';
import { GetColor } from '../../../style/color';

interface TemplateMenuItemProps {
  label: React.ReactNode;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  disabled?: boolean;
  loading?: boolean;
}

const FullWidthMenuItemWrapper = styled.span<{ disabled?: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  white-space: nowrap;
  ${(props) =>
    props.disabled &&
    `cursor: not-allowed;
    color: ${GetColor.Grey};`};
`;

const FullWidthMenuItem = ({ label, onClick, disabled, loading }: TemplateMenuItemProps) => (
  <FullWidthMenuItemWrapper onClick={onClick} disabled={disabled || loading}>
    <span>
      {label}
      {loading && <CellLoader />}
    </span>
  </FullWidthMenuItemWrapper>
);

export default FullWidthMenuItem;
