import type { FC } from 'react';
import React from 'react';
import { getCurrentTheme } from '../getCurrentTheme';
import { BrandLogo as DefaultBrandLogo } from '../../default/BrandLogo';
import type { BrandLogoProps } from './BrandLogoProps';

const GreenBrandLogo = (
  process.env.USE_GREEN === 'true' || process.env.NODE_ENV === 'test'
    ? require('../../green/BrandLogo').default
    : require('../../default/BrandLogo').default
) as FC<BrandLogoProps>;

export const BrandLogo = (props: BrandLogoProps) => {
  const Logo = getCurrentTheme() !== 'default' ? GreenBrandLogo : DefaultBrandLogo;

  return <Logo {...props} />;
};
