import type { ReactNode } from 'react';
import React from 'react';
import styled from 'styled-components';
import type { DropMenuCheckboxItem, MenuContainerProps } from '../types';
import BaseMenu from './BaseMenu';
import CheckboxMenuItem from './CheckboxMenuItem';
import CheckboxMenuSelectAll from './CheckboxMenuSelectAll';
import CheckboxMenuHeader from './CheckboxMenuHeader';
import { checkHierarchyItem } from '../logic/checkbox-logic';

export interface CheckboxMenuProps<T = string> extends MenuContainerProps {
  /**
   * Array of dropmenu items.
   */
  items: DropMenuCheckboxItem<T>[];
  /**
   * If provided, displays a header on the menu containing this text.
   */
  header?: ReactNode;
  /**
   * On each change, fires and returns the **entire** set of items with the modification(s)
   */
  onChange: (items: DropMenuCheckboxItem<T>[]) => void;
  onCollapse?: () => void;
  hideSelectAll?: boolean;
  ignoreHierarchySelect?: boolean;
  showHierarchyLines?: boolean;
  headerHeight?: number;
  /**
   * Allow selecting up to 1 item. Hide checkboxes & "Only" buttons.
   */
  singleSelection?: boolean;
  /** Extra prefix text to show to indicate selected metrics. */
  // Eslint thinks this is unused for some reason, but it is in use.
  selectedMetricText?: string;
  footerComponent?: JSX.Element;
  /** Indicate the item should take full width */
  fullWidth?: boolean;

  tooltipPortal?: boolean;
}

interface CheckboxMenuState {
  allItemsSelected: boolean;
}

class CheckboxMenu<T> extends React.PureComponent<CheckboxMenuProps<T>, CheckboxMenuState> {
  state: CheckboxMenuState = {
    allItemsSelected: false,
  };

  componentDidMount() {
    this.setState({ allItemsSelected: this.checkIfAllFiltersAreSelected(this.props.items) });
  }

  onCheckOne = (item: DropMenuCheckboxItem<T>) => () => {
    const { items: allItems, ignoreHierarchySelect } = this.props;
    const modifiedItems = ignoreHierarchySelect
      ? allItems.map((i) => ({
          ...i,
          checked: item.value === i.value ? !i.checked : i.checked,
        }))
      : checkHierarchyItem(allItems, item);
    this.setState({
      allItemsSelected: this.checkIfAllFiltersAreSelected(modifiedItems),
    });
    this.props.onChange(modifiedItems);
  };

  onCheckOnly = (item: DropMenuCheckboxItem<T>) => () => {
    const { items: allItems, ignoreHierarchySelect } = this.props;

    // Uncheck everything first, then check the one
    const modifiedItems = ignoreHierarchySelect
      ? allItems.map((i) => ({
          ...i,
          checked: item.value === i.value,
        }))
      : checkHierarchyItem(
          allItems.map((i) => ({
            ...i,
            checked: false,
          })),
          {
            ...item,
            checked: false,
          },
        );
    this.props.onChange(modifiedItems);
    this.props.onCollapse && this.props.onCollapse();
    this.setState({
      allItemsSelected: false,
    });
  };

  onSelectAll = () => {
    const allItems = this.props.items;
    const { allItemsSelected } = this.state;

    const modifiedItems = allItems.map((i) => ({
      ...i,
      checked: !allItemsSelected,
    }));
    this.props.onChange(modifiedItems);
    this.setState({
      allItemsSelected: !allItemsSelected,
    });
  };

  checkIfAllFiltersAreSelected = (items: DropMenuCheckboxItem<T>[]) => {
    // TODO(VENN-20115): remove eslint-disable if it is safe to do so
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare
    return items.filter((item) => item.checked === false).length === 0;
  };

  render() {
    const {
      items,
      width,
      height,
      header,
      hideSelectAll,
      showHierarchyLines,
      headerHeight,
      singleSelection,
      footerComponent,
      fullWidth,
      tooltipPortal,
    } = this.props;
    const { allItemsSelected } = this.state;
    return (
      <BaseMenu<T, DropMenuCheckboxItem<T>>
        items={items}
        width={width}
        height={height || 500}
        footerComponent={footerComponent}
        headerComponent={
          <>
            {header && (
              <CheckboxMenuHeader text={typeof header === 'string' ? header : undefined} height={headerHeight}>
                {typeof header !== 'string' ? header : null}
              </CheckboxMenuHeader>
            )}
            {!hideSelectAll && !singleSelection && (
              <CheckboxMenuSelectAll onClick={this.onSelectAll} allItemsSelected={allItemsSelected} />
            )}
          </>
        }
        renderItem={(item, index) => (
          <CheckBoxMenuPadding key={index}>
            <CheckboxMenuItem<T>
              item={item}
              onCheck={this.onCheckOne(item)}
              onOnly={this.onCheckOnly(item)}
              showLines={showHierarchyLines}
              maxWidth={fullWidth ? undefined : width ?? 200}
              hideCheckbox={singleSelection}
              isFirst={index === 0}
              tooltipPortal={tooltipPortal}
            />
          </CheckBoxMenuPadding>
        )}
      />
    );
  }
}

const CheckBoxMenuPadding = styled.div`
  padding: 3px 0;
`;

export default CheckboxMenu;
