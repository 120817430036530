import { isNil } from 'lodash';
import React, { useState } from 'react';
import { Controller, type RegisterOptions, type SubmitHandler, useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { type CashFlowSetting, type PrivateFundAssetClassEnum } from 'venn-api';
import { editedCashflowSettingAtom } from 'venn-state';
import { Button, DropMenu, type DropMenuItem, GetColor, Icon, Loading, Tooltip, TooltipPosition } from 'venn-ui-kit';
import { assertNotNil, Dates, withSuspense } from 'venn-utils';
import { CashflowSettingParametersInnerComponent } from './CashflowSettingInfo';
import {
  ErrorText,
  Footer,
  MainEditorContainer,
  SettingNameContainer,
  SettingNameInput,
  WarningContainer,
} from './common';
import { ALL_ASSET_CLASSES, ALL_STRATEGIES, ALL_VINTAGES, useSystemSettingsTree } from './logic/useSystemSettingsTree';

type ClassificationOverrideFormProps = Readonly<{
  onSubmit: SubmitHandler<CashFlowSetting>;
  nameOptions: RegisterOptions<FormData, 'name'>;
  isCreatingNew: boolean;
  systemSetting: CashFlowSetting;
  onEditPanelClose: () => void;
}>;

type FormData = {
  name: string;
  assetClass: PrivateFundAssetClassEnum | typeof ALL_ASSET_CLASSES;
  strategyName: string | null;
  vintage: number | null;
};

const FULL_ROTATION_DEGREES = 360;

const isFallbackOption = <T,>(value: T) => {
  return value === ALL_ASSET_CLASSES || value === ALL_STRATEGIES || value === ALL_VINTAGES;
};

// Sort options alphabetically and put fallback option last
const sortDropMenuItems = <T,>(items: DropMenuItem<T>[]) => {
  return [...items].sort((a, b) => {
    if (isFallbackOption(a.value)) {
      return 1;
    }
    if (isFallbackOption(b.value)) {
      return -1;
    }
    return a.label.localeCompare(b.label);
  });
};

const ClassificationOverrideForm = ({
  onSubmit,
  nameOptions,
  isCreatingNew,
  systemSetting,
  onEditPanelClose,
}: ClassificationOverrideFormProps) => {
  const tree = useSystemSettingsTree();

  const editedCashflowSetting = useRecoilValue(editedCashflowSettingAtom);

  const {
    watch,
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty, isValid },
  } = useForm<FormData>({
    mode: 'all',
    defaultValues: {
      name: editedCashflowSetting?.name,
      assetClass: editedCashflowSetting?.assetClass ?? ALL_ASSET_CLASSES,
      strategyName: editedCashflowSetting?.strategyName ?? ALL_STRATEGIES,
      vintage: editedCashflowSetting?.vintage ?? ALL_VINTAGES,
    },
  });

  const [assetClass, strategyName, vintage] = watch(['assetClass', 'strategyName', 'vintage']);

  const strategies = tree.get(assetClass)?.strategies;
  const vintages = isNil(strategyName) ? undefined : strategies?.get(strategyName)?.vintages;

  const previewSetting = isNil(strategyName) || isNil(vintage) ? undefined : vintages?.get(vintage)?.setting;

  const [spinRotation, setSpinRotation] = useState<number>(0);

  const spinIcon = () => setSpinRotation((spinRotation) => spinRotation + FULL_ROTATION_DEGREES * 2);

  return (
    <>
      <Layout>
        <Form
          onSubmit={handleSubmit((formData) => {
            onSubmit({
              ...assertNotNil(previewSetting),
              settingId: editedCashflowSetting?.settingId,
              overrideType: 'CLASSIFICATION',
              userCreated: true,
              owner: undefined,
              name: formData.name,
              assetClass: formData.assetClass === ALL_ASSET_CLASSES ? undefined : formData.assetClass,
              strategyName: formData.strategyName === ALL_STRATEGIES ? undefined : assertNotNil(formData.strategyName),
              vintage: formData.vintage === ALL_VINTAGES ? undefined : assertNotNil(formData.vintage),
            });
          })}
        >
          <MainEditorContainer>
            <SettingNameContainer>
              <b>Setting Name</b>
              <Controller
                control={control}
                name="name"
                render={({ field: { value } }) => (
                  <SettingNameInput
                    data-testid="setting-name-input"
                    value={value}
                    className="qa-setting-name-input"
                    {...register('name', nameOptions)}
                  />
                )}
              />
              {errors.name && <ErrorText>{errors.name.message}</ErrorText>}
            </SettingNameContainer>
            <div>
              <Controller
                control={control}
                name="assetClass"
                render={({ field }) => (
                  <DropMenu
                    data-testid="asset-class-selector"
                    label="Asset Class"
                    rightLabel={`Venn Default: ${tree.get(systemSetting.assetClass ?? ALL_ASSET_CLASSES)?.name}`}
                    highlight={assetClass !== (editedCashflowSetting?.assetClass ?? ALL_ASSET_CLASSES)}
                    searchable
                    items={sortDropMenuItems(
                      Array.from(tree.values()).map((assetClass) => ({
                        value: assetClass.value as string,
                        label: assetClass.name,
                        style: assetClass.value === ALL_ASSET_CLASSES ? { fontStyle: 'italic' } : undefined,
                      })),
                    )}
                    selected={field.value}
                    onChange={(setting: DropMenuItem<string>) => {
                      field.onChange(setting.value);
                      setValue('strategyName', null, { shouldValidate: true });
                      spinIcon();
                    }}
                    disabled={false}
                    minimumItemsToTrigger={1}
                  />
                )}
              />
            </div>
            <div>
              <Controller
                control={control}
                name="strategyName"
                rules={{
                  required: 'Strategy is required',
                }}
                render={({ field }) => {
                  return (
                    <DropMenu<string | null>
                      data-testid="strategy-selector"
                      label="Strategy"
                      rightLabel={`Venn Default: ${systemSetting.strategyName ?? 'All Strategies'}`}
                      placeholder="Select a strategy"
                      highlight={strategyName !== (editedCashflowSetting?.strategyName ?? ALL_STRATEGIES)}
                      searchable
                      items={sortDropMenuItems(
                        Array.from(strategies?.values() ?? []).map((strategy) => ({
                          value: strategy.value,
                          label: strategy.name,
                          style: strategy.value === ALL_STRATEGIES ? { fontStyle: 'italic' } : undefined,
                        })),
                      )}
                      selected={field.value}
                      onChange={(setting: DropMenuItem<string | null>) => {
                        field.onChange(setting.value);
                        spinIcon();
                        if (setting.value && vintage) {
                          /**
                           * If newly selected strategy has the same vintage year option keep it as is
                           * Otherwise reset vintage to null so user has to select a new vintage from the list of options
                           */
                          const hasSameVintageOption = strategies?.get(setting.value)?.vintages.get(vintage);
                          if (!hasSameVintageOption) {
                            setValue('vintage', null, { shouldValidate: true });
                          }
                        }
                      }}
                      disabled={false}
                      minimumItemsToTrigger={1}
                    />
                  );
                }}
              />
              {errors.strategyName && <ErrorText>{errors.strategyName.message}</ErrorText>}
            </div>
            <div>
              <Controller
                control={control}
                name="vintage"
                rules={{
                  required: 'Vintage is required.',
                }}
                render={({ field }) => {
                  return (
                    <Tooltip
                      block
                      usePortal
                      isHidden={!isNil(strategyName)}
                      content="Please select a strategy to see available vintage options"
                      position={TooltipPosition.Bottom}
                    >
                      <DropMenu<number | null>
                        data-testid="vintage-selector"
                        label="Vintage"
                        rightLabel={`Venn Default: ${systemSetting.vintage ? Dates.toYear(systemSetting.vintage) : 'All Vintages'}`}
                        placeholder="Select a vintage"
                        highlight={vintage !== (editedCashflowSetting?.vintage ?? ALL_VINTAGES)}
                        searchable
                        items={sortDropMenuItems(
                          Array.from(vintages?.values() ?? []).map((vintage) => ({
                            value: vintage.value,
                            label: vintage.name,
                            style: vintage.value === ALL_VINTAGES ? { fontStyle: 'italic' } : undefined,
                          })),
                        )}
                        selected={field.value}
                        onChange={(setting: DropMenuItem<number | null>) => {
                          field.onChange(setting.value);
                          spinIcon();
                        }}
                        disabled={isNil(strategyName)}
                        minimumItemsToTrigger={1}
                      />
                    </Tooltip>
                  );
                }}
              />
              {errors.vintage && <ErrorText>{errors.vintage.message}</ErrorText>}
            </div>
            <Footer>
              <Button type="reset" dense disabled={false} onClick={onEditPanelClose} icon="arrow-left" noMargin>
                CANCEL
              </Button>
              <Button
                dense
                dominant
                disabled={!(isDirty && isValid)}
                type="submit"
                noMargin
                data-testid="save-setting-button"
              >
                SAVE
              </Button>
            </Footer>
          </MainEditorContainer>
        </Form>
        <ParametersWrapper>
          <UpdatedParametersHeader>
            <SpinWrapper rotation={spinRotation}>
              <Icon type="refresh" />
            </SpinWrapper>
            <div>Updated Parameters</div>
          </UpdatedParametersHeader>
          <CashflowSettingParametersInnerComponent setting={previewSetting} />
        </ParametersWrapper>
      </Layout>
      {!isCreatingNew && isDirty && (
        <WarningContainer>
          <Icon prefix="fas" type="info-circle" />
          Changes to this setting will affect all funds which use it.
        </WarningContainer>
      )}
    </>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 16px;
`;

const ParametersWrapper = styled.div`
  font-size: 12px;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  gap: 8px;
  color: ${GetColor.HintGrey};
  background: ${GetColor.GreyScale.Grey10};
`;

const UpdatedParametersHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  color: ${GetColor.Black};
  font-weight: bold;
`;

const Form = styled.form`
  flex-grow: 1;
`;

const SpinWrapper = styled.div<{ rotation: number }>`
  transition: all 1s linear;
  transform: rotate(${(props) => props.rotation}deg);
`;

export default withSuspense(<Loading />, ClassificationOverrideForm);
