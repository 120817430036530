import React from 'react';
import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';

interface BannerActionProps {
  text: string;
  onClick: () => void;
}

const BannerAction: React.FC<React.PropsWithChildren<BannerActionProps>> = ({ text, onClick }) => {
  return <StyledButton onClick={onClick}>{text}</StyledButton>;
};

export default BannerAction;

const StyledButton = styled.button`
  color: ${GetColor.White};
  text-decoration: underline;
  :hover {
    color: ${GetColor.White};
  }
  font-size: 14px;
`;
