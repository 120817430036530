import type { FactorSummaryChartRow } from '../../factorSummaryTypes';
import type { AnalysisDetail } from '../../../factor-chart/tooltips/types';
import type { AnalysisSubjectSecondaryLabel } from 'venn-utils';
import { getAnalysisLabels } from 'venn-utils';

export const getFactorDetails = (
  factor: FactorSummaryChartRow,
  mainDataSetName: string,
  baselineDataSetName?: string,
  comparisonDataSetName?: string,
  categoryDataSetName?: string,
  relative?: boolean,
  isFund?: boolean,
  secondaryLabel?: AnalysisSubjectSecondaryLabel,
  secondaryPortfolioUpdated?: number,
): AnalysisDetail[] => {
  const details: AnalysisDetail[] = [];
  const portfolioLabels = getAnalysisLabels('portfolio', secondaryLabel, secondaryPortfolioUpdated);
  const investmentLabels = getAnalysisLabels('investment');
  if (mainDataSetName) {
    details.push({
      portfolioName: isFund ? investmentLabels.main : portfolioLabels.main,
      value: factor.mainDataPoint?.value,
      tvalue: factor.mainDataPoint?.tvalue,
    });
  }
  if (!isFund && baselineDataSetName && factor.baselineDataPoint) {
    details.push({
      portfolioName: portfolioLabels.comparison,
      value: factor.baselineDataPoint.value,
      tvalue: factor.baselineDataPoint.tvalue,
      hideTValue: !!relative,
    });
  }
  if (comparisonDataSetName && factor.comparisonDataPoint) {
    details.push({
      portfolioName: isFund ? investmentLabels.benchmark : portfolioLabels.benchmark,
      value: factor.comparisonDataPoint.value,
      tvalue: factor.comparisonDataPoint.tvalue,
    });
  }
  if (isFund && categoryDataSetName && factor.categoryDataPoint) {
    details.push({
      portfolioName: investmentLabels.category,
      value: factor.categoryDataPoint.value,
      tvalue: factor.categoryDataPoint.tvalue,
      hideTValue: !!relative,
    });
  }
  return details;
};
