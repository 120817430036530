import React from 'react';
import styled, { css } from 'styled-components';
import { GetColor } from '../../style/color';
import type { BrandLogoProps } from '../src/logo/BrandLogoProps';
import { useHasFF } from 'venn-utils';

const VennText = ({ brandMarkUpdate }: { brandMarkUpdate: boolean }) => {
  return brandMarkUpdate ? (
    <>
      <path
        className="venn-logo-5"
        d="M88.1,20.1c-0.4-1-0.1-1.5,1-1.5h0.8c0.8,0,1.3,0.3,1.5,1l11.5,29.2c0.2,0.5,0.3,1,0.5,1.5
      c0.2,0.5,0.4,0.9,0.5,1.3c0.2,0.5,0.3,1,0.4,1.4h0.2c0.1-0.4,0.2-0.9,0.4-1.4c0.1-0.4,0.2-0.8,0.4-1.3c0.2-0.5,0.3-1,0.5-1.5
      l11.5-29.2c0.2-0.7,0.7-1,1.5-1h0.7c1.1,0,1.4,0.5,1,1.5l-14.1,36c-0.2,0.7-0.7,1-1.5,1h-0.9c-0.8,0-1.3-0.3-1.5-1L88.1,20.1z
      M123.5,37.9c0-3.2,0.5-6.1,1.5-8.6s2.4-4.6,4.1-6.3c1.7-1.7,3.8-3.1,6-3.9c2.3-0.9,4.6-1.4,7.1-1.4c2.5,0,4.8,0.4,6.7,1.3
      c2,0.9,3.6,2.1,4.9,3.7c1.3,1.6,2.3,3.5,3,5.8c0.7,2.3,1.1,4.8,1.1,7.6c0,0.4-0.1,0.7-0.4,1c-0.3,0.2-0.6,0.3-1,0.3h-29.7
      c0,2.8,0.4,5.3,1.3,7.5c0.9,2.2,2.1,4,3.5,5.6c1.5,1.5,3.3,2.6,5.2,3.4c2,0.8,4.1,1.2,6.4,1.2c1.3,0,2.6-0.1,3.7-0.4
      c1.1-0.3,2.2-0.6,3.1-1c0.9-0.4,1.7-0.8,2.4-1.3c0.7-0.5,1.3-0.9,1.7-1.2c0.7-0.6,1.3-0.5,1.8,0.2l0.5,0.6
      c0.5,0.6,0.4,1.2-0.3,1.7c-0.5,0.4-1.1,0.9-1.9,1.4c-0.8,0.5-1.7,1-2.8,1.5s-2.3,0.8-3.7,1.2c-1.3,0.4-2.8,0.5-4.4,0.5
      c-2.7,0-5.3-0.5-7.7-1.4c-2.4-1-4.5-2.3-6.3-4.1s-3.2-3.9-4.3-6.3C124,43.8,123.5,41,123.5,37.9z M154.6,34.3
      c-0.1-2.4-0.5-4.4-1.2-6.1c-0.7-1.7-1.6-3.1-2.7-4.2s-2.4-2-3.9-2.5c-1.4-0.6-3-0.8-4.6-0.8c-1.8,0-3.5,0.3-5.2,0.8
      s-3.2,1.4-4.6,2.6c-1.3,1.1-2.5,2.5-3.4,4.2c-0.9,1.7-1.6,3.7-1.9,5.9H154.6z M166.2,19.9c0-0.9,0.4-1.3,1.3-1.3h0.6
      c0.9,0,1.3,0.4,1.3,1.3v5.5c0,0.5,0,0.9-0.1,1.3c0,0.4,0,0.7-0.1,0.9c-0.1,0.3-0.1,0.6-0.2,0.9h0.2c0.3-0.9,0.9-2,1.7-3.2
      c0.9-1.2,2-2.4,3.3-3.5c1.4-1.1,3-2.1,4.9-2.9c1.9-0.8,4.1-1.2,6.5-1.2c2.5,0,4.6,0.4,6.2,1.1c1.6,0.7,3,1.7,3.9,3.1
      c1,1.3,1.6,2.9,2,4.7c0.4,1.9,0.6,3.9,0.6,6.2v23.1c0,0.9-0.4,1.3-1.3,1.3h-0.6c-0.9,0-1.3-0.4-1.3-1.3v-22c0-1.7-0.1-3.3-0.3-4.9
      c-0.2-1.6-0.6-3-1.2-4.2c-0.6-1.2-1.6-2.2-2.9-2.9c-1.2-0.7-2.9-1.1-5.1-1.1c-2.2,0-4.3,0.4-6.2,1.3c-2,0.8-3.7,2-5.2,3.5
      c-1.4,1.5-2.6,3.4-3.5,5.5c-0.9,2.1-1.3,4.3-1.3,6.8v17.9c0,0.9-0.4,1.3-1.3,1.3h-0.6c-0.9,0-1.3-0.4-1.3-1.3V19.9z M208.8,19.9
      c0-0.9,0.4-1.3,1.3-1.3h0.6c0.9,0,1.3,0.4,1.3,1.3v5.5c0,0.5,0,0.9-0.1,1.3c0,0.4,0,0.7-0.1,0.9c-0.1,0.3-0.1,0.6-0.2,0.9h0.2
      c0.3-0.9,0.9-2,1.7-3.2c0.9-1.2,2-2.4,3.3-3.5c1.4-1.1,3-2.1,4.9-2.9c1.9-0.8,4.1-1.2,6.5-1.2c2.5,0,4.6,0.4,6.2,1.1
      c1.6,0.7,3,1.7,3.9,3.1c1,1.3,1.6,2.9,2,4.7c0.4,1.9,0.6,3.9,0.6,6.2v23.1c0,0.9-0.4,1.3-1.3,1.3h-0.6c-0.9,0-1.3-0.4-1.3-1.3v-22
      c0-1.7-0.1-3.3-0.3-4.9c-0.2-1.6-0.6-3-1.2-4.2c-0.6-1.2-1.6-2.2-2.9-2.9c-1.2-0.7-2.9-1.1-5.1-1.1c-2.2,0-4.3,0.4-6.2,1.3
      c-2,0.8-3.7,2-5.2,3.5c-1.4,1.5-2.6,3.4-3.5,5.5c-0.9,2.1-1.3,4.3-1.3,6.8v17.9c0,0.9-0.4,1.3-1.3,1.3h-0.6
      c-0.9,0-1.3-0.4-1.3-1.3V19.9z"
      />
    </>
  ) : (
    <>
      <path
        className="venn-logo-5"
        d="M340.65,50.11,295.71,154.63h-4.66l-45-104.52h4.14a2.26,2.26,0,0,1,1.69.64,3.19,3.19,0,0,1,.85,1.27l38.8,90.42a25.15,25.15,0,0,1,1.06,3c.28,1,.53,2,.74,3,.28-1,.56-2,.85-3a22.09,22.09,0,0,1,1.06-3L334.08,52a4.16,4.16,0,0,1,1.06-1.38,2.44,2.44,0,0,1,1.59-.53Z"
      />
      <path
        className="venn-logo-5"
        d="M436,141.8A25.81,25.81,0,0,1,430,147a47.07,47.07,0,0,1-8.74,4.51,65.21,65.21,0,0,1-10.71,3.18,57.26,57.26,0,0,1-11.82,1.22,47.94,47.94,0,0,1-19.34-3.77,40.46,40.46,0,0,1-14.74-10.86,49.38,49.38,0,0,1-9.38-17.44A77.8,77.8,0,0,1,352,100.25a66.78,66.78,0,0,1,3.12-20.94,47.62,47.62,0,0,1,9.07-16.37,40.48,40.48,0,0,1,14.52-10.71,47.58,47.58,0,0,1,19.5-3.82,42,42,0,0,1,15.85,3,36.53,36.53,0,0,1,12.88,8.75,40.91,40.91,0,0,1,8.64,14.25,56,56,0,0,1,3.13,19.4c0,1.49-.64,2.23-1.91,2.23H357.61c-.07.7-.1,1.39-.1,2.06v2.18a74.29,74.29,0,0,0,3,22,45.75,45.75,0,0,0,8.37,16,35.19,35.19,0,0,0,13.09,9.81,42.65,42.65,0,0,0,17.12,3.34,50.41,50.41,0,0,0,14.52-1.86,56.33,56.33,0,0,0,10.28-4.08,41.23,41.23,0,0,0,6.42-4.08q2.28-1.86,2.91-1.86a1.72,1.72,0,0,1,1.38.64Zm-2.33-49.5a48.37,48.37,0,0,0-2.71-16.8A36.51,36.51,0,0,0,423.49,63a31.22,31.22,0,0,0-11.18-7.74,36.64,36.64,0,0,0-14-2.65,42.82,42.82,0,0,0-15.95,2.81,34.8,34.8,0,0,0-12.14,8A40.42,40.42,0,0,0,362,76a56.77,56.77,0,0,0-4.08,16.33Z"
      />
      <path
        className="venn-logo-5"
        d="M470.08,70.36a57.26,57.26,0,0,1,17.44-16,42.85,42.85,0,0,1,22.1-5.94,39.38,39.38,0,0,1,15.21,2.71,28.14,28.14,0,0,1,10.81,7.73,33.55,33.55,0,0,1,6.47,12.35,58.4,58.4,0,0,1,2.17,16.54v66.89h-5.62V87.74a54.61,54.61,0,0,0-1.8-14.63,29,29,0,0,0-5.57-11,24.49,24.49,0,0,0-9.38-6.89,33.72,33.72,0,0,0-13.25-2.38,39.47,39.47,0,0,0-21.14,6,59.94,59.94,0,0,0-17.23,16.59v79.19h-5.62V50.11h2.65a1.94,1.94,0,0,1,2.12,2.12Z"
      />
      <path
        className="venn-logo-5"
        d="M582.23,70.36a57.11,57.11,0,0,1,17.43-16,42.91,42.91,0,0,1,22.1-5.94A39.31,39.31,0,0,1,637,51.12a28.17,28.17,0,0,1,10.82,7.73,33.54,33.54,0,0,1,6.46,12.35,58,58,0,0,1,2.18,16.54v66.89h-5.62V87.74A54.61,54.61,0,0,0,649,73.11a29.16,29.16,0,0,0-5.57-11,24.56,24.56,0,0,0-9.38-6.89,33.75,33.75,0,0,0-13.25-2.38,39.53,39.53,0,0,0-21.15,6,59.91,59.91,0,0,0-17.22,16.59v79.19h-5.62V50.11h2.65a1.94,1.94,0,0,1,2.12,2.12Z"
      />
      <polygon
        className="venn-logo-5"
        points="483.98 184.16 476.71 184.16 476.71 180.94 494.86 180.94 494.86 184.16 487.59 184.16 487.59 201.98 483.98 201.98 483.98 184.16"
      />
      <polygon
        className="venn-logo-5"
        points="498.26 180.94 502.07 180.94 506.91 196.9 506.97 196.9 511.54 180.94 514.9 180.94 519.44 196.9 519.5 196.9 524.37 180.94 528.15 180.94 521.51 201.98 517.64 201.98 513.22 186.56 513.16 186.56 508.74 201.98 504.87 201.98 498.26 180.94"
      />
    </>
  );
};

const ByTwoSigma = ({ brandMarkUpdate }: { brandMarkUpdate: boolean }) => {
  return brandMarkUpdate ? (
    <>
      <VennText brandMarkUpdate={brandMarkUpdate} />
      <path
        className="venn-logo-5"
        d="M135.5,67.2c0-0.3,0.1-0.4,0.4-0.4h0.4c0.3,0,0.4,0.1,0.4,0.4v2.5c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2
      c0,0.1,0,0.1,0,0.1h0c0.1-0.2,0.3-0.4,0.4-0.6c0.2-0.1,0.4-0.3,0.6-0.4c0.3-0.1,0.6-0.2,0.9-0.2c0.4,0,0.8,0.1,1.1,0.2
      c0.3,0.2,0.6,0.4,0.8,0.7c0.2,0.3,0.4,0.6,0.5,1c0.1,0.4,0.2,0.8,0.2,1.3c0,0.5-0.1,0.9-0.2,1.3c-0.1,0.4-0.3,0.7-0.6,1
      c-0.2,0.3-0.5,0.5-0.9,0.6s-0.7,0.2-1.1,0.2c-0.4,0-0.7-0.1-0.9-0.2c-0.2-0.1-0.4-0.3-0.6-0.4c-0.2-0.2-0.3-0.4-0.4-0.6h0
      c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0,0.3V75c0,0.2-0.1,0.4-0.4,0.4h-0.3c-0.3,0-0.4-0.1-0.4-0.4V67.2z M136.6,72.3c0,0.3,0,0.6,0.1,0.8
      c0.1,0.3,0.2,0.5,0.3,0.7c0.1,0.2,0.3,0.4,0.5,0.5c0.2,0.1,0.5,0.2,0.8,0.2c0.2,0,0.5,0,0.7-0.1c0.2-0.1,0.4-0.2,0.6-0.4
      c0.2-0.2,0.3-0.4,0.4-0.7c0.1-0.3,0.1-0.6,0.1-0.9c0-0.3,0-0.6-0.1-0.9c-0.1-0.3-0.2-0.5-0.4-0.7c-0.2-0.2-0.3-0.3-0.6-0.4
      c-0.2-0.1-0.4-0.2-0.7-0.2c-0.2,0-0.5,0-0.7,0.1c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.2-0.3,0.4-0.4,0.7
      C136.6,71.6,136.6,71.9,136.6,72.3z M141.7,77c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.3,0
      c0.2,0,0.5-0.1,0.6-0.3c0.2-0.2,0.3-0.4,0.4-0.6l0.3-0.7l-2.4-5.6c-0.1-0.1-0.1-0.2,0-0.3c0.1-0.1,0.2-0.1,0.3-0.1h0.5
      c0.2,0,0.4,0.1,0.4,0.3l1.4,3.7c0,0.1,0,0.2,0.1,0.3c0,0.1,0.1,0.2,0.1,0.2c0,0.1,0,0.2,0.1,0.3h0l0.1-0.2c0-0.1,0-0.1,0.1-0.2
      c0-0.1,0.1-0.2,0.1-0.3l1.4-3.7c0.1-0.2,0.2-0.3,0.4-0.3h0.4c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0,0.3l-2.7,6.7
      c-0.2,0.5-0.5,0.8-0.8,1.1c-0.3,0.2-0.7,0.4-1.2,0.4c-0.2,0-0.3,0-0.5-0.1c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.2-0.2
      c0-0.1,0-0.2,0-0.3L141.7,77z"
      />
      <path className="venn-logo-5" d="M174.5,75.4v-7h-3v-1.5h7.4v1.5h-3v7H174.5z" />
      <path
        className="venn-logo-5"
        d="M188.9,66.9h1.5l-2.1,8.4H187l-1.9-5.9l-1.9,5.9h-1.3l-2.1-8.4h1.5l1.4,5.9l1.9-5.9h1.1l1.9,5.9L188.9,66.9z"
      />
      <path
        className="venn-logo-5"
        d="M195,75.5c-0.9,0-1.7-0.3-2.4-0.7c-0.7-0.5-1.3-1.1-1.6-1.9c-0.3-0.8-0.4-1.7-0.2-2.5
      c0.2-0.8,0.6-1.6,1.2-2.2c0.6-0.6,1.4-1,2.2-1.2c0.8-0.2,1.7-0.1,2.5,0.2c0.8,0.3,1.5,0.9,1.9,1.6c0.5,0.7,0.7,1.5,0.7,2.4
      c0,1.1-0.5,2.2-1.3,3.1C197.2,75,196.1,75.5,195,75.5z M192.2,71.1c0,0.5,0.2,1.1,0.5,1.5c0.3,0.5,0.7,0.8,1.2,1
      c0.5,0.2,1.1,0.3,1.6,0.2c0.5-0.1,1-0.4,1.4-0.8c0.4-0.4,0.7-0.9,0.8-1.4c0.1-0.5,0.1-1.1-0.2-1.6c-0.2-0.5-0.6-0.9-1-1.2
      c-0.5-0.3-1-0.5-1.5-0.5c-0.7,0-1.4,0.3-2,0.8C192.5,69.7,192.2,70.4,192.2,71.1z"
      />
      <path
        className="venn-logo-5"
        d="M206.3,75.5c-1.9,0-3.1-0.9-3.4-2.7h1.7c0.3,0.8,0.8,1.2,1.8,1.2c1,0,1.5-0.5,1.5-1c0-0.4-0.2-0.7-1-0.9
      l-1.5-0.3c-1.6-0.3-2.2-1-2.2-2.3c0-1.3,1.2-2.7,3.1-2.7c1.7,0,2.9,1,3.1,2.7h-1.5c-0.2-0.8-0.8-1.2-1.7-1.2c-1,0-1.5,0.5-1.5,1.1
      c0,0.5,0.3,0.8,1.1,0.9l1.6,0.3c1.5,0.3,2.1,1,2.1,2.2C209.6,74.4,208.3,75.5,206.3,75.5z"
      />
      <path className="venn-logo-5" d="M210.9,75.4v-8.4h1.5v8.4H210.9z" />
      <path
        className="venn-logo-5"
        d="M217.8,75.5c-0.6,0-1.1-0.1-1.7-0.3c-0.5-0.2-1-0.5-1.4-0.9c-0.4-0.4-0.7-0.9-1-1.4c-0.2-0.5-0.3-1.1-0.3-1.7
      c0-0.6,0.1-1.1,0.3-1.7c0.2-0.5,0.5-1,0.9-1.4c0.4-0.4,0.9-0.7,1.4-0.9c0.5-0.2,1.1-0.3,1.7-0.3c1.7,0,3.3,1,4,2.7H220
      c-0.2-0.4-0.6-0.6-1-0.8c-0.4-0.2-0.8-0.3-1.2-0.3c-0.5,0-1,0.1-1.4,0.4c-0.4,0.3-0.8,0.6-1,1.1c-0.2,0.4-0.4,0.9-0.3,1.4
      c0,0.5,0.2,1,0.5,1.4c0.3,0.4,0.7,0.8,1.1,1c0.4,0.2,1,0.3,1.5,0.3c0.5,0,1-0.2,1.4-0.5c0.4-0.3,0.7-0.7,0.9-1.1H218v-1.5h3.8v4.6
      h-1.3v-1.1C219.8,75.1,218.9,75.5,217.8,75.5z"
      />
      <path
        className="venn-logo-5"
        d="M227.2,75.4l-2.5-5.6v5.6h-1.5v-8.4h1.6l2.8,6l2.8-6h1.6v8.4h-1.5v-5.6l-2.5,5.6H227.2z"
      />
      <path
        className="venn-logo-5"
        d="M234.4,75.4h-1.6l3.6-8.4h1.1l3.6,8.4h-1.6l-0.6-1.5H235L234.4,75.4z M235.5,72.4h2.6l-1.3-3.2L235.5,72.4z"
      />
      <path
        className="venn-logo-5"
        d="M158.3,71.1c0.5-0.5,0.7-1,0.7-1.6c0-0.6-0.2-1.1-0.6-1.5c-0.4-0.4-0.9-0.6-1.5-0.6c-0.6,0-1.1,0.2-1.5,0.6
      c-0.4,0.4-0.7,0.9-0.7,1.4h1.1c0-0.3,0.2-0.5,0.4-0.7c0.2-0.2,0.5-0.3,0.7-0.2c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.5,0.3,0.7
      c0,0.3-0.1,0.6-0.4,0.9l-2.8,2.8v1h4.4v-1.1h-2.9L158.3,71.1z"
      />
      <path
        className="venn-logo-5"
        d="M163.5,70.2h0.9l0.9-0.9h-3.1c-1.6,0-2.8,1.1-2.8,2.5c0,0.7,0.3,1.3,0.7,1.8c0.5,0.5,1.1,0.7,1.8,0.7
      c0.7,0,1.3-0.3,1.8-0.7c0.5-0.5,0.7-1.1,0.7-1.8C164.5,71.3,164.3,70.7,163.5,70.2z M162,73.3c-0.4,0-0.8-0.2-1.1-0.4
      c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.4,0.2-0.8,0.4-1.1c0.3-0.3,0.7-0.4,1.1-0.4c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.5,0.3
      c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.2,0.1,0.4,0.1,0.6c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.1-0.3,0.2-0.5,0.3
      C162.4,73.3,162.2,73.3,162,73.3z"
      />
      <path
        className="venn-logo-7"
        d="M163.5,71.8c0,0.4-0.2,0.8-0.5,1.1c-0.3,0.3-0.7,0.4-1.1,0.4c-0.4,0-0.8-0.2-1.1-0.4
      c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.4,0.2-0.8,0.4-1.1c0.3-0.3,0.7-0.4,1.1-0.4c0.4,0,0.8,0.2,1.1,0.4S163.5,71.4,163.5,71.8z
       M168.2,71.1l-1.1,1.1l-6.6,6.6l-1.1,1.1l-1.1-1.1l-6.6-6.6l-1.1-1.1l1.1-1.1l6.6-6.6l1.1-1.1l1.1,1.1l6.6,6.6L168.2,71.1z
       M159.1,74.3v-1.1h-2.9l2.1-2.1c0.5-0.5,0.7-1,0.7-1.6c0-0.6-0.2-1.1-0.6-1.5c-0.4-0.4-0.9-0.6-1.5-0.6c-0.6,0-1.1,0.2-1.5,0.6
      c-0.4,0.4-0.7,0.9-0.7,1.4h1.1c0-0.3,0.2-0.5,0.4-0.7c0.2-0.2,0.5-0.3,0.7-0.2c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.5,0.3,0.7
      c0,0.3-0.1,0.6-0.4,0.9l-2.8,2.8v1L159.1,74.3z M163.5,70.2h0.9l0.9-0.9h-3.1c-1.6,0-2.8,1.1-2.8,2.5c0,0.7,0.3,1.3,0.7,1.8
      c0.5,0.5,1.1,0.7,1.8,0.7c0.7,0,1.3-0.3,1.8-0.7c0.5-0.5,0.7-1.1,0.7-1.8C164.5,71.3,164.3,70.7,163.5,70.2L163.5,70.2z"
      />
    </>
  ) : (
    <>
      <VennText brandMarkUpdate={brandMarkUpdate} />
      <g className="venn-logo-6">
        <path
          className="venn-logo-5"
          d="M540.25,199.21c4,0,7.06-2.94,7.06-7.87,0-4.75-3.06-7.66-7.06-7.66s-7.06,2.91-7.06,7.66c0,4.93,3.1,7.87,7.06,7.87m0-18.63A10.3,10.3,0,0,1,551,191.34c0,6.67-4.63,11-10.73,11s-10.75-4.33-10.75-11,4.65-10.76,10.75-10.76"
        />
        <polygon
          className="venn-logo-5"
          points="581.87 180.94 585.48 180.94 585.48 192.28 585.48 201.98 581.87 201.98 581.87 190.22 581.87 180.94"
        />
        <path
          className="venn-logo-5"
          d="M601,180.58a12.82,12.82,0,0,1,7.45,2.38,28.09,28.09,0,0,1-2.16,2.35,9.41,9.41,0,0,0-5.2-1.6c-4.32,0-7.18,3.12-7.18,7.57,0,4.84,2.68,7.93,7.18,7.93a8.73,8.73,0,0,0,4.21-1v-5H600v-2.67h8.62v9.52a13.17,13.17,0,0,1-7.63,2.29c-6.58,0-10.79-4.45-10.79-11.06,0-6.19,4.27-10.7,10.79-10.7"
        />
        <polygon
          className="venn-logo-5"
          points="613.72 180.94 617.35 180.94 624.29 190.83 631.23 180.94 634.84 180.94 634.84 201.98 631.35 201.98 631.35 186.29 624.26 196.21 617.17 186.29 617.17 201.98 613.72 201.98 613.72 180.94"
        />
        <path
          className="venn-logo-5"
          d="M644.67,194.53h6.85l-3.4-9.8h-.06ZM646,180.94h4.24L658,202h-3.88l-1.59-4.54h-8.86L642.12,202h-3.91Z"
        />
        <path
          className="venn-logo-5"
          d="M563.22,197.44a12,12,0,0,0,6,1.71c2.76,0,4.15-1.2,4.15-2.88,0-4.75-11.84-1.62-11.84-9.65,0-3.36,2.79-6,7.51-6a15.58,15.58,0,0,1,7.72,1.94l-2.36,2.41a12.14,12.14,0,0,0-5.24-1.16c-2.43,0-3.88,1.23-3.88,2.79,0,4.72,11.84,1.51,11.84,9.68,0,3.45-2.61,6.1-7.9,6.1a16,16,0,0,1-8.43-2.47Z"
        />
        <rect
          className="venn-logo-7"
          x="429.96"
          y="176.6"
          width="29.72"
          height="29.72"
          transform="translate(-5.07 370.69) rotate(-45.01)"
        />
        <path
          className="venn-logo-5"
          d="M433.74,197.42c-.16-6.73,7.94-5.85,7.94-9.3,0-1.12-.89-2.08-2.84-2.08a5.76,5.76,0,0,0-3.79,1.63l-1.49-1.49a8.11,8.11,0,0,1,5.38-2.18c3.37,0,5.08,1.87,5.08,4.08,0,5.08-7.87,4.18-7.87,8.42h7.75v2H433.76Z"
        />
        <path
          className="venn-logo-5"
          d="M459.87,187.49h-8.6a6.21,6.21,0,0,0-6.15,6.52,4.42,4.42,0,0,0,4.63,4.76,6.21,6.21,0,0,0,6.18-6.51,5,5,0,0,0-.87-3h3ZM450,196.91c-1.55,0-2.47-1-2.47-2.92,0-2.45,1.63-4.65,3.59-4.65,1.53,0,2.45,1,2.45,2.94,0,2.45-1.61,4.63-3.57,4.63"
        />
      </g>
      <text className="venn-logo-8" transform="translate(381.71 201.98)">
        by
      </text>
    </>
  );
};

export const BrandLogo = ({
  animateOnHover,
  isAnimated,
  isBackground,
  scaleToFit,
  showText,
  height,
}: BrandLogoProps) => {
  const logoHeight = height || (showText ? 47 : 30);
  const brandMarkUpdate = useHasFF('brand_mark_update_ff');
  const viewBoxValue = brandMarkUpdate ? `0 0 ${showText ? 244 : 88} 80` : `0 0 ${showText ? 665 : 234} 215`;

  return brandMarkUpdate ? (
    <StyledLogo
      animateOnHover={animateOnHover}
      isAnimated={isAnimated}
      height={logoHeight}
      width={scaleToFit ? (showText ? 3 * logoHeight : logoHeight) : showText ? 145 : 32}
      viewBox={viewBoxValue}
      shapeRendering="geometricPrecision"
      isBackground={isBackground}
      brandMarkUpdate={brandMarkUpdate}
    >
      <path className="venn-logo-3" d="M22.2,0H1.1l10.6,18.3h21.1L22.2,0z" />
      <path className="venn-logo-3" d="M77.6,18.3L88.2,0H67.1L56.5,18.3H77.6z" />
      <path className="venn-logo-2" d="M56.5,18.3L67.1,0H22.2l10.6,18.3H56.5z" />
      <path className="venn-logo-3" d="M55.2,57.1L44.6,38.9L34.1,57.1l10.5,18.3L55.2,57.1z" />
      <path className="venn-logo-2" d="M44.6,38.9L32.8,18.3H11.7l22.4,38.8L44.6,38.9z" />
      <path className="venn-logo-2" d="M44.6,38.9l10.6,18.3l22.4-38.8H56.5L44.6,38.9z" />
      <path className="venn-logo-4" d="M32.8,18.3l11.9,20.5l11.9-20.5H32.8z" />

      {showText && <ByTwoSigma brandMarkUpdate={brandMarkUpdate} />}
    </StyledLogo>
  ) : (
    <StyledLogo
      animateOnHover={animateOnHover}
      isAnimated={isAnimated}
      height={logoHeight}
      width={scaleToFit ? (showText ? 3 * logoHeight : logoHeight) : showText ? 145 : 32}
      viewBox={viewBoxValue}
      shapeRendering="geometricPrecision"
      isBackground={isBackground}
      brandMarkUpdate={brandMarkUpdate}
    >
      <defs>
        <clipPath id="clip-path">
          <rect className="venn-logo-1" x="423.81" y="170.44" width="234.2" height="42.04" />
        </clipPath>
      </defs>
      <polygon className="venn-logo-2" points="56.47 1 0 1 28.32 50.06 84.79 50.06 56.47 1" />
      <polygon className="venn-logo-2" points="204.75 50.06 233.07 1 176.6 1 148.28 50.06 204.75 50.06" />
      <polygon className="venn-logo-3" points="148.28 50.06 176.6 1 56.47 1 84.79 50.06 148.28 50.06" />
      <polygon className="venn-logo-2" points="144.77 153.94 116.53 105.04 88.3 153.94 116.53 203 144.77 153.94" />
      <polygon className="venn-logo-3" points="116.53 105.04 84.79 50.06 28.32 50.06 88.3 153.94 116.53 105.04" />
      <polygon className="venn-logo-3" points="116.53 105.04 144.77 153.94 204.75 50.06 148.28 50.06 116.53 105.04" />
      <polygon className="venn-logo-4" points="84.79 50.06 116.53 105.04 148.28 50.06 84.79 50.06" />
      {showText && <ByTwoSigma brandMarkUpdate={brandMarkUpdate} />}
    </StyledLogo>
  );
};

export default BrandLogo;

const animateCss = css`
  path,
  .venn-logo-5,
  .venn-logo-8 {
    fill-opacity: 1;
    stroke: ${GetColor.White};
    stroke-width: 2;
    stroke-dasharray: 3319;
    stroke-dashoffset: 3319;
    animation: lines 5s forwards;
  }

  .venn-logo-2 {
    animation: logoTwoFill 4s infinite alternate;
  }

  .venn-logo-3 {
    animation: logoThreeFill 6s infinite alternate;
  }

  .venn-logo-4 {
    animation: logoFourFill 5s infinite alternate;
  }
`;

export const LogoColor = {
  Logo1: '#009AA6',
  Logo2: '#7DCECA',
  Logo3: '#CEF2ED',
  Logo4: '#00A5BD',
  Logo5: '#00CCCC',
};

export const UpdatedLogoColor = {
  Logo1: '#CEF2ED',
  Logo2: '#7DCECA',
  Logo3: '#9C61C1',
  Logo4: '#00A5BD',
};

const StyledLogo = styled.svg<{
  animateOnHover?: boolean;
  isAnimated?: boolean;
  isBackground?: boolean;
  brandMarkUpdate?: boolean;
}>`
  .venn-logo-1 {
    fill: none;
  }

  .venn-logo-2 {
    fill: ${({ brandMarkUpdate }) => (brandMarkUpdate ? UpdatedLogoColor.Logo1 : LogoColor.Logo1)};
  }

  .venn-logo-3 {
    fill: ${({ brandMarkUpdate }) => (brandMarkUpdate ? UpdatedLogoColor.Logo2 : LogoColor.Logo2)};
  }

  .venn-logo-4 {
    fill: ${({ brandMarkUpdate }) => (brandMarkUpdate ? UpdatedLogoColor.Logo3 : LogoColor.Logo3)};
  }

  .venn-logo-5,
  .venn-logo-8 {
    fill: ${GetColor.White};
  }

  .venn-logo-6 {
    clip-path: url(#clip-path);
  }

  .venn-logo-7 {
    fill: ${({ brandMarkUpdate }) => (brandMarkUpdate ? UpdatedLogoColor.Logo4 : LogoColor.Logo4)};
  }

  .venn-logo-8 {
    font-size: 30px;
    font-family: ${(props) => props.theme.Typography.fontFamily};
    font-weight: 400;
    letter-spacing: -0.01em;
  }

  @keyframes lines {
    90% {
      stroke-dashoffset: 0;
      stroke-width: 2;
    }
    100% {
      stroke-dashoffset: 0;
      stroke-width: 2;
    }
  }

  @keyframes logoTwoFill {
    10% {
      fill: ${({ brandMarkUpdate }) => (brandMarkUpdate ? UpdatedLogoColor.Logo1 : LogoColor.Logo1)};
    }
    50% {
      fill: ${({ brandMarkUpdate }) => (brandMarkUpdate ? UpdatedLogoColor.Logo3 : LogoColor.Logo3)};
    }
    100% {
      fill: ${({ brandMarkUpdate }) => (brandMarkUpdate ? UpdatedLogoColor.Logo1 : LogoColor.Logo1)};
    }
  }

  @keyframes logoThreeFill {
    15% {
      fill: ${({ brandMarkUpdate }) => (brandMarkUpdate ? UpdatedLogoColor.Logo2 : LogoColor.Logo2)};
    }
    55% {
      fill: ${({ brandMarkUpdate }) => (brandMarkUpdate ? UpdatedLogoColor.Logo1 : LogoColor.Logo1)};
    }
    100% {
      fill: ${({ brandMarkUpdate }) => (brandMarkUpdate ? UpdatedLogoColor.Logo2 : LogoColor.Logo2)};
    }
  }

  @keyframes logoFourFill {
    5% {
      fill: ${({ brandMarkUpdate }) => (brandMarkUpdate ? UpdatedLogoColor.Logo3 : LogoColor.Logo3)};
    }
    45% {
      fill: ${({ brandMarkUpdate }) => (brandMarkUpdate ? UpdatedLogoColor.Logo1 : LogoColor.Logo1)};
    }
    100% {
      fill: ${({ brandMarkUpdate }) => (brandMarkUpdate ? UpdatedLogoColor.Logo3 : LogoColor.Logo3)};
    }
  }
  ${({ animateOnHover, isAnimated }) =>
    isAnimated
      ? animateCss
      : animateOnHover
        ? css`
            &:hover {
              ${animateCss}
            }
          `
        : ''}

  ${({ isBackground }) =>
    isBackground
      ? ` opacity: 0.1;
  position: absolute;
  left: 50%;
  margin-left: -150px;
  top: 70px;`
      : ''}
`;
