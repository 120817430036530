import styled, { css } from 'styled-components';
import { GetColor } from 'venn-ui-kit';

export const SubjectName = styled.div<{ disabled?: boolean; secondary?: boolean }>`
  margin: 0 10px;
  display: flex;
  align-items: center;
  column-gap: 3px;
  height: 24px;
  font-size: 1.125rem;

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${GetColor.GreyScale.Grey70};

      .qa-Investment-type-pill,
      .qa-Portfolio-type-pill {
        background-color: ${GetColor.GreyScale.Grey60};
      }

      i {
        color: ${GetColor.GreyScale.Grey60};
      }
    `}

  ${({ secondary }) =>
    secondary &&
    css`
      padding-left: 16px;
      position: relative;
      i {
        color: ${GetColor.DarkGrey};
      }
      &:hover {
        button {
          visibility: visible;
        }
      }
    `}
`;
