import React, { useMemo } from 'react';
import { DeleteIcon, ErrorMessage, InfoHeading, Row, ViewConfigurationArea } from './styles';
import { StatefulInput } from 'venn-components';
import type { DropMenuItem } from 'venn-ui-kit';
import { Body1, Button, DropMenu, getAppTitle, GetColor } from 'venn-ui-kit';
import styled from 'styled-components';
import type { ViewConfiguration } from './useAddeparViewConfiguration';
import type { AddeparOption } from 'venn-api';
import { MIN_GROUPINGS } from './useAddeparViewConfiguration';
import { SECURITY_GROUPING } from './groupings';
import ViewActions from './ViewActions';
import AttributeFilter from './AttributeFilter';
import Filters from './Filters';

const appTitle = getAppTitle();
const TRASH_ICON_WIDTH = 10.5;
const GROUPING_NUMBER_WIDTH = 12;

interface EditViewConfigurationProps extends ViewConfiguration {
  updatePortfolioPrefix: (viewId: string, updated: string) => void;
  removeGrouping: (viewId: string, index: number) => void;
  updateGrouping: (viewId: string, groupingIndex: number, updatedKey: string) => void;
  addGrouping: (viewId: string) => void;
  groupingOptions: AddeparOption[];
  filterOptions: AddeparOption[];
  portfolioPrefixError: string | undefined;
  applyToAttributeOptions: (viewId: string, selected: string[]) => void;
  includedAttributesError: string | undefined;
  shouldLoadActiveViewIncludedAttrs: boolean;
  onlyHasOneView: boolean;
  removeCurrentView: () => void;
  onToggle: () => void;
}

const EditViewConfiguration = ({
  configUuid,
  portfolioPrefix,
  groupings,
  filters,
  filterOptions,
  updatePortfolioPrefix,
  removeGrouping,
  updateGrouping,
  addGrouping,
  groupingOptions,
  portfolioPrefixError,
  includedAttributes,
  applyToAttributeOptions,
  includedAttributesError,
  shouldLoadActiveViewIncludedAttrs,
  ...actionsProps
}: EditViewConfigurationProps) => {
  const groupingDropMenuOptions = useMemo(
    () => groupingOptions.map(({ key, label }) => ({ value: key, label })),
    [groupingOptions],
  );
  return (
    <ViewConfigurationArea>
      <InfoHeading as="label">Portfolio name prefix</InfoHeading>
      <Row>
        <Column>
          <Body1>
            This will prefix all integrated portfolios, e.g.{' '}
            <DynamicText>
              <Highlight>{portfolioPrefix}:</Highlight> Top Grouping (Addepar Entity ID)
            </DynamicText>
            .
          </Body1>
          <StatefulInput value={portfolioPrefix} onChange={(v) => updatePortfolioPrefix(configUuid, v)} />
          {portfolioPrefixError && <ErrorMessage>{portfolioPrefixError}</ErrorMessage>}
        </Column>
        <ViewActions {...actionsProps} isActive />
      </Row>
      <Filters filters={filters} filterOptions={filterOptions} />

      <GroupingsAndAttributes>
        <InfoHeading>Groupings order</InfoHeading>
        <Body1>
          Adjusting the order of these groupings changes how {appTitle} interprets your data. The top position will
          always follow the portfolio name prefix when portfolios are imported.
        </Body1>
        <Groupings>
          {groupings.map((key, index) => {
            const isSecurity = key === SECURITY_GROUPING && index === groupings.length - 1;
            return (
              <React.Fragment key={key || `new-${index}`}>
                <Row>
                  <Body1>{index + 1}.</Body1>
                  <DropmenuContainer>
                    <DropMenu
                      searchable
                      className="qa-addepar-select-grouping"
                      items={groupingDropMenuOptions.filter(({ value }) => value === key || !groupings.includes(value))}
                      selected={key}
                      onChange={(item: DropMenuItem<string>) => updateGrouping(configUuid, index, item.value)}
                      placeholder="Type or select grouping"
                      disabled={isSecurity}
                      getTooltipContent={
                        isSecurity ? () => 'This grouping maps to Venn investments so it must be Security' : undefined
                      }
                      alwaysShowTooltip={isSecurity}
                    />
                  </DropmenuContainer>
                  {!isSecurity ? (
                    <DeleteIcon
                      prefix="fas"
                      type="trash"
                      onClick={() => removeGrouping(configUuid, index)}
                      disabled={groupings.length === MIN_GROUPINGS}
                    />
                  ) : (
                    <Spacer width={TRASH_ICON_WIDTH} />
                  )}
                </Row>
                {index === 0 && (
                  <Row>
                    <Spacer width={GROUPING_NUMBER_WIDTH} />
                    <DropmenuContainer>
                      <Column>
                        <AttributeFilter
                          includedAttributes={includedAttributes}
                          applyToAttributeOptions={applyToAttributeOptions}
                          viewId={configUuid}
                          disabled={shouldLoadActiveViewIncludedAttrs}
                          hasError={!!includedAttributesError}
                        />
                        {includedAttributesError && <ErrorMessage>{includedAttributesError}</ErrorMessage>}
                      </Column>
                    </DropmenuContainer>
                    <Spacer width={TRASH_ICON_WIDTH} />
                  </Row>
                )}
              </React.Fragment>
            );
          })}
          <Row>
            <Spacer width={GROUPING_NUMBER_WIDTH} />
            <FullButton icon="plus" onClick={() => addGrouping(configUuid)}>
              Add Grouping
            </FullButton>
            <Spacer width={TRASH_ICON_WIDTH} />
          </Row>
        </Groupings>
      </GroupingsAndAttributes>
    </ViewConfigurationArea>
  );
};

export default EditViewConfiguration;

const DynamicText = styled.span`
  color: ${GetColor.HintGrey};
`;

const Highlight = styled.span`
  font-weight: bold;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const FullButton = styled(Button)`
  flex: 1;
  border: 1px solid ${GetColor.GreyScale.Grey30};
`;

const Spacer = styled.div<{ width: number }>`
  width: ${({ width }) => width}px;
`;

const DropmenuContainer = styled.div`
  flex: 1;
`;

const GroupingsAndAttributes = styled.div`
  display: flex;
  flex-direction: column;
`;

const Groupings = styled.div`
  & > * {
    margin-top: 4px;
  }
`;
