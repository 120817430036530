import React from 'react';
import { PanelLabel } from '../../../../shared';
import { SmallToggle } from 'venn-components';
import { getTextThemeProvider, Tooltip } from 'venn-ui-kit';
import { useRecoilState, useRecoilValue } from 'recoil';
import { blockRequestSubjects, viewShowVenncast } from 'venn-state';

interface VenncastSettingsProps {
  selected: string;
}

export const VenncastSettings = ({ selected }: VenncastSettingsProps) => {
  const [showVenncast = false, setShowVenncast] = useRecoilState(viewShowVenncast(selected));
  const subjects = useRecoilValue(blockRequestSubjects(selected));
  const tooManySubjects = !showVenncast && subjects.length > 1;
  const venncastName = getTextThemeProvider().VenncastName;

  return (
    <>
      <PanelLabel>{venncastName} Missing Data:</PanelLabel>
      <div>
        <Tooltip
          isHidden={!tooManySubjects}
          content={`${venncastName} is available only for a single subject`}
          usePortal
        >
          <SmallToggle
            disabled={tooManySubjects}
            toggled={showVenncast}
            onToggle={() => setShowVenncast((current) => !current)}
          />
        </Tooltip>
      </div>
    </>
  );
};
