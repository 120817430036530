/*
 * Venn Platform API 1.0.0 Operations for "theme"
 * Automatically generated file - do not modify!
 */

import type { CustomTheme } from '../types';
import type { OperationResult } from '../../utils';
import { buildRequestInit, extractError, retryGeneratedFetch } from '../../utils';

/** Create or update a theme */
export async function updateTheme(
  body?: Partial<CustomTheme>,
  signal?: AbortSignal,
): Promise<OperationResult<CustomTheme>> {
  const endpoint = '/api/theme';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: CustomTheme = (await response.json()) as CustomTheme;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Delete a given theme */
export async function deleteTheme(id: string, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/theme/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({ method: 'DELETE', signal });
  const response = await retryGeneratedFetch('DELETE', () => fetch(endpoint, requestInit));
  if ((response.status >= 200 && response.status < 400) || response.status === 404) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get the user's current theme */
export async function getTheme(signal?: AbortSignal): Promise<OperationResult<CustomTheme>> {
  const endpoint = '/api/theme';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: CustomTheme = (await response.json()) as CustomTheme;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
