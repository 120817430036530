import type { CustomizableMetric } from 'venn-utils';

export const PEER_GROUPS_METRICS: CustomizableMetric[] = [
  {
    key: 'periodReturn',
    label: 'Return (Analysis Period)',
    relativeLabel: 'Excess Return (Analysis Period)',
    dataType: 'PERCENTAGE',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Historical Performance',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'ANALYSIS_PERIOD.PERIOD_RETURN',
    relativeMetricPath: 'ANALYSIS_PERIOD.PERIOD_EXCESS_RETURN',
  },
  {
    key: 'ytdReturn',
    label: 'Return (YTD)',
    relativeLabel: 'Excess Return (YTD)',
    dataType: 'PERCENTAGE',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Historical Performance',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'YTD.PERIOD_RETURN',
    relativeMetricPath: 'YTD.PERIOD_EXCESS_RETURN',
  },
  {
    key: 'YEAR_1-periodReturn',
    label: 'Return (1Y)',
    relativeLabel: 'Excess Return (1Y)',
    dataType: 'PERCENTAGE',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Historical Performance',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'YEAR_1.PERIOD_RETURN',
    relativeMetricPath: 'YEAR_1.PERIOD_EXCESS_RETURN',
  },
  {
    key: 'YEAR_3-periodReturn',
    label: 'Return (3Y)',
    relativeLabel: 'Excess Return (3Y)',
    dataType: 'PERCENTAGE',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Historical Performance',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'YEAR_3.PERIOD_RETURN',
    relativeMetricPath: 'YEAR_3.PERIOD_EXCESS_RETURN',
  },
  {
    key: 'YEAR_5-periodReturn',
    label: 'Return (5Y)',
    relativeLabel: 'Excess Return (5Y)',
    dataType: 'PERCENTAGE',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Historical Performance',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'YEAR_5.PERIOD_RETURN',
    relativeMetricPath: 'YEAR_5.PERIOD_EXCESS_RETURN',
  },
  {
    key: 'YEAR_10-periodReturn',
    label: 'Return (10Y)',
    relativeLabel: 'Excess Return (10Y)',
    dataType: 'PERCENTAGE',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Historical Performance',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'YEAR_10.PERIOD_RETURN',
    relativeMetricPath: 'YEAR_10.PERIOD_EXCESS_RETURN',
  },
  {
    key: 'YEAR_15-periodReturn',
    label: 'Return (15Y)',
    relativeLabel: 'Excess Return (15Y)',
    dataType: 'PERCENTAGE',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Historical Performance',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'YEAR_15.PERIOD_RETURN',
    relativeMetricPath: 'YEAR_15.PERIOD_EXCESS_RETURN',
  },
  {
    key: 'YEAR_20-periodReturn',
    label: 'Return (20Y)',
    relativeLabel: 'Excess Return (20Y)',
    dataType: 'PERCENTAGE',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Historical Performance',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'YEAR_20.PERIOD_RETURN',
    relativeMetricPath: 'YEAR_20.PERIOD_EXCESS_RETURN',
  },
  {
    key: 'sharpe',
    label: 'Sharpe Ratio',
    relativeLabel: 'Information Ratio',
    dataType: 'NUMERIC',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Historical Performance',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'ANALYSIS_PERIOD.SHARPE_RATIO',
    relativeMetricPath: 'ANALYSIS_PERIOD.INFORMATION_RATIO',
  },
  {
    key: 'YEAR_1-sharpe',
    label: 'Sharpe Ratio (1Y)',
    relativeLabel: 'Information Ratio (1Y)',
    dataType: 'NUMERIC',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Historical Performance',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'YEAR_1.SHARPE_RATIO',
    relativeMetricPath: 'YEAR_1.INFORMATION_RATIO',
  },
  {
    key: 'YEAR_3-sharpe',
    label: 'Sharpe Ratio (3Y)',
    relativeLabel: 'Information Ratio (3Y)',
    dataType: 'NUMERIC',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Historical Performance',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'YEAR_3.SHARPE_RATIO',
    relativeMetricPath: 'YEAR_3.INFORMATION_RATIO',
  },
  {
    key: 'YEAR_5-sharpe',
    label: 'Sharpe Ratio (5Y)',
    relativeLabel: 'Information Ratio (5Y)',
    dataType: 'NUMERIC',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Historical Performance',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'YEAR_5.SHARPE_RATIO',
    relativeMetricPath: 'YEAR_5.INFORMATION_RATIO',
  },
  {
    key: 'YEAR_10-sharpe',
    label: 'Sharpe Ratio (10Y)',
    relativeLabel: 'Information Ratio (10Y)',
    dataType: 'NUMERIC',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Historical Performance',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'YEAR_10.SHARPE_RATIO',
    relativeMetricPath: 'YEAR_10.INFORMATION_RATIO',
  },
  {
    key: 'YEAR_15-sharpe',
    label: 'Sharpe Ratio (15Y)',
    relativeLabel: 'Information Ratio (15Y)',
    dataType: 'NUMERIC',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Historical Performance',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'YEAR_15.SHARPE_RATIO',
    relativeMetricPath: 'YEAR_15.INFORMATION_RATIO',
  },
  {
    key: 'YEAR_20-sharpe',
    label: 'Sharpe Ratio (20Y)',
    relativeLabel: 'Information Ratio (20Y)',
    dataType: 'NUMERIC',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Historical Performance',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'YEAR_20.SHARPE_RATIO',
    relativeMetricPath: 'YEAR_20.INFORMATION_RATIO',
  },
  {
    key: 'volatility',
    label: 'Volatility',
    relativeLabel: 'Tracking Error',
    dataType: 'PERCENTAGE',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Historical Performance',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'ANALYSIS_PERIOD.ANNUALIZED_VOLATILITY',
    relativeMetricPath: 'ANALYSIS_PERIOD.TRACKING_ERROR',
  },
  {
    key: 'YEAR_1-volatility',
    label: 'Volatility (1Y)',
    relativeLabel: 'Tracking Error (1Y)',
    dataType: 'PERCENTAGE',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Historical Performance',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'YEAR_1.ANNUALIZED_VOLATILITY',
    relativeMetricPath: 'YEAR_1.TRACKING_ERROR',
  },
  {
    key: 'YEAR_3-volatility',
    label: 'Volatility (3Y)',
    relativeLabel: 'Tracking Error (3Y)',
    dataType: 'PERCENTAGE',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Historical Performance',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'YEAR_3.ANNUALIZED_VOLATILITY',
    relativeMetricPath: 'YEAR_3.TRACKING_ERROR',
  },
  {
    key: 'YEAR_5-volatility',
    label: 'Volatility (5Y)',
    relativeLabel: 'Tracking Error (5Y)',
    dataType: 'PERCENTAGE',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Historical Performance',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'YEAR_5.ANNUALIZED_VOLATILITY',
    relativeMetricPath: 'YEAR_5.TRACKING_ERROR',
  },
  {
    key: 'YEAR_10-volatility',
    label: 'Volatility (10Y)',
    relativeLabel: 'Tracking Error (10Y)',
    dataType: 'PERCENTAGE',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Historical Performance',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'YEAR_10.ANNUALIZED_VOLATILITY',
    relativeMetricPath: 'YEAR_10.TRACKING_ERROR',
  },
  {
    key: 'YEAR_15-volatility',
    label: 'Volatility (15Y)',
    relativeLabel: 'Tracking Error (15Y)',
    dataType: 'PERCENTAGE',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Historical Performance',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'YEAR_15.ANNUALIZED_VOLATILITY',
    relativeMetricPath: 'YEAR_15.TRACKING_ERROR',
  },
  {
    key: 'YEAR_20-volatility',
    label: 'Volatility (20Y)',
    relativeLabel: 'Tracking Error (20Y)',
    dataType: 'PERCENTAGE',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Historical Performance',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'YEAR_20.ANNUALIZED_VOLATILITY',
    relativeMetricPath: 'YEAR_20.TRACKING_ERROR',
  },
  {
    key: 'maxDrawdown',
    label: 'Max drawdown',
    relativeLabel: 'Max underperformance',
    dataType: 'PERCENTAGE',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Historical Performance',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'ANALYSIS_PERIOD.MAX_DRAWDOWN',
    relativeMetricPath: 'ANALYSIS_PERIOD.MAX_UNDERPERFORMANCE',
  },
  {
    key: 'YEAR_1-maxDrawdown',
    label: 'Max drawdown (1Y)',
    relativeLabel: 'Max underperformance (1Y)',
    dataType: 'PERCENTAGE',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Historical Performance',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'YEAR_1.MAX_DRAWDOWN',
    relativeMetricPath: 'YEAR_1.MAX_UNDERPERFORMANCE',
  },
  {
    key: 'YEAR_3-maxDrawdown',
    label: 'Max drawdown (3Y)',
    relativeLabel: 'Max underperformance (3Y)',
    dataType: 'PERCENTAGE',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Historical Performance',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'YEAR_3.MAX_DRAWDOWN',
    relativeMetricPath: 'YEAR_3.MAX_UNDERPERFORMANCE',
  },
  {
    key: 'YEAR_5-maxDrawdown',
    label: 'Max drawdown (5Y)',
    relativeLabel: 'Max underperformance (5Y)',
    dataType: 'PERCENTAGE',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Historical Performance',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'YEAR_5.MAX_DRAWDOWN',
    relativeMetricPath: 'YEAR_5.MAX_UNDERPERFORMANCE',
  },
  {
    key: 'YEAR_10-maxDrawdown',
    label: 'Max drawdown (10Y)',
    relativeLabel: 'Max underperformance (10Y)',
    dataType: 'PERCENTAGE',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Historical Performance',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'YEAR_10.MAX_DRAWDOWN',
    relativeMetricPath: 'YEAR_10.MAX_UNDERPERFORMANCE',
  },
  {
    key: 'YEAR_15-maxDrawdown',
    label: 'Max drawdown (15Y)',
    relativeLabel: 'Max underperformance (15Y)',
    dataType: 'PERCENTAGE',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Historical Performance',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'YEAR_15.MAX_DRAWDOWN',
    relativeMetricPath: 'YEAR_15.MAX_UNDERPERFORMANCE',
  },
  {
    key: 'YEAR_20-maxDrawdown',
    label: 'Max drawdown (20Y)',
    relativeLabel: 'Max underperformance (20Y)',
    dataType: 'PERCENTAGE',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Historical Performance',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'YEAR_20.MAX_DRAWDOWN',
    relativeMetricPath: 'YEAR_20.MAX_UNDERPERFORMANCE',
  },
  {
    key: 'calendarReturns2023',
    label: '2023 Return',
    relativeLabel: '2023 Excess Return',
    dataType: 'PERCENTAGE',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Calendar Year Performance',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'FULL.CALENDAR_YEAR_2023_RETURN',
    relativeMetricPath: 'FULL.CALENDAR_YEAR_2023_EXCESS_RETURN',
  },
  {
    key: 'calendarReturns2022',
    label: '2022 Return',
    relativeLabel: '2022 Excess Return',
    dataType: 'PERCENTAGE',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Calendar Year Performance',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'FULL.CALENDAR_YEAR_2022_RETURN',
    relativeMetricPath: 'FULL.CALENDAR_YEAR_2022_EXCESS_RETURN',
  },
  {
    key: 'calendarReturns2021',
    label: '2021 Return',
    relativeLabel: '2021 Excess Return',
    dataType: 'PERCENTAGE',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Calendar Year Performance',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'FULL.CALENDAR_YEAR_2021_RETURN',
    relativeMetricPath: 'FULL.CALENDAR_YEAR_2021_EXCESS_RETURN',
  },
  {
    key: 'calendarReturns2020',
    label: '2020 Return',
    relativeLabel: '2020 Excess Return',
    dataType: 'PERCENTAGE',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Calendar Year Performance',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'FULL.CALENDAR_YEAR_2020_RETURN',
    relativeMetricPath: 'FULL.CALENDAR_YEAR_2020_EXCESS_RETURN',
  },
  {
    key: 'calendarReturns2019',
    label: '2019 Return',
    relativeLabel: '2019 Excess Return',
    dataType: 'PERCENTAGE',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Calendar Year Performance',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'FULL.CALENDAR_YEAR_2019_RETURN',
    relativeMetricPath: 'FULL.CALENDAR_YEAR_2019_EXCESS_RETURN',
  },
  {
    key: 'calendarReturns2018',
    label: '2018 Return',
    relativeLabel: '2018 Excess Return',
    dataType: 'PERCENTAGE',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Calendar Year Performance',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'FULL.CALENDAR_YEAR_2018_RETURN',
    relativeMetricPath: 'FULL.CALENDAR_YEAR_2018_EXCESS_RETURN',
  },
  {
    key: 'calendarReturns2017',
    label: '2017 Return',
    relativeLabel: '2017 Excess Return',
    dataType: 'PERCENTAGE',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Calendar Year Performance',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'FULL.CALENDAR_YEAR_2017_RETURN',
    relativeMetricPath: 'FULL.CALENDAR_YEAR_2017_EXCESS_RETURN',
  },
  {
    key: 'calendarReturns2016',
    label: '2016 Return',
    relativeLabel: '2016 Excess Return',
    dataType: 'PERCENTAGE',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Calendar Year Performance',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'FULL.CALENDAR_YEAR_2016_RETURN',
    relativeMetricPath: 'FULL.CALENDAR_YEAR_2016_EXCESS_RETURN',
  },
  {
    key: 'calendarReturns2015',
    label: '2015 Return',
    relativeLabel: '2015 Excess Return',
    dataType: 'PERCENTAGE',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Calendar Year Performance',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'FULL.CALENDAR_YEAR_2015_RETURN',
    relativeMetricPath: 'FULL.CALENDAR_YEAR_2015_EXCESS_RETURN',
  },
  {
    key: 'sortino',
    label: 'Sortino Ratio',
    relativeLabel: 'Sortino Ratio',
    dataType: 'NUMERIC',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Historical Risk Statistics',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'ANALYSIS_PERIOD.SORTINO',
    relativeMetricPath: 'ANALYSIS_PERIOD.RELATIVE_SORTINO',
  },
  {
    key: 'calmar',
    label: 'Calmar Ratio',
    relativeLabel: 'Calmar Ratio',
    dataType: 'NUMERIC',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Historical Risk Statistics',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'YEAR_3.CALMAR',
    relativeMetricPath: 'YEAR_3.RELATIVE_CALMAR',
  },
  {
    key: 'averageUpMonth',
    label: 'Average Up Month',
    relativeLabel: 'Average Up Month',
    dataType: 'PERCENTAGE',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Historical Performance',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'ANALYSIS_PERIOD.AVERAGE_UP_MONTH',
    relativeMetricPath: 'ANALYSIS_PERIOD.RELATIVE_AVERAGE_UP_MONTH',
  },
  {
    key: 'averageDownMonth',
    label: 'Average Down Month',
    relativeLabel: 'Average Down Month',
    dataType: 'PERCENTAGE',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Historical Performance',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'ANALYSIS_PERIOD.AVERAGE_DOWN_MONTH',
    relativeMetricPath: 'ANALYSIS_PERIOD.RELATIVE_AVERAGE_DOWN_MONTH',
  },
  {
    key: 'battingAverage',
    label: 'Batting Average',
    relativeLabel: 'Batting Average',
    dataType: 'PERCENTAGE',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Historical Risk Statistics',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'ANALYSIS_PERIOD.BATTING_AVERAGE',
    relativeMetricPath: 'ANALYSIS_PERIOD.RELATIVE_BATTING_AVERAGE',
  },
  {
    key: 'conditionalValueAtRisk95',
    label: 'CVaR (5%)',
    relativeLabel: 'CVaR (5%)',
    dataType: 'PERCENTAGE',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Historical Risk Statistics',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'ANALYSIS_PERIOD.CONDITIONAL_VALUE_AT_RISK_C_95',
    relativeMetricPath: 'ANALYSIS_PERIOD.RELATIVE_CONDITIONAL_VALUE_AT_RISK_C_95',
  },
  {
    key: 'valueAtRisk',
    label: 'VaR (95%)',
    relativeLabel: 'VaR (95%)',
    dataType: 'PERCENTAGE',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Historical Risk Statistics',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'ANALYSIS_PERIOD.VALUE_AT_RISK_C_95',
    relativeMetricPath: 'ANALYSIS_PERIOD.RELATIVE_VALUE_AT_RISK_C_95',
  },
  {
    key: 'valueAtRisk975',
    label: 'VaR (97.5%)',
    relativeLabel: 'VaR (97.5%)',
    dataType: 'PERCENTAGE',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Historical Risk Statistics',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'ANALYSIS_PERIOD.VALUE_AT_RISK_C_97_5',
    relativeMetricPath: 'ANALYSIS_PERIOD.RELATIVE_VALUE_AT_RISK_C_97_5',
  },
  {
    key: 'valueAtRisk99',
    label: 'VaR (99%)',
    relativeLabel: 'VaR (99%)',
    dataType: 'PERCENTAGE',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Historical Risk Statistics',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'ANALYSIS_PERIOD.VALUE_AT_RISK_C_99',
    relativeMetricPath: 'ANALYSIS_PERIOD.RELATIVE_VALUE_AT_RISK_C_99',
  },
  {
    key: 'skewness',
    label: 'Skewness',
    relativeLabel: 'Skewness',
    dataType: 'NUMERIC',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Historical Risk Statistics',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'ANALYSIS_PERIOD.SKEWNESS',
    relativeMetricPath: 'ANALYSIS_PERIOD.RELATIVE_SKEWNESS',
  },
  {
    key: 'kurtosis',
    label: 'Kurtosis',
    relativeLabel: 'Kurtosis',
    dataType: 'NUMERIC',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Historical Risk Statistics',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'ANALYSIS_PERIOD.KURTOSIS',
    relativeMetricPath: 'ANALYSIS_PERIOD.RELATIVE_KURTOSIS',
  },
  {
    key: 'betaToBenchmark',
    label: 'Beta To Benchmark',
    relativeLabel: 'Beta To Benchmark',
    dataType: 'NUMERIC',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Benchmark Required Statistics',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'ANALYSIS_PERIOD.BETA_TO_BENCHMARK',
    needsBenchmark: true,
    relativeMetricPath: 'ANALYSIS_PERIOD.BETA_TO_BENCHMARK',
  },
  {
    key: 'correlation',
    label: 'Correlation',
    relativeLabel: 'Correlation',
    dataType: 'NUMERIC',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Benchmark Required Statistics',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'ANALYSIS_PERIOD.BENCHMARK_CORRELATION',
    needsBenchmark: true,
    relativeMetricPath: 'ANALYSIS_PERIOD.BENCHMARK_CORRELATION',
  },
  {
    key: 'downsideCorrelation',
    label: 'Downside Correlation',
    relativeLabel: 'Downside Correlation',
    dataType: 'NUMERIC',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Benchmark Required Statistics',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'ANALYSIS_PERIOD.DOWNSIDE_CORRELATION',
    needsBenchmark: true,
    relativeMetricPath: 'ANALYSIS_PERIOD.DOWNSIDE_CORRELATION',
  },
  {
    key: 'downsideCaptureRatio',
    label: 'Downside Capture',
    relativeLabel: 'Downside Capture',
    dataType: 'PERCENTAGE',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Benchmark Required Statistics',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'ANALYSIS_PERIOD.DOWNSIDE_CAPTURE_RATIO',
    needsBenchmark: true,
    relativeMetricPath: 'ANALYSIS_PERIOD.DOWNSIDE_CAPTURE_RATIO',
  },
  {
    key: 'upsideCaptureRatio',
    label: 'Upside Capture',
    relativeLabel: 'Upside Capture',
    dataType: 'PERCENTAGE',
    analysisType: 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS',
    category: 'Benchmark Required Statistics',
    analysisResultKey: 'peerGroupAnalysis',
    metricPath: 'ANALYSIS_PERIOD.UPSIDE_CAPTURE_RATIO',
    needsBenchmark: true,
    relativeMetricPath: 'ANALYSIS_PERIOD.UPSIDE_CAPTURE_RATIO',
  },
];
