import type { FC } from 'react';
import React, { useContext } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { getMostRecentAnalysis, Routes } from 'venn-utils';
import { UserContext } from 'venn-components';

const RecentAnalysisPage: FC<React.PropsWithChildren<RouteComponentProps>> = (props) => {
  const { profileSettings } = useContext(UserContext);
  const userId = profileSettings?.user.id;
  const orgId = profileSettings?.organization.id;

  if (!userId || !orgId) {
    return <Redirect to={Routes.HOME_PATH} />;
  }

  const recentAnalysisPath = getMostRecentAnalysis(userId, orgId);

  if (recentAnalysisPath) {
    const { url, state } = recentAnalysisPath;
    const [pathname, search] = url.split('?');
    return (
      <Redirect
        to={{
          pathname,
          search: `?${search}`,
          state: { ...(props.location?.state ?? {}), reservedState: state },
        }}
      />
    );
  }

  /** Empty State */
  return <Redirect to={Routes.DEFAULT_ANALYSIS_PATH} />;
};

export default RecentAnalysisPage;
