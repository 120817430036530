import clamp from 'lodash/clamp';
import flowRight from 'lodash/flowRight';
import round from 'lodash/round';
import identity from 'lodash/identity';

type NumericValue = string | number;
export type Formatter = (numericValue: NumericValue) => NumericValue;

export const prepareNumber = (
  value: number,
  { min = -Infinity, max = Infinity, precision = 2, formatter = identity as Formatter } = {},
): NumericValue =>
  flowRight(
    formatter,
    (numericValue: number) => clamp(numericValue, min, max),
    (numericValue: number) => round(numericValue, precision),
  )(value);
