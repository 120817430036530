import { useCallback, useEffect, useState } from 'react';
import type { SignupRequest } from 'venn-api';
import { sendInvitation } from 'venn-api';
import { logExceptionIntoSentry } from 'venn-utils';

interface UseSendSponsorInvitationValue {
  error?: string;
  loading: boolean;
  send: () => void;
}

const useSendSponsorInvitation = (
  firstName: string,
  lastName: string,
  email: string,
  accountName: string,
  onSuccess: () => void,
  refreshAccounts: () => void,
): UseSendSponsorInvitationValue => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!firstName || !lastName || !email || !accountName) {
      setError('Missing required fields');
    } else {
      setError(undefined);
    }
  }, [firstName, lastName, email, accountName]);

  const send = useCallback(async () => {
    setLoading(true);
    const invite = {
      firstName,
      lastName,
      email,
      organizationLegalName: accountName,
    } as SignupRequest;

    try {
      await sendInvitation(invite);
    } catch (e) {
      logExceptionIntoSentry(e);
      setLoading(false);
      return;
    }

    setLoading(false);
    refreshAccounts();
    onSuccess();
  }, [firstName, lastName, email, accountName, onSuccess, refreshAccounts]);

  return {
    error,
    loading,
    send,
  };
};

export default useSendSponsorInvitation;
