import { AuthLogo, ContentWrapper, Info, StyledHeadline1 } from '../components';
import React, { useState } from 'react';
import { Caption, Subtitle1 } from 'venn-ui-kit';
import { TwoFactorOptions } from 'venn-components';
import { assertExhaustive, Routes } from 'venn-utils';
import EnableSMS from './EnableSMS';
import styled from 'styled-components';
import EnableAuthenticator from './EnableAuthenticator';
import BackupCodesWrapper from './BackupCodes';
import type { History } from 'history';

export const TwoFactorSetupRoute = '/2fa-setup';

enum TwoFactorType {
  AUTHENTICATOR = 'authenticator',
  SMS = 'sms',
}

const BACKUP_CODE_MESSAGE_BY_2FA_TYPE: { [k in TwoFactorType]: string } = {
  [TwoFactorType.AUTHENTICATOR]: 'the Google Authenticator app',
  [TwoFactorType.SMS]: 'my text messages',
};

interface TwoFactorSetupProps {
  history: History;
  onSubmit: () => void;
}

const TwoFactorSetup = ({ history, onSubmit }: TwoFactorSetupProps) => {
  const [twoFactorType, setTwoFactorType] = useState<TwoFactorType>();
  const [showBackupCodes, setShowBackupCodes] = useState(false);

  const onComplete = () => {
    setShowBackupCodes(true);
  };

  const onChooseADifferentMethod = () => {
    setTwoFactorType(undefined);
  };

  if (twoFactorType) {
    if (showBackupCodes) {
      return (
        <BackupCodesWrapper
          onComplete={() => {
            onSubmit();
            history.replace(Routes.HOME_PATH);
          }}
          confirmMessage={BACKUP_CODE_MESSAGE_BY_2FA_TYPE[twoFactorType]}
        />
      );
    }

    switch (twoFactorType) {
      case TwoFactorType.AUTHENTICATOR:
        return <EnableAuthenticator onComplete={onComplete} onChooseADifferentMethod={onChooseADifferentMethod} />;
      case TwoFactorType.SMS:
        return <EnableSMS onComplete={onComplete} onChooseADifferentMethod={onChooseADifferentMethod} />;
      default:
        throw assertExhaustive(twoFactorType, 'Unexpected two-factor type');
    }
  }

  return (
    <ContentWrapper>
      <AuthLogo />
      <Info>
        <StyledHeadline1>Set secondary authentication method</StyledHeadline1>
        <Subtitle1>
          Two-factor authentication helps secure your account by adding an additional layer of security by asking for a
          code every time you log in. Your workspace requires this as part of the sign-up process.
        </Subtitle1>
      </Info>
      <OptionsContainer>
        <TwoFactorOptions
          onSelectGoogleAuthentication={() => setTwoFactorType(TwoFactorType.AUTHENTICATOR)}
          onSelectSMS={() => setTwoFactorType(TwoFactorType.SMS)}
        />
      </OptionsContainer>
      <Caption inverted>Not sure which option to choose? Ask your Workspace Administrator for help.</Caption>
    </ContentWrapper>
  );
};

export default TwoFactorSetup;

const OptionsContainer = styled.div`
  margin-top: 30px;
  width: 100%;
  > div > button {
    width: 100%;
    max-width: 450px;
  }
`;
