import React from 'react';
import type {
  Portfolio,
  OptimizationConfiguration,
  PortfolioSummary,
  AllocationConstraint,
  InvestmentFactorForecast,
} from 'venn-api';
import noop from 'lodash/noop';

export interface MissedTarget {
  targetMetric: 'Return' | 'Volatility';
  value: number;
}

export interface AllocationConstraintsProviderOptions {
  isStrategy: boolean;
  nodeAllocation: number;
  totalAllocation: number;
  isRoot: boolean;
  isSelected: boolean;
  isInSelectedSubtree: boolean;
  parentNodeAllocation?: number;
}

export interface AllocationConstraintsProviderResult {
  min: number;
  max: number;
  customConstraintInfo?: string;
  defaultConstraintInfo?: string;
}

export type OptimizationErrorType = 'OPTIMIZATION_FAILED' | 'NO_DEFAULT_CONSTRAINTS';

interface OptimalPortfolioContextValue {
  hasAccessToOptimization: boolean;
  optimalPortfolio: Portfolio | null;
  loading: boolean;
  optimizationError?: OptimizationErrorType;
  missedTarget: MissedTarget | null;
  canRerunOptimization: boolean;
  setCanRerunOptimization: (canRerunOptimization: boolean) => void;
  updateOptimizationContextAfterSave: (
    savedPortfolio: Portfolio,
    nodeIdMapper?: Map<number, number>,
    clearAllNew?: boolean,
  ) => void;
  setOptimizationResult: (
    resultPortfolioId: number,
    resultSummary: PortfolioSummary,
    resultConfig: OptimizationConfiguration,
    resultAdHocPortfolio?: Portfolio,
    customAllocationConstraints?: AllocationConstraint[],
  ) => void;
  optimizeAdHoc: () => void;
  customAllocationConstraints?: AllocationConstraint[];
  investmentForecasts?: { [key: string]: InvestmentFactorForecast };
  onForecastUpdate?: (fundId: string) => void;
}

export default React.createContext<OptimalPortfolioContextValue>({
  hasAccessToOptimization: false,
  optimalPortfolio: null,
  loading: false,
  optimizationError: undefined,
  missedTarget: null,
  canRerunOptimization: false,
  setCanRerunOptimization: noop,
  updateOptimizationContextAfterSave: noop,
  setOptimizationResult: noop,
  optimizeAdHoc: noop,
  customAllocationConstraints: undefined,
  investmentForecasts: undefined,
  onForecastUpdate: undefined,
} as OptimalPortfolioContextValue);
