import type { RollingAnalysisResponse } from 'venn-api';
import type { SerieData, ChartDataWithRollingPeriods, FullPeriodLabels, RollingPeriod } from '../types';
import { RollingPeriodEnum } from '../types';
import type { AnalysisSubject, AnalysisGroup } from 'venn-utils';
import type { DownloadMetaData } from 'venn-components';
import { cloneDeep, isNil } from 'lodash';

export const buildDataWithRollingPeriodsForAnalysis = (
  analysisGroup: AnalysisGroup<RollingAnalysisResponse>[],
  subject: AnalysisSubject,
  fullPeriodLabels: FullPeriodLabels,
  customPeriodAnalysisGroup?: AnalysisGroup<RollingAnalysisResponse>,
): ChartDataWithRollingPeriods | undefined => {
  if (!analysisGroup) {
    return undefined;
  }
  const mainData = buildRollingData(analysisGroup, (analysis) => analysis?.subject?.rolling ?? []);
  const hasSecondary = analysisGroup.length > 0 && analysisGroup[0] && analysisGroup[0].category;
  const benchmarkData = !subject.activeBenchmark
    ? undefined
    : buildRollingData(analysisGroup, (analysis) => analysis?.benchmark?.rolling ?? []);
  const secondaryData = !hasSecondary
    ? undefined
    : buildRollingData(analysisGroup, (analysis) => analysis?.category?.rolling ?? []);
  const annualized = analysisGroup?.[0]?.subject?.annualized ?? customPeriodAnalysisGroup?.subject?.annualized;
  const cumulative = analysisGroup?.[0]?.subject?.cumulative ?? customPeriodAnalysisGroup?.subject?.cumulative;
  return {
    main: {
      name: subject.name,
      data: mainData,
      fullPeriodValueLine: {
        absolute: {
          label: `${fullPeriodLabels.absolute}${isNil(annualized) && !isNil(cumulative) ? ' (cumulative)' : ''}`,
          value: annualized ?? cumulative ?? undefined,
        },
        relative: {
          label: `${fullPeriodLabels.relative}${isNil(annualized) && !isNil(cumulative) ? ' (cumulative)' : ''}`,
          value: annualized ?? cumulative ?? undefined,
        },
      },
      customData: buildCustomData(customPeriodAnalysisGroup, 'subject'),
    },
    secondary:
      !secondaryData || subject.type !== 'portfolio'
        ? undefined
        : {
            name: subject?.secondaryPortfolio?.name || '',
            data: secondaryData,
            customData: buildCustomData(customPeriodAnalysisGroup, 'category'),
          },
    benchmark: !benchmarkData
      ? undefined
      : {
          name: subject?.activeBenchmarkName || '',
          data: benchmarkData,
          customData: buildCustomData(customPeriodAnalysisGroup, 'benchmark'),
        },
    category:
      !secondaryData || subject.type !== 'investment'
        ? undefined
        : {
            name: subject?.categoryGroup?.name || '',
            data: secondaryData,
            customData: buildCustomData(customPeriodAnalysisGroup, 'category'),
          },
  };
};

const buildRollingData = (
  analysisGroup: AnalysisGroup<RollingAnalysisResponse>[],
  getter: (analysis: AnalysisGroup<RollingAnalysisResponse>) => number[][],
): { [key in RollingPeriodEnum]: SerieData } => ({
  [RollingPeriodEnum.ONE_YEAR]: { absolute: getter(analysisGroup[0]), relative: getter(analysisGroup[0]) },
  [RollingPeriodEnum.THREE_YEAR]: { absolute: getter(analysisGroup[1]), relative: getter(analysisGroup[1]) },
  [RollingPeriodEnum.FIVE_YEAR]: { absolute: getter(analysisGroup[2]), relative: getter(analysisGroup[2]) },
});

const buildCustomData = (
  customGroup: AnalysisGroup<RollingAnalysisResponse> | undefined,
  accessor: string,
): SerieData | undefined =>
  customGroup?.[accessor]?.rolling
    ? {
        absolute: customGroup?.[accessor]?.rolling,
        relative: customGroup?.[accessor]?.rolling,
      }
    : undefined;

const ROLLING_PERIOD_LABEL = 'Rolling Period';

export const getMetaDataWithRollingPeriod = (
  downloadMetaData: DownloadMetaData | undefined,
  rollingPeriod: RollingPeriod | undefined,
  customPeriodMonths: number | undefined,
  subjectName: string | undefined = '--',
) => {
  const metaData: DownloadMetaData = isNil(downloadMetaData) ? { subjectName } : cloneDeep(downloadMetaData);
  if (isNil(metaData.extra)) {
    metaData.extra = [];
  }
  const rollingPeriodIndex = metaData.extra.findIndex((item) => item.label === ROLLING_PERIOD_LABEL);
  if (rollingPeriodIndex === -1) {
    metaData.extra.push({ label: ROLLING_PERIOD_LABEL, value: rollingPeriod?.name ?? `${customPeriodMonths}M` });
  } else {
    metaData.extra[rollingPeriodIndex].value = rollingPeriod?.name ?? `${customPeriodMonths}M`;
  }
  return metaData;
};
