import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import styled, { css } from 'styled-components';
import { selectedLibraryTabAtom } from 'venn-state';
import { Flexbox, getAppTitle, GetColor, getTextThemeProvider, Icon, PRIVATE_ASSET_LAB_FAQ_HREF } from 'venn-ui-kit';
import {
  analyticsService,
  LibraryTab,
  PrivateAssetLibraryRoute,
  ReturnsDataLibraryRoute,
  useHasFF,
  userActivity,
} from 'venn-utils';
import { StyledHr } from '../../../account-page/src/components/integrations/addepar/styles';

const LOCAL_STORAGE_KEY = 'privates_upsell_dismissed';

const usePrivatesUpsellTooltip = (): [boolean, () => void, () => void] => {
  const [isTooltipShown, setIsTooltipShown] = useState(
    userActivity.getLocalStorageItemPerUser(LOCAL_STORAGE_KEY) !== 'true',
  );

  const showTooltip = () => {
    setIsTooltipShown(true);
  };
  const hideTooltip = () => {
    setIsTooltipShown(false);
    userActivity.setLocalStorageItemPerUser(LOCAL_STORAGE_KEY, 'true');
  };

  return [isTooltipShown, showTooltip, hideTooltip];
};

const PrivatesUpsellInfo = () => {
  const [isTooltipShown, showTooltip, hideTooltip] = usePrivatesUpsellTooltip();

  if (isTooltipShown) {
    return (
      <TooltipWrapper>
        &nbsp;
        <Tooltip>
          <Flexbox direction="row" gap={10}>
            <Flexbox direction="column" gap={10}>
              <div>
                <b>To access Private Asset Lab,</b> contact your {getAppTitle()} Customer Support Representative.
              </div>
              <div>
                <button
                  type="button"
                  onClick={() => {
                    window.open(PRIVATE_ASSET_LAB_FAQ_HREF, '_blank', 'noopener,noreferrer');
                    analyticsService.ctaClicked({
                      destination: 'Private Asset Lab help article',
                      filled: true,
                      locationOnPage: 'Data library privates paywall',
                      purpose: 'Inquire about Private Asset lab',
                      text: 'LEARN MORE',
                      type: 'button',
                    });
                  }}
                >
                  LEARN MORE
                </button>{' '}
                |{' '}
                <button
                  type="button"
                  onClick={() => {
                    window.open(
                      `mailto:${getTextThemeProvider().salesEmail}?subject=Private Asset Lab Inquiry`,
                      '_blank',
                      'noopener,noreferrer',
                    );
                    analyticsService.ctaClicked({
                      destination: 'Private Asset Lab inquiry email',
                      filled: true,
                      locationOnPage: 'Data library privates paywall',
                      purpose: 'Inquire about Private Asset lab',
                      text: 'CONTACT SUPPORT',
                      type: 'button',
                    });
                  }}
                >
                  CONTACT SUPPORT
                </button>
              </div>
            </Flexbox>
            <div>
              <CloseTooltipButton data-testid="qa-close-button" onClick={hideTooltip}>
                <Icon type="close" />
              </CloseTooltipButton>
            </div>
          </Flexbox>
        </Tooltip>
      </TooltipWrapper>
    );
  }

  return (
    <Badge onClick={showTooltip}>
      <Icon type="lock" /> New! Contact us to access
    </Badge>
  );
};

export const LibraryTabs = () => {
  const history = useHistory();
  const userHasPrivates = useHasFF('private_analytics');
  const [selectedTab, setSelectedTab] = useState<LibraryTab>(
    history.location.pathname === PrivateAssetLibraryRoute ? LibraryTab.PrivateAssets : LibraryTab.ReturnsData,
  );
  const persistSelectedTab = useSetRecoilState(selectedLibraryTabAtom);
  // persist the changes to the recoil atom so that it can be read from anywhere
  useEffect(() => {
    persistSelectedTab(selectedTab);
  }, [selectedTab, persistSelectedTab]);

  const changeTab = useCallback(
    (tab: LibraryTab) => {
      if (!userHasPrivates || selectedTab === tab) {
        // changing the tab only allowed if FF ON, and it's a different tab
        return;
      }
      setSelectedTab(tab);
      analyticsService.ctaClicked({
        purpose: `Navigate to ${tab === LibraryTab.PrivateAssets ? 'Private Asset' : 'Returns'} Data Library`,
        text: `${tab === LibraryTab.PrivateAssets ? 'Private Asset' : 'Returns'}`,
      });
    },
    [selectedTab, setSelectedTab, userHasPrivates],
  );

  /** Synchronize selected tab with the URL */
  useEffect(() => {
    if (selectedTab === LibraryTab.PrivateAssets && history.location.pathname !== PrivateAssetLibraryRoute) {
      history.push(PrivateAssetLibraryRoute);
    }
    if (selectedTab === LibraryTab.ReturnsData && history.location.pathname !== ReturnsDataLibraryRoute) {
      history.push(ReturnsDataLibraryRoute);
    }
  }, [selectedTab, history, history.location.pathname]);

  return (
    <div>
      <TabsContainer>
        <StyledButton
          tabSelected={selectedTab === LibraryTab.ReturnsData}
          onClick={() => changeTab(LibraryTab.ReturnsData)}
        >
          Returns Data
        </StyledButton>
        <Flexbox direction="row" alignItems="baseline" columnGap={8}>
          <StyledButton
            tabSelected={selectedTab === LibraryTab.PrivateAssets}
            onClick={() => changeTab(LibraryTab.PrivateAssets)}
            disabled={!userHasPrivates}
          >
            Private Asset Data
          </StyledButton>
          {!userHasPrivates && <PrivatesUpsellInfo />}
        </Flexbox>
      </TabsContainer>
      <VerticalHighlight />
    </div>
  );
};

const TabsContainer = styled.div`
  position: sticky;
  margin-top: 32px;
  display: flex;
  align-items: center;
  width: 500px;
  height: 34px;
  gap: 20px;
`;

const StyledButton = styled.button<{
  tabSelected: boolean;
}>`
  ${(props) =>
    props.tabSelected
      ? css`
          color: ${GetColor.Black};
          border-bottom: 6px solid ${GetColor.Primary.Main};
        `
      : css`
          color: ${GetColor.DarkGrey};
          border-bottom: 6px solid ${GetColor.White};
        `}
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  padding: 2px;
  border-left: none;
  border-right: none;
  border-top: none;
  text-transform: capitalize;
`;

const VerticalHighlight = styled(StyledHr)`
  background-color: ${GetColor.Grey};
  margin-top: 0px;
`;

const Badge = styled.button`
  background-color: ${GetColor.HighlightDark};
  color: ${GetColor.White};
  font-size: 11px;
  padding: 2px 6px;
  border-radius: 4px;
  font-weight: 700;

  &:hover {
    color: ${GetColor.White};
  }
`;

const TooltipWrapper = styled.div`
  position: relative;
`;

const Tooltip = styled.div`
  position: absolute;
  background-color: ${GetColor.HighlightDark};
  color: ${GetColor.White};
  font-size: 12px;
  padding: 6px 10px;
  border-radius: 6px;
  top: 50%;
  transform: translateY(-50%);
  width: 300px;

  &::after {
    content: ' ';
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent ${GetColor.HighlightDark} transparent transparent;
  }

  & button {
    color: ${GetColor.White};
    font-weight: bold;
  }
`;

const CloseTooltipButton = styled.button`
  color: ${GetColor.White};

  &:hover {
    color: ${GetColor.White};
  }
`;
