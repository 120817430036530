import type { ValueFormatterParams } from 'ag-grid-community';
import type { CashFlowOverrideTypeEnum } from 'venn-api';
import { formatData } from '../../../data-grid';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import React from 'react';
import { Dates } from 'venn-utils';

export const percentageValueFormatter = ({ value }: ValueFormatterParams) => {
  return formatData(value, 'PERCENTAGE', 2);
};

export const numericValueFormatter = ({ value }: ValueFormatterParams) => {
  return formatData(value, 'NUMERIC', 2);
};

export const vintageFormatter = (value?: number) => {
  return value ? Dates.toYear(value) : '';
};

type ClassificationCellRendererProps = {
  value?: string | null;
  placeholder: string;
  overrideType?: CashFlowOverrideTypeEnum;
};

export const EM_DASH = '\u2014';

export const ClassificationCellRenderer = ({ value, placeholder, overrideType }: ClassificationCellRendererProps) => {
  if (overrideType === 'PARAMETER') {
    return <span>{EM_DASH}</span>;
  }

  if (isEmpty(value)) {
    return <ClassificationPlaceholder>{placeholder}</ClassificationPlaceholder>;
  }

  return <span>{value}</span>;
};

const ClassificationPlaceholder = styled.span`
  font-style: italic;
`;
