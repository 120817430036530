import React from 'react';
import styled from 'styled-components';
import { getShortComparisonLabel, getComparisonLabelForTooltip } from 'venn-utils';
import {
  EllipsisTooltipSpan,
  GetColor,
  Icon,
  ItemIcon,
  ItemType,
  Tooltip,
  TooltipBodyDirection,
  TooltipPosition,
} from 'venn-ui-kit';
import { isNil } from 'lodash';
import { SubjectName } from './shared';
import { useRecoilValue } from 'recoil';
import type { StudioRequestSubject } from 'venn-state';
import { openAllocatorSubject } from 'venn-state';

interface SecondarySubjectLineProps {
  subject: StudioRequestSubject;
  excluded?: boolean;
  onDelete?: () => void;
}

const SecondarySubjectLine = ({ subject, excluded, onDelete }: SecondarySubjectLineProps) => {
  const subjectInAllocator = useRecoilValue(openAllocatorSubject);

  const {
    primaryPortfolio,
    portfolio: secondaryPortfolio,
    strategy: secondaryStrategy,
    portfolioComparisonType,
  } = subject;
  if (isNil(primaryPortfolio) || isNil(secondaryPortfolio) || isNil(portfolioComparisonType)) {
    return null;
  }

  const canDelete =
    (isNil(subjectInAllocator) || subjectInAllocator.portfolioId !== primaryPortfolio.id) && !isNil(onDelete);
  const itemLabel = getShortComparisonLabel(
    secondaryPortfolio,
    secondaryStrategy,
    portfolioComparisonType,
    primaryPortfolio,
  );

  return (
    <SubjectName secondary key={`${portfolioComparisonType}-${itemLabel}`} disabled={excluded}>
      {portfolioComparisonType === 'SAVED' ? (
        <Tooltip
          content={getComparisonLabelForTooltip(
            secondaryPortfolio,
            secondaryStrategy,
            portfolioComparisonType,
            primaryPortfolio,
          )}
          bodyDirection={TooltipBodyDirection.Right}
        >
          <Icon type="clock-rotate-left" />
        </Tooltip>
      ) : (
        <ItemIcon item={ItemType.Portfolio} />
      )}
      <EllipsisTooltipSpan>{itemLabel}</EllipsisTooltipSpan>
      {canDelete && (
        <DeleteButton onClick={onDelete}>
          <Icon type="trash" />
        </DeleteButton>
      )}
      {excluded && (
        <RightSideItem>
          <Tooltip content="This object has been excluded from this block" position={TooltipPosition.Left} usePortal>
            <Icon type="ban" />
          </Tooltip>
        </RightSideItem>
      )}
    </SubjectName>
  );
};

export default SecondarySubjectLine;

const RightSideItem = styled.div`
  position: relative;
  right: 9px;
  margin-left: 8px;
  i {
    color: ${GetColor.GreyScale.Grey60};
  }
`;

const DeleteButton = styled.button`
  visibility: hidden;
  position: absolute;
  right: -5px;
  i {
    color: ${GetColor.Grey};
    :hover {
      color: ${GetColor.Primary.Dark};
      cursor: pointer;
    }
  }
`;
