import React from 'react';
import { analyticsService, useDisablePrintHotkey, useHasFF, useModal } from 'venn-utils';
import Modal from '../modal/Modal';
import ModalHeader, { ModalSubhead } from '../modal/ModalHeader';
import ModalFooter from '../modal/ModalFooter';
import { getTextThemeProvider } from 'venn-ui-kit';
import { ModalSize } from '../modal';
import { PrintDisabledText } from './shared';

export const PrintDisabledModal = ({ isPrintDisabled }: { isPrintDisabled: boolean }) => {
  const [isOpen, onOpen, onClose] = useModal();
  const hasReportLab = useHasFF('studio_report_editor');

  useDisablePrintHotkey(isPrintDisabled && !hasReportLab, onOpen);

  const onPrimaryClick = () => {
    if (!hasReportLab) {
      window.location.href = `mailto:${getTextThemeProvider().salesEmail}?subject=Report Lab Inquiry`;
      analyticsService.ctaClicked({
        destination: 'Report lab inquiry email',
        filled: true,
        locationOnPage: 'Print disabled modal',
        purpose: 'Inquire about report lab',
        text: `Contact ${getTextThemeProvider().AppTitle}`,
        type: 'button',
      });
    }
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Modal size={ModalSize.Large}>
      <ModalHeader>{PrintDisabledText.header}</ModalHeader>
      <ModalSubhead>{PrintDisabledText.subHeader}</ModalSubhead>
      <ModalFooter
        onCancel={onClose}
        primaryLabel={hasReportLab ? 'Dismiss' : `Contact ${getTextThemeProvider().AppTitle}`}
        onPrimaryClick={onPrimaryClick}
      />
    </Modal>
  );
};
