import { useCallback, useEffect, useState } from 'react';
import { userActivity } from 'venn-utils';

/** A custom hook for persisting library searches in local storage */
export const useRecentLibrarySearches = (CACHE_SIZE: number) => {
  const [recentSearches, setRecentSearches] = useState<string[]>([]);
  useEffect(() => {
    const storageParams = userActivity.getLocalStorageItemPerUser(userActivity.USER_LIBRARY_RECENT_QUERIES);
    const queries = storageParams ? JSON.parse(storageParams) : [];
    setRecentSearches(queries);
  }, []);

  const saveRecentSearch = useCallback(
    (query: string | undefined) => {
      if (!query || !query.trim()) {
        return;
      }
      const storageParams = userActivity.getLocalStorageItemPerUser(userActivity.USER_LIBRARY_RECENT_QUERIES);
      let queries: string[] = storageParams ? JSON.parse(storageParams) : [];
      queries = queries.filter((q) => q && query.toLocaleLowerCase() !== q.toLocaleLowerCase());
      queries.unshift(query);
      queries = queries.slice(0, CACHE_SIZE);
      setRecentSearches(queries);
      userActivity.setLocalStorageItemPerUser(userActivity.USER_LIBRARY_RECENT_QUERIES, JSON.stringify(queries));
    },
    [setRecentSearches, CACHE_SIZE],
  );

  return {
    recentSearches,
    saveRecentSearch,
  };
};
