/*
 * Venn Platform API 1.0.0 Operations for "signup-v3"
 * Automatically generated file - do not modify!
 */

import type { AccountCreationRequest, SignupRequest, SignupResponse } from '../types';
import type { OperationResult } from '../../utils';
import { buildRequestInit, extractError } from '../../utils';

/** Activate a new user account */
export async function activateAccountV3(
  body?: Partial<AccountCreationRequest>,
  signal?: AbortSignal,
): Promise<OperationResult<SignupResponse>> {
  const endpoint = '/api/signup/v3/activate';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: SignupResponse = (await response.json()) as SignupResponse;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Add the user to the waitlist */
export async function waitlistUserSignupV3(
  body?: Partial<SignupRequest>,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = '/api/signup/v3/waitlist';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Sign up a new user - V3 */
export async function attemptUserSignupV3(
  body?: Partial<SignupRequest>,
  signal?: AbortSignal,
): Promise<OperationResult<SignupResponse>> {
  const endpoint = '/api/signup/v3/submit';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: SignupResponse = (await response.json()) as SignupResponse;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
