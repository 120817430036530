import React, { useEffect, useLayoutEffect, useRef, useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import StickyNode from '../sticky-node/StickyNode';
import type { ItemClickHandler, SideMenuItem, SideMenuSection } from '../side-menu/SideMenu';
import SideMenu from '../side-menu/SideMenu';
import { GetColor, ZIndex } from 'venn-ui-kit';
import { scrollToElementByQuerySelector, VennEvents } from 'venn-utils';
import isNil from 'lodash/isNil';
import isEqual from 'lodash/isEqual';

interface SideNavStickyState {
  bottom: boolean;
  left?: number;
  top?: number;
  height?: number;
}

const DEFAULT_PAGE_PADDING = 60;
const DEFAULT_SIDE_NAV_WIDTH = 238;

export interface StickyHeaderWithSideNavProps<T> {
  /* SideMenu sub-components props */
  items: SideMenuItem<T>[];
  sideMenuSections?: SideMenuSection<T>[];
  onSideNavClick: ItemClickHandler<T>;
  onToggleSideNavCheckbox?: ItemClickHandler<T>;
  selectedItems: T | T[];
  resetSelectedItems?: () => void;
  /** The width of the side nav plus the distance between the side nav and the page content */
  sideNavWidth?: number;

  HeaderContent: JSX.Element;
  PageContent: JSX.Element;
  pageContentContainerSelector: string;
  pagePadding: number;
  /** Whether the page content display is set to flex or not, defaults to false */
  pageDisplayFlex?: boolean;
  headerSidePadding: number;
  /** Space between the sticky header and the side nav.
   * (Add margin-top to the page content separately for space between the header and the page content.
   * DON'T add margin-bottom to the sticky header because IE */
  headerBottomPadding: number;
  scrollStartOffset: number;
  sideMenuTitle?: string;
  valueToObserveForResize?: boolean;
  outerContainerMargin?: number;
  disableAutoScroll?: boolean;
}

function StickyHeaderWithSideNav<T>({
  items,
  sideMenuSections,
  selectedItems,
  onSideNavClick,
  onToggleSideNavCheckbox,
  resetSelectedItems,
  HeaderContent,
  PageContent,
  pageContentContainerSelector,
  pagePadding = DEFAULT_PAGE_PADDING,
  headerSidePadding,
  headerBottomPadding,
  sideNavWidth = DEFAULT_SIDE_NAV_WIDTH,
  sideMenuTitle,
  valueToObserveForResize,
  scrollStartOffset,
  outerContainerMargin = 0,
  pageDisplayFlex = false,
  disableAutoScroll,
}: StickyHeaderWithSideNavProps<T>) {
  const stickyHeaderRef = useRef<HTMLDivElement>(null);
  const sideNavRef = useRef<HTMLDivElement>(null);
  const selectorRef = useRef<string>(pageContentContainerSelector);
  const pagePaddingRef = useRef<number>(pagePadding);
  const headerBottomPaddingRef = useRef<number>(headerBottomPadding);
  const [headerSticky, setHeaderSticky] = useState(false);
  const [sidePanelSticky, setSidePanelSticky] = useState<SideNavStickyState>({
    bottom: false,
  });

  const contentHeight = document.querySelector(selectorRef?.current)?.getBoundingClientRect()?.height;
  const isInnerNavScrollable = useMemo(
    () => !isNil(contentHeight) && !isNil(sidePanelSticky.height) && contentHeight < sidePanelSticky.height,
    [contentHeight, sidePanelSticky.height],
  );

  const getSideNavStickyState = useCallback(
    (prevSticky: SideNavStickyState): SideNavStickyState => {
      const mainElement = sideNavRef.current?.closest('main');
      if (!mainElement) {
        return prevSticky;
      }
      const mainLeft = mainElement?.getBoundingClientRect()?.left;
      const headerHeight = stickyHeaderRef.current?.getBoundingClientRect()?.height;
      const headerBottom = stickyHeaderRef.current?.getBoundingClientRect()?.bottom;
      const top =
        !isNil(headerHeight) && !isNil(headerBottom)
          ? (headerHeight < headerBottom ? headerBottom : headerHeight) + headerBottomPaddingRef.current
          : headerBottomPaddingRef.current;
      const navContainer = sideNavRef.current?.getBoundingClientRect();
      const navTop = navContainer?.top;
      const navBottom = navContainer?.bottom;
      const left = isInnerNavScrollable ? 0 : mainLeft + pagePaddingRef.current - mainElement?.scrollLeft;
      const height = navBottom && navTop ? navBottom - navTop : undefined;
      if (prevSticky.bottom) {
        if (navTop && parseFloat(navTop.toFixed(2)) > parseFloat(top?.toFixed(2))) {
          return {
            bottom: false,
            left,
            top,
            height,
          };
        }
      }
      const contentBottom = document.querySelector(selectorRef.current)?.getBoundingClientRect()?.bottom;
      const bottom = parseFloat(contentBottom?.toFixed(2) || '') <= parseFloat(navBottom?.toFixed(2) || '');
      const newNavStickyState = {
        bottom,
        left,
        top: isInnerNavScrollable && !bottom ? mainElement.scrollTop : top,
        height,
      };
      // prevent rerender if the state is the same
      return isEqual(newNavStickyState, prevSticky) ? prevSticky : newNavStickyState;
    },
    [isInnerNavScrollable],
  );

  const onSideMenuItemClick = (itemValue: T, label: string, restricted?: boolean, sectionKey?: string) => {
    setSidePanelSticky(getSideNavStickyState);
    if (!disableAutoScroll) {
      scrollToElementByQuerySelector(selectorRef.current, scrollStartOffset);
    }
    onSideNavClick?.(itemValue, label, restricted, sectionKey);
  };

  // Initialize left sidenav state based on initial window layout and
  // Recompute sticky layout due to observable value from parent component, ie "loading" state of the page
  useLayoutEffect(() => {
    setSidePanelSticky(getSideNavStickyState);
  }, [valueToObserveForResize, contentHeight, getSideNavStickyState]);

  // Recompute sticky layout if side menu items opened/closed
  const onToggleSideMenuItem = useCallback(() => {
    setSidePanelSticky(getSideNavStickyState);
  }, [getSideNavStickyState]);

  const calculateHeaderSticky = () => {
    const scrollMain = sideNavRef.current?.closest('main');
    if (!scrollMain) {
      return;
    }
    setHeaderSticky((prevStickyHeader) => {
      if (scrollMain.scrollTop > 0 && !prevStickyHeader) {
        return true;
      }
      if (scrollMain.scrollTop === 0 && prevStickyHeader) {
        return false;
      }
      return prevStickyHeader;
    });
  };

  const handleReposition = useCallback(() => {
    calculateHeaderSticky();
    setSidePanelSticky(getSideNavStickyState);
  }, [getSideNavStickyState]);

  useEffect(() => {
    window.addEventListener('scroll', handleReposition, true);
    window.addEventListener(VennEvents.transitioned, handleReposition, true);
    window.addEventListener('resize', handleReposition);
    return () => {
      window.removeEventListener('resize', handleReposition);
      window.removeEventListener(VennEvents.transitioned, handleReposition, true);
      window.removeEventListener('scroll', handleReposition, true);
    };
  }, [handleReposition]);

  useEffect(() => {
    /* Recalculate the position when selector is changed */
    handleReposition();
  }, [handleReposition, selectedItems]);

  return (
    <>
      <Header reference={stickyHeaderRef} sticky={headerSticky}>
        <HeaderWrapper padding={headerSidePadding}>{HeaderContent}</HeaderWrapper>
      </Header>
      <PageWrapper padding={pagePadding}>
        <PageLayout displayFlex={pageDisplayFlex}>
          <StickySideMenu
            isInnerNavScrollable={isInnerNavScrollable}
            bottomLeft={-outerContainerMargin}
            hasTitle={Boolean(sideMenuTitle)}
            ref={sideNavRef}
            maxWidth={sideNavWidth}
            {...sidePanelSticky}
          >
            <SideMenu<T>
              title={sideMenuTitle}
              items={items}
              sections={sideMenuSections}
              selectedItems={selectedItems}
              onClick={onSideMenuItemClick}
              onToggleItemCheckbox={onToggleSideNavCheckbox}
              onToggleItemOpen={onToggleSideMenuItem}
              maxHeight={
                isNil(contentHeight) ? undefined : Math.max(contentHeight - headerBottomPaddingRef.current, 300)
              }
              resetSelected={resetSelectedItems}
              allowItemOverflow
            />
          </StickySideMenu>
          <PageContentWrapper sideNavWidth={sideNavWidth + 10} minHeight={sidePanelSticky.height}>
            {PageContent}
          </PageContentWrapper>
        </PageLayout>
      </PageWrapper>
    </>
  );
}

const PageWrapper = styled.div<{ padding: number }>`
  z-index: ${ZIndex.StickyFront};
  padding: 0 ${({ padding }) => padding}px 46px ${({ padding }) => padding}px;
`;

const HeaderWrapper = styled(PageWrapper)<{ padding: number }>`
  z-index: ${ZIndex.StickyFront};
  padding: 0 ${({ padding }) => padding}px;
`;

const Header = styled(StickyNode)<{ sticky: boolean }>`
  z-index: ${ZIndex.StickyFront};
  background-color: ${GetColor.White};
  box-shadow: ${(props) => props.sticky && '0px 10px 10px -10px rgba(0, 0, 0, 0.17)'};
  > div > div:last-child {
    border: ${(props) => props.sticky && 'none'};
  }
`;

type StickySideMenuProps = {
  bottom: boolean;
  left?: number;
  hasTitle: boolean;
  top?: number;
  bottomLeft: number;
  isInnerNavScrollable?: boolean;
  maxWidth?: number;
};
const StickySideMenu = styled.div.attrs(({ top, left, bottom, bottomLeft, maxWidth }: StickySideMenuProps) => ({
  style: {
    top: bottom || isNil(top) ? undefined : top,
    left: bottom ? bottomLeft : isNil(left) ? undefined : left,
    maxWidth,
  },
}))`
  z-index: ${ZIndex.Sticky};
  ${({ bottom, isInnerNavScrollable }: StickySideMenuProps) =>
    bottom
      ? `position: absolute;
         bottom: 0;`
      : isInnerNavScrollable
        ? 'position: relative'
        : 'position: fixed;'}
  ${({ hasTitle }: StickySideMenuProps) =>
    hasTitle
      ? `nav {
         width: 250px;
       }`
      : ''};
`;

const PageLayout = styled.div<{ displayFlex: boolean }>`
  position: relative;
  ${({ displayFlex }) => (displayFlex ? 'display: flex;' : undefined)}
`;

const PageContentWrapper = styled.div<{ sideNavWidth: number; minHeight?: number }>`
  margin-left: ${({ sideNavWidth }) => sideNavWidth}px;
  flex: 1;
  ${(props) => props.minHeight && `min-height: ${props.minHeight}px;`}
`;

export default StickyHeaderWithSideNav;
