import { withSuspense } from 'venn-utils';
import { useRecoilValue } from 'recoil';
import { filterSelectorById, getFiltersStateFromSearchParams } from 'venn-state';
import { getLibrarySearchParamsFromSearchQuery } from '../search';
import { getFilterPills } from '../../logic/filterPills';
import { FilterPill } from 'venn-ui-kit';
import React from 'react';
import styled from 'styled-components';

const PADDING_BETWEEN = 10;

interface FilterPillComponentProps {
  filterId: string;
}

export const FilterPillsComponent = withSuspense(null, ({ filterId }: FilterPillComponentProps) => {
  const savedFilterData = useRecoilValue(filterSelectorById(filterId));
  const savedSearchParams = getLibrarySearchParamsFromSearchQuery(savedFilterData);
  const pills = getFilterPills({
    filtersState: getFiltersStateFromSearchParams(savedSearchParams),
    toggleParamItem: () => {},
    updateSearchParam: () => {},
    removeAdvancedQuery: () => {},
  });

  return (
    <ExpandingPanel>
      {pills?.map(({ key, label, icon }) => <FilterPill key={key} name={label} iconType={icon} />)}
    </ExpandingPanel>
  );
});

const ExpandingPanel = styled.div<{ expanded?: boolean }>`
  padding-top: ${PADDING_BETWEEN - 4}px;
  padding-left: 36px;
  padding-bottom: ${PADDING_BETWEEN}px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 280px;
`;
