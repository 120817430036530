import React, { Component } from 'react';
import background from './background';
import styled, { css } from 'styled-components';
import { ColorUtils, GetColor, ZIndex } from 'venn-ui-kit';
import { buttonize } from 'venn-utils';

const selectedCSS = css`
  background-color: ${ColorUtils.opacifyFrom(GetColor.Primary.Dark, 0.1)};
  box-shadow: 0 0 0 1px ${GetColor.Primary.Dark};
  z-index: ${ZIndex.Front};
`;

const disabledCSS = (nonPrimary: boolean) => css`
  cursor: not-allowed;
  color: ${GetColor.LightGrey};
  ${!nonPrimary &&
  css`
    background-image: ${background};
    background-repeat: 2px 2px;
    background-size: 2px 2px;
    box-shadow: none;
  `}
`;

const StyledOption = styled.div<ToggleOptionProps>`
  padding: 5px 10px;
  ${({ nonPrimary }) =>
    !nonPrimary &&
    css`
      background-color: ${GetColor.White};
    `}
  color: ${({ nonPrimary }) => (nonPrimary ? GetColor.Black : GetColor.Primary.Dark)};
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ small }) =>
    small
      ? css`
          font-size: 12px;
          border-radius: 15px;
          line-height: 20px;
          min-width: 90px;
        `
      : css`
          font-size: 11px;
          border-radius: 35px;
          letter-spacing: 1px;
          line-height: 13px;
          text-transform: uppercase;
        `}

  background-image: none;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  user-select: none;
  z-index: ${ZIndex.Base};
  transition: all 0.3s ease-in-out;

  &:hover {
    ${(props) =>
      props.disabled
        ? ''
        : css`
            color: ${props.nonPrimary ? GetColor.Primary.Dark : GetColor.Primary.Main};
          `}
  }

  ${(props) => props.selected && selectedCSS}

  ${(props) => props.disabled && disabledCSS(!!props.nonPrimary)}

    ${(props) =>
    props.selected &&
    props.disabled &&
    css`
      color: ${GetColor.LightGrey};
      background-color: ${ColorUtils.opacifyFrom(GetColor.Grey, 0.1)};
      background-image: none;
    `}
`;

export interface ToggleOptionProps {
  className?: string;
  disabled?: boolean;
  selected?: boolean;
  value?: unknown;
  width?: number;
  onClick?(value: unknown): unknown;
  nonPrimary?: boolean;
  small?: boolean;
}

class ToggleOption extends Component<React.PropsWithChildren<ToggleOptionProps>> {
  constructor(props: ToggleOptionProps) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    const { disabled, value, onClick } = this.props;
    if (!disabled && onClick) {
      onClick(value);
    }
  }

  render() {
    const { children, className, disabled, selected, width, small, nonPrimary } = this.props;

    return (
      <StyledOption
        {...buttonize(this.onClick)}
        data-testid={selected ? 'selected-toggle' : undefined}
        className={className}
        style={{ width }}
        disabled={disabled}
        selected={selected}
        nonPrimary={nonPrimary}
        small={small}
      >
        {children}
      </StyledOption>
    );
  }
}

export default ToggleOption;
