import type { Portfolio } from 'venn-api';
import type { RedistributionMode } from './types';
import { flattenNode } from 'venn-utils';
import { isNil, sumBy } from 'lodash';

const getWithAmountAddedToFundAllocations = (node: Portfolio, amount: number): Portfolio => {
  if (!isNil(node.fund)) {
    return {
      ...node,
      allocation: (node.allocation ?? 0) + amount,
    };
  }
  const updatedChildren = node.children.map((child) => getWithAmountAddedToFundAllocations(child, amount));

  return {
    ...node,
    children: updatedChildren,
    allocation: sumBy(updatedChildren, 'allocation'),
  };
};

const getWithScaledAllocations = (node: Portfolio, scalingFactor: number): Portfolio => {
  if (!isNil(node.fund)) {
    return {
      ...node,
      allocation: (node.allocation ?? 0) * scalingFactor,
    };
  }
  const updatedChildren = node.children.map((child) => getWithScaledAllocations(child, scalingFactor));

  return {
    ...node,
    children: updatedChildren,
    allocation: sumBy(updatedChildren, 'allocation'),
  };
};

/**
 * Get a strategy with allocation increased by the amount passed, with allocations in the subtree modified depending on
 * the redistribution mode.
 *
 * @param strategy - strategy to modify
 * @param amount - capital to add/remove from the strategy (can be negative)
 * @param redistributionMode - 'evenly' if we want to remove the same amount of capital from each fund, or 'prorata'
 *        if we want to remove proportionately to what % of the strategy the fund is today
 */
export const getStrategyWithAddedCapital = (
  strategy: Portfolio,
  amount: number, // can be negative
  redistributionMode: RedistributionMode,
): Portfolio => {
  if (redistributionMode === 'evenly') {
    const allChildFunds: Portfolio[] = flattenNode(strategy).filter((node) => !isNil(node.fund));
    const perChildAmount: number = amount / allChildFunds.length;
    return getWithAmountAddedToFundAllocations(strategy, perChildAmount);
  }

  const totalAllocation = strategy.allocation ?? 0;

  if (totalAllocation === 0) {
    return { ...strategy };
  }

  return getWithScaledAllocations(strategy, (totalAllocation + amount) / totalAllocation);
};
