import type { ComputedInvestmentResidual, InvestmentResidualOverride } from 'venn-api';
import type { InvestmentOverrideType } from 'venn-state';
import { assertExhaustive } from 'venn-utils';

export const typeOfInvestmentOverride = (
  override: ComputedInvestmentResidual | InvestmentResidualOverride,
): InvestmentOverrideType | null => {
  const typeOfComputedOverride = (override: ComputedInvestmentResidual): InvestmentOverrideType | null => {
    if (override.overriddenReturn) {
      return 'return';
    }
    if (override.overriddenResidual) {
      return 'residual';
    }
    return null;
  };
  const typeOfSpecifiedOverride = (override: InvestmentResidualOverride): InvestmentOverrideType => {
    if (override.forecastReturn) {
      return 'return';
    }
    return 'residual';
  };

  const isComputedOverride = (
    override: ComputedInvestmentResidual | InvestmentResidualOverride,
  ): override is ComputedInvestmentResidual => {
    return 'computedResidual' in override;
  };

  return isComputedOverride(override) ? typeOfComputedOverride(override) : typeOfSpecifiedOverride(override);
};

export const getNewUserSpecifiedOverride = ({
  currentInvestmentResidual,
  newValue,
  overrideType,
}: {
  currentInvestmentResidual: ComputedInvestmentResidual;
  newValue: number;
  overrideType: InvestmentOverrideType;
}): InvestmentResidualOverride => {
  const baseInvestmentOverride = {
    created: currentInvestmentResidual.created,
    fundId: currentInvestmentResidual.fundId,
    permissionGroupId: currentInvestmentResidual.permissionGroupId,
    userId: currentInvestmentResidual.userId,
    workspaceId: currentInvestmentResidual.workspaceId,
    isManualOverride: true,
  };
  switch (overrideType) {
    case 'residual':
      return {
        ...baseInvestmentOverride,
        forecastResidual: newValue,
      };
    case 'return':
      return {
        ...baseInvestmentOverride,
        forecastReturn: newValue,
      };
    default:
      throw assertExhaustive(overrideType, 'unexpected overrideType');
  }
};
