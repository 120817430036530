import React from 'react';
import { Modal, ModalContent, ModalFooter, ModalHeader, ModalSize, ModalSubhead } from 'venn-components';
import { Button, HyperLink, IconPosition, ZIndex, getTextThemeProvider, getAppTitle } from 'venn-ui-kit';
import styled from 'styled-components';
import { analyticsService } from 'venn-utils';

interface AddeparHelpModalProps {
  onCancel: () => void;
}

const helpCenterArticle = getTextThemeProvider().HelpCenterLinks.ADDEPAR_HELP_HREF;
const addeparHelpArticle = 'https://help.addepar.com/hc/en-us/articles/360041880934-Create-custom-attributes';

const AddeparHelpModal = ({ onCancel }: AddeparHelpModalProps) => {
  return (
    <Modal onClose={onCancel} size={ModalSize.Large} zIndex={ZIndex.InterComFront2}>
      <ModalHeader>Get help with Addepar</ModalHeader>
      <ModalSubhead>Not sure how to choose the right settings for your Addepar integration?</ModalSubhead>
      <ModalContent>
        <Note>
          <strong>How to set up a custom {getAppTitle()} Return attribute in Addepar</strong>
          <div>
            The investment return column you select will dictate the returns data {getAppTitle()} will use for analyses.
            If you wish to use a value other than the default, Adjusted TWR, you will need to create a custom attribute
            in Addepar called "{getAppTitle()} Return" whose Output Type is Percent. One common use case is to define{' '}
            {getAppTitle()} Return to represent IRR for private investments and TWR for public ones. If you need help
            understanding how to create custom attributes in Addepar, visit this{' '}
            <HyperLink href={addeparHelpArticle} target="_blank" rel="noopener noreferrer" className="qa-addepar-help">
              Addepar help center article
            </HyperLink>
          </div>
        </Note>
        <Note>
          <strong>How to choose Addepar groupings</strong>
          <div>
            The top-level grouping represents the set of portfolios to be created on {getAppTitle()} for a particular
            view configuration. The rest of the groupings will form the portfolio structure in {getAppTitle()}. The
            lowest level grouping should always be Security and a {getAppTitle()} investment will be created for each
            one.
          </div>
        </Note>
        <Note>
          <strong>How to choose included attributes</strong>
          <div>
            The attributes you select for your top-level grouping will appear in {getAppTitle()} as portfolios. Deselect
            any which you don’t want to appear as portfolios on {getAppTitle()}.
          </div>
        </Note>
        <Note>
          <strong> Need help understanding the Addepar interface? </strong>
          <div>
            We’ve put together a help article to guide you through the most common issues with the set-up process. If
            you need more help, feel free to contact us, Addepar support, or your third-party data integration vendor
            for more assistance.
          </div>
        </Note>
      </ModalContent>
      <ModalFooter
        rightChildren={
          <Button
            dense
            type="submit"
            icon="external-link"
            iconPosition={IconPosition.Right}
            onClick={() => {
              analyticsService.ctaClicked({
                purpose: 'Open Help Article',
                text: 'View Help Article',
              });
            }}
            link={helpCenterArticle}
            target="_blank"
            rel="noopener noreferrer"
          >
            View Help Article
          </Button>
        }
        onCancel={onCancel}
        primaryClassName="qa-help-btn"
      />
    </Modal>
  );
};

export default AddeparHelpModal;

const Note = styled.div`
  margin-top: 0px;
  margin-bottom: 30px;
  line-height: 24px;
`;
