import React from 'react';
import styled from 'styled-components';
import { ItemIcon } from 'venn-ui-kit';
import { blockFonts, type StudioRequestSubject } from 'venn-state';
import { convertRequestSubjectToItemType } from '../../../analysis';
import { NameWithOptionalFee } from '../../../legend';
import { useBlockId } from '../../contexts/BlockIdContext';
import { useRecoilValue } from 'recoil';

type ItemProps = {
  icon: JSX.Element;
};

const Item: React.FunctionComponent<React.PropsWithChildren<ItemProps>> = ({ icon, children }) => (
  <>
    {icon}
    <Name>{children}</Name>
  </>
);

export interface GraphicSubjectLabelProps {
  subject: StudioRequestSubject;
}

const GraphicSubjectLabelInternal = ({ subject }: GraphicSubjectLabelProps) => {
  const blockId = useBlockId();
  const legendFont = useRecoilValue(blockFonts.blockChartLegend(blockId));
  const fontSize = `${legendFont.fontSizePt}pt`;
  return (
    <Wrapper>
      <ItemPair fontSize={fontSize} key={subject.id}>
        <Item icon={<ItemIcon item={convertRequestSubjectToItemType(subject)} />}>
          <NameWithOptionalFee subject={subject} />
        </Item>
      </ItemPair>
    </Wrapper>
  );
};

export const GraphicSubjectLabel = React.memo(GraphicSubjectLabelInternal);

const Name = styled.span`
  margin-left: 3px;
`;

const Wrapper = styled.div`
  padding: 5px 0 0 8px;
`;

const ItemPair = styled.div<{ fontSize: string }>`
  display: flex;
  margin-right: 20px;
  white-space: nowrap;
  font-size: ${({ fontSize }) => fontSize};
  padding: 4px 0;
  i {
    margin-right: 3px;
  }
`;
