import FactorForecastOverrideNumericTextInput from '../views/FactorForecastOverrideNumericTextInput';
import React from 'react';
import { isNil } from 'lodash';
import type { FactorOverrideType, PartialFactorForecast } from 'venn-state';
import { getInRangeValue } from '../../../input/ShockInput/logic';

type SpecificFactorForecastAnnualizedReturnContentProps = {
  partialForecast: PartialFactorForecast;
  isForecastEditable: boolean;
  setCurrentPendingOverride: (override: FactorOverrideType) => void;
  currentPendingOverride: FactorOverrideType;
  editValueBuffer: string;
  setEditValueBuffer: (value: string) => void;
  isEditing: boolean;
};
const SpecificFactorForecastAnnualizedReturnContent = ({
  partialForecast,
  isForecastEditable,
  setCurrentPendingOverride,
  currentPendingOverride,
  editValueBuffer,
  setEditValueBuffer,
  isEditing,
}: SpecificFactorForecastAnnualizedReturnContentProps) => {
  const { annualizedReturnOverride, annualizedReturn, factorOverrideRange } = partialForecast;
  const { minOverride, maxOverride } = factorOverrideRange;
  const hasStoredOverride = !isNil(annualizedReturnOverride);
  const hasPendingRevert = currentPendingOverride === 'reset';
  const hasPendingOverride =
    currentPendingOverride !== 'reset' &&
    currentPendingOverride !== 'no change' &&
    currentPendingOverride !== 'invalid change';
  return isForecastEditable ? (
    <>
      <FactorForecastOverrideNumericTextInput
        value={editValueBuffer}
        onCommitInput={(parsedValue) => {
          if (parsedValue.isValid) {
            const inRangeValue = getInRangeValue(parsedValue.value / 100, minOverride, maxOverride);
            if (annualizedReturnOverride === inRangeValue) {
              setCurrentPendingOverride('no change');
            } else {
              setCurrentPendingOverride(inRangeValue);
            }
            setEditValueBuffer((inRangeValue * 100).toString());
          } else {
            setCurrentPendingOverride('invalid change');
          }
        }}
        shouldHighlight={hasStoredOverride && !hasPendingRevert}
        isCurrentValUserSpecified={(hasStoredOverride || hasPendingOverride) && !hasPendingRevert}
        onInputChange={(value: string) => setEditValueBuffer(value)}
        isLocked={!isEditing}
        factorOverrideRange={partialForecast.factorOverrideRange}
      />
      %
    </>
  ) : (
    <>{(annualizedReturn * 100).toFixed(2)}%</>
  );
};

export default SpecificFactorForecastAnnualizedReturnContent;
