import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import styled, { css } from 'styled-components';
import { isEqual, isNil, noop } from 'lodash';
import { AlternateModalHeader, type ReorderableGroup, ModalFooter, NumberInput } from 'venn-components';
import type { TreeNode } from 'venn-ui-kit';
import {
  ExternalActivityListener,
  GetColor,
  Loading,
  portalMenuIgnoreActivityClassName,
  RelativePortal,
  Subtitle2,
  CheckboxTree,
  Collapsible,
  CheckboxInput,
  flattenTree,
} from 'venn-ui-kit';
import type { Subject, SubjectInputId } from 'venn-state';
import { SubjectGroupRowWrapper, SubjectItem } from './SubjectGroupRow';
import { useFees } from './useFees';
import Measure from 'react-measure';
import { useHasFF } from 'venn-utils';
import { requestSubjects, excludedFees } from 'venn-state';
import type { Portfolio } from 'venn-api';

interface ManageFeesModalProps {
  subjectGroups: ReorderableGroup<SubjectInputId, { key: string; value: Subject }>[];
  onClose: () => void;
}

const FEE_MIN_PCT = 0;
const FEE_MAX_PCT = 100;
const FEE_MAX = FEE_MAX_PCT / 100;
const FEE_MIN = FEE_MIN_PCT / 100;
const noTopMarginFooterClassname = 'fees-modal-footer-classname';
const subjectItemContainerClassname = 'subject-item-container';
const subjectRowClassname = 'subject-row-container';

const ManageFeesModal = ({ subjectGroups, onClose }: ManageFeesModalProps) => {
  const groupIds = subjectGroups.map(({ id }) => id);
  const [baselineFees, setBaselineFees] = useFees(groupIds);
  const [fees, setFees] = useState(baselineFees);
  const baselineExclusions = useRecoilValue(excludedFees(groupIds));
  const [selectedExclusions, setSelectedExclusions] = useState<Record<SubjectInputId, string[][]>>(baselineExclusions);
  const [canSave, saveDisabledTooltip] = getCanSaveStatusAndOptionalError(
    fees,
    baselineFees,
    selectedExclusions,
    baselineExclusions,
  );

  return (
    <>
      <ScrollableGroupContainer>
        {subjectGroups.map((group) => (
          <SubjectGroupRow
            key={group.id}
            group={group}
            fees={fees}
            setFees={setFees}
            selectedKeys={selectedExclusions[group.id] ?? []}
            setSelectedKeys={(newKeys) => setSelectedExclusions({ ...selectedExclusions, [group.id]: newKeys })}
          />
        ))}
      </ScrollableGroupContainer>
      <ModalFooter
        className={noTopMarginFooterClassname}
        primaryLabel="Save"
        onPrimaryClick={() => {
          setBaselineFees(fees, selectedExclusions);
          onClose();
        }}
        primaryDisabled={!canSave}
        primaryTooltip={saveDisabledTooltip}
        onCancel={onClose}
      />
    </>
  );
};

interface SubjectGroupRowProps {
  group: ReorderableGroup<SubjectInputId, { key: string; value: Subject }>;
  fees: Record<SubjectInputId, number[]>;
  setFees: (fees: Record<SubjectInputId, number[]>) => void;
  selectedKeys: string[][];
  setSelectedKeys: (keys: string[][]) => void;
}

const portfolioToTreeNode = (portfolio: Portfolio): TreeNode<string> => {
  const { children, name } = portfolio;
  return { children: children?.map(portfolioToTreeNode), data: name, key: portfolio?.id.toString(10) };
};

const PortfolioItemRow = styled.div<{ level: number; isLeaf: boolean }>`
  ${({ isLeaf }) => !isLeaf && 'font-weight: bold;'}
  ${({ level }) => `margin-left: ${16 * (level - 1)}px;`}
  margin-top: 2px;
  margin-bottom: 2px;
  display: flex;
  font-size: 1rem;
`;

const ItemRowName = styled.span`
  margin: 2px;
`;

const SubjectGroupRow = ({ group, fees, setFees, selectedKeys, setSelectedKeys }: SubjectGroupRowProps) => {
  const hasInvestmentAdvisoryFeesFF = useHasFF('investment_advisory_fees_ff');
  const groupsRequestSubjects = useRecoilValue(requestSubjects(group.reorderableItems.map((item) => item.value)));
  const portfolioNodes = groupsRequestSubjects.map((subject) =>
    subject.portfolio ? portfolioToTreeNode(subject.portfolio) : undefined,
  );

  return (
    <SubjectGroupRowWrapper
      key={group.id}
      readonly
      noHoverStyles
      groupId={group.id}
      trackGroupChanged={noop}
      className={subjectRowClassname}
      deletable={false}
    >
      {group.reorderableItems.map((item, itemIndex) =>
        // If a fee currently exists for an investment, always allow them to edit it
        fees[group.id] &&
        ((hasInvestmentAdvisoryFeesFF && !isNil(item.value.fundId)) ||
          !isNil(item.value.portfolioId) ||
          fees[group.id][itemIndex]) ? (
          <>
            <ItemWithFee key={item.key}>
              <SubjectItem
                readonly
                hideAllocatorLauncher
                hideManageData
                subject={item.value}
                groupId={group.id}
                onDelete={noop}
                onSwap={noop}
                className={subjectItemContainerClassname}
              />
              <FeesInput
                data-testid="qa-fees-input"
                min={FEE_MIN_PCT}
                max={FEE_MAX_PCT}
                precision={2}
                step={0.1}
                onChange={(value: number) => {
                  setFees({
                    ...fees,
                    [group.id]: [
                      ...fees[group.id].slice(0, itemIndex),
                      value / 100,
                      ...fees[group.id].slice(itemIndex + 1),
                    ],
                  });
                }}
                formatter={(value) => {
                  return `${value} %`;
                }}
                value={(fees[group.id][itemIndex] ?? 0) * 100}
                controlled
              />
            </ItemWithFee>
            {portfolioNodes[itemIndex] && (
              <Collapsible
                label={`Exclude investments ${
                  selectedKeys[itemIndex]?.length ? `(${selectedKeys[itemIndex].length} excluded)` : ''
                }`}
              >
                <FeeExclusionInstructionText>{FEE_EXCLUSION_INSTRUCTIONS}</FeeExclusionInstructionText>
                <CheckboxTree
                  selectedKeys={selectedKeys[itemIndex] ?? []}
                  setSelectedKeys={(newSelectedKeys) =>
                    setSelectedKeys([
                      ...selectedKeys.slice(0, itemIndex),
                      newSelectedKeys,
                      ...selectedKeys.slice(itemIndex + 1),
                    ])
                  }
                  rows={flattenTree(portfolioNodes[itemIndex]!).slice(1)}
                  rowRenderer={({ level, key, data, isLeaf, selected, onSelect }) => (
                    <PortfolioItemRow level={level} isLeaf={isLeaf}>
                      {isLeaf && <CheckboxInput checked={!selected} onChange={() => onSelect(key)} />}
                      <ItemRowName>{data}</ItemRowName>
                    </PortfolioItemRow>
                  )}
                />
              </Collapsible>
            )}
          </>
        ) : null,
      )}
    </SubjectGroupRowWrapper>
  );
};

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default (props: ManageFeesModalProps) => {
  const [height, setHeight] = useState<number | undefined>(undefined);
  return (
    <Measure
      bounds
      onResize={({ bounds }) => {
        setHeight(bounds?.height);
      }}
    >
      {({ measureRef }) => (
        <div>
          <RelativePortal leftOffset={10} topOffset={-100} expectedHeight={height}>
            <ExternalActivityListener
              ignoreActivityFromClassName={portalMenuIgnoreActivityClassName}
              onExternalActivity={props.onClose}
            >
              <PopupContainer ref={measureRef} hidden={height === undefined} wide>
                <AlternateModalHeader>Advisory Fees</AlternateModalHeader>
                <LeftRight>
                  <Subtitle2>Subjects</Subtitle2>
                  <Subtitle2>Fees</Subtitle2>
                </LeftRight>
                <React.Suspense fallback={<Loading />}>
                  <ManageFeesModal {...props} />
                </React.Suspense>
              </PopupContainer>
            </ExternalActivityListener>
          </RelativePortal>
        </div>
      )}
    </Measure>
  );
};

const PopupContainer = styled.div<{ hidden: boolean; wide: boolean }>`
  background: ${GetColor.White};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 160px;
  min-width: ${({ wide }) => (wide ? '700px' : '300px')};
  ${({ wide }) => wide && 'max-width: 700px;'}
  border: 4px solid ${GetColor.Primary.Main};
  ${({ hidden }) =>
    hidden &&
    css`
      visibility: hidden;
    `}

  .${noTopMarginFooterClassname} {
    margin-top: 0;
  }
`;

const LeftRight = styled.div`
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
`;

const ScrollableGroupContainer = styled.div`
  max-height: 70vh;
  overflow-y: auto;
  .${subjectRowClassname} {
    padding: 8px;
    margin: 0 8px;
    border-top: 1px solid ${GetColor.Grey};
  }
`;

const ItemWithFee = styled.div`
  min-width: 400px;
  display: flex;
  justify-content: space-between;
  .${subjectItemContainerClassname} {
    padding: 0 15px 0 0;
  }
`;

const FeesInput = styled(NumberInput)`
  min-width: 124px;
  .rc-input-number-input {
    text-align: right;
    padding-right: 4px;
  }
`;

export const getCanSaveStatusAndOptionalError = (
  feesGroup: Record<SubjectInputId, number[]>,
  baselineFeesGroup: Record<SubjectInputId, number[]>,
  exclusions: Record<SubjectInputId, string[][]>,
  baselineExclusions: Record<SubjectInputId, string[][]>,
): [boolean, string | undefined] => {
  if (Object.values(feesGroup).some((value) => Math.max(...value) > FEE_MAX || Math.min(...value) < FEE_MIN)) {
    return [false, `Advisory Fee value must be between ${FEE_MIN_PCT} and ${FEE_MAX_PCT}%`];
  }

  if (isEqual(feesGroup, baselineFeesGroup) && isEqual(exclusions, baselineExclusions)) {
    return [false, 'No changes to save'];
  }
  return [true, undefined];
};

const FeeExclusionInstructionText = styled.span`
  color: ${GetColor.HintGrey};
  font-size: 1rem;
`;

const FEE_EXCLUSION_INSTRUCTIONS = `
Uncheck investments to exclude them from the applied advisory fee. When investments are excluded, a
footnote will be added to each page directing readers to the Disclosures page, where all excluded
investments will be detailed.
`;
