import type React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useDebounce } from '../hooks';

const useSearch = (value: string, onChange: (value: string) => void, disableAutofocus?: boolean, debounce?: number) => {
  const [searchString, setSearchString] = useState(value);
  const [debouncedSearchString] = useDebounce(searchString, debounce) ?? [''];
  const mounted = useRef(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (mounted.current) {
      onChange(debouncedSearchString);
    }
    mounted.current = true;
  }, [debouncedSearchString, onChange]);

  useEffect(() => {
    setSearchString(value);
  }, [value]);

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(event?.target?.value);
  };

  const clearSearchQuery = () => {
    setSearchString('');
  };

  useEffect(() => {
    if (!disableAutofocus) {
      inputRef.current?.focus();
    }
  }, [disableAutofocus]);

  return {
    inputRef,
    searchString,
    onInputChange,
    clearSearchQuery,
  };
};

export default useSearch;
