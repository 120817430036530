import styled, { css } from 'styled-components';
import { GetColor } from '../../style/color';
import { ZIndex } from '../../zIndexValues';

export const StyledIcon = styled.span`
  & i {
    cursor: pointer;
    margin-left: 3px;
    color: ${GetColor.HintGrey};
  }

  & div {
    display: inline;
  }

  &:hover {
    & i {
      color: ${GetColor.Primary.Dark};
    }
  }
`;

export const Container = styled.div`
  position: relative;
  max-width: 520px;
  background-color: ${GetColor.White};
  padding: 15px;
  border: 1px solid ${GetColor.Grey};
  z-index: ${ZIndex.Front};
  box-shadow: 5px 5px 5px -3px ${GetColor.Grey};
`;

export const Arrow = styled.div<{ top?: number; left?: number }>`
  position: absolute;
  height: 20px;
  width: 20px;
  background-color: ${GetColor.White};
  border-top: 1px solid ${GetColor.Grey};
  border-right: 1px solid ${GetColor.Grey};
  ${({ left, top }) =>
    left
      ? css`
          bottom: -10px;
          left: ${left}px;
          transform: rotate(135deg);
        `
      : css`
          right: -10px;
          top: ${top}px;
          transform: rotate(45deg);
        `}
`;

export const Header = styled.div`
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 16px;
`;

export const Text = styled.div`
  margin-bottom: 20px;
  line-height: 24px;
  font-size: 14px;
`;

export const LearnMore = styled.span`
  padding: 0 5px;
  font-weight: bold;
  color: ${GetColor.Black};
`;
