import React, { useContext } from 'react';
import { PanelLabel } from '../../../../shared';
import { RollingWindowDropMenu, UserContext } from 'venn-components';
import { getSelectedRollingPeriod, isFactorTrendMetric, PERIODS } from 'venn-utils';
import { useRecoilState, useRecoilValue } from 'recoil';
import { blockMetrics, blockRollingYears } from 'venn-state';
import type { SingleCustomOptionMenuItem } from 'venn-ui-kit';

interface RollingPeriodSettingsProps {
  selected: string;
}

export const RollingPeriodSettings = ({ selected }: RollingPeriodSettingsProps) => {
  const { hasPermission } = useContext(UserContext);
  const isReadOnly = !hasPermission('STUDIO_EDIT_FORMAT');
  const [rollingYears, setRollingYears] = useRecoilState(blockRollingYears(selected));
  const selectedMetrics = useRecoilValue(blockMetrics(selected));

  const onOptionSelect = (item: SingleCustomOptionMenuItem) => {
    const selectedPeriod = item.value / 12;
    setRollingYears(selectedPeriod);
  };

  const factorTrendMetricSelected = isFactorTrendMetric(selectedMetrics);
  const selectedOption = getSelectedRollingPeriod(rollingYears, factorTrendMetricSelected);

  return (
    <>
      <PanelLabel>Rolling period:</PanelLabel>
      <RollingWindowDropMenu
        selected={selectedOption}
        onSelect={onOptionSelect}
        items={PERIODS}
        horizontalPosition="left"
        disabled={isReadOnly}
      />
    </>
  );
};
