import type { CategoryGroupOption, FundMetadata, CategoryAssetTypeOption } from 'venn-api';

export const categoryOptions = {
  categoryAssetTypes: [{ key: 'HEDGE_FUND', name: 'Hedge Fund' }] as CategoryAssetTypeOption[],
  categoryGroups: [{ name: 'Multi-Strategy', key: 'HF_MULTI_STRAT' }] as CategoryGroupOption[],
  categoryOptions: { BANK_LOAN: 'Bank Loan' },
  currencyOptions: { USD: 'United States dollar' },
};

export const metaDataFund = {
  accountNumber: null,
  assetType: 'UPLOADED_INSTRUMENT',
  category: null,
  categoryAssetType: null,
  categoryGroup: null,
  currency: 'USD',
  dataSource: null,
  fundMaster: null,
  id: '1cdfdf4a-adfe-41f5-80c4-1fd65570d264',
  investmentObjective: null,
  live: true,
  name: 'UploadedInvestment',
  netExpenseRatio: null,
  primaryBenchmark: null,
  symbol: 'Ticker',
} as unknown as FundMetadata;

export const metadataModalProps = {
  isEditable: true,
  fundId: 'id',
  fundName: 'UploadedInvestment',
  defaultEditData: false,
  userData: {
    data: [
      ['Date', 'UploadedInvestment'],
      ['1/31/2014', '898.00'],
      ['2/28/2014', '998.00'],
      ['3/31/2014', '1098.00'],
    ],
    dataType: 1,
  },
};

export default {
  categoryOptions,
  metaDataFund,
  metadataModalProps,
};
