import React, { useContext } from 'react';
import Copyright from '../copyright';
import styled, { ThemeContext } from 'styled-components';
import { GetColor } from 'venn-ui-kit';
import {
  COUNTRY_DISCLAIMERS,
  AGREEMENTS_FOR_SCREEN,
  THIRD_PARTY_NOTICES_FOR_SCREEN,
  PRIVATES_MODULE_DISCLAIMER,
} from './Messages';
import Markdown from '../markdown/Markdown';
import { assertNotNil, useHasFF } from 'venn-utils';

const StyledAttribution = styled('div')`
  color: ${GetColor.White};
  font-size: 12px;
  line-height: 16px;
  flex: 1;

  a {
    color: ${GetColor.Primary.Main};
    font-weight: bold;

    &:hover {
      border-bottom: 1px solid ${GetColor.Primary.Main};
    }
  }

  img {
    display: inline-block;
    height: 8px;
    margin: 0 2px 0 0;
  }

  p {
    margin: 8px 0;
  }
`;

const StyledNotice = styled('div')`
  margin: 10px 0;
`;

const StyledCopyright = styled(Copyright)`
  margin: 10px 0;
  color: ${GetColor.White};
  font-size: 12px;
`;

interface FooterAttributionProps {
  countryCode?: string;
}

const FooterAttribution: React.FC<React.PropsWithChildren<FooterAttributionProps>> = ({ countryCode }) => {
  const { Images } = useContext(ThemeContext);
  return (
    <StyledAttribution>
      {countryCode && COUNTRY_DISCLAIMERS[countryCode] && (
        <StyledNotice>
          <Markdown source={COUNTRY_DISCLAIMERS[countryCode]} />
        </StyledNotice>
      )}
      <StyledNotice>
        <Markdown source={AGREEMENTS_FOR_SCREEN} />
      </StyledNotice>
      <StyledCopyright />
      {useHasFF('privates_reveal_ff') && (
        <StyledNotice>
          <Markdown source={PRIVATES_MODULE_DISCLAIMER} />
        </StyledNotice>
      )}
      <StyledNotice>
        <Markdown source={THIRD_PARTY_NOTICES_FOR_SCREEN(assertNotNil(Images.six))} />
      </StyledNotice>
    </StyledAttribution>
  );
};

export default FooterAttribution;
