import { useCallback, useState } from 'react';
import type { LabSideBarProps } from '../components/LabSideBar';

interface SideBarStateValue extends LabSideBarProps {
  onShowAllocationSection: () => void;
  onShowFactorSection: () => void;
}

// This hook keeps a part of the state of the side bar, so that we can open the Allocation Constraints section
// (or the Factor Exposure Constraints section) from anywhere on the page.
const useSideBarState = (): SideBarStateValue => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [allocationSectionExpanded, setAllocationSectionExpanded] = useState(false);
  const [factorSectionExpanded, setFactorSectionExpanded] = useState(false);

  const onShowAllocationSection = useCallback(() => {
    setAllocationSectionExpanded(true);
    setFactorSectionExpanded(false);
    setIsCollapsed(false);
  }, []);

  const onShowFactorSection = useCallback(() => {
    setFactorSectionExpanded(true);
    setAllocationSectionExpanded(false);
    setIsCollapsed(false);
  }, []);

  const toggleSideBarCollapsed = useCallback(() => setIsCollapsed(!isCollapsed), [isCollapsed]);
  const toggleAllocationSection = useCallback(
    () => setAllocationSectionExpanded(!allocationSectionExpanded),
    [allocationSectionExpanded],
  );
  const toggleFactorSection = useCallback(
    () => setFactorSectionExpanded(!factorSectionExpanded),
    [factorSectionExpanded],
  );

  return {
    onShowAllocationSection,
    onShowFactorSection,
    isSideBarCollapsed: isCollapsed,
    toggleSideBarCollapsed,
    allocationSectionExpanded,
    toggleAllocationSection,
    factorSectionExpanded,
    toggleFactorSection,
  };
};

export default useSideBarState;
