import { getAppTitleSimplifiedUtil } from '../../../utils';

const searchResponse = {
  results: [
    {
      investment: {
        assetType: 'INDEX',
        created: 1579907541781,
        currency: 'USD',
        endRange: 1585958399999,
        factorLensName: 'TSFLWE V3 (USD)',
        favorite: false,
        frequency: 1,
        fundSourceType: null,
        id: 'ebb90198-7977-4740-91a8-6b09b27daf4c',
        investmentType: 'INDEX',
        investmentTypeName: 'Index',
        live: true,
        name: 'S&P Goldman Sachs Commodity Index',
        startRange: 924825599999,
        symbol: 'SPGSCI',
        updated: 1585994541845,
        userUploaded: false,
        viewed: false,
      },
      name: 'S&P Goldman Sachs Commodity Index',
      type: 'venn_fund',
    },
    {
      investment: {
        assetType: 'INDEX',
        created: 1579907541781,
        currency: 'USD',
        endRange: 1585958399999,
        factorLensName: 'TSFLWE V3 (USD)',
        favorite: false,
        frequency: 1,
        fundSourceType: null,
        id: 'ebb90198-7977-4740-91a8-6b09b2733333',
        investmentType: 'INDEX',
        investmentTypeName: 'Index',
        live: true,
        name: 'Should be excluded',
        startRange: 924825599999,
        symbol: 'SPGSCI',
        updated: 1585994541845,
        userUploaded: false,
        viewed: false,
        owner: {
          name: `${getAppTitleSimplifiedUtil()}`,
          displayName: `${getAppTitleSimplifiedUtil()}`,
        },
      },
      name: 'Should be excluded',
      type: 'venn_fund',
    },
    {
      investment: {
        assetType: 'INDEX',
        created: 1579907541781,
        currency: 'USD',
        endRange: 1585958399999,
        factorLensName: 'TSFLWE V3 (USD)',
        favorite: false,
        frequency: 1,
        fundSourceType: null,
        id: 'ebb90198-7977-4740-91a8-6b09b2744444',
        investmentType: 'NOT_SPECIFIED',
        investmentTypeName: 'Not specified',
        live: true,
        name: 'User Uploaded',
        startRange: 924825599999,
        symbol: 'SPGSCI',
        updated: 1585994541845,
        userUploaded: true,
        viewed: false,
        owner: {
          name: `${getAppTitleSimplifiedUtil()}-Uploader`,
          displayName: `${getAppTitleSimplifiedUtil()}-Uploader`,
        },
      },
      name: 'User Uploaded',
      type: 'venn_fund',
    },
  ],
};

export default searchResponse;
