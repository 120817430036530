import { ValidationCircle } from '../ValidationCircle';
import React, { useState } from 'react';
import styled from 'styled-components';
import type { InputProps } from './types';
import { FormInput } from 'venn-components';

const PasswordInputs = ({ field }: InputProps<string>) => {
  const [showError, setShowError] = useState(false);

  const passwordLegend = (
    <PasswordLegend
      validPassword={Boolean(field?.touched && !field?.error)}
      showError={Boolean(showError && field?.hasError)}
    />
  );
  const validationProps = {
    hint: !field?.error ? passwordLegend : null,
    error: field?.error ? passwordLegend : null,
  };

  return field ? (
    <FormInput
      required
      type="password"
      inputId="password"
      label="Create a password"
      {...field}
      {...validationProps}
      name="password"
      onBlur={() => {
        field.onBlur();
        setShowError(true);
      }}
    />
  ) : null;
};

export default PasswordInputs;

const PasswordLegend = ({ validPassword, showError }: { validPassword: boolean; showError: boolean }) => (
  <PasswordLegendWrapper>
    <ValidationCircle checked={validPassword} showError={showError} />
    Password should be a minimum of 9 characters
  </PasswordLegendWrapper>
);

const PasswordLegendWrapper = styled.div`
  font-size: 10px;
  margin-top: 0;
`;
