import type { RecentAnalysisRedirect } from '../../analysis/defaultAnalysis';

const now = Date.now();

const mockRecentViews: RecentAnalysisRedirect[] = [
  {
    url: '/analysis/results/performance/investment/226a8bee-8493-47c5-9475-dc807e9cceaf?active=list&period=1yr&relative=false',
    state: {},
    subjectName: 'H.G. McGrath - Primitive Funeral',
    createdTime: now,
  },
  {
    url: '/analysis/results/RETURNS_GRID/portfolio/45939/45939?compare=none&period=ytd&relative=false',
    state: { returnsGridFrequency: 'quarterly' },
    subjectName: 'AnnasFancyPortfolio',
    createdTime: now - 1000 * 60 * 60,
  },
];

export const mockRecentViewsWithSavedBaseline: RecentAnalysisRedirect[] = [
  {
    url: '/analysis/results/factor/portfolio/123/456?savedId=asdf-ghjk',
    state: { isTradesView: true },
    subjectName: 'Thingy',
    createdTime: now,
  },
];

export default mockRecentViews;
