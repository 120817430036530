import { isNil } from 'lodash';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { AnalysisContext, AnalysisViewContext, PortfoliosContext, UserContext } from 'venn-components';
import { forceZeroResidualForecastChangedAtom } from 'venn-state';
import type { AnalysisConfig } from 'venn-utils';
import { convert, useApi } from 'venn-utils';
import type { AnalysesResults } from '../types';
import type { FetchAnalysisResult } from './fetchAnalysis';
import fetchAnalysisApi from './fetchAnalysis';

/**
 * This logic handles returning analysis fetch result, including analysis errors and actual analysis timeFrame
 * We will refetch the analysis when analysisConfig.trackingId has changed.
 * @param analysisConfig Analysis Object
 */
const useAnalysis = (analysisConfig: AnalysisConfig) => {
  const apiRef = useRef(useApi(fetchAnalysisApi));
  const userContext = useContext(UserContext);
  const { templates } = useContext(AnalysisContext);
  const { masterPortfolio } = useContext(PortfoliosContext);
  const { rollingFactorExposuresPeriod, rollingFactorRiskPeriod, rollingFactorReturnPeriod, selectedNotablePeriods } =
    useContext(AnalysisViewContext);

  const masterPortfolioRef = useRef(masterPortfolio);
  const [apiAnalysisResult, setApiAnalysisResult] = useState<FetchAnalysisResult>({
    analysesError: undefined,
    analysesPeriod: {
      maxEndTime: undefined,
      maxStartTime: undefined,
      frequency: 'MONTHLY',
    },
    analyses: [],
    request: undefined,
    loading: false,
    actualTimeFrame: {
      startTime: undefined,
      endTime: undefined,
    },
  });
  const [resultTrackingId, setResultTrackingId] = useState<number>(-1);
  const currentBenchmark = analysisConfig.subject && analysisConfig.subject.activeBenchmarkId;
  // prevTrackingIdRef is used to determine when NOT to refresh analysis:
  // we only want to re-fetch analysis if analysisConfig.trackingId has changed.
  const prevTrackingIdRef = useRef<number>(-1);
  const forceZeroResidualForecastChanged = useRecoilValue(forceZeroResidualForecastChangedAtom);

  // When analysis type changes in the URL or the config subject changes, re-fetch-analysis
  useEffect(() => {
    const setAnalysisDetails = async () => {
      if (!analysisConfig || !analysisConfig.subject || !analysisConfig.subject.id) {
        // When loading subject, this effect will reset the analyses period values and the analysis result
        setApiAnalysisResult((prevConfig) => ({
          ...prevConfig,
          analysesPeriod: {
            maxEndTime: undefined,
            maxStartTime: undefined,
            frequency: 'MONTHLY',
          },
          analyses: [],
          analysesError: undefined,
        }));
        return;
      }

      let data: FetchAnalysisResult;

      setApiAnalysisResult((prevConfig) => ({
        ...prevConfig,
        analysesError: undefined,
        loading: true,
      }));

      try {
        const trendRollingPeriods = {
          rollingFactorExposuresPeriod,
          rollingFactorRiskPeriod,
          rollingFactorReturnPeriod,
        };
        data = await apiRef.current(
          analysisConfig,
          userContext.settings,
          masterPortfolioRef.current,
          templates,
          trendRollingPeriods,
          userContext.profileSettings,
          selectedNotablePeriods,
        );
      } catch (e) {
        if (e.name === 'AbortError') {
          return;
        }
        setApiAnalysisResult((prevConfig) => ({
          ...prevConfig,
          loading: false,
        }));
        setResultTrackingId(analysisConfig.trackingId);
        return;
      }

      // Only update the analysis result (and set loading = false) if we have got a non-empty result, or if
      // this is an "empty" query (no templates)
      if (
        data.analyses ||
        data.analysesError ||
        isNil(analysisConfig?.analysisTemplate?.analysisBlocks) ||
        analysisConfig?.analysisTemplate?.analysisBlocks.length === 0
      ) {
        setApiAnalysisResult((prevConfig) => ({
          ...prevConfig,
          ...data,
          loading: false,
        }));
        setResultTrackingId(analysisConfig.trackingId);
      }
    };

    // Only re-fetch analysis results if analysisConfig.trackingId has changed or if "Force Zero Residual Forecast" has been toggled
    const shouldRefetch =
      prevTrackingIdRef.current !== analysisConfig.trackingId ||
      analysisConfig.trackingId === -1 ||
      forceZeroResidualForecastChanged;
    if (shouldRefetch) {
      setAnalysisDetails();
    }
  }, [
    analysisConfig,
    currentBenchmark,
    templates,
    userContext.settings,
    userContext.profileSettings,
    rollingFactorExposuresPeriod,
    rollingFactorRiskPeriod,
    rollingFactorReturnPeriod,
    selectedNotablePeriods,
    forceZeroResidualForecastChanged,
  ]);

  const analysesResults: AnalysesResults | undefined = useMemo(
    () =>
      analysisConfig.subject && apiAnalysisResult.request && apiAnalysisResult.analyses
        ? convert(
            apiAnalysisResult.request,
            apiAnalysisResult.analyses,
            analysisConfig.subject,
            analysisConfig.relative,
            apiAnalysisResult.analysesPeriod,
          )
        : undefined,
    [apiAnalysisResult, analysisConfig.subject, analysisConfig.relative],
  );

  useEffect(() => {
    prevTrackingIdRef.current = analysisConfig.trackingId;
  }, [analysisConfig.trackingId]);

  return {
    analysesError: apiAnalysisResult.analysesError,
    loadingAnalysis: !!apiAnalysisResult.loading || resultTrackingId < analysisConfig.trackingId,
    analysesResults,
    actualTimeFrame: apiAnalysisResult.actualTimeFrame ?? {
      startTime: undefined,
      endTime: undefined,
    },
  };
};

export default useAnalysis;
