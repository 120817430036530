import { AuthButton, AuthLoader, AuthLogo, ContentWrapper, Info, StyledHeadline1 } from '../components';
import { Body2, Icon, Label, Link as LinkStyle, Subtitle1 } from 'venn-ui-kit';
import { FormInput, PhoneInput, useEnableSMS } from 'venn-components';
import React, { Suspense } from 'react';
import styled from 'styled-components';

interface EnableSMSProps {
  onChooseADifferentMethod: () => void;
  onComplete: () => void;
}

const VERIFICATION_INPUT_ID = 'verification-code';

const EnableSMS = ({ onChooseADifferentMethod, onComplete }: EnableSMSProps) => {
  const {
    phoneNumber,
    code,
    showVerify,
    onPhoneNumberChange,
    onVerificationCodeChange,
    onSubmit,
    resendCode,
    loading,
    error,
  } = useEnableSMS({ onComplete });

  return (
    <ContentWrapper>
      <AuthLogo />
      <Info>
        <StyledHeadline1>Enable text message authentication</StyledHeadline1>
        <Subtitle1>
          The phone number you enter will only ever be used for account security purposes. SMS and data rates may apply.
        </Subtitle1>
      </Info>
      {loading ? (
        <AuthLoader />
      ) : (
        <Suspense fallback={<AuthLoader />}>
          <StyledForm onSubmit={onSubmit}>
            <>
              <div className="form-field">
                {!showVerify ? (
                  <>
                    <StyledLabel inverted htmlFor="phone-form-control">
                      Enter a phone number which can receive an SMS
                    </StyledLabel>
                    <PhoneInput
                      defaultCountry="us"
                      preferredCountries={['jp', 'us', 'gb']}
                      disableAreaCodes
                      value={phoneNumber}
                      onChange={onPhoneNumberChange}
                      hasError={!!error}
                      hasValue={!!phoneNumber}
                    />
                  </>
                ) : (
                  <>
                    <StyledLabel inverted htmlFor={VERIFICATION_INPUT_ID}>
                      Confirm code sent to {phoneNumber}
                    </StyledLabel>
                    <FormInput
                      required
                      hint="––– –––"
                      value={code}
                      onChange={onVerificationCodeChange}
                      error={error}
                      inputId={VERIFICATION_INPUT_ID}
                      hintAsPlaceholder
                    />
                    <Body2 inverted>
                      Didn't get anything?{' '}
                      <LinkStyle inverted onClick={resendCode}>
                        Resend code
                      </LinkStyle>
                    </Body2>
                  </>
                )}
              </div>
              <AuthButtonContainer>
                <AuthButton dominant type="submit" disabled={!phoneNumber || (showVerify && !code)}>
                  Next
                </AuthButton>
              </AuthButtonContainer>
              <BackLink inverted onClick={onChooseADifferentMethod}>
                <Icon type="chevron-left" />
                &nbsp; Choose a different method
              </BackLink>
            </>
          </StyledForm>
        </Suspense>
      )}
    </ContentWrapper>
  );
};

export default EnableSMS;

const AuthButtonContainer = styled.div`
  margin-top: 15px;
`;

const StyledForm = styled.form`
  margin-top: 30px;
  max-width: 450px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const BackLink = styled(LinkStyle)`
  display: flex;
  justify-content: center;
`;

const StyledLabel = styled(Label)`
  display: block;
  margin-bottom: 5px;
`;
