import React from 'react';
import type { MenuProps } from '../Menu';
import Menu, { MenuType } from '../Menu';
import type { SearchProps } from './Search';
import Search from './Search';

export interface TypeaheadMenuProps<T> extends MenuProps<T>, SearchProps {}

export const TypeaheadMenu = <T,>({
  value,
  query,
  disabled,
  buttonProps,
  placeholder,
  onChange,
  onFocus,
  onBlur,
  isLoading,
  autoFocus,
  setInputRef,
  ...menuProps
}: TypeaheadMenuProps<T>) => (
  <Menu shouldRender type={MenuType.Search} isLoading={isLoading} {...menuProps} searchWords={query}>
    <Search
      value={value}
      query={query}
      disabled={disabled}
      buttonProps={buttonProps}
      isLoading={isLoading}
      placeholder={placeholder}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      autoFocus={autoFocus}
      setInputRef={setInputRef}
    />
  </Menu>
);

export default TypeaheadMenu;
