import type { IHeaderParams } from 'ag-grid-community';
import { isNil } from 'lodash';
import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { getItemColor, ItemIcon, ItemType, Tooltip } from 'venn-ui-kit';
import { getComparisonLabelForBlockLegend, getComparisonLabelForTooltip } from 'venn-utils';
import { convertRequestSubjectToItemType, isBenchmark } from '../../../../analysis';
import type { HeaderComponentParamsType } from '../../../types';
import SubjectLink from './SubjectLink';
import NameWithOptionalFee from '../../../../legend/NameWithOptionalFee';

export interface HeaderCellRendererProps extends IHeaderParams, HeaderComponentParamsType {
  style?: React.CSSProperties;
}

const HeaderCellRenderer = ({
  subject,
  color,
  isCommonBenchmark,
  relativeTo,
  style,
  noLink = false,
}: HeaderCellRendererProps) => {
  const { Colors } = useContext(ThemeContext);

  if (!subject) {
    return <>--</>;
  }

  const { portfolioComparisonType, primaryPortfolio } = subject;

  const renderLink = () =>
    !noLink ? (
      <SubjectLink subject={subject} customColor={color} isCommonBenchmark={isCommonBenchmark} headerMode />
    ) : (
      <StyledName
        data-testid="qa-header-cell-renderer-no-link"
        color={color ?? getItemColor(Colors, convertRequestSubjectToItemType(subject, isCommonBenchmark))}
      >
        <NameWithOptionalFee subject={subject} />
      </StyledName>
    );

  return (
    <HeaderCellWrapper
      className="header-cell-wrapper"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        ...style,
      }}
    >
      {!isNil(portfolioComparisonType) && !isNil(subject.portfolio) ? (
        <StyledCellWrapper>
          <StyledTooltip
            content={
              primaryPortfolio
                ? getComparisonLabelForTooltip(
                    subject.portfolio,
                    subject.strategy,
                    portfolioComparisonType,
                    primaryPortfolio,
                  )
                : undefined
            }
            isHidden={isNil(primaryPortfolio)}
            usePortal
          >
            {getComparisonLabelForBlockLegend(subject)}
          </StyledTooltip>
        </StyledCellWrapper>
      ) : (
        renderLink()
      )}
      {relativeTo && (
        <div
          style={{
            fontWeight: 'lighter',
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'row',
            alignItems: 'center',
            paddingBottom: 5,
          }}
        >
          <RelativeToIcon item={ItemType.CommonBenchmark} />
          <SubjectLink
            subject={relativeTo}
            headerMode
            style={{ paddingTop: 0, paddingBottom: 0 }}
            isCommonBenchmark={!isBenchmark(relativeTo)}
          />
        </div>
      )}
    </HeaderCellWrapper>
  );
};

export default HeaderCellRenderer;

const RelativeToIcon = styled(ItemIcon)`
  margin: 0 0.5em;
`;

const HeaderCellWrapper = styled.div`
  align-items: baseline;
  display: inline-flex;
  justify-content: flex-end;
  width: 100%;
`;

const StyledCellWrapper = styled.div`
  text-align: right;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
`;

const StyledTooltip = styled(Tooltip)`
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
`;

const StyledName = styled.div<{ color: string }>`
  padding-top: 5px;
  padding-bottom: 5px;
  white-space: pre-line;
  color: ${({ color }) => color};
`;
