import type { NotablePeriod } from 'venn-api';
import { useMemo } from 'react';
import type { CustomNotablePeriod, SelectableCustomNotablePeriod, SelectableNotablePeriodItem } from './types';
import { getAllNotablePeriodItems, getSelectedAndOrderedNotablePeriodItems } from './notablePeriodUtils';
import type { CustomBlockTypeEnum } from 'venn-utils';

/**
 * Hook returning a list all notable periods from `view.customViewOptions` with a flag for whether they are selected
 * for analysis in this view. The returned value is memoized and will not change if neither of the used values
 * in `view.customViewOptions` nor the other hook arguments have changed.
 *
 */
export const useGetAllNotablePeriodItems = (
  selectedOrderedPeriodIds: number[] | undefined,
  selectableCustomNotablePeriods: SelectableCustomNotablePeriod[] | undefined,
  blockType: CustomBlockTypeEnum | undefined,
  allPredefinedPeriods: NotablePeriod[] | undefined,
): SelectableNotablePeriodItem[] | undefined => {
  const items = useMemo(
    () =>
      allPredefinedPeriods && blockType === 'NOTABLE_PERIODS'
        ? getAllNotablePeriodItems(selectedOrderedPeriodIds, selectableCustomNotablePeriods, allPredefinedPeriods)
        : undefined,
    [selectedOrderedPeriodIds, selectableCustomNotablePeriods, allPredefinedPeriods, blockType],
  );

  return items;
};

/**
 * Hook returning a list of selected predefined & custom notable periods from `view.customViewOptions`, in the order
 * set by the user in the settings panel, and with `source` field to determine if they are `PREDEFINED` or `CUSTOM`.
 * The returned value is memoized and will not change if neither of the used values in `view.customViewOptions` nor
 * the other hook arguments have changed.
 *
 */
export const useGetSelectedAndOrderedNotablePeriodItems = (
  selectedOrderedPeriodIds: number[] | undefined,
  selectableCustomNotablePeriods: SelectableCustomNotablePeriod[] | undefined,
  blockType: CustomBlockTypeEnum | undefined,
  allPredefinedPeriods: NotablePeriod[] | undefined,
): CustomNotablePeriod[] | undefined => {
  const items = useMemo(
    () =>
      allPredefinedPeriods && blockType === 'NOTABLE_PERIODS'
        ? getSelectedAndOrderedNotablePeriodItems(
            selectedOrderedPeriodIds,
            selectableCustomNotablePeriods,
            allPredefinedPeriods,
          )
        : undefined,
    [selectedOrderedPeriodIds, selectableCustomNotablePeriods, allPredefinedPeriods, blockType],
  );

  return items;
};
