import React from 'react';
import moment from 'moment';
import styled, { css } from 'styled-components';
import EmptyState from '../empty-state/EmptyState';
import type { BasicTableColumn, BasicTableProps, StyledTableType } from '../basictable/BasicTable';
import BasicTable from '../basictable/BasicTable';
import type { DrawdownPeriodAnalysis, Message } from 'venn-api';
import { GetColor } from 'venn-ui-kit';
import type { AnyDuringEslintMigration } from 'venn-utils';

const qaClass = 'qa-historical-table';

export interface DrawdownTableProps {
  drawdownPeriods: Partial<DrawdownPeriodAnalysis>[];
  hasAnalyzeError: boolean;
  isPortfolio?: boolean;
  threshold: number;
  selectedRow: Partial<DrawdownPeriodAnalysis> | null;
  relative?: boolean;
  isFilteredByThresholdServerSide?: boolean;
  error?: Message;
  columns: BasicTableColumn<DrawdownPeriodAnalysis>[];
  onPeriodSelect?: (period: DrawdownPeriodAnalysis) => void;
}

export const DrawdownTable = (props: DrawdownTableProps) => {
  const {
    drawdownPeriods,
    threshold,
    selectedRow,
    onPeriodSelect,
    hasAnalyzeError,
    relative,
    isFilteredByThresholdServerSide,
    isPortfolio,
    error,
    columns,
  } = props;

  // When drawdownPeriods.length === 0:
  //   - If the page gets its drawdownPeriods already filtered by threshold from the server, that means the only time
  //     we should show "no drawdown periods" is when threshold is 0 (example: Drawdown Analysis page).
  //   - If the page always gets all the drawdown periods from the server, and then filters them by threshold on the
  //     client, we should show "no drawdown periods" if there is nothing to filter (example: Tearsheet).
  const noResultsAvailable =
    !drawdownPeriods ||
    (isFilteredByThresholdServerSide && threshold.toFixed(1) === '0.0' && drawdownPeriods.length === 0) ||
    (!isFilteredByThresholdServerSide && drawdownPeriods.length === 0);
  if (noResultsAvailable) {
    return (
      <StyledPaddingEmptyState
        header={`No ${relative ? 'underperformance' : 'drawdown'} periods found`}
        message={
          error
            ? // TODO: (VENN-20577 / TYPES) is autogenerated type for Message wrong, should it contain the .message field?
              error.text || (error as AnyDuringEslintMigration).message
            : `${isPortfolio ? 'Portfolio' : 'Investment'} doesn't have ${
                relative ? 'underperformance' : 'drawdown'
              } periods within the selected time frame.`
        }
      />
    );
  }

  const start = drawdownPeriods.length ? drawdownPeriods[0].start : null;
  const end = drawdownPeriods.length ? drawdownPeriods[drawdownPeriods.length - 1].end : null;

  const filteredData: Partial<DrawdownPeriodAnalysis>[] = drawdownPeriods.filter(
    (period) => Number(((period.maxDrawdown ?? NaN) * 100).toFixed(1)) <= threshold * 100,
  );

  const startYear = start ? moment.utc(start).format('YYYY') : null;
  const endYear = end ? moment.utc(end).add(1, 'years').format('YYYY') : null;

  const noHistoricalResultsMessage = filteredData.length === 0;

  if (noHistoricalResultsMessage) {
    return (
      <StyledPaddingEmptyState
        header="No historical periods were found."
        message="Try lowering the drawdown threshold to view additional periods."
      />
    );
  }

  return (
    <StyledBasicTable
      noHoverState={!hasAnalyzeError}
      data={filteredData}
      onRowSelect={onPeriodSelect}
      selectedRow={selectedRow}
      className={qaClass}
      selectable
      startYear={startYear}
      endYear={endYear}
      columns={columns as AnyDuringEslintMigration}
    />
  );
};

export default DrawdownTable;

const StyledPaddingEmptyState = styled(EmptyState)`
  margin: 20px;
`;

const tableFooterYearsRangeMixin = (startYear: string | null, endYear: string | null) => css`
  &:before,
  &:after {
    position: absolute;
    font-size: 12px;
    color: ${GetColor.MidGrey2};
    bottom: -3px;
  }

  &:before {
    content: '${startYear}';
    left: 0;
    transform: translate(-50%, 100%);
  }

  &:after {
    content: '${endYear}';
    right: 0;
    transform: translate(50%, 100%);
  }
`;

export const StyledBasicTable: StyledTableType<{
  noHoverState: boolean;
  startYear: string | null;
  endYear: string | null;
}> = styled(<T extends BasicTableColumn<K>, K>(props: BasicTableProps<T, K>) => <BasicTable<T, K> {...props} />)<{
  noHoverState: boolean;
  startYear: string | null;
  endYear: string | null;
}>`
  margin: 30px 0 35px;
  width: 100%;

  tr {
    border-bottom: 1px solid ${GetColor.Grey};
    height: 35px;
  }

  th {
    i {
      color: ${GetColor.MidGrey2};
    }
  }

  th:nth-child(2) {
    i {
      margin: 0 2px;
      color: ${GetColor.Black};
    }
  }

  tbody {
    tr {
      cursor: pointer;
    }

    ${(props) =>
      props.noHoverState &&
      css`
        tr {
          cursor: inherit;
        }
        tr: hover {
          background-color: transparent;
        }
      `}

    tr:last-child td:nth-child(2) {
      ${(props) => tableFooterYearsRangeMixin(props.startYear, props.endYear)};
    }
  }

  @media print {
    margin: 5px 0 18px;

    thead th {
      padding: 0 !important;
      max-width: 60px;
      min-width: 60px !important;
      width: 60px !important;
    }

    thead th:nth-child(2) {
      max-width: 120px;
      min-width: 120px !important;
      width: 120px !important;
    }
  }
`;
