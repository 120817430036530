import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';

// all in px
export const NUMBER_COL_WIDTH = 40;
export const VALUE_COL_WIDTH = 250;
export const DATE_COL_MIN_WIDTH = 200; // to fit the "Click to add new date" hint

export const HEADER_HEIGHT = 33;
export const ROW_HEIGHT = 35;

export const NumberCol = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  width: ${NUMBER_COL_WIDTH}px;
  height: ${ROW_HEIGHT}px;
  border-bottom: 2px solid ${GetColor.Grey};
  background-color: ${GetColor.DEPRECATED_DivergingColor.MID};
`;
