import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import type { DropMenuCheckboxItem, FilterDropMenuProps } from 'venn-ui-kit';
import { FilterDropMenu } from 'venn-ui-kit';

interface FactorDropDown {
  items: DropMenuCheckboxItem[];
  onItemClick: (filtredItems: DropMenuCheckboxItem[]) => void;
}

const FactorDropDown = ({ items, onItemClick }: FactorDropDown) => (
  <StyledFilterDropMenu<FC<FilterDropMenuProps<string>>>
    onChange={onItemClick}
    label="Display Factor(s):"
    height="60vh"
    width={300}
    items={items}
  />
);

const StyledFilterDropMenu = styled(FilterDropMenu)`
  max-width: 200px;
`;

export default FactorDropDown;
