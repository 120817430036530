/**
 * Returns the next name to use given a prefix and the set of names that are already in use for the input.
 */
export const getNextInputName = (prefix: string, allNamesSoFar: string[]): string => {
  const MIN_SUFFIX = 1;
  const highestSuffixSoFar = allNamesSoFar.reduce((maxSuffixSoFar, currentName) => {
    if (!currentName.startsWith(prefix)) {
      return maxSuffixSoFar;
    }
    const currentSuffix = Number.parseInt(currentName.substring(prefix.length), 10);
    return Math.max(maxSuffixSoFar, Number.isNaN(currentSuffix) ? 0 : currentSuffix);
  }, MIN_SUFFIX - 1);

  return `${prefix}${highestSuffixSoFar + 1}`;
};
