import React from 'react';

import type { WhatsNewContent } from 'venn-api';
import { Button } from 'venn-ui-kit';
import { useModal } from 'venn-utils';
import EditDataModal from './EditDataModal';

interface EditButtonProps {
  whatsNewData?: WhatsNewContent;
  disabled: boolean;
  onConfirm: (data: WhatsNewContent) => void;
}

const EditButton = ({ whatsNewData, disabled, onConfirm }: EditButtonProps) => {
  const [isOpen, open, close] = useModal();

  return (
    <>
      <Button className="qa-edit-content-btn" dominant disabled={disabled} onClick={open}>
        Edit Content
      </Button>

      {isOpen && whatsNewData ? (
        // @ts-expect-error: TODO fix strictFunctionTypes
        <EditDataModal close={close} onConfirm={onConfirm} whatsNewData={whatsNewData} />
      ) : null}
    </>
  );
};

export default EditButton;
