import { atomFamily, type RecoilState } from 'recoil';
import { type BlockId } from '../types';
import { type CustomMetricGrid, type PCBlockCustomMetricGrid } from '../customFieldTypes';
import { isEmpty, isNil, mapValues, omitBy } from 'lodash';

export const customField_gridData = atomFamily<CustomMetricGrid, BlockId>({
  key: 'customField_gridData',
  default: {},
  effects: [],
});

/** Type wrapper around {@link customField_gridData} with key types narrowed to the key types used in the Portfolio Comparison block. */
export const pcBlock_customField_gridData: (blockId: BlockId) => RecoilState<PCBlockCustomMetricGrid> =
  customField_gridData;

/** Removes empty cells from rows, and removes empty rows from the grid. */
export const cleanEmptyFromGrid = (grid: CustomMetricGrid) => {
  const withCleanedRows = mapValues(grid, (rowOfCells) => omitBy(rowOfCells, isNilOrBlank));
  return omitBy(withCleanedRows, isEmpty);
};
const isNilOrBlank = (cellValue: string | undefined) => isNil(cellValue) || cellValue.trim().length === 0;
