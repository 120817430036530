import React from 'react';
import { useAdvertisingTrackingAllowed } from 'venn-components';

/**
 * This pixel will helps identify conversions that didn't click on our ads but saw them and ended up converting.
 * @constructor
 */
const SignupTrackingPixel = () => {
  const allowed = useAdvertisingTrackingAllowed();
  if (!allowed) {
    return null;
  }
  return (
    <img
      height="1"
      width="1"
      style={{ display: 'none' }}
      alt=""
      src="https://px.ads.linkedin.com/collect/?pid=1421089&conversionId=1421514&fmt=gif"
    />
  );
};

export default SignupTrackingPixel;
