import styled, { css } from 'styled-components';
import AutosizeInput from 'react-input-autosize';
import { GetColor } from 'venn-ui-kit';
import type { Page } from 'venn-components';
import TextareaAutosize from 'react-textarea-autosize';
import { useSetRecoilState } from 'recoil';
import React, { useCallback, type ComponentProps } from 'react';
import { viewPages } from 'venn-state';

export const useUpdatePage = (pageNumber: number) => {
  const setPages = useSetRecoilState(viewPages);

  const updatePage = useCallback(
    (pageUpdates: Partial<Page>) => {
      setPages((pages) =>
        pages.map((page, index) =>
          index === pageNumber
            ? ({
                ...page,
                ...pageUpdates,
              } as Page)
            : page,
        ),
      );
    },
    [pageNumber, setPages],
  );

  return updatePage;
};

export const InlineInput = (props: ComponentProps<typeof InternalInlineInput>) => {
  // Necessary to dismount and remount the component when font related properties change per the docs @ https://www.npmjs.com/package/react-input-autosize#changing-the-styles-at-runtime
  const key = props.style
    ? [props.style.fontSize, props.style.fontWeight, props.style.lineHeight].join(',')
    : undefined;

  return <InternalInlineInput key={key} {...props} />;
};
const InternalInlineInput = styled(AutosizeInput)<{ $inPrintMode: boolean }>`
  // TODO remove when possible
  display: block !important;
  margin-left: -10px;

  > input {
    background-color: transparent;
    color: unset;
    border: none;
    border-radius: 4px;

    :hover,
    :focus {
      outline: 1px solid ${GetColor.Primary.Main};
    }

    ${({ $inPrintMode }) =>
      $inPrintMode &&
      css`
        ::placeholder {
          color: transparent;
        }
      `}
  }
`;

export const MultilineInput = styled(TextareaAutosize)<{
  inPrintMode: boolean;
}>`
  display: block !important;
  transform: translateX(10px);
  border-color: transparent;
  resize: none;
  width: 100%;
  text-align: right;

  :hover {
    outline: 1px solid ${GetColor.Primary.Main};
  }

  :focus {
    outline: 1px solid transparent;
  }

  ${({ inPrintMode }) =>
    inPrintMode &&
    css`
            resize: none;

            ::placeholder {
              color: transparent;
            }

          ,
          `}
`;

export const CoverPageRow = styled.div<{ height: string }>`
  position: relative;
  display: block;
  font-size: 30px;
  text-align: left;
  height: ${(props) => props.height ?? '33%'};
  border: 0px;
  padding: 0px;
  margin: 0px;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  @media screen {
    width: 100%;
  }
  @media print {
    width: 100vw;
  }
`;

export const CoverPageOrganizationName = styled.span`
  vertical-align: text-bottom;
  margin: 0px;
  padding: 0px;
  text-align: right;
  font-weight: bold;
`;
