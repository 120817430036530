import React, { Component } from 'react';
import { getAppTitle, Icon, Loading, LoadingSize } from 'venn-ui-kit';
import { setupPhoneMFA, verifyPhoneMFA } from 'venn-api';
import { Content, Error, Field, Hint, Input, Label, SubHeader } from '../account-modals/shared';
import type ReactPhoneInputModule from 'react-phone-input-2';
import styled from 'styled-components';
import BackupCodes from './BackupCodes';
import { ModalFooter } from '../../modal';

interface EnableSMSProps {
  onBack: undefined | (() => void);
  onClose: () => void;
  refresh: () => void;
}

interface EnableSMSState {
  phoneNumber: string;
  code: string;
  showVerify: boolean;
  showBackupCodes: boolean;
  loading: boolean;
  error: string;
  reactPhoneInput: typeof ReactPhoneInputModule | null;
}

const TEXT_BACKUP_CONFIRM_MESSAGE = 'my text messages.';
const BACKUP_HEADER = 'Save your backup codes.';

const sanitizePhoneNumber = (phoneNumber: string) => phoneNumber.replace(/[^0-9\.]+/g, '');

export class EnableSMS extends Component<EnableSMSProps, EnableSMSState> {
  state = {
    phoneNumber: '',
    code: '',
    error: '',
    showVerify: false,
    showBackupCodes: false,
    loading: false,
    reactPhoneInput: null,
  };

  formRef = React.createRef<HTMLFormElement>();

  componentDidMount() {
    import('react-phone-input-2').then((module) => this.setState({ reactPhoneInput: module.default }));
  }

  onPhoneNumberChange = (phoneNumber: string) => {
    this.setState({
      phoneNumber,
    });
  };

  onVerificationCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    this.setState({
      code: value,
    });
  };

  onSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    const { showVerify } = this.state;
    if (showVerify) {
      this.onSubmitVerificationCode();
    } else {
      this.onSubmitNumber();
    }
  };

  onSubmitNumber = async () => {
    const { phoneNumber } = this.state;
    this.setState({
      loading: true,
      error: '',
    });
    try {
      await setupPhoneMFA({ phoneNumber: sanitizePhoneNumber(phoneNumber) });
      this.setState({
        showVerify: true,
      });
    } catch (e) {
      const error = await e;
      this.setState({ error: error.content?.message });
    }

    this.setState({
      loading: false,
    });
  };

  onSubmitVerificationCode = async () => {
    const { code } = this.state;
    this.setState({
      loading: true,
    });
    const { refresh } = this.props;
    try {
      await verifyPhoneMFA({ code });
      this.setState({
        showVerify: false,
        showBackupCodes: true,
        error: '',
      });
      refresh();
    } catch (e) {
      const error = await e;
      this.setState({ error: error.content?.message });
    }

    this.setState({
      loading: false,
    });
  };

  resendCode = this.onSubmitNumber;

  render() {
    const { onBack, onClose } = this.props;
    const { code, error, loading, phoneNumber, showBackupCodes, showVerify, reactPhoneInput } = this.state;
    if (loading || !reactPhoneInput) {
      return (
        <Content>
          <Loading size={LoadingSize.small} title="Loading..." />
        </Content>
      );
    }

    const ReactPhoneInput: typeof ReactPhoneInputModule = reactPhoneInput;
    const confirmation = `${getAppTitle()} just sent a text message with a verification code to ${phoneNumber}.`;
    return (
      <>
        <Content>
          <SubHeader>
            <Icon type="mobile-alt" prefix="fas" />
            &nbsp;Enable text message
          </SubHeader>
        </Content>
        <form onSubmit={this.onSubmit} ref={this.formRef}>
          <Field>
            <Content>
              {!showVerify && !showBackupCodes && (
                <>
                  <Label>What phone number do you want to use?</Label>
                  <StyledReactPhoneInput>
                    <ReactPhoneInput
                      defaultCountry="us"
                      preferredCountries={['jp', 'us', 'gb']}
                      disableAreaCodes
                      value={phoneNumber}
                      onChange={this.onPhoneNumberChange}
                    />
                  </StyledReactPhoneInput>
                  <Hint>
                    This phone number will only ever be used for account security purposes. SMS and data rates may
                    apply.
                  </Hint>
                </>
              )}
              {showVerify && (
                <>
                  <Label>Confirm that it works</Label>
                  <Hint>{confirmation}</Hint>
                  <Input defaultValue={code} onChange={this.onVerificationCodeChange} />
                  <p>
                    Didn't get it?{' '}
                    <button onClick={this.resendCode} type="button">
                      Resend
                    </button>
                  </p>
                </>
              )}
            </Content>
            {showBackupCodes && (
              <BackupCodes onClose={onClose} header={BACKUP_HEADER} confirmMessage={TEXT_BACKUP_CONFIRM_MESSAGE} />
            )}
          </Field>
          <Content>
            <Error>{error}</Error>
          </Content>
          {!showBackupCodes && (
            <ModalFooter
              primaryLabel="Next"
              onPrimaryClick={this.onSubmit}
              onCancel={onBack ?? onClose}
              cancelLabel={onBack ? 'Back' : 'Cancel'}
            />
          )}
        </form>
      </>
    );
  }
}

export default EnableSMS;

const StyledReactPhoneInput = styled.div`
  .react-tel-input {
    margin-top: 15px;
    input[type='tel'] {
      font-weight: normal;
    }
    .flag-dropdown {
      border-right: none;
      background: none;
    }
  }
`;
