import { useMemo, useState, useCallback } from 'react';
import min from 'lodash/min';
import max from 'lodash/max';
import sortBy from 'lodash/sortBy';
import reverse from 'lodash/reverse';
import orderBy from 'lodash/orderBy';
import type { PerformanceAttribution, PortfolioPerformanceAttribution } from 'venn-api';

export type SortKey = keyof PerformanceAttribution;

const useModalLogic = (attributions: PortfolioPerformanceAttribution, value: number) => {
  const [displayFunds, setDisplayFunds] = useState(true);
  const [currentSortKey, setCurrentSortKey] = useState<SortKey>('contributionValue');
  const [sortAscending, setSortAscending] = useState(value < 0);
  const contributions = displayFunds ? attributions.funds : attributions.strategies;

  const shouldDisplayToggle = useMemo(() => {
    if (attributions.funds.length !== attributions.strategies.length) {
      return true;
    }
    const sortedFunds = sortBy(attributions.funds, 'name');
    const sortedStrategies = sortBy(attributions.strategies, 'name');

    for (let i = 0; i < sortedFunds.length; i++) {
      if (sortedFunds[i].name !== sortedStrategies[i].name) {
        return true;
      }
    }

    return false;
  }, [attributions]);

  const toggle = useCallback(() => {
    setDisplayFunds(!displayFunds);
  }, [displayFunds]);

  const sort = useCallback(
    (sortKey: SortKey) => {
      if (sortKey === currentSortKey) {
        setSortAscending(!sortAscending);
      } else {
        setCurrentSortKey(sortKey);
        // by default sort names ascending but every other column descending
        setSortAscending(sortKey === 'name');
      }
    },
    [currentSortKey, sortAscending],
  );

  const list = useMemo(() => {
    const sorted =
      currentSortKey === 'name'
        ? orderBy(contributions, (item) => item.name?.toLowerCase())
        : sortBy(contributions, currentSortKey);
    if (!sortAscending) {
      return reverse(sorted);
    }
    return sorted;
  }, [contributions, currentSortKey, sortAscending]);
  const maximum = useMemo(() => getMax(contributions), [contributions]);

  return {
    /**
     * Display funds or strategies?
     */
    displayFunds: displayFunds || !shouldDisplayToggle,
    /**
     * List of contribution to display (already sorted)
     */
    list,
    /**
     * Max value for the bars
     */
    maximum,
    /**
     * Toggle between funds and strategies
     */
    toggle,
    /**
     * Whether the investments/strategies should be shown to the user
     */
    shouldDisplayToggle,
    /**
     * Sort function. Call this with the key you want to sort on
     */
    sort,
    /**
     * Current sort key
     */
    currentSortKey,
    /**
     * Current sort direction
     */
    sortDirection: sortAscending,
  };
};

const getMax = (investments: PerformanceAttribution[]) => {
  const contributions = investments.map((i) => i.contributionValue);
  const minimum = min(contributions);
  const maximum = max(contributions);
  if (minimum === undefined || maximum === undefined) {
    return minimum || maximum;
  }
  return max([Math.abs(minimum), Math.abs(maximum)]);
};

export default useModalLogic;
