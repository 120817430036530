import type { ColGroupDef } from 'ag-grid-community';
import React, { useMemo } from 'react';
import { ceil, compact, get } from 'lodash';
import { useRecoilValue } from 'recoil';
import type {
  PeerGroupAnalysisResponse,
  PeerGroupAnalysisSubjectMetricResponse,
  PeerGroupAnalysisUniverseMetricResponse,
} from 'venn-api';
import {
  blockBenchmarkConfig,
  blockCustomizableMetrics,
  blockLimitedRequestSubjects,
  blockMetrics,
  viewSelectedInvestmentGroupInformation,
} from 'venn-state';
import {
  assertNotNil,
  type CustomizableMetric,
  FootnoteSymbols,
  getRequestSubjectFromAnalysisSubject,
} from 'venn-utils';
import { NameWithOptionalFee } from '../../../../legend/NameWithOptionalFee';
import { useBlockId } from '../../../contexts/BlockIdContext';
import { RIGHT_ALIGN_CLASS, VALUE_CELL_RENDERER } from '../../../customAnalysisContants';
import { useMeasureGridText } from '../../../../utils/grids';
import MetricNameHeaderRenderer from '../../grid/renderers/MetricNameHeaderRenderer';
import type { PGADatapoint, PGARowData } from './types';
import { universeDatapoints } from './types';
import { rankColumnFormatter, rankColumnGetter, valueColumnFormatter, valueColumnGetter } from './utils';

const ERROR_TOOLTIP_WIDTH = 14; // 12px for icon + 2px for padding

function usePeerGroupsGridColumnDefs() {
  const blockId = useBlockId();
  const selectedMetrics = useRecoilValue(blockMetrics(blockId));
  const allMetrics = useRecoilValue(blockCustomizableMetrics(blockId));
  const relative = useRecoilValue(blockBenchmarkConfig(blockId)).relative;
  const measureText = useMeasureGridText();

  return useMemo(() => {
    const expectedRankColumnWidth = measureText('Rank *', 'bold');
    const expectedValueColumnWidth = measureText('100.00%', 'normal');
    const expectedColumnsWidth = expectedValueColumnWidth + expectedRankColumnWidth;
    return [
      {
        // This trick with wrapping name column is needed to make sure columns are aligned properly in Excel export
        headerName: '',
        marryChildren: true,
        children: [
          {
            wrapText: true,
            autoHeight: true,
            headerName: '',
            field: 'name',
            cellRenderer: ({ value }: { value: string | JSX.Element }) => value,
            minWidth: measureText('Peer Group Summary' as const, 'bold'),
          },
        ],
      },
      ...selectedMetrics.map((metric, index) => {
        const metricMetadata = assertNotNil(allMetrics.find((customizableMetric) => customizableMetric.key === metric));
        const metricLabel = assertNotNil(relative ? metricMetadata.relativeLabel : metricMetadata.label);
        const headerWidth = Math.max(measureText(metricLabel, 'bold'), expectedColumnsWidth) + ERROR_TOOLTIP_WIDTH;
        return {
          marryChildren: true,
          headerGroupComponent: MetricNameHeaderRenderer,
          headerGroupComponentParams: {
            displayName: metricLabel,
            padding: 8,
          },
          headerName: metricLabel, // not displayed because of headerGroupComponent, but required for export
          headerClass: [RIGHT_ALIGN_CLASS],
          cellStyle: {
            textAlign: 'right' as const,
          },
          children: [
            {
              headerName: 'Value',
              maxWidth: ceil(headerWidth * (expectedValueColumnWidth / expectedColumnsWidth)),
              minWidth: ceil(headerWidth * (expectedValueColumnWidth / expectedColumnsWidth)),
              colId: `${metric}-value`,
              cellRenderer: VALUE_CELL_RENDERER,
              cellClass: (data) => {
                return [data?.data?.rowId === 'constituents' ? 'NUMERIC' : metricMetadata.dataType, RIGHT_ALIGN_CLASS];
              },
              valueGetter: ({ data }) => {
                return valueColumnGetter({
                  data,
                  index,
                });
              },
              valueFormatter: ({ data }) => {
                return valueColumnFormatter({
                  data,
                  index,
                  dataType: metricMetadata.dataType,
                });
              },
            },
            {
              headerName: `Rank${FootnoteSymbols.pgaRank}`,
              maxWidth: ceil(headerWidth * (expectedRankColumnWidth / expectedColumnsWidth)),
              minWidth: ceil(headerWidth * (expectedRankColumnWidth / expectedColumnsWidth)),
              colId: `${metric}-rank`,
              cellClass: () => ['NUMERIC', RIGHT_ALIGN_CLASS],
              valueGetter: ({ data }) => {
                return rankColumnGetter({
                  data,
                  index,
                });
              },
              valueFormatter: ({ data }) => {
                return rankColumnFormatter({
                  data,
                  index,
                });
              },
            },
          ],
        } as ColGroupDef<PGARowData>;
      }),
    ];
  }, [measureText, selectedMetrics, allMetrics, relative]);
}

function transformToRowData(
  response: PeerGroupAnalysisResponse,
  subjectIndex: number,
  selectedMetrics: string[],
  allMetrics: CustomizableMetric[],
  relative: boolean,
): PGADatapoint[] {
  return selectedMetrics.map((metric) => {
    const metricMetadata = assertNotNil(allMetrics.find((customizableMetric) => customizableMetric.key === metric));
    const dataForThisMetric = get(
      response.subjectsData,
      assertNotNil(relative ? metricMetadata.relativeMetricPath : metricMetadata.metricPath),
    )?.[subjectIndex] as unknown as PeerGroupAnalysisSubjectMetricResponse;

    return {
      value: dataForThisMetric?.value,
      rank: dataForThisMetric?.percentile,
    } as PGADatapoint;
  });
}

function transformToUniverseRowData(
  universe: { [key: string]: { [key: string]: PeerGroupAnalysisUniverseMetricResponse } },
  rowId: string,
  selectedMetrics: string[],
  allMetrics: CustomizableMetric[],
  relative: boolean,
): PGADatapoint[] {
  return selectedMetrics.map((metric) => {
    const metricMetadata = assertNotNil(allMetrics.find((customizableMetric) => customizableMetric.key === metric));
    const universeDataForThisMetric = get(
      universe,
      assertNotNil(relative ? metricMetadata.relativeMetricPath : metricMetadata.metricPath),
    ) as unknown as PeerGroupAnalysisSubjectMetricResponse;
    return {
      value: universeDataForThisMetric?.[rowId],
      rank: undefined,
    } as PGADatapoint;
  });
}

function usePGAGridRowData(response: PeerGroupAnalysisResponse): PGARowData[] {
  const blockId = useBlockId();
  const selectedMetrics = useRecoilValue(blockMetrics(blockId));
  const allMetrics = useRecoilValue(blockCustomizableMetrics(blockId));
  const subjects = useRecoilValue(blockLimitedRequestSubjects(blockId));
  const selectedInvestmentGroupInfoRows = useRecoilValue(viewSelectedInvestmentGroupInformation(blockId));
  const benchmarkConfig = useRecoilValue(blockBenchmarkConfig(blockId));
  return compact([
    ...subjects.map((subject, subjectIndex) => {
      return {
        name: <NameWithOptionalFee subject={subject} />,
        rowData: transformToRowData(response, subjectIndex, selectedMetrics, allMetrics, benchmarkConfig.relative),
        rowId: subject.id,
      } as PGARowData;
    }),
    benchmarkConfig.type === 'COMMON' &&
      !benchmarkConfig.relative &&
      benchmarkConfig.subject &&
      ({
        name: <NameWithOptionalFee subject={getRequestSubjectFromAnalysisSubject(benchmarkConfig.subject)} />,
        rowData: transformToRowData(response, subjects.length, selectedMetrics, allMetrics, benchmarkConfig.relative),
        rowId: benchmarkConfig.subject.id,
      } as PGARowData),
    {
      name: 'Peer Group Summary',
      rowData: [],
      isMetadata: true,
      rowId: 'peer-group-summary',
    } as PGARowData,
    ...universeDatapoints
      .filter((datapoint) => !selectedInvestmentGroupInfoRows || selectedInvestmentGroupInfoRows.includes(datapoint.id))
      .map((datapoint) => {
        return {
          name: datapoint.name,
          id: datapoint.id,
          rowData: transformToUniverseRowData(
            response.universeData,
            datapoint.id,
            selectedMetrics,
            allMetrics,
            benchmarkConfig.relative,
          ),
          rowId: datapoint.id,
          isMetadata: datapoint.id === 'constituents',
          isUniverse: true,
        };
      }),
  ]);
}

export const usePeerGroupsGrid = (data: PeerGroupAnalysisResponse) => {
  const columnDefs = usePeerGroupsGridColumnDefs();
  const rowData = usePGAGridRowData(data);
  return {
    columnDefs,
    rowData,
  };
};
