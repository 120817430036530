import type {
  AnalysisComparisonTypeEnum,
  AnalysisPortfolioComparisonTypeEnum,
  AnalysisView,
  HoldingsSubject,
  Portfolio,
  PrivatePortfolioNode,
} from 'venn-api';
import { isEmpty, isEqual, isNil, partition, startCase } from 'lodash';
import type { StudioRequestSubject, Subject, SubjectWithFee, SubjectWithOptionalFee } from './types';
import type { CustomBlockTypeEnum } from 'venn-utils';
import { assertNotNil, type CustomizableMetric, getRandomId } from 'venn-utils';

export const customBlockTypeWithRelativeLabel = (customBlockType?: CustomBlockTypeEnum) => {
  const typesEnabled: CustomBlockTypeEnum[] = ['TIMESERIES', 'CORRELATION', 'DISTRIBUTION', 'ROLLING_CORRELATION'];
  return customBlockType && typesEnabled.includes(customBlockType);
};

export type ApiSubject = {
  id: string;
  subjectType: 'PORTFOLIO' | 'INVESTMENT';
  comparisonType: AnalysisComparisonTypeEnum;
  primary: boolean;
  portfolio: Portfolio | undefined;
  feesMapping: Record<string, number> | undefined;
  portfolioComparisonType: AnalysisPortfolioComparisonTypeEnum | undefined;
  privatePortfolio: PrivatePortfolioNode | undefined;
  isPrivate: boolean;
};

export const convertStudioSubjectToApiSubject = (
  subject: StudioRequestSubject,
  comparisonType: AnalysisComparisonTypeEnum,
  primary: boolean,
): ApiSubject => {
  const isInvestmentSelectedInPortfolio = !isNil(subject.strategy?.fund);
  return {
    ...(isInvestmentSelectedInPortfolio
      ? {
          id: subject.strategy!.fund!.id,
          subjectType: 'INVESTMENT',
          portfolio: undefined,
          isPrivate: subject.private,
          privatePortfolio: undefined,
        }
      : {
          id: String(subject.strategy?.id ?? subject.id),
          subjectType: subject.subjectType,
          portfolio: subject.strategy ?? subject.modifiedPortfolio,
          privatePortfolio: subject.private ? subject.modifiedPrivatePortfolio ?? subject.privatePortfolio : undefined,
          isPrivate: subject.private,
        }),
    comparisonType,
    primary,
    feesMapping: subject.feesMapping,
    portfolioComparisonType: subject.portfolioComparisonType,
  };
};

export const convertApiSubjectToStudioSubject = (apiSubject: ApiSubject): Subject => {
  return apiSubject.subjectType !== 'PORTFOLIO'
    ? { fundId: apiSubject.id }
    : { portfolioId: apiSubject.portfolio?.id ?? Number(apiSubject.id) };
};

export const convertStudioSubjectToHoldingsSubject = (subject: StudioRequestSubject): HoldingsSubject => {
  const isInvestment = subject.subjectType === 'INVESTMENT';
  if (isInvestment) {
    return {
      portfolio: undefined,
      investment: subject.id,
    };
  }

  // Portfolios are tricky: subjectType alone is not enough to determine if a Subject should be treated as a Portfolio or Investment.
  // Portfolios can have a strategy selected and that strategy can be either a portfolio or a single fund.
  // If the strategy is a single fund, then the subject isn't representing a portfolio anymore, but just that single selected fund!
  const selectedFundStrategy = subject.strategy?.fund?.id;
  if (selectedFundStrategy) {
    return {
      portfolio: undefined,
      investment: selectedFundStrategy,
    };
  }

  return {
    portfolio: subject.strategy ?? subject.portfolio,
    investment: undefined,
  };
};

/**
 * Gets the string id of the subject depending on which type of subject the subject is.
 * Does not take into account portfolio version.
 * @param subject the subject to get the id of.
 */
export const getSubjectId = (subject: Subject): string =>
  subject.privateFundId ?? subject.privatePortfolioId ?? subject.fundId ?? assertNotNil(subject.portfolioId).toString();

/**
 * Converts a subject with an optional fees mapping to a subject with a filled in fees mapping.
 * Used to make sure that subjects are uniform (particularly if used as recoil keys), across a number of
 * equivalent fee states. Eg, fees mapping being empty and fees mapping being set to 0.
 * @param subject the subject to convert
 */
export const convertToSubjectWithFee = (subject: SubjectWithOptionalFee): SubjectWithFee => ({
  ...subject,
  feesMapping:
    subject.feesMapping === undefined ||
    isEmpty(subject.feesMapping) ||
    Object.values(subject.feesMapping).every((fee) => fee === 0)
      ? { [getSubjectId(subject)]: 0 }
      : subject.feesMapping,
});

export const subjectsAreEqual = (s1: Subject | undefined, s2: Subject | undefined): boolean => {
  if (isNil(s1) && isNil(s2)) {
    return true;
  }
  if (s1?.fundId) {
    return s1.fundId === s2?.fundId;
  }
  if (s1?.privateFundId) {
    return s1.privateFundId === s2?.privateFundId;
  }
  if (s1?.privatePortfolioId) {
    return s1.privatePortfolioId === s2?.privatePortfolioId;
  }
  return s1?.portfolioId === s2?.portfolioId && s1?.portfolioVersion === s2?.portfolioVersion;
};

export const subjectsAreEqualInclFees = (
  s1: SubjectWithOptionalFee | undefined,
  s2: SubjectWithOptionalFee | undefined,
): boolean => {
  return (
    subjectsAreEqual(s1, s2) &&
    isEqual(s1 && convertToSubjectWithFee(s1).feesMapping, s2 && convertToSubjectWithFee(s2).feesMapping)
  );
};

export const updateInputIds = (analysisView: Partial<AnalysisView>): Partial<AnalysisView> => {
  const { subjectGroups = [], benchmarkSettings = [], dateRanges = [] } = analysisView;
  const idMapping = [...subjectGroups, ...benchmarkSettings, ...dateRanges].reduce(
    (result, current) => ({ ...result, [current.id]: getRandomId() }),
    {},
  );

  const newSubjectGroups = analysisView.subjectGroups?.map((group) => ({ ...group, id: idMapping[group.id] }));
  const newBenchmarkSettings = analysisView.benchmarkSettings?.map((settings) => ({
    ...settings,
    id: idMapping[settings.id],
  }));
  const newDateRanges = analysisView.dateRanges?.map((range) => ({ ...range, id: idMapping[range.id] }));

  const customizedViews = analysisView.customizedViews?.map((c) => {
    const subjectGroups = c.customizedBlock?.linkedSubjectGroups;
    const benchmarkSettings = c.customizedBlock?.linkedBenchmarkSettings;
    const dateRange = c.customizedBlock?.linkedDateRange;

    return {
      ...c,
      customizedBlock: c.customizedBlock && {
        ...c.customizedBlock,
        linkedSubjectGroups: subjectGroups?.map((group) => idMapping[group]),
        linkedBenchmarkSettings: benchmarkSettings && idMapping[benchmarkSettings],
        linkedDateRange: dateRange && idMapping[dateRange],
      },
    };
  });
  return {
    ...analysisView,
    subjectGroups: newSubjectGroups,
    benchmarkSettings: newBenchmarkSettings,
    dateRanges: newDateRanges,
    customizedViews,
  };
};

/**
 * Get or default a pretty-print name for a private block metric name by looking up then doing startCase
 * @param metricDefinitionsMap the map of all key -> metric definitions a block supports
 * @param metricName the metric name to get a pretty-print name for
 */
export const getPrivateMetricName = (
  metricDefinitionsMap: { [id: string]: CustomizableMetric },
  metricName: string,
): string => {
  return metricDefinitionsMap[metricName]?.label ?? startCase(metricName);
};

export type SubjectsMultiselectProps = {
  subjects: StudioRequestSubject[];
  maxPublicSubjects: number;
  maxPrivateSubjects: number;
};

/**
 * Select public and private subjects, filtering up to allowed maxima
 * Returns all public subjects before all private subjects
 * @param subjects subjects
 * @param maxPublicSubjects
 * @param maxPrivateSubjects
 */
export const subjectsMultiselect = ({ subjects, maxPublicSubjects, maxPrivateSubjects }: SubjectsMultiselectProps) => {
  const [privates, publics] = partition(subjects, (subject) => subject.private);
  return [...publics.slice(0, maxPublicSubjects), ...privates.slice(0, maxPrivateSubjects)];
};
