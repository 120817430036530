import type { ReactNode } from 'react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { DropMenuCheckboxItem } from '../types';
import type { MenuPosition } from './SkeletalDropMenu';
import SkeletalDropMenu from './SkeletalDropMenu';
import ButtonTrigger from '../triggers/ButtonTrigger';
import CheckboxMenu from '../menus/CheckboxMenu';
import MenuActions, { APPLY_BUTTON_CLASSNAME } from './MenuActions';

export interface ButtonFilterDropMenuProps {
  /** The text on the trigger to open/close the filter */
  label: string;
  /** The options for the filter */
  items: DropMenuCheckboxItem[];
  /** Whether only one option at a time can be selected */
  singleSelection?: boolean;
  /** Class name to pass through to the drop menu component */
  className?: string;
  /** Class name to pass through to the drop menu trigger */
  triggerClassName?: string;
  /**
   * The function to call with the updated selection.
   * string type (as opposed to string[]) is passed in only if it's a single selection
   */
  onFilter: (updated: string | string[]) => void;
  /** Whether the drop menu should close when the ONLY option is clicked. Default is true */
  closeOnOnlyClick?: boolean;
  /** Width for the menu */
  menuWidth?: number;
  /** Height of the menu */
  menuHeight?: number;
  /** Indicate to take full width of parent */
  fullWidth?: boolean;
  /** Custom text for the Apply button */
  applyLabel?: string;
  /** Additional header for the menu component */
  header?: ReactNode;
  /** Default is center */
  menuPosition?: MenuPosition;
  /** Filter options if provided */
  textFilter?: string;
  /** Disable the button */
  disabled?: boolean;
  /** Set different tracking type other than "button filter" */
  analyticsType?: string;
  /** If specificy, enable auto scroll */
  autoScrollParentClassName?: string;
}

/**
 * Component for a button filter, which includes a trigger button and a dropdown menu
 */
const ButtonFilterDropMenu = ({
  label,
  items,
  className,
  triggerClassName,
  onFilter,
  singleSelection,
  closeOnOnlyClick,
  menuWidth,
  fullWidth,
  menuHeight,
  applyLabel,
  header,
  menuPosition = 'center',
  textFilter,
  disabled,
  analyticsType,
  autoScrollParentClassName,
}: ButtonFilterDropMenuProps) => {
  const analyticsProps = {
    label,
    selectType: singleSelection ? 'single-select' : 'multiselect',
    type: analyticsType ?? 'button filter',
  };

  const [localItems, setLocalItems] = useState<DropMenuCheckboxItem[]>(items);

  useEffect(() => {
    setLocalItems(items);
  }, [items]);

  const applyChanges = useCallback(() => {
    const checked = localItems.filter((item) => item.checked).map((item) => item.value);
    onFilter(singleSelection ? checked[0] ?? '' : checked);
  }, [onFilter, localItems, singleSelection]);

  const shouldApply = useRef(false);
  useEffect(() => {
    if (shouldApply.current) {
      shouldApply.current = false;
      applyChanges();
    }
  }, [applyChanges]);

  const resetLocalState = useCallback(() => {
    setLocalItems(items);
  }, [items]);

  const onChange = useCallback((items: DropMenuCheckboxItem[]) => {
    setLocalItems((localItems) => localItems.map((s) => items.find((i) => i.value === s.value) ?? s));
  }, []);

  const filterItems = useMemo(
    () =>
      textFilter
        ? localItems.filter((s) => s.label.toLocaleLowerCase().includes(textFilter.toLowerCase()))
        : localItems,
    [localItems, textFilter],
  );

  return (
    <SkeletalDropMenu
      menuPosition={menuPosition}
      analyticsProps={analyticsProps}
      onExternalActivity={resetLocalState}
      className={className}
      triggerComponent={(expanded, toggleMenu) => (
        <ButtonTrigger
          label={label}
          isOpen={expanded}
          onClick={toggleMenu}
          aria-expanded={expanded}
          aria-haspopup
          className={triggerClassName}
          disabled={disabled}
        />
      )}
      menuComponent={(applyStateAndClose, onClose) => (
        <>
          {header}
          <CheckboxMenu
            singleSelection={singleSelection}
            width={menuWidth}
            fullWidth={fullWidth}
            height={menuHeight ?? 510}
            items={filterItems}
            onChange={onChange}
            onCollapse={() => {
              if (singleSelection || closeOnOnlyClick) {
                shouldApply.current = true;
                onClose();
              }
            }}
            footerComponent={
              singleSelection ? undefined : (
                <MenuActions
                  onCancel={() => {
                    resetLocalState();
                    onClose();
                  }}
                  onApply={applyStateAndClose}
                  applyLabel={applyLabel}
                />
              )
            }
          />
        </>
      )}
      onApply={applyChanges}
      autoScrollSelector={autoScrollParentClassName ? `.${APPLY_BUTTON_CLASSNAME}` : undefined}
      autoScrollParentSelector={`.${autoScrollParentClassName}`}
    />
  );
};

export default ButtonFilterDropMenu;
