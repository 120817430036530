import React, { useCallback, useContext, useMemo } from 'react';
import styled, { ThemeContext } from 'styled-components';
import type { DropMenuItem } from 'venn-ui-kit';
import { Icon, GetColor, BaseDropMenu, SimpleMenu, ZIndex, Notifications, NotificationType } from 'venn-ui-kit';
import { isNil, partial, compact, flatten } from 'lodash';
import { flattenNode, useHasFF, useModal } from 'venn-utils';
import type { Portfolio } from 'venn-api';
import EditCapitalModal from './edit-capital/EditCapitalModal';
import CreatePortfolioModal from '../modals/create-portfolio/CreatePortfolioModal';
import { UserContext } from '../contexts';
import ImportStrategyModal from './strategy/ImportStrategyModal';

const separatorClassName = 'menuOption-separator';
type MenuActionOption = DropMenuItem<(() => void) | undefined>;

interface AllocationStrategyActionsProps {
  isRoot: boolean;
  onAddStrategy: (strategy?: Portfolio) => void;
  onAddInvestment: () => void;
  onEditName: () => void;
  onDelete: () => void;
  onCompare?: () => void;
  compareLink?: string;
  strategy: Portfolio;
  onUpdateChildrenAllocations: (updatedStrategy: Portfolio) => void;
  isPercentageMode: boolean;
  rootName: string;
}

const AllocationStrategyActions = ({
  isRoot,
  onAddStrategy,
  onAddInvestment,
  onEditName,
  onDelete,
  onCompare,
  compareLink,
  strategy,
  onUpdateChildrenAllocations,
  isPercentageMode,
  rootName,
}: AllocationStrategyActionsProps) => {
  const hasImportExportStrategiesFF = useHasFF('import_export_strategies_ff');
  const { Colors } = useContext(ThemeContext);
  const [isEditCapitalModalOpen, openEditCapitalModal, closeEditCapitalModal] = useModal();
  const hasFundsInSubtree = useMemo(
    () => flattenNode(strategy).filter((child) => !isNil(child.fund)).length > 0,
    [strategy],
  );

  const { hasPermission } = useContext(UserContext);
  const [isExportStrategyModalOpen, openExportStrategyModal, closeExportStrategyModal] = useModal();
  const onStrategySavedAsPortfolio = useCallback(
    (newPortfolio: Portfolio) => {
      closeExportStrategyModal();
      Notifications.notify(
        `Successfully saved '${strategy?.name}' strategy as a new portfolio named '${newPortfolio.name}'.`,
        NotificationType.SUCCESS,
      );
    },
    [closeExportStrategyModal, strategy?.name],
  );

  const [isImportStrategyModalOpen, openImportStrategyModal, closeImportStrategyModal] = useModal();

  const options: MenuActionOption[] = flatten(
    [
      [
        {
          label: isRoot ? 'Add a Strategy' : 'Add a Sub-strategy',
          value: onAddStrategy,
        },
      ],
      [
        {
          label: 'Add an investment',
          value: onAddInvestment,
        },
      ],
      compact([
        !isRoot && {
          label: 'Edit Name',
          value: onEditName,
        },
        !isRoot && {
          label: 'Delete',
          value: onDelete,
        },
        {
          label: 'Edit Capital',
          value: openEditCapitalModal,
          disabled: !hasFundsInSubtree || isPercentageMode,
        },
      ]),
      compact([
        hasImportExportStrategiesFF &&
          !isRoot &&
          hasPermission('CREATE_PORTFOLIO') && {
            label: 'Save as a portfolio',
            value: openExportStrategyModal,
            disabled: isPercentageMode,
          },
        hasImportExportStrategiesFF && {
          label: `Import portfolio as a ${isRoot ? 'strategy' : 'sub-strategy'}`,
          value: openImportStrategyModal,
        },
      ]),
      [
        {
          icon: (
            <Icon
              type="line-columns"
              prefix="fad"
              style={{ color: isNil(onCompare) ? Colors.Grey : Colors.Primary.Dark, marginRight: 8 }}
            />
          ),
          label: 'Compare all investments',
          value: onCompare,
          disabled: isNil(onCompare),
          link: isNil(onCompare) ? undefined : compareLink,
        },
      ],
    ]
      .filter((section: MenuActionOption[]) => section.length > 0)
      .map((section: MenuActionOption[]) => [
        ...section.slice(0, section.length - 1),
        { ...section[section.length - 1], className: separatorClassName },
      ]),
  );

  return (
    <>
      <StyledBaseDropMenu
        style={{ position: 'absolute' }}
        className="drop-menu"
        usePortal
        filteredItems={options}
        triggerComponent={(_, _highlighted, onToggle) => (
          <ActionsButton onClick={partial(onToggle, undefined)} className="qa-strategy-actions">
            <GreyIcon type="ellipsis-h" />
          </ActionsButton>
        )}
        menuComponent={(_, onCollapse, menuClassName) => (
          <SimpleMenu
            className={menuClassName}
            items={options}
            selected={null}
            onChange={(item) => {
              onCollapse();
              item?.value?.();
            }}
          />
        )}
      />
      {isEditCapitalModalOpen && (
        <EditCapitalModal
          isRoot={isRoot}
          strategy={strategy}
          onClose={closeEditCapitalModal}
          onSubmit={onUpdateChildrenAllocations}
        />
      )}
      {isExportStrategyModalOpen && (
        <CreatePortfolioModal
          isCreatingFromStrategy
          defaultName={`${rootName}: ${strategy.name}`}
          onClose={closeExportStrategyModal}
          onSubmit={onStrategySavedAsPortfolio}
          baseline={strategy}
          disableBaselineSelection
          hideBasePortfolio
          canCreateFromScratch={false}
          createDraft={false}
        />
      )}
      {isImportStrategyModalOpen && (
        <ImportStrategyModal isAddingToRoot={isRoot} onSubmit={onAddStrategy} onClose={closeImportStrategyModal} />
      )}
    </>
  );
};

export default AllocationStrategyActions;

export const ActionsButton = styled.button`
  width: 100%;
  cursor: pointer;
  i {
    line-height: 30px;
  }
`;

export const GreyIcon = styled(Icon)`
  color: ${GetColor.HintGrey};
  &:hover {
    color: ${GetColor.Primary.Main};
  }
`;

export const StyledBaseDropMenu = styled(BaseDropMenu)`
  &.drop-menu {
    width: 232px;
    > div {
      max-height: 322px;
    }
    > div > div {
      min-width: 210px;
      > div {
        padding-top: 0px;
        padding-bottom: 0px;
      }
    }
    z-index: ${ZIndex.StickyCover};
    position: relative;
  }
  .${separatorClassName}:not(:last-child) {
    border-bottom: 1px solid ${GetColor.Grey};
  }
`;
