import styled, { css } from 'styled-components';
import { ButtonIcon, GetColor } from 'venn-ui-kit';

export const StyledButtonIcon = styled(ButtonIcon).attrs(() => ({
  size: 30,
}))<{ warningColor?: boolean; disabled?: boolean }>`
  display: flex;
  margin-right: 6px;

  ${(props) =>
    props.warningColor &&
    !props.disabled &&
    css`
      color: ${GetColor.Error};
    `}
`;

export const ErrorMessageContainer = styled.div`
  position: absolute;
  height: 0;
`;

export const ErrorMessagePositioning = styled.div<{ widthPx?: number }>`
  position: absolute;
  & > div > div {
    top: 0;
    position: relative;
    left: 175px;
  }
  bottom: 10px;
  width: ${({ widthPx }) => widthPx ?? 250}px;
  pointer-events: none;
`;
