import tinycolor from 'tinycolor2';
import type { Theme } from './themes';
import { round } from 'lodash';

const hex2rgba = function (color: string, alpha?: number) {
  if (!tinycolor(color).isValid()) {
    throw new Error(`Invalid color format: ${color}`);
  }

  const rgba = tinycolor(color).toRgb();
  const { r, g, b } = rgba;

  if (alpha !== undefined) {
    return `rgba(${r}, ${g}, ${b}, ${Math.min(Math.max(alpha, 0), 1)})`;
  }
  return `rgb(${r}, ${g}, ${b})`;
};

/**
 * Converts an RGB colour to another lighter RGB colour, given an opacity,
 * assuming we have a white background (i.e. does not set an Alpha channel)
 * @param color The base colour as an RGB Hex string
 * @param opacity Desired opacity, between 0 and 1
 */
const opacify = function (color: string, opacity: number) {
  // equivalent to tinycolor.mix(color, '#FFFFFF', 50).toHexString()
  const rgba = tinycolor(color).toRgb();
  const convertChannel = (channel: number): number => channel * opacity + (1 - opacity) * 255;
  return tinycolor({
    r: convertChannel(rgba.r),
    g: convertChannel(rgba.g),
    b: convertChannel(rgba.b),
    a: 1,
  }).toHexString();
};

/**
 * Converts an RGB colour to another darker RGB colour, given an opacity,
 * assuming we have a black background (i.e. does not set an Alpha channel)
 * @param color The base colour as an RGB Hex string
 * @param opacity Desired opacity of the black background, between 0 and 1
 */
const opacifyDark = function (color: string, opacity: number) {
  // equivalent to tinycolor.mix(color, '#000000', 50).toHexString()
  const rgba = tinycolor(color).toRgb();
  const convertChannel = (channel: number): number => channel * (1 + opacity) - opacity * 255;
  return tinycolor({
    r: convertChannel(rgba.r),
    g: convertChannel(rgba.g),
    b: convertChannel(rgba.b),
    a: 1,
  }).toHexString();
};

export const ColorUtils = {
  hex2rgba,

  opacify,

  opacifyDark,

  hex2rgbaFrom: (colorSelector: (props: { theme: Theme }) => string, alpha?: number) => (props: { theme: Theme }) => {
    const color = colorSelector(props);
    return hex2rgba(color, alpha);
  },

  opacifyFrom: (colorSelector: (props: { theme: Theme }) => string, opacity: number) => (props: { theme: Theme }) => {
    const color = colorSelector(props);
    return opacify(color, opacity);
  },

  opacifyDarkFrom:
    (colorSelector: (props: { theme: Theme }) => string, opacity: number) => (props: { theme: Theme }) => {
      const color = colorSelector(props);
      return opacifyDark(color, opacity);
    },
};

/**
 * A DOM node created once, globally, used for testing if a color is a valid CSS color.
 * This allows us to reuse the same dom node rather than creating new ones constantly during rendering.
 * The state of the node must be reset after a test, so that future tests aren't affected.
 */
const colorTestNode = new Option().style;
/** Returns true if the provided string represents a valid CSS color (such as #00F) or CSS color keyword (such as 'initial', 'inherit', 'unset', or 'red'). */
export const isColor = (color: string | undefined | null): color is string => {
  if (!color) {
    return false;
  }

  colorTestNode.color = color;
  const isValidColor = colorTestNode.color !== '';

  // Reset the node's color value for the next test
  colorTestNode.color = '';

  return isValidColor;
};

type RGB = [red: number, green: number, blue: number];

/**
 * Converts a hexadecimal color string in to an RGB array of decimals
 */
const convertHexToRgb = (hex: string): RGB | null => {
  // TODO: support shortform hex
  const hexToRgbRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
  const result = hexToRgbRegex.exec(hex);
  return result && [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)];
};

const componentToHex = (colorComponent: number) => {
  const hex = colorComponent.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
};

/**
 * Convert an array of length 3 [R, G, B] to its hexadecimal representation (#F0ABDD)
 */
const convertRgbToHex = (color: RGB) => `#${color.map(componentToHex).join('')}`;

const mixColorComponents = (a: number, b: number, ratio: number) => a + round((b - a) * ratio);

// TODO(VENN-25616) move this to tiny color
export const mixColors = (aHex: string, bHex: string, ratio: number) => {
  const aRgb = convertHexToRgb(aHex);
  const bRgb = convertHexToRgb(bHex);
  const mixRgb: RGB =
    aRgb && bRgb ? (aRgb.map((a, idx) => mixColorComponents(a, bRgb?.[idx], ratio)) as RGB) : [0, 0, 0];
  return convertRgbToHex(mixRgb);
};
