import styled from 'styled-components';
import { Label } from 'venn-ui-kit';

export const StyledLabel = styled(Label)`
  display: inline-block;
  margin-bottom: 5px;
`;

export const InputRow = styled.div`
  margin-top: 20px;
`;
