import type { FC } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { GetColor, Icon, ZIndex } from 'venn-ui-kit';
import Modal from './Modal';
import { isNil } from 'lodash';

interface VideoModalProps {
  onClose: () => void;
  trackHeartbeat: (timeElapsed?: number, timeTotal?: number) => void;
}

const VideoModal: FC<React.PropsWithChildren<VideoModalProps>> = ({ children, onClose, trackHeartbeat }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoHeartbeatRef = useRef<number | undefined>(undefined);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (!isPlaying) {
      if (!isNil(videoHeartbeatRef.current)) {
        clearInterval(videoHeartbeatRef.current);
        videoHeartbeatRef.current = undefined;
      }
      return;
    }
    trackHeartbeat(videoRef.current?.currentTime, videoRef.current?.duration);
    if (isNil(videoHeartbeatRef.current)) {
      videoHeartbeatRef.current = window.setInterval(trackHeartbeat, 5000);
    }
  }, [isPlaying, trackHeartbeat]);

  useEffect(() => {
    return () => window.clearInterval(videoHeartbeatRef.current);
  }, []);

  const onCloseClick = () => {
    setIsPlaying(false);
    onClose();
  };

  return (
    <Modal size={1214} inverted closeOnEsc onClose={onCloseClick} noPadding>
      <Xout type="button" onClick={onCloseClick}>
        <Icon type="times" prefix="fal" />
      </Xout>
      <Video
        ref={(ref) => (videoRef.current = ref)}
        controls
        autoPlay
        onPlaying={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
        onEnded={() => setIsPlaying(false)}
      >
        {children}
      </Video>
    </Modal>
  );
};

export default VideoModal;

const Video = styled.video`
  height: 684px;
  width: 1212px;
`;

const Xout = styled.button`
  position: absolute;
  right: 16px;
  top: 20px;
  width: 24px;
  height: 24px;
  z-index: ${ZIndex.ModalFront};
  i {
    font-size: 24px;
    color: ${GetColor.Primary.Main};
  }
`;
