import type { SerializableParam } from 'recoil';
import type { AnyObject } from 'venn-utils';
import { logExceptionIntoSentry } from '../error-logging';

/**
 * Typecheck that the `value` type is assignable to the generic `Type`.
 * Does not perform any runtime checks; at runtime this is a no-op.
 *
 * Useful in tests to check the types of things are or are not expected.
 *
 * ```ts
 * expectType<number>(123);
 * expectType<boolean>(true);
 *
 * //@ts-expect-error 123 is not a boolean
 * expectType<boolean>(123);
 * ```
 */
export const expectType = <Type>(_: Type) => {};

/** Given a type T and a union of keys, it returns a type where the specified keys are optional in the provided type.
 * https://stackoverflow.com/a/54178819
 * */
export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

/** map values of interface recursively to given type */
export type DeepMapTo<T, N> = T extends string | number | boolean
  ? N
  : { [P in keyof T]: T[P] extends (infer U)[] ? DeepMapTo<U, N>[] : DeepMapTo<T[P], N> };

/**
 * Compile-time and runtime type safety check to help ensure a type union has been exhaustively handled.
 * This function throws an error if is reached at runtime.
 *
 * @see {@link logExhaustive} for a function which only logs to sentry at runtime
 *
 * @see {@link https://www.typescriptlang.org/docs/handbook/unions-and-intersections.html#union-exhaustiveness-checking| TS Handbook}
 *
 * @example
 * type Fruit = 'apple'|'kiwi'|'orange';
 * let froo: Fruit = ...;
 * if (froo === 'apple') { ... }
 * else if (froo === 'kiwi') { ... }
 * else assertExhaustive(froo); // Fails to compile because 'orange' is unhandled. Throws an error at runtime.
 */
export function assertExhaustive(_param: never, message?: string): never {
  throw new Error(`Expected never but received ${_param}. ${message}`);
}

/**
 * Compile-time and runtime type safety check to help ensure a type union has been exhaustively handled.
 * This function logs to sentry if it is reached at runtime.
 *
 * @see {@link assertExhaustive} for a function which will throw an error at runtime
 *
 * @see {@link https://www.typescriptlang.org/docs/handbook/unions-and-intersections.html#union-exhaustiveness-checking| TS Handbook}
 *
 * @example
 * type Fruit = 'apple'|'kiwi'|'orange';
 * let froo: Fruit = ...;
 * if (froo === 'apple') { ... }
 * else if (froo === 'kiwi') { ... }
 * else logExhaustive(froo); // Fails to compile because 'orange' is unhandled. Logs to sentry at runtime.
 */
export function logExhaustive(_param: never, message?: string) {
  const error = new Error(`Expected never but received ${_param}. ${message}`);
  logExceptionIntoSentry(error);
  return error;
}

/**
 * The Recoil types for `selectorFamily` have a bug in which they do not allow interfaces to be passed in as a parameter.
 * This utility type deeply re-maps an interface to a standard type.
 * @see https://github.com/facebookexperimental/Recoil/issues/629#issuecomment-797000984
 * @see https://www.typescriptlang.org/docs/handbook/2/mapped-types.html
 * @see https://www.typescriptlang.org/docs/handbook/2/conditional-types.html
 */
export type CreateSerializableParam<Type> = {
  // We construct a new mapped type in which the keys are the keys of the original type and the values are
  // converted from interface types into normal types (which appears to happen implicitly when using mapped types).
  //
  // When the type of a property is already a `SerializableParam` we return it as-is.
  // But if it is not, we should check to see if it it is a record/object or undefined and then recursively map it.
  // Finally, if it matches neither of these conditions we should return it as-is.
  [Property in keyof Type]: Type[Property] extends SerializableParam
    ? Type[Property]
    : Type[Property] extends AnyObject | undefined | null
      ? CreateSerializableParam<Type[Property]>
      : Type[Property];
};

/** Perform an omit distributed over a union of objects. Regular omit will smash the union into a single types, while DistributiveOmit retains the union. */
export type DistributiveOmit<T, K extends keyof T> = T extends unknown ? Omit<T, K> : never;

/** Checks that the second type param satisfies the first type param. */
export type Satisfies<T, U extends T> = U extends T ? U : never;
