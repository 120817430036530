import React, { useEffect } from 'react';
import { Headline1, Subtitle1, Loading, LoadingSize, GetColor } from 'venn-ui-kit';
import styled from 'styled-components';
import { SaveShareTopBar } from 'venn-components';
import moment from 'moment';

interface HeaderProps {
  compressed?: boolean;
  onXlsxDownload: () => void;
  downloadDisabled: boolean;
  /** Indicate that we are using cached data but fetching new data in background */
  showCachedLoader?: boolean;
  analysisUpdatedTime?: number;
  // save view
  subjectsIsEmpty: boolean;
  savedId?: string;
  currentViewName?: string;
  hasUnsavedChanges?: boolean;
  onRename?: (name: string) => void;
  onSaveAs?: (name: string, ownerContextId?: string) => void;
  onSave?: (name: string) => Promise<string | undefined>;
  noAccessModifiedView?: boolean;
}

const Header: React.FC<React.PropsWithChildren<HeaderProps>> = ({
  onXlsxDownload,
  downloadDisabled,
  compressed,
  analysisUpdatedTime,
  showCachedLoader,
  subjectsIsEmpty,
  savedId,
  currentViewName,
  hasUnsavedChanges,
  onRename,
  onSave,
  onSaveAs,
  noAccessModifiedView,
}) => {
  useEffect(() => {
    const scrollContainer = document.getElementById('comparison-main');
    if (scrollContainer) {
      scrollContainer.scrollTop = 0;
    }
  }, []);

  return (
    <>
      {onSave && onSaveAs && onRename && (
        <TopBar>
          <SaveShareTopBar
            disabled={downloadDisabled}
            active
            hasUnsavedChanges={!!hasUnsavedChanges || !!noAccessModifiedView}
            hasSavedBaseline={!!savedId && !noAccessModifiedView}
            currentViewName={currentViewName}
            loading={false}
            savedId={savedId}
            // @ts-expect-error: TODO fix strictFunctionTypes
            onSave={onSave}
            onSaveAs={onSaveAs}
            onRename={onRename}
            isCompare
            isEmpty={subjectsIsEmpty}
            emptyName="Compare"
            viewTypeName="Comparison"
            shareMetaData={{ comparison: true }}
            downloadXlsx={downloadDisabled ? undefined : onXlsxDownload}
            noAccessModifiedView={noAccessModifiedView}
          />
        </TopBar>
      )}
      <Wrapper compressed={compressed}>
        <Title compressed={compressed}>
          <StyledHeadline1 compressed={compressed}>Compare</StyledHeadline1>
          {!!analysisUpdatedTime && (
            <Subtitle1>
              <FetchDataMessage>
                Data last updated: {moment(analysisUpdatedTime).format('YYYY-MM-DD hh:mm A')}.
                {showCachedLoader && (
                  <FetchDataMessage>
                    <FetchingMessage>Currently fetching new data </FetchingMessage>
                    <Loading title="" size={LoadingSize.micro} />
                  </FetchDataMessage>
                )}
              </FetchDataMessage>
            </Subtitle1>
          )}
        </Title>
      </Wrapper>
    </>
  );
};

const StyledHeadline1 = styled(Headline1)<{ compressed?: boolean }>`
  ${({ compressed }) =>
    compressed
      ? `
  font-size: 32px;
  margin: 0;
  `
      : ''}
  transition: 0.5s;
`;

const Wrapper = styled.div<{ compressed?: boolean }>`
  padding-top: ${({ compressed }) => (compressed ? '10px;' : '20px')};
  padding-bottom: 0;
  display: flex;
  justify-content: space-between;
  transition: 0.5s;
`;

const FetchDataMessage = styled.div`
  display: flex;
  line-height: 35px;
`;

const Title = styled.div<{ compressed?: boolean }>`
  height: ${({ compressed }) => (compressed ? '70px;' : '99px')};
`;

const FetchingMessage = styled.span`
  color: ${GetColor.HighlightDark};
  margin-left: 5px;
  margin-right: 5px;
`;

const TopBar = styled.div`
  position: relative;
  margin: 0 -60px;
  width: calc(100% + 120px);
  > div {
    height: 40px;
  }
`;
export default Header;
