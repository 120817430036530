import React from 'react';
import { Icon } from 'venn-ui-kit';
import { Content, SubHeader } from '../account-modals/shared';
import BackupCodes from './BackupCodes';

interface BackupCodesModalProps {
  onClose: () => void;
}

export const BackupCodesModal = ({ onClose }: BackupCodesModalProps) => (
  <>
    <Content>
      <SubHeader>
        <Icon type="check-circle" />
        &nbsp;One-time backup codes
      </SubHeader>
    </Content>
    <BackupCodes onClose={onClose} />
  </>
);
