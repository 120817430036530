import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import type { Images } from 'venn-ui-kit';
import { Body1, getAppTitle } from 'venn-ui-kit';
import { InfoContainer, InfoHeading, PreviewArea } from './styles';

const appTitle = getAppTitle();
const info = [
  {
    key: 'prefix',
    header: 'Portfolio name prefix(es)',
    content: 'These will prefix the view(s) that you set up and are input manually as part of the set-up process.',
    height: 149,
    getPreview: (images: Images) => images.addeparStep2PortfolioPrefix,
  },
  {
    key: 'portfolio-names',
    header: 'Portfolio names',
    content:
      'These are taken from the highest-level grouping within Addepar. You can change the order of these groupings in the next step.',
    height: 332,
    getPreview: (images: Images) => images.addeparStep2PortfolioName,
  },
  {
    key: 'investment-names',
    header: 'Investment names',
    content: `All Addepar groupings below the top level are interpreted by ${appTitle} as part of the hierarchical portfolio structure.`,
    height: 279,
    getPreview: (images: Images) => images.addeparStep2InvestmentName,
  },
];

const AddeparStep2 = () => {
  const { Images } = useContext(ThemeContext);
  return (
    <Container>
      {info.map(({ key, header, content, height, getPreview }) => (
        <OverviewItem key={key} height={height}>
          <StyledPreviewArea>
            <img src={getPreview(Images)} alt={header} />
          </StyledPreviewArea>
          <InfoContainer>
            <InfoHeading>{header}</InfoHeading>
            <Body1>{content}</Body1>
          </InfoContainer>
        </OverviewItem>
      ))}
    </Container>
  );
};

export default AddeparStep2;

const StyledPreviewArea = styled(PreviewArea)`
  padding: 18px 24px;
`;

const OverviewItem = styled.div<{ height: number }>`
  display: flex;
  flex-direction: row;
  height: ${({ height }) => height}px;
  & > :not(:last-child) {
    margin-bottom: 24px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;
