import React, { useMemo } from 'react';
import type { DropMenuCheckboxItem, ButtonFilterDropMenuProps } from 'venn-ui-kit';
import { ButtonFilterDropMenu } from 'venn-ui-kit';
import { compact } from 'lodash';
import type { LibraryFilterOption } from './types';

interface SimpleFilterProps
  extends Omit<ButtonFilterDropMenuProps, 'items' | 'analyticsType' | 'triggerClassName' | 'menuPosition'> {
  /** The options for the filter */
  items: LibraryFilterOption<string>[];
  /** The initially selected items */
  initialSelected: string[];
  isAdvanced?: boolean;
}

/**
 * Component for a simple library filter, which includes a trigger button and a dropdown menu
 */
const SimpleFilter = ({ items, initialSelected, isAdvanced, ...buttonFilterProps }: SimpleFilterProps) => {
  const advanced = !!isAdvanced;

  const dropmenuItems = useMemo(
    () => toDropMenuCheckboxItems(items, initialSelected, 0, advanced),
    [items, initialSelected, advanced],
  );
  return (
    <ButtonFilterDropMenu
      triggerClassName="qa-library-filter-trigger"
      analyticsType="library filter"
      menuPosition={advanced ? 'right' : 'center'}
      items={dropmenuItems}
      {...buttonFilterProps}
    />
  );
};

export const toDropMenuCheckboxItems = (
  items: LibraryFilterOption<string>[],
  filterValues: string[],
  level: number,
  advanced: boolean,
): DropMenuCheckboxItem<string>[] => {
  return compact(
    items.flatMap((item) => {
      return [
        (!item.advancedOnly || advanced) && {
          icon: item.icon!,
          badge: item.badge!,
          label: item.label,
          value: item.value,
          checked: filterValues.includes(item.value),
          level,
          tooltipContent: item.tooltip,
          disabled: item.disabled,
          className: item.className,
          alwaysShowTooltip: !!item.tooltip,
        },
        ...(item.subItems ? toDropMenuCheckboxItems(item.subItems, filterValues, level + 1, advanced) : []),
      ];
    }),
  );
};

export default SimpleFilter;
