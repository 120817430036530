import { selectorFamily } from 'recoil';
import type { BlockId } from '../studio';
import { blockLoadingState } from '../studio';
import { recoilBlockMaxSize, recoilBlockContentSize } from './blockSize';

export const blockContentSizeExceededState = selectorFamily<{ width: boolean; height: boolean }, BlockId>({
  key: 'blockContentSizeExceeded',
  get:
    (id) =>
    ({ get }) => {
      const size = get(recoilBlockContentSize.rawState(id));
      const maxSize = get(recoilBlockMaxSize.rawState(id));
      const loading = get(blockLoadingState(id));
      if (!size || !maxSize || loading) {
        return { width: false, height: false };
      }
      if (
        Number.isNaN(size.height) ||
        Number.isNaN(size.width) ||
        Number.isNaN(maxSize.height) ||
        Number.isNaN(maxSize.width)
      ) {
        return { width: false, height: false };
      }
      // ag-grid appears to floor / collapse to integer all of its measurements.
      // Flooring css figures for consistent provides for a more stable behaviour.
      return {
        width: Math.floor(size.width) > Math.ceil(maxSize.width),
        height: Math.floor(size.height) > Math.ceil(maxSize.height),
      };
    },
});
