import { SectionKey } from '../types';
import { LABELS } from '../constants';
import type { ItemType } from 'venn-ui-kit';
import { getItemLabel } from 'venn-ui-kit';
import type { SavedViewsQuickFilter } from 'venn-utils';

interface FilterPillProps {
  key: string;
  label: string;
  value: string;
  icon: string;

  onClick(): void;
}

type GetFilterPills = (props: {
  quickFilters: SavedViewsQuickFilter[];
  itemTypes: ItemType[];
  toggleParamItem: (key: SectionKey, item: SavedViewsQuickFilter | ItemType) => void;
}) => FilterPillProps[];

const getBasePill = (value: ItemType | SavedViewsQuickFilter) => ({
  label: LABELS[value] ?? getItemLabel(value as ItemType),
  value,
  icon: 'filter',
  key: `itemType-${value}`,
});

export const getFilterPills: GetFilterPills = ({ quickFilters, itemTypes, toggleParamItem }) => [
  ...itemTypes.map((itemType) => ({
    ...getBasePill(itemType),
    onClick: () => toggleParamItem(SectionKey.ITEM_TYPE, itemType),
  })),
  ...quickFilters.map((quickFilter) => ({
    ...getBasePill(quickFilter),
    onClick: () => toggleParamItem(SectionKey.FILTERS, quickFilter),
  })),
];
