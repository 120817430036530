import { useEffect } from 'react';
import type { DateRange } from 'venn-ui-kit';
import type { SubjectId } from './types';
import { compact } from 'lodash';
import type { AnalysisSubject } from '../analysis';
import userActivity from '../user-activity';

const SAVED_COMPARISON_KEY = 'recent_comparison_subjects';

interface LocalComparison {
  subjects?: SubjectId[];
  benchmark?: SubjectId;
  dateRange?: DateRange;
  relative?: boolean;
  viewId?: string;
  isPivoted?: boolean;
}

export const saveComparionsToLocal = (localComparison: LocalComparison) => {
  userActivity.setLocalStorageItemPerUser(SAVED_COMPARISON_KEY, JSON.stringify(localComparison));
};

/**
 * Hook to save the subjects of a comparison. These can be later retrieved by utility functions
 * @param subjects
 * @param dateRange
 * @param benchmark
 */
const useSavedComparisons = (
  subjects: (AnalysisSubject | undefined)[],
  dateRange: DateRange,
  benchmark?: AnalysisSubject,
  relative?: boolean,
  viewId?: string,
  isPivoted?: boolean,
) => {
  useEffect(() => {
    if (subjects.some(Boolean)) {
      saveComparionsToLocal({
        subjects: compact(subjects.map((s) => s?.id)),
        benchmark: benchmark?.id,
        dateRange,
        viewId,
        relative,
        isPivoted,
      });
    }
  }, [subjects, dateRange, benchmark, viewId, relative, isPivoted]);
};

export default useSavedComparisons;

export const getRecentComparison = (): LocalComparison => {
  const raw = userActivity.getLocalStorageItemPerUser(SAVED_COMPARISON_KEY);
  const parsed = raw ? JSON.parse(raw) : {};
  return {
    subjects: parsed.subjects ?? [],
    benchmark: parsed.benchmark,
    dateRange: parsed.dateRange,
    relative: parsed.relative,
    viewId: parsed.viewId,
    isPivoted: parsed.isPivoted,
  };
};

export const getRecentComparisonIds = (): SubjectId[] => {
  return compact(getRecentComparison().subjects);
};

export const getRecentDateRange = (): DateRange => {
  return getRecentComparison().dateRange || {};
};

export const getRecentBenchmarkId = (): SubjectId | undefined => {
  return getRecentComparison().benchmark ?? undefined;
};

export const getRecentRelative = (): boolean | undefined => {
  return getRecentComparison().relative ?? undefined;
};

export const getRecentViewId = (): string | undefined => {
  return getRecentComparison().viewId ?? undefined;
};

export const getRecentPivoted = (): boolean => {
  // Default to make isPivot to true
  return getRecentComparison().isPivoted ?? true;
};
