import React, { useContext, useMemo } from 'react';
import styled, { ThemeContext } from 'styled-components';
import type { Portfolio, DrawdownRangeAnalysis, DrawdownContribution } from 'venn-api';
import type { VennColors } from 'venn-ui-kit';
import { GetColor, OverflowContainer, OverflowText, TooltipBodyDirection, Tooltip } from 'venn-ui-kit';
import Bar from '../../../../bar/Bar';
import type { BasicTableColumn, BasicTableProps, StyledTableType } from '../../../../basictable/BasicTable';
import BasicTable, { SORTDIR, ColumnAlign } from '../../../../basictable/BasicTable';
import exportToExcel from '../../../../basictable/convertToExcel';
import DownloadableContentBlock from '../../../../content-block/DownloadableContentBlock';
import { getPortfolioAllocation, sortDrawdownContribution } from './utils';
import type { AnalysisSubject } from 'venn-utils';
import type { DownloadMetaData } from '../../../../downloadable';

const qaClasses = {
  strategy: 'qa-strategy-drivers',
  fund: 'qa-fund-drivers',
};

function getColumns(
  data: DrawdownContribution[],
  portfolio: Portfolio,
  colors: VennColors,
): BasicTableColumn<DrawdownContribution>[] {
  return [
    {
      label: 'Name',
      accessor: 'name',
      headerStyle: { paddingLeft: 20 },
      cellStyle: { paddingLeft: 20 },
      cellRenderer: ({ name }: DrawdownContribution) => (
        <Tooltip content={name} hideArrow bodyDirection={TooltipBodyDirection.Right}>
          <StyledNameOverflowContainer>
            <OverflowText.SingleLine>{name}</OverflowText.SingleLine>
          </StyledNameOverflowContainer>
        </Tooltip>
      ),
      excelCellRenderer: ({ name }) => ({
        value: name,
      }),
    },
    {
      label: 'Percent of Portfolio Drawdown',
      accessor: 'contribution',
      align: ColumnAlign.RIGHT,
      cellStyle: { color: colors.DataLineColor.Gold, width: 270, minWidth: 270, maxWidth: 270 },
      headerStyle: { paddingRight: 13 },
      sortable: true,
      sorted: SORTDIR.DESC,
      sortTableFunc: sortDrawdownContribution,
      cellRenderer: ({
        name,
        contribution,
        factorContribution,
        residualContribution,
        cashContribution,
      }: DrawdownContribution) => {
        const maxContribution = data.reduce((memo, ddc) => Math.max(memo, Math.abs(ddc.contribution)), 0);
        const barValue = (contribution / maxContribution) * 100;
        return (
          <BarContainer>
            <Tooltip
              content={
                <ContributionTooltip>
                  <p>Factor Contribution to Drawdown:</p>
                  <p>{name}</p>
                  {factorContribution.map((factor) => (
                    <ContributionTooltipFactor key={factor.factorName}>
                      <span>{factor.factorName}:</span>
                      <span>{factor.contribution ? `${(factor.contribution * 100).toFixed(2)}%` : '--'}</span>
                    </ContributionTooltipFactor>
                  ))}
                  <ContributionTooltipFactor>
                    <span>
                      <i>Residual:</i>
                    </span>
                    <span>
                      <i>{residualContribution ? `${(residualContribution * 100).toFixed(2)}%` : '--'}</i>
                    </span>
                  </ContributionTooltipFactor>
                  <ContributionTooltipFactor>
                    <span>
                      <i>Risk-Free Rate:</i>
                    </span>
                    <span>
                      <i>{cashContribution ? `${(cashContribution * 100).toFixed(2)}%` : '--'}</i>
                    </span>
                  </ContributionTooltipFactor>
                </ContributionTooltip>
              }
            >
              <StyledBar
                barColor={colors.DataBarColor.LightGold}
                barWidth={200}
                barHeight={12}
                value={barValue}
                centerLine
              />
            </Tooltip>
            <Contribution>{contribution !== null ? `${(contribution * 100).toFixed(2)}%` : '--'}</Contribution>
          </BarContainer>
        );
      },
      excelCellRenderer: ({ contribution }) => ({
        value: contribution,
        percentage: true,
      }),
    },
    {
      label: 'Allocation',
      accessor: 'allocation',
      align: ColumnAlign.RIGHT,
      sortable: true,
      headerStyle: { minWidth: 100, flexGrow: 0 },
      cellRenderer: ({ allocation }: DrawdownContribution) =>
        `${((allocation / getPortfolioAllocation(portfolio)) * 100).toFixed(2)}%`,
      excelCellRenderer: ({ allocation }) => ({
        value: allocation / getPortfolioAllocation(portfolio),
        percentage: true,
      }),
    },
    {
      label: 'Return During Portfolio Max Drawdown',
      accessor: 'returnValue',
      headerStyle: { paddingRight: 20, minWidth: 200 },
      cellStyle: { paddingRight: 20, minWidth: 200 },
      align: ColumnAlign.RIGHT,
      sortable: true,
      cellRenderer: ({ returnValue }: DrawdownContribution) =>
        returnValue !== null ? `${(returnValue * 100).toFixed(2)}%` : '--',
      excelCellRenderer: ({ returnValue }) => ({
        value: returnValue !== null ? returnValue : '--',
        percentage: returnValue !== null,
      }),
    },
  ];
}

export interface PortfolioInvestmentsProps {
  subject: AnalysisSubject;
  data: DrawdownRangeAnalysis;
  downloadMetaData?: DownloadMetaData;
}

const PortofolioInvestments = (props: PortfolioInvestmentsProps) => {
  const { Colors } = useContext(ThemeContext);
  const {
    subject,
    data: { strategyDrawdownContribution: sdc, fundDrawdownContribution },
    downloadMetaData,
  } = props;
  const portfolio = subject.strategy;
  const strategyColumns = useMemo(
    () => (portfolio ? getColumns(sdc, portfolio, Colors) : []),
    [sdc, portfolio, Colors],
  );
  const strategyExcelData = useMemo(
    () => (portfolio ? exportToExcel(sdc, strategyColumns) : undefined),
    [strategyColumns, sdc, portfolio],
  );
  const investmentsColumns = useMemo(
    () => (portfolio ? getColumns(fundDrawdownContribution, portfolio, Colors) : []),
    [fundDrawdownContribution, portfolio, Colors],
  );
  const investmentsExcelData = useMemo(
    () => (portfolio ? exportToExcel(fundDrawdownContribution, investmentsColumns) : undefined),
    [investmentsColumns, fundDrawdownContribution, portfolio],
  );
  return (
    <>
      {!!(sdc && sdc.length) && (
        <>
          <DownloadableContentBlock
            header={`Strategy Drivers for ${subject.name}`}
            downloadable={{
              png: true,
              excel: strategyExcelData,
              tracking: {
                description: 'DRAWDOWN_MARKET_ENV_STRATEGY_DRIVERS',
                relativeToBenchmark: false,
                subjectType: subject.type,
                subjectId: subject.id,
                userUploaded: subject.userUploaded,
              },
              options: {
                fileName: `Strategy Drivers for ${subject.name}`,
                metaData: downloadMetaData,
              },
            }}
          >
            <StyledBasicTable columns={strategyColumns} data={sdc} className={qaClasses.strategy} />
          </DownloadableContentBlock>
        </>
      )}
      <DownloadableContentBlock
        header={`Investment Drivers for ${portfolio?.name}`}
        downloadable={{
          png: true,
          excel: investmentsExcelData,
          tracking: {
            description: 'DRAWDOWN_MARKET_ENV_INVESTMENT_DRIVERS',
            relativeToBenchmark: false,
            subjectType: subject.type,
            subjectId: subject.id,
            userUploaded: subject.userUploaded,
          },
          options: {
            fileName: `Investment Drivers for ${portfolio?.name}`,
          },
        }}
      >
        <StyledBasicTable columns={investmentsColumns} data={fundDrawdownContribution} className={qaClasses.fund} />
      </DownloadableContentBlock>
    </>
  );
};
export default PortofolioInvestments;
const StyledNameOverflowContainer = styled(OverflowContainer)`
  width: 400px;
  min-width: 400px;
  @media (max-width: 1800px) {
    width: 200px;
    min-width: 200px;
  }
`;

const BarContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ContributionTooltip = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 210px;
  padding: 2px;

  p {
    margin: 0;
    text-align: left;
  }

  p:first-child {
    font-size: 12px;
    font-weight: 300;
  }

  p:nth-child(2) {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 15px;
  }
`;

const ContributionTooltipFactor = styled.div`
  display: flex;

  span:first-child {
    justify-self: flex-start;
  }

  span:last-child {
    justify-self: flex-end;
    margin-left: auto;
    margin-right: 20px;
    font-weight: bold;
  }
`;

const StyledBar = styled(Bar)`
  border: 1px solid ${GetColor.Grey};
`;

const Contribution = styled.span`
  display: inline-block;
  min-width: 60px;
`;

const StyledBasicTable: StyledTableType<unknown> = styled(
  <T extends BasicTableColumn<K>, K>(props: BasicTableProps<T, K>) => <BasicTable<T, K> {...props} />,
)`
  margin: 10px 0 30px;

  > tbody > tr {
    height: 26px;
  }
`;
