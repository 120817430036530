import type { History } from 'history';
import { isNil } from 'lodash';
import queryString from 'query-string';
import type { AnalysisView, AnalysisViewSearchResult, Portfolio } from 'venn-api';
import type { ItemType } from 'venn-ui-kit';
import type { AnalysisSubjectSecondaryLabel, AnalysisSubjectType } from '../analysis';
import analyticsService from '../analytics/generated';
import { isReport } from '../studio/studioUtils';
import type { StudioTemplateState } from '../studio/types';
import type { SelectedFilters } from '../types';
import { LibraryFilterSectionKey, LibraryItemType } from '../types';
import { assert } from '../utils/assert';
import { Routes } from './routes';

export type PreviousLocation = 'Analysis' | 'Library' | 'Portfolio Lab' | 'Studio' | 'Manage Investment';

export interface ManageDataState {
  secondaryPortfolio?: Portfolio;
  secondaryLabel?: AnalysisSubjectSecondaryLabel;
  previousLocation?: PreviousLocation;
}

export interface ObjectId {
  fundId?: string;
  portfolioId?: number;
  privateFundId?: string;
  privatePortfolioId?: string;
}

export type PrivateInvestmentId = {
  itemType: 'INVESTMENT';
  id: string;
};
export type PrivatePortfolioId = {
  itemType: 'PORTFOLIO';
  id: string;
};
export type PrivatesObjectId = PrivatePortfolioId | PrivateInvestmentId;

export enum MANAGE_DATA_SECTION {
  INVESTMENT_INFORMATION = 'investment-info',
  ANALYSIS_RANGE = 'analysis-range',
  DATA = 'data',
  PORTFOLIOS = 'portfolios',
  PORTFOLIO_POLICY = 'portfolio-policy',
  PORTFOLIO_INFORMATION = 'portfolio-info',
  TRANSACTIONS = 'transactions',
  CASH_FLOW_PACING_SETTINGS = 'cash-flow-pacing-settings',
}

export const DEFAULT_INVESTMENT_SECTION = MANAGE_DATA_SECTION.INVESTMENT_INFORMATION;
export const DEFAULT_PORTFOLIO_SECTION = MANAGE_DATA_SECTION.PORTFOLIO_INFORMATION;

export const getDefaultSection = (objectType: AnalysisSubjectType) =>
  objectType === 'investment' || objectType === 'private-investment'
    ? DEFAULT_INVESTMENT_SECTION
    : DEFAULT_PORTFOLIO_SECTION;

export const navigateToManageDataPage = (
  history: History,
  objectId: ObjectId,
  previousLocation: PreviousLocation,
  openNewWindow: boolean,
  section?: MANAGE_DATA_SECTION,
  state: ManageDataState = {},
) => {
  const isPrivate = objectId.privateFundId !== undefined || objectId.privatePortfolioId !== undefined;
  const objectType = isPrivate
    ? objectId.privateFundId
      ? 'private-investment'
      : 'private-portfolio'
    : objectId.fundId
      ? 'investment'
      : 'portfolio';
  const id = objectId.fundId ?? objectId.portfolioId ?? objectId.privateFundId ?? objectId.privatePortfolioId;
  analyticsService.dataManagementOpened({
    objectId: id?.toString(),
    location: previousLocation,
  });
  const urlSection = section ?? getDefaultSection(objectType);

  if (openNewWindow) {
    window.open(
      history.createHref({ pathname: `${Routes.MANAGE_DATA_PATH}/${objectType}/${id}/${urlSection}` }),
      '_blank',
    );
  } else {
    history.push(`${Routes.MANAGE_DATA_PATH}/${objectType}/${id}/${urlSection}`, state);
  }
};

export const navigateToDataSetManagement = (history: History, dataSetId?: string) => {
  const prefix = Routes.ACCOUNT_MANAGE_DATA_SET;
  const addDataSet = dataSetId ? `?dataSetId=${dataSetId}` : '';
  history.push(`${prefix}${addDataSet}`, {
    state: history.location.state,
  });
};

export interface TemplateNavigationState {
  previousPathname?: string;
  previousSearch?: string;
}

/** Remember previous path and search to be able to navigate back */
export const navigateToTemplate = (history: History, templateId?: string, blockId?: string) => {
  const prefix = templateId ? `${Routes.EDIT_TEMPLATE_PATH}/${templateId}` : Routes.CREATE_TEMPLATE;
  const addbBlock = blockId ? `?add=${blockId}` : '';
  history.push(`${prefix}${addbBlock}`, {
    previousPathname: history.location.pathname,
    previousSearch: history.location.search,
    state: history.location.state,
  });
};

export const getRedirectLinkFromHistory = (history: History<TemplateNavigationState>, basePath?: string): string => {
  if (!history.location.state?.previousPathname) {
    return '';
  }
  const search = history.location.state.previousSearch ?? '';
  if (!basePath) {
    return `${history.location.state?.previousPathname}${search}`;
  }

  // Rest path exclude template "objectType/objectId/strategyId"
  const resPath = history.location.state.previousPathname
    .replace(`${Routes.ANALYSIS_PATH}/results/`, '')
    .split('/')
    .slice(1)
    .join('/');

  return `${basePath}${resPath}${search}`;
};

export const getPreviousState = (redirectLink: string, state = {}) => {
  /** If previous view is a saved view, return a reserved state that could override saved params */
  if (redirectLink.includes('savedId')) {
    return { reservedState: state };
  }
  return state;
};

export const ReturnsDataLibraryRoute = '/libraries/public';
export const PrivateAssetLibraryRoute = '/libraries/private-assets';

interface LibraryNavigationRequestProps {
  tab?: LibraryTab;
  selectedFilters?: SelectedFilters;
  selectedIds?: string[];
  lastTimeBeforeUpdate?: number;
  name?: string;
  sortByUpdated?: boolean;
  state?: LibraryState;
}

export const getLibraryPath = ({
  tab = LibraryTab.ReturnsData,
  selectedFilters,
  selectedIds = [],
  lastTimeBeforeUpdate,
  name,
  sortByUpdated = true,
}: LibraryNavigationRequestProps) => {
  const { quickFilters, itemType, tags } = selectedFilters ?? {
    quickFilters: [],
    itemType: LibraryItemType.ALL,
    tags: [],
  };

  const queryParams = queryString.stringify({
    selectedIds,
    [LibraryFilterSectionKey.FILTERS]: quickFilters,
    [LibraryFilterSectionKey.ITEM_TYPE]: itemType,
    [LibraryFilterSectionKey.TAGS]: tags,
  });

  const route = tab === LibraryTab.PrivateAssets ? PrivateAssetLibraryRoute : ReturnsDataLibraryRoute;
  return `${route}?name=${name ?? ''}&order=desc&page=1${
    sortByUpdated ? '&sortBy=updated' : ''
  }&lastTrackTime=${lastTimeBeforeUpdate}&${queryParams}`;
};

export interface LibraryState {
  advancedFiltersOpen?: boolean;
}

export enum LibraryTab {
  ReturnsData = 'returns-data',
  PrivateAssets = 'private-assets',
}

export const navigateToLibrary = (
  history: History,
  {
    tab = LibraryTab.ReturnsData,
    selectedFilters,
    selectedIds = [],
    lastTimeBeforeUpdate,
    state,
  }: LibraryNavigationRequestProps,
) => {
  history.push(
    getLibraryPath({
      tab,
      selectedIds,
      selectedFilters,
      lastTimeBeforeUpdate,
    }),
    state,
  );
};

export const getAnalysisPath = (subjectType: AnalysisSubjectType, subjectId: string | number, templateId = 'default') =>
  `${Routes.ANALYSIS_PATH}/results/${templateId}/${subjectType}/${subjectId}`;

export const getPortfolioLabPath = (
  portfolioId: number,
  investmentIds?: string[],
  parentStrategyId?: number,
  maxAllocation?: number,
) => {
  const mainRoute = `${Routes.PORTFOLIO_LAB_RESULTS_PATH}?portfolioId=${portfolioId}`;

  const additionalInvestmentMaxAllocationParams = !isNil(maxAllocation) ? `&maxAllocation=${maxAllocation}` : '';
  const additionalInvestmentParams = !isNil(investmentIds)
    ? `&investmentIds=${investmentIds.join(',')}&parentStrategyId=${
        parentStrategyId ?? portfolioId
      }${additionalInvestmentMaxAllocationParams}`
    : '';

  return `${mainRoute}${additionalInvestmentParams}`;
};

const getCompareParam = (compareToMaster?: boolean) => {
  if (compareToMaster === undefined) {
    return '';
  }
  return `?compare=${compareToMaster ? 'master' : 'none'}`;
};

export const getAnalysisPathForPortfolio = (portfolioId: number, templateId?: string, compareToMaster?: boolean) =>
  `${getAnalysisPath('portfolio', portfolioId, templateId)}${getCompareParam(compareToMaster)}`;

export const getAnalysisPathForInvestment = (investmentId: string, templateId?: string) =>
  getAnalysisPath('investment', investmentId, templateId);

export const navigateToReportingItemType = (history: History, itemType: ItemType) => {
  history.push(`${Routes.REPORTS}?itemType=${itemType}`);
};

export const getAnalysisViewUrl = (analysisView: AnalysisViewSearchResult | AnalysisView, editTemplate?: boolean) => {
  const templateId = analysisView.systemTemplate || analysisView.customTemplateId || 'default';
  const prefix = (() => {
    if (analysisView.analysisViewType === 'COMPARE') {
      return Routes.ANALYSIS_COMPARE_PATH;
    }

    if (analysisView.analysisViewType === 'CUSTOM') {
      return Routes.CUSTOM_BLOCK_PATH;
    }

    if (['ASSEMBLY', 'TEARSHEET', 'TEARSHEET_TEMPLATE'].includes(analysisView.analysisViewType ?? '')) {
      return Routes.STUDIO_PATH;
    }

    if (['REPORT', 'REPORT_TEMPLATE'].includes(analysisView.analysisViewType ?? '')) {
      return Routes.REPORT_LAB_PATH;
    }

    assert('analysisSubjectType' in analysisView, 'analysisSubjectType is required');
    const subjectType = analysisView.analysisSubjectType === 'PORTFOLIO' ? 'portfolio' : 'investment';
    const subjectId = analysisView.subjectId;

    return getAnalysisPath(subjectType, subjectId, templateId);
  })();

  return `${prefix}?savedId=${analysisView.id}${
    window.location.href.includes('studio_use_parallel_queries_ff') ? '&studio_use_parallel_queries_ff=true' : ''
  }${editTemplate !== undefined ? `&editTemplate=${editTemplate}&isReport=${analysisView.analysisViewType && isReport(analysisView.analysisViewType)}` : ''}`;
};

export const navigateToSavedView = (history: History, analysisView: AnalysisViewSearchResult | AnalysisView) =>
  history.push(getAnalysisViewUrl(analysisView));

export const navigateToStudioView = (history: History, state?: StudioTemplateState) =>
  history.push(Routes.STUDIO_PATH, state);

export const navigateToReportLab = (history: History, state?: StudioTemplateState) =>
  history.push(Routes.REPORT_LAB_PATH, state);

export const navigateToFactorInsights = (history: History, factor: string) => {
  const query = queryString.stringify({ factors: JSON.stringify([factor]) });
  history.push(`${Routes.FACTOR_LENS_INSIGHTS}?${query}`);
};
