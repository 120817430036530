import { compact, concat, isNil } from 'lodash';
import moment from 'moment/moment';
import { useRecoilValue } from 'recoil';
import type { PrivatesAnalysisRequest, PrivatesAnalysisResponse, PrivateSubject } from 'venn-api';
import { privatesAnalysis } from 'venn-api';
import {
  analysisIdState,
  type ApiSubject,
  type BenchmarkConfig,
  blockBenchmarkConfig,
  blockDateRange,
  blockPrivateDefaultAsOfDateBehavior,
  blockRequestSubjects,
  blockSettings,
  convertApiSubjectToStudioSubject,
  convertStudioSubjectToApiSubject,
  getBenchmarkSubject,
  type StudioRequestSubject,
  type Subject,
} from 'venn-state';
import {
  assert,
  assertNotNil,
  getRequestSubjectFromAnalysisSubject,
  getSubjectFromRequestSubject,
  isPrivatesBlock,
  isPrivatesPerformanceBlock,
  isProjectionBasedPrivatesBlock,
  logExceptionIntoSentry,
  PRIVATES_PERFORMANCE_BLOCKS,
  useQuery,
} from 'venn-utils';
import { TRACK_TIMESTAMPS_AFTER } from '../../../../venn-frontend/src/analysis-page/src/logic/useTrackFailedAnalysis';
import { usePrivatesExportInfo } from './usePrivatesExportInfo';
import { useSelectedMetrics } from './useSelectedMetrics';

const PRIVATE_CF_CACHED_KEY = 'private_cf_cached';
const ONE_HOUR = 1000 * 60 * 60;

const convertToPrivateSubject = (subject: StudioRequestSubject): PrivateSubject => {
  if (subject.privateFund?.id) {
    return {
      type: 'private-fund-id',
      id: assertNotNil(subject.privateFund.id),
    };
  }
  if (!isNil(subject.modifiedPrivatePortfolio)) {
    return {
      type: 'private-portfolio',
      portfolio: subject.modifiedPrivatePortfolio,
    };
  }
  return {
    type: 'private-portfolio-id',
    id: assertNotNil(subject.privatePortfolio?.id),
  };
};

export const getBenchmarkForPrivateSubject = (
  benchmarkConfig: BenchmarkConfig,
  subject: StudioRequestSubject,
): ApiSubject | null => {
  let benchmarkSubject;

  switch (benchmarkConfig.type) {
    case 'NONE':
      benchmarkSubject = null;
      break;
    case 'INDIVIDUAL':
      benchmarkSubject = !isNil(subject.individualBenchmark)
        ? getRequestSubjectFromAnalysisSubject(getBenchmarkSubject(subject.individualBenchmark))
        : null;
      break;
    case 'COMMON':
      benchmarkSubject = !isNil(benchmarkConfig.subject)
        ? getRequestSubjectFromAnalysisSubject(benchmarkConfig.subject)
        : null;
      break;
  }

  if (isNil(benchmarkSubject)) {
    return null;
  }

  return convertStudioSubjectToApiSubject(benchmarkSubject, 'BENCHMARK', false);
};

/** Fetches up to date info for checking export permissions, respecting changes in allocator panel */
const getUpToDateSubjectForCheckingExport = (subject: StudioRequestSubject): Subject[] => {
  if (subject.modifiedPrivatePortfolio) {
    // we need to get all funds inside modifiedPrivatePortfolio to have most up to date info
    // excel export doesn't require saving, so the ID for modifiedPrivatePortfolio doesn't exist
    return subject.modifiedPrivatePortfolio.children.map((node) => {
      return {
        privateFundId: node.fundId,
      };
    });
  }
  // if there is no modified private portfolio, this subject is up to date and we can use the standard conversion
  return [getSubjectFromRequestSubject(subject)];
};

export const usePrivateAnalysis = (id: string) => {
  const settings = useRecoilValue(blockSettings(id));
  const blockType = settings.customBlockType;
  const subjects = useRecoilValue(blockRequestSubjects(id));
  const defaultAsOfDateBehavior = useRecoilValue(blockPrivateDefaultAsOfDateBehavior(id));
  const isPrivate = isPrivatesBlock(blockType);
  const benchmarkConfig = useRecoilValue(blockBenchmarkConfig(id));

  assert(isPrivate, 'usePrivateAnalysis can only be called from private blocks');

  const dateRange = useRecoilValue(blockDateRange(id));

  const benchmarks = subjects.map((subject) => getBenchmarkForPrivateSubject(benchmarkConfig, subject));

  const projectAfterLastCashflow = isProjectionBasedPrivatesBlock(blockType) ? defaultAsOfDateBehavior : false;
  const endOnLatestAvailableTransaction = isPrivatesPerformanceBlock(blockType) ? defaultAsOfDateBehavior : false;
  const request: PrivatesAnalysisRequest = {
    asOfDate: dateRange?.range.to ?? moment().valueOf(),
    subjects: subjects.map(convertToPrivateSubject),
    benchmarks,
    projectAfterLastCashFlow: projectAfterLastCashflow,
    endOnLatestAvailableTransaction,
    analysisTypes: [
      'PRIVATE_CASH_FLOW_PACING',
      'PRIVATE_PERFORMANCE_SUMMARY_TIME_SERIES',
      'PRIVATE_PERFORMANCE_SUMMARY',
    ],
    trackingId: Date.now() - TRACK_TIMESTAMPS_AFTER,
  };

  const privatesAnalysisFetchingFunction = async (): Promise<PrivatesAnalysisResponse> => {
    const { content } = await privatesAnalysis(request);
    return content;
  };

  const analysisId = useRecoilValue(analysisIdState);

  // add blockType to the queryKey below if the queries need to be modified per-block
  const { data } = useQuery<PrivatesAnalysisResponse>(
    [
      PRIVATE_CF_CACHED_KEY,
      analysisId,
      defaultAsOfDateBehavior,
      endOnLatestAvailableTransaction,
      dateRange,
      subjects,
      benchmarks,
    ],
    () => {
      return privatesAnalysisFetchingFunction();
    },
    {
      onError: (error) => {
        logExceptionIntoSentry(error as Error);
      },
      suspense: true,
      staleTime: ONE_HOUR,
      enabled: isPrivate && subjects.length !== 0,
    },
  );

  const selectedMetrics = useSelectedMetrics();
  const isUsingMetricsWithBenchmarks =
    PRIVATES_PERFORMANCE_BLOCKS.includes(blockType) && selectedMetrics.some((metric) => metric.needsBenchmark === true);

  const isExportable = usePrivatesExportInfo(
    concat(
      subjects.map(getUpToDateSubjectForCheckingExport).flat(),
      isUsingMetricsWithBenchmarks
        ? compact(
            benchmarks.map((benchmark) => {
              if (isNil(benchmark)) {
                return null;
              }
              return convertApiSubjectToStudioSubject(benchmark);
            }),
          )
        : [],
    ),
  );

  return {
    data,
    isExportable,
  };
};
