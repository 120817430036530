import type { FC } from 'react';
import React, { useContext, useMemo, useRef } from 'react';
import styled, { ThemeContext } from 'styled-components';
import {
  AnalysisBlockWatermark,
  Bar,
  BlockByolWatermark,
  Downloadable,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalSize,
  ModalSubhead,
  Toggle,
  ToggleOption,
} from 'venn-components';
import type { AnalysisSubjectType, ExcelCell } from 'venn-utils';
import { Numbers, SpecialCssClasses } from 'venn-utils';
import type { PerformanceAttribution, PortfolioPerformanceAttribution } from 'venn-api';
import { GetColor } from 'venn-ui-kit';
import { Cell, HeaderRow, LargeRow, Table } from '../Table';
import useModalLogic from './useModalLogic';
import SortableHeader from './SortableHeader';
import { isNil } from 'lodash';

export enum TimeframeEnum {
  Historical = 'Historical',
  Forecast = 'Forecast',
}
export interface ContributionData {
  metric: Metric;
  value: number;
  subjectName: string;
  attributions: PortfolioPerformanceAttribution;
  timeframe: TimeframeEnum;
}
interface Metric {
  name: string;
  abbreviatedName?: string;
  formatter: (value: number | undefined) => string;
  percentage?: boolean;
}

interface Subject {
  name: string;
  userUploaded: boolean;
  type: AnalysisSubjectType;
  id: string | number;
}

export interface ContributionProps {
  data: ContributionData;
  subject: Subject;
  relative: boolean;
  onClose: () => void;
}

const ContributionTooltip: FC<React.PropsWithChildren<ContributionProps>> = ({
  subject,
  relative,
  data: { metric, value, attributions, timeframe },
  onClose,
}) => {
  const { list, maximum, toggle, shouldDisplayToggle, displayFunds, sort, currentSortKey, sortDirection } =
    useModalLogic(attributions, value);
  const { Colors } = useContext(ThemeContext);

  const excelData = useMemo(() => getExcelData(displayFunds, metric, list), [displayFunds, metric, list]);

  const modalContentRef = useRef<HTMLDivElement>(null);

  return (
    <Modal closeOnEsc onClose={onClose} size={ModalSize.XLarge}>
      <div ref={modalContentRef}>
        <HeaderWrapper>
          <ModalSubhead>
            Contribution to {metric.name} ({timeframe})
          </ModalSubhead>
          <Downloadable
            png
            excel={excelData}
            tracking={{
              description:
                timeframe === 'Forecast'
                  ? 'PERFORMANCE_SUMMARY_FORECAST_CONTRIBUTION'
                  : 'PERFORMANCE_SUMMARY_HISTORICAL_CONTRIBUTION',
              relativeToBenchmark: relative,
              subjectType: subject.type,
              userUploaded: subject.userUploaded,
              subjectId: subject.id,
            }}
            options={{
              fileName: `Contribution to ${metric.name} - ${subject.name}`,
            }}
            target={modalContentRef}
          />
        </HeaderWrapper>
        <ModalHeader>
          Total <Highlight>{metric.formatter(value)}</Highlight>
        </ModalHeader>
        <ModalContent>
          {shouldDisplayToggle && (
            <ToggleContainer className={SpecialCssClasses.NotDownloadable}>
              <Toggle onChange={toggle} value={displayFunds ? 'inv' : 'strat'}>
                <ToggleOption value="inv">Investments</ToggleOption>
                <ToggleOption value="strat">Strategies</ToggleOption>
              </Toggle>
            </ToggleContainer>
          )}
          <TableContainer className={SpecialCssClasses.DownloadableHeightFitContent}>
            <Table>
              <thead>
                <HeaderRow>
                  <SortableHeader
                    sortKey="name"
                    current={currentSortKey}
                    asc={sortDirection}
                    // @ts-expect-error: TODO fix strictFunctionTypes
                    onClick={sort}
                  >
                    {displayFunds ? 'Investment' : 'Strategy'} Name
                  </SortableHeader>
                  <SortableHeader
                    right
                    width={90}
                    sortKey="weight"
                    current={currentSortKey}
                    asc={sortDirection}
                    // @ts-expect-error: TODO fix strictFunctionTypes
                    onClick={sort}
                  >
                    Weight
                  </SortableHeader>
                  <SortableHeader
                    right
                    width={90}
                    sortKey="value"
                    current={currentSortKey}
                    asc={sortDirection}
                    // @ts-expect-error: TODO fix strictFunctionTypes
                    onClick={sort}
                  >
                    {metric.abbreviatedName || metric.name}
                  </SortableHeader>
                  <SortableHeader
                    width={300}
                    sortKey="contributionValue"
                    current={currentSortKey}
                    asc={sortDirection}
                    // @ts-expect-error: TODO fix strictFunctionTypes
                    onClick={sort}
                    right
                  >
                    Contribution
                  </SortableHeader>
                </HeaderRow>
              </thead>
              <tbody>
                {list.map((investment) => (
                  <LargeRow key={investment.name}>
                    <Cell>
                      <InvestmentName>{investment.name}</InvestmentName>
                    </Cell>
                    <Cell align="right">{Numbers.safeFormatPercentage(investment.weight, 2)}</Cell>
                    <Cell align="right">{metric.formatter(investment.value)}</Cell>
                    <ContributionCell>
                      <DataCellInner>
                        <DataBar
                          barColor={Colors.DataBarColor.LightGold}
                          min={isNil(maximum) ? undefined : -maximum}
                          max={maximum}
                          value={investment.contributionValue}
                          barWidth={200}
                        />
                        {metric.formatter(investment.contributionValue)}
                      </DataCellInner>
                    </ContributionCell>
                  </LargeRow>
                ))}
              </tbody>
            </Table>
          </TableContainer>
        </ModalContent>
        <BlockByolWatermark />
        <AnalysisBlockWatermark />
      </div>
      <ModalFooter onCancel={onClose} />
    </Modal>
  );
};

function getExcelData(
  displayFunds: boolean,
  metric: Metric,
  data: PerformanceAttribution[],
): ExcelCell[][] | undefined {
  if (!data || !data.length) {
    return undefined;
  }
  return [
    [
      { value: `${displayFunds ? 'Investment' : 'Strategy'} Name`, bold: true },
      { value: 'Weight', bold: true },
      { value: metric.name, bold: true },
      { value: 'Contribution', bold: true },
    ],
    ...data.map(
      (item) =>
        [
          { value: item.name },
          { value: item.weight, percentage: true, digits: 2 },
          { value: item.value, percentage: metric.percentage, digits: metric.percentage ? 1 : 2 },
          { value: item.contributionValue, percentage: metric.percentage, digits: metric.percentage ? 1 : 2 },
        ] as ExcelCell[],
    ),
  ];
}

const ToggleContainer = styled.div`
  display: flex;
  justify-items: end;
`;

const TableContainer = styled.div`
  max-height: 310px;
  overflow-y: scroll;
  margin-top: 0px;
  > table {
    width: 100%;
  }
`;

const DataCellInner = styled.div`
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ContributionCell = styled(Cell)`
  color: ${GetColor.DataLineColor.Gold};
`;

const DataBar = styled(Bar)`
  border: 1px solid ${GetColor.Grey};
  height: 16px;
  margin-right: 30px;
`;

const InvestmentName = styled.div`
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 5px;
`;

const Highlight = styled.span`
  color: ${GetColor.Primary.Dark};
  font-weight: bold;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
`;

export default ContributionTooltip;
