import type { FactorExposure } from 'venn-api';

export const mockFactorBreakdown: FactorExposure[] = [
  {
    id: 10000,
    name: 'Equity',
    exposure: 0.5001835013430367,
    riskContribution: 0.03282316833499926,
    returnContribution: -0.04994909793277561,
    significant: true,
  },
  {
    id: 10001,
    name: 'Interest Rates',
    exposure: 1.7214277963620928,
    riskContribution: 0.006174400025364622,
    returnContribution: 0.06839310366465665,
    significant: false,
  },
  {
    id: 10002,
    name: 'Credit',
    exposure: -0.20477447664244233,
    riskContribution: 0.004913574214539086,
    returnContribution: 0.05057515119387898,
    significant: false,
  },
  {
    id: 10003,
    name: 'Commodities',
    exposure: 0.8689128776081906,
    riskContribution: 0.03529609552678843,
    returnContribution: 0.061065201043506045,
    significant: true,
  },
  {
    id: 10004,
    name: 'Foreign Currency',
    exposure: 1.6408149998707628,
    riskContribution: 0.08241533848443423,
    returnContribution: 0.28702767143272273,
    significant: true,
  },
  {
    id: 10005,
    name: 'Local Inflation',
    exposure: 0.3512617473579111,
    riskContribution: 0.009112999012486526,
    returnContribution: 0.010323265906041419,
    significant: false,
  },
  {
    id: 10006,
    name: 'Emerging Markets',
    exposure: 0.4885417081250895,
    riskContribution: -0.027380640906703044,
    returnContribution: 0.013509319581194735,
    significant: true,
  },
  {
    id: 10037,
    name: 'Fixed Income Carry',
    exposure: 0.18087146865085435,
    riskContribution: 0.0022942768799510374,
    returnContribution: 0.03758447121913433,
    significant: false,
  },
  {
    id: 10038,
    name: 'Foreign Exchange Carry',
    exposure: 0.2488029105466911,
    riskContribution: 0.015079088484870117,
    returnContribution: 0.016133424866593887,
    significant: true,
  },
  {
    id: 10039,
    name: 'Trend Following',
    exposure: 0.5752009132742433,
    riskContribution: 0.046237562661258745,
    returnContribution: 0.0426499307483376,
    significant: true,
  },
  {
    id: 10045,
    name: 'Local Equity',
    exposure: 0.870028215004666,
    riskContribution: 0.030478750108814102,
    returnContribution: 0.0011029505680681803,
    significant: true,
  },
  {
    id: 10046,
    name: 'Equity Short Volatility',
    exposure: -0.1348126832788203,
    riskContribution: -0.00440729119634166,
    returnContribution: 0.0016052578580282425,
    significant: false,
  },
  {
    id: 10066,
    name: 'Low Risk',
    exposure: -0.051067357027968746,
    riskContribution: 0.000560677034289373,
    returnContribution: 0.004742202195427187,
    significant: false,
  },
  {
    id: 10067,
    name: 'Momentum',
    exposure: -0.156220499898219,
    riskContribution: -0.007200639306293378,
    returnContribution: -0.039235770089072045,
    significant: false,
  },
  {
    id: 10068,
    name: 'Quality',
    exposure: 0.7959902680739086,
    riskContribution: 0.010669982868120029,
    returnContribution: 0.05696637307848274,
    significant: true,
  },
  {
    id: 10069,
    name: 'Value',
    exposure: -0.2750382926770995,
    riskContribution: 0.0087773508808829,
    returnContribution: 0.026699997599875715,
    significant: false,
  },
  {
    id: 10070,
    name: 'Small Cap',
    exposure: 0.937010479115586,
    riskContribution: 0.0051319387489852965,
    returnContribution: -0.08835063481508804,
    significant: false,
  },
  {
    id: 10071,
    name: 'Crowding',
    exposure: 1.2018377825665085,
    riskContribution: -0.00564060729509568,
    returnContribution: 0.000834002782344039,
    significant: false,
  },
];
