import React from 'react';
import type { SponsoredAccount } from 'venn-api';
import type { BasicTableProps, BasicTableColumn } from 'venn-components';
import { BasicTable } from 'venn-components';
import { isInvitationExpired } from 'venn-utils';
import styled from 'styled-components';
import { Caption, ColorUtils, GetColor, Pagination, TableRowOptionsMenu } from 'venn-ui-kit';
import { isNil } from 'lodash';

const accountActionsRenderer =
  (
    resendInvite: (account: SponsoredAccount) => void,
    removeInvite: (account: SponsoredAccount) => void,
    removeAccount: (account: SponsoredAccount) => void,
  ) =>
  // TODO(VENN-24534): add a display name to this React component
  // eslint-disable-next-line react/display-name
  (row: SponsoredAccount) => {
    const isInvite = !isNil(row.invitationId);
    const options = isInvite
      ? [
          {
            label: 'Re-send invite',
            onClick: () => resendInvite(row),
          },
          {
            label: 'Remove',
            onClick: () => removeInvite(row),
          },
        ]
      : [
          {
            label: 'Remove',
            onClick: () => removeAccount(row),
          },
        ];
    return (
      <TableRowOptionsMenu
        options={options}
        menuClassName="accounts-menu-dropdown"
        triggerClassName="accounts-menu-trigger"
      />
    );
  };

const statusRenderer = (row: SponsoredAccount) => {
  if (row.status) {
    return row.status === 'INACTIVE' ? 'Account Deactivated' : 'Sponsoring';
  }
  return isInvitationExpired(row.invitationExpirationDate) ? (
    'Invitation Expired'
  ) : (
    <>
      Invitation Pending
      <div>
        <Email>Sent to {row.invitationEmail}</Email>
      </div>
    </>
  );
};

interface SponsoredAccountsTableProps {
  accounts: SponsoredAccount[];
  numPages: number;
  page: number;
  resendInvite: (account: SponsoredAccount) => void;
  removeInvite: (account: SponsoredAccount) => void;
  removeAccount: (account: SponsoredAccount) => void;
  setPage: (page: number) => void;
}

const SponsoredAccountsTable = ({
  accounts,
  numPages,
  setPage,
  page,
  resendInvite,
  removeInvite,
  removeAccount,
}: SponsoredAccountsTableProps) => {
  const columns = [
    {
      accessor: 'accountName',
      label: 'Account Name',
    },
    {
      label: 'Status',
      cellRenderer: statusRenderer,
    },
    {
      label: 'Actions',
      headerStyle: { textAlign: 'center' as const },
      cellRenderer: accountActionsRenderer(resendInvite, removeInvite, removeAccount),
    },
  ];

  return (
    <>
      <StyledTable data={accounts} columns={columns} />
      <Pagination pagesCount={numPages} selectedPage={page} onPageChange={setPage} />
    </>
  );
};

export default SponsoredAccountsTable;

const Email = styled(Caption)`
  color: ${GetColor.HintGrey};
`;

const StyledTable = styled(<T extends BasicTableColumn<K>, K>(props: BasicTableProps<T, K>) => (
  <BasicTable<T, K> {...props} />
))`
  thead tr {
    border-bottom: 1px solid ${GetColor.Black};
  }
  tbody > tr {
    font-size: 14px;
    border-bottom: 1px solid ${GetColor.PaleGrey};
    height: 40px;

    > :last-child {
      display: flex;
      justify-content: center;
    }

    &:hover {
      background-color: ${ColorUtils.hex2rgbaFrom(GetColor.Primary.Main, 0.1)};
      .accounts-menu-trigger {
        color: ${GetColor.Black};
      }
    }
  }
  margin-bottom: 20px;
`;
