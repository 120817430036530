import React from 'react';
import styled from 'styled-components';
import type { ReadonlyPortfolio } from './ReadonlyAllocationTreeItem';
import ReadonlyAllocationTreeItem from './ReadonlyAllocationTreeItem';

interface ReadonlyAllocationTreeProps {
  portfolio: ReadonlyPortfolio;
  itemNameMaxWidth: number;
}

const ReadonlyAllocationTree = ({ portfolio, itemNameMaxWidth }: ReadonlyAllocationTreeProps) => {
  return (
    <TreeContainer>
      <ReadonlyAllocationTreeItem strategy={portfolio} isRoot itemNameMaxWidth={itemNameMaxWidth} />
    </TreeContainer>
  );
};

export default ReadonlyAllocationTree;

const TreeContainer = styled.div`
  margin-top: 16px;
  line-height: normal;
  button {
    pointer-events: none;
  }
`;
