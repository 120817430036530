import React from 'react';
import type { ErrorWrapper } from './errorWrapperTypes';
import { useSelectedMetrics } from '../../logic/useSelectedMetrics';
import { StyledErrorState } from '../StyledErrorState';
import { ComparisonHelpLink } from './shared';
import { useDebugValue } from 'venn-utils';

export const ComparisonMetricsErrorWrapper: ErrorWrapper = ({ children, blockId }) => {
  const selectedMetrics = useSelectedMetrics();
  const forceError = useDebugValue('debugBlockErrorState') === 'ComparisonMetrics';

  if (forceError || selectedMetrics.length <= 0) {
    return (
      <StyledErrorState
        selectedRefId={blockId}
        header="Add metrics in order to compare"
        message={<ComparisonHelpLink />}
      />
    );
  }
  return <>{children}</>;
};
