import React from 'react';
import InfoIcon from './InfoIcon';
import styled from 'styled-components';
import type { StylingProps } from './styles';
import { Container, getInputStyles, getLabelStyles, RequiredSymbol } from './styles';
import type { BaseFormFieldProps } from './types';
import SubText from './SubText';

export interface FormInputProps extends BaseFormFieldProps {
  value?: string;
  type?: string;
  autoComplete?: string;
  onChange?: (value: string) => void;
  inputRef?: (node: HTMLInputElement | null) => void;
  hintAsPlaceholder?: boolean;
}

const FormInput = (props: FormInputProps) => {
  const {
    name,
    label,
    hint,
    hintAsPlaceholder,
    error,
    infoIconText,
    disabled,
    className = '',
    autoComplete = 'off',
    required = false,
    type = 'text',
    inputId,
    value,
    errorHidden,
    onChange,
    onFocus,
    onBlur,
    inputRef,
  } = props;

  const stylingProps: StylingProps = {
    hasValue: Boolean(value),
    hasError: !errorHidden && Boolean(error),
    disabled,
    hasLabel: !!label,
  };

  return (
    <Container>
      <Label {...stylingProps}>
        <Input
          ref={inputRef}
          onFocus={onFocus}
          onBlur={onBlur}
          name={name}
          onChange={(e) => onChange && onChange(e.target.value)}
          disabled={disabled}
          className={className}
          autoComplete={autoComplete}
          value={value ?? ''}
          type={type}
          required={required}
          id={inputId}
          formNoValidate
          {...stylingProps}
          placeholder={hintAsPlaceholder && typeof hint === 'string' ? hint : undefined}
        />
        {label && (
          <label htmlFor={inputId}>
            {label}
            {required && <RequiredSymbol>*</RequiredSymbol>}
          </label>
        )}
        <SubText errorHidden={errorHidden} error={error} hint={!hintAsPlaceholder ? hint : undefined} />
      </Label>
      <InfoIcon text={infoIconText} />
    </Container>
  );
};

export default FormInput;

const Label = styled.div<StylingProps>`
  position: relative;
  width: 100%;
  label {
    ${(props) => getLabelStyles(props)}
  }
`;

const Input = styled.input<StylingProps>`
  ${(props) => getInputStyles(props)}
`;
