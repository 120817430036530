import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { PanelSection } from '../../../PanelSection';
import type { HoldingsCategory, HoldingsCategoryId } from 'venn-api';
import type { DropMenuCheckboxItem, MenuCategory } from 'venn-ui-kit';
import { CategorizedCheckboxDropMenu } from 'venn-ui-kit';
import { allHoldingsCategories, filterHoldingsToLevel, holdingsCategories } from 'venn-state';
import { useRecoilState, useRecoilValue } from 'recoil';
import { compact } from 'lodash';
import { HOLDINGS_TREE_MAX_DEPTH } from 'venn-components';
import type { CustomBlockTypeEnum } from 'venn-utils';

interface HoldingsBlockSectionProps {
  selected: HoldingsCategory[];
  blockType: CustomBlockTypeEnum;
  selectedRefId: string;
}

const getSectionTitle = (blockType: CustomBlockTypeEnum) => {
  switch (blockType) {
    case 'SECTOR_EXPOSURE':
      return 'Sectors';
    case 'ASSET_EXPOSURE':
      return 'Assets';
    case 'GEOGRAPHY_EXPOSURE':
      return 'Regions';
    default:
      throw new Error(`unexpected block type for HoldingsTreeSection: ${blockType}`);
  }
};

const createMenuItem = (
  category: HoldingsCategory,
  depth: number,
  contextualChildItems: string[] | undefined,
  categoryIds: string[],
) => ({
  value: category.id,
  label: category.categoryName,
  level: depth,
  checked: depth !== 0 || categoryIds.includes(category.id.id),
  hideCheckbox: depth !== 0, // only allow users to uncheck parent categories
  contextualChildItems,
});

const HoldingsTreeSection = ({ blockType, selectedRefId }: HoldingsBlockSectionProps) => {
  const allHoldingsCategoryOptions = useRecoilValue(allHoldingsCategories(selectedRefId));
  const [categories, setCategories] = useRecoilState(holdingsCategories(selectedRefId));
  const categoryIds = useMemo(
    () =>
      filterHoldingsToLevel(categories, HOLDINGS_TREE_MAX_DEPTH)
        .flatMap((category) => category.children)
        .map((category) => category.id.id),
    [categories],
  );

  const getCategories = useCallback(
    (category: HoldingsCategory, depth: number): DropMenuCheckboxItem<HoldingsCategoryId>[] => {
      // don't skip top level categories that have no children
      if (category.children.length === 0 && depth === 0) {
        return [createMenuItem(category, depth, undefined, categoryIds)];
      }

      // anything else without children is a sub-tree or leaf node which we can skip
      if (category.children.length === 0 && category.id.id !== 'UNKNOWN') {
        return [];
      }

      const contextualChildItems: string[] = [];
      const childCategories =
        blockType === 'GEOGRAPHY_EXPOSURE'
          ? []
          : category.children?.flatMap((child) => {
              contextualChildItems.push(child.categoryName);
              return getCategories(child, depth + 1);
            });

      const menuItem = createMenuItem(category, depth, contextualChildItems, categoryIds);

      return [menuItem, ...childCategories];
    },
    [blockType, categoryIds],
  );

  const selectedCategories = useMemo(
    () =>
      filterHoldingsToLevel(allHoldingsCategoryOptions, 3).flatMap((c) => ({
        name: c.categoryName,
        value: c.id,
        items: compact(c.children.flatMap((c) => getCategories(c, 0))),
      })),
    [allHoldingsCategoryOptions, getCategories],
  );

  const setSelectedCategories = (
    selection: MenuCategory<HoldingsCategoryId, DropMenuCheckboxItem<HoldingsCategoryId>>[],
  ) => {
    const mapped = compact([
      ...selection.flatMap((parent) =>
        parent.items.map((category) =>
          category.checked
            ? allHoldingsCategoryOptions
                .flatMap((category) => category.children)
                .find((item) => item.id === category.value)
            : undefined,
        ),
      ),
    ]);
    setCategories(mapped);
  };

  return (
    <PanelSection header={getSectionTitle(blockType)} className="qa-holdings-settings">
      <Container>
        <CategorizedCheckboxDropMenu<HoldingsCategoryId>
          categories={selectedCategories}
          onChange={setSelectedCategories}
          width={250}
          disabled={blockType === 'GEOGRAPHY_EXPOSURE'}
        />
      </Container>
    </PanelSection>
  );
};

export default React.memo(HoldingsTreeSection);

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
