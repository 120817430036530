import React from 'react';
import styled from 'styled-components';
import type { DropMenuCheckboxItem, MenuContainerProps } from '../types';
import BaseMenu from './BaseMenu';
import CheckboxMenuItem from './CheckboxMenuItem';

export interface JumboCheckboxMenuProps<T = string> extends MenuContainerProps {
  /**
   * Array of dropmenu items.
   */
  items: DropMenuCheckboxItem<T>[];
  /**
   * On each change, fires and returns the updated item
   */
  onChange: (item: DropMenuCheckboxItem<T>) => void;
}

class JumboCheckboxMenu<T> extends React.PureComponent<JumboCheckboxMenuProps<T>> {
  onCheckOne = (item: DropMenuCheckboxItem<T>) => () => {
    this.props.onChange(item);
  };

  render() {
    const { items, width, height } = this.props;
    return (
      <BaseMenu<T, DropMenuCheckboxItem<T>>
        items={items}
        width={width}
        height={height || 300}
        renderItem={(item, index) => (
          <CheckBoxMenuPadding key={index}>
            <CheckboxMenuItem<T> item={item} onCheck={this.onCheckOne(item)} />
          </CheckBoxMenuPadding>
        )}
      />
    );
  }
}

const CheckBoxMenuPadding = styled.div`
  padding: 3px 0;
`;

export default JumboCheckboxMenu;
