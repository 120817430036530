import type { RangeType } from 'venn-ui-kit';
import moment from 'moment';
import type { CustomizedBlock, AnalysisSubjectTypeEnum, AnalysisSubject as ApiAnalysisSubject } from 'venn-api';
import { pickBy, isNil, isEqual } from 'lodash';
import type AnalysisSubject from './AnalysisSubject';

/** Compare if two range type has difference */
export const isEqualWithFullPeriodAmendment = (a?: RangeType, b?: RangeType) => {
  const isAFull = a === 'full' || isNil(a);
  const isBFull = b === 'full' || isNil(b);
  return isAFull && isBFull ? true : a === b;
};

/** If one is null and another is undefine, they are equal. so we only compare when at least one of them have value. */
export const ignoreEmptyEqual = <T>(a: T, b: T) => !((a || b) && a !== b);

export const compareDateEqual = (a?: number, b?: number) => {
  // Both of them empty will be equal
  if (!a && !b) {
    return true;
  }
  return moment.utc(a).isSame(moment.utc(b), 'day');
};

const getNotEmptyField = (obj?: CustomizedBlock | null) =>
  pickBy(obj, (val) => val !== undefined && val !== null && val !== false);

export const compareSelectedBlock = (a?: CustomizedBlock | null, b?: CustomizedBlock | null) => {
  const cleanA = getNotEmptyField(a);
  const cleanB = getNotEmptyField(b);
  return isEqual(cleanA, cleanB);
};

export const getApiSubjectsFromAnalysisSubjects = (subjects: AnalysisSubject[]) =>
  subjects.map((s, index) => ({
    comparisonType: index === 0 ? 'PRIMARY' : 'COMPARISON',
    id: String(s.id),
    primary: index === 0,
    subjectType: s.type.toUpperCase() as AnalysisSubjectTypeEnum,
  })) as ApiAnalysisSubject[];

export const getApiBenchmarkSubjectFromAnalysisSubject = (subject: AnalysisSubject) =>
  ({
    comparisonType: 'BENCHMARK',
    id: String(subject.id),
    primary: false,
    subjectType: subject.type.toUpperCase() as AnalysisSubjectTypeEnum,
  }) as ApiAnalysisSubject;
