import type { GeneralAnalysisTemplate, AnalysisBlockTypeEnum, AnalysisBlock, AppUser } from 'venn-api';
import profile from './profile';
import { getAppTitleSimplifiedUtil } from '../../utils';

const { user: appUser } = profile;

const createAnalysisBlock = (analysisBlockType: AnalysisBlockTypeEnum, name?: string) =>
  ({
    analysisBlockType,
    analysisList: [],
    created: Date.now(),
    description: analysisBlockType,
    relativeToPortfolio: false,
    title: name || '',
    pricingFeatures: [],
    content: '',
  }) as AnalysisBlock;

const vennAppUser: AppUser = {
  displayName: `${getAppTitleSimplifiedUtil()}`,
  active: true,
  id: -42,
  name: `${getAppTitleSimplifiedUtil()}`,
  actualUser: false,
  accountId: 1,
};
const mocktemplates: GeneralAnalysisTemplate[] = [
  {
    id: '6a386363-d7f2-4f27-8403-808f79558e37',
    name: 'All the analyses template',
    description: '',
    analysisBlocks: [createAnalysisBlock('PERFORMANCE_SUMMARY'), createAnalysisBlock('CUMULATIVE_RETURN')],
    appUser,
    created: 1566321141993,
    updated: 1566850169657,
    analysisTemplateType: 'CUSTOM',
  },
  {
    id: 'cd8df557-a689-415c-92e4-7eb8df9c2a4a',
    name: "Antoine's template",
    description: '',
    analysisBlocks: [createAnalysisBlock('PERFORMANCE_SUMMARY')],
    appUser,
    created: 1567432688872,
    updated: 1567432688873,
    analysisTemplateType: 'CUSTOM',
  },
  {
    id: '78325c4d-a34a-4387-9522-9f7bb0ff197e',
    name: 'Everything Template',
    description: "LET'S GO CRAZY",
    analysisBlocks: [
      createAnalysisBlock('PERFORMANCE_SUMMARY'),
      createAnalysisBlock('FACTOR_CONTRIBUTION_TO_EXPOSURE'),
    ],
    appUser,
    created: 1562927499520,
    updated: 1573234895080,
    analysisTemplateType: 'CUSTOM',
  },
  {
    id: 'performance',
    name: 'Performance Analysis',
    description: "LET'S GO CRAZY",
    analysisBlocks: [createAnalysisBlock('PERFORMANCE_SUMMARY')],
    appUser: vennAppUser,
    created: 1562927499520,
    updated: 1573234895080,
    analysisTemplateType: 'VENN',
  },
  {
    id: 'factor',
    name: 'Factor Analysis',
    description: 'Factor Analysis',
    analysisBlocks: [createAnalysisBlock('FACTOR_CONTRIBUTION_TO_RISK_TREND')],
    appUser: vennAppUser,
    created: 1562927499520,
    updated: 1573234895080,
    analysisTemplateType: 'VENN',
  },
  {
    id: 'PERFORMANCE_SUMMARY',
    name: 'Performance Summary',
    description: 'Block template',
    analysisBlocks: [createAnalysisBlock('PERFORMANCE_SUMMARY', 'Performance Summary')],
    appUser,
    created: 1562927499520,
    updated: 1573234895080,
    analysisTemplateType: 'BLOCK',
  },
  {
    id: 'CUMULATIVE_RETURN',
    name: 'Cumulative Return',
    description: 'Block template',
    analysisBlocks: [createAnalysisBlock('CUMULATIVE_RETURN', 'Cumulative Return')],
    appUser,
    created: 1562927499520,
    updated: 1573234895080,
    analysisTemplateType: 'BLOCK',
  },
];

export default mocktemplates;
