import React from 'react';
import styled from 'styled-components';
import { SORTDIR, InitialAvatar } from 'venn-components';
import {
  Tooltip,
  EllipsisTooltipSpan,
  GetColor,
  Hint,
  Caption,
  Icon,
  WORKSPACE_MANAGEMENT_ARTICLE_HREF,
} from 'venn-ui-kit';
import type { WorkspaceMember, UserStatusEnum, MemberSortEnum } from 'venn-api';
import { Dates, isInvitationExpired } from 'venn-utils';
import { compact } from 'lodash';
import MemberActionsMenu from './MemberActionsMenu';
import UserRoleInOrganizationMenu from './UserRoleInOrganizationMenu';
import RoleInOrganizationOptionsContextProvider from './UserRoleInOrganizationOptionsProvider';
import { StyledTable } from '../shared';

const MFA_BADGE_TOOLTIP = 'mfa-badge-tooltip';
const AVATAR_SIZE = 30;

interface MembersTableProps {
  sortField: MemberSortEnum;
  descending: boolean;
  members: WorkspaceMember[];
  adminEmail?: string;
  onSort: (key: MemberSortEnum, dir: SORTDIR) => void;
  refreshSearch: () => void;
  readOnly?: boolean;
}

const MembersTable = ({
  adminEmail,
  descending,
  members,
  onSort,
  sortField,
  refreshSearch,
  readOnly,
}: MembersTableProps) => {
  const getColumns = () => {
    const direction = descending ? SORTDIR.DESC : SORTDIR.ASC;
    return compact([
      {
        label: 'Name',
        accessor: 'NAME',
        sortable: true,
        sorted: sortField === 'NAME' ? direction : undefined,
        sortingIsExternal: true,
        cellRenderer: ({ name, email, avatarId, status, created }: WorkspaceMember) => {
          return (
            <MainCellWrapper>
              <InitialAvatar
                className="avatar"
                avatarId={avatarId}
                displayName={avatarId ? undefined : email}
                width={AVATAR_SIZE}
                inactive={notActive(created, status)}
              />
              <NameAndEmail>
                <Name className="qa-member-name">
                  <EllipsisTooltipSpan maxWidth={400}>
                    {email && email === adminEmail ? <Italic>{name || '--'} (admin)</Italic> : name || '--'}
                  </EllipsisTooltipSpan>
                </Name>
                <div>
                  <Hint>
                    <EllipsisTooltipSpan maxWidth={400}>{email ?? '--'}</EllipsisTooltipSpan>
                  </Hint>
                </div>
              </NameAndEmail>
            </MainCellWrapper>
          );
        },
      },
      {
        label: 'Security',
        accessor: 'SECURITY',
        sortable: false,
        headerStyle: {
          paddingRight: 4,
        },
        cellStyle: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          maxWidth: 51,
          height: 38,
        },
        cellRenderer: ({ mfaEnabled, status, created }: WorkspaceMember) =>
          notActive(created, status) ? (
            <div />
          ) : (
            <div>
              <Tooltip
                content={`This member ${
                  mfaEnabled ? 'has' : "hasn't"
                } turned on Multi-Factor Authentication on their account`}
                className={MFA_BADGE_TOOLTIP}
              >
                <MFAIcon className={mfaEnabled ? 'icon-locked-xs' : 'icon-unlocked-xs'} mfaEnabled={mfaEnabled} />
              </Tooltip>
            </div>
          ),
      },
      {
        label: 'Account Status',
        className: 'qa-account-status',
        accessor: 'STATUS',
        sortable: true,
        sorted: sortField === 'STATUS' ? direction : undefined,
        sortingIsExternal: true,
        cellRenderer: ({ created, status, invitationExpiration }: WorkspaceMember) => {
          return getStatus(created, status, invitationExpiration);
        },
      },
      {
        label: 'Role',
        accessor: 'ROLE',
        sortable: false,
        cellRenderer: (member: WorkspaceMember) => {
          const { created, status } = member;
          if (notActive(created, status)) {
            return null;
          }
          return <UserRoleInOrganizationMenu member={member} onUpdateRole={refreshSearch} readOnly={readOnly} />;
        },
        headerRenderer: (label: string) => (
          <HelpLink target="_blank" rel="noopener noreferrer" href={WORKSPACE_MANAGEMENT_ARTICLE_HREF}>
            {label} <Icon type="question-circle" />
          </HelpLink>
        ),
      },
      readOnly
        ? null
        : {
            label: 'Actions',
            accessor: 'ACTIONS',
            sortable: false,
            cellStyle: {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 4,
            },
            headerStyle: { textAlign: 'center' as const },
            cellRenderer: (member: WorkspaceMember) => (
              <MemberActionsMenu member={member} onChangeMembers={refreshSearch} />
            ),
          },
    ]);
  };

  return (
    <Content>
      <RoleInOrganizationOptionsContextProvider>
        <StyledTable columns={getColumns()} data={members} onSort={onSort} />
      </RoleInOrganizationOptionsContextProvider>
    </Content>
  );
};

export default MembersTable;

function notActive(created?: number, status?: UserStatusEnum): boolean {
  return !created || status === 'INACTIVE';
}

function getStatus(created?: number, status?: UserStatusEnum, invitationExpiration?: number): React.ReactNode {
  if (!created) {
    return isInvitationExpired(invitationExpiration) ? 'Invite Expired' : 'Invited';
  }
  return status === 'INACTIVE' ? (
    'Deactivated'
  ) : (
    <>
      Active <Since>since {Dates.toDayMonthYear(created)}</Since>
    </>
  );
}

const HelpLink = styled.a`
  color: ${GetColor.Black};
`;

const Content = styled.div`
  padding-top: 30px;
`;

const Italic = styled.span`
  font-style: italic;
`;

const MFAIcon = styled.div<{ mfaEnabled?: boolean }>`
  color: ${({ mfaEnabled }) => (mfaEnabled ? GetColor.Primary.Main : GetColor.DEPRECATED_DivergingColor.A3)};
`;

const MainCellWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Name = styled.div`
  font-weight: bold;
  height: 14px;
  max-height: 14px;
  margin-bottom: 1px;
`;

const NameAndEmail = styled.div`
  padding-left: 8px;
  padding-top: 3px;
`;

const Since = styled(Caption)`
  color: ${GetColor.HintGrey};
`;
