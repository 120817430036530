import {
  blockLimitedHoldingsSubjectsWithFees,
  blockSettings,
  convertStudioSubjectToHoldingsSubject,
  holdingsCategories,
  convertHoldingsBlockTypeToBreakdown,
} from 'venn-state';
import { logExceptionIntoSentry, useQuery } from 'venn-utils';
import { useRecoilValue } from 'recoil';
import type { HoldingsDataRequest, HoldingsDataResponse, HoldingsSubject } from 'venn-api';
import { getHoldingsBreakdown } from 'venn-api';
import type { CustomBlockTypeEnum } from 'venn-utils';

const HOLDINGS_CACHED_KEY = 'holdings_cached';
const ONE_HOUR = 1000 * 60 * 60;

export const useHoldings = (id: string) => {
  const allSubjects = useRecoilValue(blockLimitedHoldingsSubjectsWithFees(id));
  const settings = useRecoilValue(blockSettings(id));
  const blockType = settings.customBlockType;
  const categories = useRecoilValue(holdingsCategories(id));

  // holdingsSubjects doesn't need memo since useQuery uses deep equality anyway
  const holdingsSubjects = allSubjects.map(convertStudioSubjectToHoldingsSubject);

  const holdingsFetchingFunction = async (
    blockType: CustomBlockTypeEnum,
    holdingsSubjects: HoldingsSubject[],
  ): Promise<HoldingsDataResponse> => {
    const breakdownType = convertHoldingsBlockTypeToBreakdown(blockType);
    const request: HoldingsDataRequest = {
      breakdownType,
      subjects: holdingsSubjects,
    };

    const { content } = await getHoldingsBreakdown(request);
    return content;
  };

  const { data } = useQuery<HoldingsDataResponse>(
    [HOLDINGS_CACHED_KEY, blockType, holdingsSubjects],
    () => {
      return holdingsFetchingFunction(blockType, holdingsSubjects);
    },
    {
      onError: (error) => {
        logExceptionIntoSentry(error as Error);
      },
      suspense: true,
      staleTime: ONE_HOUR,
    },
  );

  return { data, categories };
};
