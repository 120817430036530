import { useMemo } from 'react';

/**
 * Only recompute the memoized value that `func` returns when one of its `args` or any `extraDeps` have changed.
 *
 * The `extraDeps` rest parameter can be used for any other dependencies that you want to cause recalculation.
 * If calling `useMemoWithArgs` with a referentially unstable function, you can also pass the function as an
 * extra dependency to cause recalculation when the function changes.
 *
 * Examples:
 * ```ts
 * function Component () {
 *   const arg1 = ...
 *   const arg2 = ...
 *   // These two are equivalent
 *   const useMemoResult = useMemo(() => expensiveFn(arg1, arg2, arg3, arg4)), [arg1, arg2, arg3, arg4])
 *   const useMemoArgsResult1 = useMemoArgs(expensive, [arg1, arg2, arg3, arg4])
 *   // This handles a referentially unstable function
 *   const useMemoArgsResult2 = useMemoArgs(expensiveUnstableFn, [arg1, arg2, arg3, arg4], [expensiveUnstableFn])
 *
 *   return ...
 * }
 * ```
 *
 * @see https://reactjs.org/docs/hooks-reference.html#usememo
 */
export function useMemoWithArgs<A extends unknown[], R>(
  func: (...args: A) => R,
  args: A,
  extraDeps?: readonly unknown[],
): R {
  const deps = extraDeps ? [...args, ...extraDeps] : args;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => func(...args), deps);
}
