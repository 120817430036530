import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import ErrorEditor from './ErrorEditor';
import type { DataCell } from '../../types';
import { Icon, GetColor, getAppTitle } from 'venn-ui-kit';

interface ErrorEditorRowProps {
  /**
   * Cell to edit
   */
  cell: DataCell;
  date: string;
  valid?: boolean;
  errors: string[];
  /**
   * Returns the updated value
   */
  onChange: (value: string) => void;
  onBlur: () => void;
  isPrice: boolean;
}

export const ErrorEditorRow: FC<React.PropsWithChildren<ErrorEditorRowProps>> = (props) => {
  const { errors, valid, cell, isPrice } = props;
  return (
    <Container>
      <Description valid={!!valid}>
        <Icon type={valid ? 'check-circle' : 'circle'} />
        &nbsp;
        {getDescription(errors)}
        {isPrice && Number(cell.value) < 0 && ` – ${getAppTitle()} does not support negative prices`}
      </Description>
      <ErrorEditor {...props} />
    </Container>
  );
};

const getDescription = (errors: string[]) => errors.join(' / ');

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  height: 60px;
  border-bottom: 1px solid ${GetColor.PaleGrey};
`;

interface DescriptionProps {
  valid: boolean;
}

const Description = styled.div<DescriptionProps>`
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => (props.valid ? GetColor.Black : GetColor.Error)};

  i {
    font-size: 12px;
    margin-right: 5px;
    position: relative;
    top: -1px;
    color: ${(props) => (props.valid ? GetColor.Primary.Main : GetColor.Error)};
  }
`;

export default ErrorEditorRow;
