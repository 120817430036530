import type { FC } from 'react';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import type { AnalysesPeriod, AnalysisSubjectSecondaryLabel, AnalysisSubjectType, TimeFrame } from 'venn-utils';
import {
  AnalysisSubject,
  analyticsService,
  logMessageToSentry,
  selectStrategy,
  updateAllFunds,
  navigateToTemplate,
} from 'venn-utils';
import type { Fund, Portfolio, PortfolioCompare } from 'venn-api';
import { getFund, viewEntity } from 'venn-api';
import type { RangeType } from 'venn-ui-kit';
import {
  FactorLensesContext,
  getBlockTitle,
  TreeItemUpdateType,
  UnsavedChangesModal,
  formatDateRangeForTracking,
} from 'venn-components';
import type { AnalysisPageProps } from '../utils';
import { getParam, updateChildNodeWithBenchmarks } from '../utils';
import useNavigation from '../logic/useNavigation';
import useAnalysis from '../logic/useAnalysis';
import useAnalysisConfig from '../logic/useAnalysisConfig';
import Workspace from './workspace/Workspace';
import { getTemplateBlocksForTracking } from '../logic/useTrackFailedAnalysis';
import { isNil } from 'lodash';
import AnalysisConfigStore from '../logic/AnalysisConfigStore';

const AnalysisPage: FC<React.PropsWithChildren<AnalysisPageProps>> = (props) => {
  const { history, templates } = props;
  const { factorLenses } = useContext(FactorLensesContext);

  // TODO: (VENN-20577 / TYPES) these assertions are incorrect/unsafe in that the values can actually be undefined.
  const objectType = getParam(props, 'objectType', false)!;
  const objectId = getParam(props, 'objectId', false)!;

  const {
    analysisConfig,
    fetchObjectForBenchmarksUpdateOnly,
    onChangeAnalysisTemplate,
    onChangeSubject,
    onUpdateSubject,
    loadingSubject,
    setTimeFrame,
    setCategoryConfig,
    toggleRelative,
    canToggleRelative,
    updateAnalysisStatusForTracking,
  } = useAnalysisConfig(props, templates);
  const subject = analysisConfig.subject;

  const blocksForTracking = useMemo(
    () => getTemplateBlocksForTracking(analysisConfig.analysisTemplate),
    [analysisConfig.analysisTemplate],
  );

  useNavigation(analysisConfig, history);

  const { analysesError, analysesResults, loadingAnalysis, actualTimeFrame } = useAnalysis(analysisConfig);

  /* Tracking for when all analyses failed */
  const prevAnalysesErrorRef = useRef(analysesError);
  useEffect(() => {
    if (analysesError && prevAnalysesErrorRef.current !== analysesError) {
      updateAnalysisStatusForTracking(
        blocksForTracking.map((blockName) => ({
          name: blockName,
          title: getBlockTitle(blockName, analysisConfig?.relative),
          succeeded: false,
          analysisType: analysisConfig?.analysisTemplate?.name || '',
          errorCode: analysesError.code,
          analysesPeriod: analysesResults?.analysesPeriod,
        })),
        analysisConfig.trackingId,
        !isNil(actualTimeFrame.startTime) && !isNil(actualTimeFrame.endTime)
          ? formatDateRangeForTracking(actualTimeFrame.startTime, actualTimeFrame.endTime)
          : undefined,
      );
    }
  }, [
    analysesError,
    analysesResults,
    analysisConfig,
    blocksForTracking,
    actualTimeFrame,
    updateAnalysisStatusForTracking,
  ]);
  useEffect(() => {
    prevAnalysesErrorRef.current = analysesError;
  }, [analysesError]);
  /* End block: tracking for when all analyses failed */

  const onResetTimeFrame = useCallback(() => {
    setTimeFrame({}, 'full');
  }, [setTimeFrame]);

  const setSafeTimeFrame = useCallback(
    (timeFrame: TimeFrame, period?: RangeType) => {
      if (!analysesResults || !analysesResults.analysesPeriod) {
        setTimeFrame(timeFrame, period);
        return;
      }
      if (period) {
        // clear startTime and endTime when select period
        setTimeFrame({}, period);
        return;
      }

      const { maxStartTime, maxEndTime } = analysesResults.analysesPeriod;
      const startTime = getInrangeTime(timeFrame.startTime, maxStartTime, maxEndTime);
      const endTime = getInrangeTime(timeFrame.endTime, maxStartTime, maxEndTime);
      setTimeFrame(
        {
          startTime,
          endTime,
        },
        period,
      );
    },
    [analysesResults, setTimeFrame],
  );

  const onForecastUpdate = useCallback(() => {
    if (!subject) {
      logMessageToSentry('Failed to update subject for default forecast');
      return;
    }
    onUpdateSubject(
      new AnalysisSubject(subject.superItem, subject.superType, {
        strategyId: subject.strategyId,
        strategyFund: subject.isInvestmentInPortfolio ? subject.fund : undefined,
        secondaryPortfolio: subject.secondaryPortfolio,
        secondaryLabel: subject.secondaryLabel,
        categoryPrediction: subject.categoryPrediction,
      }),
    );
  }, [onUpdateSubject, subject]);

  const loading = loadingAnalysis || loadingSubject || !subject;

  const [hasAllocationError, setAllocationError] = useState(false);
  const [allUpdatedFunds, setAllUpdatedFunds] = useState<Map<string, Fund>>(new Map());

  const onUpdateAllFunds = useCallback(
    async (fundOrFunds: Fund | Fund[], preventSubjectUpdate?: boolean) => {
      const allFunds = new Map(allUpdatedFunds);

      const funds: Fund[] = Array.isArray(fundOrFunds) ? fundOrFunds : [fundOrFunds];
      funds.forEach((fund: Fund) => allFunds.set(fund.id, fund));
      setAllUpdatedFunds(allFunds);

      if (!subject || preventSubjectUpdate || funds.length !== 1) {
        return;
      }
      await onUpdateSubject(
        new AnalysisSubject(
          getSubjectItemWithUpdatedFunds(subject.superItem as Portfolio | Fund, subject.superType, allFunds),
          subject.superType,
          {
            strategyId: subject.strategyId,
            strategyFund: subject.type === 'investment' ? funds[0] : undefined,
            secondaryPortfolio: subject.secondaryPortfolio,
            secondaryLabel: subject.secondaryLabel,
          },
        ),
      );
    },
    [allUpdatedFunds, onUpdateSubject, subject],
  );

  const getUpdatedFund = useCallback(
    async (fundId: string, forceUpdate?: boolean): Promise<Fund | undefined> => {
      // Try to read the fund from locally saved map of funds. If it's not present, fetch it and save it so it's
      // easily available for the next time it's selected.
      if (!forceUpdate && allUpdatedFunds.has(fundId)) {
        return allUpdatedFunds.get(fundId);
      }

      try {
        const fund = (await getFund(fundId)).content;
        onUpdateAllFunds(fund, true);
        return fund;
      } catch (e) {
        return undefined;
      }
    },
    [allUpdatedFunds, onUpdateAllFunds],
  );

  // Map from portfolio id to benchmarks, as updated by the benchmarks selector in the analysis content header.
  // If the entry is undefined, it means that the benchmark for this strategy hasn't been modified by the selector,
  // and therefore it's safe to use the portfolio's compare field as the true source of benchmarks for this node.
  const [benchmarksMap, setBenchmarksMap] = useState<Map<number, PortfolioCompare[]>>(new Map());

  const onBenchmarksUpdate = useCallback(
    async (compare: PortfolioCompare[]) => {
      if (subject?.type === 'portfolio') {
        const updatedBenchmarksMap = new Map(benchmarksMap);
        updatedBenchmarksMap.set(subject.strategyId || (subject.id as number), compare);
        setBenchmarksMap(updatedBenchmarksMap);
      } else if (subject?.superType === 'portfolio' && subject?.type === 'investment') {
        getUpdatedFund(subject.fund!.id, true);
      }
      await fetchObjectForBenchmarksUpdateOnly();
    },
    [subject, fetchObjectForBenchmarksUpdateOnly, benchmarksMap, getUpdatedFund],
  );

  const onPortfolioChange = useCallback(
    (portfolio: Portfolio, updateType: TreeItemUpdateType) => {
      if (subject) {
        // We don't refresh benchmarks in AP nodes when they change in the benchmark selector. Therefore, we should
        // ignore benchmarks coming from the AP's updated portfolio, and preserve the ones our current subject has.
        const selectedStrategyId =
          subject.strategyId && selectStrategy(subject.strategyId, portfolio) != null
            ? subject.strategyId
            : portfolio.id;
        const benchmarks =
          benchmarksMap.get(selectedStrategyId) ||
          selectStrategy(selectedStrategyId, subject.portfolio ?? null)?.compare;
        const newPortfolio = updateChildNodeWithBenchmarks(portfolio, selectedStrategyId, benchmarks);
        const templateRelativeToPortfolio = analysisConfig?.analysisTemplate?.analysisBlocks
          .map((block) => block.relativeToPortfolio)
          .reduce((a, b) => a || b);
        // Update subject, but don't refresh analyses in the scenarios listed below. That's because we consider
        // these changes non-meaningful, as they wouldn't affect analysis results in any way.
        const preventAnalysisRefresh =
          updateType === TreeItemUpdateType.ADD_STRATEGY ||
          (updateType === TreeItemUpdateType.ADD_FUND && !templateRelativeToPortfolio) ||
          updateType === TreeItemUpdateType.DRAG_N_DROP_REORDER_ONLY;
        const updatedSelectedStrategyId =
          updateType === TreeItemUpdateType.CHANGE_ALLOCATION && subject.isInvestmentInPortfolio
            ? newPortfolio.id
            : subject.strategyId;

        onUpdateSubject(
          new AnalysisSubject(getSubjectItemWithUpdatedFunds(newPortfolio, 'portfolio', allUpdatedFunds), 'portfolio', {
            strategyId: updatedSelectedStrategyId,
            strategyFund: subject.fund,
            secondaryPortfolio: subject.secondaryPortfolio,
            secondaryLabel: subject.secondaryLabel,
            categoryPrediction: subject.categoryPrediction,
          }),
          preventAnalysisRefresh,
        );
      }
    },
    [subject, benchmarksMap, analysisConfig, onUpdateSubject, allUpdatedFunds],
  );

  const onPortfolioCreate = useCallback(
    (portfolio: Portfolio) => {
      UnsavedChangesModal.unblockHistory();
      onChangeSubject(
        new AnalysisSubject(getSubjectItemWithUpdatedFunds(portfolio, 'portfolio', allUpdatedFunds), 'portfolio'),
      );
    },
    [allUpdatedFunds, onChangeSubject],
  );

  const trackNavigation = useCallback(() => {
    analyticsService.navigationTriggered({
      location: 'allocator panel',
      itemType: 'selector',
      userIntent: 'change object',
      destinationPageTitle: 'Analysis Results',
    });
  }, []);

  const onStrategyChangeNewFlow = useCallback(
    async (
      selected: Portfolio,
      _?: number,
      secondaryStrategy?: Portfolio,
      secondaryLabel?: AnalysisSubjectSecondaryLabel,
    ) => {
      if (!subject) {
        logMessageToSentry('Failed to change strategy when missing subject');
        return;
      }
      if (!isNil(selected) && !isNil(selected?.fund) && !isNil(selected?.fund.id)) {
        // User selected a fund for analysis in the Allocation Panel. We need to go into "portfolio-but-actually-fund"
        // analysis mode. AnalysisSubject will need to have both `_portfolio` and `_fund` objects set, and show the
        // Allocation Panel in "portfolio" mode, but the Analysis in "investment" mode.
        try {
          const fund = await getUpdatedFund(selected.fund.id);
          // Track when users analyse investments in portfolio
          viewEntity(selected.fund.id);
          onUpdateSubject(
            new AnalysisSubject(subject.superItem, subject.superType, {
              strategyId: selected.id,
              strategyFund: fund,
            }),
          );
          trackNavigation();
          return;
        } catch (e) {
          // Fetching the fund failed. Don't let this fund be selected.
          return;
        }
      }
      trackNavigation();
      // We don't refresh benchmarks in AP nodes when they change in the benchmark selector. Therefore, when the
      // selected strategy is updated, we should check if we have a more current source of benchmarks for that strategy
      // (namely, `benchmarksMap`).
      const benchmarks = benchmarksMap.get(selected.id) || selected.compare;
      const withUpdatedBenchmarks = updateChildNodeWithBenchmarks(
        subject.superItem as Portfolio,
        selected.id,
        benchmarks,
      );
      onUpdateSubject(
        new AnalysisSubject(withUpdatedBenchmarks, subject.superType, {
          strategyId: selected.id,
          secondaryPortfolio: secondaryStrategy,
          secondaryLabel,
        }),
      );
    },
    [trackNavigation, benchmarksMap, subject, onUpdateSubject, getUpdatedFund],
  );

  const onComparisonChange = useCallback(
    (secondaryPortfolio?: Portfolio, secondaryLabel?: AnalysisSubjectSecondaryLabel) => {
      if (!subject) {
        logMessageToSentry('Failed to change comparison when missing subject');
        return;
      }
      // We only support secondary subject for portfolio analysis
      if (subject.type === 'portfolio') {
        onUpdateSubject(
          new AnalysisSubject(subject.superItem, subject.superType, {
            strategyId: subject.strategyId,
            strategyFund: subject.fund,
            secondaryPortfolio,
            secondaryLabel,
          }),
        );
      }
    },
    [onUpdateSubject, subject],
  );
  const defaultAnalysesPeriod: AnalysesPeriod = analysesResults?.analysesPeriod ?? { frequency: 'MONTHLY' };
  const templateId = analysisConfig?.analysisTemplate?.id;

  const onEditTemplate = useCallback((id: string) => navigateToTemplate(history, id), [history]);
  const analysisWorksPaceProps = {
    history,
    /** Template */
    templateId: templateId || '',
    templates,
    onChangeAnalysisTemplate,
    onEditTemplate,
    /** Subject */
    objectId,
    objectType,
    loadingSubject,
    onChangeSubject,
    analysisConfig,
    /** Analyses */
    analysisLoading: loading,
    analyses: analysesResults,
    analysesError,
    updateAnalysisStatusForTracking,
    /** Portfolio */
    hasAllocationError,
    onUpdateAllocationError: setAllocationError,
    allUpdatedFunds,
    onStrategyChange: onStrategyChangeNewFlow,
    onPortfolioChange,
    onComparisonChange,
    onPortfolioCreate,
    /** Funds */
    updateFund: getUpdatedFund,
    onFundUpdated: onUpdateAllFunds,
    updateAllFunds: onUpdateAllFunds,
    /** Parameters */
    onToggleRelative: toggleRelative,
    canToggleRelative: !!canToggleRelative,
    setTimeFrame: setSafeTimeFrame,
    onResetTimeFrame,
    defaultAnalysesPeriod,
    setCategoryConfig,
    factorLenses,
    onForecastUpdate,
    onBenchmarksUpdate,
  };

  return (
    <AnalysisConfigStore actualTimeFrame={actualTimeFrame}>
      <Workspace {...analysisWorksPaceProps} />
    </AnalysisConfigStore>
  );
};

export default AnalysisPage;

const getInrangeTime = (time?: number, maxStartTime?: number, maxEndTime?: number): number | undefined => {
  if (!time) {
    return time;
  }
  if (maxStartTime && time < maxStartTime) {
    return maxStartTime;
  }
  if (maxEndTime && time > maxEndTime) {
    return maxEndTime;
  }
  return time;
};
/**
 * Use whenever `item` is coming from a potentially not-up-to-date source, i.e.
 *   - Allocation Panel may have OUTDATED structure
 * Current subject should have an up-to-date structure, unless we have just updated the funds.
 */
const getSubjectItemWithUpdatedFunds = (
  item: Fund | Portfolio,
  itemType: AnalysisSubjectType,
  allUpdatedFunds: Map<string, Fund>,
): Fund | Portfolio => {
  if (itemType === 'investment') {
    return allUpdatedFunds.get((item as Fund).id) || item;
  }
  return updateAllFunds(item as Portfolio, allUpdatedFunds);
};
