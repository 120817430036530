import React from 'react';
import type { TableRowMenuOption } from 'venn-ui-kit';
import { TableRowOptionsMenu, Tooltip } from 'venn-ui-kit';
import styled from 'styled-components';
import { compact } from 'lodash';
import type { AnalysisViewSearchResultWithUIState } from 'venn-utils';
import { getLaunchText } from '../utils/getLaunchText';
import { useHasFF } from 'venn-utils';

interface SavedViewActionsProps {
  canEditTemplate?: boolean;
  canDelete?: boolean;
  savedView: AnalysisViewSearchResultWithUIState;
  onDelete: () => void;
  onCancelScheduledExport: () => void;
  onEditTemplate: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onLaunch: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

type EnabledOptions = 'delete' | 'launch' | 'editTemplate' | 'cancelScheduledExport';

export const SavedViewActions = ({
  savedView,
  onDelete,
  onEditTemplate,
  onLaunch,
  onCancelScheduledExport,
  canEditTemplate,
  canDelete,
}: SavedViewActionsProps) => {
  const allOptions: Record<EnabledOptions, TableRowMenuOption> = {
    delete: {
      label: 'Delete',
      onClick: () => onDelete(),
      disabled: !canDelete,
      renderWrapper: (item) => (
        <Tip
          isHidden={!savedView.vennTemplate}
          usePortal
          block
          content="You do not have permission to perform this action"
        >
          {item}
        </Tip>
      ),
    },
    launch: {
      label: getLaunchText(savedView.analysisViewType) || 'Launch',
      onClick: (e) => onLaunch(e),
    },
    editTemplate: {
      label: 'Edit template',
      onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onEditTemplate(e),
    },
    cancelScheduledExport: {
      label: 'Cancel scheduled export',
      onClick: () => onCancelScheduledExport(),
    },
  };

  const hasScheduledExport =
    !useHasFF('disable_exports_ff') && savedView.scheduledFrequency && savedView.scheduledFrequency !== 'UNKNOWN';

  const options: TableRowMenuOption[] = compact([
    allOptions.delete,
    allOptions.launch,
    canEditTemplate && allOptions.editTemplate,
    hasScheduledExport && allOptions.cancelScheduledExport,
  ]);

  return <TableRowOptionsMenu menuClassName="saved-view-actions" options={options} />;
};

const Tip = styled(Tooltip)`
  height: 100%;
`;
