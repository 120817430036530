import type { FC } from 'react';
import React, { useContext } from 'react';
import type { FactorProps } from '../types';
import { ThemeContext } from 'styled-components';

export interface BarBaseProps extends FactorProps {
  opacity?: number;
}

const Factor: FC<React.PropsWithChildren<BarBaseProps>> = ({ opacity = 1, x, y, width, height, color, transform }) => {
  const { Colors } = useContext(ThemeContext);
  return (
    <g>
      {opacity < 1 && <rect fill={Colors.White} fillOpacity={1} x={x} y={y} width={width} height={height} />}
      <rect fill={color} transform={transform} x={x} y={y} width={width} height={height} opacity={opacity} />
    </g>
  );
};

export default Factor;
