import { CookiesAllowed } from '../CookiesCommon';
import type { UserCookiePolicyStatus } from 'venn-api';
import { getUserAcceptCookiePolicy, updateUserAcceptCookiePolicy } from 'venn-api';
import { isRequestSuccessful, useRemoteState } from 'venn-utils';

export const useRemoteCookieAcceptPolicy = (): [UserCookiePolicyStatus, (cookies: CookiesAllowed) => void] => {
  const [remoteAcceptCookiePolicy, setRemoteUpdateCookiePolicy] = useRemoteState({
    key: ['userAcceptedCookiePolicy'],
    getter: getCookiePolicy,
    updater: updateUserAcceptCookiePolicy,
  });
  return [
    remoteAcceptCookiePolicy,
    (cookiesAllowed: CookiesAllowed) => setRemoteUpdateCookiePolicy(convert(cookiesAllowed)),
  ] as [UserCookiePolicyStatus, (x: CookiesAllowed) => void];
};

const NOTHING_ALLOWED = {
  setCookiePolicy: true,
  consentedCookieTypes: [],
};

const getCookiePolicy = async () => {
  try {
    const response = await getUserAcceptCookiePolicy();
    if (isRequestSuccessful(response)) {
      return response.content;
    }
    return NOTHING_ALLOWED;
  } catch (e) {
    return NOTHING_ALLOWED;
  }
};

const convert = (cookiesAllowed: CookiesAllowed) => {
  if (cookiesAllowed === CookiesAllowed.FunctionalOnly) {
    return ['FUNCTIONAL' as const];
  }
  if (cookiesAllowed === CookiesAllowed.AdvertisingOnly) {
    return ['ADVERTISING' as const];
  }
  if (cookiesAllowed === CookiesAllowed.All) {
    return ['FUNCTIONAL' as const, 'ADVERTISING' as const];
  }
  return [];
};
