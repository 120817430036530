import React, { useMemo, useState } from 'react';
import Modal from '../../modal/Modal';
import { ModalSize, ModalSubhead } from '../../modal';
import ModalHeader from '../../modal/ModalHeader';
import ModalFooter from '../../modal/ModalFooter';
import styled from 'styled-components';
import StyledLabel from '../../label';
import Input from '../../input/Input';
import { useDebounce } from '../../hooks';
import { useValidatePrivatePortfolioName } from '../../utils/useValidatePrivatePortfolioName';
import { IconPosition, LoadingSize, Spinner } from 'venn-ui-kit';
import Field from '../../modals/validation/Field';

interface SavePrivatePortfolioAsModalProps {
  onClose: () => void;
  onSave: (newName: string) => void;
}

export const SavePrivatePortfolioAsModal = ({ onClose, onSave }: SavePrivatePortfolioAsModalProps) => {
  const [name, setName] = useState<string>('');
  const [debouncedName] = useDebounce(name, 500);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  useValidatePrivatePortfolioName(
    debouncedName,
    useMemo(
      () => ({
        onEmpty: () => setError(''),
        onValid: () => setError(''),
        onInvalid: (error: string) => setError(error),
        setIsLoading: (isLoading: boolean) => setIsLoading(isLoading),
      }),
      [],
    ),
  );
  return (
    <Modal
      closeOnOutsideClick
      onClose={onClose}
      size={ModalSize.Large}
      closeOnEsc
      className="qa-save-private-portfolio-as-modal"
    >
      <ModalHeader>Save As...</ModalHeader>
      <ModalSubhead>Please enter a name for your portfolio</ModalSubhead>
      <ModalContent>
        <FieldWrapper className="qa-input-portfolio-name" error={error}>
          <StyledLabel required>Portfolio name:</StyledLabel>
          <StyledInput
            width={580}
            placeholder="Select unique portfolio name"
            value={name}
            onChange={setName}
            icon={isLoading ? <Spinner size={LoadingSize.micro} /> : undefined}
            iconPosition={IconPosition.Right}
          />
        </FieldWrapper>
      </ModalContent>
      <ModalFooter
        onCancel={onClose}
        primaryLabel="Save"
        onPrimaryClick={() => onSave(name)}
        primaryDisabled={!name || !!error}
        secondaryLabel="Back"
        onSecondaryClick={onClose}
      />
    </Modal>
  );
};

const ModalContent = styled.div`
  display: flex;
  margin: 100px 150px 130px 150px;
`;

const StyledInput = styled(Input)`
  display: flex;
`;

const FieldWrapper = styled(Field)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
