import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';

export interface TradesToggleProps {
  disabled?: boolean;
  toggled?: boolean;
  onToggle?(): unknown;
}

const TradesToggle: FC<React.PropsWithChildren<TradesToggleProps>> = ({
  disabled = false,
  toggled = false,
  onToggle,
}) => {
  return (
    <Container disabled={disabled} onClick={disabled ? undefined : onToggle}>
      <Toggle disabled={disabled} toggled={toggled} className="qa-trades-toggle">
        {toggled && <Text disabled={disabled}>ON</Text>}
        <Indicator toggled={toggled} disabled={disabled} />
        {!toggled && <Text disabled={disabled}>OFF</Text>}
      </Toggle>
    </Container>
  );
};

export default TradesToggle;

interface ToggleProps {
  disabled: boolean;
  toggled: boolean;
}

const Container = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  user-select: none;
`;

const Toggle = styled.div<ToggleProps>`
  width: 45px;
  height: 15px;
  border-radius: 15px;
  background-color: ${({ toggled, disabled }) =>
    disabled ? GetColor.PaleGrey : toggled ? GetColor.Primary.Dark : GetColor.DarkGrey};
  box-sizing: content-box;

  ${({ disabled }) => disabled && 'cursor: not-allowed; pointer-events: none;'};

  border: 1px solid ${GetColor.DarkGrey};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Indicator = styled.div<ToggleProps>`
  width: 15px;
  height: 15px;
  transition-duration: 0.3s;
  border-radius: 50%;
  border: 1px solid ${GetColor.DarkGrey};
  background-color: ${({ disabled }) => (disabled ? GetColor.MidGrey1 : GetColor.White)};
  ${({ toggled }) => (toggled ? 'transform: translateX(2px);' : 'margin-left: -2px;')};
`;

const Text = styled.div<{ disabled?: boolean }>`
  font-size: 11px;
  font-weight: bold;
  flex-grow: 1;
  text-align: center;
  color: ${({ disabled }) => (disabled ? GetColor.MidGrey2 : GetColor.White)};
`;
