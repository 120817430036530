import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import type { SearchMenuItem } from '../../../../search-menu';
import { SearchMenuBar } from '../../../../search-menu';

const qaClass = 'qa-fund';

export interface BenchmarkInvestmentProps {
  defaultValue?: SearchMenuItem;
  onFundSelect: (select: SearchMenuItem) => void;
}

const BenchmarkInvestment: FC<React.PropsWithChildren<BenchmarkInvestmentProps>> = ({ defaultValue, onFundSelect }) => (
  <BenchmarkInvestmentContainer className={qaClass}>
    <SearchMenuBar
      defaultValue={defaultValue}
      onSelected={onFundSelect}
      investmentsOnly
      defaultMenuIsOpen={false}
      autofocus={false}
      shortPlaceholder
      fixedMenuWidth
      location="createCompositeBenchmark"
      privateAssetSearchMode="PUBLIC_ONLY"
    />
  </BenchmarkInvestmentContainer>
);

export default BenchmarkInvestment;

const BenchmarkInvestmentContainer = styled.div`
  flex: 0 0 390px;
  padding: 0 10px;
`;
