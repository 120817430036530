import type { ScenarioAnalysis, ShockUnitsEnum } from 'venn-api';
import { isNil } from 'lodash';
import { assertExhaustive } from 'venn-utils';

export interface ScenarioAnalysisResult {
  fundId: string;
  fundName: string;
  id: string;
  units: ShockUnitsEnum;
  shock: number;
  mean: number;
  mainPredict?: ScenarioAnalysis;
  benchmarkPredict?: ScenarioAnalysis;
  comparisonPredict?: ScenarioAnalysis;
  minShock?: number;
  maxShock?: number;
  minShockZScore?: number;
  maxShockZScore?: number;
  loading: boolean;
}

const convertToPercentageNumber = (value: number | undefined): string => (value ? (value * 100).toString() : '0.0');

const convertToBasicPoints = (value: number | undefined): string => (value ? (value * 10000).toString() : '0.0');

export const convertByUnits = (value: number | undefined, units: ShockUnitsEnum, addNewShock?: boolean): string => {
  if (addNewShock && isNil(value)) {
    return '';
  }
  switch (units) {
    case 'PRICE':
    case 'POINTS':
      return isNil(value) ? '' : value.toString();
    case 'PERCENTAGE':
    case 'ABS_PERCENTAGE':
      return convertToPercentageNumber(value);
    case 'BPS':
      return convertToBasicPoints(value);
    default:
      throw assertExhaustive(units);
  }
};

export const getUnitBase = (units: ShockUnitsEnum): number => {
  switch (units) {
    case 'PRICE':
    case 'POINTS':
      return 1;
    case 'PERCENTAGE':
    case 'ABS_PERCENTAGE':
      return 100;
    case 'BPS':
      return 10000;
    default:
      throw assertExhaustive(units);
  }
};

export const getUnitSymbol = (units: ShockUnitsEnum): string => {
  switch (units) {
    case 'PRICE':
      return '$';
    case 'POINTS':
      return 'pts';
    case 'PERCENTAGE':
    case 'ABS_PERCENTAGE':
      return '%';
    case 'BPS':
      return 'bps';
    default:
      throw assertExhaustive(units);
  }
};

export const getUnitPrecision = (units: ShockUnitsEnum): number => {
  switch (units) {
    case 'PRICE':
    case 'POINTS':
    case 'PERCENTAGE':
    case 'BPS':
      return 1;
    case 'ABS_PERCENTAGE':
      return 2;
    default:
      throw assertExhaustive(units);
  }
};
