import React from 'react';
import { GetColor } from 'venn-ui-kit';
import styled from 'styled-components';

export interface XAxisTickProps {
  gridColor: string;
  height: number;
  rowHeight: number;
  label: string;
  labelPosition: number;
  position: number;
  labelTransform?: number;
}

const XAxisTick = ({
  gridColor,
  height,
  rowHeight,
  label,
  labelPosition,
  position,
  labelTransform,
}: XAxisTickProps) => (
  <g>
    <line x1={position} y1={0} x2={position} y2={height} stroke={gridColor} />
    <Label labelTransform={labelTransform ?? 0} rowHeight={rowHeight} x={position} y={labelPosition}>
      {label}
    </Label>
  </g>
);
export default XAxisTick;

const Label = styled.text<{ labelTransform: number; rowHeight: number }>`
  font-size: ${({ rowHeight }) => (rowHeight < 20 ? '11px' : '14px')};
  text-anchor: middle;
  fill: ${GetColor.MidGrey2};
  transform: translateX(${({ labelTransform }) => labelTransform}px);
`;
