import React, { useMemo } from 'react';
import { StudioContext, StudioSidePanelContext } from 'venn-components';
import useSelectionSystem from '../logic/useSelectionSystem';
import useStudioSetup from '../logic/useStudioSetup';
import useStudioToolbar from '../logic/useStudioToolbar';
import useStudioExport from '../logic/useStudioExport';
import { useSyncColors } from '../logic/useSyncColors';

const StudioContextStore: React.FC<React.PropsWithChildren<unknown>> = React.memo(function StudioContextStore({
  children,
}) {
  const setupProps = useStudioSetup();
  const {
    isGlobalAnalysisRangeLoading,
    firstOpeningOfTheView,
    setFirstOpeningOfTheView,
    afterUnsavedChangesAction,
    setAfterUnsavedChangesAction,
    setIsDuplicateReportName,
    setIsCheckingDuplicateReportName,
  } = setupProps;

  const sideBarProps = useSelectionSystem();
  const { onSelectBlock, onSelectGlobal, onSelectPage } = sideBarProps;

  const exportProps = useStudioExport();

  const toolBarProps = useStudioToolbar({
    firstOpeningOfTheView,
    setFirstOpeningOfTheView,
    afterUnsavedChangesAction,
    setAfterUnsavedChangesAction,
    onSelectPage,
    onSelectBlock,
    onSelectGlobal,
    onExport: exportProps.onExport,
    setIsDuplicateReportName,
    setIsCheckingDuplicateReportName,
  });

  useSyncColors();

  const contextValue = useMemo(() => {
    return {
      ...setupProps,
      ...toolBarProps,
      ...exportProps,
      isGlobalAnalysisRangeLoading,
    };
  }, [exportProps, isGlobalAnalysisRangeLoading, setupProps, toolBarProps]);

  return (
    <StudioContext.Provider value={contextValue}>
      <StudioSidePanelContext.Provider value={sideBarProps}>{children}</StudioSidePanelContext.Provider>
    </StudioContext.Provider>
  );
});

export default StudioContextStore;
