import type { BlockId } from '../types';
import { blockMetricsErrors } from './blockConfig';
import { useRecoilState } from 'recoil';
import { useCallback, useMemo } from 'react';
import { groupBy } from 'lodash';

export enum MetricError {
  ONE_METRIC = 'Only one metric can be displayed at a time',
  TWO_METRICS = 'Only the first two metrics can be displayed',
  PME_NEEDS_BENCHMARK = 'PME metrics require a valid benchmark',
  HISTORICAL_CASHFLOWS_UNAVAILABLE = 'One or more funds in your subject don’t have enough historical cashflow data available before the projection start date. You can confirm the historical cashflow data in each investment’s Manage Data page.',
  PROJECTION_UNAVAILABLE = 'One or more funds in your subject don’t have any historical NAV and cash flow data available before the projection start date. Please confirm the available historical NAV and cash flow data in each investment’s Manage Data page.',
}

export interface MetricSpecificError {
  metricKey: string;
  error: MetricError;
}

/**
 * Manage errors of metrics within a particular block.
 *
 * Sometimes a user selected a metric, and the block doesn't display it for some reason. This family stores information
 * why certain metrics couldn't be displayed. Example: to calculate a private asset performance PME metric, user needs
 * to supply a benchmark. If a benchmark is not supplied, the calculation will fail and the user should be informed of
 * the appropriate action to take.
 *
 * It also supports block-level metrics errors using the key "blockLevel". This is useful if there is a metric-related
 * error that shouldn't be attributed to any specific metric, such as an infographic type only supporting the first
 * from a list of metrics.
 *
 * Important: The caller is responsible for cleaning up the errors they introduce. That is, in the effect which calls some of the functionality provided
 * by this hook, you should return clearMetricErrors to make sure the errors don't persist once your component unmounts.
 * It is theoretically possible to have multiple sources of error creation for one block, but in practice quite error-prone.
 * In the future we could consider adding more meaningful keys to the errors to make it easier to figure out which bits should be cleaned up.
 */
export const useMetricsErrors = (blockId: BlockId) => {
  const [currentErrors, setErrors] = useRecoilState(blockMetricsErrors(blockId));

  /** Append a list of metrics errors to already existing errors within this block */
  const appendMetricErrors = useCallback(
    (errors: MetricSpecificError[]) => {
      setErrors((currentErrors) => [...currentErrors, ...errors]);
    },
    [setErrors],
  );

  /** Clear all metrics errors within this block */
  const clearMetricErrors = useCallback(() => {
    setErrors([]);
  }, [setErrors]);

  /** A dictionary [string, [errors]] of errors within this block
   *
   * Key: string corresponding to a metricKey or "blockLevel" for a block-level error
   * Values: lists of metric-specific errors. {@see MetricSpecificError} */
  const errors = useMemo(() => {
    return groupBy(currentErrors, 'metricKey');
  }, [currentErrors]);

  return {
    metricErrors: errors,
    setMetricErrors: setErrors,
    appendMetricErrors,
    clearMetricErrors,
  };
};
