/* eslint-disable */
import { getAppTitle, getTextThemeProvider } from 'venn-ui-kit';
const CURRENT_YEAR = new Date().getUTCFullYear();

/////////////////////////////////////////////////////////////////////////////
// Section for screen-specific messages

export const COUNTRY_DISCLAIMERS = {
  CA: `Notice to Canadian Subscribers:  Use of ${getAppTitle()} to analyze commodity futures contracts or any commodity futures options, as such terms are defined under the Commodity Futures Act (Ontario), is prohibited.  Permitted Clients, as defined under National Instrument 31-103 Registration Requirements, Exemptions and Ongoing Registrant Obligations ("NI 31-103"), may not use ${getAppTitle()} to analyze any security that is not a "foreign security" as defined under NI 31-103, unless any advice generated in respect of such security is incidental under section 8.26 of NI 31-103.`,
};

export const AGREEMENTS_FOR_SCREEN = `
Nothing on ${getAppTitle()}${
  getTextThemeProvider().tsParam
} is or should be construed as an offer to sell, or a solicitation of an offer to buy, any security or other instrument.

Past performance is not necessarily indicative of future results. Exposure to risk factors is not a guarantee of increased performance or of decreased risk. Two Sigma Investor Solutions, LP ("TSIS") operates ${getAppTitle()}. Any content on or available through ${getAppTitle()} (including any content added or modified by subscriber, authorized representatives or third parties) is provided "AS IS." TSIS makes no representations or warranties with respect to, and does not guarantee, the accuracy, timeliness, completeness, performance or usefulness of any content or analytics on ${getAppTitle()}.

Outputs from analytics on ${getAppTitle()} are mere estimates or approximations and, in some cases are forward looking in nature or include hypothetical performance.  Forward looking statements and hypothetical performance are based on certain assumptions, are not a guarantee of future results and should not be considered alone in making an investment decision. Forward looking statements represent TSIS’s expectations or beliefs as to future events based on TSIS’s assumptions and analysis in light of its experience and perception of historical trends and future conditions. Hypothetical performance presentations use criteria and assumptions provided by the subscriber and algorithms powered by Venn by Two Sigma applied to relevant data sets and do not represent actual investment activities. Neither is a guarantee of future results as, among other risks and limitations, assumptions might be inappropriate or incomplete and future conditions could vary significantly from past or projected conditions.

When subscribers make investment decisions, it is TSIS’s expectation that ${getAppTitle()} will be a resource supplementing their primary financial, tax, accounting, legal and other advisors and due diligence. You should not rely on ${getAppTitle()} as the sole or as a primary basis for investment decisions. The various business activities of TSIS and its affiliates within the Two Sigma group (including such affiliates’ management of or other services in connection with various investment accounts) create actual and potential conflicts of interest that TSIS believes are material to subscribers. Funds advised by an affiliate of TSIS can use similar factor analyses as those that are incorporated into ${getAppTitle()}. This could result in the ${getAppTitle()} algorithm viewing such funds in a potentially more favorable light than comparable funds advised by other advisers. Please see TSIS’s Form ADV for important disclosures, including disclosures about material conflicts of interest and risks. Links from this website to third - party websites or from third - party websites to this website do not imply any endorsement by this firm of the third - party website or of the link nor do they imply any endorsement by the third party of this website or of the link.

${getAppTitle().toUpperCase()}${getTextThemeProvider().tsParam.toUpperCase()} IS FOR INSTITUTIONAL/WHOLESALE/PROFESSIONAL CLIENTS AND OTHER QUALIFIED CLIENTS IN CERTAIN JURISDICTIONS ONLY – NOT FOR RETAIL USE OR DISTRIBUTION.   FOR UK SUBSCRIBERS: ${getAppTitle().toUpperCase()} IS INTENDED ONLY FOR INVESTMENT PROFESSIONALS WITHIN THE MEANING OF ARTICLE 19 OF THE FINANCIAL SERVICES AND MARKETS ACT 2000 (FINANCIAL PROMOTION) ORDER 2005 ("FPO") OR HIGH NET WORTH ENTITIES OR OTHER ENTITIES FALLING WITHIN ARTICLE 49 OF THE FPO.  FOR HK SUBSCRIBERS: Certain ${getAppTitle()} Pro features (including optimization) are not generally available in Hong Kong. TSIS is based in the United States. TSIS does not conduct any regulated activity (as defined in the Securities and Futures Ordinance (Cap. 571, Laws of Hong Kong)) in Hong Kong, and TSIS is not licensed by any regulatory authority in Hong Kong.  FOR ERISA, PUBLIC PENSION AND OTHER RETIREMENT ASSET SUBSCRIBERS: ${getAppTitle()} is not for use as a primary basis for investment decisions. TSIS does not hold itself out as a fiduciary (or as otherwise owing any other special duties) to any pension or retirement plan under pension, retirement or similar laws or regulations.
`;

export const PRIVATES_MODULE_DISCLAIMER = `Certain data provided by Preqin Ltd.  Copyright ${CURRENT_YEAR} Preqin Ltd.  All rights reserved.`;

export const THIRD_PARTY_NOTICES_FOR_SCREEN = (sixLogoPath: string) => `
MSCI Inc’s data and analytics were used in preparation of this information. Copyright ${CURRENT_YEAR} MSCI, Inc. All rights reserved.  Certain data provided by MSCI. Copyright MSCI ${CURRENT_YEAR}. All Rights Reserved. Unpublished. PROPRIETARY TO MSCI.

Market Data Powered By ![Six](${sixLogoPath} "Six")

©${CURRENT_YEAR} S&P Dow Jones Indices LLC. All rights reserved.

©${CURRENT_YEAR} Morningstar. All Rights Reserved. The information contained herein: 
(1) is proprietary to Morningstar and/or its content providers; 
(2) may not be copied or distributed; and (3) is not warranted to be accurate, complete or timely. 
Neither Morningstar nor its content providers are responsible for any damages or losses arising 
from any use of this information. Past performance is no guarantee of future results.

Certain data derived from data by Global Financial Data, Incorporated.

Portions of the information contained in this display were supplied by Lipper, A Refinitiv 
Company, subject to the following: Copyright ${CURRENT_YEAR} © Refinitiv. All rights reserved. Lipper shall 
not be liable for any errors or delays in the content, or for any actions taken in reliance thereon.

Bloomberg Commodity IndexSM and its associated data, Copyright © ${CURRENT_YEAR} Bloomberg Index Services Limited. Bloomberg® is a 
trademark and service mark of Bloomberg Finance L.P. and its affiliates (collectively “Bloomberg”). Bloomberg or 
Bloomberg’s licensors own all proprietary rights in the Bloomberg Indices. Bloomberg does not approve or endorse 
this material or guarantee the accuracy or completeness of any information herein, nor does Bloomberg make any warranty, 
express or implied, as to the results to be obtained therefrom and, to the maximum extent allowed by law, Bloomberg shall 
not have any liability or responsibility for injury or damages arising in connection therewith.

Bloomberg fixed income indices and their associated data, Copyright © ${CURRENT_YEAR} Bloomberg Index Services Limited. Bloomberg®
is a trademark and service mark of Bloomberg Finance L.P. and its affiliates (collectively “Bloomberg”). Bloomberg or
Bloomberg’s licensors own all proprietary rights in the Bloomberg Indices. Bloomberg does not approve or endorse this
material or guarantee the accuracy or completeness of any information herein, nor does Bloomberg make any warranty,
express or implied, as to the results to be obtained therefrom and, to the maximum extent allowed by law, Bloomberg
shall not have any liability or responsibility for injury or damages arising in connection therewith.

Source for hedge fund index data: HFR 
`;

/////////////////////////////////////////////////////////////////////////////
// Section for print-specific messages
export const STUDIO_DISCLOSURE_PAGE_DEFAULT_DISCLOSURE = (organizationDisplayName?: string) =>
  `Important Disclosure and Disclaimer Information (Provided by ${organizationDisplayName})

Certain Disclosures Concerning the Use of Hypothetical Performance Calculations

The report includes a hypothetical presentation as to the performance of one or more securities, or of a portfolio of securities, based on certain criteria and assumptions provided by ${organizationDisplayName} or set as a default in ${getAppTitle()} and the application of algorithms powered by ${getAppTitle()}${
    getTextThemeProvider().tsParam
  }. The report seeks to explain the performance of such securities or portfolios by examining factor exposures and contribution to risk and return.  The hypothetical performance does not reflect actual trading and could reflect better results than would be achieved through actual trading. A variety of factors can impact the actual market performance of an investment or group of investments, not all of such factors can or will be reflected in the assumptions used in generating the report. The conditions reflected in the assumptions might not match current or future market conditions and there could be errors in the assumptions or in the underlying algorithms. As a result, hypothetical performance information is inherently limited and cannot be seen as a guarantee of future results. The assumptions, forecasts and estimates used to generate the hypothetical performance are not all described herein, but include assumptions, forecasts and estimates utilized by ${organizationDisplayName} to formulate the methodology for the hypothetical performance.  

Hypothetical performance is inherently limited and should not be relied upon as a basis for investment decision-making. A variety of factors can impact the actual market performance of an investment or portfolio, not all of which can or will be reflected in the assumptions used in generating this report. Additionally, the conditions reflected in the assumptions might not match current or future market conditions and there could be errors in the assumptions or in the underlying algorithms. This report is for informational purposes only. It contains informational inputs that are not verified or warranted to be correct, complete, or accurate. This report is not investment advice nor an offer to buy or sell a security, and should not be used alone in forming an investment recommendation or decision.

Among other things, hypothetical performance results do not represent actual trading and do not necessarily reflect the impact that material economic and market factors might have had on an asset manager's decision-making if the asset manager were actually managing clients' money. Performance results will vary due to market conditions and other factors, including cash flows, fund allocations, frequency and precision of rebalancing, cash balances, varying custodial fees, and the timing of fee deductions. As a result, actual performance for client accounts may differ materially from, and may be lower than, that of a model portfolio. 

This report is provided to you by ${organizationDisplayName}, an SEC-registered investment adviser and/or broker-dealer. The report is prepared using a tool called ${getAppTitle()}${
    getTextThemeProvider().tsParam
  } ("${getAppTitle()}"), which allows for your financial advisor or intermediary to input information about you onto ${getAppTitle()} to assist them in analyzing your portfolio and its investments. 

The particular assumptions applied in generating this report, as well as the general risks and limitations of its use should be discussed with your ${organizationDisplayName} financial advisor.

Certain Disclosures Concerning Net of Fee Calculations

Except as otherwise indicated, hypothetical returns are calculated and presented net of a model advisory fee determined by ${organizationDisplayName} (the "Model Advisory Fee") and such net returns are used in calculating any metrics presented herein that include investment returns as a component.

Specifically, the Model Advisory Fee applied in this report for each portfolio (or portion thereof) is indicated in a field adjacent to or within the relevant visualization. Other metrics that are not based on returns are not impacted by Model Advisory Fees. If your arrangement provides for different fees to be applied to different portions of a portfolio, your actual fees could vary depending on the investments selected by ${organizationDisplayName} for your actual portfolio as compared to the portfolio presented herein. Such fee arrangements can create conflicts of interest in that ${organizationDisplayName} has an incentive to construct portfolios that would allow it to earn higher fees. TSIS (as defined below) is not responsible for determining or verifying the Model Advisory Fee.

Certain Disclosures Concerning Use of Indices for Comparison Purposes

This report utilizes various funds and/or indices for comparison purposes only. The returns of any security, strategy or portfolio will differ from the performance of such funds/indices because such securities, strategies or portfolios generally (i) will not attempt to replicate the performance of the funds/indices, (ii) are expected to engage in a variety of investment strategies that may not be reflected in the funds/indices and (iii) may be subject to different expense ratios. It should not be assumed that any strategy shown in this report will invest in any specific securities that (directly or indirectly) comprise such funds/indices or will have performance similar to the historical performance of such funds/indices. For the avoidance of doubt, indices are generally not subject to management fees and Model Advisory Fees (where applicable) are not deducted from such returns either when presented as benchmark returns or included as a component in calculating a metric.
`;

export const SWISS_DISCLOSURE =
  '\nThis report is not intended to constitute a financial service or an offer of a financial instrument to clients in Switzerland under the Swiss Financial Services Act ("FinSA") and the Swiss Collective Investment Schemes Act ("CISA"). Readers of this document acknowledge that, in Switzerland, the offer and the marketing of the funds mentioned in this document might be exclusively made to, and directed at, qualified investors (the "Qualified Investors"), as defined in Article 10(3) and (3ter) CISA and its implementing ordinance, at the exclusion of qualified investors with an opting-out pursuant to Article 5(1) FinSA and without any portfolio management or advisory relationship with a financial intermediary pursuant to Article 10(3ter) CISA ("Excluded Qualified Investors"). Accordingly, the funds may not be registered with the Swiss Financial Market Supervisory Authority ("FINMA") and no representative or paying agent may have been or may be appointed in Switzerland.';

export const AGREEMENTS_FOR_PRINT = (
  organizationDisplayName: string | undefined,
  swissDisclosure: boolean,
) => `## Important Information and Disclosures about ${getAppTitle()}${
  getTextThemeProvider().tsParam
} (Provided by ${getAppTitle()})

${getAppTitle()}${
  getTextThemeProvider().tsParam
} ("${getAppTitle()}") powers this report. ${getAppTitle()} is a computerized data analysis platform that uses research and methodologies that are changed frequently, into which each subscriber, at its own initiative and at its own discretion, can input certain data and receive certain results. It is each subscriber’s responsibility to determine for itself that ${getAppTitle()} usage is, and that ${getAppTitle()} results are, appropriate for report recipients. To the extent any statements or disclosures provided by ${organizationDisplayName} regarding ${getAppTitle()} and its usage conflict with the statements contained in this section (titled "Important Information and Disclosures about ${getAppTitle()}${
  getTextThemeProvider().tsParam
}"), the statements contained in this section should be understood to control. 

${organizationDisplayName} utilizes ${getAppTitle()} to assist with analyzing actual and potential investments and/or portfolios. ${getAppTitle()} does not provide investment advice, recommendations or advisory services to you, and this report should not be the basis of any investment decision-making. ${getAppTitle()} relies on assumptions, forecasts, estimates, models, algorithms and information sources, which could contain errors and biases, and there is no guarantee that the performance and other metrics included herein are correct, complete, or accurate.  ${getAppTitle()} also relies on assumptions and other data input by ${organizationDisplayName} and by third-parties that could be or prove over time to be incorrect, inaccurate or incomplete.  Depending on the use case, it is possible that this report could contain hypothetical performance information.  To the extent included, hypothetical performance presentations are inherently limited and should not be relied upon as a basis for investment decision-making.  Please consult with ${organizationDisplayName} for more information about the potential use of hypothetical performance and/or any assumptions applied in generating this report (including the applicability of fees and expenses), in each case, to the extent applicable. You should consult with your professional advisors (including financial, legal and tax advice) suitable to your particular circumstances prior to making any investment decision. 

Two Sigma Investor Solutions, LP ("TSIS") operates ${getAppTitle()}. Nothing on ${getAppTitle()} or in this report is or should be construed as an offer to sell, or a solicitation of an offer to buy, any security or other instrument.

Past performance is not necessarily indicative of future results. An awareness or evaluation of risk factor exposures is not a guarantee of increased performance or decreased risk. TSIS makes no representations or warranties with respect to, and does not guarantee, the accuracy, timeliness, completeness, performance or usefulness of any content on ${getAppTitle()}. Metrics provided by ${getAppTitle()} are estimates only. TSIS does not verify the data upon which these metrics rely or whether such data is provided in compliance with any securities laws, rules, regulations or industry standards or practices, including those related to marketing or advertising of investment funds.

${getAppTitle()} is not intended to, and does not purport to, take into account your overall or complete financial situation, level of financial sophistication, investment experience, investment mandate, financial goals, tax situation, or investment profile. ${getAppTitle()} does not accept all relevant investment profiles or other data, make all potential or standard settings available or customizable, or otherwise provide all important or relevant analysis, nor does ${getAppTitle()} or TSIS inquire into whether any data, settings or analysis are actually appropriate for the intended use. Neither ${getAppTitle()} nor TSIS investigates any security or other financial instrument analyzed by ${getAppTitle()} other than to the extent TSIS generally operates ${getAppTitle()}.

The various business activities of TSIS and its affiliates within the Two Sigma group (including such affiliates' management of or other services in connection with various investment accounts) create actual and potential conflicts of interest that TSIS believes are material to subscribers. Funds advised by an affiliate of TSIS can use similar factor analyses as those that are incorporated into ${getAppTitle()}. This could result in the ${getAppTitle()} algorithm viewing such funds in a potentially more favorable light than comparable funds advised by other advisers. Subscribers receive TSIS’s Form ADV, which contains important disclosures, including disclosures about material conflicts of interest and risks. 

  ${swissDisclosure ? SWISS_DISCLOSURE : ''}
`;

export const THIRD_PARTY_NOTICES_FOR_PRINT = (sixLogoPath: string, privatesDisclosure: boolean) => `
${privatesDisclosure ? PRIVATES_MODULE_DISCLAIMER : ''}

MSCI Inc’s data and analytics were used in preparation of this information. Copyright ${CURRENT_YEAR} MSCI, Inc. All rights reserved.  Certain data provided by MSCI. Copyright MSCI ${CURRENT_YEAR}. All Rights Reserved. Unpublished. PROPRIETARY TO MSCI.

The data is provided on an “as is” basis.  TSIS, its information providers (including without limitation MSCI Inc.), and any other third party involved in or related to the making or compiling of the data make no representation or warranty of any kind, either express or implied, with respect to the data in this report (or the results to be obtained by the use thereof). TSIS, its information providers (including without limitation MSCI inc.) and any other third party involved in or related to the making or compiling of the data expressly disclaim any and all implied warranties of originality, accuracy, completeness, non-infringement, merchantability and fitness for a particular purpose.

You assume the entire risk of any use you may make of the data. In no event shall TSIS, its information providers (including without limitation MSCI Inc.) or any third party involved in or related to the making or compiling of the data, be liable to you, or any other third party, for any direct or indirect damages, including, without limitation, any lost profits, lost savings or other incidental or consequential damages arising out of this agreement or your inability to use the data, regardless of the form of action, even if TSIS, any of its information providers (including without limitation MSCI Inc.), or any other third party involved in or related to the making or compiling of the data has been advised of or otherwise might have anticipated the possibility of such damages.

Market Data Powered By ![Six](${sixLogoPath} "Six")

©${CURRENT_YEAR} S&P Dow Jones Indices LLC. All rights reserved.

©${CURRENT_YEAR} Morningstar. All Rights Reserved. The information contained herein: 
(1) is proprietary to Morningstar and/or its content providers; 
(2) may not be copied or distributed; and (3) is not warranted to be accurate, complete or timely. 
Neither Morningstar nor its content providers are responsible for any damages or losses arising 
from any use of this information. Past performance is no guarantee of future results.

Certain data derived from data by Global Financial Data, Incorporated.

Portions of the information contained in this display were supplied by Lipper, A Refinitiv Company, subject
to the following: Copyright ${CURRENT_YEAR} © Refinitiv. All rights reserved. Lipper shall not be liable for any errors
or delays in the content, or for any actions taken in reliance thereon.

Bloomberg Commodity IndexSM and its associated data, Copyright © ${CURRENT_YEAR} Bloomberg Index Services Limited. "Bloomberg®," the "Bloomberg Commodity IndexSM" and the names of the other indices and subindices that are part of the Bloomberg Commodity Index family (such indices and subindices collectively referred to as the "BCOM Indices") are service marks of Bloomberg Finance L.P. and its affiliates, including Bloomberg Index Services Limited, the administrator of the indices (collectively, "Bloomberg". Bloomberg or Bloomberg's licensors own all proprietary rights in the BCOM Indices. Bloomberg does not approve or endorse this material, or guarantee the accuracy or completeness of any information herein, or make any warranty, express or implied, as to the results to be obtained therefrom and, to the maximum extent allowed by law, shall have no liability or responsibility for injury or damages arising in connection therewith.

Bloomberg fixed income indices and their associated data, Copyright © ${CURRENT_YEAR} Bloomberg Index Services Limited. Bloomberg® is a trademark and service mark of Bloomberg Finance L.P. and its affiliates (collectively "Bloomberg"). Bloomberg or Bloomberg's licensors own all proprietary rights in the Bloomberg Indices. Bloomberg does not approve or endorse this material or guarantee the accuracy or completeness of any information herein, nor does Bloomberg make any warranty, express or implied, as to the results to be obtained therefrom and, to the maximum extent allowed by law, Bloomberg shall not have any liability or responsibility for injury or damages arising in connection therewith.

Source for hedge fund index data: HFR 
`;
