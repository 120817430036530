import styled, { css } from 'styled-components';
import { ZIndex } from 'venn-ui-kit';

const navMenuBaseCss = css`
  position: absolute;
  font-size: 14px;
  line-height: 1.71;
  min-width: 205px;
  max-height: inherit;
  padding: 0;

  z-index: ${ZIndex.ModalFront};

  background: white;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);

  transition:
    transform 50ms ease-in-out,
    opacity 50ms linear;
`;

export const NavigationMenu = styled.div`
  ${navMenuBaseCss}
  bottom: 40px;
  left: 60%;
  transform-origin: left bottom;
`;

export const TopNavigationMenu = styled.div`
  ${navMenuBaseCss}
  top: 45px;
  right: 50%;
  transform-origin: top right;
`;
