import React, { useContext } from 'react';
import styled from 'styled-components';
import { useHover } from 'venn-utils';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { blockBenchmarkInput } from 'venn-state';
import { Flex } from '../../../../../shared';
import { HoverIcon } from '../HoverIcon';
import { BenchmarkInputDropMenu } from './BenchmarkInputDropMenu';
import { UserContext } from 'venn-components';

/** List the benchmark input on a block, with support for replacing or adding new ones to replace it */
// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export const BlockBenchmarkInput = React.memo(({ selectedBlockId }: { selectedBlockId: string }) => {
  const { hasPermission } = useContext(UserContext);
  const selectedInputId = useRecoilValue(blockBenchmarkInput(selectedBlockId));
  const setBlockInputId = useSetRecoilState(blockBenchmarkInput(selectedBlockId));
  const [isRootHovered, rootHoverHandlers] = useHover();
  const readonly = !hasPermission('STUDIO_EDIT_BENCHMARKS') || !hasPermission('STUDIO_EDIT');

  return (
    <BenchmarkInputRowsGrid>
      <Flex style={{ justifyContent: 'space-between' }} {...rootHoverHandlers}>
        <BenchmarkInputDropMenu
          selectedInputId={selectedInputId}
          onSelectInput={(benchmarkId) => setBlockInputId(benchmarkId)}
          readonly={readonly}
        />
        {!readonly && selectedInputId && (
          <HoverIcon type="trash" tabIndex={0} visible={isRootHovered} onClick={() => setBlockInputId(undefined)} />
        )}
      </Flex>
    </BenchmarkInputRowsGrid>
  );
});

const BenchmarkInputRowsGrid = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 14px;
`;
