import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Body1, getAppTitle, getTextThemeProvider, HyperLink, Icon } from 'venn-ui-kit';
import { InfoContainer, InfoHeading, PreviewArea } from './styles';

const IMAGE_ICON_MARGIN = 22;

const helpCenterArticle = getTextThemeProvider().HelpCenterLinks.ADDEPAR_HELP_HREF;
const info = [
  {
    key: 'instances',
    header: 'Have multiple Addepar instances?',
    content: 'If you need to add multiple instances, you can add another one after this process is complete.',
  },
  {
    key: 'help',
    header: 'Need help?',
    content: (
      <>
        Read this{' '}
        <HyperLink href={helpCenterArticle} target="_blank" rel="noopener noreferrer" className="qa-addepar-help">
          help article
        </HyperLink>
        , or contact{' '}
        <HyperLink href={`mailto:${getTextThemeProvider().supportEmail}`}>{`${getAppTitle()} support`}</HyperLink>.
        Issues with Addepar? Submit a support ticket in the Addepar app or reach out to your Addepar account manager if
        you have any trouble.
      </>
    ),
  },
];

const AddeparStep1 = () => {
  const { Images } = useContext(ThemeContext);
  return (
    <Container>
      <StyledPreviewArea>
        <StyledImg src={Images.addeparStep1AddeparLogin} alt="addepar login screen" />
        <Icon type="arrow-right" style={{ fontSize: 24, fontWeight: 400, margin: `0 ${IMAGE_ICON_MARGIN}px` }} />
        <StyledImg src={Images.addeparStep1Authorization} alt="addepar authorization screen" />
      </StyledPreviewArea>
      <Info>
        {info.map(({ key, header, content }) => (
          <div key={key}>
            <InfoHeading>{header}</InfoHeading>
            <Body1>{content}</Body1>
          </div>
        ))}
      </Info>
    </Container>
  );
};

export default AddeparStep1;

const StyledPreviewArea = styled(PreviewArea)`
  padding: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Info = styled(InfoContainer)`
  width: 50%;
  padding-right: 5px;
  > * {
    margin-bottom: 40px;
  }
`;

const StyledImg = styled.img`
  width: calc(50% - ${IMAGE_ICON_MARGIN}px);
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;
