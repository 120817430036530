import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import Input from '../../../../input/Input';
import { TextAlign } from 'venn-ui-kit';

const ScalingValueWrapper = styled.div`
  & > div {
    width: 56px;
    height: 36px;
    margin-right: 10px;
  }
`;

const qaClass = 'qa-percentage';

export interface ScalingValueProps {
  value: string;
  onChange(value: string): void;
}

const ScalingValue: FC<React.PropsWithChildren<ScalingValueProps>> = ({ value, onChange }) => (
  <ScalingValueWrapper className={qaClass}>
    <Input textAlign={TextAlign.Left} type="number" value={value} placeholder="0.0" onChange={onChange} />
  </ScalingValueWrapper>
);

export default ScalingValue;
