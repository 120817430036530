import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';
import { Link } from 'react-router-dom';

const LensInfo = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: ${GetColor.DarkGrey};
  margin: 0;
  a {
    font-weight: bold;
  }
`;

const FactorLensInfo: FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <LensInfo>
    The analysis below relies on the&nbsp;
    <Link to="/factor-lens">Two Sigma Factor Lens</Link>, which is a combination of macro and equity styles.
    {children}
  </LensInfo>
);

export default FactorLensInfo;
