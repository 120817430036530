import React from 'react';
import styled, { css } from 'styled-components';
import {
  Button,
  Flexbox,
  GetColor,
  NewBadge,
  PORTFOLIO_CONSTRUCTION_FAQ_HREF,
  PRIVATE_ASSET_LAB_FAQ_HREF,
} from 'venn-ui-kit';
import FaqLink from '../../faq-link/FaqLink';
import ModalFooter from '../../modal/ModalFooter';
import { Header } from '../data-uploader/components/page-parts';

interface Props {
  openProFormaModal: () => void;
  onCancel: () => void;
  moveToCreatePrivatePortfolio: () => void;
}

const SelectPortfolioTypeStep = ({ onCancel, openProFormaModal, moveToCreatePrivatePortfolio }: Props) => {
  return (
    <>
      <Header>
        <h1>Create new portfolio</h1>
        <h2>Select a portfolio type</h2>
      </Header>

      <Flexbox direction="column" grow={1} alignItems="center" justifyContent="center">
        <Flexbox direction="column" rowGap={16}>
          <Flexbox direction="row" columnGap={32}>
            <Flexbox direction="column" rowGap={16} css={[widthCss]}>
              <Title>Returns-Based Portfolio</Title>
              <Paragraph>Contains only investment returns and is used for returns-based analytics.</Paragraph>
            </Flexbox>
            <Flexbox direction="column" rowGap={16} css={[widthCss]}>
              <Flexbox direction="row" alignItems="center" columnGap={10}>
                <NewBadge noMargin />
                <Title>Private Asset Portfolio</Title>
              </Flexbox>
              <Paragraph>Contains only private asset data and is used for private asset-specific analytics.</Paragraph>
            </Flexbox>
          </Flexbox>
          <Flexbox direction="row" columnGap={32}>
            <CreateButton dominant noMargin onClick={openProFormaModal}>
              Create new returns-based portfolio
            </CreateButton>
            <CreateButton dominant noMargin onClick={moveToCreatePrivatePortfolio}>
              Create new private asset portfolio
            </CreateButton>
          </Flexbox>
          <Flexbox direction="row" columnGap={160}>
            <span>
              <HelpText>
                Questions? Visit the <FaqLink link={PORTFOLIO_CONSTRUCTION_FAQ_HREF} />.
              </HelpText>
            </span>
            <span>
              <HelpText>
                Questions? Visit the <FaqLink link={PRIVATE_ASSET_LAB_FAQ_HREF} /> .
              </HelpText>
            </span>
          </Flexbox>
        </Flexbox>
      </Flexbox>
      <ModalFooter onCancel={onCancel} />
    </>
  );
};

export default SelectPortfolioTypeStep;

const widthCss = css`
  width: 300px;
  min-width: 300px;
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: 20px;
`;

const Paragraph = styled.p`
  font-size: 14px;
  line-height: 1.15;
  margin: 0;
`;

const CreateButton = styled(Button)`
  ${widthCss};
  font-size: 11px;
  text-transform: uppercase;
`;

const HelpText = styled.div`
  color: ${GetColor.DarkGrey};
`;
