import { useCallback, useEffect, useState } from 'react';
import type { SponsoredAccount } from 'venn-api';
import { getSponsoredAccounts, removeSponsorshipInvitations, resendInvitation, unsponsorAccount } from 'venn-api';

interface UseSponsoredAccountsValue {
  accounts: SponsoredAccount[];
  numPages: number;
  page: number;
  resendInvite: (account: SponsoredAccount) => void;
  removeInvite: (account: SponsoredAccount) => void;
  removeAccount: (account: SponsoredAccount) => void;
  setPage: (page: number) => void;
  refreshAccounts: () => void;
}

const useSponsoredAccounts = (): UseSponsoredAccountsValue => {
  const [accounts, setAccounts] = useState<SponsoredAccount[]>([]);
  const [numPages, setNumPages] = useState<number>(0);
  const [page, setPage] = useState<number>(1);

  const refresh = useCallback(async () => {
    const query = {
      pageSize: 20,
      page,
    };
    const { content } = await getSponsoredAccounts(query);
    setAccounts(content.accounts);
    setNumPages(Math.ceil(content.total / 20));
  }, [page]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const resendInvite = useCallback(
    async (account: SponsoredAccount) => {
      await resendInvitation(`"${account.invitationId}"`);
      refresh();
    },
    [refresh],
  );

  const removeInvite = useCallback(
    async (account: SponsoredAccount) => {
      await removeSponsorshipInvitations([account.invitationId]);
      refresh();
    },
    [refresh],
  );

  const removeAccount = useCallback(
    async (account: SponsoredAccount) => {
      await unsponsorAccount(account.accountId);
      refresh();
    },
    [refresh],
  );

  return {
    accounts,
    numPages,
    setPage,
    resendInvite,
    removeInvite,
    removeAccount,
    page,
    refreshAccounts: refresh,
  };
};

export default useSponsoredAccounts;
