import type { SearchMenuItem } from '../../search-menu';

export enum ScalingSign {
  Plus = 'PLUS',
  Minus = 'MINUS',
}

export interface BenchmarkShape {
  scalingSign: ScalingSign;
  scalingValue: string;
  weight: string;
  item: SearchMenuItem | null;
}

export const INITIAL_BENCHMARK_STATE: BenchmarkShape = {
  scalingSign: ScalingSign.Plus,
  scalingValue: '',
  weight: '',
  item: null,
};

export enum RequestStatus {
  Void,
  Pending,
  Failure,
  Success,
}

export interface CreateCompositeBenchmarkTabState {
  benchmarks: BenchmarkShape[];
  name: string;
  id?: number;
  fundId?: string;
  status: RequestStatus;
  error?: string;
}

export const INITIAL_BENCHMARK_TAB_STATE = {
  benchmarks: [INITIAL_BENCHMARK_STATE],
  name: '',
  status: RequestStatus.Void,
};
