import type { FC } from 'react';
import React from 'react';
import { GetColor } from 'venn-ui-kit';
import styled, { css } from 'styled-components';

interface ErrorBarProps {
  x: number;
  y: number;
  size: number;
  hidden?: boolean;
}

const ErrorBar: FC<React.PropsWithChildren<ErrorBarProps>> = ({ x, y, size, hidden }) => (
  <Container hidden={hidden}>
    <line x1={x - size / 2} y1={y} x2={x + size / 2} y2={y} />
    <line x1={x - size / 2} y1={y - 3} x2={x - size / 2} y2={y + 3} />
    <line x1={x + size / 2} y1={y - 3} x2={x + size / 2} y2={y + 3} />
  </Container>
);

export default ErrorBar;

const Container = styled.g<{ hidden?: boolean }>`
  ${(props) =>
    props.hidden
      ? css`
          stroke: transparent;
        `
      : css`
          transition: all 0.1s ease;
          stroke: ${GetColor.Black};
        `}
`;
