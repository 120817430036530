import { isNil } from 'lodash';
import { useAgGridStyle } from '../studio-blocks/components/grid/AgGridThemeOverrides';

import { useCallback } from 'react';
import { useTheme } from 'styled-components';
import type { GridStyle } from 'venn-state';
import type { Theme } from 'venn-ui-kit';
import { measureString } from 'venn-utils';

type GridValue = number | string | null | undefined;
/**
 * Measures the width of a string in pixels using the provided grid style and theme as if it were in an ag-grid, including cell padding.
 *
 * Prefer using {@link useMeasureGridText} unless for some reason you need an imperative version.
 */
export const measureGridText = (
  value: GridValue,
  fontWeight: 'normal' | 'bold',
  gridStyle: GridStyle,
  theme: Theme,
) => {
  if (isNil(value)) return 0;
  const text = String(value);
  return Math.ceil(
    gridStyle.cellHorizontalPaddingPx * 2 +
      measureString(text, {
        fontSize: gridStyle.fontSize,
        fontFamily: gridStyle.fontFamily({ theme }),
        fontWeight,
      }),
  );
};

/** Returns a callback that measures the width of a string in pixels as if it were in an ag-grid, including cell padding. */
export function useMeasureGridText() {
  const theme = useTheme();
  const gridStyle = useAgGridStyle();
  return useCallback(
    (text: GridValue, fontWeight: 'normal' | 'bold') => measureGridText(text, fontWeight, gridStyle, theme),
    [gridStyle, theme],
  );
}

/** Returns a deterministic and prototypical example of a value for use with {@link measureGridText} to estimate widths for data cells. */
export const generateSampleValueToMeasure = ({
  mode,
  integerDigits,
  fractionalDigits,
  canBeNegative,
}: {
  mode: 'currency' | 'percent' | 'number';
  integerDigits: number;
  fractionalDigits: number;
  canBeNegative: boolean;
}) => {
  const wideDigit = '8';
  const negative = canBeNegative ? '-' : '';
  const fractionPart = fractionalDigits > 0 ? `.${wideDigit.repeat(fractionalDigits)}` : '';
  const integerPart = integerDigits > 0 ? wideDigit.repeat(integerDigits) : '0';
  return `${negative}${mode === 'currency' ? '$' : ''}${integerPart}${fractionPart}${mode === 'percent' ? '%' : ''}`;
};

/**
 * Returns the number of pixels that supports showing the provided header text without breaking words across lines.
 *
 * Prefer using {@link useMeasureHeader} unless for some reason you need an imperative version.
 */
export const measureHeader = (
  header: string,
  theme: Theme,
  gridStyle: GridStyle,
  { splitAtHyphen }: { splitAtHyphen: boolean } = { splitAtHyphen: true },
) => {
  const modifyHyphen = (text: string) => (splitAtHyphen ? text.replaceAll(/(\w)-(\w)/gi, '$1- $2') : text);

  return Math.max(
    ...modifyHyphen(header)
      .split(' ')
      .map((word) => measureGridText(word, 'bold', gridStyle, theme)),
    measureGridText('1000.00%', 'bold', gridStyle, theme),
  );
};

/**
 * Returns a callback that computes the number of pixels to show the provided header text without breaking words across lines.
 */
export const useMeasureHeader = () => {
  const theme = useTheme();
  const gridStyle = useAgGridStyle();
  return useCallback((header: string) => measureHeader(header, theme, gridStyle), [gridStyle, theme]);
};
