import React from 'react';
import styled, { css } from 'styled-components';
import type { AnalysisSubject } from 'venn-utils';
import { GetColor, Icon, StudioButton, Tooltip, TooltipPosition } from 'venn-ui-kit';
import { isNil } from 'lodash';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import type { Subject } from 'venn-state';
import { openAllocatorSubject, allocatorAnalysisSubject, isAnyAllocatorOpen, isReportState } from 'venn-state';

interface AllocationPanelLauncherActionProps {
  subject: AnalysisSubject;
}

const AllocationPanelLauncherAction = ({ subject }: AllocationPanelLauncherActionProps) => {
  const [subjectInAllocator, onOpenSubjectInAllocator] = useRecoilState(openAllocatorSubject);
  const studioSubjectId = { portfolioId: subject.portfolio?.id, fundId: undefined };
  const setAllocatorAnalysisSubject = useSetRecoilState(allocatorAnalysisSubject(studioSubjectId));
  const disabled = useRecoilValue(isAnyAllocatorOpen);
  const isReport = useRecoilValue(isReportState);

  if (isReport || subject.type !== 'portfolio' || isNil(subject.portfolio)) {
    return null;
  }

  const tooltipMessage = generateTooltipMessage(disabled, subject, subjectInAllocator);

  return (
    <Tooltip content={tooltipMessage} position={TooltipPosition.Left}>
      <StudioButton
        size="tiny"
        disabled={disabled}
        onClick={() => {
          setAllocatorAnalysisSubject(subject);
          onOpenSubjectInAllocator(studioSubjectId);
        }}
      >
        <CalculatorIcon type="calculator" disabled={disabled} />
      </StudioButton>
    </Tooltip>
  );
};

const generateTooltipMessage = (isDisabled: boolean, subject: AnalysisSubject, subjectInAllocator?: Subject) => {
  if (!isDisabled) {
    return 'View portfolio allocations';
  }
  return subject.id !== (subjectInAllocator?.fundId ?? subjectInAllocator?.portfolioId)
    ? 'Close currently open allocations before viewing this item'
    : '';
};

export const CalculatorIcon = styled(Icon)<{ disabled: boolean }>`
  font-size: 10px;
  margin: 0 4px;
  line-height: 14px;
  color: ${GetColor.Primary.Dark};

  &:hover {
    cursor: pointer;
    color: ${GetColor.Primary.Main};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      cursor: default;
      &:hover {
        cursor: default;
      }
      color: ${GetColor.Grey};
    `}
`;

export default AllocationPanelLauncherAction;
