import type { CurrencyEnum, TableWorkspace } from 'venn-api';
import { getAppTitleSimplifiedUtil } from '../../../utils';

const organizations: TableWorkspace[] = [
  {
    id: 1,
    name: 'Two Sigma Dev',
    description: `Creators behind ${getAppTitleSimplifiedUtil()}!`,
    created: 1481587109721,
    permissionGroupId: 2,
    mfaRequired: false,
    currency: 'USD' as CurrencyEnum,
    countryCode: 'US',
    locality: 'Arizona',
    language: 'EN',
    workspaceName: 'Two Sigma Dev',
    legalName: 'Two Sigma Dev',
    organizationType: 'CONSULTANT_FOF',
    aum: { id: '1_10', name: 'US$ 1 - 10B', approximateAUM: 10.0 },
    accountAdministrator: '',
    avatarId: '',
    accountType: 'DEMO',
    status: 'ACTIVE',
    account: {
      id: 1,
      name: 'Two Sigma Dev',
      countryCode: 'US',
      accountType: 'DEMO',
      legalName: 'Two Sigma Dev',
      organizationType: 'CONSULTANT_FOF',
      aum: { id: '1_10', name: 'US$ 1 - 10B', approximateAUM: 10.0 },
      locality: 'Arizona',
      created: 1551196053796,
      issuesPublicEquity: true,
      accountTier: 'PRO',
      status: 'ACTIVE',
      requiresMfa: false,
      sponsorAccountId: 1,
      isSponsor: true,
    },
  },
  {
    id: 1628,
    name: 'Copy',
    description: '',
    created: 1554127749849,
    permissionGroupId: 3404,
    mfaRequired: false,
    currency: 'USD' as CurrencyEnum,
    countryCode: 'US',
    locality: 'Arizona',
    language: 'EN',
    workspaceName: 'Copy',
    legalName: 'Two Sigma Dev',
    organizationType: 'CONSULTANT_FOF',
    aum: { id: '1_10', name: 'US$ 1 - 10B', approximateAUM: 10.0 },
    accountAdministrator: '',
    avatarId: '',
    accountType: 'DEMO',
    status: 'ACTIVE',
    account: {
      id: 1,
      name: 'Two Sigma Dev',
      countryCode: 'US',
      accountType: 'DEMO',
      legalName: 'Two Sigma Dev',
      organizationType: 'CONSULTANT_FOF',
      aum: { id: '1_10', name: 'US$ 1 - 10B', approximateAUM: 10.0 },
      locality: 'Arizona',
      created: 1551196053796,
      issuesPublicEquity: true,
      accountTier: 'PRO',
      status: 'ACTIVE',
      requiresMfa: false,
      sponsorAccountId: 1,
      isSponsor: true,
    },
  },
];

export default organizations;
