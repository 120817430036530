import type { AtomEffect } from 'recoil';
import { DefaultValue } from 'recoil';
import type { StateSignals } from '../signals';
import { serializeSignal } from '../signals';
import type { Subject } from '../studio/types';

/**
 * Creates an atom effect that runs the given action when the given javascript event
 * of type signal is fired
 * @param signal the signal to listen to
 * @param action the action to perform when the signal is fired
 */
export const createSignalEffect =
  <T>(signal: StateSignals, action: (props: Parameters<AtomEffect<T>>[0]) => void): AtomEffect<T> =>
  (props) => {
    const listener = () => action(props);
    const signalString = serializeSignal(signal);

    document.addEventListener(signalString, listener);

    return () => document.removeEventListener(signalString, listener);
  };

/**
 * Creates an atom effect that increments the atom when the given signal is fired
 * The atom that this effect is attached to MUST be of type number
 * @param signal the signal to increment the atom on
 */
export const createIncrementOnSignalEffect = (signal: StateSignals) =>
  createSignalEffect<number>(signal, ({ setSelf }) => {
    setSelf((current) => {
      if (current instanceof DefaultValue) {
        return 1;
      }
      return current + 1;
    });
  });

/**
 * Creates an atom effect that resets the atom when the given signal is fired
 * @param signal the signal to reset the atom on
 */
export const createResetOnSignalEffect = (signal: StateSignals) =>
  /* eslint-disable @typescript-eslint/no-explicit-any */
  createSignalEffect<any>(signal, ({ resetSelf }) => resetSelf());

export const incrementOnStudioReset = createIncrementOnSignalEffect({ type: 'StudioReset' });

export const incrementOnSubjectChange = (subject: Subject) =>
  createIncrementOnSignalEffect({ type: 'SubjectUpdate', subject });

export const incrementOnWorkspaceConfigurationChange = createIncrementOnSignalEffect({
  type: 'WorkspaceConfigurationUpdate',
});

export const resetOnStudioReset = createResetOnSignalEffect({ type: 'StudioReset' });
