import type { Ref } from 'react';
import React from 'react';
import styled from 'styled-components';
import { StudioTile } from 'venn-components';
import { GetColor } from 'venn-ui-kit';
import { type CustomizableBlockSetting, FIGURE_ICONS, getBlockTileLabel } from 'venn-utils';
import { getDynamicIcon } from '../../logic/studioUtils';

const DragCard = ({
  blockSetting,
  iconsWrapperRef,
  draggable,
  disabled,
}: {
  blockSetting: CustomizableBlockSetting;
  iconsWrapperRef: Ref<HTMLDivElement>;
  draggable?: boolean;
  disabled?: boolean;
}) => {
  const DEFAULT_TYPE = getDynamicIcon(blockSetting);
  const tileLabel = getBlockTileLabel(blockSetting.customBlockType);
  return (
    <Card hasLabel={!!tileLabel} draggable={draggable} disabled={disabled}>
      <IconsWrapper ref={iconsWrapperRef}>
        {blockSetting.supportedGraphicTypes.length ? (
          blockSetting.supportedGraphicTypes.map((type) => (
            <StudioTile prefix="fal" small key={type} type={FIGURE_ICONS[type]} />
          ))
        ) : (
          <StudioTile prefix="fal" small type={DEFAULT_TYPE} />
        )}
      </IconsWrapper>
      <TextWrapper>{blockSetting.title}</TextWrapper>
      {tileLabel && <LabelWrapper>{tileLabel}</LabelWrapper>}
    </Card>
  );
};

export default DragCard;

const TextWrapper = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex: 1;
  font-size: 14px;
`;

const IconsWrapper = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 5px;
`;

const LabelWrapper = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: baseline;
  height: 16px;
  border-radius: 4px;
  padding: 7px 6px;
  margin: 2px 0;
  border: 1px solid ${GetColor.Grey};
  background: ${GetColor.White};
  font-size: 8px;
  line-height: 2px;
  font-weight: 500;
`;

const Card = styled.div<{ draggable?: boolean; disabled?: boolean; hasLabel?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 125px;
  height: ${({ hasLabel }) => (hasLabel ? '92px' : '82px')};
  padding: 5px;
  background-color: ${GetColor.GreyScale.Grey10};
  border: 1px solid ${GetColor.GreyScale.Grey20};
  cursor: ${({ draggable, disabled }) => (draggable ? 'move' : !disabled ? 'pointer' : 'auto')};

  :hover {
    ${({ disabled, theme }) => !disabled && `background-color: ${theme.Colors.GreyScale.Grey20}`};
  }
`;
