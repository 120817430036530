import profile from '../profile';
import type { Portfolio } from 'venn-api';
import { getAppTitleSimplifiedUtil } from '../../../utils';

const portfolio = {
  id: 495962,
  name: 'A',
  owner: profile,
  allocation: 6,
  created: 1583244951144,
  updated: 1583244951166,
  order: 1,
  master: false,
  draft: false,
  demo: false,
  remoteType: 'LOCAL',
  children: [
    {
      allocation: 3,
      children: [],
      compare: [],
      id: 1583245815255,
      fund: {
        id: '0115968d-1e0a-49e8-bd6e-5e3a2c684b49',
        name: 'Managed Futures Investment Category (MF)',
        owner: {
          id: -42,
          name: `${getAppTitleSimplifiedUtil()}`,
          displayName: `${getAppTitleSimplifiedUtil()}`,
          email: null,
          active: false,
          created: null,
          pwdLastUpdated: null,
          avatarId: null,
          actualUser: false,
        },
        frequency: 1,
        startRange: 783734399999,
        endRange: 1582934399999,
        created: 1579907551763,
        updated: 1583146784594,
        fundSourceType: {
          id: 1,
          name: 'Investment',
        },
        userUploaded: false,
        investmentBenchmarks: [],
        active: true,
        live: true,
        symbol: 'MF_MAN_FUTURES',
        investmentSource: 'VENN',
        investmentType: 'INDEX',
        assetType: 'CATEGORY_GROUPING',
        remoteType: 'DATA',
        currency: 'USD',
        tags: [],
        favorite: false,
        viewed: false,
        unproxiedNumReturns: 6609,
        categoryAssetType: {
          key: 'MUTUAL_FUND',
          name: 'Mutual Fund',
        },
        categoryGroup: {
          assetType: 'MUTUAL_FUND',
          key: 'MF_MAN_FUTURES',
          name: 'Managed Futures',
          categoryId: '0115968d-1e0a-49e8-bd6e-5e3a2c684b49',
        },
        needsProxy: false,
        needsInterpolation: false,
        benchmarks: [],
        outOfDate: false,
        userControlled: false,
        returnFrequency: 'DAILY',
        proxyable: true,
        interpolatable: false,
        hasMinDataForInterpolation: true,
        userEditable: false,
      },
      name: 'Managed Futures Investment Category (MF)',
      periodStart: 783734399999,
      periodEnd: 1582847999999,
    },
    {
      allocation: 3,
      children: [],
      compare: [],
      id: 1583245776159,
      fund: {
        id: '090891d4-b30b-4e50-9331-d5a52ae27ae8',
        name: 'Other Investment Category (HF)',
        owner: {
          id: -42,
          name: `${getAppTitleSimplifiedUtil()}`,
          displayName: `${getAppTitleSimplifiedUtil()}`,
          email: null,
          active: false,
          created: null,
          pwdLastUpdated: null,
          avatarId: null,
          actualUser: false,
        },
        frequency: 2,
        startRange: 478483199999,
        endRange: 1583020799999,
        created: 1579907551893,
        updated: 1583146784874,
        fundSourceType: {
          id: 1,
          name: 'Investment',
        },
        userUploaded: false,
        investmentBenchmarks: [],
        active: true,
        live: true,
        symbol: 'HF_OTHER',
        investmentSource: `${getAppTitleSimplifiedUtil()}`.toUpperCase(),
        investmentType: 'INDEX',
        assetType: 'CATEGORY_GROUPING',
        remoteType: 'DATA',
        currency: 'USD',
        tags: [],
        favorite: false,
        viewed: false,
        unproxiedNumReturns: 421,
        categoryAssetType: {
          key: 'HEDGE_FUND',
          name: 'Hedge Fund',
        },
        categoryGroup: {
          assetType: 'HEDGE_FUND',
          key: 'HF_OTHER',
          name: 'Other',
          categoryId: '090891d4-b30b-4e50-9331-d5a52ae27ae8',
        },
        needsProxy: false,
        needsInterpolation: false,
        benchmarks: [],
        outOfDate: false,
        userControlled: false,
        returnFrequency: 'MONTHLY',
        proxyable: true,
        interpolatable: false,
        hasMinDataForInterpolation: true,
        userEditable: false,
      },
      name: 'Other Investment Category (HF)',
      periodStart: 478483199999,
      periodEnd: 1580515199999,
    },
  ],
  compare: [
    {
      name: 'Other Investment Category (MF)',
      fundId: '090891d4-b30b-4e50-9331-d5a52ae27ae8',
      benchmark: true,
      primary: true,
    },
  ],
  locked: false,
  editable: true,
  frequencyId: 1,
  messages: [],
  periodStart: 1325289599999,
  periodEnd: 1501631999999,
  rebalanceStrategy: 3,
};

export default portfolio as unknown as Portfolio;
