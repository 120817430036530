import type { RecoilState } from 'recoil';
import { useRecoilState } from 'recoil';
import type React from 'react';
import { useCallback, useEffect, useState } from 'react';

export const useNameStateEditor = (nameState: RecoilState<string>) => {
  const [name, setName] = useRecoilState(nameState);
  const [tempName, setTempName] = useState(name);
  useEffect(() => setTempName(name), [name]);

  const onNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setTempName(e.target.value), []);
  const onNameApply = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // If the new name is valid, use it, otherwise reset the temp name back to the valid current name.
      return e.target.value ? setName(e.target.value) : setTempName(name);
    },
    [name, setName],
  );

  return {
    name: tempName,
    onNameChange,
    onNameApply,
  };
};
