import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div``;

const Error = styled.div`
  margin-top: 10px;
  color: #e61717;
  font-size: 13px;
`;

export interface FieldProps {
  className?: string;
  error?: string;
}

const Field: FC<React.PropsWithChildren<FieldProps>> = ({ children, className, error }) => (
  <Wrapper className={className}>
    <div>{children}</div>
    {error && <Error>{error}</Error>}
  </Wrapper>
);

export default Field;
