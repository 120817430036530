import React from 'react';
import styled, { css } from 'styled-components';
import { ColorUtils, GetColor } from '../../style';
import { Icon } from '../icon/Icon';
import { buttonize, toClassName } from 'venn-utils';

interface FilterPillProps {
  iconType?: string;
  onClick?: () => void;
  onClose?: () => void;
  name: React.ReactNode;
}

const FilterPill = ({ iconType, name, onClick, onClose }: FilterPillProps) => {
  const pillProps = onClick ? buttonize(() => onClick()) : {};

  return (
    <Pill
      className={typeof name === 'string' ? `${toClassName(name)}-filter-bubble qa-filter-bubble` : ''}
      {...pillProps}
    >
      {iconType && <StyledIcon type={iconType} />}
      {name}
      {onClose && (
        <CloseButton
          {...buttonize((e) => {
            e.stopPropagation();
            onClose();
          })}
          type="times"
          className="qa-close-filter"
        />
      )}
    </Pill>
  );
};

export default FilterPill;

const StyledIcon = styled(Icon)`
  font-size: 11px;
  color: ${GetColor.Primary.Dark};
  margin-right: 3px;
  line-height: 12px;
`;

const CloseButton = styled(Icon)`
  font-weight: normal;
  margin-left: 5px;
  color: ${GetColor.Primary.Dark};
  vertical-align: middle;

  :hover {
    color: ${ColorUtils.opacifyDarkFrom(GetColor.Primary.Dark, 0.5)};
  }
`;

const Pill = styled.div<{ onClick?: () => void }>`
  border-radius: 8px;
  background-color: ${ColorUtils.opacifyFrom(GetColor.Primary.Main, 0.15)};
  border: 1px solid ${ColorUtils.opacifyFrom(GetColor.Primary.Dark, 0.5)};
  height: 20px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  margin-right: 5px;
  margin-bottom: 2px;
  font-size: 13px;
  font-weight: 500;
  white-space: nowrap;
  width: fit-content;
  line-height: 1.17;

  ${(props) =>
    props.onClick &&
    css`
      :hover {
        background-color: ${ColorUtils.opacifyFrom(GetColor.Primary.Main, 0.3)};
      }
      cursor: pointer;
    `}
`;
