import React, { useCallback } from 'react';
import SelectorRow from '../SelectorRow';
import type { PortfolioRebalancingStrategyEnum } from 'venn-api';
import type { DropMenuItem } from 'venn-ui-kit';
import { StyledMetaDataFields } from '../styles';
import { REBALANCE_STRATEGY_ARTICLE_HREF } from 'venn-ui-kit';
import { useFF } from 'venn-utils';

const strategyOptions: DropMenuItem<PortfolioRebalancingStrategyEnum>[] = [
  {
    value: 'CONTINUOUS',
    label: 'Continuous',
    description:
      'This strategy keeps weights constant by regularly rebalancing at the same frequency as your returns. I.e. monthly data will be rebalanced monthly and daily data will be rebalanced daily.',
  },
  {
    value: 'MONTHLY',
    label: 'Monthly',
    description: 'This strategy rebalances the portfolio on a monthly basis on the first day of the month.',
  },
  {
    value: 'QUARTERLY',
    label: 'Quarterly',
    description: 'This strategy rebalances the portfolio every quarter on the first day of the quarter.',
  },
  {
    value: 'SEMIANNUAL',
    label: 'Semi-Annual',
    description: 'This strategy rebalances the portfolio twice a year, on January 1st and July 1st.',
  },
  {
    value: 'ANNUAL',
    label: 'Annual',
    description: 'This strategy rebalances the portfolio once a year on January 1st.',
  },
  {
    value: 'CUSTOM_REBALANCE',
    label: 'Custom Rebalance',
    description: 'This strategy rebalances the portfolio on a custom monthly schedule.',
  },
  {
    value: 'NO_REBALANCE',
    label: 'No Rebalance',
    description: 'This strategy does not rebalance the portfolio.',
  },
];

interface RebalanceStrategySelectorProps {
  strategy?: PortfolioRebalancingStrategyEnum;
  onSelect: (strategy: PortfolioRebalancingStrategyEnum) => void;
  loading: boolean;
  isEditable?: boolean;
}

const RebalanceStrategySelector = ({ strategy, onSelect, loading, isEditable }: RebalanceStrategySelectorProps) => {
  const hasNewRebalanceFF = useFF('new_rebalance_ff');
  const rebalanceOptions = hasNewRebalanceFF
    ? strategyOptions
    : strategyOptions.filter((i) => !['CUSTOM_REBALANCE', 'NO_REBALANCE'].includes(i.value));
  const onSelectOption = useCallback(
    (option: DropMenuItem<PortfolioRebalancingStrategyEnum>) => onSelect(option.value),
    [onSelect],
  );

  return (
    <StyledMetaDataFields>
      <SelectorRow<PortfolioRebalancingStrategyEnum>
        label="Rebalance Frequency"
        subLabel={
          <>
            The selected frequency will be used to rebalance the weights/NAVs in your portfolio to your given
            allocations.{' '}
            <a target="_blank" rel="noopener noreferrer" href={REBALANCE_STRATEGY_ARTICLE_HREF}>
              Learn more
            </a>
          </>
        }
        options={rebalanceOptions}
        value={strategy ?? 'QUARTERLY'}
        onSelect={onSelectOption}
        loading={loading}
        isEditable={!!isEditable}
        showDisabledStyling={false}
      />
    </StyledMetaDataFields>
  );
};

export default RebalanceStrategySelector;
