import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';

interface SmallBannerProps {
  message?: string;
  error?: boolean;
}

export class SmallBanner extends PureComponent<SmallBannerProps> {
  render() {
    const { message, error } = this.props;
    return (
      <Wrapper error={error}>
        <div className="qa-sent-message">{message}</div>
      </Wrapper>
    );
  }
}

export default SmallBanner;

const Wrapper = styled.div<{ error?: boolean }>`
  position: absolute;
  display: flex;
  padding: 4px 36px 9px;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 20px;
  background-color: ${(props) => (props.error ? GetColor.Primary.Dark : GetColor.Primary.Main)};
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  border-radius: 0 0 8px 8px;
  width: 80%;
`;
