import type { ReactNode } from 'react';
import React, { useContext } from 'react';
import { GetColor, ColorUtils, Tooltip, Icon, TooltipPosition } from 'venn-ui-kit';
import type {
  BasicTableProps,
  BasicTableColumn,
  ContentChartLegendItem,
  ScenarioAnalysisResult,
  StyledTableType,
} from 'venn-components';
import {
  BasicTable,
  ContentChartLegend,
  ShockInput,
  scenarioReturnHeaderRenderer,
  renderHead,
  getMax,
  hasBenchmarkData,
  hasComparisonData,
  hasCategoryData,
  scenarioSortTableFunc,
} from 'venn-components';
import styled, { ThemeContext } from 'styled-components';
import type { ScenarioAnalysis, ScenarioAnalysisIndex, Scenario, ScenarioShockRange } from 'venn-api';
import type { AnalysisConfig } from 'venn-utils';
import { getAnalysisLabels, Numbers, SpecialCssClasses } from 'venn-utils';
import BarChart, { BarChartAxisFooter } from './BarChart';
import NewScenario, { TrashIcon } from './NewScenario';
import ScenarioPredictCell from './ScenarioPredictCell';
import compact from 'lodash/compact';

const { safeFormatNumber } = Numbers;
const formatNumber = (value: number | undefined) => safeFormatNumber(value! * 100, 1);
const HoverBackgroundColor = ColorUtils.hex2rgbaFrom(GetColor.Primary.Main, 0.1);

interface ScenarioAnalysisTableProps {
  onDelete: (scenarioId: string) => void;
  onAdd: (fundId: string, shock: number, rawValue: number) => void;
  onChange: (scenario: Partial<Scenario>, rawValue: number) => void;
  scenariosResult: ScenarioAnalysisResult[];
  analysisConfig: AnalysisConfig;
  indices: ScenarioAnalysisIndex[];
}

const ScenarioAnalysisTable = ({
  scenariosResult,
  analysisConfig,
  onDelete,
  onChange,
  indices,
  onAdd,
}: ScenarioAnalysisTableProps) => {
  const {
    Colors,
    Schemes: { BarChartColors },
  } = useContext(ThemeContext);
  const barColors = [BarChartColors.mainColor, BarChartColors.secondaryColor, BarChartColors.comparisonColor];
  const failedRowColor = ColorUtils.opacify(Colors.Error, 0.1);

  if (!analysisConfig.subject) {
    return null;
  }
  const labels = getAnalysisLabels(
    analysisConfig.subject.type,
    analysisConfig.subject.secondaryLabel,
    analysisConfig.subject.secondaryPortfolio?.updated,
  );
  const hasData = scenariosResult.length > 0;
  const hasBenchmark = hasBenchmarkData(analysisConfig, scenariosResult);
  const hasComparison = hasComparisonData(analysisConfig, scenariosResult);
  const hasCategory = hasCategoryData(analysisConfig, scenariosResult);

  const mainName = analysisConfig.subject.name;
  const benchmarkName = analysisConfig.subject.activeBenchmarkName;
  const comparisonName = analysisConfig.subject.secondaryPortfolio?.name;
  const categoryName = analysisConfig.subject.categoryGroup?.name;

  const max = getMax(scenariosResult);
  const chartColSpan =
    1 + (Number(!!hasData) + Number(!!hasComparison) + Number(!!hasBenchmark) + Number(!!hasCategory)) * 2;
  const relative = analysisConfig.relative;

  // TODO(VENN-24534): add a display name to this React component
  // eslint-disable-next-line react/display-name
  const renderTail = (hasResult: boolean) => () => (
    <>
      {hasResult && <BarChartAxisFooter colSpan={chartColSpan + 2} max={max} />}

      <NewScenario onAdd={onAdd} indices={indices} hasScenario={hasResult} />
    </>
  );

  const headerStyleRightAligned = {
    minWidth: 70,
    maxWidth: 100,
    textAlign: 'right' as const,
    paddingRight: 10,
    paddingLeft: 0,
  };

  const cellStyleRightAligned = {
    textAlign: 'right' as const,
  };

  const getColumns = (): BasicTableColumn<ScenarioAnalysisResult>[] =>
    compact([
      {
        label: '',
        accessor: 'delete',
        headerStyle: {
          width: 20,
          maxWidth: 20,
        },
        cellStyle: (scenarioAnalysis) =>
          cellStyle({
            style: {
              textAlign: 'center' as const,
            },
            scenarioAnalysis,
            failedRowColor,
          }),
        cellRenderer: (data: ScenarioAnalysisResult) => (
          <TrashIcon type="trash" onClick={() => onDelete(data.id)} className={SpecialCssClasses.NotDownloadable} />
        ),
      },
      {
        label: 'Input',
        accessor: 'index',
        headerStyle: {
          paddingRight: 5,
        },
        cellStyle: (scenarioAnalysis) => cellStyle({ style: {}, scenarioAnalysis, failedRowColor }),
        cellRenderer: (data: ScenarioAnalysisResult) => (
          <SubjectNameContainer>
            <div>{data.fundName}</div>
            <div>
              {containsFailure(data) ? (
                <Tooltip
                  content="We could not return some values for your scenario return input. Try decreasing the value."
                  maxWidth={240}
                >
                  <ErrorIcon type="exclamation-triangle" />
                </Tooltip>
              ) : null}
            </div>
          </SubjectNameContainer>
        ),
      },
      {
        label: 'Shock Value',
        accessor: 'Return',
        headerStyle: {
          width: 115,
          maxWidth: 115,
          minWidth: 115,
          textAlign: 'right' as const,
        },
        cellStyle: (scenarioAnalysis) =>
          cellStyle({
            style: {
              textAlign: 'right' as const,
            },
            scenarioAnalysis,
            failedRowColor,
          }),
        headerRenderer: scenarioReturnHeaderRenderer,
        cellRenderer: (data: ScenarioAnalysisResult) => {
          const shockRange: ScenarioShockRange = {
            minShock: data.minShock!,
            maxShock: data.maxShock!,
            minShockZScore: data.minShockZScore!,
            maxShockZScore: data.maxShockZScore!,
          };
          return (
            <ShockInput
              shock={data.shock}
              mean={data.mean}
              shockRange={shockRange}
              onChange={(value: number, rawValue: number) =>
                onChange(
                  {
                    fundId: data.fundId,
                    fundName: data.fundName,
                    id: data.id,
                    mean: data.mean,
                    shock: value,
                    units: data.units,
                  },
                  rawValue,
                )
              }
              units={data.units}
              fundName={data.fundName}
              tooltipPosition={TooltipPosition.Bottom}
            />
          );
        },
      },
      hasData
        ? {
            label: labels.main,
            sortable: true,
            sortTableFunc: scenarioSortTableFunc('mainPredict'),
            accessor: 'mainPredict',
            headerStyle: { ...headerStyleRightAligned, color: barColors[0] },
            cellStyle: (scenarioAnalysis) =>
              cellStyle({ style: cellStyleRightAligned, scenarioAnalysis, failedRowColor }),
            cellRenderer: ({ mainPredict }: ScenarioAnalysisResult) =>
              renderPredictCell(relative, mainPredict, mainName),
          }
        : undefined,
      hasData
        ? {
            accessor: 'mainPredictError',
            headerStyle: {
              color: barColors[0],
              width: 35,
            },
            cellStyle: (scenarioAnalysis) =>
              cellStyle({ style: { color: Colors.MidGrey1 }, scenarioAnalysis, failedRowColor }),
            headerRenderer: renderErrorBoundHeader,
            cellRenderer: ({ mainPredict }: ScenarioAnalysisResult) =>
              renderPredictCell(relative, mainPredict, mainName, true),
          }
        : undefined,
      hasComparison
        ? {
            label: labels.comparison,
            accessor: 'comparisonPredict',
            sortTableFunc: scenarioSortTableFunc('comparisonPredict'),
            sortable: true,
            headerStyle: { ...headerStyleRightAligned, color: barColors[1] },
            cellStyle: (scenarioAnalysis) =>
              cellStyle({ style: cellStyleRightAligned, scenarioAnalysis, failedRowColor }),
            cellRenderer: ({ comparisonPredict }: ScenarioAnalysisResult) =>
              renderPredictCell(relative, comparisonPredict, comparisonName),
          }
        : undefined,
      hasComparison
        ? {
            accessor: 'comparisonPredictError',
            headerStyle: {
              color: barColors[1],
              width: 35,
            },
            cellStyle: (scenarioAnalysis) =>
              cellStyle({ style: { color: Colors.MidGrey1 }, scenarioAnalysis, failedRowColor }),
            headerRenderer: renderErrorBoundHeader,
            cellRenderer: ({ comparisonPredict }: ScenarioAnalysisResult) =>
              renderPredictCell(relative, comparisonPredict, comparisonName, true),
          }
        : undefined,
      hasBenchmark
        ? {
            label: labels.benchmark,
            accessor: 'benchmarkPredict',
            sortTableFunc: scenarioSortTableFunc('benchmarkPredict'),
            sortable: true,
            headerStyle: { ...headerStyleRightAligned, color: barColors[hasComparison ? 2 : 1] },
            cellStyle: (scenarioAnalysis) =>
              cellStyle({ style: cellStyleRightAligned, scenarioAnalysis, failedRowColor }),
            cellRenderer: ({ benchmarkPredict }: ScenarioAnalysisResult) =>
              renderPredictCell(relative, benchmarkPredict, benchmarkName),
          }
        : undefined,
      hasBenchmark
        ? {
            accessor: 'benchmarkPredictError',
            headerStyle: {
              color: barColors[hasComparison ? 2 : 1],
              width: 35,
            },
            cellStyle: (scenarioAnalysis) =>
              cellStyle({ style: { color: Colors.MidGrey1 }, scenarioAnalysis, failedRowColor }),
            headerRenderer: renderErrorBoundHeader,
            cellRenderer: ({ benchmarkPredict }: ScenarioAnalysisResult) =>
              renderPredictCell(relative, benchmarkPredict, benchmarkName, true),
          }
        : undefined,
      hasCategory
        ? {
            label: labels.category,
            accessor: 'comparisonPredict',
            sortTableFunc: scenarioSortTableFunc('comparisonPredict'),
            sortable: true,
            headerStyle: { ...headerStyleRightAligned, color: barColors[2] },
            cellStyle: (scenarioAnalysis) =>
              cellStyle({ style: cellStyleRightAligned, scenarioAnalysis, failedRowColor }),
            cellRenderer: ({ comparisonPredict }: ScenarioAnalysisResult) =>
              renderPredictCell(relative, comparisonPredict, categoryName),
          }
        : undefined,
      hasCategory
        ? {
            accessor: 'comparisonPredictError',
            headerStyle: {
              color: barColors[2],
              width: 35,
            },
            cellStyle: (scenarioAnalysis) =>
              cellStyle({ style: { color: Colors.MidGrey1 }, scenarioAnalysis, failedRowColor }),
            headerRenderer: renderErrorBoundHeader,
            cellRenderer: ({ comparisonPredict }: ScenarioAnalysisResult) =>
              renderPredictCell(relative, comparisonPredict, categoryName, true),
          }
        : undefined,
      {
        label: '',
        accessor: 'chart',
        headerStyle: {
          minWidth: 200,
        },
        cellStyle: (scenarioAnalysis) =>
          cellStyle({
            style: {
              borderLeft: `dashed 1px ${Colors.Grey}`,
              borderRight: `dashed 1px ${Colors.Grey}`,
              paddingLeft: 0,
              paddingRight: 0,
            },
            scenarioAnalysis,
            failedRowColor,
          }),
        cellRenderer: (data: ScenarioAnalysisResult) => (
          <BarChart
            max={max}
            mainData={data.mainPredict?.predicted}
            benchmarkData={data.benchmarkPredict?.predicted}
            comparisonData={hasComparison ? data.comparisonPredict?.predicted : undefined}
            categoryData={hasCategory ? data.comparisonPredict?.predicted : undefined}
            loading={data.loading}
          />
        ),
      },
    ]);
  const legendItems: ContentChartLegendItem[] = compact([
    {
      name: labels.main,
      color: BarChartColors.mainColor,
    },
    hasComparison
      ? {
          name: labels.comparison ?? '',
          color: BarChartColors.secondaryColor,
        }
      : null,
    hasBenchmark
      ? {
          name: labels.benchmark,
          color: hasComparison ? BarChartColors.comparisonColor : BarChartColors.secondaryColor,
        }
      : null,
    hasCategory
      ? {
          name: labels.category ?? '',
          color: hasBenchmark ? BarChartColors.comparisonColor : BarChartColors.secondaryColor,
        }
      : null,
  ]);

  return (
    <div>
      <StyledBasicTable
        data={scenariosResult}
        columns={getColumns()}
        renderHead={renderHead(chartColSpan, relative)}
        renderTail={renderTail(scenariosResult.length > 0)}
      >
        <LegendRow>
          <td colSpan={2} />
          <LegendTd colSpan={chartColSpan + 1}>
            {hasData && <ContentChartLegend items={legendItems} histogram />}
          </LegendTd>
        </LegendRow>
      </StyledBasicTable>
    </div>
  );
};

export default React.memo(ScenarioAnalysisTable);

const renderPredictCell = (relative: boolean, item?: ScenarioAnalysis, fundName?: string, isError?: boolean) =>
  item?.status === 'FAILURE' ? (
    '--'
  ) : isError ? (
    <div>{formatNumber(item?.predictedError)}</div>
  ) : (
    <ScenarioPredictCell subjectName={fundName} scenarioAnalysis={item} relative={relative} />
  );

const renderErrorBoundHeader = () => <Tooltip content="Error band">+/-</Tooltip>;

const containsFailure = (data: ScenarioAnalysisResult) =>
  [data.mainPredict, data.benchmarkPredict, data.comparisonPredict].map((x) => x?.status).includes('FAILURE');

const cellStyle = (props: {
  scenarioAnalysis: ScenarioAnalysisResult;
  style: React.CSSProperties;
  failedRowColor: string;
}): React.CSSProperties =>
  containsFailure(props.scenarioAnalysis) ? { ...props.style, background: props.failedRowColor } : props.style;

export const StyledBasicTable: StyledTableType<{
  children?: ReactNode;
}> = styled(<T extends BasicTableColumn<K>, K>(props: BasicTableProps<T, K>) => <BasicTable<T, K> {...props} />)<{
  children?: ReactNode;
}>`
  margin: 30px 0 35px;
  width: 100%;
  color: ${GetColor.Black};
  && tr {
    border-bottom: none;
  }
  && th {
    color: ${GetColor.Black};
  }
  tr:not(:first-child):hover {
    background-color: ${HoverBackgroundColor};
  }
  && thead tr:hover {
    background-color: transparent;
  }
  @media print {
    .${SpecialCssClasses.NotDownloadable} {
      display: none;
    }
  }
`;

const LegendRow = styled.tr`
  background-color: ${GetColor.WhiteGrey};
  height: 56px;
`;

const LegendTd = styled.td`
  > div {
    border-top: none;
    border-bottom: none;
    padding-left: 0;
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
  }
`;

const ErrorIcon = styled(Icon)`
  color: ${GetColor.Error};
`;

const SubjectNameContainer = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
`;
