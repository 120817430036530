import React from 'react';
import styled from 'styled-components';
import { Button as ButtonBase, GetColor, Icon, Tooltip } from 'venn-ui-kit';

interface ForecastOverrideActionsProps {
  isSubmitButtonDisabled: boolean;
  isCancelButtonDisabled: boolean;
  onCancelClick: () => void;
  onApplyClick: () => void;
  tooltipContent: string;
  tooltipSideText: string;
  noTooltip?: boolean;
  applyButtonLabel?: string;
}

export const ForecastOverrideActions = ({
  isSubmitButtonDisabled,
  isCancelButtonDisabled,
  onCancelClick,
  onApplyClick,
  tooltipContent,
  tooltipSideText,
  noTooltip,
  applyButtonLabel = 'APPLY',
}: ForecastOverrideActionsProps) => {
  return (
    <Actions>
      <div>
        <Button dense disabled={isCancelButtonDisabled} onClick={onCancelClick} tabIndex={0}>
          CANCEL
        </Button>
        <Button type="submit" dense dominant disabled={isSubmitButtonDisabled} onClick={onApplyClick} tabIndex={0}>
          {applyButtonLabel}
        </Button>
      </div>
      {noTooltip ? (
        <Label>{tooltipSideText}</Label>
      ) : (
        <OverrideTooltip content={tooltipContent}>
          {tooltipSideText}
          <InfoIcon type="info-circle" />
        </OverrideTooltip>
      )}
    </Actions>
  );
};

const Button = styled(ButtonBase)`
  margin-right: 0;
  margin-left: 10px;
`;

const Actions = styled.div`
  align-items: center;
  margin-right: 10px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
`;

const InfoIcon = styled(Icon)`
  color: ${GetColor.Primary.Dark};
  margin-left: 5px;
`;

const OverrideTooltip = styled(Tooltip)`
  font-size: 14px;
  font-weight: bold;
`;

const Label = styled.span`
  font-size: 14px;
  font-weight: bold;
`;
