import type { RefObject } from 'react';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import MediaQuery from 'react-responsive';
import type { AnalysesPeriod, AnalysisSubject, TimeFrame, CategoryConfig, AnalysisConfig } from 'venn-utils';
import {
  Dates,
  getDateMode,
  analyticsService,
  AnalyticsUtils,
  shouldUserUpdateDataForFund,
  assertNotNil,
  useHasFF,
} from 'venn-utils';
import {
  AnalyzeInvestmentModalsLauncher,
  FactorLensInfo,
  ForecastsMenuLauncher,
  ManageInvestmentDataTrigger,
  ManagePortfolioDataTrigger,
  StickyNode,
  InvestmentDataFixActionsButton,
  UserContext,
} from 'venn-components';
import type { GeneralAnalysisTemplate, Fund } from 'venn-api';
import { getFund } from 'venn-api';
import type { DateRange, RangeType } from 'venn-ui-kit';
import {
  ButtonIcon,
  ColorUtils,
  DateRangePicker,
  GetColor,
  Icon,
  Link as LinkStyle,
  PrintSubjectBar,
  PrintSubjectLeft,
  PrintSubjectRight,
  PrintSubjectLabel,
  PrintAnalysisTemplate,
  Tooltip,
  TooltipPosition,
  ZIndex,
  INTERPOLATION_EXTRAPOLATION_FAQ_HREF,
  ShimmerBlock,
} from 'venn-ui-kit';
import { getProxy, getDateRange } from './utils';
import CompareButton from './CompareButton';
import ConstrainedAnalysisAlert from './ConstrainedAnalysisAlert';
import LeftHeaderGroup from './LeftHeaderGroup';
import { HeaderItemLoader, LabelText } from './shared';
import { noop } from 'lodash';
import TagButton from './TagButton';
import WorkspaceTitle from './WorkspaceTitle';
import { shouldShowCategoryPicker } from '../../utils';
import PortfolioLabLauncher from './PortfolioLabLauncher';
import type { MenuOpenerProps } from '../../../../../venn-components/src/modals/forecasts/ForecastsMenuLauncher';
import ExtrapolationInfo from './ExtrapolationInfo';

const ICON_BUTTON_INNER_HEIGHT = 38;

interface HeaderProps {
  analysesPeriod: AnalysesPeriod;
  analysisConfig: AnalysisConfig;
  canToggleRelative: boolean;
  setTimeFrame?: (timeframe: TimeFrame, period?: RangeType) => void;
  setCategoryConfig?: (categoryConfig: CategoryConfig) => void;
  renderMenu?: () => JSX.Element;
  onToggleRelative?: () => void;
  stickyNodeReference?: RefObject<HTMLDivElement>;
  templates?: GeneralAnalysisTemplate[];
  onChangeAnalysisTemplate?: (type: GeneralAnalysisTemplate) => void;
  onEditTemplate?: (id: string) => void;
  onForecastUpdate?: () => void;
  onFundUpdated?: (fund: Fund) => void;
  onChangeSubject?: (item: AnalysisSubject) => void;
  controlsDisabled?: boolean;
  currentViewName?: string;
}

const ForecastsMenuButton = ({ onClick, isReadOnly }: MenuOpenerProps) => (
  <ButtonIcon
    iconType="compass"
    onClick={onClick}
    tooltip={isReadOnly ? 'Edit forecasts' : 'View forecasts'}
    className="qa-forecast-link"
    size={ICON_BUTTON_INNER_HEIGHT}
  />
);

const Header = ({
  analysisConfig,
  setTimeFrame,
  setCategoryConfig,
  renderMenu,
  analysesPeriod,
  canToggleRelative,
  onToggleRelative,
  templates,
  onChangeAnalysisTemplate,
  onEditTemplate,
  onForecastUpdate = noop,
  onFundUpdated,
  stickyNodeReference,
  onChangeSubject,
  controlsDisabled,
  currentViewName,
}: HeaderProps) => {
  const hasOptimization = useHasFF('optimization');
  const { hasPermission } = useContext(UserContext);
  const { subject, relative, selectedTimeFrame, selectedPeriod } = analysisConfig;
  const proxy: Partial<Fund> | null = subject ? getProxy(subject.type, subject.item) : null;
  const category = subject?.type === 'investment' && subject.categoryGroup;
  const categoryOff = analysisConfig?.category === 'OFF';
  const templateId = analysisConfig?.analysisTemplate?.id;
  const showFactorLensNote = templateId === 'factor' || templateId === 'drawdown';

  const [smallHeader, setSmallHeader] = useState(false);
  const [headerShadow, setHeaderShadow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      window.requestAnimationFrame(() => {
        const scrollMain = document.getElementsByTagName('main')[0];
        if (!scrollMain) {
          return;
        }
        const scrollTop = scrollMain.scrollTop;
        if (scrollTop > 140) {
          setSmallHeader(true);
        }
        if (scrollTop < 1) {
          setSmallHeader(false);
        }
        setHeaderShadow(scrollTop > 10);
      });
    };
    window.addEventListener('scroll', handleScroll, true);
    return () => window.removeEventListener('scroll', handleScroll, true);
  }, []);

  const optimizerEnabled = hasOptimization && hasPermission('OPTIMIZATION');
  const showOptimizerButton = optimizerEnabled && subject?.type === 'portfolio';
  const canOptimize = hasPermission('OPTIMIZATION');
  const canCompare = hasPermission('COMPARE');
  const canManageProxy = hasPermission('MANAGE_PROXY');

  const periodValue = useMemo(
    (): DateRange | undefined => getDateRange(selectedTimeFrame, selectedPeriod, analysesPeriod),
    [selectedTimeFrame, analysesPeriod, selectedPeriod],
  );

  const periodValueText = useMemo(() => {
    const frequency = analysesPeriod.frequency;
    const formatAnalysisFrom = periodValue?.from ? Dates.toDDMMMYYYY(periodValue.from, frequency) : '--';
    const formatAnalysisTo = periodValue?.to ? Dates.toDDMMMYYYY(periodValue.to, frequency) : '--';
    return `${formatAnalysisFrom} - ${formatAnalysisTo}`;
  }, [periodValue, analysesPeriod.frequency]);

  const periodRange = useMemo(() => {
    const from = analysesPeriod.maxStartTime || undefined;
    const to = analysesPeriod.maxEndTime || undefined;
    return from === undefined || to === undefined
      ? undefined
      : {
          from,
          to,
        };
  }, [analysesPeriod]);
  const handlePeriodChange = useCallback(
    ({ from, to, period: updatedPeriod }) => {
      setTimeFrame?.(
        {
          startTime: from,
          endTime: to,
        },
        updatedPeriod,
      );
      if (!updatedPeriod) {
        // track update date picker for non-period
        const startDate = AnalyticsUtils.dateFormat(from);
        const endDate = AnalyticsUtils.dateFormat(to);
        analyticsService.ctaClicked({
          filled: false,
          locationOnPage: 'date range picker',
          purpose: 'select date range',
          text: `${startDate}-${endDate}`,
          type: 'date range picker',
        });
      }
    },
    [setTimeFrame],
  );

  const onFundDataUpdated = async (fundId: string, throwOnFailure?: boolean) => {
    try {
      const response = await getFund(fundId);
      await onFundUpdated?.(response.content);
    } catch (e) {
      if (throwOnFailure) {
        throw e;
      }
    }
  };

  const fundId = subject?.fund?.id;

  const screenHeader = (
    <>
      <StickyHeader shadow={headerShadow} reference={stickyNodeReference}>
        <PaddingWrapper>
          <ConstrainedAnalysisAlert analysisConfig={analysisConfig} />
          <StickyTitle smallHeader={smallHeader}>
            <WorkspaceTitle
              addBlock
              loadingSubject={!subject?.id}
              analysisConfig={analysisConfig}
              templates={templates}
              onChangeAnalysisTemplate={onChangeAnalysisTemplate}
              onEditTemplate={onEditTemplate}
              onChangeSubject={onChangeSubject}
              fontSize={smallHeader ? 20 : undefined}
            />
            <ButtonContainer disabled={controlsDisabled}>
              <ButtonBar>
                {hasPermission('MANAGE_TAGS') && (
                  <ButtonWrapper>
                    <TagButton subject={subject} />
                  </ButtonWrapper>
                )}
                {canOptimize && showOptimizerButton && subject && (
                  <ButtonWrapper>
                    <PortfolioLabLauncher subject={subject} buttonSize={ICON_BUTTON_INNER_HEIGHT} />
                  </ButtonWrapper>
                )}
                {canOptimize && subject?.type === 'investment' && subject.superType === 'investment' && (
                  <AnalyzeInvestmentModalsLauncher
                    subject={subject}
                    size={ICON_BUTTON_INNER_HEIGHT}
                    Wrapper={ButtonWrapper}
                  />
                )}
                {canCompare && subject && (
                  <ButtonWrapper>
                    <CompareButton
                      subject={subject}
                      relative={relative}
                      dateRange={{
                        from: selectedTimeFrame.startTime,
                        to: selectedTimeFrame.endTime,
                      }}
                      size={ICON_BUTTON_INNER_HEIGHT}
                    />
                  </ButtonWrapper>
                )}
                <ButtonWrapper>
                  <ForecastsMenuLauncher
                    iconOnly
                    onDefaultForecastUpdated={onForecastUpdate}
                    onResidualForecastUpdated={onForecastUpdate}
                    fund={subject?.fund}
                    portfolio={subject?.portfolio}
                    ctaPurpose="Edit forecasts from Analysis header"
                    renderCustomMenuOpener={(props) => <ForecastsMenuButton {...props} />}
                    isReadOnly={!hasPermission('EDIT_FORECASTS')}
                  />
                </ButtonWrapper>
              </ButtonBar>

              {subject?.fund && (
                <InvestmentDataFixActionsButton
                  fund={subject.fund}
                  onFundDataUpdated={onFundDataUpdated}
                  readOnly={!canManageProxy}
                  usePortal
                />
              )}

              {subject && subject.type === 'investment' && fundId !== undefined && (
                <ButtonWrapper>
                  <ManageInvestmentDataTrigger
                    fundId={fundId}
                    needsAttention={shouldUserUpdateDataForFund(subject?.fund)}
                  />
                </ButtonWrapper>
              )}
              {subject && (
                <ManagePortfolioDataTrigger hidden={subject.type !== 'portfolio'}>
                  {(openManageDataPage) => (
                    <ButtonWrapper>
                      <ButtonIcon
                        className="qa-manage-data-button"
                        iconType="cog"
                        onClick={openManageDataPage}
                        tooltip={
                          subject.portfolio?.children?.length === 0 && !hasOptimization
                            ? 'Add at least one investment to the portfolio'
                            : 'Manage data'
                        }
                        disabled={subject.portfolio?.children?.length === 0 && !hasOptimization}
                        border
                      />
                    </ButtonWrapper>
                  )}
                </ManagePortfolioDataTrigger>
              )}
            </ButtonContainer>
          </StickyTitle>
          {showFactorLensNote && <FactorLensInfo />}
        </PaddingWrapper>

        <FlexWrapper className="qa-tearsheet-config">
          <Left>
            <LeftHeaderGroup
              periodRange={periodRange}
              analysisConfig={analysisConfig}
              canToggleRelative={canToggleRelative}
              onToggleRelative={onToggleRelative}
              setCategoryConfig={setCategoryConfig}
              controlsDisabled={controlsDisabled}
            />
          </Left>
          <Right>
            <DateWrapper>
              <LabelText>
                {subject && (
                  <ManagePortfolioDataTrigger
                    hidden={subject.type !== 'portfolio' || subject.portfolio?.children?.length === 0}
                  >
                    {(openManageDataPage) => (
                      <LinkStyle>
                        <Tooltip content="Where is this coming from?" position={TooltipPosition.Left}>
                          <button
                            type="button"
                            onClick={openManageDataPage}
                            style={{
                              marginRight: 4,
                              fontWeight: 'bold',
                              display: 'inline',
                            }}
                          >
                            <Icon type="question-circle" />
                          </button>
                        </Tooltip>
                      </LinkStyle>
                    )}
                  </ManagePortfolioDataTrigger>
                )}
                Analysis Period:
                {subject?.type === 'investment' && proxy?.extrapolate && (
                  <React.Suspense fallback={<ShimmerBlock height={10} />}>
                    <button type="button" onClick={() => window.open(INTERPOLATION_EXTRAPOLATION_FAQ_HREF, '_blank')}>
                      <ExtrapolationInfo investmentId={assertNotNil(subject?.id) as string} />
                    </button>
                  </React.Suspense>
                )}
              </LabelText>
              <HeaderItemLoader>
                <DateRangePicker
                  value={periodValue}
                  range={periodRange}
                  granularity={getDateMode(analysesPeriod.frequency)}
                  disabled={!periodRange || controlsDisabled}
                  className="timeframeDatePicker"
                  onChange={handlePeriodChange}
                />
              </HeaderItemLoader>
            </DateWrapper>
          </Right>
        </FlexWrapper>
        {renderMenu && <PaddingWrapper>{renderMenu()}</PaddingWrapper>}
      </StickyHeader>
    </>
  );
  const printHeader = (
    <>
      {currentViewName && (
        <PrintSubjectBar>
          <PrintSubjectLeft>View Name: {currentViewName}</PrintSubjectLeft>
        </PrintSubjectBar>
      )}
      <PrintSubjectBar>
        <PrintSubjectLeft>{subject ? subject.name : ''}</PrintSubjectLeft>
        <PrintSubjectRight>
          <PrintSubjectLabel>Analysis Period:</PrintSubjectLabel> {periodValueText}
        </PrintSubjectRight>
      </PrintSubjectBar>
      <PrintSubjectBar>
        <PrintSubjectLeft>&nbsp;</PrintSubjectLeft>
        <PrintSubjectRight>
          {proxy && (
            <>
              <PrintSubjectLabel>Proxy:</PrintSubjectLabel> {proxy.name}
            </>
          )}
          {category && shouldShowCategoryPicker(subject) && (
            <>
              <PrintSubjectLabel>Category:</PrintSubjectLabel>
              {categoryOff ? <i>(Off)</i> : category.name}
            </>
          )}
          <PrintSubjectLabel>Benchmark:</PrintSubjectLabel>
          {subject && subject.activeBenchmark ? subject.activeBenchmarkName : <i>(None)</i>}
        </PrintSubjectRight>
      </PrintSubjectBar>
      <PrintAnalysisTemplate>{analysisConfig?.analysisTemplate?.name}</PrintAnalysisTemplate>
    </>
  );
  return <MediaQuery print>{(print) => (print ? printHeader : screenHeader)}</MediaQuery>;
};

export default Header;

const paddingStyle = css`
  padding-left: 60px;
  padding-right: 60px;
  @media (max-width: 1280px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const StickyHeader = styled(StickyNode)<{ shadow: boolean }>`
  ${(props) =>
    props.shadow &&
    css`
      box-shadow: 0 20px 20px -20px ${ColorUtils.hex2rgbaFrom(GetColor.Black, 0.4)};
    `}
  background: ${GetColor.White};
  z-index: ${ZIndex.StickyFront};
  && {
    margin: 0;
  }
  transition: 0.5s;
`;

const PaddingWrapper = styled.div`
  ${paddingStyle}
`;

const FlexWrapper = styled.div`
  ${paddingStyle}
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 0 12px;
  transition: 0.5s;
  background-color: ${GetColor.PaleGrey};
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 15px;
  margin-bottom: 0;
`;

const Left = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 10px;
  .fa-edit {
    opacity: 1;
  }
`;

const DateWrapper = styled.div`
  margin-left: 20px;
  min-width: 152px;
  .dateRangeContent {
    top: 25px;
  }
`;

const StickyTitle = styled.div<{ smallHeader: boolean }>`
  display: flex;
  justify-content: space-between;
  z-index: ${ZIndex.StickyCover};
  padding-top: ${(props) => (props.smallHeader ? 10 : 20)}px;
  transition: 0.5s;
`;

const ButtonContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  background-color: ${GetColor.White};
  z-index: ${ZIndex.StickyFront};
  padding-right: 60px;
  margin-right: -60px;
  margin-top: 4px;
  margin-left: 8px;
  @media (max-width: 1280px) {
    padding-right: 10px;
    margin-right: -10px;
  }
  ${(props) => props.disabled && 'display: none;'}
`;

const ButtonBar = styled.div`
  border: 1px solid ${GetColor.Primary.Dark};
  border-radius: 2px;
  display: flex;
  height: 40px;
  > div:first-child {
    margin-left: 0;
  }
`;

const ButtonWrapper = styled.div`
  margin-left: 10px;
  > button {
    margin-right: 0;
  }
`;
