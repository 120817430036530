import styled from 'styled-components';
import { Loading as LoadingComponent, ZIndex } from 'venn-ui-kit';

export default styled(LoadingComponent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 80px;
  background: transparent;
  z-index: ${ZIndex.Front};
`;
