import React, { useMemo } from 'react';
import styled from 'styled-components';
import { ColumnAlign, ContentBlock } from 'venn-components';
import { Numbers } from 'venn-utils';
import { Delta, ShimmerTr, SuperHeader, Table, TableShimmer, TableShimmerRow } from './styled-table';
import { isNil } from 'lodash';
import { ColorUtils, GetColor, Icon, Tooltip } from 'venn-ui-kit';
import { EmptyStateContent, ErrorHeader } from './ErrorContent';

export interface FactorMetricRow {
  metricName: string;
  exposure: number | undefined;
  exposureBase: number | undefined;
  contributionToRisk: number | undefined;
  contributionToRiskBase: number | undefined;
  contributionToReturn: number | undefined;
  contributionToReturnBase: number | undefined;
  isResidual?: boolean;
}

interface FactorPerformanceCardViewProps {
  data: FactorMetricRow[];
  loading: boolean;
  error?: string;
  onEditExposures: () => void;
}

const getColumns = (onEditExposures: () => void) => [
  {
    label: 'Factors',
    accessor: 'metricName',
    align: ColumnAlign.LEFT,
    headerStyle: { minWidth: 200 },
    cellStyle: { minWidth: 200 },
    cellRenderer: ({ metricName, isResidual }: FactorMetricRow) => (isResidual ? <i>{metricName}</i> : metricName),
  },
  {
    label: 'Exposure (β)',
    accessor: 'exposure',
    align: ColumnAlign.RIGHT,
    cellRenderer: ({ exposure }: FactorMetricRow) => {
      if (isNil(exposure)) {
        return <strong>--</strong>;
      }

      return (
        <StyledTooltip content={<i>Click to add a factor exposure constraint</i>} block maxWidth={300}>
          <ExposureBox tabIndex={0} onClick={onEditExposures}>
            <AbsoluteIcon type="dna" />
            <strong>{Numbers.safeFormatNumber(exposure, 2)}</strong>
          </ExposureBox>
        </StyledTooltip>
      );
    },
  },
  {
    label: 'Δ',
    accessor: 'exposureDelta',
    align: ColumnAlign.LEFT,
    cellRenderer: ({ exposure, exposureBase }: FactorMetricRow) => <Delta base={exposureBase} value={exposure} />,
  },
  {
    label: 'Cont. to Risk',
    accessor: 'contributionToRisk',
    align: ColumnAlign.RIGHT,
    cellRenderer: ({ contributionToRisk }: FactorMetricRow) => (
      <strong>{Numbers.safeFormatPercentage(contributionToRisk, 2)}</strong>
    ),
  },
  {
    label: 'Δ',
    accessor: 'riskDelta',
    align: ColumnAlign.LEFT,
    cellRenderer: ({ contributionToRisk, contributionToRiskBase }: FactorMetricRow) => (
      <Delta base={contributionToRiskBase} value={contributionToRisk} isPercentage />
    ),
  },
  {
    label: 'Cont. to Return',
    accessor: 'contributionToReturn',
    align: ColumnAlign.RIGHT,
    cellRenderer: ({ contributionToReturn }: FactorMetricRow) => (
      <strong>{Numbers.safeFormatPercentage(contributionToReturn, 2)}</strong>
    ),
  },
  {
    label: 'Δ',
    accessor: 'returnDelta',
    align: ColumnAlign.LEFT,
    cellRenderer: ({ contributionToReturn, contributionToReturnBase }: FactorMetricRow) => (
      <Delta base={contributionToReturnBase} value={contributionToReturn} isPercentage />
    ),
  },
];

const FactorPerformanceCardView = ({ data, loading, error, onEditExposures }: FactorPerformanceCardViewProps) => {
  const columns = useMemo(() => getColumns(onEditExposures), [onEditExposures]);

  return (
    <ContentBlock header="Factor Performance" subHeader="Click delta metrics to add or adjust constraints.">
      {error ? (
        <EmptyStateContent>
          <ErrorHeader>{error}</ErrorHeader>
        </EmptyStateContent>
      ) : (
        <Table
          className="qa-factor-performance-table"
          renderHead={() => (
            <Tr>
              <th style={{ width: 100 }} />
              <SuperHeader wide colSpan={6}>
                <div>Forecast</div>
              </SuperHeader>
            </Tr>
          )}
          columns={columns}
          data={loading ? [] : data}
          renderTail={
            !loading
              ? undefined
              : () => (
                  <ShimmerTr className="qa-shimmer">
                    <td colSpan={7}>
                      <TableShimmer>
                        <TableShimmerRow />
                        <TableShimmerRow />
                        <TableShimmerRow />
                        <TableShimmerRow />
                        <TableShimmerRow />
                      </TableShimmer>
                    </td>
                  </ShimmerTr>
                )
          }
        />
      )}
    </ContentBlock>
  );
};

export default FactorPerformanceCardView;

const Tr = styled.tr`
  border: none;
`;

const ExposureBox = styled.div`
  position: relative;
  max-width: 74px;
  padding: 4px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: calc(100% + 8px);
  margin-left: auto;
  i {
    visibility: hidden;
  }
  &:hover {
    background-color: ${ColorUtils.hex2rgbaFrom(GetColor.DEPRECATED_DivergingColor.B3, 0.2)};
    cursor: pointer;
    i {
      visibility: visible;
    }
  }
`;

const StyledTooltip = styled(Tooltip)`
  height: calc(100% - 2px);
  top: -4px;
`;

const AbsoluteIcon = styled(Icon)`
  position: absolute;
  left: 6px;
  margin-top: 1px;
`;
