import { PRINT_CHART_HEIGHT, blockInfoGraphicType, blockSettings, customizedBlock, isReportState } from 'venn-state';
import type { AnalysisGridProps, CustomBlockProps } from '../../../types';
import ExportableGrid from '../../grid/ExportableGrid';
import { useRecoilValue } from 'recoil';
import { useBlockId } from '../../../contexts/BlockIdContext';
import { GridWrapper } from '../../../common';
import React, { useMemo } from 'react';
import { ColumnChart } from '../../charts/returns-distribution';
import { useMetaRows } from '../../../logic/useAnalysisGrid';
import { useCommonGridProps } from '../../../logic/columnUtils';
import { analysisGridDefaultColDefOverrides } from '../../grid/AnalysisGrid';
import { DATA_TYPE_EXCEL_STYLES } from '../../../logic/customBlockUtils';
import { useNotablePeriodsColumnDefs } from './useNotablePeriodsColumnDefs';
import { useSubjectColors } from '../../../logic/useSubjectColors';
import { useAppPrintMode } from '../../../../print/AppPrintMode';
import { getAnalyzedSubjectFromRequestSubject } from 'venn-utils';
import { compact } from 'lodash';
import { useNotablePeriodsChartData, useNotablePeriodsGridData } from './useNotablePeriodsData';

// TODO(VENN-19715): all of the print, export, and block props would ideally be pulled from recoil rather than drilled.
type NotableHistoricalProps = Pick<
  AnalysisGridProps,
  'customBlockType' | 'analysesGroup' | 'requests' | 'selectedRefId' | 'isCompact' | 'inPrintMode' | 'exportMetaData'
> &
  Pick<CustomBlockProps, 'downloadableContentRef'>;

export const NotableHistoricalBlock = React.memo(function InternalNotableHistoricalBlock(
  props: NotableHistoricalProps,
) {
  const blockId = useBlockId();
  const isReport = useRecoilValue(isReportState);
  const infoGraphicType = useRecoilValue(blockInfoGraphicType(blockId));

  if (infoGraphicType === 'GRID') {
    return (
      <GridWrapper isReport={isReport}>
        <NotableHistoricalGrid {...props} />
      </GridWrapper>
    );
  }

  return <NotableHistoricalChart {...props} />;
});

const NotableHistoricalChart = (props: NotableHistoricalProps) => {
  const { requests } = props;
  const blockId = useBlockId();
  const isPrinting = useAppPrintMode().inPrintModeOrReportLab;
  const selectedBlock = useRecoilValue(customizedBlock(blockId));
  const blockSetting = useRecoilValue(blockSettings(blockId));
  const infoGraphicType = useRecoilValue(blockInfoGraphicType(blockId));
  const isReport = useRecoilValue(isReportState);

  const analyzedSubjects = useMemo(
    () => requests.map(({ subject }) => getAnalyzedSubjectFromRequestSubject(subject)),
    [requests],
  );
  const subjectColors = useSubjectColors(analyzedSubjects);

  const height = isPrinting && !isReport ? PRINT_CHART_HEIGHT : undefined;
  // We filter selectedMetrics on blockSetting.metrics because metric order should be preserved,
  // even though reversing this logic would have simpler code.
  // We do this filter to remove any unsupported selectedMetrics.
  const metrics = useMemo(
    () => compact(selectedBlock.selectedMetrics.map((m) => blockSetting.metrics.find((metric) => metric.key === m))),
    [selectedBlock, blockSetting.metrics],
  );

  const rowData = useNotablePeriodsChartData(props.analysesGroup);

  if (infoGraphicType === 'DISTRIBUTE_BAR') {
    return (
      <ColumnChart
        analyses={props.analysesGroup}
        requests={props.requests}
        inPrintMode={props.inPrintMode}
        exportMetaData={props.exportMetaData}
        rowData={rowData}
        downloadableContentRef={props.downloadableContentRef}
        height={height}
        selectedBlock={selectedBlock}
        metrics={metrics}
        customBlockType={props.customBlockType}
        subjectColors={subjectColors}
        selectedRefId={props.selectedRefId}
      />
    );
  }
  throw new Error(`Unsupported chart type: ${infoGraphicType}`);
};

const NotableHistoricalGrid = (props: NotableHistoricalProps) => {
  const metaRows = useMetaRows(props.requests);
  const dataRows = useNotablePeriodsGridData(props.analysesGroup);
  const rowData = useMemo(() => [...metaRows, ...dataRows], [metaRows, dataRows]);

  const columnDefs = useNotablePeriodsColumnDefs({ ...props, gridData: dataRows });
  const commonGridProps = useCommonGridProps(analysisGridDefaultColDefOverrides);

  return (
    <ExportableGrid
      selectedRefId={props.selectedRefId}
      isCompact={props.isCompact}
      inPrintMode={props.inPrintMode}
      exportMetaData={props.exportMetaData}
      {...commonGridProps}
      exportable
      rowData={rowData}
      columnDefs={columnDefs}
      excelStyles={DATA_TYPE_EXCEL_STYLES}
    />
  );
};
