import React, { useContext, useEffect, useRef, useState } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import type { AnalysesPeriod, AnalysisConfig } from 'venn-utils';
import { AnalysisSubject, getTemplateById, emptyAnalysisTemplate } from 'venn-utils';
import { Analysis, Content, Grid, MinWidth } from './Layout';
import NewPortfolioPanel from './side-panel/NewPortfolioPanel';
import Header from './header/Header';
import Loading from './Loading';
import useAnalysis from '../logic/useAnalysis';
import { CreatePortfolioStage } from './portfolio-tutorial/constants';
import PortfolioTutorial from './portfolio-tutorial/PortfolioTutorial';
import { FactorLensesContext, Footer, PortfoliosContext, AnalysisContext, UserContext } from 'venn-components';
import type { SharedProps } from '../types';
import AnalysisBlocks from './AnalysisBlocks';
import ComparisonContextStore from './ComparisonContextStore';
import { getIntegerQueryStringParam } from '../utils';
import { TRACK_TIMESTAMPS_AFTER } from '../logic/useTrackFailedAnalysis';
import type { Portfolio } from 'venn-api';
import { getSpecificPortfolioV3 } from 'venn-api';

type CreatePortfolioPageProps = RouteComponentProps<{ compare?: string }>;

const CreatePortfolioPage: React.FC<React.PropsWithChildren<CreatePortfolioPageProps>> = ({ history }) => {
  const { hasPermission } = useContext(UserContext);
  const hasAccessToCompare = hasPermission('COMPARE');
  const { templates } = useContext(AnalysisContext);
  // Use performance analysis type when creating a portfolio
  const PORTFOLIO_TEMPLATE = useRef(getTemplateById(templates, 'performance') ?? emptyAnalysisTemplate()).current;
  const { factorLenses } = useContext(FactorLensesContext);
  const [analysisConfig, setAnalysisConfig] = useState<AnalysisConfig>({
    analysisTemplate: PORTFOLIO_TEMPLATE,
    subject: undefined,
    selectedTimeFrame: {},
    relative: false,
    category: 'HIDDEN',
    trackingId: -1,
  });

  const [currentStage, setCurrentStage] = useState<CreatePortfolioStage>(CreatePortfolioStage.ADD_INVESTMENT);
  const { analysesError, analysesResults, loadingAnalysis } = useAnalysis(analysisConfig);
  const { masterPortfolio, demoPortfolio } = useContext(PortfoliosContext);
  const draftPortfolioId = getIntegerQueryStringParam(history.location, 'id');
  const [draftPortfolio, setDraftPortfolio] = useState<Portfolio | undefined>();

  useEffect(() => {
    const fetchDraft = async () => {
      try {
        if (draftPortfolioId) {
          const portfolio = (await getSpecificPortfolioV3(draftPortfolioId)).content;
          setDraftPortfolio(portfolio);
          if (portfolio) {
            setCurrentStage(CreatePortfolioStage.ADD_STRATEGIES);
          }
        }
      } catch (e) {
        // fail silently
      }
    };
    fetchDraft();
  }, [draftPortfolioId, setDraftPortfolio, setCurrentStage]);

  const portfolio = demoPortfolio || masterPortfolio;
  useEffect(() => {
    if (portfolio) {
      const newPortfolio = {
        ...portfolio,
        name: 'New Portfolio',
      };
      const subject = new AnalysisSubject(newPortfolio, 'portfolio');
      setAnalysisConfig({
        analysisTemplate: PORTFOLIO_TEMPLATE,
        subject,
        selectedTimeFrame: {},
        relative: false,
        category: 'HIDDEN',
        trackingId: Date.now() - TRACK_TIMESTAMPS_AFTER,
      });
    }
  }, [PORTFOLIO_TEMPLATE, portfolio]);

  const defaultAnalysesPeriod: AnalysesPeriod = analysesResults?.analysesPeriod ?? { frequency: 'MONTHLY' };

  const sharedProps: SharedProps = {
    analysisConfig,
    analyses: analysesResults,
    analysesError,
    analysisLoading: loadingAnalysis,
    factorLenses,
  };

  return (
    <Grid>
      <ComparisonContextStore analysisSubject={analysisConfig.subject}>
        <NewPortfolioPanel
          initialPortfolio={draftPortfolio}
          setCurrentStage={setCurrentStage}
          currentStage={currentStage}
          hasAccessToCompare={hasAccessToCompare}
        />
      </ComparisonContextStore>
      <Analysis>
        <Content>
          <Header
            analysesPeriod={defaultAnalysesPeriod}
            canToggleRelative
            analysisConfig={analysisConfig}
            templates={[]}
          />
          {loadingAnalysis ? <Loading /> : <AnalysisBlocks {...sharedProps} />}
        </Content>
        <MinWidth>
          <Footer />
        </MinWidth>
      </Analysis>
      <PortfolioTutorial currentStage={currentStage} />
    </Grid>
  );
};

export default withRouter(CreatePortfolioPage);
