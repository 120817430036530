import React from 'react';
import { TooltipPosition } from 'venn-ui-kit';
import { useModal } from 'venn-utils';
import PrivateDeleteConfirmationModal from '../PrivateDeleteConfirmationModal';
import { StyledButtonIcon } from './styles';
import type { PrivateBulkActionProps } from './types';

export const PrivateDeleteItems = ({ items, onComplete, updateData }: PrivateBulkActionProps) => {
  const [isOpen, open, close] = useModal();
  const disabled = !items.every((i) => i.userUploaded);
  const isDemo = !items.every((i) => (i.ownerId ? i.ownerId > 0 : true));

  let tooltip;

  if (disabled || isDemo) {
    tooltip = 'Cannot delete non user created items.';
  }

  return (
    <>
      <StyledButtonIcon
        tooltip={tooltip}
        tooltipPosition={TooltipPosition.Bottom}
        onClick={open}
        iconType="trash"
        disabled={disabled || isDemo}
        text={`Delete (${items.length})`}
        className="qa-bulk-delete"
        warningColor
      />
      {isOpen && (
        <PrivateDeleteConfirmationModal
          items={items}
          onCancelDelete={close}
          onDeleteComplete={() => {
            onComplete('Delete', close, true);
            updateData();
          }}
        />
      )}
    </>
  );
};
