import React, { useContext } from 'react';
import { default as FactorAnalysis } from './FactorAnalysis';
import Loading from './Loading';
import type { SharedProps } from '../types';
import { AnalysisConfigContext, getFailedAnalysisTrackingProps } from 'venn-components';

const FactorLayoutLoading = (
  <>
    {[...Array(3)].map((_, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <Loading key={i} />
    ))}
  </>
);
const FactorAnalysisLayout = (props: SharedProps) => {
  const { analyses, analysisLoading, analysisConfig, updateAnalysisStatusForTracking } = props;
  const { actualTimeFrame } = useContext(AnalysisConfigContext);

  const factorAnalysisResult = analyses?.results?.factorAnalysis;
  const factorAnalysisSubject = analyses?.subject;
  if (!factorAnalysisResult || !factorAnalysisSubject || analysisLoading) {
    return FactorLayoutLoading;
  }
  const { summary, trend } = factorAnalysisResult;
  if (!summary || !trend || !summary.risk || !trend.risk) {
    return FactorLayoutLoading;
  }

  const analysesPeriod = analyses?.analysesPeriod;
  const { relative, subject, trackingId, selectedTimeFrame } = analysisConfig;
  const trackingProps = getFailedAnalysisTrackingProps(
    analysisConfig?.analysisTemplate?.name || '',
    'Factor Analysis',
    actualTimeFrame ?? selectedTimeFrame,
    analysesPeriod,
    factorAnalysisResult?.summary?.exposure?.message,
    subject,
    relative,
    'FACTOR_ANALYSIS',
    trackingId,
    updateAnalysisStatusForTracking,
  );

  return <FactorAnalysis {...props} trackingProps={trackingProps} />;
};

export default FactorAnalysisLayout;
