import type { FC } from 'react';
import React from 'react';
import styled, { css } from 'styled-components';
import type { ButtonProps } from 'venn-ui-kit';
import { Button, GetColor, Spinner, LoadingSize } from 'venn-ui-kit';

const StyledAuthButton = styled(Button)<{ dark: boolean; darkText: boolean }>`
  width: 100%;
  margin-bottom: 20px;

  circle {
    stroke: ${GetColor.DarkGrey};
  }

  ${({ dark }) =>
    dark
      ? css`
          background-color: ${GetColor.Black};
        `
      : ''}
  ${({ darkText }) =>
    darkText
      ? css`
          color: ${GetColor.Black};
        `
      : ''}
`;

interface AuthButtonProps extends ButtonProps {
  className?: string;
  loading?: boolean;
  dark?: boolean;
  darkText?: boolean;
  disabled?: boolean;
}

export const AuthButton: FC<React.PropsWithChildren<AuthButtonProps>> = ({
  className,
  dark = false,
  darkText = false,
  disabled = false,
  loading = false,
  children,
  ...restProps
}) => {
  return (
    <StyledAuthButton dark={dark} darkText={darkText} disabled={disabled} className={className} {...restProps}>
      {children}
      {loading && <Spinner size={LoadingSize.nano} />}
    </StyledAuthButton>
  );
};

export default AuthButton;
