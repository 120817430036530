import React from 'react';
import styled, { css } from 'styled-components';
import type { DropMenuItem, MenuCategory } from '../types';
import type { SimpleMenuProps } from './SimpleMenu';
import { Container, Items } from './BaseMenu';
import NoItem from './NoItem';
import { GetColor } from '../../../style/color';
import { Icon } from '../../icon/Icon';

export interface CategorizedMenuProps<TValue = string, TItem = DropMenuItem<TValue>>
  extends Omit<SimpleMenuProps<TValue>, 'items'> {
  categories: MenuCategory<TValue, TItem>[];
  headerComponent?: JSX.Element;
  footerComponent?: JSX.Element;
  hideSeparator?: boolean;
}

interface CategorizedMenuPropsInner<TValue, TItem> extends CategorizedMenuProps<TValue, TItem> {
  renderItem: (item: TItem, category: MenuCategory<TValue, TItem>, index: number) => JSX.Element;
}

export default function CategorizedMenu<TValue, TItem = DropMenuItem<TValue>>({
  categories,
  width,
  height,
  renderItem,
  headerComponent,
  footerComponent,
  className,
  hideSeparator,
}: CategorizedMenuPropsInner<TValue, TItem>) {
  return (
    <Container width={width} height={height} tabIndex={-1} className={className}>
      {headerComponent}
      {categories && categories.length > 0 ? (
        <Items>
          {categories.map((category) => (
            <Category hideSeparator={hideSeparator} key={category.name}>
              <CategoryName>
                {category.name}{' '}
                {category.disabled && (
                  <DisabledHint>
                    <Icon type="exclamation-circle" />
                    {category.disabledMessage}
                  </DisabledHint>
                )}
              </CategoryName>
              {(category.items || []).map((item, index) => renderItem(item, category, index))}
            </Category>
          ))}
        </Items>
      ) : (
        <NoItem>(No results found)</NoItem>
      )}
      {footerComponent}
    </Container>
  );
}

const Category = styled.div<{ hideSeparator?: boolean }>`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  ${({ hideSeparator }) =>
    !hideSeparator
      ? css`
          padding-bottom: 19px;
          border-bottom: 1px solid ${GetColor.PaleGrey};
        `
      : ''}
`;

const CategoryName = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  padding: 0 20px;
  line-height: 2;
`;

const DisabledHint = styled.div`
  color: ${GetColor.HighlightDark};
  font-size: 10px;

  i {
    margin-right: 4px;
  }
`;
