import { noop } from 'lodash';
import React, { createContext, useCallback, useMemo } from 'react';
import { useRecoilCallback, useResetRecoilState, useSetRecoilState } from 'recoil';
import type { ComputedInvestmentResidual, InvestmentResidualOverride } from 'venn-api';
import { getInvestmentResidual, storeInvestmentResidualForecasts } from 'venn-api';
import type { InvestmentOverrideOperation, InvestmentOverrideType } from 'venn-state';
import {
  forecastPanelViewSelector,
  ForecastTab,
  selectedInvestmentOverrideAtomSyncedWithOverrideType,
  selectedInvestmentOverrideTypeAtom,
  selectedInvestmentOverrideValueAtom,
} from 'venn-state';
import { Notifications, NotificationType } from 'venn-ui-kit';
import { analyticsService, asyncNoop, logExceptionIntoSentry, useHasFF } from 'venn-utils';
import {
  getNewUserSpecifiedOverride,
  typeOfInvestmentOverride,
} from '../helpers/InvestmentForecastPanelFooterActionsHelper';
import { useRefreshInvestmentOverridesList } from '../hooks/useRefreshInvestmentOverridesList';

type InvestmentForecastPanelFooterActions = {
  onCancelInvestmentOverride: () => void;
  onCancelInvestmentOverrideForCreation: () => void;
  onUpdateOrCreateInvestmentOverride: (
    fundId: string,
    newValue: number,
    overrideType: InvestmentOverrideType,
  ) => Promise<void>;
};

const defaultInvestmentForecastPanelFooterActionsContext: InvestmentForecastPanelFooterActions = {
  onCancelInvestmentOverride: noop,
  onCancelInvestmentOverrideForCreation: noop,
  onUpdateOrCreateInvestmentOverride: asyncNoop,
};
// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export const InvestmentForecastPanelFooterActionsContext: React.Context<InvestmentForecastPanelFooterActions> =
  createContext(defaultInvestmentForecastPanelFooterActionsContext);

type InvestmentForecastPanelFooterActionsProviderProps = {
  onResidualForecastUpdated?: (fundId?: string) => void;
  children: React.ReactNode;
};
export const InvestmentForecastPanelFooterActionsProvider = ({
  onResidualForecastUpdated,
  children,
}: InvestmentForecastPanelFooterActionsProviderProps) => {
  const hasRedesignFF = useHasFF('forecast_panel_redesign_ff');

  const refreshInvestmentOverridesList = useRefreshInvestmentOverridesList();
  const resetInvestmentOverrideSelection = useResetRecoilState(
    selectedInvestmentOverrideAtomSyncedWithOverrideType('EDIT'),
  );
  const setView = useSetRecoilState(forecastPanelViewSelector);

  const resetForOperation = useRecoilCallback(
    ({ reset }) =>
      (operation: InvestmentOverrideOperation) => {
        reset(selectedInvestmentOverrideValueAtom(operation));
        reset(selectedInvestmentOverrideTypeAtom(operation));
        reset(selectedInvestmentOverrideAtomSyncedWithOverrideType(operation));
      },
    [],
  );

  const onCancelInvestmentOverride = useCallback(() => {
    if (!hasRedesignFF) {
      setView({
        tab: ForecastTab.InvestmentForecast,
        detail: { type: 'Empty' },
      });
      resetInvestmentOverrideSelection();
    } else {
      resetForOperation('EDIT');
    }
  }, [hasRedesignFF, resetForOperation, resetInvestmentOverrideSelection, setView]);

  const onCancelInvestmentOverrideForCreation = useCallback(() => {
    resetForOperation('CREATE');
  }, [resetForOperation]);

  const trackSaveInvestmentOverride = (
    oldOverride: ComputedInvestmentResidual,
    newOverride: InvestmentResidualOverride,
  ) => {
    const oldOverrideType = typeOfInvestmentOverride(oldOverride);
    const newOverrideType = typeOfInvestmentOverride(newOverride)!;
    const investmentId = newOverride.fundId;
    const hadInvestmentOverrides = oldOverrideType !== null;
    if (hadInvestmentOverrides) {
      analyticsService.modifiedInvestmentOverride({
        currentState: newOverrideType,
        previousState: oldOverrideType,
        investmentId,
      });
    } else {
      analyticsService.savedNewInvestmentOverride({
        currentState: newOverrideType,
        investmentId,
      });
    }
  };

  const onUpdateOrCreateInvestmentOverride = useCallback(
    async (fundId: string, newValue: number, overrideType: InvestmentOverrideType) => {
      const toastId = Notifications.notify('Applying changes...', NotificationType.LOADING);
      try {
        const investmentResponse = await getInvestmentResidual(fundId);
        const currentInvestmentResidual = investmentResponse.content;
        const newInvestmentResidual = getNewUserSpecifiedOverride({
          currentInvestmentResidual,
          newValue,
          overrideType,
        });
        await storeInvestmentResidualForecasts([newInvestmentResidual]);
        trackSaveInvestmentOverride(currentInvestmentResidual, newInvestmentResidual);

        if (!hasRedesignFF) {
          setView({
            tab: ForecastTab.InvestmentForecast,
            detail: { type: 'Empty' },
          });
          resetInvestmentOverrideSelection();
        } else {
          // TODO: VENN-24750 we need to pass a flag to know whether this save function was called from create or edit form
          resetForOperation('CREATE');
          resetForOperation('EDIT');
        }
        Notifications.notifyUpdate(toastId, 'Changes applied successfully', NotificationType.SUCCESS);
        onResidualForecastUpdated?.(fundId);
        refreshInvestmentOverridesList();
      } catch (error) {
        Notifications.notifyUpdate(toastId, 'Failed to apply changes', NotificationType.ERROR);
        logExceptionIntoSentry(error);
      }
    },
    [
      hasRedesignFF,
      onResidualForecastUpdated,
      refreshInvestmentOverridesList,
      resetForOperation,
      resetInvestmentOverrideSelection,
      setView,
    ],
  );

  const memoedContextValue = useMemo(
    () => ({
      onCancelInvestmentOverride,
      onCancelInvestmentOverrideForCreation,
      onUpdateOrCreateInvestmentOverride,
    }),
    [onCancelInvestmentOverride, onCancelInvestmentOverrideForCreation, onUpdateOrCreateInvestmentOverride],
  );

  return (
    <InvestmentForecastPanelFooterActionsContext.Provider value={memoedContextValue}>
      {children}
    </InvestmentForecastPanelFooterActionsContext.Provider>
  );
};
