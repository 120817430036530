import type { FC } from 'react';
import React from 'react';
import { times } from 'lodash';
import { GetColor } from 'venn-ui-kit';
import styled from 'styled-components';

interface Props {
  count: number;
  margin: number;
}

const Lines: FC<React.PropsWithChildren<Props>> = ({ count, margin }) => (
  <g>
    {times(count, (i) => (
      <StyledLine key={i} x1="0.5" y1={i * margin} x2="100%" y2={i * margin} />
    ))}
  </g>
);

export default Lines;

const StyledLine = styled.line`
  stroke: ${GetColor.PaleGrey};
  stroke-width: 1;
`;
