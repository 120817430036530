import { KeyCodes } from 'venn-ui-kit';
import React, { useEffect, useRef, useState } from 'react';
import { isEmpty, isNil } from 'lodash';
import { formatAllocation } from 'venn-utils';

export type ParsedValue =
  | {
      isValid: true;
      value: number;
    }
  | { isValid: false };
export type NumericTextInputStateManagerProps = SharedProps & {
  allowNegative: boolean;
  isPercentage: boolean;
  value: string | undefined;
  onChange: (value: string) => void;
  onFocus?: () => void;
  onMouseDown?: () => void;
  onCommitInput: (parsedValue: ParsedValue) => void;
  inputRef?: React.RefObject<HTMLInputElement>;
  render: (props: InjectedProps) => React.ReactNode;
  maxDecimalPlaces: number;
};

type SharedProps = {
  isLocked: boolean;
};

export type InjectedProps = SharedProps & {
  disabled: boolean;
  ref: React.RefObject<HTMLInputElement>;
  isFocused: boolean;
  isError: boolean;
  value: string;
  onFocus: () => void;
  onMouseDown: () => void;
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: () => void;
};

const ALPHABETS_REGEX = /^[a-zA-Z]$/;
const BLOCKED_SYMBOLS_REGEX = /^[\+\*\/]$/;
const MINUS_SYMBOL_REGEX = /^\-$/;

const KeyBlockingNumericInputStateManager = (props: NumericTextInputStateManagerProps) => {
  const [focused, setFocused] = useState(false);

  const [caretPosition, setCaretPosition] = useState(0);

  const defaultInput = useRef<HTMLInputElement>(null);
  const input = props.inputRef ?? defaultInput;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, selectionEnd } = event.target;
    requestAnimationFrame(() => {
      setCaretPosition(Number(selectionEnd));
      props.onChange(value);
    });
  };

  const isKeyBlocked = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const key = event.key;
    return (
      key.match(ALPHABETS_REGEX) ||
      key.match(BLOCKED_SYMBOLS_REGEX) ||
      (!props.allowNegative && key.match(MINUS_SYMBOL_REGEX))
    );
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (isKeyBlocked(event) && !(event.ctrlKey || event.metaKey || event.altKey)) {
      event.preventDefault();
    }
    if (event.keyCode === KeyCodes.Enter) {
      input.current?.blur();
    }
  };

  const onFocus = () => {
    requestAnimationFrame(() => {
      setFocused(true);
      const target = input.current;
      target && target.setSelectionRange(0, String(props.value).length);
      props.onFocus?.();
    });
  };

  const onMouseDown = () => props.onMouseDown?.();

  const onBlur = () => {
    const { onCommitInput, value } = props;
    const isValueValidNumber = !isNil(value) && !isEmpty(value) && Number.isFinite(Number(value));
    requestAnimationFrame(() => {
      if (isValueValidNumber) {
        onCommitInput({
          isValid: true,
          value: Number.parseFloat(value),
        });
      } else {
        onCommitInput({
          isValid: false,
        });
      }
      setFocused(false);
    });
  };

  const getInputValue = (): string => {
    const { value, isPercentage, maxDecimalPlaces } = props;

    if (isNil(value) || value === '') {
      return '';
    }
    if (focused) {
      return value;
    }
    return formatAllocation(Number(value), false, isPercentage, undefined, false, maxDecimalPlaces);
  };

  useEffect(() => {
    // When deleting last digit of value we get 0 from props at first,
    // this makes caret appear after it not before
    const index = props.value === '0' ? 1 : caretPosition;
    const target = input.current;

    if (target) {
      target.selectionStart = index;
      target.selectionEnd = index;
    }
  }, [caretPosition, input, props.value]);

  const { value, isLocked, render } = props;
  const textValue = getInputValue();
  return (
    <>
      {render({
        ref: input,
        isFocused: focused,
        isError: Number.isNaN(Number(value)),
        value: textValue,
        onFocus,
        onMouseDown,
        onBlur,
        onKeyDown,
        onChange,
        isLocked,
        disabled: isLocked,
      })}
    </>
  );
};

export default KeyBlockingNumericInputStateManager;
