import React from 'react';
import styled, { css } from 'styled-components';
import { Headline3, Subtitle1, GetColor, ZIndex } from 'venn-ui-kit';

export interface ContentBlockProps {
  header: string | React.ReactNode;
  subHeader?: string | React.ReactNode;
  children: React.ReactNode;
  /**
   * Displays an arbitrary component on the right of the header
   */
  rightOptions?: JSX.Element;
  /**
   * Whether to display a separator line between the header and the content.
   * Not enabled by default.
   */
  separator?: boolean;
  /**
   * Removes all outside borders
   */
  noBorder?: boolean;
  noPadding?: boolean;
  className?: string;
  innerRef?: React.RefObject<HTMLDivElement>;
  dataTitle?: string;
  /**
   * If enabled the options icon for downloading will be floating above the content of the block rather than its own separate row
   */
  floatingOptions?: boolean;
}

const ContentBlock = ({
  header,
  subHeader,
  children,
  rightOptions,
  separator = false,
  noBorder = false,
  noPadding = false,
  className,
  innerRef,
  dataTitle,
  floatingOptions,
}: ContentBlockProps) => (
  <Container className={className} ref={innerRef} noBorder={noBorder} data-title={dataTitle}>
    {(header || subHeader || (!floatingOptions && rightOptions)) && (
      <HeaderContainer separator={separator}>
        <Headers noPadding={noPadding}>
          <Headline3>{header}</Headline3>
          {subHeader && <Subtitle1>{subHeader}</Subtitle1>}
        </Headers>
        {rightOptions && !floatingOptions && (
          <RightOptions noPadding={noPadding} floatingOptions={false}>
            {rightOptions}
          </RightOptions>
        )}
      </HeaderContainer>
    )}
    {rightOptions && floatingOptions && (
      <RightOptions noPadding={noPadding} floatingOptions>
        {rightOptions}
      </RightOptions>
    )}
    {children}
  </Container>
);

const Container = styled.div<{ noBorder: boolean }>`
  ${(props) =>
    !props.noBorder &&
    css`
      border: 1px solid ${GetColor.Grey};
    `}
  @media print {
    page-break-inside: avoid;
  }
`;

const HeaderContainer = styled.div<{ separator: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(props) =>
    props.separator &&
    css`
      border-bottom: 1px solid ${GetColor.Grey};
    `}
`;

const RightOptions = styled.div<{ noPadding: boolean; floatingOptions?: boolean }>`
  margin-right: ${(props) => (props.noPadding ? 0 : 20)}px;
  ${({ floatingOptions }) =>
    floatingOptions &&
    css`
      position: absolute;
      top: 10px;
      right: 0px;
      z-index: ${ZIndex.StickyFront};
      height: 0px;
    `}
`;

const Headers = styled.div<{ noPadding: boolean }>`
  flex: 1;
  margin: ${(props) => (props.noPadding ? '20px 0 16px' : '20px 20px 16px')};
  @media print {
    margin: 4px 6px;
  }

  > h1 {
    margin-bottom: 0;
  }

  > h2 {
    margin-top: 4px;
    margin-bottom: 0;
  }
`;

export default ContentBlock;
