import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import { Headline1, GetColor, ColorUtils, ZIndex, Stepper } from 'venn-ui-kit';
import { Analysis } from '../Layout';
import type { CreatePortfolioStage } from './constants';
import { CREATE_PORTFOLIO_STEPS } from './constants';
import step1 from './images/step1.png';
import step2 from './images/step2.png';
import step3 from './images/step3.png';
import step4 from './images/step4.png';
import step5 from './images/step5.png';

const WHITE_COVER = ColorUtils.hex2rgbaFrom(GetColor.White, 0.9);

interface PortfolioTutorialProps {
  currentStage: CreatePortfolioStage;
}

const messages = [
  'Upload or select investments from the library. ',
  'Click the ellipsis next to “[New Portfolio]” to add strategies.',
  'Drag and drop investments and strategies.',
  'Upload or manually input your portfolio’s NAVs.',
  'Click “Save” to finish portfolio set up.',
];

const PortfolioTutorial: FC<React.PropsWithChildren<PortfolioTutorialProps>> = ({ currentStage }) => {
  return (
    <Wrapper>
      <Center>
        <Stepper steps={CREATE_PORTFOLIO_STEPS} currentStep={currentStage} />
        <Message>
          {getImage(currentStage)}
          <Headline1>{messages[currentStage]}</Headline1>
        </Message>
      </Center>
    </Wrapper>
  );
};

export default PortfolioTutorial;

const getImage = (currentStage: CreatePortfolioStage) => {
  switch (currentStage) {
    case 0:
      return <StyledImg src={step1} alt="first step" />;
    case 1:
      return <StyledImg src={step2} alt="second step" />;
    case 2:
      return <StyledImg src={step3} alt="third step" />;
    case 3:
      return <StyledImg src={step4} alt="fourth step" />;
    case 4:
      return <StyledImg src={step5} alt="fifth step" />;
    default:
      return null;
  }
};

const Wrapper = styled(Analysis)`
  background-color: ${WHITE_COVER};
  position: relative;
  z-index: ${ZIndex.StickyCover};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Message = styled.div`
  margin: 45px 20px 0;
  max-width: 830px;
  display: flex;
  align-items: center;
`;

const Center = styled.div`
  text-align: center;
`;

const StyledImg = styled.img`
  width: 216px;
  height: 133px;
  border-radius: 10px;
  border: solid 1px ${GetColor.Black};
  background-color: ${GetColor.Grey};
  margin-right: 20px;
`;
