import type { AnalysisViewTypeEnum } from 'venn-api';

export const getLaunchText = (analysisViewType: AnalysisViewTypeEnum) => {
  switch (analysisViewType) {
    case 'ANALYSIS':
      return 'Edit Analysis';
    case 'COMPARE':
      return 'Edit Compare';
    case 'ASSEMBLY':
    case 'TEARSHEET':
      return 'Edit Studio';
    case 'REPORT':
      return 'Edit Report';
    case 'TEARSHEET_TEMPLATE':
      return 'Launch as Studio';
    case 'REPORT_TEMPLATE':
      return 'Launch as Report';
    default:
      return '';
  }
};
