import React from 'react';
import styled from 'styled-components';

import { PaginationBody } from './PaginationBody';
import { LeftSideControls } from './LeftSideControls';
import { RightSideControls } from './RightSideControls';

const PaginationContainer = styled.div`
  flex: 1;
  font-size: 14px;
  text-align: center;
`;

export interface PaginationProps {
  pagesCount: number;
  selectedPage: number;
  onPageChange: (pageIndex: number) => void;
}

const Pagination = ({ pagesCount, selectedPage, onPageChange }: PaginationProps) => {
  if (!pagesCount) {
    return null;
  }
  return (
    <PaginationContainer data-testid="pagination-controls">
      <LeftSideControls selectedPage={selectedPage} onPageChange={onPageChange} />
      <PaginationBody pagesCount={pagesCount} selectedPage={selectedPage} onPageChange={onPageChange} />
      <RightSideControls pagesCount={pagesCount} selectedPage={selectedPage} onPageChange={onPageChange} />
    </PaginationContainer>
  );
};

export default React.memo(Pagination);
