import React, { useContext } from 'react';
import MediaQuery from 'react-responsive';
import type { BasicTableColumn, BasicTableProps } from 'venn-components';
import { AnalysisViewContext, BasicTable, UserContext } from 'venn-components';
import type { AnalysisConfig, PortfolioWithLevel } from 'venn-utils';
import {
  calculateComparison,
  formatAllocation,
  getFlattenedNodesWithCompareAndLevel,
  normalizePortfolio,
  useHasFF,
} from 'venn-utils';
import styled, { ThemeContext } from 'styled-components';
import { compact, isNil } from 'lodash';
import type { Portfolio } from 'venn-api';
import { PrintAnalysisTemplate } from 'venn-ui-kit';
import { getComparisonLabel } from '../workspace/exportData/helper';

interface PortfolioNavsProps {
  analysisConfig: AnalysisConfig;
}

const PortfolioNavs = ({ analysisConfig }: PortfolioNavsProps) => {
  const strategy = analysisConfig.subject?.strategy;
  const hasExportsDisabled = useHasFF('disable_exports_ff');

  if (!strategy || strategy.fund || hasExportsDisabled) {
    return null;
  }

  return (
    <MediaQuery print>
      {(print) =>
        print && (
          <div>
            <PrintAnalysisTemplate>Portfolio NAVs</PrintAnalysisTemplate>
            <PortfolioNavsTable
              strategy={strategy}
              secondary={analysisConfig.subject?.secondaryPortfolio}
              secondaryLabel={getComparisonLabel(analysisConfig.subject)}
            />
          </div>
        )
      }
    </MediaQuery>
  );
};

export default PortfolioNavs;

interface PortfolioNavsTableProps {
  strategy: Portfolio;
  secondary?: Portfolio;
  secondaryLabel?: string;
}

const PortfolioNavsTable = ({ strategy, secondary, secondaryLabel }: PortfolioNavsTableProps) => {
  const { isPercentageMode } = useContext(AnalysisViewContext);
  const userContext = useContext(UserContext);
  const { Colors } = useContext(ThemeContext);
  const workspaceCurrency = userContext.profileSettings?.organization?.currency;

  const normalizedStrategy = normalizePortfolio(strategy);
  const normalizedSecondary = isNil(secondary) ? undefined : normalizePortfolio(secondary);
  const [allCompareNodes, allGhostChildren] = calculateComparison(normalizedStrategy, normalizedSecondary);

  const nodes = getFlattenedNodesWithCompareAndLevel(
    normalizedStrategy,
    normalizedSecondary,
    allCompareNodes,
    allGhostChildren,
  );

  const rootAllocation = nodes[0][0]?.allocation;
  const columns = compact([
    {
      label: 'Investment',
      sortable: false,
      cellRenderer: (data: [PortfolioWithLevel | undefined, PortfolioWithLevel | undefined]) => {
        const [node, compare] = [data[0], data[1]];
        return (
          <div
            style={{
              marginLeft: `${20 * (node?.level ?? compare?.level ?? 0)}px`,
              fontWeight: isNil(node?.fund ?? compare?.fund) ? 'bold' : 'normal',
              color: isNil(node) ? Colors.MidGrey2 : Colors.Black,
            }}
          >
            {node?.name ?? compare?.name}
          </div>
        );
      },
    },
    {
      label: `Allocation (${isPercentageMode ? '%' : workspaceCurrency})`,
      sortable: false,
      cellRenderer: (data: [PortfolioWithLevel | undefined, PortfolioWithLevel | undefined]) => {
        const [node, compare] = [data[0], data[1]];
        return (
          <div style={{ fontWeight: isNil(node?.fund ?? compare?.fund) ? 'bold' : 'normal' }}>
            {!isNil(node)
              ? formatAllocation(
                  node?.allocation ?? 0,
                  false,
                  !!(isPercentageMode && rootAllocation && node.allocation),
                  rootAllocation,
                )
              : '--'}
          </div>
        );
      },
    },
    isNil(secondary)
      ? null
      : {
          label: `${secondaryLabel} Allocation (${isPercentageMode ? '%' : workspaceCurrency})`,
          sortable: false,
          cellRenderer: (data: [PortfolioWithLevel | undefined, PortfolioWithLevel | undefined]) => {
            const [node, compare] = [data[0], data[1]];
            return (
              <div style={{ fontWeight: isNil(node?.fund ?? compare?.fund) ? 'bold' : 'normal' }}>
                {!isNil(compare)
                  ? formatAllocation(
                      compare?.allocation ?? 0,
                      false,
                      !!(isPercentageMode && rootAllocation && compare.allocation),
                      rootAllocation,
                    )
                  : '--'}
              </div>
            );
          },
        },
  ]);
  return <StyledBasicTable data={nodes} columns={columns} />;
};

const StyledBasicTable = styled(<T extends BasicTableColumn<K>, K>(props: BasicTableProps<T, K>) => (
  <BasicTable<T, K> {...props} />
))`
  margin: 10px 0 30px;
`;
