enum KeyCode {
  Enter = 13,
  Escape = 27,
  ArrowLeft = 37,
  ArrowUp = 38,
  ArrowRight = 39,
  ArrowDown = 40,
  Space = 32,
  Delete = 46,
  Backspace = 8,
  Tab = 9,
}

export default KeyCode;
