import type { PortfolioMultiUploaderView } from '../../types';
import { useContext } from 'react';
import { UserContext } from '../../../../contexts';
import { useHasAllFFs } from 'venn-utils';

type DisabledProps =
  | {
      disabled: false;
      reason: undefined;
    }
  | {
      disabled: true;
      reason: string;
    };

export const useOptionPermissions = (option: PortfolioMultiUploaderView): DisabledProps => {
  const { hasPermission } = useContext(UserContext);
  const hasAllFeatures = useHasAllFFs(option.requiredFeatures);
  const enabled = hasAllFeatures && option.requiredPermissions.every((action) => hasPermission(action));
  return enabled
    ? {
        disabled: false as const,
        reason: undefined,
      }
    : {
        disabled: true as const,
        reason: 'Please contact an administrator to add this feature to your workspace',
      };
};
