import type { History } from 'history';
import type { AnalysisConfig } from 'venn-utils';
import { getAnalysisPath, Routes } from 'venn-utils';
import omit from 'lodash/omit';

const buildBasePath = (analysisConfig: AnalysisConfig) => {
  const { analysisTemplate, subject } = analysisConfig;
  if (!subject || !analysisTemplate) {
    return null;
  }
  const { strategyId, superType, superId } = subject;
  const basePath = getAnalysisPath(superType, superId, analysisTemplate.id);
  const optionPath: string = strategyId ? `/${strategyId}` : '';
  return `${basePath}${optionPath}`;
};

const updateNavigation = (analysisConfig: AnalysisConfig, history: History) => {
  const path = buildBasePath(analysisConfig);
  const currentPath = history.location.pathname;
  if (path && path !== currentPath && currentPath.startsWith(`${Routes.ANALYSIS_PATH}/results`)) {
    // Preserve search params on subject change
    const search = history.location.search;
    const locationState = history.location.state;
    history.push(`${path}${search}`, typeof locationState === 'object' ? omit(locationState, 'previousPathMatch') : {});
  }
};

export { updateNavigation };
