import React, { useContext, useState } from 'react';
import type { MultiPortfolioParseResult, MultiPortfolioPersistResult } from 'venn-api';
import { assertNotNil } from 'venn-utils';
import UniversalUploaderContext from '../../../contexts/universal-uploader';
import { IPortfolioUploadConfirmationView, IReviewPortfolioView, IUploadPortfolioView } from '../constants';
import type { MultiPortfolioUploaderViewId } from '../types';
import MultiPortfolioConfirmationStep from './MultiPortfolioConfirmationStep';
import { MultiPortfolioReviewStep } from './MultiPortfolioReviewStep';
import MultiPortfolioUploadStep from './MultiPortfolioUploadStep';

type MultiPortfolioUploaderProps = Readonly<{
  viewId: MultiPortfolioUploaderViewId;
}>;

const useMultiPortfolioUploaderState = () => {
  const { setView } = useContext(UniversalUploaderContext);
  const [parsedData, setParsedData] = useState<MultiPortfolioParseResult>();
  const [persistResult, setPersistResult] = useState<MultiPortfolioPersistResult>();

  const goToReviewStep = (parsedData: MultiPortfolioParseResult) => {
    setParsedData(parsedData);
    setView(IReviewPortfolioView);
  };

  const goBackToUploadStep = () => {
    setParsedData(undefined);
    setView(IUploadPortfolioView);
  };

  const goToUploadConfirmation = (persistResult: MultiPortfolioPersistResult) => {
    setPersistResult(persistResult);
    setView(IPortfolioUploadConfirmationView);
  };

  return {
    parsedData,
    persistResult,
    goToReviewStep,
    goBackToUploadStep,
    goToUploadConfirmation,
  };
};

const MultiPortfolioUploader = ({ viewId }: MultiPortfolioUploaderProps) => {
  const { parsedData, persistResult, goToReviewStep, goBackToUploadStep, goToUploadConfirmation } =
    useMultiPortfolioUploaderState();

  switch (viewId) {
    case 'UPLOAD_PORTFOLIO':
      return <MultiPortfolioUploadStep goToReviewStep={goToReviewStep} />;
    case 'REVIEW_PORTFOLIO':
      return (
        <MultiPortfolioReviewStep
          parsedData={assertNotNil(parsedData)}
          goBackToUploadStep={goBackToUploadStep}
          goToUploadConfirmation={goToUploadConfirmation}
        />
      );
    case 'PORTFOLIO_UPLOAD_CONFIRMATION':
      return <MultiPortfolioConfirmationStep persistResult={assertNotNil(persistResult)} />;
    default:
      return null;
  }
};

export default MultiPortfolioUploader;
