import type { OperationResult } from './utils';
import { buildRequestInit, extractError } from './utils';
import { cached } from './cache';
import type { ExportTypeEnum, UserFeature } from './autogenerated/types';
import { signout } from './autogenerated/operations/auth';
import { getMetadataOptions } from './autogenerated/operations/funds';
import { getSwitchableWorkspaces } from './autogenerated/operations/user';
import { getUploadMetadata } from './autogenerated/operations/timeseries-v2';
import { getNotableFactorLensPeriodsV2 } from './autogenerated/operations/factorlenses-v2';

export async function storeImageWithBasePath(image: File, basePath: string): Promise<OperationResult<string>> {
  const formData = new FormData();
  formData.append('image', image);
  formData.append('basePath', basePath);
  const endpoint = '/api/images';
  const requestInit = buildRequestInit({
    method: 'post',
    body: formData,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: string = (await response.json()) as string;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}

export async function storeOrganizationLogo(body: File, isDefault?: boolean): Promise<OperationResult<void>> {
  const formData = new FormData();
  formData.append('logo', body);
  const endpoint = `/api/printing/logo${isDefault ? '/default' : ''}`;
  const requestInit = buildRequestInit({
    method: 'post',
    body: formData,
  });
  const response = await fetch(endpoint, requestInit);
  return {
    content: undefined,
    status: response.status,
    headers: {},
  };
}

export async function updateAvatar(body: File, isSponsor?: boolean): Promise<OperationResult<void>> {
  const formData = new FormData();
  formData.append('avatar[]', body);
  const endpoint = `/api/avatar/${isSponsor ? 'sponsor' : 'user'}`;
  const requestInit = buildRequestInit({
    method: 'post',
    body: formData,
  });
  const response = await fetch(endpoint, requestInit);
  return {
    content: undefined,
    status: response.status,
    headers: {},
  };
}

/** Custom function to allow cookie banner to be exported - Check if IP is under GPDR  */
export async function absolutePathCheckIPGdprApplied(signal?: AbortSignal): Promise<OperationResult<boolean>> {
  const endpoint = `${process.env.DOMAIN ?? 'https://app.venn.twosigma.com'}/api/user/gdpr`;
  const requestInit = buildRequestInit({
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: boolean = (await response.json()) as boolean;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}

/** Custom function to allow cookie banner to be exported - Get all features visible to a user */
export async function absolutePathGetVisibleFeaturesForUser(
  signal?: AbortSignal,
): Promise<OperationResult<{ [key: string]: UserFeature }>> {
  const endpoint = `${process.env.DOMAIN ?? 'https://app.venn.twosigma.com'}/api/featureavailability`;
  const requestInit = buildRequestInit({
    method: 'GET',
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: { [key: string]: UserFeature } = (await response.json()) as { [key: string]: UserFeature };
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}

// This file contains temporary API for endpoints where the Swagger
// file is either missing or not correct.
export const cachedGetNotableFactorLensPeriodsV2 = cached(getNotableFactorLensPeriodsV2);
export const cachedGetFileUploadMetadata = cached(getUploadMetadata);
export const cachedGetOrganizations = cached(getSwitchableWorkspaces);
export const cachedGetCategoriesOptions = cached(getMetadataOptions);

export interface UploadMetaData {
  name: string;
  subjectId: string | number;
  subjectName?: string;
  exportType?: ExportTypeEnum;
  startDate?: number;
  endDate?: number;
  savedId?: string;
  benchmarkName?: string;
  templateId?: string;
  templateName?: string;
}

/** Format FormData to backend supported way */
export async function uploadSnapshotFile(
  body: File | Blob,
  metaData: UploadMetaData,
  signal?: AbortSignal,
): Promise<OperationResult<string>> {
  const endpoint = '/api/snapshot';
  const formData = new FormData();
  const {
    name,
    startDate,
    endDate,
    subjectId,
    subjectName,
    exportType,
    savedId,
    benchmarkName,
    templateId,
    templateName,
  } = metaData;
  formData.append('fileName', name);
  formData.append('subjectId', subjectId.toString());
  if (subjectName) {
    formData.append('subjectName', subjectName);
  }
  if (exportType) {
    formData.append('exportType', exportType);
  }

  if (savedId) {
    formData.append('saveId', savedId);
  }

  if (templateId && templateName) {
    formData.append('templateId', templateId);
    formData.append('templateName', templateName);
  }

  if (benchmarkName) {
    formData.append('benchmarkName', benchmarkName);
  }

  if (startDate) {
    formData.append('startDate', startDate.toString());
  }
  if (endDate) {
    formData.append('endDate', endDate.toString());
  }

  formData.append('file', body);

  const requestInit = buildRequestInit({
    method: 'POST',
    body: formData,
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: string = (await response.json()) as string;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}

export async function signOutAndClearStorage() {
  const response = await signout();
  localStorage.clear();
  return response;
}
