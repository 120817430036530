import React from 'react';
import type { DataRangeInfo } from './utils';
import { formatDateByFrequency } from './utils';
import { CellLoader } from 'venn-ui-kit';

interface DataDateRangeProps {
  dataRangeInfo?: DataRangeInfo;
}

const DataDateRange = ({ dataRangeInfo }: DataDateRangeProps) => {
  return (
    <>
      {!dataRangeInfo ? (
        <CellLoader />
      ) : dataRangeInfo.start && dataRangeInfo.end && dataRangeInfo.frequency ? (
        `${formatDateByFrequency(dataRangeInfo.start, dataRangeInfo.frequency)} - ${formatDateByFrequency(
          dataRangeInfo.end,
          dataRangeInfo.frequency,
        )}`
      ) : (
        'Not Available'
      )}
    </>
  );
};

export default DataDateRange;
