import { mockTheme } from 'venn-utils';
import { theme } from '../global';
import { userIsAuthenticated } from '../user';
import { setRecoilState } from 'venn-test/recoil';

/** A simple array of common states to kickstart testing that involves Recoil and potentially Studio.  */
export const authAndThemeState = () =>
  Object.freeze([
    setRecoilState({ state: userIsAuthenticated, value: true }),
    setRecoilState({ state: theme, value: mockTheme }),
  ]);
