import { IconForPill } from './IconForPill';
import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import Pill from '../Pill';
import type { ItemType } from './itemType';
import { getItemColor } from './getItemColor';
import { getItemLabel } from './getItemLabel';

export const ItemTypePillWithIcon = ({ itemType }: { itemType: ItemType }) => {
  const { Colors } = useContext(ThemeContext);
  const color = getItemColor(Colors, itemType);
  const label = getItemLabel(itemType, false);

  return (
    <StyledPill className={`qa-${itemType}-type-pill`} color={color}>
      <IconForPill itemType={itemType} />
      <span>{label}</span>
    </StyledPill>
  );
};

const StyledPill = styled(Pill)<{ color: string }>`
  background: ${(props) => props.color};
  display: inline-block;
  height: 22px;
  line-height: 22px;
  padding: 0 8px;
  text-align: left;

  & > * + * {
    margin-left: 6px;
  }
`;
