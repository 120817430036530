import React from 'react';
import type { StudioIconProps } from './shared';
import { GetColor } from '../../style/color';
import { Icon } from '../icon/Icon';
import styled from 'styled-components';

const StudioIcon = ({ color, size = 26 }: StudioIconProps) => {
  return <StudioFontAwesomeIcon color={color} size={size} type="calculator-simple" data-testid="qa-studio-icon" />;
};

const StudioFontAwesomeIcon = styled(Icon)<{ size: number; color?: string }>`
  color: ${(props) => `${props.color ?? GetColor.GreyScale.Grey40}`};
  text-align: center;
  font-size: ${(props) => `${props.size}px`};
  width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 1px;
`;

export default StudioIcon;
