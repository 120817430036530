export const CHART_PADDING = 20;
export const CHART_TOP_TEXT_PADDING = CHART_PADDING + 14;
export const TOTAL_ID = -2;
export const RESIDUAL_ID = -1;
export const RISK_FREE_RATE_ID = -3;
export const TOOLTIP_WIDTH = 310;
export const COMPACT_TOOLTIP_WIDTH = 240;
export const POPOVER_WIDTH = 400;
export const LEGEND_TEXT_PADDING_GAP = 4;
export const LEGEND_LINE_HEIGHT = 18;
export const ROW_HEIGHT = 32;
export const ROW_PRINT_HEIGHT = 16;
export const MESSAGE_HEIGHT = 25;
export const MESSAGE_PRINT_HEIGHT = 16;
