import { memoize } from 'lodash';

/**
 * Converts a number into letters:
 * 0 is A, 25 is Z, and 26 is AA and so on.
 */
function convertToLettersBase(num: number) {
  const baseChar = 'A'.charCodeAt(0);
  let internalNumber = num;
  let letters = '';

  do {
    letters = String.fromCharCode(baseChar + (internalNumber % 26)) + letters;
    internalNumber = Math.floor(internalNumber / 26) - 1;
  } while (internalNumber >= 0);

  return letters;
}

export const convertToLetters = memoize(convertToLettersBase);

export const acceptedFileTypes = [
  '.csv',
  '.xls',
  '.xlsx',
  '.pdf',
  'text/csv',
  'text/plain',
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
].join(',');

export const acceptedPrivatesFileTypes = ['.xls', '.xlsx'].join(',');
