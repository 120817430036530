import type { Portfolio } from 'venn-api';
import { compact } from 'lodash';
import type { Theme } from 'venn-ui-kit';
import { ColorUtils } from 'venn-ui-kit';
import type { CompareType } from '../contexts/comparison-context';

export enum TreeItemUpdateType {
  'CHANGE_ALLOCATION',
  'ADD_FUND',
  'UPLOAD_FUND',
  'DELETE_PORTFOLIO',
  'DELETE_PORTFOLIO_CHILD',
  'ADD_STRATEGY', // Add empty strategy
  'DRAG_N_DROP',
  'DRAG_N_DROP_REORDER_ONLY',
  'RESET',
  'SAVE',
  'ADD_FROM_GHOST',
  'EDIT_NAME',
  'EXTERNAL_DELETE',
  'APPLY_ALLOCATIONS_FROM_COMPARE',
  'EDIT_CAPITAL',
  'IMPORT_STRATEGY', // Import existing strategy
}

export const checkHasQuantDiff = (value: number, compareValue: number, divider: number) =>
  Math.round(Math.abs(value - compareValue) * divider) / divider >= 1 / divider;

/**
 * When it's in percentage mode, we will keep previous base allocation
 * UNLESS we're doing cash reallocation (update type EDIT_CAPITAL)
 */
export const getCurrentBaseAllocation = (
  newPortfolio: Portfolio,
  prevBaseAllocation: number,
  isPercentageMode: boolean,
  updateType: TreeItemUpdateType,
): number => {
  if (isPercentageMode && updateType !== TreeItemUpdateType.EDIT_CAPITAL) {
    return prevBaseAllocation;
  }
  return newPortfolio.allocation ?? 0;
};

export const getWithReplacedStrategy = (
  portfolio: Portfolio,
  replaceStrategyId: number,
  allCompareNodes: Map<number, Portfolio>,
): Portfolio => {
  const { updatedPortfolio } = getWithMaybeReplacedInnerStrategy(portfolio, replaceStrategyId, allCompareNodes);
  if (updatedPortfolio === null) {
    return {
      ...portfolio,
      allocation: 0,
      children: [],
    };
  }
  return updatedPortfolio;
};

const getWithMaybeReplacedInnerStrategy = (
  portfolio: Portfolio,
  replaceStrategyId: number,
  allCompareNodes: Map<number, Portfolio>,
): { updatedPortfolio: Portfolio | null; hasUpdated: boolean } => {
  if (portfolio.id === replaceStrategyId) {
    return { updatedPortfolio: getReplaced(portfolio, allCompareNodes), hasUpdated: true };
  }

  for (let i = 0; i < portfolio.children.length; i++) {
    const { updatedPortfolio: updatedChild, hasUpdated } = getWithMaybeReplacedInnerStrategy(
      portfolio.children[i],
      replaceStrategyId,
      allCompareNodes,
    );

    if (hasUpdated) {
      const children = compact([...portfolio.children.slice(0, i), updatedChild, ...portfolio.children.slice(i + 1)]);
      return {
        updatedPortfolio: {
          ...portfolio,
          children,
          allocation: children.reduce((sum: number, { allocation }) => sum + (allocation ?? 0), 0),
        },
        hasUpdated: true,
      };
    }
  }

  return { updatedPortfolio: portfolio, hasUpdated: false };
};

const getReplaced = (node: Portfolio, allCompareNodes: Map<number, Portfolio>): Portfolio | null => {
  const compareNode = allCompareNodes.get(node.id);
  if (!compareNode) {
    return null;
  }
  if (node.fund) {
    return {
      ...node,
      allocation: compareNode.allocation,
    };
  }
  const ghostChildren = new Map<number | string, Portfolio>();
  compareNode.children.forEach((child) => {
    ghostChildren.set(child.fund?.id ?? child.id, child);
  });
  const children: Portfolio[] = [];
  const existingStrategyNames = new Set<string>();
  node.children.forEach((child) => {
    ghostChildren.delete(child.fund?.id ?? child.id);
    const updatedChild = getReplaced(child, allCompareNodes);
    if (updatedChild !== null) {
      children.push(updatedChild);
      if (!updatedChild.fund) {
        existingStrategyNames.add(updatedChild.name);
      }
    }
  });
  [...ghostChildren.values()]
    // deduplicate strategies with the same, they should have already been added in the previous forEach
    .filter((ghostChild) => ghostChild.fund || !existingStrategyNames.has(ghostChild.name))
    .forEach((ghostChild) => {
      children.push(ghostChild);
    });
  return {
    ...node,
    children,
    allocation: children.reduce((sum: number, { allocation }) => sum + (allocation ?? 0), 0),
  };
};

/**
 * Returns the primary & lighter color
 */
export const getColorsFromCompareType = (compareType: CompareType | undefined, theme: Theme): [string, string] => {
  const { Colors } = theme;
  const Blue = Colors.DEPRECATED_DataLineColor.DarkBlue;
  const LightBlue = ColorUtils.hex2rgba(Blue, 0.5);

  const Purple = Colors.HighlightDark;
  const LightPurple = Colors.HighlightLight;

  return compareType === 'Optimized' ? [Purple, LightPurple] : [Blue, LightBlue];
};
