import { useQuery } from '@tanstack/react-query';
import { groupBy } from 'lodash';
import { useMemo } from 'react';
import { type CashFlowSetting, getAllSystemCashflowSettings, type PrivateFundAssetClassEnum } from 'venn-api';
import { Dates, logExceptionIntoSentry } from 'venn-utils';

const KEY = 'FETCH_SYSTEM_CASHFLOW_SETTINGS';

const useAllSystemSettings = () => {
  return useQuery<CashFlowSetting[]>(
    [KEY],
    async () => {
      return (await getAllSystemCashflowSettings()).content;
    },
    {
      onError: (error) => {
        return logExceptionIntoSentry(error as Error);
      },
      suspense: true,
    },
  );
};

type Vintage = {
  value: number;
  name: string;
  setting: CashFlowSetting;
};

type Strategy = {
  value: string;
  name: string;
  vintages: Map<number, Vintage>;
};

type AssetClass = {
  value: PrivateFundAssetClassEnum | typeof ALL_ASSET_CLASSES;
  name: string;
  strategies: Map<string, Strategy>;
};

type Tree = Map<string, AssetClass>;

// Map<string, ...> and DropMenu don't work with undefined so we will be using these instead
export const ALL_ASSET_CLASSES = '__ALL_ASSET_CLASSES__';
export const ALL_STRATEGIES = '__ALL_STRATEGIES__';
export const ALL_VINTAGES = -1;

export const useSystemSettingsTree = (): Tree => {
  const { data = [] } = useAllSystemSettings();

  return useMemo(() => {
    const tree = new Map<string, AssetClass>();

    const groupedByAssetClass = groupBy(data, (setting) => setting.assetClass ?? ALL_ASSET_CLASSES);

    Object.keys(groupedByAssetClass).forEach((assetClass) => {
      const settingsForAssetClass = groupedByAssetClass[assetClass];
      const assetClassName = settingsForAssetClass[0].assetClassName ?? 'All Asset Classes';

      tree.set(assetClass, {
        value: assetClass as AssetClass['value'],
        name: assetClassName,
        strategies: new Map<string, Strategy>(),
      });

      const groupedByStrategy = groupBy(settingsForAssetClass, (setting) => setting.strategyName ?? ALL_STRATEGIES);

      Object.keys(groupedByStrategy).forEach((strategyName) => {
        const settingsForStrategy = groupedByStrategy[strategyName];

        tree.get(assetClass)?.strategies.set(strategyName, {
          value: strategyName,
          name: strategyName === ALL_STRATEGIES ? 'All Strategies' : strategyName,
          vintages: new Map<number, Vintage>(
            settingsForStrategy.map((setting) => [
              setting.vintage ?? ALL_VINTAGES,
              {
                value: setting.vintage ?? ALL_VINTAGES,
                name: setting.vintage ? Dates.toYear(setting.vintage) : 'All Vintages',
                setting,
              },
            ]),
          ),
        });
      });
    });

    return tree;
  }, [data]);
};
