import { useEffect, useState } from 'react';
import type { Solution } from 'venn-components';
import type { Point } from 'highcharts';
import { isNil } from 'lodash';

/**
 * Use case: having more than one Highchart in the app, with corresponding points, and wanting to synchronize the
 * selected point between them.
 * How to use it:
 * call it in `
 *   chart: {
 *     events: {
 *       load() {
 *         const points = [];
 *         // ... logic to select the points to store ...
 *         setChartPoints(points);
 *       }
 *     }
 *   }`
 * with all the selectable points that you want to synchronize.
 * The hook stores references to chart points and whenever the `selectedSolution` changes, it goes in and selects/deselects
 * the appropriate points to keep the charts in sync.
 */
const useSynchronizedSelectedChartPoints = (selectedSolution: Solution | undefined) => {
  const [chartPoints, setChartPoints] = useState<Point[]>([]);
  useEffect(() => {
    const shouldBeSelected = isNil(selectedSolution)
      ? undefined
      : chartPoints.find(
          (point) =>
            point.options?.category === selectedSolution?.category &&
            point.options?.alternateIdx === selectedSolution?.alternateSolutionIdx,
        );
    const currentSelected = chartPoints.find((point) => point.selected);
    if (isNil(shouldBeSelected)) {
      // Point that should now become selected is not present in that chart
      if (!isNil(currentSelected)) {
        // Deselect the one that's currently selected, but shouldn't be (anymore)
        currentSelected.select(false, false);
      }
      return;
    }
    if (shouldBeSelected.selected) {
      return;
    }
    shouldBeSelected.select(true, false);
  }, [selectedSolution, chartPoints]);

  return {
    setChartPoints,
  };
};

export default useSynchronizedSelectedChartPoints;
