import type { BasicTableColumn, BasicTableProps, StyledTableType } from './BasicTable';
import BasicTable from './BasicTable';
import { GetColor, ZIndex } from 'venn-ui-kit';
import styled from 'styled-components';
import React from 'react';

export const StripedTable: StyledTableType<unknown> = styled(
  <T extends BasicTableColumn<K>, K>(props: BasicTableProps<T, K>) => <BasicTable<T, K> {...props} />,
)`
  table-layout: auto;
  white-space: nowrap;
  border-spacing: 0;
  padding-top: 40px;
  border-collapse: collapse;

  > thead {
    height: 26px;
  }

  > thead > tr > th {
    font-size: 14px;
    position: sticky;
    background-color: ${GetColor.White};
    z-index: ${ZIndex.Front};
    border-bottom: 1px solid ${GetColor.Grey};
    padding: 5px 20px;
    vertical-align: middle;
  }

  > tbody > tr {
    height: 60px;

    > td {
      padding: 20px;
      font-size: 14px;
      vertical-align: top;
    }

    .reporting-name-cell {
      position: relative;
      padding-right: 20px;
    }
  }

  tr.basic-table-row-odd {
    background-color: ${GetColor.GreyScale.Grey10};
  }

  tr.basic-table-row-expandable {
    height: auto;
    border-bottom: 1px solid ${GetColor.PaleGrey};
  }
`;
