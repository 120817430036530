import React, { Fragment } from 'react';
import type { AnalysisBlockTypeEnum } from 'venn-api';
import styled from 'styled-components';
import { ComponentFromBlock } from '../blocks';
import Factor from './FactorAnalysisLayout';
import Tearsheet from './Tearsheet';
import PerformanceAndRisk from './PerformanceAndRisk';
import ShockAnalysis from './ShockAnalysis';
import WelcomeToVennTemplate from './WelcomeToVennTemplate';
import type { SharedProps } from '../types';
import type { AnyDuringEslintMigration } from 'venn-utils';
import { PRINT_NARROW_BLOCKS, logMessageToSentry } from 'venn-utils';
import { FailedAnalysisInfo } from 'venn-components';
import isEqual from 'lodash/isEqual';
import { noop, isNil } from 'lodash';
import { BlockRow, BlockCell, SectionHeadline } from 'venn-ui-kit';
import { getDownloadMetaData } from '../utils';
import { SpecialFactorTemplateId } from '../logic/useTrackFailedAnalysis';

interface AnalysisBlocksProps extends SharedProps {
  getHeaderOffset?: () => number;
}

const PREDEFINED_SPECIAL_TEMPLATES: { [key: string]: React.ComponentType<React.PropsWithChildren<SharedProps>> } = {
  [SpecialFactorTemplateId.TEARSHEET]: Tearsheet,
  [SpecialFactorTemplateId.ALL]: Tearsheet,
  [SpecialFactorTemplateId.FACTOR]: Factor,
  performance_risk: PerformanceAndRisk,
  shock: ShockAnalysis,
  welcome: WelcomeToVennTemplate,
};

const PERFORMANCE_SUMMARY = 'PERFORMANCE_SUMMARY';
const AnalysisBlocks = React.memo(
  function AnalysisBlocks(props: AnalysisBlocksProps) {
    const { analyses, analysisConfig, analysesError, onResetTimeFrame, getHeaderOffset } = props;

    if (analysesError) {
      // `blockNames`, `blockTitles` and `updateAnalysisStatusForTracking` are NOT supplied to the below error.
      // In case when all analyses fail, tracking is performed from AnalysisPage. This is because the below
      // component is RE-RENDERED BEFORE WE START FETCHING, so before we actually know if the new analysis failed.
      return (
        <Section>
          <FailedAnalysisInfo
            subject={analyses?.subject}
            analysesPeriod={analyses?.analysesPeriod}
            onResetAnalysisPeriod={onResetTimeFrame}
            error={{
              // TODO: (VENN-20577 / TYPES) is autogenerated type for Message wrong, should it contain the .message field?
              message: analysesError.text || (analysesError as AnyDuringEslintMigration).message,
              code: analysesError.code,
            }}
            regressionName={analysisConfig?.analysisTemplate?.name || ''}
            relativeToBenchmark={analysisConfig.relative}
            categoryActive={analysisConfig.category === 'ON'}
            trackingId={analysisConfig.trackingId}
            blockNames={[]}
            blockTitles={[]}
            updateAnalysisStatusForTracking={noop}
          />
        </Section>
      );
    }
    if (!analysisConfig.analysisTemplate || !analysisConfig.analysisTemplate.analysisBlocks) {
      logMessageToSentry('Failed to load AnalysisBlocks because missing analysisTemplate');
      return null;
    }

    const downloadMetaData = getDownloadMetaData(
      analysisConfig,
      analyses?.analysesPeriod?.startTime,
      analyses?.analysesPeriod?.endTime,
      analyses?.analysesPeriod?.frequency,
    );

    const sharedPropsWithMeta = { ...props, downloadMetaData };

    const { analysisBlocks, id: templateId } = analysisConfig.analysisTemplate;

    const perfSummaryBlock = <ComponentFromBlock block={PERFORMANCE_SUMMARY} sharedProps={sharedPropsWithMeta} />;
    const blockRows: AnalysisBlockTypeEnum[][] = [];
    for (const block of analysisBlocks) {
      const blockName = block.analysisBlockType;
      const lastRow: AnalysisBlockTypeEnum[] | undefined = blockRows?.[blockRows.length - 1];
      if (
        lastRow &&
        lastRow.length === 1 &&
        PRINT_NARROW_BLOCKS.includes(lastRow[0]) &&
        PRINT_NARROW_BLOCKS.includes(blockName)
      ) {
        lastRow.push(blockName);
      } else {
        blockRows.push([blockName]);
      }
    }

    const blocks = blockRows.map((blockRow: AnalysisBlockTypeEnum[], rowIndex: number) => (
      // eslint-disable-next-line react/no-array-index-key
      <BlockRow key={rowIndex} avoidPageBreak={blockRow[0] !== 'DRAWDOWN'}>
        {blockRow.map((block: AnalysisBlockTypeEnum, colIndex: number) => (
          <BlockCell key={block} col={colIndex} numCellsInRow={blockRow.length}>
            {block === PERFORMANCE_SUMMARY ? (
              perfSummaryBlock
            ) : (
              <ComponentFromBlock
                block={block}
                sharedProps={sharedPropsWithMeta}
                PerfSummaryBlock={perfSummaryBlock}
                getHeaderOffset={getHeaderOffset}
              />
            )}
          </BlockCell>
        ))}
      </BlockRow>
    ));

    const Template = PREDEFINED_SPECIAL_TEMPLATES[templateId];
    const isWelcomeTemplate = templateId === 'welcome';
    const PerfSummaryWrapper = isWelcomeTemplate ? Section : Fragment;

    if (isNil(Template)) {
      return <BlocksList>{blocks}</BlocksList>;
    }

    return (
      <BlocksList>
        <PerfSummaryWrapper>
          {isWelcomeTemplate && <SectionHeadline>Performance Check</SectionHeadline>}
          {perfSummaryBlock}
        </PerfSummaryWrapper>
        <Template {...sharedPropsWithMeta} />
      </BlocksList>
    );
  },
  // Hooks version of shouldComponentUpdate
  // https://reactjs.org/docs/hooks-faq.html#how-do-i-implement-shouldcomponentupdate
  (prevProps, nextProps) => {
    const { analyses, analysesError, onResetTimeFrame, analysisConfig, analysisLoading } = nextProps;
    return (
      isEqual(analyses, prevProps.analyses) &&
      analysisLoading === prevProps.analysisLoading &&
      isEqual(analysesError, prevProps.analysesError) &&
      isEqual(analysisConfig, prevProps.analysisConfig) &&
      analysisConfig.subject === prevProps.analysisConfig.subject &&
      analysisConfig.analysisTemplate?.analysisBlocks === prevProps.analysisConfig.analysisTemplate?.analysisBlocks &&
      isEqual(onResetTimeFrame, prevProps.onResetTimeFrame)
    );
  },
);

export default AnalysisBlocks;

const Section = styled.section`
  padding-top: 20px;
`;

const BlocksList = styled.div`
  @media print {
    display: block;
    width: 100%;
  }
`;
