import { compact, sortedUniqBy } from 'lodash';
import { selectorFamily } from 'recoil';
import type { SubjectId } from 'venn-api';
import { batchGetExportMetadata } from 'venn-api';
import type { CreateSerializableParam } from 'venn-utils';
import { isRequestSuccessful } from 'venn-utils';
import type { Subject } from './types';
import { allUniqViewSubjectsFlattened } from './configuration/allViewSubjects';
import { blockScenarios } from './configuration/customViewOptions';
import { allBlockIdsState } from './grid';
import { blockBenchmarkSubjects } from './configuration/benchmark';
import { blockMetrics } from './configuration/blockConfig';
import { Notifications, NotificationType } from 'venn-ui-kit';

const UNABLE_TO_CHECK = 'Unable to check if data can be exported.';

export const toTypeSafeSubjectId = ({ portfolioId, fundId, privatePortfolioId, privateFundId }: Subject): SubjectId => {
  if (portfolioId) {
    return { type: 'portfolio' as const, id: portfolioId };
  }
  if (fundId) {
    return { type: 'fund' as const, id: fundId };
  }
  if (privatePortfolioId) {
    return { type: 'private-portfolio-id' as const, id: privatePortfolioId };
  }
  if (privateFundId) {
    return { type: 'private-fund-id' as const, id: privateFundId };
  }
  throw new Error('unreachable');
};

type RedistributableFetchParams = CreateSerializableParam<{ subjectIds: SubjectId[]; usesForecast: boolean }>;

/**
 * Separated into its own selector for optimizing caching.
 * Technically we don't need to pass in usesForecast either, and clients could handle it instead, but it
 * seemed handy to centralize the logic for response processing next to the fetch.
 *
 * NOTE: for optimal caching, subjectIds should be sorted and unique prior to using with this selector.
 */
const fetchRedistributableForSubjectIds = selectorFamily({
  key: 'fetchRedistributableForSubjectIds',
  get:
    ({ subjectIds, usesForecast }: RedistributableFetchParams) =>
    async () => {
      if (subjectIds.length === 0) {
        return true;
      }

      try {
        const response = await batchGetExportMetadata({ subjectIds });
        if (!isRequestSuccessful(response)) {
          Notifications.notify(UNABLE_TO_CHECK, NotificationType.ERROR);
          return false;
        }
        const content = response.content;
        const subjectRedistributable = content.subjectExportMetadata.every((metadatum) => metadatum.redistributable);
        const forecastRedistributable = content.forecastExportMetadata.every((metadatum) => metadatum.redistributable);
        return subjectRedistributable && (!usesForecast || forecastRedistributable);
      } catch (error) {
        Notifications.notify(UNABLE_TO_CHECK, NotificationType.ERROR);
        return false;
      }
    },
});

/** This is only to be used in report lab as it does not honour allocator panel changes. */
export const redistributableStateIgnoringAllocatorPanel = selectorFamily({
  key: 'redistributable',
  // We must use the viewId as a key or otherwise we get stale data when switching between views. That would be fine for most UI because it
  //  near-instantly corrects itself, but it is much worse for a persistent toast notification to display erroneously.
  get:
    (_viewId: string) =>
    ({ get }) => {
      // TODO(VENN-23000): we should just return false for when in Studio since Studio
      // doesn't care about redistributability, but we're still sending lots of requests for redistributability data.
      const blockSubjectIds = get(allUniqViewSubjectsFlattened).map(toTypeSafeSubjectId);
      const blockIds = get(allBlockIdsState);
      const scenarioSubjectIds = blockIds
        .flatMap((blockId) => get(blockScenarios(blockId)))
        .filter((scenario) => scenario.type !== 'MACRO')
        .map((scenario) => ({
          type: 'fund' as const,
          id: scenario.fundId,
        }));
      const benchmarkIds = compact(blockIds.flatMap((blockId) => get(blockBenchmarkSubjects(blockId)))).map(
        toTypeSafeSubjectId,
      );

      const sortedUniqSubjectIds = sortedUniqBy(
        [scenarioSubjectIds, blockSubjectIds, benchmarkIds].flat().sort(),
        (subjectId) => subjectId.type + subjectId.id,
      );
      const usesForecast = blockIds
        .flatMap((blockId) => get(blockMetrics(blockId)))
        .some((metric) => metric.includes('FORECAST'));

      return get(fetchRedistributableForSubjectIds({ subjectIds: sortedUniqSubjectIds, usesForecast }));
    },
});
