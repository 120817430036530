import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import type { IconProps } from 'venn-ui-kit';
import { getAppTitle, GetColor, Headline1, Icon, StudioIcon } from 'venn-ui-kit';
import { analyticsService, navigateToReportLab, navigateToStudioView } from 'venn-utils';
import GoToSavedViewsAction from './GoToSavedViewsAction';
import GoToDataLibraryAction from './GoToDataLibraryAction';
import WealthCard from './WealthCard';
import { useHistory } from 'react-router-dom';
import type { AnalysisView } from 'venn-api';

export const HOLDINGS_TEMPLATE_ID_REPORTLAB_V2 = '308f00d3-14dc-4d7f-8523-c26791eabc70';

const Headline = () => (
  <Headline1>
    <div>
      <New>Get started:</New>
    </div>
    <div> View reports and analyze your portfolios. </div>
  </Headline1>
);

const WealthManagerTab = () => {
  const history = useHistory();
  const { Colors } = useContext(ThemeContext);

  const visitReportlabTemplate = () => {
    const document = { id: HOLDINGS_TEMPLATE_ID_REPORTLAB_V2, analysisViewType: 'REPORT' } as AnalysisView;
    navigateToReportLab(history, {
      newDocument: document,
      id: HOLDINGS_TEMPLATE_ID_REPORTLAB_V2,
    });
    analyticsService.ctaClicked({
      destination: 'Report Lab',
      locationOnPage: 'Wealth onboarding tutorial',
      purpose: 'View demo report',
      type: 'button',
    });
  };
  const visitStudioTemplate = () => {
    const document = { id: HOLDINGS_TEMPLATE_ID_REPORTLAB_V2, analysisViewType: 'TEARSHEET' } as AnalysisView;
    navigateToStudioView(history, {
      newDocument: document,
      id: HOLDINGS_TEMPLATE_ID_REPORTLAB_V2,
    });
    analyticsService.ctaClicked({
      destination: 'Studio Page',
      locationOnPage: 'Wealth onboarding tutorial',
      purpose: 'Analyze demo portfolio',
      type: 'button',
    });
  };

  return (
    <>
      <Headline />
      <ContentWrapper>
        <WealthCard
          title="View a report"
          buttonText="View demo report"
          bodyText={`Review a demo report created by ${getAppTitle()}.`}
          icon={<StyledIcon type="file-chart-column" iconFontSize={44} />}
          onClick={visitReportlabTemplate}
          alternativeAction={<GoToSavedViewsAction />}
        />
        <WealthCard
          title="Analyze a portfolio"
          buttonText="Analyze demo portfolio"
          bodyText={`Analyze a demo portfolio created by ${getAppTitle()}.`}
          icon={<StudioIcon color={Colors.MidGrey2} size={64} />}
          onClick={visitStudioTemplate}
          alternativeAction={<GoToDataLibraryAction />}
        />
      </ContentWrapper>
    </>
  );
};

export default WealthManagerTab;

const StyledIcon = styled((props) => <Icon {...(props as IconProps)} />)`
  color: ${GetColor.MidGrey2};
  font-size: ${({ iconFontSize }) => iconFontSize}px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const New = styled(Headline1)`
  color: ${GetColor.HighlightDark};
  font-weight: bold;
  font-style: italic;
`;
