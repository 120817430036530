import React, { useCallback, useContext, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import styled, { ThemeContext } from 'styled-components';
import type { MetricFilter, OrderEnum } from 'venn-api';
import { FactorLensesContext, type SORTDIR, StickyNode, TagsContext } from 'venn-components';
import { libraryFiltersStateSelector, librarySearchParamsAtom } from 'venn-state';
import { GetColor, Pagination, ZIndex } from 'venn-ui-kit';
import type { LibraryItemType } from 'venn-utils';
import { LibraryFilterSectionKey, QuickFilter, useHasFF } from 'venn-utils';
import type { AdvancedFilterValue, SearchResultWithUIState, SearchState } from './components';
import {
  AdvancedFilters,
  AdvancedFilterType,
  AssetTypeFilter,
  convertToSortDir,
  CurrencyFilter,
  getFilters,
  SimpleFilter,
  TableContainer,
  TagFilter,
} from './components';
import { getSupportedMetrics } from './components/filters/components/advanced/advancedQueryUtils';
import DataSourceFilter from './components/filters/components/advanced/DataSourceFilter';
import CategoryFilter from './components/filters/components/CategoryFilter';
import ManageSavedFilters from './components/filters/components/ManageSavedFilters';
import type { FilterLocation } from './components/filters/search';
import LibraryResults from './components/LibraryResults';
import { LibraryTabs } from './components/LibraryTabs';
import { LibraryTypeaheadSearchBar } from './components/LibraryTypeaheadSearchBar';
import { getSimilarMetricFilters } from './components/logic/similarItems';

interface LibraryLayoutProps {
  pageLoaded: React.MutableRefObject<boolean>;
  onSearchTextChange: (query: string) => void;
  handleApplyFilters: (key: string, value: string | string[], location: FilterLocation) => void;
  onSort: (key: string, dir: SORTDIR) => void;
  tableAnnotation: React.ReactNode;
  selectedFilters: [string[], LibraryItemType, string[]]; // quickfilters/source, item type, tags
  sortBy?: string;
  order: OrderEnum;
  results: SearchResultWithUIState[];
  loading: boolean;
  onAdvancedFilter: (key: AdvancedFilterType, updated: AdvancedFilterValue, location: FilterLocation) => void;
  setSearchState: React.Dispatch<React.SetStateAction<SearchState>>;
  fetchSearch: (hideLoading?: boolean) => Promise<void>;
  totalCount: number;
  size: number;
  page: number;
  onPageChange: (index: number) => void;
  name?: string;
  resetLibrarySearchParams: () => void;
  canResetFilters: boolean;
  trackSelectionMade: (item: SearchResultWithUIState) => void;
}

const LIBRARY_CONTENT_CLASS = 'library-content';
export const LIBRARY_CONTENT_SELECTOR = `.${LIBRARY_CONTENT_CLASS}`;

const LibraryLayout = ({
  pageLoaded,
  onSearchTextChange,
  handleApplyFilters,
  onSort,
  tableAnnotation,
  results,
  loading,
  setSearchState,
  fetchSearch,
  onPageChange,
  totalCount,
  size,
  sortBy,
  order,
  page,
  name,
  resetLibrarySearchParams,
  canResetFilters,
  trackSelectionMade,
  onAdvancedFilter,
}: LibraryLayoutProps) => {
  const librarySearchParams = useRecoilValue(librarySearchParamsAtom);
  const libraryFiltersState = useRecoilValue(libraryFiltersStateSelector);
  const theme = useContext(ThemeContext);
  const tagsContext = useContext(TagsContext);
  const { factorLenses } = useContext(FactorLensesContext);

  const [quickFiltersSection, itemTypesSection, tagsSection] = useMemo(
    () => getFilters(tagsContext.tags, theme),
    [tagsContext.tags, theme],
  );

  const supportedMetrics = useMemo(() => getSupportedMetrics(factorLenses), [factorLenses]);

  const findSimilar = useCallback(
    (item: SearchResultWithUIState) => {
      const metrics: MetricFilter[] = getSimilarMetricFilters(item, supportedMetrics);
      resetLibrarySearchParams();
      onAdvancedFilter(AdvancedFilterType.METRICS, metrics, 'library');
    },
    [supportedMetrics, resetLibrarySearchParams, onAdvancedFilter],
  );

  const shouldRevealPrivates = useHasFF('privates_reveal_ff');
  const hasCategoriesSearchFF = useHasFF('categories_search_ff');
  const hasCategoriesSearchLibraryFF = useHasFF('categories_search_library_ff');
  return (
    <Content>
      <TableAndAnnotationContainer className={LIBRARY_CONTENT_CLASS} includePadding>
        <Main>
          <>
            <TableAnnotationContainer>
              {shouldRevealPrivates && <LibraryTabs />}
              <SearchAndFiltersContainer>
                <div
                  style={{
                    width: 300,
                    marginRight: 30,
                    marginTop: 5,
                  }}
                >
                  {pageLoaded.current && (
                    <LibraryTypeaheadSearchBar
                      searchText={name}
                      onSearchTextChange={onSearchTextChange}
                      placeholder="Search library by name or identifier"
                    />
                  )}
                </div>
                <TagFilter
                  tags={tagsContext.tags}
                  disabled={tagsSection.disabled}
                  initialSelected={librarySearchParams[LibraryFilterSectionKey.TAGS]}
                  onFilter={(updated) => handleApplyFilters('tags', updated, 'library')}
                  tooltipContent={loading ? undefined : tagsSection.tooltip}
                  badge={tagsSection.badge}
                />
                <SimpleFiltersContainer>
                  {[quickFiltersSection, itemTypesSection]
                    .filter(({ advancedOnly }) => !advancedOnly)
                    .map((section, index) => {
                      const selected =
                        index === 0
                          ? librarySearchParams[LibraryFilterSectionKey.FILTERS]
                          : librarySearchParams.itemType;
                      return (
                        <SimpleFilter
                          applyLabel="Select"
                          menuWidth={295}
                          closeOnOnlyClick
                          singleSelection={!section.checkbox}
                          key={section.key}
                          label={section.title!}
                          initialSelected={typeof selected === 'string' ? [selected] : selected}
                          items={section.items}
                          onFilter={(updated) => handleApplyFilters(section.key, updated, 'library')}
                        />
                      );
                    })}
                  <CurrencyFilter
                    closeOnOnlyClick
                    onFilter={(v) => onAdvancedFilter(AdvancedFilterType.CURRENCY, v, 'library')}
                    selected={librarySearchParams.currency ?? []}
                  />

                  <AssetTypeFilter
                    closeOnOnlyClick
                    menuWidth={295}
                    onFilter={(v) => onAdvancedFilter(AdvancedFilterType.ASSET_TYPE, v, 'library')}
                    selected={librarySearchParams.assetTypes ?? []}
                  />
                  <DataSourceFilter
                    onFilter={(v) => onAdvancedFilter(AdvancedFilterType.DATA_SOURCE, v, 'library')}
                    selected={librarySearchParams.dataSource ?? []}
                    closeOnOnlyClick
                  />
                  {hasCategoriesSearchFF && hasCategoriesSearchLibraryFF && (
                    <CategoryFilter
                      onFilter={(v) => onAdvancedFilter(AdvancedFilterType.MORNINGSTAR_CATEGORY, v, 'library')}
                      selected={librarySearchParams.morningstarCategories ?? []}
                      closeOnOnlyClick
                    />
                  )}
                </SimpleFiltersContainer>
                <AdvancedFilters
                  onFilter={({
                    quickFilters,
                    itemType,
                    currency,
                    assetTypes,
                    dataSource,
                    advancedQueries,
                    morningstarCategories,
                    tags,
                  }) => {
                    handleApplyFilters('filters', quickFilters, 'allFilters');
                    handleApplyFilters('itemType', itemType, 'allFilters');
                    handleApplyFilters('tags', tags, 'allFilters');
                    onAdvancedFilter(AdvancedFilterType.CURRENCY, currency, 'allFilters');
                    onAdvancedFilter(AdvancedFilterType.ASSET_TYPE, assetTypes, 'allFilters');
                    onAdvancedFilter(AdvancedFilterType.DATA_SOURCE, dataSource, 'allFilters');
                    onAdvancedFilter(AdvancedFilterType.MORNINGSTAR_CATEGORY, morningstarCategories, 'allFilters');
                    onAdvancedFilter(
                      AdvancedFilterType.METRICS,
                      advancedQueries.map(({ operator, metric, value, timePeriod }) => ({
                        metric,
                        minimum: operator === 'geq' ? value : undefined,
                        maximum: operator === 'leq' ? value : undefined,
                        timePeriod,
                      })),
                      'allFilters',
                    );
                  }}
                  sections={[quickFiltersSection, itemTypesSection, tagsSection]}
                  initialSelected={libraryFiltersState}
                />
                <ManageFiltersContainer>
                  <ManageSavedFilters className="qa-manage-saved-filters" label="MANAGE SAVED FILTERS" />
                </ManageFiltersContainer>
              </SearchAndFiltersContainer>
              <ActionBar>
                {tableAnnotation}
                <div>
                  <Pagination
                    pagesCount={Math.ceil(totalCount / size)}
                    selectedPage={page}
                    onPageChange={onPageChange}
                  />
                </div>
              </ActionBar>
            </TableAnnotationContainer>
            <TableContainer>
              <LibraryResults
                data={results}
                sortDir={convertToSortDir(order)}
                sortKey={sortBy}
                onSort={onSort}
                loading={loading}
                updateData={setSearchState}
                refetchSearch={fetchSearch}
                onClear={resetLibrarySearchParams}
                findSimilar={findSimilar}
                disableSort={librarySearchParams[LibraryFilterSectionKey.FILTERS].includes(
                  QuickFilter.RECENTLY_ANALYZED,
                )}
                canClearQuery={name !== ''}
                canClearFilters={canResetFilters}
                trackSelectionMade={trackSelectionMade}
                showAssetType={librarySearchParams.assetTypes.length > 0}
                showMorningstarCategory={librarySearchParams.morningstarCategories.length > 0}
                metricFilters={librarySearchParams.metrics}
              />
            </TableContainer>
          </>
        </Main>
      </TableAndAnnotationContainer>
    </Content>
  );
};

export default LibraryLayout;

const ActionBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding-left: 10px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Main = styled.div`
  width: 100%;
  min-height: 840px; // so that all non-tag filters are seen
`;

const TableAndAnnotationContainer = styled.div<{ includePadding: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${(props) =>
    props.includePadding &&
    `
    padding: 0px;
    padding-top: 0px;
  `}
`;

const TableAnnotationContainer = styled(StickyNode)`
  display: flex;
  flex-direction: column;
  background: ${GetColor.White};
  z-index: ${ZIndex.StickyFront};
  border-bottom: 1px solid ${GetColor.Grey};
`;

const SearchAndFiltersContainer = styled.div`
  padding-top: 27px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;

  label {
    font-size: calc(1rem * 7 / 8);
  }
`;

const SimpleFiltersContainer = styled.div`
  margin-left: 49px;
  margin-right: 51px;
  display: flex;
  flex-direction: row;
  column-gap: 15px;
`;

const ManageFiltersContainer = styled.div`
  padding-left: 54px;
`;
