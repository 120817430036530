import React, { Component } from 'react';
import styled, { withTheme } from 'styled-components';
import type { Theme } from 'venn-ui-kit';
import { Icon, GetColor, EllipsisTooltipSpan, Tooltip, TooltipPosition, CellLoader } from 'venn-ui-kit';
import ProxyInfo from './ProxyInfo';
import type { CellButtonProps } from '../../cell-button/CellButton';
import CellButton from '../../cell-button/CellButton';
import { buttonize } from 'venn-utils';
import type { ProxySelectorProps, ProxyTriggerProps } from './ProxySelector';
import ProxySelector from './ProxySelector';
import type { FundProxy } from '../pickers/types';

interface ProxyCellProps extends Omit<ProxySelectorProps, 'children'> {
  theme: Theme;
}

class ProxyCell extends Component<ProxyCellProps> {
  renderDropdown(readOnly: boolean, selectorOpen: boolean, onToggleSelector: () => void) {
    const { hoverAddProxy, theme } = this.props;
    const { Colors } = theme;
    const showHoverProxy = hoverAddProxy && !selectorOpen;
    const buttonColors: CellButtonProps = hoverAddProxy
      ? {
          backgroundColor: Colors.Primary.Dark,
          hoverBackgroundColor: Colors.Primary.Main,
        }
      : {
          backgroundColor: Colors.DEPRECATED_DataBarColor.LightPaleGold,
          borderColor: Colors.DEPRECATED_DataBarColor.LightGold,
        };
    return (
      <>
        {showHoverProxy && <ProxyEmpty>--</ProxyEmpty>}
        {!readOnly && (
          <CellButton onClick={onToggleSelector} showOnHover={showHoverProxy} {...buttonColors}>
            <Icon type="arrow-from-right" />
            Add a proxy
          </CellButton>
        )}
      </>
    );
  }

  renderLabel(readOnly: boolean, selectedProxy: FundProxy, onToggleSelector: () => void) {
    const { name } = selectedProxy;
    const { investment, usePortal } = this.props;
    return (
      <EditLabel {...buttonize(onToggleSelector)} className="qa-edit-label">
        <Tooltip
          content={
            <ProxyInfo
              key={investment.id}
              investmentLastUpdated={investment.updated}
              investmentId={investment.id}
              proxy={selectedProxy}
              investmentFrequency={investment.frequency}
            />
          }
          position={TooltipPosition.Bottom}
          className="qa-tooltip"
          usePortal={usePortal}
        >
          <EllipsisTooltipSpan maxWidth={120} isHidden className="">
            {name}
          </EllipsisTooltipSpan>
        </Tooltip>
        {!readOnly && <EditIcon type="edit" />}
      </EditLabel>
    );
  }

  render() {
    return (
      <ProxySelector {...this.props}>
        {({ selectedProxy, selectorOpen, onToggleSelector, isProxyUpdating }: ProxyTriggerProps) => {
          return this.props.loading || isProxyUpdating ? (
            <CellLoader className="qa-cell-loader" />
          ) : selectedProxy?.name ? (
            this.renderLabel(!!this.props.readOnly, selectedProxy, onToggleSelector)
          ) : (
            this.renderDropdown(!!this.props.readOnly, selectorOpen, onToggleSelector)
          );
        }}
      </ProxySelector>
    );
  }
}

export default withTheme(ProxyCell);

const ProxyEmpty = styled.span`
  display: block;
  transition: all 0.3s ease-in-out;
  tr:hover & {
    display: none;
  }
`;

const EditIcon = styled(Icon)`
  cursor: pointer;
  color: ${GetColor.Primary.Dark};
  padding-left: 2px;
  float: right;
  line-height: inherit;
  &:hover {
    color: ${GetColor.Primary.Main};
  }
`;

const EditLabel = styled.div`
  cursor: pointer;
  display: inline;
  width: 100%;
`;
