import React, { useContext, useMemo } from 'react';
import { SidePanelSettings, UserContext } from 'venn-components';
import { compact } from 'lodash';
import OutlinePanel from './panels/OutlinePanel';
import PagesPanel from './panels/PagesPanel';
import DropBlocksPanel from './panels/DropBlocksPanel';
import { withSuspense } from 'venn-utils';
import InputsPanel from './panels/InputsPanel';
import { useRecoilValue } from 'recoil';
import { isReportState } from 'venn-state';

const StudioMainLeftPanel = React.memo(function StudioMainLeftPanel() {
  const { hasPermission } = useContext(UserContext);
  const isReport = useRecoilValue(isReportState);

  const studioInsertBlocksPermission = hasPermission('STUDIO_INSERT_BLOCKS');

  const sideBarItems = useMemo(
    () =>
      compact([
        {
          key: 'inputs',
          text: 'Inputs',
          Component: <InputsPanel />,
        },
        {
          key: 'outline',
          text: 'Outline',
          Component: isReport ? <PagesPanel /> : <OutlinePanel />,
        },
        studioInsertBlocksPermission && {
          key: 'insert',
          text: 'Insert',
          Component: <DropBlocksPanel />,
        },
      ]),
    [studioInsertBlocksPermission, isReport],
  );

  return <SidePanelSettings defaultKey="inputs" items={sideBarItems} />;
});

export default withSuspense(null, StudioMainLeftPanel);
