import React from 'react';
import styled from 'styled-components';
import type { DropMenuItem } from 'venn-ui-kit';
import { Tooltip, Label, DropMenu, Icon, GetColor } from 'venn-ui-kit';
import type { CustomBenchmarkTypeEnum } from 'venn-api';
import { benchmarkItems } from './benchmarkTypeUtils';

const BENCHMARK_TYPE_MESSAGE = 'Available analysis period will be aligned with benchmark(s)';

interface BenchmarkTypeDropdownProps {
  onlyValue?: boolean;
  customBenchmarkType?: CustomBenchmarkTypeEnum;
  onChangeBenchmarkType: (item: DropMenuItem<CustomBenchmarkTypeEnum>) => void;
}

const BenchmarkTypeDropdown = ({
  onlyValue,
  onChangeBenchmarkType,
  customBenchmarkType = 'NONE',
}: BenchmarkTypeDropdownProps) => {
  const selectedItem = benchmarkItems.find((item) => item.value === customBenchmarkType);

  return (
    <>
      <StyledLabel>
        Benchmark Type:
        <Tooltip content={<TooltipContent>{BENCHMARK_TYPE_MESSAGE}</TooltipContent>}>
          <InfoIcon type="info-circle" prefix="far" />
        </Tooltip>
      </StyledLabel>
      {onlyValue ? (
        <Text>
          {selectedItem?.icon} {selectedItem?.label}
        </Text>
      ) : (
        <DropMenu<CustomBenchmarkTypeEnum>
          items={benchmarkItems}
          onChange={onChangeBenchmarkType}
          selected={customBenchmarkType}
          placeholder="Select a benchmark type"
          width={180}
        />
      )}
    </>
  );
};

export default BenchmarkTypeDropdown;

const InfoIcon = styled(Icon)`
  margin-left: 3px;
  color: ${GetColor.HintGrey};
`;

const TooltipContent = styled.div`
  text-align: center;
`;

const Text = styled.div`
  font-size: 12px;
  color: ${GetColor.GreyScale.Grey70};
`;

const StyledLabel = styled(Label)`
  line-height: 1.2;
  margin-bottom: 8px;
  display: inline-block;
`;
