import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import { Icon, GetColor } from 'venn-ui-kit';

export interface HamburgerTriggerProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const HamburgerTriggerButton = styled.button`
  width: 24px;
  height: 24px;
`;

const HamburgerTrigger: FC<React.PropsWithChildren<HamburgerTriggerProps>> = ({ onClick, ...props }) => (
  <HamburgerTriggerButton onClick={onClick} {...props}>
    <StyledIcon type="hamburger" />
  </HamburgerTriggerButton>
);

export default HamburgerTrigger;

const StyledIcon = styled(Icon)`
  width: 18px;
  color: ${GetColor.Primary.Main};
`;
