import React, { PureComponent } from 'react';

import { getPaginationItems, getPaginationKey } from './utils/PaginationItemsGenerator';
import { PaginationItem } from './PaginationItem';
import type { PaginationProps } from './Pagination';

export class PaginationBody extends PureComponent<PaginationProps> {
  render() {
    const { pagesCount, selectedPage, onPageChange } = this.props;
    if (!pagesCount) {
      return null;
    }

    const paginationItems = getPaginationItems(pagesCount, selectedPage);

    return paginationItems.map((item, idx) => (
      <PaginationItem
        key={getPaginationKey(item, idx)}
        item={item}
        selected={item === selectedPage}
        // @ts-expect-error: TODO fix strictFunctionTypes
        onClick={onPageChange}
      />
    ));
  }
}
