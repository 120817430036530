import React, { Component } from 'react';
import type { BasicTableColumn } from '../../../../basictable/BasicTable';
import type { DropdownValue } from '../../components/Dropdown';
import type { ColumnMapping } from 'venn-api';
import { ErrorMessage, FooterButtons, Header } from '../../components/page-parts';
import { Header as MapDataTableHeader } from '../../components/map-data';
import { isColumnDeleted, updateMappingColumnState, updateNavColumnMappingsByGlobalChange } from './helpers';
import type { MappingNavsProps } from './types';
import { DO_NOT_MAP_ID } from '../../types';
import { SectionHeader, StyledBasicTable, StyledContent } from './styles';
import {
  createCellRenderer,
  DeleteCellRenderer,
  FundCellRenderer,
  LinkInformationRenderer,
  PerformanceCellRenderer,
} from '../../components/renderers';
import sum from 'lodash/sum';
import { withTheme } from 'styled-components';

enum SectionType {
  Existing,
  New,
  NotMapped,
}

interface Section {
  type: SectionType;
  title: string;
  columns: BasicTableColumn<ColumnMapping>[];
  filter: (mapping: ColumnMapping) => boolean;
}

const headerColumns = [
  {
    options: [{ label: 'NAVs', value: 'NAVs' }],
    readonly: true,
  },
];
const headerValues = ['NAVs'];

interface MappingNavsState {
  sections: Section[];
}

export class MappingNavs extends Component<MappingNavsProps, MappingNavsState> {
  state: MappingNavsState = {
    sections: [
      {
        type: SectionType.Existing,
        title: 'Existing portfolio investments',
        columns: [],
        filter: (c) => !!c.portfolioNodeId && !!c.fundId && c.typeId !== DO_NOT_MAP_ID,
      },
      {
        type: SectionType.New,
        title: 'New portfolio investments',
        columns: [],
        filter: (c) => !c.portfolioNodeId && !!c.fundId && c.typeId !== DO_NOT_MAP_ID,
      },
      {
        type: SectionType.NotMapped,
        title: 'Not Mapped',
        columns: [],
        filter: (c) => !c.fundId || c.typeId === DO_NOT_MAP_ID,
      },
    ],
  };

  constructor(props: MappingNavsProps) {
    super(props);
    this.computeColumns = this.computeColumns.bind(this);
  }

  computeColumns() {
    const {
      mapping: { columns },
    } = this.props;
    this.setState((prevState) => ({
      sections: prevState.sections.map((section) => ({
        ...section,
        columns: this.getTableColumns(columns.filter(section.filter)),
      })),
    }));
  }

  componentDidMount() {
    this.computeColumns();
  }

  componentDidUpdate(prevProps: MappingNavsProps) {
    const { mapping } = this.props;
    if (prevProps.mapping !== mapping) {
      this.computeColumns();
    }
  }

  getTableColumns(columns: ColumnMapping[]) {
    const {
      metadata,
      mode,
      mapping,
      portfolio,
      strategyId,
      theme: { Colors },
    } = this.props;
    const rendererArgs = {
      mapping,
      columns,
      metadata,
      mode,
      portfolio,
      strategyId,
      onChange: this.props.onMappingChange,
    };
    return [
      {
        label: 'Funds',
        cellStyle: { width: 225 },
        cellRenderer: createCellRenderer.call(null, {
          renderer: FundCellRenderer,
          ...rendererArgs,
        }),
      },
      {
        label: 'NAV ($)',
        cellStyle: { width: 100 },
        cellRenderer: createCellRenderer.call(null, {
          renderer: PerformanceCellRenderer,
          ...rendererArgs,
        }),
      },
      {
        label: '',
        cellRenderer: createCellRenderer.call(null, {
          renderer: LinkInformationRenderer,
          ...rendererArgs,
        }),
      },
      {
        cellStyle: { color: Colors.HintGrey, width: 30 },
        cellRenderer: createCellRenderer.call(null, {
          renderer: DeleteCellRenderer,
          ...rendererArgs,
        }),
      },
    ];
  }

  onContinue = () => {
    const { mapping, metadata, onContinue } = this.props;
    onContinue({
      ...mapping,
      columns: mapping.columns.filter((col) => !isColumnDeleted(col, metadata)),
    });
  };

  onHeaderDropdownChange = (columns: ColumnMapping[], values: DropdownValue[]) => {
    const { mapping } = this.props;
    const newMapping = updateMappingColumnState({ mapping, columns, values }, updateNavColumnMappingsByGlobalChange);
    this.props.onMappingChange(newMapping);
  };

  renderMappingSection(columns: ColumnMapping[], section: Section) {
    const { mode } = this.props;
    if (!columns.length) {
      return null;
    }

    return (
      <section key={section.type}>
        <SectionHeader>{section.title}</SectionHeader>
        <MapDataTableHeader
          mode={mode}
          columns={headerColumns}
          values={headerValues}
          onChange={(values) => this.onHeaderDropdownChange(columns, values)}
        />
        <StyledBasicTable data={columns} columns={section.columns} rowHeight={60} />
      </section>
    );
  }

  render() {
    const {
      error,
      mapping: { columns },
    } = this.props;
    const { sections } = this.state;

    const sectionsColumns = sections.map((section) => columns.filter(section.filter));
    // only mapped investments will be uploaded
    const sectionsToUpload = sections.filter((section) => section.type !== SectionType.NotMapped);
    const numColumnsToUpload = sum(sectionsToUpload.map((value, index) => sectionsColumns[index].length));

    return (
      <>
        <Header bottomShadow>
          <h1>Map Data</h1>
          <h2>Is your new and existing data mapped correctly?</h2>
          <h3>Use the controls below to ensure correct data identification and mapping.</h3>
          {!!error && <ErrorMessage>{error}</ErrorMessage>}
        </Header>
        <StyledContent>
          {sections.map((section, index) => this.renderMappingSection(sectionsColumns[index], section))}
        </StyledContent>
        <FooterButtons
          onCancel={this.props.onCancel}
          onStartOver={this.props.onStartOver}
          onContinue={this.onContinue}
          disabled={!numColumnsToUpload}
        />
      </>
    );
  }
}

export default withTheme(MappingNavs);
