import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';

const calculateWidth = (value: number, min: number, max: number, totalWidth: number) =>
  Math.round((Math.abs(value) / (Math.max(max, 0) - Math.min(min, 0))) * totalWidth);

export interface Marker {
  height?: number;
  value: number;
  color: string;
  style?: Record<string, unknown>;
}

export interface BarProps {
  /** Classname for bar container */
  className?: string;
  /** Classname for inside bars */
  barClassName?: string;
  barHeight?: number;
  barWidth?: number;
  barColor?: string;
  barBackground?: string;
  max?: number;
  min?: number;
  value?: number;
  markers?: Marker[];
  centerLine?: boolean;
}

const Marker = styled.span<Marker>`
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  border: 1px solid ${GetColor.White};

  ${(props) =>
    props &&
    `
    height: ${props.height}px;
    width: ${props.height}px;
    left: calc(50% + ${props.value}px);
    background: ${props.color};
  `};
`;

const BarWrapper = styled.div<BarProps>`
  ${(props) =>
    props.barHeight &&
    `
    height: ${props.barHeight}px;
  `};
  display: inline-flex;
  position: relative;
  ${(props) => `background-color: ${props.barBackground || 'transparent'}`};
`;

const BarNegative = styled.div<BarProps>`
  display: flex;
  justify-content: flex-end;
  width: ${(props) => props.barWidth}px;

  > div {
    background-color: ${(props) => props.barColor ?? GetColor.UNSAFE.Azure};
  }
`;

const BarPositive = styled.div<BarProps>`
  width: ${(props) => props.barWidth}px;

  > div {
    background-color: ${(props) => props.barColor ?? GetColor.UNSAFE.Azure};
  }
  margin-left: ${(props) => (props.centerLine ? '1px' : '0')};
`;

const BarContent = styled.div<BarProps>`
  width: ${(props) => props.barWidth}px;
  height: 100%;
`;

const CenterLine = styled.span<{ barWidth: number }>`
  position: absolute;
  left: ${({ barWidth }) => Math.floor(barWidth / 2 + 1)}px;
  width: 1px;
  height: 100%;
  background-color: ${GetColor.Grey};
`;

export const Bar: FC<React.PropsWithChildren<BarProps>> = ({
  barClassName,
  className,
  barHeight = 7,
  barWidth: barWidthProp = 300,
  barColor,
  barBackground,
  value,
  max,
  min,
  markers,
  centerLine,
}) => {
  const barWidth = centerLine ? barWidthProp + 1 : barWidthProp;
  const barContentWidth = calculateWidth(value!, min!, max!, barWidth);
  return (
    <BarWrapper className={className} barHeight={barHeight} barBackground={barBackground}>
      <BarNegative
        barColor={barColor}
        barHeight={barHeight}
        barWidth={calculateWidth(Math.min(min!, 0), min!, max!, barWidth)}
      >
        <BarContent className={barClassName} barWidth={value! < 0 ? barContentWidth : 0} barHeight={barHeight} />
      </BarNegative>
      {centerLine && <CenterLine data-testid="qa-centerline" barWidth={barWidth} />}
      <BarPositive
        centerLine={centerLine}
        barColor={barColor}
        barHeight={barHeight}
        barWidth={calculateWidth(Math.max(max!, 0), min!, max!, barWidth)}
      >
        <BarContent className={barClassName} barWidth={value! > 0 ? barContentWidth : 0} barHeight={barHeight} />
      </BarPositive>
      {markers
        ?.filter((marker) => marker)
        .map((marker) => (
          <Marker
            data-testid="qa-marker"
            key={marker.value}
            height={marker.height ?? barHeight - 4 /* fit inside bar */}
            value={marker.value}
            color={marker.color}
            style={marker.style}
          />
        ))}
    </BarWrapper>
  );
};

Bar.defaultProps = {
  max: 100,
  min: -100,
  value: 0,
};

export default Bar;
