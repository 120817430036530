import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { Label } from '../../typography';
import { GetColor } from '../../../style/color';
import { Icon } from '../../icon/Icon';
import type { TriggerComponentProps } from '../types';
import TriggerContainer from './TriggerContainer';

interface FilterTriggerComponentProps extends TriggerComponentProps {
  all?: boolean;
  square?: boolean;
  width?: number;
  onClear: () => void;
}

const getIcon = (all: boolean | undefined, expanded: boolean | undefined, onClear: () => void) => {
  if (!all) {
    return (
      <ClearIcon
        type="times-circle"
        onClick={(event) => {
          event?.stopPropagation();
          onClear();
        }}
      />
    );
  }
  return expanded ? <Icon type="angle-up" /> : <Icon type="angle-down" />;
};

const FilterTrigger: React.FC<React.PropsWithChildren<FilterTriggerComponentProps>> = ({
  label,
  selection,
  expanded,
  disabled,
  className,
  all,
  innerRef,
  square,
  width,
  onClick,
  onClear,
}) => {
  const handleClick = useCallback(() => {
    if (!disabled) {
      onClick?.();
    }
  }, [disabled, onClick]);

  return (
    <TriggerContainer
      className={className}
      onClick={handleClick}
      disabled={disabled}
      expanded={expanded}
      innerRef={innerRef}
      width={width}
    >
      {label && (
        <LabelContainer>
          <Label>{label}</Label>
        </LabelContainer>
      )}
      <Input disabled={disabled} all={all} square={square}>
        <Selection>
          <Label>{selection || (all ? 'All' : 'None')}</Label>
        </Selection>
        {!disabled && getIcon(all || !selection, expanded, onClear)}
      </Input>
    </TriggerContainer>
  );
};

interface StateProps {
  disabled?: boolean;
  all?: boolean;
  square?: boolean;
}

const LabelContainer = styled.div`
  margin-bottom: 5px;
  margin-left: 10px;
`;

const Selection = styled.div`
  flex-grow: 1;
  min-width: 0;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
`;

const Input = styled.div<StateProps>`
  display: flex;
  align-items: center;
  height: 30px;
  font-size: 14px;
  ${(props) => css`
    border-radius: ${props.square ? '4px' : '20px'};
  `};
  padding: 6px 10px;
  ${(props) =>
    props.all &&
    css`
      border: solid 1px ${GetColor.PaleGrey};
    `}
  box-shadow: 0 1px 1px 0 ${GetColor.Grey};
  background-color: ${(props) => (props.disabled ? GetColor.Grey : props.all ? GetColor.White : GetColor.Primary.Main)};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  ${(props) =>
    !props.disabled &&
    css<StateProps>`
      &:hover,
      &:focus {
        cursor: pointer;
        label {
          color: ${(props) => (props.all ? GetColor.Black : GetColor.White)};
        }
        background-color: ${(props) => (props.all ? GetColor.PaleGrey : GetColor.Primary.Dark)};
      }
    `}
  label {
    display: inline-block;
    width: 100%;
    font-size: 14px;
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ClearIcon = styled(Icon)`
  &:hover {
    color: ${GetColor.White};
  }
`;

export default FilterTrigger;
