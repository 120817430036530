import type { Theme } from 'venn-ui-kit';
import type { AnalysisSubjectSecondaryLabel } from 'venn-utils';
import { getAnalysisLabels } from 'venn-utils';
import compact from 'lodash/compact';
import { isNil } from 'lodash';

interface Label {
  name: string;
  value: string;
}

export function getLabels(
  mainDataSetName: string | undefined,
  baselineDataSetName: string | undefined,
  comparisonDataSetName: string | undefined,
  categoryDataSetName: string | undefined,
  categoryActive: boolean,
  relative?: boolean,
  isFund?: boolean,
  benchmarkName?: string,
  secondaryLabel?: AnalysisSubjectSecondaryLabel,
  secondaryPortfolioUpdated?: number,
) {
  let labels: (Label | undefined)[] = [];
  const investmentLabels = getAnalysisLabels('investment');
  const portfolioLabels = getAnalysisLabels('portfolio', secondaryLabel, secondaryPortfolioUpdated);
  const mainName = isFund ? investmentLabels.main : portfolioLabels.main;
  const relativeBenchmarkName = relative ? benchmarkName : undefined;
  labels = [
    mainDataSetName
      ? {
          name: mainName,
          value: mainDataSetName,
        }
      : undefined,
    !isFund && portfolioLabels.comparison && baselineDataSetName
      ? {
          name: portfolioLabels.comparison,
          value: baselineDataSetName,
        }
      : undefined,
    comparisonDataSetName || relativeBenchmarkName
      ? {
          name: portfolioLabels.benchmark,
          value: (comparisonDataSetName || relativeBenchmarkName)!,
        }
      : undefined,
    categoryActive && isFund && investmentLabels.category && categoryDataSetName
      ? {
          name: investmentLabels.category,
          value: categoryDataSetName,
        }
      : undefined,
  ];
  return compact(labels);
}

export const getBarColor = (itemValue: number, theme: Theme): string => {
  const {
    Colors,
    Schemes: { FactorColors },
  } = theme;

  if (itemValue === 0) {
    return Colors.White;
  }

  const barColor = itemValue > 0 ? FactorColors.mainColor : FactorColors.comparisonColor;
  return barColor;
};

export const getOpacity = (itemSignificant: boolean): number => (itemSignificant ? 1 : 0.2);

export const getTestOpacity = (itemSignificant: boolean): number => (itemSignificant ? 1 : 0.4);

export const formatNumber = (value: number): string => (value ? value.toFixed(2) : '0');

const formatPercentage = (value: number): string => `${(value * 100).toFixed(2)}%`; // TODO proper formatter

export const formatValue = (value: number | undefined, percentage?: boolean): string => {
  if (isNil(value)) {
    return '--';
  }
  const formattedValue = percentage ? formatPercentage(value) : formatNumber(value);
  return formattedValue;
};
