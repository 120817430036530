import { IS_JEST_TEST } from '../environment';

const CHROME_MIN_VERSION = 100;

export const isChrome = browserTest();

/** Tests if the browser the user is using is a primary supported browser. */
export function browserTest() {
  if (IS_JEST_TEST) {
    return false;
  }
  if (!window) {
    return false;
  }
  const isDesktopChrome =
    /chrome/i.test(window.navigator.userAgent) &&
    !/mobile/i.test(window.navigator.userAgent) &&
    !/android/i.test(window.navigator.userAgent) &&
    window.navigator.vendor === 'Google Inc.';

  if (isDesktopChrome) {
    const versionMatch = /chrome\/([0-9]+)/i.exec(window.navigator.userAgent);
    return versionMatch && +versionMatch[1] >= CHROME_MIN_VERSION;
  }

  return false;
}
