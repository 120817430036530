import React from 'react';
import { EmptyStateContent, ListItemWithReturns } from '../components/styled';
import type { ComparisonSubject } from 'venn-utils';
import { hasAnalysisSubjectNoReturns, subjectIdWithBenchmark } from 'venn-utils';
import ExpandableList from '../components/ExpandableList';
import InlineActionPillButton from '../components/InlineActionPillButton';
import styled from 'styled-components';

interface ComparisonErrorNoReturnsProps {
  /**
   * List of subjects used in the Comparison Page
   */
  comparisonSubjects: ComparisonSubject[];
  /**
   * Callback to remove items from the comparison page by specifying array index in {@link comparisonSubjects}
   */
  removeComparisonSubject?: (idx: number) => void;
}

/**
 * Should be rendered only on errors with code {@link SupportedErrorCodes.NoFundReturns}.
 * Displays all comparison elements with no returns (funds + portfolios).
 */
const ComparisonErrorNoReturns: React.FC<React.PropsWithChildren<ComparisonErrorNoReturnsProps>> = ({
  comparisonSubjects,
  removeComparisonSubject,
}) => {
  const subjectsWithNoReturns = comparisonSubjects.filter((subject) =>
    subject.analysisSubject ? hasAnalysisSubjectNoReturns(subject.analysisSubject) : false,
  );
  return (
    <EmptyStateContent>
      One or more of the items you are analyzing do not have a return stream.
      <ExpandableList initiallyShown={3}>
        {subjectsWithNoReturns.map((subject: ComparisonSubject, idx) => (
          <ListItemWithReturns
            // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
            name={subject.analysisSubject?.name!}
            suffix=". "
            key={subjectIdWithBenchmark(subject, idx)}
          >
            {removeComparisonSubject && (
              <>
                Suggested actions:
                <FlexBox>
                  <InlineActionPillButton onClick={() => removeComparisonSubject(idx)}>
                    Remove from comparison
                  </InlineActionPillButton>
                </FlexBox>
              </>
            )}
          </ListItemWithReturns>
        ))}
      </ExpandableList>
    </EmptyStateContent>
  );
};

export default ComparisonErrorNoReturns;

const FlexBox = styled.div`
  display: flex;
  align-items: center;
`;
