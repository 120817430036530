import type { FunctionComponent, ReactNode } from 'react';
import React from 'react';
import styled, { css } from 'styled-components';
import { noop } from 'lodash';
import type { IconProps } from 'venn-ui-kit';
import { Icon, IconPosition, GetColor, TooltipPosition, Tooltip } from 'venn-ui-kit';

export interface StyledIconProps {
  /** Position of the icon - defaults to left */
  iconPosition?: IconPosition;
}

export interface ToolbarButtonProps extends StyledIconProps {
  /** Inside text of the button, beside the icon */
  children: ReactNode;
  /** You can pass aditional styling directly to the button */
  className?: string;
  /** Should the button be filled with color (background and border) */
  filled?: boolean;
  /** @see [Icon](#icon) type. */
  icon?: string;
  /** Should the button be disabled */
  disabled?: boolean;
  /** You can pass an optional tooltip parameter to be shown on hover */
  tooltipMessage?: string;
  //* * Tooltip position */
  tooltipPosition?: TooltipPosition;
  /** Function to call on click */
  onClick?: () => void;
}

const StyledIcon = styled(Icon)<StyledIconProps & IconProps>`
  margin-right: ${(props: StyledIconProps) => (props.iconPosition === IconPosition.Left ? '10px' : '0')};
  margin-left: ${(props: StyledIconProps) => (props.iconPosition === IconPosition.Right ? '10px' : '0')};
`;

const filledCSS = css`
  border-color: ${GetColor.Primary.Dark};
  background-color: ${GetColor.Primary.Dark};
  color: ${GetColor.White};

  &:hover {
    border-color: ${GetColor.Primary.Main};
    background-color: ${GetColor.Primary.Main};
    color: ${GetColor.White};
  }

  &:disabled {
    border-color: ${GetColor.HintGrey};
    background-color: ${GetColor.HintGrey};
    cursor: not-allowed;
  }
`;

const StyledToolbarButton = styled.button<ToolbarButtonProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 88px;
  min-height: 36px;
  margin-right: 20px;
  padding: 10px 15px;
  border: 1px solid ${GetColor.HintGrey};
  border-radius: 2px;
  background: ${GetColor.White};
  color: ${GetColor.Primary.Dark};
  font-size: 1.1rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  cursor: pointer;
  flex-direction: ${(props) => (props.iconPosition === IconPosition.Right ? 'row-reverse' : 'row')};

  ${(props) => (props.filled ? filledCSS : '')};

  &:disabled,
  &:hover:disabled {
    cursor: not-allowed;
  }

  &:hover {
    color: $link-blue;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const renderToolbarButton = (props: ToolbarButtonProps) => {
  const {
    children = null,
    className,
    filled = false,
    icon = null,
    disabled = false,
    iconPosition = IconPosition.Left,
    onClick = noop,
    ...restProps
  } = props;
  return (
    <StyledToolbarButton
      onClick={onClick}
      filled={filled}
      iconPosition={iconPosition}
      className={className}
      disabled={disabled}
      {...restProps}
    >
      {icon && <StyledIcon type={icon} iconPosition={iconPosition} />}
      <div>{children}</div>
    </StyledToolbarButton>
  );
};

/**
 * General purpose toolbar button.
 *
 * Any props not listed, will be passed directly to button.
 */
export const ToolbarButton: FunctionComponent<React.PropsWithChildren<ToolbarButtonProps>> = (props) => {
  const { tooltipMessage = null, tooltipPosition = TooltipPosition.Bottom } = props;

  return (
    <>
      {tooltipMessage && (
        <Tooltip content={tooltipMessage} position={tooltipPosition}>
          {renderToolbarButton(props)}
        </Tooltip>
      )}
      {!tooltipMessage && renderToolbarButton(props)}
    </>
  );
};

export default ToolbarButton;
