import React from 'react';
import type { ClientIntegration, AddeparSyncErrorTypeEnum } from 'venn-api';
import styled, { css } from 'styled-components';
import { GetColor, Hint, Icon, getAppTitle } from 'venn-ui-kit';
import { Dates, analyticsService } from 'venn-utils';
import { StyledTable } from '../../shared';

interface ErrorSyncMessageProps {
  lastPortfolioReturnSyncError?: AddeparSyncErrorTypeEnum;
  lastPortfolioStructureSyncError?: AddeparSyncErrorTypeEnum;
  onEdit: () => void;
  onOpenPanel: () => void;
}

const ErrorSyncMessage = ({
  lastPortfolioReturnSyncError,
  lastPortfolioStructureSyncError,
  onOpenPanel,
  onEdit,
}: ErrorSyncMessageProps) => {
  if (!lastPortfolioReturnSyncError && !lastPortfolioStructureSyncError) {
    return null;
  }
  if (lastPortfolioReturnSyncError === 'AUTH' || lastPortfolioStructureSyncError === 'AUTH') {
    return (
      <>
        <Error>Sync error: credentials expired.</Error>
        <LinkButton onClick={onOpenPanel} as="button">
          Reauthorize with Addepar <Icon type="external-link" />
        </LinkButton>
      </>
    );
  }

  if (
    lastPortfolioReturnSyncError === 'INVALID_GROUPING_ATTR' ||
    lastPortfolioStructureSyncError === 'INVALID_GROUPING_ATTR'
  ) {
    return (
      <>
        <Error>Sync error: invalid groupings.</Error>
        <LinkButton onClick={onEdit} as="button">
          Update views configuration
        </LinkButton>
      </>
    );
  }

  if (
    lastPortfolioReturnSyncError === 'VENN_RETURN_COLUMN_MISSING' ||
    lastPortfolioStructureSyncError === 'VENN_RETURN_COLUMN_MISSING'
  ) {
    return (
      <>
        <Error>Sync error: missing "{getAppTitle()} Return" column.</Error>
        <LinkButton onClick={onEdit} as="button">
          Update views configuration
        </LinkButton>
      </>
    );
  }
  return <Error>There are errors in the last sync. We are working to fix it.</Error>;
};

interface AddeparInstancesTableProps {
  clientIntegrations: ClientIntegration[];
  onDeleteClient: (integration: ClientIntegration) => void;
  onEditClient: (integration: ClientIntegration) => void;
  onOpenPanel: () => void;
}

const Columns = (
  onEdit: (integration: ClientIntegration) => void,
  onDelete: (integration: ClientIntegration) => void,
  onOpenPanel: () => void,
) => [
  {
    accessor: 'name',
    cellRenderer: (data: ClientIntegration) => (
      <NameWrapper>
        <div>
          <PaddedCell>
            {(data.lastPortfolioReturnSyncError || data.lastPortfolioStructureSyncError) && (
              <ErrorIcon type="exclamation-triangle" />
            )}
            <Label hasError={!!data.lastPortfolioReturnSyncError || !!data.lastPortfolioStructureSyncError}>
              {data.clientName}
            </Label>
            <Hint>Added on: {Dates.toDayMonthYear(data.created)}</Hint>
          </PaddedCell>
        </div>
        <ErrorSyncMessage
          lastPortfolioReturnSyncError={data.lastPortfolioReturnSyncError}
          lastPortfolioStructureSyncError={data.lastPortfolioStructureSyncError}
          onEdit={() => {
            onEdit(data);
            analyticsService.ctaClicked({
              purpose: 'Edit Addepar Integration',
              text: 'Edit',
              locationOnPage: 'Addepar Error Sync Message',
            });
          }}
          onOpenPanel={() => {
            onOpenPanel();
            analyticsService.ctaClicked({
              purpose: 'Reauthorize with Addepar',
              text: 'Reauthorize with Addepar',
              locationOnPage: 'Addepar Error Sync Message',
            });
          }}
        />
      </NameWrapper>
    ),
  },
  {
    accessor: 'edit',
    cellRenderer: (data: ClientIntegration) => (
      <EditIcon
        type="pencil"
        onClick={() => {
          onEdit(data);
          analyticsService.ctaClicked({
            purpose: 'Edit Addepar Integration',
            text: 'Edit',
            locationOnPage: 'Addepar Integration List',
          });
        }}
      />
    ),
  },
  {
    accessor: 'delete',
    cellRenderer: (data: ClientIntegration) => (
      <TrashIcon
        type="trash"
        onClick={() => {
          onDelete(data);
          analyticsService.ctaClicked({
            purpose: 'Delete Addepar Integration',
            text: 'Delete',
            locationOnPage: 'Addepar Integration List',
          });
        }}
      />
    ),
  },
];

const AddeparInstancesTable = ({
  clientIntegrations,
  onDeleteClient,
  onEditClient,
  onOpenPanel,
}: AddeparInstancesTableProps) => {
  if (clientIntegrations.length === 0) {
    return null;
  }
  return (
    <StyledTable
      columns={Columns(onEditClient, onDeleteClient, onOpenPanel)}
      data={clientIntegrations}
      className="qa-addepar-list-table"
    />
  );
};

export default AddeparInstancesTable;

const NameWrapper = styled.div`
  line-height: 20px;
`;

const PaddedCell = styled.span`
  padding: 6px 0;
`;

const Label = styled.label<{ hasError: boolean }>`
  font-weight: bold;
  font-size: 14px;
  padding-right: 5px;
  ${(props) =>
    props.hasError &&
    css`
      color: ${GetColor.Error};
    `}
`;

const EditIcon = styled(Icon)`
  color: ${GetColor.Primary.Dark};
  cursor: pointer;
  :hover {
    color: ${GetColor.Primary.Main};
  }
`;

const TrashIcon = styled(Icon)`
  color: ${GetColor.Grey};
  cursor: pointer;
  :hover {
    color: ${GetColor.LightGrey};
  }
`;

const Error = styled.span`
  color: ${GetColor.Error};
`;

const ErrorIcon = styled(Icon)`
  color: ${GetColor.Error};
  padding-right: 3px;
`;

const LinkButton = styled.button`
  color: ${GetColor.Primary.Dark};
  padding-left: 5px;
  i {
    margin-left: 5px;
  }
`;
