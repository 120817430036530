import { useCommonTreeGridProps } from '../../../studio-blocks/logic/columnUtils';
import DataGrid from '../../../data-grid/DataGrid';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { ColorUtils, GetColor } from 'venn-ui-kit';
import type { UploadPortfolioAllocatorProps } from '../types';
import { useUploadPortfolioAllocator } from './useUniversalUploaderAllocator';
import type { Fund } from 'venn-api';
import { remapInvestment } from './review/helpers';

export const UploadPortfolioAllocator = ({
  selectedIndex,
  updatePortfolio,
  portfolio,
  accessMode,
}: UploadPortfolioAllocatorProps) => {
  const applyInvestmentRemap = useCallback(
    (path: number[], fund: Fund) => {
      const newPortfolio = remapInvestment(portfolio, path, fund);
      updatePortfolio(selectedIndex, newPortfolio);
    },
    [updatePortfolio, selectedIndex, portfolio],
  );

  const { rowData, columnDefs, autoGroupColumnDefs, rowClassRules } = useUploadPortfolioAllocator(
    portfolio,
    accessMode,
    applyInvestmentRemap,
  );
  const commonGridProps = useCommonTreeGridProps();

  return (
    <PortfolioAllocatorDataGridWrapper>
      <DataGrid
        {...commonGridProps}
        rowData={rowData}
        columnDefs={columnDefs}
        domLayout="normal"
        autoGroupColumnDef={autoGroupColumnDefs}
        rowClassRules={rowClassRules}
        suppressCellFocus
        suppressRowTransform
        suppressScrollOnNewData
        suppressRowHoverHighlight
      />
    </PortfolioAllocatorDataGridWrapper>
  );
};

const PortfolioAllocatorDataGridWrapper = styled.div`
  // ag-grid does not appear unless the parent has a height set
  // perhaps this is a problem with our DataGrid wrapper
  height: 100%;

  // styling for portfolio review
  .ag-row-odd,
  .ag-row-even {
    background-color: ${GetColor.GreyScale.Grey20};
  }
  .ag-header-container {
    background-color: ${GetColor.White};
  }

  .no-mapped-fund-row {
    background-color: ${ColorUtils.opacifyFrom(GetColor.Error, 0.1)};
  }

  .ag-cell {
    &[col-id='mapped'] {
      display: flex;
      flex-direction: row;
      justify-content: left;
    }
  }
  .ag-cell-wrapper.ag-row-group {
    align-items: center;
  }

  .ag-group-expanded,
  .ag-group-contracted {
    margin-right: 4px;
  }

  .ag-header-cell {
    &[col-id='allocation'] {
      padding-right: 14px;
      text-align: right;
    }
  }
`;
