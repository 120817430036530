import type { ShellLayoutStyle } from '../components/shell-layout/ShellLayoutContainer';
import { FactorLensHomepage, FactorLensBasics, FactorLensInsights, FactorLensDeepDive } from 'factor-lens-page';
import { RecentAnalysisPage, AnalysisDetailsPage, CreatePortfolioPage, CreateTemplatePage } from 'analysis-page';
import { Library, HomePage } from 'home-page';
import { AccountPage } from 'account-page';
import type { FeatureName, AnyDuringEslintMigration } from 'venn-utils';
import { Routes } from 'venn-utils';
import { ComparisonPage } from 'comparison-page';
import { ManageDataPage } from 'manage-data-page';
import { Reporting } from 'reporting-page';
import { PortfolioLabPage, RecentPortfolioLabPage } from 'portfolio-lab-page';
import { StudioPage } from 'studio-page';
import { ArchivePage } from 'archive-page';
import { ServiceTiersPage } from 'service-tiers-page';
import type { ActionEnum } from 'venn-api';
import { UpgradesStatusPage } from '../../../upgrades-status-page';

export interface ModuleConfig {
  module:
    | React.FC<React.PropsWithChildren<unknown>>
    | React.ClassType<unknown, AnyDuringEslintMigration, AnyDuringEslintMigration>
    | React.ComponentClass<unknown>;
  path: string;
  exact?: boolean;
  disabled?: boolean;
  requiredFeatures?: FeatureName[];
  layoutOptions: {
    layout: ShellLayoutStyle;
    noMinWidth?: boolean;
    /** If undefined, uses a standardized min width value. */
    minWidth?: number;
    showNavBar?: boolean;
  };
}

const moduleConfigInitializer = (hasPermission: (permission: ActionEnum) => boolean): ModuleConfig[] => {
  return [
    {
      path: Routes.UPGRADES_STATUS_PAGE,
      module: UpgradesStatusPage,
      exact: true,
      layoutOptions: { layout: 'NO_FOOTER', showNavBar: false },
    },
    {
      module: AccountPage,
      path: `${Routes.ACCOUNT_PATH}/:section?`,
      exact: false,
      layoutOptions: { layout: 'STANDARD' },
    },
    {
      path: Routes.MANAGE_DATA,
      module: ManageDataPage,
      layoutOptions: { layout: 'STANDARD' },
    },
    {
      path: Routes.FACTOR_LENS_HOMEPAGE,
      module: FactorLensHomepage,
      exact: true,
      layoutOptions: { layout: 'STANDARD' },
    },
    {
      path: Routes.FACTOR_LENS_BASICS,
      module: FactorLensBasics,
      layoutOptions: { layout: 'STANDARD' },
    },
    {
      path: Routes.FACTOR_LENS_INSIGHTS,
      module: FactorLensInsights,
      layoutOptions: { layout: 'STANDARD' },
    },
    {
      path: `${Routes.FACTOR_LENS_DEEP_DIVE}/:factor?`,
      module: FactorLensDeepDive,
      layoutOptions: { layout: 'STANDARD' },
    },
    {
      path: Routes.HOME_PATH,
      module: HomePage,
      exact: true,
      layoutOptions: { layout: 'FIXED_FULL_PAGE_CONTENT' },
    },
    {
      path: Routes.COOKIE_MANAGER,
      module: HomePage,
      exact: true,
      layoutOptions: { layout: 'FIXED_FULL_PAGE_CONTENT' },
    },
    {
      path: Routes.ANALYSIS_PATH,
      module: RecentAnalysisPage,
      exact: true,
      disabled: !hasPermission('ANALYSIS_PAGE'),
      layoutOptions: { layout: 'NO_FOOTER' },
    },
    {
      path: Routes.ANALYSIS_RESULTS_PATH,
      module: AnalysisDetailsPage,
      disabled: !hasPermission('ANALYSIS_PAGE'),
      layoutOptions: { layout: 'NO_FOOTER' },
    },
    {
      path: Routes.ANALYSIS_COMPARE_PATH,
      module: ComparisonPage,
      disabled: !hasPermission('COMPARE'),
      layoutOptions: { layout: 'NO_FOOTER' },
    },
    {
      path: Routes.CREATE_PORTFOLIO,
      module: CreatePortfolioPage,
      disabled: !hasPermission('CREATE_PORTFOLIO'),
      layoutOptions: { layout: 'NO_FOOTER' },
    },
    {
      path: Routes.CREATE_TEMPLATE,
      module: CreateTemplatePage,
      disabled: !hasPermission('ANALYSIS_PAGE_CREATE_TEMPLATE'),
      layoutOptions: { layout: 'NO_FOOTER' },
    },
    {
      path: Routes.EDIT_TEMPLATE_BY_ID,
      module: CreateTemplatePage,
      disabled: !hasPermission('ANALYSIS_PAGE_CREATE_TEMPLATE'),
      layoutOptions: { layout: 'NO_FOOTER' },
    },
    {
      path: Routes.LIBRARIES_PATH,
      module: Library,
      layoutOptions: { layout: 'STANDARD', noMinWidth: true },
    },
    {
      path: Routes.REPORTS,
      module: Reporting,
      layoutOptions: { layout: 'STANDARD' },
    },
    {
      path: Routes.PORTFOLIO_LAB_RESULTS_PATH,
      module: PortfolioLabPage,
      disabled: !hasPermission('OPTIMIZATION'),
      layoutOptions: { layout: 'STANDARD', minWidth: 1654 },
    },
    {
      path: Routes.PORTFOLIO_LAB_PATH,
      module: RecentPortfolioLabPage,
      exact: true,
      disabled: !hasPermission('OPTIMIZATION'),
      layoutOptions: { layout: 'STANDARD' },
    },
    {
      path: Routes.STUDIO_PATH,
      module: StudioPage,
      requiredFeatures: ['studio_ff'],
      layoutOptions: { layout: 'NO_FOOTER' },
    },
    {
      path: Routes.REPORT_LAB_PATH,
      module: StudioPage,

      requiredFeatures: ['studio_report_editor'],
      layoutOptions: { layout: 'NO_FOOTER' },
    },
    {
      path: Routes.ARCHIVE_PAGE_PATH,
      module: ArchivePage,
      requiredFeatures: ['studio_report_editor'],
      layoutOptions: { layout: 'STANDARD', minWidth: 1450 },
    },
    {
      path: Routes.SERVICE_TIERS_PATH,
      module: ServiceTiersPage,
      layoutOptions: { layout: 'STANDARD' },
    },
  ];
};

export default moduleConfigInitializer;
