import type { ComponentProps } from 'react';
import React from 'react';
import type { CellRenderer } from './types';
import { isCellDisabled } from './helpers';
import styled from 'styled-components';
import { Observations } from '../review-data';
import type { ColumnMapping, FileUploadMetadata } from 'venn-api';
import type { ErrorViewModel } from '../../views/review/helpers';
import type { VennColors } from 'venn-ui-kit';

interface ObservationsRendererProps {
  metadata: FileUploadMetadata;
  columnMapping: ColumnMapping;
  errors: ErrorViewModel[];
  colors: VennColors;
}

// @ts-expect-error: TODO fix strictFunctionTypes
export const ObservationsRenderer: CellRenderer = ({
  metadata,
  columnMapping,
  errors,
  colors,
}: ObservationsRendererProps) => {
  const { cumulativeReturn, startOfNewData, newDataCount, seriesId, fundId } = columnMapping;
  const isDisabled = isCellDisabled(columnMapping, metadata, errors, seriesId);
  const delimiter =
    startOfNewData === null ? cumulativeReturn.length : cumulativeReturn.findIndex(([time]) => time >= startOfNewData);
  const dataPoints = cumulativeReturn.map(([x, y]) => ({ x, y }));

  return (
    <StyledObservations
      newDataColor={fundId ? colors.Black : undefined}
      isDisabled={isDisabled}
      data={dataPoints.slice(delimiter)}
      previousData={delimiter > 0 ? dataPoints.slice(0, delimiter + 1) : undefined}
      newDataCount={newDataCount}
    />
  );
};

const StyledObservations = styled(Observations)<ComponentProps<typeof Observations> & { isDisabled?: boolean }>`
  ${({ isDisabled }) =>
    isDisabled &&
    `
    opacity: 0.25;
  `}
`;
