import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import type { AuthPageProps } from './components';
import {
  AuthButton,
  AuthButtonGroup,
  AuthFormErrors,
  AuthenticationFooter,
  AuthLoader,
  AuthLogo,
  AuthMessage,
  AuthValidators,
  ContentWrapper,
} from './components';
import { Routes } from 'venn-utils';
import type { AuthenticationStatus, ActivationCodeCheck } from 'venn-api';
import type { FormValues } from 'venn-components';
import { FormInput, createField, useForm } from 'venn-components';

const { required, isPasswordValid, matchesPasswordValue, PASSWORD_RULES_TEXT } = AuthValidators;

export const ResetPasswordRoute = '/reset-password';

interface ResetPasswordProps extends AuthPageProps {
  onActivationCodeCheck(user: ActivationCodeCheck): Promise<AuthenticationStatus>;
}

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledForm = styled.form`
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 330px;
`;

const StyledMessage = styled(AuthMessage)`
  font-size: 14px;
`;

function getEmailAndActivationCodeFromURL(locationSearchString: string) {
  const { email, code } = queryString.parse(locationSearchString);
  return {
    email: Array.isArray(email) ? email[0] : email,
    activationCode: Array.isArray(code) ? code[0] : code,
  };
}

const ResetPassword = ({ onSubmit, onActivationCodeCheck, error, pending, history }: ResetPasswordProps) => {
  const historyRef = useRef(history);
  const [redirect, setRedirect] = useState(false);
  const [activationCodeChecked, setActivationCodeChecked] = useState(false);
  const { email, activationCode } = getEmailAndActivationCodeFromURL(window.location.search.slice(1));

  const handleSubmit = async (values: FormValues) => {
    if (!email || !activationCode) {
      return;
    }

    await onSubmit({
      email,
      password: values.password,
      activationCode,
    });

    setRedirect(true);
  };

  const [fields, isValid, isSubmitting, submit] = useForm(
    [
      createField('password', '', [required('New password'), isPasswordValid]),
      createField('confirmPassword', '', [required('Password confirmation'), matchesPasswordValue]),
    ],
    handleSubmit,
  );

  useEffect(() => {
    if (email && activationCode && !activationCodeChecked) {
      onActivationCodeCheck({
        email,
        activationCode,
      });
      return () => setActivationCodeChecked(true);
    }
    return undefined;
  }, [email, activationCode, activationCodeChecked, onActivationCodeCheck]);

  useEffect(() => {
    if (redirect) {
      historyRef.current.push(Routes.SIGN_IN_PATH);
    }
  }, [redirect]);

  const disabled = !isValid || isSubmitting;

  return (
    <>
      <ContentWrapper isLoading={!!pending}>
        {pending && <AuthLoader title="Loading" />}
        {!pending && (
          <FormWrapper>
            <AuthLogo />
            <StyledForm onSubmit={submit}>
              <StyledMessage>Please enter a new password ({PASSWORD_RULES_TEXT})</StyledMessage>
              <FormInput type="password" inputId="password" label="New password" {...fields.password} />
              <FormInput
                type="password"
                inputId="confirmPassword"
                label="Confirm new password"
                {...fields.confirmPassword}
              />
              <AuthFormErrors error={error?.message} />
              <AuthButtonGroup>
                <Link to={Routes.SIGN_IN_PATH}>
                  <AuthButton type="button">Cancel</AuthButton>
                </Link>
                <AuthButton dominant disabled={disabled} type="submit">
                  Submit
                </AuthButton>
              </AuthButtonGroup>
            </StyledForm>
          </FormWrapper>
        )}
      </ContentWrapper>
      <AuthenticationFooter />
    </>
  );
};

export default ResetPassword;
