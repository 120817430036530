import { GetColor } from '../../style/color';
import styled, { ThemeContext } from 'styled-components';
import React, { useContext } from 'react';

const Badge = styled.span<{ color: string; noMargin: boolean }>`
  font-size: 11px;
  font-weight: bold;
  color: ${GetColor.White};
  border-radius: 2px;
  background-color: ${(props) => props.color};
  ${({ noMargin }) => (!noMargin ? 'margin-left: 5px;' : '')}
  padding: 0 3px;
  line-height: normal;
  vertical-align: middle;
  text-transform: uppercase;
`;

interface Props {
  noMargin?: boolean;
}

export const ProBadge = ({ noMargin = false }: Props) => {
  const { Colors } = useContext(ThemeContext);
  return (
    <Badge color={Colors.Primary.Dark} noMargin={noMargin}>
      Pro
    </Badge>
  );
};

export const NewBadge = ({ noMargin = false }: Props) => {
  const { Colors } = useContext(ThemeContext);
  return (
    <Badge color={Colors.HighlightDark} noMargin={noMargin}>
      New
    </Badge>
  );
};
