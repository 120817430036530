export interface HeaderCellDescription {
  text?: string;
  fontSize?: number;
  highlight?: boolean;
}
export interface FeatureRowDescription {
  text?: string;
  limited: boolean;
  standard: boolean;
  premium: boolean;
}

export const tiers: HeaderCellDescription[] = [
  {},
  {
    text: 'Limited',
    fontSize: 20,
  },
  {
    text: 'Standard',
    fontSize: 20,
  },
  {
    text: 'Premium',
    fontSize: 28,
    highlight: true,
  },
];

export const onboardingFeatures: FeatureRowDescription[] = [
  {
    text: 'Training resources',
    limited: true,
    standard: true,
    premium: true,
  },
  {
    text: 'One-hour phone or video call',
    limited: true,
    standard: true,
    premium: true,
  },
  {
    text: 'Data and portfolio upload assistance',
    limited: false,
    standard: true,
    premium: true,
  },
  {
    text: 'Customized training resources',
    limited: false,
    standard: true,
    premium: true,
  },
];

export const supportFeatures: FeatureRowDescription[] = [
  {
    text: 'Self-service help articles',
    limited: true,
    standard: true,
    premium: true,
  },
  {
    text: 'Email support',
    limited: true,
    standard: true,
    premium: true,
  },
  {
    text: '1:1 training calls for all new users',
    limited: false,
    standard: true,
    premium: true,
  },
  {
    text: 'Onsite meetings',
    limited: false,
    standard: true,
    premium: true,
  },
  {
    text: 'Quarterly calls',
    limited: false,
    standard: true,
    premium: true,
  },
  {
    text: 'Live chat support',
    limited: false,
    standard: true,
    premium: true,
  },
  {
    text: 'Priority support',
    limited: false,
    standard: false,
    premium: true,
  },
  {
    text: 'Unlimited phone support',
    limited: false,
    standard: false,
    premium: true,
  },
];

export const accessFeatures: FeatureRowDescription[] = [
  {
    text: 'Client Solutions Specialist',
    limited: false,
    standard: true,
    premium: true,
  },
  {
    text: 'Dedicated Solutions Specialist',
    limited: false,
    standard: false,
    premium: true,
  },
  {
    text: 'Client Solutions Strategist',
    limited: false,
    standard: false,
    premium: true,
  },
];

export const integrationsFeatures: FeatureRowDescription[] = [
  {
    text: 'Integration support for investments and portfolios',
    limited: false,
    standard: false,
    premium: true,
  },
];
