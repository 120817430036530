import React from 'react';
import AttributionIcon from './AttributionIcon';
import FactorSummaryIcon from './FactorSummaryIcon';
import LineGraphIcon from './LineGraphIcon';
import HeatmapIcon from './HeatmapIcon';
import ImageIcon from './ImageIcon';

type SupportedTypes =
  | 'GRID'
  | 'LINE'
  | 'BAR'
  | 'DIVERGENT_BAR'
  | 'HEATMAP'
  | 'SCATTER'
  | 'DISTRIBUTE_BAR'
  | 'CORRELATION'
  | 'IMAGE';

export const DragIconMapper: { [key in SupportedTypes]: React.ReactNode } = {
  GRID: <AttributionIcon />,
  BAR: <FactorSummaryIcon />,
  LINE: <LineGraphIcon />,
  DIVERGENT_BAR: <FactorSummaryIcon />,
  HEATMAP: <HeatmapIcon />,
  SCATTER: <LineGraphIcon />,
  DISTRIBUTE_BAR: <FactorSummaryIcon />,
  CORRELATION: <HeatmapIcon />,
  IMAGE: <ImageIcon />,
};
