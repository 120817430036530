import type { ConditionOption } from '../dropdowns/ConstraintConditionDropdown';
import type { FactorCondition } from 'venn-utils';

export const factorConditionOptions: ConditionOption<FactorCondition>[] = [
  {
    label: 'Minimum Exposure',
    value: 'minExposure',
    description: 'Minimum required exposure for the selected factor',
  },
  {
    label: 'Maximum Exposure',
    value: 'maxExposure',
    description: 'Maximum allowed exposure for the selected factor',
  },
];
