import styled, { css } from 'styled-components';
import { GetColor, SIDE_NAV_LINK_HEIGHT, TOP_NAV_HEIGHT } from 'venn-ui-kit';
import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import React from 'react';

interface NavLinkProps {
  active?: boolean;
  className?: string;
  showTopNav?: boolean;
}

const navLinkCSS = ({ active, showTopNav }: NavLinkProps) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  ${showTopNav
    ? css`
        height: ${TOP_NAV_HEIGHT}px;
      `
    : css`
        min-height: ${SIDE_NAV_LINK_HEIGHT}px;
        padding: 10px 0;
      `}

  &&.qa-navigation-no-access {
    background-color: ${GetColor.NavigationBarColor.UpsellInactiveBackground};
    border-bottom: 1px solid ${GetColor.HighlightDark} !important;
  }

  color: ${GetColor.GreyScale.Grey40};
  font-weight: bold;
  letter-spacing: 1px;
  line-height: normal;
  transition: all 0.1s ease-out;
  overflow: hidden;

  ${active &&
  css`
    background-color: ${GetColor.DarkBlue};
    color: ${GetColor.White} !important;
  `}

  &:hover {
    &&:not(.qa-navigation-no-access) {
      ${!active &&
      css`
        background-color: ${GetColor.GreyScale.Grey100};
        color: ${GetColor.GreyScale.Grey20} !important;
        .styled-icon {
          color: inherit;
        }
      `}
    }
    &&.qa-navigation-no-access {
      ${!active &&
      css`
        background-color: ${GetColor.NavigationBarColor.UpsellActiveBackground};
        color: ${GetColor.White} !important;
        .styled-icon {
          color: inherit;
        }
      `}
    }
  }
`;

export const StyledNavigationLink = styled(({ active, showTopNav, ...linkProps }: NavLinkProps & LinkProps) => (
  <Link {...linkProps} />
))`
  ${navLinkCSS}
`;

// A work around using any since Link sometimes can be an <a />
export const StyledNavigationPseudoLink = styled.button<NavLinkProps>`
  width: 100%;
  height: 100%;
  /* stylelint-disable-next-line */
  ${navLinkCSS}
  :hover {
    cursor: pointer;
    .external-icon {
      opacity: 1;
    }
  }
  &.highlighted {
    background-color: ${GetColor.Primary.Main};
    color: ${GetColor.Black};
    i {
      color: ${GetColor.Black};
    }
  }
`;
