import React from 'react';
import styled from 'styled-components';
import { GetColor, Hint, Icon, INVESTMENT_CORRELATION_TOOL_HREF } from 'venn-ui-kit';
import type { TimeFrame } from 'venn-utils';
import { SpecialCssClasses } from 'venn-utils';
import moment from 'moment';
import MediaQuery from 'react-responsive';
import { MAX_INVESTMENTS } from './logic';

interface FundCorrelationDateRangeMessageProps {
  analysisTimeFrame?: TimeFrame;
  isPortfolio?: boolean;
  totalInvestmentsCount: number;
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default ({ analysisTimeFrame, isPortfolio, totalInvestmentsCount }: FundCorrelationDateRangeMessageProps) => {
  if (isPortfolio && totalInvestmentsCount <= MAX_INVESTMENTS) {
    return null;
  }

  const dateFormat = 'MMM D, YYYY';
  const formatStartTime =
    analysisTimeFrame && analysisTimeFrame.startTime
      ? moment.utc(analysisTimeFrame.startTime).format(dateFormat)
      : '--';
  const formatEndTime =
    analysisTimeFrame && analysisTimeFrame.endTime ? moment.utc(analysisTimeFrame.endTime).format(dateFormat) : '--';

  const excludedInvestments = totalInvestmentsCount - MAX_INVESTMENTS;
  const excludedMessage =
    excludedInvestments > 1 ? 'smaller investments were excluded.' : 'smaller investment was excluded.';

  return (
    <MediaQuery print>
      {(print) => (
        <Message>
          <Hint>
            {totalInvestmentsCount > MAX_INVESTMENTS && (
              <>
                {excludedInvestments} {excludedMessage}
              </>
            )}
            {!isPortfolio && (
              <>
                {' '}
                This analysis is constrained by your master portfolio. Data shown is for{' '}
                <MessageHighlight>{formatStartTime}</MessageHighlight> -{' '}
                <MessageHighlight>{formatEndTime}</MessageHighlight>.
                {!print && (
                  <a href={INVESTMENT_CORRELATION_TOOL_HREF} target="_blank" rel="noopener noreferrer">
                    {' '}
                    <InfoIcon className={SpecialCssClasses.NotDownloadable}>
                      <Icon type="info-circle" />
                    </InfoIcon>
                  </a>
                )}
              </>
            )}
          </Hint>
        </Message>
      )}
    </MediaQuery>
  );
};

const Message = styled.div`
  min-height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 20px;
`;

const MessageHighlight = styled.span`
  color: ${GetColor.HighlightDark};
`;

const InfoIcon = styled.span`
  color: ${GetColor.Black};
  font-weight: 900;
  cursor: pointer;
  @media print {
    cursor: pointer;
  }
`;
