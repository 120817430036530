import React, { useContext, useRef } from 'react';
import { isNil, mapValues, merge, omitBy, pick } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { useRecoilValue, useRecoilRefresher_UNSTABLE } from 'recoil';
import { patchPrintSettings } from 'venn-api';
import { useMutation } from '@tanstack/react-query';
import { Button, GetColor, Loading, Notifications, NotificationType, Warning } from 'venn-ui-kit';
import { apiPrintSettings, DEFAULT_FONT_SIZE_OVERRIDES } from 'venn-state';
import { analyticsService, toClassName, withSuspense } from 'venn-utils';
import styled from 'styled-components';
import { FlexWrapper, Name, Section, StyledIcon as HeaderIcon, Subtitle, WrapperForSections } from './shared';
import { RequiredLabel, UserContext, FontSizeInput } from 'venn-components';
import { type FontOverridesFormData, fontOverridesFormSections } from './logic/fontSizeDefaultsLogic';
import { FontSizeConfirmationDialog } from './FontSizeConfirmationDialog';

const dataFields = [
  'legalFontOverrides',
  'blockFontOverrides',
  'sectionPageFontOverrides',
  'titlePageFontOverrides',
] as const;

export const ReportLabFontSizeDefaultsSection = withSuspense(<Loading title="Loading print settings..." />, () => {
  const { hasPermission } = useContext(UserContext);
  const isReadOnly = !hasPermission('MANAGE_PRINT_CONFIGURATION');
  const apiPrintSettingsData = useRecoilValue(apiPrintSettings);
  const resetRecoilPrintSettings = useRecoilRefresher_UNSTABLE(apiPrintSettings);
  const patchFontOverrides = useMutation((data: FontOverridesFormData) => patchPrintSettings(data), {
    onSuccess: () => {
      resetRecoilPrintSettings();
      Notifications.notify('Font size defaults have been updated successfully.');
    },
    onError: () => Notifications.notify('Failed to update font size defaults.', NotificationType.ERROR),
  });
  const dialogRef = useRef<HTMLDialogElement>(null);

  const { formState, handleSubmit, getValues, control, reset } = useForm<FontOverridesFormData>({
    mode: 'onSubmit',
    defaultValues: DEFAULT_FONT_SIZE_OVERRIDES,
    values: merge(
      {},
      DEFAULT_FONT_SIZE_OVERRIDES,
      mapValues(pick(apiPrintSettingsData, ...dataFields), (fontSizeSection) =>
        omitBy(fontSizeSection, (fontSize) => isNil(fontSize)),
      ) as FontOverridesFormData,
    ),
  });

  const onSubmit = () => {
    dialogRef.current?.showModal?.();
  };
  return patchFontOverrides.isLoading ? (
    <Loading title="Loading print settings..." />
  ) : (
    <WrapperForSections>
      <dialog ref={dialogRef}>
        <FontSizeConfirmationDialog
          onPrimaryClick={() => {
            const data = getValues();
            patchFontOverrides.mutate(data);
            analyticsService.ctaClicked({ purpose: 'saveWorkspaceDefaults' });
            reset(data);
          }}
          onClose={() => dialogRef.current?.close?.()}
          formState={formState}
        />
      </dialog>
      <form onSubmit={handleSubmit(onSubmit)} data-testid="qa-report-lab-fonts">
        <Section>
          <HeaderWrapper>
            <FlexWrapper>
              <HeaderIcon type="text-size" />
              <Name>Report Lab Font Size Defaults</Name>
            </FlexWrapper>
            <Button disabled={!formState.isDirty || isReadOnly} icon="save" type="submit">
              Save changes
            </Button>
          </HeaderWrapper>
        </Section>
        {fontOverridesFormSections.map((section) => (
          <Section key={section.name} data-testid={toClassName(section.name)}>
            <Subsection>
              <SectionHeadingWrapper>
                <div>
                  <Subtitle>{section.name}</Subtitle>
                </div>
                {section.affects === 'existing' && (
                  <Warning iconPrefix="fas" icon="triangle-exclamation" text="Will update all existing reports" />
                )}
                {section.affects === 'new' && <AffectsNewSpan>Only affects newly-created blocks</AffectsNewSpan>}
              </SectionHeadingWrapper>

              <RowWrapper>
                {section.fields.map((field) => (
                  <FieldWrapper key={field.path}>
                    <RequiredLabel>{field.name}</RequiredLabel>
                    <Description>
                      {field.description && (
                        <>
                          <span>{field.description}</span>
                          &nbsp;
                        </>
                      )}
                      <Constraint>
                        ({field.min}-{field.max}pt)
                      </Constraint>
                    </Description>
                    <InputWrapper>
                      <Controller
                        control={control}
                        name={field.path}
                        render={({ field: { onChange, value } }) => (
                          <FontSizeInput
                            name={field.name}
                            min={field.min}
                            max={field.max}
                            onChange={onChange}
                            value={value}
                            isReadOnly={isReadOnly}
                            onReset={() => onChange(field.default)}
                            resetEnabled={field.default !== getValues(field.path)}
                            resetTooltip={`Revert to Venn default (${field.default}pt)`}
                          />
                        )}
                      />
                    </InputWrapper>
                  </FieldWrapper>
                ))}
              </RowWrapper>
            </Subsection>
          </Section>
        ))}
      </form>
    </WrapperForSections>
  );
});

const RowWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 6px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;
`;

const Subsection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const InputWrapper = styled.span`
  display: inline-flex;
  gap: 4px;
  align-items: baseline;
`;

const Constraint = styled.span`
  color: ${GetColor.HintGrey};
  white-space: nowrap;
`;

const Description = styled.div`
  font-size: 12px;
`;

export const SectionHeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const AffectsNewSpan = styled.span`
  color: ${GetColor.DarkGrey};
  font-size: 10px;
`;
