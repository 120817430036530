import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import { TOOLTIP_WIDTH } from '../factor-chart/constants';
import { GetColor } from 'venn-ui-kit';

interface BaseCorrelationChartTooltipProps {
  x: number;
  y: number;
  cellWidth: number;
  rightEdge: number;
}

const BaseCorrelationChartTooltip: FC<React.PropsWithChildren<BaseCorrelationChartTooltipProps>> = ({
  x,
  y,
  cellWidth,
  rightEdge,
  children,
}) => {
  const overflowsRightEdge = x + cellWidth / 2 + TOOLTIP_WIDTH / 2 >= rightEdge;
  const tooltipX = overflowsRightEdge
    ? rightEdge - TOOLTIP_WIDTH - cellWidth / 8
    : x + cellWidth / 2 - TOOLTIP_WIDTH / 2;
  const tooltipArrowX = overflowsRightEdge ? x - tooltipX + cellWidth / 2 : TOOLTIP_WIDTH / 2;
  return (
    <Tip x={tooltipX} y={y} tipX={tooltipArrowX}>
      <Container width={TOOLTIP_WIDTH}>{children}</Container>
    </Tip>
  );
};

export default BaseCorrelationChartTooltip;

const Tip = styled.div<{ x: number; y: number; tipX: number }>`
  pointer-events: none;
  position: absolute;
  top: ${({ y }) => y}px;
  left: ${({ x }) => x}px;
  width: 0;
  height: 0;
  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border: 5px solid black;
    border-color: transparent transparent ${GetColor.Black} ${GetColor.Black};
    ${({ tipX }) => `transform: translateX(${tipX}px) translateX(-50%) translateY(-5px) rotate(-45deg);`}
  }
`;

const Container = styled.div<{ width: number }>`
  position: absolute;
  bottom: 0;
  width: ${({ width }) => `${width}px`};
  background-color: rgba(16, 22, 27, 0.9);
  color: ${GetColor.White};
  padding: 10px;
`;
