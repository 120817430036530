import React from 'react';
import styled from 'styled-components';
import { ReportingTable } from './components/ReportingTable';

const Reporting = () => {
  return (
    <Content>
      <TableAndAnnotationContainer includePadding>
        <ReportingTable />
      </TableAndAnnotationContainer>
    </Content>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const TableAndAnnotationContainer = styled.div<{ includePadding: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${(props) =>
    props.includePadding &&
    `
    padding: 40px;
    padding-top: 0;
  `}
`;

export default Reporting;
