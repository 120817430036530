import React from 'react';
import styled from 'styled-components';
import { buttonize, useHover, withSuspense } from 'venn-utils';
import { EllipsisTooltipSpan, GetColor, ItemIcon, ShimmerBlock, Subtitle2 } from 'venn-ui-kit';
import { useRecoilValue, waitForAll } from 'recoil';
import type { SubjectInputId } from 'venn-state';
import { analysisSubjectQuery, subjectInputGroupName, subjectInputGroupSubjects } from 'venn-state';
import { HoverIcon } from './HoverIcon';
import { SpacedItems } from '../../../../studio-main-left-panel/panels/components/SubjectGroupRow';
import { convertAnalysisSubjectToItemType } from 'venn-components';

/**
 * Displays a subject input group and all of its constituent subjects, allowing selection of the entire input group.
 */
export const SelectSubjectInputSection = ({
  inputId,
  isChecked,
  onClick,
}: {
  inputId: SubjectInputId;
  /** Whether to start out checked, otherwise will show a checkmark icon on hover. */
  isChecked: boolean;
  /** Invoked with the same inputId provided to this component, for ease of use. */
  onClick: (inputId: SubjectInputId) => void;
}) => {
  const [isRootHovered, rootHoverHandlers] = useHover();
  return (
    <SubjectInputSubjectsSectionWrapper {...rootHoverHandlers} {...buttonize(() => onClick(inputId))} role="option">
      <HoverIcon
        // Whole row is selectable, so icon shouldn't be.
        tabIndex={-1}
        style={{ padding: '6px' }}
        type="check"
        visible={isChecked || isRootHovered}
        hoverColor={GetColor.Primary.Dark}
      />
      <div style={{ overflow: 'hidden' }}>
        <SubjectInputSubjectsList key={inputId} inputId={inputId} />
      </div>
    </SubjectInputSubjectsSectionWrapper>
  );
};

/** Provides layout, spacing, and container styling. */
const SubjectInputSubjectsSectionWrapper = styled.span`
  display: flex;
  align-items: baseline;

  margin-top: 2px;
  padding-top: 6px;
  padding-bottom: 6px;
  margin-bottom: 10px;

  &:hover {
    background-color: ${GetColor.WhiteGrey};
    cursor: pointer;
  }
`;

/** Lists the name of a subject input and also all of its constituent subjects. */
const SubjectInputSubjectsList = withSuspense(<ShimmerBlock />, ({ inputId }: { inputId: SubjectInputId }) => {
  const name = useRecoilValue(subjectInputGroupName(inputId));
  const subjects = useRecoilValue(subjectInputGroupSubjects(inputId));
  const analysisSubjects = useRecoilValue(waitForAll(subjects.map((s) => analysisSubjectQuery(s))));
  return (
    <>
      <Subtitle2 style={{ marginBottom: 4 }}>{name}</Subtitle2>
      {analysisSubjects.map((subject) => (
        <SpacedItems key={subject?.id}>
          <ItemIcon
            item={convertAnalysisSubjectToItemType(subject)}
            dataSource={subject.superItem.dataSource || subject.portfolio?.dataSource}
            isUploaded={subject.userUploaded && !subject.isCompositeBenchmark}
            investmentSource={subject.fund?.investmentSource}
          />
          <EllipsisTooltipSpan usePortal flex maxWidth={200}>
            {subject.name}
          </EllipsisTooltipSpan>
        </SpacedItems>
      ))}
    </>
  );
});
