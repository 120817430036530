import type React from 'react';
import type { SearchResultWithUIState, SearchState } from '../types';
import type { LibrarySearchEntity, LibrarySearchResult, OperationResult, SearchQuery } from 'venn-api';
import type { QuickFilter } from 'venn-utils';
import {
  analyticsService,
  getLibraryItemTypeFromString,
  getLibraryQuickFilterFromString,
  getQueryParamsFromFilters,
  LibraryItemType,
} from 'venn-utils';
import { PAGE_SIZE, toTrackingFormat } from '../utils';
import debounce from 'lodash/debounce';
import type { DataSource, MorningstarCategory, SearchParams } from 'venn-state';
import { DEFAULT_PARAMS_V2 } from 'venn-state';
import { isNull, omitBy } from 'lodash';

export type FilterLocation = 'library' | 'allFilters' | 'filterPill' | 'sort' | 'private-library';

/**
 * Get the library search params based on the search query
 * Useful for loading a filter set stored in the database as a SearchQuery
 */
export const getLibrarySearchParamsFromSearchQuery = (params: SearchQuery): SearchParams => {
  // some values in the returned SearchQuery can be null because our API lies
  // it is important to get rid of them and fallback to default params
  const nonNullParams = omitBy(params, isNull);

  const filters: QuickFilter[] = !params.quickFilters
    ? []
    : params.quickFilters.map((filter) => {
        return getLibraryQuickFilterFromString(filter);
      });
  const { fundIds, currencyFilters, dataSources, metricFilters, morningstarCategories, ...nonRenamedParams } =
    nonNullParams;
  return {
    ...DEFAULT_PARAMS_V2,
    ...nonRenamedParams,
    selectedIds: fundIds ?? [],
    currency: currencyFilters ?? [],
    dataSource: (dataSources ?? []) as DataSource[],
    metrics: metricFilters ?? [],
    morningstarCategories: (morningstarCategories ?? []) as MorningstarCategory[],
    filters,
    itemType: getLibraryItemTypeFromString(params.itemType ?? LibraryItemType.ALL),
  };
};

/**
 * Get the API library search query params based on the selected filters and SearchParams kept in the URL.
 */
export const getSearchQueryParams = (params: SearchParams): Partial<SearchQuery> => {
  const apiParamsFromFilters = getQueryParamsFromFilters({
    quickFilters: params.filters ?? [],
    tags: params.tags ?? [],
    itemType: params.itemType ?? LibraryItemType.ALL,
  });

  // for historical reasons we use slightly different names in SearchParams (URL) and SearchQuery (API)
  // the destructure below avoids adding both "currency" and "currencyFilters" (etc) into the returned SearchQuery
  const { currency, dataSource, metrics, ...nonRenamedParams } = params;
  return {
    ...apiParamsFromFilters,
    ...nonRenamedParams,
    pageSize: PAGE_SIZE,
    currencyFilters: currency,
    dataSources: dataSource,
    metricFilters: metrics,
    privateAssetSearchMode: 'PUBLIC_ONLY',
  };
};

/**
 * Search the library using the given params.
 * Also transforms the search results by adding UI-related parameters such as isNew and selected.
 * @param params the search params
 * @param setSearchState method use to set the search results
 * @param searchApi the search api to use
 * @param isInitialLoad true if the search query string has changed since the previous one, falsy otherwise
 * @param latestFilterLocation the location of the last filter click (for tracking purposes)
 * @param hideLoading whether or not to set search state to loading: true
 * @param onPageChange method to call to go back to the previous page if the page (> 1) being requested has no more results
 */
const search = async (
  params: SearchParams,
  setSearchState: React.Dispatch<React.SetStateAction<SearchState>>,
  searchApi: (query: Partial<SearchQuery>) => Promise<OperationResult<LibrarySearchResult>>,
  isInitialLoad: boolean,
  latestFilterLocation: FilterLocation,
  hideLoading = false,
  onPageChange?: (index: number) => void,
) => {
  // For example when updating the proxy, show just an inline loader
  if (!hideLoading) {
    setSearchState((prev) => ({
      ...prev,
      loading: true,
    }));
  }

  await debouncedSearch(params, setSearchState, searchApi, isInitialLoad, latestFilterLocation, onPageChange);
};

const debouncedSearch = debounce(
  async (
    params: SearchParams,
    setSearchState: React.Dispatch<React.SetStateAction<SearchState>>,
    searchApi: (query: Partial<SearchQuery>) => Promise<OperationResult<LibrarySearchResult>>,
    isInitialLoad: boolean,
    latestFilterLocation: FilterLocation,
    onPageChange?: (index: number) => void,
  ) => {
    const {
      filters = [],
      tags: selectedTags = [],
      itemType = LibraryItemType.ALL,
      page,
      name,
      lastTrackTime,
      selectedIds,
      currency = [],
      assetTypes = [],
      dataSource = [],
      metrics = [],
    } = params;

    try {
      const apiQueryParams = getSearchQueryParams({
        ...params,
      });
      const { content } = await searchApi(apiQueryParams);

      const resResults = content.results.map((result: LibrarySearchEntity): SearchResultWithUIState => {
        return {
          ...result,
          isNew: !!lastTrackTime && result.created > lastTrackTime,
          selected: !!selectedIds?.find((id) => result.fundId === id),
        };
      });

      if (!isInitialLoad) {
        analyticsService.searchQueryEntered({
          totalResults: content.total,
          visibleResults: content.results.length,
          query: name ?? '',
          location: latestFilterLocation,
          quickFilters: filters,
          tagFilters: selectedTags,
          typeFilters: [itemType],
          currencyFilters: currency,
          dataSourceFilters: dataSource,
          metricFilters: toTrackingFormat(metrics),
          assetTypeFilters: assetTypes,
        });
      }

      if (resResults.length === 0 && page && page > 1 && onPageChange) {
        // If it's the last page and result is 0, change to previous page
        onPageChange(page - 1);
        return;
      }

      setSearchState({
        loading: false,
        results: resResults,
        totalCount: content.total,
      });
    } catch (e) {
      if (e.name !== 'AbortError') {
        setSearchState((prev) => ({
          ...prev,
          loading: false,
        }));
      }
    }
  },
  500,
);

export default search;
