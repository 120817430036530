import React, { useContext, useEffect, useState } from 'react';
import type { UserData } from 'venn-components';
import {
  ConfirmationModal,
  DataEditorContent,
  FieldWithLabel,
  MetaHeader,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSubhead,
  Toggle,
  ToggleOption,
  UnsavedChangesModal,
  useDataEditor,
  UserContext,
} from 'venn-components';
import { Button, Col, Container, GetColor, Notifications, NotificationType, Row, Tooltip } from 'venn-ui-kit';
import styled from 'styled-components';
import {
  analyticsService,
  FrequencyTypes,
  FundUtils,
  logExceptionIntoSentry,
  toReturnFrequency,
  useModal,
} from 'venn-utils';
import type { Fund } from 'venn-api';
import { updateFundFrequency, updateSeriesForFund } from 'venn-api';

interface InvestmentDataEditorProps {
  fund: Fund;
  userData: UserData;
  onReturnsUpdated: (newFundSeries: number[][]) => void;
  onFundUpdated: () => void;
}

const InvestmentDataEditor = ({ fund, userData, onReturnsUpdated, onFundUpdated }: InvestmentDataEditorProps) => {
  const { hasPermission } = useContext(UserContext);
  const [saving, setSaving] = useState(false);

  const [preferredFrequency, setPreferredFrequency] = useState<FrequencyTypes>(
    fund.preferredFrequency ?? FrequencyTypes.MONTHLY,
  );

  const [frequencyConfirmationOpen, openFrequencyConfirmation, closeFrequencyConfirmation] = useModal();
  const handleFrequencyChange = async (value: FrequencyTypes) => {
    try {
      await updateFundFrequency(fund.id, toReturnFrequency(value));
      setPreferredFrequency(value);
      Notifications.notify('Updated return frequency.', NotificationType.SUCCESS);
    } catch (e) {
      logExceptionIntoSentry(e);
      Notifications.notify('Failed to update return frequency.', NotificationType.ERROR);
    }
    onFundUpdated();
  };
  const [handleFrequencyConfirm, setHandleFrequencyConfirm] = useState<() => void>(closeFrequencyConfirmation);
  const handleFrequencyToggle = (value: FrequencyTypes) => {
    setHandleFrequencyConfirm(() => {
      return () => {
        closeFrequencyConfirmation();
        handleFrequencyChange(value);
      };
    });
    openFrequencyConfirmation();
  };

  const fundId = fund.id;

  const saveData = async (data: number[][]) => {
    try {
      setSaving(true);
      const { content } = await updateSeriesForFund(fundId, true, data);
      analyticsService.investmentMetadataSaved({
        fieldsModified: ['Returns'],
        investmentId: fundId,
        userUploaded: true,
      });
      onReturnsUpdated(content);
      Notifications.notify('Data successfully updated!', NotificationType.SUCCESS);
    } catch (e) {
      const error = await e;
      logExceptionIntoSentry(error);
      Notifications.notify(error?.content?.message ?? 'Unable to save data. Try again later.', NotificationType.ERROR);
    }
    setSaving(false);
  };

  const dataEditorProps = useDataEditor(userData, saveData);
  const { isEdited, hasError, onSave, resetData } = dataEditorProps;
  const [isSaveModalOpen, openSaveModal, closeSaveModal, onModalSave] = useModal(onSave);
  const [isUnsavedChangesModalOpen, openUnsavedChangesModal, closeUnsavedChangesModal] = useModal();
  const isFundDataEditable = FundUtils.isPerformanceEditable(fund) && hasPermission('UPLOAD_RETURNS');
  const showFrequencyToggle = fund.investmentSource === 'CUSTODIAN';

  useEffect(() => {
    if (isEdited) {
      UnsavedChangesModal.blockHistory(openUnsavedChangesModal);
      return UnsavedChangesModal.unblockHistory;
    }
    UnsavedChangesModal.unblockHistory();
    return undefined;
  }, [isEdited, openUnsavedChangesModal]);

  const custodianTooltipMessage =
    (!isFundDataEditable && 'Data cannot be edited for integrated investments') || undefined;
  const resetTooltipContent = custodianTooltipMessage || (!isEdited && 'No changes have been made yet') || undefined;
  const saveTooltipContent = resetTooltipContent || (hasError && 'Invalid data') || undefined;

  return (
    <>
      <Container fluid>
        <PerformanceRow>
          <Col
            xs={{
              span: showFrequencyToggle ? 6 : 8,
              offset: 2,
            }}
          >
            <DataEditorContent
              {...dataEditorProps}
              saving={saving}
              isReturn={userData.dataType === 1}
              stickyHeaderOffset={400}
              disabled={!isFundDataEditable}
              minHeight={250}
              disabledTooltipContent={custodianTooltipMessage}
            />
            <ButtonsWrapper>
              <Tooltip usePortal content={resetTooltipContent}>
                <Button
                  onClick={resetData}
                  disabled={saving || !isEdited || !isFundDataEditable}
                  data-testid="reset-button"
                >
                  Reset
                </Button>
              </Tooltip>
              <Tooltip content={saveTooltipContent}>
                <Button
                  destructive
                  noMargin
                  onClick={openSaveModal}
                  disabled={saving || !isEdited || hasError || !isFundDataEditable}
                  data-testid="save-button"
                >
                  Permanently Update Data
                </Button>
              </Tooltip>
            </ButtonsWrapper>
          </Col>
          {showFrequencyToggle && (
            <ButtonColumn xs={2}>
              <FrequencyPickerWrapper>
                <FieldWithLabel data-testid="qa-frequency-toggle">
                  <MetaHeader label="Return Frequency" info="Change the return frequency of this investment." />
                  <div>
                    <Toggle value={preferredFrequency} onChange={handleFrequencyToggle}>
                      <ToggleOption value={FrequencyTypes.MONTHLY}>Monthly</ToggleOption>
                      <ToggleOption value={FrequencyTypes.QUARTERLY}>Quarterly</ToggleOption>
                    </Toggle>
                  </div>
                </FieldWithLabel>
              </FrequencyPickerWrapper>
            </ButtonColumn>
          )}
        </PerformanceRow>
      </Container>
      {/* Modals */}
      {isUnsavedChangesModalOpen && (
        <UnsavedChangesModal onCancel={closeUnsavedChangesModal} onAccept={closeUnsavedChangesModal} />
      )}
      {frequencyConfirmationOpen && (
        <ConfirmationModal
          destructive
          text="Changing the return frequency of this investment will remove any proxies or benchmarks associated with it."
          onCancel={closeFrequencyConfirmation}
          onProceed={handleFrequencyConfirm}
        />
      )}
      {isSaveModalOpen && (
        <>
          <Modal>
            <ModalHeader extraPadding>Are you sure?</ModalHeader>
            <ModalSubhead extraPadding>The investment's data will be permanently updated.</ModalSubhead>
            <ModalFooter
              destructive
              onCancel={closeSaveModal}
              primaryLabel="Yes, save changes"
              onPrimaryClick={() => onModalSave(undefined)}
            />
          </Modal>
        </>
      )}
    </>
  );
};

export default InvestmentDataEditor;

const PerformanceRow = styled(Row)`
  min-height: 250px;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  background-color: ${GetColor.White};
`;

const FrequencyPickerWrapper = styled.div`
  margin: 0 auto;
  margin-left: 0;
`;

const ButtonColumn = styled(Col)`
  display: flex;
  flex-direction: column;
`;
