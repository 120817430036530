import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import type { AnalysisSubject } from 'venn-utils';
import { DropMenu, GetColor, BrandLogo } from 'venn-ui-kit';
import ManageInvestmentDataTrigger from './ManageInvestmentDataTrigger';
import isNil from 'lodash/isNil';
import compact from 'lodash/compact';

interface CategoryDropMenuProps {
  subject: AnalysisSubject;
  isOff: boolean;
  onToggleTurnOffCategory: (turnOff: boolean) => void;
  onOpenModal?: () => void;
  disabled?: boolean;
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default ({ subject, isOff, onToggleTurnOffCategory, onOpenModal, disabled }: CategoryDropMenuProps) => {
  const { Colors } = useContext(ThemeContext);
  if (!subject.fund) {
    return null;
  }
  const category = subject && subject.type === 'investment' && subject.categoryGroup;
  const isPredicted =
    !isOff && subject && subject.type === 'investment' && category && subject.categoryPrediction?.key === category?.key;

  const isEditable = subject.fund.userUploaded;

  const noCategory = isNil(category);
  return (
    <ManageInvestmentDataTrigger
      fundId={subject.fund.id}
      customRenderer={(onClick) => (
        <StyledDropMenu
          items={compact([
            !noCategory && {
              value: true,
              label: category ? category.name : '(None)',
              style: { color: isPredicted ? Colors.HighlightDark : Colors.Black },
              icon: isPredicted ? (
                <OptionLogo>
                  <BrandLogo height={18} scaleToFit />
                </OptionLogo>
              ) : undefined,
            },
            { value: false, label: 'Off', style: { color: Colors.HintGrey } },
          ])}
          selected={!isOff && !noCategory}
          onChange={({ value }) => onToggleTurnOffCategory(!value)}
          link={isEditable ? 'Edit Category' : undefined}
          onLinkClick={() => {
            onClick();
            onOpenModal && onOpenModal();
          }}
          className={isPredicted ? 'trigger-value-predicted' : undefined}
          disabled={disabled}
        />
      )}
    />
  );
};

const StyledDropMenu = styled(DropMenu)`
  &.trigger-value-predicted label {
    color: ${GetColor.HighlightDark};
    position: relative;
    top: -3px;
  }
`;

const OptionLogo = styled.span`
  position: relative;
  top: 5px;
  margin-right: 10px;
`;
