import React, { useCallback } from 'react';
import styled from 'styled-components';
import SimpleFilter from '../SimpleFilter';
import { useRecoilValue } from 'recoil';
import type { DataSource } from 'venn-state';
import { allDataSourcesSelector } from 'venn-state';
import { withSuspense } from 'venn-utils';

const fieldName = 'Data Source';

interface DataSourceFilterProps {
  className?: string;
  onFilter: (updated: DataSource[]) => void;
  selected: DataSource[];
  closeOnOnlyClick?: boolean;
  isAdvanced?: boolean;
}

const DataSourceFilter = ({ className, selected, onFilter, closeOnOnlyClick, isAdvanced }: DataSourceFilterProps) => {
  const handleFilter = useCallback(
    (updated: string | string[]) => {
      const updatedFilters = updated as DataSource[];
      onFilter(Array.from(updatedFilters));
    },
    [onFilter],
  );
  const viewableDataSources = useRecoilValue(allDataSourcesSelector);
  if (viewableDataSources.length === 0) {
    return null;
  }
  const viewableDataSourceItems = Object.values(viewableDataSources).map((dataSource) => ({
    label: dataSource,
    value: dataSource,
  }));

  return (
    <StyledFilter
      className={className}
      label={fieldName}
      items={viewableDataSourceItems}
      initialSelected={selected}
      onFilter={handleFilter}
      closeOnOnlyClick={closeOnOnlyClick}
      isAdvanced={isAdvanced}
      menuWidth={330}
    />
  );
};

const StyledFilter = styled(SimpleFilter)`
  display: inline-flex;
`;
export default withSuspense(null, DataSourceFilter);
