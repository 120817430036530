import React, { useContext } from 'react';
import { analyticsService, logMessageToSentry } from 'venn-utils';
import getAnalysisBlocks from '../analysisBlocks';
import { FailedAnalysisInfo, formatDateRangeForTracking, UserContext } from 'venn-components';
import type { AnalysisBlocksProps } from './AnalysisBlocks';
import type { Message } from 'venn-api';
import { HIDE_FORECASTS_IN_EXPORTS_KEY } from 'venn-api';

interface SubjectErrorProps
  extends Pick<
    AnalysisBlocksProps,
    | 'subjects'
    | 'relative'
    | 'setDateRange'
    | 'trackingId'
    | 'dateRange'
    | 'hasBenchmark'
    | 'maxFrequency'
    | 'removeComparisonSubject'
  > {
  subjectErrors: (Message | undefined)[];
}

const SubjectError: React.FC<React.PropsWithChildren<SubjectErrorProps>> = ({
  subjects,
  subjectErrors,
  relative,
  setDateRange,
  trackingId,
  dateRange,
  hasBenchmark,
  maxFrequency,
  removeComparisonSubject,
}) => {
  const error = subjectErrors.find(Boolean);
  const { settings } = useContext(UserContext);

  if (!error) {
    logMessageToSentry('Failed to render failed analysis info');
    return null;
  }

  const analysisBlocks = getAnalysisBlocks(!!settings?.user?.[HIDE_FORECASTS_IN_EXPORTS_KEY]);

  return (
    <FailedAnalysisInfo
      comparisonSubjects={subjects}
      removeComparisonSubject={removeComparisonSubject}
      relativeToBenchmark={relative}
      error={{
        message: error.text,
        code: error.code,
      }}
      regressionName="Multi-subject comparison"
      onResetAnalysisPeriod={() => setDateRange({ from: undefined, to: undefined })}
      trackingId={trackingId}
      blockNames={analysisBlocks.map((block) => block.type)}
      blockTitles={analysisBlocks.map((block) => block.getTitle(relative))}
      updateAnalysisStatusForTracking={() => {
        analyticsService.analysisFailed({
          analysisName: 'Multi-subject comparison',
          blocks: analysisBlocks.map((block) => block.getTitle(relative)),
          dateRange: formatDateRangeForTracking(dateRange.from, dateRange.to),
          frequency: maxFrequency,
          hasBenchmark,
          hasProxy: false,
          objectType: 'multi-subject',
          relativeToBenchmark: relative,
        });
      }}
    />
  );
};

export default SubjectError;
