import React from 'react';
import type { FactorProps } from '../types';

const Total = ({ x, y, width, negative, color }: FactorProps) => {
  const transform = negative ? `rotate(45 ${x} ${y + 5})` : `rotate(45 ${x + width} ${y + 5})`;
  return (
    <g>
      <rect x={negative ? x : x + width} y={y + 5} width={10} height={10} fill={color} transform={transform} />
      <line stroke={color} x1={x} y1={y + 12} x2={x + width} y2={y + 12} />
    </g>
  );
};
export default Total;
