import React from 'react';
import { getBlocksExceptPerformance } from 'venn-utils';
import type { SharedProps } from '../types';
import Loading from './Loading';
import { perfRiskTabs } from '../configs';
import { ComponentFromBlock } from '../blocks';
import { Section, SectionHeadline, BlockRow } from 'venn-ui-kit';

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default (props: SharedProps) => {
  const { analyses, factorLenses, analysisConfig } = props;

  if (!analyses || !analyses.results || !factorLenses || factorLenses.length === 0) {
    return <Loading />;
  }

  const blocks = getBlocksExceptPerformance(analysisConfig);

  const performanceBlocks = blocks.slice(0, blocks.length / 2);

  const riskBlocks = blocks.slice(blocks.length / 2);

  return (
    <>
      <Section id={perfRiskTabs.PERFORMANCE}>
        <SectionHeadline>Performance Analysis</SectionHeadline>
        {performanceBlocks.map((block) => (
          <BlockRow key={block}>
            <ComponentFromBlock block={block} sharedProps={props} />
          </BlockRow>
        ))}
      </Section>
      <Section id={perfRiskTabs.RISK} pageBreak>
        <SectionHeadline>Risk Analysis</SectionHeadline>
        {riskBlocks.map((block) => (
          <BlockRow key={block}>
            <ComponentFromBlock block={block} sharedProps={props} />
          </BlockRow>
        ))}
      </Section>
    </>
  );
};
