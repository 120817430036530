import type { NotablePeriod, TypeEnum as NotablePeriodSource } from 'venn-api';
import type { CustomNotablePeriod, SelectableCustomNotablePeriod, SelectableNotablePeriodItem } from 'venn-utils';
import { compact, isNil } from 'lodash';

/**
 * Get a list all notable periods:
 *   - with flag indicating whether they are selected
 *   - with a field documenting their source
 *   - in the following order:
 *     - chronologically ordered PREDEFINED periods
 *     - CUSTOM periods in the order in which they were defined
 */
export const getAllNotablePeriodItems = (
  selectedOrderedPeriodIds: number[] | undefined,
  selectableCustomNotablePeriods: SelectableCustomNotablePeriod[] | undefined,
  allPredefinedPeriods: NotablePeriod[],
): SelectableNotablePeriodItem[] => {
  // If no selection has been made by the user yet, select all predefined periods by default.
  const selectedPredefinedAndCustomIds: number[] = selectedOrderedPeriodIds ?? allPredefinedPeriods.map(({ id }) => id);

  return [
    ...allPredefinedPeriods.map(({ name, id, start, extremumDate }) => ({
      name,
      id,
      start,
      end: extremumDate,
      selected: selectedPredefinedAndCustomIds.includes(id),
      source: 'PREDEFINED' as NotablePeriodSource,
    })),
    ...(selectableCustomNotablePeriods ?? []).map(({ name, id, start, end, selected }) => ({
      name,
      id,
      start,
      end,
      selected,
      source: 'CUSTOM' as NotablePeriodSource,
    })),
  ];
};

/**
 * Get a list all SELECTED notable periods, in the order dictated by `selectedOrderedPeriodIds`.
 */
export const getSelectedAndOrderedNotablePeriodItems = (
  selectedOrderedPeriodIds: number[] | undefined,
  selectableCustomNotablePeriods: SelectableCustomNotablePeriod[] | undefined,
  allPredefinedPeriods: NotablePeriod[],
): CustomNotablePeriod[] => {
  // If no selection has been made by the user yet, select all predefined periods by default.
  const selectedAndOrderedPredefinedAndCustomIds: number[] =
    selectedOrderedPeriodIds ?? allPredefinedPeriods.map(({ id }) => id);

  return compact(
    selectedAndOrderedPredefinedAndCustomIds.map((selectedId: number) => {
      const predefinedPeriod = allPredefinedPeriods.find(({ id }) => selectedId === id);
      if (!isNil(predefinedPeriod)) {
        const { name, id, start, extremumDate: end } = predefinedPeriod;
        return { name, id, start, end };
      }

      const customPeriod = selectableCustomNotablePeriods?.find(({ id }) => selectedId === id);
      if (!isNil(customPeriod)) {
        const { name, id, start, end } = customPeriod;
        return { name, id, start, end };
      }

      return undefined;
    }),
  );
};
