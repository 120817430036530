import type { FC, HTMLAttributes } from 'react';
import React from 'react';
import styled, { css } from 'styled-components';
import type { ThemeProvider } from 'venn-ui-kit';
import { ColorUtils, GetColor, Loading, ZIndex } from 'venn-ui-kit';

const Container = styled.div`
  position: relative;
`;

interface OverlayProps {
  center: boolean;
  baseBackgroundColor: ThemeProvider;
}

const Overlay = styled.div<OverlayProps>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${({ baseBackgroundColor }) => ColorUtils.hex2rgbaFrom(baseBackgroundColor, 0.7)};
  z-index: ${ZIndex.Cover};

  ${(props) =>
    props.center
      ? css`
          display: flex;
          align-items: center;
        `
      : ''};

  div {
    z-index: ${ZIndex.Cover};
  }
`;

const Content = styled.div`
  z-index: ${ZIndex.Base};
`;

const DEFAULT_OVERLAY = <Loading />;

export interface ConditionalOverlayProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  condition: boolean;
  center?: boolean;
  contentClassName?: string;
  overlay?: JSX.Element[] | JSX.Element | string;
  /** The background color of the component where the overlay is being placed. Defaults to white. */
  baseBackgroundColor?: ThemeProvider;
}

const ConditionalOverlay: FC<React.PropsWithChildren<ConditionalOverlayProps>> = ({
  children,
  className,
  condition,
  contentClassName,
  center = false,
  overlay = DEFAULT_OVERLAY,
  baseBackgroundColor = GetColor.White,
}: ConditionalOverlayProps) => (
  <Container className={className}>
    {condition && (
      <Overlay center={center} baseBackgroundColor={baseBackgroundColor}>
        {overlay}
      </Overlay>
    )}

    <Content className={contentClassName}>{children}</Content>
  </Container>
);

export default ConditionalOverlay;
