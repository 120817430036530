import React from 'react';
import styled from 'styled-components';
import { Label, ZIndex } from 'venn-ui-kit';
import { SpecialCssClasses } from 'venn-utils';
import { SmallToggle } from '../../../toggle';

export interface ShowCorrelationValuesToggleProps {
  hidden: boolean;
  showValues?: boolean;
  onToggle: () => void;
}

const ShowCorrelationValuesToggle = ({ hidden, onToggle, showValues }: ShowCorrelationValuesToggleProps) =>
  hidden ? null : (
    <Wrapper className={SpecialCssClasses.NotDownloadable}>
      <Label>Show values:</Label>
      <SmallToggle toggled={showValues} onToggle={onToggle} />
    </Wrapper>
  );

export default ShowCorrelationValuesToggle;

const Wrapper = styled.div`
  position: absolute;
  top: 111px;
  z-index: ${ZIndex.Front};
  display: flex;

  > label {
    padding: 4px 8px 4px 0;
    display: inline-block;
  }

  @media print {
    display: none;
  }
`;
