import Carousel from 'react-bootstrap/Carousel';
import styled from 'styled-components';
import { GetColor } from '../../style/color';
import { ZIndex } from '../../zIndexValues';

const ARROW_WIDTH_PX = 30;
const ARROWS_DISTANCE_PX = 10;
const ARROW_NEXT_RIGHT_MARGIN_PX = 35;

const StyledCarousel = styled(Carousel)`
  position: relative;
  .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
  }

  .carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    backface-visibility: hidden;
    transition: transform 0.6s ease-in-out;
  }

  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none;
    }
  }

  .carousel-item.active,
  .carousel-item-next,
  .carousel-item-prev {
    display: block;
  }

  .carousel-item-next:not(.carousel-item-left),
  .active.carousel-item-right {
    transform: translateX(100%);
  }

  .carousel-item-prev:not(.carousel-item-right),
  .active.carousel-item-left {
    transform: translateX(-100%);
  }

  .carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    -webkit-transform: none;
    transform: none;
  }

  .carousel-fade .carousel-item.active,
  .carousel-fade .carousel-item-next.carousel-item-left,
  .carousel-fade .carousel-item-prev.carousel-item-right {
    z-index: 1;
    opacity: 1;
  }

  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    z-index: 0;
    opacity: 0;
    transition: opacity 0s 0.6s;
  }

  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none;
    }
  }

  .carousel-control-prev,
  .carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: ${ZIndex.Front};
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${ARROW_WIDTH_PX}px;
    color: ${GetColor.White};
    text-align: center;
    opacity: 0.5;
    transition: opacity 0.15s ease;
    font-size: 30px;
  }
  .carousel-control-prev {
    right: ${ARROW_WIDTH_PX + ARROW_NEXT_RIGHT_MARGIN_PX + ARROWS_DISTANCE_PX}px;
  }
  .carousel-control-next {
    right: ${ARROW_NEXT_RIGHT_MARGIN_PX}px;
  }
  .carousel-control-prev:hover,
  .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: ${GetColor.White};
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
  }

  .carousel-control-prev-icon {
    &::before {
      font-family: 'Font Awesome 5 Pro';
      content: '\\f0a8';
    }
  }
  .carousel-control-next-icon {
    right: 0;
    &::before {
      font-family: 'Font Awesome 5 Pro';
      content: '\\f0a9';
    }
  }

  .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: ${ZIndex.Cover};
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
  }

  .carousel-indicators li {
    box-sizing: content-box;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: ${GetColor.White};
    background-clip: padding-box;
    opacity: 0.5;
    transition: opacity 0.6s ease;
  }

  .carousel-indicators .active {
    opacity: 1;
  }

  .carousel-caption {
    position: absolute;
    right: 13%;
    top: 0;
    left: 3%;
    z-index: ${ZIndex.Cover};
    height: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    color: ${GetColor.White};
    text-align: center;
  }
`;

export default StyledCarousel;
