import { uniq } from 'lodash';
import type { AtomEffect, DefaultValue } from 'recoil';
import { assertExhaustive, logExceptionIntoSentry } from 'venn-utils';

/**
 * Creates an atom effect that validates some condition as part of setting a recoil atom's state to a new value.
 *
 * devAssert mode, the default mode, is a no-op at runtime for production builds, so it has zero performance impact and can be used for expensive assertions.
 */
export const validateSetEffect =
  <T>(
    validator: (newValue: T, oldValue: T | DefaultValue) => string | undefined | null,
    mode: 'assert' | 'devAssert' | 'sentry' = 'devAssert',
  ) =>
  ({ onSet, node }: Pick<Parameters<AtomEffect<T>>[0], 'onSet' | 'node'>) => {
    if (mode === 'devAssert' && process.env.ACTUAL_ENV === 'production') {
      return;
    }

    onSet((newValue, oldValue) => {
      if (oldValue === newValue) {
        return;
      }

      const validationError = validator(newValue, oldValue);
      if (!validationError) {
        return;
      }

      const message = `Recoil validatorEffect failed for key: ${node.key}.\nOldValue: ${oldValue}.\nNewValue: ${newValue}.\nValidator message: ${validationError}\n`;
      switch (mode) {
        case 'devAssert':
        case 'assert':
          throw new Error(message);
        case 'sentry':
          logExceptionIntoSentry(message);
          break;
        default:
          throw assertExhaustive(mode);
      }
    });
  };

/** Pre-made effect that will check the array for duplicates as a development-mode assertion. */
export const noDuplicatesValidatorEffect = validateSetEffect(<T>(newValue: T[]) =>
  uniq(newValue).length !== newValue.length ? 'duplicates detected' : '',
);

/**
 * Pre-made effect that will check that the key used with an atomFamily is truthy (e.g. not empty string, undefined, null, 0) as a
 * dev-mode assertion on both set and get.
 *
 * TODO(collin.irwin): implement the get assertion
 *
 * @example
 * Valid usage of the effect
 * ```
 * export const foo = atomFamily<string, InputId>({
 *   key: 'foo',
 *   effects: (key) => [truthyKeyValidatorEffect(key)],
 *  });
 * ```
 *
 * @example
 * Invalid usage, do not do this, it will not work as intended.
 * ```
 * export const foo = atomFamily<string, InputId>({
 *   key: 'foo',
 *   effects: [truthyKeyValidatorEffect],
 *  });
 * ```
 */
export const truthyKeyValidatorEffect = <K, V>(itemKey: K) =>
  validateSetEffect<V>(() => (itemKey ? '' : `falsey item key used ${itemKey}`));

/** Pre-made effect that will check the array's length is not greater than the provided length. */
export const maxLengthValidatorEffect = (maxLength: number) =>
  validateSetEffect(<T>(newValue: T[]) => (newValue.length > maxLength ? `max length of ${maxLength} exceeded` : ''));
