import type { CustomRow } from '../types';
import { formatData } from '../../data-grid';
import {
  RESIDUAL_LABEL,
  RISK_FREE_RATE_LABEL,
  BOLD_CLASS,
  TOTAL_LABEL,
  TOTAL_LABEL_CUMULATIVE,
  ITALIC_CLASS,
  RIGHT_ALIGN_CLASS,
  PERCENTAGE_CLASS,
  CURRENCY_CLASS_PREFIX,
} from '../customAnalysisContants';
import { compact } from 'lodash';
import type { CellClassParams, ValueFormatterParams } from 'ag-grid-community';
import { assertExhaustive, type TabularDataTypeEnum } from 'venn-utils';

const isNumericData = (dataType: TabularDataTypeEnum | undefined) => {
  switch (dataType) {
    case 'PERCENTAGE':
    case 'NUMERIC':
    case 'CURRENCY':
      return true;
    case 'TEXT':
    case undefined:
      return false;
    default:
      return assertExhaustive(dataType);
  }
};

export const getCellClassForDataType = (dataType: TabularDataTypeEnum | undefined) => {
  switch (dataType) {
    case 'TEXT':
      return 'TEXT';
    case 'NUMERIC':
      return 'NUMERIC';
    case 'PERCENTAGE':
      return PERCENTAGE_CLASS;
    case 'CURRENCY':
      return `${CURRENCY_CLASS_PREFIX}$`;
    case undefined:
      return '';
    default:
      return assertExhaustive(dataType);
  }
};

const getType = ({ data, colDef }: Pick<ValueFormatterParams, 'data' | 'colDef'>): TabularDataTypeEnum | undefined =>
  (data && 'type' in data && data.type) || colDef?.cellRendererParams?.type;

/** Selected such that 0.005% (which is 0.00005) can be shown as rounded 0.01% while 0.0049% which rounds to 0.00% is shown as -- */
const EPSILON = 0.00005;

/** Properly formats percentages, numbers, nil values, and strings. */
export const getDefaultValueFormat = ({
  value,
  data,
  colDef,
}: Pick<ValueFormatterParams, 'value' | 'data' | 'colDef'>) => {
  const type = getType({ data, colDef });
  let displayValue = value;
  if (colDef?.cellRendererParams?.formatZeroAsUndefined) {
    displayValue = isNumericData(type) && Math.abs(value) < EPSILON ? undefined : value;
  }
  return isNumericData(type) ? formatData(displayValue, type) : displayValue;
};

/**
 * Cell classes for data types, bold and italics, and excel export styling.
 *
 * TODO(collin.irwin): add getDefaultCellClass to the defaultColDef in columnUtils
 */
export const getDefaultCellClass = <V, T extends CustomRow<V>>({ data, colDef }: CellClassParams<T>): string[] =>
  compact([
    (data && 'type' in data && data.type) || getCellClassForDataType(colDef?.cellRendererParams?.type),
    data && 'label' in data && getSpecialClass(data.label),
    getSpecialClass(colDef.headerName),
    RIGHT_ALIGN_CLASS,
  ]);

export const getSpecialClass = (label?: string) =>
  label === TOTAL_LABEL || label === TOTAL_LABEL_CUMULATIVE
    ? BOLD_CLASS
    : label === RESIDUAL_LABEL || label === RISK_FREE_RATE_LABEL
      ? ITALIC_CLASS
      : undefined;
