import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';

const BenchmarksHeader = styled.div`
  h1 {
    margin: 0;
    font-size: 32px;
    font-weight: 300;
    color: ${GetColor.Black};
  }

  h2 {
    margin: 10px 20px 20px;
    line-height: 15px;
    font-size: 12px;
    font-weight: bold;
    color: ${GetColor.HintGrey};
  }

  h2 strong {
    margin-left: 3px;
    line-height: 15px;
    font-size: 14px;
    font-weight: normal;
    color: ${GetColor.Black};
  }
`;

export default BenchmarksHeader;
