import type { FormFields } from 'venn-components';
import { compact } from 'lodash';

export const getFormErrorMessage = (
  fields: FormFields,
  fieldLabels: Record<string, string>,
  missingCheckbox?: boolean,
) => {
  const fieldsWithErrors = compact(
    Object.keys(fields)
      .filter((name) => fields[name].hasError)
      .map((name) => fieldLabels[name]),
  );
  const numErrors = fieldsWithErrors.length;
  if (!numErrors) {
    return missingCheckbox ? 'Please accept the terms above to continue.' : '';
  }
  let fieldNames = fieldsWithErrors[0];
  if (numErrors > 1) {
    const lastField = fieldsWithErrors[numErrors - 1];
    fieldNames = `${fieldsWithErrors.slice(0, numErrors - 1).join(', ')} and ${lastField}`;
  }
  return `Make sure ${fieldNames} ${numErrors === 1 ? 'is' : 'are'} filled out correctly.`;
};
