import type { CSSProperties } from 'react';
import React from 'react';
import { GetColor } from '../../../style/color';
import Icon from '../../icon/Icon';
import styled from 'styled-components';

interface ExpanderProps {
  expanded: boolean;
  toggleExpanded: (isExpanded: boolean) => void;
  style?: CSSProperties;
  expandedLabel?: string;
  collapsedLabel?: string;
  icon?: string;
}

const Expander = ({
  expanded,
  toggleExpanded,
  style,
  expandedLabel = 'View Less',
  collapsedLabel = 'View All',
  icon = 'caret',
}: ExpanderProps) => (
  <StyledExpander onClick={() => toggleExpanded(!expanded)} style={style}>
    {expanded ? (
      <>
        {expandedLabel} <Icon type={`${icon}-up`} />
      </>
    ) : (
      <>
        {collapsedLabel} <Icon type={`${icon}-down`} />
      </>
    )}
  </StyledExpander>
);

export default Expander;

const StyledExpander = styled.div`
  font-size: 10px;
  font-weight: bold;
  padding-left: 20px;
  padding-top: 5px;
  color: ${GetColor.Primary.Dark};
  cursor: pointer;
`;
