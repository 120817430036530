export const STUDIO_DEFAULT_USER_DISCLAIMERS = (organizationDisplayName?: string) =>
  `This report is for use with Accredited Investors only. This report includes hypothetical investment performance and certain metrics derived from inputs about your investment objectives and potential investment options provided by your ${organizationDisplayName} Financial advisor.  The inputs could represent your current holdings as of the date of this report or solely components selected by your ${organizationDisplayName} financial advisor. In any case, the ultimate statistics do not necessarily represent your current portfolio or any actual portfolio existing during the relevant period.  Hypothetical returns are calculated and presented net of Model Advisory Fees specified and inputted by the preparer of this report and such hypothetical net returns are used in calculating the metrics that are part of where fees are indicated in a field adjacent to or within the relevant visualization. Metrics not so designated do not consider performance in their calculation. Please see the section of this report titled "Certain Disclosures Concerning Net of Fee Calculations" for additional information. Past or hypothetical performance is no guarantee of future results. Hypothetical performance is subject to significant limitations and should not be the basis for an investment decision. Please see the section of this report titled "Certain Disclosures Concerning the Use of Hypothetical Performance Calculations" for additional information.  To the extent there are charts analyzing the hypothetical performance of your portfolio, you should review the disclosure titled "Hypothetical Performance Disclosure" at the end of this report.
The funds or indices used for comparison have been chosen to represent a mix of investment options with different investment strategies, however, these funds and/or indices should not be viewed as necessarily comprising a comparable mix of underlying instruments as the hypothetical portfolio. An investment cannot be made directly in an index. Indices are not managed and do not charge fees or expenses.`;

export const STUDIO_DEFAULT_DISCLAIMER_FONT_SIZE = 6;
export const STUDIO_DEFAULT_DISCLOSURE_FONT_SIZE = 7.5;
export const STUDIO_COVER_PAGE_DISCLOSURE_DEFAULT_FONT_SIZE = 8;

export const STUDIO_COVER_PAGE_DEFAULT_DISCLOSURE =
  'Disclosure. This report is for use with Accredited Investors only.  This report contains hypothetical performance information. Hypothetical performance is inherently limited and should not be relied upon as a basis for your investment decision-making.  Please see the section of this report titled "Important Disclosure and Disclaimer Information" for further information.';

export const STUDIO_DISCLAIMER_DISCLOSURE_FONT_SIZE_MIN = 5;
export const STUDIO_DISCLAIMER_DISCLOSURE_FONT_SIZE_MAX = 12;
export const STUDIO_DISCLAIMER_DISCLOSURE_FONT_SIZE_PRECISION = 1;
