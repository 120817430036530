import { timePeriodToRange } from 'venn-utils';
import type { ComputableMetricEnum, MetricFilter, TimePeriodEnum } from 'venn-api';
import { getMetricLabel } from '../filters';
import { uniqWith, isEqual } from 'lodash';

export interface PeriodMetric {
  timePeriod: TimePeriodEnum;
  metric: ComputableMetricEnum;
}

export const getPeriodMetrics = ({ metricFilters }: { metricFilters: MetricFilter[] }): PeriodMetric[] =>
  uniqWith(
    metricFilters.map((m) => ({ timePeriod: m.timePeriod, metric: m.metric })),
    isEqual,
  ) // these 3 metrics already in the default columns
    .filter(
      (p) =>
        p.timePeriod !== 'FULL' || !['ANNUALIZED_RETURN', 'ANNUALIZED_VOLATILITY', 'SHARPE_RATIO'].includes(p.metric),
    );

export const toPath = ({ periodMetric }: { periodMetric: PeriodMetric }) => {
  const { timePeriod, metric } = periodMetric;
  return `${timePeriod}.${metric}`;
};

const shortenTerms = (str: string) => {
  return str
    .replace(/\bcontribution to\b/i, 'Cto.')
    .replace(/\bcumulative\b/i, 'Cum.')
    .replace(/\bannualized\b/i, 'Ann.');
};

export const toColumnName = ({ periodMetric }: { periodMetric: PeriodMetric }) => {
  const { timePeriod, metric } = periodMetric;
  return `${shortenTerms(getMetricLabel(metric))} (${timePeriodToRange(timePeriod)})`;
};
