import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import type { AnalysisView, AnalysisViewSearchResult } from 'venn-api';
import type { VennColors } from 'venn-ui-kit';
import { Icon, GetColor, ColorUtils, getAppTitle } from 'venn-ui-kit';
import { analyticsService, isReport, navigateToReportLab, navigateToStudioView } from 'venn-utils';

const CircleIcon = ({ iconType, color }: { iconType: string; color: string }) => {
  return <StyledCircleIcon type={iconType} color={color} />;
};

const CardButton = ({ onClick, label }: { onClick: () => void; label: string }) => (
  <StartAnalysisButton onClick={onClick}>
    <Icon type="plus-circle" prefix="far" />
    <span>{label}</span>
  </StartAnalysisButton>
);

interface StudioCardProps {
  item: AnalysisViewSearchResult;
  /** Analytics tracking source */
  location: string;
}

const StudioCard = ({ item, location }: StudioCardProps) => {
  const { Colors } = useContext(ThemeContext);
  const { name, description, systemTemplate, analysisViewType, id } = item;
  const history = useHistory();
  const onClick = () => {
    const isReportView = isReport(analysisViewType);
    const document = { id, analysisViewType: isReportView ? 'REPORT' : 'TEARSHEET' } as AnalysisView;

    analyticsService.creatingNewStudios({
      source: location,
      type: 'template',
      name,
    });

    if (isReportView) {
      navigateToReportLab(history, { newDocument: document, id });
    } else {
      navigateToStudioView(history, { newDocument: document, id });
    }
  };

  const { iconType, iconColor } = getSystemTemplateIcons(Colors, systemTemplate);

  return (
    <CardWrapper>
      <BodyWrapper>
        <CardHeader>
          <CircleIcon iconType={iconType} color={iconColor} />
          {name}
        </CardHeader>

        <CardContent>{description}</CardContent>
      </BodyWrapper>
      <CardFooter>
        <CardButton onClick={onClick} label="Start Analysis" />
      </CardFooter>
    </CardWrapper>
  );
};

export default StudioCard;

const getSystemTemplateIcons = (Colors: VennColors, systemTemplate?: string) => {
  switch (systemTemplate) {
    case `${getAppTitle()} Quick Insights`:
      return {
        iconType: 'file-spreadsheet',
        iconColor: Colors.DEPRECATED_DataBarColor.LightPink,
      };
    case 'Portfolio Performance':
      return {
        iconType: 'chart-line',
        iconColor: Colors.DEPRECATED_DataBarColor.LightPaleBlue,
      };
    case 'Fund Comparison':
      return {
        iconType: 'greater-than-equal',
        iconColor: Colors.DEPRECATED_DataBarColor.LightPaleGold,
      };

    default:
      return {
        iconType: 'file-spreadsheet',
        iconColor: Colors.DEPRECATED_DataBarColor.LightPink,
      };
  }
};

export const CardWrapper = styled.div`
  min-width: 300px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px;
  border-top: 1px solid ${GetColor.GreyScale.Grey30};
`;

const CardHeader = styled.div`
  color: ${ColorUtils.hex2rgbaFrom(GetColor.Black, 0.8)};
  font-weight: bold;
`;

const CardContent = styled.div`
  line-height: 24px;
  font-size: 14px;
  color: ${ColorUtils.hex2rgbaFrom(GetColor.DarkGrey, 0.8)};
`;

const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BodyWrapper = styled.div`
  min-height: 162px;
`;

const StartAnalysisButton = styled.button`
  text-transform: uppercase;
  border: 1px solid ${GetColor.GreyScale.Grey30};
  padding: 8px 20px;
  font-weight: bold;
  font-size: 11px;

  & i {
    font-size: 10px;
    padding-right: 5px;
  }
`;

const StyledCircleIcon = styled(Icon)<{ color: string }>`
  font-size: 20px;
  background-color: ${({ color }) => color};
  height: 35px;
  width: 35px;
  text-align: center;
  line-height: 35px;
  border-radius: 20px;
  margin-right: 10px;
`;
