import React, { useContext } from 'react';
import { RequiredLabel } from 'venn-components';
import { GetColor, Icon, Label, Tooltip } from 'venn-ui-kit';
import styled, { ThemeContext } from 'styled-components';

interface PrintSettingProps {
  title: string;
  label: string;
  labelTooltip: string;
  fontTooltip: string;
  settingContent: string;
  settingContentOnChange: (newValue?: string) => void;
  fontSize: number;
  fontSizeOnChange: (newSize?: number) => void;
  isReadOnly: boolean;
}

const ResetButton = ({ disabled, onClick }: { disabled?: boolean; onClick?: () => void }) => {
  const theme = useContext(ThemeContext);
  const style = disabled ? { color: theme.Colors.Grey } : undefined;
  return (
    <Tooltip content="Reset to defaults">
      <Icon type="arrows-rotate" style={style} onClick={disabled ? undefined : onClick} />
    </Tooltip>
  );
};

export const PrintSetting = ({
  title,
  label,
  labelTooltip,
  settingContent,
  settingContentOnChange,
  isReadOnly,
}: PrintSettingProps) => {
  return (
    <Wrapper>
      <Label>{title}</Label>
      <Grid>
        <div>
          <RequiredLabel>{`${label}: `}</RequiredLabel>
          <Tooltip maxWidth={300} content={labelTooltip}>
            <Icon type="question-circle" />
          </Tooltip>{' '}
          <ResetButton disabled={isReadOnly} onClick={() => settingContentOnChange(undefined)} />
        </div>
        <TextArea
          value={settingContent}
          onChange={(event) => {
            settingContentOnChange(event.target.value);
          }}
          disabled={isReadOnly}
        />
      </Grid>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

const Grid = styled.div`
  width: 100%;
  display: grid;
  column-gap: 4px;
`;

const TextArea = styled.textarea`
  display: flex;
  border-radius: 4px;
  border: 1px solid ${GetColor.Grey};
  font-size: 13px;
  font-weight: normal;
  height: 100px;
  width: 100%;
  resize: vertical;
`;
