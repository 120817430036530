import React from 'react';
import styled, { css, withTheme } from 'styled-components';
import type { Theme } from 'venn-ui-kit';
import { GetColor, TooltipPosition } from 'venn-ui-kit';
import { Constants } from './Layout';
import AllocationDifferenceTooltip from './AllocationDifferenceTooltip';
import KeyBlockingNumericInput from '../key-blocking-numeric-input/KeyBlockingNumericInput';
import { checkHasQuantDiff } from './AllocationUtils';
import { analyticsService, formatAllocation } from 'venn-utils';

export const IncreasedSpecificityGhostFundWrapperClass = 'allocation-input-value-wrapper';
export const GhostFundAllocationInputClass = 'ghost-fund-allocation-input';
export const GhostFundAllocationValueClass = 'ghost-fund-allocation-value';
const InvalidRootValue = 'invalid-root-value';

export interface ItemPercentageProps {
  isPercentageMode: boolean;
  // For percentage calculation
  baseAllocation: number;
  // For calculate delta
  orignalBaseAllocation: number;
}

interface ItemAllocationProps extends ItemPercentageProps {
  value?: number;
  originalValue?: number;
  isGhost: boolean;
  isStrategy: boolean;
  isRoot: boolean;
  isModified: boolean;
  isOutsideOfSelectedSubtree: boolean;
  onUpdateAllocation: (value: number) => void;
  usePortalForTooltip?: boolean;
  theme: Theme;
  className?: string;
}

interface ItemAllocationState {
  currentValue?: string;
  isEditing: boolean;
  isFocusedGhostInput: boolean;
}

class ItemAllocation extends React.PureComponent<ItemAllocationProps, ItemAllocationState> {
  state: ItemAllocationState = {
    currentValue: undefined,
    isEditing: false,
    isFocusedGhostInput: false,
  };

  static getDerivedStateFromProps(nextProps: ItemAllocationProps, prevState: ItemAllocationState) {
    const { isPercentageMode, baseAllocation } = nextProps;
    if (prevState.currentValue === undefined || !prevState.isEditing) {
      const updatedValue = isPercentageMode ? ((nextProps.value ?? 0) * 100) / baseAllocation : nextProps.value;
      return {
        currentValue:
          nextProps.value !== null &&
          nextProps.value !== undefined &&
          updatedValue !== null &&
          updatedValue !== undefined
            ? updatedValue.toString()
            : '0.0',
      };
    }
    return null;
  }

  onChange = (value: string) => {
    this.setState({
      currentValue: value,
      isEditing: true,
    });
  };

  onFocus = () => {
    const { isStrategy, isGhost } = this.props;
    if (isGhost && !isStrategy) {
      this.setState({ isFocusedGhostInput: true });
    }
  };

  onBlur = () => {
    const { value, onUpdateAllocation, isGhost, isStrategy, isPercentageMode, baseAllocation } = this.props;
    const { currentValue } = this.state;
    this.setState({
      isEditing: false,
    });

    const numericValue = parseNumber(currentValue) || 0;
    const isAddingGhostFund = isGhost && !isStrategy;
    const shouldUpdate = isAddingGhostFund
      ? numericValue !== 0
      : isPercentageMode
        ? numericValue !== ((value ?? 0) * 100) / baseAllocation
        : numericValue !== value;

    if (shouldUpdate) {
      const updatedValue = isPercentageMode ? (numericValue * baseAllocation) / 100 : numericValue;
      analyticsService.textFieldUpdated({
        locationOnPage: 'Allocator Panel',
      });
      onUpdateAllocation(updatedValue);
    }

    if (isAddingGhostFund) {
      this.setState({ isFocusedGhostInput: false });
    }
  };

  render() {
    const {
      value,
      originalValue,
      isModified,
      isRoot,
      isGhost,
      isStrategy,
      isOutsideOfSelectedSubtree,
      isPercentageMode,
      baseAllocation,
      orignalBaseAllocation,
      usePortalForTooltip,
      theme: { Colors },
      className,
    } = this.props;
    const { isEditing, currentValue, isFocusedGhostInput } = this.state;

    const canAddFundFromGhost = !isStrategy && isGhost && !isOutsideOfSelectedSubtree;
    const isNonGhostFundWithinSelectedSubtree = !isStrategy && !isGhost && !isOutsideOfSelectedSubtree;
    const allocation =
      value !== null && value !== undefined ? (isPercentageMode ? (value * 100) / baseAllocation : value) : 0;
    const invalidRoot = isPercentageMode && isRoot && checkHasQuantDiff((value ?? 0) / baseAllocation, 1, 1000);
    const difference = isPercentageMode
      ? ((value || 0) * 100) / baseAllocation - ((originalValue || 0) * 100) / (orignalBaseAllocation || 1)
      : (value || 0) - (originalValue || 0);
    return (
      <AllocationDifferenceTooltip
        className={className}
        usePortal={usePortalForTooltip}
        hasDifference={isModified && !isEditing}
        value={allocation}
        difference={difference}
        position={isRoot ? TooltipPosition.Bottom : TooltipPosition.Top}
        isPercentageMode={isPercentageMode}
        hidden={isEditing}
      >
        <ValueWrapper
          className={canAddFundFromGhost ? IncreasedSpecificityGhostFundWrapperClass : 'item-allocation-value-wrapper'}
          isRoot={isRoot}
          isStrategy={isStrategy}
          isModified={isModified && !isGhost}
          isFocusedGhostInput={isFocusedGhostInput}
          isGreyedOut={isOutsideOfSelectedSubtree || isGhost}
        >
          {(canAddFundFromGhost || isNonGhostFundWithinSelectedSubtree) && (
            <KeyBlockingNumericInput
              className={canAddFundFromGhost ? GhostFundAllocationInputClass : 'key-blocking-numeric-input'}
              isLocked={isGhost && isStrategy}
              value={currentValue}
              onChange={this.onChange}
              onFocus={this.onFocus}
              onCommitInput={this.onBlur}
              isPercentage={isPercentageMode}
              height={20}
              defaultBorderColor={isModified ? Colors.HighlightDark : Colors.Grey}
              textColor={isModified ? Colors.HighlightDark : Colors.Black}
              allowNegative={false}
            />
          )}
          {(isGhost || isStrategy || isOutsideOfSelectedSubtree) && (
            <Value
              className={
                !isStrategy && isGhost ? GhostFundAllocationValueClass : invalidRoot ? InvalidRootValue : undefined
              }
            >
              {value !== null && value !== undefined
                ? formatAllocation(value, isRoot, isPercentageMode, baseAllocation)
                : '--'}
            </Value>
          )}
        </ValueWrapper>
      </AllocationDifferenceTooltip>
    );
  }
}

// @ts-expect-error: TODO fix strictFunctionTypes
export default withTheme(ItemAllocation);

function getTextColor(isModified: boolean, isGreyedOut: boolean) {
  if (isModified) {
    return isGreyedOut ? GetColor.HighlightLight : GetColor.HighlightDark;
  }
  return isGreyedOut ? GetColor.Grey : GetColor.Black;
}

function parseNumber(value?: string): number | undefined {
  if (value === null || value === undefined) {
    return undefined;
  }
  const numeric = parseFloat(value.replace(',', '.'));
  return Number.isNaN(numeric) ? undefined : numeric;
}

export const ValueWrapper = styled.div<{
  isRoot: boolean;
  isStrategy: boolean;
  isModified: boolean;
  isFocusedGhostInput: boolean;
  isGreyedOut: boolean;
}>`
  ${({ isRoot }) => css`
    font-size: ${isRoot ? 16 : 14}px;
  `}
  ${({ isModified, isGreyedOut }) => css`
    color: ${getTextColor(isModified, isGreyedOut)};
  `}
  ${({ isStrategy }) =>
    isStrategy &&
    css`
      font-weight: bold;
    `}
  width: ${Constants.ALLOCATION_WIDTH}px;
  min-width: ${Constants.ALLOCATION_WIDTH}px;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 5px;
  padding-left: 5px;
  ${({ isFocusedGhostInput }) => `
    .${GhostFundAllocationInputClass} {
      display: ${isFocusedGhostInput ? 'block' : 'none'};
    }
    .${GhostFundAllocationValueClass} {
      display: ${isFocusedGhostInput ? 'none' : 'block'};
    }
  `}
`;

export const Value = styled.div`
  padding-right: 5px;
  &.invalid-root-value {
    color: ${GetColor.Error};
    font-style: italic;
  }
`;
