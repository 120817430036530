import React from 'react';
import styled from 'styled-components';
import type { DataCell } from '../../types';
import { convertToLetters } from '../utils';
import { GetColor } from 'venn-ui-kit';

interface ErrorEditorProps {
  /**
   * Cell to edit
   */
  cell: DataCell;
  date: string;
  valid?: boolean;
  /**
   * Returns the updated value
   */
  onChange: (value: string) => void;
  onBlur: () => void;
}

const ErrorEditor = ({ cell, valid, onBlur, onChange, date }: ErrorEditorProps) => (
  <Table valid={!!valid}>
    <thead>
      <tr>
        <th />
        <th />
        <th>{convertToLetters(cell.column)}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th>{cell.row !== undefined ? cell.row + 1 : ''}</th>
        <td>{date}</td>
        <td>
          <EditableCell valid={!!valid}>
            <input
              value={cell.value ?? ''}
              placeholder={cell.value === '' ? '--' : undefined}
              onChange={(value) => {
                if (onChange) {
                  onChange(value.target.value);
                }
              }}
              onBlur={onBlur}
            />
          </EditableCell>
        </td>
      </tr>
    </tbody>
  </Table>
);

interface TableProps {
  valid: boolean;
}

const Table = styled.table<TableProps>`
  font-size: 10px;
  border: 1px solid ${GetColor.Grey};
  border-collapse: collapse;

  tr {
    height: 18px;
  }

  /* First column */
  th:first-child {
    width: 46px;
  }

  /* Any header cell */
  th {
    width: 140px;
    font-weight: bold;
    color: ${(props) => (props.valid ? GetColor.Black : GetColor.Error)};
  }

  /* All cells */
  td,
  th {
    border: 1px solid ${GetColor.Grey};
  }

  /* All data cells  */
  td {
    text-align: right;
    padding-right: 8px;
  }

  /* Input cell  */
  td:nth-child(3) {
    color: ${(props) => (props.valid ? GetColor.Primary.Dark : GetColor.Error)};
    border: 1px solid ${(props) => (props.valid ? GetColor.Primary.Main : GetColor.Error)};
  }

  thead {
    th:nth-child(3) {
      border-bottom: none;
    }
  }

  tbody {
    td:nth-child(2) {
      border-right: none;
    }
  }
`;

const EditableCell = styled.div<TableProps>`
  input {
    color: ${(props) => (props.valid ? GetColor.Primary.Dark : GetColor.Error)};
    width: 100%;
    border: none;
    height: 16px;
    text-align: right;
  }
`;

export default ErrorEditor;
