import type { Ref } from 'react';
import React, { useContext } from 'react';
import Select from 'react-select';
import type { SelectComponentsProps } from 'react-select/lib/Select';
import { getSelectStylesV2, getSelectTheme } from './getSelectStyles';
import { ThemeContext } from 'styled-components';

/**
 * A prestyled version of react-select https://github.com/JedWatson/react-select
 * @param props
 * @constructor
 */
const VennSelect: React.FunctionComponent<
  React.PropsWithChildren<
    SelectComponentsProps & {
      darkPlaceholder?: boolean;
      maxHeight?: number;
      refreshedStyling?: boolean;
    }
  >
> = React.forwardRef(function VennSelect(props, ref: Ref<Select>) {
  const { Colors } = useContext(ThemeContext);
  return (
    <Select
      ref={ref}
      theme={getSelectTheme(Colors)}
      styles={getSelectStylesV2(Colors, props.darkPlaceholder, props.maxHeight, props.refreshedStyling)}
      {...props}
    />
  );
});

export default VennSelect;
