import { useCallback, useEffect, useState } from 'react';
import type { AdvancedQueryRow } from '../types';
import {
  getValidatedQuery,
  isValidAdvancedQuery,
  toAdvancedQueryValue,
  toInitialAdvancedQueryRows,
} from './advancedQueryUtils';
import { isEqual, omit } from 'lodash';
import type { AdvancedQuery } from 'venn-state';

const useAdvancedQueries = (initialAdvancedQueries: AdvancedQuery[]) => {
  const [advancedQueriesState, setAdvancedQueriesState] = useState<AdvancedQueryRow[]>(
    toInitialAdvancedQueryRows(initialAdvancedQueries),
  );

  const resetAdvancedQueriesState = useCallback(() => {
    if (
      !isEqual(
        advancedQueriesState.map((row) => omit(row, 'key')),
        initialAdvancedQueries,
      )
    ) {
      setAdvancedQueriesState(toInitialAdvancedQueryRows(initialAdvancedQueries));
    }
  }, [initialAdvancedQueries, advancedQueriesState]);

  useEffect(() => {
    resetAdvancedQueriesState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialAdvancedQueries]);

  const addQuery = useCallback(() => {
    setAdvancedQueriesState((prev) => [...prev.map(getValidatedQuery), { key: prev.length }]);
  }, []);

  const removeQuery = useCallback((keyToRemove: string | number) => {
    setAdvancedQueriesState((prev) => prev.filter(({ key }) => key !== keyToRemove));
  }, []);

  const updateQuery = useCallback((updatedQuery: AdvancedQueryRow) => {
    setAdvancedQueriesState((prev) =>
      prev.map((prevQuery) => (prevQuery.key === updatedQuery.key ? getValidatedQuery(updatedQuery) : prevQuery)),
    );
  }, []);

  const getValidAdvancedQueries = useCallback(() => {
    return advancedQueriesState
      .filter(isValidAdvancedQuery)
      .map((query) => ({ ...query, value: toAdvancedQueryValue(query.value!) }) as AdvancedQuery);
  }, [advancedQueriesState]);

  return {
    resetAdvancedQueriesState,
    advancedQueriesState,
    addQuery,
    removeQuery,
    updateQuery,
    getValidAdvancedQueries,
  };
};

export default useAdvancedQueries;
