import type { AnalysisViewSortTypeEnum, OrderEnum } from 'venn-api';
import type { ItemType } from 'venn-ui-kit';
import type { AnalysisViewSearchResultWithUIState, SavedViewsQuickFilter } from 'venn-utils';

export interface SearchState {
  results: AnalysisViewSearchResultWithUIState[];
  totalCount: number;
  loading: boolean;
}

export enum SectionKey {
  FILTERS = 'filters',
  ITEM_TYPE = 'itemTypes',
}

export type ConfirmationType = 'DELETE' | 'CANCEL';

export interface Params {
  page?: number;
  name?: string;
  sortBy?: AnalysisViewSortTypeEnum;
  order?: OrderEnum;
  [SectionKey.FILTERS]?: SavedViewsQuickFilter[];
  [SectionKey.ITEM_TYPE]?: ItemType[];
}
