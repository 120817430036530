import { atom } from 'recoil';
import { incrementOnStudioReset, incrementOnWorkspaceConfigurationChange } from '../effects/signalEffects';

/**
 * An id that is added to the query key of studio analysis queries
 * so that it's cache will be invalidated when this atom changes
 */
export const analysisIdState = atom<number>({
  key: 'analysisIdState',
  default: 0,
  effects: [incrementOnStudioReset, incrementOnWorkspaceConfigurationChange],
});
