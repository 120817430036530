const factorDescriptions = [
  {
    description: 'Exposure to the long-term economic growth and profitability of companies',
    id: 10000,
  },
  {
    description: 'Exposure to corporate default and failure-to-pay risks specific to developed market corporate bonds',
    id: 10002,
  },
];
export default factorDescriptions;
