import React from 'react';
import styled from 'styled-components';
import LegalLink from './LegalLink';
import { legalDocLinks, useRecoilValueWithDefault } from 'venn-state';

const StyledLegalLinks = styled.div`
  margin: 0 -90px 10px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
`;

const LegalLinks = () => {
  const links = useRecoilValueWithDefault(legalDocLinks, []);
  return (
    <StyledLegalLinks>
      {links.map((link) => (
        <LegalLink key={link.baseUrl} link={link} />
      ))}
    </StyledLegalLinks>
  );
};

export default LegalLinks;
