import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import type { SearchFund } from 'venn-api';
import { GetColor, Loading, LoadingSize } from 'venn-ui-kit';
import InvestmentsResultTable from './InvestmentsResultTable';
import { ModalFooter } from '../../modal';

export interface InvestmentsSelectorProps {
  loading?: boolean;
  searchResult?: SearchFund[];
  onSubmit: (investments: SearchFund[]) => void;
  onCancel: () => void;
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default ({ searchResult, loading, onSubmit, onCancel }: InvestmentsSelectorProps) => {
  const [selected, setSelected] = useState<SearchFund[]>([]);
  const [previouslySelected, setPreviouslySelected] = useState<SearchFund[]>([]);

  const prevSearchResult = useRef<SearchFund[] | undefined>();

  useEffect(() => {
    if (prevSearchResult.current !== searchResult && selected.length > 0) {
      setPreviouslySelected([...previouslySelected, ...selected]);
      setSelected([]);
    }
  }, [previouslySelected, searchResult, selected]);

  useEffect(() => {
    prevSearchResult.current = searchResult;
  });

  const submit = () => {
    onSubmit([...previouslySelected, ...selected]);
  };

  const isInvestmentSelected = (investment: SearchFund): boolean =>
    selected.some((selectedItem) => selectedItem.id === investment.id);

  const isInvestmentPreviouslySelected = (investment: SearchFund): boolean =>
    previouslySelected.some((selectedItem) => selectedItem.id === investment.id);

  const toggleSelection = (investment: SearchFund) => {
    const isSelected = isInvestmentSelected(investment);
    const isSelectedPreviously = isInvestmentPreviouslySelected(investment);

    if (!isSelected && !isSelectedPreviously) {
      setSelected([...selected, investment]);
      return;
    }

    if (isSelected) {
      setSelected(selected.filter((item: SearchFund) => item.id !== investment.id));
      return;
    }
    if (isSelectedPreviously) {
      setPreviouslySelected(previouslySelected.filter((item: SearchFund) => item.id !== investment.id));
    }
  };

  const selectOrDeselectAll = (investments?: SearchFund[]) => {
    if (investments) {
      const addToSelected = investments.filter(
        (investment) => !isInvestmentSelected(investment) && !isInvestmentPreviouslySelected(investment),
      );
      setSelected([...selected, ...addToSelected]);
    } else {
      setSelected([]);
      setPreviouslySelected([]);
    }
  };

  const disableSubmit = selected.length + previouslySelected.length === 0;
  return (
    <div>
      <div />
      <ResultsContainer>
        <InvestmentsResultTable
          rows={loading ? [...previouslySelected, ...selected] : searchResult || []}
          selected={loading ? [] : selected}
          previouslySelected={loading ? [...previouslySelected, ...selected] : previouslySelected}
          onSelectionToggle={toggleSelection}
          onSelectAllClick={selectOrDeselectAll}
        />
        {loading && (
          <LoadingSpan>
            <Loading size={LoadingSize.medium} />
          </LoadingSpan>
        )}
        {!loading && searchResult && searchResult.length === 0 && (
          <Message>Could not find any investments matching the current search criteria.</Message>
        )}
      </ResultsContainer>
      <ModalFooter
        onCancel={onCancel}
        rightChildren={
          <ResultsCount>{selected.length + previouslySelected.length} investment(s) selected</ResultsCount>
        }
        primaryLabel="Add"
        primaryDisabled={disableSubmit}
        onPrimaryClick={submit}
      />
    </div>
  );
};

const ResultsContainer = styled.div`
  height: 350px;
  overflow-x: hidden;
  overflow-y: scroll;
`;

const ResultsCount = styled.div`
  color: ${GetColor.Black};
`;

const LoadingSpan = styled.div`
  margin-top: 10px;
`;

const Message = styled.div`
  margin-top: 10px;
  padding-left: 35px;
  font-size: 12px;
`;
