import type { RecentFactorPerformance } from 'venn-api';

const singlePerformance: RecentFactorPerformance = {
  id: 355,
  sevenDayReturn: 0.010192429654402702,
  qtdReturn: 0.01046191295512533,
  forecastReturn: 0.07273176001259962,
  mtdReturn: 0,
  ytdCumulativeReturn: [[0, 0]],
  performanceAlert: undefined,
};
const recentFactorPerformance: RecentFactorPerformance[] = [singlePerformance];

export default recentFactorPerformance;
