import React from 'react';
import { Icon } from 'venn-ui-kit';
import styled from 'styled-components';

export interface MenuCaretProps {
  className?: string;
}

const MenuCaret = ({ className }: MenuCaretProps) => {
  return (
    <MenuCaretWrapper className={className}>
      <Icon prefix="fas" type="caret-down" />
    </MenuCaretWrapper>
  );
};

export default MenuCaret;

const MenuCaretWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-style: normal;
  font-size: 18px;
  line-height: 18px;
  margin-left: 12px;
`;
