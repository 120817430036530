import React from 'react';
import { ACCESSING_ANALYSES_FAQ_HREF, GetColor } from 'venn-ui-kit';
import styled from 'styled-components';

const ExportLearnMoreLink = () => (
  <Link href={ACCESSING_ANALYSES_FAQ_HREF} target="_blank" rel="noopener noreferrer" className="qa-export-help">
    Learn more
  </Link>
);

export default ExportLearnMoreLink;

const Link = styled.a`
  text-decoration: none;
  color: ${GetColor.Primary.Dark};
  font-weight: bold;
`;
