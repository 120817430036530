import React from 'react';
import styled from 'styled-components';
import { CURRENCY_FAQ_HREF, GetColor, HyperLink, getTextThemeProvider } from 'venn-ui-kit';
import { FlexRow, Info, StyledIcon, StyledWrapper, FlexWrapper, Name } from './shared';
import type { UserProfileSettings } from 'venn-api';

const OrganizationCurrency = ({ profileSettings }: { profileSettings: UserProfileSettings }) => {
  const { organization } = profileSettings;
  const { currency } = organization;
  return (
    <StyledWrapper>
      <FlexRow>
        <FlexWrapper>
          <StyledIcon type="dollar-sign" />
          <Name>Workspace Currency</Name>
        </FlexWrapper>
        <RightContent>
          <CurrencyBox>
            <Currency className="qa-currency-icon">{currency}</Currency>
          </CurrencyBox>
        </RightContent>
      </FlexRow>
      <Info>
        You are not permitted to change your workspace’s currency. See the{' '}
        <HyperLink
          href={CURRENCY_FAQ_HREF}
          target="_blank"
          rel="noopener noreferrer"
          className="qa-knowledge-base-link"
        >
          knowledge base
        </HyperLink>{' '}
        for more information or <HyperLink href={`mailto:${getTextThemeProvider().supportEmail}`}>contact us</HyperLink>
      </Info>
    </StyledWrapper>
  );
};

export default OrganizationCurrency;

const RightContent = styled.div`
  display: flex;
  align-items: center;
`;
const CurrencyBox = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: ${GetColor.Black};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Currency = styled.span`
  font-family: ${(props) => props.theme.Typography.fontFamily};
  font-size: 14px;
  font-weight: 900;
  line-height: 1.71;
  text-align: center;
  color: ${GetColor.White};
`;
