import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';
import Row from './Row';
import { take, takeRight } from 'lodash';

const Wrapper = styled.div`
  overflow-y: auto;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const HeaderRow = styled.tr`
  border-bottom: 1px solid ${GetColor.Grey};
  color: ${GetColor.MidGrey2};
  font-size: 12px;
  font-weight: 700;
  text-align: right;

  th {
    padding-bottom: 6px;
  }
`;

const FirstCell = styled.td`
  text-align: left;
`;

const Tbody = styled.tbody`
  tr:first-child td {
    padding-top: 6px;
  }

  tr:last-child td {
    border-bottom: 1px solid ${GetColor.Grey};
    padding-bottom: 6px;
  }
`;

export enum ChartType {
  risk = 'Risk',
  exposure = 'Exposure',
  return = 'Return',
}

export interface DataType {
  name: string;
  contribution: number;
  unweightedExposure: number;
}

interface Props {
  type: ChartType;
  usePercentages: boolean;
  data: DataType[];
}

export const extractTopAndBottomFunds = (data: DataType[]) => {
  if (data.length > 10) {
    return {
      top: take(data, 5),
      bottom: takeRight(data, 5),
    };
  }

  return {
    top: data,
    bottom: [],
  };
};

const Chart: FC<React.PropsWithChildren<Props>> = ({ data, type, usePercentages }) => {
  const contributions = data.map((item) => item.contribution);
  const min = Math.min(...contributions);
  const max = Math.max(...contributions);
  const extractedData = extractTopAndBottomFunds(data);

  const firstColName = extractedData.bottom.length === 0 ? 'Investments' : 'Top/Bottom 5';
  const maxContribution = Math.max(Math.abs(min), max, 0);
  return (
    <Wrapper>
      <Table>
        <thead>
          <HeaderRow>
            <FirstCell>{firstColName}</FirstCell>
            <th colSpan={2}>Contribution</th>
            <th>{type}</th>
          </HeaderRow>
        </thead>
        <Tbody>
          {extractedData.top.map((row) => (
            <Row
              key={row.name}
              name={row.name}
              unweightedExposure={row.unweightedExposure}
              contribution={row.contribution}
              max={maxContribution}
              usePercentages={usePercentages}
            />
          ))}
        </Tbody>
        {extractedData.bottom.length > 0 && (
          <Tbody>
            {extractedData.bottom.map((row) => (
              <Row
                key={row.name}
                name={row.name}
                max={maxContribution}
                unweightedExposure={row.unweightedExposure}
                contribution={row.contribution}
                usePercentages={usePercentages}
              />
            ))}
          </Tbody>
        )}
      </Table>
    </Wrapper>
  );
};

export default Chart;
