import type { CSSProperties, FC } from 'react';
import React, { useContext } from 'react';
import type { FactorProps } from '../types';
import { ThemeContext } from 'styled-components';

export interface RiskFreeRateProps extends FactorProps {
  opacity?: number;
}
const riskFreeRateStyle: CSSProperties = {
  fill: "url('#summary-vertical-lines')",
};

const negativeRiskFreeRateStyle: CSSProperties = {
  fill: "url('#summary-negative-vertical-lines')",
};

const getStyle = (negative: boolean): CSSProperties => ({
  ...(negative ? negativeRiskFreeRateStyle : riskFreeRateStyle),
});

const RiskFreeRate: FC<React.PropsWithChildren<RiskFreeRateProps>> = ({
  opacity = 1,
  x,
  y,
  width,
  height,
  negative = false,
}) => {
  const { Colors } = useContext(ThemeContext);
  return (
    <g>
      {opacity < 1 && <rect fill={Colors.White} fillOpacity={1} x={x} y={y} width={width} height={height} />}
      <rect style={getStyle(negative)} fillOpacity={opacity} x={x} y={y} width={width} height={height} />
    </g>
  );
};

export default RiskFreeRate;
