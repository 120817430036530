import styled, { css } from 'styled-components';
import { GetColor, ColorUtils, TOP_NAV_HEIGHT, ZIndex } from 'venn-ui-kit';

export const ANALYSIS_HEADER_HEIGHT = 40;
const getPageHeaderHeight = (hasSavedbar: boolean) => {
  const analysisHeaderHeight = hasSavedbar ? ANALYSIS_HEADER_HEIGHT : 0;
  return TOP_NAV_HEIGHT + analysisHeaderHeight;
};

export const Grid = styled.div<{ panelWidth?: number }>`
  width: 100%;
  display: grid;
  display: -ms-grid;
  -ms-grid-rows: 1fr;
  -ms-grid-columns: ${(props) => (props.panelWidth !== undefined ? props.panelWidth : 410)}px 1fr;
  grid-template-columns: ${(props) => (props.panelWidth !== undefined ? props.panelWidth : 410)}px auto;
  grid-template-rows: auto;
  grid-template-areas: 'panel analysis';
  height: 100%;
  background-color: ${GetColor.White};

  @media print {
    height: auto;
    display: block;
  }
`;

interface PanelProps {
  hasHeader?: boolean;
}

export const Panel = styled.div<PanelProps>`
  -ms-grid-column: 1;
  -ms-grid-row: 1;
  grid-area: panel;
  border-right: solid 1px ${GetColor.Grey};
  position: relative;
  > div {
    height: ${(props) => `calc(100vh - ${getPageHeaderHeight(!!props.hasHeader)}px)`};
  }
  @media print {
    display: none;
  }
`;

const AnalysisSidesMargin = css`
  margin-left: 60px;
  margin-right: 60px;
  @media (max-width: 1280px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

interface AnalysisProps {
  hasSaveViewBar?: boolean;
}

export const Analysis = styled.main<AnalysisProps>`
  -ms-grid-column: 2;
  -ms-grid-row: 1;
  grid-area: analysis;
  overflow-y: auto;
  ${(props) => props.hasSaveViewBar && `height: calc(100vh - ${getPageHeaderHeight(true)}px);`}
  @media print {
    /* I don't know why, but "overflow-y: auto" stops the printer from recalculating width */
    overflow-y: visible;
    height: auto;
  }
`;

const backgroundColor = ColorUtils.hex2rgbaFrom(GetColor.White, 0.85);
export const AnalysisCover = styled(Analysis)`
  background-color: ${backgroundColor};
  display: flex;
  position: relative;
  align-items: center;
  padding: 0 200px;
  z-index: ${ZIndex.StickyCover};

  > div {
    text-align: center;
  }

  @media (max-width: 1280px) {
    padding: 10px;
  }
`;

export const Content = styled.div`
  padding-bottom: 60px;
  position: relative;
  @media screen {
    min-width: 950px;
    > * {
      ${AnalysisSidesMargin};
    }
  }
`;
export const MinWidth = styled.div`
  @media screen {
    min-width: 950px;
  }
`;
