import type { HoldingsDataProps, HoldingsRowData } from '..';
import { useHoldingsColumns } from './useHoldingsColumns';
import { useHoldings } from './useHoldings';
import type { ValueGetterFunc } from 'ag-grid-community';
import { isNil } from 'lodash';
import { useMemo } from 'react';

const holdingsGridValueGetter: ValueGetterFunc<HoldingsRowData> = ({ data, colDef }) => {
  const index = Number(colDef.field);
  return !isNil(data?.allocation[index]) && data?.allocation[index] !== 0 ? data?.allocation[index] : '';
};

const useHoldingsDataGrid = ({ selectedRefId }: Pick<HoldingsDataProps, 'selectedRefId'>) => {
  const { data, categories } = useHoldings(selectedRefId);

  const rowData = useMemo(() => {
    const subjectToAllocationMap = data?.breakdowns?.map((breakdown) =>
      Object.fromEntries(breakdown.holdingsContributions.map((bd) => [bd.id.id, bd.allocation])),
    );
    return categories
      .map((category) => {
        const subjectToAllocation = subjectToAllocationMap?.map((map) => map[category.id.id] ?? 0);
        return {
          id: category.id,
          key: category.id,
          label: category.categoryName,
          type: 'PERCENTAGE',
          allocation: subjectToAllocation,
        };
      })
      .filter((row) => row.allocation?.some((alloc) => alloc));
  }, [categories, data?.breakdowns]);

  const columnDefs = useHoldingsColumns({
    selectedRefId,
    valueGetter: holdingsGridValueGetter,
  });

  return {
    rowData,
    columnDefs,
  };
};

export default useHoldingsDataGrid;
