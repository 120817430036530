import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { Routes, useHasFF } from 'venn-utils';

/**
 * Returns true if block watermarking is currently enabled given the current page of the application and feature flag state.
 *
 * Block visibility CSS during download vs non-download is handled by CSS not by this hook.
 */
export const useShouldShowByolWatermark = () => {
  const location = useLocation();
  const final = queryString.parse(location.search).internal === 'false';

  const hasStudioWatermark = useHasFF('studio_export_watermark_ff');
  const hasReportLabWatermark = useHasFF('report_lab_export_watermark_ff');
  const hasCompareWatermark = useHasFF('compare_export_watermark_ff');
  const hasAnalysisWatermark = useHasFF('analysis_export_watermark_ff');

  if (location.pathname.startsWith(Routes.STUDIO_PATH)) {
    return hasStudioWatermark;
  }
  if (location.pathname.startsWith(Routes.REPORT_LAB_PATH)) {
    return hasReportLabWatermark && !final;
  }
  if (location.pathname.startsWith(Routes.ANALYSIS_COMPARE_PATH)) {
    return hasCompareWatermark;
  }
  if (location.pathname.startsWith(Routes.ANALYSIS_PATH)) {
    return hasAnalysisWatermark;
  }

  return false;
};
