import React, { useState } from 'react';
import type { Fund } from 'venn-api';
import { useIsMounted } from 'venn-utils';
import ProxyIconButton from './ProxyIconButton';

type DropdownVerticalDirection = 'down' | 'up';

interface InvestmentDataFixActionsButtonProps {
  fund: Fund;
  /**
   * Callback to be executed after a fund gets updated (proxy/interpolation). Must be specified to use proxy and/or interpolation actions.
   * */
  onFundDataUpdated?: (fundId: string, throwOnFailure?: boolean) => Promise<void>;
  dropdownVerticalDirection?: DropdownVerticalDirection;
  usePortal?: boolean;
  readOnly?: boolean;
}

const InvestmentDataFixActionsButton = ({
  fund,
  onFundDataUpdated,
  dropdownVerticalDirection = 'down',
  usePortal,
  readOnly,
}: InvestmentDataFixActionsButtonProps) => {
  const fundId = fund.id;
  const proxy = fund.proxyType
    ? {
        name: fund.proxyName,
        // Confusing...cleanup later
        id: fund.proxyId,
        proxyId: fund.proxyId,
        proxyType: fund.proxyType,
        proxyCategory: fund.proxyCategory,
        numLags: fund.proxyNumLags,
        extrapolate: fund.extrapolate,
      }
    : null;

  const isMountedRef = useIsMounted();
  const [updatingProxy, setUpdatingProxy] = useState(false);

  const onProxyChange = async () => {
    setUpdatingProxy(true);
    await onFundDataUpdated?.(fundId, true);
    if (isMountedRef.current) {
      setUpdatingProxy(false);
    }
  };

  return (
    <>
      {fund.proxyable && fund && (
        <div>
          <ProxyIconButton
            proxy={proxy}
            investment={fund}
            hoverAddProxy={false}
            onProxyChange={onProxyChange}
            dropdownVerticalDirection={dropdownVerticalDirection}
            usePortal={usePortal}
            loading={updatingProxy}
            readOnly={readOnly}
          />
        </div>
      )}
    </>
  );
};

export default InvestmentDataFixActionsButton;
