import React from 'react';
import styled from 'styled-components';
import { useHover, buttonize } from 'venn-utils';
import { GetColor, Subtitle2 } from 'venn-ui-kit';
import { useRecoilValue } from 'recoil';
import type { BenchmarkInputId } from 'venn-state';
import { benchmarkInputIsRelative, benchmarkInputName, benchmarkInputSubject, benchmarkInputType } from 'venn-state';
import { HoverIcon } from '../HoverIcon';
import { BenchmarkItem, EmptyBenchmark } from '../../../../../studio-main-left-panel/panels/components/Benchmark';

/**
 * Displays a subject input group and all of its constituent subjects, allowing selection of the entire input group.
 */
export const SelectBenchmarkInputSection = ({
  inputId,
  isChecked,
  onClick,
}: {
  inputId: BenchmarkInputId;
  /** Whether to start out checked, otherwise will show a checkmark icon on hover. */
  isChecked: boolean;
  /** Invoked with the same inputId provided to this component, for ease of use. */
  onClick: (inputId: BenchmarkInputId) => void;
}) => {
  const [isRootHovered, rootHoverHandlers] = useHover();
  return (
    <BenchmarkInputSubjectsSectionWrapper {...rootHoverHandlers} {...buttonize(() => onClick(inputId))}>
      <HoverIcon
        // Whole row is selectable, so icon shouldn't be.
        tabIndex={-1}
        style={{ padding: '6px' }}
        type="check"
        visible={isChecked || isRootHovered}
        hoverColor={GetColor.Primary.Dark}
      />
      <div style={{ overflow: 'hidden' }}>
        <BenchmarkInputSubjectsList key={inputId} inputId={inputId} />
      </div>
    </BenchmarkInputSubjectsSectionWrapper>
  );
};

/** Provides layout, spacing, and container styling. */
const BenchmarkInputSubjectsSectionWrapper = styled.span`
  display: flex;
  align-items: baseline;

  margin-top: 2px;
  padding-top: 6px;
  padding-bottom: 6px;
  margin-bottom: 10px;

  &:hover {
    cursor: pointer;
    background-color: ${GetColor.WhiteGrey};
  }
`;

/** Lists the name of a benchmark and its subject if it is a common benchmark type. */
const BenchmarkInputSubjectsList = React.memo(function BenchmarkInputSubjectsList({
  inputId,
}: {
  inputId: BenchmarkInputId;
}) {
  const name = useRecoilValue(benchmarkInputName(inputId));
  const subject = useRecoilValue(benchmarkInputSubject(inputId));
  const benchmarkType = useRecoilValue(benchmarkInputType(inputId));
  const benchmarkIsRelative = useRecoilValue(benchmarkInputIsRelative(inputId));
  return (
    <>
      <Subtitle2 style={{ marginBottom: 4 }}>{name}</Subtitle2>
      {subject && benchmarkType === 'COMMON' ? (
        <BenchmarkItem subject={subject} benchmarkIsRelative={benchmarkIsRelative} readonly />
      ) : (
        <Wrapper>
          <EmptyBenchmark benchmarkType={benchmarkType} benchmarkIsRelative={benchmarkIsRelative} />
        </Wrapper>
      )}
    </>
  );
});

const Wrapper = styled.div`
  font-size: 12px;
`;
