import type { TreeActionableContext } from 'venn-api';
import type { DropMenuItem } from 'venn-ui-kit';

const getItemsHelper = (node: TreeActionableContext, level: number): DropMenuItem<string>[] => [
  {
    label: node.value.context.name,
    value: node.value.context.id,
    level,
  },
  ...node.children.flatMap((child) => getItemsHelper(child, level + 1)),
];

export const getOwnershipFlatItems = (availableContexts?: TreeActionableContext[]): DropMenuItem[] =>
  availableContexts?.flatMap((context) => getItemsHelper(context, 0)) ?? [];

export const findSelectedContext = (
  id?: string,
  availableContexts?: TreeActionableContext[],
): TreeActionableContext | undefined => {
  if (!id || !availableContexts) {
    return undefined;
  }
  for (let i = 0; i < availableContexts.length; i++) {
    if (availableContexts[i].value.context.id === id) {
      return availableContexts[i];
    }
    const result = findSelectedContext(id, availableContexts[i].children);
    if (result) {
      return result;
    }
  }
  return undefined;
};
