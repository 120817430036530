import type { CallbackInterface } from 'recoil';
import { selectorFamily, waitForAll } from 'recoil';
import { type ComputedDateRange, isPublicPrivateAssetGrowthBlock, getDateBehavior } from 'venn-utils';
import type { BlockId, Subject } from '../types';
import type { DateRange } from 'venn-ui-kit';
import { getRangeFromType } from 'venn-ui-kit';
import { requestSubjects } from './subjects';
import type { DateRangeInputId } from './input-management/dateRangeInput';
import {
  dateRangeInputBlockComputedRange,
  blockDateRangeInputState,
  dateRangeInputDateRangeState,
  dateRangeInputsState,
  dateRangeInputNameState,
  dateRangeInputConsistencyState,
  computeBlockRange,
} from './input-management/dateRangeInput';
import { recoilGetSubjectGroupRange } from '../async/getSubjectGroupRange';
import { allBlockIdsState } from '../grid';
import { blockPrivateDefaultAsOfDateBehavior } from './privateAssets';
import moment from 'moment';
import { blockSettings } from './blockSettings';

export const subjectsDateRanges = selectorFamily<ComputedDateRange[], Subject[]>({
  key: 'subjectsDateRanges',
  get:
    (subjects) =>
    ({ get }) => {
      return get(waitForAll(subjects.map((subject) => subjectGroupDateRangeQuery([subject]))));
    },
});

/**
 * Waits for both the asynchronous subject query AND for the asynchronous group range query.
 *
 * Handy to keep separate from {@link subjectsDateRanges} because it allows subjectsDateRanges to function with greater parallelism,
 * because it allows each async thread to progress through both stages (subject query, date query) without blocking behavior.
 */
const subjectGroupDateRangeQuery = selectorFamily<ComputedDateRange, Subject[]>({
  key: 'subjectGroupDateRange',
  get:
    (subjects) =>
    ({ get }) => {
      const apiSubjects = get(requestSubjects(subjects));
      return recoilGetSubjectGroupRange(get, apiSubjects);
    },
});

/**
 * A block may have an undefined date range when no input is attached to it.
 */
export const blockDateRange = selectorFamily<ComputedDateRange | undefined, BlockId>({
  key: 'blockRange',
  get:
    (id) =>
    ({ get }) => {
      const customBlockType = get(blockSettings(id)).customBlockType;
      if (getDateBehavior(customBlockType) === 'NONE') {
        // Some blocks "don't care" about date ranges, but still use the frequency of the blocks' investments, so we return a block specific range.
        return computeBlockRange(get, id);
      }

      const dateRangeInputId = get(blockDateRangeInputState(id));
      if (!dateRangeInputId) {
        return undefined;
      }

      const fullBlockRange = get(dateRangeInputBlockComputedRange({ inputId: dateRangeInputId, blockId: id }));
      const dateRangeInputRange = get(dateRangeInputDateRangeState(dateRangeInputId));
      const range = getRange(dateRangeInputRange, fullBlockRange);

      if (isPublicPrivateAssetGrowthBlock(customBlockType) && get(blockPrivateDefaultAsOfDateBehavior(id))) {
        // special case: public-private asset growth block defaults end to current date if the setting is on
        return {
          range: {
            ...range,
            to: moment().valueOf(),
          },
          maxRange: fullBlockRange.maxRange,
          frequency: fullBlockRange.frequency,
        };
      }

      return {
        range,
        maxRange: fullBlockRange.maxRange,
        frequency: fullBlockRange.frequency,
      };
    },
});

const getRange = (range: DateRange | undefined, fullRange: ComputedDateRange) => {
  if (!range) {
    return fullRange.range;
  }
  // TODO: convert frequency to granuality
  const newRange = range.period ? getRangeFromType(range.period, fullRange.maxRange, 'day') : range;
  return {
    to: newRange.to,
    from: newRange.from,
    period: range.period,
  };
};

/** use this in a useRecoilCallback hook to delete date range inputs for a given date range id */
export const onDateInputDelete =
  ({ snapshot, set, reset }: CallbackInterface) =>
  async (inputId: DateRangeInputId) => {
    set(dateRangeInputsState, (current) => current.filter((id) => id !== inputId));

    reset(dateRangeInputNameState(inputId));
    reset(dateRangeInputConsistencyState(inputId));
    reset(dateRangeInputDateRangeState(inputId));

    const blockIds = await snapshot.getPromise(allBlockIdsState);
    blockIds.forEach((blockId) =>
      set(blockDateRangeInputState(blockId), (current) => (current === inputId ? undefined : current)),
    );
  };
