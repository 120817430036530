import React from 'react';
import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';
import type { FooterLink } from 'venn-components';
import type { UserLanguageEnum } from 'venn-api';

const StyledLink = styled.a`
  color: ${GetColor.LightGrey};
  margin: 0 10px;
  transition: color 0.15s linear;

  &:hover,
  &:focus {
    color: ${GetColor.White};
  }
`;

const DEFAULT_LANGUAGE: UserLanguageEnum = 'EN';

interface LegalLinkProps {
  link: FooterLink;
  language?: UserLanguageEnum;
}

const LegalLink = ({ link, language = DEFAULT_LANGUAGE }: LegalLinkProps) => (
  <StyledLink href={link.baseUrl} target="_blank" rel="noopener noreferrer">
    <span>{(link.titles.find((title) => title.language === language) ?? link.titles[0]).text}</span>
  </StyledLink>
);

export default LegalLink;
