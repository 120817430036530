import { toEndOfPeriod, FS } from 'venn-utils';

export const DAY_SIZE = 213;
export const MONTH_SIZE = 189;
export const YEAR_SIZE = 141;
/** Available factors start date */
const DEFAULT_START_DATE = '1997/10/03';
const NEW_FACTOR_LENS_START_DATE = '1998/08/07';

/** The maximum range possible: from the start of factor data to today. */

export const getFactorMaxRange = () => ({
  from: FS.has('mstar_indices_factors_ff')
    ? toEndOfPeriod(NEW_FACTOR_LENS_START_DATE, 'day')
    : toEndOfPeriod(DEFAULT_START_DATE, 'day'),
  to: toEndOfPeriod(new Date().valueOf(), 'day'),
});
