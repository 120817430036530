import type { RefObject } from 'react';
import React from 'react';
import styled from 'styled-components';
import type { AgGridReactProps } from 'ag-grid-react';
import { AgGridReact } from 'ag-grid-react';
import type { AgGridReact as AgGridReactType } from 'ag-grid-react/lib/agGridReact';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/agGridAlpineFont.css';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import useStickyHeader from './useStickyHeader';

export interface DataGridProps extends AgGridReactProps {
  noCustomStickyHeader?: boolean;
  dataGridWrapperRef?: RefObject<HTMLDivElement>;
  gridRef?: RefObject<AgGridReactType>;
  className?: string;
}

const DataGrid = (props: DataGridProps) => {
  useStickyHeader(props.noCustomStickyHeader);
  return (
    <>
      <DataGridWrapper
        className={props.className ?? 'ag-theme-alpine'}
        ref={props.dataGridWrapperRef}
        data-testid="datagrid-wrapper"
      >
        <AgGridReact rowDataChangeDetectionStrategy="IdentityCheck" ref={props.gridRef} {...props} />
      </DataGridWrapper>
    </>
  );
};

export default DataGrid;

const DataGridWrapper = styled.div`
  width: 100%;
  height: 100%;

  .ag-root-wrapper {
    border: none;
  }

  .ag-center-cols-clipper {
    min-height: 35px !important;
  }
  .ag-center-cols-container {
    min-height: 35px !important;
  }
`;
