import { useBlockId } from '../contexts/BlockIdContext';
import StyledEmptyState from './StyledEmptyState';
import { Flexbox, GetColor, Icon } from 'venn-ui-kit';
import React from 'react';
import { getPrivateHelpCenterLink, type PrivateAnalysisSubjectError } from './PrivatesErrorMessages';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';
import { blockSettings } from 'venn-state';

type PrivatesSubjectErrorMessagesProps = Readonly<{ errors: PrivateAnalysisSubjectError[] }>;

const SubjectErrorRow = ({ subject, error }: PrivateAnalysisSubjectError) => {
  if (error.severity !== 'ERROR') {
    return null;
  }
  return (
    <div>
      <FundName>{subject}: </FundName>
      <span>{error.message}</span>
    </div>
  );
};

export const PrivatesSubjectErrors = ({ errors }: PrivatesSubjectErrorMessagesProps) => {
  const blockId = useBlockId();
  const blockSetting = useRecoilValue(blockSettings(blockId));
  const customBlockType = blockSetting.customBlockType;

  return (
    <StyledEmptyState
      header=""
      selectedRefId={blockId}
      message={
        <Flexbox direction="column" rowGap={20}>
          <ErrorTitle>
            <Icon type="exclamation-circle" />
            <span>
              Unable to run analysis. Please review and fix the issue(s) below before retrying or{' '}
              <a href={getPrivateHelpCenterLink(customBlockType)} target="_blank" rel="noreferrer">
                click here
              </a>{' '}
              to learn more:
            </span>
          </ErrorTitle>
          <ErrorList>
            {errors.map((errorRow, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <SubjectErrorRow key={index} {...errorRow} />
            ))}
          </ErrorList>
        </Flexbox>
      }
    />
  );
};

const FundName = styled.span`
  font-weight: bold;
`;

const ErrorTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  font-size: 20px;
  text-align: left;
  color: ${GetColor.Black};

  i {
    color: ${GetColor.Error};
  }
`;

const ErrorList = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  text-align: left;
  font-size: 16px;
  margin-left: 30px;
  color: ${GetColor.Black};
`;
