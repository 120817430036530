import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import RangePicker from '../range-picker/RangePicker';
import TextField from '../../text-field/TextField';
import { Label } from '../../typography';
import ButtonBase from '../../button/Button';
import type { DropMenuItem } from '../../drop-menu';
import { DropMenu } from '../../drop-menu';
import type { DatePickerProps } from '../types';
import { getTypeFromRange } from './logic';
import useNonOverlapping from './useNonOverlapping';

export const monthItems: DropMenuItem<number>[] = [
  { label: 'Jan', value: 0 },
  { label: 'Feb', value: 1 },
  { label: 'Mar', value: 2 },
  { label: 'Apr', value: 3 },
  { label: 'May', value: 4 },
  { label: 'Jun', value: 5 },
  { label: 'Jul', value: 6 },
  { label: 'Aug', value: 7 },
  { label: 'Sep', value: 8 },
  { label: 'Oct', value: 9 },
  { label: 'Nov', value: 10 },
  { label: 'Dec', value: 11 },
];
const GRANULARITY = 'month';
const MAX_YEAR = 9999;
const MIN_YEAR = 1000;

const MonthPicker = ({
  value,
  maxRange,
  onUpdatePeriod,
  onApply,
  onUpdateDate,
  onCancel,
  options,
  hideActions = false,
  autoFocus = true,
}: DatePickerProps) => {
  const fromInputRef = useRef<HTMLInputElement>(null);
  const [from, setFrom] = useState<number | undefined>(value?.from);
  const [to, setTo] = useState<number | undefined>(value?.to);

  // The years are local state, as they are text fields, so can be updated temporarily to non-valid years
  const [fromYear, setFromYear] = useState(() => from && moment.utc(from).year());
  const [toYear, setToYear] = useState(() => to && moment.utc(to).year());
  const fromMonth = useMemo(() => from && moment.utc(from).month(), [from]);
  const toMonth = useMemo(() => to && moment.utc(to).month(), [to]);

  const range = useMemo(
    () => getTypeFromRange(GRANULARITY, { from, to, period: value?.period }, maxRange),
    [from, to, maxRange, value?.period],
  );

  const { isValid } = useNonOverlapping(maxRange, from, to);

  const handleApply = useCallback(() => {
    onApply?.({ from, to });
  }, [from, to, onApply]);

  const handleChangeFromYear = useCallback(
    (year: number) => {
      setFromYear(year);
      if (year > MIN_YEAR && year < MAX_YEAR) {
        const newFrom = moment.utc(from).set('year', year).valueOf();
        setFrom(newFrom);
        onUpdateDate?.({ from: newFrom, to });
      }
    },
    [onUpdateDate, from, to],
  );

  const handleChangeToYear = useCallback(
    (year: number) => {
      setToYear(year);
      if (year > MIN_YEAR && year < MAX_YEAR) {
        const newTo = moment.utc(to).set('year', year).valueOf();
        setTo(newTo);
        onUpdateDate?.({ from, to: newTo });
      }
    },
    [onUpdateDate, from, to],
  );

  const handleChangeFromMonth = useCallback(
    (month: DropMenuItem<number>) => {
      const newFrom = moment.utc(from).set('month', month.value).valueOf();
      setFrom(newFrom);
      onUpdateDate?.({ from: newFrom, to });
    },
    [onUpdateDate, from, to],
  );

  const handleChangeToMonth = useCallback(
    (month: DropMenuItem<number>) => {
      const newTo = moment.utc(to).set('month', month.value).valueOf();
      setTo(newTo);
      onUpdateDate?.({ from, to: newTo });
    },
    [onUpdateDate, from, to],
  );

  // Prevent the user from blurring the year input in an invalid year
  const handleToYearBlur = useCallback(() => {
    setToYear(to ? moment.utc(to).year() : undefined);
  }, [to, setToYear]);

  // Prevent the user from blurring the year input in an invalid year
  const handleFromYearBlur = useCallback(() => {
    setFromYear(from ? moment.utc(from).year() : undefined);
  }, [from, setFromYear]);

  // Focus the input on mount
  useEffect(() => {
    if (autoFocus) {
      fromInputRef.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update local state if props are changing
  useEffect(() => {
    setFrom(value?.from);
    value?.from && setFromYear(moment.utc(value.from).year());
    setTo(value?.to);
    value?.to && setToYear(moment.utc(value.to).year());
  }, [value?.from, value?.to]);

  return (
    <Container>
      {maxRange && onUpdatePeriod && (
        <RangePicker
          range={maxRange}
          disposition="vertical"
          value={range}
          options={options}
          onChange={onUpdatePeriod}
          granularity={GRANULARITY}
        />
      )}
      <Form>
        <Row>
          <Label htmlFor="fromYear">From:</Label>&nbsp;
          <Dropdown
            items={monthItems}
            selected={fromMonth}
            className="qa-date-picker-month-from"
            width={70}
            innerRef={fromInputRef}
            // @ts-expect-error: TODO fix strictFunctionTypes
            onChange={handleChangeFromMonth}
          />
          <TextField<number>
            id="fromYear"
            value={fromYear}
            type="number"
            className="qa-date-picker-year-from"
            onChange={handleChangeFromYear}
            onBlur={handleFromYearBlur}
          />
        </Row>
        <Row>
          <Label htmlFor="toYear">To:</Label>&nbsp;
          <Dropdown
            items={monthItems}
            selected={toMonth}
            width={70}
            className="qa-date-picker-month-to"
            // @ts-expect-error: TODO fix strictFunctionTypes
            onChange={handleChangeToMonth}
          />
          <TextField<number>
            id="toYear"
            value={toYear}
            type="number"
            className="qa-date-picker-year-to"
            onChange={handleChangeToYear}
            onBlur={handleToYearBlur}
          />
        </Row>
        {!hideActions && (
          <Actions>
            <Button type="submit" dense dominant onClick={handleApply} disabled={!isValid}>
              Apply
            </Button>
            <Button dense onClick={onCancel}>
              Cancel
            </Button>
          </Actions>
        )}
      </Form>
    </Container>
  );
};

const Container = styled.div`
  width: 250px;
`;

export const Form = styled.div`
  margin: 20px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  ${Label} {
    width: 40px;
  }
  input {
    width: 100px;
  }
`;

export const Button = styled(ButtonBase)`
  width: 100px;
  margin: 0;
`;

export const Actions = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
`;

export const Dropdown = styled(DropMenu)`
  width: 70px;
  min-width: 70px;
  margin-right: 2px;
`;

export default MonthPicker;
