import React, { useCallback, useContext, useState } from 'react';
import { useRecoilValue } from 'recoil';
import {
  isInvestmentOverrideSubmitCancelButtonsDisabledAtom,
  selectedInvestmentOverrideAtomSyncedWithOverrideType,
  selectedInvestmentOverrideTypeAtom,
  selectedInvestmentOverrideValueAtom,
} from 'venn-state';
import { logExceptionIntoSentry, useHasFF } from 'venn-utils';
import { InvestmentForecastPanelFooterActionsContext } from '../contexts/InvestmentForecastPanelFooterActionsContext';
import { ForecastOverrideActions } from '../views/ForecastOverrideActions';

type Props = Readonly<{
  isCreating?: boolean;
}>;

export const InvestmentForecastPanelFooter = ({ isCreating }: Props) => {
  const hasRedesignFF = useHasFF('forecast_panel_redesign_ff');
  const { onCancelInvestmentOverride, onCancelInvestmentOverrideForCreation, onUpdateOrCreateInvestmentOverride } =
    useContext(InvestmentForecastPanelFooterActionsContext);

  const cancelOverride = hasRedesignFF
    ? isCreating
      ? onCancelInvestmentOverrideForCreation
      : onCancelInvestmentOverride
    : onCancelInvestmentOverride;

  const operation = hasRedesignFF ? (isCreating ? 'CREATE' : 'EDIT') : 'EDIT';

  const overrideValue = useRecoilValue(selectedInvestmentOverrideValueAtom(operation));
  const overrideType = useRecoilValue(selectedInvestmentOverrideTypeAtom(operation));
  const selectedOverride = useRecoilValue(selectedInvestmentOverrideAtomSyncedWithOverrideType(operation));
  const [isLoading, setIsLoading] = useState(false);

  const fundId = selectedOverride?.fundId;
  const shouldButtonsBeDisabled = useRecoilValue(isInvestmentOverrideSubmitCancelButtonsDisabledAtom(operation));
  const isButtonDisabled = shouldButtonsBeDisabled || isLoading;
  const onSubmitWithLoading = useCallback(() => {
    if (!fundId || overrideValue === undefined) {
      logExceptionIntoSentry(
        `fundId(${fundId}) and value(${overrideValue}) should be defined to save residual override`,
      );
      return;
    }
    setIsLoading(true);
    onUpdateOrCreateInvestmentOverride(fundId, overrideValue, overrideType).then(() => {
      setIsLoading(false);
    });
  }, [fundId, overrideValue, onUpdateOrCreateInvestmentOverride, overrideType]);

  return (
    <ForecastOverrideActions
      isCancelButtonDisabled={hasRedesignFF ? isButtonDisabled && !!isCreating : isButtonDisabled}
      isSubmitButtonDisabled={isButtonDisabled}
      onCancelClick={cancelOverride}
      onApplyClick={onSubmitWithLoading}
      tooltipContent="Applying a new override will remove the existing override for the default."
      tooltipSideText={
        hasRedesignFF
          ? isCreating
            ? 'Add investment forecast override?'
            : 'Update investment forecast override?'
          : 'Apply override changes?'
      }
      noTooltip={hasRedesignFF}
      applyButtonLabel={hasRedesignFF ? (isCreating ? 'Add' : 'Update') : undefined}
    />
  );
};
