import type { FunctionComponent } from 'react';
import React from 'react';
import styled from 'styled-components';
import { BrandLogo } from 'venn-ui-kit';
import { Link } from 'react-router-dom';

const StyledAuthLogo = styled.div<{ top?: number }>`
  display: flex;
  justify-content: center;
  padding-top: ${({ top }) => (top !== undefined ? top : 150)}px;
`;

interface AuthLogoProps {
  top?: number;
}

export const AuthLogo: FunctionComponent<React.PropsWithChildren<AuthLogoProps>> = ({ top }) => (
  <StyledAuthLogo top={top}>
    <Link to="/">
      <BrandLogo height={65} showText animateOnHover />
    </Link>
  </StyledAuthLogo>
);

export default AuthLogo;
