import React, { useCallback, useContext, useRef } from 'react';
import styled from 'styled-components';
import type { OptimizedPortfolio, RiskReturnPoint, TradeSummary } from 'venn-api';
import { GetColor, Loading, ZIndex } from 'venn-ui-kit';
import { DownloadFooter, PortfolioLabContext } from 'venn-components';
import type { LabHeaderProps } from './components/LabHeader';
import LabHeader, { formatObjectiveForPrint, HEADER_HEIGHT } from './components/LabHeader';
import type { LabSideBarProps } from './components/LabSideBar';
import LabSideBar, { SIDE_BAR_WIDTH } from './components/LabSideBar';
import EmptyPortfolioLabPage from './components/EmptyPortfolioLabPage';
import EfficientFrontierCard from './components/cards/EfficientFrontierCardContainer';
import TradesVsCapitalCard from './components/cards/TradesVsCapitalCardContainer';
import type { SolutionData } from './logic/useOptimizationSolutions';
import { isNil, pick } from 'lodash';
import SelectedSolutionHeader from './components/SelectedSolutionHeader';
import OverviewCardContainer from './components/cards/OverviewCardContainer';
import LargestAverageCard from './components/cards/LargestAverageCard';
import PortfolioPerformanceCard from './components/cards/PortfolioPerformanceCardContainer';
import FactorPerformanceCard from './components/cards/FactorPerformanceCardContainer';
import type { PortfolioLabStoredSettings } from './logic/useValuesFromHistoryState';
import useAvailablePeriodForForecast from './logic/useAvailablePeriodForForecast';
import PrintConstraintsFooter from './components/PrintConstraintsFooter';
import type { ExcelCell } from 'venn-utils';
import { SpecialCssClasses } from 'venn-utils';
import useCombinedExcelExportData from './logic/useCombinedExcelExportData';
import NoOptimizationError from './components/NoOptimizationError';

interface PortfolioLabLayoutProps extends LabSideBarProps, LabHeaderProps {
  current?: SolutionData;
  optimized?: SolutionData;
  alternate?: SolutionData[];
  benchmark?: SolutionData;
  efficientFrontier?: RiskReturnPoint[];

  optimizedResult?: OptimizedPortfolio;
  alternateResults?: OptimizedPortfolio[];

  optimizing: boolean;
  noOptimizationResult: boolean;

  averageInvestmentBuys?: TradeSummary[];
  averageInvestmentSells?: TradeSummary[];

  settings: PortfolioLabStoredSettings;
  storeSettings: (values: Partial<PortfolioLabStoredSettings>) => void;
}

const PortfolioLabLayout = (props: PortfolioLabLayoutProps) => {
  const {
    portfolio,
    portfolioLoading,
    onSelectPortfolioId,
    selectedSolution,
    solutionPortfolio,
    objective,
    objectiveConstraintValue,
    benchmarkPseudoPortfolio,
  } = useContext(PortfolioLabContext);
  const downloadableContentRef = useRef<HTMLDivElement>(null);

  const labHeaderProps = getLabHeaderProps(props);
  const sideBarProps = getSideBarProps(props);
  const {
    current,
    optimized,
    alternate,
    benchmark,
    efficientFrontier,
    summaryLoading,
    summaryError,
    optimizedResult,
    alternateResults,
    optimizing,
    noOptimizationResult,
    averageInvestmentBuys,
    averageInvestmentSells,
    settings,
    storeSettings,
    benchmarkSummaryError,
    newFunds,
    newFundsParentStrategyId,
  } = props;

  const {
    combinedExcelSheetData,
    onSetConstraintsSheetData,
    onSetTradesSheetData,
    onSetPerformanceSheetData,
    onSetFactorSheetData,
  } = useCombinedExcelExportData();

  const onUpdateOverviewCardExcelData = useCallback(
    (constraintsData: ExcelCell[][] | undefined, tradesData: ExcelCell[][] | undefined) => {
      onSetConstraintsSheetData(constraintsData);
      onSetTradesSheetData(tradesData);
    },
    [onSetConstraintsSheetData, onSetTradesSheetData],
  );

  const { analysisPeriodParams, loadingAnalysisPeriod } = useAvailablePeriodForForecast(
    portfolio,
    solutionPortfolio?.portfolio,
    benchmarkPseudoPortfolio,
    noOptimizationResult,
  );

  const hasPortfolioSelected = !isNil(portfolio) || portfolioLoading;

  return (
    <Page>
      <LabHeader {...labHeaderProps} hideAllButtons={!hasPortfolioSelected} />
      <Main>
        {hasPortfolioSelected ? (
          <>
            <LabSideBar {...sideBarProps} />
            <PageContent>
              {optimizing && (
                <LoadingOverlay>
                  <Loading />
                </LoadingOverlay>
              )}
              <LeftColumn>
                <EfficientFrontierCard
                  summaryLoading={summaryLoading}
                  summaryError={summaryError}
                  current={current}
                  optimized={optimized}
                  alternate={alternate}
                  benchmark={benchmark}
                  efficientFrontier={efficientFrontier}
                />
                <TradesVsCapitalCard
                  portfolio={portfolio}
                  optimizedPortfolio={optimizedResult}
                  alternatePortfolios={alternateResults}
                />
                <HalfAndHalf>
                  <LargestAverageCard cardType="INCREASE" data={averageInvestmentBuys} />
                  <LargestAverageCard cardType="DECREASE" data={averageInvestmentSells} />
                </HalfAndHalf>
              </LeftColumn>
              <RightColumn ref={downloadableContentRef}>
                {noOptimizationResult ? (
                  <NoOptimizationError />
                ) : isNil(selectedSolution) ? null : (
                  <>
                    <SelectedSolutionHeader
                      downloadableContentRef={downloadableContentRef}
                      selectedSolution={selectedSolution}
                      relative={!!settings.relative}
                      requestPeriodParams={analysisPeriodParams}
                      excelSheetData={combinedExcelSheetData}
                    />
                    <ResultCards>
                      <ResultLeftColumn>
                        <OverviewCardContainer
                          selectedSolution={selectedSolution}
                          solutionPortfolio={solutionPortfolio}
                          onUpdateExportData={onUpdateOverviewCardExcelData}
                          newOpportunities={newFunds}
                          newOpportunitiesParentStrategyId={newFundsParentStrategyId}
                        />
                      </ResultLeftColumn>
                      <ResultRightColumn>
                        <PortfolioPerformanceCard
                          requestPeriodParams={analysisPeriodParams}
                          loadingAnalysisPeriod={loadingAnalysisPeriod}
                          settings={settings}
                          storeSettings={storeSettings}
                          benchmarkSummaryError={benchmarkSummaryError}
                          onUpdateExportData={onSetPerformanceSheetData}
                        />
                        <FactorPerformanceCard onUpdateExportData={onSetFactorSheetData} />
                      </ResultRightColumn>
                    </ResultCards>
                  </>
                )}
                {!isNil(portfolio) && (
                  <Footer className={SpecialCssClasses.OnlyInDownloadable}>
                    <DownloadFooter
                      items={[
                        { label: 'Optimizing portfolio', value: portfolio.name },
                        { label: 'Objective', value: formatObjectiveForPrint(objective, objectiveConstraintValue) },
                        { label: 'Benchmark', value: benchmark?.solution.name ?? '--' },
                      ]}
                    />
                  </Footer>
                )}
              </RightColumn>
            </PageContent>
          </>
        ) : (
          <EmptyPortfolioLabPage onSelectPortfolioId={onSelectPortfolioId} />
        )}
      </Main>

      <PrintConstraintsFooter />
    </Page>
  );
};

export default PortfolioLabLayout;

const Page = styled.div`
  min-width: 1654px;
  @media print {
    min-width: fit-content;
    width: 100%;
  }
`;

const Main = styled.div`
  position: relative;
  height: calc(100% - ${HEADER_HEIGHT}px);
  background-color: ${GetColor.WhiteGrey};
  @media print {
    background-color: transparent;
    overflow: hidden !important;
  }
  min-height: 400px;
`;

const PageContent = styled.div`
  margin-left: ${SIDE_BAR_WIDTH}px;
  height: 100%;
  display: flex;
  @media print {
    flex-direction: column;
    margin-left: 0px;
  }
`;

const LeftColumn = styled.div`
  padding: 20px;
  min-width: 450px;
  width: 36%;
  & > :not(:first-child) {
    margin-top: 10px;
  }
  @media print {
    width: 100%;
    padding: 20px 0px;

    & > :nth-child(1),
    & > :nth-child(2) {
      display: inline-block;
      width: calc(50% - 5px);
    }

    & > :nth-child(3) {
      page-break-inside: avoid;
    }

    & > :first-child {
      margin-right: 10px;
    }

    page-break-inside: avoid;
  }
`;

const RightColumn = styled.div`
  width: 64%;
  min-width: 1031px;
  background-color: ${GetColor.White};
  border-left: 1px solid ${GetColor.Grey};
  .${SpecialCssClasses.ExportAsImage} & {
    border-right: 1px solid ${GetColor.Grey};
    border-top: 1px solid ${GetColor.Grey};
  }

  @media print {
    width: 100%;
    border-left: none;
    height: 100%;
  }
`;

const ResultCards = styled.div`
  display: flex;
  height: 100%;
  padding: 20px;
  & > :not(:last-child) {
    margin-right: 20px;
  }

  @media print {
    padding: 20px 0px;
  }
`;

const ResultLeftColumn = styled.div`
  min-width: 330px;
  width: 33%;
  @media print {
    margin-right: 10px;
  }
`;

const ResultRightColumn = styled.div`
  min-width: 641px;
  width: 64%;
  & > :not(:first-child) {
    margin-top: 10px;
  }
  @media print {
    width: calc(67% - 10px);
  }
`;

const LoadingOverlay = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.5;
  background-color: white;
  z-index: ${ZIndex.ModalCover};
  align-content: center;
  justify-content: center;
  position: absolute;
  padding-top: 230px;
`;

const HalfAndHalf = styled.div`
  display: flex;
  & > div {
    width: 50%;
    background-color: ${GetColor.White};
    margin-bottom: auto;
  }
  & > :not(:last-child) {
    margin-right: 10px;
  }
`;

const Footer = styled.div`
  width: 100%;
  display: none;
`;

const getLabHeaderProps = (props: PortfolioLabLayoutProps): LabHeaderProps =>
  pick(props, [
    'constraintInputFocused',
    'setConstraintInputFocused',
    'summary',
    'summaryLoading',
    'summaryError',
    'historicalReturn',
    'historicalVolatility',
    'newFunds',
    'newFundsParentStrategyId',
    'factorForecasts',
    'onForecastUpdated',
    'benchmarkSummaryError',
    'onUpdateNewOpportunities',
  ]);

const getSideBarProps = (props: PortfolioLabLayoutProps): LabSideBarProps =>
  pick(props, [
    'isSideBarCollapsed',
    'toggleSideBarCollapsed',
    'allocationSectionExpanded',
    'toggleAllocationSection',
    'factorSectionExpanded',
    'toggleFactorSection',
    'optimizing',
  ]);
