import React, { useState, useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';
import type { Theme } from 'venn-ui-kit';
import { ExternalActivityListener } from 'venn-ui-kit';
import {
  PortfolioNamesTooltip,
  FactorTooltips,
  InsignificantFactorMessage,
  MESSAGE_HEIGHT,
  RISK_FREE_RATE_ID,
  RESIDUAL_ID,
} from '../factor-chart';
import type { HeatMapTypes, FactorDescription } from './Types';
import YAxis from './YAxis';
import type { TopLegendFormatterType } from './TopLegend';
import TopLegend from './TopLegend';
import HeatMapRenderer from './MapRenderer';
import CanvasHeatMapRenderer from './CanvasMapRenderer';
import Values from './Values/Values';
import Lines from './Grid/Lines';
import type { MouseEventType } from './Map/Cell';
import Cursor from './Cursor';
import compact from 'lodash/compact';
import Highlight from './Highlight';
import Ticks from './Grid/Ticks';
import Border from './Grid/Border';
import { SpecialCssClasses } from 'venn-utils';

const LEGEND_LINE_HEIGHT = 10;
const BOTTOM_SPACE = 25;
const LIGHT_GREY = 'rgba(74, 204, 255, 0.2)';
const Wrapper = styled.div<{ marginTop: string | undefined; exportMarginTop: string | undefined }>`
  position: relative;
  transform: translate3d(0, 0, 0);

  svg {
    overflow: visible !important;
  }

  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: ${marginTop};
    `}

  ${({ exportMarginTop }) =>
    exportMarginTop &&
    css`
      .${SpecialCssClasses.ExportAsImage} & {
        margin-top: ${exportMarginTop};
      }
      @media print {
        margin-top: ${exportMarginTop};
      }
    `}
`;

export type CellMouseEventCallbackType = (
  data: HeatMapTypes.DataEntity,
  coords: { x: number; y: number },
  cellPositions?: {
    x: number;
    y: number;
    width: number;
    height: number;
    tooltipRight?: boolean;
  },
) => void;

interface Props {
  /** Maches print media query */
  print: boolean;
  /** Display the subject values on the right of the heatmap */
  showSubjectsValues?: boolean;
  /** Width of component (excluding factors column width) */
  width: number;
  cellHeight?: number;
  /** Width of left column with factor name */
  factorsColumnWidth?: number;
  // TODO: Remove this props after https://twosigma.atlassian.net/browse/VENN-22781
  /** Use a faster canvas-based heatmap renderer */
  useCanvas?: boolean;
  /**
   * array with description to each factor name
   * arranged in the same order as YAxis
   */
  factorsDescriptions?: FactorDescription[];
  /** Data required to display Heat Map  */
  series: HeatMapTypes.Root[];
  /** Size of heat map cells */
  seriesDataSize: number;
  /** Width of right column */
  seriesColumnWidth?: number;
  /** Value formatter for gradient's legend labels */
  topLegendFormatter?: TopLegendFormatterType;
  topLegendWidth?: number;
  topLegendThickness?: number;
  /** Colors for gradient */
  palette: string[];
  timestamps: number[];
  downloadable?: boolean;
  /**
   * Bottom legend flag
   * @default false
   */
  isPercentage?: boolean;
  onCellEnter?: CellMouseEventCallbackType;
  onCellClick?: CellMouseEventCallbackType;
  isCellClickable?: boolean;
  isFund?: boolean;
  filterCount?: number;
  metricType?: 'risk' | 'return';
  /** Flag for displaying subject names, rather than the portfolio type in the column header */
  displaySubjectNames?: boolean;
  theme: Theme;
  axisFontSize?: string;
  legendFontSize?: string;
  marginTop: string | undefined;
  exportMarginTop: string | undefined;
}

export const topMargin = 70;
// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
const HeatMap = React.memo(
  ({
    seriesColumnWidth = 80,
    factorsColumnWidth = 180,
    topLegendThickness = 14,
    topLegendWidth = 180,
    cellHeight: cellHeightOuter = 33,
    onCellEnter,
    seriesDataSize,
    print,
    series,
    width,
    timestamps,
    palette,
    topLegendFormatter,
    isPercentage,
    factorsDescriptions,
    downloadable,
    isCellClickable,
    onCellClick,
    isFund,
    filterCount,
    metricType,
    displaySubjectNames,
    showSubjectsValues,
    useCanvas,
    theme,
    axisFontSize,
    legendFontSize,
    marginTop,
    exportMarginTop,
  }: Props) => {
    const wrapperProps = {
      marginTop,
      exportMarginTop,
    };

    const [selectedCell, setSelectedCell] = useState<{ x: number; y: number } | undefined>(undefined);
    const [seriesValue, setSeriesValue] = useState<number | null | undefined>(undefined);
    const [clickedValue, setClickedValue] = useState<number | null | undefined>(undefined);
    const [clickedCell, setClickedCell] = useState<{ x: number; y: number } | undefined>(undefined);

    const rightOffset = useMemo(() => {
      const seriesColumnCount = series[0].series.length;
      return seriesColumnWidth * seriesColumnCount;
    }, [series, seriesColumnWidth]);

    const handleOnSVGMouseLeave = useCallback(() => {
      setSelectedCell(undefined);
      setSeriesValue(undefined);
    }, []);

    const handleCellOnClick: MouseEventType = useCallback(
      ({ columnId, rowId }, cellPosition) => {
        if (!isCellClickable || !onCellClick) {
          return;
        }
        const dataEntity = series[rowId].series[0].data[columnId];
        onCellClick(
          dataEntity,
          {
            x: columnId,
            y: rowId,
          },
          cellPosition,
        );
        setSelectedCell(undefined);
        setClickedValue(dataEntity?.value);
        setClickedCell({ x: columnId, y: rowId });
      },
      [isCellClickable, onCellClick, series],
    );

    const handleCellEnter = useCallback(
      ({ columnId, rowId }: { columnId: number; rowId: number }) => {
        const dataEntity = series[rowId].series[0].data[columnId];
        const seriesValue = dataEntity?.value;

        setSelectedCell({
          x: columnId,
          y: rowId,
        });

        setSeriesValue(seriesValue);
        onCellEnter?.(dataEntity, {
          x: columnId,
          y: rowId,
        });
      },
      [onCellEnter, series],
    );

    const cellWidth = useMemo(() => {
      const seriesColumnCount = series[0].series.length;
      const elementWidth = width - factorsColumnWidth;

      return (elementWidth - seriesColumnWidth * seriesColumnCount) / seriesDataSize;
    }, [factorsColumnWidth, series, seriesColumnWidth, seriesDataSize, width]);

    const cellHeight = cellHeightOuter ?? 0;

    const computedSeries = useMemo(
      () =>
        series.map((serie) => ({
          factorId: serie.factorId,
          factorName: serie.name,
        })),
      [series],
    );

    const onClickOutside = () => {
      setClickedCell(undefined);
      setSelectedCell(undefined);
      setSeriesValue(undefined);
      setClickedValue(undefined);
    };

    const descriptions = useMemo(
      () =>
        compact([
          ...(factorsDescriptions ?? []),
          metricType === 'return'
            ? {
                id: RISK_FREE_RATE_ID,
                description: 'Represents the return of short-term sovereign bond yields.',
              }
            : undefined,
          metricType
            ? {
                id: RESIDUAL_ID,
                description: `Represents the ${metricType} not explained by the Two Sigma Factor Lens.`,
              }
            : undefined,
        ]),
      [factorsDescriptions, metricType],
    );

    const oneSeries = useMemo(() => series?.[0]?.series || [], [series]);
    const labels = useMemo(
      () =>
        oneSeries
          .filter((serie) => !!serie.portfolioType)
          .map((serie) => ({
            name: serie.portfolioType!,
            value: serie.name,
          })),
      [oneSeries],
    );

    const mainSerie = series?.[0]?.series?.[0];
    const data = mainSerie?.data || [];
    const mainSubjectName = displaySubjectNames ? mainSerie?.name : mainSerie?.portfolioType;
    const computedAreaWidth = data.length * cellWidth;
    const computedAreaHeight = cellHeight * series.length;
    const valuelabels = useMemo(
      () => compact(oneSeries.map((serie) => (displaySubjectNames ? serie.name : serie.portfolioType))),
      [displaySubjectNames, oneSeries],
    );

    if (cellWidth <= 0) {
      return null;
    }

    const showPrintStyle = downloadable || print;
    const footerHeight = BOTTOM_SPACE;
    const messageHeight = filterCount ? MESSAGE_HEIGHT : 0;
    const computedHeight = topMargin + computedAreaHeight + footerHeight + messageHeight;
    const highlightCell = clickedCell || selectedCell;
    const selectedColumn = showPrintStyle ? (mainSerie?.data?.length || 1) - 1 : highlightCell?.x;
    const printX = factorsColumnWidth + (selectedColumn ?? 0) * cellWidth;
    const { Colors } = theme;

    const svgMap = (
      <>
        <OverlaySVG
          width={width}
          height={computedHeight}
          xmlns="http://www.w3.org/2000/svg"
          shapeRendering="geometricPrecision"
        >
          <TopLegend
            y={topMargin - 70}
            isPercentage={isPercentage}
            value={seriesValue || clickedValue}
            extremes={mainSerie.extremes}
            palette={palette}
            thickness={topLegendThickness}
            width={topLegendWidth}
            labelFormatter={topLegendFormatter}
            name={mainSubjectName}
            print={print}
            fontSize={legendFontSize}
          />
          <g transform={`translate(0, ${topMargin})`}>
            {showSubjectsValues && (
              <Values
                isPercentage={!!isPercentage}
                labels={valuelabels}
                source={series}
                selectedColumn={selectedColumn}
                height={cellHeight}
                width={seriesColumnWidth}
                offset={factorsColumnWidth + computedAreaWidth}
              />
            )}
            {highlightCell && (
              <Cursor
                height={cellHeight}
                width={cellWidth}
                x={factorsColumnWidth + highlightCell.x * cellWidth}
                y={highlightCell.y * cellHeight}
                areaHeight={computedAreaHeight}
                areaWidth={width}
              />
            )}
            {selectedCell && (
              <Highlight
                isPercentage={!!isPercentage}
                series={series}
                cellWidth={cellWidth}
                selectedRow={selectedCell.y}
                selectedCell={selectedCell.x}
                x={factorsColumnWidth + selectedCell.x * cellWidth}
                y={selectedCell.y * cellHeight}
                width={width}
                xAxis={timestamps}
                displaySubjectNames={displaySubjectNames}
              />
            )}
          </g>
        </OverlaySVG>
        <MapSvg
          width={width}
          height={computedHeight}
          xmlns="http://www.w3.org/2000/svg"
          shapeRendering="geometricPrecision"
          onMouseLeave={handleOnSVGMouseLeave}
        >
          <g transform={`translate(0, ${topMargin})`}>
            {clickedCell && (
              <rect fill={LIGHT_GREY} x={0} y={clickedCell.y * cellHeight} width={width} height={cellHeight} />
            )}
            <YAxis
              fontSize={axisFontSize}
              height={cellHeight}
              width={factorsColumnWidth}
              series={series}
              hoveringId={selectedCell ? selectedCell.x : null}
              textAnchor="end"
            />
            <Lines margin={cellHeight} count={series.length} />
            <ExternalActivityListener listeningEnabled={!!clickedCell} onExternalActivity={onClickOutside} svg>
              {useCanvas ? (
                <CanvasHeatMapRenderer
                  leftOffset={factorsColumnWidth}
                  rightOffset={rightOffset}
                  series={series}
                  cellHeight={cellHeight}
                  cellWidth={cellWidth}
                  onCellMouseEnter={handleCellEnter}
                  isCellClickable={isCellClickable}
                  onCellClick={onCellClick ? handleCellOnClick : undefined}
                  print={print}
                />
              ) : (
                <HeatMapRenderer
                  leftOffset={factorsColumnWidth}
                  rightOffset={rightOffset}
                  series={series}
                  cellHeight={cellHeight}
                  cellWidth={cellWidth}
                  onCellMouseEnter={handleCellEnter}
                  isCellClickable={isCellClickable}
                  onCellClick={onCellClick ? handleCellOnClick : undefined}
                  print={print}
                  fontSize={axisFontSize}
                />
              )}

              {!!filterCount && (
                <InsignificantFactorMessage
                  value={filterCount}
                  y={computedAreaHeight + BOTTOM_SPACE}
                  width={width}
                  showPrintStyle={showPrintStyle}
                  fontSize={axisFontSize}
                />
              )}
            </ExternalActivityListener>

            <Border />
            <Border y={computedAreaHeight} />
            {!!showPrintStyle && showSubjectsValues && (
              <rect
                x={printX - 1}
                y={0}
                width={cellWidth + 2}
                height={computedAreaHeight}
                fill={Colors.White}
                fillOpacity={0}
                strokeWidth={2}
                stroke={Colors.Black}
              />
            )}
            <Ticks
              cellWidth={cellWidth}
              print={print}
              offset={factorsColumnWidth}
              timestamps={timestamps}
              y={computedAreaHeight}
              showSubjectsValues={showSubjectsValues}
              fontSize={axisFontSize}
            />
          </g>
        </MapSvg>
      </>
    );

    if (downloadable) {
      return <Wrapper {...wrapperProps}>{svgMap}</Wrapper>;
    }

    const topOffset = topMargin - 3 * LEGEND_LINE_HEIGHT - 5;

    return (
      <Wrapper {...wrapperProps}>
        <FactorTooltips
          topOffset={topMargin}
          itemHeight={cellHeight}
          series={computedSeries}
          descriptions={descriptions}
          itemWidth={`${factorsColumnWidth}px`}
        />
        <PortfolioNamesTooltip
          labels={labels}
          columnWidth={seriesColumnWidth}
          topOffset={topOffset}
          leftOffset={computedAreaWidth + (factorsColumnWidth ?? 0)}
          isFund={isFund}
        />
        {svgMap}
      </Wrapper>
    );
  },
);

export default HeatMap;

const OverlaySVG = styled.svg`
  position: absolute;
  pointer-events: none;
  padding-right: 1rem;
  /**
  * Do not remove
  * will-change is needed to prompt the browser to optimise the compositing
  * of the overlay elements over the heatmap
  */
  will-change: transform;
`;

const MapSvg = styled.svg`
  padding-right: 1rem;
`;
