import { useRecoilState } from 'recoil';
import { blockMetrics, blockCustomMetricSettingsState } from 'venn-state';
import { makeCustomMetric } from 'venn-utils';

export const useCustomMetrics = (blockId: string) => {
  const [blockCustomMetricSettings, setBlockCustomMetricSettings] = useRecoilState(
    blockCustomMetricSettingsState(blockId),
  );
  const [selectedMetrics, setSelectedMetrics] = useRecoilState(blockMetrics(blockId));
  const addMetric = (metricName: string) => {
    const newMetric = makeCustomMetric(metricName);
    setBlockCustomMetricSettings([...blockCustomMetricSettings, newMetric]);
    setSelectedMetrics([...selectedMetrics, newMetric.key]);
  };

  const updateMetric = (oldMetricKey: string, newMetricName: string) => {
    const currentMetric = blockCustomMetricSettings.find((metric) => metric.key === oldMetricKey);
    if (currentMetric?.label === newMetricName) {
      return;
    }
    setBlockCustomMetricSettings(
      blockCustomMetricSettings.map((metric) =>
        metric.key === oldMetricKey ? { ...metric, label: newMetricName } : metric,
      ),
    );
  };

  const clearMetric = (metricKey: string) => {
    setSelectedMetrics(selectedMetrics.filter((metric) => metric !== metricKey));
  };

  const selectedCustomMetrics = blockCustomMetricSettings.filter((metric) => selectedMetrics.includes(metric.key));

  return { addMetric, selectedCustomMetrics, clearMetric, updateMetric };
};
