import React, { useContext } from 'react';
import {
  Modal,
  ModalFooter,
  ModalHeader,
  ModalContent,
  StudioContext,
  ModalSize,
  UserContext,
  MODAL_FOOTER_PRIMARY_BTN_ID,
} from 'venn-components';
import { CheckboxWrapper, GetColor } from 'venn-ui-kit';
import styled from 'styled-components';
import { toggleStudioTemplateType } from 'venn-utils';
import ReportingSection from './ReportSettings';
import Details from './Details';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { analysisViewTypeState, isReportState, isTemplateState } from 'venn-state';
import { getTopBarColor, type GetTopBarColorType } from '../top-bar/shared';

export interface SetupConfigurationModalProps {
  onCancel: () => void;
}

const TemplateConfiguration = () => {
  const { hasPermission } = useContext(UserContext);
  const isReport = useRecoilValue(isReportState);
  const isTemplate = useRecoilValue(isTemplateState);
  const barColors = getTopBarColor({ isReport, isTemplate });

  const onChange = useRecoilCallback(
    ({ set }) =>
      () => {
        set(analysisViewTypeState, (viewType) => toggleStudioTemplateType(viewType));
      },
    [],
  );

  return (
    <TemplateWrapper barColors={barColors}>
      <CheckboxWrapper
        checked={isTemplate}
        onChange={onChange}
        disabled={!hasPermission('STUDIO_CREATE_TEMPLATE')}
        justify="left"
      >
        Assign as a template
      </CheckboxWrapper>
    </TemplateWrapper>
  );
};

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
const SetupConfigurationModal = React.memo(({ onCancel }: SetupConfigurationModalProps) => {
  const { isCheckingDuplicateReportName } = useContext(StudioContext);
  const isReport = useRecoilValue(isReportState);

  return (
    <StyledModal
      closeOnEsc
      initialFocus={`#${MODAL_FOOTER_PRIMARY_BTN_ID}`}
      onClose={onCancel}
      className="qa-configuration-modal"
      size={ModalSize.Medium}
    >
      <StyledModalHeader>{isReport ? 'Report Lab' : 'Studio Analysis'} Setup</StyledModalHeader>
      <StyledModalContent>
        <PanelContent>
          <TemplateConfiguration />
          <Details />
          <ReportingSection />
        </PanelContent>
      </StyledModalContent>
      <StyledModalFooter
        primaryLabel="Close"
        onPrimaryClick={onCancel}
        primaryDisabled={isCheckingDuplicateReportName}
      />
    </StyledModal>
  );
});

export default SetupConfigurationModal;

const StyledModal = styled(Modal)`
  &.modal-wrapper {
    height: 600px;
  }
`;

const StyledModalHeader = styled(ModalHeader)`
  margin: 0px;
  border-bottom: 1px solid ${GetColor.GreyScale.Grey30};
  padding: 0 20px 10px 20px;
`;
const StyledModalFooter = styled(ModalFooter)`
  margin-top: 0px;
  border: 0px;
`;

const StyledModalContent = styled(ModalContent)`
  display: block;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-y: auto;
`;
const PanelContent = styled.div`
  margin: 10px 20px;
`;

const TemplateWrapper = styled.div<{ barColors: GetTopBarColorType }>`
  color: ${GetColor.White};
  padding: 3px 10px;
  background: ${({ barColors }) => barColors.Background};
`;
