import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { ModalFooter, AlternateModalHeader, VennDialog } from 'venn-components';
import { Label, GetColor, Button, Warning, PORTFOLIO_COMPARISON_HELP_HREF, Body2 } from 'venn-ui-kit';
import { useForm } from 'react-hook-form';
import { useCustomMetrics } from './hooks/useCustomMetrics';
import { type Metric, type VennDialogRef } from 'venn-utils';

interface CustomMetricsDialogProps {
  blockId: string;
  metric?: Metric;
}
interface CustomMetricsDialogFormData {
  columnTitle: string;
}

const FRIENDLY_NAMES: Record<keyof CustomMetricsDialogFormData, string> = {
  columnTitle: 'Field title',
};

const MAX_NAME_LENGTH = 20;

export const CustomMetricsDialog = forwardRef<VennDialogRef, CustomMetricsDialogProps>(function CustomMetricsDialog(
  props: CustomMetricsDialogProps,
  ref,
) {
  return (
    <VennDialog ref={ref}>{({ close: onClose }) => <CustomMetricsForm {...props} onClose={onClose} />}</VennDialog>
  );
});

export const CustomMetricsForm = ({ blockId, metric, onClose }: CustomMetricsDialogProps & { onClose: () => void }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<CustomMetricsDialogFormData>({
    mode: 'all',
    defaultValues: {
      columnTitle: metric?.label,
    },
  });
  watch();
  const { addMetric, selectedCustomMetrics, updateMetric } = useCustomMetrics(blockId);
  const onSubmit = handleSubmit((data) => {
    const newName = data.columnTitle;
    metric ? updateMetric(metric.key, newName) : addMetric(newName);
    onClose();
  });
  return (
    <form data-testid="custom-metrics-form" onSubmit={onSubmit}>
      <AlternateModalHeader>{metric ? 'Edit' : 'Add'} Custom Field</AlternateModalHeader>
      <ContentWrapper>
        {DIALOG_TEXT}
        <FormField>
          <Label>{FRIENDLY_NAMES.columnTitle}</Label>
          <input
            placeholder="Enter field title"
            {...register('columnTitle', {
              maxLength: {
                value: MAX_NAME_LENGTH,
                message: `${FRIENDLY_NAMES.columnTitle} cannot exceed ${MAX_NAME_LENGTH} characters.`,
              },
              required: `${FRIENDLY_NAMES.columnTitle} is required.`,
              validate: {
                unique: (name) =>
                  !selectedCustomMetrics.find(
                    (existingMetric) => existingMetric.label === name && existingMetric.key !== metric?.key,
                  ) || `${FRIENDLY_NAMES.columnTitle} must be unique.`,
                nonWhiteSpace: (name) => name.length > 0 || `${FRIENDLY_NAMES.columnTitle} is required.`,
              },
              setValueAs: (value) => (typeof value === 'string' ? value?.trim() : value),
            })}
          />
          <FieldWarning>{errors?.columnTitle?.message}</FieldWarning>
        </FormField>
        <Warning
          icon="pen-field"
          iconPrefix="fas"
          text={
            <span>
              Custom Field text does not automatically update when portfolio changes occur. Please make sure to review
              this block after making any portfolio changes. If you have any questions, please{' '}
              <a href={PORTFOLIO_COMPARISON_HELP_HREF} target="_blank" rel="noreferrer">
                click here
              </a>{' '}
              to learn more.
            </span>
          }
        />
      </ContentWrapper>
      <ModalFooter
        leftChildren={
          <Button flat type="button" onClick={onClose}>
            Cancel
          </Button>
        }
        rightChildren={
          <Button type="submit" dense dominant disabled={!!Object.keys(errors).length}>
            Confirm
          </Button>
        }
      />
    </form>
  );
};

const ContentWrapper = styled.div`
  margin: 24px 16px;
`;

const FieldWarning = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: ${GetColor.Error};
  height: 20px;
`;

const FormField = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  input {
    align-self: stretch;
    font-weight: 400;
  }
`;

const DIALOG_TEXT = (
  <Body2>
    <p>
      Custom Fields allow you to enter any text by double-clicking cells on the block. No validation or calculations are
      performed and text entered has no connection to any other metrics.
    </p>
    <p>You may also insert data from a spreadsheet by single-clicking on a cell and pasting text</p>
    <p style={{ marginBlockEnd: 24 }}>
      Adding Custom Fields will also include a footnote describing the data as having been entered by a Venn subscriber,
      not the platform.
    </p>
  </Body2>
);
