import { useRef, useCallback } from 'react';

export type VennDialogRef = {
  open: () => void;
  close: () => void;
};

export const useVennDialog = () => {
  const dialogRef = useRef<VennDialogRef>(null);
  const open = useCallback(() => dialogRef?.current?.open(), []);
  const close = useCallback(() => dialogRef?.current?.close(), []);
  return { dialogRef, open, close };
};
