import React from 'react';
import styled, { css } from 'styled-components';
import type { BasicTableColumn, BasicTableProps } from '../../../../basictable';
import { BasicTable } from '../../../../basictable';
import { REPORT_LAB_FONT_BODY, GetColor } from 'venn-ui-kit';
import { isReportState, SCALE_FACTOR } from 'venn-state';
import { useRecoilValue } from 'recoil';

const ROW_HEIGHT_PX = '32px';
const COMPACT_ROW_HEIGHT_PX = '16px';

const FIRST_COLUMN_OUTER_WIDTH = 160;
export const FIRST_COLUMN_INNER_WIDTH = 150;
export const FIRST_COLUMN_OUTER_WIDTH_PX = `${FIRST_COLUMN_OUTER_WIDTH}px`;

const FIRST_COLUMN_OUTER_WIDTH_PX_MODERN = `${FIRST_COLUMN_OUTER_WIDTH * SCALE_FACTOR}px`;

const REPORT_LAB_ROW_MIN_WIDTH = 25;
const ROW_MIN_WIDTH = 35;

export interface StyledBasicTableProps<TColumn extends BasicTableColumn<TRow>, TRow>
  extends BasicTableProps<TColumn, TRow> {
  compactRows?: boolean;
  fontSize?: string;
  isStudio: boolean;
}

const InnerStyledBasicTable = styled(
  <T extends BasicTableColumn<K>, K>({ compactRows, ...props }: StyledBasicTableProps<T, K>) => (
    <BasicTable<T, K> {...props} />
  ),
)`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;

  > thead {
    > tr {
      > th:first-child {
        padding-left: 5px;
        text-align: left;
      }
      > th:not(:first-child) {
        color: ${GetColor.DarkGrey};
        height: ${FIRST_COLUMN_OUTER_WIDTH_PX_MODERN};
        text-align: left;

        @media print {
          overflow-x: hidden;
          overflow-y: hidden;
        }
      }
      > th:first-child > div {
        max-width: ${({ isStudio }) => (isStudio ? FIRST_COLUMN_OUTER_WIDTH_PX : FIRST_COLUMN_OUTER_WIDTH_PX_MODERN)};
        text-align: left;
      }
      > th:not(:first-child) > div {
        position: absolute;
        height: 100%;
        width: 100%;
        padding: 0px;
        margin: 0px;
      }
    }
  }

  > tbody {
    > tr {
      ${({ compactRows, fontSize }) =>
        compactRows
          ? css`
              height: ${fontSize ? `calc(${fontSize} * 1.35)` : COMPACT_ROW_HEIGHT_PX};
              min-height: ${fontSize ? `calc(${fontSize} * 1.35)` : COMPACT_ROW_HEIGHT_PX};
              max-height: ${fontSize ? `calc(${fontSize} * 1.35)` : COMPACT_ROW_HEIGHT_PX};
            `
          : css`
              height: ${fontSize ? `calc(${fontSize} * 2.7)` : ROW_HEIGHT_PX};
              min-height: ${fontSize ? `calc(${fontSize} * 2.7)` : ROW_HEIGHT_PX};
              max-height: ${fontSize ? `calc(${fontSize} * 2.7)` : ROW_HEIGHT_PX};
            `}

      border-bottom: 1px solid ${GetColor.PaleGrey};
      :hover {
        background-color: ${GetColor.WhiteGrey};
        @media print {
          background-color: ${GetColor.White};
        }
      }

      > td:first-child {
        padding-left: 5px;
        max-width: ${FIRST_COLUMN_OUTER_WIDTH_PX};
        text-align: left;
        ${({ compactRows, fontSize }) =>
          compactRows &&
          css`
            font-size: ${fontSize || '10px'};
            height: ${fontSize ? `calc(${fontSize} * 1.35)` : COMPACT_ROW_HEIGHT_PX};
            min-height: ${fontSize ? `calc(${fontSize} * 1.35)` : COMPACT_ROW_HEIGHT_PX};
            max-height: ${fontSize ? `calc(${fontSize} * 1.35)` : COMPACT_ROW_HEIGHT_PX};
            padding-top: 1px;
            padding-bottom: 0px;
          `}
      }
      > td:not(:first-child) {
        text-align: center;
        padding: 0px;
        ${({ columns, isStudio }) => {
          const width = !columns?.length ? ROW_HEIGHT_PX : `calc(100% / ${columns.length - 1})`;
          return css`
            min-width: ${isStudio ? ROW_MIN_WIDTH : REPORT_LAB_ROW_MIN_WIDTH}px;
            width: ${width};
            max-width: ${width};
          `;
        }}
      }
      ${({ isStudio, fontSize }) =>
        fontSize
          ? css`
              > td {
                font-size: ${fontSize};
              }
            `
          : !isStudio &&
            css`
              > td {
                font-size: ${REPORT_LAB_FONT_BODY};
              }
            `}
    }
  }
`;

export const StyledBasicTable = (
  props: Omit<StyledBasicTableProps<BasicTableColumn<unknown>, unknown>, 'headerHeight' | 'isStudio' | 'isLegacy'>,
) => {
  const isStudio = !useRecoilValue(isReportState);
  return <InnerStyledBasicTable {...props} isStudio={isStudio} />;
};
