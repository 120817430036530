import React from 'react';
import { uniq, get } from 'lodash';
import { type FontOverridesFormData, fontOverridesFormSections } from './logic/fontSizeDefaultsLogic';
import { ModalContent, ModalHeader, ModalSize, ModalWrapper, ModalFooter } from 'venn-components';
import { Headline4 } from 'venn-ui-kit';
import { type FormState } from 'react-hook-form';

import styled from 'styled-components';

interface ConfirmationDialogProps {
  formState: FormState<FontOverridesFormData>;
  onPrimaryClick: () => void;
  onClose: () => void;
}

const DialogContentRoot = styled.div`
  width: ${ModalSize.Medium}px;
  display: flex;
  flex-direction: column;
`;

const EXISTING = {
  heading: 'Changes will affect all existing reports.',
  content:
    'Updates to the following will apply to all existing reports: title pages, section pages, and footers and disclosures/disclaimers.',
};

const NEW = {
  heading: 'Changes will only affect newly-created reports and blocks.',
  content: 'Updates to block font sizes will only affect new reports and new blocks added to existing reports.',
};

const EXISTING_AND_NEW = {
  heading: 'Changes will affect existing and newly-created reports.',
  content:
    'Updates to block font sizes will only affect new reports and new blocks added to existing reports. Updates to the following will apply to all existing reports: title pages, section pages, and footers and disclosures/disclaimers.',
};
export const FontSizeConfirmationDialog = ({ formState, onPrimaryClick, onClose }: ConfirmationDialogProps) => {
  const { dirtyFields } = formState;
  const affects = uniq(
    fontOverridesFormSections
      .filter((section) => section.fields.some((field) => get(dirtyFields, field.path) === true))
      .map((section) => section.affects),
  );
  const { heading, content } =
    affects.includes('existing') && affects.includes('new')
      ? EXISTING_AND_NEW
      : affects.includes('existing')
        ? EXISTING
        : NEW;

  return (
    <DialogContentRoot>
      <ModalWrapper noPadding={false}>
        <ModalHeader>{heading}</ModalHeader>
        <ModalContent>
          <Headline4>{content}</Headline4>
        </ModalContent>
      </ModalWrapper>
      <ModalFooter primaryLabel="Confirm" onPrimaryClick={onPrimaryClick} onCancel={onClose} />
    </DialogContentRoot>
  );
};
