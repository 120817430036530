import type { FrequencyEnum } from 'venn-api';

const returnsDistribution = {
  subject: {
    numReturns: 193,
    bucketSize: 0.005,
    frequency: 'MONTHLY' as FrequencyEnum,
    returns: [
      {
        name: '< -4%',
        count: 5,
      },
      {
        name: '-4% - -3.5%',
        count: 4,
      },
      {
        name: '-3.5% - -3%',
        count: 1,
      },
      {
        name: '-3% - -2.5%',
        count: 4,
      },
      {
        name: '-2.5% - -2%',
        count: 6,
      },
      {
        name: '-2% - -1.5%',
        count: 14,
      },
      {
        name: '-1.5% - -1%',
        count: 8,
      },
      {
        name: '-1% - -0.5%',
        count: 21,
      },
      {
        name: '-0.5% - 0%',
        count: 18,
      },
      {
        name: '0% - 0.5%',
        count: 14,
      },
      {
        name: '0.5% - 1%',
        count: 23,
      },
      {
        name: '1% - 1.5%',
        count: 22,
      },
      {
        name: '1.5% - 2%',
        count: 17,
      },
      {
        name: '2% - 2.5%',
        count: 15,
      },
      {
        name: '2.5% - 3%',
        count: 5,
      },
      {
        name: '3% - 3.5%',
        count: 2,
      },
      {
        name: '3.5% - 4%',
        count: 3,
      },
      {
        name: '> 4%',
        count: 11,
      },
    ],
  },
  benchmark: {
    numReturns: 193,
    bucketSize: 0.005,
    frequency: 'MONTHLY' as FrequencyEnum,
    returns: [
      {
        name: '< -4%',
        count: 3,
      },
      {
        name: '-4% - -3.5%',
        count: 0,
      },
      {
        name: '-3.5% - -3%',
        count: 0,
      },
      {
        name: '-3% - -2.5%',
        count: 2,
      },
      {
        name: '-2.5% - -2%',
        count: 5,
      },
      {
        name: '-2% - -1.5%',
        count: 2,
      },
      {
        name: '-1.5% - -1%',
        count: 12,
      },
      {
        name: '-1% - -0.5%',
        count: 15,
      },
      {
        name: '-0.5% - 0%',
        count: 23,
      },
      {
        name: '0% - 0.5%',
        count: 25,
      },
      {
        name: '0.5% - 1%',
        count: 35,
      },
      {
        name: '1% - 1.5%',
        count: 28,
      },
      {
        name: '1.5% - 2%',
        count: 21,
      },
      {
        name: '2% - 2.5%',
        count: 11,
      },
      {
        name: '2.5% - 3%',
        count: 4,
      },
      {
        name: '3% - 3.5%',
        count: 1,
      },
      {
        name: '3.5% - 4%',
        count: 6,
      },
      {
        name: '> 4%',
        count: 0,
      },
    ],
  },
};

export default returnsDistribution;
