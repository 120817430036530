import React from 'react';
import { TagsContext, useTags } from 'venn-components';

const TagsContextStore: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const contextValue = useTags();

  return <TagsContext.Provider value={contextValue}>{children}</TagsContext.Provider>;
};

export default TagsContextStore;
