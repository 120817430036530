import { Modal, ModalDescription, ModalHeader, ModalSize } from '../../../modal';
import { GetColor, ZIndex } from 'venn-ui-kit';
import { UniversalUploaderFooter } from '../components/page-parts/UniversalUploaderFooter';
import React from 'react';
import styled from 'styled-components';

export type PersistUploadConfirmationModalProps = {
  onConfirm: () => void;
  onCancel: () => void;
};

export type UploadDiscardConfirmationModalProps = {
  onConfirm: () => void;
  onCancel: () => void;
  closeEntireUploader: boolean;
};

export const DiscardUploadConfirmationModal = ({
  onCancel,
  onConfirm,
  closeEntireUploader,
}: UploadDiscardConfirmationModalProps) => {
  return (
    <Modal
      size={ModalSize.Medium}
      zIndex={ZIndex.InterComFront2}
      closeOnEsc
      onClose={onCancel}
      testId="multi-portfolio-discard-confirmation-modal"
    >
      <ModalHeader>Uploaded data will be lost</ModalHeader>
      <StyledModalDescription>
        {closeEntireUploader
          ? 'Are you sure you want to close the uploader?'
          : 'Are you sure you want to go back to the upload step?'}
      </StyledModalDescription>
      <UniversalUploaderFooter
        destructive
        onCancel={onCancel}
        cancelLabel="CONTINUE EDITING"
        primaryLabel={closeEntireUploader ? 'Discard uploaded data' : 'Discard adjustments & go back'}
        onContinue={onConfirm}
      />
    </Modal>
  );
};

export const PersistUploadConfirmationModal = ({ onCancel, onConfirm }: PersistUploadConfirmationModalProps) => {
  return (
    <Modal
      size={ModalSize.Medium}
      zIndex={ZIndex.InterComFront2}
      closeOnEsc
      onClose={onCancel}
      testId="multi-portfolio-upload-confirmation-modal"
    >
      <ModalHeader>Existing portfolios in the Data Library will be overwritten.</ModalHeader>
      <StyledModalDescription>
        Previous versions are saved in the Allocator Panel’s comparison sidebar and can be reverted to at any time.
      </StyledModalDescription>
      <UniversalUploaderFooter onCancel={onCancel} primaryLabel="Complete Upload" onContinue={onConfirm} />
    </Modal>
  );
};

const StyledModalDescription = styled(ModalDescription)`
  font-weight: bold;
  color: ${GetColor.Black};
`;
