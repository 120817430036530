import React from 'react';
import type { AutoproxyResponse } from 'venn-api';
import { Link as LinkStyle, PROXY_FAQ_HREF } from 'venn-ui-kit';
import { EmptyResults } from './EmptyResults';
import PossibleProxiesTable from './PossibleProxiesTable';
import { ReturnsCalculations } from 'venn-utils';

interface AutoproxyOptionsProps {
  autoproxyResponse?: AutoproxyResponse;
  onSelectProxyFund: (id: string, name: string) => void;
}

const AutoproxyOptions: React.FC<React.PropsWithChildren<AutoproxyOptionsProps>> = ({
  autoproxyResponse,
  onSelectProxyFund,
}) => {
  const autoproxyOptions = autoproxyResponse?.results
    ? autoproxyResponse.results.map((result) => ({
        proxy: result.proxy,
        proxiedReturns: ReturnsCalculations.toDataPoints(result.cumulativeProxiedReturns ?? []),
        proxiedStart: result.proxiedStart,
        proxiedEnd: result.proxiedEnd,
        extrapolatedStart: result.extrapolatedStart,
        correlation: result.correlation,
        percentMatch: result.percentMatch,
      }))
    : [];

  return autoproxyOptions?.length ? (
    <PossibleProxiesTable
      results={autoproxyOptions.slice(0, 5)}
      onSelect={(selected) => onSelectProxyFund(selected.id, selected.name)}
    />
  ) : (
    <EmptyResults data-testid="empty-suggestions">
      No{' '}
      <LinkStyle>
        <a href={PROXY_FAQ_HREF} target="_blank" rel="noopener noreferrer">
          potential proxies
        </a>
      </LinkStyle>{' '}
      were identified. Please enter your own proxy.
    </EmptyResults>
  );
};

export default AutoproxyOptions;
