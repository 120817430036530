import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';

export interface ModalWrapperProps {
  size?: ModalSize | number;
  ref?: (prop: HTMLElement) => void;
  noPadding: boolean;
  extraPadding?: boolean;
  smallFullScreen?: boolean;
  noBackground?: boolean;
  noBorder?: boolean;
  inverted?: boolean;
}

export enum ModalSize {
  Small = 438,
  Medium = 550,
  Large = 650,
  XLarge = 896,
  XXLarge = 1024,
}

export const ModalContent = styled.div<{ extraPadding?: boolean }>`
  padding: ${({ extraPadding }) => (extraPadding ? '10px 30px' : '10px 20px')};
`;

const ModalWrapper = styled.section<ModalWrapperProps>`
  ${(props: ModalWrapperProps) => `
    width: ${props.size || ModalSize.Medium}px;
  `};

  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  outline: none;
  overflow: visible;
  padding-top: ${({ noPadding, extraPadding }) => (noPadding ? '0px' : extraPadding ? '40px' : '20px')};
  background: ${(props) => (props.noBackground ? 'transparent' : props.inverted ? GetColor.Black : GetColor.White)};
  ${({ noBorder, noBackground, inverted }) =>
    !noBorder &&
    `
    border: 1px solid ${noBackground || inverted ? 'transparent' : GetColor.LightGrey};
  `}
  max-height: calc(100% - 40px);
  ${(props) =>
    props.smallFullScreen &&
    `
    @media (max-width: 1024px) {
      width: 100%;
      height: 100%;
      > div {
        min-height: 100%;
      }
    }
  `};
`;

export default ModalWrapper;
