import type { AnalysisGroup } from 'venn-utils';
import type { DrawdownPeriodAnalysis } from 'venn-api';

export const convertAnalysisDrawDown = (
  analysisGroup: AnalysisGroup<Partial<DrawdownPeriodAnalysis>[]>,
): Partial<DrawdownPeriodAnalysis>[] => {
  if (!analysisGroup || !analysisGroup.subject || analysisGroup.subject.length === 0) {
    return [];
  }
  const { subject, benchmark, category: baseline } = analysisGroup;
  return subject.map((drawdown, index) => ({
    benchmarkMaxDrawdown: benchmark?.[index]?.maxDrawdown,
    benchmarkMaxDrawdownError: benchmark?.[index]?.maxDrawdownError,
    benchmarkResidualRisk: benchmark?.[index]?.residualRisk,
    end: drawdown?.end,
    completed: drawdown?.completed,
    masterPortfolioMaxDrawdown: baseline?.[index]?.maxDrawdown,
    masterPortfolioMaxDrawdownError: baseline?.[index]?.maxDrawdownError,
    masterPortfolioResidualRisk: baseline?.[index]?.residualRisk,
    maxDrawdown: drawdown?.maxDrawdown,
    maxDrawdownError: drawdown?.maxDrawdownError,
    residualRisk: drawdown?.residualRisk,
    start: drawdown?.start,
    trough: drawdown?.trough,
  }));
};
