import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { partial, noop } from 'lodash';
import styled, { css } from 'styled-components';
import { GetColor } from '../../../style/color';
import Icon from '../../icon/Icon';
import type { DateRange } from '../types';
import type { Moment } from 'moment';
import moment from 'moment';

interface CalendarProps {
  /**
   * UTC Date represented as a Unix Timestamp
   */
  value?: number;
  /**
   * Maximum date range
   */
  range?: DateRange;
  /**
   * Force 6 rows in the calendar (so two calendars side-by-side are
   * certain to display 6 rows each)
   */
  long?: boolean;
  className?: string;
  /**
   * Triggered on user selecting a date. Returns a UTC Unix Timestamp
   */
  onChange: (date: number) => void;
}

export const Calendar = ({ value, range, long, className, onChange }: CalendarProps) => {
  const valueDate = useMemo(() => moment.utc(value).startOf('day'), [value]);
  const [visibleMonth, setVisibleMonth] = useState(valueDate.clone().startOf('month'));
  const cells: moment.Moment[][] = useMemo(() => getDays(visibleMonth, long), [visibleMonth, long]);
  const handlePreviousMonth = useCallback(() => {
    setVisibleMonth(visibleMonth.clone().add(-1, 'month'));
  }, [visibleMonth]);
  const handleNextMonth = useCallback(() => {
    setVisibleMonth(visibleMonth.clone().add(1, 'month'));
  }, [visibleMonth]);
  const handleSelect = useCallback(
    (date: Moment) => {
      onChange(date.valueOf());
      if (date.month() !== visibleMonth.month()) {
        setVisibleMonth(date.clone().startOf('month'));
      }
    },
    [onChange, visibleMonth],
  );
  useEffect(() => {
    setVisibleMonth(valueDate.clone().startOf('month'));
  }, [valueDate]);
  const isDisabled = (date: moment.Moment) => {
    if (range) {
      if (!range.to || date.valueOf() > range.to || !range.from || date.valueOf() < range.from) {
        return true;
      }
    }

    return false;
  };
  return (
    <div>
      <Container className={className}>
        <Header>
          <NavIcon type="chevron-circle-left" prefix="fal" onClick={handlePreviousMonth} tabIndex={-1} />
          <Month>{visibleMonth.format('MMMM YYYY')}</Month>
          <NavIcon type="chevron-circle-right" prefix="fal" onClick={handleNextMonth} tabIndex={-1} />
        </Header>
        <Row>
          <CellHeader>Su</CellHeader>
          <CellHeader>Mo</CellHeader>
          <CellHeader>Tu</CellHeader>
          <CellHeader>We</CellHeader>
          <CellHeader>Th</CellHeader>
          <CellHeader>Fr</CellHeader>
          <CellHeader>Sa</CellHeader>
        </Row>

        {cells.map((row: moment.Moment[], i: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <Row key={String(i)}>
            {row.map((date) => {
              const disabled = isDisabled(date);
              return (
                <Cell
                  disabled={disabled}
                  otherMonth={date.month() !== visibleMonth.month()}
                  selected={date.valueOf() === valueDate.valueOf()}
                  onClick={disabled ? noop : partial(handleSelect, date)}
                  key={date.valueOf()}
                >
                  {date.date()}
                </Cell>
              );
            })}
          </Row>
        ))}
      </Container>
    </div>
  );
};

const NavIcon = styled(Icon)`
  color: ${GetColor.Primary.Dark};
  font-size: 12px;
  margin: 0 8px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  margin-bottom: 5px;
`;

const Month = styled.div`
  color: ${GetColor.MidGrey2};
  font-size: 12px;
  font-weight: bold;
`;

const Container = styled.div`
  border: 1px solid ${GetColor.MidGrey2};
  display: inline-block;
`;

const Cell = styled.div<{ selected: boolean; otherMonth: boolean; disabled: boolean }>`
  width: 30px;
  height: 30px;
  border-left: 1px solid ${GetColor.PaleGrey};
  border-top: 1px solid ${GetColor.PaleGrey};
  text-align: center;
  vertical-align: middle;
  line-height: 30px;
  cursor: pointer;

  color: ${(props) => (props.disabled || props.otherMonth ? GetColor.LightGrey : GetColor.Black)};

  ${(props) =>
    props.selected
      ? css`
          color: ${GetColor.White};
          background-color: ${GetColor.Primary.Dark};
        `
      : ''}

  &:first-child {
    border-left: none;
  }

  &:hover {
    background-color: ${GetColor.Primary.Medium};
  }
`;

const CellHeader = styled.div`
  width: 30px;
  color: ${GetColor.LightGrey};
  text-align: center;
  font-size: 10;
`;

const Row = styled.div`
  display: flex;
  font-size: 1.125rem;
`;

const getDays = (date: Moment, long?: boolean): Moment[][] => {
  const matrix: Moment[][] = [];
  const firstDay = date.clone().startOf('month');
  const lastDay = date.clone().endOf('month');
  const firstDate = firstDay.clone().subtract(firstDay.weekday(), 'days');

  let current = firstDate.clone();

  while (current < lastDay || (long ? matrix.length < 6 : false)) {
    const row: Moment[] = [];
    matrix.push(row);
    for (let i = 0; i < 7; i++) {
      row.push(current.clone());
      current = current.add(1, 'day');
    }
  }
  return matrix;
};

export default Calendar;
