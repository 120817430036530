import React, { useEffect, useState } from 'react';
import { TagDropdown } from 'venn-components';
import { TooltipPosition } from 'venn-ui-kit';
import type { AnalysisSubject } from 'venn-utils';
import { logExceptionIntoSentry, useApi } from 'venn-utils';
import { getFundTags, getPortfolioTags } from 'venn-api';

type TagButtonProps = {
  subject?: AnalysisSubject;
};

const TagButton = ({ subject }: TagButtonProps) => {
  const getTagsForFund = useApi(getFundTags);
  const getTagsForPortfolio = useApi(getPortfolioTags);
  const [currentTags, setCurrentTags] = useState<Set<string>>(new Set());
  const [loading, setLoading] = useState<boolean>(true);

  const noSubject = !subject;
  const subjectIsStrategy = subject?.isStrategyInPortfolio;
  const subjectIsPortfolio = subject?.type === 'portfolio';
  const portfolioId = subject?.portfolio?.id;
  const fundId = subject?.fund?.id;

  useEffect(() => {
    const getTags = async () => {
      if (noSubject) {
        return;
      }

      let tags: string[] = [];
      setLoading(true);

      try {
        if (subjectIsPortfolio && !subjectIsStrategy) {
          const response = await getTagsForPortfolio(portfolioId!);
          tags = response.content;
        } else if (fundId) {
          const response = await getTagsForFund(fundId);
          tags = response.content;
        }
      } catch (e) {
        if (e?.name !== 'AbortError') {
          logExceptionIntoSentry(e);
        }
      }

      setCurrentTags(new Set(tags));
      setLoading(false);
    };

    getTags();
  }, [getTagsForPortfolio, getTagsForFund, noSubject, subjectIsPortfolio, subjectIsStrategy, portfolioId, fundId]);

  if (noSubject || subjectIsStrategy || loading) {
    return null;
  }

  const item = [subjectIsPortfolio ? { portfolioId } : { fundId }];

  return (
    <TagDropdown
      itemsToTag={item}
      currentTags={currentTags}
      border
      solid
      iconSize={18}
      borderSize={10}
      left={360}
      placeholderTooltip={`Tag ${subject?.name}`}
      tooltipPosition={TooltipPosition.Left}
    />
  );
};

export default TagButton;
