import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';

export const Content: FC<React.PropsWithChildren<{ className?: string }>> = ({ children, className }) => (
  <StyledContent className={className}>{children}</StyledContent>
);

export default Content;

const StyledContent = styled.div`
  flex: 1;
  min-height: 0;
`;
