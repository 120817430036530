import { selector, waitForAll } from 'recoil';
import type { Subject, SubjectWithOptionalFee } from '../types';
import { compact, isEqual, isUndefined, omitBy, uniqBy } from 'lodash';
import {
  blockSubjectInputGroups,
  subjectInputGroups,
  subjectInputGroupSubjects,
} from './input-management/subjectInput';
import { selectedBlockIdState } from '../sessionState';
import { subjectToKeyString } from './subjectKey';
import { getSubjectId } from '../utils';
import { allBlockIdsState } from '../grid';
import { blockBenchmarkSubjects } from './benchmark';

// TODO(will): IMV2 change categories to fix subject groups
type SubjectCategory = 'globalSubjects' | 'currentBlockSubjects' | 'otherBlockSubjects';

type SelectorSubjectGroup = { [key in SubjectCategory]: Subject[] };
type SelectorPortfolioGroup = { [key in SubjectCategory]: Subject[] };

/** @returns the unique set of subjects using a deep equality comparison. */
export const getUniqueSubjects = (subjects: Subject[]) => uniqBy(subjects, subjectToKeyString);

/** @returns whether the subjects are deeply equal, ignoring undefined fields */
export const subjectsEqual = (subject: Subject, otherSubject: Subject) =>
  isEqual(omitBy(subject, isUndefined), omitBy(otherSubject, isUndefined));

export const allSubjects = selector<SubjectWithOptionalFee[]>({
  key: 'allSubjects',
  get: ({ get }) => {
    const allSubjectGroupIds = get(subjectInputGroups);
    return get(waitForAll(allSubjectGroupIds.map((id) => subjectInputGroupSubjects(id)))).flat();
  },
});

export const allUniqViewSubjects = selector<SelectorSubjectGroup>({
  key: 'allUniqViewSubjects',
  get: ({ get }) => {
    const currentBlockId = get(selectedBlockIdState);
    const blockSubjectGroups = currentBlockId ? get(blockSubjectInputGroups(currentBlockId)) : [];
    const allCurrentSubjects = getUniqueSubjects(
      get(waitForAll(blockSubjectGroups.map((groupId) => subjectInputGroupSubjects(groupId)))).flat(),
    );
    const otherBlockSubjects = getUniqueSubjects(
      get(allSubjects).filter((s) => !allCurrentSubjects.some((s2) => subjectsEqual(s, s2))),
    );

    return {
      globalSubjects: [],
      currentBlockSubjects: allCurrentSubjects,
      otherBlockSubjects,
    };
  },
});

export const allViewPortfolioSubjects = selector<SelectorPortfolioGroup>({
  key: 'allViewPortfolioSubjects',
  get: ({ get }) => {
    const allSubjects = get(allUniqViewSubjects);

    const allCurrentSubjects = allSubjects.currentBlockSubjects;
    const allViewSubjects = allSubjects.otherBlockSubjects;

    const filteredCurrentSubjects = uniqBy(
      allCurrentSubjects.filter((s) => s.portfolioId || s.privatePortfolioId),
      (subject) => subject.portfolioId ?? subject.privatePortfolioId,
    );
    const filteredOtherSubjects = uniqBy(
      allViewSubjects
        .filter((s) => s.portfolioId || s.privatePortfolioId)
        .filter((s) => !filteredCurrentSubjects.some((s2) => subjectsEqual(s, s2))),
      (subject) => subject.portfolioId ?? subject.privatePortfolioId,
    );

    return {
      globalSubjects: [],
      currentBlockSubjects: filteredCurrentSubjects,
      otherBlockSubjects: filteredOtherSubjects,
    };
  },
});

export const allUniqViewSubjectsFlattened = selector({
  key: 'allUniqViewSubjectsFlattened',
  get: ({ get }) => {
    const allSubjects = get(allUniqViewSubjects);
    return getUniqueSubjects(
      [allSubjects.globalSubjects, allSubjects.currentBlockSubjects, allSubjects.otherBlockSubjects].flat(),
    );
  },
});

export const allUniqBenchmarkSubjects = selector({
  key: 'allUniqBenchmarkSubjects',
  get: ({ get }) => {
    const blockIds = get(allBlockIdsState);
    const benchmarks = compact(blockIds.flatMap((blockId) => get(blockBenchmarkSubjects(blockId))));
    return getUniqueSubjects(benchmarks);
  },
});

export const allUniqViewSubjectsAndBenchmarks = selector({
  key: 'allUniqViewSubjectsAndBenchmarks',
  get: ({ get }) => {
    const subjects = get(allUniqViewSubjectsFlattened);
    const benchmarks = get(allUniqBenchmarkSubjects);
    return getUniqueSubjects([subjects, benchmarks].flat());
  },
});

export const allViewSubjectIds = selector<string[]>({
  key: 'allViewSubjectIds',
  get: ({ get }) => {
    const allSubjects = get(allUniqViewSubjects);
    return [allSubjects.globalSubjects, allSubjects.currentBlockSubjects, allSubjects.otherBlockSubjects]
      .flat()
      .map((subject) => getSubjectId(subject));
  },
});
