import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import type { Id, SearchResultWithUIState, SearchState } from './types';
import TagSelector from './TagSelector';

interface TagProps {
  item: SearchResultWithUIState;
  updateData: React.Dispatch<React.SetStateAction<SearchState>>;
  setIdWithMenuOpen: (ItemId: Id) => void;
}

const Tag = ({ item, setIdWithMenuOpen, updateData }: TagProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const tags = item.tags || [];

  const onClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
  }, []);

  const onClose = useCallback(() => {
    setOpen(false);
    setIdWithMenuOpen({});
  }, [setIdWithMenuOpen]);

  const onOpen = useCallback(() => {
    setOpen(true);
    setIdWithMenuOpen({ fundId: item.fundId, portfolioId: item.portfolioId });
  }, [setIdWithMenuOpen, item.fundId, item.portfolioId]);

  return (
    <DropdownWrapper onClick={onClick} className="tag-item-icon" visible={open || !!tags.length}>
      <TagSelector updateData={updateData} searchItems={[item]} onClose={onClose} onOpen={onOpen} />
    </DropdownWrapper>
  );
};

export default Tag;

const DropdownWrapper = styled.span<{ visible: boolean }>`
  ${(props) => !props.visible && 'display: none;'}
  text-align: left;
  &:focus {
    display: inline;
  }
`;
