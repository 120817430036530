import styled from 'styled-components';
import { Icon, GetColor, Tooltip } from 'venn-ui-kit';

export const Table = styled.table`
  width: 100%;
  margin-top: 10px;
  color: ${GetColor.HintGrey};
  && > thead,
  && > tbody {
    > tr {
      > th,
      > td {
        text-align: right;
        white-space: nowrap;
      }

      > td:nth-child(3) {
        padding-top: 4px;
        color: ${GetColor.Black};
      }

      > td:nth-child(1) {
        width: 78px;
      }

      > td:nth-of-type(3),
      > th:nth-of-type(1),
      > td:nth-last-child(1),
      > td:nth-last-child(2),
      > th:nth-last-child(3),
      > th:nth-last-child(2),
      > th:nth-last-child(1) {
        text-align: left;
      }

      > th:nth-last-child(1),
      > td:nth-child(1),
      > td:nth-child(2),
      > td:nth-last-child(1) {
        // Date Range
        padding-right: 3px;
      }
    }
  }

  > thead {
    > tr {
      height: 35px;
      > th {
        padding-right: 10px;
        font-size: 12px;
        color: ${GetColor.Black};
      }
    }
  }

  > tbody {
    > tr {
      padding: 8px 12px 8px 20px;
      height: 26px;
      > td {
        padding-right: 10px;
        font-size: 14px;
        @media print {
          font-size: 12px;
        }
      }

      &:hover {
        background-color: ${GetColor.PaleGrey};
        cursor: pointer;
      }
    }
  }
`;

export const InfoIcon = styled(Icon)`
  color: ${GetColor.Black};
  cursor: pointer;
`;

export const StyledTooltip = styled(Tooltip)`
  position: absolute;
  text-align: center;
`;

export const StyledProxySpan = styled.span`
  padding-left: 20px;
  cursor: default;
`;
