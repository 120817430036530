import React from 'react';
import { Icon, GetColor } from 'venn-ui-kit';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Routes } from 'venn-utils';
import type { AnalysisViewTypeEnum } from 'venn-api';

interface SavedViewMessageProps {
  type?: AnalysisViewTypeEnum;
}

const SavedViewMessage = ({ type }: SavedViewMessageProps) => (
  <Link to={`${Routes.REPORTS}${type ? `?itemType=${type}` : ''}`}>
    <Message>
      View saved. Click to view all saved views in <BookIcon type="book" /> Reporting
    </Message>
  </Link>
);

export default SavedViewMessage;

const Message = styled.span`
  color: ${GetColor.White};
`;

const BookIcon = styled(Icon)`
  padding: 0 5px;
`;
