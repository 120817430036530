/*
 * Venn Platform API 1.0.0 Operations for "forecast"
 * Automatically generated file - do not modify!
 */

import type {
  CMAGrouping,
  ComputedInvestmentResidual,
  CustomFactorForecast,
  InvestmentResidualOverride,
} from '../types';
import type { OperationResult } from '../../utils';
import { buildRequestInit, extractError, retryGeneratedFetch } from '../../utils';

/** Add or update new factor forecasts with CMAs for a user */
export async function addOrUpdateFactorForecastForUser(
  body?: Partial<CustomFactorForecast>,
  signal?: AbortSignal,
): Promise<OperationResult<CustomFactorForecast[]>> {
  const endpoint = '/api/forecast/factor/multi';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: CustomFactorForecast[] = (await response.json()) as CustomFactorForecast[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Delete residual forecast for investment */
export async function clearInvestmentResidualForecasts(
  investmentId: string,
  signal?: AbortSignal,
): Promise<OperationResult<ComputedInvestmentResidual[]>> {
  const endpoint = `/api/forecast/residual/${encodeURIComponent(investmentId)}`;
  const requestInit = buildRequestInit({ method: 'DELETE', signal });
  const response = await retryGeneratedFetch('DELETE', () => fetch(endpoint, requestInit));
  if ((response.status >= 200 && response.status < 400) || response.status === 404) {
    const content: ComputedInvestmentResidual[] = (await response.json()) as ComputedInvestmentResidual[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get all investment residual overrides */
export async function getAllInvestmentResiduals(
  signal?: AbortSignal,
): Promise<OperationResult<ComputedInvestmentResidual[]>> {
  const endpoint = '/api/forecast/residual';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: ComputedInvestmentResidual[] = (await response.json()) as ComputedInvestmentResidual[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get force zero residual forecast flag for workspace */
export async function getForceZeroResidualForecastForWorkspace(
  signal?: AbortSignal,
): Promise<OperationResult<boolean>> {
  const endpoint = '/api/forecast/residual/force-zero-residual-forecast';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: boolean = (await response.json()) as boolean;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get force zero residual forecast flag for workspace */
export async function setForceZeroResidualForecastForWorkspace(
  body?: boolean,
  signal?: AbortSignal,
): Promise<OperationResult<boolean>> {
  const endpoint = '/api/forecast/residual/force-zero-residual-forecast';
  const requestInit = buildRequestInit({
    method: 'PUT',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await retryGeneratedFetch('PUT', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: boolean = (await response.json()) as boolean;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get list of all possible CMAs for all factors */
export async function getAllFactorCMAs(signal?: AbortSignal): Promise<OperationResult<CMAGrouping[]>> {
  const endpoint = '/api/forecast/factor/multi/cma/all';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: CMAGrouping[] = (await response.json()) as CMAGrouping[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get list of possible CMAs for a user */
export async function getFactorCMAs(signal?: AbortSignal): Promise<OperationResult<CMAGrouping[]>> {
  const endpoint = '/api/forecast/factor/multi/cma';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: CMAGrouping[] = (await response.json()) as CMAGrouping[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get list of the UUIDs of all indices used as CMAs for all factors */
export async function getAllFactorCMAIndexUUIDs(signal?: AbortSignal): Promise<OperationResult<string[]>> {
  const endpoint = '/api/forecast/factor/multi/cma/index-ids';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: string[] = (await response.json()) as string[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get residual override for specific investment */
export async function getInvestmentResidual(
  investmentId: string,
  signal?: AbortSignal,
): Promise<OperationResult<ComputedInvestmentResidual>> {
  const endpoint = `/api/forecast/residual/${encodeURIComponent(investmentId)}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: ComputedInvestmentResidual = (await response.json()) as ComputedInvestmentResidual;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get the factor forecasts for a user */
export async function getFactorForecasts(signal?: AbortSignal): Promise<OperationResult<CustomFactorForecast[]>> {
  const endpoint = '/api/forecast/factor/multi';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: CustomFactorForecast[] = (await response.json()) as CustomFactorForecast[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get workspace factor forecast */
export async function getCurrentForecast(signal?: AbortSignal): Promise<OperationResult<CustomFactorForecast>> {
  const endpoint = '/api/forecast/factor/default';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: CustomFactorForecast = (await response.json()) as CustomFactorForecast;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Marks the given factor forecast as default */
export async function markFactorForecastAsDefault(
  id: string,
  signal?: AbortSignal,
): Promise<OperationResult<CustomFactorForecast[]>> {
  const endpoint = `/api/forecast/factor/multi/${encodeURIComponent(id)}/default`;
  const requestInit = buildRequestInit({ method: 'POST', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: CustomFactorForecast[] = (await response.json()) as CustomFactorForecast[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Override the CMA groupings */
export async function addCMAs(
  body?: { [key: string]: string[] },
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = '/api/forecast/factor/multi/cma';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Remove the factor forecast for a user */
export async function removeFactorForecastForUser(
  id: string,
  signal?: AbortSignal,
): Promise<OperationResult<CustomFactorForecast[]>> {
  const endpoint = `/api/forecast/factor/multi/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({ method: 'DELETE', signal });
  const response = await retryGeneratedFetch('DELETE', () => fetch(endpoint, requestInit));
  if ((response.status >= 200 && response.status < 400) || response.status === 404) {
    const content: CustomFactorForecast[] = (await response.json()) as CustomFactorForecast[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Retrieve residual forecasts for collection of investments */
export async function getBatchInvestmentResiduals(
  body?: string[],
  signal?: AbortSignal,
): Promise<OperationResult<{ [key: string]: ComputedInvestmentResidual }>> {
  const endpoint = '/api/forecast/residual/investments';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await retryGeneratedFetch('POST', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: { [key: string]: ComputedInvestmentResidual } = (await response.json()) as {
      [key: string]: ComputedInvestmentResidual;
    };
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Store investment residual forecasts */
export async function storeInvestmentResidualForecasts(
  body?: InvestmentResidualOverride[],
  signal?: AbortSignal,
): Promise<OperationResult<ComputedInvestmentResidual[]>> {
  const endpoint = '/api/forecast/residual';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: ComputedInvestmentResidual[] = (await response.json()) as ComputedInvestmentResidual[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
