import React, { useContext } from 'react';
import SubjectLink from './SubjectLink';
import type { ICellRendererParams } from '../../../../data-grid';
import type { CustomRowData } from '../../../types';
import { isNil } from 'lodash';
import { Tooltip } from 'venn-ui-kit';
import { getComparisonLabelForBlockLegend, getComparisonLabelForTooltip } from 'venn-utils';
import { ThemeContext } from 'styled-components';

interface SubjectRowCellRendererProps extends ICellRendererParams {
  data: CustomRowData;
}

export const SubjectRowCellRenderer = ({ data: { headerComponentParams } }: SubjectRowCellRendererProps) => {
  const { Colors } = useContext(ThemeContext);

  if (!headerComponentParams || !headerComponentParams.subject) {
    return '--';
  }

  const { subject, isCommonBenchmark, color } = headerComponentParams;

  const { portfolioComparisonType, primaryPortfolio, portfolio, strategy } = subject;

  return !isNil(portfolioComparisonType) && !isNil(portfolio) ? (
    <Tooltip
      content={
        primaryPortfolio
          ? getComparisonLabelForTooltip(portfolio, strategy, portfolioComparisonType, primaryPortfolio)
          : undefined
      }
      isHidden={isNil(primaryPortfolio)}
      usePortal
    >
      {getComparisonLabelForBlockLegend(subject)}
    </Tooltip>
  ) : (
    <SubjectLink
      subject={subject}
      customColor={color ?? Colors.Black}
      headerMode={false}
      isCommonBenchmark={isCommonBenchmark}
    />
  );
};
