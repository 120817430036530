import type { ExcelCell } from 'venn-utils';
import type {
  Entity as CorrelationsChartEntity,
  DrawdownCorrelation,
} from '../../../../factor-correlations-chart/types';
import type { DrawdownFactorInfo } from 'venn-api';

export function factorCorrelationsToExcel(
  correlationData?: CorrelationsChartEntity<DrawdownCorrelation>[],
): ExcelCell[][] | undefined {
  if (!correlationData) {
    return undefined;
  }
  const rows: ExcelCell[][] = correlationData.map((factor) => [
    { value: factor.name, bold: true },
    ...new Array(correlationData.length).fill(null).map(() => ({ value: '', digits: 2 })),
    { value: '' },
  ]);
  correlationData.forEach((factor, factorIndex) => {
    factor.correlations.forEach((cor, corIndex) => {
      rows[corIndex + factorIndex + 1][factorIndex + 1].value = cor.value;
    });
  });
  return [
    [
      { value: '' },
      ...correlationData.map((factor) => ({
        value: factor.name,
        bold: true,
      })),
    ],
    ...rows,
  ];
}

export function factorReturnsToExcel(
  drawdownFactorInfoList: DrawdownFactorInfo[] | undefined,
  period: string,
): ExcelCell[][] | undefined {
  if (!drawdownFactorInfoList) {
    return undefined;
  }
  return [
    [
      { value: 'Factor', bold: true },
      { value: `Factor return during ${period}`, bold: true },
      { value: 'Historical average', bold: true },
    ],
    ...drawdownFactorInfoList.map((info) => [
      { value: info.factorName, bold: true },
      { value: info.annualizedReturn, percentage: true },
      { value: info.historicalAnnualizedReturn, percentage: true },
    ]),
  ];
}
