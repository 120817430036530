import type { DrawdownRangeAnalysis } from 'venn-api';

const portfolioInvestments = {
  fundDrawdownContribution: [
    {
      name: 'Managed Futures Investment Category (MF)',
      contribution: 1.5044366703328926,
      allocation: 3,
      returnValue: -0.12929468570679525,
      factorContribution: [
        {
          factorId: 10000,
          factorName: 'Equity',
          contribution: 0.06120572732991593,
        },
        {
          factorId: 10004,
          factorName: 'Foreign Currency',
          contribution: -0.08658219562030313,
        },
        {
          factorId: 10005,
          factorName: 'Local Inflation',
          contribution: 0,
        },
        {
          factorId: 10008,
          factorName: 'Local Equity',
          contribution: 0,
        },
        {
          factorId: 10039,
          factorName: 'Trend Following',
          contribution: 0.34798891529422543,
        },
      ],
      residualContribution: 0.4622959680980249,
      cashContribution: -0.21607379171058014,
    },
    {
      name: 'Other Investment Category (HF)',
      contribution: -0.5044366703328927,
      allocation: 3,
      returnValue: 0.04335242688231067,
      factorContribution: [
        {
          factorId: 10000,
          factorName: 'Equity',
          contribution: -1.0443934913976938,
        },
        {
          factorId: 10004,
          factorName: 'Foreign Currency',
          contribution: 1.6462436592555194,
        },
        {
          factorId: 10005,
          factorName: 'Local Inflation',
          contribution: -0.43710150872594616,
        },
        {
          factorId: 10008,
          factorName: 'Local Equity',
          contribution: 0,
        },
        {
          factorId: 10039,
          factorName: 'Trend Following',
          contribution: 0.34108882321697354,
        },
      ],
      residualContribution: 0.04608219003059188,
      cashContribution: -0.24227179255371828,
    },
  ],
  strategyDrawdownContribution: [
    {
      name: 'uncategorized',
      contribution: 1.9044366703328926,
      allocation: 6,
      returnValue: -0.32929468570679525,
      factorContribution: [
        {
          factorId: 10000,
          factorName: 'Equity',
          contribution: 0.16120572732991593,
        },
      ],
      residualContribution: null,
      cashContribution: -0.71607379171058014,
    },
  ],
};

export default portfolioInvestments as unknown as DrawdownRangeAnalysis;
