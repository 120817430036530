import { useEffect } from 'react';
import { noop } from 'lodash';

export const useDisablePrintHotkey = (disableHotkey: boolean, onPrintAttempt: () => void) => {
  useEffect(() => {
    if (!disableHotkey) {
      return noop;
    }

    const listener = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'p') {
        event.cancelBubble = true;
        event.preventDefault();
        event.stopImmediatePropagation();
        onPrintAttempt();
      }
    };

    window.addEventListener('keydown', listener);

    return () => window.removeEventListener('keydown', listener);
  }, [disableHotkey, onPrintAttempt]);
};
