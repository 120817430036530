import React, { useCallback } from 'react';
import { CurrencyEnum } from 'venn-api';
import styled from 'styled-components';
import SimpleFilter from './SimpleFilter';

const fieldName = 'Currency';

const CURRENCY_OPTIONS = Object.values(CurrencyEnum || {}).map((currency) => ({
  label: currency,
  value: currency,
}));

interface CurrencyFilterProps {
  className?: string;
  onFilter: (updated: CurrencyEnum[]) => void;
  selected: CurrencyEnum[];
  closeOnOnlyClick?: boolean;
  isAdvanced?: boolean;
}

const CurrencyFilter = ({ className, selected, onFilter, closeOnOnlyClick, isAdvanced }: CurrencyFilterProps) => {
  const handleFilter = useCallback(
    (updated: string | string[]) => {
      const updatedCurrencyEnum = updated as CurrencyEnum[];
      onFilter(Array.from(updatedCurrencyEnum));
    },
    [onFilter],
  );

  return (
    <>
      <StyledFilter
        className={className}
        label={fieldName}
        items={CURRENCY_OPTIONS}
        initialSelected={selected}
        onFilter={handleFilter}
        closeOnOnlyClick={closeOnOnlyClick}
        isAdvanced={isAdvanced}
      />
    </>
  );
};

const StyledFilter = styled(SimpleFilter)`
  display: inline-flex;
`;

export default CurrencyFilter;
