import React, { PureComponent } from 'react';
import type { DrawdownPeriodAnalysis, FrequencyEnum } from 'venn-api';
import DrawdownTable from '../../../../drawdown-table/DrawdownTable';
import { ConfigurationLabel } from '../../../../configuration/index';
import DownloadableContentBlock from '../../../../content-block/DownloadableContentBlock';
import convertToExcel from '../../../../basictable/convertToExcel';
import Input from '../../../../input/Input';
import styled, { withTheme } from 'styled-components';
import type { AnalysisGroup, AnalysisSubject } from 'venn-utils';
import { SpecialCssClasses } from 'venn-utils';
import type { Theme } from 'venn-ui-kit';
import { GetColor, KeyCodes } from 'venn-ui-kit';
import { convertAnalysisDrawDown } from '../utils';
import { getColumns } from '../../../../drawdown-table/getColumns';
import MediaQuery from 'react-responsive';
import type { TrackAnalysisProps } from '../../../chart-errors/TrackAnalysis';
import { TrackSuccessfulAnalysis } from '../../../chart-errors/TrackAnalysis';
import { AnalysisViewContext, blockLevelDefaultSettings } from '../../../../contexts';
import type { DownloadMetaData } from '../../../../downloadable';

interface ProformaDrawdownAnalysisProps {
  subject: AnalysisSubject;
  relative: boolean;
  analysisGroup: AnalysisGroup<Partial<DrawdownPeriodAnalysis>[]>;
  frequency?: FrequencyEnum;
  noChartTrackingProps: TrackAnalysisProps;
  initialThreshold?: number;
  onUpdateThreshold?: (value: number) => void;
  theme: Theme;
  downloadMetaData?: DownloadMetaData;
}

interface ProformaDrawdownAnalysisState {
  threshold: number;
  inputValue: string;
}

const MAX_THRESHOLD = 0;

class ProformaDrawdownAnalysis extends PureComponent<ProformaDrawdownAnalysisProps, ProformaDrawdownAnalysisState> {
  constructor(props: ProformaDrawdownAnalysisProps) {
    super(props);

    const threshold = props.initialThreshold ?? blockLevelDefaultSettings.historicalDrawdownThreshold;
    this.state = {
      threshold,
      inputValue: threshold.toFixed(1),
    };
  }

  render() {
    const { relative, analysisGroup, subject, frequency, noChartTrackingProps, theme, downloadMetaData } = this.props;
    const drawdownPeriods = convertAnalysisDrawDown(analysisGroup);
    const drawdownPeriodsError = analysisGroup?.message;
    const { threshold, inputValue } = this.state;
    const header = relative ? 'Historical Underperformance Periods' : 'Historical Drawdown Periods';
    const inputText = relative ? 'Underperformance Threshold' : 'Drawdown Threshold';
    const formatThreshold = Number((threshold / 100).toFixed(3));
    const isPortfolio = subject?.type === 'portfolio';
    const filteredData: Partial<DrawdownPeriodAnalysis>[] = drawdownPeriods.filter(
      (period) => Number(((period.maxDrawdown ?? NaN) * 100).toFixed(1)) <= formatThreshold * 100,
    );

    return (
      <MediaQuery print>
        {(print) => {
          const cols = getColumns(subject, drawdownPeriods, analysisGroup, false, relative, frequency, print, theme);
          // @ts-expect-error: TODO fix strictFunctionTypes
          const excelData = convertToExcel(filteredData, cols);
          return (
            <DownloadableContentBlock
              header={header}
              alignOptions="bottom"
              downloadable={{
                png: true,
                excel: excelData,
                tracking: {
                  description: 'DRAWDOWN_HISTORY',
                  relativeToBenchmark: relative,
                  subjectType: subject.type,
                  subjectId: subject.id,
                  userUploaded: subject.userUploaded,
                },
                options: {
                  fileName: `${header} - ${subject.name}`,
                  metaData: downloadMetaData
                    ? { ...downloadMetaData, extra: [{ label: inputText, value: `${inputValue}%` }] }
                    : undefined,
                },
                disabled: filteredData.length === 0,
              }}
              rightOptions={
                <DrawdownLabel label={`${inputText}:`} className="qa-drawdown-threshold">
                  <StyledInput
                    onClick={this.onClick}
                    onBlur={this.onThresholdBlur}
                    onChange={this.onThresholdChange}
                    onKeyDown={this.onThresholdKeydown}
                    value={inputValue}
                  />
                </DrawdownLabel>
              }
            >
              <TrackSuccessfulAnalysis {...noChartTrackingProps}>
                <DrawdownTable
                  hasAnalyzeError={false}
                  drawdownPeriods={drawdownPeriods}
                  selectedRow={null}
                  threshold={formatThreshold}
                  relative={relative}
                  isPortfolio={isPortfolio}
                  error={drawdownPeriodsError}
                  columns={cols}
                />
              </TrackSuccessfulAnalysis>
            </DownloadableContentBlock>
          );
        }}
      </MediaQuery>
    );
  }

  private onClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.currentTarget.select();
  };

  private onThresholdKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === KeyCodes.Enter) {
      e.currentTarget.blur();
    }
  };

  private onThresholdBlur = () => {
    const { onUpdateThreshold } = this.props;
    const threshold = Number(this.state.inputValue);
    if (!Number.isNaN(threshold)) {
      const fixedThreshold = Number(threshold.toFixed(1));
      const validateThreshold = fixedThreshold > 0 ? fixedThreshold * -1 : fixedThreshold;
      this.setState({ threshold: validateThreshold, inputValue: `${validateThreshold.toFixed(1)}` });
      onUpdateThreshold?.(validateThreshold);
      return;
    }
    this.setState({ threshold: MAX_THRESHOLD, inputValue: `${MAX_THRESHOLD}` });
    onUpdateThreshold?.(MAX_THRESHOLD);
  };

  private onThresholdChange = (inputValue: string) => this.setState({ inputValue });
}

export default withTheme((props: ProformaDrawdownAnalysisProps) => (
  <AnalysisViewContext.Consumer>
    {({ historicalDrawdownThreshold, onUpdateAnalysisViewParam }) => (
      <ProformaDrawdownAnalysis
        {...props}
        initialThreshold={historicalDrawdownThreshold}
        onUpdateThreshold={(value: number) =>
          onUpdateAnalysisViewParam({
            historicalDrawdownThreshold: value,
          })
        }
      />
    )}
  </AnalysisViewContext.Consumer>
));

const DrawdownLabel = styled(ConfigurationLabel)`
  .${SpecialCssClasses.ExportAsImage} & {
    display: none;
  }

  @media print {
    position: static;
    right: 0px;
    top: 0px;
    text-align: left;
    margin-left: 8px;
  }
`;

const StyledInput = styled(Input)`
  width: 100px;
  border: 1px solid ${GetColor.Grey};
  border-radius: 0;

  @media print {
    border: none;
    width: 60px;
  }

  &:before {
    content: '%';
    right: 8px;
    position: absolute;
    color: ${GetColor.MidGrey2};
  }
`;
