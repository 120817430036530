import type { ActionEnum, UserProfileSettings, UserSettings } from 'venn-api';
import { VENN_WELCOME_MODAL_VIEWED_KEY } from 'venn-api';
import { isNil } from 'lodash';
import { SKIPPED_TO_HOME } from './onboardingSharedValues';
import { isWealthManagerTabDisplayed } from '../onboarding/onboardingUtils';

const isSponsored = (profile?: UserProfileSettings) => {
  if (!profile) {
    return false;
  }
  const { sponsor } = profile;
  return !isNil(sponsor);
};

const shouldShowTutorial = (
  profile?: UserProfileSettings,
  settings?: UserSettings,
  hasPermission?: (permission: ActionEnum) => boolean,
): boolean => {
  return (
    // Wealth management first-time experience isn't shown on home tutorial
    (!hasPermission || !isWealthManagerTabDisplayed(hasPermission)) &&
    // User hasn't dismissed the Welcome modal on Welcome To Venn template yet
    !settings?.user[VENN_WELCOME_MODAL_VIEWED_KEY] &&
    // User hasn't skipped to home
    !localStorage?.getItem(SKIPPED_TO_HOME) &&
    // User signed up no earlier than a month before the feature was launched OR is on a special list of inactive clients we want to re-engage
    (profile?.user?.created ?? 0) > new Date('2020-09-29').getTime()
  );
};

export function getUserInfo(
  profile?: UserProfileSettings,
  settings?: UserSettings,
  hasPermission?: (permission: ActionEnum) => boolean,
) {
  return {
    isSponsored: isSponsored(profile),
    shouldShowTutorial: shouldShowTutorial(profile, settings, hasPermission),
  };
}

export * from './onboardingSharedValues';
