import { isNil } from 'lodash';
import { atom, atomFamily, DefaultValue, selectorFamily } from 'recoil';
import type { ComputedInvestmentResidual } from 'venn-api';
import { getAllInvestmentResiduals } from 'venn-api';
import { Notifications, NotificationType } from 'venn-ui-kit';
import { isRequestSuccessful, logExceptionIntoSentry } from 'venn-utils';
import { selectorWithAuth } from '../utils/selectorWithAuth';
import type { EmptyRightPanelView, ForecastTab } from './commonTypes';

export type InvestmentOverrideType = 'return' | 'residual';
export type InvestmentForecastView = {
  tab: ForecastTab.InvestmentForecast;
  detail: InvestmentForecastRightPanelView;
};

export type InvestmentOverrideOperation = 'CREATE' | 'EDIT';

export type InvestmentForecastRightPanelView =
  | {
      type: 'InvestmentOverrideCreator';
    }
  | {
      type: 'InvestmentOverrideEditor';
    }
  | EmptyRightPanelView;

export const defaultInvestmentOverridesList = selectorWithAuth<ComputedInvestmentResidual[]>({
  key: 'defaultResidualOverridesList',
  get: async () => {
    try {
      const response = await getAllInvestmentResiduals();
      if (isRequestSuccessful(response)) {
        return response.content;
      }
      Notifications.notify('Unable to load investment overrides', NotificationType.ERROR);
      return [];
    } catch (error) {
      Notifications.notify('Unable to load investment overrides', NotificationType.ERROR);
      logExceptionIntoSentry(error);
      return [];
    }
  },
});

export const remoteInvestmentOverridesList = atom<ComputedInvestmentResidual[]>({
  key: 'remoteInvestmentOverridesList',
  default: defaultInvestmentOverridesList,
});

/**
 * prefer using selectedOverrideAtomSyncedWithOverrideType unless
 * we want to manually specify a selected override type.
 */
export const selectedInvestmentOverrideAtom = atomFamily<
  ComputedInvestmentResidual | undefined,
  InvestmentOverrideOperation
>({
  key: 'selectedInvestmentOverrideAtom',
  default: undefined,
});

export const selectedInvestmentOverrideTypeAtom = atomFamily<InvestmentOverrideType, InvestmentOverrideOperation>({
  key: 'selectedInvestmentOverrideTypeAtom',
  default: 'return',
});

/**
 * selectedOverrideAtomSyncedWithOverrideType selector is used to update
 * the overrideType with the selected override's type. This information is
 * used in rendering the currently selected override type radio button on
 * the right forecast panel.
 * This selector should be used instead of selectedInvestmentOverrideAtom
 * unless we want to manually select the radio button for a given investment.
 */
export const selectedInvestmentOverrideAtomSyncedWithOverrideType = selectorFamily<
  ComputedInvestmentResidual | undefined,
  InvestmentOverrideOperation
>({
  key: 'selectedOverrideAtomSyncedWithOverrideType',
  get:
    (key) =>
    ({ get }) =>
      get(selectedInvestmentOverrideAtom(key)),
  set:
    (key) =>
    ({ set }, newValue) => {
      if (newValue instanceof DefaultValue) {
        set(selectedInvestmentOverrideTypeAtom(key), 'return');
        set(selectedInvestmentOverrideAtom(key), undefined);
        return;
      }
      const isResidualOverride = !isNil(newValue?.overriddenResidual);
      const isReturnOverride = !isNil(newValue?.overriddenReturn);
      if (isResidualOverride) {
        set(selectedInvestmentOverrideTypeAtom(key), 'residual');
      } else if (isReturnOverride) {
        set(selectedInvestmentOverrideTypeAtom(key), 'return');
      } else {
        set(selectedInvestmentOverrideTypeAtom(key), 'return');
      }
      set(selectedInvestmentOverrideAtom(key), newValue);
    },
});

export const shouldShowInvestmentOverrideFooter = selectorFamily<boolean, InvestmentOverrideOperation>({
  key: 'shouldShowInvestmentOverrideFooter',
  get:
    (key) =>
    ({ get }) => {
      return !get(isInvestmentOverrideSubmitCancelButtonsDisabledAtom(key));
    },
});

export const isInvestmentOverrideSubmitCancelButtonsDisabledAtom = selectorFamily<boolean, InvestmentOverrideOperation>(
  {
    key: 'isInvestmentOverrideSubmitCancelButtonsDisabled',
    get:
      (key) =>
      ({ get }) => {
        const overrideValue = get(selectedInvestmentOverrideValueAtom(key));
        const overrideType = get(selectedInvestmentOverrideTypeAtom(key));
        const selectedOverride = get(selectedInvestmentOverrideAtomSyncedWithOverrideType(key));
        const fundId = selectedOverride?.fundId;
        const isOverrideValueEqualToCurrentOverride =
          (overrideType === 'residual' && overrideValue === selectedOverride?.overriddenResidual) ||
          (overrideType === 'return' && overrideValue === selectedOverride?.overriddenReturn);

        return !fundId || overrideValue === undefined || isOverrideValueEqualToCurrentOverride;
      },
  },
);

export const selectedInvestmentOverrideValueAtom = atomFamily<number | undefined, InvestmentOverrideOperation>({
  key: 'selectedInvestmentOverrideValueAtom',
  default: undefined,
});

export const investmentOverrideSearchQuery = atom<string>({
  key: 'investmentOverrideSearchQuery',
  default: '',
});

export const investmentOverrideShouldScrollToAddForm = atom<boolean>({
  key: 'investmentOverrideShouldScrollToAddForm',
  default: false,
});
