import React from 'react';
import { Modal, ModalSize, ModalFooter } from '../../modal';
import styled from 'styled-components';
import InviteTeam from './InviteTeam';

export interface InviteTeamModalProps {
  onCancel: () => void;
  onDidSendInvitations?: () => void;
}

const InviteTeamModal = ({ onCancel, onDidSendInvitations }: InviteTeamModalProps) => (
  <StyledModal onClose={onCancel} closeOnEsc size={ModalSize.Large} closeOnOutsideClick>
    <Content>
      <InviteTeam onDidSendInvitations={onDidSendInvitations} />
    </Content>
    <ModalFooter onCancel={onCancel} cancelClassName="qa-close-button" />
  </StyledModal>
);

export default InviteTeamModal;

const StyledModal = styled(Modal)`
  &.modal-wrapper {
    text-align: center;
    overflow-y: auto;
    max-height: 90%;
  }
`;
const Content = styled.div`
  padding-bottom: 80px;
`;
