const factorResponse = {
  start: 1584662399999,
  end: 1584662399999,
  maxStart: 794102399999,
  maxEnd: 1584662399999,
  frequency: 'DAILY',
  entities: [
    {
      id: '10000',
      name: 'Equity',
      type: 'FACTOR',
      category: 'Core Macro',
      periodReturn: 0.00782926961595809,
      periodAnnualized: false,
      annualizedReturn: 6.655938029797938,
      annualizedSharpe: null,
      annualizedVolatility: null,
      cumulativeReturns: [
        [1584575999999, 0],
        [1584662399999, 0.00782926961595809],
      ],
      correlation: {
        10044: null,
      },
      factorInput: false,
    },
    {
      id: '10044',
      name: 'Small Cap',
      type: 'FACTOR',
      category: 'Equity Styles',
      periodReturn: 0.020844269962073003,
      periodAnnualized: false,
      annualizedReturn: 216.98584963451185,
      annualizedSharpe: null,
      annualizedVolatility: null,
      cumulativeReturns: [
        [1584575999999, 0],
        [1584662399999, 0.020844269962073003],
      ],
      correlation: {},
      factorInput: false,
    },
  ],
  inputMappings: [],
};

const singleFactorResponse = {
  start: 1584662399999,
  end: 1584662399999,
  maxStart: 31622399999,
  maxEnd: 1584662399999,
  frequency: 'DAILY',
  entities: [
    {
      id: '10000',
      name: 'Equity',
      type: 'FACTOR',
      category: 'Core Macro',
      periodReturn: 0.00782926961595809,
      periodAnnualized: false,
      annualizedReturn: 6.655938029797938,
      annualizedSharpe: null,
      annualizedVolatility: null,
      cumulativeReturns: [
        [1584575999999, 0],
        [1584662399999, 0.00782926961595809],
      ],
      correlation: {
        'USD Risk Free Rate': null,
        'Equity Input': null,
      },
      factorInput: false,
    },
    {
      id: 'USD Risk Free Rate',
      name: 'USD Risk Free Rate',
      type: 'FACTOR_INPUT',
      category: 'USD Risk Free Rate',
      periodReturn: 0.0000018505747125807659,
      periodAnnualized: false,
      annualizedReturn: 0.00048311621613650324,
      annualizedSharpe: null,
      annualizedVolatility: null,
      cumulativeReturns: [
        [1584575999999, 0],
        [1584662399999, 0.0000018505747125807659],
      ],
      correlation: {
        'Equity Input': null,
      },
      factorInput: true,
    },
    {
      id: 'Equity Input',
      name: 'Equity Input',
      type: 'FACTOR_INPUT',
      category: 'Equity Input',
      periodReturn: 0.00783112019067067,
      periodAnnualized: false,
      annualizedReturn: 6.65960799752999,
      annualizedSharpe: null,
      annualizedVolatility: null,
      cumulativeReturns: [
        [1584575999999, 0],
        [1584662399999, 0.00783112019067067],
      ],
      correlation: {},
      factorInput: true,
    },
  ],
  inputMappings: [
    {
      id: 'Equity Input',
      inputs: [
        {
          start: 31622399999,
          end: 1010447999999,
          nestedInputs: [
            {
              name: 'GDP-weighted sum of constituent country equity gross returns',
              contribution: 1,
            },
          ],
        },
        {
          start: 1010447999999,
          end: 1585699199999,
          nestedInputs: [
            {
              name: 'MSCI All Country World Index, currency hedged',
              contribution: 1,
            },
          ],
        },
      ],
    },
  ],
};

export { factorResponse, singleFactorResponse };
