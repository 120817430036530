import type { FocusEvent } from 'react';
import React from 'react';
import styled, { withTheme } from 'styled-components';
import Modal from '../../modal/Modal';
import ModalHeader, { ModalSubhead } from '../../modal/ModalHeader';
import ModalFooter from '../../modal/ModalFooter';
import { ModalContent, ModalSize } from '../../modal/components/ModalWrapper';
import Input from '../../input/Input';

import Label from './Label';
import StrategyDropdown from './StrategyDropdown';
import type { DropMenuItem, Theme } from 'venn-ui-kit';
import { GetColor, ColorUtils } from 'venn-ui-kit';
import type { Portfolio } from 'venn-api';
import { portfolioToSearchMenuItem, SearchMenuBar } from '../../search-menu';
import ConditionalOverlay from '../../conditional-overlay';

const PORTFOLIO_LAB_SELECTOR_CLASSNAME = 'portfolio-lab-selector-style';

export interface EvaluateInPortfolioModalContentProps {
  isLoading: boolean;

  headerText: string;
  subheaderText?: string;
  buttonText: string;
  strategySelectorText?: string;
  portfolioSelectorText?: string;
  allocationText: string;

  selectedPortfolio?: Portfolio;

  strategies: DropMenuItem<Portfolio>[];
  selectedStrategy?: Portfolio;

  allocationError?: string;

  onClose(): void;
  onSubmit(): void;

  onSelectPortfolio(portfolio: Portfolio): void;
  onSelectStrategy(strategy?: Portfolio): void;
  onChangeAllocation(value: string | null): void;

  theme: Theme;
  portfolioLabStyles?: boolean;
}

const EvaluateInPortfolioModalContent = ({
  isLoading,
  headerText,
  subheaderText,
  buttonText,
  strategySelectorText = 'Add investment to strategy:',
  portfolioSelectorText = 'Select a portfolio:',
  allocationText,
  selectedPortfolio,
  strategies,
  selectedStrategy,
  allocationError,
  onClose,
  onSubmit,
  onSelectPortfolio,
  onSelectStrategy,
  onChangeAllocation,
  theme: { Colors },
  portfolioLabStyles,
}: EvaluateInPortfolioModalContentProps) => (
  <Modal onClose={onClose} size={ModalSize.Large}>
    <ConditionalOverlay center condition={isLoading}>
      <ModalHeader>{headerText}</ModalHeader>
      {subheaderText && <ModalSubhead>{subheaderText}</ModalSubhead>}
      <StyledContent>
        <StyledGroup>
          <Label label={portfolioSelectorText}>
            <SearchMenuBar
              smallScreen
              autofocus={false}
              value={selectedPortfolio && portfolioToSearchMenuItem(selectedPortfolio)}
              isClearable
              portfoliosOnly
              onSelected={(item) => item.value?.portfolio && onSelectPortfolio(item.value.portfolio)}
              location="Evaluate in portfolio modal"
              privateAssetSearchMode="PUBLIC_ONLY"
            />
          </Label>
        </StyledGroup>
        <StyledRow>
          <StyledInvestment>
            <Label label={strategySelectorText}>
              <StrategyDropdown
                maxWidth={366}
                selected={selectedStrategy}
                options={strategies}
                placeholder={selectedPortfolio !== undefined ? selectedPortfolio.name : undefined}
                onChange={onSelectStrategy}
                className={portfolioLabStyles ? PORTFOLIO_LAB_SELECTOR_CLASSNAME : undefined}
              />
            </Label>
          </StyledInvestment>
          <StyledAllocation>
            <Label label={allocationText}>
              <StyledAllocationInput>
                <StyledInput
                  placeholder="Ex. 25"
                  onBlur={(event: FocusEvent<HTMLInputElement>) => onChangeAllocation(event.target.value)}
                  error={allocationError !== undefined}
                  errorColor={Colors.DEPRECATED_DivergingColor.A3}
                  selectOnFocus
                  data-testid="qa-allocation-input"
                />
              </StyledAllocationInput>
            </Label>
            {allocationError && <StyledError>{allocationError}</StyledError>}
          </StyledAllocation>
        </StyledRow>
      </StyledContent>
    </ConditionalOverlay>
    <ModalFooter
      primaryLabel={buttonText}
      primaryClassName="qa-optimize-button"
      primaryDisabled={!!allocationError || isLoading}
      onPrimaryClick={onSubmit}
      onCancel={onClose}
    />
  </Modal>
);

export default withTheme(EvaluateInPortfolioModalContent);

const StyledContent = styled(ModalContent)`
  .Select {
    height: 35px;
    border-radius: 3px;
    .Select-control {
      border-width: 0;
    }
  }

  .${PORTFOLIO_LAB_SELECTOR_CLASSNAME} > .qa-dropmenu-trigger {
    border-radius: 15px;
    background-color: ${ColorUtils.hex2rgbaFrom(GetColor.Primary.Dark, 0.1)};
  }
  ,
  .${PORTFOLIO_LAB_SELECTOR_CLASSNAME} > .qa-dropmenu-trigger > input {
    background-color: transparent;
  }
`;

const StyledGroup = styled.div`
  padding-bottom: 20px;
`;

const StyledInput = styled(Input)`
  width: 100%;
  font-size: 14px;
`;

const StyledRow = styled.div`
  display: flex;
  align-items: flex-end;
`;

const StyledInvestment = styled.div`
  flex: 3;
  padding-right: 20px;
  margin-bottom: 1px;
`;

const StyledAllocation = styled.div`
  flex: 2;
`;

const StyledAllocationInput = styled.div`
  position: relative;
  input {
    padding-right: 25px;
  }
`;

const StyledError = styled.div`
  color: ${GetColor.DEPRECATED_DivergingColor.A3};
  padding-top: 10px;
  font-size: 13px;
`;
