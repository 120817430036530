import React from 'react';
import styled from 'styled-components';
import { GetColor, Icon } from 'venn-ui-kit';

export interface MetricDescriptionTooltipProps {
  name: string;
  description?: string;
  learnMore?: boolean;
}

export const MetricDescriptionTooltip = ({ name, description, learnMore = true }: MetricDescriptionTooltipProps) => (
  <Wrapper className="qa-metric-description">
    <Header>{name}</Header>
    {description ? <Description>{description}</Description> : null}
    {learnMore && (
      <LearnMoreWrapper>
        <Icon type="info-circle" prefix="far" />
        <LearnMore>CLICK TO LEARN MORE</LearnMore>
      </LearnMoreWrapper>
    )}
  </Wrapper>
);

const Wrapper = styled.div`
  padding: 8px;
  color: ${GetColor.Black};
`;

const Header = styled.div`
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 16px;
`;

export const Description = styled.div`
  line-height: 24px;
  font-size: 14px;
`;

export const LearnMoreWrapper = styled.div`
  margin-top: 20px;
`;

export const LearnMore = styled.span`
  padding: 0 5px;
  font-weight: bold;
  color: ${GetColor.Black};
`;
