import { useEffect } from 'react';
import type { OperationResult } from 'venn-api';
import { getPrivatePortfolioByName } from 'venn-api';
import { isNull } from 'lodash';

interface Options {
  onEmpty: () => void;
  onValid: () => void;
  onInvalid: (error: string) => void;
  setIsLoading: (isLoading: boolean) => void;
}

export function useValidatePrivatePortfolioName(name: string, { onEmpty, onValid, onInvalid, setIsLoading }: Options) {
  useEffect(() => {
    setIsLoading(true);
    if (name === '') {
      onEmpty();
      setIsLoading(false);
    } else {
      getPrivatePortfolioByName(name)
        .then((response) => {
          const error = response.content.result != null ? 'Portfolio name is already used' : '';
          if (error) {
            onInvalid(error);
          } else {
            onValid();
          }
        })
        .finally(() => setIsLoading(false));
    }
  }, [name, onEmpty, onValid, onInvalid, setIsLoading]);
}

/** Helper function to make it behave like checkPortfolioName */
export const checkPrivatePortfolioName = (name: string): Promise<OperationResult<boolean>> => {
  return getPrivatePortfolioByName(name).then((response) => {
    return {
      ...response,
      content: !isNull(response.content.result),
    };
  });
};
