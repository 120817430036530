const persistData = {
  messages: [],
  createdCount: 1,
  updatedCount: 0,
  errorCount: 0,
  warningCount: 0,
  responseObject: [
    {
      id: '1cdfdf4a-adfe-41f5-80c4-1fd65570d264',
      name: 'ZFirstFundz',
      fundSourceType: null,
      startRange: null,
      endRange: null,
      returnFrequency: null,
      updated: 1585062892943,
      currency: 'USD',
      userUploaded: true,
      assetType: 'UPLOADED_INSTRUMENT',
      remoteType: 'LOCAL',
      dataSource: null,
      investmentSource: 'UPLOAD',
      investmentType: 'NOT_SPECIFIED',
      investmentRegion: 'NOT_SPECIFIED',
      exchangeCountry: null,
      symbol: null,
      accountNumber: null,
      category: null,
      active: true,
      live: true,
    },
  ],
};
export default persistData;
