import type { FC } from 'react';
import React, { useCallback } from 'react';
import { ButtonIcon } from 'venn-ui-kit';
import type { AnalysisSubject } from 'venn-utils';
import {
  countForecastableFunds,
  getDisabledOptimizationMessage,
  getPortfolioLabPath,
  logMessageToSentry,
} from 'venn-utils';
import { useHistory } from 'react-router-dom';
import useInvestmentForecasts from '../../logic/useInvestmentForecasts';

interface PortfolioLabLauncherProps {
  subject: AnalysisSubject;
  buttonSize?: number;
}

const PortfolioLabLauncher: FC<React.PropsWithChildren<PortfolioLabLauncherProps>> = ({ subject, buttonSize }) => {
  const { investmentForecasts } = useInvestmentForecasts(subject.portfolio);

  const history = useHistory();
  const onRedirectToPortfolioLab = useCallback(() => {
    if (!subject.portfolio) {
      logMessageToSentry('Failed to redirect to Portfolio Lab from optimizerModalLauncher for missing portfolio');
      return;
    }
    history.push(getPortfolioLabPath(subject.portfolio.id));
  }, [subject, history]);

  if (!subject.portfolio) {
    return null;
  }

  const fundsCount = countForecastableFunds(subject.portfolio, investmentForecasts);
  const defaultTooltip = 'Optimize in Portfolio Lab';
  const tooltip = getDisabledOptimizationMessage(subject.portfolio, investmentForecasts) ?? defaultTooltip;
  return (
    <ButtonIcon
      iconType="flask"
      onClick={onRedirectToPortfolioLab}
      disabled={fundsCount <= 1}
      tooltip={tooltip}
      className="qa-optimize-portfolio"
      ctaTrackingOptions={{
        ctaDestination: 'Portfolio Lab',
        ctaText: defaultTooltip,
        ctaPurpose: 'Optimize portfolio',
      }}
      size={buttonSize}
    />
  );
};

export default PortfolioLabLauncher;
