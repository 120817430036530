import type { UserProfileSettings } from 'venn-api';

export type Method = 'Google Authenticator' | 'Text';

export const primaryMethodIsSMS = (profileSettings: UserProfileSettings) =>
  profileSettings.mfaStatus.primaryType === 'SMS';

export const alternateMethod = (profileSettings: UserProfileSettings): Method =>
  profileSettings.mfaStatus.primaryType === 'SMS' ? 'Google Authenticator' : 'Text';

export const currentMethod = (profileSettings: UserProfileSettings) =>
  primaryMethodIsSMS(profileSettings) ? 'Text 2FA' : 'Google Authenticator';
