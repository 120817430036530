import React, { useCallback, useContext, Fragment } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { SelectTypes } from 'venn-ui-kit';

import { FiltersContext } from './FiltersProvider';
import QuickFilterBar, { isHelpArticles, isSavedViews } from './QuickFilterBar';
import { CELL_SPACING, HeaderCell } from './shared';
import { ColumnRenderers, VIEW_COLUMNS, ARTICLE_COLUMNS } from './Columns';
import type { CustomSelectProps, SearchFilter, SearchMenuItem } from '../types';

const SelectMenuList = SelectTypes.components.MenuList;

const MenuList = (props: SelectTypes.MenuListComponentProps<SearchMenuItem>) => {
  const { setSelectedFilters, selectedFilters } = useContext(FiltersContext);
  const { options } = props;
  const {
    showQuickFilters,
    columns,
    footer,
    smallScreen,
    canSearchEverything,
    includeTags,
    includeBenchmarks,
    privateAssetSearchMode,
    investmentsOnly,
    portfoliosOnly,
  } = props.selectProps as CustomSelectProps;

  const hasRecent = options.some((item) => item.category === 'recent');
  const hasSaved = options.some((item) => item.category === 'saved');
  const hasViews = isSavedViews(selectedFilters);
  const hasArticle = isHelpArticles(selectedFilters);
  const header = hasArticle
    ? 'Help Documents'
    : hasViews
      ? 'Saved Views'
      : hasRecent
        ? 'Recent '
        : hasSaved
          ? 'Saved Benchmarks '
          : 'Results ';
  const displayColumns = hasViews ? VIEW_COLUMNS : hasArticle ? ARTICLE_COLUMNS : columns;

  const onSelectFilter = useCallback(
    (filter: SearchFilter) => {
      setSelectedFilters((prev) => [...prev, filter]);
    },
    [setSelectedFilters],
  );

  const content = isEmpty(options) ? (
    props.children
  ) : (
    <>
      {showQuickFilters && (
        <QuickFilterBar
          privateAssetSearchMode={privateAssetSearchMode}
          selectedFilters={selectedFilters}
          onSelectFilter={onSelectFilter}
          smallScreen={smallScreen}
          canSearchEverything={canSearchEverything}
          includeTags={includeTags}
          includeBenchmarks={includeBenchmarks}
          investmentsOnly={investmentsOnly}
          portfoliosOnly={portfoliosOnly}
        />
      )}

      <TableWrapper>
        <TableScrollable>
          <StyledTable>
            <thead className="qa-menu-header">
              <tr>
                <th />
                <FirstHeaderCell>
                  <Header>{header}</Header>
                </FirstHeaderCell>
                <HeaderCell />
                <ExpandHeaderCell />
                {displayColumns.map((col) => (
                  <Fragment key={col}>{ColumnRenderers[col].headerRenderer()}</Fragment>
                ))}
              </tr>
            </thead>
            <tbody>{props.children}</tbody>
          </StyledTable>
        </TableScrollable>
      </TableWrapper>
    </>
  );

  return (
    <SelectMenuList {...props}>
      {content}
      {footer && footer({ ...props })}
    </SelectMenuList>
  );
};

export default MenuList;

const Header = styled.div`
  position: absolute;
  top: 0;
`;

const StyledTable = styled.table`
  width: 100%;
  table-layout: auto;
  white-space: nowrap;
  text-align: left;
  font-size: 12px;
  tbody {
    tr {
      td {
        padding-right: ${CELL_SPACING}px;
      }
      th:nth-child(1) {
        width: 0px;
      }
      th:nth-child(1),
      th:nth-child(2),
      th:nth-child(3) {
        padding-right: 0;
      }
    }
  }
  thead {
    tr {
      th {
        padding-right: ${CELL_SPACING}px;
      }

      th:nth-child(2) {
        padding-right: 0;
      }
    }
  }
`;

const TableWrapper = styled.div`
  position: relative;
`;

const TableScrollable = styled.div`
  max-height: 286px;
  overflow-y: auto;
  position: relative;
`;

const FirstHeaderCell = styled(HeaderCell)`
  position: relative;
`;

const ExpandHeaderCell = styled(HeaderCell)`
  width: 100%;
`;
