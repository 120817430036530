import React, { useCallback, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { compact, isEqual } from 'lodash';
import { EllipsisTooltipSpan, Hint } from 'venn-ui-kit';
import type { AnalysisBlockProps, TabularDataRow } from './types';
import { StyledBasicTable, FIRST_COLUMN_OUTER_WIDTH_PX, FIRST_COLUMN_INNER_WIDTH } from './StyledBasicTable';
import type { BasicTableColumn } from '../../../../basictable';
import { ColumnAlign } from '../../../../basictable';
import CorrelationColorLegend from './CorrelationColorLegend';
import { portfolioCorrelationExtractor } from './dataExtractors';
import { pairwiseCorrelationCellRenderer } from './cellRenderers';
import type { ExportInfo } from '../../../types';
import { type ExcelCell, FootnoteSymbols, plural } from 'venn-utils';
import useExportUpdate from '../../../logic/useExportUpdate';
import { MAX_INVESTMENTS } from '../../../../charts/analysis-charts/correlation-analysis/logic';
import VerticalHeader from './VerticalHeader';
import { useRecoilValue } from 'recoil';
import {
  blockDisplayHeader,
  blockFonts,
  getCustomFontSize,
  studioPrintOrientationType,
  flattenedProxyMapState,
} from 'venn-state';

interface PortfolioCorrelationProps extends ExportInfo, AnalysisBlockProps {}

const PortfolioCorrelation = ({ requests, analysis, selectedRefId, exportMetaData }: PortfolioCorrelationProps) => {
  const tableFont = useRecoilValue(blockFonts.blockTableData(selectedRefId));
  const legendFont = useRecoilValue(blockFonts.blockChartLegend(selectedRefId));
  const proxyMap = useRecoilValue(flattenedProxyMapState);
  const printOrientation = useRecoilValue(studioPrintOrientationType);
  const header = useRecoilValue(blockDisplayHeader(selectedRefId));
  const correlations =
    analysis?.analysisType === 'CORRELATION'
      ? compact(analysis?.correlationMatrix?.correlations)
      : compact(analysis?.downsideCorrelationMatrix?.correlations); // only display first portfolio
  const hasBenchmark = requests[0].benchmark !== undefined;
  const numberExcluded = correlations.length - MAX_INVESTMENTS;

  const data = useMemo(
    () => portfolioCorrelationExtractor(correlations, requests[0].subject.portfolio, hasBenchmark),
    [correlations, requests, hasBenchmark],
  );

  const dataRef = useRef(data);

  if (!isEqual(dataRef.current, data)) {
    dataRef.current = data;
  }

  const excelDataFn = useCallback(() => {
    const isRelative = requests[0]?.relative;
    const benchmarkName = requests[0].benchmark?.name ?? '--';
    const headerRow: ExcelCell[] = dataRef.current.map((data: TabularDataRow) => ({
      value: isRelative ? `${data.label} (Relative to ${benchmarkName})` : data.label,
      bold: true,
    }));
    const body: ExcelCell[][] = dataRef.current.map((row: TabularDataRow) => {
      const cellData: ExcelCell[] = [];
      for (let subjectIdx = 0; subjectIdx < correlations.length; subjectIdx++) {
        cellData.push({
          value: row[subjectIdx]?.value ?? '--',
          percentage: row?.type === 'percentage',
          digits: correlations.length,
        });
      }
      return [{ value: row.label, bold: true }, ...cellData];
    });
    return [[{ value: header, bold: true }, ...headerRow], ...body];
  }, [requests, header, correlations.length]);

  useExportUpdate({ selectedRefId, exportMetaData, excelDataFn });

  const getColumns = (): BasicTableColumn<unknown>[] =>
    compact([
      {
        label: 'Correlation',
        accessor: 'label',
        headerStyle: {
          maxWidth: FIRST_COLUMN_OUTER_WIDTH_PX,
          fontSize: `${tableFont.fontSizePt}pt`,
        },
        cellRenderer: (rowData: TabularDataRow) => (
          <EllipsisTooltipSpan usePortal maxWidth={FIRST_COLUMN_INNER_WIDTH}>
            {[rowData?.fundId && proxyMap?.[rowData.fundId] && <sup>{FootnoteSymbols.proxyUsage}</sup>, rowData.label]}
          </EllipsisTooltipSpan>
        ),
      },
      ...data.map((row, colNum) => {
        return {
          label: row.label,
          align: ColumnAlign.RIGHT,
          cellRenderer: pairwiseCorrelationCellRenderer(colNum, 0, undefined),
          headerRenderer: (_: unknown, data: TabularDataRow[]) => <VerticalHeader>{data[colNum].label}</VerticalHeader>,
        };
      }),
    ]);

  return (
    <>
      <StyledBasicTable
        columns={getColumns()}
        data={data}
        compactRows={printOrientation === 'LANDSCAPE'}
        fontSize={getCustomFontSize(tableFont)}
      />
      <CorrelationColorLegend fontSize={getCustomFontSize(legendFont)} />
      {numberExcluded > 0 ? (
        <Message>
          <Hint>
            {plural(numberExcluded, {
              1: '{{count}} smaller investment has been excluded.',
              other: '{{count}} smaller investments have been excluded.',
            })}
          </Hint>
        </Message>
      ) : null}
    </>
  );
};

const Message = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 10px 0px 10px;
`;

export default PortfolioCorrelation;
