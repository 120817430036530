import { useMutation } from '@tanstack/react-query';
import { cloneDeep, isEmpty, isNil, partition } from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  type MultiPortfolioParseResult,
  type MultiPortfolioPersistResult,
  type OperationError,
  type OperationResult,
  persistMultiPortfolioUpload,
  type Portfolio,
} from 'venn-api';
import { Flexbox, getAppTitle, GetColor, getTextThemeProvider, HyperLink, Icon, Loading } from 'venn-ui-kit';
import { UserContext } from '../../../contexts';
import { ErrorMessage } from '../components/page-parts';
import { UniversalUploaderFooter } from '../components/page-parts/UniversalUploaderFooter';
import { DataUploaderMode, DataUploaderView } from '../types';
import { uploadConfig } from '../utils';
import { hasUnmatchedFunds } from './review/helpers';
import { type IndexedPortfolioParseResult, ParsedPortfolioOption } from './review/ParsedPortfolioOption';
import { ScrollableSectionWithStickyHeader } from './review/ScrollableSectionWithStickyHeader';
import { MainUploadWrapper, UPLOAD_LEFT_CONTAINER_WIDTH } from './shared/layout';
import { UploadPortfolioAllocator } from './UploadPortfolioAllocator';
import { DiscardUploadConfirmationModal, PersistUploadConfirmationModal } from './UploadConfirmationModals';
import { analyticsService } from 'venn-utils';

type MultiPortfolioReviewStepProps = Readonly<{
  parsedData: MultiPortfolioParseResult;
  goBackToUploadStep: () => void;
  goToUploadConfirmation: (persistResult: MultiPortfolioPersistResult) => void;
}>;

const NEEDS_MAPPING_INVESTMENTS_MESSAGE =
  'Some investment names/tickers/ISIN could not be matched to an investment in the Data Library. Please review below and ensure all investments are mapped in order to continue.';

const NEEDS_MAPPING_TOOLTIP_MESSAGE =
  'Some portfolios contain investments that are not mapped to investments in the Data Library. Please ensure all investments are mapped in order to continue.';

const useMutablePortfolios = (parsedData: MultiPortfolioParseResult) => {
  const initialState = useMemo(() => cloneDeep(parsedData.portfolioParseResults), [parsedData.portfolioParseResults]);

  const [parsedResults, setParsedResults] = useState(initialState);

  const updatePortfolio = (index: number, portfolio: Portfolio) => {
    const newParseResults = [...parsedResults];
    newParseResults[index] = {
      ...newParseResults[index],
      parsedPortfolio: portfolio,
    };
    setParsedResults(newParseResults);
  };

  return {
    parsedResults,
    updatePortfolio,
  };
};

const useSavePortfoliosMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess: (data: MultiPortfolioPersistResult) => void;
  onError: (error: Partial<Error & OperationResult<OperationError | undefined>>) => void;
}) => {
  return useMutation(
    async (portfolios: Portfolio[]) => {
      return (
        await persistMultiPortfolioUpload({
          portfolioPersistInputs: portfolios.map((portfolio) => ({
            portfolio,
            writeMode: 'OVERWRITE',
          })),
        })
      ).content;
    },
    { onSuccess, onError },
  );
};

enum ConfirmationModalType {
  None,
  Discard,
  Upload,
}

const useMultiPortfolioConfirmationModals = () => {
  const [confirmationModalType, setConfirmationModalType] = useState<ConfirmationModalType>(ConfirmationModalType.None);

  const closeConfirmationModal = useCallback(() => {
    setConfirmationModalType(() => ConfirmationModalType.None);
  }, [setConfirmationModalType]);

  const openDiscardConfirmationModal = useCallback(() => {
    setConfirmationModalType(() => ConfirmationModalType.Discard);
  }, [setConfirmationModalType]);

  const openUploadConfirmationModal = useCallback(() => {
    setConfirmationModalType(() => ConfirmationModalType.Upload);
  }, [setConfirmationModalType]);

  return {
    confirmationModalType,
    closeConfirmationModal,
    openDiscardConfirmationModal,
    openUploadConfirmationModal,
  };
};

export const MultiPortfolioReviewStep = ({
  parsedData,
  goBackToUploadStep,
  goToUploadConfirmation,
}: MultiPortfolioReviewStepProps) => {
  const { confirmationModalType, closeConfirmationModal, openDiscardConfirmationModal, openUploadConfirmationModal } =
    useMultiPortfolioConfirmationModals();

  const { parsedResults, updatePortfolio } = useMutablePortfolios(parsedData);

  useEffect(() => {
    analyticsService.uploadStepViewed({
      dataType: uploadConfig[DataUploaderMode.Portfolios].dataType,
      step: 1,
      stepName: DataUploaderView.Review,
    });
  }, []);

  const [newParsedData, existingParsedData] = partition(
    parsedResults.map((data, index) => {
      return {
        ...data,
        originalIndex: index,
      };
    }),
    (data) => isNil(data.parsedPortfolio.id),
  );

  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const selectedAlreadyExistingPortfolio = existingParsedData.some(
    (existingParsedDatum) => existingParsedDatum.originalIndex === selectedIndex,
  );

  const openedPortfolio = parsedResults[selectedIndex].parsedPortfolio;

  const savePortfoliosMutation = useSavePortfoliosMutation({
    onSuccess: (persistResult) => {
      analyticsService.uploadStepCompleted({
        step: 1,
        dataType: uploadConfig[DataUploaderMode.Portfolios].dataType,
        stepName: DataUploaderView.Review,
      });
      goToUploadConfirmation(persistResult);
    },
    onError: (e) => {
      analyticsService.uploadStepFailed({
        step: 1,
        stepName: DataUploaderView.Review,
        dataType: uploadConfig[DataUploaderMode.Portfolios].dataType,
        error: e?.content?.message ?? e?.message,
      });
    },
  });

  const anyPortfolioHasUnmatchedFunds = parsedResults.some((parsedResult) =>
    hasUnmatchedFunds(parsedResult.parsedPortfolio),
  );

  const { currentContext } = useContext(UserContext);

  const savePortfolios = () =>
    savePortfoliosMutation.mutate(
      parsedResults.map((result) => ({ ...result.parsedPortfolio, ownerContextId: currentContext })),
    );

  const completeUpload = () => {
    if (existingParsedData.length === 0) {
      savePortfolios();
    } else {
      openUploadConfirmationModal();
    }
  };

  const onBackButtonClick = () => {
    if (savePortfoliosMutation.isError) {
      savePortfoliosMutation.reset();
    } else {
      openDiscardConfirmationModal();
    }
  };

  const selectAnotherPortfolio = (index: number) => {
    setSelectedIndex(index);

    const portfolio = parsedResults[index].parsedPortfolio;
    analyticsService.multiPortfolioUploaderPortfolioChanged({
      portfolioId: portfolio?.id,
      portfolioName: portfolio?.name,
    });
  };

  const renderLeftSidebarSection = (
    title: string,
    parsedResults: IndexedPortfolioParseResult[],
    index: number,
    reverseIndex: number,
  ) => {
    if (isEmpty(parsedResults)) {
      return null;
    }

    return (
      <ScrollableSectionWithStickyHeader
        groupIndex={index}
        groupReverseIndex={reverseIndex}
        title={title}
        count={parsedResults.length}
      >
        {parsedResults.map((data) => (
          <ParsedPortfolioOption
            setSelectedIndex={selectAnotherPortfolio}
            selected={data.originalIndex === selectedIndex}
            data={data}
            key={data.originalIndex}
          />
        ))}
      </ScrollableSectionWithStickyHeader>
    );
  };

  const renderContent = () => {
    if (savePortfoliosMutation.isLoading) {
      return (
        <Flexbox direction="row" grow={1} alignItems="center" justifyContent="center">
          <Loading title="Uploading portfolio data to Data Library" />
        </Flexbox>
      );
    }

    if (savePortfoliosMutation.isError) {
      return (
        <Flexbox direction="row" grow={1} alignItems="center" justifyContent="center">
          <Flexbox direction="column" gap={20} alignItems="center">
            <ErrorIcon type="file-exclamation" />
            <ErrorContent>
              Data failed to upload due to a system error.
              <br />
              Please contact{' '}
              <HyperLink href={`mailto:${getTextThemeProvider().supportEmail}`}>
                {`${getAppTitle()} support`}
              </HyperLink>{' '}
              for more information
            </ErrorContent>
          </Flexbox>
        </Flexbox>
      );
    }

    return (
      <>
        <LeftContainer>
          {renderLeftSidebarSection('EXISTING PORTFOLIOS', existingParsedData, 0, isEmpty(newParsedData) ? 0 : 1)}
          {renderLeftSidebarSection('NEW PORTFOLIOS', newParsedData, isEmpty(existingParsedData) ? 0 : 1, 0)}
        </LeftContainer>
        <RightContainer>
          {hasUnmatchedFunds(openedPortfolio) && (
            <ErrorMessage className="unmapped-investments-error" data-testid="unmapped-investments-error">
              {NEEDS_MAPPING_INVESTMENTS_MESSAGE}
            </ErrorMessage>
          )}
          <UploadPortfolioAllocator
            selectedIndex={selectedIndex}
            updatePortfolio={updatePortfolio}
            accessMode={selectedAlreadyExistingPortfolio ? 'OVERWRITE_EXISTING' : 'CREATE_NEW'}
            portfolio={openedPortfolio}
          />
        </RightContainer>
      </>
    );
  };

  return (
    <MainUploadWrapper>
      <ContentContainer>{renderContent()}</ContentContainer>
      <UniversalUploaderFooter
        noMargin
        onCancel={onBackButtonClick}
        onContinue={completeUpload}
        primaryLabel="Complete Upload"
        disabled={!savePortfoliosMutation.isIdle || anyPortfolioHasUnmatchedFunds}
        primaryTooltip={anyPortfolioHasUnmatchedFunds ? NEEDS_MAPPING_TOOLTIP_MESSAGE : undefined}
      />
      {confirmationModalType === ConfirmationModalType.Upload && (
        <PersistUploadConfirmationModal
          onConfirm={() => {
            closeConfirmationModal();
            savePortfolios();
          }}
          onCancel={closeConfirmationModal}
        />
      )}
      {confirmationModalType === ConfirmationModalType.Discard && (
        <DiscardUploadConfirmationModal
          closeEntireUploader={false}
          onConfirm={() => {
            closeConfirmationModal();
            goBackToUploadStep();
          }}
          onCancel={closeConfirmationModal}
        />
      )}
    </MainUploadWrapper>
  );
};

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  align-self: stretch;
  flex: 1;
  overflow: hidden;
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${UPLOAD_LEFT_CONTAINER_WIDTH}px;
  overflow: auto;
  align-items: flex-start;
  align-self: stretch;
  border-right: 1px solid ${GetColor.GreyScale.Grey30};
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  flex: 1;

  .unmapped-investments-error {
    margin-top: 0;
  }
`;

const ErrorIcon = styled(Icon)`
  font-size: 42px;
  color: ${GetColor.Error};
`;

const ErrorContent = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.35;
`;
