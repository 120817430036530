import { useMutation } from '@tanstack/react-query';
import React, { useCallback, useMemo } from 'react';
import { type RegisterOptions, type SubmitHandler } from 'react-hook-form';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { type CashFlowSetting, saveCashflowSettings } from 'venn-api';
import {
  cashflowModalActiveSettingIdAtom,
  cashflowSettingSelectedToActivateNext,
  cashflowSettingsModalIsEditing,
  editedCashflowSettingAtom,
} from 'venn-state';
import { Notifications, NotificationType } from 'venn-ui-kit';
import ClassificationOverrideForm from './ClassificationOverrideForm';
import { useAllCashflowSettingsOverrides } from './logic/useAllCashflowSettingsOverrides';
import ParameterOverrideForm from './ParameterOverrideForm';

type EditCashflowSettingsProps = {
  onChangesApplied: () => void;
  refetchActiveSetting: () => void;
  systemSetting: CashFlowSetting;
};

const MAX_ALLOWED_SETTING_NAME_LENGTH = 50;

export const EditCashflowSettings = ({
  onChangesApplied,
  systemSetting,
  refetchActiveSetting,
}: EditCashflowSettingsProps) => {
  const { data: allUserSettings, refetch: refetchAllOverrides } = useAllCashflowSettingsOverrides();
  const [editedCashflowSetting, setEditedCashflowSetting] = useRecoilState(editedCashflowSettingAtom);
  const setIsEditing = useSetRecoilState(cashflowSettingsModalIsEditing);
  const activeSettingId = useRecoilValue(cashflowModalActiveSettingIdAtom);
  const setSelectedToActivateNext = useSetRecoilState(cashflowSettingSelectedToActivateNext);

  const allSettingsNames = useMemo(() => {
    if (!allUserSettings) {
      return ['Venn Default Setting'];
    }
    return [
      'Venn Default Setting',
      ...allUserSettings.classificationSettings.map((setting) => setting.name),
      ...allUserSettings.parameterSettings.map((setting) => setting.name),
    ];
  }, [allUserSettings]);

  const isCreatingNew = !editedCashflowSetting?.settingId;

  const onEditPanelClose = useCallback(() => {
    setEditedCashflowSetting((_) => undefined);
    setIsEditing((_) => false);
  }, [setIsEditing, setEditedCashflowSetting]);

  const persistSettingChanges = useMutation((setting: CashFlowSetting) => saveCashflowSettings(setting), {
    onSuccess: (data) => {
      onChangesApplied();
      refetchAllOverrides();
      refetchActiveSetting();
      if (activeSettingId !== data.content.settingId) {
        // if these two are equal, it means the setting is already active -> no changes
        setSelectedToActivateNext(data.content.settingId);
      }
      onEditPanelClose();
      Notifications.notify(
        `Successfully ${isCreatingNew ? 'created' : 'updated'} ${data.content.name} cash flow setting`,
        NotificationType.SUCCESS,
      );
    },
    onError: () => {
      Notifications.notify('Operation failed. Please try again later or contact Venn Support.', NotificationType.ERROR);
    },
  });

  const onSubmit: SubmitHandler<CashFlowSetting> = (data) => {
    if (!editedCashflowSetting) {
      return;
    }

    persistSettingChanges.mutate({
      ...editedCashflowSetting,
      ...data,
    });
  };

  const nameOptions: RegisterOptions<{ name: string }, 'name'> = {
    required: 'Name is required.',
    validate: {
      unique: (newName) => {
        if (newName === editedCashflowSetting?.name && !isCreatingNew) {
          return undefined;
        }
        const existsAlready = allSettingsNames.find((existingName) => existingName === newName);

        return existsAlready ? 'Setting name already exists, please choose a unique name.' : undefined;
      },
      nonWhiteSpace: (name) => name.length > 0 || 'Name is required.',
      length: (name) =>
        name.length <= MAX_ALLOWED_SETTING_NAME_LENGTH ||
        `Name must not exceed ${MAX_ALLOWED_SETTING_NAME_LENGTH} characters`,
    },
  };

  return editedCashflowSetting?.overrideType === 'PARAMETER' ? (
    <ParameterOverrideForm
      onSubmit={onSubmit}
      nameOptions={nameOptions}
      isCreatingNew={isCreatingNew}
      systemSetting={systemSetting}
      onEditPanelClose={onEditPanelClose}
    />
  ) : (
    <ClassificationOverrideForm
      onSubmit={onSubmit}
      nameOptions={nameOptions}
      isCreatingNew={isCreatingNew}
      systemSetting={systemSetting}
      onEditPanelClose={onEditPanelClose}
    />
  );
};
