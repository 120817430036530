import React from 'react';
import styled from 'styled-components';

import { Headline2, GetColor, Link as LinkStyle, EmptyWorkspaceIcon, ACCESSING_ANALYSES_FAQ_HREF } from 'venn-ui-kit';

const ZeroSavedViewsEmptyState = () => (
  <>
    <TopBorder />
    <Wrapper>
      <EmptyWorkspaceIcon />
      <StyledHeadline2 center className="qa-no-saved-views">
        No saved views, yet.
      </StyledHeadline2>
      <Hint>
        Analysis and compare views will be saved here.{' '}
        <LinkStyle>
          <a target="_blank" rel="noopener noreferrer" href={ACCESSING_ANALYSES_FAQ_HREF}>
            Learn more.
          </a>
        </LinkStyle>
      </Hint>
    </Wrapper>
  </>
);

const TopBorder = styled.div`
  border-top: 1px solid ${GetColor.Grey};
  margin: 30px 30px 80px;
`;

const Wrapper = styled.div`
  text-align: center;
  margin: 100px auto;
`;

const StyledHeadline2 = styled(Headline2)`
  margin-top: 30px;
  margin-bottom: 15px;
`;

const Hint = styled.span`
  font-size: 10px;
  font-weight: normal;
  color: ${GetColor.HintGrey};
`;

export default ZeroSavedViewsEmptyState;
