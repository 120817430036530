import React, { useCallback, useEffect, useState } from 'react';
import { ModalContent, Modal, ModalHeader, ModalFooter } from '../../modal';
import type { ReleaseNoteContent } from 'venn-api';
import { getReleaseNotes } from 'venn-api';
import { logExceptionIntoSentry } from 'venn-utils';
import { ColorUtils, GetColor, Loading, Subtitle1 } from 'venn-ui-kit';
import styled from 'styled-components';
import ReleaseNote from './ReleaseNote';
import moment, { now } from 'moment';

interface ReleaseNotesModalProps {
  onClose: () => void;
}

const getNextDate = (prev: number): number => {
  const next = moment(prev);
  next.subtract(5, 'months');
  return next.valueOf();
};

const ReleaseNotesModal = ({ onClose }: ReleaseNotesModalProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [moreToShow, setMoreToShow] = useState<boolean>(true);
  const [upToDate, setUpToDate] = useState<number>(now());
  const [notes, setNotes] = useState<ReleaseNoteContent[]>([]);

  useEffect(() => {
    const from = getNextDate(upToDate);
    getReleaseNotes(from, upToDate)
      .catch((e) => {
        logExceptionIntoSentry(e);
        setMoreToShow(false);
        setLoading(false);
      })
      .then((releaseNotes) => {
        if (releaseNotes && releaseNotes.content.length > 0) {
          setNotes((currentNotes) => [...currentNotes, ...releaseNotes.content]);
        } else {
          setMoreToShow(false);
        }
        setLoading(false);
      });
  }, [upToDate]);

  const showMore = useCallback(() => {
    const newUpToDate = getNextDate(upToDate);
    setUpToDate(newUpToDate);
  }, [upToDate]);

  return (
    <Modal onClose={onClose} closeOnEsc>
      <ModalHeader>What's New?</ModalHeader>
      <Wrapper>
        <>
          {notes.map((note) => (
            <ReleaseNote content={note} key={note.publishDate} />
          ))}
          {loading ? (
            <Loading />
          ) : (
            <FooterWrapper>
              {moreToShow ? (
                <ShowMoreLink onClick={showMore}>Show more</ShowMoreLink>
              ) : (
                <Subtitle1>No {notes.length > 0 ? ' more ' : ''} release notes to show</Subtitle1>
              )}
            </FooterWrapper>
          )}
        </>
      </Wrapper>
      <ModalFooter onCancel={onClose} cancelLabel="Close" />
    </Modal>
  );
};

export default ReleaseNotesModal;

const Wrapper = styled(ModalContent)`
  min-height: 300px;
  max-height: 550px;
  overflow-y: scroll;
`;

const ShowMoreLink = styled.button`
  color: ${GetColor.Primary.Dark};
  font-size: 11px;
  text-transform: uppercase;
  margin: auto;

  &:hover {
    color: ${ColorUtils.opacifyDarkFrom(GetColor.Primary.Dark, 0.1)};
  }
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
  margin-bottom: 20px;
`;
