import type { ReactElement } from 'react';
import React, { useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import CSSTransition from 'react-transition-group/CSSTransition';
import { AnimationStyles, getAnimationClassName } from './animation';
import type { Routes } from 'venn-utils';
import { VennEvents } from 'venn-utils';

interface AnimatedRouterSwitchProps {
  /** The React router Switch component with the routes that could potentially be animated */
  children: ReactElement;
}

interface AnimatedRouterSwitchLocationState {
  /** The base path that the previous url matched */
  previousPathMatch?: Routes;
}

const AnimatedRouterSwitch = ({ children }: AnimatedRouterSwitchProps) => {
  const location = useLocation<AnimatedRouterSwitchLocationState>();
  const history = useHistory();
  const { previousPathMatch } = location.state ?? {};

  const animationClassName = getAnimationClassName(location.pathname, previousPathMatch);

  /* The animationKey controls when an animation is applied. If it changes then the child component will be re-rendered
    with an animation. */
  const animationKey = useRef<string>('');
  if (history.action !== 'REPLACE' && animationClassName.length) {
    // We'll use the concatenation of the previous and current URL. If, for some reason, there is a
    // location change triggered twice with the same previous and current paths, then the page will
    // not animate because the key will not change.
    animationKey.current = previousPathMatch + location.pathname;
  }

  return (
    <AnimationStyles>
      <TransitionGroup
        // allows us to modify the animation class names of the route that we're leaving
        childFactory={(child: ReactElement) =>
          React.cloneElement(child, {
            classNames: animationClassName,
          })
        }
      >
        <CSSTransition
          key={animationKey.current}
          classNames={animationClassName}
          timeout={animationClassName ? 1000 : 0}
          /* notify components that the transition is over so that they can do things like recalculate position */
          onEntered={() => document.dispatchEvent(new CustomEvent(VennEvents.transitioned))}
        >
          {children}
        </CSSTransition>
      </TransitionGroup>
    </AnimationStyles>
  );
};

export default AnimatedRouterSwitch;
