import React from 'react';
import styled from 'styled-components';
import { BannerMessage } from '../../../../banner-message/BannerMessage';

interface ErrorMessageProps {
  children: string | React.ReactNode;
  className?: string;
  'data-testid'?: string;
}

const ErrorMessage = ({ children, className, 'data-testid': dataTestId }: ErrorMessageProps) => (
  <StyledError className={className} data-testid={dataTestId}>
    <BannerMessage errorType="error" small iconType="warning">
      {children}
    </BannerMessage>
  </StyledError>
);

export default ErrorMessage;

const StyledError = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;
