import type {
  AxisLabelsFormatterContextObject,
  Point,
  TooltipFormatterCallbackFunction,
  TooltipFormatterContextObject,
} from 'highcharts';
import type { MomentInput } from 'moment';
import moment from 'moment';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { ThemeProvider } from 'styled-components';
import type { FundingFailure } from 'venn-api';
import type { Theme } from 'venn-ui-kit';
import { Dates, formatAllocation } from 'venn-utils';
import type { StudioRequestSubject } from '../../../venn-state/src';
import { FUNDING_FAILURE_POINT_PREFIX } from '../studio-blocks/components/blocks/public-private/utils';
import FundingFailureTooltipContent from '../studio-blocks/components/FundingFailureTooltipContent';
import type { ConfidenceLevel } from '../studio-blocks/logic/useConfidenceLevels';

/**
 * Use this tick interval for quarterly datetime axis.
 *
 * If you let highcharts choose the frequency of ticks, it sometimes chooses 4 months which completely screws
 * bar charts labelling for quarterly frequency. This is not acceptable, because the ticks aren't aligned with our data.
 * So we need to explicitly define it as three months. Highcharts can select multiples of this if they want to.
 * */
export const DEFAULT_QUARTERLY_TICK_INTERVAL = 3 * 30 * 24 * 3600 * 1000; // three months

function allocationFormatter(this: AxisLabelsFormatterContextObject) {
  return formatAllocation(this.value, true, false, undefined, false, 1);
}

function quarterlyFormatter(this: AxisLabelsFormatterContextObject) {
  return Dates.toDDMMMYYYY(alignDatesForLowFrequencies(this.value).valueOf(), 'QUARTERLY');
}

function endOfTheYearFormatter(this: AxisLabelsFormatterContextObject) {
  const { dataMin, dataMax, tickPositions } = this.axis;
  const min = moment.utc(dataMin ?? undefined);
  const max = moment.utc(dataMax ?? undefined);
  const yearsDiff = max.diff(min, 'years');

  if (yearsDiff > 0) {
    return moment.utc(this.value).format("MMM. 'YY");
  }

  const previousTick = tickPositions?.[tickPositions.indexOf(this.value) - 1];
  const currentDate = alignDatesForLowFrequencies(this.value);
  const previousDate = previousTick ? alignDatesForLowFrequencies(previousTick) : currentDate;
  const isSameYearAsBefore = previousDate.isSame(currentDate, 'year');

  return currentDate.format(`MMM. D${isSameYearAsBefore ? '' : " 'YY"}`);
}

/**
 * For non-daily frequency, we have the issue with mismatch of the month of the returns with the month on the
 * x-axis. That's because the returns for a specific month are timestamped at the last millisecond of that
 * month, and the tick on the x-axis for a specific month is timestamped at the first millisecond of that month.
 * This causes the situation where you hover over a point that's almost directly above "March", and the tooltip
 * says it's data for "February". In reality, these two differ by 1 millisecond (but one that makes a difference
 * between February and March). To fix this, we change the date to the end of the previous day, so it should
 * be aligned with our return dates.
 *
 * An analogical explanation goes for daily frequency with x-axis labels in day-month format.
 */
function alignDatesForLowFrequencies(value: MomentInput) {
  return moment.utc(value).subtract(1, 'd').endOf('day');
}

function fundingFailureFormatter(
  getPoint: (context: TooltipFormatterContextObject) => Point | undefined,
  originalFormatter: TooltipFormatterCallbackFunction,
  theme: Theme,
): TooltipFormatterCallbackFunction {
  return function fundingFailureFormatterImpl(this: TooltipFormatterContextObject) {
    const point = getPoint(this);
    if ((point?.options?.id ?? '').startsWith(FUNDING_FAILURE_POINT_PREFIX)) {
      return ReactDOMServer.renderToString(
        <ThemeProvider theme={theme}>
          <FundingFailureTooltipContent
            fundingFailure={point?.metadata?.fundingFailure as FundingFailure}
            confidenceLevel={point?.metadata?.confidenceLevel as ConfidenceLevel}
            publicSubject={point?.metadata?.publicSubject as StudioRequestSubject}
            privateSubject={point?.metadata?.privateSubject as StudioRequestSubject}
          />
        </ThemeProvider>,
      );
    }
    return originalFormatter.apply(this);
  };
}

/**
 * Calculate desired padding-top for y-axis
 *
 * Used for maxPadding setting on yAxis. Reference: https://api.highcharts.com/highcharts/yAxis.maxPadding
 * Formula explanation: fontSize numbers are returned as pt, we want to convert them to px first.
 * Worst case is axis label generated exactly at y-axis top, because they are centered, they will be cut in half.
 * So the offset needs to be also approximately that value, some additional minimal padding is added by default,
 * otherwise for small font sizes it appears to close to the top.
 * */
function yAxisPaddingBasedOnFontSize(fontSizePt: number) {
  return (fontSizePt * 0.01333) / 2 + 0.03;
}

export default {
  endOfTheYearFormatter,
  alignDatesForLowFrequencies,
  quarterlyFormatter,
  allocationFormatter,
  fundingFailureFormatter,
  yAxisPaddingBasedOnFontSize,
};
