import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { noop } from 'lodash';
import type { History } from 'history';
import type { AnyDuringEslintMigration } from 'venn-utils';
import { analyticsService, Routes } from 'venn-utils';
import { GetColor, getTextThemeProvider, ZIndex } from 'venn-ui-kit';
import { AuthNotification } from './components';

import type {
  AccountCreationRequest,
  ActivationCodeCheck,
  AuthenticationError,
  LoginResponse,
  SignupUser,
} from 'venn-api';
import { AuthenticationErrorType, AuthenticationStatus } from 'venn-api';

import type { RouteComponentProps } from 'react-router-dom';
import { Redirect, Route, Router, Switch, withRouter } from 'react-router-dom';

import ResetPassword, { ResetPasswordRoute } from './ResetPassword';
import ForgotPassword, { ForgotPasswordRoute } from './ForgotPassword';
import SignIn from './SignIn';
import SignUpPage from './SignUp/SignUpPage';
import SignUpInvitePage, { SignUpInviteRoute } from './SignUpInvite/SignUpInvitePage';
import CreatePasswordV3, { CreatePasswordRouteV3 } from './CreatePassword/CreatePasswordV3';
import TwoFactorSetup, { TwoFactorSetupRoute } from './two-factor/TwoFactorSetup';

export interface AuthContainerProps extends RouteComponentProps {
  error?: AuthenticationError;
  loginResponse?: LoginResponse;
  credentials?: SignupUser;
  signIn: (credentials: SignupUser) => Promise<void>;
  forgotPassword: (credentials: SignupUser) => Promise<void>;
  resetPassword: (credentials: SignupUser) => Promise<void>;
  activationCodeCheck: (credentials: ActivationCodeCheck) => Promise<void>;
  verificationCodeCheck: (credentials: AccountCreationRequest) => Promise<void>;
  activateAccount: (credentials: AccountCreationRequest) => Promise<void>;
  notification?: AuthenticationError;
  pending?: boolean;
  checkStatus: (isNewUser?: boolean, fallbackAuthStatus?: AuthenticationStatus) => void;
  requestedForgotPassword?: boolean;
}

interface AuthRoute {
  path: string;
  // TODO: (VENN-20577 / TYPES) (collin.irwin) clean up types here
  component:
    | React.FC<React.PropsWithChildren<unknown>>
    | React.ClassType<unknown, AnyDuringEslintMigration, AnyDuringEslintMigration>
    | React.ComponentClass<unknown>;
  props?: {
    onSubmit: (user: SignupUser | AccountCreationRequest) => void;
    onActivationCodeCheck?: (codeCheck: ActivationCodeCheck) => void;
    onVerificationCodeCheck?: (codeCheck: ActivationCodeCheck) => void;
    onSuccess?: () => void;
    history?: History;
  };
}

const pageContainerClassName = () => {
  const path = window.location.pathname;
  return path ? path.replace('/', '') : '';
};

const AuthContainer = ({
  error,
  signIn,
  activateAccount,
  checkStatus,
  forgotPassword,
  loginResponse,
  credentials,
  resetPassword,
  activationCodeCheck,
  verificationCodeCheck,
  requestedForgotPassword,
  pending,
  history,
}: AuthContainerProps) => {
  const { Colors, Videos } = useContext(ThemeContext);

  // TODO: (VENN-20577 / TYPES) (collin.irwin) clean up types here
  const authRoutes: AuthRoute[] = [
    {
      path: Routes.SIGN_IN_PATH,
      component: SignIn,
      props: {
        // @ts-expect-error: TODO fix strictFunctionTypes
        onSubmit: (user: SignupUser) => {
          signIn(user);
        },
      },
    },
    {
      path: SignUpInviteRoute,
      component: SignUpInvitePage,
      props: {
        onSubmit: () => {},
        onSuccess: () => {
          checkStatus(true);
          analyticsService.userCreated({
            source: 'invited',
          });
        },
      },
    },
    {
      path: Routes.SIGN_UP_PATH,
      component: SignUpPage,
      props: {
        history,
        onSubmit: noop,
      },
    },
    {
      path: ForgotPasswordRoute,
      component: ForgotPassword,
      props: {
        history,
        // @ts-expect-error: TODO fix strictFunctionTypes
        onSubmit: forgotPassword,
      },
    },
    {
      path: CreatePasswordRouteV3,
      component: CreatePasswordV3,
      props: {
        history,
        // @ts-expect-error: TODO fix strictFunctionTypes
        onVerificationCodeCheck: verificationCodeCheck,
        // @ts-expect-error: TODO fix strictFunctionTypes
        onSubmit: (accountCreation: AccountCreationRequest) => {
          activateAccount(accountCreation);
        },
      },
    },
    {
      path: ResetPasswordRoute,
      component: ResetPassword,
      props: {
        history,
        // @ts-expect-error: TODO fix strictFunctionTypes
        onSubmit: resetPassword,
        onActivationCodeCheck: activationCodeCheck,
      },
    },
    {
      path: TwoFactorSetupRoute,
      component: TwoFactorSetup,
      props: {
        history,
        onSubmit: () => checkStatus(false, AuthenticationStatus.AuthenticatedMfaSetupRequired),
      },
    },
  ];

  const renderActivationCodeInvalid = () => {
    if (!(error && error.errorType === AuthenticationErrorType.ActivationCodeIncorrectOrExpired)) {
      return null;
    }
    return (
      <>
        <Redirect to={Routes.SIGN_IN_PATH} />
        <AuthNotification
          icon="exclamation-triangle"
          color={Colors.DEPRECATED_DivergingColor.A3}
          title="Password reset link has expired."
          description={error.message}
          position="relative"
        />
      </>
    );
  };

  const renderVerificationCodeInvalid = () => {
    if (error?.errorType === AuthenticationErrorType.VerificationCodeIncorrectOrExpired) {
      return (
        <AuthNotification
          icon="exclamation-triangle"
          color={Colors.DEPRECATED_DivergingColor.A3}
          title={error.title ?? 'Verification link is invalid.'}
          description={error.message}
          position="relative"
        />
      );
    }
    if (error?.errorType === AuthenticationErrorType.AccountAlreadyCreated) {
      return <AuthNotification title="Account previously activated." description={error.message} position="relative" />;
    }

    if (requestedForgotPassword) {
      return (
        <AuthNotification
          title="A password reset link was sent."
          description={`If you do not receive an email, double check your email below or contact ${
            getTextThemeProvider().supportEmail
          }.`}
          position="relative"
        />
      );
    }
    return null;
  };

  return (
    <Router history={history}>
      <Switch>
        <Route>
          <FixedContainer>
            <SplashVideo autoPlay loop muted>
              <source src={Videos.splashScreenAnimation} type="video/mp4" />
            </SplashVideo>
            <PageContainer className={pageContainerClassName()}>
              {renderActivationCodeInvalid()}
              {renderVerificationCodeInvalid()}

              <Switch>
                {authRoutes.map(({ path, component: Component, props }) => {
                  const routeProps = {
                    ...props,
                    error,
                    pending,
                    loginResponse,
                    credentials,
                  };
                  return <Route key={path} path={path} render={() => <Component {...routeProps} />} />;
                })}
                {!pending ? <Redirect to={Routes.SIGN_IN_PATH} /> : null}
              </Switch>
            </PageContainer>
          </FixedContainer>
        </Route>
      </Switch>
    </Router>
  );
};

export default withRouter(AuthContainer);

const FixedContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${GetColor.Black};
  z-index: ${ZIndex.Front};
`;

const SplashVideo = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  min-width: 100%;
  height: auto;
  min-height: 100%;
  transform: translateX(-50%) translateY(-50%);
  background-size: cover;
  z-index: ${ZIndex.Background};
`;

const PageContainer = styled.div`
  z-index: ${ZIndex.Front};
  height: 100%;
  overflow-y: scroll;
`;
