import { first, round, zip as lodashZip } from 'lodash';
import type { DataPoint, DoubleDataPoint, Returns, Range } from '../types';
import moment from 'moment';

export const toDataPoints = (rawReturns: number[][]): DataPoint[] =>
  rawReturns.map((ret) => ({
    x: ret[0],
    y: ret[1],
  }));

export const toPercentage = (ret: DataPoint): DataPoint => ({
  x: ret.x,
  y: round(ret.y * 100, 12),
});

export const zip = (returns1: Returns, returns2: Returns): DoubleDataPoint[] => {
  if (returns1.length !== returns2.length) {
    throw Error('The two return arrays must be of equal length');
  }
  return lodashZip(returns1, returns2).map((array) => {
    if (!array[0] || !array[1]) {
      return {
        x: 0,
        y1: 0,
        y2: 0,
      };
    }

    if (array[0].x !== array[1].x) {
      throw Error('The two return arrays must have the same dates');
    }
    return {
      x: array[0].x,
      y1: array[0].y,
      y2: array[1].y,
    };
  });
};

export const compoundWithInception = (returns: Returns, range?: Range) =>
  appendInception(compound(returns, range), returns);

export const compound = (returns: Returns, range?: Range) => {
  if (!returns) {
    return [];
  }

  const filteredReturns = range ? returns.filter(({ x }) => x >= range.start && x <= range.end) : returns;

  const compounded = filteredReturns
    .map(pickValue)
    .map(normalize)
    .reduce(compoundReduce, [])
    .map(deNormalize)
    .map(roundTo12)
    .map(valueToDateAndValueObject(filteredReturns));
  // .map(formatter || identity);

  return compounded;
};

const pickValue = (ret: DataPoint) => ret.y;

const normalize = (value: number) => value + 1;

const deNormalize = (value: number) => value - 1;

const compoundReduce = (compoundedValues: number[], nextValue: number, index: number) => {
  const previousValue = index > 0 ? compoundedValues[index - 1] : 1;
  compoundedValues.push(previousValue * nextValue);
  return compoundedValues;
};

const roundTo12 = (value: number) => round(value, 12);

const valueToDateAndValueObject = (originalReturns: Returns) => (value: number, index: number) => ({
  x: originalReturns[index].x,
  y: value,
});

const appendInception = (returns: Returns, originalReturns: Returns): DataPoint[] => {
  const dates = (originalReturns || returns).map((ret) => ret.x);
  const start = first(returns)?.x || undefined;
  const inceptionDate = start ? guessPreviousDate(dates, start) : undefined;
  if (inceptionDate) {
    return [{ x: inceptionDate, y: 0 }, ...returns];
  }
  return returns;
};

export const guessPreviousDate = (dates: number[], startRange: number): number | undefined => {
  const index = dates.indexOf(startRange);
  if (index > 0) {
    return dates[index - 1];
  }
  if (index === -1) {
    return undefined;
  }
  if (dates.length < 2) {
    return undefined;
  }
  const firstDate = moment.utc(dates[0]);
  const secondDate = moment.utc(dates[1]);
  const daysBetween = secondDate.diff(firstDate, 'days');

  if (daysBetween >= 1 && daysBetween < 5) {
    return moment.utc(startRange).add(-1, 'days').valueOf();
  }
  if (daysBetween >= 28 && daysBetween <= 31) {
    return moment.utc(startRange).add(-1, 'month').endOf('month').valueOf();
  }
  if (daysBetween >= 89 && daysBetween <= 92) {
    return moment.utc(startRange).add(-3, 'month').endOf('month').valueOf();
  }

  return moment.utc(startRange).add(-1, 'week').endOf('month').valueOf();
};
