import React from 'react';
import type { SharedProps } from '../types';
import Loading from './Loading';
import { shockRiskTabs } from '../configs';
import { ComponentFromBlock } from '../blocks';
import { Section, SectionHeadline, BlockRow } from 'venn-ui-kit';
import { getBlocksExceptPerformance } from 'venn-utils';

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default (props: SharedProps) => {
  const { analyses, factorLenses, analysisConfig } = props;

  if (!analyses || !analyses.results || !factorLenses || factorLenses.length === 0) {
    return <Loading />;
  }

  const blocks = getBlocksExceptPerformance(analysisConfig);

  const historicalBlocks = blocks.filter(
    (block) =>
      block === 'HISTORICAL_DRAWDOWN_PERIODS' ||
      block === 'PERCENTAGE_DRAWDOWN' ||
      block === 'NOTABLE_HISTORICAL_PERIODS' ||
      block === 'HISTORICAL_DRAWDOWN_AND_RALLY_SCANNER',
  );

  const hypotheticalBlocks = blocks.filter((block) => block === 'DRAWDOWN');

  const scenarioBlocks = blocks.filter((block) => block === 'SCENARIO');

  return (
    <>
      <Section id={shockRiskTabs.HISTORICAL_ANALYSIS}>
        <SectionHeadline>Historical Analysis</SectionHeadline>
        {historicalBlocks.map((block) => (
          <BlockRow key={block}>
            <ComponentFromBlock block={block} sharedProps={props} />
          </BlockRow>
        ))}
      </Section>
      <Section id={shockRiskTabs.HYPOTHETICAL_ANALYSIS}>
        <SectionHeadline>Hypothetical Analysis</SectionHeadline>
        {hypotheticalBlocks.map((block) => (
          <BlockRow key={block}>
            <ComponentFromBlock block={block} sharedProps={props} />
          </BlockRow>
        ))}
      </Section>
      <Section id={shockRiskTabs.SCENARIO_ANALYSIS}>
        <SectionHeadline>Sensitivity Analysis</SectionHeadline>
        {scenarioBlocks.map((block) => (
          <BlockRow key={block}>
            <ComponentFromBlock block={block} sharedProps={props} />
          </BlockRow>
        ))}
      </Section>
    </>
  );
};
