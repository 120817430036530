import type { VennColors } from 'venn-ui-kit';
import { GetColor, getItemColor, ItemIcon, ItemType, ItemTypePill } from 'venn-ui-kit';
import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import type { BasicTableColumn, BasicTableProps } from 'venn-components';
import { BasicTable } from 'venn-components';

const getColumns = (colors: VennColors) => [
  {
    label: 'Item Type',
    accessor: 'icons',
    sortable: false,
    cellRenderer: () => <ItemTypePill item={ItemType.Portfolio} />,
  },
  {
    label: '',
    accessor: 'icons',
    sortable: false,
    cellRenderer: () => <ItemIcon item={ItemType.Portfolio} dataSource="Addepar" />,
  },
  {
    label: 'Name',
    accessor: 'name',
    sortable: false,
    cellRenderer: (item: { name: string }) => (
      <NameCell color={getItemColor(colors, ItemType.Portfolio)}>{item.name}</NameCell>
    ),
    headerStyle: { width: '100%', minWidth: '300px' },
  },
];

interface PreviewDataLibraryProps {
  portfolioNames: string[];
}

const PreviewDataLibrary = ({ portfolioNames }: PreviewDataLibraryProps) => {
  const { Colors } = useContext(ThemeContext);
  return <StyledTable columns={getColumns(Colors)} data={portfolioNames.map((name) => ({ name }))} />;
};

export default PreviewDataLibrary;

const NameCell = styled.div<{ color: string }>`
  color: ${({ color }) => color};
`;

const StyledTable = styled(<T extends BasicTableColumn<K>, K>(props: BasicTableProps<T, K>) => (
  <BasicTable<T, K> {...props} />
))`
  > thead {
    height: 26px;
    font-size: 14px;
  }

  > thead > tr:last-child {
    border-color: ${GetColor.MidGrey2};
  }

  > tbody > tr {
    height: 34px;

    td {
      border-bottom: 1px solid ${GetColor.Grey};
    }
  }
`;
