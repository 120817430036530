import React, { useCallback, useContext, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { ZIndex } from 'venn-ui-kit';
import { analyticsService, formatAnalysisPeriod } from 'venn-utils';
import type { ShareMetadata } from 'venn-api';
import {
  AnalysisConfigContext,
  AnalysisViewContext,
  UserContext,
  SaveShareTopBar,
  MetadataContext,
} from 'venn-components';
import type { AnalysisProps } from './AnalysisWorkspace';
import AnalysisWorkspace from './AnalysisWorkspace';
import type { ExportMetaData } from './exportData/types';
import { ANALYSIS_HEADER_HEIGHT } from '../Layout';
import useExport from './exportData/useAnalysisExport';
import { isNil } from 'lodash';

type WorkspaceProps = AnalysisProps;

const Workspace = (props: WorkspaceProps) => {
  const analysisViewValue = useContext(AnalysisViewContext);
  const userContext = useContext(UserContext);
  const workspaceCurrency = userContext.profileSettings?.organization?.currency;
  const canCreateAnalysisTemplate = userContext.hasPermission('ANALYSIS_PAGE_CREATE_TEMPLATE');
  const canPrintAnalysis = userContext.hasPermission('ANALYSIS_PAGE_PRINT');
  const {
    onSave: onPerformSave,
    onSaveAs: onPerformSaveAs,
    hasUnsavedChanges,
    currentViewName,
    savedId,
    hasSavedBaseline,
    onRenameSavedView,
    compare,
    noAccessModifiedView,
  } = analysisViewValue;

  const hasSubject = !!props.analysisConfig.subject;

  const onSave = async (saveWithName: string, ownerContextId?: string) => {
    // In case the user is viewing a saved view they're not an owner of, perform "save as" instead of "save".
    const onPerformSavingAction = hasSavedBaseline ? onPerformSave : onPerformSaveAs;
    await onPerformSavingAction(saveWithName, props.analysisConfig, ownerContextId);
    analyticsService.ctaClicked({
      destination: undefined,
      text: 'Save',
      purpose: 'Save analysis',
      type: 'button',
      filled: false,
    });
    return undefined;
  };

  const onSaveAs = async (saveWithName: string, ownerContextId?: string) => {
    await onPerformSaveAs(saveWithName, props.analysisConfig, ownerContextId);
    analyticsService.ctaClicked({
      destination: undefined,
      text: 'Save As...',
      purpose: 'Save analysis',
      type: 'button',
      filled: false,
    });
  };

  const { actualTimeFrame, actualFactorTrendRollingPeriods, venncastOn } = useContext(AnalysisConfigContext);

  const [drawdownRange, setDrawdownRange] = useState<[number, number] | undefined>();
  const setDrawdownPeriod = useCallback(
    (start: number | undefined, end: number | undefined) =>
      setDrawdownRange(isNil(start) || isNil(end) ? undefined : [start, end]),
    [],
  );

  const metadataContextValue = useMemo(
    () => ({
      drawdownPeriodStart: drawdownRange?.[0],
      drawdownPeriodEnd: drawdownRange?.[1],
      setDrawdownPeriod,
    }),
    [drawdownRange, setDrawdownPeriod],
  );

  const exportMetaData: ExportMetaData = useMemo(
    () => ({
      ...analysisViewValue,
      ...actualFactorTrendRollingPeriods,
      venncast: !!venncastOn,
      analysisConfig: props.analysisConfig,
      startDate: actualTimeFrame.startTime,
      endDate: actualTimeFrame.endTime,
      savedId,
      viewName: currentViewName || '',
      compare,
      workspaceCurrency,
      frequency: props.defaultAnalysesPeriod.frequency,
      drawdownPeriod: isNil(drawdownRange) ? undefined : formatAnalysisPeriod(drawdownRange[0], drawdownRange[1]),
    }),
    [
      currentViewName,
      savedId,
      actualTimeFrame,
      actualFactorTrendRollingPeriods,
      props.analysisConfig,
      compare,
      workspaceCurrency,
      analysisViewValue,
      venncastOn,
      props.defaultAnalysesPeriod.frequency,
      drawdownRange,
    ],
  );

  const { handleExportAsImage, handleExportAll, handleExportAsData } = useExport(exportMetaData);
  const shareMetaData: Partial<ShareMetadata> = useMemo(
    () => ({
      comparison: false,
      savedViewName: exportMetaData.viewName,
      subjectName: exportMetaData.analysisConfig?.subject?.name || '',
      templateName: exportMetaData.analysisConfig?.analysisTemplate?.name || '',
    }),
    [exportMetaData],
  );

  return (
    <Grid>
      <Header>
        <SaveShareTopBar
          disabled={!hasSubject}
          hasUnsavedChanges={hasUnsavedChanges}
          hasSavedBaseline={hasSavedBaseline}
          currentViewName={currentViewName}
          loading={props.loadingSubject}
          // @ts-expect-error: TODO fix strictFunctionTypes
          onSave={canCreateAnalysisTemplate ? onSave : undefined}
          onSaveAs={canCreateAnalysisTemplate ? onSaveAs : undefined}
          onRename={canCreateAnalysisTemplate ? onRenameSavedView : undefined}
          isCompare={false}
          isEmpty={!props.objectId}
          emptyName="Analysis"
          viewTypeName="Analysis"
          subjectId={props.objectId ? String(props.objectId) : undefined}
          shareMetaData={shareMetaData}
          downloadXlsx={handleExportAsData}
          downloadAll={handleExportAll}
          downloadPNGs={handleExportAsImage}
          savedId={savedId}
          noAccessModifiedView={noAccessModifiedView}
          canPrint={canPrintAnalysis}
        />
      </Header>
      <Content>
        <Panel>
          <MetadataContext.Provider value={metadataContextValue}>
            <AnalysisWorkspace {...props} currentViewName={currentViewName} />
          </MetadataContext.Provider>
        </Panel>
      </Content>
    </Grid>
  );
};

export default Workspace;

interface PanelProps {
  fixedHeight?: boolean;
}

const Panel = styled.div<PanelProps>`
  position: relative;
  ${(props) =>
    props.fixedHeight &&
    css`
      overflow-y: auto;
      @media print {
        overflow-y: visible;
      }
    `}
`;

const Grid = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  display: -ms-grid;
  -ms-grid-rows: 0px ${ANALYSIS_HEADER_HEIGHT}px 1fr;
  -ms-grid-columns: 1fr;
  grid-template-columns: auto;
  grid-template-rows: 0px ${ANALYSIS_HEADER_HEIGHT}px auto;
  grid-template-areas:
    'message'
    'header'
    'content';
  @media print {
    display: block;
    height: auto;
  }
`;

const Header = styled.div`
  -ms-grid-column: 1;
  -ms-grid-row: 1;
  grid-area: header;
  z-index: ${ZIndex.StickyCover};
  @media print {
    display: none;
  }
`;

const Content = styled.div`
  -ms-grid-column: 1;
  -ms-grid-row: 2;
  grid-area: content;
`;
