import type { RecoilValue, RecoilState } from 'recoil';
import { useRecoilCallback, useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import { useEffect } from 'react';

/**
 * Gets the given value of a recoil state or defaults to the given default value if the given state is pending
 * @param state the state to get
 * @param defaultValue the value to default to if the state is pending
 */
export const useRecoilValueWithDefault = <T>(state: RecoilValue<T>, defaultValue: T) => {
  const loadable = useRecoilValueLoadable(state);
  return loadable.state === 'hasValue' ? loadable.getValue() : defaultValue;
};

/**
 * A wrapper around useSetRecoilState that resets the atom on dismount
 * @param state the state to get the set state callback for
 */
export const useScopedSetRecoilState = <T>(state: RecoilState<T>) => {
  const setState = useSetRecoilState(state);

  const resetState = useRecoilCallback(
    ({ reset }) =>
      () =>
        reset(state),
    [state],
  );

  useEffect(() => {
    return () => {
      resetState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return setState;
};
