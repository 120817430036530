import styled from 'styled-components';
import React from 'react';
import { ColorUtils, GetColor } from '../../style';
import Icon from '../icon/Icon';
import type { FontAwesomePrefixes } from 'venn-utils';

interface WarningProps {
  text: React.ReactNode;
  large?: boolean;
  icon?: string;
  iconPrefix?: FontAwesomePrefixes;
}

export const Warning = ({ text, large, icon = 'exclamation-circle', iconPrefix = 'far' }: WarningProps) =>
  large ? (
    <LargeWrapper data-testid="qa-warning-wrapper">
      <Icon type={icon} prefix={iconPrefix} />
      {text}
    </LargeWrapper>
  ) : (
    <Wrapper data-testid="qa-warning-wrapper">
      <Icon type={icon} prefix={iconPrefix} />
      {text}
    </Wrapper>
  );

const LargeWrapper = styled.div`
  padding: 12px 24px;
  display: flex;
  align-items: center;
  column-gap: 16px;
  color: ${GetColor.GreyScale.Grey70};
  background: ${ColorUtils.opacifyFrom(GetColor.HighlightLight, 0.1)};
  font-size: 16px;

  i {
    font-size: 20px;
    color: ${GetColor.HighlightDark};
  }
  a {
    color: inherit;
    text-decoration-line: underline;
  }
`;

const Wrapper = styled.div`
  padding: 6px 12px;
  display: flex;
  align-items: center;
  column-gap: 8px;
  color: ${GetColor.GreyScale.Grey70};
  background: ${ColorUtils.opacifyFrom(GetColor.HighlightLight, 0.1)};
  font-size: 10px;

  i {
    font-size: 12px;
    color: ${GetColor.HighlightDark};
  }
  a {
    color: inherit;
    text-decoration-line: underline;
  }
`;
