/*
 * The utils below help for showing a message about the paginated results being displayed,
 * e.g. "Showing 1 - 25 of 100", where 1 can be obtained with `getResultRangeStart` and 25 with `getResultRangeEnd`
 * given that page 1 is being displayed with 25 results out of 100.
 */

/**
 * Get the starting item number being shown in the page.
 * For example, if there are 8 results with 5 results per page,
 * then if the selected page is 1, then the result is 1,
 * and if the selected page is 2, then the result is 6.
 * @param showingResultCount The number of results being shown on the selected page
 * @param selectedPage The page being shown, 1-indexed
 * @param resultsPerPage The number of results shown per page
 */
export const getResultRangeStart = (showingResultCount: number, selectedPage: number, resultsPerPage: number) =>
  showingResultCount ? (selectedPage - 1) * resultsPerPage + 1 : 0;

/**
 * Get the ending item number being shown in the page.
 * For example, if there are 8 results with 5 results per page,
 * then if the selected page is 1, then the result is 5,
 * and if the selected page is 2, then the result is 8.
 * @param showingResultCount The number of results being shown on the selected page
 * @param selectedPage The page being shown, 1-indexed
 * @param resultsPerPage The number of results shown per page
 */
export const getResultRangeEnd = (showingResultCount: number, selectedPage: number, resultsPerPage: number) =>
  (selectedPage - 1) * resultsPerPage + showingResultCount;
