import type { Fund, Benchmark } from 'venn-api';

export const mockBenchmarks: Benchmark[] = [
  {
    fundId: '0993d240-ed49-4172-9090-e338f4a3a664',
    type: 'INVESTMENT',
    name: 'LongName',
    portfolioId: undefined,
    primary: true,
  },
  {
    fundId: 'a89df25e-10f0-4899-bfb5-ca1420583eb2',
    type: 'COMPOSITE',
    name: 'SimpleComposite',
    portfolioId: undefined,
    primary: false,
  },
];

export const mockCompositeBenchmark = {
  name: 'SimpleComposite',
  existingComposite: {
    fundId: 'a89df25e-10f0-4899-bfb5-ca1420583eb2',
    id: 34356,
    originalName: 'SimpleComposite',
  },
  children: [
    {
      scalingSign: 'PLUS',
      scalingValue: '2.0',
      weight: '100.0',
      fund: {
        label: 'Industrials Investment Category (ETF)',
        type: '',
        value: '3ce7eda3-5862-4ff7-ae5e-c050cb0367e5',
        fund: {
          name: 'Industrials Investment Category (ETF)',
          id: '3ce7eda3-5862-4ff7-ae5e-c050cb0367e5',
          startRange: 913939199999,
          endRange: 1583971199999,
          investmentSource: 'VENN',
          investmentType: 'INDEX',
          live: true,
          active: true,
          benchmarks: [],
        } as unknown as Fund,
      },
    },
  ],
};
