import React, { useState, useMemo, Suspense } from 'react';
import styled from 'styled-components';
import { GetColor, ColorUtils, Button, Subtitle2, ShimmerBlock } from 'venn-ui-kit';
import { useRecoilValue, waitForAll } from 'recoil';
import type { DateRangeInputId } from 'venn-state';
import { dateRangeInputNameState, dateRangeInputsState } from 'venn-state';
import { Message } from '../../../../shared';
import { without } from 'lodash';
import FocusTrap from 'focus-trap-react';
import { SelectDateRangeInputSection } from './SelectDateRangeInputSection';
import { DateRangeInputEditor } from 'venn-components';
import { InputDropMenu } from '../components/shared';
import { MAX_INPUTS_PER_KIND } from 'venn-utils';

/** For selecting or editing a date range input. */
export const DateRangeInputDropMenu = React.memo(function DateRangeInputDropMenu({
  selectedInputId,
  onSelectInput,
  onCollapse,
  isPrivate,
  isProjectionBasedPrivate,
  readonly,
}: {
  selectedInputId?: DateRangeInputId;
  onSelectInput: (selectedInputId: DateRangeInputId) => void;
  onCollapse?: () => void;
  isPrivate: boolean;
  isProjectionBasedPrivate?: boolean;
  readonly: boolean;
}) {
  const [editorOpen, setEditorOpen] = useState(false);
  const allInputIds = useRecoilValue(dateRangeInputsState);
  const menuInputNames = useRecoilValue(waitForAll(allInputIds.map((inputId) => dateRangeInputNameState(inputId))));
  const inputDropMenuItems = allInputIds.map((inputId, index) => ({ value: inputId, label: menuInputNames[index] }));

  return (
    <>
      {editorOpen && (
        <DateRangeInputEditor
          inputId={undefined}
          onClose={(savedinputId) => {
            setEditorOpen(false);
            savedinputId && onSelectInput(savedinputId);
          }}
        />
      )}
      <InputDropMenu
        // usePortal is necessary otherwise the dropmenu is constrained by the parent panel and cannot overflow
        usePortal
        // minimumItemsToTrigger of 0 is necessary so that when no inputs are available, user can still use the menu UI to create a new input
        minimumItemsToTrigger={0}
        items={inputDropMenuItems}
        openByDefault={false}
        onCollapse={onCollapse}
        menuComponent={(highlighted, forceCollapse, className) => (
          <MenuPopup className={className}>
            <Suspense fallback={<ShimmerBlock />}>
              <DateRangeInputSelectorMenuContent
                inputIds={allInputIds}
                checkedInputId={highlighted?.value}
                onSelectInput={(selectedinputId) => {
                  onSelectInput(selectedinputId);
                  forceCollapse();
                }}
                openEditor={() => {
                  setEditorOpen(true);
                  forceCollapse();
                }}
                isPrivate={isPrivate}
                isProjectionBasedPrivate={isProjectionBasedPrivate}
              />
            </Suspense>
          </MenuPopup>
        )}
        selected={selectedInputId}
        width={210}
        disabled={readonly}
      />
    </>
  );
});

/** Sizing constraints on contents, but no styling or layout. */
const MenuPopup = styled.div`
  width: 280px;
  height: 434px;
`;

/**
 * Layout, styling, and content for the menu, but no constraints on width and height as that is delegated to the parent.
 */
// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
const DateRangeInputSelectorMenuContent = React.memo(
  ({
    inputIds,
    checkedInputId,
    onSelectInput,
    openEditor,
    isPrivate,
    isProjectionBasedPrivate,
  }: {
    inputIds: DateRangeInputId[];
    checkedInputId?: DateRangeInputId;
    onSelectInput: (selectedinputId: DateRangeInputId) => void;
    openEditor: () => void;
    isPrivate: boolean;
    isProjectionBasedPrivate?: boolean;
  }) => {
    /** Focus the checkedInputId if it exists, otherwise don't move focus inside so that we don't confusingly add focus styling to the first item. */
    const initialFocus = checkedInputId ? undefined : false;

    /** Sorted with the checked input Id, if it exists, to the first index. */
    const sortedInputIds = useMemo(
      () => (checkedInputId ? [checkedInputId, ...without(inputIds, checkedInputId)] : inputIds),
      [checkedInputId, inputIds],
    );

    return (
      <FocusTrap focusTrapOptions={{ initialFocus, clickOutsideDeactivates: true }}>
        <DateRangeInputMenuContentWrapper>
          <DateRangeInputMenuBody>
            <DateRangeInputMenuHeader>Date Range Inputs</DateRangeInputMenuHeader>
            {sortedInputIds.length ? (
              sortedInputIds.map((inputId) => (
                <SelectDateRangeInputSection
                  key={inputId}
                  inputId={inputId}
                  isChecked={checkedInputId === inputId}
                  onClick={onSelectInput}
                  isPrivate={isPrivate}
                />
              ))
            ) : (
              <Message colorOverride={ColorUtils.opacifyFrom(GetColor.Warning, 0.1)}>
                No date range inputs have been created yet.
              </Message>
            )}
          </DateRangeInputMenuBody>

          <DateRangeInputMenuFooter>
            <Button
              data-testid="select-new-date-btn"
              dense
              onClick={openEditor}
              icon="search"
              disabled={inputIds.length >= MAX_INPUTS_PER_KIND}
            >
              {isPrivate
                ? isProjectionBasedPrivate
                  ? 'Select New Projection Start'
                  : 'Select New Date'
                : 'Select New Date Range'}
            </Button>
          </DateRangeInputMenuFooter>
        </DateRangeInputMenuContentWrapper>
      </FocusTrap>
    );
  },
);

/** Wraps the content with layout and container-level styling, but no sizing constraints. */
const DateRangeInputMenuContentWrapper = styled.div`
  background-color: ${GetColor.White};
  border-radius: 4px;
  border: solid 1px ${GetColor.Grey};
  box-shadow: 0 0 20px ${GetColor.Grey};
`;

const DateRangeInputMenuHeader = styled(Subtitle2)`
  margin: 10px 0 10px 8px;
`;

const DateRangeInputMenuBody = styled.div`
  max-height: 373px;
  min-height: 0;
  overflow: auto;
  padding-bottom: 16px;
`;

const DateRangeInputMenuFooter = styled.footer`
  height: 70px;

  border: solid 1px ${GetColor.Grey};

  /** Horizontally and vertically center contents. */
  display: flex;
  justify-content: center;
  align-items: center;
`;
