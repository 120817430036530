import React from 'react';
import type { RedirectProps } from 'react-router';
import { Redirect, useLocation } from 'react-router-dom';

const RedirectWithQueryParams = ({ to, ...rest }: RedirectProps) => {
  const { search } = useLocation();

  return (
    <Redirect
      to={
        typeof to === 'string'
          ? {
              pathname: to,
              search,
            }
          : {
              ...to,
              search,
            }
      }
      {...rest}
    />
  );
};

export default RedirectWithQueryParams;
