import type {
  Document,
  FileMapping,
  FileUploadMetadata,
  MappingAndErrors,
  Metadata,
  OperationResult,
  SeriesCell,
  TimeSeriesPersistResult,
  UploadTypeEnum,
} from 'venn-api';
import {
  correctData,
  parseDocument,
  persistTimeSeries,
  updateMapping,
  uploadDocument,
  uploadNavsParser,
  uploadPrivatesXLS,
} from 'venn-api';
import type { ErrorViewModel } from './views/review/helpers';

type UploadFetcher = (file: File | Blob, uploadType: UploadTypeEnum, draft: boolean) => Promise<Document>;
type UpdateMappingFetcher = (fileId: number, mapping: FileMapping) => Promise<MappingAndErrors>;
type CorrectionFetcher = (fileId: number, corrections: ErrorViewModel[]) => Promise<MappingAndErrors>;
type PersistFetcher = (fileId: number, uploadType: UploadTypeEnum) => Promise<TimeSeriesPersistResult>;

async function apiWrapper<T, A extends unknown[]>(fetcher: (...args: A) => Promise<OperationResult<T>>, ...args: A) {
  try {
    return (await fetcher(...args)).content;
  } catch (error) {
    const { content } = await error;
    throw content;
  }
}

export const fetchUploadFile: UploadFetcher = async (file, uploadType: UploadTypeEnum, draft: boolean) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('uploadType', uploadType);
  formData.append('draft', String(draft));
  return apiWrapper(uploadDocument, true, uploadType, formData);
};

export const fetchParsedFile = async (document: Document) => apiWrapper(parseDocument, document.uuid, document.version);

export const fetchNewNavsUploadFile = async (file: File | Blob, uploadType: UploadTypeEnum) => {
  const formData = new FormData();
  formData.append('file', file);
  return apiWrapper(uploadNavsParser, uploadType, formData);
};

export const fetchPrivatesUploadFile = async (file: File | Blob) => {
  const formData = new FormData();
  formData.append('file', file);
  return apiWrapper(uploadPrivatesXLS, formData);
};

export const fetchUpdateMapping: UpdateMappingFetcher = async (fileId, body) => apiWrapper(updateMapping, fileId, body);

export const fetchCorrectData: CorrectionFetcher = async (fileId, corrections) => {
  const body: SeriesCell[] = corrections.map(({ rowIndex, seriesId, value, date }) => ({
    index: rowIndex,
    seriesId,
    value,
    date,
  }));
  return apiWrapper(correctData, fileId, body);
};

export const fetchPersistSeries: PersistFetcher = async (fileId, uploadType) =>
  apiWrapper(persistTimeSeries, fileId, uploadType);

const typeIdCache = {};
export function getTypeId(metadata: FileUploadMetadata | undefined, namespace: string, name: string) {
  if (!metadata) return null;
  const key = `${namespace}-${name}`;
  if (typeIdCache[key]) {
    return typeIdCache[key];
  }

  const { id } = metadata[namespace].find((a: Metadata<string>) => a.name === name);
  typeIdCache[key] = id;
  return id;
}
