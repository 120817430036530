import React from 'react';
import moment from 'moment';
import styled, { css } from 'styled-components';
import { isEmpty } from 'lodash';
import { ItemType, ItemTypePill, selectClasses } from 'venn-ui-kit';
import type { AnalysisSubject } from 'venn-utils';
import type { SearchMenuItem } from '../types';

/** The padding between each cell in the menu table. */
export const CELL_SPACING = 10;

export const getItemTrackingId = (item: SearchMenuItem) =>
  (item.searchResult?.fundId ?? item.searchResult?.portfolioId ?? item.searchResult?.tagIds)?.toString();

export const getItemType = (item: SearchMenuItem) => {
  if (!isEmpty(item.searchResult?.tagIds)) {
    return ItemType.Tag;
  }
  if (item.value?.isCompositeBenchmark) {
    return ItemType.Benchmark;
  }
  if (item.value?.type === 'private-portfolio') {
    return ItemType.PrivatePortfolio;
  }
  if (item.value?.type === 'private-investment') {
    return ItemType.PrivateInvestment;
  }
  if (item.value?.type === 'portfolio') {
    return ItemType.Portfolio;
  }

  if (item.value?.isCategory) {
    return ItemType.Investment;
  }

  if (item.category === 'view') {
    return item.viewInfo?.analysisViewType as ItemType;
  }

  if (item.category === 'article') {
    return ItemType.Article;
  }

  return ItemType.Investment;
};

export const ItemLabel = ({ item, isDisabled }: { item: SearchMenuItem; isDisabled: boolean }) => {
  return <ItemTypePill isDisabled={isDisabled} item={getItemType(item)} />;
};

/** For some reason composite benchmarks are counted as user uploaded, so check for this */
export const isUploaded = (item: SearchMenuItem) => !!item.value?.userUploaded && !item.value?.isCompositeBenchmark;

export const getTicker = (item: AnalysisSubject) => {
  const fund = item.fund;
  return fund?.symbol && !item.isCategory ? fund.symbol : '—';
};

export const getSubjectRange = (subject: AnalysisSubject) => {
  const isPortfolio = subject.type === 'portfolio';
  const start = isPortfolio ? subject.portfolio?.periodStart : subject.fund?.startRange;
  const end = isPortfolio ? subject.portfolio?.periodEnd : subject.fund?.endRange;
  return start && end ? `${moment(start).format('YYYY')} - ${moment(end).format('YYYY')}` : '—';
};

export const HeaderCell = styled.th`
  padding-bottom: 10px;
`;

export const COLLAPSE_ANIMATION_SIZE = 60;

export const LeftCollapseWrapper = styled.div<{ collapsed: boolean }>`
  margin-right: ${COLLAPSE_ANIMATION_SIZE}px;
  margin-left: -${COLLAPSE_ANIMATION_SIZE}px;
  transition: margin-right 0.3s;

  ${(props) =>
    props.collapsed &&
    css`
      visibility: hidden;
      margin-right: 0;
      margin-left: 0;
      width: 0;
      padding: 0;
      border: none;
    `}
`;

export const placeholder = ({
  customPlaceholder,
  condition,
  portfoliosOnly,
  investmentsOnly,
  smallScreen = false,
}: {
  customPlaceholder?: React.ReactNode;
  condition: boolean;
  portfoliosOnly: boolean;
  investmentsOnly: boolean;
  smallScreen?: boolean;
}) =>
  customPlaceholder ?? (
    <Placeholder className={selectClasses.PlaceholderText}>
      {getPlaceholderText(condition, portfoliosOnly, investmentsOnly, smallScreen)}
    </Placeholder>
  );

export const getPlaceholderText = (
  condition: boolean,
  portfoliosOnly: boolean,
  investmentsOnly: boolean,
  smallScreen: boolean,
) => {
  if (condition) {
    return 'Search';
  }
  if (portfoliosOnly) {
    return 'Search for any portfolio';
  }
  if (investmentsOnly) {
    return 'Search for any investment';
  }
  if (smallScreen) {
    return 'Search for any investment, portfolio, or tags';
  }
  return 'Search for any investment, portfolio, benchmark or category';
};

const Placeholder = styled.div`
  display: flex;
  height: 35px;
  align-items: center;
`;
