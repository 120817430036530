import React, { type RefObject, useCallback } from 'react';

import ExportableGrid from '../../grid/ExportableGrid';
import { useIsBlockGridCompact } from '../../../logic/useIsBlockGridCompact';
import { useCommonGridProps } from '../../../logic/columnUtils';
import type { ColDef } from 'ag-grid-community';
import { BOLD_CLASS, DARK_COLUMN_CLASS, WATERMARK_POSITION_TOP } from '../../../customAnalysisContants';
import { DATA_TYPE_EXCEL_STYLES } from '../../../logic/customBlockUtils';
import { usePeerGroupsGrid } from './usePeerGroupsGrid';
import { GridWrapper } from '../../../common';
import { useRecoilValue } from 'recoil';
import { blockInfoGraphicType, blockSelectedPeerGroupId, isReportState, recoilBlockContentSize } from 'venn-state';
import styled from 'styled-components';
import { assertNotNil, type CustomizableMetric } from 'venn-utils';
import { useBlockId } from '../../../contexts/BlockIdContext';
import type { CustomizedBlock, PeerGroupAnalysisResponse, PeerGroupIdentifier } from 'venn-api';
import StyledEmptyState from '../../StyledEmptyState';
import { isEmpty } from 'lodash';
import { DownloadableContentBlock } from '../../../../content-block';
import { GetColor } from 'venn-ui-kit';
import { useSetBlockSize } from '../../../logic/useSetBlockSize';
import { PeerGroupBoxChart } from './PeerGroupBoxChart';
import { PeerGroupPercentileRankChart } from './PeerGroupPercentileRankChart';
import { usePeerGroupChartHeight } from './usePeerGroupChartHeight';
import { PGA_RANK_DISCLAIMER } from './utils';
import { EmptyHeaderSpace } from '../../../../content-block/DownloadableContentBlock';

const FOOTER_HEIGHT_PX = 28;
const DISCLAIMER_HEIGHT_PX = 16;

const gridOverrides: ColDef = {
  cellClassRules: {
    [DARK_COLUMN_CLASS]: ({ data }) => {
      return data?.isMetadata;
    },
    [BOLD_CLASS]: ({ data }) => {
      return data?.isMetadata;
    },
  },
};

type PeerGroupChartProps = {
  data: PeerGroupAnalysisResponse;
  downloadableContentRef?: RefObject<HTMLDivElement>;
  selectedBlock: CustomizedBlock;
  exportable: boolean;
  inPrintMode?: boolean;
};

type PeerGroupChartWrapperProps = {
  data: PeerGroupAnalysisResponse | undefined;
  metrics: CustomizableMetric[];
  selectedPeerGroup: PeerGroupIdentifier | undefined;
  downloadableContentRef?: RefObject<HTMLDivElement>;
  selectedBlock: CustomizedBlock;
  exportable: boolean;
  inPrintMode?: boolean;
};
export const PeerGroupChartWrapper = ({
  data,
  metrics,
  selectedPeerGroup,
  downloadableContentRef,
  exportable,
  selectedBlock,
  inPrintMode,
}: PeerGroupChartWrapperProps) => {
  const blockId = useBlockId();
  if (!selectedPeerGroup) {
    return (
      <StyledEmptyState
        selectedRefId={blockId}
        header="No peer group selected"
        message="Please select a peer group in the settings panel"
      />
    );
  }
  if (isEmpty(metrics)) {
    return (
      <StyledEmptyState
        selectedRefId={blockId}
        header="No metrics selected"
        message="Please select metrics in the settings panel"
      />
    );
  }

  if (!data) {
    return <StyledEmptyState selectedRefId={blockId} header="Unable to run analysis" message="Chart is empty" />;
  }

  return (
    <PeerGroupChart
      data={data}
      exportable={exportable}
      downloadableContentRef={downloadableContentRef}
      selectedBlock={selectedBlock}
      inPrintMode={inPrintMode}
    />
  );
};

const PeerGroupChart = ({
  data,
  downloadableContentRef,
  exportable,
  selectedBlock,
  inPrintMode,
}: PeerGroupChartProps) => {
  const selectedRefId = useBlockId();
  const blockGraphicType = useRecoilValue(blockInfoGraphicType(selectedRefId));
  const isReport = useRecoilValue(isReportState);
  const selectedPeerGroup = assertNotNil(useRecoilValue(blockSelectedPeerGroupId(selectedRefId)));
  const chartComponentHeight = usePeerGroupChartHeight();
  const isCompact = useIsBlockGridCompact();
  const commonGridProps = useCommonGridProps(gridOverrides);
  const { rowData, columnDefs } = usePeerGroupsGrid(data);

  /** One of the few rare times we use raw state, because it is derived from an ag-grid event.
   *  Passing this callback to ag-grid is necessary, as we need to factor the chart into account, too */
  const setRawBlockContentSize = useSetBlockSize(recoilBlockContentSize.rawState(selectedRefId));
  const onGridSizeChange = useCallback(
    (height: number, width: number) => {
      setRawBlockContentSize({
        bounds: {
          height: height + chartComponentHeight + FOOTER_HEIGHT_PX + DISCLAIMER_HEIGHT_PX,
          width,
        },
      });
    },
    [setRawBlockContentSize, chartComponentHeight],
  );

  const chartComponent =
    blockGraphicType === 'BOX_CHART' ? <PeerGroupBoxChart data={data} /> : <PeerGroupPercentileRankChart data={data} />;
  const gridComponent = (
    <GridWrapper isReport={isReport}>
      <ExportableGrid
        {...commonGridProps}
        exportable={exportable}
        selectedRefId={selectedRefId}
        inPrintMode={inPrintMode}
        isCompact={isCompact}
        rowData={rowData}
        columnDefs={columnDefs}
        excelStyles={DATA_TYPE_EXCEL_STYLES}
        tooltipShowDelay={1200}
        suppressRowVirtualisation
        suppressRowTransform
        scrollbarWidth={10}
        domLayout={'autoHeight' as const}
        onGridSizeChange={onGridSizeChange}
      />
    </GridWrapper>
  );

  const peerGroupsBlockComponent = (
    <Container>
      {chartComponent}
      <StyledSeparator />
      {gridComponent}
      <FooterWrapper>
        Peer Group: &nbsp;
        <PeerGroupInfoFooter>{selectedPeerGroup.categoryName}</PeerGroupInfoFooter>
      </FooterWrapper>
      <RankDisclaimer>{PGA_RANK_DISCLAIMER}</RankDisclaimer>
    </Container>
  );
  if (inPrintMode) {
    return peerGroupsBlockComponent;
  }

  return (
    <DownloadableContentBlock
      header={<EmptyHeaderSpace />}
      noBorder
      downloadable={{
        png: true,
        options: {
          fileName: 'Peer Group',
          watermark: {
            top: WATERMARK_POSITION_TOP,
            right: 20,
          },
        },
        tracking: {
          description: 'BUILDER',
          userUploaded: false,
          relativeToBenchmark: !!selectedBlock?.relativeToBenchmark,
          subjectType: undefined,
          subjectId: undefined,
        },
        disabled: false,
        target: downloadableContentRef,
      }}
    >
      {peerGroupsBlockComponent}
    </DownloadableContentBlock>
  );
};

const Container = styled.div`
  .ag-header-group-cell {
    padding: 2px 6px 2px 2px;
  }
`;
const StyledSeparator = styled.div`
  border: 1px solid ${GetColor.Grey};
`;

const FooterWrapper = styled.div`
  height: ${FOOTER_HEIGHT_PX}px;
  align-items: center;
  display: flex;
  padding: 8px;
  flex-direction: row;
  font-size: 12px;
  font-weight: bold;
`;

const PeerGroupInfoFooter = styled.div`
  font-weight: bold;
`;

const RankDisclaimer = styled.div`
  display: flex;
  height: ${DISCLAIMER_HEIGHT_PX}px;
  padding: 12px 8px 12px;
  border-top: 1px solid ${GetColor.Grey};
  font-size: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
`;

export default PeerGroupChart;
