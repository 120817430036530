import type { Benchmark } from 'venn-api';
import type { BenchmarkAction, BenchmarkUIView } from './benchmarkActions';
import type { BenchmarksCompositeState } from './compositeState';
import reduceBenchmarks from './reduceBenchmarks';
import reduceSearch from './reduceSearch';
import reduceComposite from './reduceComposite';
import reduceViewId from './reduceViewId';
import reduceBusy from './reduceBusy';
import type { SearchMenuItem } from '../../../search-menu';

export interface BenchmarksState {
  viewId: BenchmarkUIView;
  benchmarks: Benchmark[];
  search: SearchMenuItem | null;
  composite: BenchmarksCompositeState;
  busy: boolean;
}

export function benchmarksReducer(state: BenchmarksState, action: BenchmarkAction): BenchmarksState {
  return {
    viewId: reduceViewId(state.viewId, action),
    benchmarks: reduceBenchmarks(state.benchmarks, action),
    search: reduceSearch(state.search, action),
    composite: reduceComposite(state.composite, action),
    busy: reduceBusy(state.busy, action),
  };
}
