import React from 'react';
import HeaderCellRenderer, { type HeaderCellRendererProps } from './HeaderCellRenderer';
import { Flexbox } from 'venn-ui-kit';
import styled from 'styled-components';

export interface HeaderDualSubjectCellRendererProps {
  subject1: HeaderCellRendererProps;
  subject2: HeaderCellRendererProps;
}

export const HeaderDualSubjectCellRenderer = ({ subject1, subject2 }: HeaderDualSubjectCellRendererProps) => {
  if (!subject1 || !subject2) {
    return <>--</>;
  }

  return (
    <DualSubjectCellContainer justifyContent="flex-end" direction="row" alignItems="center">
      <HeaderCellRenderer style={{ display: 'inline-block' }} {...subject1} />
      &nbsp; + &nbsp;
      <HeaderCellRenderer style={{ display: 'inline-block' }} {...subject2} />
    </DualSubjectCellContainer>
  );
};

const DualSubjectCellContainer = styled(Flexbox)`
  flex-wrap: wrap;
  .header-cell-wrapper {
    width: unset;
  }
`;
