import type { Theme } from 'venn-ui-kit';
import type { Solution } from 'venn-components';
import { getPortfolioLabColors } from '../logic/getPortfolioLabColors';

interface SolutionAttributes {
  title: string;
  color: string;
  icon: string;
}

export const getSolutionAttributes = (theme: Theme, solution: Solution): SolutionAttributes => {
  const plColors = getPortfolioLabColors(theme);
  switch (solution.category) {
    case 'Current':
      return { title: 'Current Portfolio', color: plColors.current, icon: 'map-marker' };
    case 'Optimized':
      return { title: 'Optimized Portfolio', color: plColors.optimized, icon: 'flask' };
    case 'Alternate':
      return {
        title: `Near-Optimal Portfolio [#${solution.alternateSolutionIdx! + 1}]`,
        color: plColors.alternate,
        icon: 'circle',
      };
    case 'Benchmark':
      return { title: 'Benchmark', color: plColors.benchmark, icon: 'arrows-h' };
    default:
      return { title: '--', color: theme.Colors.DarkGrey, icon: 'circle' };
  }
};
