import React from 'react';
import { useRecoilValue } from 'recoil';
import { isPrintingState, studioPrintOrientationType } from 'venn-state';
import { UrlParam, useDebugValue, withSuspense, type DebugPrintPageState } from 'venn-utils';
import styled from 'styled-components';
import {
  DeveloperFallbackComponent,
  dimensions,
  useAppPrintMode,
  useUrlParamState,
  withErrorBoundary,
} from 'venn-components';
import { GetColor } from 'venn-ui-kit';
import { CssInfo } from './CssInfo';
import { GridInfo } from './GridInfo';
import { BrowserInfo } from './BrowserInfo';
import { FontInfo } from './FontInfo';

/** Page that contains debug information during printing, if the appropraite FF is enabled. */
export const DebugPrintPage = withErrorBoundary(
  DeveloperFallbackComponent,
  withSuspense(
    null,
    React.memo(function DebugPrintPage() {
      const debugPrintPage = useDebugValue('debugPrintPage');
      const [urlPrintState] = useUrlParamState(UrlParam.PRINT_DEBUG);
      const { inPrintMode } = useAppPrintMode();

      const printState = debugPrintPage ?? urlPrintState;
      return (inPrintMode && printState?.enabledOnPrint) || (!inPrintMode && printState?.enabledInApp) ? (
        <DebugPrintPageContent printState={printState} />
      ) : null;
    }),
  ),
);

const DebugPrintPageContent = ({ printState }: { printState: DebugPrintPageState }) => {
  const { totalWidth, totalHeight } = dimensions[useRecoilValue(studioPrintOrientationType)];
  const isPrinting = useRecoilValue(isPrintingState);

  // When printing we let the browser decide how to split the content into pages, so we leave height as undefined
  const height = isPrinting ? undefined : totalHeight;

  return (
    <DebugPrintPageContainer id="debug-print-page-container" style={{ width: totalWidth, height }}>
      <h2>Print Debug Info</h2>
      <p>Controlled by debug panel</p>
      {!!printState?.browserInfoEnabled && <BrowserInfo />}
      {!!printState?.fontInfoEnabled && <FontInfo />}
      {!!printState?.cssInfoEnabled && <CssInfo />}
      {!!printState?.gridInfoEnabled && <GridInfo />}
    </DebugPrintPageContainer>
  );
};

const DebugPrintPageContainer = styled.div`
  background-color: ${GetColor.White};

  margin: auto;
  page-break-after: always;

  font-size: 11px;

  @media screen {
    overflow-y: auto;
  }
`;
