import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  batchGetFundCustomMetadata,
  type Fund,
  getFundCustomMetadata,
  type OperationError,
  type OperationResult,
  type Portfolio,
  updateFundYield,
  type YieldMetric,
} from 'venn-api';
import { useHasFF, logExceptionIntoSentry } from 'venn-utils';
import { useCallback, useMemo } from 'react';
import { compact, uniq } from 'lodash';
import { Notifications, NotificationType } from 'venn-ui-kit';

const FUND_CUSTOM_METADATA_KEY = 'get_fund_custom_metadata';
const CUSTOM_METADATA_KEY = 'get_custom_metadata';
const ONE_HOUR = 1000 * 60 * 60;

const getFundsInPortfolio = (portfolio: Portfolio): (string | undefined)[] => {
  return [portfolio.fund?.id, ...portfolio.children.flatMap((child) => getFundsInPortfolio(child))];
};

export const useCustomMetadata = (subjects: { portfolio?: Portfolio; fund?: Fund }[]) => {
  const enabled = useHasFF('yield_income_ff');
  const fundIds = useMemo(
    () =>
      uniq(
        compact(
          subjects.flatMap((subject) =>
            subject.portfolio ? getFundsInPortfolio(subject.portfolio) : [subject.fund?.id],
          ),
        ),
      ).sort(),
    [subjects],
  );

  return useQuery(
    [CUSTOM_METADATA_KEY, ...fundIds],
    async () => {
      const result = await batchGetFundCustomMetadata(fundIds);
      return result.content;
    },
    {
      enabled,
      suspense: true,
      staleTime: ONE_HOUR,
    },
  );
};

export const useFundCustomMetadata = (fundId: string) => {
  return useQuery({
    queryKey: [FUND_CUSTOM_METADATA_KEY, fundId],
    queryFn: async () => {
      const result = await getFundCustomMetadata(fundId);
      return result.content;
    },
    suspense: true,
    staleTime: ONE_HOUR,
  });
};

export const useUpdateFundYield = (fundId: string) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation<
    OperationResult<YieldMetric>,
    OperationResult<OperationError>,
    number | undefined
  >((value: number | undefined) => updateFundYield(fundId, value), {
    onSuccess: ({ content: fundYieldMetric }: OperationResult<YieldMetric>) => {
      const fundMetadata = queryClient.getQueryData([FUND_CUSTOM_METADATA_KEY, fundId])!;
      queryClient.setQueryData([FUND_CUSTOM_METADATA_KEY, fundId], { ...fundMetadata, yield: fundYieldMetric });
      queryClient.refetchQueries({ queryKey: [FUND_CUSTOM_METADATA_KEY, fundId] });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === CUSTOM_METADATA_KEY && query.queryKey.some((id) => id === fundId),
      });
    },
    onError: (e: OperationResult<OperationError>) => {
      Notifications.notify(e?.content?.message ?? 'Failed to update yield', NotificationType.ERROR);
      // @ts-expect-error VENN-27080 common type mismatch issue
      logExceptionIntoSentry(e);
    },
  });

  const updateYield = useCallback(
    (percentageYield: number | undefined) => {
      mutate(percentageYield);
    },
    [mutate],
  );

  return {
    updateYield,
    isSaving: isLoading,
  };
};
