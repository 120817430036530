import type { SubjectInputId, SubjectWithOptionalFee } from 'venn-state';
import { blockSettings, subjectGroupRequestSubjects, blockRequestSubjects } from 'venn-state';
import { constSelector, useRecoilValue } from 'recoil';
import type { CustomizableBlockSetting } from 'venn-utils';
import { getBlockSubjectTypeName, plural } from 'venn-utils';
import { compact } from 'lodash';
import { useMemo } from 'react';

const emptySelector = constSelector([]);
const EMPTY_ARRAY: ValidationError[] = [];

type ValidationError = {
  type: 'ERROR' | 'WARNING';
  message: string;
};

export const useSubjectGroupErrors = (blockId: string, inputId: SubjectInputId | undefined): ValidationError[] => {
  const groupSubjects = useRecoilValue(inputId ? subjectGroupRequestSubjects([blockId, inputId]) : emptySelector);
  const allSubjects = useRecoilValue(blockRequestSubjects(blockId));
  const blockSetting = useRecoilValue(blockSettings(blockId));

  return useMemo(() => {
    if (!inputId) {
      return EMPTY_ARRAY;
    }

    return compact([validateSubjects(groupSubjects, allSubjects, blockSetting)]);
  }, [inputId, groupSubjects, allSubjects, blockSetting]);
};

const validateSubjects = (
  groupSubjects: SubjectWithOptionalFee[],
  allSubjects: SubjectWithOptionalFee[],
  blockSetting: CustomizableBlockSetting,
): ValidationError | undefined => {
  if (groupSubjects.length === 0) {
    return {
      type: 'ERROR',
      message: `Subject group does not contain any ${getBlockSubjectTypeName(blockSetting)}s.`,
    };
  }
  if (groupSubjects.length < blockSetting.requiredSubjects && allSubjects.length < blockSetting.requiredSubjects) {
    return {
      type: 'WARNING',
      message: `Subject group contains only ${groupSubjects.length} ${plural(groupSubjects.length, {
        1: getBlockSubjectTypeName(blockSetting),
        other: `${getBlockSubjectTypeName(blockSetting)}s`,
      })}.`,
    };
  }
  return undefined;
};
