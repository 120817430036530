import { noop } from 'lodash';
import React from 'react';
import type { LogoDetails, PrintSettings } from 'venn-api';
import { asyncNoop } from 'venn-utils';

export interface StudioPrintSettingsContextValue {
  hasDefault: boolean;
  addDisclosurePage: boolean;
  defaultLogoId?: string;
  allLogos: LogoDetails[];
  needsSaving: boolean;
  organizationPrintSettings?: PrintSettings;
  setOrganizationName: (organizationName: string) => void;
  setDisclaimer: (disclaimer?: string) => void;
  setCoverPageDisclosure: (coverPageDisclosure?: string) => void;
  setDisclosure: (disclosure?: string) => void;
  setDisclaimerFontSize: (disclaimerFontSize?: number) => void;
  setCoverPageDisclosureFontSize: (coverPageDisclosureFontSize?: number) => void;
  setDisclosureFontSize: (disclosureFontSize?: number) => void;
  storeSettings: (settings: PrintSettings) => Promise<void>;
  uploadOrganizationLogo: (input: HTMLInputElement | null, isDefault: boolean) => Promise<void>;
  loading: boolean;
  deleteLogo: (logoId?: string | undefined) => Promise<void>;
  setDefaultLogo: (logoId?: string | undefined) => Promise<void>;
  getAllLogos: () => Promise<void>;
  loadSettings: () => Promise<void>;
}

export const defaultStudioPrintSettingsContextValue: StudioPrintSettingsContextValue = {
  hasDefault: false,
  addDisclosurePage: false,
  allLogos: [],
  needsSaving: false,
  setOrganizationName: noop,
  setDisclaimer: noop,
  setCoverPageDisclosure: noop,
  setDisclosure: noop,
  setDisclaimerFontSize: noop,
  setCoverPageDisclosureFontSize: noop,
  setDisclosureFontSize: noop,
  storeSettings: asyncNoop,
  uploadOrganizationLogo: asyncNoop,
  loading: true,
  deleteLogo: asyncNoop,
  setDefaultLogo: asyncNoop,
  getAllLogos: asyncNoop,
  loadSettings: asyncNoop,
};

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export const StudioPrintSettingsContext = React.createContext<StudioPrintSettingsContextValue>(
  defaultStudioPrintSettingsContextValue,
);
