import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';
import { logExceptionIntoSentry, Numbers } from 'venn-utils';
import React from 'react';

interface AllocationTextProps {
  /** expected between 0 and 1 */
  allocation: number;
  fontSize?: string;
}

/** Component to display properly formatted messages about unknown allocations */
const AllocationPercentageText = ({ allocation, fontSize }: AllocationTextProps) => {
  if (allocation < 0 || allocation > 1) {
    logExceptionIntoSentry(`Invalid allocation in geography: ${allocation}`);
    return null;
  }

  const formattedAllocation = Numbers.safeFormatPercentage(allocation, 2, true, true);

  return (
    <AccountedForText fontSize={fontSize}>
      {`${formattedAllocation} of holdings are accounted for in geography${
        formattedAllocation !== '100%' ? ', while the rest is unknown.' : '.'
      }`}
    </AccountedForText>
  );
};

const AccountedForText = styled.div<{ fontSize?: string }>`
  display: flex;
  font-style: italic;
  color: ${GetColor.DarkGrey};
  justify-content: center;
  ${({ fontSize }) => fontSize && `font-size: ${fontSize};`}
`;

export default AllocationPercentageText;
