import { GetColor, Icon, Label, Tooltip } from 'venn-ui-kit';
import styled from 'styled-components';
import NumberInput from './NumberInput';
import React from 'react';

export const FontSizeInput = ({
  label,
  value,
  onChange,
  resetEnabled,
  onReset,
  resetTooltip,
  isReadOnly,
  name,
  min,
  max,
  width,
  height,
}: {
  label?: string;
  value?: number;
  name?: string;
  onChange: (value: number) => void;
  resetEnabled?: boolean;
  onReset?: () => void;
  resetTooltip?: string;
  isReadOnly?: boolean;
  min?: number;
  max?: number;
  width?: number;
  height?: number;
}) => (
  <NumberInputWrapper>
    {label && <Label>{label}</Label>}
    <NumberInputControlsWrapper>
      <FontSizeNumberInput
        label={name ?? label}
        value={value}
        onChange={onChange}
        disabled={isReadOnly}
        width={width ?? 70}
        height={height ?? 35}
        precision={1}
        min={min ?? 4}
        max={max ?? 32}
        step={0.5}
        controlled
      />
      {resetEnabled && (
        <Tooltip usePortal content={resetTooltip}>
          <ResetButton
            type="button"
            onClick={onReset}
            data-testid={`reset-${name}`}
            aria-label={resetTooltip}
            disabled={isReadOnly}
          >
            <Icon type="clock-rotate-left" />
          </ResetButton>
        </Tooltip>
      )}
    </NumberInputControlsWrapper>
  </NumberInputWrapper>
);

const ResetButton = styled.button`
  color: ${GetColor.MidGrey1};

  :hover {
    color: ${GetColor.Primary.Dark};
  }
`;

const FontSizeNumberInput = styled(NumberInput)`
  &:after {
    content: 'pt';
    color: ${GetColor.LightGrey};
    position: relative;
    top: -29px;
    left: 53px;
  }
`;

const NumberInputControlsWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const NumberInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
`;
