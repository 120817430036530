import type { PivotedRow, RowToPivot } from '../types';
import { GREY_BG_CLASS, ITALIC_CLASS, SUBJECT_ROW_CELL_RENDERER } from '../customAnalysisContants';
import { isColGroupDef } from './typeUtils';
import type { ColDef, ColGroupDef } from 'ag-grid-community';

export const mapRowsToColumns = (rows: RowToPivot[], columns: (ColDef | ColGroupDef)[]): ColDef[] => {
  if (!columns.length) return [];

  const firstColumn = columns[0];
  const columnsForRow = isColGroupDef(firstColumn) ? firstColumn.children : [firstColumn];
  const nonGroupColumnsForRow = columnsForRow.filter((column): column is ColDef => !isColGroupDef(column));

  // create additional row for every sub-column
  const rowsWithColumn = rows
    .map((row) => {
      const columnsToHandle = row.isMetadata ? nonGroupColumnsForRow.slice(0, 1) : nonGroupColumnsForRow;
      return columnsToHandle.map((column) => ({ row, column }));
    })
    .flat();

  return rowsWithColumn.map(({ row, column }, rowIndex) => {
    const { valueFormatter, cellClass, cellRenderer, headerName } = column;
    const combineHeaderName = isColGroupDef(firstColumn) && !row.isMetadata;

    return {
      valueFormatter,
      cellClass,
      cellRenderer,
      ...mapRowToColumn(row, rowIndex),
      headerName: combineHeaderName ? `${row.label} ${headerName}` : row.label,
    };
  });
};

export const mapRowToColumn = (row: RowToPivot, index: number): ColDef => {
  return {
    wrapHeaderText: true,
    headerName: row.label,
    headerClass: row.isMetadata ? [GREY_BG_CLASS, ITALIC_CLASS] : undefined,
    cellRendererParams: {
      isMetadata: row.isMetadata,
      type: 'type' in row ? row.type : undefined,
      key: row.key,
      customBenchmarkType: row.customBenchmarkType,
      benchmarkNameRangeMap: row.benchmarkNameRangeMap,
    },
    field: `value.${index}`,
  };
};

export const mapColumnToRow = (
  colDef: ColDef | ColGroupDef,
  columnIndex: number,
  rowsSource: RowToPivot[],
): PivotedRow => {
  return isColGroupDef(colDef)
    ? mapGroupColumnToRow(colDef, columnIndex, rowsSource)
    : mapSingleColumnToRow(colDef, columnIndex, rowsSource);
};

export const mapSingleColumnToRow = (
  { headerName = '', headerComponentParams }: ColDef,
  columnIndex: number,
  rowsSource: RowToPivot[],
): PivotedRow => ({
  label: headerName,
  value: rowsSource.map((row) => row.value[columnIndex]),
  key: headerName.replace(/[^A-Z0-9]/gi, '_'),
  headerComponentParams,
});

export const mapGroupColumnToRow = (
  { headerName = '', headerGroupComponentParams, children }: ColGroupDef,
  columnIndex: number,
  rowsSource: RowToPivot[],
): RowToPivot => {
  const subColumnMetricKeys: string[] = children
    .filter((child) => !isColGroupDef(child))
    .map((child: ColDef) => child.headerComponentParams?.metricKey)
    .filter(Boolean);

  const combinedValues = rowsSource.map((row) => {
    if (row.isMetadata) {
      return row.value[columnIndex];
    }
    return subColumnMetricKeys.map((metricKey) => row.value[columnIndex]?.[metricKey]);
  });

  return {
    label: headerName,
    value: combinedValues.flat(),
    key: headerName.replace(/[^A-Z0-9]/gi, '_'),
    headerComponentParams: headerGroupComponentParams,
  };
};

export const getSubjectsColumnDefinition = (): ColDef => ({
  suppressMovable: true,
  flex: 2.5,
  minWidth: 70,
  maxWidth: 200,
  field: 'label',
  cellRenderer: SUBJECT_ROW_CELL_RENDERER,
  headerName: '',
  autoHeight: true,
  suppressSizeToFit: true,
});
