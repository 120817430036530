import type { History } from 'history';
import { compact } from 'lodash';
import isEqual from 'lodash/isEqual';
import queryString from 'query-string';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import type { OrderEnum } from 'venn-api';
import { useDebounce } from 'venn-components';
import type { PrivatesSearchParams } from 'venn-state';
import {
  DEFAULT_PARAMS_V2,
  DEFAULT_PRIVATE_PARAMS,
  privateLibrarySearchParams,
  useScopedSetRecoilState,
} from 'venn-state';
import { analyticsService, getQueryParams, LibraryItemType, LibraryTab } from 'venn-utils';

/** A hook for managing synchronization between data library state (residing in recoil) and URL state
 *  It is a wrapper for the underlying logic and NOT meant to be reused.
 *  If you just want to read/write library state, see useLibraryState.ts
 *
 *  (TODO) in the future is to get rid of this file and use recoil-sync library instead.
 *  The one big atom consisting library state could be split into several smaller ones, each of which
 *  could be synced independently from others via an appropriate refine function. See more:
 *  https://recoiljs.org/docs/recoil-sync/sync-effect
 *  */
export const usePrivateLibraryStateURLSynchronizer = (history: History<{ shouldListenerIgnore?: boolean }>) => {
  const librarySearchParams = useRecoilValue(privateLibrarySearchParams);
  const setLibrarySearchParams = useScopedSetRecoilState(privateLibrarySearchParams);
  const [debouncedParams] = useDebounce(librarySearchParams, 500); // Used to update the url when queryParams change

  // Track when the user changes the filters
  useEffect(() => {
    analyticsService.libraryFiltersSelected({
      tagFilters: [],
      quickFilters: librarySearchParams.filters ?? [],
      typeFilters: compact([librarySearchParams.itemType]),
      currencyFilters: [],
      dataSourceFilters: [],
      metricFilters: [],
      assetTypeFilters: [],
      libraryTab: LibraryTab.PrivateAssets,
    });
  }, [librarySearchParams.itemType, librarySearchParams.filters]);

  useEffect(() => {
    // initial sync search params
    setLibrarySearchParams(() => {
      const mergeParams = { ...DEFAULT_PARAMS_V2, ...getParamsFromUrl(history.location.search) };
      if (mergeParams.itemType === LibraryItemType.NONE) {
        mergeParams.itemType = LibraryItemType.ALL;
      }
      return mergeParams;
      // this should happen on mount only, and the eslint rule is intentionally disabled
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Watches for updates to history.location.search and updates librarySearchParams accordingly
    return history.listen(({ state, search }) => {
      if (state?.shouldListenerIgnore) {
        return;
      }
      setLibrarySearchParams((prev) => {
        const paramsFromUrl = getParamsFromUrl(search);
        if (isEqual(paramsFromUrl, prev)) {
          return prev;
        }
        return {
          ...prev,
          ...paramsFromUrl,
        };
      });
    });
  }, [history, setLibrarySearchParams]);

  // Watches for updates to debouncedParams and updates history.location.search accordingly
  useEffect(() => {
    const path = history.location.pathname;
    const existing = getParamsFromUrl(history.location.search);
    const mergedParams = {
      ...existing,
      ...debouncedParams,
    };
    const newSearch = queryString.stringify(mergedParams);
    if (path && !isEqual(existing, mergedParams)) {
      history.push(`${path}?${newSearch}`, { shouldListenerIgnore: true });
    }
  }, [debouncedParams, history]);
};

const getParamsFromUrl = (search: string): PrivatesSearchParams => {
  const queryParams = getQueryParams(search);
  const searchParams: PrivatesSearchParams = {
    ...DEFAULT_PRIVATE_PARAMS,
    selectedIds:
      typeof queryParams.selectedIds === 'string' ? [queryParams.selectedIds] : queryParams.selectedIds || [],
    filters: typeof queryParams.filters === 'string' ? [queryParams.filters] : queryParams.filters || [],
  };

  if (queryParams.itemType) {
    searchParams.itemType = queryParams.itemType as LibraryItemType;
  }
  if (queryParams.lastTrackTime) {
    searchParams.lastTrackTime = queryParams.lastTrackTime && Number(queryParams.lastTrackTime as string);
  }
  if (queryParams.page) {
    searchParams.page = Number(queryParams.page as string);
  }
  if (queryParams.order) {
    searchParams.order = queryParams.order as OrderEnum;
  }
  // make sure we get the empty string
  if (queryParams.name !== undefined) {
    searchParams.name = queryParams.name as string;
  }
  if (queryParams.sortBy) {
    searchParams.sortBy = queryParams.sortBy === 'null' ? undefined : (queryParams.sortBy as string);
  }

  return searchParams;
};
