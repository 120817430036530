import React from 'react';

import { analyticsService, Routes, useHasFF } from 'venn-utils';
import ShellNavigationLink from './ShellNavigationLink';
import AccountDropdown from './AccountDropdown';
import HelpCenterDropdown from './HelpCenterDropdown';
import { compact } from 'lodash';

const BottomLinks = () => {
  const hasStudioReportEditor = useHasFF('studio_report_editor');
  const bottomIcons = compact([
    <ShellNavigationLink
      navId="factor-lens-education"
      key="factors"
      icon="graduation-cap"
      label="Factor Lens Education"
      path={Routes.FACTOR_LENS_HOMEPAGE}
      onClick={() => {
        analyticsService.navigationTriggered({
          location: 'navigation bar',
          itemType: 'link',
          userIntent: 'navigate',
          destinationPageTitle: 'Factor Lens Homepage',
        });
      }}
      hideLabel
      showTopNav
      iconFontSize={20}
      tooltipContent="Factor Lens Education"
    />,
    <div key="help" className="qa-help-link">
      <HelpCenterDropdown showTopNav />
    </div>,
    hasStudioReportEditor ? (
      <ShellNavigationLink
        navId="archive-page"
        key="archive-page"
        icon="box-archive"
        label="Archive Document"
        path={Routes.ARCHIVE_PAGE_PATH}
        onClick={() => {
          analyticsService.navigationTriggered({
            location: 'navigation bar',
            itemType: 'link',
            userIntent: 'navigate',
            destinationPageTitle: 'Archive Page',
          });
        }}
        hideLabel
        showTopNav
        iconFontSize={20}
        tooltipContent="Document Archive"
      />
    ) : null,
  ]);

  return (
    <>
      {bottomIcons}
      <AccountDropdown showTopNav key="settings" />
    </>
  );
};

export default BottomLinks;
