import type { AnalysisView, AnalysisViewSearchResult, PrivatePortfolioNode } from 'venn-api';

export enum PageType {
  GRID,
  TITLE,
  SECTION,
  CONTENTS,
  DISCLOSURE,
}

export enum ReportingStyleEnum {
  TEARSHEET = 'TEARSHEET',
  REPORT = 'REPORT',
  TEARSHEET_TEMPLATE = 'TEARSHEET_TEMPLATE',
  REPORT_TEMPLATE = 'REPORT_TEMPLATE',
}

export interface StudioTemplateState {
  newDocument: AnalysisView;
  openAllocatorForPrivatePortfolio?: PrivatePortfolioNode;
  modifiedUnsavedPortfolio?: PrivatePortfolioNode;
  /** Need to refetch full document */
  id?: string;
}

export interface AnalysisViewSearchResultWithUIState extends AnalysisViewSearchResult {
  selected?: boolean;
  templateName?: string;
  ownershipName?: string;
}
