import type { IHeaderParams } from 'ag-grid-community';
import React, { useCallback } from 'react';
import { CheckboxWrapper } from 'venn-ui-kit';

export const getCheckboxMenuHeaderRenderer = (setSelectedRecoilState: (selectedIds: string[]) => void) => {
  return function CheckboxMenuHeaderRenderer({ api }: IHeaderParams) {
    const areAllVisibleRowsChecked = useCallback(() => {
      let checkedRows = 0;
      const firstDisplayedRow = api.getFirstDisplayedRow();
      const lastDisplayedRow = api.getLastDisplayedRow();
      for (let currentRow = firstDisplayedRow; currentRow <= lastDisplayedRow; ++currentRow) {
        checkedRows += api.getDisplayedRowAtIndex(currentRow)?.isSelected() ? 1 : 0;
      }
      return checkedRows > 0 && checkedRows === lastDisplayedRow - firstDisplayedRow + 1;
    }, [api]);

    const setAllVisibleRows = useCallback(
      (value: boolean) => {
        const firstDisplayedRow = api.getFirstDisplayedRow();
        const lastDisplayedRow = api.getLastDisplayedRow();
        const rowList = [];
        for (let currentRow = firstDisplayedRow; currentRow <= lastDisplayedRow; ++currentRow) {
          const row = api.getDisplayedRowAtIndex(currentRow);
          if (value && row) {
            rowList.push(row.id!);
          }
          row?.setSelected(value);
        }
        setSelectedRecoilState(rowList);
      },
      [api],
    );
    const allChecked = areAllVisibleRowsChecked();
    const onChange = () => {
      setAllVisibleRows(!allChecked);
    };
    return (
      <CheckboxWrapper
        className="ag-theme-alpine qa-header-checkbox"
        onChange={onChange}
        checked={allChecked}
        justify="left"
      />
    );
  };
};
