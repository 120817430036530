import { useRecoilValue } from 'recoil';
import { exportingToExcel, PageLayout, reportPageLayout } from 'venn-state';
import { useAppPrintMode } from 'venn-components';
import { Routes, useHasFF } from 'venn-utils';
import { useHistory } from 'react-router-dom';

/**
 * Helper to get wether scrolling should be virtualized in studio/report lab
 * Horizontal layout is disabled for report lab as Virtuoso doesn't current support horizontal scrolling https://github.com/petyosi/react-virtuoso/issues/62
 * Never enable virtualization for printing for all blocks / pages to render
 */
export const useVirtualization = () => {
  // TODO: these useContexts don't work correctly when useVirtualization in useStudioToolbar because it is used in the Studio Context provider!
  // So it ultimatley is getting used before these contexts are even provided at all.
  // TODO: replace isReport definition with the one from recoil state when studio contexts are fully removed
  const history = useHistory();
  const isReport = history.location.pathname === Routes.REPORT_LAB_PATH;
  const { inPrintMode } = useAppPrintMode();
  const layout = useRecoilValue(reportPageLayout);
  const exporting = useRecoilValue(exportingToExcel);
  const hasVirtualizationFF = useHasFF('virtualization_ff');

  return hasVirtualizationFF && !exporting && !inPrintMode && (!isReport || layout !== PageLayout.HORIZONTAL);
};
