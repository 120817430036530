const ForecastsModalClassNames = {
  CancelButton: 'forecasts-modal-cancel',
  PrimaryButton: 'forecasts-modal-primary',
  SecondaryButton: 'forecasts-modal-secondary',
  SelectableMarker: 'forecasts-modal-selectable-marker',
  DotMarker: 'specific-forecast-dot',
  DiamondMarker: 'specific-forecast-diamond',
  MarkerContainer: 'specific-forecast-marker-container',
  NewForecastBanner: 'specific-forecast-banner-new',
  DefaultForecastBanner: 'specific-forecast-banner-defaulted',
  UpdatedForecastBanner: 'specific-forecast-banner-updated',
  ForecastFactorsTable: 'specific-forecast-factors-table',
  EditForecast: 'specific-forecast-edit',
  DeleteForecast: 'specific-forecast-edit',
  CMARow: 'capital-market-assumption-row',
  CMAMenu: 'capital-market-assumption-menu',
  RowPickerInput: 'forecasts-row-picker-input',
  ForecastsListsTabsPicker: 'forecasts-tabs-picker',
  ForecastsListsTabsContainer: 'forecasts-tabs-container',
  ForecastsTabForecasts: 'forecasts-tab-forecasts',
  ForecastsTabResiduals: 'forecasts-tab-residuals',
};
export default ForecastsModalClassNames;
