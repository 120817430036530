import type { Reducer } from 'react';
import { useCallback, useEffect, useRef, useReducer } from 'react';
import type { GeneralAnalysisTemplate } from 'venn-api';
import { SupportedErrorCodes } from 'venn-api';
import type { AnalysesPeriod, AnalysisConfig, FeatureName } from 'venn-utils';
import { analyticsService, emptyAnalysisTemplate, FS } from 'venn-utils';
import type { AnalysisStatusForTracking } from 'venn-components';

/*
 Tracking ids are used as unique identifiers of analysisConfigs' set of analysis requests.

 They let us group multiple API requests done from different blocks into one tracking request (in the hook below).
 In other places in the application, they are used to identify whether the analyses should be re-run after a subject change.

  - In some cases we just want to update the subject, but not re-fetch analyses (i.e. "non-meaningful" changes
 to the subject, like adding an empty investment).
  - On pages like Create Template or Create Portfolio NUX flow we don't want to track successes/failures of analysis blocks.

 Therefore, we need to be able to assign trackingIds that will differentiate the analysisConfigs, but that will be ignored
 by the tracking hook below. The solution to that is a threshold on values of trackingId that we track. Since these values
 are timestamps, the hook below only track the results of the analyses ran based on analysisConfig with tracking id greater
 than or equal the timestamp for January 1st, 2019.
 */

export const TRACK_TIMESTAMPS_AFTER = new Date('2019-01-01').getTime();

interface ReducerState {
  analysisStatuses: AnalysisStatusForTracking[];
  blocks: string[];
  analysisConfig: AnalysisConfig;
  dateRange?: string;
}

interface ReducerAction {
  actionTrackingId: number;
  actionAnalysisStatuses: AnalysisStatusForTracking[];
  actionAnalysisConfig: AnalysisConfig;
  actionType: 'REGISTER_STATUSES' | 'UPDATE_CONFIG';
  actionDateRange?: string;
}

function trackFailedAnalyses(analyses: AnalysisStatusForTracking[], config: AnalysisConfig, dateRange?: string) {
  const failedAnalyses = analyses.filter((updatedStatus) => !updatedStatus.succeeded);
  if (failedAnalyses.length === 0 || config.trackingId < TRACK_TIMESTAMPS_AFTER) {
    return;
  }

  // Error code tied to over-usage of metered investments
  const overThreshold = failedAnalyses.some(
    (errors) => errors.errorCode && errors.errorCode === SupportedErrorCodes.AnalysisLimitErrorCode,
  );
  const analysesPeriod: Partial<AnalysesPeriod> = failedAnalyses?.[0]?.analysesPeriod || {};

  analyticsService.analysisFailed({
    analysisName: config.analysisTemplate?.name,
    blocks: failedAnalyses.map(({ title }) => title),
    dateRange: dateRange ?? '',
    frequency: analysesPeriod.frequency,
    hasBenchmark: config.subject && !!config.subject.activeBenchmark,
    hasProxy: config.subject && config.subject.hasProxy,
    overThreshold,
    objectType: config.subject && config.subject.type,
    relativeToBenchmark: config.relative,
  });
}

function trackIfNecessary(
  blocks: string[],
  analysisStatuses: AnalysisStatusForTracking[],
  analysisConfig: AnalysisConfig,
  dateRange?: string,
) {
  if (
    blocks &&
    analysisStatuses &&
    analysisStatuses.length > 0 &&
    analysisStatuses.length < blocks.length &&
    analysisConfig.trackingId >= TRACK_TIMESTAMPS_AFTER
  ) {
    // If on unmount we don't have all the statuses, this means we haven't tracked their failures yet
    // Track them before the component unmounts
    trackFailedAnalyses(analysisStatuses, analysisConfig, dateRange);
  }
}

function reducer(
  prevState: ReducerState,
  { actionTrackingId, actionAnalysisStatuses, actionAnalysisConfig, actionType, actionDateRange }: ReducerAction,
): ReducerState {
  const { analysisStatuses, blocks, analysisConfig, dateRange } = prevState;
  const actionBlocks = getTemplateBlocksForTracking(actionAnalysisConfig.analysisTemplate);

  if (actionType === 'UPDATE_CONFIG') {
    if (
      actionAnalysisConfig.trackingId > analysisConfig.trackingId ||
      actionAnalysisConfig.trackingId < TRACK_TIMESTAMPS_AFTER
    ) {
      trackIfNecessary(blocks, analysisStatuses, analysisConfig, dateRange);
      return {
        analysisConfig: actionAnalysisConfig,
        blocks: actionBlocks,
        analysisStatuses: [],
      };
    }
    return prevState;
  }

  // else actionType === 'REGISTER_STATUSES'

  if (!blocks) {
    // If there are no blocks, we don't have a list of analyses to track the statuses of
    return prevState;
  }

  if (analysisConfig.trackingId > actionTrackingId || analysisConfig.trackingId < TRACK_TIMESTAMPS_AFTER) {
    // Tracking ID has changed: this status update arrived too late to be tracked and is no longer relevant
    return prevState;
  }

  if (analysisConfig.trackingId === actionTrackingId && analysisStatuses.length === blocks.length) {
    // All blocks have registered statuses already and the tracking request was sent to the API
    // (we shouldn't be trying to register any more of them)
    return prevState;
  }

  let updatedStatuses = analysisStatuses;
  let updatedConfig = analysisConfig;
  let updatedBlocks = blocks;
  let updatedDateRange = dateRange;

  if (analysisConfig.trackingId < actionTrackingId) {
    // The state is stale: action was fired for newer config than we have in state
    trackIfNecessary(blocks, analysisStatuses, analysisConfig, dateRange);
    updatedStatuses = [];
    updatedConfig = actionAnalysisConfig;
    updatedBlocks = actionBlocks;
    updatedDateRange = actionDateRange;
  }

  let hasUpdatedAnyStatus = false;
  actionAnalysisStatuses.forEach((newAnalysisStatus: AnalysisStatusForTracking) => {
    if (!updatedBlocks.find((block) => block === newAnalysisStatus.name)) {
      // The analysis we try to track the status for is not a part of the current template
      return;
    }

    const existingStatus = updatedStatuses.find(({ name }) => name === newAnalysisStatus.name);
    if (existingStatus) {
      // The status for this analysis ID was already recorded
      return;
    }

    updatedStatuses.push(newAnalysisStatus);
    hasUpdatedAnyStatus = true;
  });

  if (hasUpdatedAnyStatus && updatedStatuses.length === updatedBlocks.length) {
    // We have registered statuses for all blocks; track them now
    trackFailedAnalyses(updatedStatuses, updatedConfig, actionDateRange);
  }

  return {
    blocks: updatedBlocks,
    analysisConfig: updatedConfig,
    analysisStatuses: updatedStatuses,
    dateRange: updatedDateRange,
  };
}

export default (analysisConfig: AnalysisConfig) => {
  const [state, dispatch] = useReducer<Reducer<ReducerState, ReducerAction>>(reducer, {
    analysisStatuses: [],
    blocks: [],
    analysisConfig: {
      analysisTemplate: emptyAnalysisTemplate(),
      subject: undefined,
      selectedTimeFrame: { startTime: undefined, endTime: undefined },
      relative: false,
      category: 'HIDDEN',
      trackingId: -1,
    },
    dateRange: undefined,
  });

  const statusesRef = useRef<AnalysisStatusForTracking[]>([]);
  const blocksRef = useRef<string[]>([]);
  const configRef = useRef<AnalysisConfig>({
    analysisTemplate: emptyAnalysisTemplate(),
    subject: undefined,
    selectedTimeFrame: { startTime: undefined, endTime: undefined },
    relative: false,
    category: 'HIDDEN',
    trackingId: -1,
  });
  const dateRangeRef = useRef<string | undefined>(undefined);

  useEffect(() => {
    if (analysisConfig.trackingId !== state.analysisConfig.trackingId) {
      if (
        state.analysisStatuses.length < state.blocks.length &&
        state.analysisConfig.trackingId >= TRACK_TIMESTAMPS_AFTER
      ) {
        // When we receive the new config, track previously registered statuses (if not yet tracked)
        trackFailedAnalyses(state.analysisStatuses, state.analysisConfig, state.dateRange);
      }
      dispatch({
        actionType: 'UPDATE_CONFIG',
        actionTrackingId: analysisConfig.trackingId,
        actionAnalysisStatuses: [],
        actionAnalysisConfig: analysisConfig,
      });
    }
  }, [analysisConfig, state.analysisConfig, state.analysisStatuses, state.blocks, state.dateRange]);

  useEffect(() => {
    statusesRef.current = state.analysisStatuses;
    blocksRef.current = state.blocks;
    configRef.current = state.analysisConfig;
    dateRangeRef.current = state.dateRange;
  }, [state.analysisStatuses, state.blocks, state.analysisConfig, state.dateRange]);

  const updateAnalysisStatusForTracking = useCallback(
    (actionAnalysisStatuses: AnalysisStatusForTracking[], actionTrackingId: number, dateRange: string | undefined) => {
      dispatch({
        actionType: 'REGISTER_STATUSES',
        actionTrackingId,
        actionAnalysisStatuses,
        actionAnalysisConfig: analysisConfig,
        actionDateRange: dateRange,
      });
    },
    [analysisConfig],
  );

  // This code should only fire on "unmount"
  // We can't access the updates component status here, so we need to use refs
  useEffect(() => {
    return () => {
      if (
        blocksRef.current &&
        statusesRef.current &&
        statusesRef.current.length > 0 &&
        statusesRef.current.length < blocksRef.current.length &&
        configRef.current.trackingId >= TRACK_TIMESTAMPS_AFTER
      ) {
        // If on unmount we don't have all the statuses, this means we haven't tracked their failures yet
        // Track them before the component unmounts
        trackFailedAnalyses(statusesRef.current, configRef.current, dateRangeRef.current);
      }
    };
  }, []);

  return { updateAnalysisStatusForTracking };
};

/** Special templates that combine factors into one group */
export enum SpecialFactorTemplateId {
  FACTOR = 'factor',
  TEARSHEET = 'tearsheet',
  ALL = 'all',
}

const includingFactorAnalysisList = Object.keys(SpecialFactorTemplateId).map((key) => SpecialFactorTemplateId[key]);

export const getTemplateBlocksForTracking = (template: GeneralAnalysisTemplate | null): string[] => {
  if (!template) {
    return [];
  }

  const availableBlocks = template.analysisBlocks.filter(
    (block) =>
      !block.pricingFeatures ||
      block.pricingFeatures.every((feature) => FS.has(feature.toLocaleLowerCase() as FeatureName)),
  );

  // Since factor template, tearsheet template and all template has a special factor analysis section,
  // We would replace all individual factor blocks with a single FACTOR_ANALYSIS block
  if (includingFactorAnalysisList.includes(template.id)) {
    return availableBlocks
      .map((block) => block.analysisBlockType.toString())
      .filter((name) => !name.includes('FACTOR'))
      .concat(['FACTOR_ANALYSIS']);
  }

  return availableBlocks.map((block) => block.analysisBlockType) || [];
};
