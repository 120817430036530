import React from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

export interface TreeNode<T> {
  data: T;
  key: string;
  children?: TreeNode<T>[];
}

export interface TreeViewRow<T> {
  data: T;
  level: number;
  key: string;
  isLeaf: boolean;
}

export const flattenTree = <T,>(root: TreeNode<T>, level = 0): TreeViewRow<T>[] => {
  const { children, key, ...rest } = root;
  return [
    { key, level, isLeaf: isEmpty(children), ...rest },
    ...(children ?? []).flatMap((child) => flattenTree(child, level + 1)),
  ];
};

export interface TreeViewProps<T, U extends TreeViewRow<T>> {
  rows: U[];
  rowRenderer: (row: U) => React.ReactNode;
}

export interface SelectProps {
  selectedKeys: string[];
  onSelect: (key: string) => void;
}

export interface SelectableTreeViewBaseProps<T> {
  rows: TreeViewRow<T>[];
  rowRenderer: (row: TreeViewRow<T> & SelectableRow) => React.ReactNode;
}

export interface SelectableTreeViewProps<T> extends SelectProps, SelectableTreeViewBaseProps<T> {}

export interface SelectableRow {
  selected: boolean;
  onSelect: (key: string) => void;
}

/**
 * @param rows A list of rows of type {@link TreeViewRow}. If you have an tree-like object (and it conforms to
 *        {@link TreeNode}, you may use {@link flattenTree} to convert it to {@link TreeViewRow}s.
 * @param rowRenderer used to render individual rows. rowRenderer gets {@link TreeViewRow} as params.
 */
export const TreeView = <T, U extends TreeViewRow<T>>({ rows, rowRenderer }: TreeViewProps<T, U>) => (
  <ListWithoutBullets>
    {rows.map((props) => (
      <li key={props.key}>{rowRenderer(props)}</li>
    ))}
  </ListWithoutBullets>
);

/**
 * @param rows A list of rows of type {@link TreeViewRow}. If you have an tree-like object (and it conforms to
 *        {@link TreeNode}, you may use {@link flattenTree} to convert it to {@link TreeViewRow}s.
 * @param rowRenderer used to render individual rows. This needs to handle the selection mechanisms.
 *        {@link rowRenderer} gets {@link TreeViewRow & @link SelectableRow} as params
 * @param selectedKeys keys of {@link TreeViewRow}s to be selected
 * @param onSelect callback to be triggered on selection.
 */
export const SelectableTreeView = <T,>({ rows, rowRenderer, selectedKeys, onSelect }: SelectableTreeViewProps<T>) => (
  <TreeView
    rows={rows.map((row) => ({ ...row, selected: selectedKeys.includes(row.key), onSelect }))}
    rowRenderer={rowRenderer}
  />
);

const ListWithoutBullets = styled.ul`
  list-style-type: none;
  padding-inline-start: 0;
`;
