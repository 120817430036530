import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import { GetColor, TooltipPosition, Tooltip, ColorUtils } from 'venn-ui-kit';

const indicatorSize = 15;
const outerRadius = 8;
const sliderHeight = indicatorSize;
const sliderWidth = 40;
const colorDisabled = GetColor.Grey;
const colorDisabledBackground = ColorUtils.hex2rgbaFrom(colorDisabled, 0.2);

const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  color: ${({ disabled }) => (disabled ? colorDisabled : GetColor.Black)};
  background-color: ${GetColor.White};
  border-radius: ${outerRadius}px;
  font-weight: bold;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  user-select: none;
`;

const Wrapper = styled.div`
  position: relative;
`;

const Toggle = styled.div<ToggleProps>`
  width: ${sliderWidth}px;
  height: ${sliderHeight}px;
  border-radius: ${outerRadius}px;
  background-color: ${GetColor.White};
  box-sizing: content-box;
  font-size: 11px;
  letter-spacing: 1px;

  ${({ disabled }) =>
    disabled
      ? `
    background-color: ${colorDisabledBackground};
    cursor: not-allowed;
    pointer-events: none;
  `
      : ''};
`;

const Indicator = styled.div<TextIndicatorProps>`
  display: block;
  width: ${indicatorSize}px;
  height: ${indicatorSize}px;
  transition-duration: 0.3s;
  border-radius: ${indicatorSize / 2}px;
  background-color: ${({ disabled }) => (disabled ? colorDisabled : GetColor.Primary.Dark)};
  ${({ toggled }) =>
    !toggled
      ? `
    transform: translateX(${sliderWidth - indicatorSize}px);
  `
      : ''};
`;

const TextIndicator = styled.span<TextIndicatorProps>`
  position: absolute;
  top: 1px;
  line-height: 13px;
  left: ${({ toggled }) => (toggled ? '22px' : '10px')};
`;

export interface PercentageToggleProps {
  tooltipPosition?: TooltipPosition;
  tooltipMessage?: string;
  disabled?: boolean;
  toggled?: boolean;
  onToggle?(): unknown;
}

interface ToggleProps {
  disabled: boolean;
  toggled: boolean;
}

interface ContainerProps {
  disabled: boolean;
}

interface TextIndicatorProps {
  toggled: boolean;
  disabled: boolean;
}

const PercentageToggle: FC<React.PropsWithChildren<PercentageToggleProps>> = ({
  children,
  tooltipPosition = TooltipPosition.Bottom,
  tooltipMessage,
  disabled = false,
  toggled = false,
  onToggle,
}) => {
  const toggle = (
    <Toggle
      disabled={disabled}
      toggled={toggled}
      className={`qa-slider-toggle qa-slider-toggle-toggled-${toggled.toString()}`}
    >
      <TextIndicator toggled={toggled} disabled={disabled}>
        {toggled ? '%' : '$'}
      </TextIndicator>
      <Indicator toggled={toggled} disabled={disabled} />
    </Toggle>
  );

  return (
    <Container disabled={disabled} onClick={disabled ? undefined : onToggle}>
      {children}
      {!tooltipMessage ? (
        <Wrapper>{toggle}</Wrapper>
      ) : (
        <Tooltip maxWidth={300} position={tooltipPosition} content={tooltipMessage || ''} usePortal>
          <Wrapper>{toggle}</Wrapper>
        </Tooltip>
      )}
    </Container>
  );
};

export default PercentageToggle;
