import type { InvestmentVenncast } from 'venn-api';

const investments = [
  {
    fundId: '65ff2414-b3ef-47c2-8ccb-15445e78bfbd',
    fundName: 'S&P 500 Index',
    lastReturnDate: 1552953599999,
    ranges: [
      { period: 'WEEK', periodReturn: -0.001034112075130822, periodReturnError: 8.182666621982007e-4, estimated: true },
      { period: 'MONTH', periodReturn: 0.01573617582665232, periodReturnError: 0.0023177937307937956, estimated: true },
      {
        period: 'QUARTER',
        periodReturn: 0.11680811037348815,
        periodReturnError: 0.0023177937307937956,
        estimated: true,
      },
      { period: 'YEAR', periodReturn: 0.1161564604457499, periodReturnError: 0.0023177937307937956, estimated: true },
      { period: 'MTD', periodReturn: 0.01581719964239925, periodReturnError: 0.0015286256666680459, estimated: true },
      { period: 'QTD', periodReturn: 0.01581719964239925, periodReturnError: 0.0015286256666680459, estimated: true },
      { period: 'YTD', periodReturn: 0.15139536774119033, periodReturnError: 0.0023177937307937956, estimated: true },
    ],
    residualRisk: 0.008685755714106236,
  },
  {
    fundId: '2cbb3b8d-da88-4988-bbd3-5a05a8003fd4',
    fundName: 'S&P 1000 Index',
    lastReturnDate: 1552953599999,
    ranges: [
      {
        period: 'WEEK',
        periodReturn: -1.7741633008183655e-4,
        periodReturnError: 0.003720860342940123,
        estimated: true,
      },
      { period: 'MONTH', periodReturn: 0.009352655135101973, periodReturnError: 0.010539579790355573, estimated: true },
      {
        period: 'QUARTER',
        periodReturn: 0.09100797423262041,
        periodReturnError: 0.010539579790355573,
        estimated: true,
      },
      { period: 'YEAR', periodReturn: 0.028792016995256464, periodReturnError: 0.010539579790355573, estimated: true },
      { period: 'MTD', periodReturn: 0.021609493875367036, periodReturnError: 0.00695103795017845, estimated: true },
      { period: 'QTD', periodReturn: 0.021609493875367036, periodReturnError: 0.00695103795017845, estimated: true },
      { period: 'YTD', periodReturn: 0.15146336419497186, periodReturnError: 0.010539579790355573, estimated: true },
    ],
    residualRisk: 0.0592250472903727,
  },
];

export default investments as unknown as InvestmentVenncast[];
