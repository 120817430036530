import { AuthButton, AuthLoader, AuthLogo, ContentWrapper, Info, StyledHeadline1 } from '../components';
import { Icon, Label, Link as LinkStyle, Subtitle1 } from 'venn-ui-kit';
import { AuthenticatorDetails, FormInput, useEnableAuthenticator } from 'venn-components';
import React from 'react';
import styled from 'styled-components';

interface EnableAuthenticatorProps {
  onChooseADifferentMethod: () => void;
  onComplete: () => void;
}

const VERIFICATION_INPUT_ID = 'verification-code';

const EnableAuthenticator = ({ onChooseADifferentMethod, onComplete }: EnableAuthenticatorProps) => {
  const { authenticatorInfo, code, onVerificationCodeChange, onSubmit, loading, error } = useEnableAuthenticator({
    onComplete,
  });

  return (
    <ContentWrapper>
      <AuthLogo />
      <Info>
        <StyledHeadline1>Enable Google Authenticator</StyledHeadline1>
        <Subtitle1>
          Download the Google Authenticator application from the App Store or Google Play Store and scan the code below
          with your smartphone.
        </Subtitle1>
      </Info>
      {loading ? (
        <AuthLoader />
      ) : (
        <StyledForm onSubmit={onSubmit}>
          <>
            <AuthenticatorDetails
              showManualEntry
              authenticatorInfo={authenticatorInfo}
              loading={loading}
              toggleManualEntry={undefined}
              colorInverted
            />
            <div className="form-field">
              <StyledLabel inverted htmlFor={VERIFICATION_INPUT_ID}>
                Enter the pin code shown on the Authenticator on your smartphone:
              </StyledLabel>
              <FormInput
                required
                label="Authentication code"
                value={code}
                onChange={onVerificationCodeChange}
                error={error}
                inputId={VERIFICATION_INPUT_ID}
              />
            </div>
            <AuthButtonContainer>
              <AuthButton dominant type="submit" disabled={!code}>
                Next
              </AuthButton>
            </AuthButtonContainer>
            <BackLink inverted onClick={onChooseADifferentMethod}>
              <Icon type="chevron-left" />
              &nbsp; Choose a different method
            </BackLink>
          </>
        </StyledForm>
      )}
    </ContentWrapper>
  );
};

export default EnableAuthenticator;

const AuthButtonContainer = styled.div``;

const StyledForm = styled.form`
  margin-top: 30px;
  max-width: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const BackLink = styled(LinkStyle)`
  display: flex;
  justify-content: center;
`;

const StyledLabel = styled(Label)`
  display: block;
  margin-top: 10px;
  margin-bottom: 5px;
`;
