import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import type { ThemeProvider } from 'venn-ui-kit';
import { ColorUtils, GetColor, GetTypography, Label, ZIndex } from 'venn-ui-kit';
import type { PrintOrientationTypeEnum } from 'venn-api';
import { StatefulInput, ALLOCATOR_MAIN_WIDTH, ALLOCATOR_TOTAL_WIDTH } from 'venn-components';
import { TOP_TOOLBAR_HEIGHT } from './top-bar/shared';
import { DEFAULT_LANDSCAPE_WIDTH, DEFAULT_PORTRAIT_WIDTH } from 'venn-state';
import { compact } from 'lodash';

export const RedistributionWarnMessage = ({ showLink }: { showLink: boolean }) => (
  <span>
    This report contains data that requires an external use license. PDF exports will be limited to “Draft” mode.
    {showLink && (
      <NotificationLink to={{ pathname: REDISTRIBUTION_HELP_LINK }} target="_blank">
        &nbsp;Learn More.
      </NotificationLink>
    )}
  </span>
);
const REDISTRIBUTION_HELP_LINK = 'https://help.venn.twosigma.com/en/articles/7888093-restricted-data-in-report-lab';

export const RedistributionCorrectMessage = () => (
  <span>
    All data that requires an external use license has been removed. PDFs can be exported in either “Draft” of “Final”
    mode.
    <NotificationLink to={{ pathname: REDISTRIBUTION_HELP_LINK }} target="_blank">
      &nbsp;Learn More.
    </NotificationLink>
  </span>
);

export const StyledLabel = styled(Label)`
  line-height: 1.3;
`;

export const PanelLabel = styled(Label)`
  line-height: 1.2;
  margin-bottom: 8px;
  display: inline-block;
`;

export const Flex = styled.div`
  display: flex;
  column-gap: 5px;
  align-items: center;
`;

export const FlexHeader = styled(Flex)`
  font-weight: bold;
  font-size: 14px;
  margin: 10px 0 10px;
`;

const MAIN_LEFT_PANEL_WIDTH = 300;
const LEFT_BLOCK_CONFIG_PANEL_WIDTH = 311;
const COLLAPSED_PANEL_WIDTH = 30;
const ALLOCATOR_MARGIN = 8;

export const PageContent = styled.div<{
  isMainLeftPanelOpen: boolean;
  isAllocatorOpen: boolean;
  isCompareOpen: boolean;
  isLeftBlockConfigPanelOpen: boolean;
  isLeftBlockConfigPanelExpanded: boolean;
  inPrintMode?: boolean;
  orientation?: PrintOrientationTypeEnum;
}>`
  width: 100%;
  height: calc(100vh - 50px);
  display: grid;
  display: -ms-grid;
  -ms-grid-rows: ${TOP_TOOLBAR_HEIGHT} 1fr;
  grid-template-rows: max-content 1fr;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  grid-template-areas:
    'topbar'
    'content';

  ${({
    isMainLeftPanelOpen,
    isAllocatorOpen,
    isCompareOpen,
    isLeftBlockConfigPanelOpen,
    isLeftBlockConfigPanelExpanded,
  }) => {
    const mainLeftPanelWidth = `${isMainLeftPanelOpen ? MAIN_LEFT_PANEL_WIDTH : COLLAPSED_PANEL_WIDTH}px`;
    const allocatorWidth = isAllocatorOpen
      ? `${ALLOCATOR_MARGIN + (isCompareOpen ? ALLOCATOR_TOTAL_WIDTH : ALLOCATOR_MAIN_WIDTH)}px`
      : '';
    const blockConfigPanelWidth = isLeftBlockConfigPanelOpen
      ? `${isLeftBlockConfigPanelExpanded ? LEFT_BLOCK_CONFIG_PANEL_WIDTH : COLLAPSED_PANEL_WIDTH + 7}px`
      : '';

    const topbarCount =
      /* main left panel */
      1 +
      Number(isAllocatorOpen) +
      Number(isLeftBlockConfigPanelOpen) +
      /* content */
      1;
    const topbarAreas = Array.from({ length: topbarCount }, () => 'topbar').join(' ');
    const templateAreas = compact([
      'leftsidebar',
      isAllocatorOpen && 'allocator',
      isLeftBlockConfigPanelOpen && 'blockConfigPanel',
      'content',
    ]).join(' ');

    return css`
      -ms-grid-columns: ${mainLeftPanelWidth} ${allocatorWidth} ${blockConfigPanelWidth} auto;
      grid-template-columns: ${mainLeftPanelWidth} ${allocatorWidth} ${blockConfigPanelWidth} auto;
      grid-template-areas: '${topbarAreas}' '${templateAreas}';
    `;
  }}

  ${(props) =>
    props.inPrintMode &&
    css`
      && {
        height: auto;
        display: block;
      }
    `}

    ${(props) =>
    props.orientation !== 'PORTRAIT' &&
    props.inPrintMode &&
    css`
      && {
        @media screen {
          width: ${DEFAULT_LANDSCAPE_WIDTH}px;
        }
      }
    `}
    
    ${(props) =>
    props.orientation === 'PORTRAIT' &&
    props.inPrintMode &&
    css`
      && {
        @media screen {
          width: ${DEFAULT_PORTRAIT_WIDTH}px;
        }
      }
    `} 
    
    @media print {
    display: block;
    width: 100%;
    margin: 0px;
    padding: 0px;
  }
`;

export const TopControl = styled.div`
  grid-area: topbar;
  -ms-grid-column: 1;
  -ms-grid-row: 1;
  min-height: ${TOP_TOOLBAR_HEIGHT};
`;

export const ContentStyle = css`
  font-family: ${GetTypography.fontFamily};
  grid-area: content;
  -ms-grid-column: 2;
  -ms-grid-row: 2;
  background-color: ${ColorUtils.hex2rgbaFrom(GetColor.WhiteGrey, 0.5)};

  .highcharts-root {
    font-family: ${(props) => props.theme.Typography.fontFamily} !important;
  }

  @media print {
    background-color: transparent;
  }
`;
export const Content = styled.div`
  ${ContentStyle}
`;

export const ContentCover = styled(Content)`
  background-color: ${ColorUtils.hex2rgbaFrom(GetColor.White, 0.85)};
  height: 100%;

  display: flex;
  align-items: center;
  padding: 0 200px;
  z-index: ${ZIndex.StickyCover};

  > div {
    text-align: center;
  }
`;

export const LeftPanel = styled.div<{ unSeenPanel?: boolean; studioV1?: boolean }>`
  grid-area: leftsidebar;
  -ms-grid-column: 1;
  -ms-grid-row: 1;
  height: calc(100vh - ${TOP_TOOLBAR_HEIGHT} - 50px);
`;

export const Panel = styled.div<{ unSeenPanel?: boolean }>`
  grid-area: sidebar;
  -ms-grid-column: 3;
  -ms-grid-row: 2;
  height: calc(100vh - ${TOP_TOOLBAR_HEIGHT} - 50px);
  z-index: ${(props) => (props.unSeenPanel ? ZIndex.Background : ZIndex.Base)};
  position: relative;
`;

export const Section = styled.div<{ noBorder?: boolean; disabled?: boolean }>`
  padding: 10px 10px 20px;
  ${({ noBorder }) =>
    !noBorder &&
    css`
      border-bottom: 1px solid ${GetColor.Grey};
    `};

  ${({ disabled }) => disabled && 'opacity: 0.5;'}
`;

export const BorderBottom = styled.div`
  padding: 0 15px 0 20px;
  border-bottom: 1px solid ${GetColor.GreyScale.Grey30};
`;

export const GapWrapper = styled.div`
  margin-top: 20px;
`;

export const Text = styled.div`
  font-size: 12px;
  color: ${GetColor.GreyScale.Grey70};
`;

export const TextInline = styled(Text)`
  display: inline-block;
`;

export const MAX_WIDTH = 1;
export const MIN_WIDTH = 0.5;
export const UNIT_WIDTH = 0.5;

export const CUSTOM_FOOTER_MAX_LENGTH = 150;

export const InputField = styled(StatefulInput)`
  display: flex;

  & > span {
    font-size: 12px;
    left: 0;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${GetColor.Grey};
    `}
`;

export const ImgWrapper = styled.div`
  display: inline-block;
  height: 20px;
  overflow: hidden;
  margin-left: 10px;
`;

export const Img = styled.img`
  height: 100%;
  max-width: auto;
`;

export const Message = styled.div<{ colorOverride?: ThemeProvider }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 15px;
  font-size: 10px;
  border-color: ${GetColor.GreyScale.Grey30};
  margin-bottom: 10px;
  background: ${(props) =>
    props.colorOverride
      ? props.colorOverride(props)
      : props.colorOverride || ColorUtils.opacifyFrom(GetColor.HighlightLight, 0.1)};
  column-gap: 10px;
  margin-top: 3px;

  i {
    color: ${GetColor.HighlightDark};
    font-size: 14px;
  }

  button {
    color: ${GetColor.HighlightDark};
    min-height: auto;
    min-width: auto;
    padding: 0;
  }
`;

export const UploadImageText = styled.div<{ disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: center;

  height: 24px;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: ${({ disabled }) => (disabled ? GetColor.DarkGrey : GetColor.Primary.Dark)};
`;
export const PlusIcon = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;

  width: 24px;
  height: 24px;
  font-weight: bold;
  font-size: 14px;
  margin-right: 5px;

  border: 1px solid ${GetColor.Primary.Dark};
  box-sizing: border-box;
  border-radius: 2px;
`;

const NotificationLink = styled(Link)`
  color: ${GetColor.White};
  font-weight: bold;
`;
