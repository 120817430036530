import React from 'react';
import type { DataRangeInfo } from './utils';
import { CellLoader } from 'venn-ui-kit';
import { capitalizeFirstLetter, getFormattedFrequency } from 'venn-utils';

interface DataFrequencyProps {
  dataRangeInfo?: DataRangeInfo;
}

const DataFrequency = ({ dataRangeInfo }: DataFrequencyProps) => {
  return (
    <>
      {!dataRangeInfo ? (
        <CellLoader />
      ) : dataRangeInfo.frequency ? (
        capitalizeFirstLetter(getFormattedFrequency(dataRangeInfo.frequency))
      ) : (
        'Not Available'
      )}
    </>
  );
};

export default DataFrequency;
