import React, { useCallback, useContext, useMemo } from 'react';
import type { DropMenuCheckboxItem, TooltipPosition } from 'venn-ui-kit';
import type { SearchResultWithUIState, SearchState } from './types';
import { isNil } from 'lodash';
import { TagDropdown, TagsContext, UserContext } from 'venn-components';

interface TagSelectorProps {
  updateData: React.Dispatch<React.SetStateAction<SearchState>>;
  searchItems: SearchResultWithUIState[];
  onClose?: () => void;
  onOpen?: () => void;
  dark?: boolean;
  solid?: boolean;
  border?: boolean;
  borderSize?: number;
  iconSize?: number;
  tooltip?: React.ReactNode;
  tooltipPosition?: TooltipPosition;
  className?: string;
  triggerText?: string;
}

const TagSelector = ({
  onClose,
  onOpen,
  dark,
  updateData,
  searchItems,
  iconSize,
  solid,
  border,
  borderSize,
  tooltip,
  tooltipPosition,
  className,
  triggerText,
}: TagSelectorProps) => {
  const { hasPermission } = useContext(UserContext);
  const { tags: contextTags } = useContext(TagsContext);
  const isReadOnly = !hasPermission('MANAGE_TAGS');

  const tags = useMemo(
    () => new Set(contextTags.filter((t) => searchItems.every((item) => item.tags?.includes(t)))),
    [contextTags, searchItems],
  );
  const indeterminateTags = useMemo(
    () =>
      new Set(
        searchItems.length > 1
          ? contextTags.filter((t) => !tags.has(t) && searchItems.some((item) => item.tags?.includes(t)))
          : [],
      ),
    [contextTags, searchItems, tags],
  );
  const itemPortfolioIds = useMemo(
    () => new Set(searchItems.map((i) => i.portfolioId).filter((id) => !isNil(id))),
    [searchItems],
  );
  const itemFundIds = useMemo(
    () => new Set(searchItems.map((i) => i.fundId).filter((id) => !isNil(id))),
    [searchItems],
  );

  const onChange = useCallback(
    (changedItems: DropMenuCheckboxItem<string>[]) => {
      const checkedTags = changedItems.filter((i) => i.checked).map((i) => i.value);
      const uncheckedTags = changedItems.filter((i) => !i.checked && !i.indeterminate).map((i) => i.value);
      updateData((prev) => ({
        ...prev,
        results: prev.results.map((dataItem) => {
          if (!itemPortfolioIds.has(dataItem.portfolioId) && !itemFundIds.has(dataItem.fundId)) {
            return dataItem;
          }

          const remainingTags =
            dataItem.tags?.filter((t) => !uncheckedTags.includes(t) && !checkedTags.includes(t)) ?? [];
          return {
            ...dataItem,
            tags: [...remainingTags, ...checkedTags],
          };
        }),
      }));
    },
    [updateData, itemFundIds, itemPortfolioIds],
  );

  return (
    <TagDropdown
      itemsToTag={searchItems}
      onChange={onChange}
      onClose={onClose}
      onOpen={onOpen}
      dark={dark}
      solid={solid}
      iconSize={iconSize}
      border={border}
      borderSize={borderSize}
      tooltip={tooltip}
      tooltipPosition={tooltipPosition}
      currentTags={tags}
      currentIndeterminateTags={indeterminateTags}
      className={className}
      triggerText={triggerText}
      isReadOnly={isReadOnly}
    />
  );
};

export default TagSelector;
