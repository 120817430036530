import React from 'react';
import type { FrequencyEnum } from 'venn-api';
import { EmptyStateContent, FloatRight, Frequency, ScreenOnly } from '../components/styled';
import {
  mapRangeToConstrainingInvestments,
  getRangeStartAndEnd,
  getFormattedPeriod,
  isPeriodSet,
  getPeriodFormatterForInsufficientReturns,
} from '../PortfolioRangesUtils';
import type { AnalysisSubject, TimeFrame } from 'venn-utils';
import { Dates, fundLengthInMonths, isLessThanOneFactorTrendDataPoint } from 'venn-utils';
import type { RangeDebugGroup } from '../../../hooks/useRangesDebug';
import ErrorInvalidAnalysisPeriod from '../shared-errors/ErrorInvalidAnalysisPeriod';
import { Icon, Link as LinkStyle } from 'venn-ui-kit';
import RangeItemsList from '../components/RangeItemsList';
import moment from 'moment';
import { ManagePortfolioDataTrigger } from '../../../manage-data';

interface RangeMetadata {
  availableStart: number;
  availableEnd: number;
  frequency: FrequencyEnum;
  requiredMonths: number;
  analysisMonths: number;
  requiredLabel: string;
}

interface ErrorInsufficientReturnsProps {
  defaultMessage: string | null;
  rangeMetadata: RangeMetadata;
  subject: AnalysisSubject;
  regressionName: string;
  onResetAnalysisPeriod?: () => void;
  actualTimeFrame: TimeFrame;
  rangeDebugGroup?: RangeDebugGroup;
  isFactorTrend?: boolean;
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default ({
  subject,
  rangeDebugGroup,
  onResetAnalysisPeriod,
  defaultMessage,
  rangeMetadata,
  regressionName,
  actualTimeFrame,
  isFactorTrend,
}: ErrorInsufficientReturnsProps) => {
  const { availableStart, availableEnd, frequency, requiredMonths, requiredLabel } = rangeMetadata;
  const noOverlap = isPeriodSet(availableStart, availableEnd) && availableStart > availableEnd;

  const portfolioRange = rangeDebugGroup?.primary?.response?.portfolioRange;

  if (!portfolioRange) {
    return <EmptyStateContent>{defaultMessage}</EmptyStateContent>;
  }

  const actualStart = actualTimeFrame.startTime ?? availableStart;
  const actualEnd = actualTimeFrame.endTime ?? availableEnd;

  const formatDateRange = getPeriodFormatterForInsufficientReturns(frequency, availableStart, availableEnd, noOverlap);
  const availableRange = formatDateRange(availableStart, availableEnd);

  if (
    isPeriodSet(availableStart, availableEnd, isFactorTrend) &&
    ((isFactorTrend ? false : actualStart !== availableStart) || actualEnd !== availableEnd)
  ) {
    return (
      <ErrorInvalidAnalysisPeriod
        defaultMessage={getMessage(
          requiredLabel,
          frequency,
          availableRange,
          availableStart,
          availableEnd,
          actualStart,
          actualEnd,
          isFactorTrend,
        )}
        onResetAnalysisPeriod={onResetAnalysisPeriod}
      />
    );
  }

  const investments = mapRangeToConstrainingInvestments(portfolioRange);

  const itemsWithInsufficientReturns = investments.filter(
    ({ range }) => fundLengthInMonths(frequency, range.start, range.end) < requiredMonths,
  );
  const benchmarkInsufficient = itemsWithInsufficientReturns.find(({ type }) => type === 'Benchmark');
  const comparisonInsufficient = itemsWithInsufficientReturns.find(({ type }) => type === 'Comparison');
  const investmentsInsufficient = itemsWithInsufficientReturns.filter(({ type }) => !type);

  const [maxStart, minEnd] = getRangeStartAndEnd(portfolioRange);
  const constrainingInvestments = investments.filter(
    ({ type, range: { start, end } }) =>
      type === undefined &&
      (noOverlap ? start > minEnd || end < maxStart : start === availableStart || end === availableEnd),
  );
  const benchmarkConstraining =
    portfolioRange.benchmark &&
    (noOverlap
      ? portfolioRange.benchmark.start > minEnd || portfolioRange.benchmark.end < maxStart
      : portfolioRange.benchmark.start === availableStart || portfolioRange.benchmark.end === availableEnd);
  const secondaryConstraining =
    portfolioRange.secondary &&
    (noOverlap
      ? portfolioRange.secondary.start > minEnd || portfolioRange.secondary.end < maxStart
      : portfolioRange.secondary.start <= availableStart || portfolioRange.secondary.end >= availableEnd);

  return (
    <EmptyStateContent style={{ maxWidth: '100%' }}>
      {!noOverlap && (
        <>
          <strong>{regressionName}</strong> requires at least{' '}
          <strong>
            <i>{requiredLabel}</i>
          </strong>{' '}
          of return history for <Frequency>{frequency}</Frequency> analysis.{' '}
        </>
      )}
      {noOverlap ? (
        <>
          Currently, the return streams of the items you're analyzing have <strong>no common overlap</strong>.
        </>
      ) : (
        <>
          Currently, the return streams of the items you're analyzing overlap over{' '}
          <strong>
            <i>{getFormattedPeriod(frequency, availableStart, availableEnd)}</i>
          </strong>{' '}
          (<strong>{availableRange}</strong>).
        </>
      )}
      <ScreenOnly>
        <div style={{ marginTop: 20 }}>
          {itemsWithInsufficientReturns.length > 0 ? (
            <>
              The following items have insufficient returns for a <Frequency>{frequency}</Frequency> analysis:
              <RangeItemsList
                subject={subject}
                portfolioRange={portfolioRange}
                includeBenchmark={!!benchmarkInsufficient}
                includeSecondary={!!comparisonInsufficient}
                infoType="returns"
                investments={investmentsInsufficient}
                availableStart={availableStart}
                availableEnd={availableEnd}
              />
            </>
          ) : (
            <>
              The following items are constraining your analysis period:
              <RangeItemsList
                subject={subject}
                portfolioRange={portfolioRange}
                includeBenchmark={benchmarkConstraining}
                includeSecondary={secondaryConstraining}
                infoType="constraining"
                investments={constrainingInvestments}
                availableStart={availableStart}
                availableEnd={availableEnd}
              />
            </>
          )}
        </div>
        <ManagePortfolioDataTrigger hidden={subject.type !== 'portfolio'}>
          {(openManageDataPage) => (
            <FloatRight>
              <LinkStyle>
                <button type="button" onClick={openManageDataPage}>
                  <Icon type="question-circle" /> How is my available analysis period calculated?
                </button>
              </LinkStyle>
            </FloatRight>
          )}
        </ManagePortfolioDataTrigger>
      </ScreenOnly>
    </EmptyStateContent>
  );
};

function formatDate(date: number, frequency: FrequencyEnum): string {
  return Dates.toDDMMMYYYY(moment.utc(date).valueOf(), frequency);
}

function getMessage(
  requiredReturnsPeriodString: string,
  frequency: FrequencyEnum,
  availableRange: string,
  availableStart: number,
  availableEnd: number,
  actualStart: number,
  actualEnd: number,
  isFactorTrend?: boolean,
): string {
  const resetAnalysisCTA = 'Reset analysis period to run analysis over full history.';

  if (!isFactorTrend) {
    return `Currently selected analysis period doesn\'t overlap with the analysis available time frame (${availableRange}) over the required minumum of ${requiredReturnsPeriodString}. ${resetAnalysisCTA}`;
  }

  if (isLessThanOneFactorTrendDataPoint(actualStart, actualEnd, frequency)) {
    return `Currently selected analysis period is too short to display Factor Trend (at least 1 full month is required). ${resetAnalysisCTA}`;
  }

  return `Factor Trend requires at least ${requiredReturnsPeriodString} of returns between the available time frame start (${formatDate(
    availableStart,
    frequency,
  )}) and selected analysis period end (currently set to ${formatDate(
    actualEnd,
    frequency,
  )} and constraining your Factor Trend window to ${getFormattedPeriod(
    frequency,
    availableStart,
    actualEnd,
  )}). ${resetAnalysisCTA}`;
}
