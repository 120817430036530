import React from 'react';
import styled, { css } from 'styled-components';
import { GetColor, Icon } from 'venn-ui-kit';
import type { FeatureRowDescription } from './content';
import { accessFeatures, integrationsFeatures, onboardingFeatures, supportFeatures, tiers } from './content';

const HeaderRow = () => {
  return (
    <HeaderRowContainer>
      {tiers.map((tier) => {
        return (
          <HeaderCell key={tier.text ?? 'empty-col'} {...tier}>
            {tier.text}
          </HeaderCell>
        );
      })}
    </HeaderRowContainer>
  );
};

type CellIconProps = {
  checked: boolean;
};

const CellIcon = ({ checked }: CellIconProps) => {
  return (
    <TableCell>
      {checked && <StyledIcon type="fa-solid fa-lg fa-circle-check" />}
      {!checked && <Icon type="minus" />}
    </TableCell>
  );
};

type SectionProps = {
  title: string;
  features: FeatureRowDescription[];
};
const TitleContainer = styled.div`
  margin: 30px 0px 14px 0px;
  color: ${GetColor.Black};
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
`;

const Section = ({ title, features }: SectionProps) => {
  return (
    <SectionContainer>
      <TitleContainer>{title}</TitleContainer>
      {features.map((feature) => (
        <FeatureRow {...feature} key={feature.text} />
      ))}
    </SectionContainer>
  );
};
const FeatureRow = ({ text, limited, standard, premium }: FeatureRowDescription) => {
  return (
    <FeatureRowContainer>
      <FirstCell>{text}</FirstCell>
      <CellIcon checked={limited} />
      <CellIcon checked={standard} />
      <CellIcon checked={premium} />
    </FeatureRowContainer>
  );
};
const ServiceTiersTable = () => {
  return (
    <Container>
      <HeaderRow />
      <Section title="Onboarding" features={onboardingFeatures} />
      <Horizontal />
      <Section title="Ongoing Support" features={supportFeatures} />
      <Horizontal />
      <Section title="Access Included" features={accessFeatures} />
      <Horizontal />
      <Section title="Integrations" features={integrationsFeatures} />
    </Container>
  );
};

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledIcon = styled(Icon)`
  color: ${GetColor.HighlightDark};
`;

const Horizontal = styled.hr`
  border: 1px solid ${GetColor.PaleGrey};
  margin-top: 20px;
`;

const FeatureRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-content: stretch;
  height: 22px;
  font-size: 13px;
  margin: 2px;
  color: ${GetColor.GreyScale.Grey80};
`;

const TableCell = styled.div<{ fontSize?: number; highlight?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  flex: 1;
  ${(props) => props.fontSize && `font-size: ${props.fontSize}px;`}
`;

const HeaderCell = styled(TableCell)<{ fontSize?: number; highlight?: boolean }>`
  height: 78px;
  ${({ highlight }) =>
    highlight
      ? css`
          border-bottom: 4px solid ${GetColor.HighlightDark};
          font-weight: bold;
        `
      : css`
          border-bottom: 2px solid ${GetColor.PaleGrey};
          font-weight: normal;
        `}
`;

const FirstCell = styled(TableCell)`
  justify-content: flex-start;
`;
const HeaderRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-content: stretch;
`;

const Container = styled.div`
  width: 100%;
  height: 850px;
  border: 1px solid ${GetColor.PaleGrey};
  background-color: ${GetColor.White};
  padding: 0px 88px 0px 44px;
`;

export default ServiceTiersTable;
