export const mockAttribution = {
  attributions: {
    historicalContribution: {
      subject: {
        volatility: {
          funds: [
            {
              name: 'Vanguard Materials ETF',
              weight: 0.3333333333333333,
              value: 0.2000879223541718,
              contributionValue: 0.06075348297656446,
              contribution: 0.6075197270263739,
            },
            {
              name: 'Other Investment Category (HF)',
              weight: 0.3333333333333333,
              value: 0.05469774249277374,
              contributionValue: 0.014037469753414189,
              contribution: 0.14037120795238758,
            },
            {
              name: 'Managed Futures Investment Category (MF)',
              weight: 0.3333333333333333,
              value: 0.1077284505651488,
              contributionValue: 0.025211533236912435,
              contribution: 0.25210906502123853,
            },
          ],
          strategies: [
            {
              name: 'New strategy',
              weight: 0.3333333333333333,
              value: 0.2000879223541718,
              contributionValue: 0.06073222668925187,
              contribution: 0.6073071694373593,
            },
            {
              name: 'Uncategorized',
              weight: 0.6666666666666666,
              value: 0.07062498970860427,
              contributionValue: 0.03927025927763922,
              contribution: 0.39269283056264076,
            },
          ],
        },
        maxDrawdown: {
          funds: [
            {
              name: 'Vanguard Materials ETF',
              weight: 0.3333333333333333,
              value: -0.5821124819688608,
              contributionValue: -0.20849014070172409,
              contribution: 0.6723896166461931,
            },
            {
              name: 'Other Investment Category (HF)',
              weight: 0.3333333333333333,
              value: -0.16233513440940706,
              contributionValue: -0.057451085298891265,
              contribution: 0.18528220610342652,
            },
            {
              name: 'Managed Futures Investment Category (MF)',
              weight: 0.3333333333333333,
              value: -0.11440383367836304,
              contributionValue: -0.04413218313626343,
              contribution: 0.1423281772503805,
            },
          ],
          strategies: [
            {
              name: 'New strategy',
              weight: 0.3333333333333333,
              value: -0.5821124819688608,
              contributionValue: -0.20851838332974337,
              contribution: 0.672480700328918,
            },
            {
              name: 'Uncategorized',
              weight: 0.6666666666666666,
              value: -0.13828723747713056,
              contributionValue: -0.1015550258071354,
              contribution: 0.32751929967108195,
            },
          ],
        },
        sharpe: {
          funds: [
            {
              name: 'Vanguard Materials ETF',
              weight: 0.3333333333333333,
              value: 0.39434854900620464,
              contributionValue: 0.2592944165557425,
              contribution: 0.5332235818566559,
            },
            {
              name: 'Other Investment Category (HF)',
              weight: 0.3333333333333333,
              value: 0.8245424793235773,
              contributionValue: 0.14820896703196895,
              contribution: 0.30478294640437104,
            },
            {
              name: 'Managed Futures Investment Category (MF)',
              weight: 0.3333333333333333,
              value: 0.22251478526313864,
              contributionValue: 0.07877371550999396,
              contribution: 0.1619934717389731,
            },
          ],
          strategies: [
            {
              name: 'New strategy',
              weight: 0.3333333333333333,
              value: 0.39434854900620464,
              contributionValue: 0.2601907318039518,
              contribution: 0.5350668009798111,
            },
            {
              name: 'Uncategorized',
              weight: 0.6666666666666666,
              value: 0.4853949814747178,
              contributionValue: 0.22608636729375356,
              contribution: 0.46493319902018887,
            },
          ],
        },
        periodReturn: {
          funds: [
            {
              name: 'Vanguard Materials ETF',
              weight: 0.3333333333333333,
              value: 0.07423728786938089,
              contributionValue: 0.026878015902983443,
              contribution: 0.4610016288892235,
            },
            {
              name: 'Other Investment Category (HF)',
              weight: 0.3333333333333333,
              value: 0.05828441581597987,
              contributionValue: 0.01992080897509208,
              contribution: 0.3416742299527082,
            },
            {
              name: 'Managed Futures Investment Category (MF)',
              weight: 0.3333333333333333,
              value: 0.031859540771733696,
              contributionValue: 0.011504691245599817,
              contribution: 0.19732414115806815,
            },
          ],
          strategies: [
            {
              name: 'New strategy',
              weight: 0.3333333333333333,
              value: 0.07423728786938089,
              contributionValue: 0.026530192316366646,
              contribution: 0.45503588943229295,
            },
            {
              name: 'Uncategorized',
              weight: 0.6666666666666666,
              value: 0.04592923202705501,
              contributionValue: 0.0317733238073087,
              contribution: 0.544964110567707,
            },
          ],
        },
        betaToBenchmark: {
          funds: [
            {
              name: 'Vanguard Materials ETF',
              weight: 0.3333333333333333,
              value: 2.4208693794742424,
              contributionValue: 0.7981895669413651,
              contribution: 0.5619787633079739,
            },
            {
              name: 'Other Investment Category (HF)',
              weight: 0.3333333333333333,
              value: 1,
              contributionValue: 0.32971195129689884,
              contribution: 0.2321392339763589,
            },
            {
              name: 'Managed Futures Investment Category (MF)',
              weight: 0.3333333333333333,
              value: 0.8868901615167484,
              contributionValue: 0.2924182857397089,
              contribution: 0.2058820027156672,
            },
          ],
          strategies: [
            {
              name: 'New strategy',
              weight: 0.3333333333333333,
              value: 2.4208693794742424,
              contributionValue: 0.7981418227721321,
              contribution: 0.5619451482241743,
            },
            {
              name: 'Uncategorized',
              weight: 0.6666666666666666,
              value: 0.9435739240257435,
              contributionValue: 0.6221779812058407,
              contribution: 0.4380548517758257,
            },
          ],
        },
        periodAnnualized: true,
        analysisStart: 1078099199999,
        analysisEnd: 1583020799999,
      },
    },
    forecastContribution: {
      subject: {
        volatility: {
          funds: [
            {
              name: 'Vanguard Materials ETF',
              weight: 0.3333333333333333,
              value: 0.20305612083411803,
              contributionValue: 0.06303405108276965,
              contribution: 0.7302280132335586,
            },
            {
              name: 'Managed Futures Investment Category (MF)',
              weight: 0.3333333333333333,
              value: 0.0793820543010343,
              contributionValue: 0.013681265906494868,
              contribution: 0.15849280586934472,
            },
            {
              name: 'Other Investment Category (HF)',
              weight: 0.3333333333333333,
              value: 0.04210331413060686,
              contributionValue: 0.009605736079687833,
              contribution: 0.1112791808970967,
            },
          ],
          strategies: [
            {
              name: 'New strategy',
              weight: 0.3333333333333333,
              value: 0.20305612083411803,
              contributionValue: 0.06303405108276965,
              contribution: 0.7302280132335586,
            },
            {
              name: 'Uncategorized',
              weight: 0.6666666666666666,
              value: 0.05087416829441369,
              contributionValue: 0.023287001986182703,
              contribution: 0.26977198676644143,
            },
          ],
        },
        sharpe: {
          funds: [
            {
              name: 'Vanguard Materials ETF',
              weight: 0.3333333333333333,
              value: 0.29718791667896666,
              contributionValue: 0.23302861188606538,
              contribution: 0.909702596500741,
            },
            {
              name: 'Managed Futures Investment Category (MF)',
              weight: 0.3333333333333333,
              value: 0.0989149201846223,
              contributionValue: 0.030321183867725407,
              contribution: 0.11836855341580312,
            },
            {
              name: 'Other Investment Category (HF)',
              weight: 0.3333333333333333,
              value: -0.04422743161863524,
              contributionValue: -0.007190680915125423,
              contribution: -0.028071149916544143,
            },
          ],
          strategies: [
            {
              name: 'New strategy',
              weight: 0.3333333333333333,
              value: 0.29718791667896666,
              contributionValue: 0.23302861188606538,
              contribution: 0.909702596500741,
            },
            {
              name: 'Uncategorized',
              weight: 0.6666666666666666,
              value: 0.058870231391149545,
              contributionValue: 0.023130502952600013,
              contribution: 0.09029740349925909,
            },
          ],
        },
        periodReturn: {
          funds: [
            {
              name: 'Vanguard Materials ETF',
              weight: 0.3333333333333333,
              value: 0.06836187313865168,
              contributionValue: 0.022787291046217226,
              contribution: 0.7563499767365591,
            },
            {
              name: 'Managed Futures Investment Category (MF)',
              weight: 0.3333333333333333,
              value: 0.01586811718432578,
              contributionValue: 0.00528937239477526,
              contribution: 0.17556350509699498,
            },
            {
              name: 'Other Investment Category (HF)',
              weight: 0.3333333333333333,
              value: 0.006153926172418287,
              contributionValue: 0.002051308724139429,
              contribution: 0.06808651816644584,
            },
          ],
          strategies: [
            {
              name: 'New strategy',
              weight: 0.3333333333333333,
              value: 0.06836187313865168,
              contributionValue: 0.022787291046217226,
              contribution: 0.7563499767365591,
            },
            {
              name: 'Uncategorized',
              weight: 0.6666666666666666,
              value: 0.011011021678372038,
              contributionValue: 0.007340681118914692,
              contribution: 0.24365002326344096,
            },
          ],
        },
      },
    },
  },
};

export const mockRelativeAttribution = {
  attributions: {
    historicalContribution: {
      subject: {
        trackingError: {
          funds: [
            {
              name: 'Managed Futures Investment Category (MF)',
              weight: 1,
              value: 0.11596938672752345,
              contributionValue: 0.11596938672752345,
              contribution: 1,
            },
          ],
          strategies: [
            {
              name: 'Managed Futures Investment Category (MF)',
              weight: 1,
              value: 0.11596938672752345,
              contributionValue: 0.11596938672752345,
              contribution: 1,
            },
          ],
        },
        maxUnderperformance: {
          funds: [
            {
              name: 'Managed Futures Investment Category (MF)',
              weight: 1,
              value: -0.5253882430944157,
              contributionValue: -0.5253882430944157,
              contribution: 1,
            },
          ],
          strategies: [
            {
              name: 'Managed Futures Investment Category (MF)',
              weight: 1,
              value: -0.5253882430944157,
              contributionValue: -0.5253882430944157,
              contribution: 1,
            },
          ],
        },
        informationRatio: {
          funds: [
            {
              name: 'Managed Futures Investment Category (MF)',
              weight: 1,
              value: -0.10353353630472627,
              contributionValue: -0.10353353630472625,
              contribution: 1,
            },
          ],
          strategies: [
            {
              name: 'Managed Futures Investment Category (MF)',
              weight: 1,
              value: -0.10353353630472627,
              contributionValue: -0.10353353630472625,
              contribution: 1,
            },
          ],
        },
        periodExcessReturn: {
          funds: [
            {
              name: 'Managed Futures Investment Category (MF)',
              weight: 1,
              value: -0.019211275671483286,
              contributionValue: -0.019211275671483286,
              contribution: 1,
            },
          ],
          strategies: [
            {
              name: 'Managed Futures Investment Category (MF)',
              weight: 1,
              value: -0.019211275671483286,
              contributionValue: -0.019211275671483286,
              contribution: 1,
            },
          ],
        },
        periodAnnualized: true,
        analysisStart: 796694399999,
        analysisEnd: 1583020799999,
      },
      benchmark: null,
    },
    forecastContribution: {
      subject: {
        trackingError: {
          funds: [
            {
              name: 'Managed Futures Investment Category (MF)',
              weight: 1,
              value: 0.07981679580496304,
              contributionValue: 0.07981679580496304,
              contribution: 1,
            },
          ],
          strategies: [
            {
              name: 'Managed Futures Investment Category (MF)',
              weight: 1,
              value: 0.07981679580496304,
              contributionValue: 0.07981679580496304,
              contribution: 1,
            },
          ],
        },
        informationRatio: {
          funds: [
            {
              name: 'Managed Futures Investment Category (MF)',
              weight: 1,
              value: 0.12170610100215824,
              contributionValue: 0.12170610100215824,
              contribution: 1,
            },
          ],
          strategies: [
            {
              name: 'Managed Futures Investment Category (MF)',
              weight: 1,
              value: 0.12170610100215824,
              contributionValue: 0.12170610100215824,
              contribution: 1,
            },
          ],
        },
        periodExcessReturn: {
          funds: [
            {
              name: 'Managed Futures Investment Category (MF)',
              weight: 1,
              value: 0.009714191011907472,
              contributionValue: 0.009714191011907472,
              contribution: 1,
            },
          ],
          strategies: [
            {
              name: 'Managed Futures Investment Category (MF)',
              weight: 1,
              value: 0.009714191011907472,
              contributionValue: 0.009714191011907472,
              contribution: 1,
            },
          ],
        },
      },
      benchmark: null,
    },
  },
};

export const mockEmptyAttributions = {
  attributions: {
    historicalContribution: {
      subject: null,
    },
    forecastContribution: {
      subject: null,
    },
  },
};

const emptyAttribution = {
  name: null,
  weight: null,
  value: null,
  contributionValue: null,
  contribution: null,
};

export const mockSomeEmptyAttributions = {
  ...mockAttribution,
  attributions: {
    ...mockAttribution.attributions,
    historicalContribution: {
      subject: {
        ...mockAttribution.attributions.historicalContribution.subject,
        volatility: {
          funds: [emptyAttribution, emptyAttribution, emptyAttribution],
          strategies: [emptyAttribution, emptyAttribution],
        },
      },
    },
  },
};
