import React from 'react';
import styled, { css } from 'styled-components';
import { noop } from 'lodash';
import backgroundImage from '../toggle/background';
import type { TooltipPosition } from 'venn-ui-kit';
import { ColorUtils, GetColor, Tooltip, Shimmer } from 'venn-ui-kit';

const sliderHeight = 30;
const sliderWidth = 80;

const Container = styled.div<ToggleProps>`
  display: flex;
  align-items: center;
  color: ${({ disabled, inverted, toggled }) =>
    disabled ? GetColor.White : inverted ? (toggled ? GetColor.Black : GetColor.Primary.Main) : GetColor.Primary.Dark};
  font-weight: bold;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  user-select: none;
`;

const LoadingShimmer = styled.div`
  ${Shimmer}
  width: ${sliderWidth}px;
  height: ${sliderHeight}px;
  border-radius: 16px;
`;

const Wrapper = styled.div`
  position: relative;
`;

const Toggle = styled.div<ToggleProps>`
  width: ${sliderWidth}px;
  height: ${sliderHeight}px;
  box-shadow: 0 0 0 1px ${({ inverted }) => (inverted ? GetColor.Primary.Main : GetColor.Primary.Dark)};
  border-radius: 16px;
  background-color: ${({ toggled, inverted }) =>
    toggled
      ? inverted
        ? GetColor.Primary.Main
        : ColorUtils.opacifyFrom(GetColor.Primary.Dark, 0.1)
      : inverted
        ? 'transparent'
        : GetColor.White};
  box-sizing: content-box;
  font-size: 11px;
  letter-spacing: 1px;
  transition-duration: 0.3s;

  &:hover {
    color: ${({ inverted }) => (inverted ? GetColor.White : GetColor.Primary.Main)};
  }

  ${(props) =>
    props.disabled &&
    css`
      box-shadow: 0 0 0 1px ${GetColor.Grey};
      background-image: ${backgroundImage};
      background-size: 2px 2px;
      cursor: not-allowed;
      pointer-events: none;
    `}
`;

const Indicator = styled.div<ToggleProps>`
  display: block;
  width: ${sliderHeight}px;
  height: ${sliderHeight}px;
  transition-duration: 0.3s;
  border-radius: ${sliderHeight / 2}px;
  background-color: ${({ inverted }) => (inverted ? 'transparent' : GetColor.White)};
  box-shadow: ${(props) =>
    props.disabled
      ? 'none'
      : `0 0 0 1px ${
          props.inverted
            ? props.toggled
              ? GetColor.Black(props)
              : GetColor.Primary.Main(props)
            : GetColor.Primary.Dark(props)
        }`};

  ${({ toggled }) =>
    toggled
      ? `
    transform: translateX(${sliderWidth - sliderHeight}px);
  `
      : ''};
`;

const TextIndicator = styled.span<{ toggled: boolean }>`
  position: absolute;
  top: 1px;
  line-height: ${sliderHeight}px;
  left: ${(props) => (props.toggled ? '20px' : '42px')};
`;

interface SliderToggleProps {
  tooltipPosition?: TooltipPosition;
  tooltipMessage?: string;
  tooltipMaxWidth?: number;
  disabled?: boolean;
  toggled?: boolean;
  inverted?: boolean;
  onToggle?(): unknown;
  loading?: boolean;
}

interface ToggleProps {
  disabled: boolean;
  toggled: boolean;
  inverted: boolean;
}

export const SliderToggle = ({
  tooltipPosition,
  tooltipMessage,
  tooltipMaxWidth = 500,
  disabled = false,
  toggled = false,
  inverted = false,
  onToggle = noop,
  loading = false,
}: SliderToggleProps) => {
  const toggleProps = { disabled, toggled, inverted };
  const toggle = (
    <Toggle {...toggleProps} className="qa-slider-toggle">
      <Indicator {...toggleProps} />
      <TextIndicator toggled={toggled}>{toggled ? 'ON' : 'OFF'}</TextIndicator>
    </Toggle>
  );

  if (loading) {
    return (
      <Container {...toggleProps}>
        <LoadingShimmer duration={5} />
      </Container>
    );
  }

  return (
    <Container {...toggleProps} onClick={disabled ? noop : onToggle} className="qa-toggle">
      {!tooltipMessage ? (
        <Wrapper>{toggle}</Wrapper>
      ) : (
        <Tooltip maxWidth={tooltipMaxWidth} position={tooltipPosition} content={tooltipMessage || ''}>
          <Wrapper>{toggle}</Wrapper>
        </Tooltip>
      )}
    </Container>
  );
};

export default SliderToggle;
