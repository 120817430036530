import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { TutorialContent } from './TutorialContent';
import { analyticsService, assertNotNil } from 'venn-utils';
import { Button, getTextThemeProvider } from 'venn-ui-kit';

export const ReportLabTab = () => {
  const { Images } = useContext(ThemeContext);

  return (
    <TutorialContent
      headline="Customizable, Flexible, and Archivable: Quickly construct reports and proposals, craft your narrative, and present with confidence."
      images={[assertNotNil(Images.reportLabAsset)]}
      hideBorder
      assetHeight="250px"
    >
      <div>
        <Button
          dominant
          className="qa-check-it"
          data-testid="qa-check-it"
          onClick={() => {
            window.location.href = `mailto:${getTextThemeProvider().salesEmail}?subject=Report Lab Inquiry`;
            analyticsService.ctaClicked({
              destination: 'Report lab inquiry email',
              filled: true,
              locationOnPage: 'Report lab carousel tab',
              purpose: 'Inquire about report lab',
              text: 'Learn more',
              type: 'button',
            });
          }}
        >
          Learn more
        </Button>
      </div>
    </TutorialContent>
  );
};
