import type { ExcelCell } from 'venn-utils';
import type { ColumnData } from 'venn-components';
import { isNil } from 'lodash';

const getHeaderForAnalysis = (name: string, relative: boolean) => {
  const relativeHeaderTitle = relative ? 'Relative Return Count' : 'Return Count';
  return { value: `${name} ${relativeHeaderTitle}`, bold: true };
};

export const convertReturnDataToExcel = (
  columnData: ColumnData[],
  subjectNames: string[],
  relative: boolean[],
): ExcelCell[][] | undefined => {
  if (!columnData.length) {
    return undefined;
  }

  // in the case that some or all subjects had errors, get xCategories from first column where it is defined
  const { xAxisCategories } = columnData.find((column) => !isNil(column.xAxisCategories)) || {};

  // if every subject had an error, just return undefined
  if (!xAxisCategories) {
    return undefined;
  }

  const headers: ExcelCell[] = [
    { value: 'Returns Distribution', bold: true },
    ...subjectNames.map((subjectName, index) => getHeaderForAnalysis(subjectName, relative[index])),
  ];

  const categoryRows = xAxisCategories.map((categoryName, categoryIndex) => {
    const columns = columnData.map((column) => {
      if (column.seriesData.length === 0) {
        return { value: undefined };
      }
      return { value: column.seriesData[categoryIndex] };
    });
    return [{ value: categoryName }, ...columns];
  });

  return [headers, ...categoryRows];
};
