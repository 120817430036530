import { useCallback, useMemo, useState } from 'react';
import {
  COOKIE_SETTINGS,
  CookiesAllowed,
  deleteAdvertisingCookies,
  deleteFunctionalCookies,
  isTrackingBlockedBySignal,
} from '../CookiesCommon';
import { domain, getDate90DaysFromToday } from '../utils';
import { useCookies } from 'react-cookie';
import { analyticsService } from 'venn-utils';
import { useRemoteCookieAcceptPolicy } from './useRemoteCookieAcceptPolicy';

interface UseCookiePreferences {
  onAcceptAllCookies: () => void;
  saveSettings: () => void;
  loading: boolean;
}

const useCookiePersistence = (
  functionalToggled: boolean,
  advertisingToggled: boolean,
  onClose: () => void,
  originalCookiePreferences: CookiesAllowed,
): UseCookiePreferences => {
  const [loading, setLoading] = useState<boolean>(false);
  const [, setRemoteCookiePolicy] = useRemoteCookieAcceptPolicy();
  const expires = getDate90DaysFromToday();
  const cookieOpts = useMemo(() => {
    return { domain, expires, path: '/' };
  }, [expires]);
  const [, setCookie] = useCookies([COOKIE_SETTINGS]);

  const cookiePreferences = useMemo(() => {
    if (functionalToggled && advertisingToggled) {
      return CookiesAllowed.All;
    }
    if (functionalToggled) {
      return CookiesAllowed.FunctionalOnly;
    }
    if (advertisingToggled) {
      return CookiesAllowed.AdvertisingOnly;
    }
    return CookiesAllowed.None;
  }, [advertisingToggled, functionalToggled]);

  const onAcceptAllCookies = useCallback(() => {
    setCookie(COOKIE_SETTINGS, CookiesAllowed.All, cookieOpts);
    setRemoteCookiePolicy(CookiesAllowed.All);
    analyticsService.cookiePreferencesModified({
      cookiesAllowed: ['required', 'functional', 'advertising'],
    });
    onClose();
  }, [cookieOpts, onClose, setCookie, setRemoteCookiePolicy]);

  const saveSettings = useCallback(() => {
    if (cookiePreferences !== originalCookiePreferences) {
      setRemoteCookiePolicy(cookiePreferences);
    }
    setCookie(COOKIE_SETTINGS, cookiePreferences, cookieOpts);
    const cookiesAllowed = ['required'];
    if (cookiePreferences === CookiesAllowed.All || cookiePreferences === CookiesAllowed.FunctionalOnly) {
      cookiesAllowed.push('functional');
    }
    if (cookiePreferences === CookiesAllowed.All || cookiePreferences === CookiesAllowed.AdvertisingOnly) {
      if (!isTrackingBlockedBySignal()) {
        cookiesAllowed.push('advertising');
      }
    }
    analyticsService.cookiePreferencesModified({
      cookiesAllowed,
    });
    // If we have reset our cookie preferences to disallow functional tracking, we need to
    // re-load the website to ensure we are no longer loading the tracking scripts
    if (originalCookiePreferences === CookiesAllowed.All && cookiePreferences === CookiesAllowed.None) {
      setLoading(true);
      // Delete functional and advertising cookies
      deleteAdvertisingCookies();
      deleteFunctionalCookies();
      setRemoteCookiePolicy(CookiesAllowed.None);
      // Reload location to ensure tracking scripts aren't running
      window.location.reload();
    } else if (
      (originalCookiePreferences === CookiesAllowed.All ||
        originalCookiePreferences === CookiesAllowed.FunctionalOnly) &&
      [CookiesAllowed.AdvertisingOnly, CookiesAllowed.None].includes(cookiePreferences)
    ) {
      setLoading(true);
      // Delete functional cookies we have set
      deleteFunctionalCookies();
      // Reload location to ensure tracking scripts aren't running
      window.location.reload();
    } else if (
      (originalCookiePreferences === CookiesAllowed.All ||
        originalCookiePreferences === CookiesAllowed.AdvertisingOnly) &&
      [CookiesAllowed.FunctionalOnly, CookiesAllowed.None].includes(cookiePreferences)
    ) {
      setLoading(true);
      // Delete advertising cookies
      deleteAdvertisingCookies();
      // Reload location to ensure tracking scripts aren't running
      window.location.reload();
    } else if (cookiePreferences === CookiesAllowed.All) {
      setRemoteCookiePolicy(CookiesAllowed.All);
    }
    onClose();
  }, [cookieOpts, cookiePreferences, onClose, originalCookiePreferences, setCookie, setRemoteCookiePolicy]);

  return {
    onAcceptAllCookies,
    saveSettings,
    loading,
  };
};

export default useCookiePersistence;
