import React from 'react';
import styled from 'styled-components';

import { Icon, GetColor, Tooltip } from 'venn-ui-kit';

const ExcludedFactor = ({ fontSize }: { fontSize?: string }) => (
  <StyledTooltip content="Excluded factors are marked with '...'">
    <StyledIcon type="ellipsis-h" />
    <StyledText fontSize={fontSize}>Excluded factor</StyledText>
  </StyledTooltip>
);

const StyledTooltip = styled(Tooltip)`
  display: flex;
  align-items: center;
`;

const StyledText = styled.div<{ fontSize: string | undefined }>`
  position: relative;
  font-weight: 700;
  font-size: ${({ fontSize }) => fontSize ?? '12px'};
`;

const StyledIcon = styled(Icon)`
  margin-right: 10px;
  color: ${GetColor.DarkGrey};
`;

export default ExcludedFactor;
