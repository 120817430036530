/* eslint-disable no-console */

import { cloneDeep, isEqual, noop } from 'lodash';
import { useRef } from 'react';

type PropsDebugger = (name: string, inputs: object) => void;

/**
 * Used for debugging a component's props to determine if the component is rendering unnecessarily, might fail to render when it
 * should, or you're just curious about what is changing each render.
 *
 * @example
 * ```
 * const FooComponent = (props: FooProps) => {
 *   const someRecoilValue = useRecoilValue(bar);
 *   usePropsDebugger('FooComponent', {...props, someRecoilValue});
 * ```
 */
export const usePropsDebugger: PropsDebugger =
  // We should delete usage of usePropsDebugger before landing commits, but in case someones forgets, we swap it to a no-op in production builds.
  process.env.NODE_ENV === 'production'
    ? noop
    : (name, inputs) => {
        // We need to store the oldInputs directly to detect changes in references
        const oldInputsRef = useRef<object | undefined>(undefined);
        // We need to clone old inputs to detect changes in values. Otherwise the stored old inputs can also mutate, if a deep mutation happens.
        const oldInputsCloneRef = useRef<object | undefined>(undefined);
        compareInputs(name, oldInputsRef.current, oldInputsCloneRef.current, inputs);
        oldInputsRef.current = inputs;
        oldInputsCloneRef.current = cloneDeep(inputs);
      };

const compareInputs = (
  name: string | undefined,
  oldInputs: object | undefined,
  oldInputsClone: object | undefined,
  newInputs: object,
) => {
  const inputKeys = Object.keys(newInputs);

  console.groupCollapsed(`${name} - ${getOverallRenderKind()}`);

  console.count(`${name} render count`);
  console.log({ oldInputs, newInputs });

  logIndividualProps(oldInputs, oldInputsClone, inputKeys, newInputs);

  return console.groupEnd();

  // Helper functions

  function getOverallRenderKind() {
    if (oldInputs === undefined || oldInputsClone === undefined) {
      return 'First Render';
    }

    const allRefsEqual = inputKeys.every((key: string | number) => {
      const oldInput = oldInputs[key];
      const newInput = newInputs[key];
      const refIsEqual = oldInput === newInput;
      return refIsEqual;
    });
    const allValuesEqual = isEqual(oldInputsClone, newInputs);

    if (allRefsEqual && allValuesEqual) {
      return 'Unneccessary Render: no changes detected.';
    }

    if (allRefsEqual) {
      return 'Error-prone Render: values changed, but references unchanged. Memoization will work incorrectly.';
    }

    if (allValuesEqual) {
      return 'Unnecessary Render: all values equal, but references changed. Memoization will unnecessarily re-run.';
    }

    return `${name} Rendered`;
  }
};

function logIndividualProps(
  oldInputs: object | undefined,
  oldInputsClone: object | undefined,
  inputKeys: string[],
  newInputs: object,
) {
  if (!oldInputs || !oldInputsClone) {
    return;
  }

  inputKeys.forEach((key: string | number) => {
    const oldInput = oldInputs[key];
    const oldInputClone = oldInputsClone[key];
    const newInput = newInputs[key];

    const refIsEqual = oldInput === newInput;
    const valueIsEqual = isEqual(oldInputClone, newInput);

    if (refIsEqual) {
      if (!valueIsEqual) {
        console.log('VALUE change detected', key, { oldInputClone, newInput });
      }
    } else if (valueIsEqual) {
      console.log('REFERENCE change detected', key, { oldInput, newInput });
    } else {
      console.log('REFERENCE AND VALUE change detected', key, { oldInputClone, newInput });
    }
  });
}
