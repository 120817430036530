import type { FC } from 'react';
import React, { Children, cloneElement } from 'react';
import styled, { css } from 'styled-components';
import { GetColor } from 'venn-ui-kit';
import background from './background';
import type { AnyDuringEslintMigration } from 'venn-utils';

const StyledToggle = styled.div<{ small?: boolean; nonPrimary?: boolean; disabled?: boolean }>`
  display: inline-flex;
  align-self: flex-end;
  justify-self: flex-end;
  margin-left: auto;
  margin-bottom: 10px;
  height: ${({ small }) => (small ? 20 : 35)}px;
  border-radius: ${({ small }) => (small ? 15 : 35)}px;
  background-color: ${GetColor.White};
  border: 1px solid ${({ nonPrimary }) => (nonPrimary ? GetColor.Grey : GetColor.Primary.Dark)};
  transition: all 0.3s ease-in-out;

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      background-image: ${background};
      background-repeat: 2px 2px;
      background-size: 2px 2px;
      border-color: ${GetColor.Grey};
    `}
`;

export interface ToggleProps {
  className?: string;
  disabled?: boolean;
  value?: unknown;
  onChange?(value: unknown): unknown;
  small?: boolean;
  nonPrimary?: boolean;
}

export const Toggle: FC<React.PropsWithChildren<ToggleProps>> = ({
  children,
  className,
  disabled,
  value,
  onChange,
  small,
  nonPrimary,
}) => (
  <StyledToggle className={className} disabled={disabled} small={small} nonPrimary={nonPrimary}>
    {Children.map(children, (child: AnyDuringEslintMigration) =>
      cloneElement(child, {
        selected: child.props.value === value,
        disabled: disabled || child.props.disabled,
        onClick: onChange,
      }),
    )}
  </StyledToggle>
);

export default Toggle;
