import React from 'react';
import type { ManageDataLinkProps } from 'venn-components';
import { EmptyState, ManageDataLink } from 'venn-components';
import type { AnalysisSubjectType } from 'venn-utils';
import styled from 'styled-components';
import type { Message } from 'venn-api';
import { SupportedErrorCodes } from 'venn-api';

interface RollingErrorMessageProps extends ManageDataLinkProps {
  subjectType: AnalysisSubjectType;
  analysisType: string;
  analysisError?: Message;
}

export const RollingErrorMessage = ({
  subjectType,
  analysisType,
  fundId,
  analysisError,
  canEditReturn,
}: RollingErrorMessageProps) => {
  if (analysisError?.code === SupportedErrorCodes.AnalysisRelativeMissingBenchmark) {
    return (
      <StyledEmptyState
        header={`${analysisType} requires a benchmark`}
        message={<div>Please select a benchmark.</div>}
      />
    );
  }

  return (
    <StyledEmptyState
      header="There are insufficient returns available to analyze"
      message={
        <>
          <div>
            The {subjectType} does not have sufficient history to calculate Rolling {analysisType} for the Rolling
            Period selected.
          </div>
          <ManagedDataLinkDiv>
            <ManageDataLink fundId={fundId} defaultEditData canEditReturn={canEditReturn} />
          </ManagedDataLinkDiv>
        </>
      }
    />
  );
};

const StyledEmptyState = styled(EmptyState)`
  margin: 20px;
`;

const ManagedDataLinkDiv = styled.div`
  margin-top: 40px;
  text-align: center;
  @media print {
    display: none;
  }
`;
