import type { FundingFailure, GrowthSimulationPublicPrivateResponse } from 'venn-api';

const singleSubjectResponse: GrowthSimulationPublicPrivateResponse = {
  publicNav: {
    CONFIDENCE_LEVEL_0_25: [
      [1719791999999, 4000000.0],
      [1727740799999, 6489880.132156579],
    ],
    CONFIDENCE_LEVEL_0_01: [
      [1719791999999, 4000000.0],
      [1727740799999, 6146107.248862993],
    ],
    CONFIDENCE_LEVEL_0_50: [
      [1719791999999, 4000000.0],
      [1727740799999, 6640490.022365483],
    ],
    CONFIDENCE_LEVEL_0_75: [
      [1719791999999, 4000000.0],
      [1727740799999, 6795967.96182482],
    ],
    CONFIDENCE_LEVEL_0_99: [
      [1719791999999, 4000000.0],
      [1727740799999, 7204990.17613681],
    ],
    CONFIDENCE_LEVEL_0_90: [
      [1719791999999, 4000000.0],
      [1727740799999, 6941251.741810888],
    ],
    CONFIDENCE_LEVEL_0_10: [
      [1719791999999, 4000000.0],
      [1727740799999, 6357651.027752701],
    ],
  },
  privateNav: {
    CONFIDENCE_LEVEL_0_25: [
      [1719791999999, 1.3412185605908783e7],
      [1727740799999, 1.1171153746422747e7],
    ],
    CONFIDENCE_LEVEL_0_01: [
      [1719791999999, 1.3412185605908783e7],
      [1727740799999, 1.1171153746422747e7],
    ],
    CONFIDENCE_LEVEL_0_50: [
      [1719791999999, 1.3412185605908783e7],
      [1727740799999, 1.1171153746422747e7],
    ],
    CONFIDENCE_LEVEL_0_75: [
      [1719791999999, 1.3412185605908783e7],
      [1727740799999, 1.1171153746422747e7],
    ],
    CONFIDENCE_LEVEL_0_99: [
      [1719791999999, 1.3412185605908783e7],
      [1727740799999, 1.1171153746422747e7],
    ],
    CONFIDENCE_LEVEL_0_90: [
      [1719791999999, 1.3412185605908783e7],
      [1727740799999, 1.1171153746422747e7],
    ],
    CONFIDENCE_LEVEL_0_10: [
      [1719791999999, 1.3412185605908783e7],
      [1727740799999, 1.1171153746422747e7],
    ],
  },
  publicAndPrivateNav: {
    CONFIDENCE_LEVEL_0_25: [
      [1719791999999, 1.741218560590878e7],
      [1727740799999, 1.7661033878579326e7],
    ],
    CONFIDENCE_LEVEL_0_01: [
      [1719791999999, 1.741218560590878e7],
      [1727740799999, 1.7317260995285742e7],
    ],
    CONFIDENCE_LEVEL_0_50: [
      [1719791999999, 1.741218560590878e7],
      [1727740799999, 1.781164376878823e7],
    ],
    CONFIDENCE_LEVEL_0_75: [
      [1719791999999, 1.741218560590878e7],
      [1727740799999, 1.796712170824757e7],
    ],
    CONFIDENCE_LEVEL_0_99: [
      [1719791999999, 1.741218560590878e7],
      [1727740799999, 1.837614392255956e7],
    ],
    CONFIDENCE_LEVEL_0_90: [
      [1719791999999, 1.741218560590878e7],
      [1727740799999, 1.8112405488233633e7],
    ],
    CONFIDENCE_LEVEL_0_10: [
      [1719791999999, 1.741218560590878e7],
      [1727740799999, 1.752880477417545e7],
    ],
  },
  publicNavPercentage: {
    CONFIDENCE_LEVEL_0_01: [
      [1719791999999, 0.22972417653546073],
      [1727740799999, 0.35491220294803794],
    ],
    CONFIDENCE_LEVEL_0_10: [
      [1719791999999, 0.22972417653546073],
      [1727740799999, 0.36269734928642705],
    ],
    CONFIDENCE_LEVEL_0_25: [
      [1719791999999, 0.22972417653546073],
      [1727740799999, 0.3674688682879438],
    ],
    CONFIDENCE_LEVEL_0_50: [
      [1719791999999, 0.22972417653546073],
      [1727740799999, 0.37281736085480066],
    ],
    CONFIDENCE_LEVEL_0_75: [
      [1719791999999, 0.22972417653546073],
      [1727740799999, 0.378244666684994],
    ],
    CONFIDENCE_LEVEL_0_90: [
      [1719791999999, 0.22972417653546073],
      [1727740799999, 0.3832319095506189],
    ],
    CONFIDENCE_LEVEL_0_99: [
      [1719791999999, 0.22972417653546073],
      [1727740799999, 0.39208390000099924],
    ],
  },
  privateNavPercentage: {
    CONFIDENCE_LEVEL_0_01: [
      [1719791999999, 0.7702758234645393],
      [1727740799999, 0.6450877970519621],
    ],
    CONFIDENCE_LEVEL_0_10: [
      [1719791999999, 0.7702758234645393],
      [1727740799999, 0.637302650713573],
    ],
    CONFIDENCE_LEVEL_0_25: [
      [1719791999999, 0.7702758234645393],
      [1727740799999, 0.6325311317120562],
    ],
    CONFIDENCE_LEVEL_0_50: [
      [1719791999999, 0.7702758234645393],
      [1727740799999, 0.6271826391451993],
    ],
    CONFIDENCE_LEVEL_0_75: [
      [1719791999999, 0.7702758234645393],
      [1727740799999, 0.621755333315006],
    ],
    CONFIDENCE_LEVEL_0_90: [
      [1719791999999, 0.7702758234645393],
      [1727740799999, 0.6167680904493811],
    ],
    CONFIDENCE_LEVEL_0_99: [
      [1719791999999, 0.7702758234645393],
      [1727740799999, 0.6079160999990008],
    ],
  },
  probabilityOfSuccess: 0.98,
  forecastReturn: 0.05023989676497908,
  forecastVolatility: 0.11250016016154478,
  fundingFailures: {},
  simulationRange: {
    start: 1719791999999,
    end: 1727740799999,
  },
};

export const privatePublicAssetGrowthMockData: GrowthSimulationPublicPrivateResponse[] = [
  singleSubjectResponse,
  singleSubjectResponse,
];

export const fundingFailureMockData: FundingFailure = {
  date: 1735689599999,
  fundCapitalCalls: [
    {
      fundId: 'id1',
      name: 'name1',
      projectedCapitalCall: 400,
    },
    {
      fundId: 'id2',
      name: 'name2',
      projectedCapitalCall: 600,
    },
  ],
  projectedTotalCapitalCall: 1000,
  projectedTotalDistributions: 500,
  projectedTotalNav: 200,
};
