export const TITLE_COLUMN_WIDTH = 157;
export const PRINT_TITLE_COLUMN_WIDTH = 100;
const WIDE_VALUE_COLUMN_WIDTH = 65;

export const LEGEND_HEIGHT = 26;
export const LEGEND_LINE_HEIGHT = 15;

export const LEGEND_PADDING_GAP = 25;
export const LEGEND_TOP = 0;

export const LABEL_MAX_LINES = 2;
export const FACTOR_SUMMARY_CHART_TRANSFORM = 65;
export const FACTOR_SUMMARY_CHART_NARROW_TRANSFORM = 40;

export const COLUMN_SPACING = 15;

export const WIDE_VALUE_COLUMN_OFFSET = WIDE_VALUE_COLUMN_WIDTH + COLUMN_SPACING;

export const TREND_TOOLTIP_OFFSET = 120;

export const SUMMARY_TOOLTIP_OFFSET = 95;
