import type { ReactNode } from 'react';
import React from 'react';
import { Button, GetColor, Headline3 } from 'venn-ui-kit';
import styled from 'styled-components';

interface WealthCardProps {
  title?: string;
  bodyText?: string;
  buttonText?: string;
  onClick?: () => void;
  icon?: ReactNode;
  alternativeAction?: ReactNode;
}

const WealthCard = ({ title, bodyText, buttonText, onClick, icon, alternativeAction }: WealthCardProps) => {
  return (
    <CardWrapper>
      <CardHeader>
        <Headline3>{title}</Headline3>
      </CardHeader>
      <Body>
        {icon}
        <BodyText>{bodyText}</BodyText>
      </Body>
      <ButtonWrapper>
        <Button dominant onClick={onClick} data-testid="qa-wealth-card">
          {buttonText}
        </Button>
      </ButtonWrapper>
      <AlternativeActionWrapper>{alternativeAction}</AlternativeActionWrapper>
    </CardWrapper>
  );
};

const BodyText = styled(Headline3)`
  font-weight: 300;
  overflow-wrap: break-word;
  padding: 0px 20px 0px 20px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 32px 24px 0px 24px;
`;

const CardHeader = styled.div`
  font-weight: bold;
  border-bottom: 1px solid ${GetColor.GreyScale.Grey30};
  height: 70px;
  display: flex;
  align-items: center;
  padding: 24px 50px 20px 24px;
`;

const CardWrapper = styled.div`
  background-color: ${GetColor.White};
  width: 400px;
  height: 290px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid ${GetColor.GreyScale.Grey30};
  margin-top: 32px;
  margin-right: 32px;
`;

const ButtonWrapper = styled.div`
  padding: 14px 20px 0px 20px;
`;

const AlternativeActionWrapper = styled.div`
  padding: 16px 10px 20px 24px;
`;

export default WealthCard;
