import { isNil } from 'lodash';
import type { ComputableMetricEnum, MetricFilter } from 'venn-api';
import type { SearchResultWithUIState } from '..';

export const SIMILAR_TIMEFRAME = 'YEAR_3';

/** 10% on either side of the metric value */
export const SIMILAR_DELTA_PCT = 0.1;

/** If it already has a low value, still give it some room to get results */
export const SIMILAR_DELTA_MIN = 0.01;

export const getSimilarMetricFilters = (item: SearchResultWithUIState, supportedMetrics?: string[]) => {
  const metrics: MetricFilter[] = [];

  if (!item.metricsOnTimePeriod?.[SIMILAR_TIMEFRAME]) {
    return metrics;
  }

  for (const key of Object.keys(item.metricsOnTimePeriod[SIMILAR_TIMEFRAME])) {
    if (!isNil(supportedMetrics) && !supportedMetrics.includes(key)) {
      // Not all metrics are supported in data library
      continue;
    }
    const val = item.metricsOnTimePeriod[SIMILAR_TIMEFRAME][key];
    let delta = val * SIMILAR_DELTA_PCT;
    if (Math.abs(delta) < SIMILAR_DELTA_MIN) {
      delta = SIMILAR_DELTA_MIN;
    }
    const min = delta < 0 ? val + delta : val - delta;
    const max = delta < 0 ? val - delta : val + delta;
    metrics.push({
      metric: key as ComputableMetricEnum,
      minimum: min,
      timePeriod: SIMILAR_TIMEFRAME,
    });
    metrics.push({
      maximum: max,
      metric: key as ComputableMetricEnum,
      timePeriod: SIMILAR_TIMEFRAME,
    });
  }
  return metrics;
};
