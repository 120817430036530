import React from 'react';
import type { HeatMapTypes } from '../Types';

import type { MouseEventType } from './Cell';
import Cell from './Cell';

interface RowProps {
  rowData: HeatMapTypes.DataEntity[];
  rowId: number;
  cellWidth: number;
  cellHeight: number;
  onCellClick?: MouseEventType;
  leftOffset?: number;
  rightOffset?: number;
  isCellClickable?: boolean;
  print?: boolean;
  showValue?: boolean;
  fontSize?: string;
}

const Row = ({
  rowData,
  rowId,
  cellWidth,
  cellHeight,
  onCellClick,
  leftOffset = 0,
  rightOffset = 0,
  isCellClickable,
  print,
  showValue,
  fontSize,
}: RowProps) => {
  return (
    <>
      {rowData.map((data, i) => {
        const statisticallyNonSignificant = !data.value;

        return (
          <Cell
            // eslint-disable-next-line react/no-array-index-key
            key={i + rowId}
            width={cellWidth}
            height={cellHeight}
            x={i}
            y={rowId}
            displayValue={showValue ? data.value : undefined}
            print={print}
            totalCount={rowData.length}
            leftOffset={leftOffset}
            rightOffset={rightOffset}
            fill={statisticallyNonSignificant ? undefined : data.color}
            onClick={statisticallyNonSignificant ? undefined : onCellClick}
            clickable={!statisticallyNonSignificant && !!isCellClickable}
            fontSize={fontSize}
          />
        );
      })}
    </>
  );
};

export default React.memo(Row);
