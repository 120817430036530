import type { Fund } from 'venn-api';
import { getFactorCMAs } from 'venn-api';
import flatMap from 'lodash/flatMap';

const DEFAULT_INVESTMENT_NAMES = new Set([
  'MSCI ACWI Index',
  'Bloomberg Commodity Index',
  'Bloomberg Global Aggregate Index',
]);

export const getDefaultInvestmentSearchResults = async (): Promise<Fund[]> => {
  const response = await getFactorCMAs();
  if (response && response.content) {
    const allOptions = flatMap(response.content, (grouping) => grouping.options);
    const defaults = allOptions.filter((fund) => DEFAULT_INVESTMENT_NAMES.has(fund.name));
    if (defaults.length < DEFAULT_INVESTMENT_NAMES.size) {
      // fall back to other funds if defaults aren't found
      const nondefaults = allOptions.filter((fund) => !DEFAULT_INVESTMENT_NAMES.has(fund.name));
      return defaults.concat(nondefaults.slice(0, DEFAULT_INVESTMENT_NAMES.size - defaults.length));
    }
    return defaults;
  }
  return [];
};
