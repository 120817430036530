/*
 * Venn Platform API 1.0.0 Operations for "auth"
 * Automatically generated file - do not modify!
 */

import type { AppUser, LoginResponse, ResponseBody, ResponseBodyBoolean, SignupUser } from '../types';
import type { OperationResult } from '../../utils';
import { buildQuery, buildRequestInit, extractError, retryGeneratedFetch } from '../../utils';

/** Allow a user to reset their password when forgotten */
export async function forgotPassword(body?: Partial<SignupUser>, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = '/api/auth/forgotPassword';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Attempt a signin with a user */
export async function signin(
  body?: Partial<SignupUser>,
  signal?: AbortSignal,
): Promise<OperationResult<LoginResponse>> {
  const endpoint = '/api/auth/signin';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: LoginResponse = (await response.json()) as LoginResponse;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Authenticate credentials and retrieve bearer token used for API endpoints */
export async function authenticate(signal?: AbortSignal): Promise<OperationResult<string>> {
  const endpoint = '/api/auth/accesskey/token';
  const requestInit = buildRequestInit({ method: 'POST', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: string = (await response.text()) as string;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Reset a password with a given activation token */
export async function resetPassword(
  body?: Partial<SignupUser>,
  signal?: AbortSignal,
): Promise<OperationResult<AppUser>> {
  const endpoint = '/api/auth/resetPassword';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: AppUser = (await response.json()) as AppUser;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Sends welcome email for given email */
export async function welcome(email?: string, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/auth/welcome${buildQuery({ email })}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch('GET', () => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Sign out of the application */
export async function signout(signal?: AbortSignal): Promise<OperationResult<ResponseBody>> {
  const endpoint = '/api/auth/signout';
  const requestInit = buildRequestInit({ method: 'POST', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: ResponseBody = (await response.json()) as ResponseBody;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Validate that activation token from link is still valid */
export async function validatePasswordReset(
  body?: Partial<SignupUser>,
  signal?: AbortSignal,
): Promise<OperationResult<ResponseBodyBoolean>> {
  const endpoint = '/api/auth/validateReset';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: ResponseBodyBoolean = (await response.json()) as ResponseBodyBoolean;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
