import React, { useCallback, useContext, useMemo } from 'react';
import type { GeneralChartProps } from './types';
import { FactorTrendLineChart } from './factor-line/FactorTrendLineChart';
import { ThemeContext } from 'styled-components';
import { FactorTrendNoData } from './FactorTrendNoData';
import { parseLineChartItems, convertLineDataToExcel } from './factor-line/factorLineUtils';
import useExportUpdate from '../../logic/useExportUpdate';
import { useRecoilValue } from 'recoil';
import { blockDisplayHeader, allProxyInfoState } from 'venn-state';

export const FactorTrendLineChartParent = ({
  metrics = [],
  analyses = [],
  requests,
  selectedBlock,
  factorMetrics,
  exportMetaData,
  selectedRefId,
}: GeneralChartProps) => {
  const theme = useContext(ThemeContext);
  const title = useRecoilValue(blockDisplayHeader(selectedRefId));
  const allProxy = useRecoilValue(allProxyInfoState);

  const currentMetric = metrics[0];
  const showAsPercentage = currentMetric?.dataType === 'PERCENTAGE';

  const items = useMemo(
    () =>
      parseLineChartItems({
        analyses,
        requests,
        selectedBlock,
        factorMetrics,
        colors: theme.Colors,
        currentMetric,
        allProxy,
      }),
    [analyses, currentMetric, factorMetrics, requests, selectedBlock, theme.Colors, allProxy],
  );

  const excelDataFn = useCallback(
    () => convertLineDataToExcel(items, title, currentMetric?.dataType === 'PERCENTAGE') || [],
    [items, title, currentMetric?.dataType],
  );

  useExportUpdate({ selectedRefId, exportMetaData, excelDataFn });

  return (
    <div style={{ flex: 1, minWidth: 0 }} data-testid="qa-factor-trend-line-chart-parent">
      {!analyses.length ? (
        <FactorTrendNoData />
      ) : (
        <FactorTrendLineChart items={items} displayOptions={{ percentageValue: showAsPercentage }} />
      )}
    </div>
  );
};
