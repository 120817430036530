import React from 'react';
import styled from 'styled-components';
import type { Fund, Portfolio } from 'venn-api';
import { GetColor, LegacyRelativePortal } from 'venn-ui-kit';
import { compact, isEmpty, noop, sumBy } from 'lodash';
import AllocationPanelRow from './AllocationPanelRow';
import { Constants } from './Layout';
import { RelativeDropIndicator as DropIndicator } from './drag-and-drop/DropIndicator';
import type { ItemPercentageProps } from './ItemAllocation';
import { logMessageToSentry } from 'venn-utils';
import type { SearchMenuItem } from '../search-menu';
import { MultiSelectSearch } from '../search-menu';
import { TreeItemUpdateType } from './AllocationUtils';

export type DropPosition = 'NEXT_SIBLING' | 'FIRST_CHILD' | 'LAST_CHILD';

interface AllocationTreeItemProps extends ItemPercentageProps {
  parentId?: number;
  strategy?: Portfolio;
  selectedStrategyId: number;
  compareStrategy?: Portfolio;
  allUpdatedFunds?: Map<string, Fund>;
  allOriginalNodes: Map<number, Portfolio>;
  allCompareNodes: Map<number, Portfolio>;
  allGhostChildren: Map<number, Portfolio[]>;
  totalWidth: number;
  hideCompareValue: boolean;
  compareLoading: boolean;
  isRoot?: boolean;
  isInSelectedSubtree?: boolean;
  draggedNode?: Portfolio;
  isInDraggedSubtree?: boolean;
  secondaryTotal?: number;
  isTradesView?: boolean;
  onStrategyUpdate: (updatedStrategy: Portfolio, updateType: TreeItemUpdateType) => void;
  onAddChildFromGhost?: (child: Portfolio) => void;
  onSelectStrategy?: (selectedStrategy?: Portfolio) => void;
  onDrag: (event: React.MouseEvent<HTMLElement>, strategy: Portfolio) => void;
  onDrop: (dropOnNode: Portfolio, positionedAs: DropPosition) => void;
  updateFund?: (fundId: string) => Promise<Fund | undefined>;
  hasAccessToCompare?: boolean;
  hideComparisonColumn?: boolean;
  rootName: string;
}

interface AllocationTreeItemState {
  // Values derived from props, explaining the nature of the item
  isGhost: boolean;
  isStrategy: boolean;
  isSelected: boolean;
  // Value controlled by the togglable triangle
  isCollapsed: boolean;
  // Values for drag'n'drop
  isDragged: boolean;
  dropIndicator?: DropPosition;
  isAddingNewFund: boolean;
}

export default class AllocationTreeItem extends React.PureComponent<AllocationTreeItemProps, AllocationTreeItemState> {
  static defaultProps = {
    onAddChildFromGhost: noop,
  };

  node = React.createRef<HTMLDivElement>();

  state: AllocationTreeItemState = {
    isGhost: false,
    isStrategy: false,
    isSelected: false,
    isCollapsed: false,
    isDragged: false,
    dropIndicator: undefined,
    isAddingNewFund: false,
  };

  static getDerivedStateFromProps(nextProps: AllocationTreeItemProps, prevState: AllocationTreeItemState) {
    const { strategy, compareStrategy, selectedStrategyId, isRoot, draggedNode } = nextProps;
    return {
      isGhost: !strategy && !!compareStrategy,
      isStrategy: strategy ? strategy.fund === undefined : compareStrategy?.fund === undefined,
      isSelected: (isRoot && !selectedStrategyId) || (strategy && strategy.id === selectedStrategyId),
      isDragged: draggedNode && strategy && draggedNode.id === strategy.id,
      dropIndicator: prevState.dropIndicator && !draggedNode ? undefined : prevState.dropIndicator,
    };
  }

  componentDidMount() {
    const { isSelected } = this.state;
    if (isSelected && this.node.current && this.node.current.scrollIntoView) {
      if (!this.node.current || !this.node.current.offsetParent) {
        return;
      }
      const isNotFullyVisible =
        this.node.current.offsetTop + this.node.current.offsetHeight > this.node.current.offsetParent.clientHeight;
      if (isNotFullyVisible) {
        this.node.current.scrollIntoView();
      }
    }
  }

  onChildUpdate = (updatedChild: Portfolio, updateType: TreeItemUpdateType) => {
    const { isStrategy } = this.state;
    if (!isStrategy) {
      return;
    }
    const isDeleteFund = updateType === TreeItemUpdateType.DELETE_PORTFOLIO_CHILD;
    // Find the child amongst this strategy's children
    const { strategy, onStrategyUpdate } = this.props;
    if (!strategy) {
      logMessageToSentry('Failed to update portfolio child because of missing strategy');
      return;
    }

    const updatedChildren = [...strategy.children];

    const childIdx = strategy.children.findIndex((child: Portfolio) => child.id === updatedChild.id);
    if (childIdx === -1) {
      // If this is a new child, add it to the top (if normal add) or to the bottom (if adding from ghost)
      updateType === TreeItemUpdateType.ADD_FROM_GHOST
        ? updatedChildren.push(updatedChild)
        : updatedChildren.unshift(updatedChild);
    } else {
      // Else replace the child with the updated version or delete the child
      isDeleteFund ? updatedChildren.splice(childIdx, 1) : updatedChildren.splice(childIdx, 1, updatedChild);
    }

    const updatedAllocation = sumBy(updatedChildren, 'allocation');
    // To avoid cascading the delete to the parent, we would change type
    const newUpdateType = isDeleteFund ? TreeItemUpdateType.DELETE_PORTFOLIO : updateType;
    // Send the updated node to the parent component
    onStrategyUpdate(
      {
        ...strategy,
        children: updatedChildren,
        allocation: updatedAllocation,
      },
      newUpdateType,
    );
  };

  onMultiChildAdd = (updatedChilds: Portfolio[]) => {
    const { isStrategy } = this.state;
    if (!isStrategy) {
      return;
    }
    // Find the child amongst this strategy's children
    const { strategy, onStrategyUpdate } = this.props;
    if (!strategy) {
      logMessageToSentry('Failed to update portfolio child because of missing strategy');
      return;
    }

    const updatedChildren = [...updatedChilds, ...strategy.children];

    const updatedAllocation = sumBy(updatedChildren, 'allocation');
    // To avoid cascading the delete to the parent, we would change type
    // Send the updated node to the parent component

    onStrategyUpdate(
      {
        ...strategy,
        children: updatedChildren,
        allocation: updatedAllocation,
      },
      TreeItemUpdateType.ADD_FUND,
    );
  };

  onAddChildFromGhost = (child: Portfolio) => {
    const { compareStrategy, onAddChildFromGhost, updateFund } = this.props;
    const { isStrategy, isGhost } = this.state;

    if (!isStrategy) {
      // Funds don't have children, so this method should never be called on a fund.
      return;
    }

    if (!isGhost) {
      // If this strategy is not a ghost, then adding a child to it can follow the normal onChildUpdate procedure.
      this.onChildUpdate(child, TreeItemUpdateType.ADD_FROM_GHOST);
      return;
    }

    /**
     * If we're adding a fund from a ghost fund whose parent is a ghost strategy, whose parent is a ghost strategy,
     * ..., etc. using Date.now() to get the id of each of them might give the same value for some (it's that fast).
     *
     * The solution is to use the child id plus one millisecond: adding one is more deterministic than hoping that
     * one millisecond has passed since the last call to Date.now().
     */
    const newStrategyId = child.id + 1;

    if (!compareStrategy) {
      logMessageToSentry('Failed to add child from comparison because of missing compareStrategy');
      return;
    }

    onAddChildFromGhost?.({
      ...compareStrategy,
      children: [child],
      allocation: child.allocation,
      id: newStrategyId,
    });
    if (child.fund) {
      updateFund?.(child.fund.id);
    }
  };

  onUpdateAllocation = (updatedAllocation: number) => {
    const { strategy, compareStrategy, onStrategyUpdate, onAddChildFromGhost } = this.props;
    const { isGhost, isStrategy } = this.state;

    if (isStrategy) {
      // We should never be able to update the strategy allocation directly.
      return;
    }

    if (!isGhost) {
      if (!strategy) {
        logMessageToSentry('Failed to update allocation because of missing strategy');
        return;
      }
      onStrategyUpdate?.(
        {
          ...strategy,
          allocation: updatedAllocation,
        },
        TreeItemUpdateType.CHANGE_ALLOCATION,
      );
      return;
    }

    if (!compareStrategy) {
      logMessageToSentry('Failed to update comparison allocation because of missing compareStrategy');
      return;
    }

    onAddChildFromGhost?.({
      ...compareStrategy,
      allocation: updatedAllocation,
      id: Date.now(),
    });
  };

  onCapitalModifiedInChildren = (updatedStrategy: Portfolio) => {
    const { onStrategyUpdate } = this.props;
    const { isGhost, isStrategy } = this.state;

    if (!isStrategy || isGhost) {
      // We should never perform cash redistribution on ghosts or items without children
      return;
    }

    onStrategyUpdate?.(updatedStrategy, TreeItemUpdateType.EDIT_CAPITAL);
  };

  onDelete = () => {
    const { onStrategyUpdate, strategy, onSelectStrategy, isInSelectedSubtree } = this.props;
    const { isGhost, isSelected } = this.state;
    if (isGhost || (!isInSelectedSubtree && !isSelected)) {
      // We shouldn't be able to delete ghost strategies, or strategies outside of the selected subtree. Do nothing.
      return;
    }
    if (isSelected) {
      onSelectStrategy?.(undefined);
    }
    if (!strategy) {
      logMessageToSentry('Failed to delete the item because of missing strategy');
      return;
    }
    onStrategyUpdate(strategy, TreeItemUpdateType.DELETE_PORTFOLIO_CHILD);
  };

  toggleIsCollapsed = (isCollapsed: boolean) => {
    this.setState({ isCollapsed });
  };

  onUpdateStrategyName = (newName: string) => {
    const { strategy } = this.props;
    const node = {
      ...strategy,
      name: newName,
    };
    this.props.onStrategyUpdate(node as Portfolio, TreeItemUpdateType.EDIT_NAME);
  };

  onAddStrategy = (strategy?: Portfolio) => {
    if (strategy) {
      this.onChildUpdate(strategy, TreeItemUpdateType.IMPORT_STRATEGY);
      return;
    }

    const portfolioId = Date.now();

    const node: Partial<Portfolio> = {
      name: '',
      allocation: 0,
      compare: [],
      id: portfolioId,
      children: [],
      draft: true,
    };

    this.onChildUpdate(node as Portfolio, TreeItemUpdateType.ADD_STRATEGY);
  };

  onSelectStrategy = () => {
    const { isGhost, isSelected } = this.state;
    const { strategy, onSelectStrategy } = this.props;
    if (!isGhost && !isSelected) {
      onSelectStrategy?.(strategy);
    }
  };

  onDragMe = (event: React.MouseEvent<HTMLElement>) => {
    const { strategy } = this.props;
    if (!strategy) {
      logMessageToSentry('Failed to drag the item because of missing strategy');
      return;
    }
    this.props.onDrag(event, strategy);
  };

  onToggleDropIndicatorForRow = (showDropIndicator: boolean) => {
    if (!showDropIndicator) {
      this.setState({ dropIndicator: undefined });
      return;
    }

    const { isStrategy } = this.state;
    this.setState({ dropIndicator: isStrategy ? 'FIRST_CHILD' : 'NEXT_SIBLING' });
  };

  toggleDropIndicatorOverIndent = (showDropIndicator: boolean) => {
    this.setState({ dropIndicator: showDropIndicator ? 'LAST_CHILD' : undefined });
  };

  handleDrop = () => {
    const { dropIndicator } = this.state;
    if (!dropIndicator) {
      return;
    }
    const { strategy } = this.props;
    if (!strategy) {
      logMessageToSentry('Failed to drop the item because of missing strategy');
      return;
    }

    this.props.onDrop(strategy, dropIndicator);
  };

  onClickAddFund = () => {
    this.setState({ isAddingNewFund: true });
  };

  handleOnSelectFund = (item: SearchMenuItem) => {
    if (!item.value) {
      return;
    }
    const { fund } = item.value;
    if (!fund) {
      logMessageToSentry('Failed to select the fund because it is missing');
      return;
    }
    const node: Partial<Portfolio> = {
      allocation: 0,
      children: [],
      compare: [],
      id: Date.now(),
      fund,
      name: fund.name,
      periodStart: fund.startRange,
      periodEnd: fund.endRange,
    };

    this.setState({
      isAddingNewFund: false,
    });

    const { strategy, updateFund } = this.props;
    if (!strategy) {
      logMessageToSentry('Failed to select fund because of missing strategy');
      return;
    }
    this.onChildUpdate(node as Portfolio, TreeItemUpdateType.ADD_FUND);
    updateFund?.(fund.id);
  };

  handleOnMultiSelectFund = (items: SearchMenuItem[]) => {
    const funds = compact(items.map((item) => item.value?.fund));
    if (isEmpty(funds)) {
      logMessageToSentry('Failed to select the funds because they are missing');
      return;
    }
    const { strategy, updateFund } = this.props;

    if (!strategy) {
      logMessageToSentry('Failed to select fund because of missing strategy');
      return;
    }

    const node: Partial<Portfolio>[] = funds.map((fund, index) => ({
      allocation: 0,
      children: [],
      compare: [],
      id: Date.now() + index,
      fund,
      name: fund.name,
      periodStart: fund.startRange,
      periodEnd: fund.endRange,
    }));
    funds.forEach((fund) => fund && updateFund?.(fund.id));

    this.setState({
      isAddingNewFund: false,
    });

    this.onMultiChildAdd(node as Portfolio[]);
  };

  onCancelAddingNewFund = () => {
    this.setState({
      isAddingNewFund: false,
    });
  };

  render() {
    const {
      strategy,
      compareStrategy,
      allUpdatedFunds,
      allOriginalNodes,
      allCompareNodes,
      allGhostChildren,
      hideCompareValue,
      compareLoading,
      isRoot,
      selectedStrategyId,
      onSelectStrategy,
      isInSelectedSubtree,
      totalWidth,
      draggedNode,
      onDrag,
      onDrop,
      isInDraggedSubtree,
      isPercentageMode,
      baseAllocation,
      orignalBaseAllocation,
      secondaryTotal,
      isTradesView,
      updateFund,
      hideComparisonColumn,
      rootName,
    } = this.props;
    if (!strategy && !compareStrategy) {
      return undefined;
    }

    const { isGhost, isStrategy, isSelected, isCollapsed, isDragged, dropIndicator, isAddingNewFund } = this.state;

    const children = (!isStrategy ? [] : isGhost ? compareStrategy?.children : strategy?.children) || [];

    const originalNode = isGhost
      ? undefined
      : allOriginalNodes.has(strategy!.id)
        ? allOriginalNodes.get(strategy!.id)
        : undefined;

    const ghosts =
      (compareLoading
        ? []
        : isGhost
          ? compareStrategy?.children
          : isStrategy
            ? allGhostChildren.get(strategy!.id)
            : []) || [];

    const showDropIndicatorOverIndent =
      isStrategy && draggedNode && !isDragged && !isInDraggedSubtree && !isGhost && (isSelected || isInSelectedSubtree);

    const qaClass = {
      itemType: isRoot ? 'qa-portfolio-wrapper' : isStrategy ? 'qa-strategy' : 'qa-investment',
      itemsWrapper: 'qa-items-wrapper',
      enabledBlock: 'qa-enabled-block',
      disabledBlock: 'qa-disabled-block',
      isRootPortfolio: isRoot ? 'qa-root-portfolio' : undefined,
    };

    return (
      <Item className={qaClass.itemType} ref={this.node} onMouseUp={this.handleDrop}>
        <AllocationPanelRow
          className={qaClass.isRootPortfolio}
          strategy={strategy}
          compareStrategy={compareStrategy}
          originalNode={originalNode}
          isRoot={!!isRoot}
          isGhost={isGhost}
          isStrategy={isStrategy}
          isSelected={isSelected}
          isDragged={isDragged}
          isInDraggedSubtree={!!isInDraggedSubtree}
          isDraggingInProgress={!!draggedNode}
          totalWidth={totalWidth}
          isInSelectedSubtree={!!isInSelectedSubtree}
          hideCompareValue={hideCompareValue}
          compareLoading={compareLoading}
          isCollapsed={isCollapsed}
          toggleIsCollapsed={this.toggleIsCollapsed}
          onClickAddFund={this.onClickAddFund}
          onUpdateStrategyName={this.onUpdateStrategyName}
          onDelete={this.onDelete}
          onSelectStrategy={onSelectStrategy !== undefined ? this.onSelectStrategy : undefined}
          onUpdateAllocation={this.onUpdateAllocation}
          onAddStrategy={this.onAddStrategy}
          onDrag={this.onDragMe}
          onToggleDropIndicatorForRow={this.onToggleDropIndicatorForRow}
          isPercentageMode={isPercentageMode}
          baseAllocation={baseAllocation}
          orignalBaseAllocation={orignalBaseAllocation}
          secondaryTotal={secondaryTotal}
          isTradesView={isTradesView}
          updatedFund={
            strategy && strategy.fund && !isStrategy && allUpdatedFunds
              ? allUpdatedFunds.get(strategy.fund.id)
              : undefined
          }
          hasAccessToCompare={this.props.hasAccessToCompare}
          onUpdateChildrenAllocations={this.onCapitalModifiedInChildren}
          hideComparisonColumn={hideComparisonColumn}
          rootName={rootName}
        />

        {dropIndicator === 'NEXT_SIBLING' && <DropIndicator verticalPadding={Constants.NAME_MARGIN} />}

        {isStrategy && (
          <ChildrenWrapper className={qaClass.itemsWrapper} hidden={isCollapsed}>
            <Indentation
              onMouseEnter={showDropIndicatorOverIndent ? () => this.toggleDropIndicatorOverIndent(true) : undefined}
              onMouseLeave={showDropIndicatorOverIndent ? () => this.toggleDropIndicatorOverIndent(false) : undefined}
            />

            {dropIndicator === 'FIRST_CHILD' && <DropIndicator verticalPadding={Constants.NAME_MARGIN * 2} />}

            {!isGhost && (
              <ItemChildren className={qaClass.enabledBlock} isGhost={isGhost}>
                {isAddingNewFund && (
                  <LegacyRelativePortal component="div" left={5} style={{ width: 700 }} className="investment-search">
                    <MultiSelectSearch
                      autofocus
                      investmentsOnly
                      boundingRect={this.node.current?.getBoundingClientRect()}
                      onSelected={this.handleOnMultiSelectFund}
                      onBlur={this.onCancelAddingNewFund}
                      excludedItems={(strategy!.children || [])
                        .filter((child) => child.fund)
                        .map(({ fund }) => ({
                          id: fund!.id,
                          type: 'FUND',
                        }))}
                      location="allocationPanel"
                      privateAssetSearchMode="PUBLIC_ONLY"
                    />
                  </LegacyRelativePortal>
                )}
                {children.map((child: Portfolio) => (
                  <AllocationTreeItem
                    parentId={strategy!.id}
                    strategy={child}
                    selectedStrategyId={selectedStrategyId}
                    isInSelectedSubtree={isInSelectedSubtree || isSelected}
                    onSelectStrategy={onSelectStrategy}
                    compareStrategy={allCompareNodes.has(child.id) ? allCompareNodes.get(child.id) : undefined}
                    onStrategyUpdate={this.onChildUpdate}
                    onAddChildFromGhost={this.onAddChildFromGhost}
                    allUpdatedFunds={allUpdatedFunds}
                    allOriginalNodes={allOriginalNodes}
                    allCompareNodes={allCompareNodes}
                    allGhostChildren={allGhostChildren}
                    hideCompareValue={hideCompareValue}
                    compareLoading={compareLoading}
                    totalWidth={totalWidth}
                    draggedNode={draggedNode}
                    onDrag={onDrag}
                    onDrop={onDrop}
                    isInDraggedSubtree={isInDraggedSubtree || isDragged}
                    key={child.id}
                    isPercentageMode={isPercentageMode}
                    baseAllocation={baseAllocation}
                    orignalBaseAllocation={orignalBaseAllocation}
                    secondaryTotal={secondaryTotal}
                    isTradesView={isTradesView}
                    updateFund={updateFund}
                    hasAccessToCompare={this.props.hasAccessToCompare}
                    hideComparisonColumn={hideComparisonColumn}
                    rootName={rootName}
                  />
                ))}
              </ItemChildren>
            )}

            {dropIndicator === 'LAST_CHILD' && <DropIndicator verticalPadding={Constants.NAME_MARGIN * 2} />}

            <ItemChildren isGhost className={qaClass.disabledBlock}>
              {ghosts.map((ghostChild: Portfolio) => (
                <AllocationTreeItem
                  parentId={compareStrategy!.id}
                  strategy={undefined}
                  selectedStrategyId={selectedStrategyId}
                  isInSelectedSubtree={isInSelectedSubtree || isSelected}
                  compareStrategy={ghostChild}
                  onStrategyUpdate={this.onChildUpdate}
                  onAddChildFromGhost={this.onAddChildFromGhost}
                  allUpdatedFunds={allUpdatedFunds}
                  allOriginalNodes={allOriginalNodes}
                  allCompareNodes={allCompareNodes}
                  allGhostChildren={allGhostChildren}
                  hideCompareValue={hideCompareValue}
                  compareLoading={compareLoading}
                  totalWidth={totalWidth}
                  orignalBaseAllocation={orignalBaseAllocation}
                  draggedNode={draggedNode}
                  onDrag={noop}
                  onDrop={noop}
                  isInDraggedSubtree={false}
                  key={ghostChild.id}
                  isPercentageMode={isPercentageMode}
                  baseAllocation={baseAllocation}
                  secondaryTotal={secondaryTotal}
                  isTradesView={isTradesView}
                  updateFund={updateFund}
                  hasAccessToCompare={this.props.hasAccessToCompare}
                  hideComparisonColumn={hideComparisonColumn}
                  rootName={rootName}
                />
              ))}
            </ItemChildren>
          </ChildrenWrapper>
        )}
      </Item>
    );
  }
}

const Item = styled.div`
  position: relative;
`;

const ChildrenWrapper = styled.div<{ hidden: boolean }>`
  ${({ hidden }) => hidden && 'display: none;'}
`;

export const BaseIndentation = styled.div`
  min-width: ${Constants.NAME_MARGIN}px;
  width: ${Constants.NAME_MARGIN}px;
  position: absolute;
`;

const Indentation = styled(BaseIndentation)`
  // enables drag and drop at all indentations
  height: calc(100% - 30px);
`;

export const ItemChildren = styled.div<{ isGhost: boolean }>`
  border-left: dashed 1px ${({ isGhost }) => (isGhost ? GetColor.Grey : GetColor.DEPRECATED_DataLineColor.Gold)};
  margin-left: ${Constants.NAME_MARGIN}px;
`;
