export enum FileTypes {
  CSV = '.csv',
  PDF = '.pdf',
  EXCEL = '.xls',
}

export enum FileLayout {
  HORIZONTAL = 'Horizontal',
  VERTICAL = 'Vertical',
  DATABASE = 'Database',
  MULTIDATE = 'Multi-Date',
  GRID = 'Returns Grid',
}

export enum SampleFrequency {
  MONTHLY = 'Monthly',
  QUARTERLY = 'Quarterly',
  DAILY = 'Daily',
}

export type Sample = {
  type: FileTypes;
  layout: FileLayout;
  title: string;
  description: string;
  tag: string;
  frequency?: SampleFrequency[];
  info?: string;
};

export const SampleReturnsData: Sample[] = [
  {
    type: FileTypes.EXCEL,
    layout: FileLayout.VERTICAL,
    title: 'Excel Vertical Template',
    description: 'Excel file (.xls/.xlsx) with a single date column and multiple investment columns.',
    tag: 'vertical_layout_sample_xls',
    frequency: [SampleFrequency.DAILY, SampleFrequency.MONTHLY, SampleFrequency.QUARTERLY],
  },
  {
    type: FileTypes.EXCEL,
    layout: FileLayout.HORIZONTAL,
    title: 'Excel Horizontal Template',
    description: 'Excel file (.xls/.xlsx) with a row of dates and a column for investment names.',
    tag: 'horizontal_layout_sample_xls',
    frequency: [SampleFrequency.DAILY, SampleFrequency.MONTHLY, SampleFrequency.QUARTERLY],
  },
  {
    type: FileTypes.EXCEL,
    layout: FileLayout.DATABASE,
    title: 'Excel Database Template',
    description: 'Excel file (.xls/.xlsx) with a date column, investment name column and return column.',
    tag: 'database_layout_sample_xls',
    frequency: [SampleFrequency.DAILY, SampleFrequency.MONTHLY, SampleFrequency.QUARTERLY],
  },
  {
    type: FileTypes.EXCEL,
    layout: FileLayout.MULTIDATE,
    title: 'Excel Multi-Table Template',
    description: 'Excel file (.xls/.xlsx) with multiple tables containing a date and investment column each.',
    tag: 'multi_table_sample_xls',
    frequency: [SampleFrequency.DAILY, SampleFrequency.MONTHLY, SampleFrequency.QUARTERLY],
  },
  {
    type: FileTypes.EXCEL,
    layout: FileLayout.GRID,
    title: 'Excel Returns Grid Template',
    description:
      'Excel file (.xls/.xlsx) with monthly returns in a calendar grid format, with years in one column, and months in a row across.',
    tag: 'returns_grid_sample_xls',
    frequency: [SampleFrequency.MONTHLY],
  },
  {
    type: FileTypes.CSV,
    layout: FileLayout.VERTICAL,
    title: 'CSV Vertical Template',
    description: 'CSV file with a single date column and multiple investment columns.',
    tag: 'vertical_layout_sample_csv',
    frequency: [SampleFrequency.DAILY, SampleFrequency.MONTHLY, SampleFrequency.QUARTERLY],
  },
  {
    type: FileTypes.CSV,
    layout: FileLayout.HORIZONTAL,
    title: 'CSV Horizontal Template',
    description: 'CSV file with a row of dates and a column for investment names.',
    tag: 'horizontal_layout_sample_csv',
    frequency: [SampleFrequency.DAILY, SampleFrequency.MONTHLY, SampleFrequency.QUARTERLY],
  },
  {
    type: FileTypes.CSV,
    layout: FileLayout.DATABASE,
    title: 'CSV Database Template',
    description: 'CSV file with a date column, investment name column and return column.',
    tag: 'database_layout_sample_csv',
    frequency: [SampleFrequency.DAILY, SampleFrequency.MONTHLY, SampleFrequency.QUARTERLY],
  },
  {
    type: FileTypes.CSV,
    layout: FileLayout.MULTIDATE,
    title: 'CSV Multi-Table Template',
    description: 'CSV file with multiple tables containing a date and investment column each.',
    tag: 'multi_table_sample_csv',
    frequency: [SampleFrequency.DAILY, SampleFrequency.MONTHLY, SampleFrequency.QUARTERLY],
  },
  {
    type: FileTypes.CSV,
    layout: FileLayout.GRID,
    title: 'CSV Returns Grid Template',
    description:
      'CSV file with monthly returns in a calendar grid format, with years in one column, and months in a row across.',
    tag: 'returns_grid_sample_csv',
    frequency: [SampleFrequency.MONTHLY],
  },
  {
    type: FileTypes.PDF,
    layout: FileLayout.VERTICAL,
    title: 'PDF Vertical Template',
    description: 'PDF file with a single date column and multiple investment columns.',
    tag: 'vertical_layout_sample_pdf',
    frequency: [SampleFrequency.DAILY, SampleFrequency.MONTHLY, SampleFrequency.QUARTERLY],
  },
  {
    type: FileTypes.PDF,
    layout: FileLayout.HORIZONTAL,
    title: 'PDF Horizontal Template',
    description: 'PDF file with a row of dates and a column for invetment names.',
    tag: 'horizontal_layout_sample_pdf',
    frequency: [SampleFrequency.DAILY, SampleFrequency.MONTHLY, SampleFrequency.QUARTERLY],
  },
  {
    type: FileTypes.PDF,
    layout: FileLayout.DATABASE,
    title: 'PDF Database Template',
    description: 'PDF file with a date column, investment name column and return column.',
    tag: 'database_layout_sample_pdf',
    frequency: [SampleFrequency.DAILY, SampleFrequency.MONTHLY, SampleFrequency.QUARTERLY],
  },
  {
    type: FileTypes.PDF,
    layout: FileLayout.GRID,
    title: 'PDF Returns Grid Template',
    description:
      'PDF file with monthly returns in a calendar grid format, with years in one column, and months in a row across.',
    tag: 'returns_grid_sample_pdf',
    frequency: [SampleFrequency.MONTHLY],
  },
];

export const SamplePrivatesData: Sample[] = [
  {
    type: FileTypes.EXCEL,
    layout: FileLayout.HORIZONTAL,
    title: 'Full Cash Flow History Template',
    description: 'Template for private capital investments with full historical cashflow transactions.',
    tag: 'private_sample_xls',
    info: 'Note: Instructions provided on first tab of the Excel',
  },
  {
    type: FileTypes.EXCEL,
    layout: FileLayout.HORIZONTAL,
    title: 'Cumulative Cash Flow History Template',
    description:
      'Template for private capital investments with the most recent Value and Cumulative Contributions - useful for investments whose historical cashflow transactions are not available.',
    tag: 'cumulative_private_sample_xls',
    info: 'Note: Instructions provided on first tab of the Excel',
  },
];

export const SampleNavData: Sample[] = [
  {
    type: FileTypes.EXCEL,
    layout: FileLayout.VERTICAL,
    title: '.Xls Nav Template',
    description: '.Xls file with portfolio allocations as rows.',
    tag: 'sample_navs',
  },
];

export const SamplePortfolioData: Sample[] = [
  {
    type: FileTypes.EXCEL,
    layout: FileLayout.VERTICAL,
    title: '.Xls Portfolio Template',
    description: '.Xls file with multiple portfolios that supports nested strategies.',
    // TODO: This file doesn't exist yet. Please upload it when template is finalized
    tag: 'sample_multi_portfolio',
  },
];
