import type { Analysis, NamedEntityLong } from 'venn-api';
import type { Theme } from 'venn-ui-kit';
import { getItemColor } from 'venn-ui-kit';
import type { AnalysisRequest, HeaderComponentParamsType } from '../types';
import { compact, uniqBy } from 'lodash';
import { formatData } from '../../data-grid';
import {
  BOLD_CLASS,
  RIGHT_ALIGN_CLASS,
  SUBJECT_HEADER_RENDERER,
  VALUE_CELL_RENDERER,
  ALLOCATION_KEY,
  FACTOR_CONTRIBUTION_ANALYSIS_TYPES,
} from '../customAnalysisContants';
import { convertRequestSubjectToItemType } from '../../analysis';
import type { ColGroupDef } from 'ag-grid-community';
import { getMetricIgnoresPercentageContributionSetting } from 'venn-utils';
import type { CustomizableMetric } from 'venn-utils';
import { measureHeader } from '../../utils/grids';
import { formatExportableSubjectWithOptionalFee } from '../../legend';
import type { GridStyle } from 'venn-state';

// TODO(collin.irwin): this can be cleaned up by switching to availableMetrics.filter(<selectedMetricKey predicate>),
// and returning the metric rather than a metric key, because the client code actually uses the metric not the metric key.
const filterByAvailable = (selectedMetrics: string[] = [], availableMetrics: CustomizableMetric[] = []): string[] =>
  selectedMetrics.filter((selectedMetricKey) => availableMetrics.some((metric) => metric.key === selectedMetricKey));

export const getGroupColDef = (
  requests: AnalysisRequest[],
  analyses: (Analysis | undefined)[][],
  metricsSettings: CustomizableMetric[] | undefined,
  theme: Theme,
  gridStyle: GridStyle,
  // We get these fields explicitly rather than in an object, to make exhaustive deps work properly with useMemo and related functions.
  // Otherwise people might pass in objects like the entire block settings object, which changes much more frequently than these values do.
  relativeToBenchmark: boolean,
  contributionToPercentage: boolean,
  blockSelectedMetrics: string[],
  blockSelectedFactors: string[] | undefined,
) => {
  const relative = relativeToBenchmark;
  // Only use selected metrics that exist in the list of available metric settings
  const selectedMetrics = filterByAvailable(blockSelectedMetrics, metricsSettings);
  const selectedFactors = blockSelectedFactors ?? [];
  const perSubjectColumns = compact(
    requests.map((req, index) => {
      const headerName = formatExportableSubjectWithOptionalFee(req.subject);
      const headerGroupComponentParams: HeaderComponentParamsType = {
        color: getItemColor(theme.Colors, convertRequestSubjectToItemType(req.subject, req.isBenchmark)),
        subject: req.subject,
        isCommonBenchmark: req.isBenchmark,
      };
      return {
        marryChildren: true,
        headerName,
        headerGroupComponent: SUBJECT_HEADER_RENDERER,
        headerGroupComponentParams,
        children: compact(
          selectedMetrics.flatMap((key: string) => {
            const metric = metricsSettings?.find((setting) => setting.key === key);
            if (!metric) {
              return [];
            }
            const formatType =
              contributionToPercentage && !getMetricIgnoresPercentageContributionSetting(metric.key)
                ? 'PERCENTAGE'
                : metric.dataType ?? 'TEXT';
            const precision = metric.analysisType === 'NOTABLE_PERIODS' ? 1 : 2;
            if (FACTOR_CONTRIBUTION_ANALYSIS_TYPES.includes(metric.analysisType!)) {
              const allFactors = uniqBy(
                analyses
                  ?.map((a) => a.flatMap((b) => b?.factorExposureComponents))
                  .filter((a) => a)
                  .map((a) => a.map((c) => c?.factors).filter((a) => a) ?? [])
                  .flat(2) ?? ([] as NamedEntityLong[]),
                'id',
              );

              return allFactors
                .filter((factor) => factor && selectedFactors.includes(factor.id.toString()))
                .map((factor) => {
                  const headerName = `${factor?.name} ${
                    relative && metric?.relativeLabel ? metric.relativeLabel : metric?.label
                  }`;
                  return {
                    headerName,
                    valueGetter: ({ data }) =>
                      data.isMetadata
                        ? data.value[index]
                        : key === ALLOCATION_KEY
                          ? data.allocation[index]
                          : data.value[index]?.[`${metric?.key}.${factor?.id}`] ?? '--',
                    valueFormatter: ({ value, data, colDef }) =>
                      data.isMetadata || colDef?.cellRendererParams?.isMetadata ? value : formatData(value, formatType),
                    colSpan: ({ data }) => (data.isMetadata ? selectedFactors.length * selectedMetrics.length : 1),
                    cellRenderer: VALUE_CELL_RENDERER,
                    headerComponentParams: {
                      metricKey: key,
                    },
                    // Cell class for excel export style
                    cellClass: ({ data }) =>
                      compact([formatType, RIGHT_ALIGN_CLASS, data.isStrategy ? BOLD_CLASS : undefined]),
                    minWidth: measureHeader(headerName, theme, gridStyle),
                  };
                });
            }

            const headerName = relative && metric?.relativeLabel ? metric.relativeLabel : metric?.label;
            return [
              {
                headerName,
                valueGetter: ({ data }) =>
                  data.isMetadata
                    ? data.value[index]
                    : key === ALLOCATION_KEY
                      ? data.allocation[index]
                      : data.value[index]?.[metric?.key] ?? '--',
                valueFormatter: ({ value, data, colDef }) => {
                  const formatted =
                    data.isMetadata || colDef?.cellRendererParams?.isMetadata
                      ? value
                      : formatData(value, formatType, precision);
                  return formatted;
                },
                colSpan: ({ data }) => (data.isMetadata ? selectedMetrics.length : 1),
                cellRenderer: VALUE_CELL_RENDERER,
                headerComponentParams: {
                  metricKey: key,
                },
                // Cell class for excel export style
                cellClass: ({ data }) =>
                  compact([formatType, RIGHT_ALIGN_CLASS, data.isStrategy ? BOLD_CLASS : undefined]),
                minWidth: measureHeader(headerName, theme, gridStyle),
              },
            ];
          }),
        ),
      };
    }),
  ) as ColGroupDef[];

  return perSubjectColumns;
};
