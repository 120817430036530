import type { FC, ReactNode } from 'react';
import React, { useState, useEffect } from 'react';
import { get, isEmpty } from 'lodash';
import styled, { withTheme } from 'styled-components';
import type { AxisLabelsFormatterContextObject, TooltipFormatterContextObject, SeriesLineOptions } from 'highcharts';
import Legend from '../../../../legend/Legend';
import Highchart from '../../../../highchart/Highchart';
import DownloadableContentBlock from '../../../../content-block/DownloadableContentBlock';
import EmptyState from '../../../../empty-state/EmptyState';
import type { DownloadMetaData } from '../../../../downloadable';
import { toDownloadTrackingEvent } from '../../../../downloadable';
import HighchartsUtils from '../../../../utils/highcharts';
import type { HeatMapTypes } from '../../../../heat-map';
import type { Theme, DropMenuCheckboxItem } from 'venn-ui-kit';
import { GetColor, HighchartZIndex, Loading } from 'venn-ui-kit';
import type { AnalysisTypeEnum, Message, DataExportEvent } from 'venn-api';
import { SupportedErrorCodes } from 'venn-api';
import type { ExcelCell, AnyDuringEslintMigration } from 'venn-utils';
import { EXPOSURE_BLOCK } from '../types';

import ExcludedFactor from './ExcludedFactor';
import FactorDropDown from './FactorDropDown';
import type { LineChartLegendItem } from './parseData';
import parseData from './parseData';
import getTooltipFormatter from './getTooltipFormatter';

interface FactorTrendLineChartProps {
  data: HeatMapTypes.Root[];
  title: string;
  subtitle: string | ReactNode;
  timestamps: number[];
  chartReloading: AnalysisTypeEnum[];
  block: AnalysisTypeEnum;
  errorMessage: Message;
  ActionsComponent: ReactNode;
  theme: Theme;
  dataExportEvent: DataExportEvent;
  exportData?: ExcelCell[][];
  onUpdateRollingPeriod?: (rollingYears: number | undefined, trendBlock: AnalysisTypeEnum) => void;
  onResetAnalysisPeriod?: () => void;
  downloadMetaData?: DownloadMetaData;
}

interface ErrorInfo {
  actionLabel?: string;
  onAction?: () => void;
}

export interface FactorTrendLineChartItem extends LineChartLegendItem, DropMenuCheckboxItem {}

const getErrorAction = (
  errorCode?: number,
  resetRollingPeriod?: () => void,
  resetAnalysisPeriod?: () => void,
): ErrorInfo => {
  switch (errorCode) {
    case SupportedErrorCodes.InvalidRollingPeriod:
      return { actionLabel: 'Reset Rolling Period', onAction: resetRollingPeriod };
    case SupportedErrorCodes.PeriodTooNarrowForFactorTrend:
      return { actionLabel: 'Reset Analysis Period', onAction: resetAnalysisPeriod };
    default:
      return {};
  }
};

const FactorTrendLineChart: FC<React.PropsWithChildren<FactorTrendLineChartProps>> = ({
  data,
  timestamps,
  title,
  subtitle,
  exportData,
  downloadMetaData,
  block,
  dataExportEvent,
  onUpdateRollingPeriod,
  onResetAnalysisPeriod,
  chartReloading,
  errorMessage,
  ActionsComponent,
  theme: { Typography, Colors },
}) => {
  const isExposureBlock = block === EXPOSURE_BLOCK;

  const [items, setItems] = useState<FactorTrendLineChartItem[]>(() => {
    const legend: LineChartLegendItem[] = parseData(data, timestamps, Colors);
    return legend.map((legendItem) => ({
      ...legendItem,
      label: legendItem.name,
      value: legendItem.name,
      level: 0,
      checked: true,
    }));
  });

  useEffect(() => {
    const newLegend: LineChartLegendItem[] = parseData(data, timestamps, Colors);
    setItems((prevItems) =>
      prevItems.map((item, index) => {
        const newItem = newLegend[index];
        return {
          ...newItem,
          label: newItem.name,
          value: newItem.name,
          level: 0,
          checked: true,
        };
      }),
    );
  }, [data, timestamps, Colors]);

  const dottedMissingSeries = () => {
    const resultArray = [];
    for (const item of items) {
      if (item.checked) {
        const serieDotted = {
          type: 'line' as const,
          name: item.name,
          data: (item.mainSerie ?? []) as SeriesLineOptions['data'],
          zIndex: HighchartZIndex.Serie.Front,
          color: item.color,
          connectNulls: true,
          dashStyle: 'Dot' as const,
        };
        const serieData = {
          type: 'line' as const,
          name: item.name,
          data: (item.mainSerie ?? []) as SeriesLineOptions['data'],
          zIndex: HighchartZIndex.Serie.Front,
          color: item.color,
          connectNulls: false,
          linkedTo: ':previous',
        };
        resultArray.push(serieDotted, serieData);
      }
    }
    return resultArray;
  };

  const getSerie = (serieName: string) => {
    const {
      mainSerie,
      benchmark,
      category,
      seriesTypes = {},
    } = items.filter((legendItem) => legendItem.name === serieName).pop() || {};

    return { mainSerie, benchmark, category, seriesTypes };
  };

  const portfolioName = get(data, [0, 'series', 0, 'name']);

  const handleOnItemClick = (items: DropMenuCheckboxItem<string>[]) => setItems(items as FactorTrendLineChartItem[]);

  const noFactorsSelected = isEmpty(dottedMissingSeries());

  const config: Highcharts.Options = {
    credits: {
      enabled: false,
    },
    chart: {
      type: 'line',
      marginTop: 0,
      marginBottom: 30,
      marginRight: 0,
      marginLeft: 0,
    },
    title: {
      text: '',
    },
    plotOptions: {
      line: {
        marker: {
          enabled: false,
        },
      },
      series: {
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
    },

    xAxis: [
      {
        type: 'datetime',
        gridLineWidth: 1,
        minorTickLength: 0,
        tickLength: 0,
        minPadding: 0.1,
        maxPadding: 0,
        startOnTick: true,
        endOnTick: false,
        showFirstLabel: false,
        labels: {
          y: 20,
          useHTML: true,
          formatter: HighchartsUtils.endOfTheYearFormatter,
          style: {
            fontFamily: Typography.fontFamily,
            fontSize: '14px',
          },
        },
      },
    ],
    yAxis: [
      {
        title: { text: null },
        gridLineWidth: 1,
        startOnTick: true,
        endOnTick: true,
        showFirstLabel: true,
        showLastLabel: false,
        minPadding: 0,
        labels: {
          align: 'right',
          formatter(this: AxisLabelsFormatterContextObject) {
            const { value } = this;
            if (isExposureBlock) {
              // Formatter is supposed to return as string but this is a number.
              // Can we just convert to string before returning? e.g. `${value}`
              return value as AnyDuringEslintMigration;
            }

            return `${(value * 100).toFixed(1)}%`;
          },
          style: {
            fontFamily: Typography.fontFamily,
            fontSize: '14px',
          },
          x: 60,
        },
      },
    ],
    legend: {
      enabled: false,
    },
    tooltip: {
      crosshairs: true,
      backgroundColor: Colors.TransparentBlack,
      style: {
        color: Colors.White,
      },
      formatter(this: TooltipFormatterContextObject) {
        const { series, x, y } = this;
        const { mainSerie, benchmark, category, seriesTypes } = getSerie(series.name);

        return getTooltipFormatter(
          series.name,
          x,
          y,
          Typography,
          seriesTypes,
          isExposureBlock,
          mainSerie!,
          benchmark,
          category,
        );
      },
      useHTML: true,
    },
    series: dottedMissingSeries(),
  };

  const resetRollingPeriod = () => {
    onUpdateRollingPeriod?.(undefined, block);
  };

  const errorAction = getErrorAction(errorMessage?.code, resetRollingPeriod, onResetAnalysisPeriod);

  const getChartContent = () => {
    if (chartReloading.includes(block)) {
      return (
        <LoadingWrapper>
          <Loading pageLoader title="Loading details..." />;
        </LoadingWrapper>
      );
    }

    if (isEmpty(data)) {
      return <EmptyState header="No factors show significant results." />;
    }

    if (errorMessage) {
      return <EmptyState header="Unable to run analysis" message={errorMessage.text} {...errorAction} />;
    }

    if (noFactorsSelected) {
      return <EmptyState header="No factors selected." message="Please select at least one factor to run analysis." />;
    }

    return (
      <FactorTrendLineChartContainer>
        <StyledLegend
          className="qa-trend-line-chart-legend"
          series={items.filter(
            ({ color, name, checked }) =>
              checked && {
                color,
                name,
              },
          )}
        >
          <ExcludedFactor />
        </StyledLegend>
        <Highchart options={config} />
      </FactorTrendLineChartContainer>
    );
  };

  return (
    <Wrapper>
      <DownloadableContentBlock
        header={title}
        subHeader={subtitle}
        downloadable={{
          png: true,
          excel: exportData,
          options: {
            fileName: `${title} - ${portfolioName} - Trend`,
            metaData: downloadMetaData,
          },
          tracking: toDownloadTrackingEvent(dataExportEvent),
        }}
      >
        <FactorDropDownWrapper>
          <FactorDropDown items={items} onItemClick={handleOnItemClick} />
          {ActionsComponent}
        </FactorDropDownWrapper>
        {getChartContent()}
      </DownloadableContentBlock>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 20px;
`;

const FactorDropDownWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 10px 20px;
`;

const StyledLegend = styled(Legend)`
  padding: 10px 20px;
  height: 60px;
  border-bottom: 1px solid ${GetColor.Grey};
  border-top: 1px solid ${GetColor.Grey};

  > div {
    margin: 0 20px 0 0;
  }
`;

const FactorTrendLineChartContainer = styled.div`
  margin-top: 10px;
  transform: translate3d(0, 0, 0);

  .highcharts-container:hover {
    .highcharts-line-series {
      &:not(.highcharts-series-hover) {
        path {
          stroke: ${GetColor.Grey};
          stroke-width: 1;
        }
      }
    }
  }

  .highcharts-xaxis-labels span {
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
  }

  .highcharts-tooltip-circle {
    display: inline-block;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    border: 1px solid white;
  }
`;

const LoadingWrapper = styled.div`
  min-height: 450px;
`;

export default withTheme(FactorTrendLineChart);
