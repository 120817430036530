import React, { useContext } from 'react';
import { FailedAnalysisInfo, ResidualReturnsChart, AnalysisConfigContext } from 'venn-components';
import type { AnalysisTrackingProps, SharedProps } from '../types';

export interface CumulativeResidualReturnProps extends SharedProps, AnalysisTrackingProps {}

const CumulativeResidualReturn = ({
  analyses,
  analysisConfig,
  onResetTimeFrame,
  trackingProps,
  downloadMetaData,
  updateAnalysisStatusForTracking,
}: CumulativeResidualReturnProps) => {
  const { subject, relative, trackingId } = analysisConfig;
  const { actualTimeFrame } = useContext(AnalysisConfigContext);
  if (!subject || !analyses) {
    return null;
  }

  const analysisError = analyses.results.residualReturnsAnalyses?.message;
  const analysesPeriod = analyses.analysesPeriod;

  if (analysisError !== undefined) {
    return (
      <FailedAnalysisInfo
        subject={subject}
        analysesPeriod={analyses.analysesPeriod}
        onResetAnalysisPeriod={onResetTimeFrame}
        error={{
          message: analysisError.text,
          code: analysisError.code,
        }}
        regressionName="Cumulative Residual Return"
        relativeToBenchmark={relative}
        blockNames={['CUMULATIVE_RESIDUAL_RETURN']}
        blockTitles={['Cumulative Residual Return']}
        trackingId={trackingId}
        updateAnalysisStatusForTracking={updateAnalysisStatusForTracking}
      />
    );
  }

  const residualAnalyses = analyses.results.residualReturnsAnalyses;

  return (
    <ResidualReturnsChart
      residualAnalyses={residualAnalyses}
      subject={subject}
      relative={relative}
      trackingProps={trackingProps}
      analysesPeriod={analysesPeriod}
      actualTimeFrame={actualTimeFrame}
      downloadMetaData={downloadMetaData}
    />
  );
};

export default CumulativeResidualReturn;
