import React, { useMemo, useRef } from 'react';
import { withTheme } from 'styled-components';
import type { Options as HighchartOptions, AxisLabelsFormatterContextObject } from 'highcharts';
import { numberFormat } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Highstock from 'highcharts/highstock';
import type { ChartProps } from './types';
import {
  getCustomTooltip,
  toPercentage,
  calculateLegendPadding,
} from '../../../charts/analysis-charts/chart-config-logic';
import { parseDatesCategoriesWithSeries } from './chartsUtils';
import { useChartReflow } from '../../../hooks';
import { useRecoilValue } from 'recoil';
import { blockFonts } from 'venn-state';
import { useBlockId } from '../../contexts/BlockIdContext';

const BarChart = ({
  frequency,
  theme,
  series,
  start,
  end,
  yAxisUnitFormat,
  height,
  pointPadding,
  groupPadding,
  borderWidth,
  className,
}: ChartProps) => {
  const blockId = useBlockId();
  const chartRef = useRef<HighchartsReact.RefObject>(null);
  useChartReflow(blockId, chartRef);
  const legendFont = useRecoilValue(blockFonts.blockChartLegend(blockId));
  const axisFont = useRecoilValue(blockFonts.blockChartAxis(blockId));
  const options = useMemo(() => {
    const { categories, seriesValue } = parseDatesCategoriesWithSeries(series, frequency, theme, start, end);

    return {
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      chart: {
        type: 'column',
        height,
        spacingBottom: 4,
      },
      series: seriesValue,
      xAxis: {
        categories,
        crosshair: true,
        labels: {
          style: {
            fontSize: `${axisFont.fontSizePt}pt`,
          },
        },
      },
      yAxis: {
        labels: {
          formatter(this: AxisLabelsFormatterContextObject) {
            const { value } = this;
            return yAxisUnitFormat === 'percent' ? `${numberFormat(toPercentage(value), 1)}%` : numberFormat(value, 1);
          },

          style: {
            fontSize: `${axisFont.fontSizePt}pt`,
          },
        },
        title: {
          text: '',
        },
      },
      tooltip: getCustomTooltip(yAxisUnitFormat, frequency, theme.Colors, undefined),
      plotOptions: {
        series: {
          events: {
            legendItemClick: () => false,
          },
        },
        column: {
          pointPadding: pointPadding ?? 0.2,
          borderWidth: borderWidth ?? 0,
          // defaulting to null b/c undefined breaks the chart
          groupPadding: groupPadding ?? null,
        },
      },
      legend: {
        itemStyle: {
          fontSize: `${legendFont.fontSizePt}pt`,
        },
        useHTML: true,
        padding: calculateLegendPadding(legendFont.fontSizePt),
        margin: 0,
      },
    } as HighchartOptions;
  }, [
    series,
    frequency,
    theme,
    start,
    end,
    height,
    yAxisUnitFormat,
    pointPadding,
    groupPadding,
    borderWidth,
    legendFont,
    axisFont.fontSizePt,
  ]);

  return (
    <HighchartsReact
      highcharts={Highstock}
      ref={chartRef}
      containerProps={{ className, style: { height: '100%' } }}
      options={options}
    />
  );
};

export default withTheme(BarChart);
