import React from 'react';
import { RowTooltip, TOOLTIP_WIDTH } from '../factor-chart';
import type { HeatMapTypes } from './Types';

interface HighlightProps {
  cellWidth: number;
  width: number;
  x: number;
  y: number;
  selectedRow: number;
  selectedCell: number;
  isPercentage: boolean;
  series: HeatMapTypes.Root[];
  xAxis?: number[];
  displaySubjectNames?: boolean;
}

const Highlight = ({
  width,
  x,
  y,
  series,
  selectedRow,
  selectedCell,
  xAxis = [],
  isPercentage,
  cellWidth,
  displaySubjectNames,
}: HighlightProps) => {
  const toolipXOffset = cellWidth / 2;
  const tooltipX = width - x < TOOLTIP_WIDTH / 2 ? width - TOOLTIP_WIDTH / 2 - toolipXOffset : x + toolipXOffset;

  const content = {
    timestamp: xAxis[selectedCell],
    rowName: series[selectedRow].name,
    factorId: series[selectedRow].factorId,
    details: series[selectedRow].series.map((serie) => ({
      portfolioName: displaySubjectNames ? serie.name : serie.portfolioType,
      tvalue: serie?.data?.[selectedCell]?.tValue,
      value: serie?.data?.[selectedCell]?.value,
      hideTValue: false,
    })),
  };
  return <RowTooltip x={tooltipX} y={y + 1} percentage={isPercentage} content={content} />;
};

export default Highlight;
