import React, { useContext } from 'react';
import styled from 'styled-components';
import { ColorUtils, GetColor, Icon, Shimmer, Tooltip } from 'venn-ui-kit';
import { PortfolioLabContext } from 'venn-components';
import { Numbers } from 'venn-utils';
import { isNil } from 'lodash';

type ConstraintsMetStatus = 'ALL' | 'SOME' | 'NONE';

interface ConstraintsStatusBarProps {
  solutionName: string;
  objectiveConstraintsMet: boolean | undefined;
  allocationConstraintsMet: number | undefined;
  factorConstraintsMet: number | undefined;
}

const ConstraintsStatusBar = ({
  solutionName,
  objectiveConstraintsMet,
  allocationConstraintsMet,
  factorConstraintsMet,
}: ConstraintsStatusBarProps) => {
  const {
    objective,
    objectiveConstraintValue,

    allocationConstraints,
    factorConstraints,

    solutionPortfolio,

    onShowAllocationSection,
    onShowFactorSection,
    onEditObjectiveConstraintValue,
  } = useContext(PortfolioLabContext);

  const allAllocationConstraintsMet = isNil(allocationConstraintsMet)
    ? undefined
    : allocationConstraints.length === allocationConstraintsMet;
  const allFactorConstraintsMet = isNil(factorConstraintsMet)
    ? undefined
    : factorConstraints.length === factorConstraintsMet;

  const constraintsMet: ConstraintsMetStatus =
    objectiveConstraintsMet && allAllocationConstraintsMet && allFactorConstraintsMet
      ? 'ALL'
      : objectiveConstraintsMet || allAllocationConstraintsMet || allFactorConstraintsMet
        ? 'SOME'
        : 'NONE';
  const objectiveName =
    objective === 'maximizeReturns'
      ? 'Maximize Returns'
      : objective === 'targetReturn'
        ? 'Target Return'
        : 'Maximize Sharpe';

  return (
    <Background status={constraintsMet}>
      <div>
        <StatusIcon
          type={constraintsMet === 'ALL' ? 'check-circle' : constraintsMet === 'SOME' ? 'minus-circle' : 'times-circle'}
          status={constraintsMet}
        />
        {constraintsMet === 'ALL'
          ? 'Meets All Constraints'
          : constraintsMet === 'SOME'
            ? 'Meets Some Constraints'
            : 'Does Not Meet Constraints'}
      </div>
      <ConstraintIcons>
        <Tooltip
          content={
            <TooltipContent>
              <h4>
                <strong>
                  {isNil(objectiveConstraintsMet)
                    ? `Unable to determine if ${solutionName} meets your objective constraint`
                    : `${solutionName} ${objectiveConstraintsMet ? 'meets' : "doesn't meet"} your objective constraint`}
                </strong>
              </h4>
              <div>
                Objective: <strong>{objectiveName}</strong>
              </div>
              <div>
                {objective === 'maximizeReturns' ? 'Max Volatility' : 'Min Return'}:{' '}
                <strong>{Numbers.safeFormatNumber(objectiveConstraintValue, 2)}%</strong>
                <i>
                  {' '}
                  (actual:{' '}
                  {Numbers.safeFormatPercentage(
                    objective === 'maximizeReturns'
                      ? solutionPortfolio?.summary?.annualizedVolatility
                      : solutionPortfolio?.summary?.annualizedTotalReturn,
                    2,
                  )}
                  )
                </i>
              </div>
              <div>
                <i>Click to change your objective</i>
              </div>
            </TooltipContent>
          }
        >
          <ConstraintIcon
            type="function"
            onClick={onEditObjectiveConstraintValue}
            met={objectiveConstraintsMet}
            hasGreyBackground={constraintsMet === 'SOME'}
          />
        </Tooltip>
        <Tooltip
          content={
            <TooltipContent>
              <h4>
                <strong>
                  {isNil(allAllocationConstraintsMet)
                    ? `Unable to determine if ${solutionName} meets your allocation constraints`
                    : `${solutionName} ${
                        allAllocationConstraintsMet ? 'meets' : "doesn't meet"
                      } all allocation constraints`}
                </strong>
              </h4>
              <div>
                Number of constraints met: <strong>{allocationConstraintsMet ?? '--'}</strong>
              </div>
              <div>
                Number of constraints not met:{' '}
                <strong>
                  {isNil(allocationConstraintsMet) ? '--' : allocationConstraints.length - allocationConstraintsMet}
                </strong>
              </div>
              <div>
                <i>Click to change your allocation constraints</i>
              </div>
            </TooltipContent>
          }
        >
          <ConstraintIcon
            type="sliders-h"
            onClick={onShowAllocationSection}
            met={allAllocationConstraintsMet}
            hasGreyBackground={constraintsMet === 'SOME'}
          />
        </Tooltip>
        <Tooltip
          content={
            <TooltipContent>
              <h4>
                <strong>
                  {isNil(allFactorConstraintsMet)
                    ? `Unable to determine if ${solutionName} meets your factor constraints`
                    : `${solutionName} ${allFactorConstraintsMet ? 'meets' : "doesn't meet"} all factor constraints`}
                </strong>
              </h4>
              <div>
                Number of constraints met: <strong>{factorConstraintsMet ?? '--'}</strong>
              </div>
              <div>
                Number of constraints not met:{' '}
                <strong>{isNil(factorConstraintsMet) ? '--' : factorConstraints.length - factorConstraintsMet}</strong>
              </div>
              <div>
                <i>Click to change your factor constraints</i>
              </div>
            </TooltipContent>
          }
        >
          <ConstraintIcon
            type="dna"
            onClick={onShowFactorSection}
            met={allFactorConstraintsMet}
            hasGreyBackground={constraintsMet === 'SOME'}
          />
        </Tooltip>
      </ConstraintIcons>
    </Background>
  );
};

export default ConstraintsStatusBar;

const Background = styled.div<{ status: ConstraintsMetStatus }>`
  background-color: ${({ status }) =>
    status === 'ALL'
      ? GetColor.DEPRECATED_DivergingColor.B1
      : status === 'SOME'
        ? GetColor.DEPRECATED_DivergingColor.MID
        : GetColor.DEPRECATED_DivergingColor.A1};
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  height: 50px;
  & > div {
    display: flex;
    align-items: center;
  }
`;

const StatusIcon = styled(Icon)<{ status?: ConstraintsMetStatus }>`
  margin-right: 10px;
  color: ${({ status }) =>
    status === 'ALL'
      ? GetColor.DEPRECATED_DivergingColor.B4
      : status === 'SOME'
        ? GetColor.DarkGrey
        : status === 'NONE'
          ? GetColor.DEPRECATED_DivergingColor.A4
          : GetColor.White};
  font-size: 24px;
`;

const ConstraintIcons = styled.div`
  display: flex;
  align-items: center;
`;

const ConstraintIcon = styled(Icon)<{ met: boolean | undefined; hasGreyBackground: boolean }>`
  font-size: 12px;
  color: ${({ met }) =>
    isNil(met)
      ? GetColor.DarkGrey
      : met
        ? GetColor.DEPRECATED_DivergingColor.B4
        : GetColor.DEPRECATED_DivergingColor.A4};
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  &:hover {
    background-color: ${({ met, hasGreyBackground }) =>
      ColorUtils.opacifyFrom(
        isNil(met)
          ? GetColor.MidGrey1
          : met
            ? GetColor.DEPRECATED_DivergingColor.B2
            : GetColor.DEPRECATED_DivergingColor.A2,
        hasGreyBackground ? 0.5 : 0.8,
      )};
  }
`;

const TooltipContent = styled.div`
  width: 236px;
  padding: 5px;
  & > div:last-child {
    margin-top: 6px;
  }
`;

export const ConstraintsStatusBarShimmer = () => (
  <ConstraintsStatusBarShimmerContainer>
    <StatusIcon type="spinner" />
    <ConstraintIcons>
      <Icon type="function" />
      <Icon type="sliders-h" />
      <Icon type="dna" />
    </ConstraintIcons>
  </ConstraintsStatusBarShimmerContainer>
);

const ConstraintsStatusBarShimmerContainer = styled.div`
  height: 50px;
  ${Shimmer}
  animation-duration: 5s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  i {
    color: ${GetColor.White};
    width: 25px;
    text-align: center;
  }
`;
