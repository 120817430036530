import AdvancedQueryInput from './AdvancedQueryInput';
import { Button } from 'venn-ui-kit';
import React from 'react';
import type { AdvancedQueryRow } from '../types';

interface AdvancedQueriesProps {
  rows: AdvancedQueryRow[];
  onRemoveQuery: (key: string | number) => void;
  onUpdateQuery: (updated: AdvancedQueryRow) => void;
  onAddQuery: () => void;
  showErrors: boolean;
}

const AdvancedQueries = ({ rows, onRemoveQuery, onUpdateQuery, onAddQuery, showErrors }: AdvancedQueriesProps) => {
  return (
    <>
      <div>
        {rows.map((query) => (
          <AdvancedQueryInput
            key={query.key}
            onRemoveQuery={() => onRemoveQuery(query.key)}
            onUpdateQuery={onUpdateQuery}
            query={query}
            showErrors={showErrors && !!query.error}
          />
        ))}
      </div>
      <Button className="qa-add-adv-query" flat onClick={onAddQuery}>
        Add Query +
      </Button>
    </>
  );
};

export default AdvancedQueries;
