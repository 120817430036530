import type { RefObject } from 'react';
import React, { Component } from 'react';
import type { Chart, Options } from 'highcharts/highstock';
import Highstock from 'highcharts/highstock';
import HighchartHeatmap from 'highcharts/modules/heatmap';
import highchartsMore from 'highcharts/highcharts-more';
import ProvidePatternFill from 'highcharts-pattern-fill';
import styled from 'styled-components';
import type { SeriesLineOptions, SeriesBarOptions } from 'highcharts';
import { logExceptionIntoSentry } from 'venn-utils';
import PatternFill from 'highcharts/modules/pattern-fill';

highchartsMore(Highstock);
HighchartHeatmap(Highstock);
ProvidePatternFill(Highstock);
PatternFill(Highstock);
Highstock.SVGRenderer.prototype.symbols.cross = (x: number, y: number, w: number, h: number) => [
  'M',
  x,
  y,
  'L',
  x + w,
  y + h,
  'M',
  x + w,
  y,
  'L',
  x,
  y + h,
  'z',
];

Highstock.SVGRenderer.prototype.symbols.shortLine = (x: number, y: number, h: number) => [
  'M',
  x + h / 2,
  y,
  'L',
  x + h / 2 + 2,
  y,
  x + h / 2 + 2,
  y + h,
  x + h / 2,
  y + h,
  'Z',
];

/* Highcharts' hacky 'mini-plugin' to enable text-based Font Awesome icons as custom markers.
 * Usage example: for Font Awesome 'dna' icon, the unicode is 'f471', and so to use it as a custom marker symbol do:
 * {
 *   marker: {
 *     symbol: 'text:\uf471'
 *   }
 * }
 */
Highstock.wrap(
  Highstock.SVGRenderer.prototype,
  'symbol',
  function svgRendererSymbolWrapper(
    this: Highstock.SVGRenderer,
    proceed: Highstock.SVGRenderer['symbol'],
    ...[symbol, ...restArgs]: Parameters<Highstock.SVGRenderer['symbol']>
  ) {
    const [x, y, , h] = restArgs;
    if (symbol.startsWith('text:')) {
      const text = symbol.split(':')[1];
      const svgElem = this.text(text, x, y)
        .attr({
          translateY: h,
          translateX: -1,
        })
        .css({
          fontFamily: '"Font Awesome 5 Pro"',
          fontSize: h ? `${h * 2}px` : undefined,
          'font-weight': 900,
        });
      return svgElem;
    }
    return proceed.apply(this, [].slice.call(arguments, 1));
  },
);

export interface HighchartProps {
  /** Highcharts options object */
  options: Options;
  /** If true will use Highstock to render chart, Highcharts otherwise  */
  stockChart?: boolean;
  /** Class for the chart wrapping div */
  className?: string;
  /** Programmaticaly change chart before render */
  beforeRender?: (chart: Chart) => void;
}

export class Highchart extends Component<HighchartProps> {
  private container: RefObject<HTMLDivElement> = React.createRef();

  private chartObject: Chart | null = null;

  private mediaQueryList: MediaQueryList = window.matchMedia && window.matchMedia('print');

  shouldComponentUpdate(nextProps: HighchartProps) {
    if (nextProps.options !== this.props.options || nextProps.beforeRender !== this.props.beforeRender) {
      this.renderChart(nextProps.options, nextProps.beforeRender);
    }

    return false;
  }

  componentDidMount() {
    this.renderChart(this.props.options, this.props.beforeRender);
    if (this.mediaQueryList) {
      this.mediaQueryList.addListener(this.reflowChart);
    }
  }

  componentWillUnmount() {
    // highchart has some lifecycle problem and sometimes destroy will
    // throw errors. We add destroy function before to prevent memory leaking,
    // if we have memory leaking problem, we might want to bring it back
    // this.chartObject.destroy();
    this.chartObject = null;
    if (this.mediaQueryList) {
      this.mediaQueryList.removeListener(this.reflowChart);
    }
  }

  componentDidCatch(error: Error) {
    logExceptionIntoSentry(error);
  }

  render() {
    return <HighchartWrapper className={this.props.className} ref={this.container} />;
  }

  private renderChart(options: Options, beforeRender?: (chart: Chart) => void) {
    // Same problems mentioned above
    // if (this.chartObject) {
    //   this.chartObject.destroy();
    // }

    this.chartObject = this.props.stockChart
      ? Highstock.stockChart(this.container.current!, options)
      : Highstock.chart(this.container.current!, options);

    beforeRender?.(this.chartObject);
    Highstock.addEvent(this.chartObject, 'resize', () => {
      if (this.chartObject) {
        beforeRender?.(this.chartObject);
      }
    });

    window.requestAnimationFrame && window.requestAnimationFrame(this.reflowChart);
  }

  private reflowChart = (): void => {
    if (this.chartObject && this.chartObject.options) {
      this.chartObject.reflow();
    }
  };
}

export default Highchart;
export type { Options } from 'highcharts/highstock';
export { Chart } from 'highcharts/highstock';
export type { DashStyleValue as HighchartsDashStyle, TooltipShapeValue as HighchartsTooltipShape } from 'highcharts';
export type HighchartsSeriesOptions = SeriesLineOptions | SeriesBarOptions;
export const hideChartTooltips = () => Highstock.charts.map((chart) => chart?.tooltip?.hide(0));

const HighchartWrapper = styled.div`
  .highcharts-credits {
    display: none;
  }
`;
