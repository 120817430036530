import { useEffect, useState } from 'react';
import type { WhatsNewContent } from 'venn-api';
import { getSponsoredContent, updateSponsoredContent, deleteSponsoredContent, getLatestBlogPost } from 'venn-api';
import { logExceptionIntoSentry, analyticsService } from 'venn-utils';

const useWhatsNewSectionData = (sponsorAccountId: number) => {
  const [isDefault, setIsDefault] = useState(true);
  const [whatsNewData, setWhatsNewData] = useState<WhatsNewContent>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchSponsoredContent = async () => {
      try {
        const {
          content: { whatsNewContent },
        } = await getSponsoredContent();

        if (whatsNewContent) {
          setWhatsNewData(whatsNewContent);
          setIsLoading(false);
          setIsDefault(false);
          return;
        }

        const { content } = await getLatestBlogPost();
        const parsedBlogpostContent = {
          title: content.html_title,
          text: content.meta_description,
          url: content.url,
          link: 'Learn more',
          publishedAt: content.publish_date,
          sponsored: false,
        };
        setWhatsNewData(parsedBlogpostContent);
        setIsLoading(false);
      } catch (e) {
        logExceptionIntoSentry(e);
        setIsLoading(false);
      }
    };
    fetchSponsoredContent();
  }, []);

  const handleOnEdit = async (data: WhatsNewContent) => {
    try {
      setIsLoading(true);
      const {
        content: { whatsNewContent },
      } = await updateSponsoredContent({ whatsNewContent: data });
      const parsedContent = {
        title: whatsNewContent.title,
        text: whatsNewContent.text,
        url: whatsNewContent.url,
        link: whatsNewContent.link,
        publishedAt: whatsNewContent.publishedAt,
        sponsored: whatsNewContent.sponsored,
      };
      analyticsService.homeContentUpdated({ sponsorAccountId, type: 'update' });
      setWhatsNewData(parsedContent);
      setIsDefault(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleOnReset = async () => {
    try {
      setIsLoading(true);
      await deleteSponsoredContent();
      const { content } = await getLatestBlogPost();
      const parsedBlogpostContent = {
        title: content.html_title,
        text: content.meta_description,
        url: content.url,
        link: 'Learn more',
        publishedAt: content.publish_date,
        sponsored: false,
      };
      analyticsService.homeContentUpdated({ sponsorAccountId, type: 'reset' });
      setWhatsNewData(parsedBlogpostContent);
      setIsDefault(true);
      setIsLoading(false);
    } catch (error) {
      logExceptionIntoSentry(error);
      setIsLoading(false);
    }
  };

  return { isDefault, whatsNewData, isLoading, handleOnEdit, handleOnReset };
};

export default useWhatsNewSectionData;
