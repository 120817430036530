import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import type { Fund, Portfolio } from 'venn-api';
import { Icon, LegacyRelativePortal } from 'venn-ui-kit';
import {
  ConstraintSubjectDropdown,
  MultiSelectSearch,
  NewOpportunitiesDropdown,
  MultiPillButtonContainer,
  PrimaryPill,
  SeparatorPill,
} from 'venn-components';
import { compact, isEqual, isNil, uniqBy } from 'lodash';
import { FREQUENCY_TO_ENUM, FrequencyTypes, selectStrategy } from 'venn-utils';
import moment from 'moment';

interface OpportunitiesSelectorProps {
  portfolio: Portfolio;
  funds: Fund[] | undefined;
  parentStrategyId: number | undefined;
  onUpdateNewOpportunities: (funds: Fund[], strategyId: number, isInitializing?: boolean) => void;
  parentRef: React.RefObject<HTMLDivElement>;
}

const OpportunitiesSelector = ({
  portfolio,
  funds,
  parentStrategyId,
  onUpdateNewOpportunities,
  parentRef,
}: OpportunitiesSelectorProps) => {
  const strategy = useMemo(
    () => selectStrategy(parentStrategyId ?? portfolio.id, portfolio) ?? portfolio,
    [parentStrategyId, portfolio],
  );

  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [selectedFunds, setSelectedFunds] = useState<Fund[]>();
  const selectedFundsPortfolioIdRef = useRef<number>(portfolio.id);
  const [hasInitializedOpportunities, setHasInitializedOpportunities] = useState(false);

  const onRemoveItem = useCallback(
    (item: Fund) => {
      if (isNil(selectedFunds)) {
        return;
      }
      const fundIdx = selectedFunds.findIndex((fund) => fund.id === item.id);
      if (fundIdx !== -1) {
        setSelectedFunds([...selectedFunds.slice(0, fundIdx), ...selectedFunds.slice(fundIdx + 1)]);
      }
    },
    [selectedFunds],
  );

  const fundOptions = useMemo(
    () =>
      (selectedFunds ?? []).map((fund) => ({
        label: fund.name,
        value: fund,
        description: getExclusionReason(fund, strategy, portfolio),
      })),
    [selectedFunds, strategy, portfolio],
  );

  useEffect(() => {
    if (isNil(selectedFunds)) {
      if (!isNil(funds)) {
        setSelectedFunds(funds);
      }
      return;
    }

    if (isNil(funds) && selectedFundsPortfolioIdRef.current !== portfolio.id) {
      setSelectedFunds(undefined);
      selectedFundsPortfolioIdRef.current = portfolio.id;
      return;
    }

    const includedFunds = fundOptions.filter((option) => isNil(option.description)).map((option) => option.value);
    if (!isEqual(includedFunds, funds)) {
      onUpdateNewOpportunities(includedFunds, parentStrategyId ?? portfolio.id, !hasInitializedOpportunities);
      if (!hasInitializedOpportunities) {
        setHasInitializedOpportunities(true);
      }
    }
  }, [
    fundOptions,
    funds,
    selectedFunds,
    parentStrategyId,
    portfolio.id,
    onUpdateNewOpportunities,
    hasInitializedOpportunities,
  ]);

  const includedOpportunitiesLength = fundOptions.filter((option) => isNil(option.description)).length;

  return (
    <>
      {isSearchOpen && (
        <LegacyRelativePortal
          onOutClick={() => setIsSearchOpen(false)}
          component="div"
          left={0}
          top={-20}
          style={{ width: 700 }}
          className="investment-search"
        >
          <MultiSelectSearch
            autofocus
            investmentsOnly
            onSelected={(items) => {
              setSelectedFunds(
                uniqBy(
                  [
                    ...(funds ?? []),
                    ...compact(items.map((item) => item.searchResult)).map(
                      (item) =>
                        ({
                          id: item.fundId,
                          name: item.name,
                          frequency: FrequencyTypes[item.frequency],
                          startRange: item.startRange,
                          endRange: item.endRange,
                        }) as Fund,
                    ),
                  ],
                  'id',
                ),
              );
              setIsSearchOpen(false);
            }}
            onBlur={() => setIsSearchOpen(false)}
            boundingRect={parentRef.current?.getBoundingClientRect?.()}
            excludedItems={(selectedFunds ?? []).map((fund) => ({ id: fund.id, type: 'FUND' }))}
            location="newOpportunitiesBench"
            privateAssetSearchMode="PUBLIC_ONLY"
          />
        </LegacyRelativePortal>
      )}

      {(selectedFunds ?? []).length > 0 && (
        <MultiPillButtonContainer>
          <SeparatorPill>Consider</SeparatorPill>
          <NewOpportunitiesDropdown
            items={fundOptions}
            onRemoveItem={onRemoveItem}
            onRemoveAllItems={() => setSelectedFunds([])}
            style={{ marginTop: 5, marginLeft: -1 }}
          >
            {(open, onToggle) => (
              <PrimaryPill
                type="button"
                active={open}
                onClick={() => onToggle(!open)}
                error={fundOptions.some((option) => !isNil(option.description))}
              >
                {includedOpportunitiesLength} New{' '}
                {includedOpportunitiesLength === 1 ? 'Opportunity ' : 'Opportunities '}
                <Icon type={open ? 'caret-up' : 'caret-down'} />
              </PrimaryPill>
            )}
          </NewOpportunitiesDropdown>
          <SeparatorPill>within</SeparatorPill>
          <ConstraintSubjectDropdown
            portfolio={portfolio}
            strategiesOnly
            singleSelection
            allInvestmentsSelected={false}
            selected={[strategy ?? portfolio]}
            onChangeSelection={(selected) =>
              onUpdateNewOpportunities(
                [], // Temporarily clear funds to allow re-filtering selectedFunds for the updated strategy
                selected?.[0]?.id ?? portfolio.id,
              )
            }
          >
            {(open, onToggle) => (
              <PrimaryPill type="button" active={open} onClick={() => onToggle(!open)} isLastChild>
                {strategy.name} <Icon type={open ? 'caret-up' : 'caret-down'} />
              </PrimaryPill>
            )}
          </ConstraintSubjectDropdown>
        </MultiPillButtonContainer>
      )}

      <AddFundsButton onClick={() => setIsSearchOpen(true)} className="qa-new-opportunities-btn">
        <Icon type="plus-circle" prefix="far" />
        {(selectedFunds ?? []).length === 0 ? <span>Add New Opportunities</span> : null}
      </AddFundsButton>
    </>
  );
};

export default OpportunitiesSelector;

const getExclusionReason = (fund: Fund, strategy: Portfolio, portfolio: Portfolio): string | undefined => {
  if (fund.frequency !== FrequencyTypes.DAILY && fund.frequency !== FrequencyTypes.MONTHLY) {
    return `Funds with ${FREQUENCY_TO_ENUM[
      fund.frequency
    ].toLowerCase()} frequency are excluded from optimization results`;
  }

  if (strategy.children?.some((strategyChild) => !strategyChild.draft && strategyChild.fund?.id === fund.id)) {
    return `${fund.name} already exists in ${strategy.name}`;
  }

  if (!isNil(portfolio.periodEnd) && !isNil(portfolio.periodStart)) {
    const portfolioPeriod = moment.utc(portfolio.periodEnd).diff(moment.utc(portfolio.periodStart), 'years', true);
    const overlapPeriod = moment
      .utc(Math.min(portfolio.periodEnd, fund.endRange))
      .diff(moment.utc(Math.max(portfolio.periodStart, fund.startRange)), 'years', true);

    if (overlapPeriod < portfolioPeriod && overlapPeriod < 1) {
      return `${fund.name} does not have enough overlapping data and with be excluded from optimization results`;
    }
  }

  return undefined;
};

const AddFundsButton = styled.button`
  font-weight: bold;
  line-height: 24px;
  i {
    font-size: 24px;
    :not(:last-child) {
      margin-right: 10px;
    }
  }
  display: flex;
  align-items: center;
`;
