import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
  type ReactNode,
  type ReactElement,
} from 'react';
import styled from 'styled-components';
import { assertNotNil, type VennDialogRef } from 'venn-utils';
import { createPortal } from 'react-dom';
import { identity } from 'lodash';
import { ModalSize } from '../modal/components/ModalWrapper';

type DialogChildrenFn = (props: { /** Closes the containing dialog. */ close: () => void }) => ReactNode;

type VennDialogProps = {
  /**
   * Default true.
   * Modal has a backdrop, pressing escape closes the modal, interaction outside of the dialog is blocked, and content outside of the dialog is inert.
   */
  isModal?: boolean;

  /** Default false. When true, children will be under the DOM hierarchy of the parent component. */
  disablePortal?: boolean;

  /** This component supports the use of a render function as a child for more control, enabling access to states like  */
  children?: ReactNode | DialogChildrenFn;
};

export const VennDialog = forwardRef<VennDialogRef, VennDialogProps>(function VennDialog(
  { children, isModal = true, disablePortal = false },
  ref,
) {
  const internalRef = useRef<HTMLDialogElement>(null);
  const [isContentMounted, setIsContentMounted] = useState(false);

  const close = useCallback(() => {
    assertNotNil(internalRef.current).close();
    setIsContentMounted(false);
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      open: () => {
        const dialogEl = assertNotNil(internalRef.current);
        if (isModal) {
          dialogEl.showModal();
        } else {
          dialogEl.show();
        }

        setIsContentMounted(true);
      },
      close,
    }),
    [isModal, close],
  );

  const placementFn = disablePortal ? identity<ReactElement> : createPortal;
  return placementFn(
    <dialog ref={internalRef} data-testid="venn-dialog">
      {isContentMounted && (
        <DialogContentRoot data-testid="venn-dialog-content-root">
          {typeof children === 'function' ? children({ close }) : children}
        </DialogContentRoot>
      )}
    </dialog>,
    document.body,
  );
});

const DialogContentRoot = styled.div`
  width: ${ModalSize.Large}px;
  display: flex;
  flex-direction: column;
`;
