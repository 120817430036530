import { compact } from 'lodash';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import type { StudioAnalysisRequest, StudioRequestSubject, Subject } from 'venn-state';
import { subjectsDateRanges } from 'venn-state';
import type { ComputedDateRange } from 'venn-utils';
import { getSubjectFromRequestSubject } from 'venn-utils';

const getUniqueKeyFromSubject = (subject: Subject): string =>
  subject.fundId ??
  subject.privateFundId ??
  subject.privatePortfolioId ??
  `${subject.portfolioId}${subject.portfolioVersion ? `-${subject.portfolioVersion}` : ''}`;

export const getUniqueKeyFromStudioRequestSubject = (subject: StudioRequestSubject): string =>
  getUniqueKeyFromSubject(getSubjectFromRequestSubject(subject));

export const getRangeMapKeyFromRequest = (request: StudioAnalysisRequest): string =>
  getUniqueKeyFromStudioRequestSubject(request.subject);

export const useRequestsRangeMap = (requests: StudioAnalysisRequest[]): Record<string | number, ComputedDateRange> => {
  const allSubjects = useMemo(() => {
    const subjects = requests.map((request) => getSubjectFromRequestSubject(request.subject));
    // TODO: VENN-21001: fix case where we have an instrument-only portfolio (usually as individual benchmark)
    const benchmarks = compact(
      requests.map(
        (request) =>
          request.benchmark && {
            fundId: request.benchmark.fund?.id,
            portfolioId: request.benchmark.portfolio?.id,
          },
      ),
    );
    return [...subjects, ...benchmarks];
  }, [requests]);

  const ranges = useRecoilValue(subjectsDateRanges(allSubjects));

  const map = useMemo(() => {
    const allEntries = ranges.map((range, i) => [getUniqueKeyFromSubject(allSubjects[i]), range]);
    return Object.fromEntries(allEntries.filter(([key]) => key));
  }, [allSubjects, ranges]);

  return map;
};
