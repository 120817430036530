import type { RangeAnalysisResponse, SubjectRangeAnalysis } from 'venn-api';
import type { Theme } from 'venn-ui-kit';
import { clamp } from 'lodash';
import { assertNotNil, logExceptionIntoSentry } from 'venn-utils';
import { now } from 'moment';

const DAY_IN_MILLIS = 24 * 60 * 60 * 1000;
export const atStartOfDay = (date?: number) => {
  if (date === undefined || date === null) {
    return date;
  }
  return date - DAY_IN_MILLIS + 1;
};
export const getRangeLogic = (
  rangeAnalysis: RangeAnalysisResponse,
  primaryRangeAnalysis: SubjectRangeAnalysis | undefined,
) => {
  const earliestStart = assertNotNil(
    atStartOfDay(Math.max(rangeAnalysis.factorRange.start ?? 0, primaryRangeAnalysis?.earliestStart ?? 0)),
  );
  const latestEnd = Math.min(rangeAnalysis.factorRange.end ?? now(), rangeAnalysis?.latestEnd ?? now());
  const overlapStart = atStartOfDay(rangeAnalysis.start);
  const overlapEnd = rangeAnalysis.end;
  const fullRange = latestEnd && earliestStart ? latestEnd - earliestStart : 0;
  return {
    earliestStart,
    latestEnd,
    fullRange,
    overlap:
      !overlapStart || !overlapEnd
        ? {
            percentageStart: 0,
            percentageWidth: 0,
          }
        : {
            percentageWidth: fullRange <= 0 ? 0 : clamp((overlapEnd - overlapStart) / fullRange, 0, 1),
            percentageStart: fullRange <= 0 ? 0 : clamp((overlapStart - earliestStart) / fullRange, 0, 1),
          },
  };
};

export type RowRangeProps = {
  /** Start date of this row's investment */
  startDate: number;
  /** End date of this row's investment */
  endDate: number;
  /** Start date of proxy, if the proxy exists */
  proxyStartDate: number | undefined;
  /** End date of proxy, if the proxy exists */
  proxyEndDate: number | undefined;
  /** Start date of extrapolation, if it is applied */
  extrapolateStartDate: number | undefined;
  /** End date of extrapolation, if it is applied */
  extrapolateEndDate: number | undefined;
  /** Length of the entire analysis period (a.k.a length of the chart) */
  fullRange: number;
  /** Start of the entire analysis period (a.k.a start of the chart) */
  earliestStart: number;
};

export type RowRange = {
  /** where the range starts, expected in [0 - 1] */
  percentageStart: number;
  /** width of this range, expected in [0 - 1] */
  percentageWidth: number;
};

export type AnalysisPeriodChartRowInfo = {
  proxy: RowRange;
  investment: RowRange;
  extrapolation: RowRange;
};
export const getRowRangeLogic = (info: RowRangeProps): AnalysisPeriodChartRowInfo => {
  const { startDate, endDate, fullRange, earliestStart, proxyEndDate, extrapolateStartDate, extrapolateEndDate } = info;
  const barStart = Math.max(startDate, earliestStart);
  const investmentRange = endDate - barStart;
  if (fullRange === 0 || investmentRange === 0) {
    logExceptionIntoSentry(
      `Error plotting row in analysis period legend. Full analysis range: ${fullRange}. Investment range: ${investmentRange}`,
    );
  }

  // returned investment is a range in the entire chart
  // however, returned "proxy" and "extrapolation" ranges are defined in terms of the full investment range

  // Example. The chart displays years [2018 - 2022] (5 years).
  // Let's say the full investment range is [2018 - 2021] (4 years),
  // of which the extrapolation period is last six months of 2021,
  // and the used proxy is backfilling the entirety of 2018.
  // Then on the displayed chart:
  // investment = [0.0 start, 0.8 width]
  // proxy = [0.0 start, 0.25 width (as this a quarter of the investment!)]
  // extrapolation = [0.75 start, 0.25 width]
  return {
    investment: {
      percentageStart: clamp((barStart - earliestStart) / fullRange, 0, 1),
      percentageWidth: clamp(investmentRange / fullRange, 0, 1),
    },
    proxy: {
      percentageStart: 0, // the proxy always starts at the chart boundary
      percentageWidth: !proxyEndDate ? 0 : clamp((proxyEndDate - barStart) / investmentRange, 0, 1),
    },
    extrapolation:
      extrapolateStartDate !== undefined && extrapolateEndDate !== undefined
        ? {
            percentageStart: (extrapolateStartDate - barStart) / investmentRange,
            percentageWidth: (extrapolateEndDate - extrapolateStartDate) / investmentRange,
          }
        : {
            percentageWidth: 0,
            percentageStart: 0,
          },
  };
};

export type GetDataColorProps = {
  theme: Theme;
  isGreyedOut: boolean;
  secondaryData: boolean;
};
export const getDataColor = ({ theme, isGreyedOut, secondaryData }: GetDataColorProps) => {
  const { Colors } = theme;
  if (isGreyedOut) {
    return {
      investmentColor: Colors.MidGrey2,
      proxyColor: Colors.MidGrey1,
    };
  }
  if (secondaryData) {
    return {
      investmentColor: Colors.DEPRECATED_DataBarColor.LightDarkBlue,
      proxyColor: Colors.DEPRECATED_DataBarColor.LightPaleBlue,
    };
  }
  return {
    investmentColor: Colors.DEPRECATED_DataLineColor.Gold,
    proxyColor: Colors.DEPRECATED_DataLineColor.PaleGold,
  };
};
