import React, { useEffect } from 'react';
import ImageBlock from './components/ImageBlock';
import { PageBreak } from './components/PageBreak';
import TextBlock from './components/TextBlock';
import { blockExcelFunction, useScopedSetRecoilState } from 'venn-state';
import type { CustomBlockTypeEnum } from 'venn-utils';

interface TextAndFormatBlockProps {
  /** @deprecated use useBlockId hook */
  id: string;
  customBlockType: CustomBlockTypeEnum;
  active: boolean;
  inPrintMode: boolean;
  /** @deprecated use isReportState recoil state */
  isReport: boolean;
}

export const TextAndFormatBlock = ({ id, customBlockType, active, isReport }: TextAndFormatBlockProps) => {
  const setExportFunction = useScopedSetRecoilState(blockExcelFunction(id));

  useEffect(() => {
    setExportFunction({ excelFunction: undefined });
  }, [setExportFunction]);

  switch (customBlockType) {
    case 'IMAGE':
      return <ImageBlock id={id} isReport={isReport} />;
    case 'PAGE_BREAK':
      return <PageBreak active={active} />;
    case 'MARKDOWN':
      return <TextBlock id={id} active={active} isReport={isReport} />;
    default:
      // TODO(collin.irwin): wrangle types to remove default case and use assertExhaustive
      return null;
  }
};
