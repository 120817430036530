import { GetColor, getItemColor, getItemLabel, ItemType, Label, Tooltip } from 'venn-ui-kit';
import { getAnalysisPath, getComparisonLabelForBlockLegend } from 'venn-utils';
import React, { useContext } from 'react';
import styled, { css, ThemeContext } from 'styled-components';
import { Link } from 'react-router-dom';
import NameWithOptionalFee from '../../../../legend/NameWithOptionalFee';
import type { StudioRequestSubject } from 'venn-state';
import { convertRequestSubjectToItemType } from '../../../../analysis';

interface SubjectLinkProps {
  subject: StudioRequestSubject;
  customColor?: string;
  headerMode: boolean;
  style?: React.CSSProperties;
  isCommonBenchmark?: boolean;
}

const SubjectLink = ({ subject, customColor, headerMode, style, isCommonBenchmark }: SubjectLinkProps) => {
  const { Colors } = useContext(ThemeContext);
  const subjectType = subject.subjectType === 'PORTFOLIO' && !subject.strategy?.fund ? 'portfolio' : 'investment';
  const strategyId = !subject.strategy?.fund ? subject.strategy?.id : undefined;
  const itemType = convertRequestSubjectToItemType(subject, isCommonBenchmark);
  const color = customColor ?? getItemColor(Colors, itemType);

  return subject.private ? null : (
    <StyledCellWrapper headerMode={headerMode} style={style}>
      <span>
        <Tooltip
          plain
          usePortal
          content={
            <Label inverted>
              {getItemLabel(itemType, itemType === ItemType.Benchmark)}: {getComparisonLabelForBlockLegend(subject)}
              <ClickToAnalyzeLink>Click to analyze.</ClickToAnalyzeLink>
            </Label>
          }
        >
          <StyledLink
            color={color}
            target="_blank"
            rel="noopener noreferrer"
            to={`${getAnalysisPath(subjectType, subject.id)}${strategyId ? `/${strategyId}` : ''}`}
          >
            <NameWithOptionalFee subject={subject} />
          </StyledLink>
        </Tooltip>
      </span>
    </StyledCellWrapper>
  );
};

export default SubjectLink;

const StyledCellWrapper = styled.div<{ headerMode?: boolean }>`
  text-align: ${({ headerMode }) => (headerMode ? 'right' : 'left')};
  text-overflow: ellipsis;
  overflow: hidden;
  ${({ headerMode }) =>
    headerMode &&
    css`
      padding-top: 5px;
      padding-bottom: 5px;
    `}
`;

const ClickToAnalyzeLink = styled.div`
  margin-top: 6px;
  color: ${GetColor.Primary.Main};
`;

const StyledLink = styled(Link)<{ color: string }>`
  color: ${({ color }) => color};
  white-space: pre-line;
`;
