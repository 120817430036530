import type { FC } from 'react';
import React from 'react';
import type { Fund } from 'venn-api';
import styled from 'styled-components';
import { Icon, GetColor, DropDirection } from 'venn-ui-kit';
import { DropdownMenu, DropdownMenuItem } from '../../../dropdown-menu';

export type DropdownValue = number | string | boolean | Fund | symbol;
export const Mixed = Symbol('mixed');

interface DropdownProps {
  /**
   * List of options, with a value and a label
   */
  options: DropdownOption[];
  /**
   * Selected value. Must correspond to a value in options.
   */
  value?: DropdownValue;

  mixedLabel?: string;
  /**
   * Whether the dropdown opens up or down relative to the trigger. Defaults to down.
   */
  opensUp?: boolean;
  /**
   * If readonly, the value will be displayed but won't be changeable.
   */
  readonly?: boolean;
  /**
   * Fires when an option is selected. Doesn't fire if the selected option
   * is the same as the one already selected.
   */
  onChange?: (value: DropdownValue) => void;

  zIndex?: number;
}

export interface DropdownOption {
  displayLabel?: string;
  label?: string;
  value: DropdownValue;
}

const Dropdown: FC<React.PropsWithChildren<DropdownProps>> = ({
  options,
  value,
  readonly,
  onChange,
  mixedLabel,
  opensUp,
  zIndex,
}) => {
  const isMixed = value === Mixed;
  const currentOption = options.find((option) => option.value === value);
  const readOnlyValue = isMixed
    ? mixedLabel || 'Mixed'
    : currentOption
      ? currentOption.displayLabel || currentOption.label || currentOption.value
      : '(unselected)';

  if (readonly) {
    return <ReadOnly>{readOnlyValue}</ReadOnly>;
  }

  return (
    <DropdownMenu
      zIndex={zIndex}
      portal
      dropDirection={opensUp ? DropDirection.Up : DropDirection.Down}
      trigger={
        <CellText>
          {readOnlyValue}
          <StyledIcon type="angle-down" />
        </CellText>
      }
    >
      {options.map((option) => (
        <StyledDropdownMenuItem
          key={String(option.value)}
          onClick={() => {
            if (onChange && option.value !== value) {
              onChange(option.value);
            }
          }}
        >
          {option.label}
        </StyledDropdownMenuItem>
      ))}
    </DropdownMenu>
  );
};

const StyledDropdownMenuItem = styled(DropdownMenuItem)`
  color: ${GetColor.Black} !important;
`;

const CellText = styled.div`
  vertical-align: middle;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
`;

const ReadOnly = styled(CellText)`
  color: ${GetColor.MidGrey2};
`;

const StyledIcon = styled(Icon)`
  margin-left: 5px;
  font-weight: normal;
`;

export default Dropdown;
