import type { BenchmarkAction } from './benchmarkActions';

export default function reduceBusy(busy: boolean, action: BenchmarkAction): boolean {
  switch (action.type) {
    case 'BenchmarkCreateComposite':
    case 'BenchmarkLoadComposite':
      return true;
    case 'BenchmarkAdd':
    case 'BenchmarkAddFromSearch':
    case 'BenchmarkUpdate':
    case 'BenchmarkLoadCompositeSuccess':
    case 'BenchmarkUINavigation':
    case 'Error':
      return false;
    default:
      return busy;
  }
}
