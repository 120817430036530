import type React from 'react';
import { useEffect, useState } from 'react';
import type { MFAResponse } from 'venn-api';
import { setupTOTPMFA, verifyTOTPMFA } from 'venn-api';

interface UseEnableAuthenticatorProps {
  onComplete(): void;
}

interface UseEnableAuthenticator {
  authenticatorInfo: MFAResponse | null;
  code: string;
  onVerificationCodeChange: (code: string) => void;
  onSubmit: (e?: React.FormEvent<HTMLFormElement>) => void;
  loading: boolean;
  error: string;
}

const useEnableAuthenticator = ({ onComplete }: UseEnableAuthenticatorProps): UseEnableAuthenticator => {
  const [authenticatorInfo, setAuthenticatorInfo] = useState<MFAResponse | null>(null);
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const onVerificationCodeChange = (code: string) => {
    setCode(code);
  };

  const onSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setLoading(true);
    try {
      await verifyTOTPMFA({ code });
      setError('');
      onComplete();
    } catch (e) {
      setError(e.content?.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    const loadTOTP = async () => {
      setLoading(true);
      try {
        const { content } = await setupTOTPMFA();
        setAuthenticatorInfo(content);
      } catch (e) {
        setError(e.content?.message);
      }
      setLoading(false);
    };
    loadTOTP();
  }, []);

  return {
    authenticatorInfo,
    code,
    onVerificationCodeChange,
    onSubmit,
    loading,
    error,
  };
};

export default useEnableAuthenticator;
