import React from 'react';
import type { AnyObject } from 'venn-utils';

/**
 * HOC to wrap components with suspense
 * @param Child the component to wrap
 * @param fallback the fallback state in case of suspense
 */
export const withSuspense = <P extends JSX.IntrinsicAttributes & AnyObject>(
  fallback: JSX.Element | null,
  Child: React.ComponentType<React.PropsWithChildren<P>>,
) =>
  function SuspenseHoc(props: P) {
    return (
      <React.Suspense fallback={fallback}>
        <Child {...props} />
      </React.Suspense>
    );
  };
