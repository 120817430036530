import type { Fund } from 'venn-api';

const fund = {
  id: '60632102-a8ca-494d-a3f2-c6037f70218a',
  name: 'Apple',
  owner: {
    id: 12,
    name: 'Antoine Jaussoin',
    displayName: 'Antoine Jaussoin',
    email: 'antoinej@twosigma.com',
    active: true,
    created: 1481587109721,
    pwdLastUpdated: null,
    avatarId: null,
  },
  frequency: 2,
  startRange: 983404799999,
  endRange: 1546300799999,
  created: 1544628316830,
  updated: 1544628316830,
  fundSourceType: {
    id: 1,
    name: 'Investment',
  },
  userUploaded: true,
  active: true,
  investmentSource: 'UPLOAD',
  investmentType: 'NOT_SPECIFIED',
  investmentRegion: 'NOT_SPECIFIED',
  assetType: 'UPLOADED_INSTRUMENT',
  remoteType: 'LOCAL',
  currency: 'USD',
  returnFrequency: 'MONTHLY',
  investmentBenchmarks: [
    {
      fundId: '06ecf50c-95e6-42b4-913a-3d4c51ff1e92',
      portfolioId: null,
      name: 'Microsoft',
      primary: true,
      type: 'INVESTMENT',
    },
    {
      fundId: 'e61b1108-99e6-4cef-8843-99fd56b01cba',
      portfolioId: null,
      name: 'Fund Test00000000086',
      primary: false,
      type: 'INVESTMENT',
    },
  ],
  proxyId: 'e61b1108-99e6-4cef-8843-99fd56b01cba',
  proxyName: 'Fund Test00000000086',
  proxyType: 'BACKFILL',
  categoryAssetType: { key: 'ETF', name: 'ETF' },
  categoryGroup: {
    assetType: 'ETF',
    key: 'ETF_COMMODITY',
    categoryId: '6c8ef70d-281c-43d7-b68b-f42afbded62d',
    name: 'Commodities',
  },
  tags: [],
};

export default fund as unknown as Fund;
