import React from 'react';
import qr from 'qr-image';
import styled, { css } from 'styled-components';
import { GetColor } from 'venn-ui-kit';

export const QRCode = ({ text, colorInverted }: { text: string; colorInverted?: boolean }) => {
  const { path, size } = qr.svgObject(text, { margin: 0, type: 'svg' });
  if (path) {
    return (
      <QRContainer inverted={colorInverted}>
        <QRSVG width={100} height={100} viewBox={`0 0 ${size} ${size}`}>
          <path d={path} />
        </QRSVG>
      </QRContainer>
    );
  }
  return <div>Failed to render QR code.</div>;
};

interface ColorProps {
  inverted?: boolean;
}

const QRContainer = styled.div<ColorProps>`
  width: 106px;
  height: 106px;
  display: flex;
  align-items: center;
  ${({ inverted }) =>
    inverted &&
    css`
      background-color: ${GetColor.White};
      border-radius: 4px;
      padding: 16px;
    `};
`;

const QRSVG = styled.svg`
  margin: 0 auto;
`;
