import React from 'react';
import type { ErrorWrapper } from './errorWrapperTypes';
import { useRecoilValue } from 'recoil';
import { blockLimitedRequestSubjects, subjectsAreEqual } from 'venn-state';
import { getSubjectFromRequestSubject } from 'venn-utils';
import { StyledErrorState } from '../StyledErrorState';
import { isNil } from 'lodash';

export const ComparisonDuplicateSubjectsError: ErrorWrapper = ({ blockId, children }) => {
  // TODO(willw) Refactor subject selectors s.t base subjects are filtered by type and limited to supported block number
  const [primarySubject, secondarySubject] = useRecoilValue(blockLimitedRequestSubjects(blockId)).map(
    getSubjectFromRequestSubject,
  );

  if (!isNil(primarySubject) && subjectsAreEqual(primarySubject, secondarySubject)) {
    return (
      <StyledErrorState
        header="Select two different portfolios to compare"
        message="Configure subjects in the block settings panel to remove duplicate portfolios"
        selectedRefId={blockId}
      />
    );
  }

  return <>{children}</>;
};
