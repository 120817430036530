import React, { useContext } from 'react';
import { getBarColor } from '../../factor-chart';
import Bar from './bar/Bar';
import Row from './row/Row';
import { getOpacity } from './utils';
import type { ChartRowComponentProps } from './summary-chart/SummaryChart';
import { ThemeContext } from 'styled-components';

const WaterfallChartRows: React.FC<React.PropsWithChildren<ChartRowComponentProps>> = ({
  factors,
  errorBars,
  percentage,
  scale,
  viewPort,
  categoryActive,
  ...restProps
}) => {
  const theme = useContext(ThemeContext);
  return (
    <g {...restProps}>
      {factors.map((factor, factorIndex) => {
        const { factorId, mainDataPoint, bar } = factor;

        const barColor = getBarColor(bar.value, theme);
        const statisticallySignificant = !!mainDataPoint?.statisticallySignificant;
        const opacity = getOpacity(statisticallySignificant);
        const barComponent = (
          <Bar
            color={barColor}
            opacity={opacity}
            error={0}
            errorBar={errorBars}
            factorId={factorId}
            index={factorIndex}
            statisticallySignificant={statisticallySignificant}
            barScale={scale}
            transform={`translate(${viewPort.padding.left}, 0)`}
            value={bar}
            viewPort={viewPort}
            waterfall
          />
        );

        return (
          <Row
            key={factor.factorId}
            factor={factor}
            factorIndex={factorIndex}
            percentage={percentage}
            viewPort={viewPort}
            categoryActive={categoryActive}
            typography={theme.Typography}
          >
            {barComponent}
          </Row>
        );
      })}
    </g>
  );
};

export default WaterfallChartRows;
