/**
 * Binary search algorithm that returns the last indice to be valid.
 *
 * Searches over the integer domain [low, high] for the last indice `mid` such that `isMidLow(mid)` is true.
 */
export function intBinarySearchLow(low: number, high: number, isMidLow: (mid: number) => boolean): number {
  let lastValid = low;

  while (low <= high) {
    const mid = Math.floor((low + high) / 2);
    if (isMidLow(mid)) {
      lastValid = mid;
      low = mid + 1;
    } else {
      high = mid - 1;
    }
  }

  return lastValid;
}
