import React from 'react';
import { FlexHeader, Section } from './shared';
import styled from 'styled-components';

interface PanelSectionProps {
  header: string | JSX.Element;
  children: React.ReactNode;
  className?: string;
}

export const PanelSection = ({ header, children, className }: PanelSectionProps) => (
  <Section className={className}>
    <FlexHeader>{header}</FlexHeader>
    <Content>{children}</Content>
  </Section>
);

const Content = styled.div`
  padding: 0 10px;
`;
