import type { WorkspaceMember } from 'venn-api';

const members = [
  {
    avatarId: null,
    created: 1559901300357,
    email: 'test1@twosigma.com',
    invitationExpiration: null,
    invited: null,
    invitedById: null,
    mfaEnabled: false,
    name: 'TestAcount 1',
    status: 'ACTIVE',
    userId: 3000402,
  },
  {
    avatarId: null,
    created: null,
    email: 'test2@twosigma.com',
    invitationExpiration: 1583772568727,
    invited: 1583167768737,
    invitedById: 3000030,
    mfaEnabled: false,
    name: 'TestAcount 2',
    status: null,
    userId: null,
  },
  {
    avatarId: null,
    created: 1549901300357,
    email: 'test3@twosigma.com',
    invitationExpiration: null,
    invited: null,
    invitedById: null,
    mfaEnabled: false,
    name: 'TestAcount 3',
    status: 'ACTIVE',
    userId: 3000402,
  },
  {
    avatarId: null,
    created: 1549901300357,
    email: 'test4@twosigma.com',
    invitationExpiration: null,
    invited: null,
    invitedById: null,
    mfaEnabled: false,
    name: 'TestAcount 4',
    status: 'INACTIVE',
    userId: 3000402,
  },
  {
    avatarId: null,
    created: null,
    email: 'test5@twosigma.com',
    invitationExpiration: Date.now() + 10000000,
    invited: 1583168768737,
    invitedById: 3000030,
    mfaEnabled: false,
    name: 'TestAcount 5',
    status: 'INVITED',
    userId: null,
  },
];

export default members as unknown as WorkspaceMember[];
