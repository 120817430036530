import styled, { css, keyframes } from 'styled-components';
import { GetColor } from '../style/color';
import { ColorUtils } from '../style/colorUtils';
import type { ThemeProvider } from 'venn-ui-kit';

const placeHolderShimmer = keyframes`
0% {
  background-position: -468px 0;
}
100% {
  background-position: 468px 0;
}
`;

export interface ShimmerProps {
  duration?: number;
  shimmerColor?: ThemeProvider;
  backgroundColor?: ThemeProvider;
}

export const Shimmer = css<ShimmerProps>`
  -webkit-animation-duration: ${(props) => props.duration || 2}s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: ${placeHolderShimmer};
  -webkit-animation-timing-function: linear;
  animation-duration: ${(props) => props.duration || 2}s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${placeHolderShimmer};
  animation-timing-function: linear;
  background: ${({ backgroundColor = GetColor.White }) => backgroundColor};
  background-image: linear-gradient(
    to right,
    ${({ shimmerColor = GetColor.PaleGrey }) => ColorUtils.hex2rgbaFrom(shimmerColor, 0.2)} 0%,
    ${({ shimmerColor = GetColor.PaleGrey }) => ColorUtils.hex2rgbaFrom(shimmerColor, 0.7)} 25%,
    ${({ shimmerColor = GetColor.PaleGrey }) => ColorUtils.hex2rgbaFrom(shimmerColor, 1)} 50%,
    ${({ shimmerColor = GetColor.PaleGrey }) => ColorUtils.hex2rgbaFrom(shimmerColor, 0.7)} 75%,
    ${({ shimmerColor = GetColor.PaleGrey }) => ColorUtils.hex2rgbaFrom(shimmerColor, 0.2)} 100%
  );
`;

export type ShimmerBlockProps = { height?: number; margin?: number; width?: string } & ShimmerProps;

export const ShimmerBlock = styled.div.attrs<{ className: string }>({ className: 'qa-shimmer' })<ShimmerBlockProps>`
  height: ${({ height }) => (height ? `${height}px` : '100%')};
  width: ${({ width }) => (width ? `${width}` : 'auto')};
  ${Shimmer};
  animation-duration: 5s;
  margin-bottom: ${({ margin }) => margin ?? 20}px;
`;
