import type { FC } from 'react';
import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';
import type { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import type { RangeDebugResponse } from 'venn-api';
import type { AnalysesPeriod, AnalysisSubject, TimeFrame } from 'venn-utils';
import { assert, LibraryItemType, navigateToLibrary, Routes, shouldUserUpdateDataForFund } from 'venn-utils';
import EmptyState from '../../../empty-state/EmptyState';
import FailedAnalysisInfo from '../../chart-errors/FailedAnalysisInfo';
import type { TrackAnalysisProps } from '../../chart-errors/TrackAnalysis';
import type { RangeDebugGroup } from '../../../hooks';
import { useRangesDebug } from '../../../hooks';
import { Loading } from 'venn-ui-kit';
import RangeDebugContext from '../../../contexts/range-debug-context';
import { getMaxFrequency } from '../../chart-errors/PortfolioRangesUtils';
import { EmptyStateContent } from '../../chart-errors/components/styled';
import { compact, isNil } from 'lodash';
import ErrorInvalidAnalysisPeriod from '../../chart-errors/shared-errors/ErrorInvalidAnalysisPeriod';
import InvestmentActions from '../../../investment-actions/InvestmentActions';
import type { FailedAnalysisError } from '../../chart-errors/types';

interface CorrelationErrorProps extends RouteComponentProps {
  subject?: AnalysisSubject;
  error: FailedAnalysisError;
  isMissingMaster: boolean;
  actualTimeFrame: TimeFrame;
  categoryActive: boolean;
  trackingProps: TrackAnalysisProps;
  onResetTimeFrame?: () => void;
}

const CorrelationError: FC<React.PropsWithChildren<CorrelationErrorProps>> = ({
  history,
  subject,
  error,
  isMissingMaster,
  actualTimeFrame,
  categoryActive,
  trackingProps,
  onResetTimeFrame,
}) => {
  const redirectToCreateNewPortfolio = useCallback(() => {
    history.push(Routes.CREATE_PORTFOLIO);
  }, [history]);

  const redirectToMasterHoldings = useCallback(() => {
    navigateToLibrary(history, {
      selectedFilters: {
        tags: [],
        itemType: LibraryItemType.MASTER_PORTFOLIO_HOLDING,
        quickFilters: [],
      },
    });
  }, [history]);

  const { onFundDataUpdated } = useContext(RangeDebugContext);

  const { loading, masterRangeDebug, rangeDebugGroup } = useRangesDebug(
    subject,
    categoryActive,
    trackingProps.trackingId,
    true,
  );

  if (!subject) {
    return (
      <EmptyMasterWithCTA>
        <EmptyState header="Couldn't calculate correlations" message={error.message} />
      </EmptyMasterWithCTA>
    );
  }

  if (subject.type === 'investment' && isMissingMaster) {
    return (
      <EmptyMasterWithCTA>
        <EmptyState
          header="Set up your portfolio to view correlations"
          message={error.message}
          actionLabel="Set Up Your Portfolio"
          onAction={redirectToCreateNewPortfolio}
        />
      </EmptyMasterWithCTA>
    );
  }

  const isMasterConstraining = getIsMasterConstraining(masterRangeDebug, rangeDebugGroup);
  const isFundOutOfDate = shouldUserUpdateDataForFund(subject.fund);
  const isPeriodManuallyConstrained = isAnalysisPeriodManuallyConstrained(
    actualTimeFrame,
    trackingProps?.analysesPeriod,
  );
  const canInterpolate = subject.fund?.interpolatable;

  if (
    subject.type === 'investment' &&
    subject.fund &&
    (isFundOutOfDate || isMasterConstraining || isPeriodManuallyConstrained || canInterpolate || loading)
  ) {
    const showViewMasterAction =
      !loading &&
      !isFundOutOfDate &&
      !isPeriodManuallyConstrained &&
      !canInterpolate &&
      isMasterConstraining &&
      !(subject.superType === 'portfolio' && subject.portfolio?.master);
    return (
      <EmptyMasterWithCTA>
        <EmptyState
          header="Couldn't calculate correlations"
          message={
            loading ? (
              <Loading title="Loading details..." />
            ) : isPeriodManuallyConstrained ? (
              <ErrorInvalidAnalysisPeriod
                defaultMessage="Your selected analysis period may be constraining correlation analysis from running."
                onResetAnalysisPeriod={onResetTimeFrame}
              />
            ) : (
              <>
                {error.message}
                {!showViewMasterAction && (
                  <StyledEmptyStateContent>
                    Suggested actions:{' '}
                    <InvestmentActions
                      context="error"
                      fund={subject.fund}
                      onFundDataUpdated={onFundDataUpdated}
                      availableStart={trackingProps?.analysesPeriod?.maxStartTime}
                      availableEnd={trackingProps?.analysesPeriod?.maxEndTime}
                    />
                  </StyledEmptyStateContent>
                )}
              </>
            )
          }
          actionLabel={showViewMasterAction ? 'View Master Holdings' : undefined}
          onAction={showViewMasterAction ? redirectToMasterHoldings : undefined}
        />
      </EmptyMasterWithCTA>
    );
  }

  return (
    <FailedAnalysisInfo
      subject={subject}
      relativeToBenchmark={trackingProps.relativeToBenchmark}
      categoryActive={categoryActive}
      analysesPeriod={trackingProps.analysesPeriod}
      error={error}
      regressionName="Correlation"
      onResetAnalysisPeriod={onResetTimeFrame}
      trackingId={trackingProps.trackingId}
      updateAnalysisStatusForTracking={trackingProps.updateAnalysisStatusForTracking}
      blockNames={trackingProps.blockNames}
      blockTitles={trackingProps.blockTitles}
    />
  );
};

export default withRouter(CorrelationError);

const EmptyMasterWithCTA = styled.div`
  margin: 20px;
`;

const StyledEmptyStateContent = styled(EmptyStateContent)`
  font-size: 14px;
  display: flex;
  align-items: center;
  > :last-child {
    margin-left: 12px;
  }
  @media print {
    display: none;
  }
`;

function getIsMasterConstraining(masterRangeDebug?: RangeDebugResponse, rangeDebugGroup?: RangeDebugGroup): boolean {
  const masterConstrainingRanges =
    masterRangeDebug?.constrainingPrimaryStart || masterRangeDebug?.constrainingPrimaryEnd;
  const masterConstrainingFrequency = isMasterConstrainingFrequency(masterRangeDebug, rangeDebugGroup);
  return masterConstrainingRanges || masterConstrainingFrequency || !!masterRangeDebug?.insufficientRange;
}

function isMasterConstrainingFrequency(
  masterRangeDebug?: RangeDebugResponse,
  rangeDebugGroup?: RangeDebugGroup,
): boolean {
  if (!masterRangeDebug || !rangeDebugGroup) {
    return false;
  }
  if (!masterRangeDebug.constrainingPrimaryFrequency) {
    return false;
  }

  const analysisSubjectsFrequency = getMaxFrequency(
    compact([rangeDebugGroup.primary.response?.maxFrequency, rangeDebugGroup.benchmark?.response?.maxFrequency]),
  );
  const masterFrequency = masterRangeDebug?.maxFrequency;

  return (
    (analysisSubjectsFrequency === 'DAILY' || analysisSubjectsFrequency === 'MONTHLY') &&
    masterFrequency !== 'DAILY' &&
    masterFrequency !== 'MONTHLY'
  );
}

function isAnalysisPeriodManuallyConstrained(timeFrame: TimeFrame, analysesPeriod?: AnalysesPeriod): boolean {
  const { startTime, endTime } = timeFrame;
  if (!analysesPeriod || (startTime === undefined && endTime === undefined)) {
    return false;
  }

  const { maxStartTime, maxEndTime } = analysesPeriod;
  if (isNil(maxStartTime) && isNil(maxEndTime)) {
    return false;
  }

  if (isNil(maxStartTime)) {
    assert(maxEndTime !== undefined);
    return !isNil(endTime) && endTime < maxEndTime;
  }

  if (isNil(maxEndTime)) {
    assert(maxStartTime !== undefined);
    return !isNil(startTime) && maxStartTime < startTime;
  }

  return (!isNil(startTime) && maxStartTime < startTime) || (!isNil(endTime) && endTime < maxEndTime);
}
