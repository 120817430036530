import React from 'react';
import styled, { css } from 'styled-components';
import { GetColor } from 'venn-ui-kit';
import { useAppPrintMode } from '../../print/AppPrintMode';

interface PageBreakProps {
  active?: boolean;
}

export const PageBreak = ({ active }: PageBreakProps) => {
  const { inPrintMode } = useAppPrintMode();
  if (inPrintMode) {
    return null;
  }

  return (
    <div data-testid="page-break">
      <PageBreakBlock active={active}>
        <Side>
          <Dots />
        </Side>
        <ButtonWrapper>
          <Title>Page Break</Title>
        </ButtonWrapper>
        <Side>
          <Dots />
        </Side>
      </PageBreakBlock>
    </div>
  );
};

const Title = styled.p`
  margin: 0;
  cursor: default;
  text-transform: uppercase;
  height: 22px;
  padding: 0 15px;
  font-size: 11px;
  white-space: nowrap;
  color: ${GetColor.GreyScale.Grey50};
  font-weight: 700;
  line-height: 22px;
  transition: color 200ms linear;
`;

const BlockControlsWrapper = styled.div`
  margin-left: 20px;
  display: none;
`;

const PageBreakBlock = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px 0;

  ${({ active }) => css`
    ${active ? '' : '&:hover {'}
    ${Title} {
      color: ${GetColor.GreyScale.Grey80};
    }

    ${BlockControlsWrapper} {
      display: block;
    }
    ${!active && '}'}
  `}

  @media print {
    display: none;
  }
`;

const ButtonWrapper = styled.div`
  margin: 0 20px;
`;

const Side = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const Dots = styled.div`
  border: 1px dashed ${GetColor.GreyScale.Grey40};
  height: 1px;
  width: 100%;
`;
