import React from 'react';
import styled from 'styled-components';
import type { AnalysisSubject } from 'venn-utils';
import { hasAnalysisSubjectNoReturns } from 'venn-utils';
import { Col, Container, Headline2, Row, BrandLogo } from 'venn-ui-kit';
import type { SearchMenuItem } from '../search-menu';
import { MultiSelectSearch } from '../search-menu';
import { isEmpty, compact } from 'lodash';

export const MAX_SUBJECTS = 30;

interface EmptySubjectsProps {
  header: string;
  /** Used in search tracking  */
  location: string;
  onAddSubject: (subjects: AnalysisSubject[]) => void;
}

const EmptySubjects: React.FC<React.PropsWithChildren<EmptySubjectsProps>> = ({ header, location, onAddSubject }) => {
  return (
    <Wrapper data-testid="qa-empty-subjects-wrapper">
      <BrandLogo height={300} isBackground scaleToFit />
      <Container fluid>
        <Row>
          <Col xs={{ span: 8, offset: 2 }}>
            <Headline2 center>{header}</Headline2>
            <MultiSelectSearch
              onSelected={(selections: SearchMenuItem[]) => {
                if (!isEmpty(selections)) {
                  const selectedItems = compact(selections.map((s) => s.value));

                  onAddSubject(selectedItems);
                }
              }}
              optionDisabledTooltipContent={(option) =>
                hasAnalysisSubjectNoReturns(option.value) ? 'This item has no returns and cannot be analyzed' : ''
              }
              isOptionDisabled={(option) => hasAnalysisSubjectNoReturns(option.value)}
              location={location}
              maxSelections={MAX_SUBJECTS}
              privateAssetSearchMode="PUBLIC_ONLY"
            />
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 100px;
  padding-top: 120px;
  position: relative;
  height: 75vh;
  width: 100%;
`;

export default EmptySubjects;
