import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import { GetColor } from '../../../style/color';
import { Link as LinkStyle } from '../../typography';

export interface CheckboxMenuSelectAllProps {
  onClick: () => void;
  allItemsSelected: boolean;
}

const CheckboxMenuSelectAll: FC<React.PropsWithChildren<CheckboxMenuSelectAllProps>> = ({
  allItemsSelected,
  onClick,
}) => (
  <Item type="button" className="qa-dropmenu-select-all" onClick={onClick}>
    <LinkStyle>{allItemsSelected ? 'Deselect all' : 'Select all'}</LinkStyle>
  </Item>
);

const Item = styled.button`
  width: 100%;
  height: 37px;
  min-height: 37px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${GetColor.PaleGrey};
`;

export default CheckboxMenuSelectAll;
