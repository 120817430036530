import React, { useContext } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { AddToPortfolioModal, PortfoliosContext } from 'venn-components';
import { TooltipPosition } from 'venn-ui-kit';
import { useModal } from 'venn-utils';
import type { PrivatesTableRow } from '../privatesColumns';
import { StyledButtonIcon } from './styles';
import type { PrivateBulkActionProps } from './types';

interface AddToPortfolioProps extends PrivateBulkActionProps, RouteComponentProps {}

// eslint-disable-next-line unused-imports/no-unused-vars
const AddToPortfolio = ({ items, history, onComplete }: AddToPortfolioProps) => {
  const [isOpen, open, close] = useModal();
  const { refresh } = useContext(PortfoliosContext);

  const portfolioButtonText = 'Add to Portfolio';
  const investments = getFunds(items);

  // if the action can't be performed on any of the selected items, don't show the option
  if (!investments.length) {
    return null;
  }

  const disabled = investments.length < items.length;
  const tooltip = disabled ? 'Only investments can be added to a portfolio.' : undefined;

  return (
    <>
      <StyledButtonIcon
        tooltip={tooltip}
        tooltipPosition={TooltipPosition.Bottom}
        onClick={open}
        iconType="th"
        disabled={disabled}
        text={`${portfolioButtonText} (${items.length})`}
        className="qa-bulk-add-to-portfolio"
      />

      {isOpen && (
        <AddToPortfolioModal
          investments={investments}
          onCancel={close}
          refreshPortfolios={refresh}
          onComplete={() => onComplete('Add to Portfolio', close)}
          privatesOnly
        />
      )}
    </>
  );
};

export default withRouter(AddToPortfolio);

const getFunds = (items: PrivatesTableRow[]) => items.filter((item) => item.itemType === 'INVESTMENT' && item.id);
