import React, { useState } from 'react';
import {
  Modal,
  ModalContent,
  ModalDescription,
  ModalFooter,
  ModalHeader,
  ModalSize,
  RequiredLabel,
  StatefulInput,
  ConditionalOverlay,
} from 'venn-components';
import styled from 'styled-components';
import useSendSponsorInvitation from './logic/useSendSponsorInvitation';
import { getAppTitle } from 'venn-ui-kit';

interface SponsorInvitationModalProps {
  onClose: () => void;
  refreshAccounts: () => void;
}

const SponsorInvitationModal = ({ onClose, refreshAccounts }: SponsorInvitationModalProps) => {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [accountName, setAccountName] = useState<string>('');

  const { error, loading, send } = useSendSponsorInvitation(
    firstName,
    lastName,
    email,
    accountName,
    onClose,
    refreshAccounts,
  );

  return (
    <Modal onClose={onClose} size={ModalSize.Large}>
      <ConditionalOverlay center condition={loading}>
        <ModalHeader>Sponsor Account</ModalHeader>
        <ModalDescription>
          {`If the user already has a ${getAppTitle()} account, they will be sent a code to link their account to be sponsored. Else,
          they will be sent a email to create a new sponsored account.`}
        </ModalDescription>
        <ModalContent>
          <FieldWrapper>
            <RequiredLabel required>First Name:</RequiredLabel>
            <FieldInput autoFocus value={firstName} onChange={setFirstName} />
          </FieldWrapper>
          <FieldWrapper>
            <RequiredLabel required>Last Name:</RequiredLabel>
            <FieldInput value={lastName} onChange={setLastName} />
          </FieldWrapper>
          <FieldWrapper>
            <RequiredLabel required>Email:</RequiredLabel>
            <FieldInput value={email} onChange={setEmail} />
          </FieldWrapper>
          <FieldWrapper>
            <RequiredLabel required>Account Name:</RequiredLabel>
            <FieldInput value={accountName} onChange={setAccountName} />
          </FieldWrapper>
        </ModalContent>
        <ModalFooter
          primaryLabel="Send Invitation"
          primaryDisabled={!!error}
          primaryTooltip={error}
          onPrimaryClick={send}
          onCancel={onClose}
        />
      </ConditionalOverlay>
    </Modal>
  );
};

export default SponsorInvitationModal;

const FieldInput = styled(StatefulInput)`
  display: flex;
`;

const FieldWrapper = styled.div`
  margin-top: 20px;
`;
