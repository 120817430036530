// Adapted from https://github.com/sunify/react-relative-portal

import React from 'react';
import ReactDOM from 'react-dom';
import type { AnyDuringEslintMigration } from 'venn-utils';

interface PortalProps {
  onOutClick?: (e: unknown) => void;
}

export default class Portal extends React.Component<React.PropsWithChildren<PortalProps>> {
  node: HTMLDivElement;

  root: HTMLElement | null;

  handleRootRef: (root: HTMLElement | null) => void;

  handleOutClick: (e: AnyDuringEslintMigration) => void;

  constructor(props: PortalProps) {
    super(props);

    this.node = document.createElement('div');
    this.root = null;
    this.handleRootRef = (root) => {
      this.root = root;
    };

    this.handleOutClick = (e) => {
      const { onOutClick } = this.props;
      if (typeof onOutClick === 'function') {
        if (this.root && !this.root.contains(e.target)) {
          onOutClick(e);
        }
        if (!this.root) {
          onOutClick(e);
        }
      }
    };

    document.addEventListener('click', this.handleOutClick, true);
  }

  componentDidMount() {
    document.body.appendChild(this.node);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutClick, true);
    document.body.removeChild(this.node);
  }

  render() {
    const { onOutClick, ...restProps } = this.props;
    return ReactDOM.createPortal(<div {...restProps} ref={this.handleRootRef} />, this.node);
  }
}
