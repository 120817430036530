import type { AnalysisSubject, AnyDuringEslintMigration } from 'venn-utils';
import { Dates, FREQUENCY_TO_ENUM, getAnalysisRequest } from 'venn-utils';
import { useEffect, useState } from 'react';
import type { FrequencyEnum } from 'venn-api';
import { analysis } from 'venn-api';
import { isNil } from 'lodash';

export const getSubjectRange = (start?: number, end?: number, frequency?: FrequencyEnum, loading?: boolean): string => {
  if (loading) {
    return '';
  }
  if (!isNil(start) && !isNil(end) && !isNil(frequency)) {
    return `${Dates.toDDMMMYYYY(start, frequency, true)} - ${Dates.toDDMMMYYYY(end, frequency, true)}`;
  }
  return '-- - --';
};

const useSubjectAvailableRange = (subject?: AnalysisSubject) => {
  const [start, setStart] = useState<number | undefined>();
  const [end, setEnd] = useState<number | undefined>();
  const [frequency, setFrequency] = useState<FrequencyEnum | undefined>();
  const [rangeLoading, setRangeLoading] = useState(false);

  useEffect(() => {
    const fetchRange = async () => {
      setRangeLoading(true);
      const analysisRequest = getAnalysisRequest(
        ['RANGE_ANALYSIS'],
        subject,
        {},
        false,
        false,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        true,
      );
      try {
        const { content } = await analysis(analysisRequest);
        setStart(content.maxStartTime);
        setEnd(content.maxEndTime);
        setFrequency(content.maxFrequency);
      } catch (e) {
        setStart(undefined);
        setEnd(undefined);
        setFrequency(undefined);
      }
      setRangeLoading(false);
    };
    if (subject?.type === 'investment' && subject.fund) {
      setStart(subject.fund.startRange);
      setEnd(subject.fund.endRange);
      setFrequency(FREQUENCY_TO_ENUM[subject.fund.frequency]);
      return;
    }
    if (subject?.type === 'portfolio') {
      // When the portfolio has been added from search, it doesn't have the proper Portfolio shape.
      const { startRange, endRange, frequency: portfolioFrequency } = subject.portfolio as AnyDuringEslintMigration;
      if (!isNil(startRange) && !isNil(endRange) && !isNil(portfolioFrequency)) {
        setStart(startRange);
        setEnd(endRange);
        setFrequency(portfolioFrequency);
        return;
      }
    }
    fetchRange();
  }, [subject]);

  return {
    rangeLoading,
    formattedRange: getSubjectRange(start, end, frequency === 'QUARTERLY' ? 'MONTHLY' : frequency, rangeLoading),
  };
};

export default useSubjectAvailableRange;
