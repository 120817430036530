import type { DateRange, Granularity } from '../types';
import type { RangeType } from '../range-picker/RangePicker';
import moment from 'moment';
import { assertExhaustive, FS } from 'venn-utils';
import { getFactorMaxRange } from '../constants';

export function isEndOfPeriod(date: number, granularity: Granularity) {
  return (
    moment.utc(date).add(1, 'millisecond').subtract(1, granularity).valueOf() ===
    moment.utc(date).startOf(granularity).valueOf()
  );
}

function isBeginningOfPeriod(date: number, granularity: Granularity) {
  return moment.utc(date).valueOf() === moment.utc(date).startOf(granularity).valueOf();
}

export const constrainRangeToGranularity = (
  range: DateRange | undefined,
  granularity: Granularity,
): DateRange | undefined => {
  if (!range || !range.to || !range.from) {
    return undefined;
  }

  let from = moment.utc(range.from);
  let to = moment.utc(range.to);

  if (isEndOfPeriod(range.from, granularity)) {
    from = from.startOf(granularity);
  } else {
    from = from.subtract(1, 'millisecond').add(1, granularity).startOf(granularity);
  }

  if (!isBeginningOfPeriod(range.to, granularity)) {
    to = to.add(1, 'millisecond').subtract(1, granularity).startOf(granularity);
  }

  return {
    from: from.valueOf(),
    to: to.valueOf(),
  };
};

export const validate = (value: DateRange, maxRange?: DateRange) => {
  const min = moment.utc('1900-01-01', 'YYYY-MM-DD').valueOf();
  const origin = maxRange && maxRange.to ? maxRange.to : moment.utc().endOf('day').valueOf();

  if (value.from && value.to && value.from > value.to) {
    return false;
  }
  if (value.from && value.to && (value.from < min || value.from > origin || value.to < min || value.to > origin)) {
    return false;
  }
  if (!maxRange) {
    return value && !!value.from && !!value.to;
  }

  if (
    value.from &&
    value.to &&
    maxRange.from &&
    maxRange.to &&
    (value.from < maxRange.from || value.to > maxRange.to)
  ) {
    return false;
  }

  return true;
};

export const getRangeFromType = (
  rangeType: RangeType | undefined | null,
  maxRange: DateRange,
  granularity: Granularity,
): DateRange => {
  // TODO(willw): Make rangeType always be defined
  if (!rangeType) {
    return maxRange;
  }
  const originDate = moment.utc(maxRange.to);
  switch (rangeType) {
    case 'ytd':
      return {
        from: originDate.clone().startOf('year').valueOf(),
        to: originDate.valueOf(),
        period: rangeType,
      };
    case '1yr':
      return {
        from: originDate.clone().subtract(1, 'year').add(1, granularity).valueOf(),
        to: originDate.valueOf(),
        period: rangeType,
      };
    case '3yr':
      return {
        from: originDate.clone().subtract(3, 'year').add(1, granularity).valueOf(),
        to: originDate.valueOf(),
        period: rangeType,
      };
    case '5yr':
      return {
        from: originDate.clone().subtract(5, 'year').add(1, granularity).valueOf(),
        to: originDate.valueOf(),
        period: rangeType,
      };
    case '7yr':
      return {
        from: originDate.clone().subtract(7, 'year').add(1, granularity).valueOf(),
        to: originDate.valueOf(),
        period: rangeType,
      };
    case '10yr':
      return {
        from: originDate.clone().subtract(10, 'year').add(1, granularity).valueOf(),
        to: originDate.valueOf(),
        period: rangeType,
      };
    case 'full':
      const defaultMaxRange = getFactorMaxRange();
      return FS.has('extend_full_history_ff')
        ? {
            to: Math.min(defaultMaxRange.to, maxRange.to ?? Infinity),
            from: Math.max(defaultMaxRange.from, maxRange.from ?? -Infinity),
            period: rangeType,
          }
        : { ...maxRange, period: rangeType };
    case 'full_no_factor_constraint':
      return { ...maxRange, period: rangeType };
    default:
      throw assertExhaustive(rangeType);
  }
};

export const getRangeFromString = (range?: string): RangeType | undefined =>
  getRangeFromType(range as RangeType, {}, 'day').period;

export const getTypeFromRange = (
  granularity: Granularity,
  value?: DateRange,
  maxRange?: DateRange,
): RangeType | undefined => {
  if (!value || !maxRange) {
    return undefined;
  }

  if (value.period) {
    return value.period;
  }

  const fromDate = moment.utc(value.from);
  const toDate = moment.utc(value.to);

  if (maxRange.from === value.from && maxRange.to === value.to) {
    return FS.has('extend_full_history_ff') ? 'full_no_factor_constraint' : 'full';
  }
  const defaultMaxRange = getFactorMaxRange();
  if (FS.has('extend_full_history_ff') && defaultMaxRange.from === value.from && defaultMaxRange.to === value.to) {
    return 'full';
  }

  if (maxRange.to === value.to && value.from === moment.utc(value.to).startOf('year').valueOf()) {
    return 'ytd';
  }

  if (value.to !== maxRange.to) {
    return undefined;
  }

  fromDate.subtract(1, granularity);

  if (fromDate.date() !== toDate.date() || fromDate.month() !== toDate.month()) {
    return undefined;
  }
  const yearDiff = toDate.year() - fromDate.year();

  switch (yearDiff) {
    case 1:
      return '1yr';
    case 3:
      return '3yr';
    case 5:
      return '5yr';
    case 7:
      return '7yr';
    case 10:
      return '10yr';
    default:
      return undefined;
  }
};

export const getRangeTypeShortName = (rangeType: RangeType) => {
  switch (rangeType) {
    case 'full_no_factor_constraint':
      return 'Full History';
    case 'full':
      return FS.has('extend_full_history_ff') ? 'Factor Lens History' : 'Full History';
    case 'ytd':
      return 'YTD';
    case '1yr':
    case '3yr':
    case '5yr':
    case '7yr':
    case '10yr':
      return rangeType.toUpperCase();
    default:
      return assertExhaustive(rangeType);
  }
};

export const getRangeTypeName = (rangeType: RangeType) => {
  switch (rangeType) {
    case 'full_no_factor_constraint':
    case 'full':
    case 'ytd':
      return getRangeTypeShortName(rangeType);
    case '1yr':
      return '1 Year';
    case '3yr':
      return '3 Year';
    case '5yr':
      return '5 Year';
    case '7yr':
      return '7 Year';
    case '10yr':
      return '10 Year';
    default:
      return assertExhaustive(rangeType);
  }
};
