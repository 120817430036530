import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';

import { GetColor } from 'venn-ui-kit';

interface CharactersInputCounterProps {
  charactersLimit: number;
  isFocused: boolean;
  inputValue?: string;
}

const CharactersInputCounter: FC<React.PropsWithChildren<CharactersInputCounterProps>> = ({
  charactersLimit,
  inputValue,
  isFocused,
}) => {
  const currentCharacterCount = inputValue?.length ?? 0;

  return (
    <CharactersCounterWrapper>
      <CurrentCharactersCountWrapper charactersLimit={charactersLimit} inputValue={inputValue} isFocused={isFocused}>
        {currentCharacterCount}
      </CurrentCharactersCountWrapper>
      /{charactersLimit}
    </CharactersCounterWrapper>
  );
};

export default CharactersInputCounter;

const CharactersCounterWrapper = styled.div`
  padding: 0 13px;
  font-size: 14px;
  font-weight: bold;
`;

const getCounterOnFocusColor = (charactersLimit: number, isFocused: boolean, inputValue?: string) => {
  const closeToLimit = 10;

  if (!isFocused) {
    return GetColor.DarkGrey;
  }
  if (!inputValue) {
    return GetColor.HighlightDark;
  }
  if (charactersLimit - inputValue.length <= closeToLimit) {
    return GetColor.Error;
  }

  return GetColor.HighlightDark;
};

const CurrentCharactersCountWrapper = styled.span<{ charactersLimit: number; isFocused: boolean; inputValue?: string }>`
  color: ${(props) => getCounterOnFocusColor(props.charactersLimit, props.isFocused, props.inputValue)};
`;
