/*
 * Venn Platform API 1.0.0 Operations for "svg2png"
 * Automatically generated file - do not modify!
 */

import type { OperationResult } from '../../utils';
import { buildRequestInit, extractError } from '../../utils';

/** Convert svg to png */
export async function convertSvgToPng(body?: any, signal?: AbortSignal): Promise<OperationResult<Blob>> {
  const endpoint = '/api/svg2png';
  const requestInit = buildRequestInit({ method: 'POST', body, signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: Blob = (await response.blob()) as Blob;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
