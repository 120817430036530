import React from 'react';
import styled from 'styled-components';
import { DropdownShimmer, DropMenu, type DropMenuItem, LabelShimmer } from 'venn-ui-kit';
import type { ScenarioAnalysisIndex } from 'venn-api';
import { StyledLabel } from '../../../../shared';

type ScenarioDropdownProps = {
  label: string;
  onChange: (item: DropMenuItem<ScenarioAnalysisIndex>) => void;
  disabled: boolean;
  items: DropMenuItem<ScenarioAnalysisIndex>[];
  placeholder: string;
};

export const ScenarioDropdown = ({ label, onChange, disabled, items, placeholder }: ScenarioDropdownProps) => (
  <DropdownWrapper data-testid="scenario-dropdown">
    <StyledLabel>{label}</StyledLabel>
    <DropMenu
      searchable
      items={items}
      onChange={onChange}
      placeholder={placeholder}
      className="dropdown"
      style={{ width: '500px' }}
      portalAnchorPlacement="right"
      disabled={disabled}
      usePortal
    />
  </DropdownWrapper>
);

export const ScenarioDropdownShimmer = () => (
  <DropdownWrapper>
    <LabelShimmer />
    <DropdownShimmer />
  </DropdownWrapper>
);

const DropdownWrapper = styled.div`
  max-width: 600px;
  .dropdown {
    min-width: auto;
  }
  margin-bottom: 16px;
`;
