import React from 'react';
import type { BasicTableColumn } from '../../../basictable/BasicTable';
import { ColumnAlign, SORTDIR } from '../../../basictable/BasicTable';
import { GetColor, Tooltip, TooltipBodyDirection, TooltipContentContainerStyled } from 'venn-ui-kit';
import { compact } from 'lodash';
import styled, { css } from 'styled-components';
import SpecificFactorForecastActions from './SpecificFactorForecastActions';
import SpecificFactorForecastAnnualizedReturnContent from './SpecificFactorForecastAnnualizedReturnContent';
import { assertExhaustive } from 'venn-utils';
import type { FactorNameType, FactorOverrideType, PartialFactorForecast } from 'venn-state';
import { roundTo2DecimalPlaces } from 'venn-state';

type State = {
  factorTextboxIsEditing: { [name: FactorNameType]: boolean };
  setFactorTextboxIsEditing: React.Dispatch<React.SetStateAction<{ [name: FactorNameType]: boolean }>>;
  factorCurrentPendingOverride: { [name: FactorNameType]: FactorOverrideType };
  setFactorCurrentPendingOverride: React.Dispatch<React.SetStateAction<{ [name: FactorNameType]: FactorOverrideType }>>;
  factorEditValueBuffer: { [name: FactorNameType]: string };
  setFactorEditValueBuffer: React.Dispatch<React.SetStateAction<{ [name: FactorNameType]: string }>>;
  hoveredFactor: FactorNameType | undefined;
  setHoveredFactor: (hoveredFactor: FactorNameType | undefined) => void;
};

export const getColumnsUsingState =
  (state: State) =>
  ({
    allowContextMarker,
    isForecastEditable,
  }: {
    allowContextMarker: boolean;
    isForecastEditable: boolean;
  }): BasicTableColumn<PartialFactorForecast>[] => {
    const {
      factorTextboxIsEditing,
      setFactorTextboxIsEditing,
      factorCurrentPendingOverride,
      setFactorCurrentPendingOverride,
      factorEditValueBuffer,
      setFactorEditValueBuffer,
      hoveredFactor,
      setHoveredFactor,
    } = state;
    const setCurrentPendingOverride =
      ({ name }: PartialFactorForecast) =>
      (override: FactorOverrideType) =>
        setFactorCurrentPendingOverride((prevState) => ({
          ...prevState,
          [name]: override,
        }));
    const currentPendingOverride = ({ name }: PartialFactorForecast) => factorCurrentPendingOverride[name];
    const setIsTextboxEditing =
      ({ name }: PartialFactorForecast) =>
      (isTextboxEditing: boolean) =>
        setFactorTextboxIsEditing((prevState) => ({
          ...prevState,
          [name]: isTextboxEditing,
        }));
    const setEditValueBuffer =
      ({ name }: PartialFactorForecast) =>
      (value: string) =>
        setFactorEditValueBuffer((prevState) => ({
          ...prevState,
          [name]: value,
        }));
    const editValueBuffer = ({ name }: PartialFactorForecast) => factorEditValueBuffer[name];
    const isEditing = ({ name }: PartialFactorForecast) => factorTextboxIsEditing[name];
    // quirk: ideally, we'd be handling onmouseenter/onmouseleave on each row
    // rather than on each cell, but there's no easy way to do this given
    // we're handling cell renders by columns with BasicTable
    const getHoverProps = (name: FactorNameType) => ({
      highlighted: hoveredFactor === name,
      onMouseEnter: () => setHoveredFactor(name),
      onMouseLeave: () => setHoveredFactor(undefined),
    });
    const nameColumn: BasicTableColumn<PartialFactorForecast> = {
      label: 'Factor',
      accessor: 'name',
      sortable: true,
      cellRenderer: ({ forecastContext, computedByCustomForecast, name }) => (
        <Cell
          {...getHoverProps(name)}
          greyedOut={allowContextMarker && !computedByCustomForecast}
          alignment={ColumnAlign.LEFT}
        >
          <FactorName>
            {allowContextMarker && !computedByCustomForecast ? (
              <StyledTooltip
                content={forecastContext === 'HISTORICAL_FORECASTS' ? 'Historical Forecast' : 'Two Sigma Forecast'}
                bodyDirection={TooltipBodyDirection.Right}
              >
                {name}
              </StyledTooltip>
            ) : (
              <div>{name}</div>
            )}
          </FactorName>
        </Cell>
      ),
      headerRenderer: (label: string) => <>{label}</>,
    };
    const annualizedReturnColumn: BasicTableColumn<PartialFactorForecast> = {
      label: 'Forecast',
      accessor: 'annualizedReturn',
      align: ColumnAlign.RIGHT,
      sortable: true,
      sorted: SORTDIR.DESC,
      sortValueFunc: ({ annualizedReturn, annualizedReturnOverride }: PartialFactorForecast) =>
        annualizedReturnOverride ?? annualizedReturn,
      cellRenderer: (partialForecast) => {
        const { computedByCustomForecast, name } = partialForecast;
        return (
          <Cell
            {...getHoverProps(name)}
            greyedOut={allowContextMarker && !computedByCustomForecast}
            alignment={ColumnAlign.RIGHT}
          >
            <SpecificFactorForecastAnnualizedReturnContent
              partialForecast={partialForecast}
              isForecastEditable={isForecastEditable}
              setCurrentPendingOverride={setCurrentPendingOverride(partialForecast)}
              currentPendingOverride={currentPendingOverride(partialForecast)}
              editValueBuffer={editValueBuffer(partialForecast)}
              setEditValueBuffer={setEditValueBuffer(partialForecast)}
              isEditing={isEditing(partialForecast)}
            />
          </Cell>
        );
      },
    };
    const volatilityColumn: BasicTableColumn<PartialFactorForecast> = {
      label: 'Forecast Volatility',
      accessor: 'annualizedVolatility',
      align: ColumnAlign.RIGHT,
      sortable: true,
      cellRenderer: ({ computedByCustomForecast, annualizedVolatility, name }) => (
        <Cell
          {...getHoverProps(name)}
          greyedOut={allowContextMarker && !computedByCustomForecast}
          alignment={ColumnAlign.RIGHT}
        >
          {(annualizedVolatility * 100).toFixed(2)}%
        </Cell>
      ),
    };
    const actionsColumn: BasicTableColumn<PartialFactorForecast> = {
      align: ColumnAlign.CENTER,
      sortable: false,
      cellRenderer: (partialForecast) => (
        <Cell {...getHoverProps(partialForecast.name)} greyedOut={false} alignment={ColumnAlign.RIGHT}>
          <SpecificFactorForecastActions
            partialForecast={partialForecast}
            setCurrentPendingOverride={setCurrentPendingOverride(partialForecast)}
            currentPendingOverride={currentPendingOverride(partialForecast)}
            setIsTextboxEditing={setIsTextboxEditing(partialForecast)}
            isEditing={isEditing(partialForecast)}
            setEditValueBuffer={setEditValueBuffer(partialForecast)}
            roundNumberToString={roundTo2DecimalPlaces}
            isVisible={hoveredFactor === partialForecast.name}
          />
        </Cell>
      ),
    };
    return compact([nameColumn, annualizedReturnColumn, volatilityColumn, isForecastEditable && actionsColumn]);
  };

const Cell = styled.div<{
  greyedOut: boolean;
  highlighted: boolean;
  alignment: ColumnAlign;
}>`
  color: ${({ greyedOut }) => (greyedOut ? GetColor.MidGrey2 : GetColor.Black)};
  ${({ highlighted }) =>
    highlighted &&
    css`
      background-color: ${GetColor.GreyScale.Grey10};
    `}
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: ${({ alignment }) => {
    switch (alignment) {
      case ColumnAlign.LEFT:
        return 'flex-start';
      case ColumnAlign.RIGHT:
        return 'flex-end';
      case ColumnAlign.CENTER:
        return 'center';
      default:
        throw assertExhaustive(alignment, 'Unexpected column alignment');
    }
  }};
`;

const FactorName = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTooltip = styled(Tooltip)`
  > div {
    ${TooltipContentContainerStyled} {
      margin-left: -12px;
    }
  }
`;
