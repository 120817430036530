import { useCallback, useEffect, useState } from 'react';
import type {
  InvestmentSourceTypeEnum,
  AutocompleteResponse,
  AutocompleteSearchRequest,
  ItemTypeEnum,
  OperationResult,
  SearchFund,
} from 'venn-api';
import { autocompleteSearch } from 'venn-api';
import { useApi } from 'venn-utils';
import { debounce, compact } from 'lodash';

type AbortableAutocompleteType = (
  body?: Partial<AutocompleteSearchRequest>,
  signal?: AbortSignal,
) => Promise<OperationResult<AutocompleteResponse>>;

const debouncedSearch = debounce(
  async (
    query: string,
    setLoading: (isLoading: boolean) => void,
    onResults: (results: SearchFund[]) => void,
    abortableAutocomplete: AbortableAutocompleteType,
    excludedFundIds: string[] = [],
    recentUploads?: boolean,
  ) => {
    const investmentParams = recentUploads ? { investmentSources: ['UPLOAD' as InvestmentSourceTypeEnum] } : {};
    try {
      setLoading(true);
      const response = await abortableAutocomplete({
        query,
        scopes: ['INVESTMENT'],
        excludedItems: excludedFundIds.map((fundId) => ({
          id: fundId,
          type: 'venn_fund' as ItemTypeEnum,
        })),
        count: 100,
        investmentParams,
      });
      onResults(compact(response.content.results.map(({ investment }) => investment)));
      setLoading(false);
    } catch (e) {
      // Fail silently (but cease loading if there was an actual failure)
      if (e.name !== 'AbortError') {
        setLoading(false);
      }
    }
  },
  500,
);

export default (excludedFundIds?: string[], recentUploads?: boolean) => {
  const [query, setQuery] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [results, setResults] = useState<SearchFund[] | undefined>();
  const abortableAutocomplete = useApi(autocompleteSearch);

  useEffect(() => {
    debouncedSearch(query, setLoading, setResults, abortableAutocomplete, excludedFundIds, recentUploads);
  }, [abortableAutocomplete, excludedFundIds, query, recentUploads]);

  const onSearch = useCallback((newSearch: string) => {
    setQuery(newSearch);
  }, []);

  return { query, loading, results, onSearch };
};
