import React, { useCallback } from 'react';
import styled from 'styled-components';
import flow from 'lodash/flow';
import Input from '../../input/Input';
import { GetColor, Icon, TextAlign } from 'venn-ui-kit';
import Name from './create-composite/Name';
import BenchmarksList from './create-composite/BenchmarksList';
import { computeTotalWeight, formatTotalWeight } from './create-composite/utils/weight';
import type { BenchmarkShape, CreateCompositeBenchmarkTabState } from './types';
import { INITIAL_BENCHMARK_STATE } from './types';
import BenchmarksLabels from './create-composite/BenchmrksLabels';
import type { SearchMenuItem } from 'venn-components';

const AddBenchmark = styled.div`
  padding: 20px 0 25px 0;

  > button {
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    display: flex;
    align-items: center;
  }

  > span {
    cursor: pointer;
    color: ${GetColor.Primary.Main};
  }
`;

const Plus = styled(Icon)`
  font-size: 24px;
  font-weight: normal;
  margin-right: 5px;
`;

const TotalWeight = styled.div`
  display: flex;
  flex-direction: column;
  width: 56px;

  > input {
    color: #757575;
    font-weight: normal;
    text-align: right;
  }
`;

const TotalWeightLabel = styled.div`
  margin-bottom: 4px;
  color: ${GetColor.Black};
  font-size: 11px;
  font-weight: bold;
`;

const qaClass = 'qa-new-fund';

const getFormattedTotalWeight = flow(computeTotalWeight, formatTotalWeight);

export type CreateCompositeBenchmarkTabStateUpdater = (
  state: CreateCompositeBenchmarkTabState,
) => CreateCompositeBenchmarkTabState;

export interface CompositeBenchmarkEditorProps {
  onUpdate(updater: CreateCompositeBenchmarkTabStateUpdater): void;
  composite: CreateCompositeBenchmarkTabState;
  resultsFilter(item: SearchMenuItem): boolean;
}

const CompositeBenchmarkEditor = ({ onUpdate, composite, resultsFilter }: CompositeBenchmarkEditorProps) => {
  const { name, benchmarks } = composite;

  const onUpdateBenchmark = useCallback(
    (index: number, modifier: (benchmark: BenchmarkShape) => BenchmarkShape) =>
      onUpdate((prevState) => ({
        ...prevState,
        benchmarks: prevState.benchmarks.map((benchmark, i) => (index === i ? modifier(benchmark) : benchmark)),
      })),
    [onUpdate],
  );

  const onRemoveBenchmark = useCallback(
    (index: number) =>
      onUpdate((prevState) => ({
        ...prevState,
        benchmarks: [...prevState.benchmarks.slice(0, index), ...prevState.benchmarks.slice(index + 1)],
      })),
    [onUpdate],
  );

  const onAddNewFund = useCallback(
    () =>
      onUpdate((prevState) => ({
        ...prevState,
        benchmarks: [...prevState.benchmarks, INITIAL_BENCHMARK_STATE],
      })),
    [onUpdate],
  );

  const onChangeName = useCallback(
    (name: string) =>
      onUpdate((prevState) => ({
        ...prevState,
        name,
      })),
    [onUpdate],
  );

  return (
    <>
      <Name value={name} onChange={onChangeName} />
      <BenchmarksLabels />
      <BenchmarksList
        resultsFilter={resultsFilter}
        benchmarks={benchmarks}
        onChange={onUpdateBenchmark}
        onRemove={onRemoveBenchmark}
      />
      <AddBenchmark className={qaClass}>
        <button type="button" onClick={onAddNewFund}>
          <Plus type="plus-circle" /> Add another investment
        </button>
      </AddBenchmark>
      <TotalWeight>
        <TotalWeightLabel>Total (%)</TotalWeightLabel>
        <Input disabled textAlign={TextAlign.Left} type="text" value={getFormattedTotalWeight(benchmarks)} />
      </TotalWeight>
    </>
  );
};

export default CompositeBenchmarkEditor;
