import React from 'react';
import type { IHeaderParams } from 'ag-grid-community';
import styled, { css } from 'styled-components';
import { GetColor, Icon, Tooltip, TooltipPosition, ZIndex } from 'venn-ui-kit';

interface BasicCellRendererProps extends IHeaderParams {
  tooltip?: string;
  className?: string;
  linkTo: string;
  padding?: number;
  iconColor?: string;
  color?: string;
}

export const HeaderRendererWithLink = ({
  displayName,
  tooltip,
  padding,
  className,
  linkTo,
  iconColor,
  color,
}: BasicCellRendererProps) => {
  return (
    <Wrapper padding={padding} color={color} className={className}>
      <span>{displayName}</span>
      <Tooltip zIndex={ZIndex.InterComFront} usePortal position={TooltipPosition.Top} content={tooltip}>
        <a target="_blank" rel="noopener noreferrer" href={linkTo}>
          <RedirectIcon type="info-circle" prefix="fas" color={iconColor} />
        </a>
      </Tooltip>
    </Wrapper>
  );
};

const RedirectIcon = styled(Icon)<{ color?: string }>`
  pointer-events: visible;
  ${({ color }) => css`
    color: ${color ?? GetColor.Primary.Dark};
  `}
  padding-left: 2px;
`;

const Wrapper = styled.span<{ padding?: number; iconColor?: string; color?: string }>`
  ${({ color }) => css`
    color: ${color ?? GetColor.Black};
  `}
  display: block;
  ${({ padding }) =>
    padding &&
    css`
      padding-top: ${padding}px;
      padding-bottom: ${padding}px;
    `}
`;
