import type { Portfolio, AllocationConstraint } from 'venn-api';
import { searchPortfolio } from '../portfolio';
import { noop } from 'lodash';

export function filterConstraintsNotInSubtree(
  portfolio: Portfolio,
  selectedStrategyId: number,
  allocationConstraints?: AllocationConstraint[],
): AllocationConstraint[] {
  // Map of (ids of strategies that are in subtree) to these strategies. We don't map funds, because constraints don't
  // preserve node ids for funds (they keep parent strategy id in .portfolioId).
  const nodesInSubtree = new Map<number, Portfolio>();
  searchPortfolio(
    portfolio,
    undefined,
    selectedStrategyId,
    portfolio.id === selectedStrategyId,
    noop,
    // @ts-expect-error: TODO fix strictFunctionTypes
    (strategy: Portfolio, _parent: Portfolio | undefined, _rootOfSelectedSubtreeId: number, isInSubtree: boolean) => {
      if (isInSubtree) {
        nodesInSubtree.set(strategy.id, strategy);
      }
    },
  );
  return (allocationConstraints ?? []).filter((constraint) => {
    const strategy = constraint.portfolioId ? nodesInSubtree.get(constraint.portfolioId) : undefined;
    const fund = constraint.fund;
    if (fund) {
      return strategy?.children?.find((child) => child.fund && child.fund.id === fund.id);
    }
    return strategy !== undefined;
  });
}
