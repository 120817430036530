import { atomFamily, selectorFamily } from 'recoil';
import type { BlockId } from '../types';
import { blockSettingsMap } from '../globalConfig';
import type { CustomizableBlockSetting, CustomizableMetric } from 'venn-utils';

export const blockMetricsInternal = atomFamily<string[], BlockId>({
  key: 'blockMetricsInternal',
  default: [] as string[],
});
export const blockSettings = selectorFamily<CustomizableBlockSetting, BlockId>({
  key: 'blockSettings',
  get:
    (id) =>
    ({ get }) => {
      const settingId = get(blockSettingId(id));
      const blockSettingsData = get(blockSettingsMap)[settingId ?? ''];
      const customMetricSettings = get(blockCustomMetricSettingsState(id));
      return {
        ...blockSettingsData,
        metrics: [...(blockSettingsData?.metrics ?? []), ...(customMetricSettings ?? [])],
      };
    },
});

export const blockCustomMetricSettingsState = atomFamily<CustomizableMetric[], BlockId>({
  key: 'blockCustomMetricSettings',
  default: [],
});

export const blockSettingId = atomFamily<string | undefined, BlockId>({
  key: 'blockSettingId',
  default: undefined,
});
