import React, { useContext } from 'react';
import moment from 'moment';
import styled, { ThemeContext } from 'styled-components';
import { GetColor } from 'venn-ui-kit';
import { Numbers } from 'venn-utils';
import { getBarColor, getTestOpacity } from '../utils';

interface Props {
  timestamp?: number;
  factor: string;
  regressionYears: number;
  usePercentages: boolean;
  factorSignificant: boolean;
  value: number;
}

const StyledText = styled.div`
  color: ${GetColor.HintGrey};
`;

const StyledValue = styled.span<{ color: string; opacity: number }>`
  color: ${(props) => props.color};
  font-size: 28px;
  font-weight: bold;
  opacity: ${(props) => props.opacity};
`;

const StyledFactor = styled.div`
  font-size: 28px;
  color: ${GetColor.Black};
  font-weight: lighter;
`;

const StyledHint = styled.div`
  padding: 8px 0 16px 0;
  font-size: 14px;
`;

const getDateEstimation = (regressionYears: number) => {
  switch (regressionYears) {
    case 0:
      return 'a full history';
    case 0.5:
      return '6-month';
    case 1:
      return '1-year';
    case 3:
      return '3-year';
    case 5:
      return '5-year';
    case 9:
      return '9-year';
    case 36:
      return '36-year';
    default:
      return '';
  }
};

const formatValue = (value: number, isPercentage: boolean) =>
  isPercentage ? `${Numbers.formatNumber(value * 100, 2)}%` : Numbers.formatNumber(value, 2);

const Explanation = ({ timestamp, regressionYears, factor, value, usePercentages, factorSignificant }: Props) => {
  const theme = useContext(ThemeContext);
  const date = moment.utc(timestamp || '').format('MMMM YYYY');
  const color = getBarColor(value, theme);
  const opacity = getTestOpacity(factorSignificant);
  return (
    <StyledText>
      <StyledFactor>
        {factor} (
        <StyledValue color={color} opacity={opacity} className="qa-factor-value">
          {formatValue(value, usePercentages)}
        </StyledValue>
        )
      </StyledFactor>
      <StyledHint>
        {timestamp === undefined ? (
          <span>Estimated from full history.</span>
        ) : (
          `For ${date}, estimated from ${getDateEstimation(regressionYears)} trailing regression.`
        )}{' '}
      </StyledHint>
    </StyledText>
  );
};

export default Explanation;
