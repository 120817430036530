import React from 'react';
import { Value, ValueWrapper } from './ItemAllocation';
import { Row } from './AllocationPanelRow';
import KeyBlockingNumericInput from '../key-blocking-numeric-input/KeyBlockingNumericInput';
import { formatAllocation } from 'venn-utils';
import AllocationItemName from './AllocationItemName';
import { BaseIndentation, ItemChildren } from './AllocationTreeItem';

export interface ReadonlyPortfolio {
  id: string;
  name: string;
  children: ReadonlyPortfolio[];
  allocation: number | undefined;
  collapsed?: boolean;
}

interface ReadonlyAllocationTreeItemProps {
  strategy: ReadonlyPortfolio;
  isRoot: boolean;
  itemNameMaxWidth: number;
}

const ReadonlyAllocationTreeItem = ({ strategy, isRoot, itemNameMaxWidth }: ReadonlyAllocationTreeItemProps) => {
  const { allocation, name, children, collapsed } = strategy;
  const isStrategy = !!strategy.children.length;

  return (
    <>
      <Row isDraggingInProgress={false} hasAlert={false}>
        <AllocationItemName
          name={name}
          width={itemNameMaxWidth}
          isGhost={false}
          isEditing={false}
          isStrategy={isStrategy}
          hasAddFund
          isSelected={isRoot}
          isCollapsed={!!collapsed}
          isOutsideOfSelectedSubtree={false}
          hideComparisonColumn
          onAddFundClick={undefined}
          onTriangleClick={undefined}
          onEditNameClick={undefined}
        />
        <ValueWrapper
          isRoot={isRoot}
          isStrategy={isStrategy}
          isModified={false}
          isFocusedGhostInput={false}
          isGreyedOut={false}
        >
          {!isStrategy ? (
            <KeyBlockingNumericInput
              isLocked={isStrategy}
              value={(allocation ?? 0).toString()}
              height={20}
              allowNegative={false}
              isPercentage={false}
              onChange={() => {}}
              onCommitInput={() => {}}
            />
          ) : (
            <Value>
              {allocation !== null && allocation !== undefined ? formatAllocation(allocation, isRoot) : '--'}
            </Value>
          )}
        </ValueWrapper>
      </Row>
      {isStrategy && !collapsed && (
        <div>
          <BaseIndentation />
          <ItemChildren isGhost={false}>
            {children.map((child: ReadonlyPortfolio) => (
              <ReadonlyAllocationTreeItem
                strategy={child}
                key={child.id}
                isRoot={false}
                itemNameMaxWidth={itemNameMaxWidth}
              />
            ))}
          </ItemChildren>
        </div>
      )}
    </>
  );
};

export default ReadonlyAllocationTreeItem;
