import type { FC } from 'react';
import React from 'react';
import { Modal, ModalHeader } from '../../modal';
import { ModalSize } from '../../modal/components/ModalWrapper';
import type { AddInvestmentsProps } from './AddInvestments';
import AddInvestments from './AddInvestments';

type AddInvestmentsModalProps = AddInvestmentsProps;
const AddInvestmentsModal: FC<React.PropsWithChildren<AddInvestmentsModalProps>> = (props) => (
  <Modal size={ModalSize.XLarge}>
    <ModalHeader>Add Investments</ModalHeader>
    <AddInvestments {...props} />
  </Modal>
);

export default AddInvestmentsModal;
