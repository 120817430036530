import React, { useCallback, useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import { analyticsService, Routes, getBlocksExceptPerformance } from 'venn-utils';
import type { SharedProps } from '../types';
import Loading from './Loading';
import { ComponentFromBlock } from '../blocks';
import { Section, SectionHeadline, BlockRow, GetColor, Headline3, Icon, getAppTitle } from 'venn-ui-kit';
import { useHistory } from 'react-router-dom';
import type { SortByOption } from 'venn-components';
import {
  AnalysisViewContext,
  AnalysisWelcomeBanner,
  AnalysisWelcomeModal,
  SortBy,
  backgroundTriangleAsset,
  UserContext,
  DataUploaderLauncher,
} from 'venn-components';
import { VENN_WELCOME_MODAL_VIEWED_KEY } from 'venn-api';

const WelcomeToVennTemplate = (props: SharedProps) => {
  const { analyses, factorLenses, analysisConfig } = props;
  const { settings, updateSettings, hasPermission } = useContext(UserContext);
  const canUploadReturns = hasPermission('UPLOAD_RETURNS');

  const [showWelcomeModal, setShowWelcomeModal] = useState(!settings.user[VENN_WELCOME_MODAL_VIEWED_KEY]);
  const history = useHistory();

  const factorAnalysisResult = props.analyses?.results?.factorAnalysis;
  const { summary } = factorAnalysisResult || {};
  const { factorAnalysisSortBy: sortFactorsBy, onUpdateAnalysisViewParam } = useContext(AnalysisViewContext);
  const setSortFactorsBy = useCallback(
    (value: SortByOption) => onUpdateAnalysisViewParam({ factorAnalysisSortBy: value }),
    [onUpdateAnalysisViewParam],
  );

  if (!analyses || !analyses.results || !factorLenses || factorLenses.length === 0) {
    return <Loading />;
  }

  const blocks = getBlocksExceptPerformance(analysisConfig);

  const factorBlocks = blocks.filter((block) => block.includes('FACTOR'));
  const performanceBlocks = blocks.filter((block) => block === 'CUMULATIVE_RESIDUAL_RETURN');

  return (
    <>
      <Section>
        <SectionHeadline>Factor Insights</SectionHeadline>
        <AnalysisWelcomeBanner />
        <SortConfig>
          <SortBy
            jumbo
            value={sortFactorsBy}
            onChange={(option) => setSortFactorsBy(option.value)}
            disable={!summary?.risk?.factorContributionToRisk}
          />
        </SortConfig>
        {factorBlocks.map((block) => (
          <BlockRow key={block}>
            <ComponentFromBlock block={block} sharedProps={props} factorChartSortBy={sortFactorsBy} />
          </BlockRow>
        ))}
        <SectionFooter>
          <LargeIcon type="graduation-cap" />
          <div>
            <Headline3>Learn more about factors</Headline3>
            <LinkButton
              type="button"
              onClick={() => {
                history.push(Routes.FACTOR_LENS_HOMEPAGE);
                analyticsService.ctaClicked({
                  destination: 'Factor Lens Homepage',
                  filled: false,
                  locationOnPage: `Welcome to ${getAppTitle()} Template - Factor Insights - Footer`,
                  purpose: 'View factor education',
                  text: 'View Two Sigma Factor Lens Education',
                  type: 'banner',
                });
              }}
            >
              View Two Sigma Factor Lens Education <Icon type="external-link" />
            </LinkButton>
          </div>
        </SectionFooter>
      </Section>
      <Section>
        <SectionHeadline>Performance Insights</SectionHeadline>
        {performanceBlocks.map((block) => (
          <BlockRow key={block}>
            <ComponentFromBlock block={block} sharedProps={props} />
          </BlockRow>
        ))}
        <SectionFooter withMargin inverted>
          <BackgroundTriangle src={backgroundTriangleAsset} />
          <LargeIcon type="upload" />
          <div>
            <Headline3 inverted>{`See your own data in ${getAppTitle()}`}</Headline3>
            <DataUploaderLauncher
              analyticsProps={{
                locationOnPage: `Welcome to ${getAppTitle()} Template - Performance Insights - Footer`,
                text: 'Upload Returns Now',
                type: 'banner',
                filled: true,
              }}
            >
              {(onLaunchDataUploader) => (
                <LinkButton inverted type="button" onClick={onLaunchDataUploader} disabled={!canUploadReturns}>
                  Upload Returns Now
                </LinkButton>
              )}
            </DataUploaderLauncher>
          </div>
        </SectionFooter>
      </Section>
      {showWelcomeModal && (
        <AnalysisWelcomeModal
          onClose={() => {
            setShowWelcomeModal(false);
            updateSettings({ [VENN_WELCOME_MODAL_VIEWED_KEY]: true });
          }}
        />
      )}
    </>
  );
};

export default WelcomeToVennTemplate;

const SortConfig = styled.div`
  width: 200px;
`;

const SectionFooter = styled.div<{ withMargin?: boolean; inverted?: boolean }>`
  border: 1px solid ${GetColor.Grey};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px;
  ${({ withMargin }) => withMargin && 'margin-top: 20px;'}
  ${({ inverted }) =>
    inverted &&
    css`
      background-color: ${GetColor.Black};
      i {
        color: ${GetColor.Primary.Main};
      }
    `}
  position: relative;
  overflow: hidden;
`;

const LinkButton = styled.button<{ inverted?: boolean }>`
  font-weight: bold;
  position: relative;
  ${({ inverted }) =>
    inverted &&
    css`
      color: ${GetColor.Primary.Main};
      &:hover {
        color: ${GetColor.White};
      }
    `}
`;

const LargeIcon = styled(Icon)`
  font-size: 45px;
  color: ${GetColor.Black};
  margin-right: 12px;
  margin-top: 4px;
`;

const BackgroundTriangle = styled.img`
  position: absolute;
`;
