import React, { useLayoutEffect, useRef, useState } from 'react';
import { Loading, LoadingSize } from 'venn-ui-kit';
import { disableMFA } from 'venn-api';
import { Content, Error, Field } from '../account-modals/shared';
import styled from 'styled-components';
import { ModalFooter } from '../../modal';

interface DisableTwoFactorProps {
  onClose: () => void;
  refresh: () => void;
}

const DisableTwoFactor = ({ onClose, refresh }: DisableTwoFactorProps) => {
  const [disabled, setDisabled] = useState(false);
  const isMount = useRef(false);
  const [error, setError] = useState('');

  useLayoutEffect(() => {
    isMount.current = true;
    return () => {
      isMount.current = false;
    };
  }, []);

  const onSubmit = async () => {
    try {
      setDisabled(true);
      await disableMFA();
      refresh();
      onClose();
    } catch (e) {
      const err = await e;
      if (isMount.current) {
        setDisabled(false);
        setError(err.content?.message);
      }
    }
    if (isMount.current) {
      setDisabled(false);
    }
  };

  return (
    <>
      <Content>
        <Field>Are you sure you want to disable your two factor authentication?</Field>
        <Error>{error}</Error>
      </Content>
      <ModalFooter
        destructive
        primaryLabel="Disable Two Factor"
        primaryDisabled={disabled}
        onPrimaryClick={onSubmit}
        rightChildren={disabled ? <StyledLoading size={LoadingSize.nano} title="" /> : undefined}
        onCancel={onClose}
      />
    </>
  );
};

export default DisableTwoFactor;

const StyledLoading = styled(Loading)`
  display: inline;
`;
