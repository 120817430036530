import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import type { Portfolio } from 'venn-api';
import {
  analyticsService,
  AnalyticsUtils,
  arePortfoliosEqual,
  calculateComparison,
  logMessageToSentry,
  mapPortfolioToNodes,
  selectStrategy,
} from 'venn-utils';
import type { DropMenuItem } from 'venn-ui-kit';
import AllocationPanelContent from '../AllocationPanelContent';
import { getWithReplacedStrategy, TreeItemUpdateType } from '../AllocationUtils';
import type { CompareType } from '../../contexts';
import { UserContext, PortfoliosContext, ComparisonContext } from '../../contexts';
import { isNil } from 'lodash';

export type PortfolioComparisonType = 'MASTER' | 'SAVED' | undefined;

const getSaveAsNotification = (portfolioName: string): string => `Successfully saved portfolio as ${portfolioName}.`;

interface AllocationPanelV2Props {
  current: Portfolio;
  original: Portfolio;
  onUpdatePortfolio: (portfolio: Portfolio, updateType: TreeItemUpdateType) => void;

  selectedStrategyId: number;
  updateSelectedStrategy: (strategy: Portfolio, secondaryStrategy: Portfolio | undefined) => void;

  compare: Portfolio | undefined;
  comparisonType: PortfolioComparisonType;
  onUpdateComparison: (
    comparisonType: PortfolioComparisonType,
    portfolioId?: number,
    portfolioVersion?: number,
  ) => void;
  onUpdateSecondaryPortfolio: (
    comparisonType: PortfolioComparisonType,
    portfolio: Portfolio | undefined,
    strategy: Portfolio | undefined,
  ) => void;

  isPercentageMode: boolean;
  togglePercentageMode: () => void;
  hasAllocationError: boolean;
  baseAllocation: number;

  hideComparisonColumn: boolean;

  onSavedAsNewPortfolio: (portfolio: Portfolio) => void;
}

const AllocationPanelV2 = ({
  current,
  original,
  compare,
  comparisonType,
  onUpdateComparison,
  onUpdateSecondaryPortfolio,
  selectedStrategyId,
  updateSelectedStrategy,
  onUpdatePortfolio,
  isPercentageMode,
  togglePercentageMode,
  hasAllocationError,
  baseAllocation,
  hideComparisonColumn,
  onSavedAsNewPortfolio,
}: AllocationPanelV2Props) => {
  const allOriginalNodes = useMemo(() => {
    if (isNil(original)) {
      return new Map<number, Portfolio>();
    }
    return mapPortfolioToNodes(original);
  }, [original]);

  const selectedStrategy = useMemo(
    () => selectStrategy(selectedStrategyId, current) ?? current,
    [current, selectedStrategyId],
  );

  const [allCompareNodes, allGhostChildren] = useMemo(() => {
    if (isNil(compare)) {
      return [new Map<number, Portfolio>(), new Map<number, Portfolio[]>()];
    }
    return calculateComparison(current, compare);
  }, [current, compare]);

  const onChangeComparison = useCallback(
    ({ value }: DropMenuItem<CompareType>, specificPortfolio?: Portfolio) => {
      const [updatedComparisonType, updatedCompareId, updatedCompareVersion] =
        value === 'Last Saved'
          ? ['SAVED' as const, specificPortfolio?.id ?? current.id, specificPortfolio?.version ?? current.version]
          : [value === 'Master' ? ('MASTER' as const) : undefined, undefined, undefined];

      const noComparisonChange =
        updatedComparisonType === comparisonType &&
        (comparisonType === 'MASTER' ||
          isNil(comparisonType) ||
          (comparisonType === 'SAVED' &&
            !isNil(compare) &&
            compare.id === updatedCompareId &&
            compare.version === updatedCompareVersion));

      if (noComparisonChange) {
        return;
      }

      onUpdateComparison(updatedComparisonType, updatedCompareId, updatedCompareVersion);
    },
    [current.id, current.version, compare, comparisonType, onUpdateComparison],
  );

  useEffect(() => {
    const comparePortfolio = allCompareNodes.get(current.id);
    const compareStrategy = allCompareNodes.get(selectedStrategy.id);
    if ((comparisonType && comparePortfolio) || (!comparisonType && !comparePortfolio)) {
      onUpdateSecondaryPortfolio(comparisonType, comparePortfolio, compareStrategy);
    }
  }, [allCompareNodes, comparisonType, current.id, onUpdateSecondaryPortfolio, selectedStrategy.id]);

  const [isTradesView, setIsTradesView] = useState<boolean>(false);
  const toggleTradesView = useCallback(() => setIsTradesView(!isTradesView), [isTradesView]);

  const originalBaseAllocation = original.allocation ?? 0;

  const onApplyComparisonAllocationsToCurrent = useCallback(() => {
    const replacementStrategy = allCompareNodes.get(selectedStrategy.id);
    if (isNil(replacementStrategy)) {
      return;
    }

    onUpdatePortfolio(
      getWithReplacedStrategy(current, selectedStrategy.id, allCompareNodes),
      TreeItemUpdateType.APPLY_ALLOCATIONS_FROM_COMPARE,
    );
  }, [selectedStrategy.id, current, allCompareNodes, onUpdatePortfolio]);

  const onReset = useCallback(
    () => onUpdatePortfolio(original, TreeItemUpdateType.RESET),
    [onUpdatePortfolio, original],
  );

  const { masterPortfolio, refresh: refreshPortfoliosInContext } = useContext(PortfoliosContext);
  const onSaveComplete = useCallback(
    (saved: Portfolio) => {
      if (isNil(saved.allocation)) {
        logMessageToSentry('Failed to save portfolio because of missing allocation.');
        return;
      }

      // Only update the local portfolio if we saved it to the same portfolio ("save as" flow saves it with a new id)
      if (current.id === saved.id) {
        onUpdatePortfolio(saved, TreeItemUpdateType.SAVE);
      }

      // Analytics track user save portfolio
      const hasBenchmark = !!(saved.compare && saved.compare.find((_compare) => _compare.benchmark));
      analyticsService.portfolioSaved({
        isMaster: saved.master,
        dateRange: `${saved.periodStart ? AnalyticsUtils.dateFormat(saved.periodStart) : ''}-${
          saved.periodEnd ? AnalyticsUtils.dateFormat(saved.periodEnd) : ''
        }`,
        portfolioId: saved.id,
        hasBenchmark,
      });

      // Update all portfolios list and/or master portfolio in PortfoliosContext
      refreshPortfoliosInContext();
    },
    [refreshPortfoliosInContext, onUpdatePortfolio, current],
  );

  const isModified = useMemo(() => !arePortfoliosEqual(current, original), [current, original]);
  const canSave = current.draft || isModified;

  const { hasPermission } = useContext(UserContext);
  const hasAccessToCompare = hasPermission('COMPARE');

  const { compareType, compareLoading } = useContext(ComparisonContext);

  const tradesToggleDisabled = isNil(compare) || compareLoading;
  const isDraftCreatedPortfolio = current.draft;

  const onSelectStrategy = useCallback(
    (strategy: Portfolio) => {
      const compareStrategy =
        isNil(compareType) || compareType === 'None' ? undefined : allCompareNodes.get(strategy.id);
      updateSelectedStrategy(strategy, compareStrategy);
    },
    [compareType, allCompareNodes, updateSelectedStrategy],
  );

  return (
    <AllocationPanelContent
      portfolio={current}
      selectedStrategyId={selectedStrategy.id}
      allOriginalNodes={allOriginalNodes}
      allCompareNodes={allCompareNodes}
      allGhostChildren={allGhostChildren}
      hideCompareValue={isNil(comparisonType)}
      compareLoading={compareLoading}
      onSelectStrategy={onSelectStrategy}
      onUpdatePortfolio={onUpdatePortfolio}
      hideMaster={
        isNil(masterPortfolio) ||
        current.master ||
        (comparisonType === 'MASTER' && !compare && !compareLoading) ||
        isDraftCreatedPortfolio ||
        !hasPermission('MANAGE_MASTER_PORTFOLIO')
      }
      selected={compareType}
      masterName={masterPortfolio?.name}
      onChangeComparison={onChangeComparison}
      canSave={canSave}
      canReset={isModified}
      onSave={onSaveComplete}
      getSaveAsSuccessCustomNotification={getSaveAsNotification}
      onSavedAsNewPortfolio={onSavedAsNewPortfolio}
      onReset={onReset}
      lastSavedPortfolio={original}
      baseAllocation={baseAllocation}
      isPercentageMode={isPercentageMode}
      togglePercentageMode={togglePercentageMode}
      hasAllocationError={hasAllocationError}
      invalidTotal={current.allocation === 0}
      showOptimizerConstraintsButton={false}
      isTradesView={isTradesView && !tradesToggleDisabled}
      toggleTradesView={toggleTradesView}
      tradesToggleDisabled={tradesToggleDisabled}
      orignalBaseAllocation={originalBaseAllocation}
      hasComparison={!isNil(allCompareNodes.get(selectedStrategy.id)) && !compareLoading}
      onApplyComparisonAllocationsToCurrent={onApplyComparisonAllocationsToCurrent}
      hasAccessToCompare={hasAccessToCompare}
      hideComparisonColumn={hideComparisonColumn}
      isStudio
    />
  );
};

export default AllocationPanelV2;
