import React, { useContext, useMemo } from 'react';
import type { RollingChartProps, RollingPeriodEnum } from './types';
import { getAnalysisLabels } from 'venn-utils';
import AnalysisLineChart from './internal/AnalysisLineChart';
import { AnalysisViewContext } from '../../contexts';
import { PERIODS } from './internal/AnalysisChartLegend';
import useCustomRollingWindow from './internal/useCustomRollingWindow';
import { isNil } from 'lodash';

const AnalysisRollingVolatilityChart = ({
  frequency,
  analysisGroup,
  subject,
  actualTimeFrame,
  relative,
  noChartErrorComponent,
  noChartTrackingProps,
  downloadMetaData,
  categoryActive,
}: RollingChartProps) => {
  const fullPeriodLabels = useMemo(() => {
    const titleType = getAnalysisLabels(subject.type).main;
    return {
      absolute: `${titleType}'s Full-History Volatility`,
      relative: `${titleType}'s Full-History Tracking Error`,
    };
  }, [subject.type]);

  const { rollingVolatilityPeriod, onUpdateAnalysisViewParam } = useContext(AnalysisViewContext);
  const { chartDataWithRollingPeriods, customPeriodLoading, setOptimisticLoading } = useCustomRollingWindow(
    subject,
    analysisGroup,
    rollingVolatilityPeriod,
    relative,
    categoryActive,
    actualTimeFrame,
    'ROLLING_VOLATILITY',
    'rollingVolatility',
    fullPeriodLabels,
    noChartTrackingProps?.trackingId,
  );

  const header = relative ? 'Rolling Tracking Error' : 'Rolling Volatility';
  return (
    <AnalysisLineChart
      defaultRollingPeriod={PERIODS.find(({ key }) => key === rollingVolatilityPeriod)}
      rollingPeriodSettings={rollingVolatilityPeriod}
      loadingSerieData={customPeriodLoading}
      onRollingPeriodChange={(updatedPeriodKey?: RollingPeriodEnum, customPeriodMonths?: number) => {
        if (!isNil(updatedPeriodKey)) {
          onUpdateAnalysisViewParam({ rollingVolatilityPeriod: { period: updatedPeriodKey } });
          return;
        }
        if (!isNil(customPeriodMonths)) {
          setOptimisticLoading(true);
          onUpdateAnalysisViewParam({ rollingVolatilityPeriod: { customMonths: customPeriodMonths } });
        }
      }}
      allowCustomWindow
      analysisSubject={subject}
      frequency={frequency}
      relative={relative}
      chartDataWithRollingPeriods={chartDataWithRollingPeriods}
      start={actualTimeFrame.startTime}
      end={actualTimeFrame.endTime}
      header={header}
      noChartErrorComponent={noChartErrorComponent}
      noChartTrackingProps={noChartTrackingProps}
      dataExportEvent={{
        description: 'ROLLING_VOLATILITY',
        relativeToBenchmark: relative,
        subjectType: subject.type,
        subjectId: subject.id,
        userUploaded: subject.userUploaded,
      }}
      exportPNGEnabled
      exportXLSXEnabled
      yAxisUnitFormat="percent"
      downloadMetaData={downloadMetaData}
    />
  );
};

export default AnalysisRollingVolatilityChart;
