import type { AnalysisViewTypeEnum } from 'venn-api';
import { searchAnalysisView } from 'venn-api';
import { useQuery } from '../cached';

const RECENT_STUDIO = 'recent_studio';
const MAX_SIZE = 3;
const types: AnalysisViewTypeEnum[] = [
  'ANALYSIS',
  'COMPARE',
  'CUSTOM',
  'ASSEMBLY_CHILD',
  'ASSEMBLY',
  'REPORT',
  'REPORT_TEMPLATE',
  'TEARSHEET',
  'TEARSHEET_TEMPLATE',
];

export const useRecentStudio = ({
  includeTypes,
  vennTemplate = false,
  excludeVennCreated = false,
  maxSize = MAX_SIZE,
}: {
  includeTypes: AnalysisViewTypeEnum[];
  vennTemplate?: boolean;
  excludeVennCreated?: boolean;
  maxSize?: number;
}) => {
  const excludeTypes = types.filter((type) => !includeTypes.includes(type));
  const { data, refetch, isLoading } = useQuery(
    [RECENT_STUDIO, excludeTypes, maxSize, vennTemplate, excludeVennCreated],
    () => {
      const query = {
        name: '',
        pageSize: maxSize,
        onlyRecentlyViewed: false,
        excludedAnalysisViewTypes: excludeTypes,
        includeAllInWorkspace: true,
        vennTemplate,
        excludeVennCreated,
      };
      return searchAnalysisView(query);
    },
  );

  return {
    studioItems: data?.content.results ?? [],
    refetch,
    isLoading,
  };
};
