import { useCallback } from 'react';
import { analyticsService, copyToClipboard, useModal } from 'venn-utils';
import { Notifications } from 'venn-ui-kit';

/**
 * Hooks for copy to clipboard and open share modal
 * @param analyticsDescription description for analytics purpose
 * @param disabled if the button is disabled
 */

const useShare = (analyticsDescription: string, disabled?: boolean) => {
  const [isShareModalOpen, openShareModal, closeShareModal] = useModal();
  const handleCopyToClipboard = useCallback(async () => {
    if (disabled) {
      return;
    }
    const copyText = window.location.href;
    const response = await copyToClipboard(copyText);
    if (response) {
      Notifications.notify('Link copied');
      if (analyticsDescription) {
        analyticsService.ctaClicked({
          destination: undefined,
          text: 'Copy Link',
          purpose: `Copy link to ${analyticsDescription}`,
          type: 'button',
          filled: false,
        });
      }
    }
  }, [analyticsDescription, disabled]);

  const handleShare = useCallback(() => {
    openShareModal();
    if (analyticsDescription) {
      analyticsService.ctaClicked({
        destination: undefined,
        text: 'Share',
        purpose: `Share on ${analyticsDescription}`,
        type: 'button',
        filled: false,
      });
    }
  }, [openShareModal, analyticsDescription]);

  return {
    handleCopyToClipboard,
    handleShare,
    isShareModalOpen,
    closeShareModal,
  };
};

export default useShare;
