import React from 'react';
import EllipsisTooltipSpan from './EllipsisTooltipSpan';
import type { TooltipBodyDirection } from '../enums';
import { TooltipPosition } from '../enums';

// translation of the tooltip on hover
const TOOLTIP_ANIMATED_SHIFT = 12;

interface EllipsisAutoPositionTooltipSpanProps {
  children: React.ReactNode;
  content?: React.ReactNode;
  block?: boolean;
  flex?: boolean;
  maxWidth?: string | number;
  alwaysShowTooltip?: boolean;
  bodyDirection?: TooltipBodyDirection;
  hideArrow?: boolean;
  disableTooltip?: boolean;
  tooltipMaxWidth?: number;
  usePortal?: boolean;
}

interface EllipsisAutoPositionTooltipSpanState {
  position: TooltipPosition;
}

export class EllipsisAutoPositionTooltipSpan extends React.Component<
  EllipsisAutoPositionTooltipSpanProps,
  EllipsisAutoPositionTooltipSpanState
> {
  state: EllipsisAutoPositionTooltipSpanState = {
    position: TooltipPosition.Top,
  };

  private contentElementRef: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();

  private scrollParentElement: HTMLElement;

  handleParentScroll() {
    const contentElement = this.contentElementRef.current;
    if (!contentElement) {
      return;
    }
    this.scrollParentElement = this.scrollParentElement || getScrollParent(contentElement);
    const contentRect = contentElement.getBoundingClientRect();
    const scrollParentRect = this.scrollParentElement.getBoundingClientRect();
    if (
      this.state.position === TooltipPosition.Top &&
      contentRect.top - TOOLTIP_ANIMATED_SHIFT < scrollParentRect.top
    ) {
      this.setState({
        position: TooltipPosition.Bottom,
      });
    } else if (
      this.state.position === TooltipPosition.Bottom &&
      contentRect.bottom + TOOLTIP_ANIMATED_SHIFT > scrollParentRect.bottom
    ) {
      this.setState({
        position: TooltipPosition.Top,
      });
    }
  }

  handleTooltipVisible = () => {
    this.handleParentScroll();
  };

  render() {
    const { children, content, disableTooltip, ...ellipsisTooltipSpanProps } = this.props;
    const { position } = this.state;

    return (
      <EllipsisTooltipSpan
        {...ellipsisTooltipSpanProps}
        isHidden={disableTooltip}
        onMouseEnter={this.handleTooltipVisible}
        position={position}
        content={<div ref={this.contentElementRef}>{content || children}</div>}
      >
        {children}
      </EllipsisTooltipSpan>
    );
  }
}

function getScrollParent(element: HTMLDivElement) {
  const overflowRegex = /(auto|scroll)/;

  let parent = element.parentElement;
  while (parent) {
    const style = getComputedStyle(parent);
    if (overflowRegex.test(style.overflow + style.overflowX + style.overflowY)) {
      return parent;
    }
    parent = parent.parentElement;
  }
  return document.body;
}
