import { logExceptionIntoSentry } from 'venn-utils';
import { compact } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { addTagV3, removeTagV3 } from 'venn-api';

export type TagableItem = {
  fundId?: string;
  portfolioId?: number;
};

export const addTagToItems = async (tag: string, items: TagableItem[]) => {
  try {
    await addTagV3({
      tag,
      entities: {
        fundIds: compact(items.map((item) => item.fundId)),
        portfolioIds: compact(items.map((item) => item.portfolioId)),
      },
    });
  } catch (e) {
    logExceptionIntoSentry(e);
  }
};

export const removeTagFromItems = async (tag: string, items: TagableItem[]) => {
  try {
    await removeTagV3({
      tag,
      entities: {
        fundIds: compact(items.map((item) => item.fundId)),
        portfolioIds: compact(items.map((item) => item.portfolioId)),
      },
    });
  } catch (e) {
    logExceptionIntoSentry(e);
  }
};

export const getTagsTooltip = (tagSet: Set<string>, placeHolder?: React.ReactNode) => {
  const tags = [...tagSet.values()];
  return tags.length ? (
    <TipWrapper>
      <Header>Tags ({tags.length}):</Header>
      <List>{tags.length === 1 ? tags[0] : tags.map((t, idx) => (idx === tags.length - 1 ? `& ${t}` : `${t}, `))}</List>
    </TipWrapper>
  ) : (
    placeHolder
  );
};

const Header = styled.div`
  font-size: 12px;
`;

const List = styled.div`
  font-size: 14px;
`;

const TipWrapper = styled.div`
  text-align: left;
  min-width: 100px;
`;
