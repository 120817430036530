import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { scheduleExportForView } from 'venn-api';
import type { AnalysisViewSearchResultWithUIState } from 'venn-utils';
import { useModal, analyticsService, logExceptionIntoSentry } from 'venn-utils';
import { ButtonIcon, GetColor, LoadingSize, Spinner } from 'venn-ui-kit';
import { ConfirmationModal } from 'venn-components';
import { BUTTON_SIZE } from './constants';

interface BulkCancel {
  items: AnalysisViewSearchResultWithUIState[];
  fetchResults: () => void;
}

const BulkCancel = ({ items, fetchResults }: BulkCancel) => {
  const [isConfirmationModalOpen, openConfirmationModal, closeConfirmationModal] = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const isCancellable = items.every((item) => item.scheduledFrequency && item.scheduledFrequency !== 'UNKNOWN');
  const selectedItemsNames = items.map((item: AnalysisViewSearchResultWithUIState) => item.name).join(', ');

  const handleOnProceed = async () => {
    setIsLoading(true);
    try {
      analyticsService.ctaClicked({
        destination: undefined,
        filled: false,
        locationOnPage: 'Saved views table',
        purpose: 'Cancel scheduled exports',
        text: 'bulk cancel',
        type: 'button',
      });

      await Promise.all(
        items.map(async (item: AnalysisViewSearchResultWithUIState) => {
          try {
            await scheduleExportForView({
              scheduledExportId: item.scheduledId,
              scheduledFrequency: 'UNKNOWN',
              analysisViewId: item.id,
            });
          } catch (e) {
            logExceptionIntoSentry(e);
          }
        }),
      );
      setIsLoading(false);
      closeConfirmationModal();

      fetchResults();
    } catch (e) {
      setIsLoading(false);
      closeConfirmationModal();
      logExceptionIntoSentry(e);
    }
  };

  if (items.length === 0) {
    return null;
  }

  return (
    <>
      <StyledButtonIcon
        tooltip={!isCancellable ? 'Not all selected views have scheduled exports' : undefined}
        onClick={openConfirmationModal}
        iconType="calendar-times"
        text={`Cancel (${items.length})`}
        className="qa-bulk-cancel"
        disabled={!isCancellable}
        size={BUTTON_SIZE}
      />
      {isConfirmationModalOpen && (
        <ConfirmationModal
          subhead={`Are you sure you want to cancel scheduled exports for ${selectedItemsNames}?`}
          text="You will no longer receive scheduled exports for the saved view(s)."
          onCancel={closeConfirmationModal}
          onProceed={handleOnProceed}
          rightChildren={isLoading && <Spinner size={LoadingSize.small} />}
          proceedDisabled={isLoading}
          destructive
        />
      )}
    </>
  );
};

const StyledButtonIcon = styled(ButtonIcon)<{ disabled?: boolean }>`
  display: flex;
  margin-right: 6px;
  ${(props) =>
    !props.disabled &&
    css`
      &.button-icon-wrapper {
        color: ${GetColor.Error};
      }
    `}
`;

export default BulkCancel;
