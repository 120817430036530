import React from 'react';
import { withTheme } from 'styled-components';
import type { Theme } from 'venn-ui-kit';
import { TooltipLink, INTERCOM_HELP_HREF } from 'venn-ui-kit';

interface InsignificantFactorMessageProps {
  value: number;
  y: number;
  width: number;
  showPrintStyle?: boolean;
  theme: Theme;
  fontSize?: string;
}

const InsignificantFactorMessage = ({
  value,
  y,
  width,
  showPrintStyle,
  fontSize,
  theme: { Colors },
}: InsignificantFactorMessageProps) => {
  const factorText = value > 1 ? 'factors' : 'factor';

  return (
    <g>
      <line x1="0" y1={y} x2={width} y2={y} strokeWidth={1} stroke={Colors.PaleGrey} />
      <text
        style={{ fill: Colors.HintGrey, fontSize: showPrintStyle ? '9px' : '14px' }}
        x="15"
        y={y + (showPrintStyle ? 18 : 25)}
        fontSize={fontSize}
      >
        {`Two Sigma's factor selection methodology excluded ${value} insignificant ${factorText}.`}
      </text>
      {!showPrintStyle && (
        <foreignObject x={485} y={y + 15} width={15} height={15}>
          <TooltipLink
            positions={{
              top: -60,
              left: -90,
            }}
            href={INTERCOM_HELP_HREF}
            top
          />
        </foreignObject>
      )}
    </g>
  );
};

export default withTheme(InsignificantFactorMessage);
