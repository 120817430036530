import React, { useContext } from 'react';
import styled from 'styled-components';
import { FlexRow, FlexWrapper, Name, Section, StyledIcon, WrapperForSections } from './shared';
import {
  RequiredLabel,
  StatefulInput,
  StudioPrintSettingsContext,
  UserContext,
  STUDIO_DISCLOSURE_PAGE_DEFAULT_DISCLOSURE,
} from 'venn-components';
import { Button, Caption, getAppTitle, Label, Link as LinkStyle, Tooltip } from 'venn-ui-kit';
import {
  STUDIO_COVER_PAGE_DEFAULT_DISCLOSURE,
  STUDIO_COVER_PAGE_DISCLOSURE_DEFAULT_FONT_SIZE,
  STUDIO_DEFAULT_DISCLAIMER_FONT_SIZE,
  STUDIO_DEFAULT_DISCLOSURE_FONT_SIZE,
  STUDIO_DEFAULT_USER_DISCLAIMERS,
  STUDIO_DISCLAIMER_DISCLOSURE_FONT_SIZE_MAX,
  STUDIO_DISCLAIMER_DISCLOSURE_FONT_SIZE_MIN,
  useModal,
} from 'venn-utils';
import LogoManagementModal from './printing/LogoManagementModal';
import { PrintSetting } from './PrintSetting';

const PrintSettingsSection = () => {
  const { hasPermission, profileSettings } = useContext(UserContext);
  const {
    hasDefault,
    defaultLogoId,
    loading,
    needsSaving,
    organizationPrintSettings,
    storeSettings,
    uploadOrganizationLogo,
    setOrganizationName,
    setDisclaimer,
    setCoverPageDisclosure,
    setDisclosure,
    setDisclaimerFontSize,
    setCoverPageDisclosureFontSize,
    setDisclosureFontSize,
    allLogos,
    deleteLogo,
    setDefaultLogo,
  } = useContext(StudioPrintSettingsContext);
  const [isOpen, open, close] = useModal();
  const isReadOnly = !hasPermission('MANAGE_PRINT_CONFIGURATION');
  const organizationDefaultDisclosure = STUDIO_DISCLOSURE_PAGE_DEFAULT_DISCLOSURE(
    organizationPrintSettings?.organizationName ?? profileSettings?.organization?.name,
  );
  const organizationDefaultDisclaimer = STUDIO_DEFAULT_USER_DISCLAIMERS(
    organizationPrintSettings?.organizationName ?? profileSettings?.organization?.name,
  );
  const finalDisclosure = organizationPrintSettings?.disclosure ?? organizationDefaultDisclosure;
  const finalDisclaimer = organizationPrintSettings?.disclaimer ?? organizationDefaultDisclaimer;
  const finalCoverPageDisclosure =
    organizationPrintSettings?.coverPageDisclosure ?? STUDIO_COVER_PAGE_DEFAULT_DISCLOSURE;

  const finalDisclosureFontSize = organizationPrintSettings?.disclosureFontSize ?? STUDIO_DEFAULT_DISCLOSURE_FONT_SIZE;
  const finalDisclaimerFontSize = organizationPrintSettings?.disclaimerFontSize ?? STUDIO_DEFAULT_DISCLAIMER_FONT_SIZE;
  const finalCoverPageDisclosureFontSize =
    organizationPrintSettings?.coverPageDisclosureFontSize ?? STUDIO_COVER_PAGE_DISCLOSURE_DEFAULT_FONT_SIZE;

  return (
    <WrapperForSections>
      <Section>
        <FlexRow style={{ flex: 1 }}>
          <FieldWrapper
            style={{
              display: 'flex',
              marginTop: '0px',
            }}
          >
            <StyledIcon type="print" />
            <Name>Report Lab Print Configuration</Name>
          </FieldWrapper>
          <FlexWrapperWide
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <StyledButton
              onClick={() => {
                organizationPrintSettings && storeSettings(organizationPrintSettings);
              }}
              icon="save"
              disabled={!needsSaving || loading || isReadOnly}
            >
              Save Changes
            </StyledButton>
          </FlexWrapperWide>
        </FlexRow>
      </Section>
      <Section>
        <FieldWrapper>
          <RequiredLabel>Organization Display Name:</RequiredLabel>
          <FieldInput
            value={organizationPrintSettings?.organizationName}
            onChange={(value) => {
              setOrganizationName(value);
            }}
            disabled={loading || isReadOnly}
            placeholder={profileSettings?.organization?.name}
          />
        </FieldWrapper>
        <FieldWrapper>
          <RequiredLabel>Selected Logo:</RequiredLabel>
          <div
            style={{
              display: 'flex',
              gap: '15px',
            }}
          >
            <div>
              <div
                style={{
                  position: 'absolute',
                  padding: '10px',
                  paddingLeft: '100px',
                }}
              >
                <input
                  type="file"
                  id="logoFileDialogId"
                  style={{ display: 'none' }}
                  accept="image/*"
                  onChange={(file) => uploadOrganizationLogo(file.target, true)}
                />
                {!isReadOnly && (
                  <InteractiveLink
                    onClick={() => {
                      document.getElementById('logoFileDialogId')?.click();
                    }}
                  >
                    <Tooltip content="Edit Default Logo">
                      <StyledIcon type="edit" />
                    </Tooltip>
                  </InteractiveLink>
                )}
              </div>
              <div style={{ display: 'inline-block' }}>
                <LogoOverlay hasDefault={hasDefault}>Upload or Set Default Logo</LogoOverlay>
              </div>
              <LogoImage
                hasDefault={hasDefault}
                src={hasDefault ? `/api/printing/logo/${defaultLogoId}` : ''}
                alt={organizationPrintSettings?.logoId}
              />
            </div>
            <ManageLogosButton onClick={open}>Manage Logos</ManageLogosButton>
          </div>
        </FieldWrapper>
        {isOpen && (
          <LogoManagementModal
            onClose={close}
            allLogos={allLogos}
            deleteLogo={deleteLogo}
            setDefaultLogo={setDefaultLogo}
            uploadOrganizationLogo={uploadOrganizationLogo}
          />
        )}
      </Section>
      <Section>
        <PrintSetting
          title="Cover Page Disclosure"
          label="Disclosure text"
          labelTooltip="Appears at the bottom of the Cover Page"
          fontTooltip={`Cover Page Disclosure font size can be set to any value between
            ${STUDIO_DISCLAIMER_DISCLOSURE_FONT_SIZE_MIN}pts and ${STUDIO_DISCLAIMER_DISCLOSURE_FONT_SIZE_MAX}
              pts`}
          settingContent={finalCoverPageDisclosure}
          settingContentOnChange={(content) => setCoverPageDisclosure(content)}
          fontSize={finalCoverPageDisclosureFontSize}
          fontSizeOnChange={(value) => setCoverPageDisclosureFontSize(value)}
          isReadOnly={isReadOnly || loading}
        />
      </Section>
      <Section>
        <PrintSetting
          title="Page Footer Disclaimer"
          label="Disclaimer text"
          labelTooltip={`Appears on bottom of every page in the report in addition to ${getAppTitle()} disclaimers`}
          fontTooltip={`Disclaimer font size can be set to any value between ${STUDIO_DISCLAIMER_DISCLOSURE_FONT_SIZE_MIN}
                      pts and ${STUDIO_DISCLAIMER_DISCLOSURE_FONT_SIZE_MAX}
                      pts`}
          settingContent={finalDisclaimer}
          settingContentOnChange={(content) => setDisclaimer(content)}
          fontSize={finalDisclaimerFontSize}
          fontSizeOnChange={(value) => setDisclaimerFontSize(value)}
          isReadOnly={isReadOnly || loading}
        />
      </Section>
      <Section>
        <PrintSetting
          title="Disclosures Page"
          label="Disclosure text"
          labelTooltip={`Appears at end of every report in addition to ${getAppTitle()} disclosures`}
          fontTooltip={`Disclosure font size can be set to any value between ${STUDIO_DISCLAIMER_DISCLOSURE_FONT_SIZE_MIN}
                      pts and ${STUDIO_DISCLAIMER_DISCLOSURE_FONT_SIZE_MAX}
                      pts`}
          settingContent={finalDisclosure}
          settingContentOnChange={(content) => setDisclosure(content)}
          fontSize={finalDisclosureFontSize}
          fontSizeOnChange={(value) => setDisclosureFontSize(value)}
          isReadOnly={isReadOnly || loading}
        />
      </Section>
      <Section>
        <Label>Investments Excluded from Advisory Fees</Label>
        <Caption style={{ display: 'block', marginTop: '8px' }}>
          When investments are excluded from portfolio advisory fees, a footnote will be added to each page directing
          readers to the Disclosures page, where all investments excluded from portfolio advisory fees will be detailed.
        </Caption>
      </Section>
    </WrapperForSections>
  );
};

export default PrintSettingsSection;

const FlexWrapperWide = styled(FlexWrapper)`
  width: 100%;
`;

const LogoOverlay = styled.div<{ hasDefault?: boolean }>`
  display: ${(props) => (props.hasDefault ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  height: 128px;
  width: 128px;
  border: 1px solid red;
  padding: 0px;
  margin: 0px;
  text-align: center;
`;

const LogoImage = styled.img<{ hasDefault?: boolean }>`
  display: ${(props) => (props.hasDefault ? 'inline-block' : 'none')};
  width: 128px;
  height: 128px;
  object-fit: contain;
  border: 1px dotted gray;
  padding: 3px;
`;

const ManageLogosButton = styled(Button)`
  width: 128px;
  height: 128px;
`;

const FieldInput = styled(StatefulInput)`
  display: flex;
`;

const FieldWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
`;

const InteractiveLink = styled(LinkStyle)`
  &:hover {
    cursor: pointer;
  }

  @media print {
    display: none;
  }
`;

const StyledButton = styled(Button)`
  i {
    font-size: 16px;
    margin-top: -4px;
  }
`;
