import React, { Suspense } from 'react';
import styled from 'styled-components';
import { Link as LinkStyle, Loading, PRIVATE_ASSET_CASH_FLOW_HELP_HREF } from 'venn-ui-kit';
import { FlexRow, FlexWrapper, Info, Name, StyledIcon, StyledWrapper } from '../shared';
import CashflowSettingsTable from './CashflowSettingsTable';

const CashflowSettings = () => {
  return (
    <StyledWrapper data-testid="cashflow-settings">
      <FlexRow>
        <FlexWrapper>
          <StyledIcon type="money-bill-trend-up" />
          <Name>Manage Cash Flow Pacing Model Settings</Name>
        </FlexWrapper>
      </FlexRow>
      <InfoWithMargin>
        Review or delete all of the cash flow pacing model settings created by your organization.{' '}
        <LinkStyle>
          <a target="_blank" rel="noopener noreferrer" href={PRIVATE_ASSET_CASH_FLOW_HELP_HREF}>
            Learn More
          </a>
        </LinkStyle>
      </InfoWithMargin>
      <Suspense fallback={<Loading title="Cash Flow Pacing Model Settings are loading" />}>
        <TableWrapper>
          <CashflowSettingsTable />
        </TableWrapper>
      </Suspense>
    </StyledWrapper>
  );
};

const InfoWithMargin = styled(Info)`
  margin-top: 8px;
`;

const TableWrapper = styled.div`
  margin-top: 40px;
`;

export default CashflowSettings;
