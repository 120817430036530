import type { FC } from 'react';
import React from 'react';
import { ModalFooter } from '../../../../modal';

export interface BenchmarksSearchProps {
  disableAddButton?: boolean;
  onAddButtonClick: () => void;
  onCancelButtonClick: () => void;
  onReturnToListButtonClick: () => void;
}

const BenchmarksSearchFooter: FC<React.PropsWithChildren<BenchmarksSearchProps>> = ({
  disableAddButton,
  onAddButtonClick,
  onCancelButtonClick,
  onReturnToListButtonClick,
}) => (
  <ModalFooter
    onCancel={onCancelButtonClick}
    primaryLabel="Add"
    primaryDisabled={disableAddButton}
    onPrimaryClick={onAddButtonClick}
    secondaryLabel="Return to List"
    onSecondaryClick={onReturnToListButtonClick}
  />
);

export default BenchmarksSearchFooter;
