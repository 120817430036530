import userActivity from './user-activity-service';

export { default as ephemeralUserActivity } from './ephemeral-user-activty';

export const initializeUserActivity = () => {
  // Initial session start interval
  userActivity.initialize();
};

export default userActivity;
