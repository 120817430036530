import React, { useCallback } from 'react';
import type { SelectableTreeViewBaseProps } from '../tree-view/TreeView';
import { SelectableTreeView } from '../tree-view/TreeView';

export interface CheckboxTreeProps<T> extends SelectableTreeViewBaseProps<T> {
  selectedKeys: string[];
  setSelectedKeys: (keys: string[]) => void;
}

/**
 * CheckboxTree component renders a tree structure suitable for using checkboxes as selection.
 * It manages the selection state internally and
 *
 * @param selectedKeys defines which entries are selected.
 * @param setSelectedKeys function to update the list of selected entries.
 * @param rows A list of rows of type {@link TreeViewRow}. If you have an tree-like object (and it conforms to
 *        {@link TreeNode}, you may use {@link flattenTree} to convert it to {@link TreeViewRow}s.
 * @param rowRenderer used to render individual rows. This needs to handle the rendering of checkbox or other
 *        selection mechanisms. {@link rowRenderer} gets {@link TreeViewRow & @link SelectableRow} as params
 */
export const CheckboxTree = <T,>({ selectedKeys, rows, rowRenderer, setSelectedKeys }: CheckboxTreeProps<T>) => {
  const onSelect = useCallback(
    (key: string) =>
      setSelectedKeys(
        selectedKeys.includes(key) ? selectedKeys.filter((oldKey) => oldKey !== key) : [...selectedKeys, key],
      ),
    [selectedKeys, setSelectedKeys],
  );
  return <SelectableTreeView rows={rows} rowRenderer={rowRenderer} selectedKeys={selectedKeys} onSelect={onSelect} />;
};
