import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';

import { Radio } from 'venn-ui-kit';

interface ChartModeSwitchProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  chartMode: string;
  block: string;
}

const ChartModeSwitch: FC<React.PropsWithChildren<ChartModeSwitchProps>> = ({ block, onChange, chartMode }) => (
  <Wrapper>
    <Title>Mode:</Title>
    <Radio inputId={`${block}_heatmap-mode`} value="heatmap" checked={chartMode === 'heatmap'} onChange={onChange}>
      <RadioLabel>Heatmap</RadioLabel>
    </Radio>
    <Radio
      inputId={`${block}_linechart-mode`}
      value="lineChart"
      checked={chartMode === 'lineChart'}
      onChange={onChange}
    >
      <RadioLabel>Line Chart</RadioLabel>
    </Radio>
  </Wrapper>
);

const Title = styled.span`
  margin-right: 10px;
  font-size: 12px;
  font-weight: 700;
`;

const RadioLabel = styled.span`
  font-size: 12px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  label {
    display: flex;
    align-items: center;
    margin-bottom: 3px;
  }
`;

export default ChartModeSwitch;
