import React from 'react';
import { noop } from 'lodash';
import type { BlockId } from 'venn-state';

export type StudioSidePanelContextProps = {
  /** Show global information in side panel */
  onSelectGlobal: (scrollToTop?: boolean) => void;
  onSelectPage: (pageIndex: number) => void;
  /** Select a block to show in side panel */
  onSelectBlock: (
    refId: BlockId | undefined,
    {
      scrollIntoView,
      isGlobal,
      pageIndex,
    }: {
      /** Whether to scroll the provided ref or page into view. See pageIndex for scrolling to a specific page. */
      scrollIntoView: boolean;
      /** Whether the selection is due to selection the global input settings in IMV1. */
      isGlobal?: boolean;
      /**
       * Page index is provided so that inserted blocks can still be scrolled to, because otherwise when this function runs,
       * the block won't yet be in the page, and we won't be able to scroll to it.
       */
      pageIndex?: number;
    },
  ) => Promise<void> | void;
  /** when focus on text input in title page or section page */
  onFocusSpecialPage: (pageNumber: number) => void;
};

export const defaultStudioSidePanelContextValue: StudioSidePanelContextProps = {
  onSelectGlobal: noop,
  onSelectPage: noop,
  onSelectBlock: noop,
  onFocusSpecialPage: noop,
};

export const StudioSidePanelContext = React.createContext({ ...defaultStudioSidePanelContextValue });
StudioSidePanelContext.displayName = 'StudioSidePanelContext';
