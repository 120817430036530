import type { TopLegendFormatter } from '../../../heat-map/MapWrapper';

const percentFormatter = new Intl.NumberFormat('en-US', {
  useGrouping: true,
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
  style: 'percent',
});

const numberFormatter = new Intl.NumberFormat('en-US', {
  useGrouping: true,
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

export const percentTopLegendFormatter: TopLegendFormatter = (value?: number | null) =>
  value == null ? '--' : percentFormatter.format(value);

export const numberTopLegendFormatter = (value?: number | null) => numberFormatter.format(value || 0);
