import type { ReactNode } from 'react';
import React from 'react';
import styled from 'styled-components';
import type { AllocationCondition, AllocationItem, AllocConstraint, ConstraintValueType } from 'venn-utils';
import { formatAllocation } from 'venn-utils';
import type { Theme } from 'venn-ui-kit';
import { GetColor } from 'venn-ui-kit';
import { compact, isNil } from 'lodash';

type ItemsTypeLabel = 'Portfolio' | 'Strategy' | 'Investment' | 'Unknown' | 'Creating multiple queries...';

export const getConstraintItemTypeAndColor = (
  items: AllocationItem[],
  allInvestments: boolean | undefined,
  rootId: number,
  theme: Theme,
): { itemsType: ItemsTypeLabel; color: string } => {
  if (items.length === 0) {
    return allInvestments
      ? { itemsType: 'Investment', color: theme.Colors.DEPRECATED_DataLineColor.PaleGold }
      : { itemsType: 'Unknown', color: theme.Colors.Grey };
  }

  const hasInvestments = items.some((item) => item.fund);
  const hasStrategies = items.some((item) => item.id !== rootId && isNil(item.fund));
  const hasPortfolio = items.some((item) => item.id === rootId);

  if (Number(hasInvestments) + Number(hasStrategies) + Number(hasPortfolio) > 1) {
    return { itemsType: 'Creating multiple queries...', color: theme.Colors.MidGrey2 };
  }

  return hasInvestments
    ? { itemsType: 'Investment', color: theme.Colors.DEPRECATED_DataLineColor.PaleGold }
    : hasStrategies
      ? { itemsType: 'Strategy', color: theme.Colors.DEPRECATED_DataBarColor.LightDarkBlue }
      : { itemsType: 'Portfolio', color: theme.Colors.DEPRECATED_DataLineColor.PaleBlue };
};

export const getConstraintCondition = (condition: AllocationCondition): string => {
  switch (condition) {
    case 'minAllocation':
      return 'minimum allocation';
    case 'maxAllocation':
      return 'maximum allocation';
    case 'maxTurnover':
      return 'maximum turnover';
    case 'lockedAllocation':
      return 'locked allocation';
    default:
      return '';
  }
};

export const formatConstraintValue = (
  itemsCount: number,
  valueType: ConstraintValueType,
  value: number | undefined,
  doNotShorten?: boolean,
): string => {
  let formattedNumericValue = isNil(value) ? '0' : formatAllocation(value, false);
  const decimalPointIdx = formattedNumericValue.indexOf('.0');
  if (formattedNumericValue.length > 2 && decimalPointIdx !== -1) {
    // Remove .0 from values to save space
    formattedNumericValue =
      formattedNumericValue.slice(0, decimalPointIdx) + formattedNumericValue.slice(decimalPointIdx + 2);
  }

  const displayedValue = doNotShorten ? (isNil(value) ? '0' : value.toLocaleString()) : formattedNumericValue;

  switch (valueType) {
    case 'percentOfPortfolio':
      return `${displayedValue}%`;
    case 'fixedValue':
      return `$${displayedValue}`;
    case 'currentValue':
      return `current value (${itemsCount === 1 ? `$${displayedValue}` : '$ Mixed'})`;
    default:
      return '';
  }
};

export const MAX_CHARS = 32;

export const formatItemsName = (items: AllocationItem[], allInvestments: boolean | undefined): ReactNode => {
  if (allInvestments) {
    return 'All';
  }

  if (items.length === 0) {
    return '--';
  }

  const firstItemName =
    items[0].name.length > MAX_CHARS ? items[0].name.slice(0, MAX_CHARS - 3).concat('...') : items[0].name;

  if (items.length === 1) {
    return items[0].draft ? <Highlight>{firstItemName}</Highlight> : firstItemName;
  }

  let counter = 1;
  let resultText = `${firstItemName}`;
  const resultComponents: ReactNode[] = [
    <>{items[0].draft ? <Highlight>{items[0].name}</Highlight> : items[0].name}</>,
  ];
  for (let i = 1; i < items.length; i++) {
    const temp = `${resultText}, ${items[i].name}`;
    if (temp.length > MAX_CHARS) {
      break;
    }
    resultText = `${temp}`;
    resultComponents.push(
      <>
        ,<Space />
        {items[i].draft ? <Highlight> {items[i].name}</Highlight> : items[i].name}
      </>,
    );
    counter++;
  }

  if (counter !== items.length) {
    resultComponents.push(
      <>
        <Space />+{items.length - counter}
      </>,
    );
  }

  if (items.every((item) => item.draft)) {
    return <Highlight>{resultComponents}</Highlight>;
  }

  return <>{resultComponents}</>;
};

export const splitConstraintsByType = (combinedConstraint: AllocConstraint, rootId: number): AllocConstraint[] => {
  if (combinedConstraint.allInvestments) {
    return [{ ...combinedConstraint, items: [] }];
  }

  if (combinedConstraint.items.length === 0) {
    return [];
  }

  const portfolioConstraint: AllocConstraint = { ...combinedConstraint, items: [] };
  const strategyConstraint: AllocConstraint = { ...combinedConstraint, items: [] };
  const investmentConstraint: AllocConstraint = { ...combinedConstraint, items: [] };

  combinedConstraint.items.forEach((item) => {
    if (!isNil(item.fund)) {
      investmentConstraint.items.push(item);
    } else if (item.id === rootId) {
      portfolioConstraint.items.push(item);
    } else {
      strategyConstraint.items.push(item);
    }
  });

  return compact([
    portfolioConstraint.items.length === 0 ? null : portfolioConstraint,
    strategyConstraint.items.length === 0 ? null : strategyConstraint,
    investmentConstraint.items.length === 0 ? null : investmentConstraint,
  ]);
};

const Highlight = styled.span`
  color: ${GetColor.HighlightDark};
`;

const Space = styled.span`
  margin-left: 2px;
`;
