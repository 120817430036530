import { default as styled } from 'styled-components';

export const FieldWithLabelHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FieldWithLabelHeaderLeft = styled.div`
  display: flex;
  justify-content: flex-start;
`;
