const riskStatistics = {
  analysesPeriod: {
    maxStartTime: 796694399999,
    maxEndTime: 1583020799999,
    frequency: 'MONTHLY',
  },
  relative: false,
  results: {
    performance: {
      historical: {
        subject: {
          totalReturn: 5.630725762173562,
          excessTotalReturn: null,
          volatility: 0.07317041828090988,
          trackingError: null,
          maxUnderperformance: null,
          maxDrawdown: -0.1559771783270918,
          sharpe: 0.7608005329497118,
          informationRatio: null,
          periodReturn: 0.0786050334743793,
          periodExcessReturn: null,
          periodAnnualized: true,
          betaToBenchmark: 0.8692631126479692,
          upsideCaptureRatio: 0.8824105164179045,
          downsideCaptureRatio: 0.764190278045293,
          averageUpMonth: 0.84,
          averageDownMonth: -0.85,
          upsideVolatility: 0.86,
          downsideVolatility: 0.91,
          battingAverage: 0.7541,
          conditionalValueAtRisk95: 0.049133740674098873,
          valueAtRisk: 0.026133740674098873,
          valueAtRisk95: 0.026133740674098873,
          valueAtRisk975: 0.036133740674098873,
          valueAtRisk99: 0.047133740674098873,
          sortino: 0.8325921941673573,
          calmar: 0.11974934215744376,
          skewness: 0.04920039493168164,
          kurtosis: null,
          autocorrelation: 0.92,
          correlation: 0.13,
          downsideCorrelation: 0.14,
        },
        benchmark: {
          totalReturn: 6.9372031381056125,
          excessTotalReturn: null,
          volatility: 0.05151185671306217,
          trackingError: null,
          maxUnderperformance: null,
          maxDrawdown: -0.17570221255932117,
          sharpe: 1.1956305647219012,
          informationRatio: null,
          periodReturn: 0.08639235255479072,
          periodExcessReturn: null,
          periodAnnualized: true,
          betaToBenchmark: 1.0000000000000009,
          upsideCaptureRatio: 1,
          downsideCaptureRatio: 1,
          averageUpMonth: 0.87,
          averageDownMonth: -0.88,
          upsideVolatility: 0.89,
          downsideVolatility: 0.9,
          battingAverage: 0.5455,
          conditionalValueAtRisk95: 0.059133740674098873,
          valueAtRisk: 0.01363166269024781,
          valueAtRisk95: 0.01363166269024781,
          valueAtRisk975: 0.028133740674098873,
          valueAtRisk99: 0.038133740674098873,
          sortino: 1.047559466433408,
          calmar: 0.3255971607034162,
          skewness: -1.2197194785908154,
          kurtosis: 6.847309628286135,
          autocorrelation: 0.93,
          correlation: 0.15,
          downsideCorrelation: 0.16,
        },
      },
      forecast: {},
    },
  },
};
export default riskStatistics;
