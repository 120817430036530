import React from 'react';
import styled from 'styled-components';
import { GetColor, Headline3, Icon, Subtitle1 } from 'venn-ui-kit';

const TagsEmptyState = () => {
  return (
    <Wrapper>
      <Tag type="tag" />
      <Headline3>Tags keep your library organized</Headline3>
      <Subtitle1>Start typing to create and manage tags.</Subtitle1>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 190px;
  flex-direction: column;
`;

const Tag = styled(Icon)`
  color: ${GetColor.HighlightDark};
  font-size: 20px;
  margin-bottom: 15px;
`;

export default TagsEmptyState;
