import React, { useMemo } from 'react';
import PrintContainerDimensions from '../print/PrintContainerDimensions';
import styled from 'styled-components';
import type { OptionType } from 'venn-ui-kit';
import { Button, Icon, TriggerableMenu, TooltipPosition, Tooltip } from 'venn-ui-kit';
import { compact } from 'lodash';
import type { ShareMetadata } from 'venn-api';
import SplitButtonDropdownItem from '../split-button-dropdown/SplitButtonDropdownItem';
import type { ShareTriggerClickCallback } from '../share-button/ShareEmailButton';
import ShareEmailButton from '../share-button/ShareEmailButton';
import ExportButton from '../share-button/ExportButton';
import type { ExportButtonProps } from '../share-button/shared';
import ExportButtonWithScheduled from '../share-button/ExportButtonWithScheduled';

export interface SaveAndShareProps extends ExportButtonProps {
  onSaveClick?: () => void;
  onSaveAsClick?: () => void;
  shareMetaData: Partial<ShareMetadata>;
  disabledTooltipMsg?: string;
  printLabel?: string;
  onShareTriggerClick?: ShareTriggerClickCallback;
}

const NAME_WIDTH = 270;
const SAVE_AS_WIDTH = 145;
const SAVE_WIDTH = 115;
const EXPORT_WIDTH = 110;
// Leave the space for 3 buttons
const minWidth = NAME_WIDTH + SAVE_AS_WIDTH + SAVE_WIDTH + EXPORT_WIDTH;

const renderSaveButtons = (Component: React.ReactNode, disabled?: boolean, disabledTooltipMsg?: string) => {
  if (disabled && disabledTooltipMsg) {
    return (
      <Tooltip position={TooltipPosition.Bottom} content={disabledTooltipMsg}>
        {Component}
      </Tooltip>
    );
  }

  return Component;
};

const SaveAndShare = ({
  onSaveClick,
  onSaveAsClick,
  shareMetaData,
  savedId,
  downloadAll,
  downloadXlsx,
  downloadPdf,
  downloadPNGs,
  disabled,
  disabledTooltipMsg,
  isCompare,
  subjectId,
  onSave,
  hasSavedModified,
  noAccessModifiedView,
  onPrintClick,
  printLabel,
  onShareTriggerClick,
}: SaveAndShareProps) => {
  const { savedViewName } = shareMetaData || {};

  const options: OptionType[] = useMemo(
    () =>
      compact([
        onSaveAsClick
          ? {
              children: <SplitButtonDropdownItem label="Save View as..." onClick={onSaveAsClick} />,
            }
          : null,
        onSaveClick
          ? {
              children: <SplitButtonDropdownItem label="Save View" onClick={onSaveClick} />,
            }
          : null,
      ]),
    [onSaveClick, onSaveAsClick],
  );

  const ExportComponent = isCompare ? ExportButtonWithScheduled : ExportButton;

  return (
    <PrintContainerDimensions>
      {({ width }) => {
        const collapsed = width <= minWidth && process.env.NODE_ENV !== 'test';
        return (
          <ButtonGroup collapsed={collapsed}>
            {!onSaveAsClick && !onSaveClick ? null : !collapsed ? (
              <>
                {onSaveAsClick &&
                  renderSaveButtons(
                    <ButtonWrapper minWidth={SAVE_AS_WIDTH}>
                      <StyledButton
                        dense
                        noMargin
                        onClick={onSaveAsClick}
                        disabled={disabled}
                        className="qa-save-as-view"
                      >
                        <Icon type="save" prefix={disabled && !onSaveClick ? 'fas' : 'far'} />
                        <span>&nbsp;Save View As...</span>
                      </StyledButton>
                    </ButtonWrapper>,
                    disabled,
                    disabledTooltipMsg,
                  )}
                {onSaveClick &&
                  renderSaveButtons(
                    <ButtonWrapper minWidth={SAVE_WIDTH}>
                      <StyledButton
                        dense
                        noMargin
                        onClick={onSaveClick}
                        disabled={disabled}
                        dominant
                        className="qa-save-view"
                      >
                        <Icon type="save" prefix="fas" />
                        <span>&nbsp;Save View</span>
                      </StyledButton>
                    </ButtonWrapper>,
                    disabled,
                    disabledTooltipMsg,
                  )}
              </>
            ) : onSaveClick || onSaveAsClick ? (
              <TriggerableMenu options={options}>
                <Button noMargin dense disabled={disabled}>
                  <Icon type="save" />
                  <Icon type="caret-down" />
                </Button>
              </TriggerableMenu>
            ) : null}
            <ShareEmailButton
              disabled={disabled}
              analyticsDescription="analysis results"
              shareMetaData={shareMetaData}
              onShareTriggerClick={onShareTriggerClick}
            />

            <ButtonWrapper minWidth={collapsed || isCompare ? undefined : EXPORT_WIDTH}>
              <ExportComponent
                onPrintClick={onPrintClick}
                downloadAll={downloadAll}
                downloadXlsx={downloadXlsx}
                downloadPdf={downloadPdf}
                downloadPNGs={downloadPNGs}
                densed={collapsed}
                savedId={savedId}
                currentViewName={savedViewName}
                isCompare={isCompare}
                subjectId={subjectId}
                onSave={onSave}
                hasSavedModified={hasSavedModified}
                noAccessModifiedView={noAccessModifiedView}
                printLabel={printLabel}
              />
            </ButtonWrapper>
          </ButtonGroup>
        );
      }}
    </PrintContainerDimensions>
  );
};

export default React.memo(SaveAndShare);

const StyledButton = styled(Button)``;

const ButtonGroup = styled.div<{ collapsed: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 10px;

  ${StyledButton} {
    margin-right: 10px;
  }

  ${(props) =>
    props.collapsed &&
    `
    button {
      min-width: 40px;
      padding-left: 10px;
      padding-right: 10px;
      margin-left: 10px;
    }
  `}
`;

const ButtonWrapper = styled.div<{ minWidth?: number }>`
  button {
    min-width: ${(props) => (props.minWidth ? props.minWidth : 40)}px;
  }
`;
