import React, { useContext } from 'react';
import type { AnalysisSubject } from 'venn-utils';
import { getSecondaryDisplayLabel } from 'venn-utils';
import type { RangeDebugGroup } from '../../../hooks/useRangesDebug';
import { EmptyStateContent, QuickAction } from '../components/styled';
import RangeDebugContext from '../../../contexts/range-debug-context';

interface ErrorNonPrimarySubjectBrokenProps {
  subject: AnalysisSubject;
  rangeDebugGroup: RangeDebugGroup;
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default ({ subject, rangeDebugGroup }: ErrorNonPrimarySubjectBrokenProps) => {
  const { onClearBenchmark, onClearComparison, onClearCategory } = useContext(RangeDebugContext);

  const { benchmark, secondary } = rangeDebugGroup;
  if ((!benchmark?.subjectError && !secondary?.subjectError) || !subject) {
    return null;
  }

  const secondaryName =
    subject.type === 'portfolio'
      ? getSecondaryDisplayLabel(subject, 'Portfolio as of', 'Portfolio')
      : subject.categoryGroup?.name || 'Category';
  const secondarySubjectType = subject.type === 'investment' && subject.categoryGroup?.name ? ' (Category)' : '';

  return (
    <EmptyStateContent>
      {benchmark?.subjectError && (
        <div>
          <strong>
            {subject.activeBenchmarkName} <i>(Benchmark)</i>
          </strong>{' '}
          has insufficient returns and cannot be analyzed.{' '}
          <QuickAction onClick={onClearBenchmark}>Click to remove benchmark.</QuickAction>
        </div>
      )}
      {secondary?.subjectError && (
        <div>
          <strong>
            {secondaryName}
            <i>{secondarySubjectType}</i>
          </strong>{' '}
          has insufficient returns and cannot be analyzed.{' '}
          {subject.type === 'portfolio' ? (
            <QuickAction onClick={onClearComparison}>Click to remove comparison portfolio.</QuickAction>
          ) : (
            <QuickAction onClick={onClearCategory}>Click to turn category off.</QuickAction>
          )}
        </div>
      )}
    </EmptyStateContent>
  );
};
