import type { RefObject } from 'react';
import React from 'react';
import styled, { css } from 'styled-components';
import { GetColor, Tooltip } from 'venn-ui-kit';
import { checkHasDiff, parseDate } from './logic';
import { compact } from 'lodash';
import { ROW_HEIGHT, VALUE_COL_WIDTH } from './styles';

interface CellProps {
  x: number;
  y: number;
  onChangedValue: ({ x, y }: { x: number; y: number }, value: string) => void;
  handlePaste: (x: number, y: number, data: string[][]) => void;
  value: string;
  isDate: boolean;
  isNewRow: boolean;
  isReturn: boolean;
  originData: string;
  /**
   *  When true the Cell cannot be edited and appears greyed out
   */
  disabled?: boolean;
  /**
   *  When the Cell is disabled this content will be displayed in the tooltip
   */
  disabledTooltipContent?: string;
}

interface CellState {
  editing: boolean;
  value: string;
}

export default class Cell extends React.Component<CellProps, CellState> {
  input: RefObject<HTMLInputElement>;

  constructor(props: CellProps) {
    super(props);
    this.state = {
      editing: false,
      value: props.value,
    };
    this.input = React.createRef<HTMLInputElement>();
  }

  componentDidUpdate(prevProps: CellProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        value: this.props.value,
      });
    }
  }

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ value: e.target.value });
  };

  onKeyPressOnInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      this.hasNewValue((e.target as HTMLInputElement).value);
    }
  };

  onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    this.hasNewValue(e.target.value);
  };

  hasNewValue = (value: string) => {
    const { x, y } = this.props;
    this.props.onChangedValue(
      {
        x,
        y,
      },
      value,
    );
    this.setState({ editing: false });
  };

  clickCell = () => {
    if (!this.props.disabled) {
      this.setState({ editing: true }, () => {
        const target = this.input.current;
        const { value } = this.state;
        if (!value) {
          return;
        }
        target && target.setSelectionRange(0, value.length);
      });
    }
  };

  pasteEvent = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
    const { x, y, handlePaste } = this.props;
    const clipboardData = e.clipboardData || window.clipboardData;

    if (!clipboardData) {
      return;
    }
    const pastedData = clipboardData.getData('Text');
    const clipRows = pastedData.split('\n');
    // Replace % with ''
    const newRows = clipRows.map((row) =>
      row ? row.split(String.fromCharCode(9)).map((item: string) => item.replace('%', '')) : undefined,
    );
    handlePaste(x, y, compact(newRows));
  };

  render() {
    const { value, isNewRow, isDate, isReturn, originData, disabled, disabledTooltipContent } = this.props;
    const showHint = isNewRow && value === '';
    const hasError =
      isNewRow && value === '' ? false : isDate ? Number.isNaN(parseDate(value)) : Number.isNaN(parseFloat(value));
    const displayValue = isDate || hasError ? this.state.value : parseFloat(this.state.value).toFixed(2);

    return (
      <Display
        onClick={this.clickCell}
        onFocus={this.clickCell}
        selected={this.state.editing}
        role="presentation"
        hasError={hasError}
        hasDiff={checkHasDiff(originData, value)}
        isDate={isDate}
        tabIndex={0}
        disabled={disabled}
      >
        {this.state.editing ? (
          <Input
            type="text"
            ref={this.input}
            onBlur={this.onBlur}
            onKeyPress={this.onKeyPressOnInput}
            value={this.state.value}
            onChange={this.onChange}
            autoFocus
            onPaste={this.pasteEvent}
            data-testid="cell-input"
          />
        ) : showHint ? (
          !isDate ? (
            <Tooltip usePortal content="Click to manually edit or paste returns">
              <Hint>Click to add new value</Hint>
            </Tooltip>
          ) : (
            <Tooltip usePortal content="Click to manually edit or paste returns">
              <Hint>Click to add new date</Hint>
            </Tooltip>
          )
        ) : disabled ? (
          <Tooltip usePortal content={disabledTooltipContent || 'Data editing is disabled'}>
            {displayValue}
          </Tooltip>
        ) : (
          displayValue
        )}
        {isReturn && !isDate && <Percentage>%</Percentage>}
      </Display>
    );
  }
}

const CellStyle = css`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 35px;
  margin: 0;
  height: ${ROW_HEIGHT}px;
  border-bottom: 2px solid ${GetColor.Grey};
`;

const Display = styled.div<{
  selected?: boolean;
  hasError?: boolean;
  isDate?: boolean;
  hasDiff: boolean;
  disabled?: boolean;
}>`
  position: relative;
  ${CellStyle}
  ${(props) =>
    props.isDate &&
    css`
      border-right: 2px solid ${GetColor.Black};
    `}
  ${(props) =>
    !props.isDate
      ? css`
          width: ${VALUE_COL_WIDTH}px;
        `
      : 'flex-grow: 1;'}
  ${(props) =>
    props.hasDiff &&
    css`
      color: ${GetColor.HighlightDark};
    `}
  ${(props) =>
    props.selected &&
    css`
      border: 2px solid ${GetColor.HighlightDark};
    `}
  ${(props) =>
    props.hasError &&
    css`
      border: 2px solid ${GetColor.Error};
    `}
   ${(props) =>
    props.disabled &&
    css`
      color: ${GetColor.HintGrey};
      cursor: not-allowed;
    `}
`;

const Percentage = styled.span`
  right: 16px;
  top: 8px;
  color: ${GetColor.HintGrey};
  position: absolute;
`;

const Input = styled.input`
  border: none;
  text-align: right;
  line-height: 17px;
  font-size: 15px;
  padding: 0;
`;

const Hint = styled.span`
  color: ${GetColor.HintGrey};
  line-height: 30px;
`;
