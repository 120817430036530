import React from 'react';
import type { CompositeBenchmark } from 'venn-api';
import { getCompositeBenchmarkByFund, getFund } from 'venn-api';
import head from 'lodash/head';
import { Modal, ModalSize } from '../../modal';
import Benchmarks from '../benchmarks/Benchmarks';
import styled from 'styled-components';
import { ColorUtils, GetColor, Loading, Notifications, NotificationType, ZIndex } from 'venn-ui-kit';
import type { BenchmarksCompositeState } from '../benchmarks/state/compositeState';
import { createBenchmarkCompositeChild } from '../benchmarks/state/compositeState';

interface EditCompositeBenchmarkModalProps {
  onCancel: () => void;
  onSave: () => void;
  fundId: string;
}

interface EditCompositeBenchmarkModalState {
  composite?: BenchmarksCompositeState;
}

export default class EditCompositeBenchmarkModal extends React.Component<
  EditCompositeBenchmarkModalProps,
  EditCompositeBenchmarkModalState
> {
  state: EditCompositeBenchmarkModalState = {
    composite: undefined,
  };

  componentDidMount() {
    this.fetchBenchmark();
  }

  fetchBenchmark = async () => {
    const { fundId } = this.props;
    const compositeBenchmark: CompositeBenchmark = (await getCompositeBenchmarkByFund(fundId)).content;
    const loadChildrenResult = await Promise.all(
      compositeBenchmark.children.map(async (child) => {
        try {
          return { child: createBenchmarkCompositeChild(child, (await getFund(child.fundId)).content) };
        } catch (promiseError) {
          return {
            child: createBenchmarkCompositeChild(child, null),
            errorMessage: (await promiseError).content?.message,
          };
        }
      }),
    );
    const children = loadChildrenResult.map((result) => result.child);
    const errorMessage = head(loadChildrenResult.map((result) => result.errorMessage).filter((error) => error));
    if (errorMessage) {
      Notifications.notify(errorMessage, NotificationType.ERROR);
    }
    this.setState({
      composite: {
        name: compositeBenchmark.name,
        children,
        existingComposite: {
          id: compositeBenchmark.id,
          fundId: compositeBenchmark.fundId,
          originalName: compositeBenchmark.name,
        },
      },
    });
  };

  render() {
    const { onSave, onCancel } = this.props;
    const { composite } = this.state;
    if (!composite) {
      return (
        <Overlay>
          <Loading title="" />
        </Overlay>
      );
    }

    return (
      <Modal onClose={onCancel} closeOnEsc size={ModalSize.Large}>
        <Benchmarks
          ownerName=""
          compositeBenchmark={composite}
          benchmarks={[]}
          onSubmit={onSave}
          onCancel={onCancel}
          onlyShowEditComposite
        />
      </Modal>
    );
  }
}
const overlayBackground = ColorUtils.hex2rgbaFrom(GetColor.White, 0.85);

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: ${overlayBackground};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${ZIndex.Front};
`;
