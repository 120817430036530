import React, { useContext } from 'react';
import { UserContext } from 'venn-components';
import type { ImageDetails } from 'venn-api';
import { GetColor, Icon } from 'venn-ui-kit';
import styled from 'styled-components';
import { Section, UploadImageText, PlusIcon } from '../../shared';
import { buttonize } from 'venn-utils';
import { useStudioImages } from '../../../logic/useStudioImages';
import { useRecoilState } from 'recoil';
import { viewImageId } from 'venn-state';

interface ImagesTabProps {
  selected: string;
}

const ImagesTab = ({ selected }: ImagesTabProps) => {
  const { hasPermission } = useContext(UserContext);
  const { imageUploading, uploadReportImage, images } = useStudioImages();
  const [selectedImage, setSelectedImage] = useRecoilState(viewImageId(selected));

  const isReadOnly = !hasPermission('STUDIO_EDIT_IMAGES');

  return (
    <Section>
      <Content>
        {images?.map((image: ImageDetails) => (
          <BadgeWrapper key={image.id}>
            {image.id === selectedImage ? <DefaultBadge type="check-circle" /> : null}
            <Image
              key={image.id}
              src={`/api/images/${image.id}`}
              alt={image.id}
              selected={image.id === selectedImage}
              {...buttonize(() => !isReadOnly && setSelectedImage(image.id))}
            />
          </BadgeWrapper>
        ))}
      </Content>
      <UploadImageText
        disabled={imageUploading || isReadOnly}
        {...buttonize(() => !isReadOnly && document.getElementById('imageUploadFileDialogId')?.click())}
      >
        <PlusIcon>
          <Icon prefix="fa" type="plus" />
        </PlusIcon>
        Upload Image
      </UploadImageText>
      <input
        type="file"
        id="imageUploadFileDialogId"
        style={{ display: 'none' }}
        accept="image/*"
        onChange={(file) => uploadReportImage(file.target, false, setSelectedImage)}
      />
    </Section>
  );
};

export default ImagesTab;

const Content = styled.div`
  padding-bottom: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
`;

const Image = styled.img<{ selected?: boolean }>`
  cursor: pointer;
  display: inline-block;
  width: 128px;
  height: 128px;
  object-fit: contain;
  border: ${(props) => (props.selected ? '3px solid turquoise' : '1px dotted gray')};
  margin-right: 5px;
  padding: 3px;
`;

const BadgeWrapper = styled.div``;
const DefaultBadge = styled(Icon)`
  position: absolute;
  padding: 3px;
  padding-left: 112px;
  color: ${GetColor.Primary.Main};
`;
