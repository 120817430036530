import { noop } from 'lodash';
import React from 'react';
import type { PortfolioMultiUploaderView } from '../modals/data-uploader/types';

export type UniversalUploaderContextValue = {
  closeFlyout: () => void;
  setView: (view: PortfolioMultiUploaderView) => void;
  refreshedStyling: boolean;
};

const defaultUniversalUploaderContext: UniversalUploaderContextValue = {
  closeFlyout: noop,
  setView: noop,
  refreshedStyling: false,
};

const UniversalUploaderContext = React.createContext<UniversalUploaderContextValue>(defaultUniversalUploaderContext);
UniversalUploaderContext.displayName = 'UniversalUploaderContext';

export default UniversalUploaderContext;
