import type { VennColors, Theme, Typography, Images, Schemes } from 'venn-ui-kit';
import { noop } from 'lodash';
import type { Videos } from '../../../../../venn-ui-kit/src/style/themes';

export const mockVideos: Videos = {
  splashScreenAnimation: 'https://app.venn.twosigma.com/splash-screen-animation.mp4',
  factorLensVideo: 'https://app.venn.twosigma.com/TSFL.mp4',
  productTourVideo: 'https://app.venn.twosigma.com/productWalkthrough.mp4',
  aisDemoVideo: 'https://app.venn.twosigma.com/ais_demo.mp4',
};
export const mockGreenVideos: Videos = {
  splashScreenAnimation: 'https://dev.altivant.com/splash-screen-animation.mp4',
  factorLensVideo: 'https://dev.altivant.com/TSFL.mp4',
  productTourVideo: 'https://dev.altivant.com/productWalkthrough.mp4',
  aisDemoVideo: 'https://dev.altivant.com/ais_demo.mp4',
};

export const mockImages: Images = {
  holdingsAsset: 'default/holdingsAsset.png',
  extrapolationAsset: '',
  reportLabAsset: '',
  macroScenario: '',
  scenario: '',
  vennBackground: '',
  six: '',
  portfolioLabs: '',
  trend: '',
  customFonts: '',
};
export const mockGreenImages: Images = {
  holdingsAsset: 'green/holdingsAsset.png',
  extrapolationAsset: '',
  reportLabAsset: '',
  macroScenario: '',
  scenario: '',
  vennBackground: '',
  six: '',
  portfolioLabs: '',
  trend: '',
  customFonts: '',
};
export const mockTypography: Typography = {
  fontFamily: 'Roboto,sans-serif',
};

export const mockColors: VennColors = {
  Primary: {
    Main: '#00CCCC',
    Dark: '#00818A',
    Light: '#D4F3F2',
    Medium: '#94BEC3',
  },
  PaleTeal: '#94BEC3',
  WhiteGrey: '#F7F7F7',
  PaleGrey: '#F1F1F2',
  LightGrey: '#B2B2B2',
  LightGrey2: '#E6E6E6',
  Grey: '#D8D8D8',
  MidGrey1: '#BBBDBF',
  MidGrey2: '#999999',
  HintGrey: '#767676',
  DarkGrey: '#666666',
  DarkGrey2: '#242A2F',
  HoverGrey: '#F3F1F1',
  Black: '#10161B',
  TransparentBlack: 'rgba(16, 22, 27, 0.9)',
  White: '#FFFFFF',
  HighlightDark: '#6600FF',
  HighlightDeepDark: '#5E00EB',
  HighlightLight: '#AC75FF',
  HighlightLightBackground: '#ECDEFF',
  HighlightBackground: '#302683',
  Alert: '#DE9F9B',
  Error: '#B73A3A',
  Warning: '#FFAB00',
  PeerGroupCarouselColor: '#11696F',

  Gold: '#7C7026',
  PaleGold: '#A08D14',
  Green: '#008854',
  PaleBlue: '#189ACD',
  DarkBlue: '#0072AF',
  DeepDarkBlue: '#006499',
  Pink: '#AE5A88',

  Optimization: { Negative: '#ba1c1c', Positive: '#177c34' },

  DEPRECATED_DataBarColor: {
    LightGold: '#9E9459',
    LightPaleGold: '#CBBC5B',
    LightPaleBlue: '#6ED6FF',
    LightPink: '#DE9BC0',
    LightDarkBlue: '#3396C7',
  },

  DataBarColor: { LightDarkBlue: '#ff8731', LightGold: '#0284b5', LightPaleBlue: '#5fb3ba' },

  DEPRECATED_DataLineColor: {
    Gold: '#867A30',
    PaleGold: '#BEAB32',
    Green: '#00B072',
    PaleBlue: '#4ACCFF',
    DarkBlue: '#007CB9',
    Pink: '#D682B0',
    DeepGreen: '#00897B',
  },

  DataLineColor: {
    DarkBlue: '#ff8731',
    DeepGreen: '#a55cc5',
    Gold: '#0284b5',
    Green: '#f26686',
    PaleBlue: '#5fb3ba',
    PaleGold: '#e89366',
    Pink: '#415463',
    Yellow: '#6c7b80',
  },

  HoldingsColor: {
    AssetColor: {
      Cash: '#415463',
      Convertible: '#ff8731',
      'Non-US Fixed Income': '#f26686',
      'Non-US Stock': '#e89366',
      Other: '#7798b3',
      Preferred: '#5fb3ba',
      'US Fixed Income': '#6c7b80',
      'US Stock': '#0284b5',
      Unknown: '#7c535c',
    },
    SectorColor: {
      'Cash And Equivalents': '#f26686',
      Corporate: '#5fb3ba',
      Cyclical: '#0284b5',
      Defensive: '#e89366',
      Derivative: '#ff8731',
      Government: '#415463',
      Municipal: '#a55cc5',
      Securitized: '#d95b78',
      Sensitive: '#6c7b80',
      Unknown: '#7c535c',
    },
  },
  PeerGroupColor: { GradientHigh: '#B2F0F0', GradientLow: '#d9f8f8' },

  CashFlowColor: {
    Historical: '#0284b5',
    Projected: '#e89366',
    Typical: '#6c7b80',
  },

  StudioSubjectColor: {
    A1: '#0284b5',
    A2: '#e89366',
    A3: '#6c7b80',
    A4: '#f26686',
    A5: '#5fb3ba',
    A6: '#ff8731',
    A7: '#415463',
    A8: '#a55cc5',
    A9: '#d95b78',
  },

  DEPRECATED_DivergingColor: {
    A5: '#4E2508',
    A4: '#8F5017',
    A3: '#B7793A',
    A2: '#DCB887',
    A1: '#EEDDC7',
    MID: '#F5F5F5',
    B1: '#CAE4E6',
    B2: '#8CC8CC',
    B3: '#3E9397',
    B4: '#11696F',
    B5: '#003638',
  },

  DivergingColor: {
    A1: '#E9CACA',
    A2: '#DD9E9E',
    A3: '#D27373',
    A4: '#C64747',
    A5: '#ba1c1c',
    B1: '#C9DDCE',
    B2: '#9CC5A8',
    B3: '#70AC81',
    B4: '#43945B',
    B5: '#177c34',
    MID: '#f5f5f5',
  },

  NavigationBarColor: {
    Active: '#2F3942',
    ActiveBackground: '#479EA5',
    ActiveLighter: '#009AA6',
    InactiveBackground: '#2F3942',
    ShimmerBackground: '#505A63',
    UpsellInactiveBackground: '#211249',
    UpsellActiveLighter: '#3B0B8D',
    UpsellActiveBackground: '#421595',
  },

  UNSAFE: {
    LightBlue: '#B7E3F5',
    Separator: '#E2E2E2',
    Silver: '#D4D8DC',
    Azure: '#2196F3',
    Steel: '#76848F',
    Red: '#B82A2B',
  },

  GreyScale: {
    Grey10: '#F5F7FA',
    Grey20: '#EBEFF5',
    Grey30: '#DDE3ED',
    Grey40: '#C8D1E0',
    Grey50: '#AFBACC',
    Grey60: '#8E99AB',
    Grey70: '#707A8A',
    Grey80: '#58606E',
    Grey90: '#434A54',
    Grey100: '#333840',
  },
};

export const mockSchemes: Schemes = {
  BarChartColors: { comparisonColor: '#6c7b80', lineColor: '#F1F1F2', mainColor: '#0284b5', secondaryColor: '#e89366' },
  FactorColors: { comparisonColor: '#D27373', labelColor: '#666666', mainColor: '#70AC81' },
  LineChartColors: {
    comparisonColor: '#6c7b80',
    lineColor: '#F1F1F2',
    mainColor: '#0284b5',
    secondaryColor: '#e89366',
  },
  Proxy: {
    badgeBg: '#A08D14',
    badgeText: '#FFFFFF',
    darkProxyLine: '#867A30',
    extrapolation: '#4ACCFF',
    invertedSubjectLine: '#FFFFFF',
    proxyLine: '#BEAB32',
    subjectLine: '#10161B',
  },
};
export const mockTheme: Theme = {
  Images: mockImages,
  Typography: mockTypography,
  Colors: mockColors,
  Schemes: mockSchemes,
  Videos: mockVideos,
  setCustomColors: noop,
  customColors: undefined,
  setDebugColors: noop,
  inPrintMode: false,
  inPrintModeOrReportLab: false,
};
