import React, { useMemo, useState } from 'react';
import { InfoHeading } from './styles';
import { Body1, getAppTitle, GetColor, getTextThemeProvider, HyperLink, Icon } from 'venn-ui-kit';
import styled from 'styled-components';
import type { AddeparOption, AddeparViewFilter } from 'venn-api';

export const getFilterText = (filter: AddeparViewFilter, optionMap: { [key: string]: string }) => {
  const attributeLabel = optionMap[filter.attribute] ?? filter.attribute;

  switch (filter.operator) {
    case 'RANK':
      return `Include items whose attribute ${attributeLabel} ranks in the ${
        filter.rankOrder?.toLocaleLowerCase() ?? 'top'
      } ${filter.rankValue ?? '0'}.`;
    case 'RANGE':
      return `Include items whose attribute ${attributeLabel} ranges ${
        filter.ranges?.map((r) => `from ${r.from} to ${r.to}`).join(', ') ?? ''
      }.`;
    case 'INCLUDE':
      return `Only include items whose attribute ${attributeLabel} has value ${
        filter.values?.map((v) => `"${v}"`).join(', ') ?? ''
      }.`;
    case 'EXCLUDE':
      return `Exclude items whose attribute ${attributeLabel} has value ${
        filter.values?.map((v) => `"${v}"`).join(', ') ?? ''
      }.`;
  }
  return '';
};

const ContactInfo = () => (
  <>
    <HyperLink href={`mailto:${getTextThemeProvider().supportEmail}`}>Contact {getAppTitle()}</HyperLink> if you would
    like to modify your Addepar filters for this view.
  </>
);

const NoFilterInfo = () => (
  <>
    <InfoHeading>No filters available</InfoHeading>
    <Body1>
      <ContactInfo />
    </Body1>
  </>
);

interface FiltersProps {
  filters: AddeparViewFilter[];
  filterOptions: AddeparOption[];
}

const Filters = ({ filterOptions, filters }: FiltersProps) => {
  const [isOpen, setOpen] = useState(false);
  const optionMap = useMemo(
    () =>
      filterOptions.reduce((prev, current) => {
        prev[current.key] = current.label;
        return prev;
      }, {}),
    [filterOptions],
  );
  return (
    <FilterSection>
      {filters.length > 0 ? (
        <>
          <InfoHeading>Filters</InfoHeading>
          <Body1>
            {filters.length} filter{filters.length > 1 ? 's have' : ' has'} been automatically applied.
            <ToggleButton onClick={() => setOpen(!isOpen)}>
              {isOpen ? 'Hide filters' : 'Show filters'}
              <Icon type={isOpen ? 'caret-up' : 'caret-down'} />
            </ToggleButton>
            {isOpen && (
              <div>
                <ul>
                  {filters.map((filter) => (
                    <li key={`${filter.operator}-${filter.attribute}`}>{getFilterText(filter, optionMap)}</li>
                  ))}
                </ul>
                <ContactInfo />
              </div>
            )}
          </Body1>
        </>
      ) : (
        <NoFilterInfo />
      )}
    </FilterSection>
  );
};

export default Filters;

const ToggleButton = styled.button`
  color: ${GetColor.Primary.Dark};
  padding-left: 5px;
  i {
    margin-left: 5px;
  }
`;

const FilterSection = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
`;
