import type { SearchResultWithUIState } from './types';
import { StyledCell, StyledIcon } from './StyledComponents';
import React from 'react';
import type { FrequencyEnum, ProxyTypeEnum } from 'venn-api';
import { Dates, getFormattedProxyType } from 'venn-utils';
import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';

export const emptyRenderer = () => {
  return <StyledCell className="empty-table-cell">--</StyledCell>;
};

export const iconsRender = (item: SearchResultWithUIState) => {
  if (!item.portfolioId && !item.fundId) {
    return '';
  }
  if (item.portfolioId) {
    return <StyledIcon type="th" colorGetter={GetColor.DEPRECATED_DataLineColor.PaleBlue} />;
  }

  if (item.investmentSource === 'CUSTOM') {
    return <StyledIcon type="layer-group" colorGetter={GetColor.DEPRECATED_DataLineColor.Pink} />;
  }

  return <StyledIcon type="square" colorGetter={GetColor.DEPRECATED_DataLineColor.Gold} />;
};
export const renderTimespan = (
  title: string,
  start?: number,
  end?: number,
  frequency?: FrequencyEnum,
  highlight?: boolean,
) => {
  if (start && end && frequency) {
    const startYear = Dates.toDDMMMYYYY(start, frequency);
    const endYear = Dates.toDDMMMYYYY(end, frequency);
    return (
      <p>
        {title}:&nbsp;
        <SpanText className="tooltip-value" hightlight={!!highlight}>
          {startYear !== endYear ? `${startYear} - ${endYear}` : startYear}
        </SpanText>
      </p>
    );
  }
  return null;
};

export const renderProxyType = (proxyType?: ProxyTypeEnum) => {
  if (proxyType) {
    return (
      <p>
        Proxy Type: <span className="tooltip-value">{getFormattedProxyType(proxyType)}</span>
      </p>
    );
  }
  return null;
};

export const renderExtrapolation = (extrapolated: boolean) => {
  return extrapolated ? (
    <p>
      Extrapolation: <span className="tooltip-value">On</span>
    </p>
  ) : null;
};

const SpanText = styled.span<{ hightlight: boolean }>`
  color: ${(props) => (props.hightlight ? GetColor.Gold : GetColor.White)};
`;
