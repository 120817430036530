import React from 'react';
import { getDateBehavior, hasDisabledDateRange, requiresAnalysisRequest, useDebugValue } from 'venn-utils';
import { useRecoilValue } from 'recoil';
import { blockDateRange, blockPrivateDefaultAsOfDateBehavior, blockSettings } from 'venn-state';
import StyledEmptyState from '../StyledEmptyState';
import type { ErrorWrapper } from './errorWrapperTypes';

export const DateRangeErrorWrapper: ErrorWrapper = ({ blockId, children }) => {
  const dateRange = useRecoilValue(blockDateRange(blockId));
  const blockSetting = useRecoilValue(blockSettings(blockId));
  const startAsOfLastTransaction = useRecoilValue(blockPrivateDefaultAsOfDateBehavior(blockId));
  const dateBehaviour = getDateBehavior(blockSetting.customBlockType);
  const notUsingDateRange = dateBehaviour === 'END_DATE_RANGE' && startAsOfLastTransaction;
  const needsDateRange =
    requiresAnalysisRequest(blockSetting.customBlockType) &&
    !(hasDisabledDateRange(blockSetting.customBlockType) || notUsingDateRange);

  const debugState = useDebugValue('debugBlockErrorState');
  const forceNoDateRange = debugState === 'NoDateRange';
  const forceNoOverlap = debugState === 'NoOverlap';

  if (needsDateRange) {
    if (!dateRange || forceNoDateRange) {
      return (
        <StyledEmptyState
          selectedRefId={blockId}
          header="Select a date range input"
          message="Configure the block's date range input in the block settings panel."
        />
      );
    }
    if (dateRange.maxRange.from === undefined || dateRange.maxRange.to === undefined || forceNoOverlap) {
      return (
        <StyledEmptyState
          selectedRefId={blockId}
          header="Unable to run analysis"
          message={
            <>
              <div>Unable to calculate the selected date range.</div>
              <div>
                The subjects included in the date range have <b>no common overlap.</b>
              </div>
            </>
          }
        />
      );
    }
  }

  return <>{children}</>;
};
