import type { Benchmark, Fund } from 'venn-api';
import type { AnalysisSubjectType, AnyDuringEslintMigration } from 'venn-utils';
import type { SearchMenuItem } from '../../../search-menu';
import type { BenchmarkAction } from './benchmarkActions';

export default function reduceBenchmarks(benchmarks: Benchmark[], action: BenchmarkAction): Benchmark[] {
  switch (action.type) {
    case 'BenchmarkAdd':
      return addBenchmark(benchmarks, action.payload.benchmark);

    case 'BenchmarkAddFromSearch':
      return addBenchmark(benchmarks, createBenchmarkFromSearchResult(action.payload));

    case 'BenchmarkUpdate':
      return updateBenchmark(benchmarks, action.payload);

    case 'BenchmarkDelete':
      const nextBenchmarks = benchmarks.filter((b) => b !== action.payload);
      if (nextBenchmarks.length > 0 && action.payload.primary) {
        nextBenchmarks[0] = { ...nextBenchmarks[0], primary: true };
      }
      return nextBenchmarks;

    case 'BenchmarkSetDefault':
      return benchmarks.map((b) => {
        if (b === action.payload) {
          return { ...b, primary: !b.primary };
        }
        if (b.primary) {
          return { ...b, primary: false };
        }
        return b;
      });

    default:
      return benchmarks;
  }
}

function addBenchmark(benchmarks: Benchmark[], primary: Benchmark): Benchmark[] {
  if (benchmarks.length === 0) {
    if (primary.primary) {
      return [primary];
    }
    return [{ ...primary, primary: true }];
  }
  return [...benchmarks, primary];
}

function updateBenchmark(benchmarks: Benchmark[], benchmark: Benchmark): Benchmark[] {
  return benchmarks.map((b) => {
    if (
      (b.fundId !== undefined && b.fundId === benchmark.fundId) ||
      (b.portfolioId !== undefined && b.portfolioId === benchmark.portfolioId)
    ) {
      return b.primary !== benchmark.primary ? { ...benchmark, primary: b.primary } : benchmark;
    }
    return b;
  });
}

function createBenchmarkFromSearchResult(result: SearchMenuItem): Benchmark {
  const nodeType =
    result.value?.fund?.assetType === 'BENCHMARK'
      ? NodeType.CompositeBenchmark
      : getNodeType(result.value!.type, result.value!.fund);
  return {
    fundId: nodeType !== NodeType.Portfolio ? (result.value!.id as string) : undefined,
    name: result.label,
    portfolioId: nodeType === NodeType.Portfolio ? (result.value!.id as number) : undefined,
    primary: false,
    type: nodeType === NodeType.Fund ? 'INVESTMENT' : nodeType === NodeType.Portfolio ? 'PORTFOLIO' : 'COMPOSITE',
  };
}

function getNodeType(searchResultType: AnalysisSubjectType, fund?: Fund): NodeType {
  switch (searchResultType) {
    case 'investment':
      // TODO: (VENN-20577 / TYPES) is the generated type wrong? why do we need AnyDuringEslintMigration?
      if ((fund?.fundSourceType as AnyDuringEslintMigration)?.id === 5) {
        return NodeType.CompositeBenchmark;
      }
      return NodeType.Fund;
    case 'portfolio':
      return NodeType.Portfolio;
    default:
      return NodeType.Fund;
  }
}

export enum NodeType {
  Fund = 'Fund',
  Portfolio = 'Portfolio',
  CompositeBenchmark = 'Composite Benchmark',
}
