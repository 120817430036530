import { atomFamily } from 'recoil';
import type { BlockId } from '../types';

export const viewTitle = atomFamily<string | undefined, BlockId>({
  key: 'viewTitle',
  default: undefined,
});

export const viewSectionName = atomFamily<string | undefined, BlockId>({
  key: 'viewSectionName',
  default: undefined,
});

export const viewSubtitle = atomFamily<string | undefined, BlockId>({
  key: 'viewSubtitle',
  default: undefined,
});
