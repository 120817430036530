import type { ReactNode } from 'react';
import React, { useMemo, useState } from 'react';
import type { Portfolio } from 'venn-api';
import type { DropMenuCheckboxItem, DropMenuItem } from 'venn-ui-kit';
import { BaseDropMenu, CheckboxMenu, GetColor, Hint } from 'venn-ui-kit';
import type { AllocationItem } from 'venn-utils';
import { formatAllocation } from 'venn-utils';
import { EXPERIMENTAL_FUND_CLASSNAME, getPortfolioMenuItems, mapStrategiesToChildNames } from './dropdownUtils';
import { isNil } from 'lodash';
import SearchBar from '../../search-bar/SearchBar';
import styled from 'styled-components';
import SmallToggle from '../../toggle/SmallToggle';

interface ConstraintSubjectDropdownProps {
  portfolio: Portfolio;
  strategiesOnly?: boolean; // Only show strategy nodes
  singleSelection?: boolean; // Allow selection of 1 item only + hide checkboxes
  disableRoot?: boolean;
  disableAll?: boolean;
  disableAllInvestmentsToggle?: boolean;
  allInvestmentsSelected: boolean;
  selected: AllocationItem[];
  onChangeSelection: (selected: AllocationItem[], allInvestments?: boolean) => void;
  onClose?: () => void;
  children: (open: boolean, onToggle: (open?: boolean) => void) => ReactNode;
  headerText?: string;
}

const MENU_WIDTH = 382;
const MENU_HEADER_HEIGHT = 85;

const ConstraintSubjectDropdown = ({
  children,
  portfolio,
  strategiesOnly,
  singleSelection,
  disableRoot,
  disableAll,
  disableAllInvestmentsToggle,
  allInvestmentsSelected,
  selected,
  onChangeSelection,
  onClose,
  headerText,
}: ConstraintSubjectDropdownProps) => {
  const [search, setSearch] = useState('');

  const options = useMemo(
    () =>
      getPortfolioMenuItems(portfolio)
        .filter((item) => !strategiesOnly || isNil(item.value.fund))
        .map((option: DropMenuItem<AllocationItem>) => ({
          ...option,
          checked: selected.some((item) => item.id === option.value.id),
          disabled: allInvestmentsSelected || disableAll || (disableRoot && option.value.id === portfolio.id),
          label: `${option.label} ($${formatAllocation(option.value.allocation ?? 0, false)})`,
          alwaysShowTooltip: true,
        })),
    [portfolio, selected, allInvestmentsSelected, disableRoot, disableAll, strategiesOnly],
  );

  const strategyToChildNames = useMemo(() => mapStrategiesToChildNames(portfolio), [portfolio]);

  const filteredOptions = useMemo(
    () =>
      options.filter((option) => {
        const isStrategy = isNil(option.value.fund);
        const searchTerm = search.toLowerCase();
        const foundInLabel = option.label.toLowerCase().includes(searchTerm);

        if (foundInLabel || !isStrategy || strategiesOnly) {
          return foundInLabel;
        }

        const foundInChildren =
          strategyToChildNames.has(option.value.id) &&
          strategyToChildNames.get(option.value.id)!.some((childName) => childName.toLowerCase().includes(searchTerm));

        return foundInChildren;
      }),
    [options, search, strategyToChildNames, strategiesOnly],
  );

  return (
    <BaseDropMenu
      usePortal
      filteredItems={filteredOptions}
      triggerComponent={(expanded, __, onToggle) => <>{children(expanded, onToggle)}</>}
      onCollapse={onClose}
      menuComponent={(_, collapse, menuClassName) => (
        <Container className={menuClassName}>
          <CheckboxMenu
            items={filteredOptions}
            singleSelection={singleSelection}
            onChange={(items: DropMenuCheckboxItem<AllocationItem>[]) =>
              onChangeSelection(items.filter(({ checked }) => checked).map(({ value }) => value))
            }
            onCollapse={singleSelection ? collapse : undefined}
            header={
              <Header>
                <SearchBar
                  value={search}
                  placeholder={`Search ${strategiesOnly ? 'strategies ' : ''}within ${portfolio.name.slice(0, 28)}...`}
                  onChange={setSearch}
                />
                {!strategiesOnly && (
                  <SelectAll disabled={disableAllInvestmentsToggle}>
                    Select all investments
                    <SmallToggle
                      toggled={allInvestmentsSelected}
                      disabled={disableAllInvestmentsToggle}
                      onToggle={() =>
                        onChangeSelection(
                          options.map((option) => option.value).filter(({ fund }) => !isNil(fund)),
                          !allInvestmentsSelected,
                        )
                      }
                    />
                  </SelectAll>
                )}
                {headerText && (
                  <Spaced>
                    <Hint>{headerText}</Hint>
                  </Spaced>
                )}
              </Header>
            }
            hideSelectAll
            ignoreHierarchySelect
            showHierarchyLines
            width={MENU_WIDTH}
            headerHeight={!strategiesOnly ? MENU_HEADER_HEIGHT : undefined}
          />
        </Container>
      )}
    />
  );
};

export default ConstraintSubjectDropdown;

const Container = styled.div`
  padding-left: 5px;
  padding-top: 5px;

  .${EXPERIMENTAL_FUND_CLASSNAME} {
    color: ${GetColor.HighlightDark};
  }
`;

const Header = styled.div`
  width: 100%;
  margin-top: 11px;
  padding: 0;
`;

const SelectAll = styled.div<{ disabled?: boolean }>`
  line-height: 24px;
  margin-top: 5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: ${({ disabled }) => (disabled ? GetColor.LightGrey : GetColor.Black)};
  & > :last-child {
    margin-left: 5px;
  }
`;

const Spaced = styled.div`
  margin: 4px;
`;
