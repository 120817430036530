import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Button, ColorUtils, GetColor, Headline2, Hint, Icon, Tooltip, TooltipBodyDirection } from 'venn-ui-kit';
import type { Solution, ExcelSheetData } from 'venn-components';
import {
  CreatePortfolioModal,
  ForecastsMenuLauncher,
  PortfolioLabContext,
  Downloadable,
  UserContext,
} from 'venn-components';
import { getSolutionAttributes } from './solutionUtils';
import useSelectedHeaderActions from '../logic/useSelectedHeaderActions';
import { isNil } from 'lodash';
import { SpecialCssClasses, useModal } from 'venn-utils';
import type { Portfolio } from 'venn-api';
import type { AnalysisPeriodParams } from '../logic/useAvailablePeriodForForecast';
import { formatRequestPeriod } from '../logic/useAvailablePeriodForForecast';

interface SelectedSolutionHeaderProps {
  selectedSolution: Solution;
  downloadableContentRef: React.RefObject<HTMLDivElement>;
  relative: boolean;
  excelSheetData: ExcelSheetData[];
  requestPeriodParams: Partial<AnalysisPeriodParams>;
}

const SelectedSolutionHeader = ({
  selectedSolution,
  downloadableContentRef,
  relative,
  excelSheetData,
  requestPeriodParams,
}: SelectedSolutionHeaderProps) => {
  const { hasPermission } = useContext(UserContext);
  const theme = useContext(ThemeContext);
  const { onForecastUpdated, solutionPortfolio } = useContext(PortfolioLabContext);
  const { title, color, icon } = getSolutionAttributes(theme, selectedSolution);
  const { loading, onAnalyze, onAddToCompare, onSaveAndTag, savedPortfolioId } = useSelectedHeaderActions();

  const [isCreatePortfolioOpen, openCreatePortfolioModal, closeCreatePortfolioModal] = useModal();
  const selectedPortfolio = solutionPortfolio?.portfolio;

  return (
    <Container>
      <Header baseColor={color}>
        <Group>
          <IconBackground baseColor={color}>
            <Icon type={icon} />
          </IconBackground>
          <Headline2>{title}</Headline2>
        </Group>
        <Group>
          <ButtonGroup className={SpecialCssClasses.NotDownloadable}>
            <StyledButton
              icon="line-columns"
              prefix="+"
              disabled={isNil(selectedPortfolio) || loading}
              onClick={onAddToCompare}
            >
              Add to compare
            </StyledButton>
            <StyledButton icon="calculator" disabled={isNil(selectedPortfolio) || loading} onClick={onAnalyze}>
              Analyze
            </StyledButton>
          </ButtonGroup>
          {selectedSolution?.category === 'Current' ? null : savedPortfolioId ? (
            <Tooltip
              content="Portfolio is saved under tag 'Portfolio Lab'"
              bodyDirection={TooltipBodyDirection.Left}
              maxWidth={250}
            >
              <SavedLabel className={SpecialCssClasses.NotDownloadable}>
                <Icon type="check" />
                Saved
              </SavedLabel>
            </Tooltip>
          ) : (
            <SaveButton
              dominant
              icon="tag"
              onClick={openCreatePortfolioModal}
              className={SpecialCssClasses.NotDownloadable}
            >
              Save
            </SaveButton>
          )}
          <DownloadableWrapper>
            <Downloadable
              png
              excelMultiSheet={excelSheetData}
              tracking={{
                description: 'PORTFOLIO_LAB',
                relativeToBenchmark: relative,
                subjectType: 'portfolio',
                subjectId: selectedPortfolio?.id,
                userUploaded: false,
              }}
              options={{
                fileName: `${title}`,
              }}
              target={downloadableContentRef}
            />
          </DownloadableWrapper>
        </Group>
      </Header>
      <Subheader style={{ display: 'flex' }}>
        <Hint>
          <Row>
            <strong>Analysis Period:&nbsp;</strong>
            <ColoredPeriod highlight={isNil(requestPeriodParams.period)}>
              {!isNil(requestPeriodParams.start) && !isNil(requestPeriodParams.end) && (
                <>
                  {isNil(requestPeriodParams.period) ? '< ' : ''}3 Years{' - '}
                </>
              )}
              <strong>{formatRequestPeriod(requestPeriodParams)}</strong>
            </ColoredPeriod>
            &nbsp; | Metrics reflect your constraints &nbsp;
            <Tooltip
              content={
                <TooltipContent>
                  Constraints include the objective (set above), as well as the allocation and factor exposure
                  constraints (set in the side "Inputs" menu).
                </TooltipContent>
              }
            >
              <Icon type="info-circle" prefix="far" />
            </Tooltip>
            , historical data, and&nbsp;
            <ForecastsMenuLauncher
              ctaPurpose="Edit forecasts from Portfolio Lab - selected solution header "
              onDefaultForecastUpdated={onForecastUpdated}
              onResidualForecastUpdated={onForecastUpdated}
              isReadOnly={!hasPermission('EDIT_FORECASTS')}
            />
            <Tooltip
              content={
                <TooltipContent>
                  Forecast metrics are calculated using up to 3 years (minimum 1 year) of daily or monthly data and your
                  long-term views.
                </TooltipContent>
              }
            >
              <Icon type="question-circle" prefix="far" />
            </Tooltip>
          </Row>
          <PrintLabel>long-term forecasts</PrintLabel>
        </Hint>
      </Subheader>
      {isCreatePortfolioOpen && (
        <CreatePortfolioModal
          baseline={selectedPortfolio}
          disableBaselineSelection
          hideBasePortfolio
          onClose={closeCreatePortfolioModal}
          onSubmit={(createdPortfolio: Portfolio) => {
            closeCreatePortfolioModal();
            onSaveAndTag(createdPortfolio);
          }}
        />
      )}
    </Container>
  );
};

export default SelectedSolutionHeader;

const Container = styled.div`
  border-bottom: 1px solid ${GetColor.Grey};
  @media print {
    page-break-inside: avoid;
    page-break-before: always;
  }
`;

const Header = styled.div<{ baseColor: string }>`
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ baseColor }) => ColorUtils.opacify(baseColor, 0.1)};
  padding: 0 20px;
`;

const Subheader = styled.div`
  min-height: 32px;
  display: flex;
  align-items: center;
  padding: 6px 20px;
  line-height: 18px;
`;

const Row = styled.div`
  display: flex;
`;

const TooltipContent = styled.div`
  text-align: center;
  width: 236px;
  padding: 5px;
`;

const IconBackground = styled.div<{ baseColor: string }>`
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: ${({ baseColor }) => ColorUtils.opacify(baseColor, 0.25)};
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    font-size: 18px;
    color: ${GetColor.Black};
  }
`;

const Group = styled.div`
  display: flex;
  align-items: center;
  & > div:not(:last-child) {
    margin-right: 8px;
  }
  button {
    margin-right: 0;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${GetColor.Primary.Dark};
  border-radius: 2px;

  @media print {
    display: none;
  }

  button {
    border: none;
    margin-right: 0;
    border-radius: 0;
  }
  button:first-child {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  button:last-child {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
`;

const StyledButton = styled(Button)`
  min-height: 38px;
`;

const SavedLabel = styled.div`
  min-height: 40px;
  padding: 9px 15px;
  display: flex;
  align-items: center;
  i {
    margin-right: 8px;
  }
  @media print {
    display: none;
  }
`;

const DownloadableWrapper = styled.div`
  @media print {
    display: none;
  }

  i {
    font-size: 11px;
  }
  margin-left: 8px;
`;

const SaveButton = styled(Button)`
  @media print {
    display: none;
  }
`;

const PrintLabel = styled.span`
  display: none;
  @media print {
    display: inline;
  }
`;

const ColoredPeriod = styled.span<{ highlight?: boolean }>`
  color: ${({ highlight }) => (highlight ? GetColor.HighlightDark : GetColor.Black)};
`;
