import type { AnalysisGroup } from '../../../analysis';
import type { VenncastReturn } from 'venn-api';

export const mockCumulativeAnalysisGroup = {
  benchmark: [
    [1577836799999, 0],
    [1580515199999, -0.0028443083207275732],
    [1583020799999, -0.023257015009051085],
  ],
  category: undefined,
  message: undefined,
  subject: [
    [1577836799999, 0],
    [1580515199999, -0.0029721192283256315],
    [1583020799999, -0.020739847926409705],
  ],
};

export const mockCumulativeVenncast = {
  subject: {
    lastReturnDate: 1583020799999,
    residualRisk: 0.09694156584845093,
    estimateBounds: [
      [1583193599999, -0.010523726886199403, -0.005860381820782967],
      [1583279999999, -0.009498376523745522, -0.003018150884626425],
      [1583366399999, -0.004683828754058261, 0.004660326918850988],
      [1583452799999, -0.006271765384402217, 0.0038680370176269463],
      [1583539199999, -0.0058894727105578415, 0.005481209418245259],
      [1583798399999, -0.022465717725280943, -0.008541898500586398],
      [1583884799999, -0.024244705889539753, -0.00897860282546259],
      [1583971199999, -0.03703496941097628, -0.02090659771449699],
    ],
    estimateLowerBound: [
      [1583193599999, -0.010523726886199403],
      [1583279999999, -0.009498376523745522],
      [1583366399999, -0.004683828754058261],
      [1583452799999, -0.006271765384402217],
      [1583539199999, -0.0058894727105578415],
      [1583798399999, -0.022465717725280943],
      [1583884799999, -0.024244705889539753],
      [1583971199999, -0.03703496941097628],
    ],
    estimateUpperBound: [
      [1583193599999, -0.005860381820782967],
      [1583279999999, -0.003018150884626425],
      [1583366399999, 0.004660326918850988],
      [1583452799999, 0.0038680370176269463],
      [1583539199999, 0.005481209418245259],
      [1583798399999, -0.008541898500586398],
      [1583884799999, -0.00897860282546259],
      [1583971199999, -0.02090659771449699],
    ],
    estimateError: [
      [1583193599999, 0.0023810552566353015],
      [1583279999999, 0.0033087354904604116],
      [1583366399999, 0.0047710282365330415],
      [1583452799999, 0.005177277141604344],
      [1583539199999, 0.0058057514669241595],
      [1583798399999, 0.007109356586812417],
      [1583884799999, 0.007794712687813887],
      [1583971199999, 0.00823497804047645],
    ],
    cumulativeReturn: [
      [1577836799999, 0],
      [1580515199999, -0.0029721192283256315],
      [1583020799999, -0.020739847926409705],
    ],
    estimatedCumulativeReturns: [
      [1583193599999, -0.00819205435349124],
      [1583279999999, -0.00625826370418614],
      [1583366399999, -0.000011750917603636779],
      [1583452799999, -0.0012018641833876353],
      [1583539199999, -0.00020413164615629142],
      [1583798399999, -0.015503808112933615],
      [1583884799999, -0.016611654357501338],
      [1583971199999, -0.028970783562736857],
    ],
    status: 'SUCCESSFUL',
    interpolated: false,
    estimated: true,
  },
  benchmark: {
    lastReturnDate: 1583020799999,
    residualRisk: null,
    estimateBounds: [],
    estimateLowerBound: [],
    estimateUpperBound: [],
    estimateError: [],
    cumulativeReturn: [
      [1577836799999, 0],
      [1580515199999, -0.0028443083207275732],
      [1583020799999, -0.023257015009051085],
    ],
    estimatedCumulativeReturns: [],
    status: 'SECONDARY_SUBJECT',
    interpolated: false,
    estimated: false,
  },
} as unknown as AnalysisGroup<VenncastReturn>;
