import React, { useState } from 'react';
import {
  ButtonIcon,
  Caption,
  CellLoader,
  GetColor,
  Icon,
  Label,
  Tooltip,
  TooltipBodyDirection,
  TooltipPosition,
} from 'venn-ui-kit';
import styled from 'styled-components';
import type { AnalysisSubject, ComparisonSubject } from 'venn-utils';
import { Routes, useHasFF } from 'venn-utils';
import { Link, useHistory } from 'react-router-dom';
import { AnalyzeInvestmentModalsLauncher, useSubjectAvailableRange } from 'venn-components';

interface SubjectLabelProps {
  subject: ComparisonSubject;
  idx: number;
  onRemove: () => void;
  onHover: (hover: boolean) => void;
}

const NAME_MAX_CHARS = 26; // Should always fit one line
const OPTIMIZE_BUTTON_SIZE = 38;

const formatName = (name: string) => {
  if (name.length < NAME_MAX_CHARS) {
    return name;
  }
  return name.slice(0, NAME_MAX_CHARS).concat('...');
};

export const getIconType = (analysisSubject: AnalysisSubject) => {
  if (analysisSubject.portfolio) {
    return 'th';
  }
  return analysisSubject.isCompositeBenchmark ? 'layer-group' : 'square';
};

const SubjectLabel: React.FC<React.PropsWithChildren<SubjectLabelProps>> = ({ subject, idx, onRemove, onHover }) => {
  const hasOptimization = useHasFF('optimization');

  const [analyzeHovered, setAnalyzeHovered] = useState(false);
  const { formattedRange, rangeLoading } = useSubjectAvailableRange(subject.analysisSubject);
  const history = useHistory();

  if (!subject.analysisSubject) {
    return (
      <Cell>
        <LoadingPlaceholder />
      </Cell>
    );
  }

  const isFirst = idx === 0;

  return (
    <RelativeButtonWrapper onMouseEnter={() => onHover(true)} onMouseLeave={() => onHover(false)}>
      {hasOptimization && subject.isHovered && (
        <>
          <AbsoluteButton>
            {subject.analysisSubject?.type === 'portfolio' ? (
              <ButtonIcon
                iconType="flask"
                border
                size={OPTIMIZE_BUTTON_SIZE}
                tooltip="Optimize with Portfolio Lab"
                tooltipPosition={TooltipPosition.Top}
                tooltipDirection={TooltipBodyDirection.Right}
                onClick={() =>
                  history.push(
                    `${Routes.PORTFOLIO_LAB_RESULTS_PATH}?portfolioId=${subject.analysisSubject?.portfolio?.id}`,
                  )
                }
                ctaTrackingOptions={{
                  ctaText: 'flask icon',
                  ctaPurpose: 'Optimize portfolio in Portfolio Lab',
                  ctaDestination: 'Portfolio Lab',
                }}
              />
            ) : (
              <AnalyzeInvestmentModalsLauncher
                subject={subject.analysisSubject}
                size={OPTIMIZE_BUTTON_SIZE}
                border
                tooltipPosition={TooltipPosition.Top}
                tooltipDirection={TooltipBodyDirection.Right}
              />
            )}
          </AbsoluteButton>
        </>
      )}
      <Tooltip
        position={TooltipPosition.Right}
        block
        usePortal
        content={
          <Label inverted>
            {subject.isBenchmark
              ? 'Benchmark'
              : subject.analysisSubject.isCompositeBenchmark
                ? 'Composite Benchmark'
                : subject.analysisSubject.type === 'investment'
                  ? 'Investment'
                  : 'Portfolio'}
            : {subject.analysisSubject.name}
            {analyzeHovered && <ClickToAnalyzeLink>Click to analyze.</ClickToAnalyzeLink>}
          </Label>
        }
      >
        <Wrapper hovered={subject.isHovered} isFirst={isFirst} className="qa-subject-item">
          <Row>
            <ContentGutter>
              {subject.isHovered && (
                <button type="button" onClick={onRemove}>
                  <Icon type="times" />
                </button>
              )}
              <ColoredIcon type={getIconType(subject.analysisSubject)} color={subject.color} />
            </ContentGutter>
            <NameWrapper
              onMouseEnter={() => setAnalyzeHovered(true)}
              onMouseLeave={() => setAnalyzeHovered(false)}
              to={`${Routes.DEFAULT_ANALYSIS_PATH}/${subject.analysisSubject.type}/${subject.analysisSubject.id}/`}
            >
              {formatName(subject.analysisSubject.name)}
              <SubjectInfoRow>
                <SubjectInfo>{rangeLoading ? <CellLoader /> : formattedRange}</SubjectInfo>
              </SubjectInfoRow>
            </NameWrapper>
          </Row>
          <ColoredBar color={subject.color} />
        </Wrapper>
      </Tooltip>
    </RelativeButtonWrapper>
  );
};

export default SubjectLabel;

const Cell = styled.div`
  height: 36px;
`;

const Wrapper = styled(Cell)<{ hovered: boolean; isFirst: boolean }>`
  background-color: ${(props) => (props.hovered ? GetColor.WhiteGrey : 'initial')};
  color: ${GetColor.Black};
  font-size: 12px;
  display: flex;
  align-items: end;
  justify-content: flex-end;
  ${({ isFirst }) => isFirst && `margin-top: -1px; border-top: 1px solid ${GetColor.Black};`}
`;

const Row = styled.div`
  display: flex;
  flex-wrap: nowrap;
  height: 36px;
  width: 100%;
`;

const ColoredIcon = styled(Icon)<{ color: string }>`
  color: ${({ color }) => color};
  position: absolute;
  left: 22px;
  top: calc(50% - 6px);
`;

const ColoredBar = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  margin-top: 1px;
  margin-left: 6px;
  height: calc(100% - 2px);
  width: 5px;
  min-width: 5px;
`;

const ContentGutter = styled.div`
  max-width: 36px;
  flex: auto;
  padding-left: 8px;
  position: relative;
  display: flex;
  align-items: center;
`;

const NameWrapper = styled(Link)`
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
  flex-grow: 1;
  color: ${GetColor.Black};
  height: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;

  :hover {
    color: ${GetColor.Primary.Main};
    cursor: pointer;
  }
`;

const SubjectInfoRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2px;
`;

const SubjectInfo = styled(Caption)`
  font-weight: normal;
  margin-right: 4px;
  color: ${GetColor.DarkGrey};
`;

const ClickToAnalyzeLink = styled.div`
  margin-top: 6px;
  color: ${GetColor.Primary.Main};
`;

const LoadingPlaceholder = styled.div`
  background-color: ${GetColor.WhiteGrey};
  height: 100%;
  width: 100%;
  margin: 0 auto;
`;

const RelativeButtonWrapper = styled.div`
  position: relative;
`;

const AbsoluteButton = styled.div`
  position: absolute;
  top: -1px;
  left: -${OPTIMIZE_BUTTON_SIZE}px;
  height: ${OPTIMIZE_BUTTON_SIZE}px;
  width: ${OPTIMIZE_BUTTON_SIZE}px;
  span[role='button'] {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  i {
    font-size: 14px;
  }
`;
