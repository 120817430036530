import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import StatefulNumericInput from '../../../../stateful-numeric-input/StatefulNumericInput';
import { Numbers } from 'venn-utils';

export interface BenchmarksWeightProps {
  value: string;
  onValueChange(value: string): void;
}

const formatter = (value: string) => Numbers.formatNumber(value, 1);

const qaClass = 'qa-weight';

const BenchmarksWeight: FC<React.PropsWithChildren<BenchmarksWeightProps>> = ({ onValueChange, value }) => (
  <BenchmarksWeightWrapper className={qaClass}>
    <StatefulNumericInput
      // @ts-expect-error: TODO fix strictFunctionTypes
      formatter={formatter}
      value={value}
      placeholder="0.0"
      min={0}
      max={100}
      precision={1}
      onValueChange={onValueChange}
      selectOnFocus
    />
  </BenchmarksWeightWrapper>
);

export default BenchmarksWeight;

const BenchmarksWeightWrapper = styled.div`
  width: 56px;
  font-weight: normal;

  & > div {
    width: 56px;
  }

  input {
    width: 100%;
    text-align: right;
  }
`;
