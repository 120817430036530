import React, { useCallback, useMemo, useState } from 'react';
import type { EditorType, TextControlsState } from 'venn-components';
import { calculateTextControlsState, EditorContext } from 'venn-components';

const StudioEditorProvider: React.FC<React.PropsWithChildren<unknown>> = React.memo(function StudioEditorProvider({
  children,
}) {
  const [activeEditor, setActiveEditorState] = useState<EditorType>();
  const [textControlsState, setTextControlsState] = useState<TextControlsState>();

  const recalculateTextControlsState = useCallback(() => {
    activeEditor && setTextControlsState(calculateTextControlsState(activeEditor));
  }, [activeEditor]);

  const setActiveEditor = useCallback((editor: EditorType) => {
    setActiveEditorState(editor);
    setTextControlsState(calculateTextControlsState(editor));
  }, []);

  // All consumers will rerender if the actual object changes reference
  const contextValue = useMemo(
    () => ({ setActiveEditor, recalculateTextControlsState, textControlsState, activeEditor }),
    [activeEditor, recalculateTextControlsState, setActiveEditor, textControlsState],
  );

  return <EditorContext.Provider value={contextValue}>{children}</EditorContext.Provider>;
});

export default StudioEditorProvider;
