import React from 'react';
import type {
  ChartSerieWithRollingPeriods,
  ChartSerie,
  ChartData,
  ChartDataWithRollingPeriods,
  RollingPeriod,
  SerieValue,
  SerieData,
  FullPeriodValueData,
  FullPeriodValue,
} from '../types';
import { RollingPeriodEnum } from '../types';
import type { AnalysisSubject } from 'venn-utils';
import { getSecondaryDisplayLabel } from 'venn-utils';
import { getLineSerie } from '../chart-config-logic';
import { withTheme } from 'styled-components';
import type { Theme } from 'venn-ui-kit';
import { getLineColors } from './utils';
import { isNil } from 'lodash';

interface LineChartSerieProviderProps {
  relative: boolean;
  activePeriod?: RollingPeriod;
  customPeriodMonths?: number;
  loadingSerieData?: boolean;
  chartData?: ChartData;
  chartDataWithRollingPeriods?: ChartDataWithRollingPeriods;
  children: (state: LineChartSerieProviderState) => React.ReactNode;
  chartType?: 'line' | 'area';
  analysisSubject?: AnalysisSubject;
  theme: Theme;
}

export interface LineChartSerieProviderState {
  mainSerie: SerieValue | undefined;
  secondarySerie: SerieValue | undefined;
  benchmarkSerie: SerieValue | undefined;
  categorySerie: SerieValue | undefined;
}

class LineChartSerieProvider extends React.Component<LineChartSerieProviderProps, LineChartSerieProviderState> {
  state = { mainSerie: undefined, secondarySerie: undefined, benchmarkSerie: undefined, categorySerie: undefined };

  static getDerivedStateFromProps(nextProps: LineChartSerieProviderProps, prevState: LineChartSerieProviderState) {
    const {
      chartData,
      chartDataWithRollingPeriods,
      relative,
      activePeriod,
      customPeriodMonths,
      chartType,
      analysisSubject,
      theme,
      loadingSerieData,
    } = nextProps;

    const isWithRollingPeriods = !!chartDataWithRollingPeriods;
    const dataForChart = isWithRollingPeriods ? chartDataWithRollingPeriods : chartData;
    if (!dataForChart) {
      return {};
    }

    const { main, secondary, benchmark, category } = dataForChart;

    let colorIdx = 0;
    const tooltipLabels = getTooltipLabels(
      relative,
      main.name,
      secondary?.name,
      benchmark?.name,
      category?.name,
      analysisSubject,
    );
    const legendLabels = getLegendLabels(analysisSubject);
    const lineColors = getLineColors(theme);

    // While we're loading custom period data, keep showing the previous series in the background
    // (or the series that's most likely going to be available, 1Y)
    if (isWithRollingPeriods && !isNil(customPeriodMonths) && loadingSerieData) {
      const result = prevState.mainSerie
        ? prevState
        : {
            mainSerie: main.data[RollingPeriodEnum.ONE_YEAR],
            secondarySerie: secondary?.data[RollingPeriodEnum.ONE_YEAR],
            benchmarkSerie: !relative ? benchmark?.data[RollingPeriodEnum.ONE_YEAR] : undefined,
            categorySerie: category?.data[RollingPeriodEnum.ONE_YEAR],
          };
      return result;
    }

    const [mainSerie, secondarySerie, benchmarkSerie, categorySerie] = [
      main,
      secondary,
      !relative ? benchmark : undefined,
      category,
    ].map((serie: ChartSerieWithRollingPeriods | ChartSerie | undefined, idx: number): SerieValue | undefined => {
      if (serie === undefined) {
        return undefined;
      }
      const lineSerie = getLineSerie(
        getValueFromData<number[][], SerieData>(
          isWithRollingPeriods && activePeriod
            ? serie.data[activePeriod.key]
            : isWithRollingPeriods && !isNil(customPeriodMonths)
              ? (serie as ChartSerieWithRollingPeriods).customData ?? { absolute: undefined, relative: undefined }
              : serie.data,
          relative,
        ),
        tooltipLabels[idx] ?? '',
        lineColors[colorIdx],
        chartType,
        undefined,
        9 - idx,
        false,
      );
      if (lineSerie === undefined) {
        return undefined;
      }
      return {
        name: serie.name,
        legendLabel: legendLabels[idx] ?? '',
        data: lineSerie,
        fullPeriodValueLine: getFullPeriodValue(serie.fullPeriodValueLine, relative),
        color: lineColors[colorIdx++],
      };
    });
    return {
      mainSerie,
      secondarySerie,
      benchmarkSerie,
      categorySerie,
    };
  }

  render() {
    return this.props.children(this.state);
  }
}

// @ts-expect-error: TODO fix strictFunctionTypes
export default withTheme(LineChartSerieProvider);

function getTooltipLabels(
  relative: boolean,
  main: string,
  secondary?: string,
  benchmark?: string,
  category?: string,
  subject?: AnalysisSubject,
) {
  if (subject?.type === 'investment') {
    return [
      `Investment: ${main}`,
      '',
      !relative && benchmark ? `Benchmark: ${benchmark}` : undefined,
      category ? `Category: ${category}` : undefined,
    ];
  }

  const label = subject ? getSecondaryDisplayLabel(subject, `${secondary} as of`, `: ${secondary}`) : undefined;
  return [main, secondary ? label : undefined, !relative && benchmark ? `Benchmark: ${benchmark}` : undefined];
}

function getLegendLabels(subject?: AnalysisSubject) {
  const { type: mode, secondaryLabel } = subject ?? {};
  if (mode === 'investment') {
    return ['Investment', undefined, 'Benchmark', 'Category'];
  }
  return [
    'Current Portfolio',
    secondaryLabel ? getSecondaryDisplayLabel(subject!, undefined, 'Portfolio') : 'Master Portfolio',
    'Benchmark',
    undefined,
  ];
}

function getValueFromData<V, T extends { absolute: V; relative: V }>(sd: T, relative: boolean): V {
  return relative ? sd.relative : sd.absolute;
}

const getFullPeriodValue = (fullPeriodValueLine: FullPeriodValueData | undefined, relative: boolean) => {
  if (!fullPeriodValueLine) {
    return undefined;
  }
  return getValueFromData<FullPeriodValue, FullPeriodValueData>(fullPeriodValueLine, relative);
};
