import type { FrequencyEnum } from 'venn-api';
import type { Granularity } from 'venn-ui-kit';
import type moment from 'moment';

/** Enum to represent/interpret the frequency type values (1, 2,...) from the backend */
export enum FrequencyTypes {
  DAILY = 1,
  MONTHLY = 2,
  QUARTERLY = 3,
  YEARLY = 4,
}

export const FREQUENCY_TO_ENUM: { [K in FrequencyTypes]: FrequencyEnum } = {
  [FrequencyTypes.DAILY]: 'DAILY',
  [FrequencyTypes.MONTHLY]: 'MONTHLY',
  [FrequencyTypes.QUARTERLY]: 'QUARTERLY',
  [FrequencyTypes.YEARLY]: 'YEARLY',
};

export const DATEPICKER_FREQUENCY_FORMATS: { [K in FrequencyTypes | FrequencyEnum]: string } = {
  [FrequencyTypes.DAILY]: 'MM/DD/YYYY',
  [FrequencyTypes.MONTHLY]: 'MM/YYYY',
  [FrequencyTypes.QUARTERLY]: 'MM/YYYY',
  [FrequencyTypes.YEARLY]: 'YYYY',
  DAILY: 'MM/DD/YYYY',
  MONTHLY: 'MM/YYYY',
  WEEKLY: 'MM/YYYY',
  QUARTERLY: 'MM/YYYY',
  YEARLY: 'YYYY',
  UNKNOWN: 'MM/YYYY',
};

export const DISPLAY_FREQUENCY_FORMATS: { [K in FrequencyTypes | FrequencyEnum]: string } = {
  [FrequencyTypes.DAILY]: 'D MMM YYYY',
  [FrequencyTypes.MONTHLY]: 'MMM YYYY',
  [FrequencyTypes.QUARTERLY]: 'MMM YYYY',
  [FrequencyTypes.YEARLY]: 'YYYY',
  DAILY: 'D MMM YYYY',
  MONTHLY: 'MMM YYYY',
  WEEKLY: 'MMM YYYY',
  QUARTERLY: 'MMM YYYY',
  YEARLY: 'MMM YYYY',
  UNKNOWN: 'YYYY-MM-DD',
};

export const FREQUENCY_DATEPICKER_MODES: { [K in FrequencyTypes | FrequencyEnum]: Granularity } = {
  [FrequencyTypes.DAILY]: 'day',
  [FrequencyTypes.MONTHLY]: 'month',
  [FrequencyTypes.QUARTERLY]: 'month',
  [FrequencyTypes.YEARLY]: 'year',
  DAILY: 'day',
  MONTHLY: 'month',
  WEEKLY: 'month',
  QUARTERLY: 'month',
  YEARLY: 'year',
  UNKNOWN: 'month',
};

export const MOMENT_DURATIONS: { [K in FrequencyTypes]: moment.DurationInputArg2 } = {
  [FrequencyTypes.DAILY]: 'day',
  [FrequencyTypes.MONTHLY]: 'month',
  [FrequencyTypes.QUARTERLY]: 'quarter',
  [FrequencyTypes.YEARLY]: 'year',
};
