import React from 'react';
import type { HoldingsDataProps } from '../../types';
import { DATA_TYPE_EXCEL_STYLES } from '../../logic/customBlockUtils';
import ExportableGrid from './ExportableGrid';
import useHoldingsDataGrid from '../../logic/useHoldingsGridData';
import { useCommonGridProps } from '../../logic/columnUtils';
import { useIsBlockGridCompact } from '../../logic/useIsBlockGridCompact';

const HoldingsDataGrid = ({ selectedRefId, ...exportProps }: HoldingsDataProps) => {
  const { rowData, columnDefs } = useHoldingsDataGrid({ selectedRefId });

  const sharedProps = useCommonGridProps();
  const isCompact = useIsBlockGridCompact();

  return (
    <ExportableGrid
      {...sharedProps}
      {...exportProps}
      exportable={false} // TODO VENN-21564 - make this exportable
      rowData={rowData}
      selectedRefId={selectedRefId}
      columnDefs={columnDefs}
      excelStyles={DATA_TYPE_EXCEL_STYLES}
      isCompact={isCompact}
    />
  );
};

export default HoldingsDataGrid;
