import React from 'react';
import styled from 'styled-components';
import { GetColor } from '../../style';
import { Icon } from '../icon/Icon';

interface StyleProps {
  dominant?: boolean;
  large?: boolean;
  error?: boolean;
  singleLine?: boolean;
}

interface SelectedItemPillProps<T> extends StyleProps {
  label: string;
  value: T;
  onRemoveItem: (value: T) => void;
}

function SelectedItemPill<T>({ label, value, onRemoveItem, ...styleProps }: SelectedItemPillProps<T>) {
  return (
    <Pill className="qa-selected-item-pill" {...styleProps}>
      {label}
      <RemoveIcon
        type="times"
        prefix="far"
        onClick={(e) => {
          // prevent onOutsideClick of Modal from closing the modal
          e.nativeEvent.stopImmediatePropagation();
          onRemoveItem(value);
        }}
      />
    </Pill>
  );
}

export default SelectedItemPill;

const getBackgroundColor = ({ dominant, error }: StyleProps) => {
  if (error) {
    return GetColor.DEPRECATED_DivergingColor.A3;
  }
  return dominant ? GetColor.Primary.Dark : GetColor.Black;
};

const Pill = styled.div<StyleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 8px;
  background: ${getBackgroundColor};
  color: ${GetColor.White};
  font-weight: normal;
  ${({ singleLine }) => (singleLine ? 'white-space: nowrap;' : undefined)}
  ${({ large }) =>
    large
      ? `
    border-radius: 200px;
    font-size: 14px;
    height: 24px;
    line-height: 1.71;
    &:not(:last-child) {
      margin-right: 8px;
    }
  `
      : `
    border-radius: 4px;
    font-size: 10px;
    word-break: break-all;
    line-height: 13px;
    &:not(:last-child) {
      margin-right: 5px;
    }
    padding-top: 5px;
    padding-bottom: 5px;
    max-width: 320px;
  `}
`;

const RemoveIcon = styled(Icon)`
  &:hover {
    color: ${GetColor.Primary.Main};
  }
  padding: 2px 10px 1px 7px;
`;
