import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';

export interface FactorDescriptionTooltipProps {
  name: string;
  description: string;
}

const StyledName = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${GetColor.Black};
`;

const StyledDescription = styled.div`
  color: ${GetColor.MidGrey1};
`;

const StyledWrapper = styled.div`
  padding: 8px;
`;

const FactorDescriptionTooltip: FC<React.PropsWithChildren<FactorDescriptionTooltipProps>> = ({
  name,
  description,
}) => (
  <StyledWrapper className="qa-factor-description">
    <StyledName>{name}</StyledName>
    <StyledDescription>{description}</StyledDescription>
  </StyledWrapper>
);

export default FactorDescriptionTooltip;
