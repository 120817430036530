import React, { useContext, useMemo } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { GetColor, Loading } from 'venn-ui-kit';
import type { Solution } from 'venn-components';
import { ContentBlock, Highchart } from 'venn-components';
import type { TradeSolution } from './chartConfigUtils';
import { getTradesVsCapitalChartConfig } from './chartConfigUtils';
import useSynchronizedSelectedChartPoints from './useSynchronizedSelectedChartPoints';
import ChartLegend from './ChartLegend';
import SelectableSolutionChartSubheader from './SelectableSolutionChartSubheader';
import { isNil } from 'lodash';

interface TradesVsCapitalCardViewProps {
  current?: TradeSolution;
  optimized?: TradeSolution;
  alternate?: TradeSolution[];

  solutionsLoading: boolean;

  selectedSolution?: Solution;
  onSelectSolution: (solution: Solution) => void;
}

const TradesVsCapitalCardView = ({
  current,
  optimized,
  alternate,
  solutionsLoading,
  selectedSolution,
  onSelectSolution,
}: TradesVsCapitalCardViewProps) => {
  const theme = useContext(ThemeContext);
  const { setChartPoints } = useSynchronizedSelectedChartPoints(selectedSolution);

  const chartConfig = useMemo(() => {
    return getTradesVsCapitalChartConfig(
      theme,
      onSelectSolution,
      current,
      optimized,
      alternate ?? [],
      undefined, // Don't show benchmark on this chart, as it can be completely irrelevant in structuregi
      setChartPoints,
    );
  }, [theme, current, optimized, alternate, onSelectSolution, setChartPoints]);

  return (
    <Card
      header="Trades Required vs. Total Capital Moved (per solution)"
      subHeader={<SelectableSolutionChartSubheader />}
    >
      {solutionsLoading ? (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      ) : (
        <>
          <ChartLegend hasOptimized={!isNil(optimized)} />
          <Highchart options={chartConfig} />
        </>
      )}
    </Card>
  );
};

export default TradesVsCapitalCardView;

const Card = styled(ContentBlock)`
  background-color: ${GetColor.White};
`;

const LoadingContainer = styled.div`
  min-height: 430px;
`;
