import type { ComponentType } from 'react';
import { BasicComparisonTable, PairwiseCorrelation } from './components/analyses';
import {
  factorContributionToReturnExtractor,
  factorContributionToRiskExtractor,
  factorExposureExtractor,
  forecastReturnsExtractor,
  historicalReturnsExtractor,
  historicalRiskStatsExtractor,
  pairwiseCorrelationExtractor,
} from './logic/dataExtractors';
import {
  basicComparisonTableCellRenderer,
  CorrelationLegendComponent,
  factorMetricHeaderRenderer,
  pairwiseCorrelationCellRenderer,
  subjectNameHeaderRenderer,
} from './logic/cellRenderers';
import type { AnalysisBlockProps, CellRendererType, DataExtractor, HeaderRendererType } from './types';

export interface ComparisonAnalysisBlock {
  getTitle: (relative: boolean) => string; // Block title
  label: string; // Label describing metrics displayed within the block (cell [0,0] in non-pivoted table)
  component: ComponentType<React.PropsWithChildren<AnalysisBlockProps>>; // Component rendering the block in non-pivoted table
  type: ComparisonBlockType; // Analysis type
  extractor: DataExtractor; // Function that extracts the data for this blog from the analysis response
  cellRenderer: CellRendererType; // Cell renderer for pivoted (combined) table
  headerRenderer?: HeaderRendererType; // Header cell renderer for pivoted (combined) table
  footerComponent?: ComponentType<React.PropsWithChildren<unknown>>; // Footer component to attach below the pivoted (combined) table
  key: string;
  hideInPrint?: boolean; // Whether the block should be removed from print
}

export type ComparisonBlockType = ReturnType<typeof getAnalysisBlocks>[number]['type'];

const getAnalysisBlocks = (hideForecastBlock: boolean) =>
  [
    {
      getTitle: (relative: boolean) => `${relative ? 'Relative ' : ''}Historical Performance`,
      label: 'Historical Metrics',
      component: BasicComparisonTable,
      type: 'PERFORMANCE_SUMMARY_HISTORICAL',
      extractor: historicalReturnsExtractor,
      cellRenderer: basicComparisonTableCellRenderer,
      key: 'historical-metrics',
      hideInPrint: false,
    },
    {
      getTitle: (relative: boolean) => `${relative ? 'Relative ' : ''}Forecast Performance`,
      label: 'Forecast Metrics',
      component: BasicComparisonTable,
      type: 'PERFORMANCE_SUMMARY_FORECAST',
      extractor: forecastReturnsExtractor,
      cellRenderer: basicComparisonTableCellRenderer,
      key: 'forecast-metrics',
      hideInPrint: hideForecastBlock,
    },
    {
      getTitle: (relative: boolean) => `${relative ? 'Relative ' : ''}Historical Risk Statistics`,
      label: 'Statistics',
      component: BasicComparisonTable,
      type: 'PERFORMANCE_SUMMARY_HISTORICAL',
      extractor: historicalRiskStatsExtractor,
      cellRenderer: basicComparisonTableCellRenderer,
      key: 'historical-risk-stats',
      hideInPrint: false,
    },
    {
      getTitle: (relative: boolean) => (relative ? 'Active Factor Exposures (β)' : 'Factor Exposures (β)'),
      label: 'Factors',
      component: BasicComparisonTable,
      type: 'FACTOR_EXPOSURES',
      extractor: factorExposureExtractor,
      cellRenderer: basicComparisonTableCellRenderer,
      key: 'factor-exposures',
      hideInPrint: false,
    },
    {
      getTitle: (relative: boolean) =>
        relative ? 'Active Factor Contributions To Risk' : 'Factor Contributions To Risk',
      label: 'Factors',
      component: BasicComparisonTable,
      type: 'FACTOR_CONTRIBUTION_TO_RISK',
      extractor: factorContributionToRiskExtractor,
      cellRenderer: basicComparisonTableCellRenderer,
      headerRenderer: factorMetricHeaderRenderer,
      key: 'factor-risks',
      hideInPrint: false,
    },
    {
      getTitle: (relative: boolean) =>
        relative ? 'Active Factor Contributions To Return' : 'Factor Contributions To Return',
      label: 'Factors',
      component: BasicComparisonTable,
      type: 'FACTOR_CONTRIBUTION_TO_RETURN',
      extractor: factorContributionToReturnExtractor,
      cellRenderer: basicComparisonTableCellRenderer,
      headerRenderer: factorMetricHeaderRenderer,
      key: 'factor-returns',
      hideInPrint: false,
    },
    {
      getTitle: (relative: boolean) => (relative ? 'Benchmark Relative Correlation' : 'Correlation'),
      label: 'Correlation',
      component: PairwiseCorrelation,
      type: 'PAIRWISE_CORRELATION',
      extractor: pairwiseCorrelationExtractor,
      cellRenderer: pairwiseCorrelationCellRenderer,
      headerRenderer: subjectNameHeaderRenderer,
      footerComponent: CorrelationLegendComponent,
      key: 'pairwise-correlation',
      hideInPrint: false,
    },
  ] as const; // satisfies ComparisonAnalysisBlock

export default getAnalysisBlocks;
