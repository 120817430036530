import type { FilterTypes } from 'venn-api';

const searchOptions: FilterTypes = {
  factorGroups: [
    { id: 465, name: 'Equity' },
    { id: 466, name: 'Interest Rates' },
    { id: 467, name: 'Credit' },
    { id: 468, name: 'Commodities' },
    { id: -1, name: 'Secondary Macro' },
    { id: -1, name: 'Equity Styles' },
    { id: -1, name: 'Residual' },
  ],
  equityRegions: [],
  assetTypes: [],
  fields: [
    'id',
    'fundSourceTypeId',
    'fundSourceTypeName',
    'name',
    'nameNormalized',
    'symbol',
    'userUploaded',
    'accountNumber',
    'annualizedVolatility',
    'annualizedReturn',
    'periodReturn',
    'sharpe',
    'informationRatio',
    'relativeReturn',
    'relativeVolatility',
    'maxDrawdown',
    'factorAnalysis',
    'riskContribution',
    'exposures',
    'largestExposure',
    'benchmarkID',
    'benchmarkName',
    'consistency',
    'effectiveness',
    'permissionGroupID',
    'assetType',
    'assetTypeName',
    'ownerId',
    'ownerName',
    'remoteType',
    'active',
    'currencyName',
    'dataSource',
    'startRange',
    'endRange',
    'unproxiedStartRange',
    'unproxiedEndRange',
    'created',
    'updated',
    'numReturns',
    'unproxiedNumReturns',
    'rangeYears',
    'unproxiedRangeYears',
    'investmentRegionName',
    'investmentSourceName',
    'investmentTypeName',
    'tags.id',
    'frequency',
  ],
  performanceFilters: [
    { name: 'Min Annualized Return', key: 'minimumAnnualizedReturn', type: 'PERCENTAGE' },
    { name: 'Min Sharpe', key: 'minimumSharpe', type: 'NUMBER' },
    { name: 'Max Annualized Volatility', key: 'maximumAnnualizedVolatility', type: 'PERCENTAGE' },
    { name: 'Max Drawdown', key: 'maximumDrawdown', type: 'PERCENTAGE' },
  ],
  factors: [
    { id: 465, name: 'Equity' },
    { id: 466, name: 'Interest Rates' },
    { id: 467, name: 'Credit' },
    { id: 468, name: 'Commodities' },
    { id: 469, name: 'Foreign Currency' },
    { id: 470, name: 'Local Inflation' },
    { id: 471, name: 'Emerging Markets' },
    { id: 472, name: 'Equity Short Volatility' },
    { id: 483, name: 'Local Equity' },
    { id: 394, name: 'Low Risk' },
    { id: 395, name: 'Momentum' },
    { id: 396, name: 'Quality' },
    { id: 397, name: 'Value' },
    { id: 398, name: 'Small Cap' },
  ],
  investmentSources: [
    { id: 'VENN', name: 'Venn' },
    { id: 'UPLOAD', name: 'Uploads' },
    { id: 'CUSTODIAN', name: 'Custodian' },
    { id: 'CUSTOM', name: 'Custom' },
  ],
  investmentTypes: [
    { id: 'EQUITY', name: 'Equities' },
    { id: 'MUTUAL_FUND', name: 'Mutual funds' },
    { id: 'ETF', name: 'ETFs' },
    { id: 'INDEX', name: 'Indices' },
    { id: 'COMPOSITE_BENCHMARK', name: 'Composite benchmarks' },
    { id: 'NOT_SPECIFIED', name: 'Not specified' },
  ],
  investmentRegions: [
    { id: 'US', name: 'U.S.' },
    { id: 'PAN_AMERICA', name: 'Pan-America' },
    { id: 'EMEA', name: 'EMEA' },
    { id: 'ASIA_PACIFIC', name: 'Asia-Pacific Ex-Japan' },
    { id: 'JAPAN', name: 'Japan' },
    { id: 'NOT_SPECIFIED', name: 'Not specified' },
  ],
  numUploadedInvestments: 8541,
};

export default searchOptions;
