import { GetColor } from 'venn-ui-kit';
import styled from 'styled-components';

export const AlternateModalHeader = styled.div`
  display: flex;
  padding: 16px;
  align-items: flex-start;
  align-self: stretch;
  border-bottom: 1px solid ${GetColor.Grey};
  background: ${GetColor.PaleGrey};
  font-size: 14px;
  font-weight: bold;
`;
