import type { Theme } from 'venn-ui-kit';
import { ColorUtils } from 'venn-ui-kit';
import type {
  Options as HighchartOptions,
  Point,
  PointClickEventObject,
  PointInteractionEventObject,
  TooltipOptions,
  AxisLabelsFormatterContextObject,
  TooltipFormatterContextObject,
  Chart,
} from 'highcharts';
import { numberFormat } from 'highcharts';
import { formatAllocation, Numbers } from 'venn-utils';
import { compact, isNil, sortBy } from 'lodash';
import type { SolutionData } from '../../logic/useOptimizationSolutions';
import type { Solution } from 'venn-components';
import type { FactorForecast, RiskReturnPoint } from 'venn-api';
import type { TradesStatistics } from '../../logic/tradesUtils';
import { getPortfolioLabColors } from '../../logic/getPortfolioLabColors';

export interface TradeSolution {
  solution: Solution;
  trade: TradesStatistics;
}

export const getTradesVsCapitalChartConfig = (
  theme: Theme,
  onSelectSolution: (solution: Solution) => void,
  current: TradeSolution | undefined,
  optimized: TradeSolution | undefined,
  alternate: TradeSolution[],
  benchmark: TradeSolution | undefined,
  setChartPoints: (points: Point[]) => void,
): HighchartOptions => {
  const plColors = getPortfolioLabColors(theme);
  const portfolioData = getTradePoint(current, plColors.current);
  const optimizedData = getTradePoint(optimized, plColors.optimized);
  const alternateData =
    alternate.length === 0
      ? undefined
      : compact(alternate.map((alternateSolution) => getTradePoint(alternateSolution, plColors.alternate)));
  const benchmarkData = getTradePoint(benchmark, plColors.benchmark);
  return {
    ...baseChartConfig(
      theme,
      setChartPoints,
      'Total Capital Moved ($)',
      'Number of Trades',
      80,
      false,
      true,
      true,
      true,
      1,
    ),
    tooltip: getTooltip(
      theme,
      'Total Capital Moved',
      'Number of Trades',
      (x) => `$${formatAllocation(x, false)}`,
      (y) => Numbers.safeFormatNumber(y, 0),
    ),
    plotOptions: plotOptions(
      onSelectPoint(
        current?.solution,
        optimized?.solution,
        benchmark?.solution,
        alternate.map((item) => item.solution),
        onSelectSolution,
      ),
    ),
    series: getSeries(theme, portfolioData, optimizedData, alternateData, benchmarkData, undefined, undefined, false),
  };
};

export const getEfficientFrontierChartConfig = (
  theme: Theme,
  onSelectSolution: (solution: Solution) => void,
  current: SolutionData | undefined,
  showFactorForecasts: boolean,
  factorForecasts: FactorForecast[] | undefined,
  optimized: SolutionData | undefined,
  efficientFrontier: RiskReturnPoint[],
  alternate: SolutionData[],
  benchmark: SolutionData | undefined,
  setChartPoints: (points: Point[]) => void,
): HighchartOptions => {
  const plColors = getPortfolioLabColors(theme);
  const portfolioData = getPoint(current, plColors.current);
  const optimizedData = getPoint(optimized, plColors.optimized);
  const alternateData =
    alternate.length === 0
      ? undefined
      : compact(alternate.map((alternateSolution) => getPoint(alternateSolution, plColors.alternate)));
  const benchmarkData = getPoint(benchmark, plColors.benchmark);
  const factorsData = getForecastPoints(factorForecasts, theme);
  const efficientFrontierData: [number, number][] = sortBy(efficientFrontier, 'annualizedVolatility').map(
    (frontierPoint) => [frontierPoint.annualizedVolatility, frontierPoint.annualizedReturn * 100],
  );

  return {
    ...baseChartConfig(theme, setChartPoints, 'Forecasted Volatility', 'Forecasted Return', 90, true),
    tooltip: getTooltip(
      theme,
      'Volatility',
      'Return',
      (x) => `${Numbers.safeFormatNumber(x * 100, 1)}%`,
      (y) => `${Numbers.safeFormatNumber(y, 1)}%`,
    ),
    plotOptions: plotOptions(
      onSelectPoint(
        current?.solution,
        optimized?.solution,
        benchmark?.solution,
        alternate.map((item) => item.solution),
        onSelectSolution,
      ),
    ),
    series: getSeries(
      theme,
      portfolioData,
      optimizedData,
      alternateData,
      benchmarkData,
      efficientFrontierData,
      factorsData,
      showFactorForecasts,
    ),
  };
};

const onSelectPoint =
  (
    current: Solution | undefined,
    optimized: Solution | undefined,
    benchmark: Solution | undefined,
    alternate: Solution[],
    onSelectSolution: (solution: Solution) => void,
  ) =>
  (point: Point) => {
    const category = point.options.category;
    switch (category) {
      case 'Current':
        if (!isNil(current)) {
          onSelectSolution(current);
        }
        break;
      case 'Optimized':
        if (!isNil(optimized)) {
          onSelectSolution(optimized);
        }
        break;
      case 'Alternate':
        const alternateIdx = point.options.alternateIdx;
        const alternatePoint = alternate.find((item) => item.alternateSolutionIdx === alternateIdx);
        if (!isNil(alternatePoint)) {
          onSelectSolution(alternatePoint);
        }
        break;
      case 'Benchmark':
      default:
        break;
    }
  };

const baseChartConfig = (
  theme: Theme,
  setChartPointsOnLoad: (point: Point[]) => void,
  xAxisLabel: string,
  yAxisLabel: string,
  marginLeft: number,
  isYAxisPercentage?: boolean,
  isXAxisCapital?: boolean,
  reverseYAxis?: boolean,
  startXAxisAtZero?: boolean,
  minTickInterval?: number,
) => ({
  chart: {
    type: 'scatter',
    marginLeft,
    marginRight: 10,
    style: {
      fontSize: '14px',
      overflow: 'visible',
      fontFamily: theme.Typography.fontFamily,
      color: theme.Colors.Black,
    },
    events: {
      load(this: Chart) {
        const points = [];
        for (const serie of this.series) {
          for (const point of serie.data) {
            const category = point?.options.category;
            if (['Current', 'Optimized', 'Benchmark', 'Alternate'].includes(category)) {
              points.push(point);
            }
          }
        }
        setChartPointsOnLoad(points);
      },
    },
  },
  title: {
    text: undefined,
  },
  yAxis: {
    title: {
      text: yAxisLabel,
      style: {
        color: theme.Colors.Black,
        fontFamily: theme.Typography.fontFamily,
        fontSize: '14px',
      },
    },
    gridLineColor: theme.Schemes.LineChartColors.lineColor,
    gridLineWidth: 1,
    tickWidth: 1,
    minTickInterval,
    tickColor: theme.Schemes.LineChartColors.lineColor,
    tickLength: 80,
    lineWidth: 1,
    lineColor: theme.Schemes.LineChartColors.lineColor,
    showLastLabel: !!reverseYAxis,
    showFirstLabel: !reverseYAxis,
    labels: {
      y: 5,
      style: {
        fontFamily: theme.Typography.fontFamily,
        fontSize: '14px',
        whiteSpace: 'nowrap',
        color: theme.Colors.MidGrey2,
        width: 60,
      },
      useHTML: true,
      formatter(this: AxisLabelsFormatterContextObject) {
        return isYAxisPercentage ? `${numberFormat(this.value, 1)}%` : numberFormat(this.value, 0);
      },
    },
    reversed: !!reverseYAxis,
  },
  xAxis: {
    title: {
      text: xAxisLabel,
      style: {
        color: theme.Colors.Black,
        fontFamily: theme.Typography.fontFamily,
        fontSize: '14px',
        transform: 'translateX(-30px)',
      },
    },
    gridLineWidth: 1,
    gridLineColor: theme.Schemes.LineChartColors.lineColor,
    tickWidth: 0,
    lineColor: theme.Schemes.LineChartColors.lineColor,
    startOnTick: true,
    showFirstLabel: false,
    labels: {
      style: {
        fontFamily: theme.Typography.fontFamily,
        fontSize: '14px',
        whiteSpace: 'nowrap',
        color: theme.Colors.MidGrey2,
      },
      formatter(this: AxisLabelsFormatterContextObject) {
        return isXAxisCapital ? `$${formatAllocation(this.value, false)}` : `${numberFormat(this.value * 100, 1)}%`;
      },
    },
    min: startXAxisAtZero ? 0 : undefined,
  },
  legend: {
    enabled: false,
  },
});

const plotOptions = (onSelect: (point: Point) => void) => ({
  series: {
    point: {
      events: {
        select(e: PointInteractionEventObject) {
          const point = e.target as unknown as Point;
          if (point !== null && point.options !== null) {
            onSelect(point);
          } else {
            e.preventDefault();
          }
        },
        click(e: PointClickEventObject) {
          const classList = (e.target as HTMLElement).classList;
          // Prevent deselecting selected points on click, while still allowing programmatic deselect
          if (classList.contains('highcharts-point-select')) {
            e.preventDefault();
          }
        },
      },
    },
    states: {
      select: {
        halo: {
          size: 15,
          opacity: 0.25,
          attributes: {
            transform: 'translate(1, -1.5)',
          },
        },
      },
      hover: {
        halo: {
          size: 15,
          opacity: 0.25,
          attributes: {
            transform: 'translate(1, -1.5)',
          },
        },
      },
      inactive: {
        opacity: 1,
      },
    },
  },
  scatter: {
    stickyTracking: false,
  },
});

const selectablePointData = (symbol: string, theme: Theme, radius = 4) => ({
  zIndex: 3,
  allowPointSelect: true,
  lineWidth: 0,
  marker: {
    radius,
    symbol,
    states: {
      select: {
        fillColor: undefined, // keeps point color
        lineColor: theme.Colors.White,
        lineWidth: 1,
        halo: {
          attributes: {
            opacity: 0.25,
          },
        },
      },
      hover: {
        lineWidthPlus: 0,
        radiusPlus: 0,
      },
    },
  },
  cursor: 'pointer',
});

const getPoint = (solutionData: SolutionData | undefined, color: string) =>
  isNil(solutionData)
    ? undefined
    : {
        category: solutionData.solution.category,
        alternateIdx: solutionData.solution.alternateSolutionIdx,
        name: solutionData.solution.name,
        x: solutionData.point.annualizedVolatility,
        y: solutionData.point.annualizedReturn * 100,
        color,
      };

const getForecastPoints = (factorForecasts: FactorForecast[] | undefined, theme: Theme) =>
  isNil(factorForecasts) || factorForecasts.length === 0
    ? undefined
    : factorForecasts
        .filter((factor) => !isNil(factor.annualizedVolatility) && !isNil(factor.annualizedReturn))
        .map((factor) => ({
          category: 'Factor',
          name: factor.name,
          x: factor.annualizedVolatility,
          y: factor.annualizedReturn * 100,
          color: theme.Colors.Grey,
        }));

const getTradePoint = (tradeSolution: TradeSolution | undefined, color: string) =>
  isNil(tradeSolution)
    ? undefined
    : {
        category: tradeSolution.solution.category,
        alternateIdx: tradeSolution.solution.alternateSolutionIdx,
        name: tradeSolution.solution.name,
        x: tradeSolution.trade.capitalMoved,
        y: tradeSolution.trade.tradeCount,
        color,
      };

const getSeries = (
  theme: Theme,
  portfolioData: Partial<Point> | undefined,
  optimizedData: Partial<Point> | undefined,
  alternateData: Partial<Point>[] | undefined,
  benchmarkData: Partial<Point> | undefined,
  efficientFrontierData: [number, number][] | undefined,
  factorsData: Partial<Point>[] | undefined,
  showFactorForecasts: boolean,
) =>
  compact([
    !isNil(portfolioData)
      ? {
          name: 'Current',
          type: 'scatter' as const,
          data: [portfolioData],
          ...selectablePointData('text:\uf041', theme),
        }
      : null,
    !isNil(optimizedData)
      ? {
          name: 'Optimized',
          type: 'scatter' as const,
          data: [optimizedData],
          ...selectablePointData('text:\uf0c3', theme),
        }
      : null,
    ...(!isNil(alternateData)
      ? // Map do separate series to prevent the "halo" on the selected point from disappearing when another is hovered
        alternateData.map((point) => ({
          name: 'Alternate',
          type: 'scatter' as const,
          data: [{ ...point }],
          ...selectablePointData('text:\uf111', theme, 2),
        }))
      : []),
    !isNil(benchmarkData)
      ? {
          name: 'Benchmark',
          type: 'scatter' as const,
          data: [benchmarkData],
          zIndex: 3,
          lineWidth: 0,
          marker: {
            radius: 3,
            symbol: 'text:\uf07e',
            states: {
              hover: { enabled: false },
              select: { enabled: false },
            },
          },
        }
      : null,
    !isNil(efficientFrontierData)
      ? {
          name: 'Efficient Frontier',
          data: efficientFrontierData,
          type: 'line' as const,
          lineColor: theme.Colors.Black,
          allowPointSelect: false,
          enableMouseTracking: false,
          marker: {
            radius: 0,
            states: {
              hover: { enabled: false, tooltip: { enabled: false } },
              select: { enabled: false },
            },
          },
        }
      : null,
    !isNil(factorsData)
      ? {
          name: 'Factor',
          type: 'scatter' as const,
          data: factorsData,
          visible: showFactorForecasts,
          zIndex: 0,
          allowPointSelect: false,
          lineWidth: 0,
          marker: {
            radius: 2,
            symbol: 'text:\uf471',
            fillColor: theme.Colors.Grey,
            states: {
              hover: { enabled: false },
              select: { enabled: false },
            },
          },
          cursor: 'default',
        }
      : null,
  ]);

const getTooltip = (
  theme: Theme,
  xLabel: string,
  yLabel: string,
  formatXValue: (x: number) => string,
  formatYValue: (y: number) => string,
): TooltipOptions => ({
  formatter(this: TooltipFormatterContextObject) {
    const { point, x, y } = this;
    if (point.series.type !== 'scatter') {
      return null;
    }
    const category = point.options.category;
    const title =
      category === 'Current'
        ? `Current Portfolio: ${point.name}`
        : category === 'Optimized'
          ? 'Optimized Portfolio'
          : category === 'Alternate'
            ? `Near-Optimal Portfolio [#${1 + (point.alternateIdx ?? 0)}]`
            : category === 'Benchmark'
              ? `Benchmark: ${point.name}`
              : category === 'Factor'
                ? `Factor: ${point.name}`
                : point.name;
    return `
          <h4>
            <b>${title}</b>
          </h4>
          ${yLabel}: <b>${formatYValue(y)}</b><br />
          ${xLabel}: <b>${formatXValue(x)}</b><br />
          ${
            category === 'Factor' || category === 'Benchmark'
              ? ''
              : `
            <br /><br />
            <i>Click for more details</i>
          `
          }`;
  },
  shadow: false,
  backgroundColor: ColorUtils.hex2rgba(theme.Colors.Black, 0.9),
  borderRadius: 0,
  borderWidth: 0,
  useHTML: true,
  style: {
    color: theme.Colors.White,
    fontFamily: theme.Typography.fontFamily,
  },
});
