import React from 'react';
import styled from 'styled-components';
import { GetColor, Icon } from 'venn-ui-kit';
import { ActiveBar } from '../../side-panel-settings/SidePanelSettings';
import { buttonize } from 'venn-utils';
import { Constants } from '../Layout';
import StudioAllocatorSubjectSelector from './StudioAllocatorSubjectSelector';
import { useRecoilState } from 'recoil';
import { compareColumnOpen } from 'venn-state';

interface StudioAllocatorWindowHeaderProps {
  width: number;
  onClose?: () => void;
}
export const StudioAllocatorWindowHeader = ({ width, onClose }: StudioAllocatorWindowHeaderProps) => (
  <StyledStudioAllocatorWindowHeader width={width}>
    <HeaderText>Portfolio Allocator</HeaderText>
    <ActiveBar left={20} width={117} />
    {onClose && <Icon type="times" {...buttonize(onClose)} />}
  </StyledStudioAllocatorWindowHeader>
);

interface StudioAllocatorSubjectProps {
  width: number;
  disabled?: boolean;
  isPrivate?: boolean;
}

export const StudioAllocatorSubject = ({ width, disabled, isPrivate = false }: StudioAllocatorSubjectProps) => {
  return (
    <StudioAllocatorSubjectSection width={width}>
      <StudioAllocatorSubjectSelector disabled={disabled} selectorMaxWidth={width - 40} />
      {!isPrivate && <CompareLink disabled={disabled} />}
    </StudioAllocatorSubjectSection>
  );
};

function CompareLink({ disabled }: { disabled?: boolean }) {
  const [compareOpen, setCompareOpen] = useRecoilState(compareColumnOpen);
  const onLinkClick = () => setCompareOpen(!compareOpen);

  return (
    <div style={{ textAlign: 'right' }}>
      <LinkText onClick={onLinkClick} disabled={disabled}>
        {compareOpen ? 'Hide Compare' : 'Compare Versions'}
      </LinkText>
    </div>
  );
}

interface ShimmerPanelProps {
  isPrivate?: boolean;
}
export const StudioAllocationPanelShimmerBlock = ({ isPrivate }: ShimmerPanelProps) => (
  <StudioAllocatorWindow>
    <StudioAllocatorWindowHeader width={Constants.ALLOCATION_PANEL_TOTAL_WIDTH + 1} />
    <StudioAllocatorSubject isPrivate={isPrivate} width={Constants.ALLOCATION_PANEL_TOTAL_WIDTH + 1} disabled />
  </StudioAllocatorWindow>
);

const WINDOW_HEADER_HEIGHT = 44;

export const StudioAllocatorWindow = styled.div`
  height: 100%;
  background-color: ${GetColor.White};
  border-left: 1px solid ${GetColor.Grey};
  border-right: 1px solid ${GetColor.Grey};
  overflow: hidden;
  position: relative;
  display: grid;
  grid-template-areas:
    'allocator-header'
    'allocator-subject'
    'allocator-panel';
  grid-template-columns: 1fr;
  grid-template-rows: ${WINDOW_HEADER_HEIGHT}px auto 1fr;
`;

const StyledStudioAllocatorWindowHeader = styled.div<{ width: number }>`
  grid-area: allocator-header;
  width: ${({ width }) => width}px;
  background-color: ${GetColor.White};
  color: ${GetColor.White};
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  i {
    margin: 0 10px;
    color: ${GetColor.Primary.Dark};
    :hover {
      color: ${GetColor.Primary.Main};
    }
  }
`;

const HeaderText = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${GetColor.Black};
  padding: 4px 2px;
  margin: 0 8px;
`;

export const StudioAllocatorSubjectSection = styled.div<{ width: number }>`
  grid-area: allocator-subject;
  background-color: ${GetColor.White};
  width: ${({ width }) => width}px;
  padding: 12px 20px;
  border-bottom: 1px solid ${GetColor.Grey};
`;

const LinkText = styled.button`
  color: ${GetColor.Primary.Dark};
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  margin-top: 8px;
`;
