import {
  type SetRecoilState,
  type GetRecoilValue,
  type ResetRecoilState,
  type CallbackInterface,
  type RecoilValue,
  type Loadable,
  RecoilLoadable,
} from 'recoil';

type SelectorProps = {
  set: SetRecoilState;
  get: GetRecoilValue;
  reset: ResetRecoilState;
};

export type CallbackOrSelectorProps = CallbackInterface | SelectorProps;

function isCallbackProps(props: CallbackOrSelectorProps): props is CallbackInterface {
  // We can't use the 'in' operator because props from Recoil is a Proxy object, it doesn't really have the props inside of it.
  return (props as CallbackInterface).snapshot !== undefined;
}

/**
 * Enables a function to be used with props from either a Recoil selector or a Recoil callback.
 *
 * The function should take in {@link CallbackOrSelectorProps} and then use this function to normalize the props to {@link SelectorProps}.
 */
export function bridgeSelectorAndCallback(props: CallbackOrSelectorProps): {
  set: SetRecoilState;
  get: GetRecoilValue;
  getLoadable: <T>(recoilVal: RecoilValue<T>) => Loadable<T>;
  reset: ResetRecoilState;
} {
  return {
    set: props.set,
    reset: props.reset,
    getLoadable: (recoilVal) => {
      return isCallbackProps(props) ? props.snapshot.getLoadable(recoilVal) : RecoilLoadable.of(props.get(recoilVal));
    },
    get: (recoilVal) => {
      // loadable.getValue matches the semantics of React Suspense and Recoil selectors
      return isCallbackProps(props) ? props.snapshot.getLoadable(recoilVal).getValue() : props.get(recoilVal);
    },
  };
}
