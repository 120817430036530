import React from 'react';
import styled from 'styled-components';
import EmptyState from '../../../empty-state/EmptyState';

export const FactorTrendNoData = () => {
  return <StyledErrorState header="No data to display." message="Please change selected Metrics or Factors." />;
};

const StyledErrorState = styled(EmptyState)`
  margin: 30px;
`;
