import type { ColGroupDef } from 'ag-grid-community';
import { compact } from 'lodash';
import { useMeasureGridText, useMeasureHeader } from '../../../../utils';
import { RIGHT_ALIGN_CLASS } from '../../../customAnalysisContants';
import { createLabelColumn, getGroupColumnLabel } from '../../../logic/columnParsers';
import { getGroupColDef } from '../../../logic/getGroupColDef';
import { filterMetrics, filterGlobalMetrics } from './notablePeriodsUtils';
import { useTheme } from 'styled-components';
import { useAgGridStyle } from '../../grid/AgGridThemeOverrides';
import { useBlockId } from '../../../contexts/BlockIdContext';
import { useRecoilValue } from 'recoil';
import { blockCustomizableMetrics, customizedBlock } from 'venn-state';
import type { AnalysisGridProps, MultiGlobalColumnsRowData } from '../../../types';

export const useNotablePeriodsColumnDefs = ({
  requests,
  analysesGroup,
  gridData,
}: { gridData: MultiGlobalColumnsRowData[] } & Pick<AnalysisGridProps, 'analysesGroup' | 'requests'>) => {
  const theme = useTheme();
  const gridStyle = useAgGridStyle();
  const measureGridText = useMeasureGridText();

  const measureHeader = useMeasureHeader();
  const blockId = useBlockId();
  const selectedBlock = useRecoilValue(customizedBlock(blockId));
  const metricsSettings = useRecoilValue(blockCustomizableMetrics(blockId));

  const isRelative = selectedBlock?.relativeToBenchmark ?? false;

  const labelColumn = createLabelColumn({
    requests,
  });

  const minWidthCap = 70;
  let largestMinWidth = 0;
  for (const { label } of gridData) {
    const labelWidth = measureGridText(label, 'normal');
    // Break early to reduce usage of expensive measureGridText
    if (labelWidth > minWidthCap) {
      largestMinWidth = minWidthCap;
      break;
    }
    largestMinWidth = Math.max(largestMinWidth, labelWidth);
  }
  labelColumn.minWidth = largestMinWidth;

  const nonGlobalMetricsSettings = metricsSettings ? filterMetrics(metricsSettings) : [];
  const globalMetricsSettings = metricsSettings ? filterGlobalMetrics(metricsSettings) : [];
  const selectedGlobalMetrics = selectedBlock?.selectedMetrics
    ? globalMetricsSettings.filter((setting) => selectedBlock.selectedMetrics.includes(setting.key))
    : [];
  const globalColumns: ColGroupDef[] =
    !requests.length || !selectedGlobalMetrics.length
      ? []
      : [
          {
            headerName: '',
            marryChildren: true,
            children: compact(
              selectedGlobalMetrics.flatMap((globalMetric) => {
                const headerName =
                  isRelative && globalMetric.relativeLabel ? globalMetric.relativeLabel : globalMetric.label;

                const minWidth = (() => {
                  const largestCellContent = Math.max(
                    ...gridData.map(({ globalValue }) => measureGridText(globalValue[globalMetric.key], 'normal')),
                  );
                  const headerMinWidth = measureHeader(headerName);
                  return Math.max(largestCellContent, headerMinWidth);
                })();

                return {
                  headerName,
                  valueGetter: ({ data }) => (data.isMetadata ? null : data.globalValue?.[globalMetric?.key] ?? '--'),
                  minWidth,
                  cellClass: [RIGHT_ALIGN_CLASS],
                };
              }),
            ),
          },
        ];

  const perSubjectColumns = getGroupColDef(
    requests,
    analysesGroup,
    nonGlobalMetricsSettings,
    theme,
    gridStyle,
    isRelative,
    selectedBlock?.contributionToPercentage,
    selectedBlock?.selectedMetrics,
    selectedBlock?.selectedFactors,
  );

  return [getGroupColumnLabel(labelColumn), ...globalColumns, ...perSubjectColumns];
};
