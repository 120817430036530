import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { GetColor, ZIndex, CellLoader } from 'venn-ui-kit';
import { updateAvatar } from 'venn-api';
import { getAvatarUrl, analyticsService } from 'venn-utils';

interface AvatarProps {
  avatarId?: string;
  edit?: boolean;
  width?: number;
  refresh?: () => void;
  isSponsor?: boolean;
}

interface AvatarState {
  isUploading: boolean;
}

class Avatar extends Component<AvatarProps, AvatarState> {
  state = {
    isUploading: false,
  };

  private input: HTMLInputElement | null;

  onImageChange = async () => {
    if (!this.input || !this.input.files) {
      return;
    }
    const image = this.input.files[0];
    this.setState({ isUploading: true });
    analyticsService.profileEdited({
      fieldsChanged: 'avatar',
    });
    await updateAvatar(image, this.props.isSponsor);
    this.setState({ isUploading: false });
    if (this.props.refresh) {
      this.props.refresh();
    }
  };

  onImageClick = () => {
    if (!this.input) {
      return;
    }

    if (!this.state.isUploading) {
      this.input.click();
    }
  };

  render() {
    const { edit, width, avatarId } = this.props;
    const { isUploading } = this.state;
    const url = getAvatarUrl(avatarId);
    return (
      <EditMode onClick={this.onImageClick} className="avatar-upload" edit={edit} width={width}>
        <AvatarContainer>
          <StyledInput
            ref={(input) => {
              this.input = input;
            }}
            type="file"
            onChange={this.onImageChange}
          />
          <Image style={{ backgroundImage: url && `url(${url})` }} />
          {isUploading && <StyledCellLoader />}
        </AvatarContainer>
      </EditMode>
    );
  }
}

const editCSS = css`
  cursor: pointer;
  ::after {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 30px;
    height: 30px;
    background-color: ${GetColor.White};
    color: ${GetColor.Grey};
    border-radius: 50%;
    line-height: 30px;
    text-align: center;
    font-family: FAP;
    content: '\f044';
    font-weight: 900;
    font-size: 18px;
  }
`;

const EditMode = styled.div<{ width?: number; edit?: boolean }>`
  min-width: ${(props) => (props.width ? props.width : 100)}px;
  min-height: ${(props) => (props.width ? props.width : 100)}px;
  max-width: ${(props) => (props.width ? props.width : 100)}px;
  max-height: ${(props) => (props.width ? props.width : 100)}px;
  position: relative;
  ${({ edit }) => edit && editCSS}
`;

const AvatarContainer = styled.div`
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  z-index: ${ZIndex.Front};
  margin-right: 50px;

  .avatar-upload-overlay {
    height: 100%;
  }

  .avatar-upload-overlay-inner {
    height: 100%;
  }
`;

const StyledInput = styled.input`
  display: none;
`;

const Image = styled.div`
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
`;

const StyledCellLoader = styled(CellLoader)`
  position: absolute;
  top: 50%;
  left: 40%;
`;

export default Avatar;
