import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  BenchmarksContext,
  CategoryDropMenu,
  ComparisonDropMenu,
  CompositeBenchmarkModal,
  SliderToggle,
  UserContext,
} from 'venn-components';
import type { DateRange } from 'venn-ui-kit';
import { Notifications, NotificationType } from 'venn-ui-kit';
import FactorLensCurrency from './FactorLensCurrency';
import { HeaderItemLoader, LabelText } from './shared';
import { getBenchmarkResultsFilter } from './utils';
import { shouldShowCategoryPicker } from '../../utils';
import type { AnalysisConfig, CategoryConfig } from 'venn-utils';
import { analyticsService } from 'venn-utils';
import type { PortfolioCompare } from 'venn-api';

interface LeftHeaderGroupProps {
  analysisConfig: AnalysisConfig;
  canToggleRelative: boolean;
  onToggleRelative?: () => void;
  setCategoryConfig?: (categoryConfig: CategoryConfig) => void;
  periodRange?: DateRange;
  controlsDisabled?: boolean;
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default ({
  analysisConfig,
  canToggleRelative,
  onToggleRelative,
  setCategoryConfig,
  periodRange,
  controlsDisabled,
}: LeftHeaderGroupProps) => {
  const { subject, relative } = analysisConfig;
  const { profileSettings, hasPermission } = useContext(UserContext);
  const organization = profileSettings?.organization;
  const activeCurrency = subject?.fund?.currency ?? organization?.currency;

  const benchmarkResultsFilter = getBenchmarkResultsFilter(periodRange);
  const benchmarksContext = useContext(BenchmarksContext);
  const { benchmarks, benchmarksLoading, benchmarksError, onBenchmarkCreate, onClearBenchmark } = benchmarksContext;
  const [benchmarksModal, setBenchmarksModal] = useState(false);
  const hasActiveBenchmark = subject && !!subject.activeBenchmark;
  const templateId = analysisConfig?.analysisTemplate?.id;
  const disabledTooltipText =
    templateId === 'drawdown'
      ? 'Benchmark relative calculations are not supported for this analysis yet.'
      : 'Select a benchmark to enable benchmark-relative analysis';

  useEffect(() => {
    if (benchmarksError) {
      Notifications.notify(benchmarksError, NotificationType.ERROR);
    }
  }, [benchmarksError]);

  // VENN-10005: We can select a benchmark in all cases EXCEPT when an un-saved strategy is selected.
  // An unsaved strategy will have an ID of a timestamp.
  const disabledBenchmarkMessage = !subject
    ? undefined
    : !hasPermission('CONFIGURE_BENCHMARKS')
      ? 'You do not have permission to edit benchmarks'
      : subject.strategyId && subject.strategyId > new Date('2000-01-01').valueOf()
        ? 'Save your portfolio to configure benchmarks for your new strategy'
        : subject.fund?.assetType === 'BENCHMARK'
          ? 'Benchmarks are not currently supported for composite benchmarks'
          : undefined;
  const hasBenchmark = benchmarks.some((compare) => compare.benchmark);
  const canSelectBenchmark = subject && !disabledBenchmarkMessage && !(controlsDisabled && !hasBenchmark);

  const onToggleTurnOffCategory = useCallback(
    (turnedOff: boolean) => setCategoryConfig?.(turnedOff ? 'OFF' : 'ON'),
    [setCategoryConfig],
  );

  const onToggleRelativeButton = useCallback(() => {
    onToggleRelative?.();
    analyticsService.toggleToggled({
      purpose: 'Toggle relative benchmark',
      selection: !relative ? 'On' : 'Off',
      locationOnPage: 'Analysis header',
    });
  }, [onToggleRelative, relative]);

  return (
    <>
      <FactorLensCurrency activeCurrency={activeCurrency} />

      <BenchmarkWrapper className="qa-benchmark">
        <ComparisonDropMenu
          resultsFilter={benchmarkResultsFilter}
          benchmarks={benchmarks}
          disabled={!canSelectBenchmark}
          disabledTooltipMessage={disabledBenchmarkMessage}
          onSelectBenchmark={onBenchmarkCreate}
          onClearBenchmark={onClearBenchmark}
          investmentsOnly={subject?.type === 'investment'}
          loading={benchmarksLoading}
        />
      </BenchmarkWrapper>
      <ItemWrapper className="qa-relative">
        <LabelText>Relative to Benchmark:</LabelText>
        <HeaderItemLoader>
          <SliderToggle
            disabled={!hasActiveBenchmark || !canToggleRelative || controlsDisabled}
            toggled={relative}
            onToggle={onToggleRelativeButton}
            tooltipMessage={!canToggleRelative ? disabledTooltipText : undefined}
            loading={benchmarksLoading}
          />
        </HeaderItemLoader>
      </ItemWrapper>

      {subject !== undefined && shouldShowCategoryPicker(subject) && (
        <ItemWrapper className="qa-category">
          <LabelText>Category:</LabelText>
          <CategoryDropMenu
            subject={subject}
            isOff={analysisConfig && analysisConfig.category === 'OFF'}
            onToggleTurnOffCategory={onToggleTurnOffCategory}
            disabled={controlsDisabled}
          />
        </ItemWrapper>
      )}

      {benchmarksModal && (
        <CompositeBenchmarkModal
          onClose={() => setBenchmarksModal(false)}
          onSubmit={(newBenchmark: PortfolioCompare) => onBenchmarkCreate(newBenchmark)}
          resultsFilter={benchmarkResultsFilter}
        />
      )}
    </>
  );
};

const BenchmarkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  min-width: 300px;
  max-width: 300px;
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  max-width: 200px;
`;
