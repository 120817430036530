import React, { PureComponent } from 'react';
import { TextControls, PaginationButton } from './components';
import Icon from '../icon/Icon';

export interface RightSideControlsProps {
  pagesCount: number;
  selectedPage: number;
  onPageChange: (pageIndex: number) => void;
}

export class RightSideControls extends PureComponent<RightSideControlsProps> {
  handleNextClick = () => this.props.onPageChange(this.props.selectedPage + 1);

  handleLastClick = () => this.props.onPageChange(this.props.pagesCount);

  render() {
    const { selectedPage, pagesCount } = this.props;
    return (
      <TextControls>
        <PaginationButton disabled={selectedPage >= pagesCount} onClick={this.handleNextClick}>
          <Icon type="angle-right" />
        </PaginationButton>

        <PaginationButton disabled={selectedPage >= pagesCount} onClick={this.handleLastClick}>
          <Icon type="angle-double-right" />
        </PaginationButton>
      </TextControls>
    );
  }
}
