import React, { useContext, useRef } from 'react';
import type { AnalysisStatusForTracking } from 'venn-components';
import { StickyMenu, Footer, ComparisonContext, OptimalPortfolioContext, PrintDisabledWarning } from 'venn-components';
import type { AnalysesPeriod, TimeFrame, AnalysisSubject, CategoryConfig } from 'venn-utils';
import { Analysis, Content, MinWidth } from './Layout';
import Header from './header/Header';
import PortfolioNavs from './header/PortfolioNavs';
import { getMenu } from '../configs';

import type { SharedProps } from '../types';
import AnalysisBlocks from './AnalysisBlocks';
import type { Fund, GeneralAnalysisTemplate } from 'venn-api';
import { SupportedErrorCodes } from 'venn-api';
import type { RangeType } from 'venn-ui-kit';
import MediaQuery from 'react-responsive';
import { useHasFF } from 'venn-utils';

export interface AnalysisContentProps extends SharedProps {
  defaultAnalysesPeriod: AnalysesPeriod;
  onToggleRelative: () => void;
  canToggleRelative: boolean;
  setTimeFrame: (selectedTimeFrame: TimeFrame, selectedPeriod?: RangeType) => void;
  setCategoryConfig: (categoryConfig: CategoryConfig) => void;
  templateId: string;
  templates: GeneralAnalysisTemplate[];
  onChangeAnalysisTemplate: (type: GeneralAnalysisTemplate) => void;
  onEditTemplate: (id: string) => void;
  onFundUpdated: (fund: Fund) => void;
  onChangeSubject?: (item: AnalysisSubject) => void;
  updateAnalysisStatusForTracking: (
    actionAnalysisStatuses: AnalysisStatusForTracking[],
    actionTrackingId: number,
    dateRange?: string,
  ) => void;
  currentViewName?: string;
}

const AnalysisContent = ({
  defaultAnalysesPeriod,
  onToggleRelative,
  canToggleRelative,
  setTimeFrame,
  setCategoryConfig,
  templateId,
  templates,
  onChangeAnalysisTemplate,
  onEditTemplate,
  onChangeSubject,
  analysisConfig,
  analysesError,
  analysisLoading,
  factorLenses,
  onResetTimeFrame,
  analyses,
  onForecastUpdate,
  onFundUpdated,
  updateAnalysisStatusForTracking,
  currentViewName,
}: AnalysisContentProps) => {
  const { optimizeAdHoc } = useContext(OptimalPortfolioContext);
  const { compareType } = useContext(ComparisonContext);
  const hasExportsDisabled = useHasFF('disable_exports_ff');
  const hasReportLab = useHasFF('studio_report_editor');
  const refreshOptimalSolution = compareType === 'Optimized' ? optimizeAdHoc : undefined;
  const controlsDisabled = analysesError?.code === SupportedErrorCodes.AnalysisLimitErrorCode;

  const onForecastUpdateWrapper = () => {
    onForecastUpdate?.();
    refreshOptimalSolution && refreshOptimalSolution();
  };
  const getHeaderOffset = () => stickyNode.current?.offsetHeight || 0;

  const stickyNode = useRef<HTMLDivElement>(null);
  const menuItems = getMenu(templateId);
  const sharedProps = {
    analysisConfig,
    analysesError,
    analysisLoading,
    factorLenses,
    onResetTimeFrame,
    analyses,
    onForecastUpdate: onForecastUpdateWrapper,
    onFundUpdated,
    updateAnalysisStatusForTracking,
    getHeaderOffset,
  };

  return (
    <Analysis hasSaveViewBar>
      <Content>
        <Header
          currentViewName={currentViewName}
          stickyNodeReference={stickyNode}
          analysesPeriod={defaultAnalysesPeriod}
          onToggleRelative={onToggleRelative}
          canToggleRelative={canToggleRelative}
          {...sharedProps}
          setTimeFrame={setTimeFrame}
          setCategoryConfig={setCategoryConfig}
          onChangeAnalysisTemplate={onChangeAnalysisTemplate}
          templates={templates}
          onEditTemplate={onEditTemplate}
          onForecastUpdate={onForecastUpdateWrapper}
          onFundUpdated={onFundUpdated}
          onChangeSubject={onChangeSubject}
          controlsDisabled={controlsDisabled}
          renderMenu={
            menuItems !== undefined
              ? () => <StickyMenu items={menuItems} getStartOffset={getHeaderOffset} marginBetweenSections={60} />
              : undefined
          }
        />
        <MediaQuery print>
          {(print) => (
            <>
              {hasExportsDisabled && !hasReportLab && <PrintDisabledWarning />}
              {(!print || !hasExportsDisabled || hasReportLab) && <AnalysisBlocks {...sharedProps} />}
            </>
          )}
        </MediaQuery>
      </Content>
      <MinWidth>
        <PortfolioNavs analysisConfig={sharedProps.analysisConfig} />
        <Footer />
      </MinWidth>
    </Analysis>
  );
};

export default AnalysisContent;
