import React, { useEffect } from 'react';
import styled from 'styled-components';
import type { EmptyStateProps } from '../../empty-state/EmptyState';
import EmptyState from '../../empty-state/EmptyState';
import { blockExcelFunction, useScopedSetRecoilState } from 'venn-state';
import { useAppPrintMode } from '../../print';

/**
 * This styled empty state is for Studio and Report Lab and defaults to a smaller error
 * state for printing mode
 */
const StyledEmptyState = (props: EmptyStateProps & { selectedRefId: string }) => {
  const { inPrintModeOrReportLab } = useAppPrintMode();
  const setExportFunction = useScopedSetRecoilState(blockExcelFunction(props.selectedRefId));

  useEffect(() => {
    setExportFunction({ excelFunction: undefined });
  }, [setExportFunction]);

  return <StyledEmptyStateComponent {...props} small={inPrintModeOrReportLab ? true : props.small} />;
};
export default StyledEmptyState;

const StyledEmptyStateComponent = styled(EmptyState)`
  margin: 20px;
`;
