import React from 'react';
import styled from 'styled-components';
import { GetColor, Icon, Tooltip, TooltipPosition } from 'venn-ui-kit';
import type { CellRenderer } from './types';
import { getTypeId } from '../../fetchers';
import { DataUploaderMode, FUND } from '../../types';
import {
  getDeletedTypeId,
  isColumnDeleted,
  updateColumnMappingsByFundChange,
  updateMappingColumnState,
} from '../../views/mapping/helpers';
import type { ColumnMapping, FileMapping, FileUploadMetadata } from 'venn-api';
import { isDataNew } from './helpers';

interface DeleteCellRendererProps {
  mode: DataUploaderMode;
  mapping: FileMapping;
  columns: ColumnMapping[];
  metadata: FileUploadMetadata;
  onChange: (newMapping: FileMapping) => void;
  columnMapping: ColumnMapping;
}

// @ts-expect-error: TODO fix strictFunctionTypes
export const DeleteCellRenderer: CellRenderer = ({
  mode,
  mapping,
  columns,
  metadata,
  onChange,
  columnMapping,
}: DeleteCellRendererProps) => {
  const { currency, seriesId, appendId, newDataCount, fundId, portfolioNodeId } = columnMapping;
  const fundTypeId = getTypeId(metadata, 'dataType', FUND);
  const isDeleted = isColumnDeleted(columnMapping, metadata);
  const deletedTypeId = getDeletedTypeId(metadata);
  const isReturns = mode === DataUploaderMode.Returns;
  const isNew = isDataNew(isReturns, !!fundId, !!portfolioNodeId);

  const values = [appendId, currency, isDeleted ? fundTypeId : deletedTypeId];
  return newDataCount === 0 ? (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Tooltip
        position={TooltipPosition.Left}
        content={
          <>
            <div>No new observations were found.</div>
            {isNew ? (
              <div>This item will not be uploaded.</div>
            ) : (
              <div>This data will not be uploaded unless you select 'overwrite'.</div>
            )}
          </>
        }
      >
        <WarningIcon type="exclamation-circle" />
      </Tooltip>
    </div>
  ) : (
    <DeleteIcon
      isDeleted={isDeleted}
      prefix="fas"
      type={isDeleted ? 'redo' : 'trash'}
      onClick={() =>
        onChange(
          updateMappingColumnState(
            {
              mapping,
              columns,
              values,
              seriesId,
            },
            updateColumnMappingsByFundChange,
            mode,
          ),
        )
      }
    />
  );
};

const DeleteIcon = styled(Icon)<{ isDeleted?: boolean }>`
  cursor: pointer;
  ${({ isDeleted }) =>
    isDeleted &&
    `
    color: ${GetColor.Primary.Dark};
  `};
  &:hover {
    color: ${GetColor.Primary.Main};
  }
`;

const WarningIcon = styled(Icon)`
  color: ${GetColor.Error};
`;
