import styled from 'styled-components';

export const Content = styled.div`
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
`;

export const PagePadding = styled.div`
  padding: 50px 60px 15px;
  min-width: 1220px;
`;
