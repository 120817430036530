import styled, { css } from 'styled-components';
import { GetColor } from 'venn-ui-kit';

interface LabelProps {
  children?: JSX.Element[] | JSX.Element | string;
  className?: string;
  required?: boolean;
}

const StyledLabel = styled.label<LabelProps>`
  ${(props) =>
    props.required
      ? css`
          :after {
            margin-left: 2px;
            color: ${GetColor.UNSAFE.Red};
            content: '*';
          }
        `
      : ''};
`;

export default StyledLabel;
