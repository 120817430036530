import React, { useMemo } from 'react';
import styled from 'styled-components';

import type { ItemType } from 'venn-ui-kit';
import { GetColor, Body1, FilterPill, Button } from 'venn-ui-kit';

import { PAGE_SIZE } from './constants';
import type { SectionKey } from './types';
import BulkCancel from './BulkCancel';
import BulkDelete from './BulkDelete';
import type { AnalysisViewSearchResultWithUIState, SavedViewsQuickFilter } from 'venn-utils';
import { getResultRangeEnd, getResultRangeStart, plural } from 'venn-utils';
import { getFilterPills } from './utils/getFilterPills';

export interface TableAnnotationFilters {
  quickFilters: SavedViewsQuickFilter[];
  itemTypes: ItemType[];
}

interface TableAnnotationProps {
  results: AnalysisViewSearchResultWithUIState[];
  fetchResults: () => void;
  onPageChange: (index: number) => void;
  totalCount: number;
  page: number;
  filters: TableAnnotationFilters;
  toggleParamItem: (key: SectionKey, item: SavedViewsQuickFilter | ItemType) => void;
  userId?: number;
  handleReset: () => void;
}

const TableAnnotation = ({
  results,
  fetchResults,
  onPageChange,
  totalCount,
  page,
  filters,
  toggleParamItem,
  userId,
  handleReset,
}: TableAnnotationProps) => {
  const rangeStart = getResultRangeStart(results.length, page, PAGE_SIZE);
  const rangeEnd = getResultRangeEnd(results.length, page, PAGE_SIZE);
  const selectedItems = results.filter((item) => item.selected);
  const selectedCount = selectedItems.length;

  const pills = useMemo(
    () =>
      getFilterPills({
        quickFilters: filters.quickFilters,
        itemTypes: filters.itemTypes,
        toggleParamItem,
      }),
    [filters, toggleParamItem],
  );

  const showPills = !!pills.length;

  const bulkMenu = (
    <ActionsContainer>
      <Body1>{selectedCount} Selected</Body1>
      <ItemsSelectedDivider />
      <BulkDelete
        items={selectedItems}
        fetchResults={fetchResults}
        onPageChange={onPageChange}
        currentPage={page}
        totalCount={totalCount}
        userId={userId}
      />
      <BulkCancel items={selectedItems} fetchResults={fetchResults} />
    </ActionsContainer>
  );

  const filterPills = (
    <FilterPillContainer>
      <ResultCount>
        {showPills
          ? plural(totalCount, { 1: '{{count}} result', other: '{{count}} results' })
          : `Showing ${rangeStart}-${rangeEnd} of ${totalCount}`}
      </ResultCount>
      {showPills ? (
        <>
          <ItemsSelectedDivider />
          <FilterPills>
            {pills?.map(({ key, label, onClick, icon }) => (
              <FilterPill key={key} name={label} iconType={icon} onClose={onClick} />
            ))}
          </FilterPills>
          <Button
            className="qa-clear-all"
            flat
            onClick={() => handleReset()}
            style={{ minHeight: 20, padding: 0, marginLeft: 15 }}
          >
            Clear All
          </Button>
        </>
      ) : null}
    </FilterPillContainer>
  );

  return selectedCount ? bulkMenu : filterPills;
};

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const FilterPills = styled.div`
  overflow-x: auto;
  display: flex;
  white-space: nowrap;

  ::-webkit-scrollbar {
    height: 8px;
  }
`;

const ResultCount = styled(Body1)`
  white-space: nowrap;
`;

const ItemsSelectedDivider = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin: 0 13px 0 19px;
  width: 1px;
  height: 24px;
  border: solid 0.5px ${GetColor.Grey};
`;

const FilterPillContainer = styled.div`
  display: flex;
  max-width: 100%;
`;

export default TableAnnotation;
