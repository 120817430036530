import React, { Component } from 'react';
import styled from 'styled-components';

import { ManualAuthentication } from './ManualAuthentication';

import { GetColor, Button, Icon, getAppTitle } from 'venn-ui-kit';
import { FS } from 'venn-utils';

interface TryAnotherWayProps {
  backupCodes: () => void;
  requestManualAuthentication: () => void;
  email: string;
}

interface TryAnotherWayState {
  manualAuthentication: boolean;
}

const StyledIcon = styled(Icon)`
  font-size: 36px;
  width: 44px;
  vertical-align: middle;
  text-align: center;
`;

const ButtonTitle = styled.p`
  font-size: 12px;
  font-weight: bold;
`;

const ButtonLine = styled.p`
  font-size: 12px;
  font-weight: normal;
`;

const ButtonText = styled.div`
  display: inline-block;
  vertical-align: middle;
  padding-left: 9px;
`;

const ButtonContainer = styled.div`
  padding: 10px 0 28px;
`;

const IconButton = styled(Button)`
  font-size: 12px;
  font-weight: bold;
  color: ${GetColor.White};
  background-color: ${GetColor.Black};
  width: 100%;
  height: 62px;
  border: 1px solid ${GetColor.White};
  border-color: ${GetColor.White};
  text-transform: none;
  letter-spacing: normal;
  text-align: left;
  padding: 12px 9px;
  justify-content: left;
  display: inline-block;
  margin-bottom: 10px;

  p {
    margin: 0;
  }
`;

const AuthenticationInfo = styled.p`
  font-size: 12px;
  font-family: ${(props) => props.theme.Typography.fontFamily};
  color: ${GetColor.White};
  font-weight: bold;
`;

const AuthenticationHint = styled.p`
  font-size: 14px;
  font-family: ${(props) => props.theme.Typography.fontFamily};
  color: ${GetColor.HintGrey};
`;

export class TryAnotherWay extends Component<TryAnotherWayProps, TryAnotherWayState> {
  state = {
    manualAuthentication: false,
  };

  manualAuthentication = () => {
    this.setState({
      manualAuthentication: true,
    });
  };

  goBack = () => {
    this.setState({
      manualAuthentication: false,
    });
  };

  render() {
    const { backupCodes, email, requestManualAuthentication } = this.props;
    const { manualAuthentication } = this.state;

    if (manualAuthentication) {
      return (
        <ManualAuthentication
          email={email}
          requestManualAuthentication={requestManualAuthentication}
          goBack={this.goBack}
        />
      );
    }

    return (
      <>
        <AuthenticationInfo>Two-Factor Authentication</AuthenticationInfo>
        <AuthenticationHint>
          Having trouble with your primary two-factor authorization? Try another way
          {FS.has('mfa_updates_ff') &&
            ", or contact your organization's Workspace Administrator to reset it for you under the Settings > Security tab"}
        </AuthenticationHint>
        <ButtonContainer>
          <IconButton onClick={backupCodes}>
            <StyledIcon type="check-circle" prefix="fas" />
            <ButtonText>
              <ButtonTitle>Use Your Backup Codes</ButtonTitle>
              <ButtonLine>Enter one of your saved backup codes.</ButtonLine>
            </ButtonText>
          </IconButton>
          <IconButton onClick={this.manualAuthentication}>
            <StyledIcon type="comments" prefix="fas" />
            <ButtonText>
              <ButtonTitle>{`Contact ${getAppTitle()} for Manual Authentication`}</ButtonTitle>
              <ButtonLine>This process could take 3-5 business days.</ButtonLine>
            </ButtonText>
          </IconButton>
        </ButtonContainer>
      </>
    );
  }
}
