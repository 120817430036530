import { createContext } from 'react';
import { noop } from 'lodash';

export interface MetadataContextValue {
  drawdownPeriodStart?: number;
  drawdownPeriodEnd?: number;
  setDrawdownPeriod: (start: number | undefined, end: number | undefined) => void;
}

export default createContext<MetadataContextValue>({
  setDrawdownPeriod: noop,
});
