import type { OperationResult } from 'venn-api';
import useFetchApiConditionally from './useFetchApiConditionally';
import type { FetchApiResult } from './types';

/**
 * This hook is meant to be a convenient way of calling API functions.
 *
 * It manages state to hold the result of the API call, the loading state, and any error state.
 *
 * It also wraps the api function in the {@link useApi} hook and takes care of checking whether the component
 * is mounted before set any state.
 *
 * @param apiFunction The api function to call.
 * @param args The arguments to the api function.
 */
const useFetchApi = <T extends unknown[], U>(
  apiFunction: (...args: T) => Promise<OperationResult<U>>,
  ...args: T
): FetchApiResult<U> => {
  return useFetchApiConditionally(true, apiFunction, ...args);
};

export default useFetchApi;
