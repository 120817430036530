import type { FC } from 'react';
import React from 'react';
import moment from 'moment';
import { getAppTitle } from 'venn-ui-kit';

export interface CopyrightProps {
  className?: string;
  lineBreak?: boolean;
  year?: number;
}

const Copyright: FC<React.PropsWithChildren<CopyrightProps>> = ({ className, lineBreak, year }) => (
  <div className={className}>
    {`© ${year} Two Sigma Investments, LP. All rights reserved. `}
    {lineBreak && <br />}
    {`Two Sigma, the Two Sigma logo, ${getAppTitle()} and the ${getAppTitle()} logo are trademarks of Two Sigma Investments, LP.`}
  </div>
);

Copyright.defaultProps = {
  className: '',
  lineBreak: false,
  year: Number(moment.utc().format('YYYY')),
};

export default Copyright;
