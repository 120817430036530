import React, { Component } from 'react';
import styled from 'styled-components';
import AuthButton from './AuthButton';

import { getAppTitle, GetColor, getTextThemeProvider } from 'venn-ui-kit';

interface ManualAuthenticationProps {
  email: string;
  goBack: () => void;
  requestManualAuthentication: () => void;
}

interface ManualAuthenticationState {
  requested: boolean;
}

const AuthenticationInfo = styled.p`
  font-size: 12px;
  font-family: ${(props) => props.theme.Typography.fontFamily};
  color: ${GetColor.White};
  font-weight: bold;
`;

const AuthenticationHint = styled.p`
  font-size: 14px;
  font-family: ${(props) => props.theme.Typography.fontFamily};
  color: ${GetColor.HintGrey};
`;

const ButtonContainer = styled.div`
  padding: 10px 0 28px;
`;

const Anchor = styled.a`
  color: ${GetColor.Primary.Dark};
  font-size: 14px;

  &:hover {
    color: ${GetColor.White};
  }
`;

export class ManualAuthentication extends Component<ManualAuthenticationProps, ManualAuthenticationState> {
  state = {
    requested: false,
  };

  requestManualAuthentication = async () => {
    await this.props.requestManualAuthentication();

    this.setState({
      requested: true,
    });
  };

  render() {
    const { requested } = this.state;
    const { email, goBack } = this.props;

    return (
      <>
        <AuthenticationInfo>Manual Authentication and Account Recovery</AuthenticationInfo>
        {requested ? (
          <>
            <AuthenticationHint>
              {`A request for manual account recovery has been sent to ${getAppTitle()} support. We will reach out to you at {email}{' '}
              within 3-5 business days.`}
            </AuthenticationHint>
            <AuthenticationHint>
              Your account will be locked and inaccessible until we manually verify your information.
            </AuthenticationHint>
            <AuthenticationHint>
              <Anchor href={`mailto:${getTextThemeProvider().supportEmail}`}>{`Contact ${getAppTitle()}`}</Anchor> if
              you have any questions.
            </AuthenticationHint>
          </>
        ) : (
          <>
            <AuthenticationHint>
              We want to ensure that you - and not someone who stole your password - are the one trying to get into your
              account. Because of this, it will take at least 3 business days to get back into your account when you
              request Manual Authentication. We will contact you at {email}.
            </AuthenticationHint>
            <AuthenticationHint>
              Click the back button and choose one of your Two-Factor Authentication methods to get access to your
              account more quickly.
            </AuthenticationHint>
            <ButtonContainer>
              <AuthButton dominant onClick={goBack}>
                Back
              </AuthButton>
              <AuthButton onClick={this.requestManualAuthentication}>Request Manual Authentication</AuthButton>
            </ButtonContainer>
          </>
        )}
      </>
    );
  }
}
