import type { FC } from 'react';
import React from 'react';
import { CHART_TOP_TEXT_PADDING, CHART_PADDING } from './constants';
import { GetColor } from 'venn-ui-kit';
import styled from 'styled-components';

interface TitleProps {
  print: boolean;
}

const Title: FC<React.PropsWithChildren<TitleProps>> = ({ print, children }) => (
  <StyledText x={CHART_PADDING} y={CHART_TOP_TEXT_PADDING} print={print}>
    {children}
  </StyledText>
);

export default Title;

const StyledText = styled.text<{ print: boolean }>`
  font-family: ${(props) => props.theme.Typography.fontFamily};
  font-size: ${(props) => (props.print ? '14px' : '20px')};
  fill: ${GetColor.Black};
  font-weight: bold;
`;
