import React from 'react';

const FactorSummaryIcon = () => (
  <svg viewBox="0 0 55 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="19.6914" y="0.197632" width="35.3082" height="3.95038" fill="#3E9397" />
    <rect x="19.6914" y="4.54309" width="9.50604" height="3.95038" fill="#3E9397" />
    <rect y="8.88843" width="19.6911" height="3.95038" fill="#B7793A" />
    <line x1="20.1914" y1="-1.2733e-08" x2="20.1914" y2="13.0362" stroke="#D8D8D8" />
    <rect x="19.6504" y="17.5973" width="23.2713" height="3.82396" fill="#3E9397" />
    <rect x="16.0703" y="13.4258" width="3.5802" height="3.82396" fill="#B7793A" />
    <line x1="20.1504" y1="13.0781" x2="20.1504" y2="22.1166" stroke="#D8D8D8" />
  </svg>
);

export default FactorSummaryIcon;
