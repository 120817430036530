import React, { useContext, useState } from 'react';
import { useRecoilState } from 'recoil';
import { Button, GetColor, Icon, ShimmerBlock, StudioButton, Tooltip } from 'venn-ui-kit';
import { withSuspense } from 'venn-utils';
import { UserContext } from 'venn-components';
import { FlexHeader, Section } from '../../../shared';
import styled from 'styled-components';
import { blockSelectedPeerGroupId } from 'venn-state';
import { SelectPeerGroup } from './components/SelectPeerGroup';

interface PeerGroupsSectionProps {
  selected: string;
}

const PeerGroupsSection = ({ selected }: PeerGroupsSectionProps) => {
  const { hasPermission } = useContext(UserContext);
  const isReadOnly = !hasPermission('STUDIO_EDIT');

  const [peerGroup, setPeerGroup] = useRecoilState(blockSelectedPeerGroupId(selected));

  const [isPeerGroupEditorOpen, setIsPeerGroupEditorOpen] = useState<boolean>(false);

  return (
    <Section>
      <FlexHeader>
        Peer Group
        <Tooltip
          usePortal
          content={
            <div>
              Peer Groups are comprised of investments available in the Venn Data Library at the time of analysis,
              filtered based on categories provided by third parties.
            </div>
          }
          maxWidth={360}
        >
          <Icon prefix="far" type="info-circle" />
        </Tooltip>
      </FlexHeader>
      <Content>
        {peerGroup ? (
          <PeerGroupContainer>
            {peerGroup.categoryName}
            <StudioButton
              data-testid="qa-edit-peer-group"
              size="small"
              disabled={isReadOnly || isPeerGroupEditorOpen}
              onClick={() => setIsPeerGroupEditorOpen(true)}
            >
              <Icon type="pen" />
            </StudioButton>
          </PeerGroupContainer>
        ) : (
          <Button
            className="open-peer-group-modal-btn"
            data-testid="qa-select-peer-group-btn"
            dense
            disabled={isReadOnly || isPeerGroupEditorOpen}
            onClick={() => setIsPeerGroupEditorOpen(true)}
          >
            SELECT PEER GROUP
          </Button>
        )}
      </Content>
      {isPeerGroupEditorOpen && (
        <SelectPeerGroup
          selectedPeerGroupId={peerGroup}
          onApply={setPeerGroup}
          onClose={() => setIsPeerGroupEditorOpen(false)}
        />
      )}
    </Section>
  );
};

const PeerGroupContainer = styled.div`
  height: 34px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${GetColor.Grey};
  border-radius: 4px;
  padding: 0 10px;
  font-size: 12px;
  font-weight: bold;
`;
const Content = styled.div`
  .open-peer-group-modal-btn {
    width: 100%;
  }
`;

export default withSuspense(<ShimmerBlock />, PeerGroupsSection);
