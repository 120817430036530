import React from 'react';
import type { IHeaderParams } from 'ag-grid-community';
import styled from 'styled-components';
import { Label, Tooltip } from 'venn-ui-kit';
import { CellWrapper } from './shared';

interface BasicCellRendererProps extends IHeaderParams {
  color?: string;
  tooltip?: string;
  style?: React.CSSProperties;
  className?: string;
}

export const BasicHeaderRenderer = ({ displayName, color, tooltip, style, className }: BasicCellRendererProps) => {
  return (
    <HeaderCellWrapper className={className} color={color} style={style}>
      <Tooltip block usePortal content={tooltip && <Label inverted>{tooltip}</Label>}>
        {displayName}
      </Tooltip>
    </HeaderCellWrapper>
  );
};

const HeaderCellWrapper = styled(CellWrapper)`
  width: calc(100% - 10px);

  text-align: right;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-top: 5px;
  padding-bottom: 5px;

  @media print {
    width: 100%;
  }
`;
