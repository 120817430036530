import {
  AuthButton,
  AuthLoader,
  AuthLogo,
  ContentWrapper,
  Info,
  StyledCheckboxWrapper,
  StyledHeadline1,
} from '../components';
import { Body2, Button, GetColor, Subtitle1 } from 'venn-ui-kit';
import { BackupCodeList, useBackupCodes } from 'venn-components';
import React from 'react';
import styled from 'styled-components';

interface BackupCodesWrapperProps {
  onComplete: () => void;
  confirmMessage: string;
}

const BackupCodesWrapper = ({ confirmMessage, onComplete }: BackupCodesWrapperProps) => {
  const { backupCodes, confirmCheckboxClicked, onConfirmClick, downloadCodes, onGenerateMoreCodes, loading, error } =
    useBackupCodes();

  return (
    <ContentWrapper>
      <AuthLogo />
      <Info>
        <StyledHeadline1>Save your backup codes</StyledHeadline1>
        <Subtitle1>
          We strongly recommend saving your backup codes in a safe place. These codes will allow you to gain access to
          your account in the event that you lose access to your primary authentication method.
        </Subtitle1>
      </Info>
      {loading ? (
        <AuthLoader />
      ) : (
        <Content>
          {' '}
          <BackupCodeList backupCodes={backupCodes} loading={loading} colorInverted>
            <ButtonContainer>
              <Button dense dominant onClick={downloadCodes} disabled={loading}>
                Download Codes
              </Button>
              <Button dense onClick={onGenerateMoreCodes} disabled={loading}>
                Reset Codes
              </Button>
            </ButtonContainer>
          </BackupCodeList>
          <Error>{error}</Error>
          <form onSubmit={onComplete}>
            <Terms>
              <StyledCheckboxWrapper
                checkboxDataTestId="terms-checkbox"
                checked={confirmCheckboxClicked}
                onChange={onConfirmClick}
                required
              >
                <Body2 inverted>
                  I understand that I could permanently lose access to my account, or need to go through a 3–5 day
                  manual authentication process if I lose these codes or am unable to access {confirmMessage}.
                </Body2>
              </StyledCheckboxWrapper>
            </Terms>
            <AuthButtonContainer>
              <AuthButton dominant type="submit" disabled={!confirmCheckboxClicked}>
                Finish & Start Using Venn
              </AuthButton>
            </AuthButtonContainer>
          </form>
        </Content>
      )}
    </ContentWrapper>
  );
};

export default BackupCodesWrapper;

const AuthButtonContainer = styled.div`
  margin-top: 15px;
`;

const Content = styled.div`
  margin-top: 30px;
  max-width: 450px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
`;

const Terms = styled.div`
  margin-top: 15px;
`;

const Error = styled(Body2)`
  color: ${GetColor.Error};
`;
