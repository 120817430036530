import type { AnyDuringEslintMigration, ExcelCell, ExcelCellValue, ExcelWorkbook, MetaData } from 'venn-utils';
import {
  formatDownloadMessage,
  formatGeneralDownloadMetaData,
  getRawMetadataLabel,
  getSafeWorkSheetName,
  logExceptionIntoSentry,
  logMessageToSentry,
} from 'venn-utils';
import type { DownloadMetaData, DownloadTrackingEvent, ExcelSheetData } from './types';
import type { DataExportEvent } from 'venn-api';
import { compact, isNil } from 'lodash';
import type { Theme } from 'venn-ui-kit';
import { renderToStaticMarkup } from 'react-dom/server';
import { ThemeProvider } from 'styled-components';
import React from 'react';
import DownloadFooter from './DownloadFooter';
import { ExcelStyleCache } from './excelStyleCache';

/**
 * Exports Excel cell data with formatting options on each cell.
 * @param exportData Export data
 * @param exportFilename Filename
 * @param sheetName the name of the sheet in the excel workbook, defaults to "Sheet 1"
 * @param autofit whether the column widths are resized to try to fit the data
 */

export const onExportExcel = async (
  exportData: (ExcelCell | undefined)[][],
  exportFilename?: string,
  sheetName?: string,
  autofit?: boolean,
) => {
  if (!exportData) {
    return;
  }

  await generateExcel(exportData, writeToFile, exportFilename, sheetName, autofit);
};

/**
 * Add data into a workbook sheet
 * @param workbook xlsl workbook
 * @param workSheetIndex 0 will be the first sheet
 * @param workSheetName a sheet's name
 * @param exportData data to parse
 * @param prependIndex add sheet index into sheet name
 */
const addDataToSheet = (
  workbook: ExcelWorkbook,
  styleCache: ExcelStyleCache,
  workSheetIndex: number,
  workSheetName: string,
  exportData: (ExcelCell | undefined)[][],
  prependIndex?: boolean,
) => {
  const values = exportData.map((row) => row.map((c) => c?.value ?? ''));
  const sheetName = getSafeWorkSheetName(workSheetName);
  const sheet =
    workSheetIndex === 0
      ? workbook.sheet(workSheetIndex).name(sheetName)
      : workbook.addSheet(`${prependIndex ? `${workSheetIndex}-` : ''}${sheetName}`);

  sheet.cell('A1').value(values);

  const maxCol = sheet.usedRange().endCell().column().columnNumber();
  for (let col = 1; col <= maxCol; col++) {
    const rowWidths = exportData.map((val) => (val[col - 1] ? (val[col - 1]?.value || '').toString().length + 4 : 1));
    const customWidths = compact(exportData.map((val) => (val[col - 1] ? val[col - 1]?.width : null)));
    const maxColumnWidth = customWidths.length ? Math.max(...customWidths) : Math.max(...rowWidths) * 1.1;
    // 1.1 seems to be about average character width for the data we see
    sheet.column(col).width(maxColumnWidth);

    for (let row = 1; row <= exportData.length; row++) {
      const cellValue = exportData[row - 1][col - 1];
      if (cellValue) {
        /** We use this object to build up the set of styles, then apply them all at once. Otherwise they overwrite eachother. */
        const styles: ExcelCell['style'] = {};
        if (cellValue.bold) {
          styles.bold = true;
        }

        if (
          cellValue.digits !== undefined ||
          cellValue.percentage ||
          cellValue.currency ||
          !isNil(cellValue.currencySymbol)
        ) {
          // Consider refactoring this to something like cellValue.numberKind: '%' | '$' | 'none';
          // Since otherwise having both currency and percentage simultaneoulsy is a strange but possible behavior.
          const currencySymbol = cellValue.currencySymbol ?? (cellValue.currency ? '$' : '');

          // adding hashes will create a pattern like $#,##0.00 which will separate thousands/millions with extra
          // commas, e.g. instead of 1000000.00 it will be 1,000,000.00
          const prefix = currencySymbol ? `${currencySymbol}#,##` : '';
          const postfix = cellValue.percentage ? '%' : '';
          const format = `${prefix}0.${'0'.repeat(cellValue.digits !== undefined ? cellValue.digits : 2)}${postfix}`;
          styles.numberFormat = format;
        }

        if (cellValue.style) {
          Object.assign(styles, cellValue.style);
        }

        if (Object.keys(styles).length) {
          sheet.row(row).cell(col).style(styleCache.memoizedGetStyle(styles));
        }
      }
    }
  }
};

const autofitSheet = (sheet: AnyDuringEslintMigration) => {
  const maxCol = sheet.usedRange().endCell().column().columnNumber();

  const maxRow = sheet.usedRange().endCell().row().rowNumber();

  for (let col = 1; col <= maxCol; col++) {
    const range = sheet.range(sheet.cell(1, col), sheet.cell(maxRow, col)).value();
    const values = range.map((r: AnyDuringEslintMigration) => r[0]);
    const maxWidth = Math.max(...values.map((value: number | string) => String(value).length));

    // 1.1 seems to be about average character width for the data we see
    sheet.column(col).width(maxWidth * 1.1);
  }
};

const xlsxPopulateCatch = (error: unknown) => {
  if (error instanceof Error || typeof error === 'string') logExceptionIntoSentry(error);
  else logMessageToSentry(`Export to excel failed due to error ${error}`);

  throw error;
};

export const generateExcel = async (
  exportData: (ExcelCell | undefined)[][],
  callback: (file: unknown, filename: string) => void,
  exportFilename?: string,
  sheetName?: string,
  autofit?: boolean,
) => {
  const generatedName = `${exportFilename}.xlsx`;
  const workSheetName = sheetName ?? 'Sheet 1';
  const xlsxPopulate = (await import('xlsx-populate/browser/xlsx-populate')).default;

  xlsxPopulate.fromBlankAsync().then((wb: ExcelWorkbook) => {
    const styleCache = new ExcelStyleCache(wb);
    addDataToSheet(wb, styleCache, 0, workSheetName, exportData);
    if (autofit) {
      autofitSheet(wb.sheet(0));
    }
    // Write to file.
    callback(wb, generatedName);
  }, xlsxPopulateCatch);
};

export const onExportExcelWithMetaData = async (
  exportSheetsData: ExcelSheetData[],
  exportFilename: string,
  metaData?: DownloadMetaData,
) => {
  if (!exportSheetsData) {
    return;
  }
  if (metaData) {
    exportSheetsData.unshift({
      sheetName: 'metadata',
      data: formatMetadata(metaData).map((item) => [{ value: item[0], bold: true }, { value: item[1] }]),
    });
  }
  await onExportMultiSheets(exportSheetsData, exportFilename, writeToFile);
};

export const onExportMultiSheets = async (
  sheetsData: ExcelSheetData[],
  exportFilename: string,
  callback: (file: unknown, filename: string) => void,
) => {
  if (!sheetsData || !sheetsData.length) {
    return;
  }
  const generatedName = `${exportFilename}.xlsx`;
  const xlsxPopulate = (await import('xlsx-populate/browser/xlsx-populate')).default;

  // TODO: (VENN-20577 / TYPES) There are types packages for xlsxPopulate that we could start using or fork.
  xlsxPopulate.fromBlankAsync().then((wb: unknown) => {
    const styleCache = new ExcelStyleCache(wb);

    // Prepend index to avoid sheet name conflict
    const prependIndex = sheetsData.length > 2;
    sheetsData.forEach((sheetData, index) => {
      addDataToSheet(wb, styleCache, index, sheetData.sheetName, sheetData.data, prependIndex);
    });
    // Write to file.
    callback(wb, generatedName);
  }, xlsxPopulateCatch);
};

/**
 * DEPRECATED: Please use onExportExcel instead
 * @param exportData Cell values
 * @param exportFilename Filename (without extension)
 * @param percentageColumn Columns which are treated as percentage (in excel format: 'A', 'B', 'AA', etc.)
 */
export const onExportData = async (
  exportData: ExcelCellValue[][],
  exportFilename?: string,
  percentageColumn?: string[],
) => {
  if (!exportData) {
    return;
  }

  const generatedName = `${exportFilename}.xlsx`;
  const workSheetName = exportFilename;
  const xlsxPopulate = (await import('xlsx-populate/browser/xlsx-populate')).default;

  xlsxPopulate.fromBlankAsync().then((wb: ExcelWorkbook) => {
    const sheet = wb.sheet(0).name(workSheetName);

    const cleanData = exportData.filter((item) => !!item);
    sheet.cell('A1').value(cleanData);
    sheet.row(1).style('bold', true);
    sheet.column(1).style('bold', true);

    const maxCol = sheet.usedRange().endCell().column().columnNumber();

    for (let col = 1; col <= maxCol; col++) {
      const rowWidths = cleanData.map((val) => (val[col - 1] ? val[col - 1]!.toString().length : 1));
      const maxColumnWidth = Math.max(...rowWidths);
      // 1.1 seems to be about average character width for the data we see
      sheet.column(col).width(maxColumnWidth * (col === 1 ? 1.5 : 1.1));
    }

    if (percentageColumn) {
      percentageColumn.map((col) => sheet.column(col).style('numberFormat', '0.00%'));
    }

    // Write to file.
    writeToFile(wb, generatedName);
  }, xlsxPopulateCatch);
};

function writeToFile(wb: ExcelWorkbook, name: string) {
  wb.outputAsync().then((blob: unknown) => {
    if (window.navigator && (window.navigator as AnyDuringEslintMigration).msSaveOrOpenBlob) {
      // If IE, you must uses a different method.
      (window.navigator as AnyDuringEslintMigration).msSaveOrOpenBlob(blob, name);
    } else {
      const url = window.URL?.createObjectURL?.(blob as AnyDuringEslintMigration);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href = url;
      a.download = name;
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
  });
}

/**
 * Converts an export event to the new format. Used for legacy data export.
 * @param exportEvent Old Export Event format
 */
export function toDownloadTrackingEvent(exportEvent: DataExportEvent): DownloadTrackingEvent {
  return {
    description: exportEvent.outputDescription,
    relativeToBenchmark: exportEvent.relativeToBenchmark,
    userUploaded: exportEvent.userUploaded,
    subjectType: exportEvent.objectType.toLowerCase() as 'portfolio' | 'factor' | 'investment',
    subjectId: exportEvent.subjectId,
  };
}

/**
 * Make the content of a Canvas downloadable as a PNG image
 * @param canvas HTML Canvas
 * @param fileName Exported file name, including extension
 */
export function downloadCanvasContentAsImage(canvas: HTMLCanvasElement, fileName: string) {
  const uri = convertCanvasToUri(canvas);
  const link = document.createElement('a');
  document.body.appendChild(link);
  link.download = fileName;
  link.href = uri;
  link.click();
  document.body.removeChild(link);
}

const convertCanvasToUri = (canvas: HTMLCanvasElement): string => {
  const img = canvas.toDataURL('image/png');
  return img.replace(/^data:image\/[^;]/, 'data:application/octet-stream');
};

/**
 * Copies the content of a Canvas to the clipboard, as an image.
 * @param canvas HTML Canvas containing the image to copy to clipboard
 */
export async function copyCanvasToClipboard(canvas: HTMLCanvasElement) {
  if (navigator?.clipboard?.write && window.ClipboardItem) {
    // Modern browsers support clipboard API

    const blob = await new Promise<Blob | null>((resolve) => canvas.toBlob(resolve));
    if (!blob) {
      return;
    }
    await navigator.clipboard.write([
      new window.ClipboardItem({
        [blob.type]: blob,
      }),
    ]);
  } else {
    // For legacy browser support; doesn't work when pasting into an iamge editor.
    const imgUrl = canvas.toDataURL('image/png');
    const canvasContainer = document.createElement('div');
    canvasContainer.setAttribute('contenteditable', 'true');

    const img = document.createElement('img');
    img.src = imgUrl;
    canvasContainer.appendChild(img);

    document.body.appendChild(canvasContainer);

    selectText(canvasContainer);
    document.execCommand('copy');
    window?.getSelection()?.removeAllRanges?.();
    document.body.removeChild(canvasContainer);
  }
}

function selectText(element: HTMLElement) {
  if (window.getSelection) {
    const selection = window.getSelection();
    if (selection) {
      const range = document.createRange();
      range.selectNodeContents(element);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }
}

// TODO(VENN-23056): refactor to use portal and client-side rendering rather than super brittle client-side usage of server-side rendering utils
export function renderStaticMarkupWithTheme(element: JSX.Element, theme: Theme) {
  return renderToStaticMarkup(<ThemeProvider theme={theme}>{element}</ThemeProvider>);
}

/** CSS styles do not work for svg elements when using html2canvas. This is a workaround to make sure fill is correctly applied to all svg elements in this case https://github.com/niklasvh/html2canvas/issues/1011 */
export function applyStyleToSvg(container: Element | Document) {
  for (const svg of Array.from(container.getElementsByTagName('svg'))) {
    for (const element of Array.from(svg.getElementsByTagName('*')) as SVGElement[]) {
      const computedStyle = getComputedStyle(element);
      element.style.setProperty('fill', computedStyle.getPropertyValue('fill'));
    }
  }
}

const formatMetadata = (metaData: DownloadMetaData): MetaData[] => {
  const { extra, ...generalMetaData } = metaData;
  const formattedGeneralMetaData = formatGeneralDownloadMetaData(generalMetaData);
  const formattedExtra = extra ? extra.map((item) => formatDownloadMessage(item.label, item.value)) : [];
  return [...formattedGeneralMetaData, ...formattedExtra];
};

export const getImagesFooter = (metaData?: DownloadMetaData): JSX.Element | undefined => {
  if (!metaData) {
    return undefined;
  }
  const items = formatMetadata(metaData).map((message) => {
    return {
      value: message[1],
      label: getRawMetadataLabel(message[0]),
    };
  });
  return <DownloadFooter items={items} />;
};
