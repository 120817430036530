import React from 'react';
import { EllipsisTooltipSpan, ItemIcon, ItemType, ItemTypePill, PROXY_FAQ_HREF, TooltipPosition } from 'venn-ui-kit';
import type { SearchFund } from 'venn-api';
import { Dates, FundUtils, Numbers } from 'venn-utils';
import LineChart from './LineChart';
import type { DataPoint } from '../../types';
import { InfoIcon, StyledProxySpan, StyledTooltip, Table } from '../../styles';
import { partition } from 'lodash';

interface PossibleProxiesTableProps {
  results: ProxyResult[];
  onSelect: (investment: SearchFund) => void;
}

interface ProxyResult {
  proxy: SearchFund;
  proxiedReturns: DataPoint[];
  proxiedStart?: number;
  proxiedEnd?: number;
  extrapolatedStart?: number;
  correlation: number;
  percentMatch?: number;
}

const getYear = (timestamp: number) => Dates.parse(timestamp).year();

const POTENTIAL_PROXY_INFO = 'Investments with similar returns to those provided that can be used to back-fill data.';

const PossibleProxiesTable: React.FC<React.PropsWithChildren<PossibleProxiesTableProps>> = ({ results, onSelect }) => {
  return (
    <Table>
      <thead>
        <tr>
          <th colSpan={3}>
            <StyledTooltip content={POTENTIAL_PROXY_INFO} position={TooltipPosition.Top}>
              <a target="_blank" rel="noopener noreferrer" href={PROXY_FAQ_HREF}>
                <InfoIcon type="info-circle" prefix="fas" />
              </a>
            </StyledTooltip>
            <StyledProxySpan>Potential Proxy</StyledProxySpan>
          </th>
          <th>{results?.[0]?.percentMatch ? 'Match' : 'Correlation'}</th>
          <th>Cumulative Return</th>
          <th>Type</th>
          <th>Date Range</th>
        </tr>
      </thead>
      <tbody>
        {results.map((result) => {
          const extrapolatedStart = result.extrapolatedStart ?? Infinity;
          const proxiedEnd = result.proxiedEnd ?? 0;
          // splitting the proxied returns to distinguish between investment's and proxy ranges
          const [proxy, investmentReturns] = partition(
            result.proxiedReturns,
            (point) => point.x >= extrapolatedStart || point.x <= proxiedEnd,
          );
          const [extrapolatedReturns, realProxyReturns] = partition(proxy, (point) => point.x >= extrapolatedStart);
          const proxyAssetType = result.proxy.assetType;
          const proxyItemType = proxyAssetType === 'BENCHMARK' ? ItemType.Benchmark : ItemType.Investment;
          return (
            <tr key={result.proxy.id} onClick={() => onSelect(result.proxy)} className="qa-possible-proxy">
              <td>
                <ItemTypePill item={proxyItemType} />
              </td>
              <td>
                <ItemIcon
                  item={proxyItemType}
                  isUploaded={result.proxy.userUploaded}
                  dataSource={result.proxy.investmentSourceName}
                  investmentSource={result.proxy.investmentSource}
                />
              </td>
              <td>
                <EllipsisTooltipSpan maxWidth={240}>{result.proxy.name}</EllipsisTooltipSpan>
              </td>
              <td>
                {result.percentMatch
                  ? Numbers.formatPercentMatch(result.percentMatch)
                  : Numbers.formatCorrelation(result.correlation)}
              </td>
              <td>
                <LineChart
                  investment={investmentReturns}
                  proxy={realProxyReturns}
                  extrapolation={extrapolatedReturns}
                />
              </td>
              <td>
                <EllipsisTooltipSpan maxWidth={90} position={TooltipPosition.Left}>
                  {FundUtils.getAssetTypeName(result.proxy.assetType)}
                </EllipsisTooltipSpan>
              </td>
              <td>
                {getYear(result.proxy.startRange)} - {getYear(result.proxy.endRange)}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default React.memo(PossibleProxiesTable);
