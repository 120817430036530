import { isEmpty, isNil } from 'lodash';
import React, { Suspense } from 'react';
import styled from 'styled-components';
import { type CashFlowSetting } from 'venn-api';
import {
  CashflowActiveSettingInfo,
  Modal,
  ModalContent,
  ModalDescription,
  ModalFooter,
  ModalHeader,
  ModalSize,
  ModalSubhead,
} from 'venn-components';
import { ItemIcon, ItemType, Loading, PRIVATE_ASSET_CASH_FLOW_HELP_HREF } from 'venn-ui-kit';
import { useFundsUsingSetting } from '../../utils/cashflow-settings/queries';
import { assertNotNil } from 'venn-utils';

type CashflowSettingsFundsModalProps = Readonly<{
  setting: CashFlowSetting;
  onClose: () => void;
}>;

const Funds = ({ settingId }: { settingId: string }) => {
  const { data } = useFundsUsingSetting(settingId);

  if (isNil(data) || isEmpty(data)) {
    return <EmptyMessage>No funds found</EmptyMessage>;
  }

  return (
    <FundsList>
      {data.map((fund) => (
        <FundItem key={fund.id} data-testid="fund-item">
          <ItemIcon item={ItemType.PrivateInvestment} />
          <div>{fund.name}</div>
        </FundItem>
      ))}
    </FundsList>
  );
};

const FundsList = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  gap: 4px;
`;

const FundItem = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const EmptyMessage = styled.div`
  font-style: italic;
`;

const CashflowSettingFundsModal = ({ setting, onClose }: CashflowSettingsFundsModalProps) => {
  return (
    <Modal size={ModalSize.XXLarge} closeOnEsc onClose={onClose} testId="cashflow-settings-funds-modal">
      <ModalHeader>Funds using this setting</ModalHeader>
      <ModalSubhead>{setting.name}</ModalSubhead>
      <ModalDescription>
        Have more questions? Visit the{' '}
        <a target="_blank" rel="noopener noreferrer" href={PRIVATE_ASSET_CASH_FLOW_HELP_HREF}>
          FAQ
        </a>
        .
      </ModalDescription>

      <ModalContent>
        <Layout>
          <Main>
            <Suspense fallback={<Loading />}>
              <Funds settingId={assertNotNil(setting.settingId)} />
            </Suspense>
          </Main>
          <CashflowActiveSettingInfo setting={setting} hideHeader />
        </Layout>
      </ModalContent>
      <ModalFooter primaryLabel="Close" onPrimaryClick={onClose} />
    </Modal>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: row;
`;

const Main = styled.div`
  flex-grow: 1;
`;

export default CashflowSettingFundsModal;
