import React, { PureComponent } from 'react';
import type { Theme } from 'venn-ui-kit';
import type { AnalysisSubject, AnalysisSubjectSecondaryLabel } from 'venn-utils';
import type { BackgroundClickEvent } from './Background';
import Background from './Background';
import type { ViewPort, Scale } from '../types';
import { getChartScale } from '../scale';
import { RowTooltip, InsignificantFactorMessage } from '../../../factor-chart';
import type { FactorSummaryChartRow, FactorSummaryRow } from '../../factorSummaryTypes';
import { WIDE_VALUE_COLUMN_OFFSET, LEGEND_HEIGHT, LEGEND_LINE_HEIGHT, LABEL_MAX_LINES } from '../constants';
import ChartBorders from './ChartBorders';
import ColumnLabels from '../../../factor-chart/column-labels/ColumnLabels';
import CrossHatchPattern from './CrossHatchPattern';
import VerticalLinesPattern from './VerticalLinesPattern';
import Grid from './grid/Grid';
import OverprintGrid from './grid/OverprintGrid';
import type { ToolTipContent } from '../../../factor-chart/tooltips/types';
import { withTheme } from 'styled-components';

export interface ChartRowComponentProps {
  className?: string;
  errorBars?: boolean;
  factors: FactorSummaryChartRow[];
  percentage: boolean;
  scale: Scale;
  viewPort: ViewPort;
  categoryActive: boolean;
}

export interface ChartRowProviderData {
  preparedFactors: FactorSummaryChartRow[];
  RowsComponent: React.ComponentType<React.PropsWithChildren<ChartRowComponentProps>>;
}

export interface ChartRowProviderProps {
  factors: FactorSummaryRow[];
  children: (data: ChartRowProviderData) => JSX.Element;
}

export interface SummaryChartProps {
  activeFactorId?: number | undefined;
  baselineDataSetName?: string;
  breakdownAvailable?: boolean;
  comparisonDataSetName?: string;
  categoryDataSetName?: string;
  ChartRowProvider: React.ComponentType<React.PropsWithChildren<ChartRowProviderProps>>;
  defaultExtreme?: number;
  errorBars?: boolean;
  factors: FactorSummaryRow[];
  mainDataSetName: string;
  onClick: (event: BackgroundClickEvent) => void;
  onDismissPopup: () => void; // TODO arguments + redesign popup API
  percentage: boolean;
  updateActiveFactorId?: (activeFactorId: number) => void;
  viewPort: ViewPort;
  categoryActive: boolean;
  relative?: boolean;
  isFund?: boolean;
  transform?: number;
  filterCount?: number;
  showPrintStyle?: boolean;
  secondaryLabel?: AnalysisSubjectSecondaryLabel;
  analysisSubject: AnalysisSubject;
  theme: Theme;
}

export interface SummaryChartState {
  tooltipX?: number;
  tooltipY?: number;
  content?: ToolTipContent;
}

class SummaryChart extends PureComponent<SummaryChartProps, SummaryChartState> {
  static defaultProps = {
    defaultExtreme: 1.0,
  };

  constructor(props: SummaryChartProps) {
    super(props);

    this.state = {
      tooltipX: undefined,
      tooltipY: undefined,
      content: undefined,
    };
  }

  handleRowEnter = (tooltipX: number, tooltipY: number, content: ToolTipContent) => {
    this.setState({
      tooltipX,
      tooltipY,
      content,
    });
  };

  resetTooltip = () => {
    this.setState({
      tooltipX: undefined,
      tooltipY: undefined,
    });
  };

  render() {
    const {
      activeFactorId,
      breakdownAvailable,
      baselineDataSetName,
      comparisonDataSetName,
      categoryDataSetName,
      ChartRowProvider,
      defaultExtreme,
      errorBars,
      factors,
      mainDataSetName,
      onClick,
      onDismissPopup,
      percentage,
      viewPort,
      updateActiveFactorId,
      secondaryLabel,
      relative,
      categoryActive,
      isFund,
      transform,
      filterCount,
      showPrintStyle,
      analysisSubject,
      theme: {
        Colors,
        Schemes: { FactorColors },
      },
    } = this.props;

    const messageStartPosition = viewPort.rowHeight * factors.length + viewPort.rowHeight + 4;

    return (
      <ChartRowProvider factors={factors}>
        {({ preparedFactors, RowsComponent }) => {
          const scale = getChartScale(preparedFactors, defaultExtreme ?? 0);
          return (
            <g className="qa-chart-container">
              <defs>
                <CrossHatchPattern id="summary-diagonal-hatch" color={FactorColors.mainColor} />
                <CrossHatchPattern id="summary-negative-hatch" color={FactorColors.comparisonColor} />
                <CrossHatchPattern id="summary-legend-hatch" color={Colors.Black} />
                <VerticalLinesPattern id="summary-vertical-lines" color={FactorColors.mainColor} />
                <VerticalLinesPattern id="summary-negative-vertical-lines" color={FactorColors.comparisonColor} />
                <VerticalLinesPattern id="summary-legend-vertical-lines" color={Colors.Black} />
              </defs>
              <Background
                activeFactorId={activeFactorId}
                baselineDataSetName={baselineDataSetName}
                breakdownAvailable={breakdownAvailable}
                comparisonDataSetName={comparisonDataSetName}
                categoryDataSetName={categoryDataSetName}
                factors={preparedFactors}
                handleRowEnter={this.handleRowEnter}
                mainDataSetName={mainDataSetName}
                onClick={onClick}
                onDismissPopup={onDismissPopup}
                resetTooltip={this.resetTooltip}
                updateActiveFactorId={updateActiveFactorId}
                viewPort={viewPort}
                relative={relative}
                transform={transform}
                isFund={isFund}
                secondaryLabel={secondaryLabel}
                analysisSubject={analysisSubject}
              />
              <Grid factors={preparedFactors} percentage={percentage} scale={scale} viewPort={viewPort} />
              <OverprintGrid factors={preparedFactors} percentage={percentage} scale={scale} viewPort={viewPort} />
              <RowsComponent
                errorBars={errorBars}
                factors={preparedFactors}
                percentage={percentage}
                scale={scale}
                viewPort={viewPort}
                categoryActive={categoryActive}
              />
              <ChartBorders width={viewPort.width} height={viewPort.rowHeight * factors.length} />
              {!!mainDataSetName && (
                <ColumnLabels
                  viewPort={viewPort}
                  mainDataSetName={mainDataSetName}
                  baselineDataSetName={baselineDataSetName}
                  comparisonDataSetName={comparisonDataSetName}
                  categoryDataSetName={categoryDataSetName}
                  relative={relative}
                  categoryActive={categoryActive}
                  columnWidth={WIDE_VALUE_COLUMN_OFFSET}
                  labelHeight={LEGEND_HEIGHT}
                  lineHeight={LEGEND_LINE_HEIGHT}
                  maxLines={LABEL_MAX_LINES}
                  isFund={isFund}
                  secondaryLabel={secondaryLabel}
                  analysisSubject={analysisSubject}
                />
              )}
              <RowTooltip
                x={this.state.tooltipX ?? 0}
                y={this.state.tooltipY ?? 0}
                percentage={percentage}
                content={this.state.content}
              />
              {filterCount && (
                <InsignificantFactorMessage
                  value={filterCount}
                  y={messageStartPosition}
                  width={viewPort.width}
                  showPrintStyle={showPrintStyle}
                />
              )}
            </g>
          );
        }}
      </ChartRowProvider>
    );
  }
}

export default withTheme(SummaryChart);
