import type { FC } from 'react';
import React from 'react';
import NumericSpan from '../numeric-span/NumericSpan';
import { isNil } from 'lodash';

export interface PercentageSpanProps {
  value?: number;
  precision?: number;
  className?: string;
}

const PercentageSpan: FC<React.PropsWithChildren<PercentageSpanProps>> = ({
  value,
  precision = 2,
  className,
  children,
}) => {
  let displayValue = !isNil(value) ? value : (children as number);
  if (!isNil(displayValue)) {
    displayValue *= 100;
  }
  return (
    <NumericSpan suffix="%" precision={precision} className={className}>
      {displayValue ?? null}
    </NumericSpan>
  );
};

export default PercentageSpan;
