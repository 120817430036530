import React from 'react';
import styled from 'styled-components';
import { GetColor } from '../../style/color';

interface InitialIconCircleProps {
  type: string;
  backgroundColor?: string;
  children?: React.ReactNode;
}

const IconCircle = (props: InitialIconCircleProps) => {
  const { backgroundColor, children, type } = props;
  return (
    <IconContainer className="fa-stack fa-4x">
      <StyledBackgroundIcon backgroundColor={backgroundColor} className="fa fa-circle fa-stack-2x" />
      <StyledIcon className={`fa fa-${type} fa-stack-1x`} />
      {children}
    </IconContainer>
  );
};

export default IconCircle;

const StyledBackgroundIcon = styled.i<{ backgroundColor?: string }>`
  color: ${({ backgroundColor }) => backgroundColor || GetColor.HintGrey};
`;

const StyledIcon = styled.i<{ iconColor?: string }>`
  color: ${({ iconColor }) => iconColor || GetColor.White};
`;

const IconContainer = styled.span`
  &.fa-stack {
    height: 100px;
    min-width: 100px;
    max-width: 100px;
  }
`;
