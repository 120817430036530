import { getAppTitle, Icon, PrivateInvestmentIcon, PrivatePortfolioIcon, type Theme } from 'venn-ui-kit';
import { LibraryFilterSectionKey, LibraryItemType, QuickFilter } from 'venn-utils';
import React from 'react';
import styled from 'styled-components';
import compact from 'lodash/compact';
import type { LibraryFilterSection } from './components';

export const QUICK_FILTER_LABELS: { [k in QuickFilter]: string } = {
  [QuickFilter.RECENTLY_ANALYZED]: 'Recently Analyzed by Me',
  [QuickFilter.UPLOADED_BY_ANYONE]: 'Uploaded by my Team',
  [QuickFilter.UPLOADED_BY_ME]: 'Uploaded by Me',
  [QuickFilter.INTEGRATIONS]: 'Integrations',
  [QuickFilter.REQUIRES_ATTENTION]: 'Requires Attention',
};

export const LIBRARY_ITEM_TYPE_LABELS: { [k in LibraryItemType]: string } = {
  [LibraryItemType.ALL]: 'All',
  [LibraryItemType.PORTFOLIO]: 'Portfolio',
  [LibraryItemType.PORTFOLIO_INTEGRATION]: 'Integration',
  [LibraryItemType.PRO_FORMA]: 'Pro Forma',
  [LibraryItemType.COMPOSITE_BENCHMARK]: 'Composite Benchmark',
  [LibraryItemType.INVESTMENT]: 'Investment',
  [LibraryItemType.UPLOAD]: 'Upload',
  [LibraryItemType.INVESTMENT_INTEGRATION]: 'Integration',
  [LibraryItemType.MASTER_PORTFOLIO_HOLDING]: 'Master Portfolio Holding',
  [LibraryItemType.VENN_PROVIDED]: `${getAppTitle()} Provided`,
  [LibraryItemType.NONE]: '',
  [LibraryItemType.TAGS]: 'Tags',
};

export const getPrivatesFilters = (
  theme: Theme,
): [LibraryFilterSection<string>, LibraryFilterSection<LibraryItemType>] => {
  const { Colors } = theme;

  const quickFilters: LibraryFilterSection<string> = {
    key: LibraryFilterSectionKey.FILTERS,
    title: 'Quick Filters',
    checkbox: true,
    items: [
      {
        label: QUICK_FILTER_LABELS[QuickFilter.UPLOADED_BY_ANYONE],
        value: QuickFilter.UPLOADED_BY_ANYONE,
        icon: <StyledIcon type="upload" color={Colors.HighlightDark} />,
      },
      {
        label: QUICK_FILTER_LABELS[QuickFilter.UPLOADED_BY_ME],
        value: QuickFilter.UPLOADED_BY_ME,
        icon: <StyledIcon type="upload" color={Colors.HighlightDark} />,
      },
    ],
  };

  const itemTypes: LibraryFilterSection<LibraryItemType> = {
    key: LibraryFilterSectionKey.ITEM_TYPE,
    title: 'Item Type',
    items: compact([
      {
        label: LIBRARY_ITEM_TYPE_LABELS[LibraryItemType.ALL],
        value: LibraryItemType.ALL,
        bold: true,
      },
      {
        label: LIBRARY_ITEM_TYPE_LABELS[LibraryItemType.PORTFOLIO],
        value: LibraryItemType.PORTFOLIO,
        icon: <StyledPrivatePortfolioIcon />,
        subItemsArrowHidden: true,
      },
      {
        label: LIBRARY_ITEM_TYPE_LABELS[LibraryItemType.INVESTMENT],
        value: LibraryItemType.INVESTMENT,
        icon: <StyledPrivateInvestmentIcon />,
        subItemsArrowHidden: true,
        subItems: [
          {
            label: LIBRARY_ITEM_TYPE_LABELS[LibraryItemType.UPLOAD],
            value: LibraryItemType.UPLOAD,
            icon: <StyledUploadIcon type="upload" color={Colors.Gold} />,
          },
          {
            label: LIBRARY_ITEM_TYPE_LABELS[LibraryItemType.VENN_PROVIDED],
            value: LibraryItemType.VENN_PROVIDED,
            icon: <StyledPrivateInvestmentIcon />,
          },
        ],
      },
    ]),
  };

  return [quickFilters, itemTypes];
};

export const getFilters = (
  tags: string[],
  theme: Theme,
): [LibraryFilterSection<string>, LibraryFilterSection<LibraryItemType>, LibraryFilterSection<string>] => {
  const { Colors } = theme;
  const { DEPRECATED_DataLineColor } = Colors;

  const quickFilters: LibraryFilterSection<string> = {
    key: LibraryFilterSectionKey.FILTERS,
    title: 'Quick Filters',
    checkbox: true,
    items: [
      {
        label: QUICK_FILTER_LABELS[QuickFilter.RECENTLY_ANALYZED],
        value: QuickFilter.RECENTLY_ANALYZED,
        icon: <StyledIcon type="clock" color={Colors.Black} />,
        advancedOnly: true,
      },
      {
        label: QUICK_FILTER_LABELS[QuickFilter.UPLOADED_BY_ANYONE],
        value: QuickFilter.UPLOADED_BY_ANYONE,
        icon: <StyledIcon type="upload" color={Colors.HighlightDark} />,
      },
      {
        label: QUICK_FILTER_LABELS[QuickFilter.UPLOADED_BY_ME],
        value: QuickFilter.UPLOADED_BY_ME,
        icon: <StyledIcon type="upload" color={Colors.HighlightDark} />,
      },
      {
        label: QUICK_FILTER_LABELS[QuickFilter.INTEGRATIONS],
        value: QuickFilter.INTEGRATIONS,
        icon: <StyledIcon type="exchange" color={Colors.Primary.Dark} />,
      },
      {
        label: QUICK_FILTER_LABELS[QuickFilter.REQUIRES_ATTENTION],
        value: QuickFilter.REQUIRES_ATTENTION,
        icon: <StyledIcon type="exclamation-triangle" color={Colors.Error} />,
        advancedOnly: true,
      },
    ],
  };

  const itemTypes: LibraryFilterSection<LibraryItemType> = {
    key: LibraryFilterSectionKey.ITEM_TYPE,
    title: 'Item Type',
    items: compact([
      {
        label: LIBRARY_ITEM_TYPE_LABELS[LibraryItemType.ALL],
        value: LibraryItemType.ALL,
        bold: true,
      },
      {
        label: LIBRARY_ITEM_TYPE_LABELS[LibraryItemType.PORTFOLIO],
        value: LibraryItemType.PORTFOLIO,
        icon: <StyledIcon type="th" color={DEPRECATED_DataLineColor.PaleBlue} />,
        subItemsArrowHidden: true,
        subItems: [
          {
            label: LIBRARY_ITEM_TYPE_LABELS[LibraryItemType.PORTFOLIO_INTEGRATION],
            value: LibraryItemType.PORTFOLIO_INTEGRATION,
            icon: <StyledIcon type="exchange" color={DEPRECATED_DataLineColor.PaleBlue} />,
            className: 'qa-portfolio-integration',
          },
          {
            label: LIBRARY_ITEM_TYPE_LABELS[LibraryItemType.PRO_FORMA],
            value: LibraryItemType.PRO_FORMA,
            icon: <StyledIcon type="th" color={DEPRECATED_DataLineColor.PaleBlue} />,
          },
        ],
      },
      {
        label: LIBRARY_ITEM_TYPE_LABELS[LibraryItemType.COMPOSITE_BENCHMARK],
        value: LibraryItemType.COMPOSITE_BENCHMARK,
        icon: <StyledIcon type="layer-group" color={DEPRECATED_DataLineColor.Pink} />,
      },
      {
        label: LIBRARY_ITEM_TYPE_LABELS[LibraryItemType.INVESTMENT],
        value: LibraryItemType.INVESTMENT,
        icon: <StyledIcon type="square" color={DEPRECATED_DataLineColor.Gold} />,
        subItemsArrowHidden: true,
        subItems: [
          {
            label: LIBRARY_ITEM_TYPE_LABELS[LibraryItemType.UPLOAD],
            value: LibraryItemType.UPLOAD,
            icon: <StyledIcon type="upload" color={DEPRECATED_DataLineColor.Gold} />,
          },
          {
            label: LIBRARY_ITEM_TYPE_LABELS[LibraryItemType.INVESTMENT_INTEGRATION],
            value: LibraryItemType.INVESTMENT_INTEGRATION,
            icon: <StyledIcon type="exchange" color={DEPRECATED_DataLineColor.Gold} />,
            className: 'qa-investment-integration',
          },
          {
            label: LIBRARY_ITEM_TYPE_LABELS[LibraryItemType.MASTER_PORTFOLIO_HOLDING],
            value: LibraryItemType.MASTER_PORTFOLIO_HOLDING,
            icon: <StyledIcon type="square" color={DEPRECATED_DataLineColor.Gold} />,
          },
          {
            label: LIBRARY_ITEM_TYPE_LABELS[LibraryItemType.VENN_PROVIDED],
            value: LibraryItemType.VENN_PROVIDED,
            icon: <StyledIcon type="square" color={DEPRECATED_DataLineColor.Gold} />,
          },
        ],
      },
    ]),
  };

  let tagSectionTooltip;
  if (tags.length === 0) {
    tagSectionTooltip = 'You have no tags. Hover over an item in the library and click the tag icon to start tagging!';
  }

  const tagFilters = {
    key: LibraryFilterSectionKey.TAGS,
    title: 'Tags',
    checkbox: true,
    collapsible: true,
    advancedOnly: true,
    disabled: tags.length === 0,
    tooltip: tagSectionTooltip,
    items: tags.map((tag) => ({
      label: tag,
      value: tag,
      icon: <StyledIcon type="tag" color={Colors.Primary.Dark} />,
    })),
  };

  return [quickFilters, itemTypes, tagFilters];
};

const StyledIcon = styled(Icon)<{ color: string }>`
  color: ${(props) => props.color};
  margin-right: 5px;
`;

const StyledUploadIcon = styled(Icon)<{ color: string }>`
  color: ${(props) => props.color};
  margin-right: 5px;
  font-size: 12px;
`;

const StyledPrivatePortfolioIcon = styled(PrivatePortfolioIcon)`
  margin-right: 5px;
`;

const StyledPrivateInvestmentIcon = styled(PrivateInvestmentIcon)`
  margin-right: 5px;
`;
