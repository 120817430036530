import React from 'react';
import { StyledInfoTooltip, StyledInfoLink, StyledInfoIcon } from './StyledComponents';
import { CURRENCY_CONVERSION_ARTICLE_HREF } from 'venn-ui-kit';

interface CurrencyHeaderProps {
  label: string;
}

const CurrencyHeader = ({ label }: CurrencyHeaderProps) => (
  <>
    {label}
    <StyledInfoTooltip content="Returns are converted to your organization’s base currency for analysis">
      <StyledInfoLink
        target="_blank"
        rel="noopener noreferrer"
        href={CURRENCY_CONVERSION_ARTICLE_HREF}
        onClick={
          // Hack to communicate to BasicTable not to
          // sort when this is clicked
          (e: React.MouseEvent<HTMLAnchorElement> & { noSort: boolean }) => {
            e.persist();
            e.noSort = true;
          }
        }
      >
        <StyledInfoIcon type="info-circle" prefix="fas" />
      </StyledInfoLink>
    </StyledInfoTooltip>
  </>
);

export default CurrencyHeader;
