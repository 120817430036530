import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { deleteAnalysisView } from 'venn-api';
import type { AnalysisViewSearchResultWithUIState } from 'venn-utils';
import { useModal, analyticsService, logExceptionIntoSentry } from 'venn-utils';
import { ButtonIcon, GetColor, LoadingSize, Spinner, TooltipPosition } from 'venn-ui-kit';
import { ConfirmationModal } from 'venn-components';

import { PAGE_SIZE, BUTTON_SIZE } from './constants';

interface BulkDelete {
  items: AnalysisViewSearchResultWithUIState[];
  fetchResults: () => void;
  onPageChange: (pageNumber: number) => void;
  currentPage: number;
  totalCount: number;
  userId?: number;
}

const BulkDelete = ({ items, fetchResults, onPageChange, currentPage, totalCount, userId }: BulkDelete) => {
  const [isConfirmationModalOpen, openConfirmationModal, closeConfirmationModal] = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const pagesCount = Math.ceil(totalCount / PAGE_SIZE);
  const nonUserCreatedItems = items.some((item) => item.owner.id !== userId);

  const handleOnProceed = async () => {
    if (nonUserCreatedItems) {
      return;
    }

    setIsLoading(true);
    try {
      analyticsService.ctaClicked({
        destination: undefined,
        filled: false,
        locationOnPage: 'Saved views table',
        purpose: 'Delete saved view(s)',
        text: 'bulk delete',
        type: 'button',
      });

      await Promise.all(
        items.map(async (item: AnalysisViewSearchResultWithUIState) => {
          try {
            await deleteAnalysisView(item?.id);
          } catch (e) {
            logExceptionIntoSentry(e);
          }
        }),
      );
      setIsLoading(false);
      closeConfirmationModal();
      const deletedAllResultsFromLastPage = pagesCount === currentPage && totalCount % PAGE_SIZE === items.length;
      const pageNumber = currentPage > 1 && deletedAllResultsFromLastPage ? currentPage - 1 : currentPage;

      if (pageNumber !== currentPage) {
        onPageChange(pageNumber);
        return;
      }

      fetchResults();
    } catch (e) {
      setIsLoading(false);
      closeConfirmationModal();
      logExceptionIntoSentry(e);
    }
  };

  const exportMessage = ' or any related export history';
  const singleMessage = `You will no longer be able to access this saved view${exportMessage}.`;
  const multiMessage = `You will no longer be able to access these saved views${exportMessage}.`;

  const singleView = items.length === 1;

  return (
    <>
      <StyledButtonIcon
        tooltip={nonUserCreatedItems ? 'Cannot delete non user created items' : undefined}
        tooltipPosition={TooltipPosition.Bottom}
        onClick={openConfirmationModal}
        iconType="trash"
        text={`Delete (${items.length})`}
        className="qa-bulk-delete"
        size={BUTTON_SIZE}
        disabled={nonUserCreatedItems}
        nonUserCreatedItems={nonUserCreatedItems}
      />
      {isConfirmationModalOpen ? (
        <ConfirmationModal
          header={`Delete ${items.length} ${singleView ? 'view' : 'views'}?`}
          subhead={`${
            singleView ? 'This item' : 'These items'
          } will be deleted permanently for everyone in your organization. You cannot undo this action.`}
          text={singleView ? singleMessage : multiMessage}
          onCancel={closeConfirmationModal}
          onProceed={handleOnProceed}
          rightChildren={isLoading && <Spinner size={LoadingSize.small} />}
          proceedDisabled={isLoading}
          destructive
        />
      ) : null}
    </>
  );
};

const StyledButtonIcon = styled(ButtonIcon)<{ nonUserCreatedItems: boolean }>`
  display: flex;
  margin-right: 6px;
  ${({ nonUserCreatedItems }) =>
    !nonUserCreatedItems &&
    css`
      &.button-icon-wrapper {
        color: ${GetColor.Error};
      }
    `}
`;

export default BulkDelete;
