import type { Statistic } from './types/statistic';
import { HISTORICAL_RISK_STATISTICS_HREF } from 'venn-ui-kit';

export const statistics: Statistic[] = [
  {
    name: 'Beta to Benchmark',
    getter: (data) => data.betaToBenchmark,
    learnMoreUrl: HISTORICAL_RISK_STATISTICS_HREF,
    description:
      'The investment’s or portfolio’s sensitivity to its benchmark. For daily returns, beta is calculated using the rolling 5-day average returns to minimize the impact of market asynchronicity.',
  },
  {
    name: 'Correlation',
    getter: (data) => data.correlation,
    learnMoreUrl: HISTORICAL_RISK_STATISTICS_HREF,
    description:
      'The correlation between the given return stream and the specified benchmark. For daily returns, correlation is calculated using the rolling 5-day average returns to minimize the impact of market asynchronicity.',
  },
  {
    name: 'Downside Correlation',
    getter: (data) => data.downsideCorrelation,
    learnMoreUrl: HISTORICAL_RISK_STATISTICS_HREF,
    description:
      'The correlation between the given return stream and the benchmark for all periods where both returns are negative. For daily returns, downside correlation is calculated using the rolling 5-day average returns to minimize the impact of market asynchronicity.',
  },
  {
    name: 'Downside Capture',
    percentage: true,
    getter: (data) => data.downsideCaptureRatio,
    learnMoreUrl: HISTORICAL_RISK_STATISTICS_HREF,
    description:
      'The ratio of the average returns of the investment to the average returns of the benchmark when benchmark returns were negative.',
  },
  {
    name: 'Upside Capture',
    percentage: true,
    getter: (data) => data.upsideCaptureRatio,
    learnMoreUrl: HISTORICAL_RISK_STATISTICS_HREF,
    description:
      'The ratio of the average returns of the investment to the average returns of the benchmark when benchmark returns were non-negative.',
  },
  {
    name: 'Average Down Month',
    percentage: true,
    getter: (data) => data.averageDownMonth,
    learnMoreUrl: HISTORICAL_RISK_STATISTICS_HREF,
    description: 'It is calculated by taking the average return of all the negative monthly return data points.',
  },
  {
    name: 'Average Up Month',
    percentage: true,
    getter: (data) => data.averageUpMonth,
    learnMoreUrl: HISTORICAL_RISK_STATISTICS_HREF,
    description: 'It is calculated by taking the average return of all the positive monthly return data points.',
  },
  {
    name: 'Downside Volatility',
    percentage: true,
    getter: (data) => data.downsideVolatility,
    learnMoreUrl: HISTORICAL_RISK_STATISTICS_HREF,
    description:
      'Downside volatility is computed as the square root of downside semi-variance of the returns, which is the sum of the squares of the negative values of returns divided by their count.',
  },
  {
    name: 'Upside Volatility',
    percentage: true,
    getter: (data) => data.upsideVolatility,
    learnMoreUrl: HISTORICAL_RISK_STATISTICS_HREF,
    description:
      'Upside volatility is computed as the square root of upside semi-variance of the returns, which is the sum of the squares of the positive values of returns divided by their count.',
  },
  {
    name: 'Batting Average',
    percentage: true,
    getter: (data) => data.battingAverage,
    learnMoreUrl: HISTORICAL_RISK_STATISTICS_HREF,
    description:
      'If Relative to Benchmark is toggled off, it is the ratio of total periods that an investment posts non-negative returns. If Relative to Benchmark is toggled on, it is the ratio of total periods that an investment beats or matches its index.',
  },
  {
    name: 'CVaR (5%)',
    percentage: true,
    getter: (data) => data.conditionalValueAtRisk95,
    learnMoreUrl: HISTORICAL_RISK_STATISTICS_HREF,
    description:
      'Conditional VaR is the weighted average measure compared to the “point estimation” of VaR, which could more accurately measure the tail risk.',
  },
  {
    name: 'VaR (95%)',
    percentage: true,
    getter: (data) => data.valueAtRisk95,
    learnMoreUrl: HISTORICAL_RISK_STATISTICS_HREF,
    description:
      'The “Value at Risk” of the investment or portfolio is the estimated maximum amount the investment or portfolio might lose over a one month period based on historical returns at a 95% confidence level.',
  },
  {
    name: 'VaR (97.5%)',
    percentage: true,
    getter: (data) => data.valueAtRisk975,
    learnMoreUrl: HISTORICAL_RISK_STATISTICS_HREF,
    description:
      'The “Value at Risk” of the investment or portfolio is the estimated maximum amount the investment or portfolio might lose over a one month period based on historical returns at a 97.5% confidence level.',
  },
  {
    name: 'VaR (99%)',
    percentage: true,
    getter: (data) => data.valueAtRisk99,
    learnMoreUrl: HISTORICAL_RISK_STATISTICS_HREF,
    description:
      'The “Value at Risk” of the investment or portfolio is the estimated maximum amount the investment or portfolio might lose over a one month period based on historical returns at a 99% confidence level.',
  },
  {
    name: 'Skewness',
    getter: (data) => data.skewness,
    learnMoreUrl: HISTORICAL_RISK_STATISTICS_HREF,
    description:
      'The degree of asymmetry in the distribution of returns for an investment or portfolio. For example, if the distribution had more values in the right side of the distribution (compared to a normal distribution), the investment or portfolio will have a negative skewness.',
  },
  {
    name: 'Kurtosis',
    getter: (data) => data.kurtosis,
    learnMoreUrl: HISTORICAL_RISK_STATISTICS_HREF,
    description:
      'The degree of extreme values in the distribution of returns for an investment or portfolio. For example, if the distribution had more values in the tails of the distribution (relative to a normal distribution), it will have a positive kurtosis.',
  },
  {
    name: 'Sortino Ratio',
    getter: (data) => data.sortino,
    learnMoreUrl: HISTORICAL_RISK_STATISTICS_HREF,
    description:
      'A risk-adjusted performance measure calculated by dividing the excess arithmetic annualized return for the investment or portfolio (relative to the risk-free rate) by its annualized downside volatility, or volatility when excess returns were negative.',
  },
  {
    name: 'Calmar Ratio',
    getter: (data) => data.calmar,
    learnMoreUrl: HISTORICAL_RISK_STATISTICS_HREF,
    description:
      'A risk-adjusted performance measure calculated by dividing the geometric annualized return for the investment or portfolio by its absolute value of the maximum drawdown over the most recent 3 year period.',
  },
  {
    name: 'Autocorrelation',
    getter: (data) => data.autocorrelation,
    learnMoreUrl: HISTORICAL_RISK_STATISTICS_HREF,
    description: 'The correlation between the given return stream and a single-period lagged version of itself.',
  },
];
