import type { FC } from 'react';
import React from 'react';
import { GetColor } from 'venn-ui-kit';
import styled from 'styled-components';

export interface VerticalLinesPatternProps {
  id: string;
  color?: string;
  size?: number;
}

const VerticalLinesPattern: FC<React.PropsWithChildren<VerticalLinesPatternProps>> = ({ id, size = 4, color }) => (
  <pattern id={id} patternUnits="userSpaceOnUse" width={size} height={size}>
    <StyledRect width={(3 * size) / 4} height={size} color={color} />
  </pattern>
);

export default VerticalLinesPattern;

const StyledRect = styled.rect<{ color?: string }>`
  fill: ${({ color }) => color || GetColor.LightGrey};
`;
