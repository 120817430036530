// This file contains temporary types for endpoints where the Swagger
// file is either missing or not correct.
import type {
  AnalysisSubjectTypeEnum,
  AnalysisTypeEnum,
  Portfolio,
  DrawdownContribution,
  DrawdownFactorInfo,
  FactorAnalysis,
  LibrarySearchEntity,
  ProxyCategoryEnum,
  FundSourceTypeEnum,
  ProxyTypeEnum,
  SimpleFund,
  PortfolioConstraint,
  AppUser,
  OptimizationTargetEnum,
} from './autogenerated/types';

type ExtraAnalysisType =
  | 'RETURNS_DISTRIBUTION'
  | 'VENNCAST'
  | 'FACTOR_FORECASTS'
  | 'RAW_DATA'
  | 'DRAWDOWN_HISTORY'
  | 'HYPOTHETICAL_DRAWDOWN_ANALYSIS'
  | 'DRAWDOWN_MARKET_ENV_RETURNS'
  | 'DRAWDOWN_MARKET_ENV_CORRELATIONS'
  | 'DRAWDOWN_MARKET_ENV_STRATEGY_DRIVERS'
  | 'DRAWDOWN_MARKET_ENV_INVESTMENT_DRIVERS'
  | 'DRAWDOWN_MARKET_ENV_FACTORS'
  | 'NOTABLE_PERIODS'
  | 'FACTOR_CONTRIBUTION_MODAL'
  | 'RISK_STATISTICS'
  | 'CUMULATIVE_RESIDUAL_RETURN'
  | 'RESIDUAL_PERFORMANCE_SUMMARY'
  | 'FACTOR_PERFORMANCE_SUMMARY'
  | 'FACTOR_CORRELATIONS'
  | 'PORTFOLIO_LAB'
  | 'PRIVATE_CASH_FLOW'
  | 'PRIVATE_PERFORMANCE_TIME_SERIES'
  | 'BUILDER';

export type AnalysisTypeEnumForDataExport = AnalysisTypeEnum | ExtraAnalysisType;

export type OutputFileType = 'PNG' | 'PDF' | 'XLSX' | 'CLIPBOARD' | 'PRINT PDF';
export interface DataExportEvent {
  objectType: AnalysisSubjectTypeEnum | 'FACTOR';
  subjectId?: string;
  outputDescription: AnalysisTypeEnumForDataExport;
  outputFileType?: OutputFileType;
  userUploaded: boolean;
  relativeToBenchmark: boolean;
}

export interface FileUploadMetadata {
  appendType: Metadata<number>[];
  currency: Metadata<string>[];
  dataType: Metadata<number>[];
  dateFormat: Metadata<number>[];
  errorTypes: Metadata<string>[];
  frequency: Metadata<number>[];
  metricType: Metadata<number>[];
}

export interface Metadata<idType extends string | number> {
  id: idType;
  name: string;
}

export interface ActivationCodeCheck {
  email: string;
  activationCode: string;
}

export enum AuthenticationStatus {
  Unknown,
  Checking,
  CredentialsRequired,
  CredentialsSubmitted,
  CredentialsRefused,
  Authenticated,
  AccountLocked,
  AuthenticatedMfaSetupRequired,
}

export enum AuthenticationErrorType {
  /** Network error */
  NetworkError,
  /**
   * An unexpected server error occurred, authentication request
   * was not processed
   */
  ServerError,
  /** Credentials were refused */
  IncorrectCredentials,
  /** Two-factor auth code is required */
  IncorrectOrMissingExtraCredentials,
  ActivationCodeIncorrectOrExpired,
  VerificationCodeIncorrectOrExpired,
  AccountAlreadyCreated,
  /** The user has been removed from their org */
  NotMemberOfOrganization = 40020,
  PasswordResetRequired = 40072,
}

/**
 * An Error object describing an authentication failure. Its main purpose it
 * to deliver human-readable and actionable feedback to the user on the
 * failure of a user-initiated authentication-related action (e.g.
 * signin, signout. )
 */
export interface AuthenticationError {
  /**
   * What type of error.
   */
  readonly errorType: AuthenticationErrorType;
  /**
   * Optional short message about the error to be displayed before the longer message.
   */
  readonly title?: string;

  /**
   * Human-readable error message. Should be actionable.
   */
  readonly message: string;
}

export interface DrawdownRangeAnalysis {
  benchmarkCashContribution?: number;
  benchmarkDrawdown?: number[][];
  benchmarkResidualContribution?: number;
  canAnalyzeBenchmark: boolean;
  canAnalyzeSecondary: boolean;
  cashContribution: number;
  drawdownFactorInfoList: DrawdownFactorInfo[];
  errorDrawdown: number[][];
  factorExposureList: DrawdownFactorExposure[];
  fundDrawdownContribution: DrawdownContribution[];
  lowerErrorDrawdown: number[][];
  secondaryCashContribution: number;
  secondaryPortfolioDrawdown: number[][];
  secondaryResidualContribution: number;
  maxBenchmarkDrawdown?: number;
  maxBenchmarkDrawdownDate?: number;
  maxDrawdown: number;
  maxDrawdownDate: number;
  maxSecondaryDrawdown: number;
  maxSecondaryDrawdownDate: number;
  portfolioAnalysis: FactorAnalysis;
  portfolioDrawdown: number[][];
  residualContribution: number;
  strategyDrawdownContribution: DrawdownContribution[];
  upperErrorDrawdown: number[][];
}

export interface DrawdownRequest {
  benchmarkId: string;
  factorLensId: number;
  portfolio: Portfolio;
  threshold: number;
}
export interface DrawdownFactorExposure {
  benchmarkBeta?: number;
  benchmarkContribution?: number;
  contribution: number;
  factorId: number;
  factorName: string;
  secondaryContribution: number;
  secondaryPortfolioBeta: number;
  portfolioBeta: number;
}

export interface BenchmarkCompare {
  benchmark: boolean;
  fundId?: string;
  fundType?: FundSourceTypeEnum;
  name: string;
  portfolioId?: number;
  primary: boolean;
}
export interface OptimizationTarget {
  id: number;
  name: string;
}

export interface OptionalLibrarySearchEntity
  extends Omit<LibrarySearchEntity, 'proxyCategory' | 'proxyId' | 'proxyName' | 'proxyType' | 'extrapolated'> {
  // Override optional properties from API
  proxyCategory?: ProxyCategoryEnum;
  proxyId?: string;
  proxyName?: string;
  proxyType?: ProxyTypeEnum;
  extrapolated?: boolean;
}

export enum SupportedErrorCodes {
  NoCommonOverlap = 10134,
  InsufficientReturns = 10047,
  AnalysisRelativeMissingBenchmark = 10050,
  NoOverlapWithFactorReturns = 10211,
  InvalidAnalysisPeriod = 10048,
  PeriodTooNarrowForFactorTrend = 10141,
  InsufficientHistoryForDrawdown = 10160,
  ResidualRiskTooHighForDrawdown = 10176,
  InsufficientDataForScenario = 10234,
  EmptyPortfolio = 10112,
  NotAllowed = 4030,
  AnalysisLimitErrorCode = 10275,
  AnalysisBelowMinus100Percent = 10317,
  NoFundReturns = 10020,
  InvalidRollingPeriod = 10292,
  EnfUser = 40020,
  InvalidActivationCode = 40024,
  ActivationCodeExpiration = 40025,
  AccountNoActivate = 40026,
  UploadedDataHasGaps = 10252,
  UploadedDataHasAnomalies = 10253,
  PasswordResetRequired = 40072,
}

/**
 * Old optimization types that need to be removed later
 */
export interface AllocationConstraint {
  fund?: SimpleFund;
  maxAllocation?: number;
  minAllocation?: number;
  newFund: boolean;
  portfolioId?: number;
}

export interface FactorConstraint {
  factorId: number;
  maxExposure: number;
  minExposure: number;
}

export type RelaxationsStrategyEnum = 'TARGET' | 'ALLOCATIONS' | 'FACTORS';

export interface OptimizationConfiguration {
  allocationConstraints: AllocationConstraint[];
  benchmarkFundId: string;
  benchmarkPortfolioId: number;
  constraints: PortfolioConstraint[];
  created: number;
  creator: AppUser;
  factorConstraints: FactorConstraint[];
  factorForecastId: string;
  factorForecastVersion: string;
  id: string;
  maxDrawdown: number;
  maxTurnover: number;
  maxVolatility: number;
  minReturn: number;
  minSharpe: number;
  minTurnover: number;
  name: string;
  optimizationTarget: OptimizationTargetEnum;
  portfolio: Portfolio;
  relaxationStrategy: RelaxationsStrategyEnum;
  relaxationsNum: number;
  relaxationsPercent: number;
  resultPortfolioId: number;
  resultTargetId: number;
  selectedStrategyId: number;
  targetId: number;
}

export type InvestmentAssetTypeEnum = 'ETF' | 'HEDGE_FUND' | 'MUTUAL_FUND' | 'OTHER';

export type HttpMethod = 'GET' | 'HEAD' | 'POST' | 'PUT' | 'DELETE' | 'CONNECT' | 'OPTIONS' | 'TRACE' | 'PATCH';
