import React from 'react';
import { ErrorMessage, InfoHeading, RowSpaceBetween, ViewConfigurationArea } from './styles';
import { values } from 'lodash';
import { Column } from './EditViewConfiguration';
import ViewActions from './ViewActions';
import type { ViewErrors } from './useAddeparViewConfiguration';

interface CollapedViewProps {
  portfolioPrefix: string;
  error: ViewErrors;
  onClickExpand(): void;
  onClickDelete(): void;
}

const CollapedView = ({ portfolioPrefix, error, onClickExpand, onClickDelete }: CollapedViewProps) => {
  return (
    <ViewConfigurationArea>
      <RowSpaceBetween>
        <Column>
          <InfoHeading as="label">{portfolioPrefix}</InfoHeading>
          {values(error)
            .filter((message) => !!message)
            .map((message) => (
              <ErrorMessage key={message}>{message}</ErrorMessage>
            ))}
        </Column>
        <ViewActions
          onToggle={onClickExpand}
          onlyHasOneView={false}
          removeCurrentView={onClickDelete}
          isActive={false}
        />
      </RowSpaceBetween>
    </ViewConfigurationArea>
  );
};

export default CollapedView;
