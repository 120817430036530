import React, { useEffect, useState } from 'react';
import { groupBy, noop, uniq } from 'lodash';

const REFRESH_INTERVAL_MS = 500;

/** Component that displays information about loaded and unloaded font faces. */
export const FontInfo = () => {
  const fontInfo = useFontInfo();

  return (
    <>
      <h3>Font Info</h3>
      <p>
        Note: an unloaded font face does not mean that it failed to load. The font face will only complete loading when
        it is needed by a glyph on the page.
      </p>

      {fontInfo.state === 'no_support' && (
        <p>
          This browser does not support the FontFaceSet API. This page will not be able to display font loading
          information.
        </p>
      )}

      {fontInfo.state === 'loaded' &&
        fontInfo.info.map(({ family, faces }) => {
          const loadedFaces = faces.filter((face) => face.isLoaded);
          const unloadedFaces = faces.filter((face) => !face.isLoaded);
          return (
            <div key={family} style={{ borderBottom: '1px solid grey', margin: 1 }}>
              {!!loadedFaces.length && (
                <div style={{ color: 'darkgreen' }}>
                  {family} loaded: {loadedFaces.map((face) => `${face.style}-${face.weight}`).join(', ')}
                </div>
              )}
              {!!unloadedFaces.length && (
                <div>
                  {family} NOT loaded: {unloadedFaces.map((face) => `${face.style}-${face.weight}`).join(', ')}
                </div>
              )}
            </div>
          );
        })}
    </>
  );
};

type FontInfoState =
  | { state: 'loaded'; info: ReturnType<typeof getFontInfo> }
  | { state: 'loading' }
  | { state: 'no_support' };
const useFontInfo = () => {
  const [fontInfo, setFontInfo] = useState<FontInfoState>({ state: 'loading' });

  useEffect(() => {
    if (!('fonts' in document) || !document.fonts) {
      setFontInfo({ state: 'no_support' });
      return noop;
    }

    const updateFontInfo = () => {
      setFontInfo({ state: 'loaded', info: getFontInfo() });
    };

    updateFontInfo();
    const iid = setInterval(updateFontInfo, REFRESH_INTERVAL_MS);
    return () => clearInterval(iid);
  }, []);

  return fontInfo;
};

const getFontInfo = () => {
  const fontFaces = [...document.fonts.values()];
  const fontFamilies = uniq(fontFaces.map(({ family }) => family));

  const fontFamilyStatuses = fontFamilies.map((family) => {
    const wrappedFamily = wrapFontFamily(family);
    const allFamilyFaces = fontFaces.filter((face) => face.family === family);
    const uniqStyleAndWeight = groupBy(allFamilyFaces, (face) => `${face.style}${face.weight}`);
    return {
      family: wrappedFamily,
      faces: Object.values(uniqStyleAndWeight).map((faces) => ({
        style: faces[0].style,
        weight: faces[0].weight,
        // We consider a face to be loaded if even just a portion is loaded, because that means it CAN be loaded but the browser chose to only load a portion.
        isLoaded: faces.some((face) => face.status === 'loaded'),
      })),
    };
  });
  return fontFamilyStatuses;
};

/** Wraps a font family with quotes if necessary. */
function wrapFontFamily(fontFamily: string): string {
  const hasSingleQuotes = fontFamily.startsWith("'") && fontFamily.endsWith("'");
  const hasDoubleQuotes = fontFamily.startsWith('"') && fontFamily.endsWith('"');
  if (hasSingleQuotes || hasDoubleQuotes) {
    return fontFamily;
  }

  const needsQuotes = fontFamily.includes(' ');
  if (!needsQuotes) {
    return fontFamily;
  }

  return `"${fontFamily}"`;
}
