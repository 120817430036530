import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import Icon from '../../icon/Icon';
import type { VennColors } from '../../../style';
import { GetColor } from '../../../style';
import { ItemType } from './itemType';
import StudioIcon from '../../custom-icon/StudioIcon';

const getIconForItemType = (itemType: ItemType, colors: VennColors) => {
  switch (itemType) {
    case ItemType.Analysis:
      return <StyledIconForPill type="calculator" />;
    case ItemType.StudioReport:
    case ItemType.ReportTemplate:
      return <StyledIconForPill type="file-chart-column" />;
    case ItemType.Compare:
      return <StyledIconForPill type="line-columns" />;
    case ItemType.Studio:
    case ItemType.StudioTearsheet:
    case ItemType.TearsheetTemplate:
      return <StudioIcon color={colors.White} size={14} top={2} />;
    case ItemType.Article:
      return <StyledIconForPill type="cloud-question" />;
    default:
      return null;
  }
};

export const IconForPill = ({ itemType }: { itemType: ItemType }) => {
  const { Colors } = useContext(ThemeContext);
  const icon = getIconForItemType(itemType, Colors);
  return icon ? <IconWrapper>{icon}</IconWrapper> : null;
};

const IconWrapper = styled.span`
  width: 14px;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
`;

const StyledIconForPill = styled(Icon)`
  color: ${GetColor.White};
  font-size: 14px;
`;
