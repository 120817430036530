import React, { Component } from 'react';
import type { MFAResponse } from 'venn-api';
import { setupTOTPMFA, verifyTOTPMFA } from 'venn-api';
import { Content, Error, Field, Input, Label, SubHeader } from '../account-modals/shared';
import { BackupCodes } from './BackupCodes';
import { Icon } from 'venn-ui-kit';
import { ModalFooter } from '../../modal';
import AuthenticatorDetails from './AuthenticatorDetails';

interface EnableAuthenticatorProps {
  onBack: undefined | (() => void);
  onClose: () => void;
  refresh: () => void;
}

interface EnableAuthenticatorState {
  code: string;
  showBackupCodes: boolean;
  showManualEntry: boolean;
  loading: boolean;
  error: string;
  authenticatorInfo: MFAResponse | null;
}

const AUTH_BACKUP_CONFIRM_MESSAGE = 'the Google Authenticator app.';
const BACKUP_HEADER = 'Save your backup codes.';

export class EnableAuthenticator extends Component<EnableAuthenticatorProps, EnableAuthenticatorState> {
  state: EnableAuthenticatorState = {
    code: '',
    error: '',
    showBackupCodes: false,
    showManualEntry: false,
    loading: false,
    authenticatorInfo: null,
  };

  formRef = React.createRef<HTMLFormElement>();

  componentDidMount = async () => {
    this.setState({
      loading: true,
    });
    try {
      const response = await setupTOTPMFA();
      this.setState({
        authenticatorInfo: response.content,
      });
    } catch (e) {
      const error = await e;
      this.setState({
        error: error.content?.message,
      });
    }
    this.setState({
      loading: false,
    });
  };

  onVerificationCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    this.setState((prevState) => ({
      ...prevState,
      code: value,
    }));
  };

  onSubmitVerificationCode = async (e: React.FormEvent<HTMLFormElement>) => {
    e.stopPropagation();
    e.preventDefault();
    const { code } = this.state;
    const { refresh } = this.props;
    this.setState({
      loading: true,
    });
    try {
      await verifyTOTPMFA({ code });
      this.setState({
        showBackupCodes: true,
        error: '',
      });
      refresh();
    } catch (err) {
      const error = await err;
      this.setState({ error: error.content?.message });
    }

    this.setState({
      loading: false,
    });
  };

  toggleManualEntry = () => {
    this.setState((prevState) => ({
      showManualEntry: !prevState.showManualEntry,
    }));
  };

  render() {
    const { onBack, onClose } = this.props;
    const { authenticatorInfo, code, error, loading, showBackupCodes, showManualEntry } = this.state;
    return (
      <>
        <Content>
          <SubHeader>
            <Icon type="google" prefix="fab" />
            &nbsp;Enable Google Authenticator
          </SubHeader>
        </Content>
        <form onSubmit={this.onSubmitVerificationCode} ref={this.formRef}>
          <Content>
            <Field>
              {!showBackupCodes && (
                <>
                  <AuthenticatorDetails
                    showManualEntry={showManualEntry}
                    authenticatorInfo={authenticatorInfo}
                    loading={loading}
                    toggleManualEntry={this.toggleManualEntry}
                  />
                  <Label>Enter the pin code shown on the Authenticator on your smartphone:</Label>
                  <Input
                    defaultValue={code}
                    placeholder="Authentication code"
                    onChange={this.onVerificationCodeChange}
                  />
                </>
              )}
            </Field>
          </Content>
          {showBackupCodes && (
            <BackupCodes onClose={onClose} header={BACKUP_HEADER} confirmMessage={AUTH_BACKUP_CONFIRM_MESSAGE} />
          )}
          <Content>
            <Error>{error}</Error>
          </Content>
          {!showBackupCodes && (
            <ModalFooter
              primaryLabel="Next"
              primaryDisabled={!code}
              onPrimaryClick={() =>
                typeof this.formRef.current?.onSubmit === 'function' && this.formRef.current.onSubmit()
              }
              onCancel={onBack ?? onClose}
              cancelLabel={onBack ? 'Back' : 'Cancel'}
            />
          )}
        </form>
      </>
    );
  }
}

export default EnableAuthenticator;
