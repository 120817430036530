import React, { useContext, useEffect, useMemo, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { EditorContext, UserContext } from '../../contexts';
import RichTextEditor from '../../richtext-editor/RichTextEditor';
import type { EditorValue } from '../../richtext-editor';
import { createReactEditor } from '../../richtext-editor';
import { viewRichText } from 'venn-state';
import { useRecoilState } from 'recoil';
import { useAppPrintMode } from '../../print/AppPrintMode';

interface TextBlockProps {
  id: string;
  active: boolean;
  isReport?: boolean;
}

const TextBlock = ({ active, id, isReport }: TextBlockProps) => {
  const { inPrintModeOrReportLab } = useAppPrintMode();
  const { hasPermission } = useContext(UserContext);
  const { setActiveEditor, recalculateTextControlsState } = useContext(EditorContext);
  const editor = useMemo(() => createReactEditor(), []);
  const isReadOnly = !hasPermission('STUDIO_EDIT_TEXT');

  const [value, setValue] = useRecoilState(viewRichText(id));

  const handleOnChange = useCallback(
    (value: EditorValue) => {
      setValue(value);
      recalculateTextControlsState();
    },
    [setValue, recalculateTextControlsState],
  );

  useEffect(() => {
    if (active) {
      setActiveEditor(editor);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  const previewStyle = inPrintModeOrReportLab;

  return (
    <Container previewStyle={previewStyle} inReportEditor={isReport}>
      {editor && (
        <RichTextEditor
          controlledEditor={editor}
          initialValue={value}
          handleOnChange={handleOnChange}
          readOnly={isReadOnly}
        />
      )}
    </Container>
  );
};

const Container = styled.div<{ previewStyle?: boolean; inReportEditor?: boolean }>`
  padding: ${({ previewStyle }) => (previewStyle ? '0' : '0 20px')};
  cursor: pointer;
  ${({ inReportEditor }) =>
    inReportEditor &&
    css`
      p,
      ul {
        font-size: 14px;
      }
    `}
`;

export default TextBlock;
