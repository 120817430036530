import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import { GetColor, ZIndex } from 'venn-ui-kit';

interface DropIndicatorProps {
  level?: number;
  indentWidth?: number;
  verticalPadding?: number;
}

const DropIndicator: FC<React.PropsWithChildren<DropIndicatorProps>> = ({
  level = 0,
  indentWidth = 0,
  verticalPadding = 0,
}) => <Indicator style={getDropIndicatorWidth(level, indentWidth, verticalPadding)} />;

export const getDropIndicatorWidth = (level: number, indentWidth: number, verticalPadding: number) => ({
  width: `calc(100% - ${level * indentWidth + verticalPadding}px)`,
});

const Indicator = styled.div`
  position: absolute;
  display: block;
  height: 4px;
  background-color: ${GetColor.Primary.Main};
  bottom: -2px;
  right: 0;
  z-index: ${ZIndex.Front};

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: -3px;
    left: -4px;
    border: 3px solid ${GetColor.Primary.Main};
    border-radius: 100%;
    background: ${GetColor.White};
    width: 10px;
    height: 10px;
  }
`;

export default DropIndicator;

export const RelativeDropIndicator = (props: DropIndicatorProps) => (
  <div style={{ position: 'relative' }}>
    <DropIndicator {...props} />
  </div>
);
