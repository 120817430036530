import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import { Severity } from './Types';

const Wrapper = styled.div`
  cursor: pointer;
`;

interface Props {
  severity?: Severity;
  onClose(): void;
}

const colors = {
  [Severity.Success]: '#009650',
  [Severity.Error]: '#b82a2b',
  [Severity.Warn]: '#757575',
  [Severity.Info]: '#53bde6',
};

const CloseButton: FC<React.PropsWithChildren<Props>> = ({ onClose, severity }) => (
  <Wrapper onClick={onClose}>
    <svg width="16" height="16" viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        fill={severity ? colors[severity] : ''}
        d="
          M14.062 0L7.991 6.079 1.92 0 0 1.921 6.091 8.02 0 14.098 1.92
          16l6.071-6.077L14.062 16l1.939-1.902L9.909 8.02l6.092-6.099z"
      />
    </svg>
  </Wrapper>
);

export default CloseButton;
