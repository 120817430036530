import type { ReactNode } from 'react';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { BaseDropMenu, ColorUtils, GetColor } from 'venn-ui-kit';

export interface ConditionOption<T = string> {
  label: string;
  value: T;
  description: string;
}

interface ConstraintConditionDropdownProps<T = string> {
  options: ConditionOption<T>[];
  children: (open: boolean, onToggle: (open?: boolean) => void) => ReactNode;
  selected?: T;
  onSelect: (condition: T) => void;
}

const ITEMS_WIDTH = 162;
const DESCRIPTION_WIDTH = 246;
const MENU_HEIGHT = 128;

function ConstraintConditionDropdown<T = string>({
  options,
  children,
  selected,
  onSelect,
}: ConstraintConditionDropdownProps<T>) {
  const [description, setDescription] = useState<string | undefined>();
  const onSetText = (text: string) => setDescription(text);
  const onClearText = (text: string) => setDescription((prev) => (prev === text ? undefined : prev));

  return (
    <BaseDropMenu
      usePortal
      filteredItems={options}
      triggerComponent={(expanded, __, onToggle) => <>{children(expanded, onToggle)}</>}
      menuComponent={(_, onMenuCollapse, menuClassName) => (
        <SplitMenuContainer tabIndex={-1} className={menuClassName}>
          <Items>
            {options.map((option) => (
              <Item
                key={option.label}
                type="button"
                onMouseEnter={() => onSetText(option.description)}
                onMouseLeave={() => onClearText(option.description)}
                onClick={() => {
                  onSelect(option.value);
                  onMenuCollapse();
                }}
                isSelected={selected === option.value}
              >
                {option.label}
              </Item>
            ))}
          </Items>
          <DescriptionContainer>{description ?? 'Hover over an option for more information'}</DescriptionContainer>
        </SplitMenuContainer>
      )}
    />
  );
}

export default ConstraintConditionDropdown;

const SplitMenuContainer = styled.div`
  border: 1px solid ${GetColor.Grey};
  border-radius: 4px;
  display: flex;
  min-width: ${ITEMS_WIDTH + DESCRIPTION_WIDTH}px;
  width: ${ITEMS_WIDTH + DESCRIPTION_WIDTH}px;
  height: ${MENU_HEIGHT}px;
  max-width: 100%;
  box-shadow: 0 5px 10px 0 ${GetColor.MidGrey2};
  background-color: ${GetColor.White};
  margin-top: 9px;
  margin-left: 5px;
`;

const Items = styled.div`
  overflow: hidden;
  padding-top: 14px;
  padding-bottom: 14px;
  flex: 1;
`;

const Item = styled.button<{ isSelected?: boolean }>`
  font-weight: bold;
  min-width: ${ITEMS_WIDTH}px;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  text-align: left;
  padding-left: 22px;
  color: ${GetColor.Primary.Dark};
  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${GetColor.PaleGrey};
    `}
  &:hover {
    background-color: ${({ isSelected }) =>
      isSelected ? GetColor.PaleGrey : ColorUtils.opacifyFrom(GetColor.Primary.Dark, 0.1)};
    color: ${GetColor.Primary.Dark};
  }
`;

const DescriptionContainer = styled.div`
  width: ${DESCRIPTION_WIDTH}px;
  height: ${MENU_HEIGHT}px;
  border-left: 1px solid ${GetColor.Grey};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 18px 20px 18px;
  color: ${GetColor.DarkGrey};
  font-size: 14px;
  line-height: 24px;
`;
