import React, { PureComponent } from 'react';
import type { Theme } from 'venn-ui-kit';
import { select } from 'd3-selection';
import { extent } from 'd3-array';
import { scaleLinear, scaleTime } from 'd3-scale';
import type { DataPoint } from '../../types';
import { withTheme } from 'styled-components';

const height = 20;
const width = 109;

interface LineChartProps {
  /**
   * Time series for the proxy
   */
  proxy: DataPoint[];
  /**
   * Time series for the investment
   */
  investment: DataPoint[];
  /**
   * Time series for the extrapolation
   */
  extrapolation: DataPoint[];
  /**
   * The current theme
   */
  theme: Theme;
}

class LineChart extends PureComponent<LineChartProps> {
  node: SVGSVGElement | null;

  constructor(props: LineChartProps) {
    super(props);
    this.buildChart = this.buildChart.bind(this);
  }

  componentDidMount() {
    this.buildChart();
  }

  componentDidUpdate() {
    this.buildChart();
  }

  async buildChart() {
    const { node } = this;
    if (!node) {
      return;
    }

    node.innerHTML = '';

    const shape = await import('d3-shape');
    const line = shape.line;

    const {
      proxy,
      investment,
      extrapolation,
      theme: { Schemes },
    } = this.props;

    if (!proxy.length && !investment.length) {
      return;
    }

    const margins = { top: 0, bottom: 0, right: 0, left: 0 };
    const svg = select(node);
    const series = svg.append('g').attr('transform', `translate(${margins.left}, ${margins.top})`);

    const datum = investment.map((d) => [d.x, d.y]) ?? [];
    const proxyDatum = proxy.map((d) => [d.x, d.y]) ?? [];
    const extrapolationDatum = extrapolation.map((d) => [d.x, d.y]);
    const all = [...datum, ...proxyDatum, ...extrapolationDatum];

    // we can make a type assertion because we're sure `both` is nonempty since it came from `investment` and `proxy` which are both nonempty
    const xRange = extent(all, (d) => d[0]) as [number, number];
    const x = scaleTime()
      .rangeRound([0, width - margins.left - margins.right])
      .domain(xRange);
    const y = scaleLinear()
      .rangeRound([height - margins.top - margins.bottom, 0])
      .domain(extent(all, (d) => d[1]) as [number, number]);
    const serie = line()
      .x((d) => x(d[0]))
      .y((d) => y(d[1]));

    series
      .append('path')
      .datum(datum)
      .attr('fill', 'none')
      .attr('stroke', Schemes.Proxy.subjectLine)
      .attr('stroke-linejoin', 'round')
      .attr('stroke-linecap', 'round')
      .attr('stroke-width', 1)
      // @ts-expect-error: TODO fix strictFunctionTypes
      .attr('d', serie);

    series
      .append('path')
      .datum(proxyDatum)
      .attr('fill', 'none')
      .attr('stroke', Schemes.Proxy.proxyLine)
      .attr('stroke-linejoin', 'round')
      .attr('stroke-linecap', 'round')
      .attr('stroke-width', 1)
      // @ts-expect-error: TODO fix strictFunctionTypes
      .attr('d', serie);

    series
      .append('path')
      .datum(extrapolationDatum)
      .attr('fill', 'none')
      .attr('stroke', Schemes.Proxy.proxyLine)
      .attr('stroke-linejoin', 'round')
      .attr('stroke-linecap', 'round')
      .attr('stroke-width', 1)
      // @ts-expect-error: TODO fix strictFunctionTypes
      .attr('d', serie);
  }

  render() {
    return <svg width={width} height={height} ref={(node) => (this.node = node)} />;
  }
}

export default withTheme(LineChart);
