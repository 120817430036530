import React, { useCallback, useContext, useMemo } from 'react';
import type { AnalysisConfig, AnalysisSubject } from 'venn-utils';
import { vennAppUser, analyticsService, navigateToTemplate } from 'venn-utils';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import type { GeneralAnalysisTemplate } from 'venn-api';
import { Headline2, Shimmer, GetColor, Icon, IconDropMenu, Tooltip, TooltipPosition, getAppTitle } from 'venn-ui-kit';
import { SubjectSelector, UserContext } from 'venn-components';
import TemplateSelector from './TemplateSelector';

export interface WorkspaceTitleProps {
  /** Show add block button or not */
  addBlock?: boolean;
  loadingSubject?: boolean;
  fontSize?: number;
  analysisConfig: AnalysisConfig;
  templates?: GeneralAnalysisTemplate[];
  onChangeAnalysisTemplate?: (type: GeneralAnalysisTemplate) => void;
  onEditTemplate?: (id: string) => void;
  onChangeSubject?: (item: AnalysisSubject) => void;
}

const WorkspaceTitle = ({
  addBlock,
  loadingSubject,
  fontSize,
  analysisConfig,
  templates,
  onChangeSubject,
  onEditTemplate,
  onChangeAnalysisTemplate,
}: WorkspaceTitleProps) => {
  const { hasPermission } = useContext(UserContext);
  const { analysisTemplate } = analysisConfig;
  const history = useHistory();
  const createNewTemplate = useCallback(() => {
    if (analysisTemplate?.id) {
      navigateToTemplate(history, undefined, analysisTemplate?.id);
    }
  }, [history, analysisTemplate]);

  const templatesWithoutBlock = useMemo(() => {
    if (!analysisTemplate || analysisTemplate.analysisTemplateType !== 'BLOCK' || !templates) {
      return [];
    }
    return templates
      .filter((t) => t.analysisTemplateType !== 'BLOCK' && t.appUser?.displayName !== `${getAppTitle()}`)
      .filter(
        (t) =>
          !t.analysisBlocks.find(
            (block) => block.analysisBlockType === analysisTemplate.analysisBlocks[0].analysisBlockType,
          ),
      )
      .map((item: GeneralAnalysisTemplate) => ({
        value: item,
        label: item.name,
        level: 0,
      }));
  }, [analysisTemplate, templates]);

  if (!analysisConfig.analysisTemplate || !templates || loadingSubject) {
    return (
      <ShimmerWrapper>
        <LoadingHeadline />
        <LoadingHeadline />
      </ShimmerWrapper>
    );
  }

  const subjectDisabled =
    analysisConfig.subject?.isInvestmentInPortfolio || analysisConfig.subject?.isStrategyInPortfolio;

  const isBlock = analysisTemplate?.analysisTemplateType === 'BLOCK';
  return (
    <WorkspaceTitleWrapper>
      <StyledHeadline fontSize={fontSize || 32}>
        <SubjectSelector
          selectedSubject={analysisConfig.subject}
          onChangeSubject={onChangeSubject}
          disabled={!onChangeSubject || subjectDisabled}
          noLeftPadding
          showItemTypeBadge
          analyticsProps={{
            location: 'analysisSubjectSelector',
            destinationPageTitle: 'Analysis Results',
          }}
        />
      </StyledHeadline>
      <Flex>
        <InlineBlock>
          <TemplateSelector
            currentTemplate={analysisConfig.analysisTemplate}
            templates={templates}
            onEditTemplate={onEditTemplate}
            onSelectTemplate={(template) => {
              analyticsService.navigationTriggered({
                location: 'title',
                itemType: 'dropdown',
                userIntent: 'change analysis',
                destinationPageTitle: `Analysis Results - ${template.name}`,
              });
              onChangeAnalysisTemplate?.(template);
            }}
          />
        </InlineBlock>

        {addBlock && isBlock && hasPermission('ANALYSIS_PAGE_CREATE_TEMPLATE') && (
          <AddBlockWrapper>
            <AddBlockTooltip
              content={<span>Add {analysisConfig?.analysisTemplate?.name ?? ''} block to an analysis template </span>}
              position={TooltipPosition.Right}
              hideArrow
            >
              <IconDropMenu
                usePortal
                width={200}
                iconSize={15}
                icon="plus-circle"
                link="Create New Template"
                onLinkClick={createNewTemplate}
                items={templatesWithoutBlock}
                onChange={({ value }) => navigateToTemplate(history, value.id, analysisTemplate?.id)}
                selected={undefined}
              />
            </AddBlockTooltip>
          </AddBlockWrapper>
        )}

        {analysisTemplate?.id &&
          onEditTemplate &&
          !isBlock &&
          analysisTemplate?.appUser?.id !== vennAppUser.id &&
          hasPermission('ANALYSIS_PAGE_CREATE_TEMPLATE') && (
            <Tooltip position={TooltipPosition.Right} content="Edit template">
              <EditTemplateIcon type="edit" tabIndex={0} onClick={() => onEditTemplate(analysisTemplate.id)} />
            </Tooltip>
          )}
      </Flex>
    </WorkspaceTitleWrapper>
  );
};

export default WorkspaceTitle;

const WorkspaceTitleWrapper = styled.div`
  margin-bottom: 5px;
  min-width: 200px;
`;

const InlineBlock = styled.div`
  display: inline-block;
`;

const ShimmerWrapper = styled.div`
  width: 100%;
`;

const LoadingHeadline = styled.div`
  ${Shimmer}
  width: 80%;
  height: 35px;
  margin-bottom: 9px;
`;

const StyledHeadline = styled(Headline2)<{ fontSize: number }>`
  font-size: ${(props) => props.fontSize}px;
  transition: 0.5s;
  display: inline-block;
  overflow: hidden;
  max-width: 100%;
`;

const Flex = styled.div`
  display: flex;
`;

const AddBlockTooltip = styled(Tooltip)`
  overflow: visible;
  line-height: 0;
`;

const AddBlockWrapper = styled.span`
  i {
    font-size: 25px;
  }
`;

const EditTemplateIcon = styled(Icon)`
  color: ${GetColor.Primary.Dark};
  padding: 4px;
  font-size: 18px;
  line-height: 27px;
  margin-left: 8px;

  &:hover {
    color: ${GetColor.Primary.Main};
    cursor: pointer;
  }
`;
