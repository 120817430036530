import type {
  BlockFontOverrides,
  LegalFontOverrides,
  SectionPageFontOverrides,
  TitlePageFontOverrides,
} from 'venn-api';
import { DEFAULT_FONT_SIZE_OVERRIDES, FONT_SIZE_RANGES } from 'venn-state';
import { get } from 'lodash';

export interface FontOverridesFormData {
  blockFontOverrides?: BlockFontOverrides;
  legalFontOverrides?: LegalFontOverrides;
  sectionPageFontOverrides?: SectionPageFontOverrides;
  titlePageFontOverrides?: TitlePageFontOverrides;
}

type PrefixedKeys<T, P extends string> = {
  [K in keyof T as `${P}.${string & K}`]: T[K];
};

type OverrideKey =
  | keyof PrefixedKeys<LegalFontOverrides, 'legalFontOverrides'>
  | keyof PrefixedKeys<BlockFontOverrides, 'blockFontOverrides'>
  | keyof PrefixedKeys<SectionPageFontOverrides, 'sectionPageFontOverrides'>
  | keyof PrefixedKeys<TitlePageFontOverrides, 'titlePageFontOverrides'>;

interface FormField {
  name: string;
  path: OverrideKey;
  default: number;
  min: number;
  max: number;
  description?: string;
}

const addDefaults = (partialFields: Omit<FormField, 'default' | 'min' | 'max'>[]): FormField[] =>
  partialFields.map((field) => ({
    default: get(DEFAULT_FONT_SIZE_OVERRIDES, field.path),
    ...get(FONT_SIZE_RANGES, field.path),
    ...field,
  }));

const titlePageFields = addDefaults([
  { name: 'Date', path: 'titlePageFontOverrides.dateFontSize' as const },
  { name: 'Title', path: 'titlePageFontOverrides.titleFontSize' as const },
  { name: 'Subtitle', path: 'titlePageFontOverrides.subtitleFontSize' as const },
  { name: 'Additional Text', path: 'titlePageFontOverrides.additionalTextFontSize' as const },
]);

const sectionPageFields = addDefaults([
  { name: 'Section Name', path: 'sectionPageFontOverrides.nameFontSize' as const },
  { name: 'Title', path: 'sectionPageFontOverrides.titleFontSize' as const },
  { name: 'Subtitle', path: 'sectionPageFontOverrides.subtitleFontSize' as const },
  {
    name: 'Organization Display Name',
    path: 'sectionPageFontOverrides.orgDisplayNameFontSize' as const,
  },
]);

const blockFields = addDefaults([
  {
    name: 'Block Title',
    path: 'blockFontOverrides.titleFontSize' as const,
    description: 'Such as Portfolio Comparison, Calendar Returns, etc.',
  },
  {
    name: 'Block Subtitle',
    path: 'blockFontOverrides.subtitleFontSize' as const,
    description: 'Custom subtitles added in the block settings panel.',
  },
  {
    name: 'Block Header Data',
    path: 'blockFontOverrides.headerFontSize' as const,
    description: 'Such as returns frequency, date ranges, benchmark, etc.',
  },
  {
    name: 'Table Content',
    path: 'blockFontOverrides.tableFontSize' as const,
    description: 'Column headers and cell values',
  },
  {
    name: 'Chart Axis Labels',
    path: 'blockFontOverrides.chartAxisFontSize' as const,
    description: 'Names and values displayed along chart axes',
  },
  {
    name: 'Chart Legends',
    path: 'blockFontOverrides.chartLegendFontSize' as const,
    description: 'Text identifying colors and shapes used in charts',
  },
]);

const legalFields = addDefaults([
  {
    name: 'Cover Page Disclosure',
    path: 'legalFontOverrides.coverPageDisclosureFontSize' as const,
    description: 'Appears at the bottom left of every cover page',
  },
  {
    name: 'Page Footer Disclaimer',
    path: 'legalFontOverrides.disclaimerFontSize' as const,
    description: 'Appears on the bottom of every page; includes dynamically-added Venn disclaimers',
  },
  {
    name: 'Disclosures Page Text',
    path: 'legalFontOverrides.disclosureFontSize' as const,
    description: 'Appears on every disclosure page; includes dynamically-added Venn disclosures',
  },
]);
export const fontOverridesFormSections: {
  name: string;
  affects: 'existing' | 'new';
  fields: FormField[];
}[] = [
  { name: 'Title Page', fields: titlePageFields, affects: 'existing' },
  { name: 'Section Page', fields: sectionPageFields, affects: 'existing' },
  { name: 'Blocks', fields: blockFields, affects: 'new' },
  { name: 'Footnotes and disclosures/Disclaimers', affects: 'existing', fields: legalFields },
];
