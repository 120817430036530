import type { TimeFrame, AnalysisSubject, AnalysesPeriod } from 'venn-utils';
import type { Message } from 'venn-api';
import moment from 'moment';
import type { AnalysisStatusForTracking, TrackAnalysisProps } from 'venn-components';
import { noop } from 'lodash';

export function getFailedAnalysisTrackingProps(
  templateName: string,
  chartName: string,
  actualTimeFrame: TimeFrame,
  analysesPeriod: AnalysesPeriod | undefined,
  analysesError: Partial<Message> | undefined,
  subject: AnalysisSubject | undefined,
  relative: boolean,
  blockName: string,
  trackingId = -1,
  updateAnalysisStatusForTracking: (
    actionAnalysisStatuses: AnalysisStatusForTracking[],
    actionTrackingId: number,
    dateRange?: string,
  ) => void = noop,
): TrackAnalysisProps {
  const start = actualTimeFrame?.startTime ?? analysesPeriod?.maxStartTime;
  const end = actualTimeFrame?.endTime ?? analysesPeriod?.maxEndTime;

  return {
    blockTitles: [chartName],
    analysisType: templateName,
    dateRange: start !== undefined && end !== undefined ? formatDateRangeForTracking(start, end) : undefined,
    analysesPeriod,
    hasBenchmark: subject?.activeBenchmark !== undefined,
    hasProxy: !!subject?.hasProxy,
    objectType: subject?.type,
    relativeToBenchmark: relative,
    blockNames: [blockName],
    errorCode: analysesError?.code,
    trackingId,
    updateAnalysisStatusForTracking,
  };
}

export function formatDateRangeForTracking(start: number | undefined, end: number | undefined): string {
  return `${start ? moment.utc(start).format('MM/DD/YYYY') : '(--)'}-${
    end ? moment.utc(end).format('MM/DD/YYYY') : '(--)'
  }`;
}

export function getBlockTitle(blockName: string, relative: boolean): string {
  switch (blockName) {
    case 'PERFORMANCE_SUMMARY':
      return 'Performance Summary';
    case 'SCENARIO':
      return 'Sensitivity Analysis';
    case 'CUMULATIVE_RETURN':
      return `Cumulative ${relative ? 'Excess ' : ''}Return`;
    case 'RETURNS_DISTRIBUTION':
      return `${relative ? 'Relative ' : ''}Returns Distribution`;
    case 'ROLLING_VOLATILITY':
      return `Rolling ${relative ? 'Tracking Error' : 'Volatility'}`;
    case 'ROLLING_SHARPE_RATIO':
      return `Rolling ${relative ? 'Information Ratio' : 'Sharpe Ratio'}`;
    case 'ROLLING_BETA':
      return 'Rolling Beta';
    case 'ROLLING_BENCHMARK_CORRELATION':
      return 'Rolling Benchmark Correlation';
    case 'HISTORICAL_DRAWDOWN_PERIODS':
      return `Historical ${relative ? 'Underperformance' : 'Drawdown'} Periods`;
    case 'FACTOR_CONTRIBUTION_TO_RETURN':
      return `Factor Summary - ${relative ? 'Active ' : ''}Factor Contributions to Return`;
    case 'FACTOR_CONTRIBUTION_TO_RISK':
      return `Factor Summary - ${relative ? 'Active ' : ''}Factor Contributions to Risk`;
    case 'FACTOR_CONTRIBUTION_TO_EXPOSURE':
      return `Factor Summary - ${relative ? 'Active ' : ''}Factor Exposures (β)`;
    case 'FACTOR_CONTRIBUTION_TO_RETURN_TREND':
      return `Factor Trend - ${relative ? 'Active ' : ''}Factor Contributions to Return`;
    case 'FACTOR_CONTRIBUTION_TO_RISK_TREND':
      return `Factor Trend - ${relative ? 'Active ' : ''}Factor Contributions to Risk`;
    case 'FACTOR_CONTRIBUTION_TO_EXPOSURE_TREND':
      return `Factor Trend - ${relative ? 'Active ' : ''}Factor Exposures (β)`;
    case 'RISK_STATISTICS':
      return 'Historical Risk Statistics';
    case 'DRAWDOWN':
      return 'Drawdown Analysis';
    case 'RETURNS_GRID':
      return `${relative ? 'Relative ' : ''}Calendar Returns`;
    case 'CORRELATION':
      return `${relative ? 'Relative ' : ''}Correlation`;
    default:
      return blockName;
  }
}
