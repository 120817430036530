import { isNil } from 'lodash';
import React from 'react';
import {
  type Control,
  Controller,
  type FieldPath,
  type RegisterOptions,
  type SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import type { CashFlowSetting } from 'venn-api';
import { editedCashflowSettingAtom } from '../../../../venn-state/src';
import { Button, GetColor, Icon } from '../../../../venn-ui-kit/src';
import { formatData } from '../../data-grid';
import NumberInput from '../../input/NumberInput';
import {
  Footer,
  MainEditorContainer,
  ErrorText,
  SettingNameContainer,
  SettingNameInput,
  WarningContainer,
} from './common';

type ParameterOverrideFormProps = Readonly<{
  onSubmit: SubmitHandler<CashFlowSetting>;
  nameOptions: RegisterOptions<CashFlowSetting, 'name'>;
  isCreatingNew: boolean;
  systemSetting: CashFlowSetting;
  onEditPanelClose: () => void;
}>;

type RenderComponentProps = {
  onChange: (value: number) => void;
  value: number | undefined;
};

const ControlledTextInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;
const DefaultValueInfo = styled.span`
  color: ${GetColor.HintGrey};
`;

type ControlledTextInputProps = {
  id: FieldPath<Omit<CashFlowSetting, 'owner'>>;
  title: string;
  renderForm: ({ onChange, value }: RenderComponentProps) => JSX.Element;
  control: Control<CashFlowSetting>;
  defaultSettingValue: string;
};
const ControlledTextInput = ({ id, title, renderForm, control, defaultSettingValue }: ControlledTextInputProps) => {
  return (
    <ControlledTextInputContainer className={`qa-controlled-text-input-${id}`} data-testid={`${id}-input`}>
      <Controller
        control={control}
        name={id}
        render={({ field: { onChange, value } }) => {
          if (isNil(value) || typeof value === 'string' || typeof value === 'boolean') {
            return <></>;
          }
          return (
            <>
              {renderForm({
                onChange,
                value,
              })}
            </>
          );
        }}
      />
      {title}
      <DefaultValueInfo>(default: {`${defaultSettingValue}`})</DefaultValueInfo>
    </ControlledTextInputContainer>
  );
};

type CashflowSettingsFormNumberInputProps = {
  onChange: (value: number) => void;
  value: number | undefined;
  originalValue: number | undefined;
  precision?: number;
  min?: number;
  max?: number;
};

const PercentageNumberInput = ({
  value,
  onChange,
  originalValue,
  precision = 1,
  min = 0,
  max = 100,
}: CashflowSettingsFormNumberInputProps) => {
  return (
    <NumberInput
      min={min}
      max={max}
      width={56}
      precision={precision}
      step={0.1}
      formatter={(value) => `${value}%`}
      value={isNil(value) ? undefined : value * 100}
      originalValue={isNil(originalValue) ? undefined : originalValue * 100}
      onChange={(value) => onChange(value / 100)}
    />
  );
};

const ParameterOverrideForm = ({
  onSubmit,
  nameOptions,
  isCreatingNew,
  systemSetting,
  onEditPanelClose,
}: ParameterOverrideFormProps) => {
  const editedCashflowSetting = useRecoilValue(editedCashflowSettingAtom);

  const {
    formState: { errors, isDirty, isValid },
    register,
    handleSubmit,
    control,
  } = useForm<CashFlowSetting>({
    mode: 'all',
    shouldUnregister: true,
    defaultValues: editedCashflowSetting,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <MainEditorContainer>
        <SettingNameContainer data-testid="qa-setting-name-container">
          <b>Setting Name</b>
          <Controller
            control={control}
            name="name"
            render={({ field: { value } }) => (
              <SettingNameInput
                data-testid="setting-name-input"
                value={value}
                className="qa-setting-name-input"
                {...register('name', nameOptions)}
              />
            )}
          />
          {errors.name && <ErrorText>{errors.name.message}</ErrorText>}
        </SettingNameContainer>
        <ParametersContainer>
          <ContributionRatesContainer>
            <b>Rates Of Contribution</b>
            <ControlledTextInput
              defaultSettingValue={formatData(systemSetting.rateOfContribution0, 'PERCENTAGE', 1)}
              id="rateOfContribution0"
              title="0-4 qtr."
              renderForm={({ onChange, value }) => (
                <PercentageNumberInput
                  originalValue={editedCashflowSetting?.rateOfContribution0}
                  onChange={onChange}
                  value={value}
                />
              )}
              control={control}
            />
            <ControlledTextInput
              defaultSettingValue={formatData(systemSetting.rateOfContribution4, 'PERCENTAGE', 1)}
              id="rateOfContribution4"
              title="4-8 qtr."
              renderForm={({ onChange, value }) => (
                <PercentageNumberInput
                  originalValue={editedCashflowSetting?.rateOfContribution4}
                  onChange={onChange}
                  value={value}
                />
              )}
              control={control}
            />
            <ControlledTextInput
              defaultSettingValue={formatData(systemSetting.rateOfContribution8, 'PERCENTAGE', 1)}
              id="rateOfContribution8"
              title=">8 qtr."
              renderForm={({ onChange, value }) => (
                <PercentageNumberInput
                  originalValue={editedCashflowSetting?.rateOfContribution8}
                  onChange={onChange}
                  value={value}
                />
              )}
              control={control}
            />
          </ContributionRatesContainer>
          <OtherParametersContainer>
            <b>Other Parameters</b>
            <ControlledTextInput
              defaultSettingValue={formatData(systemSetting.lifeExpectancy, 'NUMERIC', 0)}
              id="lifeExpectancy"
              title="Life Expectancy (qtr.)"
              renderForm={({ onChange, value }) => (
                <NumberInput
                  originalValue={editedCashflowSetting?.lifeExpectancy}
                  min={4}
                  max={undefined}
                  width={56}
                  precision={0}
                  step={1}
                  value={value}
                  onChange={onChange}
                />
              )}
              control={control}
            />
            <ControlledTextInput
              defaultSettingValue={formatData(systemSetting.bow, 'NUMERIC', 2)}
              id="bow"
              title="Bow"
              renderForm={({ onChange, value }) => (
                <NumberInput
                  originalValue={editedCashflowSetting?.bow}
                  min={0.01}
                  max={undefined}
                  width={56}
                  precision={2}
                  step={0.01}
                  value={value}
                  onChange={onChange}
                />
              )}
              control={control}
            />
            <ControlledTextInput
              defaultSettingValue={formatData(systemSetting.yield, 'PERCENTAGE', 1)}
              id="yield"
              title="Yield"
              renderForm={({ onChange, value }) => (
                <PercentageNumberInput
                  originalValue={editedCashflowSetting?.yield}
                  precision={2}
                  onChange={onChange}
                  value={value}
                />
              )}
              control={control}
            />
            <ControlledTextInput
              defaultSettingValue={formatData(systemSetting.growth, 'PERCENTAGE', 1)}
              id="growth"
              title="Growth"
              renderForm={({ onChange, value }) => (
                <PercentageNumberInput
                  originalValue={editedCashflowSetting?.growth}
                  min={-50}
                  max={150}
                  precision={2}
                  onChange={onChange}
                  value={value}
                />
              )}
              control={control}
            />
          </OtherParametersContainer>
        </ParametersContainer>
        <Footer>
          <Button type="reset" dense disabled={false} onClick={onEditPanelClose} icon="arrow-left" noMargin>
            CANCEL
          </Button>
          <Button
            data-testid="save-setting-button"
            dense
            dominant
            disabled={!(isDirty && isValid)}
            type="submit"
            noMargin
          >
            SAVE
          </Button>
        </Footer>
      </MainEditorContainer>
      {!isCreatingNew && isDirty && (
        <WarningContainer>
          <Icon prefix="fas" type="info-circle" />
          Changes to this setting will affect all funds which use it.
        </WarningContainer>
      )}
    </form>
  );
};

const ParametersContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 48px;
`;

const ContributionRatesContainer = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
`;

const OtherParametersContainer = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
`;

export default ParameterOverrideForm;
