import {
  HALF_COLUMN_MARGIN,
  HalfConfigurationArea,
  InfoContainer,
  InfoHeading,
  LARGE_SPACING,
  PrefixBorder,
  PreviewArea,
} from './styles';
import React from 'react';
import { Body1, DropMenu } from 'venn-ui-kit';
import type { ViewConfiguration } from './useAddeparViewConfiguration';
import styled, { css } from 'styled-components';
import PreviewDataLibrary from './PreviewDataLibrary';
import PreviewPortfolioAllocator from './PreviewPortfolioAllocator';
import useAddeparViewPreview from './useAddeparViewPreview';

interface AddeparStep4Props {
  firmId: string;
  views: ViewConfiguration[];
}

const AddeparStep4 = ({ firmId, views }: AddeparStep4Props) => {
  const { selectedViewIndex, isLoading, viewPreview, onUpdateViewToPreview } = useAddeparViewPreview({ firmId, views });
  const viewOptions = views.map(({ portfolioPrefix }, index) => ({
    label: portfolioPrefix,
    value: index,
  }));

  return (
    <>
      <InfoHeading as="label">Select view to preview</InfoHeading>
      <DropmenuContainer>
        <DropMenu<number>
          searchable
          className="qa-addepar-select-view-preview"
          items={viewOptions}
          selected={viewOptions[selectedViewIndex].value}
          onChange={(item) => onUpdateViewToPreview(item.value)}
        />
      </DropmenuContainer>
      <PrefixBorder />
      <Container>
        <OverviewItem>
          <StyledPreviewArea maxHeight={150}>
            {!isLoading && viewPreview && <PreviewDataLibrary portfolioNames={viewPreview.portfolioNames} />}
          </StyledPreviewArea>
          <InfoContainer>
            <InfoHeading>Data Library preview</InfoHeading>
            <Body1>This is how your Addepar data will look when browsing your investments in the Data Library</Body1>
          </InfoContainer>
        </OverviewItem>
        <OverviewItem>
          <StyledPreviewArea>
            {!isLoading && viewPreview && <PreviewPortfolioAllocator portfolio={viewPreview.samplePortfolio} />}
          </StyledPreviewArea>
          <InfoContainer>
            <InfoHeading>Portfolio allocator preview</InfoHeading>
            <Body1>
              This will be the data hierarchy/structure that your Addepar data will be displayed in when using the
              Portfolio Allocator in individual tearsheets.
            </Body1>
          </InfoContainer>
        </OverviewItem>
      </Container>
    </>
  );
};

export default AddeparStep4;

const DropmenuContainer = styled(HalfConfigurationArea)`
  padding-right: ${HALF_COLUMN_MARGIN / 2}px;
`;

const StyledPreviewArea = styled(PreviewArea)<{ maxHeight?: number }>`
  padding: ${LARGE_SPACING}px;
  min-height: 114px;
  min-width: 540px;
  ${({ maxHeight }) =>
    maxHeight
      ? css`
          max-height: ${maxHeight}px;
          overflow: auto;
        `
      : undefined}
`;

const OverviewItem = styled.div`
  display: flex;
  & > :not(:last-child) {
    margin-bottom: ${LARGE_SPACING}px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
