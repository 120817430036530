import React from 'react';
import type { Editor } from 'slate';
import {
  type TextControlsState,
  SupportedBlock,
  SupportedMarks,
  SupportedAlignment,
  toggleBlock,
  toggleMark,
  toggleAlignment,
  useFontSize,
  changeFontSizePts,
} from './utils';
import { Button, Label } from 'venn-ui-kit';
import styled from 'styled-components';
import { FontSizeInput } from '../input';

const RichTextControls = ({ controlsState, editor }: { editor: Editor; controlsState: TextControlsState }) => {
  const fontSize = useFontSize(controlsState);

  return (
    <div>
      <FontSizeInput label="Font Size" value={fontSize} onChange={(size) => size && changeFontSizePts(editor, size)} />

      <StyledLabel>Style</StyledLabel>
      <ControlsWrapper>
        <MarkButton format={SupportedMarks.BOLD} icon="bold" editor={editor} active={controlsState.bold} />
        <MarkButton format={SupportedMarks.ITALIC} icon="italic" editor={editor} active={controlsState.italic} />
        <MarkButton
          format={SupportedMarks.UNDERLINE}
          icon="underline"
          editor={editor}
          active={controlsState.underline}
        />
      </ControlsWrapper>
      <StyledLabel>List</StyledLabel>
      <ControlsWrapper>
        <BlockButton
          format={SupportedBlock.NUMBERED_LIST}
          icon="list-ol"
          editor={editor}
          active={controlsState.numberedList}
        />
        <BlockButton
          format={SupportedBlock.BULLETED_LIST}
          icon="list-ul"
          editor={editor}
          active={controlsState.bulletedList}
        />
      </ControlsWrapper>
      <StyledLabel>Text Alignment</StyledLabel>
      <ControlsWrapper>
        <AlignmentButton
          format={SupportedAlignment.LEFT}
          icon="align-left"
          active={controlsState.textAlign === SupportedAlignment.LEFT}
          editor={editor}
        />
        <AlignmentButton
          format={SupportedAlignment.CENTER}
          icon="align-center"
          active={controlsState.textAlign === SupportedAlignment.CENTER}
          editor={editor}
        />
        <AlignmentButton
          format={SupportedAlignment.RIGHT}
          icon="align-right"
          active={controlsState.textAlign === SupportedAlignment.RIGHT}
          editor={editor}
        />
      </ControlsWrapper>
    </div>
  );
};

export default RichTextControls;

const BlockButton = ({
  format,
  icon,
  editor,
  active,
}: {
  active: boolean;
  format: SupportedBlock;
  icon: string;
  editor: Editor;
}) => {
  return (
    <ToggleButton
      active={active}
      onClick={(e) => {
        e.preventDefault();
        toggleBlock(editor, format);
      }}
      icon={icon}
    />
  );
};

const MarkButton = ({
  icon,
  editor,
  active,
  format,
}: {
  active: boolean;
  format: SupportedMarks;
  icon: string;
  editor: Editor;
}) => {
  return (
    <ToggleButton
      active={active}
      onClick={(e) => {
        e.preventDefault();
        toggleMark(editor, format);
      }}
      icon={icon}
    />
  );
};

const AlignmentButton = ({
  format,
  icon,
  editor,
  active,
}: {
  active: boolean;
  format: SupportedAlignment;
  icon: string;
  editor: Editor;
}) => {
  return (
    <ToggleButton
      active={active}
      onClick={(e) => {
        e.preventDefault();
        toggleAlignment(editor, format);
      }}
      icon={icon}
    />
  );
};

const ControlsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledLabel = styled(Label)`
  display: block;
  margin-top: 20px;
`;

const ToggleButton = styled(Button)`
  font-size: 18px;
  width: 43px;
  margin-top: 5px;
  min-width: unset;
  min-height: unset;

  > i {
    margin-right: 0;
    height: unset;
  }
`;
