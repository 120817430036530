import React from 'react';
import styled from 'styled-components';
import type { Message } from 'venn-api';
import { SupportedErrorCodes } from 'venn-api';
import { GetColor } from 'venn-ui-kit';

interface ErrorContentProps {
  errors: (Message | undefined)[];
}

const ErrorContent = ({ errors }: ErrorContentProps) => {
  const error = errors?.find(Boolean);

  if (error?.code === SupportedErrorCodes.NoCommonOverlap) {
    return (
      <EmptyStateContent>
        <ErrorHeader>Unable to run analysis</ErrorHeader>
        <Text>
          Selected benchmark and your portfolio have <strong>no common overlap</strong>.
        </Text>
      </EmptyStateContent>
    );
  }

  return (
    <EmptyStateContent>
      <ErrorHeader>{error?.text}</ErrorHeader>
    </EmptyStateContent>
  );
};

export const EmptyStateContent = styled.div`
  padding: 20px 20px 40px;
  width: 100%;
`;

export const ErrorHeader = styled.div`
  margin-bottom: 10px;
  width: 100%;
  font-size: 36px;
  font-weight: 300;
  text-align: center;
  color: ${GetColor.Black};
`;

const Text = styled.div`
  margin: 0 auto;
  text-align: center;
  font-size: 14px;
  color: ${GetColor.HintGrey};
`;

export default ErrorContent;
