import styled from 'styled-components';

export const Header = styled.div`
  font-size: 32px;
  font-weight: lighter;
`;

export const Notes = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
`;
