import React from 'react';
import styled from 'styled-components';
import { Body2, GetColor, NewBadge } from 'venn-ui-kit';
import {
  supportsLegendFontSize,
  supportsTableDataFontSize,
  isTextOrFormatBlock,
  Routes,
  supportsAxisFontSize,
} from 'venn-utils';
import { TABS } from 'venn-components';
import {
  blockInfoGraphicType,
  blockSettings,
  isReportState,
  type BlockId,
  blockFonts,
  blockFontsDefaults,
  FONT_SIZE_RANGES,
} from 'venn-state';
import { useRecoilValue } from 'recoil';
import { FlexHeader, Section } from '../../../shared';
import { Link } from 'react-router-dom';
import { BlockFontSizeInput } from './components/BlockFontSizeInput';

export const CustomFontSection = ({ selected, isReadOnly }: { selected: BlockId; isReadOnly?: boolean }) => {
  const settings = useRecoilValue(blockSettings(selected));
  const isReport = useRecoilValue(isReportState);
  const infoGraphicType = useRecoilValue(blockInfoGraphicType(selected)) ?? 'DEFAULT';

  const blockType = settings.customBlockType;
  if (isTextOrFormatBlock(blockType)) {
    return <></>;
  }

  if (!isReport) {
    return <></>;
  }

  const blockSupportsTableDataFontSize = settings.supportedGraphicTypes.some((type) =>
    supportsTableDataFontSize(type, settings.customBlockType),
  );
  const tableDataFontSizeEnabled = supportsTableDataFontSize(infoGraphicType, settings.customBlockType);
  const blockSupportsLegendFontSize = settings.supportedGraphicTypes.some((type) => supportsLegendFontSize(type));
  const legendFontSizeEnabled = supportsLegendFontSize(infoGraphicType);
  const blockSupportsAxisFontSize = settings.supportedGraphicTypes.some((type) =>
    supportsAxisFontSize(type, settings.customBlockType),
  );
  const axisFontSizeEnabled = supportsAxisFontSize(infoGraphicType, settings.customBlockType);

  if (!blockSupportsTableDataFontSize && !blockSupportsLegendFontSize) {
    return <></>;
  }

  return (
    <Section>
      <FlexHeader style={{ columnGap: 8 }}>
        Font Customization
        <NewBadge noMargin />
      </FlexHeader>
      <FontCustomizationInputs>
        <BlockFontSizeInput
          blockId={selected}
          stateName="Block Title"
          range={FONT_SIZE_RANGES.blockFontOverrides.titleFontSize}
          state={blockFonts.blockTitle}
          defaultState={blockFontsDefaults.blockTitle(selected)}
          isReadOnly={isReadOnly}
        />
        <BlockFontSizeInput
          blockId={selected}
          stateName="Block Subtitle"
          range={FONT_SIZE_RANGES.blockFontOverrides.subtitleFontSize}
          state={blockFonts.blockSubtitle}
          defaultState={blockFontsDefaults.blockSubtitle(selected)}
          isReadOnly={isReadOnly}
        />
        <BlockFontSizeInput
          blockId={selected}
          stateName="Block Header Data"
          range={FONT_SIZE_RANGES.blockFontOverrides.headerFontSize}
          state={blockFonts.blockHeaderInfo}
          defaultState={blockFontsDefaults.blockHeaderInfo(selected)}
          isReadOnly={isReadOnly}
        />
        {blockSupportsTableDataFontSize && (
          <BlockFontSizeInput
            blockId={selected}
            stateName="Table Content"
            range={FONT_SIZE_RANGES.blockFontOverrides.tableFontSize}
            state={blockFonts.blockTableData}
            defaultState={blockFontsDefaults.blockTableData(selected)}
            isReadOnly={isReadOnly || !tableDataFontSizeEnabled}
          />
        )}
        {blockSupportsAxisFontSize && (
          <BlockFontSizeInput
            blockId={selected}
            stateName="Chart Axis Labels"
            range={FONT_SIZE_RANGES.blockFontOverrides.chartAxisFontSize}
            state={blockFonts.blockChartAxis}
            defaultState={blockFontsDefaults.blockChartAxis(selected)}
            isReadOnly={isReadOnly || !axisFontSizeEnabled}
          />
        )}
        {blockSupportsLegendFontSize && (
          <BlockFontSizeInput
            blockId={selected}
            stateName="Chart Legends"
            range={FONT_SIZE_RANGES.blockFontOverrides.chartLegendFontSize}
            state={blockFonts.blockChartLegend}
            defaultState={blockFontsDefaults.blockChartLegend(selected)}
            isReadOnly={isReadOnly || !legendFontSizeEnabled}
          />
        )}
      </FontCustomizationInputs>
      <DefaultsLabel>
        Edit default font sizes in{' '}
        <Link to={`${Routes.ACCOUNT_PATH}/${TABS.WORKSPACE_CONFIGURATION}`}>Workspace Configuration</Link>
      </DefaultsLabel>
    </Section>
  );
};

const DefaultsLabel = styled(Body2)`
  color: ${GetColor.GreyScale.Grey70};
`;

const FontCustomizationInputs = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: column;
  gap: 8px;
  padding: 8px 0;
`;
