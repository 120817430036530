import React, { useRef, useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Headline2 } from 'venn-ui-kit';
import { useApi } from 'venn-utils';
import { DownloadableContentBlock } from 'venn-components';
import type { FactorWithNonSerializedReturns as FactorEntity, NotableFactorPeriodGroup } from 'venn-api';
import { cachedGetNotableFactorLensPeriodsV2 } from 'venn-api';
import type { Factor } from './types';
import Pagination from './Pagination';
import NotableItem from './NotableItem';

interface NotableProps {
  factor: Factor;
  factorName: string;
  factorEntity?: FactorEntity;
}

const Notable = ({ factor, factorName, factorEntity }: NotableProps) => {
  const [index, setIndex] = useState(0);
  const isMount = useRef(false);
  const factorPeriodRef = useRef(useApi(cachedGetNotableFactorLensPeriodsV2));
  const [notablePeriods, setNotablePeriods] = useState<NotableFactorPeriodGroup | undefined>(undefined);

  useEffect(() => {
    isMount.current = true;
    return () => {
      isMount.current = false;
    };
  }, []);
  useEffect(() => {
    if (!factorEntity) {
      return;
    }
    const fetchPeriods = async () => {
      try {
        const factorPeriodGroups = await factorPeriodRef.current();
        const periodGroup = factorPeriodGroups?.content?.find((group) => group.factorId === factorEntity.id);
        isMount.current && setNotablePeriods(periodGroup);
      } catch (e) {
        if (e.name !== 'AbortError') {
          // TODO: handle loading error
        }
      }
    };
    fetchPeriods();
  }, [factor, factorEntity]);

  const currentPeriod = useMemo(
    () => (notablePeriods ? notablePeriods.factorPeriods[index] : undefined),
    [notablePeriods, index],
  );

  return currentPeriod ? (
    <Container className="qa-deep-dive-notable">
      <Headline2>What are some examples of notable performance for the {factorName} factor?</Headline2>
      <DownloadableContentBlock
        header="Notable Performance"
        downloadable={{
          png: true,
          options: {
            fileName: `Notable Periods for ${factorName}`,
          },
          tracking: {
            description: 'NOTABLE_PERIODS',
            subjectType: 'factor',
            subjectId: undefined,
            relativeToBenchmark: false,
            userUploaded: false,
          },
        }}
      >
        <NotableItem factor={factor} period={currentPeriod} />
        {notablePeriods?.factorPeriods && notablePeriods.factorPeriods.length > 1 && (
          <Pagination current={index} onChange={setIndex} count={notablePeriods.factorPeriods.length} />
        )}
      </DownloadableContentBlock>
    </Container>
  ) : null;
};

const Container = styled.div`
  margin-top: 60px;
  ${Headline2} {
    margin-bottom: 40px;
  }
`;

export default Notable;
