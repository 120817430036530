import React, { Suspense } from 'react';
import { CustomizableErrorBoundary, useHasUrlParam } from 'venn-components';
import { UrlParam } from 'venn-utils';

const LazyRecoilize = React.lazy(() => import('recoilize'));

/** Recoilize debugging tools but loaded lazily ONLY when using the {@link forceRecoilize} url parameter. */
export const LazyRecoilizeDebugger = () => {
  const LazyLoadedRecoilize = useHasUrlParam(UrlParam.FORCE_RECOILIZE) ? LazyRecoilize : null;

  return (
    LazyLoadedRecoilize && (
      <CustomizableErrorBoundary>
        <Suspense fallback={null}>
          <LazyLoadedRecoilize />
        </Suspense>
      </CustomizableErrorBoundary>
    )
  );
};
