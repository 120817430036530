import type { FC } from 'react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import type { Portfolio, SearchFund } from 'venn-api';
import styled from 'styled-components';
import {
  AddInvestmentsModal,
  AllocationPanel,
  DataUploaderModalWrapper,
  DataUploaderMode,
  SplitButtonDropdown,
  TreeItemUpdateType,
  UserContext,
} from 'venn-components';
import { analyticsService, createPortfolioFromFunds, useModal } from 'venn-utils';
import { CreatePortfolioStage } from '../portfolio-tutorial/constants';
import { Headline2, Headline3 } from 'venn-ui-kit';
import { Panel } from '../Layout';
import { compact } from 'lodash';

interface NewPortfolioPanelProps {
  initialPortfolio?: Portfolio;
  currentStage: CreatePortfolioStage;
  setCurrentStage: (nextStage: CreatePortfolioStage) => void;
  hasAccessToCompare?: boolean;
  canUploadInvestments?: boolean;
}

const NewPortfolioPanel: FC<React.PropsWithChildren<NewPortfolioPanelProps>> = ({
  initialPortfolio,
  setCurrentStage,
  currentStage,
  hasAccessToCompare,
  canUploadInvestments,
}) => {
  const { hasPermission } = useContext(UserContext);
  const [draftPortfolio, setDraftPortfolio] = useState<Portfolio | undefined>(initialPortfolio);
  const [isUploadModalOpen, openUploadModal, closeUploadModal] = useModal();
  const [isInvestmentsModalOpen, openInvestmentsModal, closeInvestmentsModal] = useModal();
  const completeUploadModal = (portfolio: Portfolio) => {
    setDraftPortfolio(portfolio);
    setCurrentStage(CreatePortfolioStage.ADD_STRATEGIES);
    closeUploadModal();
    analyticsService.portfolioSetupStepViewed({
      stepDescription: 'Add Strategies',
    });
  };

  const completeAddInvestments = useCallback(
    (investments: SearchFund[]) => {
      const portfolio = createPortfolioFromFunds(investments);
      setDraftPortfolio(portfolio);
      setCurrentStage(CreatePortfolioStage.ADD_STRATEGIES);
      closeInvestmentsModal();
      analyticsService.portfolioSetupStepViewed({
        stepDescription: 'Add Strategies',
      });
    },
    [closeInvestmentsModal, setCurrentStage],
  );

  const onUpdate = useCallback(
    (_, updateType: TreeItemUpdateType) => {
      switch (updateType) {
        // Tutorial will only go with one direction
        case TreeItemUpdateType.ADD_STRATEGY:
          if (currentStage === CreatePortfolioStage.ADD_STRATEGIES) {
            setCurrentStage(CreatePortfolioStage.EDIT_PORTFOLIO);
            analyticsService.portfolioSetupStepViewed({
              stepDescription: 'Edit Portfolio',
            });
          }
          break;
        case TreeItemUpdateType.DRAG_N_DROP:
        case TreeItemUpdateType.DRAG_N_DROP_REORDER_ONLY:
          if (currentStage === CreatePortfolioStage.EDIT_PORTFOLIO) {
            setCurrentStage(CreatePortfolioStage.ADD_ALLOCATION);
            analyticsService.portfolioSetupStepViewed({
              stepDescription: 'Add Allocations',
            });
          }
          break;
        case TreeItemUpdateType.CHANGE_ALLOCATION:
          if (currentStage === CreatePortfolioStage.ADD_ALLOCATION) {
            setCurrentStage(CreatePortfolioStage.SAVE_AND_VIEW);
            analyticsService.portfolioSetupStepViewed({
              stepDescription: 'Save and View',
            });
          }
          break;
        case TreeItemUpdateType.SAVE:
          analyticsService.portfolioSetupStepCompleted({
            stepDescription: 'Save and View',
          });
          break;
        default:
          break;
      }
    },
    [setCurrentStage, currentStage],
  );

  useEffect(() => {
    if (currentStage === CreatePortfolioStage.ADD_INVESTMENT) {
      analyticsService.portfolioSetupStepViewed({
        stepDescription: 'Add Investments',
      });
    } else if (currentStage === CreatePortfolioStage.ADD_STRATEGIES) {
      analyticsService.portfolioSetupStepViewed({
        stepDescription: 'Add Strategies',
      });
      if (initialPortfolio) {
        setDraftPortfolio(initialPortfolio);
      }
    }
  }, [currentStage, initialPortfolio]);

  const options = compact([
    hasPermission('UPLOAD_RETURNS') && {
      onClick: openUploadModal,
      label: 'Upload New investments',
    },
    {
      onClick: openInvestmentsModal,
      label: 'Add Investments From Library',
    },
  ]);
  if (!draftPortfolio) {
    return (
      <Panel>
        <Wrapper>
          <Title>
            <Headline2>Create your portfolio.</Headline2>
            <Headline3>Add or upload investments and allocations to get started.</Headline3>
          </Title>

          <SplitButtonDropdown
            options={options}
            mainButtonClassName="qa-data-upload"
            buttonClassName="qa-data-upload-button"
          />
          {isUploadModalOpen && (
            <DataUploaderModalWrapper
              portfolio={draftPortfolio}
              mode={DataUploaderMode.Returns}
              onCancel={closeUploadModal}
              onComplete={completeUploadModal}
            />
          )}
          {isInvestmentsModalOpen && (
            <AddInvestmentsModal onSubmit={completeAddInvestments} onCancel={closeInvestmentsModal} />
          )}
        </Wrapper>
      </Panel>
    );
  }

  return (
    <Panel>
      <AllocationPanel
        portfolio={draftPortfolio}
        onPortfolioUpdate={onUpdate}
        selectedStrategyId={draftPortfolio.id}
        blockHistoryTest={() => new RegExp('/create/portfolio')}
        hasAccessToCompare={hasAccessToCompare}
        canUploadInvestments={canUploadInvestments}
      />
    </Panel>
  );
};

export default NewPortfolioPanel;

const Wrapper = styled.div`
  padding: 60px 40px;
  .split-button-dropdown {
    width: 246px;
    left: -10px;
  }
`;

const Title = styled.div`
  margin-bottom: 30px;
`;
