import React from 'react';
import type { CustomBenchmarkTypeEnum } from 'venn-api';
import type { DropMenuItem } from 'venn-ui-kit';
import { Icon } from 'venn-ui-kit';
import styled from 'styled-components';

const StyledIcon = styled(Icon)`
  margin-right: 5px;
`;

export const benchmarkItems: DropMenuItem<CustomBenchmarkTypeEnum>[] = [
  { value: 'NONE', label: 'No Benchmark', icon: <StyledIcon type="ban" /> },
  {
    value: 'COMMON',
    label: 'Common Benchmark',
    icon: <StyledIcon type="triangle" />,
  },
  {
    value: 'INDIVIDUAL',
    label: 'Individual Benchmarks',
    icon: <StyledIcon type="shapes" />,
  },
];

export const getBenchmarkTypeText = (customBenchmarkType?: CustomBenchmarkTypeEnum) =>
  customBenchmarkType === 'INDIVIDUAL'
    ? 'Individual Benchmarks'
    : customBenchmarkType === 'COMMON'
      ? 'Common Benchmark'
      : 'None';
