import type React from 'react';
import { useCallback, useState } from 'react';
import { setupPhoneMFA, verifyPhoneMFA } from 'venn-api';

interface UseEnableSMSProps {
  onComplete(): void;
}

interface UseEnableSMS {
  phoneNumber: string;
  code: string;
  showVerify: boolean;
  onPhoneNumberChange: (phoneNumber: string) => void;
  onVerificationCodeChange: (code: string) => void;
  onSubmit: (e?: React.FormEvent<HTMLFormElement>) => void;
  resendCode: () => void;
  loading: boolean;
  error: string;
}

const sanitizePhoneNumber = (phoneNumber: string) => phoneNumber.replace(/[^0-9\.]+/g, '');

const useEnableSMS = ({ onComplete }: UseEnableSMSProps): UseEnableSMS => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [showVerify, setShowVerify] = useState(false);
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const onPhoneNumberChange = useCallback((phoneNumber: string) => {
    setPhoneNumber(phoneNumber);
  }, []);

  const onVerificationCodeChange = (code: string) => {
    setCode(code);
  };

  const onSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (showVerify) {
      onSubmitVerificationCode();
    } else {
      onSubmitNumber();
    }
  };

  const onSubmitNumber = async () => {
    setLoading(true);
    setError('');
    try {
      await setupPhoneMFA({ phoneNumber: sanitizePhoneNumber(phoneNumber) });
      setShowVerify(true);
    } catch (e) {
      setError(e.content?.message);
    }
    setLoading(false);
  };

  const onSubmitVerificationCode = async () => {
    setLoading(true);
    try {
      await verifyPhoneMFA({ code });
      setShowVerify(false);
      setError('');
      onComplete();
    } catch (e) {
      setError(e.content?.message);
    }
    setLoading(false);
  };

  return {
    phoneNumber,
    code,
    showVerify,
    onPhoneNumberChange,
    onVerificationCodeChange,
    onSubmit,
    resendCode: onSubmitNumber,
    loading,
    error,
  };
};

export default useEnableSMS;
