import React from 'react';
import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';
import type { AvailableRange } from 'venn-utils';
import { Dates } from 'venn-utils';
import { capitalize } from 'lodash';

export const CellWrapper = styled.div<{ color?: string }>`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  color: ${(props) => props.color ?? GetColor.DarkGrey};
  a {
    color: ${(props) => props.color ?? GetColor.DarkGrey};
  }
  i {
    margin-right: 5px;
  }
`;

export const renderTimeRangeTooltip = (benchmarkName: string, range?: AvailableRange) => {
  if (!range) {
    return 'Failed to find available range';
  }
  const { start, end, frequency } = range;

  if (start && end && frequency) {
    const startYear = Dates.toDDMMMYYYY(start, frequency);
    const endYear = Dates.toDDMMMYYYY(end, frequency);
    return (
      <TooltipContent>
        <Name>{benchmarkName}</Name>
        <Row>
          <span>Availability:</span>
          <Bold>{startYear !== endYear ? `${startYear} - ${endYear}` : startYear}</Bold>
        </Row>
        <Row>
          <span>Max Frequency:</span>
          <Bold>{capitalize(frequency)}</Bold>
        </Row>
      </TooltipContent>
    );
  }
  return 'Miss information';
};

const TooltipContent = styled.div`
  width: 200px;
  font-size: 11px;
`;

const Name = styled.div`
  margin-bottom: 10px;
  font-weight: bold;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Bold = styled.span`
  font-weight: bold;
`;
