import type { PublicPrivateAssetGrowthBlockProps } from './types';
import { useCommonGridProps } from '../../../logic/columnUtils';
import { useIsBlockGridCompact } from '../../../logic/useIsBlockGridCompact';
import ExportableGrid from '../../grid/ExportableGrid';
import React from 'react';
import {
  usePublicPrivateAssetGrowthGridColumnDefs,
  usePublicPrivateAssetGrowthGridData,
} from './logic/usePublicPrivateGrowthGridData';
import { DATA_TYPE_EXCEL_STYLES } from '../../../logic/customBlockUtils';
import { useBlockId } from '../../../contexts/BlockIdContext';
import { assertNotNil } from 'venn-utils';

export const PublicPrivateAssetGrowthGridBlock = ({
  data,
  exportable,
  inPrintMode,
}: PublicPrivateAssetGrowthBlockProps) => {
  const selectedRefId = useBlockId();
  const commonGridProps = useCommonGridProps();

  const rowData = usePublicPrivateAssetGrowthGridData(assertNotNil(data));
  const isCompact = useIsBlockGridCompact();
  const columnDefs = usePublicPrivateAssetGrowthGridColumnDefs();

  return (
    <ExportableGrid
      exportable={exportable}
      inPrintMode={inPrintMode}
      {...commonGridProps}
      selectedRefId={selectedRefId}
      rowData={rowData}
      columnDefs={columnDefs}
      excelStyles={DATA_TYPE_EXCEL_STYLES}
      isCompact={isCompact}
      tooltipShowDelay={1200}
    />
  );
};
