import React, { useMemo, useState } from 'react';
import type { UserProfileSettings } from 'venn-api';
import { getUserLogins, signOutAndClearStorage } from 'venn-api';
import { AccountModalWrapper, EditPassword, TwoFactor } from 'venn-components';
import { FlexRow, FlexWrapper, Name, StyledButton, StyledIcon, StyledWrapper } from './components/shared';
import { useFetchApi, useModal } from 'venn-utils';
import LoginHistory from './components/LoginHistory';
import { Notifications, NotificationType } from 'venn-ui-kit';

interface SecurityProps {
  profileSettings: UserProfileSettings;
  refresh: () => void;
}

const PAGE_SIZE = 25;

const Security: React.FC<React.PropsWithChildren<SecurityProps>> = ({ profileSettings, refresh }) => {
  const [showPasswordModal, openPasswordModal, closePasswordModal] = useModal();

  const afterChangePassword = () => {
    Notifications.notify('Password has been updated.', NotificationType.SUCCESS);
    refresh();
  };

  const goToForgotPassword = async () => {
    try {
      await signOutAndClearStorage();
    } catch {
      // Do nothing and redirect to forgot-password route
    }
    window.location.href = `${process.env.PUBLIC_URL}/forgot-password`;
  };

  const [selectedPage, setSelectedPage] = useState(1);
  const searchRequestBody = useMemo(
    () => ({
      resultsPerPage: PAGE_SIZE,
      // pageNumber is 0-indexed
      pageNumber: selectedPage - 1,
    }),
    [selectedPage],
  );
  const { loading, result, error } = useFetchApi(getUserLogins, searchRequestBody);
  const { results, totalResults } = result ?? {
    results: [],
    totalResults: 0,
  };

  const { pwdLastUpdated } = profileSettings.user;
  return (
    <>
      <StyledWrapper>
        <FlexRow>
          <FlexWrapper>
            <StyledIcon type="key" />
            <Name>Manage Password</Name>
          </FlexWrapper>
          <StyledButton onClick={openPasswordModal} className="qa-change-password-btn">
            Change Password
          </StyledButton>
          {showPasswordModal && (
            <AccountModalWrapper onClose={closePasswordModal} title="Manage Password" icon="key">
              <EditPassword
                onClose={closePasswordModal}
                updateCallback={afterChangePassword}
                goToForgotPassword={goToForgotPassword}
                pwdLastUpdated={pwdLastUpdated}
              />
            </AccountModalWrapper>
          )}
        </FlexRow>
      </StyledWrapper>
      <TwoFactor profileSettings={profileSettings} refresh={refresh} />
      <StyledWrapper>
        <LoginHistory
          data={results}
          total={totalResults}
          loading={loading}
          error={error}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          pageSize={PAGE_SIZE}
          onChangePasswordClick={openPasswordModal}
        />
      </StyledWrapper>
    </>
  );
};

export default Security;
