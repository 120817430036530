import type { ProxyOption } from 'venn-api';

const desmoothData: ProxyOption[] = [
  {
    rawCumulativeReturn: null!,
    valid: false,
    correlation: null!,
    error: 'BELOW_THRESHOLD',
    warning: 'BELOW_THRESHOLD',
    numLags: 1,
    suggestedNumLags: undefined,
    interpolatedCumulativeReturn: null!,
    proxiedCumulativeReturn: [],
    category: {
      category: 'NATURAL_RESOURCES',
      displayName: 'Natural Resources',
      indexId: '87c44d7b-6876-4757-9a71-18754f49c0ec',
      indexName: 'myindex',
    },
  },
  {
    rawCumulativeReturn: [[1285891199999, 1.7809238609203861]],
    valid: true,
    error: null!,
    warning: null!,
    category: {
      category: 'REAL_ESTATE',
      displayName: 'Real Estate',
      indexId: '4e978fe7-1440-4273-81da-d2c74e31adea',
      indexName: 'myOtherIndex',
    },
    numLags: 1,
    suggestedNumLags: 2,
    correlation: 0.06316807372905535,
    proxiedCumulativeReturn: [[1293753599999, 1.7106136424907261]],
    interpolatedCumulativeReturn: undefined!,
  },
  {
    rawCumulativeReturn: [[1285891199999, 1.7809238609203861]],
    valid: true,
    error: null!,
    warning: null!,
    category: {
      category: 'INFRASTRUCTURE',
      displayName: 'Infrastructure',
      indexId: '4e978fasd-1440-4273-81da-d2c74e31adea',
      indexName: 'infraIndex',
    },
    numLags: 1,
    suggestedNumLags: 4,
    correlation: 0.08316807372905535,
    proxiedCumulativeReturn: [[1293753599999, 1.7106136424907261]],
    interpolatedCumulativeReturn: undefined!,
  },
];

export default desmoothData;
